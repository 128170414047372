<div id="WpaForms1">
  <form [formGroup]="WpaForms1">
    <table>
      <tbody>
         <div class="table table-bordered">
         
         <tr class="col-12">
            <td colspan="12">
               <label><b>21.  PROFESSIONAL LIABILITY&nbsp;&nbsp;(Do not abbreviate)</b></label>
            </td>
         </tr>
         <tr class="col-12">
            <td colspan="6">
               <div class="row">
                  <div class="col-6 pr-0">
                     <label for="345"><b>A. Current Insurance Carrier:</b></label>
                  </div>
                  <div class="col-6 pl-0">
                     <input type="text" placeholder="Enter Name of Carrier" id="345" formControlName="345" class="form-control"> </div>
               </div>
            </td>
            <td colspan="6">
               <div class="row">
                  <div class="col-6 pr-0">
                     <label for="346">Policy Number:</label>
                  </div>
                  <div class="col-6 pl-0">
                     <input type="text" placeholder="Enter Policy Number" id="346" formControlName="346" class="form-control"> </div>
               </div>
            </td>
         </tr>
         <tr class="col-12">
            <td colspan="6">
               <div class="row">
                  <div class="col-4 pr-0">
                     <label for="901">Mailing Address:</label>
                  </div>
                  <div class="col-4 pl-0">
                     <input type="text" placeholder="Enter Mailing Address" id="901" formControlName="901" class="form-control"> </div>
                  <div class="col-4 pl-0">
                     <input type="text" placeholder="Enter Mailing Address" id="902" formControlName="902" class="form-control"> </div>
               </div>
            </td>
            <td colspan="6">
               <div class="row">
                  <div class="col-3">
                     <label for="903">City/State/Zip:</label>
                  </div>
                  <div class="col-3">
                     <input type="text" placeholder="Enter City" id="903" formControlName="903" class="form-control"> </div>
                  <div class="col-3">
                     <ng-select [items]="states" bindLabel="name" placeholder="Enter state" bindValue="id" formControlName="905"> </ng-select>
                  </div>
                  <div class="col-3">
                     <input type="text" placeholder="Enter Zip Code" id="906" formControlName="906" class="form-control"> </div>
               </div>
               <!-- </div> -->
            </td>

         </tr>
         <tr class="col-12">
            <td colspan="6">
               <div class="row">
                  <div class="col-6 pr-0">
                     <label for="1266">Phone Number:</label>
                  </div>
                  <div class="col-6 pl-0">
                     <input type="text" placeholder="Enter Phone Number" id="1266" formControlName="1266" class="form-control"> <small class="text-danger" *ngIf="WpaForms1.controls['1266'].errors?.pattern">
                    Only number are allowed</small> </div>
               </div>
            </td>
            <td colspan="6">
               <div class="row">
                  <div class="col-6 pr-0">
                     <label for="1273">Fax Number:</label>
                  </div>
                  <div class="col-6 pl-0">
                     <input type="text" placeholder="Enter Fax Number" id="1273" formControlName="1273" class="form-control"> </div>
               </div>
            </td>
         </tr>
         <tr class="col-12">
            <td colspan="3">
               <div class="row">
                  <div class="col-6 pr-0">
                     <label for="350">Per claim amount: $</label>
                  </div>
                  <div class="col-6 pl-0">
                     <ng-select [items]="preClaim" bindLabel="name" placeholder="Enter claim amount" bindValue="id" formControlName="350"> </ng-select>
                  </div>
               </div>
            </td>
            <td colspan="3">
               <div class="row">
                  <div class="col-6 pr-0">
                     <label for="351">Aggregate amount: $</label>
                  </div>
                  <div class="col-6 pl-0">
                     <ng-select [items]="aggregates" [class.is-invalid]="(WpaForms1.controls['351'].touched &&
                    WpaForms1.controls['351'].invalid) ||
                    (WpaForms1.controls['351'].invalid && isSubmit)" bindLabel="name" placeholder="Enter aggregate amount" bindValue="id" formControlName="351"> </ng-select>
                  </div>
               </div>
            </td>
            <td colspan="6">
               <div class="row">
                  <div class="col-6">
                     <label for="348">Date Began (mm/yyyy):</label>
                  </div>
                  <div class="col-6  d-flex">
                     <input type="text" placeholder="Enter Issue Date " id="348" (blur)="clearInputIfInvalid('348')" formControlName="348" class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker #wpa101="ngbDatepicker">
                     <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="wpa101.toggle()" type="button"> <i class="fas fa-calendar-alt"></i> </button>
                     </div>
                  </div>
                  <div class="col-6"></div>
									<div class="col-6">
									  <div  *ngIf="WpaForms1.get('348').invalid">
										<small class="text-danger" *ngIf="WpaForms1.get('348').errors?.invalidDateFormat">
										  Only Date Format is allowed(MM/DD/YYYY)                    </small>
										<small class="text-danger" *ngIf="WpaForms1.get('348').errors?.invalidMonth">
										Month is out of range
										</small>
										<small class="text-danger" *ngIf="WpaForms1.get('348').errors?.invalidDateyear">
										  year is out of range(1900-2099)                      </small>
										  <small class="text-danger" *ngIf="WpaForms1.get('348').errors?.invalidDateRange">
											date is out of range
											</small>
										</div>
									</div>
                  <div class="col-6">
                     <label for="349">Expiration Date (mm/yyyy):</label>
                  </div>
                  <div class="col-6  d-flex">
                     <input type="text" placeholder="Enter Expiration Date " id="349" formControlName="349" (blur)="clearInputIfInvalid('349')" class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker #wpa1001="ngbDatepicker">
                     <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="wpa1001.toggle()" type="button"> <i class="fas fa-calendar-alt"></i> </button>
                     </div>
                  </div>
                  <div class="col-6"></div>
                  <div class="col-6">
                    <div  *ngIf="WpaForms1.get('349').invalid">
                     <small class="text-danger" *ngIf="WpaForms1.get('349').errors?.invalidDateFormat">
                       Only Date Format is allowed(MM/DD/YYYY)                    </small>
                     <small class="text-danger" *ngIf="WpaForms1.get('349').errors?.invalidMonth">
                     Month is out of range
                     </small>
                     <small class="text-danger" *ngIf="WpaForms1.get('349').errors?.invalidDateyear">
                       year is out of range(1900-2099)                      </small>
                       <small class="text-danger" *ngIf="WpaForms1.get('349').errors?.invalidDateRange">
                        date is out of range
                        </small>
                     </div>
                  </div>
               </div>
            </td>

         </tr>
         <tr class="col-12">
            <td colspan="12">
               <label><b>B. PREVIOUS PROFESSIONAL LIABILITY CARRIERS WITHIN THE LAST TEN YEARS (Do not
              abbreviate)&nbsp;&nbsp;&nbsp;<br>(Attach Additional Sheet if Necessary)</b>
                  <br> </label>
            </td>
         </tr>
         <tr class="col-12">
            <td colspan="6">
               <div class="row">
                  <div class="col-6 pr-0">
                     <label for="1242"><b>Name of Carrier:</b><span class="mandatory">*</span></label>
                  </div>
                  <div class="col-6 pl-0">
                     <input type="text" placeholder="Enter Name of Carrier" id="1242" formControlName="1242" class="form-control" [class.is-invalid]="(WpaForms1.controls['1242'].touched && WpaForms1.controls['1242'].invalid ) || (WpaForms1.controls['1242'].invalid && isSubmit)">
                     <div class="invalid-feedback" *ngIf="(WpaForms1.controls['1242'].touched && WpaForms1.controls['1242'].invalid) || (WpaForms1.controls['1242'].invalid && isSubmit)"> Required </div>
                  </div>
               </div>
            </td>
            <td colspan="6">
               <div class="row">
                  <div class="col-6 pr-0">
                     <label for="1256">Policy Number:<span class="mandatory">*</span></label>
                  </div>
                  <div class="col-6 pl-0">
                     <input type="text" placeholder="Enter Policy Number" id="1256" formControlName="1256" class="form-control" [class.is-invalid]="(WpaForms1.controls['1256'].touched && WpaForms1.controls['1256'].invalid ) || (WpaForms1.controls['1256'].invalid && isSubmit)">
                     <div class="invalid-feedback" *ngIf="(WpaForms1.controls['1256'].touched && WpaForms1.controls['1256'].invalid) || (WpaForms1.controls['1256'].invalid && isSubmit)"> Required </div>
                  </div>
               </div>
            </td>
         </tr>
         <tr class="col-12">
            <td colspan="6">
               <div class="row">
                  <div class="col-6 pr-0">
                     <label for="1244">Mailing Address:<span class="mandatory">*</span></label>
                  </div>
                  <div class="col-3 pl-0">
                     <input type="text" placeholder="Enter Mailing Address" id="1244" formControlName="1244" class="form-control" [class.is-invalid]="(WpaForms1.controls['1244'].touched && WpaForms1.controls['1244'].invalid ) || (WpaForms1.controls['1244'].invalid && isSubmit)">
                     <div class="invalid-feedback" *ngIf="(WpaForms1.controls['1244'].touched && WpaForms1.controls['1244'].invalid) || (WpaForms1.controls['1244'].invalid && isSubmit)"> Required </div>
                  </div>
                  <div class="col-3 pl-0">
                     <input type="text" placeholder="Enter Mailing Address" id="1245" formControlName="1245" class="form-control" [class.is-invalid]="(WpaForms1.controls['1245'].touched && WpaForms1.controls['1245'].invalid ) || (WpaForms1.controls['1245'].invalid && isSubmit)">
                     <div class="invalid-feedback" *ngIf="(WpaForms1.controls['1245'].touched && WpaForms1.controls['1245'].invalid) || (WpaForms1.controls['1245'].invalid && isSubmit)"> Required </div>
                  </div>
               </div>
            </td>
            <td colspan="6">
               <div class="row">
                  <div class="col-3 pr-0">
                     <label for="1246">City/State/Zip:<span class="mandatory">*</span></label>
                  </div>
                  <div class="col-3 pl-0">
                     <input type="text" placeholder="Enter City" id="1246" formControlName="1246" class="form-control" [class.is-invalid]="(WpaForms1.controls['1246'].touched && WpaForms1.controls['1246'].invalid ) || (WpaForms1.controls['1246'].invalid && isSubmit)">
                     <div class="invalid-feedback" *ngIf="(WpaForms1.controls['1246'].touched && WpaForms1.controls['1246'].invalid) || (WpaForms1.controls['1246'].invalid && isSubmit)"> Required </div>
                  </div>
                  <div class="col-3 pl-0">
                     <ng-select [items]="states" [class.is-invalid]="(WpaForms1.controls['1248'].touched &&
                    WpaForms1.controls['1248'].invalid) ||
                    (WpaForms1.controls['1248'].invalid && isSubmit)" bindLabel="name" placeholder="Enter state" bindValue="id" formControlName="1248"> </ng-select>
                     <div class="invalid-feedback" *ngIf="(WpaForms1.controls['1248'].touched && WpaForms1.controls['1248'].invalid) || (WpaForms1.controls['1248'].invalid && isSubmit)"> State is required and cannot be empty </div>
                  </div>
                  <div class="col-3 pl-0">
                     <input type="text" placeholder="Enter Zip Code" id="1249" formControlName="1249" class="form-control" [class.is-invalid]="(WpaForms1.controls['1249'].touched && WpaForms1.controls['1249'].invalid ) || (WpaForms1.controls['1249'].invalid && isSubmit)">
                     <div class="invalid-feedback" *ngIf="(WpaForms1.controls['1249'].touched && WpaForms1.controls['1249'].invalid) || (WpaForms1.controls['1249'].invalid && isSubmit)"> Required </div>
                  </div>
               </div>
            </td>

         </tr>
         <tr class="col-12">
            <td colspan="6">
               <div class="row">
                  <div class="col-6 pr-0">
                     <label for="1254">Phone Number:<span class="mandatory">*</span></label>
                  </div>
                  <div class="col-6 pl-0">
                     <input type="text" placeholder="Enter Phone Number" id="1254" formControlName="1254" class="form-control" [class.is-invalid]="(WpaForms1.controls['1254'].touched && WpaForms1.controls['1254'].invalid ) || (WpaForms1.controls['1254'].invalid && isSubmit)"> <small class="text-danger" *ngIf="WpaForms1.controls['1254'].errors?.pattern">
                    Only number are allowed</small> </div>
                  <div class="invalid-feedback" *ngIf="(WpaForms1.controls['1254'].touched && WpaForms1.controls['1254'].invalid) || (WpaForms1.controls['1254'].invalid && isSubmit)"> Required </div>
               </div>
            </td>
            <td colspan="6">
               <div class="row">
                  <div class="col-6 pr-0">
                     <label for="1255">Fax Number:</label>
                  </div>
                  <div class="col-6 pl-0">
                     <input type="text" placeholder="Enter Fax Number" id="1255" formControlName="1255" class="form-control"> </div>
               </div>
            </td>
         </tr>
         <tr class="col-12">
            <td colspan="4">
               <div class="row">
                  <div class="col-6">
                     <label for="1264">Per claim amount: $<span class="mandatory">*</span></label>
                  </div>
                  <div class="col-6">
                     <ng-select [items]="preClaim" [class.is-invalid]="(WpaForms1.controls['1264'].touched &&
                    WpaForms1.controls['1264'].invalid) ||
                    (WpaForms1.controls['1264'].invalid && isSubmit)" bindLabel="name" placeholder="Enter claim amount" bindValue="id" formControlName="1264"> </ng-select>
                     <div class="invalid-feedback" *ngIf="(WpaForms1.controls['1264'].touched && WpaForms1.controls['1264'].invalid) || (WpaForms1.controls['1264'].invalid && isSubmit)"> Per Claim Amount is required and cannot be empty </div>
                  </div>
               </div>
            </td>
            <td colspan="4">
               <div class="row">
                  <div class="col-6">
                     <label for="1265">Aggregate amount: $<span class="mandatory">*</span></label>
                  </div>
                  <div class="col-6">
                     <ng-select [items]="aggregates" [class.is-invalid]="(WpaForms1.controls['1265'].touched &&
                    WpaForms1.controls['1265'].invalid) ||
                    (WpaForms1.controls['1265'].invalid && isSubmit)" bindLabel="name" placeholder="Enter aggregate amount" bindValue="id" formControlName="1265"> </ng-select>
                     <div class="invalid-feedback" *ngIf="(WpaForms1.controls['1265'].touched && WpaForms1.controls['1265'].invalid) || (WpaForms1.controls['1265'].invalid && isSubmit)"> Aggregate Amount is required and cannot be empty </div>
                  </div>
               </div>
            </td>
            <td colspan="4">
               <div class="row">
                  <div class="col-6">
                     <label for="1252">Date Began (mm/yyyy):<span class="mandatory">*</span></label>
                  </div>
                  <div class="col-6 d-flex">
                     <input type="text" placeholder="Enter Effective Date" id="1252" formControlName="1252" (blur)="clearInputIfInvalidMandi('1252')"  class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker #wpa76="ngbDatepicker" [class.is-invalid]="(WpaForms1.controls['1252'].touched && WpaForms1.controls['1252'].invalid ) || (WpaForms1.controls['1252'].invalid && isSubmit)">
                     <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="wpa76.toggle()" type="button"> <i class="fas fa-calendar-alt"></i> </button>
                     </div>
                     <div class="text-danger" *ngIf="(WpaForms1.controls['1252'].invalid&&WpaForms1.controls['1252'].touched) && (WpaForms1.controls['1252'].value==''|| WpaForms1.controls['1252'].value==null) " >
                        <small >
                        Required
                        </small>
           
                     </div>
                       <small class="text-danger" *ngIf="WpaForms1.get('1252').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)                </small>
                       <small class="text-danger" *ngIf="WpaForms1.get('1252').errors?.invalidMonth">
                       Month is out of range
                       </small>
                       <small class="text-danger" *ngIf="WpaForms1.get('1252').errors?.invalidDateyear">
                        year is out of range(1900-2099)                  </small>
                        <small class="text-danger" *ngIf="WpaForms1.get('1252').errors?.invalidDateRange">
                          date is out of range
                          </small>
                  </div>
                  <div class="col-6">
                     <label for="1253">Expiration Date (mm/yyyy):<span class="mandatory">*</span></label>
                  </div>
                  <div class="col-6 d-flex">
                     <input type="text" placeholder="Enter Expiration Date" id="1253" formControlName="1253" (blur)="clearInputIfInvalidMandi('1253')"  class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker #wpa77="ngbDatepicker" [class.is-invalid]="(WpaForms1.controls['1253'].touched && WpaForms1.controls['1253'].invalid ) || (WpaForms1.controls['1253'].invalid && isSubmit)">
                     <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="wpa77.toggle()" type="button"> <i class="fas fa-calendar-alt"></i> </button>
                     </div>
                     <div class="text-danger" *ngIf="(WpaForms1.controls['1253'].invalid&&WpaForms1.controls['1253'].touched) && (WpaForms1.controls['1253'].value==''|| WpaForms1.controls['1253'].value==null) " >
                        <small >
                        Required
                        </small>
           
                     </div>
                       <small class="text-danger" *ngIf="WpaForms1.get('1253').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)                </small>
                       <small class="text-danger" *ngIf="WpaForms1.get('1253').errors?.invalidMonth">
                       Month is out of range
                       </small>
                       <small class="text-danger" *ngIf="WpaForms1.get('1253').errors?.invalidDateyear">
                        year is out of range(1900-2099)                  </small>
                        <small class="text-danger" *ngIf="WpaForms1.get('1253').errors?.invalidDateRange">
                          date is out of range
                          </small>
                  </div>
               </div>
            </td>

         </tr>
            <tr >
               <td colspan="6">
                  <div class="row">
                     <div class="col-6 pr-0">
                        <label for="add_1_1242"><b>Name of Carrier:</b></label>
                     </div>
                     <div class="col-6 pl-0">
                        <input type="text" placeholder="Enter Name of Carrier" id="add_1_1242" formControlName="add_1_1242" class="form-control"> </div>
                  </div>
               </td>
               <td colspan="6">
                  <div class="row">
                     <div class="col-6 pr-0">
                        <label for="add_1_1256">Policy Number:</label>
                     </div>
                     <div class="col-6 pl-0">
                        <input type="text" placeholder="Enter Policy Number" id="add_1_1256" formControlName="add_1_1256" class="form-control"> </div>
                  </div>
               </td>
            </tr>
            <tr class="col-12">
               <td colspan="6">
                  <div class="row">
                     <div class="col-4">
                        <label for="add_1_1244">Mailing Address:</label>
                     </div>
                     <div class="col-4">
                        <input type="text" placeholder="Enter Mailing Address" id="add_1_1244" formControlName="add_1_1244" class="form-control"> </div>
                     <div class="col-4">
                        <input type="text" placeholder="Enter Mailing Address" id="add_1_1245" formControlName="add_1_1245" class="form-control"> </div>
                  </div>
               </td>
               <td colspan="6">
                  <div class="row">
                     <div class="col-3 pr-0">
                        <label for="add_1_1246">City/State/Zip:</label>
                     </div>
                     <div class="col-3 pl-0">
                        <input type="text" placeholder="Enter City" id="add_1_1246" formControlName="add_1_1246" class="form-control"> </div>
                     <div class="col-3 pl-0">
                        <ng-select [items]="states" [class.is-invalid]="(WpaForms1.controls['add_1_1248'].touched &&
                       WpaForms1.controls['add_1_1248'].invalid) ||
                       (WpaForms1.controls['add_1_1248'].invalid && isSubmit)" bindLabel="name" placeholder="Enter state" bindValue="id" formControlName="add_1_1248"> </ng-select>
                     </div>
                     <div class="col-3 pl-0">
                        <input type="text" placeholder="Enter Zip Code" id="add_1_1249" formControlName="add_1_1249" class="form-control"> </div>
                  </div>
               </td>

            </tr>
            <tr class="col-12">
               <td colspan="6">
                  <div class="row">
                     <div class="col-6 pr-0">
                        <label for="add_1_1254">Phone Number:</label>
                     </div>
                     <div class="col-6 pl-0">
                        <input type="text" placeholder="Enter Phone Number" id="add_1_1254" formControlName="add_1_1254" class="form-control"> <small class="text-danger" *ngIf="WpaForms1.controls['add_1_1254'].errors?.pattern">
                       Only number are allowed</small> </div>
                  </div>
               </td>
               <td colspan="6">
                  <div class="row">
                     <div class="col-6 pr-0">
                        <label for="add_1_1255">Fax Number:</label>
                     </div>
                     <div class="col-6 pl-0">
                        <input type="text" placeholder="Enter Fax Number" id="add_1_1255" formControlName="add_1_1255" class="form-control"> </div>
                  </div>
               </td>
            </tr>
            <tr class="col-12">
               <td colspan="3">
                  <div class="row">
                     <div class="col-6 pr-0">
                        <label for="add_1_1264">Percalim amount: $</label>
                     </div>
                     <div class="col-6 pl-0">
                        <ng-select [items]="preClaim" [class.is-invalid]="(WpaForms1.controls['add_1_1264'].touched &&
                       WpaForms1.controls['add_1_1264'].invalid) ||
                       (WpaForms1.controls['add_1_1264'].invalid && isSubmit)" bindLabel="name" placeholder="Enter Percalim amount" bindValue="id" formControlName="add_1_1264"> </ng-select>
                     </div>
                  </div>
               </td>
               <td colspan="3">
                  <div class="row">
                     <div class="col-6 pr-0">
                        <label for="add_1_1265">Aggregate amount: $</label>
                     </div>
                     <div class="col-6 pl-0">
                        <ng-select [items]="aggregates" [class.is-invalid]="(WpaForms1.controls['add_1_1265'].touched &&
                       WpaForms1.controls['add_1_1265'].invalid) ||
                       (WpaForms1.controls['add_1_1265'].invalid && isSubmit)" bindLabel="name" placeholder="Enter aggregate amount" bindValue="id" formControlName="add_1_1265"> </ng-select>
                     </div>
                  </div>
               </td>
               <td colspan="6">
                  <div class="row">
                     <div class="col-6">
                        <label for="add_1_1252">Date Began (mm/yyyy):</label>
                     </div>
                     <div class="col-6 d-flex">
                        <input type="text" placeholder="Enter Effective Date" id="add_1_1252" (blur)="clearInputIfInvalid('add_1_1252')" formControlName="add_1_1252" class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker #wpa78="ngbDatepicker">
                        <div class="input-group-append">
                           <button class="btn btn-outline-secondary calendar" (click)="wpa78.toggle()" type="button"> <i class="fas fa-calendar-alt"></i> </button>
                        </div>
                     </div> 
                     <div class="col-6"></div>
                     <div class="col-6">
                       <div  *ngIf="WpaForms1.get('add_1_1252').invalid">
                        <small class="text-danger" *ngIf="WpaForms1.get('add_1_1252').errors?.invalidDateFormat">
                          Only Date Format is allowed(MM/DD/YYYY)                    </small>
                        <small class="text-danger" *ngIf="WpaForms1.get('add_1_1252').errors?.invalidMonth">
                        Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="WpaForms1.get('add_1_1252').errors?.invalidDateyear">
                          year is out of range(1900-2099)                      </small>
                          <small class="text-danger" *ngIf="WpaForms1.get('add_1_1252').errors?.invalidDateRange">
                           date is out of range
                           </small>
                        </div>
                     </div>
                      
                     <div class="col-6">
                        <label for="add_1_1253">Expiration Date (mm/yyyy):</label>
                     </div>
                     <div class="col-6 d-flex">
                        <input type="text" placeholder="Enter Expiration Date" id="add_1_1253" formControlName="add_1_1253" (blur)="clearInputIfInvalid('add_1_1253')" class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker #wpa102="ngbDatepicker">
                        <div class="input-group-append">
                           <button class="btn btn-outline-secondary calendar" (click)="wpa102.toggle()" type="button"> <i class="fas fa-calendar-alt"></i> </button>
                        </div>
                     </div>
                     <div class="col-6"></div>
                     <div class="col-6">
                       <div  *ngIf="WpaForms1.get('add_1_1253').invalid">
                        <small class="text-danger" *ngIf="WpaForms1.get('add_1_1253').errors?.invalidDateFormat">
                          Only Date Format is allowed(MM/DD/YYYY)                    </small>
                        <small class="text-danger" *ngIf="WpaForms1.get('add_1_1253').errors?.invalidMonth">
                        Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="WpaForms1.get('add_1_1253').errors?.invalidDateyear">
                          year is out of range(1900-2099)                      </small>
                          <small class="text-danger" *ngIf="WpaForms1.get('add_1_1253').errors?.invalidDateRange">
                           date is out of range
                           </small>
                        </div>
                     </div>
                  </div>
               </td>

            </tr>
            <tr class="col-12">
               <td colspan="6">
                  <div class="row">
                     <div class="col-6 pr-0">
                        <label for="add_2_1242"><b>Name of Carrier:</b></label>
                     </div>
                     <div class="col-6 pl-0">
                        <input type="text" placeholder="Enter Name of Carrier" id="add_2_1242" formControlName="add_2_1242" class="form-control"> </div>
                  </div>
               </td>
               <td colspan="6">
                  <div class="row">
                     <div class="col-6 pr-0">
                        <label for="add_2_1256">Policy Number:</label>
                     </div>
                     <div class="col-6 pl-0">
                        <input type="text" placeholder="Enter Policy Number" id="add_2_1256" formControlName="add_2_1256" class="form-control"> </div>
                  </div>
               </td>
            </tr>
            <tr class="col-12">
               <td colspan="6">
                  <div class="row">
                     <div class="col-4 pr-0">
                        <label for="add_2_1244">Mailing Address:</label>
                     </div>
                     <div class="col-4 pl-0">
                        <input type="text" placeholder="Enter Mailing Address" id="add_2_1244" formControlName="add_2_1244" class="form-control"> </div>
                     <div class="col-4 pl-0">
                        <input type="text" placeholder="Enter Mailing Address" id="add_2_1245" formControlName="add_2_1245" class="form-control"> </div>
                  </div>
               </td>
               <td colspan="6">
                  <div class="row">
                     <div class="col-3 pr-0">
                        <label for="add_2_1246">City/State/Zip:</label>
                     </div>
                     <div class="col-3 pl-0">
                        <input type="text" placeholder="Enter City" id="add_2_1246" formControlName="add_2_1246" class="form-control"> </div>
                     <div class="col-3 pl-0">
                        <ng-select [items]="states" [class.is-invalid]="(WpaForms1.controls['add_2_1248'].touched &&
                       WpaForms1.controls['add_2_1248'].invalid) ||
                       (WpaForms1.controls['add_2_1248'].invalid && isSubmit)" bindLabel="name" placeholder="Enter state" bindValue="id" formControlName="add_2_1248"> </ng-select>
                     </div>
                     <div class="col-3 pl-0">
                        <input type="text" placeholder="Enter Zip Code" id="add_2_1249" formControlName="add_2_1249" class="form-control"> </div>
                  </div>
               </td>

            </tr>
            <tr class="col-12">
               <td colspan="6">
                  <div class="row">
                     <div class="col-6 pr-0">
                        <label for="add_2_1254">Phone Number:</label>
                     </div>
                     <div class="col-6 pl-0">
                        <input type="text" placeholder="Enter Phone Number" id="add_2_1254" formControlName="add_2_1254" class="form-control"> <small class="text-danger" *ngIf="WpaForms1.controls['add_2_1254'].errors?.pattern">
                       Only number are allowed</small> </div>
                  </div>
               </td>
               <td colspan="6">
                  <div class="row">
                     <div class="col-6 pr-0">
                        <label for="add_2_1255">Fax Number:</label>
                     </div>
                     <div class="col-6 pl-0">
                        <input type="text" placeholder="Enter Fax Number" id="add_2_1255" formControlName="add_2_1255" class="form-control"> </div>
                  </div>
               </td>
            </tr>
            <tr class="col-12">
               <td colspan="3">
                  <div class="row">
                     <div class="col-6 pr-0">
                        <label for="add_2_1264">Per claim amount: $</label>
                     </div>
                     <div class="col-6 pl-0">
                        <ng-select [items]="preClaim" bindLabel="name" placeholder="Enter claim amount" bindValue="id" formControlName="add_2_1264"> </ng-select>
                     </div>
                  </div>
               </td>
               <td colspan="3">
                  <div class="row">
                     <div class="col-6 pr-0">
                        <label for="add_2_1265">Aggregate amount: $</label>
                     </div>
                     <div class="col-6 pl-0">
                        <ng-select [items]="aggregates" bindLabel="name" placeholder="Enter aggregate amount" bindValue="id" formControlName="add_2_1265"> </ng-select>
                     </div>
                  </div>
               </td>
               <td colspan="6">
                  <div class="row">
                     <div class="col-6">
                        <label for="add_2_1252">Date Began (mm/yyyy):</label>
                     </div>
                     <div class="col-6 d-flex">
                        <input type="text" placeholder="Enter Effective Date" id="add_2_1252" formControlName="add_2_1252" (blur)="clearInputIfInvalid('add_2_1252')" class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker #wpa79="ngbDatepicker">
                        <div class="input-group-append">
                           <button class="btn btn-outline-secondary calendar" (click)="wpa79.toggle()" type="button"> <i class="fas fa-calendar-alt"></i> </button>
                        </div>
                     </div>
                     <div class="col-6"></div>
                     <div class="col-6">
                       <div  *ngIf="WpaForms1.get('add_2_1252').invalid">
                        <small class="text-danger" *ngIf="WpaForms1.get('add_2_1252').errors?.invalidDateFormat">
                          Only Date Format is allowed(MM/DD/YYYY)                    </small>
                        <small class="text-danger" *ngIf="WpaForms1.get('add_2_1252').errors?.invalidMonth">
                        Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="WpaForms1.get('add_2_1252').errors?.invalidDateyear">
                          year is out of range(1900-2099)                      </small>
                          <small class="text-danger" *ngIf="WpaForms1.get('add_2_1252').errors?.invalidDateRange">
                           date is out of range
                           </small>
                        </div>
                     </div>
                     <div class="col-6">
                        <label for="add_2_1253">Expiration Date (mm/yyyy):</label>
                     </div>
                     <div class="col-6 d-flex">
                        <input type="text" placeholder="Enter Expiration Date" id="add_2_1253" (blur)="clearInputIfInvalid('add_2_1253')" formControlName="add_2_1253" class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker #wpa80="ngbDatepicker">
                        <div class="input-group-append">
                           <button class="btn btn-outline-secondary calendar" (click)="wpa80.toggle()" type="button"> <i class="fas fa-calendar-alt"></i> </button>
                        </div>
                     </div>
                     <div class="col-6"></div>
                     <div class="col-6">
                       <div  *ngIf="WpaForms1.get('add_2_1253').invalid">
                        <small class="text-danger" *ngIf="WpaForms1.get('add_2_1253').errors?.invalidDateFormat">
                          Only Date Format is allowed(MM/DD/YYYY)                    </small>
                        <small class="text-danger" *ngIf="WpaForms1.get('add_2_1253').errors?.invalidMonth">
                        Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="WpaForms1.get('add_2_1253').errors?.invalidDateyear">
                          year is out of range(1900-2099)                      </small>
                          <small class="text-danger" *ngIf="WpaForms1.get('add_2_1253').errors?.invalidDateRange">
                           date is out of range
                           </small>
                        </div>
                     </div>
                  </div>
               </td>

            </tr>
            <tr class="col-12">
               <td colspan="6">
                  <div class="row">
                     <div class="col-6 pr-0">
                        <label for="add_3_1242"><b>Name of Carrier:</b></label>
                     </div>
                     <div class="col-6 pl-0">
                        <input type="text" placeholder="Enter Name of Carrier" id="add_3_1242" formControlName="add_3_1242" class="form-control"> </div>
                  </div>
               </td>
               <td colspan="6">
                  <div class="row">
                     <div class="col-6 pr-0">
                        <label for="add_3_1256">Policy Number:</label>
                     </div>
                     <div class="col-6 pl-0">
                        <input type="text" placeholder="Enter Policy Number" id="add_3_1256" formControlName="add_3_1256" class="form-control"> </div>
                  </div>
               </td>
            </tr>
            <tr class="col-12">
               <td colspan="6">
                  <div class="row">
                     <div class="col-4 pr-0">
                        <label for="add_3_1244">Mailing Address:</label>
                     </div>
                     <div class="col-4 pl-0">
                        <input type="text" placeholder="Enter Mailing Address" id="add_3_1244" formControlName="add_3_1244" class="form-control"> </div>
                     <div class="col-4 pl-0">
                        <input type="text" placeholder="Enter Mailing Address" id="add_3_1245" formControlName="add_3_1245" class="form-control"> </div>
                  </div>
               </td>
               <td colspan="6">
                  <div class="row">
                     <div class="col-3 pr-0">
                        <label for="add_3_1246">City/State/Zip:</label>
                     </div>
                     <div class="col-3 pl-0">
                        <input type="text" placeholder="Enter City" id="add_3_1246" formControlName="add_3_1246" class="form-control"> </div>
                     <div class="col-3 pl-0">
                        <ng-select [items]="states" [class.is-invalid]="(WpaForms1.controls['add_3_1248'].touched &&
                       WpaForms1.controls['add_3_1248'].invalid) ||
                       (WpaForms1.controls['add_3_1248'].invalid && isSubmit)" bindLabel="name" placeholder="Enter state" bindValue="id" formControlName="add_3_1248"> </ng-select>
                     </div>
                     <div class="col-3 pl-0">
                        <input type="text" placeholder="Enter Zip Code" id="add_3_1249" formControlName="add_3_1249" class="form-control"> </div>
                  </div>
               </td>

            </tr>
            <tr class="col-12">
               <td colspan="6">
                  <div class="row">
                     <div class="col-6 pr-0">
                        <label for="add_3_1254">Phone Number:</label>
                     </div>
                     <div class="col-6 pl-0">
                        <input type="text" placeholder="Enter Phone Number" id="add_3_1254" formControlName="add_3_1254" class="form-control"> <small class="text-danger" *ngIf="WpaForms1.controls['add_3_1254'].errors?.pattern">
                       Only number are allowed</small> </div>
                  </div>
               </td>
               <td colspan="6">
                  <div class="row">
                     <div class="col-6 pr-0">
                        <label for="add_3_1255">Fax Number:</label>
                     </div>
                     <div class="col-6 pl-0">
                        <input type="text" placeholder="Enter Fax Number" id="add_3_1255" formControlName="add_3_1255" class="form-control"> </div>
                  </div>
               </td>
            </tr>
            <tr class="col-12">
               <td colspan="3">
                  <div class="row">
                     <div class="col-6 pr-0">
                        <label for="add_3_1264">Per claim amount: $</label>
                     </div>
                     <div class="col-6 pl-0">
                        <ng-select [items]="preClaim" [class.is-invalid]="(WpaForms1.controls['add_3_1264'].touched &&
                       WpaForms1.controls['add_3_1264'].invalid) ||
                       (WpaForms1.controls['add_3_1264'].invalid && isSubmit)" bindLabel="name" placeholder="Enter claim amount" bindValue="id" formControlName="add_3_1264"> </ng-select>
                     </div>
                  </div>
               </td>
               <td colspan="3">
                  <div class="row">
                     <div class="col-6 pr-0">
                        <label for="add_3_1265">Aggregate amount: $</label>
                     </div>
                     <div class="col-6 pl-0">
                        <ng-select [items]="aggregates" [class.is-invalid]="(WpaForms1.controls['add_3_1265'].touched &&
                       WpaForms1.controls['add_3_1265'].invalid) ||
                       (WpaForms1.controls['add_3_1265'].invalid && isSubmit)" bindLabel="name" placeholder="Enter aggregate amount" bindValue="id" formControlName="add_3_1265"> </ng-select>
                     </div>
                  </div>
               </td>
               <td colspan="6">
                  <div class="row">
                     <div class="col-6">
                        <label for="add_3_1252">Date Began (mm/yyyy):</label>
                     </div>
                     <div class="col-6 d-flex">
                        <input type="text" placeholder="Enter Effective Date" id="add_3_1252" (blur)="clearInputIfInvalid('add_3_1252')"  formControlName="add_3_1252" class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker #wpa81="ngbDatepicker">
                        <div class="input-group-append">
                           <button class="btn btn-outline-secondary calendar" (click)="wpa81.toggle()" type="button"> <i class="fas fa-calendar-alt"></i> </button>
                        </div>
                     </div>
                     <div class="col-6"></div>
                     <div class="col-6">
                       <div  *ngIf="WpaForms1.get('add_3_1252').invalid">
                        <small class="text-danger" *ngIf="WpaForms1.get('add_3_1252').errors?.invalidDateFormat">
                          Only Date Format is allowed(MM/DD/YYYY)                    </small>
                        <small class="text-danger" *ngIf="WpaForms1.get('add_3_1252').errors?.invalidMonth">
                        Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="WpaForms1.get('add_3_1252').errors?.invalidDateyear">
                          year is out of range(1900-2099)                      </small>
                          <small class="text-danger" *ngIf="WpaForms1.get('add_3_1252').errors?.invalidDateRange">
                           date is out of range
                           </small>
                        </div>
                     </div>
                     <div class="col-6">
                        <label for="add_3_1253">Expiration Date (mm/yyyy):</label>
                     </div>
                     <div class="col-6 d-flex">
                        <input type="text" placeholder="Enter Expiration Date" id="add_3_1253" formControlName="add_3_1253" (blur)="clearInputIfInvalid('add_3_1253')" class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker #wpa82="ngbDatepicker">
                        <div class="input-group-append">
                           <button class="btn btn-outline-secondary calendar" (click)="wpa82.toggle()" type="button"> <i class="fas fa-calendar-alt"></i> </button>
                        </div>
                     </div>
                     <div class="col-6"></div>
                     <div class="col-6">
                       <div  *ngIf="WpaForms1.get('add_3_1253').invalid">
                        <small class="text-danger" *ngIf="WpaForms1.get('add_3_1253').errors?.invalidDateFormat">
                          Only Date Format is allowed(MM/DD/YYYY)                    </small>
                        <small class="text-danger" *ngIf="WpaForms1.get('add_3_1253').errors?.invalidMonth">
                        Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="WpaForms1.get('add_3_1253').errors?.invalidDateyear">
                          year is out of range(1900-2099)                      </small>
                          <small class="text-danger" *ngIf="WpaForms1.get('add_3_1253').errors?.invalidDateRange">
                           date is out of range
                           </small>
                        </div>
                     </div>
                  </div>
               </td>

            </tr>
            <tr class="col-12">
               <td colspan="6">
                  <div class="row">
                     <div class="col-6 pr-0">
                        <label for="add_4_1242"><b>Name of Carrier:</b></label>
                     </div>
                     <div class="col-6 pl-0">
                        <input type="text" placeholder="Enter Name of Carrier" id="add_4_1242" formControlName="add_4_1242" class="form-control"> </div>
                  </div>
               </td>
               <td colspan="6">
                  <div class="row">
                     <div class="col-6 pr-0">
                        <label for="add_4_1256">Policy Number:</label>
                     </div>
                     <div class="col-6 pl-0">
                        <input type="text" placeholder="Enter Policy Number" id="add_4_1256" formControlName="add_4_1256" class="form-control"> </div>
                  </div>
               </td>
            </tr>
            <tr class="col-12">
               <td colspan="6">
                  <div class="row">
                     <div class="col-4 pr-0">
                        <label for="add_4_1244">Mailing Address:</label>
                     </div>
                     <div class="col-4 pl-0">
                        <input type="text" placeholder="Enter Mailing Address" id="add_4_1244" formControlName="add_4_1244" class="form-control"> </div>
                     <div class="col-4 pl-0">
                        <input type="text" placeholder="Enter Mailing Address" id="add_4_1245" formControlName="add_4_1245" class="form-control"> </div>
                  </div>
               </td>
               <td colspan="6">
                  <div class="row">
                     <div class="col-3">
                        <label for="add_4_1246">City/State/Zip:</label>
                     </div>
                     <div class="col-3">
                        <input type="text" placeholder="Enter City" id="add_4_1246" formControlName="add_4_1246" class="form-control"> </div>
                     <div class="col-3">
                        <ng-select [items]="states" [class.is-invalid]="(WpaForms1.controls['add_4_1248'].touched &&
                       WpaForms1.controls['add_4_1248'].invalid) ||
                       (WpaForms1.controls['add_4_1248'].invalid && isSubmit)" bindLabel="name" placeholder="Enter state" bindValue="id" formControlName="add_4_1248"> </ng-select>
                        <div class="invalid-feedback" *ngIf="(WpaForms1.controls['add_4_1248'].touched && WpaForms1.controls['add_4_1248'].invalid) || (WpaForms1.controls['add_4_1248'].invalid && isSubmit)"> State is required and cannot be empty </div>
                     </div>
                     <div class="col-3">
                        <input type="text" placeholder="Enter Zip Code" id="add_4_1249" formControlName="add_4_1249" class="form-control"> </div>
                  </div>
               </td>

            </tr>
            <tr class="col-12">
               <td colspan="6">
                  <div class="row">
                     <div class="col-6 pr-0">
                        <label for="add_4_1254">Phone Number:</label>
                     </div>
                     <div class="col-6 pl-0">
                        <input type="text" placeholder="Enter Phone Number" id="add_4_1254" formControlName="add_4_1254" class="form-control"> <small class="text-danger" *ngIf="WpaForms1.controls['add_4_1254'].errors?.pattern">
                       Only number are allowed</small> </div>
                  </div>
               </td>
               <td colspan="6">
                  <div class="row">
                     <div class="col-6 pr-0">
                        <label for="add_4_1255">Fax Number:</label>
                     </div>
                     <div class="col-6 pl-0">
                        <input type="text" placeholder="Enter Fax Number" id="add_4_1255" formControlName="add_4_1255" class="form-control"> </div>
                  </div>
               </td>
            </tr>
            <tr class="col-12">
               <td colspan="3">
                  <div class="row">
                     <div class="col-6 pr-0">
                        <label for="add_4_1264">Per claim amount: $</label>
                     </div>
                     <div class="col-6 pl-0">
                        <ng-select [items]="preClaim" [class.is-invalid]="(WpaForms1.controls['add_4_1264'].touched &&
                       WpaForms1.controls['add_4_1264'].invalid) ||
                       (WpaForms1.controls['add_4_1264'].invalid && isSubmit)" bindLabel="name" placeholder="Enter claim amount" bindValue="id" formControlName="add_4_1264"> </ng-select>
                     </div>
                  </div>
               </td>
               <td colspan="3">
                  <div class="row">
                     <div class="col-6 pr-0">
                        <label for="add_4_1265">Aggregate amount: $</label>
                     </div>
                     <div class="col-6 pl-0">
                        <ng-select [items]="aggregates" [class.is-invalid]="(WpaForms1.controls['add_4_1265'].touched &&
                       WpaForms1.controls['add_4_1265'].invalid) ||
                       (WpaForms1.controls['add_4_1265'].invalid && isSubmit)" bindLabel="name" placeholder="Enter aggregate amount" bindValue="id" formControlName="add_4_1265"> </ng-select>
                     </div>
                  </div>
               </td>
               <td colspan="6">
                  <div class="row">
                     <div class="col-6">
                        <label for="add_4_1252">Date Began (mm/yyyy):</label>
                     </div>
                     <div class="col-6 d-flex">
                        <input type="text" placeholder="Enter Effective Date" id="add_4_1252" (blur)="clearInputIfInvalid('add_4_1252')" formControlName="add_4_1252" class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker #wpa83="ngbDatepicker">
                        <div class="input-group-append">
                           <button class="btn btn-outline-secondary calendar" (click)="wpa83.toggle()" type="button"> <i class="fas fa-calendar-alt"></i> </button>
                        </div>
                     </div>
                     <div class="col-6"></div>
                     <div class="col-6">
                       <div  *ngIf="WpaForms1.get('add_4_1252').invalid">
                        <small class="text-danger" *ngIf="WpaForms1.get('add_4_1252').errors?.invalidDateFormat">
                          Only Date Format is allowed(MM/DD/YYYY)                    </small>
                        <small class="text-danger" *ngIf="WpaForms1.get('add_4_1252').errors?.invalidMonth">
                        Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="WpaForms1.get('add_4_1252').errors?.invalidDateyear">
                          year is out of range(1900-2099)                      </small>
                          <small class="text-danger" *ngIf="WpaForms1.get('add_4_1252').errors?.invalidDateRange">
                           date is out of range
                           </small>
                        </div>
                     </div>
                     <div class="col-6">
                        <label for="add_4_1253">Expiration Date (mm/yyyy):</label>
                     </div>
                     <div class="col-6 d-flex">
                        <input type="text" placeholder="Enter Expiration Date" id="add_4_1253" formControlName="add_4_1253" (blur)="clearInputIfInvalid('add_4_1253')" class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker #wpa84="ngbDatepicker">
                        <div class="input-group-append">
                           <button class="btn btn-outline-secondary calendar" (click)="wpa84.toggle()" type="button"> <i class="fas fa-calendar-alt"></i> </button>
                        </div>
                     </div>
                     <div class="col-6"></div>
                     <div class="col-6">
                       <div  *ngIf="WpaForms1.get('add_4_1253').invalid">
                        <small class="text-danger" *ngIf="WpaForms1.get('add_4_1253').errors?.invalidDateFormat">
                          Only Date Format is allowed(MM/DD/YYYY)                    </small>
                        <small class="text-danger" *ngIf="WpaForms1.get('add_4_1253').errors?.invalidMonth">
                        Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="WpaForms1.get('add_4_1253').errors?.invalidDateyear">
                          year is out of range(1900-2099)                      </small>
                          <small class="text-danger" *ngIf="WpaForms1.get('add_4_1253').errors?.invalidDateRange">
                           date is out of range
                           </small>
                        </div>
                     </div>
                  </div>
               </td>

            </tr>
            <tr class="col-12">
               <td colspan="6">
                  <div class="row">
                     <div class="col-6 pr-0">
                        <label for="add_5_1242"><b>Name of Carrier:</b></label>
                     </div>
                     <div class="col-6 pl-0">
                        <input type="text" placeholder="Enter Name of Carrier" id="add_5_1242" formControlName="add_5_1242" class="form-control"> </div>
                  </div>
               </td>
               <td colspan="6">
                  <div class="row">
                     <div class="col-6 pr-0">
                        <label for="add_5_1256">Policy Number:</label>
                     </div>
                     <div class="col-6 pl-0">
                        <input type="text" placeholder="Enter Policy Number" id="add_5_1256" formControlName="add_5_1256" class="form-control"> </div>
                  </div>
               </td>
            </tr>
            <tr class="col-12">
               <td colspan="6">
                  <div class="row">
                     <div class="col-4 pr-0">
                        <label for="add_5_1244">Mailing Address:</label>
                     </div>
                     <div class="col-4 pl-0">
                        <input type="text" placeholder="Enter Mailing Address" id="add_5_1244" formControlName="add_5_1244" class="form-control"> </div>
                     <div class="col-4 pl-0">
                        <input type="text" placeholder="Enter Mailing Address" id="add_5_1245" formControlName="add_5_1245" class="form-control"> </div>
                  </div>
               </td>
               <td colspan="6">
                  <div class="row">
                     <div class="col-3">
                        <label for="add_5_1246">City/State/Zip:</label>
                     </div>
                     <div class="col-3">
                        <input type="text" placeholder="Enter City" id="add_5_1246" formControlName="add_5_1246" class="form-control"> </div>
                     <div class="col-3">
                        <ng-select [items]="states" [class.is-invalid]="(WpaForms1.controls['add_5_1248'].touched &&
                       WpaForms1.controls['add_5_1248'].invalid) ||
                       (WpaForms1.controls['add_5_1248'].invalid && isSubmit)" bindLabel="name" placeholder="Enter state" bindValue="id" formControlName="add_5_1248"> </ng-select>
                     </div>
                     <div class="col-3">
                        <input type="text" placeholder="Enter Zip Code" id="add_5_1249" formControlName="add_5_1249" class="form-control"> </div>
                  </div>
               </td>

            </tr>
            <tr class="col-12">
               <td colspan="6">
                  <div class="row">
                     <div class="col-6 pr-0">
                        <label for="add_5_1254">Phone Number:</label>
                     </div>
                     <div class="col-6 pl-0">
                        <input type="text" placeholder="Enter Phone Number" id="add_5_1254" formControlName="add_5_1254" class="form-control"> <small class="text-danger" *ngIf="WpaForms1.controls['add_5_1254'].errors?.pattern">
                       Only number are allowed</small> </div>
                  </div>
               </td>
               <td colspan="6">
                  <div class="row">
                     <div class="col-6 pr-0">
                        <label for="add_5_1255">Fax Number:</label>
                     </div>
                     <div class="col-6 pl-0">
                        <input type="text" placeholder="Enter Fax Number" id="add_5_1255" formControlName="add_5_1255" class="form-control"> </div>
                  </div>
               </td>
            </tr>
            <tr class="col-12">
               <td colspan="3">
                  <div class="row">
                     <div class="col-6 pr-0">
                        <label for="add_5_1264">Per claim amount: $</label>
                     </div>
                     <div class="col-6 pl-0">
                        <ng-select [items]="preClaim" [class.is-invalid]="(WpaForms1.controls['add_5_1264'].touched &&
                       WpaForms1.controls['add_5_1264'].invalid) ||
                       (WpaForms1.controls['add_5_1264'].invalid && isSubmit)" bindLabel="name" placeholder="Enter claim amount" bindValue="id" formControlName="add_5_1264"> </ng-select>
                     </div>
                  </div>
               </td>
               <td colspan="3">
                  <div class="row">
                     <div class="col-6 pr-0">
                        <label for="add_5_1265">Aggregate amount: $</label>
                     </div>
                     <div class="col-6 pl-0">
                        <ng-select [items]="aggregates" [class.is-invalid]="(WpaForms1.controls['add_5_1265'].touched &&
                       WpaForms1.controls['add_5_1265'].invalid) ||
                       (WpaForms1.controls['add_5_1265'].invalid && isSubmit)" bindLabel="name" placeholder="Enter aggregate amount" bindValue="id" formControlName="add_5_1265"> </ng-select>
                     </div>
                  </div>
               </td>
               <td colspan="6">
                  <div class="row">
                     <div class="col-6">
                        <label for="add_5_1252">Date Began (mm/yyyy):</label>
                     </div>
                     <div class="col-6 d-flex">
                        <input type="text" placeholder="Enter Effective Date" id="add_5_1252" (blur)="clearInputIfInvalid('add_5_1252')" formControlName="add_5_1252" class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker #wpa86="ngbDatepicker">
                        <div class="input-group-append">
                           <button class="btn btn-outline-secondary calendar" (click)="wpa86.toggle()" type="button"> <i class="fas fa-calendar-alt"></i> </button>
                        </div>
                     </div>
                     <div class="col-6"></div>
                     <div class="col-6">
                       <div  *ngIf="WpaForms1.get('add_5_1252').invalid">
                        <small class="text-danger" *ngIf="WpaForms1.get('add_5_1252').errors?.invalidDateFormat">
                          Only Date Format is allowed(MM/DD/YYYY)                    </small>
                        <small class="text-danger" *ngIf="WpaForms1.get('add_5_1252').errors?.invalidMonth">
                        Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="WpaForms1.get('add_5_1252').errors?.invalidDateyear">
                          year is out of range(1900-2099)                      </small>
                          <small class="text-danger" *ngIf="WpaForms1.get('add_5_1252').errors?.invalidDateRange">
                           date is out of range
                           </small>
                        </div>
                     </div>
                     <div class="col-6">
                        <label for="add_5_1253">Expiration Date (mm/yyyy):</label>
                     </div>
                     <div class="col-6 d-flex">
                        <input type="text" placeholder="Enter Expiration Date" id="add_5_1253" formControlName="add_5_1253" (blur)="clearInputIfInvalid('add_5_1253')" class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker #wpa87="ngbDatepicker">
                        <div class="input-group-append">
                           <button class="btn btn-outline-secondary calendar" (click)="wpa87.toggle()" type="button"> <i class="fas fa-calendar-alt"></i> </button>
                        </div>
                     </div>
                     <div class="col-6"></div>
                     <div class="col-6">
                       <div  *ngIf="WpaForms1.get('add_5_1253').invalid">
                        <small class="text-danger" *ngIf="WpaForms1.get('add_5_1253').errors?.invalidDateFormat">
                          Only Date Format is allowed(MM/DD/YYYY)                    </small>
                        <small class="text-danger" *ngIf="WpaForms1.get('add_5_1253').errors?.invalidMonth">
                        Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="WpaForms1.get('add_5_1253').errors?.invalidDateyear">
                          year is out of range(1900-2099)                      </small>
                          <small class="text-danger" *ngIf="WpaForms1.get('add_5_1253').errors?.invalidDateRange">
                           date is out of range
                           </small>
                        </div>
                     </div>
                  </div>
               </td>

            </tr>
            <tr class="col-12">
               <td colspan="6">
                  <div class="row">
                     <div class="col-6 pr-0">
                        <label for="add_6_1242"><b>Name of Carrier:</b></label>
                     </div>
                     <div class="col-6 pl-0">
                        <input type="text" placeholder="Enter Name of Carrier" id="add_6_1242" formControlName="add_6_1242" class="form-control"> </div>
                  </div>
               </td>
               <td colspan="6">
                  <div class="row">
                     <div class="col-6 pr-0">
                        <label for="add_6_1256">Policy Number:</label>
                     </div>
                     <div class="col-6 pl-0">
                        <input type="text" placeholder="Enter Policy Number" id="add_6_1256" formControlName="add_6_1256" class="form-control"> </div>
                  </div>
               </td>
            </tr>
            <tr class="col-12">
               <td colspan="6">
                  <div class="row">
                     <div class="col-4 pr-0">
                        <label for="add_6_1244">Mailing Address:</label>
                     </div>
                     <div class="col-4 pl-0">
                        <input type="text" placeholder="Enter Mailing Address" id="add_6_1244" formControlName="add_6_1244" class="form-control"> </div>
                     <div class="col-4 pl-0">
                        <input type="text" placeholder="Enter Mailing Address" id="add_6_1245" formControlName="add_6_1245" class="form-control"> </div>
                  </div>
               </td>
               <td colspan="6">
                  <div class="row">
                     <div class="col-3">
                        <label for="add_6_1246">City/State/Zip:</label>
                     </div>
                     <div class="col-3">
                        <input type="text" placeholder="Enter City" id="add_6_1246" formControlName="add_6_1246" class="form-control"> </div>
                     <div class="col-3">
                        <ng-select [items]="states" [class.is-invalid]="(WpaForms1.controls['add_6_1248'].touched &&
                       WpaForms1.controls['add_6_1248'].invalid) ||
                       (WpaForms1.controls['add_6_1248'].invalid && isSubmit)" bindLabel="name" placeholder="Enter state" bindValue="id" formControlName="add_6_1248"> </ng-select>
                     </div>
                     <div class="col-3">
                        <input type="text" placeholder="Enter Zip Code" id="add_6_1249" formControlName="add_6_1249" class="form-control"> </div>
                  </div>
               </td>

            </tr>
            <tr class="col-12">
               <td colspan="6">
                  <div class="row">
                     <div class="col-6 pr-0">
                        <label for="add_6_1254">Phone Number:</label>
                     </div>
                     <div class="col-6 pl-0">
                        <input type="text" placeholder="Enter Phone Number" id="add_6_1254" formControlName="add_6_1254" class="form-control"> <small class="text-danger" *ngIf="WpaForms1.controls['add_6_1254'].errors?.pattern">
                       Only number are allowed</small> </div>
                  </div>
               </td>
               <td colspan="6">
                  <div class="row">
                     <div class="col-6 pr-0">
                        <label for="add_6_1255">Fax Number:</label>
                     </div>
                     <div class="col-6 pl-0">
                        <input type="text" placeholder="Enter Fax Number" id="add_6_1255" formControlName="add_6_1255" class="form-control"> </div>
                  </div>
               </td>
            </tr>
            <tr class="col-12">
               <td colspan="3">
                  <div class="row">
                     <div class="col-6 pr-0">
                        <label for="add_6_1264">Per claim amount: $</label>
                     </div>
                     <div class="col-6 pl-0">
                        <ng-select [items]="preClaim" [class.is-invalid]="(WpaForms1.controls['add_6_1264'].touched &&
                       WpaForms1.controls['add_6_1264'].invalid) ||
                       (WpaForms1.controls['add_6_1264'].invalid && isSubmit)" bindLabel="name" placeholder="Enter claim amount" bindValue="id" formControlName="add_6_1264"> </ng-select>
                     </div>
                  </div>
               </td>
               <td colspan="3">
                  <div class="row">
                     <div class="col-6 pr-0">
                        <label for="add_6_1265">Aggregate amount: $</label>
                     </div>
                     <div class="col-6 pl-0">
                        <ng-select [items]="aggregates" bindLabel="name" placeholder="Enter aggregate amount" bindValue="id" formControlName="add_6_1265"> </ng-select>
                     </div>
                  </div>
               </td>
               <td colspan="6">
                  <div class="row">
                     <div class="col-6">
                        <label for="add_6_1252">Date Began (mm/yyyy):</label>
                     </div>
                     <div class="col-6 d-flex">
                        <input type="text" placeholder="Enter Effective Date" id="add_6_1252" (blur)="clearInputIfInvalid('add_6_1252')" formControlName="add_6_1252" class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker #wpa88="ngbDatepicker">
                        <div class="input-group-append">
                           <button class="btn btn-outline-secondary calendar" (click)="wpa88.toggle()" type="button"> <i class="fas fa-calendar-alt"></i> </button>
                        </div>
                     </div>
                     <div class="col-6"></div>
                     <div class="col-6">
                       <div  *ngIf="WpaForms1.get('add_6_1252').invalid">
                        <small class="text-danger" *ngIf="WpaForms1.get('add_6_1252').errors?.invalidDateFormat">
                          Only Date Format is allowed(MM/DD/YYYY)                    </small>
                        <small class="text-danger" *ngIf="WpaForms1.get('add_6_1252').errors?.invalidMonth">
                        Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="WpaForms1.get('add_6_1252').errors?.invalidDateyear">
                          year is out of range(1900-2099)                      </small>
                          <small class="text-danger" *ngIf="WpaForms1.get('add_6_1252').errors?.invalidDateRange">
                           date is out of range
                           </small>
                        </div>
                     </div>
                     <div class="col-6">
                        <label for="add_6_1253">Expiration Date (mm/yyyy):</label>
                     </div>
                     <div class="col-6 d-flex">
                        <input type="text" placeholder="Enter Expiration Date" id="add_6_1253" formControlName="add_6_1253" (blur)="clearInputIfInvalid('add_6_1253')" class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker #wpa89="ngbDatepicker">
                        <div class="input-group-append">
                           <button class="btn btn-outline-secondary calendar" (click)="wpa89.toggle()" type="button"> <i class="fas fa-calendar-alt"></i> </button>
                        </div>
                     </div>
                     <div class="col-6"></div>
                     <div class="col-6">
                       <div  *ngIf="WpaForms1.get('add_6_1253').invalid">
                        <small class="text-danger" *ngIf="WpaForms1.get('add_6_1253').errors?.invalidDateFormat">
                          Only Date Format is allowed(MM/DD/YYYY)                    </small>
                        <small class="text-danger" *ngIf="WpaForms1.get('add_6_1253').errors?.invalidMonth">
                        Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="WpaForms1.get('add_6_1253').errors?.invalidDateyear">
                          year is out of range(1900-2099)                      </small>
                          <small class="text-danger" *ngIf="WpaForms1.get('add_6_1253').errors?.invalidDateRange">
                           date is out of range
                           </small>
                        </div>
                     </div>
                  </div>
               </td>

            </tr>

         </div>
        <div>
          <label class="table table-bordered" style="border: 2px solid black;">
              <h6>WASHINGTON PRACTITIONER ATTESTATION QUESTIONS - To be completed by the practitioner</h6> </label>
          <label style="padding-left:10px">Please answer all of the following questions. If your answer to any of the following questions is 'Yes", provide details as specified on a separate sheet.<i>If you attach additional sheets, sign and
      date each sheet.</i></label>
      </div>
      <div class="table table-bordered"  style="table-layout:fixed;">
        <label class="table table-bordered" style="border: 2px solid black;">
            <h6>A. PROFESSIONAL SANCTIONS</h6> </label>
        <!-- <div style="visibility: collapse;">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    </div> -->
        <div class="col-12 row">
            <!-- <div class="col-1">
                <div>
                    <label>1</label>
                </div>
            </div> -->
            <!-- <div class="col-11"> -->
                    <label>1. Have you ever been, or are you now in the process of being denied, revoked, terminated, suspended, restricted, reduced, limited, sanctioned, placed on probation, monitored, or not renewed for any of the following? Or have you voluntarily or involuntarily relinquished, withdrawn, or failed to proceed with an application for any of the following in order to avoid an adverse action or to preclude an investigation or while under investigation relating to professional competence or conduct? </label>
            <!-- </div> -->
        </div>
        <div class="col-12 row">
            <!-- <div class="col-4">
      </div> -->
            <td class="col-10">
                <label>a. License to practice any profession in any jurisdiction</label>
            </td>
            <td class="col-2">
                <input type="Radio" value="1" name="cmd_329" formControlName="cmd_329" id="cmd_329">
                <label for="Yes">Yes</label>&nbsp;
                <input type="Radio" value="0" name="cmd_329" formControlName="cmd_329" id="cmd_329">
                <label for="No">No</label>
            </td>
        </div>
        <div class="col-12 row">
            <!-- <div class="col-1">
      </div> -->
            <td class="col-10">
                        <label for="9">b. Other professional registration or certification in any jurisdiction </label>
            </td>
            <td class="col-2">
                <input type="Radio" value="1" name="cmd_330" formControlName="cmd_330" id="cmd_330">
                <label for="Yes">Yes</label>&nbsp;
                <input type="Radio" value="0" name="cmd_330" formControlName="cmd_330" id="cmd_330">
                <label for="No">No</label>
            </td>
        </div>
        <div class="col-12 row">
            <!-- <div class="col-1">
      </div> -->
            <td class="col-10">
                        <label for="9">c. Specialty or subspecialty board certification </label>
            </td>
            <td class="col-2">
                <input type="Radio" value="1" name="cmd_331" formControlName="cmd_331" id="cmd_331">
                <label for="Yes">Yes</label>&nbsp;
                <input type="Radio" value="0" name="cmd_331" formControlName="cmd_331" id="cmd_331">
                <label for="No">No</label>
            </td>
        </div>
        <div class="col-12 row">
            <!-- <div class="col-1">
      </div> -->
            <td class="col-10">
                <label> d. Membership on any hospital medical staff </label>
            </td>
            <td class="col-2">
                <input type="Radio" value="1" name="cmd_332" formControlName="cmd_332" id="cmd_332">
                <label for="Yes">Yes</label>&nbsp;
                <input type="Radio" value="0" name="cmd_332" formControlName="cmd_332" id="cmd_332">
                <label for="No">No</label>
            </td>
        </div>
        <div class="col-12 row">
            <!-- <div class="col-1">
      </div> -->
            <td class="col-10">
                        <label for="9">e.  Clinical privileges at any facility, including hospitals, ambulatory surgical centers, skilled nursing facilities, etc. </label>
            </td>
            <td class="col-2">
                <input type="Radio" value="1" name="cmd_333" formControlName="cmd_333" id="cmd_333">
                <label for="Yes">Yes</label>&nbsp;
                <input type="Radio" value="0" name="cmd_333" formControlName="cmd_333" id="cmd_333">
                <label for="No">No</label>
            </td>
        </div>
        <div class="col-12 row">
            <!-- <div class="col-1">
      </div> -->
            <td class="col-10">
                        <label for="9">f.  Medicare, Medicaid, FDA, NIH (Office of Human Research Protection), governmental, national or international regulatory agency or any public program </label>
            </td>
            <td class="col-2">
                <input type="Radio" value="1" name="cmd_334" formControlName="cmd_334" id="cmd_334">
                <label for="Yes">Yes</label>&nbsp;
                <input type="Radio" value="0" name="cmd_334" formControlName="cmd_334" id="cmd_334">
                <label for="No">No</label>
            </td>
        </div>
        <div class="col-12 row">
            <!-- <div class="col-1">
      </div> -->
            <td class="col-10">
                        <label for="9">g. Professional society membership or fellowship </label>
            </td>
            <td class="col-2">
                <input type="Radio" value="1" name="cmd_335" formControlName="cmd_335" id="cmd_335">
                <label for="Yes">Yes</label>&nbsp;
                <input type="Radio" value="0" name="cmd_335" formControlName="cmd_335" id="cmd_335">
                <label for="No">No</label>
            </td>
        </div>
        <div class="col-12 row">
            <!-- <div class="col-1">
      </div> -->
            <td class="col-10">
                        <label for="9"> h. Participation/membership in an HMO, PPO, IPA, PHO, Health Plan or other entity </label>
            </td>
            <td class="col-2">
                <input type="Radio" value="1" name="cmd_336" formControlName="cmd_336" id="cmd_336">
                <label for="Yes">Yes</label>&nbsp;
                <input type="Radio" value="0" name="cmd_336" formControlName="cmd_336" id="cmd_336">
                <label for="No">No</label>
            </td>
        </div>
        <div class="col-12 row">
            <!-- <div class="col-1">
      </div> -->
            <td class="col-10">
                        <label for="9">i.  Academic Appointment </label>
            </td>
            <td class="col-2">
                <input type="Radio" value="1" name="cmd_337" formControlName="cmd_337" id="cmd_337">
                <label for="Yes">Yes</label>&nbsp;
                <input type="Radio" value="0" name="cmd_337" formControlName="cmd_337" id="cmd_337">
                <label for="No">No</label>
            </td>
        </div>
        <div class="col-12 row">
            <!-- <div class="col-1">
      </div> -->
            <td class="col-10">
                        <label>j. Authority to prescribe controlled substances (DEA or other authority) </label>
            </td>
            <td class="col-2">
                <input type="Radio" value="1" name="cmd_338" formControlName="cmd_338" id="cmd_338">
                <label for="Yes">Yes</label>&nbsp;
                <input type="Radio" value="0" name="cmd_338" formControlName="cmd_338" id="cmd_338">
                <label for="No">No</label>
            </td>
        </div>
        <div class="col-12 row">
            <!-- <div class="col-1">
                <label><b>2.</b></label>
            </div> -->
            <td class="col-10">
                <label>2. Have you ever been subject to review, challenges, and/or disciplinary action, formal or informal, by an ethics committee, licensing board, medical disciplinary board, professional association or education/training institution? </label>
            </td>
            <td class="col-2">
                <input type="Radio" value="1" name="cmd_339" formControlName="cmd_339" id="cmd_339">
                <label for="Yes">Yes</label>&nbsp;
                <input type="Radio" value="0" name="cmd_339" formControlName="cmd_339" id="cmd_339">
                <label for="No">No</label>
            </td>
        </div>
        <div class="col-12 row">
            <!-- <div class="col-1">
                <label><b>3.</b></label>
            </div> -->
            <td class="col-10">3. Have you been found by a state professional disciplinary board to have committed unprofessional conduct as defined in applicable state provisions?

            </td>
            <td class="col-2">
                <input type="Radio" value="1" name="cmd_340" formControlName="cmd_340" id="cmd_340">
                <label for="Yes">Yes</label>&nbsp;
                <input type="Radio" value="0" name="cmd_340" formControlName="cmd_340" id="cmd_340">
                <label for="No">No</label>
            </td>
        </div>
        <div class="col-12 row">
            <!-- <div class="col-1">
                <label><b>4.</b></label>
            </div> -->
            <td class="col-10">
                <label>4. Have you ever been the subject of any reports to a state, federal, national data bank, or state licensing or disciplinary entity? </label>
            </td>
            <td class="col-2">
                <input type="Radio" value="1" name="cmd_341" formControlName="cmd_341" id="cmd_341">
                <label for="Yes">Yes</label>&nbsp;
                <input type="Radio" value="0" name="cmd_341" formControlName="cmd_341" id="cmd_341">
                <label for="No">No</label>
            </td>
        </div>
    </div>

        <div class="table table-bordered" style="table-layout: fixed;">
          <label class="table table-bordered" style="border: 2px solid black;">
            <h6>B. CRIMINAL HISTORY</h6> </label>
          <!-- <tr style="visibility: collapse;">
                   <td></td>
                   <td></td>
                   <td></td>
                   <td></td>
                   <td></td>
                   <td></td>
                   <td></td>
                   <td></td>
                   <td></td>
                   <td></td>
                   <td></td>
                   <td></td>
                   </tr> -->
          <div class="col-12 row">
            <!-- <td class="col-1">
              <label><b>1.</b></label>
            </td> -->
            <td class="col-10">
              <label>1.  Have you ever been charged with a criminal violation (felony or misdemeanor) resulting in either a plea bargain, conviction on the original or lesser charge, or payment of a fine, suspended sentence, community service or other obligation? </label>
            </td>
            <td class="col-2">
              <input type="Radio" value="1" name="cmd_342" formControlName="cmd_342" id="cmd_342">
              <label for="Yes">Yes</label>&nbsp;
              <input type="Radio" value="0" name="cmd_342" formControlName="cmd_342" id="cmd_342">
              <label for="No">No</label>
            </td>
         </div>
          <div class="col-12 row">
            <!-- <td class="col-1"> </td> -->
            <td class="col-10">
              <label>a. Do you have notice of any such anticipated charges? </label>
            </td>
            <td class="col-2">
              <input type="Radio" value="1" name="cmd_343" formControlName="cmd_343" id="cmd_343">
              <label for="Yes">Yes</label>&nbsp;
              <input type="Radio" value="0" name="cmd_343" formControlName="cmd_343" id="cmd_343">
              <label for="No">No</label>
            </td>
         </div>
          <div class="col-12 row">
            <!-- <td class="col-1"> </td> -->
            <td class="col-10">
              <label>b. Are you currently under governmental investigation? </label>
            </td>
            <td class="col-2">
              <input type="Radio" value="1" name="cmd_344" formControlName="cmd_344" id="cmd_344">
              <label for="Yes">Yes</label>&nbsp;
              <input type="Radio" value="0" name="cmd_344" formControlName="cmd_344" id="cmd_344">
              <label for="No">No</label>
            </td>
         </div>
        </div>
        <div class="table table-bordered" style="table-layout: fixed;">
          <label class="table table-bordered" style="border: 2px solid black;">
            <h6>C. AFFIRMATION OF ABILITIES</h6> </label>
          <!-- <tr style="visibility: collapse;">
                   <td></td>
                   <td></td>
                   <td></td>
                   <td></td>
                   <td></td>
                   <td></td>
                   <td></td>
                   <td></td>
                   <td></td>
                   <td></td>
                   <td></td>
                   <td></td>
                   </tr> -->
          <div class="col-12 row">
            <!-- <td class="col-1">
              <label><b>1.</b></label>
            </td> -->
            <td class="col-10">
              <label> 1. Do you presently use any drugs illegally? </label>
            </td>
            <td class="col-2">
              <input type="Radio" value="1" name="cmd_345" class="margin_custom_class_yes" formControlName="cmd_345" id="cmd_345">
              <label for="Yes">Yes</label>&nbsp;
              <input type="Radio" value="0" name="cmd_345" formControlName="cmd_345" id="cmd_345">
              <label for="No">No</label>
            </td>
         </div>
          <div class="col-12 row">
            <!-- <td class="col-1">
              <label><b>2.</b></label>
            </td> -->
            <td class="col-10">
              <label> 2. Do you have, or have you had in the last five years, any physical condition, mental health condition, or chemical dependency condition (alcohol or other substance) that affects or will affect your current ability to practice with or without reasonable accommodation? If reasonable accommodation is required, specify the accommodations required.<u>If the answer to this question is yes,</u> please identify and describe any rehabilitation program in which you are or were enrolled which assures your ability to adhere to prevailing standards of professional performance. </label>
            </td>
            <td class="col-2">
              <input type="Radio" value="1" name="cmd_346" class="margin_custom_class_yes" formControlName="cmd_346" id="cmd_346">
              <label for="Yes">Yes</label>&nbsp;&nbsp;&nbsp;&nbsp;
              <input type="Radio" value="0" class="margin_custom_class_no" name="cmd_346" formControlName="cmd_346" id="cmd_346">
              <label for="No">No</label>
            </td>
         </div>
          <div class="col-12 row">
            <!-- <td class="col-1">
              <label><b>3.</b></label>
            </td> -->
            <td class="col-10">
              <label> 3. Are you unable to perform any of the services/clinical privileges required by the applicable participating practitioner agreement/hospital agreement, with or without reasonable accommodation, according to accepted standards of professional performance? </label>
            </td>
            <td class="col-2">
              <input type="Radio" value="1" name="cmd_347" class="margin_custom_class_yes" formControlName="cmd_347" id="cmd_347">
              <label for="Yes">Yes</label>&nbsp;&nbsp;&nbsp;&nbsp;
              <input type="Radio" value="0" name="cmd_347" class="margin_custom_class_no" formControlName="cmd_347" id="cmd_347">
              <label for="No">No</label>
            </td>
         </div>
        </div>
        <div class="table table-bordered" style="table-layout: fixed;">
          <label class="table table-bordered" style="border: 2px solid black;">
            <h6>D. LITIGATION AND MALPRACTICE COVERAGE HISTORY (If you answer "Yes" to any of the questions in this
              section,</h6>
            <span>please document in Section XXI. PROFESSIONAL LIABILITY ACTION DETAIL of this
              application.)</span>
          </label>
          <!-- <tr style="visibility: collapse;">
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr> -->
          <tr>
            <!-- <td colspan="1">
              <div class="row">
                 <label><b>1.</b></label>
              </div>
            </td> -->
            <td colspan="6">
              <div class="row ml-1">
                  <label>
                  1. Have allegations or claims of professional negligence been made against you at any time, whether
                    or
                    not you were individually named in the claim or lawsuit?
                  </label>
              </div>
            </td>
            <td colspan="6" class="custom_class">
              <div class="row ml-1">
                <input type="radio" value="1"  name="cmd_348" formControlName="cmd_348" id="cmd_348">
                <label for="d3">Yes</label>
                <input type="radio" value="0"  name="cmd_348" formControlName="cmd_348" id="cmd_348">
                <label for="d4">No</label>
              </div>
            </td>
          </tr>
          <tr>
            <!-- <td colspan="1">
              <div class="row">
                <label><b>2.</b></label>
              </div>
            </td> -->
            <td colspan="6">
              <div class="row ml-1">
                  <label>
                    2. Have you or your insurance carrier(s) ever paid any money on your behalf to settle/resolve a
                    professional malpractice claim (not necessarily a lawsuit) and/or to satisfy a judgement
                    (courtordered damage award) in a professional lawsuit?
                  </label>
              </div>
            </td>
            <td colspan="6" class="custom_class">
              <div class="row ml-1">
                <input type="radio" value="1"  name="cmd_349" formControlName="cmd_349" id="cmd_349">
                <label for="d5">Yes</label>
                <input type="radio" value="0" name="cmd_349" formControlName="cmd_349" id="cmd_349">
                <label for="d6">No</label>
              </div>
            </td>
          </tr>
          <tr>
            <!-- <td colspan="1">
              <div class="row">
                 <label for="9"><b>3.</b></label>
              </div>
            </td> -->
            <td colspan="6">
              <div class="row ml-1">
                  <label>
                   3.  Are there any such claims being asserted against you now?
                  </label>
              </div>
            </td>
            <td colspan="6" class="custom_class">
              <div class="row ml-1">
                <input type="radio"  value="1" name="cmd_350" formControlName="cmd_350" id="cmd_350">
                <label for="d1">Yes</label>
                <input type="radio" value="0" name="cmd_350" formControlName="cmd_350" id="cmd_350">
                <label for="d2">No</label>
              </div>
            </td>
          </tr>
          <tr>
            <!-- <td colspan="1">
              <div class="row ">
                 <label for="9"><b>4.</b></label>
              </div>
            </td> -->
            <td colspan="6">
              <div class="row ml-1">
                  <label>
                    4. Have you ever been denied professional liability coverage or has your coverage ever been
                    terminated, not renewed, restricted, or modified (e.g. reduced limits, restricted coverage,
                    surcharged)?
                  </label>
              </div>
            </td>
            <td colspan="6" class="custom_class">
              <div class="row ml-1">
                <input type="radio" value="1" name="cmd_351" formControlName="cmd_351" id="cmd_351">
                <label for="Yes">Yes</label><br>
                <input type="radio" value="0" name="cmd_351" formControlName="cmd_351" id="cmd_351">
                <label for="No">No</label>
              </div>
            </td>
          </tr>
          <tr>
            <!-- <td colspan="1">
              <div class="row">
                 <label for="9"><b>5.</b></label>
              </div>
            </td> -->
            <td colspan="6">
              <div class="row ml-1">
                  <label>
                    5. Are any of the privileges that you are requesting not covered by your current malpractice
                    coverage?
                  </label>
              </div>
            </td>
            <td colspan="6" class="custom_class">
              <div class="row ml-1">
                <input type="radio" value="1" name="cmd_352" formControlName="cmd_352" id="cmd_352">
                <label for="Yes">Yes</label><br>
                <input type="radio" value="0" name="cmd_352" formControlName="cmd_352" id="cmd_352">
                <label for="No">No</label>
              </div>
            </td>
          </tr>
        </div>
        <div>
          <tr>
            <td colspan="12">
              <div class="row">
                 <label for="9" style="padding-left:20px">
                    I warrant that all the statements made on this form and on any attached information sheets are
                    complete, accurate, and current. I
                    understand that any material misstatements in, or omissions from, this statement constitute
                    cause for denial of membership or cause
                    for summary dismissal from the entity to which this statement has been submitted.
                  </label>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="4">
              <div class="row">
                <div class="col-6 pr-0"> <label for="9">Applicant's Signature:</label></div>
                <div class="col-6 pl-0">
                  <img *ngIf="!showOne" class="img" src="./assets/images/e_sign.png" alt="Credential my Doc"
                    (click)="esignOpenOne()" />
                  <img *ngIf="showOne" class="img eSignImageShow" [src]="esignOne" alt="Credential my Doc"
                    (click)="esignOpenOne()" /><br><span>(Stamped signature is not acceptable)
                  </span>
                  <input type="hidden" placeholder="Enter Provider Signature" name="cmd_353"
                    value="{{finalImgPath}}" id="cmd_353" class="form-control" formControlName="cmd_353"
                    [class.is-invalid]="(WpaForms1.controls['cmd_353'].touched && WpaForms1.controls['cmd_353'].invalid ) || (WpaForms1.controls['cmd_353'].invalid && isSubmit)">
                  <div class="invalid-feedback"
                    *ngIf="(WpaForms1.controls['cmd_353'].touched && WpaForms1.controls['cmd_353'].invalid) || (WpaForms1.controls['cmd_353'].invalid && isSubmit)">
                    Provider Signature is required and cannot be empty
                  </div>
                </div>
              </div>
            </td>
            <td colspan="4">
              <div class="row">
                <div class="col-6 pr-0"> <label for="9">Date:</label></div>
                <div class="col-6 pl-0 d-flex">
                  <input type="text" placeholder="Enter Date" id="cmd_354" (blur)="clearInputIfInvalid('cmd_354')" formControlName="cmd_354" class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker  #wpa91="ngbDatepicker">
                  <div class="input-group-append" >
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="wpa91.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
                <div class="col-6"></div>
                <div class="col-6">
                  <div  *ngIf="WpaForms1.get('cmd_354').invalid">
                   <small class="text-danger" *ngIf="WpaForms1.get('cmd_354').errors?.invalidDateFormat">
                     Only Date Format is allowed(MM/DD/YYYY)                    </small>
                   <small class="text-danger" *ngIf="WpaForms1.get('cmd_354').errors?.invalidMonth">
                   Month is out of range
                   </small>
                   <small class="text-danger" *ngIf="WpaForms1.get('cmd_354').errors?.invalidDateyear">
                     year is out of range(1900-2099)                      </small>
                     <small class="text-danger" *ngIf="WpaForms1.get('cmd_354').errors?.invalidDateRange">
                      date is out of range
                      </small>
                   </div>
                </div>
              </div>
            </td>
            <td colspan="4">
              <div class="row">
                <div class="col-6 pr-0"> <label for="9">Type or Print name here:</label></div>
                <div class="col-6 pl-0">
                  <input type="text" placeholder="Enter Type" id="cmd_33553" formControlName="cmd_33553"
                    class="form-control">
                </div>
              </div>
            </td>
          </tr>
        </div>
        <div class="table table-bordered">
          <tr>
             <td colspan="10">
                <label for="9">
                   <h6>22. PROFESSIONAL LIABILITY ACTION DETAIL – CONFIDENTIAL </h6>
                </label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <span class="doesnotapply">
                  <label for="cmd_1007"><b>Does Not Apply:</b></label>&nbsp;&nbsp;
                  <input type="checkbox" value="1" formControlName="cmd_59" id="cmd_59">
                  </span>
             </td>
             <!-- <td colspan="2">
                <div class="row">
                   <label for="cmd_01007"><b>Does Not Apply:</b></label>&nbsp;&nbsp;
                   <input type="checkbox" value="1" formControlName ="cmd_01007" id="cmd_01007">
                </div>
             </td> -->
          </tr>
          <tr>
             <td colspan="12">
                <div class="row">
                   <div class="col-8 pr-0"> <label for="9">Practitioner Name:(print or type):</label></div>
                   <div class="col-4 pl-0">
                      <input type="text" placeholder="Enter Practitioner Name" id="cmd_356" formControlName ="cmd_356" class="form-control">
                   </div>
                </div>
             </td>
          </tr>
          <tr>
             <td colspan="12">
                <div class="col-md-12">
                   <label>
                     Please list any past or current professional liability claim(s) or lawsuit(s), in which allegations of professional
                     negligence were made against you, whether or not you were individually named in the claim or lawsuit.<u> Please do
                     not include patient names or other HIPAA protected PHI.</u> Photocopy this page as needed and submit a separate
                     page for EACH claim/event. A legible signed practitioner narrative that addresses all of the following details is an acceptable alternative.
                   </label>
                </div>
             </td>
          </tr>
          <tr>
             <td colspan="12">
                <div class="row">
                   <div class="col-6 pr-0"> <label for="9">Date and clinical details of the incident, with preceding events:</label></div>
                </div>
                <div class="form-inline d-flex">
                   <label for="1208">Date:</label>
                   <input type="text" placeholder="Enter Date of occurance" class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker  #wpa92="ngbDatepicker"
                   formControlName ="1208" (blur)="clearInputIfInvalid('1208')" id="1208">
                   <div class="input-group-append" >
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="wpa92.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
                <div class="col-6"></div>
									<div class="col-6">
									  <div  *ngIf="WpaForms1.get('1208').invalid">
										<small class="text-danger" *ngIf="WpaForms1.get('1208').errors?.invalidDateFormat">
										  Only Date Format is allowed(MM/DD/YYYY)                    </small>
										<small class="text-danger" *ngIf="WpaForms1.get('1208').errors?.invalidMonth">
										Month is out of range
										</small>
										<small class="text-danger" *ngIf="WpaForms1.get('1208').errors?.invalidDateyear">
										  year is out of range(1900-2099)                      </small>
										  <small class="text-danger" *ngIf="WpaForms1.get('1208').errors?.invalidDateRange">
											date is out of range
											</small>
										</div>
									</div>
                <br>
                <div class="form-inline">
                   <label for="6">Details:</label>
                   <textarea id="1217" name="wpaarea" row="30" formControlName ="1217"  cols="30"></textarea>
                </div>
             </td>
          </tr>
          <tr>
             <td colspan="12">
                <div class="row">
                   <div class="col-6 pr-0"> <label for="9">Your role and specific responsibility in the incident:</label></div>
                   <textarea id="1218" name="wpaarea" row="30"  formControlName ="1218"  cols="30"></textarea>
                </div>
             </td>
          </tr>
          <tr>
             <td colspan="12">
                <div class="row">
                   <div class="col-6 pr-0"> <label for="9">Subsequent events, including patient’s clinical outcome:</label></div>
                   <textarea id="1214" name="wpaarea" row="30" formControlName ="1214"  cols="30"></textarea>
                </div>
             </td>
          </tr>
          <tr>
             <td colspan="12">
                <div class="row">
                   <div class="col-8 pr-0"> <label for="9">Date suit or claim was filed:</label></div>
                   <div class="col-4 pl-0 d-flex">
                      <input type="text" placeholder="Enter Date Suit" id="1209" (blur)="clearInputIfInvalid('1209')" formControlName ="1209" class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker  #wpa93="ngbDatepicker"
                      >
                      <div class="input-group-append" >
                        <button
                          class="btn btn-outline-secondary calendar"
                          (click)="wpa93.toggle()"
                          type="button"
                        >
                          <i class="fas fa-calendar-alt"></i>
                        </button>
                      </div>
                   </div>
                   <div class="col-8"></div>
									<div class="col-4">
									  <div  *ngIf="WpaForms1.get('1209').invalid">
										<small class="text-danger" *ngIf="WpaForms1.get('1209').errors?.invalidDateFormat">
										  Only Date Format is allowed(MM/DD/YYYY)                    </small>
										<small class="text-danger" *ngIf="WpaForms1.get('1209').errors?.invalidMonth">
										Month is out of range
										</small>
										<small class="text-danger" *ngIf="WpaForms1.get('1209').errors?.invalidDateyear">
										  year is out of range(1900-2099)                      </small>
										  <small class="text-danger" *ngIf="WpaForms1.get('1209').errors?.invalidDateRange">
											date is out of range
											</small>
										</div>
									</div>
                </div>
             </td>
          </tr>
          <tr>
             <td colspan="12">
                <div class="row">
                   <div class="col-8 pr-0"> <label for="1210">Name and Address of Insurance Carrier that handled the claim:</label></div>
                   <div class="col-4 pl-0">
                      <input type="text" placeholder="Enter Insurance Carrier" id="1210" formControlName ="1210"  class="form-control">
                   </div>
                </div>
             </td>
          </tr>
          <tr>
             <td colspan="6">
                <div class="row">
                   <div class="col-3 pr-0"></div>
                   <div class="col-3 pl-0">
                      <input type="text" placeholder="Insurance Carrier Address 1" id="2012" formControlName ="2012" class="form-control">
                   </div>
                   <div class="col-3 pl-0">
                      <input type="text" placeholder="Insurance Carrier Address 2" id="1844" formControlName ="1844"  class="form-control">
                   </div>
                </div>
             </td>
             <td colspan="6">
                <div class="row">
                   <div class="col-3"> </div>
                   <div class="col-3 pl-0">
                      <input type="text" placeholder="Enter City" id="1845" formControlName ="1845" class="form-control">
                   </div>
                   <div class="col-3 pl-0">
                      <ng-select
                      [items]="states"
                      [class.is-invalid]="(WpaForms1.controls['1847'].touched &&
                      WpaForms1.controls['1847'].invalid) ||
                      (WpaForms1.controls['1847'].invalid && isSubmit)"
                      bindLabel="name"
                      placeholder="Enter state"
                      bindValue="id"
                      formControlName="1847"
                      >
                      </ng-select>
                   </div>
                   <div class="col-3 pl-0">
                      <input type="text" placeholder="Enter Zip" id="1848" formControlName ="1848"  class="form-control">
                   </div>
                </div>
             </td>
          </tr>
          <tr>
             <td colspan="12">
                <div class="row">
                   <div class="col-8 pr-0"> <label for="1213">Your status in the legal action (primary defendant, co-defendant, other):</label></div>
                   <div class="col-4 pl-0">
                      <input type="text" placeholder="Enter Status" id="1213" formControlName ="1213"  class="form-control">
                   </div>
                </div>
             </td>
          </tr>
          <tr>
             <td colspan="12">
                <div class="row">
                   <div class="col-8 pr-0"> <label for="1858">Current status of suit or other action:</label></div>
                   <div class="col-4 pl-0">
                      <input type="text" placeholder="Enter Claim Status" id="1858" formControlName ="1858"  class="form-control">
                   </div>
                </div>
             </td>
          </tr>
          <tr>
             <td colspan="12">
                <div class="row">
                   <div class="col-8 pr-0"> <label for="1226">Date of settlement, judgment, or dismissal:</label></div>
                   <div class="col-4 pl-0 d-flex">
                      <input type="text" placeholder="Enter Date " id="1226" formControlName ="1226" (blur)="clearInputIfInvalid('1226')" class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker  #wpa94="ngbDatepicker"
                      >
                      <div class="input-group-append" >
                        <button
                          class="btn btn-outline-secondary calendar"
                          (click)="wpa94.toggle()"
                          type="button"
                        >
                          <i class="fas fa-calendar-alt"></i>
                        </button>
                      </div>
                   </div>
                   <div class="col-8"></div>
                   <div class="col-4">
                     <div  *ngIf="WpaForms1.get('1226').invalid">
                      <small class="text-danger" *ngIf="WpaForms1.get('1226').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)                    </small>
                      <small class="text-danger" *ngIf="WpaForms1.get('1226').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="WpaForms1.get('1226').errors?.invalidDateyear">
                        year is out of range(1900-2099)                      </small>
                        <small class="text-danger" *ngIf="WpaForms1.get('1226').errors?.invalidDateRange">
                         date is out of range
                         </small>
                      </div>
                   </div>
                </div>
             </td>
          </tr>
          <tr>
             <td colspan="12">
                <div class="row">
                   <div class="col-8 pr-0"> <label for="1228">If case was settled out-of-court, or with a judgment, settlement amount attributed to you? $</label></div>
                   <div class="col-4 pl-0">
                      <input type="text" placeholder="Enter Total Amount" id="1228" formControlName ="1228"  class="form-control">
                   </div>
                </div>
             </td>
          </tr>
       </div>
       <div class="table table-bordered" style="table-layout: fixed;">
          <label class="table table-bordered" style="border: 2px solid black;">
             <h6>23. ATTESTATION</h6>
          </label>
          <tr>
             <td colspan="12">
                <div class="form-inline">
                   <label for="1">
                   I certify the information in this entire application is complete, accurate, and current. I acknowledge that any misstatements in or
                   omissions from this application constitute cause for denial of membership or cause for summary dismissal from the entity to
                   which this statement has been made. A photocopy of this application has the same force and effect as the original. I have
                   reviewed this information as of the most recent date listed below.
                   </label>
                </div>
             </td>
          </tr>
          <tr>
             <td  colspan="12">
                <div class="row">
                   <div class="col-6 pr-0"> <label for="9">Print name here:</label></div>
                   <div class="col-6 pl-0">
                      <input type="text" placeholder="Enter Name" id="cmd_43554" formControlName ="cmd_43554"  class="form-control">
                   </div>
                   <br>
                   <div class="col-6 pr-0"> <label for="9"> Signature:</label></div>
                   <div class="col-6 pl-0">
                      <img
                      *ngIf="!showTwo"
                      class="img"
                      src="./assets/images/e_sign.png"
                      alt="Credential my Doc"
                      (click)="esignOpenTwo()"
                      />
                      <img
                      *ngIf="showTwo"
                      class="img eSignImageShow"
                      [src]="esignTwo"
                      alt="Credential my Doc"
                      (click)="esignOpenTwo()"
                      /><br><span>(Stamped signature is not acceptable)
                      </span>
                      <input type="hidden" placeholder="Enter Provider Signature" name="cmd_355" value ="{{finalImgPathTwo}}"id="cmd_355" class="form-control" formControlName="cmd_355"
                         [class.is-invalid]="(WpaForms1.controls['cmd_355'].touched && WpaForms1.controls['cmd_355'].invalid ) || (WpaForms1.controls['cmd_355'].invalid && isSubmit)">
                      <div class="invalid-feedback"
                         *ngIf="(WpaForms1.controls['cmd_355'].touched && WpaForms1.controls['cmd_355'].invalid) || (WpaForms1.controls['cmd_355'].invalid && isSubmit)">
                         Provider Signature is required and cannot be empty
                      </div>
                   </div>
                   <div class="col-6 pr-0"> <label for="9">Date:</label></div>
                   <div class="col-6 pl-0 d-flex">
                      <input type="text" placeholder="Enter Date" id="cmd_10354" formControlName ="cmd_10354" (blur)="clearInputIfInvalid('cmd_10354')" class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker  #wpa95="ngbDatepicker"
                      >
                      <div class="input-group-append" >
                        <button
                          class="btn btn-outline-secondary calendar"
                          (click)="wpa95.toggle()"
                          type="button"
                        >
                          <i class="fas fa-calendar-alt"></i>
                        </button>
                      </div>
                   </div>
                   <div class="col-6"></div>
									<div class="col-6">
									  <div  *ngIf="WpaForms1.get('cmd_10354').invalid">
										<small class="text-danger" *ngIf="WpaForms1.get('cmd_10354').errors?.invalidDateFormat">
										  Only Date Format is allowed(MM/DD/YYYY)                    </small>
										<small class="text-danger" *ngIf="WpaForms1.get('cmd_10354').errors?.invalidMonth">
										Month is out of range
										</small>
										<small class="text-danger" *ngIf="WpaForms1.get('cmd_10354').errors?.invalidDateyear">
										  year is out of range(1900-2099)                      </small>
										  <small class="text-danger" *ngIf="WpaForms1.get('cmd_10354').errors?.invalidDateRange">
											date is out of range
											</small>
										</div>
									</div>
                   <div class="col-6 pr-0"> <label for="9"><b>Review dates and initials:</b></label></div>
                   <div class="col-6 pl-0">
                      <textarea id="cmd_0354" name="wpaarea" row="90"  formControlName ="cmd_0354" cols="70"></textarea>
                   </div>
                </div>
             </td>
          </tr>
          <tr>
             <div class="row">
                <div class="col-6 pr-0"> <label for="9">Healthcare Organization:</label></div>
                <div class="col-6 pl-0">
                   <input type="text" placeholder="Enter Organization" id="cmd_0355" formControlName ="cmd_0355"  class="form-control">
                </div>
                <div class="col-6 pr-0"> <label for="9">And/or Designated Agent:</label></div>
                <div class="col-6 pl-0">
                   <input type="text" placeholder="Enter Designated Agent" id="cmd_0356" formControlName ="cmd_0356"  class="form-control">
                </div>
             </div>
          </tr>
          <div class="table table-bordered" style="table-layout: fixed;">
            <div class="centered" style="text-align:center;width:100%; margin: 0 auto;"> 
               <label class="table table-bordered" style="border: 2px solid black;">
                  <h6>WASHINGTON PRACTITIONER APPLICATION AUTHORIZATION AND RELEASE OF INFORMATION FORM</h6>
                  <b><i>Modified Releases Will Not Be Accepted</i></b>
               </label>
              
               
            </div>   
             <tr>
                <td colspan="12">
                   <div>
                      <label for="5">
                      By submitting this authorization and release of information form in conjunction with the Washington Practitioner Application (WPA) and/or the
                      Washington Practitioner Attestation or Credentials Update (CU) form, I understand and agree as follows:
                      </label>
                   </div>
                </td>
             </tr>
             <tr>
                <td colspan="1">
                   <label for="cmd_1111">1.</label>
                </td>
                <td colspan="11">
                   <label for="9">
                   I understand and acknowledge that, as an applicant for medical staff membership and/or participating status with the Healthcare
                   Organization(s)* indicated on the WPA for initial credentialing or recredentialing, I have the burden of producing adequate information for
                   proper evaluation of my competence, character, ethics, mental and physical health status, and or other qualifications in a timely manner. I
                   understand that the application will not be processed until the application is deemed complete by the healthcare organization
                   </label>
                </td>
             </tr>
             <tr>
                <td colspan="1">
                   <label for="cmd_1111">2.</label>
                </td>
                <td colspan="11">
                   <label for="9">
                   I further understand and acknowledge that the Healthcare Organization(s) or designated agent will investigate the information in this
                   application. By submitting this application, I agree to such investigation and to information exchange activities of the Healthcare
                   Organization(s) as part of the verification and credentialing process.
                   </label>
                </td>
             </tr>
             <tr>
                <td colspan="1">
                   <label for="cmd_1111">3.</label>
                </td>
                <td colspan="11">
                   <label for="9">
                   I authorize all individuals, institutions and entities or organizations with which I am currently or have been associated and all professional
                   liability insurers with which I have had or currently have professional liability insurance, who may have information bearing on my
                   professional qualifications, ethical standing, competence, and mental and physical health status to release the aforementioned information
                   to the designated Healthcare Organization(s), their staffs and agents.
                   </label>
                </td>
             </tr>
             <tr>
                <td colspan="1">
                   <label for="cmd_1111">4.</label>
                </td>
                <td colspan="11">
                   <label for="9">
                   I consent to the inspection of records and documents that may be material to an evaluation of qualifications and my ability to carry out the
                   clinical privileges or provide services I request. I authorize each and every individual and organization in custody of such records and
                   documents to permit such inspection and copying. I am willing to make myself available for interviews if required or requested.
                   </label>
                </td>
             </tr>
             <tr>
                <td colspan="1">
                   <label for="cmd_1111">5.</label>
                </td>
                <td colspan="11">
                   <label for="9">
                   I release from any liability, to the fullest extent permitted by law, all persons for their acts performed in a reasonable manner in conjunction
                   with providing information, investigating and evaluating my application and qualifications, and I waive all legal claims against any
                   representative of the Healthcare Organization(s) or their respective agent(s) who act in good faith and without malice in connection with the investigation of this application.
                   </label>
                </td>
             </tr>
             <tr>
                <td colspan="1">
                   <label for="cmd_1111">6.</label>
                </td>
                <td colspan="11">
                   <label for="9">
                   I acknowledge that I have been informed of, and hereby agree to abide by, the bylaws, rules, regulations, contractual agreements, and policies of the Healthcare Organization.
                   </label>
                </td>
             </tr>
             <tr>
                <td colspan="1">
                   <label for="cmd_1111">7.</label>
                </td>
                <td colspan="11">
                   <label for="9">
                   I acknowledge that I am responsible for notifying the Healthcare Organization of any changes/challenges to licensure, DEA, malpractice claims, criminal convictions, hospital privileges or other disciplinary actions
                   </label>
                </td>
             </tr>
             <tr>
                <td colspan="1">
                   <label for="cmd_1111">8.</label>
                </td>
                <td colspan="11">
                   <label for="9">
                   I attest to the accuracy, currency and completeness of the information provided. I understand and agree that any misstatements in or
                   omissions from the CU, WPA, Washington Practitioner Attestation and attachments hereto may constitute cause for denial of the application or summary dismissal or termination of membership/clinical privileges/participation agreement.
                   </label>
                </td>
             </tr>
             <tr>
                <td colspan="1">
                   <label for="cmd_1111">9.</label>
                </td>
                <td colspan="11">
                   <label for="9">
                   I agree to exhaust all available procedures and remedies as outlined in the bylaws, rules, regulations, and policies, and/or contractual
                   agreements of the Healthcare Organization(s) where I have membership and/or clinical privileges/participation status before initiating judicial action.
                   </label>
                </td>
             </tr>
             <tr>
                <td colspan="1">
                   <label for="cmd_1111">10.</label>
                </td>
                <td colspan="11">
                   <label for="9">
                   I understand that completion and submission of the Authorization and Release does not automatically grant me membership or clinical
                   privileges/participating status with the Healthcare Organization(s)* indicated on the WPA/CU or Attestation.
                   </label>
                </td>
             </tr>
             <tr>
                <td colspan="1">
                   <label for="cmd_1111">11.</label>
                </td>
                <td colspan="11">
                   <label for="9">
                   I hereby further authorize and consent to the release of information and/or reporting by the Healthcare Organization(s) to medical
                   associations, licensing boards, the National Practitioner Data Bank, the Healthcare Integrity and Protection Data Bank, and other similar
                   organizations regarding any pertinent information which the Healthcare Organization(s) may have concerning me as long as such release
                   of information and/or reporting is done in good faith and without malice, and I hereby release from liability Healthcare Organization(s) and
                   its staff and representatives for so doing.
                   </label>
                </td>
             </tr>
             <tr>
                <td colspan="1">
                   <label for="cmd_1111">12.</label>
                </td>
                <td colspan="11">
                   <label for="9">
                   I further acknowledge that I have read and understand the foregoing Authorization and Release. A photocopy of this Authorization and
                   Release shall be as effective as the original and authorization constitutes my written authorization and request to communicate any
                   relevant information and to release any and all supportive documentation regarding this application/attestation.
                   </label>
             <tr>
                <div class="row">
                   <div class="col-6 pr-0"> <label for="9">Print name here:</label></div>
                   <div class="col-6 pl-0">
                      <input type="text" placeholder="Enter Name" id="cmd_03550" formControlName ="cmd_03550"  class="form-control">
                   </div>
                   <br>
                   <div class="col-6 pr-0"> <label for="9"> Signature:</label></div>
                   <div class="col-6 pl-0">
                      <img
                      *ngIf="!showThree"
                      class="img"
                      src="./assets/images/e_sign.png"
                      alt="Credential my Doc"
                      (click)="esignOpenThree()"
                      />
                      <img
                      *ngIf="showThree"
                      class="img eSignImageShow"
                      [src]="esignThree"
                      alt="Credential my Doc"
                      (click)="esignOpenThree()"
                      /><br><span>(Stamped signature is not acceptable)
                      </span>
                      <input type="hidden" placeholder="Enter Provider Signature" name="cmd_370" value ="{{finalImgPathThree}}"id="cmd_370" class="form-control" formControlName="cmd_370"
                         [class.is-invalid]="(WpaForms1.controls['cmd_370'].touched && WpaForms1.controls['cmd_370'].invalid ) || (WpaForms1.controls['cmd_370'].invalid && isSubmit)">
                   </div>
                   <div class="col-6 pr-0"> <label for="9">Date:</label></div>
                   <div class="col-6 pl-0 d-flex">
                      <input type="text" placeholder="Enter Date" id="cmd_20354" (blur)="clearInputIfInvalid('cmd_20354')" formControlName ="cmd_20354" class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker  #wpa96="ngbDatepicker"
                      >
                      <div class="input-group-append" >
                        <button
                          class="btn btn-outline-secondary calendar"
                          (click)="wpa96.toggle()"
                          type="button"
                        >
                          <i class="fas fa-calendar-alt"></i>
                        </button>
                      </div>
                   </div>
                   <div class="col-6"></div>
									<div class="col-6">
									  <div  *ngIf="WpaForms1.get('cmd_20354').invalid">
										<small class="text-danger" *ngIf="WpaForms1.get('cmd_20354').errors?.invalidDateFormat">
										  Only Date Format is allowed(MM/DD/YYYY)                    </small>
										<small class="text-danger" *ngIf="WpaForms1.get('cmd_20354').errors?.invalidMonth">
										Month is out of range
										</small>
										<small class="text-danger" *ngIf="WpaForms1.get('cmd_20354').errors?.invalidDateyear">
										  year is out of range(1900-2099)                      </small>
										  <small class="text-danger" *ngIf="WpaForms1.get('cmd_20354').errors?.invalidDateRange">
											date is out of range
											</small>
										</div>
									</div>
                </div>
             </tr>
             </td>
             </tr>
             <tr>
                <td colspan="12">
                   <label for="9">
                   <b>
                   *Healthcare Organization (e.g. hospital, medical staff, medical group, independent practice association, professional review organization health plan,
                   health maintenance organization, preferred provider organization, physician hospital organization, medical society, credentials verification
                   organization, professional association, medical school faculty position or other health delivery entity or system).
                   </b>
                   </label>
                </td>
             </tr>
             <tr>
                <td colspan="12">
                   <div class="row">
                      <div class="col-3 pr-0"> <label for="9">PRACTITIONER NAME:</label></div>
                      <div class="col-3 pl-0">
                         <input type="text" placeholder="Enter Name" id="cmd_13551" formControlName ="cmd_13551"  class="form-control">
                      </div>
                   </div>
                </td>
             </tr>
             <!-- <tr>
                <td colspan="12">
                   <div class="row">
                      <div class="col-6 pr-0"> <label for="9">Healthcare Organization:</label></div>
                      <div class="col-6 pl-0">
                         <input type="text" placeholder="Enter Organization" id="cmd_10355" formControlName ="cmd_10355"  class="form-control">
                      </div>
                      <div class="col-6 pr-0"> <label for="9">And/or Designated Agent:</label></div>
                      <div class="col-6 pl-0">
                         <input type="text" placeholder="Enter Designated Agent" id="cmd_10356" formControlName ="cmd_10356"  class="form-control">
                      </div>
                   </div>
                </td>
             </tr> -->
          </div>
          <!-- <div class="table table-bordered" style="table-layout: fixed;">
             <label class="table table-bordered" style="border: 2px solid black;">
                <h6>WASHINGTON PRACTITIONER APPLICATION AUTHORIZATION AND RELEASE OF INFORMATION FORM</h6>
             </label>
             <label>
                <h6><i>Modified Releases Will Not Be Accepted</i></h6>
             </label>
             <tr>
                <td colspan="12">
                   <div>
                      <label for="5">
                      By submitting this authorization and release of information form in conjunction with the Washington Practitioner Application (WPA) and/or the
                      Washington Practitioner Attestation or Credentials Update (CU) form, I understand and agree as follows:
                      </label>
                   </div>
                </td>
             </tr>
             <tr>
                <td colspan="1">
                   <label for="cmd_1111">1.</label>
                </td>
                <td colspan="11">
                   <label for="9">
                   I understand and acknowledge that, as an applicant for medical staff membership and/or participating status with the Healthcare
                   Organization(s)* indicated on the WPA for initial credentialing or recredentialing, I have the burden of producing adequate information for
                   proper evaluation of my competence, character, ethics, mental and physical health status, and or other qualifications in a timely manner. I
                   understand that the application will not be processed until the application is deemed complete by the healthcare organization
                   </label>
                </td>
             </tr>
             <tr>
                <td colspan="1">
                   <label for="cmd_1111">2.</label>
                </td>
                <td colspan="11">
                   <label for="9">
                   I further understand and acknowledge that the Healthcare Organization(s) or designated agent will investigate the information in this
                   application. By submitting this application, I agree to such investigation and to information exchange activities of the Healthcare
                   Organization(s) as part of the verification and credentialing process.
                   </label>
                </td>
             </tr>
             <tr>
                <td colspan="1">
                   <label for="cmd_1111">3.</label>
                </td>
                <td colspan="11">
                   <label for="9">
                   I authorize all individuals, institutions and entities or organizations with which I am currently or have been associated and all professional
                   liability insurers with which I have had or currently have professional liability insurance, who may have information bearing on my
                   professional qualifications, ethical standing, competence, and mental and physical health status to release the aforementioned information
                   to the designated Healthcare Organization(s), their staffs and agents.
                   </label>
                </td>
             </tr>
             <tr>
                <td colspan="1">
                   <label for="cmd_1111">4.</label>
                </td>
                <td colspan="11">
                   <label for="9">
                   I consent to the inspection of records and documents that may be material to an evaluation of qualifications and my ability to carry out the
                   clinical privileges or provide services I request. I authorize each and every individual and organization in custody of such records and
                   documents to permit such inspection and copying. I am willing to make myself available for interviews if required or requested.
                   </label>
                </td>
             </tr>
             <tr>
                <td colspan="1">
                   <label for="cmd_1111">5.</label>
                </td>
                <td colspan="11">
                   <label for="9">
                   I release from any liability, to the fullest extent permitted by law, all persons for their acts performed in a reasonable manner in conjunction
                   with providing information, investigating and evaluating my application and qualifications, and I waive all legal claims against any
                   representative of the Healthcare Organization(s) or their respective agent(s) who act in good faith and without malice in connection with the investigation of this application.
                   </label>
                </td>
             </tr>
             <tr>
                <td colspan="1">
                   <label for="cmd_1111">6.</label>
                </td>
                <td colspan="11">
                   <label for="9">
                   I acknowledge that I have been informed of, and hereby agree to abide by, the bylaws, rules, regulations, contractual agreements, and policies of the Healthcare Organization.
                   </label>
                </td>
             </tr>
             <tr>
                <td colspan="1">
                   <label for="cmd_1111">7.</label>
                </td>
                <td colspan="11">
                   <label for="9">
                   I acknowledge that I am responsible for notifying the Healthcare Organization of any changes/challenges to licensure, DEA, malpractice claims, criminal convictions, hospital privileges or other disciplinary actions
                   </label>
                </td>
             </tr>
             <tr>
                <td colspan="1">
                   <label for="cmd_1111">8.</label>
                </td>
                <td colspan="11">
                   <label for="9">
                   I attest to the accuracy, currency and completeness of the information provided. I understand and agree that any misstatements in or
                   omissions from the CU, WPA, Washington Practitioner Attestation and attachments hereto may constitute cause for denial of the application or summary dismissal or termination of membership/clinical privileges/participation agreement.
                   </label>
                </td>
             </tr>
             <tr>
                <td colspan="1">
                   <label for="cmd_1111">9.</label>
                </td>
                <td colspan="11">
                   <label for="9">
                   I agree to exhaust all available procedures and remedies as outlined in the bylaws, rules, regulations, and policies, and/or contractual
                   agreements of the Healthcare Organization(s) where I have membership and/or clinical privileges/participation status before initiating judicial action.
                   </label>
                </td>
             </tr>
             <tr>
                <td colspan="1">
                   <label for="cmd_1111">10.</label>
                </td>
                <td colspan="11">
                   <label for="9">
                   I understand that completion and submission of the Authorization and Release does not automatically grant me membership or clinical
                   privileges/participating status with the Healthcare Organization(s)* indicated on the WPA/CU or Attestation.
                   </label>
                </td>
             </tr>
             <tr>
                <td colspan="1">
                   <label for="cmd_1111">11.</label>
                </td>
                <td colspan="11">
                   <label for="9">
                   I hereby further authorize and consent to the release of information and/or reporting by the Healthcare Organization(s) to medical
                   associations, licensing boards, the National Practitioner Data Bank, the Healthcare Integrity and Protection Data Bank, and other similar
                   organizations regarding any pertinent information which the Healthcare Organization(s) may have concerning me as long as such release
                   of information and/or reporting is done in good faith and without malice, and I hereby release from liability Healthcare Organization(s) and
                   its staff and representatives for so doing.
                   </label>
                </td>
             </tr>
             <tr>
                <td colspan="1">
                   <label for="cmd_1111">12.</label>
                </td>
                <td colspan="11">
                   <label for="9">
                   I further acknowledge that I have read and understand the foregoing Authorization and Release. A photocopy of this Authorization and
                   Release shall be as effective as the original and authorization constitutes my written authorization and request to communicate any
                   relevant information and to release any and all supportive documentation regarding this application/attestation.
                   </label>
             <tr>
                <div class="row">
                   <div class="col-6 pr-0"> <label for="cmd_23552">Print name here:</label></div>
                   <div class="col-6 pl-0">
                      <input type="text" placeholder="Enter Name" id="cmd_23552" formControlName ="cmd_23552"  class="form-control">
                   </div>
                   <br>
                   <div class="col-6 pr-0"> <label for="9">Signature:</label></div>
                   <div class="col-6 pl-0">
                      <img
                      *ngIf="!showFour"
                      class="img"
                      src="./assets/images/e_sign.png"
                      alt="Credential my Doc"
                      (click)="esignOpenFour()"
                      />
                      <img
                      *ngIf="showFour"
                      class="img eSignImageShow"
                      [src]="esignFour"
                      alt="Credential my Doc"
                      (click)="esignOpenFour()"
                      /><br><span>(Stamped signature is not acceptable)
                      </span>
                      <input type="hidden" placeholder="Enter Provider Signature" name="cmd_371" value ="{{finalImgPathFour}}"id="cmd_371" class="form-control" formControlName="cmd_371"
                         [class.is-invalid]="(WpaForms1.controls['cmd_371'].touched && WpaForms1.controls['cmd_371'].invalid ) || (WpaForms1.controls['cmd_371'].invalid && isSubmit)">
                   </div>
                   <div class="col-6 pr-0"> <label for="cmd_30354">Date:</label></div>
                   <div class="col-6 pl-0">
                      <input type="Date" placeholder="Enter Date" id="cmd_30354" formControlName ="cmd_30354" class="form-control">
                   </div>
                </div>
             </tr>
             </td>
             </tr>
             <tr>
                <td colspan="12">
                   <label for="9">
                   <b>
                   *Healthcare Organization (e.g. hospital, medical staff, medical group, independent practice association, professional review organization health plan,
                   health maintenance organization, preferred provider organization, physician hospital organization, medical society, credentials verification
                   organization, professional association, medical school faculty position or other health delivery entity or system).
                   </b>
                   </label>
                </td>
             </tr>
          </div> -->

       </div>
      </tbody>
    </table>
  </form>
</div>
