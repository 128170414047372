
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'apps/cmd-frontend/src/environments/environment';
import { UserFieldConstant } from '../../constants/user-field-constants';
import { CommonService, Error_Message } from '../../services/common/common.service';
import { ToasterNotiService } from '../../services/notifications/toaster-noti.service';
import { ChangePasswordComponent } from '../change-password/change-password.component';
import { SpinnerService } from '../../services/spinner/spinner.service';
import { UpdatePasswordComponent } from '../../../containers/auth-routing/update-password/update-password.component';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public currentUserPermission: any;
  modalOption: NgbModalOptions = {
    size: 'md',
    keyboard: false,
    backdrop: 'static'
  };

  public reportCount: any = 0;
  public reportsList: any = [];
  public timeLeft: any = 0;
  public interval: any;
  public isShowHotList: boolean = false;
  public toggleStatus:boolean = false;
  public password_message :string = '';
  public setPswdMsdTimeOut :any;
  public pswdMsg: boolean;
  public time_left:any;
  public countDown;

  constructor(private router: Router,
    private modalService: NgbModal,
    private commonService: CommonService,
    private notiService: ToasterNotiService,
    private spinner:SpinnerService) { }
   
  ngOnInit(): void {
    this.getReportList();
    this.startTimer();
    this.commonService.currentUserPermission.subscribe(data => {
      this.currentUserPermission = data
      if ((this.currentUserPermission && (this.currentUserPermission.isShowAll || this.currentUserPermission.role_id == 1)) || (this.currentUserPermission && this.currentUserPermission.chk_ids && this.currentUserPermission.chk_ids.split(",").indexOf(UserFieldConstant.workflow) != -1)) {
        this.isShowHotList = true;
      }
    })

    this.password_message = localStorage.getItem('password_message');
    if(this.password_message != 'null' && this.password_message != null){
      this.pswdMsg = true;
      this.startTimerForPassword();
    }

  }
  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    clearInterval(this.interval)
  }
  onOpen() {
    this.getReportList()
  }
  
  logOut() {
    this.spinner.show();
    this.commonService.logoutUser().subscribe(data =>{ 
      if(data){
        this.spinner.hide();
        this.notiService.showSuccess(data.message,'', 4000);
        localStorage.clear();
        this.router.navigate(['']);
      }
     
    },
    error => {
      this.spinner.hide();
      if (error) {
        this.notiService.showError(error, '', 4000)
      }
    });
    
  }


  regenerateSession(){
    let postParams = {
    }

    this.commonService.regenerateSSession(postParams).subscribe(
      data => {

        if (data['success']) {
          // this.time_left = data['time_left']
          this.notiService.showSuccess(data['message'], '', 1000)
        }
        else {
        }
      },
      error => {
        // if (error) {
        //   this.notiService.showError(Error_Message, '', 4000)
        // }
      }
    )
  }

  public onChangePassword() {
    this.modalOption.size = 'md';
    const modalRef = this.modalService.open(ChangePasswordComponent, this.modalOption);
    modalRef.result.then((result) => {
    })
  }

  public startTimer() {
    this.interval = setInterval(() => {
      if (this.timeLeft > 1) {
        this.timeLeft--;
      } else {
        this.timeLeft = 180;
        this.getReportList();
      }
    }, 1000)
  }


  getReportList() {
    let postParams = {
    }

    this.commonService.getGeneratedReportList(postParams).subscribe(
      data => {

        if (data['success']) {
          this.reportsList = data['report_details'];
          this.reportCount = data['report_details'].length;
          this.time_left = data['time_left'];      
        }
        else {
        }
      },
      error => {
        // if (error) {
        //   this.notiService.showError(Error_Message, '', 4000)
        // }
      }
    )
  }

  ondownload(selectedReport) {

    let postParams = {
      queue_report_id: selectedReport.queue_report_id
    }

    this.spinner.show()
    this.commonService.downloadQueueReport(postParams).subscribe(
      data => {
        this.spinner.hide()
        if (data['success']) {
          if (data['report_details']) {
            if (data.report_details && data.report_details.s3_path.split(".").pop() == 'pdf') {
              let fileURL = `${environment.apiDownloadUrl}${data.report_details.s3_path}`
              var _window = window.open(fileURL, '_blank');
              let fileName = data.report_details.s3_path.split('/')[1];
              _window.document.close();
              _window.document.execCommand('Save', true, fileName || fileURL)
            }
            else {
              var downloadLink = window.document.createElement('a');
              downloadLink.href = `${environment.apiDownloadUrl}${data.report_details.s3_path}`
              downloadLink.download = data.report_details.s3_path;
              document.body.appendChild(downloadLink);
              downloadLink.click();
              document.body.removeChild(downloadLink);
              this.notiService.showSuccess('Report Downloaded SuccessFully', '', 4000);
            }
          }
        }
      },
      error => {
        this.spinner.hide()
        this.notiService.showError(Error_Message, '', 4000)
        // if (error) {
        //   this.notiService.showError(Error_Message, '', 4000)
        // }
      }
    )
  }

  clickEvent(): void{
    this.toggleStatus = !this.toggleStatus;
  	this.commonService.toggleClicked(this.toggleStatus);
  }

  updatePassword(){
    this.pswdMsg = false;
    this.modalOption.backdrop = 'static';
    this.modalOption.keyboard = false;
    this.modalOption.size = 'md';
    const adduserpopup = this.modalService.open(UpdatePasswordComponent, this.modalOption);
  }
  closePassMsg(){
      this.pswdMsg = false;
  }

  timeLeft1: number = 20;
  interval1;

  startTimerForPassword() {
    this.interval1 = setInterval(() => {
      if (this.timeLeft1 > 0 ) {
            this.timeLeft1--;
      } else {
        this.pswdMsg = false;
        localStorage.setItem('password_message', null);
        this.timeLeft1 = 0;
        clearInterval(this.interval1);
      }
    }, 1000);
  }

}