import { Component,OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { OnboardingformsServiceService } from '../../../services/onboardingforms-service/onboardingforms-service.service';
import { ToasterNotiService } from '../../../services/notifications/toaster-noti.service';
// import { CommonService } from '../../../shared/services/common/common.service'
import { EsignComponent } from '../../esign/esign.component';
import { DomSanitizer } from '@angular/platform-browser';
import {
  NgbModal,
  NgbModalOptions,
  NgbDateAdapter,
  NgbDateParserFormatter
} from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'ctg-wpa1',
  templateUrl: './wpa1.component.html',
  styleUrls: ['./wpa1.component.scss']
})
export class Wpa1Component implements OnInit {

  @Input() WpaForms1 : UntypedFormGroup
  @Input() isSubmit;
  @Input() showTwo: any = false;

  @Input() showThree: any = false;
  // @Input() showFour: any = false;
  @Input() showOne: any = false;


  @Input() esignOne: any;
  @Input() esignTwo: any;
  @Input() esignThree: any;
  @Input() esignFour: any;

  @Input() finalImgPath: any = '';
  @Input() finalImg: any;

  @Input() finalImgPathTwo: any = [];
  @Input() finalImgTwo: any = '';


  @Input() finalImgPathThree: any = [];
  @Input() finalImgThree: any = '';

  // @Input() finalImgPathFour: any = [];
  // @Input() finalImgFour: any = '';






  constructor(
    private fb: UntypedFormBuilder,
    private modalService: NgbModal,
    private _sanitizer: DomSanitizer,
    private onboardService: OnboardingformsServiceService, private notiService: ToasterNotiService,

  ) { }

  @Input() formData;
  @Input() fieldsData;
  @Input() formId;

  modalOption: NgbModalOptions = {
    size: 'md',
    keyboard: false,
    backdrop: 'static'
  };


  ngOnInit(): void {
    this.getStatesData();
    this.getPreClaimData();
    this.getAggregatesData();


  }


  states: Array<object> = [];
  preClaim: Array<object> = [];
  aggregates: Array<object> = [];

  public getStatesData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getStatesData(params).subscribe(
      data => {
        if (data['success']) {
          this.states = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
      },
      error => {
      }
    );
  }
  public getPreClaimData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getPreClaimData(params).subscribe(
      data => {
        if (data['success']) {
          this.preClaim = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }
  public getAggregatesData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getAggregatesData(params).subscribe(
      data => {
        if (data['success']) {
          this.aggregates = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public esignOpenTwo() {
    const modalRef = this.modalService.open(EsignComponent, this.modalOption);
    modalRef.result.then(result => { });
    modalRef.componentInstance.base64Img.subscribe(data => {
      modalRef.close();
      this.finalImgPathTwo = data.finalFilePath;
      let temp = {
        cmd_355: this.finalImgPathTwo
      };
      this.WpaForms1.patchValue(temp);
      this.finalImgPathTwo = data.finalFilePath;
      this.finalImgTwo = data.base64Img;
      this.esignTwo = this._sanitizer.bypassSecurityTrustResourceUrl(
        'data:image/jpg;base64,' + this.finalImgTwo
      );
      this.showTwo = true;
    });
  }

  public esignOpenThree() {
    const modalRef = this.modalService.open(EsignComponent, this.modalOption);
    modalRef.result.then(result => { });
    modalRef.componentInstance.base64Img.subscribe(data => {
      modalRef.close();
      this.finalImgPathThree = data.finalFilePath;
      let temp = {
        cmd_370: this.finalImgPathThree
      };
      this.WpaForms1.patchValue(temp);
      this.finalImgPathThree = data.finalFilePath;
      this.finalImgThree = data.base64Img;
      this.esignThree = this._sanitizer.bypassSecurityTrustResourceUrl(
        'data:image/jpg;base64,' + this.finalImgThree
      );
      this.showThree = true;
    });
  }

  public esignOpenOne() {
    const modalRef = this.modalService.open(EsignComponent, this.modalOption);
    modalRef.result.then(result => { });
    modalRef.componentInstance.base64Img.subscribe(data => {
      modalRef.close();
      this.finalImgPath = data.finalFilePath;
      let temp = {
        cmd_353: this.finalImgPath
      };
      this.WpaForms1.patchValue(temp);
      this.finalImgPath = data.finalFilePath;
      this.finalImg = data.base64Img;
      this.esignOne = this._sanitizer.bypassSecurityTrustResourceUrl(
        'data:image/jpg;base64,' + this.finalImg
      );
      this.showOne = true;
    });
  }



  // public esignOpenFour() {
  //   const modalRef = this.modalService.open(EsignComponent, this.modalOption);
  //   modalRef.result.then(result => { });
  //   modalRef.componentInstance.base64Img.subscribe(data => {
  //     modalRef.close();
  //     this.finalImgPathFour = data.finalFilePath;
  //     let temp = {
  //       cmd_371: this.finalImgPathFour
  //     };
  //     this.WpaForms1.patchValue(temp);
  //     this.finalImgPathFour = data.finalFilePath;
  //     this.finalImgFour = data.base64Img;
  //     this.esignFour = this._sanitizer.bypassSecurityTrustResourceUrl(
  //       'data:image/jpg;base64,' + this.finalImgFour
  //     );
  //     this.showFour = true;
  //   });
  // }

  clearInputIfInvalid(controlName: string,handleYesNoValue?:string) {
    const control = this.WpaForms1.get(controlName);
    const dateString = control.value;
  
    // Use the dateFormatValidator to check if the date is valid
    const validationErrors = this.dateFormatValidator(new FormControl(dateString));
  
    if (validationErrors) {
      // If any of the validation conditions are met, clear the input field and set errors to null
      control.setValue('');
      control.setErrors(null);
    }
    
    
    
  }
  clearInputIfInvalidMandi(controlName: string) {
    const control = this.WpaForms1.get(controlName);
    const dateString = control.value;
  
    // Use the dateFormatValidator to check if the date is valid
    const validationErrors = this.dateFormatValidator(new FormControl(dateString));
  
    if (validationErrors) {
      // If any of the validation conditions are met, clear the input field and set errors to null
      control.setValue('');

    }}

  dateFormatValidator(control: FormControl): ValidationErrors | null {
    const dateString = control.value;
    const dateFormat = /^\d{2}\/\d{2}\/\d{4}$/;
  
    if (dateString && !dateString.match(dateFormat)) {
      return { 'invalidDateFormat': true };
    }
  


    if (dateString) {
      const dateParts = dateString.split('/');
      const month = parseInt(dateParts[0]);
      const day = parseInt(dateParts[1]);
      const year = parseInt(dateParts[2]);
  
      // Check if any of the date components are zero or month is not between 1 and 12
      
  
      const ListofDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
      
      
      if (month < 1 || month > 12 || day === 0 || year === 0) {
        return { 'invalidMonth': true };
      }
      if (month === 1 || month > 2) {
        if (day > ListofDays[month - 1]) {
          return { 'invalidDateRange': true };
        }
      } else if (month === 2) {
        const leapYear = (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
        if (!leapYear && day >= 29) {
          return { 'invalidDateRange': true };
        }
         else if (leapYear && day > 29) {
          return { 'invalidDateRange': true };
        }
      }
      if (year < 1900 || year > 2099) {
        return { 'invalidDateyear': true };
      }
      
     
    }
  
    return null;
  }



}
