import { FormControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import {
  NgbModal,
  NgbModalOptions,
  NgbDateAdapter,
  NgbDateParserFormatter
} from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToasterNotiService } from '../../services/notifications/toaster-noti.service';
import { OnboardingformsServiceService } from '../../services/onboardingforms-service/onboardingforms-service.service';
import { StorageService } from '../../services/storage/storage.service';
import { EsignComponent } from '../esign/esign.component';
import {
  CustomAdapter,
  CustomDateParserFormatter
} from '../../DateFormatter/customDate';
@Component({
    selector: 'tanner-clinic',
    templateUrl: './tanner-clinic.component.html',
    styleUrls: ['./tanner-clinic.component.scss'],
    providers: [
      { provide: NgbDateAdapter, useClass: CustomAdapter },
      { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }
    ]
  })
    export class TannerClinicComponent implements OnInit {
        @Input() formData;
        @Input() fieldsData;
        @Input() formId;
        @Output() onSubmit = new EventEmitter();
        @Output() onClose = new EventEmitter();
        private emailPattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        constructor(
        private fb: UntypedFormBuilder,
        private onboardService: OnboardingformsServiceService,
        private spinner: NgxSpinnerService,
        private notiService: ToasterNotiService,
        private modalService: NgbModal,
        private _sanitizer: DomSanitizer,
        private storageService: StorageService
        ) {}
        public userId: any;
        public customerId: any;
        public TannerClinicFroms: UntypedFormGroup;
        ngSelect = 'ACLS';
        suffix: Array<object> = [];
        public credentials: any = [];
        specialty: Array<object> = [];
        specialtySecondary: Array<object> = [];
        states: Array<object> = [];
        languages: Array<object> = [];
        secondaryLanguages: Array<object> = [];
        tertiaryLanguages: Array<object> = [];
        quaternaryLanguages: Array<object> = [];
        altLicenseState: Array<object> = [];
        AltCdsStates: Array<object> = [];
        boardStatus: Array<any> = [];
        boardCertification: Array<object> = [];
        preClaim: Array<object> = [];
        aggregates: Array<object> = [];
        public staffStatus: any = [];
        mediCertifications: Array<object> = [];
        degree: Array<object> = [];
        isSubmit: boolean = false;
        private phonePattern = '^[0-9]*$';
        questionCounter = 0;
        indexKey = 0;
        indexKeys = 0;
        questionCounters = 0;
        modalOption: NgbModalOptions = {
        size: 'md',
        keyboard: false,
        backdrop: 'static'
        };

        ngOnInit(): void {
            this.userId = Number(this.storageService.getItem('userId'));
            this.customerId = Number(this.storageService.getItem('customerId'));
            this.buildnewmanalliedForm();
            this.getSuffixData();
            this.getCredentialsData();
            this.getSpecialtyData();
            this.getSpecialtySecondaryData();
            this.getStatesData();
            this.getLanguagesData();
            this.getsecondaryLanguagesData();
            this.gettertiaryLanguagesData();
            this.getquaternaryLanguagesData();
            this.getAlternateLicenseStateData();
            this.getAltCdsStatesData();
            this.getboardstatus();
            this.getBoardCertificationData();
            this.getPreClaimData();
            this.getAggregatesData();
            this.getStaffStatusData();
            this.getMediCertifications();
            this.getDegreeData();
            this.TannerClinicFroms.controls['add_1_674'].setValue('1');
            this.TannerClinicFroms.controls['add_2_674'].setValue('5');
            this.TannerClinicFroms.controls['add_3_674'].setValue('11');
            this.TannerClinicFroms.controls['add_4_674'].setValue('7');
            this.fieldsData && this.bindFormValue(this.fieldsData);
            this.afterSaveDataDisplay();
            
        } 
        //drop down arrays
        public doits: any;
        finalImgPath: any = [];
        finalImg: any;
        esignOne: any;
        showOne: any = false;  
        
        public afterSaveDataDisplay() {
            let params = {
              type: 41,
              formId: this.formId
            };
            //this.spinner.show();
            this.onboardService.toGetSaveData(params).subscribe(
              data => {
                if (data['success']) {
                  this.bindFormValue(data.data);
                } else {
                  // this.notiService.showError(data.error, '', 3000)
                }
                //this.spinner.hide();
              },
              error => {
                // this.notiService.showError(Error_Message, '', 3000)
              }
            );
        } 

        public bindFormValue(array) {
            let fieldsCtrls = {};
            for (let i = 0; i < array.length; i++) {
              fieldsCtrls[array[i].field_name] = array[i].field_value;
              if (array[i].field_name == 'cmd_0019') {
                if (array[i].field_value != '') {
                  this.previewEsign(array[i].field_value, array[i].field_name);
                }
              }
         
            }
            
            this.TannerClinicFroms.patchValue(fieldsCtrls);
        }
        
        public setEsign(filePath, fieldId) {
            // console.log(filePath);
            if (fieldId == 'cmd_0019') {
              this.esignOne = this._sanitizer.bypassSecurityTrustResourceUrl(
                'data:image/jpg;base64,' + filePath
              );
              this.showOne = true;
            }
          }
      
        public previewEsign(filePath, fieldId) {
            //this.spinner.show();
            let params = {
              filePath: filePath
            };
            this.onboardService.getPreviewEsign(params).subscribe(
              (data: any) => {
                if (data['success']) {
                  this.setEsign(data.base64, fieldId);
                } else {
                  //this.notiService.showError(data.error, '', 3000)
                }
                //this.spinner.hide();
              },
              error => {
                //this.spinner.hide();
              }
            );
        }
        handleNumber(event, name?) {
          if (event.code == 'Delete' || event.code == 'Backspace') {
          
          }
          else {
          let z = event.target.value;
          z = z.replace(/[^\d-]/g, '');
          if (z.length == 3) {
          z = z + '-'
          }
          else if (z.length == 7) {
          z = z + '-'
          }
          this.TannerClinicFroms.controls[name].setValue(z)
          }
          }
        clearInputIfInvalidMandi(controlName: string) {
          const control = this.TannerClinicFroms.get(controlName);
          const dateString = control.value;
        
          // Use the dateFormatValidator to check if the date is valid
          const validationErrors = this.dateFormatValidator(new FormControl(dateString));
        
          if (validationErrors) {
            // If any of the validation conditions are met, clear the input field and set errors to null
            control.setValue('');
      
          }}
          clearInputIfInvalid(controlName: string,handleYesNoValue?:string) {
            const control = this.TannerClinicFroms.get(controlName);
            const dateString = control.value;
          
            // Use the dateFormatValidator to check if the date is valid
            const validationErrors = this.dateFormatValidator(new FormControl(dateString));
          
            if (validationErrors) {
              // If any of the validation conditions are met, clear the input field and set errors to null
              control.setValue('');
              control.setErrors(null);
            }
          }
        dateFormatValidator(control: FormControl): ValidationErrors | null {
          const dateString = control.value;
          const dateFormat = /^\d{2}\/\d{2}\/\d{4}$/;
        
          if (dateString && !dateString.match(dateFormat)) {
            return { 'invalidDateFormat': true };
          }
        
      
      
          if (dateString) {
            const dateParts = dateString.split('/');
            const month = parseInt(dateParts[0]);
            const day = parseInt(dateParts[1]);
            const year = parseInt(dateParts[2]);
        
            // Check if any of the date components are zero or month is not between 1 and 12
            
        
            const ListofDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
            
            
            if (month < 1 || month > 12 || day === 0 || year === 0) {
              return { 'invalidMonth': true };
            }
            if (month === 1 || month > 2) {
              if (day > ListofDays[month - 1]) {
                return { 'invalidDateRange': true };
              }
            } else if (month === 2) {
              const leapYear = (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
              if (!leapYear && day >= 29) {
                return { 'invalidDateRange': true };
              }
               else if (leapYear && day > 29) {
                return { 'invalidDateRange': true };
              }
            }
            if (year < 1900 || year > 2099) {
              return { 'invalidDateyear': true };
            }
            
           
          }
        
          return null;
        }
        public esignOpenOne() {
            const modalRef = this.modalService.open(EsignComponent, this.modalOption);
            modalRef.result.then(result => { });
            modalRef.componentInstance.base64Img.subscribe(data => {
              modalRef.close();
              this.finalImgPath = data.finalFilePath;
              let temp = {
                cmd_0019: this.finalImgPath
              };
              this.TannerClinicFroms.controls['cmd_0019'].setValue(this.finalImgPath);
              this.finalImg = data.base64Img;
              this.esignOne = this._sanitizer.bypassSecurityTrustResourceUrl(
                'data:image/jpg;base64,' + this.finalImg
              );
              this.showOne = true;
            });
          }
        setErrorControl(name){
            this.TannerClinicFroms.controls[name].setErrors({ errors: true });
        }
        removeErrorControl(name){
            this.TannerClinicFroms.controls[name].setErrors(null)
        }
        buildnewmanalliedForm() {
            this.TannerClinicFroms = this.fb.group({
              8: ['', [Validators.required]],
              809:['', [Validators.required]],
              9: ['', [Validators.required]],
              29: [null],
              278:[''],
              280:[null,[this.dateFormatValidator] ],
              281:[null,[this.dateFormatValidator]],
              822: [null, [Validators.required]],
              284: [null, [Validators.required]],
              962: [null],
              31: [null, [Validators.required,this.dateFormatValidator]],
              810: ['', [Validators.required]],
              289: [null, [Validators.required]],
              291: ['', [Validators.required]],
              292: ['', [Validators.required]],
              1282:[''],
              32: ['', [Validators.required]],
              709:['', [Validators.required,Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]],
              708:['', [Validators.required,Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]],
              16: ['', [Validators.required]],
              17: [''],
              18: ['', [Validators.required]],
              20: [null, [Validators.required]],
              21: ['', [Validators.required]],
              759: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
              1664:['',[Validators.pattern(this.emailPattern)]],
              277: [null, [Validators.required]],
              1535: [null],
              1536: [null],
              2207: [null],
              33: ['', [Validators.required]],
              cmd_01766:[''],
              cmd_01767:[''],
              cmd_01768:[''],
              cmd_01769:[''],
              cmd_01770:[null],
              cmd_01771:[''],
              cmd_01772:[null, [Validators.required,this.dateFormatValidator]],
              cmd_01773:[''],
              cmd_01774:[''],
              1634: ['', Validators.required],
              1635: ['', Validators.required],
              1636: ['', Validators.required],
              325:[''],
              812:[''],
              333:[''],
              334: [''],
              335: [''],
              819: [''],
              309: [''],
              310:[null,[this.dateFormatValidator]],
              311:[null,[this.dateFormatValidator]],
              cmd_01775:[null,[this.dateFormatValidator]],
              293:[''],
              294:[null,[this.dateFormatValidator]],
              295:[null,[this.dateFormatValidator]],
              cmd_01776:[null,[this.dateFormatValidator]],
              337:[''],
              338:[null,[this.dateFormatValidator]],
              339:[null,[this.dateFormatValidator]],
              cmd_01777:[null,[this.dateFormatValidator]],
              907:[null],
              300:[''],
              307:[null,[this.dateFormatValidator]],
              308:[null,[this.dateFormatValidator]],
              997:[null],
              340:[''],
              341:[null,[this.dateFormatValidator]],
              342:[null,[this.dateFormatValidator]],
              1234:[null, Validators.required],
              554:[null],
              1169:[''],
              557:[null,[this.dateFormatValidator]],
              560:[null,[this.dateFormatValidator]],
              558:[null,[this.dateFormatValidator]],
              cmd_01778:[''],
              cmd_01794:[''],
              1377:[null,[this.dateFormatValidator]],
              1235:[null],
              561:[null],
              562:[''],
              564:[null,[this.dateFormatValidator]],
              567:[null,[this.dateFormatValidator]],
              565:[null,[this.dateFormatValidator]],
              1378:[null,[this.dateFormatValidator]],
              345:[''],
              901:[''],
              902:[''],
              903:[''],
              905:[null],
              906:[''],
              1266:['', [Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]],
              1273:[''],
              346:[''],
              348:[null,[this.dateFormatValidator]],
              349:[null,[this.dateFormatValidator]],
              945:[null,[this.dateFormatValidator]],
              350:[null],
              351:[null],
              add_1_1242:[''],
              add_1_1244:[''],
              add_1_1245:[''],
              add_1_1246:[''],
              add_1_1248:[null],
              add_1_1249:[''],
              add_1_1254:['', [Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]],
              add_1_1255:[''],
              add_1_1256:[''],
              add_1_1252:[null,[this.dateFormatValidator]],
              add_1_1253:[null,[this.dateFormatValidator]],
              add_1_1264:[null],
              add_1_1265:[null],
              add_2_1242:[''],
              add_2_1244:[''],
              add_2_1245:[''],
              add_2_1246:[''],
              add_2_1248:[null],
              add_2_1249:[''],
              add_2_1254:['', [Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]],
              add_2_1255:[''],
              add_2_1256:[''],
              add_2_1252:[null,[this.dateFormatValidator]],
              add_2_1253:[null,[this.dateFormatValidator]],
              add_2_1264:[null],
              add_2_1265:[null],
              add_3_1242:[''],
              add_3_1244:[''],
              add_3_1245:[''],
              add_3_1246:[''],
              add_3_1248:[null],
              add_3_1249:[''],
              add_3_1254:['', [Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]],
              add_3_1255:[''],
              add_3_1256:[''],
              add_3_1252:[null,[this.dateFormatValidator]],
              add_3_1253:[null,[this.dateFormatValidator]],
              add_3_1264:[null],
              add_3_1265:[null],
              add_4_1242:[''],
              add_4_1244:[''],
              add_4_1245:[''],
              add_4_1246:[''],
              add_4_1248:[null],
              add_4_1249:[''],
              add_4_1254:['', [Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]],
              add_4_1255:[''],
              add_4_1256:[''],
              add_4_1252:[null,[this.dateFormatValidator]],
              add_4_1253:[null,[this.dateFormatValidator]],
              add_4_1264:[null],
              add_4_1265:[null],
              418:[''],
              419:[''],
              420:[''],
              421:[''],
              423:[null],
              424:[''],
              1305:['', [Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]],
              2136:[''],
              426:[null,[this.dateFormatValidator]],
              2007:[null,[this.dateFormatValidator]],
              894:[null],
              3183:[''],
              427:[''],
              428:[''],
              429:[''],
              430:[''],
              432:[null],
              433:[''],
              1307:['', [Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]],
              2137:[''],
              435:[null,[this.dateFormatValidator]],
              2008:[null,[this.dateFormatValidator]],
              895:[null],
              3184:[''],
              436:[''],
              437:[''],
              438:[''],
              439:[''],
              441:[null],
              442:[''],
              1309:['', [Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]],
              2138:[''],
              444:[null,[this.dateFormatValidator]],
              2011:[null,[this.dateFormatValidator]],
              896:[null],
              3185:[''],
              //add aff
              add_1_986:[''],
              add_1_987:[''],
              add_1_988:[''],
              add_1_989:[''],
              add_1_991:[null],
              add_1_992:[''],
              add_1_1311:['', [Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]],
              add_1_2139:[''],
              add_1_994:[null,[this.dateFormatValidator]],
              add_1_2010:[null,[this.dateFormatValidator]],
              add_1_995:[null],
              add_1_3186:[''],
              add_2_986:[''],
              add_2_987:[''],
              add_2_988:[''],
              add_2_989:[''],
              add_2_991:[null],
              add_2_992:[''],
              add_2_1311:['', [Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]],
              add_2_2139:[''],
              add_2_994:[null,[this.dateFormatValidator]],
              add_2_2010:[null,[this.dateFormatValidator] ],
              add_2_995:[null],
              add_2_3186:[''],
              add_1_1183:[''],
              add_1_1184:[''],
              add_1_1185:[''],
              add_1_1186:[''],
              add_1_1188:[null],
              add_1_1189:[''],
              add_1_1313:['', [Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]],
              add_1_2140:[''],
              add_1_1191:[null,[this.dateFormatValidator]],
              add_1_2381:[null,[this.dateFormatValidator]],
              add_1_1192:[null],
              add_1_2461:[''],
              add_2_1183:[''],
              add_2_1184:[''],
              add_2_1185:[''],
              add_2_1186:[''],
              add_2_1188:[null],
              add_2_1189:[''],
              add_2_1313:['', [Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]],
              add_2_2140:[''],
              add_2_1191:[null,[this.dateFormatValidator]],
              add_2_2381:[null,[this.dateFormatValidator]],
              add_2_1192:[null],
              add_2_2461:[''],
              add_3_1183:[''],
              add_3_1184:[''],
              add_3_1185:[''],
              add_3_1186:[''],
              add_3_1188:[null],
              add_3_1189:[''],
              add_3_1313:['', [Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]],
              add_3_2140:[''],
              add_3_1191:[null,[this.dateFormatValidator]],
              add_3_2381:[null,[this.dateFormatValidator]],
              add_3_1192:[null],
              add_3_2461:[''],
              add_4_1183:[''],
              add_4_1184:[''],
              add_4_1185:[''],
              add_4_1186:[''],
              add_4_1188:[null],
              add_4_1189:[''],
              add_4_1313:['', [Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]],
              add_4_2140:[''],
              add_4_1191:[null,[this.dateFormatValidator]],
              add_4_2381:[null,[this.dateFormatValidator]],
              add_4_1192:[null],
              add_4_2461:[''],
              //ACLS  Cert
              add_1_674:[null],
              add_1_3270:[''],
              add_1_677:[null,[this.dateFormatValidator]],
              add_1_678:[null,[this.dateFormatValidator]],
              cmd_01779:[null,[this.dateFormatValidator]],
              //BLS  Cert
              add_2_674:[null],
              add_2_3270:[''],
              add_2_677:[null,[this.dateFormatValidator]],
              add_2_678:[null,[this.dateFormatValidator]],
              cmd_01790:[null,[this.dateFormatValidator]],
               //PALS  Cert
               add_3_674:[null],
               add_3_3270:[''],
               add_3_677:[null,[this.dateFormatValidator]],
               add_3_678:[null,[this.dateFormatValidator]],
               cmd_01791:[null,[this.dateFormatValidator]],
                //CPR  Cert
                add_4_674:[null],
                add_4_3270:[''],
                add_4_677:[null,[this.dateFormatValidator]],
                add_4_678:[null,[this.dateFormatValidator]],
                cmd_01792:[null,[this.dateFormatValidator]],
              //Education
              503:[''],
              512:[null],
              504:[''],
              505:[''],
              506: [''],
              508: [null],
              509: [''],
              2345:['', [Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]],
              2346:[''],
              510:[null,[this.dateFormatValidator]],
              511:[null,[this.dateFormatValidator]],
              490:['', Validators.required],
              500:[null],
              491:[''],
              492:[''],
              493: [''],
              495: [null],
              496: [''],
              2351:['', [Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]],
              2352:[''],
              497:[null,[this.dateFormatValidator]],
              499:[null,[this.dateFormatValidator]],
              //ECFMG
              514:[''],
              515:[null,[this.dateFormatValidator]],
              //Intern
              517:['', Validators.required],
              518:[''],
              519:[''],
              520:[''],
              522:[null],
              523:[''],
              1318:['', [Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]],
              1319:[''],
              524:[null,[this.dateFormatValidator]],
              526:[null,[this.dateFormatValidator]],
              527:[null],
              528:[''],
              4579:['',[Validators.pattern(this.emailPattern)]],
              cmd_01796:[''],
              cmd_01798:['', [Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]],
              //Residency
              529:['', Validators.required],
              531:[''],
              1498:[''],
              533:[''],
              535:[null],
              536:[''],
              1316:['', [Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]],
              1317:[''],
              537:[null,[this.dateFormatValidator]],
              539:[null,[this.dateFormatValidator]],
              530:[null],
              540:[''],
              cmd_01795:[''],
              4581:['',[Validators.pattern(this.emailPattern)]],
              cmd_01800:['', [Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]],
              //Fellowship
              542:[''],
              544:[''],
              545:[''],
              546:[''],
              548:[null],
              549:[''],
              1322:['', [Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]],
              1323:[''],
              550:[null,[this.dateFormatValidator]],
              552:[null,[this.dateFormatValidator]],
              543:[null],
              553:[''],
              4583:['',[Validators.pattern(this.emailPattern)]],
              cmd_1797:[''],
              cmd_01801:['', [Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]],
              //Military
              cmd_01780: ['', [Validators.required]],
              1141:[''],
              1144:[null,[this.dateFormatValidator]],
              1145:[null,[this.dateFormatValidator]],
              2112:[''],
              2113:[''],
              2114:[''],
              2116:[null],
              2117:[''],
              //Work
              445:[''],
              446:[''],
              447:[''],
              448:[''],
              450:[null],
              451:[''],
              454:[null,[this.dateFormatValidator]],
              455:[null,[this.dateFormatValidator]],
              453:['', [Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]],
              888:[''],
              //Work2
              456:[''],
              457:[''],
              458:[''],
              459:[''],
              461:[null],
              462:[''],
              465:[null,[this.dateFormatValidator]],
              466:[null,[this.dateFormatValidator]],
              464:['', [Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]],
              890:[''],
              //Work3
              467:[''],
              469:[''],
              468:[''],
              470:[''],
              472:[null],
              473:[''],
              476:[null,[this.dateFormatValidator]],
              477:[null,[this.dateFormatValidator]],
              475:['', [Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]],
              892:[''],
              //Work4
              1935:[''],
              1936:[''],
              1937:[''],
              1938:[''],
              1940:[null],
              1941:[''],
              1947:[null,[this.dateFormatValidator]],
              1948:[null,[this.dateFormatValidator]],
              1944:['', [Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]],
              1945:[''],
              //Add1
              add_1_1954:[''],
              add_1_1955:[''],
              add_1_1956:[''],
              add_1_1957:[''],
              add_1_1959:[null],
              add_1_1960:[''],
              add_1_1966:[null,[this.dateFormatValidator]],
              add_1_1967:[null,[this.dateFormatValidator]],
              add_1_1963:['', [Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]],
              add_1_1964:[''],
              //Add1
              add_2_1954:[''],
              add_2_1955:[''],
              add_2_1956:[''],
              add_2_1957:[''],
              add_2_1959:[null],
              add_2_1960:[''],
              add_2_1966:[null,[this.dateFormatValidator]],
              add_2_1967:[null,[this.dateFormatValidator]],
              add_2_1963:['', [Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]],
              add_2_1964:[''],
              //Explanation1
              1138:[''],
              3395:[null,[this.dateFormatValidator]],
              3396:[null,[this.dateFormatValidator]],
              //Explanation2
              1831:[''],
              3397:[null,[this.dateFormatValidator]],
              3398:[null,[this.dateFormatValidator]],
              //Explanation3
              1832:[''],
              3399:[null,[this.dateFormatValidator]],
              3400:[null,[this.dateFormatValidator]],
              //References1
              359: ['', [Validators.required]],
              2722: ['', [Validators.required]],
              368: [null, [Validators.required]],
              360: ['', [Validators.required]],
              361: [''],
              362: ['', [Validators.required]],
              364: [null, [Validators.required]],
              365: ['', [Validators.required]],
              369: ['', [Validators.required, Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]],
              370: [''],
              371: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
              //References2
              372: ['', [Validators.required]],
              2723: ['', [Validators.required]],
              380: [null, [Validators.required]],
              373: ['', [Validators.required]],
              374: [''],
              375: ['', [Validators.required]],
              377: [null, [Validators.required]],
              378: ['', [Validators.required]],
              381: ['', [Validators.required, Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]],
              382: [''],
              383: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
              //References3
               384: ['', [Validators.required]],
               2725: ['', [Validators.required]],
               393: [null, [Validators.required]],
               385: ['', [Validators.required]],
               386: [''],
               387: ['', [Validators.required]],
               389: [null, [Validators.required]],
               390: ['', [Validators.required]],
               394: ['', [Validators.required, Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]],
               395: [''],
               396: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
                //References4
                1681: ['', [Validators.required]],
                2727: ['', [Validators.required]],
                1691: [null, [Validators.required]],
                1682: ['', [Validators.required]],
                1683: [''],
                1684: ['', [Validators.required]],
                1686: [null, [Validators.required]],
                1687: ['', [Validators.required]],
                1692: ['', [Validators.required, Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]],
                1693: [''],
                1694: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
                 //References5
                 2792: ['', [Validators.required]],
                 2793: ['', [Validators.required]],
                 2809: [null, [Validators.required]],
                 2794: ['', [Validators.required]],
                 2795: [''],
                 2796: ['', [Validators.required]],
                 2798: [null, [Validators.required]],
                 2799: ['', [Validators.required]],
                 2800: ['', [Validators.required,Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]],
                 2811: [''],
                 2812: ['', [Validators.required,Validators.pattern(this.emailPattern)]],
                 cmd_01781:[''],
                 cmd_01782:['',Validators.pattern('^[0-9]*$')],
                 cmd_01783:[null,[this.dateFormatValidator]],
                 cmd_01784:[null,[this.dateFormatValidator]],
                 cmd_01785:[''],
                 cmd_01786:['',Validators.pattern('^[0-9]*$')],
                 cmd_01787:[null,[this.dateFormatValidator]],
                 cmd_01788:[null,[this.dateFormatValidator]],
                 cmd_0233: ['', [Validators.required]],
                 cmd_0232:['', [Validators.required]],
                 cmd_0234:['', [Validators.required]],
                 cmd_0235: ['', [Validators.required]],
                 cmd_0236: ['', [Validators.required]],
                 cmd_0237: ['', [Validators.required]],
                 cmd_0238: ['', [Validators.required]],
                 cmd_0239: ['', [Validators.required]],
                 cmd_0240: ['', [Validators.required]],
                 cmd_0241: ['', [Validators.required]],
                 cmd_0242: ['', [Validators.required]],
                 cmd_0243: ['', [Validators.required]],
                 cmd_0244: ['', [Validators.required]],
                 cmd_0245: ['', [Validators.required]],
                 cmd_0246: ['', [Validators.required]],
                 cmd_0247: ['', [Validators.required]],
                 cmd_0248: ['', [Validators.required]],
                 cmd_0249: ['', [Validators.required]],
                 cmd_0250: ['', [Validators.required]],
                 cmd_0251: ['', [Validators.required]],
                 cmd_0252: ['', [Validators.required]],
                 cmd_0253: ['', [Validators.required]],
                 cmd_0254: ['', [Validators.required]],
                 cmd_0255:[''],
                cmd_0256: ['', [Validators.required]],
                cmd_0257: ['', [Validators.required]],
                cmd_0259: ['', [Validators.required]],
                cmd_0260: ['', [Validators.required]],
                cmd_0261: ['', [Validators.required]],
                cmd_0262: ['', [Validators.required]],
                cmd_0263: ['', [Validators.required]],
                cmd_0264: ['', [Validators.required]],
                cmd_0265: ['', [Validators.required]],
                cmd_0266: ['', [Validators.required]],
                cmd_0267: ['', [Validators.required]],
                cmd_0268: ['', [Validators.required]],
                cmd_0269: ['', [Validators.required]],
                cmd_0270: ['', [Validators.required]],
                cmd_0271: ['', [Validators.required]],
                cmd_0272: ['', [Validators.required]],
                cmd_0273: ['', [Validators.required]],
                cmd_0274: ['', [Validators.required]],
                cmd_0275: ['', [Validators.required]],
                cmd_0276: ['', [Validators.required]],
                cmd_0277: ['', [Validators.required]],
                cmd_0278: ['', [Validators.required]],
                cmd_0279: ['', [Validators.required]],
                cmd_0281: ['', [Validators.required]],
                cmd_0282: ['', [Validators.required]],
                cmd_0283: ['', [Validators.required]],
                cmd_0284: ['', [Validators.required]],
                cmd_0285: ['', [Validators.required]],
                cmd_0286: ['', [Validators.required]],
                cmd_0258: ['', [Validators.required]],
                cmd_0280: ['', [Validators.required]],
                cmd_0287: ['', [Validators.required]],
                cmd_0288: ['', [Validators.required]],
                cmd_0289: ['', [Validators.required]],
                cmd_0290: ['', [Validators.required]],
                cmd_0291: ['', [Validators.required]],
                cmd_0292: ['', [Validators.required]],
                cmd_0293: ['', [Validators.required]],
                cmd_0294: ['', [Validators.required]],
                cmd_0295: ['', [Validators.required]],
                cmd_0296: ['', [Validators.required]],
                cmd_0297: ['', [Validators.required]],
                cmd_0298: ['', [Validators.required]],
                cmd_0299: ['', [Validators.required]],
                cmd_0300: ['', [Validators.required]],
                // cmd_0301: [''],
                // cmd_0302: [''],
                // cmd_0303: [''],
                // cmd_0304: [''],
                // cmd_0305: [''],
                // cmd_0306: [''],
                cmd_0019:  ['', [Validators.required]],
                cmd_0020:[null,[Validators.required,this.dateFormatValidator]],
                 

            });    
                console.log(this.TannerClinicFroms);

        } 
        public getSuffixData() {
          let params: {};
          //this.spinner.show();
          this.onboardService.getSuffixData(params).subscribe(
            data => {
              if (data['success']) {
                this.suffix = data.data.map(i => ({
                  ...i,
                  id: i.id.toString()
                }));
              } else {
                this.notiService.showError(data.error, '', 3000);
              }
              //this.spinner.hide();
            },
            error => {
              //this.spinner.hide();
            }
          );
        }
        public getCredentialsData() {
          let params: {};
          //this.spinner.show();
          this.onboardService.getCredentialsData(params).subscribe(
            data => {
              if (data['success']) {
                this.credentials = data.data.map(i => ({
                  ...i,
                  id: i.id.toString()
                }));
              } else {
                this.notiService.showError(data.error, '', 3000);
              }
              //this.spinner.hide();
            },
            error => {
              //this.spinner.hide();
            }
          );
        }
        public getSpecialtyData() {
          let params: {};
          //this.spinner.show();
          this.onboardService.getSpecialtyData(params).subscribe(
            data => {
              if (data['success']) {
                this.specialty = data.data.map(i => ({
                  ...i,
                  id: i.id.toString()
                }));
              } else {
                this.notiService.showError(data.error, '', 3000);
              }
              //this.spinner.hide();
            },
            error => {
              //this.spinner.hide();
            }
          );
        }
        public getSpecialtySecondaryData() {
          let params: {};
          //this.spinner.show();
          this.onboardService.getSpecialtySecondaryData(params).subscribe(
            data => {
              if (data['success']) {
                this.specialtySecondary = data.data.map(i => ({
                  ...i,
                  id: i.id.toString()
                }));
              } else {
                this.notiService.showError(data.error, '', 3000);
              }
              //this.spinner.hide();
            },
            error => {
              //this.spinner.hide();
            }
          );
        }
        public getStatesData() {
          let params: {};
          //this.spinner.show();
          this.onboardService.getStatesData(params).subscribe(
            data => {
              if (data['success']) {
                this.states = data.data.map(i => ({
                  ...i,
                  id: i.id.toString()
                }));
              } else {
                this.notiService.showError(data.error, '', 3000);
              }
              //this.spinner.hide();
            },
            error => {
              //this.spinner.hide();
            }
          );
        }
        public getLanguagesData() {
          let params: {};
          //this.spinner.show();
          this.onboardService.getLanguagesData(params).subscribe(
            data => {
              if (data['success']) {
                this.languages = data.data.map(i => ({
                  ...i,
                  id: i.id.toString()
                }));
              } else {
                this.notiService.showError(data.error, '', 3000);
              }
              //this.spinner.hide();
            },
            error => {
              //this.spinner.hide();
            }
          );
        }
        public getsecondaryLanguagesData() {
          let params: {};
          //this.spinner.show();
          this.onboardService.getsecondaryLanguagesData(params).subscribe(
            data => {
              if (data['success']) {
                this.secondaryLanguages = data.data.map(i => ({
                  ...i,
                  id: i.id.toString()
                }));
              } else {
                this.notiService.showError(data.error, '', 3000);
              }
              //this.spinner.hide();
            },
            error => {
              //this.spinner.hide();
            }
          );
        }
      
        public gettertiaryLanguagesData() {
          let params: {};
          //this.spinner.show();
          this.onboardService.gettertiaryLanguagesData(params).subscribe(
            data => {
              if (data['success']) {
                this.tertiaryLanguages = data.data.map(i => ({
                  ...i,
                  id: i.id.toString()
                }));
              } else {
                this.notiService.showError(data.error, '', 3000);
              }
              //this.spinner.hide();
            },
            error => {
              //this.spinner.hide();
            }
          );
        }
      
        public getquaternaryLanguagesData() {
          let params: {};
          //this.spinner.show();
          this.onboardService.getquaternaryLanguagesData(params).subscribe(
            data => {
              if (data['success']) {
                this.quaternaryLanguages = data.data.map(i => ({
                  ...i,
                  id: i.id.toString()
                }));
              } else {
                this.notiService.showError(data.error, '', 3000);
              }
              //this.spinner.hide();
            },
            error => {
              //this.spinner.hide();
            }
          );
        }
        public getAlternateLicenseStateData() {
          let params: {};
          //this.spinner.show();
          this.onboardService.getAlternateLicenseStateData(params).subscribe(
            data => {
              if (data['success']) {
                this.altLicenseState = data.data.map(i => ({
                  ...i,
                  id: i.id.toString()
                }));
              } else {
                this.notiService.showError(data.error, '', 3000);
              }
              //this.spinner.hide();
            },
            error => {
              //this.spinner.hide();
            }
          );
        }
        public getAltCdsStatesData() {
          let params: {};
          //this.spinner.show();
          this.onboardService.getAltCdsStatesData(params).subscribe(
            data => {
              if (data['success']) {
                this.AltCdsStates = data.data.map(i => ({
                  ...i,
                  id: i.id.toString()
                }));
              } else {
                this.notiService.showError(data.error, '', 3000);
              }
              //this.spinner.hide();
            },
            error => {
              //this.spinner.hide();
            }
          );
        }
        public getboardstatus() {
          let params: {};
          //this.spinner.show();
          this.onboardService.getboardstatus(params).subscribe(
          data => {
          if (data['success']) {
          this.boardStatus = data.data.map(i => ({
          ...i,
          id: i.id.toString(),
          label:i.name
          }));
          } else {
          this.notiService.showError(data.error, '', 3000);
          }
          //this.spinner.hide();
          },
          error => {
          //this.spinner.hide();
          }
          );
          }
          public getBoardCertificationData() {
            let params: {};
            //this.spinner.show();
            this.onboardService.getBoardCertificationData(params).subscribe(
              data => {
                if (data['success']) {
                  this.boardCertification = data.data.map(i => ({
                    ...i,
                    id: i.id.toString()
                  }));
                } else {
                  this.notiService.showError(data.error, '', 3000);
                }
                //this.spinner.hide();
              },
              error => {
                //this.spinner.hide();
              }
            );
          }  
          public getPreClaimData() {
            let params: {};
            //this.spinner.show();
            this.onboardService.getPreClaimData(params).subscribe(
              data => {
                if (data['success']) {
                  this.preClaim = data.data.map(i => ({
                    ...i,
                    id: i.id.toString()
                  }));
                } else {
                  this.notiService.showError(data.error, '', 3000);
                }
                //this.spinner.hide();
              },
              error => {
                //this.spinner.hide();
              }
            );
          }
          public getAggregatesData() {
            let params: {};
            //this.spinner.show();
            this.onboardService.getAggregatesData(params).subscribe(
              data => {
                if (data['success']) {
                  this.aggregates = data.data.map(i => ({
                    ...i,
                    id: i.id.toString()
                  }));
                } else {
                  this.notiService.showError(data.error, '', 3000);
                }
                //this.spinner.hide();
              },
              error => {
                //this.spinner.hide();
              }
            );
          }
          public getStaffStatusData() {
            let params: {};
            //this.spinner.show();
            this.onboardService.getStaffStatusData(params).subscribe(
              data => {
                if (data['success']) {
                  // console.log(data.data)
                  this.staffStatus = data.data.map(i => ({
                    ...i,
                    id: i.id.toString()
                  }));
                } else {
                  this.notiService.showError(data.error, '', 3000);
                }
                //this.spinner.hide();
              },
              error => {
                //this.spinner.hide();
              }
            );
          }
          public getMediCertifications() {
            let params: {};
            //this.spinner.show();
            this.onboardService.getMediCertifications(params).subscribe(
              data => {
                if (data['success']) {
                  this.mediCertifications = data.data.map(i => ({
                    ...i,
                    id: i.id.toString()
                  }));
                } else {
                  this.notiService.showError(data.error, '', 3000);
                }
                //this.spinner.hide();
              },
              error => {
                //this.spinner.hide();
              }
            );
          }
          public getDegreeData() {
            let params: {};
            this.onboardService.getDegreeData(params).subscribe(
              data => {
                if (data['success']) {
                  this.degree = data.data.map(i => ({
                    ...i,
                    id: i.id.toString()
                  }));
                } else {
                  this.notiService.showError(data.error, '', 3000);
                }
              },
              error => {
              }
            );
          }
         
          public validationsDataAdd(event?) {
            let yesNoQuestionIds = {
              cmd_0232: 'cmd_0255',
              cmd_0233: 'cmd_0257',
              cmd_0234: 'cmd_0259',
              cmd_0235: 'cmd_0261',
              cmd_0236: 'cmd_0263',
              cmd_0237: 'cmd_0265',
              cmd_0238: 'cmd_0267',
              cmd_0239: 'cmd_0269',
              cmd_0240: 'cmd_0271',
              cmd_0241: 'cmd_0273',
              cmd_0242: 'cmd_0275',
              cmd_0243: 'cmd_0277',
              cmd_0244: 'cmd_0279',
              cmd_0245: 'cmd_0281',
              cmd_0246: 'cmd_0283',
              cmd_0247: 'cmd_0285',
              cmd_0248: 'cmd_0287',
              cmd_0249: 'cmd_0289',
              cmd_0250: 'cmd_0291',
              cmd_0251: 'cmd_0293',
              cmd_0252: 'cmd_0295',
              cmd_0253: 'cmd_0297',
              cmd_0254: 'cmd_0299'
            };
            
            // ID of respective explanation section
            let yesNoQuestionExplanation = [
              
              'cmd_0255',
              'cmd_0257',
              'cmd_0259',
              'cmd_0261',
              'cmd_0263',
              'cmd_0265',
              'cmd_0267',
              'cmd_0269',
              'cmd_0271',
              'cmd_0273',
              'cmd_0275',
              'cmd_0277',
              'cmd_0279',
              'cmd_0281',
              'cmd_0283',
              'cmd_0285',
              'cmd_0287',
              'cmd_0289',
              'cmd_0291',
              'cmd_0293',
              'cmd_0295',
              'cmd_0297',
              'cmd_0299'
            ];
            let yesNoExplanationIds = {
              
              cmd_0232: 'cmd_0256',
              cmd_0233: 'cmd_0258',
              cmd_0234: 'cmd_0260',
              cmd_0235: 'cmd_0262',
              cmd_0236: 'cmd_0264',
              cmd_0237: 'cmd_0266',
              cmd_0238: 'cmd_0268',
              cmd_0239: 'cmd_0270',
              cmd_0240: 'cmd_0272',
              cmd_0241: 'cmd_0274',
              cmd_0242: 'cmd_0276',
              cmd_0243: 'cmd_0278',
              cmd_0244: 'cmd_0280',
              cmd_0245: 'cmd_0282',
              cmd_0246: 'cmd_0284',
              cmd_0247: 'cmd_0286',
              cmd_0248: 'cmd_0288',
              cmd_0249: 'cmd_0290',
              cmd_0250: 'cmd_0292',
              cmd_0251: 'cmd_0294',
              cmd_0252: 'cmd_0296',
              cmd_0253: 'cmd_0298',
              cmd_0254: 'cmd_0300'
              
            };
            let yesNoExplanation = [
                'cmd_0256',
                'cmd_0258',
                'cmd_0260',
                'cmd_0262',
                'cmd_0264',
                'cmd_0266',
                'cmd_0268',
                'cmd_0270',
                'cmd_0272',
                'cmd_0274',
                'cmd_0276',
                'cmd_0278',
                'cmd_0280',
                'cmd_0282',
                'cmd_0284',
                'cmd_0286',
                'cmd_0288',
                'cmd_0290',
                'cmd_0292',
                'cmd_0294',
                'cmd_0296',
                'cmd_0298',
                'cmd_0300'

            ];
            this.questionCounter = 0;this.questionCounters = 0;
            for (const key in yesNoQuestionIds) {
              if (yesNoQuestionIds.hasOwnProperty(key)) {
                // console.log(yesNoQuestionIds[key]);
                const element = this.TannerClinicFroms.controls[key].value;
                // console.log(element);
                if (element == 1) {
                  this.questionCounter++;
                }
              }
            }
            // console.log(this.questionCounter);
            for (this.indexKey = 0; this.indexKey < 23; this.indexKey++) {
              // console.log(yesNoQuestionExplanation[0]);
              if (this.indexKey < this.questionCounter) {
                // console.log(this.TannerClinicFroms.controls[yesNoQuestionExplanation[this.indexKey]])
                if (this.TannerClinicFroms.controls[yesNoQuestionExplanation[this.indexKey]].value == '' || this.TannerClinicFroms.controls[yesNoQuestionExplanation[this.indexKey]].value == null) {
                  // console.log('setting error')
                this.TannerClinicFroms.controls[yesNoQuestionExplanation[this.indexKey]].setErrors(
                  {
                    errors: true
                  }
                );
                }
              } else {
                // console.log('removing error');
                // console.log('else error');
                this.TannerClinicFroms.controls[yesNoQuestionExplanation[this.indexKey]].setErrors(
                  null
                );
              }
            }
            for (const key in yesNoExplanationIds) {
              if (yesNoExplanationIds.hasOwnProperty(key)) {
                // console.log(yesNoExplanationIds[key]);
                const element = this.TannerClinicFroms.controls[key].value;
                // console.log(element);
                if (element == 1) {
                  this.questionCounters++;
                }
              }
            }
            // console.log(this.questionCounter);
            for (this.indexKeys = 0; this.indexKeys < 23; this.indexKeys++) {
              // console.log(yesNoExplanation[0]);
              if (this.indexKeys < this.questionCounters) {
                // console.log(this.TannerClinicFroms.controls[yesNoExplanation[this.indexKeys]])
                if (this.TannerClinicFroms.controls[yesNoExplanation[this.indexKeys]].value == '' || this.TannerClinicFroms.controls[yesNoExplanation[this.indexKeys]].value == null) {
                  // console.log('setting error')
                  console.log(this.TannerClinicFroms.controls[yesNoExplanation[this.indexKeys]]);
                this.TannerClinicFroms.controls[yesNoExplanation[this.indexKeys]].setErrors(
                  {
                    errors: true
                  }
                );
                }
              } else {
                  // console.log(this.TannerClinicFroms.controls[yesNoExplanation[this.indexKeys]]);
                // console.log('else error');
                this.TannerClinicFroms.controls[yesNoExplanation[this.indexKeys]].setErrors(
                  null
              
                );
              }
            }
          }  
        saveForm(type) {
            this.isSubmit = true;
            this.validationsDataAdd()
            console.log(this.TannerClinicFroms);
            if (type == 'submit') {
              if (this.TannerClinicFroms.invalid) {
                this.notiService.showError(
                  'Please Fill the all mandatory fields to continue',
                  '',
                  3000
                );
                for (const key in this.TannerClinicFroms.controls) {
                  if (this.TannerClinicFroms.controls.hasOwnProperty(key)) {
                    const element = this.TannerClinicFroms.controls[key];
                    if(element.status=="INVALID"){
                    console.log(key + " " + element.status)
                    }
                  }
                }
              }
              else {
                if (this.TannerClinicFroms.controls['cmd_0019'].value == null || this.TannerClinicFroms.controls['cmd_0019'].value == '') {
                  this.notiService.showError('Please add your Signature')
                  return
                }
                let data = {
                  formType: type,
                  formValues: this.TannerClinicFroms.value
                };
                console.log("Form has been submitted successfully");
                this.onSubmit.emit(data);
                console.log(this.TannerClinicFroms);
        
              }
            } else {
              let data = {
                formType: type,
                formValues: this.TannerClinicFroms.value,
                formId: 41
              };
              this.onSubmit.emit(data);
              this.onClose.emit('close modal');
            }
        }
        close() {
            this.onClose.emit('close modal');
        }
}   