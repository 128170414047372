<div id="caliPhyStyle">
    <div class="cotainer-fluid mx-1">
        <div class="header">
            <div class="row">
                <div class="col-md-12">
                    <img src="./assets/images/Califorinia.png" alt="Credential my Doc" class="imStyle">
                    <h2 class="hStyle">California Participating Physician Application</h2>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <p style="text-align: justify;">
                        <b>This form should be typed or legibly printed in black or blue ink.</b>If more space is
                        needed, send additional sheets to <u style="color: blue;">operations@bassmedicalgroup.com</u>
                        and reference the question being answered. Please do not use abbreviations when completing the
                        application.
                    </p>
                </div>
            </div>
        </div>
        <div class="section">
            <form [formGroup]="caliPhy">
                <table class="table tabwidth" border="1">
                    <thead class="tabhead">
                        <tr>
                            <th class="tabTh">I. IDENTIFYING INFORMATION</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px">LastName</label>
                                        <input class="form-control onboard" formControlName="9"
                                            [class.is-invalid]="(caliPhy.controls['9'].touched && caliPhy.controls['9'].invalid) || (caliPhy.controls['9'].invalid && isSubmit)"
                                            id="9" name="9" type="text" placeholder="Last Name"
                                            data-id="managing_contact_name" (change)="onChangeLastName($event)">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['9'].touched && caliPhy.controls['9'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['9'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px">First Name</label>
                                        <input class="form-control onboard" formControlName="8"
                                            [class.is-invalid]="(caliPhy.controls['8'].touched && caliPhy.controls['8'].invalid) || (caliPhy.controls['8'].invalid && isSubmit)"
                                            id="8" name="8" type="text" placeholder="First Name"
                                            data-id="managing_contact_name" (change)="onChangeFirstName($event)">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['8'].touched && caliPhy.controls['8'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['8'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <label style="font-weight: normal;font-size: 14px">Middle Name or Initial<span *ngIf="caliPhy.controls['809'].invalid" class="mandatory">*</span> </label>
                                    
            
                                        <input class="form-control onboard" formControlName="809" (blur)="handleNAchange('cmd_NA_01')" [class.is-invalid]="caliPhy.controls['809'].invalid" id="809" name="809" class="form-control"
                                        placeholder= "Middle Name" formControlName="809" type="text" data-id="managing_contact_name" />
                                        <div class="mandatory" *ngIf="caliPhy.controls['809'].invalid">
                                          <small>required</small>
                                        
                                        </div>
                                    </div>

                                        
                                    <div class="form-check">
                                      <input formControlName="cmd_NA_01" (ngModelChange)="handleNAchange('cmd_NA_01')"
                                      class="form-check-input" type="checkbox" value="1" id="cmd_NA_01">
                                      <label class="form-check-label" for="cmd_NA_01">
                                        N/A
                                      </label>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px">Home Mailing Street
                                            Address</label>
                                        <input class="form-control onboard" formControlName="16"
                                            [class.is-invalid]="(caliPhy.controls['16'].touched && caliPhy.controls['16'].invalid) || (caliPhy.controls['16'].invalid && isSubmit)"
                                            id="16" name="16" type="text" data-id="managing_contact_name"
                                            placeholder="Home Address1">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['16'].touched && caliPhy.controls['16'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['16'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <label style="font-weight: normal;font-size: 14px;padding-top:15px;"></label>
                                        <input class="form-control onboard" formControlName="17"
                                            [class.is-invalid]="(caliPhy.controls['17'].touched && caliPhy.controls['17'].invalid) || (caliPhy.controls['17'].invalid && isSubmit)"
                                            id="17" name="17" type="text" data-id="managing_contact_name"
                                            placeholder="Home Address2">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['17'].touched && caliPhy.controls['17'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['17'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <label style="font-weight: normal;font-size: 14px">Phone Number</label>
                                        <input class="form-control onboard" formControlName="708"
                                            [class.is-invalid]="(caliPhy.controls['708'].touched && caliPhy.controls['708'].invalid) || (caliPhy.controls['708'].invalid && isSubmit)"
                                            id="708" name="708" type="text" placeholder="Phone Number"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['708'].touched && caliPhy.controls['708'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['708'].errors?.required">
                                                Home phone is required and format is xxx-xxx-xxxx
                                            </small>
                                            <small class="text-danger" *ngIf="caliPhy.controls['708'].errors?.pattern">
                                                Required Pattern xxx-xxx-xxxx
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <label style="font-weight: normal;font-size: 14px">Email</label>
                                        <input class="form-control onboard" formControlName="759"
                                            [class.is-invalid]="(caliPhy.controls['759'].touched && caliPhy.controls['759'].invalid) || (caliPhy.controls['759'].invalid && isSubmit)"
                                            id="759" name="759" type="text" placeholder="Email"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['759'].touched && caliPhy.controls['759'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['759'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px">Home City</label>
                                        <input class="form-control onboard" formControlName="18"
                                            [class.is-invalid]="(caliPhy.controls['18'].touched && caliPhy.controls['18'].invalid) || (caliPhy.controls['18'].invalid && isSubmit)"
                                            id="18" name="18" type="text" data-id="managing_contact_name"
                                            placeholder="Home Address1">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['18'].touched && caliPhy.controls['18'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['18'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px">Home US county</label>
                                        <div class="form-group selectDrop dropDrownSet">
                                            <ng-select [items]="county" bindLabel="name" placeholder="Select County"
                                                bindValue="id"
                                                [class.is-invalid]="(caliPhy.controls['19'].touched && caliPhy.controls['19'].invalid) || (caliPhy.controls['19'].invalid && isSubmit)"
                                                formControlName="19">
                                            </ng-select>
                                            <div class="formLabel col-md-11"
                                                *ngIf="caliPhy.controls['19'].touched && caliPhy.controls['19'].invalid">
                                                <small class="text-danger"
                                                    *ngIf="caliPhy.controls['19'].errors?.required">
                                                    required
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px">Home State or Country</label>
                                        <div class="form-group selectDrop dropDrownSet">
                                            <ng-select [items]="states" bindLabel="name" placeholder="Select States"
                                                bindValue="id"
                                                [class.is-invalid]="(caliPhy.controls['20'].touched && caliPhy.controls['20'].invalid) || (caliPhy.controls['20'].invalid && isSubmit)"
                                                formControlName="20">
                                            </ng-select>
                                            <div class="formLabel col-md-11"
                                                *ngIf="caliPhy.controls['20'].touched && caliPhy.controls['20'].invalid">
                                                <small class="text-danger"
                                                    *ngIf="caliPhy.controls['20'].errors?.required">
                                                    required
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <label style="font-weight: normal;font-size: 14px">Home Zip</label>
                                        <input class="form-control onboard" formControlName="21"
                                            [class.is-invalid]="(caliPhy.controls['21'].touched && caliPhy.controls['21'].invalid) || (caliPhy.controls['21'].invalid && isSubmit)"
                                            id="21" name="21" type="text" placeholder="Home Zip"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['21'].touched && caliPhy.controls['21'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['21'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px">Birthdate</label>
                                        <div class="input-group">
                                            <input class="form-control text" ngbDatepicker #d1="ngbDatepicker"
                                                formControlName="31"
                                                [class.is-invalid]="(caliPhy.controls['31'].touched && caliPhy.controls['31'].invalid) || (caliPhy.controls['31'].invalid && isSubmit)"
                                                id="31" name="31" type="text" placeholder="Birth Date" (blur)="clearInputIfInvalidMandi('31')"
                                                data-id="managing_contact_name">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary calendar" (click)="d1.toggle()"
                                                    type="button"><i class="fas fa-calendar-alt"></i></button>
                                            </div>
                                        </div>
                                        <div class="text-danger" *ngIf="(caliPhy.controls['31'].invalid&&caliPhy.controls['31'].touched) && (caliPhy.controls['31'].value==''|| caliPhy.controls['31'].value==null) " >
                                            <small >
                                            required
                                            </small>
                                          
                                          </div>
                                          <small class="text-danger" *ngIf="caliPhy.get('31').errors?.invalidDateFormat">
                                            Only Date Format is allowed(MM/DD/YYYY)                </small>
                                          <small class="text-danger" *ngIf="caliPhy.get('31').errors?.invalidMonth">
                                          Month is out of range
                                          </small>
                                          <small class="text-danger" *ngIf="caliPhy.get('31').errors?.invalidDateyear">
                                            year is out of range(1900-2099)                  </small>
                                            <small class="text-danger" *ngIf="caliPhy.get('31').errors?.invalidDateRange">
                                              date is out of range
                                              </small>
                                    </div>
                                    <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px">BirthPlace(City)</label>
                                        <input class="form-control onboard" formControlName="810"
                                            [class.is-invalid]="(caliPhy.controls['810'].touched && caliPhy.controls['810'].invalid) || (caliPhy.controls['810'].invalid && isSubmit)"
                                            id="810" name="810" type="text" placeholder="BirthPlace City"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['810'].touched && caliPhy.controls['810'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['810'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <label
                                            style="font-weight: normal;font-size: 14px">BirthPlace(State/Country)</label>
                                        <div class="form-group selectDrop dropDrownSet">
                                            <ng-select [items]="states" bindLabel="name" placeholder="Select States"
                                                bindValue="id"
                                                [class.is-invalid]="(caliPhy.controls['289'].touched && caliPhy.controls['289'].invalid) || (caliPhy.controls['289'].invalid && isSubmit)"
                                                formControlName="289">
                                            </ng-select>
                                            <div class="formLabel col-md-11"
                                                *ngIf="caliPhy.controls['289'].touched && caliPhy.controls['289'].invalid">
                                                <small class="text-danger"
                                                    *ngIf="caliPhy.controls['289'].errors?.required">
                                                    required
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="col-md-6">
                                    <label style="font-weight: normal;font-size: 14px">Citizenship (if not a United
                                        States citizen, please include copy of Alien Registration Card )</label>
                                    <input class="form-control onboard" formControlName="292"
                                        [class.is-invalid]="(caliPhy.controls['292'].touched && caliPhy.controls['292'].invalid) || (caliPhy.controls['292'].invalid && isSubmit)"
                                        id="292" Placeholder="Citizenship" name="292" type="text"
                                        data-id="managing_contact_name">
                                    <div class="formLabel col-md-11"
                                        *ngIf="caliPhy.controls['292'].touched && caliPhy.controls['292'].invalid">
                                        <small class="text-danger" *ngIf="caliPhy.controls['292'].errors?.required">
                                            required
                                        </small>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <label style="font-weight: normal;font-size: 14px">Social Security Number</label>
                                    <input class="form-control onboard" formControlName="32"
                                        [class.is-invalid]="(caliPhy.controls['32'].touched && caliPhy.controls['32'].invalid) || (caliPhy.controls['32'].invalid && isSubmit)"
                                        id="32" Placeholder="SSN" name="32" type="text" data-id="managing_contact_name">
                                    <div class="formLabel col-md-11"
                                        *ngIf="caliPhy.controls['32'].touched && caliPhy.controls['32'].invalid">
                                        <small class="text-danger" *ngIf="caliPhy.controls['32'].errors?.required">
                                            required
                                        </small>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-6">
                                        <label
                                            style="font-weight: normal;font-size: 14px;padding-right:10px;">Gender</label>
                                        <!-- <div class="col-md-3" style="padding-top:30px">                                         -->
                                        <div class="form-group selectDrop dropDrownSet">
                                            <ng-select [items]="gender" bindLabel="name" placeholder="Select Gender"
                                                bindValue="id"
                                                [class.is-invalid]="(caliPhy.controls['30'].touched && caliPhy.controls['30'].invalid) || (caliPhy.controls['30'].invalid && isSubmit)"
                                                formControlName="30">
                                            </ng-select>
                                            <div class="formLabel col-md-11"
                                                *ngIf="caliPhy.controls['30'].touched && caliPhy.controls['30'].invalid">
                                                <small class="text-danger"
                                                    *ngIf="caliPhy.controls['30'].errors?.required">
                                                    required
                                                </small>
                                            </div>
                                        </div>
                                        <!-- </div> -->
                                    </div>
                                    <div class="col-md-6">
                                        <label style="font-weight: normal;font-size: 14px">Race/Ethnicity
                                            (required)</label>
                                        <!-- <div class="col-md-3" style="padding-top:30px">                                         -->
                                        <div class="form-group selectDrop dropDrownSet">
                                            <ng-select [items]="race" bindLabel="name"
                                                [class.is-invalid]="(caliPhy.controls['741'].touched && caliPhy.controls['741'].invalid) || (caliPhy.controls['741'].invalid && isSubmit)"
                                                placeholder="Select Race" bindValue="id" formControlName="741">
                                            </ng-select>
                                            <div class="formLabel col-md-11"
                                                *ngIf="caliPhy.controls['741'].touched && caliPhy.controls['741'].invalid">
                                                <small class="text-danger"
                                                    *ngIf="caliPhy.controls['741'].errors?.required">
                                                    required
                                                </small>
                                            </div>
                                        </div>
                                        <!-- </div> -->
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="col-md-5">
                                    <label style="font-weight: normal;font-size: 14px">Please list any foreign languages
                                        you speak fluently:</label>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="col-md-3" style="padding-top:10px">
                                    <div class="form-group selectDrop dropDrownSet">
                                        <ng-select [items]="languages" bindLabel="name"
                                            [class.is-invalid]="(caliPhy.controls['277'].touched && caliPhy.controls['277'].invalid) || (caliPhy.controls['277'].invalid && isSubmit)"
                                            placeholder="Select langauge" bindValue="id" formControlName="277">
                                        </ng-select>
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['277'].touched && caliPhy.controls['277'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['277'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3" style="padding-top:10px">
                                    <div class="form-group selectDrop dropDrownSet">
                                        <ng-select [items]="secondaryLanguages" bindLabel="name"
                                            [class.is-invalid]="(caliPhy.controls['1535'].touched && caliPhy.controls['1535'].invalid) || (caliPhy.controls['1535'].invalid && isSubmit)"
                                            placeholder="Select langauge" bindValue="id" formControlName="1535">
                                        </ng-select>
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['1535'].touched && caliPhy.controls['1535'].invalid">
                                            <small class="text-danger"
                                                *ngIf="caliPhy.controls['1535'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3" style="padding-top:10px">
                                    <div class="form-group selectDrop dropDrownSet">
                                        <ng-select [items]="tertiaryLanguages" bindLabel="name"
                                            [class.is-invalid]="(caliPhy.controls['1536'].touched && caliPhy.controls['1536'].invalid) || (caliPhy.controls['1536'].invalid && isSubmit)"
                                            placeholder="Select langauge" bindValue="id" formControlName="1536">
                                        </ng-select>
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['1536'].touched && caliPhy.controls['1536'].invalid">
                                            <small class="text-danger"
                                                *ngIf="caliPhy.controls['1536'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3" style="padding-top:10px">
                                    <div class="form-group selectDrop dropDrownSet">
                                        <ng-select [items]="quaternaryLanguages" bindLabel="name"
                                            [class.is-invalid]="(caliPhy.controls['2207'].touched && caliPhy.controls['2207'].invalid) || (caliPhy.controls['2207'].invalid && isSubmit)"
                                            placeholder="Select langauge" bindValue="id" formControlName="2207">
                                        </ng-select>
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['2207'].touched && caliPhy.controls['2207'].invalid">
                                            <small class="text-danger"
                                                *ngIf="caliPhy.controls['2207'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>
                                    You will receive an email to have Paula Hewitt act as a surrogate in order to apply
                                    for a BASS Medicare number in the I+A system. When the
                                    email is sent from Noridian, please accept the request.
                                </p>
                                <input class="form-control onboard" formControlName="cmd_960"
                                    [class.is-invalid]="(caliPhy.controls['cmd_960'].touched && caliPhy.controls['cmd_960'].invalid) || (caliPhy.controls['cmd_960'].invalid && isSubmit)"
                                    id="cmd_960" name="cmd_960" type="text" data-id="managing_contact_name">
                                <div class="formLabel col-md-11"
                                    *ngIf="caliPhy.controls['cmd_960'].touched && caliPhy.controls['cmd_960'].invalid">
                                    <small class="text-danger" *ngIf="caliPhy.controls['cmd_960'].errors?.required">
                                        required
                                    </small>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th class="tabSideHeading">CAQH PROVIEW</th>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-12">
                                        <p>Please list the username and password for your CAQH Proview account. CAQH is
                                            a universal database which is accessed by health plans for credentialing
                                            information. You will need to create a complete profile at
                                            https://proview.caqh.org. Please make sure to save your work on every page.
                                            Our credentialing team will add the billing and contact info and will
                                            maintain the profile for you; we just need you to do the initial setup. If
                                            you need assistance with CAQH, please reach out to the CAQH help desk.</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <b>CAQH Provider Help Desk:</b>
                                    </div>
                                    <div class="col-md-12">
                                        Phone: 1-888-599-1771
                                    </div>
                                    <div class="col-md-12">
                                        Phone Hours:
                                    </div>
                                    <div class="col-md-12">
                                        Monday – Thursday: 7 AM – 9 PM (EST)
                                    </div>
                                    <div class="col-md-12">
                                        Friday: 7 AM – 7 PM (EST)
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px">Provider Number</label>
                                        <input class="form-control onboard" formControlName="333"
                                            [class.is-invalid]="(caliPhy.controls['333'].touched && caliPhy.controls['333'].invalid) || (caliPhy.controls['333'].invalid && isSubmit)"
                                            id="333" name="333" type="text" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['333'].touched && caliPhy.controls['333'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['333'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px">User Name</label>
                                        <input class="form-control onboard" formControlName="334"
                                            [class.is-invalid]="(caliPhy.controls['334'].touched && caliPhy.controls['334'].invalid) || (caliPhy.controls['334'].invalid && isSubmit)"
                                            id="334" name="334" type="text" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['334'].touched && caliPhy.controls['334'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['334'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px">Password</label>
                                        <input class="form-control onboard" formControlName="335"
                                            [class.is-invalid]="(caliPhy.controls['335'].touched && caliPhy.controls['335'].invalid) || (caliPhy.controls['335'].invalid && isSubmit)"
                                            id="335" name="335" type="text" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['335'].touched && caliPhy.controls['335'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['335'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr class="tabSideHeading">
                            <th class="tabMainHeading">MEDICAL LICENSURE/REGISTRATION</th>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px">California Medical
                                            License</label>
                                        <input class="form-control onboard" formControlName="293"
                                            [class.is-invalid]="(caliPhy.controls['293'].touched && caliPhy.controls['293'].invalid) || (caliPhy.controls['293'].invalid && isSubmit)"
                                            id="293" name="293" type="text" placeholder="License Number"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['293'].touched && caliPhy.controls['293'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['293'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px">Issue Date</label>
                                        <div class="input-group">
                                            <input class="form-control" formControlName="294" ngbDatepicker
                                                #d2="ngbDatepicker"
                                                [class.is-invalid]="(caliPhy.controls['294'].touched && caliPhy.controls['294'].invalid) || (caliPhy.controls['294'].invalid && isSubmit)"
                                                id="294" name="294" type="text" placeholder="License IssueDate" (blur)="clearInputIfInvalidMandi('294')"
                                                data-id="managing_contact_name">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary calendar" (click)="d2.toggle()"
                                                    type="button"><i class="fas fa-calendar-alt"></i></button>
                                            </div>
                                        </div>
                                        <div class="mandatory" *ngIf="(caliPhy.controls['294'].invalid&&caliPhy.controls['294'].touched) && (caliPhy.controls['294'].value==''|| caliPhy.controls['294'].value==null) " >
                                            <small >
                                            required
                                            </small>
                                          
                                          </div>
                                          <small class="text-danger" *ngIf="caliPhy.get('294').errors?.invalidDateFormat">
                                            Only Date Format is allowed(MM/DD/YYYY)                </small>
                                          <small class="text-danger" *ngIf="caliPhy.get('294').errors?.invalidMonth">
                                          Month is out of range
                                          </small>
                                          <small class="text-danger" *ngIf="caliPhy.get('294').errors?.invalidDateyear">
                                            year is out of range(1900-2099)                  </small>
                                            <small class="text-danger" *ngIf="caliPhy.get('294').errors?.invalidDateRange">
                                              date is out of range
                                              </small>
                                    </div>
                                    <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px">Expiration Date</label>
                                        <div class="input-group">
                                            <input class="form-control onboard datepicker" formControlName="295"
                                                ngbDatepicker #d3="ngbDatepicker"
                                                [class.is-invalid]="(caliPhy.controls['295'].touched && caliPhy.controls['295'].invalid) || (caliPhy.controls['295'].invalid && isSubmit)"
                                                id="295" name="295" type="text" placeholder="License ExpDate" (blur)="clearInputIfInvalidMandi('295')"
                                                data-id="managing_contact_name">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary calendar" (click)="d3.toggle()"
                                                    type="button"><i class="fas fa-calendar-alt"></i></button>
                                            </div>
                                        </div>
                                        <div class="mandatory" *ngIf="(caliPhy.controls['295'].invalid&&caliPhy.controls['295'].touched) && (caliPhy.controls['295'].value==''|| caliPhy.controls['295'].value==null) " >
                                            <small >
                                            required
                                            </small>
                                          
                                          </div>
                                          <small class="text-danger" *ngIf="caliPhy.get('295').errors?.invalidDateFormat">
                                            Only Date Format is allowed(MM/DD/YYYY)                </small>
                                          <small class="text-danger" *ngIf="caliPhy.get('295').errors?.invalidMonth">
                                          Month is out of range
                                          </small>
                                          <small class="text-danger" *ngIf="caliPhy.get('295').errors?.invalidDateyear">
                                            year is out of range(1900-2099)                  </small>
                                            <small class="text-danger" *ngIf="caliPhy.get('295').errors?.invalidDateRange">
                                              date is out of range
                                              </small>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-6">
                                        <label style="font-weight: normal;font-size: 14px">Drug Enforcement
                                            Administration (DEA)</label>
                                        <input class="form-control onboard" formControlName="309"
                                            [class.is-invalid]="(caliPhy.controls['309'].touched && caliPhy.controls['309'].invalid) || (caliPhy.controls['309'].invalid && isSubmit)"
                                            id="309" name="309" type="text" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['309'].touched && caliPhy.controls['309'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['309'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <label style="font-weight: normal;font-size: 14px">Expiration Date</label>
                                        <div class="input-group">
                                            <input class="form-control onboard datepicker" formControlName="311"
                                                ngbDatepicker #d4="ngbDatepicker"
                                                [class.is-invalid]="(caliPhy.controls['311'].touched && caliPhy.controls['311'].invalid) || (caliPhy.controls['311'].invalid && isSubmit)"
                                                id="311" name="311" type="text" placeholder="DEA ExpDate" (blur)="clearInputIfInvalidMandi('311')"
                                                data-id="managing_contact_name">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary calendar" (click)="d4.toggle()"
                                                    type="button"><i class="fas fa-calendar-alt"></i></button>
                                            </div>
                                        </div>
                                        <div class="mandatory" *ngIf="(caliPhy.controls['311'].invalid&&caliPhy.controls['311'].touched) && (caliPhy.controls['311'].value==''|| caliPhy.controls['311'].value==null) " >
                                            <small >
                                            required
                                            </small>
                                          
                                          </div>
                                          <small class="text-danger" *ngIf="caliPhy.get('311').errors?.invalidDateFormat">
                                            Only Date Format is allowed(MM/DD/YYYY)                </small>
                                          <small class="text-danger" *ngIf="caliPhy.get('311').errors?.invalidMonth">
                                          Month is out of range
                                          </small>
                                          <small class="text-danger" *ngIf="caliPhy.get('311').errors?.invalidDateyear">
                                            year is out of range(1900-2099)                  </small>
                                            <small class="text-danger" *ngIf="caliPhy.get('311').errors?.invalidDateRange">
                                              date is out of range
                                              </small>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-12">
                                        <label style="font-weight: normal;font-size: 14px">National Physician Identifier
                                            (NPI)</label>
                                        <input class="form-control onboard" formControlName="1634"
                                            [class.is-invalid]="(caliPhy.controls['1634'].touched && caliPhy.controls['1634'].invalid) || (caliPhy.controls['1634'].invalid && isSubmit)"
                                            id="1634" name="1634" type="text" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['1634'].touched && caliPhy.controls['1634'].invalid">
                                            <small class="text-danger"
                                                *ngIf="caliPhy.controls['1634'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-12">
                                        <label style="font-weight: normal;font-size: 14px">Educational Commission for
                                            Foreign Medical Graduates (ECFMG) Number
                                            (applicable to foreign medical graduates):</label>
                                        <input class="form-control onboard" formControlName="514"
                                            [class.is-invalid]="(caliPhy.controls['514'].touched && caliPhy.controls['514'].invalid) || (caliPhy.controls['514'].invalid && isSubmit)"
                                            id="514" name="514" type="text" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['514'].touched && caliPhy.controls['514'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['514'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-6">
                                        <label style="font-weight: normal;font-size: 14px">Issue Date:</label>
                                        <div class="input-group">
                                            <input class="form-control onboard datepicker"
                                                [class.is-invalid]="(caliPhy.controls['515'].touched && caliPhy.controls['515'].invalid) || (caliPhy.controls['515'].invalid && isSubmit)"
                                                formControlName="515" ngbDatepicker #d5="ngbDatepicker" id="515" (blur)="clearInputIfInvalidMandi('515')"
                                                name="515" type="text" placeholder="ECFMG Issue Date"
                                                data-id="managing_contact_name">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary calendar" (click)="d5.toggle()"
                                                    type="button"><i class="fas fa-calendar-alt"></i></button>
                                            </div>
                                            
                                        </div>
                                        <div class="mandatory" *ngIf="(caliPhy.controls['515'].invalid&&caliPhy.controls['515'].touched) && (caliPhy.controls['515'].value==''|| caliPhy.controls['515'].value==null) " >
                                            <small >
                                            required
                                            </small>
                                          
                                          </div>
                                          <small class="text-danger" *ngIf="caliPhy.get('515').errors?.invalidDateFormat">
                                            Only Date Format is allowed(MM/DD/YYYY)                </small>
                                          <small class="text-danger" *ngIf="caliPhy.get('515').errors?.invalidMonth">
                                          Month is out of range
                                          </small>
                                          <small class="text-danger" *ngIf="caliPhy.get('515').errors?.invalidDateyear">
                                            year is out of range(1900-2099)                  </small>
                                            <small class="text-danger" *ngIf="caliPhy.get('515').errors?.invalidDateRange">
                                              date is out of range
                                              </small>
                                    </div>
                                    <div class="col-md-6">
                                        <label style="font-weight: normal;font-size: 14px">Valid Through Date:</label>
                                        <div class="input-group">
                                            <input class="form-control onboard datepicker"
                                                [class.is-invalid]="(caliPhy.controls['516'].touched && caliPhy.controls['516'].invalid) || (caliPhy.controls['516'].invalid && isSubmit)"
                                                formControlName="516" ngbDatepicker #d6="ngbDatepicker" id="516" (blur)="clearInputIfInvalidMandi('516')"
                                                name="516" type="text" placeholder="ECFMG Exp Date"
                                                data-id="managing_contact_name">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary calendar" (click)="d6.toggle()"
                                                    type="button"><i class="fas fa-calendar-alt"></i></button>
                                            </div>
                                           
                                        </div>
                                        <div class="mandatory" *ngIf="(caliPhy.controls['516'].invalid&&caliPhy.controls['516'].touched) && (caliPhy.controls['516'].value==''|| caliPhy.controls['516'].value==null) " >
                                            <small >
                                            required
                                            </small>
                                          
                                          </div>
                                          <small class="text-danger" *ngIf="caliPhy.get('516').errors?.invalidDateFormat">
                                            Only Date Format is allowed(MM/DD/YYYY)                </small>
                                          <small class="text-danger" *ngIf="caliPhy.get('516').errors?.invalidMonth">
                                          Month is out of range
                                          </small>
                                          <small class="text-danger" *ngIf="caliPhy.get('516').errors?.invalidDateyear">
                                            year is out of range(1900-2099)                  </small>
                                            <small class="text-danger" *ngIf="caliPhy.get('516').errors?.invalidDateRange">
                                              date is out of range
                                              </small>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-6">
                                        <label style="font-weight: normal;font-size: 14px">Medicare Number
                                            (Mandatory)</label>
                                        <input class="form-control onboard" formControlName="325"
                                            [class.is-invalid]="(caliPhy.controls['325'].touched && caliPhy.controls['325'].invalid) || (caliPhy.controls['325'].invalid && isSubmit)"
                                            id="325" name="325" type="text" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['325'].touched && caliPhy.controls['325'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['325'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <label style="font-weight: normal;font-size: 14px">Medi-Cal Number (If
                                            Applicable)</label>
                                        <input class="form-control onboard" formControlName="812"
                                            [class.is-invalid]="(caliPhy.controls['812'].touched && caliPhy.controls['812'].invalid) || (caliPhy.controls['812'].invalid && isSubmit)"
                                            id="812" name="812" type="text" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['812'].touched && caliPhy.controls['812'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['812'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr class="tabSideHeading">
                            <th class="tabMainHeading">PEER REFERENCES</th>
                        </tr>
                        <tr>
                            <td>
                                <p>
                                    List three professional references, preferably from your specialty area, not
                                    including relatives, current partners or associates in practice. If
                                    possible, include at least one member from the Medical Staff of each facility at
                                    which you have privileges.
                                </p>
                                <p>
                                    <b>Note:</b>References must be from individuals who are directly familiar with your
                                    work, either via direct clinical observation or through close
                                    working relations.
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-6">
                                        <label style="font-weight: normal;font-size: 14px"><b>Name of Reference and
                                                Address:</b></label>
                                        <input class="form-control onboard" formControlName="359"
                                            [class.is-invalid]="(caliPhy.controls['359'].touched && caliPhy.controls['359'].invalid) || (caliPhy.controls['359'].invalid && isSubmit)"
                                            id="359" name="359" type="text" placeholder="Reference1 Name"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['359'].touched && caliPhy.controls['359'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['359'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="360"
                                            [class.is-invalid]="(caliPhy.controls['360'].touched && caliPhy.controls['360'].invalid) || (caliPhy.controls['360'].invalid && isSubmit)"
                                            id="360" name="360" type="text" placeholder="Reference1 Address1"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['360'].touched && caliPhy.controls['360'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['360'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="361"
                                            [class.is-invalid]="(caliPhy.controls['361'].touched && caliPhy.controls['361'].invalid) || (caliPhy.controls['361'].invalid && isSubmit)"
                                            id="361" name="361" type="text" placeholder="Reference1 Address2"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['361'].touched && caliPhy.controls['361'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['361'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="362"
                                            [class.is-invalid]="(caliPhy.controls['362'].touched && caliPhy.controls['362'].invalid) || (caliPhy.controls['362'].invalid && isSubmit)"
                                            id="362" name="362" type="text" placeholder="Reference1 City"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['362'].touched && caliPhy.controls['362'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['362'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="365"
                                            [class.is-invalid]="(caliPhy.controls['365'].touched && caliPhy.controls['365'].invalid) || (caliPhy.controls['365'].invalid && isSubmit)"
                                            id="365" name="365" type="text" placeholder="Reference1 Zip"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['365'].touched && caliPhy.controls['365'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['365'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-6">
                                        <label
                                            style="font-weight: normal;font-size: 14px;padding-right:10px;">State</label>
                                        <!-- <div class="col-md-3" style="padding-top:30px">                                         -->
                                        <div class="form-group selectDrop dropDrownSet">
                                            <ng-select [items]="states" bindLabel="name"
                                                [class.is-invalid]="(caliPhy.controls['364'].touched && caliPhy.controls['364'].invalid) || (caliPhy.controls['364'].invalid && isSubmit)"
                                                placeholder="Select State" bindValue="id" formControlName="364">
                                            </ng-select>
                                            <div class="formLabel col-md-11"
                                                *ngIf="caliPhy.controls['364'].touched && caliPhy.controls['364'].invalid">
                                                <small class="text-danger"
                                                    *ngIf="caliPhy.controls['364'].errors?.required">
                                                    required
                                                </small>
                                            </div>
                                        </div>
                                        <!-- </div> -->
                                    </div>
                                    <div class="col-md-6">
                                        <label style="font-weight: normal;font-size: 14px">Specialty</label>
                                        <!-- <div class="col-md-3" style="padding-top:30px">                                         -->
                                        <div class="form-group selectDrop dropDrownSet">
                                            <ng-select [items]="specialty" bindLabel="name"
                                                [class.is-invalid]="(caliPhy.controls['368'].touched && caliPhy.controls['368'].invalid) || (caliPhy.controls['368'].invalid && isSubmit)"
                                                placeholder="Select Specialities" bindValue="id" formControlName="368">
                                            </ng-select>
                                            <div class="formLabel col-md-11"
                                                *ngIf="caliPhy.controls['368'].touched && caliPhy.controls['368'].invalid">
                                                <small class="text-danger"
                                                    *ngIf="caliPhy.controls['368'].errors?.required">
                                                    required
                                                </small>
                                            </div>
                                        </div>
                                        <!-- </div> -->
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-6">
                                        <label style="font-weight: normal;font-size: 14px">Phone Number:</label>
                                        <input class="form-control onboard" formControlName="369"
                                            [class.is-invalid]="(caliPhy.controls['369'].touched && caliPhy.controls['369'].invalid) || (caliPhy.controls['369'].invalid && isSubmit)"
                                            id="369" name="369" type="text" placeholder="Reference1 Phone"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['369'].touched && caliPhy.controls['369'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['369'].errors?.required">
                                                phone is required and format is xxx-xxx-xxxx
                                            </small>
                                            <small class="text-danger" *ngIf="caliPhy.controls['369'].errors?.required">
                                                pattern xxx-xxx-xxxx
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <label style="font-weight: normal;font-size: 14px">Email:</label>
                                        <input class="form-control onboard" formControlName="371"
                                            [class.is-invalid]="(caliPhy.controls['371'].touched && caliPhy.controls['371'].invalid) || (caliPhy.controls['371'].invalid && isSubmit)"
                                            id="371" name="371" type="text" placeholder="Reference1 Email"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['371'].touched && caliPhy.controls['371'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['371'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-6">
                                        <label style="font-weight: normal;font-size: 14px"><b>Name of Reference and
                                                Address:</b></label>
                                        <input class="form-control onboard" formControlName="372"
                                            [class.is-invalid]="(caliPhy.controls['372'].touched && caliPhy.controls['372'].invalid) || (caliPhy.controls['372'].invalid && isSubmit)"
                                            id="372" name="372" type="text" placeholder="Reference2 Name"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['372'].touched && caliPhy.controls['372'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['372'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="373"
                                            [class.is-invalid]="(caliPhy.controls['373'].touched && caliPhy.controls['373'].invalid) || (caliPhy.controls['373'].invalid && isSubmit)"
                                            id="373" name="373" type="text" placeholder="Reference2 Address1"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['373'].touched && caliPhy.controls['373'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['373'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="374"
                                            [class.is-invalid]="(caliPhy.controls['374'].touched && caliPhy.controls['374'].invalid) || (caliPhy.controls['374'].invalid && isSubmit)"
                                            id="374" name="374" type="text" placeholder="Reference2 Address2"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['374'].touched && caliPhy.controls['374'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['374'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="375"
                                            [class.is-invalid]="(caliPhy.controls['375'].touched && caliPhy.controls['375'].invalid) || (caliPhy.controls['375'].invalid && isSubmit)"
                                            id="375" name="375" type="text" placeholder="Reference2 City"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['375'].touched && caliPhy.controls['375'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['375'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="378"
                                            [class.is-invalid]="(caliPhy.controls['378'].touched && caliPhy.controls['378'].invalid) || (caliPhy.controls['378'].invalid && isSubmit)"
                                            id="378" name="378" type="text" placeholder="Reference2 Zip"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['378'].touched && caliPhy.controls['378'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['378'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-6">
                                        <label
                                            style="font-weight: normal;font-size: 14px;padding-right:10px;">State</label>
                                        <!-- <div class="col-md-3" style="padding-top:30px">                                         -->
                                        <div class="form-group selectDrop dropDrownSet">
                                            <ng-select [items]="states" bindLabel="name"
                                                [class.is-invalid]="(caliPhy.controls['377'].touched && caliPhy.controls['377'].invalid) || (caliPhy.controls['377'].invalid && isSubmit)"
                                                placeholder="Select States" bindValue="id" formControlName="377">
                                            </ng-select>
                                            <div class="formLabel col-md-11"
                                                *ngIf="caliPhy.controls['377'].touched && caliPhy.controls['377'].invalid">
                                                <small class="text-danger"
                                                    *ngIf="caliPhy.controls['377'].errors?.required">
                                                    required
                                                </small>
                                            </div>
                                        </div>
                                        <!-- </div> -->
                                    </div>
                                    <div class="col-md-6">
                                        <label style="font-weight: normal;font-size: 14px">Specialty</label>
                                        <!-- <div class="col-md-3" style="padding-top:30px">                                        -->
                                        <div class="form-group selectDrop dropDrownSet">
                                            <ng-select [items]="specialty" bindLabel="name"
                                                [class.is-invalid]="(caliPhy.controls['380'].touched && caliPhy.controls['380'].invalid) || (caliPhy.controls['380'].invalid && isSubmit)"
                                                placeholder="Select Specialities" bindValue="id" formControlName="380">
                                            </ng-select>
                                            <div class="formLabel col-md-11"
                                                *ngIf="caliPhy.controls['380'].touched && caliPhy.controls['380'].invalid">
                                                <small class="text-danger"
                                                    *ngIf="caliPhy.controls['380'].errors?.required">
                                                    required
                                                </small>
                                            </div>
                                        </div>
                                        <!-- </div> -->
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-6">
                                        <label style="font-weight: normal;font-size: 14px">Phone Number:</label>
                                        <input class="form-control onboard" formControlName="381"
                                            [class.is-invalid]="(caliPhy.controls['381'].touched && caliPhy.controls['381'].invalid) || (caliPhy.controls['381'].invalid && isSubmit)"
                                            id="381" name="381" type="text" placeholder="Reference2 Phone"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['381'].touched && caliPhy.controls['381'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['381'].errors?.required">
                                                phone is required and format is xxx-xxx-xxxx
                                            </small>
                                            <small class="text-danger" *ngIf="caliPhy.controls['381'].errors?.required">
                                                pattern xxx-xxx-xxxx
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <label style="font-weight: normal;font-size: 14px">Email:</label>
                                        <input class="form-control onboard" formControlName="383"
                                            [class.is-invalid]="(caliPhy.controls['383'].touched && caliPhy.controls['383'].invalid) || (caliPhy.controls['383'].invalid && isSubmit)"
                                            id="383" name="383" type="text" placeholder="Reference2 Email"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['383'].touched && caliPhy.controls['383'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['383'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-6">
                                        <label style="font-weight: normal;font-size: 14px"><b>Name of Reference and
                                                Address:</b></label>
                                        <input class="form-control onboard" formControlName="384"
                                            [class.is-invalid]="(caliPhy.controls['384'].touched && caliPhy.controls['384'].invalid) || (caliPhy.controls['384'].invalid && isSubmit)"
                                            id="384" name="384" type="text" placeholder="Reference3 Name"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['384'].touched && caliPhy.controls['384'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['384'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="385"
                                            [class.is-invalid]="(caliPhy.controls['385'].touched && caliPhy.controls['385'].invalid) || (caliPhy.controls['385'].invalid && isSubmit)"
                                            id="385" name="385" type="text" placeholder="Reference3 Address1"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['385'].touched && caliPhy.controls['385'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['385'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="386"
                                            [class.is-invalid]="(caliPhy.controls['386'].touched && caliPhy.controls['386'].invalid) || (caliPhy.controls['386'].invalid && isSubmit)"
                                            id="386" name="386" type="text" placeholder="Reference3 Address2"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['386'].touched && caliPhy.controls['386'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['386'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="387"
                                            [class.is-invalid]="(caliPhy.controls['387'].touched && caliPhy.controls['387'].invalid) || (caliPhy.controls['387'].invalid && isSubmit)"
                                            id="387" name="387" type="text" placeholder="Reference3 City"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['387'].touched && caliPhy.controls['387'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['387'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="390"
                                            [class.is-invalid]="(caliPhy.controls['390'].touched && caliPhy.controls['390'].invalid) || (caliPhy.controls['390'].invalid && isSubmit)"
                                            id="390" name="390" type="text" placeholder="Reference3 Zip"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['390'].touched && caliPhy.controls['390'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['390'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-6">
                                        <label
                                            style="font-weight: normal;font-size: 14px;padding-right:10px;">State</label>
                                        <!-- <div class="col-md-3" style="padding-top:30px">                                         -->
                                        <div class="form-group selectDrop dropDrownSet">
                                            <ng-select [items]="states" bindLabel="name"
                                                [class.is-invalid]="(caliPhy.controls['389'].touched && caliPhy.controls['389'].invalid) || (caliPhy.controls['389'].invalid && isSubmit)"
                                                placeholder="Select States" bindValue="id" formControlName="389">
                                            </ng-select>
                                            <div class="formLabel col-md-11"
                                                *ngIf="caliPhy.controls['389'].touched && caliPhy.controls['389'].invalid">
                                                <small class="text-danger"
                                                    *ngIf="caliPhy.controls['389'].errors?.required">
                                                    required
                                                </small>
                                            </div>
                                        </div>
                                        <!-- </div> -->
                                    </div>
                                    <div class="col-md-6">
                                        <label style="font-weight: normal;font-size: 14px">Specialty</label>
                                        <!-- <div class="col-md-3" style="padding-top:30px">                                         -->
                                        <div class="form-group selectDrop dropDrownSet">
                                            <ng-select [items]="specialty" bindLabel="name"
                                                [class.is-invalid]="(caliPhy.controls['393'].touched && caliPhy.controls['393'].invalid) || (caliPhy.controls['393'].invalid && isSubmit)"
                                                placeholder="Select Specialities" bindValue="id" formControlName="393">
                                            </ng-select>
                                            <div class="formLabel col-md-11"
                                                *ngIf="caliPhy.controls['393'].touched && caliPhy.controls['393'].invalid">
                                                <small class="text-danger"
                                                    *ngIf="caliPhy.controls['393'].errors?.required">
                                                    required
                                                </small>
                                            </div>
                                        </div>
                                        <!-- </div> -->
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-6">
                                        <label style="font-weight: normal;font-size: 14px">Phone Number:</label>
                                        <input class="form-control onboard" formControlName="394"
                                            [class.is-invalid]="(caliPhy.controls['394'].touched && caliPhy.controls['394'].invalid) || (caliPhy.controls['394'].invalid && isSubmit)"
                                            id="394" name="394" type="text" placeholder="Reference3 Phone"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['394'].touched && caliPhy.controls['394'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['394'].errors?.required">
                                                phone is required and format is xxx-xxx-xxxx
                                            </small>
                                            <small class="text-danger" *ngIf="caliPhy.controls['394'].errors?.required">
                                                pattern xxx-xxx-xxxx
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <label style="font-weight: normal;font-size: 14px">Email:</label>
                                        <input class="form-control onboard" formControlName="396"
                                            [class.is-invalid]="(caliPhy.controls['396'].touched && caliPhy.controls['396'].invalid) || (caliPhy.controls['396'].invalid && isSubmit)"
                                            id="396" name="396" type="text" placeholder="Reference3 Email"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['396'].touched && caliPhy.controls['396'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['396'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr class="tabhead">
                            <th class="tabTh">II.EDUCATION (Send additional sheets to <u
                                    style="color:blue;">operations@bassmedicalgroup.com</u> if necessary. Please
                                reference this section number and title.)
                            </th>
                        </tr>
                        <tr class="tabSideHeading">
                            <th class="tabMainHeading">PREMEDICAL EDUCATION</th>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-6">
                                        <label style="font-weight: normal;font-size: 14px">College or University
                                            Name</label>
                                        <input class="form-control onboard" formControlName="503"
                                            [class.is-invalid]="(caliPhy.controls['503'].touched && caliPhy.controls['503'].invalid) || (caliPhy.controls['503'].invalid && isSubmit)"
                                            id="503" name="503" type="text" placeholder="UG School"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['503'].touched && caliPhy.controls['503'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['503'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label style="font-weight: normal;font-size: 14px">Mailling Address</label>
                                <div class="row">
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="504"
                                            [class.is-invalid]="(caliPhy.controls['504'].touched && caliPhy.controls['504'].invalid) || (caliPhy.controls['504'].invalid && isSubmit)"
                                            id="504" name="504" type="text" placeholder="UG School Address1"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['504'].touched && caliPhy.controls['504'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['504'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="505"
                                            [class.is-invalid]="(caliPhy.controls['505'].touched && caliPhy.controls['505'].invalid) || (caliPhy.controls['505'].invalid && isSubmit)"
                                            id="505" name="505" type="text" placeholder="UG School Address2"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['505'].touched && caliPhy.controls['505'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['505'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="506"
                                            [class.is-invalid]="(caliPhy.controls['506'].touched && caliPhy.controls['506'].invalid) || (caliPhy.controls['506'].invalid && isSubmit)"
                                            id="506" name="506" type="text" placeholder="UG School City"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['506'].touched && caliPhy.controls['506'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['506'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="509"
                                            [class.is-invalid]="(caliPhy.controls['509'].touched && caliPhy.controls['509'].invalid) || (caliPhy.controls['509'].invalid && isSubmit)"
                                            id="509" name="509" type="text" placeholder="UG School Zip"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['509'].touched && caliPhy.controls['509'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['509'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px">Degree Received</label>
                                        <div class="form-group selectDrop dropDrownSet">
                                            <ng-select [items]="degree" bindLabel="name"
                                                [class.is-invalid]="(caliPhy.controls['512'].touched && caliPhy.controls['512'].invalid) || (caliPhy.controls['512'].invalid && isSubmit)"
                                                placeholder="Select Degree" bindValue="id" formControlName="512">
                                            </ng-select>
                                            <div class="formLabel col-md-11"
                                                *ngIf="caliPhy.controls['512'].touched && caliPhy.controls['512'].invalid">
                                                <small class="text-danger"
                                                    *ngIf="caliPhy.controls['512'].errors?.required">
                                                    required
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px">Start Date
                                            (mm/dd/yyyy)</label>
                                        <div class="input-group">
                                            <input class="form-control onboard datepicker" formControlName="510"
                                                [class.is-invalid]="(caliPhy.controls['510'].touched && caliPhy.controls['510'].invalid) || (caliPhy.controls['510'].invalid && isSubmit)"
                                                ngbDatepicker #d100="ngbDatepicker" id="510" name="510" type="text" (blur)="clearInputIfInvalidMandi('510')"
                                                placeholder="Enter Start Date" data-id="managing_contact_name">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary calendar"
                                                    (click)="d100.toggle()" type="button"><i
                                                        class="fas fa-calendar-alt"></i></button>
                                            </div>
                                            
                                        </div>
                                        <div class="mandatory" *ngIf="(caliPhy.controls['510'].invalid&&caliPhy.controls['510'].touched) && (caliPhy.controls['510'].value==''|| caliPhy.controls['510'].value==null) " >
                                            <small >
                                            required
                                            </small>
                                          
                                          </div>
                                          <small class="text-danger" *ngIf="caliPhy.get('510').errors?.invalidDateFormat">
                                            Only Date Format is allowed(MM/DD/YYYY)                </small>
                                          <small class="text-danger" *ngIf="caliPhy.get('510').errors?.invalidMonth">
                                          Month is out of range
                                          </small>
                                          <small class="text-danger" *ngIf="caliPhy.get('510').errors?.invalidDateyear">
                                            year is out of range(1900-2099)                  </small>
                                            <small class="text-danger" *ngIf="caliPhy.get('510').errors?.invalidDateRange">
                                              date is out of range
                                              </small>
                                    </div>
                                    <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px">Date of Graduation
                                            (mm/yyyy)</label>
                                        <div class="input-group">
                                            <input class="form-control onboard datepicker" formControlName="1238"
                                                [class.is-invalid]="(caliPhy.controls['1238'].touched && caliPhy.controls['1238'].invalid) || (caliPhy.controls['1238'].invalid && isSubmit)"
                                                ngbDatepicker #d7="ngbDatepicker" id="1238" name="1238" type="text" (blur)="clearInputIfInvalidMandi('1238')"
                                                placeholder="UG Date of Graduation" data-id="managing_contact_name">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary calendar" (click)="d7.toggle()"
                                                    type="button"><i class="fas fa-calendar-alt"></i></button>
                                            </div>
                                            
                                        </div>
                                        <div class="mandatory" *ngIf="(caliPhy.controls['1238'].invalid&&caliPhy.controls['1238'].touched) && (caliPhy.controls['1238'].value==''|| caliPhy.controls['1238'].value==null) " >
                                            <small >
                                            required
                                            </small>
                                          
                                          </div>
                                          <small class="text-danger" *ngIf="caliPhy.get('1238').errors?.invalidDateFormat">
                                            Only Date Format is allowed(MM/DD/YYYY)                </small>
                                          <small class="text-danger" *ngIf="caliPhy.get('1238').errors?.invalidMonth">
                                          Month is out of range
                                          </small>
                                          <small class="text-danger" *ngIf="caliPhy.get('1238').errors?.invalidDateyear">
                                            year is out of range(1900-2099)                  </small>
                                            <small class="text-danger" *ngIf="caliPhy.get('1238').errors?.invalidDateRange">
                                              date is out of range
                                              </small>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr class="tabSideHeading">
                            <th class="tabMainHeading">MEDICAL/PROFESSIONAL EDUCATION</th>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-6">
                                        <label style="font-weight: normal;font-size: 14px">Medical/Professional
                                            School</label>
                                        <input class="form-control onboard" formControlName="479"
                                            [class.is-invalid]="(caliPhy.controls['479'].touched && caliPhy.controls['479'].invalid) || (caliPhy.controls['479'].invalid && isSubmit)"
                                            id="479" name="479" type="text" placeholder="Grad School"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['479'].touched && caliPhy.controls['479'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['479'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label style="font-weight: normal;font-size: 14px">Mailing Address</label>
                                <div class="row">
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="483"
                                            [class.is-invalid]="(caliPhy.controls['483'].touched && caliPhy.controls['483'].invalid) || (caliPhy.controls['483'].invalid && isSubmit)"
                                            id="483" name="483" type="text" placeholder="Grad School Address1"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['483'].touched && caliPhy.controls['483'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['483'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="484"
                                            [class.is-invalid]="(caliPhy.controls['484'].touched && caliPhy.controls['484'].invalid) || (caliPhy.controls['484'].invalid && isSubmit)"
                                            id="484" name="484" type="text" placeholder="Grad School Address2"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['484'].touched && caliPhy.controls['484'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['484'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="485"
                                            [class.is-invalid]="(caliPhy.controls['485'].touched && caliPhy.controls['485'].invalid) || (caliPhy.controls['485'].invalid && isSubmit)"
                                            id="485" name="485" type="text" placeholder="Grad School City"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['485'].touched && caliPhy.controls['485'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['485'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="488"
                                            [class.is-invalid]="(caliPhy.controls['488'].touched && caliPhy.controls['488'].invalid) || (caliPhy.controls['488'].invalid && isSubmit)"
                                            id="488" name="488" type="text" placeholder="Grad School Zip"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['488'].touched && caliPhy.controls['488'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['488'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px">Degree Received</label>
                                        <div class="form-group selectDrop dropDrownSet">
                                            <ng-select [items]="degree" bindLabel="name"
                                                [class.is-invalid]="(caliPhy.controls['480'].touched && caliPhy.controls['480'].invalid) || (caliPhy.controls['480'].invalid && isSubmit)"
                                                placeholder="Select Degree" bindValue="id" formControlName="480">
                                            </ng-select>
                                            <div class="formLabel col-md-11"
                                                *ngIf="caliPhy.controls['480'].touched && caliPhy.controls['480'].invalid">
                                                <small class="text-danger"
                                                    *ngIf="caliPhy.controls['480'].errors?.required">
                                                    required
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px">Start Date
                                            (mm/dd/yyyy)</label>
                                        <div class="input-group">
                                            <input class="form-control onboard datepicker"
                                                [class.is-invalid]="(caliPhy.controls['481'].touched && caliPhy.controls['481'].invalid) || (caliPhy.controls['481'].invalid && isSubmit)"
                                                formControlName="481" ngbDatepicker #d101="ngbDatepicker" id="481" (blur)="clearInputIfInvalidMandi('481')"
                                                name="481" type="text" placeholder="Enter Start Date">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary calendar"
                                                    (click)="d101.toggle()" type="button"><i
                                                        class="fas fa-calendar-alt"></i></button>
                                            </div>
                                           
                                        </div>
                                        <div class="mandatory" *ngIf="(caliPhy.controls['481'].invalid&&caliPhy.controls['481'].touched) && (caliPhy.controls['481'].value==''|| caliPhy.controls['481'].value==null) " >
                                            <small >
                                            required
                                            </small>
                                          
                                          </div>
                                          <small class="text-danger" *ngIf="caliPhy.get('481').errors?.invalidDateFormat">
                                            Only Date Format is allowed(MM/DD/YYYY)                </small>
                                          <small class="text-danger" *ngIf="caliPhy.get('481').errors?.invalidMonth">
                                          Month is out of range
                                          </small>
                                          <small class="text-danger" *ngIf="caliPhy.get('481').errors?.invalidDateyear">
                                            year is out of range(1900-2099)                  </small>
                                            <small class="text-danger" *ngIf="caliPhy.get('481').errors?.invalidDateRange">
                                              date is out of range
                                              </small>
                                    </div>
                                    <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px">Date of Graduation
                                            (mm/yyyy)</label>
                                        <div class="input-group">
                                            <input class="form-control onboard datepicker"
                                                [class.is-invalid]="(caliPhy.controls['1300'].touched && caliPhy.controls['1300'].invalid) || (caliPhy.controls['1300'].invalid && isSubmit)"
                                                formControlName="1300" ngbDatepicker #d8="ngbDatepicker" id="1300" (blur)="clearInputIfInvalidMandi('1300')"
                                                name="1300" type="text" placeholder="Grad Date of Graduation"
                                                data-id="managing_contact_name">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary calendar" (click)="d8.toggle()"
                                                    type="button"><i class="fas fa-calendar-alt"></i></button>
                                            </div>
                                            
                                        </div>
                                        <div class="mandatory" *ngIf="(caliPhy.controls['1300'].invalid&&caliPhy.controls['1300'].touched) && (caliPhy.controls['1300'].value==''|| caliPhy.controls['1300'].value==null) " >
                                            <small >
                                            required
                                            </small>
                                          
                                          </div>
                                          <small class="text-danger" *ngIf="caliPhy.get('1300').errors?.invalidDateFormat">
                                            Only Date Format is allowed(MM/DD/YYYY)                </small>
                                          <small class="text-danger" *ngIf="caliPhy.get('1300').errors?.invalidMonth">
                                          Month is out of range
                                          </small>
                                          <small class="text-danger" *ngIf="caliPhy.get('1300').errors?.invalidDateyear">
                                            year is out of range(1900-2099)                  </small>
                                            <small class="text-danger" *ngIf="caliPhy.get('1300').errors?.invalidDateRange">
                                              date is out of range
                                              </small>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-6">
                                        <label style="font-weight: normal;font-size: 14px">Medical/Professional
                                            School</label>
                                        <input class="form-control onboard" formControlName="490"
                                            [class.is-invalid]="(caliPhy.controls['490'].touched && caliPhy.controls['490'].invalid) || (caliPhy.controls['490'].invalid && isSubmit)"
                                            id="490" name="490" type="text" placeholder="Med School"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['490'].touched && caliPhy.controls['490'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['490'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label style="font-weight: normal;font-size: 14px">Mailling Address</label>
                                <div class="row">
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="491"
                                            [class.is-invalid]="(caliPhy.controls['491'].touched && caliPhy.controls['491'].invalid) || (caliPhy.controls['491'].invalid && isSubmit)"
                                            id="491" name="491" type="text" placeholder="Med School Address1"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['491'].touched && caliPhy.controls['491'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['491'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="492"
                                            [class.is-invalid]="(caliPhy.controls['492'].touched && caliPhy.controls['492'].invalid) || (caliPhy.controls['492'].invalid && isSubmit)"
                                            id="492" name="492" type="text" placeholder="Med School Address2"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['492'].touched && caliPhy.controls['492'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['492'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="493"
                                            [class.is-invalid]="(caliPhy.controls['493'].touched && caliPhy.controls['493'].invalid) || (caliPhy.controls['493'].invalid && isSubmit)"
                                            id="493" name="493" type="text" placeholder="Med School City"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['493'].touched && caliPhy.controls['493'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['493'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="496"
                                            [class.is-invalid]="(caliPhy.controls['496'].touched && caliPhy.controls['496'].invalid) || (caliPhy.controls['496'].invalid && isSubmit)"
                                            id="496" name="496" type="text" placeholder="Med School Zip"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['496'].touched && caliPhy.controls['496'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['496'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px">Degree Received</label>
                                        <div class="form-group selectDrop dropDrownSet">
                                            <ng-select [items]="degree" bindLabel="name" placeholder="Select Degree"
                                                bindValue="id"
                                                [class.is-invalid]="(caliPhy.controls['500'].touched && caliPhy.controls['500'].invalid) || (caliPhy.controls['500'].invalid && isSubmit)"
                                                formControlName="500">
                                            </ng-select>
                                            <div class="formLabel col-md-11"
                                                *ngIf="caliPhy.controls['500'].touched && caliPhy.controls['500'].invalid">
                                                <small class="text-danger"
                                                    *ngIf="caliPhy.controls['500'].errors?.required">
                                                    required
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px">Start Date
                                            (mm/dd/yyyy)</label>
                                        <div class="input-group">
                                            <input class="form-control onboard datepicker" formControlName="497"
                                                ngbDatepicker #d102="ngbDatepicker"
                                                [class.is-invalid]="(caliPhy.controls['497'].touched && caliPhy.controls['497'].invalid) || (caliPhy.controls['497'].invalid && isSubmit)"
                                                id="497" name="497" type="text" (blur)="clearInputIfInvalid('497')" placeholder="Enter Start Date">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary calendar"
                                                    (click)="d102.toggle()" type="button"><i
                                                        class="fas fa-calendar-alt"></i></button>
                                            </div>
                                        </div>
                                        <div  *ngIf="caliPhy.get('497').invalid ">
                                            <small class="text-danger" *ngIf="caliPhy.get('497').errors?.invalidDateFormat">
                                              Only Date Format is allowed(MM/DD/YYYY)
                                            </small>
                                            <small class="text-danger" *ngIf="caliPhy.get('497').errors?.invalidMonth">
                                            Month is out of range
                                            </small>
                                            <small class="text-danger" *ngIf="caliPhy.get('497').errors?.invalidDateyear">
                                              year is out of range(1900-2099)                      </small>
                                              <small class="text-danger" *ngIf="caliPhy.get('497').errors?.invalidDateRange">
                                                date is out of range
                                                </small>
                                            </div>
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['497'].touched && caliPhy.controls['497'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['497'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px">Date of Graduation
                                            (mm/yyyy)</label>
                                        <div class="input-group">
                                            <input class="form-control onboard datepicker" formControlName="498"
                                                ngbDatepicker #d9="ngbDatepicker"
                                                [class.is-invalid]="(caliPhy.controls['498'].touched && caliPhy.controls['498'].invalid) || (caliPhy.controls['498'].invalid && isSubmit)"
                                                id="498" name="498" (blur)="clearInputIfInvalid('498')" type="text" placeholder="Med Date of Graduation"
                                                data-id="managing_contact_name">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary calendar" (click)="d9.toggle()"
                                                    type="button"><i class="fas fa-calendar-alt"></i></button>
                                            </div>
                                        </div>
                                        <div  *ngIf="caliPhy.get('498').invalid ">
                                            <small class="text-danger" *ngIf="caliPhy.get('498').errors?.invalidDateFormat">
                                              Only Date Format is allowed(MM/DD/YYYY)
                                            </small>
                                            <small class="text-danger" *ngIf="caliPhy.get('498').errors?.invalidMonth">
                                            Month is out of range
                                            </small>
                                            <small class="text-danger" *ngIf="caliPhy.get('498').errors?.invalidDateyear">
                                              year is out of range(1900-2099)                      </small>
                                              <small class="text-danger" *ngIf="caliPhy.get('498').errors?.invalidDateRange">
                                                date is out of range
                                                </small>
                                            </div>
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['498'].touched && caliPhy.controls['498'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['498'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr class="tabSideHeading">
                            <th class="tabMainHeading">INTERNSHIP - RESIDENCIES/FELLOWSHIPS</th>
                        </tr>
                        <tr>
                            <td>
                                <p>
                                    Include residencies, fellowships, preceptorships, and teaching appointments
                                    (indicate whether clinical or academic) completed in chronological
                                    order, giving name, address, city and ZIP code, and dates. Include all programs you
                                    have attended, whether or not completed.
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-6">
                                        <label style="font-weight: normal;font-size: 14px">Institution</label>
                                        <input class="form-control onboard" formControlName="517"
                                            [class.is-invalid]="(caliPhy.controls['517'].touched && caliPhy.controls['517'].invalid) || (caliPhy.controls['517'].invalid && isSubmit)"
                                            id="517" name="517" type="text" placeholder="Intern School"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['517'].touched && caliPhy.controls['517'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['517'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <label style="font-weight: normal;font-size: 14px">Program Director</label>
                                        <input class="form-control onboard" formControlName="528"
                                            [class.is-invalid]="(caliPhy.controls['528'].touched && caliPhy.controls['528'].invalid) || (caliPhy.controls['528'].invalid && isSubmit)"
                                            id="528" name="528" type="text" placeholder="Program Director"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['528'].touched && caliPhy.controls['528'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['528'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-6">
                                        <label style="font-weight: normal;font-size: 14px">From (mm/yyyy)</label>
                                        <div class="input-group">
                                            <input class="form-control onboard datepicker" formControlName="524"
                                                [class.is-invalid]="(caliPhy.controls['524'].touched && caliPhy.controls['524'].invalid) || (caliPhy.controls['524'].invalid && isSubmit)"
                                                ngbDatepicker #d10="ngbDatepicker" id="524" name="524" type="text" (blur)="clearInputIfInvalidMandi('524')"
                                                placeholder="Intern Start Date" data-id="managing_contact_name">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary calendar"
                                                    (click)="d10.toggle()" type="button"><i
                                                        class="fas fa-calendar-alt"></i></button>
                                            </div>
                                           
                                        </div>
                                        <div class="mandatory" *ngIf="(caliPhy.controls['524'].invalid&&caliPhy.controls['524'].touched) && (caliPhy.controls['524'].value==''|| caliPhy.controls['524'].value==null) " >
                                            <small >
                                            required
                                            </small>
                                          
                                          </div>
                                          <small class="text-danger" *ngIf="caliPhy.get('524').errors?.invalidDateFormat">
                                            Only Date Format is allowed(MM/DD/YYYY)                </small>
                                          <small class="text-danger" *ngIf="caliPhy.get('524').errors?.invalidMonth">
                                          Month is out of range
                                          </small>
                                          <small class="text-danger" *ngIf="caliPhy.get('524').errors?.invalidDateyear">
                                            year is out of range(1900-2099)                  </small>
                                            <small class="text-danger" *ngIf="caliPhy.get('524').errors?.invalidDateRange">
                                              date is out of range
                                              </small>
                                    </div>
                                    <div class="col-md-6">
                                        <label style="font-weight: normal;font-size: 14px">To (mm/yyyy)</label>
                                        <div class="input-group">
                                            <input class="form-control onboard datepicker" formControlName="525"
                                                [class.is-invalid]="(caliPhy.controls['525'].touched && caliPhy.controls['525'].invalid) || (caliPhy.controls['525'].invalid && isSubmit)"
                                                ngbDatepicker #d11="ngbDatepicker" id="525" name="525" type="text" (blur)="clearInputIfInvalidMandi('525')"
                                                placeholder="Intern End Date" data-id="managing_contact_name">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary calendar"
                                                    (click)="d11.toggle()" type="button"><i
                                                        class="fas fa-calendar-alt"></i></button>
                                            </div>
                                           
                                        </div>
                                        <div class="mandatory" *ngIf="(caliPhy.controls['525'].invalid&&caliPhy.controls['525'].touched) && (caliPhy.controls['525'].value==''|| caliPhy.controls['525'].value==null) " >
                                            <small >
                                            required
                                            </small>
                                          
                                          </div>
                                          <small class="text-danger" *ngIf="caliPhy.get('525').errors?.invalidDateFormat">
                                            Only Date Format is allowed(MM/DD/YYYY)                </small>
                                          <small class="text-danger" *ngIf="caliPhy.get('525').errors?.invalidMonth">
                                          Month is out of range
                                          </small>
                                          <small class="text-danger" *ngIf="caliPhy.get('525').errors?.invalidDateyear">
                                            year is out of range(1900-2099)                  </small>
                                            <small class="text-danger" *ngIf="caliPhy.get('525').errors?.invalidDateRange">
                                              date is out of range
                                              </small>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label style="font-weight: normal;font-size: 14px">Mailling Address</label>
                                <div class="row">
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="518"
                                            [class.is-invalid]="(caliPhy.controls['518'].touched && caliPhy.controls['518'].invalid) || (caliPhy.controls['518'].invalid && isSubmit)"
                                            id="518" name="518" type="text" placeholder="Intern Address1"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['518'].touched && caliPhy.controls['518'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['518'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="519"
                                            [class.is-invalid]="(caliPhy.controls['519'].touched && caliPhy.controls['519'].invalid) || (caliPhy.controls['519'].invalid && isSubmit)"
                                            id="519" name="519" type="text" placeholder="Intern Address2"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['519'].touched && caliPhy.controls['519'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['519'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="520"
                                            [class.is-invalid]="(caliPhy.controls['520'].touched && caliPhy.controls['520'].invalid) || (caliPhy.controls['520'].invalid && isSubmit)"
                                            id="520" name="520" type="text" placeholder="Intern City"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['520'].touched && caliPhy.controls['520'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['520'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="523"
                                            [class.is-invalid]="(caliPhy.controls['523'].touched && caliPhy.controls['523'].invalid) || (caliPhy.controls['523'].invalid && isSubmit)"
                                            id="523" name="523" type="text" placeholder="Intern Zip"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['523'].touched && caliPhy.controls['523'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['523'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-6">
                                        <label style="font-weight: normal;font-size: 14px;padding-right:10px;">Intern
                                            State</label>
                                        <!-- <div class="col-md-3" style="padding-top:30px">                                         -->
                                        <div class="form-group selectDrop dropDrownSet">
                                            <ng-select [items]="states" bindLabel="name"
                                                [class.is-invalid]="(caliPhy.controls['522'].touched && caliPhy.controls['522'].invalid) || (caliPhy.controls['522'].invalid && isSubmit)"
                                                placeholder="Select States" bindValue="id" formControlName="522">
                                            </ng-select>
                                            <div class="formLabel col-md-11"
                                                *ngIf="caliPhy.controls['522'].touched && caliPhy.controls['522'].invalid">
                                                <small class="text-danger"
                                                    *ngIf="caliPhy.controls['522'].errors?.required">
                                                    required
                                                </small>
                                            </div>
                                        </div>
                                        <!-- </div> -->
                                    </div>
                                    <div class="col-md-6">
                                        <label style="font-weight: normal;font-size: 14px">Intern Specialty</label>
                                        <!-- <div class="col-md-3" style="padding-top:30px">                                        -->
                                        <div class="form-group selectDrop dropDrownSet">
                                            <ng-select [items]="specialty" bindLabel="name"
                                                [class.is-invalid]="(caliPhy.controls['527'].touched && caliPhy.controls['527'].invalid) || (caliPhy.controls['527'].invalid && isSubmit)"
                                                placeholder="Select Specialities" bindValue="id" formControlName="527">
                                            </ng-select>
                                            <div class="formLabel col-md-11"
                                                *ngIf="caliPhy.controls['527'].touched && caliPhy.controls['527'].invalid">
                                                <small class="text-danger"
                                                    *ngIf="caliPhy.controls['527'].errors?.required">
                                                    required
                                                </small>
                                            </div>
                                        </div>
                                        <!-- </div> -->
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-3">
                                        <label style="font-weight: normal;font-size: 14px">Type of Training</label>
                                        <input class="form-control onboard" formControlName="2539"
                                            [class.is-invalid]="(caliPhy.controls['2539'].touched && caliPhy.controls['2539'].invalid) || (caliPhy.controls['2539'].invalid && isSubmit)"
                                            id="2539" name="2539" type="text" placeholder="Intern PrimaryDept"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['2539'].touched && caliPhy.controls['2539'].invalid">
                                            <small class="text-danger"
                                                *ngIf="caliPhy.controls['2539'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <label style="font-weight: normal;font-size: 14px;padding-left:20px">Did you
                                        successfully complete the program?&nbsp; </label>
                                    <input type="radio" formControlName="cmd_936"
                                        [class.is-invalid]="(caliPhy.controls['cmd_936'].touched && caliPhy.controls['cmd_936'].invalid) || (caliPhy.controls['cmd_936'].invalid && isSubmit)"
                                        id="cmd_936" name="cmd_936" value="1">
                                    <label>Yes&nbsp;</label>
                                    <div class="formLabel col-md-11"
                                        *ngIf="caliPhy.controls['cmd_936'].touched && caliPhy.controls['cmd_936'].invalid">
                                        <small class="text-danger" *ngIf="caliPhy.controls['cmd_936'].errors?.required">
                                            required
                                        </small>
                                    </div>
                                    <input type="radio" formControlName="cmd_936"
                                        [class.is-invalid]="(caliPhy.controls['cmd_936'].touched && caliPhy.controls['cmd_936'].invalid) || (caliPhy.controls['cmd_936'].invalid && isSubmit)"
                                        id="cmd_936" name="cmd_936" value="0">
                                    <label>No</label>
                                    <label style="font-weight: normal;font-size: 14px;padding-left:20px">(If “No,”
                                        please explain; attach additional sheets if necessary.)</label>
                                    <div class="formLabel col-md-11"
                                        *ngIf="caliPhy.controls['cmd_936'].touched && caliPhy.controls['cmd_936'].invalid">
                                        <small class="text-danger" *ngIf="caliPhy.controls['cmd_936'].errors?.required">
                                            required
                                        </small>
                                    </div>
                                    <span
                                        *ngIf="(caliPhy.controls['cmd_936'].touched && caliPhy.controls['cmd_936'].invalid) || (caliPhy.controls['cmd_936'].invalid && isSubmit)">
                                        <i class="fas fa-times ml-2 text-danger"></i>
                                    </span>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-6">
                                        <label style="font-weight: normal;font-size: 14px">Institution</label>
                                        <input class="form-control onboard" formControlName="529"
                                            [class.is-invalid]="(caliPhy.controls['529'].touched && caliPhy.controls['529'].invalid) || (caliPhy.controls['529'].invalid && isSubmit)"
                                            id="529" name="529" type="text" placeholder="Residency School"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['529'].touched && caliPhy.controls['529'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['529'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <label style="font-weight: normal;font-size: 14px">Program Director</label>
                                        <input class="form-control onboard" formControlName="540"
                                            [class.is-invalid]="(caliPhy.controls['540'].touched && caliPhy.controls['540'].invalid) || (caliPhy.controls['540'].invalid && isSubmit)"
                                            id="540" name="540" type="text" placeholder="Residency Program Director"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['540'].touched && caliPhy.controls['540'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['540'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-6">
                                        <label style="font-weight: normal;font-size: 14px">From (mm/yyyy)</label>
                                        <div class="input-group">
                                            <input class="form-control onboard datepicker"
                                                [class.is-invalid]="(caliPhy.controls['537'].touched && caliPhy.controls['537'].invalid) || (caliPhy.controls['537'].invalid && isSubmit)"
                                                formControlName="537" ngbDatepicker #d12="ngbDatepicker" id="537" (blur)="clearInputIfInvalidMandi('537')"
                                                name="537" type="text" placeholder="Residency Start Date"
                                                data-id="managing_contact_name">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary calendar"
                                                    (click)="d12.toggle()" type="button"><i
                                                        class="fas fa-calendar-alt"></i></button>
                                            </div>
                                           
                                        </div>
                                        <div class="mandatory" *ngIf="(caliPhy.controls['537'].invalid&&caliPhy.controls['537'].touched) && (caliPhy.controls['537'].value==''|| caliPhy.controls['537'].value==null) " >
                                            <small >
                                            required
                                            </small>
                                          
                                          </div>
                                          <small class="text-danger" *ngIf="caliPhy.get('537').errors?.invalidDateFormat">
                                            Only Date Format is allowed(MM/DD/YYYY)                </small>
                                          <small class="text-danger" *ngIf="caliPhy.get('537').errors?.invalidMonth">
                                          Month is out of range
                                          </small>
                                          <small class="text-danger" *ngIf="caliPhy.get('537').errors?.invalidDateyear">
                                            year is out of range(1900-2099)                  </small>
                                            <small class="text-danger" *ngIf="caliPhy.get('537').errors?.invalidDateRange">
                                              date is out of range
                                              </small>
                                    </div>
                                    <div class="col-md-6">
                                        <label style="font-weight: normal;font-size: 14px">To (mm/yyyy)</label>
                                        <div class="input-group">
                                            <input class="form-control onboard datepicker"
                                                [class.is-invalid]="(caliPhy.controls['538'].touched && caliPhy.controls['538'].invalid) || (caliPhy.controls['538'].invalid && isSubmit)"
                                                formControlName="538" ngbDatepicker #d13="ngbDatepicker" id="538" (blur)="clearInputIfInvalidMandi('538')"
                                                name="538" type="text" placeholder="Residency End Date"
                                                data-id="managing_contact_name">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary calendar"
                                                    (click)="d13.toggle()" type="button"><i
                                                        class="fas fa-calendar-alt"></i></button>
                                            </div>
                                            
                                        </div>
                                        <div class="mandatory" *ngIf="(caliPhy.controls['538'].invalid&&caliPhy.controls['538'].touched) && (caliPhy.controls['538'].value==''|| caliPhy.controls['538'].value==null) " >
                                            <small >
                                            required
                                            </small>
                                          
                                          </div>
                                          <small class="text-danger" *ngIf="caliPhy.get('538').errors?.invalidDateFormat">
                                            Only Date Format is allowed(MM/DD/YYYY)                </small>
                                          <small class="text-danger" *ngIf="caliPhy.get('538').errors?.invalidMonth">
                                          Month is out of range
                                          </small>
                                          <small class="text-danger" *ngIf="caliPhy.get('538').errors?.invalidDateyear">
                                            year is out of range(1900-2099)                  </small>
                                            <small class="text-danger" *ngIf="caliPhy.get('538').errors?.invalidDateRange">
                                              date is out of range
                                              </small>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label style="font-weight: normal;font-size: 14px">Mailling Address</label>
                                <div class="row">
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="531"
                                            [class.is-invalid]="(caliPhy.controls['531'].touched && caliPhy.controls['531'].invalid) || (caliPhy.controls['531'].invalid && isSubmit)"
                                            id="531" name="531" type="text" placeholder="Residency Address1"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['531'].touched && caliPhy.controls['531'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['531'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="1498"
                                            [class.is-invalid]="(caliPhy.controls['1498'].touched && caliPhy.controls['1498'].invalid) || (caliPhy.controls['1498'].invalid && isSubmit)"
                                            id="1498" name="1498" type="text" placeholder="Residency Address2"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['1498'].touched && caliPhy.controls['1498'].invalid">
                                            <small class="text-danger"
                                                *ngIf="caliPhy.controls['1498'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="533"
                                            [class.is-invalid]="(caliPhy.controls['533'].touched && caliPhy.controls['533'].invalid) || (caliPhy.controls['533'].invalid && isSubmit)"
                                            id="533" name="533" type="text" placeholder="Residency City"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['533'].touched && caliPhy.controls['533'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['533'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="536"
                                            [class.is-invalid]="(caliPhy.controls['536'].touched && caliPhy.controls['536'].invalid) || (caliPhy.controls['536'].invalid && isSubmit)"
                                            id="536" name="536" type="text" placeholder="Residency Zip"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['536'].touched && caliPhy.controls['536'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['536'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-6">
                                        <label style="font-weight: normal;font-size: 14px;padding-right:10px;">Residency
                                            State</label>
                                        <!-- <div class="col-md-3" style="padding-top:30px">                                         -->
                                        <div class="form-group selectDrop dropDrownSet">
                                            <ng-select [items]="states" bindLabel="name"
                                                [class.is-invalid]="(caliPhy.controls['535'].touched && caliPhy.controls['535'].invalid) || (caliPhy.controls['535'].invalid && isSubmit)"
                                                placeholder="Select States" bindValue="id" formControlName="535">
                                            </ng-select>
                                            <div class="formLabel col-md-11"
                                                *ngIf="caliPhy.controls['535'].touched && caliPhy.controls['535'].invalid">
                                                <small class="text-danger"
                                                    *ngIf="caliPhy.controls['535'].errors?.required">
                                                    required
                                                </small>
                                            </div>
                                        </div>
                                        <!-- </div> -->
                                    </div>
                                    <div class="col-md-6">
                                        <label style="font-weight: normal;font-size: 14px">Residency Specialty</label>
                                        <!-- <div class="col-md-3" style="padding-top:30px">                                         -->
                                        <div class="form-group selectDrop dropDrownSet">
                                            <ng-select [items]="specialty" bindLabel="name"
                                                [class.is-invalid]="(caliPhy.controls['530'].touched && caliPhy.controls['530'].invalid) || (caliPhy.controls['530'].invalid && isSubmit)"
                                                placeholder="Select Specialities" bindValue="id" formControlName="530">
                                            </ng-select>
                                            <div class="formLabel col-md-11"
                                                *ngIf="caliPhy.controls['530'].touched && caliPhy.controls['530'].invalid">
                                                <small class="text-danger"
                                                    *ngIf="caliPhy.controls['530'].errors?.required">
                                                    required
                                                </small>
                                            </div>
                                        </div>
                                        <!-- </div> -->
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-3">
                                        <label style="font-weight: normal;font-size: 14px">Type of Training</label>
                                        <input class="form-control onboard" formControlName="2540"
                                            [class.is-invalid]="(caliPhy.controls['2540'].touched && caliPhy.controls['2540'].invalid) || (caliPhy.controls['2540'].invalid && isSubmit)"
                                            id="2540" name="2540" type="text" placeholder="Residency PrimaryDept"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['2540'].touched && caliPhy.controls['2540'].invalid">
                                            <small class="text-danger"
                                                *ngIf="caliPhy.controls['2540'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <label style="font-weight: normal;font-size: 14px;padding-left:20px">Did you
                                        successfully complete the program? &nbsp;</label>
                                    <input type="radio" formControlName="cmd_937" 
                                        [class.is-invalid]="(caliPhy.controls['cmd_937'].touched && caliPhy.controls['cmd_937'].invalid) || (caliPhy.controls['cmd_937'].invalid && isSubmit)"
                                        id="cmd_937" name="cmd_937" value="1">
                                    <label>Yes&nbsp;</label>
                                    <div class="formLabel col-md-11"
                                        *ngIf="caliPhy.controls['cmd_937'].touched && caliPhy.controls['cmd_937'].invalid">
                                        <small class="text-danger" *ngIf="caliPhy.controls['cmd_937'].errors?.required">
                                            required
                                        </small>
                                    </div>
                                    <input type="radio" formControlName="cmd_937"
                                        [class.is-invalid]="(caliPhy.controls['cmd_937'].touched && caliPhy.controls['cmd_937'].invalid) || (caliPhy.controls['cmd_937'].invalid && isSubmit)"
                                        id="cmd_937" name="cmd_937" value="0">
                                    <label>No</label>
                                    <label style="font-weight: normal;font-size: 14px;padding-left:20px">(If “No,”
                                        please explain; attach additional sheets if necessary.)</label>
                                    <div class="formLabel col-md-11"
                                        *ngIf="caliPhy.controls['cmd_937'].touched && caliPhy.controls['cmd_937'].invalid">
                                        <small class="text-danger" *ngIf="caliPhy.controls['cmd_937'].errors?.required">
                                            required
                                        </small>
                                    </div>
                                    <span
                                        *ngIf="(caliPhy.controls['cmd_937'].touched && caliPhy.controls['cmd_937'].invalid) || (caliPhy.controls['cmd_937'].invalid && isSubmit)">
                                        <i class="fas fa-times ml-2 text-danger"></i>
                                    </span>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-6">
                                        <label style="font-weight: normal;font-size: 14px">Institution</label>
                                        <input class="form-control onboard" formControlName="542"
                                            [class.is-invalid]="(caliPhy.controls['542'].touched && caliPhy.controls['542'].invalid) || (caliPhy.controls['542'].invalid && isSubmit)"
                                            id="542" name="542" type="text" placeholder="FellowShip School"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['542'].touched && caliPhy.controls['542'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['542'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <label style="font-weight: normal;font-size: 14px">Program Director</label>
                                        <input class="form-control onboard" formControlName="553"
                                            [class.is-invalid]="(caliPhy.controls['553'].touched && caliPhy.controls['553'].invalid) || (caliPhy.controls['553'].invalid && isSubmit)"
                                            id="553" name="553" type="text" placeholder="FellowShip Program Director"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['553'].touched && caliPhy.controls['553'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['553'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-6">
                                        <label style="font-weight: normal;font-size: 14px">From (mm/yyyy)</label>
                                        <div class="input-group">
                                            <input class="form-control onboard datepicker" formControlName="550"
                                                [class.is-invalid]="(caliPhy.controls['550'].touched && caliPhy.controls['550'].invalid) || (caliPhy.controls['550'].invalid && isSubmit)"
                                                ngbDatepicker #d14="ngbDatepicker" id="550" name="550" type="text" (blur)="clearInputIfInvalidMandi('550')"
                                                placeholder="FellowShip Start Date" data-id="managing_contact_name">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary calendar"
                                                    (click)="d14.toggle()" type="button"><i
                                                        class="fas fa-calendar-alt"></i></button>
                                            </div>
                                            
                                        </div>
                                        <div class="mandatory" *ngIf="(caliPhy.controls['550'].invalid&&caliPhy.controls['550'].touched) && (caliPhy.controls['550'].value==''|| caliPhy.controls['550'].value==null) " >
                                            <small >
                                            required
                                            </small>
                                          
                                          </div>
                                          <small class="text-danger" *ngIf="caliPhy.get('550').errors?.invalidDateFormat">
                                            Only Date Format is allowed(MM/DD/YYYY)                </small>
                                          <small class="text-danger" *ngIf="caliPhy.get('550').errors?.invalidMonth">
                                          Month is out of range
                                          </small>
                                          <small class="text-danger" *ngIf="caliPhy.get('550').errors?.invalidDateyear">
                                            year is out of range(1900-2099)                  </small>
                                            <small class="text-danger" *ngIf="caliPhy.get('550').errors?.invalidDateRange">
                                              date is out of range
                                              </small>
                                    </div>
                                    <div class="col-md-6">
                                        <label style="font-weight: normal;font-size: 14px">To (mm/yyyy)</label>
                                        <div class="input-group">
                                            <input class="form-control onboard datepicker"
                                                [class.is-invalid]="(caliPhy.controls['551'].touched && caliPhy.controls['551'].invalid) || (caliPhy.controls['551'].invalid && isSubmit)"
                                                formControlName="551" ngbDatepicker #d15="ngbDatepicker" id="551" (blur)="clearInputIfInvalidMandi('551')"
                                                name="551" type="text" placeholder="FellowShip End Date"
                                                data-id="managing_contact_name">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary calendar"
                                                    (click)="d15.toggle()" type="button"><i
                                                        class="fas fa-calendar-alt"></i></button>
                                            </div>
                                           
                                        </div>
                                        <div class="mandatory" *ngIf="(caliPhy.controls['551'].invalid&&caliPhy.controls['551'].touched) && (caliPhy.controls['551'].value==''|| caliPhy.controls['551'].value==null) " >
                                            <small >
                                            required
                                            </small>
                                          
                                          </div>
                                          <small class="text-danger" *ngIf="caliPhy.get('551').errors?.invalidDateFormat">
                                            Only Date Format is allowed(MM/DD/YYYY)                </small>
                                          <small class="text-danger" *ngIf="caliPhy.get('551').errors?.invalidMonth">
                                          Month is out of range
                                          </small>
                                          <small class="text-danger" *ngIf="caliPhy.get('551').errors?.invalidDateyear">
                                            year is out of range(1900-2099)                  </small>
                                            <small class="text-danger" *ngIf="caliPhy.get('551').errors?.invalidDateRange">
                                              date is out of range
                                              </small>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label style="font-weight: normal;font-size: 14px">Mailling Address</label>
                                <div class="row">
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="544"
                                            [class.is-invalid]="(caliPhy.controls['544'].touched && caliPhy.controls['544'].invalid) || (caliPhy.controls['544'].invalid && isSubmit)"
                                            id="544" name="544" type="text" placeholder="FellowShip Address1"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['544'].touched && caliPhy.controls['544'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['544'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="545"
                                            [class.is-invalid]="(caliPhy.controls['545'].touched && caliPhy.controls['545'].invalid) || (caliPhy.controls['545'].invalid && isSubmit)"
                                            id="545" name="545" type="text" placeholder="FellowShip Address2"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['545'].touched && caliPhy.controls['545'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['545'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="546"
                                            [class.is-invalid]="(caliPhy.controls['546'].touched && caliPhy.controls['546'].invalid) || (caliPhy.controls['546'].invalid && isSubmit)"
                                            id="546" name="546" type="text" placeholder="FellowShip City"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['546'].touched && caliPhy.controls['546'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['546'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="549"
                                            [class.is-invalid]="(caliPhy.controls['549'].touched && caliPhy.controls['549'].invalid) || (caliPhy.controls['549'].invalid && isSubmit)"
                                            id="549" name="549" type="text" placeholder="FellowShip Zip"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['549'].touched && caliPhy.controls['549'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['549'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-6">
                                        <label
                                            style="font-weight: normal;font-size: 14px;padding-right:10px;">FellowShip
                                            State</label>
                                        <!-- <div class="col-md-3" style="padding-top:30px">                                         -->
                                        <div class="form-group selectDrop dropDrownSet">
                                            <ng-select [items]="states" bindLabel="name"
                                                [class.is-invalid]="(caliPhy.controls['548'].touched && caliPhy.controls['548'].invalid) || (caliPhy.controls['548'].invalid && isSubmit)"
                                                placeholder="Select States" bindValue="id" formControlName="548">
                                            </ng-select>
                                            <div class="formLabel col-md-11"
                                                *ngIf="caliPhy.controls['548'].touched && caliPhy.controls['548'].invalid">
                                                <small class="text-danger"
                                                    *ngIf="caliPhy.controls['548'].errors?.required">
                                                    required
                                                </small>
                                            </div>
                                        </div>
                                        <!-- </div> -->
                                    </div>
                                    <div class="col-md-6">
                                        <label style="font-weight: normal;font-size: 14px">FellowShip Specialty</label>
                                        <!-- <div class="col-md-3" style="padding-top:30px">                                         -->
                                        <div class="form-group selectDrop dropDrownSet">
                                            <ng-select [items]="specialty" bindLabel="name"
                                                [class.is-invalid]="(caliPhy.controls['543'].touched && caliPhy.controls['543'].invalid) || (caliPhy.controls['543'].invalid && isSubmit)"
                                                placeholder="Select Specialities" bindValue="id" formControlName="543">
                                            </ng-select>
                                            <div class="formLabel col-md-11"
                                                *ngIf="caliPhy.controls['543'].touched && caliPhy.controls['543'].invalid">
                                                <small class="text-danger"
                                                    *ngIf="caliPhy.controls['543'].errors?.required">
                                                    required
                                                </small>
                                            </div>
                                        </div>
                                        <!-- </div> -->
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-3">
                                        <label style="font-weight: normal;font-size: 14px">Type of Training</label>
                                        <input class="form-control onboard" formControlName="2542"
                                            [class.is-invalid]="(caliPhy.controls['2542'].touched && caliPhy.controls['2542'].invalid) || (caliPhy.controls['2542'].invalid && isSubmit)"
                                            id="2542" name="2542" type="text" placeholder="FellowShip PrimaryDept"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['2542'].touched && caliPhy.controls['2542'].invalid">
                                            <small class="text-danger"
                                                *ngIf="caliPhy.controls['2542'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <label style="font-weight: normal;font-size: 14px;padding-left:20px">Did you
                                        successfully complete the program? &nbsp;</label>
                                    <input type="radio" formControlName="cmd_938"
                                        [class.is-invalid]="(caliPhy.controls['cmd_938'].touched && caliPhy.controls['cmd_938'].invalid) || (caliPhy.controls['cmd_938'].invalid && isSubmit)"
                                        id="cmd_938" name="cmd_938" value="1">
                                    <label>Yes&nbsp;</label>
                                    <div class="formLabel col-md-11"
                                        *ngIf="caliPhy.controls['cmd_938'].touched && caliPhy.controls['cmd_938'].invalid">
                                        <small class="text-danger" *ngIf="caliPhy.controls['cmd_938'].errors?.required">
                                            required
                                        </small>
                                    </div>
                                    <input type="radio" formControlName="cmd_938"
                                        [class.is-invalid]="(caliPhy.controls['cmd_938'].touched && caliPhy.controls['cmd_938'].invalid) || (caliPhy.controls['cmd_938'].invalid && isSubmit)"
                                        id="cmd_938" name="cmd_938" value="0">
                                    <label>No</label>
                                    <label style="font-weight: normal;font-size: 14px;padding-left:20px">(If “No,”
                                        please explain; attach additional sheets if necessary.)</label>
                                    <div class="formLabel col-md-11"
                                        *ngIf="caliPhy.controls['cmd_938'].touched && caliPhy.controls['cmd_938'].invalid">
                                        <small class="text-danger" *ngIf="caliPhy.controls['cmd_938'].errors?.required">
                                            required
                                        </small>
                                    </div>
                                    <span
                                        *ngIf="(caliPhy.controls['cmd_938'].touched && caliPhy.controls['cmd_938'].invalid) || (caliPhy.controls['cmd_938'].invalid && isSubmit)">
                                        <i class="fas fa-times ml-2 text-danger"></i>
                                    </span>
                                </div>
                            </td>
                        </tr>
                        <tr class="tabhead">
                            <th class="tabTh">III. WORK HISTORY (Send additional sheets to <u
                                    style="color: blue;">operations@bassmedicalgroup.com</u> if necessary. Please
                                reference this section number and title.)
                            </th>
                        </tr>
                        <tr>
                            <td>
                                <p>
                                    Chronologically list all work activities within the last five years. Curriculum
                                    vitae is sufficient, provided it is current and contains all information requested
                                    below. Please explain any gaps in professional work history.
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-12">
                                        <label style="font-weight: normal;font-size: 14px">Current
                                            Practice/Employer:</label>
                                        <input class="form-control onboard" formControlName="445"
                                            [class.is-invalid]="(caliPhy.controls['445'].touched && caliPhy.controls['445'].invalid) || (caliPhy.controls['445'].invalid && isSubmit)"
                                            id="445" name="445" type="text" placeholder="WorkHistory1 Location"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['445'].touched && caliPhy.controls['445'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['445'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-6">
                                        <label style="font-weight: normal;font-size: 14px">From (mm/yyyy)</label>
                                        <div class="input-group">
                                            <input class="form-control onboard datepicker" formControlName="454"
                                                [class.is-invalid]="(caliPhy.controls['454'].touched && caliPhy.controls['454'].invalid) || (caliPhy.controls['454'].invalid && isSubmit)"
                                                ngbDatepicker #d16="ngbDatepicker" id="454" name="454" type="text" (blur)="clearInputIfInvalidMandi('454')"
                                                placeholder="WorkHistory1 Start Date" data-id="managing_contact_name">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary calendar"
                                                    (click)="d16.toggle()" type="button"><i
                                                        class="fas fa-calendar-alt"></i></button>
                                            </div>
                                           
                                        </div>
                                        <div class="mandatory" *ngIf="(caliPhy.controls['454'].invalid&&caliPhy.controls['454'].touched) && (caliPhy.controls['454'].value==''|| caliPhy.controls['454'].value==null) " >
                                            <small >
                                            required
                                            </small>
                                          
                                          </div>
                                          <small class="text-danger" *ngIf="caliPhy.get('454').errors?.invalidDateFormat">
                                            Only Date Format is allowed(MM/DD/YYYY)                </small>
                                          <small class="text-danger" *ngIf="caliPhy.get('454').errors?.invalidMonth">
                                          Month is out of range
                                          </small>
                                          <small class="text-danger" *ngIf="caliPhy.get('454').errors?.invalidDateyear">
                                            year is out of range(1900-2099)                  </small>
                                            <small class="text-danger" *ngIf="caliPhy.get('454').errors?.invalidDateRange">
                                              date is out of range
                                              </small>
                                    </div>
                                    <div class="col-md-6">
                                        <label style="font-weight: normal;font-size: 14px">To (mm/yyyy)</label>
                                        <div class="input-group">
                                            <input class="form-control onboard datepicker" formControlName="455"
                                                [class.is-invalid]="(caliPhy.controls['455'].touched && caliPhy.controls['455'].invalid) || (caliPhy.controls['455'].invalid && isSubmit)"
                                                ngbDatepicker #d17="ngbDatepicker" id="455" name="455" type="text" (blur)="clearInputIfInvalidMandi('455')"
                                                placeholder="WorkHistory1 End Date" data-id="managing_contact_name">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary calendar"
                                                    (click)="d17.toggle()" type="button"><i
                                                        class="fas fa-calendar-alt"></i></button>
                                            </div>
                                           
                                        </div>
                                        <div class="mandatory" *ngIf="(caliPhy.controls['455'].invalid&&caliPhy.controls['455'].touched) && (caliPhy.controls['455'].value==''|| caliPhy.controls['455'].value==null) " >
                                            <small >
                                            required
                                            </small>
                                          
                                          </div>
                                          <small class="text-danger" *ngIf="caliPhy.get('455').errors?.invalidDateFormat">
                                            Only Date Format is allowed(MM/DD/YYYY)                </small>
                                          <small class="text-danger" *ngIf="caliPhy.get('455').errors?.invalidMonth">
                                          Month is out of range
                                          </small>
                                          <small class="text-danger" *ngIf="caliPhy.get('455').errors?.invalidDateyear">
                                            year is out of range(1900-2099)                  </small>
                                            <small class="text-danger" *ngIf="caliPhy.get('455').errors?.invalidDateRange">
                                              date is out of range
                                              </small>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label style="font-weight: normal;font-size: 14px">Mailling Address</label>
                                <div class="row">
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="446"
                                            [class.is-invalid]="(caliPhy.controls['446'].touched && caliPhy.controls['446'].invalid) || (caliPhy.controls['446'].invalid && isSubmit)"
                                            id="446" name="446" type="text" placeholder="WorkHistory1 Address1"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['446'].touched && caliPhy.controls['446'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['446'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="447"
                                            [class.is-invalid]="(caliPhy.controls['447'].touched && caliPhy.controls['447'].invalid) || (caliPhy.controls['447'].invalid && isSubmit)"
                                            id="447" name="447" type="text" placeholder="WorkHistory1 Address2"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['447'].touched && caliPhy.controls['447'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['447'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="448"
                                            [class.is-invalid]="(caliPhy.controls['448'].touched && caliPhy.controls['448'].invalid) || (caliPhy.controls['448'].invalid && isSubmit)"
                                            id="448" name="448" type="text" placeholder="WorkHistory1 City"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['448'].touched && caliPhy.controls['448'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['448'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="451"
                                            [class.is-invalid]="(caliPhy.controls['451'].touched && caliPhy.controls['451'].invalid) || (caliPhy.controls['451'].invalid && isSubmit)"
                                            id="451" name="451" type="text" placeholder="WorkHistory1 Zip"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['451'].touched && caliPhy.controls['451'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['451'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-6">
                                        <label
                                            style="font-weight: normal;font-size: 14px;padding-right:10px;">WorkHistory1
                                            State</label>
                                        <!-- <div class="col-md-3" style="padding-top:30px">                                             -->
                                        <div class="form-group selectDrop dropDrownSet">
                                            <ng-select [items]="states" bindLabel="name"
                                                [class.is-invalid]="(caliPhy.controls['450'].touched && caliPhy.controls['450'].invalid) || (caliPhy.controls['450'].invalid && isSubmit)"
                                                placeholder="Select States" bindValue="id" formControlName="450">
                                            </ng-select>
                                            <div class="formLabel col-md-11"
                                                *ngIf="caliPhy.controls['450'].touched && caliPhy.controls['450'].invalid">
                                                <small class="text-danger"
                                                    *ngIf="caliPhy.controls['450'].errors?.required">
                                                    required
                                                </small>
                                            </div>
                                        </div>
                                        <!-- </div> -->
                                    </div>
                                    <div class="col-md-6">
                                        <label style="font-weight: normal;font-size: 14px">Contact Name</label>
                                        <input class="form-control onboard" formControlName="452"
                                            [class.is-invalid]="(caliPhy.controls['452'].touched && caliPhy.controls['452'].invalid) || (caliPhy.controls['452'].invalid && isSubmit)"
                                            id="452" name="452" type="text" placeholder="WorkHistory1 Contact"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['452'].touched && caliPhy.controls['452'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['452'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-3">
                                        <label style="font-weight: normal;font-size: 14px">Telephone Number</label>
                                        <input class="form-control onboard" formControlName="453"
                                            [class.is-invalid]="(caliPhy.controls['453'].touched && caliPhy.controls['453'].invalid) || (caliPhy.controls['453'].invalid && isSubmit)"
                                            id="453" name="453" type="text" placeholder="WorkHistory1 Telephone"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['453'].touched && caliPhy.controls['453'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['453'].errors?.required">
                                                phone is required and format is xxx-xxx-xxxx
                                            </small>
                                            <small class="text-danger" *ngIf="caliPhy.controls['708'].errors?.required">
                                                pattern xxx-xxx-xxxx
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <label style="font-weight: normal;font-size: 14px">Email Address</label>
                                        <input class="form-control onboard" formControlName="889"
                                            [class.is-invalid]="(caliPhy.controls['889'].touched && caliPhy.controls['889'].invalid) || (caliPhy.controls['889'].invalid && isSubmit)"
                                            id="889" name="889" type="text" placeholder="WorkHistory1 Email"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['889'].touched && caliPhy.controls['889'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['889'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-12">
                                        <label style="font-weight: normal;font-size: 14px">Please explain any gaps
                                            between this and previous employment</label>
                                        <input class="form-control onboard" formControlName="1138"
                                            [class.is-invalid]="(caliPhy.controls['1138'].touched && caliPhy.controls['1138'].invalid) || (caliPhy.controls['1138'].invalid && isSubmit)"
                                            id="1138" name="1138" type="text"
                                            placeholder="Explanation for Gap in WorkHistory1"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['1138'].touched && caliPhy.controls['1138'].invalid">
                                            <small class="text-danger"
                                                *ngIf="caliPhy.controls['1138'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-12">
                                        <label style="font-weight: normal;font-size: 14px">Previous
                                            Practice/Employer:</label>
                                        <input class="form-control onboard" formControlName="456"
                                            [class.is-invalid]="(caliPhy.controls['456'].touched && caliPhy.controls['456'].invalid) || (caliPhy.controls['456'].invalid && isSubmit)"
                                            id="456" name="456" type="text" placeholder="WorkHistory2 Location "
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['456'].touched && caliPhy.controls['456'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['456'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-6">
                                        <label style="font-weight: normal;font-size: 14px">From (mm/yyyy)</label>
                                        <div class="input-group">
                                            <input class="form-control onboard datepicker" formControlName="465"
                                                ngbDatepicker #d18="ngbDatepicker"
                                                [class.is-invalid]="(caliPhy.controls['465'].touched && caliPhy.controls['465'].invalid) || (caliPhy.controls['465'].invalid && isSubmit)"
                                                id="465" name="465" (blur)="clearInputIfInvalid('465')" type="text" placeholder="WorkHistory2 Start Date"
                                                data-id="managing_contact_name">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary calendar"
                                                    (click)="d18.toggle()" type="button"><i
                                                        class="fas fa-calendar-alt"></i></button>
                                            </div>
                                        </div>
                                        <div  *ngIf="caliPhy.get('465').invalid ">
                                            <small class="text-danger" *ngIf="caliPhy.get('465').errors?.invalidDateFormat">
                                              Only Date Format is allowed(MM/DD/YYYY)
                                            </small>
                                            <small class="text-danger" *ngIf="caliPhy.get('465').errors?.invalidMonth">
                                            Month is out of range
                                            </small>
                                            <small class="text-danger" *ngIf="caliPhy.get('465').errors?.invalidDateyear">
                                              year is out of range(1900-2099)                      </small>
                                              <small class="text-danger" *ngIf="caliPhy.get('465').errors?.invalidDateRange">
                                                date is out of range
                                                </small>
                                            </div>
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['465'].touched && caliPhy.controls['465'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['465'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <label style="font-weight: normal;font-size: 14px">To (mm/yyyy)</label>
                                        <div class="input-group">
                                            <input class="form-control onboard datepicker" formControlName="466"
                                                ngbDatepicker #d19="ngbDatepicker"
                                                [class.is-invalid]="(caliPhy.controls['466'].touched && caliPhy.controls['466'].invalid) || (caliPhy.controls['466'].invalid && isSubmit)"
                                                id="466" name="466" (blur)="clearInputIfInvalid('466')" type="text" placeholder="WorkHistory2 End Date"
                                                data-id="managing_contact_name">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary calendar"
                                                    (click)="d19.toggle()" type="button"><i
                                                        class="fas fa-calendar-alt"></i></button>
                                            </div>
                                        </div>
                                        <div  *ngIf="caliPhy.get('466').invalid ">
                                            <small class="text-danger" *ngIf="caliPhy.get('466').errors?.invalidDateFormat">
                                              Only Date Format is allowed(MM/DD/YYYY)
                                            </small>
                                            <small class="text-danger" *ngIf="caliPhy.get('466').errors?.invalidMonth">
                                            Month is out of range
                                            </small>
                                            <small class="text-danger" *ngIf="caliPhy.get('466').errors?.invalidDateyear">
                                              year is out of range(1900-2099)                      </small>
                                              <small class="text-danger" *ngIf="caliPhy.get('466').errors?.invalidDateRange">
                                                date is out of range
                                                </small>
                                            </div>
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['466'].touched && caliPhy.controls['466'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['466'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label style="font-weight: normal;font-size: 14px">Mailling Address</label>
                                <div class="row">
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="457"
                                            [class.is-invalid]="(caliPhy.controls['457'].touched && caliPhy.controls['457'].invalid) || (caliPhy.controls['457'].invalid && isSubmit)"
                                            id="457" name="457" type="text" placeholder="WorkHistory2 Address1"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['457'].touched && caliPhy.controls['457'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['457'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="458"
                                            [class.is-invalid]="(caliPhy.controls['458'].touched && caliPhy.controls['458'].invalid) || (caliPhy.controls['458'].invalid && isSubmit)"
                                            id="458" name="458" type="text" placeholder="WorkHistory2 Address2"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['458'].touched && caliPhy.controls['458'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['458'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="459"
                                            [class.is-invalid]="(caliPhy.controls['459'].touched && caliPhy.controls['459'].invalid) || (caliPhy.controls['459'].invalid && isSubmit)"
                                            id="459" name="459" type="text" placeholder="WorkHistory2 City"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['459'].touched && caliPhy.controls['459'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['459'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="462"
                                            [class.is-invalid]="(caliPhy.controls['462'].touched && caliPhy.controls['462'].invalid) || (caliPhy.controls['462'].invalid && isSubmit)"
                                            id="462" name="462" type="text" placeholder="WorkHistory2 Zip"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['462'].touched && caliPhy.controls['462'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['462'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-6">
                                        <label
                                            style="font-weight: normal;font-size: 14px;padding-right:10px;">WorkHistory2
                                            State</label>
                                        <!-- <div class="col-md-3" style="padding-top:30px">                                             -->
                                        <div class="form-group selectDrop dropDrownSet">
                                            <ng-select [items]="states" bindLabel="name" placeholder="Select States"
                                                bindValue="id"
                                                [class.is-invalid]="(caliPhy.controls['461'].touched && caliPhy.controls['461'].invalid) || (caliPhy.controls['461'].invalid && isSubmit)"
                                                formControlName="461">
                                            </ng-select>
                                            <div class="formLabel col-md-11"
                                                *ngIf="caliPhy.controls['461'].touched && caliPhy.controls['461'].invalid">
                                                <small class="text-danger"
                                                    *ngIf="caliPhy.controls['461'].errors?.required">
                                                    required
                                                </small>
                                            </div>
                                        </div>
                                        <!-- </div> -->
                                    </div>
                                    <div class="col-md-6">
                                        <label style="font-weight: normal;font-size: 14px">Contact Name</label>
                                        <input class="form-control onboard" formControlName="463"
                                            [class.is-invalid]="(caliPhy.controls['463'].touched && caliPhy.controls['463'].invalid) || (caliPhy.controls['463'].invalid && isSubmit)"
                                            id="463" name="463" type="text" placeholder="WorkHistory2 Contact"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['463'].touched && caliPhy.controls['463'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['463'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-3">
                                        <label style="font-weight: normal;font-size: 14px">Telephone Number</label>
                                        <input class="form-control onboard" formControlName="464"
                                            [class.is-invalid]="(caliPhy.controls['464'].touched && caliPhy.controls['464'].invalid) || (caliPhy.controls['464'].invalid && isSubmit)"
                                            id="464" name="464" type="text" placeholder="WorkHistory2 Telephone"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11" *ngIf="caliPhy.controls['464'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['464'].errors?.pattern">
                                                pattern xxx-xxx-xxxx
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <label style="font-weight: normal;font-size: 14px">Email Address</label>
                                        <input class="form-control onboard" formControlName="891"
                                            [class.is-invalid]="(caliPhy.controls['891'].touched && caliPhy.controls['891'].invalid) || (caliPhy.controls['891'].invalid && isSubmit)"
                                            id="891" name="891" type="text" placeholder="WorkHistory2 Email"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['891'].touched && caliPhy.controls['891'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['891'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-12">
                                        <label style="font-weight: normal;font-size: 14px">Please explain any gaps
                                            between this and previous employment</label>
                                        <input class="form-control onboard" formControlName="1831"
                                            [class.is-invalid]="(caliPhy.controls['1831'].touched && caliPhy.controls['1831'].invalid) || (caliPhy.controls['1831'].invalid && isSubmit)"
                                            id="1831" name="1831" type="text"
                                            placeholder="Explanation for Gap in WorkHistory2"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['1831'].touched && caliPhy.controls['1831'].invalid">
                                            <small class="text-danger"
                                                *ngIf="caliPhy.controls['1831'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr class="tabhead">
                            <th class="tabTh">IV. PRACTICE INFORMATION</th>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-6">
                                        <label style="font-weight: normal;font-size: 14px">Current Name Affiliated with
                                            Tax ID Number</label>
                                        <input class="form-control onboard" formControlName="cmd_402"
                                            [class.is-invalid]="(caliPhy.controls['cmd_402'].touched && caliPhy.controls['cmd_402'].invalid) || (caliPhy.controls['cmd_402'].invalid && isSubmit)"
                                            id="cmd_402" name="cmd_402" type="text" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['cmd_402'].touched && caliPhy.controls['cmd_402'].invalid">
                                            <small class="text-danger"
                                                *ngIf="caliPhy.controls['cmd_402'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <label style="font-weight: normal;font-size: 14px">Federal Tax ID Number</label>
                                        <input class="form-control onboard" formControlName="cmd_403"
                                            [class.is-invalid]="(caliPhy.controls['cmd_403'].touched && caliPhy.controls['cmd_403'].invalid) || (caliPhy.controls['cmd_403'].invalid && isSubmit)"
                                            id="cmd_403" name="cmd_403" type="text" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['cmd_403'].touched && caliPhy.controls['cmd_403'].invalid">
                                            <small class="text-danger"
                                                *ngIf="caliPhy.controls['cmd_403'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-9">
                                        <label style="font-weight: normal;font-size: 14px;padding-left:20px">Are you a
                                            Certified Qualified Medical Examiner (QME) of the State Industrial Medical
                                            Council?</label>
                                    </div>
                                    <div class="col-md-3">
                                        <input type="radio" formControlName="cmd_901"
                                            [class.is-invalid]="(caliPhy.controls['cmd_901'].touched && caliPhy.controls['cmd_901'].invalid) || (caliPhy.controls['cmd_901'].invalid && isSubmit)"
                                            id="cmd_901" name="cmd_901" value="1">
                                        <label>Yes&nbsp;</label>
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['cmd_901'].touched && caliPhy.controls['cmd_901'].invalid">
                                            <small class="text-danger"
                                                *ngIf="caliPhy.controls['cmd_901'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                        <input type="radio" formControlName="cmd_901"
                                            [class.is-invalid]="(caliPhy.controls['cmd_901'].touched && caliPhy.controls['cmd_901'].invalid) || (caliPhy.controls['cmd_901'].invalid && isSubmit)"
                                            id="cmd_901" name="cmd_901" value="0">
                                        <label>No</label>
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['cmd_901'].touched && caliPhy.controls['cmd_901'].invalid">
                                            <small class="text-danger"
                                                *ngIf="caliPhy.controls['cmd_901'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                        <span
                                            *ngIf="(caliPhy.controls['cmd_901'].touched && caliPhy.controls['cmd_901'].invalid) || (caliPhy.controls['cmd_901'].invalid && isSubmit)">
                                            <i class="fas fa-times ml-2 text-danger"></i>
                                        </span>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px;padding-left:20px">Do you
                                            intend to serve as a primary care provider?</label>
                                    </div>
                                    <div class="col-md-3">
                                        <input type="radio" formControlName="cmd_902"
                                            [class.is-invalid]="(caliPhy.controls['cmd_902'].touched && caliPhy.controls['cmd_902'].invalid) || (caliPhy.controls['cmd_902'].invalid && isSubmit)"
                                            id="cmd_902" name="cmd_902" value="1">
                                        <label>Yes&nbsp;</label>
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['cmd_902'].touched && caliPhy.controls['cmd_902'].invalid">
                                            <small class="text-danger"
                                                *ngIf="caliPhy.controls['cmd_902'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                        <input type="radio" formControlName="cmd_902"
                                            [class.is-invalid]="(caliPhy.controls['cmd_902'].touched && caliPhy.controls['cmd_902'].invalid) || (caliPhy.controls['cmd_902'].invalid && isSubmit)"
                                            id="cmd_902" name="cmd_902" value="0">
                                        <label>No</label>
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['cmd_902'].touched && caliPhy.controls['cmd_902'].invalid">
                                            <small class="text-danger"
                                                *ngIf="caliPhy.controls['cmd_902'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                        <span
                                            *ngIf="(caliPhy.controls['cmd_902'].touched && caliPhy.controls['cmd_902'].invalid) || (caliPhy.controls['cmd_902'].invalid && isSubmit)">
                                            <i class="fas fa-times ml-2 text-danger"></i>
                                        </span>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px;padding-left:20px">Do you
                                            intend to serve as a specialist?</label>
                                    </div>
                                    <div class="col-md-2">
                                        <input type="radio" formControlName="cmd_903"
                                        (ngModelChange)="handleYesNoChanges('cmd_903')"

                                            [class.is-invalid]="(caliPhy.controls['cmd_903'].touched && caliPhy.controls['cmd_903'].invalid) || (caliPhy.controls['cmd_903'].invalid && isSubmit)"
                                            id="cmd_903" name="cmd_903" value="1"   >
                                        <label>Yes&nbsp;</label>
                                        
                                        <input type="radio" formControlName="cmd_903"
                                        (ngModelChange)="handleYesNoChanges('cmd_903')"

                                            [class.is-invalid]="(caliPhy.controls['cmd_903'].touched && caliPhy.controls['cmd_903'].invalid) || (caliPhy.controls['cmd_903'].invalid && isSubmit)"
                                            id="cmd_903" name="cmd_903" value="0" >
                                        <label>No</label>
                                        <div class="formLabel col-md-11"
                                        *ngIf="caliPhy.controls['cmd_903'].touched && caliPhy.controls['cmd_903'].invalid">
                                        <small class="text-danger" *ngIf="caliPhy.controls['cmd_903'].errors?.required">
                                            required
                                        </small>
                                    </div>
                                        <span *ngIf="(caliPhy.controls['cmd_903'].touched && caliPhy.controls['cmd_903'].invalid) || (caliPhy.controls['cmd_903'].invalid && isSubmit)">
                                        <i class="fas fa-times ml-2 text-danger"></i>
                                        </span>
                                     </div>
                        
                                    <div class="col-md-3">
                                        <label style="font-weight: normal;font-size: 14px;padding-left:20px">If yes,
                                            list specialty(ies)</label>
                                            <input class="form-control onboard" formControlName="cmd_939"
                                            [class.is-invalid]="(caliPhy.controls['cmd_939'].invalid) || (caliPhy.controls['cmd_939'].invalid && isSubmit)"
                                            id="cmd_939" name="cmd_939" type="text" data-id="managing_contact_name" (blur)="handleYesNoChanges('cmd_903')" >
                                            <span *ngIf="(  caliPhy.controls['cmd_939'].invalid) || (caliPhy.controls['cmd_939'].invalid && isSubmit)">
                                                <i class="fas fa-times ml-2 text-danger">required</i>
                                            </span>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px;padding-left:20px">Is your
                                            practice limited to certain ages?</label>
                                    </div>
                                    <div class="col-md-2">
                                        <input type="radio" formControlName="cmd_904"
                                        (ngModelChange)="handleYesNoChanges('cmd_904')"

                                            [class.is-invalid]="(caliPhy.controls['cmd_904'].touched && caliPhy.controls['cmd_904'].invalid) || (caliPhy.controls['cmd_904'].invalid && isSubmit)"
                                            id="cmd_904" name="cmd_904" value="1"   >
                                        <label>Yes&nbsp;</label>
                                        
                                        <input type="radio" formControlName="cmd_904"
                                        (ngModelChange)="handleYesNoChanges('cmd_904')"

                                            [class.is-invalid]="(caliPhy.controls['cmd_904'].touched && caliPhy.controls['cmd_904'].invalid) || (caliPhy.controls['cmd_904'].invalid && isSubmit)"
                                            id="cmd_904" name="cmd_904" value="0" >
                                        <label>No</label>
                                        <div class="formLabel col-md-11"
                                        *ngIf="caliPhy.controls['cmd_904'].touched && caliPhy.controls['cmd_904'].invalid">
                                        <small class="text-danger" *ngIf="caliPhy.controls['cmd_904'].errors?.required">
                                            required
                                        </small>
                                    </div>
                                        <span *ngIf="(caliPhy.controls['cmd_904'].touched && caliPhy.controls['cmd_904'].invalid) || (caliPhy.controls['cmd_904'].invalid && isSubmit)">
                                        <i class="fas fa-times ml-2 text-danger"></i>
                                        </span>
                                     </div>
                                    <div class="col-md-3">
                                        <label style="font-weight: normal;font-size: 14px;padding-left:20px">If yes,
                                            specify limitations</label>
                                            <input class="form-control onboard" formControlName="cmd_940"
                                            [class.is-invalid]="(caliPhy.controls['cmd_940'].invalid) || (caliPhy.controls['cmd_940'].invalid && isSubmit)"
                                            id="cmd_940" name="cmd_940" type="text" data-id="managing_contact_name" (blur)="handleYesNoChanges('cmd_904')" >
                                            <span *ngIf="(  caliPhy.controls['cmd_940'].invalid) || (caliPhy.controls['cmd_940'].invalid && isSubmit)">
                                                <i class="fas fa-times ml-2 text-danger">required</i>
                                            </span>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px;padding-left:20px">Do you
                                            participate in EDI (electronic data interchange)?</label>
                                    </div>
                                    <div class="col-md-2">
                                        <input type="radio" formControlName="cmd_905"
                                        
                                            id="cmd_905" name="cmd_905" value="1">
                                        <label>Yes&nbsp;</label>
                                        <input type="radio" formControlName="cmd_905"
                                            id="cmd_905" name="cmd_905" value="0" >
                                        <label>No</label>
                                
                                    </div>
                                    <div class="col-md-3">
                                        <label style="font-weight: normal;font-size: 14px;padding-left:20px">If yes,
                                            which Network?</label>
                                        <input class="form-control onboard" formControlName="cmd_941"
                                            id="cmd_941" name="cmd_941" type="text" data-id="managing_contact_name">
    
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px;padding-left:20px">Do you use
                                            a practice management system/software?</label>
                                    </div>
                                    <div class="col-md-2">
                                        <input type="radio" formControlName="cmd_906"
                                            id="cmd_906" name="cmd_906" value="1">
                                        <label>Yes&nbsp;</label>
                                        <input type="radio" formControlName="cmd_906"
                                            id="cmd_906" name="cmd_906" value="0" (change)="dyuapm($event)">
                                        <label>No</label>
                                        
                                    </div>
                                    <div class="col-md-3">
                                        <label style="font-weight: normal;font-size: 14px;padding-left:20px">If yes,
                                            which one?</label>
                                        <input class="form-control onboard" formControlName="cmd_942"
                                            id="cmd_942" name="cmd_942" type="text" data-id="managing_contact_name">
                                        
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-12">
                                        <label style="font-weight: normal;font-size: 14px">Do you employ any allied
                                            health professionals (e.g. nurse practitioners, physician assistants,
                                            psychologists, etc.)?</label>
                                        <input type="radio" formControlName="cmd_907"
                                            [class.is-invalid]="(caliPhy.controls['cmd_907'].touched && caliPhy.controls['cmd_907'].invalid) || (caliPhy.controls['cmd_907'].invalid && isSubmit)"
                                            id="cmd_907" name="cmd_907" value="1" (change)="dyeaah($event)">
                                        <label>Yes&nbsp;</label>
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['cmd_907'].touched && caliPhy.controls['cmd_907'].invalid">
                                            <small class="text-danger"
                                                *ngIf="caliPhy.controls['cmd_907'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                        <input type="radio" formControlName="cmd_907"
                                            [class.is-invalid]="(caliPhy.controls['cmd_907'].touched && caliPhy.controls['cmd_907'].invalid) || (caliPhy.controls['cmd_907'].invalid && isSubmit)"
                                            id="cmd_907" name="cmd_907" value="0" (change)="dyeaah($event)">
                                        <label>No</label>
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['cmd_907'].touched && caliPhy.controls['cmd_907'].invalid">
                                            <small class="text-danger"
                                                *ngIf="caliPhy.controls['cmd_907'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                        <span
                                            *ngIf="(caliPhy.controls['cmd_907'].touched && caliPhy.controls['cmd_907'].invalid) || (caliPhy.controls['cmd_907'].invalid && isSubmit)">
                                            <i class="fas fa-times ml-2 text-danger"></i>
                                        </span>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label style="font-weight: normal;font-size: 14px">If so, please list</label>
                                <div class="row">
                                    <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px">Name</label>
                                        <input class="form-control onboard" formControlName="cmd_501"
                                            [class.is-invalid]="(caliPhy.controls['cmd_501'].touched && caliPhy.controls['cmd_501'].invalid) || (caliPhy.controls['cmd_501'].invalid && isSubmit)"
                                            id="cmd_501" name="cmd_501" type="text" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['cmd_501'].touched && caliPhy.controls['cmd_501'].invalid">
                                            <small class="text-danger"
                                                *ngIf="caliPhy.controls['cmd_501'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px">Type of Provider</label>
                                        <input class="form-control onboard" formControlName="cmd_502"
                                            [class.is-invalid]="(caliPhy.controls['cmd_502'].touched && caliPhy.controls['cmd_502'].invalid) || (caliPhy.controls['cmd_502'].invalid && isSubmit)"
                                            id="cmd_502" name="cmd_502" type="text" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['cmd_502'].touched && caliPhy.controls['cmd_502'].invalid">
                                            <small class="text-danger"
                                                *ngIf="caliPhy.controls['cmd_502'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px">License Number</label>
                                        <input class="form-control onboard" formControlName="cmd_503"
                                            [class.is-invalid]="(caliPhy.controls['cmd_503'].touched && caliPhy.controls['cmd_503'].invalid) || (caliPhy.controls['cmd_501'].invalid && isSubmit)"
                                            id="cmd_503" name="cmd_503" type="text" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['cmd_503'].touched && caliPhy.controls['cmd_503'].invalid">
                                            <small class="text-danger"
                                                *ngIf="caliPhy.controls['cmd_503'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px">Name</label>
                                        <input class="form-control onboard" formControlName="cmd_504" id="cmd_504"
                                            name="cmd_504" type="text" data-id="managing_contact_name">
                                    </div>
                                    <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px">Type of Provider</label>
                                        <input class="form-control onboard" formControlName="cmd_505" id="cmd_505"
                                            name="cmd_505" type="text" data-id="managing_contact_name">
                                    </div>
                                    <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px">License Number</label>
                                        <input class="form-control onboard" formControlName="cmd_506" id="cmd_506"
                                            name="cmd_506" type="text" data-id="managing_contact_name">
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px">Name</label>
                                        <input class="form-control onboard" formControlName="cmd_507" id="cmd_507"
                                            name="cmd_507" type="text" data-id="managing_contact_name">
                                    </div>
                                    <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px">Type of Provider</label>
                                        <input class="form-control onboard" formControlName="cmd_508" id="cmd_508"
                                            name="cmd_508" type="text" data-id="managing_contact_name">
                                    </div>
                                    <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px">License Number</label>
                                        <input class="form-control onboard" formControlName="cmd_509" id="cmd_509"
                                            name="cmd_509" type="text" data-id="managing_contact_name">
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px">Name</label>
                                        <input class="form-control onboard" formControlName="cmd_510" id="cmd_510"
                                            name="cmd_510" type="text" data-id="managing_contact_name">
                                    </div>
                                    <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px">Type of Provider</label>
                                        <input class="form-control onboard" formControlName="cmd_511" id="cmd_511"
                                            name="cmd_511" type="text" data-id="managing_contact_name">
                                    </div>
                                    <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px">License Number</label>
                                        <input class="form-control onboard" formControlName="cmd_512" id="cmd_512"
                                            name="cmd_512" type="text" data-id="managing_contact_name">
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px">Name</label>
                                        <input class="form-control onboard" formControlName="cmd_420" id="cmd_420"
                                            name="cmd_420" type="text" data-id="managing_contact_name">
                                    </div>
                                    <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px">Type of Provider</label>
                                        <input class="form-control onboard" formControlName="cmd_421" id="cmd_421"
                                            name="cmd_421" type="text" data-id="managing_contact_name">
                                    </div>
                                    <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px">License Number</label>
                                        <input class="form-control onboard" formControlName="cmd_422" id="cmd_422"
                                            name="cmd_422" type="text" data-id="managing_contact_name">
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr class="tabSideHeading">
                            <th class="tabMainHeading">OFFICE HOURS (Please indicate the hours your office is open,
                                including lunch closures)</th>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px"></label>
                                    </div>
                                    <div class="col-md-1">
                                        <label style="font-weight: normal;font-size: 14px">Monday</label>
                                    </div>
                                    <div class="col-md-1">
                                        <label style="font-weight: normal;font-size: 14px">Tuesday</label>
                                    </div>
                                    <div class="col-md-1">
                                        <label style="font-weight: normal;font-size: 14px">Wednesday</label>
                                    </div>
                                    <div class="col-md-1">
                                        <label style="font-weight: normal;font-size: 14px">Thrusday</label>
                                    </div>
                                    <div class="col-md-1">
                                        <label style="font-weight: normal;font-size: 14px">Friday</label>
                                    </div>
                                    <div class="col-md-1">
                                        <label style="font-weight: normal;font-size: 14px">Saturday</label>
                                    </div>
                                    <div class="col-md-1">
                                        <label style="font-weight: normal;font-size: 14px">Sunday</label>
                                    </div>
                                    <div class="col-md-1">
                                        <label style="font-weight: normal;font-size: 14px">Holiday</label>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px">Primary Office</label>
                                    </div>
                                    <div class="col-md-1">
                                        <input class="form-control onboard" formControlName="cmd_517"
                                            [class.is-invalid]="(caliPhy.controls['cmd_517'].touched && caliPhy.controls['cmd_517'].invalid) || (caliPhy.controls['cmd_517'].invalid && isSubmit)"
                                            id="cmd_517" name="cmd_517" type="text" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['cmd_517'].touched && caliPhy.controls['cmd_517'].invalid">
                                            <small class="text-danger"
                                                *ngIf="caliPhy.controls['cmd_517'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-1">
                                        <input class="form-control onboard" formControlName="cmd_518"
                                            [class.is-invalid]="(caliPhy.controls['cmd_518'].touched && caliPhy.controls['cmd_518'].invalid) || (caliPhy.controls['cmd_518'].invalid && isSubmit)"
                                            id="cmd_518" name="cmd_518" type="text" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['cmd_518'].touched && caliPhy.controls['cmd_518'].invalid">
                                            <small class="text-danger"
                                                *ngIf="caliPhy.controls['cmd_518'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-1">
                                        <input class="form-control onboard" formControlName="cmd_519"
                                            [class.is-invalid]="(caliPhy.controls['cmd_519'].touched && caliPhy.controls['cmd_519'].invalid) || (caliPhy.controls['cmd_519'].invalid && isSubmit)"
                                            id="cmd_519" name="cmd_519" type="text" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['cmd_519'].touched && caliPhy.controls['cmd_519'].invalid">
                                            <small class="text-danger"
                                                *ngIf="caliPhy.controls['cmd_519'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-1">
                                        <input class="form-control onboard" formControlName="cmd_520"
                                            [class.is-invalid]="(caliPhy.controls['cmd_520'].touched && caliPhy.controls['cmd_520'].invalid) || (caliPhy.controls['cmd_520'].invalid && isSubmit)"
                                            id="cmd_520" name="cmd_520" type="text" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['cmd_520'].touched && caliPhy.controls['cmd_520'].invalid">
                                            <small class="text-danger"
                                                *ngIf="caliPhy.controls['cmd_520'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-1">
                                        <input class="form-control onboard" formControlName="cmd_521"
                                            [class.is-invalid]="(caliPhy.controls['cmd_521'].touched && caliPhy.controls['cmd_521'].invalid) || (caliPhy.controls['cmd_521'].invalid && isSubmit)"
                                            id="cmd_521" name="cmd_521" type="text" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['cmd_521'].touched && caliPhy.controls['cmd_521'].invalid">
                                            <small class="text-danger"
                                                *ngIf="caliPhy.controls['cmd_521'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-1">
                                        <input class="form-control onboard" formControlName="cmd_522"
                                            [class.is-invalid]="(caliPhy.controls['cmd_522'].touched && caliPhy.controls['cmd_522'].invalid) || (caliPhy.controls['cmd_522'].invalid && isSubmit)"
                                            id="cmd_522" name="cmd_522" type="text" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['cmd_522'].touched && caliPhy.controls['cmd_522'].invalid">
                                            <small class="text-danger"
                                                *ngIf="caliPhy.controls['cmd_522'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-1">
                                        <input class="form-control onboard" formControlName="cmd_523"
                                            [class.is-invalid]="(caliPhy.controls['cmd_523'].touched && caliPhy.controls['cmd_523'].invalid) || (caliPhy.controls['cmd_523'].invalid && isSubmit)"
                                            id="cmd_523" name="cmd_523" type="text" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['cmd_523'].touched && caliPhy.controls['cmd_523'].invalid">
                                            <small class="text-danger"
                                                *ngIf="caliPhy.controls['cmd_523'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-1">
                                        <input class="form-control onboard" formControlName="cmd_524"
                                            [class.is-invalid]="(caliPhy.controls['cmd_524'].touched && caliPhy.controls['cmd_524'].invalid) || (caliPhy.controls['cmd_524'].invalid && isSubmit)"
                                            id="cmd_524" name="cmd_524" type="text" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['cmd_524'].touched && caliPhy.controls['cmd_524'].invalid">
                                            <small class="text-danger"
                                                *ngIf="caliPhy.controls['cmd_524'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px">2nd Office</label>
                                    </div>
                                    <div class="col-md-1">
                                        <input class="form-control onboard" formControlName="cmd_525" id="cmd_525"
                                            name="cmd_525" type="text" data-id="managing_contact_name">
                                    </div>
                                    <div class="col-md-1">
                                        <input class="form-control onboard" formControlName="cmd_526" id="cmd_526"
                                            name="cmd_526" type="text" data-id="managing_contact_name">
                                    </div>
                                    <div class="col-md-1">
                                        <input class="form-control onboard" formControlName="cmd_527" id="cmd_527"
                                            name="cmd_527" type="text" data-id="managing_contact_name">
                                    </div>
                                    <div class="col-md-1">
                                        <input class="form-control onboard" formControlName="cmd_528" id="cmd_528"
                                            name="cmd_528" type="text" data-id="managing_contact_name">
                                    </div>
                                    <div class="col-md-1">
                                        <input class="form-control onboard" formControlName="cmd_529" id="cmd_529"
                                            name="cmd_529" type="text" data-id="managing_contact_name">
                                    </div>
                                    <div class="col-md-1">
                                        <input class="form-control onboard" formControlName="cmd_530" id="cmd_530"
                                            name="cmd_530" type="text" data-id="managing_contact_name">
                                    </div>
                                    <div class="col-md-1">
                                        <input class="form-control onboard" formControlName="cmd_531" id="cmd_531"
                                            name="cmd_531" type="text" data-id="managing_contact_name">
                                    </div>
                                    <div class="col-md-1">
                                        <input class="form-control onboard" formControlName="cmd_532" id="cmd_532"
                                            name="cmd_532" type="text" data-id="managing_contact_name">
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px">3rd Office</label>
                                    </div>
                                    <div class="col-md-1">
                                        <input class="form-control onboard" formControlName="cmd_533" id="cmd_533"
                                            name="cmd_533" type="text" data-id="managing_contact_name">
                                    </div>
                                    <div class="col-md-1">
                                        <input class="form-control onboard" formControlName="cmd_534" id="cmd_534"
                                            name="cmd_534" type="text" data-id="managing_contact_name">
                                    </div>
                                    <div class="col-md-1">
                                        <input class="form-control onboard" formControlName="cmd_535" id="cmd_535"
                                            name="cmd_535" type="text" data-id="managing_contact_name">
                                    </div>
                                    <div class="col-md-1">
                                        <input class="form-control onboard" formControlName="cmd_536" id="cmd_536"
                                            name="cmd_536" type="text" data-id="managing_contact_name">
                                    </div>
                                    <div class="col-md-1">
                                        <input class="form-control onboard" formControlName="cmd_537" id="cmd_537"
                                            name="cmd_537" type="text" data-id="managing_contact_name">
                                    </div>
                                    <div class="col-md-1">
                                        <input class="form-control onboard" formControlName="cmd_538" id="cmd_538"
                                            name="cmd_538" type="text" data-id="managing_contact_name">
                                    </div>
                                    <div class="col-md-1">
                                        <input class="form-control onboard" formControlName="cmd_539" id="cmd_539"
                                            name="cmd_539" type="text" data-id="managing_contact_name">
                                    </div>
                                    <div class="col-md-1">
                                        <input class="form-control onboard" formControlName="cmd_540" id="cmd_540"
                                            name="cmd_540" type="text" data-id="managing_contact_name">
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr class="tabhead">
                            <th class="tabTh">V. CURRENT HOSPITAL AND OTHER INSTITUTIONAL AFFILIATIONS </th>
                        </tr>
                        <tr>
                            <td>
                                <p>
                                    Please list in reverse chronological order (with the current affiliation[s] first)
                                    all institutions where you have current affiliations (A) and have
                                    had previous hospital privileges (B) during the past ten years. This includes
                                    hospitals, surgery centers, institutions, corporations, military
                                    assignments, or government agencies. If you do not have hospital privileges, please
                                    explain in the last question of this section
                                </p>
                            </td>
                        </tr>
                        <tr class="tabSideHeading">
                            <th class="tabMainHeading">CURRENT AFFILIATIONS (Attach additional sheets if necessary.
                                Please reference this section number and title.)</th>
                        </tr>
                        <tr>
                            <td>
                                <label style="font-weight: normal;font-size: 14px">Name and Address of Primary Admitting
                                    Hospital</label>
                                <div class="row">
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="418"
                                            [class.is-invalid]="(caliPhy.controls['418'].touched && caliPhy.controls['418'].invalid) || (caliPhy.controls['418'].invalid && isSubmit)"
                                            id="418" name="418" type="text" placeholder="Hospital Affiliation Name"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['418'].touched && caliPhy.controls['418'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['418'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="419"
                                            [class.is-invalid]="(caliPhy.controls['419'].touched && caliPhy.controls['419'].invalid) || (caliPhy.controls['419'].invalid && isSubmit)"
                                            id="419" name="419" type="text" placeholder="Hospital Affiliation Address1"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['419'].touched && caliPhy.controls['419'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['419'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="420"
                                            [class.is-invalid]="(caliPhy.controls['420'].touched && caliPhy.controls['420'].invalid) || (caliPhy.controls['420'].invalid && isSubmit)"
                                            id="420" name="420" type="text" placeholder="Hospital Affiliation Address2"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['420'].touched && caliPhy.controls['420'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['420'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="421"
                                            [class.is-invalid]="(caliPhy.controls['421'].touched && caliPhy.controls['421'].invalid) || (caliPhy.controls['421'].invalid && isSubmit)"
                                            id="421" name="421" type="text" placeholder="Hospital Affiliation City"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['421'].touched && caliPhy.controls['421'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['421'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-6">
                                        <label style="font-weight: normal;font-size: 14px">Hospital Affiliation
                                            State</label>
                                        <div class="form-group selectDropTwo">
                                            <ng-select [items]="states" bindLabel="name" placeholder="Select States"
                                                bindValue="id"
                                                [class.is-invalid]="(caliPhy.controls['423'].touched && caliPhy.controls['423'].invalid) || (caliPhy.controls['423'].invalid && isSubmit)"
                                                formControlName="423">
                                            </ng-select>
                                            <div class="formLabel col-md-11"
                                                *ngIf="caliPhy.controls['423'].touched && caliPhy.controls['423'].invalid">
                                                <small class="text-danger"
                                                    *ngIf="caliPhy.controls['423'].errors?.required">
                                                    required
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px">Hospital Affiliation
                                            Zip</label>
                                        <input class="form-control onboard" formControlName="424"
                                            [class.is-invalid]="(caliPhy.controls['424'].touched && caliPhy.controls['424'].invalid) || (caliPhy.controls['424'].invalid && isSubmit)"
                                            id="424" name="424" type="text" placeholder="Hospital Affiliation Zip"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['424'].touched && caliPhy.controls['424'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['424'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px">Department</label>
                                        <input class="form-control onboard" formControlName="425"
                                            [class.is-invalid]="(caliPhy.controls['425'].touched && caliPhy.controls['425'].invalid) || (caliPhy.controls['425'].invalid && isSubmit)"
                                            id="425" name="425" type="text" placeholder="Hospital Affiliation Dep"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['425'].touched && caliPhy.controls['425'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['425'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <!-- <label style="font-weight: normal;font-size: 14px">Status (active, provisional, courtesy)</label>                             -->
                                        <label style="font-weight: normal;font-size: 14px">Status (active, provisional,
                                            courtesy)</label>
                                        <div class="form-group selectDropTwo">
                                            <ng-select [items]="staffStatus" bindLabel="name"
                                                placeholder="Select StaffStatus" bindValue="id"
                                                [class.is-invalid]="(caliPhy.controls['894'].touched && caliPhy.controls['894'].invalid) || (caliPhy.controls['894'].invalid && isSubmit)"
                                                formControlName="894">
                                            </ng-select>
                                            <div class="formLabel col-md-11"
                                                *ngIf="caliPhy.controls['894'].touched && caliPhy.controls['894'].invalid">
                                                <small class="text-danger"
                                                    *ngIf="caliPhy.controls['894'].errors?.required">
                                                    required
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px">Appointment Date</label>
                                        <div class="input-group">
                                            <input class="form-control onboard datepicker" formControlName="426" (blur)="clearInputIfInvalidMandi('426')"
                                                ngbDatepicker #d21="ngbDatepicker"
                                                [class.is-invalid]="(caliPhy.controls['426'].touched && caliPhy.controls['426'].invalid) || (caliPhy.controls['426'].invalid && isSubmit)"
                                                id="426" name="426" type="text"
                                                placeholder="Hospital Affiliation InitialAppointDate"
                                                data-id="managing_contact_name">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary calendar"
                                                    (click)="d21.toggle()" type="button"><i
                                                        class="fas fa-calendar-alt"></i></button>
                                            </div>
                                        </div>
                               
                                    </div>
                                    <div class="mandatory" *ngIf="(caliPhy.controls['426'].invalid&&caliPhy.controls['426'].touched) && (caliPhy.controls['426'].value==''|| caliPhy.controls['426'].value==null) " >
                                        <small >
                                        required
                                        </small>
                                      
                                      </div>
                                      <small class="text-danger" *ngIf="caliPhy.get('426').errors?.invalidDateFormat">
                                        Only Date Format is allowed(MM/DD/YYYY)                </small>
                                      <small class="text-danger" *ngIf="caliPhy.get('426').errors?.invalidMonth">
                                      Month is out of range
                                      </small>
                                      <small class="text-danger" *ngIf="caliPhy.get('426').errors?.invalidDateyear">
                                        year is out of range(1900-2099)                  </small>
                                        <small class="text-danger" *ngIf="caliPhy.get('426').errors?.invalidDateRange">
                                          date is out of range
                                          </small>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label style="font-weight: normal;font-size: 14px">Name and Address of Other Admitting
                                    Hospital</label>
                                <div class="row">
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="427"
                                            [class.is-invalid]="(caliPhy.controls['427'].touched && caliPhy.controls['427'].invalid) || (caliPhy.controls['427'].invalid && isSubmit)"
                                            id="427" name="427" type="text" placeholder="Hospital2 Affiliation Name"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['427'].touched && caliPhy.controls['427'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['427'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="428"
                                            [class.is-invalid]="(caliPhy.controls['428'].touched && caliPhy.controls['428'].invalid) || (caliPhy.controls['428'].invalid && isSubmit)"
                                            id="428" name="428" type="text" placeholder="Hospital2 Affiliation Address1"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['428'].touched && caliPhy.controls['428'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['428'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="429"
                                            [class.is-invalid]="(caliPhy.controls['429'].touched && caliPhy.controls['429'].invalid) || (caliPhy.controls['429'].invalid && isSubmit)"
                                            id="429" name="429" type="text" placeholder="Hospital2 Affiliation Address2"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['429'].touched && caliPhy.controls['429'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['429'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="430"
                                            [class.is-invalid]="(caliPhy.controls['430'].touched && caliPhy.controls['430'].invalid) || (caliPhy.controls['430'].invalid && isSubmit)"
                                            id="430" name="430" type="text" placeholder="Hospital2 Affiliation City"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['430'].touched && caliPhy.controls['430'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['430'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-6">
                                        <label style="font-weight: normal;font-size: 14px">Hospital Affiliation
                                            State</label>
                                        <div class="form-group selectDropTwo">
                                            <ng-select [items]="states" bindLabel="name" placeholder="Select States"
                                                bindValue="id"
                                                [class.is-invalid]="(caliPhy.controls['432'].touched && caliPhy.controls['432'].invalid) || (caliPhy.controls['432'].invalid && isSubmit)"
                                                formControlName="432">
                                            </ng-select>
                                            <div class="formLabel col-md-11"
                                                *ngIf="caliPhy.controls['432'].touched && caliPhy.controls['432'].invalid">
                                                <small class="text-danger"
                                                    *ngIf="caliPhy.controls['432'].errors?.required">
                                                    required
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px">Hospital Affiliation
                                            Zip</label>
                                        <input class="form-control onboard" formControlName="433"
                                            [class.is-invalid]="(caliPhy.controls['433'].touched && caliPhy.controls['433'].invalid) || (caliPhy.controls['433'].invalid && isSubmit)"
                                            id="433" name="433" type="text" placeholder="Hospital2 Affiliation Zip"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['433'].touched && caliPhy.controls['433'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['433'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px">Department</label>
                                        <input class="form-control onboard" formControlName="434"
                                            [class.is-invalid]="(caliPhy.controls['434'].touched && caliPhy.controls['434'].invalid) || (caliPhy.controls['434'].invalid && isSubmit)"
                                            id="434" name="434" type="text" placeholder="Hospital2 Affiliation Dep"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['434'].touched && caliPhy.controls['434'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['434'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <!-- <label style="font-weight: normal;font-size: 14px">Status (active, provisional, courtesy)</label>                             -->
                                        <label style="font-weight: normal;font-size: 14px">Status (active, provisional,
                                            courtesy)</label>
                                        <div class="form-group selectDropTwo">
                                            <ng-select [items]="staffStatus" bindLabel="name"
                                                placeholder="Select StaffStatus" bindValue="id"
                                                [class.is-invalid]="(caliPhy.controls['895'].touched && caliPhy.controls['895'].invalid) || (caliPhy.controls['895'].invalid && isSubmit)"
                                                formControlName="895">
                                            </ng-select>
                                            <div class="formLabel col-md-11"
                                                *ngIf="caliPhy.controls['895'].touched && caliPhy.controls['895'].invalid">
                                                <small class="text-danger"
                                                    *ngIf="caliPhy.controls['895'].errors?.required">
                                                    required
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px">Appointment Date</label>
                                        <div class="input-group">
                                            <input class="form-control onboard datepicker" formControlName="435"
                                                ngbDatepicker #d22="ngbDatepicker"
                                                [class.is-invalid]="(caliPhy.controls['435'].touched && caliPhy.controls['435'].invalid) || (caliPhy.controls['435'].invalid && isSubmit)"
                                                id="435" name="435" (blur)="clearInputIfInvalid('435')" type="text"
                                                placeholder="Hospital2 Affiliation InitialAppointDate"
                                                data-id="managing_contact_name">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary calendar"
                                                    (click)="d22.toggle()" type="button"><i
                                                        class="fas fa-calendar-alt"></i></button>
                                            </div>
                                        </div>
                                        <div  *ngIf="caliPhy.get('435').invalid ">
                                            <small class="text-danger" *ngIf="caliPhy.get('435').errors?.invalidDateFormat">
                                              Only Date Format is allowed(MM/DD/YYYY)
                                            </small>
                                            <small class="text-danger" *ngIf="caliPhy.get('435').errors?.invalidMonth">
                                            Month is out of range
                                            </small>
                                            <small class="text-danger" *ngIf="caliPhy.get('435').errors?.invalidDateyear">
                                              year is out of range(1900-2099)                      </small>
                                              <small class="text-danger" *ngIf="caliPhy.get('435').errors?.invalidDateRange">
                                                date is out of range
                                                </small>
                                            </div>
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['435'].touched && caliPhy.controls['435'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['435'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label style="font-weight: normal;font-size: 14px">Name and Address of Other Admitting
                                    Hospital</label>
                                <div class="row">
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="436"
                                            [class.is-invalid]="(caliPhy.controls['436'].touched && caliPhy.controls['436'].invalid) || (caliPhy.controls['436'].invalid && isSubmit)"
                                            id="436" name="436" type="text" placeholder="Hospital3 Affiliation Name"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['436'].touched && caliPhy.controls['436'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['436'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="437"
                                            [class.is-invalid]="(caliPhy.controls['437'].touched && caliPhy.controls['437'].invalid) || (caliPhy.controls['437'].invalid && isSubmit)"
                                            id="437" name="437" type="text" placeholder="Hospital3 Affiliation Address1"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['437'].touched && caliPhy.controls['437'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['437'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="438"
                                            [class.is-invalid]="(caliPhy.controls['438'].touched && caliPhy.controls['438'].invalid) || (caliPhy.controls['438'].invalid && isSubmit)"
                                            id="438" name="438" type="text" placeholder="Hospital3 Affiliation Address2"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['438'].touched && caliPhy.controls['438'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['438'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="439"
                                            [class.is-invalid]="(caliPhy.controls['439'].touched && caliPhy.controls['439'].invalid) || (caliPhy.controls['439'].invalid && isSubmit)"
                                            id="439" name="439" type="text" placeholder="Hospital3 Affiliation City"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['439'].touched && caliPhy.controls['439'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['439'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-6">
                                        <label style="font-weight: normal;font-size: 14px">Hospital Affiliation
                                            State</label>
                                        <div class="form-group selectDropTwo">
                                            <ng-select [items]="states" bindLabel="name" placeholder="Select States"
                                                bindValue="id"
                                                [class.is-invalid]="(caliPhy.controls['441'].touched && caliPhy.controls['441'].invalid) || (caliPhy.controls['441'].invalid && isSubmit)"
                                                formControlName="441">
                                            </ng-select>
                                            <div class="formLabel col-md-11"
                                                *ngIf="caliPhy.controls['441'].touched && caliPhy.controls['441'].invalid">
                                                <small class="text-danger"
                                                    *ngIf="caliPhy.controls['441'].errors?.required">
                                                    required
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px">Hospital Affiliation
                                            Zip</label>
                                        <input class="form-control onboard" formControlName="442"
                                            [class.is-invalid]="(caliPhy.controls['442'].touched && caliPhy.controls['442'].invalid) || (caliPhy.controls['442'].invalid && isSubmit)"
                                            id="442" name="442" type="text" placeholder="Hospital3 Affiliation Zip"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['442'].touched && caliPhy.controls['442'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['442'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px">Department</label>
                                        <input class="form-control onboard" formControlName="443"
                                            [class.is-invalid]="(caliPhy.controls['443'].touched && caliPhy.controls['443'].invalid) || (caliPhy.controls['443'].invalid && isSubmit)"
                                            id="443" name="443" type="text" placeholder="Hospital3 Affiliation Dep"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['443'].touched && caliPhy.controls['443'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['443'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <!-- <label style="font-weight: normal;font-size: 14px">Status (active, provisional, courtesy)</label>                             -->
                                        <label style="font-weight: normal;font-size: 14px">Status (active, provisional,
                                            courtesy)</label>
                                        <div class="form-group selectDropTwo">
                                            <ng-select [items]="staffStatus" bindLabel="name"
                                                placeholder="Select StaffStatus" bindValue="id"
                                                [class.is-invalid]="(caliPhy.controls['896'].touched && caliPhy.controls['896'].invalid) || (caliPhy.controls['896'].invalid && isSubmit)"
                                                formControlName="896">
                                            </ng-select>
                                            <div class="formLabel col-md-11"
                                                *ngIf="caliPhy.controls['896'].touched && caliPhy.controls['896'].invalid">
                                                <small class="text-danger"
                                                    *ngIf="caliPhy.controls['896'].errors?.required">
                                                    required
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px">Appointment Date</label>
                                        <div class="input-group">
                                            <input class="form-control onboard datepicker" formControlName="444"
                                                ngbDatepicker #d24="ngbDatepicker"
                                                [class.is-invalid]="(caliPhy.controls['444'].touched && caliPhy.controls['444'].invalid) || (caliPhy.controls['444'].invalid && isSubmit)"
                                                id="444" name="444" (blur)="clearInputIfInvalid('444')" type="text"
                                                placeholder="Hospital3 Affiliation InitialAppointDate"
                                                data-id="managing_contact_name">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary calendar"
                                                    (click)="d24.toggle()" type="button"><i
                                                        class="fas fa-calendar-alt"></i></button>
                                            </div>
                                        </div>
                                        <div  *ngIf="caliPhy.get('444').invalid ">
                                            <small class="text-danger" *ngIf="caliPhy.get('444').errors?.invalidDateFormat">
                                              Only Date Format is allowed(MM/DD/YYYY)
                                            </small>
                                            <small class="text-danger" *ngIf="caliPhy.get('444').errors?.invalidMonth">
                                            Month is out of range
                                            </small>
                                            <small class="text-danger" *ngIf="caliPhy.get('444').errors?.invalidDateyear">
                                              year is out of range(1900-2099)                      </small>
                                              <small class="text-danger" *ngIf="caliPhy.get('444').errors?.invalidDateRange">
                                                date is out of range
                                                </small>
                                            </div>
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['444'].touched && caliPhy.controls['444'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['444'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr class="tabSideHeading">
                            <th class="tabMainHeading">PREVIOUS AFFILIATIONS (during last ten years. Attach additional
                                sheets if necessary. Please reference this section number and title.)</th>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <label style="float: right;">Does Not Apply</label>
                                    <input type="checkbox" name="cmd_1020" formControlName="cmd_1020" id="cmd_1020"
                                        value="1">
                                    <!-- <div class="formLabel col-md-11"
                                        *ngIf="caliPhy.controls['cmd_1020'].touched && caliPhy.controls['cmd_1020'].invalid">
                                        <small class="text-danger" *ngIf="caliPhy.controls['cmd_1020'].errors?.required">
                                            required
                                        </small>
                                    </div>   -->
                                    <!-- <span *ngIf="(caliPhy.controls['cmd_1020'].touched && caliPhy.controls['cmd_1020'].invalid) || (caliPhy.controls['cmd_1020'].invalid && isSubmit)">
                                        <i class="fas fa-times ml-2 text-danger"></i>
                                    </span>   -->
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label style="font-weight: normal;font-size: 14px">Name and Address of Other Admitting
                                    Hospital</label>
                                <div class="row">
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="cmd_1000"
                                            [class.is-invalid]="(caliPhy.controls['cmd_1000'].touched && caliPhy.controls['cmd_1000'].invalid) || (caliPhy.controls['cmd_1000'].invalid && isSubmit)"
                                            id="cmd_1000" name="cmd_1000" type="text"
                                            placeholder="Previous Affiliation Name" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['cmd_1000'].touched && caliPhy.controls['cmd_1000'].invalid">
                                            <small class="text-danger"
                                                *ngIf="caliPhy.controls['cmd_1000'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="cmd_1001"
                                            [class.is-invalid]="(caliPhy.controls['cmd_1001'].touched && caliPhy.controls['cmd_1001'].invalid) || (caliPhy.controls['cmd_1001'].invalid && isSubmit)"
                                            id="cmd_1001" name="cmd_1001" type="text"
                                            placeholder="Previous Affiliation Address1" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['cmd_1001'].touched && caliPhy.controls['cmd_1001'].invalid">
                                            <small class="text-danger"
                                                *ngIf="caliPhy.controls['cmd_1001'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="cmd_1002"
                                            [class.is-invalid]="(caliPhy.controls['cmd_1002'].touched && caliPhy.controls['cmd_1002'].invalid) || (caliPhy.controls['cmd_1002'].invalid && isSubmit)"
                                            id="cmd_1002" name="cmd_1002" type="text"
                                            placeholder="Previous Affiliation Address2" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['cmd_1002'].touched && caliPhy.controls['cmd_1002'].invalid">
                                            <small class="text-danger"
                                                *ngIf="caliPhy.controls['cmd_1002'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="cmd_1003"
                                            [class.is-invalid]="(caliPhy.controls['cmd_1003'].touched && caliPhy.controls['cmd_1003'].invalid) || (caliPhy.controls['cmd_1003'].invalid && isSubmit)"
                                            id="cmd_1003" name="cmd_1003" type="text"
                                            placeholder="Previous Affiliation City" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['cmd_1003'].touched && caliPhy.controls['cmd_1003'].invalid">
                                            <small class="text-danger"
                                                *ngIf="caliPhy.controls['cmd_1003'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-6">
                                        <label style="font-weight: normal;font-size: 14px">Previous Affiliation
                                            State</label>
                                        <div class="form-group selectDropTwo">
                                            <ng-select [items]="states" bindLabel="name" placeholder="Select States"
                                                bindValue="id"
                                                [class.is-invalid]="(caliPhy.controls['cmd_1004'].touched && caliPhy.controls['cmd_1004'].invalid) || (caliPhy.controls['cmd_1004'].invalid && isSubmit)"
                                                formControlName="cmd_1004">
                                            </ng-select>
                                            <div class="formLabel col-md-11"
                                                *ngIf="caliPhy.controls['cmd_1004'].touched && caliPhy.controls['cmd_1004'].invalid">
                                                <small class="text-danger"
                                                    *ngIf="caliPhy.controls['cmd_1004'].errors?.required">
                                                    required
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px">Previous Affiliation
                                            Zip</label>
                                        <input class="form-control onboard" formControlName="cmd_1005"
                                            [class.is-invalid]="(caliPhy.controls['cmd_1005'].touched && caliPhy.controls['cmd_1005'].invalid) || (caliPhy.controls['cmd_1005'].invalid && isSubmit)"
                                            id="cmd_1005" name="cmd_1005" type="text"
                                            placeholder="Previous Affiliation Zip" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['cmd_1005'].touched && caliPhy.controls['cmd_1005'].invalid">
                                            <small class="text-danger"
                                                *ngIf="caliPhy.controls['cmd_1005'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px">From (mm/yyyy)</label>
                                        <div class="input-group">
                                            <input class="form-control onboard datepicker" formControlName="cmd_1006"
                                                ngbDatepicker #d26="ngbDatepicker"
                                                [class.is-invalid]="(caliPhy.controls['cmd_1006'].touched && caliPhy.controls['cmd_1006'].invalid) || (caliPhy.controls['cmd_1006'].invalid && isSubmit)"
                                                id="cmd_1006" name="cmd_1006" (blur)="clearInputIfInvalid('cmd_1006')" type="text"
                                                placeholder="Previous Affiliation InitialDate"
                                                data-id="managing_contact_name">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary calendar"
                                                    (click)="d26.toggle()" type="button"><i
                                                        class="fas fa-calendar-alt"></i></button>
                                            </div>
                                        </div>
                                        <div  *ngIf="caliPhy.get('cmd_1006').invalid ">
                                            <small class="text-danger" *ngIf="caliPhy.get('cmd_1006').errors?.invalidDateFormat">
                                              Only Date Format is allowed(MM/DD/YYYY)
                                            </small>
                                            <small class="text-danger" *ngIf="caliPhy.get('cmd_1006').errors?.invalidMonth">
                                            Month is out of range
                                            </small>
                                            <small class="text-danger" *ngIf="caliPhy.get('cmd_1006').errors?.invalidDateyear">
                                              year is out of range(1900-2099)                      </small>
                                              <small class="text-danger" *ngIf="caliPhy.get('cmd_1006').errors?.invalidDateRange">
                                                date is out of range
                                                </small>
                                            </div>
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['cmd_1006'].touched && caliPhy.controls['cmd_1006'].invalid">
                                            <small class="text-danger"
                                                *ngIf="caliPhy.controls['cmd_1006'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px">To (mm/yyyy)</label>
                                        <div class="input-group">
                                            <input class="form-control onboard datepicker" formControlName="cmd_1007"
                                                ngbDatepicker #d27="ngbDatepicker"
                                                [class.is-invalid]="(caliPhy.controls['cmd_1007'].touched && caliPhy.controls['cmd_1007'].invalid) || (caliPhy.controls['cmd_1007'].invalid && isSubmit)"
                                                id="cmd_1007" name="cmd_1007" (blur)="clearInputIfInvalid('cmd_1007')" type="text"
                                                placeholder="Previous Affiliation EndDate"
                                                data-id="managing_contact_name">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary calendar"
                                                    (click)="d27.toggle()" type="button"><i
                                                        class="fas fa-calendar-alt"></i></button>
                                            </div>
                                        </div>
                                        <div  *ngIf="caliPhy.get('cmd_1007').invalid ">
                                            <small class="text-danger" *ngIf="caliPhy.get('cmd_1007').errors?.invalidDateFormat">
                                              Only Date Format is allowed(MM/DD/YYYY)
                                            </small>
                                            <small class="text-danger" *ngIf="caliPhy.get('cmd_1007').errors?.invalidMonth">
                                            Month is out of range
                                            </small>
                                            <small class="text-danger" *ngIf="caliPhy.get('cmd_1007').errors?.invalidDateyear">
                                              year is out of range(1900-2099)                      </small>
                                              <small class="text-danger" *ngIf="caliPhy.get('cmd_1007').errors?.invalidDateRange">
                                                date is out of range
                                                </small>
                                            </div>
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['cmd_1007'].touched && caliPhy.controls['cmd_1007'].invalid">
                                            <small class="text-danger"
                                                *ngIf="caliPhy.controls['cmd_1007'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px">Reason for Leaving</label>
                                        <input class="form-control onboard" formControlName="cmd_1008"
                                            [class.is-invalid]="(caliPhy.controls['cmd_1008'].touched && caliPhy.controls['cmd_1008'].invalid) || (caliPhy.controls['cmd_1008'].invalid && isSubmit)"
                                            id="cmd_1008" name="cmd_1008" type="text"
                                            placeholder="PrevAff Reasonforleaving" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['cmd_1008'].touched && caliPhy.controls['cmd_1008'].invalid">
                                            <small class="text-danger"
                                                *ngIf="caliPhy.controls['cmd_1008'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label style="font-weight: normal;font-size: 14px">Name and Address of Other Admitting
                                    Hospital</label>
                                <div class="row">
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="cmd_1009"
                                            [class.is-invalid]="(caliPhy.controls['cmd_1009'].touched && caliPhy.controls['cmd_1009'].invalid) || (caliPhy.controls['cmd_1009'].invalid && isSubmit)"
                                            id="cmd_1009" name="cmd_1009" type="text"
                                            placeholder="Previous Affiliation Name" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['cmd_1009'].touched && caliPhy.controls['cmd_1009'].invalid">
                                            <small class="text-danger"
                                                *ngIf="caliPhy.controls['cmd_1009'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="cmd_1010"
                                            [class.is-invalid]="(caliPhy.controls['cmd_1010'].touched && caliPhy.controls['cmd_1010'].invalid) || (caliPhy.controls['cmd_1010'].invalid && isSubmit)"
                                            id="cmd_1010" name="cmd_1010" type="text"
                                            placeholder="Previous Affiliation Address1" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['cmd_1010'].touched && caliPhy.controls['cmd_1010'].invalid">
                                            <small class="text-danger"
                                                *ngIf="caliPhy.controls['cmd_1010'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="cmd_1011"
                                            [class.is-invalid]="(caliPhy.controls['cmd_1011'].touched && caliPhy.controls['cmd_1011'].invalid) || (caliPhy.controls['cmd_1011'].invalid && isSubmit)"
                                            id="cmd_1011" name="cmd_1011" type="text"
                                            placeholder="Previous Affiliation Address2" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['cmd_1011'].touched && caliPhy.controls['cmd_1011'].invalid">
                                            <small class="text-danger"
                                                *ngIf="caliPhy.controls['cmd_1011'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="cmd_1012"
                                            [class.is-invalid]="(caliPhy.controls['cmd_1012'].touched && caliPhy.controls['cmd_1012'].invalid) || (caliPhy.controls['cmd_1012'].invalid && isSubmit)"
                                            id="cmd_1012" name="cmd_1012" type="text"
                                            placeholder="Previous Affiliation City" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['cmd_1012'].touched && caliPhy.controls['cmd_1012'].invalid">
                                            <small class="text-danger"
                                                *ngIf="caliPhy.controls['cmd_1012'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-6">
                                        <label style="font-weight: normal;font-size: 14px">Previous Affiliation
                                            State</label>
                                        <div class="form-group selectDropTwo">
                                            <ng-select [items]="states" bindLabel="name" placeholder="Select States"
                                                bindValue="id"
                                                [class.is-invalid]="(caliPhy.controls['cmd_1013'].touched && caliPhy.controls['cmd_1013'].invalid) || (caliPhy.controls['cmd_1013'].invalid && isSubmit)"
                                                formControlName="cmd_1013">
                                            </ng-select>
                                            <div class="formLabel col-md-11"
                                                *ngIf="caliPhy.controls['cmd_1013'].touched && caliPhy.controls['cmd_1013'].invalid">
                                                <small class="text-danger"
                                                    *ngIf="caliPhy.controls['cmd_1013'].errors?.required">
                                                    required
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px">Previous Affiliation
                                            Zip</label>
                                        <input class="form-control onboard" formControlName="cmd_1014"
                                            [class.is-invalid]="(caliPhy.controls['cmd_1014'].touched && caliPhy.controls['cmd_1014'].invalid) || (caliPhy.controls['cmd_1014'].invalid && isSubmit)"
                                            id="cmd_1014" name="cmd_1014" type="text"
                                            placeholder="Previous Affiliation Zip" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['cmd_1014'].touched && caliPhy.controls['cmd_1014'].invalid">
                                            <small class="text-danger"
                                                *ngIf="caliPhy.controls['cmd_1014'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px">From (mm/yyyy)</label>
                                        <div class="input-group">
                                            <input class="form-control onboard datepicker" formControlName="cmd_1015"
                                                ngbDatepicker #d28="ngbDatepicker"
                                                [class.is-invalid]="(caliPhy.controls['cmd_1015'].touched && caliPhy.controls['cmd_1015'].invalid) || (caliPhy.controls['cmd_1015'].invalid && isSubmit)"
                                                id="cmd_1015" name="cmd_1015" (blur)="clearInputIfInvalid('cmd_1015')" type="text"
                                                placeholder="Previous Affiliation InitialDate"
                                                data-id="managing_contact_name">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary calendar"
                                                    (click)="d28.toggle()" type="button"><i
                                                        class="fas fa-calendar-alt"></i></button>
                                            </div>
                                        </div>
                                        <div  *ngIf="caliPhy.get('cmd_1015').invalid ">
                                            <small class="text-danger" *ngIf="caliPhy.get('cmd_1015').errors?.invalidDateFormat">
                                              Only Date Format is allowed(MM/DD/YYYY)
                                            </small>
                                            <small class="text-danger" *ngIf="caliPhy.get('cmd_1015').errors?.invalidMonth">
                                            Month is out of range
                                            </small>
                                            <small class="text-danger" *ngIf="caliPhy.get('cmd_1015').errors?.invalidDateyear">
                                              year is out of range(1900-2099)                      </small>
                                              <small class="text-danger" *ngIf="caliPhy.get('cmd_1015').errors?.invalidDateRange">
                                                date is out of range
                                                </small>
                                            </div>
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['cmd_1015'].touched && caliPhy.controls['cmd_1015'].invalid">
                                            <small class="text-danger"
                                                *ngIf="caliPhy.controls['cmd_1015'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px">To (mm/yyyy)</label>
                                        <div class="input-group">
                                            <input class="form-control onboard datepicker" formControlName="cmd_1016"
                                                ngbDatepicker #d29="ngbDatepicker"
                                                [class.is-invalid]="(caliPhy.controls['cmd_1016'].touched && caliPhy.controls['cmd_1016'].invalid) || (caliPhy.controls['cmd_1016'].invalid && isSubmit)"
                                                id="cmd_1016" name="cmd_1016" (blur)="clearInputIfInvalid('cmd_1016')" type="text"
                                                placeholder="Previous Affiliation EndDate"
                                                data-id="managing_contact_name">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary calendar"
                                                    (click)="d29.toggle()" type="button"><i
                                                        class="fas fa-calendar-alt"></i></button>
                                            </div>
                                        </div>
                                        <div  *ngIf="caliPhy.get('cmd_1016').invalid ">
                                            <small class="text-danger" *ngIf="caliPhy.get('cmd_1016').errors?.invalidDateFormat">
                                              Only Date Format is allowed(MM/DD/YYYY)
                                            </small>
                                            <small class="text-danger" *ngIf="caliPhy.get('cmd_1016').errors?.invalidMonth">
                                            Month is out of range
                                            </small>
                                            <small class="text-danger" *ngIf="caliPhy.get('cmd_1016').errors?.invalidDateyear">
                                              year is out of range(1900-2099)                      </small>
                                              <small class="text-danger" *ngIf="caliPhy.get('cmd_1016').errors?.invalidDateRange">
                                                date is out of range
                                                </small>
                                            </div>
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['cmd_1016'].touched && caliPhy.controls['cmd_1016'].invalid">
                                            <small class="text-danger"
                                                *ngIf="caliPhy.controls['cmd_1016'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px">Reason for Leaving</label>
                                        <input class="form-control onboard" formControlName="cmd_1017"
                                            [class.is-invalid]="(caliPhy.controls['cmd_1017'].touched && caliPhy.controls['cmd_1017'].invalid) || (caliPhy.controls['cmd_1017'].invalid && isSubmit)"
                                            id="cmd_1017" name="cmd_1017" type="text"
                                            placeholder="PrevAff Reasonforleaving" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['cmd_1017'].touched && caliPhy.controls['cmd_1017'].invalid">
                                            <small class="text-danger"
                                                *ngIf="caliPhy.controls['cmd_1017'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-12">
                                        <label style="font-weight: normal;font-size: 14px">If you do not have hospital
                                            privileges, please provide written plan for continuity of care:</label>
                                        <input class="form-control onboard" formControlName="cmd_1018"
                                            [class.is-invalid]="(caliPhy.controls['cmd_1018'].touched && caliPhy.controls['cmd_1018'].invalid) || (caliPhy.controls['cmd_1018'].invalid && isSubmit)"
                                            id="cmd_1018" name="cmd_1018" type="text" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['cmd_1018'].touched && caliPhy.controls['cmd_1018'].invalid">
                                            <small class="text-danger"
                                                *ngIf="caliPhy.controls['cmd_1018'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr style="background-color:#000000;">
                            <th style="color: white;text-align:left;">VI. PROFESSIONAL LIABILITY. Please send copies of
                                professional liability policy or certification face sheet to <u
                                    style="color: blue;">operations@bassmedicalgroup.com</u></th>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px">Current Insurance
                                            Carrier</label>
                                        <input class="form-control onboard" formControlName="345" id="345" name="345"
                                            [class.is-invalid]="(caliPhy.controls['345'].touched && caliPhy.controls['345'].invalid) || (caliPhy.controls['345'].invalid && isSubmit)"
                                            type="text" placeholder="MLP Carrier Name" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['345'].touched && caliPhy.controls['345'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['345'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px">Policy Number</label>
                                        <input class="form-control onboard" formControlName="346" id="346"
                                            [class.is-invalid]="(caliPhy.controls['346'].touched && caliPhy.controls['346'].invalid) || (caliPhy.controls['346'].invalid && isSubmit)"
                                            name="346" type="text" placeholder="MLP Policy Number"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['346'].touched && caliPhy.controls['346'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['346'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px">Original Effective
                                            Date</label>
                                        <div class="input-group">
                                            <input class="form-control onboard datepicker"
                                                [class.is-invalid]="(caliPhy.controls['348'].touched && caliPhy.controls['348'].invalid) || (caliPhy.controls['348'].invalid && isSubmit)"
                                                formControlName="348" ngbDatepicker #d91="ngbDatepicker" id="348" (blur)="clearInputIfInvalidMandi('348')"
                                                name="348" type="text" placeholder="MLP issue date"
                                                data-id="managing_contact_name">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary calendar"
                                                    (click)="d91.toggle()" type="button"><i
                                                        class="fas fa-calendar-alt"></i></button>
                                            </div>
                                          
                                        </div>
                                        <div class="mandatory" *ngIf="(caliPhy.controls['348'].invalid&&caliPhy.controls['348'].touched) && (caliPhy.controls['348'].value==''|| caliPhy.controls['348'].value==null) " >
                                            <small >
                                            required
                                            </small>
                                          
                                          </div>
                                          <small class="text-danger" *ngIf="caliPhy.get('348').errors?.invalidDateFormat">
                                            Only Date Format is allowed(MM/DD/YYYY)                </small>
                                          <small class="text-danger" *ngIf="caliPhy.get('348').errors?.invalidMonth">
                                          Month is out of range
                                          </small>
                                          <small class="text-danger" *ngIf="caliPhy.get('348').errors?.invalidDateyear">
                                            year is out of range(1900-2099)                  </small>
                                            <small class="text-danger" *ngIf="caliPhy.get('348').errors?.invalidDateRange">
                                              date is out of range
                                              </small>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label style="font-weight: normal;font-size: 14px">Mailing Address</label>
                                <div class="row">
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="901"
                                            [class.is-invalid]="(caliPhy.controls['901'].touched && caliPhy.controls['901'].invalid) || (caliPhy.controls['901'].invalid && isSubmit)"
                                            id="901" name="901" type="text" placeholder="MLP Address1"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['901'].touched && caliPhy.controls['901'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['901'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="902"
                                            [class.is-invalid]="(caliPhy.controls['902'].touched && caliPhy.controls['902'].invalid) || (caliPhy.controls['902'].invalid && isSubmit)"
                                            id="902" name="902" type="text" placeholder="MLP Address2"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['902'].touched && caliPhy.controls['902'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['902'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="906"
                                            [class.is-invalid]="(caliPhy.controls['906'].touched && caliPhy.controls['906'].invalid) || (caliPhy.controls['906'].invalid && isSubmit)"
                                            id="906" name="906" type="text" placeholder="MLP ZIP"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['906'].touched && caliPhy.controls['906'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['906'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="903"
                                            [class.is-invalid]="(caliPhy.controls['903'].touched && caliPhy.controls['903'].invalid) || (caliPhy.controls['903'].invalid && isSubmit)"
                                            id="903" name="903" type="text" placeholder="MLP City"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['903'].touched && caliPhy.controls['903'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['903'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px">MLP State</label>
                                        <div class="form-group selectDropTwo">
                                            <ng-select [items]="states" bindLabel="name"
                                                [class.is-invalid]="(caliPhy.controls['905'].touched && caliPhy.controls['905'].invalid) || (caliPhy.controls['905'].invalid && isSubmit)"
                                                placeholder="Select States" bindValue="id" formControlName="905">
                                            </ng-select>
                                            <div class="formLabel col-md-11"
                                                *ngIf="caliPhy.controls['905'].touched && caliPhy.controls['905'].invalid">
                                                <small class="text-danger"
                                                    *ngIf="caliPhy.controls['905'].errors?.required">
                                                    required
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px">MLP PerCliam </label>
                                        <div class="form-group selectDropTwo">
                                            <ng-select [items]="preClaim" bindLabel="name"
                                                [class.is-invalid]="(caliPhy.controls['350'].touched && caliPhy.controls['350'].invalid) || (caliPhy.controls['350'].invalid && isSubmit)"
                                                placeholder="Select PreClaim" bindValue="id" formControlName="350">
                                            </ng-select>
                                            <div class="formLabel col-md-11"
                                                *ngIf="caliPhy.controls['350'].touched && caliPhy.controls['350'].invalid">
                                                <small class="text-danger"
                                                    *ngIf="caliPhy.controls['350'].errors?.required">
                                                    required
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px">MLP Aggregate</label>
                                        <div class="form-group selectDropTwo">
                                            <ng-select [items]="aggregates" bindLabel="name"
                                                [class.is-invalid]="(caliPhy.controls['351'].touched && caliPhy.controls['351'].invalid) || (caliPhy.controls['351'].invalid && isSubmit)"
                                                placeholder="Select Aggregate" bindValue="id" formControlName="351">
                                            </ng-select>
                                            <div class="formLabel col-md-11"
                                                *ngIf="caliPhy.controls['351'].touched && caliPhy.controls['351'].invalid">
                                                <small class="text-danger"
                                                    *ngIf="caliPhy.controls['351'].errors?.required">
                                                    required
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px">Expiration Date</label>
                                        <div class="input-group">
                                            <input class="form-control onboard datepicker"
                                                [class.is-invalid]="(caliPhy.controls['349'].touched && caliPhy.controls['349'].invalid) || (caliPhy.controls['349'].invalid && isSubmit)"
                                                formControlName="349" ngbDatepicker #d31="ngbDatepicker" id="349" (blur)="clearInputIfInvalidMandi('349')"
                                                name="349" type="text" placeholder="MLP ExiprationDate"
                                                data-id="managing_contact_name">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary calendar"
                                                    (click)="d31.toggle()" type="button"><i
                                                        class="fas fa-calendar-alt"></i></button>
                                            </div>
                                           
                                        </div>
                                        <div class="mandatory" *ngIf="(caliPhy.controls['349'].invalid&&caliPhy.controls['349'].touched) && (caliPhy.controls['349'].value==''|| caliPhy.controls['349'].value==null) " >
                                            <small >
                                            required
                                            </small>
                                          
                                          </div>
                                          <small class="text-danger" *ngIf="caliPhy.get('349').errors?.invalidDateFormat">
                                            Only Date Format is allowed(MM/DD/YYYY)                </small>
                                          <small class="text-danger" *ngIf="caliPhy.get('349').errors?.invalidMonth">
                                          Month is out of range
                                          </small>
                                          <small class="text-danger" *ngIf="caliPhy.get('349').errors?.invalidDateyear">
                                            year is out of range(1900-2099)                  </small>
                                            <small class="text-danger" *ngIf="caliPhy.get('349').errors?.invalidDateRange">
                                              date is out of range
                                              </small>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr class="tabSideHeading">
                            <th class="tabMainHeading">Please list all your professional liability carriers within the
                                past 7 years, other than the one listed above</th>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <label style="float: right;">Does Not Apply</label>
                                    <input type="checkbox" name="cmd_1021" formControlName="cmd_1021" id="cmd_1021"
                                        value="1">
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label style="font-weight: normal;font-size: 14px">Name of Carrier and Address of
                                    Carrier</label>
                                <div class="row">
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="cmd_601"
                                            [class.is-invalid]="(caliPhy.controls['cmd_601'].touched && caliPhy.controls['cmd_601'].invalid) || (caliPhy.controls['cmd_601'].invalid && isSubmit)"
                                            id="cmd_601" name="cmd_601" type="text" placeholder="Previous MLP Carriers"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['cmd_601'].touched && caliPhy.controls['cmd_601'].invalid">
                                            <small class="text-danger"
                                                *ngIf="caliPhy.controls['cmd_601'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="cmd_602"
                                            [class.is-invalid]="(caliPhy.controls['cmd_602'].touched && caliPhy.controls['cmd_602'].invalid) || (caliPhy.controls['cmd_602'].invalid && isSubmit)"
                                            id="cmd_602" name="cmd_602" type="text" placeholder="Previous MLP Address1"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['cmd_602'].touched && caliPhy.controls['cmd_602'].invalid">
                                            <small class="text-danger"
                                                *ngIf="caliPhy.controls['cmd_602'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="cmd_603"
                                            [class.is-invalid]="(caliPhy.controls['cmd_603'].touched && caliPhy.controls['cmd_603'].invalid) || (caliPhy.controls['cmd_603'].invalid && isSubmit)"
                                            id="cmd_603" name="cmd_603" type="text" placeholder="Previous MLP Address2"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['cmd_603'].touched && caliPhy.controls['cmd_603'].invalid">
                                            <small class="text-danger"
                                                *ngIf="caliPhy.controls['cmd_603'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="cmd_604"
                                            [class.is-invalid]="(caliPhy.controls['cmd_604'].touched && caliPhy.controls['cmd_604'].invalid) || (caliPhy.controls['cmd_604'].invalid && isSubmit)"
                                            id="cmd_604" name="cmd_604" type="text" placeholder="Previous MLP City"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['cmd_604'].touched && caliPhy.controls['cmd_604'].invalid">
                                            <small class="text-danger"
                                                *ngIf="caliPhy.controls['cmd_604'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px">Previous MLP State</label>
                                        <div class="form-group selectDrop">
                                            <ng-select [items]="states" bindLabel="name" placeholder="Select States"
                                                bindValue="id"
                                                [class.is-invalid]="(caliPhy.controls['cmd_605'].touched && caliPhy.controls['cmd_605'].invalid) || (caliPhy.controls['cmd_605'].invalid && isSubmit)"
                                                formControlName="cmd_605">
                                            </ng-select>
                                            <div class="formLabel col-md-11"
                                                *ngIf="caliPhy.controls['cmd_605'].touched && caliPhy.controls['cmd_605'].invalid">
                                                <small class="text-danger"
                                                    *ngIf="caliPhy.controls['cmd_605'].errors?.required">
                                                    required
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <label style="font-weight: normal;font-size: 14px;padding-top:15px"></label>
                                        <input class="form-control onboard" formControlName="cmd_606"
                                            [class.is-invalid]="(caliPhy.controls['cmd_606'].touched && caliPhy.controls['cmd_606'].invalid) || (caliPhy.controls['cmd_606'].invalid && isSubmit)"
                                            id="cmd_606" name="cmd_606" type="text" placeholder="Previous MLP Zip"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['cmd_606'].touched && caliPhy.controls['cmd_606'].invalid">
                                            <small class="text-danger"
                                                *ngIf="caliPhy.controls['cmd_606'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px">Policy No</label>
                                        <input class="form-control onboard" formControlName="cmd_607"
                                            [class.is-invalid]="(caliPhy.controls['cmd_607'].touched && caliPhy.controls['cmd_607'].invalid) || (caliPhy.controls['cmd_607'].invalid && isSubmit)"
                                            id="cmd_607" name="cmd_607" type="text"
                                            placeholder="Previous MLP Policy Number" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['cmd_607'].touched && caliPhy.controls['cmd_607'].invalid">
                                            <small class="text-danger"
                                                *ngIf="caliPhy.controls['cmd_607'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px">From (mm/yyyy)</label>
                                        <div class="input-group">
                                            <input class="form-control onboard datepicker" formControlName="cmd_608"
                                                ngbDatepicker #d35="ngbDatepicker"
                                                [class.is-invalid]="(caliPhy.controls['cmd_608'].touched && caliPhy.controls['cmd_608'].invalid) || (caliPhy.controls['cmd_608'].invalid && isSubmit)"
                                                id="cmd_608" name="cmd_608" (blur)="clearInputIfInvalid('cmd_608')" type="text"
                                                placeholder="Previous MLP Effective Date"
                                                data-id="managing_contact_name">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary calendar"
                                                    (click)="d35.toggle()" type="button"><i
                                                        class="fas fa-calendar-alt"></i></button>
                                            </div>
                                        </div>
                                        <div  *ngIf="caliPhy.get('cmd_608').invalid ">
                                            <small class="text-danger" *ngIf="caliPhy.get('cmd_608').errors?.invalidDateFormat">
                                              Only Date Format is allowed(MM/DD/YYYY)
                                            </small>
                                            <small class="text-danger" *ngIf="caliPhy.get('cmd_608').errors?.invalidMonth">
                                            Month is out of range
                                            </small>
                                            <small class="text-danger" *ngIf="caliPhy.get('cmd_608').errors?.invalidDateyear">
                                              year is out of range(1900-2099)                      </small>
                                              <small class="text-danger" *ngIf="caliPhy.get('cmd_608').errors?.invalidDateRange">
                                                date is out of range
                                                </small>
                                            </div>
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['cmd_608'].touched && caliPhy.controls['cmd_608'].invalid">
                                            <small class="text-danger"
                                                *ngIf="caliPhy.controls['cmd_608'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px">To (mm/yyyy)</label>
                                        <div class="input-group">
                                            <input class="form-control onboard datepicker" formControlName="cmd_609"
                                                ngbDatepicker #d36="ngbDatepicker"
                                                [class.is-invalid]="(caliPhy.controls['cmd_609'].touched && caliPhy.controls['cmd_609'].invalid) || (caliPhy.controls['cmd_609'].invalid && isSubmit)"
                                                id="cmd_609" name="cmd_609" (blur)="clearInputIfInvalid('cmd_609')"  type="text"
                                                placeholder="Previous MLP Exipration Date"
                                                data-id="managing_contact_name">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary calendar"
                                                    (click)="d36.toggle()" type="button"><i
                                                        class="fas fa-calendar-alt"></i></button>
                                            </div>
                                        </div>
                                        <div  *ngIf="caliPhy.get('cmd_609').invalid ">
                                            <small class="text-danger" *ngIf="caliPhy.get('cmd_609').errors?.invalidDateFormat">
                                              Only Date Format is allowed(MM/DD/YYYY)
                                            </small>
                                            <small class="text-danger" *ngIf="caliPhy.get('cmd_609').errors?.invalidMonth">
                                            Month is out of range
                                            </small>
                                            <small class="text-danger" *ngIf="caliPhy.get('cmd_609').errors?.invalidDateyear">
                                              year is out of range(1900-2099)                      </small>
                                              <small class="text-danger" *ngIf="caliPhy.get('cmd_609').errors?.invalidDateRange">
                                                date is out of range
                                                </small>
                                            </div>
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['cmd_609'].touched && caliPhy.controls['cmd_609'].invalid">
                                            <small class="text-danger"
                                                *ngIf="caliPhy.controls['cmd_609'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label style="font-weight: normal;font-size: 14px">Name of Carrier and Address of
                                    Carrier</label>
                                <div class="row">
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="cmd_610"
                                            [class.is-invalid]="(caliPhy.controls['cmd_610'].touched && caliPhy.controls['cmd_610'].invalid) || (caliPhy.controls['cmd_610'].invalid && isSubmit)"
                                            id="cmd_610" name="cmd_610" type="text" placeholder="Previous MLP Carriers"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['cmd_610'].touched && caliPhy.controls['cmd_610'].invalid">
                                            <small class="text-danger"
                                                *ngIf="caliPhy.controls['cmd_610'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="cmd_611"
                                            [class.is-invalid]="(caliPhy.controls['cmd_611'].touched && caliPhy.controls['cmd_611'].invalid) || (caliPhy.controls['cmd_611'].invalid && isSubmit)"
                                            id="cmd_611" name="cmd_611" type="text" placeholder="Previous MLP Address1"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['cmd_611'].touched && caliPhy.controls['cmd_611'].invalid">
                                            <small class="text-danger"
                                                *ngIf="caliPhy.controls['cmd_611'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="cmd_612"
                                            [class.is-invalid]="(caliPhy.controls['cmd_612'].touched && caliPhy.controls['cmd_612'].invalid) || (caliPhy.controls['cmd_612'].invalid && isSubmit)"
                                            id="cmd_612" name="cmd_612" type="text" placeholder="Previous MLP Address2"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['cmd_612'].touched && caliPhy.controls['cmd_612'].invalid">
                                            <small class="text-danger"
                                                *ngIf="caliPhy.controls['cmd_612'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="cmd_613"
                                            [class.is-invalid]="(caliPhy.controls['cmd_613'].touched && caliPhy.controls['cmd_613'].invalid) || (caliPhy.controls['cmd_613'].invalid && isSubmit)"
                                            id="cmd_613" name="cmd_613" type="text" placeholder="Previous MLP City"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['cmd_613'].touched && caliPhy.controls['cmd_613'].invalid">
                                            <small class="text-danger"
                                                *ngIf="caliPhy.controls['cmd_613'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px">Previous MLP State</label>
                                        <div class="form-group selectDrop">
                                            <ng-select [items]="states" bindLabel="name" placeholder="Select States"
                                                bindValue="id"
                                                [class.is-invalid]="(caliPhy.controls['cmd_614'].touched && caliPhy.controls['cmd_614'].invalid) || (caliPhy.controls['cmd_614'].invalid && isSubmit)"
                                                formControlName="cmd_614">
                                            </ng-select>
                                            <div class="formLabel col-md-11"
                                                *ngIf="caliPhy.controls['cmd_614'].touched && caliPhy.controls['cmd_614'].invalid">
                                                <small class="text-danger"
                                                    *ngIf="caliPhy.controls['cmd_614'].errors?.required">
                                                    required
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <label style="font-weight: normal;font-size: 14px;padding-top:15px"></label>
                                        <input class="form-control onboard" formControlName="cmd_615"
                                            [class.is-invalid]="(caliPhy.controls['cmd_615'].touched && caliPhy.controls['cmd_615'].invalid) || (caliPhy.controls['cmd_615'].invalid && isSubmit)"
                                            id="cmd_615" name="cmd_615" type="text" placeholder="Previous MLP Zip"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['cmd_615'].touched && caliPhy.controls['cmd_615'].invalid">
                                            <small class="text-danger"
                                                *ngIf="caliPhy.controls['cmd_615'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px">Policy No</label>
                                        <input class="form-control onboard" formControlName="cmd_616"
                                            [class.is-invalid]="(caliPhy.controls['cmd_616'].touched && caliPhy.controls['cmd_616'].invalid) || (caliPhy.controls['cmd_616'].invalid && isSubmit)"
                                            id="cmd_616" name="cmd_616" type="text"
                                            placeholder="Previous MLP Policy Number" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['cmd_616'].touched && caliPhy.controls['cmd_616'].invalid">
                                            <small class="text-danger"
                                                *ngIf="caliPhy.controls['cmd_616'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px">From (mm/yyyy)</label>
                                        <div class="input-group">
                                            <input class="form-control onboard datepicker" formControlName="cmd_617"
                                                ngbDatepicker #d38="ngbDatepicker"
                                                [class.is-invalid]="(caliPhy.controls['cmd_617'].touched && caliPhy.controls['cmd_617'].invalid) || (caliPhy.controls['cmd_617'].invalid && isSubmit)"
                                                id="cmd_617" name="cmd_617" (blur)="clearInputIfInvalid('cmd_617')"  type="text"
                                                placeholder="Previous MLP Effective Date"
                                                data-id="managing_contact_name">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary calendar"
                                                    (click)="d38.toggle()" type="button"><i
                                                        class="fas fa-calendar-alt"></i></button>
                                            </div>
                                        </div>
                                        <div  *ngIf="caliPhy.get('cmd_617').invalid ">
                                            <small class="text-danger" *ngIf="caliPhy.get('cmd_617').errors?.invalidDateFormat">
                                              Only Date Format is allowed(MM/DD/YYYY)
                                            </small>
                                            <small class="text-danger" *ngIf="caliPhy.get('cmd_617').errors?.invalidMonth">
                                            Month is out of range
                                            </small>
                                            <small class="text-danger" *ngIf="caliPhy.get('cmd_617').errors?.invalidDateyear">
                                              year is out of range(1900-2099)                      </small>
                                              <small class="text-danger" *ngIf="caliPhy.get('cmd_617').errors?.invalidDateRange">
                                                date is out of range
                                                </small>
                                            </div>
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['cmd_617'].touched && caliPhy.controls['cmd_617'].invalid">
                                            <small class="text-danger"
                                                *ngIf="caliPhy.controls['cmd_617'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px">To (mm/yyyy)</label>
                                        <div class="input-group">
                                            <input class="form-control onboard datepicker" formControlName="cmd_618"
                                                ngbDatepicker #d39="ngbDatepicker"
                                                [class.is-invalid]="(caliPhy.controls['cmd_618'].touched && caliPhy.controls['cmd_618'].invalid) || (caliPhy.controls['cmd_618'].invalid && isSubmit)"
                                                id="cmd_618" name="cmd_618" (blur)="clearInputIfInvalid('cmd_618')"  type="text"
                                                placeholder="Previous MLP Exipration Date"
                                                data-id="managing_contact_name">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary calendar"
                                                    (click)="d39.toggle()" type="button"><i
                                                        class="fas fa-calendar-alt"></i></button>
                                            </div>
                                        </div>
                                        <div  *ngIf="caliPhy.get('cmd_618').invalid ">
                                            <small class="text-danger" *ngIf="caliPhy.get('cmd_618').errors?.invalidDateFormat">
                                              Only Date Format is allowed(MM/DD/YYYY)
                                            </small>
                                            <small class="text-danger" *ngIf="caliPhy.get('cmd_618').errors?.invalidMonth">
                                            Month is out of range
                                            </small>
                                            <small class="text-danger" *ngIf="caliPhy.get('cmd_618').errors?.invalidDateyear">
                                              year is out of range(1900-2099)                      </small>
                                              <small class="text-danger" *ngIf="caliPhy.get('cmd_618').errors?.invalidDateRange">
                                                date is out of range
                                                </small>
                                            </div>
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['cmd_618'].touched && caliPhy.controls['cmd_618'].invalid">
                                            <small class="text-danger"
                                                *ngIf="caliPhy.controls['cmd_618'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr style="background-color:#000000;">
                            <th style="color: white;text-align:left;">VII. COVERAGE OF PRACTICE</th>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-6">
                                        <label style="font-weight: normal;font-size: 14px">Answering Service
                                            Company</label>
                                        <input class="form-control onboard" formControlName="cmd_321" id="cmd_321"
                                            name="cmd_321" type="text" placeholder="Answering Service Company"
                                            data-id="managing_contact_name">
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-6">
                                        <label style="font-weight: normal;font-size: 14px">Mailing Address</label>
                                        <input class="form-control onboard" formControlName="cmd_541"
                                            [class.is-invalid]="(caliPhy.controls['cmd_541'].touched && caliPhy.controls['cmd_541'].invalid) || (caliPhy.controls['cmd_541'].invalid && isSubmit)"
                                            id="cmd_541" name="cmd_541" type="text" placeholder="Mailing Address"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['cmd_541'].touched && caliPhy.controls['cmd_541'].invalid">
                                            <small class="text-danger"
                                                *ngIf="caliPhy.controls['cmd_541'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <label style="font-weight: normal;font-size: 14px">Phone Number</label>
                                        <input class="form-control onboard" formControlName="cmd_542"
                                            [class.is-invalid]="(caliPhy.controls['cmd_542'].touched && caliPhy.controls['cmd_542'].invalid) || (caliPhy.controls['cmd_542'].invalid && isSubmit)"
                                            id="cmd_542" name="cmd_542" type="text" placeholder="Phone Number"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['cmd_542'].touched && caliPhy.controls['cmd_542'].invalid">
                                            <small class="text-danger"
                                                *ngIf="caliPhy.controls['cmd_542'].errors?.required">
                                                Home phone is required and format is xxx-xxx-xxxx
                                            </small>
                                            <small class="text-danger"
                                                *ngIf="caliPhy.controls['cmd_542'].errors?.required">
                                                pattern xxx-xxx-xxxx
                                            </small>
                                        </div>

                                    </div>
                                    <div class="col-md-3">
                                        <label style="font-weight: normal;font-size: 14px">Fax Number</label>
                                        <input class="form-control onboard" formControlName="cmd_543"
                                            [class.is-invalid]="(caliPhy.controls['cmd_543'].touched && caliPhy.controls['cmd_543'].invalid) || (caliPhy.controls['cmd_543'].invalid && isSubmit)"
                                            id="cmd_543" name="cmd_543" type="text" placeholder="Fax Number"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['cmd_543'].touched && caliPhy.controls['cmd_543'].invalid">
                                            <small class="text-danger"
                                                *ngIf="caliPhy.controls['cmd_543'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-6">
                                        <label style="font-weight: normal;font-size: 14px">Covering Physician's
                                            Name</label>
                                        <input class="form-control onboard" formControlName="2953"
                                            [class.is-invalid]="(caliPhy.controls['2953'].touched && caliPhy.controls['2953'].invalid) || (caliPhy.controls['2953'].invalid && isSubmit)"
                                            id="2953" name="2953" type="text" placeholder="Covering Physician's Name1"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['2953'].touched && caliPhy.controls['2953'].invalid">
                                            <small class="text-danger"
                                                *ngIf="caliPhy.controls['2953'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <label style="font-weight: normal;font-size: 14px">Telephone Number</label>
                                        <input class="form-control onboard" formControlName="2960"
                                            [class.is-invalid]="(caliPhy.controls['2960'].touched && caliPhy.controls['2960'].invalid) || (caliPhy.controls['2960'].invalid && isSubmit)"
                                            id="2960" name="2960" type="text" placeholder="Telephone Number1"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['2960'].touched && caliPhy.controls['2960'].invalid">
                                            <small class="text-danger"
                                                *ngIf="caliPhy.controls['2960'].errors?.required">
                                                phone is required and format is xxx-xxx-xxxx
                                            </small>
                                            <small class="text-danger"
                                                *ngIf="caliPhy.controls['2960'].errors?.required">
                                                pattern xxx-xxx-xxxx
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-6">
                                        <label style="font-weight: normal;font-size: 14px">Covering Physician's
                                            Name</label>
                                        <input class="form-control onboard" formControlName="2963"
                                            [class.is-invalid]="(caliPhy.controls['2963'].touched && caliPhy.controls['2963'].invalid) || (caliPhy.controls['2963'].invalid && isSubmit)"
                                            id="2963" name="2963" type="text" placeholder="Covering Physician's Name2"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['2963'].touched && caliPhy.controls['2963'].invalid">
                                            <small class="text-danger"
                                                *ngIf="caliPhy.controls['2963'].errors?.required">
                                                required
                                            </small>
                                        </div>

                                    </div>
                                    <div class="col-md-6">
                                        <label style="font-weight: normal;font-size: 14px">Telephone Number</label>
                                        <input class="form-control onboard" formControlName="2976"
                                            [class.is-invalid]="(caliPhy.controls['2976'].touched && caliPhy.controls['2976'].invalid) || (caliPhy.controls['2976'].invalid && isSubmit)"
                                            id="2976" name="2976" type="text" placeholder="Telephone Number2"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['2976'].touched && caliPhy.controls['2976'].invalid">
                                            <small class="text-danger"
                                                *ngIf="caliPhy.controls['2976'].errors?.required">
                                                phone is required and format is xxx-xxx-xxxx
                                            </small>
                                            <small class="text-danger"
                                                *ngIf="caliPhy.controls['2976'].errors?.required">
                                                pattern xxx-xxx-xxxx
                                            </small>
                                        </div>

                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-6">
                                        <label style="font-weight: normal;font-size: 14px">Covering Physician's
                                            Name</label>
                                        <input class="form-control onboard" formControlName="2979"
                                            [class.is-invalid]="(caliPhy.controls['2979'].touched && caliPhy.controls['2979'].invalid) || (caliPhy.controls['2979'].invalid && isSubmit)"
                                            id="2979" name="2979" type="text" placeholder="Covering Physician's Name3"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['2979'].touched && caliPhy.controls['2979'].invalid">
                                            <small class="text-danger"
                                                *ngIf="caliPhy.controls['2979'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <label style="font-weight: normal;font-size: 14px">Telephone Number</label>
                                        <input class="form-control onboard" formControlName="2986"
                                            [class.is-invalid]="(caliPhy.controls['2986'].touched && caliPhy.controls['2986'].invalid) || (caliPhy.controls['2986'].invalid && isSubmit)"
                                            id="2986" name="2986" type="text" placeholder="Telephone Number3"
                                            data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['2986'].touched && caliPhy.controls['2986'].invalid">
                                            <small class="text-danger"
                                                *ngIf="caliPhy.controls['2986'].errors?.required">
                                                phone is required and format is xxx-xxx-xxxx
                                            </small>
                                            <small class="text-danger"
                                                *ngIf="caliPhy.controls['2986'].errors?.required">
                                                pattern xxx-xxx-xxxx
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr class="tabhead">
                            <th class="tabTh">VIII. ATTESTATION QUESTIONS</th>
                        </tr>
                        <tr>
                            <td>
                                <p><b>
                                        Please answer the following questions "yes" or "no". If your answer to questions
                                        A through L is "yes" or if your answer to M & N is "no", please provide full
                                        details on a separate document and email to <u
                                            style="color: blue;">operations@bassmedicalgroup.com.</u></b>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-9">
                                        <label
                                            style="font-weight: normal;font-size: 14px;padding-left:20px;text-align:block;">
                                            A. Has your license to practice medicine in any jurisdiction, your Drug
                                            Enforcement Administration (DEA) registration
                                            or any applicable narcotic registration in any jurisdiction ever been
                                            denied, limited, restricted, suspended, revoked,
                                            not renewed, or subject to probationary conditions, or have you voluntarily
                                            or involuntarily relinquished any such
                                            license or registration or voluntarily or involuntarily accepted any such
                                            actions or conditions, or have you been fined
                                            or received a letter of reprimand or is such action pending?
                                        </label>
                                    </div>
                                    <div class="col-md-2">
                                        <input type="radio" formControlName="cmd_909" id="cmd_909" name="cmd_909"
                                        [class.is-invalid]="(caliPhy.controls['cmd_909'].touched && caliPhy.controls['cmd_909'].invalid) || (caliPhy.controls['cmd_909'].invalid && isSubmit)"
                                        value="1">
                                        <label>Yes&nbsp;</label>
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['cmd_909'].touched && caliPhy.controls['cmd_909'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['cmd_909'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                        <input type="radio" formControlName="cmd_909" id="cmd_909" name="cmd_909"
                                        [class.is-invalid]="(caliPhy.controls['cmd_909'].touched && caliPhy.controls['cmd_909'].invalid) || (caliPhy.controls['cmd_909'].invalid && isSubmit)"
                                            value="0">
                                        <label>No</label>
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['cmd_909'].touched && caliPhy.controls['cmd_909'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['cmd_909'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                        <span *ngIf="(caliPhy.controls['cmd_909'].touched && caliPhy.controls['cmd_909'].invalid) || (caliPhy.controls['cmd_909'].invalid && isSubmit)">
                                            <i class="fas fa-times ml-2 text-danger"></i>
                                        </span>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-9">
                                        <label
                                            style="font-weight: normal;font-size: 14px;padding-left:20px;text-align:block;">
                                            B. Have you ever been charged, suspended, fined, disciplined, or otherwise
                                            sanctioned, subjected to probationary
                                            conditions, restricted or excluded, or have you voluntarily or involuntarily
                                            relinquished eligibility to provide
                                            services or accepted conditions on your eligibility to provide services, for
                                            reasons relating to possible incompetence
                                            or improper professional conduct, or breach of contract or program
                                            conditions, by Medicare, Medicaid, or any
                                            public program, or is any such action pending?
                                        </label>
                                    </div>
                                    <div class="col-md-2">
                                        <input type="radio" formControlName="cmd_910" id="cmd_910" name="cmd_910"
                                        [class.is-invalid]="(caliPhy.controls['cmd_910'].touched && caliPhy.controls['cmd_910'].invalid) || (caliPhy.controls['cmd_910'].invalid && isSubmit)"
                                        value="1">
                                        <label>Yes&nbsp;</label>
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['cmd_910'].touched && caliPhy.controls['cmd_910'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['cmd_910'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                        <input type="radio" formControlName="cmd_910" id="cmd_910" name="cmd_910"
                                        [class.is-invalid]="(caliPhy.controls['cmd_910'].touched && caliPhy.controls['cmd_910'].invalid) || (caliPhy.controls['cmd_910'].invalid && isSubmit)"
                                            value="0">
                                        <label>No</label>
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['cmd_910'].touched && caliPhy.controls['cmd_910'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['cmd_910'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                        <span *ngIf="(caliPhy.controls['cmd_910'].touched && caliPhy.controls['cmd_910'].invalid) || (caliPhy.controls['cmd_910'].invalid && isSubmit)">
                                            <i class="fas fa-times ml-2 text-danger"></i>
                                        </span>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-9">
                                        <label
                                            style="font-weight: normal;font-size: 14px;padding-left:20px;text-align:block;">
                                            C. Have your clinical privileges, membership, contractual participation or
                                            employment by any medical organization
                                            (e.g. hospital medical staff, medical group, independent practice
                                            association (IPA), health plan, health maintenance
                                            organization (HMO), preferred provider organization (PPO), private payer
                                            (including those that contract with public
                                            programs), medical society, professional association, medical school faculty
                                            position or other health delivery entity
                                            or system), ever been denied, suspended, restricted, reduced, subject to
                                            probationary conditions, revoked or not
                                            renewed for possible incompetence, improper professional conduct or breach
                                            of contract, or is any such action
                                            pending?
                                        </label>
                                    </div>
                                    <div class="col-md-2">
                                        <input type="radio" formControlName="cmd_911" id="cmd_911" name="cmd_911"
                                        [class.is-invalid]="(caliPhy.controls['cmd_911'].touched && caliPhy.controls['cmd_911'].invalid) || (caliPhy.controls['cmd_911'].invalid && isSubmit)"
                                        value="1">
                                        <label>Yes&nbsp;</label>
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['cmd_911'].touched && caliPhy.controls['cmd_911'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['cmd_911'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                        <input type="radio" formControlName="cmd_911" id="cmd_911" name="cmd_911"
                                        [class.is-invalid]="(caliPhy.controls['cmd_911'].touched && caliPhy.controls['cmd_911'].invalid) || (caliPhy.controls['cmd_911'].invalid && isSubmit)"
                                            value="0">
                                        <label>No</label>
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['cmd_911'].touched && caliPhy.controls['cmd_911'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['cmd_911'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                        <span *ngIf="(caliPhy.controls['cmd_911'].touched && caliPhy.controls['cmd_911'].invalid) || (caliPhy.controls['cmd_911'].invalid && isSubmit)">
                                            <i class="fas fa-times ml-2 text-danger"></i>
                                        </span>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-9">
                                        <label
                                            style="font-weight: normal;font-size: 14px;padding-left:20px;text-align:block;">
                                            D. Have you ever surrendered, allowed to expire, voluntarily or
                                            involuntarily withdrawn a request for membership or
                                            clinical privileges, terminated contractual participation or employment, or
                                            resigned from any medical organization
                                            (e.g. hospital medical staff, medical group, independent practice
                                            association (IPA), health plan, health maintenance
                                            organization (HMO), preferred provider organization (PPO), medical society,
                                            professional association, medical
                                            school faculty position or other health delivery entity or system) while
                                            under investigation for possible
                                            incompetence or improper professional conduct, or breach of contract, or in
                                            return for such an investigation not
                                            being conducted, or is any such action pending?
                                        </label>
                                    </div>
                                    <div class="col-md-2">
                                        <input type="radio" formControlName="cmd_912" id="cmd_912" name="cmd_912"
                                        [class.is-invalid]="(caliPhy.controls['cmd_912'].touched && caliPhy.controls['cmd_912'].invalid) || (caliPhy.controls['cmd_912'].invalid && isSubmit)"
                                        value="1">
                                        <label>Yes&nbsp;</label>
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['cmd_912'].touched && caliPhy.controls['cmd_912'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['cmd_912'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                        <input type="radio" formControlName="cmd_912" id="cmd_912" name="cmd_912"
                                        [class.is-invalid]="(caliPhy.controls['cmd_912'].touched && caliPhy.controls['cmd_912'].invalid) || (caliPhy.controls['cmd_912'].invalid && isSubmit)"
                                            value="0">
                                        <label>No</label>
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['cmd_912'].touched && caliPhy.controls['cmd_912'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['cmd_912'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                        <span *ngIf="(caliPhy.controls['cmd_912'].touched && caliPhy.controls['cmd_912'].invalid) || (caliPhy.controls['cmd_912'].invalid && isSubmit)">
                                            <i class="fas fa-times ml-2 text-danger"></i>
                                        </span>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-9">
                                        <label
                                            style="font-weight: normal;font-size: 14px;padding-left:20px;text-align:block;">
                                            E. Have you ever surrendered, voluntarily withdrawn, or been requested or
                                            compelled to relinquish your status as a
                                            student in good standing in any internship, residency, fellowship,
                                            preceptorship, or other clinical education
                                            program?
                                        </label>
                                    </div>
                                    <div class="col-md-2">
                                        <input type="radio" formControlName="cmd_913" id="cmd_913" name="cmd_913"
                                        [class.is-invalid]="(caliPhy.controls['cmd_913'].touched && caliPhy.controls['cmd_913'].invalid) || (caliPhy.controls['cmd_913'].invalid && isSubmit)"
                                        value="1">
                                        <label>Yes&nbsp;</label>
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['cmd_913'].touched && caliPhy.controls['cmd_913'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['cmd_913'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                        <input type="radio" formControlName="cmd_913" id="cmd_913" name="cmd_913"
                                        [class.is-invalid]="(caliPhy.controls['cmd_913'].touched && caliPhy.controls['cmd_913'].invalid) || (caliPhy.controls['cmd_913'].invalid && isSubmit)"
                                            value="0">
                                        <label>No</label>
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['cmd_913'].touched && caliPhy.controls['cmd_913'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['cmd_913'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                        <span *ngIf="(caliPhy.controls['cmd_913'].touched && caliPhy.controls['cmd_913'].invalid) || (caliPhy.controls['cmd_913'].invalid && isSubmit)">
                                            <i class="fas fa-times ml-2 text-danger"></i>
                                        </span>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-9">
                                        <label
                                            style="font-weight: normal;font-size: 14px;padding-left:20px;text-align:block;">
                                            F. Has your membership or fellowship in any local, county, state, regional,
                                            national, or international professional
                                            organization ever been revoked, denied, reduced, limited, subjected to
                                            probationary conditions, or not renewed, or is
                                            any such action pending?
                                        </label>
                                    </div>
                                    <div class="col-md-2">
                                        <input type="radio" formControlName="cmd_914" id="cmd_914" name="cmd_914"
                                        [class.is-invalid]="(caliPhy.controls['cmd_914'].touched && caliPhy.controls['cmd_914'].invalid) || (caliPhy.controls['cmd_914'].invalid && isSubmit)"
                                        value="1">
                                        <label>Yes&nbsp;</label>
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['cmd_914'].touched && caliPhy.controls['cmd_914'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['cmd_914'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                        <input type="radio" formControlName="cmd_914" id="cmd_914" name="cmd_914"
                                        [class.is-invalid]="(caliPhy.controls['cmd_914'].touched && caliPhy.controls['cmd_914'].invalid) || (caliPhy.controls['cmd_914'].invalid && isSubmit)"
                                            value="0">
                                        <label>No</label>
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['cmd_914'].touched && caliPhy.controls['cmd_914'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['cmd_914'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                        <span *ngIf="(caliPhy.controls['cmd_914'].touched && caliPhy.controls['cmd_914'].invalid) || (caliPhy.controls['cmd_914'].invalid && isSubmit)">
                                            <i class="fas fa-times ml-2 text-danger"></i>
                                        </span>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-9">
                                        <label
                                            style="font-weight: normal;font-size: 14px;padding-left:20px;text-align:block;">
                                            G. Have you been denied certification/recertification by a specialty board,
                                            or has your eligibility, certification or
                                            recertification status changed (other than changing from eligible to
                                            certified)?
                                        </label>
                                    </div>
                                    <div class="col-md-2">
                                        <input type="radio" formControlName="cmd_915" id="cmd_915" name="cmd_915"
                                        [class.is-invalid]="(caliPhy.controls['cmd_915'].touched && caliPhy.controls['cmd_915'].invalid) || (caliPhy.controls['cmd_915'].invalid && isSubmit)"
                                        value="1">
                                        <label>Yes&nbsp;</label>
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['cmd_915'].touched && caliPhy.controls['cmd_915'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['cmd_915'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                        <input type="radio" formControlName="cmd_915" id="cmd_915" name="cmd_915"
                                        [class.is-invalid]="(caliPhy.controls['cmd_915'].touched && caliPhy.controls['cmd_915'].invalid) || (caliPhy.controls['cmd_915'].invalid && isSubmit)"
                                            value="0">
                                        <label>No</label>
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['cmd_915'].touched && caliPhy.controls['cmd_915'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['cmd_915'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                        <span *ngIf="(caliPhy.controls['cmd_915'].touched && caliPhy.controls['cmd_915'].invalid) || (caliPhy.controls['cmd_915'].invalid && isSubmit)">
                                            <i class="fas fa-times ml-2 text-danger"></i>
                                        </span>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-9">
                                        <label
                                            style="font-weight: normal;font-size: 14px;padding-left:20px;text-align:block;">
                                            H. Have you ever been convicted of any crime (other than a minor traffic
                                            violation)?
                                        </label>
                                    </div>
                                    <div class="col-md-2">
                                        <input type="radio" formControlName="cmd_916" id="cmd_916" name="cmd_916"
                                        [class.is-invalid]="(caliPhy.controls['cmd_916'].touched && caliPhy.controls['cmd_916'].invalid) || (caliPhy.controls['cmd_916'].invalid && isSubmit)"
                                        value="1">
                                        <label>Yes&nbsp;</label>
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['cmd_916'].touched && caliPhy.controls['cmd_916'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['cmd_916'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                        <input type="radio" formControlName="cmd_916" id="cmd_916" name="cmd_916"
                                        [class.is-invalid]="(caliPhy.controls['cmd_916'].touched && caliPhy.controls['cmd_916'].invalid) || (caliPhy.controls['cmd_916'].invalid && isSubmit)"
                                            value="0">
                                        <label>No</label>
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['cmd_916'].touched && caliPhy.controls['cmd_916'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['cmd_916'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                        <span *ngIf="(caliPhy.controls['cmd_916'].touched && caliPhy.controls['cmd_916'].invalid) || (caliPhy.controls['cmd_916'].invalid && isSubmit)">
                                            <i class="fas fa-times ml-2 text-danger"></i>
                                        </span>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-9">
                                        <label
                                            style="font-weight: normal;font-size: 14px;padding-left:20px;text-align:block;">
                                            I. Do you presently use any drugs illegally?
                                        </label>
                                    </div>
                                    <div class="col-md-2">
                                        <input type="radio" formControlName="cmd_917" id="cmd_917" name="cmd_917"
                                        [class.is-invalid]="(caliPhy.controls['cmd_917'].touched && caliPhy.controls['cmd_917'].invalid) || (caliPhy.controls['cmd_917'].invalid && isSubmit)"
                                        value="1">
                                        <label>Yes&nbsp;</label>
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['cmd_917'].touched && caliPhy.controls['cmd_917'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['cmd_917'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                        <input type="radio" formControlName="cmd_917" id="cmd_917" name="cmd_917"
                                        [class.is-invalid]="(caliPhy.controls['cmd_917'].touched && caliPhy.controls['cmd_917'].invalid) || (caliPhy.controls['cmd_917'].invalid && isSubmit)"
                                            value="0">
                                        <label>No</label>
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['cmd_917'].touched && caliPhy.controls['cmd_917'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['cmd_917'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                        <span *ngIf="(caliPhy.controls['cmd_917'].touched && caliPhy.controls['cmd_917'].invalid) || (caliPhy.controls['cmd_917'].invalid && isSubmit)">
                                            <i class="fas fa-times ml-2 text-danger"></i>
                                        </span>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-9">
                                        <label
                                            style="font-weight: normal;font-size: 14px;padding-left:20px;text-align:block;">
                                            J. Do you have any ongoing physical or mental impairment or condition which
                                            would make you unable, with or
                                            without reasonable accommodation, to perform the essential functions of a
                                            practitioner in your area of practice or
                                            unable to perform those essential functions without a direct threat to the
                                            health and safety of others? Please describe
                                            any accommodations that could reasonably be made to facilitate your
                                            performance of such functions without risk of
                                            compromise
                                        </label>
                                    </div>
                                    <div class="col-md-2">
                                        <input type="radio" formControlName="cmd_918" id="cmd_918" name="cmd_918"
                                        [class.is-invalid]="(caliPhy.controls['cmd_918'].touched && caliPhy.controls['cmd_918'].invalid) || (caliPhy.controls['cmd_918'].invalid && isSubmit)"
                                        value="1">
                                        <label>Yes&nbsp;</label>
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['cmd_918'].touched && caliPhy.controls['cmd_918'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['cmd_918'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                        <input type="radio" formControlName="cmd_918" id="cmd_918" name="cmd_918"
                                        [class.is-invalid]="(caliPhy.controls['cmd_918'].touched && caliPhy.controls['cmd_918'].invalid) || (caliPhy.controls['cmd_918'].invalid && isSubmit)"
                                            value="0">
                                        <label>No</label>
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['cmd_918'].touched && caliPhy.controls['cmd_918'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['cmd_918'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                        <span *ngIf="(caliPhy.controls['cmd_918'].touched && caliPhy.controls['cmd_918'].invalid) || (caliPhy.controls['cmd_918'].invalid && isSubmit)">
                                            <i class="fas fa-times ml-2 text-danger"></i>
                                        </span>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-9">
                                        <label
                                            style="font-weight: normal;font-size: 14px;padding-left:20px;text-align:block;">
                                            K. Have any judgments been entered against you or settlements been agreed to
                                            by you within the last seven (7) years,
                                            in professional liability cases, or are there any filed and served
                                            professional liability lawsuits/arbitrations against
                                            you pending?
                                        </label>
                                    </div>
                                    <div class="col-md-2">
                                        <input type="radio" formControlName="cmd_919" id="cmd_919" name="cmd_919"
                                        [class.is-invalid]="(caliPhy.controls['cmd_919'].touched && caliPhy.controls['cmd_919'].invalid) || (caliPhy.controls['cmd_919'].invalid && isSubmit)"
                                        value="1">
                                        <label>Yes&nbsp;</label>
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['cmd_919'].touched && caliPhy.controls['cmd_919'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['cmd_919'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                        <input type="radio" formControlName="cmd_919" id="cmd_919" name="cmd_919"
                                        [class.is-invalid]="(caliPhy.controls['cmd_919'].touched && caliPhy.controls['cmd_919'].invalid) || (caliPhy.controls['cmd_919'].invalid && isSubmit)"
                                            value="0">
                                        <label>No</label>
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['cmd_919'].touched && caliPhy.controls['cmd_919'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['cmd_919'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                        <span *ngIf="(caliPhy.controls['cmd_919'].touched && caliPhy.controls['cmd_919'].invalid) || (caliPhy.controls['cmd_919'].invalid && isSubmit)">
                                            <i class="fas fa-times ml-2 text-danger"></i>
                                        </span>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-9">
                                        <label
                                            style="font-weight: normal;font-size: 14px;padding-left:20px;text-align:block;">
                                            L. Has your professional liability insurance ever been terminated, not
                                            renewed, restricted, or modified (e.g. reduced
                                            limits, restricted coverage, surcharged) or have you ever been denied
                                            professional liability insurance, or has any
                                            professional liability carrier provided you with written notice of any
                                            intent to deny, cancel, not renew, or limit your
                                            professional liability insurance or its coverage of any procedures?
                                        </label>
                                    </div>
                                    <div class="col-md-2">
                                        <input type="radio" formControlName="cmd_920" id="cmd_920" name="cmd_920"
                                        [class.is-invalid]="(caliPhy.controls['cmd_920'].touched && caliPhy.controls['cmd_920'].invalid) || (caliPhy.controls['cmd_920'].invalid && isSubmit)"
                                        value="1">
                                        <label>Yes&nbsp;</label>
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['cmd_920'].touched && caliPhy.controls['cmd_920'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['cmd_920'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                        <input type="radio" formControlName="cmd_920" id="cmd_920" name="cmd_920"
                                        [class.is-invalid]="(caliPhy.controls['cmd_920'].touched && caliPhy.controls['cmd_920'].invalid) || (caliPhy.controls['cmd_920'].invalid && isSubmit)"
                                            value="0">
                                        <label>No</label>
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['cmd_920'].touched && caliPhy.controls['cmd_920'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['cmd_920'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                        <span *ngIf="(caliPhy.controls['cmd_920'].touched && caliPhy.controls['cmd_920'].invalid) || (caliPhy.controls['cmd_920'].invalid && isSubmit)">
                                            <i class="fas fa-times ml-2 text-danger"></i>
                                        </span>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-9">
                                        <label
                                            style="font-weight: normal;font-size: 14px;padding-left:20px;text-align:block;">
                                            M. Is your professional liability insurance valid and current?
                                        </label>
                                    </div>
                                    <div class="col-md-2">
                                        <input type="radio" formControlName="cmd_921" id="cmd_921" name="cmd_921"
                                        [class.is-invalid]="(caliPhy.controls['cmd_921'].touched && caliPhy.controls['cmd_921'].invalid) || (caliPhy.controls['cmd_921'].invalid && isSubmit)"
                                        value="1">
                                        <label>Yes&nbsp;</label>
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['cmd_921'].touched && caliPhy.controls['cmd_921'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['cmd_921'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                        <input type="radio" formControlName="cmd_921" id="cmd_921" name="cmd_921"
                                        [class.is-invalid]="(caliPhy.controls['cmd_921'].touched && caliPhy.controls['cmd_921'].invalid) || (caliPhy.controls['cmd_921'].invalid && isSubmit)"
                                            value="0">
                                        <label>No</label>
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['cmd_921'].touched && caliPhy.controls['cmd_921'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['cmd_921'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                        <span *ngIf="(caliPhy.controls['cmd_921'].touched && caliPhy.controls['cmd_921'].invalid) || (caliPhy.controls['cmd_921'].invalid && isSubmit)">
                                            <i class="fas fa-times ml-2 text-danger"></i>
                                        </span>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-9">
                                        <label
                                            style="font-weight: normal;font-size: 14px;padding-left:20px;text-align:block;">
                                            N. Are you able to perform all the services required by your agreement with,
                                            or the professional staff bylaws of, the
                                            Healthcare Organization to which you are applying, with or without
                                            reasonable accommodation, according to
                                            accepted standards of professional performance and without posing a direct
                                            threat to the safety of patients?
                                        </label>
                                    </div>
                                    <div class="col-md-2">
                                        <input type="radio" formControlName="cmd_922" id="cmd_922" name="cmd_922"
                                        [class.is-invalid]="(caliPhy.controls['cmd_922'].touched && caliPhy.controls['cmd_922'].invalid) || (caliPhy.controls['cmd_922'].invalid && isSubmit)"
                                        value="1">
                                        <label>Yes&nbsp;</label>
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['cmd_922'].touched && caliPhy.controls['cmd_922'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['cmd_922'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                        <input type="radio" formControlName="cmd_922" id="cmd_922" name="cmd_922"
                                        [class.is-invalid]="(caliPhy.controls['cmd_922'].touched && caliPhy.controls['cmd_922'].invalid) || (caliPhy.controls['cmd_922'].invalid && isSubmit)"
                                            value="0">
                                        <label>No</label>
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['cmd_922'].touched && caliPhy.controls['cmd_922'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['cmd_922'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                        <span *ngIf="(caliPhy.controls['cmd_922'].touched && caliPhy.controls['cmd_922'].invalid) || (caliPhy.controls['cmd_922'].invalid && isSubmit)">
                                            <i class="fas fa-times ml-2 text-danger"></i>
                                        </span>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr class="tabhead">
                            <th class="tabTh">IX. PROFESSIONAL LIABILITY ACTION EXPLANATION</th>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-9">
                                        <label
                                            style="font-weight: normal;font-size: 14px;padding-left:20px;text-align:block;">
                                            A. Do you have a pending/settled claim to report in the past five years?
                                        </label>
                                    </div>
                                    <div class="col-md-2">
                                        <input type="radio" formControlName="cmd_923"
                                            [class.is-invalid]="(caliPhy.controls['cmd_923'].touched && caliPhy.controls['cmd_923'].invalid) || (caliPhy.controls['cmd_923'].invalid && isSubmit)"
                                            id="cmd_923" name="cmd_923" value="1">
                                        <label>Yes&nbsp;</label>
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['cmd_923'].touched && caliPhy.controls['cmd_923'].invalid">
                                            <small class="text-danger"
                                                *ngIf="caliPhy.controls['cmd_923'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                        <input type="radio" formControlName="cmd_923"
                                            [class.is-invalid]="(caliPhy.controls['cmd_923'].touched && caliPhy.controls['cmd_923'].invalid) || (caliPhy.controls['cmd_923'].invalid && isSubmit)"
                                            id="cmd_923" name="cmd_923" value="0">
                                        <label>No</label>
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['cmd_923'].touched && caliPhy.controls['cmd_923'].invalid">
                                            <small class="text-danger"
                                                *ngIf="caliPhy.controls['cmd_923'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                        <span
                                            *ngIf="(caliPhy.controls['cmd_923'].touched && caliPhy.controls['cmd_923'].invalid) || (caliPhy.controls['cmd_923'].invalid && isSubmit)">
                                            <i class="fas fa-times ml-2 text-danger"></i>
                                        </span>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr class="tabhead">
                            <th class="tabTh">X. ADDITIONAL QUESTIONS</th>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-9">
                                        <label
                                            style="font-weight: normal;font-size: 14px;padding-left:20px;text-align:block;">
                                            A. Do you have any lab certificates (such as CLIA/Waiver)? If so, please
                                            attach.
                                        </label>
                                    </div>
                                    <div class="col-md-2">
                                        <input type="radio" formControlName="cmd_924"
                                            [class.is-invalid]="(caliPhy.controls['cmd_924'].touched && caliPhy.controls['cmd_924'].invalid) || (caliPhy.controls['cmd_924'].invalid && isSubmit)"
                                            id="cmd_924" name="cmd_924" value="1">
                                        <label>Yes&nbsp;</label>
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['cmd_924'].touched && caliPhy.controls['cmd_924'].invalid">
                                            <small class="text-danger"
                                                *ngIf="caliPhy.controls['cmd_924'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                        <input type="radio" formControlName="cmd_924"
                                            [class.is-invalid]="(caliPhy.controls['cmd_924'].touched && caliPhy.controls['cmd_924'].invalid) || (caliPhy.controls['cmd_924'].invalid && isSubmit)"
                                            id="cmd_924" name="cmd_924" value="0">
                                        <label>No</label>
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['cmd_924'].touched && caliPhy.controls['cmd_924'].invalid">
                                            <small class="text-danger"
                                                *ngIf="caliPhy.controls['cmd_924'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                        <span
                                            *ngIf="(caliPhy.controls['cmd_924'].touched && caliPhy.controls['cmd_924'].invalid) || (caliPhy.controls['cmd_924'].invalid && isSubmit)">
                                            <i class="fas fa-times ml-2 text-danger"></i>
                                        </span>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-9">
                                        <label
                                            style="font-weight: normal;font-size: 14px;padding-left:20px;text-align:block;">
                                            B. Do you have board certifications? If so, please attach.
                                        </label>
                                    </div>
                                    <div class="col-md-2">
                                        <input type="radio" formControlName="cmd_925"
                                            [class.is-invalid]="(caliPhy.controls['cmd_925'].touched && caliPhy.controls['cmd_925'].invalid) || (caliPhy.controls['cmd_925'].invalid && isSubmit)"
                                            id="cmd_925" name="cmd_925" value="1">
                                        <label>Yes&nbsp;</label>
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['cmd_925'].touched && caliPhy.controls['cmd_925'].invalid">
                                            <small class="text-danger"
                                                *ngIf="caliPhy.controls['cmd_925'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                        <input type="radio" formControlName="cmd_925"
                                            [class.is-invalid]="(caliPhy.controls['cmd_925'].touched && caliPhy.controls['cmd_925'].invalid) || (caliPhy.controls['cmd_925'].invalid && isSubmit)"
                                            id="cmd_925" name="cmd_925" value="0">
                                        <label>No</label>
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['cmd_925'].touched && caliPhy.controls['cmd_925'].invalid">
                                            <small class="text-danger"
                                                *ngIf="caliPhy.controls['cmd_925'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                        <span
                                            *ngIf="(caliPhy.controls['cmd_925'].touched && caliPhy.controls['cmd_925'].invalid) || (caliPhy.controls['cmd_925'].invalid && isSubmit)">
                                            <i class="fas fa-times ml-2 text-danger"></i>
                                        </span>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-9">
                                        <label
                                            style="font-weight: normal;font-size: 14px;padding-left:20px;text-align:block;">
                                            C. Do you have any other certifications? If so, please attach
                                        </label>
                                    </div>
                                    <div class="col-md-2">
                                        <input type="radio" formControlName="cmd_926"
                                            [class.is-invalid]="(caliPhy.controls['cmd_926'].touched && caliPhy.controls['cmd_926'].invalid) || (caliPhy.controls['cmd_926'].invalid && isSubmit)"
                                            id="cmd_926" name="cmd_926" value="1">
                                        <label>Yes&nbsp;</label>
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['cmd_926'].touched && caliPhy.controls['cmd_926'].invalid">
                                            <small class="text-danger"
                                                *ngIf="caliPhy.controls['cmd_926'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                        <input type="radio" formControlName="cmd_926"
                                            [class.is-invalid]="(caliPhy.controls['cmd_926'].touched && caliPhy.controls['cmd_926'].invalid) || (caliPhy.controls['cmd_926'].invalid && isSubmit)"
                                            id="cmd_926" name="cmd_926" value="0">
                                        <label>No</label>
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['cmd_926'].touched && caliPhy.controls['cmd_926'].invalid">
                                            <small class="text-danger"
                                                *ngIf="caliPhy.controls['cmd_926'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                        <span
                                            *ngIf="(caliPhy.controls['cmd_926'].touched && caliPhy.controls['cmd_926'].invalid) || (caliPhy.controls['cmd_926'].invalid && isSubmit)">
                                            <i class="fas fa-times ml-2 text-danger"></i>
                                        </span>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-9">
                                        <label
                                            style="font-weight: normal;font-size: 14px;padding-left:20px;text-align:block;">
                                            D. Do you have any other state licenses? If so, please attach
                                        </label>
                                    </div>
                                    <div class="col-md-2">
                                        <input type="radio" formControlName="cmd_927"
                                            [class.is-invalid]="(caliPhy.controls['cmd_927'].touched && caliPhy.controls['cmd_927'].invalid) || (caliPhy.controls['cmd_927'].invalid && isSubmit)"
                                            id="cmd_927" name="cmd_927" value="1">
                                        <label>Yes&nbsp;</label>
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['cmd_927'].touched && caliPhy.controls['cmd_927'].invalid">
                                            <small class="text-danger"
                                                *ngIf="caliPhy.controls['cmd_927'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                        <input type="radio" formControlName="cmd_927"
                                            [class.is-invalid]="(caliPhy.controls['cmd_927'].touched && caliPhy.controls['cmd_927'].invalid) || (caliPhy.controls['cmd_927'].invalid && isSubmit)"
                                            id="cmd_927" name="cmd_927" value="0">
                                        <label>No</label>
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['cmd_927'].touched && caliPhy.controls['cmd_927'].invalid">
                                            <small class="text-danger"
                                                *ngIf="caliPhy.controls['cmd_927'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                        <span
                                            *ngIf="(caliPhy.controls['cmd_927'].touched && caliPhy.controls['cmd_927'].invalid) || (caliPhy.controls['cmd_927'].invalid && isSubmit)">
                                            <i class="fas fa-times ml-2 text-danger"></i>
                                        </span>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-9">
                                        <label
                                            style="font-weight: normal;font-size: 14px;padding-left:20px;text-align:block;">
                                            E. Do you receive any stipends or bonuses from hospitals?
                                        </label>
                                    </div>
                                    <div class="col-md-2">
                                        <input type="radio" formControlName="cmd_928" 
                                        (ngModelChange)="handleYesNoChanges('cmd_928')"

                                            [class.is-invalid]="(caliPhy.controls['cmd_928'].touched && caliPhy.controls['cmd_928'].invalid) || (caliPhy.controls['cmd_928'].invalid && isSubmit)"
                                            id="cmd_928" name="cmd_928" value="1"   >
                                        <label>Yes&nbsp;</label>
                                        
                                        <input type="radio" formControlName="cmd_928"
                                        (ngModelChange)="handleYesNoChanges('cmd_928')"

                                            [class.is-invalid]="(caliPhy.controls['cmd_928'].touched && caliPhy.controls['cmd_928'].invalid) || (caliPhy.controls['cmd_928'].invalid && isSubmit)"
                                            id="cmd_928" name="cmd_928" value="0" >
                                        <label>No</label>
                                        <div class="formLabel col-md-11"
                                        *ngIf="caliPhy.controls['cmd_928'].touched && caliPhy.controls['cmd_928'].invalid">
                                        <small class="text-danger" *ngIf="caliPhy.controls['cmd_928'].errors?.required">
                                            required
                                        </small>
                                    </div>
                                        <span *ngIf="(caliPhy.controls['cmd_928'].touched && caliPhy.controls['cmd_928'].invalid) || (caliPhy.controls['cmd_928'].invalid && isSubmit)">
                                        <i class="fas fa-times ml-2 text-danger"></i>
                                        </span>
                                     </div>
                                    <div class="col-md-5">
                                        <label
                                            style="font-weight: normal;font-size: 14px;padding-left:20px;text-align:block;">
                                            If yes, which hospitals?
                                        </label>
                                        <input class="form-control onboard" formControlName="cmd_35"
                                        [class.is-invalid]="(caliPhy.controls['cmd_35'].invalid) || (caliPhy.controls['cmd_35'].invalid && isSubmit)"
                                        id="cmd_35" name="cmd_35" type="text" data-id="managing_contact_name" (blur)="handleYesNoChanges('cmd_928')" >
                                        <span *ngIf="(  caliPhy.controls['cmd_35'].invalid) || (caliPhy.controls['cmd_35'].invalid && isSubmit)">
                                            <i class="fas fa-times ml-2 text-danger">required</i>
                                        </span>
                                    </div>
                                    <!-- </div> -->
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-9">
                                        <label
                                            style="font-weight: normal;font-size: 14px;padding-left:20px;text-align:block;">
                                            F. Do you do ED/Call Coverage at John Muir?
                                        </label>
                                    </div>
                                    <div class="col-md-2">
                                        <input type="radio" formControlName="cmd_929"
                                            [class.is-invalid]="(caliPhy.controls['cmd_929'].touched && caliPhy.controls['cmd_929'].invalid) || (caliPhy.controls['cmd_929'].invalid && isSubmit)"
                                            id="cmd_929" name="cmd_929" value="1">
                                        <label>Yes&nbsp;</label>
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['cmd_929'].touched && caliPhy.controls['cmd_929'].invalid">
                                            <small class="text-danger"
                                                *ngIf="caliPhy.controls['cmd_929'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                        <input type="radio" formControlName="cmd_929"
                                            [class.is-invalid]="(caliPhy.controls['cmd_929'].touched && caliPhy.controls['cmd_929'].invalid) || (caliPhy.controls['cmd_929'].invalid && isSubmit)"
                                            id="cmd_929" name="cmd_929" value="0">
                                        <label>No</label>
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['cmd_929'].touched && caliPhy.controls['cmd_929'].invalid">
                                            <small class="text-danger"
                                                *ngIf="caliPhy.controls['cmd_929'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                        <span
                                            *ngIf="(caliPhy.controls['cmd_929'].touched && caliPhy.controls['cmd_929'].invalid) || (caliPhy.controls['cmd_929'].invalid && isSubmit)">
                                            <i class="fas fa-times ml-2 text-danger"></i>
                                        </span>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-9">
                                        <label
                                            style="font-weight: normal;font-size: 14px;padding-left:20px;text-align:block;">
                                            G. Would you like to be credentialed to have privileges at the BASS Surgery
                                            Center in Walnut Creek?
                                        </label>
                                    </div>
                                    <div class="col-md-2">
                                        <input type="radio" formControlName="cmd_930"
                                            [class.is-invalid]="(caliPhy.controls['cmd_930'].touched && caliPhy.controls['cmd_930'].invalid) || (caliPhy.controls['cmd_930'].invalid && isSubmit)"
                                            id="cmd_930" name="cmd_930" value="1">
                                        <label>Yes&nbsp;</label>
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['cmd_930'].touched && caliPhy.controls['cmd_930'].invalid">
                                            <small class="text-danger"
                                                *ngIf="caliPhy.controls['cmd_930'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                        <input type="radio" formControlName="cmd_930"
                                            [class.is-invalid]="(caliPhy.controls['cmd_930'].touched && caliPhy.controls['cmd_930'].invalid) || (caliPhy.controls['cmd_930'].invalid && isSubmit)"
                                            id="cmd_930" name="cmd_930" value="0">
                                        <label>No</label>
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['cmd_930'].touched && caliPhy.controls['cmd_930'].invalid">
                                            <small class="text-danger"
                                                *ngIf="caliPhy.controls['cmd_930'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                        <span
                                            *ngIf="(caliPhy.controls['cmd_930'].touched && caliPhy.controls['cmd_930'].invalid) || (caliPhy.controls['cmd_930'].invalid && isSubmit)">
                                            <i class="fas fa-times ml-2 text-danger"></i>
                                        </span>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-9">
                                        <label
                                            style="font-weight: normal;font-size: 14px;padding-left:20px;text-align:block;">
                                            H. Does your office dispense DME (Durable Medical Equipment) to Medicare
                                            recipients?
                                        </label>
                                    </div>
                                    <div class="col-md-2">
                                        <input type="radio" formControlName="cmd_931"
                                            [class.is-invalid]="(caliPhy.controls['cmd_931'].touched && caliPhy.controls['cmd_931'].invalid) || (caliPhy.controls['cmd_931'].invalid && isSubmit)"
                                            id="cmd_931" name="cmd_931" value="1">
                                        <label>Yes&nbsp;</label>
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['cmd_931'].touched && caliPhy.controls['cmd_931'].invalid">
                                            <small class="text-danger"
                                                *ngIf="caliPhy.controls['cmd_931'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                        <input type="radio" formControlName="cmd_931"
                                            [class.is-invalid]="(caliPhy.controls['cmd_931'].touched && caliPhy.controls['cmd_931'].invalid) || (caliPhy.controls['cmd_931'].invalid && isSubmit)"
                                            id="cmd_931" name="cmd_931" value="0">
                                        <label>No</label>
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['cmd_931'].touched && caliPhy.controls['cmd_931'].invalid">
                                            <small class="text-danger"
                                                *ngIf="caliPhy.controls['cmd_931'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                        <span
                                            *ngIf="(caliPhy.controls['cmd_931'].touched && caliPhy.controls['cmd_931'].invalid) || (caliPhy.controls['cmd_931'].invalid && isSubmit)">
                                            <i class="fas fa-times ml-2 text-danger"></i>
                                        </span>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-9">
                                        <label
                                            style="font-weight: normal;font-size: 14px;padding-left:20px;text-align:block;">
                                            I. Are you a certified Worker’s Compensation provider?
                                        </label>
                                    </div>
                                    <div class="col-md-2">
                                        <input type="radio" formControlName="cmd_932"
                                            [class.is-invalid]="(caliPhy.controls['cmd_932'].touched && caliPhy.controls['cmd_932'].invalid) || (caliPhy.controls['cmd_932'].invalid && isSubmit)"
                                            id="cmd_932" name="cmd_932" value="1">
                                        <label>Yes&nbsp;</label>
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['cmd_932'].touched && caliPhy.controls['cmd_932'].invalid">
                                            <small class="text-danger"
                                                *ngIf="caliPhy.controls['cmd_932'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                        <input type="radio" formControlName="cmd_932"
                                            [class.is-invalid]="(caliPhy.controls['cmd_932'].touched && caliPhy.controls['cmd_932'].invalid) || (caliPhy.controls['cmd_932'].invalid && isSubmit)"
                                            id="cmd_932" name="cmd_932" value="0">
                                        <label>No</label>
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['cmd_932'].touched && caliPhy.controls['cmd_932'].invalid">
                                            <small class="text-danger"
                                                *ngIf="caliPhy.controls['cmd_932'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                        <span
                                            *ngIf="(caliPhy.controls['cmd_932'].touched && caliPhy.controls['cmd_932'].invalid) || (caliPhy.controls['cmd_932'].invalid && isSubmit)">
                                            <i class="fas fa-times ml-2 text-danger"></i>
                                        </span>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-9">
                                        <label
                                            style="font-weight: normal;font-size: 14px;padding-left:20px;text-align:block;">
                                            J. Are you a reservist?
                                        </label>
                                    </div>
                                    <div class="col-md-2">
                                        <input type="radio" formControlName="cmd_933"
                                        (ngModelChange)="handleYesNoChanges('cmd_933')"

                                            [class.is-invalid]="(caliPhy.controls['cmd_933'].touched && caliPhy.controls['cmd_933'].invalid) || (caliPhy.controls['cmd_933'].invalid && isSubmit)"
                                            id="cmd_933" name="cmd_933" value="1"   >
                                        <label>Yes&nbsp;</label>
                                        
                                        <input type="radio" formControlName="cmd_933"
                                        (ngModelChange)="handleYesNoChanges('cmd_933')"

                                            [class.is-invalid]="(caliPhy.controls['cmd_933'].touched && caliPhy.controls['cmd_933'].invalid) || (caliPhy.controls['cmd_933'].invalid && isSubmit)"
                                            id="cmd_933" name="cmd_933" value="0" >
                                        <label>No</label>
                                        <div class="formLabel col-md-11"
                                        *ngIf="caliPhy.controls['cmd_933'].touched && caliPhy.controls['cmd_933'].invalid">
                                        <small class="text-danger" *ngIf="caliPhy.controls['cmd_933'].errors?.required">
                                            required
                                        </small>
                                    </div>
                                        <span *ngIf="(caliPhy.controls['cmd_933'].touched && caliPhy.controls['cmd_933'].invalid) || (caliPhy.controls['cmd_933'].invalid && isSubmit)">
                                        <i class="fas fa-times ml-2 text-danger"></i>
                                        </span>
                                     </div>
                                    <div class="col-md-5">
                                        <label
                                            style="font-weight: normal;font-size: 14px;padding-left:20px;text-align:block;">
                                            If yes, which branch?
                                        </label>
                                        <input class="form-control onboard" formControlName="1141"
                                        [class.is-invalid]="(caliPhy.controls['1141'].invalid) || (caliPhy.controls['1141'].invalid && isSubmit)"
                                        id="1141" name="1141" type="text" data-id="managing_contact_name" (blur)="handleYesNoChanges('cmd_933')" >
                                        <span *ngIf="(  caliPhy.controls['1141'].invalid) || (caliPhy.controls['1141'].invalid && isSubmit)">
                                            <i class="fas fa-times ml-2 text-danger">required</i>
                                        </span>
                                    </div>
                                    <div class="col-md-6">
                                        <label style="font-weight: normal;font-size: 14px;padding-left:20px">Anticipated
                                            date of separation from reserve duty?</label>
                                        <div class="input-group">
                                            <input class="form-control text" ngbDatepicker #d103="ngbDatepicker"
                                                formControlName="1145"
                                                [class.is-invalid]="(caliPhy.controls['1145'].touched && caliPhy.controls['1145'].invalid) || (caliPhy.controls['1145'].invalid && isSubmit)"
                                                id="1145" name="1145" type="text" (blur)="handleYesNoChanges('cmd_933')" (blur)="clearInputIfInvalid('1145','cmd_933')"
                                                placeholder="Active Duty Discharge Date"
                                                data-id="managing_contact_name">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary calendar"
                                                    (click)="d103.toggle()" type="button"><i
                                                        class="fas fa-calendar-alt"></i></button>
                                            </div>
                                        </div>
                                        <div class="mandatory" *ngIf="(caliPhy.controls['1145'].invalid&&caliPhy.controls['1145'].touched) && (caliPhy.controls['1145'].value==''|| caliPhy.controls['1145'].value==null) " >
                                            <small >
                                            required
                                            </small>
                                          
                                          </div>
                                          <small class="text-danger" *ngIf="caliPhy.get('1145').errors?.invalidDateFormat">
                                            Only Date Format is allowed(MM/DD/YYYY)                </small>
                                          <small class="text-danger" *ngIf="caliPhy.get('1145').errors?.invalidMonth">
                                          Month is out of range
                                          </small>
                                          <small class="text-danger" *ngIf="caliPhy.get('1145').errors?.invalidDateyear">
                                            year is out of range(1900-2099)                  </small>
                                            <small class="text-danger" *ngIf="caliPhy.get('1145').errors?.invalidDateRange">
                                              date is out of range
                                              </small>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label style="font-weight: normal;font-size: 14px;padding-left:20px;text-align:block;">
                                    <b>Current copies of the following documents must be sent to
                                        <a>operations@bassmedicalgroup.com</a></b>
                                </label>
                                <div class="row">
                                    <div class="col-md-6">
                                        <label
                                            style="font-weight: normal;font-size: 14px;padding-left:20px;text-align:block;">-
                                            State Medical License(s)</label>
                                    </div>
                                    <div class="col-md-6">
                                        <label
                                            style="font-weight: normal;font-size: 14px;padding-left:20px;text-align:block;">-
                                            Driver’s License</label>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <label
                                            style="font-weight: normal;font-size: 14px;padding-left:20px;text-align:block;">-
                                            Face Sheet of Professional Liability Certification</label>
                                    </div>
                                    <div class="col-md-6">
                                        <label
                                            style="font-weight: normal;font-size: 14px;padding-left:20px;text-align:block;">-
                                            Board Certification (if applicable)</label>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <label
                                            style="font-weight: normal;font-size: 14px;padding-left:20px;text-align:block;">-
                                            DEA Certificate</label>
                                    </div>
                                    <div class="col-md-6">
                                        <label
                                            style="font-weight: normal;font-size: 14px;padding-left:20px;text-align:block;">-
                                            Life Support Documents</label>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <label
                                            style="font-weight: normal;font-size: 14px;padding-left:20px;text-align:block;">-
                                            Curriculum Vitae in Month/Year (MM/YYYY) format for all Education & Work
                                            History</label>
                                    </div>
                                    <div class="col-md-6">
                                        <label
                                            style="font-weight: normal;font-size: 14px;padding-left:20px;text-align:block;">-
                                            ECFMG Certificate (if applicable)</label>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <label
                                            style="font-weight: normal;font-size: 14px;padding-left:20px;text-align:block;">-
                                            Lab/CLIA Certificates if applicable
                                        </label>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-12">
                                        <p>I hereby affirm that the information submitted in this Section VII, Attestation Questions,
                                            and any addenda thereto is true, current, correct, and
                                            complete to the best of my knowledge and belief an is furnished in good faith. I understand
                                            that material, omissions or misrepresentations may result
                                            in denial of my application or termination of my privileges, employment or physician
                                            participation agreement.</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <label style="font-weight: normal;font-size: 14px">Provider Name</label>
                                        <input class="form-control onboard" formControlName="cmd_934" id="cmd_934" name="cmd_934"
                                            type="text" placeholder="Provider Name" data-id="managing_contact_name">
                                    </div>
                                    <div class="col-md-6">
                                        <label style="font-weight: normal;font-size: 14px">Date</label>
                                        <div class="input-group">
                                            <input class="form-control onboard datepicker" formControlName="cmd_935" ngbDatepicker
                                                #d41="ngbDatepicker"
                                                [class.is-invalid]="(caliPhy.controls['cmd_935'].touched && caliPhy.controls['cmd_935'].invalid) || (caliPhy.controls['cmd_935'].invalid && isSubmit)"
                                                id="cmd_935" name="cmd_935" type="text" placeholder="Date" (blur)="clearInputIfInvalidMandi('cmd_935')"
                                                data-id="managing_contact_name">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary calendar" (click)="d41.toggle()"
                                                    type="button"><i class="fas fa-calendar-alt"></i></button>
                                            </div>
                                        </div>
                                        <div class="mandatory" *ngIf="(caliPhy.controls['cmd_935'].invalid&&caliPhy.controls['cmd_935'].touched) && (caliPhy.controls['cmd_935'].value==''|| caliPhy.controls['cmd_935'].value==null) " >
                                            <small >
                                            required
                                            </small>
                                          
                                          </div>
                                          <small class="text-danger" *ngIf="caliPhy.get('cmd_935').errors?.invalidDateFormat">
                                            Only Date Format is allowed(MM/DD/YYYY)                </small>
                                          <small class="text-danger" *ngIf="caliPhy.get('cmd_935').errors?.invalidMonth">
                                          Month is out of range
                                          </small>
                                          <small class="text-danger" *ngIf="caliPhy.get('cmd_935').errors?.invalidDateyear">
                                            year is out of range(1900-2099)                  </small>
                                            <small class="text-danger" *ngIf="caliPhy.get('cmd_935').errors?.invalidDateRange">
                                              date is out of range
                                              </small>
                                    </div>
                                </div>
                                <div class="row" style="padding-left:20px">
                                    <div class="col-md-3">
                                        <label style="font-weight: normal;font-size: 15px">Signature:</label>
                                    </div>
                                    <div class="col-md-4">
                                        <img *ngIf="!showOne" class="img" src="./assets/images/e_sign.png" alt="Credential my Doc"
                                            (click)="esignOpenOne()">
                                        <img *ngIf="showOne" class="img eSignImageShow" [src]="esignOne" alt="Credential my Doc"
                                            (click)="esignOpenOne()">
                                        <input type="hidden" name="cmd_943" formControlName="cmd_943"
                                            [class.is-invalid]="(caliPhy.controls['cmd_943'].touched && caliPhy.controls['cmd_943'].invalid) || (caliPhy.controls['cmd_943'].invalid && isSubmit)"
                                            id="cmd_943">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliPhy.controls['cmd_943'].touched && caliPhy.controls['cmd_943'].invalid">
                                            <small class="text-danger" *ngIf="caliPhy.controls['cmd_943'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                        <span
                                            *ngIf="(caliPhy.controls['cmd_943'].touched && caliPhy.controls['cmd_943'].invalid) || (caliPhy.controls['cmd_943'].invalid && isSubmit)">
                                            <i class="fas fa-times ml-2 text-danger"></i>
                                        </span>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr style="border:2px solid black;text-align:center">
                            <th>INFORMATION RELEASE/ACKNOWLEDGMENTS </th>
                        </tr>
                        <tr>
                            <td>
                        
                                <label for="">
                                    I hereby consent to the disclosure, inspection and copying of information and documents relating to my
                                    credentials, qualifications and I hereby consent to
                                    the disclosure, inspection and copying of information and documents relating to my credentials,
                                    qualifications and performance ("credentialing
                                    information") by and between "this Healthcare Organization" and other Healthcare Organizations (e.g.,
                                    hospital medical staffs, medical groups,
                                    independent practice associations&#123;IPAs&#125;, -health maintenance organizations &#123;HMOs&#125;,
                                    preferred
                                    provider
                                    organizations &#123;PPOs&#125;, other health delivery
                                    systems or entities, medical societies, professional associations, medical school faculty positions,
                                    training programs, professional liability insurance
                                    companies &#123;with respect to certification of coverage and claim history&#125;, licensing authorities,
                                    and
                                    businesses and individuals acting as their agents
                                    collectively "Healthcare Organizations,") for the purpose of evaluating this application and any
                                    recredentialing application regarding my professional
                                    training, experience, character, conduct and judgment, ethics and ability to work with others. In this
                                    regard, the utmost care shall be taken to safeguard the
                                    privacy of patients and the confidentiality of patient records, and to protect credentialing information
                                    from being further disclosed.
                                </label>
                        
                        
                        
                                <label for="">
                                    I am informed and acknowledge that federal and state laws provide immunity protections to certain
                                    individuals and entities for their acts and/or
                                    communications in connection with evaluating the qualifications of healthcare providers. I hereby release
                                    all persons and entities, including this Healthcare
                                    Organization, engaged in quality assessment, peer review and credentialing on behalf of this Healthcare
                                    Organization, and all persons and entities providing
                                    credentialing information to such representatives of this Healthcare Organization, from any liability they
                                    might incur for their acts and/or communications
                                    in connection with evaluation of my qualifications for participation in this Healthcare Organization, to the
                                    extent that those acts and/or communications are
                                    protected by state or federal law.
                                </label>
                                <label for="">
                                    I understand that I shall be afforded such fair procedures with respect to my participation in this
                                    Healthcare Organization as may be required by state and
                                    federal law and regulation, including but not limited to, California Business and Professional Code Section
                                    809 <u>et seq</u>, if applicable.
                                </label>
                                <label>
                                    I the undersigned and agree that I, as an applicant, have the burden of producing adequate information for
                                    proper evaluation of my professional
                                    competence, character, ethics and other qualifications and for resolving any doubt about such
                                    qualifications.
                                </label>
                                <label for="">
                                    During such time as this application is being processed, I agree to update the application should there be any change in the information provided
                                </label>
                                <br />
                                <label for="">
                                    In addition to any notice required by any contract with a Healthcare Organization, I agree to notify this Healthcare
                                    Organization immediately in writing of
                                    the occurrence of any of the following: (i) the unstayed suspension, revocation or nonrenewal of my license to
                                    practice medicine in California; (ii) any
                                    suspension, revocation or nonrenewal of my DEA or other controlled substances registration; or (iii) any
                                    cancellation or nonrenewal of my professional
                                    liability insurance coverage.
                                </label>
                                <label for="">
                                    I further agree to notify this Healthcare Organization in writing, promptly and no later than fourteen (14) calendar
                                    days from the occurrence of any of the
                                    following: (i) receipt of written notice of any adverse action against me by the Medical Board of California taken
                                    or pending, including but not limited to,
                                    any accusation filed, temporary restraining order, or imposition of any interim suspension, probation or limitations
                                    affecting my license to practice
                                    medicine; or (ii) any adverse action against me by any Healthcare Organization which has resulted in the filing of a
                                    Section 805 report with the Medical
                                    Board of California, or a report with the National Practitioner Data Bank; or (iii) the denial, revocation,
                                    suspension reduction, limitation, nonrenewal or
                                    voluntary relinquishment by registration of my medical staff membership or clinical privileges at any Healthcare
                                    Organization; or (iv) any material
                                    reduction in my professional liability insurance coverage; or (v) my receipt of written notice of any legal action
                                    against me, including, without limitation,
                                    ant filed and served malpractice suite or arbitration action; or (vi) my conviction of any criminal law (excluding
                                    minor traffic violations); or (vii) my receipt
                                    of written notice of any adverse action against me under the Medicare or Medicaid programs, including, but not
                                    limited to, fraud and abuse proceedings or
                                    convictions.
                                </label>
                                <label for="">
                                    I hereby affirm that the information submitted in this application and any addenda thereto (including my curriculum
                                    vitae if attached) is true, current,
                                    correct, and complete to the best of my knowledge and belief and is furnished in good faith. I understand that
                                    material omissions or misrepresentations
                                    may result in denial of my application or termination of my privileges, employment or physician participation
                                    agreement. A photocopy of this document
                                    shall be as effective as the original, however, original signatures and current dates are required on pages 7 and 8.
                                </label>
                            </td>
                        </tr>
                        <tr>
                            
                            <td colspan="12">
                        
                                <div class="row">
                        
                                    <div class="col-2 pr-0"> <label ><b>Print Name here:</b></label></div>
                        
                                    <div class="col-4 pl-0">
                        
                                        <input type="text" placeholder=" Printed Name" id="cmd_43554" [class.is-invalid]="(caliPhy.controls['cmd_43554'].touched &&
                                                        
                                                        caliPhy.controls['cmd_43554'].invalid) ||
                                                        
                                                        (caliPhy.controls['cmd_43554'].invalid && isSubmit)" formControlName="cmd_43554"
                                            class="form-control">
                        
                                        <div class="invalid-feedback"
                                            *ngIf="(caliPhy.controls['cmd_43554'].touched && caliPhy.controls['cmd_43554'].invalid) || (caliPhy.controls['cmd_43554'].invalid && isSubmit)">
                                            Required </div>
                        
                                    </div>
                                </div>
                                    <br>
                                    <br>
                                    <br>
                                    <br>
                                <div class="row" >
                        
                                    <div class="col-1 pr-0"> <label > <b>Signature:</b></label></div>
                        
                                    <div class="col-5 pl-0">
                        
                                        <img *ngIf="!showTwo" class="img" src="./assets/images/e_sign.png" alt="Credential my Doc"
                                            (click)="esignOpenTwo()" />
                        
                                        <img *ngIf="showTwo" class="img eSignImageShow" [src]="esignTwo" alt="Credential my Doc"
                                            (click)="esignOpenTwo()" /><br><span> <b>(Stamped Signature Is Not Acceptable)</b>
                        
                                        </span>
                        
                                        <input type="hidden" placeholder=" Provider Signature" name="cmd_355" value="{{finalImgPathTwo}}"
                                            id="cmd_355" class="form-control" formControlName="cmd_355"
                                            [class.is-invalid]="(caliPhy.controls['cmd_355'].touched && caliPhy.controls['cmd_355'].invalid ) || (caliPhy.controls['cmd_355'].invalid && isSubmit)">
                                            <span
                                            *ngIf="(caliPhy.controls['cmd_355'].touched && caliPhy.controls['cmd_355'].invalid) || (caliPhy.controls['cmd_355'].invalid && isSubmit)">
                                            <i class="fas fa-times ml-2 text-danger"></i>
                                        </span>
                                        <div class="invalid-feedback"
                                            *ngIf="(caliPhy.controls['cmd_355'].touched && caliPhy.controls['cmd_355'].invalid) || (caliPhy.controls['cmd_355'].invalid && isSubmit)">
                        
                                            Provider Signature is required and cannot be empty
                        
                                        </div>
                        
                                    </div>
                        
                        
                                    <div class="col-1 pr-0"> <label ><b>Date: </b></label></div>
                        
                                    <div class="col-5 pl-0 ">
                                        <div class="input-group">
                                        
                                        <input type="text" placeholder=" date picker" id="cmd_10354" (blur)="clearInputIfInvalidMandi('cmd_10354')" [class.is-invalid]="(caliPhy.controls['cmd_10354'].touched &&
                                                        
                                                        caliPhy.controls['cmd_10354'].invalid) ||
                                                        
                                                        (caliPhy.controls['cmd_10354'].invalid && isSubmit)" formControlName="cmd_10354"
                                            class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker
                                            #idh101="ngbDatepicker">
                        
                                        <div class="input-group-append">
                        
                                            <button class="btn btn-outline-secondary calendar" (click)="idh101.toggle()" type="button">
                        
                                                <i class="fas fa-calendar-alt"></i>
                        
                                            </button>
                        
                                        </div>
                                        </div>
                                        
                                        <div class="text-danger" *ngIf="(caliPhy.controls['cmd_10354'].invalid&&caliPhy.controls['cmd_10354'].touched) && (caliPhy.controls['cmd_10354'].value==''|| caliPhy.controls['cmd_10354'].value==null) " >
                                            <small >
                                            required
                                            </small>
                                          
                                          </div>
                                          <small class="text-danger" *ngIf="caliPhy.get('cmd_10354').errors?.invalidDateFormat">
                                            Only Date Format is allowed(MM/DD/YYYY)                </small>
                                          <small class="text-danger" *ngIf="caliPhy.get('cmd_10354').errors?.invalidMonth">
                                          Month is out of range
                                          </small>
                                          <small class="text-danger" *ngIf="caliPhy.get('cmd_10354').errors?.invalidDateyear">
                                            year is out of range(1900-2099)                  </small>
                                            <small class="text-danger" *ngIf="caliPhy.get('cmd_10354').errors?.invalidDateRange">
                                              date is out of range
                                              </small>
                        
                                    </div>
                        
                                  
                        
                                    
                        
                                </div>
                                <br>
                                <label for="" style="font-size: 10px;">
                                    The intent of this release is to apply at a minimum, protection comparable to those available in California to any action, regardless of where such action is brought. 
                                </label>
                                <br>
                                <br>
                                <div class="row">
                                    <div class="col-6">
                                        <label for=""><b> Addenda Submitting (Please check the following):</b></label>
                                        <div class="row">
                                            <div class="col-1"><input type="checkbox" id="cmd_35512" name="cmd_35512" formControlName="cmd_35512"
                                                value="1" /></div>
                                            <div class="col-11">
                                                <label for=""> <b >  Addendum A - Health Plan and IPA/Medical Group</b></label>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-1"><input type="checkbox" id="cmd_35513" name="cmd_35513" formControlName="cmd_35513"
                                                value="1" /></div>
                                            <div class="col-11">
                                                <label for=""><b> Addendum B - Professional Liability Action Explanation </b></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <label for="">This Application and Addenda A and B were created and are endorsed by:</label>
                                        <div class="row">
                                            <ul>
                                                <li>American Medical Group Association - (310/430-1191x223)</li>
                                                <li>California Association of Health Plans - (916/552-2910)</li>
                                                <li>California Healthcare Association - (916/552-7574)</li>
                                                <li>California Medical Association - (415/882-3368)</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <br>
                                <br>
                                <label for="">
                                    Individual healthcare organizations may request additional information or attach supplements to this form. They are
                                    not part of the
                                    California Participation Physician Application nor have they been endorsed by the above organizations. Any questions
                                    about
                                    supplements should be addressed to the healthcare organization from which it was provided.
                                </label>
                        
                            </td>
                        
                        </tr>
                        <tr style="border:2px solid black;">
                            <th style="text-align:center">HOSPITAL SERVICES CORPORATION  
                            <br>
                            <label for="">CREDENTIALS VERIFICATION SERVICE  </label>
                            <br>
                            <label for="" style="text-align: left;">DESIGNATION AND AUTHORIZATION FOR RELEASE AND REDISCLOSURE OF INFORMATION </label>
                            <br>
                            <label for="">(“Release”)  </label>
                        </th>
                        
                        </tr>
                        <tr>
                            <td>
                                <label for=""><b>Authority to Release: </b></label> I have applied to participate as a provider for <input
                                    type="text" id="cmd_35514" name="cmd_35514"  formControlName="cmd_35514" class="form-control">
                                <br>
                                <label for="" style="display: block; text-align: center;">

                                   <b> Print the names of all organizations to which you are applying.</b>
                                  </label>
                                <label for="">and its authorized representatives (hereafter “Health Care Entity”) which has designated Hospital
                                    Services
                                    Corporation’s Credentials Verification Service (“HSC”) as their agent. I consent to complete disclosure by the
                                    recipient of this release to HSC of all relevant information pertaining to my professional qualifications, moral
                                    character, physical and mental health (hereinafter “qualifications”). I authorize the recipient to make
                                    available
                                    and/or disclose to HSC all such information in its files from any university, professional school, licensing
                                    authority, accreditation board, hospital, physician, dentist, professional society, insurance carrier, law
                                    enforcement agency, military service, or any other person or entity deemed necessary or appropriate in the
                                    investigation and processing of my application. </label>
                                <label for="">
                                    I request and authorize the recipient to release the requested information and I expressly waive any claim of
                                    privilege or privacy with respect to the released information bearing on my admission to, retention or
                                    termination
                                    of medical staff appointment or clinical privileges. I release and discharge HSC, the Health Care Entity and the
                                    medical, dental, podiatry and ancillary staffs or panels, credentials committees, administrators, review and
                                    approval boards or committees, governing boards, whether or not designated by these titles, and their agents,
                                    servants or employees authorized by representatives and all other persons or entities supplying information to
                                    them from liability or claims of any kind or character in any way arising out of inquiries concerning me or
                                    disclosures made in good faith in connection with my application for appointment to the Health Care Entity’s
                                    Medical Staff or Provider Panel.
                                </label>
                                <div class="form-group form-inline ">
                                    <label class="formLabel" for=""><b>Authority to Redisclose: Unless I have denied authority by initialing here</b></label>
                                    <input type="text" class="form-control" id="cmd_35515" name="cmd_35515"  formControlName="cmd_35515"  style="display: inline-block; width: 880px;">
                                    <span style="display: inline-block;">, I authorize the Health
                                    Care Entity, the Health Care Entity’s Authorized Representatives, and HSC to redisclose information concerning
                                    my qualifications, or credentials and privileges to third parties who have a need to know the information (1)
                                    based upon state or federal laws or regulations, or (2) pursuant to any health care provider agreement to which
                                    I am or will be a party and in which I have an interest as an individual health care provider, or (3) to participate
                                    in the common recredentials program, if applicable.</span>
                                </div>
                                
                                <label for="">
                                    This Release does not authorize HSC to disclose information about my qualifications to any claimant. If a
                                    claimant requests information from HSC about me or if a subpoena duces tecum is served upon HSC seeking
                                    information about me, which is in HSC’s possession, I understand I will be notified immediately. If I direct HSC
                                    to resist the subpoena, I hereby agree to indemnify and hold harmless HSC, its officers, directors, employees
                                    and agents for all attorney fees, costs, fines, and expenses incurred in resisting the subpoena at my request.
                                </label>
                                <label for="">
                                    This authorization is limited to the acquisition and disclosure of information required by state or federal law, and
                                    information which is acquired or disclosed pursuant to activities protected by the state’s Review Organizational
                                    Immunity Act and the Health Care Quality Improvement Act of 1986. A photocopy of this Designation and
                                    Authorization for release and redisclosure of information shall be considered by the recipient to be a signed
                                    original, as long as it is transmitted to the recipient by HSC and is received within five years of its date.
                                </label>

                                <label for="">
                                    <b>The certain definitions used in this Release and set forth on the following page of this application are
                                        incorporated by reference. I understand that I may withdraw or modify this authorization at any time in
                                        writing by submitting a written request to HSC. PHOTOCOPY BOTH PAGES OF THIS FORM.</b>
                                </label>
                                <label for="" style="display: block; text-align: center;">

                                    <b> Signature stamps and date stamps are not acceptable. </b>
                                   </label>
                                   <br>
<div class="row">

    <!-- <div class="col-2 pr-0"> <label>Print Name here:</label></div>

    <div class="col-4 pl-0">

        <input type="text" placeholder=" Printed Name" id="cmd_435541"
            [class.is-invalid]="(caliPhy.controls['cmd_435541'].touched &&
                                                                                        
                                                                                        caliPhy.controls['cmd_435541'].invalid) ||
                                                                                        
                                                                                        (caliPhy.controls['cmd_435541'].invalid && isSubmit)" formControlName="cmd_435541"
            class="form-control">

        <div class="invalid-feedback"
            *ngIf="(caliPhy.controls['cmd_435541'].touched && caliPhy.controls['cmd_435541'].invalid) || (caliPhy.controls['cmd_435541'].invalid && isSubmit)">
            Required </div>

    </div> -->
    <div class="col-2 pr-0"> <label> <b> Applicant Signature :</b></label></div>

    <div class="col-4 pl-0">

        <img *ngIf="!showThree" class="img" src="./assets/images/e_sign.png" alt="Credential my Doc"
            (click)="esignOpenThree()" />

        <img *ngIf="showThree" class="img eSignImageShow" [src]="esignThree" alt="Credential my Doc"
            (click)="esignOpenThree()" /><br><span> (Stamped Signature Is Not Acceptable)

        </span>

        <input type="hidden" placeholder=" Provider Signature" name="cmd_3551" value="{{finalImgPathThree}}"
            id="cmd_3551" class="form-control" formControlName="cmd_3551"
            [class.is-invalid]="(caliPhy.controls['cmd_3551'].touched && caliPhy.controls['cmd_3551'].invalid ) || (caliPhy.controls['cmd_3551'].invalid && isSubmit)">
        <span
            *ngIf="(caliPhy.controls['cmd_3551'].touched && caliPhy.controls['cmd_3551'].invalid) || (caliPhy.controls['cmd_3551'].invalid && isSubmit)">
            <i class="fas fa-times ml-2 text-danger"></i>
        </span>
        <div class="invalid-feedback"
            *ngIf="(caliPhy.controls['cmd_3551'].touched && caliPhy.controls['cmd_3551'].invalid) || (caliPhy.controls['cmd_3551'].invalid && isSubmit)">

            Provider Signature is required and cannot be empty

        </div>

    </div>
</div>
<br>
<br>
<br>
<br>
<div class="row">

    <div class="col-1 pr-0"> <label> <b > Print Name:</b></label></div>

    <div class="col-5 pl-0">
        <input type="text" placeholder=" Printed Name" id="cmd_435541"
        [class.is-invalid]="(caliPhy.controls['cmd_435541'].touched &&
                                                                                    
                                                                                    caliPhy.controls['cmd_435541'].invalid) ||
                                                                                    
                                                                                    (caliPhy.controls['cmd_435541'].invalid && isSubmit)" formControlName="cmd_435541"
        class="form-control">

    <div class="invalid-feedback"
        *ngIf="(caliPhy.controls['cmd_435541'].touched && caliPhy.controls['cmd_435541'].invalid) || (caliPhy.controls['cmd_435541'].invalid && isSubmit)">
        Required </div>
       

    </div>


    <div class="col-1 pr-0"> <label><b>  Date:</b></label></div>

    <div class="col-5 pl-0 ">
        <div class="input-group">

            <input type="text" placeholder=" date picker" id="cmd_103541" (blur)="clearInputIfInvalidMandi('cmd_103541')"
                [class.is-invalid]="(caliPhy.controls['cmd_103541'].touched &&
                                                                                        
                                                                                        caliPhy.controls['cmd_103541'].invalid) ||
                                                                                        
                                                                                        (caliPhy.controls['cmd_103541'].invalid && isSubmit)" formControlName="cmd_103541"
                class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker #idh1012="ngbDatepicker">

            <div class="input-group-append">

                <button class="btn btn-outline-secondary calendar" (click)="idh1012.toggle()" type="button">

                    <i class="fas fa-calendar-alt"></i>

                </button>

            </div>
        </div>

        <div class="text-danger" *ngIf="(caliPhy.controls['cmd_103541'].invalid&&caliPhy.controls['cmd_103541'].touched) && (caliPhy.controls['cmd_103541'].value==''|| caliPhy.controls['cmd_103541'].value==null) " >
            <small >
            required
            </small>
          
          </div>
          <small class="text-danger" *ngIf="caliPhy.get('cmd_103541').errors?.invalidDateFormat">
            Only Date Format is allowed(MM/DD/YYYY)                </small>
          <small class="text-danger" *ngIf="caliPhy.get('cmd_103541').errors?.invalidMonth">
          Month is out of range
          </small>
          <small class="text-danger" *ngIf="caliPhy.get('cmd_103541').errors?.invalidDateyear">
            year is out of range(1900-2099)                  </small>
            <small class="text-danger" *ngIf="caliPhy.get('cmd_103541').errors?.invalidDateRange">
              date is out of range
              </small>

    </div>





</div>
<br>
<label for="" style="display: block; text-align: center;">

    <b>Please fax or e-mail this completed form to:  </b>

   </label>
   <label for="" style="display: block; text-align: center;">

    Hospital Services Corporation  
   </label>
   <label for="" style="display: block; text-align: center;">

    Credentials Verification Services 
   </label>
   <label for="" style="display: block; text-align: center;">

    Toll Free: (866) 908-0070 
   </label>
   <label for="" style="display: block; text-align: center;">
    Facsimile: (505) 346-0288
   </label>
   <label for="" style="display: block; text-align: center;">
    Email:<span style="color: rgb(18, 18, 248);"><u> Credentialing@nmhsc.com</u></span>
   </label>
                            
                            </td>
                         
                              
                        </tr>
                    </tbody>
                </table>
                
            </form>
        </div>
    </div>
    <div class="modal-footer mt-4">
        <button *ngIf="formData.receivedForm" type="button" class="btn customOrange float-right"
            (click)="saveForm('approve')">Approve</button>
        <button *ngIf="formData.submitForm" type="submit" class="btn btn customOrange float-right"
            (click)="saveForm('submit')">Submit</button>
        <button *ngIf="formData.submitForm" type="button" class="btn btn customOrange float-right"
            (click)="saveForm('save')">Save</button>
        <button *ngIf="formData.previewForm" type="button" class="btn customOrange float-right"
            (click)="close()">Close</button>
    </div>
</div>