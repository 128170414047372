import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import {NgbModal, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { SignaturePad } from 'angular2-signaturepad';
import {OnboardingformsServiceService} from 'apps/cmd-frontend/src/app/shared/services/onboardingforms-service/onboardingforms-service.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToasterNotiService } from 'apps/cmd-frontend/src/app/shared/services/notifications/toaster-noti.service';
import { File_Error } from '../../services/common/common.service';
import { AsyncSubject, Observable } from 'rxjs';
export interface SelectedFiles {
  fileName: string;
  fileSize : Number;
  mimeType : string; 
  base64?: string;

}


@Component({
  selector: 'ctg-esign',
  templateUrl: './esign.component.html',
  styleUrls: ['./esign.component.scss']
})
export class EsignComponent {
  @Input() formData
  @Output() base64Img: EventEmitter<any> = new EventEmitter();
  @Output() finalFilePath: EventEmitter<any> = new EventEmitter();
  @ViewChild('content') content;
  @ViewChild(SignaturePad) public signaturePad: SignaturePad;

  public img : any;
  public type : any;
  public filePath : any;
  public selectedFile : any;
  public previewOutputFile : any;
  public IsmodelShow = 1;
  public selectedFiles : SelectedFiles[] = [];

  constructor(
    private modalService: NgbModal,
    public activeModal:NgbActiveModal,
    private spinner: NgxSpinnerService,
    private notiService: ToasterNotiService,
    private onboardService: OnboardingformsServiceService,
  ) { }
    open(content) {
      this.modalService.open(
        content,
        {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
        });
    }

    public signaturePadOptions: Object = {
      'minWidth' : 2,
      penColour : 'rgb(66, 133, 244)',
      backgroundColour : 'rgb(169, 169, 169)',
      'canvasWidth': 250,
      'canvasHeight': 75
    };

    public finalImageOutput(previewOutputFile, filePath) {
      let data = {
        base64Img : previewOutputFile,
        finalFilePath : filePath
      }
      this.base64Img.emit(data)
    }

  public createEsign() {

    let ImageURL = this.signaturePad.toDataURL()
    var block = ImageURL.split(";");
    // Get the content type of the image
    var contentType = block[0].split(":")[1]; // In this case "image/gif"

    // get the real base64 content of the file
    var realData = block[1].split(",")[1];// In this case "R0lGODlhPQBEAPeoAJosM...."

    // Convert it to a blob to upload
    var blob = this.base64toBlob(realData, contentType);

    this.type = 1;
    // Create a FormData and append the file with "image" as parameter name
    const fileParams = new FormData();
    fileParams.append('fileUpload', blob);
    fileParams.append('fileType', this.type);

    this.spinner.show();
    this.onboardService.toCreateEsignOrSign(fileParams).subscribe(
      (data: any) => {
        if (data['success']) {
          this.filePath = data.data
          this.previewEsign(this.filePath)
          this.notiService.showSuccess(data.message, '', 3000)
        }
        else {
          this.notiService.showError(data.error, '', 3000)
        }
        this.spinner.hide();
      },
      error => {
        this.notiService.showError(File_Error)
        this.spinner.hide();
      }
    )
  }


 base64toBlob(b64Data, contentType, sliceSize?) {

  contentType = contentType || '';
  sliceSize = sliceSize || 512;

  let byteCharacters = atob(b64Data);
  let byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      let slice = byteCharacters.slice(offset, offset + sliceSize);

      let byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
      }

      let byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
  }

let blob = new Blob(byteArrays, {type: contentType});
return blob;
}

    public selectedFileForUpload(event){
      this.selectedFile = event.target.files[0]
    }

    public toFilesBase64(files: File[], selectedFiles: SelectedFiles[]): Observable<SelectedFiles[]> {
      const result = new AsyncSubject<SelectedFiles[]>();
      if (files?.length) {
        Object.keys(files)?.forEach(async (file, i) => {
          const reader = new FileReader();
          reader.readAsDataURL(files[i]);
          reader.onload = (e) => {
            selectedFiles = selectedFiles?.filter(f => f?.fileName != files[i]?.name)
            selectedFiles.push({ fileName: files[i]?.name, fileSize : files[i]?.size, mimeType : files[i]?.type, base64:reader?.result as string })
            result.next(selectedFiles);
           //const base64Img = (fileReader.result as string).substring((fileReader.result as string).indexOf( ',' ) + 1 );
            this.selectedFiles = selectedFiles;
            if (files?.length === (i + 1)) {
              result.complete();
            }
          };
        });
        return result;
      } else {
        result.next([]);
        result.complete();
        return result;
      }
    }
  
    public onFileSelected(event) {
      this.selectedFiles = [];
      const file = event.target.files;
    //  this.Uploadfile.nativeElement.nextSibling.innerHTML = file[0].name;
      // this.selectedFiles = []; // clear
      console.log(file)
      this.toFilesBase64(file, this.selectedFiles).subscribe((res: SelectedFiles[]) => {
        this.selectedFiles = res;
      });
    }

    public uploadEsign(){
      // console.log(this.selectedFile);

      if(this.selectedFiles.length != 0){
        for (let x in this.selectedFiles) {
        let size =  this.selectedFiles[x].fileSize.toString();
        let uploadFileSizeLimit = 60;
        console.log("File Size in MB : ",((+size/1024)/1000));
            if(((+size/1024)/1000) > uploadFileSizeLimit){
              this.notiService.showError("File size can not be greater than 60 MB.","",3000);
              this.selectedFiles = [];
              return
            }
        }
      }
      this.type = 2;

      const fb = new FormData();
     // fb.append('fileUpload', this.selectedFile);
      fb.append('fileType', this.type);

      for (var i = 0; i < this.selectedFiles.length; i++) {
        fb.append("fileUpload", JSON.stringify(this.selectedFiles[i]));
      }
      this.spinner.show();
      this.onboardService.toCreateEsignOrSign(fb).subscribe(
        (data : any) => {
          if (data['success']) {
            this.filePath = data.data
            this.finalImageOutput(this.selectedFiles[0].base64,this.filePath)
            this.previewEsign(this.filePath)
            this.notiService.showSuccess(data.message, '', 3000)
            this.selectedFiles = [];
          }
          else {
            this.notiService.showError(data.error, '', 3000);
            this.selectedFiles = [];
          }
          this.spinner.hide();
        },
        error => {
          this.notiService.showError(File_Error)
          this.spinner.hide();
        }
      )
    }

    public modalClose(){
      // console.log(this.IsmodelShow)
      this.IsmodelShow = 0;
      // console.log(this.IsmodelShow)

    }
    public previewEsign(filePath){
      this.spinner.show();
      let params = {
        filePath:filePath
      }
      this.onboardService.getPreviewEsign(params).subscribe(
        (data : any) => {
          if (data['success']) {
            this.previewOutputFile = data.base64
            this.finalImageOutput(data.base64,filePath)
          }
          else {
            this.notiService.showError(data.error, '', 3000)
          }
          this.spinner.hide();
        },
        error => {
          this.spinner.hide();
        }
      )
    }

    public reset(){
      this.signaturePad.clear();
    }
}
