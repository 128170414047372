import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { ApiServiceService } from '../cmd-api-service/api-service.service';
export const File_Error = 'Invalid file type.';
export const Error_Message = 'An error occurred';
import { timer, Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(private apiService:ApiServiceService) { }

  private _currentUserPermission = new BehaviorSubject(null);
  currentUserPermission = this._currentUserPermission.asObservable();

  public get userPermission(){
    return this._currentUserPermission.value;
  }

  private previousSearchRecord = new BehaviorSubject<any>('');
  public $SearchRecordObservable = this.previousSearchRecord.asObservable();

  private toggle = new BehaviorSubject(false);
	public $toggleObservable = this.toggle.asObservable();
 
	toggleClicked(status:boolean){
		this.toggle.next(status);
	}

  public get searchRecord(){
    return this.previousSearchRecord.value;
  }
  
  onPreviousSearchClicked(value:any){
      this.previousSearchRecord.next(value);
  }

  public setCurrentUserPermission(value: any) {
    this._currentUserPermission.next(value)
  }


  public getUserPermission(){
    return this.apiService.post('getuserpermission','')
  }

  public logoutUser(){
    return this.apiService.post('logout','')
  }

  public unlinkExcelSheet(postParams){
    return this.apiService.post('unlink', postParams);
  }

  public updatePayer(postParams){
    return this.apiService.post('providerpassport/updatepayer',postParams)
  }

  public updatePassword(postParams) {
    return this.apiService.post('updatepwd', postParams)
  }

  public getGeneratedReportList(postParams){
    return this.apiService.post('reportcanned/getqueuejobreport', postParams)
  }

  public downloadQueueReport(postParams) {
    return this.apiService.post('reportcanned/downloadqueuereport', postParams)
  }

  public regenerateSSession(postParams){
    return this.apiService.post('regeneratesession', postParams)
  }

  startTimer(initialDelay: number, interval: number): Observable<number> {
    return timer(initialDelay, interval);
  }

}
