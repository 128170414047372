<div id="onPointMedical">
  <div class="custHeader">
    <img src="./assets/images/OPMG.png" width="150" alt="no image found">
  </div>
  <div class="cust_body mb-4">
    <form [formGroup]="onPointForm">
      <div class="sectionOne ml-1">
        <h4>Section 1: Basic Personal Information</h4>
        <div class="row form-group">
          <div class="col-3">
            <div class="row">
              <div class="col-4 p-0 pl-2">
                <label for="9">Last Name <span class="mandatory">*</span></label>
              </div>
              <div class="col-8">
                <input class="form-control" id="9" name="9"
                  [class.is-invalid]="(onPointForm.controls['9'].touched && onPointForm.controls['9'].invalid) || (onPointForm.controls['9'].invalid && isSubmit)"
                  formControlName="9" type="text" data-id="lname" />
                <div class="mandatory" *ngIf=" onPointForm.controls['9'].touched && onPointForm.controls['9'].invalid">
                  <small *ngIf="onPointForm.controls['9'].errors">
                    required
                  </small>
                </div>
              </div>
            </div>
          </div>
          <div class="col-3">
            <div class="row">
              <div class="col-4 p-0 pl-2">
                <label for="8">First Name <span class="mandatory">*</span></label>
              </div>
              <div class="col-8">
                <input class="form-control" id="8" name="8" [class.is-invalid]="(onPointForm.controls['8'].touched && onPointForm.controls['8'].invalid) ||
                                      (onPointForm.controls['8'].invalid && isSubmit)" formControlName="8" type="text"
                  data-id="lname" />
                <div class="mandatory" *ngIf=" onPointForm.controls['8'].touched && onPointForm.controls['8'].invalid">
                  <small *ngIf="onPointForm.controls['8'].errors">
                    required
                  </small>
                </div>
              </div>
            </div>
          </div>
          <div class="col-3">
            <div class="row">
              <div class="col-5 p-0 pl-2">
                <label for="809">Middle Name </label>
              </div>
              <div class="col-7">
                <input class="form-control" id="809" name="809"  formControlName="809"
                  type="text" />
                
              </div>
            </div>
          </div>
          <div class="col-3">
            <div class="row">
              <div class="col-3 p-0 pl-2">
                <label for="29">Suffix </label>
              </div>
              <div class="col-9">
                <ng-select [labelForId]="29" [items]="suffix" bindLabel="name" placeholder="Suffix" bindValue="id"
                  formControlName="29">
                </ng-select>
              </div>
            </div>
          </div>
        </div>
        <div class="row form-group">
          <div class="col-4">
            <div class="row">
              <div class="col-4 p-0 pl-2">
                <label for="822">Credentials <span class="mandatory">*</span></label>
              </div>
              <div class="col-8">
                <ng-select [items]="credentials" [class.is-invalid]="
                          (onPointForm.controls['822'].touched &&
                            onPointForm.controls['822'].invalid) ||
                          (onPointForm.controls['822'].invalid && isSubmit)
                        " bindLabel="name" placeholder="Select Degree" bindValue="id" [labelForId]="822"
                  formControlName="822">
                </ng-select>
                <div class="mandatory" *ngIf="onPointForm.controls['822'].touched &&
                      onPointForm.controls['822'].invalid
                      ">
                  <small *ngIf="onPointForm.controls['822'].errors?.required">
                    required
                  </small>
                </div>
              </div>
            </div>
          </div>
          <div class="col-4">
            <div class="row">
              <div class="col-5 p-0 pl-1">
                <label for="284">Primary Specialty<span class="mandatory">*</span></label>
              </div>
              <div class="col-7">
                <ng-select [class.is-invalid]="
                (onPointForm.controls['284'].touched &&
                  onPointForm.controls['284'].invalid) ||
                (onPointForm.controls['284'].invalid && isSubmit)
              " [items]="specialty" bindLabel="name" placeholder="Select Specialty" bindValue="id" [labelForId]="284"
                  formControlName="284">
                </ng-select>
                <div class="mandatory"
                  *ngIf=" onPointForm.controls['284'].touched && onPointForm.controls['284'].invalid">
                  <small *ngIf="onPointForm.controls['284'].errors">
                    required
                  </small>
                </div>
              </div>
            </div>
          </div>
          <div class="col-4">
            <div class="row">
              <div class="col-5 p-0 pl-1">
                <label for="962">Secondary Specialty</label>
              </div>
              <div class="col-7">
                <ng-select [items]="specialtySecondary" bindLabel="name" placeholder="Select Specialty" bindValue="id"
                  [labelForId]="962" formControlName="962">
                </ng-select>
              </div>
            </div>
          </div>
        </div>
        <div class="row form-group">
          <div class="col-6">
            <div class="row">
              <div class="col-4 p-0 pl-2">
                <label for="278">Other Names Used</label>
              </div>
              <div class="col-8">
                <input class="form-control" id="278" name="278" formControlName="278" type="text" data-id="lname" />
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="row">
              <!-- <div class="col-2 p-0 pl-2">
                <label for="280">Dates Used</label>
              </div>
              <div class="col-5">
                <div class="input-group">
                  <input class="form-control onboard datepicker" placeholder="Effective Date" ngbDatepicker
                    #d280="ngbDatepicker" id="280" name="280" formControlName="280" type="text" />
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar " (click)="d280.toggle()" type="button">
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
              </div> -->
               
             <div class="col-2 p-0 pl-2">
                <label for="280">Dates Used</label>
              </div>
              <div class="col-5">
                <div class="input-group">
                  <input class="form-control"  name="dp"
                 
                      placeholder="Effective Date"  appMaskInput [appMaskValue]="this.onPointForm.controls['280'].value"ngbDatepicker #d280="ngbDatepicker"
                      placement="bottom" formControlName="280"  (blur)="clearInputIfInvalid('280')" >
                  <div class="input-group-append">
                      <button class="btn btn-outline-secondary calendar" (click)="d280.toggle()"
                          type="button"><i class="fas fa-calendar-alt"></i></button>
                  </div>
              </div>

              <div  *ngIf="onPointForm.get('280').invalid ">
                <small class="text-danger" *ngIf="onPointForm.get('280').errors?.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY)
                </small>
                <small class="text-danger" *ngIf="onPointForm.get('280').errors?.invalidMonth">
                Month is out of range
                </small>
                <small class="text-danger" *ngIf="onPointForm.get('280').errors?.invalidDateyear">
                  year is out of range(1900-2099)                      </small>
                  <small class="text-danger" *ngIf="onPointForm.get('280').errors?.invalidDateRange">
                    date is out of range
                    </small>
                </div>
              </div>
               
                
                


              <div class="col-5">
                <div class="input-group">
                  <input class="form-control"  name="281" appMaskInput [appMaskValue]="this.onPointForm.controls['281'].value"
                        placeholder="Term Date" ngbDatepicker #d281="ngbDatepicker"
                        placement="bottom" formControlName="281"  (blur)="clearInputIfInvalid('281')" >
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d281.toggle()"
                            type="button"><i class="fas fa-calendar-alt"></i></button>
                    </div>
                  </div> 
                  <div  *ngIf="onPointForm.get('281').invalid ">
                    <small class="text-danger" *ngIf="onPointForm.get('281').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="onPointForm.get('281').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="onPointForm.get('281').errors?.invalidDateyear">
                      year is out of range(1900-2099)                      </small>
                      <small class="text-danger" *ngIf="onPointForm.get('281').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
                </div>
              
              <!-- </div>  -->
              
            </div>
            </div>
          </div>
          
        
        <div class="row form-group">
          <div class="col-3">
            <div class="row">
              <div class="col-2 p-0 pl-2">
                <label for="31">DOB <span class="mandatory">*</span></label>
              </div>
              <div class="col-10">
                <div class="input-group">
                  <input class="form-control"  name="31" appMaskInput [appMaskValue]="this.onPointForm.controls['31'].value"
                  [class.is-invalid]="
                  (onPointForm.controls['31'].touched &&
                    onPointForm.controls['31'].invalid) ||
                  (onPointForm.controls['31'].invalid && isSubmit)"
                        placeholder="MM/DD/YYYY" (blur)="clearInputIfInvalidMandi('31')"  ngbDatepicker #d31="ngbDatepicker"
                        placement="bottom" formControlName="31">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d31.toggle()"
                            type="button"><i class="fas fa-calendar-alt"></i></button>
                    </div>
                
                </div>
                <div class="text-danger" *ngIf="(onPointForm.controls['31'].invalid&&onPointForm.controls['31'].touched) && (onPointForm.controls['31'].value==''|| onPointForm.controls['31'].value==null) " >
                  <small >
                  required
                  </small>

              </div>
                <small class="text-danger" *ngIf="onPointForm.get('31').errors?.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY)                </small>
                <small class="text-danger" *ngIf="onPointForm.get('31').errors?.invalidMonth">
                Month is out of range
                </small>
                <small class="text-danger" *ngIf="onPointForm.get('31').errors?.invalidDateyear">
                  year is out of range(1900-2099)                  </small>
                  <small class="text-danger" *ngIf="onPointForm.get('31').errors?.invalidDateRange">
                    date is out of range
                    </small>
              </div>
            </div>
          </div>
          <div class="col-3">
            <div class="row">
              <div class="col-6 p-0">
                <label for="32">Social Security Number <span class="mandatory">*</span></label>
              </div>
              <div class="col-6">
                <input class="form-control" [class.is-invalid]="
                    (onPointForm.controls['32'].touched &&
                      onPointForm.controls['32'].invalid) ||
                    (onPointForm.controls['32'].invalid && isSubmit)
                  " id="32" name="32" formControlName="32" type="text" data-id="ssn"
                  (ngModelChange)="getSsn($event)" />
                <div class="mandatory" *ngIf="
                    onPointForm.controls['32'].touched &&
                    onPointForm.controls['32'].invalid
                  ">
                  <small *ngIf="onPointForm.controls['32'].errors?.required">
                    required
                  </small>
                </div>
              </div>
            </div>
          </div>
          <div class="col-3">
            <div class="row">
              <div class="col-4 p-0 pl-2">
                <label for="30">Gender</label>
              </div>
              <div class="col-8">
                <ng-select [items]="gender" bindLabel="name" placeholder="Select Gender" bindValue="id"
                  formControlName="30" [labelForId]="30">
                </ng-select>
              </div>
            </div>
          </div>
          <div class="col-3">
            <div class="row">
              <div class="col-4 p-0 pl-2">
                <label for="741">Race</label>
              </div>
              <div class="col-8">
                <ng-select [items]="race" bindLabel="name" [labelForId]="741" placeholder="Select Race" bindValue="id"
                  formControlName="741" [labelForId]="741">
                </ng-select>
              </div>
            </div>
          </div>
        </div>
        <div class="row form-group">
          <div class="col-3 p-0 pl-2">
            <label for="810">Place of Birth:(City/State/County) <span class="mandatory">*</span></label>
          </div>
          <div class="col-3">
            <input class="form-control" id="810" [class.is-invalid]="
                  (onPointForm.controls['810'].touched &&
                    onPointForm.controls['810'].invalid) ||
                  (onPointForm.controls['810'].invalid && isSubmit)
                " name="810" placeholder="City of Birth" formControlName="810" type="text" data-id="bcity" />
            <div class="mandatory" *ngIf="
                                onPointForm.controls['810'].touched &&
                                onPointForm.controls['810'].invalid
                              ">
              <small *ngIf="onPointForm.controls['810'].errors?.required">
                required
              </small>
            </div>
          </div>
          <div class="col-3">
            <ng-select [class.is-invalid]="
                      (onPointForm.controls['289'].touched &&
                        onPointForm.controls['289'].invalid) ||
                      (onPointForm.controls['289'].invalid && isSubmit)" [labelForId]="289" [items]="states"
              bindLabel="name" placeholder="Select State" bindValue="id" formControlName="289">
            </ng-select>
            <div class="mandatory" *ngIf="
                      onPointForm.controls['289'].touched &&
                      onPointForm.controls['289'].invalid
                    ">
              <small *ngIf="onPointForm.controls['289'].errors?.required">
                required
              </small>
            </div>
          </div>
          <div class="col-3 form-group">
            <input
            [class.is-invalid]="
            (onPointForm.controls['291'].touched &&
              onPointForm.controls['291'].invalid) ||
            (onPointForm.controls['291'].invalid && isSubmit)"  type="text" class="form-control" name="291" id="291" formControlName="291"
              placeholder="Country of Birth">
              <div class="mandatory" *ngIf="
              onPointForm.controls['291'].touched &&
              onPointForm.controls['291'].invalid
            ">
      <small *ngIf="onPointForm.controls['291'].errors?.required">
        required
      </small>
    </div>
          </div>
        </div>
        <div class="row form-group">
          <div class="col-3 p-0 pl-2">
            <label for="16">Home Address 1 <span class="mandatory">*</span></label>
          </div>
          <div class="col-3">
            <input class="form-control" [class.is-invalid]="
                    (onPointForm.controls['16'].touched &&
                      onPointForm.controls['16'].errors) ||
                    (onPointForm.controls['16'].errors && isSubmit)
                  " id="16" name="16" formControlName="16" type="text" data-id="homeaddress1" />
            <div class="mandatory" *ngIf="
                    onPointForm.controls['16'].touched &&
                    onPointForm.controls['16'].errors
                  ">
              <small class="text-danger" *ngIf="onPointForm.controls['16'].errors?.required">
                required
              </small>
            </div>
          </div>
          <div class="col-3 p-0 pl-2">
            <label for="17">Home Address 2</label>
          </div>
          <div class="col-3">
            <input class="form-control" id="17" name="17" formControlName="17" type="text" data-id="homeaddress2" />
          </div>
        </div>
        <div class="row form-group">
          <div class="col-3">
            <div class="row">
              <div class="col-4 p-0 pl-2">
                <label for="18">Home City <span class="mandatory">*</span></label>
              </div>
              <div class="col-8">
                <input [class.is-invalid]="
                (onPointForm.controls['18'].touched &&
                  onPointForm.controls['18'].errors) ||
                (onPointForm.controls['18'].errors && isSubmit)
              " class="form-control" id="18" name="18" formControlName="18" type="text" />
                <div class="mandatory" *ngIf="
              onPointForm.controls['18'].touched &&
              onPointForm.controls['18'].errors
            ">
                  <small class="text-danger" *ngIf="onPointForm.controls['18'].errors?.required">
                    required
                  </small>
                </div>
              </div>
            </div>
          </div>
          <div class="col-3">
            <div class="row">
              <div class="col-4 p-0 pl-2">
                <label for="19">Home County <span class="mandatory">*</span></label>
              </div>
              <div class="col-8">
                <ng-select [class.is-invalid]="
                (onPointForm.controls['19'].touched &&
                  onPointForm.controls['19'].errors) ||
                (onPointForm.controls['19'].errors && isSubmit)" [labelForId]="19" [items]="county" bindLabel="name"
                  placeholder="Select County" bindValue="id" formControlName="19">
                </ng-select>
                <div class="mandatory" *ngIf="
              onPointForm.controls['19'].touched &&
              onPointForm.controls['19'].errors
            ">
                  <small class="text-danger" *ngIf="onPointForm.controls['19'].errors?.required">
                    required
                  </small>
                </div>
              </div>
            </div>
          </div>
          <div class="col-3">
            <div class="row">
              <div class="col-4 p-0 pl-2">
                <label for="20">Home State/Country <span class="mandatory">*</span></label>
              </div>
              <div class="col-8">
                <ng-select [class.is-invalid]="
                (onPointForm.controls['20'].touched &&
                  onPointForm.controls['20'].errors) ||
                (onPointForm.controls['20'].errors && isSubmit)" [labelForId]="20" [items]="states" bindLabel="name"
                  placeholder="Select State/Country" bindValue="id" formControlName="20">
                </ng-select>
                <div class="mandatory" *ngIf="
              onPointForm.controls['20'].touched &&
              onPointForm.controls['20'].errors
            ">
                  <small class="text-danger" *ngIf="onPointForm.controls['20'].errors?.required">
                    required
                  </small>
                </div>
              </div>
            </div>
          </div>
          <div class="col-3">
            <div class="row">
              <div class="col-4 p-0 pl-2">
                <label for="21">Home ZIP <span class="mandatory">*</span></label>
              </div>
              <div class="col-8">
                <input [class.is-invalid]="
                (onPointForm.controls['21'].touched &&
                  onPointForm.controls['21'].errors) ||
                (onPointForm.controls['21'].errors && isSubmit)" class="form-control" id="21" name="21"
                  formControlName="21" type="text" />
                <div class="mandatory" *ngIf="
                onPointForm.controls['21'].touched &&
                onPointForm.controls['21'].errors
              ">
                  <small class="text-danger" *ngIf="onPointForm.controls['21'].errors?.required">
                    required
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row form-group">
         

          <div class="col-2 pl-2">
            <label for="709">Mobile Phone<span class="mandatory">*</span></label>
          </div>
            <div class="col-4">
             <input [class.is-invalid]="
             (onPointForm.controls['709'].touched &&
               onPointForm.controls['709'].invalid) ||
              (onPointForm.controls['709'].invalid && isSubmit)"type="text" class="form-control" id="709" name="709" maxlength="12"
               [formControlName]="709" (keyup)="handleNumber($event,709)"/>
             
                <div class="mandatory" *ngIf="
                 onPointForm.controls['709'].touched &&
                 onPointForm.controls['709'].invalid ">
                   <small class="text-danger" *ngIf="onPointForm.controls['709'].errors?.required">
                      Required 
                    </small>
                    <small class="text-danger" *ngIf="onPointForm.controls['709'].errors?.pattern">
                     Required pattern xxx-xxx-xxxx
                    </small>
                </div>

          </div>
          <div class="col-2">
            <label for="759">Email Address<span class="mandatory">*</span></label>
          </div>
          <div class="col-4">
            <input [class.is-invalid]="
            (onPointForm.controls['759'].touched &&
              onPointForm.controls['759'].errors) ||
            (onPointForm.controls['759'].errors && isSubmit)" class="form-control" id="759" name="759"
              formControlName="759" type="759" />
            <div class="mandatory" *ngIf="
                        onPointForm.controls['759'].touched &&
                        onPointForm.controls['759'].errors
                      ">
              <small class="text-danger" *ngIf="onPointForm.controls['759'].errors?.required">
                required
              </small>
            </div>
          </div>
        </div>
        <div class="row ">
          <div class="col-4 form-group">
            <div class="row">
              <div class="col-3 p-0 pl-2">
                <label for="1634">NPI <span class="mandatory">*</span></label>
              </div>
              <div class="col-9">
                <input [class.is-invalid]="
                (onPointForm.controls['1634'].touched &&
                  onPointForm.controls['1634'].errors) ||
                (onPointForm.controls['1634'].errors && isSubmit)" class="form-control" id="1634" name="1634"
                  formControlName="1634" type="text" />
                <div class="mandatory" *ngIf="
                onPointForm.controls['1634'].touched &&
                onPointForm.controls['1634'].errors
              ">
                  <small class="text-danger" *ngIf="onPointForm.controls['1634'].errors?.required">
                    required
                  </small>
                </div>
              </div>
            </div>
          </div>
          <div class="col-4">
            <div class="row">
              <div class="col-3 p-0 pl-2">
                <label for="1635">NPI User</label>
              </div>
              <div class="col-9">
                <input class="form-control" id="1635" name="1635" formControlName="1635" type="text" />
              </div>
            </div>
          </div>
          <div class="col-4">
            <div class="row">
              <div class="col-3 p-0 pl-2">
                <label for="1636">NPI Password</label>
              </div>
              <div class="col-9">
                <input class="form-control" id="1636" name="1636" formControlName="1636" type="text" />
              </div>
            </div>
          </div>
        </div>
        <div class="row form-group mr-1">
          <div class="col-2 pl-2"> <label for="277">Languages Spoken</label> <span class="mandatory">*</span></div>
          <div class="col-10 p-0">
            <div class="row">
              <div class="col-3">
                <ng-select [items]="languages" [class.is-invalid]="(onPointForm.controls['277'].touched &&
                onPointForm.controls['277'].invalid) ||
                (onPointForm.controls['277'].invalid && isSubmit)" bindLabel="name" placeholder="Provider Language 1"
                  bindValue="id" formControlName="277">
                </ng-select>
                <div class="invalid-feedback"
                  *ngIf="(onPointForm.controls['277'].touched && onPointForm.controls['277'].invalid) || (onPointForm.controls['277'].invalid && isSubmit)">
                  Language is required and cannot be empty
                </div>
              </div>
              <div class="col-3">
                <ng-select [items]="secondaryLanguages" bindLabel="name" placeholder="Provider Language 2"
                  bindValue="id" formControlName="1535">
                </ng-select>
              </div>
              <div class="col-3">
                <ng-select [items]="tertiaryLanguages" bindLabel="name" placeholder="Provider Language 3" bindValue="id"
                  formControlName="1536">
                </ng-select>
              </div>
              <div class="col-3">
                <ng-select [items]="quaternaryLanguages" bindLabel="name" placeholder="Provider Language 4"
                  bindValue="id" formControlName="2207">
                </ng-select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="sectionTwo">
        <div class="row">
          <div class="col-10">
            <h4>Section 2: Professional IDs
            </h4>
          </div>
          <div class="col-2">
            <div class="form-check">
              <input (ngModelChange)="handleNAchange('cmd_NA_10')" class="form-check-input"
                formControlName="cmd_NA_10" type="checkbox" value="1" id="cmd_NA_10">
              <label class="form-check-label" for="cmd_NA_10">
                In Process
              </label>
            </div>
          </div>
        </div>
        
        <div class="row form-group ml-1">
          <div class="col-2 p-0">
            <div class="row">
              <div class="col-6 p-0 pl-2">
                Medical License<span *ngIf="onPointForm.controls['cmd_NA_10'].value==null || onPointForm.controls['293'].invalid" class="mandatory">*</span>
              </div>
              <div class="col-6">
                <input class="form-control" id="293" name="293" (blur)="handleNAchange('cmd_NA_10',293)"
                [class.is-invalid]="onPointForm.controls['293'].invalid"
                  formControlName="293" type="text" data-id="lname" />
                  <div class="mandatory"
                  *ngIf="onPointForm.controls['293'].invalid">
                  <small >
                    required
                  </small>
                </div>
              </div>
            </div>
          </div>
          <div class="col-3">
            <div class="row">
              <div class="col-3">Type</div>
              <div class="col-9">
                <input class="form-control" id="296" name="296"
                 
                  formControlName="296" type="text" data-id="lname"  placeholder="License Type"/>
                <!-- <div class="mandatory"
                  *ngIf=" onPointForm.controls['296'].touched && onPointForm.controls['296'].invalid">
                  <small *ngIf="onPointForm.controls['296'].errors">
                    required
                  </small>
                </div> -->
                
              </div>
            </div>
          </div>
          <div class="col-3">
            <div class="row">
              <div class="col-2">State<span *ngIf="onPointForm.controls['cmd_NA_10'].value==null || onPointForm.controls['299'].invalid" class="mandatory">*</span></div>
              <div class="col-9">
                <ng-select [class.is-invalid]="onPointForm.controls['299'].invalid" [items]="states" bindLabel="name" placeholder="Select State" bindValue="id" (blur)="handleNAchange('cmd_NA_10',29)"
                  [labelForId]="299" formControlName="299">
                </ng-select>
                <div class="mandatory"
                  *ngIf="onPointForm.controls['299'].invalid">
                  <small >
                    required
                  </small>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2">
            <div class="row">
              <div class="col-2">Issue<span *ngIf="onPointForm.controls['294'].invalid||onPointForm.controls['cmd_NA_10'].value==null" class="mandatory">*</span></div>
              <div class="col-10">
                <div class="input-group">
                  <input class="form-control"  name="294" 
                  [class.is-invalid]="(onPointForm.controls['294'].invalid && onPointForm.controls['294'].touched )|| (onPointForm.controls['294'].invalid && isSubmit)"
                        placeholder="MM/DD/YYYY"  appMaskInput [appMaskValue]="this.onPointForm.controls['294'].value"ngbDatepicker #d294="ngbDatepicker"
                        placement="bottom" formControlName="294" (blur)="handleNAchange('cmd_NA_10',294)"   (blur)="clearInputIfInvalid('294','cmd_NA_10')"  >
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d294.toggle()"
                            type="button"><i class="fas fa-calendar-alt"></i></button>
                    </div>
               </div> 
          </div>

               
          <div class="mandatory" *ngIf="onPointForm.controls['294'].invalid && (onPointForm.controls['294'].value==''|| onPointForm.controls['294'].value==null) " >
            <small >
            required
            </small>

        </div>
        <div  *ngIf="onPointForm.get('294').invalid ">
          <small class="text-danger" *ngIf="onPointForm.get('294').errors.invalidDateFormat">
            Only Date Format is allowed(MM/DD/YYYY)                </small>
          <small class="text-danger" *ngIf="onPointForm.get('294').errors.invalidMonth">
          Month is out of range
          </small>
          <small class="text-danger" *ngIf="onPointForm.get('294').errors.invalidDateyear">
            year is out of range(1900-2099)                  </small>
            <small class="text-danger" *ngIf="onPointForm.get('294').errors.invalidDateRange">
              date is out of range
              </small>
          </div>
         
         
                </div>
            </div>
          <!-- </div> -->
          
          <div class="col-2">
            <div class="row">
              <div class="col-2">Exp<span *ngIf="onPointForm.controls['295'].invalid||onPointForm.controls['cmd_NA_10'].value==null" class="mandatory">*</span></div>
              <div class="col-10">
                <div class="input-group">
                  <input class="form-control"  name="295" appMaskInput [appMaskValue]="this.onPointForm.controls['295'].value"
                 
                  [class.is-invalid]="(onPointForm.controls['295'].invalid && onPointForm.controls['295'].touched )|| (onPointForm.controls['295'].invalid && isSubmit)"      placeholder="MM/DD/YYYY" ngbDatepicker #d295="ngbDatepicker"
                        placement="bottom" formControlName="295"  (blur)="handleNAchange('cmd_NA_10',295)" (blur)="clearInputIfInvalid('295','cmd_NA_10')"  >
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d295.toggle()"
                            type="button"><i class="fas fa-calendar-alt"></i></button>
                    </div>
               </div> 
            </div>

            <div class="mandatory" *ngIf="onPointForm.controls['295'].invalid && (onPointForm.controls['295'].value==''|| onPointForm.controls['295'].value==null) " >
              <small >
              required
              </small>

          </div>
          <div  *ngIf="onPointForm.get('295').invalid ">
            <small class="text-danger" *ngIf="onPointForm.get('295').errors.invalidDateFormat">
              Only Date Format is allowed(MM/DD/YYYY)                </small>
            <small class="text-danger" *ngIf="onPointForm.get('295').errors.invalidMonth">
            Month is out of range
            </small>
            <small class="text-danger" *ngIf="onPointForm.get('295').errors.invalidDateyear">
              year is out of range(1900-2099)                  </small>
              <small class="text-danger" *ngIf="onPointForm.get('295').errors.invalidDateRange">
                date is out of range
                </small>
            </div>    
              </div>
            <!-- </div> -->
          </div>
        </div>
        <div class="row form-group ml-1">
          <div class="col-2 p-0">
            <div class="row">
              <div class="col-6 p-0 pl-2">
                Medical License
              </div>
              <div class="col-6">
                <input class="form-control" id="300" name="300" formControlName="300" type="text" data-id="lname" />

              </div>
            </div>
          </div>
          <div class="col-3">
            <div class="row">
              <div class="col-3">Type</div>
              <div class="col-9">
                <!-- <ng-select [items]="specialty" bindLabel="name" placeholder="Select Specialty" bindValue="id"
                  [labelForId]="1274" formControlName="1274">
                </ng-select> -->
                  <input class="form-control" id="1274" name="1274"
                  formControlName="1274" type="text" data-id="lname"  placeholder="License 2 Type"/>
              </div>
            </div>
          </div>
          <div class="col-3">
            <div class="row">
              <div class="col-2">State</div>
              <div class="col-9">
                <ng-select [items]="altLicenseState" bindLabel="name" placeholder="Select State" bindValue="id"
                  [labelForId]="907" formControlName="907">
                </ng-select>
              </div>
            </div>
          </div>
          <div class="col-2">
            <div class="row">
              <div class="col-2">Issue</div>
              <div class="col-10">
                <div class="input-group">
                  <input class="form-control onboard" placeholder="MM/DD/YYYY" ngbDatepicker #d307="ngbDatepicker"
                    id="307" name="307" formControlName="307" appMaskInput [appMaskValue]="this.onPointForm.controls['307'].value"type="text" data-id="dob_date"  (blur)="clearInputIfInvalid('307')" />
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d307.toggle()" type="button">
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
                </div>
                <div  *ngIf="onPointForm.get('307').invalid ">
                  <small class="text-danger" *ngIf="onPointForm.get('307').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="onPointForm.get('307').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="onPointForm.get('307').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="onPointForm.get('307').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </div>
            </div>
         
          <div class="col-2">
            <div class="row">
              <div class="col-2">Exp</div>
              <div class="col-10">
                <div class="input-group">
                  <input class="form-control onboard" placeholder="MM/DD/YYYY" appMaskInput [appMaskValue]="this.onPointForm.controls['308'].value"ngbDatepicker #d308="ngbDatepicker"
                    id="308" name="308" formControlName="308" type="text" data-id="dob_date"   (blur)="clearInputIfInvalid('308')" />
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d308.toggle()" type="button">
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div  *ngIf="onPointForm.get('308').invalid ">
                <small class="text-danger" *ngIf="onPointForm.get('308').errors?.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY)
                </small>
                <small class="text-danger" *ngIf="onPointForm.get('308').errors?.invalidMonth">
                Month is out of range
                </small>
                <small class="text-danger" *ngIf="onPointForm.get('308').errors?.invalidDateyear">
                  year is out of range(1900-2099)                      </small>
                  <small class="text-danger" *ngIf="onPointForm.get('308').errors?.invalidDateRange">
                    date is out of range
                    </small>
                </div>
            </div>
          </div>
        </div>
        <div class="row form-group ml-1">
          <div class="col-2 p-0">
            <div class="row">
              <div class="col-6 p-0 pl-2">
                Medical License
              </div>
              <div class="col-6">
                <input class="form-control" id="999" name="999" formControlName="999" type="text" data-id="lname" />
              </div>
            </div>
          </div>
          <div class="col-3">
            <div class="row">
              <div class="col-3">Type</div>
              <div class="col-9">
               
                <input class="form-control" id="1432" name="1432"
                formControlName="1432" type="text" data-id="lname"  placeholder="License 2 Type"/>
              </div>
            </div>
          </div>
          <div class="col-3">
            <div class="row">
              <div class="col-2">State</div>
              <div class="col-9">
                <ng-select [items]="additionalLicenseState" bindLabel="name" placeholder="Select State" bindValue="id"
                  [labelForId]="1010" formControlName="1010">
                </ng-select>
              </div>
            </div>
          </div>
          <div class="col-2">
            <div class="row">
              <div class="col-2">Issue</div>
              <div class="col-10">
                <div class="input-group">
                  <input class="form-control onboard" placeholder="MM/DD/YYYY" appMaskInput [appMaskValue]="this.onPointForm.controls['1003'].value"ngbDatepicker #d1003="ngbDatepicker"
                    id="1003" name="1003" formControlName="1003" type="text" data-id="dob_date"  (blur)="clearInputIfInvalid('1003')"  />
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d1003.toggle()" type="button">
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div  *ngIf="onPointForm.get('1003').invalid ">
                <small class="text-danger" *ngIf="onPointForm.get('1003').errors?.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY)
                </small>
                <small class="text-danger" *ngIf="onPointForm.get('1003').errors?.invalidMonth">
                Month is out of range
                </small>
                <small class="text-danger" *ngIf="onPointForm.get('1003').errors?.invalidDateyear">
                  year is out of range(1900-2099)                      </small>
                  <small class="text-danger" *ngIf="onPointForm.get('1003').errors?.invalidDateRange">
                    date is out of range
                    </small>
                </div>
            </div>
          </div>
          <div class="col-2">
            <div class="row">
              <div class="col-2">Exp</div>
              <div class="col-10">
                <div class="input-group">
                  <input class="form-control onboard" placeholder="MM/DD/YYYY" appMaskInput [appMaskValue]="this.onPointForm.controls['1004'].value" ngbDatepicker #d1004="ngbDatepicker"
                    id="1004" name="1004" formControlName="1004" type="text" data-id="dob_date"  (blur)="clearInputIfInvalid('1004')"  />
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d1004.toggle()" type="button">
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div  *ngIf="onPointForm.get('1004').invalid ">
                <small class="text-danger" *ngIf="onPointForm.get('1004').errors?.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY)
                </small>
                <small class="text-danger" *ngIf="onPointForm.get('1004').errors?.invalidMonth">
                Month is out of range
                </small>
                <small class="text-danger" *ngIf="onPointForm.get('1004').errors?.invalidDateyear">
                  year is out of range(1900-2099)                      </small>
                  <small class="text-danger" *ngIf="onPointForm.get('1004').errors?.invalidDateRange">
                    date is out of range
                    </small>
                </div>
            </div>
          </div>
        </div>
        <h5 class="mandatory warn">*NP/APN/APRN- please provide all valid licensure for RN and RXN.
        </h5>
        <div class="row form-group">
          <div class="col-4">
            <div class="row">
              <div class="col-6">
                <label for="812">Individual CO Medicaid #</label>
              </div>
              <div class="col-6">
                <input class="form-control" id="812" name="812" placeholder="Medicaid Number " formControlName="812"
                  type="text" data-id="lname" />
              </div>
            </div>
          </div>
          <div class="col-4">
            <div class="row">
              <div class="col-6">
                <label>Medicaid User</label>
              </div>
              <div class="col-6">
                <input class="form-control" id="2110" name="2110" placeholder="Medicaid Login" formControlName="2110"
                  type="text" data-id="lname" />
              </div>
            </div>
          </div>
          <div class="col-4">
            <div class="row">
              <div class="col-6">
                <label>Medicaid Password#</label>
              </div>
              <div class="col-6">
                <input class="form-control" id="2111" name="2111" placeholder="Medicaid Password" formControlName="2111"
                  type="text" data-id="lname" />
              </div>
            </div>
          </div>
        </div>
        <div class="row form-group">
          <div class="col-2">
            <label>Individual CO Medicare #</label>
          </div>
          <div class="col-10">
            <input class="form-control" id="1549" name="1549" placeholder="Medicare Number (PTAN) "
              formControlName="325" type="text" data-id="lname" />
          </div>
        </div>
        <div class="row form-group">
          <div class="col-3">
            <div class="row">
              <div class="col-3"> <label for="309">DEA</label><span *ngIf="onPointForm.controls['cmd_NA_01'].value==null || onPointForm.controls['309'].invalid" class="mandatory">*</span> </div>
              <div class="col-9">
                <input (blur)="handleNAchange('cmd_NA_01',309)" [class.is-invalid]="onPointForm.controls['309'].invalid"
                  class="form-control" id="309" name="309" placeholder="DEA Number" formControlName="309" type="text"
                  data-id="309" />
                <div class="mandatory"
                  *ngIf="onPointForm.controls['309'].invalid">
                  <small >
                    required
                  </small>
                </div>
              </div>
            </div>
          </div>
         
          
          <div class="col-3">
            <div class="row">
              <div class="col-3 p-0"><label for="312">DEA State<span *ngIf="onPointForm.controls['cmd_NA_01'].value==null || onPointForm.controls['312'].invalid" class="mandatory">*</span></label></div>
              <div class="col-9">
                <ng-select (blur)="handleNAchange('cmd_NA_01',312)" [class.is-invalid]="onPointForm.controls['312'].invalid"
                  [items]="states" bindLabel="name" placeholder="State or Country" bindValue="id" [labelForId]="312"
                  formControlName="312">
                </ng-select>
                <div class="mandatory"
                  *ngIf=" onPointForm.controls['312'].invalid">
                  <small>
                    required
                  </small>
                </div>
              </div>
            </div>
          </div>
          <div class="col-3">
            <div class="row">
              <div class="col-4">
                <label for="310">DEA Issue<span *ngIf="onPointForm.controls['310'].invalid||onPointForm.controls['cmd_NA_01'].value==null" class="mandatory">*</span></label>
              </div>
              <div class="col-8">
                <div class="input-group">
                  <input (blur)="handleNAchange('cmd_NA_01',310)" [class.is-invalid]="(onPointForm.controls['310'].invalid && onPointForm.controls['310'].touched )|| (onPointForm.controls['310'].invalid && isSubmit)"
                  class="form-control"
                  placeholder="MM/DD/YYYY" ngbDatepicker #d310="ngbDatepicker" appMaskInput [appMaskValue]="this.onPointForm.controls['310'].value"
                  placement="bottom"  formControlName="310" (blur)="clearInputIfInvalid('310','cmd_NA_01')"   >
              <div class="input-group-append">
                  <button class="btn btn-outline-secondary calendar" (click)="d310.toggle()"
                      type="button"><i class="fas fa-calendar-alt"></i></button>
              </div>
              </div>
              <div class="mandatory" *ngIf="onPointForm.controls['310'].invalid && (onPointForm.controls['310'].value==''|| onPointForm.controls['310'].value==null) " >
                <small >
                required
                </small>

            </div>
            <div  *ngIf="onPointForm.get('310').invalid ">
              <small class="text-danger" *ngIf="onPointForm.get('310').errors.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY)                </small>
              <small class="text-danger" *ngIf="onPointForm.get('310').errors.invalidMonth">
              Month is out of range
              </small>
              <small class="text-danger" *ngIf="onPointForm.get('310').errors.invalidDateyear">
                year is out of range(1900-2099)                  </small>
                <small class="text-danger" *ngIf="onPointForm.get('310').errors.invalidDateRange">
                  date is out of range
                  </small>
              </div>
              </div>
            </div>
          </div>
          <div class="col-3">
            <div class="row">
              <div class="col-4">
                <label for="311">DEA Exp<span  *ngIf="onPointForm.controls['311'].invalid|| onPointForm.controls['cmd_NA_01'].value==null" class="mandatory">*</span></label>
              </div>
              <div class="col-6">
                <div class="input-group">
                  <input (blur)="handleNAchange('cmd_NA_01',311)"   class="form-control"  name="311" [class.is-invalid]="(onPointForm.controls['311'].invalid&& onPointForm.controls['311'].touched )|| (onPointForm.controls['311'].invalid && isSubmit)"
                  

                  placeholder="MM/DD/YYYY" ngbDatepicker #d311="ngbDatepicker" appMaskInput [appMaskValue]="this.onPointForm.controls['311'].value"
                  placement="bottom" formControlName="311" (blur)="clearInputIfInvalid('311','cmd_NA_01')"  >
              <div class="input-group-append">
                  <button class="btn btn-outline-secondary calendar" (click)="d311.toggle()"
                      type="button"><i class="fas fa-calendar-alt"></i></button>
              </div>
            </div>
          
              <!-- <div class="mandatory"  *ngIf=" this.onPointForm.controls['311'].invalid && this.onPointForm.controls['311'].touched">

                <span
               class="form-text text-danger"
               *ngIf="this.onPointForm.controls['311'].errors.required">
                required.
             </span>
             <span
             class="form-text text-danger"
             *ngIf="this.onPointForm.controls['311'].errors.ngbDate?.invalid">
             Please Enter valid Date
           </span>
           </div> -->
           <div class="mandatory" *ngIf="onPointForm.controls['311'].invalid && (onPointForm.controls['311'].value==''|| onPointForm.controls['311'].value==null) " >
            <small >
            required
            </small>

        </div>
        <div  *ngIf="onPointForm.get('311').invalid ">
          <small class="text-danger" *ngIf="onPointForm.get('311').errors.invalidDateFormat">
            Only Date Format is allowed(MM/DD/YYYY)                </small>
          <small class="text-danger" *ngIf="onPointForm.get('311').errors.invalidMonth">
          Month is out of range
          </small>
          <small class="text-danger" *ngIf="onPointForm.get('311').errors.invalidDateyear">
            year is out of range(1900-2099)                  </small>
            <small class="text-danger" *ngIf="onPointForm.get('311').errors.invalidDateRange">
              date is out of range
              </small>
          </div>

              </div>
              <div class="col-2">
                <div class="form-check">
                  <input formControlName="cmd_NA_01" (ngModelChange)="handleNAchange('cmd_NA_01')"
                  class="form-check-input" type="checkbox" value="1" id="cmd_NA_01">
                  <label class="form-check-label" for="cmd_NA_01">
                    N/A
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row form-group">
          <div class="col-2"><label for="333"> CAQH Preview ID <span *ngIf="onPointForm.controls['cmd_NA_01'].value==null || onPointForm.controls['333'].invalid" class="mandatory">*</span></label></div>
          <div class="col-4">
            <input (blur)="handleNAchange('cmd_NA_01',333)" class="form-control" [class.is-invalid]=" onPointForm.controls['333'].invalid" id="333"
              name="333" placeholder="CAQH ID Number" formControlName="333" type="text" data-id="lname" />
            <div class="mandatory" *ngIf="onPointForm.controls['333'].invalid">
              <small >
                required
              </small>
            </div>
          </div>
          <div class="col-6">
            <a href=" www.caqhproview.com" class="mr-2"> www.caqhproview.com</a> Help Desk: 1-888-599-1771
          </div>
        </div>
        <div class="row form-group">
          <div class="col-2"><label for="334"> CAQH Username <span *ngIf="onPointForm.controls['cmd_NA_01'].value==null || onPointForm.controls['334'].invalid" class="mandatory">*</span></label></div>
          <div class="col-4">
            <input (blur)="handleNAchange('cmd_NA_01',334)" class="form-control" [class.is-invalid]="onPointForm.controls['334'].invalid" id="334"
              name="334" placeholder="CAQH Username" formControlName="334" type="text" data-id="lname" />
            <div class="mandatory" *ngIf="onPointForm.controls['334'].invalid">
              <small>
                required
              </small>
            </div>
          </div>
          <div class="col-2"><label for="335">CAQH Password <span *ngIf="onPointForm.controls['cmd_NA_01'].value==null || onPointForm.controls['335'].invalid" class="mandatory">*</span></label></div>
          <div class="col-4">
            <input (blur)="handleNAchange('cmd_NA_01',335)" class="form-control" id="335" name="335" placeholder="CAQH Password " formControlName="335"
              type="password" data-id="lname" [class.is-invalid]="onPointForm.controls['335'].invalid" />
            <div class="mandatory" *ngIf="onPointForm.controls['335'].invalid">
              <small >
                required
              </small>
            </div>
          </div>
        </div>
      </div>
      <div class="sectionThree">
        <h4>Section 3: Internship, Residency and Fellowship</h4>
        <div class="row form-group">
           <div class="col-2">
            <label for="517">Internship School <span *ngIf="onPointForm.controls['cmd_NA_02'].value==null || onPointForm.controls['517'].invalid"
                class="mandatory">*</span></label>
          </div>
          <div class="col-4">
            <input class="form-control" (blur)="handleNAchange('cmd_NA_02',517)" [class.is-invalid]="onPointForm.controls['517'].invalid" id="517" name="517"
              placeholder="Intern School" formControlName="517" type="text" data-id="lname" />
              <div class="mandatory" *ngIf="onPointForm.controls['517'].invalid">
                <small >
                  required
                </small>
              </div>
          </div> 
          <div class="col-1">
            <label for="2858">Specialty</label>
          </div>
          <div class="col-4">
            <ng-select [items]="specialty" bindLabel="name" placeholder="Select Specialty" bindValue="id"
              [labelForId]="527" formControlName="527">
            </ng-select>
          </div>
          <div class="col-1">
            <div class="form-check">
              <input (ngModelChange)="handleNAchange('cmd_NA_02')" class="form-check-input"
                formControlName="cmd_NA_02" type="checkbox" value="1" id="cmd_NA_02">
              <label class="form-check-label" for="cmd_NA_02">
                N/A
              </label>
            </div>
          </div>
        </div>
        <div class="row form-group">
          <div class="col-2">
            <label for="">Start Date: (mm/dd/yy)</label>
          </div>
          <div class="col-2">
            <div class="input-group">
              <input class="form-control onboard" placeholder="MM/DD/YYYY" appMaskInput [appMaskValue]="this.onPointForm.controls['524'].value" ngbDatepicker #d524="ngbDatepicker" id="524"
                name="524" formControlName="524" type="text" data-id="dob_date"   (blur)="clearInputIfInvalid('524')"
                />
              <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d524.toggle()" type="button">
                  <i class="fas fa-calendar-alt"></i>
                </button>
              </div>
            </div>
            <div  *ngIf="onPointForm.get('524').invalid ">
              <small class="text-danger" *ngIf="onPointForm.get('524').errors?.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY)
              </small>
              <small class="text-danger" *ngIf="onPointForm.get('524').errors?.invalidMonth">
              Month is out of range
              </small>
              <small class="text-danger" *ngIf="onPointForm.get('524').errors?.invalidDateyear">
                year is out of range(1900-2099)                      </small>
                <small class="text-danger" *ngIf="onPointForm.get('524').errors?.invalidDateRange">
                  date is out of range
                  </small>
              </div>
          </div>
          


          <div class="col-2">
            <label for="">End Date: (mm/dd/yy)</label>
          </div>
          <div class="col-2">
            <div class="input-group">
              <input class="form-control onboard" placeholder="MM/DD/YYYY"appMaskInput [appMaskValue]="this.onPointForm.controls['525'].value" ngbDatepicker #d525="ngbDatepicker" id="525"
                name="525" formControlName="525" type="text" data-id="dob_date"    (blur)="clearInputIfInvalid('525')"
                />
              <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d525.toggle()" type="button">
                  <i class="fas fa-calendar-alt"></i>
                </button>
              </div>
            </div>
            <div  *ngIf="onPointForm.get('525').invalid ">
              <small class="text-danger" *ngIf="onPointForm.get('525').errors?.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY)
              </small>
              <small class="text-danger" *ngIf="onPointForm.get('525').errors?.invalidMonth">
              Month is out of range
              </small>
              <small class="text-danger" *ngIf="onPointForm.get('525').errors?.invalidDateyear">
                year is out of range(1900-2099)                      </small>
                <small class="text-danger" *ngIf="onPointForm.get('525').errors?.invalidDateRange">
                  date is out of range
                  </small>
              </div>
          </div>
         
          <div class="col-2">
            <label for="">Complete Date: (mm/dd/yy)</label>
          </div>
          <div class="col-2">
            <div class="input-group">
              <input class="form-control onboard" placeholder="MM/DD/YYYY" appMaskInput [appMaskValue]="this.onPointForm.controls['526'].value" ngbDatepicker #d526="ngbDatepicker" id="526"
                name="526" formControlName="526" type="text" data-id="dob_date"    (blur)="clearInputIfInvalid('526')"
                />
              <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d526.toggle()" type="button">
                  <i class="fas fa-calendar-alt"></i>
                </button>
              </div>
            </div>
            <div  *ngIf="onPointForm.get('526').invalid ">
              <small class="text-danger" *ngIf="onPointForm.get('526').errors?.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY)
              </small>
              <small class="text-danger" *ngIf="onPointForm.get('526').errors?.invalidMonth">
              Month is out of range
              </small>
              <small class="text-danger" *ngIf="onPointForm.get('526').errors?.invalidDateyear">
                year is out of range(1900-2099)                      </small>
                <small class="text-danger" *ngIf="onPointForm.get('526').errors?.invalidDateRange">
                  date is out of range
                  </small>
              </div>
          </div>
         
        </div>
        <div class="row form-group">
          <div class="col-2">
            <label for="16">Address 1 </label>
          </div>
          <div class="col-4">
            <input class="form-control" id="518" name="518" formControlName="518" type="text" data-id="homeaddress1" />
            <div class="formLabel col-md-2" *ngIf="
                    onPointForm.controls['518'].touched &&
                    onPointForm.controls['518'].errors
                  ">
              <small class="text-danger" *ngIf="onPointForm.controls['518'].errors?.required">
                required
              </small>
            </div>
          </div>
          <div class="col-2">
            <label for="519">Address 2</label>
          </div>
          <div class="col-4">
            <input class="form-control" id="519" name="519" formControlName="519" type="text" data-id="homeaddress2" />
          </div>
        </div>
        <div class="row form-group">
          <div class="col-4">
            <div class="row">
              <div class="col-4">
                <label for="520">City</label>
              </div>
              <div class="col-8">
                <input class="form-control" id="520" name="520" formControlName="520" type="text" />
              </div>
            </div>
          </div>
          <div class="col-4">
            <div class="row">
              <div class="col-4 p-0 pl-2">
                <label for="522">State </label>
              </div>
              <div class="col-8">
                <ng-select [labelForId]="522" [items]="states" bindLabel="name" placeholder="Select State"
                  bindValue="id" formControlName="522">
                </ng-select>
              </div>
            </div>
          </div>
          <div class="col-4">
            <div class="row">
              <div class="col-4 p-0 pl-2">
                <label for="523">ZIP code
                </label>
              </div>
              <div class="col-8">
                <input class="form-control" id="523" name="523" formControlName="523" type="text" />
              </div>
            </div>
          </div>
        </div>
        <div class="row form-group">
          <div class="col-4">
            <div class="row">
              <div class="col-6"><label>Internship Director</label></div>
              <div class="col-6">
                <input class="form-control" id="528" name="528" placeholder="Intern Director " formControlName="528"
                  type="text" />
              </div>
            </div>
          </div>
          <div class="col-4">
            <div class="row">
              <div class="col-6"><label>Phone</label></div>
              <div class="col-6">
                <input [class.is-invalid]="
             (onPointForm.controls['1318'].touched &&
               onPointForm.controls['1318'].invalid) ||
              (onPointForm.controls['1318'].invalid && isSubmit)"type="text" class="form-control"  placeholder="Intern Director Phone "id="1318" name="1318" maxlength="12"
               [formControlName]="1318" (keyup)="handleNumber($event,1318)"/>
             
                <div class="mandatory" *ngIf="
                 onPointForm.controls['1318'].touched &&
                 onPointForm.controls['1318'].invalid ">
                   <small class="text-danger" *ngIf="onPointForm.controls['1318'].errors?.required">
                      Required 
                    </small>
                    <small class="text-danger" *ngIf="onPointForm.controls['1318'].errors?.pattern">
                     Required pattern xxx-xxx-xxxx
                    </small>
                </div>
              </div>
            </div>
          </div>
          <div class="col-4">
            <div class="row">
              <div class="col-6"><label>Fax</label></div>
              <div class="col-6">
                <input class="form-control" id="1319" name="1319" placeholder="Intern Director Fax"
                  formControlName="1319" type="text" />
              </div>
            </div>
          </div>
        </div>
        <div class="row pb-4 form-group">
          <div class="col-2">
            <label for="Department">Department</label>
          </div>
          <div class="col-4">
            <input class="form-control" id="2539" name="2539" placeholder="Internship Department  " formControlName="2539"
              type="text" />
          </div>
          <div class="col-2">
            <label for="Website">Website</label>
          </div>
          <div class="col-4">
            <input class="form-control" id="3462" name="3462" placeholder="Intern School Website  " formControlName="3462"
              type="text" />
          </div>
        </div>
        <div class="row pt-4 form-group">
          <div class="col-2">
            <label for="529">Residency School: <span *ngIf="onPointForm.controls['cmd_NA_03'].value==null||onPointForm.controls['529'].invalid" class="mandatory">*</span></label>
          </div>
          <div class="col-4">
            <input (blur)="handleNAchange('cmd_NA_03',529)" class="form-control" [class.is-invalid]="onPointForm.controls['529'].invalid" id="529"
              name="529" placeholder="Residency School" formControlName="529" type="text" data-id="lname" />
            <div class="mandatory" *ngIf="
            onPointForm.controls['529'].invalid
            ">
              <small >
                required
              </small>
            </div>
          </div>
          <div class="col-1">
            <label for="">Specialty</label>
          </div>
          <div class="col-4">
            <ng-select [items]="specialty" bindLabel="name" placeholder="Select Specialty" bindValue="id"
              [labelForId]="530" formControlName="530">
            </ng-select>
          </div>
          <div class="col-1">
            <div class="form-check">
              <input formControlName="cmd_NA_03" (ngModelChange)="handleNAchange('cmd_NA_03')" class="form-check-input" type="checkbox" value="1" id="cmd_NA_03">
              <label class="form-check-label" for="cmd_NA_03">
                N/A
              </label>
            </div>
          </div>
        </div>
        <div class="row form-group">
          <div class="col-2">
            <label for="">Start Date: (mm/dd/yy)</label>
          </div>
          <div class="col-2">
            <div class="input-group">
              <input class="form-control onboard" placeholder="MM/DD/YYYY" ngbDatepicker #d537="ngbDatepicker" id="537"
                name="537" formControlName="537" appMaskInput [appMaskValue]="this.onPointForm.controls['537'].value" type="text" data-id="dob_date"   (blur)="clearInputIfInvalid('537')"  />
              <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d537.toggle()" type="button">
                  <i class="fas fa-calendar-alt"></i>
                </button>
              </div>
            </div>
            <div  *ngIf="onPointForm.get('537').invalid ">
              <small class="text-danger" *ngIf="onPointForm.get('537').errors?.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY)
              </small>
              <small class="text-danger" *ngIf="onPointForm.get('537').errors?.invalidMonth">
              Month is out of range
              </small>
              <small class="text-danger" *ngIf="onPointForm.get('537').errors?.invalidDateyear">
                year is out of range(1900-2099)                      </small>
                <small class="text-danger" *ngIf="onPointForm.get('537').errors?.invalidDateRange">
                  date is out of range
                  </small>
              </div>
          </div>
          
          <div class="col-2">
            <label for="">End Date: (mm/dd/yy)</label>
          </div>
          <div class="col-2">
            <div class="input-group">
              <input class="form-control onboard" placeholder="MM/DD/YYYY" ngbDatepicker #d538="ngbDatepicker" id="538"
                name="538" formControlName="538" type="text" appMaskInput [appMaskValue]="this.onPointForm.controls['538'].value" data-id="dob_date"  (blur)="clearInputIfInvalid('538')" />
              <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d538.toggle()" type="button">
                  <i class="fas fa-calendar-alt"></i>
                </button>
              </div>
            </div>
            <div  *ngIf="onPointForm.get('538').invalid ">
              <small class="text-danger" *ngIf="onPointForm.get('538').errors?.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY)
              </small>
              <small class="text-danger" *ngIf="onPointForm.get('538').errors?.invalidMonth">
              Month is out of range
              </small>
              <small class="text-danger" *ngIf="onPointForm.get('538').errors?.invalidDateyear">
                year is out of range(1900-2099)                      </small>
                <small class="text-danger" *ngIf="onPointForm.get('538').errors?.invalidDateRange">
                  date is out of range
                  </small>
              </div>
          </div>
          
          <div class="col-2">
            <label for="">Complete Date: (mm/dd/yy)</label>
          </div>
          <div class="col-2">
            <div class="input-group">
              <input class="form-control onboard" placeholder="MM/DD/YYYY"  appMaskInput [appMaskValue]="this.onPointForm.controls['539'].value"ngbDatepicker #d539="ngbDatepicker" id="539"
                name="539" formControlName="539" type="text" data-id="dob_date"  (blur)="clearInputIfInvalid('539')"  />
              <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d539.toggle()" type="button">
                  <i class="fas fa-calendar-alt"></i>
                </button>
              </div>
            </div>
            <div  *ngIf="onPointForm.get('539').invalid ">
              <small class="text-danger" *ngIf="onPointForm.get('539').errors?.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY)
              </small>
              <small class="text-danger" *ngIf="onPointForm.get('539').errors?.invalidMonth">
              Month is out of range
              </small>
              <small class="text-danger" *ngIf="onPointForm.get('539').errors?.invalidDateyear">
                year is out of range(1900-2099)                      </small>
                <small class="text-danger" *ngIf="onPointForm.get('539').errors?.invalidDateRange">
                  date is out of range
                  </small>
              </div>
          </div>
          

        </div>
        <div class="row form-group">
          <div class="col-2">
            <label for="16">Address 1 </label>
          </div>
          <div class="col-4">
            <input class="form-control" id="531" name="531" formControlName="531" type="text" data-id="homeaddress1" />
            <div class="formLabel col-md-2" *ngIf="
                    onPointForm.controls['531'].touched &&
                    onPointForm.controls['531'].errors
                  ">
              <small class="text-danger" *ngIf="onPointForm.controls['531'].errors?.required">
                required
              </small>
            </div>
          </div>
          <div class="col-2">
            <label for="17">Address 2</label>
          </div>
          <div class="col-4">
            <input class="form-control" id="1498" name="1498" formControlName="1498" type="text"
              data-id="homeaddress2" />
          </div>
        </div>
        <div class="row form-group">
          <div class="col-4">
            <div class="row">
              <div class="col-4">
                <label for="533">City </label>
              </div>
              <div class="col-8">
                <input class="form-control" id="533" name="533" formControlName="533" type="text" />
              </div>
            </div>
          </div>
          <div class="col-4">
            <div class="row">
              <div class="col-4 p-0 pl-2">
                <label for="535">State</label>
              </div>
              <div class="col-8">
                <ng-select [labelForId]="535" [items]="states" bindLabel="name" placeholder="State or Country"
                  bindValue="id" formControlName="535">
                </ng-select>
              </div>
            </div>
          </div>
          <div class="col-4">
            <div class="row">
              <div class="col-4 p-0 pl-2">
                <label for="536">ZIP code</label>
              </div>
              <div class="col-8">
                <input class="form-control" id="536" name="536" formControlName="536" type="text" />
              </div>
            </div>
          </div>
        </div>
        <div class="row form-group">
          <div class="col-4">
            <div class="row">
              <div class="col-6"><label>Residency Director</label></div>
              <div class="col-6">
                <input class="form-control" id="540" name="540" placeholder="Residency Director " formControlName="540"
                  type="text" />
              </div>
            </div>
          </div>
          <div class="col-4">
            <div class="row">
              <div class="col-6"><label>Phone</label></div>
              <div class="col-6">
                <input [class.is-invalid]="
                (onPointForm.controls['1316'].touched &&
                  onPointForm.controls['1316'].invalid) ||
                 (onPointForm.controls['1316'].invalid && isSubmit)"type="text" class="form-control"  placeholder="Residency Director Phone "id="1316" name="1316" maxlength="12"
                  [formControlName]="1316" (keyup)="handleNumber($event,1316)"/>
                
                   <div class="mandatory" *ngIf="
                    onPointForm.controls['1316'].touched &&
                    onPointForm.controls['1316'].invalid ">
                      <small class="text-danger" *ngIf="onPointForm.controls['1316'].errors?.required">
                         Required 
                       </small>
                       <small class="text-danger" *ngIf="onPointForm.controls['1316'].errors?.pattern">
                        Required pattern xxx-xxx-xxxx
                       </small>
                   </div>
              </div>
            </div>
          </div>
          <div class="col-4">
            <div class="row">
              <div class="col-6"><label>Fax</label></div>
              <div class="col-6">
                <input class="form-control" id="1317" name="1317" placeholder="Residency Director Fax"
                  formControlName="1317" type="text" />
              </div>
            </div>
          </div>
        </div>
        <div class="row pb-4 form-group">
          <div class="col-2">
            <label for="Department">Department</label>
          </div>
          <div class="col-4">
            <input class="form-control" id="2540" name="2540" placeholder="Residency Department  " formControlName="2540"
              type="text" />
          </div>
          <div class="col-2">
            <label for="Website">Website</label>
          </div>
          <div class="col-4">
            <input class="form-control" id="3464" name="3464" placeholder="Residency School Website  " formControlName="3464"
              type="text" />
          </div>
        </div>
        <div class="row pt-4 form-group">
          <div class="col-2">
            <label for="add_1_1170">Residency School: <span *ngIf="onPointForm.controls['cmd_NA_04'].value==null || onPointForm.controls['add_1_1170'].invalid" class="mandatory">*</span></label>
          </div>
          <div class="col-4">
            <input  (blur)="handleNAchange('cmd_NA_04','add_1_1170')" class="form-control" [class.is-invalid]="onPointForm.controls['add_1_1170'].invalid"
              id="add_1_1170" name="add_1_1170" placeholder="Additional Residency Schools" formControlName="add_1_1170"
              type="text" data-id="lname" />
            <div class="mandatory" *ngIf="onPointForm.controls['add_1_1170'].invalid">
              <small >
                required
              </small>
            </div>
          </div>
          <div class="col-1">
            <label for="add_1_1171">Specialty</label>
          </div>
          <div class="col-4">
            <ng-select [items]="specialty" bindLabel="name" placeholder="Select Specialty" bindValue="id"
              [labelForId]="'add_1_1171'" formControlName="add_1_1171">
            </ng-select>
          </div>
          <div class="col-1">
            <div class="form-check">
              <input (ngModelChange)="handleNAchange('cmd_NA_04')" class="form-check-input" type="checkbox" formControlName="cmd_NA_04" value="1" id="cmd_NA_04">
              <label class="form-check-label" for="cmd_NA_04">
                N/A
              </label>
            </div>
          </div>
        </div>
        <div class="row form-group">
          <div class="col-2">
            <label for="add_1_1178">Start Date: (mm/dd/yy)</label>
          </div>
          <div class="col-2">
            <div class="input-group">
              <input class="form-control onboard" placeholder="MM/DD/YYYY"  appMaskInput [appMaskValue]="this.onPointForm.controls['add_1_1178'].value"ngbDatepicker #add_1_1178="ngbDatepicker"
                id="add_1_1178" name="add_1_1178" formControlName="add_1_1178" type="text" data-id="dob_date"  (blur)="clearInputIfInvalid('add_1_1178')"  />
              <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="add_1_1178.toggle()" type="button">
                  <i class="fas fa-calendar-alt"></i>
                </button>
              </div>
            </div>
            <div  *ngIf="onPointForm.get('add_1_1178').invalid ">
              <small class="text-danger" *ngIf="onPointForm.get('add_1_1178').errors?.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY)
              </small>
              <small class="text-danger" *ngIf="onPointForm.get('add_1_1178').errors?.invalidMonth">
              Month is out of range
              </small>
              <small class="text-danger" *ngIf="onPointForm.get('add_1_1178').errors?.invalidDateyear">
                year is out of range(1900-2099)                      </small>
                <small class="text-danger" *ngIf="onPointForm.get('add_1_1178').errors?.invalidDateRange">
                  date is out of range
                  </small>
              </div>
          </div>
          
          <div class="col-2">
            <label for="add_1_1179">End Date: (mm/dd/yy)</label>
          </div>
          <div class="col-2">
            <div class="input-group">
              <input class="form-control onboard" placeholder="MM/DD/YYYY" appMaskInput [appMaskValue]="this.onPointForm.controls['add_1_1179'].value"ngbDatepicker #add_1_1179="ngbDatepicker"
                id="add_1_1179" name="add_1_1179" formControlName="add_1_1179" type="text" data-id="dob_date" (blur)="clearInputIfInvalid('add_1_1179')"  />
              <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="add_1_1179.toggle()" type="button">
                  <i class="fas fa-calendar-alt"></i>
                </button>
              </div>
            </div>
            <div  *ngIf="onPointForm.get('add_1_1179').invalid ">
              <small class="text-danger" *ngIf="onPointForm.get('add_1_1179').errors?.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY)
              </small>
              <small class="text-danger" *ngIf="onPointForm.get('add_1_1179').errors?.invalidMonth">
              Month is out of range
              </small>
              <small class="text-danger" *ngIf="onPointForm.get('add_1_1179').errors?.invalidDateyear">
                year is out of range(1900-2099)                      </small>
                <small class="text-danger" *ngIf="onPointForm.get('add_1_1179').errors?.invalidDateRange">
                  date is out of range
                  </small>
              </div>
          </div>
         
          <div class="col-2">
            <label for="add_1_1180">Complete Date: (mm/dd/yy)</label>
          </div>
          <div class="col-2">
            <div class="input-group">
              <input class="form-control onboard" placeholder="MM/DD/YYYY"  appMaskInput [appMaskValue]="this.onPointForm.controls['add_1_1180'].value" ngbDatepicker #add_1_1180="ngbDatepicker"
                id="add_1_1180" name="add_1_1180" formControlName="add_1_1180" type="text" data-id="dob_date" (blur)="clearInputIfInvalid('add_1_1180')"   />
              <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="add_1_1180.toggle()" type="button">
                  <i class="fas fa-calendar-alt"></i>
                </button>
              </div>
            </div>
            <div  *ngIf="onPointForm.get('add_1_1180').invalid ">
              <small class="text-danger" *ngIf="onPointForm.get('add_1_1180').errors?.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY)
              </small>
              <small class="text-danger" *ngIf="onPointForm.get('add_1_1180').errors?.invalidMonth">
              Month is out of range
              </small>
              <small class="text-danger" *ngIf="onPointForm.get('add_1_1180').errors?.invalidDateyear">
                year is out of range(1900-2099)                      </small>
                <small class="text-danger" *ngIf="onPointForm.get('add_1_1180').errors?.invalidDateRange">
                  date is out of range
                  </small>
              </div>
          </div>
     
        </div>
        <div class="row form-group">
          <div class="col-2">
            <label for="add_1_1172">Address 1</label>
          </div>
          <div class="col-4">
            <input class="form-control" id="add_1_1172" name="add_1_1172" formControlName="add_1_1172" type="text" />
          </div>
          <div class="col-2">
            <label for="add_1_1173">Address 2</label>
          </div>
          <div class="col-4">
            <input class="form-control" id="add_1_1173" name="add_1_1173" formControlName="add_1_1173" type="text" />
          </div>
        </div>
        <div class="row form-group">
          <div class="col-4">
            <div class="row">
              <div class="col-4 ">
                <label for="add_1_1174">City</label>
              </div>
              <div class="col-8">
                <input class="form-control" id="add_1_1174" name="add_1_1174" formControlName="add_1_1174"
                  type="text" />
              </div>
            </div>
          </div>
          <div class="col-4">
            <div class="row">
              <div class="col-4 p-0 pl-2">
                <label for="add_1_1176">State </label>
              </div>
              <div class="col-8">
                <ng-select [labelForId]="'add_1_1176'" [items]="states" bindLabel="name" placeholder="Select State"
                  bindValue="id" formControlName="add_1_1176">
                </ng-select>
              </div>
            </div>
          </div>
          <div class="col-4">
            <div class="row">
              <div class="col-4 p-0 pl-2">
                <label for="add_1_1177">ZIP code</label>
              </div>
              <div class="col-8">
                <input class="form-control" id="add_1_1177" name="add_1_1177" formControlName="add_1_1177"
                  type="text" />
              </div>
            </div>
          </div>
        </div>
        <div class="row form-group">
          <div class="col-4">
            <div class="row">
              <div class="col-6"><label for="add_1_1181">Residency Director </label>
              </div>
              <div class="col-6">
                <input class="form-control" id="add_1_1181" name="add_1_1181"
                  placeholder="Additional Residency Director" formControlName="add_1_1181" type="text" />
              </div>
            </div>
          </div>
          <div class="col-4">
            <div class="row">
              <div class="col-6"><label for="add_1_1320">Phone</label></div>
              <div class="col-6">
                
                  <input [class.is-invalid]="
                (onPointForm.controls['add_1_1320'].touched &&
                  onPointForm.controls['add_1_1320'].invalid) ||
                 (onPointForm.controls['add_1_1320'].invalid && isSubmit)"type="text" class="form-control"  placeholder="Additional Residency Director Phone "id="add_1_1320" name="add_1_1320" maxlength="12"
                 formControlName="add_1_1320" (keyup)="handleNumber($event,'add_1_1320')"/>
                
                   <div class="mandatory" *ngIf="
                    onPointForm.controls['add_1_1320'].touched &&
                    onPointForm.controls['add_1_1320'].invalid ">
                      <small class="text-danger" *ngIf="onPointForm.controls['add_1_1320'].errors?.required">
                         Required 
                       </small>
                       <small class="text-danger" *ngIf="onPointForm.controls['add_1_1320'].errors?.pattern">
                        Required pattern xxx-xxx-xxxx
                       </small>
                   </div>
              </div>
            </div>
          </div>
          <div class="col-4">
            <div class="row">
              <div class="col-6"><label for=add_1_1321>Fax</label></div>
              <div class="col-6">
                <input class="form-control" id="add_1_1321" name="add_1_1321"
                  placeholder="Additional Residency Director Fax" formControlName="add_1_1321" type="text" />
              </div>
            </div>
          </div>
        </div>
        <div class="row pb-4 form-group">
          <div class="col-2">
            <label for="add_1_2541">Department</label>
          </div>
          <div class="col-4">
            <input class="form-control" id="add_1_2541" name="add_1_2541"
              placeholder="Additional Residency Department  " formControlName="add_1_2541" type="text" />
          </div>
          <div class="col-2">
            <label for="add_1_3465">Website</label>
          </div>
          <div class="col-4">
            <input class="form-control" id="add_1_3465" name="add_1_3465"
              placeholder="Additional Residency School Website  " formControlName="add_1_3465" type="text" />
          </div>
        </div>
        <div class="row pt-4 form-group">
          <div class="col-2">
            <label for="add_2_1170">Residency School: <span *ngIf="onPointForm.controls['cmd_NA_05'].value==null || onPointForm.controls['add_2_1170'].invalid" class="mandatory">*</span></label>
          </div>
          <div class="col-4">
            <input (blur)="handleNAchange('cmd_NA_05','add_2_1170')" class="form-control" [class.is-invalid]="onPointForm.controls['add_2_1170'].invalid"
              id="add_2_1170" name="add_2_1170" placeholder="Additional Residency Schools" formControlName="add_2_1170"
              type="text" data-id="lname" />
            <div class="mandatory" *ngIf="onPointForm.controls['add_2_1170'].invalid">
              <small >
                required
              </small>
            </div>
          </div>
          <div class="col-1">
            <label for="add_2_1171">Specialty</label>
          </div>
          <div class="col-4">
            <ng-select [items]="specialty" bindLabel="name" placeholder="Select Specialty" bindValue="id"
              [labelForId]="'add_2_1171'" formControlName="add_2_1171">
            </ng-select>
          </div>
          <div class="col-1">
            <div class="form-check">
              <input (ngModelChange)="handleNAchange('cmd_NA_05')" class="form-check-input" formControlName="cmd_NA_05" type="checkbox" value="1" id="cmd_NA_05">
              <label class="form-check-label" for="cmd_NA_05">
                N/A
              </label>
            </div>
          </div>
        </div>
        <div class="row form-group">
          <div class="col-2">
            <label for="add_2_1178">Start Date: (mm/dd/yy)</label>
          </div>
          <div class="col-2">
            <div class="input-group">
              <input class="form-control onboard" placeholder="MM/DD/YYYY" appMaskInput [appMaskValue]="this.onPointForm.controls['add_2_1178'].value" ngbDatepicker #add_2_1178="ngbDatepicker"
                id="add_2_1178" name="add_2_1178" formControlName="add_2_1178" type="text" data-id="dob_date" (blur)="clearInputIfInvalid('add_2_1178')"   />
              <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="add_2_1178.toggle()" type="button">
                  <i class="fas fa-calendar-alt"></i>
                </button>
              </div>
            </div>
            <div  *ngIf="onPointForm.get('add_2_1178').invalid ">
              <small class="text-danger" *ngIf="onPointForm.get('add_2_1178').errors?.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY)
              </small>
              <small class="text-danger" *ngIf="onPointForm.get('add_2_1178').errors?.invalidMonth">
              Month is out of range
              </small>
              <small class="text-danger" *ngIf="onPointForm.get('add_2_1178').errors?.invalidDateyear">
                year is out of range(1900-2099)                      </small>
                <small class="text-danger" *ngIf="onPointForm.get('add_2_1178').errors?.invalidDateRange">
                  date is out of range
                  </small>
              </div>
          </div>
          
          <div class="col-2">
            <label for="add_2_1179">End Date: (mm/dd/yy)</label>
          </div>
          <div class="col-2">
            <div class="input-group">
              <input class="form-control onboard" placeholder="MM/DD/YYYY"  appMaskInput [appMaskValue]="this.onPointForm.controls['add_2_1179'].value"ngbDatepicker #add_2_1179="ngbDatepicker"
                id="add_2_1179" name="add_2_1179" formControlName="add_2_1179" type="text" data-id="dob_date" (blur)="clearInputIfInvalid('add_2_1179')"   />
              <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="add_2_1179.toggle()" type="button">
                  <i class="fas fa-calendar-alt"></i>
                </button>
              </div>
            </div>
            <div  *ngIf="onPointForm.get('add_2_1179').invalid ">
              <small class="text-danger" *ngIf="onPointForm.get('add_2_1179').errors?.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY)
              </small>
              <small class="text-danger" *ngIf="onPointForm.get('add_2_1179').errors?.invalidMonth">
              Month is out of range
              </small>
              <small class="text-danger" *ngIf="onPointForm.get('add_2_1179').errors?.invalidDateyear">
                year is out of range(1900-2099)                      </small>
                <small class="text-danger" *ngIf="onPointForm.get('add_2_1179').errors?.invalidDateRange">
                  date is out of range
                  </small>
              </div>
          </div>
          
          <div class="col-2">
            <label for="add_2_1180">Complete Date: (mm/dd/yy)</label>
          </div>
          <div class="col-2">
            <div class="input-group">
              <input class="form-control onboard" placeholder="MM/DD/YYYY"  appMaskInput [appMaskValue]="this.onPointForm.controls['add_2_1180'].value" ngbDatepicker #add_2_1180="ngbDatepicker"
                id="add_2_1180" name="add_2_1180" formControlName="add_2_1180" type="text" data-id="dob_date"  (blur)="clearInputIfInvalid('add_2_1180')"  />
              <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="add_2_1180.toggle()" type="button">
                  <i class="fas fa-calendar-alt"></i>
                </button>
              </div>
            </div>
            <div  *ngIf="onPointForm.get('add_2_1180').invalid ">
              <small class="text-danger" *ngIf="onPointForm.get('add_2_1180').errors?.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY)
              </small>
              <small class="text-danger" *ngIf="onPointForm.get('add_2_1180').errors?.invalidMonth">
              Month is out of range
              </small>
              <small class="text-danger" *ngIf="onPointForm.get('add_2_1180').errors?.invalidDateyear">
                year is out of range(1900-2099)                      </small>
                <small class="text-danger" *ngIf="onPointForm.get('add_2_1180').errors?.invalidDateRange">
                  date is out of range
                  </small>
              </div>
          </div>
          
        </div>
        <div class="row form-group">
          <div class="col-2">
            <label for="add_2_1172">Address 1</label>
          </div>
          <div class="col-4">
            <input class="form-control" id="add_2_1172" name="add_2_1172" formControlName="add_2_1172" type="text" />
          </div>
          <div class="col-2">
            <label for="add_2_1173">Address 2</label>
          </div>
          <div class="col-4">
            <input class="form-control" id="add_2_1173" name="add_2_1173" formControlName="add_2_1173" type="text" />
          </div>
        </div>
        <div class="row form-group">
          <div class="col-4">
            <div class="row">
              <div class="col-4">
                <label for="add_2_1174">City</label>
              </div>
              <div class="col-8">
                <input class="form-control" id="add_2_1174" name="add_2_1174" formControlName="add_2_1174"
                  type="text" />
              </div>
            </div>
          </div>
          <div class="col-4">
            <div class="row">
              <div class="col-4 p-0 pl-2">
                <label for="add_2_1176">State </label>
              </div>
              <div class="col-8">
                <ng-select [labelForId]="'add_2_1176'" [items]="states" bindLabel="name" placeholder="Select State"
                  bindValue="id" formControlName="add_2_1176">
                </ng-select>
              </div>
            </div>
          </div>
          <div class="col-4">
            <div class="row">
              <div class="col-4 p-0 pl-2">
                <label for="add_2_1177">ZIP</label>
              </div>
              <div class="col-8">
                <input class="form-control" id="add_2_1177" name="add_2_1177" formControlName="add_2_1177"
                  type="text" />
              </div>
            </div>
          </div>
        </div>
        <div class="row form-group">
          <div class="col-4">
            <div class="row">
              <div class="col-6"><label for="add_2_1181">Residency Director </label>
              </div>
              <div class="col-6">
                <input class="form-control" id="add_2_1181" name="add_2_1181"
                  placeholder="Additional Residency Director" formControlName="add_2_1181" type="text" />
              </div>
            </div>
          </div>
          <div class="col-4">
            <div class="row">
              <div class="col-6"><label for="add_2_1320">Phone</label></div>
              <div class="col-6">
                <input [class.is-invalid]="
                (onPointForm.controls['add_2_1320'].touched &&
                  onPointForm.controls['add_2_1320'].invalid) ||
                 (onPointForm.controls['add_2_1320'].invalid && isSubmit)"type="text" class="form-control"  placeholder="Additional Residency Director Phone "id="add_2_1320" name="add_2_1320" maxlength="12"
                 formControlName="add_2_1320" (keyup)="handleNumber($event,'add_2_1320')"/>
                
                   <div class="mandatory" *ngIf="
                    onPointForm.controls['add_2_1320'].touched &&
                    onPointForm.controls['add_2_1320'].invalid ">
                      <small class="text-danger" *ngIf="onPointForm.controls['add_2_1320'].errors?.required">
                         Required 
                       </small>
                       <small class="text-danger" *ngIf="onPointForm.controls['add_2_1320'].errors?.pattern">
                        Required pattern xxx-xxx-xxxx
                       </small>
                   </div>
              </div>
            </div>
          </div>
          <div class="col-4">
            <div class="row">
              <div class="col-6"><label for=add_2_1321>Fax</label></div>
              <div class="col-6">
                <input class="form-control" id="add_2_1321" name="add_2_1321"
                  placeholder="Additional Residency Director Fax" formControlName="add_2_1321" type="text" />
              </div>
            </div>
          </div>
        </div>
        <div class="row pb-4 form-group">
          <div class="col-2">
            <label for="add_2_2541">Department</label>
          </div>
          <div class="col-4">
            <input class="form-control" id="add_2_2541" name="add_2_2541"
              placeholder="Additional Residency Department  " formControlName="add_2_2541" type="text" />
          </div>
          <div class="col-2">
            <label for="add_2_3465">Website</label>
          </div>
          <div class="col-4">
            <input class="form-control" id="add_2_3465" name="add_2_3465"
              placeholder="Additional Residency School Website  " formControlName="add_2_3465" type="text" />
          </div>
        </div>
        <div class="row pt-4 form-group">
          <div class="col-2">
            <label for="542">Fellowship School: <span *ngIf="onPointForm.controls['cmd_NA_06'].value==null || onPointForm.controls['542'].invalid" class="mandatory">*</span></label>

          </div>
          <div class="col-4">
            <input (blur)="handleNAchange('cmd_NA_06','542')" class="form-control" [class.is-invalid]="onPointForm.controls['542'].invalid" id="542"
              name="542" placeholder="Fellowship School" formControlName="542" type="text" data-id="lname" />
              <div class="mandatory" *ngIf="onPointForm.controls['542'].invalid">
                <small >
                  required
                </small>
              </div>
          </div>
          <div class="col-1">
            <label for="">Specialty</label>
          </div>
          <div class="col-4">
            <ng-select [items]="specialty" bindLabel="name" placeholder="Select Specialty" bindValue="id"
              [labelForId]="543" formControlName="543">
            </ng-select>
          </div>
          <div class="col-1">
            <div class="form-check">
              <input (ngModelChange)="handleNAchange('cmd_NA_06')" formControlName="cmd_NA_06"  class="form-check-input" type="checkbox" value="1" id="cmd_NA_06">
              <label class="form-check-label" for="cmd_NA_06">
                N/A
              </label>
            </div>
          </div>
        </div>
        <div class="row form-group">
          <div class="col-2">
            <label for="">Start Date: (mm/dd/yy)</label>
          </div>
          <div class="col-2">
            <div class="input-group">
              <input class="form-control onboard" placeholder="MM/DD/YYYY"  appMaskInput [appMaskValue]="this.onPointForm.controls['550'].value" ngbDatepicker #d550="ngbDatepicker" id="550"
                name="550" formControlName="550" type="text" data-id="dob_date"  (blur)="clearInputIfInvalid('550')"   />
              <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d550.toggle()" type="button">
                  <i class="fas fa-calendar-alt"></i>
                </button>
              </div>
            </div>
            <div  *ngIf="onPointForm.get('550').invalid ">
              <small class="text-danger" *ngIf="onPointForm.get('550').errors?.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY)
              </small>
              <small class="text-danger" *ngIf="onPointForm.get('550').errors?.invalidMonth">
              Month is out of range
              </small>
              <small class="text-danger" *ngIf="onPointForm.get('550').errors?.invalidDateyear">
                year is out of range(1900-2099)                      </small>
                <small class="text-danger" *ngIf="onPointForm.get('550').errors?.invalidDateRange">
                  date is out of range
                  </small>
              </div>
          </div>
          
          <div class="col-2">
            <label for="">End Date: (mm/dd/yy)</label>
          </div>
          <div class="col-2">
            <div class="input-group">
              <input class="form-control onboard" placeholder="MM/DD/YYYY" appMaskInput [appMaskValue]="this.onPointForm.controls['551'].value" ngbDatepicker #d551="ngbDatepicker" id="551"
                name="551" formControlName="551" type="text" data-id="dob_date"  (blur)="clearInputIfInvalid('551')" />
              <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d551.toggle()" type="button">
                  <i class="fas fa-calendar-alt"></i>
                </button>
              </div>
            </div>
            <div  *ngIf="onPointForm.get('551').invalid ">
              <small class="text-danger" *ngIf="onPointForm.get('551').errors?.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY)
              </small>
              <small class="text-danger" *ngIf="onPointForm.get('551').errors?.invalidMonth">
              Month is out of range
              </small>
              <small class="text-danger" *ngIf="onPointForm.get('551').errors?.invalidDateyear">
                year is out of range(1900-2099)                      </small>
                <small class="text-danger" *ngIf="onPointForm.get('551').errors?.invalidDateRange">
                  date is out of range
                  </small>
              </div>
          </div>
          
          <div class="col-2">
            <label for="">Complete Date: (mm/dd/yy)</label>
          </div>
          <div class="col-2">
            <div class="input-group">
              <input class="form-control onboard" placeholder="MM/DD/YYYY"  appMaskInput [appMaskValue]="this.onPointForm.controls['552'].value"ngbDatepicker #d552="ngbDatepicker" id="552"
                name="552" formControlName="552" type="text" data-id="dob_date"   (blur)="clearInputIfInvalid('552')" />
              <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d552.toggle()" type="button">
                  <i class="fas fa-calendar-alt"></i>
                </button>
              </div>
            </div>
            <div  *ngIf="onPointForm.get('552').invalid ">
              <small class="text-danger" *ngIf="onPointForm.get('552').errors?.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY)
              </small>
              <small class="text-danger" *ngIf="onPointForm.get('552').errors?.invalidMonth">
              Month is out of range
              </small>
              <small class="text-danger" *ngIf="onPointForm.get('552').errors?.invalidDateyear">
                year is out of range(1900-2099)                      </small>
                <small class="text-danger" *ngIf="onPointForm.get('552').errors?.invalidDateRange">
                  date is out of range
                  </small>
              </div>
          </div>
        
        </div>
        <div class="row form-group">
          <div class="col-2 ">
            <label for="544">Address 1 </label>
          </div>
          <div class="col-4">
            <input class="form-control" id="544" name="544" formControlName="544" type="text" data-id="homeaddress1" />
            <div class="formLabel col-md-2" *ngIf="
                    onPointForm.controls['544'].touched &&
                    onPointForm.controls['544'].errors
                  ">
              <small class="text-danger" *ngIf="onPointForm.controls['544'].errors?.required">
                required
              </small>
            </div>
          </div>
          <div class="col-2 ">
            <label for="17">Address 2</label>
          </div>
          <div class="col-4">
            <input class="form-control" id="545" name="545" formControlName="545" type="text" data-id="homeaddress2" />
          </div>
        </div>
        <div class="row form-group">
          <div class="col-4">
            <div class="row">
              <div class="col-4">
                <label for="546">City</label>
              </div>
              <div class="col-8">
                <input class="form-control" id="546" name="546" formControlName="546" type="text" />
              </div>
            </div>
          </div>
          <div class="col-4">
            <div class="row">
              <div class="col-4 p-0 pl-2">
                <label for="548">State </label>
              </div>
              <div class="col-8">
                <ng-select [labelForId]="548" [items]="states" bindLabel="name" placeholder="Select State"
                  bindValue="id" formControlName="548">
                </ng-select>
              </div>
            </div>
          </div>
          <div class="col-4">
            <div class="row">
              <div class="col-4 p-0 pl-2">
                <label for="549">ZIP code</label>
              </div>
              <div class="col-8">
                <input class="form-control" id="549" name="549" formControlName="549" type="text" />
              </div>
            </div>
          </div>
        </div>
        <div class="row form-group">
          <div class="col-4">
            <div class="row">
              <div class="col-6"><label>Fellowship Director</label></div>
              <div class="col-6">
                <input class="form-control" id="553" name="553" placeholder="Fellowship Director  "
                  formControlName="553" type="text" />
              </div>
            </div>
          </div>
          <div class="col-4">
            <div class="row">
              <div class="col-6"><label>Phone</label></div>
              <div class="col-6">
               
                  <input [class.is-invalid]="
                (onPointForm.controls['1322'].touched &&
                  onPointForm.controls['1322'].invalid) ||
                 (onPointForm.controls['1322'].invalid && isSubmit)"type="text" class="form-control"  placeholder="Fellowship Director Phone "id="1322" name="1322" maxlength="12"
                 formControlName="1322" (keyup)="handleNumber($event,1322)"/>
                
                   <div class="mandatory" *ngIf="
                    onPointForm.controls['1322'].touched &&
                    onPointForm.controls['1322'].invalid ">
                      <small class="text-danger" *ngIf="onPointForm.controls['1322'].errors?.required">
                         Required 
                       </small>
                       <small class="text-danger" *ngIf="onPointForm.controls['1322'].errors?.pattern">
                        Required pattern xxx-xxx-xxxx
                       </small>
                   </div>
              </div>
            </div>
          </div>
          <div class="col-4">
            <div class="row">
              <div class="col-6"><label>Fax</label></div>
              <div class="col-6">
                <input class="form-control" id="1323" name="1323" placeholder="Fellowship Director Fax "
                  formControlName="1323" type="text" />
              </div>
            </div>
          </div>
        </div>
        <div class="row form-group">
          <div class="col-2">
            <label for="Department">Department</label>
          </div>
          <div class="col-4">
            <input class="form-control" id="2542" name="2542" placeholder="Fellowship Department  "
              formControlName="2542" type="text" />
          </div>
          <div class="col-2">
            <label for="Website">Website</label>
          </div>
          <div class="col-4">
            <input class="form-control" id="3466" name="3466" placeholder="Fellowship School Website  "
              formControlName="3466" type="text" />
          </div>
        </div>
        <h5 class="mandatory warn">* Please let us know if you’ve completed any additional residencies programs
        </h5>
      </div>
      <div class="sectionFour">
        <h4>Section 4: Board Certification</h4>
        <div class="row pt-4 form-group">
          <div class="col-2">
            <label for="boardCertification">Board Certification: <span *ngIf="onPointForm.controls['cmd_NA_07'].value==null || onPointForm.controls['554'].invalid" class="mandatory">*</span></label>
          </div>
          <div class="col-4">
            <ng-select (blur)="handleNAchange('cmd_NA_07',554)"  [class.is-invalid]=" onPointForm.controls['554'].invalid"
              [items]="boardCertification" bindLabel="name" placeholder="Select Board Certification" bindValue="id"
              [labelForId]="'boardCertification'" formControlName="554">
            </ng-select>
            <div class="mandatory" *ngIf="onPointForm.controls['554'].invalid">
              <small >
                required
              </small>
            </div>
          </div>
          <div class="col-1">
            <label for="1234">Status: <span *ngIf="onPointForm.controls['cmd_NA_07'].value==null || onPointForm.controls['1234'].invalid" class="mandatory">*</span></label>
          </div>
          <div class="col-4">
            <ng-select (blur)="handleNAchange('cmd_NA_07',1234)" [class.is-invalid]="onPointForm.controls['1234'].invalid"
              [items]="boardStatus" bindLabel="name" placeholder="Select Status" bindValue="id" [labelForId]="'Status'"
              formControlName="1234">
            </ng-select>
            <div class="mandatory" *ngIf="onPointForm.controls['1234'].invalid">
              <small>
                required
              </small>
            </div>
          </div>
          <div class="col-1">
            <div class="form-check">
              <input formControlName="cmd_NA_07" (ngModelChange)="handleNAchange('cmd_NA_07')" class="form-check-input" type="checkbox" value="1" id="cmd_NA_07">
              <label class="form-check-label" for="cmd_NA_07">
                N/A
              </label>
            </div>
          </div>
        </div>
        <div class="row form-group">
          <div class="col-2">
            <label for="556">Specialty <span *ngIf="onPointForm.controls['cmd_NA_07'].value==null || onPointForm.controls['556'].invalid" class="mandatory">*</span></label>
          </div>
          <div class="col-10">
            <ng-select (blur)="handleNAchange('cmd_NA_07',556)" [class.is-invalid]="onPointForm.controls['556'].invalid"
              [items]="specialty" bindLabel="name" placeholder="Select Specialty" bindValue="id"
              [labelForId]="'specialty'" formControlName="556">
            </ng-select>
            <div class="mandatory" *ngIf="onPointForm.controls['556'].invalid">
              <small >
                required
              </small>
            </div>
          </div>
        </div>
        <div class="row form-group mb-4">
          <div class="col-2">
            <label for="557">Original Start Date: (mm/dd/yy)<span *ngIf="onPointForm.controls['557'].invalid||onPointForm.controls['cmd_NA_07'].value==null" class="mandatory">*</span></label>
          </div>
          <div class="col-4">
            <div class="input-group">
              <input (change)="handleNAchange('cmd_NA_07',557)"[class.is-invalid]="(onPointForm.controls['557'].invalid  && onPointForm.controls['557'].touched)|| (onPointForm.controls['557'].invalid && isSubmit)"
                class="form-control onboard" placeholder="MM/DD/YYYY" ngbDatepicker #d557="ngbDatepicker" id="557"
                name="557" formControlName="557"  data-id="dob_date"  appMaskInput [appMaskValue]="this.onPointForm.controls['557'].value"  (blur)="clearInputIfInvalid('557','cmd_NA_07')"   />
              <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d557.toggle()" type="button">
                  <i class="fas fa-calendar-alt"></i>
                </button>
              </div>
            </div>
           
            <div class="mandatory" *ngIf="onPointForm.controls['557'].invalid && (onPointForm.controls['557'].value==''|| onPointForm.controls['557'].value==null) " >
              <small >
              required
              </small>

          </div>
          <div  *ngIf="onPointForm.get('557').invalid ">
            <small class="text-danger" *ngIf="onPointForm.get('557').errors.invalidDateFormat">
              Only Date Format is allowed(MM/DD/YYYY)                </small>
            <small class="text-danger" *ngIf="onPointForm.get('557').errors.invalidMonth">
            Month is out of range
            </small>
            <small class="text-danger" *ngIf="onPointForm.get('557').errors.invalidDateyear">
              year is out of range(1900-2099)                  </small>
              <small class="text-danger" *ngIf="onPointForm.get('557').errors.invalidDateRange">
                date is out of range
                </small>
            </div>
             
          </div>

          <div class="col-1">
            <label for="560">Exp Date: (mm/dd/yy) <span *ngIf="(onPointForm.controls['560'].invalid )" class="mandatory">*</span></label>
          </div>
          <div class="col-4">
            <div class="input-group">
              <input (blur)="handleNAchange('cmd_NA_07',560,'cmd_NA_007')"[class.is-invalid]="(onPointForm.controls['560'].invalid  && onPointForm.controls['560'].touched)|| (onPointForm.controls['560'].invalid && isSubmit)"
                class="form-control onboard" placeholder="MM/DD/YYYY" ngbDatepicker #d560="ngbDatepicker" id="560" appMaskInput [appMaskValue]="this.onPointForm.controls['560'].value"
                name="560" formControlName="560" type="text" data-id="dob_date"  (blur)="clearInputIfInvalid('560','cmd_NA_07')"  />
              <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d560.toggle()" type="button">
                  <i class="fas fa-calendar-alt"></i>
                </button>
              </div>
            </div>
            <div class="mandatory" *ngIf="onPointForm.controls['560'].invalid && (onPointForm.controls['560'].value==''|| onPointForm.controls['560'].value==null) " >
              <small >
              required
              </small>

          </div>
          <div  *ngIf="onPointForm.get('560').invalid ">
            <small class="text-danger" *ngIf="onPointForm.get('560').errors.invalidDateFormat">
              Only Date Format is allowed(MM/DD/YYYY)                </small>
            <small class="text-danger" *ngIf="onPointForm.get('560').errors.invalidMonth">
            Month is out of range
            </small>
            <small class="text-danger" *ngIf="onPointForm.get('560').errors.invalidDateyear">
              year is out of range(1900-2099)                  </small>
              <small class="text-danger" *ngIf="onPointForm.get('560').errors.invalidDateRange">
                date is out of range
                </small>
            </div>
            </div>
          
          <div class="col-1">
            
              <div class="form-check">
                <input formControlName="cmd_NA_007" (change)="handleNAchange('cmd_NA_007',560)" class="form-check-input" type="checkbox" value="1" id="cmd_NA_007"
                    for="cmd_NA_007"/>    
                    <label class="form-check-label" for="cmd_NA_007">
                      N/A
                    </label>     
             </div>
              
            </div>
          <!-- </div> -->
        </div>
        </div>

        <div class="row pt-4 form-group mt-4">
          <div class="col-2">
            <label for="boardCertification">Board Certification: <span *ngIf="onPointForm.controls['cmd_NA_08'].value==null || onPointForm.controls['561'].invalid" class="mandatory">*</span></label>
          </div>
          <div class="col-4">
            <ng-select (change)="handleNAchange('cmd_NA_08',561)" [class.is-invalid]="onPointForm.controls['561'].invalid"
              [items]="boardCertification" bindLabel="name" placeholder="Select Board Certification" bindValue="id"
              [labelForId]="'boardCertification'" formControlName="561">
            </ng-select>
            <div class="mandatory" *ngIf="onPointForm.controls['561'].invalid&& onPointForm.controls['561'].touched ">
              <small >
                required
              </small>
            </div>
          </div>
          <div class="col-1">
            <label for="Status">Status: <span *ngIf="onPointForm.controls['cmd_NA_08'].value==null || onPointForm.controls['1235'].invalid" class="mandatory">*</span></label>
          </div>
          <div class="col-4">
            <ng-select (blur)="handleNAchange('cmd_NA_08',1235)" [class.is-invalid]="onPointForm.controls['1235'].invalid"
              [items]="boardStatus" bindLabel="name" placeholder="Select Status" bindValue="id" [labelForId]="'Status'"
              formControlName="1235">
            </ng-select>
            <div class="mandatory" *ngIf="onPointForm.controls['1235'].invalid&& onPointForm.controls['1235'].touched ">
              <small>
                required
              </small>
            </div>
          </div>
          
          <div class="col-1">
            <div class="form-check">
              <input formControlName="cmd_NA_08" (ngModelChange)="handleNAchange('cmd_NA_08')" class="form-check-input" type="checkbox" value="1" id="cmd_NA_08">
              <label class="form-check-label" for="cmd_NA_08">
                N/A
              </label>
            </div>
          </div>
        </div>
        <div class="row form-group">
          <div class="col-2">
            <label for="specialty">Specialty <span *ngIf="onPointForm.controls['cmd_NA_08'].value==null || onPointForm.controls['563'].invalid" class="mandatory">*</span></label>
          </div>
          <div class="col-10">
            <ng-select (blur)="handleNAchange('cmd_NA_08',563)" [class.is-invalid]="onPointForm.controls['563'].invalid"
              [items]="specialtySecondary" bindLabel="name" placeholder="Select Specialty" bindValue="id"
              [labelForId]="'specialty'" formControlName="563">
            </ng-select>
            <div class="mandatory" *ngIf="onPointForm.controls['563'].invalid&& onPointForm.controls['563'].touched ">
              <small>
                required
              </small>
            </div>
          </div>
        </div>
        <div class="row form-group">
          <div class="col-2">
            <label for="">Original Start Date: (mm/dd/yy) <span *ngIf="onPointForm.controls['564'].invalid||onPointForm.controls['cmd_NA_08'].value==null" class="mandatory">*</span></label>
          </div>
          <div class="col-4">
            <div class="input-group">
              <input (blur)="handleNAchange('cmd_NA_08',564)" [class.is-invalid]="(onPointForm.controls['564'].invalid && onPointForm.controls['564'].touched )|| (onPointForm.controls['564'].invalid && isSubmit)"
                class="form-control onboard" placeholder="MM/DD/YYYY" ngbDatepicker #d564="ngbDatepicker" id="564" appMaskInput [appMaskValue]="this.onPointForm.controls['564'].value"
                name="564" formControlName="564" type="" 
                 data-id="dob_date" (blur)="clearInputIfInvalid('564','cmd_NA_08')" />
              <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d564.toggle()" type="button">
                  <i class="fas fa-calendar-alt"></i>
                </button>
              </div>
            </div>
            <div class="mandatory" *ngIf="onPointForm.controls['564'].invalid && (onPointForm.controls['564'].value==''|| onPointForm.controls['564'].value==null) " >
              <small >
              required
              </small>

          </div>
          <div  *ngIf="onPointForm.get('564').invalid ">
            <small class="text-danger" *ngIf="onPointForm.get('564').errors.invalidDateFormat">
              Only Date Format is allowed(MM/DD/YYYY)                </small>
            <small class="text-danger" *ngIf="onPointForm.get('564').errors.invalidMonth">
            Month is out of range
            </small>
            <small class="text-danger" *ngIf="onPointForm.get('564').errors.invalidDateyear">
              year is out of range(1900-2099)                  </small>
              <small class="text-danger" *ngIf="onPointForm.get('564').errors.invalidDateRange">
                date is out of range
                </small>
            </div>
             
            </div>
         
          <div class="col-1">
            <label for="567">Exp Date: (mm/dd/yy) <span  *ngIf="onPointForm.controls['567'].invalid" class="mandatory">*</span></label>
          </div>
          <div class="col-4">
            <div class="input-group">
              <input (blur)="handleNAchange('cmd_NA_08',567,'cmd_NA_008')"  [class.is-invalid]="(onPointForm.controls['567'].invalid && onPointForm.controls['567'].touched) || (onPointForm.controls['567'].invalid && isSubmit)"
                class="form-control onboard" placeholder="MM/DD/YYYY" ngbDatepicker #d567="ngbDatepicker" id="567" appMaskInput [appMaskValue]="this.onPointForm.controls['567'].value"
                name="567" formControlName="567" type="" data-id="dob_date" (blur)="clearInputIfInvalid('567','cmd_NA_08')" />
              <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d567.toggle()" type="button">
                  <i class="fas fa-calendar-alt"></i>
                </button>
              </div>
            </div>
            <div class="mandatory" *ngIf="onPointForm.controls['567'].invalid && (onPointForm.controls['567'].value==''|| onPointForm.controls['567'].value==null) " >
              <small >
              required
              </small>

          </div>
          <div  *ngIf="onPointForm.get('567').invalid ">
            <small class="text-danger" *ngIf="onPointForm.get('567').errors.invalidDateFormat">
              Only Date Format is allowed(MM/DD/YYYY)                </small>
            <small class="text-danger" *ngIf="onPointForm.get('567').errors.invalidMonth">
            Month is out of range
            </small>
            <small class="text-danger" *ngIf="onPointForm.get('567').errors.invalidDateyear">
              year is out of range(1900-2099)                  </small>
              <small class="text-danger" *ngIf="onPointForm.get('567').errors.invalidDateRange">
                date is out of range
                </small>
            </div>
          </div>

            
            <div class="col-1">
              <div class="form-check">
                <input formControlName="cmd_NA_008" (change)="handleNAchange('cmd_NA_008',567)" class="form-check-input" type="checkbox" value="1" id="cmd_NA_008">
                <label class="form-check-label" for="cmd_NA_008">
                  N/A
                </label>
              </div>
            </div>
             
          </div>
        <!-- </div> -->
     


      <div class="sectionFive">
        <h4>Section 5: Board Certification Attestation Form</h4>
        <h5 class="mandatory warn">
          *Please answer the following questions and submit via email any explanation form(s) if necessary.
        </h5>
        <div class="subPara">
          <span class="mr-3"> A</span>
          <p>
            1. If you are not currently certified, have you applied for the certification examination?
            <span>
              <div class="form-check-inline">
                <input class="form-check-input" formControlName="cmd_65" name="cmd_65" type="radio" value="1"
                  id="cmd_65">
                <label class="form-check-label" for="cmd_65">
                  Yes
                </label>
              </div>
            </span><span>
              <div class="form-check-inline">
                <input class="form-check-input" formControlName="cmd_65" name="cmd_65" type="radio" value="0"
                  id="cmd_65_1">
                <label class="form-check-label" for="cmd_65_1">
                  NO
                </label>
              </div>
            </span>
          </p>
        </div>
        <div class="subPara ml-4">
          <p class="subPara">
            2. If you have not applied for the certification examination, do you intend to apply for the certification examination?
            If yes, when?
            <span>
              <div class="form-check-inline">
                <input class="form-check-input" formControlName="cmd_63" name="cmd_63" type="radio" value="1"
                  id="cmd_63">
                <label class="form-check-label" for="cmd_63">
                  Yes
                </label>
              </div>
            </span><span>
              <div class="form-check-inline">
                <input class="form-check-input" formControlName="cmd_63" name="cmd_63" type="radio" value="0"
                  id="cmd_63_1">
                <label class="form-check-label" for="cmd_63_1">
                  NO
                </label>
              </div>
            </span>
            <span>
              <div class="form-inline">
                <label for="applyDate">Date to apply</label>
                <div class="input-group">
                  <input class="form-control onboard datepicker" placeholder="Date to apply" ngbDatepicker
                    #cmd_61="ngbDatepicker" id="cmd_61" name="cmd_61"  appMaskInput [appMaskValue]="this.onPointForm.controls['cmd_61'].value"formControlName="cmd_61"
                    type="text" data-id="mlp1dateeff_date"  (blur)="clearInputIfInvalid('cmd_61')"  />
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar " (click)="cmd_61.toggle()" type="button">
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
                <div  *ngIf="onPointForm.get('cmd_61').invalid ">
                  <small class="text-danger" *ngIf="onPointForm.get('cmd_61').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="onPointForm.get('cmd_61').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="onPointForm.get('cmd_61').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="onPointForm.get('cmd_61').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </div>
            </span>
          </p>
        </div>
      
        <div class="subPara ml-4">
          <p>
            3. If you have applied for the certification examination, have you been accepted to take the certification
            examination?
            <span>
              <div class="form-check-inline">
                <input class="form-check-input" formControlName="cmd_60" name="cmd_60" type="radio" value="1"
                  id="cmd_60">
                <label class="form-check-label" for="cmd_60">
                  Yes
                </label>
              </div>
            </span><span>
              <div class="form-check-inline">
                <input class="form-check-input" name="cmd_60" formControlName="cmd_60" type="radio" value="0"
                  id="cmd_60_1" />
                <label class="form-check-label" for="cmd_60_1">
                  NO
                </label>
              </div>
            </span>
          </p>
        </div>
        <div class="subPara ml-4">
          <p class="subPara">
            4. If you have been accepted, when do you intend to take the examination?
            <span>
              <div class="form-inline">
                <label for="applyDate">Date</label>
                <div class="input-group">
                  <input class="form-control onboard datepicker"  appMaskInput [appMaskValue]="this.onPointForm.controls['cmd_58'].value"placeholder="Select date" ngbDatepicker
                    #cmd_58="ngbDatepicker" id="cmd_58" name="cmd_58" formControlName="cmd_58"    (blur)="clearInputIfInvalid('cmd_58')"
                    type="text" data-id="mlp1dateeff_date"  />
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar " (click)="cmd_58.toggle()" type="button">
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
                <div  *ngIf="onPointForm.get('cmd_58').invalid ">
                  <small class="text-danger" *ngIf="onPointForm.get('cmd_58').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="onPointForm.get('cmd_58').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="onPointForm.get('cmd_58').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="onPointForm.get('cmd_58').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </div>
            </span>
          </p>
        </div>
       
        <div class="ml-4">
          <p>
            5. If you do not intend to apply for the certification examination, please give reason below:
            <span>
              <div class="form-group mt-2">
                <textarea id="cmd_57" class="form-control" formControlName="cmd_57" name="" id="" cols="30"
                  rows="10"></textarea>
              </div>
            </span>
          </p>
        </div>
        <div class="subPara ml-4">
          <p class="subPara">
            6. If you are not currently certified, please provide the expiration date of admissibility.
            <span>
              <div class="form-inline">
                <label for="applyDate">Date</label>
                <div class="input-group">
                  <input class="form-control onboard datepicker"  appMaskInput [appMaskValue]="this.onPointForm.controls['cmd_56'].value" placeholder="Select date" ngbDatepicker
                    #cmd_56="ngbDatepicker" id="cmd_56" name="cmd_56" formControlName="cmd_56" (blur)="clearInputIfInvalid('cmd_56')"
                    type="text" data-id="mlp1dateeff_date"/>
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar " (click)="cmd_56.toggle()" type="button">
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
                <div  *ngIf="onPointForm.get('cmd_56').invalid ">
                  <small class="text-danger" *ngIf="onPointForm.get('cmd_56').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="onPointForm.get('cmd_56').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="onPointForm.get('cmd_56').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="onPointForm.get('cmd_56').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </div>
            </span>
          </p>
        </div>
        
        <div class="subPara">
          <p>
            B. Have you ever had certification denied, revoked, limited, restricted, suspended, involuntarily
            relinquished, subject to
            stipulated or probationary conditions, received a letter of reprimand from a specialty Board, or is any
            such action
            currently pending? If yes, please give reason below.
            <span>
              <div class="form-check-inline">
                <input class="form-check-input" formControlName="cmd_55" name="cmd_55" type="radio" value="1"
                  id="cmd_55">
                <label class="form-check-label" for="cmd_55">
                  Yes
                </label>
              </div>
            </span><span>
              <div class="form-check-inline">
                <input class="form-check-input" formControlName="cmd_55" name="cmd_55" type="radio" value="0"
                  id="cmd_55_1">
                <label class="form-check-label" for="cmd_55_1">
                  NO
                </label>
              </div>
            </span>
            <span>
              <div class="form-inline">
                <label for="cmd_53">Date</label>
                <div class="input-group">
                  <input class="form-control onboard datepicker" appMaskInput [appMaskValue]="this.onPointForm.controls['cmd_53'].value" placeholder="Date" ngbDatepicker
                    #cmd_53="ngbDatepicker" id="cmd_53" name="cmd_53" formControlName="cmd_53" (blur)="clearInputIfInvalid('cmd_53')"
                    type="text" data-id="mlp1dateeff_date"  />
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar " (click)="cmd_53.toggle()" type="button">
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
                <div  *ngIf="onPointForm.get('cmd_53').invalid ">
                  <small class="text-danger" *ngIf="onPointForm.get('cmd_53').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="onPointForm.get('cmd_53').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="onPointForm.get('cmd_53').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="onPointForm.get('cmd_53').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </div>
              


              <div class="form-group mt-2">
                <textarea id="cmd_52" class="form-control" formControlName="cmd_52" name="" id="" cols="30"
                  rows="10"></textarea>
              </div>
            </span>
          </p>
        </div>
        <div class="subPara">
          <p>
            C. Have you ever voluntarily relinquished a certification, including any voluntary non-of a time renewal
            limited
            certification? If yes, please give reason below.
            <span>
              <div class="form-check-inline">
                <input class="form-check-input" name="cmd_51" formControlName="cmd_51" type="radio" value="1"
                  id="cmd_51" />
                <label class="form-check-label" for="cmd_51">
                  Yes
                </label>
              </div>
            </span><span>
              <div class="form-check-inline">
                <input class="form-check-input" name="cmd_51" formControlName="cmd_51" type="radio" value="0"
                  id="cmd_51_1" />
                <label class="form-check-label" for="cmd_51_1">
                  NO
                </label>
              </div>
            </span>
            <span>
              <div class="form-inline">
                <label for="cmd_49">Date</label>
                <div class="input-group">
                  <input class="form-control onboard datepicker"  appMaskInput [appMaskValue]="this.onPointForm.controls['cmd_49'].value"placeholder="Date" ngbDatepicker
                    #cmd_49="ngbDatepicker" id="cmd_49" name="cmd_49" formControlName="cmd_49" (blur)="clearInputIfInvalid('cmd_49')"
                    type="text" data-id="mlp1dateeff_date" />
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar " (click)="cmd_49.toggle()" type="button">
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div  *ngIf="onPointForm.get('cmd_49').invalid ">
                <small class="text-danger" *ngIf="onPointForm.get('cmd_49').errors?.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY)
                </small>
                <small class="text-danger" *ngIf="onPointForm.get('cmd_49').errors?.invalidMonth">
                Month is out of range
                </small>
                <small class="text-danger" *ngIf="onPointForm.get('cmd_49').errors?.invalidDateyear">
                  year is out of range(1900-2099)                      </small>
                  <small class="text-danger" *ngIf="onPointForm.get('cmd_49').errors?.invalidDateRange">
                    date is out of range
                    </small>
                </div>
              <div class="form-group mt-2">
                <textarea id="cmd_48" class="form-control" name="" id="" cols="30" formControlName="cmd_48"
                  rows="10"></textarea>
              </div>
            </span>
          </p>
        </div>
        <div class="subPara">
          <p>
            D. Have you ever failed a certification exam? If yes, please give reason below.
            <span>
              <div class="form-check-inline">
                <input class="form-check-input" name="cmd_47" formControlName="cmd_47" type="radio" value="1"
                  id="cmd_47" />
                <label class="form-check-label" for="cmd_47">
                  Yes
                </label>
              </div>
              <div class="form-check-inline">
                <input class="form-check-input" name="cmd_47" formControlName="cmd_47" type="radio" value="0"
                  id="cmd_47_1" />
                <label class="form-check-label" for="cmd_47_1">
                  NO
                </label>
              </div>
              <div class="form-group mt-2">
                <textarea id="cmd_45" class="form-control" name="" id="" cols="30" formControlName="cmd_45"
                  rows="10"></textarea>
              </div>
            </span>
          </p>
        </div>
      </div>
      <div class="sectionSix">
        <h4>Section 6: Professional References</h4>
        <h5 class="mandatory warn">
          *Please provide 3 peers who can attest on your behalf from the past 24 months. They will need to be a
          professional reference of equal or higher education level and all forms of contact are required.
        </h5>
        <div class="row mb-2 mt-4">
          <div class="col-2"><label for="">Reference Name <span class="mandatory">*</span></label></div>
          <div class="col-4 form-group">
            <input class="form-control" id="359" name="359" [class.is-invalid]="(onPointForm.controls['359'].touched &&
              onPointForm.controls['359'].invalid) || (onPointForm.controls['359'].invalid && isSubmit)"
              formControlName="359" type="text" data-id="lname" placeholder="Reference 1 Name" />
            <div class="mandatory" *ngIf=" onPointForm.controls['359'].touched && onPointForm.controls['359'].invalid">
              <small *ngIf="onPointForm.controls['359'].errors">
                required
              </small>
            </div>
          </div>
          <div class="col-2"><label for="">Title</label></div>
          <div class="col-4 form-group">
            <input class="form-control" id="2722" name="2722" formControlName="2722" type="text" data-id="lname" />
          </div>
          <div class="col-2"><label for="">Reference 1 Relationship</label></div>
          <div class="col-4 form-group">
            <input class="form-control" id="2721" name="2721" formControlName="2721" type="text" data-id="lname" />
          </div>
          <div class="col-2">
            <label for="368">Specialty</label>
          </div>
          <div class="col-4">
            <ng-select [items]="specialty" bindLabel="name" placeholder="Select Specialty" bindValue="id"
              [labelForId]="368" formControlName="368">
            </ng-select>
          </div>
          <div class="col-2">
            <label for="16">Address 1</label>
          </div>
          <div class="col-4">
            <input class="form-control" id="360" name="360" formControlName="360" type="text" data-id="360" />
          </div>
          <div class="col-2">
            <label for="add_2_253838">Address 2</label>
          </div>
          <div class="col-4">
            <input class="form-control" id="361" name="361" formControlName="361" type="text" data-id="361" />
          </div>
        </div>
        <div class="row form-group">
          <div class="col-4">
            <div class="row">
              <div class="col-4">
                <label for="362">City</label>
              </div>
              <div class="col-8">
                <input class="form-control" id="362" name="362" formControlName="362" type="text" />
              </div>
            </div>
          </div>
          <div class="col-4">
            <div class="row">
              <div class="col-4 p-0 pl-2">
                <label for="364">State </label>
              </div>
              <div class="col-8">
                <ng-select [labelForId]="364" [items]="states" bindLabel="name" placeholder="Select State"
                  bindValue="id" formControlName="364">
                </ng-select>
              </div>
            </div>
          </div>
          <div class="col-4">
            <div class="row">
              <div class="col-4 p-0 pl-2">
                <label for="365">ZIP
                </label>
              </div>
              <div class="col-8">
                <input class="form-control" id="365" name="365" formControlName="365" type="text" />
              </div>
            </div>
          </div>
        </div>
        <div class="row form-group">
          <div class="col-1"><label for="369">Phone</label> <span class="mandatory">*</span></div>
          <div class="col-3">
            <input [class.is-invalid]="
             (onPointForm.controls['369'].touched &&
               onPointForm.controls['369'].invalid) ||
              (onPointForm.controls['369'].invalid && isSubmit)"type="text" class="form-control" id="369" name="369" maxlength="12"
               [formControlName]="369" (keyup)="handleNumber($event,369)"/>
             
                <div class="mandatory" *ngIf="
                 onPointForm.controls['369'].touched &&
                 onPointForm.controls['369'].invalid ">
                   <small class="text-danger" *ngIf="onPointForm.controls['369'].errors?.required">
                      Required 
                    </small>
                    <small class="text-danger" *ngIf="onPointForm.controls['369'].errors?.pattern">
                     Required pattern xxx-xxx-xxxx
                    </small>
                </div>

          </div>
          <div class="col-1"><label for="Fax">Fax</label></div>
          <div class="col-7">
            <input class="form-control" id="370" name="370" formControlName="370" type="text" />
          </div>
        </div>
        <div class="row form-group">
          <div class="col-1"><label for="Phone">Email:</label> <span class="mandatory">*</span></div>
          <div class="col-3">
            <input [class.is-invalid]="(onPointForm.controls['371'].touched &&
              onPointForm.controls['371'].invalid) || (onPointForm.controls['371'].invalid && isSubmit)"
              class="form-control" id="371" name="371" formControlName="371" type="text" />
            <div class="mandatory" *ngIf=" onPointForm.controls['371'].touched && onPointForm.controls['371'].invalid">
              <small *ngIf="onPointForm.controls['371'].errors">
                required
              </small>
            </div>
          </div>
          <div class="col-3"><label for=""> Date of Association: From (mm/dd/yy)</label></div>
          <div class="col-2">
            <div class="input-group">
              <input class="form-control onboard datepicker"  appMaskInput [appMaskValue]="this.onPointForm.controls['366'].value" placeholder="Reference 1 Date of Association From " ngbDatepicker
                #d366="ngbDatepicker" id="366" name="366" formControlName="366" type="text" (blur)="clearInputIfInvalid('366')"
                data-id="mlp1dateeff_date" /> 
              <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar " (click)="d366.toggle()" type="button">
                  <i class="fas fa-calendar-alt"></i>
                </button>
              </div>
            </div>
            <div  *ngIf="onPointForm.get('366').invalid ">
              <small class="text-danger" *ngIf="onPointForm.get('366').errors?.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY)
              </small>
              <small class="text-danger" *ngIf="onPointForm.get('366').errors?.invalidMonth">
              Month is out of range
              </small>
              <small class="text-danger" *ngIf="onPointForm.get('366').errors?.invalidDateyear">
                year is out of range(1900-2099)                      </small>
                <small class="text-danger" *ngIf="onPointForm.get('366').errors?.invalidDateRange">
                  date is out of range
                  </small>
              </div>
          </div>
          
          <div class="col-1">
            <label for="">To (mm/dd/yy)</label>
          </div>
          <div class="col-2">
            <div class="input-group">
              <input class="form-control onboard datepicker"  appMaskInput [appMaskValue]="this.onPointForm.controls['367'].value" placeholder="Reference 1 Date of Association To " ngbDatepicker
                #d367="ngbDatepicker" id="367" name="367" formControlName="367" type="text" (blur)="clearInputIfInvalid('367')"
                data-id="mlp1dateeff_date" />
              <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar " (click)="d367.toggle()" type="button">
                  <i class="fas fa-calendar-alt"></i>
                </button>
              </div>
            </div>
            <div  *ngIf="onPointForm.get('367').invalid ">
              <small class="text-danger" *ngIf="onPointForm.get('367').errors?.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY)
              </small>
              <small class="text-danger" *ngIf="onPointForm.get('367').errors?.invalidMonth">
              Month is out of range
              </small>
              <small class="text-danger" *ngIf="onPointForm.get('367').errors?.invalidDateyear">
                year is out of range(1900-2099)                      </small>
                <small class="text-danger" *ngIf="onPointForm.get('367').errors?.invalidDateRange">
                  date is out of range
                  </small>
              </div>
          </div>
        
        </div>
        <div class="row mb-2 mt-4 pt-4">
          <div class="col-2"><label for="">Reference Name <span class="mandatory">*</span></label></div>
          <div class="col-4 form-group">
            <input class="form-control" id="372" name="372"
              [class.is-invalid]="(onPointForm.controls['372'].touched && onPointForm.controls['372'].invalid) || (onPointForm.controls['372'].invalid && isSubmit)"
              formControlName="372" type="text" data-id="lname" placeholder="Reference 2 Name" />
            <div class="mandatory" *ngIf=" onPointForm.controls['372'].touched && onPointForm.controls['372'].invalid">
              <small *ngIf="onPointForm.controls['372'].errors">
                required
              </small>
            </div>
          </div>
          <div class="col-2"><label for="">Title:</label></div>
          <div class="col-4 form-group">
            <input class="form-control" id="2723" name="2723" formControlName="2723" type="text" data-id="lname" />
          </div>
          <div class="col-2"><label for="">Reference 2 Relationship</label></div>
          <div class="col-4 form-group">
            <input class="form-control" id="2724" name="2724" formControlName="2724" type="text" data-id="lname" />
          </div>
          <div class="col-2">
            <label for="380">Specialty</label>
          </div>
          <div class="col-4">
            <ng-select [items]="specialty" bindLabel="name" placeholder="Select Specialty" bindValue="id"
              [labelForId]="380" formControlName="380">
            </ng-select>
          </div>
          <div class="col-2">
            <label for="373">Address 1 </label>
          </div>
          <div class="col-4">
            <input class="form-control" id="373" name="373" formControlName="373" type="text" data-id="homeaddress1" />
          </div>
          <div class="col-2">
            <label for="374">Address 2</label>
          </div>
          <div class="col-4">
            <input class="form-control" id="374" name="374" formControlName="374" type="text" data-id="homeaddress2" />
          </div>
        </div>
        <div class="row form-group">
          <div class="col-4">
            <div class="row">
              <div class="col-4">
                <label for="375">City</label>
              </div>
              <div class="col-8">
                <input class="form-control" id="375" name="375" formControlName="375" type="text" />
              </div>
            </div>
          </div>
          <div class="col-4">
            <div class="row">
              <div class="col-4 p-0 pl-2">
                <label for="377">State </label>
              </div>
              <div class="col-8">
                <ng-select [labelForId]="377" [items]="states" bindLabel="name" placeholder="Select State"
                  bindValue="id" formControlName="377">
                </ng-select>
              </div>
            </div>
          </div>
          <div class="col-4">
            <div class="row">
              <div class="col-4 p-0 pl-2">
                <label for="378">ZIP
                </label>
              </div>
              <div class="col-8">
                <input class="form-control" id="378" name="378" formControlName="378" type="text" />
              </div>
            </div>
          </div>
        </div>
        <div class="row form-group">
          <div class="col-1"><label for="Phone">Phone</label> <span class="mandatory">*</span></div>
          <div class="col-3">
            <input [class.is-invalid]="
             (onPointForm.controls['381'].touched &&
               onPointForm.controls['381'].invalid) ||
              (onPointForm.controls['381'].invalid && isSubmit)"type="text" class="form-control" id="381" name="381" maxlength="12"
               [formControlName]="381" (keyup)="handleNumber($event,381)"/>
             
                <div class="mandatory" *ngIf="
                 onPointForm.controls['381'].touched &&
                 onPointForm.controls['381'].invalid ">
                   <small class="text-danger" *ngIf="onPointForm.controls['381'].errors?.required">
                      Required 
                    </small>
                    <small class="text-danger" *ngIf="onPointForm.controls['381'].errors?.pattern">
                     Required pattern xxx-xxx-xxxx
                    </small>
                </div>
          </div>
          <div class="col-1"><label for="Fax">Fax</label></div>
          <div class="col-7">
            <input class="form-control" id="382" name="382" formControlName="382" type="text" />
          </div>
        </div>
        <div class="row form-group mb-4">
          <div class="col-1"><label for="Phone">Email</label> <span class="mandatory">*</span></div>
          <div class="col-3">
            <input
              [class.is-invalid]="(onPointForm.controls['383'].touched && onPointForm.controls['383'].invalid) || (onPointForm.controls['383'].invalid && isSubmit)"
              class="form-control" id="383" name="383" formControlName="383" type="text" />
            <div class="mandatory" *ngIf=" onPointForm.controls['383'].touched && onPointForm.controls['383'].invalid">
              <small *ngIf="onPointForm.controls['383'].errors">
                required
              </small>
            </div>
          </div>
          <div class="col-3"><label for=""> Date of Association: From (mm/dd/yy)</label></div>
          <div class="col-2">
            <div class="input-group">
              <input class="form-control onboard datepicker"  appMaskInput [appMaskValue]="this.onPointForm.controls['817'].value" placeholder="Reference 2 Date of Association From " ngbDatepicker
                #d817="ngbDatepicker" id="817" name="817" formControlName="817" type="text"  (blur)="clearInputIfInvalid('817')"
                data-id="mlp1dateeff_date" />
              <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar " (click)="d817.toggle()" type="button">
                  <i class="fas fa-calendar-alt"></i>
                </button>
              </div>
            </div>
            <div  *ngIf="onPointForm.get('817').invalid ">
              <small class="text-danger" *ngIf="onPointForm.get('817').errors?.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY)
              </small>
              <small class="text-danger" *ngIf="onPointForm.get('817').errors?.invalidMonth">
              Month is out of range
              </small>
              <small class="text-danger" *ngIf="onPointForm.get('817').errors?.invalidDateyear">
                year is out of range(1900-2099)                      </small>
                <small class="text-danger" *ngIf="onPointForm.get('817').errors?.invalidDateRange">
                  date is out of range
                  </small>
              </div>
          </div>
        
          <div class="col-1">
            <label for="">To (mm/dd/yy)</label>
          </div>
          <div class="col-2">
            <div class="input-group">
              <input class="form-control onboard datepicker"  appMaskInput [appMaskValue]="this.onPointForm.controls['379'].value" placeholder="Reference 2 Date of Association To " ngbDatepicker
                #d379="ngbDatepicker" id="379" name="379" formControlName="379" type="text" (blur)="clearInputIfInvalid('379')"
                data-id="mlp1dateeff_date" />
              <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar " (click)="d379.toggle()" type="button">
                  <i class="fas fa-calendar-alt"></i>
                </button>
              </div>
            </div>
            <div  *ngIf="onPointForm.get('379').invalid ">
              <small class="text-danger" *ngIf="onPointForm.get('379').errors?.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY)
              </small>
              <small class="text-danger" *ngIf="onPointForm.get('379').errors?.invalidMonth">
              Month is out of range
              </small>
              <small class="text-danger" *ngIf="onPointForm.get('379').errors?.invalidDateyear">
                year is out of range(1900-2099)                      </small>
                <small class="text-danger" *ngIf="onPointForm.get('379').errors?.invalidDateRange">
                  date is out of range
                  </small>
              </div>
          </div>
          
        </div>
        <div class="row mb-2 mt-4 pt-4">
          <div class="col-2"><label for="384">Reference Name <span class="mandatory">*</span></label></div>
          <div class="col-4 form-group">
            <input class="form-control" id="384" name="384"
              [class.is-invalid]="(onPointForm.controls['384'].touched && onPointForm.controls['384'].invalid) || (onPointForm.controls['384'].invalid && isSubmit)"
              formControlName="384" type="text" data-id="lname" placeholder="Reference 3 Name" />
            <div class="mandatory" *ngIf=" onPointForm.controls['384'].touched && onPointForm.controls['384'].invalid">
              <small *ngIf="onPointForm.controls['384'].errors">
                required
              </small>
            </div>
          </div>
          <div class="col-2"><label for="">Title</label></div>
          <div class="col-4 form-group">
            <input class="form-control" id="2725" name="2725" formControlName="2725" type="text" data-id="lname" />
          </div>
          <div class="col-2"><label for="">Reference 3 Relationship</label></div>
          <div class="col-4 form-group">
            <input class="form-control" id="2726" name="2726" formControlName="2726" type="text" data-id="lname" />
          </div>
          <div class="col-2">
            <label for="393">Specialty</label>
          </div>
          <div class="col-4">
            <ng-select [items]="specialty" bindLabel="name" placeholder="Select Specialty" bindValue="id"
              [labelForId]="393" formControlName="393">
            </ng-select>
          </div>
          <div class="col-2">
            <label for="385">Address 1</label>
          </div>
          <div class="col-4">
            <input class="form-control" id="385" name="385" formControlName="385" type="text" data-id="homeaddress1" />
          </div>
          <div class="col-2">
            <label for="386">Address 2</label>
          </div>
          <div class="col-4">
            <input class="form-control" id="386" name="386" formControlName="386" type="text" data-id="homeaddress2" />
          </div>
        </div>
        <div class="row form-group">
          <div class="col-4">
            <div class="row">
              <div class="col-4">
                <label for="387">City</label>
              </div>
              <div class="col-8">
                <input class="form-control" id="387" name="387" formControlName="387" type="text" />
              </div>
            </div>
          </div>
          <div class="col-4">
            <div class="row">
              <div class="col-4 p-0 pl-2">
                <label for="389">State </label>
              </div>
              <div class="col-8">
                <ng-select [labelForId]="389" [items]="states" bindLabel="name" placeholder="Select State"
                  bindValue="id" formControlName="389">
                </ng-select>
              </div>
            </div>
          </div>
          <div class="col-4">
            <div class="row">
              <div class="col-4 p-0 pl-2">
                <label for="390">ZIP
                </label>
              </div>
              <div class="col-8">
                <input class="form-control" id="390" name="390" formControlName="390" type="text" />
              </div>
            </div>
          </div>
        </div>
        <div class="row form-group">
          <div class="col-1"><label for="Phone">Phone</label> <span class="mandatory">*</span></div>
          <div class="col-3">
            <input [class.is-invalid]="
             (onPointForm.controls['394'].touched &&
               onPointForm.controls['394'].invalid) ||
              (onPointForm.controls['394'].invalid && isSubmit)"type="text" class="form-control" id="394" name="394" maxlength="12"
               [formControlName]="394" (keyup)="handleNumber($event,394)"/>
             
                <div class="mandatory" *ngIf="
                 onPointForm.controls['394'].touched &&
                 onPointForm.controls['394'].invalid ">
                   <small class="text-danger" *ngIf="onPointForm.controls['394'].errors?.required">
                      Required 
                    </small>
                    <small class="text-danger" *ngIf="onPointForm.controls['394'].errors?.pattern">
                     Required pattern xxx-xxx-xxxx
                    </small>
                </div>
          </div>
          <div class="col-1"><label for="Fax">Fax</label></div>
          <div class="col-7">
            <input class="form-control" id="395" name="395" formControlName="395" type="text" />
          </div>
        </div>
        <div class="row form-group mb-4">
          <div class="col-1"><label for="Email">Email</label> <span class="mandatory">*</span></div>
          <div class="col-3">
            <input
              [class.is-invalid]="(onPointForm.controls['396'].touched && onPointForm.controls['396'].invalid) || (onPointForm.controls['396'].invalid && isSubmit)"
              class="form-control" id="396" name="396" formControlName="396" type="text" />
            <div class="mandatory" *ngIf=" onPointForm.controls['396'].touched && onPointForm.controls['396'].invalid">
              <small *ngIf="onPointForm.controls['396'].errors">
                required
              </small>
            </div>
          </div>
          <div class="col-3"><label for=""> Date of Association: From (mm/dd/yy)</label></div>
          <div class="col-2">
            <div class="input-group">
              <input class="form-control onboard datepicker"   appMaskInput [appMaskValue]="this.onPointForm.controls['391'].value"placeholder="Reference 3 Date of Association From " ngbDatepicker
                #d391="ngbDatepicker" id="391" name="391" formControlName="391" type="text" (blur)="clearInputIfInvalid('391')"
                data-id="mlp1dateeff_date" />
              <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar " (click)="d391.toggle()" type="button">
                  <i class="fas fa-calendar-alt"></i>
                </button>
              </div>
            </div>
            <div  *ngIf="onPointForm.get('391').invalid ">
              <small class="text-danger" *ngIf="onPointForm.get('391').errors?.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY)
              </small>
              <small class="text-danger" *ngIf="onPointForm.get('391').errors?.invalidMonth">
              Month is out of range
              </small>
              <small class="text-danger" *ngIf="onPointForm.get('391').errors?.invalidDateyear">
                year is out of range(1900-2099)                      </small>
                <small class="text-danger" *ngIf="onPointForm.get('391').errors?.invalidDateRange">
                  date is out of range
                  </small>
              </div>
          </div>
          
          <div class="col-1">
            <label for="">To (mm/dd/yy)</label>
          </div>
          <div class="col-2">
            <div class="input-group">
              <input class="form-control onboard datepicker"   appMaskInput [appMaskValue]="this.onPointForm.controls['392'].value" placeholder="Reference 3 Date of Association To " ngbDatepicker
                #d392="ngbDatepicker" id="392" name="392" formControlName="392" type="text" (blur)="clearInputIfInvalid('392')"
                data-id="mlp1dateeff_date" /> 
              <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar " (click)="d392.toggle()" type="button">
                  <i class="fas fa-calendar-alt"></i>
                </button>
              </div>
            </div>
            <div  *ngIf="onPointForm.get('392').invalid ">
              <small class="text-danger" *ngIf="onPointForm.get('392').errors?.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY)
              </small>
              <small class="text-danger" *ngIf="onPointForm.get('392').errors?.invalidMonth">
              Month is out of range
              </small>
              <small class="text-danger" *ngIf="onPointForm.get('392').errors?.invalidDateyear">
                year is out of range(1900-2099)                      </small>
                <small class="text-danger" *ngIf="onPointForm.get('392').errors?.invalidDateRange">
                  date is out of range
                  </small>
              </div>
          </div>
          
        </div>
      </div>
      <div class="sectionSeven">
        <h4>Section 7: Health Status and Vaccinations </h4>
        <div class="row">
          <div class="col-2 form-group"><label for="2135">Date of PPD Test</label></div>
          <div class="col-2 form-group">
            <div class="input-group">
              <input class="form-control onboard datepicker"  appMaskInput [appMaskValue]="this.onPointForm.controls['2135'].value" placeholder="Date of PPD Test " ngbDatepicker
                #d2135="ngbDatepicker" id="2135" name="2135" formControlName="2135" type="text" (blur)="clearInputIfInvalid('2135')"
                data-id="mlp1dateeff_date" />
              <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar " (click)="d2135.toggle()" type="button">
                  <i class="fas fa-calendar-alt"></i>
                </button>
              </div>
            </div>
          
            <div  *ngIf="onPointForm.get('2135').invalid ">
              <small class="text-danger" *ngIf="onPointForm.get('2135').errors?.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY)
              </small>
              <small class="text-danger" *ngIf="onPointForm.get('2135').errors?.invalidMonth">
              Month is out of range
              </small>
              <small class="text-danger" *ngIf="onPointForm.get('2135').errors?.invalidDateyear">
                year is out of range(1900-2099)                      </small>
                <small class="text-danger" *ngIf="onPointForm.get('2135').errors?.invalidDateRange">
                  date is out of range
                  </small>
              </div>
        </div>


          <div class="col-2 form-group"><label for="826">Date of PPD Read</label></div>
          <div class="col-2 form-group">
            <div class="input-group">
              <input class="form-control onboard datepicker"  appMaskInput [appMaskValue]="this.onPointForm.controls['826'].value" placeholder="Date of PPD Reading " ngbDatepicker
                #d826="ngbDatepicker" id="826" name="826" formControlName="826" type="text" (blur)="clearInputIfInvalid('826')"
                data-id="mlp1dateeff_date" />
              <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar " (click)="d826.toggle()" type="button">
                  <i class="fas fa-calendar-alt"></i>
                </button>
              </div>
            </div>
          
            <div  *ngIf="onPointForm.get('826').invalid ">
              <small class="text-danger" *ngIf="onPointForm.get('826').errors?.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY)
              </small>
              <small class="text-danger" *ngIf="onPointForm.get('826').errors?.invalidMonth">
              Month is out of range
              </small>
              <small class="text-danger" *ngIf="onPointForm.get('826').errors?.invalidDateyear">
                year is out of range(1900-2099)                      </small>
                <small class="text-danger" *ngIf="onPointForm.get('826').errors?.invalidDateRange">
                  date is out of range
                  </small>
              </div>
        </div>

          <div class="col-2 form-group"><label for="1474">PPD Results</label></div>
          <div class="col-2 form-group">
            <input class="form-control" placeholder="PPD Results" id="1474" name="1474" formControlName="1474"
              type="text" />
          </div>
        </div>
        <div class="row">
          <div class="col-2 form-group"><label for="1529">Date of Flu Vaccine</label></div>
          <div class="col-2 form-group">
            <div class="input-group">
              <input class="form-control onboard datepicker"   appMaskInput [appMaskValue]="this.onPointForm.controls['1529'].value" placeholder="Date of Flu Vaccine" ngbDatepicker
                #d1529="ngbDatepicker" id="1529" name="1529" formControlName="1529" type="text" (blur)="clearInputIfInvalid('1529')"
                data-id="mlp1dateeff_date" />
              <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar " (click)="d1529.toggle()" type="button">
                  <i class="fas fa-calendar-alt"></i>
                </button>
              </div>
            </div>
          
            <div  *ngIf="onPointForm.get('1529').invalid ">
              <small class="text-danger" *ngIf="onPointForm.get('1529').errors?.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY)
              </small>
              <small class="text-danger" *ngIf="onPointForm.get('1529').errors?.invalidMonth">
              Month is out of range
              </small>
              <small class="text-danger" *ngIf="onPointForm.get('1529').errors?.invalidDateyear">
                year is out of range(1900-2099)                      </small>
                <small class="text-danger" *ngIf="onPointForm.get('1529').errors?.invalidDateRange">
                  date is out of range
                  </small>
              </div>
        </div>

          <div class="col-2 form-group"><label for="1530">Date of Flu Exp</label></div>
          <div class="col-2 form-group">
            <div class="input-group">
              <input class="form-control onboard datepicker"  appMaskInput [appMaskValue]="this.onPointForm.controls['1530'].value" placeholder="Flu Vaccine Expiration Date " ngbDatepicker
                #d1530="ngbDatepicker" id="1530" name="1530" formControlName="1530" type="text"  (blur)="clearInputIfInvalid('1530')"
                data-id="mlp1dateeff_date" />
              <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar " (click)="d1530.toggle()" type="button">
                  <i class="fas fa-calendar-alt"></i>
                </button>
              </div>
            </div>
          
            <div  *ngIf="onPointForm.get('1530').invalid ">
              <small class="text-danger" *ngIf="onPointForm.get('1530').errors?.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY)
              </small>
              <small class="text-danger" *ngIf="onPointForm.get('1530').errors?.invalidMonth">
              Month is out of range
              </small>
              <small class="text-danger" *ngIf="onPointForm.get('1530').errors?.invalidDateyear">
                year is out of range(1900-2099)                      </small>
                <small class="text-danger" *ngIf="onPointForm.get('1530').errors?.invalidDateRange">
                  date is out of range
                  </small>
              </div>
        </div>

          <div class="col-2 form-group"><label for="2322">MMR, Hep, Varicella Vaccin</label></div>
          <div class="col-2">
            <div class="input-group">
              <input class="form-control onboard datepicker"  appMaskInput [appMaskValue]="this.onPointForm.controls['2322'].value" placeholder="MMR, Hep, Varicella Vaccine Date " ngbDatepicker
                #d2322="ngbDatepicker" id="2322" name="2322" formControlName="2322" type="text" (blur)="clearInputIfInvalid('2322')"
                data-id="mlp1dateeff_date" />
              <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar " (click)="d2322.toggle()" type="button">
                  <i class="fas fa-calendar-alt"></i>
                </button>
              </div>
            </div>
          
       
            <div  *ngIf="onPointForm.get('2322').invalid ">
              <small class="text-danger" *ngIf="onPointForm.get('2322').errors?.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY)
              </small>
              <small class="text-danger" *ngIf="onPointForm.get('2322').errors?.invalidMonth">
              Month is out of range
              </small>
              <small class="text-danger" *ngIf="onPointForm.get('2322').errors?.invalidDateyear">
                year is out of range(1900-2099)                      </small>
                <small class="text-danger" *ngIf="onPointForm.get('2322').errors?.invalidDateRange">
                  date is out of range
                  </small>
              </div>
      </div>
    </div>



        <div class="row">
          <div class="col-2 form-group"><label for="4316">COVID 19 First Vaccine Date:</label></div>
          <div class="col-2 form-group">
            <div class="input-group">
              <input class="form-control onboard datepicker"   appMaskInput [appMaskValue]="this.onPointForm.controls['4316'].value"placeholder="COVID 19 First Vaccine Date " ngbDatepicker
                #d4316="ngbDatepicker" id="4316" name="4316" formControlName="4316" type="text" (blur)="clearInputIfInvalid('4316')"
                data-id="mlp1dateeff_date" />
              <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar " (click)="d4316.toggle()" type="button">
                  <i class="fas fa-calendar-alt"></i>
                </button>
              </div>
            </div>
            <div  *ngIf="onPointForm.get('4316').invalid ">
              <small class="text-danger" *ngIf="onPointForm.get('4316').errors?.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY)
              </small>
              <small class="text-danger" *ngIf="onPointForm.get('4316').errors?.invalidMonth">
              Month is out of range
              </small>
              <small class="text-danger" *ngIf="onPointForm.get('4316').errors?.invalidDateyear">
                year is out of range(1900-2099)                      </small>
                <small class="text-danger" *ngIf="onPointForm.get('4316').errors?.invalidDateRange">
                  date is out of range
                  </small>
              </div>
          </div>
          


          <div class="col-2 form-group"><label for="">COVID 19 Second Vaccine Date</label></div>
          <div class="col-2 form-group">
            <div class="input-group">
              <input class="form-control onboard datepicker"  appMaskInput [appMaskValue]="this.onPointForm.controls['4317'].value" placeholder="COVID 19 Second Vaccine Date" ngbDatepicker
                #d4317="ngbDatepicker" id="4317" name="4317" formControlName="4317" type="text" (blur)="clearInputIfInvalid('4317')"
                data-id="mlp1dateeff_date"/>
              <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar " (click)="d4317.toggle()" type="button">
                  <i class="fas fa-calendar-alt"></i>
                </button>
              </div>
            </div>
            <div  *ngIf="onPointForm.get('4317').invalid ">
              <small class="text-danger" *ngIf="onPointForm.get('4317').errors?.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY)
              </small>
              <small class="text-danger" *ngIf="onPointForm.get('4317').errors?.invalidMonth">
              Month is out of range
              </small>
              <small class="text-danger" *ngIf="onPointForm.get('4317').errors?.invalidDateyear">
                year is out of range(1900-2099)                      </small>
                <small class="text-danger" *ngIf="onPointForm.get('4317').errors?.invalidDateRange">
                  date is out of range
                  </small>
              </div>
          </div>
          


          <div class="col-2 form-group"><label for="">COVID 19 Booster Vaccine Date</label></div>
          <div class="col-2">
            <div class="input-group">
              <input class="form-control onboard datepicker"   appMaskInput [appMaskValue]="this.onPointForm.controls['4421'].value"placeholder="COVID 19 Booster Vaccine Date" ngbDatepicker
                #d4421="ngbDatepicker" id="4421" name="4421" formControlName="4421" type="text" (blur)="clearInputIfInvalid('4421')"
                data-id="mlp1dateeff_date" />
              <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar " (click)="d4421.toggle()" type="button">
                  <i class="fas fa-calendar-alt"></i>
                </button>
              </div>
            </div>
            <div  *ngIf="onPointForm.get('4421').invalid ">
              <small class="text-danger" *ngIf="onPointForm.get('4421').errors?.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY)
              </small>
              <small class="text-danger" *ngIf="onPointForm.get('4421').errors?.invalidMonth">
              Month is out of range
              </small>
              <small class="text-danger" *ngIf="onPointForm.get('4421').errors?.invalidDateyear">
                year is out of range(1900-2099)                      </small>
                <small class="text-danger" *ngIf="onPointForm.get('4421').errors?.invalidDateRange">
                  date is out of range
                  </small>
              </div>
          </div>
          

        </div>
        <h5 class="mandatory warn">
          *If positive PPD Test, please send all positive Xray report to credentialing@opmedgroup.com.
        </h5>
      </div>
      <div class="sectionEight">
        <h4>Section 8: Malpractice Claims Explanation </h4>
        <h5 class="mandatory warn">
          * *Required Response. If you have additional that need to be submitted, please email the information below to
          us at credentialing@opmedgroup.com
        </h5>
        <div class="row form-group">
          <div class="col-6">
            <div class="row form-group">
              <div class="col-4"><label for="1208">Date of Occurrence</label> <span *ngIf="onPointForm.controls['1208'].invalid||onPointForm.controls['cmd_NA_09'].value==null" class="mandatory">*</span></div>
              <div class="col-8">
                <div class="input-group">
                  <input (blur)="handleNAchange('cmd_NA_09',1208)" class="form-control onboard"
                  placeholder="Date of occurance"
                  ngbDatepicker #d1208="ngbDatepicker" id="1208" name="1208" [class.is-invalid]="(onPointForm.controls['1208'].invalid&& onPointForm.controls['1208'].touched )|| (onPointForm.controls['1208'].invalid && isSubmit)"
                    formControlName="1208" type="text"  appMaskInput [appMaskValue]="this.onPointForm.controls['1208'].value" data-id="dob_date" (blur)="clearInputIfInvalid('1208','cmd_NA_09')" />
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d1208.toggle()" type="button">
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
                <div class="mandatory" *ngIf="onPointForm.controls['1208'].invalid && (onPointForm.controls['1208'].value==''|| onPointForm.controls['1208'].value==null) " >
                  <small >
                  required
                  </small>

              </div>
              <div  *ngIf="onPointForm.get('1208').invalid ">
                <small class="text-danger" *ngIf="onPointForm.get('1208').errors.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY)                </small>
                <small class="text-danger" *ngIf="onPointForm.get('1208').errors.invalidMonth">
                Month is out of range
                </small>
                <small class="text-danger" *ngIf="onPointForm.get('1208').errors.invalidDateyear">
                  year is out of range(1900-2099)                  </small>
                  <small class="text-danger" *ngIf="onPointForm.get('1208').errors.invalidDateRange">
                    date is out of range
                    </small>
                </div>
              </div>

            </div>
          </div>
          <div class="col-6">
            <div class="row">
              <div class="col-4">
                <label for="1209">Date Claim was Filed <span *ngIf="onPointForm.controls['1209'].invalid ||onPointForm.controls['cmd_NA_09'].value==null" class="mandatory">*</span></label>
              </div>
              <div class="col-6">
                <div class="input-group">
                  <input (blur)="handleNAchange('cmd_NA_09',1209)" class="form-control onboard" [class.is-invalid]="(onPointForm.controls['1209'].invalid&& onPointForm.controls['1209'].touched )|| (onPointForm.controls['1209'].invalid && isSubmit)"
                   placeholder="Date of Lawsuit" ngbDatepicker #d1209="ngbDatepicker" id="1209" name="1209" appMaskInput [appMaskValue]="this.onPointForm.controls['1209'].value"
                    formControlName="1209" type="text" data-id="dob_date" (blur)="clearInputIfInvalid('1209','cmd_NA_09')"  />
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d1209.toggle()" type="button">
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
                <div class="mandatory" *ngIf="onPointForm.controls['1209'].invalid && (onPointForm.controls['1209'].value==''|| onPointForm.controls['1209'].value==null) " >
                  <small >
                  required
                  </small>

              </div>
              <div  *ngIf="onPointForm.get('1209').invalid ">
                <small class="text-danger" *ngIf="onPointForm.get('1209').errors.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY)                </small>
                <small class="text-danger" *ngIf="onPointForm.get('1209').errors.invalidMonth">
                Month is out of range
                </small>
                <small class="text-danger" *ngIf="onPointForm.get('1209').errors.invalidDateyear">
                  year is out of range(1900-2099)                  </small>
                  <small class="text-danger" *ngIf="onPointForm.get('1209').errors.invalidDateRange">
                    date is out of range
                    </small>
                </div>
              </div>

              <div class="col-2">
                <div class="form-check">
                  <input formControlName="cmd_NA_09" (ngModelChange)="handleNAchange('cmd_NA_09')" class="form-check-input" type="checkbox" value="" id="cmd_NA_09">
                  <label class="form-check-label" for="cmd_NA_09">
                    N/A
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="col-5"><label for="1858">Status of Claim: (Note – If case is pending, select open) <span
            *ngIf="onPointForm.controls['1858'].invalid || onPointForm.controls['cmd_NA_09'].value==null"  class="mandatory">*</span></label></div>
          <div class="col-7 form-group">
            <input (blur)="handleNAchange('cmd_NA_09',1858)" class="form-control" id="1858" name="1858" placeholder="Claim Status"
              [class.is-invalid]="onPointForm.controls['1858'].invalid"
              formControlName="1858" type="text" data-id="lname" />
            <div class="mandatory"
              *ngIf="onPointForm.controls['1858'].invalid">
              <small>
                required
              </small>
            </div>
            <!-- <ng-select [class.is-invalid]=" (onPointForm.controls['1858'].touched &&
            onPointForm.controls['1858'].invalid) || (onPointForm.controls['1858'].invalid && isSubmit)"
              [items]="statusDropdown" bindLabel="name" placeholder="Select Status" bindValue="id"
              [labelForId]="'Status'" formControlName="1858">
            </ng-select>
            <div class="mandatory" *ngIf="
            onPointForm.controls['1858'].touched &&
            onPointForm.controls['1858'].invalid
          ">
              <small *ngIf="onPointForm.controls['1858'].errors?.required">
                required
              </small>
            </div> -->
          </div>
          <div class="col-4"><label for="1226">If settled, date claim was settled <span *ngIf="onPointForm.controls['1226'].invalid||onPointForm.controls['cmd_NA_09'].value==null"
            class="mandatory">*</span>
            </label></div>
            <div class="col-8 form-group">
              <div class="input-group">
                <input (blur)="handleNAchange('cmd_NA_09',1226)"[class.is-invalid]="(onPointForm.controls['1226'].invalid&&onPointForm.controls['1226'].touched)|| (onPointForm.controls['1226'].invalid&& isSubmit)"
                 
                  class="form-control onboard datepicker" placeholder="Settlement Date " ngbDatepicker
                  #d1226="ngbDatepicker" id="1226" name="1226" formControlName="1226" type="text" appMaskInput [appMaskValue]="this.onPointForm.controls['1226'].value"
                  data-id="mlp1dateeff_date" (blur)="clearInputIfInvalid('1226','cmd_NA_09')"   />
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary calendar " (click)="d1226.toggle()" type="button">
                    <i class="fas fa-calendar-alt"></i>
                  </button>
                </div>
              </div>
              <div class="mandatory" *ngIf="onPointForm.controls['1226'].invalid && (onPointForm.controls['1226'].value==''|| onPointForm.controls['1226'].value==null) " >
                <small >
                required
                </small>

            </div>
            <div  *ngIf="onPointForm.get('1226').invalid ">
              <small class="text-danger" *ngIf="onPointForm.get('1226').errors.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY)                </small>
              <small class="text-danger" *ngIf="onPointForm.get('1226').errors.invalidMonth">
              Month is out of range
              </small>
              <small class="text-danger" *ngIf="onPointForm.get('1226').errors.invalidDateyear">
                year is out of range(1900-2099)                  </small>
                <small class="text-danger" *ngIf="onPointForm.get('1226').errors.invalidDateRange">
                  date is out of range
                  </small>
              </div>
            </div>
  
          <div class="col-4"><label for="1210">Professional Liability Carrier Involved: <span *ngIf="onPointForm.controls['1210'].invalid ||  onPointForm.controls['cmd_NA_09'].value==null"
                class="mandatory">*</span></label></div>
          <div class="col-3 form-group">
            <input (blur)="handleNAchange('cmd_NA_09',1210)" [class.is-invalid]="onPointForm.controls['1210'].invalid "
              class="form-control" id="1210" name="1210" placeholder="Liability" formControlName="1210" type="text" />
            <div class="mandatory" *ngIf="onPointForm.controls['1210'].invalid">
              <small>
                required
              </small>
            </div>
          </div>
          <div class="col-2"><label for="1859">Policy Number: <span class="mandatory" *ngIf="onPointForm.controls['1859'].invalid ||  onPointForm.controls['cmd_NA_09'].value==null">*</span></label></div>
          <div class="col-3 form-group">
            <input (blur)="handleNAchange('cmd_NA_09',1859)" [class.is-invalid]="onPointForm.controls['1859'].invalid"
              class="form-control" id="1859" name="1859" placeholder="Policy Number" formControlName="1859"
              type="text" />
            <div class="mandatory" *ngIf="onPointForm.controls['1859'].invalid">
              <small>
                required
              </small>
            </div>
          </div>
        </div>
        <div class="row form-group">
          <div class="col-4"><label for="2012">Address 1: <span *ngIf="onPointForm.controls['2012'].invalid ||  onPointForm.controls['cmd_NA_09'].value==null" class="mandatory">*</span></label></div>
          <div class="col-3 form-group">
            <input (blur)="handleNAchange('cmd_NA_09',2012)"
             [class.is-invalid]="onPointForm.controls['2012'].invalid"
              class="form-control" id="2012" name="2012" placeholder="Address 1" formControlName="2012" type="text" />
            <div class="mandatory" *ngIf="onPointForm.controls['2012'].invalid">
              <small >
                required
              </small>
            </div>
          </div>
          <div class="col-2"><label for="">Address 2: <span *ngIf="onPointForm.controls['1844'].invalid ||  onPointForm.controls['cmd_NA_09'].value==null" class="mandatory">*</span></label></div>
          <div class="col-3 form-group">
            <input (blur)="handleNAchange('cmd_NA_09',1844)" [class.is-invalid]="onPointForm.controls['1844'].invalid"
              class="form-control" id="1844" name="1844" placeholder="Address 2" formControlName="1844" type="text" />
            <div class="mandatory" *ngIf="onPointForm.controls['1844'].invalid">
              <small>
                required
              </small>
            </div>
          </div>
        </div>

        <div class="row form-group">
          <div class="col-3">
            <div class="row">
              <div class="col-4">
                <label for="1845">City <span *ngIf="onPointForm.controls['1845'].invalid ||  onPointForm.controls['cmd_NA_09'].value==null" class="mandatory">*</span></label>
              </div>
              <div class="col-8">
                <input (blur)="handleNAchange('cmd_NA_09',1845)" [class.is-invalid]="onPointForm.controls['1845'].invalid"
                  class="form-control" id="1845" name="1845" formControlName="1845" type="text" />
                <div class="mandatory" *ngIf="onPointForm.controls['1845'].invalid
              ">
                  <small>
                    required
                  </small>
                </div>
              </div>
            </div>
          </div>
          <div class="col-3">
            <div class="row">
              <div class="col-4 p-0 pl-2">
                <label for="1847">State <span *ngIf="onPointForm.controls['1847'].invalid || onPointForm.controls['cmd_NA_09'].value==null" class="mandatory">*</span> </label>
              </div>
              <div class="col-8">
                <ng-select (blur)="handleNAchange('cmd_NA_09',1847)" [class.is-invalid]="onPointForm.controls['1847'].invalid"
                  [labelForId]="1847" [items]="states" bindLabel="name" placeholder="Select State" bindValue="id"
                  formControlName="1847">
                </ng-select>
                <div class="mandatory" *ngIf="onPointForm.controls['1847'].invalid">
                  <small>
                    required
                  </small>
                </div>
              </div>
            </div>
          </div>
          <div class="col-3">
            <div class="row">
              <div class="col-4 p-0 pl-2">
                <label for="1848">ZIP <span *ngIf="onPointForm.controls['1848'].invalid ||  onPointForm.controls['cmd_NA_09'].value==null" class="mandatory">*</span>
                </label>
              </div>
              <div class="col-8">
                <input (blur)="handleNAchange('cmd_NA_09',1848)" [class.is-invalid]="onPointForm.controls['1848'].invalid"
                  class="form-control" id="1848" name="1848" formControlName="1848" type="text" />
                <div class="mandatory"*ngIf="onPointForm.controls['1848'].invalid">
                  <small *ngIf="onPointForm.controls['1848'].invalid">
                    required
                  </small>
                </div>
              </div>
            </div>
          </div>
          <div class="col-3">
            <div class="row">
              <div class="col-4"><label>Phone <span  *ngIf="onPointForm.controls['1849'].invalid ||  onPointForm.controls['cmd_NA_09'].value==null" class="mandatory">*</span></label></div>
              <div class="col-8">
                <input (blur)="handleNAchange('cmd_NA_09',1849)"  [class.is-invalid]="onPointForm.controls['1849'].invalid"
                  class="form-control" id="1849" name="1849" placeholder="Insurance Carrier Phone " type="text"  maxlength="12"
                  [formControlName]="1849" (keyup)="handleNumber($event,1849)"/>
                  <div class="mandatory" *ngIf="onPointForm.controls['1849'].invalid
                  ">
                <small >
                  required
                </small>
              </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row ">
          <div class="col form-group">
            <label  for="1216">Description of Allegations<span *ngIf="onPointForm.controls['1216'].invalid  || onPointForm.controls['cmd_NA_09'].value==null"  class="mandatory">*</span></label>
            <textarea (blur)="handleNAchange('cmd_NA_09','1216')"  id="1216" [class.is-invalid]="onPointForm.controls['1216'].invalid"
              class="form-control" name="1216" id="1216" formControlName="1216" cols="30"
              rows="10"></textarea>
            <div class="mandatory" *ngIf="onPointForm.controls['1216'].invalid">
              <small >
                required
              </small>
            </div>
          </div>
        </div>
        <div class="row ">
          <div class="col-12 form-group">
            <label for="1217">Description of Alleged Injury to the patient<span *ngIf="onPointForm.controls['1217'].invalid ||  onPointForm.controls['cmd_NA_09'].value==null" class="mandatory">*</span></label>
            <textarea (blur)="handleNAchange('cmd_NA_09','1217')" [class.is-invalid]="onPointForm.controls['1217'].invalid"
              class="form-control" name="1217" id="1217" cols="30" formControlName="1217"
              rows="10"></textarea>
            <div class="mandatory" *ngIf="onPointForm.controls['1217'].invalid
                ">
              <small >
                required
              </small>
            </div>
          </div>
          <div class="col-12">
            <div class="subPara">
              Did the alleged injury result in death? 
              <span>
                <div class="form-check-inline">
                  <input  class="form-check-input" name="cmd_43"
                   formControlName="cmd_43" type="radio" value="1" id="cmd_43" />
                  <label class="form-check-label" for="cmd_43">
                    Yes
                  </label>
                </div>
                <div class="form-check-inline">
                  <input  class="form-check-input" name="cmd_43"
                   formControlName="cmd_43" type="radio" value="0" id="cmd_43_1">
                  <label class="form-check-label" for="cmd_43_1">
                    NO
                  </label>
                  </div>
                  
                  <!-- <div class="mandatory ml-3" *ngIf="isSubmit &&onPointForm.controls['cmd_43'].invalid">
                    <small *ngIf="onPointForm.controls['cmd_43'].errors?.required">
                      Please select Yes/NO
                    </small>
                  </div> -->
                

              </span>
            </div>
            
    
              </div>
            </div>

            
            <div>
              <div class="subPara">
                To the best of your knowledge, is the case included in the National Practitioner Data Bank (NPDB)?
                
                <span>
                  <div class="form-check-inline">
                    <input  class="form-check-input" name="cmd_02"
                     formControlName="cmd_02" type="radio" value="1" id="cmd_02" />
                    <label class="form-check-label" for="cmd_02">
                      Yes
                    </label>
                  </div>
                  <div class="form-check-inline">
                    <input  class="form-check-input" name="cmd_02"
                     formControlName="cmd_02" type="radio" value="0" id="cmd_02">
                    <label class="form-check-label" for="cmd_02">
                      NO
                    </label>
                    </div>
                
                    
                     
                   
                </span>
              </div>
              
            </div>
          </div>
        <!-- </div> -->
      
      <div class="sectionNine">
        <h4>Section 9: Continuing Medical Education </h4>
        <div class="row">
          <div class="col-2"><label for=add_19_1016>CME Hours:</label></div>
          <div class="col-3 form-group">
            <input type="number" name="cme_hours" class="form-control" placeholder='' formControlName=add_19_1016>
          </div>
          <div class="col-3"><label for=add_19_2538>CME Category/Modality</label></div>
          <div class="col-4 form-group">
            <ng-select [items]="CMEDropdown" bindLabel="label" placeholder="Select CME Category" bindValue="id"
              formControlName=add_19_2538>
            </ng-select>
          </div>
        </div>
        <div class="row">
          <div class="col-2"><label for="cmd_5">CME Hours:</label></div>
          <div class="col-3 form-group">
            <input type="number" name="add_1_1016" class="form-control"  formControlName="add_1_1016">
          </div>
          <div class="col-3"><label for="add_1_2538">CME Category/Modality</label></div>
          <div class="col-4 form-group">
            <ng-select [items]="CMEDropdown" bindLabel="label" placeholder="Select CME Category" bindValue="id"
              formControlName="add_1_2538">
            </ng-select>
          </div>
        </div>

        <div class="row">
          <div class="col-2"><label for="add_2_1016">CME Hours:</label></div>
          <div class="col-3 form-group">
            <input type="number" name="cme_hours" class="form-control" placeholder='' formControlName="add_2_1016">
          </div>
          <div class="col-3"><label for="add_2_2538">CME Category/Modality</label></div>
          <div class="col-4 form-group">
            <ng-select [items]="CMEDropdown" bindLabel="label" placeholder="Select CME Category" bindValue="id"
              formControlName="add_2_2538">
            </ng-select>
          </div>
        </div>

        <div class="row">
          <div class="col-2"><label for="add_3_1016">CME Hours:</label></div>
          <div class="col-3 form-group">
            <input type="number" name="cme_hours" class="form-control" placeholder='' formControlName="add_3_1016">
          </div>
          <div class="col-3"><label for="add_3_2538">CME Category/Modality</label></div>
          <div class="col-4 form-group">
            <ng-select [items]="CMEDropdown" bindLabel="label" placeholder="Select CME Category" bindValue="id"
              formControlName="add_3_2538">
            </ng-select>
          </div>
        </div>

        <div class="row">
          <div class="col-2"><label for="add_4_1016">CME Hours:</label></div>
          <div class="col-3 form-group">
            <input type="number" name="cme_hours" class="form-control" placeholder='' formControlName="add_4_1016">
          </div>
          <div class="col-3"><label for="add_4_2538">CME Category/Modality</label></div>
          <div class="col-4 form-group">
            <ng-select [items]="CMEDropdown" bindLabel="label" placeholder="Select CME Category" bindValue="id"
              formControlName="add_4_2538">
            </ng-select>
          </div>
        </div>

        <div class="row">
          <div class="col-2"><label for="add_5_1016">CME Hours:</label></div>
          <div class="col-3 form-group">
            <input type="number" name="cme_hours" class="form-control" placeholder='' formControlName="add_5_1016">
          </div>
          <div class="col-3"><label for="add_5_2538">CME Category/Modality</label></div>
          <div class="col-4 form-group">
            <ng-select [items]="CMEDropdown" bindLabel="label" placeholder="Select CME Category" bindValue="id"
              formControlName="add_5_2538">
            </ng-select>
          </div>
        </div>

        <div class="row">
          <div class="col-2"><label for="add_6_1016">CME Hours:</label></div>
          <div class="col-3 form-group">
            <input type="number" name="cme_hours" class="form-control" placeholder='' formControlName="add_6_1016">
          </div>
          <div class="col-3"><label for="add_6_2538">CME Category/Modality</label></div>
          <div class="col-4 form-group">
            <ng-select [items]="CMEDropdown" bindLabel="label" placeholder="Select CME Category" bindValue="id"
              formControlName="add_6_2538">
            </ng-select>
          </div>
        </div>

        <div class="row">
          <div class="col-2"><label for="add_7_1016">CME Hours:</label></div>
          <div class="col-3 form-group">
            <input type="number" name="cme_hours" class="form-control" placeholder='' formControlName="add_7_1016">
          </div>
          <div class="col-3"><label for="add_7_2538">CME Category/Modality</label></div>
          <div class="col-4 form-group">
            <ng-select [items]="CMEDropdown" bindLabel="label" placeholder="Select CME Category" bindValue="id"
              formControlName="add_7_2538">
            </ng-select>
          </div>
        </div>

        <div class="row">
          <div class="col-2"><label for="add_8_1016">CME Hours:</label></div>
          <div class="col-3 form-group">
            <input type="number" name="cme_hours" class="form-control" placeholder='' formControlName="add_8_1016">
          </div>
          <div class="col-3"><label for="add_8_2538">CME Category/Modality</label></div>
          <div class="col-4 form-group">
            <ng-select [items]="CMEDropdown" bindLabel="label" placeholder="Select CME Category" bindValue="id"
              formControlName="add_8_2538">
            </ng-select>
          </div>
        </div>

        <div class="row">
          <div class="col-2"><label for="add_9_1016">CME Hours:</label></div>
          <div class="col-3 form-group">
            <input type="number" name="cme_hours" class="form-control" placeholder='' formControlName="add_9_1016">
          </div>
          <div class="col-3"><label for="add_9_2538">CME Category/Modality</label></div>
          <div class="col-4 form-group">
            <ng-select [items]="CMEDropdown" bindLabel="label" placeholder="Select CME Category" bindValue="id"
              formControlName="add_9_2538">
            </ng-select>
          </div>
        </div>

        <div class="row">
          <div class="col-2"><label for="add_10_1016">CME Hours:</label></div>
          <div class="col-3 form-group">
            <input type="number" name="cme_hours" class="form-control" placeholder='' formControlName="add_10_1016">
          </div>
          <div class="col-3"><label for="add_10_2538">CME Category/Modality</label></div>
          <div class="col-4 form-group">
            <ng-select [items]="CMEDropdown" bindLabel="label" placeholder="Select CME Category" bindValue="id"
              formControlName="add_10_2538">
            </ng-select>
          </div>
        </div>

        <div class="row">
          <div class="col-2"><label for="add_11_1016">CME Hours:</label></div>
          <div class="col-3 form-group">
            <input type="number" name="cme_hours" class="form-control" placeholder='' formControlName="add_11_1016">
          </div>
          <div class="col-3"><label for="add_11_2538">CME Category/Modality</label></div>
          <div class="col-4 form-group">
            <ng-select [items]="CMEDropdown" bindLabel="label" placeholder="Select CME Category" bindValue="id"
              formControlName="add_11_2538">
            </ng-select>
          </div>
        </div>

        <div class="row">
          <div class="col-2"><label for="add_12_1016">CME Hours:</label></div>
          <div class="col-3 form-group">
            <input type="number" name="cme_hours" class="form-control" placeholder='' formControlName="add_12_1016">
          </div>
          <div class="col-3"><label for="add_12_2538">CME Category/Modality</label></div>
          <div class="col-4 form-group">
            <ng-select [items]="CMEDropdown" bindLabel="label" placeholder="Select CME Category" bindValue="id"
              formControlName="add_12_2538">
            </ng-select>
          </div>
        </div>

        <div class="row">
          <div class="col-2"><label for="add_13_1016">CME Hours:</label></div>
          <div class="col-3 form-group">
            <input type="number" name="cme_hours" class="form-control" placeholder='' formControlName="add_13_1016">
          </div>
          <div class="col-3"><label for="add_13_2538">CME Category/Modality</label></div>
          <div class="col-4 form-group">
            <ng-select [items]="CMEDropdown" bindLabel="label" placeholder="Select CME Category" bindValue="id"
              formControlName="add_13_2538">
            </ng-select>
          </div>
        </div>

        <div class="row">
          <div class="col-2"><label for="add_14_1016">CME Hours:</label></div>
          <div class="col-3 form-group">
            <input type="number" name="cme_hours" class="form-control" placeholder='' formControlName="add_14_1016">
          </div>
          <div class="col-3"><label for="add_14_2538">CME Category/Modality</label></div>
          <div class="col-4 form-group">
            <ng-select [items]="CMEDropdown" bindLabel="label" placeholder="Select CME Category" bindValue="id"
              formControlName="add_14_2538">
            </ng-select>
          </div>
        </div>

        <div class="row">
          <div class="col-2"><label for="add_15_1016">CME Hours:</label></div>
          <div class="col-3 form-group">
            <input type="number" name="cme_hours" class="form-control" placeholder='' formControlName="add_15_1016">
          </div>
          <div class="col-3"><label for="add_15_2538">CME Category/Modality</label></div>
          <div class="col-4 form-group">
            <ng-select [items]="CMEDropdown" bindLabel="label" placeholder="Select CME Category" bindValue="id"
              formControlName="add_15_2538">
            </ng-select>
          </div>
        </div>

        <div class="row">
          <div class="col-2"><label for="add_16_1016">CME Hours:</label></div>
          <div class="col-3 form-group">
            <input type="number" name="cme_hours" class="form-control" placeholder='' formControlName="add_16_1016">
          </div>
          <div class="col-3"><label for="add_16_2538">CME Category/Modality</label></div>
          <div class="col-4 form-group">
            <ng-select [items]="CMEDropdown" bindLabel="label" placeholder="Select CME Category" bindValue="id"
              formControlName="add_16_2538">
            </ng-select>
          </div>
        </div>

        <div class="row">
          <div class="col-2"><label for="add_17_1016">CME Hours:</label></div>
          <div class="col-3 form-group">
            <input type="number" name="cme_hours" class="form-control" placeholder='' formControlName="add_17_1016">
          </div>
          <div class="col-3"><label for="add_17_2538">CME Category/Modality</label></div>
          <div class="col-4 form-group">
            <ng-select [items]="CMEDropdown" bindLabel="label" placeholder="Select CME Category" bindValue="id"
              formControlName="add_17_2538">
            </ng-select>
          </div>
        </div>

        <div class="row">
          <div class="col-2"><label for="add_18_1016">CME Hours:</label></div>
          <div class="col-3 form-group">
            <input type="number" name="cme_hours" class="form-control" placeholder='' formControlName="add_18_1016">
          </div>
          <div class="col-3"><label for="add_18_2538">CME Category/Modality</label></div>
          <div class="col-4 form-group">
            <ng-select [items]="CMEDropdown" bindLabel="label" placeholder="Select CME Category" bindValue="id"
              formControlName="add_18_2538">
            </ng-select>
          </div>
        </div>
        <h5 class="mandatory warn">
          *Current CME credits from previous 36 months, please email us at
          <a href="https://credentialing@opmedgroup.com" target="_blank">credentialing@opmedgroup.com</a> with the CME
          certificates or breakdown ofthe CME hours/credits earned.</h5>
      </div>
      <div class="sectionTen">
        <h4>Section 10: Documents Needed </h4>
        <h5 class="mandatory warn">
          *Please email the supporting documentation as listed below to <a href="https://credentialing@opmedgroup.com"
            target="_blank">credentialing@opmedgroup.com</a>
        </h5>
        <ul>
          <li>
            Medical License – Wallet ID Card
          </li>
          <li>
            Government Issued Photo ID - Driver’s License, passport, etc.
          </li>
          <li>
            DEA Certificate
          </li>
          <li>
            Current CV - listing work history in the MM/YYYY format and any gap of 6 months or more
          </li>
          <li>
            Board Certificate (s) – If not certified, please complete attached attestation form
          </li>
          <li>
            Medical School Diploma/Graduate School Diploma
          </li>
          <li>
            Internship Certificate of Completion (If Applicable)
          </li>
          <li>
            Residency Certificate of Completion (If Applicable)
          </li>
          <li>
            Fellowship Certificate of Completion (If Applicable)
          </li>
          <li>
            Malpractice Claim Information (If Applicable
          </li>
          <li>
            Hospital Privileges, case logs (If Applicable)
          </li>
          <li>
            Activity Logs from previous 24 months. If none, need job duties/activities from previous employers from the
            last 2 years.
          </li>
          <li>
            Current CME credits from previous 36 months
          </li>
          <li>
            Current Flu documentation
          </li>
          <li>
            Current COVID Documentation
          </li>
          <li>
            Current TB Test Results. If positive results send Chest X-ray report.
          </li>
          <li>
            Current Headshot Photograph
          </li>
          <li>
            BLS, ACLS, PALS, NRP
          </li>
        </ul>
      </div>
      <div class="sectionTen mb-4">
        <h4>Section 11: Care Statement </h4>
        <h5 class="mandatory warn mb-3">
          *Please visit the link below to complete the biography for use at OnPoint Medical Group.
        </h5>
        <a href="https://opmedgroup.com/new-provider-bio-form/"
          target="_blank">https://opmedgroup.com/new-provider-bio-form/</a>
      </div>
      <div class="row">
        <div class="col-8 form-inline">
          <label (click)="esignOpenOne()">Signature <span class="mandatory">*</span></label>
          <img *ngIf="!showOne" class="img" src="./assets/images/e_sign.png" alt="Credential my Doc"
            (click)="esignOpenOne()" />
          <img *ngIf="showOne" class="img eSignImageShow" [src]="esignOne" alt="Credential my Doc"
            (click)="esignOpenOne()" />
          <input type="hidden" name="cmd_2" id="cmd_2" formControlName="cmd_2" value="{{ finalImgPath }}" />
          <!-- <div class="input-group">
            <input   class="form-control"  name="cmd_1"
                  placeholder="MM/DD/YYYY" ngbDatepicker #cmd_1="ngbDatepicker"
                  placement="bottom" formControlName="cmd_1" appMaskInput [appMaskValue]="this.onPointForm.controls['cmd_1'].value">
              
            <div class="input-group-append">
              <button class="btn btn-outline-secondary calendar" (click)="cmd_1.toggle()" type="button">
                <i class="fas fa-calendar-alt"></i>
              </button>
              <span class="mandatory">*</span>
            </div>
            <div class="mandatory" *ngIf="isSubmit &&onPointForm.controls['cmd_1'].invalid">
              <small *ngIf="onPointForm.controls['cmd_1'].errors?.required">
                required
              </small>
            </div>
          </div> -->
         
              <div class="input-group">
                <input class="form-control"  name="cmd_1"
                [class.is-invalid]="
                (onPointForm.controls['cmd_1'].touched &&
                  onPointForm.controls['cmd_1'].invalid) ||
                (onPointForm.controls['cmd_1'].invalid && isSubmit)"
                      placeholder="MM/DD/YYYY" 
               appMaskInput [appMaskValue]="this.onPointForm.controls['cmd_1'].value"ngbDatepicker #cmd_1="ngbDatepicker"
                      placement="bottom" formControlName="cmd_1"         (blur)="clearInputIfInvalidMandi('cmd_1')" >
                  <div class="input-group-append">
                      <button class="btn btn-outline-secondary calendar" (click)="cmd_1.toggle()"
                          type="button"><i class="fas fa-calendar-alt"></i></button>
                          <span class="mandatory">*</span>
                  </div>
             </div> 
        <!-- </div> -->

            
        <div class="mandatory" *ngIf="(onPointForm.controls['cmd_1'].invalid&&onPointForm.controls['cmd_1'].touched) && (onPointForm.controls['cmd_1'].value==''|| onPointForm.controls['cmd_1'].value==null) " >
          <small >
          required
          </small>

      </div>
        <small class="text-danger" *ngIf="onPointForm.get('cmd_1').errors?.invalidDateFormat">
          Only Date Format is allowed(MM/DD/YYYY)                </small>
        <small class="text-danger" *ngIf="onPointForm.get('cmd_1').errors?.invalidMonth">
        Month is out of range
        </small>
        <small class="text-danger" *ngIf="onPointForm.get('cmd_1').errors?.invalidDateyear">
          year is out of range(1900-2099)                  </small>
          <small class="text-danger" *ngIf="onPointForm.get('cmd_1').errors?.invalidDateRange">
            date is out of range
            </small>   
              <!-- </div> -->
        </div>
        <div class="col-6 form-group">

        </div>
      </div>
    </form>
    <button *ngIf="formData.receivedForm" type="button" class="btn customOrange float-right"
      (click)="saveForm('approve')">
      Approve
    </button>
    <button *ngIf="formData.submitForm" type="button" class="btn btn customOrange float-right"
      (click)="saveForm('submit')">
      Submit
    </button>
    <button *ngIf="formData.submitForm" type="button" class="btn btn customOrange float-right"
      (click)="saveForm('save')">
      Save
    </button>

    <button *ngIf="formData.previewForm" type="button" class="btn customOrange float-right" (click)="close()">
      Close
    </button>
  </div>
</div>