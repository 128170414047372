<div id="dicksonmedmd" >
  <form [formGroup]="DicksonMDFroms1"> 
    <hr class="tablePersonal" style="border: 17px solid #818181;" />
          <table border="1" width="100%">
            <tr >
              <td colspan="18" width="100%" style="text-align: center;">
                
                  <b style="font-size:16px;text-align: center;" >Other Current Hospital Affiliations</b>
                
              </td>
            </tr>
            <tr>
              <td colspan="9">
                <div
                  class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel"
                    >OTHER HOSPITAL WHERE YOU HAVE PRIVILEGES
                  </label>
                  <input
                    class="form-control"
                    id="436"
                    name="436"
                    formControlName="436"
                    type="text"
                    data-id="hospaff2nm"
                  />
                </div>
              </td>
              <td colspan="9">
                <div
                  class="form-group form-inline no-margin col-md-5 sideLabelAlignment"
                >
                  <label class="formLabel">START DATE (MM/DD/YYYY) </label>
                  <div class="input-group">
                    <input
                      class="form-control onboard datepicker"
                      placeholder="MM/DD/YYYY"
                      ngbDatepicker
                      #d444="ngbDatepicker"
                      id="444"
                      name="444"
                      formControlName="444"
                      type="text"
                      data-id="hospaff2app"
                      (blur)="clearInputIfInvalid('444')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="d444.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div  *ngIf="DicksonMDFroms1.get('444').invalid">
                    <small class="text-danger" *ngIf="DicksonMDFroms1.get('444').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms1.get('444').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms1.get('444').errors?.invalidDateyear">
                      year is out of range(1900-2099)
                      </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms1.get('444').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div
                  class="row"
                >
                  <div class="col-md-1">
                    <label class="formLabel">ADDRESS</label></div>&nbsp;&nbsp;
                  <div class="col-md-2">  
                    <input
                      class="form-control"
                      id="437"
                      name="437"
                      formControlName="437"
                      type="text"
                      data-id="hospaff2add.0"
                    />
                  </div>
                  <div class="col-md-2">    
                    <input
                      class="form-control"
                      id="438"
                      name="438"
                      formControlName="438"
                      type="text"
                      data-id="hospaff2add.1"
                    />
                  </div>  
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div
                  class="row"
                >
                  <div class="col-md-1">
                    <label class="formLabel">CITY</label>
                  </div>  
                  <div class="col-md-2">
                    <input
                      class="form-control"
                      id="439"
                      name="439"
                      formControlName="439"
                      type="text"
                      placeholder="City"
                      data-id="hospaff2city"
                    />
                  </div>
                  <div
                    class="col-md-2"
                  >
                    <label class="formLabel">STATE/COUNTRY</label>
                  </div>  
                  
                    <div class="col-md-2">
                      <ng-select
                        [items]="states"
                        bindLabel="name"
                        placeholder="Select States"
                        bindValue="id"
                        formControlName="441"
                        
                      >
                      </ng-select>
                    </div>
                   
                  
                  <div
                    class="col-md-2"
                  >
                    <label class="formLabel">POSTAL CODE</label>
                  </div>
                  <div
                    class="col-md-2"
                  >
                    <input
                      class="form-control"
                      id="442"
                      name="442"
                      formControlName="442"
                      type="text"
                      placeholder="Zip"
                      data-id="hospaff2zip"
                    />
                    <small
                    class="text-danger"
                    *ngIf="DicksonMDFroms1.controls['442'].errors?.pattern"
                    > Zip code must be atleast 5 digits and less than 10 digits.</small>
                  </div>  
          
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6">
                <div
                  class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel">PHONE NUMBER </label>&nbsp;
                  <input
                    class="form-control"
                    (keypress)="numberOnly($event)"
                    id="1309"
                    name="1309"
                    formControlName="1309"
                    type="text"
                    data-id="hospaff2phone"
                  />
                </div>
              </td>
              <td colspan="6">
                <div
                  class="form-group form-inline no-margin col-md-8 sideLabelAlignment"
                >
                  <label class="formLabel">FAX</label>&nbsp;&nbsp;&nbsp;
                  <input
                    class="form-control"
                    id="2138"
                    name="2138"
                    formControlName="2138"
                    type="text"
                    data-id="hospaff2fax"
                  />
                </div>
              </td>
              <td colspan="6">
                <div
                  class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel">E-MAIL</label>&nbsp;
                  <input
                    class="form-control"
                    id="2144"
                    name="2144"
                    formControlName="2144"
                    type="text"
                    data-id="hospaff2email"
                  />
                  <small
                        class="text-danger"
                        *ngIf="DicksonMDFroms1.controls['2144'].errors?.pattern"
                        >Please enter a valid email.</small
                      >
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6">
                <div class="form-inline no-margin col-md-12 sideLabelAlignment">
                  <label class="formLabel">FULL UNRESTRICTED PRIVILEGES? </label>&nbsp;
                  <label class="formLabel">
                    <input
                      type="radio"
                      id="cmd_0064"
                      name="cmd_0064"
                      formControlName="cmd_0064"
                      value="1"
                    />Yes</label
                  >&nbsp;
                  <label class="formLabel">
                    <input
                      type="radio"
                      id="cmd_0064"
                      name="cmd_0064"
                      formControlName="cmd_0064"
                      value="0"
                    />No</label
                  >
                </div>
              </td>
              <td colspan="6">
                <div class="col-md-12">
                  <label class="formLabel"
                    >TYPES OF PRIVILEGES (PROVISIONAL, LIMITED, CONDITIONAL, ETC.)
                  </label>
                </div>  
                  <div class="col-md-8">
                    <ng-select
                      [items]="staffStatus"
                      bindLabel="name"
                      placeholder="Select Status"
                      bindValue="id"
                      formControlName="896"
                    >
                    </ng-select>
                  </div>
                
              </td>
              <td colspan="6">
                <div class="form-inline no-margin col-md-12 sideLabelAlignment">
                  <label class="formLabel">ARE PRIVILEGES TEMPORARY? </label>&nbsp;
                  <label class="formLabel">
                    <input
                      type="radio"
                      id="cmd_0065"
                      name="cmd_0065"
                      formControlName="cmd_0065"
                      value="1"
                    />Yes</label
                  >&nbsp;
                  <label class="formLabel">
                    <input
                      type="radio"
                      id="cmd_0065"
                      name="cmd_0065"
                      formControlName="cmd_0065"
                      value="0"
                    />No</label
                  >
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div
                  class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel"
                    >OF THE TOTAL NUMBER OF ADMISSIONS TO ALL HOSPITALS IN THE
                    PAST YEAR, WHAT PERCENTAGE IS TO PRIMARY HOSPITAL?
                  </label>
                  <input
                    class="form-control"
                    id="cmd_0066"
                    name="cmd_0066"
                    formControlName="cmd_0066"
                    type="text"
                    data-id="hospaff2admissons"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="9">
                <div
                  class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel"
                    >OTHER HOSPITAL WHERE YOU HAVE PRIVILEGES
                  </label>
                  <input
                    class="form-control"
                    id="add_1_986"
                    name="add_1_986"
                    formControlName="add_1_986"
                    type="text"
                    data-id="hospaff2nm"
                  />
                </div>
              </td>
              <td colspan="9">
                <div
                  class="form-group form-inline no-margin col-md-5 sideLabelAlignment"
                >
                  <label class="formLabel">START DATE (MM/DD/YYYY) </label>
                  <div class="input-group">
                    <input
                      class="form-control onboard datepicker"
                      placeholder="MM/DD/YYYY"
                      ngbDatepicker
                      #d994="ngbDatepicker"
                      id="add_1_994"
                      name="add_1_994"
                      formControlName="add_1_994"
                      type="text"
                      data-id="hospaff2app"
                      (blur)="clearInputIfInvalid('add_1_994')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="d994.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div  *ngIf="DicksonMDFroms1.get('add_1_994').invalid">
                    <small class="text-danger" *ngIf="DicksonMDFroms1.get('add_1_994').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms1.get('add_1_994').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms1.get('add_1_994').errors?.invalidDateyear">
                      year is out of range(1900-2099)
                      </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms1.get('add_1_994').errors?.invalidDateRange">
                        date is out of range
                        </small>
                  </div>
                  <div class="input-group">
                      <input
                        class="form-control onboard datepicker"
                        placeholder="MM/DD/YYYY"
                        ngbDatepicker
                        #d1373="ngbDatepicker"
                        id="add_1_1373"
                        name="add_1_1373"
                        formControlName="add_1_1373"
                        type="text"
                        data-id="hospaff2app"
                        (blur)="clearInputIfInvalid('add_1_1373')"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-outline-secondary calendar"
                          (click)="d1373.toggle()"
                          type="button"
                        >
                          <i class="fas fa-calendar-alt"></i>
                        </button>
                      </div>
                    </div>
                    <div  *ngIf="DicksonMDFroms1.get('add_1_1373').invalid">
                      <small class="text-danger" *ngIf="DicksonMDFroms1.get('add_1_1373').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)
                      </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms1.get('add_1_1373').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms1.get('add_1_1373').errors?.invalidDateyear">
                        year is out of range(1900-2099)
                        </small>
                        <small class="text-danger" *ngIf="DicksonMDFroms1.get('add_1_1373').errors?.invalidDateRange">
                          date is out of range
                          </small>
                    </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div
                  class="row"
                >
                  <div class="col-md-1">
                    <label class="formLabel">ADDRESS</label></div>&nbsp;&nbsp;
                  <div class="col-md-2">  
                    <input
                      class="form-control"
                      id="add_1_987"
                      name="add_1_987"
                      formControlName="add_1_987"
                      type="text"
                      data-id="hospaff2add.0"
                    />
                  </div>
                  <div class="col-md-2">    
                    <input
                      class="form-control"
                      id="add_1_988"
                      name="add_1_988"
                      formControlName="add_1_988"
                      type="text"
                      data-id="hospaff2add.1"
                    />
                  </div>  
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div
                  class="row"
                >
                  <div class="col-md-1">
                    <label class="formLabel">CITY</label>
                  </div>  
                  <div class="col-md-2">
                    <input
                      class="form-control"
                      id="add_1_989"
                      name="add_1_989"
                      formControlName="add_1_989"
                      type="text"
                      placeholder="City"
                      data-id="hospaff2city"
                    />
                  </div>
                  <div
                    class="col-md-2"
                  >
                    <label class="formLabel">STATE/COUNTRY</label>
                  </div>  
                  
                    <div class="col-md-2">
                      <ng-select
                        [items]="states"
                        bindLabel="name"
                        placeholder="Select States"
                        bindValue="id"
                        formControlName="add_1_991"
                        
                      >
                      </ng-select>
                    </div>
                   
                  
                  <div
                    class="col-md-2"
                  >
                    <label class="formLabel">POSTAL CODE</label>
                  </div>
                  <div
                    class="col-md-2"
                  >
                    <input
                      class="form-control"
                      id="add_1_992"
                      name="add_1_992"
                      formControlName="add_1_992"
                      type="text"
                      placeholder="Zip"
                      data-id="hospaff2zip"
                    />
                    <small
                    class="text-danger"
                    *ngIf="DicksonMDFroms1.controls['add_1_992'].errors?.pattern"
                    > Zip code must be atleast 5 digits and less than 10 digits.</small>
                  </div>  
          
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6">
                <div
                  class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel">PHONE NUMBER </label>&nbsp;
                  <input
                    class="form-control"
                    (keypress)="numberOnly($event)"
                    id="add_1_1311"
                    name="add_1_1311"
                    formControlName="add_1_1311"
                    type="text"
                    data-id="hospaff2phone"
                  />
                </div>
              </td>
              <td colspan="6">
                <div
                  class="form-group form-inline no-margin col-md-8 sideLabelAlignment"
                >
                  <label class="formLabel">FAX</label>&nbsp;&nbsp;&nbsp;
                  <input
                    class="form-control"
                    id="add_1_2139"
                    name="add_1_2139"
                    formControlName="add_1_2139"
                    type="text"
                    data-id="hospaff2fax"
                  />
                </div>
              </td>
              <td colspan="6">
                <div
                  class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel">E-MAIL</label>&nbsp;
                  <input
                    class="form-control"
                    id="add_1_2145"
                    name="add_1_2145"
                    formControlName="add_1_2145"
                    type="text"
                    data-id="hospaff2email"
                  />
                  <small
                        class="text-danger"
                        *ngIf="DicksonMDFroms1.controls['add_1_2145'].errors?.pattern"
                        >Please enter a valid email.</small
                      >
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6">
                <div class="form-inline no-margin col-md-12 sideLabelAlignment">
                  <label class="formLabel">FULL UNRESTRICTED PRIVILEGES? </label>&nbsp;
                  <label class="formLabel">
                    <input
                      type="radio"
                      id="cmd_00064"
                      name="cmd_00064"
                      formControlName="cmd_00064"
                      value="1"
                    />Yes</label
                  >&nbsp;
                  <label class="formLabel">
                    <input
                      type="radio"
                      id="cmd_00064"
                      name="cmd_00064"
                      formControlName="cmd_00064"
                      value="0"
                    />No</label
                  >
                </div>
              </td>
              <td colspan="6">
                <div class="col-md-12">
                  <label class="formLabel"
                    >TYPES OF PRIVILEGES (PROVISIONAL, LIMITED, CONDITIONAL, ETC.)
                  </label>
                </div>  
                  <div class="col-md-8">
                    <ng-select
                      [items]="staffStatus"
                      bindLabel="name"
                      placeholder="Select Status"
                      bindValue="id"
                      formControlName="add_1_995"
                    >
                    </ng-select>
                  </div>
                
              </td>
              <td colspan="6">
                <div class="form-inline no-margin col-md-12 sideLabelAlignment">
                  <label class="formLabel">ARE PRIVILEGES TEMPORARY? </label>&nbsp;
                  <label class="formLabel">
                    <input
                      type="radio"
                      id="cmd_00065"
                      name="cmd_00065"
                      formControlName="cmd_00065"
                      value="1"
                    />Yes</label
                  >&nbsp;
                  <label class="formLabel">
                    <input
                      type="radio"
                      id="cmd_00065"
                      name="cmd_00065"
                      formControlName="cmd_00065"
                      value="0"
                    />No</label
                  >
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div
                  class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel"
                    >OF THE TOTAL NUMBER OF ADMISSIONS TO ALL HOSPITALS IN THE
                    PAST YEAR, WHAT PERCENTAGE IS TO PRIMARY HOSPITAL?
                  </label>
                  <input
                    class="form-control"
                    id="cmd_00066"
                    name="cmd_00066"
                    formControlName="cmd_00066"
                    type="text"
                    data-id="hospaff2admissons"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="9">
                <div
                  class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel"
                    >OTHER HOSPITAL WHERE YOU HAVE PRIVILEGES
                  </label>
                  <input
                    class="form-control"
                    id="add_2_986"
                    name="add_2_986"
                    formControlName="add_2_986"
                    type="text"
                    data-id="hospaff2nm"
                  />
                </div>
              </td>
              <td colspan="9">
                <div
                  class="form-group form-inline no-margin col-md-5 sideLabelAlignment"
                >
                  <label class="formLabel">START DATE (MM/DD/YYYY) </label>
                  <div class="input-group">
                    <input
                      class="form-control onboard datepicker"
                      placeholder="MM/DD/YYYY"
                      ngbDatepicker
                      #d0994="ngbDatepicker"
                      id="add_2_994"
                      name="add_2_994"
                      formControlName="add_2_994"
                      type="text"
                      data-id="hospaff2app"
                      (blur)="clearInputIfInvalid('add_2_994')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="d0994.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div  *ngIf="DicksonMDFroms1.get('add_2_994').invalid">
                    <small class="text-danger" *ngIf="DicksonMDFroms1.get('add_2_994').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms1.get('add_2_994').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms1.get('add_2_994').errors?.invalidDateyear">
                      year is out of range(1900-2099)
                      </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms1.get('add_2_994').errors?.invalidDateRange">
                        date is out of range
                        </small>
                  </div>
                  <div class="input-group">
                      <input
                        class="form-control onboard datepicker"
                        placeholder="MM/DD/YYYY"
                        ngbDatepicker
                        #d01373="ngbDatepicker"
                        id="add_2_1373"
                        name="add_2_1373"
                        formControlName="add_2_1373"
                        type="text"
                        data-id="hospaff2app"
                        (blur)="clearInputIfInvalid('add_2_1373')"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-outline-secondary calendar"
                          (click)="d01373.toggle()"
                          type="button"
                        >
                          <i class="fas fa-calendar-alt"></i>
                        </button>
                      </div>
                    </div>
                    <div  *ngIf="DicksonMDFroms1.get('add_2_1373').invalid">
                      <small class="text-danger" *ngIf="DicksonMDFroms1.get('add_2_1373').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)
                      </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms1.get('add_2_1373').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms1.get('add_2_1373').errors?.invalidDateyear">
                        year is out of range(1900-2099)
                        </small>
                        <small class="text-danger" *ngIf="DicksonMDFroms1.get('add_2_1373').errors?.invalidDateRange">
                          date is out of range
                          </small>
                    </div>
                </div>
              </td>
            </tr>
            
   
    <tr>
      <td colspan="18">
        <div
          class="row"
        >
          <div class="col-md-1">
            <label class="formLabel">ADDRESS</label></div>&nbsp;&nbsp;
          <div class="col-md-2">  
            <input
              class="form-control"
              id="add_2_987"
              name="add_2_987"
              formControlName="add_2_987"
              type="text"
              data-id="hospaff2add.0"
            />
          </div>
          <div class="col-md-2">    
            <input
              class="form-control"
              id="add_2_988"
              name="add_2_988"
              formControlName="add_2_988"
              type="text"
              data-id="hospaff2add.1"
            />
          </div>  
        </div>
      </td>
    </tr>

    <tr>
      <td colspan="18">
        <div
          class="row"
        >
          <div class="col-md-1">
            <label class="formLabel">CITY</label>
          </div>  
          <div class="col-md-2">
            <input
              class="form-control"
              id="add_2_989"
              name="add_2_989"
              formControlName="add_2_989"
              type="text"
              placeholder="City"
              data-id="hospaff2city"
            />
          </div>
          <div
            class="col-md-2"
          >
            <label class="formLabel">STATE/COUNTRY</label>
          </div>  
          
            <div class="col-md-2">
              <ng-select
                [items]="states"
                bindLabel="name"
                placeholder="Select States"
                bindValue="id"
                formControlName="add_2_991"
                
              >
              </ng-select>
            </div>
           
          
          <div
            class="col-md-2"
          >
            <label class="formLabel">POSTAL CODE</label>
          </div>
          <div
            class="col-md-2"
          >
            <input
              class="form-control"
              id="add_2_992"
              name="add_2_992"
              formControlName="add_2_992"
              type="text"
              placeholder="Zip"
              data-id="hospaff2zip"
            />
            <small
            class="text-danger"
            *ngIf="DicksonMDFroms1.controls['add_2_992'].errors?.pattern"
            > Zip code must be atleast 5 digits and less than 10 digits.</small>
          </div>  
  
        </div>
      </td>
    </tr>
    <tr>
      <td colspan="6">
        <div
          class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
        >
          <label class="formLabel">PHONE NUMBER </label>&nbsp;
          <input
            class="form-control"
            (keypress)="numberOnly($event)"
            id="add_2_1311"
            name="add_2_1311"
            formControlName="add_2_1311"
            type="text"
            data-id="hospaff2phone"
          />
        </div>
      </td>
      <td colspan="6">
        <div
          class="form-group form-inline no-margin col-md-8 sideLabelAlignment"
        >
          <label class="formLabel">FAX</label>&nbsp;&nbsp;&nbsp;
          <input
            class="form-control"
            id="add_2_2139"
            name="add_2_2139"
            formControlName="add_2_2139"
            type="text"
            data-id="hospaff2fax"
          />
        </div>
      </td>
      <td colspan="6">
        <div
          class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
        >
          <label class="formLabel">E-MAIL</label>&nbsp;
          <input
            class="form-control"
            id="add_2_2145"
            name="add_2_2145"
            formControlName="add_2_2145"
            type="text"
            data-id="hospaff2email"
          />
          <small
                class="text-danger"
                *ngIf="DicksonMDFroms1.controls['add_2_2145'].errors?.pattern"
                >Please enter a valid email.</small
              >
        </div>
      </td>
    </tr>
    <tr>
      <td colspan="6">
        <div class="form-inline no-margin col-md-12 sideLabelAlignment">
          <label class="formLabel">FULL UNRESTRICTED PRIVILEGES? </label>&nbsp;
          <label class="formLabel">
            <input
              type="radio"
              id="cmd_000064"
              name="cmd_000064"
              formControlName="cmd_000064"
              value="1"
            />Yes</label
          >&nbsp;
          <label class="formLabel">
            <input
              type="radio"
              id="cmd_000064"
              name="cmd_000064"
              formControlName="cmd_000064"
              value="0"
            />No</label
          >
        </div>
      </td>
      <td colspan="6">
        <div class="col-md-12">
          <label class="formLabel"
            >TYPES OF PRIVILEGES (PROVISIONAL, LIMITED, CONDITIONAL, ETC.)
          </label>
        </div>  
          <div class="col-md-8">
            <ng-select
              [items]="staffStatus"
              bindLabel="name"
              placeholder="Select Status"
              bindValue="id"
              formControlName="add_2_995"
            >
            </ng-select>
          
          </div>
      </td>
      <td colspan="6">
        <div class="form-inline no-margin col-md-12 sideLabelAlignment">
          <label class="formLabel">ARE PRIVILEGES TEMPORARY? </label>&nbsp;
          <label class="formLabel">
            <input
              type="radio"
              id="cmd_000065"
              name="cmd_000065"
              formControlName="cmd_000065"
              value="1"
            />Yes</label
          >&nbsp;
          <label class="formLabel">
            <input
              type="radio"
              id="cmd_000065"
              name="cmd_000065"
              formControlName="cmd_000065"
              value="0"
            />No</label
          >
        </div>
      </td>
    </tr>
    <tr>
      <td colspan="18">
        <div
          class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
        >
          <label class="formLabel"
            >OF THE TOTAL NUMBER OF ADMISSIONS TO ALL HOSPITALS IN THE
            PAST YEAR, WHAT PERCENTAGE IS TO PRIMARY HOSPITAL?
          </label>
          <input
            class="form-control"
            id="cmd_000066"
            name="cmd_000066"
            formControlName="cmd_000066"
            type="text"
            data-id="hospaff2admissons"
          />
        </div>
      </td>
    </tr>
    <tr>
      <td colspan="9">
        <div
          class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
        >
          <label class="formLabel"
            >OTHER HOSPITAL WHERE YOU HAVE PRIVILEGES
          </label>
          <input
            class="form-control"
            id="add_3_986"
            name="add_3_986"
            formControlName="add_3_986"
            type="text"
            data-id="hospaff2nm"
          />
        </div>
      </td>
      <td colspan="9">
        <div
          class="form-group form-inline no-margin col-md-5 sideLabelAlignment"
        >
          <label class="formLabel">START DATE (MM/DD/YYYY) </label>
          <div class="input-group">
            <input
              class="form-control onboard datepicker"
              placeholder="MM/DD/YYYY"
              ngbDatepicker
              #d00994="ngbDatepicker"
              id="add_3_994"
              name="add_3_994"
              formControlName="add_3_994"
              type="text"
              data-id="hospaff2app"
              (blur)="clearInputIfInvalid('add_3_994')"
            />
            <div class="input-group-append">
              <button
                class="btn btn-outline-secondary calendar"
                (click)="d00994.toggle()"
                type="button"
              >
                <i class="fas fa-calendar-alt"></i>
              </button>
            </div>
          </div>
          <div  *ngIf="DicksonMDFroms1.get('add_3_994').invalid">
            <small class="text-danger" *ngIf="DicksonMDFroms1.get('add_3_994').errors?.invalidDateFormat">
              Only Date Format is allowed(MM/DD/YYYY)
            </small>
            <small class="text-danger" *ngIf="DicksonMDFroms1.get('add_3_994').errors?.invalidMonth">
            Month is out of range
            </small>
            <small class="text-danger" *ngIf="DicksonMDFroms1.get('add_3_994').errors?.invalidDateyear">
              year is out of range(1900-2099)
              </small>
              <small class="text-danger" *ngIf="DicksonMDFroms1.get('add_3_994').errors?.invalidDateRange">
                date is out of range
                </small>
          </div>
          <div class="input-group">
              <input
                class="form-control onboard datepicker"
                placeholder="MM/DD/YYYY"
                ngbDatepicker
                #d001373="ngbDatepicker"
                id="add_3_1373"
                name="add_3_1373"
                formControlName="add_3_1373"
                type="text"
                data-id="hospaff2app"
                (blur)="clearInputIfInvalid('add_3_1373')"
              />
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary calendar"
                  (click)="d001373.toggle()"
                  type="button"
                >
                  <i class="fas fa-calendar-alt"></i>
                </button>
              </div>
            </div>
            <div  *ngIf="DicksonMDFroms1.get('add_3_1373').invalid">
              <small class="text-danger" *ngIf="DicksonMDFroms1.get('add_3_1373').errors?.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY)
              </small>
              <small class="text-danger" *ngIf="DicksonMDFroms1.get('add_3_1373').errors?.invalidMonth">
              Month is out of range
              </small>
              <small class="text-danger" *ngIf="DicksonMDFroms1.get('add_3_1373').errors?.invalidDateyear">
                year is out of range(1900-2099)
                </small>
                <small class="text-danger" *ngIf="DicksonMDFroms1.get('add_3_1373').errors?.invalidDateRange">
                  date is out of range
                  </small>
            </div>
        </div>
      </td>
    </tr>
    <tr>
      <td colspan="18">
        <div
          class="row"
        >
          <div class="col-md-1">
            <label class="formLabel">ADDRESS</label></div>&nbsp;&nbsp;
          <div class="col-md-2">  
            <input
              class="form-control"
              id="add_3_987"
              name="add_3_987"
              formControlName="add_3_987"
              type="text"
              data-id="hospaff2add.0"
            />
          </div>
          <div class="col-md-2">    
            <input
              class="form-control"
              id="add_3_988"
              name="add_3_988"
              formControlName="add_3_988"
              type="text"
              data-id="hospaff2add.1"
            />
          </div>  
        </div>
      </td>
    </tr>
    <tr>
      <td colspan="18">
        <div
          class="row"
        >
          <div class="col-md-1">
            <label class="formLabel">CITY</label>
          </div>  
          <div class="col-md-2">
            <input
              class="form-control"
              id="add_3_989"
              name="add_3_989"
              formControlName="add_3_989"
              type="text"
              placeholder="City"
              data-id="hospaff2city"
            />
          </div>
          <div
            class="col-md-2"
          >
            <label class="formLabel">STATE/COUNTRY</label>
          </div>  
          
            <div class="col-md-2">
              <ng-select
                [items]="states"
                bindLabel="name"
                placeholder="Select States"
                bindValue="id"
                formControlName="add_3_991"
                
              >
              </ng-select>
            </div>
           
          
          <div
            class="col-md-2"
          >
            <label class="formLabel">POSTAL CODE</label>
          </div>
          <div
            class="col-md-2"
          >
            <input
              class="form-control"
              id="add_3_992"
              name="add_3_992"
              formControlName="add_3_992"
              type="text"
              placeholder="Zip"
              data-id="hospaff2zip"
            />
            <small
            class="text-danger"
            *ngIf="DicksonMDFroms1.controls['add_3_992'].errors?.pattern"
            > Zip code must be atleast 5 digits and less than 10 digits.</small>
          </div>  
  
        </div>
      </td>
    </tr>
    <tr>
      <td colspan="6">
        <div
          class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
        >
          <label class="formLabel">PHONE NUMBER </label>&nbsp;
          <input
            class="form-control"
            (keypress)="numberOnly($event)"
            id="add_3_1311"
            name="add_3_1311"
            formControlName="add_3_1311"
            type="text"
            data-id="hospaff2phone"
          />
        </div>
      </td>
      <td colspan="6">
        <div
          class="form-group form-inline no-margin col-md-8 sideLabelAlignment"
        >
          <label class="formLabel">FAX</label>&nbsp;&nbsp;&nbsp;
          <input
            class="form-control"
            id="add_3_2139"
            name="add_3_2139"
            formControlName="add_3_2139"
            type="text"
            data-id="hospaff2fax"
          />
        </div>
      </td>
      <td colspan="6">
        <div
          class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
        >
          <label class="formLabel">E-MAIL</label>&nbsp;
          <input
            class="form-control"
            id="add_3_2145"
            name="add_3_2145"
            formControlName="add_3_2145"
            type="text"
            data-id="hospaff2email"
          />
          <small
                class="text-danger"
                *ngIf="DicksonMDFroms1.controls['add_3_2145'].errors?.pattern"
                >Please enter a valid email.</small
              >
        </div>
      </td>
    </tr>
    <tr>
      <td colspan="6">
        <div class="form-inline no-margin col-md-12 sideLabelAlignment">
          <label class="formLabel">FULL UNRESTRICTED PRIVILEGES? </label>&nbsp;
          <label class="formLabel">
            <input
              type="radio"
              id="cmd_0000064"
              name="cmd_0000064"
              formControlName="cmd_0000064"
              value="1"
            />Yes</label
          >&nbsp;
          <label class="formLabel">
            <input
              type="radio"
              id="cmd_0000064"
              name="cmd_0000064"
              formControlName="cmd_0000064"
              value="0"
            />No</label
          >
        </div>
      </td>
      <td colspan="6">
        <div class="col-md-12">
          <label class="formLabel"
            >TYPES OF PRIVILEGES (PROVISIONAL, LIMITED, CONDITIONAL, ETC.)
          </label>
        </div>  
          <div class="col-md-8">
            <ng-select
              [items]="staffStatus"
              bindLabel="name"
              placeholder="Select Status"
              bindValue="id"
              formControlName="add_3_995"
            >
            </ng-select>
          </div>
        
      </td>
      <td colspan="6">
        <div class="form-inline no-margin col-md-12 sideLabelAlignment">
          <label class="formLabel">ARE PRIVILEGES TEMPORARY? </label>&nbsp;
          <label class="formLabel">
            <input
              type="radio"
              id="cmd_0000065"
              name="cmd_0000065"
              formControlName="cmd_0000065"
              value="1"
            />Yes</label
          >&nbsp;
          <label class="formLabel">
            <input
              type="radio"
              id="cmd_0000065"
              name="cmd_0000065"
              formControlName="cmd_0000065"
              value="0"
            />No</label
          >
        </div>
      </td>
    </tr>
    <tr>
      <td colspan="18">
        <div
          class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
        >
          <label class="formLabel"
            >OF THE TOTAL NUMBER OF ADMISSIONS TO ALL HOSPITALS IN THE
            PAST YEAR, WHAT PERCENTAGE IS TO PRIMARY HOSPITAL?
          </label>
          <input
            class="form-control"
            id="cmd_0000066"
            name="cmd_0000066"
            formControlName="cmd_0000066"
            type="text"
            data-id="hospaff2admissons"
          />
        </div>
      </td>
    </tr>
    <tr>
      <td colspan="9">
        <div
          class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
        >
          <label class="formLabel"
            >OTHER HOSPITAL WHERE YOU HAVE PRIVILEGES
          </label>
          <input
            class="form-control"
            id="add_4_986"
            name="add_4_986"
            formControlName="add_4_986"
            type="text"
            data-id="hospaff2nm"
          />
        </div>
      </td>
      <td colspan="9">
        <div
          class="form-group form-inline no-margin col-md-5 sideLabelAlignment"
        >
          <label class="formLabel">START DATE (MM/DD/YYYY) </label>
          <div class="input-group">
            <input
              class="form-control onboard datepicker"
              placeholder="MM/DD/YYYY"
              ngbDatepicker
              #d000994="ngbDatepicker"
              id="add_4_994"
              name="add_4_994"
              formControlName="add_4_994"
              type="text"
              data-id="hospaff2app"
              (blur)="clearInputIfInvalid('add_4_994')"
            />
            <div class="input-group-append">
              <button
                class="btn btn-outline-secondary calendar"
                (click)="d000994.toggle()"
                type="button"
              >
                <i class="fas fa-calendar-alt"></i>
              </button>
            </div>
          </div>
          <div  *ngIf="DicksonMDFroms1.get('add_4_994').invalid">
            <small class="text-danger" *ngIf="DicksonMDFroms1.get('add_4_994').errors?.invalidDateFormat">
              Only Date Format is allowed(MM/DD/YYYY)
            </small>
            <small class="text-danger" *ngIf="DicksonMDFroms1.get('add_4_994').errors?.invalidMonth">
            Month is out of range
            </small>
            <small class="text-danger" *ngIf="DicksonMDFroms1.get('add_4_994').errors?.invalidDateyear">
              year is out of range(1900-2099)
              </small>
              <small class="text-danger" *ngIf="DicksonMDFroms1.get('add_4_994').errors?.invalidDateRange">
                date is out of range
                </small>
          </div>
          <div class="input-group">
              <input
                class="form-control onboard datepicker"
                placeholder="MM/DD/YYYY"
                ngbDatepicker
                #d0001373="ngbDatepicker"
                id="add_4_1373"
                name="add_4_1373"
                formControlName="add_4_1373"
                type="text"
                data-id="hospaff2app"
                (blur)="clearInputIfInvalid('add_4_1373')"
              />
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary calendar"
                  (click)="d0001373.toggle()"
                  type="button"
                >
                  <i class="fas fa-calendar-alt"></i>
                </button>
              </div>
            </div>
            <div  *ngIf="DicksonMDFroms1.get('add_4_1373').invalid">
              <small class="text-danger" *ngIf="DicksonMDFroms1.get('add_4_1373').errors?.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY)
              </small>
              <small class="text-danger" *ngIf="DicksonMDFroms1.get('add_4_1373').errors?.invalidMonth">
              Month is out of range
              </small>
              <small class="text-danger" *ngIf="DicksonMDFroms1.get('add_4_1373').errors?.invalidDateyear">
                year is out of range(1900-2099)
                </small>
                <small class="text-danger" *ngIf="DicksonMDFroms1.get('add_4_1373').errors?.invalidDateRange">
                  date is out of range
                  </small>
            </div>
        </div>
      </td>
    </tr>
    <tr>
      <td colspan="18">
        <div
          class="row"
        >
          <div class="col-md-1">
            <label class="formLabel">ADDRESS</label></div>&nbsp;&nbsp;
          <div class="col-md-2">  
            <input
              class="form-control"
              id="add_4_987"
              name="add_4_987"
              formControlName="add_4_987"
              type="text"
              data-id="hospaff2add.0"
            />
          </div>
          <div class="col-md-2">    
            <input
              class="form-control"
              id="add_4_988"
              name="add_4_988"
              formControlName="add_4_988"
              type="text"
              data-id="hospaff2add.1"
            />
          </div>  
        </div>
      </td>
    </tr>
    <tr>
      <td colspan="18">
        <div
          class="row"
        >
          <div class="col-md-1">
            <label class="formLabel">CITY</label>
          </div>  
          <div class="col-md-2">
            <input
              class="form-control"
              id="add_4_989"
              name="add_4_989"
              formControlName="add_4_989"
              type="text"
              placeholder="City"
              data-id="hospaff2city"
            />
          </div>
          <div
            class="col-md-2"
          >
            <label class="formLabel">STATE/COUNTRY</label>
          </div>  
          
            <div class="col-md-2">
              <ng-select
                [items]="states"
                bindLabel="name"
                placeholder="Select States"
                bindValue="id"
                formControlName="add_4_991"
                
              >
              </ng-select>
            </div>
           
          
          <div
            class="col-md-2"
          >
            <label class="formLabel">POSTAL CODE</label>
          </div>
          <div
            class="col-md-2"
          >
            <input
              class="form-control"
              id="add_4_992"
              name="add_4_992"
              formControlName="add_4_992"
              type="text"
              placeholder="Zip"
              data-id="hospaff2zip"
            />
            <small
            class="text-danger"
            *ngIf="DicksonMDFroms1.controls['add_4_992'].errors?.pattern"
            > Zip code must be atleast 5 digits and less than 10 digits.</small>
          </div>  
  
        </div>
      </td>
    </tr>
    <tr>
      <td colspan="6">
        <div
          class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
        >
          <label class="formLabel">PHONE NUMBER </label>&nbsp;
          <input
            class="form-control"
            (keypress)="numberOnly($event)"
            id="add_4_1311"
            name="add_4_1311"
            formControlName="add_4_1311"
            type="text"
            data-id="hospaff2phone"
          />
        </div>
      </td>
      <td colspan="6">
        <div
          class="form-group form-inline no-margin col-md-8 sideLabelAlignment"
        >
          <label class="formLabel">FAX</label>&nbsp;&nbsp;&nbsp;
          <input
            class="form-control"
            id="add_4_2139"
            name="add_4_2139"
            formControlName="add_4_2139"
            type="text"
            data-id="hospaff2fax"
          />
        </div>
      </td>
      <td colspan="6">
        <div
          class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
        >
          <label class="formLabel">E-MAIL</label>&nbsp;
          <input
            class="form-control"
            id="add_4_2145"
            name="add_4_2145"
            formControlName="add_4_2145"
            type="text"
            data-id="hospaff2email"
          />
          <small
                class="text-danger"
                *ngIf="DicksonMDFroms1.controls['add_4_2145'].errors?.pattern"
                >Please enter a valid email.</small
              >
        </div>
      </td>
    </tr>
    <tr>
      <td colspan="6">
        <div class="form-inline no-margin col-md-12 sideLabelAlignment">
          <label class="formLabel">FULL UNRESTRICTED PRIVILEGES? </label>&nbsp;
          <label class="formLabel">
            <input
              type="radio"
              id="cmd_00000064"
              name="cmd_00000064"
              formControlName="cmd_00000064"
              value="1"
            />Yes</label
          >&nbsp;
          <label class="formLabel">
            <input
              type="radio"
              id="cmd_00000064"
              name="cmd_00000064"
              formControlName="cmd_00000064"
              value="0"
            />No</label
          >
        </div>
      </td>
      <td colspan="6">
        <div class="col-md-12">
          <label class="formLabel"
            >TYPES OF PRIVILEGES (PROVISIONAL, LIMITED, CONDITIONAL, ETC.)
          </label>
        </div>  
          <div class="col-md-8">
            <ng-select
              [items]="staffStatus"
              bindLabel="name"
              placeholder="Select Status"
              bindValue="id"
              formControlName="add_4_995"
            >
            </ng-select>
          </div>
        
      </td>
      <td colspan="6">
        <div class="form-inline no-margin col-md-12 sideLabelAlignment">
          <label class="formLabel">ARE PRIVILEGES TEMPORARY? </label>&nbsp;
          <label class="formLabel">
            <input
              type="radio"
              id="cmd_00000065"
              name="cmd_00000065"
              formControlName="cmd_00000065"
              value="1"
            />Yes</label
          >&nbsp;
          <label class="formLabel">
            <input
              type="radio"
              id="cmd_00000065"
              name="cmd_00000065"
              formControlName="cmd_00000065"
              value="0"
            />No</label
          >
        </div>
      </td>
    </tr>
    <tr>
      <td colspan="18">
        <div
          class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
        >
          <label class="formLabel"
            >OF THE TOTAL NUMBER OF ADMISSIONS TO ALL HOSPITALS IN THE
            PAST YEAR, WHAT PERCENTAGE IS TO PRIMARY HOSPITAL?
          </label>
          <input
            class="form-control"
            id="cmd_00000066"
            name="cmd_00000066"
            formControlName="cmd_00000066"
            type="text"
            data-id="hospaff2admissons"
          />
        </div>
      </td>
    </tr> 

          </table>          
          <hr class="tablePersonal" style="border: 17px solid #818181;" />
          <table border="1" width="100%">
            <tr >
              <td colspan="18" width="100%" style="text-align: center;">
                
                  <b style="font-size:16px;text-align: center;" >Other Previous Hospital Affiliations</b>
                
              </td>
            </tr>
            <tr>
              <td colspan="10">
                <div
                  class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel"
                    >PREVIOUS HOSPITAL WHERE YOU HAVE HAD PRIVILEGES</label
                  >
                  <input
                    class="form-control"
                    id="add_1_1183"
                    name="add_1_1183"
                    formControlName="add_1_1183"
                    type="text"
                    data-id="Pre aff 1"
                  />
                </div>
              </td>
              <td colspan="8">
                <div
                  class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel"
                    >AFFILIATION DATES (MM/DD/YYYY TO MM/DD/YYYY)
                  </label>
                  <div class="input-group">
                    <input
                      style="width: 30%;"
                      class="form-control onboard datepicker"
                      placeholder="MM/DD/YYYY"
                      ngbDatepicker
                      #d056="ngbDatepicker"
                      id="add_1_1191"
                      name="add_1_1191"
                      formControlName="add_1_1191"
                      type="text"
                      data-id="Pre aff 1 from"
                      (blur)="clearInputIfInvalid('add_1_1191')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="d056.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div  *ngIf="DicksonMDFroms1.get('add_1_1191').invalid ">
                    <small class="text-danger" *ngIf="DicksonMDFroms1.get('add_1_1191').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms1.get('add_1_1191').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms1.get('add_1_1191').errors?.invalidDateyear">
                      year is out of range(1900-2099)
                      </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms1.get('add_1_1191').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
                  <div class="input-group">
                    <input
                      class="form-control onboard datepicker"
                      placeholder="MM/DD/YYYY"
                      ngbDatepicker
                      #d057="ngbDatepicker"
                      id="add_1_2381"
                      name="add_1_2381"
                      formControlName="add_1_2381"
                      type="text"
                      data-id="Pre aff 1 to"
                      (blur)="clearInputIfInvalid('add_1_2381')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="d057.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div  *ngIf="DicksonMDFroms1.get('add_1_2381').invalid ">
                    <small class="text-danger" *ngIf="DicksonMDFroms1.get('add_1_2381').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms1.get('add_1_2381').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms1.get('add_1_2381').errors?.invalidDateyear">
                      year is out of range(1900-2099)
                      </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms1.get('add_1_2381').errors?.invalidDateRange">
                        date is out of range
                      </small>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div
                  class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel">ADDRESS</label>
                  <input
                    class="form-control"
                    id="add_1_1184"
                    name="add_1_1184"
                    formControlName="add_1_1184"
                    type="text"
                    data-id="Pre aff 1 add1"
                  />
                  <input
                    class="form-control"
                    id="add_1_1185"
                    name="add_1_1185"
                    formControlName="add_1_1185"
                    type="text"
                    data-id="Pre aff 1 add2"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div
                  class="row"
                  >
                  <div class="col-md-1">
                      <label class="formLabel">CITY</label>
                  </div>    
                  <div class="col-md-2">
                    <input
                      class="form-control"
                      id="add_1_1186"
                      name="add_1_1186"
                      formControlName="add_1_1186"
                      type="text"
                      placeholder="City"
                      data-id="Pre aff 1 city"
                    />
                  </div>
                  <div
                    class="col-md-2"
                  >
                    <label class="formLabel">STATE/COUNTRY</label>
                  </div>  
                  <div class="col-md-2">
                    <ng-select
                      [items]="states"
                      bindLabel="name"
                      placeholder="Select States"
                      bindValue="id"
                      formControlName="add_1_1188"
                    >
                    </ng-select>
                    
                  </div>
                  <div
                    class="col-md-2"
                  >
                    <label class="formLabel">POSTAL CODE</label>
                  </div> 
                  <div class="col-md-2">
                      <input
                        
                        class="form-control"
                        id="add_1_1189"
                        name="add_1_1189"
                        formControlName="add_1_1189"
                        type="text"
                        placeholder="Zip"
                        data-id="Pre aff 1 zip"
                      />
                      <small
                      class="text-danger"
                      *ngIf="DicksonMDFroms1.controls['add_1_1189'].errors?.pattern"
                      > Zip code must be atleast 5 digits and less than 10 digits.</small
                    >
                  </div>
                </div>
          
              </td>
            </tr>
            <tr>
              <td colspan="6">
                <div class="form-inline no-margin col-md-12 sideLabelAlignment">
                  <label class="formLabel">FULL UNRESTRICTED PRIVILEGES? </label>
                  <label class="formLabel">
                    <input
                      type="radio"
                      id="cmd_0068"
                      name="cmd_0068"
                      formControlName="cmd_0068"
                      value="1"
                    />Yes</label
                  >
                  <label class="formLabel">
                    <input
                      type="radio"
                      id="cmd_0068"
                      name="cmd_0068"
                      formControlName="cmd_0068"
                      value="0"
                    />No</label
                  >
                </div>
              </td>
              <td colspan="6">
                <div class="col-md-12">
                  <label class="formLabel"
                    >TYPES OF PRIVILEGES (PROVISIONAL, LIMITED, CONDITIONAL, ETC.)
                  </label>
                </div>  
                  <div class="col-md-8">
                    <ng-select
                      [items]="staffStatus"
                      bindLabel="name"
                      placeholder="Select Status"
                      bindValue="id"
                      formControlName="add_1_1192"
                    >
                    </ng-select>
                  </div>
                
              </td>
              <td colspan="6">
                <div class="form-inline no-margin col-md-12 sideLabelAlignment">
                  <label class="formLabel">WERE PRIVILEGES TEMPORARY? </label>
                  <label class="formLabel">
                    <input
                      type="radio"
                      id="cmd_0069"
                      name="cmd_0069"
                      formControlName="cmd_0069"
                      value="1"
                    />Yes</label
                  >
                  <label class="formLabel">
                    <input
                      type="radio"
                      id="cmd_0069"
                      name="cmd_0069"
                      formControlName="cmd_0069"
                      value="0"
                    />No</label
                  >
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div
                  class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel">REASON FOR DISCONTINUANCE </label>
                  <input
                    class="form-control"
                    id="add_1_1303"
                    name="add_1_1303"
                    formControlName="add_1_1303"
                    type="text"
                    data-id="Pre aff 1 leaving"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="10">
                <div
                  class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel"
                    >PREVIOUS HOSPITAL WHERE YOU HAVE HAD PRIVILEGES</label
                  >
                  <input
                    class="form-control"
                    id="add_2_1183"
                    name="add_2_1183"
                    formControlName="add_2_1183"
                    type="text"
                    data-id="Pre aff 1"
                  />
                </div>
              </td>
              <td colspan="8">
                <div
                  class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel"
                    >AFFILIATION DATES (MM/DD/YYYY TO MM/DD/YYYY)
                  </label>
                  <div class="input-group">
                    <input
                      style="width: 30%;"
                      class="form-control onboard datepicker"
                      placeholder="MM/DD/YYYY"
                      ngbDatepicker
                      #d0056="ngbDatepicker"
                      id="add_2_1191"
                      name="add_2_1191"
                      formControlName="add_2_1191"
                      type="text"
                      data-id="Pre aff 1 from"
                      (blur)="clearInputIfInvalid('add_2_1191')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="d0056.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div  *ngIf="DicksonMDFroms1.get('add_2_1191').invalid ">
                    <small class="text-danger" *ngIf="DicksonMDFroms1.get('add_2_1191').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms1.get('add_2_1191').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms1.get('add_2_1191').errors?.invalidDateyear">
                      year is out of range(1900-2099)
                      </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms1.get('add_2_1191').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
                  <div class="input-group">
                    <input
                      class="form-control onboard datepicker"
                      placeholder="MM/DD/YYYY"
                      ngbDatepicker
                      #d0057="ngbDatepicker"
                      id="add_2_2381"
                      name="add_2_2381"
                      formControlName="add_2_2381"
                      type="text"
                      data-id="Pre aff 1 to"
                      (blur)="clearInputIfInvalid('add_2_2381')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="d0057.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div  *ngIf="DicksonMDFroms1.get('add_2_2381').invalid ">
                    <small class="text-danger" *ngIf="DicksonMDFroms1.get('add_2_2381').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms1.get('add_2_2381').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms1.get('add_2_2381').errors?.invalidDateyear">
                      year is out of range(1900-2099)
                      </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms1.get('add_2_2381').errors?.invalidDateRange">
                        date is out of range
                      </small>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div
                  class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel">ADDRESS</label>
                  <input
                    class="form-control"
                    id="add_2_1184"
                    name="add_2_1184"
                    formControlName="add_2_1184"
                    type="text"
                    data-id="Pre aff 1 add1"
                  />
                  <input
                    class="form-control"
                    id="add_2_1185"
                    name="add_2_1185"
                    formControlName="add_2_1185"
                    type="text"
                    data-id="Pre aff 1 add2"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div
                  class="row"
                  >
                  <div class="col-md-1">
                      <label class="formLabel">CITY</label>
                  </div>    
                  <div class="col-md-2">
                    <input
                      class="form-control"
                      id="add_2_1186"
                      name="add_2_1186"
                      formControlName="add_2_1186"
                      type="text"
                      placeholder="City"
                      data-id="Pre aff 1 city"
                    />
                  </div>
                  <div
                    class="col-md-2"
                  >
                    <label class="formLabel">STATE/COUNTRY</label>
                  </div>  
                  <div class="col-md-2">
                    <ng-select
                      [items]="states"
                      bindLabel="name"
                      placeholder="Select States"
                      bindValue="id"
                      formControlName="add_2_1188"
                    >
                    </ng-select>
                    
                  </div>
                  <div
                    class="col-md-2"
                  >
                    <label class="formLabel">POSTAL CODE</label>
                  </div> 
                  <div class="col-md-2">
                      <input
                        
                        class="form-control"
                        id="add_2_1189"
                        name="add_2_1189"
                        formControlName="add_2_1189"
                        type="text"
                        placeholder="Zip"
                        data-id="Pre aff 1 zip"
                      />
                      <small
                      class="text-danger"
                      *ngIf="DicksonMDFroms1.controls['add_2_1189'].errors?.pattern"
                      > Zip code must be atleast 5 digits and less than 10 digits.</small
                    >
                  </div>
                </div>
          
              </td>
            </tr>
            <tr>
              <td colspan="6">
                <div class="form-inline no-margin col-md-12 sideLabelAlignment">
                  <label class="formLabel">FULL UNRESTRICTED PRIVILEGES? </label>
                  <label class="formLabel">
                    <input
                      type="radio"
                      id="cmd_00068"
                      name="cmd_00068"
                      formControlName="cmd_00068"
                      value="1"
                    />Yes</label
                  >
                  <label class="formLabel">
                    <input
                      type="radio"
                      id="cmd_00068"
                      name="cmd_00068"
                      formControlName="cmd_00068"
                      value="0"
                    />No</label
                  >
                </div>
              </td>
              <td colspan="6">
                <div class="col-md-12">
                  <label class="formLabel"
                    >TYPES OF PRIVILEGES (PROVISIONAL, LIMITED, CONDITIONAL, ETC.)
                  </label>
                </div>  
                  <div class="col-md-8">
                    <ng-select
                      [items]="staffStatus"
                      bindLabel="name"
                      placeholder="Select Status"
                      bindValue="id"
                      formControlName="add_2_1192"
                    >
                    </ng-select>
                  </div>
                
              </td>
              <td colspan="6">
                <div class="form-inline no-margin col-md-12 sideLabelAlignment">
                  <label class="formLabel">WERE PRIVILEGES TEMPORARY? </label>
                  <label class="formLabel">
                    <input
                      type="radio"
                      id="cmd_00069"
                      name="cmd_00069"
                      formControlName="cmd_00069"
                      value="1"
                    />Yes</label
                  >
                  <label class="formLabel">
                    <input
                      type="radio"
                      id="cmd_00069"
                      name="cmd_00069"
                      formControlName="cmd_00069"
                      value="0"
                    />No</label
                  >
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div
                  class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel">REASON FOR DISCONTINUANCE </label>
                  <input
                    class="form-control"
                    id="add_2_1303"
                    name="add_2_1303"
                    formControlName="add_2_1303"
                    type="text"
                    data-id="Pre aff 1 leaving"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="10">
                <div
                  class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel"
                    >PREVIOUS HOSPITAL WHERE YOU HAVE HAD PRIVILEGES</label
                  >
                  <input
                    class="form-control"
                    id="add_3_1183"
                    name="add_3_1183"
                    formControlName="add_3_1183"
                    type="text"
                    data-id="Pre aff 1"
                  />
                </div>
              </td>
              <td colspan="8">
                <div
                  class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel"
                    >AFFILIATION DATES (MM/DD/YYYY TO MM/DD/YYYY)
                  </label>
                  <div class="input-group">
                    <input
                      style="width: 30%;"
                      class="form-control onboard datepicker"
                      placeholder="MM/DD/YYYY"
                      ngbDatepicker
                      #d00056="ngbDatepicker"
                      id="add_3_1191"
                      name="add_3_1191"
                      formControlName="add_3_1191"
                      type="text"
                      data-id="Pre aff 1 from"
                      (blur)="clearInputIfInvalid('add_3_1191')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="d00056.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div  *ngIf="DicksonMDFroms1.get('add_3_1191').invalid ">
                    <small class="text-danger" *ngIf="DicksonMDFroms1.get('add_3_1191').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms1.get('add_3_1191').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms1.get('add_3_1191').errors?.invalidDateyear">
                      year is out of range(1900-2099)
                      </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms1.get('add_3_1191').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
                  <div class="input-group">
                    <input
                      class="form-control onboard datepicker"
                      placeholder="MM/DD/YYYY"
                      ngbDatepicker
                      #d00057="ngbDatepicker"
                      id="add_3_2381"
                      name="add_3_2381"
                      formControlName="add_3_2381"
                      type="text"
                      data-id="Pre aff 1 to"
                      (blur)="clearInputIfInvalid('add_3_2381')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="d00057.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div  *ngIf="DicksonMDFroms1.get('add_3_2381').invalid ">
                    <small class="text-danger" *ngIf="DicksonMDFroms1.get('add_3_2381').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms1.get('add_3_2381').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms1.get('add_3_2381').errors?.invalidDateyear">
                      year is out of range(1900-2099)
                      </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms1.get('add_3_2381').errors?.invalidDateRange">
                        date is out of range
                      </small>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div
                  class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel">ADDRESS</label>
                  <input
                    class="form-control"
                    id="add_3_1184"
                    name="add_3_1184"
                    formControlName="add_3_1184"
                    type="text"
                    data-id="Pre aff 1 add1"
                  />
                  <input
                    class="form-control"
                    id="add_3_1185"
                    name="add_3_1185"
                    formControlName="add_3_1185"
                    type="text"
                    data-id="Pre aff 1 add2"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div
                  class="row"
                  >
                  <div class="col-md-1">
                      <label class="formLabel">CITY</label>
                  </div>    
                  <div class="col-md-2">
                    <input
                      class="form-control"
                      id="add_3_1186"
                      name="add_3_1186"
                      formControlName="add_3_1186"
                      type="text"
                      placeholder="City"
                      data-id="Pre aff 1 city"
                    />
                  </div>
                  <div
                    class="col-md-2"
                  >
                    <label class="formLabel">STATE/COUNTRY</label>
                  </div>  
                  <div class="col-md-2">
                    <ng-select
                      [items]="states"
                      bindLabel="name"
                      placeholder="Select States"
                      bindValue="id"
                      formControlName="add_3_1188"
                    >
                    </ng-select>
                    
                  </div>
                  <div
                    class="col-md-2"
                  >
                    <label class="formLabel">POSTAL CODE</label>
                  </div> 
                  <div class="col-md-2">
                      <input
                        
                        class="form-control"
                        id="add_3_1189"
                        name="add_3_1189"
                        formControlName="add_3_1189"
                        type="text"
                        placeholder="Zip"
                        data-id="Pre aff 1 zip"
                      />
                      <small
                      class="text-danger"
                      *ngIf="DicksonMDFroms1.controls['add_3_1189'].errors?.pattern"
                      > Zip code must be atleast 5 digits and less than 10 digits.</small
                    >
                  </div>
                </div>
          
              </td>
            </tr>
            <tr>
              <td colspan="6">
                <div class="form-inline no-margin col-md-12 sideLabelAlignment">
                  <label class="formLabel">FULL UNRESTRICTED PRIVILEGES? </label>
                  <label class="formLabel">
                    <input
                      type="radio"
                      id="cmd_000068"
                      name="cmd_000068"
                      formControlName="cmd_000068"
                      value="1"
                    />Yes</label
                  >
                  <label class="formLabel">
                    <input
                      type="radio"
                      id="cmd_000068"
                      name="cmd_000068"
                      formControlName="cmd_000068"
                      value="0"
                    />No</label
                  >
                </div>
              </td>
              <td colspan="6">
                <div class="col-md-12">
                  <label class="formLabel"
                    >TYPES OF PRIVILEGES (PROVISIONAL, LIMITED, CONDITIONAL, ETC.)
                  </label>
                </div>  
                  <div class="col-md-8">
                    <ng-select
                      [items]="staffStatus"
                      bindLabel="name"
                      placeholder="Select Status"
                      bindValue="id"
                      formControlName="add_3_1192"
                    >
                    </ng-select>
                  </div>
                
              </td>
              <td colspan="6">
                <div class="form-inline no-margin col-md-12 sideLabelAlignment">
                  <label class="formLabel">WERE PRIVILEGES TEMPORARY? </label>
                  <label class="formLabel">
                    <input
                      type="radio"
                      id="cmd_000069"
                      name="cmd_000069"
                      formControlName="cmd_000069"
                      value="1"
                    />Yes</label
                  >
                  <label class="formLabel">
                    <input
                      type="radio"
                      id="cmd_000069"
                      name="cmd_000069"
                      formControlName="cmd_000069"
                      value="0"
                    />No</label
                  >
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div
                  class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel">REASON FOR DISCONTINUANCE </label>
                  <input
                    class="form-control"
                    id="add_3_1303"
                    name="add_3_1303"
                    formControlName="add_3_1303"
                    type="text"
                    data-id="Pre aff 1 leaving"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="10">
                <div
                  class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel"
                    >PREVIOUS HOSPITAL WHERE YOU HAVE HAD PRIVILEGES</label
                  >
                  <input
                    class="form-control"
                    id="add_4_1183"
                    name="add_4_1183"
                    formControlName="add_4_1183"
                    type="text"
                    data-id="Pre aff 1"
                  />
                </div>
              </td>
              <td colspan="8">
                <div
                  class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel"
                    >AFFILIATION DATES (MM/DD/YYYY TO MM/DD/YYYY)
                  </label>
                  <div class="input-group">
                    <input
                      style="width: 30%;"
                      class="form-control onboard datepicker"
                      placeholder="MM/DD/YYYY"
                      ngbDatepicker
                      #d000056="ngbDatepicker"
                      id="add_4_1191"
                      name="add_4_1191"
                      formControlName="add_4_1191"
                      type="text"
                      data-id="Pre aff 1 from"
                      (blur)="clearInputIfInvalid('add_4_1191')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="d000056.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div  *ngIf="DicksonMDFroms1.get('add_4_1191').invalid ">
                    <small class="text-danger" *ngIf="DicksonMDFroms1.get('add_4_1191').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms1.get('add_4_1191').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms1.get('add_4_1191').errors?.invalidDateyear">
                      year is out of range(1900-2099)
                      </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms1.get('add_4_1191').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
                  <div class="input-group">
                    <input
                      class="form-control onboard datepicker"
                      placeholder="MM/DD/YYYY"
                      ngbDatepicker
                      #d000057="ngbDatepicker"
                      id="add_4_2381"
                      name="add_4_2381"
                      formControlName="add_4_2381"
                      type="text"
                      data-id="Pre aff 1 to"
                      (blur)="clearInputIfInvalid('add_4_2381')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="d000057.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div  *ngIf="DicksonMDFroms1.get('add_4_2381').invalid ">
                    <small class="text-danger" *ngIf="DicksonMDFroms1.get('add_4_2381').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms1.get('add_4_2381').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms1.get('add_4_2381').errors?.invalidDateyear">
                      year is out of range(1900-2099)
                      </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms1.get('add_4_2381').errors?.invalidDateRange">
                        date is out of range
                      </small>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div
                  class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel">ADDRESS</label>
                  <input
                    class="form-control"
                    id="add_4_1184"
                    name="add_4_1184"
                    formControlName="add_4_1184"
                    type="text"
                    data-id="Pre aff 1 add1"
                  />
                  <input
                    class="form-control"
                    id="add_4_1185"
                    name="add_4_1185"
                    formControlName="add_4_1185"
                    type="text"
                    data-id="Pre aff 1 add2"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div
                  class="row"
                  >
                  <div class="col-md-1">
                      <label class="formLabel">CITY</label>
                  </div>    
                  <div class="col-md-2">
                    <input
                      class="form-control"
                      id="add_4_1186"
                      name="add_4_1186"
                      formControlName="add_4_1186"
                      type="text"
                      placeholder="City"
                      data-id="Pre aff 1 city"
                    />
                  </div>
                  <div
                    class="col-md-2"
                  >
                    <label class="formLabel">STATE/COUNTRY</label>
                  </div>  
                  <div class="col-md-2">
                    <ng-select
                      [items]="states"
                      bindLabel="name"
                      placeholder="Select States"
                      bindValue="id"
                      formControlName="add_4_1188"
                    >
                    </ng-select>
                    
                  </div>
                  <div
                    class="col-md-2"
                  >
                    <label class="formLabel">POSTAL CODE</label>
                  </div> 
                  <div class="col-md-2">
                      <input
                        
                        class="form-control"
                        id="add_4_1189"
                        name="add_4_1189"
                        formControlName="add_4_1189"
                        type="text"
                        placeholder="Zip"
                        data-id="Pre aff 1 zip"
                      />
                      <small
                      class="text-danger"
                      *ngIf="DicksonMDFroms1.controls['add_4_1189'].errors?.pattern"
                      > Zip code must be atleast 5 digits and less than 10 digits.</small
                    >
                  </div>
                </div>
          
              </td>
            </tr>
            <tr>
              <td colspan="6">
                <div class="form-inline no-margin col-md-12 sideLabelAlignment">
                  <label class="formLabel">FULL UNRESTRICTED PRIVILEGES? </label>
                  <label class="formLabel">
                    <input
                      type="radio"
                      id="cmd_0000068"
                      name="cmd_0000068"
                      formControlName="cmd_0000068"
                      value="1"
                    />Yes</label
                  >
                  <label class="formLabel">
                    <input
                      type="radio"
                      id="cmd_0000068"
                      name="cmd_0000068"
                      formControlName="cmd_0000068"
                      value="0"
                    />No</label
                  >
                </div>
              </td>
              <td colspan="6">
                <div class="col-md-12">
                  <label class="formLabel"
                    >TYPES OF PRIVILEGES (PROVISIONAL, LIMITED, CONDITIONAL, ETC.)
                  </label>
                </div>  
                  <div class="col-md-8">
                    <ng-select
                      [items]="staffStatus"
                      bindLabel="name"
                      placeholder="Select Status"
                      bindValue="id"
                      formControlName="add_4_1192"
                    >
                    </ng-select>
                  </div>
                
              </td>
              <td colspan="6">
                <div class="form-inline no-margin col-md-12 sideLabelAlignment">
                  <label class="formLabel">WERE PRIVILEGES TEMPORARY? </label>
                  <label class="formLabel">
                    <input
                      type="radio"
                      id="cmd_0000069"
                      name="cmd_0000069"
                      formControlName="cmd_0000069"
                      value="1"
                    />Yes</label
                  >
                  <label class="formLabel">
                    <input
                      type="radio"
                      id="cmd_0000069"
                      name="cmd_0000069"
                      formControlName="cmd_0000069"
                      value="0"
                    />No</label
                  >
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div
                  class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel">REASON FOR DISCONTINUANCE </label>
                  <input
                    class="form-control"
                    id="add_4_1303"
                    name="add_4_1303"
                    formControlName="add_4_1303"
                    type="text"
                    data-id="Pre aff 1 leaving"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="10">
                <div
                  class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel"
                    >PREVIOUS HOSPITAL WHERE YOU HAVE HAD PRIVILEGES</label
                  >
                  <input
                    class="form-control"
                    id="add_5_1183"
                    name="add_5_1183"
                    formControlName="add_5_1183"
                    type="text"
                    data-id="Pre aff 1"
                  />
                </div>
              </td>
              <td colspan="8">
                <div
                  class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel"
                    >AFFILIATION DATES (MM/DD/YYYY TO MM/DD/YYYY)
                  </label>
                  <div class="input-group">
                    <input
                      style="width: 30%;"
                      class="form-control onboard datepicker"
                      placeholder="MM/DD/YYYY"
                      ngbDatepicker
                      #d0000056="ngbDatepicker"
                      id="add_5_1191"
                      name="add_5_1191"
                      formControlName="add_5_1191"
                      type="text"
                      data-id="Pre aff 1 from"
                      (blur)="clearInputIfInvalid('add_5_1191')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="d0000056.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div  *ngIf="DicksonMDFroms1.get('add_5_1191').invalid ">
                    <small class="text-danger" *ngIf="DicksonMDFroms1.get('add_5_1191').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms1.get('add_5_1191').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms1.get('add_5_1191').errors?.invalidDateyear">
                      year is out of range(1900-2099)
                      </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms1.get('add_5_1191').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
                  <div class="input-group">
                    <input
                      class="form-control onboard datepicker"
                      placeholder="MM/DD/YYYY"
                      ngbDatepicker
                      #d0000057="ngbDatepicker"
                      id="add_5_2381"
                      name="add_5_2381"
                      formControlName="add_5_2381"
                      type="text"
                      data-id="Pre aff 1 to"
                      (blur)="clearInputIfInvalid('add_5_2381')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="d0000057.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div  *ngIf="DicksonMDFroms1.get('add_5_2381').invalid ">
                    <small class="text-danger" *ngIf="DicksonMDFroms1.get('add_5_2381').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms1.get('add_5_2381').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms1.get('add_5_2381').errors?.invalidDateyear">
                      year is out of range(1900-2099)
                      </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms1.get('add_5_2381').errors?.invalidDateRange">
                        date is out of range
                      </small>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div
                  class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel">ADDRESS</label>
                  <input
                    class="form-control"
                    id="add_5_1184"
                    name="add_5_1184"
                    formControlName="add_5_1184"
                    type="text"
                    data-id="Pre aff 1 add1"
                  />
                  <input
                    class="form-control"
                    id="add_5_1185"
                    name="add_5_1185"
                    formControlName="add_5_1185"
                    type="text"
                    data-id="Pre aff 1 add2"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div
                  class="row"
                  >
                  <div class="col-md-1">
                      <label class="formLabel">CITY</label>
                  </div>    
                  <div class="col-md-2">
                    <input
                      class="form-control"
                      id="add_5_1186"
                      name="add_5_1186"
                      formControlName="add_5_1186"
                      type="text"
                      placeholder="City"
                      data-id="Pre aff 1 city"
                    />
                  </div>
                  <div
                    class="col-md-2"
                  >
                    <label class="formLabel">STATE/COUNTRY</label>
                  </div>  
                  <div class="col-md-2">
                    <ng-select
                      [items]="states"
                      bindLabel="name"
                      placeholder="Select States"
                      bindValue="id"
                      formControlName="add_5_1188"
                    >
                    </ng-select>
                    
                  </div>
                  <div
                    class="col-md-2"
                  >
                    <label class="formLabel">POSTAL CODE</label>
                  </div> 
                  <div class="col-md-2">
                      <input
                        
                        class="form-control"
                        id="add_5_1189"
                        name="add_5_1189"
                        formControlName="add_5_1189"
                        type="text"
                        placeholder="Zip"
                        data-id="Pre aff 1 zip"
                      />
                      <small
                      class="text-danger"
                      *ngIf="DicksonMDFroms1.controls['add_5_1189'].errors?.pattern"
                      > Zip code must be atleast 5 digits and less than 10 digits.</small
                    >
                  </div>
                </div>
          
              </td>
            </tr>
            <tr>
              <td colspan="6">
                <div class="form-inline no-margin col-md-12 sideLabelAlignment">
                  <label class="formLabel">FULL UNRESTRICTED PRIVILEGES? </label>
                  <label class="formLabel">
                    <input
                      type="radio"
                      id="cmd_00000068"
                      name="cmd_00000068"
                      formControlName="cmd_00000068"
                      value="1"
                    />Yes</label
                  >
                  <label class="formLabel">
                    <input
                      type="radio"
                      id="cmd_00000068"
                      name="cmd_00000068"
                      formControlName="cmd_00000068"
                      value="0"
                    />No</label
                  >
                </div>
              </td>
              <td colspan="6">
                <div class="col-md-12">
                  <label class="formLabel"
                    >TYPES OF PRIVILEGES (PROVISIONAL, LIMITED, CONDITIONAL, ETC.)
                  </label>
                </div>  
                  <div class="col-md-8">
                    <ng-select
                      [items]="staffStatus"
                      bindLabel="name"
                      placeholder="Select Status"
                      bindValue="id"
                      formControlName="add_5_1192"
                    >
                    </ng-select>
                  </div>
                
              </td>
              <td colspan="6">
                <div class="form-inline no-margin col-md-12 sideLabelAlignment">
                  <label class="formLabel">WERE PRIVILEGES TEMPORARY? </label>
                  <label class="formLabel">
                    <input
                      type="radio"
                      id="cmd_00000069"
                      name="cmd_00000069"
                      formControlName="cmd_00000069"
                      value="1"
                    />Yes</label
                  >
                  <label class="formLabel">
                    <input
                      type="radio"
                      id="cmd_00000069"
                      name="cmd_00000069"
                      formControlName="cmd_00000069"
                      value="0"
                    />No</label
                  >
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div
                  class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel">REASON FOR DISCONTINUANCE </label>
                  <input
                    class="form-control"
                    id="add_5_1303"
                    name="add_5_1303"
                    formControlName="add_5_1303"
                    type="text"
                    data-id="Pre aff 1 leaving"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="10">
                <div
                  class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel"
                    >PREVIOUS HOSPITAL WHERE YOU HAVE HAD PRIVILEGES</label
                  >
                  <input
                    class="form-control"
                    id="add_6_1183"
                    name="add_6_1183"
                    formControlName="add_6_1183"
                    type="text"
                    data-id="Pre aff 1"
                  />
                </div>
              </td>
              <td colspan="8">
                <div
                  class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel"
                    >AFFILIATION DATES (MM/DD/YYYY TO MM/DD/YYYY)
                  </label>
                  <div class="input-group">
                    <input
                      style="width: 30%;"
                      class="form-control onboard datepicker"
                      placeholder="MM/DD/YYYY"
                      ngbDatepicker
                      #d00000056="ngbDatepicker"
                      id="add_6_1191"
                      name="add_6_1191"
                      formControlName="add_6_1191"
                      type="text"
                      data-id="Pre aff 1 from"
                      (blur)="clearInputIfInvalid('add_6_1191')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="d00000056.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div  *ngIf="DicksonMDFroms1.get('add_6_1191').invalid ">
                    <small class="text-danger" *ngIf="DicksonMDFroms1.get('add_6_1191').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms1.get('add_6_1191').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms1.get('add_6_1191').errors?.invalidDateyear">
                      year is out of range(1900-2099)
                      </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms1.get('add_6_1191').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
                  <div class="input-group">
                    <input
                      class="form-control onboard datepicker"
                      placeholder="MM/DD/YYYY"
                      ngbDatepicker
                      #d00000057="ngbDatepicker"
                      id="add_6_2381"
                      name="add_6_2381"
                      formControlName="add_6_2381"
                      type="text"
                      data-id="Pre aff 1 to"
                      (blur)="clearInputIfInvalid('add_6_2381')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="d00000057.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div  *ngIf="DicksonMDFroms1.get('add_6_2381').invalid ">
                    <small class="text-danger" *ngIf="DicksonMDFroms1.get('add_6_2381').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms1.get('add_6_2381').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms1.get('add_6_2381').errors?.invalidDateyear">
                      year is out of range(1900-2099)
                      </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms1.get('add_6_2381').errors?.invalidDateRange">
                        date is out of range
                      </small>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div
                  class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel">ADDRESS</label>
                  <input
                    class="form-control"
                    id="add_6_1184"
                    name="add_6_1184"
                    formControlName="add_6_1184"
                    type="text"
                    data-id="Pre aff 1 add1"
                  />
                  <input
                    class="form-control"
                    id="add_6_1185"
                    name="add_6_1185"
                    formControlName="add_6_1185"
                    type="text"
                    data-id="Pre aff 1 add2"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div
                  class="row"
                  >
                  <div class="col-md-1">
                      <label class="formLabel">CITY</label>
                  </div>    
                  <div class="col-md-2">
                    <input
                      class="form-control"
                      id="add_6_1186"
                      name="add_6_1186"
                      formControlName="add_6_1186"
                      type="text"
                      placeholder="City"
                      data-id="Pre aff 1 city"
                    />
                  </div>
                  <div
                    class="col-md-2"
                  >
                    <label class="formLabel">STATE/COUNTRY</label>
                  </div>  
                  <div class="col-md-2">
                    <ng-select
                      [items]="states"
                      bindLabel="name"
                      placeholder="Select States"
                      bindValue="id"
                      formControlName="add_6_1188"
                    >
                    </ng-select>
                    
                  </div>
                  <div
                    class="col-md-2"
                  >
                    <label class="formLabel">POSTAL CODE</label>
                  </div> 
                  <div class="col-md-2">
                      <input
                        
                        class="form-control"
                        id="add_6_1189"
                        name="add_6_1189"
                        formControlName="add_6_1189"
                        type="text"
                        placeholder="Zip"
                        data-id="Pre aff 1 zip"
                      />
                      <small
                      class="text-danger"
                      *ngIf="DicksonMDFroms1.controls['add_6_1189'].errors?.pattern"
                      > Zip code must be atleast 5 digits and less than 10 digits.</small
                    >
                  </div>
                </div>
          
              </td>
            </tr>
            <tr>
              <td colspan="6">
                <div class="form-inline no-margin col-md-12 sideLabelAlignment">
                  <label class="formLabel">FULL UNRESTRICTED PRIVILEGES? </label>
                  <label class="formLabel">
                    <input
                      type="radio"
                      id="cmd_000000068"
                      name="cmd_000000068"
                      formControlName="cmd_000000068"
                      value="1"
                    />Yes</label
                  >
                  <label class="formLabel">
                    <input
                      type="radio"
                      id="cmd_000000068"
                      name="cmd_000000068"
                      formControlName="cmd_000000068"
                      value="0"
                    />No</label
                  >
                </div>
              </td>
              <td colspan="6">
                <div class="col-md-12">
                  <label class="formLabel"
                    >TYPES OF PRIVILEGES (PROVISIONAL, LIMITED, CONDITIONAL, ETC.)
                  </label>
                </div>  
                  <div class="col-md-8">
                    <ng-select
                      [items]="staffStatus"
                      bindLabel="name"
                      placeholder="Select Status"
                      bindValue="id"
                      formControlName="add_6_1192"
                    >
                    </ng-select>
                  </div>
                
              </td>
              <td colspan="6">
                <div class="form-inline no-margin col-md-12 sideLabelAlignment">
                  <label class="formLabel">WERE PRIVILEGES TEMPORARY? </label>
                  <label class="formLabel">
                    <input
                      type="radio"
                      id="cmd_000000069"
                      name="cmd_000000069"
                      formControlName="cmd_000000069"
                      value="1"
                    />Yes</label
                  >
                  <label class="formLabel">
                    <input
                      type="radio"
                      id="cmd_000000069"
                      name="cmd_000000069"
                      formControlName="cmd_000000069"
                      value="0"
                    />No</label
                  >
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div
                  class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel">REASON FOR DISCONTINUANCE </label>
                  <input
                    class="form-control"
                    id="add_6_1303"
                    name="add_6_1303"
                    formControlName="add_6_1303"
                    type="text"
                    data-id="Pre aff 1 leaving"
                  />
                </div>
              </td>
            </tr>
          </table>  
          <hr class="tablePersonal" style="border: 17px solid #818181;" />
          <table border="1" width="100%">
            <tr>
              <td colspan="14" style="width:70%;">
                <div class="col-md-12">
                  <b style="font-size:16px;">Malpractice Claims History</b>
                </div>
              </td>
              <td colspan="6">
                <div class="form-check">
                  <input formControlName="cmd_NA_014" (ngModelChange)="handleNAchange('cmd_NA_014')" class="form-check-input" type="checkbox" value="1" id="cmd_NA_014">
                     <label class="form-check-label" for="cmd_NA_014">
                      <b style="font-size:12px;">Does Not Apply</b>
                     </label>
                </div>
              </td>              
            </tr>
            <tr>
              <td colspan="9">
                  <div class="form-group form-inline no-margin sideLabelAlignment">
                      <label class="formLabel">INCIDENT DATE (MM/DD/YYYY) </label>
                      <div class="input-group">
                        <input  class="form-control onboard datepicker" (blur)="clearInputIfInvalidMandi('1208')" ngbDatepicker #d1208="ngbDatepicker" (blur)="handleNAchange('cmd_NA_014',1208)" [class.is-invalid]="DicksonMDFroms1.controls['1208'].invalid" placeholder="MM/DD/YYYY" id="1208" name="1208" formControlName="1208" type="text"
                          data-id="pending date">
                        <div class="input-group-append">
                          <button class="btn btn-outline-secondary calendar" (click)="d1208.toggle()" type="button">
                          <i class="fas fa-calendar-alt"></i>
                          </button>
                        </div>
                      </div>  
                      <div class="mandatory" *ngIf="(DicksonMDFroms1.controls['1208'].invalid&&DicksonMDFroms1.controls['1208'].touched) && (DicksonMDFroms1.controls['1208'].value==''|| DicksonMDFroms1.controls['1208'].value==null) " >
                        <small >
                        required
                        </small>
                      </div>
                        <small class="text-danger" *ngIf="DicksonMDFroms1.get('1208').errors?.invalidDateFormat">
                          Only Date Format is allowed(MM/DD/YYYY)                </small>
                        <small class="text-danger" *ngIf="DicksonMDFroms1.get('1208').errors?.invalidMonth">
                        Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="DicksonMDFroms1.get('1208').errors?.invalidDateyear">
                          year is out of range(1900-2099)                  
                        </small>
                          <small class="text-danger" *ngIf="DicksonMDFroms1.get('1208').errors?.invalidDateRange">
                            date is out of range
                          </small>
                  </div>
              </td>
              <td colspan="6">
                  <div class="form-group form-inline no-margin sideLabelAlignment">
                      <label class="formLabel"> DATE CLAIM WAS FILED (MM/DD/YYYY) </label>
                      <div class="input-group">
                        <input  class="form-control onboard datepicker" (blur)="clearInputIfInvalidMandi('1209')" ngbDatepicker #d1209="ngbDatepicker" (blur)="handleNAchange('cmd_NA_014',1209)"  [class.is-invalid]="DicksonMDFroms1.controls['1209'].invalid"   placeholder="MM/DD/YYYY"  id="1209" name="1209" formControlName="1209" type="text" data-id="pending date">
                        <div class="input-group-append">
                          <button class="btn btn-outline-secondary calendar" (click)="d1209.toggle()" type="button">
                          <i class="fas fa-calendar-alt"></i>
                          </button>
                        </div>
                        <div class="mandatory" *ngIf="(DicksonMDFroms1.controls['1209'].invalid&&DicksonMDFroms1.controls['1209'].touched) && (DicksonMDFroms1.controls['1209'].value==''|| DicksonMDFroms1.controls['1209'].value==null) " >
                          <small >
                          required
                          </small>
                        </div>
                          <small class="text-danger" *ngIf="DicksonMDFroms1.get('1209').errors?.invalidDateFormat">
                            Only Date Format is allowed(MM/DD/YYYY)                </small>
                          <small class="text-danger" *ngIf="DicksonMDFroms1.get('1209').errors?.invalidMonth">
                          Month is out of range
                          </small>
                          <small class="text-danger" *ngIf="DicksonMDFroms1.get('1209').errors?.invalidDateyear">
                            year is out of range(1900-2099)                  
                          </small>
                            <small class="text-danger" *ngIf="DicksonMDFroms1.get('1209').errors?.invalidDateRange">
                              date is out of range
                            </small>
                      </div>  
                  </div>
              </td>
              <td colspan="6">
                  <div class="form-group form-inline no-margin sideLabelAlignment">
                      <label class="formLabel">CLAIM/CASE STATUS  </label>
                      <input class="form-control onboard" id="1858" name="1858" (blur)="handleNAchange('cmd_NA_014',1858)" [class.is-invalid]="DicksonMDFroms1.controls['1858'].invalid" formControlName="1858" type="text" data-id="Role in event">
                      <div class="mandatory"
                          *ngIf="DicksonMDFroms1.controls['1858'].invalid">
                          <small >
                            required
                          </small>
                      </div> 
                  </div>
              </td>
            </tr>
            <tr>
                <td colspan="18">
                    <div class="form-group form-inline no-margin sideLabelAlignment">
                        <label class="formLabel">PROFESSIONAL LIABILITY CARRIER INVOLVED </label>
                        <input  class="form-control onboard " id="1210" name="1210" formControlName="1210" type="text" (blur)="handleNAchange('cmd_NA_014',1210)" [class.is-invalid]="DicksonMDFroms1.controls['1210'].invalid" data-id="Cause of harm">
                        <div class="mandatory"
                            *ngIf="DicksonMDFroms1.controls['1210'].invalid">
                            <small >
                              required
                            </small>
                        </div> 
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="18">
                    <div class="form-group form-inline no-margin sideLabelAlignment">
                        <label class="formLabel">ADDRESS </label>
                        <input  class="form-control onboard " id="2012" name="2012" formControlName="2012" type="text" data-id="Text6.4.1">
                        <input  class="form-control onboard " id="1844" name="1844" formControlName="1844" type="text" data-id="Text6.4.2">
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="18" style="padding:1%">
                    <div class="row">
                      <div class="col-md-1">
                        <label class="formLabel">CITY</label>
                      </div>  
                      <div class="col-md-2">
                        <input class="form-control" id="1845" name="1845" formControlName="1845" type="text" data-id="claimcity">
                      </div>
                      <div class="col-md-2">
                        <label class="formLabel">STATE/COUNTRY</label>
                      </div>    
                      <div class="col-md-2">
                          <ng-select [items]="states" bindLabel="name" placeholder="Select States" bindValue="id" formControlName="1847">
                          </ng-select>
                      </div>                      
                      <div class="col-md-2">
                          <label class="formLabel">POSTAL CODE</label>
                      </div>   
                      <div class="col-md-2">
                          <input class="form-control onboard " id="1848" name="1848" formControlName="1848" type="text" data-id="claimzip">
                          <small
                            class="text-danger"
                            *ngIf="DicksonMDFroms1.controls['1848'].errors?.pattern"
                            > Zip code must be atleast 5 digits and less than 10 digits.
                          </small>
                      </div>
                    </div>  
                </td>
            </tr>
            <tr>
                <td colspan="6">
                    <div class="form-group form-inline no-margin sideLabelAlignment">
                        <label class="formLabel">PHONE NUMBER</label>
                        <input class="form-control onboard " id="1849" name="1849" (keypress)="numberOnly($event)" formControlName="1849" type="text">
                    </div>
                </td>
                <td colspan="6">
                    <div class="form-group form-inline no-margin sideLabelAlignment">
                        <label class="formLabel">POLICY NUMBER  </label>
                        <input class="form-control onboard " id="1859" name="1859" formControlName="1859"  type="text">
                    </div>
                </td>
                <td colspan="6">
                    <div class="form-group form-inline no-margin sideLabelAlignment">
                        <label class="formLabel">AMOUNT OF AWARD OR SETTLEMENT $ & AMOUNT PAID $ </label>
                        <input  class="form-control onboard " id="1229" name="1229" formControlName="1229" type="text" data-id="Text6.3.0">
                        <input class="form-control onboard " id="1228" name="1228" formControlName="1228" type="text" data-id="Text6.2.3">
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="18">
                    <div class="form-group form-inline no-margin sideLabelAlignment">
                        METHOD OF RESOLUTION &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <label class="formLabel">
                        <input type="checkbox" id="cmd_0708" name="cmd_0708" formControlName="cmd_0708" value="1">&nbsp;Dismissed
                        </label>&nbsp;&nbsp;
                        <label class="formLabel">
                        <input type="checkbox" id="cmd_0709" name="cmd_0709" formControlName="cmd_0709" value="1">&nbsp;Settled (with prejudice)
                        </label>&nbsp;&nbsp;
                        <label class="formLabel">
                        <input type="checkbox" id="cmd_0710" name="cmd_0710" formControlName="cmd_0710" value="1">&nbsp;Settled (without prejudice)
                        </label>&nbsp;&nbsp;
                        <label class="formLabel">
                        <input type="checkbox" id="cmd_0711" name="cmd_0711" formControlName="cmd_0711" value="1">&nbsp;Judgment for Defendant(s)
                        </label>&nbsp;&nbsp;
                        <label class="formLabel">
                        <input type="checkbox" id="cmd_0712" name="cmd_0712" formControlName="cmd_0712" value="1">&nbsp;Judgment for Plaintiff(s)
                        </label>&nbsp;&nbsp;
                        <label class="formLabel">
                        <input type="checkbox" id="cmd_0713" name="cmd_0713" formControlName="cmd_0713" value="1">&nbsp;Mediation or Arbitration
                        </label>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="18">
                    <div class="form-group form-inline no-margin sideLabelAlignment">
                        <label class="formLabel">DESCRIPTION OF ALLEGATIONS</label>
                        <input  class="form-control onboard " id="1216" name="1216" formControlName="1216" type="text" data-id="Text6.1.3">
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="6">
                    <div class="form-group form-inline no-margin sideLabelAlignment">
                        <label class="formLabel">WERE YOU PRIMARY DEFENDANT OR CO-DEFENDANT?</label>
                        <input  class="form-control onboard " id="1213" name="1213" formControlName="1213" type="text" data-id="Specifics on the claim">
                    </div>
                </td>
                <td colspan="6">
                    <div class="form-group form-inline no-margin sideLabelAlignment">
                        <label class="formLabel"> NUMBER OF OTHER CO-DEFENDANTS </label>
                        <input  class="form-control onboard " id="cmd_0716" name="cmd_0716" formControlName="cmd_0716" type="text" data-id="List other defendants">
                    </div>
                </td>
                <td colspan="6">
                    <div class="form-group form-inline no-margin sideLabelAlignment">
                        <label class="formLabel">YOUR INVOLVEMENT (ATTENDING, CONSULTING, ETC.) </label>
                        <input  class="form-control onboard " id="1218" name="1218" formControlName="1218" type="text" data-id="Role in event">
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="18">
                    <div class="form-group form-inline no-margin sideLabelAlignment">
                        <label class="formLabel">DESCRIPTION OF ALLEGED INJURY TO THE PATIENT</label>
                        <input style="width: 100%" class="form-control onboard " id="1217" name="1217" formControlName="1217" type="text" data-id="Text6.1.2">
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="18">
                    <div class="form-group form-inline no-margin sideLabelAlignment">
                        TO THE BEST OF YOUR KNOWLEDGE, IS THIS CASE INCLUDED IN THE NATIONAL PRACTITIONER DATA BANK (NPDB)? &nbsp;&nbsp;
                        <label class="formLabel">
                        <input type="radio" id="cmd_0719" name="cmd_0719" formControlName="cmd_0719" value="1">Yes
                        </label>&nbsp;&nbsp;
                        <label class="formLabel">
                        <input type="radio" id="cmd_0719" name="cmd_0719" formControlName="cmd_0719"  value="0">No
                        </label>
                    </div>
                </td>
            </tr>
            <tr>
              <td colspan="9">
                  <div class="form-group form-inline no-margin sideLabelAlignment">
                      <label class="formLabel">INCIDENT DATE (MM/DD/YYYY) </label>
                      <div class="form-group col-md-8 input-group">
                        <input
                          
                          class="form-control onboard  datepicker"
                          ngbDatepicker
                          #d01208="ngbDatepicker"
                          id="add_1_1208"
                          name="add_1_1208"
                          formControlName="add_1_1208"
                          type="text"
                          data-id="Text5.1.2"
                          (blur)="clearInputIfInvalid('add_1_1208')"
                        />
                        <div class="input-group-append">
                          <button
                            class="btn btn-outline-secondary calendar"
                            (click)="d01208.toggle()"
                            type="button"
                          >
                            <i class="fas fa-calendar-alt"></i>
                          </button>
                        </div>
                      </div>
                      <div  *ngIf="DicksonMDFroms1.get('add_1_1208').invalid ">
                        <small class="text-danger" *ngIf="DicksonMDFroms1.get('add_1_1208').errors?.invalidDateFormat">
                          Only Date Format is allowed(MM/DD/YYYY)
                        </small>
                        <small class="text-danger" *ngIf="DicksonMDFroms1.get('add_1_1208').errors?.invalidMonth">
                        Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="DicksonMDFroms1.get('add_1_1208').errors?.invalidDateyear">
                          year is out of range(1900-2099)
                          </small>
                          <small class="text-danger" *ngIf="DicksonMDFroms1.get('add_1_1208').errors?.invalidDateRange">
                            date is out of range
                            </small>
                      </div>
                     
                  </div>
              </td>
              <td colspan="6">
                  <div class="form-group form-inline no-margin sideLabelAlignment">
                      <label class="formLabel"> DATE CLAIM WAS FILED (MM/DD/YYYY) </label>
                      <div class="form-group col-md-8 input-group">
                        <input
                          
                          class="form-control onboard  datepicker"
                          ngbDatepicker
                          #d01209="ngbDatepicker"
                          id="add_1_1209"
                          name="add_1_1209"
                          formControlName="add_1_1209"
                          type="text"
                          data-id="Text5.1.2"
                          (blur)="clearInputIfInvalid('add_1_1209')"
                        />
                        <div class="input-group-append">
                          <button
                            class="btn btn-outline-secondary calendar"
                            (click)="d01209.toggle()"
                            type="button"
                          >
                            <i class="fas fa-calendar-alt"></i>
                          </button>
                        </div>
                      </div>
                      <div  *ngIf="DicksonMDFroms1.get('add_1_1209').invalid ">
                        <small class="text-danger" *ngIf="DicksonMDFroms1.get('add_1_1209').errors?.invalidDateFormat">
                          Only Date Format is allowed(MM/DD/YYYY)
                        </small>
                        <small class="text-danger" *ngIf="DicksonMDFroms1.get('add_1_1209').errors?.invalidMonth">
                        Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="DicksonMDFroms1.get('add_1_1209').errors?.invalidDateyear">
                          year is out of range(1900-2099)
                          </small>
                          <small class="text-danger" *ngIf="DicksonMDFroms1.get('add_1_1209').errors?.invalidDateRange">
                            date is out of range
                            </small>
                      </div>
                  </div>
              </td>
              <td colspan="6">
                  <div class="form-group form-inline no-margin sideLabelAlignment">
                      <label class="formLabel">CLAIM/CASE STATUS  </label>
                      <input class="form-control onboard" id="add_1_1858" name="add_1_1858"  formControlName="add_1_1858" type="text" data-id="Role in event">
                      
                  </div>
              </td>
            </tr>
            <tr>
                <td colspan="18">
                    <div class="form-group form-inline no-margin sideLabelAlignment">
                        <label class="formLabel">PROFESSIONAL LIABILITY CARRIER INVOLVED </label>
                        <input  class="form-control onboard " id="add_1_1210" name="add_1_1210" formControlName="add_1_1210" type="text"  data-id="Cause of harm">
                        
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="18">
                    <div class="form-group form-inline no-margin sideLabelAlignment">
                        <label class="formLabel">ADDRESS </label>
                        <input  class="form-control onboard " id="add_1_2012" name="add_1_2012" formControlName="add_1_2012" type="text" data-id="Text6.4.1">
                        <input  class="form-control onboard " id="add_1_1844" name="add_1_1844" formControlName="add_1_1844" type="text" data-id="Text6.4.2">
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="18" style="padding:1%;">
                  <div class="row">
                      <div class="col-md-1">
                          <label class="formLabel">CITY</label>
                      </div>    
                      <div class="col-md-2">
                          <input class="form-control onboard " id="add_1_1845" name="add_1_1845" formControlName="add_1_1845" type="text" data-id="claimcity">
                      </div>
                      <div class="col-md-2">
                          <label class="formLabel">STATE/COUNTRY</label>
                      </div>    
                      <div class="col-md-2">
                          <ng-select [items]="states" bindLabel="name" placeholder="Select States" bindValue="id" formControlName="add_1_1847">
                          </ng-select>
                      </div>
                      <div class="col-md-2">
                          <label class="formLabel">POSTAL CODE</label>
                      </div>   
                      <div class="col-md-2">
                        <input class="form-control onboard " id="add_1_1848" name="add_1_1848" formControlName="add_1_1848" type="text" data-id="claimzip">
                          <small
                            class="text-danger"
                            *ngIf="DicksonMDFroms1.controls['add_1_1848'].errors?.pattern"
                            > Zip code must be atleast 5 digits and less than 10 digits.
                          </small>
                      </div>
                    </div>    
                </td>
            </tr>
            <tr>
                <td colspan="6">
                    <div class="form-group form-inline no-margin sideLabelAlignment">
                        <label class="formLabel">PHONE NUMBER</label>
                        <input class="form-control onboard " id="add_1_1849" name="add_1_1849" (keypress)="numberOnly($event)" formControlName="add_1_1849" type="text">
                    </div>
                </td>
                <td colspan="6">
                    <div class="form-group form-inline no-margin sideLabelAlignment">
                        <label class="formLabel">POLICY NUMBER  </label>
                        <input class="form-control onboard " id="add_1_1859" name="add_1_1859" formControlName="add_1_1859"  type="text">
                    </div>
                </td>
                <td colspan="6">
                    <div class="form-group form-inline no-margin sideLabelAlignment">
                        <label class="formLabel">AMOUNT OF AWARD OR SETTLEMENT $ & AMOUNT PAID $ </label>
                        <input  class="form-control onboard " id="add_1_1229" name="add_1_1229" formControlName="add_1_1229" type="text" data-id="Text6.3.0">
                        <input class="form-control onboard " id="add_1_1228" name="add_1_1228" formControlName="add_1_1228" type="text" data-id="Text6.2.3">
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="18">
                    <div class="form-group form-inline no-margin sideLabelAlignment">
                        METHOD OF RESOLUTION &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <label class="formLabel">
                        <input type="checkbox" id="cmd_0725" name="cmd_0725" formControlName="cmd_0725" value="1">&nbsp;Dismissed
                        </label>&nbsp;&nbsp;
                        <label class="formLabel">
                        <input type="checkbox" id="cmd_0715" name="cmd_0715" formControlName="cmd_0715" value="1">&nbsp;Settled (with prejudice)
                        </label>&nbsp;&nbsp;
                        <label class="formLabel">
                        <input type="checkbox" id="cmd_0720" name="cmd_0720" formControlName="cmd_0720" value="1">&nbsp;Settled (without prejudice)
                        </label>&nbsp;&nbsp;
                        <label class="formLabel">
                        <input type="checkbox" id="cmd_0721" name="cmd_0721" formControlName="cmd_0721" value="1">&nbsp;Judgment for Defendant(s)
                        </label>&nbsp;&nbsp;
                        <label class="formLabel">
                        <input type="checkbox" id="cmd_0718" name="cmd_0718" formControlName="cmd_0718" value="1">&nbsp;Judgment for Plaintiff(s)
                        </label>&nbsp;&nbsp;
                        <label class="formLabel">
                        <input type="checkbox" id="cmd_0724" name="cmd_0724" formControlName="cmd_0724" value="1">&nbsp;Mediation or Arbitration
                        </label>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="18">
                    <div class="form-group form-inline no-margin sideLabelAlignment">
                        <label class="formLabel">DESCRIPTION OF ALLEGATIONS</label>
                        <input  class="form-control onboard " id="add_1_1216" name="add_1_1216" formControlName="add_1_1216" type="text" data-id="Text6.1.3">
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="6">
                    <div class="form-group form-inline no-margin sideLabelAlignment">
                        <label class="formLabel">WERE YOU PRIMARY DEFENDANT OR CO-DEFENDANT?</label>
                        <input  class="form-control onboard " id="add_1_1213" name="add_1_1213" formControlName="add_1_1213" type="text" data-id="Specifics on the claim">
                    </div>
                </td>
                <td colspan="6">
                    <div class="form-group form-inline no-margin sideLabelAlignment">
                        <label class="formLabel"> NUMBER OF OTHER CO-DEFENDANTS </label>
                        <input  class="form-control onboard " id="cmd_07211" name="cmd_07211" formControlName="cmd_07211" type="text" data-id="List other defendants">
                    </div>
                </td>
                <td colspan="6">
                    <div class="form-group form-inline no-margin sideLabelAlignment">
                        <label class="formLabel">YOUR INVOLVEMENT (ATTENDING, CONSULTING, ETC.) </label>
                        <input  class="form-control onboard " id="add_1_1218" name="add_1_1218" formControlName="add_1_1218" type="text" data-id="Role in event">
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="18">
                    <div class="form-group form-inline no-margin sideLabelAlignment">
                        <label class="formLabel">DESCRIPTION OF ALLEGED INJURY TO THE PATIENT</label>
                        <input style="width: 100%" class="form-control onboard " id="add_1_1217" name="add_1_1217" formControlName="add_1_1217" type="text" data-id="Text6.1.2">
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="18">
                    <div class="form-group form-inline no-margin sideLabelAlignment">
                        TO THE BEST OF YOUR KNOWLEDGE, IS THIS CASE INCLUDED IN THE NATIONAL PRACTITIONER DATA BANK (NPDB)? &nbsp;&nbsp;
                        <label class="formLabel">
                        <input type="radio" id="cmd_00722" name="cmd_00722" formControlName="cmd_00722" value="1">Yes
                        </label>&nbsp;&nbsp;
                        <label class="formLabel">
                        <input type="radio" id="cmd_00722" name="cmd_00722" formControlName="cmd_00722"  value="0">No
                        </label>
                    </div>
                </td>
            </tr>
          
          </table>    
          <hr class="tablePersonal" />
          <table border="1" width="100%">
            <tr>
              <td colspan="18">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <b style="font-size:16px;">Section II-Disclosure Questions</b>
                  <label style="font-size:15px;"
                    >- Please provide an explanation for any question answered
                    yes-except 16-on page 10. 
                  </label>
                  <br />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18" width="5%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="sideheadingpara"
                    >Licensure
                  </label>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="2" width="5%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="sideheadingpara">1</label>
                </div>
              </td>
              <td colspan="12" width="75%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="formLabel"
                    >Has your license to practice, in your profession, ever been
                    denied, suspended, revoked, restricted,voluntarily surrendered
                    while under investigation, or have you ever been subject to a
                    consent order,probation or any conditions or limitations by
                    any state licensing board?</label
                  >
                </div>
              </td>
              <td colspan="4" width="20%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="radio-inline radioStyle">
                    <input
                      type="radio"
                      [class.is-invalid]="
                        (DicksonMDFroms1.controls['cmd_0232'].touched &&
                          DicksonMDFroms1.controls['cmd_0232'].errors) ||
                        (DicksonMDFroms1.controls['cmd_0232'].invalid && isSubmit)
                      "
                      id="cmd_0232"
                      name="cmd_0232"
                      formControlName="cmd_0232"
                      value="1"
                      (change)="validationsDataAdd($event)"
                    />Yes</label
                  >
                  <label class="radio-inline radioStyle">
                    <input
                      type="radio"
                      [class.is-invalid]="
                        (DicksonMDFroms1.controls['cmd_0232'].touched &&
                          DicksonMDFroms1.controls['cmd_0232'].errors) ||
                        (DicksonMDFroms1.controls['cmd_0232'].invalid && isSubmit)
                      "
                      id="cmd_0232"
                      name="cmd_0232"
                      formControlName="cmd_0232"
                      value="0"
                      (change)="validationsDataAdd($event)"
                    />No</label
                  >
                  <div
                  class="formLabel col-md-2"
                  *ngIf="
                    DicksonMDFroms1.controls['cmd_0232'].touched &&
                    DicksonMDFroms1.controls['cmd_0232'].errors
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="DicksonMDFroms1.controls['cmd_0232'].errors?.required"
                  >
                    required
                  </small>
                </div>
                <span
                  *ngIf="
                    (DicksonMDFroms1.controls['cmd_0232'].touched &&
                      DicksonMDFroms1.controls['cmd_0232'].errors) ||
                    (DicksonMDFroms1.controls['cmd_0232'].errors && isSubmit)
                  "
                >
                  <i class="fas fa-times ml-2 text-danger"></i>
                </span>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="2" width="5%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="sideheadingpara">2</label>
                </div>
              </td>
              <td colspan="12" width="75%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="formLabel"
                    >Have you ever received a reprimand or been fined by any state
                    licensing board?
                  </label>
                </div>
              </td>
              <td colspan="4" width="20%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="radio-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_0233"
                      [class.is-invalid]="
                        (DicksonMDFroms1.controls['cmd_0233'].touched &&
                          DicksonMDFroms1.controls['cmd_0233'].errors) ||
                        (DicksonMDFroms1.controls['cmd_0233'].errors && isSubmit)
                      "
                      name="cmd_0233"
                      formControlName="cmd_0233"
                      value="1"
                      (change)="validationsDataAdd($event)"
                    />Yes</label
                  >
                  <label class="radio-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_0233"
                      [class.is-invalid]="
                        (DicksonMDFroms1.controls['cmd_0233'].touched &&
                          DicksonMDFroms1.controls['cmd_0233'].errors) ||
                        (DicksonMDFroms1.controls['cmd_0233'].errors && isSubmit)
                      "
                      name="cmd_0233"
                      formControlName="cmd_0233"
                      value="0"
                      (change)="validationsDataAdd($event)"
                    />No</label
                  >
                  <div
                    class="formLabel col-md-2"
                    *ngIf="
                      DicksonMDFroms1.controls['cmd_0233'].touched &&
                      DicksonMDFroms1.controls['cmd_0233'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="DicksonMDFroms1.controls['cmd_0233'].errors?.required"
                    >
                      required
                    </small>
                  </div>
                  <span
                    *ngIf="
                      (DicksonMDFroms1.controls['cmd_0233'].touched &&
                        DicksonMDFroms1.controls['cmd_0233'].errors) ||
                      (DicksonMDFroms1.controls['cmd_0233'].errors && isSubmit)
                    "
                  >
                    <i class="fas fa-times ml-2 text-danger"></i>
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18" width="5%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="sideheadingpara"
                    >Hospital Privileges and Other Affiliations
                  </label>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="2" width="5%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="sideheadingpara">3</label>
                </div>
              </td>
              <td colspan="12" width="75%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="formLabel"
                    >Have your clinical privileges or Medical Staff membership at
                    any hospital or healthcare institution ever been denied,
                    suspended, revoked, restricted, denied renewal or subject to
                    probationary or to other disciplinary conditions (for reasons
                    other than non-completion of medical records when quality of
                    care was not adversely affected) or have proceedings toward
                    any of those ends been instituted or recommended by any
                    hospital or healthcare institution, medical staff or
                    committee, or governing board?
                  </label>
                </div>
              </td>
              <td colspan="4" width="20%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="radio-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_0234"
                      [class.is-invalid]="
                        (DicksonMDFroms1.controls['cmd_0234'].touched &&
                          DicksonMDFroms1.controls['cmd_0234'].errors) ||
                        (DicksonMDFroms1.controls['cmd_0234'].errors && isSubmit)
                      "
                      name="cmd_0234"
                      formControlName="cmd_0234"
                      value="1"
                      (change)="validationsDataAdd($event)"
                    />Yes</label
                  >
                  <label class="radio-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_0234"
                      [class.is-invalid]="
                        (DicksonMDFroms1.controls['cmd_0234'].touched &&
                          DicksonMDFroms1.controls['cmd_0234'].errors) ||
                        (DicksonMDFroms1.controls['cmd_0234'].errors && isSubmit)
                      "
                      name="cmd_0234"
                      formControlName="cmd_0234"
                      value="0"
                      (change)="validationsDataAdd($event)"
                    />No</label
                  >
                  <div
                    class="formLabel col-md-2"
                    *ngIf="
                      DicksonMDFroms1.controls['cmd_0234'].touched &&
                      DicksonMDFroms1.controls['cmd_0234'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="DicksonMDFroms1.controls['cmd_0234'].errors?.required"
                    >
                      required
                    </small>
                  </div>
                  <span
                    *ngIf="
                      (DicksonMDFroms1.controls['cmd_0234'].touched &&
                        DicksonMDFroms1.controls['cmd_0234'].errors) ||
                      (DicksonMDFroms1.controls['cmd_0234'].errors && isSubmit)
                    "
                  >
                    <i class="fas fa-times ml-2 text-danger"></i>
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="2" width="5%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="sideheadingpara">4</label>
                </div>
              </td>
              <td colspan="12" width="75%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="formLabel"
                    >Have you voluntarily surrendered, limited your privileges or
                    not reapplied for privileges while under investigation?</label
                  >
                </div>
              </td>
              <td colspan="4" width="20%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="radio-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_0235"
                      [class.is-invalid]="
                        (DicksonMDFroms1.controls['cmd_0235'].touched &&
                          DicksonMDFroms1.controls['cmd_0235'].errors) ||
                        (DicksonMDFroms1.controls['cmd_0235'].errors && isSubmit)
                      "
                      name="cmd_0235"
                      formControlName="cmd_0235"
                      value="1"
                      (change)="validationsDataAdd($event)"
                    />Yes</label
                  >
                  <label class="radio-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_0235"
                      [class.is-invalid]="
                        (DicksonMDFroms1.controls['cmd_0235'].touched &&
                          DicksonMDFroms1.controls['cmd_0235'].errors) ||
                        (DicksonMDFroms1.controls['cmd_0235'].errors && isSubmit)
                      "
                      name="cmd_0235"
                      formControlName="cmd_0235"
                      value="0"
                    />No</label
                  >
                  <div
                    class="formLabel col-md-2"
                    *ngIf="
                      DicksonMDFroms1.controls['cmd_0235'].touched &&
                      DicksonMDFroms1.controls['cmd_0235'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="DicksonMDFroms1.controls['cmd_0235'].errors?.required"
                    >
                      required
                    </small>
                  </div>
                  <span
                    *ngIf="
                      (DicksonMDFroms1.controls['cmd_0235'].touched &&
                        DicksonMDFroms1.controls['cmd_0235'].errors) ||
                      (DicksonMDFroms1.controls['cmd_0235'].errors && isSubmit)
                    "
                  >
                    <i class="fas fa-times ml-2 text-danger"></i>
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="2" width="5%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="sideheadingpara">5</label>
                </div>
              </td>
              <td colspan="12" width="75%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="formLabel"
                    >Have you ever been terminated for cause or not renewed for
                    cause from participation, or been subject to any disciplinary
                    action, by any managed care organizations (including HMOs,
                    PPOs, or provider organizations such as IPAs, PHOs)?
                  </label>
                </div>
              </td>
              <td colspan="4" width="20%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="radio-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_0236"
                      [class.is-invalid]="
                        (DicksonMDFroms1.controls['cmd_0236'].touched &&
                          DicksonMDFroms1.controls['cmd_0236'].errors) ||
                        (DicksonMDFroms1.controls['cmd_0236'].errors && isSubmit)
                      "
                      name="cmd_0236"
                      formControlName="cmd_0236"
                      value="1"
                      (change)="validationsDataAdd($event)"
                    />Yes</label
                  >
                  <label class="radio-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_0236"
                      [class.is-invalid]="
                        (DicksonMDFroms1.controls['cmd_0236'].touched &&
                          DicksonMDFroms1.controls['cmd_0236'].errors) ||
                        (DicksonMDFroms1.controls['cmd_0236'].errors && isSubmit)
                      "
                      name="cmd_0236"
                      formControlName="cmd_0236"
                      value="0"
                      (change)="validationsDataAdd($event)"
                    />No</label
                  >
                  <div
                    class="formLabel col-md-2"
                    *ngIf="
                      DicksonMDFroms1.controls['cmd_0236'].touched &&
                      DicksonMDFroms1.controls['cmd_0236'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="DicksonMDFroms1.controls['cmd_0236'].errors?.required"
                    >
                      required
                    </small>
                  </div>
                  <span
                    *ngIf="
                      (DicksonMDFroms1.controls['cmd_0236'].touched &&
                        DicksonMDFroms1.controls['cmd_0236'].errors) ||
                      (DicksonMDFroms1.controls['cmd_0236'].errors && isSubmit)
                    "
                  >
                    <i class="fas fa-times ml-2 text-danger"></i>
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18" width="5%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="sideheadingpara"
                    >Education, Training and Board Certification
                  </label>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="2" width="5%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="sideheadingpara">6</label>
                </div>
              </td>
              <td colspan="12" width="75%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="formLabel"
                    >Were you ever placed on probation, disciplined, formally
                    reprimanded, suspended or asked to resign during an
                    internship, residency, fellowship, preceptorship or other
                    clinical education program? If you are currently in a
                    training program, have you been placed on probation,
                    disciplined, formally reprimanded, suspended or asked to
                    resign?
                  </label>
                </div>
              </td>
              <td colspan="4" width="20%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="radio-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_0237"
                      [class.is-invalid]="
                        (DicksonMDFroms1.controls['cmd_0237'].touched &&
                          DicksonMDFroms1.controls['cmd_0237'].errors) ||
                        (DicksonMDFroms1.controls['cmd_0237'].errors && isSubmit)
                      "
                      name="cmd_0237"
                      formControlName="cmd_0237"
                      value="1"
                    />Yes</label
                  >
                  <label class="radio-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_0237"
                      [class.is-invalid]="
                        (DicksonMDFroms1.controls['cmd_0237'].touched &&
                          DicksonMDFroms1.controls['cmd_0237'].errors) ||
                        (DicksonMDFroms1.controls['cmd_0237'].errors && isSubmit)
                      "
                      name="cmd_0237"
                      formControlName="cmd_0237"
                      value="0"
                      (change)="validationsDataAdd($event)"
                    />No</label
                  >
                  <div
                    class="formLabel col-md-2"
                    *ngIf="
                      DicksonMDFroms1.controls['cmd_0237'].touched &&
                      DicksonMDFroms1.controls['cmd_0237'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="DicksonMDFroms1.controls['cmd_0237'].errors?.required"
                    >
                      required
                    </small>
                  </div>
                  <span
                    *ngIf="
                      (DicksonMDFroms1.controls['cmd_0237'].touched &&
                        DicksonMDFroms1.controls['cmd_0237'].errors) ||
                      (DicksonMDFroms1.controls['cmd_0237'].errors && isSubmit)
                    "
                  >
                    <i class="fas fa-times ml-2 text-danger"></i>
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="2" width="5%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="sideheadingpara">7</label>
                </div>
              </td>
              <td colspan="12" width="75%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="formLabel">
                    Have you ever, while under investigation, voluntarily
                    withdrawn or prematurely terminated your status as a student
                    or employee in any internship, residency, fellowship,
                    preceptorship, or other clinical education program?
                  </label>
                </div>
              </td>
              <td colspan="4" width="20%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="radio-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_0238"
                      [class.is-invalid]="
                        (DicksonMDFroms1.controls['cmd_0238'].touched &&
                          DicksonMDFroms1.controls['cmd_0238'].errors) ||
                        (DicksonMDFroms1.controls['cmd_0238'].errors && isSubmit)
                      "
                      name="cmd_0238"
                      formControlName="cmd_0238"
                      value="1"
                      (change)="validationsDataAdd($event)"
                    />Yes</label
                  >
                  <label class="radio-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_0238"
                      [class.is-invalid]="
                        (DicksonMDFroms1.controls['cmd_0238'].touched &&
                          DicksonMDFroms1.controls['cmd_0238'].errors) ||
                        (DicksonMDFroms1.controls['cmd_0238'].errors && isSubmit)
                      "
                      name="cmd_0238"
                      formControlName="cmd_0238"
                      value="0"
                      (change)="validationsDataAdd($event)"
                    />No</label
                  >
                  <div
                    class="formLabel col-md-2"
                    *ngIf="
                      DicksonMDFroms1.controls['cmd_0238'].touched &&
                      DicksonMDFroms1.controls['cmd_0238'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="DicksonMDFroms1.controls['cmd_0238'].errors?.required"
                    >
                      required
                    </small>
                  </div>
                  <span
                    *ngIf="
                      (DicksonMDFroms1.controls['cmd_0238'].touched &&
                        DicksonMDFroms1.controls['cmd_0238'].errors) ||
                      (DicksonMDFroms1.controls['cmd_0238'].errors && isSubmit)
                    "
                  >
                    <i class="fas fa-times ml-2 text-danger"></i>
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="2" width="5%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="sideheadingpara">8</label>
                </div>
              </td>
              <td colspan="12" width="75%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="formLabel"
                    >Have any of your board certifications or eligibility ever
                    been revoked?
                  </label>
                </div>
              </td>
              <td colspan="4" width="20%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="radio-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_0239"
                      [class.is-invalid]="
                        (DicksonMDFroms1.controls['cmd_0239'].touched &&
                          DicksonMDFroms1.controls['cmd_0239'].errors) ||
                        (DicksonMDFroms1.controls['cmd_0239'].errors && isSubmit)
                      "
                      name="cmd_0239"
                      formControlName="cmd_0239"
                      value="1"
                      (change)="validationsDataAdd($event)"
                    />Yes</label
                  >
                  <label class="radio-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_0239"
                      [class.is-invalid]="
                        (DicksonMDFroms1.controls['cmd_0239'].touched &&
                          DicksonMDFroms1.controls['cmd_0239'].errors) ||
                        (DicksonMDFroms1.controls['cmd_0239'].errors && isSubmit)
                      "
                      name="cmd_0239"
                      formControlName="cmd_0239"
                      value="0"
                      (change)="validationsDataAdd($event)"
                    />No</label
                  >
                  <div
                    class="formLabel col-md-2"
                    *ngIf="
                      DicksonMDFroms1.controls['cmd_0239'].touched &&
                      DicksonMDFroms1.controls['cmd_0239'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="DicksonMDFroms1.controls['cmd_0239'].errors?.required"
                    >
                      required
                    </small>
                  </div>
                  <span
                    *ngIf="
                      (DicksonMDFroms1.controls['cmd_0239'].touched &&
                        DicksonMDFroms1.controls['cmd_0239'].errors) ||
                      (DicksonMDFroms1.controls['cmd_0239'].errors && isSubmit)
                    "
                  >
                    <i class="fas fa-times ml-2 text-danger"></i>
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="2" width="5%">
                <div    class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="sideheadingpara">9</label>
                </div>
              </td>
              <td colspan="12" width="75%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="formLabel"
                    >Have you ever chosen not to re-certify or voluntarily
                    surrendered your board certification(s) while under
                    investigation?
                  </label>
                </div>
              </td>
              <td colspan="4" width="20%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="radio-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_0240"
                      [class.is-invalid]="
                        (DicksonMDFroms1.controls['cmd_0240'].touched &&
                          DicksonMDFroms1.controls['cmd_0240'].errors) ||
                        (DicksonMDFroms1.controls['cmd_0240'].errors && isSubmit)
                      "
                      name="cmd_0240"
                      formControlName="cmd_0240"
                      value="1"
                      (change)="validationsDataAdd($event)"
                    />Yes</label
                  >
                  <label class="radio-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_0240"
                      [class.is-invalid]="
                        (DicksonMDFroms1.controls['cmd_0240'].touched &&
                          DicksonMDFroms1.controls['cmd_0240'].errors) ||
                        (DicksonMDFroms1.controls['cmd_0240'].errors && isSubmit)
                      "
                      name="cmd_0240"
                      formControlName="cmd_0240"
                      value="0"
                      (change)="validationsDataAdd($event)"
                    />No</label
                  >
                  <div
                    class="formLabel col-md-2"
                    *ngIf="
                      DicksonMDFroms1.controls['cmd_0240'].touched &&
                      DicksonMDFroms1.controls['cmd_0240'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="DicksonMDFroms1.controls['cmd_0240'].errors?.required"
                    >
                      required
                    </small>
                  </div>
                  <span
                    *ngIf="
                      (DicksonMDFroms1.controls['cmd_0240'].touched &&
                        DicksonMDFroms1.controls['cmd_0240'].errors) ||
                      (DicksonMDFroms1.controls['cmd_0240'].errors && isSubmit)
                    "
                  >
                    <i class="fas fa-times ml-2 text-danger"></i>
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18" width="5%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="sideheadingpara">DEA or CDS </label>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="2" width="5%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="sideheadingpara">10</label>
                </div>
              </td>
              <td colspan="12" width="75%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="formLabel"
                    >Have your Federal DEA and/or Controlled Substances
                    Certificate(s) or authorization(s) ever been denied,
                    suspended, revoked, restricted, denied renewal, or voluntarily
                    relinquished?</label
                  >
                </div>
              </td>
              <td colspan="4" width="20%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="radio-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_0241"
                      [class.is-invalid]="
                        (DicksonMDFroms1.controls['cmd_0241'].touched &&
                          DicksonMDFroms1.controls['cmd_0241'].errors) ||
                        (DicksonMDFroms1.controls['cmd_0241'].errors && isSubmit)
                      "
                      name="cmd_0241"
                      formControlName="cmd_0241"
                      value="1"
                      (change)="validationsDataAdd($event)"
                    />Yes</label
                  >
                  <label class="radio-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_0241"
                      [class.is-invalid]="
                        (DicksonMDFroms1.controls['cmd_0241'].touched &&
                          DicksonMDFroms1.controls['cmd_0241'].errors) ||
                        (DicksonMDFroms1.controls['cmd_0241'].errors && isSubmit)
                      "
                      name="cmd_0241"
                      formControlName="cmd_0241"
                      value="0"
                      (change)="validationsDataAdd($event)"
                    />No</label
                  >
                  <div
                    class="formLabel col-md-2"
                    *ngIf="
                      DicksonMDFroms1.controls['cmd_0241'].touched &&
                      DicksonMDFroms1.controls['cmd_0241'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="DicksonMDFroms1.controls['cmd_0241'].errors?.required"
                    >
                      required
                    </small>
                  </div>
                  <span
                    *ngIf="
                      (DicksonMDFroms1.controls['cmd_0241'].touched &&
                        DicksonMDFroms1.controls['cmd_0241'].errors) ||
                      (DicksonMDFroms1.controls['cmd_0241'].errors && isSubmit)
                    "
                  >
                    <i class="fas fa-times ml-2 text-danger"></i>
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18" width="5%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="sideheadingpara"
                    >Medicare, Medicaid or other Governmental Program
                    Participation
                  </label>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="2" width="5%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="sideheadingpara">11</label>
                </div>
              </td>
              <td colspan="12" width="75%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="formLabel"
                    >Have you ever been disciplined, excluded from, debarred,
                    suspended, reprimanded, sanctioned, censured, disqualified or
                    otherwise restricted in regard to participation in the
                    Medicare or Medicaid program, or in regard to other federal or
                    state governmental health care plans or programs?
                  </label>
                </div>
              </td>
              <td colspan="4" width="20%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="radio-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_0242"
                      [class.is-invalid]="
                        (DicksonMDFroms1.controls['cmd_0242'].touched &&
                          DicksonMDFroms1.controls['cmd_0242'].errors) ||
                        (DicksonMDFroms1.controls['cmd_0242'].errors && isSubmit)
                      "
                      name="cmd_0242"
                      formControlName="cmd_0242"
                      value="1"
                      (change)="validationsDataAdd($event)"
                    />Yes</label
                  >
                  <label class="radio-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_0242"
                      [class.is-invalid]="
                        (DicksonMDFroms1.controls['cmd_0242'].touched &&
                          DicksonMDFroms1.controls['cmd_0242'].errors) ||
                        (DicksonMDFroms1.controls['cmd_0242'].errors && isSubmit)
                      "
                      name="cmd_0242"
                      formControlName="cmd_0242"
                      value="0"
                      (change)="validationsDataAdd($event)"
                    />No</label
                  >
                  <div
                    class="formLabel col-md-2"
                    *ngIf="
                      DicksonMDFroms1.controls['cmd_0242'].touched &&
                      DicksonMDFroms1.controls['cmd_0242'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="DicksonMDFroms1.controls['cmd_0242'].errors?.required"
                    >
                      required
                    </small>
                  </div>
                  <span
                    *ngIf="
                      (DicksonMDFroms1.controls['cmd_0242'].touched &&
                        DicksonMDFroms1.controls['cmd_0242'].errors) ||
                      (DicksonMDFroms1.controls['cmd_0242'].errors && isSubmit)
                    "
                  >
                    <i class="fas fa-times ml-2 text-danger"></i>
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18" width="5%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="sideheadingpara"
                    >Other Sanctions or Investigations
                  </label>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="2" width="5%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="sideheadingpara">12</label>
                </div>
              </td>
              <td colspan="12" width="75%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="formLabel"
                    >Are you currently or have you ever been the subject of an
                    investigation by any hospital, licensing authority, DEA or CDS
                    authorizing entities, education or training program, Medicare
                    or Medicaid program, or any other private, federal or state
                    health program?
                  </label>
                </div>
              </td>
              <td colspan="4" width="20%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="radio-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_0243"
                      [class.is-invalid]="
                        (DicksonMDFroms1.controls['cmd_0243'].touched &&
                          DicksonMDFroms1.controls['cmd_0243'].errors) ||
                        (DicksonMDFroms1.controls['cmd_0243'].errors && isSubmit)
                      "
                      name="cmd_0243"
                      formControlName="cmd_0243"
                      value="1"
                      (change)="validationsDataAdd($event)"
                    />Yes</label
                  >
                  <label class="radio-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_0243"
                      [class.is-invalid]="
                        (DicksonMDFroms1.controls['cmd_0243'].touched &&
                          DicksonMDFroms1.controls['cmd_0243'].errors) ||
                        (DicksonMDFroms1.controls['cmd_0243'].errors && isSubmit)
                      "
                      name="cmd_0243"
                      formControlName="cmd_0243"
                      value="0"
                      (change)="validationsDataAdd($event)"
                    />No</label
                  >
                  <div
                    class="formLabel col-md-2"
                    *ngIf="
                      DicksonMDFroms1.controls['cmd_0243'].touched &&
                      DicksonMDFroms1.controls['cmd_0243'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="DicksonMDFroms1.controls['cmd_0243'].errors?.required"
                    >
                      required
                    </small>
                  </div>
                  <span
                    *ngIf="
                      (DicksonMDFroms1.controls['cmd_0243'].touched &&
                        DicksonMDFroms1.controls['cmd_0243'].errors) ||
                      (DicksonMDFroms1.controls['cmd_0243'].errors && isSubmit)
                    "
                  >
                    <i class="fas fa-times ml-2 text-danger"></i>
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18" width="5%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="formLabel"
                    >Other Sanctions or Investigations
                  </label>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="2" width="5%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="boldParagraph">13</label>
                </div>
              </td>
              <td colspan="12" width="75%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="boldParagraph"
                    >To your knowledge, has information pertaining to you ever
                    been reported to the National Practitioner Data Bank or
                    Healthcare Integrity and Protection Data Bank?
                  </label>
                </div>
              </td>
              <td colspan="4" width="20%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="radio-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_0244"
                      [class.is-invalid]="
                        (DicksonMDFroms1.controls['cmd_0244'].touched &&
                          DicksonMDFroms1.controls['cmd_0244'].errors) ||
                        (DicksonMDFroms1.controls['cmd_0244'].errors && isSubmit)
                      "
                      name="cmd_0244"
                      formControlName="cmd_0244"
                      value="1"
                      (change)="validationsDataAdd($event)"
                    />Yes</label
                  >
                  <label class="radio-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_0244"
                      [class.is-invalid]="
                        (DicksonMDFroms1.controls['cmd_0244'].touched &&
                          DicksonMDFroms1.controls['cmd_0244'].errors) ||
                        (DicksonMDFroms1.controls['cmd_0244'].errors && isSubmit)
                      "
                      name="cmd_0244"
                      formControlName="cmd_0244"
                      value="0"
                      (change)="validationsDataAdd($event)"
                    />No</label
                  >
                  <div
                    class="formLabel col-md-2"
                    *ngIf="
                      DicksonMDFroms1.controls['cmd_0244'].touched &&
                      DicksonMDFroms1.controls['cmd_0244'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="DicksonMDFroms1.controls['cmd_0244'].errors?.required"
                    >
                      required
                    </small>
                  </div>
                  <span
                    *ngIf="
                      (DicksonMDFroms1.controls['cmd_0244'].touched &&
                        DicksonMDFroms1.controls['cmd_0244'].errors) ||
                      (DicksonMDFroms1.controls['cmd_0244'].errors && isSubmit)
                    "
                  >
                    <i class="fas fa-times ml-2 text-danger"></i>
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="2" width="5%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="boldParagraph">14</label>
                </div>
              </td>
              <td colspan="12" width="75%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="formLabel"
                    >Have you ever received sanctions from or been the subject of
                    investigation by any regulatory agencies (e.g., CLIA, OSHA,
                    etc.)?
                  </label>
                </div>
              </td>
              <td colspan="4" width="20%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="radio-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_0245"
                      [class.is-invalid]="
                        (DicksonMDFroms1.controls['cmd_0245'].touched &&
                          DicksonMDFroms1.controls['cmd_0245'].errors) ||
                        (DicksonMDFroms1.controls['cmd_0245'].errors && isSubmit)
                      "
                      name="cmd_0245"
                      formControlName="cmd_0245"
                      value="1"
                      (change)="validationsDataAdd($event)"
                    />Yes</label
                  >
                  <label class="radio-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_0245"
                      [class.is-invalid]="
                        (DicksonMDFroms1.controls['cmd_0245'].touched &&
                          DicksonMDFroms1.controls['cmd_0245'].errors) ||
                        (DicksonMDFroms1.controls['cmd_0245'].errors && isSubmit)
                      "
                      name="cmd_0245"
                      formControlName="cmd_0245"
                      value="0"
                      (change)="validationsDataAdd($event)"
                    />No</label
                  >
                  <div
                    class="formLabel col-md-2"
                    *ngIf="
                      DicksonMDFroms1.controls['cmd_0245'].touched &&
                      DicksonMDFroms1.controls['cmd_0245'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="DicksonMDFroms1.controls['cmd_0245'].errors?.required"
                    >
                      required
                    </small>
                  </div>
                  <span
                    *ngIf="
                      (DicksonMDFroms1.controls['cmd_0245'].touched &&
                        DicksonMDFroms1.controls['cmd_0245'].errors) ||
                      (DicksonMDFroms1.controls['cmd_0245'].errors && isSubmit)
                    "
                  >
                    <i class="fas fa-times ml-2 text-danger"></i>
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="2" width="5%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="sideheadingpara">15</label>
                </div>
              </td>
              <td colspan="12" width="75%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="formLabel"
                    >Have you ever been investigated, sanctioned, reprimanded or
                    cautioned by a military hospital,facility, or agency, or
                    voluntarily terminated or resigned while under investigation
                    by a hospital or healthcare facility of any military agency?
                  </label>
                </div>
              </td>
              <td colspan="4" width="20%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="radio-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_0246"
                      [class.is-invalid]="
                        (DicksonMDFroms1.controls['cmd_0246'].touched &&
                          DicksonMDFroms1.controls['cmd_0246'].errors) ||
                        (DicksonMDFroms1.controls['cmd_0246'].errors && isSubmit)
                      "
                      name="cmd_0246"
                      formControlName="cmd_0246"
                      value="1"
                      (change)="validationsDataAdd($event)"
                    />Yes</label
                  >
                  <label class="radio-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_0246"
                      [class.is-invalid]="
                        (DicksonMDFroms1.controls['cmd_0246'].touched &&
                          DicksonMDFroms1.controls['cmd_0246'].errors) ||
                        (DicksonMDFroms1.controls['cmd_0246'].errors && isSubmit)
                      "
                      name="cmd_0246"
                      formControlName="cmd_0246"
                      value="0"
                      (change)="validationsDataAdd($event)"
                    />No</label
                  >
                  <div
                    class="formLabel col-md-2"
                    *ngIf="
                      DicksonMDFroms1.controls['cmd_0246'].touched &&
                      DicksonMDFroms1.controls['cmd_0246'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="DicksonMDFroms1.controls['cmd_0246'].errors?.required"
                    >
                      required
                    </small>
                  </div>
                  <span
                    *ngIf="
                      (DicksonMDFroms1.controls['cmd_0246'].touched &&
                        DicksonMDFroms1.controls['cmd_0246'].errors) ||
                      (DicksonMDFroms1.controls['cmd_0246'].errors && isSubmit)
                    "
                  >
                    <i class="fas fa-times ml-2 text-danger"></i>
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18" width="5%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="sideheadingpara">Malpractice Claims History </label>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="2" width="5%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="sideheadingpara">16</label>
                </div>
              </td>
              <td colspan="12" width="75%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="formLabel"
                    >Have you had any malpractice actions within the past 5 years
                    (pending, settled, arbitrated , mediated or litigated?
                  </label>
                </div>
              </td>
              <td colspan="4" width="20%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="radio-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_0247"
                      [class.is-invalid]="
                        (DicksonMDFroms1.controls['cmd_0247'].touched &&
                          DicksonMDFroms1.controls['cmd_0247'].errors) ||
                        (DicksonMDFroms1.controls['cmd_0247'].errors && isSubmit)
                      "
                      name="cmd_0247"
                      formControlName="cmd_0247"
                      value="1"
                      (change)="validationsDataAdd($event)"
                    />Yes</label
                  >
                  <label class="radio-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_0247"
                      [class.is-invalid]="
                        (DicksonMDFroms1.controls['cmd_0247'].touched &&
                          DicksonMDFroms1.controls['cmd_0247'].errors) ||
                        (DicksonMDFroms1.controls['cmd_0247'].errors && isSubmit)
                      "
                      name="cmd_0247"
                      formControlName="cmd_0247"
                      value="0"
                      (change)="validationsDataAdd($event)"
                    />No</label
                  >
                  <div
                    class="formLabel col-md-2"
                    *ngIf="
                      DicksonMDFroms1.controls['cmd_0247'].touched &&
                      DicksonMDFroms1.controls['cmd_0247'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="DicksonMDFroms1.controls['cmd_0247'].errors?.required"
                    >
                      required
                    </small>
                  </div>
                  <span
                    *ngIf="
                      (DicksonMDFroms1.controls['cmd_0247'].touched &&
                        DicksonMDFroms1.controls['cmd_0247'].errors) ||
                      (DicksonMDFroms1.controls['cmd_0247'].errors && isSubmit)
                    "
                  >
                    <i class="fas fa-times ml-2 text-danger"></i>
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18" width="5%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="sideheadingpara">Criminal </label>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="2" width="5%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="sideheadingpara">17</label>
                </div>
              </td>
              <td colspan="12" width="75%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="formLabel"
                    >Have you ever been convicted of, pled guilty to, or pled nolo
                    contendere to any felony that is reasonably related to your
                    qualifications, competence, functions, or duties as a medical
                    professional
                  </label>
                </div>
              </td>
              <td colspan="4" width="20%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="radio-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_0248"
                      [class.is-invalid]="
                        (DicksonMDFroms1.controls['cmd_0248'].touched &&
                          DicksonMDFroms1.controls['cmd_0248'].invalid) ||
                        (DicksonMDFroms1.controls['cmd_0248'].invalid && isSubmit)
                      "
                      name="cmd_0248"
                      formControlName="cmd_0248"
                      value="1"
                      (change)="validationsDataAdd($event)"
                    />Yes</label
                  >
                  <label class="radio-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_0248"
                      [class.is-invalid]="
                        (DicksonMDFroms1.controls['cmd_0248'].touched &&
                          DicksonMDFroms1.controls['cmd_0248'].invalid) ||
                        (DicksonMDFroms1.controls['cmd_0248'].invalid && isSubmit)
                      "
                      name="cmd_0248"
                      formControlName="cmd_0248"
                      value="0"
                      (change)="validationsDataAdd($event)"
                    />No</label
                  >
                  <div
                    class="formLabel col-md-2"
                    *ngIf="
                      DicksonMDFroms1.controls['cmd_0248'].touched &&
                      DicksonMDFroms1.controls['cmd_0248'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="DicksonMDFroms1.controls['cmd_0248'].errors?.required"
                    >
                      required
                    </small>
                  </div>
                  <span
                    *ngIf="
                      (DicksonMDFroms1.controls['cmd_0248'].touched &&
                        DicksonMDFroms1.controls['cmd_0248'].invalid) ||
                      (DicksonMDFroms1.controls['cmd_0248'].invalid && isSubmit)
                    "
                  >
                    <i class="fas fa-times ml-2 text-danger"></i>
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="2" width="5%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="sideheadingpara">18</label>
                </div>
              </td>
              <td colspan="12" width="75%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="formLabel"
                    >Have you ever been convicted of, pled guilty to, or pled nolo
                    contendere to any felony including an act of violence, child
                    abuse or a sexual offense?
                  </label>
                </div>
              </td>
              <td colspan="4" width="20%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="radio-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_0249"
                      [class.is-invalid]="
                        (DicksonMDFroms1.controls['cmd_0249'].touched &&
                          DicksonMDFroms1.controls['cmd_0249'].invalid) ||
                        (DicksonMDFroms1.controls['cmd_0249'].invalid && isSubmit)
                      "
                      name="cmd_0249"
                      formControlName="cmd_0249"
                      value="1"
                      (change)="validationsDataAdd($event)"
                    />Yes</label
                  >
                  <label class="radio-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_0249"
                      [class.is-invalid]="
                        (DicksonMDFroms1.controls['cmd_0249'].touched &&
                          DicksonMDFroms1.controls['cmd_0249'].invalid) ||
                        (DicksonMDFroms1.controls['cmd_0249'].invalid && isSubmit)
                      "
                      name="cmd_0249"
                      formControlName="cmd_0249"
                      value="0"
                      (change)="validationsDataAdd($event)"
                    />No</label
                  >
                  <div
                    class="formLabel col-md-2"
                    *ngIf="
                      DicksonMDFroms1.controls['cmd_0249'].touched &&
                      DicksonMDFroms1.controls['cmd_0249'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="DicksonMDFroms1.controls['cmd_0249'].errors?.required"
                    >
                      required
                    </small>
                  </div>
                  <span
                    *ngIf="
                      (DicksonMDFroms1.controls['cmd_0249'].touched &&
                        DicksonMDFroms1.controls['cmd_0249'].invalid) ||
                      (DicksonMDFroms1.controls['cmd_0249'].invalid && isSubmit)
                    "
                  >
                    <i class="fas fa-times ml-2 text-danger"></i>
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="2" width="5%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="sideheadingpara">19</label>
                </div>
              </td>
              <td colspan="12" width="75%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="formLabel"
                    >Have you been court-martialed for actions related to your
                    duties as a medical professional?
                  </label>
                </div>
              </td>
              <td colspan="4" width="20%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="radio-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_0250"
                      [class.is-invalid]="
                        (DicksonMDFroms1.controls['cmd_0250'].touched &&
                          DicksonMDFroms1.controls['cmd_0250'].invalid) ||
                        (DicksonMDFroms1.controls['cmd_0250'].invalid && isSubmit)
                      "
                      name="cmd_0250"
                      formControlName="cmd_0250"
                      value="1"
                      (change)="validationsDataAdd($event)"
                    />Yes</label
                  >
  
                  <label class="radio-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_0250"
                      [class.is-invalid]="
                        (DicksonMDFroms1.controls['cmd_0250'].touched &&
                          DicksonMDFroms1.controls['cmd_0250'].invalid) ||
                        (DicksonMDFroms1.controls['cmd_0250'].invalid && isSubmit)
                      "
                      name="cmd_0250"
                      formControlName="cmd_0250"
                      value="0"
                      (change)="validationsDataAdd($event)"
                    />No</label
                  >
                  <div
                    class="formLabel col-md-2"
                    *ngIf="
                      DicksonMDFroms1.controls['cmd_0250'].touched &&
                      DicksonMDFroms1.controls['cmd_0250'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="DicksonMDFroms1.controls['cmd_0250'].errors?.required"
                    >
                      required
                    </small>
                  </div>
                  <span
                    *ngIf="
                      (DicksonMDFroms1.controls['cmd_0250'].touched &&
                        DicksonMDFroms1.controls['cmd_0250'].invalid) ||
                      (DicksonMDFroms1.controls['cmd_0250'].invalid && isSubmit)
                    "
                  >
                    <i class="fas fa-times ml-2 text-danger"></i>
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18" width="5%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="sideheadingpara">Ability to Perform Job </label>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="2" width="5%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="sideheadingpara">20</label>
                </div>
              </td>
              <td colspan="12" width="75%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="formLabel"
                    >Are you currently engaged in the illegal use of drugs?
                    ("Currently" means sufficiently recent to justify a reasonable
                    belief that the use of drug may have an ongoing impact on
                    one's ability to pr actice medicine. It is not limited to the
                    day of, or within a matter of days or weeks before the d ate
                    of application, rather that it has occurred recently enough to
                    indicate the individual is actively engaged in such conduct.
                    "Illegal use of drugs" refers to drugs whose possession or
                    distribution is unlawful under the Controlled Substances Act,
                    21 U.S.C. § 812.22. It "does not include the use of a drug
                    taken under supervision by a licensed health care
                    professional, or other uses authorized by the Con trolled
                    Substances Act or other provision of Federal law." The term
                    does include, however, the unlawful use of prescription
                    controlled substances.)
                  </label>
                </div>
              </td>
              <td colspan="4" width="20%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="radio-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_0251"
                      [class.is-invalid]="
                        (DicksonMDFroms1.controls['cmd_0251'].touched &&
                          DicksonMDFroms1.controls['cmd_0251'].invalid) ||
                        (DicksonMDFroms1.controls['cmd_0251'].invalid && isSubmit)
                      "
                      name="cmd_0251"
                      formControlName="cmd_0251"
                      value="1"
                      (change)="validationsDataAdd($event)"
                    />Yes</label
                  >
                  <label class="radio-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_0251"
                      [class.is-invalid]="
                        (DicksonMDFroms1.controls['cmd_0251'].touched &&
                          DicksonMDFroms1.controls['cmd_0251'].invalid) ||
                        (DicksonMDFroms1.controls['cmd_0251'].invalid && isSubmit)
                      "
                      name="cmd_0251"
                      formControlName="cmd_0251"
                      value="0"
                      (change)="validationsDataAdd($event)"
                    />No</label
                  >
                  <div
                    class="formLabel col-md-2"
                    *ngIf="
                      DicksonMDFroms1.controls['cmd_0251'].touched &&
                      DicksonMDFroms1.controls['cmd_0251'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="DicksonMDFroms1.controls['cmd_0251'].errors?.required"
                    >
                      required
                    </small>
                  </div>
                  <span
                    *ngIf="
                      (DicksonMDFroms1.controls['cmd_0251'].touched &&
                        DicksonMDFroms1.controls['cmd_0251'].invalid) ||
                      (DicksonMDFroms1.controls['cmd_0251'].invalid && isSubmit)
                    "
                  >
                    <i class="fas fa-times ml-2 text-danger"></i>
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="2" width="5%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="formLabel">21</label>
                </div>
              </td>
              <td colspan="12" width="75%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="formLabel"
                    >Do you use any chemical substances that would in any way
                    impair or limit your ability to practice medicine and perform
                    the functions of your job with reasonable skill and safety?
                  </label>
                </div>
              </td>
              <td colspan="4" width="20%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="radio-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_0252"
                      [class.is-invalid]="
                        (DicksonMDFroms1.controls['cmd_0252'].touched &&
                          DicksonMDFroms1.controls['cmd_0252'].invalid) ||
                        (DicksonMDFroms1.controls['cmd_0252'].invalid && isSubmit)
                      "
                      name="cmd_0252"
                      formControlName="cmd_0252"
                      value="1"
                      (change)="validationsDataAdd($event)"
                    />Yes</label
                  >
                  <label class="radio-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_0252"
                      [class.is-invalid]="
                        (DicksonMDFroms1.controls['cmd_0252'].touched &&
                          DicksonMDFroms1.controls['cmd_0252'].invalid) ||
                        (DicksonMDFroms1.controls['cmd_0252'].invalid && isSubmit)
                      "
                      name="cmd_0252"
                      formControlName="cmd_0252"
                      value="0"
                      (change)="validationsDataAdd($event)"
                    />No</label
                  >
                  <div
                    class="formLabel col-md-2"
                    *ngIf="
                      DicksonMDFroms1.controls['cmd_0252'].touched &&
                      DicksonMDFroms1.controls['cmd_0252'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="DicksonMDFroms1.controls['cmd_0252'].errors?.required"
                    >
                      required
                    </small>
                  </div>
                  <span
                    *ngIf="
                      (DicksonMDFroms1.controls['cmd_0252'].touched &&
                        DicksonMDFroms1.controls['cmd_0252'].invalid) ||
                      (DicksonMDFroms1.controls['cmd_0252'].invalid && isSubmit)
                    "
                  >
                    <i class="fas fa-times ml-2 text-danger"></i>
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18" width="5%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="sideheadingpara">Ability to Perform Job </label>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="2" width="5%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="sideheadingpara">22</label>
                </div>
              </td>
              <td colspan="12" width="75%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="formLabel"
                    >Do you have any reason to believe that you would pose a risk
                    to the safety or well-being of your patients?</label
                  >
                </div>
              </td>
              <td colspan="4" width="20%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="radio-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_0253"
                      [class.is-invalid]="
                        (DicksonMDFroms1.controls['cmd_0253'].touched &&
                          DicksonMDFroms1.controls['cmd_0253'].invalid) ||
                        (DicksonMDFroms1.controls['cmd_0253'].invalid && isSubmit)
                      "
                      name="cmd_0253"
                      formControlName="cmd_0253"
                      value="1"
                      (change)="validationsDataAdd($event)"
                    />Yes</label
                  >
                  <label class="radio-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_0253"
                      [class.is-invalid]="
                        (DicksonMDFroms1.controls['cmd_0253'].touched &&
                          DicksonMDFroms1.controls['cmd_0253'].invalid) ||
                        (DicksonMDFroms1.controls['cmd_0253'].invalid && isSubmit)
                      "
                      name="cmd_0253"
                      formControlName="cmd_0253"
                      value="0"
                      (change)="validationsDataAdd($event)"
                    />No</label
                  >
                  <div
                    class="formLabel col-md-2"
                    *ngIf="
                      DicksonMDFroms1.controls['cmd_0253'].touched &&
                      DicksonMDFroms1.controls['cmd_0253'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="DicksonMDFroms1.controls['cmd_0253'].errors?.required"
                    >
                      required
                    </small>
                  </div>
                  <span
                    *ngIf="
                      (DicksonMDFroms1.controls['cmd_0253'].touched &&
                        DicksonMDFroms1.controls['cmd_0253'].invalid) ||
                      (DicksonMDFroms1.controls['cmd_0253'].invalid && isSubmit)
                    "
                  >
                    <i class="fas fa-times ml-2 text-danger"></i>
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="2" width="5%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="formLabel">23</label>
                </div>
              </td>
              <td colspan="12" width="75%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="formLabel"
                    >Are you unable to perform the essential functions of a
                    practitioner in your area of practice, with or without
                    reasonable accommodation?
                  </label>
                </div>
              </td>
              <td colspan="4" width="20%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="radio-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_0254"
                      [class.is-invalid]="
                        (DicksonMDFroms1.controls['cmd_0254'].touched &&
                          DicksonMDFroms1.controls['cmd_0254'].invalid) ||
                        (DicksonMDFroms1.controls['cmd_0254'].invalid && isSubmit)
                      "
                      name="cmd_0254"
                      formControlName="cmd_0254"
                      value="1"
                      (change)="validationsDataAdd($event)"
                    />Yes</label
                  >
                  <label class="radio-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_0254"
                      [class.is-invalid]="
                        (DicksonMDFroms1.controls['cmd_0254'].touched &&
                          DicksonMDFroms1.controls['cmd_0254'].invalid) ||
                        (DicksonMDFroms1.controls['cmd_0254'].invalid && isSubmit)
                      "
                      name="cmd_0254"
                      formControlName="cmd_0254"
                      value="0"
                      (change)="validationsDataAdd($event)"
                    />No</label
                  >
                  <div
                    class="formLabel col-md-2"
                    *ngIf="
                      DicksonMDFroms1.controls['cmd_0254'].touched &&
                      DicksonMDFroms1.controls['cmd_0254'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="DicksonMDFroms1.controls['cmd_0254'].errors?.required"
                    >
                      required
                    </small>
                  </div>
                  <span
                    *ngIf="
                      (DicksonMDFroms1.controls['cmd_0254'].touched &&
                        DicksonMDFroms1.controls['cmd_0254'].invalid) ||
                      (DicksonMDFroms1.controls['cmd_0254'].invalid && isSubmit)
                    "
                  >
                    <i class="fas fa-times ml-2 text-danger"></i>
                  </span>
                </div>
              </td>
            </tr>
          </table>
  
          <label class="boldParagraph"
            >Please use the space below to explain yes answers to any question
            except #16.
          </label>
          <hr class="tablePersonal" />
          <tr>
            <td colspan="12" width="100%" style="border-style: none;">
              
                <b style="font-size:16px;">Section II - Disclosure Questions-continued </b>
              
            </td>
          </tr>
          <table border="1" width="100%">
            <tr>
              <td colspan="18">
                <div class="form-inline no-margin sideLabelAlignment">
                  <i style="font-size:14px;"
                    >Please use the space below to explain yes answers to any
                    question except 16.
                </i>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="16">
                <div class="form-inline no-margin sideLabelAlignment">
                  <label class="boldParagraph">QUESTION NUMBER</label>
                </div>
              </td>
              <td colspan="2">
                <div class="form-inline no-margin sideLabelAlignment">
                  <label class="boldParagraph">PLEASE EXPLAIN </label>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="16">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <textarea
                    style="width: 70%"
                    [class.is-invalid]="
                      questionCounter > 0 && DicksonMDFroms1.controls['cmd_0255'].invalid"
                    class="form-control"
                    id="cmd_0255"
                    formControlName="cmd_0255"
                    name="cmd_0255"
                  ></textarea>
                  <div class="formLabel col-sm-2">
                    <small
                      class="text-danger"
                      *ngIf="
                        questionCounter > 0 && DicksonMDFroms1.controls['cmd_0255'].invalid"
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
              <td colspan="2">
                <div class="form-inline no-margin sideLabelAlignment">
                  <textarea
                    style="width: 70%"
                    class="form-control"
                    id="cmd_0256"
                    formControlName="cmd_0256"
                    name="cmd_0256"
                    data-id="Text5.2.0.0"
                  ></textarea>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="16">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <textarea
                    style="width: 70%"
                    [class.is-invalid]="
                      questionCounter > 1 && DicksonMDFroms1.controls['cmd_0257'].invalid
                    "
                    class="form-control"
                    id="cmd_0257"
                    formControlName="cmd_0257"
                    name="cmd_0257"
                    data-id="Text5.2.1.1"
                  ></textarea>
                  <div class="formLabel col-md-2">
                    <small
                      class="text-danger"
                      *ngIf="
                        questionCounter > 1 && DicksonMDFroms1.controls['cmd_0257'].invalid
                      "
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
              <td colspan="2">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <textarea
                    style="width: 70%"
                    class="form-control"
                    id="cmd_0258"
                    formControlName="cmd_0258"
                    name="cmd_0258"
                    data-id="Text5.2.0.1"
                  ></textarea>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="16">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <textarea
                    style="width: 70%"
                    [class.is-invalid]="
                      questionCounter > 2 && DicksonMDFroms1.controls['cmd_0259'].invalid
                    "
                    class="form-control"
                    id="cmd_0259"
                    formControlName="cmd_0259"
                    name="cmd_0259"
                    data-id="Text5.2.1.2"
                  ></textarea>
                  <div class="formLabel col-md-2">
                    <small
                      class="text-danger"
                      *ngIf="
                        questionCounter > 2 && DicksonMDFroms1.controls['cmd_0259'].invalid
                      "
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
              <td colspan="2">
                <div class="form-inline no-margin sideLabelAlignment">
                  <textarea
                    style="width: 70%"
                    class="form-control"
                    id="cmd_0260"
                    formControlName="cmd_0260"
                    name="cmd_0260"
                    data-id="Text5.2.0.2"
                  ></textarea>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="16">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <textarea
                    style="width: 70%"
                    [class.is-invalid]="
                      questionCounter > 3 && DicksonMDFroms1.controls['cmd_0261'].invalid
                    "
                    class="form-control"
                    id="cmd_0261"
                    formControlName="cmd_0261"
                    name="cmd_0261"
                    data-id="Text5.2.1.3"
                  ></textarea>
                  <div class="formLabel col-md-2">
                    <small
                      class="text-danger"
                      *ngIf="
                        questionCounter > 3 && DicksonMDFroms1.controls['cmd_0261'].invalid
                      "
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
              <td colspan="2">
                <div class="form-inline no-margin sideLabelAlignment">
                  <textarea
                    style="width: 70%"
                    class="form-control"
                    id="cmd_0262"
                    formControlName="cmd_0262"
                    name="cmd_0262"
                    data-id="Text5.2.0.3"
                  ></textarea>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="16">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <textarea
                    style="width: 70%"
                    [class.is-invalid]="
                      questionCounter > 4 && DicksonMDFroms1.controls['cmd_0263'].invalid
                    "
                    class="form-control"
                    id="cmd_0263"
                    formControlName="cmd_0263"
                    name="cmd_0263"
                    data-id="Text5.2.1.4"
                  ></textarea>
                  <div class="formLabel col-md-2">
                    <small
                      class="text-danger"
                      *ngIf="
                        questionCounter > 4 && DicksonMDFroms1.controls['cmd_0263'].invalid
                      "
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
              <td colspan="2">
                <div class="form-inline no-margin sideLabelAlignment">
                  <textarea
                    style="width: 70%"
                    class="form-control"
                    id="cmd_0264"
                    formControlName="cmd_0264"
                    name="cmd_0264"
                    data-id="Text5.2.0.4"
                  ></textarea>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="16">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <textarea
                    style="width: 70%"
                    [class.is-invalid]="
                      questionCounter > 5 && DicksonMDFroms1.controls['cmd_0265'].invalid
                    "
                    class="form-control"
                    id="cmd_0265"
                    formControlName="cmd_0265"
                    name="cmd_0265"
                    data-id="Text5.2.1.5"
                  ></textarea>
                  <div class="formLabel col-md-2">
                    <small
                      class="text-danger"
                      *ngIf="
                        questionCounter > 5 && DicksonMDFroms1.controls['cmd_0265'].invalid
                      "
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
              <td colspan="2">
                <div class="form-inline no-margin sideLabelAlignment">
                  <textarea
                    style="width: 70%"
                    class="form-control"
                    id="cmd_0266"
                    formControlName="cmd_0266"
                    name="cmd_0266"
                    data-id="Text5.2.0.5"
                  ></textarea>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="16">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <textarea
                    style="width: 70%"
                    [class.is-invalid]="
                      questionCounter > 6 && DicksonMDFroms1.controls['cmd_0267'].invalid
                    "
                    class="form-control"
                    id="cmd_0267"
                    formControlName="cmd_0267"
                    name="cmd_0267"
                    data-id="Text5.2.1.6"
                  ></textarea>
                  <div class="formLabel col-md-2">
                    <small
                      class="text-danger"
                      *ngIf="
                        questionCounter > 6 && DicksonMDFroms1.controls['cmd_0267'].invalid
                      "
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
              <td colspan="2">
                <div class="form-inline no-margin sideLabelAlignment">
                  <textarea
                    style="width: 70%"
                    class="form-control"
                    id="cmd_0268"
                    formControlName="cmd_0268"
                    name="cmd_0268"
                    data-id="Text5.2.0.6"
                  ></textarea>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="16">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <textarea
                    style="width: 70%"
                    [class.is-invalid]="
                      questionCounter > 7 && DicksonMDFroms1.controls['cmd_0269'].invalid
                    "
                    class="form-control"
                    id="cmd_0269"
                    formControlName="cmd_0269"
                    name="cmd_0269"
                    data-id="Text5.2.1.7"
                  ></textarea>
                  <div class="formLabel col-md-2">
                    <small
                      class="text-danger"
                      *ngIf="
                        questionCounter > 7 && DicksonMDFroms1.controls['cmd_0269'].invalid
                      "
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
              <td colspan="2">
                <div class="form-inline no-margin sideLabelAlignment">
                  <textarea
                    style="width: 70%"
                    class="form-control"
                    id="cmd_0270"
                    formControlName="cmd_0270"
                    name="cmd_0270"
                    data-id="Text5.2.0.7"
                  ></textarea>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="16">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <textarea
                    style="width: 70%"
                    [class.is-invalid]="
                      questionCounter > 8 && DicksonMDFroms1.controls['cmd_0271'].invalid
                    "
                    class="form-control"
                    id="cmd_0271"
                    formControlName="cmd_0271"
                    name="cmd_0271"
                    data-id="Text5.2.1.8.0"
                  ></textarea>
                  <div class="formLabel col-md-2">
                    <small
                      class="text-danger"
                      *ngIf="
                        questionCounter > 8 && DicksonMDFroms1.controls['cmd_0271'].invalid
                      "
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
              <td colspan="2">
                <div class="form-inline no-margin sideLabelAlignment">
                  <textarea
                    style="width: 70%"
                    class="form-control"
                    id="cmd_0272"
                    formControlName="cmd_0272"
                    name="cmd_0272"
                    data-id="Text5.2.0.8.0"
                  ></textarea>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="16">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <textarea
                    style="width: 70%"
                    [class.is-invalid]="
                      questionCounter > 9 && DicksonMDFroms1.controls['cmd_0273'].invalid
                    "
                    class="form-control"
                    id="cmd_0273"
                    formControlName="cmd_0273"
                    name="cmd_0273"
                    data-id="Text5.2.1.9.0"
                  ></textarea>
                  <div class="formLabel col-md-2">
                    <small
                      class="text-danger"
                      *ngIf="
                        questionCounter > 9 && DicksonMDFroms1.controls['cmd_0273'].invalid
                      "
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
              <td colspan="2">
                <div class="form-inline no-margin sideLabelAlignment">
                  <textarea
                    style="width: 70%"
                    class="form-control"
                    id="cmd_0274"
                    formControlName="cmd_0274"
                    name="cmd_0274"
                    data-id="Text5.2.0.9.0"
                  ></textarea>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="16">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <textarea
                    style="width: 70%"
                    [class.is-invalid]="
                      questionCounter > 10 && DicksonMDFroms1.controls['cmd_0275'].invalid
                    "
                    class="form-control"
                    id="cmd_0275"
                    formControlName="cmd_0275"
                    name="cmd_0275"
                    data-id="Text5.2.1.8.1"
                  ></textarea>
                  <div class="formLabel col-md-2">
                    <small
                      class="text-danger"
                      *ngIf="
                        questionCounter > 10 &&
                        DicksonMDFroms1.controls['cmd_0275'].invalid
                      "
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
              <td colspan="2">
                <div class="form-inline no-margin sideLabelAlignment">
                  <textarea
                    style="width: 70%"
                    class="form-control"
                    id="cmd_0276"
                    formControlName="cmd_0276"
                    name="cmd_0276"
                    data-id="Text5.2.0.8.1"
                  ></textarea>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="16">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <textarea
                    style="width: 70%"
                    [class.is-invalid]="
                      questionCounter > 11 && DicksonMDFroms1.controls['cmd_0277'].invalid
                    "
                    class="form-control"
                    id="cmd_0277"
                    formControlName="cmd_0277"
                    name="cmd_0277"
                    data-id="Text5.2.1.9.1"
                  ></textarea>
                  <div class="formLabel col-md-2">
                    <small
                      class="text-danger"
                      *ngIf="
                        questionCounter > 11 &&
                        DicksonMDFroms1.controls['cmd_0277'].invalid
                      "
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
              <td colspan="2">
                <div class="form-inline no-margin sideLabelAlignment">
                  <textarea
                    style="width: 70%"
                    class="form-control"
                    id="cmd_0278"
                    formControlName="cmd_0278"
                    name="cmd_0278"
                    data-id="Text5.2.0.9.1"
                  ></textarea>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="16">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <textarea
                    style="width: 70%"
                    [class.is-invalid]="
                      questionCounter > 12 && DicksonMDFroms1.controls['cmd_0279'].invalid
                    "
                    class="form-control"
                    id="cmd_0279"
                    formControlName="cmd_0279"
                    name="cmd_0279"
                    data-id="Text5.2.1.8.2"
                  ></textarea>
                  <div class="formLabel col-md-2">
                    <small
                      class="text-danger"
                      *ngIf="
                        questionCounter > 12 &&
                        DicksonMDFroms1.controls['cmd_0279'].invalid
                      "
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
              <td colspan="2">
                <div class="form-inline no-margin sideLabelAlignment">
                  <textarea
                    style="width: 70%"
                    class="form-control"
                    id="cmd_0280"
                    formControlName="cmd_0280"
                    name="cmd_0280"
                    data-id="Text5.2.0.8.2"
                  ></textarea>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="16">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <textarea
                    style="width: 70%"
                    [class.is-invalid]="
                      questionCounter > 13 && DicksonMDFroms1.controls['cmd_0281'].invalid
                    "
                    class="form-control"
                    id="cmd_0281"
                    formControlName="cmd_0281"
                    name="cmd_0281"
                    data-id="Text5.2.1.9.2"
                  ></textarea>
                  <div class="formLabel col-md-2">
                    <small
                      class="text-danger"
                      *ngIf="
                        questionCounter > 13 &&
                        DicksonMDFroms1.controls['cmd_0281'].invalid
                      "
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
              <td colspan="2">
                <div class="form-inline no-margin sideLabelAlignment">
                  <textarea
                    style="width: 70%"
                    class="form-control"
                    id="cmd_0282"
                    formControlName="cmd_0282"
                    name="cmd_0282"
                    data-id="Text5.2.0.9.2"
                  ></textarea>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="16">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <textarea
                    style="width: 70%"
                    [class.is-invalid]="
                      questionCounter > 14 && DicksonMDFroms1.controls['cmd_0283'].invalid
                    "
                    class="form-control"
                    id="cmd_0283"
                    formControlName="cmd_0283"
                    name="cmd_0283"
                    data-id="Text5.2.1.8.3"
                  ></textarea>
                  <div class="formLabel col-md-2">
                    <small
                      class="text-danger"
                      *ngIf="
                        questionCounter > 14 &&
                        DicksonMDFroms1.controls['cmd_0283'].invalid
                      "
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
              <td colspan="2">
                <div class="form-inline no-margin sideLabelAlignment">
                  <textarea
                    style="width: 70%"
                    class="form-control"
                    id="cmd_0284"
                    formControlName="cmd_0284"
                    name="cmd_0284"
                    data-id="Text5.2.0.8.3"
                  ></textarea>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="16">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <textarea
                    style="width: 70%"
                    [class.is-invalid]="
                      questionCounter > 15 && DicksonMDFroms1.controls['cmd_0285'].invalid
                    "
                    class="form-control"
                    id="cmd_0285"
                    formControlName="cmd_0285"
                    name="cmd_0285"
                    data-id="Explain 10"
                  ></textarea>
                  <div
                    class="formLabel col-md-2"
                    *ngIf="
                      DicksonMDFroms1.controls['cmd_0285'].touched &&
                      DicksonMDFroms1.controls['cmd_0285'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="
                        questionCounter > 15 &&
                        DicksonMDFroms1.controls['cmd_0285'].invalid
                      "
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
              <td colspan="2">
                <div class="form-inline no-margin sideLabelAlignment">
                  <textarea
                    style="width: 70%"
                    class="form-control"
                    id="cmd_0286"
                    formControlName="cmd_0286"
                    name="cmd_0286"
                    data-id="Page 10"
                  ></textarea>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="16">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <textarea
                    style="width: 70%"
                    [class.is-invalid]="
                      questionCounter > 16 && DicksonMDFroms1.controls['cmd_0287'].invalid
                    "
                    class="form-control"
                    id="cmd_0287"
                    formControlName="cmd_0287"
                    name="cmd_0287"
                    data-id="Text5.2.1.9.3"
                  ></textarea>
                  <div class="formLabel col-md-2">
                    <small
                      class="text-danger"
                      *ngIf="
                        questionCounter > 16 &&
                        DicksonMDFroms1.controls['cmd_0287'].invalid
                      "
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
              <td colspan="2">
                <div class="form-inline no-margin sideLabelAlignment">
                  <textarea
                    style="width: 70%"
                    class="form-control"
                    id="cmd_0288"
                    formControlName="cmd_0288"
                    name="cmd_0288"
                    data-id="Text5.2.0.9.3"
                  ></textarea>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="16">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <textarea
                    style="width: 70%"
                    [class.is-invalid]="
                      questionCounter > 17 && DicksonMDFroms1.controls['cmd_0289'].invalid
                    "
                    class="form-control"
                    id="cmd_0289"
                    formControlName="cmd_0289"
                    name="cmd_0289"
                    data-id="Text5.2.1.8.4"
                  ></textarea>
                  <div class="formLabel col-md-2">
                    <small
                      class="text-danger"
                      *ngIf="
                        questionCounter > 17 &&
                        DicksonMDFroms1.controls['cmd_0289'].invalid
                      "
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
              <td colspan="2">
                <div class="form-inline no-margin sideLabelAlignment">
                  <textarea
                    style="width: 70%"
                    class="form-control"
                    id="cmd_0290"
                    formControlName="cmd_0290"
                    name="cmd_0290"
                    data-id="Text5.2.0.8.4"
                  ></textarea>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="16">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <textarea
                    style="width: 70%"
                    [class.is-invalid]="
                      questionCounter > 18 && DicksonMDFroms1.controls['cmd_0291'].invalid
                    "
                    class="form-control"
                    id="cmd_0291"
                    formControlName="cmd_0291"
                    name="cmd_0291"
                    data-id="Text5.2.1.9.4"
                  ></textarea>
                  <div class="formLabel col-md-2">
                    <small
                      class="text-danger"
                      *ngIf="
                        questionCounter > 18 &&
                        DicksonMDFroms1.controls['cmd_0291'].invalid
                      "
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
              <td colspan="2">
                <div class="form-inline no-margin sideLabelAlignment">
                  <textarea
                    style="width: 70%"
                    class="form-control"
                    id="cmd_0292"
                    formControlName="cmd_0292"
                    name="cmd_0292"
                    data-id="Text5.2.0.9.4"
                  ></textarea>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="16">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <textarea
                    style="width: 70%"
                    [class.is-invalid]="
                      questionCounter > 19 && DicksonMDFroms1.controls['cmd_0293'].invalid
                    "
                    class="form-control"
                    id="cmd_0293"
                    formControlName="cmd_0293"
                    name="cmd_0293"
                    data-id="Text5.2.0.9.4"
                  ></textarea>
                  <div class="formLabel col-md-2">
                    <small
                      class="text-danger"
                      *ngIf="
                        questionCounter > 19 &&
                        DicksonMDFroms1.controls['cmd_0293'].invalid
                      "
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
              <td colspan="2">
                <div class="form-inline no-margin sideLabelAlignment">
                  <textarea
                    style="width: 70%"
                    class="form-control"
                    id="cmd_0294"
                    formControlName="cmd_0294"
                    name="cmd_0294"
                    data-id="Page 10"
                  ></textarea>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="16">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <textarea
                    style="width: 70%"
                    [class.is-invalid]="
                      questionCounter > 20 && DicksonMDFroms1.controls['cmd_0295'].invalid
                    "
                    class="form-control"
                    id="cmd_0295"
                    formControlName="cmd_0295"
                    name="cmd_0295"
                    data-id="Explain 10"
                  ></textarea>
                  <div class="formLabel col-md-2">
                    <small
                      class="text-danger"
                      *ngIf="
                        questionCounter > 20 &&
                        DicksonMDFroms1.controls['cmd_0295'].invalid
                      "
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
              <td colspan="2">
                <div class="form-inline no-margin sideLabelAlignment">
                  <textarea
                    style="width: 70%"
                    class="form-control"
                    id="cmd_0296"
                    formControlName="cmd_0296"
                    name="cmd_0296"
                    data-id="Page 10"
                  ></textarea>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="16">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <textarea
                    style="width: 70%"
                    [class.is-invalid]="
                      questionCounter > 21 && DicksonMDFroms1.controls['cmd_0297'].invalid
                    "
                    class="form-control"
                    id="cmd_0297"
                    formControlName="cmd_0297"
                    name="cmd_0297"
                    data-id="Explain 10"
                  ></textarea>
                  <div class="formLabel col-md-2">
                    <small
                      class="text-danger"
                      *ngIf="
                        questionCounter > 21 &&
                        DicksonMDFroms1.controls['cmd_0297'].invalid
                      "
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
              <td colspan="2">
                <div class="form-inline no-margin sideLabelAlignment">
                  <textarea
                    style="width: 70%"
                    class="form-control"
                    id="cmd_0298"
                    formControlName="cmd_0298"
                    name="cmd_0298"
                    data-id="Page 10"
                  ></textarea>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="16">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <textarea
                    style="width: 70%"
                    [class.is-invalid]="
                      questionCounter > 22 && DicksonMDFroms1.controls['cmd_0299'].invalid
                    "
                    class="form-control"
                    id="cmd_0299"
                    formControlName="cmd_0299"
                    name="cmd_0299"
                    data-id="Explain 10"
                  ></textarea>
                  <div class="formLabel col-md-2">
                    <small
                      class="text-danger"
                      *ngIf="
                        questionCounter > 22 &&
                        DicksonMDFroms1.controls['cmd_0299'].invalid
                      "
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
              <td colspan="2">
                <div class="form-inline no-margin sideLabelAlignment">
                  <textarea
                    style="width: 70%"
                    class="form-control"
                    id="cmd_0300"
                    formControlName="cmd_0300"
                    name="cmd_0300"
                    data-id="Page 10"
                  ></textarea>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="16">
                <div class="form-inline no-margin sideLabelAlignment">
                  <textarea
                    style="width: 70%"
                    class="form-control"
                    id="cmd_0301"
                    formControlName="cmd_0301"
                    name="cmd_0301"
                    data-id="Explain 10"
                  ></textarea>
                </div>
              </td>
              <td colspan="2">
                <div class="form-inline no-margin sideLabelAlignment">
                  <textarea
                    style="width: 70%"
                    class="form-control"
                    id="cmd_0302"
                    formControlName="cmd_0302"
                    name="cmd_0302"
                    data-id="Page 10"
                  ></textarea>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="16">
                <div class="form-inline no-margin sideLabelAlignment">
                  <textarea
                    style="width: 70%"
                    class="form-control"
                    id="cmd_0303"
                    formControlName="cmd_0303"
                    name="cmd_0303"
                    data-id="Explain 10"
                  ></textarea>
                </div>
              </td>
              <td colspan="2">
                <div class="form-inline no-margin sideLabelAlignment">
                  <textarea
                    style="width: 70%"
                    class="form-control"
                    id="cmd_0304"
                    formControlName="cmd_0304"
                    name="cmd_0304"
                    data-id="Page 10"
                  ></textarea>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="16">
                <div class="form-inline no-margin sideLabelAlignment">
                  <textarea
                    style="width: 70%"
                    class="form-control"
                    id="cmd_0305"
                    formControlName="cmd_0305"
                    name="cmd_0305"
                    data-id="Explain 10"
                  ></textarea>
                </div>
              </td>
              <td colspan="2">
                <div class="form-inline no-margin sideLabelAlignment">
                  <textarea
                    style="width: 70%"
                    class="form-control"
                    id="cmd_0306"
                    formControlName="cmd_0306"
                    name="cmd_0306"
                    data-id="Page 10"
                  ></textarea>
                </div>
              </td>
            </tr>
          </table>
          
          <hr class="tablePersonal" />
          <h3 class="texasHThree">
            <b>Section III - Standard Authorization, Attestation and Release </b>          
            <label class="boldParagraph">(Not for Use for Employment Purposes) I understand and agree that, as
            part of the credentialing application process for participation and/or
            clinical privileges (hereinafter, referred to as "Participation") at or
            with</label>
          </h3>
          <table border="1" width="100%">
            <tr>
              <td colspan="18">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="formLabel">
                    (PLEASE INDICATE MANAGED CARE COMPANY(S) OR HOSPITAL(S) TO
                    WHICH YOU ARE APPLYING) (HEREINAFTER, INDIVIDUALLY REFERRED TO
                    AS THE “ENTITY”)
                  </label>
                  <input
                    style="width: 70%"
                    class="form-control"
                    id="cmd_0307"
                    name="cmd_0307"
                    formControlName="cmd_0307"
                    type="text"
                    data-id="Text5.1.4"
                  />
                </div>
              </td>
            </tr>
          </table>
  
          <tr>
            <td colspan="18" width="80%">
              <div class="form-group form-inline no-margin sideLabelAlignment">
                <label class="boldParagraph"
                  >and any of the Entity’s affiliated entities, I am required to
                  provide sufficient and accurate information for a proper
                  evaluation of my current licensure, relevant training and⁄or
                  experience, clinical competence, health status, character,
                  ethics, and any other criteria used by the Entity for
                  determining initial and ongoing eligibility for Participation.
                  Each Entity and its representatives, employees, and agent(s)
                  acknowledge that the information obtained relating to the
                  application process will be held confidential to the extent
                  permitted by law.
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="18" width="80%">
              <div class="form-group form-inline no-margin sideLabelAlignment">
                <label class="boldParagraph"
                  >I acknowledge that each Entity has its own criteria for
                  acceptance, and I may be accepted or rejected by each
                  independently. I further acknowledge and understand that my
                  cooperation in obtaining information and my consent to the
                  release of information do not guarantee that any Entity will
                  grant me clinical privileges or contract with me as a provider
                  of services. I understand that my application for Participation
                  with the Entity is not an application for employment with the
                  Entity and that acceptance of my application by the Entity will
                  not result in my employment by the Entity.
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="18" width="80%">
              <div class="form-group form-inline no-margin sideLabelAlignment">
                <b class="sideheadingpara">For Hospital Credentialing.</b><label class="boldParagraph"
                > I consent to appear for an
                  interview with the credentials committee, medical staff
                  executive committee, or other representatives of the medical
                  staff, hospital administration or the governing board, if
                  required or requested. As a medical staff member, I pledge to
                  provide continuous care for my patients. I have been informed of
                  existing hospital by laws, rules and regulation,, and policies
                  regarding the application process,and I agree that as a medical
                  staff member, I will be bound by them.
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="18" width="80%">
              <div class="form-group form-inline no-margin sideLabelAlignment">
                <b class="sideheadingpara">Authorization of Investigation Concerning Application for
                  Participation.</b><label class="boldParagraph"
                  >I authorize the following individuals including,
                  without limitation, the Entity, its representatives, employees,
                  and/or designated agent(s); the Entity’s affiliated entities and
                  their representatives, employees, and/or designated agents; and
                  the Entity’s designated professional credentials verification
                  organization (collectively referred to as “Agents”), to
                  investigate information, which includes both oral and written
                  statements, records, and documents, concerning my application
                  for Participation. I agree to allow the Entity and/or its
                  Agent(s) to inspect all records and documents relating to such
                  an investigation.
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="18" width="80%">
              <div class="form-group form-inline no-margin sideLabelAlignment">
                <b class="sideheadingpara">Authorization of Third-Party Sources to Release Information
                  Concerning Application for Participation.</b> <label class="boldParagraph"
                  >I authorize any third
                  party, including, but not limited to, individuals, agencies,
                  medical groups responsible for credentials verification,
                  corporations, companies, employers, former employers, hospitals,
                  health plans, health maintenance organizations, managed care
                  organizations, law enforcement or licensing agencies, insurance
                  companies, educational and other institutions, military
                  services, medical credentialing and accreditation agencies,
                  professional medical societies, the Federation of State Medical
                  Boards, the National Practitioner Data Bank, and the Health Care
                  Integrity and Protection Data Bank, to release to the Entity
                  and/or its Agent(s), information, including otherwise privileged
                  or confidential information, concerning my professional
                  qualifications, credentials, clinical competence, quality
                  assurance and utilization data, character, mental condition,
                  physical condition, alcohol or chemical dependency diagnosis and
                  treatment, ethics, behavior, or any other matter reasonably
                  having a bearing on my qualifications for Participation in, or
                  with, the Entity. I authorize my current and past professional
                  liability carrier(s) to release my history of claims that have
                  been made and/or are currently pending against me. I
                  specifically waive written notice from any entities and
                  individuals who provide information based upon this
                  Authorization, Attestation and Release.</label
                >
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="18" width="80%">
              <div class="form-group form-inline no-margin sideLabelAlignment">
                
                  <b class="sideheadingpara">Authorization of Release and Exchange of Disciplinary
                  Information.</b><label class="boldParagraph">I hereby further authorize any third party at which
                  I currently have Participation or had Participation and/or each
                  third party's agents to release "Disciplinary Information,"" as
                  defined below, to the Entity and/or its Agent(s). I hereby
                  further authorize the Agent(s) to release Disciplinary
                  Information about any disciplinary action taken against me to
                  its participating Entities at which I have Participation, and as
                  may be otherwise required by law. As used herein, "Disciplinary
                  Information" means information concerning: (I) any action taken
                  by such health care organizations, their administrators, or
                  their medical or other committees to revoke, deny, suspend,
                  restrict, or condition my Participation or impose a corrective
                  action plan; (ii) any other disciplinary action involving me,
                  including, but not limited to, discipline in the employment
                  context; or (iii) my resignation prior to the conclusion of any
                  disciplinary proceedings or prior to the commencement of formal
                  charges, but after I have knowledge that such formal charges
                  were being (or are being) contemplated and/or were (or are) in
                  preparation.
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="18" width="80%">
              <div class="form-group form-inline no-margin sideLabelAlignment">
                
                  <b class="sideheadingpara">Release from Liability.</b><label class="boldParagraph">I release from all liability and hold
                  harmless any Entity, its Agent(s), and any other third party for
                  their acts performed in good faith and without malice unless
                  such acts are due to the gross negligence or willful misconduct
                  of the Entity, its Agent(s), or other third party in connection
                  with the gathering, release and exchange of, and reliance upon,
                  information used in accordance with this Authorization,
                  Attestation and Release. I further agree not to sue any Entity,
                  any Agent(s), or any other third party for their acts,
                  defamation or any other claims based on statements made in good
                  faith and without malice or misconduct of such Entity, Agent(s)
                  or third party in connection with the credentialing process.
                  This release shall be in addition to, and in no way shall limit,
                  any other applicable immunities provided by law for peer review
                  and credentialing activities.
                </label>
              </div>
            </td>
          </tr>
          <hr class="tablePersonal" />
          <h3 class="texasHThree">
            <b>Section III - Standard Authorization, Attestation and Release</b> -
            continued
          </h3>
          <tr>
            <td colspan="18" width="80%">
              <div class="form-group form-inline no-margin sideLabelAlignment">
                <label class="boldParagraph"
                  >In this Authorization, Attestation and Release, all references
                  to the Entity, its Agent(s), and/or other third party include
                  their respective employees, directors, officers, advisors,
                  counsel, and agents. The Entity or any of its affiliates or
                  agents retains the right to allow access to the application
                  information for purposes of a credentialing audit to customers
                  and/or their auditors to the extent required in connection with
                  an audit of the credentialing processes and provided that the
                  customer and/or their auditor executes an appropriate
                  confidentiality agreement. I understand and agree that this
                  Authorization, Attestation and Release is irrevocable for any
                  period during which I am an applicant for Participation at an
                  Entity, a member of an Entity's medical or health care staff, or
                  a participating provider of an Entity. I agree to execute
                  another form of consent if law or regulation limits the
                  application of this irrevocable authorization. I understand that
                  my failure to promptly provide another consent may be grounds
                  for termination or discipline by the Entity in accordance with
                  the applicable bylaws, rules, and regulations, and requirements
                  of the Entity, or grounds for my termination of Participation at
                  or with the Entity. I agree that information obtained in
                  accordance with the provisions of this Authorization,
                  Attestation and Release is not and will not be a violation of my
                  privacy.
                </label>
              </div>
            </td>
          </tr>
          <tr >
            <td colspan="18" width="80%">
              <div class="form-group form-inline no-margin sideLabelAlignment">
                <label class="boldParagraph"
                  >I certify that all information provided by me in my application
                  is true, correct, and complete to the best of my knowledge and
                  belief, and that I will notify the Entity and/or its Agent(s)
                  within 10 days of any material changes to the information I have
                  provided in my application or authorized to be released pursuant
                  to the credentialing process. I understand that corrections to
                  the application are permitted at any time prior to a
                  determination of Participation by the Entity, and must be
                  submitted on-line or in writing, and must be dated and signed by
                  me (may be a written or an electronic signature). I understand
                  and agree that any material misstatement or omission in the
                  application may constitute grounds for withdrawal of the
                  application from consideration; denial or revocation of
                  Participation; and/or immediate suspension or termination of
                  Participation. This action may be disclosed to the Entity and/or
                  its Agent(s).
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="18" width="100%">
              <div class="form-group form-inline no-margin sideLabelAlignment">
                <label class="boldParagraph"
                  >I further acknowledge that I have read and understand the
                  foregoing Authorization, Attestation and Release. I understand
                  and agree that a facsimile or photocopy of this Authorization,
                  Attestation and Release shall be as effective as the original.
                </label>
              </div>
              <div class="vcenter">
                <label class="boldForImage">SIGNATURE</label>
                <div class="col-md-3 signaturePadding">
                  <img
                    *ngIf="!showOne"
                    class="img"
                    src="./assets/images/e_sign.png"
                    alt="Credential my Doc"
                    (click)="esignOpenOne()"
                  />
                  <img
                    *ngIf="showOne"
                    class="img eSignImageShow"
                    [src]="esignOne"
                    alt="Credential my Doc"
                    (click)="esignOpenOne()"
                  />
                  <input
                    type="hidden"
                    name="cmd_11122"
                    id="cmd_11122"
                    formControlName="cmd_11122"
                    [class.is-invalid]="(DicksonMDFroms1.controls['cmd_11122'].touched && DicksonMDFroms1.controls['cmd_11122'].invalid) || (DicksonMDFroms1.controls['cmd_11122'].invalid && isSubmit)"
                    value="{{ finalImgPath }}"
                  />
                  <div class="invalid-feedback"
                      *ngIf="(DicksonMDFroms1.controls['cmd_11122'].touched && DicksonMDFroms1.controls['cmd_11122'].invalid) || (DicksonMDFroms1.controls['cmd_11122'].invalid && isSubmit)">
                          Signature is required and cannot be empty
                  </div>    
                </div>
                <input
                  style="width: 50%"
                  class="form-control"
                  id="cmd_0897"
                  name="cmd_0897"
                  formControlName="cmd_0897"
                  type="text"
                  data-id="full_name"
                />
                <label class="boldForLabel">NAME</label>
                <input
                  style="width: 50%"
                  class="form-control"
                  id="cmd_0779"
                  name="cmd_0779"
                  formControlName="cmd_0779"
                  type="text"
                  data-id="NPI"
                />
                <label class="boldForLabel"
                  >Last 4 digits of SSN or NPI (PLEASE PRINT OR TYPE)
                </label>
                <div class="form-group col-md-3 input-group">
                  <input
                    
                    class="form-control onboard  datepicker"
                    ngbDatepicker
                    #d63="ngbDatepicker"
                    id="cmd_0310"
                    name="cmd_0310"
                    formControlName="cmd_0310"
                    type="text"
                    data-id="Text5.1.2"
                    (blur)="clearInputIfInvalid('cmd_0310')"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="d63.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
                <div  *ngIf="DicksonMDFroms1.get('cmd_0310').invalid ">
                  <small class="text-danger" *ngIf="DicksonMDFroms1.get('cmd_0310').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="DicksonMDFroms1.get('cmd_0310').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="DicksonMDFroms1.get('cmd_0310').errors?.invalidDateyear">
                    year is out of range(1900-2099)
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms1.get('cmd_0310').errors?.invalidDateRange">
                      date is out of range
                      </small>
                </div>
                <label class="boldForLabel">DATE (MM/DD/YYYY) </label>
              </div>
            </td>
          </tr>
        
    
  
    

        
  
  </form>
</div>

