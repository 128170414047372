import { Injectable } from '@angular/core';
import { ApiServiceService } from '../../../shared/services/cmd-api-service/api-service.service';

@Injectable({
  providedIn: 'root'
})
export class UpdatePasswordService {

  constructor(private apiService:ApiServiceService) { }

  public changedPassword(postParams){
      return this.apiService.post('updateexpiredcreds',postParams);
  } 


}
