<div [formGroup]="texas" id="texas">
    <table border="1" width="100%">
        <tr>
            <td colspan="18">
                <div class="form-group form-inline no-margin col-md-4 sideLabelAlignment">
                    <label class="normalParagraph">TYPE OF PROFESSIONAL</label>
                </div>
                <div class="form-group selectDrop col-md-3 allMargin">
                    <ng-select [items]="credentials" bindLabel="name" placeholder="Select Degree" [class.is-invalid]="(texas.controls['822'].touched && texas.controls['822'].invalid) || (texas.controls['822'].invalid && isSubmit)" bindValue="id" formControlName="822">
                    </ng-select>
                    <div class="formLabel col-md-11"
                        *ngIf="texas.controls['822'].touched && texas.controls['822'].invalid">
                        <small class="text-danger" *ngIf="texas.controls['822'].errors?.required">
                            required
                        </small>
                    </div>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18">
                <div class="form-group no-margin col-md-3 sideLabelAlignment">
                    <label class="normalParagraph">LAST NAME</label>
                    <input class="form-control onboard " id="9" name="9" [class.is-invalid]="(texas.controls['9'].touched && texas.controls['9'].invalid) || (texas.controls['9'].invalid && isSubmit)" formControlName="9" type="text" data-id="lname">
                    <div class="formLabel col-md-11"
                        *ngIf="texas.controls['9'].touched && texas.controls['9'].invalid">
                        <small class="text-danger" *ngIf="texas.controls['9'].errors?.required">
                            required
                        </small>
                    </div>
                </div>
                <div class="form-group no-margin col-md-3 sideLabelAlignment">
                    <label class="normalParagraph">FIRST</label>
                    <input class="form-control onboard " id="8" name="8" [class.is-invalid]="(texas.controls['8'].touched && texas.controls['8'].invalid) || (texas.controls['8'].invalid && isSubmit)" formControlName="8" type="text" data-id="fname">
                    <div class="formLabel col-md-11"
                        *ngIf="texas.controls['8'].touched && texas.controls['8'].invalid">
                        <small class="text-danger" *ngIf="texas.controls['8'].errors?.required">
                            required
                        </small>
                    </div>
                </div>
                <div class="form-group no-margin col-md-3 sideLabelAlignment">
                    <label class="normalParagraph">MIDDLE</label>
                    <input class="form-control onboard " id="809" name="809" formControlName="809" type="text" data-id="mname">
                </div>
                <div class="form-group no-margin col-md-12 sideLabelAlignment">
                    (JR., SR., ETC.)
                    <div class="form-group selectDrop suffix dropDrownSet col-md-3">
                        <div class="col-md-12">
                            <ng-select [items]="suffix" bindLabel="name" placeholder="Select Suffix" bindValue="id" formControlName="29">
                            </ng-select>
                        </div>
                    </div>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="9">
                <div class="form-group form-inline no-margin col-md-6 sideLabelAlignment">
                    <label class="normalParagraph">MAIDEN NAME  </label>
                    <input class="form-control onboard " id="278" name="278" formControlName="278" type="text" data-id="priorname">
                </div>
                <div class="form-group form-inline no-margin col-md-6 sideLabelAlignment">
                    <label class="normalParagraph">YEARS ASSOCIATED (YYYY-YYYY) </label>
                    <input class="form-control onboard datepicker" placeholder="MM/DD/YYYY" (blur)="clearInputIfInvalid('280')"
                    ngbDatepicker #d1="ngbDatepicker" id="280" name="280" formControlName="280" type="text" data-id="priornameeffdate_date">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d1.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                    </div>
                    <div  *ngIf="texas.get('280').invalid ">
                        <small class="text-danger" *ngIf="texas.get('280').errors?.invalidDateFormat">
                          Only Date Format is allowed(MM/DD/YYYY)
                        </small>
                        <small class="text-danger" *ngIf="texas.get('280').errors?.invalidMonth">
                        Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="texas.get('280').errors?.invalidDateyear">
                          year is out of range(1900-2099)                      </small>
                          <small class="text-danger" *ngIf="texas.get('280').errors?.invalidDateRange">
                            date is out of range
                            </small>
                        </div>
                    <input class="form-control onboard datepicker" (blur)="clearInputIfInvalid('281')"  placeholder="MM/DD/YYYY" ngbDatepicker #d2="ngbDatepicker" id="281" name="281" formControlName="281" type="text" data-id="priornametermdate_date">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d2.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                    </div>
                    <div  *ngIf="texas.get('281').invalid ">
                        <small class="text-danger" *ngIf="texas.get('281').errors?.invalidDateFormat">
                          Only Date Format is allowed(MM/DD/YYYY)
                        </small>
                        <small class="text-danger" *ngIf="texas.get('281').errors?.invalidMonth">
                        Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="texas.get('281').errors?.invalidDateyear">
                          year is out of range(1900-2099)                      </small>
                          <small class="text-danger" *ngIf="texas.get('281').errors?.invalidDateRange">
                            date is out of range
                            </small>
                        </div>
    
                </div>
            </td>
            <td colspan="9">
                <div class="form-group form-inline no-margin col-md-6 sideLabelAlignment">
                    <label class="normalParagraph">OTHER NAME </label>
                    <input class="form-control onboard " id="2337" name="2337" formControlName="2337" type="text" data-id="othername">
                </div>
                <div class="form-group form-inline no-margin col-md-6 sideLabelAlignment">
                    <label class="normalParagraph">YEARS ASSOCIATED (YYYY-YYYY) </label>
                    <input class="form-control onboard datepicker" (blur)="clearInputIfInvalid('cmd_01')" placeholder="MM/DD/YYYY" ngbDatepicker #d3="ngbDatepicker" id="cmd_01" name="cmd_01" formControlName="cmd_01" type="text" data-id="othernameeffdate_date">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d3.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                    </div>
                    <div  *ngIf="texas.get('cmd_01').invalid ">
                        <small class="text-danger" *ngIf="texas.get('cmd_01').errors?.invalidDateFormat">
                          Only Date Format is allowed(MM/DD/YYYY)
                        </small>
                        <small class="text-danger" *ngIf="texas.get('cmd_01').errors?.invalidMonth">
                        Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="texas.get('cmd_01').errors?.invalidDateyear">
                          year is out of range(1900-2099)                      </small>
                          <small class="text-danger" *ngIf="texas.get('cmd_01').errors?.invalidDateRange">
                            date is out of range
                            </small>
                        </div>
                    <input class="form-control onboard datepicker" (blur)="clearInputIfInvalid('cmd_02')" placeholder="MM/DD/YYYY" ngbDatepicker #d4="ngbDatepicker" id="cmd_02" name="cmd_02" formControlName="cmd_02" type="text" data-id="othernametermdate_date">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d4.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                    </div>
                    <div  *ngIf="texas.get('cmd_02').invalid ">
                        <small class="text-danger" *ngIf="texas.get('cmd_02').errors?.invalidDateFormat">
                          Only Date Format is allowed(MM/DD/YYYY)
                        </small>
                        <small class="text-danger" *ngIf="texas.get('cmd_02').errors?.invalidMonth">
                        Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="texas.get('cmd_02').errors?.invalidDateyear">
                          year is out of range(1900-2099)                      </small>
                          <small class="text-danger" *ngIf="texas.get('cmd_02').errors?.invalidDateRange">
                            date is out of range
                            </small>
                        </div>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="normalParagraph">HOME MAILING ADDRESS</label>
                    <input class="form-control onboard " id="16" name="16" [class.is-invalid]="(texas.controls['16'].touched && texas.controls['16'].invalid) || (texas.controls['16'].invalid && isSubmit)" formControlName="16" type="text" data-id="homeaddress1">
                    <div class="formLabel col-md-11"
                        *ngIf="texas.controls['16'].touched && texas.controls['16'].invalid">
                        <small class="text-danger" *ngIf="texas.controls['16'].errors?.required">
                            required
                        </small>
                    </div>
                    <input class="form-control onboard " id="17" name="17" formControlName="17" [class.is-invalid]="(texas.controls['17'].touched && texas.controls['17'].invalid) || (texas.controls['17'].invalid && isSubmit)" type="text" data-id="homeaddress2">
                    <div class="formLabel col-md-11"
                        *ngIf="texas.controls['17'].touched && texas.controls['17'].invalid">
                        <small class="text-danger" *ngIf="texas.controls['17'].errors?.required">
                            required
                        </small>
                    </div>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18">
                <div class="form-group form-inline no-margin col-md-3 sideLabelAlignment">
                    <label class="normalParagraph">CITY</label>
                    <input class="form-control onboard " id="18" name="18" [class.is-invalid]="(texas.controls['18'].touched && texas.controls['18'].invalid) || (texas.controls['18'].invalid && isSubmit)" formControlName="18" type="text" data-id="homecity">
                    <div class="formLabel col-md-11"
                        *ngIf="texas.controls['18'].touched && texas.controls['18'].invalid">
                        <small class="text-danger" *ngIf="texas.controls['18'].errors?.required">
                            required
                        </small>
                    </div>
                </div>
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">STATE/COUNTRY</label>
                    <div class="form-group selectDrop col-md-12 allMargin">
                        <div class="col-md-12">
                            <ng-select [items]="states" bindLabel="name" [class.is-invalid]="(texas.controls['20'].touched && texas.controls['20'].invalid) || (texas.controls['20'].invalid && isSubmit)" placeholder="Select State" bindValue="id" formControlName="20">
                            </ng-select>
                        </div>
                        <div class="formLabel col-md-11"
                            *ngIf="texas.controls['20'].touched && texas.controls['20'].invalid">
                            <small class="text-danger" *ngIf="texas.controls['20'].errors?.required">
                                required
                            </small>
                        </div>
                    </div>
                </div>
                <div class="form-group form-inline no-margin col-md-3 sideLabelAlignment">
                    <label class="normalParagraph">POSTAL CODE</label>
                    <input class="form-control onboard " id="21" name="21" [class.is-invalid]="(texas.controls['21'].touched && texas.controls['21'].invalid) || (texas.controls['21'].invalid && isSubmit)" formControlName="21" type="text" data-id="homezip">
                    <div class="formLabel col-md-11"
                        *ngIf="texas.controls['21'].touched && texas.controls['21'].invalid">
                        <small class="text-danger" *ngIf="texas.controls['21'].errors?.required">
                            required
                        </small>
                        <small class="text-danger" *ngIf="texas.controls['21'].errors?.minlength || texas.controls['21'].errors?.maxlength">
                          Zip code must be atleast 5 digits and less than 10 digits
                      </small>
                    </div>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="6">
                <div class="form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">HOME PHONE NUMBER </label>
                    <input class="form-control onboard " id="708" name="708" [class.is-invalid]="(texas.controls['708'].touched && texas.controls['708'].invalid) || (texas.controls['708'].invalid && isSubmit)" formControlName="708" type="number" data-id="homephone">
                    <div class="formLabel col-md-11"
                        *ngIf="texas.controls['708'].touched && texas.controls['708'].invalid">
                        <small class="text-danger" *ngIf="texas.controls['708'].errors?.required">
                            required
                        </small>
                    </div>
                </div>
            </td>
            <td colspan="6">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">SOCIAL SECURITY NUMBER </label>
                    <input class="form-control onboard " id="32" name="32" [class.is-invalid]="(texas.controls['32'].touched && texas.controls['32'].invalid) || (texas.controls['32'].invalid && isSubmit)" formControlName="32" type="text" data-id="ssn">
                    <div class="formLabel col-md-11"
                        *ngIf="texas.controls['32'].touched && texas.controls['32'].invalid">
                        <small class="text-danger" *ngIf="texas.controls['32'].errors?.required">
                            required
                        </small>
                    </div>
                </div>
            </td>
            <td colspan="6">
                <div class="form-group no-margin col-md-12 sideLabelAlignment">
                    <label class="radio-inline radioStyle">
                    <input type="radio" id="cmd_03" name="cmd_03" formControlName="cmd_03" value="1">Male</label>
                    <label class="radio-inline radioStyle">
                    <input type="radio" id="cmd_03" name="cmd_03" formControlName="cmd_03" value="0">Female</label>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="normalParagraph">CORRESPONDENCE ADDRESS </label>
                    <input class="form-control onboard " id="837" name="837" formControlName="837" type="text" data-id="prac1_MailingAddress1">
                    <input class="form-control onboard " id="838" name="838" formControlName="838" type="text" data-id="prac1_MailingAddress2">
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18">
                <div class="form-group form-inline no-margin col-md-3 sideLabelAlignment">
                    <label class="normalParagraph">CITY</label>
                    <input class="form-control onboard " id="839" name="839" formControlName="839" type="text" data-id="prac1_MailingCity">
                </div>
                <div class="form-group selectDrop col-md-3 allMargin">
                    <ng-select [items]="states" bindLabel="name" placeholder="Select State" bindValue="id" formControlName="841">
                    </ng-select>
                </div>
                <div class="form-group form-inline no-margin col-md-3 sideLabelAlignment">
                    <label class="normalParagraph">POSTAL CODE</label>
                    <input class="form-control onboard " id="842" name="842" formControlName="842" type="text" data-id="prac1_MailingZIP">
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="6">
                <div class="form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">PHONE NUMBER</label>
                    <input class="form-control onboard " id="870" name="870" formControlName="870" type="text" data-id="prac1_mailingphone">
                </div>
            </td>
            <td colspan="6">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">FAX NUMBER </label>
                    <input class="form-control onboard " id="871" name="871" formControlName="871" type="text" data-id="prac1_mailingfax">
                </div>
            </td>
            <td colspan="6">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">E-MAIL </label>
                    <input class="form-control onboard " id="759" name="759" [class.is-invalid]="(texas.controls['759'].touched && texas.controls['759'].invalid) || (texas.controls['759'].invalid && isSubmit)" formControlName="759" type="text" data-id="hemail">
                    <div class="formLabel col-md-11"
                        *ngIf="texas.controls['759'].touched && texas.controls['759'].invalid">
                        <small class="text-danger" *ngIf="texas.controls['759'].errors?.required">
                          The email address is required
                        </small>
                        <small class="text-danger" *ngIf="texas.controls['759'].errors?.pattern">
                          The email address is not valid
                      </small>
                    </div>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="6">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">DATE OF BIRTH (MM/DD/YYYY) </label>
                    <input class="form-control onboard datepicker" (blur)="clearInputIfInvalidMandi('31')" ngbDatepicker #d5="ngbDatepicker" [class.is-invalid]="(texas.controls['31'].touched && texas.controls['31'].invalid) || (texas.controls['31'].invalid && isSubmit)" placeholder="MM/DD/YYYY" id="31" name="31" formControlName="31" type="text" data-id="dob_date">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d5.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                    </div>
                    <div class="formLabel col-md-11"
                        *ngIf="texas.controls['31'].touched && texas.controls['31'].invalid">
                        <small class="text-danger" *ngIf="texas.controls['31'].errors?.required">
                            required
                        </small>
                    </div>
                    <small class="text-danger" *ngIf="texas.get('31').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)                </small>
                      <small class="text-danger" *ngIf="texas.get('31').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="texas.get('31').errors?.invalidDateyear">
                        year is out of range(1900-2099)                  </small>
                        <small class="text-danger" *ngIf="texas.get('31').errors?.invalidDateRange">
                          date is out of range
                          </small>
                </div>
            </td>
            <td colspan="6">
                <div class="form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">PLACE OF BIRTH </label>
                </div>
                <div class="form-group no-margin col-md-6 sideLabelAlignment">
                    <input class="form-control onboard " [class.is-invalid]="(texas.controls['810'].touched && texas.controls['810'].invalid) || (texas.controls['810'].invalid && isSubmit)" id="810" name="810" formControlName="810" type="text" data-id="bcity">
                    <div class="formLabel col-md-11"
                        *ngIf="texas.controls['810'].touched && texas.controls['810'].invalid">
                        <small class="text-danger" *ngIf="texas.controls['810'].errors?.required">
                            required
                        </small>
                    </div>
                </div>
                <div class="form-group no-margin col-md-12 sideLabelAlignment">
                    <div class="form-group selectDrop suffix dropDrownSet col-md-12">
                        <div class="col-md-12">
                            <ng-select [items]="states" bindLabel="name" [class.is-invalid]="(texas.controls['289'].touched && texas.controls['289'].invalid) || (texas.controls['289'].invalid && isSubmit)" placeholder="Select State" bindValue="id" formControlName="289">
                            </ng-select>
                        </div>
                        <div class="formLabel col-md-11"
                            *ngIf="texas.controls['289'].touched && texas.controls['289'].invalid">
                            <small class="text-danger" *ngIf="texas.controls['289'].errors?.required">
                                required
                            </small>
                        </div>
                    </div>
                </div>
            </td>
            <td colspan="6">
                <div class="form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">CITIZENSHIP </label>
                    <input class="form-control onboard " id="292" name="292" formControlName="292" type="text" data-id="citizenship">
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="9">
                <div class="form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">IF NOT AMERICAN CITIZEN, VISA NUMBER & STATUS </label>
                    <input class="form-control onboard " id="1584" name="1584" formControlName="1584" type="text" data-id="Visa #">
                    <input class="form-control onboard " id="1282" name="1282" formControlName="1282" type="text" data-id="Visa Status">
                </div>
            </td>
            <td colspan="9">
                <div class="form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">ARE YOU ELIGIBLE TO WORK IN THE UNITED STATES? </label>
                    <label class="checkbox-inline radioStyle">
                    <input type="radio" id="cmd_04" name="cmd_04" formControlName="cmd_04" value="1">Yes</label>
                    <label class="checkbox-inline radioStyle">
                    <input type="radio" id="cmd_04" name="cmd_04" formControlName="cmd_04" value="0">No</label>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="6">
                <div class="form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">U.S.MILITARY SERVICE/PUBLIC HEALTH  </label>
                    <label class="checkbox-inline radioStyle">
                    <input type="radio" id="cmd_05" name="cmd_05" formControlName="cmd_05" (change)="onChangeCmdTwo($event)" value="1">Yes</label>
                    <label class="checkbox-inline radioStyle">
                    <input type="radio" id="cmd_05" name="cmd_05" formControlName="cmd_05" (change)="onChangeCmdTwo($event)" value="0">No</label>
                </div>
            </td>
            <td colspan="8">
                <div *ngIf="usmilchange">
                    <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                        <label class="normalParagraph">DATES OF SERVICE (MM/DD/YYYY) TO
                        (MM/DD/YYYY)
                        </label>
                    </div>
                    <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                        <input class="form-control onboard datepicker" style="width:40%;" placeholder="MM/DD/YYYY" ngbDatepicker #d6="ngbDatepicker" [class.is-invalid]="texas.controls['1144'].value==null" id="1144" name="1144" formControlName="1144" type="text" data-id="Mil Active from">
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="d6.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                        </div>
                        <div class="formLabel col-md-11"
                            *ngIf="texas.controls['1144'].touched && texas.controls['1144'].invalid">
                            <small class="text-danger" *ngIf="texas.controls['1144'].errors?.required">
                                required
                            </small>
                        </div>
                    </div>
                    <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                        <input class="form-control onboard datepicker" style="width:40%;" ngbDatepicker #d7="ngbDatepicker" [class.is-invalid]="texas.controls['1145'].value==null" placeholder="MM/DD/YYYY" id="1145" name="1145" formControlName="1145" type="text" data-id="Mil Active to">
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="d7.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                        </div>
                        <div class="formLabel col-md-11"
                            *ngIf="texas.controls['1145'].touched && texas.controls['1145'].invalid">
                            <small class="text-danger" *ngIf="texas.controls['1145'].errors?.required">
                                required
                            </small>
                        </div>
                    </div>
                </div>
            </td>
            <td colspan="6">
                <div class="form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">LAST LOCATION </label>
                    <input class="form-control onboard" [class.is-invalid]="(texas.controls['1139'].touched && texas.controls['1139'].invalid) || (texas.controls['1139'].invalid && isSubmit)" id="1139" name="1139" formControlName="1139" type="text" data-id="Military Locaiton">
                    <div class="formLabel col-md-11"
                        *ngIf="texas.controls['1139'].touched && texas.controls['1139'].invalid">
                        <small class="text-danger" *ngIf="texas.controls['1139'].errors?.required">
                            required
                        </small>
                    </div>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="9">
                <div class="form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">BRANCH OF SERVICE  </label>
                    <input class="form-control onboard " id="1141" name="1141" [class.is-invalid]="(texas.controls['1141'].touched && texas.controls['1141'].invalid) || (texas.controls['1141'].invalid && isSubmit)" formControlName="1141" type="text" data-id="Mil branch">
                    <div class="formLabel col-md-11"
                        *ngIf="texas.controls['1141'].touched && texas.controls['1141'].invalid">
                        <small class="text-danger" *ngIf="texas.controls['1141'].errors?.required">
                            required
                        </small>
                    </div>
                </div>
            </td>
            <td colspan="9">
                <div class="form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">ARE YOU CURRENTLY ON ACTIVE OR RESERVE MILITARY DUTY?  </label>
                    <label class="checkbox-inline radioStyle">
                    <input type="radio" id="cmd_06" name="cmd_06" formControlName="cmd_06" value="1">Yes</label>
                    <label class="checkbox-inline radioStyle">
                    <input type="radio" id="cmd_06" name="cmd_06" formControlName="cmd_06" value="0">No</label>
                </div>
            </td>
        </tr>
    </table>
    <table border="1" width="100%">
        <tr>
            <td colspan="14">
                <div class="centered texasFirstTable">
                    <h3 class="texasHThree">Education </h3>
                </div>
            </td>
            <td colspan="4">
                <label class="texasFloat">Does Not Apply</label>
                <input type="checkbox" name="cmd_0722" id="cmd_0722" formControlName="cmd_0722" (change)="education($event)"  value="1" class="texasFloat">
            </td>
        </tr>
        <tr>
            <td colspan="18">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">PROFESSIONAL DEGREE (MEDICAL, DENTAL, CHIROPRACTIC, ETC.) </label>
                </div>
                <div class="form-group form-inline no-margin col-md-6 sideLabelAlignment">
                    <label class="normalParagraph">Issuing Institution: </label>
                </div>
                <div class="form-group no-margin col-md-6 sideLabelAlignment">
                    <input class="form-control onboard " id="490" name="490" [class.is-invalid]="(texas.controls['490'].touched && texas.controls['490'].errors) || (texas.controls['490'].errors && isSubmit)" formControlName="490" type="text" data-id="meds">
                    <div class="formLabel col-md-11"
                        *ngIf="texas.controls['490'].touched && texas.controls['490'].errors">
                        <small class="text-danger" *ngIf="texas.controls['490'].errors">
                            required
                        </small>
                    </div>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="normalParagraph">ADDRESS  </label>
                    <input class="form-control onboard " id="491" name="491" [class.is-invalid]="(texas.controls['491'].touched && texas.controls['491'].errors) || (texas.controls['491'].errors && isSubmit)" formControlName="491" type="text" data-id="medsadd1">
                    <div class="formLabel col-md-11"
                        *ngIf="texas.controls['491'].touched && texas.controls['491'].errors">
                        <small class="text-danger" *ngIf="texas.controls['491'].errors">
                            required
                        </small>
                    </div>
                    <input class="form-control onboard " id="492" name="492" [class.is-invalid]="(texas.controls['492'].touched && texas.controls['492'].errors) || (texas.controls['492'].errors && isSubmit)" formControlName="492" type="text" data-id="medsadd2">
                    <div class="formLabel col-md-11"
                        *ngIf="texas.controls['492'].touched && texas.controls['492'].errors">
                        <small class="text-danger" *ngIf="texas.controls['492'].errors">
                            required
                        </small>
                    </div>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18">
                <div class="form-group form-inline no-margin col-md-3 sideLabelAlignment">
                    <label class="formLabel">CITY</label>
                    <input class="form-control" [class.is-invalid]="(texas.controls['493'].touched && texas.controls['493'].errors) || (texas.controls['493'].errors && isSubmit)" id="493" name="493" formControlName="493" type="text" data-id="medsci">
                    <div class="formLabel col-md-2"
                        *ngIf="texas.controls['493'].touched && texas.controls['493'].errors">
                        <small class="text-danger" *ngIf="texas.controls['493'].errors">
                            required
                        </small>
                    </div>
                </div>
                <div class="form-group form-inline no-margin col-md-3 sideLabelAlignment">
                    <label class="normalParagraph">STATE/COUNTRY</label>
                    <div class="form-group selectDrop suffix dropDrownSet col-md-3">
                        <ng-select [items]="suffix" bindLabel="name" [class.is-invalid]="(texas.controls['495'].touched && texas.controls['495'].errors) || (texas.controls['495'].errors && isSubmit)" placeholder="Select State" bindValue="id" formControlName="495">
                        </ng-select>
                        <div class="formLabel col-md-11"
                            *ngIf="texas.controls['495'].touched && texas.controls['495'].errors">
                            <small class="text-danger" *ngIf="texas.controls['495'].errors">
                                required
                            </small>
                        </div>
                    </div>
                </div>
                <div class="form-group form-inline no-margin col-md-3 sideLabelAlignment">
                    <label class="formLabel">POSTAL CODE</label>
                    <input  class="form-control" [class.is-invalid]="(texas.controls['496'].touched && texas.controls['496'].errors) || (texas.controls['496'].errors && isSubmit)" id="496" name="496" formControlName="496" type="text" data-id="medsz">
                    <div class="formLabel col-md-2"
                        *ngIf="texas.controls['496'].touched && texas.controls['496'].errors">
                        <small class="text-danger" *ngIf="texas.controls['496'].errors">
                            required
                        </small>
                        <small class="text-danger" *ngIf="texas.controls['496'].errors?.minlength || texas.controls['496'].errors?.maxlength">
                          Zip code must be atleast 5 digits and less than 10 digits
                      </small>
                    </div>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="4">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">DEGREE </label>
                    <div class="form-group selectDropTwo degree inputPadding col-md-4">
                        <ng-select [items]="degree" bindLabel="name" [class.is-invalid]="(texas.controls['500'].touched && texas.controls['500'].invalid) || (texas.controls['500'].invalid && isSubmit)" placeholder="Select Degree" bindValue="id" formControlName="500">
                        </ng-select>
                        <div class="formLabel col-md-11"
                            *ngIf="texas.controls['500'].touched && texas.controls['500'].invalid">
                            <small class="text-danger" *ngIf="texas.controls['500'].errors?.required">
                                required
                            </small>
                        </div>
                    </div>
                </div>
            </td>
            <td colspan="14">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">ATTENDANCE DATES(MM/DD/YYYY TO MM/DD/YYYY)</label>
                    <br/>
                    <input class="form-control onboard datepicker" (blur)="clearInputIfInvalidMandi('497')" ngbDatepicker #d8="ngbDatepicker" [class.is-invalid]="(texas.controls['497'].touched && texas.controls['497'].errors) || (texas.controls['497'].errors && isSubmit)" placeholder="MM/DD/YYYY" id="497" name="497" formControlName="497" type="text" data-id="medsstart_date">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d8.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                    </div>
                    <div class="formLabel col-md-11"
                        *ngIf="texas.controls['497'].touched && texas.controls['497'].errors">
                        <small class="text-danger" *ngIf="texas.controls['497'].errors">
                            required
                        </small>
                    </div>
                    <small class="text-danger" *ngIf="texas.get('497').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)                </small>
                      <small class="text-danger" *ngIf="texas.get('497').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="texas.get('497').errors?.invalidDateyear">
                        year is out of range(1900-2099)                  </small>
                        <small class="text-danger" *ngIf="texas.get('497').errors?.invalidDateRange">
                          date is out of range
                          </small>
                    <input class="form-control onboard datepicker" (blur)="clearInputIfInvalidMandi('498')" ngbDatepicker #d9="ngbDatepicker" [class.is-invalid]="(texas.controls['498'].touched && texas.controls['498'].errors) || (texas.controls['498'].errors && isSubmit)" placeholder="MM/DD/YYYY" id="498" name="498" formControlName="498" type="text" data-id="medsend_date">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d9.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                    </div>
                    <div class="formLabel col-md-11"
                        *ngIf="texas.controls['498'].touched && texas.controls['498'].errors">
                        <small class="text-danger" *ngIf="texas.controls['498'].errors">
                            required
                        </small>
                    </div>
                    <small class="text-danger" *ngIf="texas.get('498').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)                </small>
                      <small class="text-danger" *ngIf="texas.get('498').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="texas.get('498').errors?.invalidDateyear">
                        year is out of range(1900-2099)                  </small>
                        <small class="text-danger" *ngIf="texas.get('498').errors?.invalidDateRange">
                          date is out of range
                          </small>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="checkbox-inline radioStyle">
                    <input type="checkbox" id="cmd_07" name="cmd_07" formControlName="cmd_07" value="1">Please check this box and complete and submit Attachment A if you received other professional degrees.</label>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="6">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="checkbox-inline radioStyle">POST-GRADUATE EDUCATION </label>
                </div>
                <input type="radio" id="cmd_08" name="cmd_08" formControlName="cmd_08" value="0">Internship
                <input type="radio" id="cmd_08" name="cmd_08" formControlName="cmd_08" value="1">Residency
                <input type="radio" id="cmd_08" name="cmd_08" formControlName="cmd_08" value="2">Fellowship
                <input type="radio" id="cmd_08" name="cmd_08" formControlName="cmd_08" value="3">Teaching Appointment
            </td>
            <td colspan="12">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="checkbox-inline radioStyle">SPECIALTY</label>
                </div>
                <!-- <input class="form-control onboard " id="527" name="527" formControlName="527" type="text" data-id="internspec"> -->
                <div class="form-group selectDrop dropDrownSet degree col-md-6">
                    <ng-select [items]="specialty" bindLabel="name" placeholder="Select specialty" bindValue="id" formControlName="527">
                    </ng-select>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">INSTITUTION</label>
                    <input class="form-control onboard " id="517" name="517" formControlName="517"  type="text" data-id="interns">
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">ADDRESS</label>
                    <input class="form-control onboard " id="518" name="518" formControlName="518" placeholder="Address" type="text" data-id="internadd1">
                    <input class="form-control onboard " id="519" name="519" formControlName="519" placeholder="Address" type="text" data-id="internadd2">
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18">
                <div class="form-group form-inline no-margin col-md-3 sideLabelAlignment">
                    <label class="normalParagraph">CITY</label>
                    <input style="width: 40%" class="form-control onboard " id="520" name="520" formControlName="520" type="text" data-id="internci">
                </div>
                <div class="form-group form-inline no-margin col-md-3 sideLabelAlignment">
                    <label class="normalParagraph">STATE/COUNTRY</label>
                    <div class="form-group selectDrop dropDrownSet degree col-md-6">
                        <ng-select [items]="states" bindLabel="name" placeholder="Select States" bindValue="id" formControlName="522">
                        </ng-select>
                    </div>
                </div>
                <div class="form-group form-inline no-margin col-md-3 sideLabelAlignment">
                    <label class="normalParagraph">POSTAL CODE</label>
                    <input style="width: 40%" class="form-control onboard"  id="523" name="523" formControlName="523" type="text" data-id="internz">

                </div>
            </td>
        </tr>
        <tr>
            <td colspan="9">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                </div>
                <input type="checkbox" id="cmd_09" name="cmd_09" formControlName="cmd_09" value="1">Program successfully completed
            </td>
            <td colspan="9">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">ATTENDANCE DATES (MM/DD/YYYY TO MM/DD/YYYY) </label>
                    <input class="form-control onboard datepicker" (blur)="clearInputIfInvalid('524')" ngbDatepicker #d10="ngbDatepicker" placeholder="MM/DD/YYYY" id="524" name="524" formControlName="524" type="text" data-id="internstart_date">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d10.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                    </div>
                    <div  *ngIf="texas.get('524').invalid ">
                        <small class="text-danger" *ngIf="texas.get('524').errors?.invalidDateFormat">
                          Only Date Format is allowed(MM/DD/YYYY)
                        </small>
                        <small class="text-danger" *ngIf="texas.get('524').errors?.invalidMonth">
                        Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="texas.get('524').errors?.invalidDateyear">
                          year is out of range(1900-2099)                      </small>
                          <small class="text-danger" *ngIf="texas.get('524').errors?.invalidDateRange">
                            date is out of range
                            </small>
                        </div>
                    <input class="form-control onboard datepicker" (blur)="clearInputIfInvalid('525')" ngbDatepicker #d11="ngbDatepicker" placeholder="MM/DD/YYYY" id="525" name="525" formControlName="525" type="text" data-id="internend_date">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d11.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                    </div>
                    <div  *ngIf="texas.get('525').invalid ">
                        <small class="text-danger" *ngIf="texas.get('525').errors?.invalidDateFormat">
                          Only Date Format is allowed(MM/DD/YYYY)
                        </small>
                        <small class="text-danger" *ngIf="texas.get('525').errors?.invalidMonth">
                        Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="texas.get('525').errors?.invalidDateyear">
                          year is out of range(1900-2099)                      </small>
                          <small class="text-danger" *ngIf="texas.get('525').errors?.invalidDateRange">
                            date is out of range
                            </small>
                        </div>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="9">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">PROGRAM DIRECTOR </label>
                    <input style="width:40%;" class="form-control onboard " id="528" name="528" formControlName="528" type="text" data-id="interndir">
                </div>
            </td>
            <td colspan="9">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">CURRENT PROGRAM DIRECTOR (IF KNOWN) </label>
                    <input style="width:40%;" class="form-control onboard " id="528" name="528" formControlName="528" type="text" data-id="interndir2">
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="6">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="checkbox-inline radioStyle">POST-GRADUATE EDUCATION </label>
                </div>
                <input type="radio" id="cmd_010" name="cmd_010" formControlName="cmd_010" value="0">Internship
                <input type="radio" id="cmd_010" name="cmd_010" formControlName="cmd_010" value="1">Residency
                <input type="radio" id="cmd_010" name="cmd_010" formControlName="cmd_010" value="2">Fellowship
                <input type="radio" id="cmd_010" name="cmd_010" formControlName="cmd_010" value="3">Teaching Appointment
            </td>
            <td colspan="12">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="checkbox-inline radioStyle">SPECIALTY</label>
                </div>
                <!-- <input class="form-control onboard " id="530" name="530" formControlName="530" type="text" data-id="resspec"> -->
                <div class="form-group selectDropTwo inputPadding suffix">
                    <ng-select
                      [items]="specialty"
                      bindLabel="name"

                      placeholder="Select Specialty"
                      bindValue="id"
                      formControlName="530"
                    >
                    </ng-select>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="checkbox-inline radioStyle">INSTITUTION</label>
                    <input class="form-control onboard " id="529" name="529" formControlName="529" type="text" data-id="res">
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="checkbox-inline radioStyle">ADDRESS</label>
                    <input class="form-control onboard " id="531" name="531" formControlName="531" type="text" data-id="resadd1">
                    <input class="form-control onboard " id="1498" name="1498" formControlName="1498" type="text" data-id="resadd2">
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18">
                <div class="form-group form-inline no-margin col-md-3 sideLabelAlignment">
                    <label class="normalParagraph">CITY</label>
                    <input style="width: 40%" class="form-control onboard " id="533" name="533" formControlName="533" type="text" data-id="resci">
                </div>
                <div class="form-group form-inline no-margin col-md-3 sideLabelAlignment">
                    <label class="normalParagraph">STATE/COUNTRY</label>
                    <div class="form-group selectDrop dropDrownSet degree col-md-6">
                        <ng-select [items]="states" bindLabel="name" placeholder="Select States" bindValue="id" formControlName="535">
                        </ng-select>
                    </div>
                </div>
                <div class="form-group form-inline no-margin col-md-3 sideLabelAlignment">
                    <label class="normalParagraph">POSTAL CODE</label>
                    <input style="width: 40%" class="form-control onboard " id="536" name="536" formControlName="536" type="text" data-id="resz">
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="6">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="checkbox-inline radioStyle">POST-GRADUATE EDUCATION </label>
                </div>
                <input type="checkbox" id="cmd_011" name="cmd_011" formControlName="cmd_011" value="1">Program successfully completed
            </td>
            <td colspan="12">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="checkbox-inline radioStyle">ATTENDANCE DATES (MM/DD/YYYY TO MM/DD/YYYY) </label>
                </div>
                <div class="input-group">
                    <input style="width:25%;" class="form-control onboard datepicker" placeholder="MM/DD/YYYY" (blur)="clearInputIfInvalid('537')" ngbDatepicker #d12="ngbDatepicker" id="537" name="537" formControlName="537" type="text" data-id="resstart_date">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d12.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                    </div>
                    <div  *ngIf="texas.get('537').invalid ">
                        <small class="text-danger" *ngIf="texas.get('537').errors?.invalidDateFormat">
                          Only Date Format is allowed(MM/DD/YYYY)
                        </small>
                        <small class="text-danger" *ngIf="texas.get('537').errors?.invalidMonth">
                        Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="texas.get('537').errors?.invalidDateyear">
                          year is out of range(1900-2099)                      </small>
                          <small class="text-danger" *ngIf="texas.get('537').errors?.invalidDateRange">
                            date is out of range
                            </small>
                        </div>
                </div>
                <div class="input-group">
                    <input style="width:25%;" class="form-control onboard datepicker"  (blur)="clearInputIfInvalid('538')" placeholder="MM/DD/YYYY" ngbDatepicker #d13="ngbDatepicker" id="538" name="538" formControlName="538" type="text" data-id="resend_date">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d13.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                    </div>
                    <div  *ngIf="texas.get('538').invalid ">
                        <small class="text-danger" *ngIf="texas.get('538').errors?.invalidDateFormat">
                          Only Date Format is allowed(MM/DD/YYYY)
                        </small>
                        <small class="text-danger" *ngIf="texas.get('538').errors?.invalidMonth">
                        Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="texas.get('538').errors?.invalidDateyear">
                          year is out of range(1900-2099)                      </small>
                          <small class="text-danger" *ngIf="texas.get('538').errors?.invalidDateRange">
                            date is out of range
                            </small>
                        </div>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="9">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="normalParagraph">PROGRAM DIRECTOR </label>
                    <input style="width:40%;" class="form-control onboard " id="540" name="540" formControlName="540" type="text" data-id="resdir1">
                </div>
            </td>
            <td colspan="9">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="normalParagraph">CURRENT PROGRAM DIRECTOR (IF KNOWN)</label>
                    <input style="width:40%;" class="form-control onboard " id="540" name="540" formControlName="540" type="text" data-id="resdir2">
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="checkbox-inline radioStyle">
                    <input type="checkbox" id="cmd_012" name="cmd_012" formControlName="cmd_012" value="1">Please check this box and complete and submit Attachment B if you received additional postgraduate training.</label>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">OTHER GRADUATE-LEVEL EDUCATION</label>
                </div>
                <div class="form-group form-inline no-margin col-md-6 sideLabelAlignment">
                    <label class="normalParagraph">Issuing Institution: </label>
                </div>
                <div class="form-group no-margin col-md-6 sideLabelAlignment">
                    <input class="form-control onboard " id="1288" name="1288" formControlName="1288" type="text" data-id="Training Pro school1">
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="checkbox-inline radioStyle">ADDRESS</label>
                    <input class="form-control onboard " id="1289" name="1289" formControlName="1289" type="text" data-id="Training Pro 1 address1">
                    <input class="form-control onboard " id="1290" name="1290" formControlName="1290" type="text" data-id="Training Pro 1 address2">
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18">
                <div class="form-group form-inline no-margin col-md-3 sideLabelAlignment">
                    <label class="normalParagraph">CITY</label>
                    <input style="width: 40%" class="form-control onboard " id="1291" name="1291" formControlName="1291" type="text" data-id="Training Pro 1 city">
                </div>
                <div class="form-group form-inline no-margin col-md-4 sideLabelAlignment">
                    <label class="normalParagraph">STATE/COUNTRY</label>
                    <div class="form-group selectDrop dropDrownSet degree col-md-6">
                        <ng-select [items]="states" bindLabel="name" placeholder="Select States" bindValue="id" formControlName="1293">
                        </ng-select>
                    </div>
                </div>
                <div class="form-group form-inline no-margin col-md-3 sideLabelAlignment">
                    <label class="normalParagraph">POSTAL CODE</label>
                    <input style="width: 40%" class="form-control onboard " id="1294" name="1294" formControlName="1294" type="text" data-id="Training Pro 1 zip">
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="9">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="normalParagraph">DEGREE</label>
                    <!-- <input class="form-control onboard " id="1298" name="1298" formControlName="1298" type="text" data-id="Training Pro 1 cert"> -->
                    <div class="form-group selectDropTwo inputPadding suffix">
                        <ng-select
                          [items]="degree"
                          bindLabel="name"
                          placeholder="Select Degree"
                          bindValue="id"
                          formControlName="1298"
                        >
                        </ng-select>
                    </div>
                </div>
            </td>
            <td colspan="9">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="normalParagraph">ATTENDANCE DATES (MM/DD/YYYY TO MM/DD/YYYY) </label>
                    <input style="width: 40%;" class="form-control onboard datepicker"  (blur)="clearInputIfInvalid('1295')" placeholder="MM/DD/YYYY" ngbDatepicker #d14="ngbDatepicker" id="1295" name="1295" formControlName="1295" type="text" data-id="Training Pro 1 from">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d14.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                    </div>
                    <div  *ngIf="texas.get('1295').invalid ">
                        <small class="text-danger" *ngIf="texas.get('1295').errors?.invalidDateFormat">
                          Only Date Format is allowed(MM/DD/YYYY)
                        </small>
                        <small class="text-danger" *ngIf="texas.get('1295').errors?.invalidMonth">
                        Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="texas.get('1295').errors?.invalidDateyear">
                          year is out of range(1900-2099)                      </small>
                          <small class="text-danger" *ngIf="texas.get('1295').errors?.invalidDateRange">
                            date is out of range
                            </small>
                        </div>
                    <input style="width:40%;" class="form-control onboard datepicker" (blur)="clearInputIfInvalid('1297')" placeholder="MM/DD/YYYY" ngbDatepicker #d15="ngbDatepicker" id="1297" name="1297" formControlName="1297" type="text" data-id="Training Pro 1 to">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d15.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                    </div>
                    <div  *ngIf="texas.get('1297').invalid ">
                        <small class="text-danger" *ngIf="texas.get('1297').errors?.invalidDateFormat">
                          Only Date Format is allowed(MM/DD/YYYY)
                        </small>
                        <small class="text-danger" *ngIf="texas.get('1297').errors?.invalidMonth">
                        Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="texas.get('1297').errors?.invalidDateyear">
                          year is out of range(1900-2099)                      </small>
                          <small class="text-danger" *ngIf="texas.get('1297').errors?.invalidDateRange">
                            date is out of range
                            </small>
                        </div>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="checkbox-inline radioStyle">Licenses and Certificates</label>
                    - Please include all license(s) and certifications in all States where you are currently or
                    have previously been licensed.
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="6">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">LICENSE TYPE
                    </label>
                    <input style="width:70%;" class="form-control onboard " id="296" name="296" formControlName="296" type="text" data-id="licensetype">
                </div>
            </td>
            <td colspan="6">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">LICENSE NUMBER
                    </label>
                    <input style="width:70%;" class="form-control onboard" [class.is-invalid]="(texas.controls['293'].touched && texas.controls['293'].invalid) || (texas.controls['293'].invalid && isSubmit)" id="293" name="293" formControlName="293" type="text" data-id="license">
                    <div class="formLabel col-md-11"
                        *ngIf="texas.controls['293'].touched && texas.controls['293'].invalid">
                        <small class="text-danger" *ngIf="texas.controls['293'].errors?.required">
                            required
                        </small>
                    </div>
                </div>
            </td>
            <td colspan="6">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">STATE OF REGISTRATION
                    </label>
                    <!-- <input style="width:70%;" class="form-control onboard " [class.is-invalid]="(texas.controls['299'].touched && texas.controls['299'].invalid) || (texas.controls['299'].invalid && isSubmit)" id="299" name="299" formControlName="299" type="text" data-id="licensestate"> -->
                    <div class="form-group selectDrop col-md-3 allMargin">
                        <ng-select [items]="states" bindLabel="name" [class.is-invalid]="(texas.controls['299'].touched && texas.controls['299'].invalid) || (texas.controls['299'].invalid && isSubmit)" placeholder="Select States" bindValue="id" name="299" formControlName="299">
                        </ng-select>
                    </div>
                    <div class="formLabel col-md-11"
                        *ngIf="texas.controls['299'].touched && texas.controls['299'].invalid">
                        <small class="text-danger" *ngIf="texas.controls['299'].errors?.required">
                            required
                        </small>
                    </div>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="6">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="normalParagraph">ORIGINAL DATE OF ISSUE (MM/DD/YYYY)
                    </label>
                    <input style="width:70%;" class="form-control onboard datepicker"  (blur)="clearInputIfInvalidMandi('294')" ngbDatepicker #d16="ngbDatepicker" [class.is-invalid]="(texas.controls['294'].touched && texas.controls['294'].invalid) || (texas.controls['294'].invalid && isSubmit)" placeholder="MM/DD/YYYY" id="294" name="294" formControlName="294" type="text" data-id="licenseissuedate">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d16.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                    </div>
                    <div class="formLabel col-md-11"
                        *ngIf="texas.controls['294'].touched && texas.controls['294'].invalid">
                        <small class="text-danger" *ngIf="texas.controls['294'].errors?.required">
                            required
                        </small>
                    </div>
                    <small class="text-danger" *ngIf="texas.get('294').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)                </small>
                      <small class="text-danger" *ngIf="texas.get('294').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="texas.get('294').errors?.invalidDateyear">
                        year is out of range(1900-2099)                  </small>
                        <small class="text-danger" *ngIf="texas.get('294').errors?.invalidDateRange">
                          date is out of range
                          </small>
                </div>
            </td>
            <td colspan="6">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="normalParagraph">EXPIRATION DATE (MM/DD/YYYY)
                    </label>
                    <input style="width:70%;" class="form-control onboard datepicker" (blur)="clearInputIfInvalidMandi('295')" ngbDatepicker #d17="ngbDatepicker" [class.is-invalid]="(texas.controls['295'].touched && texas.controls['295'].invalid) || (texas.controls['295'].invalid && isSubmit)" placeholder="MM/DD/YYYY" id="295" name="295" formControlName="295" type="text" data-id="licenseexp_date">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d17.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                    </div>
                    <div class="formLabel col-md-11"
                        *ngIf="texas.controls['295'].touched && texas.controls['295'].invalid">
                        <small class="text-danger" *ngIf="texas.controls['295'].errors?.required">
                            required
                        </small>
                    </div>
                    <small class="text-danger" *ngIf="texas.get('295').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)                </small>
                      <small class="text-danger" *ngIf="texas.get('295').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="texas.get('295').errors?.invalidDateyear">
                        year is out of range(1900-2099)                  </small>
                        <small class="text-danger" *ngIf="texas.get('295').errors?.invalidDateRange">
                          date is out of range
                          </small>
                </div>
            </td>
            <td colspan="6">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    DO YOU CURRENTLY PRACTICE IN THIS STATE?
                </div>
                <div class="form-group no-margin col-md-12 sideLabelAlignment">
                    <label class="radio-inline radioStyle">
                    <input type="radio" id="cmd_013" name="cmd_013" formControlName="cmd_013" value="1">Yes</label>
                    <label class="radio-inline radioStyle">
                    <input type="radio" id="cmd_013" name="cmd_013" formControlName="cmd_013" value="0">No</label>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="6">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">LICENSE TYPE
                    </label>
                    <input style="width: 40%" class="form-control onboard " id="1274" name="1274" formControlName="1274" type="text" data-id="Alt License type">
                </div>
            </td>
            <td colspan="6">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">LICENSE NUMBER
                    </label>
                    <input style="width: 60%" class="form-control onboard " id="300" name="300" formControlName="300" type="text" data-id="alt license">
                </div>
            </td>
            <td colspan="6">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">STATE OF REGISTRATION
                    </label>
                    <!-- <input style="width:70%;" class="form-control onboard " id="907" name="907" formControlName="907" type="text" data-id="alt licensestate"> -->
                    <div class="form-group selectDrop col-md-3 allMargin">
                        <ng-select [items]="altLicenseState" bindLabel="name"  placeholder="Select States" bindValue="id" name="907" formControlName="907">
                        </ng-select>
                    </div>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="6">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="normalParagraph">ORIGINAL DATE OF ISSUE (MM/DD/YYYY)
                    </label>
                    <input class="form-control onboard datepicker" (blur)="clearInputIfInvalid('307')" ngbDatepicker #d18="ngbDatepicker" id="307" name="307" placeholder="MM/DD/YYYY" formControlName="307" type="text" data-id="alt licesne issue">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d18.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                    </div>
                    <div  *ngIf="texas.get('307').invalid ">
                        <small class="text-danger" *ngIf="texas.get('307').errors?.invalidDateFormat">
                          Only Date Format is allowed(MM/DD/YYYY)
                        </small>
                        <small class="text-danger" *ngIf="texas.get('307').errors?.invalidMonth">
                        Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="texas.get('307').errors?.invalidDateyear">
                          year is out of range(1900-2099)                      </small>
                          <small class="text-danger" *ngIf="texas.get('307').errors?.invalidDateRange">
                            date is out of range
                            </small>
                        </div>
                </div>
            </td>
            <td colspan="6">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="normalParagraph">EXPIRATION DATE (MM/DD/YYYY)
                    </label>
                    <input style="width: 40%" class="form-control onboard datepicker"  (blur)="clearInputIfInvalid('308')" ngbDatepicker #d19="ngbDatepicker" placeholder="MM/DD/YYYY" id="308" name="308" formControlName="308" ype="text" data-id="alt licesne exp">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d19.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                    </div>
                    <div  *ngIf="texas.get('308').invalid ">
                        <small class="text-danger" *ngIf="texas.get('308').errors?.invalidDateFormat">
                          Only Date Format is allowed(MM/DD/YYYY)
                        </small>
                        <small class="text-danger" *ngIf="texas.get('308').errors?.invalidMonth">
                        Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="texas.get('308').errors?.invalidDateyear">
                          year is out of range(1900-2099)                      </small>
                          <small class="text-danger" *ngIf="texas.get('308').errors?.invalidDateRange">
                            date is out of range
                            </small>
                        </div>
                </div>
            </td>
            <td colspan="6">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    DO YOU CURRENTLY PRACTICE IN THIS STATE?
                </div>
                <div class="form-group no-margin col-md-12 sideLabelAlignment">
                    <label class="radio-inline radioStyle">
                    <input type="radio" id="cmd_014" name="cmd_014" formControlName="cmd_014" value="1">Yes</label>
                    <label class="radio-inline radioStyle">
                    <input type="radio" id="cmd_014" name="cmd_014" formControlName="cmd_014" value="0">No</label>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="6">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">LICENSE TYPE
                    </label>
                    <input style="width: 40%" class="form-control onboard " id="1432" name="1432" formControlName="1432" type="text" data-id="add_license_type">
                </div>
            </td>
            <td colspan="6">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">LICENSE NUMBER
                    </label>
                    <input style="width: 60%" class="form-control onboard " id="999" name="999" formControlName="999" type="text" data-id="add_license">
                </div>
            </td>
            <td colspan="6">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">STATE OF REGISTRATION
                    </label>
                    <div class="form-group selectDrop dropDrownSet degree col-md-6">
                        <ng-select [items]="additionalLicenseState" bindLabel="name" placeholder="Select States" bindValue="id" formControlName="1010">
                        </ng-select>
                    </div>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="6">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="normalParagraph">ORIGINAL DATE OF ISSUE (MM/DD/YYYY)
                    </label>
                    <input class="form-control onboard datepicker"  (blur)="clearInputIfInvalid('1003')" ngbDatepicker #d20="ngbDatepicker" placeholder="MM/DD/YYYY" id="1003" name="1003" formControlName="1003" type="text" data-id="add license issue">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d20.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                    </div>
                    <div  *ngIf="texas.get('1003').invalid ">
                        <small class="text-danger" *ngIf="texas.get('1003').errors?.invalidDateFormat">
                          Only Date Format is allowed(MM/DD/YYYY)
                        </small>
                        <small class="text-danger" *ngIf="texas.get('1003').errors?.invalidMonth">
                        Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="texas.get('1003').errors?.invalidDateyear">
                          year is out of range(1900-2099)                      </small>
                          <small class="text-danger" *ngIf="texas.get('1003').errors?.invalidDateRange">
                            date is out of range
                            </small>
                        </div>
                </div>
            </td>
            <td colspan="6">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="normalParagraph">EXPIRATION DATE (MM/DD/YYYY)
                    </label>
                    <input style="width: 40%" class="form-control onboard datepicker"  (blur)="clearInputIfInvalid('1004')" ngbDatepicker #d21="ngbDatepicker" placeholder="MM/DD/YYYY" id="1004" name="1004" formControlName="1004" type="text" data-id="add license exp">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d21.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                    </div>
                    <div  *ngIf="texas.get('1004').invalid ">
                        <small class="text-danger" *ngIf="texas.get('1004').errors?.invalidDateFormat">
                          Only Date Format is allowed(MM/DD/YYYY)
                        </small>
                        <small class="text-danger" *ngIf="texas.get('1004').errors?.invalidMonth">
                        Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="texas.get('1004').errors?.invalidDateyear">
                          year is out of range(1900-2099)                      </small>
                          <small class="text-danger" *ngIf="texas.get('1004').errors?.invalidDateRange">
                            date is out of range
                            </small>
                        </div>
                </div>
            </td>
            <td colspan="6">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    DO YOU CURRENTLY PRACTICE IN THIS STATE?
                </div>
                <div class="form-group no-margin col-md-12 sideLabelAlignment">
                    <label class="radio-inline radioStyle">
                    <input type="radio" id="cmd_015" name="cmd_015" formControlName="cmd_015" value="1">Yes</label>
                    <label class="radio-inline radioStyle">
                    <input type="radio" id="cmd_015" name="cmd_015" formControlName="cmd_015" value="0">No</label>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="6">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                </div>
                <div class="form-group no-margin col-md-12 sideLabelAlignment">
                    <label class="radio-inline radioStyle"></label>
                    <input type="radio" id="cmd_016" name="cmd_016" formControlName="cmd_016" value="1">DEA Number:
                    <input style="width:70%;" class="form-control onboard " id="309" name="309" formControlName="309" type="text" data-id="dea">
                </div>
            </td>
            <td colspan="6">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="normalParagraph">ORIGINAL DATE OF ISSUE (MM/DD/YYYY)
                    </label>
                    <input style="width:30%;" class="form-control onboard datepicker"  (blur)="clearInputIfInvalid('310')" id="310" name="310" ngbDatepicker #d22="ngbDatepicker" formControlName="310" type="text" data-id="deaissue" placeholder="MM/DD/YYYY">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d22.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                    </div>
                    <div  *ngIf="texas.get('310').invalid ">
                        <small class="text-danger" *ngIf="texas.get('310').errors?.invalidDateFormat">
                          Only Date Format is allowed(MM/DD/YYYY)
                        </small>
                        <small class="text-danger" *ngIf="texas.get('310').errors?.invalidMonth">
                        Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="texas.get('310').errors?.invalidDateyear">
                          year is out of range(1900-2099)                      </small>
                          <small class="text-danger" *ngIf="texas.get('310').errors?.invalidDateRange">
                            date is out of range
                            </small>
                        </div>
                </div>
            </td>
            <td colspan="6">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="normalParagraph">EXPIRATION DATE (MM/DD/YYYY)
                    </label>
                    <input style="width:70%;" class="form-control onboard datepicker" (blur)="clearInputIfInvalid('311')" ngbDatepicker #d23="ngbDatepicker" placeholder="MM/DD/YYYY" id="311" name="311" formControlName="311" type="text" data-id="dea_exp_date">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d23.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                    </div>
                    <div  *ngIf="texas.get('311').invalid ">
                        <small class="text-danger" *ngIf="texas.get('311').errors?.invalidDateFormat">
                          Only Date Format is allowed(MM/DD/YYYY)
                        </small>
                        <small class="text-danger" *ngIf="texas.get('311').errors?.invalidMonth">
                        Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="texas.get('311').errors?.invalidDateyear">
                          year is out of range(1900-2099)                      </small>
                          <small class="text-danger" *ngIf="texas.get('311').errors?.invalidDateRange">
                            date is out of range
                            </small>
                        </div>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="6">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                </div>
                <div class="form-group no-margin col-md-12 sideLabelAlignment">
                    <label class="radio-inline radioStyle"></label>
                    <input type="radio" id="cmd_017" name="cmd_017" formControlName="cmd_017"  value="1"> DPS Number:
                    <input style="width:70%;" class="form-control onboard " id="1193" name="1193" formControlName="1193" type="text" data-id="driverslic">
                </div>
            </td>
            <td colspan="6">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="normalParagraph">ORIGINAL DATE OF ISSUE (MM/DD/YYYY)
                    </label>
                    <input style="width:70%;" class="form-control onboard datepicker"  (blur)="clearInputIfInvalid('cmd_018')" ngbDatepicker #d24="ngbDatepicker" placeholder="MM/DD/YYYY" id="cmd_018" name="cmd_018" formControlName="cmd_018" type="text" data-id="drivers issue">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d24.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                    </div>
                    <div  *ngIf="texas.get('cmd_018').invalid ">
                        <small class="text-danger" *ngIf="texas.get('cmd_018').errors?.invalidDateFormat">
                          Only Date Format is allowed(MM/DD/YYYY)
                        </small>
                        <small class="text-danger" *ngIf="texas.get('cmd_018').errors?.invalidMonth">
                        Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="texas.get('cmd_018').errors?.invalidDateyear">
                          year is out of range(1900-2099)                      </small>
                          <small class="text-danger" *ngIf="texas.get('cmd_018').errors?.invalidDateRange">
                            date is out of range
                            </small>
                        </div>
                </div>
            </td>
            <td colspan="6">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="normalParagraph">EXPIRATION DATE (MM/DD/YYYY)
                    </label>
                    <input style="width:70%;" class="form-control onboard datepicker"  (blur)="clearInputIfInvalid('821')" ngbDatepicker #d25="ngbDatepicker" placeholder="MM/DD/YYYY" id="821" name="821" formControlName="821" type="text" data-id="drivers exp">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d25.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                    </div>
                    <div  *ngIf="texas.get('821').invalid ">
                        <small class="text-danger" *ngIf="texas.get('821').errors?.invalidDateFormat">
                          Only Date Format is allowed(MM/DD/YYYY)
                        </small>
                        <small class="text-danger" *ngIf="texas.get('821').errors?.invalidMonth">
                        Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="texas.get('821').errors?.invalidDateyear">
                          year is out of range(1900-2099)                      </small>
                          <small class="text-danger" *ngIf="texas.get('821').errors?.invalidDateRange">
                            date is out of range
                            </small>
                        </div>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="6">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">OTHER CDS (PLEASE SPECIFY) </label>
                    <!-- <input style="width:70%;" class="form-control onboard " id="cmd_019" name="cmd_019" formControlName="cmd_019" type="text" data-id="CDs other">                 -->
                    <ng-select [items]="states"  bindLabel="name" placeholder="Select State" bindValue="id" formControlName="cmd_019">
                    </ng-select>
                  </div>
            </td>
            <td colspan="6">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">NUMBER</label>
                    <input style="width:70%;" class="form-control onboard " id="337" name="337" formControlName="337" type="text" data-id="CDs number">
                </div>
            </td>
            <td colspan="6">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">STATE OF REGISTRATION </label>
                    <input style="width:70%;" class="form-control onboard " id="996" name="996" formControlName="996" type="text" data-id="CDs state">
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="6">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">ORIGINAL DATE OF ISSUE (MM/DD/YYYY)  </label>
                    <input style="width:70%;" class="form-control onboard datepicker"  (blur)="clearInputIfInvalid('338')" ngbDatepicker #d26="ngbDatepicker" id="338" name="338" formControlName="338" type="text" data-id="CDs issue date" placeholder="MM/DD/YYYY">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d26.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                    </div>
                    <div  *ngIf="texas.get('338').invalid ">
                        <small class="text-danger" *ngIf="texas.get('338').errors?.invalidDateFormat">
                          Only Date Format is allowed(MM/DD/YYYY)
                        </small>
                        <small class="text-danger" *ngIf="texas.get('338').errors?.invalidMonth">
                        Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="texas.get('338').errors?.invalidDateyear">
                          year is out of range(1900-2099)                      </small>
                          <small class="text-danger" *ngIf="texas.get('338').errors?.invalidDateRange">
                            date is out of range
                            </small>
                        </div>
                </div>
            </td>
            <td colspan="6">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">EXPIRATION DATE (MM/DD/YYYY) </label>
                    <input style="width:70%;" class="form-control onboard datepicker"  (blur)="clearInputIfInvalid('339')" ngbDatepicker #d27="ngbDatepicker" placeholder="MM/DD/YYYY" id="339" name="339" formControlName="339" type="text" data-id="CDs Exp date">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d27.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                    </div>
                    <div  *ngIf="texas.get('339').invalid ">
                        <small class="text-danger" *ngIf="texas.get('339').errors?.invalidDateFormat">
                          Only Date Format is allowed(MM/DD/YYYY)
                        </small>
                        <small class="text-danger" *ngIf="texas.get('339').errors?.invalidMonth">
                        Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="texas.get('339').errors?.invalidDateyear">
                          year is out of range(1900-2099)                      </small>
                          <small class="text-danger" *ngIf="texas.get('339').errors?.invalidDateRange">
                            date is out of range
                            </small>
                        </div>
                </div>
            </td>
            <td colspan="6">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">DO YOU CURRENTLY PRACTICE IN THIS STATE?
                    <input type="radio" id="cmd_020" name="cmd_020" formControlName="cmd_020" value="1">Yes</label>
                    <label class="radio-inline radioStyle">
                    <input type="radio" id="cmd_020" name="cmd_020" formControlName="cmd_020" value="0">No</label>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="8">
                <div class="form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">UPIN</label>
                    <input class="form-control onboard " id="819" name="819" formControlName="819" type="text" data-id="upin">
                </div>
            </td>
            <td colspan="10">
                <div class="form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">NATIONAL PROVIDER IDENTIFIER (WHEN AVAILABLE)
                    </label>
                    <input class="form-control onboard " id="1634" name="1634" formControlName="1634" type="text" data-id="npi">
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="9">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="normalParagraph">ARE YOU A PARTICIPATING MEDICARE PROVIDER?
                    <input type="radio" id="cmd_021" name="cmd_021" formControlName="cmd_021" value="1">Yes</label>
                    <label class="radio-inline radioStyle">
                    <input type="radio" id="cmd_021" name="cmd_021" formControlName="cmd_021" value="0">No</label>
                    <label class="normalParagraph">Medicare Provider Number:</label>
                    <input class="form-control onboard " id="325" name="325" formControlName="325" type="text" data-id="medicare">
                </div>
            </td>
            <td colspan="9">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="normalParagraph">ARE YOU A PARTICIPATING MEDICAID PROVIDER?
                    <input type="radio" id="cmd_022" name="cmd_022" formControlName="cmd_022" value="1">Yes</label>
                    <label class="radio-inline radioStyle">
                    <input type="radio" id="cmd_022" name="cmd_022" formControlName="cmd_022" value="0">No</label>
                    <label class="normalParagraph">Medicaid Provider Number:</label>
                    <input class="form-control onboard " id="812" name="812" formControlName="812" type="text" data-id="medicaid">
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="9">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label style="font-weight: bold;">EDUCATIONAL COUNCIL FOR FOREIGN MEDICAL GRADUATES (ECFMG)
                    <input type="radio" id="cmd_023" name="cmd_023" [class.is-invalid]="(texas.controls['cmd_023'].touched && texas.controls['cmd_023'].invalid) || (texas.controls['cmd_023'].invalid && isSubmit)" formControlName="cmd_023" (change)="onChangeCmdOne($event)" value="0">N/A</label>
                    <label class="radio-inline radioStyle">
                    <input type="radio" id="cmd_023" name="cmd_023" [class.is-invalid]="(texas.controls['cmd_023'].touched && texas.controls['cmd_023'].invalid) || (texas.controls['cmd_023'].invalid && isSubmit)" formControlName="cmd_023" (change)="onChangeCmdOne($event)" value="1">Yes</label>
                    <label class="radio-inline radioStyle">
                    <input type="radio" id="cmd_023" name="cmd_023" [class.is-invalid]="(texas.controls['cmd_023'].touched && texas.controls['cmd_023'].invalid) || (texas.controls['cmd_023'].invalid && isSubmit)" formControlName="cmd_023" (change)="onChangeCmdOne($event)" value="2">No</label>
                    <div class="formLabel col-md-11"
                        *ngIf="texas.controls['cmd_023'].touched && texas.controls['cmd_023'].invalid">
                        <small class="text-danger" *ngIf="texas.controls['cmd_023'].errors?.required">
                            required
                        </small>
                    </div>
                    <span *ngIf="(texas.controls['cmd_023'].touched && texas.controls['cmd_023'].invalid) || (texas.controls['cmd_023'].invalid && isSubmit)">
                        <i class="fas fa-times ml-2 text-danger"></i>
                    </span>
                    <div *ngIf="ecfmgchange">
                        <label class="normalParagraph"> ECFMG Number:</label>
                        <input style="width: 40%" class="form-control onboard " [class.is-invalid]="texas.controls['514'].value==''" id="514" name="514" formControlName="514" type="text" data-id="ecfmg">
                        <div class="formLabel col-md-11"
                            *ngIf="texas.controls['514'].touched && texas.controls['514'].invalid">
                            <small class="text-danger" *ngIf="texas.controls['514'].errors?.required">
                                required
                            </small>
                        </div>
                    </div>
                </div>
            </td>
            <td colspan="9">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">ECFMG ISSUE DATE (MM/DD/YYYY) </label>
                </div>
                <div class="input-group">
                    <input style="width:30%;"  class="form-control onboard datepicker"  (blur)="clearInputIfInvalid('515')" placeholder="MM/DD/YYYY" ngbDatepicker #d28="ngbDatepicker" id="515" name="515" formControlName="515" type="text" data-id="ecfmgissue_date">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d28.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                    </div>
                    <div  *ngIf="texas.get('515').invalid ">
                        <small class="text-danger" *ngIf="texas.get('515').errors?.invalidDateFormat">
                          Only Date Format is allowed(MM/DD/YYYY)
                        </small>
                        <small class="text-danger" *ngIf="texas.get('515').errors?.invalidMonth">
                        Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="texas.get('515').errors?.invalidDateyear">
                          year is out of range(1900-2099)                      </small>
                          <small class="text-danger" *ngIf="texas.get('515').errors?.invalidDateRange">
                            date is out of range
                            </small>
                        </div>
                </div>
            </td>
        </tr>
    </table>
    <table border="1" width="100%">
        <tr>
            <td colspan="14">
                <div class="centered texasFirstTable">
                    <h3 class="texasHThree">Professional/Specialty Information</h3>
                </div>
            </td>
            <td colspan="4">
                <label class="texasFloat">Does Not Apply</label>
                <input type="checkbox" name="cmd_0726" id="cmd_0726" formControlName="cmd_0726" value="1" (change)="proSpecApp($event)" class="texasFloat">
            </td>
        </tr>
        <tr>
            <td colspan="6">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label style="font-weight: bold;">PRIMARY SPECIALTY </label>
                    <!-- <input class="form-control onboard " [class.is-invalid]="(texas.controls['556'].touched && texas.controls['556'].errors) || (texas.controls['556'].errors && isSubmit)" id="556" name="556" formControlName="556" type="text" data-id="primarybspec"> -->
                    <div class="form-group selectDrop boardCertiSet inputPadding">
                        <ng-select
                          [items]="specialty"
                          [class.is-invalid]="
                            (texas.controls['556'].touched &&
                              texas.controls['556'].errors) ||
                            (texas.controls['556'].errors && isSubmit)
                          "
                          bindLabel="name"
                          placeholder="Select Specialty"
                          bindValue="id"
                          formControlName="556"
                        >
                        </ng-select>
                    </div>
                    <div class="formLabel col-md-11"
                        *ngIf="texas.controls['556'].touched && texas.controls['556'].errors">
                        <small class="text-danger" *ngIf="texas.controls['556'].errors">
                            required
                        </small>
                    </div>
                </div>
            </td>
            <td colspan="12">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label style="font-weight: bold;">BOARD CERTIFIED?</label>
                    <label class="radio-inline radioStyle">
                        <input type="radio" id="cmd_024" name="cmd_024" formControlName="cmd_024" [class.is-invalid]="(texas.controls['cmd_024'].touched && texas.controls['cmd_024'].errors) || (texas.controls['cmd_024'].errors && isSubmit)" (change)="onChangeCmdThree($event)"  value="1">Yes</label>
                        <label class="radio-inline radioStyle">
                        <input type="radio" id="cmd_024" name="cmd_024" formControlName="cmd_024" [class.is-invalid]="(texas.controls['cmd_024'].touched && texas.controls['cmd_024'].errors) || (texas.controls['cmd_024'].errors && isSubmit)" (change)="onChangeCmdThree($event)"  value="0">No</label>
                        <div class="formLabel col-md-2"
                            *ngIf="texas.controls['cmd_024'].touched && texas.controls['cmd_024'].errors">
                            <small class="text-danger" *ngIf="texas.controls['cmd_024'].errors">
                                required
                            </small>
                        </div>
                        <span *ngIf="(texas.controls['cmd_024'].touched && texas.controls['cmd_024'].errors) || (texas.controls['cmd_024'].errors && isSubmit)">
                            <i class="fas fa-times ml-2 text-danger"></i>
                        </span>
                    <br/>
                    <div *ngIf="certifiedchange">
                        <div class="col-md-4">
                            <label class="formLabel"> Name of Certifying Board: </label>
                        </div>
                        <!-- <input class="form-control onboard" [class.is-invalid]="texas.controls['554'].value==''" id="554" name="554" formControlName="554" type="text" data-id="primaryboard"> -->
                        <div class="form-group selectDrop boardCertiSet inputPadding">
                            <ng-select
                              [items]="boardCertification"
                              [class.is-invalid]="
                                (texas.controls['554'].touched &&
                                  texas.controls['554'].errors) ||
                                (texas.controls['554'].errors && isSubmit)
                              "
                              bindLabel="name"
                              placeholder="Select Board Certification"
                              bindValue="id"
                              formControlName="554"
                            >
                            </ng-select>
                            <div class="formLabel col-md-11"
                                *ngIf="texas.controls['554'].touched && texas.controls['554'].invalid">
                                <small class="text-danger" *ngIf="texas.controls['554'].errors?.required">
                                    required
                                </small>
                            </div>
                        </div>
                    </div>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="4">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">INITIAL CERTIFICATION DATE (MM/DD/YYYY)
                    </label>
                    <input style="width:80%;" ngbDatepicker #d29="ngbDatepicker" (blur)="clearInputIfInvalid('557')"  [class.is-invalid]="(texas.controls['557'].touched && texas.controls['557'].errors) || (texas.controls['557'].errors && isSubmit)" class="form-control onboard datepicker" placeholder="MM/DD/YYYY" id="557" name="557" formControlName="557" type="text" data-id="primarybcert">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d29.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                    </div>
                    <div class="formLabel col-md-11"
                        *ngIf="texas.controls['557'].touched && texas.controls['557'].errors">
                        <small class="text-danger" *ngIf="texas.controls['557'].errors">
                            required
                        </small>
                    </div>
                    <small class="text-danger" *ngIf="texas.get('557').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)                </small>
                      <small class="text-danger" *ngIf="texas.get('557').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="texas.get('557').errors?.invalidDateyear">
                        year is out of range(1900-2099)                  </small>
                        <small class="text-danger" *ngIf="texas.get('557').errors?.invalidDateRange">
                          date is out of range
                          </small>

                </div>
            </td>
            <td colspan="8">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">RECERTIFICATION DATE(S), IF APPLICABLE (MM/DD/YYYY) </label>
                    <input style="width:80%;" class="form-control onboard datepicker" (blur)="clearInputIfInvalid('559')" ngbDatepicker #d30="ngbDatepicker" placeholder="MM/DD/YYYY" id="559" name="559" formControlName="559" type="text" data-id="primarybrcert2">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d30.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                    </div>
                    <div  *ngIf="texas.get('559').invalid ">
                        <small class="text-danger" *ngIf="texas.get('559').errors?.invalidDateFormat">
                          Only Date Format is allowed(MM/DD/YYYY)
                        </small>
                        <small class="text-danger" *ngIf="texas.get('559').errors?.invalidMonth">
                        Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="texas.get('559').errors?.invalidDateyear">
                          year is out of range(1900-2099)                      </small>
                          <small class="text-danger" *ngIf="texas.get('559').errors?.invalidDateRange">
                            date is out of range
                            </small>
                        </div>
                </div>
            </td>
            <td colspan="6">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="normalParagraph">EXPIRATION DATE, IF APPLICABLE (MM/DD/YYYY)
                    </label>
                    <input style="width:80%;" class="form-control onboard datepicker" (blur)="clearInputIfInvalid('560')" ngbDatepicker #d31="ngbDatepicker" placeholder="MM/DD/YYYY" id="560" name="560" formControlName="560" type="text" data-id="primarybexp_date">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d31.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                    </div>
                    <div  *ngIf="texas.get('560').invalid ">
                        <small class="text-danger" *ngIf="texas.get('560').errors?.invalidDateFormat">
                          Only Date Format is allowed(MM/DD/YYYY)
                        </small>
                        <small class="text-danger" *ngIf="texas.get('560').errors?.invalidMonth">
                        Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="texas.get('560').errors?.invalidDateyear">
                          year is out of range(1900-2099)                      </small>
                          <small class="text-danger" *ngIf="texas.get('560').errors?.invalidDateRange">
                            date is out of range
                            </small>
                        </div>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <div class="col-md-8">
                    <label class="formLabel">IF NOT BOARD CERTIFIED, INDICATE ANY OF THE FOLLOWING THAT APPLY.</label>
                    </div>
                    <br/>
                    <div class="col-md-12">
                    <label class="boldParagraph" class="checkbox-inline radioStyle">
                    <input type="checkbox" id="cmd_025" name="cmd_025"  formControlName="cmd_025" value="1"> I have taken exam, results pending for Board
                    <input style="width: 50%" class="form-control" id="cmd_029" name="cmd_029" formControlName="cmd_029" type="text" data-id="test1"></label>
                    </div>
                    <br/>
                    <div class="col-md-12">
                    <label class="boldParagraph" class="checkbox-inline radioStyle">
                    <input type="checkbox" id="cmd_026" name="cmd_026" formControlName="cmd_026" value="1"> I have taken Part I and am eligible for Part II of the Exam.
                    <input style="width: 50%" class="form-control" id="cmd_030" name="cmd_030" formControlName="cmd_030" type="text" data-id="test2"></label>
                    </div>
                    <br/>
                    <div class="col-md-12">
                    <label class="boldParagraph" class="checkbox-inline radioStyle">
                    <input type="checkbox" id="cmd_027" name="cmd_027" formControlName="cmd_027" value="1">I am intending to sit for the Boards on (date)
                    <input style="width: 50%" class="form-control" id="cmd_031" name="cmd_031" formControlName="cmd_031" type="text" data-id="test3"></label>
                    </div>
                    <br/>
                    <div class="row extraBoxPaddingLeft">
                    <div class="col-md-12">
                    <label class="boldParagraph" class="checkbox-inline radioStyle">
                    <input type="checkbox" id="cmd_028" name="cmd_028" formControlName="cmd_028" value="1"> I am not planning to take Boards</label>
                    </div>
                    </div>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <div class="row extraBoxPaddingLeft">
                    <div class="col-md-12">
                    <label class="formLabel">DO YOU WISH TO BE LISTED IN THE DIRECTORY UNDER THIS SPECIALTY? </label>
                    </div>
                    </div>
                    <br/>
                    <label class="formLabel"><b>HMO:</b></label>
                    <label class="boldParagraph" class="checkbox-inline radioStyle">
                    <input type="radio" id="cmd_032" name="cmd_032" formControlName="cmd_032" value="1"> Yes</label>
                    <label class="boldParagraph" class="checkbox-inline radioStyle">
                    <input type="radio" id="cmd_032" name="cmd_032" formControlName="cmd_032" value="0">  No </label>
                    <br/>
                    <label class="formLabel"><b>PPO:</b ></label>
                    <label class="boldParagraph" class="checkbox-inline radioStyle">
                    <input type="radio" id="cmd_033" name="cmd_033" formControlName="cmd_033" value="1"> Yes</label>
                    <label class="boldParagraph" class="checkbox-inline radioStyle">
                    <input type="radio" id="cmd_033" name="cmd_033" formControlName="cmd_033" value="0"> No </label>
                    <br/>
                    <label class="formLabel"><b>POS:</b></label>
                    <label class="boldParagraph" class="checkbox-inline radioStyle">
                        <input type="radio" id="cmd_034" name="cmd_034" formControlName="cmd_034" value="1">Yes</label>
                    <label class="boldParagraph" class="checkbox-inline radioStyle">
                    <input type="radio" id="cmd_034" name="cmd_034" formControlName="cmd_034" value="0">  No </label>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="9">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="normalParagraph">SECONDARY SPECIALTY </label>
                    <!-- <input class="form-control onboard " id="563" name="563" formControlName="563" type="text" data-id="secbspec"> -->
                    <div class="form-group selectDrop boardCertiSet inputPadding">
                        <ng-select
                          [items]="specialty"
                          bindLabel="name"
                          placeholder="Select Specialty"
                          bindValue="id"
                          formControlName="563"
                        >
                        </ng-select>
                    </div>
                </div>
            </td>
            <td colspan="9">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label style="font-weight: bold;">BOARD CERTIFIED?</label>
                    <label class="radio-inline radioStyle">
                    <input type="radio" id="cmd_035" name="cmd_035" formControlName="cmd_035"  value="1">Yes</label>
                    <label class="radio-inline radioStyle">
                    <input type="radio" id="cmd_035" name="cmd_035" formControlName="cmd_035"  value="0">No</label>
                    <br/>
                    <label class="normalParagraph">Name of Certifying Board:</label>
                    <!-- <input class="form-control onboard " id="561" name="561" formControlName="561" type="text" data-id="secboard"> -->
                    <div class="form-group selectDrop boardCertiSet inputPadding col-md-5"
                    >
                        <ng-select
                            [items]="boardCertification"
                            bindLabel="name"
                            placeholder="Select Board Certification"
                            bindValue="id"
                            formControlName="561"
                        >
                        </ng-select>
                    </div>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="6">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">INITIAL CERTIFICATION DATE (MM/DD/YYYY)
                    </label>
                    <input style="width:80%;" class="form-control onboard datepicker" (blur)="clearInputIfInvalid('564')" ngbDatepicker #d32="ngbDatepicker" placeholder="MM/DD/YYYY" id="564" name="564" formControlName="564" type="text" data-id="secbcert">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d32.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                    </div>
                    <div  *ngIf="texas.get('564').invalid ">
                        <small class="text-danger" *ngIf="texas.get('564').errors?.invalidDateFormat">
                          Only Date Format is allowed(MM/DD/YYYY)
                        </small>
                        <small class="text-danger" *ngIf="texas.get('564').errors?.invalidMonth">
                        Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="texas.get('564').errors?.invalidDateyear">
                          year is out of range(1900-2099)                      </small>
                          <small class="text-danger" *ngIf="texas.get('564').errors?.invalidDateRange">
                            date is out of range
                            </small>
                        </div>
                </div>
            </td>
            <td colspan="6">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">RECERTIFICATION DATE(S), IF APPLICABLE (MM/DD/YYYY) </label>
                    <input style="width:80%;" class="form-control onboard datepicker" (blur)="clearInputIfInvalid('565')" ngbDatepicker #d33="ngbDatepicker" placeholder="MM/DD/YYYY" id="565" name="565" formControlName="565" type="text" data-id="secbrcert2">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d33.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                    </div>
                    <div  *ngIf="texas.get('565').invalid ">
                        <small class="text-danger" *ngIf="texas.get('565').errors?.invalidDateFormat">
                          Only Date Format is allowed(MM/DD/YYYY)
                        </small>
                        <small class="text-danger" *ngIf="texas.get('565').errors?.invalidMonth">
                        Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="texas.get('565').errors?.invalidDateyear">
                          year is out of range(1900-2099)                      </small>
                          <small class="text-danger" *ngIf="texas.get('565').errors?.invalidDateRange">
                            date is out of range
                            </small>
                        </div>
                </div>
            </td>
            <td colspan="6">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">EXPIRATION DATE, IF APPLICABLE (MM/DD/YYYY)
                    </label>
                    <input style="width:80%;" class="form-control onboard datepicker" (blur)="clearInputIfInvalid('567')" ngbDatepicker #d34="ngbDatepicker" placeholder="MM/DD/YYYY" id="567" name="567" formControlName="567" type="text" data-id="secbexp_date">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d34.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                    </div>
                    <div  *ngIf="texas.get('567').invalid ">
                        <small class="text-danger" *ngIf="texas.get('567').errors?.invalidDateFormat">
                          Only Date Format is allowed(MM/DD/YYYY)
                        </small>
                        <small class="text-danger" *ngIf="texas.get('567').errors?.invalidMonth">
                        Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="texas.get('567').errors?.invalidDateyear">
                          year is out of range(1900-2099)                      </small>
                          <small class="text-danger" *ngIf="texas.get('567').errors?.invalidDateRange">
                            date is out of range
                            </small>
                        </div>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                <div class="col-md-8">
                <label class="formLabel">IF NOT BOARD CERTIFIED, INDICATE ANY OF THE FOLLOWING THAT APPLY.</label>
                </div>
                <br/>
                <div class="col-md-12">
                <label class="boldParagraph" class="checkbox-inline radioStyle">
                <input type="checkbox" id="cmd_036" name="cmd_036" formControlName="cmd_036" value="1"> I have taken exam, results pending for Board
                <input style="width: 50%" class="form-control" id="cmd_040" name="cmd_040" formControlName="cmd_040" type="text" data-id="test4"></label>
                </div>
                <br/>
                <div class="col-md-12">
                <label class="boldParagraph" class="checkbox-inline radioStyle">
                <input type="checkbox" id="cmd_037" name="cmd_037" formControlName="cmd_037" value="1"> I have taken Part I and am eligible for Part II of the Exam.
                <input style="width: 50%" class="form-control" id="cmd_041" name="cmd_041" formControlName="cmd_041" type="text" data-id="test5"></label>
                </div>
                <br/>
                <div class="col-md-12">
                <label class="boldParagraph" class="checkbox-inline radioStyle">
                <input type="checkbox" id="cmd_038" name="cmd_038" formControlName="cmd_038" value="1">I am intending to sit for the Boards on (date)
                <input style="width: 50%" class="form-control" id="cmd_042" name="cmd_042" formControlName="cmd_042" type="text" data-id="test6"></label>
                </div>
                <br/>
                <div class="col-md-12">
                <label class="boldParagraph" class="checkbox-inline radioStyle">
                <input type="checkbox" id="cmd_039" name="cmd_039" formControlName="cmd_039" value="1"> I am not planning to take Boards</label>
                </div>
            </div>
            </td>
        </tr>
        <tr>
            <td colspan="18">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="formLabel">DO YOU WISH TO BE LISTED IN THE DIRECTORY UNDER THIS SPECIALTY? </label>
                        <br/>
                    <label class="formLabel"><b>HMO:</b></label>
                    <label class="boldParagraph" class="checkbox-inline radioStyle">
                    <input type="radio" id="cmd_043" name="cmd_043" formControlName="cmd_043"  value="1"> Yes</label>
                    <label class="boldParagraph" class="checkbox-inline radioStyle">
                    <input type="radio" id="cmd_043" name="cmd_043" formControlName="cmd_043" value="0">  No </label>
                    <br/>
                    <label class="formLabel"><b>PPO:</b></label>
                    <label class="boldParagraph" class="checkbox-inline radioStyle">
                    <input type="radio" id="cmd_044" name="cmd_044" formControlName="cmd_044" value="1"> Yes</label>
                    <label class="boldParagraph" class="checkbox-inline radioStyle">
                    <input type="radio" id="cmd_044" name="cmd_044" formControlName="cmd_044" value="0"> No </label>
                    <br/>
                    <label class="formLabel"><b>POS:</b></label>
                    <label class="boldParagraph" class="checkbox-inline radioStyle">

                    <input type="radio" id="cmd_045" name="cmd_045" formControlName="cmd_045" value="1">Yes</label>
                    <label class="boldParagraph" class="checkbox-inline radioStyle">
                    <input type="radio" id="cmd_045" name="cmd_045" formControlName="cmd_045" value="0">  No </label>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="9">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="normalParagraph">ADDITIONAL SPECIALTY </label>
                    <!-- <input class="form-control onboard " id="948" name="948" formControlName="948" type="text" data-id="Tertiary Board spec"> -->
                    <div class="form-group selectDrop boardCertiSet inputPadding">
                        <ng-select
                          [items]="specialtyTer"
                          bindLabel="name"
                          placeholder="Select Specialty"
                          bindValue="id"
                          formControlName="948"
                        >
                        </ng-select>
                    </div>
                </div>
            </td>
            <td colspan="9">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label style="font-weight: bold;">BOARD CERTIFIED?</label>
                    <label class="radio-inline radioStyle">
                    <input type="radio" id="cmd_046" name="cmd_046" formControlName="cmd_046" value="1">Yes</label>
                    <label class="radio-inline radioStyle">
                    <input type="radio" id="cmd_046" name="cmd_046" formControlName="cmd_046" value="0">No</label>
                    <br/>
                    <div class="col-md-12">
                        <label class="formLabel">Name of Certifying Board:</label>
                        <!-- <input style="width:80%;" class="form-control onboard"  id="946" name="946" formControlName="946" type="text" data-id="Tertiary Board"> -->
                        <div class="form-group selectDrop boardCertiSet inputPadding col-md-5">
                            <ng-select
                                [items]="boardCertification"
                                bindLabel="name"
                                placeholder="Select Board Certification"
                                bindValue="id"
                                formControlName="946"
                            >
                            </ng-select>
                        </div>
                    </div>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="6">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">INITIAL CERTIFICATION DATE (MM/DD/YYYY)
                    </label>
                    <input style="width:80%;" class="form-control onboard datepicker" (blur)="clearInputIfInvalid('953')" ngbDatepicker #d35="ngbDatepicker" placeholder="MM/DD/YYYY" id="953" name="953" formControlName="953" type="text" data-id="Tertiary Board cert">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d35.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                    </div>
                    <div  *ngIf="texas.get('953').invalid ">
                        <small class="text-danger" *ngIf="texas.get('953').errors?.invalidDateFormat">
                          Only Date Format is allowed(MM/DD/YYYY)
                        </small>
                        <small class="text-danger" *ngIf="texas.get('953').errors?.invalidMonth">
                        Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="texas.get('953').errors?.invalidDateyear">
                          year is out of range(1900-2099)                      </small>
                          <small class="text-danger" *ngIf="texas.get('953').errors?.invalidDateRange">
                            date is out of range
                            </small>
                        </div>
                </div>
            </td>
            <td colspan="6">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">RECERTIFICATION DATE(S), IF APPLICABLE (MM/DD/YYYY) </label>
                    <input style="width:80%;" class="form-control onboard datepicker" (blur)="clearInputIfInvalid('950')" ngbDatepicker #d36="ngbDatepicker" placeholder="MM/DD/YYYY" id="950" name="950" formControlName="950" type="text" data-id="Tertiary Board recert 2">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d36.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                    </div>
                    <div  *ngIf="texas.get('950').invalid ">
                        <small class="text-danger" *ngIf="texas.get('950').errors?.invalidDateFormat">
                          Only Date Format is allowed(MM/DD/YYYY)
                        </small>
                        <small class="text-danger" *ngIf="texas.get('950').errors?.invalidMonth">
                        Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="texas.get('950').errors?.invalidDateyear">
                          year is out of range(1900-2099)                      </small>
                          <small class="text-danger" *ngIf="texas.get('950').errors?.invalidDateRange">
                            date is out of range
                            </small>
                        </div>
                </div>
            </td>
            <td colspan="6">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">EXPIRATION DATE, IF APPLICABLE (MM/DD/YYYY)
                    </label>
                    <input style="width:80%;" class="form-control onboard datepicker" (blur)="clearInputIfInvalid('954')" ngbDatepicker #d37="ngbDatepicker" placeholder="MM/DD/YYYY" id="954" name="954" formControlName="954" type="text" data-id="Tertiary Board exp">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d37.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                    </div>
                    <div  *ngIf="texas.get('954').invalid ">
                        <small class="text-danger" *ngIf="texas.get('954').errors?.invalidDateFormat">
                          Only Date Format is allowed(MM/DD/YYYY)
                        </small>
                        <small class="text-danger" *ngIf="texas.get('954').errors?.invalidMonth">
                        Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="texas.get('954').errors?.invalidDateyear">
                          year is out of range(1900-2099)                      </small>
                          <small class="text-danger" *ngIf="texas.get('954').errors?.invalidDateRange">
                            date is out of range
                            </small>
                        </div>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <div class="col-md-8">
                    <label class="formLabel">IF NOT BOARD CERTIFIED, INDICATE ANY OF THE FOLLOWING THAT APPLY.</label>
                    <br/>
                    </div>
                    <div class="col-md-12">
                    <label class="boldParagraph" class="checkbox-inline radioStyle">
                    <input type="checkbox" id="cmd_047" name="cmd_047" formControlName="cmd_047"  value="1"> I have taken exam, results pending for Board
                    <input style="width: 50%" class="form-control" id="cmd_051" name="cmd_051" formControlName="cmd_051" type="text" data-id="test7"></label>
                    </div>
                    <br/>
                    <div class="col-md-12">
                    <label class="boldParagraph" class="checkbox-inline radioStyle">
                    <input type="checkbox" id="cmd_048" name="cmd_048" formControlName="cmd_048" value="1"> I have taken Part I and am eligible for Part II of the Exam.
                    <input style="width: 50%" class="form-control" id="cmd_052" name="cmd_052" formControlName="cmd_052" type="text" data-id="test8"></label>
                    </div>
                    <br/>
                    <div class="col-md-12">
                    <label class="boldParagraph" class="checkbox-inline radioStyle">
                    <input type="checkbox" id="cmd_049" name="cmd_049" formControlName="cmd_049" value="1">I am intending to sit for the Boards on (date)
                    <input style="width: 50%" class="form-control" id="cmd_053" name="cmd_053" formControlName="cmd_053" type="text" data-id="test9"></label>
                    </div>
                    <br/>
                    <div class="col-md-12">
                    <label class="boldParagraph" class="checkbox-inline radioStyle">
                    <input type="checkbox" id="cmd_050" name="cmd_050" formControlName="cmd_050" value="1"> I am not planning to take Boards</label>
                    </div>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="formLabel">DO YOU WISH TO BE LISTED IN THE DIRECTORY UNDER THIS SPECIALTY? </label>
                        <br/>
                    <label class="formLabel"><b>HMO:</b></label>
                    <label class="boldParagraph" class="checkbox-inline radioStyle">
                    <input type="radio" id="cmd_054" name="cmd_054" formControlName="cmd_054" value="1"> Yes</label>
                    <label class="boldParagraph" class="checkbox-inline radioStyle">
                    <input type="radio" id="cmd_054" name="cmd_054" formControlName="cmd_054" value="0">  No </label>
                    <br/>
                    <label class="formLabel"><b>PPO:</b></label>
                    <label class="boldParagraph" class="checkbox-inline radioStyle">
                    <input type="radio" id="cmd_055" name="cmd_055" formControlName="cmd_055" value="1"> Yes</label>
                    <label class="boldParagraph" class="checkbox-inline radioStyle">
                    <input type="radio" id="cmd_055" name="cmd_055" formControlName="cmd_055" value="0"> No </label>
                    <br/>
                    <label class="formLabel"><b>POS:</b></label>
                    <label class="boldParagraph" class="checkbox-inline radioStyle">

                    <input type="radio" id="cmd_056" name="cmd_056" formControlName="cmd_056" value="1">Yes</label>
                    <label class="boldParagraph" class="checkbox-inline radioStyle">
                    <input type="radio" id="cmd_056" name="cmd_056" formControlName="cmd_056" value="0">  No </label>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="checkbox-inline radioStyle">PLEASE LIST OTHER AREAS OF PROFESSIONAL PRACTICE INTEREST OR FOCUS (HIV/AIDS, ETC.) </label>
                    <input class="form-control onboard " id="cmd_057" name="cmd_057" formControlName="cmd_057" type="text" data-id="other interest">
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="14">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="checkbox-inline radioStyle"><b>Work History.</b></label>- Please provide a chronological work history. You may submit a Cu rriculum Vitae as
                    a supplement. Please explain all gaps in employment that lasted more than six months
                </div>
            </td>
            <td colspan="4">
                <label class="texasFloat">Does Not Apply</label>
                <input type="checkbox" name="cmd_0727" id="cmd_0727" formControlName="cmd_0727" value="1" (change)="workHistApp($event)" class="texasFloat">
            </td>
        </tr>
        <tr>
            <td colspan="8">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">CURRENT PRACTICE/EMPLOYER NAME
                    <input class="form-control onboard " [class.is-invalid]="(texas.controls['445'].touched && texas.controls['445'].errors) || (texas.controls['445'].errors && isSubmit)" id="445" name="445" formControlName="445" type="text" data-id="wkhist1">
                    <div class="formLabel col-md-11"
                        *ngIf="texas.controls['445'].touched && texas.controls['445'].errors">
                        <small class="text-danger" *ngIf="texas.controls['445'].errors">
                            required
                        </small>
                    </div>
                    </label>
                </div>
            </td>
            <td colspan="10">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">START DATE/END DATE (MM/DD/YYYY TO MM/DD/YYYY)
                    </label>
                    <input class="form-control onboard datepicker" (blur)="clearInputIfInvalidMandi('454')" ngbDatepicker #d38="ngbDatepicker" [class.is-invalid]="(texas.controls['454'].touched && texas.controls['454'].errors) || (texas.controls['454'].errors && isSubmit)" placeholder="MM/DD/YYYY" id="454" name="454" formControlName="454" type="text" data-id="wk1histstartdt">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d38.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                    </div>
                    <div class="formLabel col-md-11"
                        *ngIf="texas.controls['454'].touched && texas.controls['454'].errors">
                        <small class="text-danger" *ngIf="texas.controls['454'].errors">
                            required
                        </small>
                    </div>
                    <small class="text-danger" *ngIf="texas.get('454').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)                </small>
                      <small class="text-danger" *ngIf="texas.get('454').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="texas.get('454').errors?.invalidDateyear">
                        year is out of range(1900-2099)                  </small>
                        <small class="text-danger" *ngIf="texas.get('454').errors?.invalidDateRange">
                          date is out of range
                          </small>
                    <input class="form-control onboard datepicker" (blur)="clearInputIfInvalidMandi('455')" ngbDatepicker #d39="ngbDatepicker" [class.is-invalid]="(texas.controls['455'].touched && texas.controls['455'].errors) || (texas.controls['455'].errors && isSubmit)" placeholder="MM/DD/YYYY" id="455" name="455" formControlName="455" type="text" data-id="wk1histenddt">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d39.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                    </div>
                    <div class="formLabel col-md-11"
                        *ngIf="texas.controls['455'].touched && texas.controls['455'].errors">
                        <small class="text-danger" *ngIf="texas.controls['455'].errors">
                            required
                        </small>
                    </div>
                    <small class="text-danger" *ngIf="texas.get('455').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)                </small>
                      <small class="text-danger" *ngIf="texas.get('455').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="texas.get('455').errors?.invalidDateyear">
                        year is out of range(1900-2099)                  </small>
                        <small class="text-danger" *ngIf="texas.get('455').errors?.invalidDateRange">
                          date is out of range
                          </small>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">ADDRESS</label>
                    <input style="width: 40%;margin-bottom: 2px;" [class.is-invalid]="(texas.controls['446'].touched && texas.controls['446'].errors) || (texas.controls['446'].errors && isSubmit)" class="form-control onboard " placeholder="Address 1" id="446" name="446" formControlName="446" type="text" data-id="wkhist1add">
                    <div class="formLabel col-md-11"
                        *ngIf="texas.controls['446'].touched && texas.controls['446'].errors">
                        <small class="text-danger" *ngIf="texas.controls['446'].errors">
                            required
                        </small>
                    </div>
                    <input style="width: 40%;margin-bottom: 2px;" [class.is-invalid]="(texas.controls['447'].touched && texas.controls['447'].errors) || (texas.controls['447'].errors && isSubmit)" class="form-control onboard " placeholder="Address 2" id="447" name="447" formControlName="447" type="text" data-id="wkhist1add2">
                    <div class="formLabel col-md-11"
                        *ngIf="texas.controls['447'].touched && texas.controls['447'].errors">
                        <small class="text-danger" *ngIf="texas.controls['447'].errors">
                            required
                        </small>
                    </div>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18">
                <div class="form-group form-inline no-margin col-md-3 sideLabelAlignment">
                    <label class="normalParagraph">CITY</label>
                    <input style="width: 100%" placeholder="City" [class.is-invalid]="(texas.controls['448'].touched && texas.controls['448'].errors) || (texas.controls['448'].errors && isSubmit)" class="form-control onboard " id="448" name="448" formControlName="448" type="text" data-id="Work hist 1 city">
                    <div class="formLabel col-md-11"
                        *ngIf="texas.controls['448'].touched && texas.controls['448'].errors">
                        <small class="text-danger" *ngIf="texas.controls['448'].errors">
                            required
                        </small>
                    </div>
                </div>
                <div class="form-group form-inline no-margin col-md-3 sideLabelAlignment">
                    <label class="normalParagraph">STATE/COUNTRY</label>
                    <div class="form-group selectDrop dropDrownSet degree col-md-6">
                        <ng-select [items]="states" [class.is-invalid]="(texas.controls['450'].touched && texas.controls['450'].errors) || (texas.controls['450'].errors && isSubmit)" bindLabel="name" placeholder="Select States" bindValue="id" formControlName="450">
                        </ng-select>
                        <div class="formLabel col-md-11"
                            *ngIf="texas.controls['450'].touched && texas.controls['450'].errors">
                            <small class="text-danger" *ngIf="texas.controls['450'].errors">
                                required
                            </small>
                        </div>
                    </div>
                </div>
                <div class="form-group form-inline no-margin col-md-3 sideLabelAlignment">
                    <label class="normalParagraph">POSTAL CODE</label>
                    <input style="width: 100%" [class.is-invalid]="(texas.controls['451'].touched && texas.controls['451'].errors) || (texas.controls['451'].errors && isSubmit)" class="form-control onboard " placeholder="Zip" id="451" name="451" formControlName="451" type="text" data-id="Work hist 1 zip">
                    <div class="formLabel col-md-11"
                        *ngIf="texas.controls['451'].touched && texas.controls['451'].errors">
                        <small class="text-danger" *ngIf="texas.controls['451'].errors">
                            required
                        </small>
                        <small class="text-danger" *ngIf="texas.controls['451'].errors?.minlength || texas.controls['451'].errors?.maxlength">
                          Zip code must be atleast 5 digits and less than 10 digits
                      </small>
                    </div>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="8">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">PREVIOUS PRACTICE/EMPLOYER NAME
                    <input class="form-control onboard " id="456" name="456" formControlName="456" type="text" data-id="wkhist2">
                    </label>
                </div>
            </td>
            <td colspan="10">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">START DATE/END DATE (MM/DD/YYYY TO MM/DD/YYYY)
                    </label>
                    <input class="form-control onboard datepicker" (blur)="clearInputIfInvalid('465')" placeholder="MM/DD/YYYY" ngbDatepicker #d40="ngbDatepicker" id="465" name="465" formControlName="465" type="text" data-id="wkhist2startdt">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d40.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                    </div>
                    <div  *ngIf="texas.get('465').invalid ">
                        <small class="text-danger" *ngIf="texas.get('465').errors?.invalidDateFormat">
                          Only Date Format is allowed(MM/DD/YYYY)
                        </small>
                        <small class="text-danger" *ngIf="texas.get('465').errors?.invalidMonth">
                        Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="texas.get('465').errors?.invalidDateyear">
                          year is out of range(1900-2099)                      </small>
                          <small class="text-danger" *ngIf="texas.get('465').errors?.invalidDateRange">
                            date is out of range
                            </small>
                        </div>
                    <input class="form-control onboard datepicker" (blur)="clearInputIfInvalid('466')" placeholder="MM/DD/YYYY" ngbDatepicker #d41="ngbDatepicker" id="466" name="466" formControlName="466" type="text" data-id="wkhist2enddt">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d41.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                    </div>
                    <div  *ngIf="texas.get('466').invalid ">
                        <small class="text-danger" *ngIf="texas.get('466').errors?.invalidDateFormat">
                          Only Date Format is allowed(MM/DD/YYYY)
                        </small>
                        <small class="text-danger" *ngIf="texas.get('466').errors?.invalidMonth">
                        Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="texas.get('466').errors?.invalidDateyear">
                          year is out of range(1900-2099)                      </small>
                          <small class="text-danger" *ngIf="texas.get('466').errors?.invalidDateRange">
                            date is out of range
                            </small>
                        </div>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">ADDRESS</label>
                    <input style="width: 40%;margin-bottom: 2px;" class="form-control onboard " placeholder="Address 1" id="457" name="457" formControlName="457" type="text" data-id="wkhist2add">
                    <input style="width: 40%;margin-bottom: 2px;" class="form-control onboard " placeholder="Address 2" id="458" name="458" formControlName="458" type="text" data-id="wkhist2address2">
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18">
                <div class="form-group form-inline no-margin col-md-3 sideLabelAlignment">
                    <label class="normalParagraph">CITY</label>
                    <input style="width: 100%" placeholder="City" class="form-control onboard " id="459" name="459" formControlName="459" type="text" data-id="Work hist 2 city">
                </div>
                <div class="form-group form-inline no-margin col-md-3 sideLabelAlignment">
                    <label class="normalParagraph">STATE/COUNTRY</label>
                    <div class="form-group selectDrop dropDrownSet degree col-md-6">
                        <ng-select [items]="states" [class.is-invalid]="(texas.controls['461'].touched && texas.controls['461'].errors) || (texas.controls['461'].errors && isSubmit)" bindLabel="name" placeholder="Select States" bindValue="id" formControlName="461">
                        </ng-select>
                        <div class="formLabel col-md-11"
                            *ngIf="texas.controls['461'].touched && texas.controls['461'].errors">
                            <small class="text-danger" *ngIf="texas.controls['461'].errors">
                                required
                            </small>
                        </div>
                    </div>
                </div>
                <div class="form-group form-inline no-margin col-md-3 sideLabelAlignment">
                    <label class="normalParagraph">POSTAL CODE</label>
                    <input style="width: 100%"  class="form-control onboard " placeholder="Zip" id="462" name="462" formControlName="462" type="text" data-id="Work hist 2 zip">
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">REASON FOR DISCONTINUANCE </label>
                    <input class="form-control onboard " id="1338" name="1338" formControlName="1338" type="text" data-id="wkhist2reasonforleaving">
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="8">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">PREVIOUS PRACTICE/EMPLOYER NAME
                    <input class="form-control onboard " id="467" name="467" formControlName="467" type="text" data-id="wkhist3">
                    </label>
                </div>
            </td>
            <td colspan="10">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">START DATE/END DATE (MM/DD/YYYY TO MM/DD/YYYY)
                    </label>
                    <input class="form-control onboard datepicker" (blur)="clearInputIfInvalid('476')" placeholder="MM/DD/YYYY" ngbDatepicker #d42="ngbDatepicker" id="476" name="476" formControlName="476" type="text" data-id="wkhist3startdt">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d42.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                    </div>
                    <div  *ngIf="texas.get('476').invalid ">
                        <small class="text-danger" *ngIf="texas.get('476').errors?.invalidDateFormat">
                          Only Date Format is allowed(MM/DD/YYYY)
                        </small>
                        <small class="text-danger" *ngIf="texas.get('476').errors?.invalidMonth">
                        Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="texas.get('476').errors?.invalidDateyear">
                          year is out of range(1900-2099)                      </small>
                          <small class="text-danger" *ngIf="texas.get('476').errors?.invalidDateRange">
                            date is out of range
                            </small>
                        </div>
                    <input class="form-control onboard datepicker" (blur)="clearInputIfInvalid('477')" placeholder="MM/DD/YYYY" ngbDatepicker #d43="ngbDatepicker" id="477" name="477" formControlName="477" type="text" data-id="wkhist3enddt">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d43.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                    </div>
                    <div  *ngIf="texas.get('477').invalid ">
                        <small class="text-danger" *ngIf="texas.get('477').errors?.invalidDateFormat">
                          Only Date Format is allowed(MM/DD/YYYY)
                        </small>
                        <small class="text-danger" *ngIf="texas.get('477').errors?.invalidMonth">
                        Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="texas.get('477').errors?.invalidDateyear">
                          year is out of range(1900-2099)                      </small>
                          <small class="text-danger" *ngIf="texas.get('477').errors?.invalidDateRange">
                            date is out of range
                            </small>
                        </div>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">ADDRESS</label>
                    <input style="width: 40%;margin-bottom: 2px;" class="form-control onboard " placeholder="Address 1" id="468" name="468" formControlName="468" type="text" data-id="wkhist3add">
                    <input style="width: 40%;margin-bottom: 2px;" class="form-control onboard " placeholder="Address 2" id="469" name="469" formControlName="469" type="text" data-id="wkhist3address2">
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18">
                <div class="form-group form-inline no-margin col-md-3 sideLabelAlignment">
                    <label class="normalParagraph">CITY</label>
                    <input style="width: 100%" placeholder="City" class="form-control onboard " id="470" name="470" formControlName="470" type="text" data-id="Work hist 3 city">
                </div>
                <div class="form-group form-inline no-margin col-md-3 sideLabelAlignment">
                    <label class="normalParagraph">STATE/COUNTRY</label>
                    <div class="form-group selectDrop dropDrownSet degree col-md-6">
                        <ng-select [items]="states" [class.is-invalid]="(texas.controls['472'].touched && texas.controls['472'].errors) || (texas.controls['472'].errors && isSubmit)" bindLabel="name" placeholder="Select States" bindValue="id" formControlName="472">
                        </ng-select>
                        <div class="formLabel col-md-11"
                            *ngIf="texas.controls['472'].touched && texas.controls['472'].errors">
                            <small class="text-danger" *ngIf="texas.controls['472'].errors">
                                required
                            </small>
                        </div>
                    </div>
                </div>
                <div class="form-group form-inline no-margin col-md-3 sideLabelAlignment">
                    <label class="normalParagraph">POSTAL CODE</label>
                    <input style="width: 100%"  class="form-control onboard " placeholder="Zip" id="473" name="473" formControlName="473" type="text" data-id="Work hist 3 zip">
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">REASON FOR DISCONTINUANCE </label>
                    <input class="form-control onboard " id="1342" name="1342" formControlName="1342" type="text" data-id="wkhist3reasonforleaving">
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="8">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">PREVIOUS PRACTICE/EMPLOYER NAME
                    <input class="form-control onboard " id="1935" name="1935" formControlName="1935" type="text" data-id="wkhist4">
                    </label>
                </div>
            </td>
            <td colspan="10">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">START DATE/END DATE (MM/DD/YYYY TO MM/DD/YYYY)
                    </label>
                    <input class="form-control onboard datepicker" (blur)="clearInputIfInvalid('1947')" placeholder="MM/DD/YYYY" ngbDatepicker #d44="ngbDatepicker" id="1947" name="1947" formControlName="1947" type="text" data-id="wkhist4startdt">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d44.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                    </div>
                    <div  *ngIf="texas.get('1947').invalid ">
                        <small class="text-danger" *ngIf="texas.get('1947').errors?.invalidDateFormat">
                          Only Date Format is allowed(MM/DD/YYYY)
                        </small>
                        <small class="text-danger" *ngIf="texas.get('1947').errors?.invalidMonth">
                        Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="texas.get('1947').errors?.invalidDateyear">
                          year is out of range(1900-2099)                      </small>
                          <small class="text-danger" *ngIf="texas.get('1947').errors?.invalidDateRange">
                            date is out of range
                            </small>
                        </div>
                    <input class="form-control onboard datepicker" (blur)="clearInputIfInvalid('1948')" placeholder="MM/DD/YYYY" ngbDatepicker #d45="ngbDatepicker" id="1948" name="1948" formControlName="1948" type="text" data-id="wkhist4enddt">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d45.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                    </div>
                    <div  *ngIf="texas.get('1948').invalid ">
                        <small class="text-danger" *ngIf="texas.get('1948').errors?.invalidDateFormat">
                          Only Date Format is allowed(MM/DD/YYYY)
                        </small>
                        <small class="text-danger" *ngIf="texas.get('1948').errors?.invalidMonth">
                        Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="texas.get('1948').errors?.invalidDateyear">
                          year is out of range(1900-2099)                      </small>
                          <small class="text-danger" *ngIf="texas.get('1948').errors?.invalidDateRange">
                            date is out of range
                            </small>
                        </div>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">ADDRESS</label>
                    <input style="width: 40%;margin-bottom: 2px;" class="form-control onboard " placeholder="Address 1" id="1936" name="1936" formControlName="1936" type="text" data-id="wkhist4add">
                    <input style="width: 40%;margin-bottom: 2px;" class="form-control onboard " placeholder="Address 2" id="1937" name="1937" formControlName="1937" type="text" data-id="wkhist4address2">
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18">
                <div class="form-group form-inline no-margin col-md-3 sideLabelAlignment">
                    <label class="normalParagraph">CITY</label>
                    <input style="width: 100%" placeholder="City" class="form-control onboard " id="1938" name="1938" formControlName="1938" type="text" data-id="Work hist 4 city">
                </div>
                <div class="form-group form-inline no-margin col-md-3 sideLabelAlignment">
                    <label class="normalParagraph">STATE/COUNTRY</label>
                    <div class="form-group selectDrop dropDrownSet degree col-md-6">
                        <ng-select [items]="states" bindLabel="name" placeholder="Select States" bindValue="id" formControlName="1940">
                        </ng-select>
                    </div>
                </div>
                <div class="form-group form-inline no-margin col-md-3 sideLabelAlignment">
                    <label class="normalParagraph">POSTAL CODE</label>
                    <input style="width: 100%" class="form-control onboard " placeholder="Zip" id="1941" name="1941" formControlName="1941" type="text" data-id="Work hist 4 zip">
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">REASON FOR DISCONTINUANCE </label>
                    <input class="form-control onboard " id="1952" name="1952" formControlName="1952" type="text" data-id="wkhist4reasonforleaving">
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18" width="60%">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">
                    PLEASE PROVIDE AN EXPLANATION FOR ANY GAPS GREATER THAN SIX MONTHS (MM/DD/YYYY TO MM/DD/YYYY) IN WORK HISTORY.  </label>
                </div>
                <div class="form-group form-inline no-margin col-md-6 sideLabelAlignment">
                    <label class="normalParagraph">Gap Dates: </label>
                    <input style="width: 30%" class="form-control onboard datepicker" (blur)="clearInputIfInvalid('3395')" ngbDatepicker #d46="ngbDatepicker" placeholder="MM/DD/YYYY" id="3395" name="3395" formControlName="3395" type="text" data-id="Gap from 1">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d46.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                    </div>
                    <div  *ngIf="texas.get('3395').invalid ">
                        <small class="text-danger" *ngIf="texas.get('3395').errors?.invalidDateFormat">
                          Only Date Format is allowed(MM/DD/YYYY)
                        </small>
                        <small class="text-danger" *ngIf="texas.get('3395').errors?.invalidMonth">
                        Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="texas.get('3395').errors?.invalidDateyear">
                          year is out of range(1900-2099)                      </small>
                          <small class="text-danger" *ngIf="texas.get('3395').errors?.invalidDateRange">
                            date is out of range
                            </small>
                        </div>
                    <input style="width: 30%" class="form-control onboard datepicker" (blur)="clearInputIfInvalid('3396')" ngbDatepicker #d47="ngbDatepicker" placeholder="MM/DD/YYYY" id="3396" name="3396" formControlName="3396" type="text" data-id="Gap to 1">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d47.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                    </div>
                    <div  *ngIf="texas.get('3396').invalid ">
                        <small class="text-danger" *ngIf="texas.get('3396').errors?.invalidDateFormat">
                          Only Date Format is allowed(MM/DD/YYYY)
                        </small>
                        <small class="text-danger" *ngIf="texas.get('3396').errors?.invalidMonth">
                        Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="texas.get('3396').errors?.invalidDateyear">
                          year is out of range(1900-2099)                      </small>
                          <small class="text-danger" *ngIf="texas.get('3396').errors?.invalidDateRange">
                            date is out of range
                            </small>
                        </div>
                </div>
                <div class="form-group form-inline no-margin col-md-6 sideLabelAlignment">
                    <label class="normalParagraph">Explanation: </label>
                    <input style="width: 50%" class="form-control onboard " id="1138" name="1138" formControlName="1138" type="text" data-id="Gap 1">
                </div>
                <div class="form-group form-inline no-margin col-md-6 sideLabelAlignment">
                    <label class="normalParagraph">Gap Dates:</label>
                    <input style="width: 30%" class="form-control onboard datepicker" (blur)="clearInputIfInvalid('3397')" ngbDatepicker #d48="ngbDatepicker" placeholder="MM/DD/YYYY" id="3397" name="3397" formControlName="3397" type="text" data-id="Gap from 2">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d48.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                    </div>
                    <div  *ngIf="texas.get('3397').invalid ">
                        <small class="text-danger" *ngIf="texas.get('3397').errors?.invalidDateFormat">
                          Only Date Format is allowed(MM/DD/YYYY)
                        </small>
                        <small class="text-danger" *ngIf="texas.get('3397').errors?.invalidMonth">
                        Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="texas.get('3397').errors?.invalidDateyear">
                          year is out of range(1900-2099)                      </small>
                          <small class="text-danger" *ngIf="texas.get('3397').errors?.invalidDateRange">
                            date is out of range
                            </small>
                        </div>
                    <input style="width: 30%" class="form-control onboard datepicker" (blur)="clearInputIfInvalid('3398')" ngbDatepicker #d49="ngbDatepicker" placeholder="MM/DD/YYYY" id="3398" name="3398" formControlName="3398" type="text" data-id="Gap to 2">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d49.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                    </div>
                    <div  *ngIf="texas.get('3398').invalid ">
                        <small class="text-danger" *ngIf="texas.get('3398').errors?.invalidDateFormat">
                          Only Date Format is allowed(MM/DD/YYYY)
                        </small>
                        <small class="text-danger" *ngIf="texas.get('3398').errors?.invalidMonth">
                        Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="texas.get('3398').errors?.invalidDateyear">
                          year is out of range(1900-2099)                      </small>
                          <small class="text-danger" *ngIf="texas.get('3398').errors?.invalidDateRange">
                            date is out of range
                            </small>
                        </div>
                </div>
                <div class="form-group form-inline no-margin col-md-6 sideLabelAlignment">
                    <label class="normalParagraph">
                    Explanation: </label>
                    <input style="width: 50%" class="form-control onboard " id="1831" name="1831" formControlName="1831" type="text" data-id="Gap 2">
                </div>
                <div class="form-group form-inline no-margin col-md-6 sideLabelAlignment">
                    <label class="normalParagraph">Gap Dates:</label>
                    <input style="width: 30%" class="form-control onboard datepicker" (blur)="clearInputIfInvalid('3399')" ngbDatepicker #d50="ngbDatepicker" placeholder="MM/DD/YYYY" id="3399" name="3399" formControlName="3399" type="text" data-id="Gap from 3.0">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d50.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                    </div>
                    <div  *ngIf="texas.get('3399').invalid ">
                        <small class="text-danger" *ngIf="texas.get('3399').errors?.invalidDateFormat">
                          Only Date Format is allowed(MM/DD/YYYY)
                        </small>
                        <small class="text-danger" *ngIf="texas.get('3399').errors?.invalidMonth">
                        Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="texas.get('3399').errors?.invalidDateyear">
                          year is out of range(1900-2099)                      </small>
                          <small class="text-danger" *ngIf="texas.get('3399').errors?.invalidDateRange">
                            date is out of range
                            </small>
                        </div>
                    <input style="width: 30%" class="form-control onboard datepicker" (blur)="clearInputIfInvalid('3400')" ngbDatepicker #d51="ngbDatepicker" placeholder="MM/DD/YYYY" id="3400" name="3400" formControlName="3400" type="text" data-id="Gap to 3.0">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d51.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                    </div>
                    <div  *ngIf="texas.get('3400').invalid ">
                        <small class="text-danger" *ngIf="texas.get('3400').errors?.invalidDateFormat">
                          Only Date Format is allowed(MM/DD/YYYY)
                        </small>
                        <small class="text-danger" *ngIf="texas.get('3400').errors?.invalidMonth">
                        Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="texas.get('3400').errors?.invalidDateyear">
                          year is out of range(1900-2099)                      </small>
                          <small class="text-danger" *ngIf="texas.get('3400').errors?.invalidDateRange">
                            date is out of range
                            </small>
                        </div>
                </div>
                <div class="form-group form-inline no-margin col-md-6 sideLabelAlignment">
                    <label class="normalParagraph">
                    Explanation: </label>
                    <input style="width: 50%" class="form-control onboard " id="1832" name="1832" formControlName="1832" type="text" data-id="Gap 3.0">
                </div>
                <div class="form-group form-inline no-margin col-md-6 sideLabelAlignment">
                    <label class="normalParagraph">Gap Dates:</label>
                    <input style="width: 30%" class="form-control onboard datepicker" (blur)="clearInputIfInvalid('3401')" ngbDatepicker #d52="ngbDatepicker" placeholder="MM/DD/YYYY" id="3401" name="3401" formControlName="3401" type="text" data-id="Gap from 3.1">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d52.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                    </div>
                    <div  *ngIf="texas.get('3401').invalid ">
                        <small class="text-danger" *ngIf="texas.get('3401').errors?.invalidDateFormat">
                          Only Date Format is allowed(MM/DD/YYYY)
                        </small>
                        <small class="text-danger" *ngIf="texas.get('3401').errors?.invalidMonth">
                        Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="texas.get('3401').errors?.invalidDateyear">
                          year is out of range(1900-2099)                      </small>
                          <small class="text-danger" *ngIf="texas.get('3401').errors?.invalidDateRange">
                            date is out of range
                            </small>
                        </div>
                    <input style="width: 30%" class="form-control onboard datepicker" (blur)="clearInputIfInvalid('3402')" ngbDatepicker #d53="ngbDatepicker" placeholder="MM/DD/YYYY" id="3402" name="3402" formControlName="3402" type="text" data-id="Gap to 3.1">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d53.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                    </div>
                    <div  *ngIf="texas.get('3402').invalid ">
                        <small class="text-danger" *ngIf="texas.get('3402').errors?.invalidDateFormat">
                          Only Date Format is allowed(MM/DD/YYYY)
                        </small>
                        <small class="text-danger" *ngIf="texas.get('3402').errors?.invalidMonth">
                        Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="texas.get('3402').errors?.invalidDateyear">
                          year is out of range(1900-2099)                      </small>
                          <small class="text-danger" *ngIf="texas.get('3402').errors?.invalidDateRange">
                            date is out of range
                            </small>
                        </div>
                </div>
                <div class="form-group form-inline no-margin col-md-6 sideLabelAlignment">
                    <label class="normalParagraph">
                    Explanation: </label>
                    <input style="width: 50%" class="form-control onboard " id="1953" name="1953" formControlName="1953" type="text" data-id="Gap 3.1">
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="checkbox-inline radioStyle">
                    <input type="checkbox" id="cmd_058" name="cmd_058" formControlName="cmd_058" value="1">Please check this box and complete and submit Attachment C if you have additional work history </label>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="14">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="checkbox-inline radioStyle"><b>Hospital Affiliations</b></label>-Please include all hospitals where you currently have or have previously had privileges.
                </div>
            </td>
            <td colspan="4">
                <label class="texasFloat">Does Not Apply</label>
                <input type="checkbox"  name="cmd_0723" id="cmd_0723" formControlName="cmd_0723" value="1" (change)="hospAffApp($event)" class="texasFloat">
            </td>
        </tr>
        <tr>
            <td colspan="9">
                <div class="form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">DO YOU HAVE HOSPITAL PRIVILEGES? </label>
                    <label class="checkbox-inline radioStyle">
                    <input type="radio" id="cmd_059" name="cmd_059" formControlName="cmd_059" value="1">Yes</label>
                    <label class="checkbox-inline radioStyle">
                    <input type="radio" id="cmd_059" name="cmd_059" formControlName="cmd_059" value="0">No</label>
                </div>
            </td>
            <td colspan="9">
                <div class="form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">IF YOU DO NOT HAVE ADMITTING PRIVILEGES, WHAT ADMITTING ARRANGEMENTS DO YOU HAVE? </label>
                    <input class="form-control onboard " id="cmd_060" name="cmd_060" formControlName="cmd_060" type="text" data-id="hospaff1arrangements">
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="9">
                <div class="form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">PRIMARY HOSPITAL WHERE YOU HAVE ADMITTING PRIVILEGES</label>
                    <input class="form-control onboard " id="418" name="418" [class.is-invalid]="(texas.controls['418'].touched && texas.controls['418'].errors) || (texas.controls['418'].errors && isSubmit)" formControlName="418" type="text" data-id="hospaff1nm">
                    <div class="formLabel col-md-11"
                        *ngIf="texas.controls['418'].touched && texas.controls['418'].errors">
                        <small class="text-danger" *ngIf="texas.controls['418'].errors">
                            required
                        </small>
                    </div>
                </div>
            </td>
            <td colspan="9">
                <div class="form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">START DATE (MM/DD/YYYY)  </label>
                    <input class="form-control onboard datepicker" (blur)="clearInputIfInvalid('426')" ngbDatepicker #d54="ngbDatepicker" [class.is-invalid]="(texas.controls['426'].touched && texas.controls['426'].errors) || (texas.controls['426'].errors && isSubmit)" placeholder="MM/DD/YYYY" id="426" name="426" formControlName="426" type="text" data-id="hospaff1app">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d54.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                    </div>
                    <div class="formLabel col-md-11"
                        *ngIf="texas.controls['426'].touched && texas.controls['426'].errors">
                        <small class="text-danger" *ngIf="texas.controls['426'].errors">
                            required
                        </small>
                    </div>
                    <small class="text-danger" *ngIf="texas.get('426').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)                </small>
                      <small class="text-danger" *ngIf="texas.get('426').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="texas.get('426').errors?.invalidDateyear">
                        year is out of range(1900-2099)                  </small>
                        <small class="text-danger" *ngIf="texas.get('426').errors?.invalidDateRange">
                          date is out of range
                          </small>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="checkbox-inline radioStyle">ADDRESS</label>
                    <input class="form-control onboard " [class.is-invalid]="(texas.controls['419'].touched && texas.controls['419'].errors) || (texas.controls['419'].errors && isSubmit)" id="419" name="419" formControlName="419" type="text" data-id="hospaff1add1">
                    <div class="formLabel col-md-11"
                        *ngIf="texas.controls['419'].touched && texas.controls['419'].errors">
                        <small class="text-danger" *ngIf="texas.controls['419'].errors">
                            required
                        </small>
                    </div>
                    <input class="form-control onboard " [class.is-invalid]="(texas.controls['420'].touched && texas.controls['420'].errors) || (texas.controls['420'].errors && isSubmit)" id="420" name="420" formControlName="420" type="text" data-id="hospaff1add2">
                    <div class="formLabel col-md-11"
                        *ngIf="texas.controls['420'].touched && texas.controls['420'].errors">
                        <small class="text-danger" *ngIf="texas.controls['420'].errors">
                            required
                        </small>
                    </div>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18">
                <div class="form-group form-inline no-margin col-md-3 sideLabelAlignment">
                    <label class="normalParagraph">CITY</label>
                    <input style="width: 100%" [class.is-invalid]="(texas.controls['421'].touched && texas.controls['421'].errors) || (texas.controls['421'].errors && isSubmit)" class="form-control onboard " placeholder="City" id="421" name="421" formControlName="421" type="text" data-id="hospaff1city">
                    <div class="formLabel col-md-11"
                        *ngIf="texas.controls['421'].touched && texas.controls['421'].errors">
                        <small class="text-danger" *ngIf="texas.controls['421'].errors">
                            required
                        </small>
                    </div>
                </div>
                <div class="form-group form-inline no-margin col-md-3 sideLabelAlignment">
                    <label class="normalParagraph">STATE/COUNTRY</label>
                    <div class="form-group selectDrop dropDrownSet degree col-md-6">
                        <ng-select [items]="states" [class.is-invalid]="(texas.controls['423'].touched && texas.controls['423'].errors) || (texas.controls['423'].errors && isSubmit)" bindLabel="name" placeholder="Select States" bindValue="id" formControlName="423">
                        </ng-select>
                        <div class="formLabel col-md-11"
                            *ngIf="texas.controls['423'].touched && texas.controls['423'].errors">
                            <small class="text-danger" *ngIf="texas.controls['423'].errors">
                                required
                            </small>
                        </div>
                    </div>
                </div>
                <div class="form-group form-inline no-margin col-md-3 sideLabelAlignment">
                    <label class="normalParagraph">POSTAL CODE</label>
                    <input style="width: 100%" class="form-control onboard " [class.is-invalid]="(texas.controls['424'].touched && texas.controls['424'].errors) || (texas.controls['424'].errors && isSubmit)" placeholder="Zip" id="424" name="424" formControlName="424" type="text" data-id="hospaff1zip">
                    <div class="formLabel col-md-11"
                        *ngIf="texas.controls['424'].touched && texas.controls['424'].errors">
                        <small class="text-danger" *ngIf="texas.controls['424'].errors">
                            required
                        </small>
                        <small class="text-danger" *ngIf="texas.controls['424'].errors?.minlength || texas.controls['424'].errors?.maxlength">
                          Zip code must be atleast 5 digits and less than 10 digits
                      </small>
                    </div>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="6">
                <div class="form-group form-inline no-margin col-md-3 sideLabelAlignment">
                    <label class="normalParagraph">PHONE NUMBER </label>
                    <input class="form-control onboard " [class.is-invalid]="(texas.controls['1305'].touched && texas.controls['1305'].errors) || (texas.controls['1305'].errors && isSubmit)" id="1305" name="1305" formControlName="1305" type="number" data-id="hospaff1phone">
                    <div class="formLabel col-md-11"
                        *ngIf="texas.controls['1305'].touched && texas.controls['1305'].errors">
                        <small class="text-danger" *ngIf="texas.controls['1305'].errors">
                            required
                        </small>
                    </div>
                </div>
            </td>
            <td colspan="6">
                <div class="form-group form-inline no-margin col-md-3 sideLabelAlignment">
                    <label class="normalParagraph">FAX</label>
                    <input class="form-control onboard " id="2136" name="2136" formControlName="2136" type="text" data-id="hospaff1fax">
                </div>
            </td>
            <td colspan="6">
                <div class="form-group form-inline no-margin col-md-3 sideLabelAlignment">
                    <label class="normalParagraph">E-MAIL </label>
                    <input class="form-control onboard " id="2142" name="2142" formControlName="2142" type="text" data-id="hospaff1email">
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="6">
                <div class="form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">FULL UNRESTRICTED PRIVILEGES?  </label>
                    <label class="checkbox-inline radioStyle">
                    <input type="radio" id="cmd_061" name="cmd_061" formControlName="cmd_061" value="1">Yes</label>
                    <label class="checkbox-inline radioStyle">
                    <input type="radio" id="cmd_061" name="cmd_061" formControlName="cmd_061" value="0">No</label>
                </div>
            </td>
            <td colspan="6">
                <div class="form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">TYPES OF PRIVILEGES (PROVISIONAL, LIMITED, CONDITIONAL, ETC.) </label>
                    <input class="form-control onboard " id="894" name="894" formControlName="894" type="text" data-id="hospaff1status">
                </div>
            </td>
            <td colspan="6">
                <div class="form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">ARE PRIVILEGES TEMPORARY? </label>
                    <label class="checkbox-inline radioStyle">
                    <input type="radio" id="cmd_062" name="cmd_062" formControlName="cmd_062" value="1">Yes</label>
                    <label class="checkbox-inline radioStyle">
                    <input type="radio" id="cmd_062" name="cmd_062" formControlName="cmd_062" value="0">No</label>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="checkbox-inline radioStyle">OF THE TOTAL NUMBER OF ADMISSIONS TO ALL HOSPITALS IN THE PAST YEAR, WHAT PERCENTAGE IS TO PRIMARY HOSPITAL? </label>
                    <input class="form-control onboard " id="cmd_063" name="cmd_063" formControlName="cmd_063" type="text" data-id="hospaff1adimissons">
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="6">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">OTHER HOSPITAL WHERE YOU HAVE PRIVILEGES </label>
                    <input class="form-control onboard " id="427" name="427" formControlName="427" type="text" data-id="hospaff2nm">
                </div>
            </td>
            <td colspan="12">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">START DATE (MM/DD/YYYY)  </label>
                    <div class="input-group">
                        <input style="width:30%;" class="form-control onboard datepicker" (blur)="clearInputIfInvalid('435')" placeholder="MM/DD/YYYY" ngbDatepicker #d55="ngbDatepicker" id="435" name="435" formControlName="435" type="text" data-id="hospaff2app">
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="d55.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                        </div>
                        <div  *ngIf="texas.get('435').invalid ">
                            <small class="text-danger" *ngIf="texas.get('435').errors?.invalidDateFormat">
                              Only Date Format is allowed(MM/DD/YYYY)
                            </small>
                            <small class="text-danger" *ngIf="texas.get('435').errors?.invalidMonth">
                            Month is out of range
                            </small>
                            <small class="text-danger" *ngIf="texas.get('435').errors?.invalidDateyear">
                              year is out of range(1900-2099)                      </small>
                              <small class="text-danger" *ngIf="texas.get('435').errors?.invalidDateRange">
                                date is out of range
                                </small>
                            </div>
                    </div>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="checkbox-inline radioStyle">ADDRESS</label>
                    <input class="form-control onboard " id="428" name="428" formControlName="428" type="text" data-id="hospaff2add.0">
                    <input class="form-control onboard " id="420" name="420" formControlName="420" type="text" data-id="hospaff2add.1">
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18">
                <div class="form-group form-inline no-margin col-md-3 sideLabelAlignment">
                    <label class="normalParagraph">CITY</label>
                    <input style="width: 100%" class="form-control onboard " id="430" name="430" formControlName="430" type="text" placeholder="City" data-id="hospaff2city">
                </div>
                <div class="form-group form-inline no-margin col-md-3 sideLabelAlignment">
                    <label class="normalParagraph">STATE/COUNTRY</label>
                    <div class="form-group selectDrop dropDrownSet degree col-md-6">
                        <ng-select [items]="states" [class.is-invalid]="(texas.controls['432'].touched && texas.controls['432'].errors) || (texas.controls['432'].errors && isSubmit)" bindLabel="name" placeholder="Select States" bindValue="id" formControlName="432">
                        </ng-select>
                        <div class="formLabel col-md-11"
                            *ngIf="texas.controls['432'].touched && texas.controls['432'].errors">
                            <small class="text-danger" *ngIf="texas.controls['432'].errors">
                                required
                            </small>
                        </div>
                    </div>
                </div>
                <div class="form-group form-inline no-margin col-md-3 sideLabelAlignment">
                    <label class="normalParagraph">POSTAL CODE</label>
                    <input style="width: 100%" class="form-control onboard "  id="433" name="433" formControlName="433" type="text" placeholder="Zip" data-id="hospaff2zip">

                </div>
            </td>
        </tr>
        <tr>
            <td colspan="6">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">PHONE NUMBER </label>
                    <input class="form-control onboard "  id="1307" name="1307" formControlName="1307" type="text" data-id="hospaff2phone">

                </div>
            </td>
            <td colspan="6">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">FAX</label>
                    <input class="form-control onboard "  id="2137" name="2137" formControlName="2137" type="text" data-id="hospaff2fax">

                </div>
            </td>
            <td colspan="6">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">E-MAIL</label>
                    <input class="form-control onboard " id="2143" name="2143" formControlName="2143" type="text" data-id="hospaff2email">
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="6">
                <div class="form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">FULL UNRESTRICTED PRIVILEGES?  </label>
                    <label class="checkbox-inline radioStyle">
                    <input type="radio" id="cmd_064" name="cmd_064" formControlName="cmd_064" value="1">Yes</label>
                    <label class="checkbox-inline radioStyle">
                    <input type="radio" id="cmd_064" name="cmd_064" formControlName="cmd_064" value="0">No</label>
                </div>
            </td>
            <td colspan="6">
                <div class="form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">TYPES OF PRIVILEGES (PROVISIONAL, LIMITED, CONDITIONAL, ETC.) </label>
                    <input class="form-control onboard " id="895" name="895" formControlName="895" type="text" data-id="hospaff2staus ">
                </div>
            </td>
            <td colspan="6">
                <div class="form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">ARE PRIVILEGES TEMPORARY? </label>
                    <label class="checkbox-inline radioStyle">
                    <input type="radio" id="cmd_065" name="cmd_065" formControlName="cmd_065" value="1">Yes</label>
                    <label class="checkbox-inline radioStyle">
                    <input type="radio" id="cmd_065" name="cmd_065" formControlName="cmd_065" value="0">No</label>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="checkbox-inline radioStyle">OF THE TOTAL NUMBER OF ADMISSIONS TO ALL HOSPITALS IN THE PAST YEAR, WHAT PERCENTAGE IS TO PRIMARY HOSPITAL? </label>
                    <input class="form-control onboard " id="cmd_066" name="cmd_066" formControlName="cmd_066" type="text" data-id="hospaff2admissons">
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="checkbox-inline radioStyle">
                    <input type="checkbox" id="cmd_067" name="cmd_067" formControlName="cmd_067" value="1">Please check this box and complete and submit Attachment D if you have additional current hospital affiliations. </label>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="10">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">PREVIOUS HOSPITAL WHERE YOU HAVE HAD PRIVILEGES</label>
                    <input class="form-control onboard " id="1183" name="1183" formControlName="1183" type="text" data-id="Pre aff 1">
                </div>
            </td>
            <td colspan="8">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">AFFILIATION DATES (MM/DD/YYYY TO
                    MM/DD/YYYY)  </label>
                    <input class="form-control onboard datepicker" (blur)="clearInputIfInvalid('1191')" placeholder="MM/DD/YYYY" ngbDatepicker #d56="ngbDatepicker" id="1191" name="1191" formControlName="1191" type="text" data-id="Pre aff 1 from">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d56.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                    </div>
                    <div  *ngIf="texas.get('1191').invalid ">
                        <small class="text-danger" *ngIf="texas.get('1191').errors?.invalidDateFormat">
                          Only Date Format is allowed(MM/DD/YYYY)
                        </small>
                        <small class="text-danger" *ngIf="texas.get('1191').errors?.invalidMonth">
                        Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="texas.get('1191').errors?.invalidDateyear">
                          year is out of range(1900-2099)                      </small>
                          <small class="text-danger" *ngIf="texas.get('1191').errors?.invalidDateRange">
                            date is out of range
                            </small>
                        </div>
                    <input class="form-control onboard datepicker" (blur)="clearInputIfInvalid('2381')" placeholder="MM/DD/YYYY" ngbDatepicker #d57="ngbDatepicker" id="2381" name="2381" formControlName="2381" type="text" data-id="Pre aff 1 to">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d57.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                    </div>
                    <div  *ngIf="texas.get('2381').invalid ">
                        <small class="text-danger" *ngIf="texas.get('2381').errors?.invalidDateFormat">
                          Only Date Format is allowed(MM/DD/YYYY)
                        </small>
                        <small class="text-danger" *ngIf="texas.get('2381').errors?.invalidMonth">
                        Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="texas.get('2381').errors?.invalidDateyear">
                          year is out of range(1900-2099)                      </small>
                          <small class="text-danger" *ngIf="texas.get('2381').errors?.invalidDateRange">
                            date is out of range
                            </small>
                        </div>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="checkbox-inline radioStyle">ADDRESS</label>
                    <input class="form-control onboard " id="1184" name="1184" formControlName="1184" type="text" data-id="Pre aff 1 add1">
                    <input class="form-control onboard " id="1185" name="1185" formControlName="1185" type="text" data-id="Pre aff 1 add2">
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18">
                <div class="form-group form-inline no-margin col-md-3 sideLabelAlignment">
                    <label class="normalParagraph">CITY</label>
                    <input style="width: 100%" class="form-control onboard " id="1186" name="1186" formControlName="1186" type="text" placeholder="City" data-id="Pre aff 1 city">
                </div>
                <div class="form-group form-inline no-margin col-md-3 sideLabelAlignment">
                    <label class="normalParagraph">STATE/COUNTRY</label>
                    <div class="form-group selectDrop dropDrownSet degree col-md-6">
                        <ng-select [items]="states" [class.is-invalid]="(texas.controls['1188'].touched && texas.controls['1188'].errors) || (texas.controls['1188'].errors && isSubmit)" bindLabel="name" placeholder="Select States" bindValue="id" formControlName="1188">
                        </ng-select>
                        <div class="formLabel col-md-11"
                            *ngIf="texas.controls['1188'].touched && texas.controls['1188'].errors">
                            <small class="text-danger" *ngIf="texas.controls['1188'].errors">
                                required
                            </small>
                        </div>
                    </div>
                </div>
                <div class="form-group form-inline no-margin col-md-3 sideLabelAlignment">
                    <label class="normalParagraph">POSTAL CODE</label>
                    <input style="width: 100%" class="form-control onboard " id="1302" name="1302" formControlName="1302" type="text" placeholder="Zip" data-id="Pre aff 1 zip">
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="6">
                <div class="form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">FULL UNRESTRICTED PRIVILEGES?  </label>
                    <label class="checkbox-inline radioStyle">
                    <input type="radio" id="cmd_068" name="cmd_068" formControlName="cmd_068" value="1">Yes</label>
                    <label class="checkbox-inline radioStyle">
                    <input type="radio" id="cmd_068" name="cmd_068" formControlName="cmd_068" value="0">No</label>
                </div>
            </td>
            <td colspan="6">
                <div class="form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">TYPES OF PRIVILEGES (PROVISIONAL, LIMITED, CONDITIONAL, ETC.) </label>
                    <input class="form-control onboard " id="1192" name="1192" formControlName="1192" type="text" data-id="Pre aff 1 status">
                </div>
            </td>
            <td colspan="6">
                <div class="form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">WERE PRIVILEGES TEMPORARY? </label>
                    <label class="checkbox-inline radioStyle">
                    <input type="radio" id="cmd_069" name="cmd_069" formControlName="cmd_069" value="1">Yes</label>
                    <label class="checkbox-inline radioStyle">
                    <input type="radio" id="cmd_069" name="cmd_069" formControlName="cmd_069" value="0">No</label>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">REASON FOR DISCONTINUANCE </label>
                    <input class="form-control onboard " id="1303" name="1303" formControlName="1303" type="text" data-id="Pre aff 1 leaving">
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="checkbox-inline radioStyle">
                    <input type="checkbox" id="cmd_070" name="cmd_070" formControlName="cmd_070" value="1">Please check this box and complete and submit Attachment E if you have additional previous hospital affiliations. </label>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="14">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="checkbox-inline radioStyle"><b>References</b></label>-Please provide three peer references from the same field and/or specialty who are not partners in your own group practice and are not
                    relatives. All peer references should have firsthand knowledge of your abilities.
                </div>
            </td>
            <td colspan="4">
                <label class="texasFloat">Does Not Apply</label>
                <input type="checkbox"  name="cmd_0724" id="cmd_0724" formControlName="cmd_0724" value="1" (change)="referencesApp($event)" class="texasFloat">
            </td>
        </tr>
        <tr>
            <td colspan="6">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">1 NAME/TITLE</label>
                    <input class="form-control onboard " [class.is-invalid]="(texas.controls['359'].touched && texas.controls['359'].errors) || (texas.controls['359'].errors && isSubmit)" id="359" name="359" formControlName="359" type="text" data-id="reference1name">
                    <div class="formLabel col-md-11"
                        *ngIf="texas.controls['359'].touched && texas.controls['359'].errors">
                        <small class="text-danger" *ngIf="texas.controls['359'].errors">
                            required
                        </small>
                    </div>
                    <label class="normalParagraph">E-MAIL
                    <input style="margin-left: 47px" [class.is-invalid]="(texas.controls['371'].touched && texas.controls['371'].errors) || (texas.controls['371'].errors && isSubmit)" class="form-control onboard " id="371" name="371" formControlName="371" type="text" data-id="reference1email">
                    <div class="formLabel col-md-11"
                        *ngIf="texas.controls['371'].touched && texas.controls['371'].invalid">
                        <small class="text-danger" *ngIf="texas.controls['371'].errors?.required">
                          The email address is required
                        </small>
                        <small class="text-danger" *ngIf="texas.controls['371'].errors?.pattern">
                          The email address is not valid
                      </small>
                    </div>
                    </label>
                </div>
            </td>
            <td colspan="6">
                <div class="form-group form-inline no-margin col-md-12" style="padding: 2px;margin-top: 13px;">
                    <label class="normalParagraph">PHONE NUMBER
                        <input class="form-control onboard " [class.is-invalid]="(texas.controls['369'].touched && texas.controls['369'].errors) || (texas.controls['369'].errors && isSubmit)" id="369" name="369" formControlName="369" type="number" data-id="reference1telephone">
                        <div class="formLabel col-md-11"
                            *ngIf="texas.controls['369'].touched && texas.controls['369'].errors">
                            <small class="text-danger" *ngIf="texas.controls['369'].errors">
                                required
                            </small>
                        </div>
                    </label>
                </div>
            </td>
            <td colspan="6">
                <div class="form-group form-inline no-margin col-md-12" style="padding: 2px;margin-top: 13px;">
                    <label class="normalParagraph">FAX NUMBER
                        <input class="form-control onboard "  id="370" name="370" formControlName="370" type="number" data-id="reference1fax">

                    </label>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="checkbox-inline radioStyle">ADDRESS</label>
                    <input style="width: 40%;" class="form-control onboard " [class.is-invalid]="(texas.controls['360'].touched && texas.controls['360'].errors) || (texas.controls['360'].errors && isSubmit)" id="360" name="360" formControlName="360" type="text" data-id="reference1address">
                    <div class="formLabel col-md-11"
                        *ngIf="texas.controls['360'].touched && texas.controls['360'].errors">
                        <small class="text-danger" *ngIf="texas.controls['360'].errors">
                            required
                        </small>
                    </div>
                    <input style="width: 40%;" class="form-control onboard " [class.is-invalid]="(texas.controls['361'].touched && texas.controls['361'].errors) || (texas.controls['361'].errors && isSubmit)" id="361" name="361" formControlName="361" type="text" data-id="reference1address2">
                    <div class="formLabel col-md-11"
                        *ngIf="texas.controls['361'].touched && texas.controls['361'].errors">
                        <small class="text-danger" *ngIf="texas.controls['361'].errors">
                            required
                        </small>
                    </div>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18">
                <div class="form-group form-inline no-margin col-md-3 sideLabelAlignment">
                    <label class="normalParagraph">CITY</label>
                    <input style="width: 100%" class="form-control onboard " [class.is-invalid]="(texas.controls['362'].touched && texas.controls['362'].errors) || (texas.controls['362'].errors && isSubmit)" id="362" name="362" formControlName="362" type="text" data-id="reference1city">
                    <div class="formLabel col-md-11"
                        *ngIf="texas.controls['362'].touched && texas.controls['362'].errors">
                        <small class="text-danger" *ngIf="texas.controls['362'].errors">
                            required
                        </small>
                    </div>
                </div>
                <div class="form-group form-inline no-margin col-md-3 sideLabelAlignment">
                    <label class="normalParagraph">STATE/COUNTRY</label>
                    <div class="form-group selectDrop dropDrownSet degree col-md-6">
                        <ng-select [items]="states" bindLabel="name" [class.is-invalid]="(texas.controls['364'].touched && texas.controls['364'].errors) || (texas.controls['364'].errors && isSubmit)" placeholder="Select States" bindValue="id" formControlName="364">
                        </ng-select>
                        <div class="formLabel col-md-11"
                            *ngIf="texas.controls['364'].touched && texas.controls['364'].errors">
                            <small class="text-danger" *ngIf="texas.controls['364'].errors">
                                required
                            </small>
                        </div>
                    </div>
                </div>
                <div class="form-group form-inline no-margin col-md-3 sideLabelAlignment">
                    <label class="normalParagraph">POSTAL CODE</label>
                    <input style="width: 100%" class="form-control onboard " [class.is-invalid]="(texas.controls['365'].touched && texas.controls['365'].errors) || (texas.controls['365'].errors && isSubmit)" id="365" name="365" formControlName="365" type="text" data-id="reference1zip">
                    <div class="formLabel col-md-11"
                        *ngIf="texas.controls['365'].touched && texas.controls['365'].errors">
                        <small class="text-danger" *ngIf="texas.controls['365'].errors">
                            required
                        </small>
                        <small class="text-danger" *ngIf="texas.controls['365'].errors?.minlength || texas.controls['365'].errors?.maxlength">
                          Zip code must be atleast 5 digits and less than 10 digits
                      </small>
                    </div>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="9">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">1 NAME/TITLE
                    <input class="form-control onboard " id="372" name="372" formControlName="372" type="text" data-id="reference2name">
                    <input class="form-control onboard " id="383" name="383" formControlName="383" type="text" data-id="Text8.1.0">
                    </label>
                </div>
            </td>
            <td colspan="9">
                <div class="form-group form-inline no-margin col-md-12" style="padding: 2px;margin-top: 13px;">
                    <label class="normalParagraph">PHONE NUMBER </label>
                    <input class="form-control onboard " [class.is-invalid]="(texas.controls['381'].touched && texas.controls['381'].invalid) || (texas.controls['381'].invalid && isSubmit)" id="381" name="381" formControlName="381" type="number" data-id="reference2telephone">
                    <div class="formLabel col-md-11"
                        *ngIf="texas.controls['381'].touched && texas.controls['381'].invalid">
                        <small class="text-danger" *ngIf="texas.controls['381'].errors?.required">
                            required
                        </small>
                    </div>
                    <label class="normalParagraph">E-MAIL
                        <input style="margin-left: 65px" [class.is-invalid]="(texas.controls['382'].touched && texas.controls['382'].invalid) || (texas.controls['382'].invalid && isSubmit)" class="form-control onboard " id="382" name="382" formControlName="382" type="text" data-id="Text8.1.1">
                        <div class="formLabel col-md-11"
                        *ngIf="texas.controls['382'].touched && texas.controls['382'].invalid">
                        <small class="text-danger" *ngIf="texas.controls['382'].errors?.required">
                          The email address is required
                        </small>
                        <small class="text-danger" *ngIf="texas.controls['382'].errors?.pattern">
                          The email address is not valid
                      </small>
                    </div>
                    </label>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="checkbox-inline radioStyle">ADDRESS</label>
                    <input  class="form-control onboard " id="373" name="373" formControlName="373" type="text" data-id="reference2address">
                    <input class="form-control onboard " id="374" name="374" formControlName="374" type="text" data-id="reference2address2">
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18">
                <div class="form-group form-inline no-margin col-md-3 sideLabelAlignment">
                    <label class="normalParagraph">CITY</label>
                    <input style="width: 100%" class="form-control onboard " id="375" name="375" formControlName="375" type="text" data-id="reference2city">
                </div>
                <div class="form-group form-inline no-margin col-md-3 sideLabelAlignment">
                    <label class="normalParagraph">STATE/COUNTRY</label>
                    <div class="form-group selectDrop dropDrownSet degree col-md-6">
                        <ng-select [items]="states" bindLabel="name" placeholder="Select States" bindValue="id" formControlName="377">
                        </ng-select>
                    </div>
                </div>
                <div class="form-group form-inline no-margin col-md-3 sideLabelAlignment">
                    <label class="normalParagraph">POSTAL CODE</label>
                    <input style="width: 100%"  class="form-control onboard " id="378" name="378" formControlName="378" type="text" data-id="reference2zip">

                </div>
            </td>
        </tr>
        <tr>
            <td colspan="9">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">1 NAME/TITLE
                    <input class="form-control onboard " id="384" name="384" formControlName="384" type="text" data-id="reference3name">
                    <input class="form-control onboard " id="396" name="396" formControlName="396" type="text" data-id="Text8.2.0">
                    </label>
                </div>
            </td>
            <td colspan="9">
                <div class="form-group form-inline no-margin col-md-12" style="padding: 2px;margin-top: 13px;">
                    <label class="normalParagraph">PHONE NUMBER </label>
                        <input class="form-control onboard " [class.is-invalid]="(texas.controls['394'].touched && texas.controls['394'].invalid) || (texas.controls['394'].invalid && isSubmit)" id="394" name="394" formControlName="394" type="number" data-id="reference3telephone">
                        <div class="formLabel col-md-11"
                            *ngIf="texas.controls['394'].touched && texas.controls['394'].invalid">
                            <small class="text-danger" *ngIf="texas.controls['394'].errors?.required">
                                required
                            </small>
                        </div>
                    <label class="normalParagraph">E-MAIL
                        <input style="margin-left : 65px" [class.is-invalid]="(texas.controls['395'].touched && texas.controls['395'].invalid) || (texas.controls['395'].invalid && isSubmit)" class="form-control onboard " id="395" name="395" formControlName="395" type="text" data-id="Text8.2.1">
                        <div class="formLabel col-md-11"
                        *ngIf="texas.controls['395'].touched && texas.controls['395'].invalid">
                        <small class="text-danger" *ngIf="texas.controls['395'].errors?.required">
                          The email address is required
                        </small>
                        <small class="text-danger" *ngIf="texas.controls['395'].errors?.pattern">
                          The email address is not valid
                      </small>
                    </div>
                    </label>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="checkbox-inline radioStyle">ADDRESS</label>
                    <input class="form-control onboard " id="385" name="385" formControlName="385" type="text" data-id="reference3add">
                    <input class="form-control onboard " id="386" name="386" formControlName="386" type="text" data-id="reference3spec">
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18">
                <div class="form-group form-inline no-margin col-md-3 sideLabelAlignment">
                    <label class="normalParagraph">CITY</label>
                    <input style="width: 100%" class="form-control onboard " id="387" name="387" formControlName="387" type="text" data-id="reference3city">
                </div>
                <div class="form-group form-inline no-margin col-md-3 sideLabelAlignment">
                    <label class="normalParagraph">STATE/COUNTRY</label>
                    <div class="form-group selectDrop dropDrownSet degree col-md-6">
                        <ng-select [items]="states" bindLabel="name" placeholder="Select States" bindValue="id" formControlName="389">
                        </ng-select>
                    </div>
                </div>
                <div class="form-group form-inline no-margin col-md-3 sideLabelAlignment">
                    <label class="normalParagraph">POSTAL CODE</label>
                    <input style="width: 100%"  class="form-control onboard " id="390" name="390" formControlName="390" type="text" data-id="reference3zip">

                </div>
            </td>
        </tr>
        <tr>
            <td colspan="14">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph"><b>Professional Liability Insurance Coverage</b></label>
                </div>
            </td>
            <td colspan="4">
                <label class="texasFloat">Does Not Apply</label>
                <input type="checkbox"  name="cmd_0725" id="cmd_0725" formControlName="cmd_0725"  value="1" (change)="proInsApp($event)" class="texasFloat">
            </td>
        </tr>
        <tr>
            <td colspan="6" width="21%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    SELF-INSURED?<label class="checkbox-inline radioStyle">
                    <input type="radio" id="cmd_071" name="cmd_071" formControlName="cmd_071" value="1">Yes</label>
                    <label class="checkbox-inline radioStyle">
                    <input type="radio" id="cmd_071" name="cmd_071" formControlName="cmd_071" value="0">No</label>
                </div>
            </td>
            <td colspan="12" width="79%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="normalParagraph">NAME OF CURRENT MALPRACTICE INSURANCE CARRIER OR SELF-INSURED ENTITY
                    </label>
                    <input class="form-control onboard " [class.is-invalid]="(texas.controls['345'].touched && texas.controls['345'].errors) || (texas.controls['345'].errors && isSubmit)" id="345" name="345" formControlName="345" type="text" data-id="mlp1carrier">
                    <div class="formLabel col-md-11"
                        *ngIf="texas.controls['345'].touched && texas.controls['345'].errors">
                        <small class="text-danger" *ngIf="texas.controls['345'].errors">
                            required
                        </small>
                    </div>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18" width="79%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="normalParagraph">ADDRESS
                    </label>
                    <input class="form-control onboard " [class.is-invalid]="(texas.controls['901'].touched && texas.controls['901'].errors) || (texas.controls['901'].errors && isSubmit)" id="901" name="901" formControlName="901" type="text" data-id="MLP Mailing Add1">
                    <div class="formLabel col-md-11"
                        *ngIf="texas.controls['901'].touched && texas.controls['901'].errors">
                        <small class="text-danger" *ngIf="texas.controls['901'].errors">
                            required
                        </small>
                    </div>
                    <input class="form-control onboard " [class.is-invalid]="(texas.controls['902'].touched && texas.controls['902'].errors) || (texas.controls['902'].errors && isSubmit)" id="902" name="902" formControlName="902" type="text" data-id="MLP Mailing Add2">
                    <div class="formLabel col-md-11"
                    *ngIf="texas.controls['902'].touched && texas.controls['902'].errors">
                    <small class="text-danger" *ngIf="texas.controls['902'].errors">
                        required
                    </small>
                </div>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18">
                <div class="form-group form-inline no-margin col-md-3 sideLabelAlignment">
                    <label class="normalParagraph">CITY</label>
                    <input style="width: 100%" [class.is-invalid]="(texas.controls['903'].touched && texas.controls['903'].errors) || (texas.controls['903'].errors && isSubmit)" class="form-control onboard " id="903" name="903" formControlName="903" type="text" data-id="MLP City">
                    <div class="formLabel col-md-11"
                        *ngIf="texas.controls['903'].touched && texas.controls['903'].errors">
                        <small class="text-danger" *ngIf="texas.controls['903'].errors">
                            required
                        </small>
                    </div>
                </div>
                <div class="form-group form-inline no-margin col-md-3 sideLabelAlignment">
                    <label class="normalParagraph">STATE/COUNTRY</label>
                    <div class="form-group selectDrop dropDrownSet degree col-md-6">
                        <ng-select [items]="states" bindLabel="name" [class.is-invalid]="(texas.controls['905'].touched && texas.controls['905'].errors) || (texas.controls['905'].errors && isSubmit)" placeholder="Select States" bindValue="id" formControlName="905">
                        </ng-select>
                    </div>
                    <div class="formLabel col-md-11"
                        *ngIf="texas.controls['905'].touched && texas.controls['905'].errors">
                        <small class="text-danger" *ngIf="texas.controls['905'].errors">
                            required
                        </small>
                    </div>
                </div>
                <div class="form-group form-inline no-margin col-md-3 sideLabelAlignment">
                    <label class="normalParagraph">POSTAL CODE</label>
                    <input style="width: 100%" [class.is-invalid]="(texas.controls['906'].touched && texas.controls['906'].errors) || (texas.controls['906'].errors && isSubmit)" class="form-control onboard " id="906" name="906" formControlName="906" type="text" data-id="MLP Zip">
                    <div class="formLabel col-md-11"
                        *ngIf="texas.controls['906'].touched && texas.controls['906'].errors">
                        <small class="text-danger" *ngIf="texas.controls['906'].errors">
                            required
                        </small>
                        <small class="text-danger" *ngIf="texas.controls['906'].errors?.minlength || texas.controls['906'].errors?.maxlength">
                          Zip code must be atleast 5 digits and less than 10 digits
                      </small>
                    </div>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="4">
                <div class="form-group form-inline no-margin col-md-3 sideLabelAlignment">
                    <label class="normalParagraph">PHONE NUMBER </label>
                    <input class="form-control onboard " [class.is-invalid]="(texas.controls['1266'].touched && texas.controls['1266'].errors) || (texas.controls['1266'].errors && isSubmit)" id="1266" name="1266" formControlName="1266" type="text" data-id="MLP Phone">
                    <div class="formLabel col-md-11"
                        *ngIf="texas.controls['1266'].touched && texas.controls['1266'].errors">
                        <small class="text-danger" *ngIf="texas.controls['1266'].errors">
                            required
                        </small>
                    </div>
                </div>
            </td>
            <td colspan="4">
                <div class="form-group form-inline no-margin col-md-3 sideLabelAlignment">
                    <label class="normalParagraph">POLICY NUMBER</label>
                    <input class="form-control onboard " [class.is-invalid]="(texas.controls['346'].touched && texas.controls['346'].errors) || (texas.controls['346'].errors && isSubmit)" id="346" name="346" formControlName="346" type="text" data-id="mlp1pol">
                    <div class="formLabel col-md-11"
                        *ngIf="texas.controls['346'].touched && texas.controls['346'].errors">
                        <small class="text-danger" *ngIf="texas.controls['346'].errors">
                            required
                        </small>
                    </div>
                </div>
            </td>
            <td colspan="10">
                <div class="form-group form-inline no-margin col-md-6 sideLabelAlignment">
                    <label class="normalParagraph">EFFECTIVE DATE (MM/DD/YYYY) </label>
                    <div class="input-group">
                        <input style="width:30%;" class="form-control onboard datepicker" (blur)="clearInputIfInvalidMandi('945')" ngbDatepicker #d58="ngbDatepicker" [class.is-invalid]="(texas.controls['945'].touched && texas.controls['945'].errors) || (texas.controls['945'].errors && isSubmit)" placeholder="MM/DD/YYYY" id="945" name="945" formControlName="945" type="text" data-id="mlp1dateeff_date">
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="d58.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                        </div>
                    </div>
                    <div class="formLabel col-md-11"
                        *ngIf="texas.controls['945'].touched && texas.controls['945'].errors">
                        <small class="text-danger" *ngIf="texas.controls['945'].errors">
                            required
                        </small>
                    </div>
                    <small class="text-danger" *ngIf="texas.get('945').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)                </small>
                      <small class="text-danger" *ngIf="texas.get('945').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="texas.get('945').errors?.invalidDateyear">
                        year is out of range(1900-2099)                  </small>
                        <small class="text-danger" *ngIf="texas.get('945').errors?.invalidDateRange">
                          date is out of range
                          </small>
                </div>
                <div class="form-group form-inline no-margin col-md-6 sideLabelAlignment">
                    <label class="normalParagraph">EXPIRATION DATE (MM/DD/YYYY) </label>
                    <div class="input-group">
                        <input style="width:30%;" class="form-control onboard datepicker" (blur)="clearInputIfInvalidMandi('349')" ngbDatepicker #d59="ngbDatepicker" [class.is-invalid]="(texas.controls['349'].touched && texas.controls['349'].errors) || (texas.controls['349'].errors && isSubmit)" placeholder="MM/DD/YYYY" id="349" name="349" formControlName="349" type="text" data-id="mlp1dateexp_date">
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="d59.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                        </div>
                    </div>
                    <div class="formLabel col-md-11"
                        *ngIf="texas.controls['349'].touched && texas.controls['349'].errors">
                        <small class="text-danger" *ngIf="texas.controls['349'].errors">
                            required
                        </small>
                    </div>
                    <small class="text-danger" *ngIf="texas.get('349').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)                </small>
                      <small class="text-danger" *ngIf="texas.get('349').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="texas.get('349').errors?.invalidDateyear">
                        year is out of range(1900-2099)                  </small>
                        <small class="text-danger" *ngIf="texas.get('349').errors?.invalidDateRange">
                          date is out of range
                          </small>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="4">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">AMOUNT OF COVERAGE PER OCCURRENCE </label>
                    <ng-select class="form-group selectDrop dropDrownSet" bindLabel="name"
                    placeholder="Select Per Occurrence"
                    [items]="preClaim"
                    bindValue="id"  [class.is-invalid]="(texas.controls['350'].touched && texas.controls['350'].errors) || (texas.controls['350'].errors && isSubmit)" id="350" name="350" formControlName="350"  data-id="perclaimamt"> </ng-select>
                    <div class="formLabel col-md-11"
                        *ngIf="texas.controls['350'].touched && texas.controls['350'].errors">
                        <small class="text-danger" *ngIf="texas.controls['350'].errors">
                            required
                        </small>
                    </div>
                </div>
            </td>
            <td colspan="4">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">AMOUNT OF COVERAGE AGGREGATE</label>
                    <ng-select class="form-group selectDrop dropDrownSet"
                    bindLabel="name"
                    [items]="aggregates"
                    placeholder="Select Aggregate"
                    bindValue="id"
                    [class.is-invalid]="(texas.controls['351'].touched && texas.controls['351'].errors) || (texas.controls['351'].errors && isSubmit)" id="351" name="351" formControlName="351"  data-id="aggreamt"></ng-select>
                    <div class="formLabel col-md-11"
                        *ngIf="texas.controls['351'].touched && texas.controls['351'].errors">
                        <small class="text-danger" *ngIf="texas.controls['351'].errors">
                            required
                        </small>
                    </div>
                </div>
            </td>
            <td colspan="6">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="checkbox-inline radioStyle">
                        TYPE OF COVERAGE
                        <br/>
                    <input type="radio" id="cmd_072" name="cmd_072" formControlName="cmd_072" value="1">Individual</label>
                    <label class="checkbox-inline radioStyle">
                    <input type="radio" id="cmd_072" name="cmd_072" formControlName="cmd_072" value="0">Shared</label>
                </div>
            </td>
            <td colspan="4">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">LENGTH OF TIME WITH CARRIER</label>
                    <input class="form-control onboard " id="cmd_073" name="cmd_073" formControlName="cmd_073" type="text" data-id="MLP length of time">
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18" width="79%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="normalParagraph">NAME OF PREVIOUS MALPRACTICE INSURANCE CARRIER IF WITH CURRENT CARRIER LESS THAN 5 YEARS
                    </label>
                    <input class="form-control onboard " [class.is-invalid]="(texas.controls['1242'].touched && texas.controls['1242'].errors) || (texas.controls['1242'].errors && isSubmit)" id="1242" name="1242" formControlName="1242" type="text" data-id="prevmlp1carrier">
                    <div class="formLabel col-md-11"
                        *ngIf="texas.controls['1242'].touched && texas.controls['1242'].errors">
                        <small class="text-danger" *ngIf="texas.controls['1242'].errors">
                            required
                        </small>
                    </div>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18" width="79%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="normalParagraph">ADDRESS
                    </label>
                    <input class="form-control onboard " [class.is-invalid]="(texas.controls['1244'].touched && texas.controls['1244'].errors) || (texas.controls['1244'].errors && isSubmit)" id="1244" name="1244" formControlName="1244" type="text" data-id="prevMLP Mailing Add1">
                    <div class="formLabel col-md-11"
                        *ngIf="texas.controls['1244'].touched && texas.controls['1244'].errors">
                        <small class="text-danger" *ngIf="texas.controls['1244'].errors">
                            required
                        </small>
                    </div>
                    <input class="form-control onboard " [class.is-invalid]="(texas.controls['1245'].touched && texas.controls['1245'].errors) || (texas.controls['1245'].errors && isSubmit)" id="1245" name="1245" formControlName="1245" type="text" data-id="prevMLP Mailing Add2">
                    <div class="formLabel col-md-11"
                        *ngIf="texas.controls['1245'].touched && texas.controls['1245'].errors">
                        <small class="text-danger" *ngIf="texas.controls['1245'].errors">
                            required
                        </small>
                    </div>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18">
                <div class="form-group form-inline no-margin col-md-3 sideLabelAlignment">
                    <label class="normalParagraph">CITY</label>
                    <input style="width: 100%" [class.is-invalid]="(texas.controls['1246'].touched && texas.controls['1246'].errors) || (texas.controls['1246'].errors && isSubmit)" class="form-control onboard " id="1246" name="1246" formControlName="1246" type="text" data-id="prevMLP City">
                    <div class="formLabel col-md-11"
                        *ngIf="texas.controls['1246'].touched && texas.controls['1246'].errors">
                        <small class="text-danger" *ngIf="texas.controls['1246'].errors">
                            required
                        </small>
                    </div>
                </div>
                <div class="form-group form-inline no-margin col-md-3 sideLabelAlignment">
                    <label class="normalParagraph">STATE/COUNTRY</label>
                    <div class="form-group selectDrop dropDrownSet degree col-md-6">
                        <ng-select [items]="states" [class.is-invalid]="(texas.controls['1248'].touched && texas.controls['1248'].errors) || (texas.controls['1248'].errors && isSubmit)" bindLabel="name" placeholder="Select States" bindValue="id" formControlName="1248">
                        </ng-select>
                        <div class="formLabel col-md-11"
                            *ngIf="texas.controls['1248'].touched && texas.controls['1248'].errors">
                            <small class="text-danger" *ngIf="texas.controls['1248'].errors">
                                required
                            </small>
                        </div>
                    </div>
                </div>
                <div class="form-group form-inline no-margin col-md-3 sideLabelAlignment">
                  <label class="normalParagraph">POSTAL CODE</label>
                  <input style="width: 100%" class="form-control onboard " id="1249"
                    [class.is-invalid]="(texas.controls['1249'].touched && texas.controls['1249'].errors) || (texas.controls['1249'].errors && isSubmit)"
                    name="1249" formControlName="1249" type="text" data-id="prevMLP Zip">
                  <div class="formLabel col-md-11" *ngIf="texas.controls['1249'].touched && texas.controls['1249'].errors">
                    <small class="text-danger" *ngIf="texas.controls['1249'].errors">
                      required
                    </small>
                    <small class="text-danger"
                      *ngIf="texas.controls['1249'].errors?.minlength || texas.controls['1249'].errors?.maxlength">
                      Zip code must be atleast 5 digits and less than 10 digits
                    </small>
                  </div>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="4">
                <div class="form-group form-inline no-margin col-md-3 sideLabelAlignment">
                    <label class="normalParagraph">PHONE NUMBER </label>
                    <input class="form-control onboard "  id="1254" name="1254" formControlName="1254" type="text" data-id="prevMLP Phone">

                </div>
            </td>
            <td colspan="4">
                <div class="form-group form-inline no-margin col-md-3 sideLabelAlignment">
                    <label class="normalParagraph">POLICY NUMBER</label>
                    <input class="form-control onboard " [class.is-invalid]="(texas.controls['1256'].touched && texas.controls['1256'].errors) || (texas.controls['1256'].errors && isSubmit)" id="1256" name="1256" formControlName="1256" type="text" data-id="prevmlp1pol">
                    <div class="formLabel col-md-11"
                        *ngIf="texas.controls['1256'].touched && texas.controls['1256'].errors">
                        <small class="text-danger" *ngIf="texas.controls['1256'].errors">
                            required
                        </small>
                    </div>
                </div>
            </td>
            <td colspan="10">
                <div class="form-group form-inline no-margin col-md-6 sideLabelAlignment">
                    <label class="normalParagraph">EFFECTIVE DATE (MM/DD/YYYY) </label>
                    <input style="width:45%;" class="form-control onboard datepicker" (blur)="clearInputIfInvalidMandi('1252')" ngbDatepicker #d60="ngbDatepicker" [class.is-invalid]="(texas.controls['1252'].touched && texas.controls['1252'].errors) || (texas.controls['1252'].errors && isSubmit)" placeholder="MM/DD/YYYY" id="1252" name="1252" formControlName="1252" type="text" data-id="prevmlp1dateeff_date">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d60.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                    </div>
                    <div class="formLabel col-md-11"
                        *ngIf="texas.controls['1252'].touched && texas.controls['1252'].errors">
                        <small class="text-danger" *ngIf="texas.controls['1252'].errors">
                            required
                        </small>
                    </div>
                    <small class="text-danger" *ngIf="texas.get('1252').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)                </small>
                      <small class="text-danger" *ngIf="texas.get('1252').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="texas.get('1252').errors?.invalidDateyear">
                        year is out of range(1900-2099)                  </small>
                        <small class="text-danger" *ngIf="texas.get('1252').errors?.invalidDateRange">
                          date is out of range
                          </small>
                </div>
                <div class="form-group form-inline no-margin col-md-6 sideLabelAlignment">
                    <label class="normalParagraph">EXPIRATION DATE (MM/DD/YYYY) </label>
                    <input style="width:45%;" class="form-control onboard datepicker" (blur)="clearInputIfInvalidMandi('1253')" ngbDatepicker #d61="ngbDatepicker" [class.is-invalid]="(texas.controls['1253'].touched && texas.controls['1253'].errors) || (texas.controls['1253'].errors && isSubmit)" placeholder="MM/DD/YYYY" id="1253" name="1253" formControlName="1253" type="text" data-id="prevmlp1dateexp_date">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d61.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                    </div>
                    <div class="formLabel col-md-11"
                        *ngIf="texas.controls['1253'].touched && texas.controls['1253'].errors">
                        <small class="text-danger" *ngIf="texas.controls['1253'].errors">
                            required
                        </small>
                    </div>
                    <small class="text-danger" *ngIf="texas.get('1253').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)                </small>
                      <small class="text-danger" *ngIf="texas.get('1253').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="texas.get('1253').errors?.invalidDateyear">
                        year is out of range(1900-2099)                  </small>
                        <small class="text-danger" *ngIf="texas.get('1253').errors?.invalidDateRange">
                          date is out of range
                          </small>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="4">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">AMOUNT OF COVERAGE PER OCCURRENCE </label>
                    <ng-select class="form-group selectDrop dropDrownSet"
                    bindLabel="name"
                    placeholder="Select Per Occurrence"
                    [items]="preClaim"
                    bindValue="id"
                    [class.is-invalid]="(texas.controls['1264'].touched && texas.controls['1264'].errors) || (texas.controls['1264'].errors && isSubmit)" id="1264" name="1264" formControlName="1264"  data-id="prevperclaimamt"></ng-select>
                    <div class="formLabel col-md-11"
                        *ngIf="texas.controls['1264'].touched && texas.controls['1264'].errors">
                        <small class="text-danger" *ngIf="texas.controls['1264'].errors">
                            required
                        </small>
                    </div>
                </div>
            </td>
            <td colspan="4">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">AMOUNT OF COVERAGE AGGREGATE</label>
                    <ng-select class="form-group selectDrop dropDrownSet"
                    bindLabel="name"
                    [items]="aggregates"
                    placeholder="Select Aggregate"
                    bindValue="id"
                    [class.is-invalid]="(texas.controls['1265'].touched && texas.controls['1265'].errors) || (texas.controls['1265'].errors && isSubmit)" id="1265" name="1265" formControlName="1265"  data-id="prevaggreamt"></ng-select>
                    <div class="formLabel col-md-11"
                        *ngIf="texas.controls['1265'].touched && texas.controls['1265'].errors">
                        <small class="text-danger" *ngIf="texas.controls['1265'].errors">
                            required
                        </small>
                    </div>
                </div>
            </td>
            <td colspan="6">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="checkbox-inline radioStyle">
                        TYPE OF COVERAGE
                        <br/>
                    <input type="radio" id="cmd_074" name="cmd_074" formControlName="cmd_074" value="1">Individual</label>
                    <label class="checkbox-inline radioStyle">
                    <input type="radio" id="cmd_074" name="cmd_074" formControlName="cmd_074" value="0">Shared</label>
                </div>
            </td>
            <td colspan="4">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">LENGTH OF TIME WITH CARRIER</label>
                    <input class="form-control onboard " id="cmd_075" name="cmd_075" formControlName="cmd_075"  type="text" data-id="prevMLP length of time">
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="checkbox-inline radioStyle"><b>Call Coverage</b></label>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="checkbox-inline radioStyle">
                    <input type="checkbox" id="cmd_076" name="cmd_076" formControlName="cmd_076" value="1"> See attached list of hospital staff within my department I utilize for call coverage.  </label>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">PLEASE LIST NAMES OF COLLEAGUE(S) PROVIDING REGULAR COVERAGE AND HIS OR HER SPECIALTIES. </label>
                </div>
                <br/>
                <div class="form-group form-inline no-margin col-md-6 sideLabelAlignment">
                    Name:<input style="width: 100%" class="form-control onboard " id="2953" name="2953" formControlName="2953" type="text" data-id="Call Name.0">
                </div>
                <div class="form-group form-inline no-margin col-md-6 sideLabelAlignment">
                    Specialty:<input style="width: 100%" class="form-control onboard " id="3403" name="3403" formControlName="3403" type="text" data-id="Call Specialty.0">
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18">
                <div class="form-group form-inline no-margin col-md-6 sideLabelAlignment">
                    Name:<input style="width: 100%" class="form-control onboard " id="2963" name="2963" formControlName="2963" type="text" data-id="Call Name.1">
                </div>
                <div class="form-group form-inline no-margin col-md-6 sideLabelAlignment">
                    Specialty:<input style="width: 100%" class="form-control onboard " id="3404" name="3404" formControlName="3404" type="text" data-id="Call Specialty.1">
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18">
                <div class="form-group form-inline no-margin col-md-6 sideLabelAlignment">
                    Name:<input style="width: 100%" class="form-control onboard " id="2979" name="2979" formControlName="2979" type="text" data-id="Call Name.2">
                </div>
                <div class="form-group form-inline no-margin col-md-6 sideLabelAlignment">
                    Specialty:<input style="width: 100%" class="form-control onboard " id="3405" name="3405" formControlName="3405" type="text" data-id="Call Specialty.2">
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18">
                <div class="form-group form-inline no-margin col-md-6 sideLabelAlignment">
                    Name:<input style="width: 100%" class="form-control onboard " id="cmd_077" name="cmd_077" formControlName="cmd_077" type="text" data-id="Call Name.3">
                </div>
                <div class="form-group form-inline no-margin col-md-6 sideLabelAlignment">
                    Specialty:<input style="width: 100%" class="form-control onboard " id="cmd_078" name="cmd_078" formControlName="cmd_078" type="text" data-id="Call Specialty.3">
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18">
                <div class="form-group form-inline no-margin col-md-6 sideLabelAlignment">
                    Name:<input style="width: 100%" class="form-control onboard " id="cmd_079" name="cmd_079" formControlName="cmd_079" type="text" data-id="Call Name.4">
                </div>
                <div class="form-group form-inline no-margin col-md-6 sideLabelAlignment">
                    Specialty:<input style="width: 100%" class="form-control onboard " id="cmd_080" name="cmd_080" formControlName="cmd_080"  type="text" data-id="Call Specialty.4">
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    PLEASE LIST FULL NAMES OF ALL PARTNERS IN YOUR PRACTICE.<label class="checkbox-inline radioStyle">
                    <input type="checkbox" id="cmd_081" name="cmd_081" value="1"> CHECK THIS BOX AND ATTACH LIST FOR LARGE GROUP.  </label>
                    <br/>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18">
                <div class="form-group form-inline no-margin col-md-6 sideLabelAlignment">
                    Name:<input style="width: 100%" class="form-control onboard " id="cmd_082" name="cmd_082" formControlName="cmd_082" type="text" data-id="Call Name.5">
                </div>
                <div class="form-group form-inline no-margin col-md-6 sideLabelAlignment">
                    Name:<input style="width: 100%" class="form-control onboard " id="cmd_083" name="cmd_083" formControlName="cmd_083" type="text" data-id="Call Specialty.5">
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18">
                <div class="form-group form-inline no-margin col-md-6 sideLabelAlignment">
                    Name:<input style="width: 100%" class="form-control onboard " id="cmd_084" name="cmd_084" formControlName="cmd_084" type="text" data-id="Call Name.6">
                </div>
                <div class="form-group form-inline no-margin col-md-6 sideLabelAlignment">
                    Name:<input style="width: 100%" class="form-control onboard " id="cmd_085" name="cmd_085" formControlName="cmd_085" type="text" data-id="Call Specialty.6">
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18">
                <div class="form-group form-inline no-margin col-md-6 sideLabelAlignment">
                    Name:<input style="width: 100%" class="form-control onboard " id="cmd_086" name="cmd_086" formControlName="cmd_086" type="text" data-id="Call Name.7">
                </div>
                <div class="form-group form-inline no-margin col-md-6 sideLabelAlignment">
                    Name:<input style="width: 100%" class="form-control onboard " id="cmd_087" name="cmd_087" formControlName="cmd_087" type="text" data-id="Call Specialty.7">
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="14">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                <label class="checkbox-inline radioStyle"><b>Practice Location Information </b></label> - Please answer the following questions for each practice location. Use Attachment F or make copies of pages 6-7 as necessary.
                </div>
            </td>
            <td colspan="4">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="checkbox-inline radioStyle">PRACTICE LOCATION </label>
                    <input class="form-control onboard " id="cmd_088" name="cmd_088" formControlName="cmd_088" type="text" data-id="Text3.0">
                    of
                    <input class="form-control onboard " id="cmd_089" name="cmd_089" formControlName="cmd_089" type="text" data-id="Text3.1">
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    TYPE OF SERVICE PROVIDED
                    <label class="checkbox-inline radioStyle">
                    <input type="checkbox" id="cmd_090" name="cmd_090" formControlName="cmd_090"  value="1">Solo Primary Care
                    </label>
                    <label class="checkbox-inline radioStyle">
                    <input type="checkbox" id="cmd_091" name="cmd_091" formControlName="cmd_091"  value="1">Solo Specialty Care
                    </label>
                    <label class="checkbox-inline radioStyle">
                    <input type="checkbox" id="cmd_092" name="cmd_092" formControlName="cmd_092"  value="1">Group Primary Care
                    </label>
                    <label class="checkbox-inline radioStyle">
                    <input type="checkbox" id="cmd_093" name="cmd_093" formControlName="cmd_093" value="1">Group Single Specialty
                    </label>
                    <label class="checkbox-inline radioStyle">
                    <input type="checkbox" id="cmd_094" name="cmd_094" formControlName="cmd_094"  value="1">Group Multi-Specialty
                    </label>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="9">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="normalParagraph">GROUP NAME/PRACTICE NAME TO APPEAR IN THE DIRECTORY </label>
                    <input class="form-control onboard " id="cmd_0728" name="cmd_0728" formControlName="cmd_0728" type="text" data-id="prac1_PracticeName">
                </div>
            </td>
            <td colspan="9">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="normalParagraph">GROUP/CORPORATE NAME AS IT APPEARS ON IRS W-9  </label>
                    <input style="width:70%;" class="form-control onboard " id="cmd_0729" name="cmd_0729" formControlName="cmd_0729" type="text" data-id="prac1_TINName">
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">PRACTICE LOCATION ADDRESS:<input type="checkbox" id="cmd_095" name="cmd_095" formControlName="cmd_095" value="1">Primary
                    </label>
                    <input class="form-control onboard " id="cmd_0730" name="cmd_0730" formControlName="cmd_0730" type="text" data-id="prac1_Address1">
                    <input class="form-control onboard " id="cmd_0731" name="cmd_0731" formControlName="cmd_0731" type="text" data-id="prac1_Address2">
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18">
                <div class="form-group form-inline no-margin col-md-3 sideLabelAlignment">
                    <label class="normalParagraph">CITY</label>
                    <input style="width:70%;" class="form-control onboard " id="cmd_0732" name="cmd_0732" formControlName="cmd_0732" type="text" data-id="prac1_City">
                </div>
                <div class="form-group form-inline no-margin col-md-4 sideLabelAlignment">
                    <label class="normalParagraph">STATE/COUNTRY</label>
                    <div class="form-group selectDrop dropDrownSet degree col-md-6">
                        <ng-select [items]="country" bindLabel="name" placeholder="Select States" bindValue="id" formControlName="cmd_0733">
                        </ng-select>
                    </div>
                </div>
                <div class="form-group form-inline no-margin col-md-3 sideLabelAlignment">
                    <label class="normalParagraph">POSTAL CODE</label>
                    <input style="width:70%;" class="form-control onboard " id="cmd_0734" name="cmd_0734" formControlName="cmd_0734" type="text" data-id="prac1_ZIP">
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="6">
                <div class="form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">PHONE NUMBER</label>
                    <input class="form-control onboard " id="cmd_0735" name="cmd_0735" formControlName="cmd_0735" type="text" data-id="prac1_Phone">
                </div>
            </td>
            <td colspan="6">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">FAX NUMBER </label>
                    <input class="form-control onboard " id="cmd_0736" name="cmd_0736" formControlName="cmd_0736" type="text" data-id="prac1_Fax">
                </div>
            </td>
            <td colspan="6">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">E-MAIL </label>
                    <input class="form-control onboard " id="cmd_0737" name="cmd_0737" formControlName="cmd_0737" type="text" data-id="Office email">
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="6">
                <div class="form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">BACK OFFICE PHONE NUMBER </label>
                    <input class="form-control onboard " id="cmd_0738" name="cmd_0738" formControlName="cmd_0738" type="text" data-id="Answering Service">
                </div>
            </td>
            <td colspan="6">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">SITE-SPECIFIC MEDICAID NUMBER</label>
                    <input class="form-control onboard " id="cmd_0739" name="cmd_0739" formControlName="cmd_0739"  type="text" data-id="Pager">
                </div>
            </td>
            <td colspan="6">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">TAX ID NUMBER</label>
                    <input style="width:70%;" class="form-control onboard " id="cmd_0740" name="cmd_0740" formControlName="cmd_0740" type="text" data-id="prac1_TIN">
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="9">
                <div class="form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">GROUP NUMBER CORRESPONDING TO TAX ID NUMBER</label>
                    <input class="form-control onboard " id="cmd_0741" name="cmd_0741" formControlName="cmd_0741" type="text" data-id="prac1_NPI">
                </div>
            </td>
            <td colspan="9">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">GROUP NAME CORRESPONDING TO TAX ID NUMBER </label>
                    <input style="width:70%;" class="form-control onboard " id="cmd_0742" name="cmd_0742" formControlName="cmd_0742" type="text" data-id="prac1_TINName">
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="6">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    ARE YOU CURRENTLY PRACTICING AT THIS LOCATION?
                    <label class="checkbox-inline radioStyle">
                    <input type="radio" id="cmd_095" name="cmd_095" formControlName="cmd_095" value="1">Yes
                    </label>
                    <label class="checkbox-inline radioStyle">
                    <input type="radio" id="cmd_095" name="cmd_095" formControlName="cmd_095" value="0">No
                    </label>
                </div>
            </td>
            <td colspan="6">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph"> IF NO, EXPECTED START DATE? (MM/DD/YYYY)  </label>
                    <div class="input-group">
                        <input style="width:30%;" class="form-control onboard datepicker" (blur)="clearInputIfInvalid('cmd_0743')" ngbDatepicker #d62="ngbDatepicker" id="cmd_0743" name="cmd_0743" formControlName="cmd_0743" placeholder="MM/DD/YYYY" type="text" data-id="phystartdate">
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="d62.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                        </div>
                        <div  *ngIf="texas.get('cmd_0743').invalid ">
                            <small class="text-danger" *ngIf="texas.get('cmd_0743').errors?.invalidDateFormat">
                              Only Date Format is allowed(MM/DD/YYYY)
                            </small>
                            <small class="text-danger" *ngIf="texas.get('cmd_0743').errors?.invalidMonth">
                            Month is out of range
                            </small>
                            <small class="text-danger" *ngIf="texas.get('cmd_0743').errors?.invalidDateyear">
                              year is out of range(1900-2099)                      </small>
                              <small class="text-danger" *ngIf="texas.get('cmd_0743').errors?.invalidDateRange">
                                date is out of range
                                </small>
                            </div>
                    </div>
                </div>
            </td>
            <td colspan="6">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    DO YOU WANT THIS LOCATION LISTED IN THE DIRECTORY?
                    <label class="checkbox-inline radioStyle">
                    <input type="radio" id="cmd_096" name="cmd_096" formControlName="cmd_096" value="1">Yes
                    </label>
                    <label class="checkbox-inline radioStyle">
                    <input type="radio" id="cmd_096" name="cmd_096" formControlName="cmd_096" value="0">No
                    </label>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="6">
                <div class="form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">OFFICE MANAGER OR STAFF CONTACT  </label>
                    <input style="width:40%;" class="form-control onboard " id="cmd_0744" name="cmd_0744" formControlName="cmd_0744" type="text" data-id="prac1_OfficeMgrFname">
                    <input style="width:40%;" class="form-control onboard " id="cmd_0745" name="cmd_0745" formControlName="cmd_0745" type="text" data-id="prac1_OfficeMgrLname">
                </div>
            </td>
            <td colspan="6">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">PHONE NUMBER </label>
                    <input style="width:70%;" class="form-control onboard " id="cmd_0746" name="cmd_0746" formControlName="cmd_0746" type="text" data-id="prac1_OMPhone">
                </div>
            </td>
            <td colspan="6">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">FAX NUMBER </label>
                    <input style="width:30%;" class="form-control onboard " id="cmd_0747" name="cmd_0747" formControlName="cmd_0747" type="text" data-id="prac1_OMFax">
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">CREDENTIALING CONTACT</label>
                    <input style="width: 20%" class="form-control onboard " placeholder="lastname" id="cmd_0748" name="cmd_0748" formControlName="cmd_0748" type="text" data-id="cplname">
                    <input style="width: 20%" class="form-control onboard " placeholder="firstname" id="cmd_0749" name="cmd_0749" formControlName="cmd_0749" type="text" data-id="cpfname">
                    <div class="form-group selectDrop suffix dropDrownSet col-md-3">
                        <ng-select [items]="suffix" bindLabel="name" placeholder="Select Suffix" bindValue="id" formControlName="cmd_0750">
                        </ng-select>
                    </div>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">ADDRESS</label>
                    <input class="form-control onboard " id="cmd_0751" name="cmd_0751" formControlName="cmd_0751" placeholder="Address" type="text" data-id="cpadd1">
                    <input class="form-control onboard " id="cmd_0752" name="cmd_0752" formControlName="cmd_0752" placeholder="Address" type="text" data-id="cpadd2">
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18">
                <div class="form-group form-inline no-margin col-md-3 sideLabelAlignment">
                    <label class="normalParagraph">CITY</label>
                    <input class="form-control onboard " placeholder="City" id="cmd_0753" name="cmd_0753" formControlName="cmd_0753" type="text" data-id="cpcity">
                </div>
                <div class="form-group form-inline no-margin col-md-3 sideLabelAlignment">
                    <label class="normalParagraph">STATE/COUNTRY</label>
                    <div class="form-group selectDrop dropDrownSet degree col-md-6">
                        <ng-select [items]="states" bindLabel="name" placeholder="Select States" bindValue="id" formControlName="cmd_0754">
                        </ng-select>
                    </div>
                </div>
                <div class="form-group form-inline no-margin col-md-3 sideLabelAlignment">
                    <label class="normalParagraph">POSTAL CODE</label>
                    <input class="form-control onboard " id="cmd_0755" name="cmd_0755" placeholder="Zip" formControlName="cmd_0755" type="text" data-id="cpzip">
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="6">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">PHONE NUMBER </label>
                    <input style="width:70%;" class="form-control onboard " id="cmd_0756" name="cmd_0756" formControlName="cmd_0756" type="text" data-id="cptele">
                </div>
            </td>
            <td colspan="6">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">FAX NUMBER </label>
                    <input style="width:70%;" class="form-control onboard " id="cmd_0757" name="cmd_0757" formControlName="cmd_0757" type="text" data-id="cpfax">
                </div>
            </td>
            <td colspan="6">
                <div class="form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">E-MAIL  </label>
                    <input style="width:40%;" class="form-control onboard " id="cmd_0758" name="cmd_0758" formControlName="cmd_0758" type="text" data-id="cpemail">
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="9">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">BILLING COMPANY'S NAME (IF APPLICABLE)</label>
                    <input style="width:70%;" class="form-control onboard " id="cmd_0759" name="cmd_0759" formControlName="cmd_0759" type="text" data-id="prac1_paytoloc">
                </div>
            </td>
            <td colspan="9">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">BILLING REPRESENTATIVE</label>
                    <input style="width:70%;" class="form-control onboard " id="cmd_0760" name="cmd_0760" formControlName="cmd_0760" type="text" data-id="prac1_paytocontract">
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">ADDRESS </label>
                    <input class="form-control onboard " id="cmd_0761" name="cmd_0761" formControlName="cmd_0761" type="text" data-id="prac1_PaytoAddress1">
                    <input class="form-control onboard " id="cmd_0762" name="cmd_0762" formControlName="cmd_0762" type="text" data-id="prac1_PaytoAddress2">
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18">
                <div class="form-group form-inline no-margin col-md-3 sideLabelAlignment">
                    <label class="normalParagraph">CITY</label>
                    <input class="form-control onboard " id="cmd_0763" name="cmd_0763" formControlName="cmd_0763" type="text" data-id="prac1_PaytoCity">
                </div>
                <div class="form-group form-inline no-margin col-md-3 sideLabelAlignment">
                    <label class="normalParagraph">STATE/COUNTRY</label>
                    <div class="form-group selectDrop dropDrownSet degree col-md-6">
                        <ng-select [items]="states" bindLabel="name" placeholder="Select States" bindValue="id" formControlName="cmd_0764">
                        </ng-select>
                    </div>
                </div>
                <div class="form-group form-inline no-margin col-md-3 sideLabelAlignment">
                    <label class="normalParagraph">POSTAL CODE</label>
                    <input class="form-control onboard " id="cmd_0765" name="cmd_0765" formControlName="cmd_0765" type="text" data-id="prac1_PaytoZIP">
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="6">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">PHONE NUMBER </label>
                    <input style="width:70%;" class="form-control onboard " id="cmd_0766" name="cmd_0766" formControlName="cmd_0766" type="text" data-id="prac1_paytophone">
                </div>
            </td>
            <td colspan="6">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">FAX NUMBER </label>
                    <input style="width:70%;" class="form-control onboard " id="cmd_0767" name="cmd_0767" formControlName="cmd_0767" type="text" data-id="prac1_paytofax">
                </div>
            </td>
            <td colspan="6">
                <div class="form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">E-MAIL  </label>
                    <input style="width:40%;" class="form-control onboard " id="cmd_0768" name="cmd_0768" formControlName="cmd_0768" type="text" data-id="prac1_paytoemail">
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="6">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">DEPARTMENT NAME IF HOSPITAL-BASED </label>
                    <input style="width:70%;" class="form-control onboard " id="cmd_097" name="cmd_097"  formControlName="cmd_097" type="text" data-id="prac1_paytodepart">

                </div>
            </td>
            <td colspan="6">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">CHECK PAYABLE TO  </label>
                    <input style="width:70%;" class="form-control onboard " id="cmd_0769" name="cmd_0769" formControlName="cmd_0769" type="text" data-id="prac1_paytotinname">
                </div>
            </td>
            <td colspan="6">
                <div class="form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">CAN YOU BILL ELECTRONICALLY?   </label>
                    <label class="radio-inline radioStyle">
                    <input type="radio" id="cmd_098" name="cmd_098" formControlName="cmd_098" value="1">Yes</label>
                    <label class="radio-inline radioStyle">
                    <input type="radio" id="cmd_098" name="cmd_098" formControlName="cmd_098" value="0">No</label>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18">
                <div class="form-group form-inline no-margin col-md-12">
                    <label class="normalParagraph">HOURS PATIENTS ARE SEEN </label>
                </div>
                <br/>
                <div class="form-group form-inline no-margin col-md-2">
                    Monday
                </div>
                <div class="form-group form-inline no-margin col-md-3">
                    <input type="checkbox" id="cmd_099" name="cmd_099" formControlName="cmd_099" value="1">No Office Hours
                </div>
                <div class="form-group form-inline no-margin col-md-2">
                    Morning<input style="width:70%;" class="form-control onboard " id="cmd_0770" name="cmd_0770" formControlName="cmd_0770" type="text" data-id="Monday">
                </div>
                <div class="form-group form-inline no-margin col-md-6">
                    Afternoon:<input style="width:70%;" class="form-control onboard " id="cmd_0106" name="cmd_0106" formControlName="cmd_0106" type="text" data-id="Text4.2.3.0">
                </div>
                <div class="form-group form-inline no-margin col-md-2">
                    Evening:<input style="width:70%;" class="form-control onboard " id="cmd_0114" name="cmd_0114" formControlName="cmd_0114" type="text" data-id="Text4.2.4.0">
                </div>
                <div class="form-group form-inline no-margin col-md-2">
                    Tuesday
                </div>
                <div class="form-group form-inline no-margin col-md-3">
                    <input type="checkbox" id="cmd_0100" name="cmd_0100" formControlName="cmd_0100" value="1">No Office Hours
                </div>
                <div class="form-group form-inline no-margin col-md-2">
                    Morning<input style="width:70%;" class="form-control onboard " id="cmd_0771" name="cmd_0771" formControlName="cmd_0771" type="text" data-id="Tuesday">
                </div>
                <div class="form-group form-inline no-margin col-md-6">
                    Afternoon:<input style="width:70%;" class="form-control onboard " id="cmd_0107" name="cmd_0107" formControlName="cmd_0107" type="text" data-id="Text4.2.3.1">
                </div>
                <div class="form-group form-inline no-margin col-md-2">
                    Evening:<input style="width:70%;" class="form-control onboard " id="cmd_0115" name="cmd_0115" formControlName="cmd_0115" type="text" data-id="Text4.2.4.1">
                </div>
                <div class="form-group form-inline no-margin col-md-2">
                    Wednesday
                </div>
                <div class="form-group form-inline no-margin col-md-3">
                    <input type="checkbox" id="cmd_0101" name="cmd_0101" formControlName="cmd_0101" value="1">No Office Hours
                </div>
                <div class="form-group form-inline no-margin col-md-2">
                    Morning<input style="width:70%;" class="form-control onboard " id="cmd_0772" name="cmd_0772" formControlName="cmd_0772" type="text" data-id="Wednesday">
                </div>
                <div class="form-group form-inline no-margin col-md-6">
                    Afternoon:<input style="width:70%;" class="form-control onboard " id="cmd_0109" name="cmd_0109" formControlName="cmd_0109"  type="text" data-id="Text4.2.3.2">
                </div>
                <div class="form-group form-inline no-margin col-md-2">
                    Evening:<input style="width:70%;" class="form-control onboard " id="cmd_0116" name="cmd_0116" formControlName="cmd_0116" type="text" data-id="Text4.2.4.2">
                </div>
                <div class="form-group form-inline no-margin col-md-2">
                    Thursday
                </div>
                <div class="form-group form-inline no-margin col-md-3">
                    <input type="checkbox" id="cmd_0102" name="cmd_0102" formControlName="cmd_0102"  value="1">No Office Hours
                </div>
                <div class="form-group form-inline no-margin col-md-2">
                    Morning<input style="width:70%;" class="form-control onboard " id="cmd_0773" name="cmd_0773" formControlName="cmd_0773" type="text" data-id="Thrusday">
                </div>
                <div class="form-group form-inline no-margin col-md-6">
                    Afternoon:<input style="width:70%;" class="form-control onboard " id="cmd_0110" name="cmd_0110" formControlName="cmd_0110" type="text" data-id="Text4.2.3.3">
                </div>
                <div class="form-group form-inline no-margin col-md-2">
                    Evening:<input style="width:70%;" class="form-control onboard " id="cmd_0117" name="cmd_0117" formControlName="cmd_0117" type="text" data-id="Text4.2.4.3">
                </div>
                <div class="form-group form-inline no-margin col-md-2">
                    Friday
                </div>
                <div class="form-group form-inline no-margin col-md-3">
                    <input type="checkbox" id="cmd_0103" name="cmd_0103" formControlName="cmd_0103" value="1">No Office Hours
                </div>
                <div class="form-group form-inline no-margin col-md-2">
                    Morning<input style="width:70%;" class="form-control onboard " id="cmd_0774" name="cmd_0774" formControlName="cmd_0774" type="text" data-id="Friday">
                </div>
                <div class="form-group form-inline no-margin col-md-6">
                    Afternoon:<input style="width:70%;" class="form-control onboard " id="cmd_0111" name="cmd_0111" formControlName="cmd_0111" type="text" data-id="Text4.2.3.4">
                </div>
                <div class="form-group form-inline no-margin col-md-2">
                    Evening:<input style="width:70%;" class="form-control onboard " id="cmd_0118" name="cmd_0118" formControlName="cmd_0118" type="text" data-id="Text4.2.4.4">
                </div>
                <div class="form-group form-inline no-margin col-md-2">
                    Saturday
                </div>
                <div class="form-group form-inline no-margin col-md-3">
                    <input type="checkbox" id="cmd_0104" name="cmd_0104" formControlName="cmd_0104"  value="1">No Office Hours
                </div>
                <div class="form-group form-inline no-margin col-md-2">
                    Morning<input style="width:70%;" class="form-control onboard " id="cmd_0775" name="cmd_0775" formControlName="cmd_0775" type="text" data-id="Saturday">
                </div>
                <div class="form-group form-inline no-margin col-md-6">
                    Afternoon:<input style="width:70%;" class="form-control onboard " id="cmd_0112" name="cmd_0112" formControlName="cmd_0112" type="text" data-id="Text4.2.3.5">
                </div>
                <div class="form-group form-inline no-margin col-md-2">
                    Evening:<input style="width:70%;" class="form-control onboard " id="cmd_0119" name="cmd_0119" formControlName="cmd_0119" type="text" data-id="Text4.2.4.5">
                </div>
                <div class="form-group form-inline no-margin col-md-2">
                    Sunday
                </div>
                <div class="form-group form-inline no-margin col-md-3">
                    <input type="checkbox" id="cmd_0105" name="cmd_0105" formControlName="cmd_0105" value="1">No Office Hours
                </div>
                <div class="form-group form-inline no-margin col-md-2">
                    Morning<input style="width:70%;" class="form-control onboard " id="cmd_0776" name="cmd_0776" formControlName="cmd_0776" type="text" data-id="Sunday">
                </div>
                <div class="form-group form-inline no-margin col-md-6">
                    Afternoon:<input style="width:70%;" class="form-control onboard " id="cmd_0113" name="cmd_0113" formControlName="cmd_0113" type="text" data-id="Text4.2.3.6">
                </div>
                <div class="form-group form-inline no-margin col-md-2">
                    Evening:<input style="width:70%;" class="form-control onboard " id="cmd_0120" name="cmd_0120" formControlName="cmd_0120" type="text" data-id="Text4.2.4.6">
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18">
                <div class="form-group form-inline no-margin col-md-12">
                    <label class="normalParagraph">DOES THIS LOCATION PROVIDE 24 HOUR/7 DAY A WEEK PHONE COVERAGE? </label>
                </div>
                <br/>
                <div class="form-group form-inline no-margin col-md-3">
                    <input type="checkbox" id="cmd_0121" name="cmd_0121" formControlName="cmd_0121" value="1">Answering Service
                </div>
                <div class="form-group form-inline no-margin col-md-12">
                    <input type="checkbox" id="cmd_0122" name="cmd_0122" formControlName="cmd_0122" value="1">Voice mail with instructions to call answering service
                </div>
                <div class="form-group form-inline no-margin col-md-3">
                    <input type="checkbox" id="cmd_0123" name="cmd_0123" formControlName="cmd_0123" value="1">Voice mail with other instructions
                </div>
                <div class="form-group form-inline no-margin col-md-3">
                    <input type="checkbox" id="cmd_0124" name="cmd_0124" formControlName="cmd_0124" value="1">None
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18">
                <div class="form-group form-inline no-margin col-md-12">
                    <label class="normalParagraph">THIS PRACTICE LOCATION ACCEPTS</label>
                </div>
                <br/>
                <div class="form-group form-inline no-margin col-md-3">
                    <input type="checkbox" id="cmd_0125" name="cmd_0125" formControlName="cmd_0125" value="1">all new patients
                </div>
                <div class="form-group form-inline no-margin col-md-3">
                    <input type="checkbox" id="cmd_0126" name="cmd_0126" formControlName="cmd_0126" value="1">existing patients with change of payor
                </div>
                <div class="form-group form-inline no-margin col-md-3">
                    <input type="checkbox" id="cmd_0127" name="cmd_0127" formControlName="cmd_0127" value="1">new patients with referral
                </div>
                <div class="form-group form-inline no-margin col-md-3">
                    <input type="checkbox" id="cmd_0128" name="cmd_0128" formControlName="cmd_0128" value="1"> new Medicare patients
                </div>
                <div class="form-group form-inline no-margin col-md-3">
                    <input type="checkbox" id="cmd_0129" name="cmd_0129" formControlName="cmd_0129" value="1"> new Medicaid patients
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="normalParagraph">IF NEW PATIENT ACCEPTANCE VARIES BY HEALTH PLAN, PLEASE PROVIDE EXPLANATION. </label>
                    <input class="form-control onboard " id="cmd_0130" name="cmd_0130" formControlName="cmd_0130" type="text" data-id="Text4.4.1">
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18">
                <div class="form-group form-inline no-margin col-md-12">
                    <label class="normalParagraph">PRACTICE LIMITATIONS </label>
                </div>
                <br/>
                <div class="form-group form-inline no-margin col-md-3">
                    <input type="radio" id="cmd_0131" name="cmd_0131" formControlName="cmd_0131" value="1">Male only
                </div>
                <div class="form-group form-inline no-margin col-md-3">
                    <input type="radio" id="cmd_0131" name="cmd_0131" formControlName="cmd_0131" value="1">Female only
                </div>
                <div class="form-group form-inline no-margin col-md-12">
                    <label class="normalParagraph">Age:</label>
                    <input style="width:40%;" class="form-control onboard " id="cmd_0777" name="cmd_0777" formControlName="cmd_0777" type="text" data-id="prac1_agespatients">
                </div>
                <div class="form-group form-inline no-margin col-md-3">
                    <input type="radio" id="cmd_0131" name="cmd_0131"  value="1">Other
                    <input style="width:70%;" class="form-control onboard " id="cmd_0132" name="cmd_0132" formControlName="cmd_0132" type="text" data-id="Text4.4.3">
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18">
                <div class="form-group form-inline no-margin col-md-12">
                    <label class="normalParagraph">DO NURSE PRACTITIONERS, PHYSICIAN ASSISTANTS, MIDWIVES, SOCIAL WORKERS OR OTHER NON-PHYSICIAN PROVIDERS CARE FOR PATIENTS AT THIS PRACTICE
                    LOCATION? </label>
                </div>
                <br/>
                <div class="form-group form-inline no-margin col-md-3">
                    <input type="radio" id="cmd_0133" name="cmd_0133" formControlName="cmd_0133" value="1">Yes
                </div>
                <div class="form-group form-inline no-margin col-md-3">
                    <input type="radio" id="cmd_0133" name="cmd_0133" formControlName="cmd_0133" value="0">No
                </div>
                If yes, provide the following information for each staff member:
                <input style="width:70%;" class="form-control onboard " id="cmd_0134" name="cmd_0134" formControlName="cmd_0134" type="text" data-id="Text4.4.3">
            </td>
        </tr>
        <tr>
            <td colspan="18">
                <div class="form-group form-inline no-margin col-md-3 sideLabelAlignment">
                    <label class="normalParagraph">NAME</label>
                    <input class="form-control onboard " id="cmd_0135" name="cmd_0135" formControlName="cmd_0135" type="text" data-id="Text4.3.3.0">
                </div>
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">PROFESSIONAL DESIGNATION </label>
                    <input class="form-control onboard " id="cmd_0136" name="cmd_0136" formControlName="cmd_0136" type="text" data-id="Text4.3.4.0">
                    <input class="form-control onboard " id="cmd_0137" name="cmd_0137" formControlName="cmd_0137" type="text" data-id="Text4.4.4.0.0">
                </div>
                <div class="form-group form-inline no-margin col-md-8 sideLabelAlignment">
                    <label class="normalParagraph">STATE & LICENSE NO.</label>
                    <input class="form-control onboard " id="cmd_0138" name="cmd_0138" formControlName="cmd_0138" type="text" data-id="Text4.4.4.1.0">
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18">
                <div class="form-group form-inline no-margin col-md-3 sideLabelAlignment">
                    <label class="normalParagraph">NAME</label>
                    <input class="form-control onboard " id="cmd_0139" name="cmd_0139" formControlName="cmd_0139" type="text" data-id="Text4.3.3.1.0">
                </div>
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">PROFESSIONAL DESIGNATION </label>
                    <input class="form-control onboard " id="cmd_0140" name="cmd_0140" formControlName="cmd_0140" type="text" data-id="Text4.3.4.1.0">

                    <input class="form-control onboard " id="cmd_0141" name="cmd_0141" formControlName="cmd_0141" type="text" data-id="Text4.4.4.0.1.0">
                </div>
                <div class="form-group form-inline no-margin col-md-8 sideLabelAlignment">
                    <label class="normalParagraph">STATE & LICENSE NO.</label>
                    <input class="form-control onboard " id="cmd_0142" name="cmd_0142" formControlName="cmd_0142" type="text" data-id="Text4.4.4.1.1.0">
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18">
                <div class="form-group form-inline no-margin col-md-3 sideLabelAlignment">
                    <label class="normalParagraph">NAME</label>
                    <input class="form-control onboard " id="cmd_0143" name="cmd_0143" formControlName="cmd_0143" type="text" data-id="Text4.3.3.1.1">
                </div>
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">PROFESSIONAL DESIGNATION </label>
                    <input class="form-control onboard " id="cmd_0144" name="cmd_0144" formControlName="cmd_0144" type="text" data-id="Text4.3.4.1.1">
                    <input class="form-control onboard " id="cmd_0145" name="cmd_0145" formControlName="cmd_0145" type="text" data-id="Text4.4.4.0.1.1">
                </div>
                <div class="form-group form-inline no-margin col-md-8 sideLabelAlignment">
                    <label class="normalParagraph">STATE & LICENSE NO.</label>
                    <input class="form-control onboard " id="cmd_0146" name="cmd_0146" formControlName="cmd_0146" type="text" data-id="Text4.4.4.1.1.1">
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18">
                <div class="form-group form-inline no-margin col-md-3 sideLabelAlignment">
                    <label class="normalParagraph">NAME</label>
                    <input class="form-control onboard " id="cmd_0147" name="cmd_0147" formControlName="cmd_0147" type="text" data-id="Text4.3.3.1.2.0">
                </div>
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">PROFESSIONAL DESIGNATION </label>
                    <input class="form-control onboard " id="cmd_0148" name="cmd_0148" formControlName="cmd_0148" type="text" data-id="Text4.3.4.1.2.0">
                    <input class="form-control onboard " id="cmd_0149" name="cmd_0149" formControlName="cmd_0149"  type="text" data-id="Text4.4.4.0.1.2.0">
                </div>
                <div class="form-group form-inline no-margin col-md-8 sideLabelAlignment">
                    <label class="normalParagraph">STATE & LICENSE NO.</label>
                    <input class="form-control onboard " id="cmd_0150" name="cmd_0150" formControlName="cmd_0150" type="text" data-id="Text4.4.4.1.1.2.0">
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18">
                <div class="form-group form-inline no-margin col-md-3 sideLabelAlignment">
                    <label class="normalParagraph">NAME</label>
                    <input class="form-control onboard " id="cmd_0151" name="cmd_0151" formControlName="cmd_0151" type="text" data-id="Text4.3.3.1.2.1">
                </div>
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">PROFESSIONAL DESIGNATION </label>
                    <input class="form-control onboard " id="cmd_0152" name="cmd_0152" formControlName="cmd_0152" type="text" data-id="Text4.3.4.1.2.1">
                    <input class="form-control onboard " id="cmd_0153" name="cmd_0153" formControlName="cmd_0153" type="text" data-id="Text4.4.4.0.1.2.1">
                </div>
                <div class="form-group form-inline no-margin col-md-8 sideLabelAlignment">
                    <label class="normalParagraph">STATE & LICENSE NO.</label>
                    <input class="form-control onboard " id="cmd_0154" name="cmd_0154" formControlName="cmd_0154" type="text" data-id="Text4.4.4.1.1.2.1">
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18">
                <div class="form-group form-inline no-margin col-md-3 sideLabelAlignment">
                    <label class="normalParagraph">NAME</label>
                    <input class="form-control onboard " id="cmd_0155" name="cmd_0155" formControlName="cmd_0155" type="text" data-id="Text4.3.3.1.2.2">
                </div>
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">PROFESSIONAL DESIGNATION </label>
                    <input class="form-control onboard " id="cmd_0156" name="cmd_0156" formControlName="cmd_0156" type="text" data-id="Text4.3.4.1.2.2">

                    <input class="form-control onboard " id="cmd_0157" name="cmd_0157" formControlName="cmd_0157" type="text" data-id="Text4.4.4.0.1.2.2">
                </div>
                <div class="form-group form-inline no-margin col-md-8 sideLabelAlignment">
                    <label class="normalParagraph">STATE & LICENSE NO.</label>
                    <input class="form-control onboard " id="cmd_0158" name="cmd_0158" formControlName="cmd_0158" type="text" data-id="Text4.4.4.1.1.2.2">
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="9">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">NON-ENGLISH LANGUAGES SPOKEN BY HEALTH CARE PROVIDERS</label>
                    <input class="form-control onboard " id="cmd_0159" name="cmd_0159" formControlName="cmd_0159" type="text" data-id="Text5.4.4">
                </div>
            </td>
            <td colspan="9">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">NON-ENGLISH LANGUAGES SPOKEN BY OFFICE PERSONNEL </label>
                    <input class="form-control onboard " id="cmd_0160" name="cmd_0160" formControlName="cmd_0160" type="text" data-id="Text5.4.2">
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">ARE INTERPRETERS AVAILABLE? </label>
                    <label class="radio-inline radioStyle">
                    <input type="radio" id="cmd_0161" name="cmd_0161" formControlName="cmd_0161" value="1">Yes</label>
                    <label class="radio-inline radioStyle">
                    <input type="radio" id="cmd_0161" name="cmd_0161" formControlName="cmd_0161" value="0">No</label>
                    No If yes, please specify languages:
                    <input class="form-control onboard " id="cmd_0162" name="cmd_0162" formControlName="cmd_0162" type="text" data-id="Text5.4.3">
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="9">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">DOES THIS PRACTICE LOCATION MEET ADA ACCESSIBILITY STANDARDS?</label>
                    <label class="radio-inline radioStyle">
                    <input type="radio" id="cmd_0163" name="cmd_0163" formControlName="cmd_0163" value="1">Yes</label>
                    <label class="radio-inline radioStyle">
                    <input type="radio" id="cmd_0163" name="cmd_0163" formControlName="cmd_0163" value="0">No</label>
                </div>
            </td>
            <td colspan="9">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">WHICH OF THE FOLLOWING FACILITIES ARE HANDICAPPED ACCESSIBLE? </label>
                    <label class="radio-inline radioStyle">
                    <input type="checkbox" id="cmd_0164" name="cmd_0164" formControlName="cmd_0164" value="1">Building</label>
                    <label class="radio-inline radioStyle">
                    <input type="checkbox" id="cmd_0165" name="cmd_0165" formControlName="cmd_0165" value="1">Parking</label>
                    <label class="radio-inline radioStyle">
                    <input type="checkbox" id="cmd_0166" name="cmd_0166" formControlName="cmd_0166" value="0">Restroom</label>
                    <label class="radio-inline radioStyle">
                    <input type="checkbox" id="cmd_0167" name="cmd_0167" formControlName="cmd_0167" value="0">Other:</label>
                    <input class="form-control onboard " id="cmd_0168" name="cmd_0168" formControlName="cmd_0168" type="text" data-id="Text5.3.4">
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">DOES THIS LOCATION HAVE OTHER SERVICES FOR THE DISABLED?  </label>
                    <label class="radio-inline radioStyle">
                    <input type="checkbox" id="cmd_0169" name="cmd_0169" formControlName="cmd_0169" value="1">Text Telephony-TTY</label>
                    <label class="radio-inline radioStyle">
                    <input type="checkbox" id="cmd_0170" name="cmd_0170" formControlName="cmd_0170"  value="0">American Sign Language-ASL</label>
                    <label class="radio-inline radioStyle">
                    <input type="checkbox" id="cmd_0171" name="cmd_0171" formControlName="cmd_0171" value="0">Mental/Physical Impairment Services </label>
                    <label class="radio-inline radioStyle">
                    <input type="checkbox" id="cmd_0172" name="cmd_0172" formControlName="cmd_0172"  value="0">0ther </label>
                    <input class="form-control onboard " id="cmd_0173" name="cmd_0173" formControlName="cmd_0173"  type="text" data-id="Text5.4.1">
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">IS THIS LOCATION ACCESSIBLE BY PUBLIC TRANSPORTATION?  </label>
                    <label class="radio-inline radioStyle">
                    <input type="checkbox" id="cmd_0174" name="cmd_0174" formControlName="cmd_0174" value="0">Bus</label>
                    <label class="radio-inline radioStyle">
                    <input type="checkbox" id="cmd_0175" name="cmd_0175" formControlName="cmd_0175" value="0">Regional Train </label>
                    <label class="radio-inline radioStyle">
                    <input type="checkbox" id="cmd_0176" name="cmd_0176" formControlName="cmd_0176" value="0">0ther </label>
                    <input class="form-control onboard " id="cmd_0177" name="cmd_0177" formControlName="cmd_0177" type="text" data-id="Text5.4.0">
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="9">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">DOES THIS LOCATION PROVIDE CHILDCARE SERVICES? </label>
                    <label class="radio-inline radioStyle">
                    <input type="radio" id="cmd_0178" name="cmd_0178" formControlName="cmd_0178" value="1">Yes</label>
                    <label class="radio-inline radioStyle">
                    <input type="radio" id="cmd_0178" name="cmd_0178" formControlName="cmd_0178" value="0">No</label>
                </div>
            </td>
            <td colspan="9">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">DOES THIS LOCATION QUALIFY AS A MINORITY BUSINESS ENTERPRISE? </label>
                    <label class="radio-inline radioStyle">
                    <input type="radio" id="cmd_0179" name="cmd_0179" formControlName="cmd_0179" value="1">Yes</label>
                    <label class="radio-inline radioStyle">
                    <input type="radio" id="cmd_0179" name="cmd_0179" formControlName="cmd_0179" value="0">No</label>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18">
                <div class="form-group form-inline no-margin col-md-12">
                    <label class="normalParagraph">WHO AT THIS LOCATION HAVE THE FOLLOWING CURRENT CERTIFICATIONS? (PLEASE LIST ONLY THE APPLICANT'S CERTIFICATION EXPIRATION DATES.) </label>
                </div>
                <br/>
                <div class="form-group form-inline no-margin col-md-2">
                    Basic Life Support
                </div>
                <div class="form-group form-inline no-margin col-md-2">
                    <input type="checkbox" id="cmd_0180" name="cmd_0180" formControlName="cmd_0180" value="1">Staff
                </div>
                <div class="form-group form-inline no-margin col-md-8">
                    <input type="checkbox" id="cmd_0181" name="cmd_0181" formControlName="cmd_0181" value="1">Provider Exp:
                    <input style="width:70%;" class="form-control onboard " formControlName="cmd_0182" id="cmd_0182" name="cmd_0182" type="text" data-id="Text5.3.3.0.0">
                </div>
                <div class="form-group form-inline no-margin col-md-2">
                    Advanced Life Support in OB
                </div>
                <div class="form-group form-inline no-margin col-md-2">
                    <input type="checkbox" id="cmd_0183" name="cmd_0183" formControlName="cmd_0183" value="1">Staff
                </div>
                <div class="form-group form-inline no-margin col-md-8">
                    <input type="checkbox" id="cmd_0184" name="cmd_0184" formControlName="cmd_0184" value="1">Provider Exp:
                    <input style="width:70%;" class="form-control onboard " formControlName="cmd_0185" id="cmd_0185" name="cmd_0185" type="text" data-id="Text5.3.3.0.1">
                </div>
                <div class="form-group form-inline no-margin col-md-6">
                    Advanced Trauma Life Support
                </div>
                <div class="form-group form-inline no-margin col-md-2">
                    <input type="checkbox" id="cmd_0186" name="cmd_0186" formControlName="cmd_0186" value="1">Staff
                </div>
                <div class="form-group form-inline no-margin col-md-8">
                    <input type="checkbox" id="cmd_0187" name="cmd_0187" formControlName="cmd_0187" value="1">Provider Exp:
                    <input style="width:70%;" class="form-control onboard " formControlName="cmd_0188" id="cmd_0188" name="cmd_0188" type="text" data-id="Text5.3.2.0.0">
                </div>
                <div class="form-group form-inline no-margin col-md-6">
                    Cardio-Pulmonary Resuscitation
                </div>
                <div class="form-group form-inline no-margin col-md-2">
                    <input type="checkbox" id="cmd_0189" name="cmd_0189"  formControlName="cmd_0189" value="1">Staff
                </div>
                <div class="form-group form-inline no-margin col-md-8">
                    <input type="checkbox" id="cmd_0190" name="cmd_0190" formControlName="cmd_0190" value="1">Provider Exp:
                    <input style="width:70%;" class="form-control onboard " formControlName="cmd_0191" id="cmd_0191" name="cmd_0191" type="text" data-id="Text5.3.2.0.1">
                </div>
                <div class="form-group form-inline no-margin col-md-6">
                    Advanced Cardiac Life Support
                </div>
                <div class="form-group form-inline no-margin col-md-2">
                    <input type="checkbox" id="cmd_0192" name="cmd_0192" formControlName="cmd_0192" value="1">Staff
                </div>
                <div class="form-group form-inline no-margin col-md-8">
                    <input type="checkbox" id="cmd_0193" name="cmd_0193" formControlName="cmd_0193" value="1">Provider Exp:
                    <input style="width:70%;" class="form-control onboard " formControlName="cmd_0194" id="cmd_0194" name="cmd_0194" type="text" data-id="Text5.3.3.1.0">
                </div>
                <div class="form-group form-inline no-margin col-md-6">
                    Pediatric Advanced Life Support
                </div>
                <div class="form-group form-inline no-margin col-md-2">
                    <input type="checkbox" id="cmd_0195" name="cmd_0195" formControlName="cmd_0195" value="1">Staff
                </div>
                <div class="form-group form-inline no-margin col-md-8">
                    <input type="checkbox" id="cmd_0196" name="cmd_0196" formControlName="cmd_0196" value="1">Provider Exp:
                    <input style="width:70%;" class="form-control onboard " formControlName="cmd_0197" id="cmd_0197" name="cmd_0197" type="text" data-id="Text5.3.3.1.1">
                </div>
                <div class="form-group form-inline no-margin col-md-6">
                    Neonatal Advanced Life Support
                </div>
                <div class="form-group form-inline no-margin col-md-2">
                    <input type="checkbox" id="cmd_0198" name="cmd_0198" formControlName="cmd_0198" value="1">Staff
                </div>
                <div class="form-group form-inline no-margin col-md-8">
                    <input type="checkbox" id="cmd_0199" name="cmd_0199" formControlName="cmd_0199" value="1">Provider Exp:
                    <input style="width:70%;" class="form-control onboard " id="cmd_0200" formControlName="cmd_0200" name="cmd_0200" type="text" data-id="Text5.3.2.1.0">
                </div>
                <div class="form-group form-inline no-margin col-md-6">
                    Other (please specify)
                    <input style="width:70%;" class="form-control onboard " id="cmd_0201" name="cmd_0201" formControlName="cmd_0201" type="text" data-id="Text2.0.3">
                </div>
                <div class="form-group form-inline no-margin col-md-2">
                    <input type="checkbox" id="cmd_0202" name="cmd_0202" formControlName="cmd_0202"  value="1">Staff
                </div>
                <div class="form-group form-inline no-margin col-md-8">
                    <input type="checkbox" id="cmd_0203" name="cmd_0203" formControlName="cmd_0203"  value="1">Provider Exp:
                    <input style="width:70%;" class="form-control onboard " id="cmd_0204" formControlName="cmd_0204" name="cmd_0204" type="text" data-id="Text5.3.2.1.1">
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18">
                <div class="form-group form-inline no-margin col-md-8">
                    <label class="normalParagraph">DOES THIS LOCATION PROVIDE ANY OF THE FOLLOWING SERVICES ON SITE? </label>
                </div>
                <div class="form-group form-inline no-margin col-md-2">
                    <input type="radio" id="cmd_0205" name="cmd_0205" formControlName="cmd_0205" value="1">Yes
                </div>
                <div class="form-group form-inline no-margin col-md-2">
                    <input type="radio" id="cmd_0205" name="cmd_0205" formControlName="cmd_0205" value="0">No
                </div>
                <br/>
                <div class="form-group form-inline no-margin col-md-12">
                    <input type="checkbox" id="cmd_0206" name="cmd_0206" formControlName="cmd_0206" value="1"> Laboratory Services; please list all Certificates of Participation (CLIA, AAFP, COLA, CAP, MLE):
                    <input style="width:70%;" class="form-control onboard " id="1233" name="1233" formControlName="1233" type="text" data-id="Text5.3.0">
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18">
                <div class="form-group form-inline no-margin col-md-8">
                    <label class="normalParagraph">DOES THIS LOCATION PROVIDE ANY OF THE FOLLOWING SERVICES ON SITE?</label>
                </div>
                <div class="form-group form-inline no-margin col-md-2">
                    <input type="radio" id="cmd_0207" name="cmd_0207" formControlName="cmd_0207" value="1">Yes
                </div>
                <div class="form-group form-inline no-margin col-md-2">
                    <input type="radio" id="cmd_0207" name="cmd_0207" formControlName="cmd_0207" value="0">No
                </div>
                <br/>
                <div class="form-group form-inline no-margin col-md-12">
                    <input type="checkbox" id="cmd_0208" name="cmd_0208" formControlName="cmd_0208" value="1"> X-ray; please list all certifications:
                    <input style="width:70%;" class="form-control onboard " id="cmd_0720" name="cmd_0720" formControlName="cmd_0720" type="text" data-id="Text5.3.1">
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="3" width="33.33%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="normalParagraph">OTHER SERVICES </label>
                    <br/>
                    <label style="font-weight: bold;" class="checkbox-inline radioStyle">
                    <input type="checkbox"  id="cmd_0209" name="cmd_0209" formControlName="cmd_0209" value="1"> Radiology Services</label>
                    <br/>
                    <label style="font-weight: bold;" class="checkbox-inline radioStyle">
                    <input type="checkbox"  id="cmd_0210" name="cmd_0210" formControlName="cmd_0210" value="1">Allergy Injections</label>
                    <br/>
                    <label style="font-weight: bold;" class="checkbox-inline radioStyle">
                    <input type="checkbox"  id="cmd_0211" name="cmd_0211" formControlName="cmd_0211" value="1">Age Appropriate Immunizations </label>
                    <br/>
                    <label style="font-weight: bold;" class="checkbox-inline radioStyle">
                    <input type="checkbox"  id="cmd_0212" name="cmd_0212" formControlName="cmd_0212"  value="1"> Osteopathic Manipulations </label>
                    <br/>
                    <label style="font-weight: bold;" class="checkbox-inline radioStyle">
                    <input type="checkbox"   id="cmd_0213" name="cmd_0213" formControlName="cmd_0213" value="1">  Other: </label>
                </div>
            </td>
            <td colspan="4" width="33.33%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label style="font-weight: bold;" class="checkbox-inline radioStyle">
                    <input type="checkbox"  id="cmd_0214" name="cmd_0214" formControlName="cmd_0214" value="1">EKG</label>
                    <br/>
                    <label style="font-weight: bold;" class="checkbox-inline radioStyle">
                    <input type="checkbox"  id="cmd_0215" name="cmd_0215" formControlName="cmd_0215" value="1">Allergy Skin Tests </label>
                    <br/>
                    <label style="font-weight: bold;" class="checkbox-inline radioStyle">
                    <input type="checkbox" id="cmd_0216" name="cmd_0216" formControlName="cmd_0216" value="1">Flexible Sigmoidoscopy </label>
                    <br/>
                    <label style="font-weight: bold;" class="checkbox-inline radioStyle">
                    <input type="checkbox"  id="cmd_0217" name="cmd_0217" formControlName="cmd_0217" value="1"> IV Hydration /Treatments </label>
                </div>
            </td>
            <td colspan="4" width="33.33%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label style="font-weight: bold;" class="checkbox-inline radioStyle">
                    <input type="checkbox"  id="cmd_0218" name="cmd_0218" formControlName="cmd_0218"  value="1"> Care of Minor Lacerations </label>
                    <br/>
                    <label style="font-weight: bold;" class="checkbox-inline radioStyle">
                    <input type="checkbox"  id="cmd_0219" name="cmd_0219" formControlName="cmd_0219" value="1">Routine Office Gynecology</label>
                    <br/>
                    <label style="font-weight: bold;" class="checkbox-inline radioStyle">
                    <input type="checkbox"  id="cmd_0220" name="cmd_0220" formControlName="cmd_0220" value="1">Tympanometry/Audiometry Tests </label>
                    <br/>
                    <label style="font-weight: bold;" class="checkbox-inline radioStyle">
                    <input type="checkbox"  id="cmd_0221" name="cmd_0222" formControlName="cmd_0222" value="1">Cardiac Stress Tests </label>
                </div>
            </td>
            <td colspan="6" width="33.33%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label style="font-weight: bold;" class="checkbox-inline radioStyle">
                    <input type="checkbox"  id="cmd_0223" name="cmd_0223" formControlName="cmd_0223"  value="1"> Pulmonary Function Tests</label>
                    <br/>
                    <label style="font-weight: bold;" class="checkbox-inline radioStyle">
                    <input type="checkbox" id="cmd_0224" name="cmd_0224" formControlName="cmd_0224"  value="1">Drawing Blood</label>
                    <br/>
                    <label style="font-weight: bold;" class="checkbox-inline radioStyle">
                    <input type="checkbox"  id="cmd_0225" name="cmd_0225" formControlName="cmd_0225"  value="1">Asthma Treatments  </label>
                    <br/>
                    <label style="font-weight: bold;" class="checkbox-inline radioStyle">
                    <input type="checkbox" id="cmd_0226" name="cmd_0226"  formControlName="cmd_0226"  value="1">Physical Therapies </label>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18">
                <div class="form-group form-inline no-margin col-md-12">
                    <label class="normalParagraph">PLEASE LIST ANY ADDITIONAL OFFICE PROCEDURES PROVIDED (INCLUDING SURGICAL PROCEDURES) </label>
                </div>
                <br/>
                <div class="form-group form-inline no-margin col-md-12">
                    <input style="width:70%;" class="form-control onboard " id="cmd_0227" name="cmd_0227" formControlName="cmd_0227" type="text" data-id="Text5.2.2">
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="16">
                <div class="form-group form-inline no-margin col-md-12">
                    <label class="normalParagraph">IS ANESTHESIA ADMINISTERED AT THIS PRACTICE LOCATION?</label>
                </div>
                <br/>
                <div class="form-group form-inline no-margin col-md-2">
                    <input type="radio" id="cmd_0228" name="cmd_0228" formControlName="cmd_0228" value="1">Yes
                </div>
                <div class="form-group form-inline no-margin col-md-2">
                    <input type="radio" id="cmd_0228" name="cmd_0228" formControlName="cmd_0228" value="0">No
                </div>
                <div class="form-group form-inline no-margin col-md-12">
                    Please specify the classes or categories:
                    <input style="width:70%;" class="form-control onboard " id="cmd_0229" name="cmd_0229" formControlName="cmd_0229" type="text" data-id="Text5.2.4">
                </div>
            </td>
            <td colspan="2">
                <div class="form-group form-inline no-margin col-md-12">
                    <label class="normalParagraph">WHO ADMINISTERS IT? </label>
                </div>
                <br/>
                <div class="form-group form-inline no-margin col-md-12">
                    <input style="width:70%;" class="form-control onboard " id="cmd_0230" name="cmd_0230" formControlName="cmd_0230" type="text" data-id="Text5.2.3">
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18">
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="checkbox-inline radioStyle">
                    <input type="checkbox" id="cmd_0231" name="cmd_0231" formControlName="cmd_0231" value="1">Please check this box and complete and submit Attachment F if you have other practice locations. </label>
                </div>
            </td>
        </tr>

        <tr>
            <td colspan="18">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <b>Section II-Disclosure Questions</b>
                    <label style="font-weight: bold;">- Please provide an explanation for any question answered yes-except 16-on
                    page 10.
                    </label>
                    <br/>
                    <label class="formLabel">Licensure</label>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="2" width="5%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="normalParagraph">1</label>
                </div>
            </td>
            <td colspan="12" width="75%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="normalParagraph">Has your license to practice, in your profession, ever been denied, suspended, revoked, restricted,voluntarily surrendered while under investigation, or have you ever been subject to a consent order,probation or any conditions or limitations by any state licensing board?</label>
                </div>
            </td>
            <td colspan="4" width="20%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="radio-inline radioStyle">
                    <input type="radio" id="cmd_0232" name="cmd_0232" [class.is-invalid]="(texas.controls['cmd_0232'].touched && texas.controls['cmd_0232'].invalid) || (texas.controls['cmd_0232'].invalid && isSubmit)" formControlName="cmd_0232" value="1" (change)="validationsDataAdd($event)">Yes</label>
                    <label class="radio-inline radioStyle">
                    <input type="radio" id="cmd_0232" name="cmd_0232" [class.is-invalid]="(texas.controls['cmd_0232'].touched && texas.controls['cmd_0232'].invalid) || (texas.controls['cmd_0232'].invalid && isSubmit)" formControlName="cmd_0232" value="0" (change)="validationsDataAdd($event)">No</label>
                    <div class="formLabel col-md-11"
                        *ngIf="texas.controls['cmd_0232'].touched && texas.controls['cmd_0232'].invalid">
                        <small class="text-danger" *ngIf="texas.controls['cmd_0232'].errors?.required">
                            required
                        </small>
                    </div>
                    <span *ngIf="(texas.controls['cmd_0232'].touched && texas.controls['cmd_0232'].errors) || (texas.controls['cmd_0232'].errors && isSubmit)">
                        <i class="fas fa-times ml-2 text-danger"></i>
                    </span>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="2" width="5%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="normalParagraph">2</label>
                </div>
            </td>
            <td colspan="12" width="75%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="normalParagraph">Have you ever received a reprimand or been fined by any state licensing board? </label>
                </div>
            </td>
            <td colspan="4" width="20%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="radio-inline radioStyle">
                    <input type="radio" id="cmd_0233" [class.is-invalid]="(texas.controls['cmd_0233'].touched && texas.controls['cmd_0233'].invalid) || (texas.controls['cmd_0233'].invalid && isSubmit)" name="cmd_0233" formControlName="cmd_0233" value="1" (change)="validationsDataAdd($event)">Yes</label>
                    <label class="radio-inline radioStyle">
                    <input type="radio" id="cmd_0233" [class.is-invalid]="(texas.controls['cmd_0233'].touched && texas.controls['cmd_0233'].invalid) || (texas.controls['cmd_0233'].invalid && isSubmit)" name="cmd_0233" formControlName="cmd_0233" value="0" (change)="validationsDataAdd($event)" >No</label>
                    <div class="formLabel col-md-11"
                        *ngIf="texas.controls['cmd_0233'].touched && texas.controls['cmd_0233'].invalid">
                        <small class="text-danger" *ngIf="texas.controls['cmd_0233'].errors?.required">
                            required
                        </small>
                    </div>
                    <span *ngIf="(texas.controls['cmd_0233'].touched && texas.controls['cmd_0233'].errors) || (texas.controls['cmd_0233'].errors && isSubmit)">
                        <i class="fas fa-times ml-2 text-danger"></i>
                    </span>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18" width="5%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="normalParagraph">Hospital Privileges and Other Affiliations
                    </label>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="2" width="5%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="normalParagraph">3</label>
                </div>
            </td>
            <td colspan="12" width="75%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="normalParagraph">Have your clinical privileges or Medical Staff membership at any hospital or healthcare institution ever been denied, suspended, revoked, restricted, denied renewal or subject to probationary or to other disciplinary conditions (for reasons other than non-completion of medical records when quality of care was not adversely affected) or have proceedings toward any of those ends been instituted or recommended by any hospital or healthcare institution, medical staff or committee, or governing board?
                    </label>
                </div>
            </td>
            <td colspan="4" width="20%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="radio-inline radioStyle">
                    <input type="radio" id="cmd_0234" [class.is-invalid]="(texas.controls['cmd_0234'].touched && texas.controls['cmd_0234'].invalid) || (texas.controls['cmd_0234'].invalid && isSubmit)" name="cmd_0234" formControlName="cmd_0234" value="1" (change)="validationsDataAdd($event)" >Yes</label>

                    <label class="radio-inline radioStyle">
                    <input type="radio" id="cmd_0234" [class.is-invalid]="(texas.controls['cmd_0234'].touched && texas.controls['cmd_0234'].invalid) || (texas.controls['cmd_0234'].invalid && isSubmit)" name="cmd_0234" formControlName="cmd_0234" value="0" (change)="validationsDataAdd($event)" >No</label>
                    <div class="formLabel col-md-11"
                        *ngIf="texas.controls['cmd_0234'].touched && texas.controls['cmd_0234'].invalid">
                        <small class="text-danger" *ngIf="texas.controls['cmd_0234'].errors?.required">
                            required
                        </small>
                    </div>
                    <span *ngIf="(texas.controls['cmd_0234'].touched && texas.controls['cmd_0234'].errors) || (texas.controls['cmd_0234'].errors && isSubmit)">
                        <i class="fas fa-times ml-2 text-danger"></i>
                    </span>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="2" width="5%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="normalParagraph">4</label>
                </div>
            </td>
            <td colspan="12" width="75%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="normalParagraph">Have you voluntarily surrendered, limited your privileges or not reapplied for privileges while under investigation?</label>
                </div>
            </td>
            <td colspan="4" width="20%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="radio-inline radioStyle">
                    <input type="radio" id="cmd_0235" [class.is-invalid]="(texas.controls['cmd_0235'].touched && texas.controls['cmd_0235'].invalid) || (texas.controls['cmd_0235'].invalid && isSubmit)" name="cmd_0235" formControlName="cmd_0235" value="1" (change)="validationsDataAdd($event)" >Yes</label>

                    <label class="radio-inline radioStyle">
                    <input type="radio" id="cmd_0235" [class.is-invalid]="(texas.controls['cmd_0235'].touched && texas.controls['cmd_0235'].invalid) || (texas.controls['cmd_0235'].invalid && isSubmit)" name="cmd_0235" formControlName="cmd_0235"  value="0" (change)="validationsDataAdd($event)">No</label>
                    <div class="formLabel col-md-11"
                        *ngIf="texas.controls['cmd_0235'].touched && texas.controls['cmd_0235'].invalid">
                        <small class="text-danger" *ngIf="texas.controls['cmd_0235'].errors?.required">
                            required
                        </small>
                    </div>
                    <span *ngIf="(texas.controls['cmd_0235'].touched && texas.controls['cmd_0235'].errors) || (texas.controls['cmd_0235'].errors && isSubmit)">
                        <i class="fas fa-times ml-2 text-danger"></i>
                    </span>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="2" width="5%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="normalParagraph">5</label>
                </div>
            </td>
            <td colspan="12" width="75%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="normalParagraph">Have you ever been terminated for cause or not renewed for cause from participation, or been subject to any disciplinary action, by any managed care organizations (including HMOs, PPOs, or provider organizations such as IPAs, PHOs)?  </label>
                </div>
            </td>
            <td colspan="4" width="20%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="radio-inline radioStyle">
                    <input type="radio" id="cmd_0236" [class.is-invalid]="(texas.controls['cmd_0236'].touched && texas.controls['cmd_0236'].invalid) || (texas.controls['cmd_0236'].invalid && isSubmit)" name="cmd_0236" formControlName="cmd_0236" value="1" (change)="validationsDataAdd($event)">Yes</label>

                    <label class="radio-inline radioStyle">
                    <input type="radio" id="cmd_0236" [class.is-invalid]="(texas.controls['cmd_0236'].touched && texas.controls['cmd_0236'].invalid) || (texas.controls['cmd_0236'].invalid && isSubmit)" name="cmd_0236" formControlName="cmd_0236" value="0" (change)="validationsDataAdd($event)">No</label>
                    <div class="formLabel col-md-11"
                        *ngIf="texas.controls['cmd_0236'].touched && texas.controls['cmd_0236'].invalid">
                        <small class="text-danger" *ngIf="texas.controls['cmd_0236'].errors?.required">
                            required
                        </small>
                    </div>
                    <span *ngIf="(texas.controls['cmd_0236'].touched && texas.controls['cmd_0236'].errors) || (texas.controls['cmd_0236'].errors && isSubmit)">
                        <i class="fas fa-times ml-2 text-danger"></i>
                    </span>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18" width="5%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="normalParagraph">Education, Training and Board Certification
                    </label>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="2" width="5%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="normalParagraph">6</label>
                </div>
            </td>
            <td colspan="12" width="75%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="normalParagraph">Were you ever placed on probation, disciplined, formally reprimanded, suspended or asked to resign
                    during an internship, residency, fellowship, preceptorship or other clinical education progr am? If you are currently in a training program, have you been placed on probation, discipline d, formally reprimanded, suspended or asked to resign?  </label>
                </div>
            </td>
            <td colspan="4" width="20%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="radio-inline radioStyle">
                    <input type="radio" id="cmd_0237" [class.is-invalid]="(texas.controls['cmd_0237'].touched && texas.controls['cmd_0237'].invalid) || (texas.controls['cmd_0237'].invalid && isSubmit)" name="cmd_0237" formControlName="cmd_0237" value="1" (change)="validationsDataAdd($event)" >Yes</label>

                    <label class="radio-inline radioStyle">
                    <input type="radio" id="cmd_0237" [class.is-invalid]="(texas.controls['cmd_0237'].touched && texas.controls['cmd_0237'].invalid) || (texas.controls['cmd_0237'].invalid && isSubmit)" name="cmd_0237" formControlName="cmd_0237" value="0" (change)="validationsDataAdd($event)">No</label>
                    <div class="formLabel col-md-11"
                        *ngIf="texas.controls['cmd_0237'].touched && texas.controls['cmd_0237'].invalid">
                        <small class="text-danger" *ngIf="texas.controls['cmd_0237'].errors?.required">
                            required
                        </small>
                    </div>
                    <span *ngIf="(texas.controls['cmd_0237'].touched && texas.controls['cmd_0237'].errors) || (texas.controls['cmd_0237'].errors && isSubmit)">
                        <i class="fas fa-times ml-2 text-danger"></i>
                    </span>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="2" width="5%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="normalParagraph">7</label>
                </div>
            </td>
            <td colspan="12" width="75%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="normalParagraph"> Have you ever, while under investigation, voluntarily withdrawn or prematurely terminated your status as a student or employee in any internship, residency, fellowship, preceptorship, or other clinical education program?
                    </label>
                </div>
            </td>
            <td colspan="4" width="20%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="radio-inline radioStyle">
                    <input type="radio" id="cmd_0238" [class.is-invalid]="(texas.controls['cmd_0238'].touched && texas.controls['cmd_0238'].invalid) || (texas.controls['cmd_0238'].invalid && isSubmit)" name="cmd_0238" formControlName="cmd_0238" value="1" (change)="validationsDataAdd($event)">Yes</label>

                    <label class="radio-inline radioStyle">
                    <input type="radio" id="cmd_0238" [class.is-invalid]="(texas.controls['cmd_0238'].touched && texas.controls['cmd_0238'].invalid) || (texas.controls['cmd_0238'].invalid && isSubmit)" name="cmd_0238" formControlName="cmd_0238" value="0" (change)="validationsDataAdd($event)">No</label>
                    <div class="formLabel col-md-11"
                        *ngIf="texas.controls['cmd_0238'].touched && texas.controls['cmd_0238'].invalid">
                        <small class="text-danger" *ngIf="texas.controls['cmd_0238'].errors?.required">
                            required
                        </small>
                    </div>
                    <span *ngIf="(texas.controls['cmd_0238'].touched && texas.controls['cmd_0238'].errors) || (texas.controls['cmd_0238'].errors && isSubmit)">
                        <i class="fas fa-times ml-2 text-danger"></i>
                    </span>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="2" width="5%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="normalParagraph">8</label>
                </div>
            </td>
            <td colspan="12" width="75%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="normalParagraph">Have any of your board certifications or eligibility ever been revoked? </label>
                </div>
            </td>
            <td colspan="4" width="20%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="radio-inline radioStyle">
                    <input type="radio" id="cmd_0239" [class.is-invalid]="(texas.controls['cmd_0239'].touched && texas.controls['cmd_0239'].invalid) || (texas.controls['cmd_0239'].invalid && isSubmit)" name="cmd_0239" formControlName="cmd_0239" value="1" (change)="validationsDataAdd($event)">Yes</label>

                    <label class="radio-inline radioStyle">
                    <input type="radio" id="cmd_0239" [class.is-invalid]="(texas.controls['cmd_0239'].touched && texas.controls['cmd_0239'].invalid) || (texas.controls['cmd_0239'].invalid && isSubmit)" name="cmd_0239" formControlName="cmd_0239" value="0" (change)="validationsDataAdd($event)" onchange="removeValidations(this.id)">No</label>
                    <div class="formLabel col-md-11"
                        *ngIf="texas.controls['cmd_0239'].touched && texas.controls['cmd_0239'].invalid">
                        <small class="text-danger" *ngIf="texas.controls['cmd_0239'].errors?.required">
                            required
                        </small>
                    </div>
                    <span *ngIf="(texas.controls['cmd_0239'].touched && texas.controls['cmd_0239'].errors) || (texas.controls['cmd_0239'].errors && isSubmit)">
                        <i class="fas fa-times ml-2 text-danger"></i>
                    </span>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="2" width="5%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="normalParagraph">9</label>
                </div>
            </td>
            <td colspan="12" width="75%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="normalParagraph">Have you ever chosen not to re-certify or voluntarily surrendered your board certificat ion(s) while under investigation? </label>
                </div>
            </td>
            <td colspan="4" width="20%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="radio-inline radioStyle">
                    <input type="radio" id="cmd_0240" [class.is-invalid]="(texas.controls['cmd_0240'].touched && texas.controls['cmd_0240'].invalid) || (texas.controls['cmd_0240'].invalid && isSubmit)" name="cmd_0240" formControlName="cmd_0240" value="1" (change)="validationsDataAdd($event)">Yes</label>

                    <label class="radio-inline radioStyle">
                    <input type="radio" id="cmd_0240" [class.is-invalid]="(texas.controls['cmd_0240'].touched && texas.controls['cmd_0240'].invalid) || (texas.controls['cmd_0240'].invalid && isSubmit)" name="cmd_0240" formControlName="cmd_0240" value="0" (change)="validationsDataAdd($event)">No</label>
                    <div class="formLabel col-md-11"
                        *ngIf="texas.controls['cmd_0240'].touched && texas.controls['cmd_0240'].invalid">
                        <small class="text-danger" *ngIf="texas.controls['cmd_0240'].errors?.required">
                            required
                        </small>
                    </div>
                    <span *ngIf="(texas.controls['cmd_0240'].touched && texas.controls['cmd_0240'].errors) || (texas.controls['cmd_0240'].errors && isSubmit)">
                        <i class="fas fa-times ml-2 text-danger"></i>
                    </span>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18" width="5%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="normalParagraph">DEA or DPS
                    </label>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="2" width="5%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="normalParagraph">10</label>
                </div>
            </td>
            <td colspan="12" width="75%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="normalParagraph">Have your Federal DEA and/or DPS Controlled Substances Certificate(s) or authorization(s) ever been denied, suspended, revoked, restricted, denied renewal, or voluntarily relinquished?</label>
                </div>
            </td>
            <td colspan="4" width="20%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="radio-inline radioStyle">
                    <input type="radio" id="cmd_0241" [class.is-invalid]="(texas.controls['cmd_0241'].touched && texas.controls['cmd_0241'].invalid) || (texas.controls['cmd_0241'].invalid && isSubmit)" name="cmd_0241" formControlName="cmd_0241" value="1" (change)="validationsDataAdd($event)" onchange="removeValidations(this.id)">Yes</label>

                    <label class="radio-inline radioStyle">
                    <input type="radio" id="cmd_0241" [class.is-invalid]="(texas.controls['cmd_0241'].touched && texas.controls['cmd_0241'].invalid) || (texas.controls['cmd_0241'].invalid && isSubmit)" name="cmd_0241" formControlName="cmd_0241" value="0" (change)="validationsDataAdd($event)" onchange="removeValidations(this.id)">No</label>
                    <div class="formLabel col-md-11"
                        *ngIf="texas.controls['cmd_0241'].touched && texas.controls['cmd_0241'].invalid">
                        <small class="text-danger" *ngIf="texas.controls['cmd_0241'].errors?.required">
                            required
                        </small>
                    </div>
                    <span *ngIf="(texas.controls['cmd_0241'].touched && texas.controls['cmd_0241'].errors) || (texas.controls['cmd_0241'].errors && isSubmit)">
                        <i class="fas fa-times ml-2 text-danger"></i>
                    </span>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18" width="5%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="normalParagraph">Medicare, Medicaid or other Governmental Program Participation
                    </label>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="2" width="5%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="normalParagraph">11</label>
                </div>
            </td>
            <td colspan="12" width="75%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="normalParagraph">Have you ever been disciplined, excluded from, debarred, suspended, reprimanded, sanctioned, censured, disqualified or otherwise restricted in regard to participation in the Medicare or Medicaid program, or in regard to other federal or state governmental health care plans or programs? </label>
                </div>
            </td>
            <td colspan="4" width="20%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="radio-inline radioStyle">
                    <input type="radio" id="cmd_0242" [class.is-invalid]="(texas.controls['cmd_0242'].touched && texas.controls['cmd_0242'].invalid) || (texas.controls['cmd_0242'].invalid && isSubmit)" name="cmd_0242" formControlName="cmd_0242" value="1" (change)="validationsDataAdd($event)" onchange="removeValidations(this.id)">Yes</label>

                    <label class="radio-inline radioStyle">
                    <input type="radio" id="cmd_0242" [class.is-invalid]="(texas.controls['cmd_0242'].touched && texas.controls['cmd_0242'].invalid) || (texas.controls['cmd_0242'].invalid && isSubmit)" name="cmd_0242" formControlName="cmd_0242" value="0" (change)="validationsDataAdd($event)" onchange="removeValidations(this.id)">No</label>
                    <div class="formLabel col-md-11"
                        *ngIf="texas.controls['cmd_0242'].touched && texas.controls['cmd_0242'].invalid">
                        <small class="text-danger" *ngIf="texas.controls['cmd_0242'].errors?.required">
                            required
                        </small>
                    </div>
                    <span *ngIf="(texas.controls['cmd_0242'].touched && texas.controls['cmd_0242'].errors) || (texas.controls['cmd_0242'].errors && isSubmit)">
                        <i class="fas fa-times ml-2 text-danger"></i>
                    </span>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18" width="5%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="normalParagraph">Other Sanctions or Investigations
                    </label>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="2" width="5%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="normalParagraph">12</label>
                </div>
            </td>
            <td colspan="12" width="75%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="normalParagraph">Are you currently or have you ever been the subject of an investigation by any hospital, licensing authority, DEA or DPS authorizing entities, education or training program, Medicare or Medicaid program, or any other private, federal or state health program? </label>
                </div>
            </td>
            <td colspan="4" width="20%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="radio-inline radioStyle">
                    <input type="radio" id="cmd_0243" [class.is-invalid]="(texas.controls['cmd_0243'].touched && texas.controls['cmd_0243'].invalid) || (texas.controls['cmd_0243'].invalid && isSubmit)" name="cmd_0243" formControlName="cmd_0243" value="1" (change)="validationsDataAdd($event)" onchange="removeValidations(this.id)">Yes</label>

                    <label class="radio-inline radioStyle">
                    <input type="radio" id="cmd_0243" [class.is-invalid]="(texas.controls['cmd_0243'].touched && texas.controls['cmd_0243'].invalid) || (texas.controls['cmd_0243'].invalid && isSubmit)" name="cmd_0243" formControlName="cmd_0243" value="0" (change)="validationsDataAdd($event)" onchange="removeValidations(this.id)">No</label>
                    <div class="formLabel col-md-11"
                        *ngIf="texas.controls['cmd_0243'].touched && texas.controls['cmd_0243'].invalid">
                        <small class="text-danger" *ngIf="texas.controls['cmd_0243'].errors?.required">
                            required
                        </small>
                    </div>
                    <span *ngIf="(texas.controls['cmd_0243'].touched && texas.controls['cmd_0243'].errors) || (texas.controls['cmd_0243'].errors && isSubmit)">
                        <i class="fas fa-times ml-2 text-danger"></i>
                    </span>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18" width="5%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="normalParagraph">Other Sanctions or Investigations
                    </label>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="2" width="5%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="normalParagraph">13</label>
                </div>
            </td>
            <td colspan="12" width="75%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="normalParagraph">To your knowledge, has information pertaining to you ever been reported to the National Practitioner Data Bank or Healthcare Integrity and Protection Data Bank? </label>
                </div>
            </td>
            <td colspan="4" width="20%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="radio-inline radioStyle">
                    <input type="radio" id="cmd_0244" [class.is-invalid]="(texas.controls['cmd_0244'].touched && texas.controls['cmd_0244'].invalid) || (texas.controls['cmd_0244'].invalid && isSubmit)" name="cmd_0244" formControlName="cmd_0244" value="1" (change)="validationsDataAdd($event)" onchange="removeValidations(this.id)">Yes</label>

                    <label class="radio-inline radioStyle">
                    <input type="radio" id="cmd_0244" [class.is-invalid]="(texas.controls['cmd_0244'].touched && texas.controls['cmd_0244'].invalid) || (texas.controls['cmd_0244'].invalid && isSubmit)" name="cmd_0244" formControlName="cmd_0244" value="0" (change)="validationsDataAdd($event)" onchange="removeValidations(this.id)">No</label>
                    <div class="formLabel col-md-11"
                        *ngIf="texas.controls['cmd_0244'].touched && texas.controls['cmd_0244'].invalid">
                        <small class="text-danger" *ngIf="texas.controls['cmd_0244'].errors?.required">
                            required
                        </small>
                    </div>
                    <span *ngIf="(texas.controls['cmd_0244'].touched && texas.controls['cmd_0244'].errors) || (texas.controls['cmd_0244'].errors && isSubmit)">
                        <i class="fas fa-times ml-2 text-danger"></i>
                    </span>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="2" width="5%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="normalParagraph">14</label>
                </div>
            </td>
            <td colspan="12" width="75%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="normalParagraph">Have you ever received sanctions from or been the subject of investigation by any regulatory agencies (e.g., CLIA, OSHA, etc.)? </label>
                </div>
            </td>
            <td colspan="4" width="20%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="radio-inline radioStyle">
                    <input type="radio" id="cmd_0245" [class.is-invalid]="(texas.controls['cmd_0245'].touched && texas.controls['cmd_0245'].invalid) || (texas.controls['cmd_0245'].invalid && isSubmit)" name="cmd_0245" formControlName="cmd_0245" value="1" (change)="validationsDataAdd($event)" onchange="removeValidations(this.id)">Yes</label>

                    <label class="radio-inline radioStyle">
                    <input type="radio" id="cmd_0245" [class.is-invalid]="(texas.controls['cmd_0245'].touched && texas.controls['cmd_0245'].invalid) || (texas.controls['cmd_0245'].invalid && isSubmit)" name="cmd_0245" formControlName="cmd_0245" value="0" (change)="validationsDataAdd($event)" onchange="removeValidations(this.id)">No</label>
                    <div class="formLabel col-md-11"
                        *ngIf="texas.controls['cmd_0245'].touched && texas.controls['cmd_0245'].invalid">
                        <small class="text-danger" *ngIf="texas.controls['cmd_0245'].errors?.required">
                            required
                        </small>
                    </div>
                    <span *ngIf="(texas.controls['cmd_0245'].touched && texas.controls['cmd_0245'].errors) || (texas.controls['cmd_0245'].errors && isSubmit)">
                        <i class="fas fa-times ml-2 text-danger"></i>
                    </span>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="2" width="5%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="normalParagraph">15</label>
                </div>
            </td>
            <td colspan="12" width="75%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="normalParagraph">Have you ever been investigated, sanctioned, reprimanded or cautioned by a military hospital,facility, or agency, or voluntarily terminated or resigned while under investigation by a hospital or healthcare facility of any military agency? </label>
                </div>
            </td>
            <td colspan="4" width="20%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="radio-inline radioStyle">
                    <input type="radio" id="cmd_0246" [class.is-invalid]="(texas.controls['cmd_0246'].touched && texas.controls['cmd_0246'].invalid) || (texas.controls['cmd_0246'].invalid && isSubmit)" name="cmd_0246" formControlName="cmd_0246" value="1" (change)="validationsDataAdd($event)" onchange="removeValidations(this.id)">Yes</label>

                    <label class="radio-inline radioStyle">
                    <input type="radio" id="cmd_0246" [class.is-invalid]="(texas.controls['cmd_0246'].touched && texas.controls['cmd_0246'].invalid) || (texas.controls['cmd_0246'].invalid && isSubmit)" name="cmd_0246" formControlName="cmd_0246" value="0" (change)="validationsDataAdd($event)" onchange="removeValidations(this.id)">No</label>
                    <div class="formLabel col-md-11"
                        *ngIf="texas.controls['cmd_0246'].touched && texas.controls['cmd_0246'].invalid">
                        <small class="text-danger" *ngIf="texas.controls['cmd_0246'].errors?.required">
                            required
                        </small>
                    </div>
                    <span *ngIf="(texas.controls['cmd_0246'].touched && texas.controls['cmd_0246'].errors) || (texas.controls['cmd_0246'].errors && isSubmit)">
                        <i class="fas fa-times ml-2 text-danger"></i>
                    </span>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18" width="5%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="normalParagraph">Malpractice Claims History
                    </label>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="2" width="5%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="normalParagraph">16</label>
                </div>
            </td>
            <td colspan="12" width="75%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="normalParagraph">Have you had any malpractice actions within the past 5 years (pending, settled, arbitrated , mediated or litigated?
                    </label>
                </div>
            </td>
            <td colspan="4" width="20%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="radio-inline radioStyle">
                    <input type="radio" id="cmd_0247" [class.is-invalid]="(texas.controls['cmd_0247'].touched && texas.controls['cmd_0247'].invalid) || (texas.controls['cmd_0247'].invalid && isSubmit)" name="cmd_0247" formControlName="cmd_0247" value="1" (change)="validationsDataAdd($event)" onchange="removeValidations(this.id)">Yes</label>

                    <label class="radio-inline radioStyle">
                    <input type="radio" id="cmd_0247" [class.is-invalid]="(texas.controls['cmd_0247'].touched && texas.controls['cmd_0247'].invalid) || (texas.controls['cmd_0247'].invalid && isSubmit)" name="cmd_0247" formControlName="cmd_0247" value="0" (change)="validationsDataAdd($event)" onchange="removeValidations(this.id)">No</label>
                    <div class="formLabel col-md-11"
                        *ngIf="texas.controls['cmd_0247'].touched && texas.controls['cmd_0247'].invalid">
                        <small class="text-danger" *ngIf="texas.controls['cmd_0247'].errors?.required">
                            required
                        </small>
                    </div>
                    <span *ngIf="(texas.controls['cmd_0247'].touched && texas.controls['cmd_0247'].errors) || (texas.controls['cmd_0247'].errors && isSubmit)">
                        <i class="fas fa-times ml-2 text-danger"></i>
                    </span>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18" width="5%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="normalParagraph">Criminal </label>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="2" width="5%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="normalParagraph">17</label>
                </div>
            </td>
            <td colspan="12" width="75%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="normalParagraph">Have you ever been convicted of, pled guilty to, or pled nolo contendere to any felony that is reasonably related to your qualifications, competence, functions, or duties as a medical professional  </label>
                </div>
            </td>
            <td colspan="4" width="20%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="radio-inline radioStyle">
                    <input type="radio" id="cmd_0248" [class.is-invalid]="(texas.controls['cmd_0248'].touched && texas.controls['cmd_0248'].invalid) || (texas.controls['cmd_0248'].invalid && isSubmit)" name="cmd_0248" formControlName="cmd_0248" value="1" (change)="validationsDataAdd($event)" onchange="removeValidations(this.id)">Yes</label>

                    <label class="radio-inline radioStyle">
                    <input type="radio" id="cmd_0248" [class.is-invalid]="(texas.controls['cmd_0248'].touched && texas.controls['cmd_0248'].invalid) || (texas.controls['cmd_0248'].invalid && isSubmit)" name="cmd_0248" formControlName="cmd_0248" value="0" (change)="validationsDataAdd($event)" onchange="removeValidations(this.id)">No</label>
                    <div class="formLabel col-md-11"
                        *ngIf="texas.controls['cmd_0248'].touched && texas.controls['cmd_0248'].invalid">
                        <small class="text-danger" *ngIf="texas.controls['cmd_0248'].errors?.required">
                            required
                        </small>
                    </div>
                    <span *ngIf="(texas.controls['cmd_0248'].touched && texas.controls['cmd_0248'].errors) || (texas.controls['cmd_0248'].errors && isSubmit)">
                        <i class="fas fa-times ml-2 text-danger"></i>
                    </span>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="2" width="5%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="normalParagraph">18</label>
                </div>
            </td>
            <td colspan="12" width="75%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="normalParagraph">Have you ever been convicted of, pled guilty to, or pled nolo contendere to any felony including an act of violence, child abuse or a sexual offense?  </label>
                </div>
            </td>
            <td colspan="4" width="20%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="radio-inline radioStyle">
                    <input type="radio" id="cmd_0249" [class.is-invalid]="(texas.controls['cmd_0249'].touched && texas.controls['cmd_0249'].invalid) || (texas.controls['cmd_0249'].invalid && isSubmit)" name="cmd_0249" formControlName="cmd_0249" value="1" (change)="validationsDataAdd($event)" onchange="removeValidations(this.id)">Yes</label>

                    <label class="radio-inline radioStyle">
                    <input type="radio" id="cmd_0249" [class.is-invalid]="(texas.controls['cmd_0249'].touched && texas.controls['cmd_0249'].invalid) || (texas.controls['cmd_0249'].invalid && isSubmit)" name="cmd_0249" formControlName="cmd_0249" value="0" (change)="validationsDataAdd($event)" onchange="removeValidations(this.id)">No</label>
                    <div class="formLabel col-md-11"
                        *ngIf="texas.controls['cmd_0249'].touched && texas.controls['cmd_0249'].invalid">
                        <small class="text-danger" *ngIf="texas.controls['cmd_0249'].errors?.required">
                            required
                        </small>
                    </div>
                    <span *ngIf="(texas.controls['cmd_0249'].touched && texas.controls['cmd_0249'].errors) || (texas.controls['cmd_0249'].errors && isSubmit)">
                        <i class="fas fa-times ml-2 text-danger"></i>
                    </span>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="2" width="5%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="normalParagraph">19</label>
                </div>
            </td>
            <td colspan="12" width="75%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="normalParagraph">Have you been court-martialed for actions related to your duties as a medical professional?  </label>
                </div>
            </td>
            <td colspan="4" width="20%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="radio-inline radioStyle">
                    <input type="radio" id="cmd_0250" [class.is-invalid]="(texas.controls['cmd_0250'].touched && texas.controls['cmd_0250'].invalid) || (texas.controls['cmd_0250'].invalid && isSubmit)" name="cmd_0250" formControlName="cmd_0250" value="1" (change)="validationsDataAdd($event)" onchange="removeValidations(this.id)">Yes</label>

                    <label class="radio-inline radioStyle">
                    <input type="radio" id="cmd_0250" [class.is-invalid]="(texas.controls['cmd_0250'].touched && texas.controls['cmd_0250'].invalid) || (texas.controls['cmd_0250'].invalid && isSubmit)" name="cmd_0250" formControlName="cmd_0250" value="0" (change)="validationsDataAdd($event)" onchange="removeValidations(this.id)">No</label>
                    <div class="formLabel col-md-11"
                        *ngIf="texas.controls['cmd_0250'].touched && texas.controls['cmd_0250'].invalid">
                        <small class="text-danger" *ngIf="texas.controls['cmd_0250'].errors?.required">
                            required
                        </small>
                    </div>
                    <span *ngIf="(texas.controls['cmd_0250'].touched && texas.controls['cmd_0250'].errors) || (texas.controls['cmd_0250'].errors && isSubmit)">
                        <i class="fas fa-times ml-2 text-danger"></i>
                    </span>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18" width="5%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="normalParagraph">Ability to Perform Job </label>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="2" width="5%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="normalParagraph">20</label>
                </div>
            </td>
            <td colspan="12" width="75%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="normalParagraph">Are you currently engaged in the illegal use of drugs? ("Currently" means sufficiently recent to justify a
                    reasonable belief that the use of drug may have an ongoing impact on one's ability to pr actice medicine. It is not limited to the day of, or within a matter of days or weeks before the d ate of application, rather that it has occurred recently enough to indicate the individual is actively engaged in such conduct. "Illegal use of drugs" refers to drugs whose possession or distribution is unlawful under the Controlled Substances Act, 21 U.S.C. § 812.22. It "does not include the use of a drug taken under supervision by a licensed health care professional, or other uses authorized by the Con trolled Substances Act or other provision of Federal law." The term does include, however, the unlawful use of
                    prescription controlled substances.)
                    </label>
                </div>
            </td>
            <td colspan="4" width="20%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="radio-inline radioStyle">
                    <input type="radio" id="cmd_0251"  [class.is-invalid]="(texas.controls['cmd_0251'].touched && texas.controls['cmd_0251'].invalid) || (texas.controls['cmd_0251'].invalid && isSubmit)" name="cmd_0251" formControlName="cmd_0251" value="1"  (change)="validationsDataAdd($event)">Yes</label>

                    <label class="radio-inline radioStyle">
                    <input type="radio" id="cmd_0251" [class.is-invalid]="(texas.controls['cmd_0251'].touched && texas.controls['cmd_0251'].invalid) || (texas.controls['cmd_0251'].invalid && isSubmit)" name="cmd_0251"  formControlName="cmd_0251" value="0"  (change)="validationsDataAdd($event)">No</label>
                    <div class="formLabel col-md-11"
                        *ngIf="texas.controls['cmd_0251'].touched && texas.controls['cmd_0251'].invalid">
                        <small class="text-danger" *ngIf="texas.controls['cmd_0251'].errors?.required">
                            required
                        </small>
                    </div>
                    <span *ngIf="(texas.controls['cmd_0251'].touched && texas.controls['cmd_0251'].errors) || (texas.controls['cmd_0251'].errors && isSubmit)">
                        <i class="fas fa-times ml-2 text-danger"></i>
                    </span>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="2" width="5%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="normalParagraph">21</label>
                </div>
            </td>
            <td colspan="12" width="75%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="normalParagraph">Do you use any chemical substances that would in any way impair or limit your ability to practice medicine and perform the functions of your job with reasonable skill and safety? </label>
                </div>
            </td>
            <td colspan="4" width="20%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="radio-inline radioStyle">
                    <input type="radio" id="cmd_0252" [class.is-invalid]="(texas.controls['cmd_0252'].touched && texas.controls['cmd_0252'].invalid) || (texas.controls['cmd_0252'].invalid && isSubmit)" name="cmd_0252" formControlName="cmd_0252" value="1" (change)="validationsDataAdd($event)" onchange="removeValidations(this.id)">Yes</label>

                    <label class="radio-inline radioStyle">
                    <input type="radio" id="cmd_0252" [class.is-invalid]="(texas.controls['cmd_0252'].touched && texas.controls['cmd_0252'].invalid) || (texas.controls['cmd_0252'].invalid && isSubmit)" name="cmd_0252" formControlName="cmd_0252" value="0" (change)="validationsDataAdd($event)" onchange="removeValidations(this.id)">No</label>
                    <div class="formLabel col-md-11"
                        *ngIf="texas.controls['cmd_0252'].touched && texas.controls['cmd_0252'].invalid">
                        <small class="text-danger" *ngIf="texas.controls['cmd_0252'].errors?.required">
                            required
                        </small>
                    </div>
                    <span *ngIf="(texas.controls['cmd_0252'].touched && texas.controls['cmd_0252'].errors) || (texas.controls['cmd_0252'].errors && isSubmit)">
                        <i class="fas fa-times ml-2 text-danger"></i>
                    </span>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18" width="5%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="normalParagraph">Ability to Perform Job </label>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="2" width="5%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="normalParagraph">22</label>
                </div>
            </td>
            <td colspan="12" width="75%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="normalParagraph">Do you have any reason to believe that you would pose a risk to the safety or well-being of your patients?</label>
                </div>
            </td>
            <td colspan="4" width="20%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="radio-inline radioStyle">
                    <input type="radio" id="cmd_0253" [class.is-invalid]="(texas.controls['cmd_0253'].touched && texas.controls['cmd_0253'].invalid) || (texas.controls['cmd_0253'].invalid && isSubmit)" name="cmd_0253" formControlName="cmd_0253" value="1" (change)="validationsDataAdd($event)" onchange="removeValidations(this.id)">Yes</label>

                    <label class="radio-inline radioStyle">
                    <input type="radio" id="cmd_0253" [class.is-invalid]="(texas.controls['cmd_0253'].touched && texas.controls['cmd_0253'].invalid) || (texas.controls['cmd_0253'].invalid && isSubmit)" name="cmd_0253" formControlName="cmd_0253" value="0" (change)="validationsDataAdd($event)" onchange="removeValidations(this.id)">No</label>
                    <div class="formLabel col-md-11"
                        *ngIf="texas.controls['cmd_0253'].touched && texas.controls['cmd_0253'].invalid">
                        <small class="text-danger" *ngIf="texas.controls['cmd_0253'].errors?.required">
                            required
                        </small>
                    </div>
                    <span *ngIf="(texas.controls['cmd_0253'].touched && texas.controls['cmd_0253'].errors) || (texas.controls['cmd_0253'].errors && isSubmit)">
                        <i class="fas fa-times ml-2 text-danger"></i>
                    </span>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="2" width="5%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="normalParagraph">23</label>
                </div>
            </td>
            <td colspan="12" width="75%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="normalParagraph">Are you unable to perform the essential functions of a practitioner in your area of practice, with or without reasonable accommodation? </label>
                </div>
            </td>
            <td colspan="4" width="20%">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="radio-inline radioStyle">
                    <input type="radio" id="cmd_0254" [class.is-invalid]="(texas.controls['cmd_0254'].touched && texas.controls['cmd_0254'].invalid) || (texas.controls['cmd_0254'].invalid && isSubmit)" name="cmd_0254" formControlName="cmd_0254" value="1" (change)="validationsDataAdd($event)" onchange="removeValidations(this.id)">Yes</label>

                    <label class="radio-inline radioStyle">
                    <input type="radio" id="cmd_0254" [class.is-invalid]="(texas.controls['cmd_0254'].touched && texas.controls['cmd_0254'].invalid) || (texas.controls['cmd_0254'].invalid && isSubmit)" name="cmd_0254" formControlName="cmd_0254" value="0" (change)="validationsDataAdd($event)" onchange="removeValidations(this.id)">No</label>
                    <div class="formLabel col-md-11"
                        *ngIf="texas.controls['cmd_0254'].touched && texas.controls['cmd_0254'].invalid">
                        <small class="text-danger" *ngIf="texas.controls['cmd_0254'].errors?.required">
                            required
                        </small>
                    </div>
                    <span *ngIf="(texas.controls['cmd_0254'].touched && texas.controls['cmd_0254'].errors) || (texas.controls['cmd_0254'].errors && isSubmit)">
                        <i class="fas fa-times ml-2 text-danger"></i>
                    </span>
                </div>
            </td>
        </tr>
    </table>
</div>
