import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MonthModeComponent } from './month-mode/month-mode.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CustomDateModalComponent } from './custom-date-modal/custom-date-modal.component';
import { MaskInputDirective } from './MaskInputDirective.directive';
import { MaskInputDirectiveForMonth } from './MaskInputDirectiveForMonth.directive';



@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgbModule,

  ],
  declarations: [MonthModeComponent, CustomDateModalComponent,MaskInputDirective,MaskInputDirectiveForMonth],
  exports:[MonthModeComponent,CustomDateModalComponent,MaskInputDirective,MaskInputDirectiveForMonth]
})
export class SharedComponentsModule { }
