<div class="modal-header">
    <h4 class="modal-title">Status Update</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.close(modalResponse)">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <form #userForm="ngForm">
        <div class="row">
            <div class="form-group col-md-12">
                <label>Status</label>
                <ng-select [items]="statusDropdown" bindLabel="label" placeholder="Select Status" bindValue="id"
                    [(ngModel)]="status" name="status" [clearable]="false">
                </ng-select>
            </div>
        </div>
        <div *ngIf="FlagForSubstatus==true" class="row">
            <div class="form-group col-md-12">
                <label>Sub Status</label>
                <ng-select [items]="substatusDropdown" [(ngModel)]="subStatus"  placeholder="Select Substatus Status" bindLabel="name" bindValue="id" name="subStatus"
                 [clearable]="false">
                </ng-select>
            </div>
        </div>
        <div *ngIf="popupType.date || status==4" class="row">
            <div *ngIf="status!=4" class="form-group col-md-12">
                <label for="provider">Due Date <span *ngIf="!(status==3 || status==8)" class="mandatory">*</span></label>
                <div class="input-group">
                    <input appMaskInput [appMaskValue]="this.date" class="form-control" [(ngModel)]="date" name="dp" placeholder="Select Due Date" ngbDatepicker
                        #due="ngModel" [required]="!(status==3 || status==8)" #d2="ngbDatepicker" container="body">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d2.toggle()" type="button"><i
                                class="fas fa-calendar-alt"></i></button>
                    </div>
                </div>
                <small class="mandatory" [class.d-none]="due.valid || due.untouched">Due Date is Required</small>
            </div>
            <div *ngIf="status==4" class="form-group col-md-12">
                <label for="provider">Effective Date </label>
                <div class="input-group">
                    <input appMaskInput [appMaskValue]="this.effDate" class="form-control" [(ngModel)]="effDate" name="dp" placeholder="Select Effective Date" ngbDatepicker
                        #eff="ngModel"  #d1="ngbDatepicker" container="body">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d1.toggle()" type="button"><i
                                class="fas fa-calendar-alt"></i></button>
                    </div>
                </div>
                <small class="mandatory" [class.d-none]="eff.valid || eff.untouched">Effective Date is Required</small>
            </div>
            <div *ngIf="status==4" class="form-group col-md-12">
                <label for="provider">Provider Number </label>
                <input id='provider' name="provider" type="text" class="form-control"
                [(ngModel)]="providerNumber" #pro="ngModel" >
                <small class="mandatory" [class.d-none]="pro.valid || pro.untouched">Provider Number is Required</small>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer">
    <button *ngIf="!readOnlyUser" type="button" class="btn customOrange float-right" (click)="onSave()" [disabled]="userForm.invalid">Save</button>
    <div type="button" class="btn customOrange float-right" (click)="activeModal.close(modalResponse)">Close</div>
</div>
