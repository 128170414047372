import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, ValidationErrors, FormControl } from '@angular/forms';
import { OnboardingformsServiceService } from '../../services/onboardingforms-service/onboardingforms-service.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToasterNotiService } from '../../services/notifications/toaster-noti.service';
import {
  NgbModal,
  NgbModalOptions,
  NgbDateAdapter,
  NgbDateParserFormatter
} from '@ng-bootstrap/ng-bootstrap';
import { EsignComponent } from '../esign/esign.component';
import { DomSanitizer } from '@angular/platform-browser';
import {
  CustomAdapter,
  CustomDateParserFormatter
} from '../../DateFormatter/customDate';
import { StorageService } from '../../services/storage/storage.service';

@Component({
  selector: 'ctg-ga-unified',
  templateUrl: './ga-unified.component.html',
  styleUrls: ['./ga-unified.component.scss'],
  providers: [
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }
  ]
})
export class GaUnifiedComponent implements OnInit {
  @Input() formData;
  @Input() emailCustId;
  @Input() fieldsData;
  @Input() formId;
  @Output() onSubmit = new EventEmitter();
  @Output() onClose = new EventEmitter();
  public gaUnifiedForm: UntypedFormGroup;
  modalOption: NgbModalOptions = {
    size: 'md',
    keyboard: false,
    backdrop: 'static'
  };
  suffix: Array<object> = [];
  degree: Array<object> = [];
  states: Array<object> = [];
  specialty: Array<object> = [];
  specialtySecondary: Array<object> = [];
  languages: Array<object> = [];
  secondaryLanguages: Array<object> = [];
  tertiaryLanguages: Array<object> = [];
  altLicenseState: Array<object> = [];
  additionalLicenseState: Array<object> = [];
  quaternaryLanguages: Array<object> = [];
  credential: Array<object> = [];
  staffStatus: Array<object> = [];
  boardCertification: Array<object> = [];
  preClaim: Array<object> = [];
  aggregates: Array<object> = [];
  id: '';
  finalImgPath: any = [];
  finalImg: any;
  finalImgPathTwo: any = [];
  finalImgTwo: any = '';
  finalImgPathThree: any = [];
  finalImgThree: any = '';
  finalImgPathFour: any = [];
  finalImgFour: any = '';
  esignOne: any;
  esignTwo: any;
  esignThree: any;
  esignFour: any;
  showOne: any = false;
  showTwo: any = false;
  showThree: any = false;
  showFour: any = false;
  isSubmit: boolean = false;
  public mlpDoesNotApply: boolean = false;
  public pIDoesNotApply: boolean = false;

  public pliDoesnot: boolean = false;
  public peerDoesnot: boolean = false;
  public otherFacilityDoesnot: boolean = false;
  public preHospDoesnot: boolean = false;
  public hospInprocessDoesnot: boolean = false;
  public otherStateCareDoesnot: boolean = false;
  public militaryHealthDoesnot: boolean = false;
  public currentHospDoesnot: boolean = false;
  public proWorkHistoryDoesnot: boolean = false;
  public delegateDoesnot: boolean = false;
  public facultyPositionsDoesnot: boolean = false;
  public otherClinicalDoesnot: boolean = false;
  public fellowshipDoesnot: boolean = false;
  public residencyDoesnot: boolean = false;
  public intersnipDoesnot: boolean = false;
  public ForeignMedicalDoesnot: boolean = false;
  county: Array<object> = [];
  locDropdown: Array<any> = [];
  public MedicalProfessionalDoesnot: boolean = false;
  public graduateDegreeDoesnot: boolean = false;
  public ExplanInterruptionDoesnot: boolean = false;
  public boardCertiDoesnot: boolean = false;
  public drugenforcementDoesnot: boolean = false;
  public proworkhistDoesnot: boolean = false;
  ImgOne: any;
  public customerId: any;
  imgOneSet: any;
  imgTwoSet: any;
  imgOneSetting: any;
  credentials:Array<any>=[]
  private emailPattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  constructor(
    private fb: UntypedFormBuilder,
    private onboardService: OnboardingformsServiceService,
    private spinner: NgxSpinnerService,
    private notiService: ToasterNotiService,
    private modalService: NgbModal,
    private _sanitizer: DomSanitizer,
    private storageService: StorageService
  ) { }

  ngOnInit(): void {
    this.customerId = Number(this.storageService.getItem('customerId'));
    this.buildForm();
    this.getSuffixData();
    this.getDegreeData();
    this.getStatesData();
    this.getAlternateLicenseStateData();
    this.getSpecialtyData();
    this.getCountyData();
    this.getAdditionalLicenseStateData();
    this.getSpecialtySecondaryData();
    this.getCredentialsData();
    this.getStaffStatusData();
    this.getLanguagesData();
    this.getLocationData();
    this.getsecondaryLanguagesData();
    this.gettertiaryLanguagesData();
    this.getquaternaryLanguagesData();
    this.getBoardCertificationData();
    this.getPreClaimData();
    this.getAggregatesData();
    this.fieldsData && this.bindFormValue(this.fieldsData);
    this.afterSaveDataDisplay();
  }

  public bindFormValue(array) {
    let fieldsCtrls = {};
    for (let i = 0; i < array.length; i++) {
      fieldsCtrls[array[i].field_name] = array[i].field_value;
      if (array[i].field_name == 'cmd_09911') {
        if (array[i].field_value != '') {
          this.previewEsign(array[i].field_value, array[i].field_name);
        }
      }
      if (array[i].field_name == 'cmd_0992') {
        if (array[i].field_value != '') {
          this.previewEsign(array[i].field_value, array[i].field_name);
        }
      }
      if (array[i].field_name == 'cmd_09931') {
        if (array[i].field_value != '') {
          this.previewEsign(array[i].field_value, array[i].field_name);
        }
      }
    }
    this.gaUnifiedForm.patchValue(fieldsCtrls);
  }

  public previewEsign(filePath, fieldId) {
    //this.spinner.show();
    let params = {
      filePath: filePath
    };
    this.onboardService.getPreviewEsign(params).subscribe(
      (data: any) => {
        if (data['success']) {
          this.ImgOne = data.base64;
          this.setEsign(this.ImgOne, fieldId);
        } else {
          //this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public setEsign(filePath, fieldId) {
    this.imgOneSetting = 1;
    // console.log(filePath);
    if (fieldId == 'cmd_09911') {
      this.esignOne = this._sanitizer.bypassSecurityTrustResourceUrl(
        'data:image/jpg;base64,' + filePath
      );
      this.showOne = true;
    }
    if (fieldId == 'cmd_0992') {
      this.esignTwo = this._sanitizer.bypassSecurityTrustResourceUrl(
        'data:image/jpg;base64,' + filePath
      );
      this.showTwo = true;
    }
    if (fieldId == 'cmd_09931') {
      this.esignThree = this._sanitizer.bypassSecurityTrustResourceUrl(
        'data:image/jpg;base64,' + filePath
      );
      this.showThree = true;
    }
    // if (fieldId == 'cmd_100072') {
    //   this.esignFour = this._sanitizer.bypassSecurityTrustResourceUrl(
    //     'data:image/jpg;base64,' + filePath
    //   );
    //   this.showFour = true;
    // }
  }

  public afterSaveDataDisplay() {
    let params = {
      type: 32,
      formId: this.formId
    };
    //this.spinner.show();
    this.onboardService.toGetSaveData(params).subscribe(
      data => {
        if (data['success']) {
          this.bindFormValue(data.data);
        } else {
          //this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.notiService.showError(Error_Message, '', 3000);
      }
    );
  }

  public getCountyData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getCountyData(params).subscribe(
      data => {
        if (data['success']) {
          this.county = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getLocationData() {
    let params: {};
    if (this.emailCustId) {
      params = {
        customerId: this.emailCustId
      };
    } else {
      params = {
        customerId: this.customerId
      };
    }
    // console.log(this.location);
    //this.spinner.show();
    this.onboardService.getLocationData(params).subscribe(
      data => {
        if (data['success']) {
          this.locDropdown = data.data.map(i => ({
            ...i,
            id: i.location_id.toString(),
            label:
              (i.practice_name ? i.practice_name : '') +
              ' ' +
              (i.practice__add1 ? i.practice__add1 : '') +
              ' ' +
              (i.practice__add2 ? i.practice__add2 : '') +
              ' ' +
              (i.tin ? i.tin : '')
          }));
          // console.log(this.locDropdown);
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  buildForm() {
    this.gaUnifiedForm = this.fb.group({
      cmd_0171: [''],
      cmd_172: [''],

      //cmd_100072: [''],
      cmd_06 : [''],
      cmd_07 : [''],
      cmd_08 : [''],
      cmd_100041: [null],
      cmd_01556 : [null, [this.dateFormatValidator] ],
      cmd_01555: [''],
      cmd_01558 : [null],
      cmd_01559 : [null],
      cmd_01557 : [''],
      cmd_01560 : [''],
      cmd_01561 : [''],
      cmd_01562 : [''],
      cmd_01563 : [''],
      cmd_01564 : [''],
      cmd_01565 : [''],
      cmd_01569 : [''],
      cmd_01566 : [''],
      cmd_01567 : [''],
      cmd_01568 : [''],
      cmd_010 : [''],
      cmd_011 : [''],
      cmd_01515 : [''],
      cmd_01516 : [''],
      cmd_01517 : [''],
      8: ['', [Validators.required]],
      9: ['', [Validators.required]],
      29: [null],
      809: [''],
      822: [null, [Validators.required]],
      cmd_180: [''],
      278: [''],
      2539: [''],
      cmd_04411: [''],
      1159: [''],
      1148: [''],
      280: [null, [this.dateFormatValidator] ],
      1160: [''],
      1239: [null],
      1149: [''],
      410: [''],
      412 :  [null],
      413: [''],
      //cmd_01510: ['', [Validators.required]],
      cmd_01502: [''],
      cmd_01501: [''],
      cmd_01504: [''],
      cmd_01503: [''],
      cmd_01505: [null, [this.dateFormatValidator] ],
      cmd_01506: [null, [this.dateFormatValidator] ],
      cmd_01507: [null, [this.dateFormatValidator] ],
      cmd_01508: [''],
      cmd_01509: [''],
      339: [null, [this.dateFormatValidator] ],
      965: [null],
      cmd_01526: [null, [this.dateFormatValidator] ],
      281: [null, [this.dateFormatValidator] ],
      16: ['', [Validators.required]],
      17: ['', [Validators.required]],
      18: ['', [Validators.required]],
      20: [null, [Validators.required]],
      21: [
        '',
        [Validators.required, Validators.minLength(5), Validators.maxLength(10)]
      ],
      cmd_100040 : [null],
      cmd_05 : [''],
      cmd_0172 : [''],
      cmd_01523 : [''],
      cmd_01534 : [null, [this.dateFormatValidator] ],
      cmd_01524 : [''],
      cmd_01525 : [null],
      cmd_015256 : [null],
      cmd_01527 : [''],
      cmd_01528 : [''],
      cmd_01529 : [''],
      cmd_01535 : [''],
      cmd_01532 : [null],
      cmd_01533 : [''],
      cmd_01536 : [''],
      cmd_01537 : [''],
      cmd_1538 : [''],
      cmd_01531 : [''],
      cmd_01530 :[''],
      cmd_1539 : [''],
      cmd_01540 : [''],
      cmd_01541 : [''],
      cmd_01542 : [''],
      cmd_01543 : [''],
      cmd_01544 : [''],
      cmd_01546 : [''],
      cmd_01547 : [''],
      cmd_01549 : [''],
      cmd_01545 : [null],
      cmd_01548 : [null],
      cmd_01550 : [''],
      cmd_01551 : [''],
      cmd_01552 : [''],
      cmd_01553 : [''],
      708: ['', [Validators.required]],
      759: ['', [Validators.required]],
      292: [''],
      31: [null, [Validators.required,this.dateFormatValidator]],
      810: ['', [Validators.required]],
      289: [null, [Validators.required]],
      30: ['', [Validators.required]],
      32: ['', [Validators.required]],
      819: [''],
      1634: ['', [Validators.required]],
      325: [''],
      812: [''],
      814: [''],
      293: ['', [Validators.required]],
      295: [null, [Validators.required,this.dateFormatValidator]],
      309: ['', [Validators.required]],
      311: [null, [Validators.required,this.dateFormatValidator]],
      cmd_02: [''],
      cmd_03: [null],
      cmd_04: [''],
      33: [''],
      284: [null],
      962: [null],
      277: [null],
      1535: [null],
      1536: [null],
      cmd_0193: [''],
      cmd_0190: [''],
      554: [null],
      556: [null],
      557: [null,[this.dateFormatValidator]],
      cmd_012: [''],
      cmd_013: [''],
      cmd_014: [null, [this.dateFormatValidator] ],
      cmd_015: [''],
      cmd_016: [''],
      1377: [null],
      cmd_017: [null, [this.dateFormatValidator] ],
      cmd_01500: [''],
      cmd_018: [''],
      cmd_019: [''],
      503: ['', [Validators.required]],
      558: [null,[this.dateFormatValidator]],
      559: [null,[this.dateFormatValidator]],
      560: [null,[this.dateFormatValidator]],
      561: [null],
      563: [null],
      564: [null, [this.dateFormatValidator] ],
      565: [null, [this.dateFormatValidator] ],
      566: [null, [this.dateFormatValidator] ],
      567: [null, [this.dateFormatValidator] ],
      946: [null],
      948: [null],
      953: [null, [this.dateFormatValidator] ],
      950: [null, [this.dateFormatValidator] ],
      952: [null, [this.dateFormatValidator] ],
      954: [null, [this.dateFormatValidator] ],
      512: [null, [Validators.required]],
      511: [null, [Validators.required,this.dateFormatValidator]],
      506: ['', [Validators.required]],
      513: ['', [Validators.required]],
      508: [null, [Validators.required]],
      cmd_020: [''],
      479: [''],
      480: [null],
      485: [''],
      482: [null,[this.dateFormatValidator]],
      487: [null],
      489: [''],
      cmd_0191: [''],
      491: [''],
      519: [''],
      493: [''],
      495: [null],
      496: ['', [Validators.minLength(5), Validators.maxLength(10)]],
      //497: [null, [Validators.required]],
      498: [null],
      499: [null],
      500: [null],
      cmd_021: [''],
      cmd_022: [''],
      514: [''],
      515: [null],
      cmd_023: [''],
      cmd_024: [null],
      cmd_026: [''],
      517: [''],
      518: [''],
      520: [''],
      522: [null],
      523: [''],
      524: [null, [this.dateFormatValidator]],
      525: [null, [this.dateFormatValidator]],
      526: [null, [this.dateFormatValidator]],
      527: [null],
      528: [''],
      cmd_027: [''],
      cmd_025: [''],
      529: [''],
      530: [null],
      531: [''],
      533: [''],
      535: [null],
      536: [''],
      537: [null],
      538: [null],
      539: [null],
      540: [''],
      cmd_028: [''],
      cmd_029: [''],
      542: [''],
      543: [null],
      544: [''],
      546: [''],
      548: [null],
      549: [''],
      550: [null],
      551: [null],
      552: [null],
      553: [''],
      cmd_030: [''],
      cmd_0192: [''],
      1288: [''],
      1628: [null],
      1291: [''],
      1293: [null],
      1294: [''],
      1295: [null],
      1296: [null],
      1297: [null],
      1298: [null],
      1299: [null],
      cmd_031: [''],
      cmd_032: [''],
      cmd_033: [''],
      cmd_034: [''],
      cmd_035: [''],
      cmd_036: [''],
      cmd_037: [null],
      cmd_038: [null],
      cmd_039: [null],
      cmd_040: [''],
      cmd_041: [''],
      cmd_042: [''],
      cmd_043: [''],
      1147: [null],
      1154: [''],
      1146: [''],
      1150: [''],
      1152: [null],
      1153: [''],
      1155: [null,[this.dateFormatValidator]],
      1156: [null,[this.dateFormatValidator]],
      1158: [null],
      1165: [''],
      1157: [''],
      1161: [''],
      1163: [null],
      1164: [''],
      1166: [null, [this.dateFormatValidator] ],
      1167: [null, [this.dateFormatValidator] ],
      cmd_044: [''],
      1139: [''],
      1140: [''],
      1141: [''],
      1144: [null,[this.dateFormatValidator]],
      1145: [null,[this.dateFormatValidator]],
      cmd_045: [''],
      cmd_046: [''],
      cmd_047: [''],
      cmd_048: [''],
      1274: [''],
      300: [''],
      894: [null],
      907: [null],
      308: [null,[this.dateFormatValidator]],
      307: [null,[this.dateFormatValidator]],
      3690: [null,[this.dateFormatValidator]],
      cmd_049: [''],
      1432: [''],
      999: [''],
      1010: [null],
      1004: [null, [this.dateFormatValidator] ],
      1003: [null, [this.dateFormatValidator] ],
      cmd_051: [''],
      418: [''],
      //425: ['', [Validators.required]],
      426: [null,[this.dateFormatValidator]],
      419: [''],
      420: [''],
      421: [''],
      423: [null],
      424: [''],
      cmd_01511: [''],
      cmd_01512: [''],
      cmd_0513: [''],
      1244: [''],
      427: [''],
      434: [''],
      435: [null,[this.dateFormatValidator]],
      428: [''],
      429: [''],
      432: [null],
      433: [''],
      436: [''],
      443: [''],
      444: [null],
      437: [''],
      439: [''],
      441: [null],
      442: [''],
      986: [''],
      993: [''],
      994: [null],
      987: [''],
      989: [''],
      991: [null],
      992: [''],
      cmd_054: [''],
      cmd_055: [''],
      cmd_056: [null],
      cmd_057: [''],
      cmd_058: [''],
      cmd_059: [null],
      cmd_060: [''],
      cmd_061: [''],
      cmd_062: [''],
      cmd_063: [null],
      cmd_064: [''],
      cmd_065: [''],
      cmd_066: [null],
      cmd_067: [''],
      cmd_068: [''],
      cmd_069: [''],
      cmd_070: [null],
      cmd_071: [''],
      cmd_072: [''],
      cmd_073: [null],
      cmd_074: [''],
      cmd_075: [''],
      //2025: ['', [Validators.required]],
      //2042: [null, [Validators.required]],
      //2044: [null, [Validators.required]],
      1303: [''],
      1184: [''],
      1186: [''],
      1188: [null],
      1189: [''],
      2046: [''],
      2057: [null],
      2059: [null],
      cmd_076: [''],
      2047: [''],
      2049: [''],
      2051: [null],
      2052: [''],
      cmd_077: [''],
      2061: [''],
      2072: [null],
      2074: [null],
      cmd_078: [''],
      2062: [''],
      2064: [''],
      2066: [null],
      2081: [null],
      2067: [''],
      2076: [''],
      2087: [null],
      2089: [null],
      cmd_079: [''],
      2077: [''],
      2079: [''],
      2082: [''],
      445: [''],
      452: [''],
      454: [null,[this.dateFormatValidator]],
      455: [null,[this.dateFormatValidator]],
      cmd_0206: [''],
      447: [''],
      446: [''],
      448: [''],
      450: [null],
      451: [''],
      456: [''],
      463: [''],
      464: [''],
      465: [null, [this.dateFormatValidator] ],
      466: [null, [this.dateFormatValidator] ],
      457: [''],
      458: [''],
      459: [''],
      461: [null],
      462: [''],
      467: [''],
      474: [''],
      475: [''],
      476: [null, [this.dateFormatValidator] ],
      477: [null, [this.dateFormatValidator] ],
      468: [''],
      469: [''],
      470: [''],
      472: [null],
      473: [''],
      1138: [''],
      cmd_081: [''],
      cmd_082: [null],
      cmd_083: [null],
      1831: [''],
      1832: [''],
      cmd_084: [null],
      cmd_085: [null],
      cmd_086: [null],
      cmd_087: [null],
      359: ['', [Validators.required]],
      368: [null, [Validators.required]],
      366: [null, [Validators.required,this.dateFormatValidator]],
      367: [null, [Validators.required,this.dateFormatValidator]],
      369: ['', [Validators.required]],
      370: ['', [Validators.required]],
      371: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
      360: ['', [Validators.required]],
      362: ['', [Validators.required]],
      365: ['', [Validators.required]],
      364: [null, [Validators.required]],
      380: [null],
      372: [''],
      817: [null, [this.dateFormatValidator] ],
      379: [null, [this.dateFormatValidator] ],
      381: [''],
      382: [''],
      383: [''],
      373: [''],
      375: [''],
      377: [null],
      378: [''],
      384: [''],
      393: [null],
      391: [null, [this.dateFormatValidator] ],
      392: [null, [this.dateFormatValidator] ],
      394: [''],
      395: [''],
      389: [null],
      396: [''],
      385: [''],
      387: [''],
      390: [''],
      453: [''],
      430: [''],
      cmd_01520 : [null],
      cmd_0201: [''],
      cmd_01571 : [null],
      cmd_01522 : [''],
      cmd_01521 : [null],
      cmd_01570 : [null],
      345: ['', [Validators.required]],
      346: ['', [Validators.required]],
      cmd_088: ['', [Validators.required]],
      1240: ['', [Validators.required]],
      901: ['', [Validators.required]],
      903: ['', [Validators.required]],
      906: ['', [Validators.required]],
      905: [null, [Validators.required]],
      348: [null, [Validators.required,this.dateFormatValidator]],
      349: [null, [Validators.required,this.dateFormatValidator]],
      cmd_089: [''],
      945: [null, [Validators.required,this.dateFormatValidator]],
      cmd_090: [''],
      1242: [''],
      1256: [''],
      cmd_092: [''],
      1243: [''],
      1254: [''],
      1246: [''],
      1249: [''],
      1252: [null, [this.dateFormatValidator] ],
      1251: [null, [this.dateFormatValidator] ],
      1253: [null, [this.dateFormatValidator] ],
      cmd_093: [''],
      cmd_094: [''],
      cmd_095: [''],
      cmd_096: [''],
      cmd_097: [''],
      cmd_098: [null],
      cmd_099: [null],
      cmd_0100: [''],
      cmd_0101: [''],
      cmd_0102: [null],
      cmd_0103: [''],
      cmd_0104: [null, [this.dateFormatValidator] ],
      cmd_0105: [null, [this.dateFormatValidator] ],
      cmd_0106: [null, [this.dateFormatValidator] ],
      cmd_0107: [''],
      cmd_0108: [''],
      cmd_0109: [''],
      cmd_0110: [''],
      cmd_0111: [''],
      cmd_0112: [''],
      350: [null, [Validators.required]],
      351: [null, [Validators.required]],
      1265: [null],
      1264: [null],
      1245: [''],
      1248: [null],
      1266: ['', [Validators.required]],
      cmd_0113: [''],
      cmd_0114: [''],
      cmd_0115: [''],
      cmd_0116: [''],
      cmd_0117: [''],
      cmd_0118: [''],
      1183: [''],
      2381: [null, [this.dateFormatValidator] ],
      1191: [null, [this.dateFormatValidator] ],
      374: [''],
      386: [''],
      902: ['', [Validators.required]],
      347: ['', [Validators.required]],
      1185: [''],
      3395: [null,[this.dateFormatValidator]],
      3396: [null,[this.dateFormatValidator]],
      3397: [null,[this.dateFormatValidator]],
      3398: [null,[this.dateFormatValidator]],
      3399: [null,[this.dateFormatValidator]],
      3400: [null,[this.dateFormatValidator]],
      361: ['', [Validators.required]],
      cmd_0119: [''],
      1257: [''],
      cmd_0120: [''],
      cmd_01519: [null, [this.dateFormatValidator] ],
      cmd_0121: [''],
      895: [null],
      cmd_052: [''],
      cmd_0122: [''],
      cmd_0123: [''],
      cmd_0124: [''],
      cmd_0125: [''],
      cmd_0126: [''],
      cmd_0127: [''],
      cmd_0128: [''],
      cmd_0129: [''],
      cmd_0130: [''],
      cmd_0131: [''],
      cmd_09911: ['', [Validators.required]],
      cmd_0132: [''],
      cmd_0200: [null, [Validators.required,this.dateFormatValidator]],
      cmd_0133: [''],
      cmd_0198: [''],
      cmd_0134: [''],
      cmd_0135: [''],
      cmd_0136: [''],
      cmd_0137: [''],
      cmd_0138: [''],
      cmd_0139: [''],
      cmd_0140: [''],
      cmd_0141: [''],
      cmd_0142: [''],
      cmd_0143: [''],
      cmd_0144: [''],
      cmd_0145: [''],
      cmd_0146: [''],
      cmd_0147: [''],
      cmd_0148: [''],
      cmd_0149: [''],
      cmd_0150: [''],
      cmd_0151: [''],
      cmd_0152: [''],
      cmd_0153: [''],
      cmd_0154: [''],
      cmd_0155: [''],
      cmd_0156: [''],
      cmd_0195: [null, [Validators.required,this.dateFormatValidator]],
      cmd_0157: [''],
      cmd_0158: [''],
      cmd_0159: [''],
      cmd_160: [''],
      cmd_0161: [''],
      1205: [''],
      1206: [''],
      1207: [''],
      1208: [null, [this.dateFormatValidator] ],
      1209: [null, [this.dateFormatValidator] ],
      1210: [''],
      cmd_0162: [''],
      cmd_0163: [''],
      cmd_0164: [''],
      1213: [''],
      1214: [''],
      1216: [''],
      1217: [''],
      1218: [''],
      cmd_0165: [''],
      1219: [null, [this.dateFormatValidator] ],
      1275: [''],
      1220: [null, [this.dateFormatValidator] ],
      cmd_0166: [''],
      cmd_0167: [''],
      1222: [null, [this.dateFormatValidator] ],
      cmd_0168: [''],
      1223: [null, [this.dateFormatValidator] ],
      1226: [null, [this.dateFormatValidator] ],
      1228: [''],
      1229: [''],
      cmd_0169: [''],
      1227: [null, [this.dateFormatValidator] ],
      1231: [''],
      1232: [''],
      cmd_0196: [null, [Validators.required,this.dateFormatValidator]],
      cmd_09931: [null, [Validators.required]],
      cmd_0994: [''],
      cmd_0170: [''],
      cmd_0199: [null],
      cmd_0992: ['', [Validators.required]],
      397: [''],
      399: [''],
      398: [''],
      408: [''],
      409: [''],
      414: [''],
      415: ['']
    });
  }

  public getSuffixData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getSuffixData(params).subscribe(
      data => {
        if (data['success']) {
          this.suffix = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getStaffStatusData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getStaffStatusData(params).subscribe(
      data => {
        if (data['success']) {
          this.staffStatus = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getCredentialsData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getCredentialsData(params).subscribe(
      data => {
        if (data['success']) {
          this.credential = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getAggregatesData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getAggregatesData(params).subscribe(
      data => {
        if (data['success']) {
          this.aggregates = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getDegreeData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getDegreeData(params).subscribe(
      data => {
        if (data['success']) {
          this.degree = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getPreClaimData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getPreClaimData(params).subscribe(
      data => {
        if (data['success']) {
          this.preClaim = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getAlternateLicenseStateData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getAlternateLicenseStateData(params).subscribe(
      data => {
        if (data['success']) {
          this.altLicenseState = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getAdditionalLicenseStateData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getAdditionalLicenseStateData(params).subscribe(
      data => {
        if (data['success']) {
          this.additionalLicenseState = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getStatesData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getStatesData(params).subscribe(
      data => {
        if (data['success']) {
          this.states = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getSpecialtyData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getSpecialtyData(params).subscribe(
      data => {
        if (data['success']) {
          this.specialty = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getSpecialtySecondaryData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getSpecialtySecondaryData(params).subscribe(
      data => {
        if (data['success']) {
          this.specialtySecondary = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getLanguagesData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getLanguagesData(params).subscribe(
      data => {
        if (data['success']) {
          this.languages = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getsecondaryLanguagesData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getsecondaryLanguagesData(params).subscribe(
      data => {
        if (data['success']) {
          this.secondaryLanguages = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public gettertiaryLanguagesData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.gettertiaryLanguagesData(params).subscribe(
      data => {
        if (data['success']) {
          this.tertiaryLanguages = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getquaternaryLanguagesData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getquaternaryLanguagesData(params).subscribe(
      data => {
        if (data['success']) {
          this.quaternaryLanguages = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getBoardCertificationData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getBoardCertificationData(params).subscribe(
      data => {
        if (data['success']) {
          this.boardCertification = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  saveForm(type) {
    this.isSubmit = true;
    console.log(this.gaUnifiedForm);
    this.handleNAchange('cmd_0193');
    this.handleNAchange('cmd_020');
    this.handleNAchange('cmd_043');
    this.handleNAchange('cmd_044');
    this.handleNAchange('cmd_04411');
    this.handleNAchange('cmd_048');
    this.handleNAchange('cmd_052');
    this.handleNAchange('cmd_0201');
    this.handleNAchange('cmd_081');
    // let check  =this.gaUnifiedForm.controls;
    // for (const key in check) {
    //   if (check.hasOwnProperty(key)) {
    //     const element = check[key];
    //     console.log(key);
    //     console.log(element.status);
    //   }
    // }
    if (type == 'submit') {
      if (this.gaUnifiedForm.invalid) {
        this.notiService.showError(
          'Please Fill the all mandatory fields to continue',
          '',
          3000
        );
        for (const key in this.gaUnifiedForm.controls) {
          if (this.gaUnifiedForm.controls.hasOwnProperty(key)) {
            const element = this.gaUnifiedForm.controls[key];
            if(element.status=="INVALID"){
            console.log(key + " " + element.status)
            }
          }
        }
      } else {
        if (this.gaUnifiedForm.controls['cmd_09931'].value == null || this.gaUnifiedForm.controls['cmd_09931'].value == '') {
          this.notiService.showError('Please add your Signature')
          return
        }
        if (this.gaUnifiedForm.controls['cmd_0992'].value == null || this.gaUnifiedForm.controls['cmd_0992'].value == '') {
          this.notiService.showError('Please add your Signature')
          return
        }
        if (this.gaUnifiedForm.controls['cmd_09911'].value == null || this.gaUnifiedForm.controls['cmd_09911'].value == '') {
          this.notiService.showError('Please add your Signature')
          return
        }
        let data = {
          formType: type,
          formValues: this.gaUnifiedForm.value
        };
        console.log("Form has been submitted successfully");
        this.onSubmit.emit(data);
        console.log(this.gaUnifiedForm);

      }
    } else {
      let data = {
        formType: type,
        formValues: this.gaUnifiedForm.value,
        formId:32
      };
      this.onSubmit.emit(data);
      this.onClose.emit('close modal');
    }
    // if (type == 'submit') {
    //   if (this.gaUnifiedForm.invalid ||
    //     (!this.ExplanInterruptionDoesnot &&
    //       (!this.gaUnifiedForm.controls['1138'].value ||
    //       !this.gaUnifiedForm.controls['3395'].value ||
    //       !this.gaUnifiedForm.controls['3396'].value ||
    //         !this.gaUnifiedForm.controls['1832'].value ||
    //         !this.gaUnifiedForm.controls['3397'].value ||
    //       !this.gaUnifiedForm.controls['3398'].value ||
    //         !this.gaUnifiedForm.controls['1831'].value ||
    //         !this.gaUnifiedForm.controls['3399'].value ||
    //       !this.gaUnifiedForm.controls['3400'].value)) ||
    //     (!this.otherStateCareDoesnot &&
    //       (!this.gaUnifiedForm.controls['1274'].value ||
    //         !this.gaUnifiedForm.controls['300'].value ||
    //         !this.gaUnifiedForm.controls['907'].value ||
    //         !this.gaUnifiedForm.controls['308'].value ||
    //         !this.gaUnifiedForm.controls['cmd_049'].value ||
    //         !this.gaUnifiedForm.controls['307'].value)) ||
    //     (!this.militaryHealthDoesnot &&
    //       (!this.gaUnifiedForm.controls['1139'].value ||
    //         !this.gaUnifiedForm.controls['1140'].value ||
    //         !this.gaUnifiedForm.controls['1144'].value ||
    //         !this.gaUnifiedForm.controls['1141'].value ||
    //         !this.gaUnifiedForm.controls['1145'].value ||
    //         !this.gaUnifiedForm.controls['cmd_045'].value)) ||
    //     (!this.currentHospDoesnot &&
    //       (!this.gaUnifiedForm.controls['418'].value ||
    //         !this.gaUnifiedForm.controls['894'].value ||
    //         !this.gaUnifiedForm.controls['426'].value ||
    //         !this.gaUnifiedForm.controls['419'].value ||
    //         !this.gaUnifiedForm.controls['420'].value ||
    //         !this.gaUnifiedForm.controls['421'].value ||
    //         !this.gaUnifiedForm.controls['423'].value ||
    //         !this.gaUnifiedForm.controls['424'].value ||
    //         !this.gaUnifiedForm.controls['427'].value ||
    //         !this.gaUnifiedForm.controls['895'].value ||
    //         !this.gaUnifiedForm.controls['435'].value ||
    //         !this.gaUnifiedForm.controls['428'].value ||
    //         !this.gaUnifiedForm.controls['429'].value ||
    //         !this.gaUnifiedForm.controls['430'].value ||
    //         !this.gaUnifiedForm.controls['432'].value ||
    //         !this.gaUnifiedForm.controls['433'].value)) ||
    //       (!this.proWorkHistoryDoesnot &&
    //         (!this.gaUnifiedForm.controls['445'].value ||
    //           !this.gaUnifiedForm.controls['452'].value ||
    //           !this.gaUnifiedForm.controls['453'].value ||
    //           !this.gaUnifiedForm.controls['454'].value ||
    //           !this.gaUnifiedForm.controls['455'].value ||
    //           !this.gaUnifiedForm.controls['446'].value ||
    //           !this.gaUnifiedForm.controls['447'].value ||
    //           !this.gaUnifiedForm.controls['448'].value ||
    //           !this.gaUnifiedForm.controls['450'].value ||
    //           !this.gaUnifiedForm.controls['451'].value )) ||
    //     (!this.graduateDegreeDoesnot &&
    //       (!this.gaUnifiedForm.controls['479'].value ||
    //         !this.gaUnifiedForm.controls['480'].value ||
    //         !this.gaUnifiedForm.controls['482'].value ||
    //         !this.gaUnifiedForm.controls['485'].value ||
    //         !this.gaUnifiedForm.controls['487'].value ||
    //         !this.gaUnifiedForm.controls['489'].value)) ||
    //     (!this.facultyPositionsDoesnot &&
    //       (!this.gaUnifiedForm.controls['1147'].value ||
    //       !this.gaUnifiedForm.controls['1148'].value ||
    //       !this.gaUnifiedForm.controls['1149'].value ||
    //         !this.gaUnifiedForm.controls['1154'].value ||
    //         !this.gaUnifiedForm.controls['1146'].value ||
    //         !this.gaUnifiedForm.controls['1150'].value ||
    //         !this.gaUnifiedForm.controls['1152'].value ||
    //         !this.gaUnifiedForm.controls['1153'].value ||
    //         !this.gaUnifiedForm.controls['1155'].value ||
    //         !this.gaUnifiedForm.controls['1156'].value   )) ||
    //     (!this.delegateDoesnot &&
    //       (!this.gaUnifiedForm.controls['397'].value ||
    //       !this.gaUnifiedForm.controls['399'].value ||
    //       !this.gaUnifiedForm.controls['1239'].value ||
    //         !this.gaUnifiedForm.controls['408'].value ||
    //         !this.gaUnifiedForm.controls['409'].value ||
    //         !this.gaUnifiedForm.controls['410'].value ||
    //         !this.gaUnifiedForm.controls['412'].value ||
    //         !this.gaUnifiedForm.controls['413'].value ||
    //         !this.gaUnifiedForm.controls['414'].value ||
    //         !this.gaUnifiedForm.controls['415'].value   )) ||
    //     (!this.boardCertiDoesnot &&
    //       (!this.gaUnifiedForm.controls['554'].value ||
    //         !this.gaUnifiedForm.controls['556'].value ||
    //         !this.gaUnifiedForm.controls['557'].value ||
    //         !this.gaUnifiedForm.controls['558'].value ||
    //         !this.gaUnifiedForm.controls['559'].value ||
    //         !this.gaUnifiedForm.controls['560'].value))) {
    //       if (
    //         !this.ExplanInterruptionDoesnot &&
    //         !this.gaUnifiedForm.controls['1832'].value
    //       ) {
    //         this.gaUnifiedForm.controls['1832'].setErrors({ errors: true });
    //       }
    //       if (
    //         !this.ExplanInterruptionDoesnot &&
    //         !this.gaUnifiedForm.controls['1831'].value
    //       ) {
    //         this.gaUnifiedForm.controls['1831'].setErrors({ errors: true });
    //       }
    //       if (
    //         !this.ExplanInterruptionDoesnot &&
    //         !this.gaUnifiedForm.controls['1138'].value
    //       ) {
    //         this.gaUnifiedForm.controls['1138'].setErrors({ errors: true });
    //       }
    //       if (
    //         !this.ExplanInterruptionDoesnot &&
    //         !this.gaUnifiedForm.controls['3395'].value
    //       ) {
    //         this.gaUnifiedForm.controls['3395'].setErrors({ errors: true });
    //       }
    //       if (
    //         !this.ExplanInterruptionDoesnot &&
    //         !this.gaUnifiedForm.controls['3396'].value
    //       ) {
    //         this.gaUnifiedForm.controls['3396'].setErrors({ errors: true });
    //       }
    //       if (
    //         !this.ExplanInterruptionDoesnot &&
    //         !this.gaUnifiedForm.controls['3397'].value
    //       ) {
    //         this.gaUnifiedForm.controls['3397'].setErrors({ errors: true });
    //       }
    //       if (
    //         !this.ExplanInterruptionDoesnot &&
    //         !this.gaUnifiedForm.controls['3398'].value
    //       ) {
    //         this.gaUnifiedForm.controls['3398'].setErrors({ errors: true });
    //       }
    //       if (
    //         !this.ExplanInterruptionDoesnot &&
    //         !this.gaUnifiedForm.controls['3399'].value
    //       ) {
    //         this.gaUnifiedForm.controls['3399'].setErrors({ errors: true });
    //       }
    //       if (
    //         !this.ExplanInterruptionDoesnot &&
    //         !this.gaUnifiedForm.controls['3400'].value
    //       ) {
    //         this.gaUnifiedForm.controls['3400'].setErrors({ errors: true });
    //       }
    //       if (
    //         !this.delegateDoesnot &&
    //         !this.gaUnifiedForm.controls['397'].value
    //       ) {
    //         this.gaUnifiedForm.controls['397'].setErrors({ errors: true });
    //       }
    //       if (
    //         !this.delegateDoesnot &&
    //         !this.gaUnifiedForm.controls['399'].value
    //       ) {
    //         this.gaUnifiedForm.controls['399'].setErrors({ errors: true });
    //       }
    //       if (
    //         !this.delegateDoesnot &&
    //         !this.gaUnifiedForm.controls['1239'].value
    //       ) {
    //         this.gaUnifiedForm.controls['1239'].setErrors({ errors: true });
    //       }
    //       if (
    //         !this.delegateDoesnot &&
    //         !this.gaUnifiedForm.controls['408'].value
    //       ) {
    //         this.gaUnifiedForm.controls['408'].setErrors({ errors: true });
    //       }
    //       if (
    //         !this.delegateDoesnot &&
    //         !this.gaUnifiedForm.controls['409'].value
    //       ) {
    //         this.gaUnifiedForm.controls['409'].setErrors({ errors: true });
    //       }
    //       if (
    //         !this.delegateDoesnot &&
    //         !this.gaUnifiedForm.controls['410'].value
    //       ) {
    //         this.gaUnifiedForm.controls['410'].setErrors({ errors: true });
    //       }
    //       if (
    //         !this.delegateDoesnot &&
    //         !this.gaUnifiedForm.controls['412'].value
    //       ) {
    //         this.gaUnifiedForm.controls['412'].setErrors({ errors: true });
    //       }
    //       if (
    //         !this.delegateDoesnot &&
    //         !this.gaUnifiedForm.controls['413'].value
    //       ) {
    //         this.gaUnifiedForm.controls['413'].setErrors({ errors: true });
    //       }
    //       if (
    //         !this.delegateDoesnot &&
    //         !this.gaUnifiedForm.controls['414'].value
    //       ) {
    //         this.gaUnifiedForm.controls['414'].setErrors({ errors: true });
    //       }
    //       if (
    //         !this.delegateDoesnot &&
    //         !this.gaUnifiedForm.controls['415'].value
    //       ) {
    //         this.gaUnifiedForm.controls['415'].setErrors({ errors: true });
    //       }
    //       if (
    //         !this.graduateDegreeDoesnot &&
    //         !this.gaUnifiedForm.controls['479'].value
    //       ) {
    //         this.gaUnifiedForm.controls['479'].setErrors({ errors: true });
    //       }
    //       if (
    //         !this.graduateDegreeDoesnot &&
    //         !this.gaUnifiedForm.controls['480'].value
    //       ) {
    //         this.gaUnifiedForm.controls['480'].setErrors({ errors: true });
    //       }
    //       if (
    //         !this.graduateDegreeDoesnot &&
    //         !this.gaUnifiedForm.controls['482'].value
    //       ) {
    //         this.gaUnifiedForm.controls['482'].setErrors({ errors: true });
    //       }
    //       if (
    //         !this.graduateDegreeDoesnot &&
    //         !this.gaUnifiedForm.controls['485'].value
    //       ) {
    //         this.gaUnifiedForm.controls['485'].setErrors({ errors: true });
    //       }
    //       if (
    //         !this.graduateDegreeDoesnot &&
    //         !this.gaUnifiedForm.controls['487'].value
    //       ) {
    //         this.gaUnifiedForm.controls['487'].setErrors({ errors: true });
    //       }
    //       if (
    //         !this.graduateDegreeDoesnot &&
    //         !this.gaUnifiedForm.controls['489'].value
    //       ) {
    //         this.gaUnifiedForm.controls['489'].setErrors({ errors: true });
    //       }
    //       if (
    //         !this.otherStateCareDoesnot &&
    //         !this.gaUnifiedForm.controls['1274'].value
    //       ) {
    //         this.gaUnifiedForm.controls['1274'].setErrors({ errors: true });
    //       }
    //       if (!this.otherStateCareDoesnot && !this.gaUnifiedForm.controls['300'].value) {
    //         this.gaUnifiedForm.controls['300'].setErrors({ errors: true });
    //       }
    //       if (!this.otherStateCareDoesnot && !this.gaUnifiedForm.controls['907'].value) {
    //         this.gaUnifiedForm.controls['907'].setErrors({ errors: true });
    //       }
    //       if (!this.otherStateCareDoesnot && !this.gaUnifiedForm.controls['308'].value) {
    //         this.gaUnifiedForm.controls['308'].setErrors({ errors: true });
    //       }
    //       if (!this.otherStateCareDoesnot && !this.gaUnifiedForm.controls['cmd_049'].value) {
    //         this.gaUnifiedForm.controls['cmd_049'].setErrors({ errors: true });
    //       }
    //       if (!this.otherStateCareDoesnot && !this.gaUnifiedForm.controls['307'].value) {
    //         this.gaUnifiedForm.controls['307'].setErrors({ errors: true });
    //       }
    //       if (!this.boardCertiDoesnot && !this.gaUnifiedForm.controls['554'].value) {
    //         this.gaUnifiedForm.controls['554'].setErrors({ errors: true });
    //       }
    //       if (!this.boardCertiDoesnot && !this.gaUnifiedForm.controls['556'].value) {
    //         this.gaUnifiedForm.controls['556'].setErrors({ errors: true });
    //       }
    //       if (!this.boardCertiDoesnot && !this.gaUnifiedForm.controls['557'].value) {
    //         this.gaUnifiedForm.controls['557'].setErrors({ errors: true });
    //       }
    //       if (!this.boardCertiDoesnot && !this.gaUnifiedForm.controls['558'].value) {
    //         this.gaUnifiedForm.controls['558'].setErrors({ errors: true });
    //       }
    //       if (!this.boardCertiDoesnot && !this.gaUnifiedForm.controls['559'].value) {
    //         this.gaUnifiedForm.controls['559'].setErrors({ errors: true });
    //       }
    //       if (!this.boardCertiDoesnot && !this.gaUnifiedForm.controls['560'].value) {
    //         this.gaUnifiedForm.controls['560'].setErrors({ errors: true });
    //       }
    //       if (
    //         !this.militaryHealthDoesnot &&
    //         !this.gaUnifiedForm.controls['1139'].value
    //       ) {
    //         this.gaUnifiedForm.controls['1139'].setErrors({ errors: true });
    //       }
    //       if (
    //         !this.militaryHealthDoesnot &&
    //         !this.gaUnifiedForm.controls['1140'].value
    //       ) {
    //         this.gaUnifiedForm.controls['1140'].setErrors({ errors: true });
    //       }
    //       if (
    //         !this.militaryHealthDoesnot &&
    //         !this.gaUnifiedForm.controls['1141'].value
    //       ) {
    //         this.gaUnifiedForm.controls['1141'].setErrors({ errors: true });
    //       }
    //       if (
    //         !this.militaryHealthDoesnot &&
    //         !this.gaUnifiedForm.controls['1144'].value
    //       ) {
    //         this.gaUnifiedForm.controls['1144'].setErrors({ errors: true });
    //       }
    //       if (
    //         !this.militaryHealthDoesnot &&
    //         !this.gaUnifiedForm.controls['1145'].value
    //       ) {
    //         this.gaUnifiedForm.controls['1145'].setErrors({ errors: true });
    //       }
    //       if (
    //         !this.militaryHealthDoesnot &&
    //         !this.gaUnifiedForm.controls['cmd_045'].value
    //       ) {
    //         this.gaUnifiedForm.controls['cmd_045'].setErrors({ errors: true });
    //       }
    //       if (
    //         !this.currentHospDoesnot &&
    //         !this.gaUnifiedForm.controls['418'].value
    //       ) {
    //         this.gaUnifiedForm.controls['418'].setErrors({ errors: true });
    //       }
    //       if (
    //         !this.currentHospDoesnot &&
    //         !this.gaUnifiedForm.controls['894'].value
    //       ) {
    //         this.gaUnifiedForm.controls['894'].setErrors({ errors: true });
    //       }
    //       if (
    //         !this.currentHospDoesnot &&
    //         !this.gaUnifiedForm.controls['426'].value
    //       ) {
    //         this.gaUnifiedForm.controls['426'].setErrors({ errors: true });
    //       }
    //       if (
    //         !this.currentHospDoesnot &&
    //         !this.gaUnifiedForm.controls['419'].value
    //       ) {
    //         this.gaUnifiedForm.controls['419'].setErrors({ errors: true });
    //       }
    //       if (
    //         !this.currentHospDoesnot &&
    //         !this.gaUnifiedForm.controls['420'].value
    //       ) {
    //         this.gaUnifiedForm.controls['420'].setErrors({ errors: true });
    //       }
    //       if (
    //         !this.currentHospDoesnot &&
    //         !this.gaUnifiedForm.controls['421'].value
    //       ) {
    //         this.gaUnifiedForm.controls['421'].setErrors({ errors: true });
    //       }
    //       if (
    //         !this.currentHospDoesnot &&
    //         !this.gaUnifiedForm.controls['423'].value
    //       ) {
    //         this.gaUnifiedForm.controls['423'].setErrors({ errors: true });
    //       }
    //       if (
    //         !this.currentHospDoesnot &&
    //         !this.gaUnifiedForm.controls['424'].value
    //       ) {
    //         this.gaUnifiedForm.controls['424'].setErrors({ errors: true });
    //       }
    //       if (
    //         !this.currentHospDoesnot &&
    //         !this.gaUnifiedForm.controls['427'].value
    //       ) {
    //         this.gaUnifiedForm.controls['427'].setErrors({ errors: true });
    //       }
    //       if (
    //         !this.currentHospDoesnot &&
    //         !this.gaUnifiedForm.controls['895'].value
    //       ) {
    //         this.gaUnifiedForm.controls['895'].setErrors({ errors: true });
    //       }
    //       if (
    //         !this.currentHospDoesnot &&
    //         !this.gaUnifiedForm.controls['435'].value
    //       ) {
    //         this.gaUnifiedForm.controls['435'].setErrors({ errors: true });
    //       }
    //       if (
    //         !this.currentHospDoesnot &&
    //         !this.gaUnifiedForm.controls['428'].value
    //       ) {
    //         this.gaUnifiedForm.controls['428'].setErrors({ errors: true });
    //       }
    //       if (
    //         !this.currentHospDoesnot &&
    //         !this.gaUnifiedForm.controls['429'].value
    //       ) {
    //         this.gaUnifiedForm.controls['429'].setErrors({ errors: true });
    //       }
    //       if (
    //         !this.currentHospDoesnot &&
    //         !this.gaUnifiedForm.controls['430'].value
    //       ) {
    //         this.gaUnifiedForm.controls['430'].setErrors({ errors: true });
    //       }
    //       if (
    //         !this.currentHospDoesnot &&
    //         !this.gaUnifiedForm.controls['430'].value
    //       ) {
    //         this.gaUnifiedForm.controls['430'].setErrors({ errors: true });
    //       }
    //       if (
    //         !this.currentHospDoesnot &&
    //         !this.gaUnifiedForm.controls['432'].value
    //       ) {
    //         this.gaUnifiedForm.controls['432'].setErrors({ errors: true });
    //       }
    //       if (
    //         !this.currentHospDoesnot &&
    //         !this.gaUnifiedForm.controls['433'].value
    //       ) {
    //         this.gaUnifiedForm.controls['433'].setErrors({ errors: true });
    //       }
    //       if (
    //         !this.proWorkHistoryDoesnot &&
    //         !this.gaUnifiedForm.controls['445'].value
    //       ) {
    //         this.gaUnifiedForm.controls['445'].setErrors({ errors: true });
    //       }
    //       if (
    //         !this.proWorkHistoryDoesnot &&
    //         !this.gaUnifiedForm.controls['452'].value
    //       ) {
    //         this.gaUnifiedForm.controls['452'].setErrors({ errors: true });
    //       }
    //       if (
    //         !this.proWorkHistoryDoesnot &&
    //         !this.gaUnifiedForm.controls['453'].value
    //       ) {
    //         this.gaUnifiedForm.controls['453'].setErrors({ errors: true });
    //       }
    //       if (
    //         !this.proWorkHistoryDoesnot &&
    //         !this.gaUnifiedForm.controls['454'].value
    //       ) {
    //         this.gaUnifiedForm.controls['454'].setErrors({ errors: true });
    //       }
    //       if (
    //         !this.proWorkHistoryDoesnot &&
    //         !this.gaUnifiedForm.controls['455'].value
    //       ) {
    //         this.gaUnifiedForm.controls['455'].setErrors({ errors: true });
    //       }
    //       if (
    //         !this.proWorkHistoryDoesnot &&
    //         !this.gaUnifiedForm.controls['446'].value
    //       ) {
    //         this.gaUnifiedForm.controls['446'].setErrors({ errors: true });
    //       }
    //       if (
    //         !this.proWorkHistoryDoesnot &&
    //         !this.gaUnifiedForm.controls['447'].value
    //       ) {
    //         this.gaUnifiedForm.controls['447'].setErrors({ errors: true });
    //       }
    //       if (
    //         !this.proWorkHistoryDoesnot &&
    //         !this.gaUnifiedForm.controls['448'].value
    //       ) {
    //         this.gaUnifiedForm.controls['448'].setErrors({ errors: true });
    //       }
    //       if (
    //         !this.proWorkHistoryDoesnot &&
    //         !this.gaUnifiedForm.controls['450'].value
    //       ) {
    //         this.gaUnifiedForm.controls['450'].setErrors({ errors: true });
    //       }
    //       if (
    //         !this.proWorkHistoryDoesnot &&
    //         !this.gaUnifiedForm.controls['451'].value
    //       ) {
    //         this.gaUnifiedForm.controls['451'].setErrors({ errors: true });
    //       }
    //       if (
    //         !this.facultyPositionsDoesnot &&
    //         !this.gaUnifiedForm.controls['1147'].value
    //       ) {
    //         this.gaUnifiedForm.controls['1147'].setErrors({ errors: true });
    //       }
    //       if (
    //         !this.facultyPositionsDoesnot &&
    //         !this.gaUnifiedForm.controls['1154'].value
    //       ) {
    //         this.gaUnifiedForm.controls['1154'].setErrors({ errors: true });
    //       }
    //       if (
    //         !this.facultyPositionsDoesnot &&
    //         !this.gaUnifiedForm.controls['1148'].value
    //       ) {
    //         this.gaUnifiedForm.controls['1148'].setErrors({ errors: true });
    //       }
    //       if (
    //         !this.facultyPositionsDoesnot &&
    //         !this.gaUnifiedForm.controls['1149'].value
    //       ) {
    //         this.gaUnifiedForm.controls['1149'].setErrors({ errors: true });
    //       }
    //       if (
    //         !this.facultyPositionsDoesnot &&
    //         !this.gaUnifiedForm.controls['1146'].value
    //       ) {
    //         this.gaUnifiedForm.controls['1146'].setErrors({ errors: true });
    //       }
    //       if (
    //         !this.facultyPositionsDoesnot &&
    //         !this.gaUnifiedForm.controls['1150'].value
    //       ) {
    //         this.gaUnifiedForm.controls['1150'].setErrors({ errors: true });
    //       }
    //       if (
    //         !this.facultyPositionsDoesnot &&
    //         !this.gaUnifiedForm.controls['1152'].value
    //       ) {
    //         this.gaUnifiedForm.controls['1152'].setErrors({ errors: true });
    //       }
    //       if (
    //         !this.facultyPositionsDoesnot &&
    //         !this.gaUnifiedForm.controls['1153'].value
    //       ) {
    //         this.gaUnifiedForm.controls['1153'].setErrors({ errors: true });
    //       }
    //       if (
    //         !this.facultyPositionsDoesnot &&
    //         !this.gaUnifiedForm.controls['1155'].value
    //       ) {
    //         this.gaUnifiedForm.controls['1155'].setErrors({ errors: true });
    //       }
    //       if (
    //         !this.facultyPositionsDoesnot &&
    //         !this.gaUnifiedForm.controls['1156'].value
    //       ) {
    //         this.gaUnifiedForm.controls['1156'].setErrors({ errors: true });
    //       }
    //       this.notiService.showError(
    //         'Please Fill all the mandatory fields to continue',
    //         '',
    //         3000
    //       );
    //     } else {
    //       let data = {
    //         formType: type,
    //         formValues: this.gaUnifiedForm.value
    //       };
    //       this.onSubmit.emit(data);
    //     }
    // } else {
    //   let data = {
    //     formType: type,
    //     formValues: this.gaUnifiedForm.value,
    //     formId: 32
    //   };
    //   this.onSubmit.emit(data);
    //   //this.onClose.emit('close modal');
    // }
  }

  close() {
    this.onClose.emit('close modal');
  }

  public esignOpenOne() {
    const modalRef = this.modalService.open(EsignComponent, this.modalOption);
    modalRef.result.then(result => { });
    modalRef.componentInstance.base64Img.subscribe(data => {
      this.finalImgPath = data.finalFilePath;
      modalRef.close();
      let temp = {
        cmd_09911: this.finalImgPath
      };
      this.gaUnifiedForm.patchValue(temp);
      this.finalImgPath = data.finalFilePath;
      this.finalImg = data.base64Img;
      this.esignOne = this._sanitizer.bypassSecurityTrustResourceUrl(
        'data:image/jpg;base64,' + this.finalImg
      );
      this.showOne = true;
    });
  }

  public esignOpenTwo() {
    const modalRef = this.modalService.open(EsignComponent, this.modalOption);
    modalRef.result.then(result => { });
    modalRef.componentInstance.base64Img.subscribe(data => {
      modalRef.close();
      this.finalImgPathTwo = data.finalFilePath;
      let temp = {
        cmd_0992: this.finalImgPathTwo
      };
      this.gaUnifiedForm.patchValue(temp);
      this.finalImgPathTwo = data.finalFilePath;
      this.finalImgTwo = data.base64Img;
      this.esignTwo = this._sanitizer.bypassSecurityTrustResourceUrl(
        'data:image/jpg;base64,' + this.finalImgTwo
      );
      this.showTwo = true;
    });
  }

  public esignOpenThree() {
    const modalRef = this.modalService.open(EsignComponent, this.modalOption);
    modalRef.result.then(result => { });
    modalRef.componentInstance.base64Img.subscribe(data => {
      modalRef.close();
      this.finalImgPathThree = data.finalFilePath;
      let temp = {
        cmd_09931: this.finalImgPathThree
      };
      this.gaUnifiedForm.patchValue(temp);
      this.finalImgPathThree = data.finalFilePath;
      this.finalImgThree = data.base64Img;
      this.esignThree = this._sanitizer.bypassSecurityTrustResourceUrl(
        'data:image/jpg;base64,' + this.finalImgThree
      );
      this.showThree = true;
    });
  }

  // public boardCerti(event) {
  //   // console.log(event.target.value, event.target.checked);
  //   this.boardCertiDoesnot = event.target.checked;
  //   if (this.boardCertiDoesnot) {
  //     this.gaUnifiedForm.controls[560].setErrors(null);
  //     this.gaUnifiedForm.controls[557].setErrors(null);
  //     this.gaUnifiedForm.controls[554].setErrors(null);
  //     this.gaUnifiedForm.controls[556].setErrors(null);
  //     this.gaUnifiedForm.controls[558].setErrors(null);
  //     this.gaUnifiedForm.controls[559].setErrors(null);
  //   }
  // }
  // public graduateProfessional(event) {
  //   // console.log(event.target.value, event.target.checked);
  //   this.graduateDegreeDoesnot = event.target.checked;
  //   if (this.graduateDegreeDoesnot) {
  //     this.gaUnifiedForm.controls[479].setErrors(null);
  //     this.gaUnifiedForm.controls[480].setErrors(null);
  //     this.gaUnifiedForm.controls[482].setErrors(null);
  //     this.gaUnifiedForm.controls[485].setErrors(null);
  //     this.gaUnifiedForm.controls[487].setErrors(null);
  //     this.gaUnifiedForm.controls[489].setErrors(null);
  //   }
  // }

  // public expalanationRequired(event) {
  //   // console.log(event.target.value, event.target.checked);
  //   this.ExplanInterruptionDoesnot = event.target.checked;
  //   if (this.ExplanInterruptionDoesnot) {
  //     this.gaUnifiedForm.controls[1138].setErrors(null);
  //     this.gaUnifiedForm.controls[3395].setErrors(null);
  //     this.gaUnifiedForm.controls[3396].setErrors(null);
  //     this.gaUnifiedForm.controls[1831].setErrors(null);
  //     this.gaUnifiedForm.controls[3397].setErrors(null);
  //     this.gaUnifiedForm.controls[3398].setErrors(null);
  //     this.gaUnifiedForm.controls[1832].setErrors(null);
  //     this.gaUnifiedForm.controls[3399].setErrors(null);
  //     this.gaUnifiedForm.controls[3400].setErrors(null);
  //   }
  // }

  // public otherStateHealth(event) {
  //   // console.log(event.target.value, event.target.checked);
  //   this.otherStateCareDoesnot = event.target.checked;
  //   if (this.otherStateCareDoesnot) {
  //     this.gaUnifiedForm.controls[1274].setErrors(null);
  //     this.gaUnifiedForm.controls[300].setErrors(null);
  //     this.gaUnifiedForm.controls[907].setErrors(null);
  //     this.gaUnifiedForm.controls[308].setErrors(null);
  //     this.gaUnifiedForm.controls[307].setErrors(null);
  //     this.gaUnifiedForm.controls['cmd_049'].setErrors(null);
  //   }
  // }

  // public milpublicHealth(event) {
  //   // console.log(event.target.value, event.target.checked);
  //   this.militaryHealthDoesnot = event.target.checked;
  //   if (this.militaryHealthDoesnot) {
  //     this.gaUnifiedForm.controls[1139].setErrors(null);
  //     this.gaUnifiedForm.controls[1140].setErrors(null);
  //     this.gaUnifiedForm.controls[1141].setErrors(null);
  //     this.gaUnifiedForm.controls[1144].setErrors(null);
  //     this.gaUnifiedForm.controls[1145].setErrors(null);
  //     this.gaUnifiedForm.controls['cmd_045'].setErrors(null);
  //   }
  // }

  // public currentHosp(event) {
  //   // console.log(event.target.value, event.target.checked);
  //   this.currentHospDoesnot = event.target.checked;
  //   if (this.currentHospDoesnot) {
  //     this.gaUnifiedForm.controls[418].setErrors(null);
  //     this.gaUnifiedForm.controls[894].setErrors(null);
  //     this.gaUnifiedForm.controls[426].setErrors(null);
  //     this.gaUnifiedForm.controls[419].setErrors(null);
  //     this.gaUnifiedForm.controls[420].setErrors(null);
  //     this.gaUnifiedForm.controls[421].setErrors(null);
  //     this.gaUnifiedForm.controls[423].setErrors(null);
  //     this.gaUnifiedForm.controls[424].setErrors(null);
  //     this.gaUnifiedForm.controls[427].setErrors(null);
  //     this.gaUnifiedForm.controls[895].setErrors(null);
  //     this.gaUnifiedForm.controls[435].setErrors(null);
  //     this.gaUnifiedForm.controls[428].setErrors(null);
  //     this.gaUnifiedForm.controls[429].setErrors(null);
  //     this.gaUnifiedForm.controls[430].setErrors(null);
  //     this.gaUnifiedForm.controls[432].setErrors(null);
  //     this.gaUnifiedForm.controls[433].setErrors(null);

  //   }
  // }


  // public delegate(event) {
  //   // console.log(event.target.value, event.target.checked);
  //   this.delegateDoesnot = event.target.checked;
  //   if (this.delegateDoesnot) {
  //     this.gaUnifiedForm.controls[397].setErrors(null);
  //     this.gaUnifiedForm.controls[399].setErrors(null);
  //     this.gaUnifiedForm.controls[1239].setErrors(null);
  //     this.gaUnifiedForm.controls[408].setErrors(null);
  //     this.gaUnifiedForm.controls[409].setErrors(null);
  //     this.gaUnifiedForm.controls[410].setErrors(null);
  //     this.gaUnifiedForm.controls[412].setErrors(null);
  //     this.gaUnifiedForm.controls[413].setErrors(null);
  //     this.gaUnifiedForm.controls[414].setErrors(null);
  //     this.gaUnifiedForm.controls[415].setErrors(null);
  //   }
  // }

  // public proWorkHistory(event) {
  //   // console.log(event.target.value, event.target.checked);
  //   this.proWorkHistoryDoesnot = event.target.checked;
  //   if (this.proWorkHistoryDoesnot) {
  //     this.gaUnifiedForm.controls[445].setErrors(null);
  //     this.gaUnifiedForm.controls[452].setErrors(null);
  //     this.gaUnifiedForm.controls[453].setErrors(null);
  //     this.gaUnifiedForm.controls[454].setErrors(null);
  //     this.gaUnifiedForm.controls[455].setErrors(null);
  //     this.gaUnifiedForm.controls[446].setErrors(null);
  //     this.gaUnifiedForm.controls[447].setErrors(null);
  //     this.gaUnifiedForm.controls[448].setErrors(null);
  //     this.gaUnifiedForm.controls[450].setErrors(null);
  //     this.gaUnifiedForm.controls[451].setErrors(null);
  //   }
  // }

  // public facultyPosition(event) {
  //   // console.log(event.target.value, event.target.checked);
  //   this.facultyPositionsDoesnot = event.target.checked;
  //   if (this.facultyPositionsDoesnot) {
  //     this.gaUnifiedForm.controls[1147].setErrors(null);
  //     this.gaUnifiedForm.controls[1154].setErrors(null);
  //     this.gaUnifiedForm.controls[1146].setErrors(null);
  //     this.gaUnifiedForm.controls[1148].setErrors(null);
  //     this.gaUnifiedForm.controls[1149].setErrors(null);
  //     this.gaUnifiedForm.controls[1150].setErrors(null);
  //     this.gaUnifiedForm.controls[1152].setErrors(null);
  //     this.gaUnifiedForm.controls[1153].setErrors(null);
  //     this.gaUnifiedForm.controls[1155].setErrors(null);
  //     this.gaUnifiedForm.controls[1156].setErrors(null);
  //   }
  // }
  clearInputIfInvalid(controlName: string,handleYesNoValue?:string) {
    const control = this.gaUnifiedForm.get(controlName);
    const dateString = control.value;
  
    // Use the dateFormatValidator to check if the date is valid
    const validationErrors = this.dateFormatValidator(new FormControl(dateString));
  
    if (validationErrors) {
      // If any of the validation conditions are met, clear the input field and set errors to null
      control.setValue('');
      control.setErrors(null);
    }
    if(handleYesNoValue ){
      
        this.handleNAchange(handleYesNoValue);
    }
    
    
  }
  clearInputIfInvalidMandi(controlName: string) {
    const control = this.gaUnifiedForm.get(controlName);
    const dateString = control.value;
  
    // Use the dateFormatValidator to check if the date is valid
    const validationErrors = this.dateFormatValidator(new FormControl(dateString));
  
    if (validationErrors) {
      // If any of the validation conditions are met, clear the input field and set errors to null
      control.setValue('');

    }}

  dateFormatValidator(control: FormControl): ValidationErrors | null {
    const dateString = control.value;
    const dateFormat = /^\d{2}\/\d{2}\/\d{4}$/;
  
    if (dateString && !dateString.match(dateFormat)) {
      return { 'invalidDateFormat': true };
    }
  


    if (dateString) {
      const dateParts = dateString.split('/');
      const month = parseInt(dateParts[0]);
      const day = parseInt(dateParts[1]);
      const year = parseInt(dateParts[2]);
  
      // Check if any of the date components are zero or month is not between 1 and 12
      
  
      const ListofDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
      
      
      if (month < 1 || month > 12 || day === 0 || year === 0) {
        return { 'invalidMonth': true };
      }
      if (month === 1 || month > 2) {
        if (day > ListofDays[month - 1]) {
          return { 'invalidDateRange': true };
        }
      } else if (month === 2) {
        const leapYear = (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
        if (!leapYear && day >= 29) {
          return { 'invalidDateRange': true };
        }
         else if (leapYear && day > 29) {
          return { 'invalidDateRange': true };
        }
      }
      if (year < 1900 || year > 2099) {
        return { 'invalidDateyear': true };
      }
      
     
    }
  
    return null;
  }
  handleNAchange(cName,eName?){
    let result:boolean=false;
    if(cName=='cmd_0193'){
      let x=[560,557,554,556,558,559]
      x.forEach(element => {
        const ctrl = this.gaUnifiedForm.controls[element];
          if ((ctrl.value === '' || ctrl.value === null) && !this.gaUnifiedForm.controls[cName].value) {
            this.setErrorControl(element);
            result = true;
          } else {
            this.removeErrorControl(element);
            ctrl.setErrors(null);
          }
      });
    } 
    if(cName=='cmd_020'){
      let x=[479,480,482,485,487,489]
      x.forEach(element => {
        const ctrl = this.gaUnifiedForm.controls[element];
          if ((ctrl.value === '' || ctrl.value === null) && !this.gaUnifiedForm.controls[cName].value) {
            this.setErrorControl(element);
            result = true;
          } else {
            this.removeErrorControl(element);
            ctrl.setErrors(null);
          }
      });
    } 
    if(cName=='cmd_043'){
      let x=[1147,1154,1146,1148,1149,1150,1152,1153,1155,1156]
      x.forEach(element => {
        const ctrl = this.gaUnifiedForm.controls[element];
          if ((ctrl.value === '' || ctrl.value === null) && !this.gaUnifiedForm.controls[cName].value) {
            this.setErrorControl(element);
            result = true;
          } else {
            this.removeErrorControl(element);
            ctrl.setErrors(null);
          }
      });
    } 
    if(cName=='cmd_044'){
      let x=[1139,1140,1141,1144,1145,'cmd_045']
      x.forEach(element => {
        const ctrl = this.gaUnifiedForm.controls[element];
          if ((ctrl.value === '' || ctrl.value === null) && !this.gaUnifiedForm.controls[cName].value) {
            this.setErrorControl(element);
            result = true;
          } else {
            this.removeErrorControl(element);
            ctrl.setErrors(null);
          }
      });
    } 
    if(cName=='cmd_04411'){
      let x=[397,399,1239,408,409,410,412,413,414,415]
      x.forEach(element => {
        const ctrl = this.gaUnifiedForm.controls[element];
          if ((ctrl.value === '' || ctrl.value === null) && !this.gaUnifiedForm.controls[cName].value) {
            this.setErrorControl(element);
            result = true;
          } else {
            this.removeErrorControl(element);
            ctrl.setErrors(null);
          }
      });
    }
    if(cName=='cmd_048'){
      let x=[1274,300,907,307,308,3690,'cmd_049']
      x.forEach(element => {
        const ctrl = this.gaUnifiedForm.controls[element];
          if ((ctrl.value === '' || ctrl.value === null) && !this.gaUnifiedForm.controls[cName].value) {
            this.setErrorControl(element);
            result = true;
          } else {
            this.removeErrorControl(element);
            ctrl.setErrors(null);
          }
      });
    }
    if(cName=='cmd_052'){
      let x=[418,894,426,419,420,421,423,424,427,895,435,428,429,430,432,433]
      x.forEach(element => {
        const ctrl = this.gaUnifiedForm.controls[element];
          if ((ctrl.value === '' || ctrl.value === null) && !this.gaUnifiedForm.controls[cName].value) {
            this.setErrorControl(element);
            result = true;
          } else {
            this.removeErrorControl(element);
            ctrl.setErrors(null);
          }
      });
    }
    if(cName=='cmd_0201'){
      let x=[445,452,453,454,455,446,447,448,450,451]
      x.forEach(element => {
        const ctrl = this.gaUnifiedForm.controls[element];
          if ((ctrl.value === '' || ctrl.value === null) && !this.gaUnifiedForm.controls[cName].value) {
            this.setErrorControl(element);
            result = true;
          } else {
            this.removeErrorControl(element);
            ctrl.setErrors(null);
          }
      });
    }
    if(cName=='cmd_081'){
      let x=[1138,3395,3396,1831,3397,3398,1832,3399,3400]
      x.forEach(element => {
        const ctrl = this.gaUnifiedForm.controls[element];
          if ((ctrl.value === '' || ctrl.value === null) && !this.gaUnifiedForm.controls[cName].value) {
            this.setErrorControl(element);
            result = true;
          } else {
            this.removeErrorControl(element);
            ctrl.setErrors(null);
          }
      });
    }
  }
  setErrorControl(name){
    this.gaUnifiedForm.controls[name].setErrors({ errors: true });
  }
  removeErrorControl(name){
    this.gaUnifiedForm.controls[name].setErrors(null)
  }

}
