<ngx-spinner bdColor = "rgba(51, 51, 51, 0.8)" size = "large" color = "#fff" [fullScreen] = "true"
type = "ball-clip-rotate-pulse"></ngx-spinner>
<div class="modal-header">
  <h4 class="modal-title">Move Record To :</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.close('close')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col">
      <div class="custom-control custom-radio" *ngFor="let item of dataParams.data">
        <input type="radio" [id]="item.id+'check'" [name]="item.id+'check'" [(ngModel)]="selected" [value]="item.id" class="custom-control-input">
        <label class="custom-control-label" [for]="item.id+'check'">{{item.name}}</label>
      </div>
    </div>
  </div>
  </div>
  <div class="modal-footer">
    <button  *ngIf="!readOnlyUser" type="button" class="btn customOrange float-right" [disabled]="selected==undefined" (click)="confirm()">Confirm</button>
  </div>
