import { Injectable } from '@angular/core';
import * as GibberishAES from  '../../../thirdparty/gibberish-aes-1.0.0.min.js';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  cmd="fjsnwROzrbx/6sFGJ3+YHUqwzK246x8OrkmDycq1090="
  constructor() { }

  setItem(key:any,value:any){

  let encryptValue=GibberishAES.enc(value, this.cmd);
  localStorage.setItem(key,encryptValue)

  }

  getItem(key:any){
   let encryptValue= localStorage.getItem(key)
   
   if(encryptValue){
    return GibberishAES.dec(encryptValue, this.cmd);
   }
   else{
    return null
   }
  }
  
  clearItem(){
    localStorage.clear();
  }
}
