import { AuthGuard } from './containers/auth-routing/auth-guard/auth-guard';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { ToastrModule } from 'ngx-toastr';
import { AppComponent } from './app.component';
import { MainLayoutComponent } from './shared/layout/main-layout/main-layout.component';
import { SideNavComponent } from './shared/layout/side-nav/side-nav.component';
import { HeaderComponent } from './shared/layout/header/header.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { AuthService } from './containers/auth-routing/auth-guard/auth.service';
import { FormGuard } from './containers/forms/form-guard/form-guard';
import { FormGuardService } from './containers/forms/form-guard/form-guard.service';
import { SharedModule } from './shared/SharedForms/shared.module';

import { HttpClientModule } from '@angular/common/http';
import { CreateNoteComponent } from './shared/components/create-note/create-note.component';
import { CreateTaskComponent } from './shared/components/create-task/create-task.component';
import { StatusUpdateModalComponent } from './shared/components/status-update-modal/status-update-modal.component';
import { ConfirmModalComponent } from './shared/components/confirm-modal/confirm-modal.component';
import { ToasterNotiService } from './shared/services/notifications/toaster-noti.service';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { UiSwitchModule } from 'ngx-ui-switch';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { HighchartsChartModule } from 'highcharts-angular';

import { SortablejsModule } from 'ngx-sortablejs';
import { LightboxModule } from 'ngx-lightbox';
import { SharedComponentsModule } from './shared/SharedComponents/shared-components.module';
import { MoveModalComponent } from './shared/components/move-modal/move-modal.component';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { ChangePasswordComponent } from './shared/layout/change-password/change-password.component';
import { UpdatePasswordComponent } from './containers/auth-routing/update-password/update-password.component';
import { MedicalStafVerificationComponent } from './shared/components/medical-staf-verification/medical-staf-verification.component';
import { MedicalStafModelComponent } from './shared/components/medical-staf-verification/medical-staf-model/medical-staf-model.component';
import { NgxEditorModule } from 'ngx-editor';

@NgModule({
  declarations: [
    AppComponent,
    MainLayoutComponent,
    SideNavComponent,
    HeaderComponent,
    CreateNoteComponent,
    CreateTaskComponent,
    StatusUpdateModalComponent,
    ConfirmModalComponent,
    MoveModalComponent,
    ChangePasswordComponent,
    UpdatePasswordComponent,
    MedicalStafVerificationComponent,
    MedicalStafModelComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    NgxDatatableModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    SharedModule,

    HttpClientModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    UiSwitchModule,
    PdfViewerModule,
    HighchartsChartModule,
    SortablejsModule.forRoot({ animation: 150 }),
    LightboxModule,
    SharedComponentsModule,
    AutocompleteLibModule,
    NgxEditorModule
  ],
  entryComponents: [UpdatePasswordComponent],
  providers: [
    AuthGuard,
    AuthService,
    FormGuard,
    FormGuardService,
    ToasterNotiService
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
