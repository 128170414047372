import { Component,OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { OnboardingformsServiceService } from '../../../services/onboardingforms-service/onboardingforms-service.service';
import { ToasterNotiService } from '../../../services/notifications/toaster-noti.service';

import { EsignComponent } from '../../esign/esign.component';
import { DomSanitizer } from '@angular/platform-browser';
import {
NgbModal,
NgbModalOptions,
NgbDateAdapter,
NgbDateParserFormatter
} from '@ng-bootstrap/ng-bootstrap';

@Component({
selector: 'ctg-Intermed-llc-Section1',                                                                                                                                                   
templateUrl: './Intermed-llc-Section1.component.html',
styleUrls: ['./Intermed-llc-Section1.component.scss']
})
export class InterMedLLCSection1Component implements OnInit {

    @Input() intermedimage1: FormGroup
    @Input() isSubmit;

    @Output() isHandleNAChanges = new EventEmitter();
    @Output() isButtonYes = new EventEmitter();
    @Output() isButtonNo = new EventEmitter();

    @Input() showTwo: any = false;
    @Input() showOne: any = false;

    @Input() esignOne: any;
    @Input() esignTwo: any;

    @Input() finalImgPath: any = '';
    @Input() finalImg: any;

    @Input() finalImgPathTwo: any = [];
    @Input() finalImgTwo: any = '';


    @Output() isclearInputIfInvalid = new EventEmitter();
    @Output() isclearInputIfInvalidMandi = new EventEmitter();


    constructor(
        private fb: FormBuilder,
        private modalService: NgbModal,
        private _sanitizer: DomSanitizer,
        private onboardService: OnboardingformsServiceService, private notiService: ToasterNotiService,

    ) { }

    @Input() formData;
    @Input() fieldsData;
    @Input() formId;

    modalOption: NgbModalOptions = {
        size: 'md',
        keyboard: false,
        backdrop: 'static'
    };

    ngOnInit(): void {
        this.getStatesData();
        this.getPreClaimData();
        this.getAggregatesData();
        this.getSpecialtyData();
        this.getSpecialtySecondaryData();
        this.getBoardCertificationData();
        this.getMediCertifications();
        this.getTerSpecialtyData();
        this.getStaffStatusData();



    }

    states: Array<object> = [];
    specialty: Array<object> = [];
    specialtySecondary: Array<object> = [];
    boardCertification: Array<object> = [];
    specialtyTer: Array<object> = [];
    mediCertifications: Array<object> = [];
    public preClaim: any = [];
    staffStatus: Array<object> = [];
    public aggregates: any = [];


    public getStatesData() {
        let params: {};
        this.onboardService.getStatesData(params).subscribe(
            data => {
                if (data['success']) {
                    this.states = data.data.map(i => ({
                        ...i,
                        id: i.id.toString()
                    }));
                } else {
                    this.notiService.showError(data.error, '', 3000);
                }
            },
            error => {
            }
        );
    }
    public getSpecialtyData() {
        let params: {};
        //this.spinner.show();
        this.onboardService.getSpecialtyData(params).subscribe(
            data => {
                if (data['success']) {
                    this.specialty = data.data.map(i => ({
                        ...i,
                        id: i.id.toString()
                    }));
                } else {
                    this.notiService.showError(data.error, '', 3000);
                }
                //this.spinner.hide();
            },
            error => {
                //this.spinner.hide();
            }
        );
    }
    public getSpecialtySecondaryData() {
        let params: {};
        //this.spinner.show();
        this.onboardService.getSpecialtySecondaryData(params).subscribe(
            data => {
                if (data['success']) {
                    this.specialtySecondary = data.data.map(i => ({
                        ...i,
                        id: i.id.toString()
                    }));
                } else {
                    this.notiService.showError(data.error, '', 3000);
                }
                //this.spinner.hide();
            },
            error => {
                //this.spinner.hide();
            }
        );
    }
    public getAggregatesData() {
        let params: {};

        this.onboardService.getAggregatesData(params).subscribe(
            data => {
                if (data['success']) {
                    this.aggregates = data.data.map(i => ({
                        ...i,
                        id: i.id.toString()
                    }));
                } else {
                    this.notiService.showError(data.error, '', 3000);
                }
            },
            error => {
            }
        );
    }
    public getPreClaimData() {
        let params: {};
        this.onboardService.getPreClaimData(params).subscribe(
            data => {
                if (data['success']) {
                    this.preClaim = data.data.map(i => ({
                        ...i,
                        id: i.id.toString()
                    }));
                } else {
                    this.notiService.showError(data.error, '', 3000);
                }
            },
            error => {
            }
        );
    }
    public getBoardCertificationData() {
        let params: {};
        //this.spinner.show();
        this.onboardService.getBoardCertificationData(params).subscribe(
            data => {
                if (data['success']) {
                    this.boardCertification = data.data.map(i => ({
                        ...i,
                        id: i.id.toString()
                    }));
                } else {
                    this.notiService.showError(data.error, '', 3000);
                }
                //this.spinner.hide();
            },
            error => {
                //this.spinner.hide();
            }
        );
    }
    public getTerSpecialtyData() {
        let params: {};
        //this.spinner.show();
        this.onboardService.getTerSpecialtyData(params).subscribe(
            data => {
                if (data['success']) {
                    this.specialtyTer = data.data.map(i => ({
                        ...i,
                        id: i.id.toString()
                    }));
                } else {
                    this.notiService.showError(data.error, '', 3000);
                }
                //this.spinner.hide();
            },
            error => {
                //this.spinner.hide();
            }
        );
    }
    public getMediCertifications() {
        let params: {};
        //this.spinner.show();
        this.onboardService.getMediCertifications(params).subscribe(
            data => {
                if (data['success']) {
                    this.mediCertifications = data.data.map(i => ({
                        ...i,
                        id: i.id.toString()
                    }));
                } else {
                    this.notiService.showError(data.error, '', 3000);
                }
                //this.spinner.hide();
            },
            error => {
                //this.spinner.hide();
            }
        );
    }
    public getStaffStatusData() {
        let params: {};
        //this.spinner.show();
        this.onboardService.getStaffStatusData(params).subscribe(
            data => {
                if (data['success']) {
                    this.staffStatus = data.data.map(i => ({
                        ...i,
                        id: i.id.toString()
                    }));
                } else {
                    this.notiService.showError(data.error, '', 3000);
                }
                //this.spinner.hide();
            },
            error => {
                //this.spinner.hide();
            }
        );
    }
    setErrorControl(name) {
        this.intermedimage1.controls[name].setErrors({ errors: true });
    }
    removeErrorControl(name) {
        this.intermedimage1.controls[name].setErrors(null)
    }

    handleNAchange(event) {
        this.isHandleNAChanges.emit(event)

    }
    BCRadioButtonYes(event) {
        this.isButtonYes.emit(event)

    }
    BCRadioButtonNo(event) {
        this.isButtonNo.emit(event)
    }

    public esignOpenTwo() {
        const modalRef = this.modalService.open(EsignComponent, this.modalOption);
        modalRef.result.then(result => { });
        modalRef.componentInstance.base64Img.subscribe(data => {
            modalRef.close();
            this.finalImgPathTwo = data.finalFilePath;
            let temp = {
                cmd_355: this.finalImgPathTwo
            };
            this.intermedimage1.patchValue(temp);
            this.finalImgPathTwo = data.finalFilePath;
            this.finalImgTwo = data.base64Img;
            this.esignTwo = this._sanitizer.bypassSecurityTrustResourceUrl(
                'data:image/jpg;base64,' + this.finalImgTwo
            );
            this.showTwo = true;
        });
    }


    public esignOpenOne() {
        const modalRef = this.modalService.open(EsignComponent, this.modalOption);
        modalRef.result.then(result => { });
        modalRef.componentInstance.base64Img.subscribe(data => {
            modalRef.close();
            this.finalImgPath = data.finalFilePath;
            let temp = {
                cmd_353: this.finalImgPath
            };
            this.intermedimage1.patchValue(temp);
            this.finalImgPath = data.finalFilePath;
            this.finalImg = data.base64Img;
            this.esignOne = this._sanitizer.bypassSecurityTrustResourceUrl(
                'data:image/jpg;base64,' + this.finalImg
            );
            this.showOne = true;
        });
    }



clearInputIfInvalid(event) {
    this.isclearInputIfInvalid.emit(event)

  }


clearInputIfInvalidMandi(event) {
    this.isclearInputIfInvalidMandi.emit(event)

  }
 

}

