import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'

@Component({
  selector: 'ctg-send-mail-notification',
  templateUrl: './send-mail-notification.component.html',
  styleUrls: ['./send-mail-notification.component.scss']
})
export class SendMailNotificationComponent implements OnInit {
@Input() isSend:boolean;
@Input() mailDetails:any;
@Output() sendEmailEmit:EventEmitter<any> = new EventEmitter();

  constructor(public activeModal: NgbActiveModal, ) { }

  ngOnInit(): void {
  }

  onsendEmail(){
    this.sendEmailEmit.emit(this.mailDetails);
  }

}
