import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, FormControl, ValidationErrors } from '@angular/forms';
import { OnboardingformsServiceService } from '../../services/onboardingforms-service/onboardingforms-service.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToasterNotiService } from '../../services/notifications/toaster-noti.service';
import {
  NgbModal,
  NgbModalOptions,
  NgbDateAdapter,
  NgbDateParserFormatter
} from '@ng-bootstrap/ng-bootstrap';
import { EsignComponent } from '../esign/esign.component';
import { DomSanitizer } from '@angular/platform-browser';
import {
  CustomAdapter,
  CustomDateParserFormatter
} from '../../DateFormatter/customDate';

@Component({
  selector: 'ctg-lifeways-direct',
  templateUrl: './lifeways-direct.component.html',
  styleUrls: ['./lifeways-direct.component.scss'],
  providers: [
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }
  ]
})
export class LifewaysDirectComponent implements OnInit {
  constructor(
    private fb: UntypedFormBuilder,
    private onboardService: OnboardingformsServiceService,
    private spinner: NgxSpinnerService,
    private notiService: ToasterNotiService,
    private modalService: NgbModal,
    private _sanitizer: DomSanitizer
  ) {}

  @Input() formData;
  @Input() fieldsData;
  @Input() formId;
  @Output() onSubmit = new EventEmitter();
  @Output() onClose = new EventEmitter();
  public lifeWaysDirect: UntypedFormGroup;
  modalOption: NgbModalOptions = {
    size: 'md',
    keyboard: false,
    backdrop: 'static'
  };
  credentials: Array<object> = [];
  greetings: Array<object> = [];
  specialty: Array<object> = [];
  specialtySecondary: Array<object> = [];
  degree: Array<object> = [];
  location: Array<object> = [];
  languages: Array<object> = [];
  secondaryLanguages: Array<object> = [];
  tertiaryLanguages: Array<object> = [];
  quaternaryLanguages: Array<object> = [];
  boardCertification: Array<object> = [];
  states: Array<object> = [];
  mediCertification: Array<object> = [];
  county: Array<object> = [];
  race: Array<object> = [];
  changeCmdOne: any;
  id: '';
  finalImgPath: any = [];
  finalImg: any;
  finalImgPathTwo: any = [];
  finalImgTwo: any = '';
  esignOne: any;
  esignTwo: any;
  showOne: any = false;
  showTwo: any = false;
  isSubmit: boolean = false;
  firstName: any;
  lastName: any;
  middleName: any;
  dob: any;
  ImgOne: any;
  imgOneSet: any;
  imgTwoSet: any;
  imgOneSetting: any;
  private emailPattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  ngOnInit(): void {
    this.buildForm();
    this.getCredentialsData();
    this.getMediCertifications();
    this.getDegreeData();
    this.getStatesData();
    this.getCountyData();
    this.getLanguagesData();
    this.getsecondaryLanguagesData();
    this.gettertiaryLanguagesData();
    this.getquaternaryLanguagesData();
    this.getRaceData();
    this.fieldsData && this.bindFormValue(this.fieldsData);
    this.afterSaveDataDisplay();
  }

  public bindFormValue(array) {
    let fieldsCtrls = {};
    for (let i = 0; i < array.length; i++) {
      fieldsCtrls[array[i].field_name] = array[i].field_value;
      if (array[i].field_name == 'cmd_1000120') {
        if (array[i].field_value != '') {
          this.previewEsign(array[i].field_value, array[i].field_name);
        }
      }
      if (array[i].field_name == 'cmd_1000130') {
        if (array[i].field_value != '') {
          this.previewEsign(array[i].field_value, array[i].field_name);
        }
      }
      if (array[i].field_name == 'cmd_10212') {
        if (array[i].field_value == 1) {
          this.changeCmdOne = 1;
        } else {
          this.changeCmdOne = 0;
        }
      }
    }
    this.lifeWaysDirect.patchValue(fieldsCtrls);
  }

  buildForm() {
    this.lifeWaysDirect = this.fb.group({
      cmd_3000: [''],
      cmd_3001: [''],
      cmd_3002: [''],
      cmd_3003: [''],
      cmd_3004: [''],
      cmd_3005: [''],
      cmd_3007: [''],
      cmd_3008: [''],
      cmd_3009: [''],
      1535: [null],
      1536: [null],
      2207: [null],
      277: [null],
      9: ['', [Validators.required]],
      8: ['', [Validators.required]],
      809: ['', [Validators.required]],
      759: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
      822: [null, [Validators.required]],
      1634: ['', [Validators.required]],
      31: ['', [Validators.required,this.dateFormatValidator]],
      32: ['', [Validators.required]],
      741: [null, [Validators.required]],
      512: [null],
      503: [''],
      511: [null, [this.dateFormatValidator]],
      480: [null],
      482: [null, [this.dateFormatValidator]],
      479: [''],
      500: [null],
      490: [''],
      499: [null, [this.dateFormatValidator]],
      529: [''],
      539: [null, [this.dateFormatValidator]],
      530: [null],
      cmd_3010: [''],
      cmd_3011: [''],
      cmd_3012: [''],
      cmd_3013: [''],
      cmd_3014: [''],
      cmd_3015: [''],
      cmd_10200: ['', [Validators.required]],
      cmd_10201: ['', [Validators.required]],
      cmd_10202: ['', [Validators.required]],
      cmd_10203: ['', [Validators.required]],
      cmd_10204: ['', [Validators.required]],
      cmd_10205: ['', [Validators.required]],
      cmd_10206: ['', [Validators.required]],
      cmd_10207: ['', [Validators.required]],
      cmd_10208: ['', [Validators.required]],
      cmd_10209: ['', [Validators.required]],
      cmd_10210: ['', [Validators.required]],
      cmd_10211: ['', [Validators.required]],
      cmd_112352: [null,this.dateFormatValidator],
      cmd_112353: [null,this.dateFormatValidator],
      cmd_10212: ['', [Validators.required]],
      cmd_10213: ['', [Validators.required]],
      cmd_10215: ['', [Validators.required]],
      cmd_1000120: ['', [Validators.required]],
      cmd_112350: [null, [Validators.required,this.dateFormatValidator]],
      cmd_11235: [''],
      cmd_11234: [''],
      cmd_11236: [''],
      cmd_11237: [null, [this.dateFormatValidator]],
      278: ['', [Validators.required]],
      30: ['', [Validators.required]],
      cmd_12405: [''],
      cmd_1000130: ['', [Validators.required]],
      cmd_112351: [null, [Validators.required,this.dateFormatValidator]]
    });
    // console.log(this.lifeWaysDirect);
  }

  public previewEsign(filePath, fieldId) {
    //this.spinner.show();
    let params = {
      filePath: filePath
    };
    this.onboardService.getPreviewEsign(params).subscribe(
      (data: any) => {
        if (data['success']) {
          this.ImgOne = data.base64;
          this.setEsign(this.ImgOne, fieldId);
        } else {
          //this.notiService.showError(data.error, '', 3000)
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public setEsign(filePath, fieldId) {
    this.imgOneSetting = 1;
    // console.log(filePath);
    if (fieldId == 'cmd_1000120') {
      this.esignOne = this._sanitizer.bypassSecurityTrustResourceUrl(
        'data:image/jpg;base64,' + filePath
      );
      this.showOne = true;
    }
    if (fieldId == 'cmd_1000130') {
      this.esignTwo = this._sanitizer.bypassSecurityTrustResourceUrl(
        'data:image/jpg;base64,' + filePath
      );
      this.showTwo = true;
    }
  }

  public getCredentialsData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getCredentialsData(params).subscribe(
      data => {
        if (data['success']) {
          this.credentials = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getRaceData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getRaceData(params).subscribe(
      data => {
        if (data['success']) {
          this.race = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getCountyData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getCountyData(params).subscribe(
      data => {
        if (data['success']) {
          this.county = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getMediCertifications() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getMediCertifications(params).subscribe(
      data => {
        if (data['success']) {
          this.mediCertification = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getDegreeData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getDegreeData(params).subscribe(
      data => {
        if (data['success']) {
          this.degree = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public afterSaveDataDisplay() {
    let params = {
      type: 24,
      formId: this.formId
    };
    //this.spinner.show();
    this.onboardService.toGetSaveData(params).subscribe(
      data => {
        if (data['success']) {
          this.bindFormValue(data.data);
        } else {
          //this.notiService.showError(data.error, '', 3000)
        }
        //this.spinner.hide();
      },
      error => {
        //this.notiService.showError(Error_Message, '', 3000)
      }
    );
  }

  public getStatesData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getStatesData(params).subscribe(
      data => {
        if (data['success']) {
          this.states = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getLanguagesData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getLanguagesData(params).subscribe(
      data => {
        if (data['success']) {
          this.languages = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getsecondaryLanguagesData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getsecondaryLanguagesData(params).subscribe(
      data => {
        if (data['success']) {
          this.secondaryLanguages = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public gettertiaryLanguagesData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.gettertiaryLanguagesData(params).subscribe(
      data => {
        if (data['success']) {
          this.tertiaryLanguages = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getquaternaryLanguagesData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getquaternaryLanguagesData(params).subscribe(
      data => {
        if (data['success']) {
          this.quaternaryLanguages = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  saveForm(type) {
    console.log(this.lifeWaysDirect.controls)
    this.isSubmit = true;
    if (type == 'submit') {
      if (
        this.lifeWaysDirect.invalid ||
        (this.changeCmdOne &&
          (this.lifeWaysDirect.controls['cmd_112352'].value == null ||
          this.lifeWaysDirect.controls['cmd_112353'].value == null))
      ) {
        this.notiService.showError(
          'Please Fill the all mandatory fields to continue',
          '',
          3000
        );
      } else {
        let data = {
          formType: type,
          formValues: this.lifeWaysDirect.value
        };
        this.onSubmit.emit(data);
      }
    } else {
      let data = {
        formType: type,
        formValues: this.lifeWaysDirect.value,
        formId: 24
      };
      this.onSubmit.emit(data);
      //this.onClose.emit('close modal');
    }
  }

  onChangeCmdOne(event) {
    if (event.target.value == 1) {
      this.changeCmdOne = true;
    } else {
      this.changeCmdOne = false;
      this.lifeWaysDirect.get("cmd_112352").setErrors(null);
      this.lifeWaysDirect.get("cmd_112353").setErrors(null);
      
      
    }
  }

  onChangeFirstName(event) {
    //cmd_11234
    this.firstName = event.target.value;
    let temp = {
      cmd_11234: this.firstName
    };
    this.lifeWaysDirect.patchValue(temp);
  }

  onChangeLastName(event) {
    //cmd_11235
    this.lastName = event.target.value;
    let temp = {
      cmd_11235: this.lastName
    };
    this.lifeWaysDirect.patchValue(temp);
  }

  onChangeMiddleName(event) {
    //cmd_11236
    this.middleName = event.target.value;
    let temp = {
      cmd_11236: this.middleName
    };
    this.lifeWaysDirect.patchValue(temp);
  }

  onChangeDobName(event) {
    //cmd_11237
    // console.log(event)
    this.lifeWaysDirect.controls['cmd_11237'].setValue(event);
  }

  close() {
    this.onClose.emit('close modal');
  }

  public esignOpenOne() {
    const modalRef = this.modalService.open(EsignComponent, this.modalOption);
    modalRef.result.then(result => {});
    modalRef.componentInstance.base64Img.subscribe(data => {
      modalRef.close();
      this.finalImgPath = data.finalFilePath;
      // console.log(this.finalImgPathTwo);
      let temp = {
        cmd_1000120: this.finalImgPath
      };
      this.lifeWaysDirect.patchValue(temp);
      this.finalImg = data.base64Img;
      this.esignOne = this._sanitizer.bypassSecurityTrustResourceUrl(
        'data:image/jpg;base64,' + this.finalImg
      );
      this.showOne = true;
    });
  }

  public esignOpenTwo() {
    const modalRef = this.modalService.open(EsignComponent, this.modalOption);
    modalRef.result.then(result => {});
    modalRef.componentInstance.base64Img.subscribe(data => {
      modalRef.close();
      this.finalImgPathTwo = data.finalFilePath;
      // console.log(this.finalImgPathTwo);
      let temp = {
        cmd_1000130: this.finalImgPathTwo
      };
      this.lifeWaysDirect.patchValue(temp);
      this.finalImgTwo = data.base64Img;
      this.esignTwo = this._sanitizer.bypassSecurityTrustResourceUrl(
        'data:image/jpg;base64,' + this.finalImgTwo
      );
      this.showTwo = true;
    });
  }
  clearInputIfInvalidForYesorNo(controlName: string,handleYesNoValue?:string) {
    const control = this.lifeWaysDirect.get(controlName);
    const dateString = control.value;
  
    // Use the dateFormatValidator to check if the date is valid
    const validationErrors = this.dateFormatValidator(new FormControl(dateString));
  
    if (validationErrors) {
      // If any of the validation conditions are met, clear the input field and set errors to null
      control.setValue(null);
      control.setErrors({ errors: true });
    }
    // if(handleYesNoValue ){
    //   // if(handleYesNoValue == ("cmd_05" || "cmd_024" || "cmd_023")){
    //   //   this.handleYesNoChanges(handleYesNoValue);
    //   // }
    //   // else{
    //   //     this.handleNAchange(handleYesNoValue);
    //   // }
      
      
    // }
    
  }
  clearInputIfInvalidMandi(controlName: string) {
    const control = this.lifeWaysDirect.get(controlName);
    const dateString = control.value;
  
    // Use the dateFormatValidator to check if the date is valid
    const validationErrors = this.dateFormatValidator(new FormControl(dateString));
  
    if (validationErrors) {
      // If any of the validation conditions are met, clear the input field and set errors to null
      control.setValue('');

    }}
    clearInputIfInvalid(controlName: string,handleYesNoValue?:string) {
      const control = this.lifeWaysDirect.get(controlName);
      const dateString = control.value;
    
      // Use the dateFormatValidator to check if the date is valid
      const validationErrors = this.dateFormatValidator(new FormControl(dateString));
    
      if (validationErrors) {
        // If any of the validation conditions are met, clear the input field and set errors to null
        control.setValue('');
        control.setErrors(null);
      }
     
      
    }
  dateFormatValidator(control: FormControl): ValidationErrors | null {
    const dateString = control.value;
    const dateFormat = /^\d{2}\/\d{2}\/\d{4}$/;
  
    if (dateString && !dateString.match(dateFormat)) {
      return { 'invalidDateFormat': true };
    }
  


    if (dateString) {
      const dateParts = dateString.split('/');
      const month = parseInt(dateParts[0]);
      const day = parseInt(dateParts[1]);
      const year = parseInt(dateParts[2]);
  
      // Check if any of the date components are zero or month is not between 1 and 12
      
  
      const ListofDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
      
      
      if (month < 1 || month > 12 || day === 0 || year === 0) {
        return { 'invalidMonth': true };
      }
      if (month === 1 || month > 2) {
        if (day > ListofDays[month - 1]) {
          return { 'invalidDateRange': true };
        }
      } else if (month === 2) {
        const leapYear = (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
        if (!leapYear && day >= 29) {
          return { 'invalidDateRange': true };
        }
         else if (leapYear && day > 29) {
          return { 'invalidDateRange': true };
        }
      }
      if (year < 1900 || year > 2099) {
        return { 'invalidDateyear': true };
      }
      
     
    }
  
    return null;
  }
}
