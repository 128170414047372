import { FormControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import {
  NgbModal,
  NgbModalOptions,
  NgbDateAdapter,
  NgbDateParserFormatter
} from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToasterNotiService } from '../../services/notifications/toaster-noti.service';
import { OnboardingformsServiceService } from '../../services/onboardingforms-service/onboardingforms-service.service';
import { StorageService } from '../../services/storage/storage.service';
import { EsignComponent } from '../esign/esign.component';
import {
  CustomAdapter,
  CustomDateParserFormatter
} from '../../DateFormatter/customDate';
@Component({
    selector: 'cancer-care-northwest',
    templateUrl: './cancer-care-northwest.component.html',
    styleUrls: ['./cancer-care-northwest.component.scss'],
    providers: [
      { provide: NgbDateAdapter, useClass: CustomAdapter },
      { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }
    ]
  })
  export class CancerCareNorthwestComponent implements OnInit {
    public activeCheck = 0;
    @Input() formData;
    @Input() fieldsData;
    @Input() formId;
    @Output() onSubmit = new EventEmitter();
    @Output() onClose = new EventEmitter();
    private emailPattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    constructor(
      private fb: UntypedFormBuilder,
      private onboardService: OnboardingformsServiceService,
      private spinner: NgxSpinnerService,
      private notiService: ToasterNotiService,
      private modalService: NgbModal,
      private _sanitizer: DomSanitizer,
      private storageService: StorageService
    ) {}
    public userId: any;
    public customerId: any;
    public CancerCareNorthwestForms: UntypedFormGroup;
    isSubmit: boolean = false;
    private phonePattern = '^[0-9]*$';
    modalOption: NgbModalOptions = {
      size: 'md',
      keyboard: false,
      backdrop: 'static'
    };
    flagForHandle: boolean =  false;
    ngOnInit(): void {
      this.userId = Number(this.storageService.getItem('userId'));
      this.customerId = Number(this.storageService.getItem('customerId'));
      this.getSuffixData();
      this.getCredentialsData();
      this.getLanguagesData();
      this.getsecondaryLanguagesData();
      this.gettertiaryLanguagesData();
      this.getquaternaryLanguagesData();
      this.getSpecialtyData();
      this.getSpecialtySecondaryData();
      this.getStatesData();
      this.getGenderData();
      this.getAlternateLicenseStateData();
      this.getDegreeData();
      this.getAdditionalLicenseStateData();
      this.getBoardCertificationData();
      this.getTerSpecialtyData();
      this.getMediCertifications();
      this.getStaffStatusData();
      this.getPreClaimData();
      this.getAggregatesData();
      this.getRaceData();
      this.buildcancercarenorthwestForm(); 
      this.fieldsData && this.bindFormValue(this.fieldsData);
      this.afterSaveDataDisplay();
      
    } 
    //drop down arrays
    states: Array<object> = [];
    gender: Array<object> = [];
    degree: Array<object> = [];
    specialty: Array<object> = [];
    specialtySecondary: Array<object> = [];
    boardCertification: Array<object> = [];
    credentials: Array<object> = [];
    altLicenseState: Array<object> = [];
    secondaryLanguages: Array<object> = [];
    additionalLicenseState: Array<object> = [];
    public tertiaryLanguages: Array<object> = [];
    public quaternaryLanguages: Array<object> = [];
    languages: Array<object> = [];
    specialtyTer: Array<object> = [];
    mediCertifications: Array<object> = [];
    staffStatus: Array<object> = [];
    public preClaim: any = [];
    public aggregates: any = [];
    public doits: any;
    finalImgPath: any = [];
    finalImg: any;
    esignOne: any;
    esignTwo: any;
    showOne: any = false;
    showTwo: any = false;
    esignThree: any;
    showThree: any = false;
    race: Array<object> = [];
    firstName: any;
    middleName: any;
    lastName: any;
    finalName: any;
    public suffix: any = [];
    
    

    public afterSaveDataDisplay() {
      let params = {
        type: 45,
        formId: this.formId
      };
      //this.spinner.show();
      this.onboardService.toGetSaveData(params).subscribe(
        data => {
          if (data['success']) {
            this.bindFormValue(data.data);
          } else {
            // this.notiService.showError(data.error, '', 3000)
          }
          //this.spinner.hide();
        },
        error => {
          // this.notiService.showError(Error_Message, '', 3000)
        }
      );
    }

   

    public bindFormValue(array) {
      let fieldsCtrls = {};
      for (let i = 0; i < array.length; i++) {
        fieldsCtrls[array[i].field_name] = array[i].field_value; 
        if (array[i].field_name == 'cmd_0019') {
          if (array[i].field_value != '') {
            this.previewEsign(array[i].field_value, array[i].field_name);
          }
        }
        if (array[i].field_name == 'cmd_00019') {
          if (array[i].field_value != '') {
            this.previewEsign(array[i].field_value, array[i].field_name);
          }
        }
        if (array[i].field_name == 'cmd_000190') {
          if (array[i].field_value != '') {
            this.previewEsign(array[i].field_value, array[i].field_name);
          }
        }
             
      }
      
      this.CancerCareNorthwestForms.patchValue(fieldsCtrls);
    }

    buildcancercarenorthwestForm() {
      this.CancerCareNorthwestForms = this.fb.group({
        cmd_373:[''],
        9: ['', [Validators.required]],
        29: [null, [Validators.required]],
        8: ['', [Validators.required]],
        809: ['', [Validators.required]],
        822: [null, [Validators.required]],
        278:['',[Validators.required]],
        cmd_0379:[null,[Validators.required ,this.dateFormatValidator]],
        16: ['', [Validators.required]],
        17: [''],
        18: ['', [Validators.required]],
        20: [null, [Validators.required]],
        21: ['', [Validators.required]],
        708:['', [Validators.required, Validators.pattern(this.phonePattern)]],
        1017: ['',],
        709:['', [Validators.required, Validators.pattern(this.phonePattern)]],
        759: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
        31: [null, [Validators.required ,this.dateFormatValidator]],
        810: ['', [Validators.required]],
        289: [null, [Validators.required]],
        291: ['', [Validators.required]],
        292: ['', [Validators.required]],
        741:[null],
        32: ['', [Validators.required]],
        30: [null, ],
        277: [null, ],
        1535: [null,],
        1536: [null, ],
        2207: [null, ],
        cmd_01780: ['',],
        1634: ['', [Validators.required]],
        325: ['', ],
        812: ['', ],
        cmd_01816: ['', ],
        284: [null, [Validators.required]],
        962: [null, ],
        cmd_11806: [''],
        cmd_01:[''],
        cmd_02:[''],
        cmd_03:[''],
        cmd_04:[''],
        cmd_05:[''],
        cmd_06:[''],
        cmd_07:[''],
        cmd_08:[''],
        cmd_09:[''],
        cmd_10:[''],
        cmd_11:[''],
        cmd_13:[''],
        cmd_0013:[''],
        cmd_0014:[''],
        cmd_0015:[''],
        cmd_0016:[''],
        cmd_0017:[''],
        cmd_15:[''],
        cmd_16:[''],
        cmd_41806:[''],
        cmd_51806:[''],
        cmd_61806:[''],
        cmd_71806:[null],
        cmd_81806:[''],
        cmd_91806:[''],
        cmd_101806:['' ,[this.phoneNumberFormatValidator] ],
        cmd_111806:['',[this.phoneNumberFormatValidator] ],
        cmd_121806:[''],
        cmd_131806:[''],
        cmd_141806:[''],
        cmd_151806:[null],
        cmd_161806:[''],
        cmd_171806:[''],
        cmd_181806:[''],
        cmd_191806:[''],
        cmd_201806:[null],
        cmd_211806:[''],
        cmd_221806:['',[this.phoneNumberFormatValidator] ],
        cmd_231806:['',[this.phoneNumberFormatValidator] ],
        cmd_241806:['',[this.phoneNumberFormatValidator] ],
        cmd_251806:[''],
        cmd_261806:['',[this.phoneNumberFormatValidator] ],
        cmd_271806:['',[this.phoneNumberFormatValidator] ],
        cmd_281806:['',[this.phoneNumberFormatValidator] ],
        cmd_291806:['',[this.phoneNumberFormatValidator] ],
        cmd_301806:['',[this.phoneNumberFormatValidator] ],
        cmd_311806:['',[this.phoneNumberFormatValidator] ],
        cmd_321806:[''],
        cmd_331806:[''],
        cmd_341806:[''],
        cmd_351806:[null],
        cmd_361806:[''],
        cmd_371806:['',[this.emailFormatValidator]],
        cmd_381806:['',[this.phoneNumberFormatValidator] ],
        cmd_391806:['',[this.emailFormatValidator]],
        cmd_401806:[''],
        cmd_411806:[''],
        cmd_37:[''],
        cmd_421806:[''],
        cmd_49:[''],
        cmd_50:[''],
        cmd_51:[''],
        cmd_52:[''],
        cmd_53:[''],
        cmd_54:[''],
        cmd_55:[''],
        cmd_56:[''],
        cmd_58:[''],
        cmd_116:[''],
        cmd_117:[''],
        cmd_059:[''],
        cmd_0117:[''],
        cmd_59:[''],
        cmd_60:[''],
        cmd_NA_01:[''],
        cmd_431806:[''],
        cmd_441806:[''],
        cmd_451806:[''],
        cmd_461806:[''],
        cmd_NA_02:[''],
        cmd_471806:[''],
        cmd_481806:[''],
        cmd_491806:[''],
        cmd_501806:['',[this.phoneNumberFormatValidator] ],
        cmd_511806:[''],
        cmd_521806:[''],
        cmd_531806:[''],
        cmd_541806:['',[this.phoneNumberFormatValidator] ],
        cmd_551806:[null,[this.dateFormatValidator]],
        cmd_561806:[null,[this.dateFormatValidator]],
        cmd_571806:[''],
        cmd_581806:[''],
        cmd_591806:[''],
        cmd_601806:[''],
        cmd_611806:[''],
        cmd_621806:[''],
        cmd_631806:[''],
        cmd_641806:[''],
        cmd_651806:[''],
        cmd_661806:[''],
        cmd_671806:[''],
        cmd_681806:[''],
        cmd_691806:[''],
        cmd_701806:[''],
        cmd_711806:[''],
        cmd_721806:[''],
        cmd_731806:[''],
        cmd_741806:[''],
        cmd_751806:[''],
        cmd_761806:[''],
        cmd_771806:[''],
        cmd_781806:[''],
        cmd_791806:[null],
        cmd_801806:[''],
        cmd_811806:[''],
        cmd_821806:['',[this.phoneNumberFormatValidator] ],
        cmd_831806:['',[this.phoneNumberFormatValidator] ],
        cmd_0891806:[''],
        cmd_841806:[''],
        cmd_851806:[''],
        cmd_0861806:[''],
        cmd_861806:[null],
        cmd_871806:[null],
        cmd_881806:[''],
        cmd_891806:[''],
        cmd_901806:[null],
        cmd_911806:[''],
        cmd_921806:['',[this.phoneNumberFormatValidator] ],
        cmd_931806:['',[this.phoneNumberFormatValidator]],
        cmd_941806:['',[this.phoneNumberFormatValidator] ],
        cmd_951806:['',[this.phoneNumberFormatValidator] ],
        cmd_961806:[''],
        cmd_971806:['',[this.emailFormatValidator]],
        cmd_981806:['',[this.phoneNumberFormatValidator] ],
        cmd_991806:['',[this.phoneNumberFormatValidator] ],
        cmd_1001806:['',[this.phoneNumberFormatValidator] ],
        cmd_1011806:['',[this.phoneNumberFormatValidator] ],
        cmd_1021806:['',[this.phoneNumberFormatValidator] ],
        cmd_1031806:[''],
        cmd_1041806:[''],
        cmd_1051806:[''],
        cmd_1061806:[null],
        cmd_1071806:[''],
        cmd_1081806:['',[this.emailFormatValidator]],
        cmd_1091806:['',[this.phoneNumberFormatValidator]],
        cmd_1101806:[''],
        cmd_1111806:[''],
        cmd_1121806:[''],
        cmd_01121806:[''],
        cmd_1131806:[''],
        cmd_1141806:[''],
        cmd_1151806:[''],
        cmd_1161806:[''],
        cmd_1171806:[''],
        cmd_1181806:[''],
        cmd_1191806:[''],
        cmd_1201806:[''],
        cmd_1211806:[''],
        cmd_1221806:[''],
        cmd_1231806:[''],
        cmd_1241806:[''],
        cmd_1251806:[''],
        cmd_1261806:[''],
        cmd_1271806:[''],
        cmd_1281806:[''],
        cmd_1291806:[''],
        cmd_NA_03:[''],
        cmd_1301806:[''],
        cmd_1311806:[''],
        cmd_1321806:[''],
        cmd_1331806:[''],
        cmd_NA_04:[''],
        cmd_1341806:[''],
        cmd_1351806:[''],
        cmd_1361806:['',[this.phoneNumberFormatValidator] ],
        cmd_1371806:[''],
        cmd_1381806:[''],
        cmd_1391806:[''],
        cmd_1401806:['',[this.phoneNumberFormatValidator] ],
        293:['',],
        294:[null,[this.dateFormatValidator]],
        295:[null,[this.dateFormatValidator]],
        1745: [''],
        1746: [''],
        1748: [null],
        1749: [null],
        cmd_153:[''],
        cmd_NA_23:[null],
        309:[''],
        311:[null, [this.dateFormatValidator]],
        514:[''],
        515:[null, [this.dateFormatValidator]],
        cmd_NA_3:[null],
        907:[null],
        300:[''],
        307:[null,[this.dateFormatValidator]],
        308:[null,[this.dateFormatValidator]],
        cmd_154:[''],
        cmd_155:[''],
        1010:[null],
        999:[''],
        1003:[null, [this.dateFormatValidator]],
        1004:[null,[this.dateFormatValidator]],
        cmd_156:[''],
        cmd_157:[''],
        add_1_1010:[null],
        add_1_999:[''],
        add_1_1003:[null,[this.dateFormatValidator]],
        add_1_1004:[null,[this.dateFormatValidator]],
        cmd_0156:[''],
        cmd_0157:[''],
        cmd_NA_05:[null],
        503:[''],
        512: [null],
        511: [null, [this.dateFormatValidator]],
        504: [''],
        505: [''],
        506: [''],
        508: [null],
        509: [''],
        1288:[''],
        1298:[null],
        1297:[null,[this.dateFormatValidator]],
        1289: [''],
        1290: [''],
        1291: [''],
        1293: [null],
        1294: [''],
        cmd_NA_06:[null],
        479:[''],
        483:[''],
        484:[''],
        485:[''],
        487:[null],
        488:[''],
        481:[null, [this.dateFormatValidator]],
        482:[null, [this.dateFormatValidator]],
        489:[''],
        4074:[''],
        480:[null],
        490:['', [Validators.required]],
        497:[null, [Validators.required ,this.dateFormatValidator]],
        499:[null, [Validators.required ,this.dateFormatValidator]],
        500:[null, [Validators.required]],
        491:['', [Validators.required]],
        492:[''],
        493:['', [Validators.required]],
        495:[null, [Validators.required]],
        496:['', [Validators.required]],
        add_1_1288:[''],
        add_1_1295:[null,[this.dateFormatValidator]],
        add_1_1297:[null,[this.dateFormatValidator]],
        add_1_1298:[null],
        add_1_1289:[''],
        add_1_1290:[''],
        add_1_1291:[''],
        add_1_1293:[null],
        add_1_1294:[''],
        cmd_NA_07:[null],
        517:[''],
        1318:['',[Validators.pattern(this.phonePattern)]],
        1319:['',[Validators.pattern(this.phonePattern)]],
        528:[''],
        518:[''],
        519:[''],
        520:[''],
        522:[null],
        523:[''],
        2539:[''],
        524:[null, [this.dateFormatValidator]],
        526:[null, [this.dateFormatValidator]],
        527:[null],
        cmd_NA_08:[null],
        cmd_164:[''],
        cmd_163:[''],
        cmd_0163:[''],
        529:[''],
        1316:['',[Validators.pattern(this.phonePattern)]],
        1317:['',[ Validators.pattern(this.phonePattern)]],
        540:[''],
        531:[''],
        1498:[''],
        533:[''],
        535:[null],
        536:[''],
        2540:[''],
        530:[null],
        537:[null, [this.dateFormatValidator]],
        538:[null, [this.dateFormatValidator]],
        1170:[''],
        1320:['',[this.phoneNumberFormatValidator] ],
        1321:['',[this.phoneNumberFormatValidator]],
        1181:[''],
        1172:[''],
        1173:[''],
        1174:[''],
        1176:[null],
        1177:[''],
        2541:[''],
        1171:[null],
        1178:[null,[this.dateFormatValidator]],
        1179:[null,[this.dateFormatValidator]],
        1180:[null],
        cmd_165:[''],
        cmd_NA_09:[null],
        542:[''],
        1322:['', [Validators.pattern(this.phonePattern)]],
        1323:['',[Validators.pattern(this.phonePattern)]],
        553:[''],
        544:[''],
        545:[''],
        546:[''],
        548:[null],
        549:[''],
        2542:[''],
        550:[null, [this.dateFormatValidator]],
        552:[null, [this.dateFormatValidator]],
        966:[''],
        1324:['',[this.phoneNumberFormatValidator]],
        1325:['',[this.phoneNumberFormatValidator]],
        983:[''],
        968:[''],
        969:[''],
        970:[''],
        972:[null],
        1301:[''],
        2543:[''],
        974:[null,[this.dateFormatValidator]],
        975:[null,[this.dateFormatValidator]],
        cmd_167:[''],
        cmd_168:[''],
        cmd_NA_10:[null],
        cmd_1501806:[''],
        cmd_1511806:[''],
        cmd_1521806:[''],
        cmd_1531806:[''],
        cmd_1541806:[null],
        cmd_1551806:[''],
        cmd_1561806:['', [Validators.pattern(this.phonePattern)]],
        cmd_1571806:['',[Validators.pattern(this.phonePattern)] ],
        cmd_1581806:['',[Validators.pattern(this.emailPattern)]],
        cmd_1591806:[null, [this.dateFormatValidator]],
        cmd_1601806:[null, [this.dateFormatValidator]],
        cmd_1611806:[''],
        cmd_1621806:[''],
        cmd_NA_11:[null],
        // FACULTY APPOINTMENT
        cmd_170:[''],
        1146:[''],
        1148:[''],
        1149:[''],
        4142:['', [Validators.pattern(this.phonePattern)]],
        1150:[''],
        1152:[null],
        1153:[''],
        cmd_171:['',[Validators.pattern(this.phonePattern)]],
        cmd_0171:['',[Validators.pattern(this.emailPattern)]],
        1382:[''],
        1155:[null, [this.dateFormatValidator]],
        1156:[null, [this.dateFormatValidator]],
        1154:[''],
        cmd_NA_12:[null],
        cmd_008:[''],
        554:[null],
        cmd_175:[null],
        556:[null],
        557:[null, [this.dateFormatValidator]],
        558:[null, [this.dateFormatValidator]],
        560:[null, [this.dateFormatValidator]],
        561:[null],
        cmd_176:[null],
        563:[null],
        564:[null, [this.dateFormatValidator]],
        565:[null, [this.dateFormatValidator]],
        567:[null, [this.dateFormatValidator]],
        946:[null],
        cmd_177:[null],
        948:[null],
        953:[null, [this.dateFormatValidator]],
        950:[null, [this.dateFormatValidator]],
        954:[null, [this.dateFormatValidator]],
        cmd_0081:[''],
        cmd_0008:[''],
        cmd_00081:[''],
        cmd_00082:[''],

        674:[null],
        3270:[''],
        678:[null,[this.dateFormatValidator]],
        add_1_674:[null],
        add_1_3270:[''],
        add_1_678:[null,[this.dateFormatValidator]],
        cmd_NA_13:[null],
        418:[''],
        425:[''],
        419:[''],
        420:[''],
        421:[''],
        423:[null],
        424:[''],
        1305:['',[Validators.pattern(this.phonePattern)]],
        1309:['',[this.phoneNumberFormatValidator]],
        2136:['',[this.phoneNumberFormatValidator]], 
        2138:['',[this.phoneNumberFormatValidator]], 
        894:[null],
        426:[null,[this.dateFormatValidator]],
        2142:['',[this.emailFormatValidator]],
        cmd_188:[''],
        cmd_NA_14:[null],
        cmd_NA_15:[null],
        427:[''],
        434:[''],
        428:[''],
        429:[''],
        430:[''],
        432:[null],
        433:[''],
        1307:['',[this.phoneNumberFormatValidator] ],
        2137:['',[this.phoneNumberFormatValidator]],
        895:[null],
        435:[null,[this.dateFormatValidator]],
        2143:['',[this.emailFormatValidator]],
        cmd_0188:[''],
        cmd_0189:[''],
        cmd_0190:[''],
        cmd_NA_16:[null],
        436:[''],
        443:[''],
        437:[''],
        438:[''],
        439:[''],
        441:[null],
        442:[''],
        896:[null],
        444:[null,[this.dateFormatValidator]],
        2144:['',[this.emailFormatValidator]],
        cmd_NA_016:[null],
        cmd_00188:[''],
        cmd_00189:[''],
        cmd_00190:[''],
        cmd_NA_17:[null],
        //  // PREVIOUS HOSPITAL AFFILIATIONS
        cmd_NA_5:[null],
        1183:[''],
        1190:[''],
        1312:[''],
        1184:[''],
        1185:[''],
        1186:[''],
        1313:[''],
        2140:[''],
        1188:[null],
        1189:[''],
        1192:[null],
        1191:[null,[this.dateFormatValidator]],
        2381:[null,[this.dateFormatValidator]],
        1303:[''],
        2146:['',[this.emailFormatValidator]],
        add_1_2146:['',[this.emailFormatValidator]],
        add_2_2146:['',[this.emailFormatValidator]],
        "add_1_1183":[''],
        "add_1_1190":[''],
        "add_1_1312":[''],
        "add_1_1184":[''],
        "add_1_1185":[''],
        "add_1_1186":[''],
        "add_1_1188":[null],
        "add_1_1189":[''],
        "add_1_1313":[''],
        "add_1_2140":[''],
        "add_1_1192":[null],
        "add_1_1303":[''],
        "add_1_1191":[null,[this.dateFormatValidator]],
        "add_1_2381":[null,[this.dateFormatValidator]],
        "add_2_1183":[''],
        "add_2_1190":[''],
        "add_2_1184":[''],
        "add_2_1185":[''],
        "add_2_1186":[''],
        "add_2_1312":[''],
        "add_2_2140":[''],
        "add_2_1313":[''],
        "add_2_1188":[null],
        "add_2_1189":[''],
        "add_2_1192":[null],
        "add_2_1191":[null,[this.dateFormatValidator]],
        "add_2_2381":[null,[this.dateFormatValidator]],
        "add_2_1303":[''],
         //CURRENT MILITARY AFFILIATIONS
         cmd_NA_6:[null],
          1139:[''],
          1141:[''],
          1145:[null],
          2112:[''],
          2113:[''],
          2114:[''],
          2116:[null],
          2117:[''],
          4152:[null],
          1144:[null, [this.dateFormatValidator]],
          cmd_232:['',[Validators.pattern(this.phonePattern)]],
          cmd_233:['',[this.phoneNumberFormatValidator] ],
          cmd_NA_7:[null],
          "cmd_221":[''],
          "cmd_222":[''],
          "cmd_223":[''],
          "cmd_224":[''],
          "cmd_225":[''],
          "cmd_226":[null],
          "cmd_227":[''],
          "cmd_228":['',[Validators.pattern(this.phonePattern)]],
          "cmd_229":['',[this.phoneNumberFormatValidator]],
          "cmd_230":[null],
          "cmd_231":[null, [this.dateFormatValidator]],

          "cmd_234":[''],
          "cmd_235":['',[this.phoneNumberFormatValidator]],
          "cmd_236":[null,[this.dateFormatValidator]],
          "cmd_237":[''],
          "cmd_238":[''],
          "cmd_239":[''],
          "cmd_240":[null],
          "cmd_241":[''],
          "cmd_242":[''],
          "cmd_243":['',[this.phoneNumberFormatValidator]],
          "cmd_244":[null,[this.dateFormatValidator]],
          "cmd_245":[''],
          "cmd_246":[''],
          "cmd_247":[''],
          "cmd_248":[null],
          "cmd_249":[''],
          //WORK HISTORY
          cmd_0451:[''],
          cmd_0452:[''],
          cmd_0453:[''],
          cmd_NA_9:[null],
          445:[''],
          452:[''],
          453:['',[Validators.pattern(this.phonePattern)]],
          1329:[''],
          889:['',[this.emailFormatValidator]],
          888:['',[this.phoneNumberFormatValidator]],
          446:[''],
          447:[''],
          448:[''],
          450:[null],
          451:[''],
          454:[null, [this.dateFormatValidator]],
          455:[null, [this.dateFormatValidator]],
  
          456:[''],
          463:[''],
          464:['',[Validators.pattern(this.phonePattern)]],
          1338:[''],
          891:['',[this.emailFormatValidator]],
          890:['',[this.phoneNumberFormatValidator]],
          457:[''],
          458:[''],
          459:[''],
          461:[null],
          462:[''],
          465:[null, [this.dateFormatValidator]],
          466:[null, [this.dateFormatValidator]],

          467:[''],
          474:[''],
          475:['',[Validators.pattern(this.phonePattern)]],
          1342:[''],
          893:['',[this.emailFormatValidator]],
          892:['',[this.phoneNumberFormatValidator]],
          468:[''],
          469:[''],
          470:[''],
          472:[null],
          473:[''],
          476:[null, [this.dateFormatValidator]],
          477:[null, [this.dateFormatValidator]],
          //GAPS IN HISTORY
          cmd_NA_18:[null],
          1138:[''],
          3395:[null, [this.dateFormatValidator]],
          3396:[null, [this.dateFormatValidator]],
          1831:[''],
          3397:[null, [this.dateFormatValidator]],
          3398:[null, [this.dateFormatValidator]],
          1832:[''],
          3399:[null, [this.dateFormatValidator]],
          3400:[null, [this.dateFormatValidator]],

          // PEER REFERENCES
          cmd_NA_19:[null],
          359:[''],
          2722:[''],
          368:[null],
          380:[null],
          393:[null],
          371:['', [Validators.pattern(this.emailPattern)]],
          360:[''],
          361:[''],
          362:[''],
          364:[null],
          365:[''],
          369:['', [Validators.pattern(this.phonePattern)]],
          370:['',[Validators.pattern(this.phonePattern)]],
          cmd_252:['',[Validators.pattern(this.phonePattern)]],
          366:[null, [this.dateFormatValidator]],
          367:[null, [this.dateFormatValidator]],
          372:[''],
          2723:[null],
          383:[null, [ Validators.pattern(this.emailPattern)]],
          373:[''],
          374:[''],
          375:[''],
          377:[null],
          378:[''],
          381:['', [Validators.pattern(this.phonePattern)]],
          382:['', [Validators.pattern(this.phonePattern)]],
          cmd_253:['',[Validators.pattern(this.phonePattern)]],
          817:[null, [this.dateFormatValidator]],
          379:[null, [this.dateFormatValidator]],
          384:[''],
          2725:[null],
          396:['', [ Validators.pattern(this.emailPattern)]],
          385:[''],
          387:[''],

          386:[''],
          389:[null],
          390:[''],
          394:['', [Validators.pattern(this.phonePattern)]],
          395:['' ,[Validators.pattern(this.phonePattern)]],
          cmd_254:['' ,[Validators.pattern(this.phonePattern)]],
          391:[null, [this.dateFormatValidator]],
          392:[null, [this.dateFormatValidator]],
           //PROFESSIONAL AFFILIATIONS

          1695:[''],
          1696:[null,[this.dateFormatValidator]],
          1701:[''],
          1702:[null,[this.dateFormatValidator]],
          cmd_255:[''],
           // PROFESSIONAL LIABILITY
          cmd_NA_21:[null],
          345:[''],
          346:[''],
          901:[''],
          902:[''],
          903:[''],
          905:[null],
          906:[''],
          1266:['',[Validators.pattern(this.phonePattern)]],
          1273:['',[this.phoneNumberFormatValidator] ],
          1639:['',[this.emailFormatValidator]],
          350:[null],
          351:[null],
          348:[null, [this.dateFormatValidator]],
          349:[null, [this.dateFormatValidator]],
          //PREVIOUS PROFESSIONAL LIABILITY
          cmd_NA_22:[null],
          1242:[''],
          1256:[''],
          1244:[''],
          1245:[''],

          1246:[''],
          1248:[null],
          1249:[''],
          1254:['', [Validators.pattern(this.phonePattern)]],
          1255:['',[this.phoneNumberFormatValidator] ],
          1857:['',[this.emailFormatValidator]],
          1264:[null],
          1265:[null],
          1252:[null, [this.dateFormatValidator]],
          1253:[null, [this.dateFormatValidator]],
          add_1_1857:['',[this.emailFormatValidator]],
          add_2_1857:['',[this.emailFormatValidator]],
          add_3_1857:['',[this.emailFormatValidator]],
          add_4_1857:['',[this.emailFormatValidator]],
          add_5_1857:['',[this.emailFormatValidator]],
          add_6_1857:['',[this.emailFormatValidator]],
          "add_1_1242":[''],
          "add_1_1256":[''],
          "add_1_1244":[''],
          "add_1_1245":[''],

          "add_1_1246":[''],
          "add_1_1248":[null],
          "add_1_1249":[''],
          "add_1_1254":['',[this.phoneNumberFormatValidator] ],
          "add_1_1255":['',[this.phoneNumberFormatValidator]],
          "add_1_1264":[null],
          "add_1_1265":[null],
          add_1_1252:[null,[this.dateFormatValidator]],
          "add_1_1253":[null,[this.dateFormatValidator]],
          "add_2_1242":[''],
          "add_2_1256":[''],
          "add_2_1244":[''],
          "add_2_1245":[''],


          "add_2_1246":[''],
          "add_2_1248":[null],
          "add_2_1249":[''],
          "add_2_1254":['',[this.phoneNumberFormatValidator] ],
          "add_2_1255":['',[this.phoneNumberFormatValidator]],
          "add_2_1264":[null],
          "add_2_1265":[null],
          "add_2_1252":[null,[this.dateFormatValidator]],
          "add_2_1253":[null,[this.dateFormatValidator]],
          "add_3_1242":[''],
          "add_3_1256":[''],
          "add_3_1244":[''],
          "add_3_1245":[''],

          "add_3_1246":[''],
          "add_3_1248":[null],

          "add_3_1249":[''],
          "add_3_1254":['',[this.phoneNumberFormatValidator]],
          "add_3_1255":['',[this.phoneNumberFormatValidator]],
          "add_3_1264":[null],
          "add_3_1265":[null],
          "add_3_1252":[null,[this.dateFormatValidator]],
          "add_3_1253":[null,[this.dateFormatValidator]],

          "add_4_1242":[''],
          "add_4_1256":[''],
          "add_4_1244":[''],
          "add_4_1245":[''],

          "add_4_1246":[''],
          "add_4_1248":[null],
          "add_4_1249":[''],
          "add_4_1254":['',[this.phoneNumberFormatValidator] ],

          "add_4_1255":['',[this.phoneNumberFormatValidator]],
          "add_4_1264":[null],
          "add_4_1265":[null],
          "add_4_1252":[null,[this.dateFormatValidator]],
          "add_4_1253":[null,[this.dateFormatValidator]],

          "add_5_1242":[''],
          "add_5_1256":[''],
          "add_5_1244":[''],
          "add_5_1245":[''],

          "add_5_1246":[''],
          "add_5_1248":[null],
          "add_5_1249":[''],
          "add_5_1254":['',[this.phoneNumberFormatValidator] ],
          "add_5_1255":['',[this.phoneNumberFormatValidator]],
          "add_5_1264":[null],

          "add_5_1265":[null],
          "add_5_1252":[null,[this.dateFormatValidator]],
          "add_5_1253":[null,[this.dateFormatValidator]],

          "add_6_1242":[''],
          "add_6_1256":[''],
          "add_6_1244":[''],
          "add_6_1245":[''],

          "add_6_1246":[''],
          "add_6_1248":[null],
          "add_6_1249":[''],
          "add_6_1254":['',[this.phoneNumberFormatValidator] ] ,
          "add_6_1255":['',[this.phoneNumberFormatValidator]],
          "add_6_1264":[null],
          "add_6_1265":[null],
          "add_6_1252":[null,[this.dateFormatValidator]],
          "add_6_1253":[null,[this.dateFormatValidator]],
          
          cmd_329:['',[Validators.required]],
          //PROFESSIONAL SANCTIONS
           "cmd_330":['',[Validators.required]],
          "cmd_331":['',[Validators.required]],
          "cmd_332":['',[Validators.required]],
          "cmd_333":['',[Validators.required]],
          "cmd_334":['',[Validators.required]],
          "cmd_335":['',[Validators.required]],
          "cmd_336":['',[Validators.required]],
          "cmd_337":['',[Validators.required]],
          "cmd_338":['',[Validators.required]],
          "cmd_339":['',[Validators.required]],
          "cmd_340":['',[Validators.required]],
          "cmd_341":['',[Validators.required]],
          "cmd_342":['',[Validators.required]],
          "cmd_343":['',[Validators.required]],
          "cmd_344":['',[Validators.required]],
          "cmd_345":['',[Validators.required]],
          "cmd_346":['',[Validators.required]],
          "cmd_347":['',[Validators.required]],
          "cmd_348":['',[Validators.required]],
          "cmd_349":['',[Validators.required]],
          "cmd_350":['',[Validators.required]],
          "cmd_351":['',[Validators.required]],
          "cmd_352":['',[Validators.required]],
          "cmd_33553":['',Validators.required],
          "cmd_43554":['',Validators.required],
          "cmd_0354":[''],
          cmd_0019:  ['', [Validators.required]],
          cmd_0020:[null,[Validators.required ,this.dateFormatValidator]],
          cmd_NA_1:[null],
          "cmd_356":['', ],
          1208:[null, [this.dateFormatValidator]],
          1209:[null, [this.dateFormatValidator]],
          1217:[''],
          1218:[''],
          1214:[''],
          1210:['', ],
          1844:['', ],
          2012:['', ],
          1845:['', ],
          1847:[null, ],
          1848:[''],
          1213:[''],
          1858:[''],
          1226:[null, [this.dateFormatValidator]],
          1228:['',],
          cmd_NA_2:[null],
          cmd_00019:  ['', [Validators.required]],
          cmd_020:[null,[Validators.required ,this.dateFormatValidator]],
          "cmd_0355":[''],
          "cmd_0356":[''],
          cmd_000190:  ['', [Validators.required]],
          cmd_0200:[null,[Validators.required ,this.dateFormatValidator]],
          cmd_NA_015:[null],
          cmd_503:[''],
          cmd_0503:[''],
          cmd_00503:[''],
          cmd_000503:['',[Validators.pattern(this.phonePattern)]],
          cmd_0000503:['',[Validators.pattern(this.emailPattern)]],

        



       

      });
      console.log(this.CancerCareNorthwestForms);

    }
    
    onChangeFirstName(event) {
      this.firstName = event.target.value;
      this.finalName = this.firstName + ' ' + this.middleName+' ' + this.lastName;
      let temp = {
        cmd_33553: this.finalName,
        cmd_43554:this.finalName,
        cmd_03550:this.finalName,
        cmd_13551:this.finalName,
        cmd_356:this.finalName,
  
        // cmd_23552:this.finalName
      };
      this.CancerCareNorthwestForms.patchValue(temp);
    }
    onChangeMiddleName(event) {
      this.middleName = event.target.value;
      this.finalName = this.firstName + ' ' + this.middleName+' ' + this.lastName;
      let temp = {
        cmd_33553: this.finalName,
        cmd_43554:this.finalName,
        cmd_03550:this.finalName,
        cmd_13551:this.finalName,
        cmd_356:this.finalName,
  
        // cmd_23552:this.finalName
      };
      this.CancerCareNorthwestForms.patchValue(temp);
    }
    onChangeLastName(event) {
      this.lastName = event.target.value;
      this.finalName = this.firstName + ' ' + this.middleName+' ' + this.lastName;
      let temp = {
        cmd_33553: this.finalName,
        cmd_43554:this.finalName,
        cmd_03550:this.finalName,
        cmd_13551:this.finalName,
        cmd_356:this.finalName,
        // cmd_23552:this.finalName
      };
      this.CancerCareNorthwestForms.patchValue(temp);
    }
    public getAlternateLicenseStateData() 
    {
      let params: {};
      //this.spinner.show();
      this.onboardService.getAlternateLicenseStateData(params).subscribe(
        data => {
          if (data['success']) {
            this.altLicenseState = data.data.map(i => ({
              ...i,
              id: i.id.toString()
            }));
          } else {
            this.notiService.showError(data.error, '', 3000);
          }
          //this.spinner.hide();
        },
        error => {
          //this.spinner.hide();
        }
      );
    }

    public getSuffixData() {
      let params: {};
      //this.spinner.show();
      this.onboardService.getSuffixData(params).subscribe(
        data => {
          if (data['success']) {
            this.suffix = data.data.map(i => ({
              ...i,
              id: i.id.toString()
            }));
          } else {
            this.notiService.showError(data.error, '', 3000);
          }
          //this.spinner.hide();
        },
        error => {
          //this.spinner.hide();
        }
      );
    }
    public getLanguagesData() {
      let params: {};
      //this.spinner.show();
      this.onboardService.getLanguagesData(params).subscribe(
        data => {
          if (data['success']) {
            this.languages = data.data.map(i => ({
              ...i,
              id: i.id.toString()
            }));
          } else {
            this.notiService.showError(data.error, '', 3000);
          }
          //this.spinner.hide();
        },
        error => {
          //this.spinner.hide();
        }
      );
    }
    public getGenderData() 
    {
      let params: {};
      //this.spinner.show();
      this.onboardService.getGenderData(params).subscribe(
        data => {
          if (data['success']) {
            this.gender = data.data.map(i => ({
              ...i,
              id: i.id.toString()
            }));
          } else {
            this.notiService.showError(data.error, '', 3000);
          }
          //this.spinner.hide();
        },
        error => {
          //this.spinner.hide();
        }
      );
    }
    public getSpecialtyData() 
    {
      let params: {};
      //this.spinner.show();
      this.onboardService.getSpecialtyData(params).subscribe(
        data => {
          if (data['success']) {
            this.specialty = data.data.map(i => ({
              ...i,
              id: i.id.toString()
            }));
          } else {
            this.notiService.showError(data.error, '', 3000);
          }
          //this.spinner.hide();
        },
        error => {
          //this.spinner.hide();
        }
      );
    }
    public getSpecialtySecondaryData() {
      let params: {};
      //this.spinner.show();
      this.onboardService.getSpecialtySecondaryData(params).subscribe(
        data => {
          if (data['success']) {
            this.specialtySecondary = data.data.map(i => ({
              ...i,
              id: i.id.toString()
            }));
          } else {
            this.notiService.showError(data.error, '', 3000);
          }
          //this.spinner.hide();
        },
        error => {
          //this.spinner.hide();
        }
      );
    }
    public getAdditionalLicenseStateData() {
      let params: {};
      //this.spinner.show();
      this.onboardService.getAdditionalLicenseStateData(params).subscribe(
        data => {
          if (data['success']) {
            this.additionalLicenseState = data.data.map(i => ({
              ...i,
              id: i.id.toString()
            }));
          } else {
            this.notiService.showError(data.error, '', 3000);
          }
          //this.spinner.hide();
        },
        error => {
          //this.spinner.hide();
        }
      );
    }
    public getStatesData() 
    {
      let params: {};
      //this.spinner.show();
      this.onboardService.getStatesData(params).subscribe(
        data => {
          if (data['success']) {
            this.states = data.data.map(i => ({
              ...i,
              id: i.id.toString()
            }));
          } else {
            this.notiService.showError(data.error, '', 3000);
          }
          //this.spinner.hide();
        },
        error => {
          //this.spinner.hide();
        }
      );
    }
    public getDegreeData()
     {
      let params: {};
      this.onboardService.getDegreeData(params).subscribe(
        data => {
          if (data['success']) {
            this.degree = data.data.map(i => ({
              ...i,
              id: i.id.toString()
            }));
          } else {
            this.notiService.showError(data.error, '', 3000);
          }
        },
        error => {
        }
      );
    }

    public  phoneNumberFormatValidator(control: FormControl): ValidationErrors | null {
      const phoneString = control.value;
      const phoneFormat = /^[0-9]*$/;
    
      if (phoneString && !phoneString.match(phoneFormat)) {
        return { 'invalidPhoneFormat': true };
      }
    
      return null;
    }
    public emailFormatValidator(control: FormControl): ValidationErrors | null {
      const emailString = control.value;
      const emailFormat = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    
      if (emailString && !emailString.match(emailFormat)) {
        return { 'invalidEmailFormat': true };
      }
    
      return null;
    }
    public getAggregatesData() {
      let params: {};
      //this.spinner.show();
      this.onboardService.getAggregatesData(params).subscribe(
        data => {
          if (data['success']) {
            this.aggregates = data.data.map(i => ({
              ...i,
              id: i.id.toString()
            }));
          } else {
            this.notiService.showError(data.error, '', 3000);
          }
          //this.spinner.hide();
        },
        error => {
          //this.spinner.hide();
        }
      );
    }
    public getRaceData() {
      let params: {};
      //this.spinner.show();
      this.onboardService.getRaceData(params).subscribe(
        data => {
          if (data['success']) {
            this.race = data.data.map(i => ({
              ...i,
              id: i.id.toString()
            }));
          } else {
            this.notiService.showError(data.error, '', 3000);
          }
          //this.spinner.hide();
        },
        error => {
          //this.spinner.hide();
        }
      );
    }
  
    public getMediCertifications() {
      let params: {};
      //this.spinner.show();
      this.onboardService.getMediCertifications(params).subscribe(
        data => {
          if (data['success']) {
            this.mediCertifications = data.data.map(i => ({
              ...i,
              id: i.id.toString()
            }));
          } else {
            this.notiService.showError(data.error, '', 3000);
          }
          //this.spinner.hide();
        },
        error => {
          //this.spinner.hide();
        }
      );
    }
    public getsecondaryLanguagesData() {
      let params: {};
      //this.spinner.show();
      this.onboardService.getsecondaryLanguagesData(params).subscribe(
        data => {
          if (data['success']) {
            this.secondaryLanguages = data.data.map(i => ({
              ...i,
              id: i.id.toString()
            }));
          } else {
            this.notiService.showError(data.error, '', 3000);
          }
          //this.spinner.hide();
        },
        error => {
          //this.spinner.hide();
        }
      );
    }
    

    public getCredentialsData() {
      let params: {};
      //this.spinner.show();
      this.onboardService.getCredentialsData(params).subscribe(
        data => {
          if (data['success']) {
            this.credentials = data.data.map(i => ({
              ...i,
              id: i.id.toString()
            }));
          } else {
            this.notiService.showError(data.error, '', 3000);
          }
          //this.spinner.hide();
        },
        error => {
          //this.spinner.hide();
        }
      );
    }
    public getBoardCertificationData() {
      let params: {};
      //this.spinner.show();
      this.onboardService.getBoardCertificationData(params).subscribe(
        data => {
          if (data['success']) {
            this.boardCertification = data.data.map(i => ({
              ...i,
              id: i.id.toString()
            }));
          } else {
            this.notiService.showError(data.error, '', 3000);
          }
          //this.spinner.hide();
        },
        error => {
          //this.spinner.hide();
        }
      );
    }
    public gettertiaryLanguagesData() {
      let params: {};
      // this.spinner.show();
      this.onboardService.gettertiaryLanguagesData(params).subscribe(
        data => {
          if (data['success']) {
            this.tertiaryLanguages = data.data.map(i => ({
              ...i,
              id: i.id.toString()
            }));
          } else {
            this.notiService.showError(data.error, '', 3000);
          }
          this.spinner.hide();
        },
        error => {
          this.spinner.hide();
        }
      );
    }
  
    public getquaternaryLanguagesData() {
      let params: {};
      // this.spinner.show();
      this.onboardService.getquaternaryLanguagesData(params).subscribe(
        data => {
          if (data['success']) {
            this.quaternaryLanguages = data.data.map(i => ({
              ...i,
              id: i.id.toString()
            }));
          } else {
            this.notiService.showError(data.error, '', 3000);
          }
          this.spinner.hide();
        },
        error => {
          this.spinner.hide();
        }
      );
    }
    public getPreClaimData() {
      let params: {};
      //this.spinner.show();
      this.onboardService.getPreClaimData(params).subscribe(
        data => {
          if (data['success']) {
            this.preClaim = data.data.map(i => ({
              ...i,
              id: i.id.toString()
            }));
          } else {
            this.notiService.showError(data.error, '', 3000);
          }
          //this.spinner.hide();
        },
        error => {
          //this.spinner.hide();
        }
      );
    }
  
    public getStaffStatusData() 
    {
      let params: {};
      //this.spinner.show();
      this.onboardService.getStaffStatusData(params).subscribe(
        data => {
          if (data['success']) {
            this.staffStatus = data.data.map(i => ({
              ...i,
              id: i.id.toString()
            }));
          } else {
            this.notiService.showError(data.error, '', 3000);
          }
          //this.spinner.hide();
        },
        error => {
          //this.spinner.hide();
        }
      );
    }
    public getTerSpecialtyData() 
    {
      let params: {};
      //this.spinner.show();
      this.onboardService.getTerSpecialtyData(params).subscribe(
        data => {
          if (data['success']) {
            this.specialtyTer = data.data.map(i => ({
              ...i,
              id: i.id.toString()
            }));
          } else {
            this.notiService.showError(data.error, '', 3000);
          }
          //this.spinner.hide();
        },
        error => {
          //this.spinner.hide();
        }
      );
    }

    setErrorControl(name){
      this.CancerCareNorthwestForms.controls[name].setErrors({ errors: true });
    }
    removeErrorControl(name){
      this.CancerCareNorthwestForms.controls[name].setErrors(null)
    }
    
    handleNAchange(cName,eName?){
      let result:boolean=false;
      if(cName=='cmd_NA_05'){
        let x=[503, 512, 511, 504, 506, 508, 509]
        x.forEach(element => {
          const ctrl = this.CancerCareNorthwestForms.controls[element];
          if ((ctrl.value === '' || ctrl.value === null) && !this.CancerCareNorthwestForms.controls[cName].value) {
            this.setErrorControl(element);
            result = true;
          }
        
          else {
            this.removeErrorControl(element);
            ctrl.setErrors(null);
          }
        });
      }  
      if(cName=='cmd_NA_06'){
        let x=[479,483, 484, 485, 487, 488,481,482,489,4074,480]
        x.forEach(element => {
          const ctrl = this.CancerCareNorthwestForms.controls[element];
          if ((ctrl.value === '' || ctrl.value === null) && !this.CancerCareNorthwestForms.controls[cName].value) {
            this.setErrorControl(element);
            result = true;
          } 
        
           else {
            this.removeErrorControl(element);
            ctrl.setErrors(null);
          }
        });
      }  
      if (cName == 'cmd_NA_07') {
        let x = [517, 1318, 1319, 518, 520, 522, 528, 523, 524, 526, 527, 2539]
        x.forEach(element => {
          const ctrl = this.CancerCareNorthwestForms.controls[element];
          if ((ctrl.value === '' || ctrl.value === null) && !this.CancerCareNorthwestForms.controls[cName].value) {
            this.setErrorControl(element);
            result = true;
          }
           
           else {
            this.removeErrorControl(element);
            ctrl.setErrors(null);
          }
        });
      }
      if (cName == 'cmd_NA_08') {
        let x = [529, 1316, 1317, 540, 531, 533, 535, 536, 2540, 530, 537, 538, "cmd_164"]
        x.forEach(element => {
          const ctrl = this.CancerCareNorthwestForms.controls[element];
          if ((ctrl.value === '' || ctrl.value === null) && !this.CancerCareNorthwestForms.controls[cName].value) {
            this.setErrorControl(element);
            result = true;
          } 
          
          else {
            this.removeErrorControl(element);
            ctrl.setErrors(null);
          }
        });
      }
      if (cName == 'cmd_NA_09') {
        let x = [542, 1322, 1323, 553, 544, 546, 548, 549, 2542, 550, 552, "cmd_167"]
        x.forEach(element => {
          const ctrl = this.CancerCareNorthwestForms.controls[element];
          if ((ctrl.value === '' || ctrl.value === null) && !this.CancerCareNorthwestForms.controls[cName].value) {
            this.setErrorControl(element);
            result = true;
          } 
         
          else {
            this.removeErrorControl(element);
            ctrl.setErrors(null);
          }
        });
      }
      if (cName == 'cmd_NA_10') {
        let x = ['cmd_1501806', 'cmd_1511806', 'cmd_1521806', 'cmd_1531806', 'cmd_1541806', 'cmd_1551806', 'cmd_1561806', 'cmd_1571806', 'cmd_1581806', 'cmd_1591806', 'cmd_1601806', 'cmd_1611806', 'cmd_1621806']
        x.forEach(element => {
          const ctrl = this.CancerCareNorthwestForms.controls[element];
          if ((ctrl.value === '' || ctrl.value === null) && !this.CancerCareNorthwestForms.controls[cName].value) {
            this.setErrorControl(element);
            result = true;
          } 
         
           else {
            this.removeErrorControl(element);
            ctrl.setErrors(null);
          }
        });
      }
      if (cName == 'cmd_NA_11') {
        let x = [1146, 1148, 1149, 1150, 1152, 4142, 1153, "cmd_171","cmd_0171", 1155, 1382, 1154, 1156]
        x.forEach(element => {
          const ctrl = this.CancerCareNorthwestForms.controls[element];
          if ((ctrl.value === '' || ctrl.value === null) && !this.CancerCareNorthwestForms.controls[cName].value) {
            this.setErrorControl(element);
            result = true;
          } 
        
          else {
            this.removeErrorControl(element);
            ctrl.setErrors(null);
          }
        });
      }
      if (cName == 'cmd_NA_12') {
        if(this.CancerCareNorthwestForms.controls[cName]){
          this.flagForHandle=true;
        }
        else{
          this.flagForHandle=false;
        }
        let x = ["cmd_008","cmd_0081","cmd_00081","cmd_00082",]
        this.handleYesNoChanges('cmd_008');
        this.handleYesNoChanges("cmd_0081");
        x.forEach(element => {
          if ((this.CancerCareNorthwestForms.controls[element].value == '' || this.CancerCareNorthwestForms.controls[element].value == null) && !this.CancerCareNorthwestForms.controls[cName].value) {
            this.setErrorControl(element);
   
            result = true
          }
          else {
            this.removeErrorControl(element);
          }
        });

        
      }
      if (cName == 'cmd_NA_13') {
        let x = [418,425,419,421,423,424,1305]
        x.forEach(element => {
          // if ((this.CancerCareNorthwestForms.controls[element].value == '' || this.CancerCareNorthwestForms.controls[element].value == null) && !this.CancerCareNorthwestForms.controls[cName].value) {
          //   this.setErrorControl(element);
          //   result = true
          // }
          // else {
          //   this.removeErrorControl(element);
          // }
          const ctrl = this.CancerCareNorthwestForms.controls[element];
          if ((ctrl.value === '' || ctrl.value === null) && !this.CancerCareNorthwestForms.controls[cName].value) {
            this.setErrorControl(element);
            result = true;
          } else if ( ((element ===1305 ) && !/^[0-9]*$/.test(ctrl.value)   )  && (!this.CancerCareNorthwestForms.controls[cName].value || (this.CancerCareNorthwestForms.controls[cName].value && ctrl.value !== '' && ctrl.value !== null))) {
            this.setErrorControl(element);
            ctrl.setErrors({ 'incorrectDateFormat': true });
            result = true;
          } else if ( (element===1305) && this.CancerCareNorthwestForms.controls[cName].value) {
            this.removeErrorControl(element);
            ctrl.setErrors(null);
          } else {
            this.removeErrorControl(element);
            ctrl.setErrors(null);
          }
        });
      }
      if (cName == 'cmd_NA_1') {
        let profconfidential = ['cmd_356', 1217, 1208, 1209, 1214, 1218, 1210, 2012, 1845, 1847, 1848, 1213, 1858, 1226, 1228]
        profconfidential.forEach(element => {
          const ctrl = this.CancerCareNorthwestForms.controls[element];
          if ((ctrl.value === '' || ctrl.value === null) && !this.CancerCareNorthwestForms.controls[cName].value) {
            this.setErrorControl(element);
            result = true;
          }
           
          else {
            this.removeErrorControl(element);
            ctrl.setErrors(null);
          }
        });
      }
      if (cName == 'cmd_NA_015') {
        let profconfidential = ['cmd_503','cmd_0503','cmd_00503','cmd_000503','cmd_0000503']
        profconfidential.forEach(element => {
          // if ((this.CancerCareNorthwestForms.controls[element].value == '' || this.CancerCareNorthwestForms.controls[element].value == null) && !this.CancerCareNorthwestForms.controls[cName].value) {
          //   this.setErrorControl(element);
          //   result = true
          // }
          // else {
          //   this.removeErrorControl(element);
          // }
          const ctrl = this.CancerCareNorthwestForms.controls[element];
          if ((ctrl.value === '' || ctrl.value === null) && !this.CancerCareNorthwestForms.controls[cName].value) {
            this.setErrorControl(element);
            result = true;
          } else if (((element =="cmd_000503") && !/^[0-9]*$/.test(ctrl.value) || ((element==="cmd_0000503") && !this.emailPattern.test(ctrl.value) ) ) && (!this.CancerCareNorthwestForms.controls[cName].value || (this.CancerCareNorthwestForms.controls[cName].value && ctrl.value !== '' && ctrl.value !== null))) {
            this.setErrorControl(element);
            ctrl.setErrors({ 'incorrectDateFormat': true });
            result = true;
          } else if (((element =="cmd_000503") || (element==="cmd_0000503"))&& this.CancerCareNorthwestForms.controls[cName].value) {
            this.removeErrorControl(element);
            ctrl.setErrors(null);
          } else {
            this.removeErrorControl(element);
            ctrl.setErrors(null);
          }
        });
      }

      if (cName == 'cmd_NA_3') {
        let x = [907,1010,'add_1_1010']
        x.forEach(element => {
          if ((this.CancerCareNorthwestForms.controls[element].value == '' || this.CancerCareNorthwestForms.controls[element].value == null) && !this.CancerCareNorthwestForms.controls[cName].value) {
            this.setErrorControl(element);
            result = true
          }
          else {
            this.removeErrorControl(element);
          }
        });
      }
      
      if (cName == 'cmd_NA_5') {
        let x = [1183,1190,1184,1186,1188,1189]
        x.forEach(element => {
          if ((this.CancerCareNorthwestForms.controls[element].value == '' || this.CancerCareNorthwestForms.controls[element].value == null) && !this.CancerCareNorthwestForms.controls[cName].value) {
            this.setErrorControl(element);
            result = true
          }
          else {
            this.removeErrorControl(element);
          }
        });
      }
      if (cName == 'cmd_NA_6') {
        let x = [1139,1141,2112,2114,2116,2117,'cmd_232',4152,1144]
        x.forEach(element => {
          const ctrl = this.CancerCareNorthwestForms.controls[element];
          if ((ctrl.value === '' || ctrl.value === null) && !this.CancerCareNorthwestForms.controls[cName].value) {
            this.setErrorControl(element);
            result = true;
          } 
         
          else {
            this.removeErrorControl(element);
            ctrl.setErrors(null);
          }
        });
      }
      if (cName == 'cmd_NA_7') {
        let x = ['cmd_221','cmd_222','cmd_223','cmd_225','cmd_226','cmd_227','cmd_228','cmd_230','cmd_231']
        x.forEach(element => {
          const ctrl = this.CancerCareNorthwestForms.controls[element];
          if ((ctrl.value === '' || ctrl.value === null) && !this.CancerCareNorthwestForms.controls[cName].value) {
            this.setErrorControl(element);
            result = true;
          } 
         
          else {
            this.removeErrorControl(element);
            ctrl.setErrors(null);
          }
        });
      }

      if (cName == 'cmd_NA_9') {
        let workHis = [445,452,453, 1329,  446, 448, 450, 451, 454, 455,456,463,464,1338,457,459,461,,462,465,466,467,474,475,1342,468,470,472,473,476,477]
        workHis.forEach(element => {
          const ctrl = this.CancerCareNorthwestForms.controls[element];
          if ((ctrl.value === '' || ctrl.value === null) && !this.CancerCareNorthwestForms.controls[cName].value) {
            this.setErrorControl(element);
            result = true;
          }
        
           else {
            this.removeErrorControl(element);
            ctrl.setErrors(null);
          }
        });
      }
      if (cName == 'cmd_NA_18') {
        let workHis = [1138,3395,3396,1831,3397,3398,1832,3399,3400]
        workHis.forEach(element => {
          const ctrl = this.CancerCareNorthwestForms.controls[element];
          if ((ctrl.value === '' || ctrl.value === null) && !this.CancerCareNorthwestForms.controls[cName].value) {
            this.setErrorControl(element);
            result = true;
          }
         
          else {
            this.removeErrorControl(element);
            ctrl.setErrors(null);
          }
        });
      }
      if (cName == 'cmd_NA_19') {
        let x = [359,2722,368,371,360,362,364,365,369,370,366,367,372,2723,380,383,373,375,377,378,381,382,817,379,384,2725,393,396,385,387,389,390,394,395,391,392,'cmd_252','cmd_253','cmd_254']
        x.forEach(element => {
          const ctrl = this.CancerCareNorthwestForms.controls[element];
          if ((ctrl.value === '' || ctrl.value === null) && !this.CancerCareNorthwestForms.controls[cName].value) {
            this.setErrorControl(element);
            result = true;
          } 
        
           else {
            this.removeErrorControl(element);
            ctrl.setErrors(null);
          }
        });
      }
      
      if (cName == 'cmd_NA_21') {
        let workHis = [345,346,901,903,905,906,1266,350,351,348,349]
        workHis.forEach(element => {
          const ctrl = this.CancerCareNorthwestForms.controls[element];
          if ((ctrl.value === '' || ctrl.value === null) && !this.CancerCareNorthwestForms.controls[cName].value) {
            this.setErrorControl(element);
            result = true;
          }
          
           else {
            this.removeErrorControl(element);
            ctrl.setErrors(null);
          }
        });
      }
      if (cName == 'cmd_NA_22') {
        let workHis = [1242,1256,1244,1246,1248,1249,1254,1264,1265,1252,1253]
        workHis.forEach(element => {
          const ctrl = this.CancerCareNorthwestForms.controls[element];
          if ((ctrl.value === '' || ctrl.value === null) && !this.CancerCareNorthwestForms.controls[cName].value) {
            this.setErrorControl(element);
            result = true;
          } 
         
          else {
            this.removeErrorControl(element);
            ctrl.setErrors(null);
          }
        });
      }
      // if (cName == 'cmd_NA_23') {
      //   let workHis = [309,311,514,515]
      //   workHis.forEach(element => {
      //     if ((this.CancerCareNorthwestForms.controls[element].value == '' || this.CancerCareNorthwestForms.controls[element].value == null) && !this.CancerCareNorthwestForms.controls[cName].value) {
      //       this.setErrorControl(element);
      //       result = true;
      //     } 
      //     else if ((element === 311 || element === 515) && !/^\d{2}\/\d{2}\/\d{4}$/.test(this.CancerCareNorthwestForms.controls[element].value) && !this.CancerCareNorthwestForms.controls[cName].value) {
      //       this.setErrorControl(element);
      //       this.CancerCareNorthwestForms.controls[element].setErrors({'incorrectDateFormat': true});
      //       result = true;
      //     } else {
      //       this.removeErrorControl(element);
      //     }
          
      //   });
      // }
      if (cName === 'cmd_NA_23') {
        const workHis = [309, 311, 514, 515];
        workHis.forEach(element => {
          const ctrl = this.CancerCareNorthwestForms.controls[element];
          if ((ctrl.value === '' || ctrl.value === null) && !this.CancerCareNorthwestForms.controls[cName].value) {
            this.setErrorControl(element);
            result = true;
          }
          
         
           else {
            this.removeErrorControl(element);
            ctrl.setErrors(null);
          }
        });
      }
    
    }  
    // handleCheckboxChanges(cName){
    //   if(cName == 'cmd_188' || cName == 'cmd_189' || cName == '190'){
    //     if(this.CancerCareNorthwestForms.controls['cmd_188'].value || this.CancerCareNorthwestForms.controls['cmd_189'].value || this.CancerCareNorthwestForms.controls['cmd_190'].value){
    //       this.activeCheck = 0;
    //       console.log( this.activeCheck)
    //     }else{
    //       this.activeCheck = 1;
    //     }
    //   }
    // }
  
    handleYesNoChanges(cName,eName?){ 
      let result:boolean=false;
     
      
      if (cName == "cmd_008") {
        if(this.CancerCareNorthwestForms.controls["cmd_NA_12"].value ==true){
          this.CancerCareNorthwestForms.controls[554].setErrors(null);
          this.CancerCareNorthwestForms.controls["cmd_175"].setErrors(null);
          this.CancerCareNorthwestForms.controls[556].setErrors(null);
          this.CancerCareNorthwestForms.controls[557].setErrors(null);
          this.CancerCareNorthwestForms.controls[558].setErrors(null);
          this.CancerCareNorthwestForms.controls[560].setErrors(null);

          this.CancerCareNorthwestForms.controls[561].setErrors(null);
          this.CancerCareNorthwestForms.controls["cmd_176"].setErrors(null);
          this.CancerCareNorthwestForms.controls[563].setErrors(null);
          this.CancerCareNorthwestForms.controls[564].setErrors(null);
          this.CancerCareNorthwestForms.controls[565].setErrors(null);
          this.CancerCareNorthwestForms.controls[567].setErrors(null);

          this.CancerCareNorthwestForms.controls[946].setErrors(null);
          this.CancerCareNorthwestForms.controls["cmd_177"].setErrors(null);
          this.CancerCareNorthwestForms.controls[948].setErrors(null);
          this.CancerCareNorthwestForms.controls[953].setErrors(null);
          this.CancerCareNorthwestForms.controls[950].setErrors(null);
          this.CancerCareNorthwestForms.controls[954].setErrors(null);

          


          return 

        }
        
        const x=['554','cmd_175','556','557','558','560','561','cmd_176','563','564','565','567','946','cmd_177','948','953','950','954']
        x.forEach(element => {
          
          const ctrl = this.CancerCareNorthwestForms.controls[element];
          if ((ctrl.value === '' || ctrl.value === null) && this.CancerCareNorthwestForms.controls["cmd_008"].value==1 ) {
            this.setErrorControl(element);
            
            result = true;
          } 
         
          else {
            this.removeErrorControl(element);
            ctrl.setErrors(null);
          }
          // if((this.CancerCareNorthwestForms.controls[element].value=='' || this.CancerCareNorthwestForms.controls[element].value==null) && (this.CancerCareNorthwestForms.controls[cName].value==1 || this.CancerCareNorthwestForms.controls[cName].value==null)){
          //   this.setErrorControl(element);
          // }
          // else{
          //   this.removeErrorControl(element);
          // }
          
        });
      }
      if (cName == "cmd_0081") {
        if(this.CancerCareNorthwestForms.controls["cmd_NA_12"].value ==true){
          this.CancerCareNorthwestForms.controls["cmd_0008"].setErrors(null);
          return 
        }

        let x=['cmd_0008']
        x.forEach(element => {
          if((this.CancerCareNorthwestForms.controls[element].value=='' || this.CancerCareNorthwestForms.controls[element].value==null) && (this.CancerCareNorthwestForms.controls["cmd_0081"].value==1 )){
            this.setErrorControl(element);
          }
          else{
            this.removeErrorControl(element);
          }
        });
      }
      if (cName == "cmd_0117") {
        let x=['cmd_59']
        x.forEach(element => {
          if((this.CancerCareNorthwestForms.controls[element].value=='' || this.CancerCareNorthwestForms.controls[element].value==null) && (this.CancerCareNorthwestForms.controls[cName].value==1 || this.CancerCareNorthwestForms.controls[cName].value==null)){
            this.setErrorControl(element);
          }
          else{
            this.removeErrorControl(element);
          }
        });
      }
      if (cName == "cmd_117") {
        let x=['cmd_059']
        x.forEach(element => {
          if((this.CancerCareNorthwestForms.controls[element].value=='' || this.CancerCareNorthwestForms.controls[element].value==null) && (this.CancerCareNorthwestForms.controls[cName].value==1 || this.CancerCareNorthwestForms.controls[cName].value==null)){
            this.setErrorControl(element);
          }
          else{
            this.removeErrorControl(element);
          }
        });
      }
      if (cName == "cmd_56") {
        let x=['cmd_58']
        x.forEach(element => {
          if((this.CancerCareNorthwestForms.controls[element].value=='' || this.CancerCareNorthwestForms.controls[element].value==null) && (this.CancerCareNorthwestForms.controls[cName].value==0 || this.CancerCareNorthwestForms.controls[cName].value==null)){
            this.setErrorControl(element);
          }
          else{
            this.removeErrorControl(element);
          }
        });
      }
      if (cName == "cmd_1241806") {
        let x=['cmd_1251806']
        x.forEach(element => {
          if((this.CancerCareNorthwestForms.controls[element].value=='' || this.CancerCareNorthwestForms.controls[element].value==null) && (this.CancerCareNorthwestForms.controls[cName].value==1 || this.CancerCareNorthwestForms.controls[cName].value==null)){
            this.setErrorControl(element);
          }
          else{
            this.removeErrorControl(element);
          }
        });
      }
      if (cName == "cmd_1261806") {
        let x=['cmd_1271806']
        x.forEach(element => {
          if((this.CancerCareNorthwestForms.controls[element].value=='' || this.CancerCareNorthwestForms.controls[element].value==null) && (this.CancerCareNorthwestForms.controls[cName].value==1 || this.CancerCareNorthwestForms.controls[cName].value==null)){
            this.setErrorControl(element);
          }
          else{
            this.removeErrorControl(element);
          }
        });
      }
      if (cName == "cmd_1211806") {
        let x=['cmd_1221806']
        x.forEach(element => {
          if((this.CancerCareNorthwestForms.controls[element].value=='' || this.CancerCareNorthwestForms.controls[element].value==null) && (this.CancerCareNorthwestForms.controls[cName].value==0 || this.CancerCareNorthwestForms.controls[cName].value==null)){
            this.setErrorControl(element);
          }
          else{
            this.removeErrorControl(element);
          }
        });
      }
     
    }  


    public setEsign(filePath, fieldId) {
      // console.log(filePath);
      if (fieldId == 'cmd_0019') {
        this.esignOne = this._sanitizer.bypassSecurityTrustResourceUrl(
          'data:image/jpg;base64,' + filePath
        );
        this.showOne = true;
      }
      if (fieldId == 'cmd_00019') {
        this.esignTwo = this._sanitizer.bypassSecurityTrustResourceUrl(
          'data:image/jpg;base64,' + filePath
        );
        this.showTwo = true;
      }
      if (fieldId == 'cmd_000190') {
        this.esignThree = this._sanitizer.bypassSecurityTrustResourceUrl(
          'data:image/jpg;base64,' + filePath
        );
        this.showThree = true;
      }
    }

  public previewEsign(filePath, fieldId) {
      //this.spinner.show();
      let params = {
        filePath: filePath
      };
      this.onboardService.getPreviewEsign(params).subscribe(
        (data: any) => {
          if (data['success']) {
            this.setEsign(data.base64, fieldId);
          } else {
            //this.notiService.showError(data.error, '', 3000)
          }
          //this.spinner.hide();
        },
        error => {
          //this.spinner.hide();
        }
      );
  }
    public esignOpenOne() {
      const modalRef = this.modalService.open(EsignComponent, this.modalOption);
      modalRef.result.then(result => { });
      modalRef.componentInstance.base64Img.subscribe(data => {
        modalRef.close();
        this.finalImgPath = data.finalFilePath;
        let temp = {
          cmd_0019: this.finalImgPath
        };
        this.CancerCareNorthwestForms.controls['cmd_0019'].setValue(this.finalImgPath);
        this.finalImg = data.base64Img;
        this.esignOne = this._sanitizer.bypassSecurityTrustResourceUrl(
          'data:image/jpg;base64,' + this.finalImg
        );
        this.showOne = true;
      });
    }
    public esignOpenTwo() {
      const modalRef = this.modalService.open(EsignComponent, this.modalOption);
      modalRef.result.then(result => { });
      modalRef.componentInstance.base64Img.subscribe(data => {
        modalRef.close();
        this.finalImgPath = data.finalFilePath;
        let temp = {
          cmd_00019: this.finalImgPath
        };
        this.CancerCareNorthwestForms.controls['cmd_00019'].setValue(this.finalImgPath);
        this.finalImg = data.base64Img;
        this.esignTwo = this._sanitizer.bypassSecurityTrustResourceUrl(
          'data:image/jpg;base64,' + this.finalImg
        );
        this.showTwo = true;
      });
    }
    public esignOpenThree() {
      const modalRef = this.modalService.open(EsignComponent, this.modalOption);
      modalRef.result.then(result => { });
      modalRef.componentInstance.base64Img.subscribe(data => {
        modalRef.close();
        this.finalImgPath = data.finalFilePath;
        let temp = {
          cmd_000190: this.finalImgPath
        };
        this.CancerCareNorthwestForms.controls['cmd_000190'].setValue(this.finalImgPath);
        this.finalImg = data.base64Img;
        this.esignThree = this._sanitizer.bypassSecurityTrustResourceUrl(
          'data:image/jpg;base64,' + this.finalImg
        );
        this.showThree = true;
      });
    }


    saveForm(type) {
      this.isSubmit = true;      
      this.handleNAchange('cmd_NA_05');
      this.handleNAchange('cmd_NA_06');
      this.handleNAchange('cmd_NA_07');
      this.handleNAchange('cmd_NA_08');
      this.handleNAchange('cmd_NA_09');
      this.handleNAchange('cmd_NA_10');
      this.handleNAchange('cmd_NA_11');
      this.handleNAchange('cmd_NA_12');
      // this.handleYesNoChanges('cmd_008');
      // this.handleYesNoChanges('cmd_0081');
      this.handleNAchange('cmd_NA_13');
      this.handleNAchange('cmd_NA_14');  
      this.handleNAchange('cmd_NA_1');
      this.handleNAchange('cmd_NA_015');
      this.handleNAchange('cmd_NA_3');
      
      this.handleNAchange('cmd_NA_5');
      this.handleNAchange('cmd_NA_6');
      this.handleNAchange('cmd_NA_7');
      
      this.handleNAchange('cmd_NA_9');
      this.handleNAchange('cmd_NA_18');
      this.handleNAchange('cmd_NA_19');
      
      this.handleNAchange('cmd_NA_21');
      this.handleNAchange('cmd_NA_22');
      this.handleNAchange('cmd_NA_23');
      // this.handleCheckboxChanges('cmd_188');

      console.log(this.CancerCareNorthwestForms);
      if (type == 'submit') {

        if (this.CancerCareNorthwestForms.invalid) {
          this.notiService.showError(
            'Please Fill the all mandatory fields to continue',
            '',
            3000
          );
          for (const key in this.CancerCareNorthwestForms.controls) {
            if (this.CancerCareNorthwestForms.controls.hasOwnProperty(key)) {
              const element = this.CancerCareNorthwestForms.controls[key];
              if(element.status=="INVALID"){
              console.log(key + " " + element.status)
              }
            }
          }
        } else {
          if (this.CancerCareNorthwestForms.controls['cmd_0019'].value == null || this.CancerCareNorthwestForms.controls['cmd_0019'].value == '') {
            this.notiService.showError('Please add your Signature')
            return
          }
          if (this.CancerCareNorthwestForms.controls['cmd_00019'].value == null || this.CancerCareNorthwestForms.controls['cmd_00019'].value == '') {
            this.notiService.showError('Please add your Signature')
            return
          }
          if (this.CancerCareNorthwestForms.controls['cmd_000190'].value == null || this.CancerCareNorthwestForms.controls['cmd_000190'].value == '') {
            this.notiService.showError('Please add your Signature')
            return
          }
          
          let data = {
            formType: type,
            formValues: this.CancerCareNorthwestForms.value
          };
          console.log("Form has been submitted successfully");
          this.onSubmit.emit(data);
          console.log(this.CancerCareNorthwestForms);
  
        }
      } else {
        let data = {
          formType: type,
          formValues: this.CancerCareNorthwestForms.value,
          formId: 45
        };
        this.onSubmit.emit(data);
        this.onClose.emit('close modal');
      }
    }
    close() {
      this.onClose.emit('close modal');
    }
    clearInputIfInvalid(controlName: string,handleYesNoValue?:string) {
      const control = this.CancerCareNorthwestForms.get(controlName);
      const dateString = control.value;
    
      // Use the dateFormatValidator to check if the date is valid
      const validationErrors = this.dateFormatValidator(new FormControl(dateString));
    
      if (validationErrors) {
        // If any of the validation conditions are met, clear the input field and set errors to null
        control.setValue('');
        control.setErrors(null);
      }
      if(handleYesNoValue ){

        if(handleYesNoValue == ("cmd_008" )){
          this.handleYesNoChanges(handleYesNoValue);
        }
        else{
          this.handleNAchange(handleYesNoValue);
        }
   
      }
      
      
    }
    clearInputIfInvalidMandi(controlName: string) {
      const control = this.CancerCareNorthwestForms.get(controlName);
      const dateString = control.value;
    
      // Use the dateFormatValidator to check if the date is valid
      const validationErrors = this.dateFormatValidator(new FormControl(dateString));
    
      if (validationErrors) {
        // If any of the validation conditions are met, clear the input field and set errors to null
        control.setValue('');
  
      }}
  
    dateFormatValidator(control: FormControl): ValidationErrors | null {
      const dateString = control.value;
      const dateFormat = /^\d{2}\/\d{2}\/\d{4}$/;
    
      if (dateString && !dateString.match(dateFormat)) {
        return { 'invalidDateFormat': true };
      }
    
  
  
      if (dateString) {
        const dateParts = dateString.split('/');
        const month = parseInt(dateParts[0]);
        const day = parseInt(dateParts[1]);
        const year = parseInt(dateParts[2]);
    
        // Check if any of the date components are zero or month is not between 1 and 12
        
    
        const ListofDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
        
        
        if (month < 1 || month > 12 || day === 0 || year === 0) {
          return { 'invalidMonth': true };
        }
        if (month === 1 || month > 2) {
          if (day > ListofDays[month - 1]) {
            return { 'invalidDateRange': true };
          }
        } else if (month === 2) {
          const leapYear = (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
          if (!leapYear && day >= 29) {
            return { 'invalidDateRange': true };
          }
           else if (leapYear && day > 29) {
            return { 'invalidDateRange': true };
          }
        }
        if (year < 1900 || year > 2099) {
          return { 'invalidDateyear': true };
        }
        
       
      }
    
      return null;
    }
    
















      
      
     
     
         
  }