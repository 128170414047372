import { Injectable } from '@angular/core';
import {StorageService} from '../../../shared/services/storage/storage.service';

@Injectable({
  providedIn: 'root'
})
export class FormGuardService {

  constructor(
    private storageService:StorageService
  ) { }
   public onboardingEmailCheckToken:any;

  isEmailValid(): Boolean  {
    this.onboardingEmailCheckToken = this.storageService.getItem('onboardingEmailCheckToken')=="true"    
    // console.log(this.onboardingEmailCheckToken)
    // console.log(localStorage.getItem('onboardingEmailCheckToken'))
    // console.log(localStorage.getItem('onboardingEmailCheckToken')=="true")
    return this.onboardingEmailCheckToken;
   }
}
