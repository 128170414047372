<div id="kidsDentalStyle">
    <div class="cotainer-fluid mx-1">
        <div class="header">
            <div class="row"> 
                <div class="col-md-5">
                    <img class="imgStyletwo" style="width:55%;height:90%;" src="./assets/images/kids_dental_brands_logo.jpg" alt="Credential my Doc" >
                 </div>
                <div class="col-md-7">
                   <h2 class="checkBoxHeading"><b>New Provider Credentialing Packet</b></h2>
                </div>
             </div>
        </div><br><br><br><br>
        <div class="section">
            <form [formGroup]="kidsDental">
                <div class="row inputBoxPaddingLeft"> 
                    <div class="col-md-2">
                    <label class="formLabel">Legal Name:</label>
                </div>
                <div class="col-md-2">
                    <input class="form-control" id="8" name="8" formControlName="8" 
                        [class.is-invalid]="(kidsDental.controls['8'].touched && kidsDental.controls['8'].invalid) || (kidsDental.controls['8'].invalid && isSubmit)" type="text" placeholder= "First Name" data-id="fname">
                    <div class="formLabel col-md-11"
                        *ngIf="kidsDental.controls['8'].touched && kidsDental.controls['8'].invalid">
                        <small class="text-danger" *ngIf="kidsDental.controls['8'].errors?.required">
                            required
                        </small>
                    </div>
                </div>
                <div class="col-md-2">
                    <input class="form-control" id="9" name="9" formControlName="9" [class.is-invalid]="(kidsDental.controls['9'].touched && kidsDental.controls['9'].invalid) || (kidsDental.controls['9'].invalid && isSubmit)" type="text" placeholder= "Last Name" data-id="lname">     
                    <div class="formLabel col-md-11"
                        *ngIf="kidsDental.controls['9'].touched && kidsDental.controls['9'].invalid">
                        <small class="text-danger" *ngIf="kidsDental.controls['9'].errors?.required">
                            required
                        </small>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="form-group selectDropTwo suffix">
                        <ng-select [items]="suffix" bindLabel="name" style="width:90%;" placeholder="Select Suffix" bindValue="id" [class.is-invalid]="(kidsDental.controls['29'].touched && kidsDental.controls['29'].invalid) || (kidsDental.controls['29'].invalid && isSubmit) " formControlName="29">
                        </ng-select>
                    </div>     
                    <div class="formLabel col-md-11"
                        *ngIf="kidsDental.controls['29'].touched && kidsDental.controls['29'].invalid">
                        <small class="text-danger" *ngIf="kidsDental.controls['29'].errors?.required">
                            required
                        </small>
                    </div>                                            
                </div>
                <div class="col-md-2">
                    <label class="formLabel">Other Names Used:</label>
                </div>
                <div class="col-md-2">
                    <input class="form-control" id="278" name="278" formControlName="278" [class.is-invalid]="(kidsDental.controls['278'].touched && kidsDental.controls['278'].invalid ) || (kidsDental.controls['278'].invalid && isSubmit)" type="text" placeholder="Prior Name" data-id="pname"/>
                    <div class="formLabel col-md-11"
                        *ngIf="kidsDental.controls['278'].touched && kidsDental.controls['278'].invalid">
                        <small class="text-danger" *ngIf="kidsDental.controls['278'].errors?.required">
                            required
                        </small>
                    </div> 
                </div>
            </div>
            <div class="row inputBoxPaddingLeft">
                    <div class="col-md-2">
                        <label class="formLabel">Date of Birth:</label>
                    </div>
                    <div class="col-md-2">
                        <div class="input-group">
                            <input class="form-control onboard datepicker" id="31" name="31"  (blur)="clearInputIfInvalidMandi('31')" ngbDatepicker #d1="ngbDatepicker" formControlName="31" [class.is-invalid]="(kidsDental.controls['31'].touched && kidsDental.controls['31'].invalid ) || (kidsDental.controls['31'].invalid && isSubmit)" type="text" placeholder= "DOB" data-id="dob"/>
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary calendar" (click)="d1.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                            </div>
                        </div>    
                        <div class="mandatory" *ngIf="(kidsDental.controls['31'].invalid&&kidsDental.controls['31'].touched) && (kidsDental.controls['31'].value==''|| kidsDental.controls['31'].value==null) " >
                            <small >
                            required
                            </small>
          
                        </div>
                          <small class="text-danger" *ngIf="kidsDental.get('31').errors?.invalidDateFormat">
                            Only Date Format is allowed(MM/DD/YYYY)                </small>
                          <small class="text-danger" *ngIf="kidsDental.get('31').errors?.invalidMonth">
                          Month is out of range
                          </small>
                          <small class="text-danger" *ngIf="kidsDental.get('31').errors?.invalidDateyear">
                            year is out of range(1900-2099)                  </small>
                            <small class="text-danger" *ngIf="kidsDental.get('31').errors?.invalidDateRange">
                              date is out of range
                              </small>
                    </div>
                    <div class="form-group col-md-2">
                        <label class="formLabel inputBoxPaddingLeft">Place of Birth:</label>
                    </div>
                    <div class="col-md-2">    
                        <input class="form-control"  id="810" name="810" formControlName="810" [class.is-invalid]="(kidsDental.controls['810'].touched && kidsDental.controls['810'].invalid ) || (kidsDental.controls['810'].invalid && isSubmit)" placeholder="City" type="text">
                        <div class="formLabel col-md-11"
                            *ngIf="kidsDental.controls['810'].touched && kidsDental.controls['810'].invalid">
                            <small class="text-danger" *ngIf="kidsDental.controls['810'].errors?.required">
                                required
                            </small>
                        </div>
                    </div>
                    <div class="col-md-3 inputBoxPaddingLeft">					
                        <div class="form-group selectDropTwo suffix">
                            <ng-select [items]="states" bindLabel="name" placeholder="Select States" bindValue="id" formControlName="289" [class.is-invalid]="(kidsDental.controls['289'].touched && kidsDental.controls['289'].invalid) || (kidsDental.controls['289'].invalid && isSubmit)">
                            </ng-select>
                            <div class="formLabel col-md-11"
                                *ngIf="kidsDental.controls['289'].touched && kidsDental.controls['289'].invalid">
                                <small class="text-danger" *ngIf="kidsDental.controls['289'].errors?.required">
                                    required
                                </small>
                            </div>
                        </div>    
                    </div>                        
                </div>
                <div class="row inputBoxPaddingLeft"> 
                    <div class="col-md-2">
                        <label class="formLabel">Home Address:</label>
                    </div>
                    <div class="col-md-5">
                        <input class="form-control" id="16" name="16" formControlName="16" type="text" [class.is-invalid]="(kidsDental.controls['16'].touched && kidsDental.controls['16'].invalid) || (kidsDental.controls['16'].invalid && isSubmit)" placeholder="Home Address1" data-id="hname1"/>
                        <div class="formLabel col-md-11"
                            *ngIf="kidsDental.controls['16'].touched && kidsDental.controls['16'].invalid">
                            <small class="text-danger" *ngIf="kidsDental.controls['16'].errors?.required">
                                required
                            </small>
                        </div>
                    </div>
                    <div class="col-md-5">
                        <input class="form-control" id="17" name="17" formControlName="17" type="text" [class.is-invalid]="(kidsDental.controls['17'].touched && kidsDental.controls['17'].invalid) || (kidsDental.controls['17'].invalid && isSubmit)" placeholder="Home Address2" data-id="hname2"/>
                        <div class="formLabel col-md-11"
                                *ngIf="kidsDental.controls['17'].touched && kidsDental.controls['17'].invalid">
                            <small class="text-danger" *ngIf="kidsDental.controls['17'].errors?.required">
                                required
                            </small>
                        </div>
                    </div>
                </div>
                <div class="row inputBoxPaddingLeft"> 
                    <div class="col-md-2 inputBoxPaddingLeft">
                        <label class="formLabel">City:</label>
                    </div>
                    <div class="col-md-2">
                        <input  class="form-control onboard " id="18" name="18" formControlName="18" type="text" [class.is-invalid]="(kidsDental.controls['18'].touched && kidsDental.controls['18'].invalid) || (kidsDental.controls['18'].invalid && isSubmit)" placeholder="Home City" data-id="hcity"/>
                        <div class="formLabel col-md-11"
                            *ngIf="kidsDental.controls['18'].touched && kidsDental.controls['18'].invalid">
                            <small class="text-danger" *ngIf="kidsDental.controls['18'].errors?.required">
                                required
                            </small>
                        </div>
                    </div>
                    <div class="col-md-1 inputBoxPaddingLeft">
                        <label class="formLabel">State:</label>
                    </div>
                    <div class="col-md-2 inputBoxPaddingLeft">
                        <div class="form-group selectDropTwo suffix">
                            <ng-select [items]="states" bindLabel="name" style="width:90%;" placeholder="Select States" bindValue="id" [class.is-invalid]="(kidsDental.controls['20'].touched && kidsDental.controls['20'].invalid) || (kidsDental.controls['20'].invalid && isSubmit)" formControlName="20">
                            </ng-select>
                            <div class="formLabel col-md-11"
                            *ngIf="kidsDental.controls['20'].touched && kidsDental.controls['20'].invalid">
                                <small class="text-danger" *ngIf="kidsDental.controls['20'].errors?.required">
                                    required
                                </small>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-1 inputBoxPaddingLeft">
                        <label class="formLabel">Zip Code:</label>
                    </div>
                    <div class="col-md-4">
                        <input class="form-control" id="21" name="21" formControlName="21" [class.is-invalid]="(kidsDental.controls['21'].touched && kidsDental.controls['21'].invalid) || (kidsDental.controls['21'].invalid && isSubmit)" type="text" data-id="zname"/>
                        <div class="formLabel col-md-11"
                            *ngIf="kidsDental.controls['21'].touched && kidsDental.controls['21'].invalid">
                            <small class="text-danger" *ngIf="kidsDental.controls['21'].errors?.required">
                                required
                            </small>
                        </div>
                    </div>                                
                </div>  
                <div class="row inputBoxPaddingLeft">
                    <div class="form-group form-inline no-margin col-md-3">
                        <label class="formLabel">Will this be your permanent address:</label>
                    </div>
                    <div class="col-md-3">    
                        <label class="checkbox-inline radioStyle">
                            <input type="radio" id="cmd_1480" name="cmd_1480" formControlName="cmd_1480" [class.is-invalid]="(kidsDental.controls['cmd_1480'].touched && kidsDental.controls['cmd_1480'].invalid) || (kidsDental.controls['cmd_1480'].invalid && isSubmit)" value="1">Yes</label>                           
                        <label class="checkbox-inline radioStyle">
                            <input type="radio" id="cmd_1480" name="cmd_1480" formControlName="cmd_1480" [class.is-invalid]="(kidsDental.controls['cmd_1480'].touched && kidsDental.controls['cmd_1480'].invalid) || (kidsDental.controls['cmd_1480'].invalid && isSubmit)" value="0">No</label>  
                            <div class="formLabel col-md-11"
                                *ngIf="kidsDental.controls['cmd_1480'].touched && kidsDental.controls['cmd_1480'].invalid">
                                <small class="text-danger" *ngIf="kidsDental.controls['cmd_1480'].errors?.required">
                                    required
                                </small>
                            </div>
                            <span *ngIf="(kidsDental.controls['cmd_1480'].touched && kidsDental.controls['cmd_1480'].invalid) || (kidsDental.controls['cmd_1480'].invalid && isSubmit)">
                                <i class="fas fa-times ml-2 text-danger"></i>
                            </span>
                    </div>  
                    <div class="col-md-3">
                        <label class="formLabel">Primary Phone::</label>
                    </div>
                    <div class="col-md-3 inputBoxPaddingLeft">
                        <input class="form-control onboard " id="708" name="708" formControlName="708" [class.is-invalid]="(kidsDental.controls['708'].touched && kidsDental.controls['708'].invalid) || (kidsDental.controls['708'].invalid && isSubmit)" type="text" data-id="homephone">
                        <div class="formLabel col-md-11"
                            *ngIf="kidsDental.controls['708'].touched && kidsDental.controls['708'].invalid">
                            <small class="text-danger" *ngIf="kidsDental.controls['708'].errors?.required">
                                required
                            </small>
                        </div>
                    </div>
                </div>
                <div class="row inputBoxPaddingLeft">
                    <div class="col-md-3">
                        <label class="formLabel">Social Security Number:</label>
                    </div>
                    <div class="col-md-9">
                        <input class="form-control onboard " id="32" name="32" formControlName="32" [class.is-invalid]="(kidsDental.controls['32'].touched && kidsDental.controls['32'].invalid) || (kidsDental.controls['32'].invalid && isSubmit)" type="text" data-id="ssn">
                        <div class="formLabel col-md-11"
                            *ngIf="kidsDental.controls['32'].touched && kidsDental.controls['32'].invalid">
                            <small class="text-danger" *ngIf="kidsDental.controls['32'].errors?.required">
                                required
                            </small>
                        </div>
                    </div>  
                </div>
                <div class="row inputBoxPaddingLeft">
                    <div class="col-md-3">
                        <label class="formLabel">Personal E-Mail Address:</label>
                    </div>
                    <div class="col-md-9">
                        <input class="form-control onboard " id="759" name="759" formControlName="759" [class.is-invalid]="(kidsDental.controls['759'].touched && kidsDental.controls['759'].invalid) || (kidsDental.controls['759'].invalid && isSubmit)" type="text" data-id="hemail">
                        <div class="formLabel col-md-11"
                            *ngIf="kidsDental.controls['759'].touched && kidsDental.controls['759'].invalid">
                            <small class="text-danger" *ngIf="kidsDental.controls['759'].errors?.required">
                                required
                            </small>
                        </div>
                    </div>
                </div>
                <div class="form-group form-inline no-margin col-md-12">
                    <label class="formLabel">Have you had any malpractice claims or board actions?: </label>
                    <label class="checkbox-inline radioStyle">
                        <input type="radio" id="cmd_1481" name="cmd_1481" formControlName="cmd_1481" [class.is-invalid]="(kidsDental.controls['cmd_1481'].touched && kidsDental.controls['cmd_1481'].invalid) || (kidsDental.controls['cmd_1481'].invalid && isSubmit)" (change)="onChangeCmdOne($event)" value="1">Yes</label>                        
                    <label class="checkbox-inline radioStyle">
                        <input type="radio" id="cmd_1481" name="cmd_1481" formControlName="cmd_1481" [class.is-invalid]="(kidsDental.controls['cmd_1481'].touched && kidsDental.controls['cmd_1481'].invalid) || (kidsDental.controls['cmd_1481'].invalid && isSubmit)" (change)="onChangeCmdOne($event)" value="0">No</label>  
                        <div class="formLabel col-md-11"
                            *ngIf="kidsDental.controls['cmd_1481'].touched && kidsDental.controls['cmd_1481'].invalid">
                            <small class="text-danger" *ngIf="kidsDental.controls['cmd_1481'].errors?.required">
                                required
                            </small>
                        </div>
                    <span *ngIf="(kidsDental.controls['cmd_1481'].touched && kidsDental.controls['cmd_1481'].invalid) || (kidsDental.controls['cmd_1481'].invalid && isSubmit)">
                        <i class="fas fa-times ml-2 text-danger"></i>
                    </span>    
                </div> 
                <div *ngIf="changeCmdOne" class="inputBoxPadding">
                    <div class="row inputBoxPaddingLeft">
                        <div class="col-md-8">
                            <label class="formLabel">If yes, please explain:</label>
                        </div>	
                    </div>    
                    <div class="row inputBoxPaddingLeft"> 
                        <div class="col-md-12">                        
                            <input class="form-control widthSet" id="cmd_1484" name="cmd_1484" formControlName="cmd_1484" 
                            [class.is-invalid]="kidsDental.controls['cmd_1484'].value==''" type="text" data-id="Role in Malpractice">                                                                            
                            <div class="formLabel col-md-11"
                                *ngIf="kidsDental.controls['cmd_1484'].touched && kidsDental.controls['cmd_1484'].invalid">
                                <small class="text-danger" *ngIf="kidsDental.controls['cmd_1484'].errors?.required">
                                    required
                                </small>
                            </div>
                        </div>
                    </div>   
                </div>   
                <div class="row inputBoxPaddingLeft">
                    <div class="col-md-3">
                        <label class="formLabel">What languages do you speak:</label>
                    </div>
                </div>   
                <table class = "southform_table languageTable">
                    <tr>                        
                        <td colspan="3" bgcolor="#a6a6a6">
                            <div class="col-md-3 inputBoxPadding">
                                <label class="checkBoxHeading">1st </label>
                            </div> 
                        </td>
                        <td colspan="3" bgcolor="#a6a6a6">
                            <div class="col-md-3 inputBoxPadding">
                                <label class="checkBoxHeading">2nd</label>
                            </div> 
                        </td>
                        <td colspan="3" bgcolor="#a6a6a6">
                            <div class="col-md-3 inputBoxPadding">
                                <label class="checkBoxHeading">3rd</label>
                            </div> 
                        </td>
                        <td colspan="2" bgcolor="#a6a6a6">
                            <div class="col-md-2 inputBoxPadding">
                                <label class="checkBoxHeading">4th</label>
                            </div> 
                        </td>                             
                    </tr>  
                    <tr>
                        <td colspan=3>
                            <div class="col-md-3 inputBoxPadding">                                    
                                <div class="form-group selectDrop suffix">
                                    <ng-select [items]="languages" bindLabel="name" placeholder="Select Langauge1" bindValue="id" [class.is-invalid]="(kidsDental.controls['277'].touched && kidsDental.controls['277'].invalid) || (kidsDental.controls['277'].invalid && isSubmit)" formControlName="277">
                                    </ng-select>
                                    <div class="formLabel col-md-11"
                                        *ngIf="kidsDental.controls['277'].touched && kidsDental.controls['277'].invalid">
                                        <small class="text-danger" *ngIf="kidsDental.controls['277'].errors?.required">
                                            required
                                        </small>
                                    </div>
                                </div>
                            </div> 
                        </td>
                        <td colspan="3">
                            <div class="col-md-3 inputBoxPadding">
                                <div class="form-group selectDrop suffix">
                                    <ng-select [items]="secondaryLanguages" bindLabel="name" placeholder="Select Langauge2" bindValue="id" [class.is-invalid]="(kidsDental.controls['1535'].touched && kidsDental.controls['1535'].invalid) || (kidsDental.controls['1535'].invalid && isSubmit)" formControlName="1535">
                                    </ng-select>
                                    <div class="formLabel col-md-11"
                                        *ngIf="kidsDental.controls['1535'].touched && kidsDental.controls['1535'].invalid">
                                        <small class="text-danger" *ngIf="kidsDental.controls['1535'].errors?.required">
                                            required
                                        </small>
                                    </div>
                                </div>
                            </div> 
                        </td>
                        <td colspan="3">
                            <div class="col-md-3 inputBoxPadding">
                                <div class="form-group selectDrop suffix">
                                    <ng-select [items]="tertiaryLanguages" bindLabel="name" placeholder="Select Langauge3" bindValue="id" [class.is-invalid]="(kidsDental.controls['1536'].touched && kidsDental.controls['1536'].invalid) || (kidsDental.controls['1536'].invalid && isSubmit)" formControlName="1536">
                                    </ng-select>
                                    <div class="formLabel col-md-11"
                                        *ngIf="kidsDental.controls['1536'].touched && kidsDental.controls['1536'].invalid">
                                        <small class="text-danger" *ngIf="kidsDental.controls['1536'].errors?.required">
                                            required
                                        </small>
                                    </div>
                                </div>
                            </div> 
                        </td>
                        <td colspan="2">
                            <div class="col-md-2 inputBoxPadding">
                                <div class="form-group selectDrop suffix">
                                    <ng-select [items]="quaternaryLanguages" bindLabel="name" placeholder="Select Langauge4" bindValue="id" [class.is-invalid]="(kidsDental.controls['2207'].touched && kidsDental.controls['2207'].invalid) || (kidsDental.controls['2207'].invalid && isSubmit)" formControlName="2207">
                                    </ng-select>
                                    <div class="formLabel col-md-11"
                                        *ngIf="kidsDental.controls['2207'].touched && kidsDental.controls['2207'].invalid">
                                        <small class="text-danger" *ngIf="kidsDental.controls['2207'].errors?.required">
                                            required
                                        </small>
                                    </div>
                                </div>
                            </div> 
                        </td>    
                    </tr>  
                </table> 
                <div class="form-group form-inline no-margin col-md-12 inputBoxPadding">
                    <label class="formLabel">Are you a veteran or actively in the armed services?</label>
                    <label class="checkbox-inline radioStyle">
                    <input type="radio" id="cmd_1482" name="cmd_1482" formControlName="cmd_1482" [class.is-invalid]="(kidsDental.controls['cmd_1482'].touched && kidsDental.controls['cmd_1482'].invalid) || (kidsDental.controls['cmd_1482'].invalid && isSubmit)" value="1" (change)="onChangeCmdTwo($event)">Yes</label>                    
                    <label class="checkbox-inline radioStyle">
                    <input type="radio" id="cmd_1482" name="cmd_1482" formControlName="cmd_1482" [class.is-invalid]="(kidsDental.controls['cmd_1482'].touched && kidsDental.controls['cmd_1482'].invalid) || (kidsDental.controls['cmd_1482'].invalid && isSubmit)" value="0" (change)="onChangeCmdTwo($event)">No</label>  
                    <div class="formLabel col-md-11"
                        *ngIf="kidsDental.controls['cmd_1482'].touched && kidsDental.controls['cmd_1482'].invalid">
                        <small class="text-danger" *ngIf="kidsDental.controls['cmd_1482'].errors?.required">
                            required
                        </small>
                    </div>
                    <span *ngIf="(kidsDental.controls['cmd_1482'].touched && kidsDental.controls['cmd_1482'].invalid) || (kidsDental.controls['cmd_1482'].invalid && isSubmit)">
                        <i class="fas fa-times ml-2 text-danger"></i>
                    </span> 
                </div>
                <div *ngIf="changeCmdTwo" class="inputBoxPadding">
                    <div class="col-md-3">
                        <label class="formLabel">If yes, branch of service:</label>
                    </div>
                    <div class="form-group no-margin col-md-9 sideLabelAlignment">
                        <input class="form-control" id="1141" name="1141" formControlName="1141" 
                        [class.is-invalid]="kidsDental.controls['1141'].value==''" type="text"  data-id="Mil branch" placeholder="Mil Branch">                        
                        <div class="formLabel col-md-11"
                            *ngIf="kidsDental.controls['1141'].touched && kidsDental.controls['1141'].invalid">
                            <small class="text-danger" *ngIf="kidsDental.controls['1141'].errors?.required">
                                required
                            </small>
                        </div>
                    </div>
                </div>    
                <div *ngIf="changeCmdTwo" class="row inputBoxPadding">    
                    <div class="col-md-3">
                        <label class="formLabel">Dates of service(Start and End):
                        </label>
                    </div>    
                    <div class="col-md-2">
                        <div class="input-group">
                            <input class="form-control onboard datepicker" ngbDatepicker #d2="ngbDatepicker" placeholder="MM/DD/YYYY" id="1144" name="1144" [class.is-invalid]="kidsDental.controls['1144'].value==null" formControlName="1144" (blur)="clearInputIfInvalidForYesorNo('1144')" type="text" data-id="Mil Active from">
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary calendar " (click)="d2.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                            </div>
                        </div>    
                        <div class="formLabel col-md-11"
                            *ngIf="kidsDental.controls['1144'].touched && kidsDental.controls['1144'].invalid">
                            <small class="text-danger" *ngIf="kidsDental.controls['1144'].errors?.required">
                                required
                            </small>
                        </div>
                        <div  *ngIf="kidsDental.get('1144').invalid ">
                            <small class="text-danger" *ngIf="kidsDental.get('1144').errors.invalidDateFormat">
                              Only Date Format is allowed(MM/DD/YYYY)                  </small>
                            <small class="text-danger" *ngIf="kidsDental.get('1144').errors.invalidMonth">
                            Month is out of range
                            </small>
                            <small class="text-danger" *ngIf="kidsDental.get('1144').errors.invalidDateyear">
                              year is out of range(1900-2099)                    </small>
                              <small class="text-danger" *ngIf="kidsDental.get('1144').errors.invalidDateRange">
                                date is out of range
                                </small>
                            </div>
      
                    </div>
                    <div class="col-md-2">
                        <div class="input-group">
                            <input class="form-control onboard datepicker sideLabelTwoAlignment" ngbDatepicker #d3="ngbDatepicker" placeholder="MM/DD/YYYY" id="1145" name="1145" (blur)="clearInputIfInvalidForYesorNo('1145')" [class.is-invalid]="kidsDental.controls['1145'].value==null" formControlName="1145" type="text"  data-id="Mil Active to">
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary calendar" (click)="d3.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                            </div>       
                        </div>                    
                        <div class="formLabel col-md-11"
                            *ngIf="kidsDental.controls['1145'].touched && kidsDental.controls['1145'].invalid">
                            <small class="text-danger" *ngIf="kidsDental.controls['1145'].errors?.required">
                                required
                            </small>
                        </div>
                        <div  *ngIf="kidsDental.get('1145').invalid ">
                            <small class="text-danger" *ngIf="kidsDental.get('1145').errors.invalidDateFormat">
                              Only Date Format is allowed(MM/DD/YYYY)                  </small>
                            <small class="text-danger" *ngIf="kidsDental.get('1145').errors.invalidMonth">
                            Month is out of range
                            </small>
                            <small class="text-danger" *ngIf="kidsDental.get('1145').errors.invalidDateyear">
                              year is out of range(1900-2099)                    </small>
                              <small class="text-danger" *ngIf="kidsDental.get('1145').errors.invalidDateRange">
                                date is out of range
                                </small>
                            </div>
                    </div>                          
                </div>
                <div class="form-group form-inline no-margin col-md-12 inputBoxPad">
                    <label class="formLabel">Please provide a copy of your DD-24 (if applicable)</label>               
                    <label class="checkbox-inline radioStyle">
                    <input type="radio" id="cmd_1489" name="cmd_1489" formControlName="cmd_1489" [class.is-invalid]="(kidsDental.controls['cmd_1489'].touched && kidsDental.controls['cmd_1489'].invalid) || (kidsDental.controls['cmd_1489'].invalid && isSubmit)" (change)="onChangeCmdThree($event)" value="1">Yes</label>                    
                    <label class="checkbox-inline radioStyle">
                    <input type="radio" id="cmd_1489" name="cmd_1489" formControlName="cmd_1489" [class.is-invalid]="(kidsDental.controls['cmd_1489'].touched && kidsDental.controls['cmd_1489'].invalid) || (kidsDental.controls['cmd_1489'].invalid && isSubmit)" (change)="onChangeCmdThree($event)" value="0">No</label>  
                    <div class="formLabel col-md-11"
                            *ngIf="kidsDental.controls['cmd_1489'].touched && kidsDental.controls['cmd_1489'].invalid">
                        <small class="text-danger" *ngIf="kidsDental.controls['cmd_1489'].errors?.required">
                            required
                        </small>
                    </div>
                    <span *ngIf="(kidsDental.controls['cmd_1489'].touched && kidsDental.controls['cmd_1489'].invalid) || (kidsDental.controls['cmd_1489'].invalid && isSubmit)">
                        <i class="fas fa-times ml-2 text-danger"></i>
                    </span> 
                </div>
                <div class="row inputBoxPaddingLeft">
                    <div class="col-md-2">
                        <label class="formLabel">Medicaid State and Number:</label>
                    </div>
                    <div class="col-md-3">                    
                        <div class="form-group selectDrop dropDrownSet">
                            <ng-select [items]="states" bindLabel="name" placeholder="Select States" bindValue="id" formControlName="1314">
                            </ng-select>                           
                        </div>
                    </div>	
                    <div class="col-md-3">	
                        <input class="form-control onboard " id="812" name="812" formControlName="812" type="text" data-id="medicaid" placeholder="Medicaid Number">                        
                    </div>	                    
                    <div class="col-md-2">
                        <label class="formLabel">NPI Number:</label>
                    </div>
                    <div class="col-md-2">
                        <input class="form-control onboard" id="1634"  name="1634" formControlName="1634" type="text" placeholder="NPI">                        
                    </div>	   
                </div>
                <div class="row inputBoxPaddingLeft">
                    <div class="col-md-2">
                        <label class="formLabel">CAQH Number:</label>
                    </div>	
                    <div class="col-md-2">
                        <input class="form-control onboard" id="333"  name="333" formControlName="333" type="text" placeholder="CAQH">                        
                    </div>
                    <div class="col-md-2">
                        <label class="formLabel">CAQH User Name:</label>
                    </div>	
                    <div class="col-md-2">
                        <input class="form-control onboard" id="334"  name="334" formControlName="334" type="text" placeholder="CAQH Username" >                   
                    </div>
                    <div class="col-md-2">
                        <label class="formLabel">CAQH Password:</label>
                    </div>	
                    <div class="col-md-2">
                        <input class="form-control onboard" id="335"  name="335" formControlName="335" type="text" placeholder="CAQH Password">                        
                    </div>
                </div>
                <div class="row inputBoxPaddingLeft">
                    <div class="col-md-2">
                        <label class="formLabel">ADA Number:</label>
                    </div>	
                    <div class="col-md-2">
                        <input class="form-control onboard" id="cmd_1486"  name="cmd_1486" formControlName="cmd_1486" type="text" placeholder="ADA Number">                        
                    </div>
                    <div class="col-md-2">
                        <label class="formLabel">ADA User Name:</label>
                    </div>	
                    <div class="col-md-2">
                        <input class="form-control onboard" id="cmd_1487"  name="cmd_1487" formControlName="cmd_1487" type="text" placeholder="ADA Username" >                        
                    </div>
                    <div class="col-md-2">
                        <label class="formLabel">ADA Password:</label>
                    </div>	
                    <div class="col-md-2">
                        <input class="form-control onboard" id="cmd_1488"  name="cmd_1488" formControlName="cmd_1488" type="text" placeholder="ADA Password">                       
                    </div>
                </div>
                <div class="row inputBoxPaddingLeft">
                    <div class="col-md-2">
                        <label class="formLabel">NPPES User Name: </label>
                    </div>
                    <div class="col-md-2">
                        <input class="form-control onboard append_office_manager_last_name_1" id="1635" name="1635" formControlName="1635" type="text" placeholder= "NPI Username" data-id="managing_contact_last_name">                                
                    </div>
                    <div class="col-md-2">
                        <label class="formLabel">NPPES Password:</label>
                    </div>
                    <div class="col-md-2">
                        <input class="form-control onboard append_office_manager_last_name_1" id="1636" name="1636" formControlName="1636" type="text" placeholder= "NPI Password" data-id="managing_contact_last_name">                                    
                    </div>
                </div>
                <div class="form-group form-inline no-margin col-md-12" style="padding:20px">
                    <label class="formLabel">Will you be covered by our malpractice plan?</label>
                        <label class="checkbox-inline radioStyle">
                            <input type="radio" id="cmd_1483" name="cmd_1483" formControlName="cmd_1483" [class.is-invalid]="(kidsDental.controls['cmd_1483'].touched && kidsDental.controls['cmd_1483'].invalid) || (kidsDental.controls['cmd_1483'].invalid && isSubmit)" value="1">Yes</label>                           
                        <label class="checkbox-inline radioStyle">
                            <input type="radio" id="cmd_1483" name="cmd_1483" formControlName="cmd_1483" [class.is-invalid]="(kidsDental.controls['cmd_1483'].touched && kidsDental.controls['cmd_1483'].invalid) || (kidsDental.controls['cmd_1483'].invalid && isSubmit)" value="0">No</label>  
                            <div class="formLabel col-md-11"
                                *ngIf="kidsDental.controls['cmd_1483'].touched && kidsDental.controls['cmd_1483'].invalid">
                                <small class="text-danger" *ngIf="kidsDental.controls['cmd_1483'].errors?.required">
                                    required
                                </small>
                            </div>
                        <span *ngIf="(kidsDental.controls['cmd_1483'].touched && kidsDental.controls['cmd_1483'].invalid) || (kidsDental.controls['cmd_1483'].invalid && isSubmit)">
                            <i class="fas fa-times ml-2 text-danger"></i>
                        </span>    
                </div>
                <div class="row" style ="padding-left:9px;">
                    <div class="form-group form-inline no-margin col-md-12" >
                        <h4 style="font-weight: normal;font-size: 14px;text-align:left;"><b>Documents Needed (these must all be provided to ensure we have all information to complete your credentialing):</b></h4>
                    </div>
                </div>
                <div class="row inputBoxPaddingLeft">
                    <div class="col-md-12">
                        <ul>
                            <li>Copy of driver’s license</li>
                            <li>Copy of social security card</li>
                            <li>Board certification (if not complete please inform of us on the date of your exam)</li>
                            <li>Copy of all active dental licenses</li>
                            <li>Copy of DEA</li>
                            <li>Copy of Malpractice Insurance (if using your own)</li>
                            <li>Copy of Conscious Sedation License or Negative Report</li>                
                            <li>Curriculum Vitae or Resume (please provide Word version)</li>
                            <li>TB test results (within 12 months)</li>
                            <li>Copy of CPR or PALS certification</li>
                            <li>TEXAS Only: First Dental Home Certification</li>    
                            <li *ngIf="changeCmdThree">Copy of DD-24 (if applicable)</li>    
                        </ul>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group form-inline no-margin col-md-12">
                        <h4 class="hThreeStyle"><b>Peer References:</b></h4>
                    </div>  
                </div>
                <div class="row extraBoxPaddingLeft">		  
                    <div class="col-md-2">
                        <label class="formLabel">Name:</label>
                    </div>
                    <div class="col-md-4">
                        <input class="form-control onboard append_office_manager_first_name_1" id="359" name="359" formControlName="359" type="text" placeholder= "REF 1 NAME" data-id="managing_contact_name">
                    </div> 
                    <div class="col-md-2">
                        <label class="formLabel">Phone:</label>
                    </div>
                    <div class="col-md-4">
                        <input class="form-control onboard append_office_manager_first_name_1" id="369" name="369" formControlName="369" type="text" placeholder= "REF 1 PHONE" data-id="managing_contact_name">
                    </div>
                </div> 
                <div class="row extraBoxPaddingLeft">
                    <div class="col-md-2">
                        <label class="formLabel">Email:</label>
                    </div>
                    <div class="col-md-4">
                        <input class="form-control onboard append_office_manager_first_name_1" id="371" name="371" formControlName="371" type="text" placeholder= "REF 1  EMAIL" data-id="managing_contact_name">
                    </div>
                    <div class="col-md-2">
                        <label class="formLabel">How do you know them:</label>
                    </div>
                    <div class="col-md-4">
                        <input class="form-control onboard append_office_manager_first_name_1" id="2721" name="2721" formControlName="2721" type="text" placeholder= "REF 1  Relation" data-id="managing_contact_name">
                    </div>
                </div>
                <div class="row extraBoxPaddingLeft">
                    <div class="col-md-2">
                        <label class="formLabel">Name:</label>
                    </div>
                    <div class="col-md-4">
                        <input class="form-control onboard append_office_manager_first_name_1" id="372" name="372" formControlName="372" type="text" placeholder= "REF 2 NAME" data-id="managing_contact_name">
                    </div> 
                    <div class="col-md-2">
                        <label class="formLabel">Phone:</label>
                    </div>
                    <div class="col-md-4">
                        <input class="form-control onboard append_office_manager_first_name_1" id="381" name="381" formControlName="381" type="text" placeholder= "REF 2 PHONE" data-id="managing_contact_name">
                    </div>
                </div>
                <div class="row extraBoxPaddingLeft">
                    <div class="col-md-2">
                        <label class="formLabel">Email:</label>
                    </div>
                    <div class="col-md-4">
                        <input class="form-control onboard append_office_manager_first_name_1" id="383" name="383" formControlName="383" type="text" placeholder= "REF 2  EMAIL" data-id="managing_contact_name">
                    </div>
                    <div class="col-md-2">
                        <label class="formLabel">How do you know them:</label>
                    </div>
                    <div class="col-md-4">
                        <input class="form-control onboard append_office_manager_first_name_1" id="2724" name="2724" formControlName="2724" type="text" placeholder= "REF 2  Relation" data-id="managing_contact_name">
                    </div>
                </div>
                <div class="row extraBoxPaddingLeft">
                    <div class="col-md-2">
                        <label class="formLabel">Name:</label>
                    </div>
                    <div class="col-md-4">
                        <input class="form-control onboard append_office_manager_first_name_1" id="384" name="384" formControlName="384" type="text" placeholder= "REF 3 NAME" data-id="managing_contact_name">
                    </div> 
                    <div class="col-md-2">
                        <label class="formLabel">Phone:</label>
                    </div>
                    <div class="col-md-4">
                        <input class="form-control onboard append_office_manager_first_name_1" id="394" name="394" formControlName="394" type="text" placeholder= "REF 3 PHONE" data-id="managing_contact_name">
                    </div>
                </div>
                <div class="row extraBoxPaddingLeft">        
                    <div class="col-md-2">
                        <label class="formLabel">Email:</label>
                    </div>
                    <div class="col-md-4">
                        <input class="form-control onboard append_office_manager_first_name_1" id="396" name="396" formControlName="396" type="text" placeholder= "REF 3  EMAIL" data-id="managing_contact_name">
                    </div>
                    <div class="col-md-2">
                        <label class="formLabel">How do you know them:</label>
                    </div>
                    <div class="col-md-4">
                        <input class="form-control onboard append_office_manager_first_name_1" id="2726" name="2726" formControlName="2726" type="text" placeholder= "REF 3  Relation" data-id="managing_contact_name">
                    </div>
                </div>
                <div class="row">
                    <div class="form-group form-inline no-margin col-md-12">
                        <h4 class="hThreeStyle"><b>Hospital Privileges:</b></h4>
                    </div> 
                </div>
                <div class="row extraBoxPaddingLeft">		
                    <div class="col-md-2">
                        <label class="formLabel">Hospital Name:</label>
                    </div>
                    <div class="col-md-4">
                        <input class="form-control onboard append_office_manager_first_name_1" id="418" name="418" formControlName="418" type="text" placeholder= "HOSP 1 NAME" data-id="managing_contact_name">
                    </div> 
                    <div class="col-md-1">
                        <label class="formLabel">Status:</label>
                    </div>
                    <div class="col-md-4">                    
                        <div class="form-group selectDrop dropDrownSet">
                            <ng-select [items]="staffStatus" bindLabel="name" placeholder="Select StaffStatus" bindValue="id" formControlName="894">
                            </ng-select>
                        </div>
                    </div>
                </div>
                <div class="row extraBoxPaddingLeft">
                    <div class="col-md-2">
                        <label class="formLabel">Address:</label>
                    </div>
                    <div class="col-md-4">
                        <input class="form-control onboard append_office_manager_first_name_1" id="419" name="419" formControlName="419" type="text" placeholder= "HOSP  1 ADD 1" data-id="managing_contact_name">
                    </div>
                    <div class="col-md-4">
                        <input class="form-control onboard append_office_manager_first_name_1" id="420" name="420" formControlName="420" type="text" placeholder= "HOSP 1 ADD  2" data-id="managing_contact_name">
                    </div>
                </div>
                <div class="row extraBoxPaddingLeft">
                    <div class="col-md-2">
                        <label class="formLabel">City:</label>
                    </div>
                    <div class="col-md-3">
                        <input class="form-control onboard append_office_manager_first_name_1" id="421" name="421" formControlName="421" type="text" placeholder= "HOSP  1 CITY" data-id="managing_contact_name">
                    </div>
                    <div class="col-md-1">
                        <label class="formLabel">State:</label>
                    </div>
                    <div class="col-md-3">                                          
                        <div class="form-group selectDrop dropDrownSet">
                            <ng-select [items]="states" bindLabel="name" placeholder="Select States" bindValue="id" formControlName="423">
                            </ng-select>
                        </div>
                    </div>
                    <div class="col-md-1">
                        <label class="formLabel">ZipCode:</label>
                    </div>
                    <div class="col-md-2">
                        <input class="form-control onboard append_office_manager_first_name_1" id="424" name="424" formControlName="424" type="text" placeholder= "HOSP  1 ZIP" data-id="managing_contact_name">
                    </div>
                </div> 
                <div class="row extraBoxPaddingLeft">
                    <div class="col-md-2">
                        <label class="formLabel">Date Began:</label>
                    </div>
                    <div class="form-group form-inline no-margin col-md-2">
                        <div class="input-group">
                            <input class="form-control onboard datepicker"  (blur)="clearInputIfInvalid('426')" ngbDatepicker #d4="ngbDatepicker" placeholder="MM/DD/YYYY" id="426" name="426" formControlName="426" type="text" data-id="hospaff1app">                    
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary calendar" (click)="d4.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                            </div>
                            <div  *ngIf="kidsDental.get('426').invalid ">
                                <small class="text-danger" *ngIf="kidsDental.get('426').errors?.invalidDateFormat">
                                  Only Date Format is allowed(MM/DD/YYYY)
                                </small>
                                <small class="text-danger" *ngIf="kidsDental.get('426').errors?.invalidMonth">
                                Month is out of range
                                </small>
                                <small class="text-danger" *ngIf="kidsDental.get('426').errors?.invalidDateyear">
                                  year is out of range(1900-2099)                      </small>
                                  <small class="text-danger" *ngIf="kidsDental.get('426').errors?.invalidDateRange">
                                    date is out of range
                                    </small>
                                </div>
                        </div>    
                    </div>    
                    <div class="col-md-2">
                        <label class="formLabel">Date Ended:</label>
                    </div>
                    <div class="form-group form-inline no-margin col-md-2">
                        <div class="input-group">
                            <input class="form-control onboard datepicker" ngbDatepicker #d5="ngbDatepicker" placeholder="MM/DD/YYYY" id="1370" name="1370" formControlName="1370"   (blur)="clearInputIfInvalid('1370')" type="text" data-id="hospaff1app">                    
                        
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary calendar" (click)="d5.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                            </div>
                            <div  *ngIf="kidsDental.get('1370').invalid ">
                                <small class="text-danger" *ngIf="kidsDental.get('1370').errors?.invalidDateFormat">
                                  Only Date Format is allowed(MM/DD/YYYY)
                                </small>
                                <small class="text-danger" *ngIf="kidsDental.get('1370').errors?.invalidMonth">
                                Month is out of range
                                </small>
                                <small class="text-danger" *ngIf="kidsDental.get('1370').errors?.invalidDateyear">
                                  year is out of range(1900-2099)                      </small>
                                  <small class="text-danger" *ngIf="kidsDental.get('1370').errors?.invalidDateRange">
                                    date is out of range
                                    </small>
                                </div>
                        </div>    
                    </div>    
                </div>
                <div class="row extraBoxPaddingLeft">
                    <div class="col-md-2">
                        <label class="formLabel">Hospital Name:</label>
                    </div>
                    <div class="col-md-4">
                        <input class="form-control onboard append_office_manager_first_name_1" id="427" name="427" formControlName="427" type="text" placeholder= "HOSP 2 NAME" data-id="managing_contact_name">
                    </div> 
                    <div class="col-md-1">
                        <label class="formLabel">Status:</label>
                    </div>
                    <div class="col-md-4">                                 
                        <div class="form-group selectDrop dropDrownSet">
                            <ng-select [items]="staffStatus" bindLabel="name" placeholder="Select StaffStatus" bindValue="id" formControlName="895">
                            </ng-select>
                        </div>                 
                    </div>
                </div>
                <div class="row extraBoxPaddingLeft">
                    <div class="col-md-2">
                        <label class="formLabel">Address:</label>
                    </div>
                    <div class="col-md-4">
                        <input class="form-control onboard append_office_manager_first_name_1" id="428" name="428" formControlName="428" type="text" placeholder= "HOSP  2 ADD 1" data-id="managing_contact_name">
                    </div>
                    <div class="col-md-4">
                        <input class="form-control onboard append_office_manager_first_name_1" id="429" name="429" formControlName="429" type="text" placeholder= "HOSP 2 ADD  2" data-id="managing_contact_name">
                    </div>
                </div>
                <div class="row extraBoxPaddingLeft">
                    <div class="col-md-2">
                        <label class="formLabel">City:</label>
                    </div>
                    <div class="col-md-3">
                        <input class="form-control onboard append_office_manager_first_name_1" id="430" name="430" formControlName="430" type="text" placeholder= "HOSP  2 CITY" data-id="managing_contact_name">
                    </div>
                    <div class="col-md-1">
                        <label class="formLabel">State:</label>
                    </div>
                    <div class="col-md-3">                                       
                        <div class="form-group selectDrop dropDrownSet">
                            <ng-select [items]="states" bindLabel="name" placeholder="Select States" bindValue="id" formControlName="432">
                            </ng-select>
                        </div>
                    </div>
                    <div class="col-md-1">
                        <label class="formLabel">ZipCode:</label>
                    </div>
                    <div class="col-md-2">
                        <input class="form-control onboard append_office_manager_first_name_1" id="433" name="433" formControlName="433" type="text" placeholder= "HOSP  2 ZIP" data-id="managing_contact_name">
                    </div>
                </div>
                <div class="row extraBoxPaddingLeft">
                    <div class="col-md-2">
                        <label class="formLabel">Date Began:</label>
                    </div>   
                    <div class="form-group form-inline no-margin col-md-2"> 
                        <div class="input-group">
                            <input class="form-control onboard datepicker" ngbDatepicker #d6="ngbDatepicker" placeholder="MM/DD/YYYY" id="435" (blur)="clearInputIfInvalid('435')" name="435" formControlName="435" type="text" data-id="hospaff1app">
                    
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary calendar" (click)="d6.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                            </div>   
                            <div  *ngIf="kidsDental.get('435').invalid ">
                                <small class="text-danger" *ngIf="kidsDental.get('435').errors?.invalidDateFormat">
                                  Only Date Format is allowed(MM/DD/YYYY)
                                </small>
                                <small class="text-danger" *ngIf="kidsDental.get('435').errors?.invalidMonth">
                                Month is out of range
                                </small>
                                <small class="text-danger" *ngIf="kidsDental.get('435').errors?.invalidDateyear">
                                  year is out of range(1900-2099)                      </small>
                                  <small class="text-danger" *ngIf="kidsDental.get('435').errors?.invalidDateRange">
                                    date is out of range
                                    </small>
                                </div>
                        </div>
                    </div>        
                    <div class="col-md-2">    
                        <label class="formLabel">Date Ended:</label>
                    </div>	
                    <div class="form-group form-inline no-margin col-md-2">
                        <div class="input-group">
                            <input class="form-control onboard datepicker" ngbDatepicker #d7="ngbDatepicker" placeholder="MM/DD/YYYY" id="1371" name="1371" (blur)="clearInputIfInvalid('1371')" formControlName="1371" type="text" data-id="hospaff1app">                 
                  
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary calendar" (click)="d7.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                            </div>
                            <div  *ngIf="kidsDental.get('1371').invalid ">
                                <small class="text-danger" *ngIf="kidsDental.get('1371').errors?.invalidDateFormat">
                                  Only Date Format is allowed(MM/DD/YYYY)
                                </small>
                                <small class="text-danger" *ngIf="kidsDental.get('1371').errors?.invalidMonth">
                                Month is out of range
                                </small>
                                <small class="text-danger" *ngIf="kidsDental.get('1371').errors?.invalidDateyear">
                                  year is out of range(1900-2099)                      </small>
                                  <small class="text-danger" *ngIf="kidsDental.get('1371').errors?.invalidDateRange">
                                    date is out of range
                                    </small>
                                </div>
                        </div>
                    </div>        
                </div>
                <div class="row extraBoxPaddingLeft">
                    <div class="col-md-2">
                        <label class="formLabel">Hospital Name:</label>
                    </div>
                    <div class="col-md-4">
                        <input class="form-control onboard append_office_manager_first_name_1" id="436" name="436" formControlName="436" type="text" placeholder= "HOSP 3 NAME" data-id="managing_contact_name">
                    </div> 
                    <div class="col-md-2">
                        <label class="formLabel">Status:</label>
                    </div>
                    <div class="col-md-4">                                        
                        <div class="form-group selectDrop dropDrownSet">
                            <ng-select [items]="staffStatus" bindLabel="name" placeholder="Select StaffStatus" bindValue="id" formControlName="896">
                            </ng-select>
                        </div>
                    </div>
                </div>
                <div class="row extraBoxPaddingLeft">
                    <div class="col-md-2">
                        <label class="formLabel">Address:</label>
                    </div>
                    <div class="col-md-4">
                        <input class="form-control onboard append_office_manager_first_name_1" id="437" name="437" formControlName="437" type="text" placeholder= "HOSP  3 ADD 1" data-id="managing_contact_name">
                    </div>
                    <div class="col-md-4">
                        <input class="form-control onboard append_office_manager_first_name_1" id="438" name="438" formControlName="438" type="text" placeholder= "HOSP 3 ADD  2" data-id="managing_contact_name">
                    </div>
                </div>
                <div class="row extraBoxPaddingLeft">
                    <div class="col-md-2">
                        <label class="formLabel">City:</label>
                    </div>
                    <div class="col-md-3">
                        <input class="form-control onboard append_office_manager_first_name_1" id="439" name="439" formControlName="439" type="text" placeholder= "HOSP  3 CITY" data-id="managing_contact_name">
                    </div>
                    <div class="col-md-1">
                        <label class="formLabel">State:</label>
                    </div>
                    <div class="col-md-3">                    
                        <div class="form-group selectDrop dropDrownSet">
                            <ng-select [items]="states" bindLabel="name" placeholder="Select States" bindValue="id" formControlName="441">
                            </ng-select>
                        </div>
                    </div>
                    <div class="col-md-1">
                        <label class="formLabel">ZipCode:</label>
                    </div>
                    <div class="col-md-2">
                        <input class="form-control onboard append_office_manager_first_name_1" id="442" name="442" formControlName="442" type="text" placeholder= "HOSP  3 ZIP" data-id="managing_contact_name">
                    </div>
                </div>
                <div class="row extraBoxPaddingLeft">
                    <div class="col-md-2">
                        <label class="formLabel">Date Began:</label>	
                    </div>     
                    <div class="form-group form-inline no-margin col-md-2">    
                        <div class="input-group">
                            <input class="form-control onboard datepicker" ngbDatepicker #d8="ngbDatepicker" placeholder="MM/DD/YYYY" id="444" (blur)="clearInputIfInvalid('444')" name="444" formControlName="444" type="text" data-id="hospaff1app">
                        
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary calendar" (click)="d8.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                            </div> 
                            <div  *ngIf="kidsDental.get('444').invalid ">
                                <small class="text-danger" *ngIf="kidsDental.get('444').errors?.invalidDateFormat">
                                  Only Date Format is allowed(MM/DD/YYYY)
                                </small>
                                <small class="text-danger" *ngIf="kidsDental.get('444').errors?.invalidMonth">
                                Month is out of range
                                </small>
                                <small class="text-danger" *ngIf="kidsDental.get('444').errors?.invalidDateyear">
                                  year is out of range(1900-2099)                      </small>
                                  <small class="text-danger" *ngIf="kidsDental.get('444').errors?.invalidDateRange">
                                    date is out of range
                                    </small>
                                </div>
                        </div>
                    </div>           
                    <div class="col-md-2">   
                        <label class="formLabel">Date Ended:</label>	
                    </div>    
                    <div class="form-group form-inline no-margin col-md-2"> 
                        <div class="input-group">
                            <input class="form-control onboard datepicker" ngbDatepicker #d9="ngbDatepicker" placeholder="MM/DD/YYYY" id="1372" name="1372" formControlName="1372" (blur)="clearInputIfInvalid('1372')" type="text" data-id="hospaff1app">                    
                     
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary calendar " (click)="d9.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                            </div>
                            <div  *ngIf="kidsDental.get('1372').invalid ">
                                <small class="text-danger" *ngIf="kidsDental.get('1372').errors?.invalidDateFormat">
                                  Only Date Format is allowed(MM/DD/YYYY)
                                </small>
                                <small class="text-danger" *ngIf="kidsDental.get('1372').errors?.invalidMonth">
                                Month is out of range
                                </small>
                                <small class="text-danger" *ngIf="kidsDental.get('1372').errors?.invalidDateyear">
                                  year is out of range(1900-2099)                      </small>
                                  <small class="text-danger" *ngIf="kidsDental.get('1372').errors?.invalidDateRange">
                                    date is out of range
                                    </small>
                                </div>
                        </div>    
                    </div>        
                </div>
            </form>    
        </div>
    </div>
    <div class="modal-footer mt-4">
        <button *ngIf="formData.receivedForm" type="button" class="btn customOrange float-right"
        (click)="saveForm('approve')">Approve</button>
        <button *ngIf="formData.submitForm" type="submit" class="btn btn customOrange float-right"
        (click)="saveForm('submit')">Submit</button>
        <button *ngIf="formData.submitForm" type="button" class="btn btn customOrange float-right"
        (click)="saveForm('save')">Save</button>
        <button *ngIf="formData.previewForm" type="button" class="btn customOrange float-right" (click)="close()">Close</button>
    </div>
</div>