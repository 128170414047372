import { Injectable } from '@angular/core';
import { ApiServiceService } from '../cmd-api-service/api-service.service';

@Injectable({
  providedIn: 'root'
})
export class CreateNoteService {

  constructor(private apiService:ApiServiceService) { }
  public tinMappedRecords(postParams){
    return this.apiService.post('workflow/tinmappedrecords', postParams);
  }
  public saveNoteFile(postParams){
    return this.apiService.postFile('workflow/savenote', postParams);
  }
  public saveChildnoteFile(postParams){
    return this.apiService.postFile('workflow/savechildnote', postParams);
  }
  public Affliation(postParams){
    return this.apiService.post('providerpassport/affiliation', postParams);
  }
}
