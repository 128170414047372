import { Injectable } from '@angular/core';
import { ApiServiceService } from 'apps/cmd-frontend/src/app/shared/services/cmd-api-service/api-service.service';

@Injectable({
  providedIn: 'root'
})
export class OnboardingformsServiceService {

  constructor(private apiService:ApiServiceService) { }

  public getStatesData(params){
    return this.apiService.post('onboardforms/getstates', params);
  }

  public getGreetingsData(params){
    return this.apiService.post('onboardforms/getgreeting', params);
  }

  public getSpecialtyData(params){
    return this.apiService.post('onboardforms/getspecialty', params);
  }

  public getSpecialtySecondaryData(params){
    return this.apiService.post('onboardforms/getsecondaryspecialty', params);
  }

  public getDegreeData(params){
    return this.apiService.post('onboardforms/getdegree', params);
  }

  public getLocationData(params){
    return this.apiService.post('onboardforms/getlocation', params);
  }

  public getLanguagesData(params){
    return this.apiService.post('onboardforms/getlanguages', params);
  }

  public getsecondaryLanguagesData(params){
    return this.apiService.post('onboardforms/getsecondarylanguages', params);
  }

  public gettertiaryLanguagesData(params){
    return this.apiService.post('onboardforms/gettertiarylanguages', params);
  }

  public getquaternaryLanguagesData(params){
    return this.apiService.post('onboardforms/getquaternarylanguages', params);
  }

  public getBoardCertificationData(params){
    return this.apiService.post('onboardforms/getboardcertification', params);
  }

  public getAggregatesData(params){
    return this.apiService.post('onboardforms/getaggregates', params);
  }

  public getPreClaimData(params){
    return this.apiService.post('onboardforms/getpreclaim', params);
  }

  public getCredentialsData(params){
    return this.apiService.post('onboardforms/getcredentials', params);
  }

  public getAlternateLicenseStateData(params){
    return this.apiService.post('onboardforms/getalternatelicensestate', params);
  }

  public getSuffixData(params){
    return this.apiService.post('onboardforms/getsuffix', params);
  }

  public getCountyData(params){
    return this.apiService.post('onboardforms/getcounty', params);
  }

  public getStaffStatusData(params){
    return this.apiService.post('onboardforms/getstaffstatus', params);
  }

  public getSecondarySpecialtyData(params){
    return this.apiService.post('onboardforms/getsecondaryspecialty', params);
  }

  public getAdditionalLicenseStateData(params){
    return this.apiService.post('onboardforms/getadditionallicenseState', params);
  }

  public getAltCdsStatesData(params){
    return this.apiService.post('onboardforms/getaltcdsstates', params);
  }

  public getTerSpecialtyData(params){
    return this.apiService.post('onboardforms/getterspecialty', params);
  }

  public getGenderData(params){
    return this.apiService.post('onboardforms/getgender', params);
  }

  public getRaceData(params){
    return this.apiService.post('onboardforms/getrace', params);
  }

  public getboardstatus(params){
    return this.apiService.post('onboardforms/getboardstatus', params);
  }

  public getcmecategory(params){
    return this.apiService.post('onboardforms/getcmecategory', params);
  }

  public toCreateEsignOrSign(params){
    return this.apiService.postFile('onboardforms/createesign', params);
  }

  public getPreviewEsign(params){
    return this.apiService.post('onboardforms/previewesign', params);
  }

  public getMediCertifications(params){
    return this.apiService.post('onboardforms/getmedicertifications', params);
  }

  //To get save data
  public toGetSaveData(postParams) {
    return this.apiService.post('onboardforms/clickhereforapporve', postParams);
  }

  //To get save data
  public toGetLocationDetails(postParams) {
    return this.apiService.post('onboardforms/getlocationdetails', postParams);
  }

  public topreviewesign(postParams) {
    return this.apiService.post('onboardforms/previewesign', postParams);
  }

  public geterielocations(postParams) {
    return this.apiService.post('onboardforms/geterielocations', postParams);
  }

  public getlocationdetails(postParams) {
    return this.apiService.post('onboardforms/getlocationdetails', postParams);
  }

  public getCdsStatesData(params){
    return this.apiService.post('onboardforms/getcdsstates', params);
  }

  public getAdditionalCdsStatesData(params){
    return this.apiService.post('onboardforms/getadditionalcdsstates', params);
  }
  public getAdditional2CdsStatesData(params){
    return this.apiService.post('onboardforms/getadditional2cdsstates', params);
  }
  public getDea2StatesData(params){
    return this.apiService.post('onboardforms/getdea2states', params);
  }
  public getAdditionalDeaStatesData(params){
    return this.apiService.post('onboardforms/getadditionaldeastates', params);
  }
  public getquaternaryspecialty(params){
    return this.apiService.post('onboardforms/getquaternaryspecialty', params);
  }
  

}
