<div id="pcsform">
  <div class="cust-header">
     <img src="./assets/images/pcs.PNG" alt="no image found">
  </div>
  <form [formGroup]="PCSForms">
     <table class="table table-bordered" style="table-layout: fixed;">
        <tbody>
           <tr style="visibility: collapse;">
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
           </tr>
           <tr>
              <td colspan="12" class="p-0">
                 <div class="coloredTitle">
                    Provider Personal Information
                 </div>
              </td>
           </tr>
           <tr>
              <td colspan="3">
                 <div class="row">
                    <div class="col-4 pr-0"> <label for="9">Last Name<span class="mandatory">*</span>:</label></div>
                    <div class="col-8 pl-0">
                       <input type="text" placeholder="Enter Last Name" id="9" class="form-control" formControlName="9" (change)="onChangeLastName($event)"

                          [class.is-invalid]="(PCSForms.controls['9'].touched && PCSForms.controls['9'].invalid ) || (PCSForms.controls['9'].invalid && isSubmit)">
                       <div class="invalid-feedback"
                          *ngIf="(PCSForms.controls['9'].touched && PCSForms.controls['9'].invalid) || (PCSForms.controls['9'].invalid && isSubmit)">
                          The Last name is required and cannot be empty
                       </div>
                    </div>
                 </div>
              </td>
              <td colspan="3">
                 <div class="row">
                    <div class="col-5 pr-0"> <label for="8">First Name<span class="mandatory">*</span>:</label></div>
                    <div class="col-7 pl-0">
                       <input type="text" placeholder="Enter First Name" id="8" class="form-control" formControlName="8" (change)="onChangeFirstName($event)"

                          [class.is-invalid]="(PCSForms.controls['8'].touched && PCSForms.controls['8'].invalid ) || (PCSForms.controls['8'].invalid && isSubmit)">
                       <div class="invalid-feedback"
                          *ngIf="(PCSForms.controls['8'].touched && PCSForms.controls['8'].invalid) || (PCSForms.controls['8'].invalid && isSubmit)">
                          The First name is required and cannot be empty
                       </div>
                    </div>
                 </div>
              </td>
              <td colspan="3">
                 <div class="row">
                  <div class="col-5 pr-0"> <label for="809">Middle:</label></div>
                  <div class="col-7 pl-0">
                    <input placeholder="Enter Middle Name" id="809" type="text" formControlName = "809" class="form-control">
                  </div>
                    <!-- <label for="809">Middle :</label>
                    <input placeholder="Enter Middle Name" id="809" type="text" formControlName = "809" class="form-control"> -->
                 </div>
              </td>
              <td colspan="3">
                 <div class="row">
                    <div class="col-3"> <label for="822">Degree<span class="mandatory">*</span>:</label></div>
                    <div class="col-8 pl-0">
                       <ng-select [labelForId]="'822'" [items]="credentials" [class.is-invalid]="
                       (PCSForms.controls['822'].touched &&
                       PCSForms.controls['822'].invalid) ||
                       (PCSForms.controls['822'].invalid && isSubmit)" bindLabel="name" placeholder="Select Degree"
                       bindValue="id" formControlName="822">
                       </ng-select>
                       <div class="invalid-feedback"
                          *ngIf="(PCSForms.controls['822'].touched && PCSForms.controls['822'].invalid) || (PCSForms.controls['822'].invalid && isSubmit)">
                          Degree is required and cannot be empty
                       </div>
                    </div>
                 </div>
              </td>
           </tr>
           <tr>
              <td colspan="6">
                 <div class="row">
                    <div class="col-4 pr-0"> <label for="16">Home Mailing Address<span class="mandatory">*</span>:</label>
                    </div>
                    <div class="col-3 pl-0">
                       <input type="text" placeholder=" Enter Home Address 1" id="16" class="form-control" formControlName="16"
                          [class.is-invalid]="(PCSForms.controls['16'].touched && PCSForms.controls['16'].invalid ) || (PCSForms.controls['16'].invalid && isSubmit)">
                       <div class="invalid-feedback"
                          *ngIf="(PCSForms.controls['16'].touched && PCSForms.controls['16'].invalid) || (PCSForms.controls['16'].invalid && isSubmit)">
                          Home Mailing Addres1 is required and cannot be empty
                       </div>
                       <!-- <div class="invalid-feedback"
                          *ngIf="PCSForms.controls['16'].touched && PCSForms.controls['16'].invalid">
                          <small class="text-danger" *ngIf="PCSForms.controls['16'].errors?.required">The
                            Home Mailing Addres1 is required and cannot be empty</small>
                          <small class="text-danger" *ngIf="PCSForms.controls['16'].errors?.pattern">
                            Email must be a valid email address </small>
                       </div> -->
                    </div>
                    <div class="col-4 pl-0">
                       <input type="text" placeholder=" Enter Home Address 2" id="17" class="form-control" formControlName="17"
                          [class.is-invalid]="(PCSForms.controls['17'].touched && PCSForms.controls['17'].invalid ) || (PCSForms.controls['17'].invalid && isSubmit)">
                       <div class="invalid-feedback"
                          *ngIf="(PCSForms.controls['17'].touched && PCSForms.controls['17'].invalid) || (PCSForms.controls['17'].invalid && isSubmit)">
                          Home Mailing Address2 is required and cannot be empty
                       </div>
                       <!-- <div class="invalid-feedback"
                       *ngIf="PCSForms.controls['17'].touched && PCSForms.controls['17'].invalid">
                       <small class="text-danger" *ngIf="PCSForms.controls['17'].errors?.required">The
                         Home Mailing Addres 2 is required and cannot be empty</small>
                       <small class="text-danger" *ngIf="PCSForms.controls['17'].errors?.pattern">
                         Email must be a valid email address </small>
                    </div> -->
                    </div>
                 </div>
                 <br>
                 <div class="row">
                    <div class="col-md-3 pr-0"> <label for="18">City/State/Zip<span class="mandatory">*</span>:</label>
                    </div>
                    <div class="col-3 pl-0">
                       <input type="text" placeholder=" Enter Home City" id="18" class="form-control"
                          formControlName="18"
                          [class.is-invalid]="(PCSForms.controls['18'].touched && PCSForms.controls['18'].invalid ) || (PCSForms.controls['18'].invalid && isSubmit)">
                       <div class="invalid-feedback"
                          *ngIf="(PCSForms.controls['18'].touched && PCSForms.controls['18'].invalid) || (PCSForms.controls['18'].invalid && isSubmit)">
                          Home City is required and cannot be empty
                       </div>
                    </div>
                    <div class="col-3 pl-0">
                      <ng-select
                       [items]="states"
                       [class.is-invalid]="(PCSForms.controls['20'].touched &&
                       PCSForms.controls['20'].invalid) ||
                       (PCSForms.controls['20'].invalid && isSubmit)"
                       bindLabel="name"
                       placeholder="Enter State"
                       bindValue="id"
                       formControlName="20"
                       >
                       </ng-select>
                      <div class="invalid-feedback"
                         *ngIf="(PCSForms.controls['20'].touched && PCSForms.controls['20'].invalid) || (PCSForms.controls['20'].invalid && isSubmit)">
                         Home state is required and cannot be empty
                      </div>
                   </div>
                    <div class="col-3 pl-0">
                       <input type="text" placeholder="Enter Home Zip" id="21" class="form-control" formControlName="21"
                          [class.is-invalid]="(PCSForms.controls['21'].touched && PCSForms.controls['21'].invalid ) || (PCSForms.controls['21'].invalid && isSubmit)">
                       <div class="invalid-feedback"
                          *ngIf="(PCSForms.controls['21'].touched && PCSForms.controls['21'].invalid) || (PCSForms.controls['21'].invalid && isSubmit)">
                          Home zip is required and cannot be empty
                       </div>
                    </div>
                 </div>
              </td>
              <td colspan="6">
                 <div class="row">
                    <!-- <div class="col-4 pr-0"> <label for="709">Phone Number<span class="mandatory">*</span>:</label></div>
                    <div class="col-8 pl-0">
                       <input type="text" placeholder="Enter Phone Number" id="709" class="form-control" formControlName="709"
                          [class.is-invalid]="(PCSForms.controls['709'].touched && PCSForms.controls['709'].invalid ) || (PCSForms.controls['709'].invalid && isSubmit)">
                       <div class="invalid-feedback"
                          *ngIf="PCSForms.controls['709'].touched && PCSForms.controls['709'].invalid">
                          <small class="text-danger" *ngIf="PCSForms.controls['709'].errors?.required">The
                          Phone is required and cannot be empty</small>
                          <small class="text-danger" *ngIf="PCSForms.controls['709'].errors?.pattern">
                          Only number are allowed</small>
                       </div>
                    </div> -->
                    <div class="col-4 pr-0"> <label for="709">Phone Number<span class="mandatory">*</span>:</label></div>
                    <div class="col-8 pl-0">
                     <input type="text" placeholder="Enter Phone Number" id="709" class="form-control" formControlName="709"
                        [class.is-invalid]="(PCSForms.controls['709'].touched && PCSForms.controls['709'].invalid ) || (PCSForms.controls['709'].invalid && isSubmit)">
                     <div class="invalid-feedback"
                        *ngIf="(PCSForms.controls['709'].touched && PCSForms.controls['709'].invalid) || (PCSForms.controls['709'].invalid && isSubmit)">
                         Phone number required and cannot be empty
                     </div>
                  </div>
                    <br>
                 </div>
                 <div class="row">
                    <!-- <div class="col-4 pr-0"> <label for="759">Email<span class="mandatory">*</span>:</label></div>
                    <div class="col-8 pl-0">
                       <input type="text" placeholder="Enter Email" id="759" class="form-control" formControlName="759"
                          [class.is-invalid]="(PCSForms.controls['759'].touched && PCSForms.controls['759'].invalid ) || (PCSForms.controls['759'].invalid && isSubmit)">
                       <div class="invalid-feedback"
                          *ngIf="PCSForms.controls['759'].touched && PCSForms.controls['759'].invalid">
                          <small class="text-danger" *ngIf="PCSForms.controls['759'].errors?.required">The
                          Email is required and cannot be empty</small>
                          <small class="text-danger" *ngIf="PCSForms.controls['759'].errors?.pattern">The
                          Email must be a valid email address</small>
                       </div>
                    </div> -->
                    <div class="col-4 pr-0"> <label for="759">Email<span class="mandatory">*</span>:</label></div>
                    <div class="col-8 pl-0">
                     <input type="text" placeholder="Enter Email" id="759" class="form-control" formControlName="759"
                        [class.is-invalid]="(PCSForms.controls['759'].touched && PCSForms.controls['759'].invalid ) || (PCSForms.controls['759'].invalid && isSubmit)">
                     <div class="invalid-feedback"
                        *ngIf="(PCSForms.controls['759'].touched && PCSForms.controls['759'].invalid) || (PCSForms.controls['759'].invalid && isSubmit)">
                        Email required and cannot be empty
                     </div>
                  </div>
                 </div>
              </td>
           </tr>
           <tr>
              <td colspan="6">
                 <div class="row">
                    <div class="col-3"> <label for="31">Date Of Birth<span class="mandatory">*</span>:</label></div>
                    <div class="col-8">
                     <div class="input-group">
                       <input type="text" placeholder="Enter Date Of Birth" ngbDatepicker #d31="ngbDatepicker" id="31" class="form-control" formControlName="31"  (blur)="clearInputIfInvalidMandi('31')"
                          [class.is-invalid]="(PCSForms.controls['31'].touched && PCSForms.controls['31'].invalid ) || (PCSForms.controls['31'].invalid && isSubmit)">
                          <div class="input-group-append">
                           <button class="btn btn-outline-secondary calendar" (click)="d31.toggle()" type="button">
                             <i class="fas fa-calendar-alt"></i>
                           </button>
                         </div>
                     </div>
                     <div class="text-danger" *ngIf="((PCSForms.controls['31'].invalid&&PCSForms.controls['31'].touched) && (PCSForms.controls['31'].value==''|| PCSForms.controls['31'].value==null) || ((PCSForms.controls['31'].invalid && isSubmit) && (PCSForms.controls['31'].value==''|| PCSForms.controls['31'].value==null)) ) " >
                        <small >
                           
                           The DOB is required and cannot be empty
                        </small>
                      
                      </div>
                      <small class="text-danger" *ngIf="PCSForms.get('31').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)                </small>
                      <small class="text-danger" *ngIf="PCSForms.get('31').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="PCSForms.get('31').errors?.invalidDateyear">
                        year is out of range(1900-2099)                  </small>
                        <small class="text-danger" *ngIf="PCSForms.get('31').errors?.invalidDateRange">
                          date is out of range
                          </small>
                  </div>    
                          <!-- <div class="invalid-feedback"
                          *ngIf="PCSForms.controls['31'].touched && PCSForms.controls['31'].invalid">
                          <small class="text-danger" *ngIf="PCSForms.controls['31'].errors?.required">The
                          DOB is required and cannot be empty</small>
                       </div> -->
                    
                 </div>
                 <br>
                 <div colspan="6">
                  <div class="row">
                    <div class="col-md-4 pr-0"> <label for="810">Birthplace (City/State/Country)<span
                       class="mandatory">*</span>:</label></div>
                    <div class="col-3 pl-0">
                       <input type="text" placeholder="Enter City" id="810" class="form-control" formControlName="810"
                          [class.is-invalid]="(PCSForms.controls['810'].touched && PCSForms.controls['810'].invalid ) || (PCSForms.controls['810'].invalid && isSubmit)">
                       <div class="invalid-feedback"
                          *ngIf="(PCSForms.controls['810'].touched && PCSForms.controls['810'].invalid) || (PCSForms.controls['810'].invalid && isSubmit)">
                        City is required and cannot be empty
                       </div>
                    </div>
                    <div class="col-3 pl-0">
                      <ng-select
                       [items]="states"
                       [class.is-invalid]="(PCSForms.controls['289'].touched &&
                       PCSForms.controls['289'].invalid) ||
                       (PCSForms.controls['289'].invalid && isSubmit)"
                       bindLabel="name"
                       placeholder="Enter State"
                       bindValue="id"
                       formControlName="289"
                       >
                       </ng-select>
                      <div class="invalid-feedback"
                         *ngIf="(PCSForms.controls['289'].touched && PCSForms.controls['289'].invalid) || (PCSForms.controls['289'].invalid && isSubmit)">
                         State  is required and cannot be empty
                      </div>
                   </div>
                   <div class="col-2 pl-0">
                    <input type="text" placeholder="Enter Country" id="291" class="form-control" formControlName="291"
                       [class.is-invalid]="(PCSForms.controls['291'].touched && PCSForms.controls['291'].invalid ) || (PCSForms.controls['291'].invalid && isSubmit)">
                    <div class="invalid-feedback"
                       *ngIf="(PCSForms.controls['291'].touched && PCSForms.controls['291'].invalid) || (PCSForms.controls['291'].invalid && isSubmit)">
                       Country  is required and cannot be empty
                    </div>
                 </div>
                 </div>

                 </div>
              </td>
              <td colspan="6">
                <div class="row">
                       <div class="col-8 pr-0"> <label for="292">Citizenship (If not US Citizen, please include copy of Alien Registration Card :</label></div>
                       <div class="col-4 pl-0">
                          <input type="text" placeholder="Enter Citizenship" id="292" class="form-control" formControlName="292"
                             [class.is-invalid]="(PCSForms.controls['292'].touched && PCSForms.controls['292'].invalid ) || (PCSForms.controls['292'].invalid && isSubmit)">
                          <div class="invalid-feedback"
                             *ngIf="(PCSForms.controls['292'].touched && PCSForms.controls['292'].invalid) || (PCSForms.controls['292'].invalid && isSubmit)">
                             Citizenship/Alien Registration Card is required and cannot be empty
                          </div>
                       </div>
                    </div>

                 <!-- <br> -->
                 <!-- <div class="form-inline">
                    <label for="cmd_1002">Alien Registration Card:</label>
                    <input type="text" placeholder="Enter Alien Registration Card" class="form-control"  formControlName = "cmd_1002" id="cmd_1002">
                 </div> -->
              </td>
           </tr>
           <tr>
              <td colspan="4">
                 <div class="row">
                    <div class="col-6 pr-0"> <label for="32">Social Security Number<span class="mandatory">*</span>:</label>
                    </div>
                    <div class="col-6 pl-0">
                       <input type="text" placeholder="Enter SSN" id="32" class="form-control" formControlName="32"
                          [class.is-invalid]="(PCSForms.controls['32'].touched && PCSForms.controls['32'].invalid ) || (PCSForms.controls['32'].invalid && isSubmit)">
                       <div class="invalid-feedback"
                          *ngIf="(PCSForms.controls['32'].touched && PCSForms.controls['32'].invalid) || (PCSForms.controls['32'].invalid && isSubmit)">
                          SSN is required and cannot be empty
                       </div>
                    </div>
                 </div>
              </td>
              <td colspan="4">
                 <div class="row">
                    <div class="col-6 ml-0"> <label for="30">Gender<span class="mandatory">*</span>:</label></div>
                    <div class="col-6 pl-0">
                       <ng-select
                       [items]="gender"
                       [class.is-invalid]=" (PCSForms.controls['30'].touched &&
                       PCSForms.controls['30'].invalid) ||
                       (PCSForms.controls['30'].invalid && isSubmit)"
                       bindLabel="name"
                       placeholder="Select Gender"
                       bindValue="id"
                       formControlName="30"
                       >
                       </ng-select>
                       <div class="invalid-feedback"
                          *ngIf="(PCSForms.controls['30'].touched && PCSForms.controls['30'].invalid) || (PCSForms.controls['30'].invalid && isSubmit)">
                          Gender is required and cannot be empty
                       </div>
                    </div>
                 </div>
              </td>
              <td colspan="4">
                 <div class="form-inline">
                    <label for="741">Race/Ethnicity (Optional) :</label>
                     <div class="col-6 pl-0">
                        <ng-select
                           [items]="race"
                           bindLabel="name"
                           placeholder="Enter Race/Ethnicity"
                           bindValue="id"
                           formControlName="741"
                           >
                        </ng-select>
                     </div>   
                 </div>
              </td>
           </tr>
           <tr>
              <td colspan="12" class="p-0">
                 <div class="row">
                    <div class="col-4 pr-0"> <label for="277">List any foreign languages you speak fluently<span
                       class="mandatory">*</span>:</label></div>
                    <div class="col-2 pl-0">
                       <!-- <input type="text" placeholder="SSN" id="277" class="form-control" formControlName="277" -->
                       <!-- [class.is-invalid]="(PCSForms.controls['277'].touched && PCSForms.controls['277'].invalid ) || (PCSForms.controls['277'].invalid && isSubmit)"> -->
                       <ng-select
                       [items]="languages"
                       [class.is-invalid]="(PCSForms.controls['277'].touched &&
                       PCSForms.controls['277'].invalid) ||
                       (PCSForms.controls['277'].invalid && isSubmit)"
                       bindLabel="name"
                       placeholder="Enter Primary Language"
                       bindValue="id"
                       formControlName="277"
                       >
                       </ng-select>
                       <div class="invalid-feedback"
                          *ngIf="(PCSForms.controls['277'].touched && PCSForms.controls['277'].invalid) || (PCSForms.controls['277'].invalid && isSubmit)">
                          Primary Language is required and cannot be empty
                       </div>
                    </div>
                    <div class="col-2 pl-0">
                      <!-- <input type="text" placeholder="SSN" id="277" class="form-control" formControlName="277" -->
                      <!-- [class.is-invalid]="(PCSForms.controls['277'].touched && PCSForms.controls['277'].invalid ) || (PCSForms.controls['277'].invalid && isSubmit)"> -->
                      <ng-select
                      [items]="secondaryLanguages"
                      [class.is-invalid]="(PCSForms.controls['1535'].touched &&
                      PCSForms.controls['1535'].invalid) ||
                      (PCSForms.controls['1535'].invalid && isSubmit)"
                      bindLabel="name"
                      placeholder="Enter Secondary Languages"
                      bindValue="id"
                      formControlName="1535"
                      >
                      </ng-select>
                      <!-- <div class="invalid-feedback"
                         *ngIf="(PCSForms.controls['1535'].touched && PCSForms.controls['1535'].invalid) || (PCSForms.controls['1535'].invalid && isSubmit)">
                         secondary Language is required and cannot be empty
                      </div> -->
                   </div>
                   <div class="col-2 pl-0">
                    <!-- <input type="text" placeholder="SSN" id="277" class="form-control" formControlName="277" -->
                    <!-- [class.is-invalid]="(PCSForms.controls['277'].touched && PCSForms.controls['277'].invalid ) || (PCSForms.controls['277'].invalid && isSubmit)"> -->
                    <ng-select
                    [items]="tertiaryLanguages"
                    [class.is-invalid]="(PCSForms.controls['1536'].touched &&
                    PCSForms.controls['1536'].invalid) ||
                    (PCSForms.controls['1536'].invalid && isSubmit)"
                    bindLabel="name"
                    placeholder="Enter  Tertiary Language"
                    bindValue="id"
                    formControlName="1536"
                    >
                    </ng-select>
                    <!-- <div class="invalid-feedback"
                       *ngIf="(PCSForms.controls['1536'].touched && PCSForms.controls['1536'].invalid) || (PCSForms.controls['1536'].invalid && isSubmit)">
                       Tertiary languages is required and cannot be empty
                    </div> -->
                 </div>
                 <div class="col-2 pl-0">
                  <!-- <input type="text" placeholder="SSN" id="277" class="form-control" formControlName="277" -->
                  <!-- [class.is-invalid]="(PCSForms.controls['277'].touched && PCSForms.controls['277'].invalid ) || (PCSForms.controls['277'].invalid && isSubmit)"> -->
                  <ng-select
                  [items]="quaternaryLanguages"
                  [class.is-invalid]="(PCSForms.controls['2207'].touched &&
                  PCSForms.controls['2207'].invalid) ||
                  (PCSForms.controls['2207'].invalid && isSubmit)"
                  bindLabel="name"
                  placeholder="Enter Quaternary Language"
                  bindValue="id"
                  formControlName="2207"
                  >
                  </ng-select>
                  <!-- <div class="invalid-feedback"
                     *ngIf="(PCSForms.controls['2207'].touched && PCSForms.controls['2207'].invalid) || (PCSForms.controls['2207'].invalid && isSubmit)">
                     Quaternary Languages  is required and cannot be empty
                  </div> -->
               </div>
                 </div>
              </td>
           </tr>
           <tr>
              <td colspan="12" class="p-0">
                 <div class="coloredTitle">
                    CAQH Proview
                 </div>
              </td>
           </tr>
           <tr>
              <td colspan="4">
                 <div class="row">
                    <div class="col-5 pr-0"> <label for="333">Provider Number<span class="mandatory">*</span>:</label></div>
                    <div class="col-5 pl-0">
                       <input type="text" placeholder="CAQH ID Number" id="333" class="form-control" formControlName="333"
                          [class.is-invalid]="(PCSForms.controls['333'].touched && PCSForms.controls['333'].invalid ) || (PCSForms.controls['333'].invalid && isSubmit)">
                       <div class="invalid-feedback"
                          *ngIf="(PCSForms.controls['333'].touched && PCSForms.controls['333'].invalid) || (PCSForms.controls['333'].invalid && isSubmit)">
                          Caqh id is required and cannot be empty
                       </div>
                    </div>
                 </div>
              </td>
              <td colspan="4">
                 <div class="row">
                    <div class="col-6 pr-0"> <label for="334">User Name<span class="mandatory">*</span>:</label></div>
                    <div class="col-6 pl-0">
                       <input type="text" placeholder="Enter User Name" id="334" class="form-control" formControlName="334"
                          [class.is-invalid]="(PCSForms.controls['334'].touched && PCSForms.controls['334'].invalid ) || (PCSForms.controls['334'].invalid && isSubmit)">
                       <div class="invalid-feedback"
                          *ngIf="(PCSForms.controls['334'].touched && PCSForms.controls['334'].invalid) || (PCSForms.controls['334'].invalid && isSubmit)">
                          Caqh username is required and cannot be empty
                       </div>
                    </div>
                 </div>
              </td>
              <td colspan="4">
                 <div class="form-inline">
                    <div class="row">
                       <div class="col-6 pr-0"> <label for="335">Password<span class="mandatory">*</span>:</label></div>
                       <div class="col-6 pl-0">
                          <input type="text" placeholder="Enter Password" id="335" class="form-control" formControlName="335"
                             [class.is-invalid]="(PCSForms.controls['335'].touched && PCSForms.controls['335'].invalid ) || (PCSForms.controls['335'].invalid && isSubmit)">
                          <div class="invalid-feedback"
                             *ngIf="(PCSForms.controls['335'].touched && PCSForms.controls['335'].invalid) || (PCSForms.controls['335'].invalid && isSubmit)">
                             CAQH Password is required and cannot be empty
                          </div>
                       </div>
                    </div>
                 </div>
              </td>
           </tr>
           <tr>
              <td colspan="12" class="p-0">
                 <div class="coloredTitle">
                    PECOS (Medicare)
                 </div>
              </td>
           </tr>
           <tr>
              <td colspan="6">
                 <div class="row">
                    <div class="col-3 pr-0"> <label for="2108">User Name<span class="mandatory">*</span>:</label></div>
                    <div class="col-3 pl-0">
                       <input type="text" placeholder="Enter User Name" id="2108" class="form-control" formControlName="2108"
                          [class.is-invalid]="(PCSForms.controls['2108'].touched && PCSForms.controls['2108'].invalid ) || (PCSForms.controls['2108'].invalid && isSubmit)">
                       <div class="invalid-feedback"
                          *ngIf="(PCSForms.controls['2108'].touched && PCSForms.controls['2108'].invalid) || (PCSForms.controls['2108'].invalid && isSubmit)">
                          Cms User Name is required and cannot be empty
                       </div>
                    </div>
                 </div>
              </td>
              <td colspan="6">
                 <div class="row">
                    <div class="col-3 pr-0"> <label for="2109">Password<span class="mandatory">*</span>:</label></div>
                    <div class="col-3 pl-0">
                       <input type="text" placeholder="Enter Password" id="2109" class="form-control" formControlName="2109"
                          [class.is-invalid]="(PCSForms.controls['2108'].touched && PCSForms.controls['2109'].invalid ) || (PCSForms.controls['2109'].invalid && isSubmit)">
                       <div class="invalid-feedback"
                          *ngIf="(PCSForms.controls['2109'].touched && PCSForms.controls['2109'].invalid) || (PCSForms.controls['2109'].invalid && isSubmit)">
                          Cms Password is required and cannot be empty
                       </div>
                    </div>
                 </div>
              </td>
           </tr>
           <tr>
              <td colspan="12" class="p-0">
                 <div class="coloredTitle">
                    NPPES (NPI Registry)
                 </div>
              </td>
           </tr>
           <tr>
              <td colspan="4">
                 <div class="row">
                    <div class="col-6 pr-0"> <label for="1635">User Name<span class="mandatory">*</span>:</label></div>
                    <div class="col-6 pl-0">
                       <input type="text" placeholder="Enter User Name" id="1635" class="form-control" formControlName="1635"
                          [class.is-invalid]="(PCSForms.controls['1635'].touched && PCSForms.controls['1635'].invalid ) || (PCSForms.controls['1635'].invalid && isSubmit)">
                       <div class="invalid-feedback"
                          *ngIf="(PCSForms.controls['1635'].touched && PCSForms.controls['1635'].invalid) || (PCSForms.controls['1635'].invalid && isSubmit)">
                          NPI Username is required and cannot be empty
                       </div>
                    </div>
                 </div>
              </td>
              <td colspan="4">
                 <div class="row">
                    <div class="col-6 pr-0"> <label for="1636">Password<span class="mandatory">*</span>:</label></div>
                    <div class="col-6 pl-0">
                       <input type="text" placeholder="Enter Password" id="1636" class="form-control" formControlName="1636"
                          [class.is-invalid]="(PCSForms.controls['1636'].touched && PCSForms.controls['1636'].invalid ) || (PCSForms.controls['1636'].invalid && isSubmit)">
                       <div class="invalid-feedback"
                          *ngIf="(PCSForms.controls['1636'].touched && PCSForms.controls['1636'].invalid) || (PCSForms.controls['1636'].invalid && isSubmit)">
                          NPI Password is required and cannot be empty
                       </div>
                    </div>
                 </div>
              </td>
              <td colspan="4">
                 <!-- <div class="form-inline">
                    <label for="2">Individual NPI#<span class="mandatory">*</span>:</label>
                    <input type="text" placeholder="Enter Password"   class="form-control" id="2">
                    </div> -->
                 <div class="row">
                    <div class="col-6 pr-0"> <label for="1634">Individual NPI # <span class="mandatory">*</span>:</label></div>
                    <div class="col-6 pl-0">
                       <input type="text" placeholder="Enter Individual NPI" id="1634" class="form-control"
                          formControlName="1634"
                          [class.is-invalid]="(PCSForms.controls['1634'].touched && PCSForms.controls['1634'].invalid ) || (PCSForms.controls['1634'].invalid && isSubmit)">
                       <div class="invalid-feedback"
                          *ngIf="(PCSForms.controls['1634'].touched && PCSForms.controls['1634'].invalid) || (PCSForms.controls['1634'].invalid && isSubmit)">
                          Individual NPI is required and cannot be empty
                       </div>
                    </div>
                 </div>
              </td>
           </tr>
           <tr>
              <td colspan="12" class="p-0">
                 <div class="coloredTitle">
                    Medical License
                 </div>
              </td>
           </tr>
           <tr>
              <td colspan="3">
                 <!-- <div class="form-inline">
                    <label for="1">State<span class="mandatory">*</span>:</label>
                    <input type="text" class="form-control" id="1">
                    </div> -->
                 <div class="row">
                    <div class="col-6 pr-0"> <label for="299">State<span class="mandatory">*</span>:</label></div>
                    <div class="col-6 pl-0">
                       <!-- <input type="text" placeholder="Enter State" id="293" class="form-control" formControlName="293" -->
                       <!-- [class.is-invalid]="(PCSForms.controls['293'].touched && PCSForms.controls['293'].invalid ) || (PCSForms.controls['293'].invalid && isSubmit)"> -->
                       <ng-select
                       [items]="states"
                       [class.is-invalid]="(PCSForms.controls['299'].touched &&
                       PCSForms.controls['299'].invalid) ||
                       (PCSForms.controls['299'].invalid && isSubmit)"
                       bindLabel="name"
                       placeholder="Enter State"
                       bindValue="id"
                       formControlName="299"
                       >
                       </ng-select>
                       <div class="invalid-feedback"
                          *ngIf="(PCSForms.controls['299'].touched && PCSForms.controls['299'].invalid) || (PCSForms.controls['299'].invalid && isSubmit)">
                          State is required and cannot be empty
                       </div>
                    </div>
                 </div>
              </td>
              <td colspan="3">
                 <div class="row">
                    <div class="col-6 pr-0"> <label for="293">License Number<span class="mandatory">*</span>:</label></div>
                    <div class="col-6 pl-0">
                       <input type="text" placeholder="License Number" id="293" class="form-control" formControlName="293"
                          [class.is-invalid]="(PCSForms.controls['293'].touched && PCSForms.controls['293'].invalid ) || (PCSForms.controls['293'].invalid && isSubmit)">
                       <div class="invalid-feedback"
                          *ngIf="(PCSForms.controls['293'].touched && PCSForms.controls['293'].invalid) || (PCSForms.controls['293'].invalid && isSubmit)">
                          Medical License Number is required and cannot be empty
                       </div>
                    </div>
                 </div>
              </td>
              <td colspan="3">
                 <div class="row">
                    <div class="col-4 pr-0"> <label for="294">Issue Date<span class="mandatory">*</span>:</label></div>
                    <div class="col-8">
                     <div class="input-group">
                       <input type="text" ngbDatepicker #d294="ngbDatepicker" placeholder="Issue Date" id="294" class="form-control" formControlName="294"  (blur)="clearInputIfInvalidMandi('294')"
                          [class.is-invalid]="(PCSForms.controls['294'].touched && PCSForms.controls['294'].invalid ) || (PCSForms.controls['294'].invalid && isSubmit)">
                          <div class="input-group-append">
                           <button class="btn btn-outline-secondary calendar" (click)="d294.toggle()" type="button">
                             <i class="fas fa-calendar-alt"></i>
                           </button>
                         </div>
                     </div> 
                     <div class="text-danger" *ngIf="((PCSForms.controls['294'].invalid&&PCSForms.controls['294'].touched) && (PCSForms.controls['294'].value==''|| PCSForms.controls['294'].value==null) || ((PCSForms.controls['294'].invalid && isSubmit) && (PCSForms.controls['294'].value==''|| PCSForms.controls['294'].value==null)) ) " >
                        <small >
                           
                           Issue Date is required and cannot be empty
                        </small>
                      
                      </div>
                      <small class="text-danger" *ngIf="PCSForms.get('294').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)                </small>
                      <small class="text-danger" *ngIf="PCSForms.get('294').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="PCSForms.get('294').errors?.invalidDateyear">
                        year is out of range(1900-2099)                  </small>
                        <small class="text-danger" *ngIf="PCSForms.get('294').errors?.invalidDateRange">
                          date is out of range
                          </small>
                          
                          <!-- <div class="invalid-feedback"
                          *ngIf="(PCSForms.controls['294'].touched && PCSForms.controls['294'].invalid) || (PCSForms.controls['294'].invalid && isSubmit)">
                          Issue Date is required and cannot be empty
                       </div> -->
                    </div>
                 </div>
              </td>
              <td colspan="3">
                 <div class="row">
                    <div class="col-5 pr-0"> <label for="295">Expiration Date<span class="mandatory">*</span>:</label></div>
                    <div class="col-7">
                     <div class="input-group"> 
                       <input type="text" ngbDatepicker #d295="ngbDatepicker" placeholder="Medical License Expiration Date" id="295" class="form-control"  (blur)="clearInputIfInvalidMandi('295')"
                          formControlName="295"
                          [class.is-invalid]="(PCSForms.controls['295'].touched && PCSForms.controls['295'].invalid ) || (PCSForms.controls['295'].invalid && isSubmit)">
                          <div class="input-group-append">
                           <button class="btn btn-outline-secondary calendar" (click)="d295.toggle()" type="button">
                             <i class="fas fa-calendar-alt"></i>
                           </button>
                         </div> 
                        </div>  

                        <div class="text-danger" *ngIf="((PCSForms.controls['295'].invalid&&PCSForms.controls['295'].touched) && (PCSForms.controls['295'].value==''|| PCSForms.controls['295'].value==null) || ((PCSForms.controls['295'].invalid && isSubmit) && (PCSForms.controls['295'].value==''|| PCSForms.controls['295'].value==null)) ) " >
                           <small >
                              
                              Expiration Date is required and cannot be empty
                           </small>
                         
                         </div>
                         <small class="text-danger" *ngIf="PCSForms.get('295').errors?.invalidDateFormat">
                           Only Date Format is allowed(MM/DD/YYYY)                </small>
                         <small class="text-danger" *ngIf="PCSForms.get('295').errors?.invalidMonth">
                         Month is out of range
                         </small>
                         <small class="text-danger" *ngIf="PCSForms.get('295').errors?.invalidDateyear">
                           year is out of range(1900-2099)                  </small>
                           <small class="text-danger" *ngIf="PCSForms.get('295').errors?.invalidDateRange">
                             date is out of range
                             </small>
                          <!-- <div class="invalid-feedback"
                          *ngIf="(PCSForms.controls['295'].touched && PCSForms.controls['295'].invalid) || (PCSForms.controls['295'].invalid && isSubmit)">
                          Expiration Date is required and cannot be empty
                       </div> -->
                    </div>
                 </div>
              </td>
           </tr>
           <tr>
              <td colspan="12" class="p-0">
                 <div class="coloredTitle">
                    Drug Enforcement Administration (DEA)
                 </div>
              </td>
           </tr>
           <tr>
              <td colspan="6">
                 <!-- <div class="form-inline">
                    <label for="1">License Number<span class="mandatory">*</span>:</label>
                    <input type="text" class="form-control" id="1">
                    </div> -->
                 <div class="row">
                    <div class="col-6 pr-0"> <label for="309">License Number<span class="mandatory">*</span>:</label></div>
                    <div class="col-6 pl-0">
                       <input type="text" placeholder="Enter License Number" id="309" class="form-control"
                          formControlName="309"
                          [class.is-invalid]="(PCSForms.controls['309'].touched && PCSForms.controls['309'].invalid ) || (PCSForms.controls['309'].invalid && isSubmit)">
                       <div class="invalid-feedback"
                          *ngIf="(PCSForms.controls['309'].touched && PCSForms.controls['309'].invalid) || (PCSForms.controls['309'].invalid && isSubmit)">
                          License Number is required and cannot be empty
                       </div>
                    </div>
                 </div>
              </td>
              <td colspan="6">
                 <div class="row">
                    <div class="col-3 pr-0"> <label for="310">Issue Date<span class="mandatory">*</span>:</label></div>
                    <div class="col-8">
                     <div class="input-group">
                       <input type="text" placeholder="Enter Issue Date" ngbDatepicker #d310="ngbDatepicker" id="310" class="form-control" formControlName="310"  (blur)="clearInputIfInvalidMandi('310')"
                          [class.is-invalid]="(PCSForms.controls['310'].touched && PCSForms.controls['310'].invalid ) || (PCSForms.controls['310'].invalid && isSubmit)">
                          <div class="input-group-append">
                           <button class="btn btn-outline-secondary calendar" (click)="d310.toggle()" type="button">
                             <i class="fas fa-calendar-alt"></i>
                           </button>
                         </div>
                     </div> 
                     <div class="text-danger" *ngIf="((PCSForms.controls['310'].invalid&&PCSForms.controls['310'].touched) && (PCSForms.controls['310'].value==''|| PCSForms.controls['310'].value==null) || ((PCSForms.controls['310'].invalid && isSubmit) && (PCSForms.controls['310'].value==''|| PCSForms.controls['310'].value==null)) ) " >
                        <small >
                           
                           DEA Expiration is required and cannot be empty
                        </small>
                      
                      </div>
                      <small class="text-danger" *ngIf="PCSForms.get('310').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)                </small>
                      <small class="text-danger" *ngIf="PCSForms.get('310').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="PCSForms.get('310').errors?.invalidDateyear">
                        year is out of range(1900-2099)                  </small>
                        <small class="text-danger" *ngIf="PCSForms.get('310').errors?.invalidDateRange">
                          date is out of range
                          </small> 
                          <!-- <div class="invalid-feedback"
                          *ngIf="(PCSForms.controls['310'].touched && PCSForms.controls['310'].invalid) || (PCSForms.controls['310'].invalid && isSubmit)">
                          DEA Expiration Date is required and cannot be empty
                       </div> -->
                    </div>
                 </div>
              </td>
           </tr>
           <tr>
              <td colspan="10" class="p-0">
                  <div class="coloredTitle">
                    Controlled Dangerous Substances (CDS) - If Applicable
                  </div>  
               </td>  
               <td colspan="2" class="p-0">
                  <div class="form-check">
                     <input formControlName="cmd_NA_01" (ngModelChange)="handleNAchange('cmd_NA_01')" class="form-check-input" type="checkbox" value="1" id="cmd_NA_01">
                        <label class="form-check-label" for="cmd_NA_01">
                                 <b>Does Not Apply</b>
                        </label>
                            
                  </div>      
                     
                    
               
                 
              </td>
           </tr>
           <tr>
              <td colspan="3">
                 <!-- <div class="form-inline">
                    <label for="1">State<span class="mandatory">*</span>:</label>
                    <input type="text" class="form-control" id="1">
                    </div> -->
                 <div class="row">
                    <div class="col-6 pr-0"> <label for="996">State:<span *ngIf="PCSForms.controls['996'].invalid" class="mandatory">*</span></label></div>
                    <div class="col-6 pl-0">
                       <!-- <input type="text" placeholder="Enter State" id="996" class="form-control" formControlName="996" -->
                       <!-- [class.is-invalid]="(PCSForms.controls['996'].touched && PCSForms.controls['996'].invalid ) || (PCSForms.controls['996'].invalid && isSubmit)"> -->
                       <ng-select (blur)="handleNAchange('cmd_NA_01')" [class.is-invalid]="PCSForms.controls['996'].invalid" [items]="CdsStates"
                       
                       bindLabel="name" placeholder="Select  State" bindValue="id"
                       formControlName="996" >
                       </ng-select>
                        <div class="mandatory"
                           *ngIf=" PCSForms.controls['996'].invalid">
                           <small>
                           required
                           </small>
                        </div>
                    </div>
                 </div>
              </td>
              <td colspan="3">
                 <div class="row">
                    <div class="col-6 pr-0"> <label for="337">Registration Number:</label><span *ngIf="PCSForms.controls['337'].invalid" class="mandatory">*</span>
                    </div>
                    <div class="col-6 pl-0">
                       <input type="text" (blur)="handleNAchange('cmd_NA_01',337)" [class.is-invalid]="PCSForms.controls['337'].invalid" placeholder="Enter Registration Number" id="337" class="form-control"
                          formControlName="337"
                          >
                        <div class="mandatory"
                          *ngIf="PCSForms.controls['337'].invalid">
                          <small >
                            required
                          </small>
                        </div>  
                       
                    </div>
                 </div>
              </td>
              <td colspan="3">
                 <div class="row">
                    <div class="col-4"> <label for="338">Issue Date:</label><span *ngIf="PCSForms.controls['338'].invalid" class="mandatory">*</span></div>
                    <div class="col-8">
                     <div class="input-group">
                       <input type="text" (blur)="handleNAchange('cmd_NA_01',338)" (blur)="clearInputIfInvalid('338','cmd_NA_01')" [class.is-invalid]="PCSForms.controls['338'].invalid" placeholder="Enter Issue Date" id="338" class="form-control" formControlName="338"
                       ngbDatepicker #d338="ngbDatepicker" >
                       <div class="input-group-append">
                              <button class="btn btn-outline-secondary calendar" (click)="d338.toggle()" type="button">
                              <i class="fas fa-calendar-alt"></i>
                              </button>
                           </div>
                        </div>
                        <div class="text-danger" *ngIf="(PCSForms.controls['338'].invalid&&PCSForms.controls['338'].touched) && (PCSForms.controls['338'].value==''|| PCSForms.controls['338'].value==null) " >
                           <small >
                           required
                           </small>
         
                       </div>
                         <small class="text-danger" *ngIf="PCSForms.get('338').errors?.invalidDateFormat">
                           Only Date Format is allowed(MM/DD/YYYY)                </small>
                         <small class="text-danger" *ngIf="PCSForms.get('338').errors?.invalidMonth">
                         Month is out of range
                         </small>
                         <small class="text-danger" *ngIf="PCSForms.get('338').errors?.invalidDateyear">
                           year is out of range(1900-2099)                  </small>
                           <small class="text-danger" *ngIf="PCSForms.get('338').errors?.invalidDateRange">
                             date is out of range
                             </small>
     
                    </div>
                 </div>
              </td>
              <td colspan="3">
                 <div class="row">
                    <div class="col-5"> <label for="339">Expiration Date:</label><span *ngIf="PCSForms.controls['339'].invalid" class="mandatory">*</span></div>
                    <div class="col-7">
                     <div class="input-group">
                       <input type="text" (blur)="handleNAchange('cmd_NA_01',339)" (blur)="clearInputIfInvalid('339','cmd_NA_01')"  [class.is-invalid]="PCSForms.controls['339'].invalid" placeholder="Enter Expiration Date" id="339" class="form-control"
                          formControlName="339" ngbDatepicker #d339="ngbDatepicker"
                        >
                        <div class="input-group-append">
                           <button class="btn btn-outline-secondary calendar" (click)="d339.toggle()" type="button">
                           <i class="fas fa-calendar-alt"></i>
                           </button>
                        </div>
                     </div>
                     <div class="text-danger" *ngIf="(PCSForms.controls['339'].invalid&&PCSForms.controls['339'].touched) && (PCSForms.controls['339'].value==''|| PCSForms.controls['339'].value==null) " >
                        <small >
                        required
                        </small>
      
                    </div>
                      <small class="text-danger" *ngIf="PCSForms.get('339').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)                </small>
                      <small class="text-danger" *ngIf="PCSForms.get('339').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="PCSForms.get('339').errors?.invalidDateyear">
                        year is out of range(1900-2099)                  </small>
                        <small class="text-danger" *ngIf="PCSForms.get('339').errors?.invalidDateRange">
                          date is out of range
                          </small>
                    </div>
                 </div>
              </td>
           </tr>
           <tr>
              <td colspan="12" class="p-0">
                 <div class="coloredTitle">
                    Peer References
                 </div>
              </td>
           </tr>
           <tr>
              <td colspan="12">
                 <p>List three (3) professional references, preferably from your specialty area, not including
                    relatives, current partners of associates in the practice.
                    If possible, include at least one member from the Medical Staff of each facility at which you have
                    privileges.<br>
                    NOTE: References must be from the individuals who are directly familiar with your work, either via direct
                    clinical observation or through close working relations.
                 </p>
              </td>
           </tr>
           <tr>
              <td colspan="6">
                 <div class="row">
                    <div class="col-4 pr-0"> <label for="359">Name of Reference<span class="mandatory">*</span>:</label></div>
                    <div class="col-4 pl-0">
                       <input type="text" placeholder="Enter Reference Name" id="359" class="form-control"
                          formControlName="359"
                          [class.is-invalid]="(PCSForms.controls['359'].touched && PCSForms.controls['359'].invalid ) || (PCSForms.controls['359'].invalid && isSubmit)">
                       <div class="invalid-feedback"
                          *ngIf="(PCSForms.controls['359'].touched && PCSForms.controls['359'].invalid) || (PCSForms.controls['359'].invalid && isSubmit)">
                          Reference Name required and cannot be empty
                       </div>
                    </div>
                 </div>
                 <br>
                 <div class="row">
                    <div class="col-4 pr-0"> <label for="360">Address<span class="mandatory">*</span>:</label></div>
                    <div class="col-4 pl-0">
                       <input type="text" placeholder="Enter Reference Address " id="ds" class="form-control"
                          formControlName="360"
                          [class.is-invalid]="(PCSForms.controls['360'].touched && PCSForms.controls['360'].invalid ) || (PCSForms.controls['360'].invalid && isSubmit)">
                       <div class="invalid-feedback"
                          *ngIf="(PCSForms.controls['360'].touched && PCSForms.controls['360'].invalid) || (PCSForms.controls['360'].invalid && isSubmit)">
                          Reference 1 Address 1 is required and cannot be empty
                       </div>
                    </div>
                    <div class="col-4 pl-0">
                       <input type="text" placeholder="Enter Reference  Address " id="361" class="form-control"
                          formControlName="361"
                          [class.is-invalid]="(PCSForms.controls['361'].touched && PCSForms.controls['361'].invalid ) || (PCSForms.controls['361'].invalid && isSubmit)">
                       <div class="invalid-feedback"
                          *ngIf="(PCSForms.controls['361'].touched && PCSForms.controls['361'].invalid) || (PCSForms.controls['361'].invalid && isSubmit)">
                          Reference 2 Address 2 is required and cannot be empty
                       </div>
                    </div>
                 </div>
                 <div class="row">
                    <div class="col-3 pr-0"></div>
                    <div class="col-3 pl-0">
                       <input type="text" placeholder="Enter Reference  City" id="362" class="form-control"
                          formControlName="362"
                          [class.is-invalid]="(PCSForms.controls['362'].touched && PCSForms.controls['362'].invalid ) || (PCSForms.controls['362'].invalid && isSubmit)">
                       <div class="invalid-feedback"
                          *ngIf="(PCSForms.controls['362'].touched && PCSForms.controls['362'].invalid) || (PCSForms.controls['362'].invalid && isSubmit)">
                          Reference 1 City is required and cannot be empty
                       </div>
                    </div>
                    <div class="col-3 pl-0">
                      <ng-select
                      [items]="states"
                      [class.is-invalid]="(PCSForms.controls['364'].touched &&
                      PCSForms.controls['364'].invalid) ||
                      (PCSForms.controls['364'].invalid && isSubmit)"
                      bindLabel="name"
                      placeholder="Enter State"
                      bindValue="id"
                      formControlName="364"
                      >
                      </ng-select>
                      <div class="invalid-feedback"
                         *ngIf="(PCSForms.controls['364'].touched && PCSForms.controls['364'].invalid) || (PCSForms.controls['364'].invalid && isSubmit)">
                         Reference 1 state is required and cannot be empty
                      </div>
                   </div>
                    <div class="col-3 pl-0">
                       <input type="text" placeholder="Enter Reference  Zip" id="365" class="form-control"
                          formControlName="365"
                          [class.is-invalid]="(PCSForms.controls['365'].touched && PCSForms.controls['365'].invalid ) || (PCSForms.controls['365'].invalid && isSubmit)">
                       <div class="invalid-feedback"
                          *ngIf="(PCSForms.controls['365'].touched && PCSForms.controls['365'].invalid) || (PCSForms.controls['365'].invalid && isSubmit)">
                          Reference 1 Zip is required and cannot be empty
                       </div>
                    </div>
                 </div>
              </td>
              <td colspan="6">
                 <div class="row">
                    <div class="col-2 pr-0"> <label for="368">Specialty<span class="mandatory">*</span>:</label></div>
                    <div class="col-10 pl-0">
                       <ng-select
                       [items]="specialty"
                       [class.is-invalid]="
                       (PCSForms.controls['368'].touched &&
                       PCSForms.controls['368'].errors) ||
                       (PCSForms.controls['368'].errors && isSubmit)
                       "
                       bindLabel="name"
                       placeholder="Select Specialty"
                       bindValue="id"
                       formControlName="368"
                       >
                       </ng-select>
                       <div class="invalid-feedback"
                          *ngIf="(PCSForms.controls['368'].touched && PCSForms.controls['368'].invalid) || (PCSForms.controls['368'].invalid && isSubmit)">
                          Primary Specialty required and cannot be empty
                       </div>
                    </div>
                 </div>
                 <br>
                 <div class="row">
                    <div class="col-4 pr-0"> <label for="369">Phone Number<span class="mandatory">*</span>:</label></div>
                    <div class="col-4 pl-0">
                       <input type="text" placeholder="Enter Phone Number" id="369" class="form-control" formControlName="369"
                          [class.is-invalid]="(PCSForms.controls['369'].touched && PCSForms.controls['369'].invalid ) || (PCSForms.controls['369'].invalid && isSubmit)">
                       <div class="invalid-feedback"
                          *ngIf="(PCSForms.controls['369'].touched && PCSForms.controls['369'].invalid) || (PCSForms.controls['369'].invalid && isSubmit)">
                          Reference Phone 1 required and cannot be empty
                       </div>
                    </div>
                 </div>
                 <br>
                 <div class="row">
                    <div class="col-4 pr-0"> <label for="371">Email:</label></div>
                    <div class="col-4 pl-0">
                       <input type="text" placeholder="Enter Email" id="371" class="form-control" formControlName="371"
                          >
                       
                    </div>
                 </div>
              </td>
           </tr>
           <tr>
              <td colspan="6">
                 <div class="row">
                    <div class="col-4 pr-0"> <label for="372">Name of Reference<span class="mandatory">*</span>:</label></div>
                    <div class="col-4 pl-0">
                       <input type="text" placeholder="Enter Reference Name" id="372" class="form-control"
                          formControlName="372"
                          [class.is-invalid]="(PCSForms.controls['372'].touched && PCSForms.controls['372'].invalid ) || (PCSForms.controls['372'].invalid && isSubmit)">
                       <div class="invalid-feedback"
                          *ngIf="(PCSForms.controls['372'].touched && PCSForms.controls['372'].invalid) || (PCSForms.controls['372'].invalid && isSubmit)">
                          Reference Name required and cannot be empty
                       </div>
                    </div>
                 </div>
                 <br>
                 <div class="row">
                    <div class="col-4 pr-0"> <label for="373">Address<span class="mandatory">*</span>:</label></div>
                    <div class="col-4 pl-0">
                       <input type="text" placeholder="Enter Reference Address" id="ds" class="form-control"
                          formControlName="373"
                          [class.is-invalid]="(PCSForms.controls['373'].touched && PCSForms.controls['373'].invalid ) || (PCSForms.controls['373'].invalid && isSubmit)">
                       <div class="invalid-feedback"
                          *ngIf="(PCSForms.controls['373'].touched && PCSForms.controls['373'].invalid) || (PCSForms.controls['373'].invalid && isSubmit)">
                          Reference 2 Address 2 is required and cannot be empty
                       </div>
                    </div>
                    <div class="col-4 pl-0">
                       <input type="text" placeholder="Enter Reference Address" id="374" class="form-control"
                          formControlName="374"
                          [class.is-invalid]="(PCSForms.controls['374'].touched && PCSForms.controls['374'].invalid ) || (PCSForms.controls['374'].invalid && isSubmit)">
                       <div class="invalid-feedback"
                          *ngIf="(PCSForms.controls['374'].touched && PCSForms.controls['374'].invalid) || (PCSForms.controls['374'].invalid && isSubmit)">
                          Reference 2 Address 2 is required and cannot be empty
                       </div>
                    </div>
                 </div>
                 <div class="row">
                    <div class="col-3 pr-0"></div>
                    <div class="col-3 pl-0">
                       <input type="text" placeholder="Enter Reference  City" id="375" class="form-control"
                          formControlName="375"
                          [class.is-invalid]="(PCSForms.controls['375'].touched && PCSForms.controls['375'].invalid ) || (PCSForms.controls['375'].invalid && isSubmit)">
                       <div class="invalid-feedback"
                          *ngIf="(PCSForms.controls['375'].touched && PCSForms.controls['375'].invalid) || (PCSForms.controls['375'].invalid && isSubmit)">
                          Reference 2 City is required and cannot be empty
                       </div>
                    </div>
                    <div class="col-3 pl-0">
                      <ng-select
                      [items]="states"
                      [class.is-invalid]="(PCSForms.controls['377'].touched &&
                      PCSForms.controls['377'].invalid) ||
                      (PCSForms.controls['377'].invalid && isSubmit)"
                      bindLabel="name"
                      placeholder="Enter State"
                      bindValue="id"
                      formControlName="377"
                      >
                      </ng-select>
                      <div class="invalid-feedback"
                         *ngIf="(PCSForms.controls['377'].touched && PCSForms.controls['377'].invalid) || (PCSForms.controls['377'].invalid && isSubmit)">
                         Reference 2 state is required and cannot be empty
                      </div>
                   </div>
                    <div class="col-3 pl-0">
                       <input type="text" placeholder="Enter Reference  Zip" id="378" class="form-control" formControlName="378"
                          [class.is-invalid]="(PCSForms.controls['378'].touched && PCSForms.controls['378'].invalid ) || (PCSForms.controls['378'].invalid && isSubmit)">
                       <div class="invalid-feedback"
                          *ngIf="(PCSForms.controls['378'].touched && PCSForms.controls['378'].invalid) || (PCSForms.controls['378'].invalid && isSubmit)">
                          Reference 2 Zip is required and cannot be empty
                       </div>
                    </div>
                 </div>
              </td>
              <td colspan="6">
                 <div class="row">
                    <div class="col-2 pr-0"> <label for="380">Specialty<span class="mandatory">*</span>:</label></div>
                    <div class="col-10 pl-0">
                       <ng-select
                       [items]="specialty"
                       [class.is-invalid]="
                       (PCSForms.controls['380'].touched &&
                       PCSForms.controls['380'].errors) ||
                       (PCSForms.controls['380'].errors && isSubmit)
                       "
                       bindLabel="name"
                       placeholder="Select Specialty"
                       bindValue="id"
                       formControlName="380"
                       >
                       </ng-select>
                       <div class="invalid-feedback"
                          *ngIf="(PCSForms.controls['380'].touched && PCSForms.controls['380'].invalid) || (PCSForms.controls['380'].invalid && isSubmit)">
                          Primary Specialty required and cannot be empty
                       </div>
                    </div>
                 </div>
                 <br>
                 <div class="row">
                    <div class="col-4 pr-0"> <label for="381">Phone Number<span class="mandatory">*</span>:</label></div>
                    <div class="col-4 pl-0">
                       <input type="text" placeholder="Enter Phone Number" id="381" class="form-control" formControlName="381"
                          [class.is-invalid]="(PCSForms.controls['381'].touched && PCSForms.controls['381'].invalid ) || (PCSForms.controls['381'].invalid && isSubmit)">
                       <div class="invalid-feedback"
                          *ngIf="(PCSForms.controls['381'].touched && PCSForms.controls['381'].invalid) || (PCSForms.controls['381'].invalid && isSubmit)">
                          Reference Phone 2 required and cannot be empty
                       </div>
                    </div>
                 </div>
                 <br>
                 <div class="row">
                    <div class="col-4 pr-0"> <label for="383">Email:</label></div>
                    <div class="col-4 pl-0">
                       <input type="text" placeholder="Enter Email" id="383" class="form-control" formControlName="383"
                          >
                      
                    </div>
                 </div>
              </td>
           </tr>
           <tr>
              <td colspan="6">
                 <div class="row">
                    <div class="col-4 pr-0"> <label for="384">Name of Reference<span class="mandatory">*</span>:</label></div>
                    <div class="col-4 pl-0">
                       <input type="text" placeholder="Enter Reference  Name" id="384" class="form-control"
                          formControlName="384"
                          [class.is-invalid]="(PCSForms.controls['384'].touched && PCSForms.controls['384'].invalid ) || (PCSForms.controls['384'].invalid && isSubmit)">
                       <div class="invalid-feedback"
                          *ngIf="(PCSForms.controls['384'].touched && PCSForms.controls['384'].invalid) || (PCSForms.controls['384'].invalid && isSubmit)">
                          Reference Name required and cannot be empty
                       </div>
                    </div>
                 </div>
                 <br>
                 <div class="row">
                    <div class="col-4 pr-0"> <label for="385">Address<span class="mandatory">*</span>:</label></div>
                    <div class="col-4 pl-0">
                       <input type="text" placeholder="Enter Reference  Address" id="385" class="form-control"
                          formControlName="385"
                          [class.is-invalid]="(PCSForms.controls['385'].touched && PCSForms.controls['385'].invalid ) || (PCSForms.controls['385'].invalid && isSubmit)">
                       <div class="invalid-feedback"
                          *ngIf="(PCSForms.controls['385'].touched && PCSForms.controls['385'].invalid) || (PCSForms.controls['385'].invalid && isSubmit)">
                          Reference 3 Address 3 is required and cannot be empty
                       </div>
                    </div>
                    <div class="col-4 pl-0">
                       <input type="text" placeholder="Enter Reference Address" id="386" class="form-control"
                          formControlName="386"
                          [class.is-invalid]="(PCSForms.controls['386'].touched && PCSForms.controls['386'].invalid ) || (PCSForms.controls['386'].invalid && isSubmit)">
                       <div class="invalid-feedback"
                          *ngIf="(PCSForms.controls['386'].touched && PCSForms.controls['386'].invalid) || (PCSForms.controls['386'].invalid && isSubmit)">
                          Reference 3 Address 3 is required and cannot be empty
                       </div>
                    </div>
                 </div>
                 <div class="row">
                    <div class="col-3 pr-0"></div>
                    <div class="col-3 pl-0">
                      <input type="text" placeholder="Enter Reference City" id="387" class="form-control"
                         formControlName="387"
                         [class.is-invalid]="(PCSForms.controls['387'].touched && PCSForms.controls['387'].invalid ) || (PCSForms.controls['387'].invalid && isSubmit)">
                      <div class="invalid-feedback"
                         *ngIf="(PCSForms.controls['387'].touched && PCSForms.controls['387'].invalid) || (PCSForms.controls['387'].invalid && isSubmit)">
                         Reference 3 City is required and cannot be empty
                      </div>
                   </div>
                   <div class="col-3 pl-0">
                    <ng-select
                    [items]="states"
                    [class.is-invalid]="(PCSForms.controls['389'].touched &&
                    PCSForms.controls['389'].invalid) ||
                    (PCSForms.controls['389'].invalid && isSubmit)"
                    bindLabel="name"
                    placeholder="Enter State"
                    bindValue="id"
                    formControlName="389"
                    >
                    </ng-select>
                    <div class="invalid-feedback"
                       *ngIf="(PCSForms.controls['389'].touched && PCSForms.controls['389'].invalid) || (PCSForms.controls['389'].invalid && isSubmit)">
                       Reference 3 state is required and cannot be empty
                    </div>
                 </div>
                    <div class="col-3 pl-0">
                       <input type="text" placeholder="Enter Reference  Zip" id="390" class="form-control" formControlName="390"
                          [class.is-invalid]="(PCSForms.controls['390'].touched && PCSForms.controls['390'].invalid ) || (PCSForms.controls['390'].invalid && isSubmit)">
                       <div class="invalid-feedback"
                          *ngIf="(PCSForms.controls['390'].touched && PCSForms.controls['390'].invalid) || (PCSForms.controls['390'].invalid && isSubmit)">
                          Reference 3 Zip is required and cannot be empty
                       </div>
                    </div>
                 </div>
              </td>
              <td colspan="6">
                 <div class="row">
                    <div class="col-2 pr-0"> <label for="393">Specialty<span class="mandatory">*</span>:</label></div>
                    <div class="col-10 pl-0">
                       <ng-select
                       [items]="specialty"
                       [class.is-invalid]="
                       (PCSForms.controls['393'].touched &&
                       PCSForms.controls['393'].errors) ||
                       (PCSForms.controls['393'].errors && isSubmit)
                       "
                       bindLabel="name"
                       placeholder="Select Specialty"
                       bindValue="id"
                       formControlName="393"
                       >
                       </ng-select>
                       <div class="invalid-feedback"
                          *ngIf="(PCSForms.controls['393'].touched && PCSForms.controls['393'].invalid) || (PCSForms.controls['393'].invalid && isSubmit)">
                          Primary Specialty required and cannot be empty
                       </div>
                    </div>
                 </div>
                 <br>
                 <div class="row">
                  <div class="col-4 pr-0"> <label for="394">Phone Number<span class="mandatory">*</span>:</label></div>
                  <div class="col-4 pl-0">
                     <input type="text" placeholder="Enter Phone Number" id="394" class="form-control" formControlName="394"
                        [class.is-invalid]="(PCSForms.controls['394'].touched && PCSForms.controls['394'].invalid ) || (PCSForms.controls['394'].invalid && isSubmit)">
                     <div class="invalid-feedback"
                        *ngIf="(PCSForms.controls['394'].touched && PCSForms.controls['394'].invalid) || (PCSForms.controls['394'].invalid && isSubmit)">
                        Reference Phone 3 required and cannot be empty
                     </div>
                  </div>
                 </div>
                 <br>
                 <div class="row">
                    <div class="col-4 pr-0"> <label for="396">Email:</label></div>
                    <div class="col-4 pl-0">
                       <input type="text" placeholder="Enter   Email" id="396" class="form-control"
                          formControlName="396"
                          >
                       
                    </div>
                 </div>
              </td>
           </tr>
           <tr>
              <td colspan="12" class="p-0">
                 <div class="coloredTitle">
                    Education-Undergraduate
                 </div>
              </td>
           </tr>
           <td colspan="6">
              <div class="row">
                 <div class="col-5 pr-0"> <label for="503">College or University Name<span
                    class="mandatory">*</span>:</label></div>
                 <div class="col-6 pl-0">
                    <input type="text" placeholder="Enter Univerisity Name" id="503" class="form-control"
                       formControlName="503"
                       [class.is-invalid]="(PCSForms.controls['503'].touched && PCSForms.controls['503'].invalid ) || (PCSForms.controls['503'].invalid && isSubmit)">
                    <div class="invalid-feedback"
                       *ngIf="(PCSForms.controls['503'].touched && PCSForms.controls['503'].invalid) || (PCSForms.controls['503'].invalid && isSubmit)">
                       University Name is required and cannot be empty
                    </div>
                 </div>
              </div>
              <br>
              <div class="row">
                 <div class="col-4 pr-0"> <label for="504">Address<span class="mandatory">*</span>:</label></div>
                 <div class="col-4 pl-0">
                    <input type="text" placeholder="Enter Address" id="504" class="form-control" formControlName="504"
                       [class.is-invalid]="(PCSForms.controls['504'].touched && PCSForms.controls['504'].invalid ) || (PCSForms.controls['504'].invalid && isSubmit)">
                    <div class="invalid-feedback"
                       *ngIf="(PCSForms.controls['504'].touched && PCSForms.controls['504'].invalid) || (PCSForms.controls['504'].invalid && isSubmit)">
                       UG school Address 1 is required and cannot be empty
                    </div>
                 </div>
                 <div class="col-4 pl-0">
                    <input type="text" placeholder="Enter Address" id="505" class="form-control" formControlName="505"
                       [class.is-invalid]="(PCSForms.controls['505'].touched && PCSForms.controls['505'].invalid ) || (PCSForms.controls['505'].invalid && isSubmit)">
                    <div class="invalid-feedback"
                       *ngIf="(PCSForms.controls['505'].touched && PCSForms.controls['505'].invalid) || (PCSForms.controls['505'].invalid && isSubmit)">
                       UG school Address 2 is required and cannot be empty
                    </div>
                 </div>
              </div>
              <div class="row">
                 <div class="col-3 pr-0"></div>
                 <div class="col-3 pl-0">
                    <input type="text" placeholder="Enter City" id="506" class="form-control" formControlName="506"
                       [class.is-invalid]="(PCSForms.controls['506'].touched && PCSForms.controls['506'].invalid ) || (PCSForms.controls['506'].invalid && isSubmit)">
                    <div class="invalid-feedback"
                       *ngIf="(PCSForms.controls['506'].touched && PCSForms.controls['506'].invalid) || (PCSForms.controls['506'].invalid && isSubmit)">
                       UG school City is required and cannot be empty
                    </div>
                 </div>
                 <div class="col-3 pl-0">
                  <ng-select
                  [items]="states"
                  [class.is-invalid]="(PCSForms.controls['508'].touched &&
                  PCSForms.controls['508'].invalid) ||
                  (PCSForms.controls['508'].invalid && isSubmit)"
                  bindLabel="name"
                  placeholder="Enter State"
                  bindValue="id"
                  formControlName="508"
                  >
                  </ng-select>
                  <div class="invalid-feedback"
                     *ngIf="(PCSForms.controls['508'].touched && PCSForms.controls['508'].invalid) || (PCSForms.controls['508'].invalid && isSubmit)">
                     UG school state is required and cannot be empty
                  </div>
               </div>
                 <div class="col-3 pl-0">
                    <input type="text" placeholder="Enter Zip" id="509" class="form-control" formControlName="509"
                       [class.is-invalid]="(PCSForms.controls['509'].touched && PCSForms.controls['509'].invalid ) || (PCSForms.controls['509'].invalid && isSubmit)">
                    <div class="invalid-feedback"
                       *ngIf="(PCSForms.controls['509'].touched && PCSForms.controls['509'].invalid) || (PCSForms.controls['509'].invalid && isSubmit)">
                       UG school Zip is required and cannot be empty
                    </div>
                 </div>
              </div>
           </td>
           <td colspan="6">
              <div class="form-inline mb-1">
                 <div class="col-4 pl-0"> <label for="512">Degree Received<span class="mandatory">*</span>:</label></div>
                 <div class="col-4 pl-0">
                    <!-- <input type="text" placeholder="Enter Degree" id="512" class="form-control" formControlName="512" -->
                    <!-- [class.is-invalid]="(PCSForms.controls['512'].touched && PCSForms.controls['512'].invalid ) || (PCSForms.controls['512'].invalid && isSubmit)"> -->
                    <ng-select [labelForId]="'512'" [items]="degree" [class.is-invalid]="
                    (PCSForms.controls['512'].touched &&
                    PCSForms.controls['512'].invalid) ||
                    (PCSForms.controls['512'].invalid && isSubmit)" bindLabel="name" placeholder="Select Degree"
                    bindValue="id" formControlName="512">
                    </ng-select>
                    <div class="invalid-feedback"
                       *ngIf="(PCSForms.controls['512'].touched && PCSForms.controls['512'].invalid) || (PCSForms.controls['512'].invalid && isSubmit)">
                       Degree is required and cannot be empty
                    </div>
                 </div>
              </div>
              <div class="form-inline mb-1">
                 <div class="col-6 pl-0"> <label for="511">Date of Graduation(mm/dd/yyyy)<span
                    class="mandatory">*</span>:</label></div>
                    <div class="col-6">
                     <div class="input-group">
                    <input type="text" placeholder="Enter Date of Graduation" id="511" class="form-control"
                       formControlName="511"  (blur)="clearInputIfInvalidMandi('511')"
                       [class.is-invalid]="(PCSForms.controls['511'].touched && PCSForms.controls['511'].invalid ) || (PCSForms.controls['511'].invalid && isSubmit)" ngbDatepicker #d511="ngbDatepicker">
                       <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d511.toggle()" type="button">
                          <i class="fas fa-calendar-alt"></i>
                        </button>
                      </div>
                    </div>
                    <div class="text-danger" *ngIf="((PCSForms.controls['511'].invalid&&PCSForms.controls['511'].touched) && (PCSForms.controls['511'].value==''|| PCSForms.controls['511'].value==null) || ((PCSForms.controls['511'].invalid && isSubmit) && (PCSForms.controls['511'].value==''|| PCSForms.controls['511'].value==null)) ) " >
                     <small >
                        
                        Date of Graduation is required and cannot be empty
                     </small>
                   
                   </div>
                   <small class="text-danger" *ngIf="PCSForms.get('511').errors?.invalidDateFormat">
                     Only Date Format is allowed(MM/DD/YYYY)                </small>
                   <small class="text-danger" *ngIf="PCSForms.get('511').errors?.invalidMonth">
                   Month is out of range
                   </small>
                   <small class="text-danger" *ngIf="PCSForms.get('511').errors?.invalidDateyear">
                     year is out of range(1900-2099)                  </small>
                     <small class="text-danger" *ngIf="PCSForms.get('511').errors?.invalidDateRange">
                       date is out of range
                       </small>
                       <!-- <div class="invalid-feedback"
                       *ngIf="(PCSForms.controls['511'].touched && PCSForms.controls['511'].invalid) || (PCSForms.controls['511'].invalid && isSubmit)">
                       Date of Graduation is required and cannot be empty
                    </div> -->
                 </div>
              </div>
           </td>
           <tr>
              <td colspan="12" class="p-0">
                 <div class="coloredTitle">
                    Education-Medical/Professional
                 </div>
              </td>
           </tr>
           
           <td colspan="6">
              <div class="row">
                 <div class="col-5 pr-0"> <label for="490">Medical/Professional School<span
                    class="mandatory">*</span>:</label></div>
                 <div class="col-6 pl-0">
                    <input type="text" placeholder="Enter Medical/Professional School" id="490" class="form-control"
                       formControlName="490"
                       [class.is-invalid]="(PCSForms.controls['490'].touched && PCSForms.controls['490'].invalid ) || (PCSForms.controls['490'].invalid && isSubmit)">
                    <div class="invalid-feedback"
                       *ngIf="(PCSForms.controls['490'].touched && PCSForms.controls['490'].invalid) || (PCSForms.controls['490'].invalid && isSubmit)">
                       Medical school is required and cannot be empty
                    </div>
                 </div>
              </div>
              <br>
              <div class="row">
                 <div class="col-4 pr-0"> <label for="491">Address<span class="mandatory">*</span>:</label></div>
                 <div class="col-4 pl-0">
                    <input type="text" placeholder="Enter  Address" id="491" class="form-control" formControlName="491"
                       [class.is-invalid]="(PCSForms.controls['491'].touched && PCSForms.controls['491'].invalid ) || (PCSForms.controls['491'].invalid && isSubmit)">
                    <div class="invalid-feedback"
                       *ngIf="(PCSForms.controls['491'].touched && PCSForms.controls['491'].invalid) || (PCSForms.controls['491'].invalid && isSubmit)">
                       Med school Address 1 is required and cannot be empty
                    </div>
                 </div>
                 <div class="col-4 pl-0">
                    <input type="text" placeholder="Enter  Address" id="492" class="form-control" formControlName="492"
                       [class.is-invalid]="(PCSForms.controls['492'].touched && PCSForms.controls['492'].invalid ) || (PCSForms.controls['492'].invalid && isSubmit)">
                    <div class="invalid-feedback"
                       *ngIf="(PCSForms.controls['492'].touched && PCSForms.controls['492'].invalid) || (PCSForms.controls['492'].invalid && isSubmit)">
                       Med school Address 2 is required and cannot be empty
                    </div>
                 </div>
              </div>
              <div class="row">
                 <div class="col-3 pr-0"></div>
                 <div class="col-3 pl-0">
                    <input type="text" placeholder="Enter  City" id="493" class="form-control" formControlName="493"
                       [class.is-invalid]="(PCSForms.controls['493'].touched && PCSForms.controls['493'].invalid ) || (PCSForms.controls['493'].invalid && isSubmit)">
                    <div class="invalid-feedback"
                       *ngIf="(PCSForms.controls['493'].touched && PCSForms.controls['493'].invalid) || (PCSForms.controls['493'].invalid && isSubmit)">
                       Med school City is required and cannot be empty
                    </div>
                 </div>
                 <div class="col-3 pl-0">
                  <ng-select
                  [items]="states"
                  [class.is-invalid]="(PCSForms.controls['495'].touched &&
                  PCSForms.controls['495'].invalid) ||
                  (PCSForms.controls['495'].invalid && isSubmit)"
                  bindLabel="name"
                  placeholder="Enter State"
                  bindValue="id"
                  formControlName="495"
                  >
                  </ng-select>
                  <div class="invalid-feedback"
                     *ngIf="(PCSForms.controls['495'].touched && PCSForms.controls['495'].invalid) || (PCSForms.controls['495'].invalid && isSubmit)">
                     Med school state is required and cannot be empty
                  </div>
               </div>
                 <div class="col-3 pl-0">
                    <input type="text" placeholder="Enter  Zip" id="496" class="form-control" formControlName="496"
                       [class.is-invalid]="(PCSForms.controls['496'].touched && PCSForms.controls['390'].invalid ) || (PCSForms.controls['496'].invalid && isSubmit)">
                    <div class="invalid-feedback"
                       *ngIf="(PCSForms.controls['496'].touched && PCSForms.controls['496'].invalid) || (PCSForms.controls['496'].invalid && isSubmit)">
                       Med school Zip is required and cannot be empty
                    </div>
                 </div>
              </div>
           </td>
           <td colspan="6">
              <div class="form-inline mb-1">
                 <div class="col-4 pl-0"> <label for="500">Degree Received<span class="mandatory">*</span>:</label></div>
                 <div class="col-4 pl-0">
                    <!-- <input type="text" placeholder="Enter Degree " id="500" class="form-control" formControlName="500" -->
                    <!-- [class.is-invalid]="(PCSForms.controls['500'].touched && PCSForms.controls['500'].invalid ) || (PCSForms.controls['500'].invalid && isSubmit)"> -->
                    <ng-select [labelForId]="'500'" [items]="degree" [class.is-invalid]="
                    (PCSForms.controls['500'].touched &&
                    PCSForms.controls['500'].invalid) ||
                    (PCSForms.controls['500'].invalid && isSubmit)" bindLabel="name" placeholder="Select Degree"
                    bindValue="id" formControlName="500">
                    </ng-select>
                    <div class="invalid-feedback"
                       *ngIf="(PCSForms.controls['500'].touched && PCSForms.controls['500'].invalid) || (PCSForms.controls['500'].invalid && isSubmit)">
                       Degree is required and cannot be empty
                    </div>
                 </div>
              </div>
              <div class="form-inline mb-1">
                 <div class="col-6"> <label for="499">Date of Graduation(mm/dd/yyyy)<span
                    class="mandatory">*</span>:</label></div>
                    <div class="col-6">
                     <div class="input-group">
                    <input type="text" placeholder="Enter Date of Graduation" id="499" class="form-control"
                       formControlName="499"  (blur)="clearInputIfInvalidMandi('499')"
                       [class.is-invalid]="(PCSForms.controls['499'].touched && PCSForms.controls['499'].invalid ) || (PCSForms.controls['499'].invalid && isSubmit)" ngbDatepicker #d499="ngbDatepicker">
                       <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d499.toggle()" type="button">
                          <i class="fas fa-calendar-alt"></i>
                        </button>
                      </div>
                    </div>

                    <div class="text-danger" *ngIf="((PCSForms.controls['499'].invalid&&PCSForms.controls['499'].touched) && (PCSForms.controls['499'].value==''|| PCSForms.controls['499'].value==null) || ((PCSForms.controls['499'].invalid && isSubmit) && (PCSForms.controls['499'].value==''|| PCSForms.controls['499'].value==null)) ) " >
                     <small >
                        
                        Date of Graduation is required and cannot be empty
                     </small>
                   
                   </div>
                   <small class="text-danger" *ngIf="PCSForms.get('499').errors?.invalidDateFormat">
                     Only Date Format is allowed(MM/DD/YYYY)                </small>
                   <small class="text-danger" *ngIf="PCSForms.get('499').errors?.invalidMonth">
                   Month is out of range
                   </small>
                   <small class="text-danger" *ngIf="PCSForms.get('499').errors?.invalidDateyear">
                     year is out of range(1900-2099)                  </small>
                     <small class="text-danger" *ngIf="PCSForms.get('499').errors?.invalidDateRange">
                       date is out of range
                       </small>
                       <!-- <div class="invalid-feedback"
                       *ngIf="(PCSForms.controls['499'].touched && PCSForms.controls['499'].invalid) || (PCSForms.controls['499'].invalid && isSubmit)">
                       Date of Graduation is required and cannot be empty
                    </div> -->
                 </div>
              </div>
           </td>
           <tr>
               <td colspan="10" class="p-0">
                  <div class="coloredTitle">
                     Education-Internship
                  </div>   
               </td>
               <td colspan="2" class="p-0">
                  <div class="form-check">
                     <input formControlName="cmd_NA_02" (ngModelChange)="handleNAchange('cmd_NA_02')" class="form-check-input" type="checkbox" value="1" id="cmd_NA_02">
                        <label class="form-check-label" for="cmd_NA_02">
                                 <b>Does Not Apply</b>
                        </label>
                            
                  </div>      
                     
                    
               </td>
           </tr>
           <td colspan="6">
               <div class="row">
                  <div class="col-5 pr-0"> <label for="517">Institution for Internship:</label><span *ngIf="PCSForms.controls['517'].invalid" class="mandatory">*</span></div>
                  <div class="col-6 pl-0">
                     <input type="text" (blur)="handleNAchange('cmd_NA_02',517)" [class.is-invalid]="PCSForms.controls['517'].invalid" placeholder="Enter Institution for Internship" id="517" class="form-control"
                       formControlName="517"
                       >
                     <div class="mandatory"
                       *ngIf="PCSForms.controls['517'].invalid">
                        <small >
                         required
                        </small>
                     </div>    
                    
                 </div>
              </div>
              <br>
              <div class="row">
                  <div class="col-4 pr-0"> <label for="518">Address:</label><span *ngIf="PCSForms.controls['518'].invalid" class="mandatory">*</span></div>
                  <div class="col-4 pl-0">
                     <input type="text" (blur)="handleNAchange('cmd_NA_02',518)" [class.is-invalid]="PCSForms.controls['518'].invalid" placeholder="Enter Address" id="518" class="form-control" formControlName="518"
                       >
                     <div class="mandatory"
                       *ngIf="PCSForms.controls['518'].invalid">
                       <small >
                         required
                       </small>
                     </div>    

                    
                  </div>
                  <div class="col-4 pl-0">
                     <input type="text" (blur)="handleNAchange('cmd_NA_02',519)" [class.is-invalid]="PCSForms.controls['519'].invalid" placeholder="Enter Address" id="519" class="form-control" formControlName="519">
                     <div class="mandatory"
                       *ngIf="PCSForms.controls['519'].invalid">
                       <small >
                         required
                       </small>
                     </div>    
                  </div>
              </div>
              <div class="row">
                 <div class="col-3 pr-0"></div>
                 <div class="col-3 pl-0">
                     <input type="text" (blur)="handleNAchange('cmd_NA_02',520)" [class.is-invalid]="PCSForms.controls['520'].invalid" placeholder="Enter City" id="520" class="form-control" formControlName="520"
                       >
                     <div class="mandatory"
                       *ngIf="PCSForms.controls['520'].invalid">
                       <small >
                         required
                       </small>
                     </div>    

                    
                 </div>
                  <div class="col-3 pl-0">
                     <ng-select (blur)="handleNAchange('cmd_NA_02')" [class.is-invalid]="PCSForms.controls['522'].invalid"
                     [items]="states"
                     
                     bindLabel="name"
                     placeholder="Enter State"
                     bindValue="id"
                     formControlName="522"
                     >
                     </ng-select>
                     <div class="mandatory"
                              *ngIf=" PCSForms.controls['522'].invalid">
                              <small>
                              required
                              </small>
                     </div>
                  </div>
                  <div class="col-3 pl-0">
                     <input type="text" (blur)="handleNAchange('cmd_NA_02',523)" [class.is-invalid]="PCSForms.controls['523'].invalid" placeholder="Enter Zip" id="523" class="form-control" formControlName="523"
                       >
                       <div class="mandatory"
                       *ngIf="PCSForms.controls['523'].invalid">
                       <small >
                         required
                       </small>
                     </div>    

                    
                 </div>
              </div>
           </td>
           <td colspan="6">
              <div class="form-inline mb-1">
                 <div class="col-4 pr-0"> <label for="528">Program Director:<span *ngIf="PCSForms.controls['528'].invalid" class="mandatory">*</span></label></div>
                 <div class="col-4 pl-0">
                     <input type="text" (blur)="handleNAchange('cmd_NA_02',528)" [class.is-invalid]="PCSForms.controls['528'].invalid" placeholder="Enter Program Director" id="528" class="form-control"
                       formControlName="528"
                       >
                     <div class="mandatory"
                       *ngIf="PCSForms.controls['528'].invalid">
                       <small >
                         required
                       </small>
                     </div>  
                    
                 </div>
              </div>
              <div class="row">
               <div class="col-3"> <label for="524">From (mm/yyyy):</label><span *ngIf="PCSForms.controls['524'].invalid" class="mandatory">*</span></div>
               <div class="col-3">
                  <div class="input-group">
                    <input type="text" (blur)="handleNAchange('cmd_NA_02',524)" (blur)="clearInputIfInvalid('524','cmd_NA_02')" [class.is-invalid]="PCSForms.controls['524'].invalid" placeholder="Enter From Date" id="524" class="form-control" formControlName="524"
                    ngbDatepicker #d524="ngbDatepicker">
                    <div class="input-group-append">
                     <button class="btn btn-outline-secondary calendar" (click)="d524.toggle()" type="button">
                       <i class="fas fa-calendar-alt"></i>
                     </button>
                   </div>
                 </div>
                 <div class="text-danger" *ngIf="(PCSForms.controls['524'].invalid&&PCSForms.controls['524'].touched) && (PCSForms.controls['524'].value==''|| PCSForms.controls['524'].value==null) " >
                  <small >
                  required
                  </small>

              </div>
                <small class="text-danger" *ngIf="PCSForms.get('524').errors?.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY)                </small>
                <small class="text-danger" *ngIf="PCSForms.get('524').errors?.invalidMonth">
                Month is out of range
                </small>
                <small class="text-danger" *ngIf="PCSForms.get('524').errors?.invalidDateyear">
                  year is out of range(1900-2099)                  </small>
                  <small class="text-danger" *ngIf="PCSForms.get('524').errors?.invalidDateRange">
                    date is out of range
                    </small>
                 </div>
                 <div class="col-3"> <label for="525">To (mm/yyyy):</label><span *ngIf="PCSForms.controls['525'].invalid" class="mandatory">*</span></div>
                 <div class="col-3">
                  <div class="input-group">
                    <input type="text" (blur)="handleNAchange('cmd_NA_02',525)" (blur)="clearInputIfInvalid('525','cmd_NA_02')" [class.is-invalid]="PCSForms.controls['525'].invalid" placeholder="Enter To Date" id="525" class="form-control" formControlName="525"
                    ngbDatepicker #d525="ngbDatepicker">
                    <div class="input-group-append">
                     <button class="btn btn-outline-secondary calendar" (click)="d525.toggle()" type="button">
                       <i class="fas fa-calendar-alt"></i>
                     </button>
                   </div>
                 </div>
                 <div class="text-danger" *ngIf="(PCSForms.controls['525'].invalid&&PCSForms.controls['525'].touched) && (PCSForms.controls['525'].value==''|| PCSForms.controls['525'].value==null) " >
                  <small >
                  required
                  </small>

              </div>
                <small class="text-danger" *ngIf="PCSForms.get('525').errors?.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY)                </small>
                <small class="text-danger" *ngIf="PCSForms.get('525').errors?.invalidMonth">
                Month is out of range
                </small>
                <small class="text-danger" *ngIf="PCSForms.get('525').errors?.invalidDateyear">
                  year is out of range(1900-2099)                  </small>
                  <small class="text-danger" *ngIf="PCSForms.get('525').errors?.invalidDateRange">
                    date is out of range
                    </small>
                 </div>
              </div>
              <div class="row">
                 <div class="col-2 pr-0"> <label for="2539">Type of Training:<span *ngIf="PCSForms.controls['2539'].invalid" class="mandatory">*</span></label></div>
                 <div class="col-3 pl-0">
                    <input type="text" (blur)="handleNAchange('cmd_NA_02',2539)" [class.is-invalid]="PCSForms.controls['2539'].invalid" placeholder="Enter Type of Training" id="2539" class="form-control"
                       formControlName="2539"
                       >
                        <div class="mandatory"
                           *ngIf="PCSForms.controls['2539'].invalid">
                           <small >
                              required
                           </small>
                        </div>  
                 </div>
                 <div class="col-2 pr-0"> <label for="527">Specialty:<span *ngIf="PCSForms.controls['527'].invalid" class="mandatory">*</span></label></div>
                 <div class="col-5 pl-0">
                    <!-- <input type="text" placeholder="Enter Specialty" id="527" class="form-control" formControlName="527" -->
                    <!-- [class.is-invalid]="(PCSForms.controls['527'].touched && PCSForms.controls['527'].invalid ) || (PCSForms.controls['527'].invalid && isSubmit)"> -->
                    <ng-select (blur)="handleNAchange('cmd_NA_02')" [class.is-invalid]="PCSForms.controls['527'].invalid" 
                    [items]="specialty"
                    
                    bindLabel="name"
                    placeholder="Select Specialty"
                    bindValue="id"
                    formControlName="527"
                    >
                    </ng-select>
                     <div class="mandatory"
                           *ngIf=" PCSForms.controls['527'].invalid">
                           <small>
                           required
                           </small>
                     </div>
                 </div>
              </div>
           </td>
           <tr>
              <td colspan="12">
                 <div class="form-inline mb-1">
                    <label for="cmd_1149">Did you successfully complete the program?<span *ngIf="PCSForms.controls['cmd_1149'].invalid" class="mandatory">*</span></label>&nbsp;&nbsp;
                   <label><input (blur)="handleNAchange('cmd_NA_02','cmd_1149')" [class.is-invalid]="PCSForms.controls['cmd_1149'].invalid"
                       type="radio"
                       id="cmd_1149"
                       name="cmd_1149"
                       formControlName="cmd_1149"
                       value="Yes"
                       />Yes</label>&nbsp;&nbsp;

                    <label><input
                       type="radio"
                       id="cmd_1149"
                       name="cmd_1149"
                       formControlName="cmd_1149"
                       value="No"
                       />No</label>&nbsp;&nbsp;<span>(If NO, please explain; attach additional sheets if
                    necessary)</span>
                    
                 
                 <div class="mandatory"
                  *ngIf="PCSForms.controls['cmd_1149'].invalid">
                  <small >
                    required
                  </small>
                </div></div>
              </td>
           </tr>
            <tr>
               <td colspan="10" class="p-0">
                 <div class="coloredTitle">
                    Education-Residency
                 </div>
               </td>
               <td colspan="2" class="p-0">
                  <div class="form-check">
                     <input formControlName="cmd_NA_03" (ngModelChange)="handleNAchange('cmd_NA_03')" class="form-check-input" type="checkbox" value="1" id="cmd_NA_03">
                        <label class="form-check-label" for="cmd_NA_03">
                                 <b>Does Not Apply</b>
                        </label>
                           
                  </div>      
               </td>
            </tr>
           <td colspan="6">
              <div class="row">
                 <div class="col-5 pr-0"> <label for="529">Institution for Residency:</label><span *ngIf="PCSForms.controls['529'].invalid" class="mandatory">*</span>
                 </div>
                 <div class="col-6 pl-0">
                    <input type="text" (blur)="handleNAchange('cmd_NA_03',529)" [class.is-invalid]="PCSForms.controls['529'].invalid" placeholder="Enter Residency" id="529" class="form-control" formControlName="529"
                       >
                       <div class="mandatory"
                           *ngIf="PCSForms.controls['529'].invalid">
                           <small >
                              required
                           </small>
                     </div>  
                 </div>
              </div>
              <br>
              <div class="row">
                 <div class="col-4 pr-0"> <label for="531">Address:</label></div>
                 <div class="col-4 pl-0">
                    <input type="text"  placeholder="Enter Address" id="531" class="form-control" formControlName="531"
                    >
                     
                 </div>
                 <div class="col-4 pl-0">
                    <input type="text" placeholder="Enter Address" id="1498" class="form-control" formControlName="1498"
                    >
                     
                 </div>
              </div>
              <div class="row">
                 <div class="col-3 pr-0"></div>
                 <div class="col-3 pl-0">
                    <input type="text" placeholder="Enter City" id="533" class="form-control" formControlName="533"
                    >
                     
                 </div>
                 <div class="col-3 pl-0">
                  <ng-select
                  [items]="states"
                  bindLabel="name"
                  placeholder="Enter State"
                  bindValue="id"
                  formControlName="535"
                  >
                  </ng-select>
                  
               </div>
                 <div class="col-3 pl-0">
                    <input type="text" placeholder="Enter Zip" id="536" class="form-control" formControlName="536"
                    >
                       
                 </div>
              </div>
           </td>

           <td colspan="6">
              <div class="form-inline mb-1">
                 <div class="col-6 pr-0"> <label for="540">Program Director:<span *ngIf="PCSForms.controls['540'].invalid" class="mandatory">*</span></label></div>
                 <div class="col-4 pl-0">
                     <input type="text" placeholder="Enter Program Director" id="540" class="form-control"
                       formControlName="540"
                       (blur)="handleNAchange('cmd_NA_03',540)" [class.is-invalid]="PCSForms.controls['540'].invalid">
                        <div class="mandatory"
                           *ngIf="PCSForms.controls['540'].invalid">
                           <small >
                              required
                           </small>
                        </div>   
                 </div>
              </div>
              <div class="row">
                 <div class="col-3"> <label for="537">From (mm/yyyy):</label><span *ngIf="PCSForms.controls['537'].invalid" class="mandatory">*</span></div>
                 <div class="col-3">
                  <!-- <div class="input-group">
                    <input type="text" placeholder="Enter From Date" id="537" class="form-control" formControlName="537"
                    (blur)="handleNAchange('cmd_NA_03',537)" [class.is-invalid]="PCSForms.controls['537'].invalid"  ngbDatepicker #d537="ngbDatepicker">
                    <div class="input-group-append">
                     <button class="btn btn-outline-secondary calendar" (click)="d537.toggle()" type="button">
                       <i class="fas fa-calendar-alt"></i>
                     </button>
                   </div>(returnValue)="returnval($event)
                 </div>     -->
                 <ctg-month-mode 
                [initialVal]="PCSForms.controls[537]&&PCSForms.controls[537].value" (blur)="handleNAchange('cmd_NA_03',537)" (blur)="clearInputIfInvalidMonth('537','cmd_NA_03')"
                (returnValue)="PCSForms.controls[537].setValue($event)"></ctg-month-mode>
                <div class="text-danger" *ngIf="(PCSForms.controls['537'].invalid&&PCSForms.controls['537'].touched) && (PCSForms.controls['537'].value==''|| PCSForms.controls['537'].value==null) " >
                  <small >
                  required
                  </small>

              </div>
                <small class="text-danger" *ngIf="PCSForms.get('537').errors?.invalidDateFormat">
                  Only Date Format is allowed(MM/YYYY)                </small>
                <small class="text-danger" *ngIf="PCSForms.get('537').errors?.invalidMonth">
                Month is out of range
                </small>
                <small class="text-danger" *ngIf="PCSForms.get('537').errors?.invalidDateyear">
                  year is out of range(1900-2099)                  </small>
                  <small class="text-danger" *ngIf="PCSForms.get('537').errors?.invalidDateRange">
                    date is out of range
                    </small>
                    <div class="mandatory"
                           *ngIf="PCSForms.controls['537'].invalid">
                           <small >
                              required
                           </small>
                        </div> 
                 </div>
                 <div class="col-3"> <label for="538">To (mm/yyyy):</label><span *ngIf="PCSForms.controls['538'].invalid" class="mandatory">*</span></div>
                 <div class="col-3">
                  <!-- <div class="input-group">
                    <input type="text" placeholder="Enter To Date" id="538" class="form-control" formControlName="538"
                    (blur)="handleNAchange('cmd_NA_03',538)" [class.is-invalid]="PCSForms.controls['538'].invalid"  ngbDatepicker #d538="ngbDatepicker">
                    <div class="input-group-append">
                     <button class="btn btn-outline-secondary calendar" (click)="d538.toggle()" type="button">
                       <i class="fas fa-calendar-alt"></i>
                     </button>
                   </div> -->
                   <ctg-month-mode 
                   [initialVal]="PCSForms.controls[538]&&PCSForms.controls[538].value" (blur)="handleNAchange('cmd_NA_03',538)" (blur)="clearInputIfInvalidMonth('538','cmd_NA_03')"
                   (returnValue)="PCSForms.controls[538].setValue($event)"></ctg-month-mode>
                   <div class="text-danger" *ngIf="(PCSForms.controls['538'].invalid&&PCSForms.controls['538'].touched) && (PCSForms.controls['538'].value==''|| PCSForms.controls['538'].value==null) " >
                     <small >
                     required
                     </small>
                 </div>
                   <small class="text-danger" *ngIf="PCSForms.get('538').errors?.invalidDateFormat">
                     Only Date Format is allowed(MM/YYYY)                </small>
                   <small class="text-danger" *ngIf="PCSForms.get('538').errors?.invalidMonth">
                   Month is out of range
                   </small>
                   <small class="text-danger" *ngIf="PCSForms.get('538').errors?.invalidDateyear">
                     year is out of range(1900-2099)                  </small>
                     <small class="text-danger" *ngIf="PCSForms.get('538').errors?.invalidDateRange">
                       date is out of range
                       </small>  
                 <!-- </div>     -->
                    <div class="mandatory"
                           *ngIf="PCSForms.controls['538'].invalid">
                           <small >
                              required
                           </small>
                        </div>   
                 </div>
              </div>
              <div class="row">
                 <div class="col-2 pr-0"> <label for="2540">Type of Training:<span *ngIf="PCSForms.controls['2540'].invalid" class="mandatory">*</span></label></div>
                 <div class="col-3 pl-0">
                    <input type="text" placeholder="Enter Type of Training" id="2540" class="form-control"
                       formControlName="2540" (blur)="handleNAchange('cmd_NA_03',2540)" [class.is-invalid]="PCSForms.controls['2540'].invalid"
                       >
                        <div class="mandatory"
                           *ngIf="PCSForms.controls['2540'].invalid">
                           <small >
                              required
                           </small>
                        </div>   
                 </div>
                 <div class="col-2 pr-0"> <label for="530">Specialty:<span *ngIf="PCSForms.controls['530'].invalid" class="mandatory">*</span></label></div>
                 <div class="col-5 pl-0">
                    <!-- <input type="text" placeholder="Enter Specialty" id="530" class="form-control" formControlName="530"
                       [class.is-invalid]="(PCSForms.controls['530'].touched && PCSForms.controls['530'].invalid ) || (PCSForms.controls['530'].invalid && isSubmit)"> -->
                    <ng-select
                    [items]="specialty"
                    (blur)="handleNAchange('cmd_NA_03')" [class.is-invalid]="PCSForms.controls['530'].invalid"
                    bindLabel="name"
                    placeholder="Select Specialty"
                    bindValue="id"
                    formControlName="530"
                    >
                    </ng-select>
                     <div class="mandatory"
                           *ngIf=" PCSForms.controls['530'].invalid">
                           <small>
                           required
                           </small>
                     </div>
                 </div>
              </div>
           </td>
           <tr>
              <td colspan="12">
                 <div class="form-inline mb-1">
                    <label for="cmd_1147">Did you successfully complete the program?<span *ngIf="PCSForms.controls['cmd_1147'].invalid" class="mandatory">*</span></label>&nbsp;&nbsp;
                    <input (blur)="handleNAchange('cmd_NA_03','cmd_1147')" [class.is-invalid]="PCSForms.controls['cmd_1147'].invalid"
                       type="radio"
                       id="cmd_1147"
                       name="cmd_1147"
                       formControlName="cmd_1147"
                       value="Yes"
                       />Yes&nbsp;&nbsp;
                    <input
                       type="radio"
                       id="cmd_1147"
                       name="cmd_1147"
                       formControlName="cmd_1147"
                       value="No"
                       />No&nbsp;&nbsp;<span>(If NO, please explain; attach additional sheets if
                    necessary)</span>
                    <div class="mandatory"
                    *ngIf="PCSForms.controls['cmd_1147'].invalid">
                    <small >
                      required
                    </small>
                  </div></div>
              </td>
           </tr>
           <tr>
              <td colspan="10" class="p-0">
                  <div class="coloredTitle">
                     Education-Fellowship
                  </div>
              </td>   
                  <td colspan="2" class="p-0">
                     <div class="form-check">
                        <input formControlName="cmd_NA_04" (ngModelChange)="handleNAchange('cmd_NA_04')" class="form-check-input" type="checkbox" value="1" id="cmd_NA_04">
                           <label class="form-check-label" for="cmd_NA_04">
                                    <b>Does Not Apply</b>
                           </label>
                     </div>      
                  </td>
            </tr>
           <td colspan="6">
              <div class="row">
                 <div class="col-5 pr-0"> <label for="542">Institution for Fellowship:</label><span *ngIf="PCSForms.controls['542'].invalid" class="mandatory">*</span></div>
                 <div class="col-6 pl-0">
                    <input type="text" (blur)="handleNAchange('cmd_NA_04',542)" [class.is-invalid]="PCSForms.controls['542'].invalid" placeholder="Enter  Fellowship" id="542" class="form-control" formControlName="542">
                    <div class="mandatory"
                    *ngIf="PCSForms.controls['542'].invalid">
                    <small >
                      required
                    </small>
                  </div>  
                  </div>
              </div>
              <br>
              <div class="row">
                 <div class="col-4 pr-0"> <label for="544">Address:</label></div>
                 <div class="col-4 pl-0">
                    <input type="text" placeholder="Enter Address" (blur)="handleNAchange('cmd_NA_04',544)" [class.is-invalid]="PCSForms.controls['544'].invalid" id="544" class="form-control" formControlName="544">
                    <div class="mandatory"
                    *ngIf="PCSForms.controls['544'].invalid">
                    <small >
                      required
                    </small>
                  </div>  
                 </div>
                 <div class="col-4 pl-0">
                    <input type="text" placeholder="Enter Address" (blur)="handleNAchange('cmd_NA_04',545)" [class.is-invalid]="PCSForms.controls['545'].invalid" id="545" class="form-control" formControlName="545">
                    <div class="mandatory"
                    *ngIf="PCSForms.controls['545'].invalid">
                    <small >
                      required
                    </small>
                  </div>  
                 </div>
              </div>
              <div class="row">
                 <div class="col-3 pr-0"></div>
                 <div class="col-3 pl-0">
                    <input type="text" (blur)="handleNAchange('cmd_NA_04',546)" [class.is-invalid]="PCSForms.controls['546'].invalid" placeholder="Enter City " id="546" class="form-control" formControlName="546">
                    <div class="mandatory"
                     *ngIf="PCSForms.controls['546'].invalid">
                     <small >
                        required
                     </small>
                     </div>  
                  </div>
                 <div class="col-3 pl-0">
                  <ng-select (blur)="handleNAchange('cmd_NA_04')" [class.is-invalid]="PCSForms.controls['548'].invalid"
                  [items]="states"
                  bindLabel="name"
                  placeholder="Enter State"
                  bindValue="id"
                  formControlName="548"
                  >
                  </ng-select>
                  <div class="mandatory"
                           *ngIf=" PCSForms.controls['548'].invalid">
                           <small>
                           required
                           </small>
                  </div>
               </div>
                 <div class="col-3 pl-0">
                    <input type="text" (blur)="handleNAchange('cmd_NA_04',549)" [class.is-invalid]="PCSForms.controls['549'].invalid" placeholder="Enter Zip" id="549" class="form-control" formControlName="549">
                     <div class="mandatory"
                        *ngIf="PCSForms.controls['549'].invalid">
                        <small >
                           required
                        </small>
                     </div>  
                  </div>
              </div>
           </td>
           <td colspan="6">
              <div class="form-inline mb-1">
                 <div class="col-4 pr-0"> <label for="553">Program Director:<span *ngIf="PCSForms.controls['553'].invalid" class="mandatory">*</span></label></div>
                 <div class="col-4 pl-0">
                    <input type="text" (blur)="handleNAchange('cmd_NA_04',553)" [class.is-invalid]="PCSForms.controls['553'].invalid" placeholder="Enter Program Director" id="553" class="form-control"
                       formControlName="553">
                       <div class="mandatory"
                       *ngIf="PCSForms.controls['553'].invalid">
                       <small >
                          required
                       </small>
                    </div>    
                 </div>
              </div>
              <div class="row">
                 <div class="col-3"> <label for="550">From (mm/yyyy) :</label><span *ngIf="PCSForms.controls['550'].invalid" class="mandatory">*</span></div>
                 <div class="col-3">
                  <div class="input-group">
                    <input type="text" (blur)="handleNAchange('cmd_NA_04',550)" (blur)="clearInputIfInvalid('550','cmd_NA_04')" [class.is-invalid]="PCSForms.controls['550'].invalid" placeholder="Enter From Date" id="550" class="form-control" formControlName="550" ngbDatepicker #d550="ngbDatepicker">
                    <div class="input-group-append">
                     <button class="btn btn-outline-secondary calendar" (click)="d550.toggle()" type="button">
                       <i class="fas fa-calendar-alt"></i>
                     </button>
                   </div>
                 </div>
                 <div class="text-danger" *ngIf="(PCSForms.controls['550'].invalid&&PCSForms.controls['550'].touched) && (PCSForms.controls['550'].value==''|| PCSForms.controls['550'].value==null) " >
                  <small >
                  required
                  </small>

              </div>
                <small class="text-danger" *ngIf="PCSForms.get('550').errors?.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY)                </small>
                <small class="text-danger" *ngIf="PCSForms.get('550').errors?.invalidMonth">
                Month is out of range
                </small>
                <small class="text-danger" *ngIf="PCSForms.get('550').errors?.invalidDateyear">
                  year is out of range(1900-2099)                  </small>
                  <small class="text-danger" *ngIf="PCSForms.get('550').errors?.invalidDateRange">
                    date is out of range
                    </small> 
                 </div>
                 <div class="col-3"> <label for="551">To (mm/yyyy) :</label><span *ngIf="PCSForms.controls['551'].invalid" class="mandatory">*</span></div>
                 <div class="col-3">
                  <div class="input-group">
                    <input type="text" placeholder="Enter To Date" (blur)="handleNAchange('cmd_NA_04',551)" (blur)="clearInputIfInvalid('551','cmd_NA_04')" [class.is-invalid]="PCSForms.controls['551'].invalid" id="551" class="form-control" formControlName="551"  ngbDatepicker #d551="ngbDatepicker">
                    <div class="input-group-append">
                     <button class="btn btn-outline-secondary calendar" (click)="d551.toggle()" type="button">
                       <i class="fas fa-calendar-alt"></i>
                     </button>
                   </div>
                 </div>
                 <div class="text-danger" *ngIf="(PCSForms.controls['551'].invalid&&PCSForms.controls['551'].touched) && (PCSForms.controls['551'].value==''|| PCSForms.controls['551'].value==null) " >
                  <small >
                  required
                  </small>

              </div>
                <small class="text-danger" *ngIf="PCSForms.get('551').errors?.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY)                </small>
                <small class="text-danger" *ngIf="PCSForms.get('551').errors?.invalidMonth">
                Month is out of range
                </small>
                <small class="text-danger" *ngIf="PCSForms.get('551').errors?.invalidDateyear">
                  year is out of range(1900-2099)                  </small>
                  <small class="text-danger" *ngIf="PCSForms.get('551').errors?.invalidDateRange">
                    date is out of range
                    </small>
                 </div>
              </div>
              <div class="row">
                 <div class="col-2 pr-0"> <label for="2542">Type of Training:<span *ngIf="PCSForms.controls['2542'].invalid" class="mandatory">*</span></label></div>
                 <div class="col-3 pl-0">
                    <input type="text" placeholder="Enter Type of Training" (blur)="handleNAchange('cmd_NA_04',2542)" [class.is-invalid]="PCSForms.controls['2542'].invalid" id="2542" class="form-control"
                       formControlName="2542">
                       <div class="mandatory"
                           *ngIf="PCSForms.controls['2542'].invalid">
                           <small >
                              required
                           </small>
                        </div>  

                 </div>
                 <div class="col-2 pr-0"> <label for="543">Specialty:</label><span *ngIf="PCSForms.controls['543'].invalid" class="mandatory">*</span></div>
                 <div class="col-5 pl-0">
                    <!-- <input type="text" placeholder="Enter Specialty" id="543" class="form-control" formControlName="543" -->
                    <!-- [class.is-invalid]="(PCSForms.controls['543'].touched && PCSForms.controls['543'].invalid ) || (PCSForms.controls['543'].invalid && isSubmit)"> -->
                    <ng-select (blur)="handleNAchange('cmd_NA_04')" [class.is-invalid]="PCSForms.controls['543'].invalid"
                    [items]="specialty"
                    bindLabel="name"
                    placeholder="Select Specialty"
                    bindValue="id"
                    formControlName="543"
                    >
                    </ng-select>
                    <div class="mandatory"
                        *ngIf=" PCSForms.controls['543'].invalid">
                        <small>
                        required
                        </small>
                     </div>
                 </div>
              </div>
           </td>
           <tr>
              <td colspan="12">
                 <div class="form-inline mb-1">
                    <label for="cmd_1146">Did you successfully complete the program?<span *ngIf="PCSForms.controls['cmd_1146'].invalid" class="mandatory">*</span></label>&nbsp;&nbsp;
                    <input
                       type="radio" (blur)="handleNAchange('cmd_NA_04','cmd_1146')" [class.is-invalid]="PCSForms.controls['cmd_1146'].invalid"
                       id="cmd_1146"
                       name="cmd_1146"
                       formControlName="cmd_1146"
                       value="Yes"
                       />Yes&nbsp;&nbsp;
                    <input
                       type="radio"
                       id="cmd_1146"
                       name="cmd_1146"
                       formControlName="cmd_1146"
                       value="No"
                       />No&nbsp;&nbsp;<span>(If NO, please explain; attach additional sheets if
                              necessary)</span>
                        <div class="mandatory"
                        *ngIf="PCSForms.controls['cmd_1146'].invalid">
                        <small >
                           required
                        </small>
                        </div>
                    <!-- <div *ngIf="PCSForms.controls['cmd_1146'].invalid && isSubmit">
                      <small
                        class="text-danger"
                        *ngIf="PCSForms.controls['cmd_1146'].errors"
                      >
                        Please select YES or NO
                      </small>
                    </div> -->
                    <!-- <div class="invalid-feedback"
                    *ngIf="(PCSForms.controls['cmd_1146'].touched && PCSForms.controls['cmd_1146'].invalid) || (PCSForms.controls['cmd_1146'].invalid && isSubmit)">
                    Please select yes or No
                 </div> -->
                 </div>
              </td>
           </tr>
           <tr>
              <td colspan="12" class="p-0">
                 <div class="coloredTitle">
                    Practice-Information
                 </div>
              </td>
           </tr>
           <tr>
              <td colspan="8">
                 <div class="row">
                    <div class="col-5 pr-0"> <label for="cmd_1005">Practice Name (as reported to IRS) <span
                       class="mandatory">*</span>:</label></div>
                    <div class="col-4 pl-0">
                       <input type="text" placeholder="Enter Practice Name" id="cmd_1005" class="form-control"
                          formControlName="cmd_1005"
                          [class.is-invalid]="(PCSForms.controls['cmd_1005'].touched && PCSForms.controls['cmd_1005'].invalid ) || (PCSForms.controls['cmd_1005'].invalid && isSubmit)">
                       <div class="invalid-feedback"
                          *ngIf="(PCSForms.controls['cmd_1005'].touched && PCSForms.controls['cmd_1005'].invalid) || (PCSForms.controls['cmd_1005'].invalid && isSubmit)">
                          Practice Name is required and cannot be empty
                       </div>
                    </div>
                 </div>
                 <div class="row">
                    
                    <div colspan="3" class="pl-4">
                     <div class="form-check">
                        <input formControlName="cmd_NA_06" (ngModelChange)="handleNAchange('cmd_NA_06')" class="form-check-input" type="checkbox" value="1" id="cmd_NA_06"
                            for="cmd_NA_06"/>         
                     </div>         
                  </div>
                  

                  <div class="col-1 pr-0"><label for="cmd_01005">DBA:</label><span *ngIf="PCSForms.controls['cmd_01005'].invalid"class="mandatory">*</span>
                  </div>
                     <div class="col-3 pl-0">
                        <input type="text" placeholder="Enter DBA" id="cmd_01005" class="form-control" formControlName="cmd_01005"
                        (blur)="handleNAchange('cmd_NA_06','cmd_01005')" [class.is-invalid]="PCSForms.controls['cmd_01005'].invalid">
                           <div class="mandatory"
                           *ngIf="PCSForms.controls['cmd_01005'].invalid">
                           <small >
                             required
                           </small>
                         </div>  
                     </div>
                  </div>
                  <!-- </div> -->
              </td>
              <td colspan="4">
                 <div class="row">
                    <div class="col-4 pr-0"> <label for="cmd_1006">Federal Tax ID<span class="mandatory">*</span>:</label>
                    </div>
                    <div class="col-4 pl-0">
                       <input type="text" placeholder="Enter Federal Tax ID" id="cmd_1006" class="form-control"
                          formControlName="cmd_1006"
                          [class.is-invalid]="(PCSForms.controls['cmd_1006'].touched && PCSForms.controls['cmd_1006'].invalid ) || (PCSForms.controls['cmd_1006'].invalid && isSubmit)">
                       <div class="invalid-feedback"
                          *ngIf="(PCSForms.controls['cmd_1006'].touched && PCSForms.controls['cmd_1006'].invalid) || (PCSForms.controls['cmd_1006'].invalid && isSubmit)">
                          Federal Tax ID is required and cannot be empty
                       </div>
                    </div>
                 </div>
              </td>
           </tr>
           <tr>
              <td colspan="12">
                 <div class="form-inline mb-1">
                    <label for="cmd_1007">Select One<span class="mandatory">*</span>:</label>&nbsp;&nbsp;
                    <input type="checkbox" value="SolePractioner" formControlName="cmd_1007"id="cmd_1007">
                    <label for="SolePractioner">Sole Practioner</label>&nbsp;&nbsp;
                    <input type="checkbox" value="LLC" formControlName="cmd_1170" id="cmd_1170">
                    <label for="LLC">LLC</label>&nbsp;&nbsp;
                    <input type="checkbox" value="Corporation" formControlName="cmd_1171" id="cmd_1171">
                    <label for="Corporation">Corporation</label>&nbsp;&nbsp;
                    <input type="checkbox" value="partnership" formControlName="cmd_1172"  id="cmd_1172">
                    <label for="partnership">Partnership</label>&nbsp;&nbsp;
                    <input type="checkbox" value="PLLC" formControlName="cmd_01170" id="cmd_01170">
                    <label for="PLLC">PLLC</label>&nbsp;&nbsp;
                    <input type="checkbox" value="Other"  formControlName="cmd_1173" id="cmd_1173">
                    <label for="Other">Other</label>
                    <input type="text"  id="cmd_001008" formControlName="cmd_001008" class="form-control">

                 </div>
              </td>
           </tr>
           <!-- <tr>
              <td colspan="12">
                 <div class="form-inline mb-1">
                    <label for="cmd_1007">Select One<span class="mandatory">*</span>:</label>&nbsp;&nbsp;
                    <input type="Radio" value="SolePractioner" formControlName="cmd_1007"id="cmd_1007">
                    <label for="SolePractioner">Sole Practioner</label>&nbsp;&nbsp;
                    <input type="Radio" value="LLC" formControlName="cmd_1007" id="cmd_1007">
                    <label for="LLC">LLC</label>&nbsp;&nbsp;
                    <input type="Radio" value="Corporation" formControlName="cmd_1007" id="cmd_1007">
                    <label for="Corporation">Corporation</label>&nbsp;&nbsp;
                    <input type="Radio" value="partnership" formControlName="cmd_1007"  id="cmd_1007">
                    <label for="partnership">partnership</label>&nbsp;&nbsp;
                    <input type="Radio" value="Other"  formControlName="cmd_1007" id="cmd_1007">
                    <label for="Other">Other</label>
                 </div>
              </td>
           </tr> -->
           <tr>
              <td colspan="12">
                 <div class="row">
                    <div class="col-4 pr-0"> <label for="cmd_1008">Primary Location Address<span
                       class="mandatory">*</span>:</label></div>
                    <div class="col-4 pl-0">
                       <input type="text" placeholder="Enter Location Address 1" id="cmd_1008" class="form-control"
                          formControlName="cmd_1008"
                          [class.is-invalid]="(PCSForms.controls['cmd_1008'].touched && PCSForms.controls['cmd_1008'].invalid ) || (PCSForms.controls['cmd_1008'].invalid && isSubmit)">
                       <div class="invalid-feedback"
                          *ngIf="(PCSForms.controls['cmd_1008'].touched && PCSForms.controls['cmd_1008'].invalid) || (PCSForms.controls['cmd_1008'].invalid && isSubmit)">
                          Primary Location Address 1 is required and cannot be empty
                       </div>
                    </div>
                    <div class="col-4 pl-0">
                      <input type="text" placeholder="Enter Location Address 2" id="cmd_01008" class="form-control"
                         formControlName="cmd_01008"
                         [class.is-invalid]="(PCSForms.controls['cmd_01008'].touched && PCSForms.controls['cmd_01008'].invalid ) || (PCSForms.controls['cmd_01008'].invalid && isSubmit)">
                      <div class="invalid-feedback"
                         *ngIf="(PCSForms.controls['cmd_01008'].touched && PCSForms.controls['cmd_01008'].invalid) || (PCSForms.controls['cmd_01008'].invalid && isSubmit)">
                         Primary Location Address 2 is required and cannot be empty
                      </div>
                   </div>
                 </div>
              </td>
              <!-- <td colspan="6">
               <div class="row">
                  <div class="col-3 pr-0"> <label for="cmd_1008">Primary Location Address<span
                     class="mandatory">*</span>:</label></div>
                  <div class="col-3 pl-0">
                     <input type="text" placeholder="Enter Location 2" id="cmd_01008" class="form-control"
                        formControlName="cmd_01008"
                        [class.is-invalid]="(PCSForms.controls['cmd_01008'].touched && PCSForms.controls['cmd_01008'].invalid ) || (PCSForms.controls['cmd_01008'].invalid && isSubmit)">
                     <div class="invalid-feedback"
                        *ngIf="(PCSForms.controls['cmd_01008'].touched && PCSForms.controls['cmd_01008'].invalid) || (PCSForms.controls['cmd_01008'].invalid && isSubmit)">
                        Primary Location Address  2 is required and cannot be empty
                     </div>
                  </div>
               </div>
            </td> -->
           </tr>
           <tr>
              <td colspan="6">
                 <div class="row">
                    <div class="col-3 pr-0"> <label for="cmd_1009">City/State/Zip<span class="mandatory">*</span>:</label>
                    </div>
                    <div class="col-3 pl-0">
                       <input type="text" placeholder="Enter City" id="cmd_1009" class="form-control"
                          formControlName="cmd_1009"
                          [class.is-invalid]="(PCSForms.controls['cmd_1009'].touched && PCSForms.controls['cmd_1009'].invalid ) || (PCSForms.controls['cmd_1009'].invalid && isSubmit)">
                       <div class="invalid-feedback"
                          *ngIf="(PCSForms.controls['cmd_1009'].touched && PCSForms.controls['cmd_1009'].invalid) || (PCSForms.controls['cmd_1009'].invalid && isSubmit)">
                          City is required and cannot be empty
                       </div>
                    </div>
                    <div class="col-3 pl-0">
                      <ng-select
                      [items]="states"
                      [class.is-invalid]="(PCSForms.controls['cmd_1150'].touched &&
                      PCSForms.controls['cmd_1150'].invalid) ||
                      (PCSForms.controls['cmd_1150'].invalid && isSubmit)"
                      bindLabel="name"
                      placeholder="Enter State"
                      bindValue="id"
                      formControlName="cmd_1150"
                      >
                      </ng-select>
                      <div class="invalid-feedback"
                         *ngIf="(PCSForms.controls['cmd_1150'].touched && PCSForms.controls['cmd_1150'].invalid) || (PCSForms.controls['cmd_1150'].invalid && isSubmit)">
                         State is required and cannot be empty
                      </div>
                   </div>
                   <div class="col-3 pl-0">
                    <input type="text" placeholder="Enter Zip" id="cmd_1151" class="form-control"
                       formControlName="cmd_1151"
                       [class.is-invalid]="(PCSForms.controls['cmd_1151'].touched && PCSForms.controls['cmd_1151'].invalid ) || (PCSForms.controls['cmd_1151'].invalid && isSubmit)">
                    <div class="invalid-feedback"
                       *ngIf="(PCSForms.controls['cmd_1151'].touched && PCSForms.controls['cmd_1151'].invalid) || (PCSForms.controls['cmd_1151'].invalid && isSubmit)">
                       Zip is required and cannot be empty
                    </div>
                 </div>
                 </div>
              </td>
              <td colspan="3">
                 <div class="row">
                    <div class="col-3 pr-0"> <label for="cmd_1010">Phone<span class="mandatory">*</span>:</label></div>
                    <div class="col-8 pl-0">
                       <input type="text" placeholder="Enter Phone" id="cmd_1010" class="form-control"
                          formControlName="cmd_1010"
                          [class.is-invalid]="(PCSForms.controls['cmd_1010'].touched && PCSForms.controls['cmd_1010'].invalid ) || (PCSForms.controls['cmd_1010'].invalid && isSubmit)">
                       <div class="invalid-feedback"
                          *ngIf="(PCSForms.controls['cmd_1010'].touched && PCSForms.controls['cmd_1010'].invalid) || (PCSForms.controls['cmd_1010'].invalid && isSubmit)">
                          Phone is required and cannot be empty
                       </div>
                    </div>
                 </div>
              </td>
              <td colspan="3">
                 <div class="row">
                    <div class="col-3 pr-0"> <label for="cmd_1011">Fax:</label></div>
                    <div class="col-8 pl-0">
                       <input type="text" placeholder="Enter Fax" id="cmd_1011" class="form-control" formControlName="cmd_1011"
                          >
                       
                    </div>
                 </div>
              </td>
           </tr>
           <tr>
              <td colspan="12">
                 <label for="5"><b>Remittance or Special Payments Address (if different than primary address) :</b></label><br>
                 <!-- <div class="form-inline mb-1">
                    <label for="cmd_1012">City/state/Zip:</label>
                    <input type="text" placeholder="Enter City/State/Zip" class="form-control" formControlName = "cmd_1012" id="cmd_1012">
                 </div> -->
                 <div class="row">
                  <div class="col-3 pr-0"> <label for="cmd_1012">City/State/Zip:</label>
                  </div>
                  <div class="col-3 pl-0">
                     <input type="text" placeholder="Enter City" id="cmd_1012" class="form-control"
                        formControlName="cmd_1012"
                        [class.is-invalid]="(PCSForms.controls['cmd_1012'].touched && PCSForms.controls['cmd_1012'].invalid ) || (PCSForms.controls['cmd_1012'].invalid && isSubmit)">
                     <!-- <div class="invalid-feedback"
                        *ngIf="(PCSForms.controls['cmd_1012'].touched && PCSForms.controls['cmd_1012'].invalid) || (PCSForms.controls['cmd_1012'].invalid && isSubmit)">
                        City is required and cannot be empty
                     </div> -->
                  </div>
                  <div class="col-3 pl-0">
                    <ng-select
                    [items]="states"
                    bindLabel="name"
                    placeholder="Enter State"
                    bindValue="id"
                    formControlName="cmd_1152"
                    >
                    </ng-select>
                    <!-- <div class="invalid-feedback"
                       *ngIf="(PCSForms.controls['cmd_1152'].touched && PCSForms.controls['cmd_1152'].invalid) || (PCSForms.controls['cmd_1152'].invalid && isSubmit)">
                       State is required and cannot be empty
                    </div> -->
                 </div>
                 <div class="col-3 pl-0">
                  <input type="text" placeholder="Enter Zip" id="cmd_1153" class="form-control"
                     formControlName="cmd_1153"
                     [class.is-invalid]="(PCSForms.controls['cmd_1153'].touched && PCSForms.controls['cmd_1153'].invalid ) || (PCSForms.controls['cmd_1153'].invalid && isSubmit)">
                  <!-- <div class="invalid-feedback"
                     *ngIf="(PCSForms.controls['cmd_1153'].touched && PCSForms.controls['cmd_1153'].invalid) || (PCSForms.controls['cmd_1153'].invalid && isSubmit)">
                     Zip is required and cannot be empty
                  </div> -->
               </div>
               </div>
              </td>
           <tr>
              <td colspan="12">
               <div class="row">
                  <div class="col-4">
                 
                    <label for="cmd_1013">Date Started at this Location:</label>
                  </div>
                  <div class="col-6">
                     <div class="input-group">
                        <input type="text" placeholder="Enter Date" class="form-control" formControlName = "cmd_1013" id="cmd_1013"  (blur)="clearInputIfInvalid('cmd_1013')" ngbDatepicker #d1013="ngbDatepicker">
                 
                        <div class="input-group-append">
                           <button class="btn btn-outline-secondary calendar" (click)="d1013.toggle()" type="button">
                             <i class="fas fa-calendar-alt"></i>
                           </button>
                         </div>
                       </div>
                       <div  *ngIf="PCSForms.get('cmd_1013').invalid ">
                        <small class="text-danger" *ngIf="PCSForms.get('cmd_1013').errors?.invalidDateFormat">
                          Only Date Format is allowed(MM/DD/YYYY)
                        </small>
                        <small class="text-danger" *ngIf="PCSForms.get('cmd_1013').errors?.invalidMonth">
                        Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="PCSForms.get('cmd_1013').errors?.invalidDateyear">
                          year is out of range(1900-2099)                      </small>
                          <small class="text-danger" *ngIf="PCSForms.get('cmd_1013').errors?.invalidDateRange">
                            date is out of range
                            </small>
                        </div>
                     </div>
                 </div>
              </td>
           </tr>
           <tr>
              <td colspan="8">
                 <div class="row">
                  <div colspan="3" class="pl-4">
                     <div class="form-check">
                        <input formControlName="cmd_NA_07" (ngModelChange)="handleNAchange('cmd_NA_07')" class="form-check-input" type="checkbox" value="1" id="cmd_NA_07"
                            for="cmd_NA_07"/>         
                     </div>         
                  </div>
                  
                    <div class="col-4 pr-0"><label for="cmd_1014">Group&nbsp; NPI Number<span *ngIf="PCSForms.controls['cmd_1014'].invalid"class="mandatory">*</span>:</label>
                    </div>
                    <div class="col-4 pl-0">
                     <input type="text" placeholder="Enter Group NPI Number" id="cmd_1014" class="form-control" formControlName="cmd_1014"
                     (blur)="handleNAchange('cmd_NA_07','cmd_1014')" [class.is-invalid]="PCSForms.controls['cmd_1014'].invalid">
                        <div class="mandatory"
                        *ngIf="PCSForms.controls['cmd_1014'].invalid">
                        <small >
                          required
                        </small>
                      </div> 
                    </div>
                 </div>
                 <div class="row">
                  <div colspan="3" class="pl-4">
                     <div class="form-check">
                        <input formControlName="cmd_NA_08" (ngModelChange)="handleNAchange('cmd_NA_08')" class="form-check-input" type="checkbox" value="1" id="cmd_NA_08"
                            for="cmd_NA_08"/>         
                     </div>         
                  </div>
                  <div class="col-4 pr-0"><label for="cmd_01014">Individual NPI Number<span *ngIf="PCSForms.controls['cmd_01014'].invalid"class="mandatory">*</span>:</label>
                  </div>
                  <div class="col-4 pl-0">
                     <input type="text" placeholder="Enter Individual NPI Number" id="cmd_01014" class="form-control" formControlName="cmd_01014"
                     (blur)="handleNAchange('cmd_NA_08','cmd_01014')" [class.is-invalid]="PCSForms.controls['cmd_01014'].invalid">
                        <div class="mandatory"
                        *ngIf="PCSForms.controls['cmd_01014'].invalid">
                        <small >
                          required
                        </small>
                      </div> 
                    </div>
               </div>
              </td>
              <td colspan="4">
                 <div class="row">
                    <div class="col-3 pr-0"><label for="cmd_1015"><b>Group</b>&nbsp;PTAN:</label>
                    </div>
                    <div class="col-6 pl-0">
                       <input type="text" placeholder="Enter Group PTAN" id="cmd_1015" class="form-control"
                          formControlName="cmd_1015"
                          />
                       
                    </div>
                 </div>
              </td>
           </tr>
           <tr>
              <td colspan="12">
                 <div class="form-inline mb-1">
                    <label for="5">Did you intend to serve as a primary care provider?<span
                       class="mandatory">*</span></label>&nbsp;&nbsp;
                    <!-- <input type="Radio" value="yes" id="cmd_1016">
                       <label for="yes"> Yes</label>&nbsp;&nbsp;
                       <input type="Radio" value="No" id="cmd_1016">
                       <label for="No"> No</label> -->
                    <input
                       type="radio"
                       id="cmd_1016"
                       name="cmd_1016"
                       formControlName="cmd_1016"
                       value="Yes"
                       />Yes&nbsp;&nbsp;
                    <input
                       type="radio"
                       id="cmd_1016"
                       name="cmd_1016"
                       formControlName="cmd_1016"
                       value="No"
                       />No
                       <div *ngIf="PCSForms.controls['cmd_1016'].invalid && isSubmit">
                        <small
                          class="text-danger"
                          *ngIf="PCSForms.controls['cmd_1016'].errors"
                        >
                          Please select YES or NO
                        </small>
                      </div>

                 </div>
                 <div class="form-inline mb-1">
                    <label for="5">Did you intend to serve as a specialist?<span class="mandatory">*</span></label>&nbsp;&nbsp;
                    <input
                       type="radio"
                       id="cmd_1017"
                       name="cmd_1017"
                       formControlName="cmd_1017"
                       value="1"
                       [class.is-invalid]="(PCSForms.controls['cmd_1017'].touched && PCSForms.controls['cmd_1017'].errors) || (PCSForms.controls['cmd_1017'].errors && isSubmit)" (change)="onChangeCmdTwo($event)"
                       />Yes&nbsp;&nbsp;
                    <input
                       type="radio"
                       id="cmd_1017"
                       name="cmd_1017"
                       formControlName="cmd_1017"
                       value="0"
                       [class.is-invalid]="(PCSForms.controls['cmd_1017'].touched && PCSForms.controls['cmd_1017'].errors) || (PCSForms.controls['cmd_1017'].errors && isSubmit)" (change)="onChangeCmdTwo($event)"
                       />No&nbsp;&nbsp;&nbsp;&nbsp;<span>If YES,list specialty(ies):
                        <div class="row" *ngIf="changeCmdTwo">
                          <input class="form-control  " id="cmd_01017" name="cmd_01017" type="text" [class.is-invalid]="PCSForms.controls['cmd_01017'].value==''" formControlName="cmd_01017">
                          <div class="formLabel col-md-11"
                              *ngIf="PCSForms.controls['cmd_01017'].touched && PCSForms.controls['cmd_01017'].errors">
                              <small class="text-danger" *ngIf="PCSForms.controls['cmd_01017'].errors?.required">
                                  required
                              </small>
                          </div>
                        </div>

                        <!-- <input type="text" placeholder="Enter specialties" id="cmd_1019" class="form-control"
                        formControlName="cmd_01017"> -->

                       </span>
                       <div *ngIf="PCSForms.controls['cmd_1017'].invalid && isSubmit">
                        <small
                          class="text-danger"
                          *ngIf="PCSForms.controls['cmd_1017'].errors"
                        >
                          Please select YES or NO
                        </small>
                      </div>
                 </div>
                 <div class="form-inline mb-1">
                  <label for="cmd_1018">Is your practice limited to certain ages?<span class="mandatory">*</span></label>&nbsp;
                    <input
                       type="radio"
                       id="cmd_1018"
                       name="cmd_1018"
                       formControlName="cmd_1018"
                       value="1"
                       [class.is-invalid]="(PCSForms.controls['cmd_1018'].touched && PCSForms.controls['cmd_1018'].errors) || (PCSForms.controls['cmd_1018'].errors && isSubmit)" (change)="onChangeCmdOne($event)"
                       />Yes&nbsp;&nbsp;
                    <input
                       type="radio"
                       id="cmd_1018"
                       name="cmd_1018"
                       formControlName="cmd_1018"
                       value="0"
                       [class.is-invalid]="(PCSForms.controls['cmd_1018'].touched && PCSForms.controls['cmd_1018'].errors) || (PCSForms.controls['cmd_1018'].errors && isSubmit)" (change)="onChangeCmdOne($event)"
                       />No&nbsp;&nbsp;&nbsp;&nbsp;<span>If YES,specify limitations:
                        <div class="row" *ngIf="changeCmdOne">
                          <input class="form-control  " id="cmd_01018" name="cmd_01018" type="text" [class.is-invalid]="PCSForms.controls['cmd_01018'].value==''" formControlName="cmd_01018">
                          <div class="formLabel col-md-11"
                              *ngIf="PCSForms.controls['cmd_01018'].touched && PCSForms.controls['cmd_01018'].errors">
                              <small class="text-danger" *ngIf="PCSForms.controls['cmd_01018'].errors?.required">
                                  required
                              </small>
                          </div>
                        </div>

                        <!-- <input type="text" placeholder="Enter Limitations" id="cmd_1019" class="form-control"
                        formControlName="cmd_01018"> -->

                       </span>
                       <div *ngIf="PCSForms.controls['cmd_1018'].invalid && isSubmit">
                        <small
                          class="text-danger"
                          *ngIf="PCSForms.controls['cmd_1018'].errors"
                        >
                          Please select YES or NO
                        </small>
                      </div>
                 </div>
              </td>
           </tr>
           <tr>
              <td colspan="12">
                 <div class="row">
                    <div class="col-4 pr-0"><label for="cmd_1019">Current Electronic Health Record (EHR) being used<span
                       class="mandatory">*</span>:</label>
                    </div>
                    <div class="col-4 pl-0">
                       <input type="text" placeholder="Current Electronic Health" id="cmd_1019" class="form-control"
                          formControlName="cmd_1019"
                          (blur)="handleNAchange('cmd_NA_09','cmd_1019')" [class.is-invalid]="PCSForms.controls['cmd_1019'].invalid">
                       
                        <div class="mandatory"
                                    *ngIf="PCSForms.controls['cmd_1019'].invalid">
                                    <small >
                                      required
                                    </small>
                        </div> 
                    </div>
                     <div class="col-md-4">
                        <div class="form-check">
                           <input formControlName="cmd_NA_09" (ngModelChange)="handleNAchange('cmd_NA_09')" class="form-check-input" type="checkbox" value="1" id="cmd_NA_09">
                              <label class="form-check-label" for="cmd_NA_09">
                                          <b>Does Not Apply</b>
                              </label>
                              
                        </div> 
                     </div>      
                 </div>
              </td>
           </tr>
           <tr>
              <td colspan="12">
                 
                 <div class="row">
                    <div class="col-4 pr-0"><label for="cmd_1020">Current Clearinghouse for EFT, EDI and ERA<span
                       class="mandatory">*</span>:</label>
                    </div>
                    <div class="col-4 pl-0">
                       <input type="text" placeholder="Current Clearing house" id="cmd_1020" class="form-control"
                          formControlName="cmd_1020"
                          (blur)="handleNAchange('cmd_NA_10','cmd_1020')" [class.is-invalid]="PCSForms.controls['cmd_NA_10'].invalid">
                           <div class="mandatory"
                                 *ngIf="PCSForms.controls['cmd_1020'].invalid">
                                 <small >
                                    required
                                 </small>
                           </div> 
                    </div>
                     <div class="col-md-4">
                        <div class="form-check">
                           <input formControlName="cmd_NA_10" (ngModelChange)="handleNAchange('cmd_NA_10')" class="form-check-input" type="checkbox" value="1" id="cmd_NA_10">
                              <label class="form-check-label" for="cmd_NA_10">
                                          <b>Does Not Apply</b>
                              </label>
                              
                        </div> 
                     </div>      
                 </div>
              </td>
           </tr>
           <tr>
              <td colspan="12" class="p-0">
                 <div class="coloredTitle">
                    Website Portal Access (If EDI/ERA enrollment requested)
                 </div>
              </td>
           </tr>
           <tr>
              <td colspan="4">
                 <div class="form-inline mb-1">
                    <label for="cmd_1021">Website URL:</label>
                    <input type="text" placeholder="Enter Website URL" class="form-control"  formControlName = "cmd_1021" id="cmd_1021">
                 </div>
              </td>
              <td colspan="4">
                 <div class="form-inline mb-1">
                    <label for="cmd_1022">User Name:</label>
                    <input type="text" placeholder=" Enter User Name" class="form-control" formControlName = "cmd_1022" id="cmd_1022">
                 </div>
              </td>
              <td colspan="4">
                 <div class="form-inline mb-1">
                    <label for="cmd_1023">Password:</label>
                    <input type="text" placeholder="Enter Password" class="form-control" formControlName = "cmd_1023" id="cmd_1023">
                 </div>
              </td>
           </tr>
           <tr>
              <td colspan="4">
                 <div class="form-inline mb-1">
                    <label for="cmd_1024">Website URL:</label>
                    <input type="text" placeholder="Enter Website URL" class="form-control"  formControlName = "cmd_1024" id="cmd_1024">
                 </div>
              </td>
              <td colspan="4">
                 <div class="form-inline mb-1">
                    <label for="cmd_1025">User Name:</label>
                    <input type="text" placeholder="Enter User Name" class="form-control" formControlName =  "cmd_1025" id="cmd_1025">
                 </div>
              </td>
              <td colspan="4">
                 <div class="form-inline mb-1">
                    <label for="cmd_1026">Password:</label>
                    <input type="text" placeholder="Enter Password" class="form-control" formControlName = "cmd_1026" id="cmd_1026">
                 </div>
              </td>
           </tr>
           <tr>
              <td colspan="4">
                 <div class="form-inline mb-1">
                    <label for="cmd_1027">Website URL:</label>
                    <input type="text" placeholder="Enter Website URL" class="form-control" formControlName = "cmd_1027" id="cmd_1027">
                 </div>
              </td>
              <td colspan="4">
                 <div class="form-inline mb-1">
                    <label for="cmd_1028">User Name:</label>
                    <input type="text" placeholder="Enter User Name" class="form-control" formControlName = "cmd_1028" id="cmd_1028">
                 </div>
              </td>
              <td colspan="4">
                 <div class="form-inline mb-1">
                    <label for="cmd_1029">Password:</label>
                    <input type="text" placeholder="Enter Password" class="form-control" formControlName = "cmd_1029" id="cmd_1029">
                 </div>
              </td>
           </tr>
           <tr>
              <td colspan="4">
                 <div class="form-inline mb-1">
                    <label for="cmd_1030">Website URL:</label>
                    <input type="text" placeholder="Enter Website URL" class="form-control" formControlName = "cmd_1030" id="cmd_1030">
                 </div>
              </td>
              <td colspan="4">
                 <div class="form-inline mb-1">
                    <label for="cmd_1031">User Name:</label>
                    <input type="text" placeholder="Enter User Name" class="form-control" formControlName = "cmd_1031" id="cmd_1031">
                 </div>
              </td>
              <td colspan="4">
                 <div class="form-inline mb-1">
                    <label for="cmd_1032">Password:</label>
                    <input type="text" placeholder="Enter Password" class="form-control" formControlName = "cmd_1032"  id="cmd_1032">
                 </div>
              </td>
           </tr>
           <tr>
              <td colspan="4">
                 <div class="form-inline mb-1">
                    <label for="cmd_1033">Website URL:</label>
                    <input type="text" placeholder="Enter Website URL" class="form-control" formControlName = "cmd_1033" id="cmd_1033">
                 </div>
              </td>
              <td colspan="4">
                 <div class="form-inline mb-1">
                    <label for="cmd_1034">User Name:</label>
                    <input type="text" placeholder="Enter User Name" class="form-control" formControlName = "cmd_1034" id="cmd_1034">
                 </div>
              </td>
              <td colspan="4">
                 <div class="form-inline mb-1">
                    <label for="cmd_1035">Password:</label>
                    <input type="text" placeholder="Enter Password" class="form-control" formControlName = "cmd_1035" id="cmd_1035">
                 </div>
              </td>
           </tr>
           <tr>
              <td colspan="12" class="p-0">
                 <div class="coloredTitle">
                    Office Hours
                 </div>
              </td>
           </tr>
           <tr>
              <td colspan="12">
                 <table>
                    <thead>
                       <tr>
                          <th scope="col"></th>
                          <th scope="col">Monday
                          <th scope="col">Tuesday
                          <th scope="col">Wednesday
                          <th scope="col">Thursday
                          <th scope="col">Friday
                          <th scope="col">Saturday
                          <th scope="col">Sunday
                          
                       </tr>
                    </thead>
                    <tbody>
                       <tr>
                          <th>Primary office<span class="mandatory">*</span>:</th>
                          <th><input type="text"  placeholder="Enter hours" class="form-control" formControlName = "cmd_1177" id="cmd_1177"></th>
                          <th><input type="text"  placeholder="Enter hours" class="form-control" formControlName = "cmd_1178" id="cmd_1178"></th>
                          <th><input type="text"  placeholder="Enter hours" class="form-control" formControlName = "cmd_1179" id="cmd_1179"></th>
                          <th><input type="text"  placeholder="Enter hours" class="form-control" formControlName = "cmd_1180" id="cmd_1180"></th>
                          <th><input type="text"  placeholder="Enter hours" class="form-control" formControlName = "cmd_1181" id="cmd_1181"></th>
                          <th><input type="text"  placeholder="Enter hours" class="form-control" formControlName = "cmd_1182" id="cmd_1182"></th>
                          <th><input type="text"  placeholder="Enter hours" class="form-control" formControlName = "cmd_1183" id="cmd_1183"></th>
                          
                       </tr>
                       <tr>
                          <th>2nd office</th>
                          <th><input type="text" placeholder="Enter hours" class="form-control" formControlName = "cmd_1186" id="cmd_1186"></th>
                          <th><input type="text" placeholder="Enter hours" class="form-control" formControlName = "cmd_1187" id="cmd_1187"></th>
                          <th><input type="text" placeholder="Enter hours" class="form-control" formControlName = "cmd_1188" id="cmd_1188"></th>
                          <th><input type="text" placeholder="Enter hours" class="form-control" formControlName = "cmd_1189" id="cmd_1189"></th>
                          <th><input type="text" placeholder="Enter hours" class="form-control" formControlName = "cmd_1190" id="cmd_1190"></th>
                          <th><input type="text" placeholder="Enter hours" class="form-control" formControlName = "cmd_1191" id="cmd_1191"></th>
                          <th><input type="text" placeholder="Enter hours" class="form-control" formControlName = "cmd_1192" id="cmd_1192"></th>
                          
                       </tr>
                       <tr>
                          <th>3rd office</th>
                          <th><input type="text" placeholder="Enter hours" class="form-control" formControlName = "cmd_1195" id="cmd_1195"></th>
                          <th><input type="text" placeholder="Enter hours" class="form-control" formControlName = "cmd_1196" id="cmd_1196"></th>
                          <th><input type="text" placeholder="Enter hours" class="form-control" formControlName = "cmd_1197" id="cmd_1197"></th>
                          <th><input type="text" placeholder="Enter hours" class="form-control" formControlName = "cmd_1198" id="cmd_1198"></th>
                          <th><input type="text" placeholder="Enter hours" class="form-control" formControlName = "cmd_1199" id="cmd_1199"></th>
                          <th><input type="text" placeholder="Enter hours" class="form-control" formControlName = "cmd_1200" id="cmd_1200"></th>
                          <th><input type="text" placeholder="Enter hours" class="form-control" formControlName = "cmd_1201" id="cmd_1201"></th>
                          
                       </tr>
                    </tbody>
                 </table>
              </td>
           </tr>
           <tr>
              <td colspan="12">
              <div class="row">
                <div class="col-4 pr-0"> <label for="cmd_1049"><b>Second Location Address:</b></label>
                </div>
                <div class="col-4 pl-0">
                  <input type="text" placeholder="Enter Location Address 1" class="form-control" formControlName = "cmd_1036" id="cmd_1036">

                </div>
                <div class="col-4 pl-0">
                  <input type="text" placeholder="Enter Location Address 2" class="form-control" formControlName = "cmd_01036" id="cmd_01036">
                </div>
              </div>
              </td>
           </tr>
           <tr>
              <td colspan="6">
                 <!-- <div class="form-inline mb-1">
                    <label for="cmd_1037"> City/State/Zip:</label>
                    <input type="text" placeholder="Enter City" class="form-control" formControlName = "cmd_1037" id="cmd_1037">
                 </div> -->
                 <div class="row">
                  <div class="col-3 pr-0"> <label for="cmd_1037">City/State/Zip:</label>
                  </div>
                  <div class="col-3 pl-0">
                     <input type="text" placeholder="Enter City" id="cmd_1037" class="form-control"
                        formControlName="cmd_1037">
                     <!-- <div class="invalid-feedback"
                        *ngIf="(PCSForms.controls['cmd_1037'].touched && PCSForms.controls['cmd_1037'].invalid) || (PCSForms.controls['cmd_1037'].invalid && isSubmit)">
                        City is required and cannot be empty
                     </div> -->
                  </div>
                  <div class="col-3 pl-0">
                    <ng-select
                    [items]="states"
                    bindLabel="name"
                    placeholder="Enter State"
                    bindValue="id"
                    formControlName="cmd_1154"
                    >
                    </ng-select>
                    <!-- <div class="invalid-feedback"
                       *ngIf="(PCSForms.controls['cmd_1154'].touched && PCSForms.controls['cmd_1154'].invalid) || (PCSForms.controls['cmd_1154'].invalid && isSubmit)">
                       State is required and cannot be empty
                    </div> -->
                 </div>
                 <div class="col-3 pl-0">
                  <input type="text" placeholder="Enter Zip" id="cmd_1155" class="form-control"
                     formControlName="cmd_1155">
                  <!-- <div class="invalid-feedback"
                     *ngIf="(PCSForms.controls['cmd_1155'].touched && PCSForms.controls['cmd_1155'].invalid) || (PCSForms.controls['cmd_1155'].invalid && isSubmit)">
                     Zip is required and cannot be empty
                  </div> -->
               </div>
               </div>
              </td>
              <td colspan="3">
                 <div class="form-inline mb-1">
                    <label for="cmd_1038">Phone:</label>
                    <input type="text" placeholder="Enter Phone" class="form-control" formControlName = "cmd_1038" id="cmd_1038">
                    <small class="text-danger" *ngIf="PCSForms.controls['cmd_1038'].errors?.pattern">
                      Only number are allowed</small>
                 </div>
              </td>
              <td colspan="3">
                 <div class="form-inline mb-1">
                    <label for="cmd_1039">Fax:</label>
                    <input type="text" placeholder="Enter Fax" class="form-control" formControlName = "cmd_1039" id="cmd_1039">
                 </div>
              </td>
           </tr>
           <tr>
              <td colspan="6">
                 <div class="row"><div class="col-4">
                    <label for="cmd_1040"><b>Location</b>&nbsp;NPI Number:</label></div>
                    <div class="col-6">
                    <input type="text" placeholder="Enter NPI Number" class="form-control" formControlName = "cmd_1040" id="cmd_1040">
                 </div></div>
              </td>
              <td colspan="6">
               <div class="row"><div class="col-4">
                    <label for="cmd_1041"><b>Location</b>&nbsp;PTAN:</label></div>
                    <div class="col-6">
                    <input type="text" placeholder="Enter PTAN" class="form-control" formControlName = "cmd_1041" id="cmd_1041">
                 </div></div>
              </td>
           </tr>
           <tr>

               <td colspan="12">
                <div class="row">
                  <div class="col-4 pr-0"> <label for="cmd_1049"><b>Third Location Address:</b></label>
                  </div>
                  <div class="col-4 pl-0">
                    <input type="text" placeholder="Enter Location 1" class="form-control" formControlName = "cmd_1042" id="cmd_1042">

                  </div>
                  <div class="col-4 pl-0">
                    <input type="text" placeholder="Enter Location 2" class="form-control" formControlName = "cmd_01042" id="cmd_01042">

                  </div>
                </div>
                </td>
           </tr>
           <tr>
              <td colspan="6">
                 <!-- <div class="form-inline mb-1">
                    <label for="cmd_1043"> City/State/Zip:</label>
                    <input type="text" placeholder="Enter City" class="form-control" formControlName = "cmd_1043" id="cmd_1043">
                 </div> -->
                 <div class="row">
                  <div class="col-3 pr-0"> <label for="cmd_1043">City/State/Zip:</label>
                  </div>
                  <div class="col-3 pl-0">
                     <input type="text" placeholder="Enter City" id="cmd_1043" class="form-control"
                        formControlName="cmd_1043">

                  </div>
                  <div class="col-3 pl-0">
                    <ng-select
                    [items]="states"
                    bindLabel="name"
                    placeholder="Enter State"
                    bindValue="id"
                    formControlName="cmd_1158"
                    >
                    </ng-select>
                    <!-- <div class="invalid-feedback"
                       *ngIf="(PCSForms.controls['cmd_1158'].touched && PCSForms.controls['cmd_1158'].invalid) || (PCSForms.controls['cmd_1158'].invalid && isSubmit)">
                       State is required and cannot be empty
                    </div> -->
                 </div>
                 <div class="col-3 pl-0">
                  <input type="text" placeholder="Enter Zip" id="cmd_1159" class="form-control"
                     formControlName="cmd_1159">
                  <!-- <div class="invalid-feedback"
                     *ngIf="(PCSForms.controls['cmd_1159'].touched && PCSForms.controls['cmd_1159'].invalid) || (PCSForms.controls['cmd_1159'].invalid && isSubmit)">
                     Zip is required and cannot be empty
                  </div> -->
               </div>
               </div>
              </td>
              <td colspan="3">
                 <div class="form-inline mb-1">
                    <label for="cmd_1044">Phone:</label>
                    <input type="text" placeholder="Enter Phone" class="form-control" formControlName ="cmd_1044" id="cmd_1044">
                    <small class="text-danger" *ngIf="PCSForms.controls['cmd_1044'].errors?.pattern">
                      Only number are allowed</small>

                 </div>
              </td>
              <td colspan="3">
                 <div class="form-inline mb-1">
                    <label for="cmd_1045">Fax:</label>
                    <input type="text" placeholder="Enter Fax" class="form-control" formControlName = "cmd_1045" id="cmd_1045">
                 </div>
              </td>
           </tr>
           <tr>
              <td colspan="6">
                 <div class="row"><div class="col-4">
                    <label for="cmd_1046"><b>Location</b>&nbsp;NPI Number:</label></div>
                    <div class="col-6">
                    <input type="text" placeholder="Enter NPI Number" class="form-control" formControlName ="cmd_1046"id="cmd_1046">
                 </div></div>
              </td>
              <td colspan="6">
                 <div class="row"><div class="col-3">
                    <label for="cmd_1047"><b>Location</b>&nbsp;PTAN:</label></div>
                    <div class="col-6">
                    <input type="text" placeholder="Enter PTAN " class="form-control" formControlName="cmd_1047"id="cmd_1047">
                 </div></div>
              </td>
           </tr>
           <tr>
              <td colspan="12" class="p-0">
                 <div class="coloredTitle">
                    Medical Records Storage Address (If different than Primary address)
                 </div>
              </td>
           </tr>
           <tr>
            <td colspan="12">
              <div class="row">
                <div class="col-4 pr-0"> <label for="cmd_1049">Location Address:</label>
                </div>
                <div class="col-4 pl-0">
                  <input type="text" placeholder="Enter Location Address" class="form-control" formControlName = "cmd_1048" id="cmd_1048">

                </div>
                <div class="col-4 pl-0">
                  <input type="text" placeholder="Enter Location Address" class="form-control" formControlName = "cmd_01048" id="cmd_01048">

                </div>
              </div>
              </td>
           </tr>
           <tr>
              <td colspan="12">
                 <!-- <div class="form-inline mb-1">
                    <label for="cmd_1049">City/State/Zip:</label>
                    <input type="text" placeholder="Enter City/State" class="form-control" formControlName="cmd_1049" id="cmd_1049">
                 </div> -->
                 <div class="row">
                  <div class="col-3 pr-0"> <label for="cmd_1049">City/State/Zip:</label>
                  </div>
                  <div class="col-3 pl-0">
                     <input type="text" placeholder="Enter City" id="cmd_1049" class="form-control"
                        formControlName="cmd_1049">

                  </div>
                  <div class="col-3 pl-0">
                    <ng-select
                    [items]="states"
                    bindLabel="name"
                    placeholder="Enter State"
                    bindValue="id"
                    formControlName="cmd_1156"
                    >
                    </ng-select>
                    <!-- <div class="invalid-feedback"
                       *ngIf="(PCSForms.controls['cmd_1156'].touched && PCSForms.controls['cmd_1156'].invalid) || (PCSForms.controls['cmd_1156'].invalid && isSubmit)">
                       State is required and cannot be empty
                    </div> -->
                 </div>
                 <div class="col-3 pl-0">
                  <input type="text" placeholder="Enter Zip" id="cmd_1157" class="form-control"
                     formControlName="cmd_1157">
                  <!-- <div class="invalid-feedback"
                     *ngIf="(PCSForms.controls['cmd_1157'].touched && PCSForms.controls['cmd_1157'].invalid) || (PCSForms.controls['cmd_1157'].invalid && isSubmit)">
                     Zip is required and cannot be empty
                  </div> -->
               </div>
               </div>
              </td>
           </tr>
           <tr>
              <td colspan="12">
                 <div class="form-inline mb-1">
                    <label for="cmd_1050">Do you store patient medical records elecetronically?</label>&nbsp;&nbsp;
                    <!-- <input type="Radio" value="Yes" id="cmd_1050">
                       <label for="Yes">Yes</label>&nbsp;&nbsp;
                       <input type="Radio" value="No" id="cmd_1050">
                       <label for="No">No</label> -->
                    <input
                       type="radio"
                       id="cmd_1050"
                       name="cmd_1050"
                       formControlName="cmd_1050"
                       value="Yes"
                       />Yes&nbsp;&nbsp;
                    <input
                       type="radio"
                       id="cmd_1050"
                       name="cmd_1050"
                       formControlName="cmd_1050"
                       value="No"
                       />No
                 </div>
              </td>
           </tr>
           <tr>
              <td colspan="12">
                 <!-- <div class="form-inline mb-1">
                    <input type="checkbox" value="In-HouseBillingProgram" id="cmd_1051">
                    <label for="In-HouseBilling Program">In-House Billing Program</label>&nbsp;&nbsp;
                    <input type="checkbox" value="Website" id="cmd_1051">
                    <label for="Website">Website</label>&nbsp;&nbsp;
                    <input type="checkbox" value="Vendor" id="cmd_1051">
                    <label for="Vendor">Vendor</label>
                    <input type="checkbox" value="URL" id="cmd_1051">
                    <label for="URL">URL</label>
                 </div> -->
                 <div class="form-inline mb-1">
                  <input type="checkbox" value="In-HouseBillingProgram" formControlName="cmd_1051" id="cmd_1051">
                  <label for="In-HouseBilling Program">In-House Billing Program</label>&nbsp;&nbsp;
                  <input type="checkbox" value="Website" formControlName="cmd_1174" id="cmd_1174">
                  <label for="Website">Website</label>&nbsp;&nbsp;
                  <input type="checkbox" value="Vendor" formControlName="cmd_1175" id="cmd_1175">
                  <label for="Vendor">Vendor</label>&nbsp;&nbsp;
                  <input type="checkbox" value="URL" formControlName="cmd_1176" id="cmd_1176">
                  <label for="URL">URL</label>
               </div>
              </td>
           </tr>
           <tr>
              <td colspan="12">
                 <div class="row">
                  <div class="col-2">
                    <label for="cmd_1052">URL/Website Address:</label></div>
                    <div class="col-8">
                    <input type="text" placeholder="Enter Website Address" class="form-control" formControlName="cmd_1052" id="cmd_1052">
                     </div>
                  </div>
              </td>
           </tr>
           <tr>
              <td colspan="12">
                 <div class="row">
                    <div class="col-2">
                    <label for="cmd_1053">Vendor Name:</label></div>
                    <div class="col-8">
                    <input type="text" placeholder=" Enter Vendor Name" class="form-control" formControlName="cmd_1053" id="cmd_1053">
                     </div>
                    </div>
              </td>
           </tr>
           <tr>
              <td colspan="12" class="p-0">
                 <div class="coloredTitle">
                    Ownership of Practice-Please Complete Organization or Individual (Both, if applicable)
                 </div>
              </td>
           </tr>
           <tr>
              <td colspan="12">
                <div class="row">
                  <div class="col-6 pr-0">
                      <label for="5">Is Ownership Interest and/or Managing Control by an &nbsp;<b>Organization</b>&nbsp;greater than
                      5%?</label>
                  </div>
                  <div class="col-6 pl-0">
                    <input type="text" placeholder="Enter Ownership Interest " class="form-control" formControlName="cmd_001054" id="cmd_001054">
                  </div>
                </div>
              </td>
           </tr>
           <tr>
             <td colspan="12">
              <div class="row">
                <div class="col-4 pr-0"> <label for="cmd_1049">Name of <b>Organization:</b></label>
                </div>
                <div class="col-4 pl-0">
                  <input type="text" placeholder="Enter Organization " class="form-control" formControlName="cmd_1054" id="cmd_1054">
                </div>
                <!-- <div class="col-4 pl-0">
                  <input type="text" placeholder="Enter Organization 2" class="form-control" formControlName="cmd_01054"  id="cmd_01054">
                </div> -->
              </div>
              </td>
           </tr>
           <tr>
              <td colspan="12">
                <div class="row">
                  <div class="col-4 pr-0"> <label for="cmd_1049">Address:</label>
                  </div>
                  <div class="col-4 pl-0">
                    <input type="text" placeholder="Enter Address 1" class="form-control" formControlName="cmd_1055"  id="cmd_1055">

                  </div>
                  <div class="col-4 pl-0">
                    <input type="text" placeholder="Enter Address 2" class="form-control" formControlName="cmd_01055"  id="cmd_01055">

                  </div>
                </div>
                </td>

           </tr>
           <tr>
              <td colspan="6">
                 <div class="row">
                  <div class="col-3 pr-0"> <label for="cmd_1056">City/State/Zip:</label>
                  </div>
                  <div class="col-3 pl-0">
                     <input type="text" placeholder="Enter City" id="cmd_1056" class="form-control"
                        formControlName="cmd_1056">
                  </div>
                  <div class="col-3 pl-0">
                    <ng-select
                    [items]="states"
                    bindLabel="name"
                    placeholder="Enter State"
                    bindValue="id"
                    formControlName="cmd_1160"
                    >
                    </ng-select>
                    <!-- <div class="invalid-feedback"
                       *ngIf="(PCSForms.controls['cmd_1160'].touched && PCSForms.controls['cmd_1160'].invalid) || (PCSForms.controls['cmd_1160'].invalid && isSubmit)">
                       State is required and cannot be empty
                    </div> -->
                 </div>
                 <div class="col-3 pl-0">
                  <input type="text" placeholder="Enter Zip" id="cmd_1161" class="form-control"
                     formControlName="cmd_1161">
                  <!-- <div class="invalid-feedback"
                     *ngIf="(PCSForms.controls['cmd_1161'].touched && PCSForms.controls['cmd_1157'].invalid) || (PCSForms.controls['cmd_1161'].invalid && isSubmit)">
                     Zip is required and cannot be empty
                  </div> -->
               </div>
               </div>
              </td>
              <td colspan="6">
                 <div class="row">
                     <div class="col-3 pr-0">
                        <label for="cmd_1057">Phone Number:</label>
                     </div>
                     <div class="col-9 pl-0">
                        <input type="text" placeholder="Enter Phone" class="form-control" formControlName = "cmd_1057" id="cmd_1057">
                        <small class="text-danger" *ngIf="PCSForms.controls['cmd_1057'].errors?.pattern">
                           Only number are allowed</small>
                     </div>      
                 </div>
              </td>
           </tr>           
           <tr>
              <td colspan="12">
                 <!-- <div class="form-inline mb-1">
                    <label for="5">Is Ownership Interest and/or Managing Control by an &nbsp;<b>Individual</b>&nbsp;greater than
                    5%?</label>
                 </div> -->
                 <div class="row">
                  <div class="col-6 pr-0">
                      <label for="5">Is Ownership Interest and/or Managing Control by an &nbsp;<b>Organization</b>&nbsp;greater than
                      5%?</label>
                  </div>
                  <div class="col-6 pl-0">
                    <input type="text" placeholder="Enter Ownership Interest " class="form-control" formControlName="cmd_01061" id="cmd_01061">
                  </div>
                </div>
              </td>
           </tr>
           <tr>
              <td colspan="6">
                 <div class="row">
                     <div class="col-3 pr-0">
                        <label for="cmd_1061">Name of the&nbsp;<b>Individual:</b></label>
                     </div>
                     <div class="col-9 pl-0">  
                        <input type="text" placeholder="Enter Individual Name" class="form-control"  formControlName="cmd_1061" id="cmd_1061">
                 
                     </div>      
                  </div>
              </td>
              <td colspan="6">
                 <div class="row">
                     <div class="col-3 pr-0">
                        <label for="cmd_1062">Title:</label>
                     </div>
                     <div class="col-9 pl-0"> 
                        <input type="text" placeholder="Enter Title" class="form-control" formControlName="cmd_1062" id="cmd_1062">
                     </div>
                  </div>   
              </td>
           </tr>
           <tr>
              <td colspan="4">
                 <div class="row">
                  <div class="col-4">
                    <label for="cmd_1063">Date of Birth:</label>
                  </div>
                  <div class="col-8">
                     <div class="input-group">
                     <input type="text" placeholder="Enter DOB" class="form-control" formControlName="cmd_1063" id="cmd_1063" (blur)="clearInputIfInvalid('cmd_1063')" ngbDatepicker #d1063="ngbDatepicker">
                     <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d1063.toggle()" type="button">
                          <i class="fas fa-calendar-alt"></i>
                        </button>
                      </div>
                    </div>
                    <div  *ngIf="PCSForms.get('cmd_1063').invalid ">
                     <small class="text-danger" *ngIf="PCSForms.get('cmd_1063').errors?.invalidDateFormat">
                       Only Date Format is allowed(MM/DD/YYYY)
                     </small>
                     <small class="text-danger" *ngIf="PCSForms.get('cmd_1063').errors?.invalidMonth">
                     Month is out of range
                     </small>
                     <small class="text-danger" *ngIf="PCSForms.get('cmd_1063').errors?.invalidDateyear">
                       year is out of range(1900-2099)                      </small>
                       <small class="text-danger" *ngIf="PCSForms.get('cmd_1063').errors?.invalidDateRange">
                         date is out of range
                         </small>
                     </div>
                  </div>   
                  </div>
              </td>
              <td colspan="8">
                 <div class="row">
                  <div class="col-4 pr-0">
                    <label for="cmd_1064">Social Security Number or Tax ID Number:</label>
                  </div>
                  <div class="col-7 pl-0">  
                    <input type="text" placeholder="Enter SSN or Tax ID" class="form-control" formControlName="cmd_1064" id="cmd_1064">
                 </div>
                 </div>
              </td>
           </tr>
           <tr>
            <td colspan="8">
               <!-- <div class="form-inline mb-1">
                  <label for="cmd_1065">Relationship to Supplier<span class="mandatory">*</span>:</label>
                  <input type="text" placeholder="Enter Supplier" class="form-control" id="cmd_1065">
               </div> -->
               <div class="row">
                  <div class="col-2 pr-0"><label for="cmd_1066">Relationship to Supplier<span class="mandatory">*</span>:</label>
                  </div>
                  <div class="col-10 pl-0">
                    <input type="Checkbox" value="moreowner" formControlName="cmd_1067" id="cmd_1067">
               <label for="cmd_1067">5% or more Owner</label>&nbsp;&nbsp;
               <input type="Checkbox" value="AuthorizedOfficial" formControlName="cmd_pcs_1068" id="cmd_pcs_1068">
               <label for="cmd_pcs_1068">Authorized Official</label>&nbsp;&nbsp;
               <input type="Checkbox" value="DelegateOfficial" formControlName="cmd_pcs_1069" id="cmd_pcs_1069">
               <label for="cmd_pcs_1069">Delegated Official</label>&nbsp;&nbsp;
               <input type="Checkbox" value="Partner" formControlName="cmd_pcs_1070" id="cmd_pcs_1070">
               <label for="cmd_pcs_1070">Partner</label>&nbsp;&nbsp;
               <input type="Checkbox" value="Director/Officer"formControlName="cmd_pcs_1071"  id="cmd_pcs_1071">
               <label for="cmd_pcs_1071">Director/Officer</label>&nbsp;&nbsp;
               <input type="Checkbox" value="ContractManagingEmployee" formControlName="cmd_pcs_1072" id="cmd_pcs_1072">
               <label for="cmd_pcs_1072">Contracted/Managing Employee</label>&nbsp;&nbsp;
               <input type="Checkbox" value="W-2 ManagingEmployee" formControlName="cmd_pcs_1073" id="cmd_pcs_1073">
               <label for="cmd_pcs_1073">W-2 Managing Employee</label>&nbsp;&nbsp;
                    <!-- <input type="text" placeholder="Enter Relationship to Supplier" id="cmd_1066" class="form-control" formControlName="cmd_1066"
                      [class.is-invalid]="(PCSForms.controls['cmd_1066'].touched && PCSForms.controls['cmd_1066'].invalid ) || (PCSForms.controls['cmd_1066'].invalid && isSubmit)">
                    <div class="invalid-feedback"
                      *ngIf="(PCSForms.controls['cmd_1066'].touched && PCSForms.controls['cmd_1066'].invalid) || (PCSForms.controls['cmd_1066'].invalid && isSubmit)">
                      Relationship to Supplier is required and cannot be empty
                    </div> -->
                  </div>
                  </div>
            </td>
            <td colspan="4">
               <div class="row">
                  <div class="col-5 pr-0"><label for="cmd_1065">Phone Number<span class="mandatory">*</span>:</label>
                  </div>
                  <div class="col-6 pl-0">
                     <input type="text" placeholder="Phone Number" id="cmd_1065" class="form-control"
                        formControlName="cmd_1065"
                        [class.is-invalid]="(PCSForms.controls['cmd_1065'].touched && PCSForms.controls['cmd_1065'].invalid ) || (PCSForms.controls['cmd_1065'].invalid && isSubmit)">
                     <div class="invalid-feedback"
                        *ngIf="(PCSForms.controls['cmd_1065'].touched && PCSForms.controls['cmd_1065'].invalid) || (PCSForms.controls['cmd_1065'].invalid && isSubmit)">
                        Phone Number is required and cannot be empty
                     </div>
                  </div>
               </div>
            </td>
         </tr>
           <tr>
              <td colspan="12">
                 <label for="cmd_1068">Has the Organization or Individual (listed above) ever had a final
                 adverse legal action imposed against him/her<span class="mandatory">*</span>?</label>
                 <!-- <input type="Radio" value="Yes" id="cmd_1068">&nbsp;&nbsp;
                    <label for="cmd_1068">Yes</label>&nbsp;&nbsp;

                    <input type="Radio" value="No" id="cmd_1068">
                    <label for="cmd_1068">No</label>&nbsp;&nbsp; -->
                 <input
                    type="radio"
                    id="cmd_1068"
                    name="cmd_1068"
                    formControlName="cmd_1068"
                    value="1"
                    [class.is-invalid]="(PCSForms.controls['cmd_1068'].touched && PCSForms.controls['cmd_1068'].errors) || (PCSForms.controls['cmd_1068'].errors && isSubmit)" (change)="onChangeCmdThree($event)"
                    />Yes&nbsp;&nbsp;
                 <input
                    type="radio"
                    id="cmd_1068"
                    name="cmd_1068"
                    formControlName="cmd_1068"
                    value="0"
                    [class.is-invalid]="(PCSForms.controls['cmd_1068'].touched && PCSForms.controls['cmd_1068'].errors) || (PCSForms.controls['cmd_1068'].errors && isSubmit)" (change)="onChangeCmdThree($event)"
                    />No&nbsp;&nbsp;&nbsp;&nbsp;<span>If YES:
                      <div *ngIf="changeCmdThree">
                        <input class="form-control  " id="cmd_01068" name="cmd_01068" type="text" [class.is-invalid]="PCSForms.controls['cmd_01068'].value==''" formControlName="cmd_01068">
                        <div class="formLabel col-md-11"
                            *ngIf="PCSForms.controls['cmd_01068'].touched && PCSForms.controls['cmd_01068'].errors">
                            <small class="text-danger" *ngIf="PCSForms.controls['cmd_01068'].errors?.required">
                                required
                            </small>
                        </div>
                      </div>

                    </span>
                    <div *ngIf="PCSForms.controls['cmd_1068'].invalid && isSubmit">
                      <small
                        class="text-danger"
                        *ngIf="PCSForms.controls['cmd_1068'].errors"
                      >
                        Please select YES or NO
                      </small>
                    </div>
              </td>
           </tr>
           <tr>
              <td colspan="12">
                 <div class="row">
                    <!-- <label for="cmd_1069">If Yes</label> -->
                    <!-- <input type="text" class="form-control" id="cmd_1069"> -->
                   
                     <div class="col-4 pr-0">
                    <label for="cmd_1069">Final Adverse Legal Action:</label>
                    </div>
                    
                     <div class="col-7 pl-0">
                    <input type="text" placeholder="Enter Final Adverse Legal" class="form-control" formControlName="cmd_1069" id="cmd_1069">
                        </div>
                  </div>
              </td>
              <!-- <td colspan="6">
                 <div class="form-inline mb-1">
                   <label for="cmd_1069">Final Adverse Legal Action:</label>
                   <input type="text" placeholder = "Enter Final Adverse Legal" class="form-control" id="cmd_1069">
                 </div>
                 </td> -->
           </tr>
           <!-- <tr>
              <td colspan="12">
                <div class="form-inline mb-1">
                  <label for="cmd_1069">Final Adverse Legal Action:</label>
                  <input type="text" placeholder = "Enter Final Adverse Legal" class="form-control" id="cmd_1069">
                </div>
              </td>
              </tr> -->
           <tr>
              <td colspan="12">
               <div class="row">
                  <div class="col-4">
                    <label for="cmd_1070">Date of Action:</label>
                    </div>
                    <div class="col-6">
                     <div class="input-group">
                           <input type="text" placeholder="Enter Date " class="form-control" formControlName="cmd_1070" id="cmd_1070" (blur)="clearInputIfInvalid('cmd_1070')" ngbDatepicker #d1070="ngbDatepicker">
                           <div class="input-group-append">
                              <button class="btn btn-outline-secondary calendar" (click)="d1070.toggle()" type="button">
                                <i class="fas fa-calendar-alt"></i>
                              </button>
                           </div>
                     </div>
                     <div  *ngIf="PCSForms.get('cmd_1070').invalid ">
                        <small class="text-danger" *ngIf="PCSForms.get('cmd_1070').errors?.invalidDateFormat">
                          Only Date Format is allowed(MM/DD/YYYY)
                        </small>
                        <small class="text-danger" *ngIf="PCSForms.get('cmd_1070').errors?.invalidMonth">
                        Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="PCSForms.get('cmd_1070').errors?.invalidDateyear">
                          year is out of range(1900-2099)                      </small>
                          <small class="text-danger" *ngIf="PCSForms.get('cmd_1070').errors?.invalidDateRange">
                            date is out of range
                            </small>
                        </div>

                   </div>
                  </div>
              </td>
           </tr>
           <tr>
              <td colspan="12">
                 <div class="row">
                  
                     <div class="col-3 pr-0">
                    <label for="cmd_1071">Action Taken By:</label>
                    </div>
                    
                     <div class="col-8 pl-0">
                    <input type="text" placeholder="Enter Action" class="form-control" formControlName="cmd_1071" id="cmd_1071">
                     </div>   
                  </div>
              </td>
           </tr>
           <tr>
              <td colspan="10" class="p-0">
                 <div class="coloredTitle">
                    Current Hospital and other Institutional Affiliations (Attach additional sheets if necessary)
                 </div>
              </td>
              <td colspan="2" class="p-0">
               <div class="form-check">
                  <input formControlName="cmd_NA_05" (ngModelChange)="handleNAchange('cmd_NA_05')" class="form-check-input" type="checkbox" value="1" id="cmd_NA_05">
                     <label class="form-check-label" for="cmd_NA_05">
                              <b>Does Not Apply</b>
                     </label>
                         
               </div>      
                  
                 
            </td>
           </tr>
           <tr>
              <td colspan="12">
                 <div class="form-inline mb-1">
                    <label>Please list in reverse chronological order (with current affiliation(s) first) all institutions where
                    you have current affiliations (A)&nbsp;
                    and have had previous hospital privileges (B) during the past ten (10) years.This includes hospitals, surgery
                    centers, institutions, corporations, military
                    assignments, or government agencies.If you do not have privileges, please explain.
                    </label>
                 </div>
              </td>
           </tr>
           <tr>
              <td colspan="6">
                <div class="row">
                  <div class="col-5 pr-0"><label for="418">Name of&nbsp;<b>Primary</b>&nbsp;Admitting Hospital:</label><span *ngIf="PCSForms.controls['418'].invalid" class="mandatory">*</span>
                  </div>
                  <div class="col-7 pl-0">
                     <input type="text" placeholder="Enter Primary Hospital" id="418" class="form-control" formControlName="418"
                     (blur)="handleNAchange('cmd_NA_05',418)" [class.is-invalid]="PCSForms.controls['418'].invalid">
                        <div class="mandatory"
                        *ngIf="PCSForms.controls['418'].invalid">
                        <small >
                          required
                        </small>
                      </div>  
                  </div>
               </div>
              </td>
              <td colspan="6">
                 <div class="row">
                    <div class="col-3 pr-0"><label for="425">Department:</label><span *ngIf="PCSForms.controls['425'].invalid" class="mandatory">*</span>
                    </div>
                    <div class="col-8 pl-0">
                       <input type="text" placeholder="Enter Department" id="425" class="form-control" formControlName="425"
                       (blur)="handleNAchange('cmd_NA_05',425)" [class.is-invalid]="PCSForms.controls['425'].invalid">
                          <div class="mandatory"
                          *ngIf="PCSForms.controls['425'].invalid">
                          <small >
                            required
                          </small>
                        </div>  
                    </div>
                 </div>
              </td>
           </tr>
           <tr>
              <td colspan="6">
                 <div class="row">
                    <div class="col-4 pr-0"> <label for="419">Address:</label></div>
                    <div class="col-3 pl-0">
                       <input type="text" placeholder=" Enter Address 1 " id="419" class="form-control"
                          formControlName="419"
                          >
                          
                    </div>
                    <div class="col-3 pl-0">
                       <input type="text" placeholder=" Enter  Address 2 " id="420" class="form-control"
                          formControlName="420"
                          >
                          
                    </div>
                 </div>
              </td>
              <td colspan="6">
                 <div class="row">
                    <div class="col-6 pr-0"><label for="894">Status(Active, Provisional, Courtesy, Pending):<span *ngIf="PCSForms.controls['894'].invalid" class="mandatory">*</span></label>
                    </div>
                    <div class="col-6 pl-0">
                       <!-- <input type="text" placeholder="Enter Status" id="894" class="form-control"  -->
                          <!-- [class.is-invalid]="(PCSForms.controls['894'].touched && PCSForms.controls['894'].invalid ) || (PCSForms.controls['894'].invalid && isSubmit)"> -->
                          <ng-select
                       [items]="staffStatus"
                       (blur)="handleNAchange('cmd_NA_05')" [class.is-invalid]="PCSForms.controls['894'].invalid"
                       bindLabel="name"
                       formControlName="894"
                       placeholder="Enter Status"
                       bindValue="id"

                       >
                       </ng-select>
                       <div class="mandatory"
                           *ngIf=" PCSForms.controls['894'].invalid">
                           <small>
                           required
                           </small>
                        </div>
                    </div>
                 </div>
              </td>
           </tr>
           <tr>
            <td colspan="6">
                <div class="row">
                    <div class="col-3 pr-0"><label for="421">City/State/Zip:</label>
                    </div>
                    <div class="col-3 pl-0">
                       <input type="text" placeholder="Enter City" id="421" class="form-control" formControlName="421"
                       >
                        
                       
                    </div>
                     <div class="col-3 pl-0">
                     <!-- <input type="text" placeholder="Enter State" id="905" class="form-control" formControlName="905" -->
                        <!-- [class.is-invalid]="(PCSForms.controls['905'].touched && PCSForms.controls['905'].invalid ) || (PCSForms.controls['905'].invalid && isSubmit)"> -->
                        <ng-select
                      [items]="states"
                      bindLabel="name"
                      placeholder="Enter State"
                      bindValue="id"
                      formControlName="423"
                      >
                      </ng-select>
                       
                     </div>
                     <div class="col-3 pl-0">
                        <input type="text" placeholder="Enter Zip" id="424" class="form-control" formControlName="424"
                        >
                          
                     </div>
                  </div>
            </td>
            <td colspan="6">
               <!-- <div class="form-inline mb-1">
                  <label for="426">Appointment Date:<span class="mandatory">*</span></label>
                  <input type="Date" placeholder="Enter Appoint Date" class="form-control" formControlName = "426" id="426">
               </div>
               <div class="invalid-feedback"
                        *ngIf="(PCSForms.controls['426'].touched && PCSForms.controls['426'].invalid) || (PCSForms.controls['426'].invalid && isSubmit)">
                        Appointment Date is required and cannot be empty
                     </div> -->
                     <div class="row">
                        <div class="col-4"><label for="426">Appointment Date:</label><span *ngIf="PCSForms.controls['426'].invalid" class="mandatory">*</span>
                       </div>
                       <div class="col-6">
                        <div class="input-group">
                          <input type="text" placeholder="Enter  Initial Date" id="426" class="form-control" formControlName="426"
                          (blur)="handleNAchange('cmd_NA_05',426)" (blur)="clearInputIfInvalid('426','cmd_NA_05')" [class.is-invalid]="PCSForms.controls['426'].invalid" ngbDatepicker #d426="ngbDatepicker">
                          <div class="input-group-append">
                           <button class="btn btn-outline-secondary calendar" (click)="d426.toggle()" type="button">
                             <i class="fas fa-calendar-alt"></i>
                           </button>
                         </div>
                       </div>   
                       <div class="text-danger" *ngIf="(PCSForms.controls['426'].invalid&&PCSForms.controls['426'].touched) && (PCSForms.controls['426'].value==''|| PCSForms.controls['426'].value==null) " >
                        <small >
                        required
                        </small>
      
                    </div>
                      <small class="text-danger" *ngIf="PCSForms.get('426').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)                </small>
                      <small class="text-danger" *ngIf="PCSForms.get('426').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="PCSForms.get('426').errors?.invalidDateyear">
                        year is out of range(1900-2099)                  </small>
                        <small class="text-danger" *ngIf="PCSForms.get('426').errors?.invalidDateRange">
                          date is out of range
                          </small> 
                       </div>
                    </div>
            </td>
         </tr>

           <tr>
              <td colspan="6">
                 <div class="row">
                  <div class="col-5 pr-0"> 
                    <label for="427">Name of&nbsp;<b>Other</b>&nbsp;Admitting Hospital:</label>
                    </div>
                    <div class="col-7 pl-0"> 
                    <input type="text" placeholder="Enter Admitting Hospital" class="form-control" formControlName = "427" id="427">
                 </div>
                 </div>
              </td>
              <td colspan="6">
                 <div class="row">
                    <div class="col-3 pr-0"><label for="434">Department:</label>
                    </div>
                    <div class="col-8 pl-0">
                       <input type="text" placeholder="Enter Department" id="434" class="form-control" formControlName="434"
                          [class.is-invalid]="(PCSForms.controls['434'].touched && PCSForms.controls['434'].invalid ) || (PCSForms.controls['434'].invalid && isSubmit)">
                       <!-- <div class="invalid-feedback"
                          *ngIf="(PCSForms.controls['434'].touched && PCSForms.controls['434'].invalid) || (PCSForms.controls['434'].invalid && isSubmit)">
                          Department is required and cannot be empty
                       </div> -->
                    </div>
                 </div>
              </td>
           </tr>
           <tr>
              <td colspan="6">
                 <div class="row">
                    <div class="col-4 pr-0"> <label for="428">Address:</label></div>
                    <div class="col-3 pl-0">
                       <input type="text" placeholder="Enter Address 1 " id="428" class="form-control"
                          formControlName="428"
                          [class.is-invalid]="(PCSForms.controls['428'].touched && PCSForms.controls['428'].invalid ) || (PCSForms.controls['428'].invalid && isSubmit)">
                       <!-- <div class="invalid-feedback"
                          *ngIf="(PCSForms.controls['428'].touched && PCSForms.controls['428'].invalid) || (PCSForms.controls['428'].invalid && isSubmit)">
                          Hospital affliation Address 2 is required and cannot be empty
                       </div> -->
                    </div>
                    <div class="col-3 pl-0">
                       <input type="text" placeholder=" Enter Address 2 " id="429" class="form-control"
                          formControlName="429"
                          [class.is-invalid]="(PCSForms.controls['429'].touched && PCSForms.controls['429'].invalid ) || (PCSForms.controls['429'].invalid && isSubmit)">
                       <!-- <div class="invalid-feedback"
                          *ngIf="(PCSForms.controls['429'].touched && PCSForms.controls['429'].invalid) || (PCSForms.controls['429'].invalid && isSubmit)">
                          Hospital affliation Address 2 is required and cannot be empty
                       </div> -->
                    </div>
                 </div>
              </td>
              <td colspan="6">
                 <!-- <div class="form-inline mb-1">
                    <label for="5">Status<span class="mandatory">*</span>(Active,provisional,courtesy,Pending):</label>
                    <input type="text" class="form-control" id="5">
                    </div> -->
                 <div class="row">
                    <div class="col-6 pr-0"><label for="895">Status (Active, Provisional, Courtesy, Pending) :</label>
                    </div>
                    <div class="col-6 pl-0">
                       <!-- <input type="text" placeholder="Enter Status" id="895" class="form-control" formControlName="895" -->
                          <!-- [class.is-invalid]="(PCSForms.controls['895'].touched && PCSForms.controls['895'].invalid ) || (PCSForms.controls['895'].invalid && isSubmit)"> -->
                          <ng-select
                          [items]="staffStatus"
                          bindLabel="name"
                          placeholder="Enter Status"
                          bindValue="id"
                          formControlName="895"
                          >
                          </ng-select>

                       <!-- <div class="invalid-feedback"
                          *ngIf="(PCSForms.controls['895'].touched && PCSForms.controls['895'].invalid) || (PCSForms.controls['895'].invalid && isSubmit)">
                          Status is required and cannot be empty
                       </div> -->
                    </div>
                 </div>
              </td>
           </tr>
           <tr>
              <td colspan="6">
                 <div class="row">
                  <div class="col-3 pr-0"> <label for="430">City/Sate/Zip:</label>
                  </div>
                  <div class="col-3 pl-0">
                    <input type="text" placeholder="Enter City" class="form-control"  formControlName = "430" id="430">
                  </div>
                  <div class="col-3 pl-0">
                    <ng-select
                    [items]="states"
                    [class.is-invalid]="(PCSForms.controls['432'].touched &&
                    PCSForms.controls['432'].invalid) ||
                    (PCSForms.controls['432'].invalid && isSubmit)"
                    bindLabel="name"
                    placeholder="Enter State"
                    bindValue="id"
                    formControlName="432"
                    >
                    </ng-select>
                    <!-- <div class="invalid-feedback"
                       *ngIf="(PCSForms.controls['432'].touched && PCSForms.controls['432'].invalid) || (PCSForms.controls['432'].invalid && isSubmit)">
                       State is required and cannot be empty
                    </div> -->
                 </div>
                 <div class="col-3 pl-0">
                  <input type="text" placeholder="Enter Zip" id="433"  formControlName = "433" class="form-control">
               </div>
               </div>
              </td>
              <td colspan="6">
               <div class="row">
                  <div class="col-4">
                    <label for="5">Appointment Date:</label></div>
                    <div class="col-6">
                     <div class="input-group">
                        <input type="text" placeholder="Enter  Initial  Date" class="form-control" formControlName = "435" id="435" (blur)="clearInputIfInvalid('435')" ngbDatepicker #d435="ngbDatepicker">
                        <div class="input-group-append">
                           <button class="btn btn-outline-secondary calendar" (click)="d435.toggle()" type="button">
                             <i class="fas fa-calendar-alt"></i>
                           </button>
                         </div>
                     </div>
                     <div  *ngIf="PCSForms.get('435').invalid ">
                        <small class="text-danger" *ngIf="PCSForms.get('435').errors?.invalidDateFormat">
                          Only Date Format is allowed(MM/DD/YYYY)
                        </small>
                        <small class="text-danger" *ngIf="PCSForms.get('435').errors?.invalidMonth">
                        Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="PCSForms.get('435').errors?.invalidDateyear">
                          year is out of range(1900-2099)                      </small>
                          <small class="text-danger" *ngIf="PCSForms.get('435').errors?.invalidDateRange">
                            date is out of range
                            </small>
                        </div>
                    </div>        
               </div>
              </td>
           </tr>
           <tr>
              <td colspan="6">
               <div class="row">
                  <div class="col-5 pr-0"> 
                    <label for="436">Name of&nbsp;<b>Other</b>&nbsp;Admitting Hospital:</label></div>
                    <div class="col-7">
                    <input type="text" placeholder="Enter Admitting Hospital" class="form-control" formControlName = "436" id="436">
                 </div></div>
              </td>
              <td colspan="6">
                 <div class="row">
                    <div class="col-3 pr-0"><label for="443">Department:</label>
                    </div>
                    <div class="col-8 pl-0">
                       <input type="text" placeholder="Enter Department" id="443" class="form-control" formControlName="443"
                          [class.is-invalid]="(PCSForms.controls['443'].touched && PCSForms.controls['443'].invalid ) || (PCSForms.controls['443'].invalid && isSubmit)">
                       <!-- <div class="invalid-feedback"
                          *ngIf="(PCSForms.controls['443'].touched && PCSForms.controls['443'].invalid) || (PCSForms.controls['443'].invalid && isSubmit)">
                          Department is required and cannot be empty
                       </div> -->
                    </div>
                 </div>
              </td>
           </tr>
           <tr>
              <td colspan="6">
                 <div class="row">
                    <div class="col-4 pr-0"> <label for="437">Address:</label></div>
                    <div class="col-3 pl-0">
                       <input type="text" placeholder="Enter Address 1 " id="437" class="form-control"
                          formControlName="437"
                          [class.is-invalid]="(PCSForms.controls['437'].touched && PCSForms.controls['437'].invalid ) || (PCSForms.controls['437'].invalid && isSubmit)">
                       <!-- <div class="invalid-feedback"
                          *ngIf="(PCSForms.controls['437'].touched && PCSForms.controls['437'].invalid) || (PCSForms.controls['437'].invalid && isSubmit)">
                          Hospital affliation Address 3 is required and cannot be empty
                       </div> -->
                    </div>
                    <div class="col-3 pl-0">
                       <input type="text" placeholder=" Enter Address 2 " id="438" class="form-control"
                          formControlName="438"
                          [class.is-invalid]="(PCSForms.controls['438'].touched && PCSForms.controls['438'].invalid ) || (PCSForms.controls['438'].invalid && isSubmit)">
                       <!-- <div class="invalid-feedback"
                          *ngIf="(PCSForms.controls['438'].touched && PCSForms.controls['438'].invalid) || (PCSForms.controls['438'].invalid && isSubmit)">
                          Hospital affliation Address 3 is required and cannot be empty
                       </div> -->
                    </div>
                 </div>
              </td>
              <td colspan="6">
                 <div class="row"><div class="col-6">
                    <label for="896">Status (Active,provisional,courtesy,Pending) :</label></div>
                    <!-- <input type="text" Placeholder="Enter Status" class="form-control" formControlName="896"id="896"> -->
                    <div class="col-6 pl-0">
                      <!-- <input type="text" placeholder="Enter Status" id="895" class="form-control" formControlName="895" -->
                         <!-- [class.is-invalid]="(PCSForms.controls['895'].touched && PCSForms.controls['895'].invalid ) || (PCSForms.controls['895'].invalid && isSubmit)"> -->
                         <ng-select
                         [items]="staffStatus"
                         [class.is-invalid]="(PCSForms.controls['896'].touched &&
                         PCSForms.controls['896'].invalid) ||
                         (PCSForms.controls['896'].invalid && isSubmit)"
                         bindLabel="name"
                         placeholder="Enter Status"
                         bindValue="id"
                         formControlName="896"
                         >
                         </ng-select>

                      <!-- <div class="invalid-feedback"
                         *ngIf="(PCSForms.controls['896'].touched && PCSForms.controls['896'].invalid) || (PCSForms.controls['896'].invalid && isSubmit)">
                         Status is required and cannot be empty
                      </div> -->
                   </div>
                 </div>
              </td>
           </tr>
           <tr>
              <td colspan="6">
                 <!-- <div class="form-inline mb-1">
                    <label for="5">City/State/Zip</label>
                    <input type="text" tate/Zip" class="form-control" formControlName="439" id="439">
                 </div> -->
                 <div class="row">
                  <div class="col-3 pr-0"> <label for="439">City/State/Zip:</label>
                  </div>
                  <div class="col-3 pl-0">
                    <input type="text" placeholder="Enter City" class="form-control"  formControlName = "439" id="439">
                  </div>
                  <div class="col-3 pl-0">
                    <ng-select
                    [items]="states"
                    [class.is-invalid]="(PCSForms.controls['441'].touched &&
                    PCSForms.controls['441'].invalid) ||
                    (PCSForms.controls['441'].invalid && isSubmit)"
                    bindLabel="name"
                    placeholder="Enter State"
                    bindValue="id"
                    formControlName="441"
                    >
                    </ng-select>
                    <!-- <div class="invalid-feedback"
                       *ngIf="(PCSForms.controls['432'].touched && PCSForms.controls['432'].invalid) || (PCSForms.controls['432'].invalid && isSubmit)">
                       State is required and cannot be empty
                    </div> -->
                 </div>
                 <div class="col-3 pl-0">
                  <input type="text" placeholder="Enter Zip" id="442"  formControlName = "442" class="form-control">
               </div>
               </div>
              </td>
              <td colspan="6">
               <div class="row">
                  <div class="col-4">
                    <label for="5">Appointment Date:</label></div>
                    <div class="col-6">
                     <div class="input-group">
                    <input type="text" Placeholder="Enter Initial Date" class="form-control" formControlName="444" id="444" (blur)="clearInputIfInvalid('444')" ngbDatepicker #d444="ngbDatepicker">
                    <div class="input-group-append">
                     <button class="btn btn-outline-secondary calendar" (click)="d444.toggle()" type="button">
                       <i class="fas fa-calendar-alt"></i>
                     </button>
                   </div>
                 </div>
                 <div  *ngIf="PCSForms.get('444').invalid ">
                  <small class="text-danger" *ngIf="PCSForms.get('444').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="PCSForms.get('444').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="PCSForms.get('444').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="PCSForms.get('444').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
                 </div>
                  </div>
              </td>
           </tr>
           <tr>
              <td colspan="12" class="p-0">
                 <div class="coloredTitle">
                    Professional Liability
                 </div>
              </td>
           </tr>
           <tr>
              <td colspan="4">
                 <!-- <div class="form-inline mb-1">
                    <label for="5">Current Insurance Carrier<span class="mandatory">*</span>:</label>
                    <input type="text" class="form-control" id="5">
                    </div> -->
                 <div class="row">
                    <div class="col-6 pr-0"><label for="345">Current Insurance Carrier<span
                       class="mandatory">*</span>:</label>
                    </div>
                    <div class="col-6 pl-0">
                       <input type="text" placeholder="Enter Current Insurance Carrier" id="345" class="form-control"
                          formControlName="345"
                          [class.is-invalid]="(PCSForms.controls['345'].touched && PCSForms.controls['345'].invalid ) || (PCSForms.controls['345'].invalid && isSubmit)">
                       <div class="invalid-feedback"
                          *ngIf="(PCSForms.controls['345'].touched && PCSForms.controls['345'].invalid) || (PCSForms.controls['345'].invalid && isSubmit)">
                          Current Insurance Carrier is required and cannot be empty
                       </div>
                    </div>
                 </div>
              </td>
              <td colspan="4">
                 <!-- <div class="form-inline mb-1">
                    <label for="5">Policy Number<span class="mandatory">*</span>:</label>
                    <input type="text" class="form-control" id="5">
                    </div> -->
                 <div class="row">
                    <div class="col-5 pr-0"><label for="346">Policy Number<span class="mandatory">*</span>:</label>
                    </div>
                    <div class="col-7 pl-0">
                       <input type="text" placeholder="Enter Policy Number" id="346" class="form-control"
                          formControlName="346"
                          [class.is-invalid]="(PCSForms.controls['346'].touched && PCSForms.controls['346'].invalid ) || (PCSForms.controls['346'].invalid && isSubmit)">
                       <div class="invalid-feedback"
                          *ngIf="(PCSForms.controls['346'].touched && PCSForms.controls['346'].invalid) || (PCSForms.controls['346'].invalid && isSubmit)">
                          Policy Number is required and cannot be empty
                       </div>
                    </div>
                 </div>
              </td>
              <td colspan="4">
                 <!-- <div class="form-inline mb-1">
                    <label for="5">Original Effective Date<span class="mandatory">*</span>:</label>
                    <input type="text" class="form-control" id="5">
                    </div> -->
                 <div class="row">
                    <div class="col-5"><label for="348">Original Effective Date<span class="mandatory">*</span>:</label>
                    </div>
                    <div class="col-6">
                     <div class="input-group">
                       <input type="text" placeholder="Enter Original Effective Date" id="348" class="form-control"
                          formControlName="348"  (blur)="clearInputIfInvalidMandi('348')"
                          [class.is-invalid]="(PCSForms.controls['348'].touched && PCSForms.controls['348'].invalid ) || (PCSForms.controls['348'].invalid && isSubmit)" ngbDatepicker #d348="ngbDatepicker">
                          <div class="input-group-append">
                           <button class="btn btn-outline-secondary calendar" (click)="d348.toggle()" type="button">
                             <i class="fas fa-calendar-alt"></i>
                           </button>
                         </div>
                        </div>
                        <div class="text-danger" *ngIf="((PCSForms.controls['348'].invalid&&PCSForms.controls['348'].touched) && (PCSForms.controls['348'].value==''|| PCSForms.controls['348'].value==null) || ((PCSForms.controls['348'].invalid && isSubmit) && (PCSForms.controls['348'].value==''|| PCSForms.controls['348'].value==null)) ) " >
                           <small >
                              
                              Original Effective is required and cannot be empty
                           </small>
                         
                         </div>
                         <small class="text-danger" *ngIf="PCSForms.get('348').errors?.invalidDateFormat">
                           Only Date Format is allowed(MM/DD/YYYY)                </small>
                         <small class="text-danger" *ngIf="PCSForms.get('348').errors?.invalidMonth">
                         Month is out of range
                         </small>
                         <small class="text-danger" *ngIf="PCSForms.get('348').errors?.invalidDateyear">
                           year is out of range(1900-2099)                  </small>
                           <small class="text-danger" *ngIf="PCSForms.get('348').errors?.invalidDateRange">
                             date is out of range
                             </small>
                          <!-- <div class="invalid-feedback"
                          *ngIf="(PCSForms.controls['348'].touched && PCSForms.controls['348'].invalid) || (PCSForms.controls['348'].invalid && isSubmit)">
                          Original Effective Date is required and cannot be empty
                       </div> -->
                    </div>
                 </div>
              </td>
           </tr>
           <tr>
              <td colspan="8">
                 <!-- <div class="form-inline mb-1">
                    <label for="5">Mailing Address<span class="mandatory">*</span>:</label>
                    <input type="text" class="form-control" id="5">
                    </div> -->
                 <div class="row">
                    <div class="col-3 pr-0"><label for="901">Mailing Address<span class="mandatory">*</span>:</label>
                    </div>
                    <div class="col-5 pl-0">
                       <input type="text" placeholder="Enter Mailing Address 1" id="901" class="form-control"
                          formControlName="901"
                          [class.is-invalid]="(PCSForms.controls['901'].touched && PCSForms.controls['901'].invalid ) || (PCSForms.controls['901'].invalid && isSubmit)">
                       <div class="invalid-feedback"
                          *ngIf="(PCSForms.controls['901'].touched && PCSForms.controls['901'].invalid) || (PCSForms.controls['901'].invalid && isSubmit)">
                          Mailing Address 1 is required and cannot be empty
                       </div>
                    </div>
                    <div class="col-4 pl-0">
                       <input type="text" placeholder="Enter Mailing Address 2" id="902" class="form-control"
                          formControlName="902"
                          [class.is-invalid]="(PCSForms.controls['902'].touched && PCSForms.controls['902'].invalid ) || (PCSForms.controls['902'].invalid && isSubmit)">
                       <div class="invalid-feedback"
                          *ngIf="(PCSForms.controls['902'].touched && PCSForms.controls['902'].invalid) || (PCSForms.controls['902'].invalid && isSubmit)">
                          Mailing Address 2 is required and cannot be empty
                       </div>
                    </div>
                 </div>
              </td>
              <td colspan="4">
                 <!-- <div class="form-inline mb-1">
                    <label for="5">Per Claim Amount<span class="mandatory">*</span>:$</label>
                    <input type="text" class="form-control" id="5">
                    </div> -->
                 <div class="row">
                    <div class="col-5 pr-0"><label for="350">Per Claim Amount<span class="mandatory">*</span>: $</label>
                    </div>
                    <div class="col-7 pl-0">
                       <!-- <input type="text" placeholder="Enter Per Claim Amount" id="350" class="form-control" -->
                          <!-- formControlName="350" -->
                          <!-- [class.is-invalid]="(PCSForms.controls['350'].touched && PCSForms.controls['350'].invalid ) || (PCSForms.controls['350'].invalid && isSubmit)"> -->
                          <ng-select
                       [items]="preClaim"
                       [class.is-invalid]="(PCSForms.controls['350'].touched &&
                       PCSForms.controls['350'].invalid) ||
                       (PCSForms.controls['350'].invalid && isSubmit)"
                       bindLabel="name"
                       placeholder="Enter Claim Amount"
                       bindValue="id"
                       formControlName="350"

                       >
                       </ng-select>
                       <div class="invalid-feedback"
                          *ngIf="(PCSForms.controls['350'].touched && PCSForms.controls['350'].invalid) || (PCSForms.controls['350'].invalid && isSubmit)">
                          Per Claim Amount is required and cannot be empty
                       </div>
                    </div>
                 </div>
              </td>
           </tr>
           <tr>
              <td colspan="8">
                 <!-- <div class="form-inline mb-1">
                    <label for="5">City/State/Zip<span class="mandatory">*</span>:</label>
                    <input type="text" class="form-control" id="5">
                    </div> -->
                 <div class="row">
                    <div class="col-3 pr-0"><label for="903">City/State/Zip<span class="mandatory">*</span>:</label>
                    </div>
                    <div class="col-3 pl-0">
                       <input type="text" placeholder="Enter City" id="903" class="form-control" formControlName="903"
                          [class.is-invalid]="(PCSForms.controls['903'].touched && PCSForms.controls['903'].invalid ) || (PCSForms.controls['903'].invalid && isSubmit)">
                       <div class="invalid-feedback"
                          *ngIf="(PCSForms.controls['903'].touched && PCSForms.controls['903'].invalid) || (PCSForms.controls['903'].invalid && isSubmit)">
                          City is required and cannot be empty
                       </div>
                    </div>
                    <div class="col-3 pl-0">
                      <!-- <input type="text" placeholder="Enter State" id="905" class="form-control" formControlName="905" -->
                         <!-- [class.is-invalid]="(PCSForms.controls['905'].touched && PCSForms.controls['905'].invalid ) || (PCSForms.controls['905'].invalid && isSubmit)"> -->
                         <ng-select
                       [items]="states"
                       [class.is-invalid]="(PCSForms.controls['905'].touched &&
                       PCSForms.controls['905'].invalid) ||
                       (PCSForms.controls['905'].invalid && isSubmit)"
                       bindLabel="name"
                       placeholder="Enter State"
                       bindValue="id"
                       formControlName="905"
                       >
                       </ng-select>
                      <div class="invalid-feedback"
                         *ngIf="(PCSForms.controls['905'].touched && PCSForms.controls['905'].invalid) || (PCSForms.controls['905'].invalid && isSubmit)">
                         State is required and cannot be empty
                      </div>
                   </div>
                    <div class="col-3 pl-0">
                       <input type="text" placeholder="Enter Zip" id="906" class="form-control" formControlName="906"
                          [class.is-invalid]="(PCSForms.controls['906'].touched && PCSForms.controls['906'].invalid ) || (PCSForms.controls['906'].invalid && isSubmit)">
                       <div class="invalid-feedback"
                          *ngIf="(PCSForms.controls['906'].touched && PCSForms.controls['906'].invalid) || (PCSForms.controls['906'].invalid && isSubmit)">
                          Zip is required and cannot be empty
                       </div>
                    </div>
                 </div>
              </td>
              <td colspan="4">
                 <!-- <div class="form-inline mb-1">
                    <label for="5">Aggregate Amount<span class="mandatory">*</span>:$</label>
                    <input type="text" class="form-control" id="5">
                    </div> -->
                 <div class="row">
                    <div class="col-5 pr-0"><label for="351">Aggregate Amount<span class="mandatory">*</span>: $</label>
                    </div>
                    <div class="col-7 pl-0">
                       <!-- <input type="text" placeholder="Enter Aggregate Amount" id="351" class="form-control" -->
                          <!-- formControlName="351" -->
                          <!-- [class.is-invalid]="(PCSForms.controls['351'].touched && PCSForms.controls['351'].invalid ) || (PCSForms.controls['351'].invalid && isSubmit)"> -->
                          <ng-select
                          [items]="aggregates"
                          [class.is-invalid]="(PCSForms.controls['351'].touched &&
                          PCSForms.controls['351'].invalid) ||
                          (PCSForms.controls['351'].invalid && isSubmit)"
                          bindLabel="name"
                          placeholder="Enter Aggregate Amount"
                          bindValue="id"
                          formControlName="351"

                          >
                          </ng-select>
                       <div class="invalid-feedback"
                          *ngIf="(PCSForms.controls['351'].touched && PCSForms.controls['351'].invalid) || (PCSForms.controls['351'].invalid && isSubmit)">
                          Aggregate Amount is required and cannot be empty
                       </div>
                    </div>
                 </div>
              </td>
           </tr>
           <tr>
              <td colspan="8">
                 <!-- <div class="form-inline mb-1">
                    <label for="5">Phone Number<span class="mandatory">*</span>:</label>
                    <input type="text" class="form-control" id="5">
                    </div> -->
                 <div class="row">
                    <div class="col-3 pr-0"><label for="1266">Phone Number<span class="mandatory">*</span>:</label>
                    </div>
                    <div class="col-9 pl-0">
                       <input type="text" placeholder="Enter Phone Number" id="1266" class="form-control"
                          formControlName="1266"
                          [class.is-invalid]="(PCSForms.controls['1266'].touched && PCSForms.controls['1266'].invalid ) || (PCSForms.controls['1266'].invalid && isSubmit)">
                       <div class="invalid-feedback"
                          *ngIf="(PCSForms.controls['1266'].touched && PCSForms.controls['1266'].invalid) || (PCSForms.controls['1266'].invalid && isSubmit)">
                          Phone Number is required and cannot be empty
                       </div>
                    </div>
                 </div>
              </td>
              <td colspan="4">
                 <!-- <div class="form-inline mb-1">
                    <label for="5">EXpiration Date<span class="mandatory">*</span>:</label>
                    <input type="text" class="form-control" id="5">
                    </div> -->
                 <div class="row">
                    <div class="col-4"><label for="349">Expiration Date<span class="mandatory">*</span>:</label>
                    </div>
                    <div class="col-8">
                     <div class="input-group">
                       <input type="text" placeholder="Enter Expiration Date" id="349" class="form-control"
                          formControlName="349"  (blur)="clearInputIfInvalidMandi('349')"
                          [class.is-invalid]="(PCSForms.controls['349'].touched && PCSForms.controls['349'].invalid ) || (PCSForms.controls['349'].invalid && isSubmit)" ngbDatepicker #d349="ngbDatepicker">
                          <div class="input-group-append">
                           <button class="btn btn-outline-secondary calendar" (click)="d349.toggle()" type="button">
                             <i class="fas fa-calendar-alt"></i>
                           </button>
                         </div>
                       </div>
                       <div class="text-danger" *ngIf="((PCSForms.controls['349'].invalid&&PCSForms.controls['349'].touched) && (PCSForms.controls['349'].value==''|| PCSForms.controls['349'].value==null) || ((PCSForms.controls['349'].invalid && isSubmit) && (PCSForms.controls['349'].value==''|| PCSForms.controls['349'].value==null)) ) " >
                        <small >
                           
                           EXpiration Date is required and cannot be empty
                        </small>
                      
                      </div>
                      <small class="text-danger" *ngIf="PCSForms.get('349').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)                </small>
                      <small class="text-danger" *ngIf="PCSForms.get('349').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="PCSForms.get('349').errors?.invalidDateyear">
                        year is out of range(1900-2099)                  </small>
                        <small class="text-danger" *ngIf="PCSForms.get('349').errors?.invalidDateRange">
                          date is out of range
                          </small>

                          <!-- <div class="invalid-feedback"
                          *ngIf="(PCSForms.controls['349'].touched && PCSForms.controls['349'].invalid) || (PCSForms.controls['349'].invalid && isSubmit)">
                          EXpiration Date is required and cannot be empty
                       </div> -->
                    </div>
                 </div>
              </td>
           </tr>
           <tr>
            <td colspan="12" class="p-0">
               <div class="coloredTitle">
                  Billing Agency/Agent Information (If Applicable)
               </div>
            </td>
         </tr>
         <tr>
            <td colspan="12">
               <div class="row">
                  <div class="col-4 pr-0"> 
                     <label for="5">Name of Agency/Agent:</label>
                  </div>
                  <div class="col-8 pl-0">   
                    <input type="text" Placeholder="Enter Name of Agency/Agent" formControlName="cmd_1088" class="form-control" id="cmd_1088">
               
                  </div>   
               </div>
            </td>
         </tr>
         <tr>

            <td colspan="12">
              <div class="row">
                <div class="col-4 pr-0"> <label for="cmd_1049">Mailing Address:</label>
                </div>
                <div class="col-4 pl-0">
                  <input type="text" Placeholder="Enter  Address 1" class="form-control" formControlName ="cmd_1089" id="cmd_1089">

                </div>
                <div class="col-4 pl-0">
                  <input type="text" Placeholder="Enter  Address 2" class="form-control" formControlName ="cmd_01089" id="cmd_01089">

                </div>
              </div>
              </td>

         </tr>
         <tr>
            <td colspan="6">
               <div class="row">
                <div class="col-3 pr-0"> <label for="cmd_1090">City/State/Zip:</label>
                </div>
                <div class="col-3 pl-0">
                   <input type="text" placeholder="Enter City" id="cmd_1090" class="form-control"
                      formControlName="cmd_1090">
                </div>
                <div class="col-3 pl-0">
                  <ng-select
                  [items]="states"
                  bindLabel="name"
                  placeholder="Enter State"
                  bindValue="id"
                  formControlName="cmd_1164"
                  >
                  </ng-select>
               </div>
               <div class="col-3 pl-0">
                <input type="text" placeholder="Enter Zip" id="cmd_1165" class="form-control"
                   formControlName="cmd_1165">
             </div>
             </div>
            </td>
            <td colspan="6">
               <div class="row"><div class="col-4">
                  <label for="cmd_1091">Phone Number:</label></div>
                  <div class="col-8">
                  <input type="text" Placeholder="Enter Phone Number" class="form-control" formControlName ="cmd_1091" id="cmd_1091">
                  <small class="text-danger" *ngIf="PCSForms.controls['cmd_1091'].errors?.pattern">
                    Only number are allowed</small>
               </div></div>
            </td>
         </tr>
         <tr>
            <td colspan="4">
               <div class="row"><div class="col-3">
                  <label for="5">Fax:</label></div>
                  <div class="col-8">
                  <input type="text" Placeholder="Enter Fax" class="form-control" formControlName="cmd_1092" id="cmd_1092">
               </div></div>
            </td>
            <td colspan="4">
               <div class="row"><div class="col-3">
                  <label for="5">Email:</label></div>
                  <div class="col-8">
                  <input type="text" Placeholder="Enter Email" class="form-control" formControlName = "cmd_1093" id="cmd_1093">
                  <small class="text-danger" *ngIf="PCSForms.controls['cmd_1093'].errors?.pattern">The
                    Email must be a valid email address</small>
               </div></div>
            </td>
            <td colspan="4">
               <div class="row"><div class="col-4">
                  <label for="5">Tax ID Number:</label></div>
                  <div class="col-8">
                  <input type="text" Placeholder="Enter Tax ID" class="form-control" formControlName="cmd_1094" id="cmd_1094">
               </div></div>
            </td>
         </tr>
           <tr>
              <td colspan="12" class="p-0">
                 <div class="coloredTitle">
                    Medicare Applications (If Applicable)
                 </div>
              </td>
           </tr>
           <tr>
              <td colspan="6">
                 <div class="row">
                  <div class="col-4">
                    <label for="5">Authorized Official Name:</label></div>
                    <div class="col-8">
                    <input type="text" placeholder="Enter Authorized Official Name " class="form-control" formControlName="cmd_1072" id="cmd_1072">
                 </div>
                 </div>
              </td>
              <td colspan="6">
                 <div class="row">
                    <div class="col-2">
                    <label for="5">Title:</label></div>
                    <div class="col-9">
                    <input type="text" placeholder="Enter Title" class="form-control" formControlName="cmd_1073" id="cmd_1073">
                  </div>
                  </div>
              </td>
           </tr>
           <tr>
              <td colspan="6">
                 <div class="row">
                  <div class="col-4">
                    <label for="5">Delegated Official Name:</label></div>
                    <div class="col-8">
                    <input type="text" placeholder="Enter Delegated Official Name" class="form-control" formControlName="cmd_1074" id="cmd_1074">
                 </div>
                 </div>
              </td>
              <td colspan="6">
               <div class="row">
                  <div class="col-2">
                    <label for="5">Title:</label></div>
                    <div class="col-9">
                    <input type="text" placeholder="Enter Title" class="form-control" formControlName="cmd_1075" id="cmd_1075">
                     </div>
                  </div>
              </td>
           </tr>
           <tr>
              <td colspan="6">
               <div class="row">
                  <div class="col-4">
                    <label for="5">Contact Person Name:</label></div>
                    <div class="col-8">
                    <input type="text" placeholder="Enter Contact Person Name" class="form-control" formControlName="cmd_1076" id="cmd_1076">
                     </div>
                  </div>
              </td>
              <td colspan="6">
               <div class="row">
                  <div class="col-2">
                    <label for="5">Title:</label></div>
                    <div class="col-9">
                    <input type="text" placeholder="Enter Title" class="form-control" formControlName="cmd_1077" id="cmd_1077">
                     </div>
                  </div>
              </td>
           </tr>
           <tr>
              <td colspan="4">
               <div class="row">
                  <div class="col-2">
                    <label for="cmd_1078">Email:</label></div>
                    <div class="col-9">
                    <input type="text" placeholder="Enter Email" class="form-control" formControlName = "cmd_1078" id="cmd_1078">
                    <small class="text-danger" *ngIf="PCSForms.controls['cmd_1078'].errors?.pattern">The
                      Email must be a valid email address</small>
                 </div>
                 </div>
              </td>
              <td colspan="4">
               <div class="row">
                  <div class="col-4">
                    <label for="cmd_1079">Phone Number:</label></div>
                    <div class="col-8">
                    <input type="text" placeholder="Enter Phone Number" class="form-control" formControlName = "cmd_1079" id="cmd_1079">
                    <small class="text-danger" *ngIf="PCSForms.controls['cmd_1079'].errors?.pattern">
                      Only number are allowed</small>
                      </div>
                 </div>
              </td>
              <td colspan="4">
               <div class="row">
                  <div class="col-2">
                    <label for="5">Fax:</label></div>
                    <div class="col-9">
                    <input type="text" placeholder="Enter Fax" class="form-control" formControlName="cmd_1080" id="cmd_1080">
                 </div>
                 </div>
              </td>
           </tr>
           <tr>
              <td colspan="12">
                 <div class="row">
                  <div class="col-4 pr-0"> <label for="cmd_1081">Contact Address:</label>
                  </div>
                  <div class="col-4 pl-0">
                    <input type="text" placeholder="Enter Contact Address 1" class="form-control" formControlName="cmd_1081" id="cmd_1081">
                  </div>
                  <div class="col-4 pl-0">
                    <input type="text" placeholder="Enter Contact Address 2" class="form-control" formControlName="cmd_01081" id="cmd_01081">
                  </div>
                </div>
              </td>
           </tr>
           <tr>
              <td colspan="12">
                 <div class="row">
                  <div class="col-3 pr-0"> <label for="cmd_1082">City/State/Zip:</label>
                  </div>
                  <div class="col-3 pl-0">
                     <input type="text" placeholder="Enter City" id="cmd_1082" class="form-control"
                        formControlName="cmd_1082">
                  </div>
                  <div class="col-3 pl-0">
                    <ng-select
                    [items]="states"
                    bindLabel="name"
                    placeholder="Enter State"
                    bindValue="id"
                    formControlName="cmd_1162"
                    >
                    </ng-select>
                 </div>
                 <div class="col-3 pl-0">
                  <input type="text" placeholder="Enter Zip" id="cmd_1163" class="form-control"
                     formControlName="cmd_1163">
               </div>
               </div>
              </td>
           </tr>
           <tr>
              <td colspan="12">
                 <div class="form-inline mb-1">
                    <label for="5">Do you render servcies in a patient's home?</label>&nbsp;&nbsp;
                    <input
                       type="radio"
                       id="cmd_1083"
                       name="cmd_1083"
                       formControlName="cmd_1083"
                       value="Yes"
                       />Yes&nbsp;&nbsp;
                    <input
                       type="radio"
                       id="cmd_1083"
                       name="cmd_1083"
                       formControlName="cmd_1083"
                       value="No"
                       />No
                 </div>
              </td>
           </tr>
           <tr>
              <td colspan="6">
                 <div class="row">
                  <div class="col-3">
                    <label for="5">If YES, City/Town:</label></div>
                    <div class="col-9">
                    <input type="text" Placeholder="Enter City" class="form-control" formControlName="cmd_1084" id="cmd_1084">
                 </div>
                 </div>
              </td>
              <td colspan="6">
               <div class="row">
                  <div class="col-2">
                    <label for="5">County:</label></div>
                   
                     <div class="col-9">
                    <input type="text" Placeholder="Enter County" class="form-control" formControlName="cmd_1085" id="cmd_1085">
                 </div>
                 </div>
              </td>
           </tr>
           <tr>
              <td colspan="6">
                 <div class="row">
                    <div class="col-3">
                    <label for="5">State:</label></div>
                    
                 <div class="col-7">
                  <ng-select
                  [items]="states"
                  bindLabel="name"
                  placeholder="Enter State"
                  bindValue="id"
                  formControlName="cmd_1086"
                  >
                  </ng-select>
               </div>
               </div>
              </td>
              <td colspan="6">
                 <div class="row"><div class="col-2">
                    <label for="5">Zip Code:</label></div>
                    <div class="col-8">
                    <input type="text" Placeholder="Enter Zip" class="form-control" formControlName="cmd_1087" id="cmd_1087">
                 </div></div>
              </td>
           </tr>

           <tr>
              <td colspan="12" class="p-0">
                 <div class="coloredTitle">
                    Reassigning Benefits (If Applicable)
                 </div>
              </td>
           </tr>
           <tr>
              <td colspan="12">
                 <div class="row">
                  <div class="col-4">
                    <label for="5">Name of Entity to Receive Benefits:</label></div>
                    <div class="col-8">
                    <input type="text" Placeholder="Enter Entity Name" class="form-control" formControlName="cmd_1095" id="cmd_1095">
                 </div></div>
              </td>
           </tr>
           <tr>
              <td colspan="6">
                 <div class="row"><div class="col-3">
                    <label for="5">Entity NPI Number:</label></div>
                     <div class="col-6">
                        <input type="text" Placeholder="Enter Entity NPI Number" class="form-control" formControlName="cmd_1096" id="cmd_1096">
                     </div>
               </div>
              </td>
              <td colspan="6">
                 <div class="row"><div class="col-3">
                    <label for="5">Entity PTAN:</label></div>
                    <div class="col-6">
                    <input type="text" Placeholder="Enter Entity PTAN" class="form-control" formControlName="cmd_1097" id="cmd_1097">
                 </div></div>
              </td>
           </tr>
           <tr>
              <td colspan="12" class="p-0">
                 <div class="coloredTitle">
                    Electronic Funds Transfer (EFT) (If Applicable)
                 </div>
              </td>
           </tr>
           <tr>
              <td colspan="12">
                <div class="row">
                  <div class="col-6 pr-0"> <label for="cmd_1049"><b>Legal Business</b>&nbsp;Name:</label>
                  </div>
                  <div class="col-6 pl-0">
                    <input type="text" Placeholder="Enter Legal Business" class="form-control" formControlName="cmd_1098" id="cmd_1098">
                  </div>
                </div>
                 <!-- <div class="form-inline mb-1">
                    <label for="5"><b>Legal Business</b>&nbsp;Name:</label>
                    <input type="text" Placeholder="Enter Legal Business" class="form-control" formControlName="cmd_1098" id="cmd_1098">
                 </div> -->
              </td>
           </tr>
           <tr>
              <td colspan="12">
                <div class="row">
                  <div class="col-4 pr-0"> <label for="cmd_1049"> Address:</label>
                  </div>
                  <div class="col-4 pl-0">
                    <input type="text" Placeholder="Enter Address 1" class="form-control" formControlName="cmd_1099" id="cmd_1099">

                  </div>
                  <div class="col-4 pl-0">
                    <input type="text" Placeholder="Enter Address 2" class="form-control" formControlName="cmd_01099" id="cmd_01099">

                  </div>
                </div>
                </td>


           </tr>
           <tr>
              <td colspan="6">
                 <!-- <div class="form-inline mb-1">
                    <label for="5">City/State/Zip:</label>
                    <input type="text" Placeholder="Enter City" class="form-control" formControlName="cmd_1100" id="cmd_1100">
                 </div> -->
                 <div class="row">
                  <div class="col-3 pr-0"> <label for="cmd_1100">City/State/Zip:</label>
                  </div>
                  <div class="col-3 pl-0">
                     <input type="text" placeholder="Enter City" id="cmd_1100" class="form-control"
                        formControlName="cmd_1100">
                  </div>
                  <div class="col-3 pl-0">
                    <ng-select
                    [items]="states"
                    bindLabel="name"
                    placeholder="Enter State"
                    bindValue="id"
                    formControlName="cmd_1166"
                    >
                    </ng-select>
                 </div>
                 <div class="col-3 pl-0">
                  <input type="text" placeholder="Enter Zip" id="cmd_1167" class="form-control"
                     formControlName="cmd_1167">
               </div>
               </div>
              </td>
              <td colspan="6">
                 <div class="form-inline mb-1">
                    <label for="5">Tax ID Number:</label>
                    <input type="text" Placeholder="Enter Tax ID" class="form-control" formControlName="cmd_1101" id="cmd_1101">
                 </div>
              </td>
           </tr>
           <tr>
              <td colspan="6">
                 <div class="form-inline mb-1">
                    <label for="5">Associated NPI Number:</label>
                    <input type="text" Placeholder="Enter Associated NPI Number" class="form-control" formControlName="cmd_1102" formControlName="cmd_1102" id="cmd_1102">
                 </div>
              </td>
              <td colspan="6">
                 <div class="form-inline mb-1">
                    <label for="5">Associated PTAN:</label>
                    <input type="text" Placeholder="Enter Associated PTAN" class="form-control" formControlName="cmd_1103" id="cmd_1103">
                 </div>
              </td>
           </tr>
           <tr>
              <td colspan="6">
                 <div class="row"><div class="col-5">
                    <label for="5"><b>Financial Institution</b>&nbsp;Name:</label></div>
                    <div class="col-6">
                    <input type="text" Placeholder="Enter Financial Institution" class="form-control" formControlName="cmd_1104" id="cmd_1104">
                 </div>
               </div>
              </td>
              <td colspan="6">
                 <div class="form-inline mb-1">
                    <label for="5">Contact Person:</label>
                    <input type="text" Placeholder="Enter Contact Person" class="form-control" formControlName="cmd_1105" id="cmd_1105">
                 </div>
              </td>
           </tr>
           <tr>
              <td colspan="12">
                <div class="row">
                  <div class="col-4 pr-0"> <label for="cmd_1106"> Address:</label>
                  </div>
                  <div class="col-4 pl-0">
                    <input type="text" Placeholder="Enter Address 1" class="form-control" formControlName="cmd_1106" id="cmd_1106">

                  </div>
                  <div class="col-4 pl-0">
                    <input type="text" Placeholder="Enter Address 2" class="form-control" formControlName="cmd_01106" id="cmd_01106">

                  </div>
                </div>
                </td>

           </tr>
           <tr>
              <td colspan="12">
                 <!-- <div class="form-inline mb-1">
                    <label for="5">City/State/Zip:</label>
                    <input type="text" Placeholder="Enter City" class="form-control" formControlName="cmd_1107" id="cmd_1107">
                 </div> -->
                 <div class="row">
                  <div class="col-3 pr-0"> <label for="cmd_1107">City/State/Zip:</label>
                  </div>
                  <div class="col-3 pl-0">
                     <input type="text" placeholder="Enter City" id="cmd_1107" class="form-control"
                        formControlName="cmd_1107">
                  </div>
                  <div class="col-3 pl-0">
                    <ng-select
                    [items]="states"
                    bindLabel="name"
                    placeholder="Enter State"
                    bindValue="id"
                    formControlName="cmd_1168"
                    >
                    </ng-select>
                 </div>
                 <div class="col-3 pl-0">
                  <input type="text" placeholder="Enter Zip" id="cmd_1169" class="form-control"
                     formControlName="cmd_1169">
               </div>
               </div>
              </td>
           </tr>
           <tr>
              <td colspan="6">
                 <div class="row"><div class="col-5">
                    <label for="5">Account Number:</label></div>
                    <div class="col-5">
                    <input type="text" Placeholder="Enter Account Number" class="form-control" formControlName="cmd_1108" id="cmd_1108">
                 </div></div>
              </td>
              <td colspan="6">
                 <div class="row"><div class="col-5">
                    <label for="5">Routing Number:</label></div>
                    <div class="col-5">
                    <input type="text" Placeholder="Enter Routing Number" class="form-control" formControlName="cmd_1109" id="cmd_1109">
                 </div></div>
              </td>
           </tr>
           <tr>
              <td colspan="12">
                 <div class="form-inline mb-1">
                    <!-- <input type="checkbox" value="Checking" id="cmd_1110">&nbsp;&nbsp;
                    <label for="Checking"> Checking</label>&nbsp;&nbsp;
                    <input type="checkbox" value="Savings" id="cmd_1110">&nbsp;&nbsp;
                    <label for="Savings">Savings</label>&nbsp;&nbsp;&nbsp;&nbsp;<span>*** Attach Copy of
                    &nbsp;<b>VOIDED</b>&nbsp;Check</span> -->

                    <input type="Radio" value="Checking" formControlName ="cmd_1110" id="cmd_1110">&nbsp;&nbsp;
                    <label for="Checking"> Checking</label>&nbsp;&nbsp;
                    <input type="Radio" value="Savings" formControlName ="cmd_1110" id="cmd_1110">&nbsp;&nbsp;
                    <label for="Savings">Savings</label>&nbsp;&nbsp;&nbsp;&nbsp;<span>*** Attach Copy of
                    &nbsp;<b>VOIDED</b>&nbsp;Check</span>
                 </div>
              </td>
           </tr>
           <tr>
              <td colspan="12" class="p-0">
                 <div class="coloredTitle">
                    Attestation Questions
                 </div>
              </td>
           </tr>
           <tr>
              <td colspan="12">
                 <div class="form-inline mb-1">
                    <label for="5"><b>Please answer the following questions 'yes' or 'no. If your answer to questions A through L is
                    'YES' of if your answer to M and N is 'NO', Please provide full details on separate sheet of
                    paper.</b></label>
                 </div>
              </td>
           </tr>
           <tr>
              <td colspan="10">
                 <label for="cmd_1111">A.<span class="mandatory">*</span>Has your licence to practice medicine in any
                 jurisdiction, your Drug Enforcement Administration (DEA) registration or any applicable narcotic registration
                 in any jurisdiction ever been denied, limited, restricted, suspended, revoked, not renewed, or subject to
                 probationary conditions, or have you voluntarily or involuntarily
                 relinquished any such licence or registration or voluntarily or involuntarily accepted any such actions or
                 conditions, or have you been fined or received a letter of reprimand or in such action pending?
                 </label>
              </td>
              <td colspan="2">
                 <div class="form-inline mb-1">
                    <input
                       type="radio"
                       id="cmd_1111"
                       name="cmd_1111"
                       formControlName="cmd_1111"
                       value="Yes"
                       />Yes&nbsp;&nbsp;
                    <input
                       type="radio"
                       id="cmd_1111"
                       name="cmd_1111"
                       formControlName="cmd_1111"
                       value="No"
                       />No
                       <div *ngIf="PCSForms.controls['cmd_1111'].invalid && isSubmit">
                        <small
                          class="text-danger"
                          *ngIf="PCSForms.controls['cmd_1111'].errors"
                        >
                          Please select YES or NO
                        </small>
                      </div>
                 </div>
              </td>
           </tr>
           <tr>
              <td colspan="10">
                 <label for="5">B.<span class="mandatory">*</span>Have you ever been charged, suspended, fined, disciplined, or
                 otherwise sactioned, subjected to probationary conditions, restricted
                 or excluded, or have you voluntarily or involuntarily relinquished eligibility to provide services or
                 accepted conditions on your eligibility to
                 provide services,for reasons to relating to possible incompetence or improper professional conduct,or
                 breach of contract or program conditions, by Medicare
                 ,medicaid, or any public program, or is any such action pending?
                 </label>
              </td>
              <td colspan="2">
                 <div class="form-inline mb-1">
                    <!-- <input type="Radio" value="Yes" id="cmd_1112">&nbsp;&nbsp;
                       <label for="yes"> Yes</label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                       <input type="Radio" value="No" id="cmd_1112">&nbsp;&nbsp;
                       <label for="Savings">No</label> -->
                    <input
                       type="radio"
                       id="cmd_1112"
                       name="cmd_1112"
                       formControlName="cmd_1112"
                       value="Yes"
                       />Yes&nbsp;&nbsp;
                    <input
                       type="radio"
                       id="cmd_1112"
                       name="cmd_1112"
                       formControlName="cmd_1112"
                       value="No"
                       />No
                       <div *ngIf="PCSForms.controls['cmd_1112'].invalid && isSubmit">
                        <small
                          class="text-danger"
                          *ngIf="PCSForms.controls['cmd_1112'].errors"
                        >
                          Please select YES or NO
                        </small>
                      </div>
                 </div>
              </td>
           </tr>
           <tr>
              <td colspan="10">
                 <label for="5">C.<span class="mandatory">*</span>
                 Have your  clinical privileges, membership, contractual, participation or employement by any medical
                 organization (e.g,hospital
                 medical staff, medical group, independent practice association (IPA), health plan,health maintenance
                 organization (HMO), preferred provider organization (PPO)&nbsp;
                 private payer (including those that contract will public programs), medical
                 socity, professional, association, medical school faculty position or other health delivery entity or system),
                 ever been denied, suspended, restricted, reduced, subject to probationary conditions, revoked or not renewed
                 for possible incompetence, improper professional
                 conduct or breach of contract, or is any such action pending?
                 </label>
              </td>
              <td colspan="2">
                 <div class="form-inline mb-1">
                    <!-- <input type="Radio" value="Yes" id="cmd_1113">&nbsp;&nbsp;
                       <label for="yes"> Yes</label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                       <input type="Radio" value="No" id="cmd_1113">&nbsp;&nbsp;
                       <label for="Savings">No</label> -->
                    <input
                       type="radio"
                       id="cmd_1113"
                       name="cmd_1113"
                       formControlName="cmd_1113"
                       value="Yes"
                       />Yes&nbsp;&nbsp;
                    <input
                       type="radio"
                       id="cmd_1113"
                       name="cmd_1113"
                       formControlName="cmd_1113"
                       value="No"
                       />No
                       <div *ngIf="PCSForms.controls['cmd_1113'].invalid && isSubmit">
                        <small
                          class="text-danger"
                          *ngIf="PCSForms.controls['cmd_1113'].errors"
                        >
                          Please select YES or NO
                        </small>
                      </div>
                 </div>
              </td>
           </tr>
           <tr>
              <td colspan="10">
                 <label for="5">D.<span class="mandatory">*</span>
                 Have your  ever surrendered,allow to expire voluntarily or involuntarily withdrawn a request for membership or
                 clinical privileges, terminated contractual
                 participation or employement or resigned from any medical organization (e.g, hospital medical staff, medical
                 group, independent practice association (IPA), health plan, health maintenance organization (HMO), preferred
                 provider organization (PPO)
                 private payer (including those that contract will public programs), medical
                 socity, professional association, medical school faculty position  or other health delivery entity or system) while under
                 investigation for possible imcompetence, improper
                 professional conduct or breach of contract, or in return for such an investigation not being conducted, or
                 is any such action pending?
                 </label>
              </td>
              <td colspan="2">
                 <div class="form-inline mb-1">
                    <!-- <input type="Radio" value="Yes" id="cmd_1114">&nbsp;&nbsp;
                       <label for="yes"> Yes</label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                       <input type="Radio" value="No" id="cmd_1114">&nbsp;&nbsp;
                       <label for="Savings">No</label> -->
                    <input
                       type="radio"
                       id="cmd_1114"
                       name="cmd_1114"
                       formControlName="cmd_1114"
                       value="Yes"
                       />Yes&nbsp;&nbsp;
                    <input
                       type="radio"
                       id="cmd_1114"
                       name="cmd_1114"
                       formControlName="cmd_1114"
                       value="No"
                       />No
                       <div *ngIf="PCSForms.controls['cmd_1114'].invalid && isSubmit">
                        <small
                          class="text-danger"
                          *ngIf="PCSForms.controls['cmd_1114'].errors"
                        >
                          Please select YES or NO
                        </small>
                      </div>
                 </div>
              </td>
           </tr>
           <tr>
              <td colspan="10">
                 <label for="5">E.<span class="mandatory">*</span>Have you ever surrendered, voluntarily withdrawn, or been
                 request or complelled to relinquish your status as a student in good
                 standing in any internship, residency, fellowship, preceptorship, or other clinical education program?
                 </label>
              </td>
              <td colspan="2">
                 <div class="form-inline mb-1">
                    <!-- <input type="Radio" value="Yes" id="cmd_1115">&nbsp;&nbsp;
                       <label for="yes"> Yes</label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                       <input type="Radio" value="No" id="cmd_1115">&nbsp;&nbsp;
                       <label for="Savings">No</label> -->
                    <input
                       type="radio"
                       id="cmd_1115"
                       name="cmd_1115"
                       formControlName="cmd_1115"
                       value="Yes"
                       />Yes&nbsp;&nbsp;
                    <input
                       type="radio"
                       id="cmd_1115"
                       name="cmd_1115"
                       formControlName="cmd_1115"
                       value="No"
                       />No
                       <div *ngIf="PCSForms.controls['cmd_1115'].invalid && isSubmit">
                        <small
                          class="text-danger"
                          *ngIf="PCSForms.controls['cmd_1115'].errors"
                        >
                          Please select YES or nO
                        </small>
                      </div>
                 </div>
              </td>
           </tr>
           <tr>
              <td colspan="10">
                 <label for="5">F.<span class="mandatory">*</span>Has your membership or fellowship in any
                 local, county, state, regional, national, or international professional organization
                 ever been revoked, denied, reduced,limited, subjected to probationary condtions, not renewed, or is any such action pending?
                 </label>
              </td>
              <td colspan="2">
                 <div class="form-inline mb-1">
                    <!-- <input type="Radio" value="Yes" id="cmd_1116">&nbsp;&nbsp;
                       <label for="yes"> Yes</label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                       <input type="Radio" value="No" id="cmd_1116">&nbsp;&nbsp;
                       <label for="Savings">No</label> -->
                    <input
                       type="radio"
                       id="cmd_1116"
                       name="cmd_1116"
                       formControlName="cmd_1116"
                       value="Yes"
                       />Yes&nbsp;&nbsp;
                    <input
                       type="radio"
                       id="cmd_1116"
                       name="cmd_1116"
                       formControlName="cmd_1116"
                       value="No"
                       />No
                       <div *ngIf="PCSForms.controls['cmd_1116'].invalid && isSubmit">
                        <small
                          class="text-danger"
                          *ngIf="PCSForms.controls['cmd_1116'].errors"
                        >
                          Please select YES or NO
                        </small>
                      </div>
                 </div>
              </td>
           </tr>
           <tr>
              <td colspan="10">
                 <label for="5">G.<span class="mandatory">*</span>Have you been denied certification/recertification by a
                  specialty board, or has your eligibility, certification or recertification status
                 changed (other than changing from eligible to certified) ?
                 </label>
              </td>
              <td colspan="2">
                 <div class="form-inline mb-1">
                    <!-- <input type="Radio" value="Yes" id="cmd_1117">&nbsp;&nbsp;
                       <label for="yes"> Yes</label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                       <input type="Radio" value="No" id="cmd_1117">&nbsp;&nbsp;
                       <label for="Savings">No</label> -->
                    <input
                       type="radio"
                       id="cmd_1117"
                       name="cmd_1117"
                       formControlName="cmd_1117"
                       value="Yes"
                       />Yes&nbsp;&nbsp;
                    <input
                       type="radio"
                       id="cmd_1117"
                       name="cmd_1117"
                       formControlName="cmd_1117"
                       value="No"
                       />No
                       <div *ngIf="PCSForms.controls['cmd_1117'].invalid && isSubmit">
                        <small
                          class="text-danger"
                          *ngIf="PCSForms.controls['cmd_1117'].errors"
                        >
                          Please select YES or NO
                        </small>
                      </div>
                 </div>
              </td>
           </tr>
           <tr>
              <td colspan="10">
                 <label for="5">H.<span class="mandatory">*</span>Have you ever been convicted as any crime (other than a
                 minor traffic violation) ?
                 </label>
              </td>
              <td colspan="2">
                 <div class="form-inline mb-1">
                    <!-- <input type="Radio" value="Yes" id="cmd_1118">&nbsp;&nbsp;
                       <label for="yes"> Yes</label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                       <input type="Radio" value="No" id="cmd_1118">&nbsp;&nbsp;
                       <label for="Savings">No</label> -->
                    <input
                       type="radio"
                       id="cmd_1118"
                       name="cmd_1118"
                       formControlName="cmd_1118"
                       value="Yes"
                       />Yes&nbsp;&nbsp;
                    <input
                       type="radio"
                       id="cmd_1118"
                       name="cmd_1118"
                       formControlName="cmd_1118"
                       value="No"
                       />No
                       <div *ngIf="PCSForms.controls['cmd_1118'].invalid && isSubmit">
                        <small
                          class="text-danger"
                          *ngIf="PCSForms.controls['cmd_1118'].errors"
                        >
                          Please select YES or NO
                        </small>
                      </div>
                 </div>
              </td>
           </tr>
           <tr>
              <td colspan="10">
                 <label for="5">I.<span class="mandatory">*</span>Do you presently use any drugs illegally?</label>
              </td>
              <td colspan="2">
                 <div class="form-inline mb-1">
                    <!-- <input type="Radio" value="Yes" id="cmd_1119">&nbsp;&nbsp;
                       <label for="yes"> Yes</label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                       <input type="Radio" value="No" id="cmd_1119">&nbsp;&nbsp;
                       <label for="Savings">No</label> -->
                    <input
                       type="radio"
                       id="cmd_1119"
                       name="cmd_1119"
                       formControlName="cmd_1119"
                       value="Yes"
                       />Yes&nbsp;&nbsp;
                    <input
                       type="radio"
                       id="cmd_1119"
                       name="cmd_1119"
                       formControlName="cmd_1119"
                       value="No"
                       />No
                       <div *ngIf="PCSForms.controls['cmd_1119'].invalid && isSubmit">
                        <small
                          class="text-danger"
                          *ngIf="PCSForms.controls['cmd_1119'].errors"
                        >
                          Please select YES or NO
                        </small>
                      </div>
                 </div>
              </td>
           </tr>
           <tr>
              <td colspan="10">
                 <label for="5">J.<span class="mandatory">*</span>Do you have any ongoing physical or mental impairment or
                 condition which would make unable, with or without
                 reasonable accommodation, to perform the essential functions of a practitioner in your area of practice or
                 unable to perform those essential functions
                 without a direct threat to the health and safety of others? Please describe any accommodations that could
                 reasonably be made to facilitate your performance
                 of such functions with out risk of compromise.
                 </label>
              </td>
              <td colspan="2">
                 <div class="form-inline mb-1">
                    <!-- <input type="Radio" value="Yes" id="cmd_1120">&nbsp;&nbsp;
                       <label for="yes"> Yes</label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                       <input type="Radio" value="No" id="cmd_1120">&nbsp;&nbsp;
                       <label for="Savings">No</label> -->
                    <input
                       type="radio"
                       id="cmd_1120"
                       name="cmd_1120"
                       formControlName="cmd_1120"
                       value="Yes"
                       />Yes&nbsp;&nbsp;
                    <input
                       type="radio"
                       id="cmd_1120"
                       name="cmd_1120"
                       formControlName="cmd_1120"
                       value="No"
                       />No
                       <div *ngIf="PCSForms.controls['cmd_1120'].invalid && isSubmit">
                        <small
                          class="text-danger"
                          *ngIf="PCSForms.controls['cmd_1120'].errors"
                        >
                          Please select YES or NO
                        </small>
                      </div>
                 </div>
              </td>
           </tr>
           <tr>

              <td colspan="10">
                 <label for="5">K.<span class="mandatory">*</span>Have any judgements been entered against you or settlements
                 been agreed to buy you with in the last seven (7) years, in
                 professional liability cases, or are there any field and served professional liability lawsuits/arbitrations
                 against you pending?
                 </label>
              </td>
              <td colspan="2">
                 <div class="form-inline mb-1">
                    <!-- <input type="Radio" value="Yes" id="cmd_1121">&nbsp;&nbsp;
                       <label for="yes"> Yes</label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                       <input type="Radio" value="No" id="cmd_1121">&nbsp;&nbsp;
                       <label for="Savings">No</label> -->
                    <input
                       type="radio"
                       id="cmd_1121"
                       name="cmd_1121"
                       formControlName="cmd_1121"
                       value="Yes"
                       />Yes&nbsp;&nbsp;
                    <input
                       type="radio"
                       id="cmd_1121"
                       name="cmd_1121"
                       formControlName="cmd_1121"
                       value="No"
                       />No
                       <div *ngIf="PCSForms.controls['cmd_1121'].invalid && isSubmit">
                        <small
                          class="text-danger"
                          *ngIf="PCSForms.controls['cmd_1121'].errors"
                        >
                          Please select YES or NO
                        </small>
                      </div>
                 </div>
              </td>
           </tr>
           <tr>
              <td colspan="10">
                 <label for="5">L.<span class="mandatory">*</span>Has your professional liability insurance ever been
                 terminated, not renewed, restricted, or modified
                 (e.g reduced limits, restricted coverage, surcharged) or have you ever been denied professional liability
                 insurance, or has any professional liability
                 carried provided you with written noitce of any intent to deny, cancel, not renew, or limit your
                 professional liability insurance or its coverage of any procedures?
                 </label>
              </td>
              <td colspan="2">
                 <div class="form-inline mb-1">
                    <!-- <input type="Radio" value="Yes" id="cmd_1122">&nbsp;&nbsp;
                       <label for="yes"> Yes</label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                       <input type="Radio" value="No" id="cmd_1123">&nbsp;&nbsp;
                       <label for="Savings">No</label> -->
                    <input
                       type="radio"
                       id="cmd_1123"
                       name="cmd_1123"
                       formControlName="cmd_1123"
                       value="Yes"
                       />Yes&nbsp;&nbsp;
                    <input
                       type="radio"
                       id="cmd_1123"
                       name="cmd_1123"
                       formControlName="cmd_1123"
                       value="No"
                       />No
                       <div *ngIf="PCSForms.controls['cmd_1123'].invalid && isSubmit">
                        <small
                          class="text-danger"
                          *ngIf="PCSForms.controls['cmd_1123'].errors"
                        >
                          Please select YES or NO
                        </small>
                      </div>
                 </div>
              </td>
           </tr>
           <tr>
              <td colspan="10">
                 <label for="5">M.<span class="mandatory">*</span>Is your professional liability insurance valid and current?
                 </label>
              </td>
              <td colspan="2">
                 <div class="form-inline mb-1">
                    <!-- <input type="Radio" value="Yes" id="cmd_1124">&nbsp;&nbsp;
                       <label for="yes"> Yes</label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                       <input type="Radio" value="No" id="cmd_1124">&nbsp;&nbsp;
                       <label for="Savings">No</label> -->
                    <input
                       type="radio"
                       id="cmd_1124"
                       name="cmd_1124"
                       formControlName="cmd_1124"
                       value="Yes"
                       />Yes&nbsp;&nbsp;
                    <input
                       type="radio"
                       id="cmd_1124"
                       name="cmd_1124"
                       formControlName="cmd_1124"
                       value="No"
                       />No
                       <div *ngIf="PCSForms.controls['cmd_1124'].invalid && isSubmit">
                        <small
                          class="text-danger"
                          *ngIf="PCSForms.controls['cmd_1124'].errors"
                        >
                          Please select YES or NO
                        </small>
                      </div>
                 </div>
              </td>
           </tr>
           <tr>
              <td colspan="10">
                 <label for="5">N.<span class="mandatory">*</span>Are you able to perform all the services required by your
                  agreement with, or the professional staffbylaws
                 of, the healthcare organization to which you are applying, with or without reasonable accommodation, according
                 to accepted standards of professional
                 performance and without posing a direct threat to the safety of patients?
                 </label>
              </td>
              <td colspan="2">
                 <div class="form-inline mb-1">
                    <!-- <input type="Radio" value="Yes" id="cmd_1125">&nbsp;&nbsp;
                       <label for="yes"> Yes</label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                       <input type="Radio" value="No" id="cmd_1125">&nbsp;&nbsp;
                       <label for="Savings">No</label> -->
                    <input
                       type="radio"
                       id="cmd_1125"
                       name="cmd_1125"
                       formControlName="cmd_1125"
                       value="Yes"
                       />Yes&nbsp;&nbsp;
                    <input
                       type="radio"
                       id="cmd_1125"
                       name="cmd_1125"
                       formControlName="cmd_1125"
                       value="No"
                       />No
                       <div *ngIf="PCSForms.controls['cmd_1125'].invalid && isSubmit">
                        <small
                          class="text-danger"
                          *ngIf="PCSForms.controls['cmd_1125'].errors"
                        >
                          Please select YES or NO
                        </small>
                      </div>
                 </div>
              </td>
           </tr>
           <tr>
              <td colspan="12" class="p-0">
                 <div class="coloredTitle">
                    Additional Questions
                 </div>
              </td>
           </tr>
           <tr>
              <td colspan="10">
                 <label for="5">A.<span class="mandatory">*</span>Do you have any lab certificates (such as CLIA/Waiver) ?
                 </label>
              </td>
              <td colspan="2">
                 <div class="form-inline mb-1">
                    <!-- <input type="Radio" value="Yes" id="cmd_1126">&nbsp;&nbsp;
                       <label for="yes"> Yes</label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                       <input type="Radio" value="No" id="cmd_1126">&nbsp;&nbsp;
                       <label for="Savings">No</label> -->
                    <input
                       type="radio"
                       id="cmd_1126"
                       name="cmd_1126"
                       formControlName="cmd_1126"
                       value="Yes"
                       />Yes&nbsp;&nbsp;
                    <input
                       type="radio"
                       id="cmd_1126"
                       name="cmd_1126"
                       formControlName="cmd_1126"
                       value="No"
                       />No
                       <div *ngIf="PCSForms.controls['cmd_1126'].invalid && isSubmit">
                        <small
                          class="text-danger"
                          *ngIf="PCSForms.controls['cmd_1126'].errors"
                        >
                          Please select YES or NO
                        </small>
                      </div>
                 </div>
              </td>
           </tr>
           <tr>
              <td colspan="10">
                 <label for="5">B.<span class="mandatory">*</span>Do you have any board certifications?
                 </label>
              </td>
              <td colspan="2">
                 <div class="form-inline mb-1">
                    <!-- <input type="Radio" value="Yes" id="cmd_1127">&nbsp;&nbsp;
                       <label for="yes"> Yes</label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                       <input type="Radio" value="No" id="cmd_1127">&nbsp;&nbsp;
                       <label for="Savings">No</label> -->
                    <input
                       type="radio"
                       id="cmd_1127"
                       name="cmd_1127"
                       formControlName="cmd_1127"
                       value="Yes"
                       />Yes&nbsp;&nbsp;
                    <input
                       type="radio"
                       id="cmd_1127"
                       name="cmd_1127"
                       formControlName="cmd_1127"
                       value="No"
                       />No
                       <div *ngIf="PCSForms.controls['cmd_1127'].invalid && isSubmit">
                        <small
                          class="text-danger"
                          *ngIf="PCSForms.controls['cmd_1127'].errors"
                        >
                          Please select YES or NO
                        </small>
                      </div>
                 </div>
              </td>
           </tr>
           <tr>
              <td colspan="10">
                 <label for="5">C.<span class="mandatory">*</span>Do you have any other certifications?
                 </label>
              </td>
              <td colspan="2">
                 <div class="form-inline mb-1">
                    <!-- <input type="Radio" value="Yes" id="cmd_1128">&nbsp;&nbsp;
                       <label for="yes"> Yes</label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                       <input type="Radio" value="No" id="cmd_1128">&nbsp;&nbsp;
                       <label for="Savings">No</label> -->
                    <input
                       type="radio"
                       id="cmd_1128"
                       name="cmd_1128"
                       formControlName="cmd_1128"
                       value="Yes"
                       />Yes&nbsp;&nbsp;
                    <input
                       type="radio"
                       id="cmd_1128"
                       name="cmd_1128"
                       formControlName="cmd_1128"
                       value="No"
                       />No
                       <div *ngIf="PCSForms.controls['cmd_1128'].invalid && isSubmit">
                        <small
                          class="text-danger"
                          *ngIf="PCSForms.controls['cmd_1128'].errors"
                        >
                          Please  Select YES or NO
                        </small>
                      </div>
                 </div>
              </td>
           </tr>
           <tr>
              <td colspan="10">
                 <label for="5">D.<span class="mandatory">*</span>Do you have any other state licenses?
                 </label>
              </td>
              <td colspan="2">
                 <div class="form-inline mb-1">
                    <!-- <input type="Radio" value="Yes" id="cmd_1129">&nbsp;&nbsp;
                       <label for="yes"> Yes</label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                       <input type="Radio" value="No" id="cmd_1129">&nbsp;&nbsp;
                       <label for="Savings">No</label> -->
                    <input
                       type="radio"
                       id="cmd_1129"
                       name="cmd_1129"
                       formControlName="cmd_1129"
                       value="Yes"
                       />Yes&nbsp;&nbsp;
                    <input
                       type="radio"
                       id="cmd_1129"
                       name="cmd_1129"
                       formControlName="cmd_1129"
                       value="No"
                       />No
                       <div *ngIf="PCSForms.controls['cmd_1129'].invalid && isSubmit">
                        <small
                          class="text-danger"
                          *ngIf="PCSForms.controls['cmd_1129'].errors"
                        >
                          Please select YES or NO
                        </small>
                      </div>
                 </div>
              </td>
           </tr>
           <tr>
              <td colspan="10">
                 <label for="5">E.<span class="mandatory">*</span>Does your office dispense DME (Durable Medical Equipment) to
                 Medicare recipients?
                 </label>
              </td>
              <td colspan="2">
                 <div class="form-inline mb-1">
                    <!-- <input type="Radio" value="Yes" id="cmd_1130">&nbsp;&nbsp;
                       <label for="yes"> Yes</label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                       <input type="Radio" value="No" id="cmd_1130">&nbsp;&nbsp;
                       <label for="Savings">No</label> -->
                    <input
                       type="radio"
                       id="cmd_1130"
                       name="cmd_1130"
                       formControlName="cmd_1130"
                       value="Yes"
                       />Yes&nbsp;&nbsp;
                    <input
                       type="radio"
                       id="cmd_1130"
                       name="cmd_1130"
                       formControlName="cmd_1130"
                       value="No"
                       />No
                       <div *ngIf="PCSForms.controls['cmd_1130'].invalid && isSubmit">
                        <small
                          class="text-danger"
                          *ngIf="PCSForms.controls['cmd_1130'].errors"
                        >
                          Please select YES or NO
                        </small>
                      </div>
                 </div>
              </td>
           </tr>
           <tr>
              <td colspan="10">
                 <label for="5">F.<span class="mandatory">*</span>Are you a certified Worker's Compenstation provider?
                 </label>
              </td>
              <td colspan="2">
                 <div class="form-inline mb-1">
                    <!-- <input type="Radio" value="Yes" id="cmd_1131">&nbsp;&nbsp;
                       <label for="yes"> Yes</label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                       <input type="Radio" value="No" id="cmd_1131">&nbsp;&nbsp;
                       <label for="Savings">No</label> -->
                    <input
                       type="radio"
                       id="cmd_1131"
                       name="cmd_1131"
                       formControlName="cmd_1131"
                       value="Yes"
                       />Yes&nbsp;&nbsp;
                    <input
                       type="radio"
                       id="cmd_1131"
                       name="cmd_1131"
                       formControlName="cmd_1131"
                       value="No"
                       />No
                       <div *ngIf="PCSForms.controls['cmd_1131'].invalid && isSubmit">
                        <small
                          class="text-danger"
                          *ngIf="PCSForms.controls['cmd_1131'].errors"
                        >
                          Please select YES or No
                        </small>
                      </div>
                 </div>
              </td>
           </tr>
           <tr>
              <td colspan="12" class="p-0">
                 <div class="coloredTitle">
                  Documentation Required-*MUST be uploaded when the Provider Portal Access has been granted. Access will be sent under separate cover.
                 </div>
              </td>
           </tr>

           <tr>
              <td colspan="12" class="p-0">
                  <div class="form-inline" style="padding-left:20px">
                        <label >State Medical License <b style="padding-left:10px"> (Required)</b></label>
                     
                  </div>
                  <div class="form-inline" style="padding-left:20px">
                        <label>Face Sheet of Professional Liability Certification <b style="padding-left:10px"> (Required)</b>
                        </label>
                       
                 </div>
                 <div class="form-inline" style="padding-left:20px">
                   <label >DEA Certificate <b style="padding-left:10px"> (Required)</b>
                    </label>
                 

                 </div>
                 <div class="form-inline" style="padding-left:20px">
                      <label>Curriculum Vitae <b style="padding-left:10px"> (Required)</b>
                        </label>
                      
                 </div>
                 <div class="form-inline" style="padding-left:20px">
                  <label >IRS CP-575  (Verifying Entity Name and Tax ID Number)
                     <b style="padding-left:10px"> (Required)</b></label>
                  
                 </div>
                 <div class="form-inline" style="padding-left:20px">
                    
                    <label >CDS Certificate (if applicable)</label>
                 </div>
                 <div class="form-inline" style="padding-left:20px">
                    
                    <label >CLIA Certificate (if applicable)</label>
                 </div>
                 <div class="form-inline" style="padding-left:20px">
                    
                    <label >Board Certification(s) Certificate(s) (if applicable)</label>
                 </div>
                 <div class="form-inline" style="padding-left:20px">
                    
                    <label >If DMERC Enrollment, Liability Insurance MUST list NSC (National Supplier
                    Clearinghouse) as
                    the insured</label>
                 </div>
              </td>
           </tr>
           <tr>
              <td colspan="12" bgcolor="black">
              </td>
           </tr>
           <tr>
              <td colspan="12" class="p-0">
                 <label style="padding-left:5px">
                    I hereby affirm that the information, Attestation Questions, and any agenda there to is
                       true, current, correct, and complete to the
                       best of my  knowledge and belief and is furnished in good faith.I understand the material, omissions or
                       misrepresentations may result in denial
                       of my application or termination of my privileges, employment or physician agreement.
                 </label>
                 <div class="form-inline">
                    <label>
                       <h6>Provider Name (Print Clearly)</h6>
                    </label>
                    <span class="mandatory">*</span>
                    <!-- <input type="text" Placeholder="Enter Provider Name" class="form-control"
                       id="cmd_1142">&nbsp;&nbsp;&nbsp;&nbsp; -->
                       <input type="text" placeholder="Enter Provider Name" id="cmd_1142" class="form-control" formControlName="cmd_1142"
                       [class.is-invalid]="(PCSForms.controls['cmd_1142'].touched && PCSForms.controls['cmd_1142'].invalid ) || (PCSForms.controls['cmd_1142'].invalid && isSubmit)">&nbsp;&nbsp;&nbsp;&nbsp;
                    <div class="invalid-feedback"
                       *ngIf="(PCSForms.controls['cmd_1142'].touched && PCSForms.controls['cmd_1142'].invalid) || (PCSForms.controls['cmd_1142'].invalid && isSubmit)">
                      Provider name is required and cannot be empty
                    </div>
                    <label>
                       <h6>Provider Signature</h6>
                    </label>
                    <span class="mandatory">*</span>
                    <div class="col-md-3 signaturePadding">
                      <img
                        *ngIf="!showOne"
                        class="img"
                        src="./assets/images/e_sign.png"
                        alt="Credential my Doc"
                        (click)="esignOpenOne()"
                      />
                      <img
                        *ngIf="showOne"
                        class="img eSignImageShow"
                        [src]="esignOne"
                        alt="Credential my Doc"
                        (click)="esignOpenOne()"
                      />
                      <!-- <input
                        type="hidden"
                        name="cmd_11122"
                        id="cmd_1143"
                        formControlName="cmd_1143"
                        value="{{ finalImgPath }}"
                      /> -->
                    
                       <input type="hidden" placeholder="Enter Provider Signature" name="cmd_1143" value ="{{finalImgPath}}"id="cmd_1143" class="form-control" formControlName="cmd_1143"
                       [class.is-invalid]="(PCSForms.controls['cmd_1143'].touched && PCSForms.controls['cmd_1143'].invalid ) || (PCSForms.controls['cmd_1143'].invalid && isSubmit)">&nbsp;&nbsp;&nbsp;&nbsp;
                        <div class="invalid-feedback"
                           *ngIf="(PCSForms.controls['cmd_1143'].touched && PCSForms.controls['cmd_1143'].invalid) || (PCSForms.controls['cmd_1143'].invalid && isSubmit)">
                           Provider Signature is required and cannot be empty
                        </div>
                     </div>
                  </div>
                     <div class="row">
                        <div class="col-md-1">
                                 <label>
                                    <h6>Date</h6>
                                 </label>
                                 
                                 <span class="mandatory">*</span>
                        </div>      
                        <div class="col-md-3">
                                 
                                 <div class="input-group">
                                       <input type="text" placeholder="Enter Date" id="cmd_1144" class="form-control" formControlName="cmd_1144"  (blur)="clearInputIfInvalidMandi('cmd_1144')"
                                          [class.is-invalid]="(PCSForms.controls['cmd_1144'].touched && PCSForms.controls['cmd_1144'].invalid ) || (PCSForms.controls['cmd_1144'].invalid && isSubmit)" ngbDatepicker #d1144="ngbDatepicker">
                                          <div class="input-group-append">
                                             <button class="btn btn-outline-secondary calendar" (click)="d1144.toggle()" type="button">
                                             <i class="fas fa-calendar-alt"></i>
                                             </button>
                                          </div>
                                 </div>    
                                 
                                 <div class="text-danger" *ngIf="((PCSForms.controls['cmd_1144'].invalid&&PCSForms.controls['cmd_1144'].touched) && (PCSForms.controls['cmd_1144'].value==''|| PCSForms.controls['cmd_1144'].value==null) || ((PCSForms.controls['cmd_1144'].invalid && isSubmit) && (PCSForms.controls['cmd_1144'].value==''|| PCSForms.controls['cmd_1144'].value==null)) ) " >
                                    <small >
                                       
                                       Date is required and cannot be empty
                                    </small>
                                  
                                  </div>
                                  <small class="text-danger" *ngIf="PCSForms.get('cmd_1144').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY)                </small>
                                  <small class="text-danger" *ngIf="PCSForms.get('cmd_1144').errors?.invalidMonth">
                                  Month is out of range
                                  </small>
                                  <small class="text-danger" *ngIf="PCSForms.get('cmd_1144').errors?.invalidDateyear">
                                    year is out of range(1900-2099)                  </small>
                                    <small class="text-danger" *ngIf="PCSForms.get('cmd_1144').errors?.invalidDateRange">
                                      date is out of range
                                      </small>
                                          <!-- <div class="invalid-feedback"
                                          *ngIf="(PCSForms.controls['cmd_1144'].touched && PCSForms.controls['cmd_1144'].invalid) || (PCSForms.controls['cmd_1144'].invalid && isSubmit)">
                                          Date is required and cannot be empty
                                          </div> -->
                        </div> 
                     </div>      
                 
               </td>
            </tr>  
            <!-- <tr>
               <td colspan="12" class="p-0"> 
                  <div CLASS="row">
                     <div class="col-md-2">
                              <label>
                                 <h6>Date</h6>
                              </label>
                              
                              <span class="mandatory">*</span>
                     </div>      
                     <div class="col-md-3">
                              
                              <div class="input-group">
                                    <input type="text" placeholder="Enter Date" id="cmd_1144" class="form-control" formControlName="cmd_1144"
                                       [class.is-invalid]="(PCSForms.controls['cmd_1144'].touched && PCSForms.controls['cmd_1144'].invalid ) || (PCSForms.controls['cmd_1144'].invalid && isSubmit)" ngbDatepicker #d1144="ngbDatepicker">
                                       <div class="input-group-append">
                                          <button class="btn btn-outline-secondary calendar" (click)="d1144.toggle()" type="button">
                                          <i class="fas fa-calendar-alt"></i>
                                          </button>
                                       </div>
                              </div>      
                                       <div class="invalid-feedback"
                                       *ngIf="(PCSForms.controls['cmd_1144'].touched && PCSForms.controls['cmd_1144'].invalid) || (PCSForms.controls['cmd_1144'].invalid && isSubmit)">
                                       Date is required and cannot be empty
                                       </div>
                     </div> 
                  </div>                    
                          
                 
              </td>
           </tr> -->
        </tbody>
     </table>
  </form>
  <button
  *ngIf="formData.receivedForm"
  type="button"
  class="btn customOrange float-right"
  (click)="saveForm('approve')"
>
  Approve
</button>
  <button
  *ngIf="formData.submitForm"
     type="button"
     class="btn btn customOrange float-right"
     (click)="saveForm('submit')"
     >
  Submit
  </button>
  <button
  *ngIf="formData.submitForm"
     type="button"
     class="btn btn customOrange float-right"
     (click)="saveForm('save')"
     >
  Save
  </button>

  <button
  *ngIf="formData.previewForm"
      type="button"
      class="btn customOrange float-right"
      (click)="close()"
    >
      Close
    </button>
</div>
