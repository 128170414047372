
import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { OnboardingformsServiceService } from '../../services/onboardingforms-service/onboardingforms-service.service';
import { ToasterNotiService } from '../../services/notifications/toaster-noti.service';
import { CustomDateParserFormatter, CustomAdapter } from '../../DateFormatter/customDate';
import { NgbDateParserFormatter, NgbDateAdapter, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { StorageService } from '../../services/storage/storage.service';

import { EsignComponent } from '../esign/esign.component';
import { CommonService } from '../../../shared/services/common/common.service';

import { DomSanitizer } from '@angular/platform-browser';
// import { Console } from 'console';
@Component({
selector: 'ctg-on-point-medical',
templateUrl: './on-point-medical.component.html',
styleUrls: ['./on-point-medical.component.scss'],
providers: [
{ provide: NgbDateAdapter, useClass: CustomAdapter },
{ provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }
]
})
export class OnPointMedicalComponent implements OnInit {
constructor(
private fb: UntypedFormBuilder,
private onboardService: OnboardingformsServiceService,
private notiService: ToasterNotiService,
private modalService: NgbModal,
private storageService: StorageService,
private _sanitizer: DomSanitizer,
private commonService: CommonService

) { }
@Input() formData;
@Input() fieldsData;
@Input() formId;
@Output() onSubmit = new EventEmitter();
@Output() onClose = new EventEmitter();
modalOption: NgbModalOptions = {
size: 'md',
keyboard: false,
backdrop: 'static'
};

dName:boolean=false;
isSubmit: boolean = false;
finalImgPath: any = [];
finalImg: any;
z:any;
esignOne: any;
showOne: any = false;
onPointForm: UntypedFormGroup;
suffix: Array<object> = [];
credentials: Array<object> = [];
states: Array<object> = [];
degree: Array<object> = [];
specialty: Array<object> = [];
gender: Array<object> = [];
AltCdsStates: Array<object> = [];
altLicenseState: Array<object> = [];
additionalLicenseState: Array<object> = [];
boardCertification: Array<object> = [];
specialtySecondary: Array<object> = [];
specialtyTer: Array<object> = [];
staffStatus: Array<object> = [];
preClaim: Array<object> = [];
aggregates: Array<object> = [];
county: Array<object> = [];
race: Array<object> = [];
languages: Array<any> = [];
secondaryLanguages: Array<object> = [];
tertiaryLanguages: Array<object> = [];
quaternaryLanguages: Array<object> = [];
statusDropdown: Array<any> = [];
boardStatus: Array<any> = []

CMEDropdown: Array<any> = [];
public stateDropDownDate: any = []

ngOnInit(): void {
this.buildForm();
this.loadDropdowns();
}

buildForm() {
this.onPointForm = this.fb.group({
9: ['', [Validators.required]],
8: ['', [Validators.required]],
809: [null],
29: [null],
822: [null, [Validators.required]],
284: [null, [Validators.required]],
962: [null],
278: [''],
280: [null,this.dateFormatValidator],
281: [null,this.dateFormatValidator],
348: [null],
349: [null],
31: [null, [Validators.required,this.dateFormatValidator]],
32: ['', Validators.required],
30: [null],
741: [null],
810: ['', Validators.required],
289: [null, Validators.required],
291: ['',Validators.required],
16: ['', [Validators.required]],
17: [''],
18: ['', [Validators.required]],
19: [null, [Validators.required]],
cmd_838: [''],
20: [null, Validators.required],
21: ['', Validators.required],
// 709: ['',[ Validators.required,Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]],
709: ['',[ Validators.required]],

759: ['', Validators.required],
1634: ['', Validators.required],
1635: [''],
1636: [''],
277: [null, [Validators.required]],
1535: [null],
1536: [null],
2207: [null],

812: [''],
325: [''],
//malpractise-cliams
1208: [null,this.dateFormatValidator],
1209: [null,this.dateFormatValidator],
1858: [''],
1226: [null,this.dateFormatValidator],
1210: [''],
1859: [''],
2012: [''],
1844: [''],
1845: [''],
1847: [null],
1848: [''],
1849: [''],

//healthstatus && vaccinations.
2135: [null,this.dateFormatValidator],
826: [null,this.dateFormatValidator],
1474: [''],
1529: [null,this.dateFormatValidator],
530:[null],
1530:[null,this.dateFormatValidator],
1962: [null],
2322: [null,this.dateFormatValidator],
4316: [null,this.dateFormatValidator],
4317: [null,this.dateFormatValidator],
4421: [null,this.dateFormatValidator],

//peer-references
359: ['', Validators.required],
2722: [''],
2721: [''],
368: [null],
360: [''],
361: [''],
362: [''],
364: [null],
365: [''],
369: ['', Validators.required],
370: [''],
371: ['', Validators.required],
366: [null,this.dateFormatValidator],
367: [null,this.dateFormatValidator],

372: ['', Validators.required],
2723: [''],
380: [null],
373: [''],
374: [''],
375: [''],
377: [null],
378: [''],
381: ['', Validators.required],
382: [''],
383: ['', Validators.required],
817: [null,this.dateFormatValidator],
379: [null,this.dateFormatValidator],
2724: [''],

384: ['', Validators.required],
2725: [''],
2726: [''],
393: [null],
385: [''],
386: [''],
387: [''],
389: [null],
390: [''],
394: ['', Validators.required],
395: [''],
396: ['', Validators.required],
391: [null,this.dateFormatValidator],
392: [null,this.dateFormatValidator],

//board-certifications
554: [null],
1234:[null],
556: [null],
// 557:[null,[Validators.required,Validators.pattern('/^\d{1,2}\/\d{1,2}\/\d{4}$/')]],
557:[null,this.dateFormatValidator],

560: [null,this.dateFormatValidator],

561: [null],
1235: [null],
563: [null],
564: [null,this.dateFormatValidator],
567: [null,this.dateFormatValidator],

//Internship, Residency and Fellowship

// 284:[''],
525: [null,this.dateFormatValidator],
526: [null,this.dateFormatValidator],
518: [''],
519: [''],
520: [''],
522: [null],
528: [''],
1318: [''],
1319: [''],
2539: [''],

3462: [''],
529: [''],
// 962:[''],
537: [null,this.dateFormatValidator],
538: [null,this.dateFormatValidator],
539: [null,this.dateFormatValidator],
531: [''],
1498: [''],
533: [''],
535: [null],
536: [''],
540: [''],
527:[null],
542: [''],
543: [null],
550: [null,this.dateFormatValidator],
551: [null,this.dateFormatValidator],
552: [null,this.dateFormatValidator],
544: [''],
545: [''],
546: [''],
548: [null],

549: [''],
553: [''],
1322: [''],
1323: [''],
2542: [''],
3466: [''],

1316: [''],

1317: [''],
2540: [''],
3464: [''],
add_1_1170: [''],
add_2_1170: [''],
add_1_1171: [null],
add_2_1171: [null],
add_1_1178: [null,this.dateFormatValidator],
add_2_1178: [null,this.dateFormatValidator],
add_1_1179: [null,this.dateFormatValidator],
add_2_1179: [null,this.dateFormatValidator],
add_1_1180: [null,this.dateFormatValidator],
add_2_1180: [null,this.dateFormatValidator],
add_1_1181: [null],
add_2_1181: [null],
add_1_1172: [''],
add_2_1172: [''],
add_1_1173: [''],
add_2_1173: [''],
add_1_1174: [''],
add_2_1174: [''],
add_1_1176: [null],
add_2_1176: [null],
add_1_1177: [''],
add_2_1177: [''],
add_1_1320: [''],
add_2_1320: [''],
add_1_1321: [''],
add_2_1321: [''],
add_1_2541: [''],
add_2_2541: [''],
add_1_3465: [''],
add_2_3465: [''],








312: [null],
// cme_category: [null],
// cmd_839: [null],
// section 2
293: [''],
296: [null, ],
299: [null,],
294: [null,this.dateFormatValidator],
295: [null,this.dateFormatValidator],
300: [null],
1274: [null],
907: [null],
307: [null,this.dateFormatValidator],
308: [null,this.dateFormatValidator],
999: [null],
1432: [null],
1010: [null],
1003: [null,this.dateFormatValidator],
1004: [null,this.dateFormatValidator],
cmd_2: [''],
2110: [''],
2111: [''],
1549: [''],
309: [''],
310: [null,this.dateFormatValidator],
311: [null,this.dateFormatValidator],
//N.A
333: [''],
334: [''],
335: [''],
517: [''],
2858: [null],
524: [null,this.dateFormatValidator],
523: [null],

cmd_65: [''],
cmd_64: [''],
cmd_63: [''],
cmd_62: [''],
cmd_61: [null,this.dateFormatValidator],
cmd_60:[''],
cmd_59: [''],
cmd_58: [null,this.dateFormatValidator],
cmd_57: [''],
cmd_56: [null,this.dateFormatValidator],
cmd_55: [''],
cmd_54: [''],
cmd_53: [null,this.dateFormatValidator],
cmd_52: [''],
cmd_51: [''],
cmd_49: [null,this.dateFormatValidator],
cmd_48: [''],
cmd_47: [''],
cmd_46: [''],
cmd_45: [''],
cmd_44: [''],

cmd_43: [null],
1217: [''],
1216: [''],
add_18_2538: [null],
      add_18_1016: [''],
      add_17_2538: [null],
      add_17_1016: [''],
      add_16_2538: [null],
      add_16_1016: [''],
      add_15_2538: [null],
      add_15_1016: [''],
      add_14_2538: [null],
      add_14_1016: [''],
      add_13_2538: [null],
      add_13_1016: [''],
      add_12_2538: [null],
      add_12_1016: [''],
      add_11_2538: [null],
      add_11_1016: [''],
      add_10_2538: [null],
      add_10_1016: [''],
      add_9_2538: [null],

      add_9_1016: [''],
      add_8_2538: [null],
      add_8_1016: [''],
      add_7_2538: [null],
      add_7_1016: [''],
      add_6_2538: [null],
      add_6_1016: [''],
      add_5_2538: [null],
      add_5_1016: [''],
      add_4_2538: [null],
      add_4_1016: [''],
      add_3_2538: [null],
      add_3_1016: [''],
      add_2_2538: [null],
      add_2_1016: [''],
      add_1_2538: [null],
      add_1_1016: [''],
        cmd_4: [''],
        cmd_3: [''],
        add_19_1016:[null],
        add_19_2538:[null],
        cmd_1: [null,[Validators.required,this.dateFormatValidator]],
        cmd_02: [null],
        cmd_01: [''],
        cmd_NA_01:[null],
        cmd_NA_02:[null],
        cmd_NA_03:[null],
        cmd_NA_04:[null],
        cmd_NA_05:[null],
        cmd_NA_06:[null],
        cmd_NA_07:[null],
        cmd_NA_007:[null],
        cmd_NA_08:[null],
        cmd_NA_008:[null],
        cmd_NA_09:[null],
        cmd_NA_10:[null],

    })
    }

setErrorControl(name){
    this.onPointForm.controls[name].setErrors({ errors: true });
}
removeErrorControl(name){
    this.onPointForm.controls[name].setErrors(null)
}

handleNAchange(cName,eName?,dName?){
// console.log(cName);
// console.log(this.onPointForm.controls[cName].value);
let result:boolean=false;
// if(eName){
// if((this.onPointForm.controls[eName].value=='' || this.onPointForm.controls[309].value==null) && !this.onPointForm.controls[cName].value){
// this.setErrorControl(eName);
// result = true
// }
// else{
// this.removeErrorControl(eName);
// }
// return
// }
if (cName == "cmd_NA_01") {
let x=[309,312,333,334,335,310,311]

x.forEach(element => {
if(( this.onPointForm.controls[element].value=='' || this.onPointForm.controls[element].value==null) && !this.onPointForm.controls[cName].value){
this.setErrorControl(element);
result = true
}
else{
this.removeErrorControl(element);
}
});


}
if(cName=='cmd_NA_02'){
if((this.onPointForm.controls[517].value=='' || this.onPointForm.controls[517].value==null) && !this.onPointForm.controls[cName].value){
this.setErrorControl(517);
result = true
}
else{
this.removeErrorControl(517);
}
}
if(cName=='cmd_NA_03'){
if((this.onPointForm.controls[529].value=='' || this.onPointForm.controls[529].value==null) && !this.onPointForm.controls[cName].value){
this.setErrorControl(529);
result = true
}
else{
this.removeErrorControl(529);
}
}
if(cName=='cmd_NA_04'){
if((this.onPointForm.controls['add_1_1170'].value=='' || this.onPointForm.controls['add_1_1170'].value==null) && !this.onPointForm.controls[cName].value){
this.setErrorControl('add_1_1170');
result = true
}
else{
this.removeErrorControl('add_1_1170');
}
}
if(cName=='cmd_NA_05'){
if((this.onPointForm.controls['add_2_1170'].value=='' || this.onPointForm.controls['add_2_1170'].value==null) && !this.onPointForm.controls[cName].value){
this.setErrorControl('add_2_1170');
result = true
}
else{
this.removeErrorControl('add_2_1170');
}
}
if(cName=='cmd_NA_06'){
if((this.onPointForm.controls['542'].value=='' || this.onPointForm.controls['542'].value==null) && !this.onPointForm.controls[cName].value){
this.setErrorControl('542');
result = true
}
else{
this.removeErrorControl('542');
}
}
if(cName=='cmd_NA_07'){
    let x=[554,1234,556,557,560]
    
    x.forEach(element => {
            if( (this.onPointForm.controls[element].value==''  || this.onPointForm.controls[element].value==null) && !this.onPointForm.controls[cName].value ){
                this.setErrorControl(element);
               
                result = true
            }
            else{
                this.removeErrorControl(element);
            }
        });
        

        
}
if(cName=='cmd_NA_007'){
    let x=['560']
    
    x.forEach(element => {
      if((this.onPointForm.controls[element].value=='' ||(!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(this.onPointForm.controls[element].value))|| this.onPointForm.controls[element].value==null) && !this.onPointForm.controls[cName].value && !this.onPointForm.controls['cmd_NA_07'].value ){
        this.setErrorControl(element);
        result = true
      }
      else{
        this.removeErrorControl(element);
      }
    });
  }  

if(cName=='cmd_NA_08'){
let x=[561,1235,563,564,567]

x.forEach(element => {
if(( this.onPointForm.controls[element].value=='' || this.onPointForm.controls[element].value==null) && !this.onPointForm.controls[cName].value){
this.setErrorControl(element);
result = true
}
else{
this.removeErrorControl(element);
}
});


}

if(cName=='cmd_NA_008'){
    let x=['567']
    
    x.forEach(element => {
      if((this.onPointForm.controls[element].value=='' ||(!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(this.onPointForm.controls[element].value))|| this.onPointForm.controls[element].value==null) && !this.onPointForm.controls[cName].value && !this.onPointForm.controls['cmd_NA_08'].value ){
        this.setErrorControl(element);
        result = true
      }
      else{
        this.removeErrorControl(element);
      }
    });
  }  
if(cName=='cmd_NA_09'){
let x=[1858,1210,1859,2012,1844,1845,1847,1848,1849,1216,1217,1208,1209,1226]

x.forEach(element => {
if(( this.onPointForm.controls[element].value=='' || this.onPointForm.controls[element].value==null) && !this.onPointForm.controls[cName].value){
this.setErrorControl(element);
result = true
}
else{
this.removeErrorControl(element);
}
});


}
if (cName == "cmd_NA_10") {
    let x=[293,299,294,295]
   
    x.forEach(element => {
    if(( this.onPointForm.controls[element].value=='' || this.onPointForm.controls[element].value==null) && !this.onPointForm.controls[cName].value){
    this.setErrorControl(element);
    result = true
    }
    else{
    this.removeErrorControl(element);
    }
    });
  
    
    }
// console.log(result);

// if(result){
// return
// }
}

close() {
this.onClose.emit('close modal');
}

saveForm(type) {
this.isSubmit = true;
this.handleNAchange('cmd_NA_01');
this.handleNAchange('cmd_NA_02');
this.handleNAchange('cmd_NA_03');
this.handleNAchange('cmd_NA_04');
this.handleNAchange('cmd_NA_05');
this.handleNAchange('cmd_NA_06');
this.handleNAchange('cmd_NA_07');
this.handleNAchange('cmd_NA_007');
this.handleNAchange('cmd_NA_08');
this.handleNAchange('cmd_NA_008');
this.handleNAchange('cmd_NA_09');
this.handleNAchange('cmd_NA_10');

console.log(this.onPointForm);
if (type == 'submit') {
if (this.onPointForm.invalid)
    {      
this.notiService.showError(
'Please Fill the all mandatory fields to continue',
'',
3000
);
 for (const key in this.onPointForm.controls) {
 if (this.onPointForm.controls.hasOwnProperty(key)) {
 const element = this.onPointForm.controls[key];
 if (element.status == "INVALID") {
 console.log(key + " " + element.status)
 }
 }
 }

}else {
if (this.onPointForm.controls['cmd_2'].value == null || this.onPointForm.controls['cmd_2'].value == '') {
this.notiService.showError('Please add your Signature')
return
}
let data = {
formType: type,
formValues: this.onPointForm.value,
formId: 35
};
// console.log(data)
console.log("Form has been submitted successfully");
this.onSubmit.emit(data);
// console.log(this.onPointForm);

}
} else {
let data = {
formType: type,
formValues: this.onPointForm.value,
formId: 35
};
// console.log(data)
this.onSubmit.emit(data);
this.onClose.emit('close modal');
}
}


loadDropdowns() {
this.getSuffixData();
this.getCredentialsData();
this.getSpecialtyData();
this.getRaceData();
this.getTerSpecialtyData();
this.getCountyData();
this.getAlternateLicenseStateData();
this.getAltCdsStatesData();
this.getAdditionalLicenseStateData();
this.getDegreeData();
this.getStatesData();
this.getGenderData();
this.getSpecialtySecondaryData();
this.getBoardCertificationData();
this.getStaffStatusData();
this.getAggregatesData();
this.getPreClaimData();
this.getboardstatus();
this.getcmecategory();
this.getLanguagesData();
this.getsecondaryLanguagesData();
this.gettertiaryLanguagesData();
this.getquaternaryLanguagesData();
this.afterSaveDataDisplay();

this.fieldsData && this.bindFormValue(this.fieldsData);
}

getSsn(value) {
this.onPointForm.patchValue({
cmd_0779: value
});
}

public afterSaveDataDisplay() {
let params = {
type: 35,
formId: this.formId
};
//this.spinner.show();
this.onboardService.toGetSaveData(params).subscribe(
data => {
if (data['success']) {
this.bindFormValue(data.data);
} else {
//this.notiService.showError(data.error, '', 3000)
}
//this.spinner.hide();
},
error => {
//this.notiService.showError(Error_Message, '', 3000)
}
);
}

public bindFormValue(array) {
let fieldsCtrls = {};
for (let i = 0; i < array.length; i++) {
fieldsCtrls[array[i].field_name] = array[i].field_value;
if (array[i].field_name == 'cmd_2') {
if (array[i].field_value != '') {
this.previewEsign(array[i].field_value, array[i].field_name);
}
}
}
this.onPointForm.patchValue(fieldsCtrls);
// console.log(this.onPointForm)
}

public getSuffixData() {
let params: {};
//this.spinner.show();
this.onboardService.getSuffixData(params).subscribe(
data => {
if (data['success']) {
this.suffix = data.data.map(i => ({
...i,
id: i.id.toString()
}));
} else {
this.notiService.showError(data.error, '', 3000);
}
//this.spinner.hide();
},
error => {
//this.spinner.hide();
}
);
}
public getRaceData() {
let params: {};
//this.spinner.show();
this.onboardService.getRaceData(params).subscribe(
data => {
if (data['success']) {
this.race = data.data.map(i => ({
...i,
id: i.id.toString()
}));
} else {
this.notiService.showError(data.error, '', 3000);
}
//this.spinner.hide();
},
error => {
//this.spinner.hide();
}
);
}
public getCredentialsData() {
let params: {};
//this.spinner.show();
this.onboardService.getCredentialsData(params).subscribe(
data => {
if (data['success']) {
this.credentials = data.data.map(i => ({
...i,
id: i.id.toString()
}));
} else {
this.notiService.showError(data.error, '', 3000);
}
//this.spinner.hide();
},
error => {
//this.spinner.hide();
}
);
}

public getTerSpecialtyData() {
let params: {};
//this.spinner.show();
this.onboardService.getTerSpecialtyData(params).subscribe(
data => {
if (data['success']) {
this.specialtyTer = data.data.map(i => ({
...i,
id: i.id.toString()
}));
} else {
this.notiService.showError(data.error, '', 3000);
}
//this.spinner.hide();
},
error => {
//this.spinner.hide();
}
);
}

public getCountyData() {
let params: {};
//this.spinner.show();
this.onboardService.getCountyData(params).subscribe(
data => {
if (data['success']) {
this.county = data.data.map(i => ({
...i,
id: i.id.toString()
}));
} else {
this.notiService.showError(data.error, '', 3000);
}
//this.spinner.hide();
},
error => {
//this.spinner.hide();
}
);
}

public getAlternateLicenseStateData() {
let params: {};
//this.spinner.show();
this.onboardService.getAlternateLicenseStateData(params).subscribe(
data => {
if (data['success']) {
this.altLicenseState = data.data.map(i => ({
...i,
id: i.id.toString()
}));
} else {
this.notiService.showError(data.error, '', 3000);
}
//this.spinner.hide();
},
error => {
//this.spinner.hide();
}
);
}

public getAltCdsStatesData() {
let params: {};
//this.spinner.show();
this.onboardService.getAltCdsStatesData(params).subscribe(
data => {
if (data['success']) {
this.AltCdsStates = data.data.map(i => ({
...i,
id: i.id.toString()
}));
} else {
this.notiService.showError(data.error, '', 3000);
}
//this.spinner.hide();
},
error => {
//this.spinner.hide();
}
);
}

public getAdditionalLicenseStateData() {
let params: {};
//this.spinner.show();
this.onboardService.getAdditionalLicenseStateData(params).subscribe(
data => {
if (data['success']) {
this.additionalLicenseState = data.data.map(i => ({
...i,
id: i.id.toString()
}));
} else {
this.notiService.showError(data.error, '', 3000);
}
//this.spinner.hide();
},
error => {
//this.spinner.hide();
}
);
}

public getDegreeData() {
let params: {};
//this.spinner.show();
this.onboardService.getDegreeData(params).subscribe(
data => {
if (data['success']) {
this.degree = data.data.map(i => ({
...i,
id: i.id.toString()
}));
} else {
this.notiService.showError(data.error, '', 3000);
}
//this.spinner.hide();
},
error => {
//this.spinner.hide();
}
);
}

public getSpecialtyData() {
let params: {};
//this.spinner.show();
this.onboardService.getSpecialtyData(params).subscribe(
data => {
if (data['success']) {
this.specialty = data.data.map(i => ({
...i,
id: i.id.toString()
}));
} else {
this.notiService.showError(data.error, '', 3000);
}
//this.spinner.hide();
},
error => {
//this.spinner.hide();
}
);
}

public getStatesData() {
let params: {};
//this.spinner.show();
this.onboardService.getStatesData(params).subscribe(
data => {
if (data['success']) {
this.states = data.data.map(i => ({
...i,
id: i.id.toString()
}));
} else {
this.notiService.showError(data.error, '', 3000);
}
//this.spinner.hide();
},
error => {
//this.spinner.hide();
}
);
}

public getGenderData() {
let params: {};
//this.spinner.show();
this.onboardService.getGenderData(params).subscribe(
data => {
if (data['success']) {
this.gender = data.data.map(i => ({
...i,
id: i.id.toString()
}));
} else {
this.notiService.showError(data.error, '', 3000);
}
//this.spinner.hide();
},
error => {
//this.spinner.hide();
}
);
}

public getSpecialtySecondaryData() {
let params: {};
//this.spinner.show();
this.onboardService.getSpecialtySecondaryData(params).subscribe(
data => {
if (data['success']) {
this.specialtySecondary = data.data.map(i => ({
...i,
id: i.id.toString()
}));
} else {
this.notiService.showError(data.error, '', 3000);
}
//this.spinner.hide();
},
error => {
//this.spinner.hide();
}
);
}

public getBoardCertificationData() {
let params: {};
//this.spinner.show();
this.onboardService.getBoardCertificationData(params).subscribe(
data => {
if (data['success']) {
this.boardCertification = data.data.map(i => ({
...i,
id: i.id.toString()
}));
} else {
this.notiService.showError(data.error, '', 3000);
}
//this.spinner.hide();
},
error => {
//this.spinner.hide();
}
);
}

public getStaffStatusData() {
let params: {};
//this.spinner.show();
this.onboardService.getStaffStatusData(params).subscribe(
data => {
if (data['success']) {
this.staffStatus = data.data.map(i => ({
...i,
id: i.id.toString()
}));
} else {
this.notiService.showError(data.error, '', 3000);
}
//this.spinner.hide();
},
error => {
//this.spinner.hide();
}
);
}

public getAggregatesData() {
let params: {};
//this.spinner.show();
this.onboardService.getAggregatesData(params).subscribe(
data => {
if (data['success']) {
this.aggregates = data.data.map(i => ({
...i,
id: i.id.toString()
}));
} else {
this.notiService.showError(data.error, '', 3000);
}
//this.spinner.hide();
},
error => {
//this.spinner.hide();
}
);
}

public getPreClaimData() {
let params: {};
//this.spinner.show();
this.onboardService.getPreClaimData(params).subscribe(
data => {
if (data['success']) {
this.preClaim = data.data.map(i => ({
...i,
id: i.id.toString()
}));
} else {
this.notiService.showError(data.error, '', 3000);
}
//this.spinner.hide();
},
error => {
//this.spinner.hide();
}
);
}

public getboardstatus() {
let params: {};
//this.spinner.show();
this.onboardService.getboardstatus(params).subscribe(
data => {
if (data['success']) {
this.boardStatus = data.data.map(i => ({
...i,
id: i.id.toString(),
label:i.name
}));
} else {
this.notiService.showError(data.error, '', 3000);
}
//this.spinner.hide();
},
error => {
//this.spinner.hide();
}
);
}

public getcmecategory() {
let params: {};
//this.spinner.show();
this.onboardService.getcmecategory(params).subscribe(
data => {
if (data['success']) {
this.CMEDropdown = data.data.map(i => ({
...i,
id: i.id.toString(),
label:i.name
}));
} else {
this.notiService.showError(data.error, '', 3000);
}
//this.spinner.hide();
},
error => {
//this.spinner.hide();
}
);
}
public getLanguagesData() {
let params: {};
//this.spinner.show();
this.onboardService.getLanguagesData(params).subscribe(
data => {
if (data['success']) {
this.languages = data.data.map(i => ({
...i,
id: i.id.toString()
}));
} else {
this.notiService.showError(data.error, '', 3000);
}
//this.spinner.hide();
},
error => {
//this.spinner.hide();
}
);
}

public getsecondaryLanguagesData() {
let params: {};
//this.spinner.show();
this.onboardService.getsecondaryLanguagesData(params).subscribe(
data => {
if (data['success']) {
this.secondaryLanguages = data.data.map(i => ({
...i,
id: i.id.toString()
}));
} else {
this.notiService.showError(data.error, '', 3000);
}
//this.spinner.hide();
},
error => {
//this.spinner.hide();
}
);
}

public gettertiaryLanguagesData() {
let params: {};
//this.spinner.show();
this.onboardService.gettertiaryLanguagesData(params).subscribe(
data => {
if (data['success']) {
this.tertiaryLanguages = data.data.map(i => ({
...i,
id: i.id.toString()
}));
} else {
this.notiService.showError(data.error, '', 3000);
}
//this.spinner.hide();
},
error => {
//this.spinner.hide();
}
);
}

public getquaternaryLanguagesData() {
let params: {};
//this.spinner.show();
this.onboardService.getquaternaryLanguagesData(params).subscribe(
data => {
if (data['success']) {
this.quaternaryLanguages = data.data.map(i => ({
...i,
id: i.id.toString()
}));
} else {
this.notiService.showError(data.error, '', 3000);
}
//this.spinner.hide();
},
error => {
//this.spinner.hide();
}
);
}

public esignOpenOne() {
const modalRef = this.modalService.open(EsignComponent, this.modalOption);
modalRef.result.then(result => { });
modalRef.componentInstance.base64Img.subscribe(data => {
modalRef.close();
this.finalImgPath = data.finalFilePath;
let temp = {
cmd_2: this.finalImgPath
};
this.onPointForm.controls['cmd_2'].setValue(this.finalImgPath);
this.finalImg = data.base64Img;
this.esignOne = this._sanitizer.bypassSecurityTrustResourceUrl(
'data:image/jpg;base64,' + this.finalImg
);
this.showOne = true;
});
}
public previewEsign(filePath, fieldId) {
//this.spinner.show();
let params = {
filePath: filePath
};
this.onboardService.getPreviewEsign(params).subscribe(
(data: any) => {
if (data['success']) {
this.setEsign(data.base64, fieldId);
} else {
//this.notiService.showError(data.error, '', 3000)
}
//this.spinner.hide();
},
error => {
//this.spinner.hide();
}
);
}

onlyNumber(event,name) {
    
          let z = event.target.value;

       z= z.replace('^\d+(\/\d+)*$', '');

    //let z = event.target.value;
    // let z = event.target.value;
    // z = z.replace(/[^\d-]/g, '');
    // if (z.length == 2) {
    // z = z + '/'
    // }
    // else if (z.length == 5) {
    // z = z + '/'
    // }
    
    this.onPointForm.controls[name].setValue(z);
    // console.log(z);

    
}

    
    // z = z.replace('^\d+(\/\d+)*$', '');





    // z=z.replace('^[0-9]+(\/[0-9]+)*$','');
    // z = z.replace('\d+\/?\d?+')
    // z='(\d\/*)+';
    // z=z.replace('/[^\d\/]/g','');
//    z=z.replace(' /[^0-9\//]/g','');
   
    // this.onPointForm.controls[name].setValue(z);
    // }

handleNumber(event, name) {
if (event.code == 'Delete' || event.code == 'Backspace') {

}
else {
let z = event.target.value;
z = z.replace(/[^\d-]/g, '');
if (z.length == 3) {
z = z + '-'
}
else if (z.length == 7) {
z = z + '-'
}
this.onPointForm.controls[name].setValue(z)
}
}
get f() { return this.onPointForm.controls; }

public setEsign(filePath, fieldId) {
// console.log(filePath);
if (fieldId == 'cmd_2') {
this.esignOne = this._sanitizer.bypassSecurityTrustResourceUrl(
'data:image/jpg;base64,' + filePath
);
this.showOne = true;
}
}
clearInputIfInvalid(controlName: string,handleYesNoValue?:string) {
    const control = this.onPointForm.get(controlName);
    const dateString = control.value;
  
    // Use the dateFormatValidator to check if the date is valid
    const validationErrors = this.dateFormatValidator(new FormControl(dateString));
  
    if (validationErrors) {
      // If any of the validation conditions are met, clear the input field and set errors to null
      control.setValue('');
      control.setErrors(null);
    }
    if(handleYesNoValue ){
         this.handleNAchange(handleYesNoValue);
    }
    
    
  }
  clearInputIfInvalidMandi(controlName: string) {
    const control = this.onPointForm.get(controlName);
    const dateString = control.value;
  
    // Use the dateFormatValidator to check if the date is valid
    const validationErrors = this.dateFormatValidator(new FormControl(dateString));
  
    if (validationErrors) {
      // If any of the validation conditions are met, clear the input field and set errors to null
      control.setValue('');

    }}

  dateFormatValidator(control: FormControl): ValidationErrors | null {
    const dateString = control.value;
    const dateFormat = /^\d{2}\/\d{2}\/\d{4}$/;
  
    if (dateString && !dateString.match(dateFormat)) {
      return { 'invalidDateFormat': true };
    }
  


    if (dateString) {
      const dateParts = dateString.split('/');
      const month = parseInt(dateParts[0]);
      const day = parseInt(dateParts[1]);
      const year = parseInt(dateParts[2]);
  
      // Check if any of the date components are zero or month is not between 1 and 12
      
  
      const ListofDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
      
      
      if (month < 1 || month > 12 || day === 0 || year === 0) {
        return { 'invalidMonth': true };
      }
      if (month === 1 || month > 2) {
        if (day > ListofDays[month - 1]) {
          return { 'invalidDateRange': true };
        }
      } else if (month === 2) {
        const leapYear = (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
        if (!leapYear && day >= 29) {
          return { 'invalidDateRange': true };
        }
         else if (leapYear && day > 29) {
          return { 'invalidDateRange': true };
        }
      }
      if (year < 1900 || year > 2099) {
        return { 'invalidDateyear': true };
      }
      
     
    }
  
    return null;
  }
}

