import { Injectable } from '@angular/core';
import { ApiServiceService } from 'apps/cmd-frontend/src/app/shared/services/cmd-api-service/api-service.service';

@Injectable({
  providedIn: 'root'
})
export class WorkflowItemService {

  constructor(private apiService:ApiServiceService) { }

  public getWorkflowData(postParams){
    return this.apiService.post('workflow/workflowdata', postParams);
  }
  public getNotesTasks(postParams){
    return this.apiService.post('workflow/getnotestasks', postParams);
  }
  public getComlog(postParams){
    return this.apiService.post('workflow/comlog', postParams);
  }
  public switchView(postParams){
    return this.apiService.post('workflow/switchview', postParams);
  }
  public enhanceData(postParams){
    return this.apiService.post('workflow/enhancedata', postParams);
  }
  public searchEnhancedData(postParams){
    return this.apiService.post('workflow/searchenhancedata', postParams);
  }
  public saveSearchFields(postParams){
    return this.apiService.post('workflow/savefields', postParams);
  }
  public getTaskNoteDetails(postParams){
    return this.apiService.post('workflow/showtask', postParams);
  }
  public addTask(postParams){
    return this.apiService.post('workflow/addtask', postParams);
  }
  public addNote(postParams){
    return this.apiService.post('workflow/addnote', postParams);
  }

  public addComlog(postParams){
    return this.apiService.post('workflow/addcomlog', postParams);
  }
  public updateStatus(postParams){
    return this.apiService.post('workflow/updatestatus', postParams);
  }
  public configurelist(postParams){
    return this.apiService.post('workflow/configurelist', postParams);
  }
  public savehotlist(postParams){
    return this.apiService.post('workflow/savehotlist', postParams);
  }
  public getViewPayers(postParams){
    return this.apiService.post('providerpassport/viewpayers', postParams);
  }
  public getDownloadList(postParams){
    return this.apiService.post('workflow/fileslist', postParams);
  }
  public downloadfiles(postParams){
    return this.apiService.post('workflow/downloadfiles', postParams);
  }
  public downloadExcel(postParams){
    return this.apiService.post('workflow/generateexcel', postParams);
  }
  public generateForm(postParams){
    return this.apiService.post('forms/stepone', postParams);
  }
  public downloadExcelForEnhacedview(postParams){
    return this.apiService.post('workflow/generateworkflowexcel', postParams);
  }

  ///provider
  public addNoteProvider(postParams){
    return this.apiService.post('providerpassport/addnoteprovider',postParams)
  }
  public addTaskProvider(postParams){
    return this.apiService.post('providerpassport/addtaskprovider',postParams)
  }
  public viewthread(postParams){
    return this.apiService.post('providerpassport/viewthread',postParams)
  }
  public editViewPayers(postParams){
    return this.apiService.post('providerpassport/editpayers', postParams);
  }
  public updatePayer(postParams){
    return this.apiService.post('providerpassport/updatepayer',postParams)
  }

  //Tin/Location
  public addTaskLocation(postParams){
    return this.apiService.post('tinlocation/addtasklocation', postParams);
  }
  public addNoteLocation(postParams){
    return this.apiService.post('tinlocation/addnotelocation', postParams);
  }
  public viewthreadLoc(postParams){
    return this.apiService.post('tinlocation/viewthread', postParams);
  }
  //Workflow dropdown data
  public getWorkflowDropdowns(postParams){
    return this.apiService.post('workflow/getworkflowdatadropdowns', postParams);
  }
}
