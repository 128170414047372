import { FormControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import {
  NgbModal,
  NgbModalOptions,
  NgbDateAdapter,
  NgbDateParserFormatter
} from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToasterNotiService } from '../../services/notifications/toaster-noti.service';
import { OnboardingformsServiceService } from '../../services/onboardingforms-service/onboardingforms-service.service';
import { StorageService } from '../../services/storage/storage.service';
import { EsignComponent } from '../esign/esign.component';
import {
  CustomAdapter,
  CustomDateParserFormatter
}from '../../DateFormatter/customDate'; 

@Component({
    selector: 'ctg-eye_helth',
    templateUrl: './eye-health-north.component.html',
    styleUrls: ['./eye-health-north.component.scss'],
    providers: [
      { provide: NgbDateAdapter, useClass: CustomAdapter },
      { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }
    ]
  })

  export class EyeHelthComponent implements OnInit {
    @Input() formData;
    @Input() fieldsData;
    @Input() formId;
    @Output() onSubmit = new EventEmitter();
    @Output() onClose = new EventEmitter();
    private emailPattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    firstName: any;
    private zippattern=/^.{5,9}$/;
    finalName: any;
    lastName: any;
    middleName: any;
    constructor(
      private fb: UntypedFormBuilder,
      private onboardService: OnboardingformsServiceService,
      private spinner: NgxSpinnerService,
      private notiService: ToasterNotiService,
      private modalService: NgbModal,
      private _sanitizer: DomSanitizer,
      private storageService: StorageService
    ) {}
    public userId: any;
    public customerId: any;
    public eyeForm: UntypedFormGroup;
    isSubmit: boolean = false;
    private phonePattern = '^[0-9]*$';
  
    modalOption: NgbModalOptions = {
      size: 'md',
      keyboard: false,
      backdrop: 'static'
    };
    ngOnInit(): void {
    this.userId = Number(this.storageService.getItem('userId'));
    this.customerId = Number(this.storageService.getItem('customerId'))
    this.bulidEyehelthForm();
     this.fieldsData && this.bindFormValue(this.fieldsData);
    // this.getSuffixData();
    this.getStatesData();
    //  this.getCountyData();
    this.getGenderData();
    // this.getAltCdsStatesData();
     this.getDegreeData();
     this.getStaffStatusData();
     this.getBoardCertificationData();
     this.getPreClaimData();
     this.getAggregatesData();
     this.getSpecialtyData();
     this.getSpecialtySecondaryData();
     this.getTerSpecialtyData();
     this.afterSaveDataDisplay();
    this.getMediCertifications();
    this.getcmecategory();
    this.getquaternaryspecialty();
    this.getCredentialsData();
    this.getadditionallicenseState();
    this.getCountyData();
    }
   
    public doits: any;
    finalImgPath: any = [];
    finalImg: any;
    esignOne: any;
    showOne: any = false;
    esignTwo: any;
    esignThree:any;
    finalImgPathThree: any = [];
    gender: Array<object> = [];
    showTwo: any = false;
    showThree: any =false;
    finalImgThree: any = '';
    suffix: Array<object> = [];
    states: Array<object> = [];
    additionalLicensesstates: Array<object> = [];
    public country: any = [];
    public stateDropDownDate: any = [];
    AltCdsStates: Array<object> = [];
    degree: Array<object> = [];
    crdential: Array<object> = [];
    staffStatus: Array<object> = [];
    boardCertification: Array<object> = [];
    public preClaim: any = [];
    public aggregates: any = [];
    specialty: Array<object> = [];
    specialtySecondary: Array<object> = [];
    specialtyTer: Array<object> = [];
    specialtyquart: Array<object> = [];
    mediCertifications: Array<object> = [];
    CMEDropdown: Array<any> = [];


    public afterSaveDataDisplay() {
        let params = {
          type: 48,
          formId: this.formId
        };
        //this.spinner.show();
        this.onboardService.toGetSaveData(params).subscribe(
          data => {
            if (data['success']) {
              this.bindFormValue(data.data);
            } else {
              // this.notiService.showError(data.error, '', 3000)
            }
            //this.spinner.hide();
          },
          error => {
            // this.notiService.showError(Error_Message, '', 3000)
          }
        );
      }
      public getTerSpecialtyData() {
        let params: {};
        //this.spinner.show();
        this.onboardService.getTerSpecialtyData(params).subscribe(
          data => {
            if (data['success']) {
              this.specialtyTer = data.data.map(i => ({
                ...i,
                id: i.id.toString()
              }));
            } else {
              this.notiService.showError(data.error, '', 3000);
            }
            //this.spinner.hide();
          },
          error => {
            //this.spinner.hide();
          }
        );
      }
      public getquaternaryspecialty() {
        let params: {};
        //this.spinner.show();
        this.onboardService.getquaternaryspecialty(params).subscribe(
          data => {
            if (data['success']) {
              this.specialtyquart = data.data.map(i => ({
                ...i,
                id: i.id.toString()
              }));
            } else {
              this.notiService.showError(data.error, '', 3000);
            }
            //this.spinner.hide();
          },
          error => {
            //this.spinner.hide();
          }
        );
      }
      public getBoardCertificationData() {
        let params: {};
        //this.spinner.show();
        this.onboardService.getBoardCertificationData(params).subscribe(
          data => {
            if (data['success']) {
              this.boardCertification = data.data.map(i => ({
                ...i,
                id: i.id.toString()
              }));
            } else {
              this.notiService.showError(data.error, '', 3000);
            }
            //this.spinner.hide();
          },
          error => {
            //this.spinner.hide();
          }
        );
      }
      
      public getSpecialtyData() {
        let params: {};
        //this.spinner.show();
        this.onboardService.getSpecialtyData(params).subscribe(
          data => {
            if (data['success']) {
              this.specialty = data.data.map(i => ({
                ...i,
                id: i.id.toString()
              }));
            } else {
              this.notiService.showError(data.error, '', 3000);
            }
            //this.spinner.hide();
          },
          error => {
            //this.spinner.hide();
          }
        );
      }
      public getCountyData() {
        let params: {};
        //this.spinner.show();
        this.onboardService.getCountyData(params).subscribe(
          data => {
            if (data['success']) {
              this.country = data.data.map(i => ({
                ...i,
                id: i.id.toString()
              }));
            } else {
              this.notiService.showError(data.error, '', 3000);
            }
            //this.spinner.hide();
          },
          error => {
            //this.spinner.hide();
          }
        );
      }
      public getSpecialtySecondaryData() {
        let params: {};
        //this.spinner.show();
        this.onboardService.getSpecialtySecondaryData(params).subscribe(
          data => {
            if (data['success']) {
              this.specialtySecondary = data.data.map(i => ({
                ...i,
                id: i.id.toString()
              }));
            } else {
              this.notiService.showError(data.error, '', 3000);
            }
            //this.spinner.hide();
          },
          error => {
            //this.spinner.hide();
          }
        );
      }
      public bindFormValue(array) {
        let fieldsCtrls = {};
        for (let i = 0; i < array.length; i++) {
          fieldsCtrls[array[i].field_name] = array[i].field_value;
          if (array[i].field_name == 'cmd_00124') {
            if (array[i].field_value != '') {
              this.previewEsign(array[i].field_value, array[i].field_name);
            }
          }
          if (array[i].field_name == 'cmd_00127') {
            if (array[i].field_value != '') {
              this.previewEsign(array[i].field_value, array[i].field_name);
            }
          }
          if (array[i].field_name == 'cmd_00130') {
            if (array[i].field_value != '') {
              this.previewEsign(array[i].field_value, array[i].field_name);
            }
          }
        }
        
        this.eyeForm.patchValue(fieldsCtrls);
      }
    public getGenderData() {
      let params: {};
      //this.spinner.show();
      this.onboardService.getGenderData(params).subscribe(
        data => {
          if (data['success']) {
            this.gender = data.data.map(i => ({
              ...i,
              id: i.id.toString()
            }));
          } else {
            this.notiService.showError(data.error, '', 3000);
          }
          //this.spinner.hide();
        },
        error => {
          //this.spinner.hide();
        }
      );
    }
    public zipCodeValidator(control: FormControl): ValidationErrors | null {
        const zipCode = control.value;
        const zipCodePattern = /^.{5,9}$/;
      
        if (zipCode && !zipCode.match(zipCodePattern)) {
          return { 'invalidZipCode': true };
        }
      
        return null;
      }
      
    public getCredentialsData() {
        let params: {};
        //this.spinner.show();
        this.onboardService.getCredentialsData(params).subscribe(
          data => {
            if (data['success']) {
              this.crdential = data.data.map(i => ({
                ...i,
                id: i.id.toString()
              }));
            } else {
              this.notiService.showError(data.error, '', 3000);
            }
            //this.spinner.hide();
          },
          error => {
            //this.spinner.hide();
          }
        );
      }  
    public getDegreeData() {
      let params: {};
      //this.spinner.show();
      this.onboardService.getDegreeData(params).subscribe(
        data => {
          if (data['success']) {
            this.degree = data.data.map(i => ({
              ...i,
              id: i.id.toString()
            }));
          } else {
            this.notiService.showError(data.error, '', 3000);
          }
          //this.spinner.hide();
        },
        error => {
          //this.spinner.hide();
        }
      );
    }  
    public getMediCertifications() {
      let params: {};
      //this.spinner.show();
      this.onboardService.getMediCertifications(params).subscribe(
        data => {
          if (data['success']) {
            this.mediCertifications = data.data.map(i => ({
              ...i,
              id: i.id.toString()
            }));
          } else {
            this.notiService.showError(data.error, '', 3000);
          }
          //this.spinner.hide();
        },
        error => {
          //this.spinner.hide();
        }
      );
    }
    public getStatesData() {
      let params: {};
      //this.spinner.show();
      this.onboardService.getStatesData(params).subscribe(
        data => {
          if (data['success']) {
            this.states = data.data.map(i => ({
              ...i,
              id: i.id.toString()
            }));
          } else {
            this.notiService.showError(data.error, '', 3000);
          }
          //this.spinner.hide();
        },
        error => {
          //this.spinner.hide();
        }
      );
    }  
    public getadditionallicenseState() {
      let params: {};
      //this.spinner.show();
      this.onboardService.getAdditionalLicenseStateData(params).subscribe(
        data => {
          if (data['success']) {
            this.additionalLicensesstates = data.data.map(i => ({
              ...i,
              id: i.id.toString()
            }));
          } else {
            this.notiService.showError(data.error, '', 3000);
          }
          //this.spinner.hide();
        },
        error => {
          //this.spinner.hide();
        }
      );
    }
    public getStaffStatusData() {
      let params: {};
      //this.spinner.show();
      this.onboardService.getStaffStatusData(params).subscribe(
        data => {
          if (data['success']) {
            this.staffStatus = data.data.map(i => ({
              ...i,
              id: i.id.toString()
            }));
          } else {
            this.notiService.showError(data.error, '', 3000);
          }
          //this.spinner.hide();
        },
        error => {
          //this.spinner.hide();
        }
      );
    }
    bulidEyehelthForm() {
        this.eyeForm = this.fb.group({
          9:['',[Validators.required]],
          8:['', [Validators.required]],
          809:['', [Validators.required]],
          822: [null, Validators.required],
          cmd_001: ['', [Validators.required]],
          278:[''],
          16:['',[Validators.required]],
          17:[''],
          708:['', [Validators.required,Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]],
          709:['', [Validators.required,Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]],
          1664: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
          18: ['', [Validators.required]],
          19: [null, [Validators.required]],
          20: [null, [Validators.required]],
          21: ['',[Validators.required,this.zipCodeValidator]],
          31: [null, [Validators.required,this.dateFormatValidator]],
          289: [null, [Validators.required]],
          810:['',[Validators.required]],
          291:['',[Validators.required]],
          292:['',[Validators.required]],
          32:['',[Validators.required]],
          30:[null,[Validators.required]],
          1584:[''],
          1585:[null,[this.dateFormatValidator]],
          1281:[''],
          1282:[''],
          514:[''],
          515:[null,[this.dateFormatValidator]],
          962:[null],
          965:[null],
          1934:[null],
          cmd_002:['',[Validators.required]],
          cmd_003:['',[Validators.required]],
          cmd_004:['',[Validators.required]],
          cmd_005:['',[Validators.required]],
          cmd_006:['',[Validators.required]],
          cmd_007:[''],
          cmd_008:[''],
          cmd_009:[''],
          cmd_0010:[''],
          cmd_011:[''],
          554:[null],
          556:[null],
          1169:[''],
          284:[null],
          557:[null, [this.dateFormatValidator]],
          560:[null, [this.dateFormatValidator]],
          561:[null],
          562:[''],
          563:[null],
          567:[null, [this.dateFormatValidator]],
          564:[null, [this.dateFormatValidator]],
          946:[null],
          951:[''],
          948:[null],
          953:[null, [this.dateFormatValidator]],
          954:[null, [this.dateFormatValidator]],
          cmd_0013:[''],
          add_1_674:[null],
          add_1_3270:[''],
          add_1_677:[null, [this.dateFormatValidator]],
          add_1_678:[null, [this.dateFormatValidator]],
          add_2_674:[null],
          add_2_3270:[''],
          add_2_677:[null, [this.dateFormatValidator]],
          add_2_678:[null, [this.dateFormatValidator]],
          add_3_674:[null],
          add_3_3270:[''],
          add_3_677:[null, [this.dateFormatValidator]],
          add_3_678:[null, [this.dateFormatValidator]],
          add_4_674:[null],
          add_4_3270:[''],
          add_4_677:[null, [this.dateFormatValidator]],
          add_4_678:[null, [this.dateFormatValidator]],
          cmd_014:[''],
          cmd_015:[''],
          cmd_016:[''],
          cmd_017:[''],
          cmd_018:[''],
          cmd_019:[''],
          cmd_020:[''],
          cmd_021:['' ,[Validators.pattern(this.zippattern)] ],
          cmd_022:['' ,[Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]],
          cmd_023:['' ,[Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]],
          cmd_024:['',[Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]],
          cmd_025:[''],
          cmd_026:[''],
          cmd_027:[''],
          cmd_028:['',[Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]],
          cmd_029:['',[Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]],
          cmd_030:[''],
          cmd_031:[''],
          cmd_032:['', [Validators.pattern(this.emailPattern)]],
          cmd_033:[''],
          cmd_034:['',[Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]],
          cmd_035:['',[Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]],
          cmd_036:['', [Validators.pattern(this.emailPattern)]],
          cmd_037:[''],
          cmd_038:[''],
          cmd_039:[''],
          cmd_040:[''],
          cmd_041:[''],
          cmd_042:[''],
          cmd_043:[''],
          cmd_044:[''],
          cmd_045:[''],
          cmd_046:['',[Validators.pattern(this.zippattern)]],
          cmd_047:['',[Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]],
          cmd_048:['',[Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]],
          cmd_049:['',[Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]],
          cmd_050:[''],
          cmd_051:[''],
          cmd_052:[''],
          cmd_053:['',[Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]],
          cmd_054:['',[Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]],
          cmd_055:[''],
          cmd_056:[''],
          cmd_057:['', [Validators.pattern(this.emailPattern)]],
          cmd_058:[''],
          cmd_059:['',[Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]],
          cmd_060:['',[Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]],
          cmd_061:['', [Validators.pattern(this.emailPattern)]],
          cmd_062:[''],
          cmd_063:[''],
          2953:[''],
          3403:[null],
          2963:[''],
          3404:[null],
          2979:[''],
          3405:[null],
          add_1_3469:[''],
          add_1_3491:[null],
          add_2_3469:[''],
          add_2_3491:[null],
          cmd_0064:[''],
          503:[''],
          504:[''],
          505:[''],
          480:[null],
          512:[null],
          510:[null, [this.dateFormatValidator]],
          1238:[null, [this.dateFormatValidator]],
          506:[''],
          513:[''],
          508:[null],
          cmd_0065:[''],
          479:[''],
          483:[''],
          484:[''],
          481:[null, [this.dateFormatValidator]],
          1300:[null, [this.dateFormatValidator]],
          485:[''],
          487:[null],
          489:[''],
          cmd_0066:[''],
          490:[''],
          491:[''],
          492:[''],
          493:[''],
          495:[null],
          496:['' , [this.zipCodeValidator]],
          2353:['', [Validators.pattern(this.emailPattern)]],
          500:[null],
          2351:['', [Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]],
          2352:['', [Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]],
          497:[null, [this.dateFormatValidatorMonth]],
          498:[null, [this.dateFormatValidatorMonth]],
          499:[null, [this.dateFormatValidator]],
          cmd_0067:[''],
          1288:[''],
          1289:[''],
          1290:[''],
          1291:[''],
          1293:[null],
          1294:['',[Validators.pattern(this.zippattern)]],
          2356: ['',[Validators.pattern(this.emailPattern)]],
          1298:[null],
          2354:['', [Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]],
          2355:['', [Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]],
          1295:[null, [this.dateFormatValidatorMonth]],
          1296:[null, [this.dateFormatValidatorMonth]],
          1297:[null, [this.dateFormatValidator]],
          cmd_0068:[''],
          cmd_0069:[''],
          517:[''],
          518:[''],
          519:[''],
          520:[''],
          522:[null],
          523:['', [this.zipCodeValidator]],
          4579:['',[Validators.pattern(this.emailPattern)]],
          527:[null],
          1318:['', [Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]],
          1319:['', [Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]],
          524:[null, [this.dateFormatValidator]],
          525:[null, [this.dateFormatValidator]],
          526:[null, [this.dateFormatValidator]],
          cmd_0070:['',[Validators.required]],
          cmd_0071:[''],
          529:[''],
          531:[''],
          1498:[''],
          533:[''],
          535:[null],
          536:['', [this.zipCodeValidator]],
          4581:['',[Validators.pattern(this.emailPattern)]],
          530:[null],
          1316:['', [Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]],
          1317:['', [Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]],
          537:[null, [this.dateFormatValidator]],
          538:[null, [this.dateFormatValidator]],
          539:[null, [this.dateFormatValidator]],
          cmd_0072:['',[Validators.required]],
          1170:[''],
          1172:[''],
          1173:[''],
          1174:[''],
          1176:[null],
          1177:['',[Validators.pattern(this.zippattern)]],
          4582:['',[Validators.pattern(this.emailPattern)]],
          1171:[null],
          1320:['', [Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]],
          1321:['', [Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]],
          1178:[null, [this.dateFormatValidator]],
          1179:[null, [this.dateFormatValidator]],
          1180:[null, [this.dateFormatValidator]],
          cmd_0073:[''],
          cmd_0074:[''],
          542:[''],
          544:[''],
          545:[''],
          546:[''],
          548:[null],
          549:['', [this.zipCodeValidator]],
          4583:['',[Validators.pattern(this.emailPattern)]],
          543:[null],
          1322:['', [Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]],
          1323:['', [Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]],
          550:[null, [this.dateFormatValidator]],
          551:[null, [this.dateFormatValidator]],
          552:[null, [this.dateFormatValidator]],
          cmd_0075:['',[Validators.required]],
          966:[''],
          968:[''],
          969:[''],
          970:[''],
          972:[null],
          1301:['',[Validators.pattern(this.zippattern)]],
          4584:['',[Validators.pattern(this.emailPattern)]],
          967:[null],
          1324:['', [Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]],
          1325:['', [Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]],
          974:[null, [this.dateFormatValidator]],
          975:[null, [this.dateFormatValidator]],
          982:[null, [this.dateFormatValidator]],
          cmd_0076:[''],
          293:[''],
          296:[''],
          295:[null, [this.dateFormatValidator]],
          309:[''],
          311:[null, [this.dateFormatValidator]],
          337:[''],
          339:[null, [this.dateFormatValidator]],
          1634:[''],
          325:[''],
          812:[''],
          cmd_0077:[''],
          cmd_0078:[''],
          add_3_1010:[null],
          add_3_999:[''],
          add_3_1432:[''],
          add_3_1003:[null, [this.dateFormatValidator]],
          add_3_1004:[null, [this.dateFormatValidator]],
          cmd_0079:[''],
          cmd_0080:[''],
          add_1_1010:[null],
          add_1_999:[''],
          add_1_1432:[''],
          add_1_1003:[null, [this.dateFormatValidator]],
          add_1_1004:[null, [this.dateFormatValidator]],
          cmd_0081:[''],
          cmd_0082:[''],
          add_2_1010:[null],
          add_2_999:[''],
          add_2_1432:[''],
          cmd_0083:[''],
          cmd_0084:[''],
          add_2_1003:[null, [this.dateFormatValidator]],
          add_2_1004:[null, [this.dateFormatValidator]],
          cmd_0085:[''],
          418:[''],
          1305:['', [Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]],
          2136:['', [Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]],
          419:[''],
          420:[''],
          421:[''],
          423:[null],
          424:['', [this.zipCodeValidator]],
          894:[null],
          426:[null, [this.dateFormatValidator]],
          2142:['',[Validators.pattern(this.emailPattern)]],
          cmd_0086:['',[Validators.required]],
          cmd_0087:[''],
          427:[''],
          1307:['', [Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]],
          2137:['', [Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]],
          428:[''],
          429:[''],
          430:[''],
          432:[null],
          433:['',[Validators.pattern(this.zippattern)]],
          895:[null],
          435:[null, [this.dateFormatValidator]],
          2143:['',[Validators.pattern(this.emailPattern)]],
          cmd_0088:[''],
          cmd_0089:[''],
          436:[''],
          1309:['', [Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]],
          2138:['', [Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]],
          437:[''],
          438:[''],
          439:[''],
          441:[null],
          442:['',[Validators.pattern(this.zippattern)]],
          896:[null],
          444:[null, [this.dateFormatValidator]],
          2144:['',[Validators.pattern(this.emailPattern)]],
          cmd_0090:[''],
          cmd_0091:[''],
          add_1_986:[''],
          add_1_1311:['', [Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]],
          add_1_2139:['', [Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]],
          add_1_987:[''],
          add_1_988:[''],
          add_1_989:[''],
          add_1_991:[null],
          add_1_992:['',[Validators.pattern(this.zippattern)]],
          add_1_995:[null],
          add_1_994:[null, [this.dateFormatValidator]],
          add_1_2145:['',[Validators.pattern(this.emailPattern)]],
          cmd_0092:[''],
          cmd_0093:[''],
          cmd_0094:[''],
          add_2_986:[''],
          add_2_1311:['', [Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]],
          add_2_2139:['', [Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]],
          add_2_987:[''],
          add_2_988:[''],
          add_2_989:[''],
          add_2_991:[null],
          add_2_992:['', [this.zipCodeValidator]],
          add_2_995:[null],
          add_2_994:[null, [this.dateFormatValidator]],
          // add_2_2145:['',[Validators.pattern(this.emailPattern)]],
          add_3_986:[''],
          add_3_1311:['', [Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]],
          add_3_2139:['', [Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]],
          add_3_987:[''],
          add_3_988:[''],
          add_3_989:[''],
          add_3_991:[null],
          add_3_992:['',[Validators.pattern(this.zippattern)]],
          add_3_995:[null],
          add_3_994:[null, [this.dateFormatValidator]],
          cmd_0095:[''],
          add_1_1183:[''],
          add_1_1313:['', [Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]],
          add_1_2140:['', [Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]],
          add_1_1184:[''],
          add_1_1185:[''],
          add_1_1186:[''],
          add_1_1188:[null],
          add_1_1189:['', [this.zipCodeValidator]],
          add_1_1191:[null, [this.dateFormatValidator]],
          add_1_2381:[null, [this.dateFormatValidator]],
          add_1_1303:[''],
          cmd_0096:[''],
          cmd_0097:[''],
          cmd_0098:[''],
          add_2_1183:[''],
          add_2_1313:['', [Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]],
          add_2_2140:['', [Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]],
          add_2_1184:[''],
          add_2_1185:[''],
          add_2_1186:[''],
          add_2_1188:[null],
          add_2_1189:['',[Validators.pattern(this.zippattern)]],
          add_2_1191:[null, [this.dateFormatValidator]],
          add_2_2381:[null, [this.dateFormatValidator]],
          add_2_1303:[''],
          add_3_1183:[''],
          add_3_1313:['', [Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]],
          add_3_2140:['', [Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]],
          add_3_1184:[''],
          add_3_1185:[''],
          add_3_1186:[''],
          add_3_1188:[null],
          add_3_1189:['',[Validators.pattern(this.zippattern)]],
          add_3_1191:[null, [this.dateFormatValidator]],
          add_3_2381:[null, [this.dateFormatValidator]],
          add_3_1303:[''],
          445:[''],
          452:[''],
          453:['', [Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]],
          888:['', [Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]],
          446:[''],
          447:[''],
          448:[''],
          450:[null],
          451:['', [this.zipCodeValidator]],
          454:[null, [this.dateFormatValidator]],
          455:[null, [this.dateFormatValidator]],
          889:['',[Validators.pattern(this.emailPattern)]],
          cmd_0099:[''],
          cmd_0101:[''],
          456:[''],
          463:[''],
          464:['', [Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]],
          890:['', [Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]],
          457:[''],
          458:[''],
          459:[''],
          461:[null],
          462:['',[Validators.pattern(this.zippattern)]],
          465:[null, [this.dateFormatValidator]],
          466:[null, [this.dateFormatValidator]],
          891:['',[Validators.pattern(this.emailPattern)]],
          cmd_00102:[''],
          467:[''],
          474:[''],
          475:['', [Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]],
          892:['', [Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]],
          468:[''],
          469:[''],
          470:[''],
          472:[null],
          473:['',[Validators.pattern(this.zippattern)]],
          476:[null, [this.dateFormatValidator]],
          477:[null, [this.dateFormatValidator]],
          893:['',[Validators.pattern(this.emailPattern)]],
          cmd_00103:[''],
          1935:[''],
          1943:[''],
          1944:['', [Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]],
          1945:['', [Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]],
          1936:[''],
          1937:[''],
          1938:[''],
          1940:[null],
          1941:['',[Validators.pattern(this.zippattern)]],
          1947:[null, [this.dateFormatValidator]],
          1948:[null, [this.dateFormatValidator]],
          1946:['',[Validators.pattern(this.emailPattern)]],
          cmd_00104:[''],
          cmd_0105:[''],
          1138:[''],
          3395:[null, [this.dateFormatValidator]],
          3396:[null, [this.dateFormatValidator]],
          1831:[''],
          3397:[null, [this.dateFormatValidator]],
          3398:[null, [this.dateFormatValidator]],
          1832:[''],
          3399:[null, [this.dateFormatValidator]],
          3400:[null, [this.dateFormatValidator]],
          1953:[''],
          3401:[null, [this.dateFormatValidator]],
          3402:[null, [this.dateFormatValidator]],
          add_1_4119:[''],
          add_1_4117:[null, [this.dateFormatValidator]],
          add_1_4118:[null, [this.dateFormatValidator]],
          add_2_4119:[''],
          add_2_4117:[null, [this.dateFormatValidator]],
          add_2_4118:[null, [this.dateFormatValidator]],
          add_3_4119:[''],
          add_3_4117:[null, [this.dateFormatValidator]],
          add_3_4118:[null, [this.dateFormatValidator]],
          add_4_4119:[''],
          add_4_4117:[null, [this.dateFormatValidator]],
          add_4_4118:[null, [this.dateFormatValidator]],
          add_5_4119:[''],
          add_5_4117:[null, [this.dateFormatValidator]],
          add_5_4118:[null, [this.dateFormatValidator]],
          add_6_4119:[''],
          add_6_4117:[null, [this.dateFormatValidator]],
          add_6_4118:[null, [this.dateFormatValidator]],
          add_7_4119:[''],
          add_7_4117:[null, [this.dateFormatValidator]],
          add_7_4118:[null, [this.dateFormatValidator]],
          368:[null,[Validators.required]],
          359:['',[Validators.required]],
          2722:['',[Validators.required]],
          2721:['',[Validators.required]],
          364:[null,[Validators.required]],
          360:['',[Validators.required]],
          361:[''],
          362:['',[Validators.required]],
          365:['',[Validators.required,this.zipCodeValidator]],
          369:['', [Validators.required,Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]],
          370:['', [Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]],
          371:['',[Validators.required,Validators.pattern(this.emailPattern)]],
          372:['',[Validators.required]],
          380:[null,[Validators.required]],
          2723:['',[Validators.required]],
          2724:['',[Validators.required]],
          373:['',[Validators.required]],
          374:[''],
          375:['',[Validators.required]],
          377:[null,[Validators.required]],
          378:['',[Validators.required,this.zipCodeValidator]],
          381:['', [Validators.required,Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]],
          382:['', [Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]],
          383:['',[Validators.required,Validators.pattern(this.emailPattern)]],
          384:['',[Validators.required]],
          393:[null,[Validators.required]],
          2725:['',[Validators.required]],
          2726:['',[Validators.required]],
          385:['',[Validators.required]],
          386:[''],
          387:['',[Validators.required]],
          389:[null,[Validators.required]],
          390:['',[Validators.required,this.zipCodeValidator]],
          394:['', [Validators.required,Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]],
          395:['', [Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]],
          396:['',[Validators.required,Validators.pattern(this.emailPattern)]],
          cmd_00106:[''],
          add_1_2538:[null],
          add_1_1374:[null, [this.dateFormatValidator]],
          add_1_1016:[''],
          add_2_2538:[null],
          add_2_1374:[null, [this.dateFormatValidator]],
          add_2_1016:[''],
          add_3_2538:[null],
          add_3_1374:[null, [this.dateFormatValidator]],
          add_3_1016:[''],
          add_4_2538:[null],
          add_4_1374:[null, [this.dateFormatValidator]],
          add_4_1016:[''],
          add_5_2538:[null],
          add_5_1374:[null, [this.dateFormatValidator]],
          add_5_1016:[''],
          add_6_2538:[null],
          add_6_1374:[null, [this.dateFormatValidator]],
          add_6_1016:[''],
          cmd_00107:[''],
          345:[''],
          346:[''],
          347:[''],
          1240:[''],
          901:[''],
          902:[''],
          903:[''],
          905:[null],
          906:['', [this.zipCodeValidator]],
          1273:['', [Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]],
          1266:['', [Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]],
          350:[null],
          351:[null],
          1639:['',[Validators.pattern(this.emailPattern)]],
          348:[null, [this.dateFormatValidator]],
          945:[null, [this.dateFormatValidator]],
          349:[null, [this.dateFormatValidator]],
          cmd_00108:[''],
          add_1_1242:[''],
          add_1_1256:[''],
          add_1_1257:[''],
          add_1_1243:[''],
          add_1_1244:[''],
          add_1_1245:[''],
          add_1_1246:[''],
          add_1_1254:['', [Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]],
          add_1_1255:['', [Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]],
          add_1_1248:[null],
          add_1_1249:['', [this.zipCodeValidator]],
          add_1_1265:[null],
          add_1_1857:['',[Validators.pattern(this.emailPattern)]],
          add_1_1264:[null],
          add_1_4637:[null, [this.dateFormatValidator]],
          add_1_1251:[null, [this.dateFormatValidator]],
          add_1_1253:[null, [this.dateFormatValidator]],
          add_2_1242:[''],
          add_2_1256:[''],
          add_2_1257:[''],
          add_2_1243:[''],
          add_2_1244:[''],
          add_2_1245:[''],
          add_2_1246:[''],
          add_2_1254:['', [Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]],
          add_2_1255:['', [Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]],
          add_2_1248:[null],
          add_2_1249:['',[Validators.pattern(this.zippattern)]],
          add_2_1265:[null],
          add_2_1857:['',[Validators.pattern(this.emailPattern)]],
          add_2_1264:[null],
          add_2_4637:[null, [this.dateFormatValidator]],
          add_2_1251:[null, [this.dateFormatValidator]],
          add_2_1253:[null, [this.dateFormatValidator]],
          add_3_1242:[''],
          add_3_1256:[''],
          add_3_1257:[''],
          add_3_1243:[''],
          add_3_1244:[''],
          add_3_1245:[''],
          add_3_1246:[''],
          add_3_1254:['', [Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]],
          add_3_1255:['', [Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]],
          add_3_1248:[null],
          add_3_1249:['',[Validators.pattern(this.zippattern)]],
          add_3_1265:[null],
          add_3_1857:['',[Validators.pattern(this.emailPattern)]],
          add_3_1264:[null],
          add_3_4637:[null, [this.dateFormatValidator]],
          add_3_1251:[null, [this.dateFormatValidator]],
          add_3_1253:[null, [this.dateFormatValidator]],
          add_4_1242:[''],
          add_4_1256:[''],
          add_4_1257:[''],
          add_4_1243:[''],
          add_4_1244:[''],
          add_4_1245:[''],
          add_4_1246:[''],
          add_4_1254:['', [Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]],
          add_4_1255:['', [Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]],
          add_4_1248:[null],
          add_4_1249:['',[Validators.pattern(this.zippattern)]],
          add_4_1265:[null],
          add_4_1857:['',[Validators.pattern(this.emailPattern)]],
          add_4_1264:[null],
          add_4_4637:[null, [this.dateFormatValidator]],
          add_4_1251:[null, [this.dateFormatValidator]],
          add_4_1253:[null, [this.dateFormatValidator]],
          cmd_00109:['',[Validators.required]],
          cmd_00110:['',[Validators.required]],
          cmd_00111:['',[Validators.required]],
          cmd_00112:['',[Validators.required]],
          cmd_00113:['',[Validators.required]],
          cmd_00114:['',[Validators.required]],
          cmd_00115:['',[Validators.required]],
          cmd_00116:['',[Validators.required]],
          cmd_00117:['',[Validators.required]],
          cmd_00118:['',[Validators.required]],
          cmd_00119:['',[Validators.required]],
          cmd_00120:['',[Validators.required]],
          cmd_00121:['',[Validators.required]],
          cmd_00122:['',[Validators.required]],
          cmd_00123:['',[Validators.required]],
          cmd_00124:['',[Validators.required]],
          cmd_00125:[null, [Validators.required,this.dateFormatValidator]],
          cmd_00126:['',[Validators.required]],
          cmd_00127:['',[Validators.required]],
          cmd_00128:[null, [Validators.required,this.dateFormatValidator]],
          1208:[null, [this.dateFormatValidator]],
          1216:[''],
          1218:[''],
          1217:[''],
          1209:[null, [this.dateFormatValidator]],
          1210:[''],
          2012:[''],
          1844:[''],
          1845:[''],
          1847:[null],
          1848:['',[Validators.pattern(this.zippattern)]],
          1213:[''],
          1858:[''],
          1226:[null, [this.dateFormatValidator]],
          1231:[''],
          cmd_00129:[null, [Validators.required,this.dateFormatValidator]],
          cmd_00130:['',[Validators.required]],
          cmd_00131:[''],
          cmd_00132:['',[Validators.required]],



        });
        console.log(this.eyeForm);
    
    }
    public esignOpenOne() {
      const modalRef = this.modalService.open(EsignComponent, this.modalOption);
      modalRef.result.then(result => { });
      modalRef.componentInstance.base64Img.subscribe(data => {
        modalRef.close();
        this.finalImgPath = data.finalFilePath;
        let temp = {
          cmd_00124: this.finalImgPath
        };
        this.eyeForm.patchValue(temp);
  
        this.eyeForm.controls['cmd_00124'].setValue(this.finalImgPath);
        this.finalImg = data.base64Img;
        this.esignOne = this._sanitizer.bypassSecurityTrustResourceUrl(
          'data:image/jpg;base64,' + this.finalImg
        );
        this.showOne = true;
      });
    }
    public esignOpenTwo() {
      const modalRef = this.modalService.open(EsignComponent, this.modalOption);
      modalRef.result.then(result => { });
      modalRef.componentInstance.base64Img.subscribe(data => {
        modalRef.close();
        this.finalImgPath = data.finalFilePath;
        let temp = {
          cmd_00127: this.finalImgPath
        };
        this.eyeForm.patchValue(temp);
  
        this.eyeForm.controls['cmd_00127'].setValue(this.finalImgPath);
        this.finalImg = data.base64Img;
        this.esignTwo = this._sanitizer.bypassSecurityTrustResourceUrl(
          'data:image/jpg;base64,' + this.finalImg
        );
        this.showTwo = true;
      });
    }
    // public esignOpenThree() {
    //   const modalRef = this.modalService.open(EsignComponent, this.modalOption);
    //   modalRef.result.then(result => { });
    //   modalRef.componentInstance.base64Img.subscribe(data => {
    //     modalRef.close();
    //     this.finalImgPathThree = data.finalFilePath;
    //     let temp = {
    //       cmd_00130: this.finalImgPathThree
    //     };
    //     this.eyeForm.patchValue(temp);
  
    //     this.eyeForm.controls['cmd_00130'].setValue(this.finalImgPathThree);
    //     this.finalImg = data.base64Img;
    //     this.esignTwo = this._sanitizer.bypassSecurityTrustResourceUrl(
    //       'data:image/jpg;base64,' + this.finalImg
    //     );
    //     this.showThree = true;
    //   });
    // }
    public esignOpenThree() {
      const modalRef = this.modalService.open(EsignComponent, this.modalOption);
      modalRef.result.then(result => {});
      modalRef.componentInstance.base64Img.subscribe(data => {
        modalRef.close();
        this.finalImgPathThree = data.finalFilePath;
        let temp = {
          cmd_00130: this.finalImgPathThree
        };
        this.eyeForm.patchValue(temp);
        this.eyeForm.controls['cmd_00130'].setValue(this.finalImgPathThree);
        this.finalImgThree = data.base64Img;
        this.esignThree = this._sanitizer.bypassSecurityTrustResourceUrl(
          'data:image/jpg;base64,' + this.finalImgThree
        );
        this.showThree = true;
      });
    }
    setErrorControl(name){
      this.eyeForm.controls[name].setErrors({ errors: true });
    }
    removeErrorControl(name){
      this.eyeForm.controls[name].setErrors(null)
    }
    onChangeFirstName(event) {
      this.firstName = event.target.value;
      this.finalName = this.firstName + ' ' + this.lastName;
      let temp = {
        cmd_00126: this.finalName
      };
      this.eyeForm.patchValue(temp);
    }
    onChangeLastName(event) {
      this.lastName = event.target.value;
      this.finalName = this.firstName + ' ' + this.lastName;
      let temp = {
        cmd_00126: this.finalName
      };
      this.eyeForm.patchValue(temp);
    }
    onChangeMiddleName(event) {
      
      this.middleName = event.target.value;
      this.finalName = this.firstName + ' ' + this.middleName+ ' ' + this.lastName;
      let temp = {
        cmd_00126: this.finalName
      };
      this.eyeForm.patchValue(temp);
    }
    handleNAchange(cName,eName?){
      let result:boolean=false;
      if(cName=='cmd_0013'){
         let x=[554,556,557,560,561,563,564,567,946,953,954,948]
      x.forEach(element => {
        if((this.eyeForm.controls[element].value=='' || this.eyeForm.controls[element].value==null) && !this.eyeForm.controls[cName].value){
          this.setErrorControl(element);
          result = true
        }
          else{
             this.removeErrorControl(element);
           }
         });
       }
       
      if(cName=='cmd_0064'){
        let x=[503,504,512,510,1238,513,506,508]
        x.forEach(element => {
          if((this.eyeForm.controls[element].value=='' || this.eyeForm.controls[element].value==null) && !this.eyeForm.controls[cName].value){
            this.setErrorControl(element);
            result = true
          }
          else{
            this.removeErrorControl(element);
          }
        });
      } 
      if(cName=='cmd_0066'){
        let x=[490,491,492,493,495,496,2353,500,2351,497,498,499]
        x.forEach(element => {
          const ctrl = this.eyeForm.controls[element];
          if((this.eyeForm.controls[element].value=='' || this.eyeForm.controls[element].value==null) && !this.eyeForm.controls[cName].value){
            this.setErrorControl(element);
            result = true
          }
          else if ((element === 2351) && !/^[0-9]{3}-[0-9]{3}-[0-9]{4}$/.test(ctrl.value) && (!this.eyeForm.controls[cName].value || (this.eyeForm.controls[cName].value && ctrl.value !== '' && ctrl.value !== null))) {
            this.setErrorControl(element);
            ctrl.setErrors({ 'incorrectPhoneFormat': true });
            result = true;
          } else if ((element === 2351) && this.eyeForm.controls[cName].value) {
            this.removeErrorControl(element);
            ctrl.setErrors(null);
          }
          else if ((element === 2353) && !this.emailPattern.test(ctrl.value) && (!this.eyeForm.controls[cName].value || (this.eyeForm.controls[cName].value && ctrl.value !== '' && ctrl.value !== null))) {
            this.setErrorControl(element);
            ctrl.setErrors({ 'invalidEmail': true });
            result = true;
          } else if ((element === 2353) && this.eyeForm.controls[cName].value) {
            this.removeErrorControl(element);
            ctrl.setErrors(null);
          } 
          else if ((element === 496) && !/^.{5,9}$/.test(ctrl.value) && (!this.eyeForm.controls[cName].value || (this.eyeForm.controls[cName].value && ctrl.value !== '' && ctrl.value !== null))) {
            this.setErrorControl(element);
            ctrl.setErrors({ 'invalidZipCode': true });
            result = true;
          } else if ((element === 496  ) && this.eyeForm.controls[cName].value) {
            this.removeErrorControl(element);
            ctrl.setErrors(null);
          } 
          
          else{
            this.removeErrorControl(element);
          }
        });
      } 
      if(cName=='cmd_0069'){
        let x=[517,518,520,522,523,4579,527,1318,1319,524,525,526]
        x.forEach(element => {
          const ctrl = this.eyeForm.controls[element];
          if((this.eyeForm.controls[element].value=='' || this.eyeForm.controls[element].value==null) && !this.eyeForm.controls[cName].value){
            this.setErrorControl(element);
            result = true
          }
          else if ((element === 1318 || element === 1319) && !/^[0-9]{3}-[0-9]{3}-[0-9]{4}$/.test(ctrl.value) && (!this.eyeForm.controls[cName].value || (this.eyeForm.controls[cName].value && ctrl.value !== '' && ctrl.value !== null))) {
            this.setErrorControl(element);
            ctrl.setErrors({ 'incorrectPhoneFormat': true });
            result = true;
          } else if ((element === 1318 || element === 1319) && this.eyeForm.controls[cName].value) {
            this.removeErrorControl(element);
            ctrl.setErrors(null);
          }
          else if ((element === 4579) && !this.emailPattern.test(ctrl.value) && (!this.eyeForm.controls[cName].value || (this.eyeForm.controls[cName].value && ctrl.value !== '' && ctrl.value !== null))) {
            this.setErrorControl(element);
            ctrl.setErrors({ 'invalidEmail': true });
            result = true;
          } else if ((element === 4579) && this.eyeForm.controls[cName].value) {
            this.removeErrorControl(element);
            ctrl.setErrors(null);
          } 
          else if ((element === 523) && !/^.{5,9}$/.test(ctrl.value) && (!this.eyeForm.controls[cName].value || (this.eyeForm.controls[cName].value && ctrl.value !== '' && ctrl.value !== null))) {
            this.setErrorControl(element);
            ctrl.setErrors({ 'invalidZipCode': true });
            result = true;
          } else if ((element === 523  ) && this.eyeForm.controls[cName].value) {
            this.removeErrorControl(element);
            ctrl.setErrors(null);
          } 
          
          else{
            this.removeErrorControl(element);
          }
        });
      } 
      if(cName=='cmd_0071'){
        let x=[529,531,533,535,536,4581,530,1316,1317,537,538,539]
        x.forEach(element => {
          const ctrl = this.eyeForm.controls[element];
          if((this.eyeForm.controls[element].value=='' || this.eyeForm.controls[element].value==null) && !this.eyeForm.controls[cName].value){
            this.setErrorControl(element);
            result = true
          }
          else if ((element === 1316 || element === 1317) && !/^[0-9]{3}-[0-9]{3}-[0-9]{4}$/.test(ctrl.value) && (!this.eyeForm.controls[cName].value || (this.eyeForm.controls[cName].value && ctrl.value !== '' && ctrl.value !== null))) {
            this.setErrorControl(element);
            ctrl.setErrors({ 'incorrectPhoneFormat': true });
            result = true;
          } else if ((element === 1316 || element === 1317) && this.eyeForm.controls[cName].value) {
            this.removeErrorControl(element);
            ctrl.setErrors(null);
          }
          else if ((element === 4581) && !this.emailPattern.test(ctrl.value) && (!this.eyeForm.controls[cName].value || (this.eyeForm.controls[cName].value && ctrl.value !== '' && ctrl.value !== null))) {
            this.setErrorControl(element);
            ctrl.setErrors({ 'invalidEmail': true });
            result = true;
          } else if ((element === 4581) && this.eyeForm.controls[cName].value) {
            this.removeErrorControl(element);
            ctrl.setErrors(null);
          } 
          else if ((element === 536) && !/^.{5,9}$/.test(ctrl.value) && (!this.eyeForm.controls[cName].value || (this.eyeForm.controls[cName].value && ctrl.value !== '' && ctrl.value !== null))) {
            this.setErrorControl(element);
            ctrl.setErrors({ 'invalidZipCode': true });
            result = true;
          } else if ((element === 536  ) && this.eyeForm.controls[cName].value) {
            this.removeErrorControl(element);
            ctrl.setErrors(null);
          } 
          else{
            this.removeErrorControl(element);
          }
        });
      } 
      if(cName=='cmd_0074'){
        let x=[542,544,546,548,549,4583,543,1322,1323,550,551,552,539]
        x.forEach(element => {
          const ctrl = this.eyeForm.controls[element];
          if((this.eyeForm.controls[element].value=='' || this.eyeForm.controls[element].value==null) && !this.eyeForm.controls[cName].value){
            this.setErrorControl(element);
            result = true
          }
          else if ((element === 1322 || element === 1323) && !/^[0-9]{3}-[0-9]{3}-[0-9]{4}$/.test(ctrl.value) && (!this.eyeForm.controls[cName].value || (this.eyeForm.controls[cName].value && ctrl.value !== '' && ctrl.value !== null))) {
            this.setErrorControl(element);
            ctrl.setErrors({ 'incorrectPhoneFormat': true });
            result = true;
          } else if ((element === 1322 || element === 1323) && this.eyeForm.controls[cName].value) {
            this.removeErrorControl(element);
            ctrl.setErrors(null);
          }
          else if ((element === 4583) && !this.emailPattern.test(ctrl.value) && (!this.eyeForm.controls[cName].value || (this.eyeForm.controls[cName].value && ctrl.value !== '' && ctrl.value !== null))) {
            this.setErrorControl(element);
            ctrl.setErrors({ 'invalidEmail': true });
            result = true;
          } else if ((element === 4583) && this.eyeForm.controls[cName].value) {
            this.removeErrorControl(element);
            ctrl.setErrors(null);
          } 
          else if ((element === 549) && !/^.{5,9}$/.test(ctrl.value) && (!this.eyeForm.controls[cName].value || (this.eyeForm.controls[cName].value && ctrl.value !== '' && ctrl.value !== null))) {
            this.setErrorControl(element);
            ctrl.setErrors({ 'invalidZipCode': true });
            result = true;
          } else if ((element === 549  ) && this.eyeForm.controls[cName].value) {
            this.removeErrorControl(element);
            ctrl.setErrors(null);
          } 
          else{
            this.removeErrorControl(element);
          }
        });
      } 
      if(cName=='cmd_0077'){
        let x=[293,296,295,309,311,1634]
        x.forEach(element => {
          if((this.eyeForm.controls[element].value=='' || this.eyeForm.controls[element].value==null) && !this.eyeForm.controls[cName].value){
            this.setErrorControl(element);
            result = true
          }
          else{
            this.removeErrorControl(element);
          }
        });
      }
      if(cName=='cmd_0078'){
        let x=['add_1_1010','add_1_999','add_1_1432','add_1_1003','add_1_1004']
        x.forEach(element => {
          if((this.eyeForm.controls[element].value=='' || this.eyeForm.controls[element].value==null) && !this.eyeForm.controls[cName].value){
            this.setErrorControl(element);
            result = true
          }
          else{
            this.removeErrorControl(element);
          }
        });
      } 
      if(cName=='cmd_0085'){
        let x=[418,1305,2136,419,420,421,423,424,894,426,2142]
        x.forEach(element => {
          const ctrl = this.eyeForm.controls[element];
          if((this.eyeForm.controls[element].value=='' || this.eyeForm.controls[element].value==null) && !this.eyeForm.controls[cName].value){
            this.setErrorControl(element);
            result = true
          }
          else if ((element === 1305 || element === 2136) && !/^[0-9]{3}-[0-9]{3}-[0-9]{4}$/.test(ctrl.value) && (!this.eyeForm.controls[cName].value || (this.eyeForm.controls[cName].value && ctrl.value !== '' && ctrl.value !== null))) {
            this.setErrorControl(element);
            ctrl.setErrors({ 'incorrectPhoneFormat': true });
            result = true;
          } else if ((element === 1305 || element === 2136) && this.eyeForm.controls[cName].value) {
            this.removeErrorControl(element);
            ctrl.setErrors(null);
          }
          else if ((element === 2142) && !this.emailPattern.test(ctrl.value) && (!this.eyeForm.controls[cName].value || (this.eyeForm.controls[cName].value && ctrl.value !== '' && ctrl.value !== null))) {
            this.setErrorControl(element);
            ctrl.setErrors({ 'invalidEmail': true });
            result = true;
          } else if ((element === 2142) && this.eyeForm.controls[cName].value) {
            this.removeErrorControl(element);
            ctrl.setErrors(null);
          } 
          else if ((element === 424) && !/^.{5,9}$/.test(ctrl.value) && (!this.eyeForm.controls[cName].value || (this.eyeForm.controls[cName].value && ctrl.value !== '' && ctrl.value !== null))) {
            this.setErrorControl(element);
            ctrl.setErrors({ 'invalidZipCode': true });
            result = true;
          } else if ((element === 424  ) && this.eyeForm.controls[cName].value) {
            this.removeErrorControl(element);
            ctrl.setErrors(null);
          } 
          else{
            this.removeErrorControl(element);
          }
        });
      }
      if(cName=='cmd_0094'){
        let x=['add_2_986','add_2_1311','add_2_2139','add_2_987','add_2_988','add_2_989','add_2_991','add_2_992','add_2_995','add_2_994']
        x.forEach(element => {
          const ctrl = this.eyeForm.controls[element];
          if((this.eyeForm.controls[element].value=='' || this.eyeForm.controls[element].value==null) && !this.eyeForm.controls[cName].value){
            this.setErrorControl(element);
            result = true
          }
          else if ((element === 'add_2_1311' || element === 'add_2_2139') && !/^[0-9]{3}-[0-9]{3}-[0-9]{4}$/.test(ctrl.value) && (!this.eyeForm.controls[cName].value || (this.eyeForm.controls[cName].value && ctrl.value !== '' && ctrl.value !== null))) {
            this.setErrorControl(element);
            ctrl.setErrors({ 'incorrectPhoneFormat': true });
            result = true;
          } else if ((element === 'add_2_1311' || element === 'add_2_2139') && this.eyeForm.controls[cName].value) {
            this.removeErrorControl(element);
            ctrl.setErrors(null);
          }
          // else if ((element === 'add_2_2145') && !this.emailPattern.test(ctrl.value) && (!this.eyeForm.controls[cName].value || (this.eyeForm.controls[cName].value && ctrl.value !== '' && ctrl.value !== null))) {
          //   this.setErrorControl(element);
          //   ctrl.setErrors({ 'invalidEmail': true });
          //   result = true;
          // } else if ((element === 'add_2_2145') && this.eyeForm.controls[cName].value) {
          //   this.removeErrorControl(element);
          //   ctrl.setErrors(null);
          // }
          else if ((element === 'add_2_992') && !/^.{5,9}$/.test(ctrl.value) && (!this.eyeForm.controls[cName].value || (this.eyeForm.controls[cName].value && ctrl.value !== '' && ctrl.value !== null))) {
            this.setErrorControl(element);
            ctrl.setErrors({ 'invalidZipCode': true });
            result = true;
          } else if ((element === 'add_2_992'  ) && this.eyeForm.controls[cName].value) {
            this.removeErrorControl(element);
            ctrl.setErrors(null);
          } 
          else{
            this.removeErrorControl(element);
          }
        });
      }
      if(cName=='cmd_0095'){
        let x=['add_1_1183','add_1_1313','add_1_2140','add_1_1184','add_1_1185','add_1_1186','add_1_1188','add_1_1189','add_1_1191','add_1_2381','add_1_1303']
        x.forEach(element => {
          const ctrl = this.eyeForm.controls[element];
          if((this.eyeForm.controls[element].value=='' || this.eyeForm.controls[element].value==null) && !this.eyeForm.controls[cName].value){
            this.setErrorControl(element);
            result = true
          }
          else if ((element === 'add_1_1313' || element === 'add_1_2140') && !/^[0-9]{3}-[0-9]{3}-[0-9]{4}$/.test(ctrl.value) && (!this.eyeForm.controls[cName].value || (this.eyeForm.controls[cName].value && ctrl.value !== '' && ctrl.value !== null))) {
            this.setErrorControl(element);
            ctrl.setErrors({ 'incorrectPhoneFormat': true });
            result = true;
          } else if ((element === 'add_1_1313' || element === 'add_1_2140') && this.eyeForm.controls[cName].value) {
            this.removeErrorControl(element);
            ctrl.setErrors(null);
          }
          else if ((element === 'add_1_1189') && !/^.{5,9}$/.test(ctrl.value) && (!this.eyeForm.controls[cName].value || (this.eyeForm.controls[cName].value && ctrl.value !== '' && ctrl.value !== null))) {
            this.setErrorControl(element);
            ctrl.setErrors({ 'invalidZipCode': true });
            result = true;
          } else if ((element === 'add_1_1189'  ) && this.eyeForm.controls[cName].value) {
            this.removeErrorControl(element);
            ctrl.setErrors(null);
          } 
          else{
            this.removeErrorControl(element);
          }
        });
      }
      if(cName=='cmd_0101'){
        let x=[445,452,453,888,446,447,448,450,451,454,455,889]
        x.forEach(element => {
          const ctrl = this.eyeForm.controls[element];
          if((this.eyeForm.controls[element].value=='' || this.eyeForm.controls[element].value==null) && !this.eyeForm.controls[cName].value){
            this.setErrorControl(element);
            result = true
          }
          else if ((element === 453 || element === 888 ) && !/^[0-9]{3}-[0-9]{3}-[0-9]{4}$/.test(ctrl.value) && (!this.eyeForm.controls[cName].value || (this.eyeForm.controls[cName].value && ctrl.value !== '' && ctrl.value !== null))) {
            this.setErrorControl(element);
            ctrl.setErrors({ 'incorrectPhoneFormat': true });
            result = true;
          } else if ((element ===  453 || element === 888 ) && this.eyeForm.controls[cName].value) {
            this.removeErrorControl(element);
            ctrl.setErrors(null);
          }
          else if ((element === 889) && !this.emailPattern.test(ctrl.value) && (!this.eyeForm.controls[cName].value || (this.eyeForm.controls[cName].value && ctrl.value !== '' && ctrl.value !== null))) {
            this.setErrorControl(element);
            ctrl.setErrors({ 'invalidEmail': true });
            result = true;
          } else if ((element === 889) && this.eyeForm.controls[cName].value) {
            this.removeErrorControl(element);
            ctrl.setErrors(null);
          }
          else if ((element === 451) && !/^.{5,9}$/.test(ctrl.value) && (!this.eyeForm.controls[cName].value || (this.eyeForm.controls[cName].value && ctrl.value !== '' && ctrl.value !== null))) {
            this.setErrorControl(element);
            ctrl.setErrors({ 'invalidZipCode': true });
            result = true;
          } else if ((element === 451  ) && this.eyeForm.controls[cName].value) {
            this.removeErrorControl(element);
            ctrl.setErrors(null);
          } 
          else{
            this.removeErrorControl(element);
          }
        });
      }
      if(cName=='cmd_0105'){
        let x=[1138,3395,3396,1831,3397,3398,1832,3399,3400,1953,3401,3402,'add_1_4119','add_1_4117','add_1_4118']
        x.forEach(element => {
          if((this.eyeForm.controls[element].value=='' || this.eyeForm.controls[element].value==null) && !this.eyeForm.controls[cName].value){
            this.setErrorControl(element);
            result = true
          }
          else{
            this.removeErrorControl(element);
          }
        });
      }
      if(cName=='cmd_00106'){
        let x=['add_1_2538','add_1_1374','add_1_1016']
        x.forEach(element => {
          if((this.eyeForm.controls[element].value=='' || this.eyeForm.controls[element].value==null) && !this.eyeForm.controls[cName].value){
            this.setErrorControl(element);
            result = true
          }
          else{
            this.removeErrorControl(element);
          }
        });
      }
      if(cName=='cmd_00107'){
        let x=[345,346,347,1240,901,902,903,1266,905,906,350,351,1639,348,945,349]
        x.forEach(element => {
          const ctrl = this.eyeForm.controls[element];
          if((this.eyeForm.controls[element].value=='' || this.eyeForm.controls[element].value==null) && !this.eyeForm.controls[cName].value){
            this.setErrorControl(element);
            result = true
          }
          else if ((element === 1266) && !/^[0-9]{3}-[0-9]{3}-[0-9]{4}$/.test(ctrl.value) && (!this.eyeForm.controls[cName].value || (this.eyeForm.controls[cName].value && ctrl.value !== '' && ctrl.value !== null))) {
            this.setErrorControl(element);
            ctrl.setErrors({ 'incorrectPhoneFormat': true });
            result = true;
          } else if ((element ===  1266) && this.eyeForm.controls[cName].value) {
            this.removeErrorControl(element);
            ctrl.setErrors(null);
          }
          else if ((element === 1639) && !this.emailPattern.test(ctrl.value) && (!this.eyeForm.controls[cName].value || (this.eyeForm.controls[cName].value && ctrl.value !== '' && ctrl.value !== null))) {
            this.setErrorControl(element);
            ctrl.setErrors({ 'invalidEmail': true });
            result = true;
          } else if ((element === 1639) && this.eyeForm.controls[cName].value) {
            this.removeErrorControl(element);
            ctrl.setErrors(null);
          }
          else if ((element === 906) && !/^.{5,9}$/.test(ctrl.value) && (!this.eyeForm.controls[cName].value || (this.eyeForm.controls[cName].value && ctrl.value !== '' && ctrl.value !== null))) {
            this.setErrorControl(element);
            ctrl.setErrors({ 'invalidZipCode': true });
            result = true;
          } else if ((element === 906  ) && this.eyeForm.controls[cName].value) {
            this.removeErrorControl(element);
            ctrl.setErrors(null);
          } 
          else{
            this.removeErrorControl(element);
          }
        });
      }
      if(cName=='cmd_00108'){
        let x=['add_1_1242','add_1_1256','add_1_1257','add_1_1243','add_1_1244','add_1_1245','add_1_1246','add_1_1254','add_1_1248','add_1_1249','add_1_1265','add_1_1857','add_1_1264','add_1_4637','add_1_1251','add_1_1253']
        x.forEach(element => {
          const ctrl = this.eyeForm.controls[element];
          if((this.eyeForm.controls[element].value=='' || this.eyeForm.controls[element].value==null) && !this.eyeForm.controls[cName].value){
            this.setErrorControl(element);
            result = true
          }
          else if ((element === 'add_1_1254') && !/^[0-9]{3}-[0-9]{3}-[0-9]{4}$/.test(ctrl.value) && (!this.eyeForm.controls[cName].value || (this.eyeForm.controls[cName].value && ctrl.value !== '' && ctrl.value !== null))) {
            this.setErrorControl(element);
            ctrl.setErrors({ 'incorrectPhoneFormat': true });
            result = true;
          } else if ((element ===  'add_1_1254') && this.eyeForm.controls[cName].value) {
            this.removeErrorControl(element);
            ctrl.setErrors(null);
          }
          else if ((element === 'add_1_1857') && !this.emailPattern.test(ctrl.value) && (!this.eyeForm.controls[cName].value || (this.eyeForm.controls[cName].value && ctrl.value !== '' && ctrl.value !== null))) {
            this.setErrorControl(element);
            ctrl.setErrors({ 'invalidEmail': true });
            result = true;
          } else if ((element === 'add_1_1857') && this.eyeForm.controls[cName].value) {
            this.removeErrorControl(element);
            ctrl.setErrors(null);
          }
          else if ((element === 'add_1_1249') && !/^.{5,9}$/.test(ctrl.value) && (!this.eyeForm.controls[cName].value || (this.eyeForm.controls[cName].value && ctrl.value !== '' && ctrl.value !== null))) {
            this.setErrorControl(element);
            ctrl.setErrors({ 'invalidZipCode': true });
            result = true;
          } else if ((element === 'add_1_1249'  ) && this.eyeForm.controls[cName].value) {
            this.removeErrorControl(element);
            ctrl.setErrors(null);
          } 
          else{
            this.removeErrorControl(element);
          }
        });
      }

    }

    saveForm(type) {
      this.isSubmit = true;
      this.handleNAchange('cmd_0013');
      this.handleNAchange('cmd_0064');
      this.handleNAchange('cmd_0066');
      this.handleNAchange('cmd_0069');
      this.handleNAchange('cmd_0071');
      this.handleNAchange('cmd_0074');
      this.handleNAchange('cmd_0077');
      this.handleNAchange('cmd_0078');
      this.handleNAchange('cmd_0085');
      this.handleNAchange('cmd_0094');
      this.handleNAchange('cmd_0095');
      this.handleNAchange('cmd_0101');
      this.handleNAchange('cmd_0105');
      this.handleNAchange('cmd_00106');
      this.handleNAchange('cmd_00107');
      this.handleNAchange('cmd_00108');



      console.log(this.eyeForm);
      if (type == 'submit') {
        if (this.eyeForm.invalid) {
          this.notiService.showError(
            'Please Fill the all mandatory fields to continue',
            '',
            3000
          );
          for (const key in this.eyeForm.controls) {
            if (this.eyeForm.controls.hasOwnProperty(key)) {
              const element = this.eyeForm.controls[key];
              if(element.status=="INVALID"){
              console.log(key + " " + element.status)
              }
            }
          }
        } else {
          if (this.eyeForm.controls['cmd_00124'].value == null || this.eyeForm.controls['cmd_00124'].value == '') {
            this.notiService.showError('Please add your Signature')
            return
          }
          if (this.eyeForm.controls['cmd_00127'].value == null || this.eyeForm.controls['cmd_00127'].value == '') {
            this.notiService.showError('Please add your Signature')
            return
          }
          if (this.eyeForm.controls['cmd_00130'].value == null || this.eyeForm.controls['cmd_00130'].value == '') {
            this.notiService.showError('Please add your Signature')
            return
          }
          let data = {
            formType: type,
            formValues: this.eyeForm.value
          };
          console.log("Form has been submitted successfully");
          this.onSubmit.emit(data);
          console.log(this.eyeForm);
  
        }
      } else {
        let data = {
          formType: type,
          formValues: this.eyeForm.value,
          formId:48
        };
        this.onSubmit.emit(data);
        this.onClose.emit('close modal');
      }
    }
    close() {
      this.onClose.emit('close modal');
    }
    public setEsign(filePath, fieldId) {
      // console.log(filePath);
      if (fieldId == 'cmd_00124') {
        this.esignOne = this._sanitizer.bypassSecurityTrustResourceUrl(
          'data:image/jpg;base64,' + filePath
        );
        this.showOne = true;
      }
      if (fieldId == 'cmd_00127') {
        this.esignTwo = this._sanitizer.bypassSecurityTrustResourceUrl(
          'data:image/jpg;base64,' + filePath
        );
        this.showTwo = true;
      }
      if (fieldId == 'cmd_00130') {
        this.esignThree = this._sanitizer.bypassSecurityTrustResourceUrl(
          'data:image/jpg;base64,' + filePath
        );
        this.showThree = true;
      }
    }
    public previewEsign(filePath, fieldId) {
      //this.spinner.show();
      let params = {
        filePath: filePath
      };
      this.onboardService.getPreviewEsign(params).subscribe(
        (data: any) => {
          if (data['success']) {
            this.setEsign(data.base64, fieldId);
          } else {
            //this.notiService.showError(data.error, '', 3000)
          }
          //this.spinner.hide();
        },
        error => {
          //this.spinner.hide();
        }
      );
    }
    public getcmecategory() {
      let params: {};
      //this.spinner.show();
      this.onboardService.getcmecategory(params).subscribe(
      data => {
      if (data['success']) {
      this.CMEDropdown = data.data.map(i => ({
      ...i,
      id: i.id.toString(),
      label:i.name
      }));
      } else {
      this.notiService.showError(data.error, '', 3000);
      }
      //this.spinner.hide();
      },
      error => {
      //this.spinner.hide();
      }
      );
      }
      public getAggregatesData() {
        let params: {};
        //this.spinner.show();
        this.onboardService.getAggregatesData(params).subscribe(
          data => {
            if (data['success']) {
              this.aggregates = data.data.map(i => ({
                ...i,
                id: i.id.toString()
              }));
            } else {
              this.notiService.showError(data.error, '', 3000);
            }
            //this.spinner.hide();
          },
          error => {
            //this.spinner.hide();
          }
        );
      }
      public getPreClaimData() {
        let params: {};
        //this.spinner.show();
        this.onboardService.getPreClaimData(params).subscribe(
          data => {
            if (data['success']) {
              this.preClaim = data.data.map(i => ({
                ...i,
                id: i.id.toString()
              }));
            } else {
              this.notiService.showError(data.error, '', 3000);
            }
            //this.spinner.hide();
          },
          error => {
            //this.spinner.hide();
          }
        );
      }
      clearInputIfInvalid(controlName: string,handleYesNoValue?:string) {
        const control = this.eyeForm.get(controlName);
        const dateString = control.value;
      
        // Use the dateFormatValidator to check if the date is valid
        const validationErrors = this.dateFormatValidator(new FormControl(dateString));
      
        if (validationErrors) {
          // If any of the validation conditions are met, clear the input field and set errors to null
          control.setValue('');
          control.setErrors(null);
        }
        if(handleYesNoValue ){
          this.handleNAchange(handleYesNoValue);
          
        }
    
        
      }
      clearInputIfInvalidMandi(controlName: string) {
        const control = this.eyeForm.get(controlName);
        const dateString = control.value;
      
        // Use the dateFormatValidator to check if the date is valid
        const validationErrors = this.dateFormatValidator(new FormControl(dateString));
      
        if (validationErrors) {
          // If any of the validation conditions are met, clear the input field and set errors to null
          control.setValue('');
    
        }}
        clearInputIfInvalidMonth(controlName: string,handleYesNoValue?:string) {
          const control = this.eyeForm.get(controlName);
          const dateString = control.value;
        
          // Use the dateFormatValidator to check if the date is valid
          const validationErrors = this.dateFormatValidatorMonth(new FormControl(dateString));
        
          if (validationErrors) {
            // If any of the validation conditions are met, clear the input field and set errors to null
            control.setValue('');
      
          }}
      dateFormatValidator(control: FormControl): ValidationErrors | null {
        const dateString = control.value;
        const dateFormat = /^\d{2}\/\d{2}\/\d{4}$/;
      
        if (dateString && !dateString.match(dateFormat)) {
          return { 'invalidDateFormat': true };
        }
      
    
    
        if (dateString) {
          const dateParts = dateString.split('/');
          const month = parseInt(dateParts[0]);
          const day = parseInt(dateParts[1]);
          const year = parseInt(dateParts[2]);
      
          // Check if any of the date components are zero or month is not between 1 and 12
          
      
          const ListofDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
          
          
          if (month < 1 || month > 12 || day === 0 || year === 0) {
            return { 'invalidMonth': true };
          }
          if (month === 1 || month > 2) {
            if (day > ListofDays[month - 1]) {
              return { 'invalidDateRange': true };
            }
          } else if (month === 2) {
            const leapYear = (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
            if (!leapYear && day >= 29) {
              return { 'invalidDateRange': true };
            }
             else if (leapYear && day > 29) {
              return { 'invalidDateRange': true };
            }
          }
          if (year < 1900 || year > 2099) {
            return { 'invalidDateyear': true };
          }
          
         
        }
      
        return null;
      }
      dateFormatValidatorMonth(control: FormControl): ValidationErrors | null {
        const dateString = control.value;
        const dateFormat = /^\d{2}\/\d{4}$/;
      
        if (dateString && !dateString.match(dateFormat)) {
          return { 'invalidDateFormat': true };
        }
      
    
    
        if (dateString) {
          const dateParts = dateString.split('/');
          const month = parseInt(dateParts[0]);
          const year = parseInt(dateParts[1]);
      
          // Check if any of the date components are zero or month is not between 1 and 12
                    
          
          if (month < 1 || month > 12 || year === 0) {
            return { 'invalidMonth': true };
          }
          if (year < 1900 || year > 2099) {
            return { 'invalidDateyear': true };
          }
          
         
        }
      
        return null;
      }
      handleNumber(event,name?) {
        if (event.code == 'Delete' || event.code == 'Backspace') {
        
        }
        else {
        let z = event.target.value;
        z = z.replace(/[^\d-]/g, '');
        if (z.length == 3) {
        z = z + '-'
        }
        else if (z.length == 7) {
        z = z + '-'
        }
        this.eyeForm.controls[name].setValue(z)
        }
        }
}