import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import {
  NgbDateAdapter,
  NgbDateParserFormatter,
  NgbModal,
  NgbModalOptions
} from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import {
  CustomAdapter,
  CustomDateParserFormatter
} from '../../DateFormatter/customDate';
import { ToasterNotiService } from '../../services/notifications/toaster-noti.service';
import { OnboardingformsServiceService } from '../../services/onboardingforms-service/onboardingforms-service.service';
import { StorageService } from '../../services/storage/storage.service';
import { EsignComponent } from '../esign/esign.component';

@Component({
  selector: 'ctg-affiliated-dermatology',
  templateUrl: './affiliated-dermatology.component.html',
  styleUrls: ['./affiliated-dermatology.component.scss'],
  providers: [
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }
  ]
})
export class AffiliatedDermatologyComponent implements OnInit {
  @Input() formData;
  @Input() fieldsData;
  @Input() formId;
  @Output() onSubmit = new EventEmitter();
  @Output() onClose = new EventEmitter();

  public userId: any;
  public customerId: any;
  public affiliatedDermatologyForm: UntypedFormGroup;
  public stateDropDownDate: any;

  showOne: boolean;
  showTwo: boolean;
  showThree: boolean;

  esignOne: any;
  esignTwo: any;
  esignThree: any;
  finalImgPathOne: any;
  finalImgOne: any;
  finalImgPathTwo: any;
  finalImgTwo: any;
  finalImgPathThree: any;
  finalImgThree: any;
  credentials: Array<object> = [];

  public locationList: any = [];
  public specialtySecondary: any = [];
  public specialty: any = [];
  public preClaim: any = [];
  public degree: any = [];
  public aggregates: any = [];
  public suffix: any = [];
  public boardCertification: any = [];
  public isSubmit: boolean = false;

  modalOption: NgbModalOptions = {
    size: 'md',
    keyboard: false,
    backdrop: 'static'
  };

  constructor(
    private fb: UntypedFormBuilder,
    private onboardService: OnboardingformsServiceService,
    private spinner: NgxSpinnerService,
    private notiService: ToasterNotiService,
    private modalService: NgbModal,
    private _sanitizer: DomSanitizer,
    private storageService: StorageService
  ) {}

  ngOnInit(): void {
    this.userId = Number(this.storageService.getItem('userId'));
    this.customerId = Number(this.storageService.getItem('customerId'));
    this.buildForm();
    this.getLocationList();
    this.getDegreeData();
    this.getStatesData();
    this.getSpecialtyData();
    this.getSpecialtySecondaryData();
    this.getBoardCertificationData();
    this.getSuffixData();
    this.getPreClaimData();
    this.getAggregatesData();
    this.getCredentialsData();
    this.fieldsData && this.bindFormValue(this.fieldsData);    
    this.afterSaveDataDisplay();
    
  }

  buildForm() {
    this.affiliatedDermatologyForm = this.fb.group({
      cmd_001417: [''],
      cmd_001418: [''],
      cmd_001419: [''],
      cmd_001420: [''],
      cmd_001421: [''],
      cmd_001422: [''],
      cmd_001423: [''],
      cmd_001424: [''],
      cmd_001434: [''],
      cmd_001425: [''],
      cmd_001426: [''],
      cmd_001427: [''],
      cmd_001428: [''],
      cmd_001429: [''],
      cmd_001430: [''],
      cmd_001431: [''],
      cmd_001432: [''],
      cmd_001433: [''],
      cmd_001435: [''],
      cmd_001436: [''],
      cmd_001437: [null, [this.dateFormatValidator]],
      9: ['', [Validators.required]],
      29: [null, [Validators.required]],
      8: ['', [Validators.required]],
      809: ['', [Validators.required]],
      822: [null, [Validators.required]],
      284: [null, [Validators.required]],
      962: [null, [Validators.required]],
      31: [null, [Validators.required,this.dateFormatValidator]],
      810: ['', [Validators.required]],
      289: [null, [Validators.required]],
      291: ['', [Validators.required]],
      292: ['', [Validators.required]],
      30: ['', [Validators.required]],
      278: ['', [Validators.required]],
      32: ['', [Validators.required]],
      759: ['', [Validators.required]],
      709: ['', [Validators.required]],
      1680: [''],
      33: [''],
      1634: ['', [Validators.required]],
      1490: [''],
      333: ['', [Validators.required]],
      334: ['', [Validators.required]],
      335: ['', [Validators.required]],
      1635: ['', [Validators.required]],
      1636: ['', [Validators.required]],
      cmd_100041: [null],
      16: [''],
      cmd_001690: [''],
      cmd_001695: [''],
      17: [''],
      cmd_001692: [''],
      cmd_001693: [null],
      cmd_001694: [''],
      18: [''],
      20: [null],
      21: [''],
      cmd_001691: [''],

      708: [''],
      cmd_001696: [''],
      710: [''],
      1664: [''],
      2682: [''],
      1017: [''],
      2631: [''],
      cmd_001438: ['', [Validators.required]],
      293: ['', [Validators.required]],
      294: [null, [Validators.required,this.dateFormatValidator]],
      295: [null, [Validators.required,this.dateFormatValidator]],
      cmd_001439: [''],
      300: [''],
      307: [null, [this.dateFormatValidator]],
      308: [null, [this.dateFormatValidator]],
      cmd_001440: [''],
      cmd_001441: [''],
      cmd_001442: [null, [this.dateFormatValidator]],
      cmd_001443: [null, [this.dateFormatValidator]],
      cmd_001444: [''],
      cmd_001445: [''],
      cmd_001446: [null, [this.dateFormatValidator]],
      cmd_001447: [null, [this.dateFormatValidator]],
      cmd_001448: [''],
      cmd_001449: [''],
      cmd_001450: [null, [this.dateFormatValidator]],
      cmd_001451: [null, [this.dateFormatValidator]],
      cmd_001452: ['', [Validators.required]],
      309: ['', [Validators.required]],
      310: [null, [Validators.required,this.dateFormatValidator]],
      311: [null, [Validators.required,this.dateFormatValidator]],
      cmd_001453: ['', [Validators.required]],
      337: ['', [Validators.required]],
      338: [null, [Validators.required,this.dateFormatValidator]],
      339: [null, [Validators.required,this.dateFormatValidator]],
      cmd_001706: [''],
      cmd_001697: [''],
      cmd_001698: [null, [this.dateFormatValidator]],
      cmd_001699: [null, [this.dateFormatValidator]],
      cmd_001646: [''],
      cmd_001700: [''],
      cmd_001701: [null, [this.dateFormatValidator]],
      cmd_001702: [null, [this.dateFormatValidator]],
      cmd_001647: [''],
      cmd_001703: [''],
      cmd_001704: [null, [this.dateFormatValidator]],
      cmd_001705: [null, [this.dateFormatValidator]],
      cmd_001645: [''],
      514: [''],
      515: [null, [this.dateFormatValidator]],
      cmd_001648: ['', [Validators.required]],
      1375: [null, [Validators.required]],
      325: ['', [Validators.required]],
      1924: ['', [Validators.required,this.dateFormatValidator]],
      1925: ['', [Validators.required,this.dateFormatValidator]],
      cmd_001649: [''],
      cmd_001650: [''],
      cmd_001651: [''],
      cmd_001652: [null, [this.dateFormatValidator]],
      cmd_001653: [null, [this.dateFormatValidator]],
      cmd_001654: [''],
      cmd_001655: [''],
      cmd_001656: [''],
      cmd_001657: [null, [this.dateFormatValidator]],
      cmd_001658: [null, [this.dateFormatValidator]],
      cmd_001659: [''],
      cmd_001660: [''],
      cmd_001661: [''],
      cmd_001662: [null, [this.dateFormatValidator]],
      cmd_001663: [null, [this.dateFormatValidator]],
      cmd_001664: [''],
      cmd_001665: [''],
      cmd_001666: [''],
      cmd_001667: [null, [this.dateFormatValidator]],
      cmd_001668: [null, [this.dateFormatValidator]],
      cmd_001669: [''],
      cmd_001670: [''],
      cmd_001671: [''],
      cmd_001672: [null, [this.dateFormatValidator]],
      cmd_001673: [null, [this.dateFormatValidator]],
      cmd_001674: [''],
      cmd_001675: [''],
      cmd_001676: [''],
      cmd_001677: [null, [this.dateFormatValidator]],
      cmd_001678: [null, [this.dateFormatValidator]],
      cmd_001679: [''],
      cmd_001680: [''],
      cmd_001681: [''],
      cmd_001682: [null, [this.dateFormatValidator]],
      cmd_001683: [null, [this.dateFormatValidator]],
      cmd_001684: [''],
      cmd_001685: [''],
      cmd_001686: [''],
      cmd_001687: [null, [this.dateFormatValidator]],
      cmd_001688: [null, [this.dateFormatValidator]],
      cmd_001454: [null],
      cmd_001455: [''],
      cmd_001456: [''],
      cmd_001457: [''],
      cmd_001458: [''],
      cmd_001459: [''],
      cmd_001460: [''],
      cmd_001461: [''],
      cmd_001462: [''],
      cmd_001463: [''],
      cmd_001464: [''],
      cmd_001465: [''],
      cmd_001466: [''],
      cmd_001467: [''],
      cmd_001468: [''],
      cmd_001469: [''],
      cmd_001470: [''],
      cmd_001471: [''],
      cmd_001472: [''],
      cmd_001473: [''],
      cmd_001474: [''],
      554: [null],
      1169: [''],
      557: [null, [this.dateFormatValidator]],
      558: [null, [this.dateFormatValidator]],
      560: [null, [this.dateFormatValidator]],
      561: [null],
      562: [''],
      564: [null, [this.dateFormatValidator]],
      565: [null, [this.dateFormatValidator]],
      567: [null, [this.dateFormatValidator]],
      cmd_001475: [''],
      cmd_001477: [''],
      cmd_001476: [''],
      503: [''],
      510: [null, [this.dateFormatValidator]],
      1238: [null, [this.dateFormatValidator]],
      504: [''],
      505: [''],
      506: [''],
      508: [null],
      509: [null],
      2345: [''],
      cmd_001478: [''],
      512: [null],
      490: [''],
      497: [null, [this.dateFormatValidator]],
      498: [null, [this.dateFormatValidator]],
      491: [''],
      492: [''],
      493: [''],
      495: [null],
      496: [''],
      2351: [''],
      cmd_001479: [''],
      500: [null],
      517: [''],
      524: [null, [this.dateFormatValidator]],
      525: [null, [this.dateFormatValidator]],
      518: [''],
      519: [''],
      520: [''],
      522: [null],
      523: [''],
      cmd_001480: [''],
      cmd_001481: [''],
      527: [null],
      528: [''],
      cmd_001482: [''],
      1318: [''],
      529: [''],
      537: [null, [this.dateFormatValidator]],
      538: [null, [this.dateFormatValidator]],
      531: [''],
      1498: [''],
      533: [''],
      535: [null],
      536: [''],
      cmd_001483: [''],
      cmd_001484: [''],
      530: [null],
      540: [''],
      cmd_001485: [''],
      1316: [''],
      542: [''],
      550: [null, [this.dateFormatValidator]],
      551: [null, [this.dateFormatValidator]],
      544: [''],
      545: [''],
      546: [''],
      548: [null],
      549: [null],
      cmd_001486: [''],
      cmd_001487: [''],
      543: [null],
      553: [''],
      cmd_001488: [''],
      1322: [''],
      cmd_001489: [''],
      cmd_001490: [''],
      cmd_001491: [''],
      cmd_001492: [''],
      cmd_001493: [''],
      cmd_001494: [''],
      cmd_001495: [''],
      cmd_001496: [''],
      cmd_001497: [''],
      cmd_001498: [''],
      826: [null, [this.dateFormatValidator]],
      cmd_001499: [''],
      cmd_001500: [''],
      cmd_001501: [''],
      cmd_001502: [''],
      cmd_001503: [''],
      cmd_001504: [''],
      cmd_001505: [''],
      cmd_001506: [''],
      cmd_001507: [''],
      cmd_001508: [''],
      cmd_001509: [''],
      cmd_001510: [''],
      cmd_001511: [''],
      cmd_001512: [''],
      cmd_001513: [''],
      cmd_001514: [''],
      cmd_001515: [''],
      418: [''],
      1305: [''],
      2136: [''],
      362: [''],
      421: [''],
      419: [''],
      423: [null],
      420: [''],
      424: [''],
      426: [null, [this.dateFormatValidator]],
      1370: [null, [this.dateFormatValidator]],
      427: [''],
      1307: [''],
      2137: [''],
      430: [''],
      428: [''],
      432: [null],
      429: [''],
      433: [''],
      435: [null, [this.dateFormatValidator]],
      1371: [null, [this.dateFormatValidator]],
      436: [''],
      1309: [''],
      2138: [''],
      439: [''],
      437: [''],
      441: [null],
      438: [''],
      442: [''],
      444: [null, [this.dateFormatValidator]],
      1372: [null, [this.dateFormatValidator]],
      cmd_001516: [''],
      cmd_001517: [''],
      cmd_001518: [''],
      cmd_001519: [''],
      cmd_001520: [''],
      cmd_001521: [null],
      cmd_001522: [''],
      cmd_001523: [''],
      cmd_001524: [null, [this.dateFormatValidator]],
      cmd_001525: [null, [this.dateFormatValidator]],
      cmd_001526: [''],
      cmd_001527: [''],
      cmd_001528: [''],
      cmd_001529: [''],
      cmd_001530: [''],
      cmd_001531: [null],
      cmd_001532: [''],
      cmd_001533: [''],
      cmd_001534: [null, [this.dateFormatValidator]],
      cmd_001535: [null, [this.dateFormatValidator]],
      cmd_001536: [''],
      cmd_001537: [''],
      cmd_001538: [''],
      cmd_001539: [''],
      cmd_001540: [''],
      cmd_001541: [null],
      cmd_001542: [''],
      cmd_001543: [''],
      cmd_001544: [null, [this.dateFormatValidator]],
      cmd_001545: [null, [this.dateFormatValidator]],
      cmd_001546: [''],
      cmd_001547: [''],
      cmd_001548: [''],
      cmd_001549: [''],
      cmd_001550: [''],
      cmd_001551: [null],
      cmd_001552: [''],
      cmd_001553: [''],
      cmd_001554: [null, [this.dateFormatValidator]],
      cmd_001555: [null, [this.dateFormatValidator]],
      445: [''],
      453: [''],
      888: [''],
      448: [''],
      446: [''],
      450: [null],
      447: [''],
      451: [''],
      454: [null, [this.dateFormatValidator]],
      455: [null, [this.dateFormatValidator]],
      456: [''],
      464: [''],
      890: [''],
      459: [''],
      457: [null],
      461: [null],
      458: [''],
      462: [''],
      465: [null, [this.dateFormatValidator]],
      466: [null, [this.dateFormatValidator]],
      467: [''],
      475: [''],
      892: [''],
      470: [''],
      468: [''],
      472: [null],
      469: [''],
      473: [''],
      476: [null, [this.dateFormatValidator]],
      477: [null, [this.dateFormatValidator]],
      1935: [''],
      1944: [''],
      1945: [''],
      1938: [''],
      1936: [''],
      1940: [null],
      1937: [''],
      1941: [''],
      1947: [null, [this.dateFormatValidator]],
      1948: [null, [this.dateFormatValidator]],
      cmd_001556: [''],
      cmd_001557: [''],
      cmd_001558: [''],
      cmd_001559: [''],
      cmd_001560: [''],
      cmd_001561: [null],
      cmd_001562: [''],
      cmd_001563: [''],
      cmd_001564: [null , [this.dateFormatValidator]],
      cmd_001565: [null, [this.dateFormatValidator]],
      3395: [null, [this.dateFormatValidator]],
      3396: [null, [this.dateFormatValidator]],
      1138: [''],
      3397: [null, [this.dateFormatValidator]],
      3398: [null, [this.dateFormatValidator]],
      1831: [''],
      3399: [null, [this.dateFormatValidator]],
      3400: [null, [this.dateFormatValidator]],
      1832: [''],
      3401: [null, [this.dateFormatValidator]],
      3402: [null, [this.dateFormatValidator]],
      1953: [null],
      359: [''],
      368: [null],
      360: [''],
      364: [null],
      361: [''],
      365: [''],
      369: [''],
      371: [''],
      370: [''],
      372: [null],
      380: [null],
      373: [''],
      375: [''],
      377: [null],
      374: [''],
      378: [''],
      381: [''],
      383: [''],
      382: [''],
      384: [''],
      393: [null],
      385: [''],
      387: [''],
      389: [null],
      386: [''],
      390: [''],
      394: [''],
      396: [''],
      395: [''],
      1681: [''],
      1691: [null],
      1682: [''],
      1684: [''],
      1686: [null],
      1683: [''],
      1687: [''],
      1692: [''],
      1694: [''],
      1693: [''],
      345: [''],
      346: [''],
      901: [''],
      902: [''],
      903: [''],
      905: [null],
      906: [''],
      1240: [''],
      1266: [''],
      1273: [''],
      1639: [''],
      cmd_001579: [''],
      cmd_001578: [''],
      350: [null],
      351: [null],
      cmd_001580: [''],
      348: [null, [this.dateFormatValidator]],
      349: [null, [this.dateFormatValidator]],
      945: [null, [this.dateFormatValidator]],
      1242: [''],
      1256: [''],
      1244: [''],
      1245: [''],
      1246: [''],
      1248: [null],
      1249: [null],
      1243: [''],
      1254: [''],
      1255: [''],
      1857: [''],
      cmd_001581: [''],
      cmd_001582: [''],
      1264: [null],
      1265: [null],
      cmd_001583: [''],
      1252: [null, [this.dateFormatValidator]],
      1253: [null, [this.dateFormatValidator]],
      1251: [null, [this.dateFormatValidator]],
      cmd_001584: [''],
      cmd_001585: [''],
      cmd_001586: [''],
      cmd_001587: [''],
      cmd_001588: [''],
      cmd_001589: [null],
      cmd_001590: [''],
      cmd_001591: [''],
      cmd_001592: [''],
      cmd_001593: [''],
      cmd_001594: [''],
      cmd_001595: [''],
      cmd_001596: [''],
      cmd_001597: [null],
      cmd_001598: [null],
      cmd_001599: [''],
      cmd_001600: [null, [this.dateFormatValidator]],
      cmd_001601: [null, [this.dateFormatValidator]],
      cmd_001602: [null, [this.dateFormatValidator]],
      cmd_001603: [''],
      cmd_001604: [''],
      cmd_001605: [''],
      cmd_001606: [''],
      cmd_001607: [''],
      cmd_001608: [null],
      cmd_001609: [''],
      cmd_001610: [''],
      cmd_001611: [''],
      cmd_001612: [''],
      cmd_001613: [''],
      cmd_001614: [''],
      cmd_001615: [''],
      cmd_001616: [null],
      cmd_001617: [null],
      cmd_001618: [''],
      cmd_001619: [null , [this.dateFormatValidator]],
      cmd_001620: [null, [this.dateFormatValidator]],
      cmd_001621: [null, [this.dateFormatValidator]],
      cmd_001622: [''],
      cmd_001623: [''],
      cmd_001624: [''],
      cmd_001625: [''],
      cmd_001626: [''],
      cmd_001627: [''],
      cmd_001628: [''],
      cmd_001629: [''],
      cmd_001630: [''],
      cmd_001631: [''],
      cmd_001632: [''],
      cmd_001633: [''],
      cmd_001634: [''],
      cmd_001635: [''],
      cmd_001636: [''],
      1141: [''],
      1144: [null, [this.dateFormatValidator]],
      1145: [null, [this.dateFormatValidator]],
      4152: [''],
      cmd_001639: [''],
      cmd_001640: [null, [this.dateFormatValidator]],
      cmd_001642: [null],
      cmd_001643: [null],
      cmd_001644: [null, [this.dateFormatValidator]],
      cmd_001641: [null]
    });
  }

  public afterSaveDataDisplay() {
    let params = {
      type: 29,
      formId: this.formId
    };
    //this.spinner.show();
    this.onboardService.toGetSaveData(params).subscribe(
      data => {
        if (data['success']) {
          this.bindFormValue(data.data);
        } else {
          //this.notiService.showError(data.error, '', 3000)
        }
        //this.spinner.hide();
      },
      error => {
        //this.notiService.showError(Error_Message, '', 3000)
      }
    );
  }

  public bindFormValue(array) {
    let fieldsCtrls = {};
    for (let i = 0; i < array.length; i++) {
      fieldsCtrls[array[i].field_name] = array[i].field_value;
      if (array[i].field_name == 'cmd_001436') {
        if (array[i].field_value != '') {
          this.previewEsign(array[i].field_value, array[i].field_name);
        }
      }
      if (array[i].field_name == 'cmd_001639') {
        if (array[i].field_value != '') {
          this.previewEsign(array[i].field_value, array[i].field_name);
        }
      }
      if (array[i].field_name == 'cmd_001641') {
        if (array[i].field_value != '') {
          this.previewEsign(array[i].field_value, array[i].field_name);
        }
      }
    }
    this.affiliatedDermatologyForm.patchValue(fieldsCtrls);
  }

  public previewEsign(filePath, fieldId) {
    //this.spinner.show();
    let params = {
      filePath: filePath
    };
    this.onboardService.getPreviewEsign(params).subscribe(
      (data: any) => {
        if (data['success']) {
          let ImgOne = data.base64;
          this.setEsign(ImgOne, fieldId);
        } else {
          // this.notiService.showError(data.error, '', 3000)
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getCredentialsData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getCredentialsData(params).subscribe(
      data => {
        if (data['success']) {
          this.credentials = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public setEsign(filePath, fieldId) {
    // console.log(filePath);
    if (fieldId == 'cmd_001436') {
      this.esignOne = this._sanitizer.bypassSecurityTrustResourceUrl(
        'data:image/jpg;base64,' + filePath
      );
      this.showOne = true;
    }
    if (fieldId == 'cmd_001639') {
      this.esignTwo = this._sanitizer.bypassSecurityTrustResourceUrl(
        'data:image/jpg;base64,' + filePath
      );
      this.showTwo = true;
    }
    if (fieldId == 'cmd_001641') {
      this.esignThree = this._sanitizer.bypassSecurityTrustResourceUrl(
        'data:image/jpg;base64,' + filePath
      );
      this.showThree = true;
    }
  }

  public getSuffixData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getSuffixData(params).subscribe(
      data => {
        if (data['success']) {
          this.suffix = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getAggregatesData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getAggregatesData(params).subscribe(
      data => {
        if (data['success']) {
          this.aggregates = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getDegreeData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getDegreeData(params).subscribe(
      data => {
        if (data['success']) {
          this.degree = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getPreClaimData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getPreClaimData(params).subscribe(
      data => {
        if (data['success']) {
          this.preClaim = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getStatesData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getStatesData(params).subscribe(
      data => {
        if (data['success']) {
          this.stateDropDownDate = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getSpecialtyData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getSpecialtyData(params).subscribe(
      data => {
        if (data['success']) {
          this.specialty = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getSpecialtySecondaryData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getSpecialtySecondaryData(params).subscribe(
      data => {
        if (data['success']) {
          this.specialtySecondary = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  getLocationList() {
    let params: {};
    this.spinner.show();
    this.onboardService.geterielocations(params).subscribe(
      data => {
        if (data['success']) {
          this.locationList = data.data.map(i => ({
            ...i,
            location_id: i.location_id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        this.spinner.hide();
      },
      error => {
        this.spinner.hide();
      }
    );
  }

  onChangeLocation1(locationId) {
    let params = {
      locationId: locationId
    };

    this.onboardService.getlocationdetails(params).subscribe(
      data => {
        if (data['success']) {
          let locationdata = data.data[0];
          this.affiliatedDermatologyForm.patchValue({
            cmd_001690: locationdata.practice__add1,
            cmd_001695: locationdata.practice__add2,
            cmd_001692: locationdata.practice__city,
            cmd_001694: locationdata.practice__zip,
            cmd_001691: locationdata.phone,
            cmd_001696: locationdata.fax
          });
        } else {
          //this.notiService.showError(data.error, '', 3000)
        }
      },
      error => {}
    );
  }

  public getBoardCertificationData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getBoardCertificationData(params).subscribe(
      data => {
        if (data['success']) {
          this.boardCertification = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  onChangeDropDownName(event) {
    //cmd_110215
    this.affiliatedDermatologyForm.controls['cmd_001643'].setValue(event.id);
  }

  public esignOpenOne() {
    const modalRef = this.modalService.open(EsignComponent, this.modalOption);
    modalRef.result.then(result => {});
    modalRef.componentInstance.base64Img.subscribe(data => {
      this.finalImgPathOne = data.finalFilePath;
      modalRef.close();
      let temp = {
        cmd_001436: this.finalImgPathOne
      };
      this.affiliatedDermatologyForm.patchValue(temp);
      this.finalImgOne = data.base64Img;
      this.esignOne = this._sanitizer.bypassSecurityTrustResourceUrl(
        'data:image/jpg;base64,' + this.finalImgOne
      );
      this.showOne = true;
    });
  }

  public esignOpenTwo() {
    const modalRef = this.modalService.open(EsignComponent, this.modalOption);
    modalRef.result.then(result => {});
    modalRef.componentInstance.base64Img.subscribe(data => {
      modalRef.close();
      this.finalImgPathTwo = data.finalFilePath;
      let temp = {
        cmd_001639: this.finalImgPathTwo
      };
      this.affiliatedDermatologyForm.patchValue(temp);
      this.finalImgPathTwo = data.finalFilePath;
      this.finalImgTwo = data.base64Img;
      this.esignTwo = this._sanitizer.bypassSecurityTrustResourceUrl(
        'data:image/jpg;base64,' + this.finalImgTwo
      );
      this.showTwo = true;
    });
  }

  public esignOpenThree() {
    const modalRef = this.modalService.open(EsignComponent, this.modalOption);
    modalRef.result.then(result => {});
    modalRef.componentInstance.base64Img.subscribe(data => {
      modalRef.close();
      this.finalImgPathThree = data.finalFilePath;
      let temp = {
        cmd_001641: this.finalImgPathThree
      };
      this.affiliatedDermatologyForm.patchValue(temp);
      this.finalImgPathThree = data.finalFilePath;
      this.finalImgThree = data.base64Img;
      this.esignThree = this._sanitizer.bypassSecurityTrustResourceUrl(
        'data:image/jpg;base64,' + this.finalImgThree
      );
      this.showThree = true;
    });
  }

  saveForm(type) {
    this.isSubmit = true;
    // console.log(this.affiliatedDermatologyForm);
    if (type == 'submit') {
      if (this.affiliatedDermatologyForm.invalid) {
        this.notiService.showError(
          'Please Fill the all mandatory fields to continue',
          '',
          3000
        );
        let invalid = [];
        let controls = this.affiliatedDermatologyForm.controls;
        for (const name in controls) {
          if (controls[name].invalid) {
            invalid.push(name);
          }
        }
        // console.log(invalid);
        return invalid;
      } else {
        let data = {
          formType: type,
          formValues: this.affiliatedDermatologyForm.value
        };
        this.onSubmit.emit(data);
      }
    } else {
      let data = {
        formType: type,
        formValues: this.affiliatedDermatologyForm.value,
        formId: 29
      };
      this.onSubmit.emit(data);
      //this.onClose.emit('close modal');
    }
  }

  close() {
    this.onClose.emit('close modal');
  }

  firstNameMap(value) {
    this.affiliatedDermatologyForm.patchValue({
      cmd_001435:
        value +
        ' ' +
        this.affiliatedDermatologyForm.controls['8'].value +
        ' ' +
        this.affiliatedDermatologyForm.controls['809'].value,
      cmd_001642:
        value +
        ' ' +
        this.affiliatedDermatologyForm.controls['8'].value +
        ' ' +
        this.affiliatedDermatologyForm.controls['809'].value
    });
  }

  middleNameMap(value) {
    this.affiliatedDermatologyForm.patchValue({
      cmd_001435:
        this.affiliatedDermatologyForm.controls['9'].value +
        ' ' +
        value +
        ' ' +
        this.affiliatedDermatologyForm.controls['809'].value,
      cmd_001642:
        this.affiliatedDermatologyForm.controls['9'].value +
        ' ' +
        value +
        ' ' +
        this.affiliatedDermatologyForm.controls['809'].value
    });
  }
  lastNameMap(value) {
    this.affiliatedDermatologyForm.patchValue({
      cmd_001435:
        this.affiliatedDermatologyForm.controls['9'].value +
        ' ' +
        this.affiliatedDermatologyForm.controls['8'].value +
        ' ' +
        value,
      cmd_001642:
        this.affiliatedDermatologyForm.controls['9'].value +
        ' ' +
        this.affiliatedDermatologyForm.controls['8'].value +
        ' ' +
        value
    });
  }
  clearInputIfInvalid(controlName: string,handleYesNoValue?:string) {
    const control = this.affiliatedDermatologyForm.get(controlName);
    const dateString = control.value;
  
    // Use the dateFormatValidator to check if the date is valid
    const validationErrors = this.dateFormatValidator(new FormControl(dateString));
  
    if (validationErrors) {
      // If any of the validation conditions are met, clear the input field and set errors to null
      control.setValue('');
      control.setErrors(null);
    }
    
    
  }
  clearInputIfInvalidMandi(controlName: string) {
    const control = this.affiliatedDermatologyForm.get(controlName);
    const dateString = control.value;
  
    // Use the dateFormatValidator to check if the date is valid
    const validationErrors = this.dateFormatValidator(new FormControl(dateString));
  
    if (validationErrors) {
      // If any of the validation conditions are met, clear the input field and set errors to null
      control.setValue('');

    }}

  dateFormatValidator(control: FormControl): ValidationErrors | null {
    const dateString = control.value;
    const dateFormat = /^\d{2}\/\d{2}\/\d{4}$/;
  
    if (dateString && !dateString.match(dateFormat)) {
      return { 'invalidDateFormat': true };
    }
  


    if (dateString) {
      const dateParts = dateString.split('/');
      const month = parseInt(dateParts[0]);
      const day = parseInt(dateParts[1]);
      const year = parseInt(dateParts[2]);
  
      // Check if any of the date components are zero or month is not between 1 and 12
      
  
      const ListofDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
      
      
      if (month < 1 || month > 12 || day === 0 || year === 0) {
        return { 'invalidMonth': true };
      }
      if (month === 1 || month > 2) {
        if (day > ListofDays[month - 1]) {
          return { 'invalidDateRange': true };
        }
      } else if (month === 2) {
        const leapYear = (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
        if (!leapYear && day >= 29) {
          return { 'invalidDateRange': true };
        }
         else if (leapYear && day > 29) {
          return { 'invalidDateRange': true };
        }
      }
      if (year < 1900 || year > 2099) {
        return { 'invalidDateyear': true };
      }
      
     
    }
  
    return null;
  }
}
