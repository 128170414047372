import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Error_Message } from '../common/common.service';


@Injectable({
  providedIn: 'root'
})
export class ToasterNotiService {

  constructor(private toast: ToastrService) { }

  public showSuccess(message, title='', duration=4000) {
    this.toast.success(message, title, {
      closeButton: true,
      timeOut: duration,
      progressBar: true,
      positionClass: 'toast-top-right'
    });
  }
  public showError(message=Error_Message, title='', duration=3000) {
    this.toast.error(message, title, {
      closeButton: true,
      timeOut: duration,
      progressBar: true,
      positionClass: 'toast-top-right'
    });
  }
  public showWarning(message, title='', duration=3000) {
    this.toast.warning(message, title, {
      closeButton: true,
      timeOut: duration,
      progressBar: true,
      positionClass: 'toast-top-right'
    });
  }

}
