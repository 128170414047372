import { Injectable } from '@angular/core';
import { ApiServiceService } from '../../../../shared/services/cmd-api-service/api-service.service';

@Injectable({
  providedIn: 'root'
})
export class PortalReportService {

  constructor(private apiService: ApiServiceService) { }

  public getPortalForms(postParams) {
    return this.apiService.post('forms/portalformslist', postParams);
  }

  
}
