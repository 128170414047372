<div id="newallied">
    <form [formGroup]="NewManAlliedForms">
        <div class="text-center">
            <h2>NEWMAN REGIONAL HEALTH</h2>
        </div> 
        <div class="text-center">
            <h2 >APPLICATION FOR ALLIED HEALTH PERSONNEL</h2>
        </div>  
        <br>
        <br>
        
        <div class="text-center">
            <h5 >CURRENT OFFICE / PERSONAL INFORMATION</h5>
        </div>
         <table class="table tabwidth" >
            <tbody>
                <tr>
                    <td>
                        <div class="row">
                            <div class="col-md-2">
                                <label style="font-weight: normal;font-size: 14px">Name in full</label>
                            </div> 
                            <div class="col-md-3"> 
                                <input class="form-control onboard"  formControlName="9"
                                    id="9" name="9" type="text" placeholder= "Last Name" [class.is-invalid]="(NewManAlliedForms.controls['9'].touched && NewManAlliedForms.controls['9'].invalid) || (NewManAlliedForms.controls['9'].invalid && isSubmit)" data-id="managing_contact_name" >
                                <div class="formLabel col-md-11"
                                    *ngIf="NewManAlliedForms.controls['9'].touched && NewManAlliedForms.controls['9'].invalid">
                                    <small class="text-danger" *ngIf="NewManAlliedForms.controls['9'].errors?.required">
                                        required
                                    </small>
                                </div> 
                            </div>
                            <div class="col-md-3"> 
                                <input class="form-control onboard"  formControlName="8"
                                    id="8" name="8" type="text" placeholder= "First Name" data-id="managing_contact_name" [class.is-invalid]="(NewManAlliedForms.controls['8'].touched && NewManAlliedForms.controls['8'].invalid) || (NewManAlliedForms.controls['8'].invalid && isSubmit)" >
                                <div class="formLabel col-md-11"
                                    *ngIf="NewManAlliedForms.controls['8'].touched && NewManAlliedForms.controls['8'].invalid">
                                    <small class="text-danger" *ngIf="NewManAlliedForms.controls['8'].errors?.required">
                                        required
                                    </small>
                                </div>
                            </div>  
                            <div class="col-md-3"> 
                                <input class="form-control onboard"  formControlName="809"
                                    id="809" name="809" type="text" placeholder= "Middle Name" data-id="managing_contact_name"  >
                                
                            </div>   
                        </div>
                    </td> 
                </tr>
                <tr>
                    <td>
                        <div class="row">
                            <div class="col-md-2">
                                <label style="font-weight: normal;font-size: 14px">Office Address</label>
                            </div> 
                            <div class="col-md-2"> 
                                <input class="form-control onboard"  formControlName="cmd_001"
                                    id="cmd_001" name="cmd_001" type="text" placeholder= "Add1"   data-id="managing_contact_name" >
                                
                            </div>  
                            
                            <div class="col-md-2"> 
                                <input class="form-control onboard"  formControlName="cmd_002"
                                    id="cmd_002" name="cmd_002" type="text" placeholder= "Add2" data-id="managing_contact_name"   >
                                
                            </div>  
                            <div class="col-md-2"> 
                                <input class="form-control onboard"  formControlName="cmd_003"
                                    id="cmd_003" name="cmd_003" type="text" placeholder= "City" data-id="managing_contact_name"  >
                                
                            </div>  
                            <div class="col-2 pl-0">
                                <ng-select
                                    [items]="states"
                                    bindLabel="name"
                                    placeholder="Enter State"
                                    bindValue="id"
                                    formControlName="cmd_004"
                                    
                                    >
                                </ng-select> 
                                
                            </div>  
                            <div class="col-md-2"> 
                                <input class="form-control onboard"  formControlName="cmd_005"
                                    id="cmd_005" name="cmd_005" type="text" placeholder= "Zip"  data-id="managing_contact_name" >
                                  
                            </div> <br><br>
                            <div class="col-md-2">
                                <label style="font-weight: normal;font-size: 14px">Tel #</label>
                            </div> 
                            <div class="col-md-5"> 
                                <input class="form-control onboard"  formControlName="cmd_006"
                                    id="cmd_006" name="cmd_006" type="text" placeholder= "Tel"   data-id="managing_contact_name" >
                                
                            </div>  
                              
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div class="row">
                            <div class="col-md-2">
                                <label style="font-weight: normal;font-size: 14px">Home Address</label>
                            </div> 
                            <div class="col-md-2"> 
                                <input class="form-control onboard"  formControlName="16" 
                                    id="16" name="16" type="text" placeholder= "Home Address 1"   data-id="managing_contact_name" >
                                
                            </div>  
                            
                            <div class="col-md-2"> 
                                <input class="form-control onboard"  formControlName="17"
                                    id="17" name="17" type="text" placeholder= "Home Address 2"  data-id="managing_contact_name"  >
                               
                            </div>  
                            <div class="col-md-2"> 
                                <input class="form-control onboard"  formControlName="18"
                                    id="18" name="18" type="text" placeholder= "Home City"  data-id="managing_contact_name" >
                                
                            </div>  
                            <div class="col-md-2">
                                <ng-select
                                    [items]="states"
                                    bindLabel="name"
                                    placeholder="Enter State"
                                    bindValue="id"
                                    formControlName="20"
                                    
                                    >
                                </ng-select> 
                                
                            </div>  
                            <div class="col-md-2"> 
                                <input class="form-control onboard"  formControlName="21"
                                    id="21" name="21" type="text" placeholder= "Home Zip"  data-id="managing_contact_name" >
                                
                            </div> <br><br>
                            <div class="col-md-2">
                                <label style="font-weight: normal;font-size: 14px">Tel #</label>
                            </div> 
                            <div class="col-md-5"> 
                                <input class="form-control onboard"  formControlName="708"
                                    id="708" name="708" type="text" placeholder= "Home Phone"   data-id="managing_contact_name" >
                                
                            </div>  
                              
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div class="row">
                            <div class="col-md-2">
                                <label style="font-weight: normal;font-size: 14px">E-Mail Address</label> 
                            </div>
                            <div class="col-md-10">
                                <input class="form-control onboard"  formControlName="759"
                                    id="759" name="759" type="text" placeholder= "Email"   data-id="managing_contact_name" >
                                
                            </div> 
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div class="row">
                            <div class="col-md-2">
                                <label style="font-weight: normal;font-size: 14px">Sex</label> 
                            </div>
                            <div class="col-4">
                                <ng-select
                                [items]="gender"
                                bindLabel="name"
                                placeholder="Select Gender"
                                bindValue="id"
                                formControlName="30"
                                >
                                </ng-select>
                               
                            </div>
                            <div class="col-2">
                                <label style="font-weight: normal;font-size: 14px">Date of Birth</label> 
                            </div>
                            <div class="col-4">
                                <div class="input-group">
                                    <input type="text" placeholder="DOB" ngbDatepicker #d31="ngbDatepicker" id="31" (blur)="clearInputIfInvalid('31')"  class="form-control" formControlName="31">
                                        <div class="input-group-append">
                                            <button class="btn btn-outline-secondary calendar" (click)="d31.toggle()" type="button">
                                            <i class="fas fa-calendar-alt"></i>
                                            </button>
                                        </div>
                                </div>
                                <div  *ngIf="NewManAlliedForms.get('31').invalid ">
                                    <small class="text-danger" *ngIf="NewManAlliedForms.get('31').errors?.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="NewManAlliedForms.get('31').errors?.invalidMonth">
                                    Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="NewManAlliedForms.get('31').errors?.invalidDateyear">
                                      year is out of range(1900-2099)                      </small>
                                      <small class="text-danger" *ngIf="NewManAlliedForms.get('31').errors?.invalidDateRange">
                                        date is out of range
                                        </small>
                                    </div>
                            </div>    
                        </div>    
                    </td>
                </tr>
                <tr>
                    <td>
                        <div class="row">
                            <div class="col-md-2">
                                <label style="font-weight: normal;font-size: 14px">Social Security No.</label> 
                            </div>
                            <div class="col-md-4">
                                <input class="form-control onboard"  formControlName="32"
                                    id="32" name="32" type="text" placeholder= "SSN" [class.is-invalid]="(NewManAlliedForms.controls['32'].touched && NewManAlliedForms.controls['32'].invalid) || (NewManAlliedForms.controls['32'].invalid && isSubmit)"  data-id="managing_contact_name" >
                                <div class="formLabel col-md-11"
                                    *ngIf="NewManAlliedForms.controls['32'].touched && NewManAlliedForms.controls['32'].invalid">
                                    <small class="text-danger" *ngIf="NewManAlliedForms.controls['32'].errors?.required">
                                        required
                                    </small>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <label style="font-weight: normal;font-size: 14px">NPI Number (if applicable)</label> 
                            </div>
                            <div class="col-md-4">
                                <input class="form-control onboard"  formControlName="1634"
                                    id="1634" name="1634" type="text" placeholder= "NPI"   data-id="managing_contact_name" >
                                
                            </div> 
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div class="row">
                            <div class="col-md-2">
                                <label style="font-weight: normal;font-size: 14px">Organization you are affiliated with:</label> 
                            </div>
                            <div class="col-md-10">
                                <input class="form-control onboard"  formControlName="cmd_007"
                                    id="cmd_007" name="cmd_007" type="text" placeholder= "Organization"   data-id="managing_contact_name" >
                                
                            </div> 
                        </div>
                    </td>
                </tr>
                <tr>
                    <th>
                        <div class="text-center">
                            <b id="subhead">EDUCATION</b>
                        </div>
                    </th>
                </tr>
                <tr>
                    <td>
                        <div class="row">
                            <div class="col-md-2">
                                <label style="font-weight: normal;font-size: 14px">College or University</label> 
                            </div> 
                            <div class="col-md-6">
                                <input class="form-control onboard"  formControlName="503"
                                    id="503" name="503" type="text" placeholder= "UG School"   data-id="managing_contact_name" >
                                
                            </div> 
                            
                        </div>    
                    </td>
                
                </tr>   
                <tr>
                    <td>
                        <div class="row">
                            <div class="col-md-2">
                                <label style="font-weight: normal;font-size: 14px">Address</label> 
                            </div> 
                            <div class="col-md-2">
                                <input class="form-control onboard"  formControlName="504"
                                    id="504" name="504" type="text" placeholder= "UG School Add1"   data-id="managing_contact_name" >
                                 
                            </div> 
                            <div class="col-md-2">
                                <input class="form-control onboard"  formControlName="505"
                                    id="505" name="505" type="text" placeholder= "UG School Add2"   data-id="managing_contact_name" >
                               
                            </div>  
                            <div class="col-md-2">
                                <input class="form-control onboard"  formControlName="506"
                                    id="506" name="506" type="text" placeholder= "UG School City"   data-id="managing_contact_name" >
                                
                            </div>   
                            <div class="col-md-2">
                                <ng-select 
                                [items]="states"
                                
                                bindLabel="name"
                                placeholder="Enter State"
                                bindValue="id"
                                formControlName="508"
                                >
                                </ng-select>
                                
                            </div>  
                            <div class="col-md-2">
                                <input class="form-control onboard"  formControlName="509"
                                    id="509" name="509" type="text" placeholder= "UG School Zip"   data-id="managing_contact_name" >
                                
                            </div>   
                        </div>     
                    </td>
                </tr>  
                <tr>
                    <td>
                        <div class="row">
                            <div class="col-md-2">
                                <label style="font-weight: normal;font-size: 14px">Telephone</label> 
                            </div>
                            <div class="col-md-2">
                                <input class="form-control onboard"  formControlName="2345"
                                    id="2345" name="2345" type="text" placeholder= "UG School Phone"   data-id="managing_contact_name" >
                                
                            </div>  
                            <div class="col-md-1">
                                <label style="font-weight: normal;font-size: 14px">Degree</label> 
                            </div> 
                            <div class="col-md-2">
                                <ng-select 
                                [items]="degree"
                                
                                bindLabel="name"
                                placeholder="Enter Degree"
                                bindValue="id"
                                formControlName="512"
                                >
                                </ng-select>
                                
                            </div> 
                            <div class="col-md-2">
                                <label style="font-weight: normal;font-size: 14px">Date of Graduation</label> 
                            </div>
                            <div class="col-md-3">
                                <div class="input-group">
                                    <input type="text"   placeholder= "UG School End Date" id="1238" (blur)="clearInputIfInvalid('1238')" class="form-control" formControlName="1238"
                                    ngbDatepicker #d1238="ngbDatepicker" >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d1238.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                                </div>        
                                    
                                <div  *ngIf="NewManAlliedForms.get('1238').invalid ">
                                    <small class="text-danger" *ngIf="NewManAlliedForms.get('1238').errors?.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="NewManAlliedForms.get('1238').errors?.invalidMonth">
                                    Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="NewManAlliedForms.get('1238').errors?.invalidDateyear">
                                      year is out of range(1900-2099)                      </small>
                                      <small class="text-danger" *ngIf="NewManAlliedForms.get('1238').errors?.invalidDateRange">
                                        date is out of range
                                        </small>
                                    </div> 
                                 
                            </div>    

                        </div>
                    </td>
                </tr> 
                <tr>
                    <td>
                        <div class="row">
                            <div class="col-md-2">
                                <label style="font-weight: normal;font-size: 14px">Post Graduate University</label> 
                            </div> 
                            <div class="col-md-6">
                                <input class="form-control onboard"  formControlName="479"
                                    id="479" name="479" type="text" placeholder= "Grad School"   data-id="managing_contact_name" >
                                 
                            </div> 
                            
                        </div>    
                    </td>
                
                </tr>   
                <tr>
                    <td>
                        <div class="row">
                            <div class="col-md-2">
                                <label style="font-weight: normal;font-size: 14px">Address</label> 
                            </div> 
                            <div class="col-md-2">
                                <input class="form-control onboard"  formControlName="483"
                                    id="483" name="483" type="text" placeholder= "Grad School Add1"   data-id="managing_contact_name" >
                                 
                            </div> 
                            <div class="col-md-2">
                                <input class="form-control onboard"  formControlName="484"
                                    id="484" name="484" type="text" placeholder= "Grad School Add2"   data-id="managing_contact_name" >
                                
                            </div>  
                            <div class="col-md-2">
                                <input class="form-control onboard"  formControlName="485"
                                    id="485" name="485" type="text" placeholder= "Grad School City"   data-id="managing_contact_name" >
                                 
                            </div>   
                            <div class="col-md-2">
                                <ng-select 
                                [items]="states"
                                
                                bindLabel="name"
                                placeholder="Enter State"
                                bindValue="id"
                                formControlName="487"
                                >
                                </ng-select>
                                
                            </div>  
                            <div class="col-md-2">
                                <input class="form-control onboard"  formControlName="488"
                                    id="488" name="488" type="text" placeholder= "Grad School Zip"   data-id="managing_contact_name" >
                               
                            </div>   
                        </div>     
                    </td>
                </tr>  
                <tr>
                    <td>
                        <div class="row">
                            <div class="col-md-2">
                                <label style="font-weight: normal;font-size: 14px">Telephone</label> 
                            </div>
                            <div class="col-md-2">
                                <input class="form-control onboard"  formControlName="2348"
                                    id="2348" name="2348" type="text" placeholder= "Grad School Phone"   data-id="managing_contact_name" >
                               
                            </div>  
                            <div class="col-md-1">
                                <label style="font-weight: normal;font-size: 14px">Degree</label> 
                            </div> 
                            <div class="col-md-2">
                                <ng-select 
                                [items]="degree"
                                
                                bindLabel="name"
                                placeholder="Enter Degree"
                                bindValue="id"
                                formControlName="480"
                                >
                                </ng-select>
                                
                            </div> 
                            <div class="col-md-2">
                                <label style="font-weight: normal;font-size: 14px">Date of Graduation</label> 
                            </div>
                            <div class="col-md-3">
                                <div class="input-group">
                                    <input type="text"   placeholder= "Grad School End Date" id="1300" (blur)="clearInputIfInvalid('1300')" class="form-control" formControlName="1300"
                                    ngbDatepicker #d1300="ngbDatepicker" >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d1300.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                                </div>    
                                    
                                <div  *ngIf="NewManAlliedForms.get('1300').invalid ">
                                    <small class="text-danger" *ngIf="NewManAlliedForms.get('1300').errors?.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="NewManAlliedForms.get('1300').errors?.invalidMonth">
                                    Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="NewManAlliedForms.get('1300').errors?.invalidDateyear">
                                      year is out of range(1900-2099)                      </small>
                                      <small class="text-danger" *ngIf="NewManAlliedForms.get('1300').errors?.invalidDateRange">
                                        date is out of range
                                        </small>
                                    </div>   
                                 
                            </div>    

                        </div>
                    </td>
                </tr>  
                <br>
                <br>
                <tr>
                    <div class="row">
                        <div class="col-md-12">
                            <label style="font-weight: normal;font-size: 15px"><u><b id="subheading">Training Other than College or University</b></u></label> 
                        </div>
                    </div>
                </tr>  
                <tr>
                    <td>
                        <div class="row">
                            <div class="col-md-3">
                                <label style="font-weight: normal;font-size: 14px">School/Institution</label> 
                            </div>
                            <div class="col-md-5"> 
                                <input class="form-control onboard"  formControlName="1288"
                                    id="1288" name="1288" type="text" placeholder= "Additional School"  data-id="managing_contact_name" >
                            
                            </div>    
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div class="row">
                            <div class="col-md-1">
                                <label style="font-weight: normal;font-size: 14px">Address</label> 
                            </div>
                            <div class="col-md-2"> 
                                <input class="form-control onboard"  formControlName="1289"
                                    id="1289" name="1289" type="text" placeholder= "Additional Add1"  data-id="managing_contact_name" >
                            
                            </div>
                            <div class="col-md-2"> 
                                <input class="form-control onboard"  formControlName="1290"
                                    id="1290" name="1290" type="text" placeholder= "Additional Add2"  data-id="managing_contact_name" >
                            
                            </div>
                            <div class="col-md-2"> 
                                <input class="form-control onboard"  formControlName="1291"
                                    id="1291" name="1291" type="text" placeholder= "Additional City"  data-id="managing_contact_name" >
                            
                            </div>
                            <div class="col-2 pl-0">
                                <ng-select
                                    [items]="states"
                                    bindLabel="name"
                                    placeholder="Enter State"
                                    bindValue="id"
                                    formControlName="1293"
                                    
                                    >
                                </ng-select>                                 
                            </div>  
                            <div class="col-md-2"> 
                                <input class="form-control onboard"  formControlName="1294"
                                    id="1294" name="1294" type="text" placeholder= "Additional Zip"  data-id="managing_contact_name" >
                            </div>                                                            
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div class="row">
                            <div class="col-md-1">
                                <label style="font-weight: normal;font-size: 14px">Telephone</label> 
                            </div>
                            <div class="col-md-3"> 
                                <input class="form-control onboard"  formControlName="2354"
                                    id="2354" name="2354" type="text" placeholder= "Additional Director Phone"  data-id="managing_contact_name" >
                                    <small class="text-danger" *ngIf="NewManAlliedForms.controls['2354'].errors?.pattern">
                                        Only number are allowed</small>
                            </div> 
                            
                            <div class="col-md-2">
                                <label style="font-weight: normal;font-size: 14px">Date Completed</label> 
                            </div>
                            <div class="col-md-3">
                                <div class="input-group">
                                    <input type="text"   placeholder= "Additional End Date" id="1296" (blur)="clearInputIfInvalid('1296')" class="form-control" formControlName="1296"
                                    ngbDatepicker #d1296="ngbDatepicker" >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d1296.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                                </div>    
                                <div  *ngIf="NewManAlliedForms.get('1296').invalid ">
                                    <small class="text-danger" *ngIf="NewManAlliedForms.get('1296').errors?.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="NewManAlliedForms.get('1296').errors?.invalidMonth">
                                    Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="NewManAlliedForms.get('1296').errors?.invalidDateyear">
                                      year is out of range(1900-2099)                      </small>
                                      <small class="text-danger" *ngIf="NewManAlliedForms.get('1296').errors?.invalidDateRange">
                                        date is out of range
                                        </small>
                                    </div>                                                                  
                            </div>    
                            
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div class="row">
                            <div class="col-md-2">
                                <label style="font-weight: normal;font-size: 14px">Course of Study</label> 
                            </div>
                            <div class="col-md-5">
                                <ng-select
                                    [items]="specialty"
                                    bindLabel="name"
                                    placeholder="Enter specialty"
                                    bindValue="id"
                                    formControlName="1628"
                                    
                                    >
                                </ng-select>                                 
                            </div>  
                        </div>
                    </td>    
                </tr>
                <br>
                <br>
                <tr>
                    <td>
                        <div class="row">
                            <div class="col-md-8">
                                <label style="font-weight: normal;font-size: 15px"><u><b id="subheading">Internships (If applicable)</b></u></label> 
                            </div> 
                              
                        </div>    
                    </td>
                </tr>   
                <tr>
                    <td>
                        <div class="row">
                            <div class="col-md-2">
                                <label style="font-weight: normal;font-size: 14px">Complete address</label> 
                            </div> 
                            <div class="col-md-2">
                                <input class="form-control onboard"  formControlName="518"
                                    id="518" name="518" type="text" placeholder= "Intern  Add1"   data-id="managing_contact_name" >
                                
                            </div> 
                            <div class="col-md-2">
                                <input class="form-control onboard"  formControlName="519"
                                    id="519" name="519" type="text" placeholder= "Intern Add2"   data-id="managing_contact_name" >
                                
                            </div>  
                            <div class="col-md-2">
                                <input class="form-control onboard"  formControlName="520"
                                    id="520" name="520" type="text" placeholder= "Intern City"   data-id="managing_contact_name" >
                                
                            </div>   
                            <div class="col-md-2">
                                <ng-select 
                                [items]="states"
                                
                                bindLabel="name"
                                placeholder="Enter State"
                                bindValue="id"
                                formControlName="522"
                                >
                                </ng-select>
                                
                            </div>  
                            <div class="col-md-2">
                                <input class="form-control onboard"  formControlName="523"
                                    id="523" name="523" type="text" placeholder= "Intern Zip"   data-id="managing_contact_name" >
                               
                            </div>   
                        </div>     
                    </td>
                </tr>
                <tr>
                    <td>
                        <div class="row">
                            <div class="col-md-2">
                                <label style="font-weight: normal;font-size: 14px">Supervisor</label> 
                            </div>
                            <div class="col-md-3">
                                <input class="form-control onboard"  formControlName="528"
                                    id="528" name="528" type="text" placeholder= "Intern Director"   data-id="managing_contact_name" >
                                
                            </div>   
                            <div class="col-md-1">
                                <label style="font-weight: normal;font-size: 14px">Dates</label> 
                            </div>
                            <div class="col-md-3">
                                <div class="input-group">
                                    <input type="text"   placeholder= "Intern Start Date" id="524" (blur)="clearInputIfInvalid('524')" class="form-control" formControlName="524"
                                    ngbDatepicker #d524="ngbDatepicker" >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d524.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                                </div>   
                                <div  *ngIf="NewManAlliedForms.get('524').invalid ">
                                    <small class="text-danger" *ngIf="NewManAlliedForms.get('524').errors?.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="NewManAlliedForms.get('524').errors?.invalidMonth">
                                    Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="NewManAlliedForms.get('524').errors?.invalidDateyear">
                                      year is out of range(1900-2099)                      </small>
                                      <small class="text-danger" *ngIf="NewManAlliedForms.get('524').errors?.invalidDateRange">
                                        date is out of range
                                        </small>
                                    </div>     
                                    
                                 
                            </div>    
                            <div class="col-md-3">
                                <div class="input-group">
                                    <input type="text"   placeholder= "Grad School End Date" id="525" (blur)="clearInputIfInvalid('525')" class="form-control" formControlName="525"
                                    ngbDatepicker #d525="ngbDatepicker" >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d525.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                                </div>        
                                <div  *ngIf="NewManAlliedForms.get('525').invalid ">
                                    <small class="text-danger" *ngIf="NewManAlliedForms.get('525').errors?.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="NewManAlliedForms.get('525').errors?.invalidMonth">
                                    Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="NewManAlliedForms.get('525').errors?.invalidDateyear">
                                      year is out of range(1900-2099)                      </small>
                                      <small class="text-danger" *ngIf="NewManAlliedForms.get('525').errors?.invalidDateRange">
                                        date is out of range
                                        </small>
                                    </div>
                                    
                                 
                            </div>    

                        </div>
                    </td>

                </tr>
                <tr>
                    <td>
                        <div class="row">
                            <div class="col-md-2">
                                <label style="font-weight: normal;font-size: 14px">Complete address</label> 
                            </div> 
                            <div class="col-md-2">
                                <input class="form-control onboard"  formControlName="2859"
                                    id="2859" name="2859" type="text" placeholder= "Additional Intern  Add1"   data-id="managing_contact_name" >
                                
                            </div> 
                            <div class="col-md-2">
                                <input class="form-control onboard"  formControlName="2860"
                                    id="2860" name="2860" type="text" placeholder= "Additional Intern  Add2"   data-id="managing_contact_name" >
                                
                            </div>   
                            <div class="col-md-2">
                                <input class="form-control onboard"  formControlName="2861"
                                    id="2861" name="2861" type="text" placeholder= "Additional Intern  City"   data-id="managing_contact_name" >
                                
                            </div>   
                            <div class="col-md-2">
                                <ng-select 
                                [items]="states"
                                
                                bindLabel="name"
                                placeholder="Enter State"
                                bindValue="id"
                                formControlName="2863"
                                >
                                </ng-select>
                                
                            </div>  
                            <div class="col-md-2">
                                <input class="form-control onboard"  formControlName="2864"
                                    id="2864" name="2864" type="text" placeholder= "Additional Intern Zip"   data-id="managing_contact_name" >
                                
                            </div>   
                        </div>     
                    </td>
                </tr>
                <tr>
                    <td>
                        <div class="row">
                            <div class="col-md-2">
                                <label style="font-weight: normal;font-size: 14px">Supervisor</label> 
                            </div>
                            <div class="col-md-3">
                                <input class="form-control onboard"  formControlName="2869"
                                    id="2869" name="2869" type="text" placeholder= "Additional Intern Director"   data-id="managing_contact_name" >
                                
                            </div>   
                            <div class="col-md-1">
                                <label style="font-weight: normal;font-size: 14px">Dates</label> 
                            </div>
                            <div class="col-md-3">
                                <div class="input-group">
                                    <input type="text"   placeholder= "Additional Intern Start Date" id="2866" (blur)="clearInputIfInvalid('2866')" class="form-control" formControlName="2866"
                                    ngbDatepicker #d2866="ngbDatepicker" >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d2866.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                                </div>    
                                 
                                <div  *ngIf="NewManAlliedForms.get('2866').invalid ">
                                    <small class="text-danger" *ngIf="NewManAlliedForms.get('2866').errors?.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="NewManAlliedForms.get('2866').errors?.invalidMonth">
                                    Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="NewManAlliedForms.get('2866').errors?.invalidDateyear">
                                      year is out of range(1900-2099)                      </small>
                                      <small class="text-danger" *ngIf="NewManAlliedForms.get('2866').errors?.invalidDateRange">
                                        date is out of range
                                        </small>
                                    </div>
                                 
                            </div>    
                            <div class="col-md-3">
                                <div class="input-group">
                                    <input type="text"   placeholder= "Additional Intern End Date" id="2867" class="form-control" (blur)="clearInputIfInvalid('2867')" formControlName="2867"
                                    ngbDatepicker #d2867="ngbDatepicker" >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d2867.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                                </div>     
                                  
                                <div  *ngIf="NewManAlliedForms.get('2867').invalid ">
                                    <small class="text-danger" *ngIf="NewManAlliedForms.get('2867').errors?.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="NewManAlliedForms.get('2867').errors?.invalidMonth">
                                    Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="NewManAlliedForms.get('2867').errors?.invalidDateyear">
                                      year is out of range(1900-2099)                      </small>
                                      <small class="text-danger" *ngIf="NewManAlliedForms.get('2867').errors?.invalidDateRange">
                                        date is out of range
                                        </small>
                                    </div>
                                     
                            </div>    
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div class="row">
                            <div class="col-md-2">
                                <label style="font-weight: normal;font-size: 14px">Complete address</label> 
                            </div> 
                            <div class="col-md-2">
                                <input class="form-control onboard"  formControlName="531"
                                    id="531" name="531" type="text" placeholder= "Residency   Add1"   data-id="managing_contact_name" >
                                
                            </div> 
                            <div class="col-md-2">
                                <input class="form-control onboard"  formControlName="1498"
                                    id="1498" name="1498" type="text" placeholder= "Residency  Add2"   data-id="managing_contact_name" >
                                
                            </div>  
                            <div class="col-md-2">
                                <input class="form-control onboard"  formControlName="533"
                                    id="533" name="533" type="text" placeholder= "Residency City"   data-id="managing_contact_name" >
                                
                            </div>   
                            <div class="col-md-2">
                                <ng-select 
                                [items]="states"
                                
                                bindLabel="name"
                                placeholder="Enter State"
                                bindValue="id"
                                formControlName="535"
                                >
                                </ng-select>
                                
                            </div>  
                            <div class="col-md-2">
                                <input class="form-control onboard"  formControlName="536"
                                    id="536" name="536" type="text" placeholder= "Residency  Zip"   data-id="managing_contact_name" >
                                
                            </div>   
                        </div>     
                    </td>
                </tr>
                <tr>
                    <td>
                        <div class="row">
                            <div class="col-md-2">
                                <label style="font-weight: normal;font-size: 14px">Supervisor</label> 
                            </div>
                            <div class="col-md-3">
                                <input class="form-control onboard"  formControlName="540"
                                    id="540" name="540" type="text" placeholder= "Residency  Director"   data-id="managing_contact_name" >
                                
                            </div>   
                            <div class="col-md-1">
                                <label style="font-weight: normal;font-size: 14px">Dates</label> 
                            </div>
                            <div class="col-md-3">
                                <div class="input-group">
                                    <input type="text"   placeholder= "Residency  Start Date" id="537" (blur)="clearInputIfInvalid('537')" class="form-control" formControlName="537"
                                    ngbDatepicker #d537="ngbDatepicker" >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d537.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                                </div>   
                                <div  *ngIf="NewManAlliedForms.get('537').invalid ">
                                    <small class="text-danger" *ngIf="NewManAlliedForms.get('537').errors?.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="NewManAlliedForms.get('537').errors?.invalidMonth">
                                    Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="NewManAlliedForms.get('537').errors?.invalidDateyear">
                                      year is out of range(1900-2099)                      </small>
                                      <small class="text-danger" *ngIf="NewManAlliedForms.get('537').errors?.invalidDateRange">
                                        date is out of range
                                        </small>
                                    </div>
                                 
                            </div>    
                            <div class="col-md-3">
                                <div class="input-group">
                                    <input type="text"   placeholder= "Residency  End Date" id="538" class="form-control" formControlName="538" (blur)="clearInputIfInvalid('538')"
                                    ngbDatepicker #d538="ngbDatepicker" >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d538.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                                </div> 
                                <div  *ngIf="NewManAlliedForms.get('538').invalid ">
                                    <small class="text-danger" *ngIf="NewManAlliedForms.get('538').errors?.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="NewManAlliedForms.get('538').errors?.invalidMonth">
                                    Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="NewManAlliedForms.get('538').errors?.invalidDateyear">
                                      year is out of range(1900-2099)                      </small>
                                      <small class="text-danger" *ngIf="NewManAlliedForms.get('538').errors?.invalidDateRange">
                                        date is out of range
                                        </small>
                                    </div>       
                                     
                            </div>    
                        </div>
                    </td>
                </tr>
                <br>
                <br>
                <tr>
                    <td>
                        <div class="row">
                            <div class="col-md-12">
                                <label style="font-weight: normal;font-size: 15px"><b id="subheadings"><u>Continuing Education</u> Attach copies of continuing education certificates or verification for which
                                    you have attended and received credit in the past two years.</b></label> 
                            </div>
                        </div> 
                    </td>
                </tr>
                <br>
                <tr>
                    <th>
                        <div class="text-center">
                            <b id="subhead">PREVIOUS RELATED EXPERIENCE</b>
                        </div>
                    </th>
                </tr>
                
                <tr>
                    <td>
                        <div class="row">
                            <div class="col-md-12">
                                <label style="font-weight: normal;font-size: 15px" >(For the past 10 years, list all present and previous hospital and/ or professional affiliations in
                                    chronological order, specify nature and extent of privileges)</label> 
                            </div>
                        </div> 
                    </td>
                </tr>
                <tr>
                    <td>
                        <div class="row">
                            <div class="col-md-2">
                                <label style="font-weight: normal;font-size: 14px">Position</label>
                            </div>
                            <div class="col-md-2"> 
                                <input class="form-control onboard"  formControlName="1326"
                                    id="1326" name="1326" type="text" placeholder= "Work History 1 Job Title"  data-id="managing_contact_name" >
                                
                            </div>  
                            <div class="col-md-1">
                                <label style="font-weight: normal;font-size: 14px">From</label>
                            </div>
                            <div class="col-md-3">
                                <div class="input-group">
                                    <input type="text" placeholder="Work History 1 Start Date" ngbDatepicker #d454="ngbDatepicker" id="454" (blur)="clearInputIfInvalid('454')" class="form-control" formControlName="454"
                                    >
                                        <div class="input-group-append">
                                            <button class="btn btn-outline-secondary calendar" (click)="d454.toggle()" type="button">
                                            <i class="fas fa-calendar-alt"></i>
                                            </button>
                                        </div>
                                </div>

                                <div  *ngIf="NewManAlliedForms.get('454').invalid ">
                                    <small class="text-danger" *ngIf="NewManAlliedForms.get('454').errors?.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="NewManAlliedForms.get('454').errors?.invalidMonth">
                                    Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="NewManAlliedForms.get('454').errors?.invalidDateyear">
                                      year is out of range(1900-2099)                      </small>
                                      <small class="text-danger" *ngIf="NewManAlliedForms.get('454').errors?.invalidDateRange">
                                        date is out of range
                                        </small>
                                    </div>
                            </div>   
                            <div class="col-md-1">
                                <label style="font-weight: normal;font-size: 14px">To</label>
                            </div>
                            <div class="col-md-3">
                                <div class="input-group">
                                    <input type="text" placeholder="Work History 1 End Date" ngbDatepicker #d455="ngbDatepicker" id="455" (blur)="clearInputIfInvalid('455')" class="form-control" formControlName="455"
                                    >
                                        <div class="input-group-append">
                                            <button class="btn btn-outline-secondary calendar" (click)="d455.toggle()" type="button">
                                            <i class="fas fa-calendar-alt"></i>
                                            </button>
                                        </div>
                                </div>
                                <div  *ngIf="NewManAlliedForms.get('455').invalid ">
                                    <small class="text-danger" *ngIf="NewManAlliedForms.get('455').errors?.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="NewManAlliedForms.get('455').errors?.invalidMonth">
                                    Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="NewManAlliedForms.get('455').errors?.invalidDateyear">
                                      year is out of range(1900-2099)                      </small>
                                      <small class="text-danger" *ngIf="NewManAlliedForms.get('455').errors?.invalidDateRange">
                                        date is out of range
                                        </small>
                                    </div>
                                 
                            </div>
                        </div>    
                    </td>
                </tr>   
                <tr>
                    <td>
                        <div class="row">
                            <div class="col-md-2">
                                <label style="font-weight: normal;font-size: 14px">Employer Name</label>
                            </div>
                            <div class="col-md-4"> 
                                <input class="form-control onboard"  formControlName="445"
                                    id="445" name="445" type="text" placeholder= "Work History 1 Location"  data-id="managing_contact_name" >
                               
                            </div> 
                            <div class="col-md-2">
                                <label style="font-weight: normal;font-size: 14px">Fax Number</label>
                            </div>
                            <div class="col-md-4"> 
                                <input class="form-control onboard"  formControlName="888"
                                    id="888" name="888" type="text" placeholder= "Work History 1 Fax"  data-id="managing_contact_name" >
                                
                            </div>  
                        
                        </div>    
                    </td>
                </tr>
                <tr>
                    <td>
                        <div class="row">
                            <div class="col-md-1">
                                <label style="font-weight: normal;font-size: 14px">Address</label>
                            </div>
                            <div class="col-md-2"> 
                                <input class="form-control onboard"  formControlName="446"
                                    id="446" name="446" type="text" placeholder= "Work History 1 Address1"  data-id="managing_contact_name" >
                                
                            </div>  
                            <div class="col-md-2"> 
                                <input class="form-control onboard"  formControlName="447"
                                    id="447" name="447" type="text" placeholder= "Work History 1 Address2"  data-id="managing_contact_name" >
                                    
                            </div>  
                            <div class="col-md-2"> 
                                <input class="form-control onboard"  formControlName="448"
                                    id="448" name="448" type="text" placeholder= "Work History 1 City"  data-id="managing_contact_name" >
                                
                            </div>  
                            <div class="col-md-2">
                                <ng-select
                                    [items]="states"
                                    bindLabel="name"
                                    placeholder="Enter State"
                                    bindValue="id"
                                    formControlName="450"
                                    
                                    >
                                </ng-select> 
                                
                            </div>
                            <div class="col-md-2"> 
                                <input class="form-control onboard"  formControlName="451"
                                    id="451" name="451" type="text" placeholder= "Work History 1 Zip"  data-id="managing_contact_name" >
                                
                            </div> 
                            
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div class="row">
                            <div class="col-md-2">
                                <label style="font-weight: normal;font-size: 14px">Position</label>
                            </div>
                            <div class="col-md-2"> 
                                <input class="form-control onboard"  formControlName="1343"
                                    id="1343" name="1343" type="text" placeholder= "Work History 2 Job Title"  data-id="managing_contact_name" >
                                
                            </div>  
                            <div class="col-md-1">
                                <label style="font-weight: normal;font-size: 14px">From</label>
                            </div>
                            <div class="col-md-3">
                                <div class="input-group">
                                    <input type="text" placeholder="Work History 2 Start Date" ngbDatepicker #d465="ngbDatepicker" id="465" (blur)="clearInputIfInvalid('465')" class="form-control" formControlName="465"
                                    >
                                        <div class="input-group-append">
                                            <button class="btn btn-outline-secondary calendar" (click)="d465.toggle()" type="button">
                                            <i class="fas fa-calendar-alt"></i>
                                            </button>
                                        </div>
                                </div>

                                <div  *ngIf="NewManAlliedForms.get('465').invalid ">
                                    <small class="text-danger" *ngIf="NewManAlliedForms.get('465').errors?.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="NewManAlliedForms.get('465').errors?.invalidMonth">
                                    Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="NewManAlliedForms.get('465').errors?.invalidDateyear">
                                      year is out of range(1900-2099)                      </small>
                                      <small class="text-danger" *ngIf="NewManAlliedForms.get('465').errors?.invalidDateRange">
                                        date is out of range
                                        </small>
                                    </div>
                            </div>   
                            <div class="col-md-1">
                                <label style="font-weight: normal;font-size: 14px">To</label>
                            </div>
                            <div class="col-md-3">
                                <div class="input-group">
                                    <input type="text" placeholder="Work History 2 End Date" ngbDatepicker #d466="ngbDatepicker" id="466" (blur)="clearInputIfInvalid('466')" class="form-control" formControlName="466"
                                    >
                                        <div class="input-group-append">
                                            <button class="btn btn-outline-secondary calendar" (click)="d466.toggle()" type="button">
                                            <i class="fas fa-calendar-alt"></i>
                                            </button>
                                        </div>
                                </div>
                                <div  *ngIf="NewManAlliedForms.get('466').invalid ">
                                    <small class="text-danger" *ngIf="NewManAlliedForms.get('466').errors?.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="NewManAlliedForms.get('466').errors?.invalidMonth">
                                    Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="NewManAlliedForms.get('466').errors?.invalidDateyear">
                                      year is out of range(1900-2099)                      </small>
                                      <small class="text-danger" *ngIf="NewManAlliedForms.get('466').errors?.invalidDateRange">
                                        date is out of range
                                        </small>
                                    </div>
                            </div>
                        </div>    
                    </td>
                </tr>   
                <tr>
                    <td>
                        <div class="row">
                            <div class="col-md-2">
                                <label style="font-weight: normal;font-size: 14px">Employer Name</label>
                            </div>
                            <div class="col-md-4"> 
                                <input class="form-control onboard"  formControlName="456"
                                    id="456" name="456" type="text" placeholder= "Work History 2 Location"  data-id="managing_contact_name" >
                                
                            </div> 
                            <div class="col-md-2">
                                <label style="font-weight: normal;font-size: 14px">Fax Number</label>
                            </div>
                            <div class="col-md-4"> 
                                <input class="form-control onboard"  formControlName="890"
                                    id="890" name="890" type="text" placeholder= "Work History 2 Fax"  data-id="managing_contact_name" >
                                
                            </div>  
                        
                        </div>    
                    </td>
                </tr>
                <tr>
                    <td>
                        <div class="row">
                            <div class="col-md-1">
                                <label style="font-weight: normal;font-size: 14px">Address</label>
                            </div>
                            <div class="col-md-2"> 
                                <input class="form-control onboard"  formControlName="457"
                                    id="457" name="457" type="text" placeholder= "Work History 2 Address1"  data-id="managing_contact_name" >
                                
                            </div>  
                            <div class="col-md-2"> 
                                <input class="form-control onboard"  formControlName="458"
                                    id="458" name="458" type="text" placeholder= "Work History 2 Address2"  data-id="managing_contact_name" >
                                    
                                
                            </div>  
                            <div class="col-md-2"> 
                                <input class="form-control onboard"  formControlName="459"
                                    id="459" name="459" type="text" placeholder= "Work History 2 City"  data-id="managing_contact_name" >
                                
                            </div>  
                            <div class="col-md-2">
                                <ng-select
                                    [items]="states"
                                    bindLabel="name"
                                    placeholder="Enter State"
                                    bindValue="id"
                                    formControlName="461"
                                    
                                    >
                                </ng-select> 
                                
                            </div>
                            <div class="col-md-2"> 
                                <input class="form-control onboard"  formControlName="462"
                                    id="462" name="462" type="text" placeholder= "Work History 2 Zip"  data-id="managing_contact_name" >
                                
                            </div> 
                            
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div class="row">
                            <div class="col-md-2">
                                <label style="font-weight: normal;font-size: 14px">Position</label>
                            </div>
                            <div class="col-md-2"> 
                                <input class="form-control onboard"  formControlName="1339"
                                    id="1339" name="1339" type="text" placeholder= "Work History 3 Job Title"  data-id="managing_contact_name" >
                                
                            </div>  
                            <div class="col-md-1">
                                <label style="font-weight: normal;font-size: 14px">From</label>
                            </div>
                            <div class="col-md-3">
                                <div class="input-group">
                                    <input type="text" placeholder="Work History 3 Start Date" ngbDatepicker #d476="ngbDatepicker" id="476" class="form-control" formControlName="476" (blur)="clearInputIfInvalid('476')"
                                    >
                                        <div class="input-group-append">
                                            <button class="btn btn-outline-secondary calendar" (click)="d476.toggle()" type="button">
                                            <i class="fas fa-calendar-alt"></i>
                                            </button>
                                        </div>
                                </div>
                                   
                                <div  *ngIf="NewManAlliedForms.get('476').invalid ">
                                    <small class="text-danger" *ngIf="NewManAlliedForms.get('476').errors?.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="NewManAlliedForms.get('476').errors?.invalidMonth">
                                    Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="NewManAlliedForms.get('476').errors?.invalidDateyear">
                                      year is out of range(1900-2099)                      </small>
                                      <small class="text-danger" *ngIf="NewManAlliedForms.get('476').errors?.invalidDateRange">
                                        date is out of range
                                        </small>
                                    </div>

                            </div>   
                            <div class="col-md-1">
                                <label style="font-weight: normal;font-size: 14px">To</label>
                            </div>
                            <div class="col-md-3">
                                <div class="input-group">
                                    <input type="text" placeholder="Work History 2 End Date" ngbDatepicker #d477="ngbDatepicker" id="477" class="form-control" formControlName="477" (blur)="clearInputIfInvalid('477')"
                                    >
                                        <div class="input-group-append">
                                            <button class="btn btn-outline-secondary calendar" (click)="d477.toggle()" type="button">
                                            <i class="fas fa-calendar-alt"></i>
                                            </button>
                                        </div>
                                </div>

                                <div  *ngIf="NewManAlliedForms.get('477').invalid ">
                                    <small class="text-danger" *ngIf="NewManAlliedForms.get('477').errors?.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="NewManAlliedForms.get('477').errors?.invalidMonth">
                                    Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="NewManAlliedForms.get('477').errors?.invalidDateyear">
                                      year is out of range(1900-2099)                      </small>
                                      <small class="text-danger" *ngIf="NewManAlliedForms.get('477').errors?.invalidDateRange">
                                        date is out of range
                                        </small>
                                    </div>
                            </div>
                        </div>    
                    </td>
                </tr>   
                <tr>
                    <td>
                        <div class="row">
                            <div class="col-md-2">
                                <label style="font-weight: normal;font-size: 14px">Employer Name</label>
                            </div>
                            <div class="col-md-4"> 
                                <input class="form-control onboard"  formControlName="467"
                                    id="467" name="467" type="text" placeholder= "Work History 3 Location"  data-id="managing_contact_name" >
                                
                            </div> 
                            <div class="col-md-2">
                                <label style="font-weight: normal;font-size: 14px">Fax Number</label>
                            </div>
                            <div class="col-md-4"> 
                                <input class="form-control onboard"  formControlName="892"
                                    id="892" name="892" type="text" placeholder= "Work History 3 Fax"  data-id="managing_contact_name" >
                                
                            </div>  
                        
                        </div>    
                    </td>
                </tr>
                <tr>
                    <td>
                        <div class="row">
                            <div class="col-md-1">
                                <label style="font-weight: normal;font-size: 14px">Address</label>
                            </div>
                            <div class="col-md-2"> 
                                <input class="form-control onboard"  formControlName="468"
                                    id="468" name="468" type="text" placeholder= "Work History 3 Address1"  data-id="managing_contact_name" >
                                
                            </div>  
                            <div class="col-md-2"> 
                                <input class="form-control onboard"  formControlName="469"
                                    id="469" name="469" type="text" placeholder= "Work History 3 Address2"  data-id="managing_contact_name" >
                                    
                                
                            </div>  
                            <div class="col-md-2"> 
                                <input class="form-control onboard"  formControlName="470"
                                    id="470" name="470" type="text" placeholder= "Work History 3 City"  data-id="managing_contact_name" >
                                
                            </div>  
                            <div class="col-md-2">
                                <ng-select
                                    [items]="states"
                                    bindLabel="name"
                                    placeholder="Enter State"
                                    bindValue="id"
                                    formControlName="472"
                                    
                                    >
                                </ng-select> 
                                
                            </div>
                            <div class="col-md-2"> 
                                <input class="form-control onboard"  formControlName="473"
                                    id="473" name="473" type="text" placeholder= "Work History 3 Zip"  data-id="managing_contact_name" >
                                
                            </div> 
                            
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div class="row">
                            <div class="col-md-2">
                                <label style="font-weight: normal;font-size: 14px">Position</label>
                            </div>
                            <div class="col-md-2"> 
                                <input class="form-control onboard"  formControlName="1949"
                                    id="1949" name="1949" type="text" placeholder= "Work History 4 Job Title"  data-id="managing_contact_name" >
                                
                            </div>  
                            <div class="col-md-1">
                                <label style="font-weight: normal;font-size: 14px">From</label>
                            </div>
                            <div class="col-md-3">
                                <div class="input-group">
                                    <input type="text" placeholder="Work History 4 Start Date" ngbDatepicker #d1947="ngbDatepicker" id="1947" (blur)="clearInputIfInvalid('1947')" class="form-control" formControlName="1947"
                                    >
                                        <div class="input-group-append">
                                            <button class="btn btn-outline-secondary calendar" (click)="d1947.toggle()" type="button">
                                            <i class="fas fa-calendar-alt"></i>
                                            </button>
                                        </div>
                                </div>

                                <div  *ngIf="NewManAlliedForms.get('1947').invalid ">
                                    <small class="text-danger" *ngIf="NewManAlliedForms.get('1947').errors?.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="NewManAlliedForms.get('1947').errors?.invalidMonth">
                                    Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="NewManAlliedForms.get('1947').errors?.invalidDateyear">
                                      year is out of range(1900-2099)                      </small>
                                      <small class="text-danger" *ngIf="NewManAlliedForms.get('1947').errors?.invalidDateRange">
                                        date is out of range
                                        </small>
                                    </div>
                            </div>   
                            <div class="col-md-1">
                                <label style="font-weight: normal;font-size: 14px">To</label>
                            </div>
                            <div class="col-md-3">
                                <div class="input-group">
                                    <input type="text" placeholder="Work History 4 End Date" ngbDatepicker #d1948="ngbDatepicker" id="1948" (blur)="clearInputIfInvalid('1948')" class="form-control" formControlName="1948"
                                    >
                                        <div class="input-group-append">
                                            <button class="btn btn-outline-secondary calendar" (click)="d1948.toggle()" type="button">
                                            <i class="fas fa-calendar-alt"></i>
                                            </button>
                                        </div>
                                </div>
                                <div  *ngIf="NewManAlliedForms.get('1948').invalid ">
                                    <small class="text-danger" *ngIf="NewManAlliedForms.get('1948').errors?.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="NewManAlliedForms.get('1948').errors?.invalidMonth">
                                    Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="NewManAlliedForms.get('1948').errors?.invalidDateyear">
                                      year is out of range(1900-2099)                      </small>
                                      <small class="text-danger" *ngIf="NewManAlliedForms.get('1948').errors?.invalidDateRange">
                                        date is out of range
                                        </small>
                                    </div>
                                 
                            </div>
                        </div>    
                    </td>
                </tr>   
                <tr>
                    <td>
                        <div class="row">
                            <div class="col-md-2">
                                <label style="font-weight: normal;font-size: 14px">Employer Name</label>
                            </div>
                            <div class="col-md-4"> 
                                <input class="form-control onboard"  formControlName="1935"
                                    id="1935" name="1935" type="text" placeholder= "Work History 4 Location"  data-id="managing_contact_name" >
                                
                            </div> 
                            <div class="col-md-2">
                                <label style="font-weight: normal;font-size: 14px">Fax Number</label>
                            </div>
                            <div class="col-md-4"> 
                                <input class="form-control onboard"  formControlName="1945"
                                    id="1945" name="1945" type="text" placeholder= "Work History 4 Fax"  data-id="managing_contact_name" >
                                
                            </div>  
                        
                        </div>    
                    </td>
                </tr>
                <tr>
                    <td>
                        <div class="row">
                            <div class="col-md-1">
                                <label style="font-weight: normal;font-size: 14px">Address</label>
                            </div>
                            <div class="col-md-2"> 
                                <input class="form-control onboard"  formControlName="1936"
                                    id="1936" name="1936" type="text" placeholder= "Work History 4 Address1"  data-id="managing_contact_name" >
                                
                            </div>  
                            <div class="col-md-2"> 
                                <input class="form-control onboard"  formControlName="1937"
                                    id="1937" name="1937" type="text" placeholder= "Work History 4 Address2"  data-id="managing_contact_name" >
                                    
                                
                            </div>  
                            <div class="col-md-2"> 
                                <input class="form-control onboard"  formControlName="1938"
                                    id="1938" name="1938" type="text" placeholder= "Work History 4 City"  data-id="managing_contact_name" >
                                
                            </div>  
                            <div class="col-md-2">
                                <ng-select
                                    [items]="states"
                                    bindLabel="name"
                                    placeholder="Enter State"
                                    bindValue="id"
                                    formControlName="1940"
                                    
                                    >
                                </ng-select> 
                                
                            </div>
                            <div class="col-md-2"> 
                                <input class="form-control onboard"  formControlName="1941"
                                    id="1941" name="1941" type="text" placeholder= "Work History 4 Zip"  data-id="managing_contact_name" >
                            </div> 
                            
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div class="row">
                            <div class="form-group col-md-9">
                                <label style="font-weight: normal;font-size: 14px"><i>(Use additional sheet if explanation is extensive)</i>
                                </label>
                            </div>
                        </div>
                    </td>
                </tr>            
                <tr>
                    <td>
                        <div class="row">
                            <div class="form-group col-md-9">
                                <label style="font-weight: normal;font-size: 14px"> Have you ever been denied employment or been subject to disciplinary proceeding from a previous
                                    employer?
                                </label>
                            </div>
                            <div class="col-md-3">
                                <label class="checkbox-inline">
                                  <input type="radio" id="cmd_008" name="cmd_008"  formControlName="cmd_008" value="1">Yes</label>
                                  <label class="checkbox-inline">
                                  <input type="radio" id="cmd_008" name="cmd_008"  formControlName="cmd_008" value="0">No</label>
                                  
                              </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <th>
                        <div class="text-center">
                            <b id="subhead">PROFESSIONAL</b>
                        </div>
                    </th>
                </tr>
                <tr>
                    <td>
                        <div class="row">
                            <div class="col-md-12">
                                <label style="font-weight: normal;font-size: 15px"><b>(Please enclose a copy of your current license, certification or registration)</b>
                                </label>
                            </div>
                        </div>
                    </td>
                </tr>            
                <tr>
                    <td>
                        <div class="row">
                            <div class="col-md-2">
                                <label style="font-weight: normal;font-size: 14px">License #</label>
                            </div> 
                            <div class="col-md-4"> 
                                <input class="form-control onboard"  formControlName="293"
                                    id="293" name="293" type="text" placeholder= "License Number" data-id="managing_contact_name" >
                                
                            </div>
                            <div class="col-md-1">
                                <label style="font-weight: normal;font-size: 14px">State</label>
                            </div> 
                            <div class="col-md-4">
                                <ng-select
                                    [items]="states"
                                    bindLabel="name"
                                    placeholder="Enter State"
                                    bindValue="id"
                                    formControlName="299"
                                    
                                    >
                                </ng-select> 
                                
                            </div>    
                        </div>
                    </td>
                </tr>            
                <tr>
                    <td>
                        <div class="row">
                            <div class="col-md-2">
                                <label style="font-weight: normal;font-size: 14px">DEA Registration </label>
                            </div> 
                            <div class="col-md-4"> 
                                <input class="form-control onboard"  formControlName="309"
                                    id="309" name="309" type="text" placeholder= "DEA Number"  data-id="managing_contact_name" >
                                
                            </div>
                            <div class="col-md-1">
                                <label style="font-weight: normal;font-size: 14px">State</label>
                            </div> 
                            <div class="col-md-4">
                                <ng-select
                                    [items]="states"
                                    bindLabel="name"
                                    placeholder="Enter State"
                                    bindValue="id"
                                    formControlName="312"
                                    
                                    >
                                </ng-select> 
                               
                            </div>    
                        </div>
                    </td>
                </tr>            
                <tr>
                    <td>
                        <div class="row">
                            <div class="col-md-2">
                                <label style="font-weight: normal;font-size: 14px">Certification#</label>
                            </div> 
                            <div class="col-md-4"> 
                                <input class="form-control onboard"  formControlName="300"
                                    id="300" name="300" type="text" placeholder= "License Number"  data-id="managing_contact_name" >
                               
                            </div>
                            <div class="col-md-1">
                                <label style="font-weight: normal;font-size: 14px">State</label>
                            </div> 
                            <div class="col-md-4">
                                <ng-select
                                    [items]="altLicenseState"
                                    bindLabel="name"
                                    placeholder="Enter State"
                                    bindValue="id"
                                    formControlName="907"
                                    
                                    >
                                </ng-select> 
                                
                            </div>    
                        </div>
                    </td>
                </tr>    
                <tr>
                    <td>
                        <div class="row">
                            <div class="form-group col-md-9">
                                <label style="font-weight: normal;font-size: 14px"> Has your license / registration / certification in any state ever been voluntarily or involuntarily suspended,
                                    revoked, altered, surrendered and /or have you ever been placed on probation or had your practice
                                    restricted in any way or is there any action currently pending?
                                </label>
                            </div>
                            <div class="col-md-3">
                                <label class="checkbox-inline">
                                  <input type="radio" id="cmd_009" name="cmd_009"  formControlName="cmd_009" value="1">Yes</label>
                                  <label class="checkbox-inline">
                                  <input type="radio" id="cmd_009" name="cmd_009"  formControlName="cmd_009" value="0">No</label>
                                  
                              </div>
                        </div>
                    </td>
                </tr>   
                <tr>
                    <td>
                        <div class="row">
                            <div class="form-group col-md-9">
                                <label style="font-weight: normal;font-size: 14px"> Have you been charged with or convicted of a crime other than a minor traffic violation?
                                </label>
                            </div>
                            <div class="col-md-3">
                                <label class="checkbox-inline">
                                  <input type="radio" id="cmd_0010" name="cmd_0010"  formControlName="cmd_0010" value="1">Yes</label>
                                  <label class="checkbox-inline">
                                  <input type="radio" id="cmd_0010" name="cmd_0010"  formControlName="cmd_0010" value="0">No</label>
                                  
                              </div>
                        </div>
                    </td>
                </tr>   
                <tr>
                    <td>
                        <div class="row">
                            <div class="form-group col-md-9">
                                <label style="font-weight: normal;font-size: 14px"> Since your last appointment have you incurred any other type of professional sanction (i.e. Medicare,
                                    Medicaid, etc)?
                                </label>
                            </div>
                            <div class="col-md-3">
                                <label class="checkbox-inline">
                                  <input type="radio" id="cmd_0011" name="cmd_0011"  formControlName="cmd_0011" value="1">Yes</label>
                                  <label class="checkbox-inline">
                                  <input type="radio" id="cmd_0011" name="cmd_0011"  formControlName="cmd_0011" value="0">No</label>
                                  
                              </div>
                        </div>
                    </td>
                </tr>     
                <tr>
                    <th>
                        <div class="text-center">
                            <b id="subhead">REFERENCES</b>
                        </div>
                    </th>
                </tr>
                <tr>
                    <td>
                        <div class="row">
                            <div class="col-md-12">
                                <label style="font-weight: normal;font-size: 14px">List the names of at least three (3) professional references from your discipline, only one of whom may be
                                    from your practice group, who have worked with you and can provide personal knowledge, gained through
                                    clinical interaction, of your professional practice over a reasonable period of time. At least two of the
                                    references must be in the same specialty and one must have had organizational responsibility for your
                                    performance. None of the individuals should be related to you by family (New Graduates--please use
                                    Supervising Instructors)
                                </label>
                            </div>
                        </div>
                    </td>
                </tr>  
                <tr>
                    <td>
                        <div class="row">
                            <div class="col-md-2">
                                <label style="font-weight: normal;font-size: 14px">1.</label> <label style="font-weight: normal;font-size: 14px;padding-left:30px;">Name</label>
                            </div>                                                         
                            <div class="col-md-4"> 
                                <input class="form-control onboard"  formControlName="359"
                                    id="359" name="359" type="text" placeholder= "Reference 1 Name"  data-id="managing_contact_name" >
                               
                            </div>
                            <div class="col-md-1">
                                <label style="font-weight: normal;font-size: 14px">Specialty</label> 
                            </div>
                            <div class="col-md-4">
                                <ng-select
                                [items]="specialty"
                                    bindLabel="name"
                                    placeholder="Enter Specialty"
                                    bindValue="id"
                                    formControlName="368"
                                    
                                    >
                                </ng-select> 
                                
                            </div>    
                        </div>
                    </td>
                </tr>  
                <tr>
                    <td>
                        <div class="row" style="padding-left:40px;">
                           
                            <div class="col-md-2">
                                <label style="font-weight: normal;font-size: 14px">Complete Address</label>
                            </div> 
                            <div class="col-md-2"> 
                                <input class="form-control onboard"  formControlName="360"
                                    id="360" name="360" type="text" placeholder= "Reference 1 Add1"  data-id="managing_contact_name" >
                                
                            </div>
                            <div class="col-md-2"> 
                                <input class="form-control onboard"  formControlName="361"
                                    id="361" name="361" type="text" placeholder= "Reference 1 Add2"  data-id="managing_contact_name" >
                                
                            </div>
                            <div class="col-md-2"> 
                                <input class="form-control onboard"  formControlName="362"
                                    id="362" name="362" type="text" placeholder= "Reference 1 City"  data-id="managing_contact_name" >
                                
                            </div>
                            <div class="col-2 pl-0">
                                <ng-select
                                    [items]="states"
                                    bindLabel="name"
                                    placeholder="Enter State"
                                    bindValue="id"
                                    formControlName="364"
                                    
                                    >
                                </ng-select> 
                               
                            </div>  
                            <div class="col-md-2"> 
                                <input class="form-control onboard"  formControlName="365"
                                    id="365" name="365" type="text" placeholder= "Reference 1 Zip"  data-id="managing_contact_name" >
                                
                            </div>
                        </div>
                    </td>
                </tr>   
                <tr>
                    <td>
                        <div class="row" style="padding-left:40px;">
                            <div class="col-md-2">
                                <label style="font-weight: normal;font-size: 14px">Telephone</label>
                            </div> 
                            <div class="col-md-2"> 
                                <input class="form-control onboard"  formControlName="369"
                                    id="369" name="369" type="text" placeholder= "Reference 1 Phone "  data-id="managing_contact_name" >
                                
                            </div>  
                            <div class="col-md-1">
                                <label style="font-weight: normal;font-size: 14px">Fax #</label>
                            </div> 
                            <div class="col-md-2"> 
                                <input class="form-control onboard"  formControlName="370"
                                    id="370" name="370" type="text" placeholder= "Reference 1 Fax "   data-id="managing_contact_name" >
                               
                            </div>  
                            <div class="col-md-2">
                                <label style="font-weight: normal;font-size: 14px">No. of years known</label>
                            </div> 
                            <div class="col-md-3"> 
                                <input class="form-control onboard"  formControlName="4242"
                                    id="4242" name="4242" type="text" placeholder= "Rerence 1 Length of Acquaintance "   data-id="managing_contact_name" >
                                
                            </div>  
                        </div>
                    </td>
                </tr>   
                <tr>
                    <td>
                        <div class="row" style="padding-left:40px;">
                            <div class="col-md-3">
                                <label style="font-weight: normal;font-size: 14px">E-mail address (if available)</label>
                            </div> 
                            <div class="col-md-4"> 
                                <input class="form-control onboard"  formControlName="371"
                                    id="371" name="371" type="text" placeholder= "Reference 1 Email "   data-id="managing_contact_name" >
                                
                            </div>  
                        </div>
                    </td>
                </tr>    
                <tr>
                    <td>
                        <div class="row">
                            <div class="col-md-2">
                                <label style="font-weight: normal;font-size: 14px">2.</label> <label style="font-weight: normal;font-size: 14px;padding-left:30px;">Name</label>
                            </div>                                                         
                            <div class="col-md-4"> 
                                <input class="form-control onboard"  formControlName="372"
                                    id="372" name="372" type="text" placeholder= "Reference 2 Name"  data-id="managing_contact_name" >
                               
                            </div>
                            <div class="col-md-1">
                                <label style="font-weight: normal;font-size: 14px">Specialty</label> 
                            </div>
                            <div class="col-md-4">
                                <ng-select
                                [items]="specialty"
                                    bindLabel="name"
                                    placeholder="Enter Specialty"
                                    bindValue="id"
                                    formControlName="380"
                                    
                                    >
                                </ng-select> 
                    
                            </div>    
                        </div>
                    </td>
                </tr>  
                <tr>
                    <td>
                        <div class="row" style="padding-left:40px;">
                           
                            <div class="col-md-2">
                                <label style="font-weight: normal;font-size: 14px">Complete Address</label>
                            </div> 
                            <div class="col-md-2"> 
                                <input class="form-control onboard"  formControlName="373"
                                    id="373" name="373" type="text" placeholder= "Reference 2 Add1"  data-id="managing_contact_name" >
                                
                            </div>
                            <div class="col-md-2"> 
                                <input class="form-control onboard"  formControlName="374"
                                    id="374" name="374" type="text" placeholder= "Reference 2 Add2"  data-id="managing_contact_name" >
                                
                            </div>
                            <div class="col-md-2"> 
                                <input class="form-control onboard"  formControlName="375"
                                    id="375" name="375" type="text" placeholder= "Reference 2 City"  data-id="managing_contact_name" >
                                
                            </div>
                            <div class="col-2 pl-0">
                                <ng-select
                                    [items]="states"
                                    bindLabel="name"
                                    placeholder="Enter State"
                                    bindValue="id"
                                    formControlName="377"
                                    
                                    >
                                </ng-select> 
                                
                            </div>  
                            <div class="col-md-2"> 
                                <input class="form-control onboard"  formControlName="378"
                                    id="378" name="378" type="text" placeholder= "Reference 2 Zip"  data-id="managing_contact_name" >
                                
                            </div>
                        </div>
                    </td>
                </tr>   
                <tr>
                    <td>
                        <div class="row" style="padding-left:40px;">
                            <div class="col-md-2">
                                <label style="font-weight: normal;font-size: 14px">Telephone</label>
                            </div> 
                            <div class="col-md-2"> 
                                <input class="form-control onboard"  formControlName="381"
                                    id="381" name="381" type="text" placeholder= "Reference 2 Phone "   data-id="managing_contact_name" >
                               
                            </div>  
                            <div class="col-md-1">
                                <label style="font-weight: normal;font-size: 14px">Fax #</label>
                            </div> 
                            <div class="col-md-2"> 
                                <input class="form-control onboard"  formControlName="382"
                                    id="382" name="382" type="text" placeholder= "Reference 2 Fax "   data-id="managing_contact_name" >
                               
                            </div>  
                            <div class="col-md-2">
                                <label style="font-weight: normal;font-size: 14px">No. of years known</label>
                            </div> 
                            <div class="col-md-3"> 
                                <input class="form-control onboard"  formControlName="4243"
                                    id="4243" name="4243" type="text" placeholder= "Reference 2 Length of Acquaintance "   data-id="managing_contact_name" >
                                
                            </div>  
                        </div>
                    </td>
                </tr>   
                <tr>
                    <td>
                        <div class="row" style="padding-left:40px;">
                            <div class="col-md-3">
                                <label style="font-weight: normal;font-size: 14px">E-mail address (if available)</label>
                            </div> 
                            <div class="col-md-4"> 
                                <input class="form-control onboard"  formControlName="383"
                                    id="383" name="383" type="text" placeholder= "Reference 2 Email "   data-id="managing_contact_name" >
                                
                            </div>  
                        </div>
                    </td>
                </tr>  
                <tr>
                    <td>
                        <div class="row">
                            <div class="col-md-2">
                                <label style="font-weight: normal;font-size: 14px">3.</label> <label style="font-weight: normal;font-size: 14px;padding-left:30px;">Name</label>
                            </div>                                                         
                            <div class="col-md-4"> 
                                <input class="form-control onboard"  formControlName="384"
                                    id="384" name="384" type="text" placeholder= "Reference 3 Name"  data-id="managing_contact_name" >
                                
                            </div>
                            <div class="col-md-1">
                                <label style="font-weight: normal;font-size: 14px">Specialty</label> 
                            </div>
                            <div class="col-md-4">
                                <ng-select
                                [items]="specialty"
                                    bindLabel="name"
                                    placeholder="Enter Specialty"
                                    bindValue="id"
                                    formControlName="393"
                                    
                                    >
                                </ng-select> 

                            </div>    
                        </div>
                    </td>
                </tr>    
                <tr>
                    <td>
                        <div class="row" style="padding-left:40px;">
                           
                            <div class="col-md-2">
                                <label style="font-weight: normal;font-size: 14px">Complete Address</label>
                            </div> 
                            <div class="col-md-2"> 
                                <input class="form-control onboard"  formControlName="385"
                                    id="385" name="385" type="text" placeholder= "Reference 3 Add1"  data-id="managing_contact_name" >
                                
                            </div>
                            <div class="col-md-2"> 
                                <input class="form-control onboard"  formControlName="386"
                                    id="386" name="386" type="text" placeholder= "Reference 3 Add2"  data-id="managing_contact_name" >
                                
                            </div>
                            <div class="col-md-2"> 
                                <input class="form-control onboard"  formControlName="387"
                                    id="387" name="387" type="text" placeholder= "Reference 3 City"  data-id="managing_contact_name" >
                              
                            </div>
                            <div class="col-2 pl-0">
                                <ng-select
                                    [items]="states"
                                    bindLabel="name"
                                    placeholder="Enter State"
                                    bindValue="id"
                                    formControlName="389"
                                    
                                    >
                                </ng-select> 
                                
                            </div>  
                            <div class="col-md-2"> 
                                <input class="form-control onboard"  formControlName="390"
                                    id="390" name="390" type="text" placeholder= "Reference 3 Zip"  data-id="managing_contact_name" >
                                
                            </div>
                        </div>
                    </td>
                </tr>   
                <tr>
                    <td>
                        <div class="row" style="padding-left:40px;">
                            <div class="col-md-2">
                                <label style="font-weight: normal;font-size: 14px">Telephone</label>
                            </div> 
                            <div class="col-md-2"> 
                                <input class="form-control onboard"  formControlName="394"
                                    id="394" name="394" type="text" placeholder= "Reference 3 Phone "   data-id="managing_contact_name" >
                                
                            </div>  
                            <div class="col-md-1">
                                <label style="font-weight: normal;font-size: 14px">Fax #</label>
                            </div> 
                            <div class="col-md-2"> 
                                <input class="form-control onboard"  formControlName="395"
                                    id="395" name="395" type="text" placeholder= "Reference 3 Fax "   data-id="managing_contact_name" >
                                
                            </div>  
                            <div class="col-md-2">
                                <label style="font-weight: normal;font-size: 14px">No. of years known</label>
                            </div> 
                            <div class="col-md-3"> 
                                <input class="form-control onboard"  formControlName="4244"
                                    id="4244" name="4244" type="text" placeholder= "Rerence 3 Length of Acquaintance "   data-id="managing_contact_name" >
                                
                            </div>  
                        </div>
                    </td>
                </tr>   
                <tr>
                    <td>
                        <div class="row" style="padding-left:40px;">
                            <div class="col-md-3">
                                <label style="font-weight: normal;font-size: 14px">E-mail address (if available)</label>
                            </div> 
                            <div class="col-md-4"> 
                                <input class="form-control onboard"  formControlName="396"
                                    id="396" name="396" type="text" placeholder= "Reference 3 Email "   data-id="managing_contact_name" >
                                
                            </div>  
                        </div>
                    </td>
                </tr>  
                <tr>
                    <td>
                        <div class="row">
                            <div class="col-md-2">
                                <label style="font-weight: normal;font-size: 14px">4.</label> <label style="font-weight: normal;font-size: 14px;padding-left:30px;">Name</label>
                            </div>                                                         
                            <div class="col-md-4"> 
                                <input class="form-control onboard"  formControlName="1681"
                                    id="1681" name="1681" type="text" placeholder= "Reference 4 Name"  data-id="managing_contact_name" >
                                
                            </div>
                            <div class="col-md-1">
                                <label style="font-weight: normal;font-size: 14px">Specialty</label> 
                            </div>
                            <div class="col-md-4">
                                <ng-select
                                [items]="specialty"
                                    bindLabel="name"
                                    placeholder="Enter Specialty"
                                    bindValue="id"
                                    formControlName="1691"
                                    
                                    >
                                </ng-select> 
                               
                            </div>    
                        </div>
                    </td>
                </tr>    
                <tr>
                    <td>
                        <div class="row" style="padding-left:40px;">
                           
                            <div class="col-md-2">
                                <label style="font-weight: normal;font-size: 14px">Complete Address</label>
                            </div> 
                            <div class="col-md-2"> 
                                <input class="form-control onboard"  formControlName="1682"
                                    id="1682" name="1682" type="text" placeholder= "Reference 4 Add1"  data-id="managing_contact_name" >
                                
                            </div>
                            <div class="col-md-2"> 
                                <input class="form-control onboard"  formControlName="1683"
                                    id="1683" name="1683" type="text" placeholder= "Reference 4 Add2"  data-id="managing_contact_name" >
                               
                            </div>
                            <div class="col-md-2"> 
                                <input class="form-control onboard"  formControlName="1684"
                                    id="1684" name="1684" type="text" placeholder= "Reference 4 City"  data-id="managing_contact_name" >
                                
                            </div>
                            <div class="col-2 pl-0">
                                <ng-select
                                    [items]="states"
                                    bindLabel="name"
                                    placeholder="Enter State"
                                    bindValue="id"
                                    formControlName="1686"
                                    
                                    >
                                </ng-select> 
                                
                            </div>  
                            <div class="col-md-2"> 
                                <input class="form-control onboard"  formControlName="1687"
                                    id="1687" name="1687" type="text" placeholder= "Reference 3 Zip"  data-id="managing_contact_name" >
                                
                            </div>
                        </div>
                    </td>
                </tr>   
                <tr>
                    <td>
                        <div class="row" style="padding-left:40px;">
                            <div class="col-md-2">
                                <label style="font-weight: normal;font-size: 14px">Telephone</label>
                            </div> 
                            <div class="col-md-2"> 
                                <input class="form-control onboard"  formControlName="1692"
                                    id="1692" name="1692" type="text" placeholder= "Reference 4 Phone "   data-id="managing_contact_name" >
                                
                            </div>  
                            <div class="col-md-1">
                                <label style="font-weight: normal;font-size: 14px">Fax #</label>
                            </div> 
                            <div class="col-md-2"> 
                                <input class="form-control onboard"  formControlName="1693"
                                    id="1693" name="1693" type="text" placeholder= "Reference 4 Fax "   data-id="managing_contact_name" >
                                
                            </div>  
                            <div class="col-md-2">
                                <label style="font-weight: normal;font-size: 14px">No. of years known</label>
                            </div> 
                            <div class="col-md-3"> 
                                <input class="form-control onboard"  formControlName="4245"
                                    id="4245" name="4245" type="text" placeholder= "Rerence 4 Length of Acquaintance "   data-id="managing_contact_name" >
                            
                            </div>  
                        </div>
                    </td>
                </tr>   
                <tr>
                    <td>
                        <div class="row" style="padding-left:40px;">
                            <div class="col-md-3">
                                <label style="font-weight: normal;font-size: 14px">E-mail address (if available)</label>
                            </div> 
                            <div class="col-md-4"> 
                                <input class="form-control onboard"  formControlName="1694"
                                    id="1694" name="1694" type="text" placeholder= "Reference 4 Email "   data-id="managing_contact_name" >
                                
                            </div>  
                        </div>
                    </td>
                </tr>  
                <tr>
                    <td>
                        <div class="row">
                            <div class="col-md-12">
                                <label style="font-weight: normal;font-size: 15px"><b id="subhead">MALPRACTICE INSURANCE</b></label> 
                            </div>
                            <br>
                            <div class="col-md-12">
                                <label style="font-weight: normal;font-size: 14px"><b>(Submit a copy of current malpractice certificate)</b></label> 
                            </div>
                            
                        </div>    
                    </td> 
                </tr>
                <tr>
                    <td>
                        <div class="row">      
                            <div class="col-md-3">
                                <label style="font-weight: normal;font-size: 14px">Current Insurance Carrier</label>
                            </div>  
                            <div class="col-md-4"> 
                                <input class="form-control onboard"  formControlName="345"
                                    id="345" name="345" type="text" placeholder= "MLP Carrier Name"   data-id="managing_contact_name" >
                                
                            </div>  
                        </div>
                    </td>    
                </tr>       
                <tr>
                    <td>
                        <div class="row">
                           
                            <div class="col-md-2">
                                <label style="font-weight: normal;font-size: 14px">Complete Address</label>
                            </div> 
                            <div class="col-md-2"> 
                                <input class="form-control onboard"  formControlName="901"
                                    id="901" name="901" type="text" placeholder= "MLP Add1"  data-id="managing_contact_name" >
                                
                            </div>
                            <div class="col-md-2"> 
                                <input class="form-control onboard"  formControlName="902"
                                    id="902" name="902" type="text" placeholder= "MLP Add2"  data-id="managing_contact_name" >
                               
                            </div>
                            <div class="col-md-2"> 
                                <input class="form-control onboard"  formControlName="903"
                                    id="903" name="903" type="text" placeholder= "MLP City"  data-id="managing_contact_name" >
                               
                            </div>
                            <div class="col-2 pl-0">
                                <ng-select
                                    [items]="states"
                                    bindLabel="name"
                                    placeholder="Enter State"
                                    bindValue="id"
                                    formControlName="905"
                                    
                                    >
                                </ng-select> 
                                
                            </div>  
                            <div class="col-md-2"> 
                                <input class="form-control onboard"  formControlName="906"
                                    id="906" name="906" type="text" placeholder= "MLP Zip"  data-id="managing_contact_name" >
                               
                            </div>
                        </div>
                    </td>
                </tr> 
                <tr>
                    <td>
                        <div class="row">
                            <div class="col-md-8">
                                <label style="font-weight: normal;font-size: 16px">List all previous insurance carriers and dates of coverage:</label> 
                            </div> 
                            
                        </div>    
                    </td>
                </tr>   
                <tr>
                    <td>
                        <div class="row">
                            <div class="col-md-2">
                                <label style="font-weight: normal;font-size: 14px">Insurance Carrier</label> 
                            </div> 
                            <div class="col-md-4">
                                <input class="form-control onboard"  formControlName="1242"
                                    id="1242" name="1242" type="text" placeholder= "Previous MLP Carriers"   data-id="managing_contact_name" >
                                
                            </div> 
                            <div class="col-md-2">
                                <label style="font-weight: normal;font-size: 14px">Policy #</label> 
                            </div> 
                            <div class="col-md-4">
                                <input class="form-control onboard"  formControlName="1256"
                                    id="1256" name="1256" type="text" placeholder= "Previous MLP Policy Number"   data-id="managing_contact_name" >
                                 
                            </div>   
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div class="row">
                            <div class="col-md-1">
                                <label style="font-weight: normal;font-size: 14px">Address</label> 
                            </div> 
                            <div class="col-md-2">
                                <input class="form-control onboard"  formControlName="1244"
                                    id="1244" name="1244" type="text" placeholder= "Previous MLP Add1"   data-id="managing_contact_name" >
                                
                            </div> 
                            <div class="col-md-2">
                                <input class="form-control onboard"  formControlName="1245"
                                    id="1245" name="1245" type="text" placeholder= "Previous MLP Add2"   data-id="managing_contact_name" >
                                
                            </div>  
                            <div class="col-md-2">
                                <input class="form-control onboard"  formControlName="1246"
                                    id="1246" name="1246" type="text" placeholder= "Previous MLP City"   data-id="managing_contact_name" >
                                 
                            </div>                    
                            <div class="col-md-2">
                                <ng-select 
                                [items]="states"
                                
                                bindLabel="name"
                                placeholder="Enter State"
                                bindValue="id"
                                formControlName="1248"
                                >
                                </ng-select>
                                
                            </div>  
                            <div class="col-md-2">
                                <input class="form-control onboard"  formControlName="1249"
                                    id="1249" name="1249" type="text" placeholder= "Previous MLP Zip"   data-id="managing_contact_name" >
                                
                            </div> 
                            
                        </div>     
                    </td>
                </tr>
                <tr>
                    <td>
                        <div class="row">
                            <div class="col-md-2">
                                <label style="font-weight: normal;font-size: 14px">Dates</label> 
                            </div>
                            <div class="col-md-4">
                                <div class="input-group">
                                    <input type="text"   placeholder= "Previous MLP Effective Date" id="1252" (blur)="clearInputIfInvalid('1252')" class="form-control" formControlName="1252"
                                    ngbDatepicker #d1252="ngbDatepicker" >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d1252.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                                </div>
                                
                                <div  *ngIf="NewManAlliedForms.get('1252').invalid ">
                                    <small class="text-danger" *ngIf="NewManAlliedForms.get('1252').errors?.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="NewManAlliedForms.get('1252').errors?.invalidMonth">
                                    Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="NewManAlliedForms.get('1252').errors?.invalidDateyear">
                                      year is out of range(1900-2099)                      </small>
                                      <small class="text-danger" *ngIf="NewManAlliedForms.get('1252').errors?.invalidDateRange">
                                        date is out of range
                                        </small>
                                    </div>
                                                                      
                            </div>      
                            <div class="col-md-4">
                                <div class="input-group">
                                    <input type="text"  placeholder= "Previous MLP Expiration Date" id="1253" (blur)="clearInputIfInvalid('1253')" class="form-control" formControlName="1253"
                                    ngbDatepicker #d1253="ngbDatepicker" >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d1253.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                                </div>      
                                
                                <div  *ngIf="NewManAlliedForms.get('1253').invalid ">
                                    <small class="text-danger" *ngIf="NewManAlliedForms.get('1253').errors?.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="NewManAlliedForms.get('1253').errors?.invalidMonth">
                                    Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="NewManAlliedForms.get('1253').errors?.invalidDateyear">
                                      year is out of range(1900-2099)                      </small>
                                      <small class="text-danger" *ngIf="NewManAlliedForms.get('1253').errors?.invalidDateRange">
                                        date is out of range
                                        </small>
                                    </div>
                                                                     
                            </div>      
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div class="row">
                            <div class="col-md-2">
                                <label style="font-weight: normal;font-size: 14px">Insurance Carrier</label> 
                            </div> 
                            <div class="col-md-4">
                                <input type="text" placeholder="Previous MLP1 Carrier" id="add_1_1242" formControlName="add_1_1242" class="form-control">
                            </div>
                            <div class="col-md-2">
                                <label style="font-weight: normal;font-size: 14px">Policy #</label> 
                            </div> 
                            <div class="col-md-4">
                                <input type="text" placeholder= "Previous MLP1 Policy Number" id="add_1_1256" formControlName="add_1_1256" class="form-control">
                            </div>
                        </div>    
                    </td>
                </tr>
                <tr>
                    <td>
                        <div class="row">
                            <div class="col-md-1">
                                <label style="font-weight: normal;font-size: 14px">Address</label> 
                            </div> 
                            <div class="col-md-2">
                                <input type="text" placeholder= "Previous MLP1 Add1" id="add_1_1244" formControlName="add_1_1244" class="form-control">
                            </div>
                            <div class="col-md-2">
                                <input type="text" placeholder= "Previous MLP1 Add2" id="add_1_1245" formControlName="add_1_1245" class="form-control">
                            </div>
                            <div class="col-md-2">
                                <input type="text" placeholder= "Previous MLP1 City" id="add_1_1246" formControlName="add_1_1246" class="form-control">
                            </div>
                            <div class="col-2 pl-0">
                                <ng-select
                                        [items]="states"
                                        bindLabel="name"
                                        placeholder="Enter State"
                                        bindValue="id"
                                        formControlName="add_1_1248"
                                        
                                        >
                                </ng-select>                                 
                            </div> 
                            <div class="col-md-2">
                                <input type="text" placeholder= "Previous MLP1 Zip" id="add_1_1249" formControlName="add_1_1249" class="form-control">
                            </div>
                        </div>
                    </td>
                </tr>  
                <tr>
                    <td>
                        <div class="row">
                            <div class="col-md-2">
                                <label style="font-weight: normal;font-size: 14px">Dates</label> 
                            </div>
                            <div class="col-md-4">
                                <div class="input-group">
                                    <input type="text"   placeholder= "Previous MLP1 Effective Date" id="add_1_1252" (blur)="clearInputIfInvalid('add_1_1252')" class="form-control" formControlName="add_1_1252"
                                    ngbDatepicker #dadd_1_1252="ngbDatepicker" >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="dadd_1_1252.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                                </div>       
                                
                                <div  *ngIf="NewManAlliedForms.get('add_1_1252').invalid ">
                                    <small class="text-danger" *ngIf="NewManAlliedForms.get('add_1_1252').errors?.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="NewManAlliedForms.get('add_1_1252').errors?.invalidMonth">
                                    Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="NewManAlliedForms.get('add_1_1252').errors?.invalidDateyear">
                                      year is out of range(1900-2099)                      </small>
                                      <small class="text-danger" *ngIf="NewManAlliedForms.get('add_1_1252').errors?.invalidDateRange">
                                        date is out of range
                                        </small>
                                    </div>
                            </div> 
                            <div class="col-md-4">
                                <div class="input-group">
                                    <input type="text"   placeholder= "Previous MLP1 Expiration Date" id="add_1_1253" class="form-control" formControlName="add_1_1253" (blur)="clearInputIfInvalid('add_1_1253')"
                                    ngbDatepicker #dadd_1_1253="ngbDatepicker" >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="dadd_1_1253.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                                </div>   
                                <div  *ngIf="NewManAlliedForms.get('add_1_1253').invalid ">
                                    <small class="text-danger" *ngIf="NewManAlliedForms.get('add_1_1253').errors?.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="NewManAlliedForms.get('add_1_1253').errors?.invalidMonth">
                                    Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="NewManAlliedForms.get('add_1_1253').errors?.invalidDateyear">
                                      year is out of range(1900-2099)                      </small>
                                      <small class="text-danger" *ngIf="NewManAlliedForms.get('add_1_1253').errors?.invalidDateRange">
                                        date is out of range
                                        </small>
                                    </div>                                                                   
                            </div>    
                                    
                        </div>
                    </td>
                </tr> 
                <tr>
                    <td>
                        <div class="row">
                            <div class="col-md-2">
                                <label style="font-weight: normal;font-size: 14px">Insurance Carrier</label> 
                            </div> 
                            <div class="col-md-4">
                                <input type="text" placeholder="Previous MLP2 Carrier" id="add_2_1242" formControlName="add_2_1242" class="form-control">
                            </div>
                            <div class="col-md-2">
                                <label style="font-weight: normal;font-size: 14px">Policy #</label> 
                            </div> 
                            <div class="col-md-4">
                                <input type="text" placeholder= "Previous MLP2 Policy Number" id="add_2_1256" formControlName="add_2_1256" class="form-control">
                            </div>
                        </div>    
                    </td>
                </tr>
                <tr>
                    <td>
                        <div class="row">
                            <div class="col-md-1">
                                <label style="font-weight: normal;font-size: 14px">Address</label> 
                            </div> 
                            <div class="col-md-2">
                                <input type="text" placeholder= "Previous MLP2 Add1" id="add_2_1244" formControlName="add_2_1244" class="form-control">
                            </div>
                            <div class="col-md-2">
                                <input type="text" placeholder= "Previous MLP2 Add2" id="add_2_1245" formControlName="add_2_1245" class="form-control">
                            </div>
                            <div class="col-md-2">
                                <input type="text" placeholder= "Previous MLP2 City" id="add_2_1246" formControlName="add_2_1246" class="form-control">
                            </div>
                            <div class="col-2 pl-0">
                                <ng-select
                                        [items]="states"
                                        bindLabel="name"
                                        placeholder="Enter State"
                                        bindValue="id"
                                        formControlName="add_2_1248"
                                        
                                        >
                                </ng-select>                                 
                            </div> 
                            <div class="col-md-2">
                                <input type="text" placeholder= "Previous MLP2 Zip" id="add_2_1249" formControlName="add_2_1249" class="form-control">
                            </div>
                        </div>
                    </td>
                </tr>  
                <tr>
                    <td>
                        <div class="row">
                            <div class="col-md-2">
                                <label style="font-weight: normal;font-size: 14px">Dates</label> 
                            </div>
                            <div class="col-md-4">
                                <div class="input-group">
                                    <input type="text"   placeholder= "Previous MLP2 Effective Date" id="add_2_1252" class="form-control" formControlName="add_2_1252"   (blur)="clearInputIfInvalid('add_2_1252')"
                                    ngbDatepicker #dadd_2_1252="ngbDatepicker" >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="dadd_2_1252.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                                </div>  
                                <div  *ngIf="NewManAlliedForms.get('add_2_1252').invalid ">
                                    <small class="text-danger" *ngIf="NewManAlliedForms.get('add_2_1252').errors?.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="NewManAlliedForms.get('add_2_1252').errors?.invalidMonth">
                                    Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="NewManAlliedForms.get('add_2_1252').errors?.invalidDateyear">
                                      year is out of range(1900-2099)                      </small>
                                      <small class="text-danger" *ngIf="NewManAlliedForms.get('add_2_1252').errors?.invalidDateRange">
                                        date is out of range
                                        </small>
                                    </div>                                                                    
                            </div> 
                            <div class="col-md-4">
                                <div class="input-group">
                                    <input type="text"   placeholder= "Previous MLP2 Expiration Date" id="add_2_1253" class="form-control" formControlName="add_2_1253" (blur)="clearInputIfInvalid('add_2_1253')"
                                    ngbDatepicker #dadd_2_1253="ngbDatepicker" >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="dadd_2_1253.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                                </div>
                                <div  *ngIf="NewManAlliedForms.get('add_2_1253').invalid ">
                                    <small class="text-danger" *ngIf="NewManAlliedForms.get('add_2_1253').errors?.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="NewManAlliedForms.get('add_2_1253').errors?.invalidMonth">
                                    Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="NewManAlliedForms.get('add_2_1253').errors?.invalidDateyear">
                                      year is out of range(1900-2099)                      </small>
                                      <small class="text-danger" *ngIf="NewManAlliedForms.get('add_2_1253').errors?.invalidDateRange">
                                        date is out of range
                                        </small>
                                    </div>                                                                      
                            </div>    
                                    
                        </div>
                    </td>
                </tr>   
                <tr>
                    <td>
                        <div class="row">
                            <div class="form-group col-md-9">
                                <label style="font-weight: normal;font-size: 14px"> Have there been, or are there currently pending, any malpractice claims, suits, settlements or arbitration
                                    proceedings involving your professional practice?
                                </label>
                            </div>
                            <div class="col-md-3">
                                <label class="checkbox-inline">
                                  <input type="radio" id="cmd_0012" name="cmd_0012"  formControlName="cmd_0012" value="1">Yes</label>
                                  <label class="checkbox-inline">
                                  <input type="radio" id="cmd_0012" name="cmd_0012"  formControlName="cmd_0012" value="0">No</label>
                                  
                              </div>
                        </div>
                    </td>
                </tr>         
                <tr>
                    <td>
                        <div class="row">
                            <div class="form-group col-md-9">
                                <label style="font-weight: normal;font-size: 14px">Have you experienced the cancellation or denial of professional liability insurance in the last ten years? <br> (If yes, please attach letter of explanation.)
                                </label>
                            </div>
                            <div class="col-md-3">
                                <label class="checkbox-inline">
                                  <input type="radio" id="cmd_0013" name="cmd_0013"  formControlName="cmd_0013" value="1">Yes</label>
                                  <label class="checkbox-inline">
                                  <input type="radio" id="cmd_0013" name="cmd_0013"  formControlName="cmd_0013" value="0">No  </label>
                                 
                              </div>
                        </div>
                    </td>
                </tr>   
                <tr>
                    <td>
                        <div class="row">
                            <div class="form-group col-md-9">
                                <label style="font-weight: normal;font-size: 14px">Have there been any adverse malpractice judgments or claim settlements in the last ten years that were
                                    paid by an employer, insurance carrier or other entity? (If yes, please attach letter of explanation.)
                                </label>
                            </div>
                            <div class="col-md-3">
                                <label class="checkbox-inline">
                                  <input type="radio" id="cmd_0014" name="cmd_0014" formControlName="cmd_0014" value="1">Yes</label>
                                  <label class="checkbox-inline">
                                  <input type="radio" id="cmd_0014" name="cmd_0014"  formControlName="cmd_0014" value="0">No  </label>
                                  
                              </div>
                        </div>
                    </td>
                </tr> 
                <tr>
                    <td>
                        <div class="row">
                            <div class="form-group col-md-9">
                                <label style="font-weight: normal;font-size: 14px">Has there been any payment of personal funds to settle any actual or potential malpractice claims in the
                                    last ten years that did not involve a payment by an insurance carrier or other entity?(If yes, please attach letter of explanation.)
                                </label>
                            </div>
                            <div class="col-md-3">
                                <label class="checkbox-inline">
                                  <input type="radio" id="cmd_0015" name="cmd_0015" formControlName="cmd_0015" value="1">Yes</label>
                                  <label class="checkbox-inline">
                                  <input type="radio" id="cmd_0015" name="cmd_0015"  formControlName="cmd_0015" value="0">No  </label>
                                  
                              </div>
                        </div>
                    </td>
                </tr>   
                <tr>
                    <th>
                        <div class="text-center">
                            <b id="subhead">HEALTH STATUS</b>
                        </div>
                    </th>
                </tr>
                <tr>
                    <td>
                        <div class="row">
                            <div class="form-group col-md-9">
                                <label style="font-weight: normal;font-size: 14px">Are you able both mentally and physically to perform all procedures for which you have requested privileges,
                                    with or without reasonable accommodation, according to accepted standards of professional performance
                                    without posing a threat to patients? 
                                </label>
                            </div>
                            <div class="col-md-3">
                                <label class="checkbox-inline">
                                  <input type="radio" id="cmd_0016" name="cmd_0016"  formControlName="cmd_0016" value="1">Yes</label>
                                  <label class="checkbox-inline">
                                  <input type="radio" id="cmd_0016" name="cmd_0016"  formControlName="cmd_0016" value="0">No  </label>
                                  
                              </div>
                        </div>
                    </td>
                </tr>    
                <tr>
                    <td>
                        <div class="row">
                            <div class="col-md-12">
                                <label style="font-weight: normal;font-size: 15px"><b id="subheadings"><u>(If you will require reasonable accommodation, please use a separate sheet to describe the
                                    accommodation(s) which will enable you to perform the privileges you have requested)</u></b></label> 
                            </div>
                        </div> 
                    </td>
                </tr>  
                <tr>
                    <th>
                        <div class="text-center">
                            <b id="subhead">LEGAL COMPLIANCE</b>
                        </div>
                    </th>
                </tr> 
                <tr>
                    <td>
                        <div class="row">
                            <div class="form-group col-md-9">
                                <label style="font-weight: normal;font-size: 14px"> Are you, directly or indirectly, a party to any employment or compensation with relationship or investment
                                    interest in any hospital, health system or other health care provider?
                                </label>
                            </div>
                            <div class="col-md-3">
                                <label class="checkbox-inline">
                                  <input type="radio" id="cmd_0017" name="cmd_0017"  formControlName="cmd_0017" value="1">Yes</label>
                                  <label class="checkbox-inline">
                                  <input type="radio" id="cmd_0017" name="cmd_0017"  formControlName="cmd_0017" value="0">No</label>
                                  
                            </div>
                        </div>
                    </td>
                </tr>   
                <tr>
                    <td>
                        <div class ="row">
                            <div class="form-group col-md-9">
                                <label style="font-weight: normal;font-size: 14px"> If yes, with whom, please explain
                                </label>
                            </div> 
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div class ="col-md-12">
                            <textarea 
                            class="form-control" name="cmd_0018" id="cmd_0018" formControlName="cmd_0018" cols="30"
                            rows="10"></textarea>
                            
                        </div>
                    </td>
                </tr>  
                <tr>
                    <td>
                        <div class="row">
                            <div class="col-md-2" style="padding-left:60px">
                                <label (click)="esignOpenOne()">Signature:</label>
                            </div>  
                            <div class="col-md-4">
                                    <img *ngIf="!showOne" class="img" src="./assets/images/e_sign.png" alt="Credential my Doc"
                                (click)="esignOpenOne()" />
                                    <img *ngIf="showOne" class="img eSignImageShow" [src]="esignOne" alt="Credential my Doc"
                                (click)="esignOpenOne()" />
                                    <input type="hidden" name="cmd_0019" id="cmd_0019" [class.is-invalid]="(NewManAlliedForms.controls['cmd_0019'].touched && NewManAlliedForms.controls['cmd_0019'].invalid) || (NewManAlliedForms.controls['cmd_0019'].invalid && isSubmit)" formControlName="cmd_0019" value="{{ finalImgPath }}" />
                                    <div class="invalid-feedback"
                                        *ngIf="(NewManAlliedForms.controls['cmd_0019'].touched && NewManAlliedForms.controls['cmd_0019'].invalid) || (NewManAlliedForms.controls['cmd_0019'].invalid && isSubmit)">
                                             Signature is required and cannot be empty
                                    </div>                                   
                                    
                            </div>
                            <div class="col-md-1">
                                <label style="font-weight: normal;font-size: 14px">Date:</label>
                            </div>
                            <div class="col-md-3">
                                <div class="input-group">
                                        <input type="text" placeholder="Enter Date" id="cmd_0020" (blur)="clearInputIfInvalidMandi('cmd_0020')" class="form-control" formControlName="cmd_0020"
                                        [class.is-invalid]="(NewManAlliedForms.controls['cmd_0020'].touched && NewManAlliedForms.controls['cmd_0020'].invalid ) || (NewManAlliedForms.controls['cmd_0020'].invalid && isSubmit)" ngbDatepicker #d0020="ngbDatepicker">
                                        <div class="input-group-append">
                                            <button class="btn btn-outline-secondary calendar" (click)="d0020.toggle()" type="button">
                                                <i class="fas fa-calendar-alt"></i>
                                            </button>
                                        </div>
                                </div>    
                                <div class="text-danger" *ngIf="(NewManAlliedForms.controls['cmd_0020'].invalid&&NewManAlliedForms.controls['cmd_0020'].touched) && (NewManAlliedForms.controls['cmd_0020'].value==''|| NewManAlliedForms.controls['cmd_0020'].value==null) " >
                                    <small >
                                        Date is required and cannot be empty
                                    </small>
                  
                                </div>
                                  <small class="text-danger" *ngIf="NewManAlliedForms.get('cmd_0020').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY)                </small>
                                  <small class="text-danger" *ngIf="NewManAlliedForms.get('cmd_0020').errors?.invalidMonth">
                                  Month is out of range
                                  </small>
                                  <small class="text-danger" *ngIf="NewManAlliedForms.get('cmd_0020').errors?.invalidDateyear">
                                    year is out of range(1900-2099)                  </small>
                                    <small class="text-danger" *ngIf="NewManAlliedForms.get('cmd_0020').errors?.invalidDateRange">
                                      date is out of range
                                      </small>
                            
                                <!-- <div class="invalid-feedback"
                                    *ngIf="(NewManAlliedForms.controls['cmd_0020'].touched && NewManAlliedForms.controls['cmd_0020'].invalid) || (NewManAlliedForms.controls['cmd_0020'].invalid && isSubmit)">
                                    Date is required and cannot be empty
                                </div> -->
                            </div>
                        </div>    
                    </td>
                </tr>          
            </tbody>                
        </table>                

        
            
            
        
    </form>


    <div class="modal-footer mt-4">
        <button *ngIf="formData.receivedForm" type="button" class="btn customOrange float-right"
        (click)="saveForm('approve')">Approve</button>
        <button *ngIf="formData.submitForm" type="submit" class="btn btn customOrange float-right"
        (click)="saveForm('submit')">Submit</button>
        <button *ngIf="formData.submitForm" type="button" class="btn btn customOrange float-right"
        (click)="saveForm('save')">Save</button>
        <button *ngIf="formData.previewForm" type="button" class="btn customOrange float-right" (click)="close()">Close</button>
    </div>
</div>    

    