import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from '../../services/common/common.service';

@Component({
  selector: 'ctg-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent implements OnInit {
  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  constructor(
    public activeModal: NgbActiveModal,
    private commonService: CommonService
  ) { }
  @Input() popupType
  
  public readOnlyUser: boolean = false;
  ngOnInit(): void {
    // // console.log(this.popupType);
    this.commonService.currentUserPermission.subscribe(data => {
      if (data) {
        if (data && data.permissions == 1) {
          this.readOnlyUser = true
        }
      }
    }
    )
  }
  
  onSave(data) {
    // this.passEntry.emit(data)
    this.activeModal.close('yes');
  }
}
