import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, FormControl, ValidationErrors } from '@angular/forms';
import { OnboardingformsServiceService } from '../../services/onboardingforms-service/onboardingforms-service.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToasterNotiService } from '../../services/notifications/toaster-noti.service';
import { ChangeDetectorRef } from '@angular/core';

import {
  NgbModal,
  NgbModalOptions,
  NgbDateAdapter,
  NgbDateParserFormatter
} from '@ng-bootstrap/ng-bootstrap';
import { EsignComponent } from '../esign/esign.component';
import { DomSanitizer } from '@angular/platform-browser';
import {
  CustomAdapter,
  CustomDateParserFormatter
} from '../../DateFormatter/customDate';
import { StorageService } from '../../services/storage/storage.service';

@Component({
  selector: 'hap-cred',
  templateUrl: './hap-cred.component.html',
  styleUrls: ['./hap-cred.component.scss'],
  providers: [
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }
  ]
})
export class HapCredComponent implements OnInit {
  


  constructor(
    private fb: UntypedFormBuilder,
    private onboardService: OnboardingformsServiceService,
    private spinner: NgxSpinnerService,
    private notiService: ToasterNotiService,
    private modalService: NgbModal,
    private storageService: StorageService,
    private _sanitizer: DomSanitizer,
    private cdr: ChangeDetectorRef,
  
  ) { }
  @Input() formData;
  @Input() fieldsData;
  @Input() formId;
  @Output() onSubmit = new EventEmitter();
  @Output() onClose = new EventEmitter();
  public hap: UntypedFormGroup;
  modalOption: NgbModalOptions = {
    size: 'md',
    keyboard: false,
    backdrop: 'static'
  };
  suffix: Array<object> = [];
  credentials: Array<object> = [];
  states: Array<object> = [];
  degree: Array<object> = [];
  specialty: Array<object> = [];
  gender: Array<object> = [];
  AltCdsStates: Array<object> = [];
  altLicenseState: Array<object> = [];
  additionalLicenseState: Array<object> = [];
  boardCertification: Array<object> = [];
  specialtySecondary: Array<object> = [];
  specialtyTer: Array<object> = [];
  staffStatus: Array<object> = [];
  preClaim: Array<object> = [];
  aggregates: Array<object> = [];
  county: Array<object> = [];
  finalImgPath: any = [];
  isSubmit: boolean = false;
  customerId: number=0;
  private emailPattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  private phonePattern =/^[0-9]*$/;

  firstName: any;
  lastName: any;
  finalName: any;

  // changeCmdThree:any;
  changeCmdThree1: any;
  changeCmdThree2: any;
  imgOneSetting: any;
  esignOne: any;
  showOne: any = false;
  ImgOne: any;
  finalImg: any;
  public licenseapp: boolean = false;
  public prospeapp: boolean = false;
  questionCounter = 0;
  indexKey = 0;
  ngOnInit(): void {
    this.customerId = Number(this.storageService.getItem('customerId'));
    this.buildForm();
    this.getCredentialsData();
    this.getGenderData();
    this.getSpecialtyData();
    this.getSuffixData();
    this.getDegreeData();
    this.getStatesData();
    this.getAltCdsStatesData();
    this.getAlternateLicenseStateData();
    this.getAdditionalLicenseStateData();
    this.getBoardCertificationData();
    this.getSpecialtySecondaryData();
    this.getStaffStatusData();
    this.getTerSpecialtyData();
    this.getPreClaimData();
    this.getAggregatesData();
    this.getCountyData();
    this.afterSaveDataDisplay();
    
    this.fieldsData && this.bindFormValue(this.fieldsData);
  }
  public getCredentialsData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getCredentialsData(params).subscribe(
      data => {
        if (data['success']) {
          this.credentials = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }
  public getSuffixData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getSuffixData(params).subscribe(
      data => {
        if (data['success']) {
          this.suffix = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }
  public getGenderData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getGenderData(params).subscribe(
      data => {
        if (data['success']) {
          this.gender = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }
  public getSpecialtyData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getSpecialtyData(params).subscribe(
      data => {
        if (data['success']) {
          this.specialty = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getCountyData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getCountyData(params).subscribe(
      data => {
        if (data['success']) {
          this.county = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getDegreeData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getDegreeData(params).subscribe(
      data => {
        if (data['success']) {
          this.degree = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getAggregatesData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getAggregatesData(params).subscribe(
      data => {
        if (data['success']) {
          this.aggregates = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }
 public  dateFormatValidator(control: FormControl): ValidationErrors | null {
    const dateString = control.value;
    const dateFormat = /^\d{2}\/\d{2}\/\d{4}$/;
  
    if (dateString && !dateString.match(dateFormat)) {
      return { 'invalidDateFormat': true };
    }
  
    return null;
  }

   public zipCodeValidator(control: FormControl): ValidationErrors | null {
    const zipCode = control.value;
    const zipCodePattern = /^\d{5,10}$/;
  
    if (zipCode && !zipCode.match(zipCodePattern)) {
      return { 'invalidZipCode': true };
    }
  
    return null;
  }
  public  phoneFormatValidator(control: FormControl): ValidationErrors | null {
    const phoneString = control.value;
    const phoneFormat = /^[0-9]*$/;
  
    if (phoneString && !phoneString.match(phoneFormat)) {
      return { 'invalidPhoneFormat': true };
    }
  
    return null;
  }
  public getStatesData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getStatesData(params).subscribe(
      data => {
        if (data['success']) {
          this.states = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }
   public getAltCdsStatesData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getAltCdsStatesData(params).subscribe(
      data => {
        if (data['success']) {
          this.AltCdsStates = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }
  public getAlternateLicenseStateData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getAlternateLicenseStateData(params).subscribe(
      data => {
        if (data['success']) {
          this.altLicenseState = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }
  public getAdditionalLicenseStateData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getAdditionalLicenseStateData(params).subscribe(
      data => {
        if (data['success']) {
          this.additionalLicenseState = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }
  public getBoardCertificationData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getBoardCertificationData(params).subscribe(
      data => {
        if (data['success']) {
          this.boardCertification = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }
  public getSpecialtySecondaryData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getSpecialtySecondaryData(params).subscribe(
      data => {
        if (data['success']) {
          this.specialtySecondary = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }
  public getStaffStatusData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getStaffStatusData(params).subscribe(
      data => {
        if (data['success']) {
          this.staffStatus = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }
  public getTerSpecialtyData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getTerSpecialtyData(params).subscribe(
      data => {
        if (data['success']) {
          this.specialtyTer = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }
  public getPreClaimData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getPreClaimData(params).subscribe(
      data => {
        if (data['success']) {
          this.preClaim = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }
  onChangeFirstName(event) {
    this.firstName = event.target.value;
    this.finalName = this.firstName + ' ' + this.lastName;
    let temp = {
      cmd_0897: this.finalName
    };
    this.hap.patchValue(temp);
  }
  onChangeLastName(event) {
    this.lastName = event.target.value;
    this.finalName = this.firstName + ' ' + this.lastName;
    let temp = {
      cmd_0897: this.finalName
    };
    this.hap.patchValue(temp);
  }
  returnImgPath(){
    let x="./assets/images/logo.png"
    if (this.customerId == 16859) {
      x = './assets/images/commitService.jpg'
    }
    else if (this.customerId == 17047) {
      x='./assets/images/STI-logo.png'
    }
    return x
  }
  getSsn(value) {
    this.hap.patchValue({
      
      cmd_0779: value?.substring(value.length-4, value.length)
    });
  }
  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  public bindFormValue(array) {
    let fieldsCtrls = {};
    for (let i = 0; i < array.length; i++) {
      fieldsCtrls[array[i].field_name] = array[i].field_value;
      if (array[i].field_name == 'cmd_11122') {
        if (array[i].field_value != '') {
          this.previewEsign(array[i].field_value, array[i].field_name);
        }
      }
    //  if (array[i].field_name == 'cmd_024') {
    //     if (array[i].field_value == 1) {
    //       this.changeCmdThree= 1;
    //     } else {
    //       this.changeCmdThree= 0;
    //     }
    //   }
      
      if (array[i].field_name == 'cmd_035') {
        if (array[i].field_value == 1) {
          this.changeCmdThree1 = 1;
        } else {
          this.changeCmdThree1 = 0;
        }
      }
      if (array[i].field_name == 'cmd_046') {
        if (array[i].field_value == 1) {
          this.changeCmdThree2 = 1;
        } else {
          this.changeCmdThree2 = 0;
        }
      }

 
    }
    
    this.hap.patchValue(fieldsCtrls);
}
public previewEsign(filePath, fieldId) {
  //this.spinner.show();
  let params = {
    filePath: filePath
  };
  this.onboardService.getPreviewEsign(params).subscribe(
    (data: any) => {
      if (data['success']) {
        this.ImgOne = data.base64;
        this.setEsign(this.ImgOne, fieldId);
      } else {
        //this.notiService.showError(data.error, '', 3000)
      }
      //this.spinner.hide();
    },
    error => {
      //this.spinner.hide();
    }
  );
}

public setEsign(filePath, fieldId) {
  this.imgOneSetting = 1;
  // console.log(filePath);
  if (fieldId == 'cmd_11122') {
    this.esignOne = this._sanitizer.bypassSecurityTrustResourceUrl(
      'data:image/jpg;base64,' + filePath
    );
    this.showOne = true;
  }
}
public afterSaveDataDisplay() {
    let params = {
      type: 47,
      formId: this.formId
    };
    //this.spinner.show();
    this.onboardService.toGetSaveData(params).subscribe(
      data => {
        if (data['success']) {
          this.bindFormValue(data.data);
        } else {
          // this.notiService.showError(data.error, '', 3000)
        }
        //this.spinner.hide();
      },
      error => {
        // this.notiService.showError(Error_Message, '', 3000)
      }
    );
  }
  // public licApp(event) {
  //   // console.log(event.target.value, event.target.checked);
  //   this.licenseapp = event;
  //   if (this.licenseapp) {
  //     this.hap.controls[293].setErrors(null);
  //     this.hap.controls[299].setErrors(null);
  //     this.hap.controls[294].setErrors(null);
  //     this.hap.controls[295].setErrors(null);
  //     this.hap.controls[296].setErrors(null);
      
  //     this.hap.controls['cmd_013'].setErrors(null);
      
  //   }
  // }

  // onChangeCmdOne(event) {
  //   if (event == 1) {
  //     this.changeCmdOne = true;
  //     this.hap.controls['514'].setErrors({ errors: true });
  //   } else {
  //     this.hap.controls[514].setErrors(null);
  //   }
  // }
  setErrorControl(name){
    this.hap.controls[name].setErrors({ errors: true });
  }
  removeErrorControl(name){
    this.hap.controls[name].setErrors(null)
  }
  
  onChangeCmdThree1(event) {
    if (event.target.value == 1) {
      this.changeCmdThree1 = true;
    } else {
      this.changeCmdThree1 = false;
    }
    

  }
  onChangeCmdThree2(event) {
    if (event.target.value == 1) {
      this.changeCmdThree2 = true;
    } else {
      this.changeCmdThree2 = false;
    }
  }
  
  
    

  handleNAchange(cName,eName?){
    let result:boolean=false;
    if(cName=='cmd_0722'){
      let x=[490,493,495,500,497,498,517,520,522,524,525,'cmd_010',529,533,535,'cmd_011',537,538,'cmd_08']
      x.forEach(element => {
        const ctrl = this.hap.controls[element];
          if ((ctrl.value === '' || ctrl.value === null) && !this.hap.controls[cName].value) {
            this.setErrorControl(element);
            result = true;
          } else if ((element === 497 || element === 498 || element === 524 || element === 525 || element === 537 || element === 538) && !/^\d{2}\/\d{2}\/\d{4}$/.test(ctrl.value) && (!this.hap.controls[cName].value || (this.hap.controls[cName].value && ctrl.value !== '' && ctrl.value !== null))) {
            this.setErrorControl(element);
            ctrl.setErrors({ 'incorrectDateFormat': true });
            result = true;
          } else if ((element === 497 || element === 498 || element === 524 || element === 525 || element === 537 || element === 538) && this.hap.controls[cName].value) {
            this.removeErrorControl(element);
            ctrl.setErrors(null);
          } else {
            this.removeErrorControl(element);
            ctrl.setErrors(null);
          }
      });
    }  

    if(cName=='cmd_0726'){
      let x=[284,'cmd_024',557]
      x.forEach(element => {
        const ctrl = this.hap.controls[element];
        if ((ctrl.value === '' || ctrl.value === null) && !this.hap.controls[cName].value) {
          this.setErrorControl(element);
          result = true;
        } else if ((element === 557 ) && !/^\d{2}\/\d{2}\/\d{4}$/.test(ctrl.value) && (!this.hap.controls[cName].value || (this.hap.controls[cName].value && ctrl.value !== '' && ctrl.value !== null))) {
          this.setErrorControl(element);
          ctrl.setErrors({ 'incorrectDateFormat': true });
          result = true;
        } else if ((element === 557 ) && this.hap.controls[cName].value) {
          this.removeErrorControl(element);
          ctrl.setErrors(null);
        } else {
          this.removeErrorControl(element);
          ctrl.setErrors(null);
        }
      });
    } 
    if(cName=='cmd_0727'){
      let x=[445,454,455,448,450,451,446, 447]
      x.forEach(element => {
        const ctrl = this.hap.controls[element];
        if ((ctrl.value === '' || ctrl.value === null) && !this.hap.controls[cName].value) {
          this.setErrorControl(element);
          result = true;
        } else if ((element === 454 || element ===455 ) && !/^\d{2}\/\d{2}\/\d{4}$/.test(ctrl.value) && (!this.hap.controls[cName].value || (this.hap.controls[cName].value && ctrl.value !== '' && ctrl.value !== null))) {
          this.setErrorControl(element);
          ctrl.setErrors({ 'incorrectDateFormat': true });
          result = true;
        } else if ((element === 454 || element ===455) && this.hap.controls[cName].value) {
          this.removeErrorControl(element);
          ctrl.setErrors(null);
        } else {
          this.removeErrorControl(element);
          ctrl.setErrors(null);
        }
      });
    } 
    if(cName=='cmd_07259'){
      let x=[293,299,294,295,296]
      x.forEach(element => {
        const ctrl = this.hap.controls[element];
          if ((ctrl.value === '' || ctrl.value === null) && !this.hap.controls[cName].value) {
            this.setErrorControl(element);
            result = true;
          } else if ((element === 294 || element ===295) && !/^\d{2}\/\d{2}\/\d{4}$/.test(ctrl.value) && (!this.hap.controls[cName].value || (this.hap.controls[cName].value && ctrl.value !== '' && ctrl.value !== null))) {
            this.setErrorControl(element);
            ctrl.setErrors({ 'incorrectDateFormat': true });
            result = true;
          } else if ((element === 294 || element ===295) && this.hap.controls[cName].value) {
            this.removeErrorControl(element);
            ctrl.setErrors(null);
          } else {
            this.removeErrorControl(element);
            ctrl.setErrors(null);
          }
      });
    } 

  }
  // handleYesNoChanges(cName,eName?){
  //   if (cName == "cmd_023") {
  //     let x=['514']
  //     x.forEach(element => {
  //       if((this.hap.controls[element].value=='' || this.hap.controls[element].value==null) && (this.hap.controls[cName].value==1 || this.hap.controls[cName].value==null)){
  //         this.setErrorControl(element);
  //       }
  //       else{
  //         this.removeErrorControl(element);
  //       }
  //     });
  //   }
    


  // }
  
  // onChangeCmdThree(event) {
  //   if (event.target.value == 1) {
  //     this.changeCmdThree = true;
  //   } else {
  //     this.changeCmdThree = false;
  //   }
  // }
  handleYesNoChanges(cName, eName?) {
    let result:boolean=false;
    if (cName == "cmd_023") {

      let x = ['514'];
      x.forEach(element => {
        if((this.hap.controls[element].value=='' || this.hap.controls[element].value==null) && (this.hap.controls["cmd_023"].value==1 )){
          this.setErrorControl(element);
        }
        else{
          this.removeErrorControl(element);
        }
      });
    }
  
    
    if (cName == "cmd_024") {
      let x=['554']
      x.forEach(element => {
        if((this.hap.controls[element].value=='' || this.hap.controls[element].value==null) && (this.hap.controls["cmd_024"].value==1 )){
          this.setErrorControl(element);
        }
        else{
          this.removeErrorControl(element);
        }
      });
    }
    
  }
  

  buildForm() {
    this.hap = this.fb.group({
        822: [null, [Validators.required]],
        9: ['', [Validators.required]],
        8: ['', [Validators.required]],
        809: [''],
        29: [null],
        278: [''],
        280: [null, [this.dateFormatValidator]],
        281: [null,[this.dateFormatValidator]],
        2337: [''],
        cmd_01: [null,[this.dateFormatValidator]],
        cmd_02: [null,[this.dateFormatValidator]],
        16: ['', [Validators.required]],
        17: ['', ],
        18: ['', [Validators.required]],
        20: [null, [Validators.required]],
        21: [
            '',
            [Validators.required]
          ],
        708: ['', [Validators.required]],
        32: ['',[Validators.required]],
        30: [null,[Validators.required]],
        cmd_837: [''],
        cmd_838: [''],
        cmd_839: [''],
        cmd_841: [null],
        cmd_842: ['', [this.zipCodeValidator]],
        cmd_870: [''],
        cmd_871: [''],
        759: ['', [ Validators.pattern(this.emailPattern)]],
        31: [null, [Validators.required]],
        810: ['', [Validators.required]],
        289: [null, [Validators.required]],
        292: [''],
        1584: [''],
        1282: [''],
        cmd_04: [''],
        333: ['',[Validators.required]],
        334: ['',[Validators.required]],
        335: ['',[Validators.required]],
        cmd_05: [''],
        1144: [null, [this.dateFormatValidator]],
        1145: [null,[this.dateFormatValidator]],
        1139: ['',],
        1141: ['', ],
        cmd_06: [''],
        cmd_0722: [''],
        490: ['',],
        491: [''],
        492: [''],
        493: [''],
        495: [null],
        496: ['',[this.zipCodeValidator]],
        500: [null],
        497: [null],
        498: [null],
        cmd_08:['',Validators.required], 
        527:[null],
        517:[''],
        518:[null],
        519:[null],
        520: [''],
       522: [null],
       523: ['',[this.zipCodeValidator]],
       524: [null],
       525: [null],
       cmd_09: [''],
       528: [''],
       818: [''],
       cmd_010: [''],
       530:[null],
       529: [''],
       531: [''],
       1498: [''],
       533: [''],
       535: [null],
       536: ['',[this.zipCodeValidator]],
       cmd_011: [''],
       537: [null],
       538: [null],
       540: [''],
       541: [''],
       479: [''],
       483: [''],
       484: [''],
       485: [''],
       487: [null],
       488: ['' ,[this.zipCodeValidator]],
       480: [null],
       481: [null,[this.dateFormatValidator]],
       1300: [null,[this.dateFormatValidator]],
       296: [''],
       293: [''],
       299: [null],
       294: [null],
       295: [null],
       cmd_013: ['',Validators.required],
       cmd_07259: [''],
       1274: [''],
       300: [''],
       907: [null],
       307: [null,[this.dateFormatValidator]],
       308: [null,[this.dateFormatValidator]],
       cmd_014: [''],
       1432: [''],
       999: [''],
       1010: [null],
       1003: [null,[this.dateFormatValidator]],
       1004: [null,[this.dateFormatValidator]],
       cmd_015: [''],
       cmd_016: [''],
       309: [''],
       310: [null,[this.dateFormatValidator]],
       311: [null,[this.dateFormatValidator]],
       cmd_017: [''],
       337: [''],
       338: [null,[this.dateFormatValidator]],
       339: [null,[this.dateFormatValidator]],
       cmd_019: [''],
       340: [''],
       997: [null],
       341: [null,[this.dateFormatValidator]],
       342: [null,[this.dateFormatValidator]],
       cmd_020: [''],
       819: [''],
       1634:['',Validators.required],
       cmd_021: [''],
       325: [''],
       cmd_022: [''],
       812: [''],
       cmd_023: ['',[Validators.required]],
       514: [''],
       515: [null,[this.dateFormatValidator]],
       cmd_0726: [null],
       284: [null],
       cmd_024: [''],
       554: [null],
       557: [null],
       559: [null,[this.dateFormatValidator]],
       560: [null,[this.dateFormatValidator]],
       cmd_025: [''],
       cmd_026: [''],
       cmd_027: [''],
       cmd_028: [''],
       cmd_029: [''],
       cmd_030: [''],
       cmd_031: [''],
       cmd_032: [''],
       cmd_033: [''],
       cmd_034: [''],
       962: [null],
       cmd_035: [''],
       561: [null],
       564: [null,[this.dateFormatValidator]],
       565: [null,[this.dateFormatValidator]],
       567: [null,[this.dateFormatValidator]],
       cmd_036: [''],
       cmd_040: [''],
       cmd_037: [''],
       cmd_038: [''],
       cmd_041: [''],
       cmd_042: [''],
       cmd_039: [''],
       cmd_043: [''],
       cmd_044: [''],
       cmd_045: [''],
       965: [null],
       cmd_046: [''],
       946: [null],
       953: [null,[this.dateFormatValidator]],
       950: [null,[this.dateFormatValidator]],
       954: [null,[this.dateFormatValidator]],
       cmd_047: [''],
       cmd_051: [''],
       cmd_048: [''],
       cmd_049: [''],
       cmd_052: [''],
       cmd_050: [''],
       cmd_053: [''],
       cmd_054: [''],
       cmd_055: [''],
       cmd_056: [''],
       cmd_057: [''],
       cmd_0727: [''],
       445: [''],
       454: [null],
       455: [null],
       446: [''],
       447: [''],
       448: [''],
      450: [null],
      451: [''],
      456: [''],
      465: [null,[this.dateFormatValidator]],
      466: [null,[this.dateFormatValidator]],
      457: [''],
      458: [''],
      459: [''],
      461: [null],
      462: ['',[this.zipCodeValidator]],
      1338: [''],
      467: [''],
      476: [null,[this.dateFormatValidator]],
      477: [null,[this.dateFormatValidator]],
      468: [''],
      469: [''],
      470: [''],
      472: [null],
      473: ['',[this.zipCodeValidator]],
      1342: [''],
      1935: [''],
      1947: [null,[this.dateFormatValidator]],
      1948: [null,[this.dateFormatValidator]],
      1936: [''],
      1937: [''],
      1938: [''],
      1940: [null],
      1941: ['',[this.zipCodeValidator]],
      1952: [''],
      3395: [null,[this.dateFormatValidator]],
      3396: [null,[this.dateFormatValidator]],
      1138:[''],
      3397: [null,[this.dateFormatValidator]],
      3398: [null,[this.dateFormatValidator]],
      1831: [''],
      1832: [''],
      3399: [null,[this.dateFormatValidator]],
      3400: [null,[this.dateFormatValidator]],
      3401: [null,[this.dateFormatValidator]],
      3402: [null,[this.dateFormatValidator]],
      1953: [''],
      cmd_059: [''],
      cmd_060: [''],
      418: [''],
      426: [null,[this.dateFormatValidator]],
      2007:[null,[this.dateFormatValidator]],
      419:[''],
      420: [''],
      421: [''],
      424: ['',[this.zipCodeValidator]],
      423: [null],
      1305: [''],
      2136: [''],
      2142: ['',[Validators.pattern(this.emailPattern)]],
      cmd_061: [''],
      894: [null],
      cmd_062: [''],
      cmd_063: [''],
      427: [''],
      435: [null,[this.dateFormatValidator]],
      2008:[null,[this.dateFormatValidator]],
      428: [''],
      429: [''],
      430: [''],
      432: [null],
      433: ['',[this.zipCodeValidator]],
      1307: [''],
      2137: [''],
      2143: ['',[Validators.pattern(this.emailPattern)]],
      cmd_064: [''],
      895: [null],
      cmd_065: [''],
      cmd_066: [''],
      1183: [''],
      1191: [null,[this.dateFormatValidator]],
      2381: [null,[this.dateFormatValidator]],
      1184: [''],
      1185: [''],
      1186: [''],
      1188: [null],
      1189: ['',[this.zipCodeValidator]],
      cmd_068: [''],
      1192: [null],
      cmd_069: [''],
      1303: [''],
      359: [''],
      371: ['', [Validators.pattern(this.emailPattern)]],
      369: [''],
      370: [''],
      360: [''],
      361: [''],
      362: [''],
      364: [null],
      365: ['',[this.zipCodeValidator]],
      372: [''],
      383: ['',[Validators.pattern(this.emailPattern)]],
      381: [''],
      382: [''],
      373: [''],
      374: [''],
      375: [''],
      377: [null],
      378: ['',[this.zipCodeValidator]],
      384: [''],
      396: ['',[Validators.pattern(this.emailPattern)]],
      394: [''],
      395: [''],
      385: [''],
      386: [''],
      387: [''],
      389: [null],
      390: ['',[this.zipCodeValidator]],
      cmd_071: [''],
      345: [''],
      901: [''],
      902: [''],
      903: [''],
      905: [null],
      906: ['',[this.zipCodeValidator]],
      1266: [''],
      346: [''],
      348: [null,[this.dateFormatValidator]],
      349: [null,[this.dateFormatValidator]],
      350: [null],
      351: [null],
      cmd_072: [''],
      cmd_073: [''],
      1242:[''],
      1244: [''],
      1245: [''],
      1246: [''],
      1248: [null],
      1249: ['',[this.zipCodeValidator]],
      1254: [''],
      1256: [''],
      1252: [null,[this.dateFormatValidator]],
      1253: [null,[this.dateFormatValidator]],
      1264: [null],
      1265: [null],
      cmd_074: [''],
      cmd_075: [''],
      cmd_088: [''],
      cmd_089: [''],
      cmd_090: [''],
      cmd_091: [''],
      cmd_092: [''],
      cmd_093: [''],
      cmd_094: [''],
      cmd_0728: [''],
      cmd_0729: [''],
      cmd_095: [''],
      cmd_0730: [''],
      cmd_0731: [''],
      cmd_0732: [''],
      cmd_0733: [null],
      cmd_0734: ['',[this.zipCodeValidator]],
      cmd_0735: [''],
      cmd_0736: [''],
      cmd_0737: ['',[Validators.pattern(this.emailPattern)]],
      cmd_0738: [''],
      cmd_0739: [''],
      cmd_0740: [''],
      cmd_0741: [''],
      cmd_0742: [''],
      cmd_09512:[''],
      cmd_0743: [null,[this.dateFormatValidator]],
      cmd_096: [''],
      cmd_0744: [''],
      cmd_0745: [''],
      cmd_0746: [''],
      cmd_0747: [''],
      cmd_0748: [''],
      cmd_0749: [''],
      cmd_0750: [null],
      cmd_0751: [''],
      cmd_0752: [''],
      cmd_0753: [''],
      cmd_0754: [null],
      cmd_0755: ['',[this.zipCodeValidator]],
      cmd_0756: [''],
      cmd_0757: [''],
      cmd_0758: ['',[Validators.pattern(this.emailPattern)]],
      cmd_0759: [''],
      cmd_0760: [''],
      cmd_0761: [''],
      cmd_0762: [''],
      cmd_0763: [''],
      cmd_0764: [null],
      cmd_0765: ['',[this.zipCodeValidator]],
      cmd_0766: [''],
      cmd_0767: [''],
      cmd_0768:['',[Validators.pattern(this.emailPattern)]],
      cmd_097: [''],
      cmd_0769: [''],
      cmd_098: [''],
      cmd_099: [''],
      cmd_0770: [''],
      cmd_0106: [''],
      cmd_0114: [''],
      cmd_0100: [''],
      cmd_0771: [''],
      cmd_0107: [''],
      cmd_0115: [''],
      cmd_0101: [''],
      cmd_0772: [''],
      cmd_0109: [''],
      cmd_0116: [''],
      cmd_0102: [''],
      cmd_0773: [''],
      cmd_0110: [''],
      cmd_0117: [''],
      cmd_0103: [''],
      cmd_0774: [''],
      cmd_0111: [''],
      cmd_0118: [''],
      cmd_0104: [''],
      cmd_0775: [''],
      cmd_0112: [''],
      cmd_0119: [''],
      cmd_0105: [''],
      cmd_0776: [''],
      cmd_0113: [''],
      cmd_0120: [''],
      cmd_0121: [''],
      cmd_0122: [''],
      cmd_0123: [''],
      cmd_0124: [''],
      cmd_0125: [''],
      cmd_0126: [''],
      cmd_0127: [''],
      cmd_0128: [''],
      cmd_0129: [''],
      cmd_0130: [''],
      cmd_0777:[''],
      cmd_0131: [''],
      cmd_0132: [''],
      cmd_0133: [''],
      cmd_0134: [''],
      cmd_0135: [''],
      cmd_0136: [''],
      cmd_0137: [''],
      cmd_0138: [''],
      cmd_0139: [''],
      cmd_0140: [''],
      cmd_0141: [''],
      cmd_0142: [''],
      cmd_0232: ['', [Validators.required]],
      cmd_0233: ['', [Validators.required]],
      cmd_0234: ['', [Validators.required]],
      cmd_0235: ['', [Validators.required]],
      cmd_0236: ['', [Validators.required]],
      cmd_0237: ['', [Validators.required]],
      cmd_0238: ['', [Validators.required]],
      cmd_0239: ['', [Validators.required]],
      cmd_0240: ['', [Validators.required]],
      cmd_0241: ['', [Validators.required]],
      cmd_0242: ['', [Validators.required]],
      cmd_0243: ['', [Validators.required]],
      cmd_0244: ['', [Validators.required]],
      cmd_0245: ['', [Validators.required]],
      cmd_0246: ['', [Validators.required]],
      cmd_0247: ['', [Validators.required]],
      cmd_0248: ['', [Validators.required]],
      cmd_0249: ['', [Validators.required]],
      cmd_0250: ['', [Validators.required]],
      cmd_0251: ['', [Validators.required]],
      cmd_0252: ['', [Validators.required]],
      cmd_0253: ['', [Validators.required]],
      cmd_0254: ['', [Validators.required]],
      cmd_0307: [''],
      cmd_11122:  ['', [Validators.required]],
      cmd_0897: ['',Validators.required],
      cmd_0779: ['',Validators.required],
      cmd_0310: [null,Validators.required],
      
    
      

    
      
    });
    this.hap.get('cmd_11122').value;
    // this.onChangeCmdOne(1)
   }
   public esignOpenOne() {
    const modalRef = this.modalService.open(EsignComponent, this.modalOption);
    modalRef.result.then(result => { });
    modalRef.componentInstance.base64Img.subscribe(data => {
      modalRef.close();
      this.finalImgPath = data.finalFilePath;
      let temp = {
        cmd_11122: this.finalImgPath
      };
      this.hap.patchValue(temp);
      this.finalImg = data.base64Img;
      this.esignOne = this._sanitizer.bypassSecurityTrustResourceUrl(
        'data:image/jpg;base64,' + this.finalImg
      );
      this.showOne = true;
    });
  }

   saveForm(type) {
    this.isSubmit=true;
    this.handleNAchange('cmd_0722');
    this.handleNAchange('cmd_0726');
    this.handleNAchange('cmd_0727');
    this.handleYesNoChanges('cmd_023')
    this.handleYesNoChanges('cmd_024')
    this.handleNAchange('cmd_07259');
    
    
    
    
    // console.log(this.hap.get('cmd_11122').value);
    // this.hap.get('cmd_0779').value;
    // console.log(this.hap);
    // if (type == 'submit') {
    //   if (this.hap.invalid) {
    //     this.notiService.showError(
    //       'Please Fill the all mandatory fields to continue',
    //       '',
    //       3000
    //     );
    //     for (const key in this.hap.controls) {
    //       if (this.hap.controls.hasOwnProperty(key)) {
    //         const element = this.hap.controls[key];
    //         if(element.status=="INVALID"){
    //         console.log(key + " " + element.status)
    //         }
    //       }
    //     }
    //   }
    //   else {
        
    //     let data = {
    //       formType: type,
    //       formValues: this.hap.value
    //     };
    //     console.log("Form has been submitted successfully");
    //     this.onSubmit.emit(data);
    //     console.log(this.hap);
  
    //   }
    // } else {
    //   let data = {
    //     formType: type,
    //     formValues: this.hap.value,
    //     formId: 47
    //   };
    //   this.onSubmit.emit(data);
    //   this.onClose.emit('close modal');
    // }
    console.log(this.hap);
      if (type == 'submit') {

        if (this.hap.invalid) {
          this.notiService.showError(
            'Please Fill the all mandatory fields to continue',
            '',
            3000
          );
          for (const key in this.hap.controls) {
            if (this.hap.controls.hasOwnProperty(key)) {
              const element = this.hap.controls[key];
              if(element.status=="INVALID"){
              console.log(key + " " + element.status)
              }
            }
          }
        } else {
          if (this.hap.controls['cmd_11122'].value == null || this.hap.controls['cmd_11122'].value == '') {
            this.notiService.showError('Please add your Signature')
            return
          }
          let data = {
            formType: type,
            formValues: this.hap.value
          };
          console.log("Form has been submitted successfully");
          this.onSubmit.emit(data);
          console.log(this.hap);
  
        }
      } else {
        let data = {
          formType: type,
          formValues: this.hap.value,
          formId: 47
        };
        this.onSubmit.emit(data);
        this.onClose.emit('close modal');
      }
  }
  close() {
    this.onClose.emit('close modal');
  }
  


}
