import { Component, OnInit, Input } from '@angular/core';
import { MoveModalService } from '../../services/move-modal/move-modal.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbActiveModal, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { ToasterNotiService } from '../../services/notifications/toaster-noti.service';
import { StorageService } from '../../services/storage/storage.service';
import { CommonService, Error_Message } from '../../services/common/common.service';

@Component({
  selector: 'ctg-move-modal',
  templateUrl: './move-modal.component.html',
  styleUrls: ['./move-modal.component.scss']
})
export class MoveModalComponent implements OnInit {

  @Input() dataParams;
  public userId:any;
  public customerId:any;
  public selected;
  public readOnlyUser:boolean=false;

  constructor(
    public activeModal: NgbActiveModal,
    private spinner: NgxSpinnerService,
    private service:MoveModalService,
    private notiService: ToasterNotiService,
    private commonService: CommonService,
    private calendar: NgbCalendar,
    private storageService:StorageService
  ) { }

  ngOnInit(): void {
    console.log(this.dataParams);
    this.userId= Number(this.storageService.getItem('userId'))
    this.customerId=Number(this.storageService.getItem('customerId'))
    this.commonService.currentUserPermission.subscribe(data => {
      if (data) {
        if(data && data.permissions==1){
          this.readOnlyUser = true
        }
      }
    }
    )
  }
  confirm(){
    let params={
      customerId:this.dataParams.params.customerId,
      userId:this.dataParams.params.userId,
      from_subgrpId:this.dataParams.params.sub_grp_id,
      to_subgrpId:this.selected,
      gridId:this.dataParams.grid,
      providerId:this.dataParams.ID
    }
    this.spinner.show()
    this.service.movedata(params).subscribe(
      data=>{
        if (data['success']) {
          this.notiService.showSuccess(data['status']['message'], '', 4000);
          this.activeModal.close('success');
        }
        else {
          this.notiService.showError(data['error'], '', 3000);
        }
        this.spinner.hide()
      },
      error => {
        this.notiService.showError(Error_Message, '', 3000)
        this.spinner.hide()
      }
    )
  }
}
