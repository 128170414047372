import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { JwtService } from '../../../containers/auth-routing/jwt.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ApiServiceService {

  constructor(
    private http: HttpClient,
    private jwtService: JwtService,
    private router: Router
  ) { }

  private setHeaders() {
    let token = this.jwtService.getRawToken();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': "application/json",
        'Authorization': 'Bearer ' + token,
        //  'Access-Control-Allow-Origin':'*',

      })
    };
    return httpOptions;
  }

  private setPostHeaders() {
    let token = this.jwtService.getRawToken();
    const httpOptions = {
      headers: new HttpHeaders({
        // 'Content-Type': "application/json",
        'Authorization': 'Bearer ' + token
      })
    };
    return httpOptions;
  }

  public handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // client-side or network error occurred.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
      console.log(error)
    }
    if ([403].indexOf(error.status) !== -1) {
      console.error('An error occurred:', error.status);
    }
    if ([401].indexOf(error.status) !== -1) {
      // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
      // removed 403 [401, 403]=>[401] for handling file upload type validation which is placed on firewall
      localStorage.clear();
      this.router.navigate([""])
    }

    return throwError(
      `${JSON.stringify(error.error.error)}`
    );

  };
  get(pathUrl) {

    return this.http.get(`${environment.apiUrl}${pathUrl}`, this.setHeaders())
      .pipe(
        catchError(this.handleError)
      );
  }
  post(postUrl, body: Object) {
    return this.http.post<any>(`${environment.apiUrl}${postUrl}`, JSON.stringify(body), this.setHeaders())
      .pipe(
        catchError(this.handleError)
      );
  }
  delete(delUrl) {
    return this.http.delete(`${environment.apiUrl}${delUrl}`, this.setHeaders())
      .pipe(
        catchError(this.handleError)
      );
  }
  put(putUrl, body: Object) {
    return this.http.put(`${environment.apiUrl}${putUrl}`, JSON.stringify(body), this.setHeaders())
      .pipe(
        catchError(this.handleError)
      );
  }

  postFile(postUrl, body: any) {
    return this.http.post(`${environment.apiUrl}${postUrl}`, body, this.setPostHeaders())
      .pipe(
        catchError(this.handleError)
      );
  }
}
