import { Component, OnInit,Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbDateAdapter, NgbDateParserFormatter, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { CustomAdapter, CustomDateParserFormatter } from '../../../DateFormatter/customDate';
import { ToasterNotiService } from '../../../services/notifications/toaster-noti.service';
import { OnboardingformsServiceService } from '../../../services/onboardingforms-service/onboardingforms-service.service';
import { StorageService } from '../../../services/storage/storage.service';

@Component({
  selector: 'ctg-erie-form-section1',
  templateUrl: './erie-form-section1.component.html',
  styleUrls: ['./erie-form-section1.component.scss'],
  providers: [
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }
  ]
})
export class ErieFormSection1Component implements OnInit {

  @Input() erieForm1 : FormGroup
  @Input() isSubmit;
  @Output() involvementData = new EventEmitter();
  @Output() racechange= new EventEmitter();
  @Output() licenseNumber= new EventEmitter();
  @Output() licenseState= new EventEmitter();
  @Output() onChangeLocation= new EventEmitter();
  @Output() changeaction18= new EventEmitter();
  @Output() changeaction17= new EventEmitter();
  @Output() changeaction21= new EventEmitter();
  @Output() esignopenevent= new EventEmitter();
  @Input() ischangeAction21:boolean;
  @Input() ischangeAction18:boolean;
  @Input() ischangeAction17:boolean;
  locationList:any=[];

  @Input() show2:boolean=false;
  @Input() show3:boolean=false;
  @Input() show4:boolean=false;
  @Input() show5:boolean=false;
  @Input() show6:boolean=false;
  @Input() show7:boolean=false;
  @Input() show8:boolean=false;
  @Input() show9:boolean=false;
  @Input() show10:boolean=false;
  @Input() show11:boolean=false;
  @Input() show12:boolean=false;
  @Input() show13:boolean=false;
  @Input() show14:boolean=false;
  @Input() show15:boolean=false;
  @Input() show16:boolean=false;
  @Input() show17:boolean=false;
  @Input() show18:boolean=false;
  @Input() show19:boolean=false;
  @Input() show20:boolean=false;
  @Input() show21:boolean=false;
  @Input() show22:boolean=false;
  
  
  @Input() esign2:any;
  @Input() esign3:any;
  @Input() esign4:any;
  @Input() esign5:any;
  @Input() esign6:any;
  @Input() esign7:any;
  @Input() esign8:any;
  @Input() esign9:any;
  @Input() esign10:any;
  @Input() esign11:any;
  @Input() esign12:any;
  @Input() esign13:any;
  @Input() esign14:any;
  @Input() esign15:any;
  @Input() esign16:any;
  @Input() esign17:any;
  @Input() esign18:any;
  @Input() esign19:any;
  @Input() esign20:any;
  @Input() esign21:any;
  @Input() esign22:any;

    
  modalOption: NgbModalOptions = {
    size:'md',
    keyboard:false,
    backdrop:'static'
  };

  constructor(
   
    private onboardService: OnboardingformsServiceService,
    private spinner: NgxSpinnerService,
    private notiService: ToasterNotiService,
    private modalService: NgbModal,
    private _sanitizer: DomSanitizer,
    private storageService: StorageService,
  ) { }

  public userId: any;
  public customerId: any;
  public stateDropDownDate: any = []
  public languageDropdownData: any = [];
  public specialtySecondary: any = [];
  public specialty: any = [];
  public country: any = [];
  public staffStatus: any = [];
  public degree: any = [];
  public raceData: any = [];
  public genderDropdownData:any=[];
  public boardCertification:any;

  ngOnInit(): void {
    this.userId = Number(this.storageService.getItem('userId'));
    this.customerId = Number(this.storageService.getItem('customerId'));
    this.getLocationList();
    this.getDegreeData();
    this.getCountyData();
    this.getStatesData();
    this.getSpecialtyData();
    this.getSpecialtySecondaryData();
    this.getRaceData();
    this.getLanguagesData();
    this.getGenderData();
  }

  
  public getStatesData() {
    let params: {}
    this.spinner.show();
    this.onboardService.getStatesData(params).subscribe(
      data => {
        if (data['success']) {
          this.stateDropDownDate = data.data.map(i => ({
            ...i, id: i.id.toString()
          }))
        }
        else {
          this.notiService.showError(data.error, '', 3000)
        }
        this.spinner.hide();
      },
      error => {
        this.spinner.hide();
      }
    )
  }

  public getSpecialtyData() {
    let params: {}
    this.spinner.show();
    this.onboardService.getSpecialtyData(params).subscribe(
      data => {
        if (data['success']) {
          this.specialty = data.data.map(i => ({
            ...i, id: i.id.toString()
          }))
        }
        else {
          this.notiService.showError(data.error, '', 3000)
        }
        this.spinner.hide();
      },
      error => {
        this.spinner.hide();
      }
    )
  }

  public getSpecialtySecondaryData() {
    let params: {}
    this.spinner.show();
    this.onboardService.getSpecialtySecondaryData(params).subscribe(
      data => {
        if (data['success']) {
          this.specialtySecondary = data.data.map(i => ({
            ...i, id: i.id.toString()
          }))
        }
        else {
          this.notiService.showError(data.error, '', 3000)
        }
        this.spinner.hide();
      },
      error => {
        this.spinner.hide();
      }
    )
  }

  public getLanguagesData() {
    let params: {}
    this.spinner.show();
    this.onboardService.getLanguagesData(params).subscribe(
      data => {
        if (data['success']) {
          this.languageDropdownData = data.data.map(i => ({
            ...i, id: i.id.toString()
          }))
        }
        else {
          this.notiService.showError(data.error, '', 3000)
        }
        this.spinner.hide();
      },
      error => {
        this.spinner.hide();
      }
    )
  }
  
  public getRaceData() {
    let params: {}
    this.spinner.show();
    this.onboardService.getRaceData(params).subscribe(
      data => {
        if (data['success']) {
          this.raceData = data.data.map(i => ({
            ...i, id: i.id.toString()
          }))
        }
        else {
          this.notiService.showError(data.error, '', 3000)
        }
        this.spinner.hide();
      },
      error => {
        this.spinner.hide();
      }
    )
  }

  public getDegreeData() {
    let params: {}
    this.spinner.show();
    this.onboardService.getDegreeData(params).subscribe(
      data => {
        if (data['success']) {
          this.degree = data.data.map(i => ({
            ...i, id: i.id.toString()
          }))
        }
        else {
          this.notiService.showError(data.error, '', 3000)
        }
        this.spinner.hide();
      },
      error => {
        this.spinner.hide();
      }
    )
  }

  public getCountyData() {
    let params: {}
    this.spinner.show();
    this.onboardService.getCountyData(params).subscribe(
      data => {
        if (data['success']) {
          this.country = data.data.map(i => ({
            ...i, id: i.id.toString()
          }))
        }
        else {
          this.notiService.showError(data.error, '', 3000)
        }
        this.spinner.hide();
      },
      error => {
        this.spinner.hide();
      }
    )
  }

  public getStaffStatusData() {
    let params: {}
    this.spinner.show();
    this.onboardService.getStaffStatusData(params).subscribe(
      data => {
        if (data['success']) {
          this.staffStatus = data.data.map(i => ({
            ...i, id: i.id.toString()
          }))
        }
        else {
          this.notiService.showError(data.error, '', 3000)
        }
        this.spinner.hide();
      },
      error => {
        this.spinner.hide();
      }
    )
  }

  public getGenderData() {
    let params: {}
    this.spinner.show();
    this.onboardService.getGenderData(params).subscribe(
      data => {
        if (data['success']) {
          this.genderDropdownData = data.data.map(i => ({
            ...i, id: i.id.toString()
          }))
        }
        else {
          this.notiService.showError(data.error, '', 3000)
        }
        this.spinner.hide();
      },
      error => {
        this.spinner.hide();
      }
    )
  }

  public esignOpen(type:any){
    this.esignopenevent.emit(type)
  }

  handleInvolvementData(event){
this.involvementData.emit(event)

  }

  handleRacechange(event){
    this.racechange.emit(event)
  }

  handleLicenseNumber(event){
    this.licenseNumber.emit(event)
  }

  handleLicenseState(event){
    this.licenseState.emit(event)
  }


  getLocationList(){
    
    let params : {}
    this.spinner.show();
    this.onboardService.geterielocations(params).subscribe(
      data => {
        if (data['success']) {
         // console.log(data)
         this.locationList = data.data.map(i => ({
          ...i, location_id: i.location_id.toString()
        }))
        }
        else {
          this.notiService.showError(data.error, '', 3000)
        }
        this.spinner.hide();
      },
      error => {
        this.spinner.hide();
      } 
    ) 
  }

  onChangeLocation2(event){
    this.onChangeLocation.emit(event);
  }

  onchangeaction18(event){
    this.changeaction18.emit(event)
  }
  onchangeaction21(event){
    this.changeaction21.emit()
  }
  onchangeaction17(event){
    this.changeaction17.emit(event)
  }
  clearInputIfInvalid(controlName: string,handleYesNoValue?:string) {
    const control = this.erieForm1.get(controlName);
    const dateString = control.value;
  
    // Use the dateFormatValidator to check if the date is valid
    const validationErrors = this.dateFormatValidator(new FormControl(dateString));
  
    if (validationErrors) {
      // If any of the validation conditions are met, clear the input field and set errors to null
      control.setValue('');
      control.setErrors(null);
    }
    // if(handleYesNoValue ){
    //   if(handleYesNoValue == ("cmd_05" || "cmd_024" || "cmd_023")){
    //     this.handleYesNoChanges(handleYesNoValue);
    //   }
    //   else{
    //       this.handleNAchange(handleYesNoValue);
    //   }
      
      
    // }
    
  }
  clearInputIfInvalidMandi(controlName: string) {
    const control = this.erieForm1.get(controlName);
    const dateString = control.value;
  
    // Use the dateFormatValidator to check if the date is valid
    const validationErrors = this.dateFormatValidator(new FormControl(dateString));
  
    if (validationErrors) {
      // If any of the validation conditions are met, clear the input field and set errors to null
      control.setValue('');

    }}

  dateFormatValidator(control: FormControl): ValidationErrors | null {
    const dateString = control.value;
    const dateFormat = /^\d{2}\/\d{2}\/\d{4}$/;
  
    if (dateString && !dateString.match(dateFormat)) {
      return { 'invalidDateFormat': true };
    }
  


    if (dateString) {
      const dateParts = dateString.split('/');
      const month = parseInt(dateParts[0]);
      const day = parseInt(dateParts[1]);
      const year = parseInt(dateParts[2]);
  
      // Check if any of the date components are zero or month is not between 1 and 12
      
  
      const ListofDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
      
      
      if (month < 1 || month > 12 || day === 0 || year === 0) {
        return { 'invalidMonth': true };
      }
      if (month === 1 || month > 2) {
        if (day > ListofDays[month - 1]) {
          return { 'invalidDateRange': true };
        }
      } else if (month === 2) {
        const leapYear = (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
        if (!leapYear && day >= 29) {
          return { 'invalidDateRange': true };
        }
         else if (leapYear && day > 29) {
          return { 'invalidDateRange': true };
        }
      }
      if (year < 1900 || year > 2099) {
        return { 'invalidDateyear': true };
      }
      
     
    }
  
    return null;
  }
}
