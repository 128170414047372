import { MainLayoutComponent } from './shared/layout/main-layout/main-layout.component';
import { AuthGuard } from './containers/auth-routing/auth-guard/auth-guard';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UserFieldConstant } from './shared/constants/user-field-constants';
import { GenerateFormComponent } from './containers/reports/generate-form/generate-form.component';
import { MedicalStafVerificationComponent } from './shared/components/medical-staf-verification/medical-staf-verification.component';


const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./containers/auth-routing/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'forms',
    loadChildren: () => import('./containers/forms/forms.module').then(m => m.FormModule)
  },
  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'workflow',
        loadChildren: () => import('./containers/workflow/workflow.module').then(m => m.WorkflowModule),
        canActivate: [AuthGuard],
        data: { role: [UserFieldConstant.cmd, UserFieldConstant.workflowMod] }
      },
      {
        path: 'provider',
        loadChildren: () => import('./containers/provider/provider.module').then(m => m.ProviderModule),
        canActivate: [AuthGuard],
        data: { role: [UserFieldConstant.providerPassport] }
      },
      {
        path: 'tin',
        loadChildren: () => import('./containers/tin/tin.module').then(m => m.TinModule),
        canActivate: [AuthGuard],
        data: { role: [UserFieldConstant.manageTInLocation] }
      },
      {
        path: 'expirations',
        loadChildren: () => import('./containers/expirations/expirations.module').then(m => m.ExpirationsModule),
       canActivate: [AuthGuard],
        data: { role: [UserFieldConstant.expiration] }
      },
      {
        path: 'settings',
        loadChildren: () => import('./containers/settings/settings.module').then(m => m.SettingsModule),
       canActivate: [AuthGuard],
        data: { role: [UserFieldConstant.settingMod] }
      },
      // {
      //   path: 'dop',
      //   loadChildren: () => import('./containers/dop/dop.module').then(m => m.DopModule)
      // },
      {
        path: 'generate',
        loadChildren: () => import('./containers/generate-form/generate-form.module').then(m => m.GenerateFormModule),
        canActivate: [AuthGuard],
      },
     {
        path: 'caqh',
        loadChildren: () => import('./containers/caqhsync/caqhsync.module').then(m => m.CaqhsyncModule),
       canActivate: [AuthGuard],
      },
      {
        path: 'apiaccess',
        loadChildren: () => import('./containers/apiaccess/apiaccess.module').then(m => m.ApiaccessModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'reports',
        loadChildren: () => import('./containers/reports/reports.module').then(m => m.ReportsModule),
        canActivate: [AuthGuard],
        data: { role: [UserFieldConstant.reportsMod] }
      },
      {
        path: 'support',
        loadChildren: () => import('./containers/support/support.module').then(m => m.SupportModule),
        canActivate: [AuthGuard],
        data: { role: [UserFieldConstant.support] }
      },
      {
        path: 'provider-portal',
        loadChildren: () => import('./containers/provider-portal/provider-portal.module').then(m => m.ProviderPortalModule),
      canActivate: [AuthGuard],
        data: { role: [UserFieldConstant.providerlogin] }
       
      },
      {
        path: 'pending-form',
        component: GenerateFormComponent,
        canActivate: [AuthGuard],
        data: { role: [UserFieldConstant.pendingForm] }

      },
      {
        path: 'view-generated-forms',
        component: GenerateFormComponent,
        canActivate: [AuthGuard],
        data: { role: [UserFieldConstant.pendingForm] }

      }
    ]
  },
  {
    path: 'peer_reference',
    loadChildren: () => import('./containers/peer-reference/peer-reference.module').then(m => m.PeerReferenceModule)
  },
  {
    path:'verify',
    component:MedicalStafVerificationComponent,

  },
  {
    path: '**',
    redirectTo: '',
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
