<div id="monthMode">
  <input name="date" appMaskInputForMonth [appMaskInputForMonthValue]="selectedYearMonth" type="text" class="form-control month-input" [(ngModel)]="selectedYearMonth" [ngbPopover]="popContent"
  [popoverTitle]="popTitle" [autoClose]="'outside'" #pop="ngbPopover" placement="bottom" container="body" triggers="manual" 
  (click)="handlePopover(pop)"  (blur)="onBlur()" (keyup)="handleDateChange($event.target.value)"  placeholder="MM/YYYY" maxlength="7" />
  <ng-template #popTitle let-language="language">
    <div class="month-header">
    <ng-select dropdownPosition='bottom' [virtualScroll]="true" [items]="years" bindLabel="name" placeholder="year"
      bindValue="id" [clearable]="false" [(ngModel)]="selectedYear" (ngModelChange)="handleYear()"></ng-select>
    </div>
    </ng-template>
  <ng-template #popContent let-greeting="greeting">
    <div class="month-body">
    <div class="row">
      <div class="col-3 month" (click)="handleMonthSelect(item)"
        [ngClass]="item.value==selectedMonth?'active':'inActive'" *ngFor="let item of months">
        {{item.name}}
      </div>
    </div>
  </div>
  </ng-template>
</div>
