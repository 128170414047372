import { Component, OnInit } from '@angular/core';
import { NgbModalOptions, NgbActiveModal, NgbModal, NgbDateStruct, NgbCalendar, NgbDateAdapter, NgbDateNativeAdapter } from '@ng-bootstrap/ng-bootstrap';
import { UpdatePasswordService } from './update-password.service';
import { UntypedFormBuilder,AbstractControl,UntypedFormControl, Validators, UntypedFormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToasterNotiService } from '../../../shared/services/notifications/toaster-noti.service';
import * as GibberishAES from '../../../thirdparty/gibberish-aes-1.0.0.min.js';
import { Router } from '@angular/router';

@Component({
  selector: 'app-update-password',
  templateUrl: './update-password.component.html',
  styleUrls: ['./update-password.component.scss']
})
export class UpdatePasswordComponent implements OnInit {

  key: string;
  modalOption: NgbModalOptions = {};
  private passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&#^(){}+_>< "',-./:;=[\]`|~])[A-Za-z\d$@$!%*?&#^(){}+_>< "',-./:;=[\]`|~].{9,}$/;
  public updtpaswd: UntypedFormGroup;                                              
  public userName: string;

  constructor(
    private changePassService: UpdatePasswordService,
    public activeModal: NgbActiveModal,
    private formBuilder: UntypedFormBuilder,
    private toast: ToasterNotiService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.key = 'fjsnwROzrbx/6sFGJ3+YHUqwzK246x8OrkmDycq1090=';
    this.userName = localStorage.getItem('userEmail');
    this.buildUpdtpaswdform();
  }

  buildUpdtpaswdform() {
    this.updtpaswd = this.formBuilder.group({
      old_password: new UntypedFormControl('', [Validators.required]),
      new_password: new UntypedFormControl('', [Validators.required,Validators.minLength(10), Validators.pattern(this.passwordPattern)]),
      confirmedPassword: new UntypedFormControl('', [Validators.required]),
    }, {
      validator: this.passwordConfirming


    })
  }

  passwordConfirming(c: AbstractControl): any {
    if (c.get('new_password').value !== c.get('confirmedPassword').value) {
      c.get('confirmedPassword').setErrors({ matchPassword: true });
    }
  }

  addsaveDetails() {

    let newPassword = GibberishAES.enc(this.updtpaswd.value.new_password, this.key);
    let oldPassword = GibberishAES.enc(this.updtpaswd.value.old_password, this.key);
    let postparam = {
      old_password: this.updtpaswd.value.old_password,
      new_password: this.updtpaswd.value.new_password,
      email: this.userName
    }

    this.changePassService.changedPassword(postparam).subscribe(data => {
      if (data['success']) {
        this.toast.showSuccess(data['message'], "", 3000);
        this.activeModal.dismiss('Cross click');
        localStorage.clear();
        sessionStorage.clear();
        this.router.navigate(['/signin']);
      }
      else {
        this.toast.showError(data['error'], "", 2000);
      }
    })
  }

  clearuser() {
    this.updtpaswd.reset();
  }

}
