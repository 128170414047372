import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { FormGuardService } from './form-guard.service';


@Injectable()
export class FormGuard implements CanActivate {
  public authToken;
  private isAuthenticated = true; // Set this value dynamically

  constructor(private router: Router, public auth: FormGuardService) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.isAuthenticated = this.auth.isEmailValid() ? true : false;
    // console.log(this.isAuthenticated);
    if (this.isAuthenticated) {
      return true;
    }
    this.router.navigate(['forms']);
    return false;

  }
}