import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToasterNotiService } from '../../../shared/services/notifications/toaster-noti.service';
import { StorageService } from '../../../shared/services/storage/storage.service';
import {PortalReportService} from '../services/portal-report/portal-report.service';
import {GeneratedFormService} from '../services/generated-form/generated-form.service';
import { environment } from 'apps/cmd-frontend/src/environments/environment';
import { NgbDateAdapter, NgbDateParserFormatter, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import{EsignUploadComponent} from '../modal/esign-upload/esign-upload.component'
import {SendMailNotificationComponent} from '../modal/send-email-notification/send-mail-notification.component'
import { CommonService, File_Error } from '../../../shared/services/common/common.service';
import { ActivatedRoute,Router } from '@angular/router';
import { CustomAdapter, CustomDateParserFormatter } from '../../../shared/DateFormatter/customDate';


@Component({
  selector: 'ctg-generate-form',
  templateUrl: './generate-form.component.html',
  styleUrls: ['./generate-form.component.scss'],
  providers: [
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }
  ]

})
export class GenerateFormComponent implements OnInit {


  public catageoryCol:any[];
  public PageNumber:any;
  public limitvalue:any;
  public count:any;
  public generateFormListCol:any[];
  public customerId:any;
  public userId:any;
  public quickUser :any;
  public quickUserProvider : any;
  public userTypeId : any;
  public searchForm:UntypedFormGroup;
  public typeCol :any;
  modalOption: NgbModalOptions = {};
  currentUserPermission:any;

  public pendingViewEnable:any;

  constructor( private spinner: NgxSpinnerService,
    private notiService: ToasterNotiService,
    private storageService:StorageService,
    private portalReportServices:PortalReportService,
    private generatedFormService:GeneratedFormService,
    private fb: UntypedFormBuilder,
    private modalService: NgbModal,
    private commonService:CommonService,
    private route: ActivatedRoute,
    private router: Router,
    ) { }

  ngOnInit(): void {
    /*get user amd customer details */
    this.userId= Number(this.storageService.getItem('userId'));
    this.customerId=Number(this.storageService.getItem('customerId'));
    this.quickUser = Number(this.storageService.getItem('quickUser'));
    this.quickUserProvider = Number(this.storageService.getItem('quickUserProvider'));
    this.userTypeId = Number(this.storageService.getItem('typeId'));
    this.PageNumber = 0;
    console.log(this.router.url);
    console.log(this.quickUser);
    /*get User Permission */
    if(this.router.url!="/view-generated-forms"){
      this.commonService.currentUserPermission.subscribe(data => {
        if(data){
          // console.log(data)
          this.currentUserPermission=data
          this.pendingViewEnable= data.quick_user==1? true:false
        }
      });
    }
    this.limitvalue=10;
    this.buildSearchForm();
    this.onPage({offset:0});
    this.getCatageory();
   // this.getGenerateFormList();
}
 

  public onPage(event) {
    // console.log(event);
    this.PageNumber = event.offset;
    // this.getGenerateFormList();
    // this.getViewGenerateFormList();
    if(this.router.url=="/view-generated-forms"){
      console.log('/view-generated-forms');
        this.getViewGenerateFormList();
        console.log('/view-generated-forms');
        // this.pendingViewEnable=false
        console.log('/view-generated-forms11');

    }
    else{
      this.getGenerateFormList();
    }
    
  }


  buildSearchForm(){
    this.searchForm = this.fb.group({
      fromDate:[''],
      toDate:[''],
      category:[null],
      selectTypeId:[null],

    })
  }

  getGenerateFormList(){

    let form_type = this.userTypeId == 5 ? 1 : 0;

    let postParams={
      customerId:this.customerId,
      userId:this.userId,
      pageNumber:this.PageNumber + 1,
      limit:this.limitvalue,
      head_val:0,
      from_date: this.searchForm.value.fromDate,
      to_date:this.searchForm.value.toDate,
      category_id:this.searchForm.value.category,
      category_item_id:this.searchForm.value.selectTypeId,
      quick_user : this.quickUser,
      quick_user_provider : this.quickUserProvider,
      form_type : form_type
    }

    this.spinner.show();
    this.generatedFormService.getFormList(postParams).subscribe(
      data => {
        if (data['success']) {
          // console.log(data)
         this.generateFormListCol =data.form_data.data
         this.count=data.form_data.t_count
         // // console.log(this.ftcaReportList)
          this.spinner.hide();
        }
        else {
          // console.log(data['error']);
        }
      },
      error => {
        // console.log(error);
        this.spinner.hide();
      }
    )

  }
  getViewGenerateFormList(){

    this.pendingViewEnable=false
    let postParams={
      customerId:this.customerId,
      userId:this.userId,
      pageNumber:this.PageNumber + 1,
      limit:this.limitvalue,
      head_val:0,
      from_date: this.searchForm.value.fromDate,
      to_date:this.searchForm.value.toDate,
      category_id:this.searchForm.value.category,
      category_item_id:this.searchForm.value.selectTypeId,
      quick_user : this.quickUser,
      quick_user_provider : this.quickUserProvider,
      form_type : 0
    }

    this.spinner.show();
    this.generatedFormService.getFormList(postParams).subscribe(
      data => {
        if (data['success']) {
          // console.log(data)
         this.generateFormListCol =data.form_data.data
         this.count=data.form_data.t_count
         // // console.log(this.ftcaReportList)
          this.spinner.hide();
        }
        else {
          // console.log(data['error']);
        }
      },
      error => {
        // console.log(error);
        this.spinner.hide();
      }
    )

  }


  getCatageory(){
    this.catageoryCol = [];
    this.spinner.show();
    this.generatedFormService.getCatageory().subscribe(
      data => {
        if (data['success']) {
          // console.log(data)
          this.catageoryCol=data.category;
          this.catageoryCol.push({id:4,label:"User"});
          console.log(this.catageoryCol)
          this.spinner.hide();
        }
        else {
          // console.log(data['error']);
        }
      },
      error => {
        // console.log(error);
        this.spinner.hide();
      }
    )

  }

  oncategoryChange(event){
    if(event){
      this.searchForm.get('selectTypeId').setValue('');
      this.typeCol = [];
      this.getCatageoryitems();
    }else{
      this.searchForm.get('selectTypeId').setValue('');
      this.typeCol = [];
    }
  }


  getCatageoryitems(){
    let postParams={
        customerId:this.customerId,
        userId:this.userId,
        typeId:this.searchForm.value.category
    }

    this.spinner.show();
    this.generatedFormService.getDropDownValuesByType(postParams).subscribe(
      data => {
        if (data['success']) {
          // console.log(data)
       this.typeCol=data.dropdown_values
          this.spinner.hide();
        }
        else {
          // console.log(data['error']);
         }
      },
      error => {
        // console.log(error);
        this.spinner.hide();
      }
    )

  }


  onSearchClick(){
    if(this.router.url=="/view-generated-forms"){
        this.PageNumber = 0;
        this.getViewGenerateFormList();
    }
    else{
        this.PageNumber = 0;
        this.getGenerateFormList();
    }
  }

  onSearchCancel(){
    this.searchForm.reset();
      if(this.router.url=="/view-generated-forms"){
          this.getViewGenerateFormList();
      }
      else{
        this.getGenerateFormList();
      }
  }


      onexcelDownloadClick(){
        let postParams={
          customerId:this.customerId,
          userId:this.userId,
          head_val:0,
          from_date:this.searchForm.value.fromDate,
          to_date: this.searchForm.value.toDate,
          category_id:this.searchForm.value.category,
          category_item_id:this.searchForm.value.selectTypeId
        }

        this.spinner.show();
        this.generatedFormService.excelDownload(postParams).subscribe(
          data => {
            if (data['success_response']) {
              this.notiService.showSuccess(data['success_response'], '', 4000)
              this.spinner.hide();
            }
            else {
              // console.log(data['error']);
            }
          },
          error => {
            // console.log(error);
            this.spinner.hide();
          }
        )

      }


  downloadExcelDocument(data) {
    var downloadLink = window.document.createElement('a');
    downloadLink.href = `${environment.apiDownloadUrl}${data['filepath']}`
    downloadLink.download = data['filepath'];
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    this.notiService.showSuccess(data.success_response, '', 4000);
    this.unlinkExcelSheet(data['filepath'])
  }


  showEmailmodal(selectedform,isSend){

    this.modalOption.size = 'md'


      let postParams={
        customerId:this.customerId,
        userId:this.userId,
        generate_form_id:selectedform.generated_form_id
      }

      this.spinner.show();
      this.generatedFormService.getMailNotificationDetails(postParams).subscribe(
        data => {
          if (data['success']) {
            // console.log(data)
            this.spinner.hide();
            const modalRef1 = this.modalService.open(SendMailNotificationComponent, this.modalOption);
            modalRef1.componentInstance.mailDetails = data.mail_details;
            modalRef1.componentInstance.isSend = isSend;

              modalRef1.componentInstance.sendEmailEmit.subscribe((data1) => {
            this.sendEmail(data1,selectedform.generated_form_id)
                modalRef1.close();

              });
          }
          else {
            // console.log(data['error']);
          }
        },
        error => {
          // console.log(error);
          this.spinner.hide();
        }
      )

  }

  sendEmail(data,generated_form_id){

    let postParams={
      customerId:this.customerId,
      userId:this.userId,
      email_id:data.email,
      gen_form_ids:generated_form_id
    }
    console.log(postParams);
    this.spinner.show();
    this.generatedFormService.sendMail(postParams).subscribe(
      data => {
        if (data['success']) {
          this.notiService.showSuccess(data['message'],'',4000)
       this.spinner.hide();
        }
        else {
          // console.log(data['error']);
        }
      },
      error => {
        // console.log(error);
        this.spinner.hide();
      }
    )
  }


  onPdfDownLoad(selectedform){

    let postParams={
      customerId:this.customerId,
      userId:this.userId,
      generate_form_id:selectedform.generated_form_id,
      typeId:selectedform.type_id
    }

    this.spinner.show();
    this.generatedFormService.downloadPdfFile(postParams).subscribe(
      data => {
        if (data['success']) {
          // console.log(data)

       this.downloadBase64File(data)
       this.spinner.hide();
        }
        else {
          // console.log(data['error']);
        }
      },
      error => {
        // console.log(error);
        this.spinner.hide();
      }
    )

  }


  downloadBase64File(data,) {
    // decode base64 string, remove space for IE compatibility
    if( data && data.base64){
      var binary = atob(data.base64.replace(/\s/g, ''));
      var len = binary.length;
      var buffer = new ArrayBuffer(len);
      var view = new Uint8Array(buffer);
      for (var i = 0; i < len; i++) {
        view[i] = binary.charCodeAt(i);
      }
      // create the blob object with content-type "application/pdf"
      var blob = new Blob([view], { type: "application/octet-stream" });
      var linkSource = URL.createObjectURL(blob);
      const downloadLink = document.createElement("a");
      downloadLink.href = linkSource;
      downloadLink.download = data.filename || 'download.pdf';
      downloadLink.click();
      this.notiService.showSuccess('Pdf Downloaded SuccessFully', '', 3000)
    }

  }


  onDownloadesignFile(selectedform){

    let postParams={
      customerId:this.customerId,
      userId:this.userId,
      generate_form_id:selectedform.generated_form_id
    }

    this.spinner.show();
    this.generatedFormService.downloadEsignFile(postParams).subscribe(
      data => {
        if (data['success']) {
          // console.log(data)

       this.downloadBase64File(data)
       this.spinner.hide();
        }
        else {
          // console.log(data['error']);
        }
      },
      error => {
        // console.log(error);
        this.spinner.hide();
      }
    )

  }

  onEsignUploadfile(selectedForm){
    this.modalOption.size = 'md'
  const modalRef1 = this.modalService.open(EsignUploadComponent, this.modalOption);
   modalRef1.componentInstance.selectedForm=selectedForm
   modalRef1.componentInstance.uploadFormEmit.subscribe((postParams) => {
  this.uploadesignFile(postParams)
    modalRef1.close();
   });
  }

uploadesignFile(postParams){
this.spinner.show();
    this.generatedFormService.uploadDocument(postParams).subscribe(
      data => {
        if (data['success']) {
          this.notiService.showSuccess(data['documentation'].message,'',4000)
        // this.downloadBase64File(data)
          this.getGenerateFormList();
        }
        else {
          this.notiService.showError(data['error'],'',4000)
           console.log(data['error']);
        }
        this.spinner.hide();
      },
      error => {
        // console.log(error);
        this.notiService.showError(File_Error)
        this.spinner.hide();
      }
    )
  }


  unlinkExcelSheet(path){
    let dataParams = {
      customerId: this.customerId,
      userId: this.userId,
      localPath:path,
    }

    this.commonService.unlinkExcelSheet(dataParams).subscribe(
      data => {
        if (data['success']) {
          // console.log(data);
        }
        else {
          // console.log(data['error']);
        }
      },
      error => {
        // console.log(error);
      }
    )
  }

}
