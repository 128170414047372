<div  id="csc_inte">
    <div class="header">
        <div class="row">
            <div class="col-md-12">
                <img src="./assets/images/CSC2ND.Form.png" alt="Credential my Doc" class="imStyle">
            </div>
        </div>
    </div>
    <form [formGroup]="CSCinte">
        <div class="text-center">
            <h2 style="color: blue;">Application for Credentialing & Privileging</h2> 
        </div>
        <table class="table tabwidth" >
            <tr>
                <td colspan="10">
                  <div class="row">
                      <div class="col-md-6">
                          <input class="form-control" id="cmd_001" name="cmd_001"  formControlName="cmd_001"/>
                          <label class="formLabel" style="font-size: 12px;">Organization Name</label>
                      </div>
                      <div class="col-md-6">
                        <input class="form-control" id="cmd_002" name="cmd_002"  formControlName="cmd_002"/>
                          <label class="formLabel" style="font-size: 12px;">Street Address</label>
                      </div>
                  </div>
                
                </td>
              </tr>
              <tr>
                <td>
                    <div class="row">
                        <div class="col-md-6">
                        </div>

                        <div class="col-md-2">
                            <input class="form-control" id="cmd_003" name="cmd_003"  formControlName="cmd_003"/>
                            <label class="formLabel" style="font-size: 12px;">City</label>
                        </div>
                        <div class="col-md-2">
                            <input  class="form-control" id="cmd_004" name="cmd_004"  formControlName="cmd_004"/>
                              <label class="formLabel" style="font-size: 12px;">State</label>
                          </div>
                        <div class="col-md-2">
                            <input  class="form-control" id="cmd_005" name="cmd_005"  formControlName="cmd_005"/>
                              <label class="formLabel" style="font-size: 12px;">Zip</label>
                          </div>
                    </div>
                    <div class="row">
                          <div class="col-md-12">
                            <div class="pb-3  ">
                                <h2  style="margin-left: 31px; color: blue; font-size: 16px;"><b>Instructions</b></h2>
                             </div>
                            <ol style="font-size: 12px;" class="pl-4 ">
                                <li> Information MUST be typed or printed. All questions MUST be answered and forms
                                    MUST be signed where indicated <i style="font-weight: 800;">Please initial and date the bottom of each page of this
                                    application.</i>
                                    </li>
                                <li>. If a question is not applicable to you, please respond with N/A </li>
                               
                                    <li>If more space is needed, please attach additional sheets and reference the questions being
                                        answered. </li>
                                    <li>If there is a break in the continuity of your medical education, internship, residency,
                                        hospital affiliations, medical practice, etc., please explain</li>
                                
                                <li>Please return the following with your application</li>
                                <ol type="a"><li><i>Curriculum vitae</i></li>
                                    <li><i>Copy of your current state license</i></li>
                                    <li><i>Current IRS W-9’s, if applicable</i></li>
                                    <li><i>Copy of narcotic registration (federal/state) (DEA and CDS)</i></li>
                                    <li><i>Request for Privileges (completed and signed)</i> </li>
                                    <li><i>Copy of declarations page of professional liability insurance policy including
                                        applicant’s name, effective date, expiration date, and policy limits
                                    </i></li>
            
                                </ol>
                                <li><i>Copy of Board Certification (if applicable)</i></li>
                                <li><i>Copy of BLS or ACLS (if applicable)</i></li>
                                <li><i>Copy of professional school/diploma, residency certificates, and Fellowship certificates</i></li>
                                <li><i>Copy of hepatitis-B vaccination or wavier</i></li>
                                <li><i>Copy of most recent tuberculosis PPD test, if applicable</i></li>
                                <li><i>Copy of COVID-19 Vaccination or exemption letter</i></li>
                                
                            </ol>
                            <h4 style="font-size: 12px;">This application must be returned within (30) days of receipt.</h4>
            
                            <h5 style="color: red; margin-top: 35px; font-size: 12px;">INCOMPLETE APPLICATIONS WILL BE RETURNED TO SUPPLY MISSING INFORMATION</h5>
                        </div>
                    </div>
                    </td>
              </tr>
              <tr>
                <td colspan="12">
                  <div class="centered texasFirstTable">
                    <div class="pb-3  ">
                        <h2  style="margin-left: 31px; color: blue; font-size: 16px;"><b>Identifying Information</b></h2>
                    </div>
                  </div>
                </td>
            </tr>   
            <tr>
                <td>
                    <div class="row">
                        <div class="col-md-2"> 
                            <input class="form-control onboard"  formControlName="9"
                                id="9" name="9" type="text" placeholder= "Last name" data-id="managing_contact_name" (change)="onChangeLastName($event)" [class.is-invalid]="(CSCinte.controls['9'].touched && CSCinte.controls['9'].invalid) || (CSCinte.controls['9'].invalid && isSubmit)" >
                            <div class="formLabel col-md-11"
                                *ngIf="CSCinte.controls['9'].touched && CSCinte.controls['9'].invalid">
                                <small class="text-danger" *ngIf="CSCinte.controls['9'].errors?.required">
                                    required
                                </small>
                            </div>
                            <label class="form-label" style="font-size: 12px;">Last Name</label>
                        </div>
                        <div class="col-md-2">
                            <div style="margin-bottom: 0px;" class="form-group selectDrop dropDrownSet">
                              <ng-select
                                [items]="suffix"
                                bindLabel="name"
                                placeholder="Suffix"
                                bindValue="id"
                                formControlName="29" [class.is-invalid]="(CSCinte.controls['29'].touched && CSCinte.controls['29'].invalid) || (CSCinte.controls['29'].invalid && isSubmit)">
                              </ng-select>
                            </div>
                            <label class="form-label" style="font-size: 12px;">Jr,Sr, etc</label>
                          </div>
                        <div class="col-md-4">  
                            <input class="form-control onboard"  formControlName="8"
                                id="8" name="8" type="text" placeholder= "First Name" data-id="managing_contact_name" (change)="onChangeFirstName($event)" [class.is-invalid]="(CSCinte.controls['8'].touched && CSCinte.controls['8'].invalid) || (CSCinte.controls['8'].invalid && isSubmit)" >
                            <div class="formLabel col-md-11"
                                *ngIf="CSCinte.controls['8'].touched && CSCinte.controls['8'].invalid">
                                <small class="text-danger" *ngIf="CSCinte.controls['8'].errors?.required">
                                    required
                                </small>
                            </div>
                            <label class="form-label" style="font-size: 12px;">First Name</label>
                        </div>
                        <div class="col-md-2"> 
                            <input class="form-control onboard"  formControlName="809"
                                id="809" name="809" type="text" placeholder= "Middle Name" data-id="managing_contact_name" (change)="onChangeMiddleName($event)" [class.is-invalid]="(CSCinte.controls['809'].touched && CSCinte.controls['809'].invalid) || (CSCinte.controls['809'].invalid && isSubmit)" >
                            <div class="formLabel col-md-11"
                                *ngIf="CSCinte.controls['809'].touched && CSCinte.controls['809'].invalid">
                                <small class="text-danger" *ngIf="CSCinte.controls['809'].errors?.required">
                                    required
                                </small>
                            </div> 
                            <label class="form-label" style="font-size: 12px;">Middle Name</label>
                        </div>
                        <div class="col-md-2"> 
                            <input class="form-control onboard"  formControlName="32"
                                id="32" name="32" type="text" placeholder= "SSN" data-id="managing_contact_name" [class.is-invalid]="(CSCinte.controls['32'].touched && CSCinte.controls['32'].invalid) || (CSCinte.controls['32'].invalid && isSubmit)" >
                            <div class="formLabel col-md-11"
                                *ngIf="CSCinte.controls['32'].touched && CSCinte.controls['32'].invalid">
                                <small class="text-danger" *ngIf="CSCinte.controls['32'].errors?.required">
                                    required
                                </small>
                            </div>
                            <label class="form-label" style="font-size: 12px;">SSN</label>
                        </div>
                    </div>
                </td>
            </tr> 
             <tr>
                <td>
                    <div class="row">
                        <label style="margin-left: 9px; font-size: 14px;" class="formLabel">List other Name(s) by which you have been known</label>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <input class="form-control" id="278" name="278"  placeholder="Prior Name"  formControlName="278"/>
                            <label class="formLabel" style="font-size: 12px;">Prior Name </label>
                        </div>
                        <div class="col-md-4">
                            <div class="input-group">
                                <input type="text" placeholder="Prior Name Effective Date " ngbDatepicker #d2801="ngbDatepicker" id="280"    (blur)="clearInputIfInvalid('280')" class="form-control" formControlName="280"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d2801.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div  *ngIf="CSCinte.get('280').invalid ">
                                <small class="text-danger" *ngIf="CSCinte.get('280').errors?.invalidDateFormat">
                                  Only Date Format is allowed(MM/DD/YYYY)
                                </small>
                                <small class="text-danger" *ngIf="CSCinte.get('280').errors?.invalidMonth">
                                Month is out of range
                                </small>
                                <small class="text-danger" *ngIf="CSCinte.get('280').errors?.invalidDateyear">
                                  year is out of range(1900-2099)                      </small>
                                  <small class="text-danger" *ngIf="CSCinte.get('280').errors?.invalidDateRange">
                                    date is out of range
                                    </small>
                                </div>
                              
                            <label class="formLabel" style="font-size: 12px;">Prior Name Effective Date </label>
                        </div>
                        <div class="col-md-4">
                            <div class="input-group">
                                <input type="text" placeholder="Prior Name Term Date " ngbDatepicker #d2080="ngbDatepicker" id="281"    (blur)="clearInputIfInvalid('281')" class="form-control" formControlName="281"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d2080.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div  *ngIf="CSCinte.get('281').invalid ">
                                <small class="text-danger" *ngIf="CSCinte.get('281').errors?.invalidDateFormat">
                                  Only Date Format is allowed(MM/DD/YYYY)
                                </small>
                                <small class="text-danger" *ngIf="CSCinte.get('281').errors?.invalidMonth">
                                Month is out of range
                                </small>
                                <small class="text-danger" *ngIf="CSCinte.get('281').errors?.invalidDateyear">
                                  year is out of range(1900-2099)                      </small>
                                  <small class="text-danger" *ngIf="CSCinte.get('281').errors?.invalidDateRange">
                                    date is out of range
                                    </small>
                                </div>
                              
                            <label class="formLabel" style="font-size: 12px;">Prior Name Term Date </label>
                        </div>
                    </div>
                </td>
            </tr>
             <tr>
                <td colspan="18">
                    <div class="row">
                        <div class="col-md-4">
                            <input class="form-control" id="cmd_0015" name="cmd_0015"  placeholder=" address 1" formControlName="cmd_0015"/>
                            <label class="formLabel" style="font-size: 12px;">Primary Professional Group and Address</label>
                        </div>
                        <div class="col-md-4">
                            <input class="form-control" id="cmd_0016" name="cmd_0016"   placeholder=" address 2" formControlName="cmd_0016"/>
                            <label class="formlabel" style="font-size: 12px;">Address2</label>
                        </div>
                        <div class="col-md-2">
                            <div class="input-group">
                                <input type="text" placeholder=" location start date " ngbDatepicker #d286="ngbDatepicker" id="cmd_0011"    (blur)="clearInputIfInvalid('cmd_0011')" class="form-control" formControlName="cmd_0011"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d286.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div  *ngIf="CSCinte.get('cmd_0011').invalid ">
                                <small class="text-danger" *ngIf="CSCinte.get('cmd_0011').errors?.invalidDateFormat">
                                  Only Date Format is allowed(MM/DD/YYYY)
                                </small>
                                <small class="text-danger" *ngIf="CSCinte.get('cmd_0011').errors?.invalidMonth">
                                Month is out of range
                                </small>
                                <small class="text-danger" *ngIf="CSCinte.get('cmd_0011').errors?.invalidDateyear">
                                  year is out of range(1900-2099)                      </small>
                                  <small class="text-danger" *ngIf="CSCinte.get('cmd_0011').errors?.invalidDateRange">
                                    date is out of range
                                    </small>
                                </div>
                              
                            <label class="formLabel" style="font-size: 12px; margin-left: 6px;">Years Associated (YYYY-YYYY)  </label>
                        </div>
                        <div class="col-md-2">
                            <div class="input-group">
                                <input type="text" placeholder="location term date " ngbDatepicker #d287="ngbDatepicker" id="cmd_0012" (blur)="clearInputIfInvalid('cmd_0012')" class="form-control" formControlName="cmd_0012"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d287.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                                    <label class="formLabel" style="font-size: 12px; margin-left: 6px;">Years Associated (YYYY-YYYY)  </label>

                            </div>
                            <div  *ngIf="CSCinte.get('cmd_0012').invalid ">
                                <small class="text-danger" *ngIf="CSCinte.get('cmd_0012').errors?.invalidDateFormat">
                                  Only Date Format is allowed(MM/DD/YYYY)
                                </small>
                                <small class="text-danger" *ngIf="CSCinte.get('cmd_0012').errors?.invalidMonth">
                                Month is out of range
                                </small>
                                <small class="text-danger" *ngIf="CSCinte.get('cmd_0012').errors?.invalidDateyear">
                                  year is out of range(1900-2099)                      </small>
                                  <small class="text-danger" *ngIf="CSCinte.get('cmd_0012').errors?.invalidDateRange">
                                    date is out of range
                                    </small>
                                </div>
                              
                        </div>
                  </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                        <div class="col-md-4">
                            <input  class="form-control" placeholder="" id="cmd_006" name="cmd_006"  formControlName="cmd_006"/>
                            <label class="formLabel" style="font-size: 12px;">City </label>
                        </div>
                        <div class="col-md-4">
                          <input  class="form-control" placeholder="" id="cmd_007" name="cmd_007"  formControlName="cmd_007"/>
                            <label class="formLabel" style="font-size: 12px;">State</label>
                        </div>
                        <div class="col-md-4">
                             <input  class="form-control" placeholder="" id="cmd_008" name="cmd_008"  formControlName="cmd_008"/>
                              <label class="formLabel" style="font-size: 12px;">Zip</label>
                          </div>                        
                    </div>
                </td>
            </tr>  
            <tr>
                <td colspan="12">
                    <div class="row">
                       
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <input class="form-control" placeholder="" id="cmd_009" name="cmd_009"  formControlName="cmd_009"/>
                            <label class="formLabel" style="font-size: 12px;">Telephone Number </label>
                        </div>
                        <div class="col-md-4">
                          <input class="form-control" placeholder="" id="cmd_0010" name="cmd_0010"  formControlName="cmd_0010"/>
                            <label class="formLabel" style="font-size: 12px;">Fax Number</label>
                        </div>
                          <div class="col-md-4">
                            <input class="form-control onboard" formControlName="1664"
                            [class.is-invalid]="(CSCinte.controls['1664'].touched && CSCinte.controls['1664'].invalid) || (CSCinte.controls['1664'].invalid && isSubmit)"
                            id="1664" name="1664" type="text" placeholder= "Work Email" data-id="managing_contact_name">
                            <div class="formLabel col-md-11"
                                *ngIf="CSCinte.controls['1664'].touched && CSCinte.controls['1664'].invalid">
                                <small class="text-danger" *ngIf="CSCinte.controls['1664'].errors?.required">
                                    required 
                                </small>
                            </div>
                            <label class="form-label" style="font-size: 12px;">Email Address</label>
                        </div>
                        
                    
                    </div>
                </td>  
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                       
                    </div>
                    <div class="row">
                        <div class="col-md-4"> 
                            <input class="form-control onboard"  formControlName="16"
                                id="16" name="16" type="text" placeholder= "Home Address 1" data-id="managing_contact_name" [class.is-invalid]="(CSCinte.controls['16'].touched && CSCinte.controls['16'].invalid) || (CSCinte.controls['16'].invalid && isSubmit)" >
                            <div class="formLabel col-md-11"
                                *ngIf="CSCinte.controls['16'].touched && CSCinte.controls['16'].invalid">
                                <small class="text-danger" *ngIf="CSCinte.controls['16'].errors?.required">
                                    required
                                </small>
                            </div>
                            <label class="form-label" style="font-size: 12px;">Home Address 1</label>
                        </div>
                        <div class="col-md-4"> 
                            <input class="form-control onboard"  formControlName="17"
                                id="17" name="17" type="text" placeholder= "Home Address 2" data-id="managing_contact_name" [class.is-invalid]="(CSCinte.controls['17'].touched && CSCinte.controls['17'].invalid) || (CSCinte.controls['17'].invalid && isSubmit)" >
                            <div class="formLabel col-md-11"
                                *ngIf="CSCinte.controls['17'].touched && CSCinte.controls['17'].invalid">
                                <small class="text-danger" *ngIf="CSCinte.controls['17'].errors?.required">
                                    required
                                </small>
                            </div>
                            <label class="form-label" style="font-size: 12px;">Home Address 2 </label>
                        </div>
                        <div class="col-md-4"> 
                            <input class="form-control onboard"  formControlName="709"
                                id="709" name="709" type="text" placeholder= "Mobile Phone" data-id="managing_contact_name" [class.is-invalid]="(CSCinte.controls['709'].touched && CSCinte.controls['709'].invalid) || (CSCinte.controls['709'].invalid && isSubmit)" >
                            <div class="formLabel col-md-11"
                                *ngIf="CSCinte.controls['709'].touched && CSCinte.controls['709'].invalid">
                                <small class="text-danger" *ngIf="CSCinte.controls['709'].errors?.required">
                                    required
                                </small>
                            </div>
                            <label class="form-label" style="font-size: 12px;">Home/CellPhone Number</label>
                        </div>
                       
                    </div>
                </td>  
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                        <div class="col-md-4">
                            <input
                              class="form-control" placeholder="Home City"
                              [class.is-invalid]="
                                (CSCinte.controls['18'].touched &&
                                CSCinte.controls['18'].invalid) ||
                                (CSCinte.controls['18'].invalid && isSubmit)
                              "
                              id="18"
                              name="18"
                              formControlName="18"
                              type="text"
                              data-id="homecity"
                            />
                            <div
                              class="formLabel col-md-2"
                              *ngIf="
                              CSCinte.controls['18'].touched &&
                              CSCinte.controls['18'].invalid
                              "
                            >
                              <small
                                class="text-danger"
                                *ngIf="CSCinte.controls['18'].errors?.required"
                              >
                                required
                              </small>
                            </div>
                            <label class="formLabel" style="font-size: 12px;">City</label>

                          </div>
                          <div class="form-group col-md-4 sideLabelAlignment">
                            
                            <div class="form-group col-md-12 dropDrownSet">
                              <ng-select
                                [items]="stateDropDownDate"
                                [class.is-invalid]="
                                  (CSCinte.controls['20'].touched &&
                                  CSCinte.controls['20'].invalid) ||
                                  (CSCinte.controls['20'].invalid && isSubmit)
                                "
                                bindLabel="name"
                                placeholder="State or Country"
                                bindValue="id"
                                formControlName="20"
                              >
                              </ng-select>
                              <div
                                class="formLabel col-md-2"
                                *ngIf="
                                CSCinte.controls['20'].touched &&
                                CSCinte.controls['20'].invalid
                                "
                              >
                                <small
                                  class="text-danger"
                                  *ngIf="CSCinte.controls['20'].errors?.required"
                                >
                                  required
                                </small>
                              </div>
                              <label class="formLabel " style="font-size: 12px;">State</label>
                            </div>
                          </div>
                          <div class="col-md-4"> 
                            <input class="form-control onboard"  formControlName="21"
                                id="21" name="21" type="text" placeholder= "Home Zip" data-id="managing_contact_name" [class.is-invalid]="(CSCinte.controls['21'].touched && CSCinte.controls['21'].invalid) || (CSCinte.controls['21'].invalid && isSubmit)" >
                            <div class="formLabel col-md-11"
                                *ngIf="CSCinte.controls['21'].touched && CSCinte.controls['21'].invalid">
                                <small class="text-danger" *ngIf="CSCinte.controls['21'].errors?.required">
                                    required
                                </small>
                            </div>
                            <label class="form-label" style="font-size: 12px;">Zip Code</label>
                        </div> 
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                        <div class="col-md-3">    
                          <div class="input-group">
                             <input
                               class="form-control onboard"
                               [class.is-invalid]="
                              (CSCinte.controls['31'].touched &&
                              CSCinte.controls['31'].invalid) ||
                              (CSCinte.controls['31'].invalid && isSubmit)
                             "
                              placeholder="DOB"
                              ngbDatepicker
                              #d5="ngbDatepicker"
                               id="31"
                                name="31"
                               formControlName="31"
                               (blur)="clearInputIfInvalidMandi('31')"
                                type="text"
                                data-id="dob_date"/>
                                <div class="input-group-append">
                              <button
                              class="btn btn-outline-secondary calendar"
                              (click)="d5.toggle()"
                              type="button">
                              <i class="fas fa-calendar-alt"></i>
                            </button>
                          </div>
                        </div>
                        <div class="text-danger"
                            *ngIf="(CSCinte.controls['31'].invalid&&CSCinte.controls['31'].touched) && (CSCinte.controls['31'].value==''|| CSCinte.controls['31'].value==null) ">
                            <small>
                                required
                            </small>
                        
                        </div>
                        <small class="text-danger" *ngIf="CSCinte.get('31').errors?.invalidDateFormat">
                            Only Date Format is allowed(MM/DD/YYYY) </small>
                        <small class="text-danger" *ngIf="CSCinte.get('31').errors?.invalidMonth">
                            Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="CSCinte.get('31').errors?.invalidDateyear">
                            year is out of range(1900-2099) </small>
                        <small class="text-danger" *ngIf="CSCinte.get('31').errors?.invalidDateRange">
                            date is out of range
                        </small>
                        <label class="form-label" style="font-size: 12px;">Date of Birth</label>
                      </div>
                     
                      <div class="col-md-3"> 
                        <input class="form-control onboard"  formControlName="810"
                            id="810" name="810" type="text" placeholder= "City of Birth" data-id="managing_contact_name" [class.is-invalid]="(CSCinte.controls['810'].touched && CSCinte.controls['810'].invalid) || (CSCinte.controls['810'].invalid && isSubmit)" >
                        <div class="formLabel col-md-11"
                            *ngIf="CSCinte.controls['810'].touched && CSCinte.controls['810'].invalid">
                            <small class="text-danger" *ngIf="CSCinte.controls['810'].errors?.required">
                                required
                            </small>
                        </div>
                        <label class="form-label" style="font-size: 12px;">Place of Birth</label>
                    </div>
                    <div class="form-group col-md-3 sideLabelAlignment">
                            
                        <div class="form-group col-md-12 dropDrownSet">
                          <ng-select
                            [items]="stateDropDownDate"
                            [class.is-invalid]="
                              (CSCinte.controls['289'].touched &&
                              CSCinte.controls['289'].invalid) ||
                              (CSCinte.controls['289'].invalid && isSubmit)
                            "
                            bindLabel="name"
                            placeholder="State or Country"
                            bindValue="id"
                            formControlName="289"
                          >
                          </ng-select>
                          <div
                            class="formLabel col-md-2"
                            *ngIf="
                            CSCinte.controls['289'].touched &&
                            CSCinte.controls['289'].invalid
                            "
                          >
                            <small
                              class="text-danger"
                              *ngIf="CSCinte.controls['289'].errors?.required"
                            >
                              required
                            </small>
                          </div>
                          <label class="formLabel " style="font-size: 12px;">State</label>
                        </div>
                      </div>
                    <div class="col-md-3"> 
                        <input class="form-control onboard"  formControlName="292"
                            id="292" name="292" type="text" placeholder= "Citizenship" data-id="managing_contact_name" [class.is-invalid]="(CSCinte.controls['292'].touched && CSCinte.controls['292'].invalid) || (CSCinte.controls['292'].invalid && isSubmit)" >
                        <div class="formLabel col-md-11"
                            *ngIf="CSCinte.controls['292'].touched && CSCinte.controls['292'].invalid">
                            <small class="text-danger" *ngIf="CSCinte.controls['292'].errors?.required">
                                required
                            </small>
                        </div>
                        <label class="form-label" style="font-size: 12px;">Citizenship</label>
                    </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                        <div class="col-md-4">
                            <input class="form-control onboard"  formControlName="325"
                                id="325" name="325" type="text" placeholder= "Medicaid Number (PTAN)" [class.is-invalid]="(CSCinte.controls['325'].touched && CSCinte.controls['325'].invalid) || (CSCinte.controls['325'].invalid && isSubmit)"  data-id="managing_contact_name" >
                            <div class="formLabel col-md-11"
                                *ngIf="CSCinte.controls['325'].touched && CSCinte.controls['325'].invalid">
                                <small class="text-danger" *ngIf="CSCinte.controls['325'].errors?.required">
                                    required
                                </small>
                            </div>
                             <label class="form-label" style="font-size: 12px;">Medicare Personal ID Number</label>
                        </div>
                        <div class="col-md-4">
                            <input class="form-control onboard"  formControlName="1634"
                                id="1634" name="1634" type="text" placeholder= "NPI" [class.is-invalid]="(CSCinte.controls['1634'].touched && CSCinte.controls['1634'].invalid) || (CSCinte.controls['1634'].invalid && isSubmit)"  data-id="managing_contact_name" >
                            <div class="formLabel col-md-11"
                                *ngIf="CSCinte.controls['1634'].touched && CSCinte.controls['1634'].invalid">
                                <small class="text-danger" *ngIf="CSCinte.controls['1634'].errors?.required">
                                    required
                                </small>
                            </div>
                             <label class="form-label" style="font-size: 12px;">NPI Number</label>
                        </div> 
                        <div class="col-md-4">
                            <input class="form-control onboard"  formControlName="812"
                                id="812" name="812" type="text" placeholder= "Medicaid Number" [class.is-invalid]="(CSCinte.controls['812'].touched && CSCinte.controls['812'].invalid) || (CSCinte.controls['812'].invalid && isSubmit)"  data-id="managing_contact_name" >
                            <div class="formLabel col-md-11"
                                *ngIf="CSCinte.controls['812'].touched && CSCinte.controls['812'].invalid">
                                <small class="text-danger" *ngIf="CSCinte.controls['812'].errors?.required">
                                    required
                                </small>
                            </div>
                             <label class="form-label" style="font-size: 12px;">Medicaid Number</label>
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                        <div class="col-md-4">
                            <input class="form-control" id="2953" name="2953"  formControlName="2953" placeholder="Covering Physician Name"/>
                            <label class="formLabel" style="font-size: 12px;">Physician Providing Coverage </label>
                        </div>
                        <div class="col-md-4">
                            <input class="form-control" id="2960" name="2960"  formControlName="2960" placeholder="Covering Physician Phone"/>
                            <label class="formLabel" style="font-size: 12px;">Phone Number</label>
                        </div>
                        <div class="col-md-4"> 
                            <input class="form-control" id="2962" name="2962"  formControlName="2962" placeholder="Covering Physician Email"/>
                            <label class="formLabel" style="font-size: 12px;">Email</label>
                        </div> 
                        <div class="row">
                            <p style="margin-left: 34px; margin-top: 14px; font-size: 14px;"><b>If not a citizen of the United States, please attach a copy of your current visa</b></p>
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                  <div class="centered texasFirstTable">
                    <div class="pb-3  ">
                        <h2  style="margin-left: 31px; color: blue; font-size: 16px;"><b>Medical Licensure/Certification</b></h2>
                    </div>
                  </div>
                </td>
            </tr> 
            <tr>
                <td colspan="12">
                    <div class="row">
                         <div class="col-md-4">
                        <input class="form-control onboard"  formControlName="293"
                            id="293" name="293" type="text" placeholder= "License Number" [class.is-invalid]="(CSCinte.controls['293'].touched && CSCinte.controls['293'].invalid) || (CSCinte.controls['293'].invalid && isSubmit)"  data-id="managing_contact_name" >
                        <div class="formLabel col-md-11"
                            *ngIf="CSCinte.controls['293'].touched && CSCinte.controls['293'].invalid">
                            <small class="text-danger" *ngIf="CSCinte.controls['293'].errors?.required">
                                required
                            </small>
                        </div>
                         <label class="form-label" style="font-size: 12px;">State License Number</label>
                        </div>
                        <div class="col-md-4">
                            <div class="input-group">
                                <input type="text" placeholder="License Issue Date " (blur)="clearInputIfInvalidMandi('294')" id="294" class="form-control" formControlName="294"
                                [class.is-invalid]="(CSCinte.controls['294'].touched && CSCinte.controls['294'].invalid ) || (CSCinte.controls['294'].invalid && isSubmit)" ngbDatepicker #d0020="ngbDatepicker">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d0020.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                    </button>
                                </div>
                        </div>    
                        <div class="text-danger" *ngIf="(CSCinte.controls['294'].invalid&&CSCinte.controls['294'].touched) && (CSCinte.controls['294'].value==''|| CSCinte.controls['294'].value==null) " >
                            <small >
                                Date is required and cannot be empty
                            </small>
          
                        </div>
                          <small class="text-danger" *ngIf="CSCinte.get('294').errors?.invalidDateFormat">
                            Only Date Format is allowed(MM/DD/YYYY)                </small>
                          <small class="text-danger" *ngIf="CSCinte.get('294').errors?.invalidMonth">
                          Month is out of range
                          </small>
                          <small class="text-danger" *ngIf="CSCinte.get('294').errors?.invalidDateyear">
                            year is out of range(1900-2099)                  </small>
                            <small class="text-danger" *ngIf="CSCinte.get('294').errors?.invalidDateRange">
                              date is out of range
                              </small>
                        <!-- <div class="invalid-feedback"
                            *ngIf="(CSCinte.controls['294'].touched && CSCinte.controls['294'].invalid) || (CSCinte.controls['294'].invalid && isSubmit)">
                            Date is required and cannot be empty
                        </div> -->
                             <label class="formLabel" style="font-size: 12px;"
                                 >Original Date of Issue (MM/DD/YY)
                          </label>
                       </div>
                       <div class="col-md-4">
                        <div class="input-group">
                            <input type="text" placeholder="Licenses Expiration Date" id="295" (blur)="clearInputIfInvalidMandi('295')" class="form-control" formControlName="295"
                            [class.is-invalid]="(CSCinte.controls['295'].touched && CSCinte.controls['295'].invalid ) || (CSCinte.controls['295'].invalid && isSubmit)" ngbDatepicker #d0021="ngbDatepicker">
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary calendar" (click)="d0021.toggle()" type="button">
                                    <i class="fas fa-calendar-alt"></i>
                                </button>
                            </div>
                    </div>    
                    
                    <div class="text-danger" *ngIf="(CSCinte.controls['295'].invalid&&CSCinte.controls['295'].touched) && (CSCinte.controls['295'].value==''|| CSCinte.controls['295'].value==null) " >
                        <small >
                            Date is required and cannot be empty
                        </small>
      
                    </div>
                      <small class="text-danger" *ngIf="CSCinte.get('295').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)                </small>
                      <small class="text-danger" *ngIf="CSCinte.get('295').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="CSCinte.get('295').errors?.invalidDateyear">
                        year is out of range(1900-2099)                  </small>
                        <small class="text-danger" *ngIf="CSCinte.get('295').errors?.invalidDateRange">
                          date is out of range
                          </small>
                 
                         <label class="formLabel" style="font-size: 12px;"
                             >Expries (MM/DD/YY)
                      </label>
                   </div>
                </div>
                </td>
            </tr>
           <tr>
            <td colspan="12">
                <div class="row">
                    <div class="col-md-4">
                        <input class="form-control onboard"  formControlName="337"
                            id="337" name="337" type="text" placeholder= "State Controlled Substances Number (CDS)" [class.is-invalid]="(CSCinte.controls['337'].touched && CSCinte.controls['337'].invalid) || (CSCinte.controls['337'].invalid && isSubmit)"  data-id="managing_contact_name" >
                        <div class="formLabel col-md-11"
                            *ngIf="CSCinte.controls['337'].touched && CSCinte.controls['337'].invalid">
                            <small class="text-danger" *ngIf="CSCinte.controls['337'].errors?.required">
                                required
                            </small>
                        </div>
                         <label class="form-label" style="font-size: 12px;">Controlled Substances Certification Number (Your State Name)</label>
                        </div>
                        <div class="col-md-4">
                            <div class="input-group">
                                <input type="text" placeholder="CDS Exp Date" id="339" (blur)="clearInputIfInvalidMandi('339')" class="form-control" formControlName="339"
                                [class.is-invalid]="(CSCinte.controls['339'].touched && CSCinte.controls['339'].invalid ) || (CSCinte.controls['339'].invalid && isSubmit)" ngbDatepicker #d0022="ngbDatepicker">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d0022.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                    </button>
                                </div>
                        </div>    
                        <div class="text-danger" *ngIf="(CSCinte.controls['339'].invalid&&CSCinte.controls['339'].touched) && (CSCinte.controls['339'].value==''|| CSCinte.controls['339'].value==null) " >
                            <small >
                                Date is required and cannot be empty
                            </small>
          
                        </div>
                          <small class="text-danger" *ngIf="CSCinte.get('339').errors?.invalidDateFormat">
                            Only Date Format is allowed(MM/DD/YYYY)                </small>
                          <small class="text-danger" *ngIf="CSCinte.get('339').errors?.invalidMonth">
                          Month is out of range
                          </small>
                          <small class="text-danger" *ngIf="CSCinte.get('339').errors?.invalidDateyear">
                            year is out of range(1900-2099)                  </small>
                            <small class="text-danger" *ngIf="CSCinte.get('339').errors?.invalidDateRange">
                              date is out of range
                              </small>
                        <!-- <div class="invalid-feedback"
                            *ngIf="(CSCinte.controls['339'].touched && CSCinte.controls['339'].invalid) || (CSCinte.controls['339'].invalid && isSubmit)">
                            Date is required and cannot be empty
                        </div> -->
                             <label class="formLabel" style="font-size: 12px;"
                                 >Expries (MM/DD/YY)
                          </label>
                       </div>
                </div>
            </td>
           </tr>
           <tr>
            <td colspan="12">
                <div class="row">
                    <div class="col-md-4">
                        <input class="form-control onboard"  formControlName="309"
                            id="309" name="309" type="text" placeholder= "DEA Number " [class.is-invalid]="(CSCinte.controls['309'].touched && CSCinte.controls['309'].invalid) || (CSCinte.controls['309'].invalid && isSubmit)"  data-id="managing_contact_name" >
                        <div class="formLabel col-md-11"
                            *ngIf="CSCinte.controls['309'].touched && CSCinte.controls['309'].invalid">
                            <small class="text-danger" *ngIf="CSCinte.controls['309'].errors?.required">
                                required
                            </small>
                        </div>
                         <label class="form-label" style="font-size: 12px;">DEA Registration Number</label>
                        </div>
                        <div class="col-md-4">
                            <div class="input-group">
                                <input type="text" placeholder="DEA Exp Date" id="311" (blur)="clearInputIfInvalidMandi('311')" class="form-control" formControlName="311"
                                [class.is-invalid]="(CSCinte.controls['311'].touched && CSCinte.controls['311'].invalid ) || (CSCinte.controls['311'].invalid && isSubmit)" ngbDatepicker #d0023="ngbDatepicker">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d0023.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                    </button>
                                </div>
                        </div>    
                        <div class="text-danger" *ngIf="(CSCinte.controls['311'].invalid&&CSCinte.controls['311'].touched) && (CSCinte.controls['311'].value==''|| CSCinte.controls['311'].value==null) " >
                            <small >
                                Date is required and cannot be empty
                            </small>
          
                        </div>
                          <small class="text-danger" *ngIf="CSCinte.get('311').errors?.invalidDateFormat">
                            Only Date Format is allowed(MM/DD/YYYY)                </small>
                          <small class="text-danger" *ngIf="CSCinte.get('311').errors?.invalidMonth">
                          Month is out of range
                          </small>
                          <small class="text-danger" *ngIf="CSCinte.get('311').errors?.invalidDateyear">
                            year is out of range(1900-2099)                  </small>
                            <small class="text-danger" *ngIf="CSCinte.get('311').errors?.invalidDateRange">
                              date is out of range
                              </small>
                        <!-- <div class="invalid-feedback"
                            *ngIf="(CSCinte.controls['311'].touched && CSCinte.controls['311'].invalid) || (CSCinte.controls['311'].invalid && isSubmit)">
                            Date is required and cannot be empty
                        </div> -->
                             <label class="formLabel" style="font-size: 12px;"
                                 >Expries (MM/DD/YY)
                          </label>
                       </div>
                </div>
            </td>
           </tr>
           <tr>
            <td colspan="12">
              <div class="centered texasFirstTable">
                <div class="pb-3  ">
                    <h2  style="margin-left: 31px; color: blue; font-size: 16px; "><b>Other State Medical Licenses - Past and Present</b></h2>
                </div>
              </div>
            </td>
        </tr>
        <tr>
            <td colspan="12">
                <div class="row">
                    <div class="col-md-4">
                        <input class="form-control" id="300" name="300"  formControlName="300" placeholder="License 2 Number"/>
                        <label class="formLabel" style="font-size: 12px;">State License Number </label>
                    </div>
                    <div class="col-md-4">
                        <div class="input-group">
                            <input type="text" placeholder="License 2 Issue Number" ngbDatepicker #d292="ngbDatepicker"  (blur)="clearInputIfInvalid('307')" id="307" class="form-control" formControlName="307"
                            >
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d292.toggle()" type="button">
                                    <i class="fas fa-calendar-alt"></i>
                                    </button>
                                </div>
                        </div>
                        <div  *ngIf="CSCinte.get('307').invalid ">
                            <small class="text-danger" *ngIf="CSCinte.get('307').errors?.invalidDateFormat">
                              Only Date Format is allowed(MM/DD/YYYY)
                            </small>
                            <small class="text-danger" *ngIf="CSCinte.get('307').errors?.invalidMonth">
                            Month is out of range
                            </small>
                            <small class="text-danger" *ngIf="CSCinte.get('307').errors?.invalidDateyear">
                              year is out of range(1900-2099)                      </small>
                              <small class="text-danger" *ngIf="CSCinte.get('307').errors?.invalidDateRange">
                                date is out of range
                                </small>
                            </div>
                          
                        <label class="formLabel" style="font-size: 12px;">Original Date of Issue (MM/DD/YY)</label>
                    </div>
                </div>
            </td>
            <tr>
                <td colspan="6">
                    <div class="form-group form-inline no-margin sideLabelAlignment">
                        Do you currently Practice in this State? 
                        <label class="checkbox-inline radioStyle">
                        <input
                            type="radio"
                            id="cmd_013"
                            name="cmd_013"
                            formControlName="cmd_013"
                            value="1"
                        />Yes
                        </label>
                        <label class="checkbox-inline radioStyle">
                            <input
                                type="radio"
                                id="cmd_013"
                                name="cmd_013"
                                formControlName="cmd_013"
                                value="0"
                            />No &nbsp; If No please Explain:
                        </label>
                  </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                  <div class="centered texasFirstTable">
                    <div class="pb-3  ">
                        <h2  style="margin-left: 31px; color: blue; font-size: 16px;"><b>Pre-Medical Education</b></h2>
                    </div>
                  </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="503"
                                id="503" name="503" type="text" placeholder= "UG School" [class.is-invalid]="(CSCinte.controls['503'].touched && CSCinte.controls['503'].invalid) || (CSCinte.controls['503'].invalid && isSubmit)"  data-id="managing_contact_name" >
                            <div class="formLabel col-md-11"
                                *ngIf="CSCinte.controls['503'].touched && CSCinte.controls['503'].invalid">
                                <small class="text-danger" *ngIf="CSCinte.controls['503'].errors?.required">
                                    required
                                </small>
                            </div>
                             <label class="form-label" style="font-size: 12px;">College/University</label>
                            </div>
                            <div class="col-md-4"> 
                                <div
                                class=" form-group selectDropTwo degree inputPadding"
                              >
                                <ng-select
                                  [items]="degree"
                                  bindLabel="name"
                                  placeholder=" Degree"
                                  bindValue="id"
                                  formControlName="512"
                                  [class.is-invalid]="
                                    (CSCinte.controls['512'].touched &&
                                    CSCinte.controls['512'].errors) ||
                                    (CSCinte.controls['512'].errors && isSubmit)
                                  "
                                >
                                </ng-select>
                                <div
                                  class="formLabel col-md-11"
                                  *ngIf="
                                  CSCinte.controls['512'].touched &&
                                  CSCinte.controls['512'].invalid
                                  "
                                >
                                  <small
                                    class="text-danger"
                                    *ngIf="CSCinte.controls['512'].errors"
                                  >
                                    required
                                  </small>
                                </div>
                                <label class="formlabel" style="font-size: 12px;">Degree/Honors</label>
                              </div>
                            </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                        <div class="col-md-4">
                            <input class="form-control onboard"  formControlName="504"
                                id="504" name="504" type="text" placeholder= "UG School Address1" [class.is-invalid]="(CSCinte.controls['504'].touched && CSCinte.controls['504'].invalid) || (CSCinte.controls['504'].invalid && isSubmit)"  data-id="managing_contact_name" >
                            <div class="formLabel col-md-11"
                                *ngIf="CSCinte.controls['504'].touched && CSCinte.controls['504'].invalid">
                                <small class="text-danger" *ngIf="CSCinte.controls['504'].errors?.required">
                                    required
                                </small>
                            </div>
                             <label class="form-label" style="font-size: 12px;">Address 1</label>
                            </div>
                            <div class="col-md-4">
                                <input class="form-control onboard"  formControlName="505"
                                    id="505" name="505" type="text" placeholder= "UG School Address2" [class.is-invalid]="(CSCinte.controls['505'].touched && CSCinte.controls['505'].invalid) || (CSCinte.controls['505'].invalid && isSubmit)"  data-id="managing_contact_name" >
                                <div class="formLabel col-md-11"
                                    *ngIf="CSCinte.controls['505'].touched && CSCinte.controls['505'].invalid">
                                    <small class="text-danger" *ngIf="CSCinte.controls['505'].errors?.required">
                                        required
                                    </small>
                                </div>
                                 <label class="form-label" style="font-size: 12px;">Address 2</label>
                                </div>
                                <div class="col-md-4">    
                                    <div class="input-group">
                                       <input
                                         class="form-control onboard"
                                         [class.is-invalid]="
                                        (CSCinte.controls['511'].touched &&
                                        CSCinte.controls['511'].invalid) ||
                                        (CSCinte.controls['511'].invalid && isSubmit)
                                       "
                                        placeholder=""
                                        ngbDatepicker
                                        #d93="ngbDatepicker"
                                         id="511"
                                          name="511"
                                         formControlName="511"
                                         (blur)="clearInputIfInvalidMandi('511')"
                                          type="text"
                                          data-id="dob_date"/>
                                          <div class="input-group-append">
                                        <button
                                        class="btn btn-outline-secondary calendar"
                                        (click)="d93.toggle()"
                                        type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                      </button>
                                    </div>
                                  </div>
                                  <div class="text-danger" *ngIf="(CSCinte.controls['511'].invalid&&CSCinte.controls['511'].touched) && (CSCinte.controls['511'].value==''|| CSCinte.controls['511'].value==null) " >
                                    <small >
                                    required
                                    </small>
                  
                                </div>
                                  <small class="text-danger" *ngIf="CSCinte.get('511').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY)                </small>
                                  <small class="text-danger" *ngIf="CSCinte.get('511').errors?.invalidMonth">
                                  Month is out of range
                                  </small>
                                  <small class="text-danger" *ngIf="CSCinte.get('511').errors?.invalidDateyear">
                                    year is out of range(1900-2099)                  </small>
                                    <small class="text-danger" *ngIf="CSCinte.get('511').errors?.invalidDateRange">
                                      date is out of range
                                      </small>
                                  <label class="form-label" style="font-size: 12px;">Date of Graduation(MM/DD/YY)</label>
                                </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                        <div class="col-md-4">
                            <input class="form-control onboard"  formControlName="506"
                                id="506" name="506" type="text" placeholder= "UG School City" [class.is-invalid]="(CSCinte.controls['506'].touched && CSCinte.controls['506'].invalid) || (CSCinte.controls['506'].invalid && isSubmit)"  data-id="managing_contact_name" >
                            <div class="formLabel col-md-11"
                                *ngIf="CSCinte.controls['506'].touched && CSCinte.controls['506'].invalid">
                                <small class="text-danger" *ngIf="CSCinte.controls['506'].errors?.required">
                                    required
                                </small>
                            </div>
                             <label class="form-label" style="font-size: 12px;">City</label>
                            </div>
                            <div class="form-group col-md-4 sideLabelAlignment">
                                <div class="form-group col-md-12 dropDrownSet">
                                  <ng-select
                                    [items]="stateDropDownDate"
                                   
                                    bindLabel="name"
                                    placeholder="State or Country"
                                    bindValue="id"
                                    formControlName="508">
                                  </ng-select>
                                  <div
                                    class="formLabel col-md-2" >
                                    <small>
                                      
                                    </small>
                                  </div>
                                  <label class="formLabel " style="font-size: 12px;">State</label>
                                 </div>
                              </div>
                              <div class="col-md-4">
                                <input class="form-control onboard"  formControlName="509"
                                    id="509" name="509" type="text" placeholder= "UG School Zip" [class.is-invalid]="(CSCinte.controls['509'].touched && CSCinte.controls['509'].invalid) || (CSCinte.controls['509'].invalid && isSubmit)"  data-id="managing_contact_name" >
                                <div class="formLabel col-md-11"
                                    *ngIf="CSCinte.controls['509'].touched && CSCinte.controls['509'].invalid">
                                    <small class="text-danger" *ngIf="CSCinte.controls['509'].errors?.required">
                                        required
                                    </small>
                                </div>
                                 <label class="form-label" style="font-size: 12px;">Zip Code</label>
                                </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                  <div class="centered texasFirstTable">
                    <div class="row">
                    <div class="col-md-8">
                        <h2  style="margin-left: 31px; color: blue; font-size: 16px;"><b>Medical Education</b></h2>
                    </div>
                    <div class="col-md-4">
                        <label for="cmd_00113"><b>Does Not Apply:</b></label>&nbsp;&nbsp;
                        <input type="checkbox" formControlName="cmd_00113"
                            id="cmd_00113" value="1"
                            (ngModelChange)="handleNAchange('cmd_00113')">
                </div>
                  </div>
                </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="490" (blur)="handleNAchange('cmd_00113',490)"
                                id="490" name="490" type="text" placeholder= "Med School" [class.is-invalid]="(CSCinte.controls['490'].touched && CSCinte.controls['490'].invalid) || (CSCinte.controls['490'].invalid && isSubmit)"  data-id="managing_contact_name" >
                            <div class="formLabel col-md-11"
                                *ngIf="CSCinte.controls['490'].touched && CSCinte.controls['490'].invalid">
                                <small class="text-danger" *ngIf="CSCinte.controls['490'].errors?.required">
                                    required
                                </small>
                            </div>
                             <label class="form-label" style="font-size: 12px;">College/University</label>
                            </div>
                            <div class="col-md-4 form-group selectDropTwo degree inputPadding">
                            <ng-select
                              [items]="degree"
                              bindLabel="name"
                              placeholder=" Degree"
                              bindValue="id"
                              formControlName="500"
                              (blur)="handleNAchange('cmd_00113',500)"
                              [class.is-invalid]="
                                (CSCinte.controls['500'].touched &&
                                CSCinte.controls['500'].errors) ||
                                (CSCinte.controls['500'].errors && isSubmit)">
                            </ng-select>
                            <div
                              class="formLabel col-md-11"
                              *ngIf="
                              CSCinte.controls['500'].touched &&
                              CSCinte.controls['500'].invalid">
                              <small
                                class="text-danger"
                                *ngIf="CSCinte.controls['500'].errors">
                                required
                              </small>
                            </div>
                            <label class="formlabel" style="font-size: 12px;">Degree/Honors</label>
                          </div>   
                    </div>
                </td>
            </tr>
            <tr> 
                <td colspan="12">
                    <div class="row">
                        <div class="col-md-4">
                            <input class="form-control onboard"  formControlName="491" (blur)="handleNAchange('cmd_00113',491)"
                                id="491" name="491" type="text" placeholder= "Med School Address1" [class.is-invalid]="(CSCinte.controls['491'].touched && CSCinte.controls['491'].invalid) || (CSCinte.controls['491'].invalid && isSubmit)"  data-id="managing_contact_name" >
                            <div class="formLabel col-md-11"
                                *ngIf="CSCinte.controls['491'].touched && CSCinte.controls['491'].invalid">
                                <small class="text-danger" *ngIf="CSCinte.controls['491'].errors?.required">
                                    required
                                </small>
                            </div>
                             <label class="form-label" style="font-size: 12px;">Address 1</label>
                            </div>
                            <div class="col-md-4">
                                <input class="form-control onboard"  formControlName="492" (blur)="handleNAchange('cmd_00113',492)"
                                    id="492" name="492" type="text" placeholder= "Med School Address2" [class.is-invalid]="(CSCinte.controls['492'].touched && CSCinte.controls['492'].invalid) || (CSCinte.controls['492'].invalid && isSubmit)"  data-id="managing_contact_name" >
                                <div class="formLabel col-md-11"
                                    *ngIf="CSCinte.controls['492'].touched && CSCinte.controls['492'].invalid">
                                    <small class="text-danger" *ngIf="CSCinte.controls['492'].errors?.required">
                                        required
                                    </small>
                                </div>
                                 <label class="form-label" style="font-size: 12px;">Address 2</label>
                                </div>
                                <div class="col-md-4">    
                                    <div class="input-group">
                                       <input
                                         class="form-control onboard"
                                         [class.is-invalid]="
                                        (CSCinte.controls['499'].touched &&
                                        CSCinte.controls['499'].invalid) ||
                                        (CSCinte.controls['499'].invalid && isSubmit)
                                       "
                                        placeholder="Med School Completed"
                                        ngbDatepicker
                                        #d97="ngbDatepicker"
                                         id="499"
                                          name="499"
                                         formControlName="499"
                                         (blur)="clearInputIfInvalid('499','cmd_00113')"
                                         (blur)="handleNAchange('cmd_00113',499)"
                                          type="text"
                                          data-id="dob_date"/>
                                          <div class="input-group-append">
                                        <button
                                        class="btn btn-outline-secondary calendar"
                                        (click)="d97.toggle()"
                                        type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                      </button>
                                    </div>
                                  </div>
                                  <div class="text-danger" *ngIf="CSCinte.controls['499'].invalid && (CSCinte.controls['499'].value==''|| CSCinte.controls['499'].value==null) " >
                                    <small >
                                    required
                                    </small>
                                  
                                  </div>
                                  <div  *ngIf="CSCinte.get('499').invalid">
                                    <small class="text-danger" *ngIf="CSCinte.get('499').errors.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY)                  </small>
                                    <small class="text-danger" *ngIf="CSCinte.get('499').errors.invalidMonth">
                                    Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="CSCinte.get('499').errors.invalidDateyear">
                                      year is out of range(1900-2099)                    </small>
                                      <small class="text-danger" *ngIf="CSCinte.get('499').errors.invalidDateRange">
                                        date is out of range
                                        </small>
                                    </div>
                                  <label class="form-label" style="font-size: 12px;">Date of Graduation (MM/DD/YY)</label>
                                </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                        <div class="col-md-4">
                            <input class="form-control onboard"  formControlName="493" (blur)="handleNAchange('cmd_00113',493)"
                                id="493" name="493" type="text" placeholder= "Med School City" [class.is-invalid]="(CSCinte.controls['493'].touched && CSCinte.controls['493'].invalid) || (CSCinte.controls['493'].invalid && isSubmit)"  data-id="managing_contact_name" >
                            <div class="formLabel col-md-11"
                                *ngIf="CSCinte.controls['493'].touched && CSCinte.controls['493'].invalid">
                                <small class="text-danger" *ngIf="CSCinte.controls['493'].errors?.required">
                                    required
                                </small>
                            </div>
                             <label class="form-label" style="font-size: 12px;">City</label>
                            </div>
                            <div class="form-group col-md-4 sideLabelAlignment">
                            
                                <div class="form-group col-md-12 dropDrownSet">
                                  <ng-select
                                    [items]="stateDropDownDate"
                                    [class.is-invalid]="
                                      (CSCinte.controls['495'].touched &&
                                      CSCinte.controls['495'].invalid) ||
                                      (CSCinte.controls['495'].invalid && isSubmit)
                                    "
                                    bindLabel="name"
                                    placeholder="State or Country"
                                    bindValue="id"
                                    formControlName="495"
                                    (blur)="handleNAchange('cmd_00113',495)"
                                  >
                                  </ng-select>
                                  <div
                                    class="formLabel col-md-2"
                                    *ngIf="
                                    CSCinte.controls['495'].touched &&
                                    CSCinte.controls['495'].invalid
                                    "
                                  >
                                    <small
                                      class="text-danger"
                                      *ngIf="CSCinte.controls['495'].errors?.required"
                                    >
                                      required
                                    </small>
                                  </div>
                                  <label class="formLabel" style="font-size: 12px;">State</label>
                                </div>
                              </div>
                              <div class="col-md-4">
                                <input class="form-control onboard"  formControlName="496" (blur)="handleNAchange('cmd_00113',496)"
                                    id="496" name="496" type="text" placeholder= "Med School Zip" [class.is-invalid]="(CSCinte.controls['496'].touched && CSCinte.controls['496'].invalid) || (CSCinte.controls['496'].invalid && isSubmit)"  data-id="managing_contact_name" >
                                <div class="formLabel col-md-11"
                                    *ngIf="CSCinte.controls['496'].touched && CSCinte.controls['496'].invalid">
                                    <small class="text-danger" *ngIf="CSCinte.controls['496'].errors?.required">
                                        required
                                    </small>
                                </div>
                                 <label class="form-label" style="font-size: 12px;">Zip Code</label>
                                </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                    <div class="col-md-8">
                        <h2  style="margin-left: 31px; color: blue; font-size: 16px;"><b>Other Professional Education</b></h2>
                    </div>
                    <div class="col-md-4">
                        <label for="cmd_0017"><b>Does Not Apply:</b></label>&nbsp;&nbsp;
                        <input type="checkbox" formControlName="cmd_0017"
                            id="cmd_0017" value="1"
                            (ngModelChange)="handleNAchange('cmd_0017')">
                </div>
                  </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="479"
                                id="479" name="479" type="text" placeholder= "Grad School" (blur)="handleNAchange('cmd_0017',479)" [class.is-invalid]="(CSCinte.controls['479'].touched && CSCinte.controls['479'].invalid) || (CSCinte.controls['479'].invalid && isSubmit)"  data-id="managing_contact_name" >
                            <div class="formLabel col-md-11"
                                *ngIf="CSCinte.controls['479'].touched && CSCinte.controls['479'].invalid">
                                <small class="text-danger" *ngIf="CSCinte.controls['479'].errors?.required">
                                    required
                                </small>
                            </div>
                             <label class="form-label" style="font-size: 12px;">Name of Institution</label>
                            </div>
                            <div class="col-md-4 form-group selectDropTwo degree inputPadding">
                                <ng-select
                                  [items]="degree"
                                  bindLabel="name"
                                  placeholder="Degree"
                                  bindValue="id"
                                  formControlName="480"
                                  (blur)="handleNAchange('cmd_0017',480)"
                                  [class.is-invalid]="
                                    (CSCinte.controls['480'].touched &&
                                    CSCinte.controls['480'].errors) ||
                                    (CSCinte.controls['480'].errors && isSubmit)">
                                </ng-select>
                                <div
                                  class="formLabel col-md-11"
                                  *ngIf="
                                  CSCinte.controls['480'].touched &&
                                  CSCinte.controls['480'].invalid">
                                  <small
                                    class="text-danger"
                                    *ngIf="CSCinte.controls['480'].errors">
                                    required
                                  </small>
                                </div>
                                <label class="formlabel" style="font-size: 12px;">Degree/Honors</label>
                              </div> 
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                        <div class="col-md-4">
                            <input class="form-control onboard"  formControlName="483"   (blur)="handleNAchange('cmd_0017',483)"
                                id="483" name="483" type="text" placeholder= "Grad School Address 1" [class.is-invalid]="(CSCinte.controls['483'].touched && CSCinte.controls['483'].invalid) || (CSCinte.controls['483'].invalid && isSubmit)"  data-id="managing_contact_name" >
                            <div class="formLabel col-md-11"
                                *ngIf="CSCinte.controls['483'].touched && CSCinte.controls['483'].invalid">
                                <small class="text-danger" *ngIf="CSCinte.controls['483'].errors?.required">
                                    required
                                </small>
                            </div>
                             <label class="form-label" style="font-size: 12px;">Address 1</label>
                            </div>
                            <div class="col-md-4">
                                <input class="form-control onboard"  formControlName="484" (blur)="handleNAchange('cmd_0017',484)"
                                    id="484" name="484" type="text" placeholder= "Grad School Address2" [class.is-invalid]="(CSCinte.controls['484'].touched && CSCinte.controls['484'].invalid) || (CSCinte.controls['484'].invalid && isSubmit)"  data-id="managing_contact_name" >
                                <div class="formLabel col-md-11"
                                    *ngIf="CSCinte.controls['484'].touched && CSCinte.controls['484'].invalid">
                                    <small class="text-danger" *ngIf="CSCinte.controls['484'].errors?.required">
                                        required
                                    </small>
                                </div>
                                 <label class="form-label" style="font-size: 12px;">Address 2</label>
                                </div>
                                <div class="col-md-4">    
                                    <div class="input-group">
                                       <input
                                         class="form-control onboard"
                                         [class.is-invalid]="
                                        (CSCinte.controls['482'].touched &&
                                        CSCinte.controls['482'].invalid) ||
                                        (CSCinte.controls['482'].invalid && isSubmit)
                                       "
                                        placeholder="Grad School Completed Date"
                                        ngbDatepicker
                                        #d101="ngbDatepicker"
                                         id="482"
                                          name="482"
                                         formControlName="482"
                                         (blur)="clearInputIfInvalid('482','cmd_0017')"
                                         (blur)="handleNAchange('cmd_0017',482)"
                                          type="text"
                                          data-id="dob_date"/>
                                          <div class="input-group-append">
                                        <button
                                        class="btn btn-outline-secondary calendar"
                                        (click)="d101.toggle()"
                                        type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                      </button>
                                    </div>
                                  </div>
                                  <div class="text-danger" *ngIf="CSCinte.controls['482'].invalid && (CSCinte.controls['482'].value==''|| CSCinte.controls['482'].value==null) " >
                                    <small >
                                    required
                                    </small>
                                  
                                  </div>
                                  <div  *ngIf="CSCinte.get('482').invalid">
                                    <small class="text-danger" *ngIf="CSCinte.get('482').errors.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY)                  </small>
                                    <small class="text-danger" *ngIf="CSCinte.get('482').errors.invalidMonth">
                                    Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="CSCinte.get('482').errors.invalidDateyear">
                                      year is out of range(1900-2099)                    </small>
                                      <small class="text-danger" *ngIf="CSCinte.get('482').errors.invalidDateRange">
                                        date is out of range
                                        </small>
                                    </div>
                                  <label class="form-label" style="font-size: 12px;">Date of Graduation (MM/DD/YY)</label>
                                </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                        <div class="col-md-4">
                            <input class="form-control onboard"  formControlName="485" (blur)="handleNAchange('cmd_0017',485)"
                                id="485" name="485" type="text" placeholder= "Grad School City" [class.is-invalid]="(CSCinte.controls['485'].touched && CSCinte.controls['484'].invalid) || (CSCinte.controls['485'].invalid && isSubmit)"  data-id="managing_contact_name" >
                            <div class="formLabel col-md-11"
                                *ngIf="CSCinte.controls['485'].touched && CSCinte.controls['485'].invalid">
                                <small class="text-danger" *ngIf="CSCinte.controls['485'].errors?.required">
                                    required
                                </small>
                            </div>
                             <label class="form-label" style="font-size: 12px;">City</label>
                            </div>
                            <div class="form-group col-md-4 sideLabelAlignment">
                            
                                <div class="form-group col-md-12 dropDrownSet">
                                  <ng-select
                                    [items]="stateDropDownDate"
                                    [class.is-invalid]="
                                      (CSCinte.controls['487'].touched &&
                                      CSCinte.controls['487'].invalid) ||
                                      (CSCinte.controls['487'].invalid && isSubmit)
                                    "
                                    bindLabel="name"
                                    placeholder="State or Country"
                                    bindValue="id"
                                    formControlName="487"
                                    (blur)="handleNAchange('cmd_0017',487)"
                                  >
                                  </ng-select>
                                  <div
                                    class="formLabel col-md-2"
                                    *ngIf="
                                    CSCinte.controls['487'].touched &&
                                    CSCinte.controls['487'].invalid
                                    "
                                  >
                                    <small
                                      class="text-danger"
                                      *ngIf="CSCinte.controls['487'].errors?.required"
                                    >
                                      required
                                    </small>
                                  </div>
                                  <label class="formLabel" style="font-size: 12px;">State</label>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <input class="form-control onboard"  formControlName="488" (blur)="handleNAchange('cmd_0017',488)"
                                    id="488" name="488" type="text" placeholder= "Grad School Zip" [class.is-invalid]="(CSCinte.controls['488'].touched && CSCinte.controls['488'].invalid) || (CSCinte.controls['488'].invalid && isSubmit)"  data-id="managing_contact_name" >
                                <div class="formLabel col-md-11"
                                    *ngIf="CSCinte.controls['488'].touched && CSCinte.controls['488'].invalid">
                                    <small class="text-danger" *ngIf="CSCinte.controls['488'].errors?.required">
                                        required
                                    </small>
                                </div>
                                 <label class="form-label" style="font-size: 12px;">Zip Code</label>
                                </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                    <div class="col-md-8">
                        <h2  style="margin-left: 31px; color: blue; font-size: 16px;"><b>Internship</b></h2>
                    </div>
                    <div class="col-md-4">
                        <label for="cmd_0018"><b>Does Not Apply:</b></label>&nbsp;&nbsp;
                        <input type="checkbox"   formControlName="cmd_0018"
                            id="cmd_0018" value="1"
                            (ngModelChange)="handleNAchange('cmd_0018')">
                </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                        <div class="col-md-4">
                            <input class="form-control onboard"  formControlName="517" (blur)="handleNAchange('cmd_0018',517)"
                                id="517" name="517" type="text" placeholder= "Intern School" [class.is-invalid]="(CSCinte.controls['517'].touched && CSCinte.controls['517'].invalid) || (CSCinte.controls['517'].invalid && isSubmit)"  data-id="managing_contact_name" >
                            <div class="formLabel col-md-11"
                                *ngIf="CSCinte.controls['517'].touched && CSCinte.controls['517'].invalid">
                                <small class="text-danger" *ngIf="CSCinte.controls['517'].errors?.required">
                                    required
                                </small>
                            </div>
                             <label class="form-label" style="font-size: 12px;">Name of Institution</label>
                            </div>
                            <div class="col-md-4">
                                <div class="input-group">
                                    <input class="form-control onboard datepicker" formControlName="524"     (blur)="clearInputIfInvalid('524','cmd_0018')" (blur)="handleNAchange('cmd_0018',524)"
                                    [class.is-invalid]="(CSCinte.controls['524'].touched && CSCinte.controls['524'].invalid) || (CSCinte.controls['524'].invalid && isSubmit)"
                                    ngbDatepicker #d102="ngbDatepicker" id="524" name="524" type="text" placeholder= "Intern Start Date" data-id="524">
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d102.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                    </div>
                                 
                                </div>
                                <div class="text-danger" *ngIf="CSCinte.controls['524'].invalid && (CSCinte.controls['524'].value==''|| CSCinte.controls['524'].value==null) " >
                                    <small >
                                    required
                                    </small>
                                  
                                  </div>
                                  <div  *ngIf="CSCinte.get('524').invalid">
                                    <small class="text-danger" *ngIf="CSCinte.get('524').errors.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY)                  </small>
                                    <small class="text-danger" *ngIf="CSCinte.get('524').errors.invalidMonth">
                                    Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="CSCinte.get('524').errors.invalidDateyear">
                                      year is out of range(1900-2099)                    </small>
                                      <small class="text-danger" *ngIf="CSCinte.get('524').errors.invalidDateRange">
                                        date is out of range
                                        </small>
                                    </div>
                                <label class="formlabel" style="font-size: 12px;">Dates Attended 1 (MM/DD/YY)</label>

                            </div>
                                <div class="col-md-4">
                                    <div class="input-group">
                                        <input class="form-control onboard datepicker" formControlName="525"         (blur)="clearInputIfInvalid('525','cmd_0018')" (blur)="handleNAchange('cmd_0018',525)"
                                        [class.is-invalid]="(CSCinte.controls['525'].touched && CSCinte.controls['525'].invalid) || (CSCinte.controls['525'].invalid && isSubmit)"
                                        ngbDatepicker #d103="ngbDatepicker" id="525" name="525" type="text" placeholder= "Intern End Date" data-id="525">
                                        <div class="input-group-append">
                                            <button class="btn btn-outline-secondary calendar" (click)="d103.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                        </div>
                                    
                                    </div>
                                    <div class="text-danger" *ngIf="CSCinte.controls['525'].invalid && (CSCinte.controls['525'].value==''|| CSCinte.controls['525'].value==null) " >
                                        <small >
                                        required
                                        </small>
                                      
                                      </div>
                                      <div  *ngIf="CSCinte.get('525').invalid">
                                        <small class="text-danger" *ngIf="CSCinte.get('525').errors.invalidDateFormat">
                                          Only Date Format is allowed(MM/DD/YYYY)                  </small>
                                        <small class="text-danger" *ngIf="CSCinte.get('525').errors.invalidMonth">
                                        Month is out of range
                                        </small>
                                        <small class="text-danger" *ngIf="CSCinte.get('525').errors.invalidDateyear">
                                          year is out of range(1900-2099)                    </small>
                                          <small class="text-danger" *ngIf="CSCinte.get('525').errors.invalidDateRange">
                                            date is out of range
                                            </small>
                                        </div>
                                    <label class="formlabel" style="font-size: 12px;">Dates Attended 2 (MM/DD/YY)</label>

                                </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                        <div class="col-md-4">
                            <input class="form-control onboard"  formControlName="518" (blur)="handleNAchange('cmd_0018',518)"
                                id="518" name="518" type="text" placeholder= "Intern Address1" [class.is-invalid]="(CSCinte.controls['518'].touched && CSCinte.controls['518'].invalid) || (CSCinte.controls['518'].invalid && isSubmit)"  data-id="managing_contact_name" >
                            <div class="formLabel col-md-11"
                                *ngIf="CSCinte.controls['518'].touched && CSCinte.controls['518'].invalid">
                                <small class="text-danger" *ngIf="CSCinte.controls['518'].errors?.required">
                                    required
                                </small>
                            </div>
                             <label class="form-label" style="font-size: 12px;">Address 1</label>
                            </div>
                            <div class="col-md-4">
                                <input class="form-control onboard"  formControlName="519" (blur)="handleNAchange('cmd_0018',519)"
                                    id="519" name="519" type="text" placeholder= "Intern Address2" [class.is-invalid]="(CSCinte.controls['519'].touched && CSCinte.controls['519'].invalid) || (CSCinte.controls['519'].invalid && isSubmit)"  data-id="managing_contact_name" >
                                <div class="formLabel col-md-11"
                                    *ngIf="CSCinte.controls['519'].touched && CSCinte.controls['519'].invalid">
                                    <small class="text-danger" *ngIf="CSCinte.controls['519'].errors?.required">
                                        required
                                    </small>
                                </div>
                                 <label class="form-label" style="font-size: 12px;">Address 2</label>
                                </div>
                                <div class="col-md-4">
                                    <input class="form-control onboard"  formControlName="528" (blur)="handleNAchange('cmd_0018',528)"
                                        id="528" name="528" type="text" placeholder= "Intern Director" [class.is-invalid]="(CSCinte.controls['528'].touched && CSCinte.controls['528'].invalid) || (CSCinte.controls['528'].invalid && isSubmit)"  data-id="managing_contact_name" >
                                      <div class="formLabel col-md-11"
                                         *ngIf="CSCinte.controls['528'].touched && CSCinte.controls['528'].invalid">
                                         <small class="text-danger" *ngIf="CSCinte.controls['528'].errors?.required">
                                            required
                                         </small>
                                     </div>
                                     <label class="form-label" style="font-size: 12px;">Full Name of Program Director or Department Chair</label>

                              </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                        <div class="col-md-4">
                            <input class="form-control onboard"  formControlName="520" (blur)="handleNAchange('cmd_0018',520)"
                                id="520" name="520" type="text" placeholder= "Intern City" [class.is-invalid]="(CSCinte.controls['520'].touched && CSCinte.controls['520'].invalid) || (CSCinte.controls['520'].invalid && isSubmit)"  data-id="managing_contact_name" >
                            <div class="formLabel col-md-11"
                                *ngIf="CSCinte.controls['520'].touched && CSCinte.controls['520'].invalid">
                                <small class="text-danger" *ngIf="CSCinte.controls['520'].errors?.required">
                                    required
                                </small>
                            </div>
                             <label class="form-label" style="font-size: 12px;">City</label>
                            </div>
                            <div class="form-group col-md-4 sideLabelAlignment">
                            
                                <div class="form-group col-md-12 dropDrownSet">
                                  <ng-select 
                                    [items]="stateDropDownDate"
                                    [class.is-invalid]="
                                      (CSCinte.controls['522'].touched &&
                                      CSCinte.controls['522'].invalid) ||
                                      (CSCinte.controls['522'].invalid && isSubmit)
                                    "
                                    bindLabel="name"
                                    placeholder="State or Country"
                                    bindValue="id"
                                    formControlName="522"
                                    (blur)="handleNAchange('cmd_0018',522)"
                                  >
                                  </ng-select>
                                  <div
                                    class="formLabel col-md-2"
                                    *ngIf="
                                    CSCinte.controls['522'].touched &&
                                    CSCinte.controls['522'].invalid
                                    "
                                  >
                                    <small
                                      class="text-danger"
                                      *ngIf="CSCinte.controls['522'].errors?.required"
                                    >
                                      required
                                    </small>
                                  </div>
                                  <label class="formLabel" style="font-size: 12px;">State</label>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <input class="form-control onboard"  formControlName="523" (blur)="handleNAchange('cmd_0018',523)"
                                    id="523" name="523" type="text" placeholder= "Intern Zip" [class.is-invalid]="(CSCinte.controls['523'].touched && CSCinte.controls['523'].invalid) || (CSCinte.controls['523'].invalid && isSubmit)"  data-id="managing_contact_name" >
                                <div class="formLabel col-md-11"
                                    *ngIf="CSCinte.controls['523'].touched && CSCinte.controls['523'].invalid">
                                    <small class="text-danger" *ngIf="CSCinte.controls['523'].errors?.required">
                                        required
                                    </small>
                                </div>
                                 <label class="form-label" style="font-size: 12px;">Zip Code</label>
                                </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                        <div class="col-md-12">
                            <input class="form-control onboard"  formControlName="2539" (blur)="handleNAchange('cmd_0018',2539)"
                                id="2539" name="2539" type="text" placeholder= "Internship Department" [class.is-invalid]="(CSCinte.controls['2539'].touched && CSCinte.controls['2539'].invalid) || (CSCinte.controls['2539'].invalid && isSubmit)"  data-id="managing_contact_name" >
                            <div class="formLabel col-md-11"
                                *ngIf="CSCinte.controls['2539'].touched && CSCinte.controls['2539'].invalid">
                                <small class="text-danger" *ngIf="CSCinte.controls['2539'].errors?.required">
                                    required
                                </small>
                            </div>
                             <label class="form-label" style="font-size: 12px;">Type of Internship (e.g., Medical, Surgical)
                            </label>
                            </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                    <div class="col-md-6">
                        <label class="formLabel" style="font-size: 14px;">Program successfully completed? If no, attach an explanation</label>
                    </div>
                    <div class="col-md-3">
                        <input class="form-control onboard"  formControlName="cmd_00115"  (blur)="handleYesNoChanges('cmd_0019')" 
                            id="cmd_00115" name="cmd_00115" placeholder="If No,Explanation" type="text"  [class.is-invalid]=" CSCinte.controls['cmd_00115'].invalid"  data-id="managing_contact_name" >
                        <div class="col-md-11 mandatory"
                            *ngIf="CSCinte.controls['cmd_00115'].invalid">
                            <small class="text-danger" >
                                required
                            </small>
                        </div>
                        
                        </div>
                    <div class="col-md-2">
                        <label class="radio-inline radioStyle">
                            <input
                                type="radio"
                                id="cmd_0019"
                                name="cmd_0019"
                                [class.is-invalid]="(CSCinte.controls['cmd_0019'].touched 
                                && CSCinte.controls['cmd_0019'].invalid) || 
                                (CSCinte.controls['cmd_0019'].invalid && isSubmit)"
                            formControlName="cmd_0019"
                            (blur)="handleNAchange('cmd_0018','cmd_0019')"
                            (ngModelChange)="handleYesNoChanges('cmd_0019')"

                            value="1"

                            />Yes</label>
                            <label class="radio-inline radioStyle">
                            <input
                                 type="radio"
                                 id="cmd_0019"
                                name="cmd_0019"
                                [class.is-invalid]="(CSCinte.controls['cmd_0019'].touched 
                                && CSCinte.controls['cmd_0019'].invalid) || 
                                (CSCinte.controls['cmd_0019'].invalid && isSubmit)"
                            formControlName="cmd_0019"  
                            (blur)="handleNAchange('cmd_0018','cmd_0019')"
                            (ngModelChange)="handleYesNoChanges('cmd_0019')"
                            value="0"

                            />No</label>
                            <div class="formLabel"
                    *ngIf="CSCinte.controls['cmd_0019'].touched && CSCinte.controls['cmd_0019'].invalid">
                    <small class="text-danger" *ngIf="CSCinte.controls['cmd_0019'].errors?.required">
                        required
                    </small>
                   </div>
                   <span *ngIf="(CSCinte.controls['cmd_0019'].touched && CSCinte.controls['cmd_0019'].errors)
                   || (CSCinte.controls['cmd_0019'].errors && isSubmit)">
                 <i class="fas fa-times ml-2 text-danger"></i>
                    </span>
            </div>
                </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                            <div class="col-md-2" style="margin-right: -120px;">
                                <label class="radio-inline radioStyle">
                                    <input
                                        type="radio"
                                        id="cmd_00111"
                                        name="cmd_00111"
                                        formControlName="cmd_00111"
                                        value="1"
                                    /></label>
                                    
                            </div>
                        <h6>Rotating</h6>
                        <div class="col-md-2" style="margin-right: -120px;">
                            <label class="radio-inline radioStyle">
                                <input
                                    type="radio"
                                    id="cmd_00112"
                                    name="cmd_00112"
                                    formControlName="cmd_00112"
                                    value="1"
                                /></label>
                                                        </div>
                        <h6> Straight</h6>
                        <div class="col-md-2">
                            <label class="normalParagraphStyle"
                            >If straight, list specialty: </label
                            >
                        </div>
                        
                        <div class="col-md-3">
                            <div class="form-group selectDrop dropDrownSet">
                              <ng-select [items]="specialty" bindLabel="name"
                                 
                                  placeholder="Primary Specialities" bindValue="id" formControlName="556">
                              </ng-select>
                             
                          </div>
                        </div>
                    </div>
                </td>
              </tr>
              <tr>
                <td colspan="12">
                    <div class="row">
                        <div class="col-md-6">
                            <label class="formLabel" style="font-size: 12px;">Were you the subject of any disciplinary actions during your attendance at this institution? </label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-2">
                            <label class="formLabel" style="font-size: 12px;">If Yes, attach an explanation</label>
                        </div>
                        <div class="col-md-2">
                            <input class="form-control onboard"  formControlName="cmd_00122"  (blur)="handleYesNoChanges('cmd_0021')" 
                                id="cmd_00122" name="cmd_00122" placeholder="If Yes,Explanation" type="text"  [class.is-invalid]=" CSCinte.controls['cmd_00122'].invalid"  data-id="managing_contact_name" >
                            <div class="col-md-11 mandatory"
                                *ngIf="CSCinte.controls['cmd_00122'].invalid">
                                <small class="text-danger" >
                                    required
                                </small>
                            </div>
                            
                            </div>
                            <div class="col-md-2">
                                <label class="radio-inline radioStyle">
                                    <input
                                        type="radio"
                                        id="cmd_0021"
                                        name="cmd_0021"
                                        [class.is-invalid]="(CSCinte.controls['cmd_0021'].touched 
                                        && CSCinte.controls['cmd_0021'].invalid) || 
                                        (CSCinte.controls['cmd_0021'].invalid && isSubmit)"
                                    formControlName="cmd_0021"
                                    (blur)="handleNAchange('cmd_0018','cmd_0021')"
                                    (ngModelChange)="handleYesNoChanges('cmd_0021')"
        
                                    value="1"
        
                                    />Yes</label>
                                    <label class="radio-inline radioStyle">
                                    <input
                                         type="radio"
                                         id="cmd_0021"
                                        name="cmd_0021"
                                        [class.is-invalid]="(CSCinte.controls['cmd_0021'].touched 
                                        && CSCinte.controls['cmd_0021'].invalid) || 
                                        (CSCinte.controls['cmd_0021'].invalid && isSubmit)"
                                    formControlName="cmd_0021"  
                                    (blur)="handleNAchange('cmd_0018','cmd_0021')"
                                    (ngModelChange)="handleYesNoChanges('cmd_0021')"
                                    value="0"
        
                                    />No</label>
                                    <div class="formLabel"
                            *ngIf="CSCinte.controls['cmd_0021'].touched && CSCinte.controls['cmd_0021'].invalid">
                            <small class="text-danger" *ngIf="CSCinte.controls['cmd_0021'].errors?.required">
                                required
                            </small>
                           </div>
                           <span *ngIf="(CSCinte.controls['cmd_0021'].touched && CSCinte.controls['cmd_0021'].errors)
                           || (CSCinte.controls['cmd_0021'].errors && isSubmit)">
                         <i class="fas fa-times ml-2 text-danger"></i>
                            </span>
                    </div>
                        </div>
                    </td>
                </tr> 
                        <tr>
                            <td colspan="12">
                                <div class="row">
                                    <div class="col-md-2" style="margin-right: -150px;">
                                        <input   formControlName="cmd_00114"  type="checkbox" id="cmd_00114" name="cmd_00114" value="1">
                                    </div>
                                    <div class="col-md-8">
                                        <label class="formLabel" style="font-size: 12px;"> If more than one internship, check here and attach additional information including responses to the
                                            above items specific to the additional internships. </label>
                                    </div> 
                                </div>
                            </td>
                        </tr>    
          
            <tr>
                <td colspan="12">
                    <div class="row">
                        <div class="col-md-10">
                                <h2  style="margin-left: 20px; color: blue; font-size: 16px;"><b>Residency Programs</b></h2>
                        </div>
                        <div class="col-md-2">
                                <label for="cmd_0022"><b>Does Not Apply:</b></label>&nbsp;&nbsp;
                                <input type="checkbox"  formControlName="cmd_0022"
                                    id="cmd_0022" value="1"
                                    (ngModelChange)="handleNAchange('cmd_0022')">
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colsapn="12">
                    <div class="row">
                        <div class="col-md-4">
                            <input class="form-control onboard"  formControlName="529" (blur)="handleNAchange('cmd_0022',529)"
                                id="529" name="529" type="text" placeholder= "Residency School" [class.is-invalid]="(CSCinte.controls['529'].touched && CSCinte.controls['529'].invalid) || (CSCinte.controls['529'].invalid && isSubmit)"  data-id="managing_contact_name" >
                            <div class="formLabel col-md-11"
                                *ngIf="CSCinte.controls['529'].touched && CSCinte.controls['529'].invalid">
                                <small class="text-danger" *ngIf="CSCinte.controls['529'].errors?.required">
                                    required
                                </small>
                            </div>
                             <label class="form-label" style="font-size: 12px;">Name of Institution</label>
                            </div>
                            <div class="col-md-4">
                                <div class="input-group">
                                    <input class="form-control onboard datepicker" formControlName="537"    (blur)="clearInputIfInvalid('537','cmd_0022')" (blur)="handleNAchange('cmd_0022',537)"
                                    [class.is-invalid]="(CSCinte.controls['537'].touched && CSCinte.controls['524'].invalid) || (CSCinte.controls['537'].invalid && isSubmit)"
                                    ngbDatepicker #d104="ngbDatepicker" id="537" name="537" type="text" placeholder= "Residency Start Date" data-id="537">
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d104.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                    </div>
                                </div>
                                <div class="text-danger" *ngIf="CSCinte.controls['537'].invalid && (CSCinte.controls['537'].value==''|| CSCinte.controls['537'].value==null) " >
                                    <small >
                                    required
                                    </small>
                                  </div>
                                  <div  *ngIf="CSCinte.get('537').invalid">
                                    <small class="text-danger" *ngIf="CSCinte.get('537').errors.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY)                  </small>
                                    <small class="text-danger" *ngIf="CSCinte.get('537').errors.invalidMonth">
                                    Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="CSCinte.get('537').errors.invalidDateyear">
                                      year is out of range(1900-2099)                    </small>
                                      <small class="text-danger" *ngIf="CSCinte.get('537').errors.invalidDateRange">
                                        date is out of range
                                        </small>
                                    </div>
                                <label class="formlabel" style="font-size: 12px;">Dates Attended 1 (MM/DD/YY)</label>

                            </div>
                            <div class="col-md-4">
                                <div class="input-group">
                                    <input class="form-control onboard datepicker" formControlName="538" (blur)="clearInputIfInvalid('538','cmd_0022')"  (blur)="handleNAchange('cmd_0022',538)"
                                    [class.is-invalid]="(CSCinte.controls['538'].touched && CSCinte.controls['538'].invalid) || (CSCinte.controls['538'].invalid && isSubmit)"
                                    ngbDatepicker #d105="ngbDatepicker" id="538" name="538" type="text" placeholder= "Residency End Date" data-id="538">
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d105.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                    </div>
                                   
                                </div>
                                <div class="text-danger" *ngIf="CSCinte.controls['538'].invalid && (CSCinte.controls['538'].value==''|| CSCinte.controls['538'].value==null) " >
                                    <small >
                                    required
                                    </small>
                                  
                                  </div>
                                  <div  *ngIf="CSCinte.get('538').invalid">
                                    <small class="text-danger" *ngIf="CSCinte.get('538').errors.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY)                  </small>
                                    <small class="text-danger" *ngIf="CSCinte.get('538').errors.invalidMonth">
                                    Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="CSCinte.get('538').errors.invalidDateyear">
                                      year is out of range(1900-2099)                    </small>
                                      <small class="text-danger" *ngIf="CSCinte.get('538').errors.invalidDateRange">
                                        date is out of range
                                        </small>
                                    </div>
                                <label class="formlabel" style="font-size: 12px;">Dates Attended 2 (MM/DD/YY)</label>

                            </div>

                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                        <div class="col-md-4">
                            <input class="form-control onboard"  formControlName="531" (blur)="handleNAchange('cmd_0022',531)"
                                id="531" name="531" type="text" placeholder= "Residency Address1" [class.is-invalid]="(CSCinte.controls['531'].touched && CSCinte.controls['531'].invalid) || (CSCinte.controls['531'].invalid && isSubmit)"  data-id="managing_contact_name" >
                            <div class="formLabel col-md-11"
                                *ngIf="CSCinte.controls['531'].touched && CSCinte.controls['531'].invalid">
                                <small class="text-danger" *ngIf="CSCinte.controls['531'].errors?.required">
                                    required
                                </small>
                            </div>
                            <label class="formlabel" style="font-size: 12px;">Address1</label>
                        </div>
                        <div class="col-md-4">
                            <input class="form-control onboard"  formControlName="1498" (blur)="handleNAchange('cmd_0022',1498)"
                                id="1498" name="1498" type="text" placeholder= "Residency Address2" [class.is-invalid]="(CSCinte.controls['1498'].touched && CSCinte.controls['1498'].invalid) || (CSCinte.controls['1498'].invalid && isSubmit)"  data-id="managing_contact_name" >
                            <div class="formLabel col-md-11"
                                *ngIf="CSCinte.controls['1498'].touched && CSCinte.controls['1498'].invalid">
                                <small class="text-danger" *ngIf="CSCinte.controls['1498'].errors?.required">
                                    required
                                </small>
                            </div>
                            <label class="formlabel" style="font-size: 12px;">Address2</label>
                        </div>
                        <div class="col-md-4">
                            <input class="form-control onboard"  formControlName="540" (blur)="handleNAchange('cmd_0022',540)"
                                id="540" name="540" type="text" placeholder= "Residency Director" [class.is-invalid]="(CSCinte.controls['540'].touched && CSCinte.controls['540'].invalid) || (CSCinte.controls['540'].invalid && isSubmit)"  data-id="managing_contact_name" >
                            <div class="formLabel col-md-11"
                                *ngIf="CSCinte.controls['540'].touched && CSCinte.controls['540'].invalid">
                                <small class="text-danger" *ngIf="CSCinte.controls['540'].errors?.required">
                                    required
                                </small>
                            </div>
                            <label class="formlabel" style="font-size: 12px;">Full Name of program Director or Department Chair</label>
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                        <div class="col-md-4">
                            <input class="form-control onboard"  formControlName="533" (blur)="handleNAchange('cmd_0022',533)"
                                id="533" name="533" type="text" placeholder= "Residency City" [class.is-invalid]="(CSCinte.controls['533'].touched && CSCinte.controls['533'].invalid) || (CSCinte.controls['533'].invalid && isSubmit)"  data-id="managing_contact_name" >
                            <div class="formLabel col-md-11"
                                *ngIf="CSCinte.controls['533'].touched && CSCinte.controls['533'].invalid">
                                <small class="text-danger" *ngIf="CSCinte.controls['533'].errors?.required">
                                    required
                                </small>
                            </div>
                             <label class="form-label" style="font-size: 12px;">City</label>
                            </div>
                            <div class="form-group col-md-4 sideLabelAlignment">
                            
                                <div class="form-group col-md-12 dropDrownSet">
                                  <ng-select
                                    [items]="stateDropDownDate"
                                    [class.is-invalid]="
                                      (CSCinte.controls['535'].touched &&
                                      CSCinte.controls['535'].invalid) ||
                                      (CSCinte.controls['535'].invalid && isSubmit)
                                    "
                                    bindLabel="name"
                                    placeholder="State or Country"
                                    bindValue="id"
                                    formControlName="535"
                                    (blur)="handleNAchange('cmd_0022',535)"
                                  >
                                  </ng-select>
                                  <div
                                    class="formLabel col-md-2"
                                    *ngIf="
                                    CSCinte.controls['535'].touched &&
                                    CSCinte.controls['535'].invalid
                                    "
                                  >
                                    <small
                                      class="text-danger"
                                      *ngIf="CSCinte.controls['535'].errors?.required"
                                    >
                                      required
                                    </small>
                                  </div>
                                  <label class="formLabel" style="font-size: 12px;">State</label>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <input class="form-control onboard"  formControlName="536" (blur)="handleNAchange('cmd_0022',536)"
                                    id="536" name="536" type="text" placeholder= "Residency Zip" [class.is-invalid]="(CSCinte.controls['536'].touched && CSCinte.controls['536'].invalid) || (CSCinte.controls['536'].invalid && isSubmit)"  data-id="managing_contact_name" >
                                <div class="formLabel col-md-11"
                                    *ngIf="CSCinte.controls['536'].touched && CSCinte.controls['536'].invalid">
                                    <small class="text-danger" *ngIf="CSCinte.controls['536'].errors?.required">
                                        required
                                    </small>
                                </div>
                                 <label class="form-label" style="font-size: 12px;">Zip Code</label>
                                </div>

                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                        <div class="col-md-12">
                            <input class="form-control onboard"  formControlName="2540" (blur)="handleNAchange('cmd_0022',2540)"
                                id="2540" name="2540" type="text" placeholder= "Residency Department" [class.is-invalid]="(CSCinte.controls['2540'].touched && CSCinte.controls['2540'].invalid) || (CSCinte.controls['2540'].invalid && isSubmit)"  data-id="managing_contact_name" >
                            <div class="formLabel col-md-11"
                                *ngIf="CSCinte.controls['2540'].touched && CSCinte.controls['2540'].invalid">
                                <small class="text-danger" *ngIf="CSCinte.controls['2540'].errors?.required">
                                    required
                                </small>
                            </div>
                             <label class="form-label" style="font-size: 12px;">
                                Type of Residency (e.g., Medical, Surgical)</label>
                            </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                    <div class="col-md-6">
                        <label class="formLabel" style="font-size: 12px;">Program successfully completed? If no, attach an explanation</label>
                    </div>
                    <div class="col-md-3">
                        <input class="form-control onboard"  formControlName="cmd_00116"  (blur)="handleYesNoChanges('cmd_0023')" 
                            id="cmd_00116" name="cmd_00116" placeholder="If No,Explanation" type="text"  [class.is-invalid]=" CSCinte.controls['cmd_00116'].invalid"  data-id="managing_contact_name" >
                        <div class="col-md-11 mandatory"
                            *ngIf="CSCinte.controls['cmd_00116'].invalid">
                            <small class="text-danger" >
                                required
                            </small>
                        </div>
                        
                        </div>
                  
                    <div class="col-md-2">
                        <label class="radio-inline radioStyle">
                            <input
                                type="radio"
                                id="cmd_0023"
                                name="cmd_0023"
                                [class.is-invalid]="(CSCinte.controls['cmd_0023'].touched 
                                && CSCinte.controls['cmd_0023'].invalid) || 
                                (CSCinte.controls['cmd_0023'].invalid && isSubmit)"
                            formControlName="cmd_0023"
                            (blur)="handleNAchange('cmd_0022','cmd_0023')"
                            (ngModelChange)="handleYesNoChanges('cmd_0023')"

                            value="1"
                            />Yes</label>
                            <label class="radio-inline radioStyle">
                            <input
                                 type="radio"
                                 id="cmd_0023"
                                name="cmd_0023"
                                [class.is-invalid]="(CSCinte.controls['cmd_0023'].touched 
                                && CSCinte.controls['cmd_0023'].invalid) || 
                                (CSCinte.controls['cmd_0023'].invalid && isSubmit)"
                            formControlName="cmd_0023"
                            (blur)="handleNAchange('cmd_0022','cmd_0023')"
                            (ngModelChange)="handleYesNoChanges('cmd_0023')"
                            value="0"
                            />No</label>
                            <div class="formLabel"
                    *ngIf="CSCinte.controls['cmd_0023'].touched && CSCinte.controls['cmd_0023'].invalid">
                    <small class="text-danger" *ngIf="CSCinte.controls['cmd_0023'].errors?.required">
                        required
                    </small>
                   </div>
                   <span *ngIf="(CSCinte.controls['cmd_0023'].touched && CSCinte.controls['cmd_0023'].errors)
                   || (CSCinte.controls['cmd_0023'].errors && isSubmit)">
                 <i class="fas fa-times ml-2 text-danger"></i>
                    </span>
            </div>
                </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                        <div class="col-md-4">
                            <input  class="form-control" id="add_1_1170" name="add_1_1170"  formControlName="add_1_1170" placeholder="Additional Residency Schools"/>
                            <label class="formLabel" style="font-size: 12px;">Name of Institution</label>
                        </div>
                        <div class="col-md-4">
                            <div class="input-group">
                                <input class="form-control onboard datepicker" formControlName="add_1_1178"  (blur)="clearInputIfInvalid('add_1_1178')"
                                ngbDatepicker #d106="ngbDatepicker" id="add_1_1178" name="add_1_1178" type="text" placeholder= "Additional Start Date" data-id="add_1_1178">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d106.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                </div>
                                <div class="formLabel col-md-11"
                                    >
                                    
                                </div>
                            </div>
                            <div  *ngIf="CSCinte.get('add_1_1178').invalid ">
                                <small class="text-danger" *ngIf="CSCinte.get('add_1_1178').errors?.invalidDateFormat">
                                  Only Date Format is allowed(MM/DD/YYYY)
                                </small>
                                <small class="text-danger" *ngIf="CSCinte.get('add_1_1178').errors?.invalidMonth">
                                Month is out of range
                                </small>
                                <small class="text-danger" *ngIf="CSCinte.get('add_1_1178').errors?.invalidDateyear">
                                  year is out of range(1900-2099)                      </small>
                                  <small class="text-danger" *ngIf="CSCinte.get('add_1_1178').errors?.invalidDateRange">
                                    date is out of range
                                    </small>
                                </div>
                              
                            <label class="formlabel" style="font-size: 12px;">Dates Attended 1 (MM/DD/YY)</label>

                        </div>
                        <div class="col-md-4">
                            <div class="input-group">
                                <input class="form-control onboard datepicker" formControlName="add_1_1179"  (blur)="clearInputIfInvalid('add_1_1179')"
                                ngbDatepicker #d107="ngbDatepicker" id="add_1_1179" name="add_1_1179" type="text" placeholder= "Additional End Date" data-id="add_1_1179">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d107.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                </div>
                                <div class="formLabel col-md-11">
                                    
                                </div>
                            </div>
                            <div  *ngIf="CSCinte.get('add_1_1179').invalid ">
                                <small class="text-danger" *ngIf="CSCinte.get('add_1_1179').errors?.invalidDateFormat">
                                  Only Date Format is allowed(MM/DD/YYYY)
                                </small>
                                <small class="text-danger" *ngIf="CSCinte.get('add_1_1179').errors?.invalidMonth">
                                Month is out of range
                                </small>
                                <small class="text-danger" *ngIf="CSCinte.get('add_1_1179').errors?.invalidDateyear">
                                  year is out of range(1900-2099)                      </small>
                                  <small class="text-danger" *ngIf="CSCinte.get('add_1_1179').errors?.invalidDateRange">
                                    date is out of range
                                    </small>
                                </div>
                              
                            
                            <label class="formlabel" style="font-size: 12px;">Dates Attended 2 (MM/DD/YY)</label>

                        </div>

                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                        <div class="col-md-4">
                            <input  class="form-control" id="add_1_1172" name="add_1_1172"  formControlName="add_1_1172" placeholder="Additional Residency Address 1"/>
                            <label class="formLabel" style="font-size: 12px;">Address 1</label>
                        </div>
                        <div class="col-md-4">
                            <input  class="form-control" id="add_1_1173" name="add_1_1173"  formControlName="add_1_1173" placeholder="Additional Residency Address 2"/>
                            <label class="formLabel" style="font-size: 12px;">Address 2</label>
                        </div>
                        <div class="col-md-4">
                            <input  class="form-control" id="add_1_1181" name="add_1_1181"  formControlName="add_1_1181" placeholder="Additional Residency Director"/>
                            <label class="formLabel" style="font-size: 12px;">Full Name of Program Director or Department Chair</label>
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                        <div class="col-md-4">
                            <input  class="form-control" id="add_1_1174" name="add_1_1174"  formControlName="add_1_1174" placeholder="Additional Residency City"/>
                            <label class="formLabel" style="font-size: 12px;">City</label>
                        </div>
                        <div class="form-group col-md-4 sideLabelAlignment">
                            <div class="form-group col-md-12 dropDrownSet">
                              <ng-select
                                [items]="stateDropDownDate"
                               
                                bindLabel="name"
                                placeholder="State or Country"
                                bindValue="id"
                                formControlName="add_1_1176">
                              </ng-select>
                              <div
                                class="formLabel col-md-2" >
                                <small>
                                  
                                </small>
                              </div>
                              <label class="formLabel" style="font-size: 12px;">State</label>
                             </div>
                          </div>
                          <div class="col-md-4">
                            <input  class="form-control" id="add_1_1177" name="add_1_1177"  formControlName="add_1_1177" placeholder="Additional Residency Zip"/>
                            <label class="formLabel" style="font-size: 12px;">Zip Code</label>
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                        <div class="col-md-12">
                            <input  class="form-control" id="add_1_2541" name="add_1_2541"  formControlName="add_1_2541" placeholder="Additional Residency Department"/>
                            <label class="formLabel" style="font-size: 12px;">Type of Internship (e.g., Medical, Surgical)
                            </label>
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                    <div class="col-md-4">
                        <label class="formLabel" style="font-size: 12px;">Program successfully completed? If no, attach an explanation</label>
                    </div>
                    <div class="col-md-3">
                        <input class="form-control onboard"  formControlName="cmd_00121"  (blur)="handleYesNoChanges('cmd_0024')" 
                            id="cmd_00121" name="cmd_00121" placeholder="If No,Explanation" type="text"  [class.is-invalid]=" CSCinte.controls['cmd_00121'].invalid"  data-id="managing_contact_name" >
                        <div class="col-md-11 mandatory"
                            *ngIf="CSCinte.controls['cmd_00121'].invalid">
                            <small class="text-danger" >
                                required
                            </small>
                        </div>
                        
                        </div>
                        <div class="col-md-2">
                            <label class="radio-inline radioStyle">
                                <input
                                    type="radio"
                                    id="cmd_0024"
                                    name="cmd_0024"
                                    [class.is-invalid]="(CSCinte.controls['cmd_0024'].touched 
                                    && CSCinte.controls['cmd_0024'].invalid) || 
                                    (CSCinte.controls['cmd_0024'].invalid && isSubmit)"
                                formControlName="cmd_0024"
                                (blur)="handleNAchange('cmd_0022','cmd_0024')"
                                (ngModelChange)="handleYesNoChanges('cmd_0024')"
    
                                value="1"
                                />Yes</label>
                                <label class="radio-inline radioStyle">
                                <input
                                     type="radio"
                                     id="cmd_0024"
                                    name="cmd_0024"
                                    [class.is-invalid]="(CSCinte.controls['cmd_0024'].touched 
                                    && CSCinte.controls['cmd_0024'].invalid) || 
                                    (CSCinte.controls['cmd_0024'].invalid && isSubmit)"
                                formControlName="cmd_0024"
                                (blur)="handleNAchange('cmd_0022','cmd_0024')"
                                (ngModelChange)="handleYesNoChanges('cmd_0024')"
                                value="0"
                                />No</label>
                                <div class="formLabel"
                        *ngIf="CSCinte.controls['cmd_0024'].touched && CSCinte.controls['cmd_0024'].invalid">
                        <small class="text-danger" *ngIf="CSCinte.controls['cmd_0024'].errors?.required">
                            required
                        </small>
                       </div>
                       <span *ngIf="(CSCinte.controls['cmd_0024'].touched && CSCinte.controls['cmd_0024'].errors)
                       || (CSCinte.controls['cmd_0024'].errors && isSubmit)">
                     <i class="fas fa-times ml-2 text-danger"></i>
                        </span>
                </div>
                </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                    <div class="col-md-10">
                        <h2  style="margin-left: 31px; color: blue; font-size: 16px;"><b>Training, Fellowship, Preceptorships & Post Graduate Education</b></h2>
                    </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                        <div class="col-md-10">
                                <h4  style="margin-left: 20px; font-size: 14px;">List in chronological order.Give complete school or hospital name and address,including Zip code,beginning and ending dates, and name of your immediate supervisor</h4>
                        </div>
                        <div class="col-md-2">
                                <label for="cmd_0025"><b>Does Not Apply:</b></label>&nbsp;&nbsp;
                                <input type="checkbox"   formControlName="cmd_0025"
                                    id="cmd_0025" value="1"
                                    (ngModelChange)="handleNAchange('cmd_0025')">
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                        <div class="col-md-4">
                            <input class="form-control onboard"  formControlName="542" (blur)="handleNAchange('cmd_0025',542)"
                                id="542" name="542" type="text" placeholder= "Fellowship School" [class.is-invalid]="(CSCinte.controls['542'].touched && CSCinte.controls['542'].invalid) || (CSCinte.controls['542'].invalid && isSubmit)"  data-id="managing_contact_name" >
                            <div class="formLabel col-md-11"
                                *ngIf="CSCinte.controls['542'].touched && CSCinte.controls['542'].invalid">
                                <small class="text-danger" *ngIf="CSCinte.controls['542'].errors?.required">
                                    required
                                </small>
                            </div>
                             <label class="form-label" style="font-size: 12px;">Name of Institution</label>
                            </div>
                            <div class="col-md-4">
                                <div class="input-group">
                                    <input class="form-control onboard datepicker" formControlName="550" (blur)="clearInputIfInvalid('550','cmd_0025')"  (blur)="handleNAchange('cmd_0025',550)"
                                    [class.is-invalid]="(CSCinte.controls['550'].touched && CSCinte.controls['550'].invalid) || (CSCinte.controls['550'].invalid && isSubmit)"
                                    ngbDatepicker #d550="ngbDatepicker" id="550" name="550" type="text" placeholder= "Fellowship Start Date" data-id="550">
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d550.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                    </div>
                                
                                </div>
                                <div class="text-danger" *ngIf="CSCinte.controls['550'].invalid && (CSCinte.controls['550'].value==''|| CSCinte.controls['550'].value==null) " >
                                    <small >
                                    required
                                    </small>
                                  
                                  </div>
                                  <div  *ngIf="CSCinte.get('550').invalid">
                                    <small class="text-danger" *ngIf="CSCinte.get('550').errors.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY)                  </small>
                                    <small class="text-danger" *ngIf="CSCinte.get('550').errors.invalidMonth">
                                    Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="CSCinte.get('550').errors.invalidDateyear">
                                      year is out of range(1900-2099)                    </small>
                                      <small class="text-danger" *ngIf="CSCinte.get('550').errors.invalidDateRange">
                                        date is out of range
                                        </small>
                                    </div>
                                <label class="formlabel" style="font-size: 12px;">Dates Attended 1 (MM/DD/YY)</label>

                            </div>
                            <div class="col-md-4">
                                <div class="input-group">
                                    <input class="form-control onboard datepicker" formControlName="551" (blur)="clearInputIfInvalid('551','cmd_0025')" (blur)="handleNAchange('cmd_0025',551)"
                                    [class.is-invalid]="(CSCinte.controls['551'].touched && CSCinte.controls['551'].invalid) || (CSCinte.controls['551'].invalid && isSubmit)"
                                    ngbDatepicker #d108="ngbDatepicker" id="551" name="551" type="text" placeholder= "Fellowship End Date" data-id="551">
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d108.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                    </div>
                                
                                </div>
                                <div class="text-danger" *ngIf="CSCinte.controls['551'].invalid && (CSCinte.controls['551'].value==''|| CSCinte.controls['551'].value==null) " >
                                    <small >
                                    required
                                    </small>
                                  
                                  </div>
                                  <div  *ngIf="CSCinte.get('551').invalid">
                                    <small class="text-danger" *ngIf="CSCinte.get('551').errors.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY)                  </small>
                                    <small class="text-danger" *ngIf="CSCinte.get('551').errors.invalidMonth">
                                    Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="CSCinte.get('551').errors.invalidDateyear">
                                      year is out of range(1900-2099)                    </small>
                                      <small class="text-danger" *ngIf="CSCinte.get('551').errors.invalidDateRange">
                                        date is out of range
                                        </small>
                                    </div>
                                <label class="formlabel" style="font-size: 12px;">Dates Attended 2 (MM/DD/YY)</label>

                            </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                        <div class="col-md-4">
                            <input class="form-control onboard"  formControlName="544" (blur)="handleNAchange('cmd_0025',544)"
                                id="544" name="544" type="text" placeholder= "Fellowship Address 1" [class.is-invalid]="(CSCinte.controls['544'].touched && CSCinte.controls['544'].invalid) || (CSCinte.controls['544'].invalid && isSubmit)"  data-id="managing_contact_name" >
                            <div class="formLabel col-md-11"
                                *ngIf="CSCinte.controls['544'].touched && CSCinte.controls['544'].invalid">
                                <small class="text-danger" *ngIf="CSCinte.controls['544'].errors?.required">
                                    required
                                </small>
                            </div>
                            <label class="formlabel" style="font-size: 12px;">Address 1</label>
                        </div>
                        <div class="col-md-4">
                            <input class="form-control onboard"  formControlName="545" (blur)="handleNAchange('cmd_0025',545)"
                                id="545" name="545" type="text" placeholder= "Fellowship Address 2" [class.is-invalid]="(CSCinte.controls['545'].touched && CSCinte.controls['545'].invalid) || (CSCinte.controls['545'].invalid && isSubmit)"  data-id="managing_contact_name" >
                            <div class="formLabel col-md-11"
                                *ngIf="CSCinte.controls['545'].touched && CSCinte.controls['545'].invalid">
                                <small class="text-danger" *ngIf="CSCinte.controls['545'].errors?.required">
                                    required
                                </small>
                            </div>
                            <label class="formlabel" style="font-size: 12px;">Address 2</label>
                        </div>
                        <div class="col-md-4">
                            <input class="form-control onboard"  formControlName="553" (blur)="handleNAchange('cmd_0025',553)"
                                id="553" name="553" type="text" placeholder= "Fellowship Director" [class.is-invalid]="(CSCinte.controls['553'].touched && CSCinte.controls['553'].invalid) || (CSCinte.controls['553'].invalid && isSubmit)"  data-id="managing_contact_name" >
                            <div class="formLabel col-md-11"
                                *ngIf="CSCinte.controls['553'].touched && CSCinte.controls['553'].invalid">
                                <small class="text-danger" *ngIf="CSCinte.controls['553'].errors?.required">
                                    required
                                </small>
                            </div>
                            <label class="formlabel" style="font-size: 12px;">Name of Immediate Supervisor</label>
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                  <div class="row">
                    <div class="col-md-4">
                        <input class="form-control onboard"  formControlName="546" (blur)="handleNAchange('cmd_0025',546)"
                            id="546" name="546" type="text" placeholder= "Fellowship City" [class.is-invalid]="(CSCinte.controls['546'].touched && CSCinte.controls['546'].invalid) || (CSCinte.controls['546'].invalid && isSubmit)"  data-id="managing_contact_name" >
                        <div class="formLabel col-md-11"
                            *ngIf="CSCinte.controls['546'].touched && CSCinte.controls['546'].invalid">
                            <small class="text-danger" *ngIf="CSCinte.controls['546'].errors?.required">
                                required
                            </small>
                        </div>
                        <label class="formlabel" style="font-size: 12px;">City</label>
                    </div>
                    <div class="form-group col-md-4 sideLabelAlignment">
                            
                        <div class="form-group col-md-12 dropDrownSet">
                          <ng-select
                            [items]="stateDropDownDate"
                            [class.is-invalid]="
                              (CSCinte.controls['548'].touched &&
                              CSCinte.controls['548'].invalid) ||
                              (CSCinte.controls['548'].invalid && isSubmit)
                            "
                            bindLabel="name"
                            placeholder="State or Country"
                            bindValue="id"
                            formControlName="548"
                            (blur)="handleNAchange('cmd_0025',548)"
                          >
                          </ng-select>
                          <div
                            class="formLabel col-md-2"
                            *ngIf="
                            CSCinte.controls['548'].touched &&
                            CSCinte.controls['548'].invalid
                            "
                          >
                            <small
                              class="text-danger"
                              *ngIf="CSCinte.controls['548'].errors?.required"
                            >
                              required
                            </small>
                          </div>
                          <label class="formLabel" style="font-size: 12px;">State</label>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <input class="form-control onboard"  formControlName="549" (blur)="handleNAchange('cmd_0025',549)"
                            id="549" name="549" type="text" placeholder= "Fellowship Zip" [class.is-invalid]="(CSCinte.controls['549'].touched && CSCinte.controls['549'].invalid) || (CSCinte.controls['549'].invalid && isSubmit)"  data-id="managing_contact_name" >
                        <div class="formLabel col-md-11"
                            *ngIf="CSCinte.controls['549'].touched && CSCinte.controls['549'].invalid">
                            <small class="text-danger" *ngIf="CSCinte.controls['549'].errors?.required">
                                required
                            </small>
                        </div>
                        <label class="formlabel" style="font-size: 12px;">Zip Code</label>
                    </div>
                  </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                        <div class="col-md-12">
                            <input class="form-control onboard"  formControlName="2542" (blur)="handleNAchange('cmd_0025',2542)"
                                id="2542" name="2542" type="text" placeholder= "Fellowship Department" [class.is-invalid]="(CSCinte.controls['2542'].touched && CSCinte.controls['2542'].invalid) || (CSCinte.controls['2542'].invalid && isSubmit)"  data-id="managing_contact_name" >
                            <div class="formLabel col-md-11"
                                *ngIf="CSCinte.controls['2542'].touched && CSCinte.controls['2542'].invalid">
                                <small class="text-danger" *ngIf="CSCinte.controls['2542'].errors?.required">
                                    required
                                </small>
                            </div>
                            <label class="formlabel" style="font-size: 12px;">Type of Program or Fellowship(e.g., Medical, Surgical)</label>
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                    <div class="col-md-6">
                        <label class="formLabel" style="font-size: 12px;">Program successfully completed? If no, attach an explanation</label>
                    </div>
                    <div class="col-md-3">
                        <input class="form-control onboard"  formControlName="cmd_00117"  (blur)="handleYesNoChanges('cmd_0026')" 
                            id="cmd_00117" name="cmd_00117" placeholder="If No,Explanation" type="text"  [class.is-invalid]=" CSCinte.controls['cmd_00117'].invalid"  data-id="managing_contact_name" >
                        <div class="col-md-11 mandatory"
                            *ngIf="CSCinte.controls['cmd_00117'].invalid">
                            <small class="text-danger" >
                                required
                            </small>
                        </div>
                        
                        </div>
                  
                    <div class="col-md-2">
                        <label class="radio-inline radioStyle">
                            <input
                                type="radio"
                                id="cmd_0026"
                                name="cmd_0026"
                                [class.is-invalid]="(CSCinte.controls['cmd_0026'].touched 
                                && CSCinte.controls['cmd_0026'].invalid) || 
                                (CSCinte.controls['cmd_0026'].invalid && isSubmit)"
                            formControlName="cmd_0026"
                            (blur)="handleNAchange('cmd_0025','cmd_0026')"
                            (ngModelChange)="handleYesNoChanges('cmd_0026')"
                            value="1"
                            />Yes</label>
                            <label class="radio-inline radioStyle">
                            <input
                                 type="radio"
                                 id="cmd_0026"
                                name="cmd_0026"
                                [class.is-invalid]="(CSCinte.controls['cmd_0026'].touched 
                                && CSCinte.controls['cmd_0026'].invalid) || 
                                (CSCinte.controls['cmd_0026'].invalid && isSubmit)"
                            formControlName="cmd_0026"
                            (blur)="handleNAchange('cmd_0025','cmd_0026')"
                            (ngModelChange)="handleYesNoChanges('cmd_0026')"
                            value="0"
                            />No</label>
                            <div class="formLabel"
                    *ngIf="CSCinte.controls['cmd_0026'].touched && CSCinte.controls['cmd_0026'].invalid">
                    <small class="text-danger" *ngIf="CSCinte.controls['cmd_0026'].errors?.required">
                        required
                    </small>
                   </div>
                   <span *ngIf="(CSCinte.controls['cmd_0026'].touched && CSCinte.controls['cmd_0026'].errors)
                   || (CSCinte.controls['cmd_0026'].errors && isSubmit)">
                 <i class="fas fa-times ml-2 text-danger"></i>
                    </span>
            </div>
                </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                    <div class="col-md-8">
                        <label class="formLabel" style="font-size: 12px;">Were You the subject of any disciplinary actions during your attendance at this institution? If Yes,attach an explanation</label>
                    </div>
                    <div class="col-md-2">
                        <input class="form-control onboard"  formControlName="cmd_00123"  (blur)="handleYesNoChanges('cmd_0027')" 
                            id="cmd_00123" name="cmd_00123" placeholder="If Yes,Explanation" type="text"  [class.is-invalid]=" CSCinte.controls['cmd_00123'].invalid"  data-id="managing_contact_name" >
                        <div class="col-md-11 mandatory"
                            *ngIf="CSCinte.controls['cmd_00123'].invalid">
                            <small class="text-danger" >
                                required
                            </small>
                        </div>
                        
                        </div>
                        <div class="col-md-2">
                            <label class="radio-inline radioStyle">
                                <input
                                    type="radio"
                                    id="cmd_0027"
                                    name="cmd_0027"
                                    [class.is-invalid]="(CSCinte.controls['cmd_0027'].touched 
                                    && CSCinte.controls['cmd_0027'].invalid) || 
                                    (CSCinte.controls['cmd_0027'].invalid && isSubmit)"
                                formControlName="cmd_0027"
                                (blur)="handleNAchange('cmd_0025','cmd_0027')"
                                (ngModelChange)="handleYesNoChanges('cmd_0027')"
                                value="1"
                                />Yes</label>
                                <label class="radio-inline radioStyle">
                                <input
                                     type="radio"
                                     id="cmd_0027"
                                    name="cmd_0027"
                                    [class.is-invalid]="(CSCinte.controls['cmd_0027'].touched 
                                    && CSCinte.controls['cmd_0027'].invalid) || 
                                    (CSCinte.controls['cmd_0027'].invalid && isSubmit)"
                                formControlName="cmd_0027"
                                (blur)="handleNAchange('cmd_0025','cmd_0027')"
                                (ngModelChange)="handleYesNoChanges('cmd_0027')"
                                value="0"
                                />No</label>
                                <div class="formLabel"
                        *ngIf="CSCinte.controls['cmd_0027'].touched && CSCinte.controls['cmd_0027'].invalid">
                        <small class="text-danger" *ngIf="CSCinte.controls['cmd_0027'].errors?.required">
                            required
                        </small>
                       </div>
                       <span *ngIf="(CSCinte.controls['cmd_0027'].touched && CSCinte.controls['cmd_0027'].errors)
                       || (CSCinte.controls['cmd_0027'].errors && isSubmit)">
                     <i class="fas fa-times ml-2 text-danger"></i>
                        </span>
                </div>
                </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                        <div class="col-md-4">
                            <input  class="form-control" id="add_1_966" name="add_1_966"  formControlName="add_1_966" placeholder="Additional Fellowship School"/>
                            <label class="formLabel" style="font-size: 12px;">Name of Institution</label>
                        </div>
                        <div class="col-md-4">
                            <div class="input-group">
                                <input class="form-control onboard datepicker" formControlName="add_1_974"  (blur)="clearInputIfInvalid('add_1_974')"
                                ngbDatepicker #d110="ngbDatepicker" id="add_1_974" name="add_1_974" type="text" placeholder= "Additional Fellowship Start Date" data-id="add_1_974">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d110.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                </div>
                                <div class="formLabel col-md-11"
                                    >
                                    
                                </div>
                            </div>
                            <div  *ngIf="CSCinte.get('add_1_974').invalid ">
                                <small class="text-danger" *ngIf="CSCinte.get('add_1_974').errors?.invalidDateFormat">
                                  Only Date Format is allowed(MM/DD/YYYY)
                                </small>
                                <small class="text-danger" *ngIf="CSCinte.get('add_1_974').errors?.invalidMonth">
                                Month is out of range
                                </small>
                                <small class="text-danger" *ngIf="CSCinte.get('add_1_974').errors?.invalidDateyear">
                                  year is out of range(1900-2099)                      </small>
                                  <small class="text-danger" *ngIf="CSCinte.get('add_1_974').errors?.invalidDateRange">
                                    date is out of range
                                    </small>
                                </div>
                              
                            <label class="formlabel" style="font-size: 12px;">Dates Attended 1 (MM/DD/YY)</label>

                        </div>
                        <div class="col-md-4">
                            <div class="input-group">
                                <input class="form-control onboard datepicker" formControlName="add_1_975" (blur)="clearInputIfInvalid('add_1_975')"
                                ngbDatepicker #d111="ngbDatepicker" id="add_1_975" name="add_1_975" type="text" placeholder= "Additional Fellowship End Date" data-id="add_1_975">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d111.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                </div>
                                <div class="formLabel col-md-11"
                                    >
                                    
                                </div>
                            </div>
                            <div  *ngIf="CSCinte.get('add_1_975').invalid ">
                                <small class="text-danger" *ngIf="CSCinte.get('add_1_975').errors?.invalidDateFormat">
                                  Only Date Format is allowed(MM/DD/YYYY)
                                </small>
                                <small class="text-danger" *ngIf="CSCinte.get('add_1_975').errors?.invalidMonth">
                                Month is out of range
                                </small>
                                <small class="text-danger" *ngIf="CSCinte.get('add_1_975').errors?.invalidDateyear">
                                  year is out of range(1900-2099)                      </small>
                                  <small class="text-danger" *ngIf="CSCinte.get('add_1_975').errors?.invalidDateRange">
                                    date is out of range
                                    </small>
                                </div>
                              
                            <label class="formlabel" style="font-size: 12px;">Dates Attended 2 (MM/DD/YY)</label>

                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                        <div class="col-md-4">
                            <input  class="form-control" id="add_1_968" name="add_1_968"  formControlName="add_1_968" placeholder="Additional Fellowship Address 1"/>
                            <label class="formLabel" style="font-size: 12px;">Address 1</label>
                        </div>
                        <div class="col-md-4">
                            <input  class="form-control" id="add_1_969" name="add_1_969"  formControlName="add_1_969" placeholder="Additional Fellowship Address 2"/>
                            <label class="formLabel" style="font-size: 12px;">Address 2</label>
                        </div>
                        <div class="col-md-4">
                            <input  class="form-control" id="add_1_983" name="add_1_983"  formControlName="add_1_983" placeholder="Additional Fellowship Director"/>
                            <label class="formLabel" style="font-size: 12px;">Name of Immediate Supervisor</label>
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                        <div class="col-md-4">
                            <input  class="form-control" id="add_1_970" name="add_1_970"  formControlName="add_1_970" placeholder="Additional Fellowship City"/>
                            <label class="formLabel" style="font-size: 12px;">City</label>
                        </div>
                        <div class="form-group col-md-4 sideLabelAlignment">
                            <div class="form-group col-md-12 dropDrownSet">
                              <ng-select
                                [items]="stateDropDownDate"
                               
                                bindLabel="name"
                                placeholder="State or Country"
                                bindValue="id"
                                formControlName="add_1_972">
                              </ng-select>
                              <div
                                class="formLabel col-md-2" >
                                <small>
                                  
                                </small>
                              </div>
                              <label class="formLabel" style="font-size: 12px;">State</label>
                             </div>
                          </div>
                          <div class="col-md-4">
                            <input  class="form-control" id="add_1_1301" name="add_1_1301"  formControlName="add_1_1301" placeholder="Additional Fellowship Zip"/>
                            <label class="formLabel" style="font-size: 12px;">Zip Code</label>
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                        <div class="col-md-12">
                            <input  class="form-control" id="add_1_2543" name="add_1_2543"  formControlName="add_1_2543" placeholder="Additional Fellowship Department"/>
                            <label class="formLabel" style="font-size: 12px;">Type of Internship (e.g., Medical, Surgical)
                            </label>
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                    <div class="col-md-4">
                        <label class="formLabel" style="font-size: 12px;">Program successfully completed? If no, attach an explanation</label>
                    </div>
                    <div class="col-md-3">
                        <input class="form-control onboard"  formControlName="cmd_00119"  (blur)="handleYesNoChanges('cmd_0028')" 
                            id="cmd_00119" name="cmd_00119" placeholder="If No,Explanation" type="text"  [class.is-invalid]=" CSCinte.controls['cmd_00119'].invalid"  data-id="managing_contact_name" >
                        <div class="col-md-11 mandatory"
                            *ngIf="CSCinte.controls['cmd_00119'].invalid">
                            <small class="text-danger" >
                                required
                            </small>
                        </div>
                        
                        </div>
                        <div class="col-md-2">
                            <label class="radio-inline radioStyle">
                                <input
                                    type="radio"
                                    id="cmd_0028"
                                    name="cmd_0028"
                                    [class.is-invalid]="(CSCinte.controls['cmd_0028'].touched 
                                    && CSCinte.controls['cmd_0028'].invalid) || 
                                    (CSCinte.controls['cmd_0028'].invalid && isSubmit)"
                                formControlName="cmd_0028"
                                (blur)="handleNAchange('cmd_0025','cmd_0028')"
                                (ngModelChange)="handleYesNoChanges('cmd_0028')"
                                value="1"
                                />Yes</label>
                                <label class="radio-inline radioStyle">
                                <input
                                     type="radio"
                                     id="cmd_0028"
                                    name="cmd_0028"
                                    [class.is-invalid]="(CSCinte.controls['cmd_0028'].touched 
                                    && CSCinte.controls['cmd_0028'].invalid) || 
                                    (CSCinte.controls['cmd_0028'].invalid && isSubmit)"
                                formControlName="cmd_0028"
                                (blur)="handleNAchange('cmd_0025','cmd_0028')"
                                (ngModelChange)="handleYesNoChanges('cmd_0028')"
                                value="0"
                                />No</label>
                                <div class="formLabel"
                        *ngIf="CSCinte.controls['cmd_0028'].touched && CSCinte.controls['cmd_0028'].invalid">
                        <small class="text-danger" *ngIf="CSCinte.controls['cmd_0028'].errors?.required">
                            required
                        </small>
                       </div>
                       <span *ngIf="(CSCinte.controls['cmd_0028'].touched && CSCinte.controls['cmd_0028'].errors)
                       || (CSCinte.controls['cmd_0028'].errors && isSubmit)">
                     <i class="fas fa-times ml-2 text-danger"></i>
                        </span>
                </div>
                </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                    <div class="col-md-8">
                        <label class="formLabel" style="font-size: 12px;">Were You the subject of any disciplinary actions during your attendance at this institution? If Yes,attach an explanation</label>
                    </div>
                    <div class="col-md-2">
                        <input class="form-control onboard"  formControlName="cmd_00124"  (blur)="handleYesNoChanges('cmd_0029')" 
                            id="cmd_00124" name="cmd_00124" placeholder="If Yes,Explanation" type="text"  [class.is-invalid]=" CSCinte.controls['cmd_00124'].invalid"  data-id="managing_contact_name" >
                        <div class="col-md-11 mandatory"
                            *ngIf="CSCinte.controls['cmd_00124'].invalid">
                            <small class="text-danger" >
                                required
                            </small>
                        </div>
                        
                        </div>
                        <div class="col-md-2">
                            <label class="radio-inline radioStyle">
                                <input
                                    type="radio"
                                    id="cmd_0029"
                                    name="cmd_0029"
                                    [class.is-invalid]="(CSCinte.controls['cmd_0029'].touched 
                                    && CSCinte.controls['cmd_0029'].invalid) || 
                                    (CSCinte.controls['cmd_0029'].invalid && isSubmit)"
                                formControlName="cmd_0029"
                                (blur)="handleNAchange('cmd_0029','cmd_0029')"
                                (ngModelChange)="handleYesNoChanges('cmd_0029')"
                                value="1"
                                />Yes</label>
                                <label class="radio-inline radioStyle">
                                <input
                                     type="radio"
                                     id="cmd_0029"
                                    name="cmd_0029"
                                    [class.is-invalid]="(CSCinte.controls['cmd_0029'].touched 
                                    && CSCinte.controls['cmd_0029'].invalid) || 
                                    (CSCinte.controls['cmd_0029'].invalid && isSubmit)"
                                formControlName="cmd_0029"
                                (blur)="handleNAchange('cmd_0029','cmd_0029')"
                                (ngModelChange)="handleYesNoChanges('cmd_0029')"
                                value="0"
                                />No</label>
                                <div class="formLabel"
                        *ngIf="CSCinte.controls['cmd_0029'].touched && CSCinte.controls['cmd_0029'].invalid">
                        <small class="text-danger" *ngIf="CSCinte.controls['cmd_0029'].errors?.required">
                            required
                        </small>
                       </div>
                       <span *ngIf="(CSCinte.controls['cmd_0029'].touched && CSCinte.controls['cmd_0029'].errors)
                       || (CSCinte.controls['cmd_0029'].errors && isSubmit)">
                     <i class="fas fa-times ml-2 text-danger"></i>
                        </span>
                </div>
                </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                        <div class="col-md-4">
                            <input  class="form-control" id="add_2_966" name="add_2_966"  formControlName="add_2_966" placeholder="Additional Fellowship School"/>
                            <label class="formLabel" style="font-size: 12px;">Name of Institution</label>
                        </div>
                        <div class="col-md-4">
                            <div class="input-group">
                                <input class="form-control onboard datepicker" formControlName="add_2_974"  (blur)="clearInputIfInvalid('add_2_974')"
                                ngbDatepicker #d112="ngbDatepicker" id="add_2_974" name="add_2_974" type="text" placeholder= "Additional Fellowship Start Date" data-id="add_2_974">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d112.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                </div>
                                <div class="formLabel col-md-11"
                                    >
                                    
                                </div>
                            </div>
                            <div  *ngIf="CSCinte.get('add_2_974').invalid ">
                                <small class="text-danger" *ngIf="CSCinte.get('add_2_974').errors?.invalidDateFormat">
                                  Only Date Format is allowed(MM/DD/YYYY)
                                </small>
                                <small class="text-danger" *ngIf="CSCinte.get('add_2_974').errors?.invalidMonth">
                                Month is out of range
                                </small>
                                <small class="text-danger" *ngIf="CSCinte.get('add_2_974').errors?.invalidDateyear">
                                  year is out of range(1900-2099)                      </small>
                                  <small class="text-danger" *ngIf="CSCinte.get('add_2_974').errors?.invalidDateRange">
                                    date is out of range
                                    </small>
                                </div>
                              
                            
                            <label class="formlabel" style="font-size: 12px;">Dates Attended 1 (MM/DD/YY)</label>

                        </div>
                        <div class="col-md-4">
                            <div class="input-group">
                                <input class="form-control onboard datepicker" formControlName="add_2_975"  (blur)="clearInputIfInvalid('add_2_975')"
                                ngbDatepicker #d113="ngbDatepicker" id="add_2_975" name="add_2_975" type="text" placeholder= "Additional Fellowship End Date" data-id="add_2_975">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d113.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                </div>
                                <div class="formLabel col-md-11"
                                    >
                                    
                                </div>
                            </div>
                            <div  *ngIf="CSCinte.get('add_2_975').invalid ">
                                <small class="text-danger" *ngIf="CSCinte.get('add_2_975').errors?.invalidDateFormat">
                                  Only Date Format is allowed(MM/DD/YYYY)
                                </small>
                                <small class="text-danger" *ngIf="CSCinte.get('add_2_975').errors?.invalidMonth">
                                Month is out of range
                                </small>
                                <small class="text-danger" *ngIf="CSCinte.get('add_2_975').errors?.invalidDateyear">
                                  year is out of range(1900-2099)                      </small>
                                  <small class="text-danger" *ngIf="CSCinte.get('add_2_975').errors?.invalidDateRange">
                                    date is out of range
                                    </small>
                                </div>
                              
                            <label class="formlabel" style="font-size: 12px;">Dates Attended 2 (MM/DD/YY)</label>

                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                        <div class="col-md-4">
                            <input  class="form-control" id="add_2_968" name="add_2_968"  formControlName="add_2_968" placeholder="Additional Fellowship Address 1"/>
                            <label class="formLabel" style="font-size: 12px;">Address 1</label>
                        </div>
                        <div class="col-md-4">
                            <input  class="form-control" id="add_2_969" name="add_2_969"  formControlName="add_2_969" placeholder="Additional Fellowship Address 2"/>
                            <label class="formLabel" style="font-size: 12px;">Address 2</label>
                        </div>
                        <div class="col-md-4">
                            <input  class="form-control" id="add_2_983" name="add_2_983"  formControlName="add_2_983" placeholder="Additional Fellowship Director"/>
                            <label class="formLabel" style="font-size: 12px;">Name of Immediate Supervisor</label>
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                        <div class="col-md-4">
                            <input  class="form-control" id="add_2_970" name="add_2_970"  formControlName="add_2_970" placeholder="Additional Fellowship City"/>
                            <label class="formLabel" style="font-size: 12px;">City</label>
                        </div>
                        <div class="form-group col-md-4 sideLabelAlignment">
                            <div class="form-group col-md-12 dropDrownSet">
                              <ng-select
                                [items]="stateDropDownDate"
                               
                                bindLabel="name"
                                placeholder="State or Country"
                                bindValue="id"
                                formControlName="add_2_972">
                              </ng-select>
                              <div
                                class="formLabel col-md-2" >
                                <small>
                                  
                                </small>
                              </div>
                              <label class="formLabel" style="font-size: 12px;">State</label>
                             </div>
                          </div>
                          <div class="col-md-4">
                            <input  class="form-control" id="add_2_1301" name="add_2_1301"  formControlName="add_2_1301" placeholder="Additional Fellowship Zip"/>
                            <label class="formLabel" style="font-size: 12px;">Zip Code</label>
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                        <div class="col-md-12">
                            <input  class="form-control" id="add_2_2543" name="add_2_2543"  formControlName="add_2_2543" placeholder="Additional Fellowship Department"/>
                            <label class="formLabel" style="font-size: 12px;">Type of Internship (e.g., Medical, Surgical)
                            </label>
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                    <div class="col-md-6">
                        <label class="formLabel" style="font-size: 12px;">Program successfully completed? If no, attach an explanation</label>
                    </div>
                    <div class="col-md-3">
                        <input class="form-control onboard"  formControlName="cmd_00120"  (blur)="handleYesNoChanges('cmd_0030')" 
                            id="cmd_00120" name="cmd_00120" placeholder="If No,Explanation" type="text"  [class.is-invalid]=" CSCinte.controls['cmd_00120'].invalid"  data-id="managing_contact_name" >
                        <div class="col-md-11 mandatory"
                            *ngIf="CSCinte.controls['cmd_00120'].invalid">
                            <small class="text-danger" >
                                required
                            </small>
                        </div>
                        
                        </div>
                        <div class="col-md-2">
                            <label class="radio-inline radioStyle">
                                <input
                                    type="radio"
                                    id="cmd_0030"
                                    name="cmd_0030"
                                    [class.is-invalid]="(CSCinte.controls['cmd_0030'].touched 
                                    && CSCinte.controls['cmd_0030'].invalid) || 
                                    (CSCinte.controls['cmd_0030'].invalid && isSubmit)"
                                formControlName="cmd_0030"
                                (blur)="handleNAchange('cmd_0025','cmd_0030')"
                                (ngModelChange)="handleYesNoChanges('cmd_0030')"
                                value="1"
                                />Yes</label>
                                <label class="radio-inline radioStyle">
                                <input
                                     type="radio"
                                     id="cmd_0030"
                                    name="cmd_0030"
                                    [class.is-invalid]="(CSCinte.controls['cmd_0030'].touched 
                                    && CSCinte.controls['cmd_0030'].invalid) || 
                                    (CSCinte.controls['cmd_0030'].invalid && isSubmit)"
                                formControlName="cmd_0030"
                                (blur)="handleNAchange('cmd_0025','cmd_0030')"
                                (ngModelChange)="handleYesNoChanges('cmd_0030')"
                                value="0"
                                />No</label>
                                <div class="formLabel"
                        *ngIf="CSCinte.controls['cmd_0030'].touched && CSCinte.controls['cmd_0030'].invalid">
                        <small class="text-danger" *ngIf="CSCinte.controls['cmd_0030'].errors?.required">
                            required
                        </small>
                       </div>
                       <span *ngIf="(CSCinte.controls['cmd_0030'].touched && CSCinte.controls['cmd_0030'].errors)
                       || (CSCinte.controls['cmd_0030'].errors && isSubmit)">
                     <i class="fas fa-times ml-2 text-danger"></i>
                        </span>
                </div>
                </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                    <div class="col-md-8">
                        <label class="formLabel" style="font-size: 12px;">Were You the subject of any disciplinary actions during your attendance at this institution? If Yes,attach an explanation</label>
                    </div>
                    <div class="col-md-2">
                        <input class="form-control onboard"  formControlName="cmd_00125"  (blur)="handleYesNoChanges('cmd_0031')" 
                            id="cmd_00125" name="cmd_00125" placeholder="If Yes,Explanation" type="text"  [class.is-invalid]=" CSCinte.controls['cmd_00125'].invalid"  data-id="managing_contact_name" >
                        <div class="col-md-11 mandatory"
                            *ngIf="CSCinte.controls['cmd_00125'].invalid">
                            <small class="text-danger" >
                                required
                            </small>
                        </div>
                        
                        </div>
                        <div class="col-md-2">
                            <label class="radio-inline radioStyle">
                                <input
                                    type="radio"
                                    id="cmd_0031"
                                    name="cmd_0031"
                                    [class.is-invalid]="(CSCinte.controls['cmd_0031'].touched 
                                    && CSCinte.controls['cmd_0031'].invalid) || 
                                    (CSCinte.controls['cmd_0031'].invalid && isSubmit)"
                                formControlName="cmd_0031"
                                (blur)="handleNAchange('cmd_0025','cmd_0031')"
                                (ngModelChange)="handleYesNoChanges('cmd_0031')"
                                value="1"
                                />Yes</label>
                                <label class="radio-inline radioStyle">
                                <input
                                     type="radio"
                                     id="cmd_0031"
                                    name="cmd_0031"
                                    [class.is-invalid]="(CSCinte.controls['cmd_0031'].touched 
                                    && CSCinte.controls['cmd_0031'].invalid) || 
                                    (CSCinte.controls['cmd_0031'].invalid && isSubmit)"
                                formControlName="cmd_0031"
                                (blur)="handleNAchange('cmd_0025','cmd_0031')"
                                (ngModelChange)="handleYesNoChanges('cmd_0031')"
                                value="0"
                                />No</label>
                                <div class="formLabel"
                        *ngIf="CSCinte.controls['cmd_0031'].touched && CSCinte.controls['cmd_0031'].invalid">
                        <small class="text-danger" *ngIf="CSCinte.controls['cmd_0031'].errors?.required">
                            required
                        </small>
                       </div>
                       <span *ngIf="(CSCinte.controls['cmd_0031'].touched && CSCinte.controls['cmd_0031'].errors)
                       || (CSCinte.controls['cmd_0031'].errors && isSubmit)">
                     <i class="fas fa-times ml-2 text-danger"></i>
                        </span>
                </div>
                </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                    <div class="col-md-8">
                        <h2  style="margin-left: 31px; color: blue; font-size: 16px;"><b>Hospital and University Affiliations</b></h2>
                    </div>
                    <div class="col-md-4">
                        <label for="cmd_0032"><b>Does Not Apply:</b></label>&nbsp;&nbsp;
                        <input type="checkbox"   formControlName="cmd_0032"
                            id="cmd_0032" value="1"
                            (ngModelChange)="handleNAchange('cmd_0032')">
                </div>
                  </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                        <div class="col-md-12">
                            <h6 style="font-size: 12px;">List all present and past affiliations in chronological order. Indicate “Membership Status” as: Active/Courtesy,
                                etc., or Academic Title. Use additional sheets if necessary</h6>
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                        <div class="col-md-4">
                            <input class="form-control onboard"  formControlName="418" (blur)="handleNAchange('cmd_0032',418)"
                                id="418" name="418" type="text" placeholder= "Hospital Affiliation 1 Name" [class.is-invalid]="(CSCinte.controls['418'].touched && CSCinte.controls['418'].invalid) || (CSCinte.controls['418'].invalid && isSubmit)"  data-id="managing_contact_name" >
                            <div class="formLabel col-md-11"
                                *ngIf="CSCinte.controls['418'].touched && CSCinte.controls['418'].invalid">
                                <small class="text-danger" *ngIf="CSCinte.controls['418'].errors?.required">
                                    required
                                </small>
                            </div>
                            <label class="formlabel" style="font-size: 12px;">Name of Institution (1)</label>
                        </div>
                        <div class="col-md-4">
                            <div class="input-group">
                                <input class="form-control onboard datepicker" formControlName="426" (blur)="clearInputIfInvalid('426','cmd_0032')" (blur)="handleNAchange('cmd_0032',426)"
                                [class.is-invalid]="(CSCinte.controls['426'].touched && CSCinte.controls['426'].invalid) || (CSCinte.controls['426'].invalid && isSubmit)"
                                ngbDatepicker #d115="ngbDatepicker" id="426" name="426" type="text" placeholder= "Hospital Affilation 1 Initial Appointement" data-id="426">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d115.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                </div>
                             
                            </div>
                            <div class="text-danger" *ngIf="CSCinte.controls['426'].invalid && (CSCinte.controls['426'].value==''|| CSCinte.controls['426'].value==null) " >
                                <small >
                                required
                                </small>
                              
                              </div>
                              <div  *ngIf="CSCinte.get('426').invalid">
                                <small class="text-danger" *ngIf="CSCinte.get('426').errors.invalidDateFormat">
                                  Only Date Format is allowed(MM/DD/YYYY)                  </small>
                                <small class="text-danger" *ngIf="CSCinte.get('426').errors.invalidMonth">
                                Month is out of range
                                </small>
                                <small class="text-danger" *ngIf="CSCinte.get('426').errors.invalidDateyear">
                                  year is out of range(1900-2099)                    </small>
                                  <small class="text-danger" *ngIf="CSCinte.get('426').errors.invalidDateRange">
                                    date is out of range
                                    </small>
                                </div>
                            <label class="formlabel" style="font-size: 12px;">Dates Affiliated 1 (MM/DD/YY)</label>

                        </div>
                        <div class="col-md-4">
                            <div class="input-group">
                                <input class="form-control onboard datepicker" formControlName="2007" (blur)="clearInputIfInvalid('2007','cmd_0032')" (blur)="handleNAchange('cmd_0032',2007)"
                                [class.is-invalid]="(CSCinte.controls['2007'].touched && CSCinte.controls['2007'].invalid) || (CSCinte.controls['2007'].invalid && isSubmit)"
                                ngbDatepicker #d116="ngbDatepicker" id="2007" name="2007" type="text" placeholder= "Hospital Affilation 1 Next Appointement Date" data-id="2007">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d116.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                </div>
                             
                            </div>
                            <div class="text-danger" *ngIf="CSCinte.controls['2007'].invalid && (CSCinte.controls['2007'].value==''|| CSCinte.controls['2007'].value==null) " >
                                <small >
                                required
                                </small>
                              
                              </div>
                              <div  *ngIf="CSCinte.get('2007').invalid">
                                <small class="text-danger" *ngIf="CSCinte.get('2007').errors.invalidDateFormat">
                                  Only Date Format is allowed(MM/DD/YYYY)                  </small>
                                <small class="text-danger" *ngIf="CSCinte.get('2007').errors.invalidMonth">
                                Month is out of range
                                </small>
                                <small class="text-danger" *ngIf="CSCinte.get('2007').errors.invalidDateyear">
                                  year is out of range(1900-2099)                    </small>
                                  <small class="text-danger" *ngIf="CSCinte.get('2007').errors.invalidDateRange">
                                    date is out of range
                                    </small>
                                </div>
                            <label class="formlabel" style="font-size: 12px;">Dates Affiliated 2 (MM/DD/YY)</label>

                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                <div class="row">
                    <div class="col-md-4">
                        <input class="form-control onboard"  formControlName="419" (blur)="handleNAchange('cmd_0032',419)"
                            id="419" name="419" type="text" placeholder= "Hospital Affiliation 1 Address 1" [class.is-invalid]="(CSCinte.controls['419'].touched && CSCinte.controls['419'].invalid) || (CSCinte.controls['419'].invalid && isSubmit)"  data-id="managing_contact_name" >
                        <div class="formLabel col-md-11"
                            *ngIf="CSCinte.controls['419'].touched && CSCinte.controls['419'].invalid">
                            <small class="text-danger" *ngIf="CSCinte.controls['419'].errors?.required">
                                required
                            </small>
                        </div>
                        <label class="formlabel" style="font-size: 12px;">Address 1</label>
                    </div>
                    <div class="col-md-4">
                        <input class="form-control onboard"  formControlName="420" (blur)="handleNAchange('cmd_0032',420)"
                            id="420" name="420" type="text" placeholder= "Hospital Affiliation 1 Address 2" [class.is-invalid]="(CSCinte.controls['420'].touched && CSCinte.controls['420'].invalid) || (CSCinte.controls['420'].invalid && isSubmit)"  data-id="managing_contact_name" >
                        <div class="formLabel col-md-11"
                            *ngIf="CSCinte.controls['420'].touched && CSCinte.controls['420'].invalid">
                            <small class="text-danger" *ngIf="CSCinte.controls['420'].errors?.required">
                                required
                            </small>
                        </div>
                        <label class="formlabel" style="font-size: 12px;">Address 2</label>
                    </div>
                </div>

                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                        <div class="col-md-3">
                            <input class="form-control onboard"  formControlName="421" (blur)="handleNAchange('cmd_0032',421)"
                                id="421" name="421" type="text" placeholder= "Hospital Affiliation 1 City" [class.is-invalid]="(CSCinte.controls['421'].touched && CSCinte.controls['421'].invalid) || (CSCinte.controls['421'].invalid && isSubmit)"  data-id="managing_contact_name" >
                            <div class="formLabel col-md-11"
                                *ngIf="CSCinte.controls['421'].touched && CSCinte.controls['421'].invalid">
                                <small class="text-danger" *ngIf="CSCinte.controls['421'].errors?.required">
                                    required
                                </small>
                            </div>
                            <label class="formlabel" style="font-size: 12px;">City</label>
                        </div>
                        <div class="form-group col-md-3 sideLabelAlignment">
                            
                            <div class="form-group col-md-12 dropDrownSet">
                              <ng-select
                                [items]="stateDropDownDate"
                                [class.is-invalid]="
                                  (CSCinte.controls['423'].touched &&
                                  CSCinte.controls['423'].invalid) ||
                                  (CSCinte.controls['423'].invalid && isSubmit)
                                "
                                bindLabel="name"
                                placeholder="State or Country"
                                bindValue="id"
                                formControlName="423"
                                (blur)="handleNAchange('cmd_0032',423)"
                              >
                              </ng-select>
                              <div
                                class="formLabel col-md-2"
                                *ngIf="
                                CSCinte.controls['423'].touched &&
                                CSCinte.controls['423'].invalid
                                "
                              >
                                <small
                                  class="text-danger"
                                  *ngIf="CSCinte.controls['423'].errors?.required"
                                >
                                  required
                                </small>
                              </div>
                              <label class="formLabel" style="font-size: 12px;">State</label>
                            </div>
                          </div>
                          <div class="col-md-3">
                            <input class="form-control onboard"  formControlName="424" (blur)="handleNAchange('cmd_0032',424)"
                                id="424" name="424" type="text" placeholder= "Hospital Affiliation 1 Zip" [class.is-invalid]="(CSCinte.controls['424'].touched && CSCinte.controls['424'].invalid) || (CSCinte.controls['424'].invalid && isSubmit)"  data-id="managing_contact_name" >
                            <div class="formLabel col-md-11"
                                *ngIf="CSCinte.controls['424'].touched && CSCinte.controls['424'].invalid">
                                <small class="text-danger" *ngIf="CSCinte.controls['424'].errors?.required">
                                    required
                                </small>
                            </div>
                            <label class="formlabel" style="font-size: 12px;">Zip Code</label>
                        </div>
                       
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                        <div class="col-md-3">
                            <input class="form-control onboard"  formControlName="1304" (blur)="handleNAchange('cmd_0032',1304)"
                                id="1304" name="1304" type="text" placeholder= "Hospital Affiliation 1 Contact" [class.is-invalid]="(CSCinte.controls['1304'].touched && CSCinte.controls['1304'].invalid) || (CSCinte.controls['1304'].invalid && isSubmit)"  data-id="managing_contact_name" >
                            <div class="formLabel col-md-11"
                                *ngIf="CSCinte.controls['1304'].touched && CSCinte.controls['1304'].invalid">
                                <small class="text-danger" *ngIf="CSCinte.controls['1304'].errors?.required">
                                    required
                                </small>
                            </div>
                            <label class="formlabel" style="font-size: 12px;">Department Cheif/Chair(Full Name)</label>
                        </div>
                        <div class="col-md-4">
                            <div class="input-group">
                                <input class="form-control onboard datepicker" formControlName="cmd_0033" (blur)="clearInputIfInvalid('cmd_0033','cmd_0032')" (blur)="handleNAchange('cmd_0032','cmd_0033')"
                                [class.is-invalid]="(CSCinte.controls['cmd_0033'].touched && CSCinte.controls['cmd_0033'].invalid) || (CSCinte.controls['cmd_0033'].invalid && isSubmit)"
                                ngbDatepicker #d117="ngbDatepicker" id="cmd_0033" name="cmd_0033" type="text" placeholder= "" data-id="cmd_0033">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d117.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                </div>
                         
                            </div>
                            <div class="text-danger" *ngIf="CSCinte.controls['cmd_0033'].invalid && (CSCinte.controls['cmd_0033'].value==''|| CSCinte.controls['cmd_0033'].value==null) " >
                                <small >
                                required
                                </small>
                              
                              </div>
                              <div  *ngIf="CSCinte.get('cmd_0033').invalid">
                                <small class="text-danger" *ngIf="CSCinte.get('cmd_0033').errors.invalidDateFormat">
                                  Only Date Format is allowed(MM/DD/YYYY)                  </small>
                                <small class="text-danger" *ngIf="CSCinte.get('cmd_0033').errors.invalidMonth">
                                Month is out of range
                                </small>
                                <small class="text-danger" *ngIf="CSCinte.get('cmd_0033').errors.invalidDateyear">
                                  year is out of range(1900-2099)                    </small>
                                  <small class="text-danger" *ngIf="CSCinte.get('cmd_0033').errors.invalidDateRange">
                                    date is out of range
                                    </small>
                                </div>
                            <label class="formlabel" style="font-size: 12px;">Dates Affiliated 1 (MM/DD/YY)</label>

                        </div>
                        <div class="col-md-4">
                            <div class="input-group">
                                <input class="form-control onboard datepicker" formControlName="cmd_00118" (blur)="clearInputIfInvalid('cmd_00118','cmd_0032')"  (blur)="handleNAchange('cmd_0032','cmd_00118')"
                                [class.is-invalid]="(CSCinte.controls['cmd_00118'].touched && CSCinte.controls['cmd_00118'].invalid) || (CSCinte.controls['cmd_00118'].invalid && isSubmit)"
                                ngbDatepicker #d130="ngbDatepicker" id="cmd_00118" name="cmd_00118" type="text" placeholder= "" data-id="cmd_00118">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d130.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                </div>
                            
                            </div>
                            <div class="text-danger" *ngIf="CSCinte.controls['cmd_00118'].invalid && (CSCinte.controls['cmd_00118'].value==''|| CSCinte.controls['cmd_00118'].value==null) " >
                                <small >
                                required
                                </small>
                              
                              </div>
                              <div  *ngIf="CSCinte.get('cmd_00118').invalid">
                                <small class="text-danger" *ngIf="CSCinte.get('cmd_00118').errors.invalidDateFormat">
                                  Only Date Format is allowed(MM/DD/YYYY)                  </small>
                                <small class="text-danger" *ngIf="CSCinte.get('cmd_00118').errors.invalidMonth">
                                Month is out of range
                                </small>
                                <small class="text-danger" *ngIf="CSCinte.get('cmd_00118').errors.invalidDateyear">
                                  year is out of range(1900-2099)                    </small>
                                  <small class="text-danger" *ngIf="CSCinte.get('cmd_00118').errors.invalidDateRange">
                                    date is out of range
                                    </small>
                                </div>
                            <label class="formlabel" style="font-size: 12px;">Dates Affiliated 2 (MM/DD/YY)</label>

                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                    <div class="col-md-8">
                        <label class="formLabel" style="font-size: 12px;">Do you currently have Privileges at this Institution?..............</label>
                    </div>
                  
                    <div class="col-md-2">
                        <label class="radio-inline radioStyle">
                            <input
                                type="radio"
                                id="cmd_0034"
                                name="cmd_0034"
                                [class.is-invalid]="(CSCinte.controls['cmd_0034'].touched 
                                && CSCinte.controls['cmd_0034'].invalid) || 
                                (CSCinte.controls['cmd_0034'].invalid && isSubmit)"
                            formControlName="cmd_0034"
                            (blur)="handleNAchange('cmd_0032','cmd_0034')"
                            value="1"
                            />Yes</label>
                            <label class="radio-inline radioStyle">
                            <input
                                 type="radio"
                                 id="cmd_0034"
                                name="cmd_0034"
                                [class.is-invalid]="(CSCinte.controls['cmd_0034'].touched 
                                && CSCinte.controls['cmd_0034'].invalid) || 
                                (CSCinte.controls['cmd_0034'].invalid && isSubmit)"
                            formControlName="cmd_0034"
                            value="0"
                            />No</label>
                            <div class="formLabel"
                    *ngIf="CSCinte.controls['cmd_0034'].touched && CSCinte.controls['cmd_0034'].invalid">
                    <small class="text-danger" *ngIf="CSCinte.controls['cmd_0034'].errors?.required">
                        required
                    </small>
                   </div>
                   <span *ngIf="(CSCinte.controls['cmd_0034'].touched && CSCinte.controls['cmd_0034'].errors)
                   || (CSCinte.controls['cmd_0034'].errors && isSubmit)">
                 <i class="fas fa-times ml-2 text-danger"></i>
                    </span>
            </div>
                </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                    <div class="col-md-6">
                        <label class="formLabel" style="font-size: 12px;">If yes, please list the types of privileges granted (e.g., Provisional, Limited, Conditional)</label>
                    </div>
                    <div class="form-group col-md-4 sideLabelAlignment">
                            
                        <div class="form-group col-md-12 dropDrownSet">
                          <ng-select
                            [items]="staffStatus"
                            [class.is-invalid]="
                              (CSCinte.controls['894'].touched &&
                              CSCinte.controls['894'].invalid) ||
                              (CSCinte.controls['894'].invalid && isSubmit)
                            "
                            bindLabel="name"
                            placeholder="Staff Status"
                            bindValue="id"
                            formControlName="894"
                            (blur)="handleNAchange('cmd_0032',894)"
                            
                          >
                          </ng-select>
                          <div
                            class="formLabel col-md-2"
                            *ngIf="
                            CSCinte.controls['894'].touched &&
                            CSCinte.controls['894'].invalid
                            "
                          >
                            <small
                              class="text-danger"
                              *ngIf="CSCinte.controls['894'].errors?.required"
                            >
                              required
                            </small>
                          </div>
                        </div>
                      </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                        <div class="col-md-12">
                            <h6 style="font-size: 12px;">List all present and past affiliations in chronological order. Indicate “Membership Status” as: Active/Courtesy,
                                etc., or Academic Title. Use additional sheets if necessary</h6>
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                        <div class="col-md-4">
                            <input  class="form-control" id="427" name="427"  formControlName="427" placeholder="Hospital Affilation 2 Name"/>
                            <label class="formLabel" style="font-size: 12px;">Name of Institution (2)</label>
                        </div>
                        <div class="col-md-4">
                            <div class="input-group">
                                <input type="text" placeholder="Hospital Affilation 2 Initial Appointement"  ngbDatepicker #d118="ngbDatepicker" id="435" class="form-control" formControlName="435"  (blur)="clearInputIfInvalid('435')"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d118.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div  *ngIf="CSCinte.get('435').invalid ">
                                <small class="text-danger" *ngIf="CSCinte.get('435').errors?.invalidDateFormat">
                                  Only Date Format is allowed(MM/DD/YYYY)
                                </small>
                                <small class="text-danger" *ngIf="CSCinte.get('435').errors?.invalidMonth">
                                Month is out of range
                                </small>
                                <small class="text-danger" *ngIf="CSCinte.get('435').errors?.invalidDateyear">
                                  year is out of range(1900-2099)                      </small>
                                  <small class="text-danger" *ngIf="CSCinte.get('435').errors?.invalidDateRange">
                                    date is out of range
                                    </small>
                                </div>
                              
                            <label class="formLabel" style="font-size: 12px;">Dates Affiliated 1(MM/DD/YY)</label>

                        </div>
                        <div class="col-md-4">
                            <div class="input-group">
                                <input type="text" placeholder="Hospital Affilation 2 Next Appointement Date"  ngbDatepicker #d119="ngbDatepicker" id="2008" class="form-control" formControlName="2008"  (blur)="clearInputIfInvalid('2008')"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d119.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div  *ngIf="CSCinte.get('2008').invalid ">
                                <small class="text-danger" *ngIf="CSCinte.get('2008').errors?.invalidDateFormat">
                                  Only Date Format is allowed(MM/DD/YYYY)
                                </small>
                                <small class="text-danger" *ngIf="CSCinte.get('2008').errors?.invalidMonth">
                                Month is out of range
                                </small>
                                <small class="text-danger" *ngIf="CSCinte.get('2008').errors?.invalidDateyear">
                                  year is out of range(1900-2099)                      </small>
                                  <small class="text-danger" *ngIf="CSCinte.get('2008').errors?.invalidDateRange">
                                    date is out of range
                                    </small>
                                </div>
                              
                            <label class="formLabel" style="font-size: 12px;">Dates Affiliated 2(MM/DD/YY)</label>

                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                        <div class="col-md-4">
                            <input  class="form-control" id="428" name="428"  formControlName="428" placeholder="Hospital Affiliation 2 Address1 "/>
                            <label class="formLabel" style="font-size: 12px;">Address 1</label>
                        </div>
                        <div class="col-md-4">
                            <input  class="form-control" id="429" name="429"  formControlName="429" placeholder="Hospital Affiliation 2 Address2"/>
                            <label class="formLabel" style="font-size: 12px;">Address 2</label>
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                        <div class="col-md-3">
                            <input  class="form-control" id="430" name="430"  formControlName="430" placeholder="Hospital Affilation 2 City"/>
                            <label class="formLabel" style="font-size: 12px;">City</label>
                        </div>
                        <div class="form-group col-md-3 sideLabelAlignment">
                            
                            <div class="form-group col-md-12 dropDrownSet">
                              <ng-select
                                [items]="stateDropDownDate"
                                
                                bindLabel="name"
                                placeholder="State or Country"
                                bindValue="id"
                                formControlName="432"
                              >
                              </ng-select>
                              <div
                               
                              >
                               
                              </div>
                              <label class="formLabel" style="font-size: 12px;">State</label>
                            </div>
                          </div>
                          <div class="col-md-3">
                            <input  class="form-control" id="433" name="433"  formControlName="433" placeholder="Hospital Affilation 2 Zip"/>
                            <label class="formLabel" style="font-size: 12px;">Zip Code</label>
                        </div>
                       
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                        <div class="col-md-3">
                            <input  class="form-control" id="1306" name="1306"  formControlName="1306" placeholder="Hospital Affilation 2 Contact"/>
                            <label class="formLabel" style="font-size: 12px;">Department Cheif/Chair(Full Name)</label>
                        </div>
                        <div class="col-md-3">
                            <div class="input-group">
                                <input type="text" placeholder=""  ngbDatepicker #d120="ngbDatepicker" id="cmd_0035"  (blur)="clearInputIfInvalid('cmd_0035')" class="form-control" formControlName="cmd_0035"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d120.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div  *ngIf="CSCinte.get('cmd_0035').invalid ">
                                <small class="text-danger" *ngIf="CSCinte.get('cmd_0035').errors?.invalidDateFormat">
                                  Only Date Format is allowed(MM/DD/YYYY)
                                </small>
                                <small class="text-danger" *ngIf="CSCinte.get('cmd_0035').errors?.invalidMonth">
                                Month is out of range
                                </small>
                                <small class="text-danger" *ngIf="CSCinte.get('cmd_0035').errors?.invalidDateyear">
                                  year is out of range(1900-2099)                      </small>
                                  <small class="text-danger" *ngIf="CSCinte.get('cmd_0035').errors?.invalidDateRange">
                                    date is out of range
                                    </small>
                                </div>
                              
                            <label class="formLabel" style="font-size: 12px;">Dates Affiliated 1(MM/DD/YY)</label>

                        </div>
                        <div class="col-md-3">
                            <div class="input-group">
                                <input type="text" placeholder=""  ngbDatepicker #d128="ngbDatepicker" id="cmd_00126" (blur)="clearInputIfInvalid('cmd_00126')"  class="form-control" formControlName="cmd_00126"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d128.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div  *ngIf="CSCinte.get('cmd_00126').invalid ">
                                <small class="text-danger" *ngIf="CSCinte.get('cmd_00126').errors?.invalidDateFormat">
                                  Only Date Format is allowed(MM/DD/YYYY)
                                </small>
                                <small class="text-danger" *ngIf="CSCinte.get('cmd_00126').errors?.invalidMonth">
                                Month is out of range
                                </small>
                                <small class="text-danger" *ngIf="CSCinte.get('cmd_00126').errors?.invalidDateyear">
                                  year is out of range(1900-2099)                      </small>
                                  <small class="text-danger" *ngIf="CSCinte.get('cmd_00126').errors?.invalidDateRange">
                                    date is out of range
                                    </small>
                                </div>
                              
                            <label class="formLabel" style="font-size: 12px;">Dates Affiliated 2(MM/DD/YY)</label>

                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                    <div class="col-md-8">
                        <label class="formLabel" style="font-size: 12px;">Do you currently have Privileges at this Institution?..............</label>
                    </div>
                  
                    <div class="col-md-4">
                        <label class="radio-inline radioStyle">
                            <input
                                type="radio"
                                id="cmd_0036"
                                name="cmd_0036"
                                formControlName="cmd_0036"
                                value="1"
                            />Yes</label>
                            <label class="radio-inline radioStyle">
                            <input
                                 type="radio"
                                 id="cmd_0036"
                                name="cmd_0036"
                                formControlName="cmd_0036"
                                value="0"
                            />No</label>
                    </div>
                </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                    <div class="col-md-6">
                        <label class="formLabel" style="font-size: 12px;">If yes, please list the types of privileges granted (e.g., Provisional, Limited, Conditional)</label>
                    </div>
                    <div class="form-group col-md-4 sideLabelAlignment">
                            
                        <div class="form-group col-md-12 dropDrownSet">
                          <ng-select
                            [items]="staffStatus"
                            
                            bindLabel="name"
                            placeholder="Staff Status"
                            bindValue="id"
                            formControlName="895"
                          >
                          </ng-select>
                          <div
                            class="formLabel col-md-2"
                            
                          >
                            
                          </div>
                        </div>
                      </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                        <div class="col-md-12">
                            <h6 style="font-size: 12px;">List all present and past affiliations in chronological order. Indicate “Membership Status” as: Active/Courtesy,
                                etc., or Academic Title. Use additional sheets if necessary</h6>
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                        <div class="col-md-4">
                            <input  class="form-control" id="436" name="436"  formControlName="436" placeholder="Hospital Affilation 3 Name"/>
                            <label class="formLabel" style="font-size: 12px;">Name of Institution (3)</label>
                        </div>
                        <div class="col-md-4">
                            <div class="input-group">
                                <input type="text" placeholder="Hospital Affilation 3 Initial Appointement"  ngbDatepicker #d121="ngbDatepicker" id="444" (blur)="clearInputIfInvalid('444')" class="form-control" formControlName="444"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d121.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div  *ngIf="CSCinte.get('444').invalid ">
                                <small class="text-danger" *ngIf="CSCinte.get('444').errors?.invalidDateFormat">
                                  Only Date Format is allowed(MM/DD/YYYY)
                                </small>
                                <small class="text-danger" *ngIf="CSCinte.get('444').errors?.invalidMonth">
                                Month is out of range
                                </small>
                                <small class="text-danger" *ngIf="CSCinte.get('444').errors?.invalidDateyear">
                                  year is out of range(1900-2099)                      </small>
                                  <small class="text-danger" *ngIf="CSCinte.get('444').errors?.invalidDateRange">
                                    date is out of range
                                    </small>
                                </div>
                              
                            <label class="formLabel" style="font-size: 12px;">Dates Affiliated 1(MM/DD/YY)</label>

                        </div>
                        <div class="col-md-4">
                            <div class="input-group">
                                <input type="text" placeholder="Hospital Affilation 3 Next Appointement Date"  ngbDatepicker #d122="ngbDatepicker" id="2011" (blur)="clearInputIfInvalid('2011')" class="form-control" formControlName="2011"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d122.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div  *ngIf="CSCinte.get('2011').invalid ">
                                <small class="text-danger" *ngIf="CSCinte.get('2011').errors?.invalidDateFormat">
                                  Only Date Format is allowed(MM/DD/YYYY)
                                </small>
                                <small class="text-danger" *ngIf="CSCinte.get('2011').errors?.invalidMonth">
                                Month is out of range
                                </small>
                                <small class="text-danger" *ngIf="CSCinte.get('2011').errors?.invalidDateyear">
                                  year is out of range(1900-2099)                      </small>
                                  <small class="text-danger" *ngIf="CSCinte.get('2011').errors?.invalidDateRange">
                                    date is out of range
                                    </small>
                                </div>
                              
                            <label class="formLabel" style="font-size: 12px;">Dates Affiliated 2(MM/DD/YY)</label>

                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                        <div class="col-md-4">
                            <input  class="form-control" id="437" name="437"  formControlName="437" placeholder="Hospital Affiliation 3 Address1"/>
                            <label class="formLabel" style="font-size: 12px;">Address 1</label>
                        </div>
                        <div class="col-md-4">
                            <input  class="form-control" id="438" name="438"  formControlName="438" placeholder="Hospital Affilation 3 Address2"/>
                            <label class="formLabel" style="font-size: 12px;">Address 2</label>
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                        <div class="col-md-3">
                            <input  class="form-control" id="439" name="439"  formControlName="439" placeholder="Hospital Affilation 3 City"/>
                            <label class="formLabel" style="font-size: 12px;">City</label>
                        </div>
                        <div class="form-group col-md-3 sideLabelAlignment">
                            
                            <div class="form-group col-md-12 dropDrownSet">
                              <ng-select
                                [items]="stateDropDownDate"
                                
                                bindLabel="name"
                                placeholder="State or Country"
                                bindValue="id"
                                formControlName="441"
                              >
                              </ng-select>
                              <div
                               
                              >
                               
                              </div>
                              <label class="formLabel" style="font-size: 12px;">State</label>
                            </div>
                          </div>
                          <div class="col-md-3">
                            <input  class="form-control" id="442" name="442"  formControlName="442" placeholder="Hospital Affilation 3 Zip"/>
                            <label class="formLabel" style="font-size: 12px;">Zip Code</label>
                        </div>
                       
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                        <div class="col-md-3">
                            <input  class="form-control" id="1308" name="1308"  formControlName="1308" placeholder="Hospital Affilation 3 Contact"/>
                            <label class="formLabel" style="font-size: 12px;">Department Cheif/Chair(Full Name)</label>
                        </div>
                        <div class="col-md-3">
                            <div class="input-group">
                                <input type="text" placeholder=""  ngbDatepicker #d123="ngbDatepicker" id="cmd_0037" (blur)="clearInputIfInvalid('cmd_0037')" class="form-control" formControlName="cmd_0037"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d123.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div  *ngIf="CSCinte.get('cmd_0037').invalid ">
                                <small class="text-danger" *ngIf="CSCinte.get('cmd_0037').errors?.invalidDateFormat">
                                  Only Date Format is allowed(MM/DD/YYYY)
                                </small>
                                <small class="text-danger" *ngIf="CSCinte.get('cmd_0037').errors?.invalidMonth">
                                Month is out of range
                                </small>
                                <small class="text-danger" *ngIf="CSCinte.get('cmd_0037').errors?.invalidDateyear">
                                  year is out of range(1900-2099)                      </small>
                                  <small class="text-danger" *ngIf="CSCinte.get('cmd_0037').errors?.invalidDateRange">
                                    date is out of range
                                    </small>
                                </div>
                              
                            <label class="formLabel" style="font-size: 12px;">Dates Affiliated 1 (MM/DD/YY)</label>

                        </div>
                        <div class="col-md-3">
                            <div class="input-group">
                                <input type="text" placeholder=""  ngbDatepicker #d141="ngbDatepicker" id="cmd_00127" (blur)="clearInputIfInvalid('cmd_00127')"  class="form-control" formControlName="cmd_00127"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d141.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div  *ngIf="CSCinte.get('cmd_00127').invalid ">
                                <small class="text-danger" *ngIf="CSCinte.get('cmd_00127').errors?.invalidDateFormat">
                                  Only Date Format is allowed(MM/DD/YYYY)
                                </small>
                                <small class="text-danger" *ngIf="CSCinte.get('cmd_00127').errors?.invalidMonth">
                                Month is out of range
                                </small>
                                <small class="text-danger" *ngIf="CSCinte.get('cmd_00127').errors?.invalidDateyear">
                                  year is out of range(1900-2099)                      </small>
                                  <small class="text-danger" *ngIf="CSCinte.get('cmd_00127').errors?.invalidDateRange">
                                    date is out of range
                                    </small>
                                </div>
                              
                            <label class="formLabel" style="font-size: 12px;">Dates Affiliated 2 (MM/DD/YY)</label>

                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                    <div class="col-md-8">
                        <label class="formLabel" style="font-size: 12px;">Do you currently have Privileges at this Institution?..............</label>
                    </div>
                  
                    <div class="col-md-4">
                        <label class="radio-inline radioStyle">
                            <input
                                type="radio"
                                id="cmd_0038"
                                name="cmd_0038"
                                formControlName="cmd_0038"
                                value="1"
                            />Yes</label>
                            <label class="radio-inline radioStyle">
                            <input
                                 type="radio"
                                 id="cmd_0038"
                                name="cmd_0038"
                                formControlName="cmd_0038"
                                value="0"
                            />No</label>
                    </div>
                </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                    <div class="col-md-6">
                        <label class="formLabel" style="font-size: 12px;">If yes, please list the types of privileges granted (e.g., Provisional, Limited, Conditional)</label>
                    </div>
                    <div class="form-group col-md-4 sideLabelAlignment">
                            
                        <div class="form-group col-md-12 dropDrownSet">
                          <ng-select
                            [items]="staffStatus"
                            
                            bindLabel="name"
                            placeholder="Staff Status"
                            bindValue="id"
                            formControlName="896"
                          >
                          </ng-select>
                          <div
                            class="formLabel col-md-2"
                            
                          >
                            
                          </div>
                        </div>
                      </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                        <div class="col-md-12">
                            <h6 style="font-size: 12px;">List all present and past affiliations in chronological order. Indicate “Membership Status” as: Active/Courtesy,
                                etc., or Academic Title. Use additional sheets if necessary</h6>
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                        <div class="col-md-4">
                            <input  class="form-control" id="add_3_986" name="add_3_986"  formControlName="add_3_986" placeholder="Additional Hospital Affilaition Name"/>
                            <label class="formLabel" style="font-size: 12px;">Name of Institution (4)</label>
                        </div>
                        <div class="col-md-4">
                            <div class="input-group">
                                <input type="text" placeholder=" Additional Hospital Affilation  Initial Appointement"  ngbDatepicker #d124="ngbDatepicker" id="add_3_994" class="form-control" formControlName="add_3_994"   (blur)="clearInputIfInvalid('add_3_994')"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d124.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div  *ngIf="CSCinte.get('add_3_994').invalid ">
                                <small class="text-danger" *ngIf="CSCinte.get('add_3_994').errors?.invalidDateFormat">
                                  Only Date Format is allowed(MM/DD/YYYY)
                                </small>
                                <small class="text-danger" *ngIf="CSCinte.get('add_3_994').errors?.invalidMonth">
                                Month is out of range
                                </small>
                                <small class="text-danger" *ngIf="CSCinte.get('add_3_994').errors?.invalidDateyear">
                                  year is out of range(1900-2099)                      </small>
                                  <small class="text-danger" *ngIf="CSCinte.get('add_3_994').errors?.invalidDateRange">
                                    date is out of range
                                    </small>
                                </div>
                              
                            <label class="formLabel" style="font-size: 12px;">Dates Affiliated 1(MM/DD/YY)</label>

                        </div>
                        <div class="col-md-4">
                            <div class="input-group">
                                <input type="text" placeholder=" Additional Hospital Affilation  Next Appointement Date"  ngbDatepicker #d125="ngbDatepicker" id="add_3_2010" class="form-control" formControlName="add_3_2010" (blur)="clearInputIfInvalid('add_3_2010')"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d125.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div  *ngIf="CSCinte.get('add_3_2010').invalid ">
                                <small class="text-danger" *ngIf="CSCinte.get('add_3_2010').errors?.invalidDateFormat">
                                  Only Date Format is allowed(MM/DD/YYYY)
                                </small>
                                <small class="text-danger" *ngIf="CSCinte.get('add_3_2010').errors?.invalidMonth">
                                Month is out of range
                                </small>
                                <small class="text-danger" *ngIf="CSCinte.get('add_3_2010').errors?.invalidDateyear">
                                  year is out of range(1900-2099)                      </small>
                                  <small class="text-danger" *ngIf="CSCinte.get('add_3_2010').errors?.invalidDateRange">
                                    date is out of range
                                    </small>
                                </div>
                              
                            <label class="formLabel" style="font-size: 12px;">Dates Affiliated 2(MM/DD/YY)</label>

                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                        <div class="col-md-4">
                            <input  class="form-control" id="add_3_987" name="add_3_987"  formControlName="add_3_987" placeholder="Additional Hospital Affilation  Address1"/>
                            <label class="formLabel" style="font-size: 12px;">Address 1</label>
                        </div>
                        <div class="col-md-4">
                            <input  class="form-control" id="add_3_988" name="add_3_988"  formControlName="add_3_988" placeholder=" Additional Hospital Affilation  Address2"/>
                            <label class="formLabel" style="font-size: 12px;">Address 2</label>
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                        <div class="col-md-3">
                            <input  class="form-control" id="add_3_989" name="add_3_989"  formControlName="add_3_989" placeholder="Additional Hospital Affilation  City"/>
                            <label class="formLabel" style="font-size: 12px;">City</label>
                        </div>
                        <div class="form-group col-md-3 sideLabelAlignment">
                            
                            <div class="form-group col-md-12 dropDrownSet">
                              <ng-select
                                [items]="stateDropDownDate"
                                
                                bindLabel="name"
                                placeholder="State or Country"
                                bindValue="id"
                                formControlName="add_3_991"
                              >
                              </ng-select>
                              <div
                               
                              >
                               
                              </div>
                              <label class="formLabel" style="font-size: 12px;">State</label>
                            </div>
                          </div>
                          <div class="col-md-3">
                            <input  class="form-control" id="add_3_992" name="add_3_992"  formControlName="add_3_992" placeholder="Additional Hospital Affilation  Zip"/>
                            <label class="formLabel" style="font-size: 12px;">Zip Code</label>
                        </div>
                        
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                        <div class="col-md-3">
                            <input  class="form-control" id="add_3_1310" name="add_3_1310"  formControlName="add_3_1310" placeholder=" Additional Hospital Affilation  Contact"/>
                            <label class="formLabel" style="font-size: 12px;">Department Cheif/Chair(Full Name)</label>
                        </div>
                        <div class="col-md-3">
                            <div class="input-group">
                                <input type="text" placeholder=""  ngbDatepicker #d126="ngbDatepicker" id="cmd_0039"  (blur)="clearInputIfInvalid('cmd_0039')" class="form-control" formControlName="cmd_0039"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d126.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div  *ngIf="CSCinte.get('cmd_0039').invalid ">
                                <small class="text-danger" *ngIf="CSCinte.get('cmd_0039').errors?.invalidDateFormat">
                                  Only Date Format is allowed(MM/DD/YYYY)
                                </small>
                                <small class="text-danger" *ngIf="CSCinte.get('cmd_0039').errors?.invalidMonth">
                                Month is out of range
                                </small>
                                <small class="text-danger" *ngIf="CSCinte.get('cmd_0039').errors?.invalidDateyear">
                                  year is out of range(1900-2099)                      </small>
                                  <small class="text-danger" *ngIf="CSCinte.get('cmd_0039').errors?.invalidDateRange">
                                    date is out of range
                                    </small>
                                </div>
                              
                            <label class="formLabel" style="font-size: 12px;">Dates Affiliated 1 (MM/DD/YY)</label>

                        </div>
                        <div class="col-md-3">
                            <div class="input-group">
                                <input type="text" placeholder=""  ngbDatepicker #d181="ngbDatepicker" id="cmd_00128" (blur)="clearInputIfInvalid('cmd_00128')"  class="form-control" formControlName="cmd_00128"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d181.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div  *ngIf="CSCinte.get('cmd_00128').invalid ">
                                <small class="text-danger" *ngIf="CSCinte.get('cmd_00128').errors?.invalidDateFormat">
                                  Only Date Format is allowed(MM/DD/YYYY)
                                </small>
                                <small class="text-danger" *ngIf="CSCinte.get('cmd_00128').errors?.invalidMonth">
                                Month is out of range
                                </small>
                                <small class="text-danger" *ngIf="CSCinte.get('cmd_00128').errors?.invalidDateyear">
                                  year is out of range(1900-2099)                      </small>
                                  <small class="text-danger" *ngIf="CSCinte.get('cmd_00128').errors?.invalidDateRange">
                                    date is out of range
                                    </small>
                                </div>
                              
                            <label class="formLabel" style="font-size: 12px;">Dates Affiliated 2 (MM/DD/YY)</label>

                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                    <div class="col-md-8">
                        <label class="formLabel" style="font-size: 12px;">Do you currently have Privileges at this Institution?..............</label>
                    </div>
                  
                    <div class="col-md-4">
                        <label class="radio-inline radioStyle">
                            <input
                                type="radio"
                                id="cmd_0040"
                                name="cmd_0040"
                                formControlName="cmd_0040"
                                value="1"
                            />Yes</label>
                            <label class="radio-inline radioStyle">
                            <input
                                 type="radio"
                                 id="cmd_0040"
                                name="cmd_0040"
                                formControlName="cmd_0040"
                                value="0"
                            />No</label>
                    </div>
                </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                    <div class="col-md-6">
                        <label class="formLabel" style="font-size: 12px;">If yes, please list the types of privileges granted (e.g., Provisional, Limited, Conditional)</label>
                    </div>
                    <div class="form-group col-md-4 sideLabelAlignment">
                            
                        <div class="form-group col-md-12 dropDrownSet">
                          <ng-select
                            [items]="staffStatus"
                            
                            bindLabel="name"
                            placeholder="Staff Status"
                            bindValue="id"
                            formControlName="add_3_995"
                          >
                          </ng-select>
                          <div
                            class="formLabel col-md-2"
                            
                          >
                            
                          </div>
                        </div>
                      </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                        <div class="col-md-12">
                            <h6 style="font-size: 12px;">List all present and past affiliations in chronological order. Indicate “Membership Status” as: Active/Courtesy,
                                etc., or Academic Title. Use additional sheets if necessary</h6>
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                        <div class="col-md-4">
                            <input  class="form-control" id="add_4_986" name="add_4_986"  formControlName="add_4_986" placeholder="Additional Hospital Affilaition Name"/>
                            <label class="formLabel" style="font-size: 12px;">Name of Institution (5)</label>
                        </div>
                        <div class="col-md-4">
                            <div class="input-group">
                                <input type="text" placeholder=" Additional Hospital Affilation  Initial Appointement"  ngbDatepicker #d127="ngbDatepicker" id="add_4_994" (blur)="clearInputIfInvalid('add_4_994')"  class="form-control" formControlName="add_4_994"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d127.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div  *ngIf="CSCinte.get('add_4_994').invalid ">
                                <small class="text-danger" *ngIf="CSCinte.get('add_4_994').errors?.invalidDateFormat">
                                  Only Date Format is allowed(MM/DD/YYYY)
                                </small>
                                <small class="text-danger" *ngIf="CSCinte.get('add_4_994').errors?.invalidMonth">
                                Month is out of range
                                </small>
                                <small class="text-danger" *ngIf="CSCinte.get('add_4_994').errors?.invalidDateyear">
                                  year is out of range(1900-2099)                      </small>
                                  <small class="text-danger" *ngIf="CSCinte.get('add_4_994').errors?.invalidDateRange">
                                    date is out of range
                                    </small>
                                </div>
                              
                            <label class="formLabel" style="font-size: 12px;">Dates Affiliated 1(MM/DD/YY)</label>

                        </div>
                        <div class="col-md-4">
                            <div class="input-group">
                                <input type="text" placeholder=" Additional Hospital Affilation  Next Appointement Date"  ngbDatepicker #d167="ngbDatepicker" id="add_4_2010" (blur)="clearInputIfInvalid('add_4_2010')" class="form-control" formControlName="add_4_2010"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d167.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div  *ngIf="CSCinte.get('add_4_2010').invalid ">
                                <small class="text-danger" *ngIf="CSCinte.get('add_4_2010').errors?.invalidDateFormat">
                                  Only Date Format is allowed(MM/DD/YYYY)
                                </small>
                                <small class="text-danger" *ngIf="CSCinte.get('add_4_2010').errors?.invalidMonth">
                                Month is out of range
                                </small>
                                <small class="text-danger" *ngIf="CSCinte.get('add_4_2010').errors?.invalidDateyear">
                                  year is out of range(1900-2099)                      </small>
                                  <small class="text-danger" *ngIf="CSCinte.get('add_4_2010').errors?.invalidDateRange">
                                    date is out of range
                                    </small>
                                </div>
                              
                            <label class="formLabel" style="font-size: 12px;">Dates Affiliated 2(MM/DD/YY)</label>

                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                        <div class="col-md-4">
                            <input  class="form-control" id="add_4_987" name="add_4_987"  formControlName="add_4_987" placeholder="Additional Hospital Affilation  Address1"/>
                            <label class="formLabel" style="font-size: 12px;">Address 1</label>
                        </div>
                        <div class="col-md-4">
                            <input  class="form-control" id="add_4_988" name="add_4_988"  formControlName="add_4_988" placeholder=" Additional Hospital Affilation  Address2"/>
                            <label class="formLabel" style="font-size: 12px;">Address 2</label>
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                        <div class="col-md-3">
                            <input  class="form-control" id="add_4_989" name="add_4_989"  formControlName="add_4_989" placeholder="Additional Hospital Affilation  City"/>
                            <label class="formLabel" style="font-size: 12px;">City</label>
                        </div>
                        <div class="form-group col-md-3 sideLabelAlignment">
                            
                            <div class="form-group col-md-12 dropDrownSet">
                              <ng-select
                                [items]="stateDropDownDate"
                                
                                bindLabel="name"
                                placeholder="State or Country"
                                bindValue="id"
                                formControlName="add_4_991"
                              >
                              </ng-select>
                              <div
                               
                              >
                               
                              </div>
                              <label class="formLabel" style="font-size: 12px;">State</label>
                            </div>
                          </div>
                          <div class="col-md-3">
                            <input  class="form-control" id="add_4_992" name="add_4_992"  formControlName="add_4_992" placeholder="Additional Hospital Affilation  Zip"/>
                            <label class="formLabel" style="font-size: 12px;">Zip Code</label>
                        </div>
                       
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                        <div class="col-md-3">
                            <input  class="form-control" id="add_4_1310" name="add_4_1310"  formControlName="add_4_1310" placeholder=" Additional Hospital Affilation  Contact"/>
                            <label class="formLabel" style="font-size: 12px;">Department Cheif/Chair(Full Name)</label>
                        </div>
                        <div class="col-md-3">
                            <div class="input-group">
                                <input type="text" placeholder=""  ngbDatepicker #d185="ngbDatepicker" id="cmd_0041" (blur)="clearInputIfInvalid('cmd_0041')" class="form-control" formControlName="cmd_0041"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d185.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div  *ngIf="CSCinte.get('cmd_0041').invalid ">
                                <small class="text-danger" *ngIf="CSCinte.get('cmd_0041').errors?.invalidDateFormat">
                                  Only Date Format is allowed(MM/DD/YYYY)
                                </small>
                                <small class="text-danger" *ngIf="CSCinte.get('cmd_0041').errors?.invalidMonth">
                                Month is out of range
                                </small>
                                <small class="text-danger" *ngIf="CSCinte.get('cmd_0041').errors?.invalidDateyear">
                                  year is out of range(1900-2099)                      </small>
                                  <small class="text-danger" *ngIf="CSCinte.get('cmd_0041').errors?.invalidDateRange">
                                    date is out of range
                                    </small>
                                </div>
                              
                            <label class="formLabel" style="font-size: 12px;">Dates Affiliated 1 (MM/DD/YY)</label>

                        </div>
                        <div class="col-md-3">
                            <div class="input-group">
                                <input type="text" placeholder=""  ngbDatepicker #d190="ngbDatepicker" id="cmd_00129" (blur)="clearInputIfInvalid('cmd_00129')" class="form-control" formControlName="cmd_00129"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d190.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div  *ngIf="CSCinte.get('cmd_00129').invalid ">
                                <small class="text-danger" *ngIf="CSCinte.get('cmd_00129').errors?.invalidDateFormat">
                                  Only Date Format is allowed(MM/DD/YYYY)
                                </small>
                                <small class="text-danger" *ngIf="CSCinte.get('cmd_00129').errors?.invalidMonth">
                                Month is out of range
                                </small>
                                <small class="text-danger" *ngIf="CSCinte.get('cmd_00129').errors?.invalidDateyear">
                                  year is out of range(1900-2099)                      </small>
                                  <small class="text-danger" *ngIf="CSCinte.get('cmd_00129').errors?.invalidDateRange">
                                    date is out of range
                                    </small>
                                </div>
                              
                            <label class="formLabel" style="font-size: 12px;">Dates Affiliated 2 (MM/DD/YY)</label>

                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                    <div class="col-md-8">
                        <label class="formLabel" style="font-size: 12px;">Do you currently have Privileges at this Institution?..............</label>
                    </div>
                  
                    <div class="col-md-4">
                        <label class="radio-inline radioStyle">
                            <input
                                type="radio"
                                id="cmd_0042"
                                name="cmd_0042"
                                formControlName="cmd_0042"
                                value="1"
                            />Yes</label>
                            <label class="radio-inline radioStyle">
                            <input
                                 type="radio"
                                 id="cmd_0042"
                                name="cmd_0042"
                                formControlName="cmd_0042"
                                value="0"
                            />No</label>
                    </div>
                </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                    <div class="col-md-6">
                        <label class="formLabel" style="font-size: 12px;">If yes, please list the types of privileges granted (e.g., Provisional, Limited, Conditional)</label>
                    </div>
                    <div class="form-group col-md-4 sideLabelAlignment">
                            
                        <div class="form-group col-md-12 dropDrownSet">
                          <ng-select
                            [items]="staffStatus"
                            
                            bindLabel="name"
                            placeholder="Staff Status"
                            bindValue="id"
                            formControlName="add_4_995"
                          >
                          </ng-select>
                          <div
                            class="formLabel col-md-2"
                            
                          >
                            
                          </div>
                        </div>
                      </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                    <div class="col-md-8">
                        <h2  style="margin-left: 31px; color: blue; font-size: 16px;"><b>Previous Group / Medical</b></h2>
                    </div>
                    <div class="col-md-4">
                        <label for="cmd_0043"><b>Does Not Apply:</b></label>&nbsp;&nbsp;
                        <input type="checkbox"  formControlName="cmd_0043"
                            id="cmd_0043" value="1"
                            (ngModelChange)="handleNAchange('cmd_0043')">
                </div>
                  </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                        <div class="col-md-3">
                            <input class="form-control onboard"  formControlName="445" (blur)="handleNAchange('cmd_0043',445)"
                                id="445" name="445" type="text" placeholder= "Work History 1 Location" [class.is-invalid]="(CSCinte.controls['445'].touched && CSCinte.controls['445'].invalid) || (CSCinte.controls['445'].invalid && isSubmit)"  data-id="managing_contact_name" >
                            <div class="formLabel col-md-11"
                                *ngIf="CSCinte.controls['445'].touched && CSCinte.controls['445'].invalid">
                                <small class="text-danger" *ngIf="CSCinte.controls['445'].errors?.required">
                                    required
                                </small>
                            </div>
                            <label class="formlabel" style="font-size: 12px;">Name of Organization (1)</label>
                        </div>
                        <div class="col-md-3">
                            <input class="form-control onboard"  formControlName="cmd_0044" (blur)="handleNAchange('cmd_0043','cmd_0044')"
                                id="cmd_0044" name="cmd_0044" type="text" placeholder= "" [class.is-invalid]="(CSCinte.controls['cmd_0044'].touched && CSCinte.controls['cmd_0044'].invalid) || (CSCinte.controls['cmd_0044'].invalid && isSubmit)"  data-id="managing_contact_name" >
                            <div class="formLabel col-md-11"
                                *ngIf="CSCinte.controls['cmd_0044'].touched && CSCinte.controls['cmd_0044'].invalid">
                                <small class="text-danger" *ngIf="CSCinte.controls['cmd_0044'].errors?.required">
                                    required
                                </small>
                            </div>
                            <label class="formlabel" style="font-size: 12px;">Types of Organization</label>
                        </div>
                        <div class="col-md-3">
                            <div class="input-group">
                                <input class="form-control onboard datepicker" formControlName="454" (blur)="clearInputIfInvalid('454','cmd_0043')"  (blur)="handleNAchange('cmd_0043',454)"
                                [class.is-invalid]="(CSCinte.controls['454'].touched && CSCinte.controls['454'].invalid) || (CSCinte.controls['454'].invalid && isSubmit)"
                                ngbDatepicker #d132="ngbDatepicker" id="454" name="454" type="text" placeholder= "Work History 1 Start Date" data-id="454">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d132.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                </div>
                           
                            </div>
                            <div class="text-danger" *ngIf="CSCinte.controls['454'].invalid && (CSCinte.controls['454'].value==''|| CSCinte.controls['454'].value==null) " >
                                <small >
                                required
                                </small>
                              
                              </div>
                              <div  *ngIf="CSCinte.get('454').invalid">
                                <small class="text-danger" *ngIf="CSCinte.get('454').errors.invalidDateFormat">
                                  Only Date Format is allowed(MM/DD/YYYY)                  </small>
                                <small class="text-danger" *ngIf="CSCinte.get('454').errors.invalidMonth">
                                Month is out of range
                                </small>
                                <small class="text-danger" *ngIf="CSCinte.get('454').errors.invalidDateyear">
                                  year is out of range(1900-2099)                    </small>
                                  <small class="text-danger" *ngIf="CSCinte.get('454').errors.invalidDateRange">
                                    date is out of range
                                    </small>
                                </div>
                            <label class="formlabel" style="font-size: 12px;">Dates Practicing 1 (MM/DD/YYYY)</label>

                        </div>
                        <div class="col-md-3">
                            <div class="input-group">
                                <input class="form-control onboard datepicker" formControlName="455" (blur)="clearInputIfInvalid('455','cmd_0043')"  (blur)="handleNAchange('cmd_0043',455)"
                                [class.is-invalid]="(CSCinte.controls['455'].touched && CSCinte.controls['455'].invalid) || (CSCinte.controls['455'].invalid && isSubmit)"
                                ngbDatepicker #d133="ngbDatepicker" id="455" name="455" type="text" placeholder= "Work History 1 End Date" data-id="455">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d133.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                </div>
                               
                            </div>
                            <div class="text-danger" *ngIf="CSCinte.controls['455'].invalid && (CSCinte.controls['455'].value==''|| CSCinte.controls['455'].value==null) " >
                                <small >
                                required
                                </small>
                              
                              </div>
                              <div  *ngIf="CSCinte.get('455').invalid">
                                <small class="text-danger" *ngIf="CSCinte.get('455').errors.invalidDateFormat">
                                  Only Date Format is allowed(MM/DD/YYYY)                  </small>
                                <small class="text-danger" *ngIf="CSCinte.get('455').errors.invalidMonth">
                                Month is out of range
                                </small>
                                <small class="text-danger" *ngIf="CSCinte.get('455').errors.invalidDateyear">
                                  year is out of range(1900-2099)                    </small>
                                  <small class="text-danger" *ngIf="CSCinte.get('455').errors.invalidDateRange">
                                    date is out of range
                                    </small>
                                </div>
                            <label class="formlabel" style="font-size: 12px;">Dates Practicing 2 (MM/DD/YYYY)</label>

                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                        <div class="col-md-2">
                            <input class="form-control onboard"  formControlName="446" (blur)="handleNAchange('cmd_0043',446)"
                                id="446" name="446" type="text" placeholder= "Work History 1 Address1" [class.is-invalid]="(CSCinte.controls['446'].touched && CSCinte.controls['446'].invalid) || (CSCinte.controls['446'].invalid && isSubmit)"  data-id="managing_contact_name" >
                            <div class="formLabel col-md-11"
                                *ngIf="CSCinte.controls['446'].touched && CSCinte.controls['446'].invalid">
                                <small class="text-danger" *ngIf="CSCinte.controls['446'].errors?.required">
                                    required
                                </small>
                            </div>
                            <label class="formlabel" style="font-size: 12px;">Address 1</label>
                        </div>
                        <div class="col-md-2">
                            <input class="form-control onboard"  formControlName="447" (blur)="handleNAchange('cmd_0043',447)"
                                id="447" name="447" type="text" placeholder= "Work History 1 Address2" [class.is-invalid]="(CSCinte.controls['447'].touched && CSCinte.controls['447'].invalid) || (CSCinte.controls['447'].invalid && isSubmit)"  data-id="managing_contact_name" >
                            <div class="formLabel col-md-11"
                                *ngIf="CSCinte.controls['447'].touched && CSCinte.controls['447'].invalid">
                                <small class="text-danger" *ngIf="CSCinte.controls['447'].errors?.required">
                                    required
                                </small>
                            </div>
                            <label class="formlabel" style="font-size: 12px;">Address 2</label>
                        </div>
                        <div class="col-md-2">
                            <input class="form-control onboard"  formControlName="448" (blur)="handleNAchange('cmd_0043',448)"
                                id="448" name="448" type="text" placeholder= "Work History 1 City" [class.is-invalid]="(CSCinte.controls['448'].touched && CSCinte.controls['448'].invalid) || (CSCinte.controls['448'].invalid && isSubmit)"  data-id="managing_contact_name" >
                            <div class="formLabel col-md-11"
                                *ngIf="CSCinte.controls['448'].touched && CSCinte.controls['448'].invalid">
                                <small class="text-danger" *ngIf="CSCinte.controls['448'].errors?.required">
                                    required
                                </small>
                            </div>
                            <label class="formlabel" style="font-size: 12px;">City</label>
                        </div>
                        <div class="form-group col-md-3 sideLabelAlignment">
                            
                            <div class="form-group col-md-12 dropDrownSet">
                              <ng-select
                                [items]="stateDropDownDate"
                                [class.is-invalid]="
                                  (CSCinte.controls['450'].touched &&
                                  CSCinte.controls['450'].invalid) ||
                                  (CSCinte.controls['450'].invalid && isSubmit)
                                "
                                bindLabel="name"
                                placeholder="State or Country"
                                bindValue="id"
                                formControlName="450"
                                (blur)="handleNAchange('cmd_0043',450)"
                              >
                              </ng-select>
                              <div
                                class="formLabel col-md-2"
                                *ngIf="
                                CSCinte.controls['450'].touched &&
                                CSCinte.controls['450'].invalid
                                "
                              >
                                <small
                                  class="text-danger"
                                  *ngIf="CSCinte.controls['450'].errors?.required"
                                >
                                  required
                                </small>
                              </div>
                              <label class="formLabel" style="font-size: 12px;">State</label>
                            </div>
                          </div>
                          <div class="col-md-3">
                            <input class="form-control onboard"  formControlName="451"  (blur)="handleNAchange('cmd_0043',451)"
                                id="451" name="451" type="text" placeholder= "Work History 1 Zip" [class.is-invalid]="(CSCinte.controls['451'].touched && CSCinte.controls['451'].invalid) || (CSCinte.controls['451'].invalid && isSubmit)"  data-id="managing_contact_name" >
                            <div class="formLabel col-md-11"
                                *ngIf="CSCinte.controls['451'].touched && CSCinte.controls['451'].invalid">
                                <small class="text-danger" *ngIf="CSCinte.controls['451'].errors?.required">
                                    required
                                </small>
                            </div>
                            <label class="formlabel" style="font-size: 12px;">Zip Code</label>
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                        <div class="col-md-3">
                            <input  class="form-control" id="456" name="456"  formControlName="456" placeholder="Work History 2 Location"/>
                            <label class="formLabel" style="font-size: 12px;">Name of Organization(2)</label>
                        </div>
                        <div class="col-md-3">
                            <input  class="form-control" id="cmd_0045" name="cmd_0045"  formControlName="cmd_0045" placeholder=""/>
                            <label class="formLabel" style="font-size: 12px;">Types of Organization</label>
                        </div>
                        <div class="col-md-3">
                            <div class="input-group">
                                <input type="text" placeholder="Work History 2 Start Date"  ngbDatepicker #d134="ngbDatepicker" id="465" (blur)="clearInputIfInvalid('465')" class="form-control" formControlName="465"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d134.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div  *ngIf="CSCinte.get('465').invalid ">
                                <small class="text-danger" *ngIf="CSCinte.get('465').errors?.invalidDateFormat">
                                  Only Date Format is allowed(MM/DD/YYYY)
                                </small>
                                <small class="text-danger" *ngIf="CSCinte.get('465').errors?.invalidMonth">
                                Month is out of range
                                </small>
                                <small class="text-danger" *ngIf="CSCinte.get('465').errors?.invalidDateyear">
                                  year is out of range(1900-2099)                      </small>
                                  <small class="text-danger" *ngIf="CSCinte.get('465').errors?.invalidDateRange">
                                    date is out of range
                                    </small>
                                </div>
                              
                            <label class="formLabel" style="font-size: 12px;">Dates Practicing 1 (MM/DD/YYYY)</label>

                        </div>
                        <div class="col-md-3">
                            <div class="input-group">
                                <input type="text" placeholder="Work History 2 End Date"  ngbDatepicker #d135="ngbDatepicker" id="466" class="form-control" formControlName="466" (blur)="clearInputIfInvalid('466')"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d135.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div  *ngIf="CSCinte.get('466').invalid ">
                                <small class="text-danger" *ngIf="CSCinte.get('466').errors?.invalidDateFormat">
                                  Only Date Format is allowed(MM/DD/YYYY)
                                </small>
                                <small class="text-danger" *ngIf="CSCinte.get('466').errors?.invalidMonth">
                                Month is out of range
                                </small>
                                <small class="text-danger" *ngIf="CSCinte.get('466').errors?.invalidDateyear">
                                  year is out of range(1900-2099)                      </small>
                                  <small class="text-danger" *ngIf="CSCinte.get('466').errors?.invalidDateRange">
                                    date is out of range
                                    </small>
                                </div>
                              
                            <label class="formLabel" style="font-size: 12px;">Dates Practicing 2 (MM/DD/YYYY)</label>

                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                        <div class="col-md-2">
                            <input  class="form-control" id="457" name="457"  formControlName="457" placeholder="Work History 2 Address1"/>
                            <label class="formLabel" style="font-size: 12px;">Address1</label>
                        </div>
                        <div class="col-md-2">
                            <input  class="form-control" id="458" name="458"  formControlName="458" placeholder="Work History 2 Address2"/>
                            <label class="formLabel" style="font-size: 12px;">Address2</label>
                        </div>
                        <div class="col-md-2">
                            <input  class="form-control" id="459" name="459"  formControlName="459" placeholder="Work History 2 City"/>
                            <label class="formLabel" style="font-size: 12px;">City</label>
                        </div>
                        <div class="form-group col-md-3 sideLabelAlignment">
                            
                            <div class="form-group col-md-12 dropDrownSet">
                              <ng-select
                                [items]="stateDropDownDate"
                               
                                bindLabel="name"
                                placeholder="State or Country"
                                bindValue="id"
                                formControlName="461"
                              >
                              </ng-select>
                              <div
                                class="formLabel col-md-2"
                               
                              >
                               
                              </div>
                              <label class="formLabel" style="font-size: 12px;">State</label>
                            </div>
                          </div>
                          <div class="col-md-3">
                            <input  class="form-control" id="462" name="462"  formControlName="462" placeholder="Work History 2 Zip"/>
                            <label class="formLabel" style="font-size: 12px;">Zip Code</label>
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                        <div class="col-md-3">
                            <input  class="form-control" id="467" name="467"  formControlName="467" placeholder="Work History 3 Location"/>
                            <label class="formLabel" style="font-size: 12px;">Name of Organization(3)</label>
                        </div>
                        <div class="col-md-3">
                            <input  class="form-control" id="cmd_0046" name="cmd_0046"  formControlName="cmd_0046" placeholder=""/>
                            <label class="formLabel" style="font-size: 12px;">Types of Organization</label>
                        </div>
                        <div class="col-md-3">
                            <div class="input-group">
                                <input type="text" placeholder="Work History 3 Start Date"  ngbDatepicker #d137="ngbDatepicker" (blur)="clearInputIfInvalid('476')" id="476" class="form-control" formControlName="476"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d137.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div  *ngIf="CSCinte.get('476').invalid ">
                                <small class="text-danger" *ngIf="CSCinte.get('476').errors?.invalidDateFormat">
                                  Only Date Format is allowed(MM/DD/YYYY)
                                </small>
                                <small class="text-danger" *ngIf="CSCinte.get('476').errors?.invalidMonth">
                                Month is out of range
                                </small>
                                <small class="text-danger" *ngIf="CSCinte.get('476').errors?.invalidDateyear">
                                  year is out of range(1900-2099)                      </small>
                                  <small class="text-danger" *ngIf="CSCinte.get('476').errors?.invalidDateRange">
                                    date is out of range
                                    </small>
                                </div>
                              
                            <label class="formLabel" style="font-size: 12px;">Dates Practicing 1 (MM/DD/YYYY)</label>

                        </div>
                        <div class="col-md-3">
                            <div class="input-group">
                                <input type="text" placeholder="Work History 3 End Date"  ngbDatepicker #d138="ngbDatepicker" id="477" (blur)="clearInputIfInvalid('477')" class="form-control" formControlName="477"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d138.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div  *ngIf="CSCinte.get('477').invalid ">
                                <small class="text-danger" *ngIf="CSCinte.get('477').errors?.invalidDateFormat">
                                  Only Date Format is allowed(MM/DD/YYYY)
                                </small>
                                <small class="text-danger" *ngIf="CSCinte.get('477').errors?.invalidMonth">
                                Month is out of range
                                </small>
                                <small class="text-danger" *ngIf="CSCinte.get('477').errors?.invalidDateyear">
                                  year is out of range(1900-2099)                      </small>
                                  <small class="text-danger" *ngIf="CSCinte.get('477').errors?.invalidDateRange">
                                    date is out of range
                                    </small>
                                </div>
                              
                            <label class="formLabel" style="font-size: 12px;">Dates Practicing 2 (MM/DD/YYYY)</label>

                        </div>
                    </div>
                   
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                        <div class="col-md-2">
                            <input  class="form-control" id="468" name="468"  formControlName="468" placeholder="Work History 3 Address1"/>
                            <label class="formLabel" style="font-size: 12px;">Address 1</label>
                        </div>
                        <div class="col-md-2">
                            <input  class="form-control" id="469" name="469"  formControlName="469" placeholder="Work History 3 Address2"/>
                            <label class="formLabel" style="font-size: 12px;">Address 2</label>
                        </div>
                        <div class="col-md-2">
                            <input  class="form-control" id="470" name="470"  formControlName="470" placeholder="Work History 3 City"/>
                            <label class="formLabel" style="font-size: 12px;">City</label>
                        </div>
                        <div class="form-group col-md-3 sideLabelAlignment">
                            
                            <div class="form-group col-md-12 dropDrownSet">
                              <ng-select
                                [items]="stateDropDownDate"
                               
                                bindLabel="name"
                                placeholder="State or Country"
                                bindValue="id"
                                formControlName="472"
                              >
                              </ng-select>
                              <div
                                class="formLabel col-md-2"
                               
                              >
                               
                              </div>
                              <label class="formLabel" style="font-size: 12px;">State</label>
                            </div>
                          </div>
                          <div class="col-md-3">
                            <input  class="form-control" id="473" name="473"  formControlName="473" placeholder="Work History 3 Zip"/>
                            <label class="formLabel" style="font-size: 12px;">Zip Code</label>
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                    <div class="col-md-8">
                        <h2  style="margin-left: 31px; color: blue; font-size: 16px;"><b>Certification</b></h2>
                    </div>
                    <div class="col-md-4">
                        <label for="cmd_0047"><b>Does Not Apply:</b></label>&nbsp;&nbsp;
                        <input type="checkbox"   formControlName="cmd_0047"
                            id="cmd_0047" value="1"
                            (ngModelChange)="handleNAchange('cmd_0047')">
                </div>
                  </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                        <div class="form-group col-md-3 dropDrownSet">
                            <ng-select
                                [items]="boardCertification"
                                [class.is-invalid]="
                                (CSCinte.controls['554'].touched &&
                                CSCinte.controls['554'].invalid) ||
                                (CSCinte.controls['554'].invalid && isSubmit)
                                " bindLabel="name"
                                placeholder="Primary Board Certification"
                                bindValue="id"
                                formControlName="554"
                                (blur)="handleNAchange('cmd_0047',554)">
                            </ng-select>
                            <div
                                class="formLabel col-md-2"
                                *ngIf="
                                CSCinte.controls['554'].touched &&
                                CSCinte.controls['554'].invalid">
                            </div>
                            <label class="formlabel" style="font-size: 12px;">Certified by American Board of (Specialty)</label>
                        </div>
                        <div class="col-md-3">
                            <input class="form-control onboard"  formControlName="1169" (blur)="handleNAchange('cmd_0047',1169)"
                                id="1169" name="1169" type="text" placeholder= "Primary Board Certification Number" [class.is-invalid]="(CSCinte.controls['1169'].touched && CSCinte.controls['1169'].invalid) || (CSCinte.controls['1169'].invalid && isSubmit)"  data-id="managing_contact_name" >
                            <div class="formLabel col-md-11"
                                *ngIf="CSCinte.controls['1169'].touched && CSCinte.controls['1169'].invalid">
                                <small class="text-danger" *ngIf="CSCinte.controls['1169'].errors?.required">
                                    required
                                </small>
                            </div>
                            <label class="formlabel" style="font-size: 12px;">Certification#</label>
                        </div>
                        <div class="col-md-3">
                            <div class="input-group">
                                <input class="form-control onboard datepicker" formControlName="557" (blur)="clearInputIfInvalid('557','cmd_0047')"   (blur)="handleNAchange('cmd_0047',557)"
                                [class.is-invalid]="(CSCinte.controls['557'].touched && CSCinte.controls['557'].invalid) || (CSCinte.controls['557'].invalid && isSubmit)"
                                ngbDatepicker #d144="ngbDatepicker" id="557" name="557" type="text" placeholder= "Primary Board Original Issue Date" data-id="557">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d144.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                </div>
                             
                            </div>
                            <div class="text-danger" *ngIf="CSCinte.controls['557'].invalid && (CSCinte.controls['557'].value==''|| CSCinte.controls['557'].value==null) " >
                                <small >
                                required
                                </small>
                              
                              </div>
                              <div  *ngIf="CSCinte.get('557').invalid">
                                <small class="text-danger" *ngIf="CSCinte.get('557').errors.invalidDateFormat">
                                  Only Date Format is allowed(MM/DD/YYYY)                  </small>
                                <small class="text-danger" *ngIf="CSCinte.get('557').errors.invalidMonth">
                                Month is out of range
                                </small>
                                <small class="text-danger" *ngIf="CSCinte.get('557').errors.invalidDateyear">
                                  year is out of range(1900-2099)                    </small>
                                  <small class="text-danger" *ngIf="CSCinte.get('557').errors.invalidDateRange">
                                    date is out of range
                                    </small>
                                </div>
                            <label class="formlabel" style="font-size: 12px;">Dates of Certification/Recertification Expiration1 (MM/DD/YY)</label>
                        </div>
                        <div class="col-md-3">
                            <div class="input-group">
                                <input class="form-control onboard datepicker" formControlName="558" (blur)="clearInputIfInvalid('558','cmd_0047')"  (blur)="handleNAchange('cmd_0047',558)"
                                [class.is-invalid]="(CSCinte.controls['558'].touched && CSCinte.controls['558'].invalid) || (CSCinte.controls['558'].invalid && isSubmit)"
                                ngbDatepicker #d145="ngbDatepicker" id="558" name="558" type="text" placeholder= "Primary Board First  Recert Date" data-id="558">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d145.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                </div>
                              
                            </div>
                            <div class="text-danger" *ngIf="CSCinte.controls['558'].invalid && (CSCinte.controls['558'].value==''|| CSCinte.controls['558'].value==null) " >
                                <small >
                                required
                                </small>
                              
                              </div>
                              <div  *ngIf="CSCinte.get('558').invalid">
                                <small class="text-danger" *ngIf="CSCinte.get('558').errors.invalidDateFormat">
                                  Only Date Format is allowed(MM/DD/YYYY)                  </small>
                                <small class="text-danger" *ngIf="CSCinte.get('558').errors.invalidMonth">
                                Month is out of range
                                </small>
                                <small class="text-danger" *ngIf="CSCinte.get('558').errors.invalidDateyear">
                                  year is out of range(1900-2099)                    </small>
                                  <small class="text-danger" *ngIf="CSCinte.get('558').errors.invalidDateRange">
                                    date is out of range
                                    </small>
                                </div>
                            <label class="formlabel" style="font-size: 12px;">Dates of Certification/Recertification Expiration 2 (MM/DD/YYYY)</label>
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                        <div class="form-group col-md-3 dropDrownSet">
                            <ng-select
                                [items]="boardCertification"
                               bindLabel="name"
                                placeholder="Second Board Certification"
                                bindValue="id"
                                formControlName="561">
                            </ng-select>
                            <div>
                            </div>
                            <label class="formlabel" style="font-size: 12px;">Subspeciality Board Status (Name of Board) </label>
                        </div>
                        <div class="col-md-3">
                            <input class="form-control onboard"  formControlName="562"
                                id="562" name="562" type="text" placeholder= "Secondary Board Certificate Number" data-id="managing_contact_name" >
                            <div class="formLabel col-md-11">
                            </div>
                            <label class="formlabel" style="font-size: 12px;">Certification#</label>
                        </div>
                        <div class="col-md-3">
                            <div class="input-group">
                                <input class="form-control onboard datepicker" formControlName="564"  (blur)="clearInputIfInvalid('564')"
                                ngbDatepicker #d146="ngbDatepicker" id="564" name="564" type="text" placeholder= "Secondary Board Original Issue Date" data-id="564">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d146.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                </div>
                                <div class="formLabel col-md-11">
                                </div>
                            </div>
                            <div  *ngIf="CSCinte.get('564').invalid ">
                                <small class="text-danger" *ngIf="CSCinte.get('564').errors?.invalidDateFormat">
                                  Only Date Format is allowed(MM/DD/YYYY)
                                </small>
                                <small class="text-danger" *ngIf="CSCinte.get('564').errors?.invalidMonth">
                                Month is out of range
                                </small>
                                <small class="text-danger" *ngIf="CSCinte.get('564').errors?.invalidDateyear">
                                  year is out of range(1900-2099)                      </small>
                                  <small class="text-danger" *ngIf="CSCinte.get('564').errors?.invalidDateRange">
                                    date is out of range
                                    </small>
                                </div>
                              
                            <label class="formlabel" style="font-size: 12px;">Dates of Certification/Recertification Expiration 1 (MM/DD/YYYY)</label>

                        </div>
                        <div class="col-md-3">
                            <div class="input-group">
                                <input class="form-control onboard datepicker" formControlName="565"  (blur)="clearInputIfInvalid('565')"
                                ngbDatepicker #d147="ngbDatepicker" id="565" name="565" type="text" placeholder= "Secondary Board First Recert Date" data-id="565">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d147.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                </div>
                                <div class="formLabel col-md-11">
                                </div>
                            </div>
                            <div  *ngIf="CSCinte.get('565').invalid ">
                                <small class="text-danger" *ngIf="CSCinte.get('565').errors?.invalidDateFormat">
                                  Only Date Format is allowed(MM/DD/YYYY)
                                </small>
                                <small class="text-danger" *ngIf="CSCinte.get('565').errors?.invalidMonth">
                                Month is out of range
                                </small>
                                <small class="text-danger" *ngIf="CSCinte.get('565').errors?.invalidDateyear">
                                  year is out of range(1900-2099)                      </small>
                                  <small class="text-danger" *ngIf="CSCinte.get('565').errors?.invalidDateRange">
                                    date is out of range
                                    </small>
                                </div>
                              
                            <label class="formlabel" style="font-size: 12px;">Dates of Certification/Recertification Expiration 2 (MM/DD/YY)</label>
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                        <div class="col-md-4">
                            <input class="form-control onboard"  formControlName="cmd_0048"
                                id="cmd_0048" name="cmd_0048" type="text" placeholder= "" data-id="managing_contact_name">
                            <label class="formlabel" style="font-size: 12px;">If not Certified,give Present Status</label>
                        </div>
                        <div class="col-md-4">
                            <div class="input-group">
                                <input class="form-control onboard datepicker" formControlName="cmd_0049"  (blur)="clearInputIfInvalid('cmd_0049')"
                                ngbDatepicker #d148="ngbDatepicker" id="cmd_0049" name="cmd_0049" type="text" placeholder= "" data-id="cmd_0049">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d148.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                </div>
                                <div class="formLabel col-md-11">
                                </div>
                            </div>
                            <div  *ngIf="CSCinte.get('cmd_0049').invalid ">
                                <small class="text-danger" *ngIf="CSCinte.get('cmd_0049').errors?.invalidDateFormat">
                                  Only Date Format is allowed(MM/DD/YYYY)
                                </small>
                                <small class="text-danger" *ngIf="CSCinte.get('cmd_0049').errors?.invalidMonth">
                                Month is out of range
                                </small>
                                <small class="text-danger" *ngIf="CSCinte.get('cmd_0049').errors?.invalidDateyear">
                                  year is out of range(1900-2099)                      </small>
                                  <small class="text-danger" *ngIf="CSCinte.get('cmd_0049').errors?.invalidDateRange">
                                    date is out of range
                                    </small>
                                </div>
                              
                            <label class="formlabel" style="font-size: 12px;">Date  (MM/DD/YY)</label>
                        </div>
                        <div class="col-md-4">
                            <div class="input-group">
                                <input class="form-control onboard datepicker" formControlName="1377"   (blur)="clearInputIfInvalid('1377')"
                                ngbDatepicker #d149="ngbDatepicker" id="1377" name="1377" type="text" placeholder= "Primary Board Test Date" data-id="1377">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d149.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                </div>
                                <div class="formLabel col-md-11">
                                </div>
                            </div>
                            <div  *ngIf="CSCinte.get('1377').invalid ">
                                <small class="text-danger" *ngIf="CSCinte.get('1377').errors?.invalidDateFormat">
                                  Only Date Format is allowed(MM/DD/YYYY)
                                </small>
                                <small class="text-danger" *ngIf="CSCinte.get('1377').errors?.invalidMonth">
                                Month is out of range
                                </small>
                                <small class="text-danger" *ngIf="CSCinte.get('1377').errors?.invalidDateyear">
                                  year is out of range(1900-2099)                      </small>
                                  <small class="text-danger" *ngIf="CSCinte.get('1377').errors?.invalidDateRange">
                                    date is out of range
                                    </small>
                                </div>
                              
                            <label class="formlabel" style="font-size: 12px;">Date of Exam  (MM/DD/YY)</label>
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                    <div class="col-md-10">
                        <h2  style="margin-left: 31px; color: blue; font-size: 16px;"><b>Professional Societies, Awarded Fellowship(e.g.,ACS,ACP)</b></h2>
                    </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                        <div class="col-md-12">
                            <h6 style="font-size: 14px;">List all memberships, past, present, or pending in professional societies. Please include dates of membership.
                                Please give complete names and addresses, including Zip codes in all instances. Attach additional sheets if
                                necessary.</h6>
                        </div>
                        <div class="col-md-12">
                            <input  class="form-control" id="1695" name="1695"  formControlName="1695" placeholder="Primary Name of Professional Society"/>
                            <input  class="form-control" id="1701" name="1701"  formControlName="1701" placeholder="Secondary Name of Professional Society">
                            <input  class="form-control" id="1707" name="1707"  formControlName="1707" placeholder="Third Name of Professional Society"/>
                            <input  class="form-control" id="1973" name="1973"  formControlName="1973" placeholder="Additional Name of Professional Society"/>
                            <input  class="form-control" id="cmd_0096" name="cmd_0096"  formControlName="cmd_0096" placeholder=""/>
                            <input  class="form-control" id="cmd_0097" name="cmd_0097"  formControlName="cmd_0097" placeholder=""/>
                            <input  class="form-control" id="cmd_0098" name="cmd_0098"  formControlName="cmd_0098" placeholder=""/>
                            <input  class="form-control" id="cmd_0099" name="cmd_0099"  formControlName="cmd_0099" placeholder=""/>

                        </div>   
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                    <div class="col-md-10">
                        <h2  style="margin-left: 31px; color: blue; font-size: 16px;"><b>Professional Peer References</b></h2>
                    </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                        <div class="col-md-12">
                            <h6 style="font-size: 14px;">List three professional references familiar with the applicant’s qualifications during the three years immediately
                                preceding this application. One professional reference must be from the Chief of the department or service
                                where the applicant last furnished professional services.</h6>
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                        <div class="col-md-6">
                            <input class="form-control onboard"  formControlName="359"
                                id="359" name="359" type="text" placeholder= "Reference 1 Name" [class.is-invalid]="(CSCinte.controls['359'].touched && CSCinte.controls['359'].invalid) || (CSCinte.controls['359'].invalid && isSubmit)"  data-id="managing_contact_name" >
                            <div class="formLabel col-md-11"
                                *ngIf="CSCinte.controls['359'].touched && CSCinte.controls['359'].invalid">
                                <small class="text-danger" *ngIf="CSCinte.controls['359'].errors?.required">
                                    required
                                </small>
                            </div>
                            <label class="formlabel" style="font-size: 12px;">Name</label>
                        </div>
                        <div class="col-md-6"> 
                            <div
                            class=" form-group selectDropTwo degree inputPadding"
                          >
                            <ng-select
                              [items]="degree"
                              bindLabel="name"
                              placeholder=" Degree"
                              bindValue="id"
                              formControlName="cmd_0050"
                              [class.is-invalid]="
                                (CSCinte.controls['cmd_0050'].touched &&
                                CSCinte.controls['cmd_0050'].errors) ||
                                (CSCinte.controls['cmd_0050'].errors && isSubmit)
                              "
                            >
                            </ng-select>
                            <div
                              class="formLabel col-md-11"
                              *ngIf="
                              CSCinte.controls['cmd_0050'].touched &&
                              CSCinte.controls['cmd_0050'].invalid
                              "
                            >
                              <small
                                class="text-danger"
                                *ngIf="CSCinte.controls['cmd_0050'].errors"
                              >
                                required
                              </small>
                            </div>
                            <label class="formlabel" style="font-size: 12px;">Degree</label>
                          </div>
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                        <div class="col-md-3">
                            <input class="form-control onboard"  formControlName="2722"
                                id="2722" name="2722" type="text" placeholder= "Reference 1 Title" [class.is-invalid]="(CSCinte.controls['2722'].touched && CSCinte.controls['2722'].invalid) || (CSCinte.controls['2722'].invalid && isSubmit)"  data-id="managing_contact_name" >
                            <div class="formLabel col-md-11"
                                *ngIf="CSCinte.controls['2722'].touched && CSCinte.controls['2722'].invalid">
                                <small class="text-danger" *ngIf="CSCinte.controls['2722'].errors?.required">
                                    required
                                </small>
                            </div>
                            <label class="formlabel" style="font-size: 12px;">Title</label>
                        </div>
                        <div class="col-md-3">
                            <input class="form-control onboard"  formControlName="2721"
                                id="2721" name="2721" type="text" placeholder= "Reference 1 Relation" [class.is-invalid]="(CSCinte.controls['2721'].touched && CSCinte.controls['2721'].invalid) || (CSCinte.controls['2721'].invalid && isSubmit)"  data-id="managing_contact_name" >
                            <div class="formLabel col-md-11"
                                *ngIf="CSCinte.controls['2721'].touched && CSCinte.controls['2721'].invalid">
                                <small class="text-danger" *ngIf="CSCinte.controls['2721'].errors?.required">
                                    required
                                </small>
                            </div>
                            <label class="formlabel" style="font-size: 12px;">Professional Realtionship</label>
                        </div>
                        <div class="col-md-3">
                          <div class="form-group selectDrop dropDrownSet">
                            <ng-select [items]="specialty" bindLabel="name"
                                [class.is-invalid]="(CSCinte.controls['368'].touched && CSCinte.controls['368'].invalid) || (CSCinte.controls['368'].invalid && isSubmit)"
                                placeholder="Select Specialities" bindValue="id" formControlName="368">
                            </ng-select>
                            <div class="formLabel col-md-11"
                                *ngIf="CSCinte.controls['368'].touched && CSCinte.controls['368'].invalid">
                                <small class="text-danger"
                                    *ngIf="CSCinte.controls['368'].errors?.required">
                                    required
                                </small>
                            </div>
                            <label class="formlabel" style="font-size: 12px;">Specialty</label>
                        </div>
                    </div>
                        <div class="col-md-3">
                            <input class="form-control onboard"  formControlName="4242"
                                id="4242" name="4242" type="text" placeholder= "Reference 1 Length of Acquaintance" [class.is-invalid]="(CSCinte.controls['4242'].touched && CSCinte.controls['4242'].invalid) || (CSCinte.controls['4242'].invalid && isSubmit)"  data-id="managing_contact_name" >
                            <div class="formLabel col-md-11"
                                *ngIf="CSCinte.controls['4242'].touched && CSCinte.controls['4242'].invalid">
                                <small class="text-danger" *ngIf="CSCinte.controls['4242'].errors?.required">
                                    required
                                </small>
                            </div>
                            <label class="formlabel" style="font-size: 12px;">Years Known</label>
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                        <div class="col-md-6">
                            <input class="form-control onboard"  formControlName="371"
                                id="371" name="371" type="text" placeholder= "Reference 1 Email" [class.is-invalid]="(CSCinte.controls['371'].touched && CSCinte.controls['371'].invalid) || (CSCinte.controls['371'].invalid && isSubmit)"  data-id="managing_contact_name" >
                            <div class="formLabel col-md-11"
                                *ngIf="CSCinte.controls['4242'].touched && CSCinte.controls['371'].invalid">
                                <small class="text-danger" *ngIf="CSCinte.controls['371'].errors?.required">
                                    required
                                </small>
                            </div>
                            <label class="formlabel" style="font-size: 12px;">Email</label>
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colsapn="12">
                    <div class="row">
                        <div class="col-md-6">
                            <input class="form-control onboard"  formControlName="372"
                                id="372" name="372" type="text" placeholder= "Reference 2 Name"  data-id="managing_contact_name" >
                            <div class="formLabel col-md-11"
                               >
                              
                            </div>
                            <label class="formlabel" style="font-size: 12px;">Name</label>
                        </div>
                        <div class="col-md-6"> 
                            <div
                            class=" form-group selectDropTwo degree inputPadding"
                          >
                            <ng-select
                              [items]="degree"
                              bindLabel="name"
                              placeholder=" Degree"
                              bindValue="id"
                              formControlName="cmd_0052">
                            </ng-select>
                            <div
                              class="formLabel col-md-11">
                             
                            </div>
                            <label class="formlabel" style="font-size: 12px;">Degree</label>
                          </div>
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                        <div class="col-md-3">
                            <input class="form-control onboard"  formControlName="2723"
                                id="2723" name="2723" type="text" placeholder= "Reference 2 Title"  data-id="managing_contact_name" >
                            <div class="formLabel col-md-11"
                              >
                                
                            </div>
                            <label class="formlabel" style="font-size: 12px;">Title</label>
                        </div>
                        <div class="col-md-3">
                            <input class="form-control onboard"  formControlName="2724"
                                id="2724" name="2724" type="text" placeholder= "Reference 2 Relation"   data-id="managing_contact_name" >
                            <div class="formLabel col-md-11">
                              
                            </div>
                            <label class="formlabel" style="font-size: 12px;">Professional Realtionship</label>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group selectDrop dropDrownSet">
                              <ng-select [items]="specialty" bindLabel="name"
                                 
                                  placeholder="Select Specialities" bindValue="id" formControlName="380">
                              </ng-select>
                              <div class="formLabel col-md-11"
                                 >
                              </div>
                              <label class="formlabel" style="font-size: 12px;">Specialty</label>
                          </div>
                      </div>
                        <div class="col-md-3">
                            <input class="form-control onboard"  formControlName="4243"
                                id="4243" name="4243" type="text" placeholder= "Reference 2 Length of Acquaintance"   data-id="managing_contact_name" >
                            <div class="formLabel col-md-11">
                                
                            </div>
                            <label class="formlabel" style="font-size: 12px;">Years Known</label>
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="383"
                                id="383" name="383" type="text" placeholder= "Reference 2 Email"  data-id="managing_contact_name" >
                            <div class="formLabel col-md-11"
                               >
                              
                            </div>
                            <label class="formlabel" style="font-size: 12px;">Email</label>
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                        <div class="col-md-6">
                            <input class="form-control onboard"  formControlName="384"
                                id="384" name="384" type="text" placeholder= "Reference 3 Name"  data-id="managing_contact_name" >
                            <div class="formLabel col-md-11"
                               >
                              
                            </div>
                            <label class="formlabel" style="font-size: 12px;">Name</label>
                        </div>
                        <div class="col-md-6"> 
                            <div
                            class=" form-group selectDropTwo degree inputPadding"
                          >
                            <ng-select
                              [items]="degree"
                              bindLabel="name"
                              placeholder="Degree"
                              bindValue="id"
                              formControlName="cmd_0054">
                            </ng-select>
                            <div
                              class="formLabel col-md-11">
                             
                            </div>
                            <label class="formlabel" style="font-size: 12px;">Degree</label>
                          </div>
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                        <div class="col-md-3">
                            <input class="form-control onboard"  formControlName="2725"
                                id="2725" name="2725" type="text" placeholder= "Reference 3 Title"  data-id="managing_contact_name" >
                            <div class="formLabel col-md-11">
                                
                            </div>
                            <label class="formlabel" style="font-size: 12px;">Title</label>
                        </div>
                        <div class="col-md-3">
                            <input class="form-control onboard"  formControlName="2726"
                                id="2726" name="2726" type="text" placeholder= "Reference 3 Relation"   data-id="managing_contact_name" >
                            <div class="formLabel col-md-11">
                              
                            </div>
                            <label class="formlabel" style="font-size: 12px;">Professional Realtionship</label>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group selectDrop dropDrownSet">
                              <ng-select [items]="specialty" bindLabel="name"
                                 
                                  placeholder="Select Specialities" bindValue="id" formControlName="393">
                              </ng-select>
                              <div class="formLabel col-md-11"
                                 >
                              </div>
                              <label class="formlabel" style="font-size: 12px;">Specialty</label>
                          </div>
                      </div>
                        <div class="col-md-3">
                            <input class="form-control onboard"  formControlName="4244"
                                id="4244" name="4244" type="text" placeholder= "Reference 3 Length of Acquaintance"   data-id="managing_contact_name" >
                            <div class="formLabel col-md-11">
                                
                            </div>
                            <label class="formlabel" style="font-size: 12px;">Years Known</label>
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="396"
                                id="396" name="396" type="text" placeholder= "Reference 3 Email"  data-id="managing_contact_name" >
                            <div class="formLabel col-md-11"
                               >
                              
                            </div>
                            <label class="formlabel" style="font-size: 12px;">Email</label>
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                    <div class="col-md-8">
                        <h2  style="margin-left: 31px; color: blue; font-size: 16px;"><b>Professional Liability</b></h2>
                    </div>
                    <div class="col-md-4">
                        <label for="cmd_0056"><b>Does Not Apply:</b></label>&nbsp;&nbsp;
                        <input type="checkbox"   formControlName="cmd_0056"
                            id="cmd_0056" value="1"
                            (ngModelChange)="handleNAchange('cmd_0056')">
                </div>
                  </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                        <div class="col-md-6">
                            <input class="form-control onboard"  formControlName="345" (blur)="handleNAchange('cmd_0056',345)"
                                id="345" name="345" type="text" placeholder= "MLP Carrier Name" [class.is-invalid]="(CSCinte.controls['345'].touched && CSCinte.controls['345'].invalid) || (CSCinte.controls['345'].invalid && isSubmit)"  data-id="managing_contact_name" >
                            <div class="formLabel col-md-11"
                                *ngIf="CSCinte.controls['345'].touched && CSCinte.controls['345'].invalid">
                                <small class="text-danger" *ngIf="CSCinte.controls['345'].errors?.required">
                                    required
                                </small>
                            </div>
                            <label class="formlabel" style="font-size: 12px;">Insurance Carrier</label>
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                        <div class="col-md-2">
                            <input class="form-control onboard"  formControlName="901" (blur)="handleNAchange('cmd_0056',901)"
                                id="901" name="901" type="text" placeholder= "MLP Address 1" [class.is-invalid]="(CSCinte.controls['901'].touched && CSCinte.controls['901'].invalid) || (CSCinte.controls['901'].invalid && isSubmit)"  data-id="managing_contact_name" >
                            <div class="formLabel col-md-11"
                                *ngIf="CSCinte.controls['901'].touched && CSCinte.controls['901'].invalid">
                                <small class="text-danger" *ngIf="CSCinte.controls['901'].errors?.required">
                                    required
                                </small>
                            </div>
                            <label class="formlabel" style="font-size: 12px;">Address 1</label>
                        </div>
                        <div class="col-md-2">
                            <input class="form-control onboard"  formControlName="902" (blur)="handleNAchange('cmd_0056',902)"
                                id="902" name="902" type="text" placeholder= "MLP Address 2" [class.is-invalid]="(CSCinte.controls['902'].touched && CSCinte.controls['902'].invalid) || (CSCinte.controls['902'].invalid && isSubmit)"  data-id="managing_contact_name" >
                            <div class="formLabel col-md-11"
                                *ngIf="CSCinte.controls['902'].touched && CSCinte.controls['902'].invalid">
                                <small class="text-danger" *ngIf="CSCinte.controls['902'].errors?.required">
                                    required
                                </small>
                            </div>
                            <label class="formlabel" style="font-size: 12px;">Address 2</label>
                        </div>
                        <div class="col-md-2">
                            <input class="form-control onboard"  formControlName="903" (blur)="handleNAchange('cmd_0056',903)"
                                id="903" name="903" type="text" placeholder= "MLP City" [class.is-invalid]="(CSCinte.controls['903'].touched && CSCinte.controls['903'].invalid) || (CSCinte.controls['903'].invalid && isSubmit)"  data-id="managing_contact_name" >
                            <div class="formLabel col-md-11"
                                *ngIf="CSCinte.controls['903'].touched && CSCinte.controls['903'].invalid">
                                <small class="text-danger" *ngIf="CSCinte.controls['903'].errors?.required">
                                    required
                                </small>
                            </div>
                            <label class="formlabel" style="font-size: 12px;">City</label>
                        </div>
                        <div class="form-group col-md-3 sideLabelAlignment">
                            
                            <div class="form-group col-md-12 dropDrownSet">
                              <ng-select
                                [items]="stateDropDownDate"
                                [class.is-invalid]="
                                  (CSCinte.controls['905'].touched &&
                                  CSCinte.controls['905'].invalid) ||
                                  (CSCinte.controls['905'].invalid && isSubmit)
                                "
                                bindLabel="name"
                                placeholder="State or Country"
                                bindValue="id"
                                formControlName="905"
                                (blur)="handleNAchange('cmd_0056',905)"
                              >
                              </ng-select>
                              <div
                                class="formLabel col-md-2"
                                *ngIf="
                                CSCinte.controls['905'].touched &&
                                CSCinte.controls['905'].invalid
                                "
                              >
                                <small
                                  class="text-danger"
                                  *ngIf="CSCinte.controls['905'].errors?.required"
                                >
                                  required
                                </small>
                              </div>
                              <label class="formLabel" style="font-size: 12px;">State</label>
                            </div>
                          </div>
                          <div class="col-md-3">
                            <input class="form-control onboard"  formControlName="906" (blur)="handleNAchange('cmd_0056',906)"
                                id="906" name="906" type="text" placeholder= "MLP Zip" [class.is-invalid]="(CSCinte.controls['906'].touched && CSCinte.controls['906'].invalid) || (CSCinte.controls['906'].invalid && isSubmit)"  data-id="managing_contact_name" >
                            <div class="formLabel col-md-11"
                                *ngIf="CSCinte.controls['906'].touched && CSCinte.controls['906'].invalid">
                                <small class="text-danger" *ngIf="CSCinte.controls['906'].errors?.required">
                                    required
                                </small>
                            </div>
                            <label class="formlabel" style="font-size: 12px;">Zip Code</label>
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                        <div class="col-md-3">
                            <input class="form-control onboard"  formControlName="346" (blur)="handleNAchange('cmd_0056',346)"
                                id="346" name="346" type="text" placeholder= "MLP Policy Number " [class.is-invalid]="(CSCinte.controls['346'].touched && CSCinte.controls['346'].invalid) || (CSCinte.controls['346'].invalid && isSubmit)"  data-id="managing_contact_name" >
                            <div class="formLabel col-md-11"
                                *ngIf="CSCinte.controls['346'].touched && CSCinte.controls['346'].invalid">
                                <small class="text-danger" *ngIf="CSCinte.controls['346'].errors?.required">
                                    required
                                </small>
                            </div>
                            <label class="formlabel" style="font-size: 12px;">Policy#</label>
                        </div>
                        <div class="col-md-3">
                            <input class="form-control onboard"  formControlName="1240" (blur)="handleNAchange('cmd_0056',1240)"
                                id="1240" name="1240" type="text" placeholder= "MLP Agents Name" [class.is-invalid]="(CSCinte.controls['1240'].touched && CSCinte.controls['1240'].invalid) || (CSCinte.controls['1240'].invalid && isSubmit)"  data-id="managing_contact_name" >
                            <div class="formLabel col-md-11"
                                *ngIf="CSCinte.controls['1240'].touched && CSCinte.controls['1240'].invalid">
                                <small class="text-danger" *ngIf="CSCinte.controls['1240'].errors?.required">
                                    required
                                </small>
                            </div>
                            <label class="formlabel" style="font-size: 12px;">Agent</label>
                        </div>
                        <div class="col-md-3">
                            <div class="input-group">
                                <input class="form-control onboard datepicker" formControlName="348" (blur)="clearInputIfInvalid('348','cmd_0056')"  (blur)="handleNAchange('cmd_0056',348)"
                                [class.is-invalid]="(CSCinte.controls['348'].touched && CSCinte.controls['348'].invalid) || (CSCinte.controls['348'].invalid && isSubmit)"
                                ngbDatepicker #d150="ngbDatepicker" id="348" name="348" type="text" placeholder= "MLP Issue Date" data-id="348">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d150.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                </div>
                                <div class="formLabel col-md-11"
                                *ngIf="
                                (CSCinte.controls['348'].touched &&
                                CSCinte.controls['348'].errors) ||
                                (CSCinte.controls['348'].errors && isSubmit)
                              ">
                   
                                </div>
                            </div>
                            <div class="text-danger" *ngIf="CSCinte.controls['348'].invalid && (CSCinte.controls['348'].value==''|| CSCinte.controls['348'].value==null) " >
                                <small >
                                required
                                </small>
                              
                              </div>
                              <div  *ngIf="CSCinte.get('348').invalid">
                                <small class="text-danger" *ngIf="CSCinte.get('348').errors.invalidDateFormat">
                                  Only Date Format is allowed(MM/DD/YYYY)                  </small>
                                <small class="text-danger" *ngIf="CSCinte.get('348').errors.invalidMonth">
                                Month is out of range
                                </small>
                                <small class="text-danger" *ngIf="CSCinte.get('348').errors.invalidDateyear">
                                  year is out of range(1900-2099)                    </small>
                                  <small class="text-danger" *ngIf="CSCinte.get('348').errors.invalidDateRange">
                                    date is out of range
                                    </small>
                                </div>
                            <label class="formlabel" style="font-size: 12px;">Effective Date(MM/DD/YY)</label>

                        </div>
                        <div class="col-md-3">
                            <div class="input-group">
                                <input class="form-control onboard datepicker" formControlName="349"  (blur)="clearInputIfInvalid('349','cmd_0056')"  (blur)="handleNAchange('cmd_0056',349)"
                                [class.is-invalid]="(CSCinte.controls['349'].touched && CSCinte.controls['349'].invalid) || (CSCinte.controls['349'].invalid && isSubmit)"
                                ngbDatepicker #d151="ngbDatepicker" id="349" name="349" type="text" placeholder= "MLP Expiration Date" data-id="349">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d151.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                </div>
                                <div class="formLabel col-md-11"
                                *ngIf="
                                (CSCinte.controls['349'].touched &&
                                CSCinte.controls['349'].errors) ||
                                (CSCinte.controls['349'].errors && isSubmit)
                              ">
                        
                                </div>
                            </div>
                            <div class="text-danger" *ngIf="CSCinte.controls['349'].invalid && (CSCinte.controls['349'].value==''|| CSCinte.controls['349'].value==null) " >
                                <small >
                                required
                                </small>
                              
                              </div>
                              <div  *ngIf="CSCinte.get('349').invalid">
                                <small class="text-danger" *ngIf="CSCinte.get('349').errors.invalidDateFormat">
                                  Only Date Format is allowed(MM/DD/YYYY)                  </small>
                                <small class="text-danger" *ngIf="CSCinte.get('349').errors.invalidMonth">
                                Month is out of range
                                </small>
                                <small class="text-danger" *ngIf="CSCinte.get('349').errors.invalidDateyear">
                                  year is out of range(1900-2099)                    </small>
                                  <small class="text-danger" *ngIf="CSCinte.get('349').errors.invalidDateRange">
                                    date is out of range
                                    </small>
                                </div>
                            <label class="formlabel" style="font-size: 12px;">Expiration Date(MM/DD/YY)</label>

                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                        <div class="col-md-4">
                            <input class="form-control onboard"  formControlName="cmd_0057" (blur)="handleNAchange('cmd_0056','cmd_0057')"
                                id="cmd_0057" name="cmd_0057" type="text" placeholder= "" [class.is-invalid]="(CSCinte.controls['cmd_0057'].touched && CSCinte.controls['cmd_0057'].invalid) || (CSCinte.controls['cmd_0057'].invalid && isSubmit)"  data-id="managing_contact_name" >
                            <div class="formLabel col-md-11"
                                *ngIf="CSCinte.controls['cmd_0057'].touched && CSCinte.controls['cmd_0057'].invalid">
                                <small class="text-danger" *ngIf="CSCinte.controls['cmd_0057'].errors?.required">
                                    required
                                </small>
                            </div>
                            <label class="formlabel" style="font-size: 12px;">Policy Limits</label>
                        </div>
                        <div class="col-md-4">
                               <ng-select
                             [items]="preClaim"
                             [class.is-invalid]="(CSCinte.controls['350'].touched &&
                             CSCinte.controls['350'].invalid) ||
                             (CSCinte.controls['350'].invalid && isSubmit)"
                             bindLabel="name"
                             placeholder="Per Claim"
                             bindValue="id"
                             formControlName="350"
                             (blur)="handleNAchange('cmd_0056', 350)">
                             </ng-select>
                              <div class="invalid-feedback"
                                  *ngIf="(CSCinte.controls['350'].touched && CSCinte.controls['350'].invalid) || (CSCinte.controls['350'].invalid && isSubmit)">
                                    required
                             </div>
                               <label class="formlabel" style="font-size: 12px;">Per Occurrence ($)</label>
                         </div>
                         <div class="col-md-4">
                               <ng-select
                               [items]="aggregates"
                               [class.is-invalid]="(CSCinte.controls['351'].touched &&
                               CSCinte.controls['351'].invalid) ||
                               (CSCinte.controls['351'].invalid && isSubmit)"
                               bindLabel="name"
                               placeholder=" Aggregate"
                               bindValue="id"
                               formControlName="351"
                               (blur)="handleNAchange('cmd_0056', 351)">
                               </ng-select>
                                <div class="invalid-feedback"
                                    *ngIf="(CSCinte.controls['351'].touched && CSCinte.controls['351'].invalid) || (CSCinte.controls['351'].invalid && isSubmit)">
                                     required
                               </div>
                               <label class="formlabel" style="font-size: 12px;">Aggregate ($)</label>
                         </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                        <div class="col-md-4">
                            <input class="form-control onboard"  formControlName="347" (blur)="handleNAchange('cmd_0056', 347)"
                                id="347" name="347" type="text" placeholder= "MLP Type of Coverage " [class.is-invalid]="(CSCinte.controls['347'].touched && CSCinte.controls['347'].invalid) || (CSCinte.controls['347'].invalid && isSubmit)"  data-id="managing_contact_name" >
                            <div class="formLabel col-md-11"
                                *ngIf="CSCinte.controls['347'].touched && CSCinte.controls['347'].invalid">
                                <small class="text-danger" *ngIf="CSCinte.controls['347'].errors?.required">
                                    required
                                </small>
                            </div>
                            <label class="formlabel" style="font-size: 12px;">Type of Coverage</label>
                        </div>
                        <div class="col-md-4">
                            <input class="form-control onboard"  formControlName="cmd_0058"
                                id="cmd_0058" name="cmd_0058" type="text" placeholder= "" data-id="managing_contact_name">
                            <label class="formlabel" style="font-size: 12px;">Claims Made</label>
                        </div>
                        <div class="col-md-4">
                            <input class="form-control onboard"  formControlName="cmd_0059"
                                id="cmd_0059" name="cmd_0059" type="text" placeholder= "" data-id="managing_contact_name">
                            <label class="formlabel" style="font-size: 12px;">Occurrence</label>
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                    <div class="col-md-8">
                        <label class="formLabel" style="font-size: 12px;">Have any Professional liability lawsuits been field against you during the past 10 years(including those closed)?</label>
                    </div>
                  
                    <div class="col-md-4">
                        <label class="radio-inline radioStyle">
                            <input
                                type="radio"
                                id="cmd_0060"
                                name="cmd_0060"
                                formControlName="cmd_0060"
                                value="1"
                            />Yes</label>
                            <label class="radio-inline radioStyle">
                            <input
                                 type="radio"
                                 id="cmd_0060"
                                name="cmd_0060"
                                formControlName="cmd_0060"
                                value="0"
                            />No</label>
                    </div>
                </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                    <div class="col-md-8">
                        <label class="formLabel" style="font-size: 12px;">Are there any now still pending? 
                        </label>
                    </div>
                  
                    <div class="col-md-4">
                        <label class="radio-inline radioStyle">
                            <input
                                type="radio"
                                id="cmd_0061"
                                name="cmd_0061"
                                formControlName="cmd_0061"
                                value="1"
                            />Yes</label>
                            <label class="radio-inline radioStyle">
                            <input
                                 type="radio"
                                 id="cmd_0061"
                                name="cmd_0061"
                                formControlName="cmd_0061"
                                value="0"
                            />No</label>
                    </div>
                </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                    <div class="col-md-8">
                        <label class="formLabel" style="font-size: 12px;">Has any judgement, payment of claim, or settlement ever been made against you in any professional 
                            liability case?
                        </label>
                    </div>
                  
                    <div class="col-md-4">
                        <label class="radio-inline radioStyle">
                            <input
                                type="radio"
                                id="cmd_0062"
                                name="cmd_0062"
                                formControlName="cmd_0062"
                                value="1"
                            />Yes</label>
                            <label class="radio-inline radioStyle">
                            <input
                                 type="radio"
                                 id="cmd_0062"
                                name="cmd_0062"
                                formControlName="cmd_0062"
                                value="0"
                            />No</label>
                    </div>
                </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                    <div class="col-md-8">
                        <label class="formLabel" style="font-size: 12px;">Has any judgement or payment of a claim or settlement amount exceeded the limits of this coverage?
                        </label>
                    </div>
                  
                    <div class="col-md-4">
                        <label class="radio-inline radioStyle">
                            <input
                                type="radio"
                                id="cmd_0063"
                                name="cmd_0063"
                                formControlName="cmd_0063"
                                value="1"
                            />Yes</label>
                            <label class="radio-inline radioStyle">
                            <input
                                 type="radio"
                                 id="cmd_0063"
                                name="cmd_0063"
                                formControlName="cmd_0063"
                                value="0"
                            />No</label>
                    </div>
                </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                    <div class="col-md-8">
                        <label class="formLabel" style="font-size: 12px;">Have you ever been denied professional insurance, or has your policy ever been cancelled?
                        </label>
                    </div>
                  
                    <div class="col-md-4">
                        <label class="radio-inline radioStyle">
                            <input
                                type="radio"
                                id="cmd_0064"
                                name="cmd_0064"
                                formControlName="cmd_0064"
                                value="1"
                            />Yes</label>
                            <label class="radio-inline radioStyle">
                            <input
                                 type="radio"
                                 id="cmd_0064"
                                name="cmd_0064"
                                formControlName="cmd_0064"
                                value="0"
                            />No</label>
                    </div>
                </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                    <div class="col-md-8">
                        <label class="formLabel" style="font-size: 12px;">Have you ever been denied professional liability insurance coverage? 
                        </label>
                    </div>
                  
                    <div class="col-md-4">
                        <label class="radio-inline radioStyle">
                            <input
                                type="radio"
                                id="cmd_0065"
                                name="cmd_0065"
                                formControlName="cmd_0065"
                                value="1"
                            />Yes</label>
                            <label class="radio-inline radioStyle">
                            <input
                                 type="radio"
                                 id="cmd_0065"
                                name="cmd_0065"
                                formControlName="cmd_0065"
                                value="0"
                            />No</label>
                    </div>
                </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                    <div class="col-md-8">
                        <label class="formLabel" style="font-size: 12px;">Has your present professional liability insurance carrier excluded any specific procedures from your coverage?
                        </label>
                    </div>
                  
                    <div class="col-md-4">
                        <label class="radio-inline radioStyle">
                            <input
                                type="radio"
                                id="cmd_0066"
                                name="cmd_0066"
                                formControlName="cmd_0066"
                                value="1"
                            />Yes</label>
                            <label class="radio-inline radioStyle">
                            <input
                                 type="radio"
                                 id="cmd_0066"
                                name="cmd_0066"
                                formControlName="cmd_0066"
                                value="0"
                            />No</label>
                    </div>
                </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                    <div class="col-md-8">
                        <h2  style="margin-left: 31px; color: blue; font-size: 16px;"><b>Professional Sanctions</b></h2>
                    </div>
                    
                  </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                       
                            <div class="form-group form-inline no-margin sideLabelAlignment">
                              <label class="formLabel" style="width:28px;">1</label>
                            </div>
                       
                    <div class="col-md-8">
                        <label class="formLabel" style="font-size: 12px;">Has your license to practice in any jurisdiction ever been denied, restricted, limited, suspended, revoked, canceled,
                            and/or subject to probation either voluntary or involuntarily, or has your application for a license ever been 
                            withdrawn?
                        </label>
                    </div>
                  
                    <div class="col-md-3">
                        <label class="radio-inline radioStyle">
                            <input
                                type="radio"
                                id="cmd_0067"
                                name="cmd_0067"
                                [class.is-invalid]="(CSCinte.controls['cmd_0067'].touched 
                                && CSCinte.controls['cmd_0067'].invalid) || 
                                (CSCinte.controls['cmd_0067'].invalid && isSubmit)"
                            formControlName="cmd_0067"
                            value="1"
                            />Yes</label>
                            <label class="radio-inline radioStyle">
                            <input
                                 type="radio"
                                 id="cmd_0067"
                                name="cmd_0067"
                                [class.is-invalid]="(CSCinte.controls['cmd_0067'].touched 
                                && CSCinte.controls['cmd_0067'].invalid) || 
                                (CSCinte.controls['cmd_0067'].invalid && isSubmit)"
                            formControlName="cmd_0067"
                            value="0"
                            />No</label>
                            <span *ngIf="(CSCinte.controls['cmd_0067'].touched && CSCinte.controls['cmd_0067'].errors)
                     || (CSCinte.controls['cmd_0067'].errors && isSubmit)">
                   <i class="fas fa-times ml-2 text-danger"></i>
                      </span>
                            <span class="formLabel"
                            *ngIf="
                            (CSCinte.controls['cmd_0067'].touched &&
                            CSCinte.controls['cmd_0067'].errors) ||
                            (CSCinte.controls['cmd_0067'].errors && isSubmit)
                          ">
                    <small class="text-danger" *ngIf="CSCinte.controls['cmd_0067'].errors?.required">
                        required
                    </small>
                            </span>
                    </div>
                   </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                            <div class="form-group form-inline no-margin sideLabelAlignment">
                              <label class="formLabel" style="width: 28px;">2</label>
                            </div>
                            <div class="col-md-8">
                           <label class="formLabel" style="font-size: 12px;">Have you ever been reprimanded and/or fined, been the subject of a complaint, and/or have you been notified in 
                            writing that you have been investigated as the possible subject of a criminal, civil, or disciplinary action by any state or
                            federal agency that licenses providers?
                           </label>
                         </div>
                  
                    <div class="col-md-2">
                        <label class="radio-inline radioStyle">
                            <input
                                type="radio"
                                id="cmd_0068"
                                name="cmd_0068"
                                [class.is-invalid]="(CSCinte.controls['cmd_0068'].touched 
                                && CSCinte.controls['cmd_0068'].invalid) || 
                                (CSCinte.controls['cmd_0068'].invalid && isSubmit)"
                            formControlName="cmd_0068"
                            value="1"
                            />Yes</label>
                            <label class="radio-inline radioStyle">
                            <input
                                 type="radio"
                                 id="cmd_0068"
                                name="cmd_0068"
                                [class.is-invalid]="(CSCinte.controls['cmd_0068'].touched 
                                && CSCinte.controls['cmd_0068'].invalid) || 
                                (CSCinte.controls['cmd_0068'].invalid && isSubmit)"
                            formControlName="cmd_0068"
                            value="0"
                            />No</label>
                            <div class="formLabel"
                    *ngIf="CSCinte.controls['cmd_0068'].touched && CSCinte.controls['cmd_0068'].invalid">
                    <small class="text-danger" *ngIf="CSCinte.controls['cmd_0068'].errors?.required">
                        required
                    </small>
                   </div>
                   <span *ngIf="(CSCinte.controls['cmd_0068'].touched && CSCinte.controls['cmd_0068'].errors)
                     || (CSCinte.controls['cmd_0068'].errors && isSubmit)">
                   <i class="fas fa-times ml-2 text-danger"></i>
                      </span>
                    </div>
                   </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                            <div class="form-group form-inline no-margin sideLabelAlignment">
                              <label class="formLabel" style="width: 28px;">3</label>
                            </div>
                            <div class="col-md-8">
                           <label class="formLabel" style="font-size: 12px;">Have you lost any board certification(s), and/or failed to rectify?
                           </label>
                         </div>
                  
                    <div class="col-md-2">
                        <label class="radio-inline radioStyle">
                            <input
                                type="radio"
                                id="cmd_0069"
                                name="cmd_0069"
                                [class.is-invalid]="(CSCinte.controls['cmd_0069'].touched 
                                && CSCinte.controls['cmd_0069'].invalid) || 
                                (CSCinte.controls['cmd_0069'].invalid && isSubmit)"
                            formControlName="cmd_0069"
                            value="1"
                            />Yes</label>
                            <label class="radio-inline radioStyle">
                            <input
                                 type="radio"
                                 id="cmd_0069"
                                name="cmd_0069"
                                [class.is-invalid]="(CSCinte.controls['cmd_0069'].touched 
                                && CSCinte.controls['cmd_0069'].invalid) || 
                                (CSCinte.controls['cmd_0069'].invalid && isSubmit)"
                            formControlName="cmd_0069"
                            value="0"
                            />No</label>
                            <div class="formLabel"
                    *ngIf="CSCinte.controls['cmd_0069'].touched && CSCinte.controls['cmd_0069'].invalid">
                    <small class="text-danger" *ngIf="CSCinte.controls['cmd_0069'].errors?.required">
                        required
                    </small>
                   </div>
                   <span *ngIf="(CSCinte.controls['cmd_0069'].touched && CSCinte.controls['cmd_0069'].errors)
                     || (CSCinte.controls['cmd_0069'].errors && isSubmit)">
                   <i class="fas fa-times ml-2 text-danger"></i>
                      </span>
                    </div>
                   </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                            <div class="form-group form-inline no-margin sideLabelAlignment">
                              <label class="formLabel" style="width: 28px;">4</label>
                            </div>
                            <div class="col-md-8">
                           <label class="formLabel" style="font-size: 12px;">Have you been examined by a Capital Certifying Board but failed to pass?
                           </label>
                         </div>
                  
                    <div class="col-md-2">
                        <label class="radio-inline radioStyle">
                            <input
                                type="radio"
                                id="cmd_0070"
                                name="cmd_0070"
                                [class.is-invalid]="(CSCinte.controls['cmd_0070'].touched 
                                && CSCinte.controls['cmd_0070'].invalid) || 
                                (CSCinte.controls['cmd_0070'].invalid && isSubmit)"
                            formControlName="cmd_0070"
                            value="1"
                            />Yes</label>
                            <label class="radio-inline radioStyle">
                            <input
                                 type="radio"
                                 id="cmd_0070"
                                name="cmd_0070"
                                [class.is-invalid]="(CSCinte.controls['cmd_0070'].touched 
                                && CSCinte.controls['cmd_0070'].invalid) || 
                                (CSCinte.controls['cmd_0070'].invalid && isSubmit)"
                            formControlName="cmd_0070"
                            value="0"
                            />No</label>
                            <div class="formLabel"
                    *ngIf="CSCinte.controls['cmd_0070'].touched && CSCinte.controls['cmd_0070'].invalid">
                    <small class="text-danger" *ngIf="CSCinte.controls['cmd_0070'].errors?.required">
                        required
                    </small>
                   </div>
                   <span *ngIf="(CSCinte.controls['cmd_0070'].touched && CSCinte.controls['cmd_0070'].errors)
                     || (CSCinte.controls['cmd_0070'].errors && isSubmit)">
                   <i class="fas fa-times ml-2 text-danger"></i>
                      </span>
                    </div>
                   </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                            <div class="form-group form-inline no-margin sideLabelAlignment">
                              <label class="formLabel" style="width: 28px;">5</label>
                            </div>
                            <div class="col-md-8">
                           <label class="formLabel" style="font-size: 12px;">Has any information pertaining to you, including malpractice judgements and/or disciplinary action, ever been reported to the National Provider Data Bank (NPDB) or any other practitioner data bank?
                           </label>
                         </div>
                  
                    <div class="col-md-2">
                        <label class="radio-inline radioStyle">
                            <input
                                type="radio"
                                id="cmd_0071"
                                name="cmd_0071"
                                [class.is-invalid]="(CSCinte.controls['cmd_0071'].touched 
                                && CSCinte.controls['cmd_0071'].invalid) || 
                                (CSCinte.controls['cmd_0071'].invalid && isSubmit)"
                            formControlName="cmd_0071"
                            value="1"
                            />Yes</label>
                            <label class="radio-inline radioStyle">
                            <input
                                 type="radio"
                                 id="cmd_0071"
                                name="cmd_0071"
                                [class.is-invalid]="(CSCinte.controls['cmd_0071'].touched 
                                && CSCinte.controls['cmd_0071'].invalid) || 
                                (CSCinte.controls['cmd_0071'].invalid && isSubmit)"
                            formControlName="cmd_0071"
                            value="0"
                            />No</label>
                            <div class="formLabel"
                    *ngIf="CSCinte.controls['cmd_0071'].touched && CSCinte.controls['cmd_0071'].invalid">
                    <small class="text-danger" *ngIf="CSCinte.controls['cmd_0071'].errors?.required">
                        required
                    </small>
                   </div>
                   <span *ngIf="(CSCinte.controls['cmd_0071'].touched && CSCinte.controls['cmd_0071'].errors)
                     || (CSCinte.controls['cmd_0071'].errors && isSubmit)">
                   <i class="fas fa-times ml-2 text-danger"></i>
                      </span>
                    </div>
                   </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                            <div class="form-group form-inline no-margin sideLabelAlignment">
                              <label class="formLabel" style="width: 28px;">6</label>
                            </div>
                            <div class="col-md-8">
                           <label class="formLabel" style="font-size: 12px;">Has your federal DEA number and/or state controlled substances license been restricted, limited, relinquished,
                            suspended, or revoked, either voluntarily or involuntarily, and/or have you ever been notified in writing that you
                            are being investigated as the possible subject of a criminal or disciplinary action with respect to your DEA or
                            controlled substance registration?
                            
                           </label>
                         </div>
                  
                    <div class="col-md-2">
                        <label class="radio-inline radioStyle">
                            <input
                                type="radio"
                                id="cmd_0072"
                                name="cmd_0072"
                                [class.is-invalid]="(CSCinte.controls['cmd_0072'].touched 
                                && CSCinte.controls['cmd_0072'].invalid) || 
                                (CSCinte.controls['cmd_0072'].invalid && isSubmit)"
                            formControlName="cmd_0072"
                            value="1"
                            />Yes</label>
                            <label class="radio-inline radioStyle">
                            <input
                                 type="radio"
                                 id="cmd_0072"
                                name="cmd_0072"
                                [class.is-invalid]="(CSCinte.controls['cmd_0072'].touched 
                                && CSCinte.controls['cmd_0072'].invalid) || 
                                (CSCinte.controls['cmd_0072'].invalid && isSubmit)"
                            formControlName="cmd_0072"
                            value="0"
                            />No</label>
                            <div class="formLabel"
                    *ngIf="CSCinte.controls['cmd_0072'].touched && CSCinte.controls['cmd_0072'].invalid">
                    <small class="text-danger" *ngIf="CSCinte.controls['cmd_0072'].errors?.required">
                        required
                    </small>
                   </div>
                   <span *ngIf="(CSCinte.controls['cmd_0072'].touched && CSCinte.controls['cmd_0072'].errors)
                     || (CSCinte.controls['cmd_0072'].errors && isSubmit)">
                   <i class="fas fa-times ml-2 text-danger"></i>
                      </span>
                    </div>
                   </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                            <div class="form-group form-inline no-margin sideLabelAlignment">
                              <label class="formLabel" style="width: 28px;">7</label>
                            </div>
                            <div class="col-md-8">
                           <label class="formLabel" style="font-size: 12px;">Have you, or any of your hospital or ambulatory surgery center privileges and/or memberships been denied,
                            revoked, suspended, reduced, placed on probation, proctored, placed under mandatory consultation, or
                            non-renewed?
                            
                           </label>
                         </div>
                  
                    <div class="col-md-2">
                        <label class="radio-inline radioStyle">
                            <input
                                type="radio"
                                id="cmd_0073"
                                name="cmd_0073"
                                [class.is-invalid]="(CSCinte.controls['cmd_0073'].touched 
                                && CSCinte.controls['cmd_0073'].invalid) || 
                                (CSCinte.controls['cmd_0073'].invalid && isSubmit)"
                            formControlName="cmd_0073"
                            value="1"
                            />Yes</label>
                            <label class="radio-inline radioStyle">
                            <input
                                 type="radio"
                                 id="cmd_0073"
                                name="cmd_0073"
                                [class.is-invalid]="(CSCinte.controls['cmd_0073'].touched 
                                && CSCinte.controls['cmd_0073'].invalid) || 
                                (CSCinte.controls['cmd_0073'].invalid && isSubmit)"
                            formControlName="cmd_0073"
                            value="0"
                            />No</label>
                            <div class="formLabel"
                    *ngIf="CSCinte.controls['cmd_0073'].touched && CSCinte.controls['cmd_0073'].invalid">
                    <small class="text-danger" *ngIf="CSCinte.controls['cmd_0073'].errors?.required">
                        required
                    </small>
                   </div>
                   <span *ngIf="(CSCinte.controls['cmd_0073'].touched && CSCinte.controls['cmd_0073'].errors)
                     || (CSCinte.controls['cmd_0073'].errors && isSubmit)">
                   <i class="fas fa-times ml-2 text-danger"></i>
                      </span>
                    </div>
                   </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                            <div class="form-group form-inline no-margin sideLabelAlignment">
                              <label class="formLabel" style="width: 28px;">8</label>
                            </div>
                            <div class="col-md-8">
                           <label class="formLabel" style="font-size: 12px;">Have you voluntarily or involuntarily relinquished or failed to seek renewal of your hospital or ambulatory
                            surgery center privileges for any reason?
                            
                           </label>
                         </div>
                  
                    <div class="col-md-2">
                        <label class="radio-inline radioStyle">
                            <input
                                type="radio"
                                id="cmd_0074"
                                name="cmd_0074"
                                [class.is-invalid]="(CSCinte.controls['cmd_0074'].touched 
                                && CSCinte.controls['cmd_0074'].invalid) || 
                                (CSCinte.controls['cmd_0074'].invalid && isSubmit)"
                            formControlName="cmd_0074"
                            value="1"
                            />Yes</label>
                            <label class="radio-inline radioStyle">
                            <input
                                 type="radio"
                                 id="cmd_0074"
                                name="cmd_0074"
                                [class.is-invalid]="(CSCinte.controls['cmd_0074'].touched 
                                && CSCinte.controls['cmd_0074'].invalid) || 
                                (CSCinte.controls['cmd_0074'].invalid && isSubmit)"
                            formControlName="cmd_0074"
                            value="0"
                            />No</label>
                            <div class="formLabel"
                    *ngIf="CSCinte.controls['cmd_0074'].touched && CSCinte.controls['cmd_0074'].invalid">
                    <small class="text-danger" *ngIf="CSCinte.controls['cmd_0074'].errors?.required">
                        required
                    </small>
                   </div>
                   <span *ngIf="(CSCinte.controls['cmd_0074'].touched && CSCinte.controls['cmd_0074'].errors)
                     || (CSCinte.controls['cmd_0074'].errors && isSubmit)">
                   <i class="fas fa-times ml-2 text-danger"></i>
                      </span>
                    </div>
                   </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                            <div class="form-group form-inline no-margin sideLabelAlignment">
                              <label class="formLabel" style="width: 28px;">9</label>
                            </div>
                            <div class="col-md-8">
                           <label class="formLabel" style="font-size: 12px;">Have any disciplinary actions or proceedings been instituted against you and/or are any disciplinary actions or
                            proceedings now pending with respect to your hospital or ambulatory surgery center privileges and/or your
                            license?
                           </label>
                         </div>
                  
                    <div class="col-md-2">
                        <label class="radio-inline radioStyle">
                            <input
                                type="radio"
                                id="cmd_0075"
                                name="cmd_0075"
                                [class.is-invalid]="(CSCinte.controls['cmd_0075'].touched 
                                && CSCinte.controls['cmd_0075'].invalid) || 
                                (CSCinte.controls['cmd_0075'].invalid && isSubmit)"
                            formControlName="cmd_0075"
                            value="1"
                            />Yes</label>
                            <label class="radio-inline radioStyle">
                            <input
                                 type="radio"
                                 id="cmd_0075"
                                name="cmd_0075"
                                [class.is-invalid]="(CSCinte.controls['cmd_0075'].touched 
                                && CSCinte.controls['cmd_0075'].invalid) || 
                                (CSCinte.controls['cmd_0075'].invalid && isSubmit)"
                            formControlName="cmd_0075"
                            value="0"
                            />No</label>
                            <div class="formLabel"
                    *ngIf="CSCinte.controls['cmd_0075'].touched && CSCinte.controls['cmd_0075'].invalid">
                    <small class="text-danger" *ngIf="CSCinte.controls['cmd_0075'].errors?.required">
                        required
                    </small>
                   </div>
                   <span *ngIf="(CSCinte.controls['cmd_0075'].touched && CSCinte.controls['cmd_0075'].errors)
                     || (CSCinte.controls['cmd_0075'].errors && isSubmit)">
                   <i class="fas fa-times ml-2 text-danger"></i>
                      </span>
                    </div>
                   </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                            <div class="form-group form-inline no-margin sideLabelAlignment">
                              <label class="formLabel" style="width: 28px;">10</label>
                            </div>
                            <div class="col-md-8">
                           <label class="formLabel" style="font-size: 12px;">Have you ever been reprimanded, censured, excluded, suspended, and/or disqualified from participating,
                            or voluntarily withdrawn to avoid an investigation, in Medicare, Medicaid, CHAMPUS, and/or any other
                            governmental health-related programs?
                           </label>
                         </div>
                  
                    <div class="col-md-2">
                        <label class="radio-inline radioStyle">
                            <input
                                type="radio"
                                id="cmd_0076"
                                name="cmd_0076"
                                [class.is-invalid]="(CSCinte.controls['cmd_0076'].touched 
                                && CSCinte.controls['cmd_0076'].invalid) || 
                                (CSCinte.controls['cmd_0076'].invalid && isSubmit)"
                            formControlName="cmd_0076"
                            value="1"
                            />Yes</label>
                            <label class="radio-inline radioStyle">
                            <input
                                 type="radio"
                                 id="cmd_0076"
                                name="cmd_0076"
                                [class.is-invalid]="(CSCinte.controls['cmd_0076'].touched 
                                && CSCinte.controls['cmd_0076'].invalid) || 
                                (CSCinte.controls['cmd_0076'].invalid && isSubmit)"
                            formControlName="cmd_0076"
                            value="0"
                            />No</label>
                            <div class="formLabel"
                    *ngIf="CSCinte.controls['cmd_0076'].touched && CSCinte.controls['cmd_0076'].invalid">
                    <small class="text-danger" *ngIf="CSCinte.controls['cmd_0076'].errors?.required">
                        required
                    </small>
                   </div>
                   <span *ngIf="(CSCinte.controls['cmd_0076'].touched && CSCinte.controls['cmd_0076'].errors)
                     || (CSCinte.controls['cmd_0076'].errors && isSubmit)">
                   <i class="fas fa-times ml-2 text-danger"></i>
                      </span>
                    </div>
                   </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                            <div class="form-group form-inline no-margin sideLabelAlignment">
                              <label class="formLabel" style="width: 28px;">11</label>
                            </div>
                            <div class="col-md-8">
                           <label class="formLabel" style="font-size: 12px;">Have Medicare, Medicaid, CHAMPUS, PRO authorities, and/or any other third-party payors brought
                            charges against you for alleged inappropriate fees and/or quality-of-care issues?
                           </label>
                         </div>
                  
                    <div class="col-md-2">
                        <label class="radio-inline radioStyle">
                            <input
                                type="radio"
                                id="cmd_0077"
                                name="cmd_0077"
                                [class.is-invalid]="(CSCinte.controls['cmd_0077'].touched 
                                && CSCinte.controls['cmd_0077'].invalid) || 
                                (CSCinte.controls['cmd_0077'].invalid && isSubmit)"
                            formControlName="cmd_0077"
                            value="1"
                            />Yes</label>
                            <label class="radio-inline radioStyle">
                            <input
                                 type="radio"
                                 id="cmd_0077"
                                name="cmd_0077"
                                [class.is-invalid]="(CSCinte.controls['cmd_0077'].touched 
                                && CSCinte.controls['cmd_0077'].invalid) || 
                                (CSCinte.controls['cmd_0077'].invalid && isSubmit)"
                            formControlName="cmd_0077"
                            value="0"
                            />No</label>
                            <div class="formLabel"
                    *ngIf="CSCinte.controls['cmd_0077'].touched && CSCinte.controls['cmd_0077'].invalid">
                    <small class="text-danger" *ngIf="CSCinte.controls['cmd_0077'].errors?.required">
                        required
                    </small>
                   </div>
                   <span *ngIf="(CSCinte.controls['cmd_0077'].touched && CSCinte.controls['cmd_0077'].errors)
                     || (CSCinte.controls['cmd_0077'].errors && isSubmit)">
                   <i class="fas fa-times ml-2 text-danger"></i>
                      </span>
                    </div>
                   </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                            <div class="form-group form-inline no-margin sideLabelAlignment">
                              <label class="formLabel" style="width: 28px;">12</label>
                            </div>
                            <div class="col-md-8">
                           <label class="formLabel" style="font-size: 12px;">Have you been denied membership and/or been subject to probation, reprimand, sanction, or disciplinary
                            action, or have you ever been notified in writing that you are being investigated as the possible subject of
                            a criminal or disciplinary action by any health care organization, e.g., hospital, HMO, PPO, IPA, professional
                            group or society, licensing board, certification board, PSRO, or PRO?
                           </label>
                         </div>
                  
                    <div class="col-md-2">
                        <label class="radio-inline radioStyle">
                            <input
                                type="radio"
                                id="cmd_0078"
                                name="cmd_0078"
                                [class.is-invalid]="(CSCinte.controls['cmd_0078'].touched 
                                && CSCinte.controls['cmd_0078'].invalid) || 
                                (CSCinte.controls['cmd_0078'].invalid && isSubmit)"
                            formControlName="cmd_0078"
                            value="1"
                            />Yes</label>
                            <label class="radio-inline radioStyle">
                            <input
                                 type="radio"
                                 id="cmd_0078"
                                name="cmd_0078"
                                [class.is-invalid]="(CSCinte.controls['cmd_0078'].touched 
                                && CSCinte.controls['cmd_0078'].invalid) || 
                                (CSCinte.controls['cmd_0078'].invalid && isSubmit)"
                            formControlName="cmd_0078"
                            value="0"
                            />No</label>
                            <div class="formLabel"
                    *ngIf="CSCinte.controls['cmd_0078'].touched && CSCinte.controls['cmd_0078'].invalid">
                    <small class="text-danger" *ngIf="CSCinte.controls['cmd_0078'].errors?.required">
                        required
                    </small>
                   </div>
                   <span *ngIf="(CSCinte.controls['cmd_0078'].touched && CSCinte.controls['cmd_0078'].errors)
                     || (CSCinte.controls['cmd_0078'].errors && isSubmit)">
                   <i class="fas fa-times ml-2 text-danger"></i>
                      </span>
                    </div>
                   </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                            <div class="form-group form-inline no-margin sideLabelAlignment">
                              <label class="formLabel" style="width: 28px;">13</label>
                            </div>
                            <div class="col-md-8">
                           <label class="formLabel" style="font-size: 12px;">Have you withdrawn an application or any portion or an application for appointment or reappointment for
                            clinical privileges or staff appointment or for license or membership in an IPA, PHO, professional group
                            or society, health care entity, or health care plan prior to a final decision to avoid a professional review or an
                            adverse action?
                           </label>
                         </div>
                  
                    <div class="col-md-2">
                        <label class="radio-inline radioStyle">
                            <input
                                type="radio"
                                id="cmd_0079"
                                name="cmd_0079"
                                [class.is-invalid]="(CSCinte.controls['cmd_0079'].touched 
                                && CSCinte.controls['cmd_0079'].invalid) || 
                                (CSCinte.controls['cmd_0079'].invalid && isSubmit)"
                            formControlName="cmd_0079"
                            value="1"
                            />Yes</label>
                            <label class="radio-inline radioStyle">
                            <input
                                 type="radio"
                                 id="cmd_0079"
                                name="cmd_0079"
                                [class.is-invalid]="(CSCinte.controls['cmd_0079'].touched 
                                && CSCinte.controls['cmd_0079'].invalid) || 
                                (CSCinte.controls['cmd_0079'].invalid && isSubmit)"
                            formControlName="cmd_0079"
                            value="0"
                            />No</label>
                            <div class="formLabel"
                    *ngIf="CSCinte.controls['cmd_0079'].touched && CSCinte.controls['cmd_0079'].invalid">
                    <small class="text-danger" *ngIf="CSCinte.controls['cmd_0079'].errors?.required">
                        required
                    </small>
                   </div>
                   <span *ngIf="(CSCinte.controls['cmd_0079'].touched && CSCinte.controls['cmd_0079'].errors)
                     || (CSCinte.controls['cmd_0079'].errors && isSubmit)">
                   <i class="fas fa-times ml-2 text-danger"></i>
                      </span>
                    </div>
                   </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                            <div class="form-group form-inline no-margin sideLabelAlignment">
                              <label class="formLabel" style="width: 28px;">14</label>
                            </div>
                            <div class="col-md-8">
                           <label class="formLabel" style="font-size: 12px;">Have you been charged with or convicted of a crime (other than a minor traffic offense) in this or any other
                            state or country and/or do you have any criminal charges pending other than minor traffic offenses in this
                            state or any other state or country?
                           </label>
                         </div>
                  
                    <div class="col-md-2">
                        <label class="radio-inline radioStyle">
                            <input
                                type="radio"
                                id="cmd_0080"
                                name="cmd_0080"
                                [class.is-invalid]="(CSCinte.controls['cmd_0080'].touched 
                                && CSCinte.controls['cmd_0080'].invalid) || 
                                (CSCinte.controls['cmd_0080'].invalid && isSubmit)"
                            formControlName="cmd_0080"
                            value="1"
                            />Yes</label>
                            <label class="radio-inline radioStyle">
                            <input
                                 type="radio"
                                 id="cmd_0080"
                                name="cmd_0080"
                                [class.is-invalid]="(CSCinte.controls['cmd_0080'].touched 
                                && CSCinte.controls['cmd_0080'].invalid) || 
                                (CSCinte.controls['cmd_0080'].invalid && isSubmit)"
                            formControlName="cmd_0080"
                            value="0"
                            />No</label>
                            <div class="formLabel"
                    *ngIf="CSCinte.controls['cmd_0080'].touched && CSCinte.controls['cmd_0080'].invalid">
                    <small class="text-danger" *ngIf="CSCinte.controls['cmd_0080'].errors?.required">
                        required
                    </small>
                   </div>
                   <span *ngIf="(CSCinte.controls['cmd_0080'].touched && CSCinte.controls['cmd_0080'].errors)
                     || (CSCinte.controls['cmd_0080'].errors && isSubmit)">
                   <i class="fas fa-times ml-2 text-danger"></i>
                      </span>
                    </div>
                   </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                            <div class="form-group form-inline no-margin sideLabelAlignment">
                              <label class="formLabel" style="width: 28px;">15</label>
                            </div>
                            <div class="col-md-8">
                           <label class="formLabel" style="font-size: 12px;">Have you been the subject of a civil or criminal or administrative action or been notified in writing that
                            you are being investigated as the possible subject at a civil, criminal, or administrative action regarding
                            sexual misconduct, child abuse, domestic violence, or elder abuse? 
                           </label>
                         </div>
                  
                    <div class="col-md-2">
                        <label class="radio-inline radioStyle">
                            <input
                                type="radio"
                                id="cmd_0081"
                                name="cmd_0081"
                                [class.is-invalid]="(CSCinte.controls['cmd_0081'].touched 
                                && CSCinte.controls['cmd_0081'].invalid) || 
                                (CSCinte.controls['cmd_0081'].invalid && isSubmit)"
                            formControlName="cmd_0081"
                            value="1"
                            />Yes</label>
                            <label class="radio-inline radioStyle">
                            <input
                                 type="radio"
                                 id="cmd_0081"
                                name="cmd_0081"
                                [class.is-invalid]="(CSCinte.controls['cmd_0081'].touched 
                                && CSCinte.controls['cmd_0081'].invalid) || 
                                (CSCinte.controls['cmd_0081'].invalid && isSubmit)"
                            formControlName="cmd_0081"
                            value="0"
                            />No</label>
                            <div class="formLabel"
                    *ngIf="CSCinte.controls['cmd_0081'].touched && CSCinte.controls['cmd_0081'].invalid">
                    <small class="text-danger" *ngIf="CSCinte.controls['cmd_0081'].errors?.required">
                        required
                    </small>
                   </div>
                   <span *ngIf="(CSCinte.controls['cmd_0081'].touched && CSCinte.controls['cmd_0081'].errors)
                     || (CSCinte.controls['cmd_0081'].errors && isSubmit)">
                   <i class="fas fa-times ml-2 text-danger"></i>
                      </span>
                    </div>
                   </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                            <div class="form-group form-inline no-margin sideLabelAlignment">
                              <label class="formLabel" style="width: 28px;">16</label>
                            </div>
                            <div class="col-md-8">
                           <label class="formLabel" style="font-size: 12px;">Have you had a refusal or cancellation of professional liability coverage? 
                           </label>
                         </div>
                  
                    <div class="col-md-2">
                        <label class="radio-inline radioStyle">
                            <input
                                type="radio"
                                id="cmd_0082"
                                name="cmd_0082"
                                [class.is-invalid]="(CSCinte.controls['cmd_0082'].touched 
                                && CSCinte.controls['cmd_0082'].invalid) || 
                                (CSCinte.controls['cmd_0082'].invalid && isSubmit)"
                            formControlName="cmd_0082"
                            value="1"
                            />Yes</label>
                            <label class="radio-inline radioStyle">
                            <input
                                 type="radio"
                                 id="cmd_0082"
                                name="cmd_0082"
                                [class.is-invalid]="(CSCinte.controls['cmd_0082'].touched 
                                && CSCinte.controls['cmd_0082'].invalid) || 
                                (CSCinte.controls['cmd_0082'].invalid && isSubmit)"
                            formControlName="cmd_0082"
                            value="0"
                            />No</label>
                            <div class="formLabel"
                    *ngIf="CSCinte.controls['cmd_0082'].touched && CSCinte.controls['cmd_0082'].invalid">
                    <small class="text-danger" *ngIf="CSCinte.controls['cmd_0082'].errors?.required">
                        required
                    </small>
                   </div>
                   <span *ngIf="(CSCinte.controls['cmd_0082'].touched && CSCinte.controls['cmd_0082'].errors)
                     || (CSCinte.controls['cmd_0082'].errors && isSubmit)">
                   <i class="fas fa-times ml-2 text-danger"></i>
                      </span>
                    </div>
                   </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                    <div class="col-md-8">
                        <h2  style="margin-left: 31px; color: blue; font-size: 16px;"><b>Health Status</b></h2>
                    </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                            <div class="form-group form-inline no-margin sideLabelAlignment">
                              <label class="formLabel" style="width: 28px;">1</label>
                            </div>
                            <div class="col-md-8">
                           <label class="formLabel" style="font-size: 12px;">Do you have a medical condition, physical defect, or emotional impairment which in any way impairs
                            and/or limits your ability to practice medicine with reasonable skill and safety?
                           </label>
                         </div>
                  
                    <div class="col-md-2">
                        <label class="radio-inline radioStyle">
                            <input
                                type="radio"
                                id="cmd_0083"
                                name="cmd_0083"
                                [class.is-invalid]="(CSCinte.controls['cmd_0083'].touched 
                                && CSCinte.controls['cmd_0083'].invalid) || 
                                (CSCinte.controls['cmd_0083'].invalid && isSubmit)"
                            formControlName="cmd_0083"
                            value="1"
                            />Yes</label>
                            <label class="radio-inline radioStyle">
                            <input
                                 type="radio"
                                 id="cmd_0083"
                                name="cmd_0083"
                                [class.is-invalid]="(CSCinte.controls['cmd_0083'].touched 
                                && CSCinte.controls['cmd_0083'].invalid) || 
                                (CSCinte.controls['cmd_0083'].invalid && isSubmit)"
                            formControlName="cmd_0083"
                            value="0"
                            />No</label>
                            <span *ngIf="(CSCinte.controls['cmd_0083'].touched && CSCinte.controls['cmd_0083'].errors)
                            || (CSCinte.controls['cmd_0083'].errors && isSubmit)">
                          <i class="fas fa-times ml-2 text-danger"></i>
                             </span>
                            <span class="formLabel"
                            *ngIf="
                            (CSCinte.controls['cmd_0083'].touched &&
                            CSCinte.controls['cmd_0083'].errors) ||
                            (CSCinte.controls['cmd_0083'].errors && isSubmit)
                          ">
                    <small class="text-danger" *ngIf="CSCinte.controls['cmd_0083'].errors?.required">
                        required
                    </small>
                            </span>
                  
                    </div>
                   </div>
                </td>
            </tr>
             
            <tr>
                <td colspan="12">
                    <div class="row">
                            <div class="form-group form-inline no-margin sideLabelAlignment">
                              <label class="formLabel" style="width: 28px;">2</label>
                            </div>
                            <div class="col-md-8">
                           <label class="formLabel" style="font-size: 12px;">Are you able to perform the essential functions of a practitioner in your area of practice, with or 
                            without reasonable accommodation?
                           </label>
                         </div>
                  
                    <div class="col-md-2">
                        <label class="radio-inline radioStyle">
                            <input
                                type="radio"
                                id="cmd_0084"
                                name="cmd_0084"
                                [class.is-invalid]="(CSCinte.controls['cmd_0084'].touched 
                                && CSCinte.controls['cmd_0084'].invalid) || 
                                (CSCinte.controls['cmd_0084'].invalid && isSubmit)"
                            formControlName="cmd_0084"
                            value="1"
                            />Yes</label>
                            <label class="radio-inline radioStyle">
                            <input
                                 type="radio"
                                 id="cmd_0084"
                                name="cmd_0084"
                                [class.is-invalid]="(CSCinte.controls['cmd_0084'].touched 
                                && CSCinte.controls['cmd_0084'].invalid) || 
                                (CSCinte.controls['cmd_0084'].invalid && isSubmit)"
                            formControlName="cmd_0084"
                            value="0"
                            />No</label>
                            <div class="formLabel"
                    *ngIf="CSCinte.controls['cmd_0084'].touched && CSCinte.controls['cmd_0084'].invalid">
                    <small class="text-danger" *ngIf="CSCinte.controls['cmd_0084'].errors?.required">
                        required
                    </small>
                   </div>
                   <span *ngIf="(CSCinte.controls['cmd_0084'].touched && CSCinte.controls['cmd_0084'].errors)
                     || (CSCinte.controls['cmd_0084'].errors && isSubmit)">
                   <i class="fas fa-times ml-2 text-danger"></i>
                      </span>
                    </div>
                   </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                            <div class="form-group form-inline no-margin sideLabelAlignment">
                              <label class="formLabel" style="width: 28px;">3</label>
                            </div>
                            <div class="col-md-8">
                           <label class="formLabel" style="font-size: 12px;">Have you been or are you currently under the care of a physician for any condition that may affect your
                            ability to provide patient care or your ability to perform the privileges requested?
                           </label>
                         </div>
                  
                    <div class="col-md-2">
                        <label class="radio-inline radioStyle">
                            <input
                                type="radio"
                                id="cmd_0085"
                                name="cmd_0085"
                                [class.is-invalid]="(CSCinte.controls['cmd_0085'].touched 
                                && CSCinte.controls['cmd_0085'].invalid) || 
                                (CSCinte.controls['cmd_0085'].invalid && isSubmit)"
                            formControlName="cmd_0085"
                            value="1"
                            />Yes</label>
                            <label class="radio-inline radioStyle">
                            <input
                                 type="radio"
                                 id="cmd_0085"
                                name="cmd_0085"
                                [class.is-invalid]="(CSCinte.controls['cmd_0085'].touched 
                                && CSCinte.controls['cmd_0085'].invalid) || 
                                (CSCinte.controls['cmd_0085'].invalid && isSubmit)"
                            formControlName="cmd_0085"
                            value="0"
                            />No</label>
                            <div class="formLabel"
                    *ngIf="CSCinte.controls['cmd_0085'].touched && CSCinte.controls['cmd_0085'].invalid">
                    <small class="text-danger" *ngIf="CSCinte.controls['cmd_0085'].errors?.required">
                        required
                    </small>
                   </div>
                   <span *ngIf="(CSCinte.controls['cmd_0085'].touched && CSCinte.controls['cmd_0085'].errors)
                     || (CSCinte.controls['cmd_0085'].errors && isSubmit)">
                   <i class="fas fa-times ml-2 text-danger"></i>
                      </span>
                    </div>
                   </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                        <div class="col-md-12">
                            <h6 style="font-size: 12px;">If an accommodation is necessary to perform the requested clinical privileges, describe on a separate
                                sheet of paper how you would perform the tasks and with what accommodations and attach.</h6>
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                    <div class="col-md-8">
                        <h2  style="margin-left: 31px; color: blue; font-size: 16px;"><b>Chemical Substance or Alcohol Abuse
                        </b></h2>
                    </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                            <div class="form-group form-inline no-margin sideLabelAlignment">
                              <label class="formLabel" style="width: 28px;">1</label>
                            </div>
                            <div class="col-md-8">
                           <label class="formLabel" style="font-size: 12px;">Are you currently engaged in illegal use of any legal or illegal substances?
                           </label>
                         </div>
                  
                    <div class="col-md-2">
                        <label class="radio-inline radioStyle">
                            <input
                                type="radio"
                                id="cmd_0086"
                                name="cmd_0086"
                                [class.is-invalid]="(CSCinte.controls['cmd_0086'].touched 
                                && CSCinte.controls['cmd_0086'].invalid) || 
                                (CSCinte.controls['cmd_0086'].invalid && isSubmit)"
                            formControlName="cmd_0086"
                            value="1"
                            />Yes</label>
                            <label class="radio-inline radioStyle">
                            <input
                                 type="radio"
                                 id="cmd_0086"
                                name="cmd_0086"
                                [class.is-invalid]="(CSCinte.controls['cmd_0086'].touched 
                                && CSCinte.controls['cmd_0086'].invalid) || 
                                (CSCinte.controls['cmd_0086'].invalid && isSubmit)"
                            formControlName="cmd_0086"
                            value="0"
                            />No</label>
                            <span *ngIf="(CSCinte.controls['cmd_0086'].touched && CSCinte.controls['cmd_0086'].errors)
                     || (CSCinte.controls['cmd_0086'].errors && isSubmit)">
                   <i class="fas fa-times ml-2 text-danger"></i>
                      </span>
                            <span class="formLabel"
                            *ngIf="
                            (CSCinte.controls['cmd_0086'].touched &&
                            CSCinte.controls['cmd_0086'].errors) ||
                            (CSCinte.controls['cmd_0086'].errors && isSubmit)
                          ">
                    <small class="text-danger" *ngIf="CSCinte.controls['cmd_0086'].errors?.required">
                        required
                    </small>
                            </span>
                   
                    </div>
                   </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                            <div class="form-group form-inline no-margin sideLabelAlignment">
                              <label class="formLabel" style="width: 28px;">2</label>
                            </div>
                            <div class="col-md-8">
                           <label class="formLabel" style="font-size: 12px;">Do you use any chemical substances that would in any way impair or limit your ability to practice
                            medicine and perform the functions of your job with reasonable skill and safety? 
                           </label>
                         </div>
                  
                    <div class="col-md-2">
                        <label class="radio-inline radioStyle">
                            <input
                                type="radio"
                                id="cmd_0087"
                                name="cmd_0087"
                                [class.is-invalid]="(CSCinte.controls['cmd_0087'].touched 
                                && CSCinte.controls['cmd_0087'].invalid) || 
                                (CSCinte.controls['cmd_0087'].invalid && isSubmit)"
                            formControlName="cmd_0087"
                            value="1"
                            />Yes</label>
                            <label class="radio-inline radioStyle">
                            <input
                                 type="radio"
                                 id="cmd_0087"
                                name="cmd_0087"
                                [class.is-invalid]="(CSCinte.controls['cmd_0087'].touched 
                                && CSCinte.controls['cmd_0087'].invalid) || 
                                (CSCinte.controls['cmd_0087'].invalid && isSubmit)"
                            formControlName="cmd_0087"
                            value="0"
                            />No</label>
                            <div class="formLabel"
                    *ngIf="CSCinte.controls['cmd_0087'].touched && CSCinte.controls['cmd_0087'].invalid">
                    <small class="text-danger" *ngIf="CSCinte.controls['cmd_0087'].errors?.required">
                        required
                    </small>
                   </div>
                   <span *ngIf="(CSCinte.controls['cmd_0087'].touched && CSCinte.controls['cmd_0087'].errors)
                     || (CSCinte.controls['cmd_0087'].errors && isSubmit)">
                   <i class="fas fa-times ml-2 text-danger"></i>
                      </span>
                    </div>
                   </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                            <div class="form-group form-inline no-margin sideLabelAlignment">
                              <label class="formLabel" style="width: 28px;">3</label>
                            </div>
                            <div class="col-md-8">
                           <label class="formLabel" style="font-size: 12px;">Have you received inpatient (hospital or residential) or outpatient treatment or therapy for alcohol Yes No
                            or drug abuse or dependency?
                             
                           </label>
                         </div>
                  
                    <div class="col-md-2">
                        <label class="radio-inline radioStyle">
                            <input
                                type="radio"
                                id="cmd_0088"
                                name="cmd_0088"
                                [class.is-invalid]="(CSCinte.controls['cmd_0088'].touched 
                                && CSCinte.controls['cmd_0088'].invalid) || 
                                (CSCinte.controls['cmd_0088'].invalid && isSubmit)"
                            formControlName="cmd_0088"
                            value="1"
                            />Yes</label>
                            <label class="radio-inline radioStyle">
                            <input
                                 type="radio"
                                 id="cmd_0088"
                                name="cmd_0088"
                                [class.is-invalid]="(CSCinte.controls['cmd_0088'].touched 
                                && CSCinte.controls['cmd_0088'].invalid) || 
                                (CSCinte.controls['cmd_0088'].invalid && isSubmit)"
                            formControlName="cmd_0088"
                            value="0"
                            />No</label>
                            <div class="formLabel"
                    *ngIf="CSCinte.controls['cmd_0088'].touched && CSCinte.controls['cmd_0088'].invalid">
                    <small class="text-danger" *ngIf="CSCinte.controls['cmd_0088'].errors?.required">
                        required
                    </small>
                   </div>
                   <span *ngIf="(CSCinte.controls['cmd_0088'].touched && CSCinte.controls['cmd_0088'].errors)
                     || (CSCinte.controls['cmd_0088'].errors && isSubmit)">
                   <i class="fas fa-times ml-2 text-danger"></i>
                      </span>
                    </div>
                   </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                            <div class="form-group form-inline no-margin sideLabelAlignment">
                              <label class="formLabel" style="width: 28px;">4</label>
                            </div>
                            <div class="col-md-8">
                           <label class="formLabel" style="font-size: 12px;">Have you been or are you enrolled in an impaired professional program?
                           </label>
                         </div>
                  
                    <div class="col-md-2">
                        <label class="radio-inline radioStyle">
                            <input
                                type="radio"
                                id="cmd_0089"
                                name="cmd_0089"
                                [class.is-invalid]="(CSCinte.controls['cmd_0089'].touched 
                                && CSCinte.controls['cmd_0089'].invalid) || 
                                (CSCinte.controls['cmd_0089'].invalid && isSubmit)"
                            formControlName="cmd_0089"
                            value="1"
                            />Yes</label>
                            <label class="radio-inline radioStyle">
                            <input
                                 type="radio"
                                 id="cmd_0089"
                                name="cmd_0089"
                                [class.is-invalid]="(CSCinte.controls['cmd_0089'].touched 
                                && CSCinte.controls['cmd_0089'].invalid) || 
                                (CSCinte.controls['cmd_0089'].invalid && isSubmit)"
                            formControlName="cmd_0089"
                            value="0"
                            />No</label>
                            <div class="formLabel"
                    *ngIf="CSCinte.controls['cmd_0089'].touched && CSCinte.controls['cmd_0089'].invalid">
                    <small class="text-danger" *ngIf="CSCinte.controls['cmd_0089'].errors?.required">
                        required
                    </small>
                   </div>
                   <span *ngIf="(CSCinte.controls['cmd_0089'].touched && CSCinte.controls['cmd_0089'].errors)
                     || (CSCinte.controls['cmd_0089'].errors && isSubmit)">
                   <i class="fas fa-times ml-2 text-danger"></i>
                      </span>
                    </div>
                   </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                        <div class="col-md-12">
                            <h6 style="margin-left: 410px; font-size: 12px;">If yes to any of the above, please explain on a separate sheet.</h6>
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row pbold">
                    <div class="col-md-8">
                        <h2  style="margin-left: 31px; color: blue; font-size: 16px;"><b>Applicant’s Consent and Release
                        </b></h2>

                    </div>
                    <p style="margin-left: 25px; margin-top: 21px; font-size: 12px;">As an applicant, I have the burden of producing adequate information for proper evaluation of my application. Failure to produce this
                        information or additional information requested by the Organization or its authorized representatives will prevent my application from
                        being evaluated and acted upon.<br><br> Information given in or attached to this application is accurate and fairly represents the current level of my training, experience,
                        capability and competence to practice with the clinical privileges requested. As a condition to making this application, any
                        misrepresentation, misstatement in, or omission from this application whether intentional or not shall, of itself alone, constitute cause
                        for automatic and immediate rejection of this application resulting in denial of appointment and clinical privileges. In the event that
                        appointment has been granted prior to the discovery of such misrepresentations, misstatement or omission, such discovery may result
                        in immediate termination of my medical staff appointment and clinical privileges.<br><br>
                        By applying for appointment and for clinical privileges, I expressly accept the following conditions during the processing and
                        consideration of my application, regardless of whether or not I am granted appointment or privileges, and for the duration of such
                        appointment or reappointments as I may be granted.<br></p>
                       <p style="margin-left: 50px; margin-top: 7px;"> I extend absolute immunity to, and release from any and all liability, the Center and its authorized representatives, including but
                        not limited to, IntelliCentrics, Inc., for any acts, communications, reports, records, statements, documents, recommendations or
                        disclosures involving me, performed, made, requested or received by this Center and its authorized representatives to, from, or
                        by any third party, including otherwise privileged or confidential information, relating, but not limited to, the following: </p><br>
                        <ol>
                            <li>applications for appointment or clinical privileges, including temporary privileges;</li>
                            <li>periodic reappraisals undertaken for reappointment or for increase or decrease in clinical privileges;</li>
                            <li>proceedings for suspension or reduction of clinical privileges or for revocation of appointment, or any other
                                disciplinary sanction or corrective action;</li>
                            <li>summary suspension; </li>
                            <li>hearings and appellate reviews; </li>
                            <li>quality assurance activities;</li>
                            <li>utilization reviews;</li>
                            <li>other health care institution medical staff, departmental, service or committee activities relating to the quality of patient
                                care rendered by me or my professional conduct;</li>
                            <li>matters or inquiries concerning my professional qualifications, credentials, clinical competence, character, ability,
                                ethics or behavior; or</li>
                            <li>any other matter that might directly or indirectly have an effect on my competence, or patient care or on the orderly
                                operation of this or any other health care institution.</li>
                        </ol>
                        <p>If appointed and granted clinical privileges, I specifically agree to 1. refrain from fee splitting or other inducements relating to patient
                            referral; 2. refrain from delegating responsibility for diagnosis or care of patients to a medical, dental or other practitioner who is not
                            qualified to undertake this responsibility and who is not adequately supervised; 3. refrain from deceiving patients as to the identity of
                            any medical or other practitioner providing treatment or services; 4. seek consultation whenever necessary; 5. abide by generally
                            recognized ethical principles applicable to my profession; 6. provide continuous care and supervision as needed to all patients in the
                            Center for whom I have responsibility; and 7. accept committee assignments and such other duties and responsibilities as shall be
                            assigned to me by the Governing Board and medical staff.</p>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                        <div class="col-md-4">
                                <img *ngIf="!showOne" class="img" src="./assets/images/e_sign.png" alt="Credential my Doc"
                            (click)="esignOpenOne()" />
                                <img *ngIf="showOne" class="img eSignImageShow" [src]="esignOne" alt="Credential my Doc"
                            (click)="esignOpenOne()" />
                                <input type="hidden" name="cmd_0090" id="cmd_0090" [class.is-invalid]="(CSCinte.controls['cmd_0090'].touched && CSCinte.controls['cmd_0090'].invalid) || (CSCinte.controls['cmd_0090'].invalid && isSubmit)" formControlName="cmd_0090" value="{{ finalImgPath }}" />
                                <div class="invalid-feedback"
                                    *ngIf="(CSCinte.controls['cmd_0090'].touched && CSCinte.controls['cmd_0090'].invalid) || (CSCinte.controls['cmd_0090'].invalid && isSubmit)">
                                         Signature is required and cannot be empty
                                </div>
                                <div class="col-md-2" style="padding-left:60px">
                                    <label (click)="esignOpenOne()" style="margin-left: -53px;">Signature:</label>
                                </div>  
                        </div>
                        <div class="col-md-3">
                            <div class="input-group">
                                <input class="form-control onboard datepicker" formControlName="cmd_0091" (blur)="clearInputIfInvalidMandi('cmd_0091')"
                                [class.is-invalid]="(CSCinte.controls['cmd_0091'].touched && CSCinte.controls['cmd_0091'].invalid) || (CSCinte.controls['cmd_0091'].invalid && isSubmit)"
                                ngbDatepicker #d172="ngbDatepicker" id="cmd_0091" name="cmd_0091" type="text" placeholder= "Selection for the date" data-id="cmd_0091">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d172.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                </div>
                                <div class="formLabel col-md-11"
                                *ngIf="
                                (CSCinte.controls['cmd_0091'].touched &&
                                CSCinte.controls['cmd_0091'].errors) ||
                                (CSCinte.controls['cmd_0091'].errors && isSubmit)
                              ">
                      
                                </div>
                            </div>
                            <div class="text-danger" *ngIf="(CSCinte.controls['cmd_0091'].invalid&&CSCinte.controls['cmd_0091'].touched) && (CSCinte.controls['cmd_0091'].value==''|| CSCinte.controls['cmd_0091'].value==null) " >
                                <small >
                                required
                                </small>
              
                            </div>
                              <small class="text-danger" *ngIf="CSCinte.get('cmd_0091').errors?.invalidDateFormat">
                                Only Date Format is allowed(MM/DD/YYYY)                </small>
                              <small class="text-danger" *ngIf="CSCinte.get('cmd_0091').errors?.invalidMonth">
                              Month is out of range
                              </small>
                              <small class="text-danger" *ngIf="CSCinte.get('cmd_0091').errors?.invalidDateyear">
                                year is out of range(1900-2099)                  </small>
                                <small class="text-danger" *ngIf="CSCinte.get('cmd_0091').errors?.invalidDateRange">
                                  date is out of range
                                  </small>
                            <label class="formlabel" style="font-size: 12px;">Date (MM/DD/YY)</label>

                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                        <div class="col-md-6">
                            <input class="form-control onboard"  formControlName="cmd_0093" 
                                id="cmd_0093" name="cmd_0093" type="text" placeholder= "Printed Name" [class.is-invalid]="(CSCinte.controls['cmd_0093'].touched && CSCinte.controls['cmd_0093'].invalid) || (CSCinte.controls['cmd_0093'].invalid && isSubmit)"  data-id="managing_contact_name" >
                            <div class="formLabel col-md-11"
                                *ngIf="CSCinte.controls['cmd_0093'].touched && CSCinte.controls['cmd_0093'].invalid">
                                <small class="text-danger" *ngIf="CSCinte.controls['cmd_0093'].errors?.required">
                                    required
                                </small>
                            </div>
                            <label class="formlabel" style="font-size: 12px;">Print Name </label>
                        </div>
                    </div>
                </td>
            </tr>

            <tr>
                <td colspan="12">
                    <div class="row pbold">
                    <div class="col-md-8">
                        <h2  style="margin-left: 31px; color: blue; font-size: 16px;"><b>Attestation
                        </b></h2>

                    </div>
                    <div class="col-md-12">
                    <p style="font-size: 12px;">By applying for clinical privileges, I hereby signify my willingness to appear for interviews in regard to my application,
                        and I authorize the “Organization,” its medical staff, and their representatives to consult with members of management
                        and members of medical staffs of other hospitals or institutions with which I have been associated and with others,
                        including past and present malpractice insurance carriers, who may have information bearing on my professional
                        competence, character, and ethical qualifications. I hereby further consent to inspection by the “Organization,” its
                        medical staff, and its representatives of all records and documents, including medical and credential records at other
                        hospitals, which may be material to an evaluation of my qualifications for staff membership. I hereby release from
                        liability all representatives of the “Organization” and its medical staff, in their individual and collective capacities, for
                        their acts performed in good faith and without malice in connection with evaluating my application and my credentials
                        and qualifications, and I hereby release from any liability any and all individuals and organizations who provide
                        information<br>to the “Organization” or to members of its medical staff in good faith and without malice concerning my professional
                        competence, ethics, character, and other qualifications for staff appointment and clinical privileges. I hereby consent
                        to the release of information by other hospitals, other medical associations, and other authorized persons, on request,
                        regarding any questions the “Organization” may have concerning me as long as such release of information is done
                        in good faith and without malice, and I hereby release from liability and hold harmless the “Organization” and any other
                        third party for so doing. I understand and agree that I, as an applicant for clinical privileges, have the burden of
                        producing adequate information for the proper evaluation of my professional competence, character, ethics, and other
                        qualifications and for the resolution of any doubts about such qualifications.<br>By accepting appointment and/or reappointment to the medical staff at Comprehensive Surgical Care, I hereby
                        acknowledge and represent that I have read and am familiar with the bylaws, rules, and regulations of the
                        “Organization”, as well as the principles, standards, and ethics of the national, state, and local associations and state
                        law and regulations that apply to and govern my specialty and/or profession, which are the “Governing Standards.” I
                        further agree to abide by such further Governing Standards as may be enacted from time to time<br>In addition, I agree to notify the “Organization” of any circumstances that would change my status in licensure, DEA,
                        Medicare participation, liability insurance coverage, board certification status, or hospital privileges.<br>I understand and agree that any significant misstatements in or omissions from this application shall constitute cause
                        for denial of appointment or cause for summary dismissal from the medical staff with no right of appeal. All information
                        submitted by me in this application is true to the best of my knowledge and belief.<br>I further authorize a photocopy or facsimile of the requests, authorizations, and releases to this application to serve as
                        the original.
                        </p>
                    </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                        <div class="col-md-4">
                                <img *ngIf="!showTwo" class="img" src="./assets/images/e_sign.png" alt="Credential my Doc"
                            (click)="esignOpenTwo()" />
                                <img *ngIf="showTwo" class="img eSignImageShow" [src]="esignTwo" alt="Credential my Doc"
                            (click)="esignOpenTwo()" />
                                <input type="hidden" name="cmd_0094" id="cmd_0094" [class.is-invalid]="(CSCinte.controls['cmd_0094'].touched && CSCinte.controls['cmd_0094'].invalid) || (CSCinte.controls['cmd_0094'].invalid && isSubmit)" formControlName="cmd_0094" value="{{ finalImgPath }}" />
                                <div class="invalid-feedback"
                                    *ngIf="(CSCinte.controls['cmd_0094'].touched && CSCinte.controls['cmd_0094'].invalid) || (CSCinte.controls['cmd_0094'].invalid && isSubmit)">
                                         Signature is required and cannot be empty
                                </div>
                                <div class="col-md-2" style="padding-left:60px">
                                    <label (click)="esignOpenTwo()" style="margin-left: -53px;">Signature:</label>
                                </div>  
                        </div>
                        <div class="col-md-3">
                            <div class="input-group">
                                <input class="form-control onboard datepicker" formControlName="cmd_0095" (blur)="clearInputIfInvalidMandi('cmd_0095')"
                                [class.is-invalid]="(CSCinte.controls['cmd_0095'].touched && CSCinte.controls['cmd_0095'].invalid) || (CSCinte.controls['cmd_0095'].invalid && isSubmit)"
                                ngbDatepicker #d173="ngbDatepicker" id="cmd_0095" name="cmd_0095" type="text" placeholder= "Selection for the date" data-id="cmd_0095">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d173.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                </div>
                                <div class="formLabel col-md-11"
                                *ngIf="
                                (CSCinte.controls['cmd_0095'].touched &&
                                CSCinte.controls['cmd_0095'].errors) ||
                                (CSCinte.controls['cmd_0095'].errors && isSubmit)
                              ">
                     
                                </div>
                            </div>
                            <div class="text-danger" *ngIf="(CSCinte.controls['cmd_0095'].invalid&&CSCinte.controls['cmd_0095'].touched) && (CSCinte.controls['cmd_0095'].value==''|| CSCinte.controls['cmd_0095'].value==null) " >
                                <small >
                                required
                                </small>
              
                            </div>
                              <small class="text-danger" *ngIf="CSCinte.get('cmd_0095').errors?.invalidDateFormat">
                                Only Date Format is allowed(MM/DD/YYYY)                </small>
                              <small class="text-danger" *ngIf="CSCinte.get('cmd_0095').errors?.invalidMonth">
                              Month is out of range
                              </small>
                              <small class="text-danger" *ngIf="CSCinte.get('cmd_0095').errors?.invalidDateyear">
                                year is out of range(1900-2099)                  </small>
                                <small class="text-danger" *ngIf="CSCinte.get('cmd_0095').errors?.invalidDateRange">
                                  date is out of range
                                  </small>
                            <label class="formlabel" style="font-size: 12px;">Date (MM/DD/YY)</label>

                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                        <div class="col-md-6">
                            <input class="form-control onboard"  formControlName="cmd_0093" 
                                id="cmd_0093" name="cmd_0093" type="text" placeholder= "Printed Name" [class.is-invalid]="(CSCinte.controls['cmd_0093'].touched && CSCinte.controls['cmd_0093'].invalid) || (CSCinte.controls['cmd_0093'].invalid && isSubmit)"  data-id="managing_contact_name" >
                            <div class="formLabel col-md-11"
                                *ngIf="CSCinte.controls['cmd_0093'].touched && CSCinte.controls['cmd_0093'].invalid">
                                <small class="text-danger" *ngIf="CSCinte.controls['cmd_0093'].errors?.required">
                                    required
                                </small>
                            </div>
                            <label class="formlabel" style="font-size: 12px;">Print Name </label>
                        </div>
                 </div>
                </td>
            </tr>
            
            
        </table>
    </form> 
    <div class="modal-footer mt-4">
        <button *ngIf="formData.receivedForm" type="button" class="btn customOrange float-right"
        (click)="saveForm('approve')">Approve</button>
        <button *ngIf="formData.submitForm" type="submit" class="btn btn customOrange float-right"
        (click)="saveForm('submit')">Submit</button>
        <button *ngIf="formData.submitForm" type="button" class="btn btn customOrange float-right"
        (click)="saveForm('save')">Save</button>
        <button *ngIf="formData.previewForm" type="button" class="btn customOrange float-right" (click)="close()">Close</button>
    </div>
</div>
