<div id="winterhealthcare">
  <div class="header">
    <div class="row"> 
      <div class="col-md-2">
        <img  class="logoImg" src="../../../../assets/images/logo.png"
          alt="Credential my Doc" />
      </div>
      <div class="col-md-10">
        <div class="text-center">
          <h2 >CredentialMyDoc Credentialing Application</h2>
        </div>
      </div>
    </div>  
  </div>
  <div class="section">
    <form [formGroup]="WinterHealthcareProviderForms">
      <div class="centered texasFirstTable">
        <b class="hThreeStyle">Section I-Individual Information</b>
      </div> 
      
        <table border="1">   
          <tbody>
            <tr>
              <td colspan="18">
                <div class="row">
                  <div class="col-md-4">
                      <label class="formLabel">TYPE OF PROFESSIONAL</label>
                    <div class="col-md-8">
                      <ng-select
                        [items]="credentials"
                        [class.is-invalid]="
                          (WinterHealthcareProviderForms.controls['822'].touched &&
                            WinterHealthcareProviderForms.controls['822'].invalid) ||
                          (WinterHealthcareProviderForms.controls['822'].invalid && isSubmit)
                        "
                        bindLabel="name"
                        (change)="onChangeCredentials($event)"
                        placeholder="Select Degree"
                        bindValue="id"
                        formControlName="822"
                      >
                      </ng-select>
                      <div
                        class="formLabel col-md-2"
                        *ngIf="
                          WinterHealthcareProviderForms.controls['822'].touched &&
                          WinterHealthcareProviderForms.controls['822'].invalid
                        "
                      >
                        <small
                          class="text-danger"
                          *ngIf="WinterHealthcareProviderForms.controls['822'].errors?.required"
                        >
                          required
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div class="row">
                  <div class="col-md-3">
                    <label class="formLabel">LAST NAME</label>
                    <input
                      class="form-control"
                      id="9"
                      [class.is-invalid]="
                        (WinterHealthcareProviderForms.controls['9'].touched &&
                          WinterHealthcareProviderForms.controls['9'].invalid) ||
                        (WinterHealthcareProviderForms.controls['9'].invalid && isSubmit)
                      "
                      name="9"
                      formControlName="9"
                      (change)="onChangeLastName($event)" 
                      type="text"
                      data-id="lname"
                    />
                    <div
                      class="formLabel col-md-2"
                      *ngIf="
                        WinterHealthcareProviderForms.controls['9'].touched &&
                        WinterHealthcareProviderForms.controls['9'].invalid
                      "
                    >
                      <small
                        class="text-danger"
                        *ngIf="WinterHealthcareProviderForms.controls['9'].errors?.required"
                      >
                        required
                      </small>
                    </div>
                  </div>
                  <div class="col-md-3 ">
                    <label class="formLabel">FIRST NAME</label>
                    <input
                      class="form-control"
                      id="8"
                      [class.is-invalid]="
                        (WinterHealthcareProviderForms.controls['8'].touched &&
                          WinterHealthcareProviderForms.controls['8'].invalid) ||
                        (WinterHealthcareProviderForms.controls['8'].invalid && isSubmit)
                      "
                      name="8"
                      (change)="onChangeFirstName($event)"
                      formControlName="8"
                      type="text"
                      data-id="fname"
                    />
                    <div
                      class="formLabel col-md-2"
                      *ngIf="
                        WinterHealthcareProviderForms.controls['8'].touched &&
                        WinterHealthcareProviderForms.controls['8'].invalid
                      "
                    >
                      <small
                        class="text-danger"
                        *ngIf="WinterHealthcareProviderForms.controls['8'].errors?.required"
                      >
                        required
                      </small>
                    </div>
                  </div>
                  <div class="col-md-3 ">
                    <label class="formLabel">MIDDLE NAME</label>
                    <input
                      class="form-control"
                      id="809"
                      name="809"
                      formControlName="809"
                      type="text"
                      data-id="mname"
                    />
                  </div>
                  <div class="col-md-3">
                      <label class="formLabel">(JR., SR., ETC.)</label>
                    
                      <ng-select
                        [items]="suffix"
                        bindLabel="name"
                        placeholder="Suffix"
                        bindValue="id"
                        formControlName="29"
                      >
                      </ng-select>
                    
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="7">
                <div class="row">
                  <div class="col-md-5">
                    <label class="formLabel">MAIDEN NAME </label>
                    <input
                      class="form-control"
                      id="278"
                      name="278"
                      formControlName="278"
                      type="text"
                      data-id="priorname"
                    />
                  </div>
                  <div class="col-md-5">
                    <label class="formLabel">YEARS ASSOCIATED (YYYY-YYYY) </label>
                    <div class="input-group">
                      <input
                        class="form-control onboard datepicker"
                        placeholder="MM/DD/YYYY"
                        ngbDatepicker
                        #d1="ngbDatepicker"
                        id="280"
                        name="280"
                        formControlName="280"
                        (blur)="clearInputIfInvalid('280')"
                        type="text"
                        data-id="priornameeffdate_date"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-outline-secondary calendar"
                          (click)="d1.toggle()"
                          type="button"
                        >
                          <i class="fas fa-calendar-alt"></i>
                        </button>
                      </div>
                    </div>
                    <div  *ngIf="WinterHealthcareProviderForms.get('280').invalid ">
                      <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('280').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)
                      </small>
                      <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('280').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('280').errors?.invalidDateyear">
                        year is out of range(1900-2099)                      </small>
                        <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('280').errors?.invalidDateRange">
                          date is out of range
                          </small>
                      </div>
                  
                    <div class="input-group">
                      <input
                        class="form-control onboard datepicker"
                        placeholder="MM/DD/YYYY"
                        ngbDatepicker
                        #d2="ngbDatepicker"
                        id="281"
                        name="281"
                        formControlName="281"
                        (blur)="clearInputIfInvalid('281')"
                        type="text"
                        data-id="priornametermdate_date"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-outline-secondary calendar"
                          (click)="d2.toggle()"
                          type="button"
                        >
                          <i class="fas fa-calendar-alt"></i>
                        </button>
                      </div>
                    </div>
                    <div  *ngIf="WinterHealthcareProviderForms.get('281').invalid ">
                      <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('281').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)
                      </small>
                      <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('281').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('281').errors?.invalidDateyear">
                        year is out of range(1900-2099)                      </small>
                        <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('281').errors?.invalidDateRange">
                          date is out of range
                          </small>
                      </div>
                  </div>  
                </div>
              </td>
              <td colspan="11">
                <div class="row">
                  <div class="col-md-6  ">
                    <label class="formLabel">OTHER NAME </label>
                    <input
                      class="form-control"
                      id="2337"
                      name="2337"
                      formControlName="2337"
                      type="text"
                      data-id="othername"
                    />
                  </div>
                  <div class="col-md-6">
                    <label class="formLabel">YEARS ASSOCIATED(YYYY-YYYY)</label>
                    <div class="input-group">
                      <input
                      class="form-control onboard datepicker"
                        placeholder="MM/DD/YYYY"
                        ngbDatepicker
                        #d3="ngbDatepicker"
                        id="cmd_01"
                        name="cmd_01"
                        formControlName="cmd_01"
                        (blur)="clearInputIfInvalid('cmd_01')"
                        type="text"
                        data-id="othernametermdate_date"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-outline-secondary calendar"
                          (click)="d3.toggle()"
                          type="button"
                        >
                          <i class="fas fa-calendar-alt"></i>
                        </button>
                      </div>
                    </div>
                    <div  *ngIf="WinterHealthcareProviderForms.get('cmd_01').invalid ">
                      <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('cmd_01').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)
                      </small>
                      <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('cmd_01').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('cmd_01').errors?.invalidDateyear">
                        year is out of range(1900-2099)                      </small>
                        <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('cmd_01').errors?.invalidDateRange">
                          date is out of range
                          </small>
                      </div>
                    <div class="input-group">
                      <input
                        class="form-control onboard datepicker"
                        placeholder="MM/DD/YYYY"
                        ngbDatepicker
                        #d4="ngbDatepicker"
                        id="cmd_02"
                        name="cmd_02"
                        formControlName="cmd_02"
                        (blur)="clearInputIfInvalid('cmd_02')"
                        type="text"
                        data-id="othernametermdate_date"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-outline-secondary calendar"
                          (click)="d4.toggle()"
                          type="button"
                        >
                          <i class="fas fa-calendar-alt"></i>
                        </button>
                      </div>
                    </div>
                    <div  *ngIf="WinterHealthcareProviderForms.get('cmd_02').invalid ">
                      <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('cmd_02').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)
                      </small>
                      <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('cmd_02').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('cmd_02').errors?.invalidDateyear">
                        year is out of range(1900-2099)                      </small>
                        <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('cmd_02').errors?.invalidDateRange">
                          date is out of range
                          </small>
                      </div>
                  </div>  
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18" style="padding:10px;">
                <div class="row">
                  <div class="col-md-2">
                      <label class="formLabel">HOME MAILING ADDRESS</label>
                  </div>  
                  <div class="col-md-4">
                      <input
                        class="form-control"
                        [class.is-invalid]="
                          (WinterHealthcareProviderForms.controls['16'].touched &&
                            WinterHealthcareProviderForms.controls['16'].errors) ||
                          (WinterHealthcareProviderForms.controls['16'].errors && isSubmit)
                        "
                        id="16"
                        name="16"
                        formControlName="16"
                        type="text"
                        data-id="homeaddress1"
                      />
                      <div
                        class="formLabel col-md-2"
                        *ngIf="
                          WinterHealthcareProviderForms.controls['16'].touched &&
                          WinterHealthcareProviderForms.controls['16'].errors
                        "
                      >
                        <small
                          class="text-danger"
                          *ngIf="WinterHealthcareProviderForms.controls['16'].errors?.required"
                        >
                          required
                        </small>
                      </div>
                  </div>
                  <div class="col-md-4">    
                      <input
                        class="form-control"
                        id="17"
                        name="17"
                        formControlName="17"
                        type="text"
                        data-id="homeaddress2"
                      />
                  </div>  
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div class="row">
                  <div class="col-md-3">
                    <label class="formLabel">CITY</label>
                    <input
                      class="form-control"
                      [class.is-invalid]="
                        (WinterHealthcareProviderForms.controls['18'].touched &&
                          WinterHealthcareProviderForms.controls['18'].invalid) ||
                        (WinterHealthcareProviderForms.controls['18'].invalid && isSubmit)
                      "
                      id="18"
                      name="18"
                      formControlName="18"
                      type="text"
                      data-id="homecity"
                    />
                    <div
                      class="formLabel col-md-2"
                      *ngIf="
                        WinterHealthcareProviderForms.controls['18'].touched &&
                        WinterHealthcareProviderForms.controls['18'].invalid
                      "
                    >
                      <small
                        class="text-danger"
                        *ngIf="WinterHealthcareProviderForms.controls['18'].errors?.required"
                      >
                        required
                      </small>
                    </div>
                  </div>
                  <div class="form-group col-md-3 sideLabelAlignment">
                    <label class="formLabel ">STATE/COUNTRY</label>
                    <div class="form-group col-md-12 dropDrownSet">
                      <ng-select
                        [items]="states"
                        [class.is-invalid]="
                          (WinterHealthcareProviderForms.controls['20'].touched &&
                            WinterHealthcareProviderForms.controls['20'].invalid) ||
                          (WinterHealthcareProviderForms.controls['20'].invalid && isSubmit)
                        "
                        bindLabel="name"
                        placeholder="Select States"
                        bindValue="id"
                        formControlName="20"
                      >
                      </ng-select>
                      <div
                        class="formLabel col-md-2"
                        *ngIf="
                          WinterHealthcareProviderForms.controls['20'].touched &&
                          WinterHealthcareProviderForms.controls['20'].invalid
                        "
                      >
                        <small
                          class="text-danger"
                          *ngIf="WinterHealthcareProviderForms.controls['20'].errors?.required"
                        >
                          required
                        </small>
                      </div>
                    </div>
                  </div>
                  <div class="form-group col-md-3 sideLabelAlignment">
                    <label class="formLabel">POSTAL CODE</label>
                    <input
                      class="form-control col-md-8"
                      minlength="5"
                      maxlength="10"
                      [class.is-invalid]="
                        (WinterHealthcareProviderForms.controls['21'].touched &&
                          WinterHealthcareProviderForms.controls['21'].invalid) ||
                        (WinterHealthcareProviderForms.controls['21'].invalid && isSubmit)
                      "
                      id="21"
                      name="21"
                      formControlName="21"
                      type="text"
                      data-id="homezip"
                    />
                    <div
                      class="formLabel col-md-10"
                      *ngIf="
                        WinterHealthcareProviderForms.controls['21'].touched &&
                        WinterHealthcareProviderForms.controls['21'].invalid
                      "
                    >
                      <small
                        class="text-danger"
                        *ngIf="WinterHealthcareProviderForms.controls['21'].errors?.required"
                      >
                        required
                      </small>
                      <small
                        class="text-danger"
                        *ngIf="
                          WinterHealthcareProviderForms.controls['21'].errors?.minlength ||
                          WinterHealthcareProviderForms.controls['21'].errors?.maxlength
                        "
                      >
                        Zip code must be atleast 5 digits and less than 10 digits
                      </small>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6">
                <div class="col-md-8">
                  <label class="formLabel">HOME PHONE NUMBER </label>
                  <input
                    class="form-control"
                    (keypress)="numberOnly($event)"
                    [class.is-invalid]="
                      (WinterHealthcareProviderForms.controls['708'].touched &&
                        WinterHealthcareProviderForms.controls['708'].invalid) ||
                      (WinterHealthcareProviderForms.controls['708'].invalid && isSubmit)
                    "
                    id="708"
                    name="708"
                    formControlName="708"
                    type="text"
                    data-id="homephone"
                  />
                  <div
                    class="formLabel col-md-2"
                    *ngIf="
                      WinterHealthcareProviderForms.controls['708'].touched &&
                      WinterHealthcareProviderForms.controls['708'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="WinterHealthcareProviderForms.controls['708'].errors?.required"
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
              <td colspan="6">
                <div
                  class="col-md-12"
                >
                  <label class="formLabel">SOCIAL SECURITY NUMBER </label>
                  <input
                    class="form-control"
                    [class.is-invalid]="
                      (WinterHealthcareProviderForms.controls['32'].touched &&
                        WinterHealthcareProviderForms.controls['32'].invalid) ||
                      (WinterHealthcareProviderForms.controls['32'].invalid && isSubmit)
                    "
                    id="32"
                    name="32"
                    formControlName="32"
                    type="text"
                    data-id="ssn"
                    (ngModelChange)="getSsn($event)"
                  />
                  <div
                    class="formLabel col-md-2"
                    *ngIf="
                      WinterHealthcareProviderForms.controls['32'].touched &&
                      WinterHealthcareProviderForms.controls['32'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="WinterHealthcareProviderForms.controls['32'].errors?.required"
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
              <td colspan="6">
                <div class="col-md-12">
                  <label class="formLabel">GENDER</label>
                  <div class="form-group selectDrop dropDrownSet">
                    <ng-select
                      [items]="gender"
                      bindLabel="name"
                      placeholder="Select Gender"
                      bindValue="id"
                      formControlName="30"
                    >
                    </ng-select>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18" style="padding:10px;">
                <div class="row">
                  <div class="col-md-3">
                    <label class="formLabel">CORRESPONDENCE ADDRESS </label>
                  </div>  
                    <div class="col-md-3">
                      <input
                        class="form-control"
                        id="cmd_837"
                        name="cmd_837"
                        formControlName="cmd_837"
                        type="text"
                        data-id="prac1_MailingAddress1"
                      />
                    </div>
                    <div class="col-md-3">
                      <input
                        class="form-control"
                        id="cmd_838"
                        name="cmd_838"
                        formControlName="cmd_838"
                        type="text"
                        data-id="prac1_MailingAddress2"
                      />
                    </div>
                  </div>
                 
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div class="row">
                  <div class="col-md-3">
                    <label class="formLabel">CITY</label>
                    <input
                      class="form-control"
                      id="cmd_839"
                      name="cmd_839"
                      formControlName="cmd_839"
                      type="text"
                      data-id="prac1_MailingCity"
                    />
                  </div>
                  <div class="col-md-3 ">
                    <label class="formLabel ">STATE/COUNTRY</label>
                    <div class="form-group dropDrownSet">
                      <div class="col-md-12">
                        <ng-select
                          [items]="states"
                          bindLabel="name"
                          placeholder="Select States"
                          bindValue="id"
                          formControlName="cmd_841"
                        >
                        </ng-select>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <label class="formLabel">POSTAL CODE</label>
                    <input
                      class="form-control col-md-8"
                      id="cmd_842"
                      name="cmd_842"
                      formControlName="cmd_842"
                      type="text"
                      data-id="prac1_MailingZIP"
                    />
                  </div>
                </div>
              </td>
            </tr>
            <tr >
              <td colspan="6" style="padding:10px;width:40%;">
                <div class="row">
                  <div class="col-md-5">
                    <label class="formLabel">PHONE NUMBER</label>
                  
                    <input
                      class="form-control"
                      id="cmd_870"
                      name="cmd_870"
                      (keypress)="numberOnly($event)"
                      formControlName="cmd_870"
                      type="text"
                      data-id="prac1_mailingphone"
                    />
                  </div>  
                </div>
              </td>
              <td colspan="6" style="padding:10px;width:40%;"  >
                <div class="row">
                  <div class="col-md-8">
                      <label class="formLabel">FAX NUMBER </label>
                    
                      <input
                        class="form-control"
                        id="cmd_871"
                        name="cmd_871"
                        formControlName="cmd_871"
                        type="text"
                        data-id="prac1_mailingfax"
                      />
                  </div>    
                </div>
              </td>
              <td colspan="6" style="padding:10px;width:40%;">
                <div class="row">
                  <div class="col-md-12">
                    <label class="formLabel">E-MAIL </label>
                    
                      <input
                        class="form-control"
                        
                        id="759"
                        name="759"
                        formControlName="759"
                        type="text"
                        data-id="hemail"
                      />
                  </div>    
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6" style="padding:10px;width:40%;">
                <div class="row" >
                  <div class="col-md-5">
                    <label class="formLabel">DATE OF BIRTH (MM/DD/YYYY) </label>
                  </div>
                  <div class="col-md-5">
                    <div class="input-group">
                      <input
                        class="form-control onboard"
                        [class.is-invalid]="
                          (WinterHealthcareProviderForms.controls['31'].touched &&
                            WinterHealthcareProviderForms.controls['31'].invalid) ||
                          (WinterHealthcareProviderForms.controls['31'].invalid && isSubmit)
                        "
                        placeholder="MM/DD/YYYY"
                        ngbDatepicker
                        #d5="ngbDatepicker"
                        id="31"
                        name="31"
                        formControlName="31"
                        (blur)="clearInputIfInvalidMandi('31')"
                        type="text"
                        data-id="dob_date"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-outline-secondary calendar"
                          (click)="d5.toggle()"
                          type="button"
                        >
                          <i class="fas fa-calendar-alt"></i>
                        </button>
                      </div>
                    </div>
                    <div class="text-danger"
                      *ngIf="(WinterHealthcareProviderForms.controls['31'].invalid&&WinterHealthcareProviderForms.controls['31'].touched) && (WinterHealthcareProviderForms.controls['31'].value==''|| WinterHealthcareProviderForms.controls['31'].value==null) ">
                      <small>
                        required
                      </small>
                    
                    </div>
                    <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('31').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY) </small>
                    <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('31').errors?.invalidMonth">
                      Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('31').errors?.invalidDateyear">
                      year is out of range(1900-2099) </small>
                    <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('31').errors?.invalidDateRange">
                      date is out of range
                    </small>
                  </div>  
                </div>
              </td>                
              <td colspan="6" style="padding:10px;width:40%;">
                <div class="form-inline no-margin col-md-12 sideLabelAlignment">
                  <label class="formLabel">PLACE OF BIRTH </label>
                </div>
                <div class="form-group no-margin col-md-6 sideLabelAlignment">
                  <input
                    class="form-control"
                    id="810"
                    [class.is-invalid]="
                      (WinterHealthcareProviderForms.controls['810'].touched &&
                        WinterHealthcareProviderForms.controls['810'].invalid) ||
                      (WinterHealthcareProviderForms.controls['810'].invalid && isSubmit)
                    "
                    name="810"
                    formControlName="810"
                    type="text"
                    data-id="bcity"
                  />
                  <div
                    class="formLabel col-md-2"
                    *ngIf="
                      WinterHealthcareProviderForms.controls['810'].touched &&
                      WinterHealthcareProviderForms.controls['810'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="WinterHealthcareProviderForms.controls['810'].errors?.required"
                    >
                      required
                    </small>
                  </div>
                </div>
                
                  <div class="col-md-7">
                    <ng-select
                      [items]="states"
                      [class.is-invalid]="
                        (WinterHealthcareProviderForms.controls['289'].touched &&
                          WinterHealthcareProviderForms.controls['289'].invalid) ||
                        (WinterHealthcareProviderForms.controls['289'].invalid && isSubmit)
                      "
                      bindLabel="name"
                      placeholder="Select States"
                      bindValue="id"
                      formControlName="289"
                    >
                    </ng-select>
                    <div
                      class="formLabel col-md-2"
                      *ngIf="
                        WinterHealthcareProviderForms.controls['289'].touched &&
                        WinterHealthcareProviderForms.controls['289'].invalid
                      "
                    >
                      <small
                        class="text-danger"
                        *ngIf="WinterHealthcareProviderForms.controls['289'].errors?.required"
                      >
                        required
                      </small>
                    </div>
                  </div>
                
              </td>
              <td colspan="6" style="padding:10px;width:40%;">
                <div class="col-md-12">
                  <label class="formLabel">CITIZENSHIP </label>
                  <input
                    class="form-control"
                    [class.is-invalid]="
                        (WinterHealthcareProviderForms.controls['292'].touched &&
                          WinterHealthcareProviderForms.controls['292'].invalid) ||
                        (WinterHealthcareProviderForms.controls['292'].invalid && isSubmit)
                      "
                    id="292"
                    name="292"
                    formControlName="292"
                    type="text"
                    data-id="citizenship"
                  />
                  <div
                  class="formLabel col-md-2"
                  *ngIf="
                    WinterHealthcareProviderForms.controls['292'].touched &&
                    WinterHealthcareProviderForms.controls['292'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="WinterHealthcareProviderForms.controls['292'].errors?.required"
                  >
                    required
                  </small>
                </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6" style="padding:10px;width:40%;">
                <div class="form-inline no-margin col-md-12 sideLabelAlignment">
                  <label class="formLabel"
                    >IF NOT AMERICAN CITIZEN, VISA NUMBER & STATUS
                  </label>
                  <input
                    class="form-control"
                    id="1584"
                    name="1584"
                    formControlName="1584"
                    type="text"
                    data-id="Visa #"
                  />
                  <input
                    class="form-control"
                    id="1282"
                    name="1282"
                    formControlName="1282"
                    type="text"
                    data-id="Visa Status"
                  />
                </div>
              </td>
              <td colspan="6" style="padding:10px;width:40%;">
                <div class="form-inline no-margin col-md-12 sideLabelAlignment">
                  <label class="formLabel"
                    >ARE YOU ELIGIBLE TO WORK IN THE UNITED STATES?
                  </label>
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_04"
                      name="cmd_04"
                      formControlName="cmd_04"
                      value="1"
                    />Yes</label
                  >
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_04"
                      name="cmd_04"
                      formControlName="cmd_04"
                      value="0"
                    />No</label
                  >
                </div>
              </td>
              <td colspan="6" style="padding:10px;width:40%;">
                <div class="form-group no-margin col-md-12 sideLabelAlignment">
                  <label class="formLabel">CAQH ID</label>
                  <input
                    class="form-control"
                    id="333"
                    name="333"
                    formControlName="333"
                    type="text"
                    data-id="caqh"
                  />
                </div>
                <div class="form-group no-margin col-md-12 sideLabelAlignment">
                  <label class="formLabel">USERNAME</label>
                  <input
                    class="form-control"
                    id="334"
                    name="334"
                    formControlName="334"
                    type="text"
                    data-id="caqh_username"
                  />
                </div>
                <div class="form-group no-margin col-md-12 sideLabelAlignment">
                  <label class="formLabel">PASSWORD</label>
                  <input
                    class="form-control"
                    id="335"
                    name="335"
                    formControlName="335"
                    type="text"
                    data-id="caqh_pswd"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6" style="padding:20px;width:40%;">
                <div class="form-inline no-margin col-md-12 sideLabelAlignment">
                  <label class="formLabel"
                    >U.S.MILITARY SERVICE/PUBLIC HEALTH
                  </label>
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_05"
                      name="cmd_05"
                      formControlName="cmd_05"
                      
                      value="1"
                    />Yes</label
                  >
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_05"
                      name="cmd_05"
                      formControlName="cmd_05"
                      
                      value="0"
                    />No</label
                  >
                  
                </div>
              </td>
              <td colspan="6" style="padding:20px;width:40%;">
                <div
                  class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel"
                    >DATES OF SERVICE (MM/DD/YYYY) TO (MM/DD/YYYY)
                  </label>
                </div>
                <div class="form-group no-margin col-md-6 sideLabelAlignment">
                  <div class="input-group">
                    <input
                      class="form-control onboard datepicker"
                  
                      placeholder="MM/DD/YYYY"
                      ngbDatepicker
                      #d6="ngbDatepicker"
                      id="1144"
                      name="1144"
                      formControlName="1144"
                      (blur)="clearInputIfInvalid('1144')"
                      type="text"
                      data-id="Mil Active from"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="d6.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div  *ngIf="WinterHealthcareProviderForms.get('1144').invalid ">
                    <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('1144').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('1144').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('1144').errors?.invalidDateyear">
                      year is out of range(1900-2099)                      </small>
                      <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('1144').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
                  
                </div>
                <div class="form-group no-margin col-md-6 sideLabelAlignment">
                  <div class="input-group">
                    <input
                      class="form-control onboard datepicker"
                      
                      
                      placeholder="MM/DD/YYYY"
                      ngbDatepicker
                      #d7="ngbDatepicker"
                      id="1145"
                      name="1145"
                      formControlName="1145"
                      (blur)="clearInputIfInvalid('1145')"
                      type="text"
                      data-id="Mil Active to"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="d7.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div  *ngIf="WinterHealthcareProviderForms.get('1145').invalid ">
                    <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('1145').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('1145').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('1145').errors?.invalidDateyear">
                      year is out of range(1900-2099)                      </small>
                      <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('1145').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
                  
                </div> 
              </td> 
              <td colspan="6" style="padding:20px;width:40%;">
                <div class="form-inline no-margin col-md-12 sideLabelAlignment">
                  <label class="formLabel">LAST LOCATION </label>
                  <input
                    class="form-control"
                    id="1139"
                   
                    name="1139"
                    formControlName="1139"
                    type="text"
                    data-id="Military Locaiton"
                  />
                  
                </div>
              </td>   
            </tr>
            <tr>
              <td colspan="6" style="padding:10px;">
                <div class="form-inline no-margin col-md-12 sideLabelAlignment">
                  <label class="formLabel">BRANCH OF SERVICE </label>
                  <input
                    class="form-control"
                    
                    id="1141"
                    name="1141"
                    formControlName="1141"
                    type="text"
                    data-id="Mil branch"
                  />
                 
                </div>
              </td> 
              <td colspan="12" style="padding:10px;">
                <div class="form-inline no-margin col-md-12 sideLabelAlignment">
                  <label class="formLabel"
                    >ARE YOU CURRENTLY ON ACTIVE OR RESERVE MILITARY DUTY?
                  </label>
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_06"
                      name="cmd_06"
                      formControlName="cmd_06"
                      value="1"
                    />Yes</label
                  >
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_06"
                      name="cmd_06"
                      formControlName="cmd_06"
                      value="0"
                    />No</label
                  >
                </div>
              </td>
            </tr>
          </tbody>          
        </table> 
        <table border="1" width="100%">
          <tbody>
            <tr>
              <td colspan="14">
                <div class="col-md-12">
                  <b style="font-size:18px;">Education</b>
                </div>
              </td>
              <td colspan="6">
                <div class="form-check">
                  <input formControlName="cmd_NA_02" (ngModelChange)="handleNAchange('cmd_NA_02')" class="form-check-input" type="checkbox" value="1" id="cmd_NA_02">
                     <label class="form-check-label" for="cmd_NA_02">
                              <b class="texasFloat">Does Not Apply</b>
                     </label>
                </div>
              </td>
              
            </tr>
            <tr>
              <td colspan="18">
                <div
                  class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel"
                    >PROFESSIONAL DEGREE (MEDICAL, DENTAL, CHIROPRACTIC, ETC.)
                  </label>
                </div>
                <div
                  class="form-group form-inline no-margin col-md-6 sideLabelAlignment"
                >
                  <label class="formLabel">Issuing Institution: </label>
                </div>
                <div class="form-group no-margin col-md-6 sideLabelAlignment">
                  <input
                    class="form-control" (blur)="handleNAchange('cmd_NA_02',490)"
                    [class.is-invalid]="WinterHealthcareProviderForms.controls['490'].invalid" 
                    id="490"
                    name="490"
                    formControlName="490"
                    type="text"
                    data-id="meds"
                  />
                  <div class="mandatory"
                                  *ngIf="WinterHealthcareProviderForms.controls['490'].invalid">
                                  <small >
                                    required
                                  </small>
                  </div> 
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="formLabel">ADDRESS </label>
                  <input
                    class="form-control"
                    (blur)="handleNAchange('cmd_NA_02',491)"
                    [class.is-invalid]="WinterHealthcareProviderForms.controls['491'].invalid" 
                    id="491"
                    name="491"
                    formControlName="491"
                    type="text"
                    data-id="medsadd1"
                  />
                  <div class="mandatory"
                                  *ngIf="WinterHealthcareProviderForms.controls['491'].invalid">
                                  <small >
                                    required
                                  </small>
                  </div>
                  <input
                    class="form-control"
                    id="492"
                    name="492"
                    formControlName="492"
                    type="text"
                    data-id="medsadd2"
                  />
                  
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div
                  class="form-group form-inline no-margin col-md-3 sideLabelAlignment"
                >
                  <label class="formLabel">CITY</label>
                  <input
                    class="form-control"
                    (blur)="handleNAchange('cmd_NA_02',493)"
                    [class.is-invalid]="WinterHealthcareProviderForms.controls['493'].invalid"
                    name="493"
                    formControlName="493"
                    type="text"
                    data-id="medsci"
                    />
                    
                  <div class="mandatory"
                                  *ngIf="WinterHealthcareProviderForms.controls['493'].invalid">
                                  <small >
                                    required
                                  </small>
                  </div> 
                </div>
                <div
                  class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel">STATE/COUNTRY</label>
                  <div class="form-group selectDrop dropDrownSet">
                    <div class="col-md-12">
                      <ng-select
                        [items]="states"
                        (blur)="handleNAchange('cmd_NA_02',495)"
                        [class.is-invalid]="WinterHealthcareProviderForms.controls['495'].invalid"
                        bindLabel="name"
                        placeholder="Select States"
                        bindValue="id"
                        formControlName="495"
                      >
                      </ng-select>
                    </div>
                    <div class="mandatory"
                                       *ngIf=" WinterHealthcareProviderForms.controls['495'].invalid">
                                       <small>
                                       required
                                       </small>
                    </div>
                  </div>
                </div>
                <div
                  class="form-group form-inline no-margin col-md-3 sideLabelAlignment"
                >
                  <label class="formLabel">POSTAL CODE</label>
                  <input
                    class="form-control"
                    minlength="5"
                    maxlength="10"
                    (blur)="handleNAchange('cmd_NA_02',496)"
                    [class.is-invalid]="WinterHealthcareProviderForms.controls['496'].invalid"
                    id="496"
                    name="496"
                    formControlName="496"
                    type="text"
                    data-id="medsz"
                  />
                  
                  <div class="mandatory"
                                       *ngIf=" WinterHealthcareProviderForms.controls['496'].invalid">
                                       <small>
                                        Zip code must be atleast 5 digits and less than 10 digits
                                      </small>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="8">
                <div
                  class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel">DEGREE </label>
                  <div class="col-md-10">
                    <ng-select
                      [items]="degree"
                      (blur)="handleNAchange('cmd_NA_02',500)"
                      [class.is-invalid]="WinterHealthcareProviderForms.controls['500'].invalid"
                      bindLabel="name"
                      placeholder="Select Degree"
                      bindValue="id"
                      formControlName="500"
                    >
                    </ng-select>
                    <div class="mandatory"
                                       *ngIf=" WinterHealthcareProviderForms.controls['500'].invalid">
                                       <small>
                                        required
                                      </small>
                  </div>
                  </div>
                </div>
              </td>
              <td colspan="10">
                <div class="row">
                  <div
                    class="col-md-9"
                  >
                    <label class="formLabel"
                      >ATTENDANCE DATES(MM/DD/YYYY TO MM/DD/YYYY)</label
                    >
                    
                  </div>
                
                  <div class="col-md-4">
                    <div class="input-group">
                      <input
                        class="form-control onboard datepicker"
                        (blur)="clearInputIfInvalid('497','cmd_NA_02')"
                        (blur)="handleNAchange('cmd_NA_02',497)" [class.is-invalid]="WinterHealthcareProviderForms.controls['497'].invalid"
                        placeholder="MM/DD/YYYY"
                        ngbDatepicker
                        #d8="ngbDatepicker"
                        id="497"
                        name="497"
                        formControlName="497"
                        type="text"
                        data-id="medsstart_date"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-outline-secondary calendar"
                          (click)="d8.toggle()"
                          type="button"
                        >
                          <i class="fas fa-calendar-alt"></i>
                        </button>
                      </div>
                    </div>
                    <div class="text-danger" *ngIf="WinterHealthcareProviderForms.controls['497'].invalid && (WinterHealthcareProviderForms.controls['497'].value==''|| WinterHealthcareProviderForms.controls['497'].value==null) " >
                      <small >
                      required
                      </small>
                    
                    </div>
                    <div  *ngIf="WinterHealthcareProviderForms.get('497').invalid ">
                      <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('497').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)                  </small>
                      <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('497').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('497').errors?.invalidDateyear">
                        year is out of range(1900-2099)                    </small>
                        <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('497').errors?.invalidDateRange">
                          date is out of range
                          </small>
                      </div>
                  </div>
                  <div class="col-md-4">
                    <div class="input-group">
                      <input
                        class="form-control onboard datepicker"
                        (blur)="clearInputIfInvalid('498','cmd_NA_02')"
                        (blur)="handleNAchange('cmd_NA_02',498)" [class.is-invalid]="WinterHealthcareProviderForms.controls['498'].invalid"
                        placeholder="MM/DD/YYYY"
                        ngbDatepicker
                        #d9="ngbDatepicker"
                        id="498"
                        name="498"
                        formControlName="498"
                        type="text"
                        data-id="medsend_date"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-outline-secondary calendar"
                          (click)="d9.toggle()"
                          type="button"
                        >
                          <i class="fas fa-calendar-alt"></i>
                        </button>
                      </div>
                    </div>
                    <div class="text-danger" *ngIf="WinterHealthcareProviderForms.controls['498'].invalid && (WinterHealthcareProviderForms.controls['498'].value==''|| WinterHealthcareProviderForms.controls['498'].value==null) " >
                      <small >
                      required
                      </small>
                    
                    </div>
                    <div  *ngIf="WinterHealthcareProviderForms.get('498').invalid ">
                      <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('498').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)                  </small>
                      <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('498').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('498').errors?.invalidDateyear">
                        year is out of range(1900-2099)                    </small>
                        <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('498').errors?.invalidDateRange">
                          date is out of range
                          </small>
                      </div>
                  </div>
                </div>  
              </td>
            </tr>
            <tr>
              <td colspan="18" style="padding:10px;">
                <div class="form-check">
                  <input formControlName="cmd_001"  class="form-check-input" type="checkbox" value="1" id="cmd_001">
                     <label class="form-check-label" for="cmd_001">
                              <i class="formLabel">Please check this box and complete and submit Attachment A if you received other professional degrees.</i>
                     </label>
                         
                </div>  
              </td>
            </tr>
            <tr>
              <td colspan="6">
                <div
                  class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="checkbox-inline">POST-GRADUATE EDUCATION </label>
                </div>
                <input
                  type="radio"
                  id="cmd_08"
                  name="cmd_08"
                  (blur)="handleNAchange('cmd_NA_011','cmd_08')"
                  formControlName="cmd_08"
                  [class.is-invalid]="(WinterHealthcareProviderForms.controls['cmd_08'].touched && WinterHealthcareProviderForms.controls['cmd_08'].invalid) || (WinterHealthcareProviderForms.controls['cmd_08'].invalid && isSubmit)"
                  value="0"
                />Internship
                <input
                  type="radio"
                  id="cmd_08"
                  (blur)="handleNAchange('cmd_NA_011','cmd_08')"
                  name="cmd_08"
                  [class.is-invalid]="(WinterHealthcareProviderForms.controls['cmd_08'].touched && WinterHealthcareProviderForms.controls['cmd_08'].invalid) || (WinterHealthcareProviderForms.controls['cmd_08'].invalid && isSubmit)"
                  formControlName="cmd_08"
                  value="1"
                />Residency
                <input
                  type="radio"
                  id="cmd_08"
                  name="cmd_08"
                  (blur)="handleNAchange('cmd_NA_011','cmd_08')"
                  [class.is-invalid]="(WinterHealthcareProviderForms.controls['cmd_08'].touched && WinterHealthcareProviderForms.controls['cmd_08'].invalid) || (WinterHealthcareProviderForms.controls['cmd_08'].invalid && isSubmit)"
                  formControlName="cmd_08"
                  value="2"
                />Fellowship
                <input
                  type="radio"
                  id="cmd_08"
                  (blur)="handleNAchange('cmd_NA_011','cmd_08')"
                  name="cmd_08"
                  [class.is-invalid]="(WinterHealthcareProviderForms.controls['cmd_08'].touched && WinterHealthcareProviderForms.controls['cmd_08'].invalid) || (WinterHealthcareProviderForms.controls['cmd_08'].invalid && isSubmit)"
                  formControlName="cmd_08"
                  value="3"
                />Teaching Appointment
                <div class="mandatory"
                                  *ngIf="WinterHealthcareProviderForms.controls['cmd_08'].invalid">
                                  <small >
                                    required
                                  </small>
                    </div>
              </td>
              <td colspan="6">
                  <div
                    class="col-md-12"
                  >
                    <label class="checkbox-inline radioStyle">SPECIALTY</label>
                  </div>
                  <div class="col-md-12">
                    <ng-select
                      [items]="specialty"
                      (blur)="handleNAchange('cmd_NA_011',527)" [class.is-invalid]="WinterHealthcareProviderForms.controls['527'].invalid"
                      bindLabel="name"
                      placeholder="Select Specialty"
                      bindValue="id"
                      formControlName="527"
                    >
                    </ng-select>
                    <div class="mandatory"
                                  *ngIf="WinterHealthcareProviderForms.controls['527'].invalid">
                                  <small >
                                    required
                                  </small>
                    </div>
                  </div>
              </td>  
              <td colspan="6" style="padding-left:80px;">
                <div class="form-check">
                  <input formControlName="cmd_NA_011" (ngModelChange)="handleNAchange('cmd_NA_011')" class="form-check-input" type="checkbox" value="1" id="cmd_NA_011">
                     <label class="form-check-label" for="cmd_NA_011">
                              <b class="texasFloat">Does Not Apply</b>
                     </label>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div
                  class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel">INSTITUTION</label>
                  <input
                    class="form-control"
                    id="517"
                    name="517"
                    (blur)="handleNAchange('cmd_NA_011',517)" [class.is-invalid]="WinterHealthcareProviderForms.controls['517'].invalid"
                    formControlName="517"
                    type="text"
                    data-id="interns"
                  />
                  <div class="mandatory"
                                  *ngIf="WinterHealthcareProviderForms.controls['517'].invalid">
                                  <small >
                                    required
                                  </small>
                  </div> 
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div
                  class="row"
                >
                  <div class="col-md-2">
                      <label class="formLabel">ADDRESS</label>
                  </div>
                  <div class="col-md-3">
                      <input
                        class="form-control"
                        id="518"
                        name="518"
                        placeholder="Address"
                        formControlName="518"
                        type="text"
                        (blur)="handleNAchange('cmd_NA_011',518)" [class.is-invalid]="WinterHealthcareProviderForms.controls['518'].invalid"
                        data-id="internadd1"
                      />
                      <div class="mandatory"
                                      *ngIf="WinterHealthcareProviderForms.controls['518'].invalid">
                                      <small >
                                        required
                                      </small>
                      </div>
                  </div> 
                  <div class="col-md-3">  
                    <input
                      class="form-control"
                      id="519"
                      name="519"
                      placeholder="Address"
                      formControlName="519"
                      type="text"
                      data-id="internadd2"
                    />
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div
                  class="form-group form-inline no-margin col-md-6 sideLabelAlignment"
                >
                  <label class="formLabel">CITY</label>
                  <input
                    style="width: 40%"
                    class="form-control"
                    id="520"
                    name="520"
                    (blur)="handleNAchange('cmd_NA_011',520)" [class.is-invalid]="WinterHealthcareProviderForms.controls['520'].invalid"
                    formControlName="520"
                    type="text"
                    data-id="internci"
                  />
                  <div class="mandatory"
                                  *ngIf="WinterHealthcareProviderForms.controls['520'].invalid">
                                  <small >
                                    required
                                  </small>
                  </div>
                </div>
                <div
                  class="form-group form-inline no-margin col-md-6 sideLabelAlignment"
                >
                  <label class="formLabel">STATE/COUNTRY</label>
                  <div class="form-group selectDrop dropDrownSet">
                    <div class="col-md-12">
                      <ng-select
                        [items]="states"
                        bindLabel="name"
                        (blur)="handleNAchange('cmd_NA_011',522)" [class.is-invalid]="WinterHealthcareProviderForms.controls['522'].invalid"
                        placeholder="Select States"
                        bindValue="id"
                        formControlName="522"
                      >
                      </ng-select>
                      <div class="mandatory"
                                  *ngIf="WinterHealthcareProviderForms.controls['522'].invalid">
                                  <small >
                                    required
                                  </small>
                  </div>
                    </div>
                  </div>
                </div>
                <div
                  class="form-group form-inline no-margin col-md-6 sideLabelAlignment"
                >
                  <label class="formLabel">POSTAL CODE</label>
                  <input
                    style="width: 40%"
                    class="form-control"
                    id="523"
                    name="523"
                    (blur)="handleNAchange('cmd_NA_011',523)" [class.is-invalid]="WinterHealthcareProviderForms.controls['523'].invalid"
                    formControlName="523"
                    type="text"
                    data-id="internz"
                  />
                  <div class="mandatory"
                                  *ngIf="WinterHealthcareProviderForms.controls['523'].invalid">
                                  <small >
                                    required
                                  </small>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6">
                <div
                  class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
                ></div>
                <input
                  type="checkbox"
                  id="cmd_09"
                  name="cmd_09"
                  formControlName="cmd_09"
                  value="1"
                />Program successfully completed
              </td>
              <td colspan="12">
                <div
                  class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel"
                    >ATTENDANCE DATES (MM/DD/YYYY TO MM/DD/YYYY)
                  </label>
                  <div class="input-group">
                    <input
                      class="form-control onboard datepicker"
                      placeholder="MM/DD/YYYY"
                      ngbDatepicker
                      #d10="ngbDatepicker"
                      id="524"
                      name="524"
                      formControlName="524"
                      (blur)="clearInputIfInvalid('524')"
                      type="text"
                      data-id="internstart_date"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="d10.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div  *ngIf="WinterHealthcareProviderForms.get('524').invalid ">
                    <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('524').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('524').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('524').errors?.invalidDateyear">
                      year is out of range(1900-2099)                      </small>
                      <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('524').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
                  <div class="input-group">
                    <input
                      class="form-control onboard datepicker"
                      placeholder="MM/DD/YYYY"
                      ngbDatepicker
                      #d11="ngbDatepicker"
                      id="525"
                      name="525"
                      formControlName="525"
                      (blur)="clearInputIfInvalid('525')"
                      type="text"
                      data-id="internend_date"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="d11.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div  *ngIf="WinterHealthcareProviderForms.get('525').invalid ">
                    <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('525').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('525').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('525').errors?.invalidDateyear">
                      year is out of range(1900-2099)                      </small>
                      <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('525').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="9">
                <div
                  class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel">PROGRAM DIRECTOR </label>
                  <input
                    style="width:40%;"
                    class="form-control"
                    id="528"
                    name="528"
                    formControlName="528"
                    type="text"
                    data-id="interndir"
                  />
                </div>
              </td>
              <td colspan="9">
                <div
                  class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel"
                    >CURRENT PROGRAM DIRECTOR (IF KNOWN)
                  </label>
                  <input
                    style="width:40%;"
                    class="form-control"
                    id="818"
                    name="818"
                    formControlName="818"
                    type="text"
                    data-id="interndir2"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6">
                <div
                  class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="checkbox-inline radioStyle"
                    >POST-GRADUATE EDUCATION
                  </label>
                </div>
                <input
                  type="radio"
                  id="cmd_010"
                  name="cmd_010"
                  formControlName="cmd_010"
                  value="0"
                />Internship
                <input
                  type="radio"
                  id="cmd_010"
                  name="cmd_010"
                  formControlName="cmd_010"
                  value="1"
                />Residency
                <input
                  type="radio"
                  id="cmd_010"
                  name="cmd_010"
                  formControlName="cmd_010"
                  value="2"
                />Fellowship
                <input
                  type="radio"
                  id="cmd_010"
                  name="cmd_010"
                  formControlName="cmd_010"
                  value="3"
                />Teaching Appointment
              </td>
              <td colspan="12">
                <div
                  class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="checkbox-inline radioStyle">SPECIALTY</label>
                </div>
                <div class="col-md-8">
                  <ng-select
                    [items]="specialty"
                    bindLabel="name"
                    placeholder="Select Specialty"
                    bindValue="id"
                    formControlName="530"
                  >
                  </ng-select>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div
                  class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="checkbox-inline radioStyle">INSTITUTION</label>
                  <input
                    class="form-control"
                    id="529"
                    name="529"
                    formControlName="529"
                    type="text"
                    data-id="res"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div
                  class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="checkbox-inline radioStyle">ADDRESS</label>
                  <input
                    class="form-control"
                    id="531"
                    name="531"
                    formControlName="531"
                    type="text"
                    data-id="resadd1"
                  />
                  <input
                    class="form-control"
                    id="1498"
                    name="1498"
                    formControlName="1498"
                    type="text"
                    data-id="resadd2"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div
                  class="form-group form-inline no-margin col-md-6 sideLabelAlignment"
                >
                  <label class="formLabel">CITY</label>
                  <input
                    style="width: 40%"
                    class="form-control"
                    id="533"
                    name="533"
                    formControlName="533"
                    type="text"
                    data-id="resci"
                  />
                </div>
                <div
                  class="form-group form-inline no-margin col-md-6 sideLabelAlignment"
                >
                  <label class="formLabel">STATE/COUNTRY</label>
                  <div class="form-group selectDrop dropDrownSet">
                    <div class="col-md-12">
                      <ng-select
                        [items]="states"
                        bindLabel="name"
                        placeholder="Select States"
                        bindValue="id"
                        formControlName="535"
                      >
                      </ng-select>
                    </div>
                  </div>
                </div>
                <div
                  class="form-group form-inline no-margin col-md-6 sideLabelAlignment"
                >
                  <label class="formLabel">POSTAL CODE</label>
                  <input
                    style="width: 40%"
                    class="form-control"
                    id="536"
                    name="536"
                    formControlName="536"
                    type="text"
                    data-id="resz"
                  />
                </div>
              </td>
            </tr>
          </tbody>  
        </table>   
        <hr class="tablePersonal" />
        <table border="1" width="100%">
          <td colspan="14">
            <div class="centered texasFirstTable">
              <b style="font-size:18px;">Education -continued</b>
            </div>
          </td>
          <tr>
            <td colspan="6">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="checkbox-inline radioStyle"
                  >POST-GRADUATE EDUCATION
                </label>
              </div>
              <input
                type="checkbox"
                id="cmd_011"
                name="cmd_011"
                formControlName="cmd_011"
                value="1"
              />Program successfully completed
            </td>
            <td colspan="12">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="checkbox-inline radioStyle"
                  >ATTENDANCE DATES (MM/DD/YYYY TO MM/DD/YYYY)
                </label>
              </div>
              <div class="input-group col-md-3">
                <input
                  style="width:25%;"
                  class="form-control onboard datepicker"
                  placeholder="MM/DD/YYYY"
                  ngbDatepicker
                  #d12="ngbDatepicker"
                  id="537"
                  name="537"
                  formControlName="537"
                  (blur)="clearInputIfInvalid('537')"
                  type="text"
                  data-id="resstart_date"
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-outline-secondary calendar"
                    (click)="d12.toggle()"
                    type="button"
                  >
                    <i class="fas fa-calendar-alt"></i>
                  </button>
                </div>
              </div>
              <div  *ngIf="WinterHealthcareProviderForms.get('537').invalid ">
                <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('537').errors?.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY)
                </small>
                <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('537').errors?.invalidMonth">
                Month is out of range
                </small>
                <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('537').errors?.invalidDateyear">
                  year is out of range(1900-2099)                      </small>
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('537').errors?.invalidDateRange">
                    date is out of range
                    </small>
                </div>
              <div class="input-group col-md-3">
                <input
                  style="width:25%;"
                  class="form-control onboard datepicker"
                  placeholder="MM/DD/YYYY"
                  ngbDatepicker
                  #d13="ngbDatepicker"
                  id="538"
                  name="538"
                  formControlName="538"
                  (blur)="clearInputIfInvalid('538')"
                  type="text"
                  data-id="resend_date"
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-outline-secondary calendar"
                    (click)="d13.toggle()"
                    type="button"
                  >
                    <i class="fas fa-calendar-alt"></i>
                  </button>
                </div>
              </div>
              <div  *ngIf="WinterHealthcareProviderForms.get('538').invalid ">
                <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('538').errors?.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY)
                </small>
                <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('538').errors?.invalidMonth">
                Month is out of range
                </small>
                <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('538').errors?.invalidDateyear">
                  year is out of range(1900-2099)                      </small>
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('538').errors?.invalidDateRange">
                    date is out of range
                    </small>
                </div>
            </td>
          </tr>
          <tr>
            <td colspan="9">
              <div class="form-group form-inline no-margin sideLabelAlignment">
                <label class="formLabel">PROGRAM DIRECTOR </label>
                <input
                  style="width:40%;"
                  class="form-control"
                  id="540"
                  name="540"
                  formControlName="540"
                  type="text"
                  data-id="resdir1"
                />
              </div>
            </td>
            <td colspan="9">
              <div class="form-group form-inline no-margin sideLabelAlignment">
                <label class="formLabel"
                  >CURRENT PROGRAM DIRECTOR (IF KNOWN)</label
                >
                <input
                  style="width:40%;"
                  class="form-control"
                  id="541"
                  name="541"
                  formControlName="541"
                  type="text"
                  data-id="resdir2"
                />
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="18" style="padding:10px;">
              <div class="form-check">
                <input formControlName="cmd_002"  class="form-check-input" type="checkbox" value="1" id="cmd_002">
                   <label class="form-check-label" for="cmd_002">
                            <i class="formLabel">Please check this box and complete and submit Attachment B if you received additional postgraduate training. </i>
                   </label>
                       
            </div>  
            </td>
          </tr>
          <tr>
            <td colspan="12">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="formLabel">OTHER GRADUATE-LEVEL EDUCATION</label>
              </div>
              <div
                class="form-group form-inline no-margin col-md-6 sideLabelAlignment"
              >
                <label class="formLabel">Issuing Institution: </label>
              </div>
              <div class="form-group no-margin col-md-6 sideLabelAlignment">
                <input
                  class="form-control"
                  id="479"
                  name="479"
                  formControlName="479"
                  type="text"
                  (blur)="handleNAchange('cmd_NA_012',479)" [class.is-invalid]="WinterHealthcareProviderForms.controls['479'].invalid"
                  data-id="grad_school1"
                />
                <div class="mandatory"
                                  *ngIf="WinterHealthcareProviderForms.controls['479'].invalid">
                                  <small >
                                    required
                                  </small>
                              </div> 
              </div>
            </td>
            <td colspan="6">
              <div class="form-check">
                <input formControlName="cmd_NA_012" (ngModelChange)="handleNAchange('cmd_NA_012')" class="form-check-input" type="checkbox" value="1" id="cmd_NA_012">
                   <label class="form-check-label" for="cmd_NA_012">
                            <b class="texasFloat">Does Not Apply</b>
                   </label>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="18">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="checkbox-inline radioStyle">ADDRESS</label>
                <input
                  class="form-control"
                  id="483"
                  name="483"
                  formControlName="483"
                  type="text"
                  (blur)="handleNAchange('cmd_NA_012',483)" [class.is-invalid]="WinterHealthcareProviderForms.controls['483'].invalid"
                  data-id="Training Pro 1 address1"
                />
                <div class="mandatory"
                                  *ngIf="WinterHealthcareProviderForms.controls['483'].invalid">
                                  <small >
                                    required
                                  </small>
                              </div>
                <input
                  class="form-control"
                  id="484"
                  name="484"
                  formControlName="484"
                  type="text"
                  
                  data-id="Training Pro 1 address2"
                />
                
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="18">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="formLabel">CITY</label>
                <input
                  style="width: 40%"
                  class="form-control"
                  id="485"
                  name="485"
                  formControlName="485"
                  type="text"
                  (blur)="handleNAchange('cmd_NA_012',485)" [class.is-invalid]="WinterHealthcareProviderForms.controls['485'].invalid"
                  data-id="Training Pro 1 city"
                />
                <div class="mandatory"
                                  *ngIf="WinterHealthcareProviderForms.controls['485'].invalid">
                                  <small >
                                    required
                                  </small>
                              </div>
              </div>
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="formLabel">STATE/COUNTRY</label>
                <div class="form-group selectDrop dropDrownSet col-md-12">
                  <div class="col-md-12">
                    <ng-select
                      [items]="states"
                      bindLabel="name"
                      placeholder="Select States"
                      bindValue="id"
                      (blur)="handleNAchange('cmd_NA_012',487)" [class.is-invalid]="WinterHealthcareProviderForms.controls['487'].invalid"
                      formControlName="487"
                    >
                    </ng-select>
                    <div class="mandatory"
                                  *ngIf="WinterHealthcareProviderForms.controls['487'].invalid">
                                  <small >
                                    required
                                  </small>
                              </div>
                  </div>
                </div>
              </div>
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="formLabel">POSTAL CODE</label>
                <input
                  style="width: 40%"
                  class="form-control"
                  id="488"
                  name="488"
                  (blur)="handleNAchange('cmd_NA_012',488)" [class.is-invalid]="WinterHealthcareProviderForms.controls['488'].invalid"
                  formControlName="488"
                  type="text"
                  data-id="Training Pro 1 zip"
                />
                <div class="mandatory"
                                  *ngIf="WinterHealthcareProviderForms.controls['488'].invalid">
                                  <small >
                                    required
                                  </small>
                              </div> 
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="8">
              <div class="row">
                <div class="col-md-3">
                  <label class="formLabel">DEGREE</label>
                </div>
                <div class="col-md-9">
                  <ng-select
                    [items]="degree"
                    bindLabel="name"
                    placeholder="Select Degree"
                    bindValue="id"
                    (blur)="handleNAchange('cmd_NA_012',480)" [class.is-invalid]="WinterHealthcareProviderForms.controls['480'].invalid"
                    formControlName="480"
                  >
                  </ng-select>
                    <div class="mandatory"
                                  *ngIf="WinterHealthcareProviderForms.controls['480'].invalid">
                                  <small >
                                    required
                                  </small>
                    </div> 
                </div>
              </div>
            </td>
            <td colspan="9">
              <div class="form-group form-inline no-margin sideLabelAlignment">
                <label class="formLabel"
                  >ATTENDANCE DATES (MM/DD/YYYY TO MM/DD/YYYY)
                </label>
              </div>
              <div class="input-group col-md-4">
                <input
                  class="form-control onboard datepicker"
                  placeholder="MM/DD/YYYY"
                  ngbDatepicker
                  #d14="ngbDatepicker"
                  id="481"
                  name="481"
                  formControlName="481"
                  (blur)="clearInputIfInvalid('481')"
                  data-id="Training Pro 1 from"
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-outline-secondary calendar"
                    (click)="d14.toggle()"
                    type="button"
                  >
                    <i class="fas fa-calendar-alt"></i>
                  </button>
                </div>
              </div>
              <div  *ngIf="WinterHealthcareProviderForms.get('481').invalid ">
                <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('481').errors?.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY)
                </small>
                <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('481').errors?.invalidMonth">
                Month is out of range
                </small>
                <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('481').errors?.invalidDateyear">
                  year is out of range(1900-2099)                      </small>
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('481').errors?.invalidDateRange">
                    date is out of range
                    </small>
                </div>
              <div class="input-group col-md-4">
                <input
                  class="form-control onboard datepicker"
                  placeholder="MM/DD/YYYY"
                  ngbDatepicker
                  #d15="ngbDatepicker"
                  id="1300"
                  name="1300"
                  formControlName="1300"
                  (blur)="clearInputIfInvalid('1300')"
                  type="text"
                  data-id="Training Pro 1 to"
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-outline-secondary calendar"
                    (click)="d15.toggle()"
                    type="button"
                  >
                    <i class="fas fa-calendar-alt"></i>
                  </button>
                </div>
              </div>
              <div  *ngIf="WinterHealthcareProviderForms.get('1300').invalid ">
                <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('1300').errors?.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY)
                </small>
                <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('1300').errors?.invalidMonth">
                Month is out of range
                </small>
                <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('1300').errors?.invalidDateyear">
                  year is out of range(1900-2099)                      </small>
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('1300').errors?.invalidDateRange">
                    date is out of range
                    </small>
                </div>
            </td>
          </tr>
          <tr>
            <td colspan="18">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                 <b class="texasFloat">
                  Licenses and Certificates</b>
                <label class="formLabel">
                - Please include all license(s) and certifications in all States
                where you are currently or have previously been licensed.</label>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="6">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="formLabel">LICENSE TYPE </label>
                <input
                  style="width:70%;"
                  class="form-control"
                  id="296"
                  name="296"
                  formControlName="296"
                  type="text"
                  (blur)="handleNAchange('cmd_NA_03',296)" [class.is-invalid]="WinterHealthcareProviderForms.controls['296'].invalid"
                  data-id="licensetype"
                />
                <div class="mandatory"
                    *ngIf="WinterHealthcareProviderForms.controls['296'].invalid">
                    <small >
                      required
                    </small>
                </div>
              </div>
            </td>
            <td colspan="6">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="formLabel">LICENSE NUMBER </label>
                <input
                  style="width:70%;"
                  (blur)="handleNAchange('cmd_NA_03',293)" [class.is-invalid]="WinterHealthcareProviderForms.controls['293'].invalid"
                  class="form-control"
                  id="293"
                  name="293"
                  formControlName="293"
                  type="text"
                  data-id="license"
                />
                <div class="mandatory"
                                  *ngIf="WinterHealthcareProviderForms.controls['293'].invalid">
                                  <small >
                                    required
                                  </small>
                </div>
              </div>
            </td>
            <td colspan="6">
              <div
                class="form-group form-inline no-margin col-md-12"
                style="padding: 2px;"
              >
                <label class="formLabel">STATE OF REGISTRATION </label>
                <div class="form-group selectDrop dropDrownSet">
                  <div class="col-md-12">
                    <ng-select
                      [items]="states"
                      (blur)="handleNAchange('cmd_NA_03',299)" [class.is-invalid]="WinterHealthcareProviderForms.controls['299'].invalid"
                      bindLabel="name"
                      placeholder="Select States"
                      bindValue="id"
                      formControlName="299"
                    >
                    </ng-select>
                  </div>
                  <div class="mandatory"
                  *ngIf="WinterHealthcareProviderForms.controls['299'].invalid">
                  <small >
                    required
                  </small>
              </div>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="6">
              <div class="form-group form-inline no-margin sideLabelAlignment">
                <label class="formLabel"
                  >ORIGINAL DATE OF ISSUE (MM/DD/YYYY)
                </label>
                <div class="input-group">
                  <input
                    style="width:50%;"
                    (blur)="clearInputIfInvalid('294','cmd_NA_03')"
                    (blur)="handleNAchange('cmd_NA_03',294)" [class.is-invalid]="WinterHealthcareProviderForms.controls['294'].invalid"
                    class="form-control onboard datepicker"
                    placeholder="MM/DD/YYYY"
                    ngbDatepicker
                    #d16="ngbDatepicker"
                    id="294"
                    name="294"
                    formControlName="294"
                    type="text"
                    data-id="licenseissuedate"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="d16.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
                <div class="text-danger" *ngIf="WinterHealthcareProviderForms.controls['294'].invalid && (WinterHealthcareProviderForms.controls['294'].value==''|| WinterHealthcareProviderForms.controls['294'].value==null) " >
                  <small >
                  required
                  </small>
                
                </div>
                <div  *ngIf="WinterHealthcareProviderForms.get('294').invalid ">
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('294').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)                  </small>
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('294').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('294').errors?.invalidDateyear">
                    year is out of range(1900-2099)                    </small>
                    <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('294').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </div>
            </td>
            <td colspan="6">
              <div class="form-group form-inline no-margin sideLabelAlignment">
                <label class="formLabel">EXPIRATION DATE (MM/DD/YYYY) </label>
                <div class="input-group">
                  <input
                    style="width:50%;"
                    (blur)="clearInputIfInvalid('295','cmd_NA_03')"
                    (blur)="handleNAchange('cmd_NA_03',295)" [class.is-invalid]="WinterHealthcareProviderForms.controls['295'].invalid"
                    class="form-control onboard datepicker"
                    placeholder="MM/DD/YYYY"
                    ngbDatepicker
                    #d17="ngbDatepicker"
                    id="295"
                    name="295"
                    formControlName="295"
                    type="text"
                    data-id="licenseexp_date"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="d17.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
                <div class="text-danger" *ngIf="WinterHealthcareProviderForms.controls['295'].invalid && (WinterHealthcareProviderForms.controls['295'].value==''|| WinterHealthcareProviderForms.controls['295'].value==null) " >
                  <small >
                  required
                  </small>
                
                </div>
                <div  *ngIf="WinterHealthcareProviderForms.get('295').invalid ">
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('295').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)                  </small>
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('295').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('295').errors?.invalidDateyear">
                    year is out of range(1900-2099)                    </small>
                    <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('295').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </div>
            </td>
            <td colspan="6">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                DO YOU CURRENTLY PRACTICE IN THIS STATE?
              </div>
              <div class="form-group no-margin col-md-10 sideLabelAlignment">
                <label class="radio-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_013"
                    name="cmd_013"
                    (blur)="handleNAchange('cmd_NA_03','cmd_013')" [class.is-invalid]="WinterHealthcareProviderForms.controls['cmd_013'].invalid"
                    formControlName="cmd_013"
                    value="1"
                  />Yes</label
                >
                <label class="radio-inline radioStyle">
                  <input
                    type="radio"
                    (blur)="handleNAchange('cmd_NA_03','cmd_013')" [class.is-invalid]="WinterHealthcareProviderForms.controls['cmd_013'].invalid"
                    id="cmd_013"
                    name="cmd_013"
                    formControlName="cmd_013"
                    value="0"
                  />No</label
                >
                <div class="mandatory"
                  *ngIf="WinterHealthcareProviderForms.controls['cmd_013'].invalid">
                    <small >
                      required
                    </small>
                </div>
              </div>
              <!-- <div class="form-group no-margin col-md-8 sideLabelAlignment">
                <input
                  type="checkbox"
                  name="cmd_NA_03"
                  id="cmd_NA_03"
                  formControlName="cmd_NA_03" 
                  (ngModelChange)="handleNAchange('cmd_NA_03')"
                  value="1"
                  class="texasFloat"
                />
                I have not obtained Licensure yet
              </div> -->
            </td>
          </tr>
          <tr>
            <td colspan="6">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="formLabel">LICENSE TYPE </label>
                <input
                  style="width: 40%"
                  class="form-control"
                  id="1274"
                  name="1274"
                  formControlName="1274"
                  type="text"
                  data-id="Alt License type"
                />
              </div>
            </td>
            <td colspan="6">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="formLabel">LICENSE NUMBER </label>
                <input
                  style="width: 60%"
                  class="form-control"
                  id="300"
                  name="300"
                  formControlName="300"
                  type="text"
                  data-id="alt license"
                />
              </div>
            </td>
            <td colspan="6">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="formLabel">STATE OF REGISTRATION </label>
                <div class="form-group selectDrop dropDrownSet">
                  <div class="col-md-12">
                    <ng-select
                      [items]="altLicenseState"
                      bindLabel="name"
                      placeholder="Select States"
                      bindValue="id"
                      formControlName="907"
                    >
                    </ng-select>
                  </div>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="6">
              <div class="form-group form-inline no-margin sideLabelAlignment">
                <label class="formLabel"
                  >ORIGINAL DATE OF ISSUE (MM/DD/YYYY)
                </label>
                <div class="input-group">
                  <input
                    class="form-control onboard datepicker"
                    placeholder="MM/DD/YYYY"
                    ngbDatepicker
                    #d18="ngbDatepicker"
                    id="307"
                    name="307"
                    formControlName="307"
                    (blur)="clearInputIfInvalid('307')"
                    type="text"
                    data-id="alt licesne issue"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="d18.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
                <div  *ngIf="WinterHealthcareProviderForms.get('307').invalid ">
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('307').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('307').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('307').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('307').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </div>
            </td>
            <td colspan="6">
              <div class="form-group form-inline no-margin sideLabelAlignment">
                <label class="formLabel">EXPIRATION DATE (MM/DD/YYYY) </label>
                <div class="input-group">
                  <input
                    style="width: 48%"
                    class="form-control onboard datepicker"
                    placeholder="MM/DD/YYYY"
                    ngbDatepicker
                    #d19="ngbDatepicker"
                    id="308"
                    name="308"
                    formControlName="308"
                    (blur)="clearInputIfInvalid('308')"
                    type="text"
                    data-id="alt licesne exp"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="d19.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
                <div  *ngIf="WinterHealthcareProviderForms.get('308').invalid ">
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('308').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('308').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('308').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('308').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </div>
            </td>
            <td colspan="6">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                DO YOU CURRENTLY PRACTICE IN THIS STATE?
              </div>
              <div class="form-group no-margin col-md-12 sideLabelAlignment">
                <label class="radio-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_014"
                    name="cmd_014"
                    formControlName="cmd_014"
                    value="1"
                  />Yes</label
                >
                <label class="radio-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_014"
                    name="cmd_014"
                    formControlName="cmd_014"
                    value="0"
                  />No</label
                >
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="6">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="formLabel">LICENSE TYPE </label>
                <input
                  style="width: 40%"
                  class="form-control"
                  id="1432"
                  name="1432"
                  formControlName="1432"
                  type="text"
                  data-id="add_license_type"
                />
              </div>
            </td>
            <td colspan="6">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="formLabel">LICENSE NUMBER </label>
                <input
                  style="width: 60%"
                  class="form-control"
                  id="999"
                  name="999"
                  formControlName="999"
                  type="text"
                  data-id="add_license"
                />
              </div>
            </td>
            <td colspan="6">
              <div
                class="form-group form-inline no-margin col-md-12 marginStyle"
              >
                <label class="formLabel">STATE OF REGISTRATION </label>
                <div class="form-group selectDrop dropDrownSet">
                  <div class="col-md-12">
                    <ng-select
                      [items]="additionalLicenseState"
                      bindLabel="name"
                      placeholder="Select States"
                      bindValue="id"
                      formControlName="1010"
                    >
                    </ng-select>
                  </div>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="6">
              <div class="form-group form-inline no-margin sideLabelAlignment">
                <label class="formLabel"
                  >ORIGINAL DATE OF ISSUE (MM/DD/YYYY)
                </label>
                <div class="input-group">
                  <input
                    class="form-control onboard datepicker"
                    placeholder="MM/DD/YYYY"
                    ngbDatepicker
                    #d20="ngbDatepicker"
                    id="1003"
                    name="1003"
                    formControlName="1003"
                    (blur)="clearInputIfInvalid('1003')"
                    type="text"
                    data-id="add license issue"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="d20.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
                <div  *ngIf="WinterHealthcareProviderForms.get('1003').invalid ">
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('1003').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('1003').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('1003').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('1003').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </div>
            </td>
            <td colspan="6">
              <div class="form-group form-inline no-margin sideLabelAlignment">
                <label class="formLabel">EXPIRATION DATE (MM/DD/YYYY) </label>
                <div class="input-group">
                  <input
                    style="width: 48%"
                    class="form-control onboard datepicker"
                    placeholder="MM/DD/YYYY"
                    ngbDatepicker
                    #d21="ngbDatepicker"
                    id="1004"
                    name="1004"
                    formControlName="1004"
                    (blur)="clearInputIfInvalid('1004')"
                    type="text"
                    data-id="add license exp"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="d21.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
                <div  *ngIf="WinterHealthcareProviderForms.get('1004').invalid ">
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('1004').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('1004').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('1004').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('1004').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </div>
            </td>
            <td colspan="6">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                DO YOU CURRENTLY PRACTICE IN THIS STATE?
              </div>
              <div class="form-group no-margin col-md-12 sideLabelAlignment">
                <label class="radio-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_015"
                    name="cmd_015"
                    formControlName="cmd_015"
                    value="1"
                  />Yes</label
                >
                <label class="radio-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_015"
                    name="cmd_015"
                    formControlName="cmd_015"
                    value="0"
                  />No</label
                >
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="6">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              ></div>
              <div class="form-group no-margin col-md-12 sideLabelAlignment">
                <label class="radio-inline radioStyle"></label>
                <input
                  type="radio"
                  id="cmd_016"
                  name="cmd_016"
                  formControlName="cmd_016"
                  value="1"
                />DEA Number:
                <input
                  style="width:70%;"
                  class="form-control"
                  id="309"
                  name="309"
                  formControlName="309"
                  type="text"
                  data-id="dea"
                />
              </div>
            </td>
            <td colspan="6">
              <div class="form-group form-inline no-margin sideLabelAlignment">
                <label class="formLabel"
                  >ORIGINAL DATE OF ISSUE (MM/DD/YYYY)
                </label>
                <div class="input-group">
                  <input
                    class="form-control onboard datepicker"
                    placeholder="MM/DD/YYYY"
                    ngbDatepicker
                    #d22="ngbDatepicker"
                    id="310"
                    name="310"
                    formControlName="310"
                    (blur)="clearInputIfInvalid('310')"
                    type="text"
                    data-id="deaissue"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="d22.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
                <div  *ngIf="WinterHealthcareProviderForms.get('310').invalid ">
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('310').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('310').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('310').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('310').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </div>
            </td>
            <td colspan="6">
              <div class="form-group form-inline no-margin sideLabelAlignment">
                <label class="formLabel">EXPIRATION DATE (MM/DD/YYYY) </label>
                <div class="input-group">
                  <input
                    style="width:52%;"
                    class="form-control onboard datepicker"
                    ngbDatepicker
                    #d23="ngbDatepicker"
                    placeholder="MM/DD/YYYY"
                    id="311"
                    name="311"
                    formControlName="311"
                    (blur)="clearInputIfInvalid('311')"
                    type="text"
                    data-id="dea_exp_date"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="d23.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
                <div  *ngIf="WinterHealthcareProviderForms.get('311').invalid ">
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('311').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('311').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('311').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('311').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="6">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              ></div>
              <div class="form-group no-margin col-md-12 sideLabelAlignment">
                <label class="radio-inline radioStyle"></label>
                <input
                  type="radio"
                  id="cmd_017"
                  name="cmd_017"
                  formControlName="cmd_017"
                  value="1"
                />
                CDS Number:
                <input
                  style="width:70%;"
                  class="form-control"
                  id="337"
                  name="337"
                  formControlName="337"
                  type="text"
                  data-id="driverslic"
                />
              </div>
            </td>
            <td colspan="6">
              <div class="form-group form-inline no-margin sideLabelAlignment">
                <label class="formLabel"
                  >ORIGINAL DATE OF ISSUE (MM/DD/YYYY)
                </label>
                <div class="input-group">
                  <input
                    style="width:48%;"
                    class="form-control onboard datepicker"
                    placeholder="MM/DD/YYYY"
                    ngbDatepicker
                    #d24="ngbDatepicker"
                    id="338"
                    name="338"
                    formControlName="338"
                    (blur)="clearInputIfInvalid('338')"
                    type="text"
                    data-id="drivers issue"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="d24.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
                <div  *ngIf="WinterHealthcareProviderForms.get('338').invalid ">
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('338').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('338').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('338').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('338').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </div>
            </td>
            <td colspan="6">
              <div class="form-group form-inline no-margin sideLabelAlignment">
                <label class="formLabel">EXPIRATION DATE (MM/DD/YYYY) </label>
                <div class="input-group">
                  <input
                    style="width:52%;"
                    class="form-control onboard datepicker"
                    placeholder="MM/DD/YYYY"
                    ngbDatepicker
                    #d25="ngbDatepicker"
                    id="339"
                    name="339"
                    formControlName="339"
                    (blur)="clearInputIfInvalid('339')"
                    type="text"
                    data-id="drivers exp"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="d25.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
                <div  *ngIf="WinterHealthcareProviderForms.get('339').invalid ">
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('339').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('339').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('339').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('339').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="6">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="formLabel">OTHER CDS (PLEASE SPECIFY) </label>
                <input
                  style="width:70%;"
                  class="form-control"
                  id="cmd_019"
                  name="cmd_019"
                  formControlName="cmd_019"
                  type="text"
                  data-id="CDs other"
                />
              </div>
            </td>
            <td colspan="6">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="formLabel">NUMBER</label>
                <input
                  style="width:70%;"
                  class="form-control"
                  id="340"
                  name="340"
                  formControlName="340"
                  type="text"
                  data-id="CDs number"
                />
              </div>
            </td>
            <td colspan="6">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="formLabel">STATE OF REGISTRATION </label>
                <div class="form-group selectDrop dropDrownSet">
                  <div class="col-md-12">
                    <ng-select
                      [items]="AltCdsStates"
                      bindLabel="name"
                      placeholder="Select States"
                      bindValue="id"
                      formControlName="997"
                    >
                    </ng-select>
                  </div>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="6">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="formLabel"
                  >ORIGINAL DATE OF ISSUE (MM/DD/YYYY)
                </label>
                <div class="input-group">
                  <input
                    style="width:50%;"
                    class="form-control onboard datepicker"
                    placeholder="MM/DD/YYYY"
                    ngbDatepicker
                    #d26="ngbDatepicker"
                    id="341"
                    name="341"
                    formControlName="341"
                    (blur)="clearInputIfInvalid('341')"
                    type="text"
                    data-id="alt CDs issue date"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="d26.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
                <div  *ngIf="WinterHealthcareProviderForms.get('341').invalid ">
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('341').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('341').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('341').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('341').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </div>
            </td>
            <td colspan="6">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="formLabel">EXPIRATION DATE (MM/DD/YYYY) </label>
                <div class="input-group">
                  <input
                    style="width:48%;"
                    class="form-control onboard datepicker"
                    placeholder="MM/DD/YYYY"
                    ngbDatepicker
                    #d27="ngbDatepicker"
                    id="342"
                    name="342"
                    formControlName="342"
                    (blur)="clearInputIfInvalid('342')"
                    type="text"
                    data-id="alt CDs Exp date"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="d27.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
                <div  *ngIf="WinterHealthcareProviderForms.get('342').invalid ">
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('342').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('342').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('342').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('342').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </div>
            </td>
            <td colspan="6">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="formLabel"
                  >DO YOU CURRENTLY PRACTICE IN THIS STATE?
                  <input
                    type="radio"
                    id="cmd_020"
                    name="cmd_020"
                    formControlName="cmd_020"
                    value="1"
                  />Yes</label
                >
                <label class="radio-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_020"
                    name="cmd_020"
                    formControlName="cmd_020"
                    value="0"
                  />No</label
                >
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="8">
              <div class="form-inline no-margin col-md-12 sideLabelAlignment">
                <label class="formLabel">UPIN</label>
                <input
                  class="form-control"
                  id="819"
                  name="819"
                  formControlName="819"
                  type="text"
                  data-id="upin"
                />
              </div>
            </td>
            <td colspan="10">
              <div class="row">
                <div class="col-md-8">
                  <label class="formLabel"
                    >NATIONAL PROVIDER IDENTIFIER (WHEN AVAILABLE)
                  </label>
                  <input
                    class="form-control"
                    id="1634"
                    (blur)="handleNAchange('cmd_NA_013',1634)" [class.is-invalid]="WinterHealthcareProviderForms.controls['1634'].invalid"
                    name="1634"
                    formControlName="1634"
                    type="text"
                    data-id="npi"
                  />
                </div>
                <div class="col-md-4">
                  <div class="form-check">
                    <input formControlName="cmd_NA_013" (ngModelChange)="handleNAchange('cmd_NA_013')" class="form-check-input" type="checkbox" value="1" id="cmd_NA_013">
                      <label class="form-check-label" for="cmd_NA_013">
                                <b class="texasFloat">Does Not Apply</b>
                      </label>
                  </div>
                </div>    
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="9">
              <div class="form-group form-inline no-margin sideLabelAlignment">
                <label class="formLabel"
                  >ARE YOU A PARTICIPATING MEDICARE PROVIDER?
                  <input
                    type="radio"
                    id="cmd_021"
                    name="cmd_021"
                    formControlName="cmd_021"
                    value="1"
                  />Yes</label
                >
                <label class="radio-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_021"
                    name="cmd_021"
                    formControlName="cmd_021"
                    value="0"
                  />No</label
                >
                <label class="formLabel">Medicare Provider Number:</label>
                <input
                  class="form-control"
                  id="325"
                  name="325"
                  formControlName="325"
                  type="text"
                  data-id="medicare"
                />
              </div>
            </td>
            <td colspan="9">
              <div class="form-group form-inline no-margin sideLabelAlignment">
                <label class="formLabel"
                  >ARE YOU A PARTICIPATING MEDICAID PROVIDER?
                  <input
                    type="radio"
                    id="cmd_022"
                    name="cmd_022"
                    formControlName="cmd_022"
                    value="1"
                  />Yes</label
                >
                <label class="radio-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_022"
                    name="cmd_022"
                    formControlName="cmd_022"
                    value="0"
                  />No</label
                >
                <label class="formLabel">Medicaid Provider Number:</label>
                <input
                  class="form-control"
                  id="812"
                  name="812"
                  formControlName="812"
                  type="text"
                  data-id="medicaid"
                />
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="9">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
              
                <label class="formLabel"
                  >EDUCATIONAL COUNCIL FOR FOREIGN MEDICAL GRADUATES (ECFMG)
                  <input
                    type="radio"
                    id="cmd_023"
                    name="cmd_023"
                    formControlName="cmd_023"
                    value="0"
                  />N/A</label
                > 
                <label class="radio-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_023"
                    name="cmd_023"
                    formControlName="cmd_023"
                    value="1"
                  />Yes</label
                > 
                <label class="radio-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_023"
                    name="cmd_023"
                    formControlName="cmd_023"
                    value="2"
                  />No</label
                > 
              </div>                 
                                
                <div class="row">
                  <div class="col-md-3">
                    <label class="formLabel"> ECFMG Number:</label>
                  </div> 
                  <div class="col-md-4">
                    <input class="form-control"
                      id="514"
                      name="514"
                      formControlName="514"
                      type="text"
                      data-id="ecfmg"
                    />
                  </div>   
                </div>  
                
             
            </td> 
            <td colspan="9">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="formLabel">ECFMG ISSUE DATE (MM/DD/YYYY) </label>
              </div>
              <div class="input-group">
                <input
                  style="width:38%;"
                  class="form-control onboard datepicker"
                  placeholder="MM/DD/YYYY"
                  ngbDatepicker
                  #d28="ngbDatepicker"
                  id="515"
                  name="515"
                  formControlName="515"
                  (blur)="clearInputIfInvalid('515')"
                  type="text"
                  data-id="ecfmgissue_date"
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-outline-secondary calendar"
                    (click)="d28.toggle()"
                    type="button"
                  >
                    <i class="fas fa-calendar-alt"></i>
                  </button>
                </div>
              </div>
              <div  *ngIf="WinterHealthcareProviderForms.get('515').invalid ">
                <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('515').errors?.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY)
                </small>
                <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('515').errors?.invalidMonth">
                Month is out of range
                </small>
                <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('515').errors?.invalidDateyear">
                  year is out of range(1900-2099)                      </small>
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('515').errors?.invalidDateRange">
                    date is out of range
                    </small>
                </div>
            </td>
          </tr>
        </table> 
        <table border="1" width="100%">
          <tr>
            <td colspan="15">
              <div class="centered texasFirstTable">
                <b style="font-size:18px;">Professional/Specialty Information</b>
              </div>
            </td>
            <td colspan="3" style="padding-left:50px;">
              <div class="form-check">
                <input formControlName="cmd_NA_04" (ngModelChange)="handleNAchange('cmd_NA_04')" class="form-check-input" type="checkbox" value="1" id="cmd_NA_04">
                  <label class="form-check-label" for="cmd_NA_04">
                            <b class="texasFloat">Does Not Apply</b>
                  </label>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="6">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="boldParagraph">PRIMARY SPECIALTY </label>
                <div class="col-md-10">
                  <ng-select
                    [items]="specialty"
                    (blur)="handleNAchange('cmd_NA_04')" [class.is-invalid]="WinterHealthcareProviderForms.controls['556'].invalid"
                    bindLabel="name"
                    placeholder="Select Specialty"
                    bindValue="id"
                    formControlName="556"
                  >
                  </ng-select>
                  <div class="mandatory"
                    *ngIf=" WinterHealthcareProviderForms.controls['556'].invalid">
                                       <small>
                                       required
                                       </small>
                  </div>
                </div>
              </div>
            </td>
            <td colspan="12">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="boldParagraph">BOARD CERTIFIED?</label>
                <label class="radio-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_024"
                    name="cmd_024"
                    formControlName="cmd_024"
                    (blur)="handleNAchange('cmd_NA_04','cmd_024')"
                    (ngModelChange)="handleYesNoChanges('cmd_024')" [class.is-invalid]="(WinterHealthcareProviderForms.controls['cmd_024'].touched && WinterHealthcareProviderForms.controls['cmd_024'].invalid) || (WinterHealthcareProviderForms.controls['cmd_024'].invalid && isSubmit)"
                    value="1"
                  />Yes</label
                >
                <label class="radio-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_024"
                    name="cmd_024"
                    (blur)="handleNAchange('cmd_NA_04','cmd_024')"                  
                    formControlName="cmd_024"
                    (ngModelChange)="handleYesNoChanges('cmd_024')" [class.is-invalid]="(WinterHealthcareProviderForms.controls['cmd_024'].touched && WinterHealthcareProviderForms.controls['cmd_024'].invalid) || (WinterHealthcareProviderForms.controls['cmd_024'].invalid && isSubmit)"
                    value="0"
                  />No</label
                >
                <div class="formLabel col-md-11"
                  *ngIf="WinterHealthcareProviderForms.controls['cmd_024'].touched && WinterHealthcareProviderForms.controls['cmd_024'].invalid">
                    <small class="text-danger" *ngIf="WinterHealthcareProviderForms.controls['cmd_024'].errors?.required">
                        required
                    </small>
                </div>
                  <span *ngIf="(WinterHealthcareProviderForms.controls['cmd_024'].touched && WinterHealthcareProviderForms.controls['cmd_024'].errors) || (WinterHealthcareProviderForms.controls['cmd_024'].errors && isSubmit)">
                    <i class="fas fa-times ml-2 text-danger"></i>
                  </span>
              </div>  
              <div class="row">  
                  <div class="col-md-3">
                    <label class="formLabel"> Name of Certifying Board: </label>
                  </div>
                  <div class="col-md-7">
                    <ng-select
                      [items]="boardCertification"
                      (blur)="handleYesNoChanges('cmd_024')" 
                      [class.is-invalid]="WinterHealthcareProviderForms.controls['554'].invalid"
                      bindLabel="name"
                      placeholder="Select Certificate"
                      bindValue="id"
                      formControlName="554"
                    >
                    </ng-select>
                    <div class="mandatory" *ngIf="WinterHealthcareProviderForms.controls['554'].invalid">
                      <small >
                      required
                      </small>
                    </div>
                  </div>
              
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="4">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="formLabel"
                  >INITIAL CERTIFICATION DATE (MM/DD/YYYY)
                </label>
                <div class="input-group">
                  <input
                    style="width:80%;"
                    [class.is-invalid]="WinterHealthcareProviderForms.controls['557'].invalid"
                    class="form-control onboard datepicker"
                    placeholder="MM/DD/YYYY"
                    ngbDatepicker
                    #d29="ngbDatepicker"
                    id="557"
                    name="557"
                    formControlName="557"
                    (blur)="clearInputIfInvalid('557','cmd_NA_04')"
                    (blur)="handleNAchange('cmd_NA_04',557)"
                    type="text"
                    data-id="primarybcert"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="d29.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
                <div class="text-danger" *ngIf="WinterHealthcareProviderForms.controls['557'].invalid && (WinterHealthcareProviderForms.controls['557'].value==''|| WinterHealthcareProviderForms.controls['557'].value==null) " >
                  <small >
                  required
                  </small>
                
                </div>
                <div  *ngIf="WinterHealthcareProviderForms.get('557').invalid ">
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('557').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)                  </small>
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('557').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('557').errors?.invalidDateyear">
                    year is out of range(1900-2099)                    </small>
                    <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('557').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </div>
            </td>
            <td colspan="8">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="formLabel"
                  >RECERTIFICATION DATE(S), IF APPLICABLE (MM/DD/YYYY)
                </label>
                <div class="input-group">
                  <input
                    style="width:48%;"
                    class="form-control onboard datepicker"
                    (blur)="clearInputIfInvalid('559','cmd_NA_04')"
                    (blur)="handleNAchange('cmd_NA_04',559)" [class.is-invalid]="WinterHealthcareProviderForms.controls['559'].invalid"
                    placeholder="MM/DD/YYYY"
                    ngbDatepicker
                    #d30="ngbDatepicker"
                    id="559"
                    name="559"
                    formControlName="559"
                    type="text"
                    data-id="primarybrcert2"
                  />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="d30.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                </div>
                <div class="text-danger" *ngIf="WinterHealthcareProviderForms.controls['559'].invalid && (WinterHealthcareProviderForms.controls['559'].value==''|| WinterHealthcareProviderForms.controls['559'].value==null) " >
                  <small >
                  required
                  </small>
                
                </div>
                <div  *ngIf="WinterHealthcareProviderForms.get('559').invalid ">
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('559').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)                  </small>
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('559').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('559').errors?.invalidDateyear">
                    year is out of range(1900-2099)                    </small>
                    <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('559').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </div>
            </td>
            <td colspan="6">
              <div class="form-group form-inline no-margin sideLabelAlignment">
                <label class="formLabel"
                  >EXPIRATION DATE, IF APPLICABLE (MM/DD/YYYY)
                </label>
                <div class="input-group">
                  <input
                    style="width:51%;"
                    class="form-control onboard datepicker"
                    placeholder="MM/DD/YYYY"
                    (blur)="clearInputIfInvalid('560','cmd_NA_04')"
                    (blur)="handleNAchange('cmd_NA_04',560)" [class.is-invalid]="WinterHealthcareProviderForms.controls['560'].invalid"
                    ngbDatepicker
                    #d31="ngbDatepicker"
                    id="560"
                    name="560"
                    formControlName="560"
                    type="text"
                    data-id="primarybexp_date"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="d31.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
                <div class="text-danger" *ngIf="WinterHealthcareProviderForms.controls['560'].invalid && (WinterHealthcareProviderForms.controls['560'].value==''|| WinterHealthcareProviderForms.controls['560'].value==null) " >
                  <small >
                  required
                  </small>
                
                </div>
                <div  *ngIf="WinterHealthcareProviderForms.get('560').invalid ">
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('560').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)                  </small>
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('560').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('560').errors?.invalidDateyear">
                    year is out of range(1900-2099)                    </small>
                    <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('560').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="18">
              
                <div class="col-md-8">
                  <label class="formLabel"
                    >IF NOT BOARD CERTIFIED, INDICATE ANY OF THE FOLLOWING THAT
                    APPLY.</label
                  >
                </div>  
                <div class="row">
                  <div class="col-md-4">
                    <label
                     
                    >
                      <input
                        type="checkbox"
                        id="cmd_025"
                        name="cmd_025"
                        formControlName="cmd_025"
                        value="1"/>
                      I have taken exam, results pending for Board</label>
                  </div>
                  <div class="col-md-4">   
                      
                    <div class="input-group">
                      <input type="text"   placeholder= "Primary Board Test Date" id="1377"   (blur)="clearInputIfInvalid('1377')" class="form-control" formControlName="1377"
                      ngbDatepicker #d1377="ngbDatepicker" >
                      <div class="input-group-append">
                          <button class="btn btn-outline-secondary calendar" (click)="d1377.toggle()" type="button">
                          <i class="fas fa-calendar-alt"></i>
                          </button>
                      </div>
                  </div>  
                  <div  *ngIf="WinterHealthcareProviderForms.get('1377').invalid ">
                    <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('1377').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('1377').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('1377').errors?.invalidDateyear">
                      year is out of range(1900-2099)                      </small>
                      <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('1377').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
                  </div>
                </div>  
                <div class="row">
                    <div class="col-md-4">
                      <label>
                        <input
                          type="checkbox"
                          id="cmd_026"
                          name="cmd_026"
                          formControlName="cmd_026"
                          value="1"/>
                        I have taken Part I and am eligible for Part II of the Exam.</label>
                    </div>
                    <div class="col-md-6">    
                        <input
                          style="width: 50%"
                          class="form-control"
                          id="cmd_030"
                          name="cmd_030"
                          formControlName="cmd_030"
                          type="text"
                          data-id="test2"
                      />
                    </div>
                </div>  
                <div class="row">             
                    <div class="col-md-4">
                      <label
                       
                      >
                        <input
                          type="checkbox"
                          id="cmd_027"
                          name="cmd_027"
                          formControlName="cmd_027"
                          value="1"/>I am intending to sit for the Boards on (date)</label>
                    </div>
                    <div class="col-md-6">
                        <input
                          style="width: 50%"
                          class="form-control"
                          id="cmd_031"
                          name="cmd_031"
                          formControlName="cmd_031"
                          type="text"
                          data-id="test3"
                      />
                    </div>
                </div>  
                <div class="row">
                    
                      <div class="col-md-12">
                        <label
                          
                        >
                          <input
                            type="checkbox"
                            id="cmd_028"
                            name="cmd_028"
                            formControlName="cmd_028"
                            value="1"
                          />
                          I am not planning to take Boards</label
                        >
                      </div>
                </div>
             
            </td>
          </tr>
          <tr>
            <td colspan="18">
              <div class="form-group form-inline no-margin sideLabelAlignment">
                <div class="row extraBoxPaddingLeft">
                  <div class="col-md-12">
                    <label class="formLabel"
                      >DO YOU WISH TO BE LISTED IN THE DIRECTORY UNDER THIS
                      SPECIALTY?
                    </label>
                  </div>
                </div>
                <br />
                <label class="formLabel"><b>HMO:</b></label>
                <label class="boldParagraph" class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_032"
                    name="cmd_032"
                    formControlName="cmd_032"
                    value="1"
                  />
                  Yes</label
                >
                <label class="boldParagraph" class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_032"
                    name="cmd_032"
                    formControlName="cmd_032"
                    value="0"
                  />
                  No
                </label>
                <br />
                <label class="formLabel"><b>PPO:</b></label>
                <label class="boldParagraph" class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_033"
                    name="cmd_033"
                    formControlName="cmd_033"
                    value="1"
                  />
                  Yes</label
                >
                <label class="boldParagraph" class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_033"
                    name="cmd_033"
                    formControlName="cmd_033"
                    value="0"
                  />
                  No
                </label>
                <br />
                <label class="formLabel"><b>POS:</b></label>
                <label class="boldParagraph" class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_034"
                    name="cmd_034"
                    formControlName="cmd_034"
                    value="1"
                  />Yes</label
                >
                <label class="boldParagraph" class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_034"
                    name="cmd_034"
                    formControlName="cmd_034"
                    value="0"
                  />
                  No
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="7">
              <div class="form-group form-inline no-margin sideLabelAlignment">
                <label class="formLabel">SECONDARY SPECIALTY </label>
                <div class="col-md-8">
                  <ng-select
                    [items]="specialtySecondary"
                    bindLabel="name"
                    placeholder="Select Secondary Specialty"
                    bindValue="id"
                    formControlName="563"
                  >
                  </ng-select>
                </div>
              </div>
            </td>
            <td colspan="14">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="boldParagraph">BOARD CERTIFIED?</label>
                <label class="radio-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_035"
                    name="cmd_035"
                    formControlName="cmd_035"
                    value="1"
                  />Yes</label
                >
                <label class="radio-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_035"
                    name="cmd_035"
                    formControlName="cmd_035"
                    value="0"
                  />No</label
                >
                <br />
                <div class="row extraBoxPaddingLeft">
                  <label class="formLabel">Name of Certifying Board:</label>
                  
                    <div class="col-md-12">
                      <ng-select
                        [items]="boardCertification"
                        bindLabel="name"
                        placeholder="Select Secondary Board Certification"
                        bindValue="id"
                        formControlName="561"
                      >
                      </ng-select>
                    </div>
                  
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="6">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="formLabel"
                  >INITIAL CERTIFICATION DATE (MM/DD/YYYY)
                </label>
                <div class="input-group">
                  <input
                    style="width:53%;"
                    class="form-control onboard datepicker"
                    placeholder="MM/DD/YYYY"
                    ngbDatepicker
                    #d32="ngbDatepicker"
                    id="564"
                    name="564"
                    formControlName="564"
                    (blur)="clearInputIfInvalid('564')"
                    type="text"
                    data-id="secbcert"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="d32.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
                <div  *ngIf="WinterHealthcareProviderForms.get('564').invalid ">
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('564').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('564').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('564').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('564').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </div>
            </td>
            <td colspan="6">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="formLabel"
                  >RECERTIFICATION DATE(S), IF APPLICABLE (MM/DD/YYYY)
                </label>
                <div class="input-group">
                  <input
                    style="width:47%;"
                    class="form-control onboard datepicker"
                    placeholder="MM/DD/YYYY"
                    ngbDatepicker
                    #d33="ngbDatepicker"
                    id="565"
                    name="565"
                    formControlName="565"
                    (blur)="clearInputIfInvalid('565')"
                    type="text"
                    data-id="secbrcert2"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="d33.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
                <div  *ngIf="WinterHealthcareProviderForms.get('565').invalid ">
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('565').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('565').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('565').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('565').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </div>
            </td>
            <td colspan="6">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="formLabel"
                  >EXPIRATION DATE, IF APPLICABLE (MM/DD/YYYY)
                </label>
                <div class="input-group">
                  <input
                    style="width:50%;"
                    class="form-control onboard datepicker"
                    placeholder="MM/DD/YYYY"
                    ngbDatepicker
                    #d34="ngbDatepicker"
                    id="567"
                    name="567"
                    formControlName="567"
                    (blur)="clearInputIfInvalid('567')"
                    type="text"
                    data-id="secbexp_date"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="d34.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
                <div  *ngIf="WinterHealthcareProviderForms.get('567').invalid ">
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('567').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('567').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('567').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('567').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </div>
            </td>
          </tr>
        </table>

        <hr class="tablePersonal" />      
        <table border="1" width="100%">
          <td colspan="18">
            <div class="centered texasFirstTable">
              
                <b style="font-size:18px;">Professional/Specialty Information</b> -continued
            
            </div>
          </td>
          <tr>
            <td colspan="18">
              
                <div class="col-md-8">
                  <label class="formLabel"
                    >IF NOT BOARD CERTIFIED, INDICATE ANY OF THE FOLLOWING THAT
                    APPLY.</label
                  >
                </div>
                <div class="row">
                  <div class="col-md-4">
                    <label>
                  
                    <input
                      type="checkbox"
                      id="cmd_036"
                      name="cmd_036"
                      formControlName="cmd_036"
                      value="1"/>
                    I have taken exam, results pending for Board</label>
                  </div>
                  <div class="col-md-4">   
            
                    <div class="input-group">
                      <input type="text"   placeholder= "Secondary Board Test Date" id="1378"   (blur)="clearInputIfInvalid('1378')" class="form-control" formControlName="1378"
                      ngbDatepicker #d1378="ngbDatepicker" >
                      <div class="input-group-append">
                          <button class="btn btn-outline-secondary calendar" (click)="d1378.toggle()" type="button">
                          <i class="fas fa-calendar-alt"></i>
                          </button>
                      </div>
                    </div>  
                    <div  *ngIf="WinterHealthcareProviderForms.get('1378').invalid ">
                      <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('1378').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)
                      </small>
                      <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('1378').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('1378').errors?.invalidDateyear">
                        year is out of range(1900-2099)                      </small>
                        <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('1378').errors?.invalidDateRange">
                          date is out of range
                          </small>
                      </div>
                  </div>
                </div>  
                <div class="row">
                  <div class="col-md-4">
                    <label>
                    <input
                      type="checkbox"
                      id="cmd_037"
                      name="cmd_037"
                      formControlName="cmd_037"
                      value="1"/>
                    I have taken Part I and am eligible for Part II of the Exam.</label>
                  </div>
                  <div class="col-md-6">   
                    <input
                      style="width: 50%"
                      class="form-control"
                      id="cmd_041"
                      name="cmd_041"
                      formControlName="cmd_041"
                      type="text"
                      data-id="test5"
                    />
                  </div>
                </div>  
                <div class="row">             
                  <div class="col-md-4">
                    <label>
                  
                    <input
                      type="checkbox"
                      id="cmd_038"
                      name="cmd_038"
                      formControlName="cmd_038"
                      value="1"/>I am intending to sit for the Boards on (date)</label>
                  </div>
                  <div class="col-md-6">   
                    <input
                      style="width: 50%"
                      class="form-control"
                      id="cmd_042"
                      name="cmd_042"
                      formControlName="cmd_042"
                      type="text"
                      data-id="test6"
                    />
                  </div>
                </div>  
                <div class="row">
          
                  <div class="col-md-12">
                    <label>
                      <input
                        type="checkbox"
                        id="cmd_039"
                        name="cmd_039"
                        formControlName="cmd_039"
                        value="1"
                      />
                    I am not planning to take Boards</label>
                 
                  </div>
                </div>  
              
            </td>
          </tr>
          <tr>
            <td colspan="18">
              <div class="form-group form-inline no-margin sideLabelAlignment">
                <label class="formLabel"
                  >DO YOU WISH TO BE LISTED IN THE DIRECTORY UNDER THIS
                  SPECIALTY?
                </label>
                <br />
                <label class="formLabel"><b>HMO:</b></label>
                <label class="boldParagraph" class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_043"
                    name="cmd_043"
                    formControlName="cmd_043"
                    value="1"
                  />
                  Yes</label
                >
                <label class="boldParagraph" class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_043"
                    name="cmd_043"
                    formControlName="cmd_043"
                    value="0"
                  />
                  No
                </label>
                <br />
                <label class="formLabel"><b>PPO:</b></label>
                <label class="boldParagraph" class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_044"
                    name="cmd_044"
                    formControlName="cmd_044"
                    value="1"
                  />
                  Yes</label
                >
                <label class="boldParagraph" class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_044"
                    name="cmd_044"
                    formControlName="cmd_044"
                    value="0"
                  />
                  No
                </label>
                <br />
                <label class="formLabel"><b>POS:</b></label>
                <label class="boldParagraph" class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_045"
                    name="cmd_045"
                    formControlName="cmd_045"
                    value="1"
                  />Yes</label
                >
                <label class="boldParagraph" class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_045"
                    name="cmd_045"
                    formControlName="cmd_045"
                    value="0"
                  />
                  No
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="9">
              <div class="row">
                <div class="col-md-3">
                    <label class="formLabel">ADDITIONAL SPECIALTY </label>
                </div>  
                <div class="col-md-9">  
                    <div class="col-md-9">
                      <ng-select
                        [items]="specialtyTer"
                        bindLabel="name"
                        placeholder="Select Specialty"
                        bindValue="id"
                        formControlName="948"
                      >
                      </ng-select>
                    </div>
                </div>    
              </div>
            </td>
            <td colspan="9">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="boldParagraph">BOARD CERTIFIED?</label>
                <label class="radio-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_046"
                    name="cmd_046"
                    formControlName="cmd_046"
                    value="1"
                  />Yes</label
                >
                <label class="radio-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_046"
                    name="cmd_046"
                    formControlName="cmd_046"
                    value="0"
                  />No</label
                >
              </div>
              <div class="row">
                <div class="col-md-5">
                  <label class="formLabel">Name of Certifying Board:</label>
                </div>  
                    <div class="col-md-7">
                      <ng-select
                        [items]="boardCertification"
                        bindLabel="name"
                        placeholder="Select ADDTIONAL BOARD CERTIFICATON"
                        bindValue="id"
                        formControlName="946"
                      >
                      </ng-select>
                    </div>
                  
                  
                
              </div>  
            </td>
          </tr>
          <tr>
            <td colspan="6">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="formLabel"
                  >INITIAL CERTIFICATION DATE (MM/DD/YYYY)
                </label>
                <div class="input-group">
                  <input
                    style="width:69%;"
                    class="form-control onboard datepicker"
                    placeholder="MM/DD/YYYY"
                    ngbDatepicker
                    #d35="ngbDatepicker"
                    id="953"
                    name="953"
                    formControlName="953"
                    (blur)="clearInputIfInvalid('953')"
                    type="text"
                    data-id="thrid board original issue date"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="d35.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
                <div  *ngIf="WinterHealthcareProviderForms.get('953').invalid ">
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('953').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('953').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('953').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('953').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </div>
            </td>
            <td colspan="6">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="formLabel"
                  >RECERTIFICATION DATE(S), IF APPLICABLE (MM/DD/YYYY)
                </label>
                <div class="input-group">
                  <input
                    style="width:43%;"
                    class="form-control onboard datepicker"
                    placeholder="MM/DD/YYYY"
                    ngbDatepicker
                    #d36="ngbDatepicker"
                    id="950"
                    name="950"
                    formControlName="950"
                    (blur)="clearInputIfInvalid('950')"
                    type="text"
                    data-id="Tertiary Board recert 2"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="d36.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
                <div  *ngIf="WinterHealthcareProviderForms.get('950').invalid ">
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('950').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('950').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('950').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('950').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </div>
            </td>
            <td colspan="6">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="formLabel"
                  >EXPIRATION DATE, IF APPLICABLE (MM/DD/YYYY)
                </label>
                <div class="input-group">
                  <input
                    style="width:45%;"
                    class="form-control onboard datepicker"
                    placeholder="MM/DD/YYYY"
                    ngbDatepicker
                    #d37="ngbDatepicker"
                    id="954"
                    name="954"
                    formControlName="954"
                    (blur)="clearInputIfInvalid('954')"
                    type="text"
                    data-id="Tertiary Board exp"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="d37.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
                <div  *ngIf="WinterHealthcareProviderForms.get('954').invalid ">
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('954').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('954').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('954').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('954').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="18">
              
                <div class="col-md-8">
                  <label class="formLabel"
                    >IF NOT BOARD CERTIFIED, INDICATE ANY OF THE FOLLOWING THAT
                    APPLY.</label
                  >
                </div>  
                <div class="row">
                  <div class="col-md-4">
                    <label
                     
                    >
                      <input
                        type="checkbox"
                        id="cmd_047"
                        name="cmd_047"
                        formControlName="cmd_047"
                        value="1"/>
                      I have taken exam, results pending for Board</label>
                  </div>
                  <div class="col-md-4">   
                      
                    <div class="input-group">
                      <input type="text"   placeholder= "Third Board Test Date" id="1379"   (blur)="clearInputIfInvalid('1379')" class="form-control" formControlName="1379"
                      ngbDatepicker #d1379="ngbDatepicker" >
                      <div class="input-group-append">
                          <button class="btn btn-outline-secondary calendar" (click)="d1379.toggle()" type="button">
                          <i class="fas fa-calendar-alt"></i>
                          </button>
                      </div>
                  </div>  
                  <div  *ngIf="WinterHealthcareProviderForms.get('1379').invalid ">
                    <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('1379').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('1379').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('1379').errors?.invalidDateyear">
                      year is out of range(1900-2099)                      </small>
                      <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('1379').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
                  </div>
                </div>  
                <div class="row">
                    <div class="col-md-4">
                      <label>
                        <input
                          type="checkbox"
                          id="cmd_048"
                          name="cmd_048"
                          formControlName="cmd_048"
                          value="1"/>
                        I have taken Part I and am eligible for Part II of the Exam.</label>
                    </div>
                    <div class="col-md-6">    
                        <input
                          style="width: 50%"
                          class="form-control"
                          id="cmd_052"
                          name="cmd_052"
                          formControlName="cmd_052"
                          type="text"
                          data-id="test2"
                      />
                    </div>
                </div>  
                <div class="row">             
                    <div class="col-md-4">
                      <label
                       
                      >
                        <input
                          type="checkbox"
                          id="cmd_049"
                          name="cmd_049"
                          formControlName="cmd_049"
                          value="1"/>I am intending to sit for the Boards on (date)</label>
                    </div>
                    <div class="col-md-6">
                        <input
                          style="width: 50%"
                          class="form-control"
                          id="cmd_053"
                          name="cmd_053"
                          formControlName="cmd_053"
                          type="text"
                          data-id="test3"
                      />
                    </div>
                </div>  
                <div class="row">
                    
                      <div class="col-md-12">
                        <label
                          
                        >
                          <input
                            type="checkbox"
                            id="cmd_050"
                            name="cmd_050"
                            formControlName="cmd_050"
                            value="1"
                          />
                          I am not planning to take Boards</label
                        >
                      </div>
                </div>
             
            </td>
          </tr>
          <tr>
            <td colspan="18">
              <div class="form-group form-inline no-margin sideLabelAlignment">
                <label class="formLabel"
                  >DO YOU WISH TO BE LISTED IN THE DIRECTORY UNDER THIS
                  SPECIALTY?
                </label>
                <br />
                <label class="formLabel"><b>HMO:</b></label>
                <label class="boldParagraph" class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_054"
                    name="cmd_054"
                    formControlName="cmd_054"
                    value="1"
                  />
                  Yes</label
                >
                <label class="boldParagraph" class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_054"
                    name="cmd_054"
                    formControlName="cmd_054"
                    value="0"
                  />
                  No
                </label>
                <br />
                <label class="formLabel"><b>PPO:</b></label>
                <label class="boldParagraph" class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_055"
                    name="cmd_055"
                    formControlName="cmd_055"
                    value="1"
                  />
                  Yes</label
                >
                <label class="boldParagraph" class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_055"
                    name="cmd_055"
                    formControlName="cmd_055"
                    value="0"
                  />
                  No
                </label>
                <br />
                <label class="formLabel"><b>POS:</b></label>
                <label class="boldParagraph" class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_056"
                    name="cmd_056"
                    formControlName="cmd_056"
                    value="1"
                  />Yes</label
                >
                <label class="boldParagraph" class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_056"
                    name="cmd_056"
                    formControlName="cmd_056"
                    value="0"
                  />
                  No
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="18">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="checkbox-inline radioStyle"
                  >PLEASE LIST OTHER AREAS OF PROFESSIONAL PRACTICE INTEREST OR
                  FOCUS (HIV/AIDS, ETC.)
                </label>
                <input
                  class="form-control"
                  id="3826"
                  name="3826"
                  formControlName="3826"
                  type="text"
                  data-id="other interest"
                />
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="15">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
              <b style="font-size:18px;">Work History</b>
                                <label class="formLabel">- Please provide a chronological work history. You may submit a
                Curriculum Vitae as a supplement. Please explain all gaps in
                employment that lasted more than six months</label>
              </div>
            </td>
            <td colspan="3" style="padding-left:40px;">
              <div class="form-check">
                <input formControlName="cmd_NA_05" (ngModelChange)="handleNAchange('cmd_NA_05')" class="form-check-input" type="checkbox" value="1" id="cmd_NA_05">
                   <label class="form-check-label" for="cmd_NA_05">
                            <b class="texasFloat">Does Not Apply</b>
                   </label>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="8">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="formLabel"
                  >CURRENT PRACTICE/EMPLOYER NAME
                  <input
                    class="form-control"
                    id="445"
                    name="445"
                    formControlName="445"
                    (blur)="handleNAchange('cmd_NA_05',445)" [class.is-invalid]="WinterHealthcareProviderForms.controls['445'].invalid"
                    type="text"
                    data-id="wkhist1"
                  />
                  <div class="mandatory"
                  *ngIf="WinterHealthcareProviderForms.controls['445'].invalid">
                  <small >
                    required
                  </small>
              </div> 
                </label>
              </div>
            </td>
            <td colspan="10">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="formLabel"
                  >START DATE/END DATE (MM/DD/YYYY TO MM/DD/YYYY)
                </label>
                <div class="input-group">
                  <input
                    class="form-control onboard datepicker"
                    (blur)="clearInputIfInvalid('454','cmd_NA_05')"
                    (blur)="handleNAchange('cmd_NA_05',454)" [class.is-invalid]="WinterHealthcareProviderForms.controls['454'].invalid"
                    placeholder="MM/DD/YYYY"
                    ngbDatepicker
                    #d38="ngbDatepicker"
                    id="454"
                    name="454"
                    formControlName="454"
                    type="text"
                    data-id="wk1histstartdt"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="d38.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
                <div class="text-danger" *ngIf="WinterHealthcareProviderForms.controls['454'].invalid && (WinterHealthcareProviderForms.controls['454'].value==''|| WinterHealthcareProviderForms.controls['454'].value==null) " >
                  <small >
                  required
                  </small>
                
                </div>
                <div  *ngIf="WinterHealthcareProviderForms.get('454').invalid ">
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('454').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)                  </small>
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('454').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('454').errors?.invalidDateyear">
                    year is out of range(1900-2099)                    </small>
                    <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('454').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
                <div class="input-group">
                  <input
                    class="form-control onboard datepicker"
                    (blur)="clearInputIfInvalid('455','cmd_NA_05')"
                    (blur)="handleNAchange('cmd_NA_05',455)" [class.is-invalid]="WinterHealthcareProviderForms.controls['455'].invalid"
                    placeholder="MM/DD/YYYY"
                    ngbDatepicker
                    #d39="ngbDatepicker"
                    id="455"
                    name="455"
                    formControlName="455"
                    type="text"
                    data-id="wk1histenddt"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="d39.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
                <div class="text-danger" *ngIf="WinterHealthcareProviderForms.controls['455'].invalid && (WinterHealthcareProviderForms.controls['455'].value==''|| WinterHealthcareProviderForms.controls['455'].value==null) " >
                  <small >
                  required
                  </small>
                
                </div>
                <div  *ngIf="WinterHealthcareProviderForms.get('455').invalid ">
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('455').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)                  </small>
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('455').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('455').errors?.invalidDateyear">
                    year is out of range(1900-2099)                    </small>
                    <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('455').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="18">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="formLabel">ADDRESS</label>
                <input
                  style="width: 40%;margin-bottom: 2px;"
                  (blur)="handleNAchange('cmd_NA_05',446)" [class.is-invalid]="WinterHealthcareProviderForms.controls['446'].invalid"
                  class="form-control"
                  placeholder="Address 1"
                  id="446"
                  name="446"
                  formControlName="446"
                  type="text"
                  data-id="wkhist1add"
                />
                <div class="mandatory"
                                  *ngIf="WinterHealthcareProviderForms.controls['446'].invalid">
                                  <small >
                                    required
                                  </small>
                </div> 
                <input
                  style="width: 40%;margin-bottom: 2px;"
                  (blur)="handleNAchange('cmd_NA_05',447)" [class.is-invalid]="WinterHealthcareProviderForms.controls['447'].invalid"
                  class="form-control"
                  placeholder="Address 2"
                  id="447"
                  name="447"
                  formControlName="447"
                  type="text"
                  data-id="wkhist1add2"
                />
                <div class="mandatory"
                                  *ngIf="WinterHealthcareProviderForms.controls['447'].invalid">
                                  <small >
                                    required
                                  </small>
                </div> 
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="18">
              <div
                class="form-group form-inline no-margin col-md-3 sideLabelAlignment"
              >
                <label class="formLabel">CITY</label>
                <input
                  style="width: 100%"
                  placeholder="City"
                  (blur)="handleNAchange('cmd_NA_05',448)" [class.is-invalid]="WinterHealthcareProviderForms.controls['448'].invalid"
                  class="form-control"
                  id="448"
                  name="448"
                  formControlName="448"
                  type="text"
                  data-id="Work hist 1 city"
                />
                <div class="mandatory"
                                  *ngIf="WinterHealthcareProviderForms.controls['448'].invalid">
                                  <small >
                                    required
                                  </small>
                </div> 
              </div>
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="formLabel">STATE/COUNTRY</label>
                <div class="form-group selectDrop dropDrownSet">
                  <div class="col-md-12">
                    <ng-select
                      [items]="states"
                      (blur)="handleNAchange('cmd_NA_05')" [class.is-invalid]="WinterHealthcareProviderForms.controls['450'].invalid"
                      bindLabel="name"
                      placeholder="Select States"
                      bindValue="id"
                      formControlName="450"
                    >
                    </ng-select>
                  </div>
                  <div class="mandatory"
                                       *ngIf=" WinterHealthcareProviderForms.controls['450'].invalid">
                                       <small>
                                       required
                                       </small>
                  </div>
                </div>
              </div>
              <div
                class="form-group form-inline no-margin col-md-3 sideLabelAlignment"
              >
                <label class="formLabel">POSTAL CODE</label>
                <input
                  style="width: 100%"
                  minlength="5"
                  maxlength="10"
                  (blur)="handleNAchange('cmd_NA_05',451)" [class.is-invalid]="WinterHealthcareProviderForms.controls['451'].invalid"
                  class="form-control"
                  placeholder="Zip"
                  id="451"
                  name="451"
                  formControlName="451"
                  type="text"
                  data-id="Work hist 1 zip"
                />
                <!-- <div
                  class="formLabel col-md-2"
                  *ngIf="
                    WinterHealthcareProviderForms.controls['451'].touched &&
                    WinterHealthcareProviderForms.controls['451'].errors
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="WinterHealthcareProviderForms.controls['451'].errors"
                  >
                    required
                  </small>
                  <small
                    class="text-danger"
                    *ngIf="
                      WinterHealthcareProviderForms.controls['451'].errors?.minlength ||
                      WinterHealthcareProviderForms.controls['451'].errors?.maxlength
                    "
                  >
                    Zip code must be atleast 5 digits and less than 10 digits
                  </small>
                </div> -->
              
                <div class="mandatory"
                                    *ngIf="WinterHealthcareProviderForms.controls['451'].invalid || WinterHealthcareProviderForms.controls['451'].errors?.minlength ||
                                    WinterHealthcareProviderForms.controls['451'].errors?.maxlength">
                                    <small >
                                      required
                                    </small>
                </div> 
              </div>  
            </td>
          </tr>
          <tr>
            <td colspan="8">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="formLabel"
                  >PREVIOUS PRACTICE/EMPLOYER NAME
                  <input
                    class="form-control"
                    id="456"
                    name="456"
                    formControlName="456"
                    type="text"
                    data-id="wkhist2"
                  />
                </label>
              </div>
            </td>
            <td colspan="10">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="formLabel"
                  >START DATE/END DATE (MM/DD/YYYY TO MM/DD/YYYY)
                </label>
                <div class="input-group">
                  <input
                    style="width:25%;"
                    class="form-control onboard datepicker"
                    placeholder="MM/DD/YYYY"
                    ngbDatepicker
                    #d40="ngbDatepicker"
                    id="465"
                    name="465"
                    formControlName="465"
                    (blur)="clearInputIfInvalid('465')"
                    type="text"
                    data-id="wkhist2startdt"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="d40.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
                <div  *ngIf="WinterHealthcareProviderForms.get('465').invalid ">
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('465').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('465').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('465').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('465').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
                <div class="input-group">
                  <input
                    class="form-control onboard datepicker"
                    placeholder="MM/DD/YYYY"
                    ngbDatepicker
                    #d41="ngbDatepicker"
                    id="466"
                    name="466"
                    formControlName="466"
                    (blur)="clearInputIfInvalid('466')"
                    type="text"
                    data-id="wkhist2enddt"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="d41.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
                <div  *ngIf="WinterHealthcareProviderForms.get('466').invalid ">
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('466').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('466').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('466').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('466').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="18">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="formLabel">ADDRESS</label>
                <input
                  style="width: 40%;margin-bottom: 2px;"
                  class="form-control"
                  placeholder="Address 1"
                  id="457"
                  name="457"
                  formControlName="457"
                  type="text"
                  data-id="wkhist2add"
                />
                <input
                  style="width: 40%;margin-bottom: 2px;"
                  class="form-control"
                  placeholder="Address 2"
                  id="458"
                  name="458"
                  formControlName="458"
                  type="text"
                  data-id="wkhist2address2"
                />
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="18">
              <div
                class="form-group form-inline no-margin col-md-3 sideLabelAlignment"
              >
                <label class="formLabel">CITY</label>
                <input
                  style="width: 100%"
                  placeholder="City"
                  class="form-control"
                  id="459"
                  name="459"
                  formControlName="459"
                  type="text"
                  data-id="Work hist 2 city"
                />
              </div>
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="formLabel">STATE/COUNTRY</label>
                <div class="form-group selectDrop dropDrownSet">
                  <div class="col-md-12">
                    <ng-select
                      [items]="states"
                      bindLabel="name"
                      placeholder="Select States"
                      bindValue="id"
                      formControlName="461"
                    >
                    </ng-select>
                  </div>
                  
                </div>
              </div>
              <div
                class="form-group form-inline no-margin col-md-3 sideLabelAlignment"
              >
                <label class="formLabel">POSTAL CODE</label>
                <input
                  style="width: 100%"
                  class="form-control"
                  placeholder="Zip"
                  id="462"
                  name="462"
                  formControlName="462"
                  type="text"
                  data-id="Work hist 2 zip"
                />
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="18">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="formLabel">REASON FOR DISCONTINUANCE </label>
                <input
                  class="form-control"
                  id="1338"
                  name="1338"
                  formControlName="1338"
                  type="text"
                  data-id="wkhist2reasonforleaving"
                />
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="8">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="formLabel"
                  >PREVIOUS PRACTICE/EMPLOYER NAME
                  <input
                    class="form-control"
                    id="467"
                    name="467"
                    formControlName="467"
                    type="text"
                    data-id="wkhist3"
                  />
                </label>
              </div>
            </td>
            <td colspan="10">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="formLabel"
                  >START DATE/END DATE (MM/DD/YYYY TO MM/DD/YYYY)
                </label>
                <div class="input-group">
                  <input
                    style="width:25%;"
                    class="form-control onboard datepicker"
                    placeholder="MM/DD/YYYY"
                    ngbDatepicker
                    #d42="ngbDatepicker"
                    id="476"
                    name="476"
                    formControlName="476"
                    (blur)="clearInputIfInvalid('476')"
                    type="text"
                    data-id="wkhist3startdt"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="d42.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
                <div  *ngIf="WinterHealthcareProviderForms.get('476').invalid ">
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('476').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('476').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('476').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('476').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
                <div class="input-group">
                  <input
                    class="form-control onboard datepicker"
                    placeholder="MM/DD/YYYY"
                    ngbDatepicker
                    #d43="ngbDatepicker"
                    id="477"
                    name="477"
                    formControlName="477"
                    (blur)="clearInputIfInvalid('477')"
                    type="text"
                    data-id="wkhist3enddt"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="d43.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
                <div  *ngIf="WinterHealthcareProviderForms.get('477').invalid ">
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('477').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('477').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('477').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('477').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="18">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="formLabel">ADDRESS</label>
                <input
                  style="width: 40%;margin-bottom: 2px;"
                  class="form-control"
                  placeholder="Address 1"
                  id="468"
                  name="468"
                  formControlName="468"
                  type="text"
                  data-id="wkhist3add"
                />
                <input
                  style="width: 40%;margin-bottom: 2px;"
                  class="form-control"
                  placeholder="Address 2"
                  id="469"
                  name="469"
                  formControlName="469"
                  type="text"
                  data-id="wkhist3address2"
                />
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="18">
              <div
                class="form-group form-inline no-margin col-md-3 sideLabelAlignment"
              >
                <label class="formLabel">CITY</label>
                <input
                  style="width: 100%"
                  placeholder="City"
                  class="form-control"
                  id="470"
                  name="470"
                  formControlName="470"
                  type="text"
                  data-id="Work hist 3 city"
                />
              </div>
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="formLabel">STATE/COUNTRY</label>
                <div class="form-group selectDrop dropDrownSet">
                  <div class="col-md-12">
                    <ng-select
                      [items]="states"
                      bindLabel="name"
                      placeholder="Select States"
                      bindValue="id"
                      formControlName="472"
                    >
                    </ng-select>
                  </div>
                  
                </div>
              </div>
              <div
                class="form-group form-inline no-margin col-md-3 sideLabelAlignment"
              >
                <label class="formLabel">POSTAL CODE</label>
                <input
                  style="width: 100%"
                  class="form-control"
                  placeholder="Zip"
                  id="473"
                  name="473"
                  formControlName="473"
                  type="text"
                  data-id="Work hist 3 zip"
                />
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="18">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="formLabel">REASON FOR DISCONTINUANCE </label>
                <input
                  class="form-control"
                  id="1342"
                  name="1342"
                  formControlName="1342"
                  type="text"
                  data-id="wkhist3reasonforleaving"
                />
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="8">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="formLabel"
                  >PREVIOUS PRACTICE/EMPLOYER NAME
                  <input
                    class="form-control"
                    id="1935"
                    name="1935"
                    formControlName="1935"
                    type="text"
                    data-id="wkhist4"
                  />
                </label>
              </div>
            </td>
            <td colspan="10">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="formLabel"
                  >START DATE/END DATE (MM/DD/YYYY TO MM/DD/YYYY)
                </label>
                <div class="input-group">
                  <input
                    style="width:25%;"
                    class="form-control onboard datepicker"
                    placeholder="MM/DD/YYYY"
                    ngbDatepicker
                    #d44="ngbDatepicker"
                    id="1947"
                    name="1947"
                    formControlName="1947"
                    (blur)="clearInputIfInvalid('1947')"
                    type="text"
                    data-id="wkhist4startdt"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="d44.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
                <div  *ngIf="WinterHealthcareProviderForms.get('1947').invalid ">
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('1947').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('1947').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('1947').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('1947').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
                <div class="input-group">
                  <input
                    class="form-control onboard datepicker"
                    placeholder="MM/DD/YYYY"
                    ngbDatepicker
                    #d45="ngbDatepicker"
                    id="1948"
                    name="1948"
                    formControlName="1948"
                    (blur)="clearInputIfInvalid('1948')"
                    type="text"
                    data-id="wkhist4enddt"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="d45.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
                <div  *ngIf="WinterHealthcareProviderForms.get('1948').invalid ">
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('1948').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('1948').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('1948').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('1948').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="18">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="formLabel">ADDRESS</label>
                <input
                  style="width: 40%;margin-bottom: 2px;"
                  class="form-control"
                  placeholder="Address 1"
                  id="1936"
                  name="1936"
                  formControlName="1936"
                  type="text"
                  data-id="wkhist4add"
                />
                <input
                  style="width: 40%;margin-bottom: 2px;"
                  class="form-control"
                  placeholder="Address 2"
                  id="1937"
                  name="1937"
                  formControlName="1937"
                  type="text"
                  data-id="wkhist4address2"
                />
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="18">
              <div
                class="form-group form-inline no-margin col-md-3 sideLabelAlignment"
              >
                <label class="formLabel">CITY</label>
                <input
                  style="width: 100%"
                  placeholder="City"
                  class="form-control"
                  id="1938"
                  name="1938"
                  formControlName="1938"
                  type="text"
                  data-id="Work hist 4 city"
                />
              </div>
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="formLabel">STATE/COUNTRY</label>
                <div class="form-group selectDrop dropDrownSet">
                  <div class="col-md-12">
                    <ng-select
                      [items]="states"
                      bindLabel="name"
                      placeholder="Select States"
                      bindValue="id"
                      formControlName="1940"
                    >
                    </ng-select>
                  </div>
                </div>
              </div>
              <div
                class="form-group form-inline no-margin col-md-3 sideLabelAlignment"
              >
                <label class="formLabel">POSTAL CODE</label>
                <input
                  style="width: 100%"
                  class="form-control"
                  placeholder="Zip"
                  id="1941"
                  name="1941"
                  formControlName="1941"
                  type="text"
                  data-id="Work hist 4 zip"
                />
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="18">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="formLabel">REASON FOR DISCONTINUANCE </label>
                <input
                  class="form-control"
                  id="1952"
                  name="1952"
                  formControlName="1952"
                  type="text"
                  data-id="wkhist4reasonforleaving"
                />
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="18" width="60%">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="formLabel">
                  PLEASE PROVIDE AN EXPLANATION FOR ANY GAPS GREATER THAN SIX
                  MONTHS (MM/DD/YYYY TO MM/DD/YYYY) IN WORK HISTORY.
                </label>
              </div>
              <div
                class="form-group form-inline no-margin col-md-6 sideLabelAlignment"
              >
                <label class="formLabel">Gap Dates: </label>
                <div class="input-group">
                  <input
                    style="width: 40%"
                    class="form-control onboard datepicker"
                    placeholder="MM/DD/YYYY"
                    ngbDatepicker
                    #d46="ngbDatepicker"
                    id="3395"
                    name="3395"
                    formControlName="3395"
                    (blur)="clearInputIfInvalid('3395')"
                    type="text"
                    data-id="Gap from 1"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="d46.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
                <div  *ngIf="WinterHealthcareProviderForms.get('3395').invalid ">
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('3395').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('3395').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('3395').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('3395').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
                <div class="input-group">
                  <input
                    style="width: 53%"
                    class="form-control onboard datepicker"
                    placeholder="MM/DD/YYYY"
                    ngbDatepicker
                    #d47="ngbDatepicker"
                    id="3396"
                    name="3396"
                    formControlName="3396"
                    (blur)="clearInputIfInvalid('3396')"
                    type="text"
                    data-id="Gap to 1"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="d47.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
                <div  *ngIf="WinterHealthcareProviderForms.get('3396').invalid ">
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('3396').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('3396').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('3396').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('3396').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </div>
              <div
                class="form-group form-inline no-margin col-md-6 sideLabelAlignment"
              >
                <label class="formLabel">Explanation: </label>
                <input
                  style="width: 50%"
                  class="form-control"
                  id="1138"
                  name="1138"
                  formControlName="1138"
                  type="text"
                  data-id="Gap 1"
                />
              </div>
              <div
                class="form-group form-inline no-margin col-md-6 sideLabelAlignment"
              >
                <label class="formLabel">Gap Dates:</label>
                <div class="input-group">
                  <input
                    style="width: 40%"
                    class="form-control onboard datepicker"
                    placeholder="MM/DD/YYYY"
                    ngbDatepicker
                    #d48="ngbDatepicker"
                    id="3397"
                    name="3397"
                    formControlName="3397"
                    (blur)="clearInputIfInvalid('3397')"
                    type="text"
                    data-id="Gap from 2"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="d48.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
                <div  *ngIf="WinterHealthcareProviderForms.get('3397').invalid ">
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('3397').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('3397').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('3397').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('3397').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
                <div class="input-group">
                  <input
                    style="width: 53%"
                    class="form-control onboard datepicker"
                    placeholder="MM/DD/YYYY"
                    ngbDatepicker
                    #d49="ngbDatepicker"
                    id="3398"
                    name="3398"
                    formControlName="3398"
                    (blur)="clearInputIfInvalid('3398')"
                    type="text"
                    data-id="Gap to 2"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="d49.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
                <div  *ngIf="WinterHealthcareProviderForms.get('3398').invalid ">
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('3398').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('3398').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('3398').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('3398').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </div>
              <div
                class="form-group form-inline no-margin col-md-6 sideLabelAlignment"
              >
                <label class="formLabel"> Explanation: </label>
                <input
                  style="width: 50%"
                  class="form-control"
                  id="1831"
                  name="1831"
                  formControlName="1831"
                  type="text"
                  data-id="Gap 2"
                />
              </div>
              <div
                class="form-group form-inline no-margin col-md-6 sideLabelAlignment"
              >
                <label class="formLabel">Gap Dates:</label>
                <div class="input-group">
                  <input
                    style="width: 40%"
                    class="form-control onboard datepicker"
                    placeholder="MM/DD/YYYY"
                    ngbDatepicker
                    #d50="ngbDatepicker"
                    id="3399"
                    name="3399"
                    formControlName="3399"
                    (blur)="clearInputIfInvalid('3399')"
                    type="text"
                    data-id="Gap from 3.0"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="d50.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
                <div  *ngIf="WinterHealthcareProviderForms.get('3399').invalid ">
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('3399').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('3399').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('3399').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('3399').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
                <div class="input-group">
                  <input
                    style="width: 53%"
                    class="form-control onboard datepicker"
                    placeholder="MM/DD/YYYY"
                    ngbDatepicker
                    #d51="ngbDatepicker"
                    id="3400"
                    name="3400"
                    formControlName="3400"
                    (blur)="clearInputIfInvalid('3400')"
                    type="text"
                    data-id="Gap to 3.0"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="d51.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
                <div  *ngIf="WinterHealthcareProviderForms.get('3400').invalid ">
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('3400').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('3400').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('3400').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('3400').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </div>
              <div
                class="form-group form-inline no-margin col-md-6 sideLabelAlignment"
              >
                <label class="formLabel"> Explanation: </label>
                <input
                  style="width: 50%"
                  class="form-control"
                  id="1832"
                  name="1832"
                  formControlName="1832"
                  type="text"
                  data-id="Gap 3.0"
                />
              </div>
              <div
                class="form-group form-inline no-margin col-md-6 sideLabelAlignment"
              >
                <label class="formLabel">Gap Dates:</label>
                <div class="input-group">
                  <input
                    style="width: 40%"
                    class="form-control onboard datepicker"
                    placeholder="MM/DD/YYYY"
                    ngbDatepicker
                    #d52="ngbDatepicker"
                    id="3401"
                    name="3401"
                    formControlName="3401"
                    (blur)="clearInputIfInvalid('3401')"
                    type="text"
                    data-id="Gap from 3.1"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="d52.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
                <div  *ngIf="WinterHealthcareProviderForms.get('3401').invalid ">
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('3401').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('3401').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('3401').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('3401').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
                <div class="input-group">
                  <input
                    style="width: 53%"
                    class="form-control onboard datepicker"
                    placeholder="MM/DD/YYYY"
                    ngbDatepicker
                    #d53="ngbDatepicker"
                    id="3402"
                    name="3402"
                    formControlName="3402"
                    (blur)="clearInputIfInvalid('3402')"
                    type="text"
                    data-id="Gap to 3.1"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="d53.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
                <div  *ngIf="WinterHealthcareProviderForms.get('3402').invalid ">
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('3402').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('3402').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('3402').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('3402').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </div>
              <div
                class="form-group form-inline no-margin col-md-6 sideLabelAlignment"
              >
                <label class="formLabel"> Explanation: </label>
                <input
                  style="width: 50%"
                  class="form-control"
                  id="1953"
                  name="1953"
                  formControlName="1953"
                  type="text"
                  data-id="Gap 3.1"
                />
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="18" style="padding:10px;">
              <div class="form-check">
                <input formControlName="cmd_003"  class="form-check-input" type="checkbox" value="1" id="cmd_003">
                   <label class="form-check-label" for="cmd_003">
                            <i class="formLabel">Please check this box and complete and submit Attachment C if you have additional work history</i>
                   </label>
                       
            </div>  
            </td>
          </tr>
          
        </table>   
        <hr class="tablePersonal" />
        <table border="1" width="100%">
          <tr>
            <td colspan="15">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              ><b style="font-size:18px;">Hospital Affiliations</b>
                <i class="formLabel">-Please include all hospitals where you currently have or have
                previously had privileges.</i>
              </div>
            </td>
            <td colspan="3">
              <div class="form-check">
                <input formControlName="cmd_NA_06" (ngModelChange)="handleNAchange('cmd_NA_06')" class="form-check-input" type="checkbox" value="1" id="cmd_NA_06">
                   <label class="form-check-label" for="cmd_NA_06">
                            <b class="texasFloat">Does Not Apply</b>
                   </label>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="9">
              <div class="form-inline no-margin col-md-12 sideLabelAlignment">
                <label class="formLabel"
                  >DO YOU HAVE HOSPITAL PRIVILEGES?
                </label>
                <label class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_059"
                    name="cmd_059"
                    formControlName="cmd_059"
                    value="1"
                  />Yes</label
                >
                <label class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_059"
                    name="cmd_059"
                    formControlName="cmd_059"
                    value="0"
                  />No</label
                >
              </div>
            </td>
            <td colspan="9">
              <div class="form-inline no-margin col-md-12 sideLabelAlignment">
                <label class="formLabel"
                  >IF YOU DO NOT HAVE ADMITTING PRIVILEGES, WHAT ADMITTING
                  ARRANGEMENTS DO YOU HAVE?
                </label>
                <input
                  class="form-control"
                  id="cmd_060"
                  name="cmd_060"
                  formControlName="cmd_060"
                  type="text"
                  data-id="hospaff1arrangements"
                />
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="9">
              <div class="form-inline no-margin col-md-12 sideLabelAlignment">
                <label class="formLabel"
                  >PRIMARY HOSPITAL WHERE YOU HAVE ADMITTING PRIVILEGES</label
                >
                <input
                  class="form-control"
                  (blur)="handleNAchange('cmd_NA_06',418)" [class.is-invalid]="WinterHealthcareProviderForms.controls['479'].invalid" 
                  id="418"
                  name="418"
                  formControlName="418"
                  type="text"
                  data-id="hospaff1nm"
                />
                <div class="mandatory"
                                  *ngIf="WinterHealthcareProviderForms.controls['418'].invalid">
                                  <small >
                                    required
                                  </small>
                </div>
              </div>
            </td>
            <td colspan="9">
              <div class="form-inline no-margin col-md-12 sideLabelAlignment">
                <label class="formLabel">START DATE (MM/DD/YYYY) </label>
                <div class="input-group">
                  <input
                    class="form-control onboard datepicker"
                    (blur)="clearInputIfInvalid('426','cmd_NA_06')"
                    (blur)="handleNAchange('cmd_NA_06',426)" [class.is-invalid]="WinterHealthcareProviderForms.controls['426'].invalid"
                    placeholder="MM/DD/YYYY"
                    ngbDatepicker
                    #d54="ngbDatepicker"
                    id="426"
                    name="426"
                    formControlName="426"
                    type="text"
                    data-id="hospaff1app"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="d54.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
                <div class="text-danger" *ngIf="WinterHealthcareProviderForms.controls['426'].invalid && (WinterHealthcareProviderForms.controls['426'].value==''|| WinterHealthcareProviderForms.controls['426'].value==null) " >
                  <small >
                  required
                  </small>
                
                </div>
                <div  *ngIf="WinterHealthcareProviderForms.get('426').invalid ">
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('426').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)                  </small>
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('426').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('426').errors?.invalidDateyear">
                    year is out of range(1900-2099)                    </small>
                    <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('426').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </div>
            </td>
          </tr> 
          <tr>
            <td colspan="18">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="checkbox-inline radioStyle">ADDRESS</label>
                <input
                  class="form-control"
                  (blur)="handleNAchange('cmd_NA_06',419)" [class.is-invalid]="WinterHealthcareProviderForms.controls['419'].invalid"
                  id="419"
                  name="419"
                  formControlName="419"
                  type="text"
                  data-id="hospaff1add1"
                />
                <div class="mandatory"
                                  *ngIf="WinterHealthcareProviderForms.controls['419'].invalid">
                                  <small >
                                    required
                                  </small>
                </div>  
                <input
                  class="form-control"
                  (blur)="handleNAchange('cmd_NA_06',420)" [class.is-invalid]="WinterHealthcareProviderForms.controls['420'].invalid"
                  id="420"
                  name="420"
                  formControlName="420"
                  type="text"
                  data-id="hospaff1add2"
                />
                <div class="mandatory"
                                  *ngIf="WinterHealthcareProviderForms.controls['420'].invalid">
                                  <small >
                                    required
                                  </small>
                </div> 
              </div>
            </td>
          </tr> 
          <tr>
            <td colspan="18">
              <div
                class="form-group form-inline no-margin col-md-3 sideLabelAlignment"
              >
                <label class="formLabel">CITY</label>
                <input
                  style="width: 100%"
                  (blur)="handleNAchange('cmd_NA_06',421)" [class.is-invalid]="WinterHealthcareProviderForms.controls['421'].invalid"
                  class="form-control"
                  placeholder="City"
                  id="421"
                  name="421"
                  formControlName="421"
                  type="text"
                  data-id="hospaff1city"
                />
                <div class="mandatory"
                    *ngIf="WinterHealthcareProviderForms.controls['421'].invalid">
                    <small >
                      required
                    </small>
                </div> 
                 
              </div>
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="formLabel">STATE/COUNTRY</label>
                <div class="form-group selectDrop dropDrownSet">
                  <div class="col-md-12">
                    <ng-select
                      [items]="states"
                      (blur)="handleNAchange('cmd_NA_06')" [class.is-invalid]="WinterHealthcareProviderForms.controls['423'].invalid"
                      bindLabel="name"
                      placeholder="Select States"
                      bindValue="id"
                      formControlName="423"
                    >
                    </ng-select>
                  </div>
                  <div class="mandatory"
                            *ngIf=" WinterHealthcareProviderForms.controls['423'].invalid">
                            <small>
                            required
                            </small>
                  </div>
                </div>
              </div>
              <div
                class="form-group form-inline no-margin col-md-3 sideLabelAlignment"
              >
                <label class="formLabel">POSTAL CODE</label>
                <input
                  style="width: 100%"
                  minlength="5"
                  maxlength="10"
                  (blur)="handleNAchange('cmd_NA_06',424)" [class.is-invalid]="WinterHealthcareProviderForms.controls['424'].invalid"
                  class="form-control"
                  placeholder="Zip"
                  id="424"
                  name="424"
                  formControlName="424"
                  type="text"
                  data-id="hospaff1zip"
                />
                
                <div class="mandatory"
                    *ngIf="WinterHealthcareProviderForms.controls['424'].invalid">
                    <small >
                      required
                    </small>
                </div> 
              </div>
            </td>
          </tr> 
          <tr>
            <td colspan="6">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="formLabel">PHONE NUMBER </label>
                <input
                  class="form-control"
                  (keypress)="numberOnly($event)"
                  (blur)="handleNAchange('cmd_NA_06',1305)" [class.is-invalid]="WinterHealthcareProviderForms.controls['1305'].invalid"
                  id="1305"
                  name="1305"
                  formControlName="1305"
                  type="text"
                  data-id="hospaff1phone"
                />
                <div class="mandatory"
                                  *ngIf="WinterHealthcareProviderForms.controls['1305'].invalid">
                                  <small >
                                    required
                                  </small>
                </div>
              </div>
            </td>
            <td colspan="6">
              <div
                class="form-group form-inline no-margin col-md-3 sideLabelAlignment"
              >
                <label class="formLabel">FAX</label>
                <input
                  class="form-control"
                  (blur)="handleNAchange('cmd_NA_06',2136)" [class.is-invalid]="WinterHealthcareProviderForms.controls['2136'].invalid"
                  id="2136"
                  name="2136"
                  formControlName="2136"
                  type="text"
                  data-id="hospaff1fax"
                />
                <div class="mandatory"
                                  *ngIf="WinterHealthcareProviderForms.controls['2136'].invalid">
                                  <small >
                                    required
                                  </small>
                </div>
              </div>
            </td>
            <td colspan="6">
              <div
                class="form-group form-inline no-margin col-md-3 sideLabelAlignment"
              >
                <label class="formLabel">E-MAIL </label>
                <input
                  class="form-control"
                  (blur)="handleNAchange('cmd_NA_06',2142)" [class.is-invalid]="WinterHealthcareProviderForms.controls['2142'].invalid"
                  id="2142"
                  name="2142"
                  formControlName="2142"
                  type="text"
                  data-id="hospaff1email"
                />
                <div class="mandatory"
                                  *ngIf="WinterHealthcareProviderForms.controls['2142'].invalid">
                                  <small >
                                    required
                                  </small>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="6">
              <div class="form-inline no-margin col-md-12 sideLabelAlignment">
                <label class="formLabel">FULL UNRESTRICTED PRIVILEGES? </label>
                <label class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_061"
                    name="cmd_061"
                    formControlName="cmd_061"
                    value="1"
                  />Yes</label
                >
                <label class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_061"
                    name="cmd_061"
                    formControlName="cmd_061"
                    value="0"
                  />No</label
                >
              </div>
            </td>
            <td colspan="6">
              <div class="form-inline no-margin col-md-12 sideLabelAlignment">
                <label class="formLabel"
                  >TYPES OF PRIVILEGES (PROVISIONAL, LIMITED, CONDITIONAL, ETC.)
                </label>
                <div class="col-md-12">
                  <div class="col-md-12">
                    <ng-select
                      [items]="staffStatus"
                      (blur)="handleNAchange('cmd_NA_06')" [class.is-invalid]="WinterHealthcareProviderForms.controls['894'].invalid"
                      bindLabel="name"
                      placeholder="Select Status"
                      bindValue="id"
                      formControlName="894"
                    >
                    </ng-select>
                    <div class="mandatory"
                                       *ngIf=" WinterHealthcareProviderForms.controls['894'].invalid">
                                       <small>
                                       required
                                       </small>
                    </div>
                  </div>
                </div>
              </div>
            </td>
            <td colspan="6">
              <div class="form-inline no-margin col-md-12 sideLabelAlignment">
                <label class="formLabel">ARE PRIVILEGES TEMPORARY? </label>
                <label class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_062"
                    name="cmd_062"
                    formControlName="cmd_062"
                    value="1"
                  />Yes</label
                >
                <label class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_062"
                    name="cmd_062"
                    formControlName="cmd_062"
                    value="0"
                  />No</label
                >
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="18">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="checkbox-inline radioStyle"
                  >OF THE TOTAL NUMBER OF ADMISSIONS TO ALL HOSPITALS IN THE
                  PAST YEAR, WHAT PERCENTAGE IS TO PRIMARY HOSPITAL?
                </label>
                <input
                  class="form-control"
                  id="cmd_063"
                  name="cmd_063"
                  formControlName="cmd_063"
                  type="text"
                  data-id="hospaff1adimissons"
                />
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="9">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="formLabel"
                  >OTHER HOSPITAL WHERE YOU HAVE PRIVILEGES
                </label>
                <input
                  class="form-control"
                  id="427"
                  name="427"
                  formControlName="427"
                  type="text"
                  data-id="hospaff2nm"
                />
              </div>
            </td>
            <td colspan="9">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="formLabel">START DATE (MM/DD/YYYY) </label>
                <div class="input-group col-md-4">
                  <input
                    class="form-control onboard datepicker"
                    placeholder="MM/DD/YYYY"
                    ngbDatepicker
                    #d55="ngbDatepicker"
                    id="435"
                    name="435"
                    formControlName="435"
                    (blur)="clearInputIfInvalid('435')"
                    type="text"
                    data-id="hospaff2app"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="d55.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
                <div  *ngIf="WinterHealthcareProviderForms.get('435').invalid ">
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('435').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('435').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('435').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('435').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="18">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="checkbox-inline radioStyle">ADDRESS</label>
                <input
                  class="form-control"
                  id="428"
                  name="428"
                  formControlName="428"
                  type="text"
                  data-id="hospaff2add.0"
                />
                <input
                  class="form-control"
                  id="429"
                  name="429"
                  formControlName="429"
                  type="text"
                  data-id="hospaff2add.1"
                />
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="18">
              <div
                class="form-group form-inline no-margin col-md-3 sideLabelAlignment"
              >
                <label class="formLabel">CITY</label>
                <input
                  style="width: 100%"
                  class="form-control"
                  id="430"
                  name="430"
                  formControlName="430"
                  type="text"
                  placeholder="City"
                  data-id="hospaff2city"
                />
              </div>
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="formLabel">STATE/COUNTRY</label>
                <div class="form-group selectDrop dropDrownSet">
                  <div class="col-md-12">
                    <ng-select
                      [items]="states"
                      bindLabel="name"
                      placeholder="Select States"
                      bindValue="id"
                      formControlName="432"
                    >
                    </ng-select>
                  </div>
                  
                </div>
              </div>
              <div
                class="form-group form-inline no-margin col-md-3 sideLabelAlignment"
              >
                <label class="formLabel">POSTAL CODE</label>
                <input
                  style="width: 100%"
                  class="form-control"
                  id="433"
                  name="433"
                  formControlName="433"
                  type="text"
                  placeholder="Zip"
                  data-id="hospaff2zip"
                />
              </div>
            </td>
          </tr>  
          <tr>
            <td colspan="6">
              <div  class="row">
                <div class="col-md-12">
                  <label class="formLabel">PHONE NUMBER </label>
                </div> 
                <div class="col-md-8"> 
                  <input
                    class="form-control"
                    (keypress)="numberOnly($event)"
                    id="1307"
                    name="1307"
                    formControlName="1307"
                    type="text"
                    data-id="hospaff2phone"
                  />
                </div>  
              </div>
            </td>
            <td colspan="6">
              <div
                class="row"
              >
                <div class="col-md-2">
                  <label class="formLabel">FAX</label>
                </div> 
                <div class="col-md-5"> 
                  <input
                    class="form-control"
                    id="2137"
                    name="2137"
                    formControlName="2137"
                    type="text"
                    data-id="hospaff2fax"
                  />
                </div>  
              </div>
            </td>
            <td colspan="6">
              <div
                class="row"
              >
                <div class="col-md-3">
                  <label class="formLabel">E-MAIL</label>
                </div>  
                <div class="col-md-7">
                  <input
                    class="form-control"
                    id="2143"
                    name="2143"
                    formControlName="2143"
                    type="text"
                    data-id="hospaff2email"
                  />
                </div>  
              </div>
            </td>
          </tr> 
          <tr>
            <td colspan="6">
              <div class="form-inline no-margin col-md-12 sideLabelAlignment">
                <label class="formLabel">FULL UNRESTRICTED PRIVILEGES? </label>
                <label class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_064"
                    name="cmd_064"
                    formControlName="cmd_064"
                    value="1"
                  />Yes</label
                >
                <label class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_064"
                    name="cmd_064"
                    formControlName="cmd_064"
                    value="0"
                  />No</label
                >
              </div>
            </td>
            <td colspan="6">
              <div class="form-inline no-margin col-md-12 sideLabelAlignment">
                <label class="formLabel"
                  >TYPES OF PRIVILEGES (PROVISIONAL, LIMITED, CONDITIONAL, ETC.)
                </label>
                <div class="col-md-8">
                  <ng-select
                    [items]="staffStatus"
                    bindLabel="name"
                    placeholder="Select Status"
                    bindValue="id"
                    formControlName="895"
                  >
                  </ng-select>
                </div>
              </div>
            </td>
            <td colspan="6">
              <div class="form-inline no-margin col-md-12 sideLabelAlignment">
                <label class="formLabel">ARE PRIVILEGES TEMPORARY? </label>
                <label class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_065"
                    name="cmd_065"
                    formControlName="cmd_065"
                    value="1"
                  />Yes</label
                >
                <label class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_065"
                    name="cmd_065"
                    formControlName="cmd_065"
                    value="0"
                  />No</label
                >
              </div>
            </td>
          </tr> 
          <tr>
            <td colspan="18">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="checkbox-inline radioStyle"
                  >OF THE TOTAL NUMBER OF ADMISSIONS TO ALL HOSPITALS IN THE
                  PAST YEAR, WHAT PERCENTAGE IS TO PRIMARY HOSPITAL?
                </label>
                <input
                  class="form-control"
                  id="cmd_066"
                  name="cmd_066"
                  formControlName="cmd_066"
                  type="text"
                  data-id="hospaff2admissons"
                />
              </div>
            </td>
          </tr> 
          <tr>
            <td colspan="18" style="padding:10px;">
                <div class="form-check">
                  <input formControlName="cmd_004"  class="form-check-input" type="checkbox" value="1" id="cmd_004">
                    <label class="form-check-label" for="cmd_004">
                              <i class="formLabel">Please check this box and complete and submit Attachment D if you have additional <u>current</u> hospital affiliations</i>
                    </label>
                        
                </div>  
            </td>
          </tr>
          <tr>
            <td colspan="10">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="formLabel"
                  >PREVIOUS HOSPITAL WHERE YOU HAVE HAD PRIVILEGES</label
                >
                <input
                  class="form-control"
                  id="1183"
                  name="1183"
                  formControlName="1183"
                  type="text"
                  data-id="Pre aff 1"
                />
              </div>
            </td>
            <td colspan="8">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="formLabel"
                  >AFFILIATION DATES (MM/DD/YYYY TO MM/DD/YYYY)
                </label>
                <div class="input-group">
                  <input
                    style="width: 30%;"
                    class="form-control onboard datepicker"
                    placeholder="MM/DD/YYYY"
                    ngbDatepicker
                    #d56="ngbDatepicker"
                    id="1191"
                    name="1191"
                    formControlName="1191"
                    (blur)="clearInputIfInvalid('1191')"
                    type="text"
                    data-id="Pre aff 1 from"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="d56.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
                <div  *ngIf="WinterHealthcareProviderForms.get('1191').invalid ">
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('1191').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('1191').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('1191').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('1191').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
                <div class="input-group">
                  <input
                    class="form-control onboard datepicker"
                    placeholder="MM/DD/YYYY"
                    ngbDatepicker
                    #d57="ngbDatepicker"
                    id="2381"
                    name="2381"
                    formControlName="2381"
                    (blur)="clearInputIfInvalid('2381')"
                    type="text"
                    data-id="Pre aff 1 to"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="d57.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
                <div  *ngIf="WinterHealthcareProviderForms.get('2381').invalid ">
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('2381').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('2381').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('2381').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('2381').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </div>
            </td>
          </tr>  
          <tr>
            <td colspan="18">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="checkbox-inline radioStyle">ADDRESS</label>
                <input
                  class="form-control"
                  id="1184"
                  name="1184"
                  formControlName="1184"
                  type="text"
                  data-id="Pre aff 1 add1"
                />
                <input
                  class="form-control"
                  id="1185"
                  name="1185"
                  formControlName="1185"
                  type="text"
                  data-id="Pre aff 1 add2"
                />
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="18">
              <div
                class="form-group form-inline no-margin col-md-3 sideLabelAlignment"
              >
                <label class="formLabel">CITY</label>
                <input
                  style="width: 100%"
                  class="form-control"
                  id="1186"
                  name="1186"
                  formControlName="1186"
                  type="text"
                  placeholder="City"
                  data-id="Pre aff 1 city"
                />
              </div>
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="formLabel">STATE/COUNTRY</label>
                <div class="form-group selectDrop dropDrownSet">
                  <ng-select
                    [items]="states"
                    bindLabel="name"
                    placeholder="Select States"
                    bindValue="id"
                    formControlName="1188"
                  >
                  </ng-select> 
                </div>
              </div>
              <div
                class="form-group form-inline no-margin col-md-3 sideLabelAlignment"
              >
                <label class="formLabel">POSTAL CODE</label>
                <input
                  style="width: 100%"
                  class="form-control"
                  id="1189"
                  name="1189"
                  formControlName="1189"
                  type="text"
                  placeholder="Zip"
                  data-id="Pre aff 1 zip"
                />
              </div>
            </td>
          </tr> 
          <tr>
            <td colspan="6">
              <div class="form-inline no-margin col-md-12 sideLabelAlignment">
                <label class="formLabel">FULL UNRESTRICTED PRIVILEGES? </label>
                <label class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_068"
                    name="cmd_068"
                    formControlName="cmd_068"
                    value="1"
                  />Yes</label
                >
                <label class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_068"
                    name="cmd_068"
                    formControlName="cmd_068"
                    value="0"
                  />No</label
                >
              </div>
            </td>
            <td colspan="6">
              <div class="form-inline no-margin col-md-12 sideLabelAlignment">
                <label class="formLabel"
                  >TYPES OF PRIVILEGES (PROVISIONAL, LIMITED, CONDITIONAL, ETC.)
                </label>
                <div class="col-md-8">
                  <ng-select
                    [items]="staffStatus"
                    bindLabel="name"
                    placeholder="Select Status"
                    bindValue="id"
                    formControlName="1192"
                  >
                  </ng-select>
                </div>
              </div>
            </td>
            <td colspan="6">
              <div class="form-inline no-margin col-md-12 sideLabelAlignment">
                <label class="formLabel">WERE PRIVILEGES TEMPORARY? </label>
                <label class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_069"
                    name="cmd_069"
                    formControlName="cmd_069"
                    value="1"
                  />Yes</label
                >
                <label class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_069"
                    name="cmd_069"
                    formControlName="cmd_069"
                    value="0"
                  />No</label
                >
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="18">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="formLabel">REASON FOR DISCONTINUANCE </label>
                <input
                  class="form-control"
                  id="1303"
                  name="1303"
                  formControlName="1303"
                  type="text"
                  data-id="Pre aff 1 leaving"
                />
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="18" style="padding:10px;">
                <div class="form-check">
                  <input formControlName="cmd_005"  class="form-check-input" type="checkbox" value="1" id="cmd_005">
                    <label class="form-check-label" for="cmd_005">
                              <i class="formLabel">Please check this box and complete and submit Attachment E if you have additional <u>previous</u>  hospital affiliations.</i>
                    </label>
                        
                </div>  
            </td>
          </tr>
        </table>  
        <hr class="tablePersonal" />
      
      <table border="1" width="100%">
        <tr>
          <td colspan="15">
            <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
              <b style="font-size:18px;">References</b>
              <i class="formLabel">-Please provide three peer references from the same field and/or specialty who are not partners in your own group practice and are not relatives. All peer references should have firsthand knowledge of your abilities.</i>
            </div>
           
          </td>
          <td colspan="3" style="padding-left:50px;">
            <div class="form-check">
              <input formControlName="cmd_NA_07" (ngModelChange)="handleNAchange('cmd_NA_07')" class="form-check-input" type="checkbox" value="1" id="cmd_NA_07">
                 <label class="form-check-label" for="cmd_NA_07">
                          <b class="texasFloat">Does Not Apply</b>
                 </label>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="6">
            <div class="row">
              <div class="col-md-3">
                <label class="formLabel">1.NAME/TITLE</label>
              </div>  
              <div class="col-md-9">
                <input
                  class="form-control"
                  (blur)="handleNAchange('cmd_NA_07',359)" [class.is-invalid]="WinterHealthcareProviderForms.controls['359'].invalid"
                  id="359"
                  name="359"
                  formControlName="359"
                  type="text"
                  data-id="reference1name"
                />
                <div class="mandatory"
                                    *ngIf="WinterHealthcareProviderForms.controls['359'].invalid">
                                    <small >
                                      required
                                    </small>
                </div> 
              </div>  
            </div>
              <div class="row">
                <div class="col-md-3">
                  <label class="formLabel">E-MAIL</label>
                </div> 
                <div class="col-md-9">
                  <input
                    
                    (blur)="handleNAchange('cmd_NA_07',371)" [class.is-invalid]="WinterHealthcareProviderForms.controls['371'].invalid"
                    class="form-control"
                    id="371"
                    name="371"
                    formControlName="371"
                    type="text"
                    data-id="reference1email"
                  />
                  <div class="mandatory"
                    *ngIf="WinterHealthcareProviderForms.controls['371'].invalid">
                    <small >
                      required
                    </small>
                  </div>
                </div>      
              </div>
          </td>
          <td colspan="6">
            <div class="row">
              <div class="col-md-8">
                  <label class="formLabel"
                    >PHONE NUMBER</label>
              </div>    
              <div class="col-md-12"> 
                <input
                  class="form-control"
                  (keypress)="numberOnly($event)"
                  (blur)="handleNAchange('cmd_NA_07',369)" [class.is-invalid]="WinterHealthcareProviderForms.controls['369'].invalid"
                  id="369"
                  name="369"
                  formControlName="369"
                  type="text"
                  data-id="reference1telephone"
                />
                <div class="mandatory"
                                  *ngIf="WinterHealthcareProviderForms.controls['369'].invalid">
                                  <small >
                                    required
                                  </small>
                </div> 
              </div>
            </div>
          </td>
          <td colspan="6">
            <div class="row">
              <div class="col-md-3">
                  <label class="formLabel"
                    >FAX NUMBER</label>
              </div>  
              <div class="col-md-8">    
                    <input
                      class="form-control"
                      id="370"
                      (blur)="handleNAchange('cmd_NA_07',370)" [class.is-invalid]="WinterHealthcareProviderForms.controls['370'].invalid"
                      name="370"
                      formControlName="370"
                      type="text"
                      data-id="reference1fax"
                    />
                    <div class="mandatory"
                                      *ngIf="WinterHealthcareProviderForms.controls['370'].invalid">
                                      <small >
                                        required
                                      </small>
                    </div>
              </div>       
                  
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="18">
            <div
              class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
            >
              <label class="checkbox-inline radioStyle">ADDRESS</label>
              <input
                style="width: 40%;"
                (blur)="handleNAchange('cmd_NA_07',360)" [class.is-invalid]="WinterHealthcareProviderForms.controls['360'].invalid"
                class="form-control"
                id="360"
                name="360"
                formControlName="360"
                type="text"
                data-id="reference1address"
              />
              <div class="mandatory"
                                  *ngIf="WinterHealthcareProviderForms.controls['360'].invalid">
                                  <small >
                                    required
                                  </small>
              </div> 
              <input
                style="width: 40%;"
                class="form-control"
                id="361"
                name="361"
                formControlName="361"
                type="text"
                data-id="reference1address2"
              />
              
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="18">
            <div
              class="form-group form-inline no-margin col-md-3 sideLabelAlignment"
            >
              <label class="formLabel">CITY</label>
              <input
                style="width: 100%"
                (blur)="handleNAchange('cmd_NA_07',362)" [class.is-invalid]="WinterHealthcareProviderForms.controls['362'].invalid"
                class="form-control"
                id="362"
                name="362"
                formControlName="362"
                type="text"
                data-id="reference1city"
              />
              <div class="mandatory"
                                  *ngIf="WinterHealthcareProviderForms.controls['362'].invalid">
                                  <small >
                                    required
                                  </small>
              </div>
            </div>
            <div
              class="form-group form-inline no-margin col-md-3 sideLabelAlignment"
            >
              <label class="formLabel">STATE/COUNTRY</label>
              <div class="col-md-12">
                <ng-select
                  [items]="states"
                  (blur)="handleNAchange('cmd_NA_07')" [class.is-invalid]="WinterHealthcareProviderForms.controls['364'].invalid"
                  bindLabel="name"
                  placeholder="Select States"
                  bindValue="id"
                  formControlName="364"
                >
                </ng-select>
                <div class="mandatory"
                                  *ngIf="WinterHealthcareProviderForms.controls['364'].invalid">
                                  <small >
                                    required
                                  </small>
                </div> 
              </div>
            </div>
            <div
              class="form-group form-inline no-margin col-md-3 sideLabelAlignment"
            >
              <label class="formLabel">POSTAL CODE</label>
              <input
                style="width: 100%"
                minlength="5"
                maxlength="10"
                (blur)="handleNAchange('cmd_NA_07',365)" [class.is-invalid]="WinterHealthcareProviderForms.controls['365'].invalid"
                class="form-control"
                id="365"
                name="365"
                formControlName="365"
                type="text"
                data-id="reference1zip"
              />
              <div class="mandatory"
                                    *ngIf="WinterHealthcareProviderForms.controls['365'].invalid || WinterHealthcareProviderForms.controls['451'].errors?.minlength ||
                                    WinterHealthcareProviderForms.controls['365'].errors?.maxlength">
                                    <small >
                                      required
                                    </small>
              </div> 
              <!-- <div
                class="formLabel col-md-2"
                *ngIf="
                  WinterHealthcareProviderForms.controls['365'].touched &&
                  WinterHealthcareProviderForms.controls['365'].errors
                "
              >
                <small
                  class="text-danger"
                  *ngIf="WinterHealthcareProviderForms.controls['365'].errors"
                >
                  required
                </small>
                <small
                  class="text-danger"
                  *ngIf="
                    WinterHealthcareProviderForms.controls['365'].errors?.minlength ||
                    WinterHealthcareProviderForms.controls['365'].errors?.maxlength
                  "
                >
                  Zip code must be atleast 5 digits and less than 10 digits
                </small>
              </div> -->
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="6">
            <div
              class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
            >
              <label class="formLabel">2. NAME/TITLE</label>
              <input
                class="form-control"
                id="372"
                name="372"
                formControlName="372"
                type="text"
                data-id="reference2name"
              />
              
              <label class="formLabel"
                >E-MAIL
                <input
                  class="form-control"
                  id="383"
                  name="383"
                  formControlName="383"
                  type="text"
                  data-id="Text8.1.0"
                />
                 
              </label>
            </div>
          </td>
          <td colspan="6">
            <div class="form-group form-inline no-margin col-md-12 textInPro">
              <label class="formLabel"
                >PHONE NUMBER
                <input
                  class="form-control"
                  (keypress)="numberOnly($event)"
                  
                  id="381"
                  name="381"
                  formControlName="381"
                  type="text"
                  data-id="reference2telephone"
                />
              
              </label>
            </div>
          </td>
          <td colspan="6">
            <div class="form-group form-inline no-margin col-md-12 textInPro">
              <label class="formLabel"
                >FAX NUMBER
                <input
                  style="margin-left: 65px"
                  class="form-control"
                  id="382"
                  name="382"
                  formControlName="382"
                  type="text"
                  data-id="Text8.1.1"
                />
              </label>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="18">
            <div
              class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
            >
              <label class="checkbox-inline radioStyle">ADDRESS</label>
              <input
                class="form-control"
                id="373"
                name="373"
                formControlName="373"
                type="text"
                data-id="reference2address"
              />
              <input
                class="form-control"
                id="374"
                name="374"
                formControlName="374"
                type="text"
                data-id="reference2address2"
              />
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="18">
            <div
              class="form-group form-inline no-margin col-md-3 sideLabelAlignment"
            >
              <label class="formLabel">CITY</label>
              <input
                style="width: 100%"
                class="form-control"
                id="375"
                name="375"
                formControlName="375"
                type="text"
                data-id="reference2city"
              />
            </div>
            <div
              class="form-group form-inline no-margin col-md-6 sideLabelAlignment"
            >
              <label class="formLabel">STATE/COUNTRY</label>
              
                <div class="col-md-6">
                  <ng-select
                    [items]="states"
                    bindLabel="name"
                    placeholder="Select States"
                    bindValue="id"
                    formControlName="377"
                  >
                  </ng-select>
                </div>
              
            </div>
            <div
              class="form-group form-inline no-margin col-md-3 sideLabelAlignment"
            >
              <label class="formLabel">POSTAL CODE</label>
              <input
                style="width: 100%"
                class="form-control"
                id="378"
                name="378"
                formControlName="378"
                type="text"
                data-id="reference2zip"
              />
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="6">
            <div
              class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
            >
              <label class="formLabel">3. NAME/TITLE</label>
              <input
                class="form-control"
                id="384"
                name="384"
                formControlName="384"
                type="text"
                data-id="reference3name"
              />
              
              <label class="formLabel"
                >E-MAIL
                <input
                  class="form-control"
                  
                  id="396"
                  name="396"
                  formControlName="396"
                  type="text"
                  data-id="Text8.2.0"
                />
                
              </label>
            </div>
          </td>
          <td colspan="6">
            <div class="form-group form-inline no-margin col-md-12 textInPro">
              <label class="formLabel"
                >PHONE NUMBER
                <input
                  class="form-control"
                  (keypress)="numberOnly($event)"
                  
                  id="394"
                  name="394"
                  formControlName="394"
                  type="text"
                  data-id="reference3telephone"
                />
                
              </label>
            </div>
          </td>
          <td colspan="6">
            <div class="form-group form-inline no-margin col-md-12 textInPro">
              <label class="formLabel"
                >FAX NUMBER
                <input
                  style="margin-left : 65px"
                  class="form-control"
                  id="395"
                  name="395"
                  formControlName="395"
                  type="text"
                  data-id="Text8.2.1"
                />
              </label>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="18">
            <div
              class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
            >
              <label class="checkbox-inline radioStyle">ADDRESS</label>
              <input
                class="form-control"
                id="385"
                name="385"
                formControlName="385"
                type="text"
                data-id="reference3add"
              />
              <input
                class="form-control"
                id="386"
                name="386"
                formControlName="386"
                type="text"
                data-id="reference3spec"
              />
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="18">
            <div
              class="form-group form-inline no-margin col-md-3 sideLabelAlignment"
            >
              <label class="formLabel">CITY</label>
              <input
                style="width: 100%"
                class="form-control"
                id="387"
                name="387"
                formControlName="387"
                type="text"
                data-id="reference3city"
              />
            </div>
            <div
              class="form-group form-inline no-margin col-md-3 sideLabelAlignment"
            >
              <label class="formLabel">STATE/COUNTRY</label>
              <div class="col-md-12">
                <ng-select
                  [items]="states"
                  bindLabel="name"
                  placeholder="Select States"
                  bindValue="id"
                  formControlName="389"
                >
                </ng-select>
              </div>
            </div>
            <div
              class="form-group form-inline no-margin col-md-3 sideLabelAlignment"
            >
              <label class="formLabel">POSTAL CODE</label>
              <input
                style="width: 100%"
                class="form-control"
                id="390"
                name="390"
                formControlName="390"
                type="text"
                data-id="reference3zip"
              />
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="15">
            <div
              class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
            >
            <b style="font-size:18px;">Professional Liability Insurance Coverage</b> 
              
            </div>
          </td>
          <td colspan="3">
            <div class="form-check">
              <input formControlName="cmd_NA_08" (ngModelChange)="handleNAchange('cmd_NA_08')" class="form-check-input" type="checkbox" value="1" id="cmd_NA_08">
                 <label class="form-check-label" for="cmd_NA_08">
                          <b class="texasFloat">Does Not Apply</b>
                 </label>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="6" width="21%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              SELF-INSURED?<label class="checkbox-inline radioStyle">
                <input
                  type="radio"
                  id="cmd_071"
                  name="cmd_071"
                  formControlName="cmd_071"
                  value="1"
                />Yes</label
              >
              <label class="checkbox-inline radioStyle">
                <input
                  type="radio"
                  id="cmd_071"
                  name="cmd_071"
                  formControlName="cmd_071"
                  value="0"
                />No</label
              >
            </div>
          </td>
          <td colspan="12" width="79%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel"
                >NAME OF CURRENT MALPRACTICE INSURANCE CARRIER OR SELF-INSURED
                ENTITY
              </label>
              <input
                class="form-control"
                (blur)="handleNAchange('cmd_NA_08',345)" [class.is-invalid]="WinterHealthcareProviderForms.controls['345'].invalid"
                id="345"
                name="345"
                formControlName="345"
                type="text"
                data-id="mlp1carrier"
              />
              <div class="mandatory"
                                  *ngIf="WinterHealthcareProviderForms.controls['345'].invalid">
                                  <small >
                                    required
                                  </small>
              </div> 
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="18" width="79%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel">ADDRESS </label>
              <input
                class="form-control"
                (blur)="handleNAchange('cmd_NA_08',901)" [class.is-invalid]="WinterHealthcareProviderForms.controls['901'].invalid"
                id="901"
                name="901"
                formControlName="901"
                type="text"
                data-id="MLP Mailing Add1"
              />
              <div class="mandatory"
                                  *ngIf="WinterHealthcareProviderForms.controls['901'].invalid">
                                  <small >
                                    required
                                  </small>
              </div> 
              <input
                class="form-control"
                id="902"
                name="902"
                formControlName="902"
                type="text"
                data-id="MLP Mailing Add2"
              />
              
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="18">
            <div
              class="form-group form-inline no-margin col-md-3 sideLabelAlignment"
            >
              <label class="formLabel">CITY</label>
              <input
                style="width: 100%"
                (blur)="handleNAchange('cmd_NA_08',903)" [class.is-invalid]="WinterHealthcareProviderForms.controls['903'].invalid"
                class="form-control"
                id="903"
                name="903"
                formControlName="903"
                type="text"
                data-id="MLP City"
              />
              <div class="mandatory"
                                  *ngIf="WinterHealthcareProviderForms.controls['903'].invalid">
                                  <small >
                                    required
                                  </small>
              </div> 
            </div>
            <div
              class="form-group form-inline no-margin col-md-3 sideLabelAlignment"
            >
              <label class="formLabel">STATE/COUNTRY</label>
              <div class="col-md-12">
                <ng-select
                  [items]="states"
                  (blur)="handleNAchange('cmd_NA_08',905)" [class.is-invalid]="WinterHealthcareProviderForms.controls['905'].invalid"
                  bindLabel="name"
                  placeholder="Select States"
                  bindValue="id"
                  formControlName="905"
                >
                </ng-select>
                <div class="mandatory"
                                  *ngIf="WinterHealthcareProviderForms.controls['905'].invalid">
                                  <small >
                                    required
                                  </small>
                </div> 
              </div>
            </div>
            <div
              class="form-group form-inline no-margin col-md-3 sideLabelAlignment"
            >
              <label class="formLabel">POSTAL CODE</label>
              <input
                style="width: 100%"
                minlength="5"
                maxlength="10"
                (blur)="handleNAchange('cmd_NA_08',906)" [class.is-invalid]="WinterHealthcareProviderForms.controls['906'].invalid"
                class="form-control"
                id="906"
                name="906"
                formControlName="906"
                type="text"
                data-id="MLP Zip"
              />
              <div class="mandatory"
                                    *ngIf="WinterHealthcareProviderForms.controls['906'].invalid || WinterHealthcareProviderForms.controls['906'].errors?.minlength ||
                                    WinterHealthcareProviderForms.controls['906'].errors?.maxlength">
                                    <small >
                                      required
                                    </small>
              </div> 
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="6">
            <div
              class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
            >
              <label class="formLabel">PHONE NUMBER </label>
              <input
                class="form-control"
                (keypress)="numberOnly($event)"
                (blur)="handleNAchange('cmd_NA_08',1266)" [class.is-invalid]="WinterHealthcareProviderForms.controls['1266'].invalid"
                id="1266"
                name="1266"
                formControlName="1266"
                type="text"
                data-id="MLP Phone"
              />
              <div class="mandatory"
                                  *ngIf="WinterHealthcareProviderForms.controls['1266'].invalid">
                                  <small >
                                    required
                                  </small>
              </div> 
            </div>
          </td>
          <td colspan="6">
            <div
              class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
            >
              <label class="formLabel">POLICY NUMBER</label>
              <input
                class="form-control"
                (blur)="handleNAchange('cmd_NA_08',346)" [class.is-invalid]="WinterHealthcareProviderForms.controls['346'].invalid"
                id="346"
                name="346"
                formControlName="346"
                type="text"
                data-id="mlp1pol"
              />
              <div class="mandatory"
                                  *ngIf="WinterHealthcareProviderForms.controls['346'].invalid">
                                  <small >
                                    required
                                  </small>
              </div> 
            </div>
          </td>
          <td colspan="6">
            <div
              class="form-group form-inline no-margin col-md-6 sideLabelAlignment"
            >
              <label class="formLabel">EFFECTIVE DATE (MM/DD/YYYY) </label>
              <div class="input-group">
                <input
                  class="form-control onboard datepicker"
                  placeholder="MM/DD/YYYY"
                  ngbDatepicker
                  #d58="ngbDatepicker"
                  id="348"
                  name="348"
                  formControlName="348"
                  type="text"
                  data-id="mlp1dateeff_date"
                  (blur)="clearInputIfInvalid('348','cmd_NA_08')"
                  (blur)="handleNAchange('cmd_NA_08',348)" [class.is-invalid]="WinterHealthcareProviderForms.controls['348'].invalid"
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-outline-secondary calendar "
                    (click)="d58.toggle()"
                    type="button"
                  >
                    <i class="fas fa-calendar-alt"></i>
                  </button>
                </div>
              </div>
              <div class="text-danger" *ngIf="WinterHealthcareProviderForms.controls['348'].invalid && (WinterHealthcareProviderForms.controls['348'].value==''|| WinterHealthcareProviderForms.controls['348'].value==null) " >
                <small >
                required
                </small>
              
              </div>
              <div  *ngIf="WinterHealthcareProviderForms.get('348').invalid ">
                <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('348').errors?.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY)                  </small>
                <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('348').errors?.invalidMonth">
                Month is out of range
                </small>
                <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('348').errors?.invalidDateyear">
                  year is out of range(1900-2099)                    </small>
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('348').errors?.invalidDateRange">
                    date is out of range
                    </small>
                </div>
            </div>
            <div
              class="form-group form-inline no-margin col-md-6 sideLabelAlignment"
            >
              <label class="formLabel">EXPIRATION DATE (MM/DD/YYYY) </label>
              <div class="input-group">
                <input
                  class="form-control onboard datepicker"
                  (blur)="clearInputIfInvalid('349','cmd_NA_08')"
                  (blur)="handleNAchange('cmd_NA_08',349)" [class.is-invalid]="WinterHealthcareProviderForms.controls['349'].invalid"
                  placeholder="MM/DD/YYYY"
                  ngbDatepicker
                  #d59="ngbDatepicker"
                  id="349"
                  name="349"
                  formControlName="349"
                  type="text"
                  data-id="mlp1dateexp_date"
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-outline-secondary calendar"
                    (click)="d59.toggle()"
                    type="button"
                  >
                    <i class="fas fa-calendar-alt"></i>
                  </button>
                </div>
              </div>
              <div class="text-danger" *ngIf="WinterHealthcareProviderForms.controls['349'].invalid && (WinterHealthcareProviderForms.controls['349'].value==''|| WinterHealthcareProviderForms.controls['349'].value==null) " >
                <small >
                required
                </small>
              
              </div>
              <div  *ngIf="WinterHealthcareProviderForms.get('349').invalid ">
                <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('349').errors?.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY)                  </small>
                <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('349').errors?.invalidMonth">
                Month is out of range
                </small>
                <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('349').errors?.invalidDateyear">
                  year is out of range(1900-2099)                    </small>
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('349').errors?.invalidDateRange">
                    date is out of range
                    </small>
                </div>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="4" style="width:12%;">
            <div
              class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
            >
              <label class="formLabel"
                >AMOUNT OF COVERAGE PER OCCURRENCE
              </label>
              <div class="form-group selectDrop dropDrownSet">
                <ng-select
                  [items]="preClaim"
                  (blur)="handleNAchange('cmd_NA_08',350)" [class.is-invalid]="WinterHealthcareProviderForms.controls['350'].invalid"
                  bindLabel="name"
                  placeholder="Select Per Occurrence"
                  bindValue="id"
                  formControlName="350"
                >
                </ng-select>
                <div class="mandatory"
                                  *ngIf="WinterHealthcareProviderForms.controls['350'].invalid">
                                  <small >
                                    required
                                  </small>
              </div> 
              </div>
            </div>
          </td>
          <td colspan="4">
            <div
              class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
            >
              <label class="formLabel">AMOUNT OF COVERAGE AGGREGATE</label>
              <div class="form-group selectDrop dropDrownSet">
                <ng-select
                  [items]="aggregates"
                  (blur)="handleNAchange('cmd_NA_08')" [class.is-invalid]="WinterHealthcareProviderForms.controls['351'].invalid"
                  bindLabel="name"
                  placeholder="Select Aggregate"
                  bindValue="id"
                  formControlName="351"
                >
                </ng-select>
                <div class="mandatory"
                                  *ngIf="WinterHealthcareProviderForms.controls['351'].invalid">
                                  <small >
                                    required
                                  </small>
                </div>
              </div>
            </div>
          </td>
          <td colspan="6">
            <div
              class="col-md-12"
            >
              <label>
                TYPE OF COVERAGE
              </label>
            </div>
            <div
              class="col-md-12"
            > <label class="checkbox-inline radioStyle">
                <input
                  type="radio"
                  id="cmd_072"
                  name="cmd_072"
                  formControlName="cmd_072"
                  value="1"
                />Individual</label>
            </div>
            <div
              class="col-md-12">
              <label class="checkbox-inline radioStyle">
                
                <input
                  type="radio"
                  id="cmd_072"
                  name="cmd_072"
                  formControlName="cmd_072"
                  value="0"
                />Shared</label
              >
            </div>
          </td>
          <td colspan="4">
            <div
              class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
            >
              <label class="formLabel">LENGTH OF TIME WITH CARRIER</label>
              <input
                class="form-control"
                id="cmd_073"
                name="cmd_073"
                formControlName="cmd_073"
                type="text"
                data-id="MLP length of time"
              />
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="18">
            <div
              class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
            >
              <label class="formLabel"
                ><b>PREVIOUS MALPRACTICE INSURANCE </b></label
              >
            </div>
          </td>
          
        </tr>
        <tr>
          <td colspan="18" width="79%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel"
                >NAME OF PREVIOUS MALPRACTICE INSURANCE CARRIER IF WITH
                CURRENT CARRIER LESS THAN 5 YEARS
              </label>
              <input
                class="form-control"
                id="1242"
                
                name="1242"
                formControlName="1242"
                type="text"
                data-id="prevmlp1carrier"
              />
              
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="18" width="79%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel">ADDRESS </label>
              <input
                class="form-control"
                id="1244"
                
                name="1244"
                formControlName="1244"
                type="text"
                data-id="prevMLP Mailing Add1"
              />
              
              <input
                class="form-control"
                
                id="1245"
                name="1245"
                formControlName="1245"
                type="text"
                data-id="prevMLP Mailing Add2"
              />
              
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="18">
            <div
              class="form-group form-inline no-margin col-md-3 sideLabelAlignment"
            >
              <label class="formLabel">CITY</label>
              <input
                style="width: 100%"
                
                class="form-control"
                id="1246"
                name="1246"
                formControlName="1246"
                type="text"
                data-id="prevMLP City"
              />
              
            </div>
            <div
              class="form-group form-inline no-margin col-md-3 sideLabelAlignment"
            >
              <label class="formLabel">STATE/COUNTRY</label>
              <div class="form-group selectDrop dropDrownSet">
                <div class="col-md-12">
                  <ng-select
                    [items]="states"
                    
                    bindLabel="name"
                    placeholder="Select States"
                    bindValue="id"
                    formControlName="1248"
                  >
                  </ng-select>
                </div>
                
              </div>
            </div>
            <div
              class="form-group form-inline no-margin col-md-3 sideLabelAlignment"
            >
              <label class="formLabel">POSTAL CODE</label>
              <input
                style="width: 100%"
                minlength="5"
                maxlength="10"
                
                class="form-control"
                id="1249"
                name="1249"
                formControlName="1249"
                type="text"
                data-id="prevMLP Zip"
              />
              
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="4">
            <div
              class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
            >
              <label class="formLabel">PHONE NUMBER </label>
              <input
                class="form-control"
                (keypress)="numberOnly($event)"
                id="1254"
                name="1254"
                formControlName="1254"
                type="text"
                data-id="prevMLP Phone"
              />
            </div>
          </td>
          <td colspan="4">
            <div
              class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
            >
              <label class="formLabel">POLICY NUMBER</label>
              <input
                class="form-control"
                
                id="1256"
                name="1256"
                formControlName="1256"
                type="text"
                data-id="prevmlp1pol"
              />
              
            </div>
          </td>
          <td colspan="10">
            <div
              class="form-group form-inline no-margin col-md-6 sideLabelAlignment"
            >
              <label class="formLabel">EFFECTIVE DATE (MM/DD/YYYY) </label>
              <div class="input-group">
                <input
                  class="form-control onboard datepicker"
                  
                  placeholder="MM/DD/YYYY"
                  ngbDatepicker
                  #d60="ngbDatepicker"
                  id="1252"
                  name="1252"
                  formControlName="1252"
                  (blur)="clearInputIfInvalid('1252')"
                  type="text"
                  data-id="prevmlp1dateeff_date"
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-outline-secondary calendar"
                    (click)="d60.toggle()"
                    type="button"
                  >
                    <i class="fas fa-calendar-alt"></i>
                  </button>
                </div>
              </div>
              <div  *ngIf="WinterHealthcareProviderForms.get('1252').invalid ">
                <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('1252').errors?.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY)
                </small>
                <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('1252').errors?.invalidMonth">
                Month is out of range
                </small>
                <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('1252').errors?.invalidDateyear">
                  year is out of range(1900-2099)                      </small>
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('1252').errors?.invalidDateRange">
                    date is out of range
                    </small>
                </div>
              
            </div>
            <div
              class="form-group form-inline no-margin col-md-6 sideLabelAlignment"
            >
              <label class="formLabel">EXPIRATION DATE (MM/DD/YYYY) </label>
              <div class="input-group">
                <input
                  class="form-control onboard datepicker"
                  
                  placeholder="MM/DD/YYYY"
                  ngbDatepicker
                  #d61="ngbDatepicker"
                  id="1253"
                  name="1253"
                  formControlName="1253"
                  (blur)="clearInputIfInvalid('1253')"
                  type="text"
                  data-id="prevmlp1dateexp_date"
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-outline-secondary calendar"
                    (click)="d61.toggle()"
                    type="button"
                  >
                    <i class="fas fa-calendar-alt"></i>
                  </button>
                </div>
              </div>
              <div  *ngIf="WinterHealthcareProviderForms.get('1253').invalid ">
                <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('1253').errors?.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY)
                </small>
                <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('1253').errors?.invalidMonth">
                Month is out of range
                </small>
                <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('1253').errors?.invalidDateyear">
                  year is out of range(1900-2099)                      </small>
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('1253').errors?.invalidDateRange">
                    date is out of range
                    </small>
                </div>
             
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="4" style="width:12%;">
            <div
              class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
            >
              <label class="formLabel"
                >AMOUNT OF COVERAGE PER OCCURRENCE
              </label>
              <div class="form-group selectDrop dropDrownSet">
                <ng-select
                  [items]="preClaim"
                  
                  bindLabel="name"
                  placeholder="Select Per Occurrence"
                  bindValue="id"
                  formControlName="1264"
                >
                </ng-select>
                
              </div>
            </div>
          </td>
          <td colspan="4">
            <div
              class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
            >
              <label class="formLabel">AMOUNT OF COVERAGE AGGREGATE</label>
              <div class="form-group selectDrop dropDrownSet">
                <ng-select
                  [items]="aggregates"
                  
                  bindLabel="name"
                  placeholder="Select Aggregate"
                  bindValue="id"
                  formControlName="1265"
                >
                </ng-select>
                 
              </div>
            </div>
          </td>
          <td colspan="6">
            <div
              class="col-md-12"
            >
              <label>
                TYPE OF COVERAGE
              </label>
            </div>
            <div
              class="col-md-12"
            > <label class="checkbox-inline radioStyle">
                <input
                  type="radio"
                  id="cmd_074"
                  name="cmd_074"
                  formControlName="cmd_074"
                  value="1"
                />Individual</label>
            </div>
            <div
              class="col-md-12">
              <label class="checkbox-inline radioStyle">
                
                <input
                  type="radio"
                  id="cmd_074"
                  name="cmd_074"
                  formControlName="cmd_074"
                  value="0"
                />Shared</label
              >
            </div>
          </td>
          <td colspan="4">
            <div
              class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
            >
              <label class="formLabel">LENGTH OF TIME WITH CARRIER</label>
              <input
                class="form-control"
                id="cmd_075"
                name="cmd_075"
                formControlName="cmd_075"
                type="text"
                data-id="prevMLP length of time"
              />
            </div>
          
        </tr>
        <tr>
          <td colspan="10" style="padding:10px">
            <b style="font-size:18px;">Malpractice Claims History</b>

          </td>
          <td colspan="8">
            <div class="col-md-12">
              <div class="form-check">
                  <input formControlName="cmd_NA_014" (ngModelChange)="handleNAchange('cmd_NA_014')" class="form-check-input" type="checkbox" value="1" id="cmd_NA_014">
                      <label class="form-check-label" for="cmd_NA_014">
                                  <b>Does Not Apply</b>
                      </label>
                      
              </div> 
          </div>    
          </td>
        </tr>
        <tr>
          <td colspan="9">
              <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="normalParagraph">INCIDENT DATE (MM/DD/YYYY) </label>
                  <div class="input-group">
                    <input  class="form-control onboard datepicker" ngbDatepicker #d1208="ngbDatepicker" (blur)="clearInputIfInvalid('1208','cmd_NA_014')"  (blur)="handleNAchange('cmd_NA_014',1208)" [class.is-invalid]="WinterHealthcareProviderForms.controls['1208'].invalid" placeholder="MM/DD/YYYY" id="1208" name="1208" formControlName="1208" type="text" data-id="pending date">
                    <div class="input-group-append">
                      <button class="btn btn-outline-secondary calendar" (click)="d1208.toggle()" type="button">
                      <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>  
                  <div class="text-danger" *ngIf="WinterHealthcareProviderForms.controls['1208'].invalid && (WinterHealthcareProviderForms.controls['1208'].value==''|| WinterHealthcareProviderForms.controls['1208'].value==null) " >
                    <small >
                    required
                    </small>
                  
                  </div>
                  <div  *ngIf="WinterHealthcareProviderForms.get('1208').invalid ">
                    <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('1208').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)                  </small>
                    <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('1208').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('1208').errors?.invalidDateyear">
                      year is out of range(1900-2099)                    </small>
                      <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('1208').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
              </div>
          </td>
          <td colspan="6">
              <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="normalParagraph"> DATE CLAIM WAS FILED (MM/DD/YYYY) </label>
                  <div class="input-group">
                    <input  class="form-control onboard datepicker" ngbDatepicker #d1209="ngbDatepicker"      (blur)="clearInputIfInvalid('1209','cmd_NA_014')" (blur)="handleNAchange('cmd_NA_014',1209)" [class.is-invalid]="WinterHealthcareProviderForms.controls['1209'].invalid" placeholder="MM/DD/YYYY" id="1209" name="1209" formControlName="1209" type="text" data-id="pending date">
                    <div class="input-group-append">
                      <button class="btn btn-outline-secondary calendar" (click)="d1209.toggle()" type="button">
                      <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                 
                  </div>  
                  <div class="text-danger" *ngIf="WinterHealthcareProviderForms.controls['1209'].invalid && (WinterHealthcareProviderForms.controls['1209'].value==''|| WinterHealthcareProviderForms.controls['1209'].value==null) " >
                    <small >
                    required
                    </small>
                  
                  </div>
                  <div  *ngIf="WinterHealthcareProviderForms.get('1209').invalid ">
                    <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('1209').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)                  </small>
                    <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('1209').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('1209').errors?.invalidDateyear">
                      year is out of range(1900-2099)                    </small>
                      <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('1209').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
              </div>
          </td>
          <td colspan="6">
              <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="normalParagraph">CLAIM/CASE STATUS  </label>
                  <input class="form-control onboard" id="1858" name="1858" (blur)="handleNAchange('cmd_NA_014',1858)" [class.is-invalid]="WinterHealthcareProviderForms.controls['1858'].invalid" formControlName="1858" type="text" data-id="Role in event">
                  <div class="mandatory"
                      *ngIf="WinterHealthcareProviderForms.controls['1858'].invalid">
                      <small >
                        required
                      </small>
                  </div> 
              </div>
          </td>
        </tr>
        <tr>
            <td colspan="18">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="normalParagraph">PROFESSIONAL LIABILITY CARRIER INVOLVED </label>
                    <input  class="form-control onboard " id="1210" name="1210" formControlName="1210" type="text" (blur)="handleNAchange('cmd_NA_014',1210)" [class.is-invalid]="WinterHealthcareProviderForms.controls['1210'].invalid" data-id="Cause of harm">
                    <div class="mandatory"
                        *ngIf="WinterHealthcareProviderForms.controls['1210'].invalid">
                        <small >
                          required
                        </small>
                    </div> 
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="normalParagraph">ADDRESS </label>
                    <input  class="form-control onboard " id="2012" name="2012" formControlName="2012" type="text" data-id="Text6.4.1">
                    <input  class="form-control onboard " id="1844" name="1844" formControlName="1844" type="text" data-id="Text6.4.2">
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18">
                <div class="form-group form-inline no-margin col-md-3 sideLabelAlignment">
                    <label class="normalParagraph">CITY</label>
                    <input class="form-control onboard " id="1845" name="1845" formControlName="1845" type="text" data-id="claimcity">
                </div>
                <div class="form-group form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="normalParagraph">STATE/COUNTRY</label>
                    <div class="form-group selectDrop dropDrownSet col-md-12">
                        <ng-select [items]="states" bindLabel="name" placeholder="Select States" bindValue="id" formControlName="1847">
                        </ng-select>
                    </div>
                </div>
                <div class="form-group form-inline no-margin col-md-3 sideLabelAlignment">
                    <label class="normalParagraph">POSTAL CODE</label>
                    <input class="form-control onboard " id="1848" name="1848" formControlName="1848" type="text" data-id="claimzip">
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="6">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="normalParagraph">PHONE NUMBER</label>
                    <input class="form-control onboard " id="1849" name="1849" formControlName="1849" type="text">
                </div>
            </td>
            <td colspan="6">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="normalParagraph">POLICY NUMBER  </label>
                    <input class="form-control onboard " id="1859" name="1859" formControlName="1859"  type="text">
                </div>
            </td>
            <td colspan="6">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="normalParagraph">AMOUNT OF AWARD OR SETTLEMENT & AMOUNT PAID </label>
                    <input  class="form-control onboard " id="1229" name="1229" formControlName="1229" type="text" data-id="Text6.3.0">
                    <input class="form-control onboard " id="1228" name="1228" formControlName="1228" type="text" data-id="Text6.2.3">
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    METHOD OF RESOLUTION <br/>
                    <label class="checkbox-inline radioStyle">
                    <input type="checkbox" id="cmd_0708" name="cmd_0708" formControlName="cmd_0708" value="1">Dismissed
                    </label>
                    <label class="checkbox-inline radioStyle">
                    <input type="checkbox" id="cmd_0709" name="cmd_0709" formControlName="cmd_0709" value="1">Settled (with prejudice)
                    </label>
                    <label class="checkbox-inline radioStyle">
                    <input type="checkbox" id="cmd_0710" name="cmd_0710" formControlName="cmd_0710" value="1">Settled (without prejudice)
                    </label>
                    <label class="checkbox-inline radioStyle">
                    <input type="checkbox" id="cmd_0711" name="cmd_0711" formControlName="cmd_0711" value="1">Judgment for Defendant(s)
                    </label>
                    <label class="checkbox-inline radioStyle">
                    <input type="checkbox" id="cmd_0712" name="cmd_0712" formControlName="cmd_0712" value="1">Judgment for Plaintiff(s)
                    </label>
                    <label class="checkbox-inline radioStyle">
                    <input type="checkbox" id="cmd_0713" name="cmd_0713" formControlName="cmd_0713" value="1">Mediation or Arbitration
                    </label>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="normalParagraph">DESCRIPTION OF ALLEGATIONS</label>
                    <input  class="form-control onboard " id="1216" name="1216" formControlName="1216" type="text" data-id="Text6.1.3">
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="6">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="normalParagraph">WERE YOU PRIMARY DEFENDANT OR CO-DEFENDANT?</label>
                    <input  class="form-control onboard " id="1213" name="1213" formControlName="1213" type="text" data-id="Specifics on the claim">
                </div>
            </td>
            <td colspan="6">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="normalParagraph"> NUMBER OF OTHER CO-DEFENDANTS </label>
                    <input  class="form-control onboard " id="cmd_0716" name="cmd_0716" formControlName="cmd_0716" type="text" data-id="List other defendants">
                </div>
            </td>
            <td colspan="6">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="normalParagraph">YOUR INVOLVEMENT (ATTENDING, CONSULTING, ETC.) </label>
                    <input  class="form-control onboard " id="1218" name="1218" formControlName="1218" type="text" data-id="Role in event">
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="normalParagraph">DESCRIPTION OF ALLEGED INJURY TO THE PATIENT</label>
                    <input style="width: 100%" class="form-control onboard " id="1217" name="1217" formControlName="1217" type="text" data-id="Text6.1.2">
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    TO THE BEST OF YOUR KNOWLEDGE, IS THIS CASE INCLUDED IN THE NATIONAL PRACTITIONER DATA BANK (NPDB)? <br/>
                    <label class="checkbox-inline radioStyle">
                    <input type="radio" id="cmd_0719" name="cmd_0719" formControlName="cmd_0719" value="1">Yes
                    </label>
                    <label class="checkbox-inline radioStyle">
                    <input type="radio" id="cmd_0719" name="cmd_0719" formControlName="cmd_0719"  value="0">No
                    </label>
                </div>
            </td>
        </tr>
        <tr>
          <td colspan="9">
              <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="normalParagraph">INCIDENT DATE (MM/DD/YYYY) </label>
                  <div class="input-group">
                    <input  class="form-control onboard datepicker" ngbDatepicker #d01208="ngbDatepicker"    (blur)="clearInputIfInvalid('add_1_1208')"  placeholder="MM/DD/YYYY" id="add_1_1208" name="add_1_1208" formControlName="add_1_1208" type="text" data-id="pending date">
                    <div class="input-group-append">
                      <button class="btn btn-outline-secondary calendar" (click)="d01208.toggle()" type="button">
                      <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>  
                  <div  *ngIf="WinterHealthcareProviderForms.get('add_1_1208').invalid ">
                    <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('add_1_1208').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('add_1_1208').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('add_1_1208').errors?.invalidDateyear">
                      year is out of range(1900-2099)                      </small>
                      <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('add_1_1208').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
                 
              </div>
          </td>
          <td colspan="6">
              <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="normalParagraph"> DATE CLAIM WAS FILED (MM/DD/YYYY) </label>
                  <div class="input-group">
                    <input  class="form-control onboard datepicker" ngbDatepicker #d01209="ngbDatepicker"  placeholder="MM/DD/YYYY" id="add_1_1209"   (blur)="clearInputIfInvalid('add_1_1209')"  name="add_1_1209" formControlName="add_1_1209" type="text" data-id="pending date">
                    <div class="input-group-append">
                      <button class="btn btn-outline-secondary calendar" (click)="d01209.toggle()" type="button">
                      <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                   
                  </div>  
                  <div  *ngIf="WinterHealthcareProviderForms.get('add_1_1209').invalid ">
                    <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('add_1_1209').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('add_1_1209').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('add_1_1209').errors?.invalidDateyear">
                      year is out of range(1900-2099)                      </small>
                      <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('add_1_1209').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
              </div>
          </td>
          <td colspan="6">
              <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="normalParagraph">CLAIM/CASE STATUS  </label>
                  <input class="form-control onboard" id="add_1_1858" name="add_1_1858"  formControlName="add_1_1858" type="text" data-id="Role in event">
                  
              </div>
          </td>
        </tr>
        <tr>
            <td colspan="18">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="normalParagraph">PROFESSIONAL LIABILITY CARRIER INVOLVED </label>
                    <input  class="form-control onboard " id="add_1_1210" name="add_1_1210" formControlName="add_1_1210" type="text"  data-id="Cause of harm">
                    
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="normalParagraph">ADDRESS </label>
                    <input  class="form-control onboard " id="add_1_2012" name="add_1_2012" formControlName="add_1_2012" type="text" data-id="Text6.4.1">
                    <input  class="form-control onboard " id="add_1_1844" name="add_1_1844" formControlName="add_1_1844" type="text" data-id="Text6.4.2">
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18">
                <div class="form-group form-inline no-margin col-md-3 sideLabelAlignment">
                    <label class="normalParagraph">CITY</label>
                    <input class="form-control onboard " id="add_1_1845" name="add_1_1845" formControlName="add_1_1845" type="text" data-id="claimcity">
                </div>
                <div class="form-group form-inline no-margin col-md-3 sideLabelAlignment">
                    <label class="normalParagraph">STATE/COUNTRY</label>
                    <div class="form-group selectDrop dropDrownSet col-md-3">
                        <ng-select [items]="states" bindLabel="name" placeholder="Select States" bindValue="id" formControlName="add_1_1847">
                        </ng-select>
                    </div>
                </div>
                <div class="form-group form-inline no-margin col-md-3 sideLabelAlignment">
                    <label class="normalParagraph">POSTAL CODE</label>
                    <input class="form-control onboard " id="add_1_1848" name="add_1_1848" formControlName="add_1_1848" type="text" data-id="claimzip">
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="6">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="normalParagraph">PHONE NUMBER</label>
                    <input class="form-control onboard " id="add_1_1849" name="add_1_1849" formControlName="add_1_1849" type="text">
                </div>
            </td>
            <td colspan="6">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="normalParagraph">POLICY NUMBER  </label>
                    <input class="form-control onboard " id="add_1_1859" name="add_1_1859" formControlName="add_1_1859"  type="text">
                </div>
            </td>
            <td colspan="6">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="normalParagraph">AMOUNT OF AWARD OR SETTLEMENT & AMOUNT PAID </label>
                    <input  class="form-control onboard " id="add_1_1229" name="add_1_1229" formControlName="add_1_1229" type="text" data-id="Text6.3.0">
                    <input class="form-control onboard " id="add_1_1228" name="add_1_1228" formControlName="add_1_1228" type="text" data-id="Text6.2.3">
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    METHOD OF RESOLUTION <br/>
                    <label class="checkbox-inline radioStyle">
                    <input type="checkbox" id="cmd_0725" name="cmd_0725" formControlName="cmd_0725" value="1">Dismissed
                    </label>
                    <label class="checkbox-inline radioStyle">
                    <input type="checkbox" id="cmd_0715" name="cmd_0715" formControlName="cmd_0715" value="1">Settled (with prejudice)
                    </label>
                    <label class="checkbox-inline radioStyle">
                    <input type="checkbox" id="cmd_0720" name="cmd_0720" formControlName="cmd_0720" value="1">Settled (without prejudice)
                    </label>
                    <label class="checkbox-inline radioStyle">
                    <input type="checkbox" id="cmd_0721" name="cmd_0721" formControlName="cmd_0721" value="1">Judgment for Defendant(s)
                    </label>
                    <label class="checkbox-inline radioStyle">
                    <input type="checkbox" id="cmd_0718" name="cmd_0718" formControlName="cmd_0718" value="1">Judgment for Plaintiff(s)
                    </label>
                    <label class="checkbox-inline radioStyle">
                    <input type="checkbox" id="cmd_0724" name="cmd_0724" formControlName="cmd_0724" value="1">Mediation or Arbitration
                    </label>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="normalParagraph">DESCRIPTION OF ALLEGATIONS</label>
                    <input  class="form-control onboard " id="add_1_1216" name="add_1_1216" formControlName="add_1_1216" type="text" data-id="Text6.1.3">
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="6">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="normalParagraph">WERE YOU PRIMARY DEFENDANT OR CO-DEFENDANT?</label>
                    <input  class="form-control onboard " id="add_1_1213" name="add_1_1213" formControlName="add_1_1213" type="text" data-id="Specifics on the claim">
                </div>
            </td>
            <td colspan="6">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="normalParagraph"> NUMBER OF OTHER CO-DEFENDANTS </label>
                    <input  class="form-control onboard " id="cmd_07211" name="cmd_07211" formControlName="cmd_07211" type="text" data-id="List other defendants">
                </div>
            </td>
            <td colspan="6">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="normalParagraph">YOUR INVOLVEMENT (ATTENDING, CONSULTING, ETC.) </label>
                    <input  class="form-control onboard " id="add_1_1218" name="add_1_1218" formControlName="add_1_1218" type="text" data-id="Role in event">
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="normalParagraph">DESCRIPTION OF ALLEGED INJURY TO THE PATIENT</label>
                    <input style="width: 100%" class="form-control onboard " id="add_1_1217" name="add_1_1217" formControlName="add_1_1217" type="text" data-id="Text6.1.2">
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="18">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                    TO THE BEST OF YOUR KNOWLEDGE, IS THIS CASE INCLUDED IN THE NATIONAL PRACTITIONER DATA BANK (NPDB)? <br/>
                    <label class="checkbox-inline radioStyle">
                    <input type="radio" id="cmd_0722" name="cmd_0722" formControlName="cmd_0722" value="1">Yes
                    </label>
                    <label class="checkbox-inline radioStyle">
                    <input type="radio" id="cmd_0722" name="cmd_0722" formControlName="cmd_0722"  value="0">No
                    </label>
                </div>
            </td>
        </tr>
      </table>
      <hr class="tablePersonal" />
      
      <table border="1" width="100%">
        <tr>
          <td colspan="18">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <b style="font-size:18px;">Section II-Disclosure Questions</b>
              <label
                >- Please provide an explanation for any question answered
                yes-except 16-on page 10. Licensure
              </label>
              <br />
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="2" width="5%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel">1</label>
            </div>
          </td>
          <td colspan="12" width="75%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel"
                >Has your license to practice, in your profession, ever been
                denied, suspended, revoked, restricted,voluntarily surrendered
                while under investigation, or have you ever been subject to a
                consent order,probation or any conditions or limitations by
                any state licensing board?</label
              >
            </div>
          </td>
          <td colspan="4" width="20%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="radio-inline radioStyle">
                <input
                  type="radio"
                  [class.is-invalid]="
                    (WinterHealthcareProviderForms.controls['cmd_0232'].touched &&
                      WinterHealthcareProviderForms.controls['cmd_0232'].errors) ||
                    (WinterHealthcareProviderForms.controls['cmd_0232'].invalid && isSubmit)
                  "
                  id="cmd_0232"
                  name="cmd_0232"
                  formControlName="cmd_0232"
                  value="1"
                  (change)="validationsDataAdd($event)"
                />Yes</label
              >
              <label class="radio-inline radioStyle">
                <input
                  type="radio"
                  [class.is-invalid]="
                    (WinterHealthcareProviderForms.controls['cmd_0232'].touched &&
                      WinterHealthcareProviderForms.controls['cmd_0232'].errors) ||
                    (WinterHealthcareProviderForms.controls['cmd_0232'].invalid && isSubmit)
                  "
                  id="cmd_0232"
                  name="cmd_0232"
                  formControlName="cmd_0232"
                  value="0"
                  (change)="validationsDataAdd($event)"
                />No</label
              >
              <div
                class="formLabel col-md-2"
                *ngIf="
                  (WinterHealthcareProviderForms.controls['cmd_0232'].touched &&
                    WinterHealthcareProviderForms.controls['cmd_0232'].errors) ||
                  (WinterHealthcareProviderForms.controls['cmd_0232'].errors && isSubmit)
                "
              >
                <small
                  class="text-danger"
                  *ngIf="WinterHealthcareProviderForms.controls['cmd_0232'].errors?.required"
                >
                  required
                </small>
              </div>
              <span
                *ngIf="
                  (WinterHealthcareProviderForms.controls['cmd_0232'].touched &&
                    WinterHealthcareProviderForms.controls['cmd_0232'].errors) ||
                  (WinterHealthcareProviderForms.controls['cmd_0232'].errors && isSubmit)
                "
              >
                <i class="fas fa-times ml-2 text-danger"></i>
              </span>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="2" width="5%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel">2</label>
            </div>
          </td>
          <td colspan="12" width="75%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel"
                >Have you ever received a reprimand or been fined by any state
                licensing board?
              </label>
            </div>
          </td>
          <td colspan="4" width="20%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="radio-inline radioStyle">
                <input
                  type="radio"
                  id="cmd_0233"
                  [class.is-invalid]="
                    (WinterHealthcareProviderForms.controls['cmd_0233'].touched &&
                      WinterHealthcareProviderForms.controls['cmd_0233'].errors) ||
                    (WinterHealthcareProviderForms.controls['cmd_0233'].errors && isSubmit)
                  "
                  name="cmd_0233"
                  formControlName="cmd_0233"
                  value="1"
                  (change)="validationsDataAdd($event)"
                />Yes</label
              >
              <label class="radio-inline radioStyle">
                <input
                  type="radio"
                  id="cmd_0233"
                  [class.is-invalid]="
                    (WinterHealthcareProviderForms.controls['cmd_0233'].touched &&
                      WinterHealthcareProviderForms.controls['cmd_0233'].errors) ||
                    (WinterHealthcareProviderForms.controls['cmd_0233'].errors && isSubmit)
                  "
                  name="cmd_0233"
                  formControlName="cmd_0233"
                  value="0"
                  (change)="validationsDataAdd($event)"
                />No</label
              >
              <div
                class="formLabel col-md-2"
                *ngIf="
                  WinterHealthcareProviderForms.controls['cmd_0233'].touched &&
                  WinterHealthcareProviderForms.controls['cmd_0233'].errors
                "
              >
                <small
                  class="text-danger"
                  *ngIf="WinterHealthcareProviderForms.controls['cmd_0233'].errors?.required"
                >
                  required
                </small>
              </div>
              <span
                *ngIf="
                  (WinterHealthcareProviderForms.controls['cmd_0233'].touched &&
                    WinterHealthcareProviderForms.controls['cmd_0233'].errors) ||
                  (WinterHealthcareProviderForms.controls['cmd_0233'].errors && isSubmit)
                "
              >
                <i class="fas fa-times ml-2 text-danger"></i>
              </span>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="18" width="5%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel"
                >Hospital Privileges and Other Affiliations
              </label>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="2" width="5%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel">3</label>
            </div>
          </td>
          <td colspan="12" width="75%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel"
                >Have your clinical privileges or Medical Staff membership at
                any hospital or healthcare institution ever been denied,
                suspended, revoked, restricted, denied renewal or subject to
                probationary or to other disciplinary conditions (for reasons
                other than non-completion of medical records when quality of
                care was not adversely affected) or have proceedings toward
                any of those ends been instituted or recommended by any
                hospital or healthcare institution, medical staff or
                committee, or governing board?
              </label>
            </div>
          </td>
          <td colspan="4" width="20%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="radio-inline radioStyle">
                <input
                  type="radio"
                  id="cmd_0234"
                  [class.is-invalid]="
                    (WinterHealthcareProviderForms.controls['cmd_0234'].touched &&
                      WinterHealthcareProviderForms.controls['cmd_0234'].errors) ||
                    (WinterHealthcareProviderForms.controls['cmd_0234'].errors && isSubmit)
                  "
                  name="cmd_0234"
                  formControlName="cmd_0234"
                  value="1"
                  (change)="validationsDataAdd($event)"
                />Yes</label
              >
              <label class="radio-inline radioStyle">
                <input
                  type="radio"
                  id="cmd_0234"
                  [class.is-invalid]="
                    (WinterHealthcareProviderForms.controls['cmd_0234'].touched &&
                      WinterHealthcareProviderForms.controls['cmd_0234'].errors) ||
                    (WinterHealthcareProviderForms.controls['cmd_0234'].errors && isSubmit)
                  "
                  name="cmd_0234"
                  formControlName="cmd_0234"
                  value="0"
                  (change)="validationsDataAdd($event)"
                />No</label
              >
              <div
                class="formLabel col-md-2"
                *ngIf="
                  WinterHealthcareProviderForms.controls['cmd_0234'].touched &&
                  WinterHealthcareProviderForms.controls['cmd_0234'].errors
                "
              >
                <small
                  class="text-danger"
                  *ngIf="WinterHealthcareProviderForms.controls['cmd_0234'].errors?.required"
                >
                  required
                </small>
              </div>
              <span
                *ngIf="
                  (WinterHealthcareProviderForms.controls['cmd_0234'].touched &&
                    WinterHealthcareProviderForms.controls['cmd_0234'].errors) ||
                  (WinterHealthcareProviderForms.controls['cmd_0234'].errors && isSubmit)
                "
              >
                <i class="fas fa-times ml-2 text-danger"></i>
              </span>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="2" width="5%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel">4</label>
            </div>
          </td>
          <td colspan="12" width="75%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel"
                >Have you voluntarily surrendered, limited your privileges or
                not reapplied for privileges while under investigation?</label
              >
            </div>
          </td>
          <td colspan="4" width="20%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="radio-inline radioStyle">
                <input
                  type="radio"
                  id="cmd_0235"
                  [class.is-invalid]="
                    (WinterHealthcareProviderForms.controls['cmd_0235'].touched &&
                      WinterHealthcareProviderForms.controls['cmd_0235'].errors) ||
                    (WinterHealthcareProviderForms.controls['cmd_0235'].errors && isSubmit)
                  "
                  name="cmd_0235"
                  formControlName="cmd_0235"
                  value="1"
                  (change)="validationsDataAdd($event)"
                />Yes</label
              >
              <label class="radio-inline radioStyle">
                <input
                  type="radio"
                  id="cmd_0235"
                  [class.is-invalid]="
                    (WinterHealthcareProviderForms.controls['cmd_0235'].touched &&
                      WinterHealthcareProviderForms.controls['cmd_0235'].errors) ||
                    (WinterHealthcareProviderForms.controls['cmd_0235'].errors && isSubmit)
                  "
                  name="cmd_0235"
                  formControlName="cmd_0235"
                  value="0"
                />No</label
              >
              <div
                class="formLabel col-md-2"
                *ngIf="
                  WinterHealthcareProviderForms.controls['cmd_0235'].touched &&
                  WinterHealthcareProviderForms.controls['cmd_0235'].errors
                "
              >
                <small
                  class="text-danger"
                  *ngIf="WinterHealthcareProviderForms.controls['cmd_0235'].errors?.required"
                >
                  required
                </small>
              </div>
              <span
                *ngIf="
                  (WinterHealthcareProviderForms.controls['cmd_0235'].touched &&
                    WinterHealthcareProviderForms.controls['cmd_0235'].errors) ||
                  (WinterHealthcareProviderForms.controls['cmd_0235'].errors && isSubmit)
                "
              >
                <i class="fas fa-times ml-2 text-danger"></i>
              </span>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="2" width="5%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel">5</label>
            </div>
          </td>
          <td colspan="12" width="75%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel"
                >Have you ever been terminated for cause or not renewed for
                cause from participation, or been subject to any disciplinary
                action, by any managed care organizations (including HMOs,
                PPOs, or provider organizations such as IPAs, PHOs)?
              </label>
            </div>
          </td>
          <td colspan="4" width="20%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="radio-inline radioStyle">
                <input
                  type="radio"
                  id="cmd_0236"
                  [class.is-invalid]="
                    (WinterHealthcareProviderForms.controls['cmd_0236'].touched &&
                      WinterHealthcareProviderForms.controls['cmd_0236'].errors) ||
                    (WinterHealthcareProviderForms.controls['cmd_0236'].errors && isSubmit)
                  "
                  name="cmd_0236"
                  formControlName="cmd_0236"
                  value="1"
                  (change)="validationsDataAdd($event)"
                />Yes</label
              >
              <label class="radio-inline radioStyle">
                <input
                  type="radio"
                  id="cmd_0236"
                  [class.is-invalid]="
                    (WinterHealthcareProviderForms.controls['cmd_0236'].touched &&
                      WinterHealthcareProviderForms.controls['cmd_0236'].errors) ||
                    (WinterHealthcareProviderForms.controls['cmd_0236'].errors && isSubmit)
                  "
                  name="cmd_0236"
                  formControlName="cmd_0236"
                  value="0"
                  (change)="validationsDataAdd($event)"
                />No</label
              >
              <div
                class="formLabel col-md-2"
                *ngIf="
                  WinterHealthcareProviderForms.controls['cmd_0236'].touched &&
                  WinterHealthcareProviderForms.controls['cmd_0236'].errors
                "
              >
                <small
                  class="text-danger"
                  *ngIf="WinterHealthcareProviderForms.controls['cmd_0236'].errors?.required"
                >
                  required
                </small>
              </div>
              <span
                *ngIf="
                  (WinterHealthcareProviderForms.controls['cmd_0236'].touched &&
                    WinterHealthcareProviderForms.controls['cmd_0236'].errors) ||
                  (WinterHealthcareProviderForms.controls['cmd_0236'].errors && isSubmit)
                "
              >
                <i class="fas fa-times ml-2 text-danger"></i>
              </span>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="18" width="5%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel"
                >Education, Training and Board Certification
              </label>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="2" width="5%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel">6</label>
            </div>
          </td>
          <td colspan="12" width="75%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel"
                >Were you ever placed on probation, disciplined, formally
                reprimanded, suspended or asked to resign during an
                internship, residency, fellowship, preceptorship or other
                clinical education program? If you are currently in a
                training program, have you been placed on probation,
                disciplined, formally reprimanded, suspended or asked to
                resign?
              </label>
            </div>
          </td>
          <td colspan="4" width="20%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="radio-inline radioStyle">
                <input
                  type="radio"
                  id="cmd_0237"
                  [class.is-invalid]="
                    (WinterHealthcareProviderForms.controls['cmd_0237'].touched &&
                      WinterHealthcareProviderForms.controls['cmd_0237'].errors) ||
                    (WinterHealthcareProviderForms.controls['cmd_0237'].errors && isSubmit)
                  "
                  name="cmd_0237"
                  formControlName="cmd_0237"
                  value="1"
                />Yes</label
              >
              <label class="radio-inline radioStyle">
                <input
                  type="radio"
                  id="cmd_0237"
                  [class.is-invalid]="
                    (WinterHealthcareProviderForms.controls['cmd_0237'].touched &&
                      WinterHealthcareProviderForms.controls['cmd_0237'].errors) ||
                    (WinterHealthcareProviderForms.controls['cmd_0237'].errors && isSubmit)
                  "
                  name="cmd_0237"
                  formControlName="cmd_0237"
                  value="0"
                  (change)="validationsDataAdd($event)"
                />No</label
              >
              <div
                class="formLabel col-md-2"
                *ngIf="
                  WinterHealthcareProviderForms.controls['cmd_0237'].touched &&
                  WinterHealthcareProviderForms.controls['cmd_0237'].errors
                "
              >
                <small
                  class="text-danger"
                  *ngIf="WinterHealthcareProviderForms.controls['cmd_0237'].errors?.required"
                >
                  required
                </small>
              </div>
              <span
                *ngIf="
                  (WinterHealthcareProviderForms.controls['cmd_0237'].touched &&
                    WinterHealthcareProviderForms.controls['cmd_0237'].errors) ||
                  (WinterHealthcareProviderForms.controls['cmd_0237'].errors && isSubmit)
                "
              >
                <i class="fas fa-times ml-2 text-danger"></i>
              </span>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="2" width="5%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel">7</label>
            </div>
          </td>
          <td colspan="12" width="75%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel">
                Have you ever, while under investigation, voluntarily
                withdrawn or prematurely terminated your status as a student
                or employee in any internship, residency, fellowship,
                preceptorship, or other clinical education program?
              </label>
            </div>
          </td>
          <td colspan="4" width="20%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="radio-inline radioStyle">
                <input
                  type="radio"
                  id="cmd_0238"
                  [class.is-invalid]="
                    (WinterHealthcareProviderForms.controls['cmd_0238'].touched &&
                      WinterHealthcareProviderForms.controls['cmd_0238'].errors) ||
                    (WinterHealthcareProviderForms.controls['cmd_0238'].errors && isSubmit)
                  "
                  name="cmd_0238"
                  formControlName="cmd_0238"
                  value="1"
                  (change)="validationsDataAdd($event)"
                />Yes</label
              >
              <label class="radio-inline radioStyle">
                <input
                  type="radio"
                  id="cmd_0238"
                  [class.is-invalid]="
                    (WinterHealthcareProviderForms.controls['cmd_0238'].touched &&
                      WinterHealthcareProviderForms.controls['cmd_0238'].errors) ||
                    (WinterHealthcareProviderForms.controls['cmd_0238'].errors && isSubmit)
                  "
                  name="cmd_0238"
                  formControlName="cmd_0238"
                  value="0"
                  (change)="validationsDataAdd($event)"
                />No</label
              >
              <div
                class="formLabel col-md-2"
                *ngIf="
                  WinterHealthcareProviderForms.controls['cmd_0238'].touched &&
                  WinterHealthcareProviderForms.controls['cmd_0238'].errors
                "
              >
                <small
                  class="text-danger"
                  *ngIf="WinterHealthcareProviderForms.controls['cmd_0238'].errors?.required"
                >
                  required
                </small>
              </div>
              <span
                *ngIf="
                  (WinterHealthcareProviderForms.controls['cmd_0238'].touched &&
                    WinterHealthcareProviderForms.controls['cmd_0238'].errors) ||
                  (WinterHealthcareProviderForms.controls['cmd_0238'].errors && isSubmit)
                "
              >
                <i class="fas fa-times ml-2 text-danger"></i>
              </span>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="2" width="5%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel">8</label>
            </div>
          </td>
          <td colspan="12" width="75%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel"
                >Have any of your board certifications or eligibility ever
                been revoked?
              </label>
            </div>
          </td>
          <td colspan="4" width="20%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="radio-inline radioStyle">
                <input
                  type="radio"
                  id="cmd_0239"
                  [class.is-invalid]="
                    (WinterHealthcareProviderForms.controls['cmd_0239'].touched &&
                      WinterHealthcareProviderForms.controls['cmd_0239'].errors) ||
                    (WinterHealthcareProviderForms.controls['cmd_0239'].errors && isSubmit)
                  "
                  name="cmd_0239"
                  formControlName="cmd_0239"
                  value="1"
                  (change)="validationsDataAdd($event)"
                />Yes</label
              >
              <label class="radio-inline radioStyle">
                <input
                  type="radio"
                  id="cmd_0239"
                  [class.is-invalid]="
                    (WinterHealthcareProviderForms.controls['cmd_0239'].touched &&
                      WinterHealthcareProviderForms.controls['cmd_0239'].errors) ||
                    (WinterHealthcareProviderForms.controls['cmd_0239'].errors && isSubmit)
                  "
                  name="cmd_0239"
                  formControlName="cmd_0239"
                  value="0"
                  (change)="validationsDataAdd($event)"
                />No</label
              >
              <div
                class="formLabel col-md-2"
                *ngIf="
                  WinterHealthcareProviderForms.controls['cmd_0239'].touched &&
                  WinterHealthcareProviderForms.controls['cmd_0239'].errors
                "
              >
                <small
                  class="text-danger"
                  *ngIf="WinterHealthcareProviderForms.controls['cmd_0239'].errors?.required"
                >
                  required
                </small>
              </div>
              <span
                *ngIf="
                  (WinterHealthcareProviderForms.controls['cmd_0239'].touched &&
                    WinterHealthcareProviderForms.controls['cmd_0239'].errors) ||
                  (WinterHealthcareProviderForms.controls['cmd_0239'].errors && isSubmit)
                "
              >
                <i class="fas fa-times ml-2 text-danger"></i>
              </span>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="2" width="5%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel">9</label>
            </div>
          </td>
          <td colspan="12" width="75%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel"
                >Have you ever chosen not to re-certify or voluntarily
                surrendered your board certification(s) while under
                investigation?
              </label>
            </div>
          </td>
          <td colspan="4" width="20%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="radio-inline radioStyle">
                <input
                  type="radio"
                  id="cmd_0240"
                  [class.is-invalid]="
                    (WinterHealthcareProviderForms.controls['cmd_0240'].touched &&
                      WinterHealthcareProviderForms.controls['cmd_0240'].errors) ||
                    (WinterHealthcareProviderForms.controls['cmd_0240'].errors && isSubmit)
                  "
                  name="cmd_0240"
                  formControlName="cmd_0240"
                  value="1"
                  (change)="validationsDataAdd($event)"
                />Yes</label
              >
              <label class="radio-inline radioStyle">
                <input
                  type="radio"
                  id="cmd_0240"
                  [class.is-invalid]="
                    (WinterHealthcareProviderForms.controls['cmd_0240'].touched &&
                      WinterHealthcareProviderForms.controls['cmd_0240'].errors) ||
                    (WinterHealthcareProviderForms.controls['cmd_0240'].errors && isSubmit)
                  "
                  name="cmd_0240"
                  formControlName="cmd_0240"
                  value="0"
                  (change)="validationsDataAdd($event)"
                />No</label
              >
              <div
                class="formLabel col-md-2"
                *ngIf="
                  WinterHealthcareProviderForms.controls['cmd_0240'].touched &&
                  WinterHealthcareProviderForms.controls['cmd_0240'].errors
                "
              >
                <small
                  class="text-danger"
                  *ngIf="WinterHealthcareProviderForms.controls['cmd_0240'].errors?.required"
                >
                  required
                </small>
              </div>
              <span
                *ngIf="
                  (WinterHealthcareProviderForms.controls['cmd_0240'].touched &&
                    WinterHealthcareProviderForms.controls['cmd_0240'].errors) ||
                  (WinterHealthcareProviderForms.controls['cmd_0240'].errors && isSubmit)
                "
              >
                <i class="fas fa-times ml-2 text-danger"></i>
              </span>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="18" width="5%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel">DEA or CDS </label>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="2" width="5%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel">10</label>
            </div>
          </td>
          <td colspan="12" width="75%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel"
                >Have your Federal DEA and/or Controlled Substances
                Certificate(s) or authorization(s) ever been denied,
                suspended, revoked, restricted, denied renewal, or voluntarily
                relinquished?</label
              >
            </div>
          </td>
          <td colspan="4" width="20%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="radio-inline radioStyle">
                <input
                  type="radio"
                  id="cmd_0241"
                  [class.is-invalid]="
                    (WinterHealthcareProviderForms.controls['cmd_0241'].touched &&
                      WinterHealthcareProviderForms.controls['cmd_0241'].errors) ||
                    (WinterHealthcareProviderForms.controls['cmd_0241'].errors && isSubmit)
                  "
                  name="cmd_0241"
                  formControlName="cmd_0241"
                  value="1"
                  (change)="validationsDataAdd($event)"
                />Yes</label
              >
              <label class="radio-inline radioStyle">
                <input
                  type="radio"
                  id="cmd_0241"
                  [class.is-invalid]="
                    (WinterHealthcareProviderForms.controls['cmd_0241'].touched &&
                      WinterHealthcareProviderForms.controls['cmd_0241'].errors) ||
                    (WinterHealthcareProviderForms.controls['cmd_0241'].errors && isSubmit)
                  "
                  name="cmd_0241"
                  formControlName="cmd_0241"
                  value="0"
                  (change)="validationsDataAdd($event)"
                />No</label
              >
              <div
                class="formLabel col-md-2"
                *ngIf="
                  WinterHealthcareProviderForms.controls['cmd_0241'].touched &&
                  WinterHealthcareProviderForms.controls['cmd_0241'].errors
                "
              >
                <small
                  class="text-danger"
                  *ngIf="WinterHealthcareProviderForms.controls['cmd_0241'].errors?.required"
                >
                  required
                </small>
              </div>
              <span
                *ngIf="
                  (WinterHealthcareProviderForms.controls['cmd_0241'].touched &&
                    WinterHealthcareProviderForms.controls['cmd_0241'].errors) ||
                  (WinterHealthcareProviderForms.controls['cmd_0241'].errors && isSubmit)
                "
              >
                <i class="fas fa-times ml-2 text-danger"></i>
              </span>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="18" width="5%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel"
                >Medicare, Medicaid or other Governmental Program
                Participation
              </label>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="2" width="5%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel">11</label>
            </div>
          </td>
          <td colspan="12" width="75%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel"
                >Have you ever been disciplined, excluded from, debarred,
                suspended, reprimanded, sanctioned, censured, disqualified or
                otherwise restricted in regard to participation in the
                Medicare or Medicaid program, or in regard to other federal or
                state governmental health care plans or programs?
              </label>
            </div>
          </td>
          <td colspan="4" width="20%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="radio-inline radioStyle">
                <input
                  type="radio"
                  id="cmd_0242"
                  [class.is-invalid]="
                    (WinterHealthcareProviderForms.controls['cmd_0242'].touched &&
                      WinterHealthcareProviderForms.controls['cmd_0242'].errors) ||
                    (WinterHealthcareProviderForms.controls['cmd_0242'].errors && isSubmit)
                  "
                  name="cmd_0242"
                  formControlName="cmd_0242"
                  value="1"
                  (change)="validationsDataAdd($event)"
                />Yes</label
              >
              <label class="radio-inline radioStyle">
                <input
                  type="radio"
                  id="cmd_0242"
                  [class.is-invalid]="
                    (WinterHealthcareProviderForms.controls['cmd_0242'].touched &&
                      WinterHealthcareProviderForms.controls['cmd_0242'].errors) ||
                    (WinterHealthcareProviderForms.controls['cmd_0242'].errors && isSubmit)
                  "
                  name="cmd_0242"
                  formControlName="cmd_0242"
                  value="0"
                  (change)="validationsDataAdd($event)"
                />No</label
              >
              <div
                class="formLabel col-md-2"
                *ngIf="
                  WinterHealthcareProviderForms.controls['cmd_0242'].touched &&
                  WinterHealthcareProviderForms.controls['cmd_0242'].errors
                "
              >
                <small
                  class="text-danger"
                  *ngIf="WinterHealthcareProviderForms.controls['cmd_0242'].errors?.required"
                >
                  required
                </small>
              </div>
              <span
                *ngIf="
                  (WinterHealthcareProviderForms.controls['cmd_0242'].touched &&
                    WinterHealthcareProviderForms.controls['cmd_0242'].errors) ||
                  (WinterHealthcareProviderForms.controls['cmd_0242'].errors && isSubmit)
                "
              >
                <i class="fas fa-times ml-2 text-danger"></i>
              </span>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="18" width="5%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel"
                >Other Sanctions or Investigations
              </label>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="2" width="5%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel">12</label>
            </div>
          </td>
          <td colspan="12" width="75%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel"
                >Are you currently or have you ever been the subject of an
                investigation by any hospital, licensing authority, DEA or CDS
                authorizing entities, education or training program, Medicare
                or Medicaid program, or any other private, federal or state
                health program?
              </label>
            </div>
          </td>
          <td colspan="4" width="20%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="radio-inline radioStyle">
                <input
                  type="radio"
                  id="cmd_0243"
                  [class.is-invalid]="
                    (WinterHealthcareProviderForms.controls['cmd_0243'].touched &&
                      WinterHealthcareProviderForms.controls['cmd_0243'].errors) ||
                    (WinterHealthcareProviderForms.controls['cmd_0243'].errors && isSubmit)
                  "
                  name="cmd_0243"
                  formControlName="cmd_0243"
                  value="1"
                  (change)="validationsDataAdd($event)"
                />Yes</label
              >
              <label class="radio-inline radioStyle">
                <input
                  type="radio"
                  id="cmd_0243"
                  [class.is-invalid]="
                    (WinterHealthcareProviderForms.controls['cmd_0243'].touched &&
                      WinterHealthcareProviderForms.controls['cmd_0243'].errors) ||
                    (WinterHealthcareProviderForms.controls['cmd_0243'].errors && isSubmit)
                  "
                  name="cmd_0243"
                  formControlName="cmd_0243"
                  value="0"
                  (change)="validationsDataAdd($event)"
                />No</label
              >
              <div
                class="formLabel col-md-2"
                *ngIf="
                  WinterHealthcareProviderForms.controls['cmd_0243'].touched &&
                  WinterHealthcareProviderForms.controls['cmd_0243'].errors
                "
              >
                <small
                  class="text-danger"
                  *ngIf="WinterHealthcareProviderForms.controls['cmd_0243'].errors?.required"
                >
                  required
                </small>
              </div>
              <span
                *ngIf="
                  (WinterHealthcareProviderForms.controls['cmd_0243'].touched &&
                    WinterHealthcareProviderForms.controls['cmd_0243'].errors) ||
                  (WinterHealthcareProviderForms.controls['cmd_0243'].errors && isSubmit)
                "
              >
                <i class="fas fa-times ml-2 text-danger"></i>
              </span>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="18" width="5%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel"
                >Other Sanctions or Investigations
              </label>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="2" width="5%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel">13</label>
            </div>
          </td>
          <td colspan="12" width="75%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel"
                >To your knowledge, has information pertaining to you ever
                been reported to the National Practitioner Data Bank or
                Healthcare Integrity and Protection Data Bank?
              </label>
            </div>
          </td>
          <td colspan="4" width="20%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="radio-inline radioStyle">
                <input
                  type="radio"
                  id="cmd_0244"
                  [class.is-invalid]="
                    (WinterHealthcareProviderForms.controls['cmd_0244'].touched &&
                      WinterHealthcareProviderForms.controls['cmd_0244'].errors) ||
                    (WinterHealthcareProviderForms.controls['cmd_0244'].errors && isSubmit)
                  "
                  name="cmd_0244"
                  formControlName="cmd_0244"
                  value="1"
                  (change)="validationsDataAdd($event)"
                />Yes</label
              >
              <label class="radio-inline radioStyle">
                <input
                  type="radio"
                  id="cmd_0244"
                  [class.is-invalid]="
                    (WinterHealthcareProviderForms.controls['cmd_0244'].touched &&
                      WinterHealthcareProviderForms.controls['cmd_0244'].errors) ||
                    (WinterHealthcareProviderForms.controls['cmd_0244'].errors && isSubmit)
                  "
                  name="cmd_0244"
                  formControlName="cmd_0244"
                  value="0"
                  (change)="validationsDataAdd($event)"
                />No</label
              >
              <div
                class="formLabel col-md-2"
                *ngIf="
                  WinterHealthcareProviderForms.controls['cmd_0244'].touched &&
                  WinterHealthcareProviderForms.controls['cmd_0244'].errors
                "
              >
                <small
                  class="text-danger"
                  *ngIf="WinterHealthcareProviderForms.controls['cmd_0244'].errors?.required"
                >
                  required
                </small>
              </div>
              <span
                *ngIf="
                  (WinterHealthcareProviderForms.controls['cmd_0244'].touched &&
                    WinterHealthcareProviderForms.controls['cmd_0244'].errors) ||
                  (WinterHealthcareProviderForms.controls['cmd_0244'].errors && isSubmit)
                "
              >
                <i class="fas fa-times ml-2 text-danger"></i>
              </span>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="2" width="5%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel">14</label>
            </div>
          </td>
          <td colspan="12" width="75%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel"
                >Have you ever received sanctions from or been the subject of
                investigation by any regulatory agencies (e.g., CLIA, OSHA,
                etc.)?
              </label>
            </div>
          </td>
          <td colspan="4" width="20%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="radio-inline radioStyle">
                <input
                  type="radio"
                  id="cmd_0245"
                  [class.is-invalid]="
                    (WinterHealthcareProviderForms.controls['cmd_0245'].touched &&
                      WinterHealthcareProviderForms.controls['cmd_0245'].errors) ||
                    (WinterHealthcareProviderForms.controls['cmd_0245'].errors && isSubmit)
                  "
                  name="cmd_0245"
                  formControlName="cmd_0245"
                  value="1"
                  (change)="validationsDataAdd($event)"
                />Yes</label
              >
              <label class="radio-inline radioStyle">
                <input
                  type="radio"
                  id="cmd_0245"
                  [class.is-invalid]="
                    (WinterHealthcareProviderForms.controls['cmd_0245'].touched &&
                      WinterHealthcareProviderForms.controls['cmd_0245'].errors) ||
                    (WinterHealthcareProviderForms.controls['cmd_0245'].errors && isSubmit)
                  "
                  name="cmd_0245"
                  formControlName="cmd_0245"
                  value="0"
                  (change)="validationsDataAdd($event)"
                />No</label
              >
              <div
                class="formLabel col-md-2"
                *ngIf="
                  WinterHealthcareProviderForms.controls['cmd_0245'].touched &&
                  WinterHealthcareProviderForms.controls['cmd_0245'].errors
                "
              >
                <small
                  class="text-danger"
                  *ngIf="WinterHealthcareProviderForms.controls['cmd_0245'].errors?.required"
                >
                  required
                </small>
              </div>
              <span
                *ngIf="
                  (WinterHealthcareProviderForms.controls['cmd_0245'].touched &&
                    WinterHealthcareProviderForms.controls['cmd_0245'].errors) ||
                  (WinterHealthcareProviderForms.controls['cmd_0245'].errors && isSubmit)
                "
              >
                <i class="fas fa-times ml-2 text-danger"></i>
              </span>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="2" width="5%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel">15</label>
            </div>
          </td>
          <td colspan="12" width="75%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel"
                >Have you ever been investigated, sanctioned, reprimanded or
                cautioned by a military hospital,facility, or agency, or
                voluntarily terminated or resigned while under investigation
                by a hospital or healthcare facility of any military agency?
              </label>
            </div>
          </td>
          <td colspan="4" width="20%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="radio-inline radioStyle">
                <input
                  type="radio"
                  id="cmd_0246"
                  [class.is-invalid]="
                    (WinterHealthcareProviderForms.controls['cmd_0246'].touched &&
                      WinterHealthcareProviderForms.controls['cmd_0246'].errors) ||
                    (WinterHealthcareProviderForms.controls['cmd_0246'].errors && isSubmit)
                  "
                  name="cmd_0246"
                  formControlName="cmd_0246"
                  value="1"
                  (change)="validationsDataAdd($event)"
                />Yes</label
              >
              <label class="radio-inline radioStyle">
                <input
                  type="radio"
                  id="cmd_0246"
                  [class.is-invalid]="
                    (WinterHealthcareProviderForms.controls['cmd_0246'].touched &&
                      WinterHealthcareProviderForms.controls['cmd_0246'].errors) ||
                    (WinterHealthcareProviderForms.controls['cmd_0246'].errors && isSubmit)
                  "
                  name="cmd_0246"
                  formControlName="cmd_0246"
                  value="0"
                  (change)="validationsDataAdd($event)"
                />No</label
              >
              <div
                class="formLabel col-md-2"
                *ngIf="
                  WinterHealthcareProviderForms.controls['cmd_0246'].touched &&
                  WinterHealthcareProviderForms.controls['cmd_0246'].errors
                "
              >
                <small
                  class="text-danger"
                  *ngIf="WinterHealthcareProviderForms.controls['cmd_0246'].errors?.required"
                >
                  required
                </small>
              </div>
              <span
                *ngIf="
                  (WinterHealthcareProviderForms.controls['cmd_0246'].touched &&
                    WinterHealthcareProviderForms.controls['cmd_0246'].errors) ||
                  (WinterHealthcareProviderForms.controls['cmd_0246'].errors && isSubmit)
                "
              >
                <i class="fas fa-times ml-2 text-danger"></i>
              </span>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="18" >
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel">Malpractice Claims History </label>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="2" >
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel">16</label>
            </div>
          </td>
          <td colspan="12" >
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel"
                >Have you had any malpractice actions within the past 5 years
                (pending, settled, arbitrated , mediated or litigated?
              </label>
            </div>
                <div class="form-check">
                  <input formControlName="cmd_008"  class="form-check-input" type="checkbox" value="1" id="cmd_008">
                     <label class="form-check-label" for="cmd_008">
                              <i class="formLabel">If yes, please check this box and complete and submit Attachment G. </i>
                     </label>
                         
                </div>  
              
          </td>
          <td colspan="4" width="20%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="radio-inline radioStyle">
                <input
                  type="radio"
                  id="cmd_0247"
                  [class.is-invalid]="
                    (WinterHealthcareProviderForms.controls['cmd_0247'].touched &&
                      WinterHealthcareProviderForms.controls['cmd_0247'].errors) ||
                    (WinterHealthcareProviderForms.controls['cmd_0247'].errors && isSubmit)
                  "
                  name="cmd_0247"
                  formControlName="cmd_0247"
                  value="1"
                  (change)="validationsDataAdd($event)"
                />Yes</label
              >
              <label class="radio-inline radioStyle">
                <input
                  type="radio"
                  id="cmd_0247"
                  [class.is-invalid]="
                    (WinterHealthcareProviderForms.controls['cmd_0247'].touched &&
                      WinterHealthcareProviderForms.controls['cmd_0247'].errors) ||
                    (WinterHealthcareProviderForms.controls['cmd_0247'].errors && isSubmit)
                  "
                  name="cmd_0247"
                  formControlName="cmd_0247"
                  value="0"
                  (change)="validationsDataAdd($event)"
                />No</label
              >
              <div
                class="formLabel col-md-2"
                *ngIf="
                  WinterHealthcareProviderForms.controls['cmd_0247'].touched &&
                  WinterHealthcareProviderForms.controls['cmd_0247'].errors
                "
              >
                <small
                  class="text-danger"
                  *ngIf="WinterHealthcareProviderForms.controls['cmd_0247'].errors?.required"
                >
                  required
                </small>
              </div>
              <span
                *ngIf="
                  (WinterHealthcareProviderForms.controls['cmd_0247'].touched &&
                    WinterHealthcareProviderForms.controls['cmd_0247'].errors) ||
                  (WinterHealthcareProviderForms.controls['cmd_0247'].errors && isSubmit)
                "
              >
                <i class="fas fa-times ml-2 text-danger"></i>
              </span>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="18" width="5%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel">Criminal </label>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="2" width="5%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel">17</label>
            </div>
          </td>
          <td colspan="12" width="75%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel"
                >Have you ever been convicted of, pled guilty to, or pled nolo
                contendere to any felony that is reasonably related to your
                qualifications, competence, functions, or duties as a medical
                professional
              </label>
            </div>
          </td>
          <td colspan="4" width="20%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="radio-inline radioStyle">
                <input
                  type="radio"
                  id="cmd_0248"
                  [class.is-invalid]="
                    (WinterHealthcareProviderForms.controls['cmd_0248'].touched &&
                      WinterHealthcareProviderForms.controls['cmd_0248'].invalid) ||
                    (WinterHealthcareProviderForms.controls['cmd_0248'].invalid && isSubmit)
                  "
                  name="cmd_0248"
                  formControlName="cmd_0248"
                  value="1"
                  (change)="validationsDataAdd($event)"
                />Yes</label
              >
              <label class="radio-inline radioStyle">
                <input
                  type="radio"
                  id="cmd_0248"
                  [class.is-invalid]="
                    (WinterHealthcareProviderForms.controls['cmd_0248'].touched &&
                      WinterHealthcareProviderForms.controls['cmd_0248'].invalid) ||
                    (WinterHealthcareProviderForms.controls['cmd_0248'].invalid && isSubmit)
                  "
                  name="cmd_0248"
                  formControlName="cmd_0248"
                  value="0"
                  (change)="validationsDataAdd($event)"
                />No</label
              >
              <div
                class="formLabel col-md-2"
                *ngIf="
                  WinterHealthcareProviderForms.controls['cmd_0248'].touched &&
                  WinterHealthcareProviderForms.controls['cmd_0248'].invalid
                "
              >
                <small
                  class="text-danger"
                  *ngIf="WinterHealthcareProviderForms.controls['cmd_0248'].errors?.required"
                >
                  required
                </small>
              </div>
              <span
                *ngIf="
                  (WinterHealthcareProviderForms.controls['cmd_0248'].touched &&
                    WinterHealthcareProviderForms.controls['cmd_0248'].invalid) ||
                  (WinterHealthcareProviderForms.controls['cmd_0248'].invalid && isSubmit)
                "
              >
                <i class="fas fa-times ml-2 text-danger"></i>
              </span>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="2" width="5%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel">18</label>
            </div>
          </td>
          <td colspan="12" width="75%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel"
                >Have you ever been convicted of, pled guilty to, or pled nolo
                contendere to any felony including an act of violence, child
                abuse or a sexual offense?
              </label>
            </div>
          </td>
          <td colspan="4" width="20%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="radio-inline radioStyle">
                <input
                  type="radio"
                  id="cmd_0249"
                  [class.is-invalid]="
                    (WinterHealthcareProviderForms.controls['cmd_0249'].touched &&
                      WinterHealthcareProviderForms.controls['cmd_0249'].invalid) ||
                    (WinterHealthcareProviderForms.controls['cmd_0249'].invalid && isSubmit)
                  "
                  name="cmd_0249"
                  formControlName="cmd_0249"
                  value="1"
                  (change)="validationsDataAdd($event)"
                />Yes</label
              >
              <label class="radio-inline radioStyle">
                <input
                  type="radio"
                  id="cmd_0249"
                  [class.is-invalid]="
                    (WinterHealthcareProviderForms.controls['cmd_0249'].touched &&
                      WinterHealthcareProviderForms.controls['cmd_0249'].invalid) ||
                    (WinterHealthcareProviderForms.controls['cmd_0249'].invalid && isSubmit)
                  "
                  name="cmd_0249"
                  formControlName="cmd_0249"
                  value="0"
                  (change)="validationsDataAdd($event)"
                />No</label
              >
              <div
                class="formLabel col-md-2"
                *ngIf="
                  WinterHealthcareProviderForms.controls['cmd_0249'].touched &&
                  WinterHealthcareProviderForms.controls['cmd_0249'].invalid
                "
              >
                <small
                  class="text-danger"
                  *ngIf="WinterHealthcareProviderForms.controls['cmd_0249'].errors?.required"
                >
                  required
                </small>
              </div>
              <span
                *ngIf="
                  (WinterHealthcareProviderForms.controls['cmd_0249'].touched &&
                    WinterHealthcareProviderForms.controls['cmd_0249'].invalid) ||
                  (WinterHealthcareProviderForms.controls['cmd_0249'].invalid && isSubmit)
                "
              >
                <i class="fas fa-times ml-2 text-danger"></i>
              </span>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="2" width="5%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel">19</label>
            </div>
          </td>
          <td colspan="12" width="75%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel"
                >Have you been court-martialed for actions related to your
                duties as a medical professional?
              </label>
            </div>
          </td>
          <td colspan="4" width="20%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="radio-inline radioStyle">
                <input
                  type="radio"
                  id="cmd_0250"
                  [class.is-invalid]="
                    (WinterHealthcareProviderForms.controls['cmd_0250'].touched &&
                      WinterHealthcareProviderForms.controls['cmd_0250'].invalid) ||
                    (WinterHealthcareProviderForms.controls['cmd_0250'].invalid && isSubmit)
                  "
                  name="cmd_0250"
                  formControlName="cmd_0250"
                  value="1"
                  (change)="validationsDataAdd($event)"
                />Yes</label
              >

              <label class="radio-inline radioStyle">
                <input
                  type="radio"
                  id="cmd_0250"
                  [class.is-invalid]="
                    (WinterHealthcareProviderForms.controls['cmd_0250'].touched &&
                      WinterHealthcareProviderForms.controls['cmd_0250'].invalid) ||
                    (WinterHealthcareProviderForms.controls['cmd_0250'].invalid && isSubmit)
                  "
                  name="cmd_0250"
                  formControlName="cmd_0250"
                  value="0"
                  (change)="validationsDataAdd($event)"
                />No</label
              >
              <div
                class="formLabel col-md-2"
                *ngIf="
                  WinterHealthcareProviderForms.controls['cmd_0250'].touched &&
                  WinterHealthcareProviderForms.controls['cmd_0250'].invalid
                "
              >
                <small
                  class="text-danger"
                  *ngIf="WinterHealthcareProviderForms.controls['cmd_0250'].errors?.required"
                >
                  required
                </small>
              </div>
              <span
                *ngIf="
                  (WinterHealthcareProviderForms.controls['cmd_0250'].touched &&
                    WinterHealthcareProviderForms.controls['cmd_0250'].invalid) ||
                  (WinterHealthcareProviderForms.controls['cmd_0250'].invalid && isSubmit)
                "
              >
                <i class="fas fa-times ml-2 text-danger"></i>
              </span>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="18" width="5%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel">Ability to Perform Job </label>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="2" width="5%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel">20</label>
            </div>
          </td>
          <td colspan="12" width="75%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel"
                >Are you currently engaged in the illegal use of drugs?
                ("Currently" means sufficiently recent to justify a reasonable
                belief that the use of drug may have an ongoing impact on
                one's ability to pr actice medicine. It is not limited to the
                day of, or within a matter of days or weeks before the d ate
                of application, rather that it has occurred recently enough to
                indicate the individual is actively engaged in such conduct.
                "Illegal use of drugs" refers to drugs whose possession or
                distribution is unlawful under the Controlled Substances Act,
                21 U.S.C. § 812.22. It "does not include the use of a drug
                taken under supervision by a licensed health care
                professional, or other uses authorized by the Con trolled
                Substances Act or other provision of Federal law." The term
                does include, however, the unlawful use of prescription
                controlled substances.)
              </label>
            </div>
          </td>
          <td colspan="4" width="20%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="radio-inline radioStyle">
                <input
                  type="radio"
                  id="cmd_0251"
                  [class.is-invalid]="
                    (WinterHealthcareProviderForms.controls['cmd_0251'].touched &&
                      WinterHealthcareProviderForms.controls['cmd_0251'].invalid) ||
                    (WinterHealthcareProviderForms.controls['cmd_0251'].invalid && isSubmit)
                  "
                  name="cmd_0251"
                  formControlName="cmd_0251"
                  value="1"
                  (change)="validationsDataAdd($event)"
                />Yes</label
              >
              <label class="radio-inline radioStyle">
                <input
                  type="radio"
                  id="cmd_0251"
                  [class.is-invalid]="
                    (WinterHealthcareProviderForms.controls['cmd_0251'].touched &&
                      WinterHealthcareProviderForms.controls['cmd_0251'].invalid) ||
                    (WinterHealthcareProviderForms.controls['cmd_0251'].invalid && isSubmit)
                  "
                  name="cmd_0251"
                  formControlName="cmd_0251"
                  value="0"
                  (change)="validationsDataAdd($event)"
                />No</label
              >
              <div
                class="formLabel col-md-2"
                *ngIf="
                  WinterHealthcareProviderForms.controls['cmd_0251'].touched &&
                  WinterHealthcareProviderForms.controls['cmd_0251'].invalid
                "
              >
                <small
                  class="text-danger"
                  *ngIf="WinterHealthcareProviderForms.controls['cmd_0251'].errors?.required"
                >
                  required
                </small>
              </div>
              <span
                *ngIf="
                  (WinterHealthcareProviderForms.controls['cmd_0251'].touched &&
                    WinterHealthcareProviderForms.controls['cmd_0251'].invalid) ||
                  (WinterHealthcareProviderForms.controls['cmd_0251'].invalid && isSubmit)
                "
              >
                <i class="fas fa-times ml-2 text-danger"></i>
              </span>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="2" width="5%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel">21</label>
            </div>
          </td>
          <td colspan="12" width="75%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel"
                >Do you use any chemical substances that would in any way
                impair or limit your ability to practice medicine and perform
                the functions of your job with reasonable skill and safety?
              </label>
            </div>
          </td>
          <td colspan="4" width="20%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="radio-inline radioStyle">
                <input
                  type="radio"
                  id="cmd_0252"
                  [class.is-invalid]="
                    (WinterHealthcareProviderForms.controls['cmd_0252'].touched &&
                      WinterHealthcareProviderForms.controls['cmd_0252'].invalid) ||
                    (WinterHealthcareProviderForms.controls['cmd_0252'].invalid && isSubmit)
                  "
                  name="cmd_0252"
                  formControlName="cmd_0252"
                  value="1"
                  (change)="validationsDataAdd($event)"
                />Yes</label
              >
              <label class="radio-inline radioStyle">
                <input
                  type="radio"
                  id="cmd_0252"
                  [class.is-invalid]="
                    (WinterHealthcareProviderForms.controls['cmd_0252'].touched &&
                      WinterHealthcareProviderForms.controls['cmd_0252'].invalid) ||
                    (WinterHealthcareProviderForms.controls['cmd_0252'].invalid && isSubmit)
                  "
                  name="cmd_0252"
                  formControlName="cmd_0252"
                  value="0"
                  (change)="validationsDataAdd($event)"
                />No</label
              >
              <div
                class="formLabel col-md-2"
                *ngIf="
                  WinterHealthcareProviderForms.controls['cmd_0252'].touched &&
                  WinterHealthcareProviderForms.controls['cmd_0252'].invalid
                "
              >
                <small
                  class="text-danger"
                  *ngIf="WinterHealthcareProviderForms.controls['cmd_0252'].errors?.required"
                >
                  required
                </small>
              </div>
              <span
                *ngIf="
                  (WinterHealthcareProviderForms.controls['cmd_0252'].touched &&
                    WinterHealthcareProviderForms.controls['cmd_0252'].invalid) ||
                  (WinterHealthcareProviderForms.controls['cmd_0252'].invalid && isSubmit)
                "
              >
                <i class="fas fa-times ml-2 text-danger"></i>
              </span>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="18" width="5%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel">Ability to Perform Job </label>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="2" width="5%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel">22</label>
            </div>
          </td>
          <td colspan="12" width="75%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel"
                >Do you have any reason to believe that you would pose a risk
                to the safety or well-being of your patients?</label
              >
            </div>
          </td>
          <td colspan="4" width="20%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="radio-inline radioStyle">
                <input
                  type="radio"
                  id="cmd_0253"
                  [class.is-invalid]="
                    (WinterHealthcareProviderForms.controls['cmd_0253'].touched &&
                      WinterHealthcareProviderForms.controls['cmd_0253'].invalid) ||
                    (WinterHealthcareProviderForms.controls['cmd_0253'].invalid && isSubmit)
                  "
                  name="cmd_0253"
                  formControlName="cmd_0253"
                  value="1"
                  (change)="validationsDataAdd($event)"
                />Yes</label
              >
              <label class="radio-inline radioStyle">
                <input
                  type="radio"
                  id="cmd_0253"
                  [class.is-invalid]="
                    (WinterHealthcareProviderForms.controls['cmd_0253'].touched &&
                      WinterHealthcareProviderForms.controls['cmd_0253'].invalid) ||
                    (WinterHealthcareProviderForms.controls['cmd_0253'].invalid && isSubmit)
                  "
                  name="cmd_0253"
                  formControlName="cmd_0253"
                  value="0"
                  (change)="validationsDataAdd($event)"
                />No</label
              >
              <div
                class="formLabel col-md-2"
                *ngIf="
                  WinterHealthcareProviderForms.controls['cmd_0253'].touched &&
                  WinterHealthcareProviderForms.controls['cmd_0253'].invalid
                "
              >
                <small
                  class="text-danger"
                  *ngIf="WinterHealthcareProviderForms.controls['cmd_0253'].errors?.required"
                >
                  required
                </small>
              </div>
              <span
                *ngIf="
                  (WinterHealthcareProviderForms.controls['cmd_0253'].touched &&
                    WinterHealthcareProviderForms.controls['cmd_0253'].invalid) ||
                  (WinterHealthcareProviderForms.controls['cmd_0253'].invalid && isSubmit)
                "
              >
                <i class="fas fa-times ml-2 text-danger"></i>
              </span>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="2" width="5%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel">23</label>
            </div>
          </td>
          <td colspan="12" width="75%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel"
                >Are you unable to perform the essential functions of a
                practitioner in your area of practice, with or without
                reasonable accommodation?
              </label>
            </div>
          </td>
          <td colspan="4" width="20%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="radio-inline radioStyle">
                <input
                  type="radio"
                  id="cmd_0254"
                  [class.is-invalid]="
                    (WinterHealthcareProviderForms.controls['cmd_0254'].touched &&
                      WinterHealthcareProviderForms.controls['cmd_0254'].invalid) ||
                    (WinterHealthcareProviderForms.controls['cmd_0254'].invalid && isSubmit)
                  "
                  name="cmd_0254"
                  formControlName="cmd_0254"
                  value="1"
                  (change)="validationsDataAdd($event)"
                />Yes</label
              >
              <label class="radio-inline radioStyle">
                <input
                  type="radio"
                  id="cmd_0254"
                  [class.is-invalid]="
                    (WinterHealthcareProviderForms.controls['cmd_0254'].touched &&
                      WinterHealthcareProviderForms.controls['cmd_0254'].invalid) ||
                    (WinterHealthcareProviderForms.controls['cmd_0254'].invalid && isSubmit)
                  "
                  name="cmd_0254"
                  formControlName="cmd_0254"
                  value="0"
                  (change)="validationsDataAdd($event)"
                />No</label
              >
              <div
                class="formLabel col-md-2"
                *ngIf="
                  WinterHealthcareProviderForms.controls['cmd_0254'].touched &&
                  WinterHealthcareProviderForms.controls['cmd_0254'].invalid
                "
              >
                <small
                  class="text-danger"
                  *ngIf="WinterHealthcareProviderForms.controls['cmd_0254'].errors?.required"
                >
                  required
                </small>
              </div>
              <span
                *ngIf="
                  (WinterHealthcareProviderForms.controls['cmd_0254'].touched &&
                    WinterHealthcareProviderForms.controls['cmd_0254'].invalid) ||
                  (WinterHealthcareProviderForms.controls['cmd_0254'].invalid && isSubmit)
                "
              >
                <i class="fas fa-times ml-2 text-danger"></i>
              </span>
            </div>
          </td>
        </tr>
      </table>
      
      <hr class="tablePersonal" />
      
      <table border="1" width="100%">
        <tr>
          <td colspan="18" width="100%">
            <h4>
              Section II - Disclosure Questions-continued 
            </h4><label 
            >-Please use the space below to explain yes answers to any question
            except #16.
          </label>
          </td>
        </tr>
        
        <tr>
          <td colspan="9">
            <div class="form-inline no-margin sideLabelAlignment">
              <label class="boldParagraph">QUESTION NUMBER</label>
            </div>
          </td>
          <td colspan="9">
            <div class="form-inline no-margin sideLabelAlignment">
              <label class="boldParagraph">PLEASE EXPLAIN </label>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="9">
          <div class="form-group form-inline no-margin sideLabelAlignment">
              <textarea
              style="width: 70%"
              [class.is-invalid]="
                  questionCounter > 0 && WinterHealthcareProviderForms.controls['cmd_0255'].invalid"
              class="form-control"
              id="cmd_0255"
              formControlName="cmd_0255"
              name="cmd_0255"
              ></textarea>
              <div class="formLabel col-sm-2">
              <small
                  class="text-danger"
                  *ngIf="
                  questionCounter > 0 && WinterHealthcareProviderForms.controls['cmd_0255'].invalid"
              >
                  required
              </small>
              </div>
          </div>
          </td>
          <td colspan="9">
          <div class="form-inline no-margin sideLabelAlignment">
              <textarea
              style="width: 70%"
              class="form-control"
              [class.is-invalid]="
                  questionCounters > 0 && WinterHealthcareProviderForms.controls['cmd_0256'].invalid"
              
              id="cmd_0256"
              formControlName="cmd_0256"
              name="cmd_0256"
              data-id="Text5.2.0.0"
              ></textarea>
              <div class="formLabel col-md-2">
                  <small
                      class="text-danger"
                      *ngIf="
                      questionCounters > 0 && WinterHealthcareProviderForms.controls['cmd_0256'].invalid
                      "
                  >
                      required
                  </small>
                  </div>
          </div>
          </td>
        </tr> 
        <tr>
            <td colspan="9">
            <div class="form-group form-inline no-margin sideLabelAlignment">
                <textarea
                style="width: 70%"
                [class.is-invalid]="
                    questionCounter > 1 && WinterHealthcareProviderForms.controls['cmd_0257'].invalid
                "
                class="form-control"
                id="cmd_0257"
                formControlName="cmd_0257"
                name="cmd_0257"
                data-id="Text5.2.1.1"
                ></textarea>
                <div class="formLabel col-md-2">
                <small
                    class="text-danger"
                    *ngIf="
                    questionCounter > 1 && WinterHealthcareProviderForms.controls['cmd_0257'].invalid
                    "
                >
                    required
                </small>
                </div>
            </div>
            </td>
            <td colspan="9">
            <div class="form-group form-inline no-margin sideLabelAlignment">
                <textarea
                style="width: 70%"
                [class.is-invalid]="
                    questionCounters > 1 && WinterHealthcareProviderForms.controls['cmd_0258'].invalid
                "
                class="form-control"
                id="cmd_0258"
                formControlName="cmd_0258"
                name="cmd_0258"
                data-id="Text5.2.0.1"
                ></textarea>
                <div class="formLabel col-md-2">
                    <small
                        class="text-danger"
                        *ngIf="
                        questionCounters > 1 && WinterHealthcareProviderForms.controls['cmd_0258'].invalid
                        "
                    >
                        required
                    </small>
                </div>
              
            </div>
            </td>
        </tr>
        <tr>
            <td colspan="9">
            <div class="form-group form-inline no-margin sideLabelAlignment">
                <textarea
                style="width: 70%"
                [class.is-invalid]="
                    questionCounter > 2 && WinterHealthcareProviderForms.controls['cmd_0259'].invalid
                "
                class="form-control"
                id="cmd_0259"
                formControlName="cmd_0259"
                name="cmd_0259"
                data-id="Text5.2.1.2"
                ></textarea>
                <div class="formLabel col-md-2">
                <small
                    class="text-danger"
                    *ngIf="
                    questionCounter > 2 && WinterHealthcareProviderForms.controls['cmd_0259'].invalid
                    "
                >
                    required
                </small>
                </div>
            </div>
            </td>
            <td colspan="9">
            <div class="form-inline no-margin sideLabelAlignment">
                <textarea
                style="width: 70%"
                class="form-control"
                id="cmd_0260"
                [class.is-invalid]="
                    questionCounters > 2 && WinterHealthcareProviderForms.controls['cmd_0260'].invalid
                "
                formControlName="cmd_0260"
                name="cmd_0260"
                data-id="Text5.2.0.2"
                ></textarea>
                <div class="formLabel col-md-2">
                    <small
                        class="text-danger"
                        *ngIf="
                        questionCounters > 2 && WinterHealthcareProviderForms.controls['cmd_0260'].invalid
                        "
                    >
                        required
                    </small>
                    </div>
            </div>
            </td>
        </tr>
        <tr>
            <td colspan="9">
            <div class="form-group form-inline no-margin sideLabelAlignment">
                <textarea
                style="width: 70%"
                [class.is-invalid]="
                    questionCounter > 3 && WinterHealthcareProviderForms.controls['cmd_0261'].invalid
                "
                class="form-control"
                id="cmd_0261"
                formControlName="cmd_0261"
                name="cmd_0261"
                data-id="Text5.2.1.3"
                ></textarea>
                <div class="formLabel col-md-2">
                <small
                    class="text-danger"
                    *ngIf="
                    questionCounter > 3 && WinterHealthcareProviderForms.controls['cmd_0261'].invalid
                    "
                >
                    required
                </small>
                </div>
            </div>
            </td>
            <td colspan="9">
            <div class="form-inline no-margin sideLabelAlignment">
                <textarea
                style="width: 70%"
                class="form-control"
                id="cmd_0262"
                [class.is-invalid]="
                    questionCounters > 3 && WinterHealthcareProviderForms.controls['cmd_0262'].invalid
                "
                formControlName="cmd_0262"
                name="cmd_0262"
                data-id="Text5.2.0.3"
                ></textarea>
                <div class="formLabel col-md-2">
                    <small
                        class="text-danger"
                        *ngIf="
                        questionCounters> 3 && WinterHealthcareProviderForms.controls['cmd_0262'].invalid
                        "
                    >
                        required
                    </small>
                </div>
            </div>
            </td>
        </tr>
        <tr>
            <td colspan="9">
            <div class="form-group form-inline no-margin sideLabelAlignment">
                <textarea
                style="width: 70%"
                [class.is-invalid]="
                    questionCounter > 4 && WinterHealthcareProviderForms.controls['cmd_0263'].invalid
                "
                class="form-control"
                id="cmd_0263"
                formControlName="cmd_0263"
                name="cmd_0263"
                data-id="Text5.2.1.4"
                ></textarea>
                <div class="formLabel col-md-2">
                <small
                    class="text-danger"
                    *ngIf="
                    questionCounter > 4 && WinterHealthcareProviderForms.controls['cmd_0263'].invalid
                    "
                >
                    required
                </small>
                </div>
            </div>
            </td>
            <td colspan="9">
            <div class="form-inline no-margin sideLabelAlignment">
                <textarea
                style="width: 70%"
                class="form-control"
                id="cmd_0264"
                [class.is-invalid]="
                    questionCounters > 4 && WinterHealthcareProviderForms.controls['cmd_0264'].invalid
                "
                formControlName="cmd_0264"
                name="cmd_0264"
                data-id="Text5.2.0.4"
                ></textarea>
                <div class="formLabel col-md-2">
                    <small
                        class="text-danger"
                        *ngIf="
                        questionCounters > 4 && WinterHealthcareProviderForms.controls['cmd_0264'].invalid
                        "
                    >
                        required
                    </small>
                    </div>
            </div>
            </td>
        </tr>
        <tr>
            <td colspan="9">
            <div class="form-group form-inline no-margin sideLabelAlignment">
                <textarea
                style="width: 70%"
                [class.is-invalid]="
                    questionCounter > 5 && WinterHealthcareProviderForms.controls['cmd_0265'].invalid
                "
                class="form-control"
                id="cmd_0265"
                formControlName="cmd_0265"
                name="cmd_0265"
                data-id="Text5.2.1.5"
                ></textarea>
                <div class="formLabel col-md-2">
                <small
                    class="text-danger"
                    *ngIf="
                    questionCounter > 5 && WinterHealthcareProviderForms.controls['cmd_0265'].invalid
                    "
                >
                    required
                </small>
                </div>
            </div>
            </td>
            <td colspan="9">
            <div class="form-inline no-margin sideLabelAlignment">
                <textarea
                style="width: 70%"
                class="form-control"
                [class.is-invalid]="
                questionCounters > 5 && WinterHealthcareProviderForms.controls['cmd_0266'].invalid
                "
                id="cmd_0266"
                formControlName="cmd_0266"
                name="cmd_0266"
                data-id="Text5.2.0.5"
                ></textarea>
                <div class="formLabel col-md-2">
                    <small
                        class="text-danger"
                        *ngIf="
                        questionCounters > 5 && WinterHealthcareProviderForms.controls['cmd_0266'].invalid
                        "
                    >
                        required
                    </small>
                    </div>
            </div>
            </td>
        </tr>
        <tr>
            <td colspan="9">
            <div class="form-group form-inline no-margin sideLabelAlignment">
                <textarea
                style="width: 70%"
                [class.is-invalid]="
                    questionCounter > 6 && WinterHealthcareProviderForms.controls['cmd_0267'].invalid
                "
                class="form-control"
                id="cmd_0267"
                formControlName="cmd_0267"
                name="cmd_0267"
                data-id="Text5.2.1.6"
                ></textarea>
                <div class="formLabel col-md-2">
                <small
                    class="text-danger"
                    *ngIf="
                    questionCounter > 6 && WinterHealthcareProviderForms.controls['cmd_0267'].invalid
                    "
                >
                    required
                </small>
                </div>
            </div>
            </td>
            <td colspan="9">
            <div class="form-inline no-margin sideLabelAlignment">
                <textarea
                style="width: 70%"
                class="form-control"
                id="cmd_0268"
                [class.is-invalid]="
                    questionCounters > 6 && WinterHealthcareProviderForms.controls['cmd_0268'].invalid
                "
                formControlName="cmd_0268"
                name="cmd_0268"
                data-id="Text5.2.0.6"
                ></textarea>
                <div class="formLabel col-md-2">
                    <small
                        class="text-danger"
                        *ngIf="
                        questionCounters > 6 && WinterHealthcareProviderForms.controls['cmd_0268'].invalid
                        "
                    >
                        required
                    </small>
                    </div>
            </div>
            </td>
        </tr>
        <tr>
            <td colspan="9">
            <div class="form-group form-inline no-margin sideLabelAlignment">
                <textarea
                style="width: 70%"
                [class.is-invalid]="
                    questionCounter > 7 && WinterHealthcareProviderForms.controls['cmd_0269'].invalid
                "
                class="form-control"
                id="cmd_0269"
                formControlName="cmd_0269"
                name="cmd_0269"
                data-id="Text5.2.1.7"
                ></textarea>
                <div class="formLabel col-md-2">
                <small
                    class="text-danger"
                    *ngIf="
                    questionCounter > 7 && WinterHealthcareProviderForms.controls['cmd_0269'].invalid
                    "
                >
                    required
                </small>
                </div>
            </div>
            </td>
            <td colspan="9">
            <div class="form-inline no-margin sideLabelAlignment">
                <textarea
                style="width: 70%"
                class="form-control"
                id="cmd_0270"
                [class.is-invalid]="
                    questionCounters > 7 && WinterHealthcareProviderForms.controls['cmd_0270'].invalid
                "
                formControlName="cmd_0270"
                name="cmd_0270"
                data-id="Text5.2.0.7"
                ></textarea>
                <div class="formLabel col-md-2">
                    <small
                        class="text-danger"
                        *ngIf="
                        questionCounters > 7 && WinterHealthcareProviderForms.controls['cmd_0270'].invalid
                        "
                    >
                        required
                    </small>
                    </div>
            </div>
            </td>
        </tr>
        <tr>
            <td colspan="9">
            <div class="form-group form-inline no-margin sideLabelAlignment">
                <textarea
                style="width: 70%"
                [class.is-invalid]="
                    questionCounter > 8 && WinterHealthcareProviderForms.controls['cmd_0271'].invalid
                "
                class="form-control"
                id="cmd_0271"
                formControlName="cmd_0271"
                name="cmd_0271"
                data-id="Text5.2.1.8.0"
                ></textarea>
                <div class="formLabel col-md-2">
                <small
                    class="text-danger"
                    *ngIf="
                    questionCounter > 8 && WinterHealthcareProviderForms.controls['cmd_0271'].invalid
                    "
                >
                    required
                </small>
                </div>
            </div>
            </td>
            <td colspan="9">
            <div class="form-inline no-margin sideLabelAlignment">
                <textarea
                style="width: 70%"
                class="form-control"
                id="cmd_0272"
                formControlName="cmd_0272"
                [class.is-invalid]="
                    questionCounters > 8 && WinterHealthcareProviderForms.controls['cmd_0272'].invalid
                "
                name="cmd_0272"
                data-id="Text5.2.0.8.0"
                ></textarea>
                <div class="formLabel col-md-2">
                    <small
                        class="text-danger"
                        *ngIf="
                        questionCounters > 8 && WinterHealthcareProviderForms.controls['cmd_0272'].invalid
                        "
                    >
                        required
                    </small>
                </div>
            </div>
            </td>
        </tr>
        <tr>
            <td colspan="9">
            <div class="form-group form-inline no-margin sideLabelAlignment">
                <textarea
                style="width: 70%"
                [class.is-invalid]="
                    questionCounter > 9 && WinterHealthcareProviderForms.controls['cmd_0273'].invalid
                "
                class="form-control"
                id="cmd_0273"
                formControlName="cmd_0273"
                name="cmd_0273"
                data-id="Text5.2.1.9.0"
                ></textarea>
                <div class="formLabel col-md-2">
                <small
                    class="text-danger"
                    *ngIf="
                    questionCounter > 9 && WinterHealthcareProviderForms.controls['cmd_0273'].invalid
                    "
                >
                    required
                </small>
                </div>
            </div>
            </td>
            <td colspan="9">
            <div class="form-inline no-margin sideLabelAlignment">
                <textarea
                style="width: 70%"
                class="form-control"
                id="cmd_0274"
                [class.is-invalid]="
                    questionCounters > 9 && WinterHealthcareProviderForms.controls['cmd_0274'].invalid
                "
                formControlName="cmd_0274"
                name="cmd_0274"
                data-id="Text5.2.0.9.0"
                ></textarea>
                <div class="formLabel col-md-2">
                    <small
                        class="text-danger"
                        *ngIf="
                        questionCounters > 9 && WinterHealthcareProviderForms.controls['cmd_0274'].invalid
                        "
                    >
                        required
                    </small>
                    </div>
            </div>
            </td>
        </tr>
        <tr>
            <td colspan="9">
            <div class="form-group form-inline no-margin sideLabelAlignment">
                <textarea
                style="width: 70%"
                [class.is-invalid]="
                    questionCounter > 10 && WinterHealthcareProviderForms.controls['cmd_0275'].invalid
                "
                class="form-control"
                id="cmd_0275"
                formControlName="cmd_0275"
                name="cmd_0275"
                data-id="Text5.2.1.8.1"
                ></textarea>
                <div class="formLabel col-md-2">
                <small
                    class="text-danger"
                    *ngIf="
                    questionCounter > 10 &&
                    WinterHealthcareProviderForms.controls['cmd_0275'].invalid
                    "
                >
                    required
                </small>
                </div>
            </div>
            </td>
            <td colspan="9">
            <div class="form-inline no-margin sideLabelAlignment">
                <textarea
                style="width: 70%"
                class="form-control"
                id="cmd_0276"
                [class.is-invalid]="
                    questionCounters > 10 && WinterHealthcareProviderForms.controls['cmd_0276'].invalid
                "
                formControlName="cmd_0276"
                name="cmd_0276"
                data-id="Text5.2.0.8.1"
                ></textarea>
                <div class="formLabel col-md-2">
                    <small
                        class="text-danger"
                        *ngIf="
                        questionCounters > 10 &&
                        WinterHealthcareProviderForms.controls['cmd_0276'].invalid
                        "
                    >
                        required
                    </small>
                    </div>
            </div>
            </td>
        </tr>
        <tr>
            <td colspan="9">
            <div class="form-group form-inline no-margin sideLabelAlignment">
                <textarea
                style="width: 70%"
                [class.is-invalid]="
                    questionCounter > 11 && WinterHealthcareProviderForms.controls['cmd_0277'].invalid
                "
                class="form-control"
                id="cmd_0277"
                formControlName="cmd_0277"
                name="cmd_0277"
                data-id="Text5.2.1.9.1"
                ></textarea>
                <div class="formLabel col-md-2">
                <small
                    class="text-danger"
                    *ngIf="
                    questionCounter > 11 &&
                    WinterHealthcareProviderForms.controls['cmd_0277'].invalid
                    "
                >
                    required
                </small>
                </div>
            </div>
            </td>
            <td colspan="9">
            <div class="form-inline no-margin sideLabelAlignment">
                <textarea
                style="width: 70%"
                class="form-control"
                id="cmd_0278"
                [class.is-invalid]="
                    questionCounters > 11 && WinterHealthcareProviderForms.controls['cmd_0278'].invalid
                "
                formControlName="cmd_0278"
                name="cmd_0278"
                data-id="Text5.2.0.9.1"
                ></textarea>
                <div class="formLabel col-md-2">
                    <small
                        class="text-danger"
                        *ngIf="
                        questionCounters > 11 &&
                        WinterHealthcareProviderForms.controls['cmd_0278'].invalid
                        "
                    >
                        required
                    </small>
                    </div>
            </div>
            </td>
        </tr>
        <tr>
            <td colspan="9">
            <div class="form-group form-inline no-margin sideLabelAlignment">
                <textarea
                style="width: 70%"
                [class.is-invalid]="
                    questionCounter > 12 && WinterHealthcareProviderForms.controls['cmd_0279'].invalid
                "
                class="form-control"
                id="cmd_0279"
                formControlName="cmd_0279"
                name="cmd_0279"
                data-id="Text5.2.1.8.2"
                ></textarea>
                <div class="formLabel col-md-2">
                <small
                    class="text-danger"
                    *ngIf="
                    questionCounter > 12 &&
                    WinterHealthcareProviderForms.controls['cmd_0279'].invalid
                    "
                >
                    required
                </small>
                </div>
            </div>
            </td>
            <td colspan="9">
            <div class="form-inline no-margin sideLabelAlignment">
                <textarea
                style="width: 70%"
                class="form-control"
                id="cmd_0280"
                [class.is-invalid]="
                    questionCounters > 12 && WinterHealthcareProviderForms.controls['cmd_0280'].invalid
                "
                formControlName="cmd_0280"
                name="cmd_0280"
                data-id="Text5.2.0.8.2"
                ></textarea>
                <div class="formLabel col-md-2">
                    <small
                        class="text-danger"
                        *ngIf="
                        questionCounters > 12 &&
                        WinterHealthcareProviderForms.controls['cmd_0280'].invalid
                        "
                    >
                        required
                    </small>
                    </div>
            </div>
            </td>
        </tr>
        <tr>
            <td colspan="9">
            <div class="form-group form-inline no-margin sideLabelAlignment">
                <textarea
                style="width: 70%"
                [class.is-invalid]="
                    questionCounter > 13 && WinterHealthcareProviderForms.controls['cmd_0281'].invalid
                "
                class="form-control"
                id="cmd_0281"
                formControlName="cmd_0281"
                name="cmd_0281"
                data-id="Text5.2.1.9.2"
                ></textarea>
                <div class="formLabel col-md-2">
                <small
                    class="text-danger"
                    *ngIf="
                    questionCounter > 13 &&
                    WinterHealthcareProviderForms.controls['cmd_0281'].invalid
                    "
                >
                    required
                </small>
                </div>
            </div>
            </td>
            <td colspan="9">
            <div class="form-inline no-margin sideLabelAlignment">
                <textarea
                style="width: 70%"
                class="form-control"
                id="cmd_0282"
                formControlName="cmd_0282"
                [class.is-invalid]="
                    questionCounters > 13 && WinterHealthcareProviderForms.controls['cmd_0282'].invalid
                "
                name="cmd_0282"
                data-id="Text5.2.0.9.2"
                ></textarea>
                <div class="formLabel col-md-2">
                    <small
                        class="text-danger"
                        *ngIf="
                        questionCounters > 13 &&
                        WinterHealthcareProviderForms.controls['cmd_0282'].invalid
                        "
                    >
                        required
                    </small>
                </div>
            </div>
            </td>
        </tr>
        <tr>
            <td colspan="9">
            <div class="form-group form-inline no-margin sideLabelAlignment">
                <textarea
                style="width: 70%"
                [class.is-invalid]="
                    questionCounter > 14 && WinterHealthcareProviderForms.controls['cmd_0283'].invalid
                "
                class="form-control"
                id="cmd_0283"
                formControlName="cmd_0283"
                name="cmd_0283"
                data-id="Text5.2.1.8.3"
                ></textarea>
                <div class="formLabel col-md-2">
                <small
                    class="text-danger"
                    *ngIf="
                    questionCounter > 14 &&
                    WinterHealthcareProviderForms.controls['cmd_0283'].invalid
                    "
                >
                    required
                </small>
                </div>
            </div>
            </td>
            <td colspan="9">
            <div class="form-inline no-margin sideLabelAlignment">
                <textarea
                style="width: 70%"
                class="form-control"
                id="cmd_0284"
                [class.is-invalid]="
                    questionCounters > 14 && WinterHealthcareProviderForms.controls['cmd_0284'].invalid
                "
                formControlName="cmd_0284"
                name="cmd_0284"
                data-id="Text5.2.0.8.3"
                ></textarea>
                <div class="formLabel col-md-2">
                    <small
                        class="text-danger"
                        *ngIf="
                        questionCounters > 14 &&
                        WinterHealthcareProviderForms.controls['cmd_0284'].invalid
                        "
                    >
                        required
                    </small>
                    </div>
            </div>
            </td>
        </tr>
        <tr>
            <td colspan="9">
            <div class="form-group form-inline no-margin sideLabelAlignment">
                <textarea
                style="width: 70%"
                [class.is-invalid]="
                    questionCounter > 15 && WinterHealthcareProviderForms.controls['cmd_0285'].invalid
                "
                class="form-control"
                id="cmd_0285"
                formControlName="cmd_0285"
                name="cmd_0285"
                data-id="Explain 10"
                ></textarea>
                <div
                class="formLabel col-md-2">
                
                <small
                    class="text-danger"
                    *ngIf="
                    questionCounter > 15 &&
                    WinterHealthcareProviderForms.controls['cmd_0285'].invalid
                    "
                >
                    required
                </small>
                </div>
            </div>
            </td>
            <td colspan="9">
            <div class="form-inline no-margin sideLabelAlignment">
                <textarea
                style="width: 70%"
                class="form-control"
                [class.is-invalid]="
                    questionCounters > 15 && WinterHealthcareProviderForms.controls['cmd_0286'].invalid
                "
                id="cmd_0286"
                formControlName="cmd_0286"
                name="cmd_0286"
                data-id="Page 10"
                ></textarea>
                <div
                class="formLabel col-md-2">
                <small
                    class="text-danger"
                    *ngIf="
                    questionCounters > 15 &&
                    WinterHealthcareProviderForms.controls['cmd_0286'].invalid
                    "
                >
                    required
                </small>
                </div>
            </div>
            </td>
        </tr>
        <tr>
            <td colspan="9">
            <div class="form-group form-inline no-margin sideLabelAlignment">
                <textarea
                style="width: 70%"
                [class.is-invalid]="
                    questionCounter > 16 && WinterHealthcareProviderForms.controls['cmd_0287'].invalid
                "
                class="form-control"
                id="cmd_0287"
                formControlName="cmd_0287"
                name="cmd_0287"
                data-id="Text5.2.1.9.3"
                ></textarea>
                <div class="formLabel col-md-2">
                <small
                    class="text-danger"
                    *ngIf="
                    questionCounter > 16 &&
                    WinterHealthcareProviderForms.controls['cmd_0287'].invalid
                    "
                >
                    required
                </small>
                </div>
            </div>
            </td>
            <td colspan="9">
            <div class="form-inline no-margin sideLabelAlignment">
                <textarea
                style="width: 70%"
                class="form-control"
                id="cmd_0288"
                [class.is-invalid]="
                    questionCounters > 16 && WinterHealthcareProviderForms.controls['cmd_0288'].invalid
                "
                formControlName="cmd_0288"
                name="cmd_0288"
                data-id="Text5.2.0.9.3"
                ></textarea>
                <div class="formLabel col-md-2">
                    <small
                        class="text-danger"
                        *ngIf="
                        questionCounters > 16 &&
                        WinterHealthcareProviderForms.controls['cmd_0288'].invalid
                        "
                    >
                        required
                    </small>
                </div>
            </div>
            </td>
        </tr>
        <tr>
            <td colspan="9">
            <div class="form-group form-inline no-margin sideLabelAlignment">
                <textarea
                style="width: 70%"
                [class.is-invalid]="
                    questionCounter > 17 && WinterHealthcareProviderForms.controls['cmd_0289'].invalid
                "
                class="form-control"
                id="cmd_0289"
                formControlName="cmd_0289"
                name="cmd_0289"
                data-id="Text5.2.1.8.4"
                ></textarea>
                <div class="formLabel col-md-2">
                <small
                    class="text-danger"
                    *ngIf="
                    questionCounter > 17 &&
                    WinterHealthcareProviderForms.controls['cmd_0289'].invalid
                    "
                >
                    required
                </small>
                </div>
            </div>
            </td>
            <td colspan="9">
            <div class="form-inline no-margin sideLabelAlignment">
                <textarea
                style="width: 70%"
                class="form-control"
                id="cmd_0290"
                [class.is-invalid]="
                    questionCounters > 17 && WinterHealthcareProviderForms.controls['cmd_0290'].invalid
                "
                formControlName="cmd_0290"
                name="cmd_0290"
                data-id="Text5.2.0.8.4"
                ></textarea>
                <div class="formLabel col-md-2">
                    <small
                        class="text-danger"
                        *ngIf="
                        questionCounters > 17 &&
                        WinterHealthcareProviderForms.controls['cmd_0290'].invalid
                        "
                    >
                        required
                    </small>
                </div>
            </div>
            </td>
        </tr>
        <tr>
            <td colspan="9">
            <div class="form-group form-inline no-margin sideLabelAlignment">
                <textarea
                style="width: 70%"
                [class.is-invalid]="
                    questionCounter > 18 && WinterHealthcareProviderForms.controls['cmd_0291'].invalid
                "
                class="form-control"
                id="cmd_0291"
                formControlName="cmd_0291"
                name="cmd_0291"
                data-id="Text5.2.1.9.4"
                ></textarea>
                <div class="formLabel col-md-2">
                <small
                    class="text-danger"
                    *ngIf="
                    questionCounter > 18 &&
                    WinterHealthcareProviderForms.controls['cmd_0291'].invalid
                    "
                >
                    required
                </small>
                </div>
            </div>
            </td>
            <td colspan="9">
            <div class="form-inline no-margin sideLabelAlignment">
                <textarea
                style="width: 70%"
                class="form-control"
                id="cmd_0292"
                [class.is-invalid]="
                    questionCounters > 18 && WinterHealthcareProviderForms.controls['cmd_0292'].invalid
                "
                formControlName="cmd_0292"
                name="cmd_0292"
                data-id="Text5.2.0.9.4"
                ></textarea>
                <div class="formLabel col-md-2">
                    <small
                        class="text-danger"
                        *ngIf="
                        questionCounters > 18 &&
                        WinterHealthcareProviderForms.controls['cmd_0292'].invalid
                        "
                    >
                        required
                    </small>
                    </div>
            </div>
            </td>
        </tr>
        <tr>
            <td colspan="9">
            <div class="form-group form-inline no-margin sideLabelAlignment">
                <textarea
                style="width: 70%"
                [class.is-invalid]="
                    questionCounter > 19 && WinterHealthcareProviderForms.controls['cmd_0293'].invalid
                "
                class="form-control"
                id="cmd_0293"
                formControlName="cmd_0293"
                name="cmd_0293"
                data-id="Text5.2.0.9.4"
                ></textarea>
                <div class="formLabel col-md-2">
                <small
                    class="text-danger"
                    *ngIf="
                    questionCounter > 19 &&
                    WinterHealthcareProviderForms.controls['cmd_0293'].invalid
                    "
                >
                    required
                </small>
                </div>
            </div>
            </td>
            <td colspan="9">
            <div class="form-inline no-margin sideLabelAlignment">
                <textarea
                style="width: 70%"
                class="form-control"
                id="cmd_0294"
                formControlName="cmd_0294"
                [class.is-invalid]="
                    questionCounters > 19 && WinterHealthcareProviderForms.controls['cmd_0294'].invalid
                "
                name="cmd_0294"
                data-id="Page 10"
                ></textarea>
                <div class="formLabel col-md-2">
                    <small
                        class="text-danger"
                        *ngIf="
                        questionCounters > 19 &&
                        WinterHealthcareProviderForms.controls['cmd_0294'].invalid
                        "
                    >
                        required
                    </small>
                    </div>
            </div>
            </td>
        </tr>
        <tr>
            <td colspan="9">
            <div class="form-group form-inline no-margin sideLabelAlignment">
                <textarea
                style="width: 70%"
                [class.is-invalid]="
                    questionCounter > 20 && WinterHealthcareProviderForms.controls['cmd_0295'].invalid
                "
                class="form-control"
                id="cmd_0295"
                formControlName="cmd_0295"
                name="cmd_0295"
                data-id="Explain 10"
                ></textarea>
                <div class="formLabel col-md-2">
                <small
                    class="text-danger"
                    *ngIf="
                    questionCounter > 20 &&
                    WinterHealthcareProviderForms.controls['cmd_0295'].invalid
                    "
                >
                    required
                </small>
                </div>
            </div>
            </td>
            <td colspan="9">
            <div class="form-inline no-margin sideLabelAlignment">
                <textarea
                style="width: 70%"
                class="form-control"
                id="cmd_0296"
                formControlName="cmd_0296"
                [class.is-invalid]="
                    questionCounters > 20 && WinterHealthcareProviderForms.controls['cmd_0296'].invalid
                "
                name="cmd_0296"
                data-id="Page 10"
                ></textarea>
                <div class="formLabel col-md-2">
                    <small
                        class="text-danger"
                        *ngIf="
                        questionCounters > 20 &&
                        WinterHealthcareProviderForms.controls['cmd_0296'].invalid
                        "
                    >
                        required
                    </small>
                    </div>
            </div>
            </td>
        </tr>
        <tr>
            <td colspan="9">
            <div class="form-group form-inline no-margin sideLabelAlignment">
                <textarea
                style="width: 70%"
                [class.is-invalid]="
                    questionCounter > 21 && WinterHealthcareProviderForms.controls['cmd_0297'].invalid
                "
                class="form-control"
                id="cmd_0297"
                formControlName="cmd_0297"
                name="cmd_0297"
                data-id="Explain 10"
                ></textarea>
                <div class="formLabel col-md-2">
                <small
                    class="text-danger"
                    *ngIf="
                    questionCounter > 21 &&
                    WinterHealthcareProviderForms.controls['cmd_0297'].invalid
                    "
                >
                    required
                </small>
                </div>
            </div>
            </td>
            <td colspan="9">
            <div class="form-inline no-margin sideLabelAlignment">
                <textarea
                style="width: 70%"
                class="form-control"
                id="cmd_0298"
                [class.is-invalid]="
                questionCounters > 21 && WinterHealthcareProviderForms.controls['cmd_0298'].invalid"
                formControlName="cmd_0298"
                name="cmd_0298"
                data-id="Page 10"
                ></textarea>
                <div class="formLabel col-md-2">
                    <small
                        class="text-danger"
                        *ngIf="
                        questionCounters > 21 &&
                        WinterHealthcareProviderForms.controls['cmd_0298'].invalid
                        "
                    >
                        required
                    </small>
                    </div>
            </div>
            </td>
        </tr>
        <tr>
            <td colspan="9">
            <div class="form-group form-inline no-margin sideLabelAlignment">
                <textarea
                style="width: 70%"
                [class.is-invalid]="
                    questionCounter > 22 && WinterHealthcareProviderForms.controls['cmd_0299'].invalid
                "
                class="form-control"
                id="cmd_0299"
                formControlName="cmd_0299"
                name="cmd_0299"
                data-id="Explain 10"
                ></textarea>
                <div class="formLabel col-md-2">
                <small
                    class="text-danger"
                    *ngIf="
                    questionCounter > 22 &&
                    WinterHealthcareProviderForms.controls['cmd_0299'].invalid
                    "
                >
                    required
                </small>
                </div>
            </div>
            </td>
            <td colspan="9">
            <div class="form-inline no-margin sideLabelAlignment">
                <textarea
                style="width: 70%"
                class="form-control"
                id="cmd_0300"
                [class.is-invalid]="
                    questionCounters > 22 && WinterHealthcareProviderForms.controls['cmd_0300'].invalid
                "
                formControlName="cmd_0300"
                name="cmd_0300"
                data-id="Page 10"
                ></textarea>
                <div class="formLabel col-md-2">
                    <small
                        class="text-danger"
                        *ngIf="
                        questionCounters > 22 &&
                        WinterHealthcareProviderForms.controls['cmd_0300'].invalid
                        "
                    >
                        required
                    </small>
                    </div>
            </div>
            </td>
        </tr>
        <tr>
          <td colspan="9">
            <div class="form-inline no-margin sideLabelAlignment">
              <textarea
                style="width: 70%"
                class="form-control"
                id="cmd_0301"
                formControlName="cmd_0301"
                name="cmd_0301"
                data-id="Explain 10"
              ></textarea>
            </div>
          </td>
          <td colspan="9">
            <div class="form-inline no-margin sideLabelAlignment">
              <textarea
                style="width: 70%"
                class="form-control"
                id="cmd_0302"
                formControlName="cmd_0302"
                name="cmd_0302"
                data-id="Page 10"
              ></textarea>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="9">
            <div class="form-inline no-margin sideLabelAlignment">
              <textarea
                style="width: 70%"
                class="form-control"
                id="cmd_0303"
                formControlName="cmd_0303"
                name="cmd_0303"
                data-id="Explain 10"
              ></textarea>
            </div>
          </td>
          <td colspan="9">
            <div class="form-inline no-margin sideLabelAlignment">
              <textarea
                style="width: 70%"
                class="form-control"
                id="cmd_0304"
                formControlName="cmd_0304"
                name="cmd_0304"
                data-id="Page 10"
              ></textarea>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="9">
            <div class="form-inline no-margin sideLabelAlignment">
              <textarea
                style="width: 70%"
                class="form-control"
                id="cmd_0305"
                formControlName="cmd_0305"
                name="cmd_0305"
                data-id="Explain 10"
              ></textarea>
            </div>
          </td>
          <td colspan="9">
            <div class="form-inline no-margin sideLabelAlignment">
              <textarea
                style="width: 70%"
                class="form-control"
                id="cmd_0306"
                formControlName="cmd_0306"
                name="cmd_0306"
                data-id="Page 10"
              ></textarea>
            </div>
          </td>
        </tr>
      </table> 
      
      <hr class="tablePersonal" />
      <div class="col-md-1">
        <img  class="logoImg" src="../../../../assets/images/winter.png"
          alt="Credential my Doc" />
      </div>
      <br><br>
      <tr>
        <td colspan="18" style="padding:20px;">
          <i class="attestion">ATTESTATION STATEMENT</i>
        </td>
      </tr>
      <tr>
        <td style="padding:20px;">
          <div>
            <label>I,    
              <input style="border: none;
              border-bottom: 1px solid black;width:20%;" id="cmd_0897" name="cmd_0897" type="text" formControlName="cmd_0897"
                placeholder="Printed Name">
                    , agree as evidenced by my signature that the information provided in this
                application is true and complete to the best of my knowledge and that the omission or falsification of information may be cause
                of ineligibility or termination from dental staff membership. I further agree that I have current professional liability coverage and I
                have disclosed the history of loss or limitation of privileges or disciplinary action. </label>
  
          </div>
        </td>
      </tr>
      <tr>
        <td style="padding:20px;">
            <div class="row">
                <div class="col-md-2" style="padding-left:60px">
                    <label (click)="esignOpenOne()">Signature:</label>
                </div>  
                <div class="col-md-4">
                        <img *ngIf="!showOne" class="img" src="./assets/images/e_sign.png" alt="Credential my Doc"
                    (click)="esignOpenOne()" />
                        <img *ngIf="showOne" class="img eSignImageShow" [src]="esignOne" alt="Credential my Doc"
                    (click)="esignOpenOne()" />
                        <input type="hidden" name="cmd_11122" id="cmd_11122" [class.is-invalid]="(WinterHealthcareProviderForms.controls['cmd_11122'].touched && WinterHealthcareProviderForms.controls['cmd_11122'].invalid) || (WinterHealthcareProviderForms.controls['cmd_11122'].invalid && isSubmit)" formControlName="cmd_11122" value="{{ finalImgPath }}" />
                        <div class="invalid-feedback"
                            *ngIf="(WinterHealthcareProviderForms.controls['cmd_11122'].touched && WinterHealthcareProviderForms.controls['cmd_11122'].invalid) || (WinterHealthcareProviderForms.controls['cmd_11122'].invalid && isSubmit)">
                                 Signature is required and cannot be empty
                        </div>                                   
                        
                </div>
                <div class="col-md-1">
                    <label style="font-weight: normal;font-size: 14px">Date:</label>
                </div>
                <div class="col-md-3">
                    <div class="input-group">
                            <input type="text" placeholder="Enter Date" id="cmd_0310" class="form-control"      (blur)="clearInputIfInvalidMandi('cmd_0310')" formControlName="cmd_0310"
                            [class.is-invalid]="(WinterHealthcareProviderForms.controls['cmd_0310'].touched && WinterHealthcareProviderForms.controls['cmd_0310'].invalid ) || (WinterHealthcareProviderForms.controls['cmd_0310'].invalid && isSubmit)" ngbDatepicker #d0020="ngbDatepicker">
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary calendar" (click)="d0020.toggle()" type="button">
                                    <i class="fas fa-calendar-alt"></i>
                                </button>
                            </div>
                    </div>    
                
                  
                    <div class="text-danger"
                      *ngIf="(WinterHealthcareProviderForms.controls['cmd_0310'].invalid&&WinterHealthcareProviderForms.controls['cmd_0310'].touched) && (WinterHealthcareProviderForms.controls['cmd_0310'].value==''|| WinterHealthcareProviderForms.controls['cmd_0310'].value==null) ">
                      <small>
                        Date is required and cannot be empty
                      </small>
                    
                    </div>
                    <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('cmd_0310').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY) </small>
                    <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('cmd_0310').errors?.invalidMonth">
                      Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('cmd_0310').errors?.invalidDateyear">
                      year is out of range(1900-2099) </small>
                    <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('cmd_0310').errors?.invalidDateRange">
                      date is out of range
                    </small>
                </div>
            </div>    
        </td>
      </tr>
    <tr>
      <td style="padding:20px;">
        <div class="row">
          <div class="col-md-3">
            <label class="formLabel">
              Print Name:
            </label>
          </div>
          <div class="col-md-9">
            <input  id="cmd_0897" name="cmd_0897" type="text" formControlName="cmd_0897"
                placeholder="Printed Name" class="form-control">
          </div>
        </div>
      </td>
    </tr> 
    <br><br><br><br><br><br><br><br><br><br>
    <hr class="tablePersonal" />
    <div class="col-md-1">
      <img  class="logoImg" src="../../../../assets/images/winter.png"
        alt="Credential my Doc" />
    </div>
    
    <table border="1" width="100%" >
      <tr>
        <td colspan="18">
          
            <b style="font-size:16px;">III. Fitness for Duty</b>
            
        </td>
      </tr>
      <tr>
        <td colspan="6" style="width:90%;">
          
        </td>
        <td colspan="6" style="width:5%;">
          <b>Yes</b>
        </td>
        <td colspan="6" style="width:5%;">
          <b>No</b>
        </td>
      </tr>
      <tr>
        <td colspan="6" style="width:90%;">
          
            <label>Do you presently have any physical or mental condition, including alcohol or drug abuse that may affect
              your ability to perform clinical or professional duties? <br>If Yes,</label>
              <input class="form-control onboard"  formControlName="cmd_0084"
              id="cmd_0084" name="cmd_0084" type="text"  (blur)="handleYesNoChanges('cmd_0081')"  id="cmd_0018" [class.is-invalid]="WinterHealthcareProviderForms.controls['cmd_0084'].invalid" data-id="managing_contact_name" >
              <div class="mandatory" *ngIf="WinterHealthcareProviderForms.controls['cmd_0084'].invalid">
                <small >
                required
                </small>
              </div>
        </td>
        <td colspan="6" style="width:5%;">                          
              <input type="radio" id="cmd_0081" name="cmd_0081" (ngModelChange)="handleYesNoChanges('cmd_0081')" [class.is-invalid]="(WinterHealthcareProviderForms.controls['cmd_0081'].touched && WinterHealthcareProviderForms.controls['cmd_0081'].invalid) || (WinterHealthcareProviderForms.controls['cmd_0081'].invalid && isSubmit)" formControlName="cmd_0081" value="1">                                    
        </td>
        <td colspan="6" style="width:5%;">
          
            <input type="radio" id="cmd_0081" name="cmd_0081" (ngModelChange)="handleYesNoChanges('cmd_0081')" [class.is-invalid]="(WinterHealthcareProviderForms.controls['cmd_0081'].touched && WinterHealthcareProviderForms.controls['cmd_0081'].invalid) || (WinterHealthcareProviderForms.controls['cmd_0081'].invalid && isSubmit)" formControlName="cmd_0081" value="0">
            <div class="formLabel col-md-11"
                *ngIf="WinterHealthcareProviderForms.controls['cmd_0081'].touched && WinterHealthcareProviderForms.controls['cmd_0081'].invalid">
                <small class="text-danger" *ngIf="WinterHealthcareProviderForms.controls['cmd_0081'].errors?.required">
                    required
                </small>
              </div>
              <span *ngIf="(WinterHealthcareProviderForms.controls['cmd_0081'].touched && WinterHealthcareProviderForms.controls['cmd_0081'].errors) || (WinterHealthcareProviderForms.controls['cmd_0081'].errors && isSubmit)">
                <i class="fas fa-times ml-2 text-danger"></i>
              </span>
        </td>
      </tr>
      <tr>
        <td colspan="6" style="width:90%;">
          
            <label>Are you currently taking any medications that may affect your ability to perform clinical or professional
              duties? <br>If Yes,</label>
              <input class="form-control onboard"  formControlName="cmd_0085"
              id="cmd_0085" name="cmd_0085" type="text"  (blur)="handleYesNoChanges('cmd_0082')"  id="cmd_0085" [class.is-invalid]="WinterHealthcareProviderForms.controls['cmd_0085'].invalid" data-id="managing_contact_name" >
              <div class="mandatory" *ngIf="WinterHealthcareProviderForms.controls['cmd_0085'].invalid">
                <small >
                required
                </small>
              </div>
              
        </td>
        <td colspan="6" style="width:5%;">                          
              <input type="radio" id="cmd_0082" (ngModelChange)="handleYesNoChanges('cmd_0082')" name="cmd_0082" [class.is-invalid]="(WinterHealthcareProviderForms.controls['cmd_0082'].touched && WinterHealthcareProviderForms.controls['cmd_0082'].invalid) || (WinterHealthcareProviderForms.controls['cmd_0082'].invalid && isSubmit)" formControlName="cmd_0082" value="1">                                    
             
        </td>
        <td colspan="6" style="width:5%;">
          
            <input type="radio" id="cmd_0082" name="cmd_0082" (ngModelChange)="handleYesNoChanges('cmd_0082')" [class.is-invalid]="(WinterHealthcareProviderForms.controls['cmd_0082'].touched && WinterHealthcareProviderForms.controls['cmd_0082'].invalid) || (WinterHealthcareProviderForms.controls['cmd_0082'].invalid && isSubmit)" formControlName="cmd_0082" value="0">
            <div class="formLabel col-md-11"
                *ngIf="WinterHealthcareProviderForms.controls['cmd_0082'].touched && WinterHealthcareProviderForms.controls['cmd_0082'].invalid">
                <small class="text-danger" *ngIf="WinterHealthcareProviderForms.controls['cmd_0082'].errors?.required">
                    required
                </small>
              </div>
              <span *ngIf="(WinterHealthcareProviderForms.controls['cmd_0082'].touched && WinterHealthcareProviderForms.controls['cmd_0082'].errors) || (WinterHealthcareProviderForms.controls['cmd_0082'].errors && isSubmit)">
                <i class="fas fa-times ml-2 text-danger"></i>
              </span>
        </td>
      </tr>
      <tr>
        <td colspan="6" style="width:90%;">
          
            <label>Do you have any communicable diseases?<br>If Yes,</label>
            <input class="form-control onboard"  formControlName="cmd_0086"
              id="cmd_0086" name="cmd_0086" type="text"  (blur)="handleYesNoChanges('cmd_0083')"  id="cmd_0086" [class.is-invalid]="WinterHealthcareProviderForms.controls['cmd_0086'].invalid" data-id="managing_contact_name" >
              <div class="mandatory" *ngIf="WinterHealthcareProviderForms.controls['cmd_0086'].invalid">
                <small >
                required
                </small>
              </div>
              
        </td>
        <td colspan="6" style="width:5%;">                          
              <input type="radio" id="cmd_0083" name="cmd_0083" (ngModelChange)="handleYesNoChanges('cmd_0083')" [class.is-invalid]="(WinterHealthcareProviderForms.controls['cmd_0083'].touched && WinterHealthcareProviderForms.controls['cmd_0083'].invalid) || (WinterHealthcareProviderForms.controls['cmd_0083'].invalid && isSubmit)" formControlName="cmd_0083" value="1">                                    
              
        </td>
        <td colspan="6" style="width:5%;">
          
            <input type="radio" id="cmd_0083" name="cmd_0083" (ngModelChange)="handleYesNoChanges('cmd_0083')" [class.is-invalid]="(WinterHealthcareProviderForms.controls['cmd_0083'].touched && WinterHealthcareProviderForms.controls['cmd_0083'].invalid) || (WinterHealthcareProviderForms.controls['cmd_0083'].invalid && isSubmit)" formControlName="cmd_0083" value="0">
            <div class="formLabel col-md-11"
                *ngIf="WinterHealthcareProviderForms.controls['cmd_0083'].touched && WinterHealthcareProviderForms.controls['cmd_0083'].invalid">
                <small class="text-danger" *ngIf="WinterHealthcareProviderForms.controls['cmd_0083'].errors?.required">
                    required
                </small>
              </div>
              <span *ngIf="(WinterHealthcareProviderForms.controls['cmd_0083'].touched && WinterHealthcareProviderForms.controls['cmd_0083'].errors) || (WinterHealthcareProviderForms.controls['cmd_0083'].errors && isSubmit)">
                <i class="fas fa-times ml-2 text-danger"></i>
              </span>
        </td>
      </tr>
      
      <tr>
        <td colspan="18" style="padding:10px;">
          <div class="row">
            <div class="col-md-3"  > 
              <input class="form-control"  [class.is-invalid]="(WinterHealthcareProviderForms.controls['cmd_0087'].touched && WinterHealthcareProviderForms.controls['cmd_0087'].invalid) || (WinterHealthcareProviderForms.controls['cmd_0087'].invalid && isSubmit)"  id="cmd_0087" name="cmd_0087" type="text" formControlName="cmd_0087"
               >
               <div class="formLabel col-md-9"
                                  *ngIf="WinterHealthcareProviderForms.controls['cmd_0087'].touched && WinterHealthcareProviderForms.controls['cmd_0087'].invalid">
                                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.controls['cmd_0087'].errors?.required">
                                      required
                                  </small>
                              </div> 
            </div>   
            <div class="col-md-10"> 
              <label>
                Please initial to certify that you are in good health and have no physical or mental conditions that may
                affect your ability to perform clinical or professional duties.
                  </label>
            </div>      
  
          </div>
          <br><br><br>
          <div class="row">
            <div class="col-md-3">
                <label>   Most recent physical exam performed by: </label>
            </div>
            <div class="col-md-4">    
                  <input  id="cmd_0088" class="form-control" name="cmd_0088" type="text" formControlName="cmd_0088"
                  >
            </div>  
            <div class="col-md-4"> 
                  <div class="input-group">
                    <input type="text"   id="cmd_0089" class="form-control" formControlName="cmd_0089"   (blur)="clearInputIfInvalid('cmd_0089')"
                    ngbDatepicker #d0089="ngbDatepicker" >
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d0089.toggle()" type="button">
                        <i class="fas fa-calendar-alt"></i>
                        </button>
                    </div>
                </div> 
                <div  *ngIf="WinterHealthcareProviderForms.get('cmd_0089').invalid ">
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('cmd_0089').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('cmd_0089').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('cmd_0089').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('cmd_0089').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
            </div>    
          </div>
        </td>
      </tr>
      <tr>
        <td colspan="18" style="padding:20px">
          <div class="row">
            <div class="col-md-3">
                <label>   Results of examination: </label>
            </div>
            <div class="col-md-4">    
                  <input  id="cmd_00900" class="form-control" name="cmd_00900" type="text" formControlName="cmd_00900"
                  >
            </div> 
          </div>  
        </td>
      </tr>
      <tr>
        <td colspan="18">
          <div class="row">
            <div class="col-md-3">
              <label class="formLabel">
                Print Name:
              </label>
            </div>
            <div class="col-md-7">
              <input  id="cmd_0897" name="cmd_0897" type="text" formControlName="cmd_0897"
                  placeholder="Printed Name" class="form-control">
              <div class="formLabel col-md-11"
                  *ngIf="WinterHealthcareProviderForms.controls['cmd_0897'].touched && WinterHealthcareProviderForms.controls['cmd_0897'].invalid">
                  <small class="text-danger" *ngIf="WinterHealthcareProviderForms.controls['cmd_0897'].errors?.required">
                      required
                  </small>
              </div>    
            </div>
          </div>
      </td>
      </tr>
      <tr>
        <td colspan="18">
            <div class="row">
                <div class="col-md-2" style="padding-left:60px">
                    <label (click)="esignOpenTwo()">Signature:</label>
                </div>  
                <div class="col-md-4">
                        <img *ngIf="!showTwo" class="img" src="./assets/images/e_sign.png" alt="Credential my Doc"
                    (click)="esignOpenTwo()" />
                        <img *ngIf="showTwo" class="img eSignImageShow" [src]="esignTwo" alt="Credential my Doc"
                    (click)="esignOpenTwo()" />
                        <input type="hidden" name="cmd_0091" id="cmd_0091" [class.is-invalid]="(WinterHealthcareProviderForms.controls['cmd_0091'].touched && WinterHealthcareProviderForms.controls['cmd_0091'].invalid) || (WinterHealthcareProviderForms.controls['cmd_0091'].invalid && isSubmit)" formControlName="cmd_0091" value="{{ finalImgPath }}" />
                        <div class="invalid-feedback"
                            *ngIf="(WinterHealthcareProviderForms.controls['cmd_0091'].touched && WinterHealthcareProviderForms.controls['cmd_0091'].invalid) || (WinterHealthcareProviderForms.controls['cmd_0091'].invalid && isSubmit)">
                                 Signature is required and cannot be empty
                        </div>                                   
                        
                </div>
                <div class="col-md-1">
                    <label style="font-weight: normal;font-size: 14px">Date:</label>
                </div>
                <div class="col-md-3">
                    <div class="input-group">
                            <input type="text" placeholder="Enter Date" id="cmd_0090" (blur)="clearInputIfInvalidMandi('cmd_0090')" class="form-control" formControlName="cmd_0090"
                            [class.is-invalid]="(WinterHealthcareProviderForms.controls['cmd_0090'].touched && WinterHealthcareProviderForms.controls['cmd_0090'].invalid ) || (WinterHealthcareProviderForms.controls['cmd_0090'].invalid && isSubmit)" ngbDatepicker #d0090="ngbDatepicker">
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary calendar" (click)="d0090.toggle()" type="button">
                                    <i class="fas fa-calendar-alt"></i>
                                </button>
                            </div>
                    </div>    
                    <div class="text-danger"
                      *ngIf="(WinterHealthcareProviderForms.controls['cmd_0090'].invalid&&WinterHealthcareProviderForms.controls['cmd_0090'].touched) && (WinterHealthcareProviderForms.controls['cmd_0090'].value==''|| WinterHealthcareProviderForms.controls['cmd_0090'].value==null) ">
                      <small>
                        Date is required and cannot be empty
                      </small>
                    
                    </div>
                    <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('cmd_0090').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY) </small>
                    <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('cmd_0090').errors?.invalidMonth">
                      Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('cmd_0090').errors?.invalidDateyear">
                      year is out of range(1900-2099) </small>
                    <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('cmd_0090').errors?.invalidDateRange">
                      date is out of range
                    </small>
                
                    <!-- <div class="invalid-feedback"
                        *ngIf="(WinterHealthcareProviderForms.controls['cmd_0090'].touched && WinterHealthcareProviderForms.controls['cmd_0090'].invalid) || (WinterHealthcareProviderForms.controls['cmd_0090'].invalid && isSubmit)">
                        Date is required and cannot be empty
                    </div> -->
                </div>
            </div>    
        </td>
      </tr>
    </table>  
    <hr class="tablePersonal" />
    <div class="col-md-1">
      <img  class="logoImg" src="../../../../assets/images/winter.png"
        alt="Credential my Doc" />
    </div>
    <table>
      <tr >
        <th style="padding:20px;">
          <i class="attestion">WINTERS HEALTCHARE RELEASE AND IMMUNITY STATEMENT</i>
        </th>
      </tr>
      <tr>
        <td colspan="18" style="padding-left:10px;">
          <label class="formLabel">
            I hereby consent to the disclosure, inspection, and copying of information and documents relating to my credentials and qualifications and
            performance (“credentialing information”) by and between “this Healthcare Organization” and other Healthcare Organizations (e.g., hospital
            medical staffs, medical groups, independent practice associations, health plans, health maintenance organizations, preferred provider
            organizations, other health delivery systems or entities, medical societies, professional associations, medical school faculty positions, training
            programs, professional liability insurance companies (with respect to certification of coverage and claims history), licensing authorities, and recredentialing application regarding my professional training, experience, character, conduct of judgement, ethics, and ability to work with others.
            In this regard, the utmost care shall be taken to safeguard the privacy of patients and the confidentiality of peer records, and to protect peer
            review information from being further disclosed.
          </label>
          <label class="formLabel">
            I am informed and acknowledge that federal and state laws provide immunity protections to certain individuals and entities for their acts and/or
            communications in connection with evaluation the qualifications of healthcare providers. I hereby release all person and entities, including this
            Healthcare Organization, engaged in quality assessment, peer review and credentialing on behalf of this Healthcare Organization, and all persons
            and entities providing credentialing information to such representatives of this Healthcare Organization, from any liability they might incur for their
            acts and/or communications are protected by state or federal law.
          </label>
          <label class="formLabel">
            I understand that I shall be afforded such fair procedures with respect to my participation in this Healthcare Organization as may be required by
            state and federal law and regulation, including but not limited to, California Business and Professions Code Section 809 et seq., if applicable.
          </label>
          <label class="formLabel">
            I understand and agree that I, as an applicant, have the burden of producing adequate information for proper evaluation of my professional
            competence, character, ethics, and other qualifications and for resolving any doubt about such qualifications.
          </label>
          <label class="formLabel">
            During such time as this application is being processed, I agree to update the application should there be any change in the information provided.
          </label>
          <label class="formLabel">
            In addition to any notice required by any contract with a Healthcare Organization, I agree to notify this Healthcare Organization immediately in
            writing of the occurrence of any of the following: (i) the unstayed suspension, revocation or nonrenewal of my professional licensure in any
            jurisdiction; (ii) any suspension, revocation or nonrenewal of my DEA or other controlled substances registration; or (iii) any cancellation or
            nonrenewal of my professional liability insurance coverage.
          </label>
          <label class="formLabel">
            I further agree to notify this Healthcare Organization in writing, within five (5) business days from the occurrence of any of the following: (i) receipt
            of written notice of any adverse action against me, by any professional licensing agency taken or pending, including but not limited to, any
            accusation filed, temporary restraining order of imposition of any interim suspension, probation or limitations affecting my professional licensure;
            or (ii) any adverse action against me by any Healthcare Organization, which has resulted in the filing of a Section 805 or Section 805.041 report with
            the nonrenewal or voluntary relinquishment by resignation of my medical staff membership of clinical privileges at any Healthcare Organization; or
            (iv) any material reduction in my professional liability insurance coverage; or (v) my receipt of written notice of any legal action against me,
            including, but not limited to, fraud and abuse proceedings or convictions.
          </label>
          <label class="formLabel">
            I pledge to provide continuous care for my patients.
          </label>
          <label class="formLabel">
            I hereby affirm that the information submitted in this application and any addenda thereto (including my curriculum vitae if attached) is current,
            correct, complete, and true to the best of my knowledge and belief and is furnished in good faith. I understand that material omissions or
            misrepresentations may result in denial of my application or termination of my privileges, employment or physician participation agreement.
          </label>
          <label class="formLabel">
            A photocopy of this document shall be as effective as the original.
          </label>
        </td>
      </tr>
      <tr>
        <td colspan="18">
            <div class="row">
                <div class="col-md-2" style="padding-left:60px">
                    <label (click)="esignOpenThree()">Signature:</label>
                </div>  
                <div class="col-md-4">
                        <img *ngIf="!showThree" class="img" src="./assets/images/e_sign.png" alt="Credential my Doc"
                    (click)="esignOpenThree()" />
                        <img *ngIf="showThree" class="img eSignImageShow" [src]="esignThree" alt="Credential my Doc"
                    (click)="esignOpenThree()" />
                        <input type="hidden" name="cmd_0093" id="cmd_0093" [class.is-invalid]="(WinterHealthcareProviderForms.controls['cmd_0093'].touched && WinterHealthcareProviderForms.controls['cmd_0093'].invalid) || (WinterHealthcareProviderForms.controls['cmd_0093'].invalid && isSubmit)" formControlName="cmd_0093" value="{{ finalImgPath }}" />
                        <div class="invalid-feedback"
                            *ngIf="(WinterHealthcareProviderForms.controls['cmd_0093'].touched && WinterHealthcareProviderForms.controls['cmd_0093'].invalid) || (WinterHealthcareProviderForms.controls['cmd_0093'].invalid && isSubmit)">
                                 Signature is required and cannot be empty
                        </div>                                   
                        
                </div>
                <div class="col-md-1">
                    <label style="font-weight: normal;font-size: 14px">Date:</label>
                </div>
                <div class="col-md-3">
                    <div class="input-group">
                            <input type="text" placeholder="Enter Date" id="cmd_0092" (blur)="clearInputIfInvalidMandi('cmd_0092')" class="form-control" formControlName="cmd_0092"
                            [class.is-invalid]="(WinterHealthcareProviderForms.controls['cmd_0092'].touched && WinterHealthcareProviderForms.controls['cmd_0092'].invalid ) || (WinterHealthcareProviderForms.controls['cmd_0092'].invalid && isSubmit)" ngbDatepicker #d0092="ngbDatepicker">
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary calendar" (click)="d0092.toggle()" type="button">
                                    <i class="fas fa-calendar-alt"></i>
                                </button>
                            </div>
                    </div>    
                    <div class="text-danger"
                      *ngIf="(WinterHealthcareProviderForms.controls['cmd_0092'].invalid&&WinterHealthcareProviderForms.controls['cmd_0092'].touched) && (WinterHealthcareProviderForms.controls['cmd_0092'].value==''|| WinterHealthcareProviderForms.controls['cmd_0092'].value==null) ">
                      <small>
                        Date is required and cannot be empty
                      </small>
                    
                    </div>
                    <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('cmd_0092').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY) </small>
                    <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('cmd_0092').errors?.invalidMonth">
                      Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('cmd_0092').errors?.invalidDateyear">
                      year is out of range(1900-2099) </small>
                    <small class="text-danger" *ngIf="WinterHealthcareProviderForms.get('cmd_0092').errors?.invalidDateRange">
                      date is out of range
                    </small>
                
                    <!-- <div class="invalid-feedback"
                        *ngIf="(WinterHealthcareProviderForms.controls['cmd_0092'].touched && WinterHealthcareProviderForms.controls['cmd_0092'].invalid) || (WinterHealthcareProviderForms.controls['cmd_0092'].invalid && isSubmit)">
                        Date is required and cannot be empty
                    </div> -->
                </div>
            </div>    
        </td>
      </tr>
      <tr>
        <td style="padding:20px;">
          <div class="row">
            <div class="col-md-3">
              <label class="formLabel">
                Print Name:
              </label>
            </div>
            <div class="col-md-7">
              <input  id="cmd_0897" name="cmd_0897" type="text" formControlName="cmd_0897"
                  placeholder="Printed Name" class="form-control">
            </div>
          </div>
        </td>
      </tr> 
    </table>
     
        
        
            
            
        
    </form>
  </div>  


  <div class="modal-footer mt-4">
      <button *ngIf="formData.receivedForm" type="button" class="btn customOrange float-right"
      (click)="saveForm('approve')">Approve</button>
      <button *ngIf="formData.submitForm" type="submit" class="btn btn customOrange float-right"
      (click)="saveForm('submit')">Submit</button>
      <button *ngIf="formData.submitForm" type="button" class="btn btn customOrange float-right"
      (click)="saveForm('save')">Save</button>
      <button *ngIf="formData.previewForm" type="button" class="btn customOrange float-right" (click)="close()">Close</button>
  </div>
</div>    

  
