import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, FormControl, ValidationErrors } from '@angular/forms';
import { OnboardingformsServiceService } from '../../services/onboardingforms-service/onboardingforms-service.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToasterNotiService } from '../../services/notifications/toaster-noti.service';
import {
  NgbModal,
  NgbModalOptions,
  NgbDateAdapter,
  NgbDateParserFormatter
} from '@ng-bootstrap/ng-bootstrap';
import {
  CustomAdapter,
  CustomDateParserFormatter
} from '../../DateFormatter/customDate';

@Component({
  selector: 'ctg-kids-dental',
  templateUrl: './kids-dental.component.html',
  styleUrls: ['./kids-dental.component.scss'],
  providers: [
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }
  ]
})
export class KidsDentalComponent implements OnInit {
  @Input() formData;
  @Input() fieldsData;
  @Input() formId;
  @Output() onSubmit = new EventEmitter();
  @Output() onClose = new EventEmitter();
  public kidsDental: UntypedFormGroup;
  suffix: Array<object> = [];
  states: Array<object> = [];
  languages: Array<object> = [];
  secondaryLanguages: Array<object> = [];
  tertiaryLanguages: Array<object> = [];
  quaternaryLanguages: Array<object> = [];
  credentials: Array<object> = [];
  staffStatus: Array<object> = [];
  submitted: boolean;
  changeCmdOne: any;
  changeCmdTwo: any;
  changeCmdThree: any;
  isSubmit: boolean = false;

  constructor(
    private fb: UntypedFormBuilder,
    private onboardService: OnboardingformsServiceService,
    private spinner: NgxSpinnerService,
    private notiService: ToasterNotiService,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.buildForm();
    this.getSuffixData();
    this.getStatesData();
    this.getLanguagesData();
    this.getsecondaryLanguagesData();
    this.gettertiaryLanguagesData();
    this.getquaternaryLanguagesData();
    this.getCredentialsData();
    this.getStaffStatusData();
    this.fieldsData && this.bindFormValue(this.fieldsData);
    this.afterSaveDataDisplay();
    
  }

  public bindFormValue(array) {
    let fieldsCtrls = {};
    for (let i = 0; i < array.length; i++) {
      fieldsCtrls[array[i].field_name] = array[i].field_value;
      if (array[i].field_name == 'cmd_1481') {
        if (array[i].field_value == 1) {
          // console.log(array[i].field_value);
          this.changeCmdOne = 1;
        } else {
          this.changeCmdOne = 0;
        }
      }
      if (array[i].field_name == 'cmd_1482') {
        if (array[i].field_value == 1) {
          this.changeCmdTwo = 1;
        } else {
          this.changeCmdTwo = 0;
          
    
        }
      }
      if (array[i].field_name == 'cmd_1489') {
        if (array[i].field_value == 1) {
          this.changeCmdThree = 1;
        } else {
          this.changeCmdThree = 0;
        }
      }
    }
    this.kidsDental.patchValue(fieldsCtrls);
  }

  buildForm() {
    this.kidsDental = this.fb.group({
      8: ['', [Validators.required]],
      9: ['', [Validators.required]],
      29: [null, [Validators.required]],
      278: [''],
      31: [null, [Validators.required,this.dateFormatValidator]],
      810: ['', [Validators.required]],
      289: [null, [Validators.required]],
      16: ['', [Validators.required]],
      17: [''],
      18: ['', [Validators.required]],
      20: [null, [Validators.required]],
      21: ['', [Validators.required]],
      cmd_1480: ['', [Validators.required]],
      708: ['', [Validators.required]],
      32: ['', [Validators.required]],
      759: ['', [Validators.required]],
      cmd_1481: ['', [Validators.required]],
      cmd_1484: [''],
      277: [null, [Validators.required]],
      1535: [null],
      1536: [null],
      2207: [null],
      cmd_1482: ['', [Validators.required]],
      1141: [''],
      1144: [null, [this.dateFormatValidator]],
      1145: [null, [this.dateFormatValidator]],
      cmd_1489: ['', [Validators.required]],
      1314: [null],
      812: [''],
      1634: [''],
      333: [''],
      334: [''],
      335: [''],
      cmd_1486: [''],
      cmd_1487: [''],
      cmd_1488: [''],
      1635: [''],
      1636: [''],
      cmd_1483: ['', [Validators.required]],
      359: [''],
      369: [''],
      371: [''],
      2721: [''],
      372: [''],
      381: [''],
      383: [''],
      2724: [''],
      384: [''],
      394: [''],
      396: [''],
      2726: [''],
      418: [''],
      894: [null],
      419: [''],
      420: [''],
      421: [''],
      423: [null],
      424: [null],
      426: [null, [this.dateFormatValidator]],
      1370: [null, [this.dateFormatValidator]],
      427: [''],
      895: [null],
      428: [''],
      429: [''],
      430: [''],
      432: [null],
      433: [''],
      435: [null, [this.dateFormatValidator]],
      1371: [null, [this.dateFormatValidator]],
      436: [''],
      896: [null],
      437: [''],
      438: [''],
      439: [''],
      441: [null],
      442: [''],
      444: [null, [this.dateFormatValidator]],
      1372: [null, [this.dateFormatValidator]]
    });
    // console.log(this.kidsDental);
  }

  public getSuffixData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getSuffixData(params).subscribe(
      data => {
        if (data['success']) {
          this.suffix = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getStatesData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getStatesData(params).subscribe(
      data => {
        if (data['success']) {
          this.states = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getLanguagesData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getLanguagesData(params).subscribe(
      data => {
        if (data['success']) {
          this.languages = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public afterSaveDataDisplay() {
    let params = {
      type: 26,
      formId: this.formId
    };
    //this.spinner.show();
    this.onboardService.toGetSaveData(params).subscribe(
      data => {
        if (data['success']) {
          this.bindFormValue(data.data);
        } else {
          //this.notiService.showError(data.error, '', 3000)
        }
        //this.spinner.hide();
      },
      error => {
        //this.notiService.showError(Error_Message, '', 3000)
      }
    );
  }

  public getsecondaryLanguagesData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getsecondaryLanguagesData(params).subscribe(
      data => {
        if (data['success']) {
          this.secondaryLanguages = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public gettertiaryLanguagesData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.gettertiaryLanguagesData(params).subscribe(
      data => {
        if (data['success']) {
          this.tertiaryLanguages = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getquaternaryLanguagesData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getquaternaryLanguagesData(params).subscribe(
      data => {
        if (data['success']) {
          this.quaternaryLanguages = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getCredentialsData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getCredentialsData(params).subscribe(
      data => {
        if (data['success']) {
          this.credentials = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getStaffStatusData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getStaffStatusData(params).subscribe(
      data => {
        if (data['success']) {
          this.staffStatus = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
          // console.log(this.kidsDental);
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  saveForm(type) {
    this.isSubmit = true;
    console.log(this.kidsDental);
    if (type == 'submit') {
      if (
        this.kidsDental.invalid ||
        (this.changeCmdOne &&
          this.kidsDental.controls['cmd_1484'].value == '') ||
        (this.changeCmdTwo &&
         ( this.kidsDental.controls['1141'].value == '' ||
          (this.kidsDental.controls['1144'].value == null ||
          this.kidsDental.controls['1145'].value == null)))
      ) {
        this.notiService.showError(
          'Please Fill the all mandatory fields to continue',
          '',
          3000
        );
      } else {
        let data = {
          formType: type,
          formValues: this.kidsDental.value
        };
        this.onSubmit.emit(data);
      }
    } else {
      let data = {
        formType: type,
        formValues: this.kidsDental.value,
        formId: 26
      };
      this.onSubmit.emit(data);
      //this.onClose.emit('close modal');
    }
  }

  onChangeCmdOne(event) {
    if (event.target.value == 1) {
      this.changeCmdOne = true;
    } else {
      this.changeCmdOne = false;
    }
  }

  onChangeCmdTwo(event) {
    if (event.target.value == 1) {
      this.changeCmdTwo = true;
    } else {
      this.changeCmdTwo = false;
      this.kidsDental.get("1144").setErrors(null);
      this.kidsDental.get("1145").setErrors(null);
    }
  }

  onChangeCmdThree(event) {
    if (event.target.value == 1) {
      this.changeCmdThree = true;
    } else {
      this.changeCmdThree = false;
    }
  }

  close() {
    this.onClose.emit('close modal');
  }
  clearInputIfInvalidForYesorNo(controlName: string,handleYesNoValue?:string) {
    const control = this.kidsDental.get(controlName);
    const dateString = control.value;
  
    // Use the dateFormatValidator to check if the date is valid
    const validationErrors = this.dateFormatValidator(new FormControl(dateString));
  
    if (validationErrors) {
      // If any of the validation conditions are met, clear the input field and set errors to null
      control.setValue(null);
      control.setErrors({ errors: true });
    }
    // if(handleYesNoValue ){
    //   // if(handleYesNoValue == ("cmd_05" || "cmd_024" || "cmd_023")){
    //   //   this.handleYesNoChanges(handleYesNoValue);
    //   // }
    //   // else{
    //   //     this.handleNAchange(handleYesNoValue);
    //   // }
      
      
    // }
    
  }
  clearInputIfInvalidMandi(controlName: string) {
    const control = this.kidsDental.get(controlName);
    const dateString = control.value;
  
    // Use the dateFormatValidator to check if the date is valid
    const validationErrors = this.dateFormatValidator(new FormControl(dateString));
  
    if (validationErrors) {
      // If any of the validation conditions are met, clear the input field and set errors to null
      control.setValue('');

    }}
    clearInputIfInvalid(controlName: string,handleYesNoValue?:string) {
      const control = this.kidsDental.get(controlName);
      const dateString = control.value;
    
      // Use the dateFormatValidator to check if the date is valid
      const validationErrors = this.dateFormatValidator(new FormControl(dateString));
    
      if (validationErrors) {
        // If any of the validation conditions are met, clear the input field and set errors to null
        control.setValue('');
        control.setErrors(null);
      }
     
      
    }
  dateFormatValidator(control: FormControl): ValidationErrors | null {
    const dateString = control.value;
    const dateFormat = /^\d{2}\/\d{2}\/\d{4}$/;
  
    if (dateString && !dateString.match(dateFormat)) {
      return { 'invalidDateFormat': true };
    }
  


    if (dateString) {
      const dateParts = dateString.split('/');
      const month = parseInt(dateParts[0]);
      const day = parseInt(dateParts[1]);
      const year = parseInt(dateParts[2]);
  
      // Check if any of the date components are zero or month is not between 1 and 12
      
  
      const ListofDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
      
      
      if (month < 1 || month > 12 || day === 0 || year === 0) {
        return { 'invalidMonth': true };
      }
      if (month === 1 || month > 2) {
        if (day > ListofDays[month - 1]) {
          return { 'invalidDateRange': true };
        }
      } else if (month === 2) {
        const leapYear = (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
        if (!leapYear && day >= 29) {
          return { 'invalidDateRange': true };
        }
         else if (leapYear && day > 29) {
          return { 'invalidDateRange': true };
        }
      }
      if (year < 1900 || year > 2099) {
        return { 'invalidDateyear': true };
      }
      
     
    }
  
    return null;
  }

}
