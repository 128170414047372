import { Component,OnInit, Input, EventEmitter, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { OnboardingformsServiceService } from '../../../services/onboardingforms-service/onboardingforms-service.service';
import { ToasterNotiService } from '../../../services/notifications/toaster-noti.service';
// import { CommonService } from '../../../shared/services/common/common.service'
import { EsignComponent } from '../../esign/esign.component';
import { DomSanitizer } from '@angular/platform-browser';
import {
  NgbModal,
  NgbModalOptions,
  NgbDateAdapter,
  NgbDateParserFormatter
} from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'cancer-care-northwest-section1',
  templateUrl: './cancer-care-northwest-section1.component.html',
  styleUrls: ['./cancer-care-northwest-section1.component.scss']
})
export class CancerCareNorthwestComponentSection implements OnInit {
  
  @Input() CancerCareNorthwestForms1 : UntypedFormGroup
  @Input() isSubmit;
  @Output() isHandleNAChanges = new EventEmitter();
  @Output() isclearInputIfInvalid =new EventEmitter<{ mandatory: string, optional?: string }>();
  @Output() isclearInputIfInvalidMandi = new EventEmitter();
  @Input() showTwo: any = false;

  @Input() showThree: any = false;
  // @Input() showFour: any = false;
  @Input() showOne: any = false;


  @Input() esignOne: any;
  @Input() esignTwo: any;
  @Input() esignThree: any;
  @Input() esignFour: any;

  @Input() finalImgPath: any = '';
  @Input() finalImg: any;

  @Input() finalImgPathTwo: any = [];
  @Input() finalImgTwo: any = '';


  @Input() finalImgPathThree: any = [];
  @Input() finalImgThree: any = '';

  // @Input() finalImgPathFour: any = [];
  // @Input() finalImgFour: any = '';






  constructor(
    private fb: UntypedFormBuilder,
    private modalService: NgbModal,
    private _sanitizer: DomSanitizer,
    private onboardService: OnboardingformsServiceService, private notiService: ToasterNotiService,

  ) { }

  @Input() formData;
  @Input() fieldsData;
  @Input() formId;

  modalOption: NgbModalOptions = {
    size: 'md',
    keyboard: false,
    backdrop: 'static'
  };


  ngOnInit(): void {
    this.getStatesData();
    this.getSpecialtyData();
    this.getPreClaimData();
    this.getAggregatesData();


  }


  states: Array<object> = [];
  specialty: Array<object> = [];
  public preClaim: any = [];
  public aggregates: any = [];

  public esignOpenOne() {
    const modalRef = this.modalService.open(EsignComponent, this.modalOption);
    modalRef.result.then(result => { });
    modalRef.componentInstance.base64Img.subscribe(data => {
      modalRef.close();
      this.finalImgPath = data.finalFilePath;
      let temp = {
        cmd_0019: this.finalImgPath
      };
      this.CancerCareNorthwestForms1.patchValue(temp);
      this.finalImgPath = data.finalFilePath;
      this.finalImg = data.base64Img;
      this.esignOne = this._sanitizer.bypassSecurityTrustResourceUrl(
        'data:image/jpg;base64,' + this.finalImg
      );
      this.showOne = true;
    });
  }
  public esignOpenTwo() {
    const modalRef = this.modalService.open(EsignComponent, this.modalOption);
    modalRef.result.then(result => { });
    modalRef.componentInstance.base64Img.subscribe(data => {
      modalRef.close();
      this.finalImgPath = data.finalFilePath;
      let temp = {
        cmd_00019: this.finalImgPath
      };
      this.CancerCareNorthwestForms1.patchValue(temp);
      this.finalImgPathTwo = data.finalFilePath;
      this.finalImg = data.base64Img;
      this.esignTwo = this._sanitizer.bypassSecurityTrustResourceUrl(
        'data:image/jpg;base64,' + this.finalImg
      );
      this.showTwo = true;
    });
  }
  public esignOpenThree() {
    const modalRef = this.modalService.open(EsignComponent, this.modalOption);
    modalRef.result.then(result => { });
    modalRef.componentInstance.base64Img.subscribe(data => {
      modalRef.close();
      this.finalImgPath = data.finalFilePath;
      let temp = {
        cmd_000190: this.finalImgPath
      };
      this.CancerCareNorthwestForms1.patchValue(temp);
      this.finalImgPathThree = data.finalFilePath;
      this.finalImg = data.base64Img;
      this.esignThree = this._sanitizer.bypassSecurityTrustResourceUrl(
        'data:image/jpg;base64,' + this.finalImg
      );
      this.showThree = true;
    });
  }
  
  

  public getAggregatesData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getAggregatesData(params).subscribe(
      data => {
        if (data['success']) {
          this.aggregates = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }
  public getPreClaimData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getPreClaimData(params).subscribe(
      data => {
        if (data['success']) {
          this.preClaim = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }
  public getSpecialtyData() 
    {
      let params: {};
      //this.spinner.show();
      this.onboardService.getSpecialtyData(params).subscribe(
        data => {
          if (data['success']) {
            this.specialty = data.data.map(i => ({
              ...i,
              id: i.id.toString()
            }));
          } else {
            this.notiService.showError(data.error, '', 3000);
          }
          //this.spinner.hide();
        },
        error => {
          //this.spinner.hide();
        }
      );
    }
  public getStatesData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getStatesData(params).subscribe(
      data => {
        if (data['success']) {
          this.states = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
      },
      error => {
      }
    );
  }
  handleNAchange(event,ename?) {
    this.isHandleNAChanges.emit(event)

  }
  clearInputIfInvalid(mandatory: string, optional?: string): void{
    if (optional) {
      this.isclearInputIfInvalid.emit({ mandatory, optional });
    } else {
      this.isclearInputIfInvalid.emit({ mandatory });
    }
    

  }
  clearInputIfInvalidMandi(event) {
    this.isclearInputIfInvalidMandi.emit(event)

  }
  

  

  



  // public esignOpenFour() {
  //   const modalRef = this.modalService.open(EsignComponent, this.modalOption);
  //   modalRef.result.then(result => { });
  //   modalRef.componentInstance.base64Img.subscribe(data => {
  //     modalRef.close();
  //     this.finalImgPathFour = data.finalFilePath;
  //     let temp = {
  //       cmd_371: this.finalImgPathFour
  //     };
  //     this.CancerCareNorthwestForms11.patchValue(temp);
  //     this.finalImgPathFour = data.finalFilePath;
  //     this.finalImgFour = data.base64Img;
  //     this.esignFour = this._sanitizer.bypassSecurityTrustResourceUrl(
  //       'data:image/jpg;base64,' + this.finalImgFour
  //     );
  //     this.showFour = true;
  //   });
  // }





}
