<div id="ababuild">
  <div class="header">
    <div class="row"> 
      <div class="col-md-1">
        <img  class="logoImg" src="../../../../assets/images/ABA-LOGO.png"
          alt="Credential my Doc" />
      </div>
      <div class="col-md-11">
        <div class="text-center">
          <h2 >ABA Building Blocks Credentialing Application</h2>
        </div>
      </div>
    </div>  
  </div>
  <div class="section">
    <form [formGroup]="AbaBuildBlocksForms">
      <div class="centered texasFirstTable">
        <b class="hThreeStyle">Section I-Individual Information</b>
      </div> 
        <table border="1" width="100%" >   
          <tbody>
            <tr>
              <td colspan="18">
                <div class="row">
                  <div class="col-md-3">
                    <label class="formLabel">LAST NAME<span
                      class="mandatory">*</span></label>
                    <input
                      class="form-control"
                      id="9"
                      [class.is-invalid]="
                        (AbaBuildBlocksForms.controls['9'].touched &&
                          AbaBuildBlocksForms.controls['9'].invalid) ||
                        (AbaBuildBlocksForms.controls['9'].invalid && isSubmit)
                      "
                      name="9"
                      formControlName="9"
                      (change)="onChangeLastName($event)" 
                      type="text"
                      data-id="lname"
                    />
                    <div
                      class="formLabel col-md-2"
                      *ngIf="
                        AbaBuildBlocksForms.controls['9'].touched &&
                        AbaBuildBlocksForms.controls['9'].invalid
                      "
                    >
                      <small
                        class="text-danger"
                        *ngIf="AbaBuildBlocksForms.controls['9'].errors?.required"
                      >
                        required
                      </small>
                    </div>
                  </div>
                  <div class="col-md-3 ">
                    <label class="formLabel">FIRST NAME<span
                      class="mandatory">*</span></label>
                    <input
                      class="form-control"
                      id="8"
                      [class.is-invalid]="
                        (AbaBuildBlocksForms.controls['8'].touched &&
                          AbaBuildBlocksForms.controls['8'].invalid) ||
                        (AbaBuildBlocksForms.controls['8'].invalid && isSubmit)
                      "
                      name="8"
                      (change)="onChangeFirstName($event)"
                      formControlName="8"
                      type="text"
                      data-id="fname"
                    />
                    <div
                      class="formLabel col-md-2"
                      *ngIf="
                        AbaBuildBlocksForms.controls['8'].touched &&
                        AbaBuildBlocksForms.controls['8'].invalid
                      "
                    >
                      <small
                        class="text-danger"
                        *ngIf="AbaBuildBlocksForms.controls['8'].errors?.required"
                      >
                        required
                      </small>
                    </div>
                  </div>
                  <div class="col-md-3 ">
                    <label class="formLabel">MIDDLE NAME</label>
                    <input
                      class="form-control"
                      id="809"
                      name="809"
                      formControlName="809"
                      type="text"
                      data-id="mname"
                    />
                  </div>
                  <div class="col-md-3">
                    (JR., SR., ETC.)
                    <div class="form-group selectDrop dropDrownSet">
                      <ng-select
                        [items]="suffix"
                        bindLabel="name"
                        placeholder="Suffix"
                        bindValue="id"
                        formControlName="29"
                      >
                      </ng-select>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="9">
                <div class="row">
                  <div class="no-margin col-md-6">
                    <label class="formLabel">MAIDEN NAME </label>
                    <input
                      class="form-control"
                      id="278"
                      name="278"
                      formControlName="278"
                      type="text"
                      data-id="priorname"
                    />
                  </div>
                  <div class="col-md-4">
                    <label class="formLabel">YEARS ASSOCIATED (YYYY-YYYY) </label>
                    <div class="input-group">
                      <input
                        class="form-control onboard datepicker"
                        placeholder="MM/DD/YYYY"
                        ngbDatepicker
                        #d1="ngbDatepicker"
                        id="280"
                        name="280"
                        formControlName="280"
                        (blur)="clearInputIfInvalid('280')"
                        type="text"
                        data-id="priornameeffdate_date"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-outline-secondary calendar"
                          (click)="d1.toggle()"
                          type="button"
                        >
                          <i class="fas fa-calendar-alt"></i>
                        </button>
                      </div>
                    </div>
                    <div  *ngIf="AbaBuildBlocksForms.get('280').invalid ">
                      <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('280').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)
                      </small>
                      <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('280').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('280').errors?.invalidDateyear">
                        year is out of range(1900-2099)                      </small>
                        <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('280').errors?.invalidDateRange">
                          date is out of range
                          </small>
                      </div>
                    <div class="input-group">
                      <input
                        class="form-control onboard datepicker"
                        placeholder="MM/DD/YYYY"
                        ngbDatepicker
                        #d2="ngbDatepicker"
                        id="281"
                        name="281"
                        formControlName="281"
                        (blur)="clearInputIfInvalid('281')"
                        type="text"
                        data-id="priornametermdate_date"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-outline-secondary calendar"
                          (click)="d2.toggle()"
                          type="button"
                        >
                          <i class="fas fa-calendar-alt"></i>
                        </button>
                      </div>
                    </div>
                    <div  *ngIf="AbaBuildBlocksForms.get('281').invalid ">
                      <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('281').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)
                      </small>
                      <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('281').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('281').errors?.invalidDateyear">
                        year is out of range(1900-2099)                      </small>
                        <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('281').errors?.invalidDateRange">
                          date is out of range
                          </small>
                      </div>
                    
                  </div>
                </div>
              </td>
              <td colspan="9">
                <div class="row">
                  <div class="col-md-8">
                    <label class="formLabel">OTHER NAME </label>
                    <input
                      class="form-control"
                      id="2337"
                      name="2337"
                      formControlName="2337"
                      type="text"
                      data-id="othername"
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <label class="formLabel">YEARS ASSOCIATED(YYYY-YYYY)</label>
                    <div class="input-group">
                      <input
                      class="form-control onboard datepicker"
                        placeholder="MM/DD/YYYY"
                        ngbDatepicker
                        #d3="ngbDatepicker"
                        id="cmd_01"
                        name="cmd_01"
                        formControlName="cmd_01"
                        (blur)="clearInputIfInvalid('cmd_01')"
                        type="text"
                        data-id="othernametermdate_date"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-outline-secondary calendar"
                          (click)="d3.toggle()"
                          type="button"
                        >
                          <i class="fas fa-calendar-alt"></i>
                        </button>
                      </div>
                    </div>
                    <div  *ngIf="AbaBuildBlocksForms.get('cmd_01').invalid ">
                      <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('cmd_01').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)
                      </small>
                      <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('cmd_01').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('cmd_01').errors?.invalidDateyear">
                        year is out of range(1900-2099)                      </small>
                        <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('cmd_01').errors?.invalidDateRange">
                          date is out of range
                          </small>
                      </div>
                    <div class="input-group">
                      <input
                        class="form-control onboard datepicker"
                        placeholder="MM/DD/YYYY"
                        ngbDatepicker
                        #d4="ngbDatepicker"
                        id="cmd_02"
                        name="cmd_02"
                        formControlName="cmd_02"
                        (blur)="clearInputIfInvalid('cmd_02')"
                        type="text"
                        data-id="othernametermdate_date"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-outline-secondary calendar"
                          (click)="d4.toggle()"
                          type="button"
                        >
                          <i class="fas fa-calendar-alt"></i>
                        </button>
                      </div>
                    </div>
                    <div  *ngIf="AbaBuildBlocksForms.get('cmd_02').invalid ">
                      <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('cmd_02').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)
                      </small>
                      <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('cmd_02').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('cmd_02').errors?.invalidDateyear">
                        year is out of range(1900-2099)                      </small>
                        <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('cmd_02').errors?.invalidDateRange">
                          date is out of range
                          </small>
                      </div>
                  </div>  
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="formLabel">HOME MAILING ADDRESS<span
                    class="mandatory">*</span></label>
                  <input
                    class="form-control"
                    [class.is-invalid]="
                      (AbaBuildBlocksForms.controls['16'].touched &&
                        AbaBuildBlocksForms.controls['16'].errors) ||
                      (AbaBuildBlocksForms.controls['16'].errors && isSubmit)
                    "
                    id="16"
                    name="16"
                    formControlName="16"
                    type="text"
                    data-id="homeaddress1"
                  />
                  <div
                    class="formLabel col-md-2"
                    *ngIf="
                      AbaBuildBlocksForms.controls['16'].touched &&
                      AbaBuildBlocksForms.controls['16'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="AbaBuildBlocksForms.controls['16'].errors?.required"
                    >
                      required
                    </small>
                  </div>
                  <input
                    class="form-control"
                    id="17"
                    name="17"
                    formControlName="17"
                    type="text"
                    data-id="homeaddress2"
                  />
                  
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div class="row">
                  <div class="col-md-3">
                    <label class="formLabel">CITY<span
                      class="mandatory">*</span></label>
                    <input
                      class="form-control"
                      [class.is-invalid]="
                        (AbaBuildBlocksForms.controls['18'].touched &&
                          AbaBuildBlocksForms.controls['18'].invalid) ||
                        (AbaBuildBlocksForms.controls['18'].invalid && isSubmit)
                      "
                      id="18"
                      name="18"
                      formControlName="18"
                      type="text"
                      data-id="homecity"
                    />
                    <div
                      class="formLabel col-md-2"
                      *ngIf="
                        AbaBuildBlocksForms.controls['18'].touched &&
                        AbaBuildBlocksForms.controls['18'].invalid
                      "
                    >
                      <small
                        class="text-danger"
                        *ngIf="AbaBuildBlocksForms.controls['18'].errors?.required"
                      >
                        required
                      </small>
                    </div>
                  </div>
                  <div class="form-group col-md-3 sideLabelAlignment">
                    <label class="formLabel ">STATE/COUNTRY<span
                      class="mandatory">*</span></label>
                    <div class="form-group col-md-12 dropDrownSet">
                      <ng-select
                        [items]="states"
                        [class.is-invalid]="
                          (AbaBuildBlocksForms.controls['20'].touched &&
                            AbaBuildBlocksForms.controls['20'].invalid) ||
                          (AbaBuildBlocksForms.controls['20'].invalid && isSubmit)
                        "
                        bindLabel="name"
                        placeholder="Select States"
                        bindValue="id"
                        formControlName="20"
                      >
                      </ng-select>
                      <div
                        class="formLabel col-md-2"
                        *ngIf="
                          AbaBuildBlocksForms.controls['20'].touched &&
                          AbaBuildBlocksForms.controls['20'].invalid
                        "
                      >
                        <small
                          class="text-danger"
                          *ngIf="AbaBuildBlocksForms.controls['20'].errors?.required"
                        >
                          required
                        </small>
                      </div>
                    </div>
                  </div>
                  <div class="form-group col-md-3 sideLabelAlignment">
                    <label class="formLabel">POSTAL CODE<span
                      class="mandatory">*</span></label>
                    <input
                      class="form-control col-md-8"
                      minlength="5"
                      maxlength="10"
                      [class.is-invalid]="
                        (AbaBuildBlocksForms.controls['21'].touched &&
                          AbaBuildBlocksForms.controls['21'].invalid) ||
                        (AbaBuildBlocksForms.controls['21'].invalid && isSubmit)
                      "
                      id="21"
                      name="21"
                      formControlName="21"
                      type="text"
                      data-id="homezip"
                    />
                    <div
                      class="formLabel col-md-10"
                      *ngIf="
                        AbaBuildBlocksForms.controls['21'].touched &&
                        AbaBuildBlocksForms.controls['21'].invalid
                      "
                    >
                      <small
                        class="text-danger"
                        *ngIf="AbaBuildBlocksForms.controls['21'].errors?.required"
                      >
                        required
                      </small>
                      <small
                        class="text-danger"
                        *ngIf="
                          AbaBuildBlocksForms.controls['21'].errors?.minlength ||
                          AbaBuildBlocksForms.controls['21'].errors?.maxlength
                        "
                      >
                        Zip code must be atleast 5 digits and less than 10 digits
                      </small>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6">
                <div class="form-inline no-margin col-md-12 sideLabelAlignment">
                  <label class="formLabel">HOME PHONE NUMBER<span
                    class="mandatory">*</span> </label>
                  <input
                    class="form-control"
                    (keypress)="numberOnly($event)"
                    [class.is-invalid]="
                      (AbaBuildBlocksForms.controls['708'].touched &&
                        AbaBuildBlocksForms.controls['708'].invalid) ||
                      (AbaBuildBlocksForms.controls['708'].invalid && isSubmit)
                    "
                    id="708"
                    name="708"
                    formControlName="708"
                    type="text"
                    data-id="homephone"
                  />
                  <div
                    class="formLabel col-md-2"
                    *ngIf="
                      AbaBuildBlocksForms.controls['708'].touched &&
                      AbaBuildBlocksForms.controls['708'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="AbaBuildBlocksForms.controls['708'].errors?.required"
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
              <td colspan="6">
                <div
                  class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel">SOCIAL SECURITY NUMBER <span
                    class="mandatory">*</span></label>
                  <input
                    class="form-control"
                    [class.is-invalid]="
                      (AbaBuildBlocksForms.controls['32'].touched &&
                        AbaBuildBlocksForms.controls['32'].invalid) ||
                      (AbaBuildBlocksForms.controls['32'].invalid && isSubmit)
                    "
                    id="32"
                    name="32"
                    formControlName="32"
                    type="text"
                    data-id="ssn"
                    (ngModelChange)="getSsn($event)"
                  />
                  <div
                    class="formLabel col-md-2"
                    *ngIf="
                      AbaBuildBlocksForms.controls['32'].touched &&
                      AbaBuildBlocksForms.controls['32'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="AbaBuildBlocksForms.controls['32'].errors?.required"
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
              <td colspan="6">
                <div class="form-group no-margin col-md-12 sideLabelAlignment">
                  <label class="formLabel">GENDER</label>
                  <div class="form-group selectDrop dropDrownSet">
                    <ng-select
                      [items]="gender"
                      bindLabel="name"
                      placeholder="Select Gender"
                      bindValue="id"
                      formControlName="30"
                    >
                    </ng-select>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="formLabel">PRACTICE CORRESPONDENCE ADDRESS </label>
                  <div class="col-md-3">
                    <input
                      class="form-control"
                      id="cmd_837"
                      name="cmd_837"
                      formControlName="cmd_837"
                      type="text"
                      data-id="prac1_MailingAddress1"
                    />
                  </div>
                  <div class="col-md-3">
                    <input
                      class="form-control"
                      id="cmd_838"
                      name="cmd_838"
                      formControlName="cmd_838"
                      type="text"
                      data-id="prac1_MailingAddress2"
                    />
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div class="row">
                  <div class="col-md-3">
                    <label class="formLabel">CITY</label>
                    <input
                      class="form-control"
                      id="cmd_839"
                      name="cmd_839"
                      formControlName="cmd_839"
                      type="text"
                      data-id="prac1_MailingCity"
                    />
                  </div>
                  <div class="col-md-3 ">
                    <label class="formLabel ">STATE/COUNTRY</label>
                    <div class="form-group dropDrownSet">
                      <div class="col-md-12">
                        <ng-select
                          [items]="states"
                          bindLabel="name"
                          placeholder="Select States"
                          bindValue="id"
                          formControlName="cmd_841"
                        >
                        </ng-select>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <label class="formLabel">POSTAL CODE</label>
                    <input
                      class="form-control col-md-8"
                      id="cmd_842"
                      name="cmd_842"
                      formControlName="cmd_842"
                      type="text"
                      data-id="prac1_MailingZIP"
                    />
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6">
                <div class="form-inline no-margin col-md-12 sideLabelAlignment">
                  <label class="formLabel">PHONE NUMBER</label>
                  <input
                    class="form-control"
                    id="cmd_870"
                    name="cmd_870"
                    (keypress)="numberOnly($event)"
                    formControlName="cmd_870"
                    type="text"
                    data-id="prac1_mailingphone"
                  />
                </div>
              </td>
              <td colspan="6">
                <div
                  class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel">FAX NUMBER </label>
                  <input
                    class="form-control"
                    id="cmd_871"
                    name="cmd_871"
                    formControlName="cmd_871"
                    type="text"
                    data-id="prac1_mailingfax"
                  />
                </div>
              </td>
              <td colspan="6">
                <div
                  class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel">E-MAIL<span
                    class="mandatory">*</span> </label>
                  <input
                    class="form-control"
                    [class.is-invalid]="
                      (AbaBuildBlocksForms.controls['759'].touched &&
                        AbaBuildBlocksForms.controls['759'].invalid) ||
                      (AbaBuildBlocksForms.controls['759'].invalid && isSubmit)
                    "
                    id="759"
                    name="759"
                    formControlName="759"
                    type="text"
                    data-id="hemail"
                  />
                  <div
                    class="formLabel col-md-2"
                    *ngIf="
                      AbaBuildBlocksForms.controls['759'].touched &&
                      AbaBuildBlocksForms.controls['759'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="AbaBuildBlocksForms.controls['759'].errors?.required"
                    >
                      required
                    </small>
                    <small
                      class="text-danger"
                      *ngIf="AbaBuildBlocksForms.controls['759'].errors.pattern"
                      >Please enter a valid email.</small
                    >
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="7">
                <div
                  class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel">DATE OF BIRTH (MM/DD/YYYY)<span
                    class="mandatory">*</span> </label>
                  <div class="input-group">
                    <input
                      class="form-control onboard"
                      [class.is-invalid]="
                        (AbaBuildBlocksForms.controls['31'].touched &&
                          AbaBuildBlocksForms.controls['31'].invalid) ||
                        (AbaBuildBlocksForms.controls['31'].invalid && isSubmit)
                      "
                      placeholder="MM/DD/YYYY"
                      ngbDatepicker
                      #d5="ngbDatepicker"
                      id="31"
                      name="31"
                      formControlName="31"
                      (blur)="clearInputIfInvalidMandi('31')" 
                      type="text"
                      data-id="dob_date"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="d5.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div class="text-danger" *ngIf="(AbaBuildBlocksForms.controls['31'].invalid&&AbaBuildBlocksForms.controls['31'].touched) && (AbaBuildBlocksForms.controls['31'].value==''|| AbaBuildBlocksForms.controls['31'].value==null) " >
                    <small >
                    required
                    </small>
  
                </div>
                  <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('31').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)                </small>
                  <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('31').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('31').errors?.invalidDateyear">
                    year is out of range(1900-2099)                  </small>
                    <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('31').errors?.invalidDateRange">
                      date is out of range
                      </small>
                </div>
              </td>
              <!-- <td colspan="7">
                              <div class="col-md-8">
                                  <div class="row">
                                      <label for="31" class="col-sm-4 pt-2 formLabel">DATE OF BIRTH (MM/DD/YYYY)<span
                                              class="mandatory">*</span></label>
                                      <div class="form-group col-md-8 ">
                                          <div class="input-group">
                                              <input class="form-control" formControlName="31" [class.is-invalid]="(AbaBuildBlocksForms.controls['31'].touched && AbaBuildBlocksForms.controls['31'].invalid) || (AbaBuildBlocksForms.controls['31'].invalid && isSubmit)" name="dp" placeholder="MM/DD/YYYY" ngbDatepicker #d5="ngbDatepicker" placement="bottom-left">
                                              <div class="input-group-append">
                                                  <button class="btn btn-outline-secondary calendar" (click)="d5.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                              </div>
                                          </div>
                                          <div class="formLabel col-md-4"
                                              *ngIf="AbaBuildBlocksForms.controls['31'].touched && AbaBuildBlocksForms.controls['31'].invalid">
                                              <small class="text-danger" *ngIf="AbaBuildBlocksForms.controls['31'].errors?.required">The
                                                  DOB is required and cannot be empty
                                              </small>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </td> -->
              <td colspan="6">
                <div class="form-inline no-margin col-md-12 sideLabelAlignment">
                  <label class="formLabel">PLACE OF BIRTH<span
                    class="mandatory">*</span> </label>
                </div>
                <div class="form-group no-margin col-md-6 sideLabelAlignment">
                  <input
                    class="form-control"
                    id="810"
                    [class.is-invalid]="
                      (AbaBuildBlocksForms.controls['810'].touched &&
                        AbaBuildBlocksForms.controls['810'].invalid) ||
                      (AbaBuildBlocksForms.controls['810'].invalid && isSubmit)
                    "
                    name="810"
                    formControlName="810"
                    type="text"
                    data-id="bcity"
                  />
                  <div
                    class="formLabel col-md-2"
                    *ngIf="
                      AbaBuildBlocksForms.controls['810'].touched &&
                      AbaBuildBlocksForms.controls['810'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="AbaBuildBlocksForms.controls['810'].errors?.required"
                    >
                      required
                    </small>
                  </div>
                </div>
                <div class="form-group no-margin col-md-6 sideLabelAlignment">
                  <div class="col-md-12">
                    <ng-select
                      [items]="states"
                      [class.is-invalid]="
                        (AbaBuildBlocksForms.controls['289'].touched &&
                          AbaBuildBlocksForms.controls['289'].invalid) ||
                        (AbaBuildBlocksForms.controls['289'].invalid && isSubmit)
                      "
                      bindLabel="name"
                      placeholder="Select States"
                      bindValue="id"
                      formControlName="289"
                    >
                    </ng-select>
                    <div
                      class="formLabel col-md-2"
                      *ngIf="
                        AbaBuildBlocksForms.controls['289'].touched &&
                        AbaBuildBlocksForms.controls['289'].invalid
                      "
                    >
                      <small
                        class="text-danger"
                        *ngIf="AbaBuildBlocksForms.controls['289'].errors?.required"
                      >
                        required
                      </small>
                    </div>
                  </div>
                </div>
              </td>
              <td colspan="6">
                <div class="form-inline no-margin col-md-12 sideLabelAlignment">
                  <label class="formLabel">CITIZENSHIP </label>
                  <input
                    class="form-control"
                    id="292"
                    name="292"
                    formControlName="292"
                    type="text"
                    data-id="citizenship"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="8">
                <div class="form-inline no-margin col-md-12 sideLabelAlignment">
                  <label class="formLabel"
                    >IF NOT AMERICAN CITIZEN, VISA NUMBER & STATUS
                  </label>
                  <input
                    class="form-control"
                    id="1584"
                    name="1584"
                    formControlName="1584"
                    type="text"
                    data-id="Visa #"
                  />
                  <input
                    class="form-control"
                    id="1282"
                    name="1282"
                    formControlName="1282"
                    type="text"
                    data-id="Visa Status"
                  />
                </div>
              </td>
              <td colspan="8">
                <div class="form-inline no-margin col-md-12 sideLabelAlignment">
                  <label class="formLabel"
                    >ARE YOU ELIGIBLE TO WORK IN THE UNITED STATES?
                  </label>
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_04"
                      name="cmd_04"
                      formControlName="cmd_04"
                      value="1"
                    />Yes</label
                  >
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_04"
                      name="cmd_04"
                      formControlName="cmd_04"
                      value="0"
                    />No</label
                  >
                </div>
              </td>
              <!-- <td colspan="6">
                <div class="form-group no-margin col-md-6 sideLabelAlignment">
                  <label class="formLabel">CAQH ID</label>
                  <input
                    class="form-control"
                    id="333"
                    name="333"
                    formControlName="333"
                    type="text"
                    data-id="caqh"
                  />
                </div>
                <div class="form-group no-margin col-md-6 sideLabelAlignment">
                  <label class="formLabel">USERNAME</label>
                  <input
                    class="form-control"
                    id="334"
                    name="334"
                    formControlName="334"
                    type="text"
                    data-id="caqh_username"
                  />
                </div>
                <div class="form-group no-margin col-md-6 sideLabelAlignment">
                  <label class="formLabel">PASSWORD</label>
                  <input
                    class="form-control"
                    id="335"
                    name="335"
                    formControlName="335"
                    type="text"
                    data-id="caqh_pswd"
                  />
                </div>
              </td> -->
            </tr>
            <tr>
              <td colspan="6">
                <div class="form-inline no-margin col-md-12 sideLabelAlignment">
                  <label class="formLabel"
                    >U.S.MILITARY SERVICE/PUBLIC HEALTH<span
                    class="mandatory">*</span>
                  </label>
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_05"
                      name="cmd_05"
                      formControlName="cmd_05"
                      (ngModelChange)="handleYesNoChanges('cmd_05')" [class.is-invalid]="(AbaBuildBlocksForms.controls['cmd_05'].touched && AbaBuildBlocksForms.controls['cmd_05'].invalid) || (AbaBuildBlocksForms.controls['cmd_05'].invalid && isSubmit)"
                      value="1"
                    />Yes</label
                  >
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_05"
                      name="cmd_05"
                      formControlName="cmd_05"
                      (ngModelChange)="handleYesNoChanges('cmd_05')" [class.is-invalid]="(AbaBuildBlocksForms.controls['cmd_05'].touched && AbaBuildBlocksForms.controls['cmd_05'].invalid) || (AbaBuildBlocksForms.controls['cmd_05'].invalid && isSubmit)"
                      value="0"
                    />No</label
                  >
                  <div class="formLabel col-md-11"
                  *ngIf="AbaBuildBlocksForms.controls['cmd_05'].touched && AbaBuildBlocksForms.controls['cmd_05'].invalid">
                  <small class="text-danger" *ngIf="AbaBuildBlocksForms.controls['cmd_05'].errors?.required">
                      required
                  </small>
                </div>
                <span *ngIf="(AbaBuildBlocksForms.controls['cmd_05'].touched && AbaBuildBlocksForms.controls['cmd_05'].errors) || (AbaBuildBlocksForms.controls['cmd_05'].errors && isSubmit)">
                  <i class="fas fa-times ml-2 text-danger"></i>
                </span>
                </div>
              </td>
              <td colspan="6">
                <div
                  class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel"
                    >DATES OF SERVICE (MM/DD/YYYY) TO (MM/DD/YYYY)<span
                    class="mandatory">*</span>
                  </label>
                </div>
                <div class="form-group no-margin col-md-6 sideLabelAlignment">
                  <div class="input-group">
                    <input
                      class="form-control onboard datepicker"
                      (blur)="handleYesNoChanges('cmd_05')"
                      [class.is-invalid]="AbaBuildBlocksForms.controls['1144'].invalid"
                      placeholder="MM/DD/YYYY"
                      ngbDatepicker
                      #d6="ngbDatepicker"
                      id="1144"
                      name="1144"
                      formControlName="1144"
                      (blur)="clearInputIfInvalid('1144','cmd_05')"
                      type="text"
                      data-id="Mil Active from"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="d6.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div class="text-danger"
                  *ngIf="AbaBuildBlocksForms.controls['1144'].invalid && (AbaBuildBlocksForms.controls['1144'].value==''|| AbaBuildBlocksForms.controls['1144'].value==null) ">
                  <small>
                    required
                  </small>
                
                </div>
                
                <div *ngIf="AbaBuildBlocksForms.get('1144').invalid">
                  <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('1144').errors.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY) </small>
                  <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('1144').errors.invalidMonth">
                    Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('1144').errors.invalidDateyear">
                    year is out of range(1900-2099) </small>
                  <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('1144').errors.invalidDateRange">
                    date is out of range
                  </small>
                </div>
                </div>
                <div class="form-group no-margin col-md-6 sideLabelAlignment">
                  <div class="input-group">
                    <input
                      class="form-control onboard datepicker"
                      (blur)="handleYesNoChanges('cmd_05')"
                      [class.is-invalid]="(AbaBuildBlocksForms.controls['1145'].invalid) || (AbaBuildBlocksForms.controls['1145'].invalid && isSubmit)"
                      placeholder="MM/DD/YYYY"
                      ngbDatepicker
                      #d7="ngbDatepicker"
                      id="1145"
                      name="1145"
                      formControlName="1145"
                      (blur)="clearInputIfInvalid('1145','cmd_05')"
                      type="text"
                      data-id="Mil Active to"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="d7.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div class="text-danger"
                    *ngIf="AbaBuildBlocksForms.controls['1145'].invalid && (AbaBuildBlocksForms.controls['1145'].value==''|| AbaBuildBlocksForms.controls['1145'].value==null) ">
                    <small>
                      required
                    </small>
                  
                  </div>
                  
                  <div *ngIf="AbaBuildBlocksForms.get('1145').invalid">
                    <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('1145').errors.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY) </small>
                    <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('1145').errors.invalidMonth">
                      Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('1145').errors.invalidDateyear">
                      year is out of range(1900-2099) </small>
                    <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('1145').errors.invalidDateRange">
                      date is out of range
                    </small>
                  </div>
                </div> 
              </td> 
              <td colspan="6">
                <div class="form-inline no-margin col-md-12 sideLabelAlignment">
                  <label class="formLabel">LAST LOCATION <span
                    class="mandatory">*</span></label>
                  <input
                    class="form-control"
                    id="1139"
                    (blur)="handleYesNoChanges('cmd_05')"
                    [class.is-invalid]="AbaBuildBlocksForms.controls['1139'].invalid"
                    name="1139"
                    formControlName="1139"
                    type="text"
                    data-id="Military Locaiton"
                  />
                  <div class="mandatory" *ngIf="AbaBuildBlocksForms.controls['1139'].invalid">
                    <small >
                    required
                    </small>
                </div>
                </div>
              </td>   
            </tr>
            <tr>
              <td colspan="9">
                <div class="form-inline no-margin col-md-12 sideLabelAlignment">
                  <label class="formLabel">BRANCH OF SERVICE <span
                    class="mandatory">*</span></label>
                  <input
                    class="form-control"
                    (blur)="handleYesNoChanges('cmd_05')"
                    [class.is-invalid]="AbaBuildBlocksForms.controls['1141'].invalid"
                    id="1141"
                    name="1141"
                    formControlName="1141"
                    type="text"
                    data-id="Mil branch"
                  />
                  <div class="mandatory" *ngIf="AbaBuildBlocksForms.controls['1141'].invalid">
                    <small >
                    required
                    </small>
                  </div>
                </div>
              </td> 
              <td colspan="9">
                <div class="form-inline no-margin col-md-12 sideLabelAlignment">
                  <label class="formLabel"
                    >ARE YOU CURRENTLY ON ACTIVE OR RESERVE MILITARY DUTY?
                  </label>
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_06"
                      name="cmd_06"
                      formControlName="cmd_06"
                      value="1"
                    />Yes</label
                  >
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_06"
                      name="cmd_06"
                      formControlName="cmd_06"
                      value="0"
                    />No</label
                  >
                </div>
              </td>
            </tr>
          </tbody>          
        </table> 
        <table border="1" width="100%">
          <tbody>
            <tr>
              <td colspan="14">
                <div class="centered texasFirstTable">
                  <h3 class="texasHThree">Education</h3>
                </div>
              </td>
              
              <td colspan="4">
                <div class="form-check">
                  <b style="font-size:14px;padding-left:700px;">Does Not Apply</b>
                  <input
                    type="checkbox"
                    style="height:17px;width:15px;"
                    name="cmd_NA_02"
                    id="cmd_NA_02"
                    formControlName="cmd_NA_02"
                    value="1"
                    (ngModelChange)="handleNAchange('cmd_NA_02')"
                    
                  />
                </div>  
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div
                  class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel"
                    >PROFESSIONAL DEGREE (MEDICAL, DENTAL, CHIROPRACTIC, ETC.)
                  </label>
                </div>
                <div
                  class="form-group form-inline no-margin col-md-6 sideLabelAlignment"
                >
                  <label class="formLabel">Issuing Institution:<span
                    class="mandatory">*</span> </label>
                </div>
                <div class="form-group no-margin col-md-6 sideLabelAlignment">
                  <input
                    class="form-control" (blur)="handleNAchange('cmd_NA_02',490)"
                    [class.is-invalid]="AbaBuildBlocksForms.controls['490'].invalid" 
                    id="490"
                    name="490"
                    formControlName="490"
                    type="text"
                    data-id="meds"
                  />
                  <div class="mandatory"
                                  *ngIf="AbaBuildBlocksForms.controls['490'].invalid">
                                  <small >
                                    required
                                  </small>
                  </div> 
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="formLabel">ADDRESS<span
                    class="mandatory">*</span> </label>
                  <input
                    class="form-control"
                    (blur)="handleNAchange('cmd_NA_02',491)"
                    [class.is-invalid]="AbaBuildBlocksForms.controls['491'].invalid" 
                    id="491"
                    name="491"
                    formControlName="491"
                    type="text"
                    data-id="medsadd1"
                  />
                  <div class="mandatory"
                                  *ngIf="AbaBuildBlocksForms.controls['491'].invalid">
                                  <small >
                                    required
                                  </small>
                  </div>
                  <input
                    class="form-control"                                        
                    id="492"
                    name="492"
                    formControlName="492"
                    type="text"
                    data-id="medsadd2"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div
                  class="form-group form-inline no-margin col-md-3 sideLabelAlignment"
                >
                  <label class="formLabel">CITY<span
                    class="mandatory">*</span></label>
                  <input
                    class="form-control"
                    (blur)="handleNAchange('cmd_NA_02',493)"
                    [class.is-invalid]="AbaBuildBlocksForms.controls['493'].invalid"
                    name="493"
                    formControlName="493"
                    type="text"
                    data-id="medsci"
                    />
                    
                  <div class="mandatory"
                                  *ngIf="AbaBuildBlocksForms.controls['493'].invalid">
                                  <small >
                                    required
                                  </small>
                  </div> 
                </div>
                <div
                  class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel">STATE/COUNTRY<span
                    class="mandatory">*</span></label>
                  <div class="form-group selectDrop dropDrownSet">
                    <div class="col-md-12">
                      <ng-select
                        [items]="states"
                        (blur)="handleNAchange('cmd_NA_02',495)"
                        [class.is-invalid]="AbaBuildBlocksForms.controls['495'].invalid"
                        bindLabel="name"
                        placeholder="Select States"
                        bindValue="id"
                        formControlName="495"
                      >
                      </ng-select>
                    </div>
                    <div class="mandatory"
                                       *ngIf=" AbaBuildBlocksForms.controls['495'].invalid">
                                       <small>
                                       required
                                       </small>
                    </div>
                  </div>
                </div>
                <div
                  class="form-group form-inline no-margin col-md-3 sideLabelAlignment"
                >
                  <label class="formLabel">POSTAL CODE<span
                    class="mandatory">*</span></label>
                  <input
                    class="form-control"
                    minlength="5"
                    maxlength="10"
                    (blur)="handleNAchange('cmd_NA_02',496)"
                    [class.is-invalid]="AbaBuildBlocksForms.controls['496'].invalid"
                    id="496"
                    name="496"
                    formControlName="496"
                    type="text"
                    data-id="medsz"
                  />
                  
                  <div class="mandatory"
                                       *ngIf=" AbaBuildBlocksForms.controls['496'].invalid">
                                       <small>
                                        Zip code must be atleast 5 digits and less than 10 digits
                                      </small>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="8">
                <div
                  class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel">DEGREE<span
                    class="mandatory">*</span> </label>
                  <div class="col-md-10">
                    <ng-select
                      [items]="degree"
                      (blur)="handleNAchange('cmd_NA_02',500)"
                      [class.is-invalid]="AbaBuildBlocksForms.controls['500'].invalid"
                      bindLabel="name"
                      placeholder="Select Degree"
                      bindValue="id"
                      formControlName="500"
                    >
                    </ng-select>
                    <div class="mandatory"
                                       *ngIf=" AbaBuildBlocksForms.controls['500'].invalid">
                                       <small>
                                        required
                                      </small>
                  </div>
                  </div>
                </div>
              </td>
              <td colspan="10">
                <div class="col-md-8">
                  <div
                    class="form-group form-inline no-margin col-md-9 sideLabelAlignment"
                  >
                    <label class="formLabel"
                      >ATTENDANCE DATES(MM/DD/YYYY TO MM/DD/YYYY)<span
                      class="mandatory">*</span></label
                    >
                    <br />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="input-group">
                    <input
                      class="form-control onboard datepicker"
                      (blur)="handleNAchange('cmd_NA_02',497)" [class.is-invalid]="AbaBuildBlocksForms.controls['497'].invalid"
                      placeholder="MM/DD/YYYY"
                      ngbDatepicker
                      #d8="ngbDatepicker"
                      id="497"
                      name="497"
                      formControlName="497"
                      (blur)="clearInputIfInvalid('497','cmd_NA_02')"
                      type="text"
                      data-id="medsstart_date"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="d8.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div class="text-danger"
                  *ngIf="AbaBuildBlocksForms.controls['497'].invalid && (AbaBuildBlocksForms.controls['497'].value==''|| AbaBuildBlocksForms.controls['497'].value==null) ">
                  <small>
                    required
                  </small>
                
                </div>
                
                <div *ngIf="AbaBuildBlocksForms.get('497').invalid">
                  <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('497').errors.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY) </small>
                  <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('497').errors.invalidMonth">
                    Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('497').errors.invalidDateyear">
                    year is out of range(1900-2099) </small>
                  <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('497').errors.invalidDateRange">
                    date is out of range
                  </small>
                </div>
                </div>
                <div class="col-md-4">
                  <div class="input-group">
                    <input
                      class="form-control onboard datepicker"
                      (blur)="handleNAchange('cmd_NA_02',498)" [class.is-invalid]="AbaBuildBlocksForms.controls['498'].invalid"
                      placeholder="MM/DD/YYYY"
                      ngbDatepicker
                      #d9="ngbDatepicker"
                      id="498"
                      name="498"
                      formControlName="498"
                      (blur)="clearInputIfInvalid('498','cmd_NA_02')"
                      type="text"
                      data-id="medsend_date"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="d9.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div class="text-danger"
                  *ngIf="AbaBuildBlocksForms.controls['498'].invalid && (AbaBuildBlocksForms.controls['498'].value==''|| AbaBuildBlocksForms.controls['498'].value==null) ">
                  <small>
                    required
                  </small>
                
                </div>
                
                <div *ngIf="AbaBuildBlocksForms.get('498').invalid">
                  <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('498').errors.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY) </small>
                  <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('498').errors.invalidMonth">
                    Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('498').errors.invalidDateyear">
                    year is out of range(1900-2099) </small>
                  <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('498').errors.invalidDateRange">
                    date is out of range
                  </small>
                </div>
                </div>
              </td>
            </tr>
            
          </tbody>  
        </table>   
        <hr class="tablePersonal" />
        <table border="1" width="100%">
          <tr>
            <td colspan="18">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <b  style="font-size:15px;"
                  >Licenses and Certificates</b
                >
                - Please include all license(s) and certifications in all States
                where you are currently or have previously been licensed.
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="6">
              <div
                class="form-group form-inline no-margin col-md-10 sideLabelAlignment"
              >
                <label class="formLabel">LICENSE TYPE </label>
              </div> 
              <div
                class="col-md-8"
              > 
                <ng-select
                  [items]="credentials"
                  bindLabel="name"
                  placeholder="Select Degree"
                  bindValue="id"
                  formControlName="822"
                >
                </ng-select>
              </div>
            </td>
            <td colspan="6">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="formLabel">LICENSE NUMBER<span
                  class="mandatory">*</span> </label>
                <input
                  style="width:70%;"
                  (blur)="handleNAchange('cmd_NA_03',293)" [class.is-invalid]="AbaBuildBlocksForms.controls['293'].invalid"
                  class="form-control"
                  id="293"
                  name="293"
                  formControlName="293"
                  type="text"
                  data-id="license"
                />
                <div class="mandatory"
                                  *ngIf="AbaBuildBlocksForms.controls['293'].invalid">
                                  <small >
                                    required
                                  </small>
                </div>
              </div>
            </td>
            <td colspan="6">
              <div
                class="form-group form-inline no-margin col-md-12"
                style="padding: 2px;"
              >
                <label class="formLabel">STATE OF REGISTRATION<span
                  class="mandatory">*</span> </label>
                <div class="form-group selectDrop dropDrownSet">
                  <div class="col-md-12">
                    <ng-select
                      [items]="states"
                      (blur)="handleNAchange('cmd_NA_03',299)" [class.is-invalid]="AbaBuildBlocksForms.controls['299'].invalid"
                      bindLabel="name"
                      placeholder="Select States"
                      bindValue="id"
                      formControlName="299"
                    >
                    </ng-select>
                  </div>
                  <div class="mandatory"
                  *ngIf="AbaBuildBlocksForms.controls['299'].invalid">
                  <small >
                    required
                  </small>
              </div>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="6">
              <div class="form-group form-inline no-margin sideLabelAlignment">
                <label class="formLabel"
                  >ORIGINAL DATE OF ISSUE (MM/DD/YYYY)<span
                  class="mandatory">*</span>
                </label>
                <div class="input-group">
                  <input
                    style="width:50%;"
              [class.is-invalid]="AbaBuildBlocksForms.controls['294'].invalid"
                    class="form-control onboard datepicker"
                    placeholder="MM/DD/YYYY"
                    ngbDatepicker
                    #d16="ngbDatepicker"
                    id="294"
                    name="294"
                    formControlName="294"
                    (blur)="clearInputIfInvalid('294','cmd_NA_03')"
                    (blur)="handleNAchange('cmd_NA_03',294)"
                    type="text"
                    data-id="licenseissuedate"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="d16.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
                <div class="text-danger"
                  *ngIf="AbaBuildBlocksForms.controls['294'].invalid && (AbaBuildBlocksForms.controls['294'].value==''|| AbaBuildBlocksForms.controls['294'].value==null) ">
                  <small>
                    required
                  </small>
                
                </div>
                
                <div *ngIf="AbaBuildBlocksForms.get('294').invalid">
                  <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('294').errors.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY) </small>
                  <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('294').errors.invalidMonth">
                    Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('294').errors.invalidDateyear">
                    year is out of range(1900-2099) </small>
                  <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('294').errors.invalidDateRange">
                    date is out of range
                  </small>
                </div>
              </div>
            </td>
            <td colspan="6">
              <div class="form-group form-inline no-margin sideLabelAlignment">
                <label class="formLabel">EXPIRATION DATE (MM/DD/YYYY)<span
                  class="mandatory">*</span> </label>
                <div class="input-group">
                  <input
                    style="width:50%;"
                    (blur)="handleNAchange('cmd_NA_03',295)" [class.is-invalid]="AbaBuildBlocksForms.controls['295'].invalid"
                    class="form-control onboard datepicker"
                    placeholder="MM/DD/YYYY"
                    ngbDatepicker
                    #d17="ngbDatepicker"
                    id="295"
                    name="295"
                    formControlName="295"
                    (blur)="clearInputIfInvalid('295','cmd_NA_03')"
                    type="text"
                    data-id="licenseexp_date"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="d17.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
                <div class="text-danger"
                  *ngIf="AbaBuildBlocksForms.controls['295'].invalid && (AbaBuildBlocksForms.controls['295'].value==''|| AbaBuildBlocksForms.controls['295'].value==null) ">
                  <small>
                    required
                  </small>
                
                </div>
                
                <div *ngIf="AbaBuildBlocksForms.get('295').invalid">
                  <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('295').errors.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY) </small>
                  <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('295').errors.invalidMonth">
                    Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('295').errors.invalidDateyear">
                    year is out of range(1900-2099) </small>
                  <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('295').errors.invalidDateRange">
                    date is out of range
                  </small>
                </div>
              </div>
            </td>
            <td colspan="6">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                DO YOU CURRENTLY PRACTICE IN THIS STATE?
              </div>
              <div class="form-group no-margin col-md-10 sideLabelAlignment">
                <label class="radio-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_013"
                    name="cmd_013"
                    formControlName="cmd_013"
                    value="1"
                  />Yes</label
                >
                <label class="radio-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_013"
                    name="cmd_013"
                    formControlName="cmd_013"
                    value="0"
                  />No</label
                >
              </div>
              <div class="form-check">
                <input style="height:15px;width:15px;top: 0;" formControlName="cmd_NA_03" (ngModelChange)="handleNAchange('cmd_NA_03')" class="form-check-input" type="checkbox" value="1" id="cmd_NA_03">
                   <label class="form-check-label" for="cmd_NA_03">
                            <b style="font-size:15px;">I have not obtained Licensure yet / Does Not Apply</b>
                            <span
                            class="mandatory">*</span></label>
                       
              </div> 
             
            </td>
          </tr>
          <tr>
            <td colspan="6">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="formLabel">LICENSE TYPE </label>
                <input
                  style="width: 40%"
                  class="form-control"
                  id="1274"
                  name="1274"
                  formControlName="1274"
                  type="text"
                  data-id="Alt License type"
                />
              </div>
            </td>
            <td colspan="6">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="formLabel">LICENSE NUMBER </label>
                <input
                  style="width: 60%"
                  class="form-control"
                  id="300"
                  name="300"
                  formControlName="300"
                  type="text"
                  data-id="alt license"
                />
              </div>
            </td>
            <td colspan="6">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="formLabel">STATE OF REGISTRATION </label>
                <div class="form-group selectDrop dropDrownSet">
                  <div class="col-md-12">
                    <ng-select
                      [items]="altLicenseState"
                      bindLabel="name"
                      placeholder="Select States"
                      bindValue="id"
                      formControlName="907"
                    >
                    </ng-select>
                  </div>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="6">
              <div class="form-group form-inline no-margin sideLabelAlignment">
                <label class="formLabel"
                  >ORIGINAL DATE OF ISSUE (MM/DD/YYYY)
                </label>
                <div class="input-group">
                  <input
                    class="form-control onboard datepicker"
                    placeholder="MM/DD/YYYY"
                    ngbDatepicker
                    #d18="ngbDatepicker"
                    id="307"
                    name="307"
                    formControlName="307"
                    (blur)="clearInputIfInvalid('307')"
                    type="text"
                    data-id="alt licesne issue"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="d18.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
                <div  *ngIf="AbaBuildBlocksForms.get('307').invalid ">
                  <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('307').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('307').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('307').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('307').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </div>
            </td>
            <td colspan="6">
              <div class="form-group form-inline no-margin sideLabelAlignment">
                <label class="formLabel">EXPIRATION DATE (MM/DD/YYYY) </label>
                <div class="input-group">
                  <input
                    style="width: 48%"
                    class="form-control onboard datepicker"
                    placeholder="MM/DD/YYYY"
                    ngbDatepicker
                    #d19="ngbDatepicker"
                    id="308"
                    name="308"
                    formControlName="308"
                    (blur)="clearInputIfInvalid('308')"
                    type="text"
                    data-id="alt licesne exp"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="d19.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
                <div  *ngIf="AbaBuildBlocksForms.get('308').invalid ">
                  <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('308').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('308').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('308').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('308').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </div>
            </td>
            <td colspan="6">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                DO YOU CURRENTLY PRACTICE IN THIS STATE?
              </div>
              <div class="form-group no-margin col-md-12 sideLabelAlignment">
                <label class="radio-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_014"
                    name="cmd_014"
                    formControlName="cmd_014"
                    value="1"
                  />Yes</label
                >
                <label class="radio-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_014"
                    name="cmd_014"
                    formControlName="cmd_014"
                    value="0"
                  />No</label
                >
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="6">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="formLabel">LICENSE TYPE </label>
                <input
                  style="width: 40%"
                  class="form-control"
                  id="1432"
                  name="1432"
                  formControlName="1432"
                  type="text"
                  data-id="add_license_type"
                />
              </div>
            </td>
            <td colspan="6">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="formLabel">LICENSE NUMBER </label>
                <input
                  style="width: 60%"
                  class="form-control"
                  id="999"
                  name="999"
                  formControlName="999"
                  type="text"
                  data-id="add_license"
                />
              </div>
            </td>
            <td colspan="6">
              <div
                class="form-group form-inline no-margin col-md-12 marginStyle"
              >
                <label class="formLabel">STATE OF REGISTRATION </label>
                <div class="form-group selectDrop dropDrownSet">
                  <div class="col-md-12">
                    <ng-select
                      [items]="additionalLicenseState"
                      bindLabel="name"
                      placeholder="Select States"
                      bindValue="id"
                      formControlName="1010"
                    >
                    </ng-select>
                  </div>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="6">
              <div class="form-group form-inline no-margin sideLabelAlignment">
                <label class="formLabel"
                  >ORIGINAL DATE OF ISSUE (MM/DD/YYYY)
                </label>
                <div class="input-group">
                  <input
                    class="form-control onboard datepicker"
                    placeholder="MM/DD/YYYY"
                    ngbDatepicker
                    #d20="ngbDatepicker"
                    id="1003"
                    name="1003"
                    (blur)="clearInputIfInvalid('1003')"
                    formControlName="1003"
                    type="text"
                    data-id="add license issue"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="d20.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
                <div  *ngIf="AbaBuildBlocksForms.get('1003').invalid ">
                  <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('1003').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('1003').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('1003').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('1003').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </div>
            </td>
            <td colspan="6">
              <div class="form-group form-inline no-margin sideLabelAlignment">
                <label class="formLabel">EXPIRATION DATE (MM/DD/YYYY) </label>
                <div class="input-group">
                  <input
                    style="width: 48%"
                    class="form-control onboard datepicker"
                    placeholder="MM/DD/YYYY"
                    ngbDatepicker
                    #d21="ngbDatepicker"
                    id="1004"
                    name="1004"
                    formControlName="1004"
                    (blur)="clearInputIfInvalid('1004')"
                    type="text"
                    data-id="add license exp"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="d21.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
                <div  *ngIf="AbaBuildBlocksForms.get('1004').invalid ">
                  <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('1004').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('1004').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('1004').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('1004').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </div>
            </td>
            <td colspan="6">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                DO YOU CURRENTLY PRACTICE IN THIS STATE?
              </div>
              <div class="form-group no-margin col-md-12 sideLabelAlignment">
                <label class="radio-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_015"
                    name="cmd_015"
                    formControlName="cmd_015"
                    value="1"
                  />Yes</label
                >
                <label class="radio-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_015"
                    name="cmd_015"
                    formControlName="cmd_015"
                    value="0"
                  />No</label
                >
              </div>
            </td>
          </tr>          
          <tr>
            <td colspan="18">
              <div class="form-inline no-margin col-md-12 sideLabelAlignment">
                <label class="formLabel"
                  >NATIONAL PROVIDER IDENTIFIER (WHEN AVAILABLE)
                </label>
                <input
                  class="form-control"
                  id="1634"
                  name="1634"
                  formControlName="1634"
                  type="text"
                  data-id="npi"
                />
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="18">
              <div class="form-group form-inline no-margin sideLabelAlignment">
                <label class="formLabel"
                  >ARE YOU A PARTICIPATING MEDICAID PROVIDER?
                  <input
                    type="radio"
                    id="cmd_022"
                    name="cmd_022"
                    formControlName="cmd_022"
                    value="1"
                  />Yes</label
                >
                <label class="radio-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_022"
                    name="cmd_022"
                    formControlName="cmd_022"
                    value="0"
                  />No</label
                >
                <label class="formLabel">Medicaid Provider Number:</label>
                <input
                  class="form-control"
                  id="812"
                  name="812"
                  formControlName="812"
                  type="text"
                  data-id="medicaid"
                />
              </div>
            </td>
          </tr>
        </table> 
        <table border="1" width="100%">
          <tr>
            <td colspan="14">
              <div class="centered texasFirstTable">
                <h3 class="texasHThree">Professional/Specialty Information</h3>
              </div>
            </td>
            <td colspan="4">
              <div class="form-check">
                <b style="font-size:14px;">Does Not Apply</b>
                <input
                  type="checkbox"
                  style="height:17px;width:15px;"
                  name="cmd_NA_04"
                  id="cmd_NA_04"
                  formControlName="cmd_NA_04"
                  value="1"
                  (ngModelChange)="handleNAchange('cmd_NA_04')"
                  
                />
              </div>  
            </td>
          </tr>
          <tr>
            <td colspan="6">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="boldParagraph">PRIMARY SPECIALTY<span
                  class="mandatory">*</span> </label>
                <div class="col-md-10">
                  <ng-select
                    [items]="specialty"
                    (blur)="handleNAchange('cmd_NA_04')" [class.is-invalid]="AbaBuildBlocksForms.controls['556'].invalid"
                    bindLabel="name"
                    placeholder="Select Specialty"
                    bindValue="id"
                    formControlName="556"
                  >
                  </ng-select>
                  <div class="mandatory"
                    *ngIf=" AbaBuildBlocksForms.controls['556'].invalid">
                                       <small>
                                       required
                                       </small>
                  </div>
                </div>
              </div>
            </td>
            <td colspan="12">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="boldParagraph">BOARD CERTIFIED?<span
                  class="mandatory">*</span></label>
                <label class="radio-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_024"
                    name="cmd_024"
                    formControlName="cmd_024"
                    (blur)="handleNAchange('cmd_NA_04','cmd_024')"
                    (ngModelChange)="handleYesNoChanges('cmd_024')" [class.is-invalid]="(AbaBuildBlocksForms.controls['cmd_024'].touched && AbaBuildBlocksForms.controls['cmd_024'].invalid) || (AbaBuildBlocksForms.controls['cmd_024'].invalid && isSubmit)"
                    value="1"
                  />Yes</label
                >
                <label class="radio-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_024"
                    name="cmd_024"
                    (blur)="handleNAchange('cmd_NA_04','cmd_024')"                  
                    formControlName="cmd_024"
                    (ngModelChange)="handleYesNoChanges('cmd_024')" [class.is-invalid]="(AbaBuildBlocksForms.controls['cmd_024'].touched && AbaBuildBlocksForms.controls['cmd_024'].invalid) || (AbaBuildBlocksForms.controls['cmd_024'].invalid && isSubmit)"
                    value="0"
                  />No</label
                >
                <div class="formLabel col-md-11"
                  *ngIf="AbaBuildBlocksForms.controls['cmd_024'].touched && AbaBuildBlocksForms.controls['cmd_024'].invalid">
                    <small class="text-danger" *ngIf="AbaBuildBlocksForms.controls['cmd_024'].errors?.required">
                        required
                    </small>
                </div>
                  <span *ngIf="(AbaBuildBlocksForms.controls['cmd_024'].touched && AbaBuildBlocksForms.controls['cmd_024'].errors) || (AbaBuildBlocksForms.controls['cmd_024'].errors && isSubmit)">
                    <i class="fas fa-times ml-2 text-danger"></i>
                  </span>
                
                
                  <div class="col-md-5">
                    <label class="formLabel"> Name of Certifying Board: </label>
                  </div>
                  <div class="col-md-7">
                    <ng-select
                      [items]="boardCertification"
                      (blur)="handleYesNoChanges('cmd_024')" 
                      [class.is-invalid]="AbaBuildBlocksForms.controls['554'].invalid"
                      bindLabel="name"
                      placeholder="Select Certificate"
                      bindValue="id"
                      formControlName="554"
                    >
                    </ng-select>
                    <div class="mandatory" *ngIf="AbaBuildBlocksForms.controls['554'].invalid">
                      <small >
                      required
                      </small>
                    </div>
                  </div>
              
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="4">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="formLabel"
                  >INITIAL CERTIFICATION DATE (MM/DD/YYYY)<span
                  class="mandatory">*</span>
                </label>
                <div class="input-group">
                  <input
                    style="width:80%;"
                    [class.is-invalid]="
                      AbaBuildBlocksForms.controls['557'].invalid
                    "
                    class="form-control onboard datepicker"
                    placeholder="MM/DD/YYYY"
                    ngbDatepicker
                    #d29="ngbDatepicker"
                    id="557"
                    name="557"
                    formControlName="557"
                  
                    (blur)="clearInputIfInvalid('557')"
                    (blur)="handleNAchange('cmd_NA_04',294)" 
                    type="text"
                    data-id="primarybcert"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="d29.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
                <div class="text-danger"
                *ngIf="AbaBuildBlocksForms.controls['557'].invalid && (AbaBuildBlocksForms.controls['557'].value==''|| AbaBuildBlocksForms.controls['557'].value==null) ">
                <small>
                  required
                </small>
              
              </div>
              
              <div *ngIf="AbaBuildBlocksForms.get('557').invalid">
                <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('557').errors.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY) </small>
                <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('557').errors.invalidMonth">
                  Month is out of range
                </small>
                <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('557').errors.invalidDateyear">
                  year is out of range(1900-2099) </small>
                <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('557').errors.invalidDateRange">
                  date is out of range
                </small>
              </div>
              </div>
            </td>
            <td colspan="8">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="formLabel"
                  >RECERTIFICATION DATE(S), IF APPLICABLE (MM/DD/YYYY)
                </label>
                <div class="input-group">
                  <input
                    style="width:48%;"
                    class="form-control onboard datepicker"
                    placeholder="MM/DD/YYYY"
                    ngbDatepicker
                    #d30="ngbDatepicker"
                    id="559"
                    name="559"
                    (blur)="clearInputIfInvalid('559')"
                    formControlName="559"
                    type="text"
                    data-id="primarybrcert2"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="d30.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
                <div  *ngIf="AbaBuildBlocksForms.get('559').invalid ">
                  <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('559').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('559').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('559').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('559').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </div>
            </td>
            <td colspan="6">
              <div class="form-group form-inline no-margin sideLabelAlignment">
                <label class="formLabel"
                  >EXPIRATION DATE, IF APPLICABLE (MM/DD/YYYY)
                </label>
                <div class="input-group">
                  <input
                    style="width:51%;"
                    class="form-control onboard datepicker"
                    placeholder="MM/DD/YYYY"
                    ngbDatepicker
                    #d31="ngbDatepicker"
                    id="560"
                    name="560"
                    formControlName="560"
                    (blur)="clearInputIfInvalid('560')"
                    type="text"
                    data-id="primarybexp_date"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="d31.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
                <div  *ngIf="AbaBuildBlocksForms.get('560').invalid ">
                  <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('560').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('560').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('560').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('560').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="18">
              <div class="form-group form-inline no-margin sideLabelAlignment">
                <div class="col-md-8">
                  <label class="formLabel"
                    >IF NOT BOARD CERTIFIED, INDICATE ANY OF THE FOLLOWING THAT
                    APPLY.</label
                  >
                </div>
                <br />
                <div class="col-md-12">
                  <label
                    class="boldParagraph"
                    class="checkbox-inline radioStyle"
                  >
                    <input
                      type="checkbox"
                      id="cmd_025"
                      name="cmd_025"
                      formControlName="cmd_025"
                      value="1"/>
                    I have taken exam, results pending for Board
                    <input
                      style="width: 50%"
                      class="form-control"
                      id="cmd_029"
                      name="cmd_029"
                      formControlName="cmd_029"
                      type="text"
                      data-id="test1"
                  /></label>
                </div>
                <br />
                <div class="col-md-12">
                  <label
                    class="boldParagraph"
                    class="checkbox-inline radioStyle"
                  >
                    <input
                      type="checkbox"
                      id="cmd_026"
                      name="cmd_026"
                      formControlName="cmd_026"
                      value="1"/>
                    I have taken Part I and am eligible for Part II of the Exam.
                    <input
                      style="width: 50%"
                      class="form-control"
                      id="cmd_030"
                      name="cmd_030"
                      formControlName="cmd_030"
                      type="text"
                      data-id="test2"
                  /></label>
                </div>
                <br />
                <div class="col-md-12">
                  <label
                    class="boldParagraph"
                    class="checkbox-inline radioStyle"
                  >
                    <input
                      type="checkbox"
                      id="cmd_027"
                      name="cmd_027"
                      formControlName="cmd_027"
                      value="1"/>I am intending to sit for the Boards on (date)
                    <input
                      style="width: 50%"
                      class="form-control"
                      id="cmd_031"
                      name="cmd_031"
                      formControlName="cmd_031"
                      type="text"
                      data-id="test3"
                  /></label>
                </div>
                <br />
                <div class="row extraBoxPaddingLeft">
                  <div class="col-md-12">
                    <label
                      class="boldParagraph"
                      class="checkbox-inline radioStyle"
                    >
                      <input
                        type="checkbox"
                        id="cmd_028"
                        name="cmd_028"
                        formControlName="cmd_028"
                        value="1"
                      />
                      I am not planning to take Boards</label
                    >
                  </div>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="18">
              <div class="form-group form-inline no-margin sideLabelAlignment">
                <div class="row extraBoxPaddingLeft">
                  <div class="col-md-12">
                    <label class="formLabel"
                      >DO YOU WISH TO BE LISTED IN THE DIRECTORY UNDER THIS
                      SPECIALTY?
                    </label>
                  </div>
                </div>
                <br />
                <label class="formLabel"><b>HMO:</b></label>
                <label class="boldParagraph" class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_032"
                    name="cmd_032"
                    formControlName="cmd_032"
                    value="1"
                  />
                  Yes</label
                >
                <label class="boldParagraph" class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_032"
                    name="cmd_032"
                    formControlName="cmd_032"
                    value="0"
                  />
                  No
                </label>
                <br />
                <label class="formLabel"><b>PPO:</b></label>
                <label class="boldParagraph" class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_033"
                    name="cmd_033"
                    formControlName="cmd_033"
                    value="1"
                  />
                  Yes</label
                >
                <label class="boldParagraph" class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_033"
                    name="cmd_033"
                    formControlName="cmd_033"
                    value="0"
                  />
                  No
                </label>
                <br />
                <label class="formLabel"><b>POS:</b></label>
                <label class="boldParagraph" class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_034"
                    name="cmd_034"
                    formControlName="cmd_034"
                    value="1"
                  />Yes</label
                >
                <label class="boldParagraph" class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_034"
                    name="cmd_034"
                    formControlName="cmd_034"
                    value="0"
                  />
                  No
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="7">
              <div class="form-group form-inline no-margin sideLabelAlignment">
                <label class="formLabel">SECONDARY SPECIALTY </label>
                <div class="col-md-8">
                  <ng-select
                    [items]="specialtySecondary"
                    bindLabel="name"
                    placeholder="Select Secondary Specialty"
                    bindValue="id"
                    formControlName="563"
                  >
                  </ng-select>
                </div>
              </div>
            </td>
            <td colspan="14">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="boldParagraph">BOARD CERTIFIED?</label>
                <label class="radio-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_035"
                    name="cmd_035"
                    formControlName="cmd_035"
                    value="1"
                  />Yes</label
                >
                <label class="radio-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_035"
                    name="cmd_035"
                    formControlName="cmd_035"
                    value="0"
                  />No</label
                >
                <br />
                <div class="row extraBoxPaddingLeft">
                  <label class="formLabel">Name of Certifying Board:</label>
                  
                    <div class="col-md-12">
                      <ng-select
                        [items]="boardCertification"
                        bindLabel="name"
                        placeholder="Select Secondary Board Certification"
                        bindValue="id"
                        formControlName="561"
                      >
                      </ng-select>
                    </div>
                  
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="6">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="formLabel"
                  >INITIAL CERTIFICATION DATE (MM/DD/YYYY)
                </label>
                <div class="input-group">
                  <input
                    style="width:53%;"
                    class="form-control onboard datepicker"
                    placeholder="MM/DD/YYYY"
                    ngbDatepicker
                    #d32="ngbDatepicker"
                    id="564"
                    name="564"
                    formControlName="564"
                    (blur)="clearInputIfInvalid('564')"
                    type="text"
                    data-id="secbcert"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="d32.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
                <div  *ngIf="AbaBuildBlocksForms.get('564').invalid ">
                  <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('564').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('564').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('564').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('564').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </div>
            </td>
            <td colspan="6">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="formLabel"
                  >RECERTIFICATION DATE(S), IF APPLICABLE (MM/DD/YYYY)
                </label>
                <div class="input-group">
                  <input
                    style="width:47%;"
                    class="form-control onboard datepicker"
                    placeholder="MM/DD/YYYY"
                    ngbDatepicker
                    #d33="ngbDatepicker"
                    id="565"
                    name="565"
                    formControlName="565"
                    (blur)="clearInputIfInvalid('565')"
                    type="text"
                    data-id="secbrcert2"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="d33.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
                <div  *ngIf="AbaBuildBlocksForms.get('565').invalid ">
                  <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('565').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('565').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('565').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('565').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </div>
            </td>
            <td colspan="6">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="formLabel"
                  >EXPIRATION DATE, IF APPLICABLE (MM/DD/YYYY)
                </label>
                <div class="input-group">
                  <input
                    style="width:50%;"
                    class="form-control onboard datepicker"
                    placeholder="MM/DD/YYYY"
                    ngbDatepicker
                    #d34="ngbDatepicker"
                    id="567"
                    name="567"
                    formControlName="567"
                    (blur)="clearInputIfInvalid('567')"
                    type="text"
                    data-id="secbexp_date"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="d34.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
                <div  *ngIf="AbaBuildBlocksForms.get('567').invalid ">
                  <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('567').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('567').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('567').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('567').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </div>
            </td>
          </tr>
        </table>

        <hr class="tablePersonal" />      
        <table border="1" width="100%">
          <td colspan="18">
            <div class="centered texasFirstTable">
              <h3 class="texasHThree">
                <b>Professional/Specialty Information</b> -continued
              </h3>
            </div>
          </td>
          <tr>
            <td colspan="18">
              <div class="form-group form-inline no-margin sideLabelAlignment">
                <div class="col-md-8">
                  <label class="formLabel"
                    >IF NOT BOARD CERTIFIED, INDICATE ANY OF THE FOLLOWING THAT
                    APPLY.</label
                  >
                </div>
                <br />
                <div class="col-md-12">
                  <label
                    class="boldParagraph"
                    class="checkbox-inline radioStyle"
                  >
                    <input
                      type="checkbox"
                      id="cmd_036"
                      name="cmd_036"
                      formControlName="cmd_036"
                      value="1"/>
                    I have taken exam, results pending for Board
                    <input
                      style="width: 50%"
                      class="form-control"
                      id="cmd_040"
                      name="cmd_040"
                      formControlName="cmd_040"
                      type="text"
                      data-id="test4"
                  /></label>
                </div>
                <br />
                <div class="col-md-12">
                  <label
                    class="boldParagraph"
                    class="checkbox-inline radioStyle"
                  >
                    <input
                      type="checkbox"
                      id="cmd_037"
                      name="cmd_037"
                      formControlName="cmd_037"
                      value="1"/>
                    I have taken Part I and am eligible for Part II of the Exam.
                    <input
                      style="width: 50%"
                      class="form-control"
                      id="cmd_041"
                      name="cmd_041"
                      formControlName="cmd_041"
                      type="text"
                      data-id="test5"
                  /></label>
                </div>
                <br />
                <div class="col-md-12">
                  <label
                    class="boldParagraph"
                    class="checkbox-inline radioStyle"
                  >
                    <input
                      type="checkbox"
                      id="cmd_038"
                      name="cmd_038"
                      formControlName="cmd_038"
                      value="1"/>I am intending to sit for the Boards on (date)
                    <input
                      style="width: 50%"
                      class="form-control"
                      id="cmd_042"
                      name="cmd_042"
                      formControlName="cmd_042"
                      type="text"
                      data-id="test6"
                  /></label>
                </div>
                <br />
                <div class="col-md-12">
                  <label
                    class="boldParagraph"
                    class="checkbox-inline radioStyle"
                  >
                    <input
                      type="checkbox"
                      id="cmd_039"
                      name="cmd_039"
                      formControlName="cmd_039"
                      value="1"
                    />
                    I am not planning to take Boards</label
                  >
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="18">
              <div class="form-group form-inline no-margin sideLabelAlignment">
                <label class="formLabel"
                  >DO YOU WISH TO BE LISTED IN THE DIRECTORY UNDER THIS
                  SPECIALTY?
                </label>
                <br />
                <label class="formLabel"><b>HMO:</b></label>
                <label class="boldParagraph" class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_043"
                    name="cmd_043"
                    formControlName="cmd_043"
                    value="1"
                  />
                  Yes</label
                >
                <label class="boldParagraph" class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_043"
                    name="cmd_043"
                    formControlName="cmd_043"
                    value="0"
                  />
                  No
                </label>
                <br />
                <label class="formLabel"><b>PPO:</b></label>
                <label class="boldParagraph" class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_044"
                    name="cmd_044"
                    formControlName="cmd_044"
                    value="1"
                  />
                  Yes</label
                >
                <label class="boldParagraph" class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_044"
                    name="cmd_044"
                    formControlName="cmd_044"
                    value="0"
                  />
                  No
                </label>
                <br />
                <label class="formLabel"><b>POS:</b></label>
                <label class="boldParagraph" class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_045"
                    name="cmd_045"
                    formControlName="cmd_045"
                    value="1"
                  />Yes</label
                >
                <label class="boldParagraph" class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_045"
                    name="cmd_045"
                    formControlName="cmd_045"
                    value="0"
                  />
                  No
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="9">
              <div class="form-group form-inline no-margin sideLabelAlignment">
                <label class="formLabel">ADDITIONAL SPECIALTY </label>
                <div class="col-md-10">
                  <ng-select
                    [items]="specialtyTer"
                    bindLabel="name"
                    placeholder="Select Specialty"
                    bindValue="id"
                    formControlName="948"
                  >
                  </ng-select>
                </div>
              </div>
            </td>
            <td colspan="9">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="boldParagraph">BOARD CERTIFIED?</label>
                <label class="radio-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_046"
                    name="cmd_046"
                    formControlName="cmd_046"
                    value="1"
                  />Yes</label
                >
                <label class="radio-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_046"
                    name="cmd_046"
                    formControlName="cmd_046"
                    value="0"
                  />No</label
                >
                <br />
                <div class="row extraBoxPaddingLeft">
                  <label class="formLabel">Name of Certifying Board:</label>
                  
                    <div class="col-md-10">
                      <ng-select
                        [items]="boardCertification"
                        bindLabel="name"
                        placeholder="Select ADDTIONAL BOARD CERTIFICATON"
                        bindValue="id"
                        formControlName="946"
                      >
                      </ng-select>
                    </div>
                  
                  </div>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="6">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="formLabel"
                  >INITIAL CERTIFICATION DATE (MM/DD/YYYY)
                </label>
                <div class="input-group">
                  <input
                    style="width:69%;"
                    class="form-control onboard datepicker"
                    placeholder="MM/DD/YYYY"
                    ngbDatepicker
                    #d35="ngbDatepicker"
                    id="953"
                    name="953"
                    formControlName="953"
                    (blur)="clearInputIfInvalid('953')"
                    type="text"
                    data-id="thrid board original issue date"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="d35.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
                <div  *ngIf="AbaBuildBlocksForms.get('953').invalid ">
                  <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('953').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('953').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('953').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('953').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </div>
            </td>
            <td colspan="6">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="formLabel"
                  >RECERTIFICATION DATE(S), IF APPLICABLE (MM/DD/YYYY)
                </label>
                <div class="input-group">
                  <input
                    style="width:43%;"
                    class="form-control onboard datepicker"
                    placeholder="MM/DD/YYYY"
                    ngbDatepicker
                    #d36="ngbDatepicker"
                    id="950"
                    name="950"
                    formControlName="950"
                    (blur)="clearInputIfInvalid('950')"
                    type="text"
                    data-id="Tertiary Board recert 2"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="d36.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
                <div  *ngIf="AbaBuildBlocksForms.get('950').invalid ">
                  <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('950').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('950').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('950').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('950').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </div>
            </td>
            <td colspan="6">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="formLabel"
                  >EXPIRATION DATE, IF APPLICABLE (MM/DD/YYYY)
                </label>
                <div class="input-group">
                  <input
                    style="width:45%;"
                    class="form-control onboard datepicker"
                    placeholder="MM/DD/YYYY"
                    ngbDatepicker
                    #d37="ngbDatepicker"
                    id="954"
                    name="954"
                    formControlName="954"
                    (blur)="clearInputIfInvalid('954')"
                    type="text"
                    data-id="Tertiary Board exp"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="d37.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
                <div  *ngIf="AbaBuildBlocksForms.get('954').invalid ">
                  <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('954').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('954').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('954').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('954').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="18">
              <div class="form-group form-inline no-margin sideLabelAlignment">
                <div class="col-md-8">
                  <label class="formLabel"
                    >IF NOT BOARD CERTIFIED, INDICATE ANY OF THE FOLLOWING THAT
                    APPLY.</label
                  >
                  <br />
                </div>
                <div class="col-md-12">
                  <label
                    class="boldParagraph"
                    class="checkbox-inline radioStyle"
                  >
                    <input
                      type="checkbox"
                      id="cmd_047"
                      name="cmd_047"
                      formControlName="cmd_047"
                      value="1"/>
                    I have taken exam, results pending for Board
                    <input
                      style="width: 50%"
                      class="form-control"
                      id="cmd_051"
                      name="cmd_051"
                      formControlName="cmd_051"
                      type="text"
                      data-id="test7"
                  /></label>
                </div>
                <br />
                <div class="col-md-12">
                  <label
                    class="boldParagraph"
                    class="checkbox-inline radioStyle"
                  >
                    <input
                      type="checkbox"
                      id="cmd_048"
                      name="cmd_048"
                      formControlName="cmd_048"
                      value="1"/>
                    I have taken Part I and am eligible for Part II of the Exam.
                    <input
                      style="width: 50%"
                      class="form-control"
                      id="cmd_052"
                      name="cmd_052"
                      formControlName="cmd_052"
                      type="text"
                      data-id="test8"
                  /></label>
                </div>
                <br />
                <div class="col-md-12">
                  <label
                    class="boldParagraph"
                    class="checkbox-inline radioStyle"
                  >
                    <input
                      type="checkbox"
                      id="cmd_049"
                      name="cmd_049"
                      formControlName="cmd_049"
                      value="1"/>I am intending to sit for the Boards on (date)
                    <input
                      style="width: 50%"
                      class="form-control"
                      id="cmd_053"
                      name="cmd_053"
                      formControlName="cmd_053"
                      type="text"
                      data-id="test9"
                  /></label>
                </div>
                <br />
                <div class="col-md-12">
                  <label
                    class="boldParagraph"
                    class="checkbox-inline radioStyle"
                  >
                    <input
                      type="checkbox"
                      id="cmd_050"
                      name="cmd_050"
                      formControlName="cmd_050"
                      value="1"
                    />
                    I am not planning to take Boards</label
                  >
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="18">
              <div class="form-group form-inline no-margin sideLabelAlignment">
                <label class="formLabel"
                  >DO YOU WISH TO BE LISTED IN THE DIRECTORY UNDER THIS
                  SPECIALTY?
                </label>
                <br />
                <label class="formLabel"><b>HMO:</b></label>
                <label class="boldParagraph" class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_054"
                    name="cmd_054"
                    formControlName="cmd_054"
                    value="1"
                  />
                  Yes</label
                >
                <label class="boldParagraph" class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_054"
                    name="cmd_054"
                    formControlName="cmd_054"
                    value="0"
                  />
                  No
                </label>
                <br />
                <label class="formLabel"><b>PPO:</b></label>
                <label class="boldParagraph" class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_055"
                    name="cmd_055"
                    formControlName="cmd_055"
                    value="1"
                  />
                  Yes</label
                >
                <label class="boldParagraph" class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_055"
                    name="cmd_055"
                    formControlName="cmd_055"
                    value="0"
                  />
                  No
                </label>
                <br />
                <label class="formLabel"><b>POS:</b></label>
                <label class="boldParagraph" class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_056"
                    name="cmd_056"
                    formControlName="cmd_056"
                    value="1"
                  />Yes</label
                >
                <label class="boldParagraph" class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_056"
                    name="cmd_056"
                    formControlName="cmd_056"
                    value="0"
                  />
                  No
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="18">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="checkbox-inline radioStyle"
                  >PLEASE LIST OTHER AREAS OF PROFESSIONAL PRACTICE INTEREST OR
                  FOCUS (HIV/AIDS, ETC.)
                </label>
                <input
                  class="form-control"
                  id="cmd_057"
                  name="cmd_057"
                  formControlName="cmd_057"
                  type="text"
                  data-id="other interest"
                />
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="14">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="formLabel"><b>Work History:</b></label
                >- Please provide a chronological work history. You may submit a
                Curriculum Vitae as a supplement. Please explain all gaps in
                employment that lasted more than six months
              </div>
            </td>
            <td colspan="4">
              <div class="form-check">
                <b style="font-size:14px;">Does Not Apply</b>
                <input
                  type="checkbox"
                  style="height:15px;width:15px;top: 0;padding-left:95px;"
                  name="cmd_NA_05"
                  id="cmd_NA_05"
                  formControlName="cmd_NA_05"
                  value="1"
                  (ngModelChange)="handleNAchange('cmd_NA_05')"
                  
                />
              </div>  
            </td>
          </tr>
          <tr>
            <td colspan="8">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="formLabel"
                  >CURRENT PRACTICE/EMPLOYER NAME<span
                  class="mandatory">*</span>
                  <input
                    class="form-control"
                    id="445"
                    name="445"
                    formControlName="445"
                    (blur)="handleNAchange('cmd_NA_05',445)" [class.is-invalid]="AbaBuildBlocksForms.controls['445'].invalid"
                    type="text"
                    data-id="wkhist1"
                  />
                  <div class="mandatory"
                  *ngIf="AbaBuildBlocksForms.controls['445'].invalid">
                  <small >
                    required
                  </small>
              </div> 
                </label>
              </div>
            </td>
            <td colspan="10">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="formLabel"
                  >START DATE/END DATE (MM/DD/YYYY TO MM/DD/YYYY)<span
                  class="mandatory">*</span>
                </label>
                <div class="input-group">
                  <input
                    class="form-control onboard datepicker"
                    [class.is-invalid]="AbaBuildBlocksForms.controls['454'].invalid"
                    placeholder="MM/DD/YYYY"
                    ngbDatepicker
                    #d38="ngbDatepicker"
                    id="454"
                    name="454"
                    formControlName="454"
                    (blur)="clearInputIfInvalid('454')"
                    (blur)="handleNAchange('cmd_NA_05',454)" 
                    type="text"
                    data-id="wk1histstartdt"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="d38.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
                <div class="text-danger"
                *ngIf="AbaBuildBlocksForms.controls['454'].invalid && (AbaBuildBlocksForms.controls['454'].value==''|| AbaBuildBlocksForms.controls['454'].value==null) ">
                <small>
                  required
                </small>
              
              </div>
              
              <div *ngIf="AbaBuildBlocksForms.get('454').invalid">
                <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('454').errors.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY) </small>
                <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('454').errors.invalidMonth">
                  Month is out of range
                </small>
                <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('454').errors.invalidDateyear">
                  year is out of range(1900-2099) </small>
                <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('454').errors.invalidDateRange">
                  date is out of range
                </small>
              </div>
                <div class="input-group">
                  <input
                    class="form-control onboard datepicker"
                    placeholder="MM/DD/YYYY"
                    ngbDatepicker
                    #d39="ngbDatepicker"
                    id="455"
                    name="455"
                    formControlName="455"
                    (blur)="clearInputIfInvalid('455')"
                    type="text"
                    data-id="wk1histenddt"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="d39.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
                <div  *ngIf="AbaBuildBlocksForms.get('455').invalid ">
                  <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('455').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('455').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('455').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('455').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
                
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="18">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="formLabel">ADDRESS<span
                  class="mandatory">*</span></label>
                <input
                  style="width: 40%;margin-bottom: 2px;"
                  (blur)="handleNAchange('cmd_NA_05',446)" [class.is-invalid]="AbaBuildBlocksForms.controls['446'].invalid"
                  class="form-control"
                  placeholder="Address 1"
                  id="446"
                  name="446"
                  formControlName="446"
                  type="text"
                  data-id="wkhist1add"
                />
                <div class="mandatory"
                                  *ngIf="AbaBuildBlocksForms.controls['446'].invalid">
                                  <small >
                                    required
                                  </small>
                </div> 
                <input
                  style="width: 40%;margin-bottom: 2px;"
                  (blur)="handleNAchange('cmd_NA_05',447)" [class.is-invalid]="AbaBuildBlocksForms.controls['447'].invalid"
                  class="form-control"
                  placeholder="Address 2"
                  id="447"
                  name="447"
                  formControlName="447"
                  type="text"
                  data-id="wkhist1add2"
                />
                <div class="mandatory"
                                  *ngIf="AbaBuildBlocksForms.controls['447'].invalid">
                                  <small >
                                    required
                                  </small>
                </div> 
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="18">
              <div
                class="form-group form-inline no-margin col-md-3 sideLabelAlignment"
              >
                <label class="formLabel">CITY<span
                  class="mandatory">*</span></label>
                <input
                  style="width: 100%"
                  placeholder="City"
                  (blur)="handleNAchange('cmd_NA_05',448)" [class.is-invalid]="AbaBuildBlocksForms.controls['448'].invalid"
                  class="form-control"
                  id="448"
                  name="448"
                  formControlName="448"
                  type="text"
                  data-id="Work hist 1 city"
                />
                <div class="mandatory"
                                  *ngIf="AbaBuildBlocksForms.controls['448'].invalid">
                                  <small >
                                    required
                                  </small>
                </div> 
              </div>
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="formLabel">STATE/COUNTRY<span
                  class="mandatory">*</span></label>
                <div class="form-group selectDrop dropDrownSet">
                  <div class="col-md-12">
                    <ng-select
                      [items]="states"
                      (blur)="handleNAchange('cmd_NA_05')" [class.is-invalid]="AbaBuildBlocksForms.controls['450'].invalid"
                      bindLabel="name"
                      placeholder="Select States"
                      bindValue="id"
                      formControlName="450"
                    >
                    </ng-select>
                  </div>
                  <div class="mandatory"
                                       *ngIf=" AbaBuildBlocksForms.controls['450'].invalid">
                                       <small>
                                       required
                                       </small>
                  </div>
                </div>
              </div>
              <div
                class="form-group form-inline no-margin col-md-3 sideLabelAlignment"
              >
                <label class="formLabel">POSTAL CODE<span
                  class="mandatory">*</span></label>
                <input
                  style="width: 100%"
                  minlength="5"
                  maxlength="10"
                  (blur)="handleNAchange('cmd_NA_05',451)" [class.is-invalid]="AbaBuildBlocksForms.controls['451'].invalid"
                  class="form-control"
                  placeholder="Zip"
                  id="451"
                  name="451"
                  formControlName="451"
                  type="text"
                  data-id="Work hist 1 zip"
                />
                <!-- <div
                  class="formLabel col-md-2"
                  *ngIf="
                    AbaBuildBlocksForms.controls['451'].touched &&
                    AbaBuildBlocksForms.controls['451'].errors
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="AbaBuildBlocksForms.controls['451'].errors"
                  >
                    required
                  </small>
                  <small
                    class="text-danger"
                    *ngIf="
                      AbaBuildBlocksForms.controls['451'].errors?.minlength ||
                      AbaBuildBlocksForms.controls['451'].errors?.maxlength
                    "
                  >
                    Zip code must be atleast 5 digits and less than 10 digits
                  </small>
                </div> -->
              
                <div class="mandatory"
                                    *ngIf="AbaBuildBlocksForms.controls['451'].invalid || AbaBuildBlocksForms.controls['451'].errors?.minlength ||
                                    AbaBuildBlocksForms.controls['451'].errors?.maxlength">
                                    <small >
                                      required
                                    </small>
                </div> 
              </div>  
            </td>
          </tr>
          <tr>
            <td colspan="8">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="formLabel"
                  >PREVIOUS PRACTICE/EMPLOYER NAME
                  <input
                    class="form-control"
                    id="456"
                    name="456"
                    formControlName="456"
                    type="text"
                    data-id="wkhist2"
                  />
                </label>
              </div>
            </td>
            <td colspan="10">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="formLabel"
                  >START DATE/END DATE (MM/DD/YYYY TO MM/DD/YYYY)
                </label>
                <div class="input-group">
                  <input
                    style="width:25%;"
                    class="form-control onboard datepicker"
                    placeholder="MM/DD/YYYY"
                    ngbDatepicker
                    #d40="ngbDatepicker"
                    id="465"
                    name="465"
                    formControlName="465"
                    (blur)="clearInputIfInvalid('465')"
                    type="text"
                    data-id="wkhist2startdt"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="d40.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
                <div  *ngIf="AbaBuildBlocksForms.get('465').invalid ">
                  <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('465').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('465').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('465').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('465').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
                <div class="input-group">
                  <input
                    class="form-control onboard datepicker"
                    placeholder="MM/DD/YYYY"
                    ngbDatepicker
                    #d41="ngbDatepicker"
                    id="466"
                    name="466"
                    formControlName="466"
                    (blur)="clearInputIfInvalid('466')"
                    type="text"
                    data-id="wkhist2enddt"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="d41.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
                <div  *ngIf="AbaBuildBlocksForms.get('466').invalid ">
                  <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('466').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('466').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('466').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('466').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="18">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="formLabel">ADDRESS</label>
                <input
                  style="width: 40%;margin-bottom: 2px;"
                  class="form-control"
                  placeholder="Address 1"
                  id="457"
                  name="457"
                  formControlName="457"
                  type="text"
                  data-id="wkhist2add"
                />
                <input
                  style="width: 40%;margin-bottom: 2px;"
                  class="form-control"
                  placeholder="Address 2"
                  id="458"
                  name="458"
                  formControlName="458"
                  type="text"
                  data-id="wkhist2address2"
                />
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="18">
              <div
                class="form-group form-inline no-margin col-md-3 sideLabelAlignment"
              >
                <label class="formLabel">CITY</label>
                <input
                  style="width: 100%"
                  placeholder="City"
                  class="form-control"
                  id="459"
                  name="459"
                  formControlName="459"
                  type="text"
                  data-id="Work hist 2 city"
                />
              </div>
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="formLabel">STATE/COUNTRY<span
                  class="mandatory">*</span></label>
                <div class="form-group selectDrop dropDrownSet">
                  <div class="col-md-12">
                    <ng-select
                      [items]="states"
                      (blur)="handleNAchange('cmd_NA_05')" [class.is-invalid]="AbaBuildBlocksForms.controls['461'].invalid"
                      bindLabel="name"
                      placeholder="Select States"
                      bindValue="id"
                      formControlName="461"
                    >
                    </ng-select>
                  </div>
                  <div class="mandatory"
                  *ngIf="AbaBuildBlocksForms.controls['461'].invalid">
                  <small >
                    required
                  </small>
              </div> 
                </div>
              </div>
              <div
                class="form-group form-inline no-margin col-md-3 sideLabelAlignment"
              >
                <label class="formLabel">POSTAL CODE</label>
                <input
                  style="width: 100%"
                  class="form-control"
                  placeholder="Zip"
                  id="462"
                  name="462"
                  formControlName="462"
                  type="text"
                  data-id="Work hist 2 zip"
                />
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="18">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="formLabel">REASON FOR DISCONTINUANCE </label>
                <input
                  class="form-control"
                  id="1338"
                  name="1338"
                  formControlName="1338"
                  type="text"
                  data-id="wkhist2reasonforleaving"
                />
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="8">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="formLabel"
                  >PREVIOUS PRACTICE/EMPLOYER NAME
                  <input
                    class="form-control"
                    id="467"
                    name="467"
                    formControlName="467"
                    type="text"
                    data-id="wkhist3"
                  />
                </label>
              </div>
            </td>
            <td colspan="10">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="formLabel"
                  >START DATE/END DATE (MM/DD/YYYY TO MM/DD/YYYY)
                </label>
                <div class="input-group">
                  <input
                    style="width:25%;"
                    class="form-control onboard datepicker"
                    placeholder="MM/DD/YYYY"
                    ngbDatepicker
                    #d42="ngbDatepicker"
                    id="476"
                    name="476"
                    formControlName="476"
                    (blur)="clearInputIfInvalid('476')"
                    type="text"
                    data-id="wkhist3startdt"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="d42.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
                <div  *ngIf="AbaBuildBlocksForms.get('476').invalid ">
                  <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('476').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('476').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('476').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('476').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
                <div class="input-group">
                  <input
                    class="form-control onboard datepicker"
                    placeholder="MM/DD/YYYY"
                    ngbDatepicker
                    #d43="ngbDatepicker"
                    id="477"
                    name="477"
                    formControlName="477"
                    (blur)="clearInputIfInvalid('477')"
                    type="text"
                    data-id="wkhist3enddt"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="d43.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
                <div  *ngIf="AbaBuildBlocksForms.get('477').invalid ">
                  <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('477').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('477').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('477').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('477').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="18">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="formLabel">ADDRESS</label>
                <input
                  style="width: 40%;margin-bottom: 2px;"
                  class="form-control"
                  placeholder="Address 1"
                  id="468"
                  name="468"
                  formControlName="468"
                  type="text"
                  data-id="wkhist3add"
                />
                <input
                  style="width: 40%;margin-bottom: 2px;"
                  class="form-control"
                  placeholder="Address 2"
                  id="469"
                  name="469"
                  formControlName="469"
                  type="text"
                  data-id="wkhist3address2"
                />
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="18">
              <div
                class="form-group form-inline no-margin col-md-3 sideLabelAlignment"
              >
                <label class="formLabel">CITY</label>
                <input
                  style="width: 100%"
                  placeholder="City"
                  class="form-control"
                  id="470"
                  name="470"
                  formControlName="470"
                  type="text"
                  data-id="Work hist 3 city"
                />
              </div>
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="formLabel">STATE/COUNTRY<span
                  class="mandatory">*</span></label>
                <div class="form-group selectDrop dropDrownSet">
                  <div class="col-md-12">
                    <ng-select
                      [items]="states"
                      (blur)="handleNAchange('cmd_NA_05')" [class.is-invalid]="AbaBuildBlocksForms.controls['472'].invalid"
                      bindLabel="name"
                      placeholder="Select States"
                      bindValue="id"
                      formControlName="472"
                    >
                    </ng-select>
                  </div>
                  <div class="mandatory"
                                       *ngIf=" AbaBuildBlocksForms.controls['472'].invalid">
                                       <small>
                                       required
                                       </small>
                  </div>
                </div>
              </div>
              <div
                class="form-group form-inline no-margin col-md-3 sideLabelAlignment"
              >
                <label class="formLabel">POSTAL CODE</label>
                <input
                  style="width: 100%"
                  class="form-control"
                  placeholder="Zip"
                  id="473"
                  name="473"
                  formControlName="473"
                  type="text"
                  data-id="Work hist 3 zip"
                />
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="18">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="formLabel">REASON FOR DISCONTINUANCE </label>
                <input
                  class="form-control"
                  id="1342"
                  name="1342"
                  formControlName="1342"
                  type="text"
                  data-id="wkhist3reasonforleaving"
                />
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="8">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="formLabel"
                  >PREVIOUS PRACTICE/EMPLOYER NAME
                  <input
                    class="form-control"
                    id="1935"
                    name="1935"
                    formControlName="1935"
                    type="text"
                    data-id="wkhist4"
                  />
                </label>
              </div>
            </td>
            <td colspan="10">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="formLabel"
                  >START DATE/END DATE (MM/DD/YYYY TO MM/DD/YYYY)
                </label>
                <div class="input-group">
                  <input
                    style="width:25%;"
                    class="form-control onboard datepicker"
                    placeholder="MM/DD/YYYY"
                    ngbDatepicker
                    #d44="ngbDatepicker"
                    id="1947"
                    name="1947"
                    formControlName="1947"
                    (blur)="clearInputIfInvalid('1947')"
                    type="text"
                    data-id="wkhist4startdt"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="d44.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
                <div  *ngIf="AbaBuildBlocksForms.get('1947').invalid ">
                  <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('1947').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('1947').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('1947').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('1947').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
                <div class="input-group">
                  <input
                    class="form-control onboard datepicker"
                    placeholder="MM/DD/YYYY"
                    ngbDatepicker
                    #d45="ngbDatepicker"
                    id="1948"
                    name="1948"
                    formControlName="1948"
                    (blur)="clearInputIfInvalid('1948')"
                    type="text"
                    data-id="wkhist4enddt"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="d45.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
                <div  *ngIf="AbaBuildBlocksForms.get('1948').invalid ">
                  <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('1948').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('1948').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('1948').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('1948').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="18">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="formLabel">ADDRESS</label>
                <input
                  style="width: 40%;margin-bottom: 2px;"
                  class="form-control"
                  placeholder="Address 1"
                  id="1936"
                  name="1936"
                  formControlName="1936"
                  type="text"
                  data-id="wkhist4add"
                />
                <input
                  style="width: 40%;margin-bottom: 2px;"
                  class="form-control"
                  placeholder="Address 2"
                  id="1937"
                  name="1937"
                  formControlName="1937"
                  type="text"
                  data-id="wkhist4address2"
                />
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="18">
              <div
                class="form-group form-inline no-margin col-md-3 sideLabelAlignment"
              >
                <label class="formLabel">CITY</label>
                <input
                  style="width: 100%"
                  placeholder="City"
                  class="form-control"
                  id="1938"
                  name="1938"
                  formControlName="1938"
                  type="text"
                  data-id="Work hist 4 city"
                />
              </div>
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="formLabel">STATE/COUNTRY</label>
                <div class="form-group selectDrop dropDrownSet">
                  <div class="col-md-12">
                    <ng-select
                      [items]="states"
                      bindLabel="name"
                      placeholder="Select States"
                      bindValue="id"
                      formControlName="1940"
                    >
                    </ng-select>
                  </div>
                </div>
              </div>
              <div
                class="form-group form-inline no-margin col-md-3 sideLabelAlignment"
              >
                <label class="formLabel">POSTAL CODE</label>
                <input
                  style="width: 100%"
                  class="form-control"
                  placeholder="Zip"
                  id="1941"
                  name="1941"
                  formControlName="1941"
                  type="text"
                  data-id="Work hist 4 zip"
                />
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="18">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="formLabel">REASON FOR DISCONTINUANCE </label>
                <input
                  class="form-control"
                  id="1952"
                  name="1952"
                  formControlName="1952"
                  type="text"
                  data-id="wkhist4reasonforleaving"
                />
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="18" width="60%">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="formLabel">
                  PLEASE PROVIDE AN EXPLANATION FOR ANY GAPS GREATER THAN SIX
                  MONTHS (MM/DD/YYYY TO MM/DD/YYYY) IN WORK HISTORY.
                </label>
              </div>
              <div
                class="form-group form-inline no-margin col-md-6 sideLabelAlignment"
              >
                <label class="formLabel">Gap Dates: </label>
                <div class="input-group">
                  <input
                    style="width: 40%"
                    class="form-control onboard datepicker"
                    placeholder="MM/DD/YYYY"
                    ngbDatepicker
                    #d46="ngbDatepicker"
                    id="3395"
                    name="3395"
                    formControlName="3395"
                    (blur)="clearInputIfInvalid('3395')"
                    type="text"
                    data-id="Gap from 1"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="d46.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
                <div  *ngIf="AbaBuildBlocksForms.get('3395').invalid ">
                  <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('3395').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('3395').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('3395').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('3395').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
                <div class="input-group">
                  <input
                    style="width: 53%"
                    class="form-control onboard datepicker"
                    placeholder="MM/DD/YYYY"
                    ngbDatepicker
                    #d47="ngbDatepicker"
                    id="3396"
                    name="3396"
                    formControlName="3396"
                    (blur)="clearInputIfInvalid('3396')"
                    type="text"
                    data-id="Gap to 1"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="d47.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
                <div  *ngIf="AbaBuildBlocksForms.get('3396').invalid ">
                  <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('3396').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('3396').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('3396').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('3396').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </div>
              <div
                class="form-group form-inline no-margin col-md-6 sideLabelAlignment"
              >
                <label class="formLabel">Explanation: </label>
                <input
                  style="width: 50%"
                  class="form-control"
                  id="1138"
                  name="1138"
                  formControlName="1138"
                  type="text"
                  data-id="Gap 1"
                />
              </div>
              <div
                class="form-group form-inline no-margin col-md-6 sideLabelAlignment"
              >
                <label class="formLabel">Gap Dates:</label>
                <div class="input-group">
                  <input
                    style="width: 40%"
                    class="form-control onboard datepicker"
                    placeholder="MM/DD/YYYY"
                    ngbDatepicker
                    #d48="ngbDatepicker"
                    id="3397"
                    name="3397"
                    formControlName="3397"
                    (blur)="clearInputIfInvalid('3397')"
                    type="text"
                    data-id="Gap from 2"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="d48.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
                <div  *ngIf="AbaBuildBlocksForms.get('3397').invalid ">
                  <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('3397').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('3397').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('3397').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('3397').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
                <div class="input-group">
                  <input
                    style="width: 53%"
                    class="form-control onboard datepicker"
                    placeholder="MM/DD/YYYY"
                    ngbDatepicker
                    #d49="ngbDatepicker"
                    id="3398"
                    name="3398"
                    formControlName="3398"
                    (blur)="clearInputIfInvalid('3398')"
                    type="text"
                    data-id="Gap to 2"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="d49.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
                <div  *ngIf="AbaBuildBlocksForms.get('3398').invalid ">
                  <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('3398').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('3398').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('3398').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('3398').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </div>
              <div
                class="form-group form-inline no-margin col-md-6 sideLabelAlignment"
              >
                <label class="formLabel"> Explanation: </label>
                <input
                  style="width: 50%"
                  class="form-control"
                  id="1831"
                  name="1831"
                  formControlName="1831"
                  type="text"
                  data-id="Gap 2"
                />
              </div>
              <div
                class="form-group form-inline no-margin col-md-6 sideLabelAlignment"
              >
                <label class="formLabel">Gap Dates:</label>
                <div class="input-group">
                  <input
                    style="width: 40%"
                    class="form-control onboard datepicker"
                    placeholder="MM/DD/YYYY"
                    ngbDatepicker
                    #d50="ngbDatepicker"
                    id="3399"
                    name="3399"
                    formControlName="3399"
                    (blur)="clearInputIfInvalid('3399')"
                    type="text"
                    data-id="Gap from 3.0"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="d50.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
                <div  *ngIf="AbaBuildBlocksForms.get('3399').invalid ">
                  <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('3399').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('3399').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('3399').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('3399').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
                <div class="input-group">
                  <input
                    style="width: 53%"
                    class="form-control onboard datepicker"
                    placeholder="MM/DD/YYYY"
                    ngbDatepicker
                    #d51="ngbDatepicker"
                    id="3400"
                    name="3400"
                    formControlName="3400"
                    (blur)="clearInputIfInvalid('3400')"
                    type="text"
                    data-id="Gap to 3.0"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="d51.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
                <div  *ngIf="AbaBuildBlocksForms.get('3400').invalid ">
                  <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('3400').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('3400').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('3400').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('3400').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </div>
              <div
                class="form-group form-inline no-margin col-md-6 sideLabelAlignment"
              >
                <label class="formLabel"> Explanation: </label>
                <input
                  style="width: 50%"
                  class="form-control"
                  id="1832"
                  name="1832"
                  formControlName="1832"
                  type="text"
                  data-id="Gap 3.0"
                />
              </div>
              <div
                class="form-group form-inline no-margin col-md-6 sideLabelAlignment"
              >
                <label class="formLabel">Gap Dates:</label>
                <div class="input-group">
                  <input
                    style="width: 40%"
                    class="form-control onboard datepicker"
                    placeholder="MM/DD/YYYY"
                    ngbDatepicker
                    #d52="ngbDatepicker"
                    id="3401"
                    name="3401"
                    formControlName="3401"
                    (blur)="clearInputIfInvalid('3401')"
                    type="text"
                    data-id="Gap from 3.1"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="d52.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
                <div  *ngIf="AbaBuildBlocksForms.get('3401').invalid ">
                  <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('3401').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('3401').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('3401').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('3401').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
                <div class="input-group">
                  <input
                    style="width: 53%"
                    class="form-control onboard datepicker"
                    placeholder="MM/DD/YYYY"
                    ngbDatepicker
                    #d53="ngbDatepicker"
                    id="3402"
                    name="3402"
                    formControlName="3402"
                    (blur)="clearInputIfInvalid('3402')"
                    type="text"
                    data-id="Gap to 3.1"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="d53.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
                <div  *ngIf="AbaBuildBlocksForms.get('3402').invalid ">
                  <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('3402').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('3402').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('3402').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('3402').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </div>
              <div
                class="form-group form-inline no-margin col-md-6 sideLabelAlignment"
              >
                <label class="formLabel"> Explanation: </label>
                <input
                  style="width: 50%"
                  class="form-control"
                  id="1953"
                  name="1953"
                  formControlName="1953"
                  type="text"
                  data-id="Gap 3.1"
                />
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="18">
              <div class="centered texasFirstTable">
                <h3 class="texasHThree">CAQH Information</h3>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="18">
              <div class="row">
                <div class="col-md-3">
                  <label class="formLabel">CAQH ID<span
                    class="mandatory">*</span></label>
                  <input
                    class="form-control"
                    id="333"
                    [class.is-invalid]="
                      (AbaBuildBlocksForms.controls['333'].touched &&
                        AbaBuildBlocksForms.controls['333'].invalid) ||
                      (AbaBuildBlocksForms.controls['333'].invalid && isSubmit)
                    "
                    name="333"
                    formControlName="333"
                    
                    type="text"
                    data-id="lname"
                  />
                  <div
                    class="formLabel col-md-2"
                    *ngIf="
                      AbaBuildBlocksForms.controls['333'].touched &&
                      AbaBuildBlocksForms.controls['333'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="AbaBuildBlocksForms.controls['333'].errors?.required"
                    >
                      required
                    </small>
                  </div>
                </div>
                <div class="col-md-3 ">
                  <label class="formLabel">CAQH User Name<span
                    class="mandatory">*</span></label>
                  <input
                    class="form-control"
                    id="334"
                    [class.is-invalid]="
                      (AbaBuildBlocksForms.controls['334'].touched &&
                        AbaBuildBlocksForms.controls['334'].invalid) ||
                      (AbaBuildBlocksForms.controls['334'].invalid && isSubmit)
                    "
                    name="334"
                   
                    formControlName="334"
                    type="text"
                    data-id="fname"
                  />
                  <div
                    class="formLabel col-md-2"
                    *ngIf="
                      AbaBuildBlocksForms.controls['334'].touched &&
                      AbaBuildBlocksForms.controls['334'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="AbaBuildBlocksForms.controls['334'].errors?.required"
                    >
                      required
                    </small>
                  </div>
                </div>
                <div class="col-md-3 ">
                  <label class="formLabel">CAQH Password<span
                    class="mandatory">*</span></label>
                  <input
                    class="form-control"
                    id="335"
                    name="335"
                    formControlName="335"
                    [class.is-invalid]="
                      (AbaBuildBlocksForms.controls['335'].touched &&
                        AbaBuildBlocksForms.controls['335'].invalid) ||
                      (AbaBuildBlocksForms.controls['335'].invalid && isSubmit)
                    "
                    type="text"
                    data-id="mname"
                  />
                  <div
                    class="formLabel col-md-2"
                    *ngIf="
                      AbaBuildBlocksForms.controls['335'].touched &&
                      AbaBuildBlocksForms.controls['335'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="AbaBuildBlocksForms.controls['335'].errors?.required"
                    >
                      required
                    </small>
                  </div>
                </div>
                
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="18">
              <div class="form-inline no-margin col-md-12 sideLabelAlignment">
                <label class="formLabel"
                  >Confirm you have granted 'payer access' to your CAQH profile?
                </label>
                <label class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_004"
                    name="cmd_004"
                    formControlName="cmd_004"
                    value="1"
                  />Yes</label
                >
                <label class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_004"
                    name="cmd_004"
                    formControlName="cmd_004"
                    value="0"
                  />No</label
                >
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="18">
              <div class="form-inline no-margin col-md-12 sideLabelAlignment">
                <label class="formLabel"
                  >Confirm your CAQH profile is up to date with a valid attestation (signed every 120 days)
                </label>
                <label class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_005"
                    name="cmd_005"
                    formControlName="cmd_005"
                    value="1"
                  />Yes</label
                >
                <label class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_005"
                    name="cmd_005"
                    formControlName="cmd_005"
                    value="0"
                  />No</label
                >
              </div>
            </td>
          </tr>  
          <tr>
            <td colspan="18">
              <div class="form-inline no-margin col-md-12 sideLabelAlignment">
                <label class="formLabel"
                  >Confirm you have uploaded all licenses, certifications, CV's and malpractice policies to your CAQH profile
                </label>
                <label class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_006"
                    name="cmd_006"
                    formControlName="cmd_006"
                    value="1"
                  />Yes</label
                >
                <label class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_006"
                    name="cmd_006"
                    formControlName="cmd_006"
                    value="0"
                  />No</label
                >
              </div>
            </td>
          </tr>  
          
        </table>   
        <hr class="tablePersonal" />
      
      <table border="1" width="100%">
        <tr>
          <td colspan="14">
            <div
              class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
            >
              <label class="formLabel"><b>References</b></label
              >-Please provide three peer references from the same field
              and/or specialty who are not partners in your own group practice
              and are not relatives. All peer references should have firsthand
              knowledge of your abilities.
            </div>
          </td>
          <td colspan="4">
            <div class="form-check">
              <b style="font-size:14px;">Does Not Apply</b>
              <input
                type="checkbox"
                style="height:15px;width:15px;top: 0;padding-left:95px;"
                name="cmd_NA_07"
                id="cmd_NA_07"
                formControlName="cmd_NA_07"
                value="1"
                (ngModelChange)="handleNAchange('cmd_NA_07')"
                
              />
            </div>  
          </td>
        </tr>
        <tr>
          <td colspan="6">
            <div
              class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
            >
              <label class="formLabel">1. NAME/TITLE<span
                class="mandatory">*</span></label>
              <input
                class="form-control"
                (blur)="handleNAchange('cmd_NA_07',359)" [class.is-invalid]="AbaBuildBlocksForms.controls['359'].invalid"
                id="359"
                name="359"
                formControlName="359"
                type="text"
                data-id="reference1name"
              />
              <div class="mandatory"
                                  *ngIf="AbaBuildBlocksForms.controls['359'].invalid">
                                  <small >
                                    required
                                  </small>
              </div> 
            </div>
            <div
              class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
            >  
              <label class="formLabel">E-MAIL<span
                class="mandatory">*</span></label>
              <input
                style="margin-left: 47px"
                (blur)="handleNAchange('cmd_NA_07',371)" [class.is-invalid]="AbaBuildBlocksForms.controls['371'].invalid"
                class="form-control"
                id="371"
                name="371"
                formControlName="371"
                type="text"
                data-id="reference1email"
              />
              <div class="mandatory"
                *ngIf="AbaBuildBlocksForms.controls['371'].invalid">
                <small >
                  required
                </small>
              </div>    
            </div>
          </td>
          <td colspan="6">
            <div class="form-group form-inline no-margin col-md-12 textInPro">
              <label class="formLabel"
                >PHONE NUMBER<span
                class="mandatory">*</span>
                <input
                  class="form-control"
                  (keypress)="numberOnly($event)"
                  (blur)="handleNAchange('cmd_NA_07',369)" [class.is-invalid]="AbaBuildBlocksForms.controls['369'].invalid"
                  id="369"
                  name="369"
                  formControlName="369"
                  type="text"
                  data-id="reference1telephone"
                />
                <div class="mandatory"
                                  *ngIf="AbaBuildBlocksForms.controls['369'].invalid">
                                  <small >
                                    required
                                  </small>
                </div> 
              </label>
            </div>
          </td>
          <td colspan="6">
            <div class="form-group form-inline no-margin col-md-12 textInPro">
              <label class="formLabel"
                >FAX NUMBER
                <input
                  class="form-control"
                  id="370"
                  name="370"
                  formControlName="370"
                  type="text"
                  data-id="reference1fax"
                />
              </label>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="18">
            <div
              class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
            >
              <label class="checkbox-inline radioStyle">ADDRESS<span
                class="mandatory">*</span></label>
              <input
                style="width: 40%;"
                (blur)="handleNAchange('cmd_NA_07',360)" [class.is-invalid]="AbaBuildBlocksForms.controls['360'].invalid"
                class="form-control"
                id="360"
                name="360"
                formControlName="360"
                type="text"
                data-id="reference1address"
              />
              <div class="mandatory"
                                  *ngIf="AbaBuildBlocksForms.controls['360'].invalid">
                                  <small >
                                    required
                                  </small>
              </div> 
              <input
                style="width: 40%;"
                (blur)="handleNAchange('cmd_NA_07',361)" [class.is-invalid]="AbaBuildBlocksForms.controls['361'].invalid"
                class="form-control"
                id="361"
                name="361"
                formControlName="361"
                type="text"
                data-id="reference1address2"
              />
              <div class="mandatory"
                                  *ngIf="AbaBuildBlocksForms.controls['361'].invalid">
                                  <small >
                                    required
                                  </small>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="18">
            <div
              class="form-group form-inline no-margin col-md-3 sideLabelAlignment"
            >
              <label class="formLabel">CITY<span
                class="mandatory">*</span></label>
              <input
                style="width: 100%"
                (blur)="handleNAchange('cmd_NA_07',362)" [class.is-invalid]="AbaBuildBlocksForms.controls['362'].invalid"
                class="form-control"
                id="362"
                name="362"
                formControlName="362"
                type="text"
                data-id="reference1city"
              />
              <div class="mandatory"
                                  *ngIf="AbaBuildBlocksForms.controls['362'].invalid">
                                  <small >
                                    required
                                  </small>
              </div>
            </div>
            <div
              class="form-group form-inline no-margin col-md-3 sideLabelAlignment"
            >
              <label class="formLabel">STATE/COUNTRY<span
                class="mandatory">*</span></label>
              <div class="col-md-12">
                <ng-select
                  [items]="states"
                  (blur)="handleNAchange('cmd_NA_07')" [class.is-invalid]="AbaBuildBlocksForms.controls['364'].invalid"
                  bindLabel="name"
                  placeholder="Select States"
                  bindValue="id"
                  formControlName="364"
                >
                </ng-select>
                <div class="mandatory"
                                  *ngIf="AbaBuildBlocksForms.controls['364'].invalid">
                                  <small >
                                    required
                                  </small>
                </div> 
              </div>
            </div>
            <div
              class="form-group form-inline no-margin col-md-3 sideLabelAlignment"
            >
              <label class="formLabel">POSTAL CODE<span
                class="mandatory">*</span></label>
              <input
                style="width: 100%"
                minlength="5"
                maxlength="10"
                (blur)="handleNAchange('cmd_NA_07',365)" [class.is-invalid]="AbaBuildBlocksForms.controls['365'].invalid"
                class="form-control"
                id="365"
                name="365"
                formControlName="365"
                type="text"
                data-id="reference1zip"
              />
              <div class="mandatory"
                                    *ngIf="AbaBuildBlocksForms.controls['365'].invalid || AbaBuildBlocksForms.controls['451'].errors?.minlength ||
                                    AbaBuildBlocksForms.controls['365'].errors?.maxlength">
                                    <small >
                                      required
                                    </small>
              </div> 
              <!-- <div
                class="formLabel col-md-2"
                *ngIf="
                  AbaBuildBlocksForms.controls['365'].touched &&
                  AbaBuildBlocksForms.controls['365'].errors
                "
              >
                <small
                  class="text-danger"
                  *ngIf="AbaBuildBlocksForms.controls['365'].errors"
                >
                  required
                </small>
                <small
                  class="text-danger"
                  *ngIf="
                    AbaBuildBlocksForms.controls['365'].errors?.minlength ||
                    AbaBuildBlocksForms.controls['365'].errors?.maxlength
                  "
                >
                  Zip code must be atleast 5 digits and less than 10 digits
                </small>
              </div> -->
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="6">
            <div
              class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
            >
              <label class="formLabel">2. NAME/TITLE<span
                class="mandatory">*</span></label>
              <input
                class="form-control"
                (blur)="handleNAchange('cmd_NA_07',372)" [class.is-invalid]="AbaBuildBlocksForms.controls['372'].invalid"
                id="372"
                name="372"
                formControlName="372"
                type="text"
                data-id="reference2name"
              />
              <div class="mandatory"
                                  *ngIf="AbaBuildBlocksForms.controls['372'].invalid">
                                  <small >
                                    required
                                  </small>
              </div> 
              <label class="formLabel"
                >E-MAIL<span
                class="mandatory">*</span>
                <input
                  class="form-control"
                  (blur)="handleNAchange('cmd_NA_07',383)" [class.is-invalid]="AbaBuildBlocksForms.controls['383'].invalid"
                  id="383"
                  name="383"
                  formControlName="383"
                  type="text"
                  data-id="Text8.1.0"
                />
                <div class="mandatory"
                                  *ngIf="AbaBuildBlocksForms.controls['383'].invalid">
                                  <small >
                                    required
                                  </small>
                </div> 
              </label>
            </div>
          </td>
          <td colspan="6">
            <div class="form-group form-inline no-margin col-md-12 textInPro">
              <label class="formLabel"
                >PHONE NUMBER<span
                class="mandatory">*</span>
                <input
                  class="form-control"
                  (keypress)="numberOnly($event)"
                  (blur)="handleNAchange('cmd_NA_07',381)" [class.is-invalid]="AbaBuildBlocksForms.controls['381'].invalid"
                  id="381"
                  name="381"
                  formControlName="381"
                  type="text"
                  data-id="reference2telephone"
                />
                <div class="mandatory"
                    *ngIf="AbaBuildBlocksForms.controls['381'].invalid">
                    <small >
                      required
                    </small>
                </div> 
              </label>
            </div>
          </td>
          <td colspan="6">
            <div class="form-group form-inline no-margin col-md-12 textInPro">
              <label class="formLabel"
                >FAX NUMBER
                <input
                  style="margin-left: 65px"
                  class="form-control"
                  id="382"
                  name="382"
                  formControlName="382"
                  type="text"
                  data-id="Text8.1.1"
                />
              </label>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="18">
            <div
              class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
            >
              <label class="checkbox-inline radioStyle">ADDRESS</label>
              <input
                class="form-control"
                id="373"
                name="373"
                formControlName="373"
                type="text"
                data-id="reference2address"
              />
              <input
                class="form-control"
                id="374"
                name="374"
                formControlName="374"
                type="text"
                data-id="reference2address2"
              />
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="18">
            <div
              class="form-group form-inline no-margin col-md-3 sideLabelAlignment"
            >
              <label class="formLabel">CITY</label>
              <input
                style="width: 100%"
                class="form-control"
                id="375"
                name="375"
                formControlName="375"
                type="text"
                data-id="reference2city"
              />
            </div>
            <div
              class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
            >
              <label class="formLabel">STATE/COUNTRY</label>
              <div class="form-group selectDrop dropDrownSet">
                <div class="col-md-12">
                  <ng-select
                    [items]="states"
                    bindLabel="name"
                    placeholder="Select States"
                    bindValue="id"
                    formControlName="377"
                  >
                  </ng-select>
                </div>
              </div>
            </div>
            <div
              class="form-group form-inline no-margin col-md-3 sideLabelAlignment"
            >
              <label class="formLabel">POSTAL CODE</label>
              <input
                style="width: 100%"
                class="form-control"
                id="378"
                name="378"
                formControlName="378"
                type="text"
                data-id="reference2zip"
              />
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="6">
            <div
              class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
            >
              <label class="formLabel">3. NAME/TITLE<span
                class="mandatory">*</span></label>
              <input
                class="form-control"
                (blur)="handleNAchange('cmd_NA_07',384)" [class.is-invalid]="AbaBuildBlocksForms.controls['384'].invalid"
                id="384"
                name="384"
                formControlName="384"
                type="text"
                data-id="reference3name"
              />
              <div class="mandatory"
                  *ngIf="AbaBuildBlocksForms.controls['384'].invalid">
                  <small >
                    required
                  </small>
              </div> 
              <label class="formLabel"
                >E-MAIL<span
                class="mandatory">*</span>
                <input
                  class="form-control"
                  (blur)="handleNAchange('cmd_NA_07',396)" [class.is-invalid]="AbaBuildBlocksForms.controls['396'].invalid"
                  id="396"
                  name="396"
                  formControlName="396"
                  type="text"
                  data-id="Text8.2.0"
                />
                <div class="mandatory"
                                  *ngIf="AbaBuildBlocksForms.controls['396'].invalid">
                                  <small >
                                    required
                                  </small>
                </div> 
              </label>
            </div>
          </td>
          <td colspan="6">
            <div class="form-group form-inline no-margin col-md-12 textInPro">
              <label class="formLabel"
                >PHONE NUMBER<span
                class="mandatory">*</span>
                <input
                  class="form-control"
                  (keypress)="numberOnly($event)"
                  (blur)="handleNAchange('cmd_NA_07',394)" [class.is-invalid]="AbaBuildBlocksForms.controls['394'].invalid"
                  id="394"
                  name="394"
                  formControlName="394"
                  type="text"
                  data-id="reference3telephone"
                />
                <div class="mandatory"
                                  *ngIf="AbaBuildBlocksForms.controls['394'].invalid">
                                  <small >
                                    required
                                  </small>
                </div> 
              </label>
            </div>
          </td>
          <td colspan="6">
            <div class="form-group form-inline no-margin col-md-12 textInPro">
              <label class="formLabel"
                >FAX NUMBER
                <input
                  style="margin-left : 65px"
                  class="form-control"
                  id="395"
                  name="395"
                  formControlName="395"
                  type="text"
                  data-id="Text8.2.1"
                />
              </label>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="18">
            <div
              class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
            >
              <label class="checkbox-inline radioStyle">ADDRESS</label>
              <input
                class="form-control"
                id="385"
                name="385"
                formControlName="385"
                type="text"
                data-id="reference3add"
              />
              <input
                class="form-control"
                id="386"
                name="386"
                formControlName="386"
                type="text"
                data-id="reference3spec"
              />
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="18">
            <div
              class="form-group form-inline no-margin col-md-3 sideLabelAlignment"
            >
              <label class="formLabel">CITY</label>
              <input
                style="width: 100%"
                class="form-control"
                id="387"
                name="387"
                formControlName="387"
                type="text"
                data-id="reference3city"
              />
            </div>
            <div
              class="form-group form-inline no-margin col-md-3 sideLabelAlignment"
            >
              <label class="formLabel">STATE/COUNTRY</label>
              <div class="col-md-12">
                <ng-select
                  [items]="states"
                  bindLabel="name"
                  placeholder="Select States"
                  bindValue="id"
                  formControlName="389"
                >
                </ng-select>
              </div>
            </div>
            <div
              class="form-group form-inline no-margin col-md-3 sideLabelAlignment"
            >
              <label class="formLabel">POSTAL CODE</label>
              <input
                style="width: 100%"
                class="form-control"
                id="390"
                name="390"
                formControlName="390"
                type="text"
                data-id="reference3zip"
              />
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="14">
            <div
              class="form-group form-inline no-margin col-md-5 sideLabelAlignment"
            >
              <label class="formLabel"
                ><b>Professional Liability Insurance Coverage</b></label
              >
            </div>
          </td>
          <td colspan="4">
            <div class="form-check">
              <b style="font-size:14px;">Does Not Apply</b>
              <input
                type="checkbox"
                style="height:17px;width:15px;"
                name="cmd_NA_08"
                id="cmd_NA_08"
                formControlName="cmd_NA_08"
                value="1"
                (ngModelChange)="handleNAchange('cmd_NA_08')"
                
              />
            </div>  
          </td>
        </tr>
        <tr>
          <td colspan="6" width="21%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              SELF-INSURED?<label class="checkbox-inline radioStyle">
                <input
                  type="radio"
                  id="cmd_071"
                  name="cmd_071"
                  formControlName="cmd_071"
                  value="1"
                />Yes</label
              >
              <label class="checkbox-inline radioStyle">
                <input
                  type="radio"
                  id="cmd_071"
                  name="cmd_071"
                  formControlName="cmd_071"
                  value="0"
                />No</label
              >
            </div>
          </td>
          <td colspan="12" width="79%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel"
                >NAME OF CURRENT MALPRACTICE INSURANCE CARRIER OR SELF-INSURED
                ENTITY<span
                class="mandatory">*</span>
              </label>
              <input
                class="form-control"
                (blur)="handleNAchange('cmd_NA_08',345)" [class.is-invalid]="AbaBuildBlocksForms.controls['345'].invalid"
                id="345"
                name="345"
                formControlName="345"
                type="text"
                data-id="mlp1carrier"
              />
              <div class="mandatory"
                                  *ngIf="AbaBuildBlocksForms.controls['345'].invalid">
                                  <small >
                                    required
                                  </small>
              </div> 
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="18" width="79%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel">ADDRESS<span
                class="mandatory">*</span> </label>
              <input
                class="form-control"
                (blur)="handleNAchange('cmd_NA_08',901)" [class.is-invalid]="AbaBuildBlocksForms.controls['901'].invalid"
                id="901"
                name="901"
                formControlName="901"
                type="text"
                data-id="MLP Mailing Add1"
              />
              <div class="mandatory"
                                  *ngIf="AbaBuildBlocksForms.controls['901'].invalid">
                                  <small >
                                    required
                                  </small>
              </div> 
              <input
                class="form-control"
                (blur)="handleNAchange('cmd_NA_08',902)" [class.is-invalid]="AbaBuildBlocksForms.controls['902'].invalid"
                id="902"
                name="902"
                formControlName="902"
                type="text"
                data-id="MLP Mailing Add2"
              />
              <div class="mandatory"
                                  *ngIf="AbaBuildBlocksForms.controls['902'].invalid">
                                  <small >
                                    required
                                  </small>
              </div> 
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="18">
            <div
              class="form-group form-inline no-margin col-md-3 sideLabelAlignment"
            >
              <label class="formLabel">CITY<span
                class="mandatory">*</span></label>
              <input
                style="width: 100%"
                (blur)="handleNAchange('cmd_NA_08',903)" [class.is-invalid]="AbaBuildBlocksForms.controls['903'].invalid"
                class="form-control"
                id="903"
                name="903"
                formControlName="903"
                type="text"
                data-id="MLP City"
              />
              <div class="mandatory"
                                  *ngIf="AbaBuildBlocksForms.controls['903'].invalid">
                                  <small >
                                    required
                                  </small>
              </div> 
            </div>
            <div
              class="form-group form-inline no-margin col-md-3 sideLabelAlignment"
            >
              <label class="formLabel">STATE/COUNTRY<span
                class="mandatory">*</span></label>
              <div class="col-md-12">
                <ng-select
                  [items]="states"
                  (blur)="handleNAchange('cmd_NA_08',905)" [class.is-invalid]="AbaBuildBlocksForms.controls['905'].invalid"
                  bindLabel="name"
                  placeholder="Select States"
                  bindValue="id"
                  formControlName="905"
                >
                </ng-select>
                <div class="mandatory"
                                  *ngIf="AbaBuildBlocksForms.controls['905'].invalid">
                                  <small >
                                    required
                                  </small>
                </div> 
              </div>
            </div>
            <div
              class="form-group form-inline no-margin col-md-3 sideLabelAlignment"
            >
              <label class="formLabel">POSTAL CODE<span
                class="mandatory">*</span></label>
              <input
                style="width: 100%"
                minlength="5"
                maxlength="10"
                (blur)="handleNAchange('cmd_NA_08',906)" [class.is-invalid]="AbaBuildBlocksForms.controls['906'].invalid"
                class="form-control"
                id="906"
                name="906"
                formControlName="906"
                type="text"
                data-id="MLP Zip"
              />
              <div class="mandatory"
                                    *ngIf="AbaBuildBlocksForms.controls['906'].invalid || AbaBuildBlocksForms.controls['906'].errors?.minlength ||
                                    AbaBuildBlocksForms.controls['906'].errors?.maxlength">
                                    <small >
                                      required
                                    </small>
              </div> 
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="4">
            <div
              class="form-group form-inline no-margin col-md-4 sideLabelAlignment"
            >
              <label class="formLabel">PHONE NUMBER<span
                class="mandatory">*</span> </label>
              <input
                class="form-control"
                (keypress)="numberOnly($event)"
                (blur)="handleNAchange('cmd_NA_08',1266)" [class.is-invalid]="AbaBuildBlocksForms.controls['1266'].invalid"
                id="1266"
                name="1266"
                formControlName="1266"
                type="text"
                data-id="MLP Phone"
              />
              <div class="mandatory"
                                  *ngIf="AbaBuildBlocksForms.controls['1266'].invalid">
                                  <small >
                                    required
                                  </small>
              </div> 
            </div>
          </td>
          <td colspan="4">
            <div
              class="form-group form-inline no-margin col-md-4 sideLabelAlignment"
            >
              <label class="formLabel">POLICY NUMBER<span
                class="mandatory">*</span></label>
              <input
                class="form-control"
                (blur)="handleNAchange('cmd_NA_08',346)" [class.is-invalid]="AbaBuildBlocksForms.controls['346'].invalid"
                id="346"
                name="346"
                formControlName="346"
                type="text"
                data-id="mlp1pol"
              />
              <div class="mandatory"
                                  *ngIf="AbaBuildBlocksForms.controls['346'].invalid">
                                  <small >
                                    required
                                  </small>
              </div> 
            </div>
          </td>
          <td colspan="10">
            <div
              class="form-group form-inline no-margin col-md-6 sideLabelAlignment"
            >
              <label class="formLabel">EFFECTIVE DATE (MM/DD/YYYY)<span
                class="mandatory">*</span> </label>
              <div class="input-group">
                <input
                  class="form-control onboard datepicker"
                  placeholder="MM/DD/YYYY"
                  ngbDatepicker
                  #d58="ngbDatepicker"
                  id="348"
                  name="348"
                  formControlName="348"
                  (blur)="clearInputIfInvalid('348')"
                  type="text"
                  data-id="mlp1dateeff_date"
                  (blur)="handleNAchange('cmd_NA_08',348)" [class.is-invalid]="AbaBuildBlocksForms.controls['348'].invalid"
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-outline-secondary calendar "
                    (click)="d58.toggle()"
                    type="button"
                  >
                    <i class="fas fa-calendar-alt"></i>
                  </button>
                </div>
              </div>
              <div class="text-danger"
              *ngIf="AbaBuildBlocksForms.controls['348'].invalid && (AbaBuildBlocksForms.controls['348'].value==''|| AbaBuildBlocksForms.controls['348'].value==null) ">
              <small>
                required
              </small>
            
            </div>
            
            <div *ngIf="AbaBuildBlocksForms.get('348').invalid">
              <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('348').errors.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY) </small>
              <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('348').errors.invalidMonth">
                Month is out of range
              </small>
              <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('348').errors.invalidDateyear">
                year is out of range(1900-2099) </small>
              <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('348').errors.invalidDateRange">
                date is out of range
              </small>
            </div>
            </div>
            <div
              class="form-group form-inline no-margin col-md-6 sideLabelAlignment"
            >
              <label class="formLabel">EXPIRATION DATE (MM/DD/YYYY)<span
                class="mandatory">*</span> </label>
              <div class="input-group">
                <input
                  class="form-control onboard datepicker"
                   [class.is-invalid]="AbaBuildBlocksForms.controls['349'].invalid"
                  placeholder="MM/DD/YYYY"
                  ngbDatepicker
                  #d59="ngbDatepicker"
                  id="349"
                  name="349"
                  formControlName="349"
                  (blur)="clearInputIfInvalid('349')"
                  (blur)="handleNAchange('cmd_NA_08',349)"
                  type="text"
                  data-id="mlp1dateexp_date"
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-outline-secondary calendar"
                    (click)="d59.toggle()"
                    type="button"
                  >
                    <i class="fas fa-calendar-alt"></i>
                  </button>
                </div>
              </div>
              <div class="text-danger"
              *ngIf="AbaBuildBlocksForms.controls['349'].invalid && (AbaBuildBlocksForms.controls['349'].value==''|| AbaBuildBlocksForms.controls['349'].value==null) ">
              <small>
                required
              </small>
            
            </div>
            
            <div *ngIf="AbaBuildBlocksForms.get('349').invalid">
              <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('349').errors.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY) </small>
              <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('349').errors.invalidMonth">
                Month is out of range
              </small>
              <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('349').errors.invalidDateyear">
                year is out of range(1900-2099) </small>
              <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('349').errors.invalidDateRange">
                date is out of range
              </small>
            </div>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="4">
            <div
              class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
            >
              <label class="formLabel"
                >AMOUNT OF COVERAGE PER OCCURRENCE<span
                class="mandatory">*</span>
              </label>
              <div class="form-group selectDrop dropDrownSet">
                <ng-select
                  [items]="preClaim"
                  (blur)="handleNAchange('cmd_NA_08',350)" [class.is-invalid]="AbaBuildBlocksForms.controls['350'].invalid"
                  bindLabel="name"
                  placeholder="Select Per Occurrence"
                  bindValue="id"
                  formControlName="350"
                >
                </ng-select>
                <div class="mandatory"
                                  *ngIf="AbaBuildBlocksForms.controls['350'].invalid">
                                  <small >
                                    required
                                  </small>
              </div> 
              </div>
            </div>
          </td>
          <td colspan="4">
            <div
              class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
            >
              <label class="formLabel">AMOUNT OF COVERAGE AGGREGATE<span
                class="mandatory">*</span></label>
              <div class="form-group selectDrop dropDrownSet">
                <ng-select
                  [items]="aggregates"
                  (blur)="handleNAchange('cmd_NA_08')" [class.is-invalid]="AbaBuildBlocksForms.controls['351'].invalid"
                  bindLabel="name"
                  placeholder="Select Aggregate"
                  bindValue="id"
                  formControlName="351"
                >
                </ng-select>
                <div class="mandatory"
                                  *ngIf="AbaBuildBlocksForms.controls['351'].invalid">
                                  <small >
                                    required
                                  </small>
                </div>
              </div>
            </div>
          </td>
          <td colspan="6">
            <div
              class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
            >
              <label class="checkbox-inline radioStyle">
                TYPE OF COVERAGE
                <br />
                <input
                  type="radio"
                  id="cmd_072"
                  name="cmd_072"
                  formControlName="cmd_072"
                  value="1"
                />Individual</label
              >
              <label class="checkbox-inline radioStyle">
                <input
                  type="radio"
                  id="cmd_072"
                  name="cmd_072"
                  formControlName="cmd_072"
                  value="0"
                />Shared</label
              >
            </div>
          </td>
          <td colspan="4">
            <div
              class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
            >
              <label class="formLabel">LENGTH OF TIME WITH CARRIER</label>
              <input
                class="form-control"
                id="cmd_073"
                name="cmd_073"
                formControlName="cmd_073"
                type="text"
                data-id="MLP length of time"
              />
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="14">
            <div
              class="form-group form-inline no-margin col-md-5 sideLabelAlignment"
            >
              <label class="formLabel"
                ><b>PREVIOUS MALPRACTICE INSURANCE </b></label
              >
            </div>
          </td>
          <td colspan="4">
              <div class="form-check">
                <b style="font-size:14px;">Does Not Apply</b>
                <input
                  type="checkbox"
                  style="height:17px;width:15px;"
                  name="cmd_NA_09"
                  id="cmd_NA_09"
                  formControlName="cmd_NA_09"
                  value="1"
                  (ngModelChange)="handleNAchange('cmd_NA_09')"
                  
                />
              </div>  
          </td>
        </tr>
        <tr>
          <td colspan="18" width="79%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel"
                >NAME OF PREVIOUS MALPRACTICE INSURANCE CARRIER IF WITH
                CURRENT CARRIER LESS THAN 5 YEARS<span
                class="mandatory">*</span>
              </label>
              <input
                class="form-control"
                id="1242"
                (blur)="handleNAchange('cmd_NA_09',1242)" [class.is-invalid]="AbaBuildBlocksForms.controls['1242'].invalid"
                name="1242"
                formControlName="1242"
                type="text"
                data-id="prevmlp1carrier"
              />
              <div class="mandatory"
                                  *ngIf="AbaBuildBlocksForms.controls['1242'].invalid">
                                  <small >
                                    required
                                  </small>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="18" width="79%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel">ADDRESS<span
                class="mandatory">*</span> </label>
              <input
                class="form-control"
                id="1244"
                (blur)="handleNAchange('cmd_NA_09',1244)" [class.is-invalid]="AbaBuildBlocksForms.controls['1244'].invalid"
                name="1244"
                formControlName="1244"
                type="text"
                data-id="prevMLP Mailing Add1"
              />
              <div class="mandatory"
                                  *ngIf="AbaBuildBlocksForms.controls['1244'].invalid">
                                  <small >
                                    required
                                  </small>
              </div> 
              <input
                class="form-control"
                (blur)="handleNAchange('cmd_NA_09',1245)" [class.is-invalid]="AbaBuildBlocksForms.controls['1245'].invalid"
                id="1245"
                name="1245"
                formControlName="1245"
                type="text"
                data-id="prevMLP Mailing Add2"
              />
              <div class="mandatory"
                                  *ngIf="AbaBuildBlocksForms.controls['1245'].invalid">
                                  <small >
                                    required
                                  </small>
              </div> 
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="18">
            <div
              class="form-group form-inline no-margin col-md-3 sideLabelAlignment"
            >
              <label class="formLabel">CITY<span
                class="mandatory">*</span></label>
              <input
                style="width: 100%"
                (blur)="handleNAchange('cmd_NA_09',1246)" [class.is-invalid]="AbaBuildBlocksForms.controls['1246'].invalid"
                class="form-control"
                id="1246"
                name="1246"
                formControlName="1246"
                type="text"
                data-id="prevMLP City"
              />
              <div class="mandatory"
                                  *ngIf="AbaBuildBlocksForms.controls['1246'].invalid">
                                  <small >
                                    required
                                  </small>
              </div> 
            </div>
            <div
              class="form-group form-inline no-margin col-md-3 sideLabelAlignment"
            >
              <label class="formLabel">STATE/COUNTRY<span
                class="mandatory">*</span></label>
              <div class="form-group selectDrop dropDrownSet">
                <div class="col-md-12">
                  <ng-select
                    [items]="states"
                    (blur)="handleNAchange('cmd_NA_09')" [class.is-invalid]="AbaBuildBlocksForms.controls['1248'].invalid"
                    bindLabel="name"
                    placeholder="Select States"
                    bindValue="id"
                    formControlName="1248"
                  >
                  </ng-select>
                </div>
                <div class="mandatory"
                                  *ngIf="AbaBuildBlocksForms.controls['1248'].invalid">
                                  <small >
                                    required
                                  </small>
                </div> 
              </div>
            </div>
            <div
              class="form-group form-inline no-margin col-md-3 sideLabelAlignment"
            >
              <label class="formLabel">POSTAL CODE<span
                class="mandatory">*</span></label>
              <input
                style="width: 100%"
                minlength="5"
                maxlength="10"
                (blur)="handleNAchange('cmd_NA_09',1249)" [class.is-invalid]="AbaBuildBlocksForms.controls['1249'].invalid"
                class="form-control"
                id="1249"
                name="1249"
                formControlName="1249"
                type="text"
                data-id="prevMLP Zip"
              />
              <div class="mandatory"
                                    *ngIf="AbaBuildBlocksForms.controls['1249'].invalid || AbaBuildBlocksForms.controls['1249'].errors?.minlength ||
                                    AbaBuildBlocksForms.controls['1249'].errors?.maxlength">
                                    <small >
                                      required
                                    </small>
              </div> 
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="4">
            <div
              class="form-group form-inline no-margin col-md-6 sideLabelAlignment"
            >
              <label class="formLabel">PHONE NUMBER </label>
              <input
                class="form-control"
                (keypress)="numberOnly($event)"
                id="1254"
                name="1254"
                formControlName="1254"
                type="text"
                data-id="prevMLP Phone"
              />
            </div>
          </td>
          <td colspan="4">
            <div
              class="form-group form-inline no-margin col-md-5 sideLabelAlignment"
            >
              <label class="formLabel">POLICY NUMBER<span
                class="mandatory">*</span></label>
              <input
                class="form-control"
                (blur)="handleNAchange('cmd_NA_09',1256)" [class.is-invalid]="AbaBuildBlocksForms.controls['1256'].invalid"
                id="1256"
                name="1256"
                formControlName="1256"
                type="text"
                data-id="prevmlp1pol"
              />
              <div class="mandatory"
                                  *ngIf="AbaBuildBlocksForms.controls['1256'].invalid">
                                  <small >
                                    required
                                  </small>
              </div> 
            </div>
          </td>
          <td colspan="10">
            <div
              class="form-group form-inline no-margin col-md-6 sideLabelAlignment"
            >
              <label class="formLabel">EFFECTIVE DATE (MM/DD/YYYY)<span
                class="mandatory">*</span> </label>
              <div class="input-group">
                <input
                  class="form-control onboard datepicker"
                  [class.is-invalid]="AbaBuildBlocksForms.controls['1252'].invalid"
                  placeholder="MM/DD/YYYY"
                  ngbDatepicker
                  #d60="ngbDatepicker"
                  id="1252"
                  name="1252"
                  formControlName="1252"
                  (blur)="clearInputIfInvalid('1252')"
                  (blur)="handleNAchange('cmd_NA_09',1252)" 
                  type="text"
                  data-id="prevmlp1dateeff_date"
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-outline-secondary calendar"
                    (click)="d60.toggle()"
                    type="button"
                  >
                    <i class="fas fa-calendar-alt"></i>
                  </button>
                </div>
              </div>
              <div class="text-danger"
              *ngIf="AbaBuildBlocksForms.controls['1252'].invalid && (AbaBuildBlocksForms.controls['1252'].value==''|| AbaBuildBlocksForms.controls['1252'].value==null) ">
              <small>
                required
              </small>
            
            </div>
            
            <div *ngIf="AbaBuildBlocksForms.get('1252').invalid">
              <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('1252').errors.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY) </small>
              <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('1252').errors.invalidMonth">
                Month is out of range
              </small>
              <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('1252').errors.invalidDateyear">
                year is out of range(1900-2099) </small>
              <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('1252').errors.invalidDateRange">
                date is out of range
              </small>
            </div>
            </div>
            <div
              class="form-group form-inline no-margin col-md-6 sideLabelAlignment"
            >
              <label class="formLabel">EXPIRATION DATE (MM/DD/YYYY)<span
                class="mandatory">*</span> </label>
              <div class="input-group">
                <input
                  class="form-control onboard datepicker"
             [class.is-invalid]="AbaBuildBlocksForms.controls['1253'].invalid"
                  placeholder="MM/DD/YYYY"
                  ngbDatepicker
                  #d61="ngbDatepicker"
                  id="1253"
                  name="1253"
                  formControlName="1253"
                  (blur)="clearInputIfInvalid('1253')"
                  (blur)="handleNAchange('cmd_NA_09',1253)" 
                  type="text"
                  data-id="prevmlp1dateexp_date"
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-outline-secondary calendar"
                    (click)="d61.toggle()"
                    type="button"
                  >
                    <i class="fas fa-calendar-alt"></i>
                  </button>
                </div>
              </div>
              <div class="text-danger"
                  *ngIf="AbaBuildBlocksForms.controls['1253'].invalid && (AbaBuildBlocksForms.controls['1253'].value==''|| AbaBuildBlocksForms.controls['1253'].value==null) ">
                  <small>
                    required
                  </small>
                
                </div>
                
                <div *ngIf="AbaBuildBlocksForms.get('1253').invalid">
                  <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('1253').errors.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY) </small>
                  <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('1253').errors.invalidMonth">
                    Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('1253').errors.invalidDateyear">
                    year is out of range(1900-2099) </small>
                  <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('1253').errors.invalidDateRange">
                    date is out of range
                  </small>
                </div>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="4">
            <div
              class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
            >
              <label class="formLabel"
                >AMOUNT OF COVERAGE PER OCCURRENCE<span
                class="mandatory">*</span>
              </label>
              <div class="form-group selectDrop dropDrownSet">
                <ng-select
                  [items]="preClaim"
                  (blur)="handleNAchange('cmd_NA_09',1264)" [class.is-invalid]="AbaBuildBlocksForms.controls['1264'].invalid"
                  bindLabel="name"
                  placeholder="Select Per Occurrence"
                  bindValue="id"
                  formControlName="1264"
                >
                </ng-select>
                <div class="mandatory"
                                  *ngIf="AbaBuildBlocksForms.controls['1264'].invalid">
                                  <small >
                                    required
                                  </small>
                </div> 
              </div>
            </div>
          </td>
          <td colspan="4">
            <div
              class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
            >
              <label class="formLabel">AMOUNT OF COVERAGE AGGREGATE<span
                class="mandatory">*</span></label>
              <div class="form-group selectDrop dropDrownSet">
                <ng-select
                  [items]="aggregates"
                  (blur)="handleNAchange('cmd_NA_09',1265)" [class.is-invalid]="AbaBuildBlocksForms.controls['1265'].invalid"
                  bindLabel="name"
                  placeholder="Select Aggregate"
                  bindValue="id"
                  formControlName="1265"
                >
                </ng-select>
                <div class="mandatory"
                                  *ngIf="AbaBuildBlocksForms.controls['1265'].invalid">
                                  <small >
                                    required
                                  </small>
                </div> 
              </div>
            </div>
          </td>
          <td colspan="6">
            <div
              class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
            >
              <label class="checkbox-inline radioStyle">
                TYPE OF COVERAGE
                <br />
                <input
                  type="radio"
                  id="cmd_074"
                  name="cmd_074"
                  formControlName="cmd_074"
                  value="1"
                />Individual</label
              >
              <label class="checkbox-inline radioStyle">
                <input
                  type="radio"
                  id="cmd_074"
                  name="cmd_074"
                  formControlName="cmd_074"
                  value="0"
                />Shared</label
              >
            </div>
          </td>
          <td colspan="4">
            <div
              class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
            >
              <label class="formLabel">LENGTH OF TIME WITH CARRIER</label>
              <input
                class="form-control"
                id="cmd_075"
                name="cmd_075"
                formControlName="cmd_075"
                type="text"
                data-id="prevMLP length of time"
              />
            </div>
          </td>
        </tr>
      </table>
      <hr class="tablePersonal" />
      <table border="1" width="100%">
        <tr>
          <td colspan="14">
            <div
              class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
            >
              <label class="formLabel"
                ><b>Practice Location Information </b></label
              >
              - Please answer the following questions for each practice
              location. Use Attachment F or make copies of pages 6-7 as
              necessary.
            </div>
          </td>
          <td colspan="4">
            <div
              class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
            >
              <label class="checkbox-inline radioStyle"
                >PRACTICE LOCATION
              </label>
              <input
                class="form-control"
                id="cmd_088"
                name="cmd_088"
                formControlName="cmd_088"
                type="text"
                data-id="Text3.0"
              />of
              <input
                class="form-control"
                id="cmd_089"
                name="cmd_089"
                formControlName="cmd_089"
                type="text"
                data-id="Text3.1"
              />
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="18">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              TYPE OF SERVICE PROVIDED
              <label class="checkbox-inline radioStyle">
                <input
                  type="checkbox"
                  id="cmd_090"
                  name="cmd_090"
                  formControlName="cmd_090"
                  value="1"
                />Solo Primary Care
              </label>
              <label class="checkbox-inline radioStyle">
                <input
                  type="checkbox"
                  id="cmd_091"
                  name="cmd_091"
                  formControlName="cmd_091"
                  value="1"
                />Solo Specialty Care
              </label>
              <label class="checkbox-inline radioStyle">
                <input
                  type="checkbox"
                  id="cmd_092"
                  name="cmd_092"
                  formControlName="cmd_092"
                  value="1"
                />Group Primary Care
              </label>
              <label class="checkbox-inline radioStyle">
                <input
                  type="checkbox"
                  id="cmd_093"
                  name="cmd_093"
                  formControlName="cmd_093"
                  value="1"
                />Group Single Specialty
              </label>
              <label class="checkbox-inline radioStyle">
                <input
                  type="checkbox"
                  id="cmd_094"
                  name="cmd_094"
                  formControlName="cmd_094"
                  value="1"
                />Group Multi-Specialty
              </label>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="9">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel"
                >GROUP NAME/PRACTICE NAME TO APPEAR IN THE DIRECTORY
              </label>
              <input
                class="form-control"
                id="cmd_0728"
                name="cmd_0728"
                formControlName="cmd_0728"
                type="text"
                data-id="prac1_PracticeName"
              />
            </div>
          </td>
          <td colspan="9">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel"
                >GROUP/CORPORATE NAME AS IT APPEARS ON IRS W-9
              </label>
              <input
                style="width:70%;"
                class="form-control"
                id="cmd_0729"
                name="cmd_0729"
                formControlName="cmd_0729"
                type="text"
                data-id="prac1_TINName"
              />
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="18">
            <div
              class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
            >
              <label class="formLabel"
                >PRACTICE LOCATION ADDRESS:<input
                  type="checkbox"
                  id="cmd_095"
                  name="cmd_095"
                  formControlName="cmd_095"
                  value="1"
                />Primary
              </label>
              <input
                class="form-control"
                id="cmd_0730"
                name="cmd_0730"
                formControlName="cmd_0730"
                type="text"
                data-id="prac1_Address1"
              />
              <input
                class="form-control"
                id="cmd_0731"
                name="cmd_0731"
                formControlName="cmd_0731"
                type="text"
                data-id="prac1_Address2"
              />
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="18">
            <div
              class="form-group form-inline no-margin col-md-3 sideLabelAlignment"
            >
              <label class="formLabel">CITY</label>
              <input
                style="width:70%;"
                class="form-control"
                id="cmd_0732"
                name="cmd_0732"
                formControlName="cmd_0732"
                type="text"
                data-id="prac1_City"
              />
            </div>
            <div
              class="form-group form-inline no-margin col-md-4 sideLabelAlignment"
            >
              <label class="formLabel">STATE/COUNTRY</label>
              <div class="col-md-8">
                <ng-select
                  [items]="states"
                  bindLabel="name"
                  placeholder="Select States"
                  bindValue="id"
                  formControlName="cmd_0733"
                >
                </ng-select>
              </div>
            </div>
            <div
              class="form-group form-inline no-margin col-md-3 sideLabelAlignment"
            >
              <label class="formLabel">POSTAL CODE</label>
              <input
                style="width:70%;"
                class="form-control"
                id="cmd_0734"
                name="cmd_0734"
                formControlName="cmd_0734"
                type="text"
                data-id="prac1_ZIP"
              />
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="6">
            <div class="form-inline no-margin col-md-12 sideLabelAlignment">
              <label class="formLabel">PHONE NUMBER</label>
              <input
                class="form-control"
                (keypress)="numberOnly($event)"
                id="cmd_0735"
                name="cmd_0735"
                formControlName="cmd_0735"
                type="text"
                data-id="prac1_Phone"
              />
            </div>
          </td>
          <td colspan="6">
            <div
              class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
            >
              <label class="formLabel">FAX NUMBER </label>
              <input
                class="form-control"
                id="cmd_0736"
                name="cmd_0736"
                formControlName="cmd_0736"
                type="text"
                data-id="prac1_Fax"
              />
            </div>
          </td>
          <td colspan="6">
            <div
              class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
            >
              <label class="formLabel">E-MAIL </label>
              <input
                class="form-control"
                id="cmd_0737"
                name="cmd_0737"
                formControlName="cmd_0737"
                type="text"
                data-id="Office email"
              />
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="6">
            <div class="form-inline no-margin col-md-12 sideLabelAlignment">
              <label class="formLabel">BACK OFFICE PHONE NUMBER </label>
              <input
                class="form-control"
                (keypress)="numberOnly($event)"
                id="cmd_0738"
                name="cmd_0738"
                formControlName="cmd_0738"
                type="text"
                data-id="Answering Service"
              />
            </div>
          </td>
          <td colspan="6">
            <div
              class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
            >
              <label class="formLabel">SITE-SPECIFIC MEDICAID NUMBER</label>
              <input
                class="form-control"
                id="cmd_0739"
                name="cmd_0739"
                formControlName="cmd_0739"
                type="text"
                data-id="Pager"
              />
            </div>
          </td>
          <td colspan="6">
            <div
              class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
            >
              <label class="formLabel">TAX ID NUMBER</label>
              <input
                style="width:70%;"
                class="form-control"
                id="cmd_0740"
                name="cmd_0740"
                formControlName="cmd_0740"
                type="text"
                data-id="prac1_TIN"
              />
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="9">
            <div class="form-inline no-margin col-md-12 sideLabelAlignment">
              <label class="formLabel"
                >GROUP NUMBER CORRESPONDING TO TAX ID NUMBER</label
              >
              <input
                class="form-control"
                id="cmd_0741"
                name="cmd_0741"
                formControlName="cmd_0741"
                type="text"
                data-id="prac1_NPI"
              />
            </div>
          </td>
          <td colspan="9">
            <div
              class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
            >
              <label class="formLabel"
                >GROUP NAME CORRESPONDING TO TAX ID NUMBER
              </label>
              <input
                style="width:70%;"
                class="form-control"
                id="cmd_0742"
                name="cmd_0742"
                formControlName="cmd_0742"
                type="text"
                data-id="prac1_TINName"
              />
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="6">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              ARE YOU CURRENTLY PRACTICING AT THIS LOCATION?
              <label class="checkbox-inline radioStyle">
                <input
                  type="radio"
                  id="cmd_095"
                  name="cmd_095"
                  formControlName="cmd_095"
                  value="1"
                />Yes
              </label>
              <label class="checkbox-inline radioStyle">
                <input
                  type="radio"
                  id="cmd_095"
                  name="cmd_095"
                  formControlName="cmd_095"
                  value="0"
                />No
              </label>
            </div>
          </td>
          <td colspan="6">
            <label class="formLabel">
              IF NO, EXPECTED START DATE? (MM/DD/YYYY)
            </label>
            <div class="input-group">
              <input
                style="width: 62%"
                class="form-control onboard datepicker"
                ngbDatepicker
                #d62="ngbDatepicker"
                id="cmd_0743"
                name="cmd_0743"
                placeholder="MM/DD/YYYY"
                formControlName="cmd_0743"
                (blur)="clearInputIfInvalid('cmd_0743')"
                type="text"
                data-id="phystartdate"
              />
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary calendar"
                  (click)="d62.toggle()"
                  type="button"
                >
                  <i class="fas fa-calendar-alt"></i>
                </button>
              </div>
            </div>
            <div  *ngIf="AbaBuildBlocksForms.get('cmd_0743').invalid ">
              <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('cmd_0743').errors?.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY)
              </small>
              <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('cmd_0743').errors?.invalidMonth">
              Month is out of range
              </small>
              <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('cmd_0743').errors?.invalidDateyear">
                year is out of range(1900-2099)                      </small>
                <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('cmd_0743').errors?.invalidDateRange">
                  date is out of range
                  </small>
              </div>
          </td>
          <td colspan="6">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              DO YOU WANT THIS LOCATION LISTED IN THE DIRECTORY?
              <label class="checkbox-inline radioStyle">
                <input
                  type="radio"
                  id="cmd_096"
                  name="cmd_096"
                  formControlName="cmd_096"
                  value="1"
                />Yes
              </label>
              <label class="checkbox-inline radioStyle">
                <input
                  type="radio"
                  id="cmd_096"
                  name="cmd_096"
                  formControlName="cmd_096"
                  value="0"
                />No
              </label>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="6">
            <div class="form-inline no-margin col-md-12 sideLabelAlignment">
              <label class="formLabel"
                >OFFICE MANAGER OR STAFF CONTACT
              </label>
              <input
                style="width:40%;"
                class="form-control"
                id="cmd_0744"
                name="cmd_0744"
                formControlName="cmd_0744"
                type="text"
                data-id="prac1_OfficeMgrFname"
              />
              <input
                style="width:40%;"
                class="form-control"
                id="cmd_0745"
                name="cmd_0745"
                formControlName="cmd_0745"
                type="text"
                data-id="prac1_OfficeMgrLname"
              />
            </div>
          </td>
          <td colspan="6">
            <div
              class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
            >
              <label class="formLabel">PHONE NUMBER </label>
              <input
                style="width:70%;"
                (keypress)="numberOnly($event)"
                class="form-control"
                id="cmd_0746"
                name="cmd_0746"
                formControlName="cmd_0746"
                type="text"
                data-id="prac1_OMPhone"
              />
            </div>
          </td>
          <td colspan="6">
            <div
              class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
            >
              <label class="formLabel">FAX NUMBER </label>
              <input
                style="width:30%;"
                class="form-control"
                id="cmd_0747"
                name="cmd_0747"
                formControlName="cmd_0747"
                type="text"
                data-id="prac1_OMFax"
              />
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="18">
            <div class="form-inline no-margin col-md-12 sideLabelAlignment">
              <label class="formLabel">CAN YOU BILL ELECTRONICALLY? </label>
              <label class="radio-inline radioStyle">
                <input
                  type="radio"
                  id="cmd_098"
                  name="cmd_098"
                  formControlName="cmd_098"
                  value="1"
                />Yes</label
              >
              <label class="radio-inline radioStyle">
                <input
                  type="radio"
                  id="cmd_098"
                  name="cmd_098"
                  formControlName="cmd_098"
                  value="0"
                />No</label
              >
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="18">
            <div class="form-group form-inline no-margin col-md-12">
              <label class="formLabel">HOURS PATIENTS ARE SEEN </label>
            </div>
            <br />
            <div class="form-group form-inline no-margin col-md-2">
              Monday
            </div>
            <div class="form-group form-inline no-margin col-md-3">
              <input
                type="checkbox"
                id="cmd_099"
                name="cmd_099"
                formControlName="cmd_099"
                value="1"
              />No Office Hours
            </div>
            <div class="form-group form-inline no-margin col-md-2">
              Morning<input
                style="width:70%;"
                class="form-control"
                id="cmd_0770"
                name="cmd_0770"
                formControlName="cmd_0770"
                type="text"
                data-id="Monday"
              />
            </div>
            <div class="form-group form-inline no-margin col-md-2">
              Afternoon:<input
                style="width:70%;"
                class="form-control"
                id="cmd_0106"
                name="cmd_0106"
                formControlName="cmd_0106"
                type="text"
                data-id="Text4.2.3.0"
              />
            </div>
            <div class="form-group form-inline no-margin col-md-2">
              Evening:<input
                style="width:70%;"
                class="form-control"
                id="cmd_0114"
                name="cmd_0114"
                formControlName="cmd_0114"
                type="text"
                data-id="Text4.2.4.0"
              />
            </div>
            <div class="form-group form-inline no-margin col-md-2">
              Tuesday
            </div>
            <div class="form-group form-inline no-margin col-md-3">
              <input
                type="checkbox"
                id="cmd_0100"
                name="cmd_0100"
                formControlName="cmd_0100"
                value="1"
              />No Office Hours
            </div>
            <div class="form-group form-inline no-margin col-md-2">
              Morning<input
                style="width:70%;"
                class="form-control"
                id="cmd_0771"
                name="cmd_0771"
                formControlName="cmd_0771"
                type="text"
                data-id="Tuesday"
              />
            </div>
            <div class="form-group form-inline no-margin col-md-2">
              Afternoon:<input
                style="width:70%;"
                class="form-control"
                id="cmd_0107"
                name="cmd_0107"
                formControlName="cmd_0107"
                type="text"
                data-id="Text4.2.3.1"
              />
            </div>
            <div class="form-group form-inline no-margin col-md-2">
              Evening:<input
                style="width:70%;"
                class="form-control"
                id="cmd_0115"
                name="cmd_0115"
                formControlName="cmd_0115"
                type="text"
                data-id="Text4.2.4.1"
              />
            </div>
            <div class="form-group form-inline no-margin col-md-2">
              Wednesday
            </div>
            <div class="form-group form-inline no-margin col-md-3">
              <input
                type="checkbox"
                id="cmd_0101"
                name="cmd_0101"
                formControlName="cmd_0101"
                value="1"
              />No Office Hours
            </div>
            <div class="form-group form-inline no-margin col-md-2">
              Morning<input
                style="width:70%;"
                class="form-control"
                id="cmd_0772"
                name="cmd_0772"
                formControlName="cmd_0772"
                type="text"
                data-id="Wednesday"
              />
            </div>
            <div class="form-group form-inline no-margin col-md-2">
              Afternoon:<input
                style="width:70%;"
                class="form-control"
                id="cmd_0109"
                name="cmd_0109"
                formControlName="cmd_0109"
                type="text"
                data-id="Text4.2.3.2"
              />
            </div>
            <div class="form-group form-inline no-margin col-md-2">
              Evening:<input
                style="width:70%;"
                class="form-control"
                id="cmd_0116"
                name="cmd_0116"
                formControlName="cmd_0116"
                type="text"
                data-id="Text4.2.4.2"
              />
            </div>
            <div class="form-group form-inline no-margin col-md-2">
              Thursday
            </div>
            <div class="form-group form-inline no-margin col-md-3">
              <input
                type="checkbox"
                id="cmd_0102"
                name="cmd_0102"
                formControlName="cmd_0102"
                value="1"
              />No Office Hours
            </div>
            <div class="form-group form-inline no-margin col-md-2">
              Morning<input
                style="width:70%;"
                class="form-control"
                id="cmd_0773"
                name="cmd_0773"
                formControlName="cmd_0773"
                type="text"
                data-id="Thrusday"
              />
            </div>
            <div class="form-group form-inline no-margin col-md-2">
              Afternoon:<input
                style="width:70%;"
                class="form-control"
                id="cmd_0110"
                name="cmd_0110"
                formControlName="cmd_0110"
                type="text"
                data-id="Text4.2.3.3"
              />
            </div>
            <div class="form-group form-inline no-margin col-md-2">
              Evening:<input
                style="width:70%;"
                class="form-control"
                id="cmd_0117"
                name="cmd_0117"
                formControlName="cmd_0117"
                type="text"
                data-id="Text4.2.4.3"
              />
            </div>
            <div class="form-group form-inline no-margin col-md-2">
              Friday
            </div>
            <div class="form-group form-inline no-margin col-md-3">
              <input
                type="checkbox"
                id="cmd_0103"
                name="cmd_0103"
                formControlName="cmd_0103"
                value="1"
              />No Office Hours
            </div>
            <div class="form-group form-inline no-margin col-md-2">
              Morning<input
                style="width:70%;"
                class="form-control"
                id="cmd_0774"
                name="cmd_0774"
                formControlName="cmd_0774"
                type="text"
                data-id="Friday"
              />
            </div>
            <div class="form-group form-inline no-margin col-md-2">
              Afternoon:<input
                style="width:70%;"
                class="form-control"
                id="cmd_0111"
                name="cmd_0111"
                formControlName="cmd_0111"
                type="text"
                data-id="Text4.2.3.4"
              />
            </div>
            <div class="form-group form-inline no-margin col-md-2">
              Evening:<input
                style="width:70%;"
                class="form-control"
                id="cmd_0118"
                name="cmd_0118"
                formControlName="cmd_0118"
                type="text"
                data-id="Text4.2.4.4"
              />
            </div>
            <div class="form-group form-inline no-margin col-md-2">
              Saturday
            </div>
            <div class="form-group form-inline no-margin col-md-3">
              <input
                type="checkbox"
                id="cmd_0104"
                name="cmd_0104"
                formControlName="cmd_0104"
                value="1"
              />No Office Hours
            </div>
            <div class="form-group form-inline no-margin col-md-2">
              Morning<input
                style="width:70%;"
                class="form-control"
                id="cmd_0775"
                name="cmd_0775"
                formControlName="cmd_0775"
                type="text"
                data-id="Saturday"
              />
            </div>
            <div class="form-group form-inline no-margin col-md-2">
              Afternoon:<input
                style="width:70%;"
                class="form-control"
                id="cmd_0112"
                name="cmd_0112"
                formControlName="cmd_0112"
                type="text"
                data-id="Text4.2.3.5"
              />
            </div>
            <div class="form-group form-inline no-margin col-md-2">
              Evening:<input
                style="width:70%;"
                class="form-control"
                id="cmd_0119"
                name="cmd_0119"
                formControlName="cmd_0119"
                type="text"
                data-id="Text4.2.4.5"
              />
            </div>
            <div class="form-group form-inline no-margin col-md-2">
              Sunday
            </div>
            <div class="form-group form-inline no-margin col-md-3">
              <input
                type="checkbox"
                id="cmd_0105"
                name="cmd_0105"
                formControlName="cmd_0105"
                value="1"
              />No Office Hours
            </div>
            <div class="form-group form-inline no-margin col-md-2">
              Morning<input
                style="width:70%;"
                class="form-control"
                id="cmd_0776"
                name="cmd_0776"
                formControlName="cmd_0776"
                type="text"
                data-id="Sunday"
              />
            </div>
            <div class="form-group form-inline no-margin col-md-2">
              Afternoon:<input
                style="width:70%;"
                class="form-control"
                id="cmd_0113"
                name="cmd_0113"
                formControlName="cmd_0113"
                type="text"
                data-id="Text4.2.3.6"
              />
            </div>
            <div class="form-group form-inline no-margin col-md-2">
              Evening:<input
                style="width:70%;"
                class="form-control"
                id="cmd_0120"
                name="cmd_0120"
                formControlName="cmd_0120"
                type="text"
                data-id="Text4.2.4.6"
              />
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="18">
            <div class="form-group form-inline no-margin col-md-12">
              <label class="formLabel"
                >DOES THIS LOCATION PROVIDE 24 HOUR/7 DAY A WEEK PHONE
                COVERAGE?
              </label>
            </div>
            <br />
            <div class="form-group form-inline no-margin col-md-3">
              <input
                type="checkbox"
                id="cmd_0121"
                name="cmd_0121"
                formControlName="cmd_0121"
                value="1"
              />Answering Service
            </div>
            <div class="form-group form-inline no-margin col-md-6">
              <input
                type="checkbox"
                id="cmd_0122"
                name="cmd_0122"
                formControlName="cmd_0122"
                value="1"
              />Voice mail with instructions to call answering service
            </div>
            <div class="form-group form-inline no-margin col-md-3">
              <input
                type="checkbox"
                id="cmd_0123"
                name="cmd_0123"
                formControlName="cmd_0123"
                value="1"
              />Voice mail with other instructions
            </div>
            <div class="form-group form-inline no-margin col-md-3">
              <input
                type="checkbox"
                id="cmd_0124"
                name="cmd_0124"
                formControlName="cmd_0124"
                value="1"
              />None
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="18">
            <div class="form-group form-inline no-margin col-md-12">
              <label class="formLabel">THIS PRACTICE LOCATION ACCEPTS</label>
            </div>
            <br />
            <div class="form-group form-inline no-margin col-md-3">
              <input
                type="checkbox"
                id="cmd_0125"
                name="cmd_0125"
                formControlName="cmd_0125"
                value="1"
              />all new patients
            </div>
            <div class="form-group form-inline no-margin col-md-3">
              <input
                type="checkbox"
                id="cmd_0126"
                name="cmd_0126"
                formControlName="cmd_0126"
                value="1"
              />existing patients with change of payor
            </div>
            <div class="form-group form-inline no-margin col-md-3">
              <input
                type="checkbox"
                id="cmd_0127"
                name="cmd_0127"
                formControlName="cmd_0127"
                value="1"
              />new patients with referral
            </div>
            <div class="form-group form-inline no-margin col-md-3">
              <input
                type="checkbox"
                id="cmd_0128"
                name="cmd_0128"
                formControlName="cmd_0128"
                value="1"
              />
              new Medicare patients
            </div>
            <div class="form-group form-inline no-margin col-md-3">
              <input
                type="checkbox"
                id="cmd_0129"
                name="cmd_0129"
                formControlName="cmd_0129"
                value="1"
              />
              new Medicaid patients
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="18">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel"
                >IF NEW PATIENT ACCEPTANCE VARIES BY HEALTH PLAN, PLEASE
                PROVIDE EXPLANATION.
              </label>
              <input
                class="form-control"
                id="cmd_0130"
                name="cmd_0130"
                formControlName="cmd_0130"
                type="text"
                data-id="Text4.4.1"
              />
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="18">
            <div class="form-group form-inline no-margin col-md-12">
              <label class="formLabel">PRACTICE LIMITATIONS </label>
            </div>
            <br />
            <div class="form-group form-inline no-margin col-md-3">
              <input
                type="radio"
                id="cmd_0131"
                name="cmd_0131"
                formControlName="cmd_0131"
                value="0"
              />Male only
            </div>
            <div class="form-group form-inline no-margin col-md-3">
              <input
                type="radio"
                id="cmd_0131"
                name="cmd_0131"
                formControlName="cmd_0131"
                value="1"
              />Female only
            </div>
            <div class="row">
              <div class="col-md-1">
                <label class="formLabel">Age:</label>
              </div>
              <div class="col-md-8">
                <input
                  style="width:40%;"
                  class="form-control"
                  id="cmd_0777"
                  name="cmd_0777"
                  formControlName="cmd_0777"
                  type="text"
                  data-id="prac1_agespatients"
                />
              </div>
            </div>
            <div class="form-group form-inline no-margin col-md-3">
              <input
                type="radio"
                id="cmd_0131"
                name="cmd_0131"
                formControlName="cmd_0131"
                value="2"
              />Other
              <input
                style="width:70%;"
                class="form-control"
                id="cmd_0132"
                name="cmd_0132"
                formControlName="cmd_0132"
                type="text"
                data-id="Text4.4.3"
              />
            </div>
          </td>
        </tr>
        
        
      </table>
      <hr class="tablePersonal" />
      <table border="1" width="100%">
        <tr>
          <td colspan="18">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <b>Section II-Disclosure Questions</b>
              <label class="boldParagraph"
                >- Please provide an explanation for any question answered
                yes-except 16-on page 10. Licensure
              </label>
              <br />
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="2" width="5%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel">1</label>
            </div>
          </td>
          <td colspan="12" width="75%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel"
                >Has your license to practice, in your profession, ever been
                denied, suspended, revoked, restricted,voluntarily surrendered
                while under investigation, or have you ever been subject to a
                consent order,probation or any conditions or limitations by
                any state licensing board?<span
                class="mandatory">*</span></label
              >
            </div>
          </td>
          <td colspan="4" width="20%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="radio-inline radioStyle">
                <input
                  type="radio"
                  [class.is-invalid]="
                    (AbaBuildBlocksForms.controls['cmd_0232'].touched &&
                      AbaBuildBlocksForms.controls['cmd_0232'].errors) ||
                    (AbaBuildBlocksForms.controls['cmd_0232'].invalid && isSubmit)
                  "
                  id="cmd_0232"
                  (blur)="handleNAchange('cmd_NA_011','cmd_0232')"
                  name="cmd_0232"
                  formControlName="cmd_0232"
                  value="1"
                  (change)="validationsDataAdd($event)"
                />Yes</label
              >
              <label class="radio-inline radioStyle">
                <input
                  type="radio"
                  [class.is-invalid]="
                    (AbaBuildBlocksForms.controls['cmd_0232'].touched &&
                      AbaBuildBlocksForms.controls['cmd_0232'].errors) ||
                    (AbaBuildBlocksForms.controls['cmd_0232'].invalid && isSubmit)
                  "
                  id="cmd_0232"
                  (blur)="handleNAchange('cmd_NA_011','cmd_0232')"
                  name="cmd_0232"
                  formControlName="cmd_0232"
                  value="0"
                  (change)="validationsDataAdd($event)"
                />No</label
              >
              <div
                class="formLabel col-md-2"
                *ngIf="
                  (AbaBuildBlocksForms.controls['cmd_0232'].touched &&
                    AbaBuildBlocksForms.controls['cmd_0232'].errors) ||
                  (AbaBuildBlocksForms.controls['cmd_0232'].errors && isSubmit)
                "
              >
                <small
                  class="text-danger"
                  *ngIf="AbaBuildBlocksForms.controls['cmd_0232'].errors?.required"
                >
                  required
                </small>
              </div>
              <span
                *ngIf="
                  (AbaBuildBlocksForms.controls['cmd_0232'].touched &&
                    AbaBuildBlocksForms.controls['cmd_0232'].errors) ||
                  (AbaBuildBlocksForms.controls['cmd_0232'].errors && isSubmit)
                "
              >
                <i class="fas fa-times ml-2 text-danger"></i>
              </span>
            </div>
            <div class="form-group no-margin col-md-8">
              <b class="texasFloat">N/A</b>
                <input
                  class="texasFloat"
                  type="checkbox"
                  name="cmd_NA_011"
                  id="cmd_NA_011"
                  (ngModelChange)="handleNAchange('cmd_NA_011')"
                  formControlName="cmd_NA_011"
                  value="1"
                />
            </div>    
          </td>
        </tr>
        <tr>
          <td colspan="2" width="5%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel">2</label>
            </div>
          </td>
          <td colspan="12" width="75%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel"
                >Have you ever received a reprimand or been fined by any state
                licensing board?<span
                class="mandatory">*</span>
              </label>
            </div>
          </td>
          <td colspan="4" width="20%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="radio-inline radioStyle">
                <input
                  type="radio"
                  id="cmd_0233"
                  (blur)="handleNAchange('cmd_NA_012','cmd_0232')"
                  [class.is-invalid]="
                    (AbaBuildBlocksForms.controls['cmd_0233'].touched &&
                      AbaBuildBlocksForms.controls['cmd_0233'].errors) ||
                    (AbaBuildBlocksForms.controls['cmd_0233'].errors && isSubmit)
                  "
                  name="cmd_0233"
                  formControlName="cmd_0233"
                  value="1"
                  (change)="validationsDataAdd($event)"
                />Yes</label
              >
              <label class="radio-inline radioStyle">
                <input
                  type="radio"
                  id="cmd_0233"
                  (blur)="handleNAchange('cmd_NA_012','cmd_0232')"
                  [class.is-invalid]="
                    (AbaBuildBlocksForms.controls['cmd_0233'].touched &&
                      AbaBuildBlocksForms.controls['cmd_0233'].errors) ||
                    (AbaBuildBlocksForms.controls['cmd_0233'].errors && isSubmit)
                  "
                  name="cmd_0233"
                  formControlName="cmd_0233"
                  value="0"
                  (change)="validationsDataAdd($event)"
                />No</label
              >
              <div
                class="formLabel col-md-2"
                *ngIf="
                  AbaBuildBlocksForms.controls['cmd_0233'].touched &&
                  AbaBuildBlocksForms.controls['cmd_0233'].errors
                "
              >
                <small
                  class="text-danger"
                  *ngIf="AbaBuildBlocksForms.controls['cmd_0233'].errors?.required"
                >
                  required
                </small>
              </div>
              <span
                *ngIf="
                  (AbaBuildBlocksForms.controls['cmd_0233'].touched &&
                    AbaBuildBlocksForms.controls['cmd_0233'].errors) ||
                  (AbaBuildBlocksForms.controls['cmd_0233'].errors && isSubmit)
                "
              >
                <i class="fas fa-times ml-2 text-danger"></i>
              </span>
            </div>
            <div class="form-group no-margin col-md-8">
              <b class="texasFloat">N/A</b>
                <input
                  class="texasFloat"
                  type="checkbox"
                  name="cmd_NA_012"
                  id="cmd_NA_012"
                  (ngModelChange)="handleNAchange('cmd_NA_012')"
                  formControlName="cmd_NA_012"
                  value="1"
                />
            </div>    
          </td>
        </tr>
        <tr>
          <td colspan="18" width="5%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel"
                >Hospital Privileges and Other Affiliations
              </label>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="2" width="5%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel">3</label>
            </div>
          </td>
          <td colspan="12" width="75%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel"
                >Have your clinical privileges or Medical Staff membership at
                any hospital or healthcare institution ever been denied,
                suspended, revoked, restricted, denied renewal or subject to
                probationary or to other disciplinary conditions (for reasons
                other than non-completion of medical records when quality of
                care was not adversely affected) or have proceedings toward
                any of those ends been instituted or recommended by any
                hospital or healthcare institution, medical staff or
                committee, or governing board?<span
                class="mandatory">*</span>
              </label>
            </div>
          </td>
          <td colspan="4" width="20%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="radio-inline radioStyle">
                <input
                  type="radio"
                  id="cmd_0234"
                  (blur)="handleNAchange('cmd_NA_013','cmd_0234')"
                  [class.is-invalid]="
                    (AbaBuildBlocksForms.controls['cmd_0234'].touched &&
                      AbaBuildBlocksForms.controls['cmd_0234'].errors) ||
                    (AbaBuildBlocksForms.controls['cmd_0234'].errors && isSubmit)
                  "
                  name="cmd_0234"
                  formControlName="cmd_0234"
                  value="1"
                  (change)="validationsDataAdd($event)"
                />Yes</label
              >
              <label class="radio-inline radioStyle">
                <input
                  type="radio"
                  id="cmd_0234"
                  (blur)="handleNAchange('cmd_NA_013','cmd_0234')"
                  [class.is-invalid]="
                    (AbaBuildBlocksForms.controls['cmd_0234'].touched &&
                      AbaBuildBlocksForms.controls['cmd_0234'].errors) ||
                    (AbaBuildBlocksForms.controls['cmd_0234'].errors && isSubmit)
                  "
                  name="cmd_0234"
                  formControlName="cmd_0234"
                  value="0"
                  (change)="validationsDataAdd($event)"
                />No</label
              >
              <div
                class="formLabel col-md-2"
                *ngIf="
                  AbaBuildBlocksForms.controls['cmd_0234'].touched &&
                  AbaBuildBlocksForms.controls['cmd_0234'].errors
                "
              >
                <small
                  class="text-danger"
                  *ngIf="AbaBuildBlocksForms.controls['cmd_0234'].errors?.required"
                >
                  required
                </small>
              </div>
              <span
                *ngIf="
                  (AbaBuildBlocksForms.controls['cmd_0234'].touched &&
                    AbaBuildBlocksForms.controls['cmd_0234'].errors) ||
                  (AbaBuildBlocksForms.controls['cmd_0234'].errors && isSubmit)
                "
              >
                <i class="fas fa-times ml-2 text-danger"></i>
              </span>
            </div>
            <div class="form-group no-margin col-md-8">
              <b class="texasFloat">N/A</b>
                <input
                  class="texasFloat"
                  type="checkbox"
                  name="cmd_NA_013"
                  id="cmd_NA_013"
                  (ngModelChange)="handleNAchange('cmd_NA_013')"
                  formControlName="cmd_NA_013"
                  value="1"
                />
            </div> 
          </td>
        </tr>
        <tr>
          <td colspan="2" width="5%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel">4</label>
            </div>
          </td>
          <td colspan="12" width="75%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel"
                >Have you voluntarily surrendered, limited your privileges or
                not reapplied for privileges while under investigation?<span
                class="mandatory">*</span></label
              >
            </div>
          </td>
          <td colspan="4" width="20%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="radio-inline radioStyle">
                <input
                  type="radio"
                  id="cmd_0235"
                  [class.is-invalid]="
                    (AbaBuildBlocksForms.controls['cmd_0235'].touched &&
                      AbaBuildBlocksForms.controls['cmd_0235'].errors) ||
                    (AbaBuildBlocksForms.controls['cmd_0235'].errors && isSubmit)
                  "
                  (blur)="handleNAchange('cmd_NA_014','cmd_0235')"
                  name="cmd_0235"
                  formControlName="cmd_0235"
                  value="1"
                  (change)="validationsDataAdd($event)"
                />Yes</label
              >
              <label class="radio-inline radioStyle">
                <input
                  type="radio"
                  id="cmd_0235"
                  (blur)="handleNAchange('cmd_NA_014','cmd_0235')"
                  [class.is-invalid]="
                    (AbaBuildBlocksForms.controls['cmd_0235'].touched &&
                      AbaBuildBlocksForms.controls['cmd_0235'].errors) ||
                    (AbaBuildBlocksForms.controls['cmd_0235'].errors && isSubmit)
                  "
                  name="cmd_0235"
                  formControlName="cmd_0235"
                  value="0"
                />No</label
              >
              <div
                class="formLabel col-md-2"
                *ngIf="
                  AbaBuildBlocksForms.controls['cmd_0235'].touched &&
                  AbaBuildBlocksForms.controls['cmd_0235'].errors
                "
              >
                <small
                  class="text-danger"
                  *ngIf="AbaBuildBlocksForms.controls['cmd_0235'].errors?.required"
                >
                  required
                </small>
              </div>
              <span
                *ngIf="
                  (AbaBuildBlocksForms.controls['cmd_0235'].touched &&
                    AbaBuildBlocksForms.controls['cmd_0235'].errors) ||
                  (AbaBuildBlocksForms.controls['cmd_0235'].errors && isSubmit)
                "
              >
                <i class="fas fa-times ml-2 text-danger"></i>
              </span>
            </div>
            <div class="form-group no-margin col-md-8">
              <b class="texasFloat">N/A</b>
                <input
                  class="texasFloat"
                  type="checkbox"
                  name="cmd_NA_014"
                  id="cmd_NA_014"
                  (ngModelChange)="handleNAchange('cmd_NA_014')"
                  formControlName="cmd_NA_014"
                  value="1"
                />
            </div> 
          </td>
        </tr>
        <tr>
          <td colspan="2" width="5%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel">5</label>
            </div>
          </td>
          <td colspan="12" width="75%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel"
                >Have you ever been terminated for cause or not renewed for
                cause from participation, or been subject to any disciplinary
                action, by any managed care organizations (including HMOs,
                PPOs, or provider organizations such as IPAs, PHOs)?<span
                class="mandatory">*</span>
              </label>
            </div>
          </td>
          <td colspan="4" width="20%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="radio-inline radioStyle">
                <input
                  type="radio"
                  id="cmd_0236"
                  [class.is-invalid]="
                    (AbaBuildBlocksForms.controls['cmd_0236'].touched &&
                      AbaBuildBlocksForms.controls['cmd_0236'].errors) ||
                    (AbaBuildBlocksForms.controls['cmd_0236'].errors && isSubmit)
                  "
                  (blur)="handleNAchange('cmd_NA_015','cmd_0236')"
                  name="cmd_0236"
                  formControlName="cmd_0236"
                  value="1"
                  (change)="validationsDataAdd($event)"
                />Yes</label
              >
              <label class="radio-inline radioStyle">
                <input
                  type="radio"
                  id="cmd_0236"
                  [class.is-invalid]="
                    (AbaBuildBlocksForms.controls['cmd_0236'].touched &&
                      AbaBuildBlocksForms.controls['cmd_0236'].errors) ||
                    (AbaBuildBlocksForms.controls['cmd_0236'].errors && isSubmit)
                  "
                  (blur)="handleNAchange('cmd_NA_015','cmd_0236')"
                  name="cmd_0236"
                  formControlName="cmd_0236"
                  value="0"
                  (change)="validationsDataAdd($event)"
                />No</label
              >
              <div
                class="formLabel col-md-2"
                *ngIf="
                  AbaBuildBlocksForms.controls['cmd_0236'].touched &&
                  AbaBuildBlocksForms.controls['cmd_0236'].errors
                "
              >
                <small
                  class="text-danger"
                  *ngIf="AbaBuildBlocksForms.controls['cmd_0236'].errors?.required"
                >
                  required
                </small>
              </div>
              <span
                *ngIf="
                  (AbaBuildBlocksForms.controls['cmd_0236'].touched &&
                    AbaBuildBlocksForms.controls['cmd_0236'].errors) ||
                  (AbaBuildBlocksForms.controls['cmd_0236'].errors && isSubmit)
                "
              >
                <i class="fas fa-times ml-2 text-danger"></i>
              </span>
            </div>
            <div class="form-group no-margin col-md-8">
              <b class="texasFloat">N/A</b>
                <input
                  class="texasFloat"
                  type="checkbox"
                  name="cmd_NA_015"
                  id="cmd_NA_015"
                  (ngModelChange)="handleNAchange('cmd_NA_015')"
                  formControlName="cmd_NA_015"
                  value="1"
                />
            </div> 
          </td>
        </tr>
        <tr>
          <td colspan="18" width="5%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel"
                >Education, Training and Board Certification
              </label>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="2" width="5%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel">6</label>
            </div>
          </td>
          <td colspan="12" width="75%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel"
                >Were you ever placed on probation, disciplined, formally
                reprimanded, suspended or asked to resign during an
                internship, residency, fellowship, preceptorship or other
                clinical education program? If you are currently in a
                training program, have you been placed on probation,
                disciplined, formally reprimanded, suspended or asked to
                resign?<span
                class="mandatory">*</span>
              </label>
            </div>
          </td>
          <td colspan="4" width="20%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="radio-inline radioStyle">
                <input
                  type="radio"
                  id="cmd_0237"
                  [class.is-invalid]="
                    (AbaBuildBlocksForms.controls['cmd_0237'].touched &&
                      AbaBuildBlocksForms.controls['cmd_0237'].errors) ||
                    (AbaBuildBlocksForms.controls['cmd_0237'].errors && isSubmit)
                  "
                  (blur)="handleNAchange('cmd_NA_016','cmd_0237')"
                  name="cmd_0237"
                  formControlName="cmd_0237"
                  value="1"
                />Yes</label
              >
              <label class="radio-inline radioStyle">
                <input
                  type="radio"
                  id="cmd_0237"
                  [class.is-invalid]="
                    (AbaBuildBlocksForms.controls['cmd_0237'].touched &&
                      AbaBuildBlocksForms.controls['cmd_0237'].errors) ||
                    (AbaBuildBlocksForms.controls['cmd_0237'].errors && isSubmit)
                  "
                  (blur)="handleNAchange('cmd_NA_016','cmd_0237')"
                  name="cmd_0237"
                  formControlName="cmd_0237"
                  value="0"
                  (change)="validationsDataAdd($event)"
                />No</label
              >
              <div
                class="formLabel col-md-2"
                *ngIf="
                  AbaBuildBlocksForms.controls['cmd_0237'].touched &&
                  AbaBuildBlocksForms.controls['cmd_0237'].errors
                "
              >
                <small
                  class="text-danger"
                  *ngIf="AbaBuildBlocksForms.controls['cmd_0237'].errors?.required"
                >
                  required
                </small>
              </div>
              <span
                *ngIf="
                  (AbaBuildBlocksForms.controls['cmd_0237'].touched &&
                    AbaBuildBlocksForms.controls['cmd_0237'].errors) ||
                  (AbaBuildBlocksForms.controls['cmd_0237'].errors && isSubmit)
                "
              >
                <i class="fas fa-times ml-2 text-danger"></i>
              </span>
            </div>
            <div class="form-group no-margin col-md-8">
              <b class="texasFloat">N/A</b>
                <input
                  class="texasFloat"
                  type="checkbox"
                  name="cmd_NA_016"
                  id="cmd_NA_016"
                  (ngModelChange)="handleNAchange('cmd_NA_016')"
                  formControlName="cmd_NA_016"
                  value="1"
                />
            </div> 
          </td>
        </tr>
        <tr>
          <td colspan="2" width="5%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel">7</label>
            </div>
          </td>
          <td colspan="12" width="75%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel">
                Have you ever, while under investigation, voluntarily
                withdrawn or prematurely terminated your status as a student
                or employee in any internship, residency, fellowship,
                preceptorship, or other clinical education program?<span
                class="mandatory">*</span>
              </label>
            </div>
          </td>
          <td colspan="4" width="20%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="radio-inline radioStyle">
                <input
                  type="radio"
                  id="cmd_0238"
                  (blur)="handleNAchange('cmd_NA_017','cmd_0238')"
                  [class.is-invalid]="
                    (AbaBuildBlocksForms.controls['cmd_0238'].touched &&
                      AbaBuildBlocksForms.controls['cmd_0238'].errors) ||
                    (AbaBuildBlocksForms.controls['cmd_0238'].errors && isSubmit)
                  "
                  name="cmd_0238"
                  formControlName="cmd_0238"
                  value="1"
                  (change)="validationsDataAdd($event)"
                />Yes</label
              >
              <label class="radio-inline radioStyle">
                <input
                  type="radio"
                  id="cmd_0238"
                  (blur)="handleNAchange('cmd_NA_017','cmd_0238')"
                  [class.is-invalid]="
                    (AbaBuildBlocksForms.controls['cmd_0238'].touched &&
                      AbaBuildBlocksForms.controls['cmd_0238'].errors) ||
                    (AbaBuildBlocksForms.controls['cmd_0238'].errors && isSubmit)
                  "
                  name="cmd_0238"
                  formControlName="cmd_0238"
                  value="0"
                  (change)="validationsDataAdd($event)"
                />No</label
              >
              <div
                class="formLabel col-md-2"
                *ngIf="
                  AbaBuildBlocksForms.controls['cmd_0238'].touched &&
                  AbaBuildBlocksForms.controls['cmd_0238'].errors
                "
              >
                <small
                  class="text-danger"
                  *ngIf="AbaBuildBlocksForms.controls['cmd_0238'].errors?.required"
                >
                  required
                </small>
              </div>
              <span
                *ngIf="
                  (AbaBuildBlocksForms.controls['cmd_0238'].touched &&
                    AbaBuildBlocksForms.controls['cmd_0238'].errors) ||
                  (AbaBuildBlocksForms.controls['cmd_0238'].errors && isSubmit)
                "
              >
                <i class="fas fa-times ml-2 text-danger"></i>
              </span>
            </div>
            <div class="form-group no-margin col-md-8">
              <b class="texasFloat">N/A</b>
                <input
                  class="texasFloat"
                  type="checkbox"
                  name="cmd_NA_017"
                  id="cmd_NA_017"
                  (ngModelChange)="handleNAchange('cmd_NA_017')"
                  formControlName="cmd_NA_017"
                  value="1"
                />
            </div> 
          </td>
        </tr>
        <tr>
          <td colspan="2" width="5%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel">8</label>
            </div>
          </td>
          <td colspan="12" width="75%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel"
                >Have any of your board certifications or eligibility ever
                been revoked?<span
                class="mandatory">*</span>
              </label>
            </div>
          </td>
          <td colspan="4" width="20%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="radio-inline radioStyle">
                <input
                  type="radio"
                  id="cmd_0239"
                  (blur)="handleNAchange('cmd_NA_018','cmd_0239')"
                  [class.is-invalid]="
                    (AbaBuildBlocksForms.controls['cmd_0239'].touched &&
                      AbaBuildBlocksForms.controls['cmd_0239'].errors) ||
                    (AbaBuildBlocksForms.controls['cmd_0239'].errors && isSubmit)
                  "
                  name="cmd_0239"
                  formControlName="cmd_0239"
                  value="1"
                  (change)="validationsDataAdd($event)"
                />Yes</label
              >
              <label class="radio-inline radioStyle">
                <input
                  type="radio"
                  id="cmd_0239"
                  (blur)="handleNAchange('cmd_NA_018','cmd_0239')"
                  [class.is-invalid]="
                    (AbaBuildBlocksForms.controls['cmd_0239'].touched &&
                      AbaBuildBlocksForms.controls['cmd_0239'].errors) ||
                    (AbaBuildBlocksForms.controls['cmd_0239'].errors && isSubmit)
                  "
                  name="cmd_0239"
                  formControlName="cmd_0239"
                  value="0"
                  (change)="validationsDataAdd($event)"
                />No</label
              >
              <div
                class="formLabel col-md-2"
                *ngIf="
                  AbaBuildBlocksForms.controls['cmd_0239'].touched &&
                  AbaBuildBlocksForms.controls['cmd_0239'].errors
                "
              >
                <small
                  class="text-danger"
                  *ngIf="AbaBuildBlocksForms.controls['cmd_0239'].errors?.required"
                >
                  required
                </small>
              </div>
              <span
                *ngIf="
                  (AbaBuildBlocksForms.controls['cmd_0239'].touched &&
                    AbaBuildBlocksForms.controls['cmd_0239'].errors) ||
                  (AbaBuildBlocksForms.controls['cmd_0239'].errors && isSubmit)
                "
              >
                <i class="fas fa-times ml-2 text-danger"></i>
              </span>
            </div>
            <div class="form-group no-margin col-md-8">
              <b class="texasFloat">N/A</b>
                <input
                  class="texasFloat"
                  type="checkbox"
                  name="cmd_NA_018"
                  id="cmd_NA_018"
                  (ngModelChange)="handleNAchange('cmd_NA_018')"
                  formControlName="cmd_NA_018"
                  value="1"
                />
            </div> 
          </td>
        </tr>
        <tr>
          <td colspan="2" width="5%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel">9</label>
            </div>
          </td>
          <td colspan="12" width="75%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel"
                >Have you ever chosen not to re-certify or voluntarily
                surrendered your board certification(s) while under
                investigation?<span
                class="mandatory">*</span>
              </label>
            </div>
          </td>
          <td colspan="4" width="20%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="radio-inline radioStyle">
                <input
                  type="radio"
                  id="cmd_0240"
                  (blur)="handleNAchange('cmd_NA_019','cmd_0240')"
                  [class.is-invalid]="
                    (AbaBuildBlocksForms.controls['cmd_0240'].touched &&
                      AbaBuildBlocksForms.controls['cmd_0240'].errors) ||
                    (AbaBuildBlocksForms.controls['cmd_0240'].errors && isSubmit)
                  "
                  name="cmd_0240"
                  formControlName="cmd_0240"
                  value="1"
                  (change)="validationsDataAdd($event)"
                />Yes</label
              >
              <label class="radio-inline radioStyle">
                <input
                  type="radio"
                  id="cmd_0240"
                  (blur)="handleNAchange('cmd_NA_019','cmd_0240')"
                  [class.is-invalid]="
                    (AbaBuildBlocksForms.controls['cmd_0240'].touched &&
                      AbaBuildBlocksForms.controls['cmd_0240'].errors) ||
                    (AbaBuildBlocksForms.controls['cmd_0240'].errors && isSubmit)
                  "
                  name="cmd_0240"
                  formControlName="cmd_0240"
                  value="0"
                  (change)="validationsDataAdd($event)"
                />No</label
              >
              <div
                class="formLabel col-md-2"
                *ngIf="
                  AbaBuildBlocksForms.controls['cmd_0240'].touched &&
                  AbaBuildBlocksForms.controls['cmd_0240'].errors
                "
              >
                <small
                  class="text-danger"
                  *ngIf="AbaBuildBlocksForms.controls['cmd_0240'].errors?.required"
                >
                  required
                </small>
              </div>
              <span
                *ngIf="
                  (AbaBuildBlocksForms.controls['cmd_0240'].touched &&
                    AbaBuildBlocksForms.controls['cmd_0240'].errors) ||
                  (AbaBuildBlocksForms.controls['cmd_0240'].errors && isSubmit)
                "
              >
                <i class="fas fa-times ml-2 text-danger"></i>
              </span>
            </div>
            <div class="form-group no-margin col-md-8">
              <b class="texasFloat">N/A</b>
                <input
                  class="texasFloat"
                  type="checkbox"
                  name="cmd_NA_019"
                  id="cmd_NA_019"
                  (ngModelChange)="handleNAchange('cmd_NA_019')"
                  formControlName="cmd_NA_019"
                  value="1"
                />
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="18" width="5%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel">DEA or CDS </label>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="2" width="5%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel">10</label>
            </div>
          </td>
          <td colspan="12" width="75%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel"
                >Have your Federal DEA and/or Controlled Substances
                Certificate(s) or authorization(s) ever been denied,
                suspended, revoked, restricted, denied renewal, or voluntarily
                relinquished?<span
                class="mandatory">*</span></label
              >
            </div>
          </td>
          <td colspan="4" width="20%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="radio-inline radioStyle">
                <input
                  type="radio"
                  id="cmd_0241"
                  (blur)="handleNAchange('cmd_NA_020','cmd_0241')"
                  [class.is-invalid]="
                    (AbaBuildBlocksForms.controls['cmd_0241'].touched &&
                      AbaBuildBlocksForms.controls['cmd_0241'].errors) ||
                    (AbaBuildBlocksForms.controls['cmd_0241'].errors && isSubmit)
                  "
                  name="cmd_0241"
                  formControlName="cmd_0241"
                  value="1"
                  (change)="validationsDataAdd($event)"
                />Yes</label
              >
              <label class="radio-inline radioStyle">
                <input
                  type="radio"
                  id="cmd_0241"
                  (blur)="handleNAchange('cmd_NA_020','cmd_0241')"
                  [class.is-invalid]="
                    (AbaBuildBlocksForms.controls['cmd_0241'].touched &&
                      AbaBuildBlocksForms.controls['cmd_0241'].errors) ||
                    (AbaBuildBlocksForms.controls['cmd_0241'].errors && isSubmit)
                  "
                  name="cmd_0241"
                  formControlName="cmd_0241"
                  value="0"
                  (change)="validationsDataAdd($event)"
                />No</label
              >
              <div
                class="formLabel col-md-2"
                *ngIf="
                  AbaBuildBlocksForms.controls['cmd_0241'].touched &&
                  AbaBuildBlocksForms.controls['cmd_0241'].errors
                "
              >
                <small
                  class="text-danger"
                  *ngIf="AbaBuildBlocksForms.controls['cmd_0241'].errors?.required"
                >
                  required
                </small>
              </div>
              <span
                *ngIf="
                  (AbaBuildBlocksForms.controls['cmd_0241'].touched &&
                    AbaBuildBlocksForms.controls['cmd_0241'].errors) ||
                  (AbaBuildBlocksForms.controls['cmd_0241'].errors && isSubmit)
                "
              >
                <i class="fas fa-times ml-2 text-danger"></i>
              </span>
            </div>
            <div class="form-group no-margin col-md-8">
              <b class="texasFloat">N/A</b>
                <input
                  class="texasFloat"
                  type="checkbox"
                  name="cmd_NA_020"
                  id="cmd_NA_020"
                  (ngModelChange)="handleNAchange('cmd_NA_020')"
                  formControlName="cmd_NA_020"
                  value="1"
                />
            </div> 
          </td>
        </tr>
        <tr>
          <td colspan="18" width="5%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel"
                >Medicare, Medicaid or other Governmental Program
                Participation
              </label>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="2" width="5%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel">11</label>
            </div>
          </td>
          <td colspan="12" width="75%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel"
                >Have you ever been disciplined, excluded from, debarred,
                suspended, reprimanded, sanctioned, censured, disqualified or
                otherwise restricted in regard to participation in the
                Medicare or Medicaid program, or in regard to other federal or
                state governmental health care plans or programs?<span
                class="mandatory">*</span>
              </label>
            </div>
          </td>
          <td colspan="4" width="20%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="radio-inline radioStyle">
                <input
                  type="radio"
                  id="cmd_0242"
                  (blur)="handleNAchange('cmd_NA_021','cmd_0242')"
                  [class.is-invalid]="
                    (AbaBuildBlocksForms.controls['cmd_0242'].touched &&
                      AbaBuildBlocksForms.controls['cmd_0242'].errors) ||
                    (AbaBuildBlocksForms.controls['cmd_0242'].errors && isSubmit)
                  "
                  name="cmd_0242"
                  formControlName="cmd_0242"
                  value="1"
                  (change)="validationsDataAdd($event)"
                />Yes</label
              >
              <label class="radio-inline radioStyle">
                <input
                  type="radio"
                  id="cmd_0242"
                  (blur)="handleNAchange('cmd_NA_021','cmd_0242')"
                  [class.is-invalid]="
                    (AbaBuildBlocksForms.controls['cmd_0242'].touched &&
                      AbaBuildBlocksForms.controls['cmd_0242'].errors) ||
                    (AbaBuildBlocksForms.controls['cmd_0242'].errors && isSubmit)
                  "
                  name="cmd_0242"
                  formControlName="cmd_0242"
                  value="0"
                  (change)="validationsDataAdd($event)"
                />No</label
              >
              <div
                class="formLabel col-md-2"
                *ngIf="
                  AbaBuildBlocksForms.controls['cmd_0242'].touched &&
                  AbaBuildBlocksForms.controls['cmd_0242'].errors
                "
              >
                <small
                  class="text-danger"
                  *ngIf="AbaBuildBlocksForms.controls['cmd_0242'].errors?.required"
                >
                  required
                </small>
              </div>
              <span
                *ngIf="
                  (AbaBuildBlocksForms.controls['cmd_0242'].touched &&
                    AbaBuildBlocksForms.controls['cmd_0242'].errors) ||
                  (AbaBuildBlocksForms.controls['cmd_0242'].errors && isSubmit)
                "
              >
                <i class="fas fa-times ml-2 text-danger"></i>
              </span>
            </div>
            <div class="form-group no-margin col-md-8">
              <b class="texasFloat">N/A</b>
                <input
                  class="texasFloat"
                  type="checkbox"
                  name="cmd_NA_021"
                  id="cmd_NA_021"
                  (ngModelChange)="handleNAchange('cmd_NA_021')"
                  formControlName="cmd_NA_021"
                  value="1"
                />
            </div> 
          </td>
        </tr>
        <tr>
          <td colspan="18" width="5%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel"
                >Other Sanctions or Investigations
              </label>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="2" width="5%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel">12</label>
            </div>
          </td>
          <td colspan="12" width="75%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel"
                >Are you currently or have you ever been the subject of an
                investigation by any hospital, licensing authority, DEA or CDS
                authorizing entities, education or training program, Medicare
                or Medicaid program, or any other private, federal or state
                health program?<span
                class="mandatory">*</span>
              </label>
            </div>
          </td>
          <td colspan="4" width="20%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="radio-inline radioStyle">
                <input
                  type="radio"
                  id="cmd_0243"
                  (blur)="handleNAchange('cmd_NA_022','cmd_0243')"
                  [class.is-invalid]="
                    (AbaBuildBlocksForms.controls['cmd_0243'].touched &&
                      AbaBuildBlocksForms.controls['cmd_0243'].errors) ||
                    (AbaBuildBlocksForms.controls['cmd_0243'].errors && isSubmit)
                  "
                  name="cmd_0243"
                  formControlName="cmd_0243"
                  value="1"
                  (change)="validationsDataAdd($event)"
                />Yes</label
              >
              <label class="radio-inline radioStyle">
                <input
                  type="radio"
                  id="cmd_0243"
                  (blur)="handleNAchange('cmd_NA_022','cmd_0243')"
                  [class.is-invalid]="
                    (AbaBuildBlocksForms.controls['cmd_0243'].touched &&
                      AbaBuildBlocksForms.controls['cmd_0243'].errors) ||
                    (AbaBuildBlocksForms.controls['cmd_0243'].errors && isSubmit)
                  "
                  name="cmd_0243"
                  formControlName="cmd_0243"
                  value="0"
                  (change)="validationsDataAdd($event)"
                />No</label
              >
              <div
                class="formLabel col-md-2"
                *ngIf="
                  AbaBuildBlocksForms.controls['cmd_0243'].touched &&
                  AbaBuildBlocksForms.controls['cmd_0243'].errors
                "
              >
                <small
                  class="text-danger"
                  *ngIf="AbaBuildBlocksForms.controls['cmd_0243'].errors?.required"
                >
                  required
                </small>
              </div>
              <span
                *ngIf="
                  (AbaBuildBlocksForms.controls['cmd_0243'].touched &&
                    AbaBuildBlocksForms.controls['cmd_0243'].errors) ||
                  (AbaBuildBlocksForms.controls['cmd_0243'].errors && isSubmit)
                "
              >
                <i class="fas fa-times ml-2 text-danger"></i>
              </span>
            </div>
            <div class="form-group no-margin col-md-8">
              <b class="texasFloat">N/A</b>
                <input
                  class="texasFloat"
                  type="checkbox"
                  name="cmd_NA_022"
                  id="cmd_NA_022"
                  (ngModelChange)="handleNAchange('cmd_NA_022')"
                  formControlName="cmd_NA_022"
                  value="1"
                />
            </div> 
          </td>
        </tr>
        <tr>
          <td colspan="18" width="5%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel"
                >Other Sanctions or Investigations
              </label>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="2" width="5%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel">13</label>
            </div>
          </td>
          <td colspan="12" width="75%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel"
                >To your knowledge, has information pertaining to you ever
                been reported to the National Practitioner Data Bank or
                Healthcare Integrity and Protection Data Bank?<span
                class="mandatory">*</span>
              </label>
            </div>
          </td>
          <td colspan="4" width="20%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="radio-inline radioStyle">
                <input
                  type="radio"
                  id="cmd_0244"
                  (blur)="handleNAchange('cmd_NA_023','cmd_0244')"
                  [class.is-invalid]="
                    (AbaBuildBlocksForms.controls['cmd_0244'].touched &&
                      AbaBuildBlocksForms.controls['cmd_0244'].errors) ||
                    (AbaBuildBlocksForms.controls['cmd_0244'].errors && isSubmit)
                  "
                  name="cmd_0244"
                  formControlName="cmd_0244"
                  value="1"
                  (change)="validationsDataAdd($event)"
                />Yes</label
              >
              <label class="radio-inline radioStyle">
                <input
                  type="radio"
                  id="cmd_0244"
                  (blur)="handleNAchange('cmd_NA_023','cmd_0244')"
                  [class.is-invalid]="
                    (AbaBuildBlocksForms.controls['cmd_0244'].touched &&
                      AbaBuildBlocksForms.controls['cmd_0244'].errors) ||
                    (AbaBuildBlocksForms.controls['cmd_0244'].errors && isSubmit)
                  "
                  name="cmd_0244"
                  formControlName="cmd_0244"
                  value="0"
                  (change)="validationsDataAdd($event)"
                />No</label
              >
              <div
                class="formLabel col-md-2"
                *ngIf="
                  AbaBuildBlocksForms.controls['cmd_0244'].touched &&
                  AbaBuildBlocksForms.controls['cmd_0244'].errors
                "
              >
                <small
                  class="text-danger"
                  *ngIf="AbaBuildBlocksForms.controls['cmd_0244'].errors?.required"
                >
                  required
                </small>
              </div>
              <span
                *ngIf="
                  (AbaBuildBlocksForms.controls['cmd_0244'].touched &&
                    AbaBuildBlocksForms.controls['cmd_0244'].errors) ||
                  (AbaBuildBlocksForms.controls['cmd_0244'].errors && isSubmit)
                "
              >
                <i class="fas fa-times ml-2 text-danger"></i>
              </span>
            </div>
            <div class="form-group no-margin col-md-8">
              <b class="texasFloat">N/A</b>
                <input
                  class="texasFloat"
                  type="checkbox"
                  name="cmd_NA_023"
                  id="cmd_NA_023"
                  (ngModelChange)="handleNAchange('cmd_NA_023')"
                  formControlName="cmd_NA_023"
                  value="1"
                />
            </div> 
          </td>
        </tr>
        <tr>
          <td colspan="2" width="5%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel">14</label>
            </div>
          </td>
          <td colspan="12" width="75%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel"
                >Have you ever received sanctions from or been the subject of
                investigation by any regulatory agencies (e.g., CLIA, OSHA,
                etc.)?<span
                class="mandatory">*</span>
              </label>
            </div>
          </td>
          <td colspan="4" width="20%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="radio-inline radioStyle">
                <input
                  type="radio"
                  id="cmd_0245"
                  (blur)="handleNAchange('cmd_NA_024','cmd_0245')"
                  [class.is-invalid]="
                    (AbaBuildBlocksForms.controls['cmd_0245'].touched &&
                      AbaBuildBlocksForms.controls['cmd_0245'].errors) ||
                    (AbaBuildBlocksForms.controls['cmd_0245'].errors && isSubmit)
                  "
                  name="cmd_0245"
                  formControlName="cmd_0245"
                  value="1"
                  (change)="validationsDataAdd($event)"
                />Yes</label
              >
              <label class="radio-inline radioStyle">
                <input
                  type="radio"
                  id="cmd_0245"
                  (blur)="handleNAchange('cmd_NA_024','cmd_0245')"
                  [class.is-invalid]="
                    (AbaBuildBlocksForms.controls['cmd_0245'].touched &&
                      AbaBuildBlocksForms.controls['cmd_0245'].errors) ||
                    (AbaBuildBlocksForms.controls['cmd_0245'].errors && isSubmit)
                  "
                  name="cmd_0245"
                  formControlName="cmd_0245"
                  value="0"
                  (change)="validationsDataAdd($event)"
                />No</label
              >
              <div
                class="formLabel col-md-2"
                *ngIf="
                  AbaBuildBlocksForms.controls['cmd_0245'].touched &&
                  AbaBuildBlocksForms.controls['cmd_0245'].errors
                "
              >
                <small
                  class="text-danger"
                  *ngIf="AbaBuildBlocksForms.controls['cmd_0245'].errors?.required"
                >
                  required
                </small>
              </div>
              <span
                *ngIf="
                  (AbaBuildBlocksForms.controls['cmd_0245'].touched &&
                    AbaBuildBlocksForms.controls['cmd_0245'].errors) ||
                  (AbaBuildBlocksForms.controls['cmd_0245'].errors && isSubmit)
                "
              >
                <i class="fas fa-times ml-2 text-danger"></i>
              </span>
            </div>
            <div class="form-group no-margin col-md-8">
              <b class="texasFloat">N/A</b>
                <input
                  class="texasFloat"
                  type="checkbox"
                  name="cmd_NA_024"
                  id="cmd_NA_024"
                  (ngModelChange)="handleNAchange('cmd_NA_024')"
                  formControlName="cmd_NA_024"
                  value="1"
                />
            </div> 
          </td>
        </tr>
        <tr>
          <td colspan="2" width="5%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel">15</label>
            </div>
          </td>
          <td colspan="12" width="75%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel"
                >Have you ever been investigated, sanctioned, reprimanded or
                cautioned by a military hospital,facility, or agency, or
                voluntarily terminated or resigned while under investigation
                by a hospital or healthcare facility of any military agency?<span
                class="mandatory">*</span>
              </label>
            </div>
          </td>
          <td colspan="4" width="20%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="radio-inline radioStyle">
                <input
                  type="radio"
                  id="cmd_0246"
                  (blur)="handleNAchange('cmd_NA_025','cmd_0246')"
                  [class.is-invalid]="
                    (AbaBuildBlocksForms.controls['cmd_0246'].touched &&
                      AbaBuildBlocksForms.controls['cmd_0246'].errors) ||
                    (AbaBuildBlocksForms.controls['cmd_0246'].errors && isSubmit)
                  "
                  name="cmd_0246"
                  formControlName="cmd_0246"
                  value="1"
                  (change)="validationsDataAdd($event)"
                />Yes</label
              >
              <label class="radio-inline radioStyle">
                <input
                  type="radio"
                  id="cmd_0246"
                  (blur)="handleNAchange('cmd_NA_025','cmd_0246')"
                  [class.is-invalid]="
                    (AbaBuildBlocksForms.controls['cmd_0246'].touched &&
                      AbaBuildBlocksForms.controls['cmd_0246'].errors) ||
                    (AbaBuildBlocksForms.controls['cmd_0246'].errors && isSubmit)
                  "
                  name="cmd_0246"
                  formControlName="cmd_0246"
                  value="0"
                  (change)="validationsDataAdd($event)"
                />No</label
              >
              <div
                class="formLabel col-md-2"
                *ngIf="
                  AbaBuildBlocksForms.controls['cmd_0246'].touched &&
                  AbaBuildBlocksForms.controls['cmd_0246'].errors
                "
              >
                <small
                  class="text-danger"
                  *ngIf="AbaBuildBlocksForms.controls['cmd_0246'].errors?.required"
                >
                  required
                </small>
              </div>
              <span
                *ngIf="
                  (AbaBuildBlocksForms.controls['cmd_0246'].touched &&
                    AbaBuildBlocksForms.controls['cmd_0246'].errors) ||
                  (AbaBuildBlocksForms.controls['cmd_0246'].errors && isSubmit)
                "
              >
                <i class="fas fa-times ml-2 text-danger"></i>
              </span>
            </div>
            <div class="form-group no-margin col-md-8">
              <b class="texasFloat">N/A</b>
                <input
                  class="texasFloat"
                  type="checkbox"
                  name="cmd_NA_025"
                  id="cmd_NA_025"
                  (ngModelChange)="handleNAchange('cmd_NA_025')"
                  formControlName="cmd_NA_025"
                  value="1"
                />
            </div> 
          </td>
        </tr>
        <tr>
          <td colspan="18" width="5%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel">Malpractice Claims History </label>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="2" width="5%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel">16</label>
            </div>
          </td>
          <td colspan="12" width="75%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel"
                >Have you had any malpractice actions within the past 5 years
                (pending, settled, arbitrated , mediated or litigated?<span
                class="mandatory">*</span>
              </label>
            </div>
          </td>
          <td colspan="4" width="20%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="radio-inline radioStyle">
                <input
                  type="radio"
                  id="cmd_0247"
                  (blur)="handleNAchange('cmd_NA_026','cmd_0247')"
                  [class.is-invalid]="
                    (AbaBuildBlocksForms.controls['cmd_0247'].touched &&
                      AbaBuildBlocksForms.controls['cmd_0247'].errors) ||
                    (AbaBuildBlocksForms.controls['cmd_0247'].errors && isSubmit)
                  "
                  name="cmd_0247"
                  formControlName="cmd_0247"
                  value="1"
                  (change)="validationsDataAdd($event)"
                />Yes</label
              >
              <label class="radio-inline radioStyle">
                <input
                  type="radio"
                  id="cmd_0247"
                  (blur)="handleNAchange('cmd_NA_026','cmd_0247')"
                  [class.is-invalid]="
                    (AbaBuildBlocksForms.controls['cmd_0247'].touched &&
                      AbaBuildBlocksForms.controls['cmd_0247'].errors) ||
                    (AbaBuildBlocksForms.controls['cmd_0247'].errors && isSubmit)
                  "
                  name="cmd_0247"
                  formControlName="cmd_0247"
                  value="0"
                  (change)="validationsDataAdd($event)"
                />No</label
              >
              <div
                class="formLabel col-md-2"
                *ngIf="
                  AbaBuildBlocksForms.controls['cmd_0247'].touched &&
                  AbaBuildBlocksForms.controls['cmd_0247'].errors
                "
              >
                <small
                  class="text-danger"
                  *ngIf="AbaBuildBlocksForms.controls['cmd_0247'].errors?.required"
                >
                  required
                </small>
              </div>
              <span
                *ngIf="
                  (AbaBuildBlocksForms.controls['cmd_0247'].touched &&
                    AbaBuildBlocksForms.controls['cmd_0247'].errors) ||
                  (AbaBuildBlocksForms.controls['cmd_0247'].errors && isSubmit)
                "
              >
                <i class="fas fa-times ml-2 text-danger"></i>
              </span>
            </div>
            <div class="form-group no-margin col-md-8">
              <b class="texasFloat">N/A</b>
                <input
                  class="texasFloat"
                  type="checkbox"
                  name="cmd_NA_026"
                  id="cmd_NA_026"
                  (ngModelChange)="handleNAchange('cmd_NA_026')"
                  formControlName="cmd_NA_026"
                  value="1"
                />
            </div> 
          </td>
        </tr>
        <tr>
          <td colspan="18" width="5%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel">Criminal </label>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="2" width="5%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel">17</label>
            </div>
          </td>
          <td colspan="12" width="75%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel"
                >Have you ever been convicted of, pled guilty to, or pled nolo
                contendere to any felony that is reasonably related to your
                qualifications, competence, functions, or duties as a medical
                professional<span
                class="mandatory">*</span>
              </label>
            </div>
          </td>
          <td colspan="4" width="20%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="radio-inline radioStyle">
                <input
                  type="radio"
                  id="cmd_0248"
                  (blur)="handleNAchange('cmd_NA_027','cmd_0248')"
                  [class.is-invalid]="
                    (AbaBuildBlocksForms.controls['cmd_0248'].touched &&
                      AbaBuildBlocksForms.controls['cmd_0248'].invalid) ||
                    (AbaBuildBlocksForms.controls['cmd_0248'].invalid && isSubmit)
                  "
                  name="cmd_0248"
                  formControlName="cmd_0248"
                  value="1"
                  (change)="validationsDataAdd($event)"
                />Yes</label
              >
              <label class="radio-inline radioStyle">
                <input
                  type="radio"
                  id="cmd_0248"
                  (blur)="handleNAchange('cmd_NA_027','cmd_0248')"
                  [class.is-invalid]="
                    (AbaBuildBlocksForms.controls['cmd_0248'].touched &&
                      AbaBuildBlocksForms.controls['cmd_0248'].invalid) ||
                    (AbaBuildBlocksForms.controls['cmd_0248'].invalid && isSubmit)
                  "
                  name="cmd_0248"
                  formControlName="cmd_0248"
                  value="0"
                  (change)="validationsDataAdd($event)"
                />No</label
              >
              <div
                class="formLabel col-md-2"
                *ngIf="
                  AbaBuildBlocksForms.controls['cmd_0248'].touched &&
                  AbaBuildBlocksForms.controls['cmd_0248'].invalid
                "
              >
                <small
                  class="text-danger"
                  *ngIf="AbaBuildBlocksForms.controls['cmd_0248'].errors?.required"
                >
                  required
                </small>
              </div>
              <span
                *ngIf="
                  (AbaBuildBlocksForms.controls['cmd_0248'].touched &&
                    AbaBuildBlocksForms.controls['cmd_0248'].invalid) ||
                  (AbaBuildBlocksForms.controls['cmd_0248'].invalid && isSubmit)
                "
              >
                <i class="fas fa-times ml-2 text-danger"></i>
              </span>
            </div>
            <div class="form-group no-margin col-md-8">
              <b class="texasFloat">N/A</b>
                <input
                  class="texasFloat"
                  type="checkbox"
                  name="cmd_NA_027"
                  id="cmd_NA_027"
                  (ngModelChange)="handleNAchange('cmd_NA_027')"
                  formControlName="cmd_NA_027"
                  value="1"
                />
            </div> 
          </td>
        </tr>
        <tr>
          <td colspan="2" width="5%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel">18</label>
            </div>
          </td>
          <td colspan="12" width="75%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel"
                >Have you ever been convicted of, pled guilty to, or pled nolo
                contendere to any felony including an act of violence, child
                abuse or a sexual offense?<span
                class="mandatory">*</span>
              </label>
            </div>
          </td>
          <td colspan="4" width="20%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="radio-inline radioStyle">
                <input
                  type="radio"
                  id="cmd_0249"
                  (blur)="handleNAchange('cmd_NA_028','cmd_0249')"
                  [class.is-invalid]="
                    (AbaBuildBlocksForms.controls['cmd_0249'].touched &&
                      AbaBuildBlocksForms.controls['cmd_0249'].invalid) ||
                    (AbaBuildBlocksForms.controls['cmd_0249'].invalid && isSubmit)
                  "
                  name="cmd_0249"
                  formControlName="cmd_0249"
                  value="1"
                  (change)="validationsDataAdd($event)"
                />Yes</label
              >
              <label class="radio-inline radioStyle">
                <input
                  type="radio"
                  id="cmd_0249"
                  (blur)="handleNAchange('cmd_NA_028','cmd_0249')"
                  [class.is-invalid]="
                    (AbaBuildBlocksForms.controls['cmd_0249'].touched &&
                      AbaBuildBlocksForms.controls['cmd_0249'].invalid) ||
                    (AbaBuildBlocksForms.controls['cmd_0249'].invalid && isSubmit)
                  "
                  name="cmd_0249"
                  formControlName="cmd_0249"
                  value="0"
                  (change)="validationsDataAdd($event)"
                />No</label
              >
              <div
                class="formLabel col-md-2"
                *ngIf="
                  AbaBuildBlocksForms.controls['cmd_0249'].touched &&
                  AbaBuildBlocksForms.controls['cmd_0249'].invalid
                "
              >
                <small
                  class="text-danger"
                  *ngIf="AbaBuildBlocksForms.controls['cmd_0249'].errors?.required"
                >
                  required
                </small>
              </div>
              <span
                *ngIf="
                  (AbaBuildBlocksForms.controls['cmd_0249'].touched &&
                    AbaBuildBlocksForms.controls['cmd_0249'].invalid) ||
                  (AbaBuildBlocksForms.controls['cmd_0249'].invalid && isSubmit)
                "
              >
                <i class="fas fa-times ml-2 text-danger"></i>
              </span>
            </div>
            <div class="form-group no-margin col-md-8">
              <b class="texasFloat">N/A</b>
                <input
                  class="texasFloat"
                  type="checkbox"
                  name="cmd_NA_028"
                  id="cmd_NA_028"
                  (ngModelChange)="handleNAchange('cmd_NA_028')"
                  formControlName="cmd_NA_028"
                  value="1"
                />
            </div> 
          </td>
        </tr>
        <tr>
          <td colspan="2" width="5%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel">19</label>
            </div>
          </td>
          <td colspan="12" width="75%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel"
                >Have you been court-martialed for actions related to your
                duties as a medical professional?<span
                class="mandatory">*</span>
              </label>
            </div>
          </td>
          <td colspan="4" width="20%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="radio-inline radioStyle">
                <input
                  type="radio"
                  id="cmd_0250"
                  (blur)="handleNAchange('cmd_NA_029','cmd_0250')"
                  [class.is-invalid]="
                    (AbaBuildBlocksForms.controls['cmd_0250'].touched &&
                      AbaBuildBlocksForms.controls['cmd_0250'].invalid) ||
                    (AbaBuildBlocksForms.controls['cmd_0250'].invalid && isSubmit)
                  "
                  name="cmd_0250"
                  formControlName="cmd_0250"
                  value="1"
                  (change)="validationsDataAdd($event)"
                />Yes</label
              >

              <label class="radio-inline radioStyle">
                <input
                  type="radio"
                  id="cmd_0250"
                  (blur)="handleNAchange('cmd_NA_029','cmd_0250')"
                  [class.is-invalid]="
                    (AbaBuildBlocksForms.controls['cmd_0250'].touched &&
                      AbaBuildBlocksForms.controls['cmd_0250'].invalid) ||
                    (AbaBuildBlocksForms.controls['cmd_0250'].invalid && isSubmit)
                  "
                  name="cmd_0250"
                  formControlName="cmd_0250"
                  value="0"
                  (change)="validationsDataAdd($event)"
                />No</label
              >
              <div
                class="formLabel col-md-2"
                *ngIf="
                  AbaBuildBlocksForms.controls['cmd_0250'].touched &&
                  AbaBuildBlocksForms.controls['cmd_0250'].invalid
                "
              >
                <small
                  class="text-danger"
                  *ngIf="AbaBuildBlocksForms.controls['cmd_0250'].errors?.required"
                >
                  required
                </small>
              </div>
              <span
                *ngIf="
                  (AbaBuildBlocksForms.controls['cmd_0250'].touched &&
                    AbaBuildBlocksForms.controls['cmd_0250'].invalid) ||
                  (AbaBuildBlocksForms.controls['cmd_0250'].invalid && isSubmit)
                "
              >
                <i class="fas fa-times ml-2 text-danger"></i>
              </span>
            </div>
            <div class="form-group no-margin col-md-8">
              <b class="texasFloat">N/A</b>
                <input
                  class="texasFloat"
                  type="checkbox"
                  name="cmd_NA_029"
                  id="cmd_NA_029"
                  (ngModelChange)="handleNAchange('cmd_NA_029')"
                  formControlName="cmd_NA_029"
                  value="1"
                />
            </div> 
          </td>
        </tr>
        <tr>
          <td colspan="18" width="5%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel">Ability to Perform Job </label>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="2" width="5%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel">20</label>
            </div>
          </td>
          <td colspan="12" width="75%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel"
                >Are you currently engaged in the illegal use of drugs?
                ("Currently" means sufficiently recent to justify a reasonable
                belief that the use of drug may have an ongoing impact on
                one's ability to pr actice medicine. It is not limited to the
                day of, or within a matter of days or weeks before the d ate
                of application, rather that it has occurred recently enough to
                indicate the individual is actively engaged in such conduct.
                "Illegal use of drugs" refers to drugs whose possession or
                distribution is unlawful under the Controlled Substances Act,
                21 U.S.C. § 812.22. It "does not include the use of a drug
                taken under supervision by a licensed health care
                professional, or other uses authorized by the Con trolled
                Substances Act or other provision of Federal law." The term
                does include, however, the unlawful use of prescription
                controlled substances.)<span
                class="mandatory">*</span>
              </label>
            </div>
          </td>
          <td colspan="4" width="20%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="radio-inline radioStyle">
                <input
                  type="radio"
                  id="cmd_0251"
                  (blur)="handleNAchange('cmd_NA_030','cmd_0251')"
                  [class.is-invalid]="
                    (AbaBuildBlocksForms.controls['cmd_0251'].touched &&
                      AbaBuildBlocksForms.controls['cmd_0251'].invalid) ||
                    (AbaBuildBlocksForms.controls['cmd_0251'].invalid && isSubmit)
                  "
                  name="cmd_0251"
                  formControlName="cmd_0251"
                  value="1"
                  (change)="validationsDataAdd($event)"
                />Yes</label
              >
              <label class="radio-inline radioStyle">
                <input
                  type="radio"
                  id="cmd_0251"
                  (blur)="handleNAchange('cmd_NA_030','cmd_0251')"
                  [class.is-invalid]="
                    (AbaBuildBlocksForms.controls['cmd_0251'].touched &&
                      AbaBuildBlocksForms.controls['cmd_0251'].invalid) ||
                    (AbaBuildBlocksForms.controls['cmd_0251'].invalid && isSubmit)
                  "
                  name="cmd_0251"
                  formControlName="cmd_0251"
                  value="0"
                  (change)="validationsDataAdd($event)"
                />No</label
              >
              <div
                class="formLabel col-md-2"
                *ngIf="
                  AbaBuildBlocksForms.controls['cmd_0251'].touched &&
                  AbaBuildBlocksForms.controls['cmd_0251'].invalid
                "
              >
                <small
                  class="text-danger"
                  *ngIf="AbaBuildBlocksForms.controls['cmd_0251'].errors?.required"
                >
                  required
                </small>
              </div>
              <span
                *ngIf="
                  (AbaBuildBlocksForms.controls['cmd_0251'].touched &&
                    AbaBuildBlocksForms.controls['cmd_0251'].invalid) ||
                  (AbaBuildBlocksForms.controls['cmd_0251'].invalid && isSubmit)
                "
              >
                <i class="fas fa-times ml-2 text-danger"></i>
              </span>
            </div>
            <div class="form-group no-margin col-md-8">
              <b class="texasFloat">N/A</b>
                <input
                  class="texasFloat"
                  type="checkbox"
                  name="cmd_NA_030"
                  id="cmd_NA_030"
                  (ngModelChange)="handleNAchange('cmd_NA_030')"
                  formControlName="cmd_NA_030"
                  value="1"
                />
            </div> 
          </td>
        </tr>
        <tr>
          <td colspan="2" width="5%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel">21</label>
            </div>
          </td>
          <td colspan="12" width="75%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel"
                >Do you use any chemical substances that would in any way
                impair or limit your ability to practice medicine and perform
                the functions of your job with reasonable skill and safety?<span
                class="mandatory">*</span>
              </label>
            </div>
          </td>
          <td colspan="4" width="20%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="radio-inline radioStyle">
                <input
                  type="radio"
                  id="cmd_0252"
                  (blur)="handleNAchange('cmd_NA_031','cmd_0252')"
                  [class.is-invalid]="
                    (AbaBuildBlocksForms.controls['cmd_0252'].touched &&
                      AbaBuildBlocksForms.controls['cmd_0252'].invalid) ||
                    (AbaBuildBlocksForms.controls['cmd_0252'].invalid && isSubmit)
                  "
                  name="cmd_0252"
                  formControlName="cmd_0252"
                  value="1"
                  (change)="validationsDataAdd($event)"
                />Yes</label
              >
              <label class="radio-inline radioStyle">
                <input
                  type="radio"
                  id="cmd_0252"
                  (blur)="handleNAchange('cmd_NA_031','cmd_0252')"
                  [class.is-invalid]="
                    (AbaBuildBlocksForms.controls['cmd_0252'].touched &&
                      AbaBuildBlocksForms.controls['cmd_0252'].invalid) ||
                    (AbaBuildBlocksForms.controls['cmd_0252'].invalid && isSubmit)
                  "
                  name="cmd_0252"
                  formControlName="cmd_0252"
                  value="0"
                  (change)="validationsDataAdd($event)"
                />No</label
              >
              <div
                class="formLabel col-md-2"
                *ngIf="
                  AbaBuildBlocksForms.controls['cmd_0252'].touched &&
                  AbaBuildBlocksForms.controls['cmd_0252'].invalid
                "
              >
                <small
                  class="text-danger"
                  *ngIf="AbaBuildBlocksForms.controls['cmd_0252'].errors?.required"
                >
                  required
                </small>
              </div>
              <span
                *ngIf="
                  (AbaBuildBlocksForms.controls['cmd_0252'].touched &&
                    AbaBuildBlocksForms.controls['cmd_0252'].invalid) ||
                  (AbaBuildBlocksForms.controls['cmd_0252'].invalid && isSubmit)
                "
              >
                <i class="fas fa-times ml-2 text-danger"></i>
              </span>
            </div>
            <div class="form-group no-margin col-md-8">
              <b class="texasFloat">N/A</b>
                <input
                  class="texasFloat"
                  type="checkbox"
                  name="cmd_NA_031"
                  id="cmd_NA_031"
                  (ngModelChange)="handleNAchange('cmd_NA_031')"
                  formControlName="cmd_NA_031"
                  value="1"
                />
            </div> 
          </td>
        </tr>
        <tr>
          <td colspan="18" width="5%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel">Ability to Perform Job </label>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="2" width="5%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel">22</label>
            </div>
          </td>
          <td colspan="12" width="75%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel"
                >Do you have any reason to believe that you would pose a risk
                to the safety or well-being of your patients?<span
                class="mandatory">*</span></label
              >
            </div>
          </td>
          <td colspan="4" width="20%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="radio-inline radioStyle">
                <input
                  type="radio"
                  id="cmd_0253"
                  (blur)="handleNAchange('cmd_NA_032','cmd_0253')"
                  [class.is-invalid]="
                    (AbaBuildBlocksForms.controls['cmd_0253'].touched &&
                      AbaBuildBlocksForms.controls['cmd_0253'].invalid) ||
                    (AbaBuildBlocksForms.controls['cmd_0253'].invalid && isSubmit)
                  "
                  name="cmd_0253"
                  formControlName="cmd_0253"
                  value="1"
                  (change)="validationsDataAdd($event)"
                />Yes</label
              >
              <label class="radio-inline radioStyle">
                <input
                  type="radio"
                  id="cmd_0253"
                  (blur)="handleNAchange('cmd_NA_032','cmd_0253')"
                  [class.is-invalid]="
                    (AbaBuildBlocksForms.controls['cmd_0253'].touched &&
                      AbaBuildBlocksForms.controls['cmd_0253'].invalid) ||
                    (AbaBuildBlocksForms.controls['cmd_0253'].invalid && isSubmit)
                  "
                  name="cmd_0253"
                  formControlName="cmd_0253"
                  value="0"
                  (change)="validationsDataAdd($event)"
                />No</label
              >
              <div
                class="formLabel col-md-2"
                *ngIf="
                  AbaBuildBlocksForms.controls['cmd_0253'].touched &&
                  AbaBuildBlocksForms.controls['cmd_0253'].invalid
                "
              >
                <small
                  class="text-danger"
                  *ngIf="AbaBuildBlocksForms.controls['cmd_0253'].errors?.required"
                >
                  required
                </small>
              </div>
              <span
                *ngIf="
                  (AbaBuildBlocksForms.controls['cmd_0253'].touched &&
                    AbaBuildBlocksForms.controls['cmd_0253'].invalid) ||
                  (AbaBuildBlocksForms.controls['cmd_0253'].invalid && isSubmit)
                "
              >
                <i class="fas fa-times ml-2 text-danger"></i>
              </span>
            </div>
            <div class="form-group no-margin col-md-8">
              <b class="texasFloat">N/A</b>
                <input
                  class="texasFloat"
                  type="checkbox"
                  name="cmd_NA_032"
                  id="cmd_NA_032"
                  (ngModelChange)="handleNAchange('cmd_NA_032')"
                  formControlName="cmd_NA_032"
                  value="1"
                />
            </div> 
          </td>
        </tr>
        <tr>
          <td colspan="2" width="5%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel">23</label>
            </div>
          </td>
          <td colspan="12" width="75%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel"
                >Are you unable to perform the essential functions of a
                practitioner in your area of practice, with or without
                reasonable accommodation?<span
                class="mandatory">*</span>
              </label>
            </div>
          </td>
          <td colspan="4" width="20%">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="radio-inline radioStyle">
                <input
                  type="radio"
                  id="cmd_0254"
                  (blur)="handleNAchange('cmd_NA_033','cmd_0254')"
                  [class.is-invalid]="
                    (AbaBuildBlocksForms.controls['cmd_0254'].touched &&
                      AbaBuildBlocksForms.controls['cmd_0254'].invalid) ||
                    (AbaBuildBlocksForms.controls['cmd_0254'].invalid && isSubmit)
                  "
                  name="cmd_0254"
                  formControlName="cmd_0254"
                  value="1"
                  (change)="validationsDataAdd($event)"
                />Yes</label
              >
              <label class="radio-inline radioStyle">
                <input
                  type="radio"
                  id="cmd_0254"
                  (blur)="handleNAchange('cmd_NA_033','cmd_0254')"
                  [class.is-invalid]="
                    (AbaBuildBlocksForms.controls['cmd_0254'].touched &&
                      AbaBuildBlocksForms.controls['cmd_0254'].invalid) ||
                    (AbaBuildBlocksForms.controls['cmd_0254'].invalid && isSubmit)
                  "
                  name="cmd_0254"
                  formControlName="cmd_0254"
                  value="0"
                  (change)="validationsDataAdd($event)"
                />No</label
              >
              <div
                class="formLabel col-md-2"
                *ngIf="
                  AbaBuildBlocksForms.controls['cmd_0254'].touched &&
                  AbaBuildBlocksForms.controls['cmd_0254'].invalid
                "
              >
                <small
                  class="text-danger"
                  *ngIf="AbaBuildBlocksForms.controls['cmd_0254'].errors?.required"
                >
                  required
                </small>
              </div>
              <span
                *ngIf="
                  (AbaBuildBlocksForms.controls['cmd_0254'].touched &&
                    AbaBuildBlocksForms.controls['cmd_0254'].invalid) ||
                  (AbaBuildBlocksForms.controls['cmd_0254'].invalid && isSubmit)
                "
              >
                <i class="fas fa-times ml-2 text-danger"></i>
              </span>
            </div>
            <div class="form-group no-margin col-md-8">
              <b class="texasFloat">N/A</b>
                <input
                  class="texasFloat"
                  type="checkbox"
                  name="cmd_NA_033"
                  id="cmd_NA_033"
                  (ngModelChange)="handleNAchange('cmd_NA_033')"
                  formControlName="cmd_NA_033"
                  value="1"
                />
            </div> 
          </td>
        </tr>
      </table>
      <label class="boldParagraph"
        >Please use the space below to explain yes answers to any question
        except #16.
      </label>
      <hr class="tablePersonal" />
      <tr>
        <td colspan="12" width="100%">
          <h3 class="hThreeTableStyle">
            <b>Section II - Disclosure Questions-continued </b>
          </h3>
        </td>
      </tr>
      <table border="1" width="100%">
        <tr>
          <td colspan="18">
            <div class="form-inline no-margin sideLabelAlignment">
              <label class="boldParagraph"
                >Please use the space below to explain yes answers to any
                question except 16.
              </label>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="16">
            <div class="form-inline no-margin sideLabelAlignment">
              <label class="boldParagraph">QUESTION NUMBER</label>
            </div>
          </td>
          <td colspan="2">
            <div class="form-inline no-margin sideLabelAlignment">
              <label class="boldParagraph">PLEASE EXPLAIN<span
                class="mandatory">*</span> </label>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="16">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <textarea
                style="width: 70%"
                [class.is-invalid]="
                  questionCounter > 0 && AbaBuildBlocksForms.controls['cmd_0255'].invalid"
                class="form-control"
                id="cmd_0255"
                formControlName="cmd_0255"
                name="cmd_0255"
              ></textarea>
              <div class="formLabel col-sm-2">
                <small
                  class="text-danger"
                  *ngIf="
                    questionCounter > 0 && AbaBuildBlocksForms.controls['cmd_0255'].invalid"
                >
                  required
                </small>
              </div>
            </div>
          </td>
          <td colspan="2">
            <div class="form-inline no-margin sideLabelAlignment">
              <textarea
                style="width: 70%"
                class="form-control"
                id="cmd_0256"
                formControlName="cmd_0256"
                name="cmd_0256"
                data-id="Text5.2.0.0"
              ></textarea>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="16">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <textarea
                style="width: 70%"
                [class.is-invalid]="
                  questionCounter > 1 && AbaBuildBlocksForms.controls['cmd_0257'].invalid
                "
                class="form-control"
                id="cmd_0257"
                formControlName="cmd_0257"
                name="cmd_0257"
                data-id="Text5.2.1.1"
              ></textarea>
              <div class="formLabel col-md-2">
                <small
                  class="text-danger"
                  *ngIf="
                    questionCounter > 1 && AbaBuildBlocksForms.controls['cmd_0257'].invalid
                  "
                >
                  required
                </small>
              </div>
            </div>
          </td>
          <td colspan="2">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <textarea
                style="width: 70%"
                class="form-control"
                id="cmd_0258"
                formControlName="cmd_0258"
                name="cmd_0258"
                data-id="Text5.2.0.1"
              ></textarea>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="16">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <textarea
                style="width: 70%"
                [class.is-invalid]="
                  questionCounter > 2 && AbaBuildBlocksForms.controls['cmd_0259'].invalid
                "
                class="form-control"
                id="cmd_0259"
                formControlName="cmd_0259"
                name="cmd_0259"
                data-id="Text5.2.1.2"
              ></textarea>
              <div class="formLabel col-md-2">
                <small
                  class="text-danger"
                  *ngIf="
                    questionCounter > 2 && AbaBuildBlocksForms.controls['cmd_0259'].invalid
                  "
                >
                  required
                </small>
              </div>
            </div>
          </td>
          <td colspan="2">
            <div class="form-inline no-margin sideLabelAlignment">
              <textarea
                style="width: 70%"
                class="form-control"
                id="cmd_0260"
                formControlName="cmd_0260"
                name="cmd_0260"
                data-id="Text5.2.0.2"
              ></textarea>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="16">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <textarea
                style="width: 70%"
                [class.is-invalid]="
                  questionCounter > 3 && AbaBuildBlocksForms.controls['cmd_0261'].invalid
                "
                class="form-control"
                id="cmd_0261"
                formControlName="cmd_0261"
                name="cmd_0261"
                data-id="Text5.2.1.3"
              ></textarea>
              <div class="formLabel col-md-2">
                <small
                  class="text-danger"
                  *ngIf="
                    questionCounter > 3 && AbaBuildBlocksForms.controls['cmd_0261'].invalid
                  "
                >
                  required
                </small>
              </div>
            </div>
          </td>
          <td colspan="2">
            <div class="form-inline no-margin sideLabelAlignment">
              <textarea
                style="width: 70%"
                class="form-control"
                id="cmd_0262"
                formControlName="cmd_0262"
                name="cmd_0262"
                data-id="Text5.2.0.3"
              ></textarea>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="16">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <textarea
                style="width: 70%"
                [class.is-invalid]="
                  questionCounter > 4 && AbaBuildBlocksForms.controls['cmd_0263'].invalid
                "
                class="form-control"
                id="cmd_0263"
                formControlName="cmd_0263"
                name="cmd_0263"
                data-id="Text5.2.1.4"
              ></textarea>
              <div class="formLabel col-md-2">
                <small
                  class="text-danger"
                  *ngIf="
                    questionCounter > 4 && AbaBuildBlocksForms.controls['cmd_0263'].invalid
                  "
                >
                  required
                </small>
              </div>
            </div>
          </td>
          <td colspan="2">
            <div class="form-inline no-margin sideLabelAlignment">
              <textarea
                style="width: 70%"
                class="form-control"
                id="cmd_0264"
                formControlName="cmd_0264"
                name="cmd_0264"
                data-id="Text5.2.0.4"
              ></textarea>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="16">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <textarea
                style="width: 70%"
                [class.is-invalid]="
                  questionCounter > 5 && AbaBuildBlocksForms.controls['cmd_0265'].invalid
                "
                class="form-control"
                id="cmd_0265"
                formControlName="cmd_0265"
                name="cmd_0265"
                data-id="Text5.2.1.5"
              ></textarea>
              <div class="formLabel col-md-2">
                <small
                  class="text-danger"
                  *ngIf="
                    questionCounter > 5 && AbaBuildBlocksForms.controls['cmd_0265'].invalid
                  "
                >
                  required
                </small>
              </div>
            </div>
          </td>
          <td colspan="2">
            <div class="form-inline no-margin sideLabelAlignment">
              <textarea
                style="width: 70%"
                class="form-control"
                id="cmd_0266"
                formControlName="cmd_0266"
                name="cmd_0266"
                data-id="Text5.2.0.5"
              ></textarea>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="16">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <textarea
                style="width: 70%"
                [class.is-invalid]="
                  questionCounter > 6 && AbaBuildBlocksForms.controls['cmd_0267'].invalid
                "
                class="form-control"
                id="cmd_0267"
                formControlName="cmd_0267"
                name="cmd_0267"
                data-id="Text5.2.1.6"
              ></textarea>
              <div class="formLabel col-md-2">
                <small
                  class="text-danger"
                  *ngIf="
                    questionCounter > 6 && AbaBuildBlocksForms.controls['cmd_0267'].invalid
                  "
                >
                  required
                </small>
              </div>
            </div>
          </td>
          <td colspan="2">
            <div class="form-inline no-margin sideLabelAlignment">
              <textarea
                style="width: 70%"
                class="form-control"
                id="cmd_0268"
                formControlName="cmd_0268"
                name="cmd_0268"
                data-id="Text5.2.0.6"
              ></textarea>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="16">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <textarea
                style="width: 70%"
                [class.is-invalid]="
                  questionCounter > 7 && AbaBuildBlocksForms.controls['cmd_0269'].invalid
                "
                class="form-control"
                id="cmd_0269"
                formControlName="cmd_0269"
                name="cmd_0269"
                data-id="Text5.2.1.7"
              ></textarea>
              <div class="formLabel col-md-2">
                <small
                  class="text-danger"
                  *ngIf="
                    questionCounter > 7 && AbaBuildBlocksForms.controls['cmd_0269'].invalid
                  "
                >
                  required
                </small>
              </div>
            </div>
          </td>
          <td colspan="2">
            <div class="form-inline no-margin sideLabelAlignment">
              <textarea
                style="width: 70%"
                class="form-control"
                id="cmd_0270"
                formControlName="cmd_0270"
                name="cmd_0270"
                data-id="Text5.2.0.7"
              ></textarea>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="16">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <textarea
                style="width: 70%"
                [class.is-invalid]="
                  questionCounter > 8 && AbaBuildBlocksForms.controls['cmd_0271'].invalid
                "
                class="form-control"
                id="cmd_0271"
                formControlName="cmd_0271"
                name="cmd_0271"
                data-id="Text5.2.1.8.0"
              ></textarea>
              <div class="formLabel col-md-2">
                <small
                  class="text-danger"
                  *ngIf="
                    questionCounter > 8 && AbaBuildBlocksForms.controls['cmd_0271'].invalid
                  "
                >
                  required
                </small>
              </div>
            </div>
          </td>
          <td colspan="2">
            <div class="form-inline no-margin sideLabelAlignment">
              <textarea
                style="width: 70%"
                class="form-control"
                id="cmd_0272"
                formControlName="cmd_0272"
                name="cmd_0272"
                data-id="Text5.2.0.8.0"
              ></textarea>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="16">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <textarea
                style="width: 70%"
                [class.is-invalid]="
                  questionCounter > 9 && AbaBuildBlocksForms.controls['cmd_0273'].invalid
                "
                class="form-control"
                id="cmd_0273"
                formControlName="cmd_0273"
                name="cmd_0273"
                data-id="Text5.2.1.9.0"
              ></textarea>
              <div class="formLabel col-md-2">
                <small
                  class="text-danger"
                  *ngIf="
                    questionCounter > 9 && AbaBuildBlocksForms.controls['cmd_0273'].invalid
                  "
                >
                  required
                </small>
              </div>
            </div>
          </td>
          <td colspan="2">
            <div class="form-inline no-margin sideLabelAlignment">
              <textarea
                style="width: 70%"
                class="form-control"
                id="cmd_0274"
                formControlName="cmd_0274"
                name="cmd_0274"
                data-id="Text5.2.0.9.0"
              ></textarea>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="16">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <textarea
                style="width: 70%"
                [class.is-invalid]="
                  questionCounter > 10 && AbaBuildBlocksForms.controls['cmd_0275'].invalid
                "
                class="form-control"
                id="cmd_0275"
                formControlName="cmd_0275"
                name="cmd_0275"
                data-id="Text5.2.1.8.1"
              ></textarea>
              <div class="formLabel col-md-2">
                <small
                  class="text-danger"
                  *ngIf="
                    questionCounter > 10 &&
                    AbaBuildBlocksForms.controls['cmd_0275'].invalid
                  "
                >
                  required
                </small>
              </div>
            </div>
          </td>
          <td colspan="2">
            <div class="form-inline no-margin sideLabelAlignment">
              <textarea
                style="width: 70%"
                class="form-control"
                id="cmd_0276"
                formControlName="cmd_0276"
                name="cmd_0276"
                data-id="Text5.2.0.8.1"
              ></textarea>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="16">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <textarea
                style="width: 70%"
                [class.is-invalid]="
                  questionCounter > 11 && AbaBuildBlocksForms.controls['cmd_0277'].invalid
                "
                class="form-control"
                id="cmd_0277"
                formControlName="cmd_0277"
                name="cmd_0277"
                data-id="Text5.2.1.9.1"
              ></textarea>
              <div class="formLabel col-md-2">
                <small
                  class="text-danger"
                  *ngIf="
                    questionCounter > 11 &&
                    AbaBuildBlocksForms.controls['cmd_0277'].invalid
                  "
                >
                  required
                </small>
              </div>
            </div>
          </td>
          <td colspan="2">
            <div class="form-inline no-margin sideLabelAlignment">
              <textarea
                style="width: 70%"
                class="form-control"
                id="cmd_0278"
                formControlName="cmd_0278"
                name="cmd_0278"
                data-id="Text5.2.0.9.1"
              ></textarea>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="16">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <textarea
                style="width: 70%"
                [class.is-invalid]="
                  questionCounter > 12 && AbaBuildBlocksForms.controls['cmd_0279'].invalid
                "
                class="form-control"
                id="cmd_0279"
                formControlName="cmd_0279"
                name="cmd_0279"
                data-id="Text5.2.1.8.2"
              ></textarea>
              <div class="formLabel col-md-2">
                <small
                  class="text-danger"
                  *ngIf="
                    questionCounter > 12 &&
                    AbaBuildBlocksForms.controls['cmd_0279'].invalid
                  "
                >
                  required
                </small>
              </div>
            </div>
          </td>
          <td colspan="2">
            <div class="form-inline no-margin sideLabelAlignment">
              <textarea
                style="width: 70%"
                class="form-control"
                id="cmd_0280"
                formControlName="cmd_0280"
                name="cmd_0280"
                data-id="Text5.2.0.8.2"
              ></textarea>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="16">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <textarea
                style="width: 70%"
                [class.is-invalid]="
                  questionCounter > 13 && AbaBuildBlocksForms.controls['cmd_0281'].invalid
                "
                class="form-control"
                id="cmd_0281"
                formControlName="cmd_0281"
                name="cmd_0281"
                data-id="Text5.2.1.9.2"
              ></textarea>
              <div class="formLabel col-md-2">
                <small
                  class="text-danger"
                  *ngIf="
                    questionCounter > 13 &&
                    AbaBuildBlocksForms.controls['cmd_0281'].invalid
                  "
                >
                  required
                </small>
              </div>
            </div>
          </td>
          <td colspan="2">
            <div class="form-inline no-margin sideLabelAlignment">
              <textarea
                style="width: 70%"
                class="form-control"
                id="cmd_0282"
                formControlName="cmd_0281"
                name="cmd_0282"
                data-id="Text5.2.0.9.2"
              ></textarea>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="16">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <textarea
                style="width: 70%"
                [class.is-invalid]="
                  questionCounter > 14 && AbaBuildBlocksForms.controls['cmd_0283'].invalid
                "
                class="form-control"
                id="cmd_0283"
                formControlName="cmd_0283"
                name="cmd_0283"
                data-id="Text5.2.1.8.3"
              ></textarea>
              <div class="formLabel col-md-2">
                <small
                  class="text-danger"
                  *ngIf="
                    questionCounter > 14 &&
                    AbaBuildBlocksForms.controls['cmd_0283'].invalid
                  "
                >
                  required
                </small>
              </div>
            </div>
          </td>
          <td colspan="2">
            <div class="form-inline no-margin sideLabelAlignment">
              <textarea
                style="width: 70%"
                class="form-control"
                id="cmd_0284"
                formControlName="cmd_0284"
                name="cmd_0284"
                data-id="Text5.2.0.8.3"
              ></textarea>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="16">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <textarea
                style="width: 70%"
                [class.is-invalid]="
                  questionCounter > 15 && AbaBuildBlocksForms.controls['cmd_0285'].invalid
                "
                class="form-control"
                id="cmd_0285"
                formControlName="cmd_0285"
                name="cmd_0285"
                data-id="Explain 10"
              ></textarea>
              <div
                class="formLabel col-md-2"
                *ngIf="
                  AbaBuildBlocksForms.controls['cmd_0285'].touched &&
                  AbaBuildBlocksForms.controls['cmd_0285'].invalid
                "
              >
                <small
                  class="text-danger"
                  *ngIf="
                    questionCounter > 15 &&
                    AbaBuildBlocksForms.controls['cmd_0285'].invalid
                  "
                >
                  required
                </small>
              </div>
            </div>
          </td>
          <td colspan="2">
            <div class="form-inline no-margin sideLabelAlignment">
              <textarea
                style="width: 70%"
                class="form-control"
                id="cmd_0286"
                formControlName="cmd_0286"
                name="cmd_0286"
                data-id="Page 10"
              ></textarea>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="16">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <textarea
                style="width: 70%"
                [class.is-invalid]="
                  questionCounter > 16 && AbaBuildBlocksForms.controls['cmd_0287'].invalid
                "
                class="form-control"
                id="cmd_0287"
                formControlName="cmd_0287"
                name="cmd_0287"
                data-id="Text5.2.1.9.3"
              ></textarea>
              <div class="formLabel col-md-2">
                <small
                  class="text-danger"
                  *ngIf="
                    questionCounter > 16 &&
                    AbaBuildBlocksForms.controls['cmd_0287'].invalid
                  "
                >
                  required
                </small>
              </div>
            </div>
          </td>
          <td colspan="2">
            <div class="form-inline no-margin sideLabelAlignment">
              <textarea
                style="width: 70%"
                class="form-control"
                id="cmd_0288"
                formControlName="cmd_0288"
                name="cmd_0288"
                data-id="Text5.2.0.9.3"
              ></textarea>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="16">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <textarea
                style="width: 70%"
                [class.is-invalid]="
                  questionCounter > 17 && AbaBuildBlocksForms.controls['cmd_0289'].invalid
                "
                class="form-control"
                id="cmd_0289"
                formControlName="cmd_0289"
                name="cmd_0289"
                data-id="Text5.2.1.8.4"
              ></textarea>
              <div class="formLabel col-md-2">
                <small
                  class="text-danger"
                  *ngIf="
                    questionCounter > 17 &&
                    AbaBuildBlocksForms.controls['cmd_0289'].invalid
                  "
                >
                  required
                </small>
              </div>
            </div>
          </td>
          <td colspan="2">
            <div class="form-inline no-margin sideLabelAlignment">
              <textarea
                style="width: 70%"
                class="form-control"
                id="cmd_0290"
                formControlName="cmd_0290"
                name="cmd_0290"
                data-id="Text5.2.0.8.4"
              ></textarea>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="16">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <textarea
                style="width: 70%"
                [class.is-invalid]="
                  questionCounter > 18 && AbaBuildBlocksForms.controls['cmd_0291'].invalid
                "
                class="form-control"
                id="cmd_0291"
                formControlName="cmd_0291"
                name="cmd_0291"
                data-id="Text5.2.1.9.4"
              ></textarea>
              <div class="formLabel col-md-2">
                <small
                  class="text-danger"
                  *ngIf="
                    questionCounter > 18 &&
                    AbaBuildBlocksForms.controls['cmd_0291'].invalid
                  "
                >
                  required
                </small>
              </div>
            </div>
          </td>
          <td colspan="2">
            <div class="form-inline no-margin sideLabelAlignment">
              <textarea
                style="width: 70%"
                class="form-control"
                id="cmd_0292"
                formControlName="cmd_0292"
                name="cmd_0292"
                data-id="Text5.2.0.9.4"
              ></textarea>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="16">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <textarea
                style="width: 70%"
                [class.is-invalid]="
                  questionCounter > 19 && AbaBuildBlocksForms.controls['cmd_0293'].invalid
                "
                class="form-control"
                id="cmd_0293"
                formControlName="cmd_0293"
                name="cmd_0293"
                data-id="Text5.2.0.9.4"
              ></textarea>
              <div class="formLabel col-md-2">
                <small
                  class="text-danger"
                  *ngIf="
                    questionCounter > 19 &&
                    AbaBuildBlocksForms.controls['cmd_0293'].invalid
                  "
                >
                  required
                </small>
              </div>
            </div>
          </td>
          <td colspan="2">
            <div class="form-inline no-margin sideLabelAlignment">
              <textarea
                style="width: 70%"
                class="form-control"
                id="cmd_0294"
                formControlName="cmd_0294"
                name="cmd_0294"
                data-id="Page 10"
              ></textarea>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="16">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <textarea
                style="width: 70%"
                [class.is-invalid]="
                  questionCounter > 20 && AbaBuildBlocksForms.controls['cmd_0295'].invalid
                "
                class="form-control"
                id="cmd_0295"
                formControlName="cmd_0295"
                name="cmd_0295"
                data-id="Explain 10"
              ></textarea>
              <div class="formLabel col-md-2">
                <small
                  class="text-danger"
                  *ngIf="
                    questionCounter > 20 &&
                    AbaBuildBlocksForms.controls['cmd_0295'].invalid
                  "
                >
                  required
                </small>
              </div>
            </div>
          </td>
          <td colspan="2">
            <div class="form-inline no-margin sideLabelAlignment">
              <textarea
                style="width: 70%"
                class="form-control"
                id="cmd_0296"
                formControlName="cmd_0296"
                name="cmd_0296"
                data-id="Page 10"
              ></textarea>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="16">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <textarea
                style="width: 70%"
                [class.is-invalid]="
                  questionCounter > 21 && AbaBuildBlocksForms.controls['cmd_0297'].invalid
                "
                class="form-control"
                id="cmd_0297"
                formControlName="cmd_0297"
                name="cmd_0297"
                data-id="Explain 10"
              ></textarea>
              <div class="formLabel col-md-2">
                <small
                  class="text-danger"
                  *ngIf="
                    questionCounter > 21 &&
                    AbaBuildBlocksForms.controls['cmd_0297'].invalid
                  "
                >
                  required
                </small>
              </div>
            </div>
          </td>
          <td colspan="2">
            <div class="form-inline no-margin sideLabelAlignment">
              <textarea
                style="width: 70%"
                class="form-control"
                id="cmd_0298"
                formControlName="cmd_0298"
                name="cmd_0298"
                data-id="Page 10"
              ></textarea>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="16">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <textarea
                style="width: 70%"
                [class.is-invalid]="
                  questionCounter > 22 && AbaBuildBlocksForms.controls['cmd_0299'].invalid
                "
                class="form-control"
                id="cmd_0299"
                formControlName="cmd_0299"
                name="cmd_0299"
                data-id="Explain 10"
              ></textarea>
              <div class="formLabel col-md-2">
                <small
                  class="text-danger"
                  *ngIf="
                    questionCounter > 22 &&
                    AbaBuildBlocksForms.controls['cmd_0299'].invalid
                  "
                >
                  required
                </small>
              </div>
            </div>
          </td>
          <td colspan="2">
            <div class="form-inline no-margin sideLabelAlignment">
              <textarea
                style="width: 70%"
                class="form-control"
                id="cmd_0300"
                formControlName="cmd_0300"
                name="cmd_0300"
                data-id="Page 10"
              ></textarea>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="16">
            <div class="form-inline no-margin sideLabelAlignment">
              <textarea
                style="width: 70%"
                class="form-control"
                id="cmd_0301"
                formControlName="cmd_0301"
                name="cmd_0301"
                data-id="Explain 10"
              ></textarea>
            </div>
          </td>
          <td colspan="2">
            <div class="form-inline no-margin sideLabelAlignment">
              <textarea
                style="width: 70%"
                class="form-control"
                id="cmd_0302"
                formControlName="cmd_0302"
                name="cmd_0302"
                data-id="Page 10"
              ></textarea>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="16">
            <div class="form-inline no-margin sideLabelAlignment">
              <textarea
                style="width: 70%"
                class="form-control"
                id="cmd_0303"
                formControlName="cmd_0303"
                name="cmd_0303"
                data-id="Explain 10"
              ></textarea>
            </div>
          </td>
          <td colspan="2">
            <div class="form-inline no-margin sideLabelAlignment">
              <textarea
                style="width: 70%"
                class="form-control"
                id="cmd_0304"
                formControlName="cmd_0304"
                name="cmd_0304"
                data-id="Page 10"
              ></textarea>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="16">
            <div class="form-inline no-margin sideLabelAlignment">
              <textarea
                style="width: 70%"
                class="form-control"
                id="cmd_0305"
                formControlName="cmd_0305"
                name="cmd_0305"
                data-id="Explain 10"
              ></textarea>
            </div>
          </td>
          <td colspan="2">
            <div class="form-inline no-margin sideLabelAlignment">
              <textarea
                style="width: 70%"
                class="form-control"
                id="cmd_0306"
                formControlName="cmd_0306"
                name="cmd_0306"
                data-id="Page 10"
              ></textarea>
            </div>
          </td>
        </tr>
      </table>
      <hr class="tablePersonal" />
      <table border="1" width="100%">
        <tr>
          <td colspan="18">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <b class="boldParagraph"> GROUP INFORMATION</b>
              
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="9">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <b class="boldParagraph">Group Information</b>
              
            </div>
          </td>
          <td colspan="9">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <b class="boldParagraph">Description of Information</b>
              
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="9">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel">Group Name:<span
                class="mandatory">*</span></label>
              
            </div>
          </td>
          <td colspan="9">
            <div class="col-md-8">
              <input
                        class="form-control"
                        id="cmd_009"
                        [class.is-invalid]="
                          (AbaBuildBlocksForms.controls['cmd_009'].touched &&
                            AbaBuildBlocksForms.controls['cmd_009'].invalid) ||
                          (AbaBuildBlocksForms.controls['cmd_009'].invalid && isSubmit)
                        "
                        name="cmd_009"
                        formControlName="cmd_009"
                         
                        type="text"
                        data-id="lname"
                      />
                      <div
                        class="formLabel col-md-2"
                        *ngIf="
                          AbaBuildBlocksForms.controls['cmd_009'].touched &&
                          AbaBuildBlocksForms.controls['cmd_009'].invalid
                        "
                      >
                        <small
                          class="text-danger"
                          *ngIf="AbaBuildBlocksForms.controls['cmd_009'].errors?.required"
                        >
                          required
                        </small>
                      </div>
                     
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="9">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel">Doing business as (DBA):</label>
              
            </div>
          </td>
          <td colspan="9">
            <div class="col-md-8">
              <input
                        class="form-control"
                        id="cmd_0023"
                        
                        name="cmd_0023"
                        formControlName="cmd_0023"
                         
                        type="text"
                        data-id="lname"
                      />
                      
                     
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="9">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel">Owner 1 Name: </label>
              
            </div>
          </td>
          <td colspan="9">
            <div class="col-md-8">
              <input
                        class="form-control"
                        id="cmd_0010"
                        name="cmd_0010"
                        formControlName="cmd_0010"
                         
                        type="text"
                        data-id="lname"
                      />
                      
                      
                     
            </div>
          </td>
        </tr> 
        <tr>
          <td colspan="9">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel">Owner 1 SSN:</label>
              
            </div>
          </td>
          <td colspan="9">
            <div class="col-md-8">
              <input
                        class="form-control"
                        id="cmd_0011"
                        name="cmd_0011"
                        formControlName="cmd_0011"
                         
                        type="text"
                        data-id="lname"
                      />
                      
                     
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="9">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel">Owner 1 DOB:</label>
              
            </div>
          </td>
          <td colspan="9">
            <div class="col-md-8">
              <input
                        class="form-control"
                        id="cmd_0012"
                        name="cmd_0012"
                        formControlName="cmd_0012"
                         
                        type="text"
                        data-id="lname"
                      />
                      
                     
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="9">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel">Owner 1 Percentage Ownership:</label>
              
            </div>
          </td>
          <td colspan="9">
            <div class="col-md-8">
              <input
                        class="form-control"
                        id="cmd_0013"
                        name="cmd_0013"
                        formControlName="cmd_0013"
                         
                        type="text"
                        data-id="lname"
                      />
                      
                     
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="9">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel">Owner 2 Name:</label>
              
            </div>
          </td>
          <td colspan="9">
            <div class="col-md-8">
              <input
                        class="form-control"
                        id="cmd_0024"
                        
                        name="cmd_0024"
                        formControlName="cmd_0024"
                         
                        type="text"
                        data-id="lname"
                      />
                      
                     
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="9">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel">Owner 2 SSN:</label>
              
            </div>
          </td>
          <td colspan="9">
            <div class="col-md-8">
              <input
                        class="form-control"
                        id="cmd_0025"
                        
                        name="cmd_0025"
                        formControlName="cmd_0025"
                         
                        type="text"
                        data-id="lname"
                      />
                      
                     
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="9">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel">Owner 2 DOB:</label>
              
            </div>
          </td>
          <td colspan="9">
            <div class="col-md-8">
              <input
                        class="form-control"
                        id="cmd_0026"
                        
                        name="cmd_0026"
                        formControlName="cmd_0026"
                         
                        type="text"
                        data-id="lname"
                      />
                      
                     
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="9">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel">Owner 2 Percentage Ownership:</label>
              
            </div>
          </td>
          <td colspan="9">
            <div class="col-md-8">
              <input
                        class="form-control"
                        id="cmd_0027"
                        
                        name="cmd_0027"
                        formControlName="cmd_0027"
                         
                        type="text"
                        data-id="lname"
                      />
                      
                     
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="9">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel">Owner 3 Name:</label>
              
            </div>
          </td>
          <td colspan="9">
            <div class="col-md-8">
              <input
                        class="form-control"
                        id="cmd_0028"
                        
                        name="cmd_0028"
                        formControlName="cmd_0028"
                         
                        type="text"
                        data-id="lname"
                      />
                      
                     
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="9">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel">Owner 3 SSN:</label>
              
            </div>
          </td>
          <td colspan="9">
            <div class="col-md-8">
              <input
                        class="form-control"
                        id="cmd_0029"
                        
                        name="cmd_0029"
                        formControlName="cmd_0029"
                         
                        type="text"
                        data-id="lname"
                      />
                      
                     
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="9">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel">Owner 3 DOB:</label>
              
            </div>
          </td>
          <td colspan="9">
            <div class="col-md-8">
              <input
                        class="form-control"
                        id="cmd_0030"
                        
                        name="cmd_0030"
                        formControlName="cmd_0030"
                         
                        type="text"
                        data-id="lname"
                      />
                      
                     
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="9">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel">Owner 3 Percentage Ownership:</label>
              
            </div>
          </td>
          <td colspan="9">
            <div class="col-md-8">
              <input
                        class="form-control"
                        id="cmd_0014"
                        name="cmd_0014"
                        formControlName="cmd_0014"
                         
                        type="text"
                        data-id="lname"
                      />
                     
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="9">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel">Primary Practice Location address:<span
                class="mandatory">*</span></label>
              
            </div>
          </td>
          <td colspan="9">
            <div class="col-md-8">
              <input
                        class="form-control"
                        id="cmd_0015"
                        [class.is-invalid]="
                          (AbaBuildBlocksForms.controls['cmd_0015'].touched &&
                            AbaBuildBlocksForms.controls['cmd_0015'].invalid) ||
                          (AbaBuildBlocksForms.controls['cmd_0015'].invalid && isSubmit)
                        "
                        name="cmd_0015"
                        formControlName="cmd_0015"
                         
                        type="text"
                        data-id="lname"
                      />
                      <div
                        class="formLabel col-md-2"
                        *ngIf="
                          AbaBuildBlocksForms.controls['cmd_0015'].touched &&
                          AbaBuildBlocksForms.controls['cmd_0015'].invalid
                        "
                      >
                        <small
                          class="text-danger"
                          *ngIf="AbaBuildBlocksForms.controls['cmd_0015'].errors?.required"
                        >
                          required
                        </small>
                      </div>
                     
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="9">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel">Secondary Practice Location address:</label>
              
            </div>
          </td>
          <td colspan="9">
            <div class="col-md-8">
              <input
                        class="form-control"
                        id="cmd_0031"
                        
                        name="cmd_0031"
                        formControlName="cmd_0031"
                         
                        type="text"
                        data-id="lname"
                      />
                      
                     
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="9">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel">Remit/Mailing address:<span
                class="mandatory">*</span></label>
              
            </div>
          </td>
          <td colspan="9">
            <div class="col-md-8">
              <input
                        class="form-control"
                        id="cmd_0016"
                        [class.is-invalid]="
                          (AbaBuildBlocksForms.controls['cmd_0016'].touched &&
                            AbaBuildBlocksForms.controls['cmd_0016'].invalid) ||
                          (AbaBuildBlocksForms.controls['cmd_0016'].invalid && isSubmit)
                        "
                        name="cmd_0016"
                        formControlName="cmd_0016"
                         
                        type="text"
                        data-id="lname"
                      />
                      <div
                        class="formLabel col-md-2"
                        *ngIf="
                          AbaBuildBlocksForms.controls['cmd_0016'].touched &&
                          AbaBuildBlocksForms.controls['cmd_0016'].invalid
                        "
                      >
                        <small
                          class="text-danger"
                          *ngIf="AbaBuildBlocksForms.controls['cmd_0016'].errors?.required"
                        >
                          required
                        </small>
                      </div>
                     
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="9">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel">Type 2 NPI:<span
                class="mandatory">*</span></label>
               
            </div>
          </td>
          <td colspan="9">
            <div class="form-group no-margin col-md-8">
              <b class="texasFloat">N/A</b>
                <input
                  class="texasFloat"
                  type="checkbox"
                  name="cmd_NA_010"
                  id="cmd_NA_010"
                  (ngModelChange)="handleNAchange('cmd_NA_010')"
                  formControlName="cmd_NA_010"
                  value="1"
                />
              
            <!-- </div> -->
            <!-- <div class="form-group no-margin col-md-6 sideLabelAlignment"> -->
              <input
                class="form-control" (blur)="handleNAchange('cmd_NA_010','cmd_0035')"
                [class.is-invalid]="(AbaBuildBlocksForms.controls['cmd_0035'].touched && AbaBuildBlocksForms.controls['cmd_0035'].invalid) || (AbaBuildBlocksForms.controls['cmd_0035'].invalid && isSubmit)"
                id="cmd_0035"
                name="cmd_0035"
                formControlName="cmd_0035"
                type="text"
                data-id="meds"
              />
              <div class="mandatory"
              *ngIf="AbaBuildBlocksForms.controls['cmd_0035'].touched && AbaBuildBlocksForms.controls['cmd_0035'].invalid">
              <small class="text-danger" *ngIf="AbaBuildBlocksForms.controls['cmd_0035'].errors?.required">
                  required
              </small>
              </div>
            </div>  

            
          </td>
        </tr>  
        
        <tr>
          <td colspan="9">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel">Tax ID:<span
                class="mandatory">*</span></label>
              
            </div>
          </td>
          <td colspan="9">
            <div class="col-md-8">
              <input
                        class="form-control"
                        id="cmd_0017"
                        [class.is-invalid]="
                          (AbaBuildBlocksForms.controls['cmd_0017'].touched &&
                            AbaBuildBlocksForms.controls['cmd_0017'].invalid) ||
                          (AbaBuildBlocksForms.controls['cmd_0017'].invalid && isSubmit)
                        "
                        name="cmd_0017"
                        formControlName="cmd_0017"
                         
                        type="text"
                        data-id="lname"
                      />
                      <div
                        class="formLabel col-md-2"
                        *ngIf="
                          AbaBuildBlocksForms.controls['cmd_0017'].touched &&
                          AbaBuildBlocksForms.controls['cmd_0017'].invalid
                        "
                      >
                        <small
                          class="text-danger"
                          *ngIf="AbaBuildBlocksForms.controls['cmd_0017'].errors?.required"
                        >
                          required
                        </small>
                      </div>
                     
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="9">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel">Phone Number (contact #):<span
                class="mandatory">*</span></label>
              
            </div>
          </td>
          <td colspan="9">
            <div class="col-md-8">
              <input
                        class="form-control"
                        id="cmd_0018"
                        [class.is-invalid]="
                          (AbaBuildBlocksForms.controls['cmd_0018'].touched &&
                            AbaBuildBlocksForms.controls['cmd_0018'].invalid) ||
                          (AbaBuildBlocksForms.controls['cmd_0018'].invalid && isSubmit)
                        "
                        name="cmd_0018"
                        formControlName="cmd_0018"
                         
                        type="text"
                        data-id="lname"
                      />
                      <div
                        class="formLabel col-md-2"
                        *ngIf="
                          AbaBuildBlocksForms.controls['cmd_0018'].touched &&
                          AbaBuildBlocksForms.controls['cmd_0018'].invalid
                        "
                      >
                        <small
                          class="text-danger"
                          *ngIf="AbaBuildBlocksForms.controls['cmd_0018'].errors?.required"
                        >
                          required
                        </small>
                      </div>
                     
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="9">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel">Phone Number (to be listed on directories):<span
                class="mandatory">*</span></label>
              
            </div>
          </td>
          <td colspan="9">
            <div class="col-md-8">
              <input
                        class="form-control"
                        id="cmd_0019"
                        [class.is-invalid]="
                          (AbaBuildBlocksForms.controls['cmd_0019'].touched &&
                            AbaBuildBlocksForms.controls['cmd_0019'].invalid) ||
                          (AbaBuildBlocksForms.controls['cmd_0019'].invalid && isSubmit)
                        "
                        name="cmd_0019"
                        formControlName="cmd_0019"
                         
                        type="text"
                        data-id="lname"
                      />
                      <div
                        class="formLabel col-md-2"
                        *ngIf="
                          AbaBuildBlocksForms.controls['cmd_0019'].touched &&
                          AbaBuildBlocksForms.controls['cmd_0019'].invalid
                        "
                      >
                        <small
                          class="text-danger"
                          *ngIf="AbaBuildBlocksForms.controls['cmd_0019'].errors?.required"
                        >
                          required
                        </small>
                      </div>
                     
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="9">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel">Email (to be listed on directories):<span
                class="mandatory">*</span></label>
              
            </div>
          </td>
          <td colspan="9">
            <div class="col-md-8">
              <input
                        class="form-control"
                        id="cmd_0020"
                        [class.is-invalid]="
                          (AbaBuildBlocksForms.controls['cmd_0020'].touched &&
                            AbaBuildBlocksForms.controls['cmd_0020'].invalid) ||
                          (AbaBuildBlocksForms.controls['cmd_0020'].invalid && isSubmit)
                        "
                        name="cmd_0020"
                        formControlName="cmd_0020"
                         
                        type="text"
                        data-id="lname"
                      />
                      <div
                        class="formLabel col-md-2"
                        *ngIf="
                          AbaBuildBlocksForms.controls['cmd_0020'].touched &&
                          AbaBuildBlocksForms.controls['cmd_0020'].invalid
                        "
                      >
                        <small
                          class="text-danger"
                          *ngIf="AbaBuildBlocksForms.controls['cmd_0020'].errors?.required"
                        >
                          required
                        </small>
                      </div>
                     
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="9">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel">Fax Number: <span
                class="mandatory">*</span></label>
              
            </div>
          </td>
          <td colspan="9">
            <div class="col-md-8">
              <input
                        class="form-control"
                        id="cmd_0021"
                        [class.is-invalid]="
                          (AbaBuildBlocksForms.controls['cmd_0021'].touched &&
                            AbaBuildBlocksForms.controls['cmd_0021'].invalid) ||
                          (AbaBuildBlocksForms.controls['cmd_0021'].invalid && isSubmit)
                        "
                        name="cmd_0021"
                        formControlName="cmd_0021"
                         
                        type="text"
                        data-id="lname"
                      />
                      <div
                        class="formLabel col-md-2"
                        *ngIf="
                          AbaBuildBlocksForms.controls['cmd_0021'].touched &&
                          AbaBuildBlocksForms.controls['cmd_0021'].invalid
                        "
                      >
                        <small
                          class="text-danger"
                          *ngIf="AbaBuildBlocksForms.controls['cmd_0021'].errors?.required"
                        >
                          required
                        </small>
                      </div>
                     
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="9">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel">Service setting (home/office/telehealth):<span
                class="mandatory">*</span></label>
              
            </div>
          </td>
          <td colspan="9">
            <div class="col-md-8">
              <input
                        class="form-control"
                        id="cmd_0022"
                        [class.is-invalid]="
                          (AbaBuildBlocksForms.controls['cmd_0022'].touched &&
                            AbaBuildBlocksForms.controls['cmd_0022'].invalid) ||
                          (AbaBuildBlocksForms.controls['cmd_0022'].invalid && isSubmit)
                        "
                        name="cmd_0022"
                        formControlName="cmd_0022"
                         
                        type="text"
                        data-id="lname"
                      />
                      <div
                        class="formLabel col-md-2"
                        *ngIf="
                          AbaBuildBlocksForms.controls['cmd_0022'].touched &&
                          AbaBuildBlocksForms.controls['cmd_0022'].invalid
                        "
                      >
                        <small
                          class="text-danger"
                          *ngIf="AbaBuildBlocksForms.controls['cmd_0022'].errors?.required"
                        >
                          required
                        </small>
                      </div>
                     
            </div>
          </td>
        </tr>  
        <tr>
          <td colspan="9">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel">Hours of Operation</label>
              
            </div>
          </td>
          <td colspan="9">
            <div class="col-md-8">
              <input
                        class="form-control"
                        id="cmd_0032"
                        
                        name="cmd_0032"
                        formControlName="cmd_0032"
                         
                        type="text"
                        data-id="lname"
                      />
                      
                     
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="9">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel">Office Manager</label>
              
            </div>
          </td>
          <td colspan="9">
            <div class="col-md-8">
              <input
                        class="form-control"
                        id="cmd_0033"
                        
                        name="cmd_0033"
                        formControlName="cmd_0033"
                         
                        type="text"
                        data-id="lname"
                      />
                      
                     
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="9">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel">Accreditation Details (if applicable)</label>
              
            </div>
          </td>
          <td colspan="9">
            <div class="col-md-8">
              <input
                        class="form-control"
                        id="cmd_0034"
                        
                        name="cmd_0034"
                        formControlName="cmd_0034"
                         
                        type="text"
                        data-id="lname"
                      />
                      
                     
            </div>
          </td>
        </tr>
        
      </table>  
      <hr class="tablePersonal" />
      <h3 class="hThreeTableStyle">
        <b>Section III – Standard Authorization, Attestation and Release </b>
      </h3>
      (Not for Use for Employment Purposes) I understand and agree that, as
      part of the credentialing application process for participation and⁄or
      clinical privileges (hereinafter, referred to as “Participation”) at or
      with
      <table border="1" width="100%">
        <tr>
          <td colspan="18">
            <div class="form-group form-inline no-margin sideLabelAlignment">
              <label class="formLabel">
                (PLEASE INDICATE MANAGED CARE COMPANY(S) OR HOSPITAL(S) TO
                WHICH YOU ARE APPLYING) (HEREINAFTER, INDIVIDUALLY REFERRED TO
                AS THE “ENTITY”)
              </label>
              <input
                style="width: 70%"
                class="form-control"
                id="cmd_0307"
                name="cmd_0307"
                formControlName="cmd_0307"
                type="text"
                data-id="Text5.1.4"
              />
            </div>
          </td>
        </tr>
      </table>
      <tr>
        <td colspan="18" width="80%">
          <div class="form-group form-inline no-margin sideLabelAlignment">
            <label class="boldParagraph"
              >and any of the Entity’s affiliated entities, I am required to
              provide sufficient and accurate information for a proper
              evaluation of my current licensure, relevant training and⁄or
              experience, clinical competence, health status, character,
              ethics, and any other criteria used by the Entity for
              determining initial and ongoing eligibility for Participation.
              Each Entity and its representatives, employees, and agent(s)
              acknowledge that the information obtained relating to the
              application process will be held confidential to the extent
              permitted by law.
            </label>
          </div>
        </td>
      </tr>
      <tr>
        <td colspan="18" width="80%">
          <div class="form-group form-inline no-margin sideLabelAlignment">
            <label class="boldParagraph"
              >I acknowledge that each Entity has its own criteria for
              acceptance, and I may be accepted or rejected by each
              independently. I further acknowledge and understand that my
              cooperation in obtaining information and my consent to the
              release of information do not guarantee that any Entity will
              grant me clinical privileges or contract with me as a provider
              of services. I understand that my application for Participation
              with the Entity is not an application for employment with the
              Entity and that acceptance of my application by the Entity will
              not result in my employment by the Entity.
            </label>
          </div>
        </td>
      </tr>
      <tr>
        <td colspan="18" width="80%">
          <div class="form-group form-inline no-margin sideLabelAlignment">
            <label class="boldParagraph"
              >For Hospital Credentialing. I consent to appear for an
              interview with the credentials committee, medical staff
              executive committee, or other representatives of the medical
              staff, hospital administration or the governing board, if
              required or requested. As a medical staff member, I pledge to
              provide continuous care for my patients. I have been informed of
              existing hospital by laws, rules and regulation,, and policies
              regarding the application process,and I agree that as a medical
              staff member, I will be bound by them.
            </label>
          </div>
        </td>
      </tr>
      <tr>
        <td colspan="18" width="80%">
          <div class="form-group form-inline no-margin sideLabelAlignment">
            <label class="boldParagraph"
              >Authorization of Investigation Concerning Application for
              Participation.I authorize the following individuals including,
              without limitation, the Entity, its representatives, employees,
              and/or designated agent(s); the Entity’s affiliated entities and
              their representatives, employees, and/or designated agents; and
              the Entity’s designated professional credentials verification
              organization (collectively referred to as “Agents”), to
              investigate information, which includes both oral and written
              statements, records, and documents, concerning my application
              for Participation. I agree to allow the Entity and/or its
              Agent(s) to inspect all records and documents relating to such
              an investigation.
            </label>
          </div>
        </td>
      </tr>
      <tr>
        <td colspan="18" width="80%">
          <div class="form-group form-inline no-margin sideLabelAlignment">
            <label class="boldParagraph"
              >Authorization of Third-Party Sources to Release Information
              Concerning Application for Participation. I authorize any third
              party, including, but not limited to, individuals, agencies,
              medical groups responsible for credentials verification,
              corporations, companies, employers, former employers, hospitals,
              health plans, health maintenance organizations, managed care
              organizations, law enforcement or licensing agencies, insurance
              companies, educational and other institutions, military
              services, medical credentialing and accreditation agencies,
              professional medical societies, the Federation of State Medical
              Boards, the National Practitioner Data Bank, and the Health Care
              Integrity and Protection Data Bank, to release to the Entity
              and/or its Agent(s), information, including otherwise privileged
              or confidential information, concerning my professional
              qualifications, credentials, clinical competence, quality
              assurance and utilization data, character, mental condition,
              physical condition, alcohol or chemical dependency diagnosis and
              treatment, ethics, behavior, or any other matter reasonably
              having a bearing on my qualifications for Participation in, or
              with, the Entity. I authorize my current and past professional
              liability carrier(s) to release my history of claims that have
              been made and/or are currently pending against me. I
              specifically waive written notice from any entities and
              individuals who provide information based upon this
              Authorization, Attestation and Release.</label
            >
          </div>
        </td>
      </tr>
      <tr>
        <td colspan="18" width="80%">
          <div class="form-group form-inline no-margin sideLabelAlignment">
            <label class="boldParagraph">
              Authorization of Release and Exchange of Disciplinary
              Information.I hereby further authorize any third party at which
              I currently have Participation or had Participation and/or each
              third party’s agents to release “Disciplinary Information,” as
              defined below, to the Entity and/or its Agent(s). I hereby
              further authorize the Agent(s) to release Disciplinary
              Information about any disciplinary action taken against me to
              its participating Entities at which I have Participation, and as
              may be otherwise required by law. As used herein, “Disciplinary
              Information” means information concerning: (I) any action taken
              by such health care organizations, their administrators, or
              their medical or other committees to revoke, deny, suspend,
              restrict, or condition my Participation or impose a corrective
              action plan; (ii) any other disciplinary action involving me,
              including, but not limited to, discipline in the employment
              context; or (iii) my resignation prior to the conclusion of any
              disciplinary proceedings or prior to the commencement of formal
              charges, but after I have knowledge that such formal charges
              were being (or are being) contemplated and/or were (or are) in
              preparation.
            </label>
          </div>
        </td>
      </tr>
      <tr>
        <td colspan="18" width="80%">
          <div class="form-group form-inline no-margin sideLabelAlignment">
            <label class="boldParagraph">
              Release from Liability.I release from all liability and hold
              harmless any Entity, its Agent(s), and any other third party for
              their acts performed in good faith and without malice unless
              such acts are due to the gross negligence or willful misconduct
              of the Entity, its Agent(s), or other third party in connection
              with the gathering, release and exchange of, and reliance upon,
              information used in accordance with this Authorization,
              Attestation and Release. I further agree not to sue any Entity,
              any Agent(s), or any other third party for their acts,
              defamation or any other claims based on statements made in good
              faith and without malice or misconduct of such Entity, Agent(s)
              or third party in connection with the credentialing process.
              This release shall be in addition to, and in no way shall limit,
              any other applicable immunities provided by law for peer review
              and credentialing activities.
            </label>
          </div>
        </td>
      </tr>
      <hr class="tablePersonal" />
      <h3 class="texasHThree">
        <b>Section III – Standard Authorization, Attestation and Release</b> –
        continued
      </h3>
      <tr>
        <td colspan="18" width="80%">
          <div class="form-group form-inline no-margin sideLabelAlignment">
            <label class="boldParagraph"
              >In this Authorization, Attestation and Release, all references
              to the Entity, its Agent(s), and⁄or other third party include
              their respective employees, directors, officers, advisors,
              counsel, and agents. The Entity or any of its affiliates or
              agents retains the right to allow access to the application
              information for purposes of a credentialing audit to customers
              and⁄or their auditors to the extent required in connection with
              an audit of the credentialing processes and provided that the
              customer and⁄or their auditor executes an appropriate
              confidentiality agreement. I understand and agree that this
              Authorization, Attestation and Release is irrevocable for any
              period during which I am an applicant for Participation at an
              Entity, a member of an Entity’s medical or health care staff, or
              a participating provider of an Entity. I agree to execute
              another form of consent if law or regulation limits the
              application of this irrevocable authorization. I understand that
              my failure to promptly provide another consent may be grounds
              for termination or discipline by the Entity in accordance with
              the applicable bylaws, rules, and regulations, and requirements
              of the Entity, or grounds for my termination of Participation at
              or with the Entity. I agree that information obtained in
              accordance with the provisions of this Authorization,
              Attestation and Release is not and will not be a violation of my
              privacy.
            </label>
          </div>
        </td>
      </tr>
      <tr>
        <td colspan="18" width="80%">
          <div class="form-group form-inline no-margin sideLabelAlignment">
            <label class="boldParagraph"
              >I certify that all information provided by me in my application
              is true, correct, and complete to the best of my knowledge and
              belief, and that I will notify the Entity and⁄or its Agent(s)
              within 10 days of any material changes to the information I have
              provided in my application or authorized to be released pursuant
              to the credentialing process. I understand that corrections to
              the application are permitted at any time prior to a
              determination of Participation by the Entity, and must be
              submitted on-line or in writing, and must be dated and signed by
              me (may be a written or an electronic signature). I understand
              and agree that any material misstatement or omission in the
              application may constitute grounds for withdrawal of the
              application from consideration; denial or revocation of
              Participation; and⁄or immediate suspension or termination of
              Participation. This action may be disclosed to the Entity and⁄or
              its Agent(s).
            </label>
          </div>
        </td>
      </tr>
      <tr>
        <td colspan="18" width="100%">
          <div class="form-group form-inline no-margin sideLabelAlignment">
            <label class="boldParagraph"
              >I further acknowledge that I have read and understand the
              foregoing Authorization, Attestation and Release. I understand
              and agree that a facsimile or photocopy of this Authorization,
              Attestation and Release shall be as effective as the original.
            </label>
          </div>
          <div class="vcenter">
            <label class="boldForImage">SIGNATURE<span
              class="mandatory">*</span></label>
            <div class="col-md-3 signaturePadding">
              <img
                *ngIf="!showOne"
                class="img"
                src="./assets/images/e_sign.png"
                alt="Credential my Doc"
                (click)="esignOpenOne()"
              />
              <img
                *ngIf="showOne"
                class="img eSignImageShow"
                [src]="esignOne"
                alt="Credential my Doc"
                (click)="esignOpenOne()"
              />
              <input
                type="hidden"
                name="cmd_11122"
                id="cmd_11122"
                formControlName="cmd_11122"
                [class.is-invalid]="(AbaBuildBlocksForms.controls['cmd_11122'].touched && AbaBuildBlocksForms.controls['cmd_11122'].invalid) || (AbaBuildBlocksForms.controls['cmd_11122'].invalid && isSubmit)"
                value="{{ finalImgPath }}"
              />
              <div class="invalid-feedback"
                  *ngIf="(AbaBuildBlocksForms.controls['cmd_11122'].touched && AbaBuildBlocksForms.controls['cmd_11122'].invalid) || (AbaBuildBlocksForms.controls['cmd_11122'].invalid && isSubmit)">
                                             Signature is required and cannot be empty
              </div>
            </div>
            <input
              style="width: 50%"
              class="form-control"
              id="cmd_0897"
              name="cmd_0897"
              formControlName="cmd_0897"
              type="text"
              data-id="full_name"
            />
            <label class="boldForLabel">NAME</label>
            <input
              style="width: 50%"
              class="form-control"
              id="cmd_0779"
              name="cmd_0779"
              formControlName="cmd_0779"
              type="text"
              data-id="NPI"
            />
            <label class="boldForLabel"
              >Last 4 digits of SSN or NPI (PLEASE PRINT OR TYPE)
            </label>
            <div class="form-group col-md-8 input-group">
              <input
                style="width: 50%"
                class="form-control onboard  datepicker"
                ngbDatepicker
                #d63="ngbDatepicker"
                [class.is-invalid]="(AbaBuildBlocksForms.controls['cmd_0310'].touched && AbaBuildBlocksForms.controls['cmd_0310'].invalid ) || (AbaBuildBlocksForms.controls['cmd_0310'].invalid && isSubmit)"
                id="cmd_0310"
                name="cmd_0310"
                formControlName="cmd_0310"
                (blur)="clearInputIfInvalidMandi('cmd_0310')" 
                type="text"
                data-id="Text5.1.2"
              />
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary calendar"
                  (click)="d63.toggle()"
                  type="button"
                >
                  <i class="fas fa-calendar-alt"></i>
                </button>
              </div>
            </div>
            <div class="text-danger" *ngIf="(AbaBuildBlocksForms.controls['cmd_0310'].invalid&&AbaBuildBlocksForms.controls['cmd_0310'].touched) && (AbaBuildBlocksForms.controls['cmd_0310'].value==''|| AbaBuildBlocksForms.controls['cmd_0310'].value==null) " >
              <small >
              required
              </small>

          </div>
          <div>
            <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('cmd_0310').errors?.invalidDateFormat">
              Only Date Format is allowed(MM/DD/YYYY)                </small>
            <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('cmd_0310').errors?.invalidMonth">
            Month is out of range
            </small>
            <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('cmd_0310').errors?.invalidDateyear">
              year is out of range(1900-2099)                  </small>
              <small class="text-danger" *ngIf="AbaBuildBlocksForms.get('cmd_0310').errors?.invalidDateRange">
                date is out of range
                </small>
              </div>
            <label class="boldForLabel">DATE (MM⁄DD⁄ YYYY)<span
              class="mandatory">*</span> </label>
          </div>
        </td>
      </tr>

        
            
            
        
    </form>
  </div>  


  <div class="modal-footer mt-4">
      <button *ngIf="formData.receivedForm" type="button" class="btn customOrange float-right"
      (click)="saveForm('approve')">Approve</button>
      <button *ngIf="formData.submitForm" type="submit" class="btn btn customOrange float-right"
      (click)="saveForm('submit')">Submit</button>
      <button *ngIf="formData.submitForm" type="button" class="btn btn customOrange float-right"
      (click)="saveForm('save')">Save</button>
      <button *ngIf="formData.previewForm" type="button" class="btn customOrange float-right" (click)="close()">Close</button>
  </div>
</div>    

  