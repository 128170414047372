<div class="modal-header">
    <h4 class="modal-title">Medical Staff Verification Letter</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="activeModal.close()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="details">
      <div class="row">
        <div class="col-md-12">
          <h5>Medical Staff Verification Details</h5>
        </div>
      </div>
  
      <div class="row">
        <div class="col-md-12">{{ date }}</div>
      </div>
  
      <div class="row">
        <div class="col-md-12">{{ firstName }}</div>
      </div>
  
      <div class="row">
        <div class="col-md-12">{{ lastName }}</div>
      </div>
  
      <div class="row">
        <div class="col-md-12">{{ phone }}</div>
      </div>
  
      <!-- {{searchData && searchData.first_name}}  -->
      <div class="row">
        <div class="col-md-5">TO WHOM IT MAY CONCERN</div>
      </div>
  
      <div class="row">
        <div class="col-md-1">RE:</div>
        <div class="col-md-8">
          {{ searchData.first_name }}{{ searchData.last_name }}
        </div>
      </div>
  
      <div class="row">
        <div class="col-md-3">Original On Staff Date</div>
        <div class="col-md-1">:</div>
        <div class="col-md-8">{{ searchData.inital_appointment_date }}</div>
      </div>
      <div class="row">
        <div class="col-md-3">Department</div>
        <div class="col-md-1">:</div>
        <div class="col-md-8">{{ searchData.speciality }}</div>
      </div>
      <div class="row">
        <div class="col-md-3">Specialty</div>
        <div class="col-md-1">:</div>
        <div class="col-md-8">{{ searchData.speciality }}</div>
      </div>
      <div class="row">
        <div class="col-md-3">Current/Most Recent Appt Dates</div>
        <div class="col-md-1">:</div>
        <div class="col-md-8">
          {{ searchData.last_reappointment_date }} -
          {{ searchData.next_reappointment_date }}
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">Termination Date</div>
        <div class="col-md-1">:</div>
        <div class="col-md-8">{{ searchData.privilege_termation_date }}</div>
      </div>
      <div class="row">
        <div class="col-md-3">Date of Birth</div>
        <div class="col-md-1">:</div>
        <div class="col-md-8">{{ searchData.dob }}</div>
      </div>
      <div class="row">
        <div class="col-md-3">Status</div>
        <div class="col-md-1">:</div>
        <div class="col-md-8">{{ searchData.staff_status }}</div>
      </div>
  
      <p>
        Covenant Technology Group can confirm that this practitioner was/is in
        good standing with no disciplinary actions taken and no restrictions on
        his/her privileges. All medical staff members are credentialed according
        to the Joint Commission Standards or HRSA. This document shall be
        considered as a Primary Source.
      </p>
      <div class="row">
        <div class="col-md-8">
          Sincerely,
        </div>
      </div>
  
      <div class="row">
        <div class="col-md-8">
          Medical Staff Coordinator
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn customOrange" (click)="download()">
      Download Pdf
    </button>
    <button type="button" class="btn customOrange" (click)="activeModal.close()">
      Close
    </button>
  </div>
  