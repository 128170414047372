<div id="lifeWaysUniversalStyle">
  <div class="cotainer-fluid mx-1">
    <div class="header">
      <div class="">
        <img class="img-logo" src="./assets/images/cmd_universal_credentialing.png" alt="Credential my Doc">
      </div>
      <div>
        <h2 class="mainHeadingStyle">LifeWays’ Employee Practitioner Credentialing Application</h2>
      </div>
    </div>
    <div class="section">
      <form [formGroup]="lifeWaysUniversal">
        <div class="row">
          <div class="col-md-12">
            <h2 class="checkBoxHeading"><b><u>Checklist of Materials to submit for CredentialMyDoc</u></b></h2>
          </div>
          <div class="form-group form-inline no-margin col-md-12 checkBoxAlign">
            <h3 class="normalParagraph"><b>Ensure the documents listed below have been submitted to your Agency
                Credentialing Coordinator.</b></h3>
          </div>
          <div class="col-md-12 checkBoxHeight">
            <input type="checkbox" id="cmd_2500001" name="cmd_2500001" formControlName="cmd_2500001" value="1">
            <label for="cmd_2500001">Official College/University Transcripts (Initial credentialing only)</label><br>
            <input type="checkbox" id="cmd_2500002" name="cmd_2500002" formControlName="cmd_2500002" value="1">
            <label for="cmd_2500002">Copies of licensure</label><br>
            <input type="checkbox" id="cmd_2500003" name="cmd_2500003" formControlName="cmd_2500003" value="1">
            <label for="cmd_2500003">Copies of applicable certifications</label><br>
            <input type="checkbox" id="cmd_2500004" name="cmd_2500004" formControlName="cmd_2500004" value="1">
            <label for="cmd_2500004">A current Curriculum Vitae/Resume (Initial credentialing only)</label><br>
            <input type="checkbox" id="cmd_2500005" name="cmd_2500005" formControlName="cmd_2500005" value="1">
            <label for="cmd_2500005">List of continuing education programs attended over the past twenty-four (24)
              months and CMEs/CEUs/Clock Hours received (re-credentialing only)</label><br>
            <input type="checkbox" id="cmd_2500006" name="cmd_2500006" formControlName="cmd_2500006" value="1">
            <label for="cmd_2500006">(If you have other insurance coverage than what LifeWays provides) A copy of any
              current malpractice insurance. *Optional</label><br>
          </div>
          <br><br><br><br><br><br>
          <div class="form-group form-inline no-margin col-md-12 checkBoxAlign">
            <h3 class="normalParagraph"><b>The following documents are included in this application.</b></h3>
          </div>
          <div class="col-md-12 checkBoxHeight">
            <input type="checkbox" id="cmd_2500007" name="cmd_2500007" formControlName="cmd_2500007" value="1">
            <label for="cmd_2500007">Completed Disciplinary Actions Form (Page 7)</label><br>
            <input type="checkbox" id="cmd_2500008" name="cmd_2500008" formControlName="cmd_2500008" value="1">
            <label for="cmd_2500008">Signed attestation form (page 8)</label><br>
            <input type="checkbox" id="cmd_2500009" name="cmd_2500009" formControlName="cmd_2500009" value="1">
            <label for="cmd_2500009">Signed Criminal Background Check Authorization (Attachment 1 – Page 9)</label><br>
            <input type="checkbox" id="cmd_2500010" name="cmd_2500010" formControlName="cmd_2500010" value="1">
            <label for="cmd_2500010">Malpractice Information Form, if applicable (Attachment 2 – Page 10)</label><br>
            <input type="checkbox" id="cmd_2500011" name="cmd_2500011" formControlName="cmd_2500011" value="1">
            <label for="cmd_2500011">Signed Training Acknowledgement form (Attachment 3 – page 11)</label><br>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <h3 class="checkBoxHeading"><b>APPLICATION FOR PRIVILEGED STAFF MEMBERSHIP</b></h3>
          </div>
          <div class="form-group form-inline no-margin col-md-12">
            <h4 class="hThreeStyle"><b>General Instructions:</b></h4>
          </div>
          <div class="col-md-12">
            <ul class="ulHeading">
              <li>Please TYPE OR PRINT all responses.</li>
              <li>Attach additional sheets if necessary.</li>
              <li>Submission of evidence of appropriate education/degree completion is required for initial application.
                Official transcripts must be sent directly from the educational facility.</li>
              <li>Application must be complete, including attachment of all requested supporting documentation.</li>
            </ul>
          </div>
        </div><br><br><br>
        <div class="row sideHeadingAlignment">
          <div class="form-group form-inline no-margin col-md-12">
            <h4 class="hThreeStyle"><b><u>PERSONAL INFORMATION</u></b></h4>
          </div>
          <div class="col-md-1">
            <label class="formLabel">LastName:</label>
          </div>
          <div class="col-md-3">
            <input class="form-control" id="9" name="9" formControlName="9"
              [class.is-invalid]="(lifeWaysUniversal.controls['9'].touched && lifeWaysUniversal.controls['9'].invalid) || (lifeWaysUniversal.controls['9'].invalid && isSubmit)"
              type="text" placeholder="Last Name" data-id="lname" (change)="onChangeLastName($event)">
            <div class="formLabel col-md-11"
              *ngIf="lifeWaysUniversal.controls['9'].touched && lifeWaysUniversal.controls['9'].invalid">
              <small class="text-danger" *ngIf="lifeWaysUniversal.controls['9'].errors?.required">
                required
              </small>
            </div>
          </div>
          <div class="col-md-1">
            <label class="formLabel">FirstName:</label>
          </div>
          <div class="col-md-3">
            <input class="form-control" id="8" name="8" formControlName="8"
              [class.is-invalid]="(lifeWaysUniversal.controls['8'].touched && lifeWaysUniversal.controls['8'].invalid) || (lifeWaysUniversal.controls['8'].invalid && isSubmit)"
              type="text" placeholder="First Name" data-id="fname" (change)="onChangeFirstName($event)">
            <div class="formLabel col-md-11"
              *ngIf="lifeWaysUniversal.controls['8'].touched && lifeWaysUniversal.controls['8'].invalid">
              <small class="text-danger" *ngIf="lifeWaysUniversal.controls['8'].errors?.required">
                required
              </small>
            </div>
          </div>
          <div class="col-md-1">
            <label class="formLabel">MI:</label>
          </div>
          <div class="col-md-2">
            <input class="form-control" id="809" name="809" formControlName="809"
              [class.is-invalid]="(lifeWaysUniversal.controls['809'].touched && lifeWaysUniversal.controls['809'].invalid) || (lifeWaysUniversal.controls['809'].invalid && isSubmit)"
              type="text" placeholder="MI" data-id="middle_initial" (change)="onChangeMiddleName($event)">
            <div class="formLabel col-md-11"
              *ngIf="lifeWaysUniversal.controls['809'].touched && lifeWaysUniversal.controls['809'].invalid">
              <small class="text-danger" *ngIf="lifeWaysUniversal.controls['809'].errors?.required">
                required
              </small>
            </div>
          </div>
        </div>
        <div class="row sideHeadingAlignment">
          <div class="col-md-1">
            <label class="formLabel">Email:</label>
          </div>
          <div class="col-md-10">
            <input class="form-control" id="759" name="759" formControlName="759"
              [class.is-invalid]="(lifeWaysUniversal.controls['759'].touched && lifeWaysUniversal.controls['759'].invalid) || (lifeWaysUniversal.controls['759'].invalid && isSubmit)"
              type="text" placeholder="Email" data-id="email">
            <div class="formLabel col-md-11"
              *ngIf="lifeWaysUniversal.controls['759'].touched && lifeWaysUniversal.controls['759'].invalid">
              <small class="text-danger" *ngIf="lifeWaysUniversal.controls['759'].errors?.required">
                Email is required
              </small>
              <small class="text-danger" *ngIf="lifeWaysUniversal.controls['759'].errors?.pattern">
               Enter a valid Email
              </small>
            </div>
          </div>
        </div>
        <div class="row sideHeadingAlignment">
          <div class="col-md-5">
            <label class="formLabel">Signature Credentials (Ex: John Doe, M.A., LMSW):</label>
          </div>
          <div class="col-md-6">
            <div class="form-group selectDropTwo suffix">
              <ng-select [items]="credentials" bindLabel="name" placeholder="Select Credential" bindValue="id"
                formControlName="822"
                [class.is-invalid]="(lifeWaysUniversal.controls['822'].touched && lifeWaysUniversal.controls['822'].invalid) || (lifeWaysUniversal.controls['822'].invalid && isSubmit)"
                (change)="onChangeDropDownName($event)">
              </ng-select>
              <div class="formLabel col-md-11"
                *ngIf="lifeWaysUniversal.controls['822'].touched && lifeWaysUniversal.controls['822'].invalid">
                <small class="text-danger" *ngIf="lifeWaysUniversal.controls['822'].errors?.required">
                  required
                </small>
              </div>
            </div>
          </div>
        </div>
        <div class="row sideHeadingAlignment">
          <div class="col-md-5">
            <label class="formLabel">*National Provider Identifier (NPI) Number (required):</label>
          </div>
          <div class="col-md-6">
            <input class="form-control" id="1634" name="1634" formControlName="1634"
              [class.is-invalid]="(lifeWaysUniversal.controls['1634'].touched && lifeWaysUniversal.controls['1634'].invalid) || (lifeWaysUniversal.controls['1634'].invalid && isSubmit)"
              type="text" data-id="npi">
            <div class="formLabel col-md-11"
              *ngIf="lifeWaysUniversal.controls['1634'].touched && lifeWaysUniversal.controls['1634'].invalid">
              <small class="text-danger" *ngIf="lifeWaysUniversal.controls['1634'].errors?.required">
                required
              </small>
            </div>
          </div>
        </div>
        <div class="row">
          <label class="formLabelPadding">(Staff Psychiatrist and Medical Director Only)</label>
        </div>
        <div class="row sideHeadingAlignment">
          <div class="col-md-5">
            <label class="formLabel">Unique Physician Identification Number (UPIN):</label>
          </div>
          <div class="col-md-6">
            <input class="form-control" id="819" name="819" formControlName="819" type="text" data-id="upin">
          </div>
        </div>
        <div class="row sideHeadingAlignment">
          <div class="col-md-2">
            <label class="formLabel">Medicare Number: </label>
          </div>
          <div class="col-md-9">
            <input class="form-control" id="325" name="325" formControlName="325" type="text" data-id="medicare_number">
          </div>
        </div>
        <br><br><br><br>
        <div class="row">
          <div class="form-group form-inline no-margin col-md-12">
            <h4 class="hThreeStyle"><b><u>CREDENTIALS</u></b></h4>
          </div>
        </div>
        <div class="row">
          <div class="form-group form-inline no-margin col-md-12">
            <h4 class="hThreeStylePadding"><b>Licensure - </b>Please indicate all licenses held. Physicians, please
              include Board Certifications.<b><u><i>Submit copies</i></u></b></h4>
          </div>
        </div>
        <table class="southform_table" class="tableStructure">
          <tr>
            <td colspan="3" bgcolor="#a6a6a6">
              <div>
                <label class="tableInputHeading">License</label>
              </div>
            </td>
            <td colspan="3" bgcolor="#a6a6a6">
              <div>
                <label class="tableInputHeading">License #</label>
              </div>
            </td>
            <td colspan="3" bgcolor="#a6a6a6">
              <div>
                <label class="tableInputHeading">Expiration Date</label>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan=3>
              <div class="col-md-3 inputBoxPadding">
                <div class="form-group form-inline no-margin inputBoxPaddingLeft">
                  <input class="form-control" id="cmd_2500042" name="cmd_2500042" formControlName="cmd_2500042"
                    type="text" placeholder="License Number" data-id="license">
                </div>
              </div>
            </td>
            <td colspan="3">
              <div class="col-md-3 inputBoxPadding">
                <div class="form-group form-inline no-margin inputBoxPaddingLeft">
                  <input class="form-control" id="293" name="293" formControlName="293" type="text" data-id="lname">
                </div>
              </div>
            </td>
            <td colspan="3">
              <div class="input-group">
                <input class="form-control" formControlName="295" 
                (blur)="clearInputIfInvalid('295')" name="dp" placeholder="" ngbDatepicker
                  #x1="ngbDatepicker">
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary calendar" (click)="x1.toggle()" type="button"><i
                      class="fas fa-calendar-alt"></i></button>
                </div>
              </div>
              <div  *ngIf="lifeWaysUniversal.get('295').invalid ">
                <small class="text-danger" *ngIf="lifeWaysUniversal.get('295').errors?.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY)
                </small>
                <small class="text-danger" *ngIf="lifeWaysUniversal.get('295').errors?.invalidMonth">
                Month is out of range
                </small>
                <small class="text-danger" *ngIf="lifeWaysUniversal.get('295').errors?.invalidDateyear">
                  year is out of range(1900-2099)                      </small>
                  <small class="text-danger" *ngIf="lifeWaysUniversal.get('295').errors?.invalidDateRange">
                    date is out of range
                    </small>
                </div>
            </td>
          </tr>
          <tr>
            <td colspan="3">
              <div class="col-md-3 inputBoxPadding">
                <div class="form-group form-inline no-margin inputBoxPaddingLeft">
                  <input class="form-control" id="cmd_2500043" name="cmd_2500043" formControlName="cmd_2500043"
                    type="text" placeholder="Additional License Number" data-id="alt_license">
                </div>
              </div>
            </td>
            <td colspan="3">
              <div class="col-md-3 inputBoxPadding">
                <div class="form-group form-inline no-margin inputBoxPaddingLeft">
                  <input class="form-control" id="300" name="300" formControlName="300" type="text" data-id="lname">
                </div>
              </div>
            </td>
            <td colspan="3">
              <div class="input-group">
                <input class="form-control" (blur)="clearInputIfInvalid('308')" formControlName="308" name="dp" placeholder="" ngbDatepicker
                  #x2="ngbDatepicker">
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary calendar" (click)="x2.toggle()" type="button"><i
                      class="fas fa-calendar-alt"></i></button>
                </div>
                
              </div>
              <div  *ngIf="lifeWaysUniversal.get('308').invalid ">
                <small class="text-danger" *ngIf="lifeWaysUniversal.get('308').errors?.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY)
                </small>
                <small class="text-danger" *ngIf="lifeWaysUniversal.get('308').errors?.invalidMonth">
                Month is out of range
                </small>
                <small class="text-danger" *ngIf="lifeWaysUniversal.get('308').errors?.invalidDateyear">
                  year is out of range(1900-2099)                      </small>
                  <small class="text-danger" *ngIf="lifeWaysUniversal.get('308').errors?.invalidDateRange">
                    date is out of range
                    </small>
                </div>
            </td>
          </tr>
          <tr>
            <td colspan="3">
              <div class="col-md-3 inputBoxPadding">
                <div class="form-group form-inline no-margin inputBoxPaddingLeft">
                  <input class="form-control" id="cmd_2500045" name="cmd_2500045" formControlName="cmd_2500045"
                    placeholder="Previous License Number" type="text" data-id="previous_medical_license_number">
                </div>
              </div>
            </td>
            <td colspan="3">
              <div class="col-md-3 inputBoxPadding">
                <div class="form-group form-inline no-margin inputBoxPaddingLeft">
                  <input class="form-control" id="999" name="999" formControlName="999" type="text" data-id="lname">
                </div>
              </div>
            </td>
            <td colspan="3">
              <div class="input-group">
                <input class="form-control" formControlName="1004" (blur)="clearInputIfInvalid('1004')" name="dp" placeholder="" ngbDatepicker
                  #x3="ngbDatepicker">
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary calendar" (click)="x3.toggle()" type="button"><i
                      class="fas fa-calendar-alt"></i></button>
                </div>
              </div>
              <div  *ngIf="lifeWaysUniversal.get('1004').invalid ">
                <small class="text-danger" *ngIf="lifeWaysUniversal.get('1004').errors?.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY)
                </small>
                <small class="text-danger" *ngIf="lifeWaysUniversal.get('1004').errors?.invalidMonth">
                Month is out of range
                </small>
                <small class="text-danger" *ngIf="lifeWaysUniversal.get('1004').errors?.invalidDateyear">
                  year is out of range(1900-2099)                      </small>
                  <small class="text-danger" *ngIf="lifeWaysUniversal.get('1004').errors?.invalidDateRange">
                    date is out of range
                    </small>
                </div>
            </td>
          </tr>
          <tr>
            <td colspan="3">
              <div class="col-md-3 inputBoxPadding">
                <div class="form-group form-inline no-margin inputBoxPaddingLeft">
                  <input class="form-control" id="cmd_2500047" name="cmd_2500047" formControlName="cmd_2500047"
                    type="text" data-id="lname">
                </div>
              </div>
            </td>
            <td colspan="3">
              <div class="col-md-3 inputBoxPadding">
                <div class="form-group form-inline no-margin inputBoxPaddingLeft">
                  <input class="form-control" id="1196" name="1196" formControlName="1196" type="text" data-id="lname">
                </div>
              </div>
            </td>
            <td colspan="3">
              <div class="input-group">
                <input class="form-control" formControlName="1199" (blur)="clearInputIfInvalid('1199')" name="dp" placeholder="" ngbDatepicker
                  #x4="ngbDatepicker">
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary calendar" (click)="x4.toggle()" type="button"><i
                      class="fas fa-calendar-alt"></i></button>
                </div>
              </div>
              <div  *ngIf="lifeWaysUniversal.get('1199').invalid ">
                <small class="text-danger" *ngIf="lifeWaysUniversal.get('1199').errors?.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY)
                </small>
                <small class="text-danger" *ngIf="lifeWaysUniversal.get('1199').errors?.invalidMonth">
                Month is out of range
                </small>
                <small class="text-danger" *ngIf="lifeWaysUniversal.get('1199').errors?.invalidDateyear">
                  year is out of range(1900-2099)                      </small>
                  <small class="text-danger" *ngIf="lifeWaysUniversal.get('1199').errors?.invalidDateRange">
                    date is out of range
                    </small>
                </div>
            </td>
          </tr>
        </table>
        <div class="row">
          <div class="form-group form-inline no-margin col-md-12">
            <h4 class="hThreeStylePadding"><b>Certifications - </b>Please indicate all certifications
              held.<b><u><i>Submit copies</i></u></b></h4>
          </div>
        </div>
        <table class="southform_table" class="tableStructure">
          <tr>
            <td colspan="3" bgcolor="#a6a6a6">
              <div>
                <label class="tableInputHeading">Certification</label>
              </div>
            </td>
            <td colspan="3" bgcolor="#a6a6a6">
              <div>
                <label class="tableInputHeading">#</label>
              </div>
            </td>
            <td colspan="3" bgcolor="#a6a6a6">
              <div>
                <label class="tableInputHeading">Expiration Date</label>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan=3>
              <div class="col-md-3 inputBoxPadding">
                <div class="col-md-6">
                  <div class="form-group selectDropTwo suffix">
                    <ng-select [items]="mediCertification" bindLabel="name" placeholder="Select MediCertification"
                      bindValue="id" formControlName="cmd_900001">
                    </ng-select>
                  </div>
                </div>
              </div>
            </td>
            <td colspan="3">
              <div class="col-md-3 inputBoxPadding" class="">
                <div class="form-group form-inline no-margin inputBoxPaddingLeft">
                  <input class="form-control" id="cmd_900002" name="cmd_900002" formControlName="cmd_900002" type="text"
                    data-id="lname">
                </div>
              </div>
            </td>
            <td colspan="3">
              <div class="input-group">
                <input class="form-control" formControlName="cmd_900003" (blur)="clearInputIfInvalid('cmd_900003')" name="dp" placeholder="" ngbDatepicker
                  #x5="ngbDatepicker">
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary calendar" (click)="x5.toggle()" type="button"><i
                      class="fas fa-calendar-alt"></i></button>
                </div>
              </div>
              <div  *ngIf="lifeWaysUniversal.get('cmd_900003').invalid ">
                <small class="text-danger" *ngIf="lifeWaysUniversal.get('cmd_900003').errors?.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY)
                </small>
                <small class="text-danger" *ngIf="lifeWaysUniversal.get('cmd_900003').errors?.invalidMonth">
                Month is out of range
                </small>
                <small class="text-danger" *ngIf="lifeWaysUniversal.get('cmd_900003').errors?.invalidDateyear">
                  year is out of range(1900-2099)                      </small>
                  <small class="text-danger" *ngIf="lifeWaysUniversal.get('cmd_900003').errors?.invalidDateRange">
                    date is out of range
                    </small>
                </div>
            </td>
          </tr>
          <tr>
            <td colspan="3">
              <div class="col-md-3 inputBoxPadding">
                <div class="col-md-6">
                  <div class="form-group selectDropTwo suffix">
                    <ng-select [items]="mediCertification" bindLabel="name" placeholder="Select MediCertification"
                      bindValue="id" formControlName="cmd_900004">
                    </ng-select>
                  </div>
                </div>
              </div>
            </td>
            <td colspan="3">
              <div class="col-md-3 inputBoxPadding">
                <div class="form-group form-inline no-margin inputBoxPaddingLeft">
                  <input class="form-control" id="cmd_900005" name="cmd_900005" formControlName="cmd_900005" type="text"
                    data-id="lname">
                </div>
              </div>
            </td>
            <td colspan="3">
              <div class="input-group">
                <input class="form-control" formControlName="cmd_900006" (blur)="clearInputIfInvalid('cmd_900006')" name="dp" placeholder="" ngbDatepicker
                  #x6="ngbDatepicker">
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary calendar" (click)="x6.toggle()" type="button"><i
                      class="fas fa-calendar-alt"></i></button>
                </div>
              </div>
              <div  *ngIf="lifeWaysUniversal.get('cmd_900006').invalid ">
                <small class="text-danger" *ngIf="lifeWaysUniversal.get('cmd_900006').errors?.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY)
                </small>
                <small class="text-danger" *ngIf="lifeWaysUniversal.get('cmd_900006').errors?.invalidMonth">
                Month is out of range
                </small>
                <small class="text-danger" *ngIf="lifeWaysUniversal.get('cmd_900006').errors?.invalidDateyear">
                  year is out of range(1900-2099)                      </small>
                  <small class="text-danger" *ngIf="lifeWaysUniversal.get('cmd_900006').errors?.invalidDateRange">
                    date is out of range
                    </small>
                </div>
            </td>
          </tr>
          <tr>
            <td colspan="3">
              <div class="col-md-3 inputBoxPadding">
                <div class="col-md-6">
                  <div class="form-group selectDropTwo suffix">
                    <ng-select [items]="mediCertification" bindLabel="name" placeholder="Select MediCertification"
                      bindValue="id" formControlName="cmd_900007">
                    </ng-select>
                  </div>
                </div>
              </div>
            </td>
            <td colspan="3">
              <div class="col-md-3 inputBoxPadding">
                <div class="form-group form-inline no-margin inputBoxPaddingLeft">
                  <input class="form-control" id="cmd_900008" name="cmd_900008" formControlName="cmd_900008" type="text"
                    data-id="lname">
                </div>
              </div>
            </td>
            <td colspan="3">
              <div class="input-group">
                <input class="form-control" formControlName="cmd_900009" (blur)="clearInputIfInvalid('cmd_900009')" name="dp" placeholder="" ngbDatepicker
                  #x7="ngbDatepicker">
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary calendar" (click)="x7.toggle()" type="button"><i
                      class="fas fa-calendar-alt"></i></button>
                </div>
              </div>
              <div  *ngIf="lifeWaysUniversal.get('cmd_900009').invalid ">
                <small class="text-danger" *ngIf="lifeWaysUniversal.get('cmd_900009').errors?.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY)
                </small>
                <small class="text-danger" *ngIf="lifeWaysUniversal.get('cmd_900009').errors?.invalidMonth">
                Month is out of range
                </small>
                <small class="text-danger" *ngIf="lifeWaysUniversal.get('cmd_900009').errors?.invalidDateyear">
                  year is out of range(1900-2099)                      </small>
                  <small class="text-danger" *ngIf="lifeWaysUniversal.get('cmd_900009').errors?.invalidDateRange">
                    date is out of range
                    </small>
                </div>
            </td>
          </tr>
          <tr>
            <td colspan="3">
              <div class="col-md-3 inputBoxPadding">
                <div class="col-md-6">
                  <div class="form-group selectDropTwo suffix">
                    <ng-select [items]="mediCertification" bindLabel="name" placeholder="Select MediCertification"
                      bindValue="id" formControlName="cmd_9000010">
                    </ng-select>
                  </div>
                </div>
              </div>
            </td>
            <td colspan="3">
              <div class="col-md-3 inputBoxPadding">
                <div class="form-group form-inline no-margin inputBoxPaddingLeft">
                  <input class="form-control" id="cmd_9000011" name="cmd_9000011" formControlName="cmd_9000011"
                    type="text" data-id="lname">
                </div>
              </div>
            </td>
            <td colspan="3">
              <div class="input-group">
                <input class="form-control" formControlName="cmd_9000012" (blur)="clearInputIfInvalid('cmd_9000012')" name="dp" placeholder="" ngbDatepicker
                  #x8="ngbDatepicker">
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary calendar" (click)="x8.toggle()" type="button"><i
                      class="fas fa-calendar-alt"></i></button>
                </div>
              </div>
              <div  *ngIf="lifeWaysUniversal.get('cmd_9000012').invalid ">
                <small class="text-danger" *ngIf="lifeWaysUniversal.get('cmd_9000012').errors?.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY)
                </small>
                <small class="text-danger" *ngIf="lifeWaysUniversal.get('cmd_9000012').errors?.invalidMonth">
                Month is out of range
                </small>
                <small class="text-danger" *ngIf="lifeWaysUniversal.get('cmd_9000012').errors?.invalidDateyear">
                  year is out of range(1900-2099)                      </small>
                  <small class="text-danger" *ngIf="lifeWaysUniversal.get('cmd_9000012').errors?.invalidDateRange">
                    date is out of range
                    </small>
                </div>
            </td>
          </tr>
          <tr>
            <td colspan="3">
              <div class="col-md-3 inputBoxPadding">
                <div class="col-md-6">
                  <div class="form-group selectDropTwo suffix">
                    <ng-select [items]="mediCertification" bindLabel="name" placeholder="Select MediCertification"
                      bindValue="id" formControlName="cmd_9000013">
                    </ng-select>
                  </div>
                </div>
              </div>
            </td>
            <td colspan="3">
              <div class="col-md-3 inputBoxPadding">
                <div class="form-group form-inline no-margin inputBoxPaddingLeft">
                  <input class="form-control" id="cmd_9000014" name="cmd_9000014" formControlName="cmd_9000014"
                    type="text" data-id="lname">
                </div>
              </div>
            </td>
            <td colspan="3">
              <div class="input-group">
                <input class="form-control" formControlName="cmd_9000015" (blur)="clearInputIfInvalid('cmd_9000015')" name="dp" placeholder="" ngbDatepicker
                  #x9="ngbDatepicker">
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary calendar" (click)="x9.toggle()" type="button"><i
                      class="fas fa-calendar-alt"></i></button>
                </div>
              </div>
              <div  *ngIf="lifeWaysUniversal.get('cmd_9000015').invalid ">
                <small class="text-danger" *ngIf="lifeWaysUniversal.get('cmd_9000015').errors?.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY)
                </small>
                <small class="text-danger" *ngIf="lifeWaysUniversal.get('cmd_9000015').errors?.invalidMonth">
                Month is out of range
                </small>
                <small class="text-danger" *ngIf="lifeWaysUniversal.get('cmd_9000015').errors?.invalidDateyear">
                  year is out of range(1900-2099)                      </small>
                  <small class="text-danger" *ngIf="lifeWaysUniversal.get('cmd_9000015').errors?.invalidDateRange">
                    date is out of range
                    </small>
                </div>
            </td>
          </tr>
          <tr>
            <td colspan="3">
              <div class="col-md-3 inputBoxPadding">
                <div class="col-md-6">
                  <div class="form-group selectDropTwo suffix">
                    <ng-select [items]="mediCertification" bindLabel="name" placeholder="Select MediCertification"
                      bindValue="id" formControlName="cmd_9000016">
                    </ng-select>
                  </div>
                </div>
              </div>
            </td>
            <td colspan="3">
              <div class="col-md-3 inputBoxPadding">
                <div class="form-group form-inline no-margin inputBoxPaddingLeft">
                  <input class="form-control" id="cmd_9000017" name="cmd_9000017" formControlName="cmd_9000017"
                    type="text" data-id="lname">
                </div>
              </div>
            </td>
            <td colspan="3">
              <div class="input-group">
                <input class="form-control" formControlName="cmd_9000018" (blur)="clearInputIfInvalid('cmd_9000018')" name="dp" placeholder="" ngbDatepicker
                  #x10="ngbDatepicker">
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary calendar" (click)="x10.toggle()" type="button"><i
                      class="fas fa-calendar-alt"></i></button>
                </div>
              </div>
              <div  *ngIf="lifeWaysUniversal.get('cmd_9000018').invalid ">
                <small class="text-danger" *ngIf="lifeWaysUniversal.get('cmd_9000018').errors?.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY)
                </small>
                <small class="text-danger" *ngIf="lifeWaysUniversal.get('cmd_9000018').errors?.invalidMonth">
                Month is out of range
                </small>
                <small class="text-danger" *ngIf="lifeWaysUniversal.get('cmd_9000018').errors?.invalidDateyear">
                  year is out of range(1900-2099)                      </small>
                  <small class="text-danger" *ngIf="lifeWaysUniversal.get('cmd_9000018').errors?.invalidDateRange">
                    date is out of range
                    </small>
                </div>
            </td>
          </tr>
        </table>
        <div class="row">
          <div class="form-group form-inline no-margin col-md-12">
            <h4 class="hThreeStylePadding"><b>Education - </b>Please indicate all degrees held, name of educational
              institution and year degree granted.<b><u><i>Submit copies of degree(s) if initial credentialing.
                  </i></u></b></h4>
          </div>
        </div>
        <table class="southform_table" class="tableStructure">
          <tr>
            <td colspan="3" bgcolor="#a6a6a6">
              <div>
                <label class="tableInputHeading">Degree</label>
              </div>
            </td>
            <td colspan="3" bgcolor="#a6a6a6">
              <div>
                <label class="tableInputHeading">Educational Institution</label>
              </div>
            </td>
            <td colspan="3" bgcolor="#a6a6a6">
              <div>
                <label class="tableInputHeading">Year Degree Granted</label>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan=3>
              <div class="col-md-3 inputBoxPadding">
                <div class="form-group form-inline no-margin inputBoxPaddingLeft">
                  <div class="form-group selectDropTwo suffix">
                    <ng-select [items]="degree" bindLabel="name" placeholder="Select Degree" bindValue="id"
                      formControlName="512">
                    </ng-select>
                  </div>
                </div>
              </div>
            </td>
            <td colspan="3">
              <div class="col-md-3 inputBoxPadding">
                <div class="form-group form-inline no-margin inputBoxPaddingLeft">
                  <input class="form-control" id="503" name="503" formControlName="503" placeholder="Undergrad School"
                    type="text" data-id="ug_school">
                </div>
              </div>
            </td>
            <td colspan="3">
              <div class="input-group">
                <input class="form-control" formControlName="511" (blur)="clearInputIfInvalid('511')" name="dp" placeholder="" ngbDatepicker
                  #x11="ngbDatepicker">
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary calendar" (click)="x11.toggle()" type="button"><i
                      class="fas fa-calendar-alt"></i></button>
                </div>
              </div>
              <div  *ngIf="lifeWaysUniversal.get('511').invalid ">
                <small class="text-danger" *ngIf="lifeWaysUniversal.get('511').errors?.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY)
                </small>
                <small class="text-danger" *ngIf="lifeWaysUniversal.get('511').errors?.invalidMonth">
                Month is out of range
                </small>
                <small class="text-danger" *ngIf="lifeWaysUniversal.get('511').errors?.invalidDateyear">
                  year is out of range(1900-2099)                      </small>
                  <small class="text-danger" *ngIf="lifeWaysUniversal.get('511').errors?.invalidDateRange">
                    date is out of range
                    </small>
                </div>
            </td>
          </tr>
          <tr>
            <td colspan="3">
              <div class="col-md-3 inputBoxPadding">
                <div class="form-group form-inline no-margin inputBoxPaddingLeft">
                  <div class="form-group selectDropTwo suffix">
                    <ng-select [items]="degree" bindLabel="name" placeholder="Select Degree" bindValue="id"
                      formControlName="480">
                    </ng-select>
                  </div>
                </div>
              </div>
            </td>
            <td colspan="3">
              <div class="col-md-3 inputBoxPadding">
                <div class="form-group form-inline no-margin inputBoxPaddingLeft">
                  <input class="form-control " id="479" name="479" formControlName="479" placeholder="Graduate School"
                    type="text" data-id="gradschool">
                </div>
              </div>
            </td>
            <td colspan="3">
              <div class="input-group">
                <input class="form-control" formControlName="482" (blur)="clearInputIfInvalid('482')"  name="dp" placeholder="" ngbDatepicker
                  #x12="ngbDatepicker">
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary calendar" (click)="x12.toggle()" type="button"><i
                      class="fas fa-calendar-alt"></i></button>
                </div>
              </div>
              <div  *ngIf="lifeWaysUniversal.get('482').invalid ">
                <small class="text-danger" *ngIf="lifeWaysUniversal.get('482').errors?.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY)
                </small>
                <small class="text-danger" *ngIf="lifeWaysUniversal.get('482').errors?.invalidMonth">
                Month is out of range
                </small>
                <small class="text-danger" *ngIf="lifeWaysUniversal.get('482').errors?.invalidDateyear">
                  year is out of range(1900-2099)                      </small>
                  <small class="text-danger" *ngIf="lifeWaysUniversal.get('482').errors?.invalidDateRange">
                    date is out of range
                    </small>
                </div>
            </td>
          </tr>
          <tr>
            <td colspan="3">
              <div class="col-md-3 inputBoxPadding">
                <div class="form-group form-inline no-margin">
                  <div class="form-group selectDropTwo suffix">
                    <ng-select [items]="degree" bindLabel="name" placeholder="Select Degree" bindValue="id"
                      formControlName="500">
                    </ng-select>
                  </div>
                </div>
              </div>
            </td>
            <td colspan="3">
              <div class="col-md-3 inputBoxPadding">
                <div class="form-group form-inline no-margin inputBoxPaddingLeft">
                  <input class="form-control" id="490" name="490" formControlName="490" placeholder="Medicare School"
                    type="text" data-id="med_school">
                </div>
              </div>
            </td>
            <td colspan="3">
              <div class="input-group">
                <input class="form-control" formControlName="499" (blur)="clearInputIfInvalid('499')" name="dp" placeholder="" ngbDatepicker
                  #x13="ngbDatepicker">
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary calendar" (click)="x13.toggle()" type="button"><i
                      class="fas fa-calendar-alt"></i></button>
                </div>
              </div>
              <div  *ngIf="lifeWaysUniversal.get('499').invalid ">
                <small class="text-danger" *ngIf="lifeWaysUniversal.get('499').errors?.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY)
                </small>
                <small class="text-danger" *ngIf="lifeWaysUniversal.get('499').errors?.invalidMonth">
                Month is out of range
                </small>
                <small class="text-danger" *ngIf="lifeWaysUniversal.get('499').errors?.invalidDateyear">
                  year is out of range(1900-2099)                      </small>
                  <small class="text-danger" *ngIf="lifeWaysUniversal.get('499').errors?.invalidDateRange">
                    date is out of range
                    </small>
                </div>
            </td>
          </tr>
          <tr>
            <td colspan="3">
              <div class="col-md-3 inputBoxPadding">
                <div class="form-group selectDropTwo suffix">
                  <ng-select [items]="degree" bindLabel="name" placeholder="Select Degree" bindValue="id"
                    formControlName="cmd_2500050">
                  </ng-select>
                </div>
              </div>
            </td>
            <td colspan="3">
              <div class="col-md-3 inputBoxPadding">
                <div class="form-group form-inline no-margin inputBoxPaddingLeft">
                  <input class="form-control" id="cmd_2500051" name="cmd_2500051" formControlName="cmd_2500051"
                    type="text" data-id="lname">
                </div>
              </div>
            </td>
            <td colspan="3">
              <div class="input-group">
                <input class="form-control" formControlName="cmd_2500052" (blur)="clearInputIfInvalid('cmd_2500052')" name="dp" placeholder="" ngbDatepicker
                  #x14="ngbDatepicker">
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary calendar" (click)="x14.toggle()" type="button"><i
                      class="fas fa-calendar-alt"></i></button>
                </div>
              </div>
              <div  *ngIf="lifeWaysUniversal.get('cmd_2500052').invalid ">
                <small class="text-danger" *ngIf="lifeWaysUniversal.get('cmd_2500052').errors?.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY)
                </small>
                <small class="text-danger" *ngIf="lifeWaysUniversal.get('cmd_2500052').errors?.invalidMonth">
                Month is out of range
                </small>
                <small class="text-danger" *ngIf="lifeWaysUniversal.get('cmd_2500052').errors?.invalidDateyear">
                  year is out of range(1900-2099)                      </small>
                  <small class="text-danger" *ngIf="lifeWaysUniversal.get('cmd_2500052').errors?.invalidDateRange">
                    date is out of range
                    </small>
                </div>
            </td>
          </tr>
        </table><br><br><br><br>
        <div class="row">
          <div class="form-group form-inline no-margin col-md-12">
            <h4 class="hThreeStyle"><b>Residency/Internship - </b>Please indicate all residencies, fellowships, or
              internships held. <b>Submit
                copies of residency certificates if initial credentialing.</b>
            </h4>
          </div>
        </div>
        <ol class="olTypeSet">
          <li>
            <div class="row sideHeadingAlignment">
              <div class="col-md-2">
                <label class="normalParagraph"> Company:</label>
              </div>
              <div class="col-md-4">
                <input class="form-control" id="517" name="517" formControlName="517" placeholder="School" type="text"
                  data-id="intern_school">
              </div>
              <div class="col-md-2">
                <label class="normalParagraph"> phone:</label>
              </div>
              <div class="col-md-3">
                <input class="form-control" id="1318" name="1318" formControlName="1318" placeholder="Director Phone"
                  type="text" data-id="intern_director_phone">
              </div>
            </div>
            <div class="row sideHeadingAlignment">
              <div class="col-md-2">
                <label class="normalParagraph"> Address:</label>
              </div>
              <div class="col-md-9">
                <input class="form-control" id="518" name="518" formControlName="518" type="text"
                  data-id="intern__add1">
              </div>
            </div>
            <div class="row sideHeadingAlignment">
              <div class="col-md-2">
                <label class="normalParagraph"> City:</label>
              </div>
              <div class="col-md-3">
                <input class="form-control" id="520" name="520" formControlName="520" type="text"
                  data-id="intern__city">
              </div>
              <div class="col-md-2">
                <label class="normalParagraph"> State:</label>
              </div>
              <div class="col-md-4">
                <div class="form-group selectDropTwo suffix">
                  <ng-select [items]="states" bindLabel="name" placeholder="Select States" bindValue="id"
                    formControlName="522">
                  </ng-select>
                </div>
              </div>
            </div>
            <div class="row sideHeadingAlignment">
              <div class="col-md-2">
                <label class="normalParagraph">Address:</label>
              </div>
              <div class="col-md-9">
                <input class="form-control" id="519" name="519" formControlName="519" type="text"
                  data-id="intern__add2">
              </div>
            </div>
            <div class="row sideHeadingAlignment">
              <div class="col-md-2">
                <label class="normalParagraph">Supervisor: </label>
              </div>
              <div class="col-md-9">
                <input class="form-control" id="528" name="528" formControlName="528" type="text"
                  data-id="intern_director">
              </div>
            </div>
            <div class="row sideHeadingAlignment">
              <div class="col-md-3">
                <label class="normalParagraph">Start Month/Years Attended:</label>
              </div>
              <div class="col-md-2">
                <input class="form-control onboard datepicker" ngbDatepicker #d1="ngbDatepicker" (blur)="clearInputIfInvalid('524')" id="524" name="524"
                  [class.is-invalid]="(lifeWaysUniversal.controls['524'].touched && lifeWaysUniversal.controls['524'].invalid) || (lifeWaysUniversal.controls['524'].invalid && isSubmit)"
                  formControlName="524" type="text" placeholder="DATE" data-id="managing_contact_name">
              </div> 
               
              <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar calenderWidthWork" (click)="d1.toggle()"
                  type="button"><i class="fas fa-calendar-alt"></i></button>
              </div>
             
              <div class="col-md-3">
                <label class="normalParagraph">End Month/Years Attended:</label>
              </div>
              <div class="col-md-2">
                <input class="form-control onboard datepicker" ngbDatepicker #d2="ngbDatepicker" id="525" name="525" (blur)="clearInputIfInvalid('525')"
                  [class.is-invalid]="(lifeWaysUniversal.controls['525'].touched && lifeWaysUniversal.controls['525'].invalid) || (lifeWaysUniversal.controls['525'].invalid && isSubmit)"
                  formControlName="525" type="text" placeholder="DATE" data-id="managing_contact_name">
              </div>
              <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar calenderWidthWork" (click)="d2.toggle()"
                  type="button"><i class="fas fa-calendar-alt"></i></button>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3"> </div>
            <div class="col-md-3"> 
              <div  *ngIf="lifeWaysUniversal.get('524').invalid ">
                <small class="text-danger" *ngIf="lifeWaysUniversal.get('524').errors?.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY)
                </small>
                <small class="text-danger" *ngIf="lifeWaysUniversal.get('524').errors?.invalidMonth">
                Month is out of range
                </small>
                <small class="text-danger" *ngIf="lifeWaysUniversal.get('524').errors?.invalidDateyear">
                  year is out of range(1900-2099)                      </small>
                  <small class="text-danger" *ngIf="lifeWaysUniversal.get('524').errors?.invalidDateRange">
                    date is out of range
                    </small>
                </div>
            </div>
            <div class="col-md-3"> </div>
            <div class="col-md-3">
              <div  *ngIf="lifeWaysUniversal.get('525').invalid ">
                <small class="text-danger" *ngIf="lifeWaysUniversal.get('525').errors?.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY)
                </small>
                <small class="text-danger" *ngIf="lifeWaysUniversal.get('525').errors?.invalidMonth">
                Month is out of range
                </small>
                <small class="text-danger" *ngIf="lifeWaysUniversal.get('525').errors?.invalidDateyear">
                  year is out of range(1900-2099)                      </small>
                  <small class="text-danger" *ngIf="lifeWaysUniversal.get('525').errors?.invalidDateRange">
                    date is out of range
                    </small>
                </div> </div>
              </div>
          
            
          </li>
          <li>
            <div class="row sideHeadingAlignment">
              <div class="col-md-2">
                <label class="normalParagraph"> Company:</label>
              </div>
              <div class="col-md-4">
                <input class="form-control" id="529" name="529" placeholder="Residency School" formControlName="529"
                  type="text" data-id="res_school">
              </div>
              <div class="col-md-2">
                <label class="normalParagraph"> phone:</label>
              </div>
              <div class="col-md-3">
                <input class="form-control" id="1316" name="1316" formControlName="1316" type="text"
                  data-id="residency_director_phone">
              </div>
            </div>
            <div class="row sideHeadingAlignment">
              <div class="col-md-2">
                <label class="normalParagraph"> Address:</label>
              </div>
              <div class="col-md-9">
                <input class="form-control" id="531" name="531" formControlName="531" type="text" data-id="res__add1">
              </div>
            </div>
            <div class="row sideHeadingAlignment">
              <div class="col-md-2">
                <label class="normalParagraph"> City:</label>
              </div>
              <div class="col-md-3">
                <input class="form-control" id="533" name="533" formControlName="533" type="text" data-id="res__city">
              </div>
              <div class="col-md-2">
                <label class="normalParagraph"> State: </label>
              </div>
              <div class="col-md-4">
                <div class="form-group selectDropTwo suffix">
                  <ng-select [items]="states" bindLabel="name" placeholder="Select States" bindValue="id"
                    formControlName="535">
                  </ng-select>
                </div>
              </div>
            </div>
            <div class="row sideHeadingAlignment">
              <div class="col-md-2">
                <label class="normalParagraph">Address: </label>
              </div>
              <div class="col-md-9">
                <input class="form-control" id="1498" name="1498" formControlName="1498" type="text"
                  data-id="residency_address_2">
              </div>
            </div>
            <div class="row sideHeadingAlignment">
              <div class="col-md-2">
                <label class="normalParagraph">Supervisor: </label>
              </div>
              <div class="col-md-9">
                <input class="form-control" id="540" name="540" formControlName="540" type="text"
                  data-id="res_director">
              </div>
            </div>
            <div class="row sideHeadingAlignment">
              <div class="col-md-3">
                <label class="normalParagraph">Start Month/Years Attended:</label>
              </div>
              <div class="col-md-2">
                <input class="form-control onboard datepicker" ngbDatepicker #d3="ngbDatepicker" id="537" name="537" (blur)="clearInputIfInvalid('537')"
                  [class.is-invalid]="(lifeWaysUniversal.controls['537'].touched && lifeWaysUniversal.controls['537'].invalid) || (lifeWaysUniversal.controls['537'].invalid && isSubmit)"
                  formControlName="537" type="text" placeholder="DATE" data-id="managing_contact_name">
              </div>
              <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar calenderWidthWork" (click)="d3.toggle()"
                  type="button"><i class="fas fa-calendar-alt"></i></button>
              </div>
              <div class="col-md-3">
                <label class="normalParagraph">End Month/Years Attended:</label>
              </div>
              <div class="col-md-2">
                <input class="form-control onboard datepicker" ngbDatepicker #d4="ngbDatepicker" id="538" name="538" (blur)="clearInputIfInvalid('538')"
                  [class.is-invalid]="(lifeWaysUniversal.controls['538'].touched && lifeWaysUniversal.controls['538'].invalid) || (lifeWaysUniversal.controls['538'].invalid && isSubmit)"
                  formControlName="538" type="text" placeholder="DATE" data-id="managing_contact_name">
              </div>
              <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar calenderWidthWork" (click)="d4.toggle()"
                  type="button"><i class="fas fa-calendar-alt"></i></button>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3"> </div>
            <div class="col-md-3"> 
              <div  *ngIf="lifeWaysUniversal.get('537').invalid ">
                <small class="text-danger" *ngIf="lifeWaysUniversal.get('537').errors?.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY)
                </small>
                <small class="text-danger" *ngIf="lifeWaysUniversal.get('537').errors?.invalidMonth">
                Month is out of range
                </small>
                <small class="text-danger" *ngIf="lifeWaysUniversal.get('537').errors?.invalidDateyear">
                  year is out of range(1900-2099)                      </small>
                  <small class="text-danger" *ngIf="lifeWaysUniversal.get('537').errors?.invalidDateRange">
                    date is out of range
                    </small>
                </div>
            </div>
            <div class="col-md-3"> </div>
            <div class="col-md-3">
              <div  *ngIf="lifeWaysUniversal.get('538').invalid ">
                <small class="text-danger" *ngIf="lifeWaysUniversal.get('538').errors?.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY)
                </small>
                <small class="text-danger" *ngIf="lifeWaysUniversal.get('538').errors?.invalidMonth">
                Month is out of range
                </small>
                <small class="text-danger" *ngIf="lifeWaysUniversal.get('538').errors?.invalidDateyear">
                  year is out of range(1900-2099)                      </small>
                  <small class="text-danger" *ngIf="lifeWaysUniversal.get('538').errors?.invalidDateRange">
                    date is out of range
                    </small>
                </div> </div>
              </div>
          </li>
          <li>
            <div class="row sideHeadingAlignment">
              <div class="col-md-2">
                <label class="normalParagraph"> Company:</label>
              </div>
              <div class="col-md-4">
                <input class="form-control" id="542" name="542" formControlName="542" type="text"
                  data-id="fellow_school">
              </div>
              <div class="col-md-2">
                <label class="normalParagraph"> phone:</label>
              </div>
              <div class="col-md-3">
                <input class="form-control" id="1322" name="1322" formControlName="1322" type="text"
                  data-id="fellowship_director_phone">
              </div>
            </div>
            <div class="row sideHeadingAlignment">
              <div class="col-md-2">
                <label class="normalParagraph"> Address1:</label>
              </div>
              <div class="col-md-9">
                <input class="form-control" id="544" name="544" formControlName="544" type="text"
                  data-id="fellow__add1">
              </div>
            </div>
            <div class="row sideHeadingAlignment">
              <div class="col-md-2">
                <label class="normalParagraph"> City:</label>
              </div>
              <div class="col-md-3">
                <input class="form-control" id="546" name="546" formControlName="546" type="text"
                  data-id="fellow__city">
              </div>
              <div class="col-md-2">
                <label class="normalParagraph"> State:</label>
              </div>
              <div class="col-md-4">
                <div class="form-group selectDropTwo suffix">
                  <ng-select [items]="states" bindLabel="name" placeholder="Select States" bindValue="id"
                    formControlName="548">
                  </ng-select>
                </div>
              </div>
            </div>
            <div class="row sideHeadingAlignment">
              <div class="col-md-2">
                <label class="normalParagraph">Address2:</label>
              </div>
              <div class="col-md-9">
                <input class="form-control" id="545" name="545" formControlName="545" type="text" data-id="fellow__add2"
                  s>
              </div>
            </div>
            <div class="row sideHeadingAlignment">
              <div class="col-md-2">
                <label class="normalParagraph">Supervisor: </label>
              </div>
              <div class="col-md-9">
                <input class="form-control" id="553" name="553" formControlName="553" type="text"
                  data-id="fellow_director">
              </div>
            </div>
            <div class="row sideHeadingAlignment">
              <div class="col-md-3">
                <label class="normalParagraph">Month/Years Attended:</label>
              </div>
              <div class="col-md-2">
                <input class="form-control onboard datepicker" ngbDatepicker #d5="ngbDatepicker" id="550" name="550"  (blur)="clearInputIfInvalid('550')"
                  [class.is-invalid]="(lifeWaysUniversal.controls['550'].touched && lifeWaysUniversal.controls['550'].invalid) || (lifeWaysUniversal.controls['550'].invalid && isSubmit)"
                  formControlName="550" type="text" placeholder="DATE" data-id="managing_contact_name">
              </div>
              <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar calenderWidthWork" (click)="d5.toggle()"
                  type="button"><i class="fas fa-calendar-alt"></i></button>
              </div>
              <div class="col-md-3">
                <label class="normalParagraph">Month/Years Attended:</label>
              </div>
              <div class="col-md-2">
                <input class="form-control onboard datepicker" ngbDatepicker #d6="ngbDatepicker" id="551" name="551" (blur)="clearInputIfInvalid('551')"
                  [class.is-invalid]="(lifeWaysUniversal.controls['551'].touched && lifeWaysUniversal.controls['551'].invalid) || (lifeWaysUniversal.controls['551'].invalid && isSubmit)"
                  formControlName="551" type="text" placeholder="DATE" data-id="managing_contact_name">
              </div>
              <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar calenderWidthWork" (click)="d6.toggle()"
                  type="button"><i class="fas fa-calendar-alt"></i></button>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3"> </div>
            <div class="col-md-3"> 
              <div  *ngIf="lifeWaysUniversal.get('550').invalid ">
                <small class="text-danger" *ngIf="lifeWaysUniversal.get('550').errors?.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY)
                </small>
                <small class="text-danger" *ngIf="lifeWaysUniversal.get('550').errors?.invalidMonth">
                Month is out of range
                </small>
                <small class="text-danger" *ngIf="lifeWaysUniversal.get('550').errors?.invalidDateyear">
                  year is out of range(1900-2099)                      </small>
                  <small class="text-danger" *ngIf="lifeWaysUniversal.get('550').errors?.invalidDateRange">
                    date is out of range
                    </small>
                </div>
            </div>
            <div class="col-md-3"> </div>
            <div class="col-md-3">
              <div  *ngIf="lifeWaysUniversal.get('551').invalid ">
                <small class="text-danger" *ngIf="lifeWaysUniversal.get('551').errors?.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY)
                </small>
                <small class="text-danger" *ngIf="lifeWaysUniversal.get('551').errors?.invalidMonth">
                Month is out of range
                </small>
                <small class="text-danger" *ngIf="lifeWaysUniversal.get('551').errors?.invalidDateyear">
                  year is out of range(1900-2099)                      </small>
                  <small class="text-danger" *ngIf="lifeWaysUniversal.get('551').errors?.invalidDateRange">
                    date is out of range
                    </small>
                </div> </div>
              </div>
          </li>
        </ol>
        <div class="row sideHeadingAlignment">
          <div class="form-group form-inline no-margin col-md-12">
            <h3 class="hThreeStyle"><b><u>SERVICE CONTINUUM</u></b></h3>
            <h3 class="hThreeStyle">Please indicate populations you have previous working experience with:</h3>
          </div>
        </div>
        <div class="col-md-12 sideHeadingAlignment checkBoxHeight">
          <input type="checkbox" id="cmd_25000012" name="cmd_25000012" formControlName="cmd_25000012" value="1">
          <label for="cmd_25000012">Adults w/Mental Illness</label><br>
          <input type="checkbox" id="cmd_25000013" name="cmd_25000013" formControlName="cmd_25000013" value="1">
          <label for="cmd_25000013">Adults w/Developmental Disabilities</label><br>
          <input type="checkbox" id="cmd_25000014" name="cmd_25000014" formControlName="cmd_25000014" value="1">
          <label for="cmd_25000014">Children w/Serious Emotional Disturbance</label><br>
          <input type="checkbox" id="cmd_2500015" name="cmd_2500015" formControlName="cmd_2500015" value="1">
          <label for="cmd_2500015">Children w/Developmental Disabilities</label><br>
          <input type="checkbox" id="cmd_2500016" name="cmd_2500016" formControlName="cmd_2500016" value="1">
          <label for="cmd_2500016">Elderly and Disabled</label><br>
          <input type="checkbox" id="cmd_25000018" name="cmd_25000018" formControlName="cmd_25000018" value="1">
          <label for="cmd_25000018">Co-Occurring Mental Illness and Substance Use Disorders</label><br>
        </div><br><br>
        <div class="form-group form-inline no-margin col-md-12">
          <h3 class="hThreeStyle"><b><u>CONTINUING EDUCATION</u></b> (Re-Credentialing Only)</h3>
          <h4 class="hThreeStyle">Please indicate all continuing education obtained in the past two years.</h4>
          <h4 class="hThreeStyle">(This section is for re-credentialing only.)</h4>
        </div><br><br><br><br><br>
        <table class="southform_table continueTable">
          <tr>
            <td colspan="3" bgcolor="#a6a6a6">
              <div class="inputBoxPadding">
                <label class="tableInputHeading">Training Title</label>
              </div>
            </td>
            <td colspan="3" bgcolor="#a6a6a6">
              <div class="inputBoxPadding">
                <label class="tableInputHeading">Sponsoring Institution</label>
              </div>
            </td>
            <td colspan="3" bgcolor="#a6a6a6">
              <div class="inputBoxPadding">
                <label class="tableInputHeading">Credits/Hours Earned</label>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan=3>
              <div class="col-md-3" class="inputBoxPadding">
                <div class="form-group form-inline no-margin inputBoxPaddingLeft ">
                  <input class="form-control" id="cmd_25000075" name="cmd_25000075" formControlName="cmd_25000075"
                    type="text" data-id="lname">
                </div>
              </div>
            </td>
            <td colspan="3">
              <div class="col-md-3" class="inputBoxPadding">
                <div class="form-group form-inline no-margin inputBoxPaddingLeft ">
                  <input class="form-control" id="cmd_25000076" name="cmd_25000076" formControlName="cmd_25000076"
                    type="text" data-id="lname">
                </div>
              </div>
            </td>
            <td colspan="3">
              <div class="col-md-3" class="inputBoxPadding">
                <div class="form-group form-inline no-margin inputBoxPaddingLeft ">
                  <input class="form-control" id="cmd_25000077" name="cmd_25000077" formControlName="cmd_25000077"
                    type="text" data-id="lname">
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="3">
              <div class="col-md-3" class="inputBoxPadding">
                <div class="form-group form-inline no-margin inputBoxPaddingLeft ">
                  <input class="form-control" id="cmd_25000078" name="cmd_25000078" formControlName="cmd_25000078"
                    type="text" data-id="lname">
                </div>
              </div>
            </td>
            <td colspan="3">
              <div class="col-md-3" class="inputBoxPadding">
                <div class="form-group form-inline no-margin inputBoxPaddingLeft ">
                  <input class="form-control" id="cmd_25000079" name="cmd_25000079" formControlName="cmd_25000079"
                    type="text" data-id="lname">
                </div>
              </div>
            </td>
            <td colspan="3">
              <div class="col-md-3" class="inputBoxPadding">
                <div class="form-group form-inline no-margin inputBoxPaddingLeft ">
                  <input class="form-control" id="cmd_25000080" name="cmd_25000080" formControlName="cmd_25000080"
                    type="text" data-id="lname">
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="3">
              <div class="col-md-3" class="inputBoxPadding">
                <div class="form-group form-inline no-margin inputBoxPaddingLeft ">
                  <input class="form-control" id="cmd_25000081" name="cmd_25000081" formControlName="cmd_25000081"
                    type="text" data-id="lname">
                </div>
              </div>
            </td>
            <td colspan="3">
              <div class="col-md-3" class="inputBoxPadding">
                <div class="form-group form-inline no-margin inputBoxPaddingLeft ">
                  <input class="form-control" id="cmd_25000082" name="cmd_25000082" formControlName="cmd_25000082"
                    type="text" data-id="lname">
                </div>
              </div>
            </td>
            <td colspan="3">
              <div class="col-md-3" class="inputBoxPadding">
                <div class="form-group form-inline no-margin inputBoxPaddingLeft ">
                  <input class="form-control" id="cmd_25000083" name="cmd_25000083" formControlName="cmd_25000083"
                    type="text" data-id="lname">
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="3">
              <div class="col-md-3" class="inputBoxPadding">
                <div class="form-group form-inline no-margin inputBoxPaddingLeft ">
                  <input class="form-control" id="cmd_25000084" name="cmd_25000084" formControlName="cmd_25000084"
                    type="text" data-id="lname">
                </div>
              </div>
            </td>
            <td colspan="3">
              <div class="col-md-3" class="inputBoxPadding">
                <div class="form-group form-inline no-margin inputBoxPaddingLeft ">
                  <input class="form-control" id="cmd_25000085" name="cmd_25000085" formControlName="cmd_25000085"
                    type="text" data-id="lname">
                </div>
              </div>
            </td>
            <td colspan="3">
              <div class="col-md-3" class="inputBoxPadding">
                <div class="form-group form-inline no-margin inputBoxPaddingLeft ">
                  <input class="form-control" id="cmd_25000086" name="cmd_25000086" formControlName="cmd_25000086"
                    type="text" data-id="lname">
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan=3>
              <div class="col-md-3" class="inputBoxPadding">
                <div class="form-group form-inline no-margin inputBoxPaddingLeft ">
                  <input class="form-control" id="cmd_25000087" name="cmd_25000087" formControlName="cmd_25000087"
                    type="text" data-id="lname">
                </div>
              </div>
            </td>
            <td colspan="3">
              <div class="col-md-3" class="inputBoxPadding">
                <div class="form-group form-inline no-margin inputBoxPaddingLeft ">
                  <input class="form-control" id="cmd_25000088" name="cmd_25000088" formControlName="cmd_25000088"
                    type="text" data-id="lname">
                </div>
              </div>
            </td>
            <td colspan="3">
              <div class="col-md-3" class="inputBoxPadding">
                <div class="form-group form-inline no-margin inputBoxPaddingLeft ">
                  <input class="form-control" id="cmd_25000089" name="cmd_25000089" formControlName="cmd_25000089"
                    type="text" data-id="lname">
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan=3>
              <div class="col-md-3" class="inputBoxPadding">
                <div class="form-group form-inline no-margin inputBoxPaddingLeft ">
                  <input class="form-control" id="cmd_25000090" name="cmd_25000090" formControlName="cmd_25000090"
                    type="text" data-id="lname">
                </div>
              </div>
            </td>
            <td colspan="3">
              <div class="col-md-3" class="inputBoxPadding">
                <div class="form-group form-inline no-margin inputBoxPaddingLeft ">
                  <input class="form-control" id="cmd_25000091" name="cmd_25000091" formControlName="cmd_25000091"
                    type="text" data-id="lname">
                </div>
              </div>
            </td>
            <td colspan="3">
              <div class="col-md-3" class="inputBoxPadding">
                <div class="form-group form-inline no-margin inputBoxPaddingLeft ">
                  <input class="form-control" id="cmd_25000092" name="cmd_25000092" formControlName="cmd_25000092"
                    type="text" data-id="lname">
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan=3>
              <div class="col-md-3" class="inputBoxPadding">
                <div class="form-group form-inline no-margin inputBoxPaddingLeft ">
                  <input class="form-control" id="cmd_25000093" name="cmd_25000093" formControlName="cmd_25000093"
                    type="text" data-id="lname">
                </div>
              </div>
            </td>
            <td colspan="3">
              <div class="col-md-3" class="inputBoxPadding">
                <div class="form-group form-inline no-margin inputBoxPaddingLeft ">
                  <input class="form-control" id="cmd_25000094" name="cmd_25000094" formControlName="cmd_25000094"
                    type="text" data-id="lname">
                </div>
              </div>
            </td>
            <td colspan="3">
              <div class="col-md-3" class="inputBoxPadding">
                <div class="form-group form-inline no-margin inputBoxPaddingLeft ">
                  <input class="form-control" id="cmd_25000095" name="cmd_25000095" formControlName="cmd_25000095"
                    type="text" data-id="lname">
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan=3>
              <div class="col-md-3" class="inputBoxPadding">
                <div class="form-group form-inline no-margin inputBoxPaddingLeft ">
                  <input class="form-control" id="cmd_25000096" name="cmd_25000096" formControlName="cmd_25000096"
                    type="text" data-id="lname">
                </div>
              </div>
            </td>
            <td colspan="3">
              <div class="col-md-3" class="inputBoxPadding">
                <div class="form-group form-inline no-margin inputBoxPaddingLeft ">
                  <input class="form-control" id="cmd_25000097" name="cmd_25000097" formControlName="cmd_25000097"
                    type="text" data-id="lname">
                </div>
              </div>
            </td>
            <td colspan="3">
              <div class="col-md-3" class="inputBoxPadding">
                <div class="form-group form-inline no-margin inputBoxPaddingLeft ">
                  <input class="form-control" id="cmd_25000098" name="cmd_25000098" formControlName="cmd_25000098"
                    type="text" data-id="lname">
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan=3>
              <div class="col-md-3" class="inputBoxPadding">
                <div class="form-group form-inline no-margin inputBoxPaddingLeft ">
                  <input class="form-control" id="cmd_25000099" name="cmd_25000099" formControlName="cmd_25000099"
                    type="text" data-id="lname">
                </div>
              </div>
            </td>
            <td colspan="3">
              <div class="col-md-3" class="inputBoxPadding">
                <div class="form-group form-inline no-margin inputBoxPaddingLeft ">
                  <input class="form-control" id="cmd_25000100" name="cmd_25000100" formControlName="cmd_25000100"
                    type="text" data-id="lname">
                </div>
              </div>
            </td>
            <td colspan="3">
              <div class="col-md-3" class="inputBoxPadding">
                <div class="form-group form-inline no-margin inputBoxPaddingLeft ">
                  <input class="form-control" id="cmd_25000101" name="cmd_25000101" formControlName="cmd_25000101"
                    type="text" data-id="lname">
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan=3>
              <div class="col-md-3" class="inputBoxPadding">
                <div class="form-group form-inline no-margin inputBoxPaddingLeft ">
                  <input class="form-control" id="cmd_25000102" name="cmd_25000102" formControlName="cmd_25000102"
                    type="text" data-id="lname">
                </div>
              </div>
            </td>
            <td colspan="3">
              <div class="col-md-3" class="inputBoxPadding">
                <div class="form-group form-inline no-margin inputBoxPaddingLeft ">
                  <input class="form-control" id="cmd_25000103" name="cmd_25000103" formControlName="cmd_25000103"
                    type="text" data-id="lname">
                </div>
              </div>
            </td>
            <td colspan="3">
              <div class="col-md-3" class="inputBoxPadding">
                <div class="form-group form-inline no-margin inputBoxPaddingLeft ">
                  <input class="form-control" id="cmd_25000104" name="cmd_25000104" formControlName="cmd_25000104"
                    type="text" data-id="lname">
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan=3>
              <div class="col-md-3" class="inputBoxPadding">
                <div class="form-group form-inline no-margin inputBoxPaddingLeft ">
                  <input class="form-control" id="cmd_25000105" name="cmd_25000105" formControlName="cmd_25000105"
                    type="text" data-id="lname">
                </div>
              </div>
            </td>
            <td colspan="3">
              <div class="col-md-3" class="inputBoxPadding">
                <div class="form-group form-inline no-margin inputBoxPaddingLeft ">
                  <input class="form-control" id="cmd_25000106" name="cmd_25000106" formControlName="cmd_25000106"
                    type="text" data-id="lname">
                </div>
              </div>
            </td>
            <td colspan="3">
              <div class="col-md-3" class="inputBoxPadding">
                <div class="form-group form-inline no-margin inputBoxPaddingLeft ">
                  <input class="form-control" id="cmd_25000107" name="cmd_25000107" formControlName="cmd_25000107"
                    type="text" data-id="lname">
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan=3>
              <div class="col-md-3" class="inputBoxPadding">
                <div class="form-group form-inline no-margin inputBoxPaddingLeft ">
                  <input class="form-control" id="cmd_25000108" name="cmd_25000108" formControlName="cmd_25000108"
                    type="text" data-id="lname">
                </div>
              </div>
            </td>
            <td colspan="3">
              <div class="col-md-3" class="inputBoxPadding">
                <div class="form-group form-inline no-margin inputBoxPaddingLeft ">
                  <input class="form-control" id="cmd_25000109" name="cmd_25000109" formControlName="cmd_25000109"
                    type="text" data-id="lname">
                </div>
              </div>
            </td>
            <td colspan="3">
              <div class="col-md-3" class="inputBoxPadding">
                <div class="form-group form-inline no-margin inputBoxPaddingLeft ">
                  <input class="form-control" id="cmd_25000110" name="cmd_25000110" formControlName="cmd_25000110"
                    type="text" data-id="lname">
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan=3>
              <div class="col-md-3" class="inputBoxPadding">
                <div class="form-group form-inline no-margin inputBoxPaddingLeft ">
                  <input class="form-control" id="cmd_25000111" name="cmd_25000111" formControlName="cmd_25000111"
                    type="text" data-id="lname">
                </div>
              </div>
            </td>
            <td colspan="3">
              <div class="col-md-3" class="inputBoxPadding">
                <div class="form-group form-inline no-margin inputBoxPaddingLeft ">
                  <input class="form-control" id="cmd_25000112" name="cmd_25000112" formControlName="cmd_25000112"
                    type="text" data-id="lname">
                </div>
              </div>
            </td>
            <td colspan="3">
              <div class="col-md-3" class="inputBoxPadding">
                <div class="form-group form-inline no-margin inputBoxPaddingLeft ">
                  <input class="form-control" id=cmd_25000113 name="cmd_25000113" formControlName="cmd_25000113"
                    type="text" data-id="lname">
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan=3>
              <div class="col-md-3" class="inputBoxPadding">
                <div class="form-group form-inline no-margin inputBoxPaddingLeft ">
                  <input class="form-control" id="cmd_25000114" name="cmd_25000114" formControlName="cmd_25000114"
                    type="text" data-id="lname">
                </div>
              </div>
            </td>
            <td colspan="3">
              <div class="col-md-3" class="inputBoxPadding">
                <div class="form-group form-inline no-margin inputBoxPaddingLeft ">
                  <input class="form-control" id="cmd_25000115" name="cmd_25000115" formControlName="cmd_25000115"
                    type="text" data-id="lname">
                </div>
              </div>
            </td>
            <td colspan="3">
              <div class="col-md-3" class="inputBoxPadding">
                <div class="form-group form-inline no-margin inputBoxPaddingLeft ">
                  <input class="form-control" id="cmd_25000116" name="cmd_25000116" formControlName="cmd_25000116"
                    type="text" data-id="lname">
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan=3>
              <div class="col-md-3" class="inputBoxPadding">
                <div class="form-group form-inline no-margin inputBoxPaddingLeft ">
                  <input class="form-control" id="cmd_25000117" name="cmd_250001117" formControlName="cmd_250001117"
                    type="text" data-id="lname">
                </div>
              </div>
            </td>
            <td colspan="3">
              <div class="col-md-3" class="inputBoxPadding">
                <div class="form-group form-inline no-margin inputBoxPaddingLeft ">
                  <input class="form-control" id="cmd_25000118" name="cmd_25000118" formControlName="cmd_25000118"
                    type="text" data-id="lname">
                </div>
              </div>
            </td>
            <td colspan="3">
              <div class="col-md-3" class="inputBoxPadding">
                <div class="form-group form-inline no-margin inputBoxPaddingLeft ">
                  <input class="form-control" id="cmd_25000119" name="cmd_25000119" formControlName="cmd_25000119"
                    type="text" data-id="lname">
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan=3>
              <div class="col-md-3" class="inputBoxPadding">
                <div class="form-group form-inline no-margin inputBoxPaddingLeft ">
                  <input class="form-control" id="cmd_25000120" name="cmd_25000120" formControlName="cmd_25000120"
                    type="text" data-id="lname">
                </div>
              </div>
            </td>
            <td colspan="3">
              <div class="col-md-3" class="inputBoxPadding">
                <div class="form-group form-inline no-margin inputBoxPaddingLeft ">
                  <input class="form-control" id="cmd_25000121" name="cmd_25000121" formControlName="cmd_25000121"
                    type="text" data-id="lname">
                </div>
              </div>
            </td>
            <td colspan="3">
              <div class="col-md-3" class="inputBoxPadding">
                <div class="form-group form-inline no-margin inputBoxPaddingLeft ">
                  <input class="form-control" id="cmd_25000122" name="cmd_25000122" formControlName="cmd_25000122"
                    type="text" data-id="lname">
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan=3>
              <div class="col-md-3" class="inputBoxPadding">
                <div class="form-group form-inline no-margin inputBoxPaddingLeft ">
                  <input class="form-control" id="cmd_25000123" name="cmd_25000123" formControlName="cmd_25000123"
                    type="text" data-id="lname">
                </div>
              </div>
            </td>
            <td colspan="3">
              <div class="col-md-3" class="inputBoxPadding">
                <div class="form-group form-inline no-margin inputBoxPaddingLeft ">
                  <input class="form-control" id="cmd_25000124" name="cmd_25000124" formControlName="cmd_25000124"
                    type="text" data-id="lname">
                </div>
              </div>
            </td>
            <td colspan="3">
              <div class="col-md-3" class="inputBoxPadding">
                <div class="form-group form-inline no-margin inputBoxPaddingLeft ">
                  <input class="form-control" id="cmd_25000125" name="cmd_25000125" formControlName="cmd_25000125"
                    type="text" data-id="lname">
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan=12>
              <div class="col-md-12" class="inputBoxPadding">
                <div class="form-group form-inline no-margin inputBoxPaddingLeft ">
                  <label class="hThreeStyleTwo">Continue list on separate sheet. Copies of trainings must be
                    attached.</label>
                </div>
              </div>
            </td>
          </tr>
        </table>
        <div class="row">
          <div class="form-group form-inline no-margin col-md-12">
            <h3 class="hFourStyle"><b><u>Please list date and title of Cultural Diversity
                  Training(s):</u></b><i>Required for Re-credentialing</i></h3>
          </div>
        </div>
        <table class="southform_table dateTable">
          <tr>
            <th colspan="2" bgcolor="#a6a6a6">
              <div style="padding-top:10px">
                <label class="dateTableLabel">Date</label>
              </div>
            </th>
            <th colspan="9" bgcolor="#a6a6a6">
              <div style="padding-top:10px">
                <label class="dateTableLabel">Title</label>
              </div>
            </th>
          </tr>
          <tr>
            <td colspan=2>
              <!-- <div class="col-md-6 dateTableInput"> -->
              <div class="input-group">
                <input class="form-control" formControlName="cmd_25000126" (blur)="clearInputIfInvalid('cmd_25000126')" name="dp" placeholder="" ngbDatepicker
                  #y1="ngbDatepicker">
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary calendar" (click)="y1.toggle()" type="button"><i
                      class="fas fa-calendar-alt"></i></button>
                </div>
              </div>
                <!-- <input class="form-control  datepicker" id="cmd_25000126" name="cmd_25000126"
                  formControlName="cmd_25000126" type="text" placeholder="Date" data-id="managing_contact_name"> -->
              <!-- </div> -->
              <div  *ngIf="lifeWaysUniversal.get('cmd_25000126').invalid ">
                <small class="text-danger" *ngIf="lifeWaysUniversal.get('cmd_25000126').errors?.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY)
                </small>
                <small class="text-danger" *ngIf="lifeWaysUniversal.get('cmd_25000126').errors?.invalidMonth">
                Month is out of range
                </small>
                <small class="text-danger" *ngIf="lifeWaysUniversal.get('cmd_25000126').errors?.invalidDateyear">
                  year is out of range(1900-2099)                      </small>
                  <small class="text-danger" *ngIf="lifeWaysUniversal.get('cmd_25000126').errors?.invalidDateRange">
                    date is out of range
                    </small>
                </div>
            </td>
            <td colspan="9">
              <div class="col-md-9 dateTableSecondCol">
                <input class="form-control" id="cmd_25000127" name="cmd_25000127" formControlName="cmd_25000127"
                  type="text" placeholder="title" data-id="lname">
              </div>
            </td>
          </tr>
          <tr>
            <td colspan=2>
              <!-- <div class="col-md-6 dateTableInput">
                <input class="form-control  datepicker" id="cmd_25000128" name="cmd_25000128"
                  formControlName="cmd_25000128" type="text" placeholder="Date" data-id="managing_contact_name"> -->
                  <div class="input-group">
                    <input class="form-control" formControlName="cmd_25000128" (blur)="clearInputIfInvalid('cmd_25000128')" name="dp" placeholder="" ngbDatepicker
                      #y2="ngbDatepicker">
                    <div class="input-group-append">
                      <button class="btn btn-outline-secondary calendar" (click)="y2.toggle()" type="button"><i
                          class="fas fa-calendar-alt"></i></button>
                    </div>
                  </div>
                <!-- </div> -->
                <div  *ngIf="lifeWaysUniversal.get('cmd_25000128').invalid ">
                  <small class="text-danger" *ngIf="lifeWaysUniversal.get('cmd_25000128').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="lifeWaysUniversal.get('cmd_25000128').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="lifeWaysUniversal.get('cmd_25000128').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="lifeWaysUniversal.get('cmd_25000128').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
            </td>
            <td colspan="9">
              <div class="col-md-9 dateTableSecondCol">
                <input class="form-control" id="cmd_25000129" name="cmd_25000129" formControlName="cmd_25000129"
                  type="text" placeholder="title" data-id="lname">
              </div>
            </td>
          </tr>
          <tr>
            <td colspan=2>
              <!-- <div class="col-md-6 dateTableInput">
                <input class="form-control  datepicker" id="cmd_25000130" name="cmd_25000130"
                  formControlName="cmd_25000130" type="text" placeholder="Date" data-id="managing_contact_name">
              </div> -->
              <div class="input-group">
                <input class="form-control" formControlName="cmd_25000130" (blur)="clearInputIfInvalid('cmd_25000130')" name="dp" placeholder="" ngbDatepicker
                  #y3="ngbDatepicker">
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary calendar" (click)="y3.toggle()" type="button"><i
                      class="fas fa-calendar-alt"></i></button>
                </div>
              </div>
              <div  *ngIf="lifeWaysUniversal.get('cmd_25000130').invalid ">
                <small class="text-danger" *ngIf="lifeWaysUniversal.get('cmd_25000130').errors?.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY)
                </small>
                <small class="text-danger" *ngIf="lifeWaysUniversal.get('cmd_25000130').errors?.invalidMonth">
                Month is out of range
                </small>
                <small class="text-danger" *ngIf="lifeWaysUniversal.get('cmd_25000130').errors?.invalidDateyear">
                  year is out of range(1900-2099)                      </small>
                  <small class="text-danger" *ngIf="lifeWaysUniversal.get('cmd_25000130').errors?.invalidDateRange">
                    date is out of range
                    </small>
                </div>
            </td>
            <td colspan="9">
              <div class="col-md-9 dateTableSecondCol">
                <input class="form-control" id="cmd_25000131" name="cmd_25000131" formControlName="cmd_25000131"
                  type="text" placeholder="title" data-id="lname">
              </div>
            </td>
          </tr>
          <tr>
            <td colspan=2>
              <!-- <div class="col-md-6 dateTableInput">
                <input class="form-control  datepicker" id="cmd_25000132" name="cmd_25000132"
                  formControlName="cmd_25000132" type="text" placeholder="Date" data-id="managing_contact_name">
              </div> -->
              <div class="input-group">
                <input class="form-control" formControlName="cmd_25000132" (blur)="clearInputIfInvalid('cmd_25000132')" name="dp" placeholder="" ngbDatepicker
                  #y4="ngbDatepicker">
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary calendar" (click)="y4.toggle()" type="button"><i
                      class="fas fa-calendar-alt"></i></button>
                </div>
              </div>
              <div  *ngIf="lifeWaysUniversal.get('cmd_25000132').invalid ">
                <small class="text-danger" *ngIf="lifeWaysUniversal.get('cmd_25000132').errors?.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY)
                </small>
                <small class="text-danger" *ngIf="lifeWaysUniversal.get('cmd_25000132').errors?.invalidMonth">
                Month is out of range
                </small>
                <small class="text-danger" *ngIf="lifeWaysUniversal.get('cmd_25000132').errors?.invalidDateyear">
                  year is out of range(1900-2099)                      </small>
                  <small class="text-danger" *ngIf="lifeWaysUniversal.get('cmd_25000132').errors?.invalidDateRange">
                    date is out of range
                    </small>
                </div>
            </td>
            <td colspan="9">
              <div class="col-md-9 dateTableSecondCol">
                <input class="form-control" id="cmd_25000133" name="cmd_25000133" formControlName="cmd_25000133"
                  type="text" placeholder="title" data-id="lname">
              </div>
            </td>
          </tr>
        </table><br><br><br>
        <div class="row">
          <div class="form-group form-inline no-margin col-md-12">
            <h4 class="hThreeStyle"><b><u>PROFESSIONAL REFERENCES</u></b> (Initial Credentialing Only)</h4>
            <label class="universal-label hThreeStylePadding">Please name three (3) individuals who have personal
              knowledge of your current clinical abilities,
              ethical character, and ability to work cooperatively with others and who will provide specific written
              comments on these matters upon request. </label>
          </div>
        </div>
        <ol class="olStyleProfessional">
          <li>
            <div class="row sideHeadingAlignment">
              <div class="col-md-2">
                <label class="normalParagraph"> Name:</label>
              </div>
              <div class="col-md-9">
                <input class="form-control" id="359" name="359" formControlName="359" type="text"
                  data-id="reference_1_name">
              </div>
            </div>
            <div class="row sideHeadingAlignment">
              <div class="col-md-2">
                <label class="normalParagraph"> Organization:</label>
              </div>
              <div class="col-md-9">
                <input class="form-control" id="cmd_25000137" formControlName="cmd_25000137" name="cmd_25000137"
                  type="text" data-id="lname">
              </div>
            </div>
            <div class="row sideHeadingAlignment">
              <div class="col-md-2">
                <label class="normalParagraph"> Address:</label>
              </div>
              <div class="col-md-9">
                <input class="form-control" id="360" name="360" formControlName="360" type="text"
                  data-id="reference_1__add1">
              </div>
            </div>
            <div class="row sideHeadingAlignment">
              <div class="col-md-2">
                <label class="normalParagraph">Address:</label>
              </div>
              <div class="col-md-9">
                <input class="form-control" id="361" name="361" formControlName="361" type="text"
                  data-id="reference_1__add2">
              </div>
            </div>
            <div class="row sideHeadingAlignment">
              <div class="col-md-2">
                <label class="normalParagraph">City:</label>
              </div>
              <div class="col-md-3">
                <input class="form-control" id="362" name="362" formControlName="362" type="text"
                  data-id="reference_1__city">
              </div>
              <div class="col-md-2">
                <label class="normalParagraph">Fax:</label>
              </div>
              <div class="col-md-4">
                <input class="form-control" id="365" name="365" formControlName="365" type="text"
                  data-id="reference_1__zip">
              </div>
            </div>
            <div class="row sideHeadingAlignment">
              <div class="col-md-2">
                <label class="normalParagraph">Title: </label>
              </div>
              <div class="col-md-4">
                <input class="form-control" id="2722" name="2722" formControlName="2722" type="text"
                  data-id="reference_1_title">
              </div>
              <div class="col-md-2">
                <label class="normalParagraph">Phone:</label>
              </div>
              <div class="col-md-3">
                <input class="form-control" id="369" name="369" formControlName="369" type="text"
                  data-id="reference_1_telephone">
              </div>
            </div>
            <div class="row sideHeadingAlignment">
              <div class="col-md-2">
                <label class="normalParagraph">Email Address:</label>
              </div>
              <div class="col-md-9">
                <input class="form-control" id="371" name="371" formControlName="371" type="text"
                  data-id="reference_1_email">
              </div>
            </div>
          </li>
          <li>
            <div class="row sideHeadingAlignment">
              <div class="col-md-2">
                <label class="normalParagraph"> Name:</label>
              </div>
              <div class="col-md-9">
                <input class="form-control" id="372" name="372" formControlName="372" type="text"
                  data-id="reference_2_name">
              </div>
            </div>
            <div class="row sideHeadingAlignment">
              <div class="col-md-2">
                <label class="normalParagraph"> Organization:</label>
              </div>
              <div class="col-md-9">
                <input class="form-control" id="cmd_25000138" name="cmd_25000138" formControlName="cmd_25000138"
                  type="text" data-id="lname">
              </div>
            </div>
            <div class="row sideHeadingAlignment">
              <div class="col-md-2">
                <label class="normalParagraph"> Address:</label>
              </div>
              <div class="col-md-9">
                <input class="form-control" id="373" name="373" formControlName="373" type="text"
                  data-id="reference_2__add1">
              </div>
            </div>
            <div class="row sideHeadingAlignment">
              <div class="col-md-2">
                <label class="normalParagraph">Address:</label>
              </div>
              <div class="col-md-9">
                <input class="form-control" id="374" name="374" formControlName="374" type="text"
                  data-id="reference_2__add2">
              </div>
            </div>
            <div class="row sideHeadingAlignment">
              <div class="col-md-2">
                <label class="normalParagraph">City:</label>
              </div>
              <div class="col-md-3">
                <input class="form-control" id="375" name="375" formControlName="375" type="text"
                  data-id="reference_2__city">
              </div>
              <div class="col-md-2">
                <label class="normalParagraph">Fax:</label>
              </div>
              <div class="col-md-4">
                <input class="form-control" id="378" name="378" formControlName="378" type="text"
                  data-id="reference_2__zip">
              </div>
            </div>
            <div class="row sideHeadingAlignment">
              <div class="col-md-2">
                <label class="normalParagraph">Title: </label>
              </div>
              <div class="col-md-4">
                <input class="form-control" id="2723" name="2723" formControlName="2723" type="text"
                  data-id="reference_2_title">
              </div>
              <div class="col-md-2">
                <label class="normalParagraph">Phone:</label>
              </div>
              <div class="col-md-3">
                <input class="form-control" id="381" name="381" formControlName="381" type="text"
                  data-id="reference_2_telephone">
              </div>
            </div>
            <div class="row sideHeadingAlignment">
              <div class="col-md-2">
                <label class="normalParagraph">Email Address:</label>
              </div>
              <div class="col-md-9">
                <input class="form-control" id="383" name="383" formControlName="383" type="text"
                  data-id="reference_2_email">
              </div>
            </div>
          </li>
          <li>
            <div class="row sideHeadingAlignment">
              <div class="col-md-2">
                <label class="normalParagraph"> Name:</label>
              </div>
              <div class="col-md-9">
                <input class="form-control" id="384" name="384" formControlName="384" type="text"
                  data-id="reference_3_name">
              </div>
            </div>
            <div class="row sideHeadingAlignment">
              <div class="col-md-2">
                <label class="normalParagraph"> Organization:</label>
              </div>
              <div class="col-md-9">
                <input class="form-control" id="cmd_25000139" name="cmd_25000139" formControlName="cmd_25000139"
                  type="text" data-id="lname">
              </div>
            </div>
            <div class="row sideHeadingAlignment">
              <div class="col-md-2">
                <label class="normalParagraph"> Address:</label>
              </div>
              <div class="col-md-9">
                <input class="form-control" id="385" name="385" formControlName="385" type="text"
                  data-id="reference_3__add1">
              </div>
            </div>
            <div class="row sideHeadingAlignment">
              <div class="col-md-2">
                <label class="normalParagraph">Address:</label>
              </div>
              <div class="col-md-9">
                <input class="form-control" id="386" name="386" formControlName="386" type="text"
                  data-id="reference_3__add2">
              </div>
            </div>
            <div class="row sideHeadingAlignment">
              <div class="col-md-2">
                <label class="normalParagraph">City:</label>
              </div>
              <div class="col-md-3">
                <input class="form-control" id="387" name="387" formControlName="387" type="text"
                  data-id="reference_3__city">
              </div>
              <div class="col-md-2">
                <label class="normalParagraph">Fax:</label>
              </div>
              <div class="col-md-4">
                <input class="form-control" id="390" name="390" formControlName="390" type="text"
                  data-id="reference_3__zip">
              </div>
            </div>
            <div class="row sideHeadingAlignment">
              <div class="col-md-2">
                <label class="normalParagraph">Title: </label>
              </div>
              <div class="col-md-4">
                <input class="form-control" id="2725" name="2725" formControlName="2725" type="text"
                  data-id="reference_3_title">
              </div>
              <div class="col-md-2">
                <label class="normalParagraph">Phone:</label>
              </div>
              <div class="col-md-3">
                <input class="form-control" id="394" name="394" formControlName="394" type="text"
                  data-id="reference_3_telephone">
              </div>
            </div>
            <div class="row sideHeadingAlignment">
              <div class="col-md-2">
                <label class="normalParagraph">Email Address:</label>
              </div>
              <div class="col-md-9">
                <input class="form-control" id="396" name="396" formControlName="396" type="text"
                  data-id="reference_3_email">
              </div>
            </div>
          </li>
        </ol><br><br>
        <div class="">
          <h4 class="hThreeStyle"><b><u>WORK HISTORY</u></b> (Initial Credentialing Only)</h4>
          <br><br>
          <label class="universal-label formLabelLeftPadding">Starting with the most recent, please list your previous
            employment over the past <b><u>five years</u>. <i>For initial
                credentialing,</i></b> You may reference a submitted resume if desired.</label>
        </div>
        <ol class="olTypeSet">
          <li>
            <div class="row sideHeadingAlignment">
              <div class="col-md-2">
                <label class="normalParagraph"> Company:</label>
              </div>
              <div class="col-md-4">
                <input class="form-control" id="445" name="445" formControlName="445" type="text" data-id="lname">
              </div>
              <div class="col-md-2">
                <label class="normalParagraph"> Phone:</label>
              </div>
              <div class="col-md-3">
                <input class="form-control" id="453" name="453" formControlName="453" type="text"
                  data-id="work_history_1_telephone">
              </div>
            </div>
            <div class="row sideHeadingAlignment">
              <div class="col-md-2">
                <label class="normalParagraph"> Address:</label>
              </div>
              <div class="col-md-9">
                <input class="form-control" id="446" name="446" formControlName="446" type="text"
                  data-id="work_history_1__add1">
              </div>
            </div>
            <div class="row sideHeadingAlignment">
              <div class="col-md-2">
                <label class="normalParagraph"> Select County:</label>
              </div>
              <div class="form-group selectDropTwo suffix inputBoxPadding">
                <ng-select [items]="county" bindLabel="name" placeholder="Select US County" bindValue="id"
                  formControlName="449">
                </ng-select>
              </div>
            </div>
            <div class="row sideHeadingAlignment">
              <div class="col-md-2">
                <label class="normalParagraph"> Select State:</label>
              </div>
              <div class="form-group selectDropTwo suffix inputBoxPadding">
                <ng-select [items]="states" bindLabel="name" placeholder="Select State" bindValue="id"
                  formControlName="450">
                </ng-select>
              </div>
            </div>
            <div class="row sideHeadingAlignment">
              <div class="col-md-2">
                <label class="normalParagraph"> Fax:</label>
              </div>
              <div class="col-md-4">
                <input class="form-control" id="888" name="888" formControlName="888" type="text"
                  data-id="work_history_1_fax">
              </div>
            </div>
            <div class="row sideHeadingAlignment">
              <div class="col-md-2">
                <label class="normalParagraph"> City:</label>
              </div>
              <div class="col-md-4">
                <input class="form-control" id="448" name="448" formControlName="448" type="text"
                  data-id="work_history_1__city">
              </div>
              <div class="col-md-2">
                <label class="normalParagraph"> Zip:</label>
              </div>
              <div class="col-md-3">
                <input class="form-control" id="451" name="451" formControlName="451" type="text"
                  data-id="work_history_1__zip">
              </div>
            </div>
            <div class="row sideHeadingAlignment">
              <div class="col-md-2">
                <label class="normalParagraph"> JobTitle:</label>
              </div>
              <div class="col-md-9">
                <input class="form-control" id="1326" name="1326" formControlName="1326" type="text"
                  data-id="work_history_1_job_title">
              </div>
            </div>
            <div class="row sideHeadingAlignment">
              <div class="col-md-2">
                <label class="normalParagraph">Supervisor:</label>
              </div>
              <div class="col-md-9">
                <input class="form-control" id="452" name="452" formControlName="452" type="text" data-id="lname">
              </div>
            </div>
            <div class="row sideHeadingAlignment">
              <div class="col-md-3">
                <label class="normalParagraph">Dates of Employment: </label>
              </div>
              <div class="col-md-2">
                <input class="form-control onboard datepicker" ngbDatepicker #d7="ngbDatepicker" id="454" name="454" (blur)="clearInputIfInvalid('454')"
                  [class.is-invalid]="(lifeWaysUniversal.controls['454'].touched && lifeWaysUniversal.controls['454'].invalid) || (lifeWaysUniversal.controls['454'].invalid && isSubmit)"
                  formControlName="454" type="text" placeholder="DATE" data-id="managing_contact_name">
              </div>
              <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar calenderWidthWork" (click)="d7.toggle()"
                  type="button"><i class="fas fa-calendar-alt"></i></button>
              </div>
              <div class="col-md-3">
                <label class="normalParagraph">End Dates of Employment: </label>
              </div>
              <div class="col-md-2">
                <input class="form-control onboard datepicker" ngbDatepicker #d8="ngbDatepicker" id="455" name="455" (blur)="clearInputIfInvalid('455')"
                  [class.is-invalid]="(lifeWaysUniversal.controls['455'].touched && lifeWaysUniversal.controls['455'].invalid) || (lifeWaysUniversal.controls['455'].invalid && isSubmit)"
                  formControlName="455" type="text" placeholder="DATE" data-id="managing_contact_name">
              </div>
              <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar calenderWidthWork" (click)="d8.toggle()"
                  type="button"><i class="fas fa-calendar-alt"></i></button>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3"> </div>
            <div class="col-md-3"> 
              <div  *ngIf="lifeWaysUniversal.get('454').invalid ">
                <small class="text-danger" *ngIf="lifeWaysUniversal.get('454').errors?.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY)
                </small>
                <small class="text-danger" *ngIf="lifeWaysUniversal.get('454').errors?.invalidMonth">
                Month is out of range
                </small>
                <small class="text-danger" *ngIf="lifeWaysUniversal.get('454').errors?.invalidDateyear">
                  year is out of range(1900-2099)                      </small>
                  <small class="text-danger" *ngIf="lifeWaysUniversal.get('454').errors?.invalidDateRange">
                    date is out of range
                    </small>
                </div>
            </div>
            <div class="col-md-3"> </div>
            <div class="col-md-3">
              <div  *ngIf="lifeWaysUniversal.get('455').invalid ">
                <small class="text-danger" *ngIf="lifeWaysUniversal.get('455').errors?.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY)
                </small>
                <small class="text-danger" *ngIf="lifeWaysUniversal.get('455').errors?.invalidMonth">
                Month is out of range
                </small>
                <small class="text-danger" *ngIf="lifeWaysUniversal.get('455').errors?.invalidDateyear">
                  year is out of range(1900-2099)                      </small>
                  <small class="text-danger" *ngIf="lifeWaysUniversal.get('455').errors?.invalidDateRange">
                    date is out of range
                    </small>
                </div> </div>
              </div>
          </li>
          <li>
            <div class="row sideHeadingAlignment">
              <div class="col-md-2">
                <label class="normalParagraph"> Company:</label>
              </div>
              <div class="col-md-4">
                <input class="form-control" id="456" name="456" formControlName="456" type="text" data-id="lname">
              </div>
              <div class="col-md-2">
                <label class="normalParagraph"> Phone:</label>
              </div>
              <div class="col-md-3">
                <input class="form-control" id="464" name="464" formControlName="464" type="text"
                  data-id="work_history_2_telephone">
              </div>
            </div>
            <div class="row sideHeadingAlignment">
              <div class="col-md-2">
                <label class="normalParagraph"> Address:</label>
              </div>
              <div class="col-md-9">
                <input class="form-control" id="457" name="457" formControlName="457" type="text"
                  data-id="work_history_2__add1">
              </div>
            </div>
            <div class="row sideHeadingAlignment">
              <div class="col-md-2">
                <label class="normalParagraph"> Select County:</label>
              </div>
              <div class="form-group selectDropTwo suffix inputBoxPadding">
                <ng-select [items]="county" bindLabel="name" placeholder="Select County" bindValue="id"
                  formControlName="460">
                </ng-select>
              </div>
            </div>
            <div class="row sideHeadingAlignment">
              <div class="col-md-2">
                <label class="normalParagraph"> Select State:</label>
              </div>
              <div class="form-group selectDropTwo suffix inputBoxPadding">
                <ng-select [items]="states" bindLabel="name" placeholder="Select State" bindValue="id"
                  formControlName="461">
                </ng-select>
              </div>
            </div>
            <div class="row sideHeadingAlignment">
              <div class="col-md-2">
                <label class="normalParagraph"> Fax:</label>
              </div>
              <div class="col-md-4">
                <input class="form-control" id="890" name="890" formControlName="890" type="text"
                  data-id="work_history_2_fax">
              </div>
            </div>
            <div class="row sideHeadingAlignment">
              <div class="col-md-2">
                <label class="normalParagraph"> City:</label>
              </div>
              <div class="col-md-4">
                <input class="form-control" id="459" name="459" formControlName="459" type="text"
                  data-id="work_history_2__city">
              </div>
              <div class="col-md-2">
                <label class="normalParagraph"> Zip:</label>
              </div>
              <div class="col-md-3">
                <input class="form-control" id="462" name="462" formControlName="462" type="text"
                  data-id="work_history_2__zip">
              </div>
            </div>
            <div class="row sideHeadingAlignment">
              <div class="col-md-2">
                <label class="normalParagraph"> JobTitle:</label>
              </div>
              <div class="col-md-9">
                <input class="form-control" id="1343" name="1343" formControlName="1343" type="text"
                  data-id="work_history_2_job_title">
              </div>
            </div>
            <div class="row sideHeadingAlignment">
              <div class="col-md-2">
                <label class="normalParagraph">Supervisor:</label>
              </div>
              <div class="col-md-9">
                <input class="form-control" id="463" name="463" formControlName="463" type="text" data-id="lname">
              </div>
            </div>
            <div class="row sideHeadingAlignment">
              <div class="col-md-3">
                <label class="normalParagraph">Dates of Employment: </label>
              </div>
              <div class="col-md-2">
                <input class="form-control onboard datepicker" ngbDatepicker #d9="ngbDatepicker" id="465" name="465" (blur)="clearInputIfInvalid('465')"
                  [class.is-invalid]="(lifeWaysUniversal.controls['465'].touched && lifeWaysUniversal.controls['465'].invalid) || (lifeWaysUniversal.controls['465'].invalid && isSubmit)"
                  formControlName="465" type="text" placeholder="DATE" data-id="managing_contact_name">
              </div>
              <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar calenderWidthWork" (click)="d9.toggle()"
                  type="button"><i class="fas fa-calendar-alt"></i></button>
              </div>
              <div class="col-md-3">
                <label class="normalParagraph">End Dates of Employment: </label>
              </div>
              <div class="col-md-2">
                <input class="form-control onboard datepicker" ngbDatepicker #d10="ngbDatepicker" id="466" name="466" (blur)="clearInputIfInvalid('466')"
                  [class.is-invalid]="(lifeWaysUniversal.controls['466'].touched && lifeWaysUniversal.controls['466'].invalid) || (lifeWaysUniversal.controls['466'].invalid && isSubmit)"
                  formControlName="466" type="text" placeholder="DATE" data-id="managing_contact_name">
              </div>
              <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar calenderWidthWork" (click)="d10.toggle()"
                  type="button"><i class="fas fa-calendar-alt"></i></button>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3"> </div>
            <div class="col-md-3"> 
              <div  *ngIf="lifeWaysUniversal.get('465').invalid ">
                <small class="text-danger" *ngIf="lifeWaysUniversal.get('465').errors?.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY)
                </small>
                <small class="text-danger" *ngIf="lifeWaysUniversal.get('465').errors?.invalidMonth">
                Month is out of range
                </small>
                <small class="text-danger" *ngIf="lifeWaysUniversal.get('465').errors?.invalidDateyear">
                  year is out of range(1900-2099)                      </small>
                  <small class="text-danger" *ngIf="lifeWaysUniversal.get('465').errors?.invalidDateRange">
                    date is out of range
                    </small>
                </div>
            </div>
            <div class="col-md-3"> </div>
            <div class="col-md-3">
              <div  *ngIf="lifeWaysUniversal.get('466').invalid ">
                <small class="text-danger" *ngIf="lifeWaysUniversal.get('466').errors?.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY)
                </small>
                <small class="text-danger" *ngIf="lifeWaysUniversal.get('466').errors?.invalidMonth">
                Month is out of range
                </small>
                <small class="text-danger" *ngIf="lifeWaysUniversal.get('466').errors?.invalidDateyear">
                  year is out of range(1900-2099)                      </small>
                  <small class="text-danger" *ngIf="lifeWaysUniversal.get('466').errors?.invalidDateRange">
                    date is out of range
                    </small>
                </div> </div>
              </div>
          </li>
          <li>
            <div class="row sideHeadingAlignment">
              <div class="col-md-2">
                <label class="normalParagraph"> Company:</label>
              </div>
              <div class="col-md-4">
                <input class="form-control" id="467" name="467" formControlName="467" type="text" data-id="lname">
              </div>
              <div class="col-md-2">
                <label class="normalParagraph"> Phone:</label>
              </div>
              <div class="col-md-3">
                <input class="form-control" id="475" name="475" formControlName="475" type="text"
                  data-id="work_history_3_telephone">
              </div>
            </div>
            <div class="row sideHeadingAlignment">
              <div class="col-md-2">
                <label class="normalParagraph"> Address:</label>
              </div>
              <div class="col-md-9">
                <input class="form-control" id="468" name="468" formControlName="468" type="text"
                  data-id="work_history_3__add1">
              </div>
            </div>
            <div class="row sideHeadingAlignment">
              <div class="col-md-2">
                <label class="normalParagraph"> Select County:</label>
              </div>
              <div class="form-group selectDropTwo suffix inputBoxPadding">
                <ng-select [items]="county" bindLabel="name" placeholder="Select County" bindValue="id"
                  formControlName="471">
                </ng-select>
              </div>
            </div>
            <div class="row sideHeadingAlignment">
              <div class="col-md-2">
                <label class="normalParagraph"> Select State:</label>
              </div>
              <div class="form-group selectDropTwo suffix inputBoxPadding">
                <ng-select [items]="states" bindLabel="name" placeholder="Select State" bindValue="id"
                  formControlName="472">
                </ng-select>
              </div>
            </div>
            <div class="row sideHeadingAlignment">
              <div class="col-md-2">
                <label class="normalParagraph"> Fax:</label>
              </div>
              <div class="col-md-4">
                <input class="form-control" id="892" name="892" formControlName="892" type="text"
                  data-id="work_history_3_fax">
              </div>
            </div>
            <div class="row sideHeadingAlignment">
              <div class="col-md-2">
                <label class="normalParagraph"> City:</label>
              </div>
              <div class="col-md-4">
                <input class="form-control" id="470" name="470" formControlName="470" type="text"
                  data-id="work_history_3__city">
              </div>
              <div class="col-md-2">
                <label class="normalParagraph"> Zip:</label>
              </div>
              <div class="col-md-3">
                <input class="form-control" id="473" name="473" formControlName="473" type="text"
                  data-id="work_history_3__zip">
              </div>
            </div>
            <div class="row sideHeadingAlignment">
              <div class="col-md-2">
                <label class="normalParagraph"> JobTitle:</label>
              </div>
              <div class="col-md-9">
                <input class="form-control" id="1339" name="1339" formControlName="1339" type="text"
                  data-id="work_history_3_job_title">
              </div>
            </div>
            <div class="row sideHeadingAlignment">
              <div class="col-md-2">
                <label class="normalParagraph">Supervisor:</label>
              </div>
              <div class="col-md-9">
                <input class="form-control" id="474" name="474" formControlName="474" type="text" data-id="lname">
              </div>
            </div>
            <div class="row sideHeadingAlignment">
              <div class="col-md-3">
                <label class="normalParagraph">Dates of Employment: </label>
              </div>
              <div class="col-md-2">
                <input class="form-control onboard datepicker" ngbDatepicker #d11="ngbDatepicker" id="476" name="476" (blur)="clearInputIfInvalid('476')"
                  [class.is-invalid]="(lifeWaysUniversal.controls['476'].touched && lifeWaysUniversal.controls['476'].invalid) || (lifeWaysUniversal.controls['476'].invalid && isSubmit)"
                  formControlName="476" type="text" placeholder="DATE" data-id="managing_contact_name">
              </div>
              <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar calenderWidthWork" (click)="d11.toggle()"
                  type="button"><i class="fas fa-calendar-alt"></i></button>
              </div>
              <div class="col-md-3">
                <label class="normalParagraph">End Dates of Employment: </label>
              </div>
              <div class="col-md-2">
                <input class="form-control onboard datepicker" ngbDatepicker #d12="ngbDatepicker" id="477" name="477" (blur)="clearInputIfInvalid('477')"
                  [class.is-invalid]="(lifeWaysUniversal.controls['477'].touched && lifeWaysUniversal.controls['477'].invalid) || (lifeWaysUniversal.controls['477'].invalid && isSubmit)"
                  formControlName="477" type="text" placeholder="DATE" data-id="managing_contact_name">
              </div>
              <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar calenderWidthWork" (click)="d12.toggle()"
                  type="button"><i class="fas fa-calendar-alt"></i></button>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3"> </div>
            <div class="col-md-3"> 
              <div  *ngIf="lifeWaysUniversal.get('476').invalid ">
                <small class="text-danger" *ngIf="lifeWaysUniversal.get('476').errors?.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY)
                </small>
                <small class="text-danger" *ngIf="lifeWaysUniversal.get('476').errors?.invalidMonth">
                Month is out of range
                </small>
                <small class="text-danger" *ngIf="lifeWaysUniversal.get('476').errors?.invalidDateyear">
                  year is out of range(1900-2099)                      </small>
                  <small class="text-danger" *ngIf="lifeWaysUniversal.get('476').errors?.invalidDateRange">
                    date is out of range
                    </small>
                </div>
            </div>
            <div class="col-md-3"> </div>
            <div class="col-md-3">
              <div  *ngIf="lifeWaysUniversal.get('477').invalid ">
                <small class="text-danger" *ngIf="lifeWaysUniversal.get('477').errors?.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY)
                </small>
                <small class="text-danger" *ngIf="lifeWaysUniversal.get('477').errors?.invalidMonth">
                Month is out of range
                </small>
                <small class="text-danger" *ngIf="lifeWaysUniversal.get('477').errors?.invalidDateyear">
                  year is out of range(1900-2099)                      </small>
                  <small class="text-danger" *ngIf="lifeWaysUniversal.get('477').errors?.invalidDateRange">
                    date is out of range
                    </small>
                </div> </div>
              </div>
          </li>
          <li>
            <div class="row sideHeadingAlignment">
              <div class="col-md-2">
                <label class="normalParagraph"> Company:</label>
              </div>
              <div class="col-md-4">
                <input class="form-control" id="1935" name="1935" formControlName="1935" type="text" data-id="lname">
              </div>
              <div class="col-md-2">
                <label class="normalParagraph"> Phone:</label>
              </div>
              <div class="col-md-3">
                <input class="form-control" id="1944" name="1944" formControlName="1944" type="text"
                  data-id="work_history_4_telephone">
              </div>
            </div>
            <div class="row sideHeadingAlignment">
              <div class="col-md-2">
                <label class="normalParagraph"> Address:</label>
              </div>
              <div class="col-md-9">
                <input class="form-control" id="1936" name="1936" formControlName="1936" type="text"
                  data-id="work_history_4__add1">
              </div>
            </div>
            <div class="row sideHeadingAlignment">
              <div class="col-md-2">
                <label class="normalParagraph"> Select County:</label>
              </div>
              <div class="form-group selectDropTwo suffix inputBoxPadding">
                <ng-select [items]="county" bindLabel="name" placeholder="Select County" bindValue="id"
                  formControlName="1939">
                </ng-select>
              </div>
            </div>
            <div class="row sideHeadingAlignment">
              <div class="col-md-2">
                <label class="normalParagraph"> Select State:</label>
              </div>
              <div class="form-group selectDropTwo suffix inputBoxPadding">
                <ng-select [items]="states" bindLabel="name" placeholder="Select State" bindValue="id"
                  formControlName="1940">
                </ng-select>
              </div>
            </div>
            <div class="row sideHeadingAlignment">
              <div class="col-md-2">
                <label class="normalParagraph"> Fax:</label>
              </div>
              <div class="col-md-4">
                <input class="form-control" id="1945" name="1945" formControlName="1945" type="text"
                  data-id="work_history_4_fax">
              </div>
            </div>
            <div class="row sideHeadingAlignment">
              <div class="col-md-2">
                <label class="normalParagraph"> City:</label>
              </div>
              <div class="col-md-4">
                <input class="form-control" id="1938" name="1938" formControlName="1938" type="text"
                  data-id="work_history_4__city">
              </div>
              <div class="col-md-2">
                <label class="normalParagraph"> Zip:</label>
              </div>
              <div class="col-md-3">
                <input class="form-control" id="1948" name="1948" formControlName="1948" type="text"
                  data-id="work_history_4__zip">
              </div>
            </div>
            <div class="row sideHeadingAlignment">
              <div class="col-md-2">
                <label class="normalParagraph"> JobTitle:</label>
              </div>
              <div class="col-md-9">
                <input class="form-control" id="1949" name="1949" formControlName="1949" type="text"
                  data-id="work_history_4_job_title">
              </div>
            </div>
            <div class="row sideHeadingAlignment">
              <div class="col-md-2">
                <label class="normalParagraph">Supervisor:</label>
              </div>
              <div class="col-md-9">
                <input class="form-control" id="1943" name="1943" formControlName="1943" type="text" data-id="lname">
              </div>
            </div>
            <div class="row sideHeadingAlignment">
              <div class="col-md-3">
                <label class="normalParagraph">Dates of Employment: </label>
              </div>
              <div class="col-md-2">
                <input class="form-control onboard datepicker" ngbDatepicker #d13="ngbDatepicker" id="1947" name="1947" (blur)="clearInputIfInvalid('1947')"
                  [class.is-invalid]="(lifeWaysUniversal.controls['1947'].touched && lifeWaysUniversal.controls['1947'].invalid) || (lifeWaysUniversal.controls['1947'].invalid && isSubmit)"
                  formControlName="1947" type="text" placeholder="DATE" data-id="managing_contact_name">
              </div>
              <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar calenderWidthWork" (click)="d13.toggle()"
                  type="button"><i class="fas fa-calendar-alt"></i></button>
              </div>
              <div class="col-md-3">
                <label class="normalParagraph">End Dates of Employment: </label>
              </div>
              <div class="col-md-2">
                <input class="form-control onboard datepicker" ngbDatepicker #d14="ngbDatepicker" id="1948" name="1948" (blur)="clearInputIfInvalid('1948')"
                  [class.is-invalid]="(lifeWaysUniversal.controls['1948'].touched && lifeWaysUniversal.controls['1948'].invalid) || (lifeWaysUniversal.controls['1948'].invalid && isSubmit)"
                  formControlName="1948" type="text" placeholder="DATE" data-id="managing_contact_name">
              </div>
              <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar calenderWidthWork" (click)="d14.toggle()"
                  type="button"><i class="fas fa-calendar-alt"></i></button>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3"> </div>
            <div class="col-md-3"> 
              <div  *ngIf="lifeWaysUniversal.get('1947').invalid ">
                <small class="text-danger" *ngIf="lifeWaysUniversal.get('1947').errors?.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY)
                </small>
                <small class="text-danger" *ngIf="lifeWaysUniversal.get('1947').errors?.invalidMonth">
                Month is out of range
                </small>
                <small class="text-danger" *ngIf="lifeWaysUniversal.get('1947').errors?.invalidDateyear">
                  year is out of range(1900-2099)                      </small>
                  <small class="text-danger" *ngIf="lifeWaysUniversal.get('1947').errors?.invalidDateRange">
                    date is out of range
                    </small>
                </div>
            </div>
            <div class="col-md-3"> </div>
            <div class="col-md-3">
              <div  *ngIf="lifeWaysUniversal.get('1948').invalid ">
                <small class="text-danger" *ngIf="lifeWaysUniversal.get('1948').errors?.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY)
                </small>
                <small class="text-danger" *ngIf="lifeWaysUniversal.get('1948').errors?.invalidMonth">
                Month is out of range
                </small>
                <small class="text-danger" *ngIf="lifeWaysUniversal.get('1948').errors?.invalidDateyear">
                  year is out of range(1900-2099)                      </small>
                  <small class="text-danger" *ngIf="lifeWaysUniversal.get('1948').errors?.invalidDateRange">
                    date is out of range
                    </small>
                </div> </div>
              </div>
          </li>
          <li>
            <div class="row sideHeadingAlignment">
              <div class="col-md-2">
                <label class="normalParagraph"> Company:</label>
              </div>
              <div class="col-md-4">
                <input class="form-control" id="1954" name="1954" formControlName="1954" type="text" data-id="lname">
              </div>
              <div class="col-md-2">
                <label class="normalParagraph"> Phone:</label>
              </div>
              <div class="col-md-3">
                <input class="form-control" id="1963" name="1963" formControlName="1963" type="text"
                  data-id="additional_work_history_phone">
              </div>
            </div>
            <div class="row sideHeadingAlignment">
              <div class="col-md-2">
                <label class="normalParagraph"> Address:</label>
              </div>
              <div class="col-md-9">
                <input class="form-control" id="1955" name="1955" formControlName="1955" type="text"
                  data-id="additional_work_history_add1">
              </div>
            </div>
            <div class="row sideHeadingAlignment">
              <div class="col-md-2">
                <label class="normalParagraph"> Select County:</label>
              </div>
              <div class="form-group selectDropTwo suffix inputBoxPadding">
                <ng-select [items]="county" bindLabel="name" placeholder="Select County" bindValue="id"
                  formControlName="1958">
                </ng-select>
              </div>
            </div>
            <div class="row sideHeadingAlignment">
              <div class="col-md-2">
                <label class="normalParagraph"> Select State:</label>
              </div>
              <div class="form-group selectDropTwo suffix inputBoxPadding">
                <ng-select [items]="states" bindLabel="name" placeholder="Select State" bindValue="id"
                  formControlName="1959">
                </ng-select>
              </div>
            </div>
            <div class="row sideHeadingAlignment">
              <div class="col-md-2">
                <label class="normalParagraph"> Fax:</label>
              </div>
              <div class="col-md-4">
                <input class="form-control" id="1964" name="1964" formControlName="1964" type="text"
                  data-id="additional_work_history_fax">
              </div>
            </div>
            <div class="row sideHeadingAlignment">
              <div class="col-md-2">
                <label class="normalParagraph"> City:</label>
              </div>
              <div class="col-md-4">
                <input class="form-control" id="1957" name="1957" formControlName="1957" type="text"
                  data-id="additional_work_history_city">
              </div>
              <div class="col-md-2">
                <label class="normalParagraph"> Zip:</label>
              </div>
              <div class="col-md-3">
                <input class="form-control" id="1960" name="1960" formControlName="1960" type="text"
                  data-id="additional_work_history_zip">
              </div>
            </div>
            <div class="row sideHeadingAlignment">
              <div class="col-md-2">
                <label class="normalParagraph"> JobTitle:</label>
              </div>
              <div class="col-md-9">
                <input class="form-control" id="1968" name="1968" formControlName="1968" type="text"
                  data-id="additional_work_history_job_title">
              </div>
            </div>
            <div class="row sideHeadingAlignment">
              <div class="col-md-2">
                <label class="normalParagraph">Supervisor:</label>
              </div>
              <div class="col-md-9">
                <input class="form-control" id="1962" name="1962" formControlName="1962" type="text" data-id="lname">
              </div>
            </div>
            <div class="row sideHeadingAlignment">
              <div class="col-md-3">
                <label class="normalParagraph">Start Dates of Employment: </label>
              </div>
              <div class="col-md-2">
                <input class="form-control onboard datepicker" ngbDatepicker #d15="ngbDatepicker" id="1966" name="1966" (blur)="clearInputIfInvalid('1966')"
                  [class.is-invalid]="(lifeWaysUniversal.controls['1966'].touched && lifeWaysUniversal.controls['1966'].invalid) || (lifeWaysUniversal.controls['1966'].invalid && isSubmit)"
                  formControlName="1966" type="text" placeholder="DATE" data-id="managing_contact_name">
              </div>
              <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar calenderWidthWork" (click)="d15.toggle()"
                  type="button"><i class="fas fa-calendar-alt"></i></button>
              </div>
              <div class="col-md-3">
                <label class="normalParagraph">End Dates of Employment: </label>
              </div>
              <div class="col-md-2">
                <input class="form-control onboard datepicker" ngbDatepicker #d16="ngbDatepicker" id="1967" name="1967" (blur)="clearInputIfInvalid('1967')"
                  [class.is-invalid]="(lifeWaysUniversal.controls['1967'].touched && lifeWaysUniversal.controls['1967'].invalid) || (lifeWaysUniversal.controls['1967'].invalid && isSubmit)"
                  formControlName="1967" type="text" placeholder="DATE" data-id="managing_contact_name">
              </div>
              <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar calenderWidthWork" (click)="d16.toggle()"
                  type="button"><i class="fas fa-calendar-alt"></i></button>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3"> </div>
            <div class="col-md-3"> 
              <div  *ngIf="lifeWaysUniversal.get('1966').invalid ">
                <small class="text-danger" *ngIf="lifeWaysUniversal.get('1966').errors?.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY)
                </small>
                <small class="text-danger" *ngIf="lifeWaysUniversal.get('1966').errors?.invalidMonth">
                Month is out of range
                </small>
                <small class="text-danger" *ngIf="lifeWaysUniversal.get('1966').errors?.invalidDateyear">
                  year is out of range(1900-2099)                      </small>
                  <small class="text-danger" *ngIf="lifeWaysUniversal.get('1966').errors?.invalidDateRange">
                    date is out of range
                    </small>
                </div>
            </div>
            <div class="col-md-3"> </div>
            <div class="col-md-3">
              <div  *ngIf="lifeWaysUniversal.get('1967').invalid ">
                <small class="text-danger" *ngIf="lifeWaysUniversal.get('1967').errors?.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY)
                </small>
                <small class="text-danger" *ngIf="lifeWaysUniversal.get('1967').errors?.invalidMonth">
                Month is out of range
                </small>
                <small class="text-danger" *ngIf="lifeWaysUniversal.get('1967').errors?.invalidDateyear">
                  year is out of range(1900-2099)                      </small>
                  <small class="text-danger" *ngIf="lifeWaysUniversal.get('1967').errors?.invalidDateRange">
                    date is out of range
                    </small>
                </div> </div>
              </div>
          </li>
        </ol> <br><br><br><br><br>
        <div class="">
          <h4 class="hThreeStyle"><b><u>DISCIPLINARY ACTIONS</u></b></h4>
          <br><br>
          <label class="universal-label formLabelLeftPadding">Have you ever had any of the following denied, revoked,
            suspended, reduced, limited, or placed on
            probation, or have you voluntarily relinquished any of the following in anticipation of any of these
            actions,
            or are any of these actions now pending? <b>IF YES, SUBMIT A FULL EXPLANATION TO YOUR
              AGENCY ON A SEPARATE SHEET.</b></label>
        </div><br><br><br><br>
        <table class="southform_table disicinaryTable">
          <tr>
            <td colspan="9" bgcolor="#a6a6a6">
              <div class="col-md-9 inputBoxPadding">
                <label class="centerNormalParagraph"></label>
              </div>
            </td>
            <td colspan="1" bgcolor="#a6a6a6">
              <div class="col-md-1 inputBoxPadding">
                <label class="centerNormalParagraph">YES </label>
              </div>
            </td>
            <td colspan="1" bgcolor="#a6a6a6">
              <div class="col-md-1 inputBoxPadding">
                <label class="centerNormalParagraph">NO</label>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan=9>
              <div class="col-md-9 inputBoxPadding">
                <label class="normalParagraph">1. License, in any State</label>
              </div>
            </td>
            <td colspan="1">
              <div class="col-md-1 normalParagraph">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="checkbox-inline radioStyle">
                    <input type="radio" id="cmd_2500024" name="cmd_2500024"
                      [class.is-invalid]="(lifeWaysUniversal.controls['cmd_2500024'].touched && lifeWaysUniversal.controls['cmd_2500024'].invalid) || (lifeWaysUniversal.controls['cmd_2500024'].invalid && isSubmit)"
                      formControlName="cmd_2500024" value="1"></label>
                </div>
              </div>
            </td>
            <td colspan="1">
              <div class="col-md-1 normalParagraph">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="checkbox-inline radioStyle">
                    <input type="radio" id="cmd_2500024" name="cmd_2500024"
                      [class.is-invalid]="(lifeWaysUniversal.controls['cmd_2500024'].touched && lifeWaysUniversal.controls['cmd_2500024'].invalid) || (lifeWaysUniversal.controls['cmd_2500024'].invalid && isSubmit)"
                      formControlName="cmd_2500024" value="0"></label>
                  <div class="formLabel col-md-11"
                    *ngIf="lifeWaysUniversal.controls['cmd_2500024'].touched && lifeWaysUniversal.controls['cmd_2500024'].invalid">
                    <small class="text-danger" *ngIf="lifeWaysUniversal.controls['cmd_2500024'].errors?.required">
                      required
                    </small>
                  </div>
                </div>
              </div>
            </td>
            <span
              *ngIf="(lifeWaysUniversal.controls['cmd_2500024'].touched && lifeWaysUniversal.controls['cmd_2500024'].invalid) || (lifeWaysUniversal.controls['cmd_2500024'].invalid && isSubmit)">
              <i class="fas fa-times ml-2 text-danger"></i>
            </span>
          </tr>
          <tr>
            <td colspan=9>
              <div class="col-md-9 inputBoxPadding">
                <label class="normalParagraph">2. DEA Registration</label>
              </div>
            </td>
            <td colspan="1">
              <div class="col-md-1 normalParagraph">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="checkbox-inline radioStyle">
                    <input type="radio" id="cmd_2500025" name="cmd_2500025"
                      [class.is-invalid]="(lifeWaysUniversal.controls['cmd_2500025'].touched && lifeWaysUniversal.controls['cmd_2500025'].invalid) || (lifeWaysUniversal.controls['cmd_2500025'].invalid && isSubmit)"
                      formControlName="cmd_2500025" value="1"></label>
                </div>
              </div>
            </td>
            <td colspan="1">
              <div class="col-md-1 normalParagraph">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="checkbox-inline radioStyle">
                    <input type="radio" id="cmd_2500025" name="cmd_2500025"
                      [class.is-invalid]="(lifeWaysUniversal.controls['cmd_2500025'].touched && lifeWaysUniversal.controls['cmd_2500025'].invalid) || (lifeWaysUniversal.controls['cmd_2500025'].invalid && isSubmit)"
                      formControlName="cmd_2500025" value="0"></label>
                  <div class="formLabel col-md-11"
                    *ngIf="lifeWaysUniversal.controls['cmd_2500025'].touched && lifeWaysUniversal.controls['cmd_2500025'].invalid">
                    <small class="text-danger" *ngIf="lifeWaysUniversal.controls['cmd_2500025'].errors?.required">
                      required
                    </small>
                  </div>
                </div>
              </div>
            </td>
            <span
              *ngIf="(lifeWaysUniversal.controls['cmd_2500025'].touched && lifeWaysUniversal.controls['cmd_2500025'].invalid) || (lifeWaysUniversal.controls['cmd_2500025'].invalid && isSubmit)">
              <i class="fas fa-times ml-2 text-danger"></i>
            </span>
          </tr>
          <tr>
            <td colspan=9>
              <div class="col-md-9 inputBoxPadding">
                <label class="normalParagraph">3. Other professional Registration/License</label>
              </div>
            </td>
            <td colspan="1">
              <div class="col-md-1 normalParagraph">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="checkbox-inline radioStyle">
                    <input type="radio" id="cmd_2500026" name="cmd_2500026"
                      [class.is-invalid]="(lifeWaysUniversal.controls['cmd_2500026'].touched && lifeWaysUniversal.controls['cmd_2500026'].invalid) || (lifeWaysUniversal.controls['cmd_2500026'].invalid && isSubmit)"
                      formControlName="cmd_2500026" value="1"></label>
                </div>
              </div>
            </td>
            <td colspan="1">
              <div class="col-md-1 normalParagraph">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="checkbox-inline radioStyle">
                    <input type="radio" id="cmd_2500026" name="cmd_2500026"
                      [class.is-invalid]="(lifeWaysUniversal.controls['cmd_2500026'].touched && lifeWaysUniversal.controls['cmd_2500026'].invalid) || (lifeWaysUniversal.controls['cmd_2500026'].invalid && isSubmit)"
                      formControlName="cmd_2500026" value="0"></label>
                  <div class="formLabel col-md-11"
                    *ngIf="lifeWaysUniversal.controls['cmd_2500026'].touched && lifeWaysUniversal.controls['cmd_2500026'].invalid">
                    <small class="text-danger" *ngIf="lifeWaysUniversal.controls['cmd_2500026'].errors?.required">
                      required
                    </small>
                  </div>
                </div>
              </div>
            </td>
            <span
              *ngIf="(lifeWaysUniversal.controls['cmd_2500026'].touched && lifeWaysUniversal.controls['cmd_2500026'].invalid) || (lifeWaysUniversal.controls['cmd_2500026'].invalid && isSubmit)">
              <i class="fas fa-times ml-2 text-danger"></i>
            </span>
          </tr>
          <tr>
            <td colspan=9>
              <div class="col-md-9 inputBoxPadding">
                <label class="normalParagraph">4. Medical/Hospital Staff Membership</label>
              </div>
            </td>
            <td colspan="1">
              <div class="col-md-1 normalParagraph">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="checkbox-inline radioStyle">
                    <input type="radio" id="cmd_2500027" name="cmd_2500027"
                      [class.is-invalid]="(lifeWaysUniversal.controls['cmd_2500027'].touched && lifeWaysUniversal.controls['cmd_2500027'].invalid) || (lifeWaysUniversal.controls['cmd_2500027'].invalid && isSubmit)"
                      formControlName="cmd_2500027" value="1"></label>
                </div>
              </div>
            </td>
            <td colspan="1">
              <div class="col-md-1 normalParagraph">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="checkbox-inline radioStyle">
                    <input type="radio" id="cmd_2500027" name="cmd_2500027"
                      [class.is-invalid]="(lifeWaysUniversal.controls['cmd_2500027'].touched && lifeWaysUniversal.controls['cmd_2500027'].invalid) || (lifeWaysUniversal.controls['cmd_2500027'].invalid && isSubmit)"
                      formControlName="cmd_2500027" value="0"></label>
                  <div class="formLabel col-md-11"
                    *ngIf="lifeWaysUniversal.controls['cmd_2500027'].touched && lifeWaysUniversal.controls['cmd_2500027'].invalid">
                    <small class="text-danger" *ngIf="lifeWaysUniversal.controls['cmd_2500027'].errors?.required">
                      required
                    </small>
                  </div>
                </div>
              </div>
            </td>
            <span
              *ngIf="(lifeWaysUniversal.controls['cmd_2500027'].touched && lifeWaysUniversal.controls['cmd_2500027'].invalid) || (lifeWaysUniversal.controls['cmd_2500027'].invalid && isSubmit)">
              <i class="fas fa-times ml-2 text-danger"></i>
            </span>
          </tr>
          <tr>
            <td colspan=9>
              <div class="col-md-9 inputBoxPadding">
                <label class="normalParagraph">5. Clinical Privileges</label>
              </div>
            </td>
            <td colspan="1">
              <div class="col-md-1 normalParagraph">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="checkbox-inline radioStyle">
                    <input type="radio" id="cmd_2500028" name="cmd_2500028"
                      [class.is-invalid]="(lifeWaysUniversal.controls['cmd_2500028'].touched && lifeWaysUniversal.controls['cmd_2500028'].invalid) || (lifeWaysUniversal.controls['cmd_2500028'].invalid && isSubmit)"
                      formControlName="cmd_2500028" value="1"></label>
                </div>
              </div>
            </td>
            <td colspan="1">
              <div class="col-md-1 normalParagraph">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="checkbox-inline radioStyle">
                    <input type="radio" id="cmd_2500028" name="cmd_2500028"
                      [class.is-invalid]="(lifeWaysUniversal.controls['cmd_2500028'].touched && lifeWaysUniversal.controls['cmd_2500028'].invalid) || (lifeWaysUniversal.controls['cmd_2500028'].invalid && isSubmit)"
                      formControlName="cmd_2500028" value="0"></label>
                  <div class="formLabel col-md-11"
                    *ngIf="lifeWaysUniversal.controls['cmd_2500028'].touched && lifeWaysUniversal.controls['cmd_2500028'].invalid">
                    <small class="text-danger" *ngIf="lifeWaysUniversal.controls['cmd_2500028'].errors?.required">
                      required
                    </small>
                  </div>
                </div>
              </div>
            </td>
            <span
              *ngIf="(lifeWaysUniversal.controls['cmd_2500028'].touched && lifeWaysUniversal.controls['cmd_2500028'].invalid) || (lifeWaysUniversal.controls['cmd_2500028'].invalid && isSubmit)">
              <i class="fas fa-times ml-2 text-danger"></i>
            </span>
          </tr>
          <tr>
            <td colspan=9>
              <div class="col-md-9 inputBoxPadding">
                <label class="normalParagraph">6. Professional Liability Insurance</label>
              </div>
            </td>
            <td colspan="1">
              <div class="col-md-1 normalParagraph">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="checkbox-inline radioStyle">
                    <input type="radio" id="cmd_2500029" name="cmd_2500029"
                      [class.is-invalid]="(lifeWaysUniversal.controls['cmd_2500029'].touched && lifeWaysUniversal.controls['cmd_2500029'].invalid) || (lifeWaysUniversal.controls['cmd_2500029'].invalid && isSubmit)"
                      formControlName="cmd_2500029" value="1"></label>
                </div>
              </div>
            </td>
            <td colspan="1">
              <div class="col-md-1 normalParagraph">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="checkbox-inline radioStyle">
                    <input type="radio" id="cmd_2500029" name="cmd_2500029"
                      [class.is-invalid]="(lifeWaysUniversal.controls['cmd_2500029'].touched && lifeWaysUniversal.controls['cmd_2500029'].invalid) || (lifeWaysUniversal.controls['cmd_2500029'].invalid && isSubmit)"
                      formControlName="cmd_2500029" value="0"></label>
                  <div class="formLabel col-md-11"
                    *ngIf="lifeWaysUniversal.controls['cmd_2500029'].touched && lifeWaysUniversal.controls['cmd_2500029'].invalid">
                    <small class="text-danger" *ngIf="lifeWaysUniversal.controls['cmd_2500029'].errors?.required">
                      required
                    </small>
                  </div>
                </div>
              </div>
            </td>
            <span
              *ngIf="(lifeWaysUniversal.controls['cmd_2500029'].touched && lifeWaysUniversal.controls['cmd_2500029'].invalid) || (lifeWaysUniversal.controls['cmd_2500029'].invalid && isSubmit)">
              <i class="fas fa-times ml-2 text-danger"></i>
            </span>
          </tr>
          <tr>
            <td colspan=9>
              <div class="col-md-9 inputBoxPadding">
                <label class="normalParagraph">7. Have you had malpractice suits settled against you in the past five
                  (5) years, or currently
                  pending? (Attachment 2)</label>
              </div>
            </td>
            <td colspan="1">
              <div class="col-md-1 normalParagraph">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="checkbox-inline radioStyle">
                    <input type="radio" id="cmd_2500030" name="cmd_2500030"
                      [class.is-invalid]="(lifeWaysUniversal.controls['cmd_2500030'].touched && lifeWaysUniversal.controls['cmd_2500030'].invalid) || (lifeWaysUniversal.controls['cmd_2500030'].invalid && isSubmit)"
                      formControlName="cmd_2500030" value="1"></label>
                </div>
              </div>
            </td>
            <td colspan="1">
              <div class="col-md-1 normalParagraph">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="checkbox-inline radioStyle">
                    <input type="radio" id="cmd_2500030" name="cmd_2500030"
                      [class.is-invalid]="(lifeWaysUniversal.controls['cmd_2500030'].touched && lifeWaysUniversal.controls['cmd_2500030'].invalid) || (lifeWaysUniversal.controls['cmd_2500030'].invalid && isSubmit)"
                      formControlName="cmd_2500030" value="0"></label>
                  <div class="formLabel col-md-11"
                    *ngIf="lifeWaysUniversal.controls['cmd_2500030'].touched && lifeWaysUniversal.controls['cmd_2500030'].invalid">
                    <small class="text-danger" *ngIf="lifeWaysUniversal.controls['cmd_2500030'].errors?.required">
                      required
                    </small>
                  </div>
                </div>
              </div>
            </td>
            <span
              *ngIf="(lifeWaysUniversal.controls['cmd_2500030'].touched && lifeWaysUniversal.controls['cmd_2500030'].invalid) || (lifeWaysUniversal.controls['cmd_2500030'].invalid && isSubmit)">
              <i class="fas fa-times ml-2 text-danger"></i>
            </span>
          </tr>
          <tr>
            <td colspan=9>
              <div class="col-md-9 inputBoxPadding">
                <label class="normalParagraph">8. Have you had any other professional sanction?</label>
              </div>
            </td>
            <td colspan="1">
              <div class="col-md-1 normalParagraph">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="checkbox-inline radioStyle">
                    <input type="radio" id="cmd_2500031" name="cmd_2500031"
                      [class.is-invalid]="(lifeWaysUniversal.controls['cmd_2500031'].touched && lifeWaysUniversal.controls['cmd_2500031'].invalid) || (lifeWaysUniversal.controls['cmd_2500031'].invalid && isSubmit)"
                      formControlName="cmd_2500031" value="1"></label>
                </div>
              </div>
            </td>
            <td colspan="1">
              <div class="col-md-1 normalParagraph">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="checkbox-inline radioStyle">
                    <input type="radio" id="cmd_2500031" name="cmd_2500031"
                      [class.is-invalid]="(lifeWaysUniversal.controls['cmd_2500031'].touched && lifeWaysUniversal.controls['cmd_2500031'].invalid) || (lifeWaysUniversal.controls['cmd_2500031'].invalid && isSubmit)"
                      formControlName="cmd_2500031" value="0"></label>
                  <div class="formLabel col-md-11"
                    *ngIf="lifeWaysUniversal.controls['cmd_2500031'].touched && lifeWaysUniversal.controls['cmd_2500031'].invalid">
                    <small class="text-danger" *ngIf="lifeWaysUniversal.controls['cmd_2500031'].errors?.required">
                      required
                    </small>
                  </div>
                </div>
              </div>
            </td>
            <span
              *ngIf="(lifeWaysUniversal.controls['cmd_2500031'].touched && lifeWaysUniversal.controls['cmd_2500031'].invalid) || (lifeWaysUniversal.controls['cmd_2500031'].invalid && isSubmit)">
              <i class="fas fa-times ml-2 text-danger"></i>
            </span>
          </tr>
          <tr>
            <td colspan=9>
              <div class="col-md-9 inputBoxPadding">
                <label class="normalParagraph">9. Have you had any professional action against you which was resolved by
                  monetary settlement? (Attachment 2)</label>
              </div>
            </td>
            <td colspan="1">
              <div class="col-md-1 normalParagraph">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="checkbox-inline radioStyle">
                    <input type="radio" id="cmd_2500032" name="cmd_2500032"
                      [class.is-invalid]="(lifeWaysUniversal.controls['cmd_2500032'].touched && lifeWaysUniversal.controls['cmd_2500032'].invalid) || (lifeWaysUniversal.controls['cmd_2500032'].invalid && isSubmit)"
                      formControlName="cmd_2500032" value="1"></label>
                </div>
              </div>
            </td>
            <td colspan="1">
              <div class="col-md-1 normalParagraph">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="checkbox-inline radioStyle">
                    <input type="radio" id="cmd_2500032" name="cmd_2500032"
                      [class.is-invalid]="(lifeWaysUniversal.controls['cmd_2500032'].touched && lifeWaysUniversal.controls['cmd_2500032'].invalid) || (lifeWaysUniversal.controls['cmd_2500032'].invalid && isSubmit)"
                      formControlName="cmd_2500032" value="0"></label>
                  <div class="formLabel col-md-11"
                    *ngIf="lifeWaysUniversal.controls['cmd_2500032'].touched && lifeWaysUniversal.controls['cmd_2500032'].invalid">
                    <small class="text-danger" *ngIf="lifeWaysUniversal.controls['cmd_2500032'].errors?.required">
                      required
                    </small>
                  </div>
                </div>
              </div>
            </td>
            <span
              *ngIf="(lifeWaysUniversal.controls['cmd_2500032'].touched && lifeWaysUniversal.controls['cmd_2500032'].invalid) || (lifeWaysUniversal.controls['cmd_2500032'].invalid && isSubmit)">
              <i class="fas fa-times ml-2 text-danger"></i>
            </span>
          </tr>
          <tr>
            <td colspan=9>
              <div class="col-md-9 inputBoxPadding">
                <label class="normalParagraph">10. Are there any medical incidents for which you have been contacted by
                  an attorney
                  regarding potential malpractice liability (settlement request, writ of summons, etc.)?</label>
              </div>
            </td>
            <td colspan="1">
              <div class="col-md-1 normalParagraph">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="checkbox-inline radioStyle">
                    <input type="radio" id="cmd_2500033" name="cmd_2500033"
                      [class.is-invalid]="(lifeWaysUniversal.controls['cmd_2500033'].touched && lifeWaysUniversal.controls['cmd_2500033'].invalid) || (lifeWaysUniversal.controls['cmd_2500033'].invalid && isSubmit)"
                      formControlName="cmd_2500033" value="1"></label>
                </div>
              </div>
            </td>
            <td colspan="1">
              <div class="col-md-1 normalParagraph">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="checkbox-inline radioStyle">
                    <input type="radio" id="cmd_2500033" name="cmd_2500033"
                      [class.is-invalid]="(lifeWaysUniversal.controls['cmd_2500033'].touched && lifeWaysUniversal.controls['cmd_2500033'].invalid) || (lifeWaysUniversal.controls['cmd_2500033'].invalid && isSubmit)"
                      formControlName="cmd_2500033" value="0"></label>
                  <div class="formLabel col-md-11"
                    *ngIf="lifeWaysUniversal.controls['cmd_2500033'].touched && lifeWaysUniversal.controls['cmd_2500033'].invalid">
                    <small class="text-danger" *ngIf="lifeWaysUniversal.controls['cmd_2500033'].errors?.required">
                      required
                    </small>
                  </div>
                </div>
              </div>
            </td>
            <span
              *ngIf="(lifeWaysUniversal.controls['cmd_2500033'].touched && lifeWaysUniversal.controls['cmd_2500033'].invalid) || (lifeWaysUniversal.controls['cmd_2500033'].invalid && isSubmit)">
              <i class="fas fa-times ml-2 text-danger"></i>
            </span>
          </tr>
          <tr>
            <td colspan=9>
              <div class="col-md-9 inputBoxPadding">
                <label class="normalParagraph">11. Within the past ten (10) years, have you ever been convicted of, or
                  pleaded guilty to, a
                  criminal offense, including a verdict of guilty following a plea of nolo contendere?
                  (Attachment 1)</label>
              </div>
            </td>
            <td colspan="1">
              <div class="col-md-1 normalParagraph">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="checkbox-inline radioStyle">
                    <input type="radio" id="cmd_2500034" name="cmd_2500034"
                      [class.is-invalid]="(lifeWaysUniversal.controls['cmd_2500034'].touched && lifeWaysUniversal.controls['cmd_2500034'].invalid) || (lifeWaysUniversal.controls['cmd_2500034'].invalid && isSubmit)"
                      formControlName="cmd_2500034" value="1"></label>
                </div>
              </div>
            </td>
            <td colspan="1">
              <div class="col-md-1 normalParagraph">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="checkbox-inline radioStyle">
                    <input type="radio" id="cmd_2500034" name="cmd_2500034"
                      [class.is-invalid]="(lifeWaysUniversal.controls['cmd_2500034'].touched && lifeWaysUniversal.controls['cmd_2500034'].invalid) || (lifeWaysUniversal.controls['cmd_2500034'].invalid && isSubmit)"
                      formControlName="cmd_2500034" value="0"></label>
                  <div class="formLabel col-md-11"
                    *ngIf="lifeWaysUniversal.controls['cmd_2500034'].touched && lifeWaysUniversal.controls['cmd_2500034'].invalid">
                    <small class="text-danger" *ngIf="lifeWaysUniversal.controls['cmd_2500034'].errors?.required">
                      required
                    </small>
                  </div>
                </div>
              </div>
            </td>
            <span
              *ngIf="(lifeWaysUniversal.controls['cmd_2500034'].touched && lifeWaysUniversal.controls['cmd_2500034'].invalid) || (lifeWaysUniversal.controls['cmd_2500034'].invalid && isSubmit)">
              <i class="fas fa-times ml-2 text-danger"></i>
            </span>
          </tr>
          <tr>
            <td colspan=9>
              <div class="col-md-9 inputBoxPadding">
                <label class="normalParagraph">12. Have you had any Medicaid, Medicare, or other governmental or
                  third-party payor
                  sanctions?</label>
              </div>
            </td>
            <td colspan="1">
              <div class="col-md-1 normalParagraph">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="checkbox-inline radioStyle">
                    <input type="radio" id="cmd_2500035" name="cmd_2500035"
                      [class.is-invalid]="(lifeWaysUniversal.controls['cmd_2500035'].touched && lifeWaysUniversal.controls['cmd_2500035'].invalid) || (lifeWaysUniversal.controls['cmd_2500035'].invalid && isSubmit)"
                      formControlName="cmd_2500035" value="1"></label>
                </div>
              </div>
            </td>
            <td colspan="1">
              <div class="col-md-1 normalParagraph">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="checkbox-inline radioStyle">
                    <input type="radio" id="cmd_2500035" name="cmd_2500035"
                      [class.is-invalid]="(lifeWaysUniversal.controls['cmd_2500035'].touched && lifeWaysUniversal.controls['cmd_2500035'].invalid) || (lifeWaysUniversal.controls['cmd_2500035'].invalid && isSubmit)"
                      formControlName="cmd_2500035" value="0"></label>
                  <div class="formLabel col-md-11"
                    *ngIf="lifeWaysUniversal.controls['cmd_2500035'].touched && lifeWaysUniversal.controls['cmd_2500035'].invalid">
                    <small class="text-danger" *ngIf="lifeWaysUniversal.controls['cmd_2500035'].errors?.required">
                      required
                    </small>
                  </div>
                </div>
              </div>
            </td>
            <span
              *ngIf="(lifeWaysUniversal.controls['cmd_2500035'].touched && lifeWaysUniversal.controls['cmd_2500035'].invalid) || (lifeWaysUniversal.controls['cmd_2500035'].invalid && isSubmit)">
              <i class="fas fa-times ml-2 text-danger"></i>
            </span>
          </tr>
          <tr>
            <td colspan=9>
              <div class="col-md-9 inputBoxPadding">
                <label class="normalParagraph">13. Have you ever been excluded from the Medicare program? </label><br>
                <div *ngIf="changeCmdOne">

                  <label class="normalParagraph">If yes, specify date:</label>
                  <!-- <div class="col-md-3">
                                        <input class="form-control onboard datepicker" ngbDatepicker #d40="ngbDatepicker" style = "width:-10px" id="cmd_25000135"
                                        [class.is-invalid]="lifeWaysUniversal.controls['cmd_25000135'].value==null"
                                        formControlName="cmd_25000135" name="cmd_25000135" type="text" placeholder= "DATE" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="lifeWaysUniversal.controls['cmd_25000135'].touched && lifeWaysUniversal.controls['cmd_25000135'].invalid">
                                            <small class="text-danger" *ngIf="lifeWaysUniversal.controls['cmd_25000135'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                        <div class="input-group-append">
                                            <button class="btn btn-outline-secondary calendar calenderWidthWorkH" (click)="d40.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                        </div>
                                    </div> -->
                  <!-- <div class="col-md-3">
                                        <input class="form-control onboard datepicker" ngbDatepicker #d40="ngbDatepicker" placeholder="MM/DD/YYYY" id="cmd_25000135" name="cmd_25000135" [class.is-invalid]="lifeWaysUniversal.controls['cmd_25000135'].value==null" formControlName="cmd_25000135" type="text" data-id="Mil Active from">
                                        <div class="input-group-append">
                                            <button class="btn btn-outline-secondary calendar calenderWidthWorkH" (click)="d40.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                        </div>
                                        <div class="formLabel col-md-11"
                                            *ngIf="lifeWaysUniversal.controls['cmd_25000135'].touched && lifeWaysUniversal.controls['cmd_25000135'].invalid">
                                            <small class="text-danger" *ngIf="lifeWaysUniversal.controls['cmd_25000135'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div> -->
                  <div class="col-md-3">
                    <input class="form-control onboard datepicker" ngbDatepicker #d89="ngbDatepicker"
                      placeholder="MM/DD/YYYY" id="cmd_25000135" name="cmd_25000135" (blur)="clearInputIfInvalidForYesorNo('cmd_25000135')"
                      [class.is-invalid]="lifeWaysUniversal.controls['cmd_25000135'].value==null"
                      formControlName="cmd_25000135" type="text" data-id="Mil Active from">
                    <div class="input-group-append">
                      <button class="btn btn-outline-secondary calendar calenderWidthSet" (click)="d89.toggle()"
                        type="button"><i class="fas fa-calendar-alt"></i></button>
                    </div>
                    <div class="formLabel col-md-11"
                      *ngIf="lifeWaysUniversal.controls['cmd_25000135'].touched && lifeWaysUniversal.controls['cmd_25000135'].invalid">
                      <small class="text-danger" *ngIf="lifeWaysUniversal.controls['cmd_25000135'].errors?.required">
                        required
                      </small>
                    </div>
                    <div  *ngIf="lifeWaysUniversal.get('cmd_25000135').invalid">
                      <small class="text-danger" *ngIf="lifeWaysUniversal.get('cmd_25000135').errors.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)                  </small>
                      <small class="text-danger" *ngIf="lifeWaysUniversal.get('cmd_25000135').errors.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="lifeWaysUniversal.get('cmd_25000135').errors.invalidDateyear">
                        year is out of range(1900-2099)                    </small>
                        <small class="text-danger" *ngIf="lifeWaysUniversal.get('cmd_25000135').errors.invalidDateRange">
                          date is out of range
                          </small>
                      </div>

                  </div>
                  <div class="col-md-3">
                    <label class="normalParagraph">Date of Reinstatement: </label>
                    <input class="form-control onboard datepicker" ngbDatepicker #d50="ngbDatepicker" id="cmd_25000136" (blur)="clearInputIfInvalidForYesorNo('cmd_25000136')"
                      name="cmd_25000136" [class.is-invalid]="lifeWaysUniversal.controls['cmd_25000136'].value==null"
                      formControlName="cmd_25000136" type="text" placeholder="DATE" data-id="managing_contact_name">
                    <div class="formLabel col-md-11"
                      *ngIf="lifeWaysUniversal.controls['cmd_25000136'].touched && lifeWaysUniversal.controls['cmd_25000136'].invalid">
                      <small class="text-danger" *ngIf="lifeWaysUniversal.controls['cmd_25000136'].errors?.required">
                        required
                      </small>
                    </div>
                    <div class="input-group-append">
                      <button class="btn btn-outline-secondary calendar calenderWidthWorkH" (click)="d50.toggle()"
                        type="button"><i class="fas fa-calendar-alt"></i></button>
                    </div>
                    <div  *ngIf="lifeWaysUniversal.get('cmd_25000136').invalid ">
                      <small class="text-danger" *ngIf="lifeWaysUniversal.get('cmd_25000136').errors.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)                  </small>
                      <small class="text-danger" *ngIf="lifeWaysUniversal.get('cmd_25000136').errors.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="lifeWaysUniversal.get('cmd_25000136').errors.invalidDateyear">
                        year is out of range(1900-2099)                    </small>
                        <small class="text-danger" *ngIf="lifeWaysUniversal.get('cmd_25000136').errors.invalidDateRange">
                          date is out of range
                          </small>
                      </div>
                  </div>
                </div>
              </div>
            </td>
            <td colspan="1">
              <div class="col-md-1 normalParagraph">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="checkbox-inline radioStyle">
                    <input type="radio" id="cmd_2500036" name="cmd_2500036" formControlName="cmd_2500036"
                      [class.is-invalid]="(lifeWaysUniversal.controls['cmd_2500036'].touched && lifeWaysUniversal.controls['cmd_2500036'].invalid) || (lifeWaysUniversal.controls['cmd_2500036'].invalid && isSubmit)"
                      (change)="onChangeCmdOne($event)" value="1"></label>
                </div>
              </div>
            </td>
            <td colspan="1">
              <div class="col-md-1 normalParagraph">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="checkbox-inline radioStyle">
                    <input type="radio" id="cmd_2500036" name="cmd_2500036" formControlName="cmd_2500036"
                      [class.is-invalid]="(lifeWaysUniversal.controls['cmd_2500036'].touched && lifeWaysUniversal.controls['cmd_2500036'].invalid) || (lifeWaysUniversal.controls['cmd_2500036'].invalid && isSubmit)"
                      (change)="onChangeCmdOne($event)" value="0"></label>
                  <div class="formLabel col-md-11"
                    *ngIf="lifeWaysUniversal.controls['cmd_2500036'].touched && lifeWaysUniversal.controls['cmd_2500036'].invalid">
                    <small class="text-danger" *ngIf="lifeWaysUniversal.controls['cmd_2500036'].errors?.required">
                      required
                    </small>
                  </div>
                </div>
              </div>
            </td>
            <span
              *ngIf="(lifeWaysUniversal.controls['cmd_2500036'].touched && lifeWaysUniversal.controls['cmd_2500036'].invalid) || (lifeWaysUniversal.controls['cmd_2500036'].invalid && isSubmit)">
              <i class="fas fa-times ml-2 text-danger"></i>
            </span>
          </tr>
          <tr>
            <td colspan=9>
              <div class="col-md-9 inputBoxPadding">
                <label class="normalParagraph">14. Have civil and monetary penalties been levied against you by Medicare
                  or Medicaid
                  programs?</label>
              </div>
            </td>
            <td colspan="1">
              <div class="col-md-1 normalParagraph">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="checkbox-inline radioStyle">
                    <input type="radio" id="cmd_2500037" name="cmd_2500037"
                      [class.is-invalid]="(lifeWaysUniversal.controls['cmd_2500037'].touched && lifeWaysUniversal.controls['cmd_2500037'].invalid) || (lifeWaysUniversal.controls['cmd_2500037'].invalid && isSubmit)"
                      formControlName="cmd_2500037" value="1"></label>
                </div>
              </div>
            </td>
            <td colspan="1">
              <div class="col-md-1 normalParagraph">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="checkbox-inline radioStyle">
                    <input type="radio" id="cmd_2500037" name="cmd_2500037"
                      [class.is-invalid]="(lifeWaysUniversal.controls['cmd_2500037'].touched && lifeWaysUniversal.controls['cmd_2500037'].invalid) || (lifeWaysUniversal.controls['cmd_2500037'].invalid && isSubmit)"
                      formControlName="cmd_2500037" value="0"></label>
                  <div class="formLabel col-md-11"
                    *ngIf="lifeWaysUniversal.controls['cmd_2500037'].touched && lifeWaysUniversal.controls['cmd_2500037'].invalid">
                    <small class="text-danger" *ngIf="lifeWaysUniversal.controls['cmd_2500037'].errors?.required">
                      required
                    </small>
                  </div>
                </div>
              </div>
            </td>
            <span
              *ngIf="(lifeWaysUniversal.controls['cmd_2500037'].touched && lifeWaysUniversal.controls['cmd_2500037'].invalid) || (lifeWaysUniversal.controls['cmd_2500037'].invalid && isSubmit)">
              <i class="fas fa-times ml-2 text-danger"></i>
            </span>
          </tr>
        </table> <br><br><br><br>
        <div class="row">
          <div class="form-group form-inline no-margin col-md-12">
            <h4 class="hThreeStyle"><b>Statement of Ability to Perform</b></h4>
          </div>
        </div><br><br><br><br>
        <table class="southform_table disicinaryTable">
          <tr>
            <td colspan=7>
              <div class="col-md-7 inputBoxPadding">
                <label class="hThreeStyle">Do you now, or have you had any physical condition, mental condition, or
                  substance abuse
                  condition (alcohol, illegal or prescription drugs) that has interfered with your ability to practice
                  or perform clinical duties, or led to suspension, termination, or any other disciplinary action(s)
                  toward privileges or employment?</label>
              </div>
            </td>
            <td colspan="2">
              <div class="col-md-2 inputBoxPadding">
                <div class="col-md-2 inputBoxPadding">
                  <label class="checkbox-inline radioStyle">YES</label>
                  <input type="radio" id="cmd_25000134" name="cmd_25000134"
                    [class.is-invalid]="(lifeWaysUniversal.controls['cmd_25000134'].touched && lifeWaysUniversal.controls['cmd_25000134'].invalid) || (lifeWaysUniversal.controls['cmd_25000134'].invalid && isSubmit)"
                    formControlName="cmd_25000134" value="1">
                </div>
              </div>
            </td>
            <td colspan="2">
              <div class="col-md-2 inputBoxPadding">
                <div class="col-md-2 inputBoxPadding">
                  <label class="checkbox-inline radioStyle">NO</label>
                  <input type="radio" id="cmd_25000134" name="cmd_25000134"
                    [class.is-invalid]="(lifeWaysUniversal.controls['cmd_25000134'].touched && lifeWaysUniversal.controls['cmd_25000134'].invalid) || (lifeWaysUniversal.controls['cmd_25000134'].invalid && isSubmit)"
                    formControlName="cmd_25000134" value="0">
                  <div class="formLabel col-md-11"
                    *ngIf="lifeWaysUniversal.controls['cmd_25000134'].touched && lifeWaysUniversal.controls['cmd_25000134'].invalid">
                    <small class="text-danger" *ngIf="lifeWaysUniversal.controls['cmd_25000134'].errors?.required">
                      required
                    </small>
                  </div>
                </div>
              </div>
            </td>
            <span
              *ngIf="(lifeWaysUniversal.controls['cmd_25000134'].touched && lifeWaysUniversal.controls['cmd_25000134'].invalid) || (lifeWaysUniversal.controls['cmd_25000134'].invalid && isSubmit)">
              <i class="fas fa-times ml-2 text-danger"></i>
            </span>
          </tr>
        </table><br><br>
        <div class="row paddingSet">
          <div class="">
            <h4 class="hThreeStylePadding"><b>Please specify all fluent communicable languages (including sign language)
                in order of
                competency: </b></h4>
          </div>
        </div><br><br>
        <table class="southform_table languageTable">
          <tr>
            <td colspan="3" bgcolor="#a6a6a6">
              <div class="col-md-3 inputBoxPadding">
                <label class="checkBoxHeading">1st </label>
              </div>
            </td>
            <td colspan="3" bgcolor="#a6a6a6">
              <div class="col-md-3 inputBoxPadding">
                <label class="checkBoxHeading">2nd</label>
              </div>
            </td>
            <td colspan="3" bgcolor="#a6a6a6">
              <div class="col-md-3 inputBoxPadding">
                <label class="checkBoxHeading">3rd</label>
              </div>
            </td>
            <td colspan="2" bgcolor="#a6a6a6">
              <div class="col-md-2 inputBoxPadding">
                <label class="checkBoxHeading">4th</label>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan=3>
              <div class="col-md-3 inputBoxPadding">
                <div class="form-group selectDrop suffix">
                  <ng-select [items]="languages" bindLabel="name" placeholder="Select Languages" bindValue="id"
                    formControlName="277">
                  </ng-select>
                </div>
              </div>
            </td>
            <td colspan="3">
              <div class="col-md-3 inputBoxPadding">
                <div class="form-group selectDrop suffix">
                  <ng-select [items]="secondaryLanguages" bindLabel="name" placeholder="Select SecondaryLanguages"
                    bindValue="id" formControlName="1535">
                  </ng-select>
                </div>
              </div>
            </td>
            <td colspan="3">
              <div class="col-md-3 inputBoxPadding">
                <div class="form-group selectDrop suffix">
                  <ng-select [items]="tertiaryLanguages" bindLabel="name" placeholder="Select TertiaryLanguages"
                    bindValue="id" formControlName="1536">
                  </ng-select>
                </div>
              </div>
            </td>
            <td colspan="2">
              <div class="col-md-2 inputBoxPadding">
                <div class="form-group selectDrop suffix">
                  <ng-select [items]="quaternaryLanguages" bindLabel="name" placeholder="Select QuaternaryLanguages"
                    bindValue="id" formControlName="2207">
                  </ng-select>
                </div>
              </div>
            </td>
          </tr>
        </table>
        <br><br><br><br><br><br>
        <div class="">
          <div class="">
            <h4 class="centerNormalParagraph"><b>ATTESTATION FORM</b></h4>
            <h4 class="centerNormalParagraph"><b>PRIVILEGED STAFF ORGANIZATION</b></h4>
            <h4 class="centerNormalParagraph"><b>STATEMENT OF PRIVATE PRACTITIONER APPLICANT</b></h4>
          </div>
          <div class="row sideHeadingAlignment">
            <div class="col-md-12">
              <label class="universal-label formLabel">
                I fully understand that any significant misstatement in or omission from this application constitutes
                cause
                for denial of appointment or cause for summary dismissal from the organization of privileged staff, loss
                of clinical privileges, and termination of employment or contract. All information submitted by me in
                this
                application is true to my best knowledge and belief.
              </label><br>
              <label class="universal-label formLabel">
                In making this application for appointment or reappointment to the LifeWays provider network and
                privileged staff, I acknowledge that I have received and read the standards and rules and regulations
                of the organized, credentialed and privileged staff of this organization, and that I am familiar with
                the
                standards and ethics of the national, state, and local associations that apply to and govern my
                specialty
                and/or profession, and I agree to be bound by the terms thereof if I am granted clinical privileges, and
                I
                further agree to be bound by the terms thereof without regard to whether or not I am granted clinical
                privileges in all matters relating to the consideration of my application for appointment to the
                organized
                privileged staff or clinical privileges, and I further agree to abide by the organization and staff
                rules and
                regulations as may be from time to time enacted.
              </label><br>
              <label class="universal-label formLabel">
                By applying for appointment or reappointment of membership and applicable clinical privileges, I hereby
                signify my willingness to appear for interviews in connection with my application. I hereby authorize
                the
                Agency, its staff, and their representatives to contact administrators and members or staffs of other
                facilities or institutions with which I have been associated, and with any person, organization, or
                others,
                including past and present malpractice liability insurance carriers, who may have information bearing
                on my professional competence, character, and ethical qualifications. I hereby further consent to the
                inspection, by the Agency, its Privileged Staff Organization, and its representatives, of all documents,
                including consumer records and other facilities, which may be material to an evaluation of my
                qualifications and competence to continue as a member of the Privileged Staff Organization and to
                maintain applicable privileges.
              </label><br>
              <label class="universal-label formLabel">
                I hereby release from liability all representatives of the Agency and its staff, including, without
                limitation,
                its officers, directors, agents, and employees, for all their acts performed in good faith and without
                malicious intent in connection with evaluating my application, my credentials, and qualifications. I
                also
                hereby release from liability any and all individuals and organizations who provide information to the
                Agency or its staff in good faith and without malicious intent concerning my competence, ethics,
                character, and other qualifications for membership in the Privileged Staff Organization or applicable
                privileges, and I hereby consent to the release of such information.
              </label><br>
              <label class="universal-label formLabel">
                I understand and agree that I, as an applicant for organized privileged staff membership or clinical
                privileges, have the burden of producing adequate information for proper evaluation of my professional
                competence, character, ethics, and other qualifications and for resolving any doubts about such
                qualifications.
              </label><br>
              <label class="universal-label formLabel">
                I have not requested privileges for any procedures for which I am not qualified. Furthermore, I realize
                that certification or licensure does not necessarily qualify me to perform certain privileges. However,
                I
                believe that I am qualified to perform all clinical activities for which I have requested privileges.
              </label><br>
            </div>
          </div><br><br><br><br><br><br>
          <div class="col-md-3">
            <label class="normalParagraph"><b>Signature: </b></label>
          </div>
          <div class="col-md-3" style="padding-top:25px;">
            <img *ngIf="!showOne" class="img" src="./assets/images/e_sign.png" alt="Credential my Doc"
              (click)="esignOpenOne()">
            <img *ngIf="showOne" class="img eSignImageShow" [src]="esignOne" alt="Credential my Doc"
              (click)="esignOpenOne()">
            <input type="hidden" name="cmd_2500017" id="cmd_2500017" formControlName="cmd_2500017"
              [class.is-invalid]="(lifeWaysUniversal.controls['cmd_2500017'].touched && lifeWaysUniversal.controls['cmd_2500017'].invalid) || (lifeWaysUniversal.controls['cmd_2500017'].invalid && isSubmit)">
            <div class="formLabel col-md-11"
              *ngIf="lifeWaysUniversal.controls['cmd_2500017'].touched && lifeWaysUniversal.controls['cmd_2500017'].invalid">
              <small class="text-danger" *ngIf="lifeWaysUniversal.controls['cmd_2500017'].errors?.required">
                required
              </small>
            </div>
            <span
              *ngIf="(lifeWaysUniversal.controls['cmd_2500017'].touched && lifeWaysUniversal.controls['cmd_2500017'].invalid) || (lifeWaysUniversal.controls['cmd_2500017'].invalid && isSubmit)">
              <i class="fas fa-times ml-2 text-danger"></i>
            </span>
          </div>
          <br><br><br><br><br><br>
          <div class="col-md-2">
            <label class="normalParagraph"><b>Date: </b></label>
          </div>
          <div class="col-md-2">
            <input class="form-control onboard datepicker" ngbDatepicker #d18="ngbDatepicker" id="cmd_2500040" (blur)="clearInputIfInvalidMandi('cmd_2500040')"
              name="cmd_2500040"
              [class.is-invalid]="(lifeWaysUniversal.controls['cmd_2500040'].touched && lifeWaysUniversal.controls['cmd_2500040'].invalid) || (lifeWaysUniversal.controls['cmd_2500040'].invalid && isSubmit)"
              formControlName="cmd_2500040" type="text" placeholder="DATE" data-id="managing_contact_name">
            <div class="input-group-append">
              <button class="btn btn-outline-secondary calendar calenderWidthFour" (click)="d18.toggle()"
                type="button"><i class="fas fa-calendar-alt"></i></button>
            </div>
            <div class="mandatory" *ngIf="(lifeWaysUniversal.controls['cmd_2500040'].invalid&&lifeWaysUniversal.controls['cmd_2500040'].touched) && (lifeWaysUniversal.controls['cmd_2500040'].value==''|| lifeWaysUniversal.controls['cmd_2500040'].value==null) " >
              <small >
              required
              </small>

          </div>
            <small class="text-danger" *ngIf="lifeWaysUniversal.get('cmd_2500040').errors?.invalidDateFormat">
              Only Date Format is allowed(MM/DD/YYYY)                </small>
            <small class="text-danger" *ngIf="lifeWaysUniversal.get('cmd_2500040').errors?.invalidMonth">
            Month is out of range
            </small>
            <small class="text-danger" *ngIf="lifeWaysUniversal.get('cmd_2500040').errors?.invalidDateyear">
              year is out of range(1900-2099)                  </small>
              <small class="text-danger" *ngIf="lifeWaysUniversal.get('cmd_2500040').errors?.invalidDateRange">
                date is out of range
                </small>
          </div>
          <br><br><br><br><br><br>
        </div>
        <div class="">
          <h4 class="centerNormalParagraph">ATTACHMENT 1</h4><br>
          <h4 class="centerNormalParagraph"><b>CONFIDENTIAL</b></h4><br>
          <h4 class="centerNormalParagraph"><b><u>CRIMINAL BACKGROUND CHECK AUTHORIZATION</u></b></h4><br>
        </div><br><br>
        <div class="row sideHeadingAlignment">
          <div class="col-md-12">
            <label class="universal-label normalParagraph">
              In connection with my Employee Practitioner Credentialing Application, I hereby authorize LifeWays
              and my employing agency to investigate and obtain a record of my criminal convictions and any pending
              felony charges from official law enforcement agencies.
            </label>
          </div>
        </div>
        <div class="row">
          <div class="form-group form-inline no-margin col-md-12">
            <h4 class="hThreeStyle"><b>*Required Fields</b></h4>
          </div>
        </div>
        <div class="row sideHeadingAlignment">
          <div class="col-md-1">
            <label class="normalParagraph">LastName:</label>
          </div>
          <div class="col-md-3">
            <input class="form-control onboard " id="cmd_25000069" name="cmd_25000069" formControlName="cmd_25000069"
              type="text" placeholder="Last Name" data-id="lname">
          </div>
          <div class="col-md-1">
            <label class="normalParagraph">FirstName:</label>
          </div>
          <div class="col-md-3">
            <input class="form-control onboard " id="cmd_25000070" name="cmd_25000070" formControlName="cmd_25000070"
              type="text" placeholder="First Name" data-id="fname">
          </div>
          <div class="col-md-1">
            <label class="normalParagraph">MI:</label>
          </div>
          <div class="col-md-3">
            <input class="form-control onboard " id="cmd_25000071" name="cmd_25000071" formControlName="cmd_25000071"
              type="text" placeholder="MI" data-id="fname">
          </div>
        </div>
        <div class="row sideHeadingAlignment">
          <div class="col-md-3">
            <label class="normalParagraph">Alias/Previous Name(s): </label>
          </div>
          <div class="col-md-8">
            <input class="form-control" id="278" name="278"
              [class.is-invalid]="(lifeWaysUniversal.controls['278'].touched && lifeWaysUniversal.controls['278'].invalid) || (lifeWaysUniversal.controls['278'].invalid && isSubmit)"
              formControlName="278" type="text" data-id="prior_name">
            <div class="formLabel col-md-11"
              *ngIf="lifeWaysUniversal.controls['278'].touched && lifeWaysUniversal.controls['278'].invalid">
              <small class="text-danger" *ngIf="lifeWaysUniversal.controls['278'].errors?.required">
                required
              </small>
            </div>
          </div>
        </div>
        <div class="row sideHeadingAlignment">
          <div class="col-md-3">
            <label class="normalParagraph">Date of Birth</label>
          </div>
          <div class="col-md-2">
            <input class="form-control onboard datepicker" ngbDatepicker #d30="ngbDatepicker" id="31" name="31"  (blur)="clearInputIfInvalidMandi('31')"
              [class.is-invalid]="(lifeWaysUniversal.controls['31'].touched && lifeWaysUniversal.controls['31'].invalid) || (lifeWaysUniversal.controls['31'].invalid && isSubmit)"
              formControlName="31" type="text" placeholder="DATE" data-id="managing_contact_name">
            <div class="input-group-append">
              <button class="btn btn-outline-secondary calendar calenderWidthFour" (click)="d30.toggle()"
                type="button"><i class="fas fa-calendar-alt"></i></button>
            </div>
            <div class="mandatory" *ngIf="(lifeWaysUniversal.controls['31'].invalid&&lifeWaysUniversal.controls['31'].touched) && (lifeWaysUniversal.controls['31'].value==''|| lifeWaysUniversal.controls['31'].value==null) " >
              <small >
              required
              </small>

          </div>
            <small class="text-danger" *ngIf="lifeWaysUniversal.get('31').errors?.invalidDateFormat">
              Only Date Format is allowed(MM/DD/YYYY)                </small>
            <small class="text-danger" *ngIf="lifeWaysUniversal.get('31').errors?.invalidMonth">
            Month is out of range
            </small>
            <small class="text-danger" *ngIf="lifeWaysUniversal.get('31').errors?.invalidDateyear">
              year is out of range(1900-2099)                  </small>
              <small class="text-danger" *ngIf="lifeWaysUniversal.get('31').errors?.invalidDateRange">
                date is out of range
                </small>
          </div>
        </div>
        <div class="row sideHeadingAlignment">
          <div class="col-md-1">
            <label class="normalParagraph">Sex:</label>
          </div>
          <div class="form-group form-inline no-margin col-md-3 inputBoxPadding">
            <label class="checkbox-inline radioStyle">
              <input type="radio" id="cmd_2500018" name="cmd_2500018"
                [class.is-invalid]="(lifeWaysUniversal.controls['cmd_2500018'].touched && lifeWaysUniversal.controls['cmd_2500018'].invalid) || (lifeWaysUniversal.controls['cmd_2500018'].invalid && isSubmit)"
                formControlName="cmd_2500018" value="0">Male</label>
          </div>
          <div class="form-group form-inline no-margin col-md-3 inputBoxPadding">
            <label class="checkbox-inline radioStyle">
              <input type="radio" id="cmd_2500018" name="cmd_2500018"
                [class.is-invalid]="(lifeWaysUniversal.controls['cmd_2500018'].touched && lifeWaysUniversal.controls['cmd_2500018'].invalid) || (lifeWaysUniversal.controls['cmd_2500018'].invalid && isSubmit)"
                formControlName="cmd_2500018" value="1">Female</label>
            <div class="formLabel col-md-11"
              *ngIf="lifeWaysUniversal.controls['cmd_2500018'].touched && lifeWaysUniversal.controls['cmd_2500018'].invalid">
              <small class="text-danger" *ngIf="lifeWaysUniversal.controls['cmd_2500018'].errors?.required">
                required
              </small>
            </div>
          </div>
          <span
            *ngIf="(lifeWaysUniversal.controls['cmd_2500018'].touched && lifeWaysUniversal.controls['cmd_2500018'].invalid) || (lifeWaysUniversal.controls['cmd_2500018'].invalid && isSubmit)">
            <i class="fas fa-times ml-2 text-danger"></i>
          </span>
        </div>
        <div class="row sideHeadingAlignment">
          <div class="col-md-3">
            <label class="normalParagraph">Race</label>
          </div>
          <div class="col-md-8">
            <div class="form-group selectDrop dropDrownSet">
              <ng-select [items]="race" bindLabel="name" placeholder="Select Race" bindValue="id" formControlName="741">
              </ng-select>
              <!-- <div class="formLabel col-md-11"
                                *ngIf="lifeWaysUniversal.controls['741'].touched && lifeWaysUniversal.controls['741'].invalid">
                                <small class="text-danger" *ngIf="lifeWaysUniversal.controls['741'].errors?.required">
                                    required
                                </small>
                            </div> -->
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <input type="checkbox" id="cmd_2500020" name="cmd_2500020" formControlName="cmd_2500020" value="1">
          <label class="universal-label" for="cmd_2500001"><b>Clinical Staff Only –</b>I acknowledge that LifeWays may
            be required to submit my information to the
            Jackson County Jail and Hillsdale County Jail, who will perform their own background check, in
            order for me to gain access to their facility. </label><br>
        </div><br><br><br><br><br><br>
        <div class="row sideHeadingAlignment">
          <div class="col-md-12">
            <label>My signature below indicates that the information that I have furnished is true and correct to the
              best of
              my knowledge.</label><br>
          </div>
          <div class="col-md-3">
            <label class="normalParagraph"><b>Applicant's Signature </b></label>
          </div>
          <div class="col-md-3 signaturePadding">
            <img *ngIf="!showTwo" class="img" src="./assets/images/e_sign.png" alt="Credential my Doc"
              (click)="esignOpenTwo()">
            <img *ngIf="showTwo" class="img eSignImageShow" [src]="esignTwo" alt="Credential my Doc"
              (click)="esignOpenTwo()">
            <input type="hidden" name="cmd_2500021" id="cmd_2500021" formControlName="cmd_2500021"
              [class.is-invalid]="(lifeWaysUniversal.controls['cmd_2500021'].touched && lifeWaysUniversal.controls['cmd_2500021'].invalid) || (lifeWaysUniversal.controls['cmd_2500021'].invalid && isSubmit)">
            <div class="formLabel col-md-11"
              *ngIf="lifeWaysUniversal.controls['cmd_2500021'].touched && lifeWaysUniversal.controls['cmd_2500021'].invalid">
              <small class="text-danger" *ngIf="lifeWaysUniversal.controls['cmd_2500021'].errors?.required">
                required
              </small>
            </div>
            <span
              *ngIf="(lifeWaysUniversal.controls['cmd_2500021'].touched && lifeWaysUniversal.controls['cmd_2500021'].invalid) || (lifeWaysUniversal.controls['cmd_2500021'].invalid && isSubmit)">
              <i class="fas fa-times ml-2 text-danger"></i>
            </span>
          </div>
        </div>
        <div class="row sideHeadingAlignment">
          <div class="col-md-2">
            <label class="normalParagraph"><b>Date: </b></label>
          </div>
          <div class="col-md-2">
            <input class="form-control onboard datepicker" ngbDatepicker #d24="ngbDatepicker" (blur)="clearInputIfInvalidMandi('cmd_2500038')"
              [class.is-invalid]="(lifeWaysUniversal.controls['cmd_2500038'].touched && lifeWaysUniversal.controls['cmd_2500038'].invalid) || (lifeWaysUniversal.controls['cmd_2500038'].invalid && isSubmit)"
              id="cmd_2500038" name="cmd_2500038" formControlName="cmd_2500038" type="text" placeholder="DATE"
              data-id="managing_contact_name">
            <div class="input-group-append">
              <button class="btn btn-outline-secondary calendar calenderWidthFour" (click)="d24.toggle()"
                type="button"><i class="fas fa-calendar-alt"></i></button>
            </div>
            <div class="mandatory" *ngIf="(lifeWaysUniversal.controls['cmd_2500038'].invalid&&lifeWaysUniversal.controls['cmd_2500038'].touched) && (lifeWaysUniversal.controls['cmd_2500038'].value==''|| lifeWaysUniversal.controls['cmd_2500038'].value==null) " >
              <small >
              required
              </small>

          </div>
            <small class="text-danger" *ngIf="lifeWaysUniversal.get('cmd_2500038').errors?.invalidDateFormat">
              Only Date Format is allowed(MM/DD/YYYY)                </small>
            <small class="text-danger" *ngIf="lifeWaysUniversal.get('cmd_2500038').errors?.invalidMonth">
            Month is out of range
            </small>
            <small class="text-danger" *ngIf="lifeWaysUniversal.get('cmd_2500038').errors?.invalidDateyear">
              year is out of range(1900-2099)                  </small>
              <small class="text-danger" *ngIf="lifeWaysUniversal.get('cmd_2500038').errors?.invalidDateRange">
                date is out of range
                </small>
          </div>
        </div><br><br><br><br><br><br>
        <div class="">
          <div class="">
            <h4 class="centerNormalParagraph">ATTACHMENT 2</h4>
            <h4 class="centerNormalParagraph"><b>CONFIDENTIAL</b></h4>
            <h4 class="centerNormalParagraph"><b><u>MALPRACTICE SUIT INFORMATION FORM</u></b></h4>
          </div>
        </div>
        <div class="row sideHeadingAlignment">
          <div class="col-md-12">
            <label class="universal-label normalParagraph">
              Please submit one sheet for each case settled and/or pending in the past ten years. You may use
              a separate form if the following elements are covered in such form.
            </label>
          </div>
          <div class="col-md-12">
            <label class="normalParagraph">
              <b>If you do not have any cases to submit, do not complete this page</b>
            </label>
          </div>
        </div>
        <div class="row sideHeadingAlignment">
          <div class="col-md-2">
            <label class="normalParagraph">Name of Case:</label>
          </div>
          <div class="col-md-3">
            <input class="form-control onboard " id="1206" name="1206" formControlName="1206" type="text"
              placeholder="Patient Last Name" data-id="patients_last_name">
          </div>
          <div class="col-md-3">
            <label class="normalParagraph">Case Number:</label>
          </div>
          <div class="col-md-3">
            <input class="form-control onboard " id="1853" name="1853" formControlName="1853" type="text"
              data-id="claim_file_number">
          </div>
        </div>
        <div class="row sideHeadingAlignment">
          <div class="col-md-3">
            <label class="normalParagraph">Date of Occurrence:</label>
          </div>
          <div class="col-md-2">
            <input class="form-control onboard datepicker" ngbDatepicker #d22="ngbDatepicker" id="1208" name="1208" (blur)="clearInputIfInvalid('1208')"
              formControlName="1208" type="text" placeholder="DATE" data-id="managing_contact_name">
          </div>
          <div class="input-group-append">
            <button class="btn btn-outline-secondary calendar calenderWidthFive" (click)="d22.toggle()" type="button"><i
                class="fas fa-calendar-alt"></i></button>
          </div>
          <div class="col-md-2">
            <label class="normalParagraph">Payment Due:</label>
          </div>
          <div class="col-md-3">
            <input class="form-control onboard " id="cmd_25000074" name="cmd_25000074" formControlName="cmd_25000074"
              type="text" data-id="lname">
          </div>
        </div>
        <div class="row">
          <div class="col-md-3"> </div>
        <div class="col-md-3"> 
          <div  *ngIf="lifeWaysUniversal.get('1208').invalid ">
            <small class="text-danger" *ngIf="lifeWaysUniversal.get('1208').errors?.invalidDateFormat">
              Only Date Format is allowed(MM/DD/YYYY)
            </small>
            <small class="text-danger" *ngIf="lifeWaysUniversal.get('1208').errors?.invalidMonth">
            Month is out of range
            </small>
            <small class="text-danger" *ngIf="lifeWaysUniversal.get('1208').errors?.invalidDateyear">
              year is out of range(1900-2099)                      </small>
              <small class="text-danger" *ngIf="lifeWaysUniversal.get('1208').errors?.invalidDateRange">
                date is out of range
                </small>
            </div>
        </div>
      
        
          </div>

        <div class="row sideHeadingAlignment">
          <div class="col-md-3">
            <label class="normalParagraph">Allegations which are basis for the claim: </label>
          </div>
          <div class="col-md-8">
            <textarea id="1216" name="1216" formControlName="1216" class="TextAreaStyle"></textarea>
          </div>
        </div>
        <div class="row sideHeadingAlignment">
          <div class="col-md-3">
            <label class="normalParagraph">Description of circumstances in the case:</label>
          </div>
          <div class="col-md-8">
            <textarea id="1217" name="1217" formControlName="1217" class="TextAreaStyle" data-id="specifics_of_the_event"></textarea>
          </div>
        </div>
        <div class="row sideHeadingAlignment">
          <div class="col-md-3">
            <label class="normalParagraph">Disposition of Claim:</label>
          </div>
          <div class="col-md-8">
            <textarea id="cmd_25000149" name="cmd_25000149" formControlName="cmd_25000149" class="TextAreaStyle"></textarea>
          </div>
        </div>
        <div class="row sideHeadingAlignment">
          <div class="col-md-3">
            <label class="normalParagraph">Date of Disposition:</label>
          </div>
          <div class="col-md-3">
            <input class="form-control onboard datepicker" ngbDatepicker #d21="ngbDatepicker" id="1209" name="1209" (blur)="clearInputIfInvalid('1209')"
              formControlName="1209" type="text" placeholder="DATE" data-id="managing_contact_name">
          </div>
          <div class="input-group-append">
            <button class="btn btn-outline-secondary calendar calenderWidthFive" (click)="d21.toggle()" type="button"><i
                class="fas fa-calendar-alt"></i></button>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3"> </div>
        <div class="col-md-3"> 
          <div  *ngIf="lifeWaysUniversal.get('1209').invalid ">
            <small class="text-danger" *ngIf="lifeWaysUniversal.get('1209').errors?.invalidDateFormat">
              Only Date Format is allowed(MM/DD/YYYY)
            </small>
            <small class="text-danger" *ngIf="lifeWaysUniversal.get('1209').errors?.invalidMonth">
            Month is out of range
            </small>
            <small class="text-danger" *ngIf="lifeWaysUniversal.get('1209').errors?.invalidDateyear">
              year is out of range(1900-2099)                      </small>
              <small class="text-danger" *ngIf="lifeWaysUniversal.get('1209').errors?.invalidDateRange">
                date is out of range
                </small>
            </div>
        </div>
      
        
          </div>
        <div class="row sideHeadingAlignment">
          <div class="col-md-3">
            <label class="normalParagraph">Amount of judgment or settlement: </label>
          </div>
          <div class="col-md-8">
            <input class="form-control onboard " id="1228" name="1228" formControlName="1228" type="text"
              data-id="total_amount_of_settlement">
          </div>
        </div>
        <div class="row sideHeadingAlignment">
          <div class="col-md-3">
            <label class="normalParagraph">Insurance Company(s) involved (if any): </label>
          </div>
          <div class="col-md-8">
            <input class="form-control onboard " id="1210" name="1210" formControlName="1210" type="text"
              data-id="insurance_carrier_at_time_of_occurance">
          </div>
        </div><br><br><br><br><br><br><br><br><br><br>
        <div class="row sideHeadingAlignment">
          <div class="col-md-12">
            <label class="normalParagraph">I hereby certify that the above information is true and accurate and that
              this form will be kept
              confidential and will only be used for credentialing or re-credentialing determination. </label>
          </div>
        </div>
        <div class="row sideHeadingAlignment">
          <div class="col-md-3">
            <label class="normalParagraph"><b>Name/Credentials </b></label>
          </div>
          <div class="col-md-3">
            <input class="form-control onboard " id="cmd_25000073" name="cmd_25000073" formControlName="cmd_25000073"
              type="text" data-id="lname">
          </div>
        </div>
        <div class="row sideHeadingAlignment">
          <div class="col-md-2">
            <label class="normalParagraph"><b>Date: </b></label>
          </div>
          <div class="col-md-3">
            <input class="form-control onboard datepicker" ngbDatepicker #d19="ngbDatepicker" id="cmd_2500039" (blur)="clearInputIfInvalid('cmd_2500039')"
              name="cmd_2500039" formControlName="cmd_2500039" type="text" placeholder="DATE"
              data-id="managing_contact_name">
          </div>
          <div class="input-group-append">
            <button class="btn btn-outline-secondary calendar calenderWidthFive" (click)="d19.toggle()" type="button"><i
                class="fas fa-calendar-alt"></i></button>
          </div>
        </div>
        <div class="row">
          <div class="col-md-2"> </div>
        <div class="col-md-3"> 
          <div  *ngIf="lifeWaysUniversal.get('cmd_2500039').invalid ">
            <small class="text-danger" *ngIf="lifeWaysUniversal.get('cmd_2500039').errors?.invalidDateFormat">
              Only Date Format is allowed(MM/DD/YYYY)
            </small>
            <small class="text-danger" *ngIf="lifeWaysUniversal.get('cmd_2500039').errors?.invalidMonth">
            Month is out of range
            </small>
            <small class="text-danger" *ngIf="lifeWaysUniversal.get('cmd_2500039').errors?.invalidDateyear">
              year is out of range(1900-2099)                      </small>
              <small class="text-danger" *ngIf="lifeWaysUniversal.get('cmd_2500039').errors?.invalidDateRange">
                date is out of range
                </small>
            </div>
        </div>
      
        
          </div>
        <br><br><br><br><br><br>
        <div class="">
          <div class="">
            <h4 class="centerNormalParagraph">ATTACHMENT 3</h4>
            <h4 class="centerNormalParagraph"><b>Training Hours for Credentialing</b></h4>
            <h4 class="centerNormalParagraph"><b><u>Acknowledgement Statement</u></b></h4>
          </div>
        </div>
        <div class="row sideHeadingAlignment">
          <div class="col-md-12">
            <label class="universal-label normalParagraph">
              Individuals in the LifeWays Network that serve adults and/or children are required to obtain clinically
              related continuing education every year in order to provide the best services for our consumers.
              Individuals that serve adults must acquire 16 clock hours of adult training <b><i>per year</i></b>.
              Individuals that
              serve children must acquire 24 clock hours of children training <b><i>per year</i></b>. These trainings
              must improve
              the skills of the individual clinically and must provide proof of trainings.
            </label><br>
          </div>
          <div class="col-md-12">
            <label class="universal-label normalParagraph">
              Once an individual obtains full membership status for credentialing, they will be re-credentialed every
              two years. Thus, an individual that services both adults and children must submit a total of 80 hours
              (32 adult and 48 children) worth of training taken over the two-year period. Hours cannot be made up
              in the second year if they were not obtained in the first year. Example: You acquired 14 adult hours’
              worth of training in year one, 2 hours less than required. In year two you acquired 20 adult hours’ worth
              of training. The extra hours in year two cannot make up for the lack of hours obtained in year one or
              vice versa; the same scenario for children’s hours.
            </label><br>
          </div>
          <div class="col-md-12">
            <label class="universal-label normalParagraph">
              An individual that serves only adults <b>OR</b> children is required to obtain the training hours
              associated with
              their population. Same rules as above apply.
            </label><br>
          </div>
          <div class="col-md-12">
            <label class="universal-label normalParagraph">
              Individuals who fail to meet the requirements may be subject to verbal counseling or written discipline,
              up to and including termination.
            </label><br>
          </div>
          <div class="col-md-12">
            <label class="universal-label normalParagraph">
              By signing below, you acknowledge that you have read and understand the information provided to you
              about re-credentialing and training requirements. You have been given opportunities to ask questions
              and have received appropriate answers. If there is anything that you do not understand, you will seek
              clarification from the Human Resources Department.
            </label><br>
          </div>
        </div>
        <div class="row sideHeadingAlignment">
          <div class="col-md-2">
            <label class="normalParagraph">Name:</label>
          </div>
          <div class="col-md-3">
            <input class="form-control onboard " id="cmd_25000072" name="cmd_25000072" value={{firstName+lastName}}
              formControlName="cmd_25000072" type="text" data-id="lname">
          </div>
          <div class="col-md-3">
            <label class="normalParagraph">Job Title:</label>
          </div>
          <div class="col-md-3">
            <div class="form-group selectDropTwo suffix inputBoxPadding">
              <ng-select [items]="credentials" bindLabel="name" placeholder="Select Credential" bindValue="id"
                formControlName="cmd_110215">
              </ng-select>
            </div>
          </div>
        </div><br><br><br><br><br><br>
        <div class="row sideHeadingAlignment">
          <div class="col-md-3">
            <label class="normalParagraph"><b>Signature:</b></label>
          </div>
          <div class="col-md-3" style="padding-top:25px;">
            <img *ngIf="!showThree" class="img" src="./assets/images/e_sign.png" alt="Credential my Doc"
              (click)="esignOpenThree()">
            <img *ngIf="showThree" class="img eSignImageShow" [src]="esignThree" alt="Credential my Doc"
              (click)="esignOpenThree()">
            <input type="hidden" name="cmd_2500023" id="cmd_2500023" formControlName="cmd_2500023">
          </div>
        </div>
        <div class="row sideHeadingAlignment">
          <div class="col-md-2">
            <label class="normalParagraph"><b>Date: </b></label>
          </div>
          <div class="col-md-3">
            <input class="form-control onboard datepicker" ngbDatepicker #d20="ngbDatepicker" id="cmd_2500041" (blur)="clearInputIfInvalid('cmd_2500041')"
              name="cmd_2500041" formControlName="cmd_2500041" type="text" placeholder="DATE" 
              data-id="managing_contact_name">
          </div>
          <div class="input-group-append">
            <button class="btn btn-outline-secondary calendar calenderWidthFive" (click)="d20.toggle()" type="button"><i
                class="fas fa-calendar-alt"></i></button>
          </div>
        </div>
        <div class="row">
          <div class="col-md-2"> </div>
        <div class="col-md-3"> 
          <div  *ngIf="lifeWaysUniversal.get('cmd_2500041').invalid ">
            <small class="text-danger" *ngIf="lifeWaysUniversal.get('cmd_2500041').errors?.invalidDateFormat">
              Only Date Format is allowed(MM/DD/YYYY)
            </small>
            <small class="text-danger" *ngIf="lifeWaysUniversal.get('cmd_2500041').errors?.invalidMonth">
            Month is out of range
            </small>
            <small class="text-danger" *ngIf="lifeWaysUniversal.get('cmd_2500041').errors?.invalidDateyear">
              year is out of range(1900-2099)                      </small>
              <small class="text-danger" *ngIf="lifeWaysUniversal.get('cmd_2500041').errors?.invalidDateRange">
                date is out of range
                </small>
            </div>
        </div>
      
        
          </div>
      </form>
    </div>
  </div>
  <div class="modal-footer mt-4">
    <button *ngIf="formData.receivedForm" type="button" class="btn customOrange float-right"
      (click)="saveForm('approve')">Approve</button>
    <button *ngIf="formData.submitForm" type="button" class="btn btn customOrange float-right"
      (click)="saveForm('submit')">Submit</button>
    <button *ngIf="formData.submitForm" type="button" class="btn btn customOrange float-right"
      (click)="saveForm('save')">Save</button>
    <button *ngIf="formData.previewForm" type="button" class="btn customOrange float-right"
      (click)="close()">Close</button>
  </div>
</div>
