<div id="tannerclinic">
    <div class="header">
        <div class="row"> 
          <div class="col-md-1">
            <img  class="logoImg" src="../../../../assets/images/Tanner.png"
              alt="Credential my Doc" />
          </div>
          <div class="col-md-11">
            <div class="text-center">
              <h2 >Physician Credentialing Application</h2>
            </div>
          </div>
        </div>  
    </div>
    <form [formGroup]="TannerClinicFroms">
        <table border="1" width="95%" class="center"> 
            <tr>
                <td colspan="12">
                    <div class="row">
                        <label class="formLabel">Please fill out the following form. Information will be used for credentialing purposes (hospital and
                            insurance).</label>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <b style="font-size:16px;">Personal Information</b>
                </td>
            </tr>
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">*Physician Legal Name :</label>
                    </div>
                </td>
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-3">
                            <input class="form-control onboard"  formControlName="8"
                                    id="8" name="8" type="text" placeholder= "First Name" [class.is-invalid]="(TannerClinicFroms.controls['8'].touched && TannerClinicFroms.controls['8'].invalid) || (TannerClinicFroms.controls['8'].invalid && isSubmit)"  data-id="managing_contact_name" >
                                <div class="formLabel col-md-11"
                                    *ngIf="TannerClinicFroms.controls['8'].touched && TannerClinicFroms.controls['8'].invalid">
                                    <small class="text-danger" *ngIf="TannerClinicFroms.controls['8'].errors?.required">
                                        required
                                    </small>
                                </div>
                        </div>
                        <div class="col-md-3">
                            <input class="form-control onboard"  formControlName="809"
                                    id="809" name="809" type="text" [class.is-invalid]="(TannerClinicFroms.controls['809'].touched && TannerClinicFroms.controls['809'].invalid) || (TannerClinicFroms.controls['809'].invalid && isSubmit)" placeholder= "Middle Name"  data-id="managing_contact_name" >
                                <div class="formLabel col-md-11"
                                    *ngIf="TannerClinicFroms.controls['809'].touched && TannerClinicFroms.controls['809'].invalid">
                                    <small class="text-danger" *ngIf="TannerClinicFroms.controls['809'].errors?.required">
                                        required
                                    </small>
                                </div>
                        </div>
                        <div class="col-md-3">
                            <input class="form-control onboard"  formControlName="9"
                                    id="9" name="9" type="text" placeholder= "Last Name" [class.is-invalid]="(TannerClinicFroms.controls['9'].touched && TannerClinicFroms.controls['9'].invalid) || (TannerClinicFroms.controls['9'].invalid && isSubmit)"  data-id="managing_contact_name" >
                                <div class="formLabel col-md-11"
                                    *ngIf="TannerClinicFroms.controls['9'].touched && TannerClinicFroms.controls['9'].invalid">
                                    <small class="text-danger" *ngIf="TannerClinicFroms.controls['9'].errors?.required">
                                        required
                                    </small>
                                </div>
                        </div>
                        <div class="col-md-3">
                            <ng-select [items]="suffix" bindLabel="name"   placeholder="Select Suffix" bindValue="id"  formControlName="29">
                                </ng-select>
                                
                        </div>    
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Other names used:</label>
                    </div>
                </td>
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="278"
                                    id="278" name="278" type="text" placeholder= "Prior Name"   data-id="managing_contact_name" >
                        </div>
                    </div>    
                </td>
            </tr>
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Years associated with other names: </label>
                    </div>
                </td>
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="input-group">
                                <input type="text" placeholder="Prior Name Effective Date " ngbDatepicker #d280="ngbDatepicker" id="280" class="form-control" formControlName="280"  (blur)="clearInputIfInvalid('280')"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d280.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div>
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('280').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY)</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('280').errors?.invalidMonth">
                                  Month is out of range</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('280').errors?.invalidDateyear">
                                    year is out of range(1900-2099)</small>
                                    <small class="text-danger" *ngIf="TannerClinicFroms.get('280').errors?.invalidDateRange">
                                      date is out of range</small>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="input-group">
                                <input type="text" placeholder="Prior Name Term Date " ngbDatepicker #d281="ngbDatepicker" id="281" class="form-control" formControlName="281" (blur)="clearInputIfInvalid('281')"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d281.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div>
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('281').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY)</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('281').errors?.invalidMonth">
                                  Month is out of range</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('281').errors?.invalidDateyear">
                                    year is out of range(1900-2099)</small>
                                    <small class="text-danger" *ngIf="TannerClinicFroms.get('281').errors?.invalidDateRange">
                                      date is out of range</small>
                            </div>
                        </div>
                    </div>    
                </td>
            </tr>
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">*Degree:</label>
                    </div>
                </td>
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-6">
                            <ng-select  [items]="credentials" [class.is-invalid]="(TannerClinicFroms.controls['822'].touched && TannerClinicFroms.controls['822'].invalid) || (TannerClinicFroms.controls['822'].invalid && isSubmit)" bindLabel="name" placeholder="Select Credential" bindValue="id" formControlName="822">
                            </ng-select>
                            <div class="formLabel col-md-2"
                                *ngIf="TannerClinicFroms.controls['822'].touched && TannerClinicFroms.controls['822'].invalid">
                                <small class="text-danger" *ngIf="TannerClinicFroms.controls['822'].errors?.required">
                                    required
                                </small>
                            </div>
                        </div>
                    </div>
                </td>  
            </tr> 
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">*Specialty:</label>
                    </div>
                </td>
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-6">
                            <ng-select  [items]="specialty" [class.is-invalid]="(TannerClinicFroms.controls['284'].touched && TannerClinicFroms.controls['284'].invalid) || (TannerClinicFroms.controls['284'].invalid && isSubmit)" bindLabel="name" placeholder="Select Primary Specialty" bindValue="id" formControlName="284">
                            </ng-select>
                            <div class="formLabel col-md-2"
                                *ngIf="TannerClinicFroms.controls['284'].touched && TannerClinicFroms.controls['284'].invalid">
                                <small class="text-danger" *ngIf="TannerClinicFroms.controls['284'].errors?.required">
                                    required
                                </small>
                            </div>
                        </div>
                    </div>
                </td>  
            </tr>  
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Sub Specialty:</label>
                    </div>
                </td>
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-6">
                            <ng-select  [items]="specialtySecondary"  bindLabel="name" placeholder="Select Secondary Specialty" bindValue="id" formControlName="962">
                            </ng-select>
                        </div>
                    </div>
                </td>  
            </tr>     
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">*Date of Birth:</label>
                    </div>
                </td>
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="input-group">
                                <input type="text" placeholder="DOB" ngbDatepicker #d31="ngbDatepicker" id="31" class="form-control" formControlName="31"  (blur)="clearInputIfInvalidMandi('31')"
                                [class.is-invalid]="(TannerClinicFroms.controls['31'].touched && TannerClinicFroms.controls['31'].invalid ) || (TannerClinicFroms.controls['31'].invalid && isSubmit)">
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d31.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                                    
                            </div>
                            <div>
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('31').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY)</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('31').errors?.invalidMonth">
                                  Month is out of range</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('31').errors?.invalidDateyear">
                                    year is out of range(1900-2099)</small>
                                    <small class="text-danger" *ngIf="TannerClinicFroms.get('31').errors?.invalidDateRange">
                                      date is out of range</small>
                            </div>
                            
                        </div>    
                    </div>
                </td>  
            </tr>     
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">*Place of Birth:</label>
                    </div>
                </td>
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-4">    
                            <input class="form-control"  id="810" name="810" formControlName="810" [class.is-invalid]="(TannerClinicFroms.controls['810'].touched && TannerClinicFroms.controls['810'].invalid ) || (TannerClinicFroms.controls['810'].invalid && isSubmit)" placeholder="City" type="text">
                            <div class="formLabel col-md-11"
                                *ngIf="TannerClinicFroms.controls['810'].touched && TannerClinicFroms.controls['810'].invalid">
                                <small class="text-danger" *ngIf="TannerClinicFroms.controls['810'].errors?.required">
                                    required
                                </small>
                            </div>
                        </div> 
                        <div class="col-md-4">					
                                <ng-select [items]="states" bindLabel="name" placeholder="Select State" bindValue="id" formControlName="289" [class.is-invalid]="(TannerClinicFroms.controls['289'].touched && TannerClinicFroms.controls['289'].invalid) || (TannerClinicFroms.controls['289'].invalid && isSubmit)">
                                </ng-select>
                                <div class="formLabel col-md-11"
                                    *ngIf="TannerClinicFroms.controls['289'].touched && TannerClinicFroms.controls['289'].invalid">
                                    <small class="text-danger" *ngIf="TannerClinicFroms.controls['289'].errors?.required">
                                        required
                                    </small>
                                </div>  
                        </div> 
                        <div class="col-md-4">
                            <input class="form-control"  id="291" name="291" formControlName="291" [class.is-invalid]="(TannerClinicFroms.controls['291'].touched && TannerClinicFroms.controls['291'].invalid ) || (TannerClinicFroms.controls['291'].invalid && isSubmit)" placeholder="Country" type="text">
                            <div class="formLabel col-md-11"
                                *ngIf="TannerClinicFroms.controls['291'].touched && TannerClinicFroms.controls['291'].invalid">
                                <small class="text-danger" *ngIf="TannerClinicFroms.controls['291'].errors?.required">
                                    required
                                </small>
                            </div>
                        </div>  
                    </div>
                </td>  
            </tr> 
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">*Citizenship:</label>
                    </div>
                </td>
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">    
                            <input class="form-control"  id="292" name="292" formControlName="292" [class.is-invalid]="(TannerClinicFroms.controls['292'].touched && TannerClinicFroms.controls['292'].invalid ) || (TannerClinicFroms.controls['292'].invalid && isSubmit)" placeholder="Citizenship" type="text">
                            <div class="formLabel col-md-11"
                                *ngIf="TannerClinicFroms.controls['292'].touched && TannerClinicFroms.controls['292'].invalid">
                                <small class="text-danger" *ngIf="TannerClinicFroms.controls['292'].errors?.required">
                                    required
                                </small>
                            </div>
                        </div>
                    </div>
                </td>
            </tr>    
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Status:</label>
                    </div>
                </td>
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">    
                            <input class="form-control"  id="1282" name="1282" formControlName="1282"  placeholder="Visa Status" type="text">
                        </div>
                    </div>
                </td>
            </tr> 
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">*Social Security Number:</label>
                    </div>
                </td>
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">    
                            <input class="form-control onboard " id="32" name="32" placeholder="SSN" formControlName="32" [class.is-invalid]="(TannerClinicFroms.controls['32'].touched && TannerClinicFroms.controls['32'].invalid) || (TannerClinicFroms.controls['32'].invalid && isSubmit)" type="text" data-id="ssn">
                                <div class="formLabel col-md-11"
                                    *ngIf="TannerClinicFroms.controls['32'].touched && TannerClinicFroms.controls['32'].invalid">
                                    <small class="text-danger" *ngIf="TannerClinicFroms.controls['32'].errors?.required">
                                        required
                                    </small>
                                </div>
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">*Cell Number:</label>
                    </div>
                </td>
                <td colspan="12">
                    <div class="row">
                        <div class="col-md-8">    
                            <input class="form-control onboard" id="709" name="709" placeholder="Mobile Phone" formControlName="709" (keydown)="handleNumber($event,709)" maxlength="12" [class.is-invalid]="(TannerClinicFroms.controls['709'].touched && TannerClinicFroms.controls['709'].invalid) || (TannerClinicFroms.controls['709'].invalid && isSubmit)" type="text" data-id="ssn">
                            <div class="mandatory" *ngIf="
                            TannerClinicFroms.controls['709'].touched &&
                            TannerClinicFroms.controls['709'].invalid ">
                              <small class="text-danger" *ngIf="TannerClinicFroms.controls['709'].errors?.required">
                                 Required 
                               </small>
                               <small class="text-danger" *ngIf="TannerClinicFroms.controls['709'].errors?.pattern">
                                Required pattern xxx-xxx-xxxx
                               </small>
                           </div>
                        </div>
                    </div>
                </td>
            </tr>  
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">*Phone Number:</label>
                    </div>
                </td>
                <td colspan="12">
                    <div class="row">
                        <div class="col-md-8">    
                            <input class="form-control onboard"  formControlName="708"  (keydown)="handleNumber($event,708)" maxlength="12"
                            id="708" name="708" type="text" placeholder= "Home Phone" [class.is-invalid]="(TannerClinicFroms.controls['708'].touched && TannerClinicFroms.controls['708'].invalid) || (TannerClinicFroms.controls['708'].invalid && isSubmit)"  data-id="managing_contact_name" >
                            <div class="mandatory" *ngIf="
                            TannerClinicFroms.controls['708'].touched &&
                            TannerClinicFroms.controls['708'].invalid ">
                              <small class="text-danger" *ngIf="TannerClinicFroms.controls['708'].errors?.required">
                                 Required 
                               </small>
                               <small class="text-danger" *ngIf="TannerClinicFroms.controls['708'].errors?.pattern">
                                Required pattern xxx-xxx-xxxx
                               </small>
                           </div>
                        </div>
                    </div>
                </td>
            </tr>    
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">*Home Address:</label>
                    </div>
                </td>
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-6">    
                            <input class="form-control onboard"  formControlName="16" 
                                    id="16" name="16" type="text" placeholder= "Home Address 1" [class.is-invalid]="(TannerClinicFroms.controls['16'].touched && TannerClinicFroms.controls['16'].invalid) || (TannerClinicFroms.controls['16'].invalid && isSubmit)"  data-id="managing_contact_name" >
                                <div class="formLabel col-md-11"
                                    *ngIf="TannerClinicFroms.controls['16'].touched && TannerClinicFroms.controls['16'].invalid">
                                    <small class="text-danger" *ngIf="TannerClinicFroms.controls['16'].errors?.required">
                                        required
                                    </small>
                                </div>
                        </div>
                        <div class="col-md-6">
                            <input class="form-control onboard"  formControlName="17"
                            id="17" name="17" type="text" placeholder= "Home Address 2"  data-id="managing_contact_name"  >
                                    
                        </div>
                    </div>
                </td>
            </tr>   
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">*City, State, Zip:</label>
                    </div>
                </td>
                <td colspan="12">
                    <div class="row">
                        <div class="col-md-4">
                            <input class="form-control onboard"  formControlName="18"
                                    id="18" name="18" type="text" placeholder= "Home City" [class.is-invalid]="(TannerClinicFroms.controls['18'].touched && TannerClinicFroms.controls['18'].invalid) || (TannerClinicFroms.controls['18'].invalid && isSubmit)" data-id="managing_contact_name" >
                                <div class="formLabel col-md-11"
                                    *ngIf="TannerClinicFroms.controls['18'].touched && TannerClinicFroms.controls['18'].invalid">
                                    <small class="text-danger" *ngIf="TannerClinicFroms.controls['18'].errors?.required">
                                        required
                                    </small>
                                </div>    
                        </div>
                        <div class="col-md-4">
                                <ng-select
                                    [items]="states"
                                    bindLabel="name"
                                    placeholder="Enter State"
                                    bindValue="id"
                                    formControlName="20"
                                    [class.is-invalid]="(TannerClinicFroms.controls['20'].touched && TannerClinicFroms.controls['20'].invalid) || (TannerClinicFroms.controls['20'].invalid && isSubmit)"
                                    >
                                </ng-select> 
                                <div class="formLabel col-md-11"
                                    *ngIf="TannerClinicFroms.controls['20'].touched && TannerClinicFroms.controls['20'].invalid">
                                    <small class="text-danger" *ngIf="TannerClinicFroms.controls['20'].errors?.required">
                                        required
                                    </small>
                                </div>    
                        </div>
                        <div class="col-md-4">   
                            <input class="form-control onboard"  formControlName="21"
                                    id="21" name="21" type="text" placeholder= "Home Zip" [class.is-invalid]="(TannerClinicFroms.controls['21'].touched && TannerClinicFroms.controls['21'].invalid) || (TannerClinicFroms.controls['21'].invalid && isSubmit)" data-id="managing_contact_name" >
                                <div class="formLabel col-md-11"
                                    *ngIf="TannerClinicFroms.controls['21'].touched && TannerClinicFroms.controls['21'].invalid">
                                    <small class="text-danger" *ngIf="TannerClinicFroms.controls['21'].errors?.required">
                                        required
                                    </small>
                                </div>  
                        </div>
                    </div>
                </td> 
            </tr>
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">*E-mail:</label> 
                    </div>
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="759"
                                id="759" name="759" type="text" placeholder= "Email" [class.is-invalid]="(TannerClinicFroms.controls['759'].touched && TannerClinicFroms.controls['759'].invalid) || (TannerClinicFroms.controls['759'].invalid && isSubmit)"  data-id="managing_contact_name" >
                            <div class="formLabel col-md-11"
                                *ngIf="TannerClinicFroms.controls['759'].touched && TannerClinicFroms.controls['759'].invalid">
                                <small class="text-danger" *ngIf="TannerClinicFroms.controls['759'].errors?.required">
                                    required
                                </small>
                            </div>
                        </div> 
                    </div>
                </td>
            </tr>         
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Tanner E-mail:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="1664"
                                id="1664" name="1664" type="text" placeholder="Work Email"   data-id="managing_contact_name" >
                                <small class="text-danger" *ngIf="TannerClinicFroms.controls['1664'].errors?.pattern">
                                    Enter valid Email format</small>   
                        </div> 
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">*Languages Spoken:</label> 
                    </div>
                </td>
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-6">
                            <ng-select [items]="languages" bindLabel="name" placeholder="Select Langauge1" bindValue="id" [class.is-invalid]="(TannerClinicFroms.controls['277'].touched && TannerClinicFroms.controls['277'].invalid) || (TannerClinicFroms.controls['277'].invalid && isSubmit)" formControlName="277">
                            </ng-select>
                            <div class="formLabel col-md-11"
                                *ngIf="TannerClinicFroms.controls['277'].touched && TannerClinicFroms.controls['277'].invalid">
                                <small class="text-danger" *ngIf="TannerClinicFroms.controls['277'].errors?.required">
                                    required
                                </small>
                            </div>
                        </div> 
                        <div class="col-md-6">
                            <ng-select [items]="secondaryLanguages" bindLabel="name" placeholder="Select Langauge2" bindValue="id"  formControlName="1535">
                            </ng-select>
                            
                        </div> 
                        <div class="col-md-6">
                            <ng-select [items]="tertiaryLanguages" bindLabel="name" placeholder="Select Langauge3" bindValue="id"  formControlName="1536">
                            </ng-select>
                            
                        </div> 
                        <div class="col-md-6">
                            <ng-select [items]="quaternaryLanguages" bindLabel="name" placeholder="Select Langauge4" bindValue="id"  formControlName="2207">
                            </ng-select>
                            
                        </div> 
                    </div>    
                </td>
            </tr>  
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">*Spouses Name or Next of kin:</label> 
                    </div>
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="33"
                                id="33" name="33" type="text" placeholder="Spouse" [class.is-invalid]="(TannerClinicFroms.controls['33'].touched && TannerClinicFroms.controls['33'].invalid) || (TannerClinicFroms.controls['33'].invalid && isSubmit)"  data-id="managing_contact_name" >
                            <div class="formLabel col-md-11"
                                *ngIf="TannerClinicFroms.controls['33'].touched && TannerClinicFroms.controls['33'].invalid">
                                <small class="text-danger" *ngIf="TannerClinicFroms.controls['33'].errors?.required">
                                    required
                                </small>
                            </div>
                        </div> 
                    </div>
                </td>
            </tr> 
            <tr>
                <td colspan="12">
                    <b style="font-size:16px;">Employee Information</b>
                </td>
            </tr> 
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Clinic Name:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="cmd_01766"
                                id="cmd_01766" name="cmd_01766" type="text" placeholder="Enter Clinic Name"   data-id="managing_contact_name" >
                        </div> 
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Address:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-6">
                            <input class="form-control onboard"  formControlName="cmd_01767"
                                id="cmd_01767" name="cmd_01767" type="text" placeholder="Enter Add1"   data-id="managing_contact_name" >
                        </div> 
                        <div class="col-md-6">
                            <input class="form-control onboard"  formControlName="cmd_01768"
                                id="cmd_01768" name="cmd_01768" type="text" placeholder="Enter Add2"   data-id="managing_contact_name" >
                        </div> 
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">City, State Zip:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-4">
                            <input class="form-control onboard"  formControlName="cmd_01769"
                                id="cmd_01769" name="cmd_01769" type="text" placeholder="Enter City"   data-id="managing_contact_name" >
                        </div> 
                        <div class="col-md-4">
                            <ng-select
                                    [items]="states"
                                    bindLabel="name"
                                    placeholder="Enter State"
                                    bindValue="id"
                                    formControlName="cmd_01770"
                                    
                                    >
                                </ng-select>
                        </div>
                        <div class="col-md-4">
                            <input class="form-control onboard"  formControlName="cmd_01771"
                                id="cmd_01771" name="cmd_01771" type="text" placeholder="Enter Zip"   data-id="managing_contact_name" >
                        </div> 
                    </div>
                </td>
            </tr> 
            <tr> 
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">*Hire Date:</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="input-group">
                                <input type="text" placeholder="Enter Hire Date" ngbDatepicker #d01772="ngbDatepicker" id="cmd_01772" class="form-control" formControlName="cmd_01772"  (blur)="clearInputIfInvalidMandi('cmd_01772')"
                                [class.is-invalid]="(TannerClinicFroms.controls['cmd_01772'].touched && TannerClinicFroms.controls['cmd_01772'].invalid ) || (TannerClinicFroms.controls['cmd_01772'].invalid && isSubmit)">
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d01772.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div>
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('cmd_01772').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY)</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('cmd_01772').errors?.invalidMonth">
                                  Month is out of range</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('cmd_01772').errors?.invalidDateyear">
                                    year is out of range(1900-2099)</small>
                                    <small class="text-danger" *ngIf="TannerClinicFroms.get('cmd_01772').errors?.invalidDateRange">
                                      date is out of range</small>
                            </div>
                        </div>
                    </div>     
                </td>    
            </tr>        
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Full Time/Part Time</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="cmd_01773"
                                id="cmd_01773" name="cmd_01773" type="text" placeholder="Enter Time"   data-id="managing_contact_name" >
                        </div> 
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Supervising Physician:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="cmd_01774"
                                id="cmd_01774" name="cmd_01774" type="text" placeholder="Enter Supervising Physician"   data-id="managing_contact_name" >
                        </div> 
                    </div>
                </td>
            </tr>  
            <tr>
                <td colspan="12">
                    <b style="font-size:16px;">Credential Information</b>
                </td>
            </tr>   
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">*NPI number:</label> 
                    </div> 
                </td>
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="1634"
                                    id="1634" name="1634" type="text" placeholder= "NPI" [class.is-invalid]="(TannerClinicFroms.controls['1634'].touched && TannerClinicFroms.controls['1634'].invalid) || (TannerClinicFroms.controls['1634'].invalid && isSubmit)"  data-id="managing_contact_name" >
                                <div class="formLabel col-md-11"
                                    *ngIf="TannerClinicFroms.controls['1634'].touched && TannerClinicFroms.controls['1634'].invalid">
                                    <small class="text-danger" *ngIf="TannerClinicFroms.controls['1634'].errors?.required">
                                        required
                                    </small>
                                </div>
                        </div>
                    </div>
                </td>        
            </tr>     
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">*User Name:</label> 
                    </div> 
                </td>
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="1635"
                                    id="1635" name="1635" type="text" placeholder= "NPI Username (NPPES)" [class.is-invalid]="(TannerClinicFroms.controls['1635'].touched && TannerClinicFroms.controls['1635'].invalid) || (TannerClinicFroms.controls['1635'].invalid && isSubmit)"  data-id="managing_contact_name" >
                                <div class="formLabel col-md-11"
                                    *ngIf="TannerClinicFroms.controls['1635'].touched && TannerClinicFroms.controls['1635'].invalid">
                                    <small class="text-danger" *ngIf="TannerClinicFroms.controls['1635'].errors?.required">
                                        required
                                    </small>
                                </div>
                        </div>
                    </div>
                </td>        
            </tr> 
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">*Password:</label> 
                    </div> 
                </td>
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="1636"
                                    id="1636" name="1636" type="text" placeholder= "NPI Password (NPPES)" [class.is-invalid]="(TannerClinicFroms.controls['1636'].touched && TannerClinicFroms.controls['1636'].invalid) || (TannerClinicFroms.controls['1636'].invalid && isSubmit)"  data-id="managing_contact_name" >
                                <div class="formLabel col-md-11"
                                    *ngIf="TannerClinicFroms.controls['1636'].touched && TannerClinicFroms.controls['1636'].invalid">
                                    <small class="text-danger" *ngIf="TannerClinicFroms.controls['1636'].errors?.required">
                                        required
                                    </small>
                                </div>
                        </div>
                    </div>
                </td>        
            </tr>  
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Medicare Number:</label> 
                    </div> 
                </td>
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="325"
                                    id="325" name="325" type="text" placeholder= "Medicare Number (PTAN)"   data-id="managing_contact_name" >
                                
                        </div>
                    </div>
                </td>        
            </tr>    
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Medicaid Number:</label> 
                    </div> 
                </td>
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard " id="812" name="812" formControlName="812" type="text" data-id="medicaid" placeholder="Medicaid Number">                            
                        </div>
                    </div>
                </td>        
            </tr> 
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">CAQH Number:</label> 
                    </div> 
                </td>
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard " id="333" name="333" formControlName="333" type="text" data-id="medicaid" placeholder="CAQH ID Number">                            
                        </div>
                    </div>
                </td>        
            </tr>      
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">User Name:</label> 
                    </div> 
                </td>
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard" id="334"  name="334" formControlName="334" type="text" placeholder="CAQH Username" >
                        </div>
                    </div>
                </td>        
            </tr>      
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Password</label> 
                    </div> 
                </td>
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard" id="335"  name="335" formControlName="335" type="text" placeholder="CAQH Password">                        
                        </div>
                    </div>
                </td>        
            </tr>    
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">UPIN:</label> 
                    </div> 
                </td>
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard" id="819"  name="819" formControlName="819" type="text" placeholder="UPIN">                        
                        </div>
                    </div>
                </td>        
            </tr> 
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">DEA Number:</label> 
                    </div> 
                </td>
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard" id="309"  name="309" formControlName="309" type="text" placeholder="DEA Number">                        
                        </div>
                    </div>
                </td>        
            </tr> 
            <tr> 
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Effective Date:(mm/dd/yyyy)</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="input-group">
                                <input type="text" placeholder="DEA Issue Date" ngbDatepicker #d310="ngbDatepicker" id="310" class="form-control" formControlName="310" (blur)="clearInputIfInvalid('310')"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d310.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div>
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('310').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY)</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('310').errors?.invalidMonth">
                                  Month is out of range</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('310').errors?.invalidDateyear">
                                    year is out of range(1900-2099)</small>
                                    <small class="text-danger" *ngIf="TannerClinicFroms.get('310').errors?.invalidDateRange">
                                      date is out of range</small>
                            </div>
                        </div>
                    </div>     
                </td>    
            </tr> 
            <tr> 
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Expiration Date:(mm/dd/yyyy)</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="input-group">
                                <input type="text" placeholder="DEA Expiration Date" ngbDatepicker #d311="ngbDatepicker" id="311" class="form-control" formControlName="311" (blur)="clearInputIfInvalid('311')"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d311.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div>
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('311').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY)</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('311').errors?.invalidMonth">
                                  Month is out of range</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('311').errors?.invalidDateyear">
                                    year is out of range(1900-2099)</small>
                                    <small class="text-danger" *ngIf="TannerClinicFroms.get('311').errors?.invalidDateRange">
                                      date is out of range</small>
                            </div>
                        </div>
                    </div>     
                </td>    
            </tr>   
            <tr> 
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Original effective Date:</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="input-group">
                                <input type="text" placeholder="Original Effective Date" ngbDatepicker #d01775="ngbDatepicker" id="cmd_01775" class="form-control" formControlName="cmd_01775" (blur)="clearInputIfInvalid('cmd_01775')"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d01775.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div>
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('cmd_01775').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY)</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('cmd_01775').errors?.invalidMonth">
                                  Month is out of range</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('cmd_01775').errors?.invalidDateyear">
                                    year is out of range(1900-2099)</small>
                                    <small class="text-danger" *ngIf="TannerClinicFroms.get('cmd_01775').errors?.invalidDateRange">
                                      date is out of range</small>
                            </div>
                        </div>
                    </div>     
                </td>    
            </tr>    
            <tr> 
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Utah License Number:</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                                <input type="text" placeholder="License Number"  id="293" class="form-control" formControlName="293"
                                >
                        </div>        
                    </div>     
                </td>    
            </tr>  
            <tr> 
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Effective Date:(mm/dd/yyyy)</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="input-group">
                                <input type="text" placeholder="License Issue Date" ngbDatepicker #d294="ngbDatepicker" id="294" class="form-control" formControlName="294" (blur)="clearInputIfInvalid('294')"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d294.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div>
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('294').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY)</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('294').errors?.invalidMonth">
                                  Month is out of range</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('294').errors?.invalidDateyear">
                                    year is out of range(1900-2099)</small>
                                    <small class="text-danger" *ngIf="TannerClinicFroms.get('294').errors?.invalidDateRange">
                                      date is out of range</small>
                            </div>
                        </div>
                    </div>     
                </td>    
            </tr>    
            <tr> 
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Expiration Date:(mm/dd/yyyy)</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="input-group">
                                <input type="text" placeholder="License Expiration Date" ngbDatepicker #d295="ngbDatepicker" id="295" class="form-control" formControlName="295" (blur)="clearInputIfInvalid('295')"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d295.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div>
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('295').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY)</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('295').errors?.invalidMonth">
                                  Month is out of range</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('295').errors?.invalidDateyear">
                                    year is out of range(1900-2099)</small>
                                    <small class="text-danger" *ngIf="TannerClinicFroms.get('295').errors?.invalidDateRange">
                                      date is out of range</small>
                            </div>
                        </div>
                    </div>     
                </td>    
            </tr>  
            <tr> 
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Original effective Date:</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="input-group">
                                <input type="text" placeholder="Original Effective Date" ngbDatepicker #d01776="ngbDatepicker" id="cmd_01776" class="form-control" formControlName="cmd_01776"  (blur)="clearInputIfInvalid('cmd_01776')"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d01776.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div>
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('cmd_01776').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY)</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('cmd_01776').errors?.invalidMonth">
                                  Month is out of range</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('cmd_01776').errors?.invalidDateyear">
                                    year is out of range(1900-2099)</small>
                                    <small class="text-danger" *ngIf="TannerClinicFroms.get('cmd_01776').errors?.invalidDateRange">
                                      date is out of range</small>
                            </div>
                        </div>
                    </div>     
                </td>    
            </tr>  
            <tr> 
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Utah Controlled License Number:</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                                <input type="text" placeholder="State Controlled Substance Number (CDS)"  id="337" class="form-control" formControlName="337"
                                >
                        </div>        
                    </div>     
                </td>    
            </tr>  
            <tr> 
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Effective Date:(mm/dd/yyyy)</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="input-group">
                                <input type="text" placeholder="CDS Issue Date" ngbDatepicker #d338="ngbDatepicker" id="338" class="form-control" formControlName="338"  (blur)="clearInputIfInvalid('338')"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d338.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div>
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('338').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY)</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('338').errors?.invalidMonth">
                                  Month is out of range</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('338').errors?.invalidDateyear">
                                    year is out of range(1900-2099)</small>
                                    <small class="text-danger" *ngIf="TannerClinicFroms.get('338').errors?.invalidDateRange">
                                      date is out of range</small>
                            </div>
                        </div>
                    </div>     
                </td>    
            </tr>    
            <tr> 
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Expiration Date:(mm/dd/yyyy)</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="input-group">
                                <input type="text" placeholder="CDS Exp Date" ngbDatepicker #d339="ngbDatepicker" id="339" class="form-control" formControlName="339"  (blur)="clearInputIfInvalid('339')"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d339.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div>
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('339').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY)</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('339').errors?.invalidMonth">
                                  Month is out of range</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('339').errors?.invalidDateyear">
                                    year is out of range(1900-2099)</small>
                                    <small class="text-danger" *ngIf="TannerClinicFroms.get('339').errors?.invalidDateRange">
                                      date is out of range</small>
                            </div>
                        </div>
                    </div>     
                </td>    
            </tr>  
            <tr> 
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Original effective Date: (mm/dd/yyyy)</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="input-group">
                                <input type="text" placeholder="Original Effective Date" ngbDatepicker #d01777="ngbDatepicker" id="cmd_01777" class="form-control" formControlName="cmd_01777" (blur)="clearInputIfInvalid('cmd_01777')"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d01777.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div>
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('cmd_01777').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY)</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('cmd_01777').errors?.invalidMonth">
                                  Month is out of range</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('cmd_01777').errors?.invalidDateyear">
                                    year is out of range(1900-2099)</small>
                                    <small class="text-danger" *ngIf="TannerClinicFroms.get('cmd_01777').errors?.invalidDateRange">
                                      date is out of range</small>
                            </div>
                        </div>
                    </div>     
                </td>    
            </tr>  
            <tr> 
                <td colspan="12">
                    <div class="row">
                        <label class="formLabel">All other current/past State Licenses:</label> 
                    </div>                    
                </td>     
            </tr> 
            <tr> 
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">State:</label> 
                    </div>                    
                </td> 
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <ng-select
                                    [items]="altLicenseState"
                                    bindLabel="name"
                                    placeholder="Enter State"
                                    bindValue="id"
                                    formControlName="907"
                                    
                                    >
                                </ng-select> 
                        </div>
                    </div>
                </td>    
            </tr> 
            <tr> 
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Number:</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                                <input type="text" placeholder="License 2 Number "  id="300" class="form-control" formControlName="300"
                                >
                        </div>        
                    </div>     
                </td>    
            </tr> 
            <tr> 
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Effective Date:(mm/dd/yyyy)</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="input-group">
                                <input type="text" placeholder="License 2 Issue Date " ngbDatepicker #d307="ngbDatepicker" id="307" class="form-control" formControlName="307" (blur)="clearInputIfInvalid('307')"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d307.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div>
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('307').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY)</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('307').errors?.invalidMonth">
                                  Month is out of range</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('307').errors?.invalidDateyear">
                                    year is out of range(1900-2099)</small>
                                    <small class="text-danger" *ngIf="TannerClinicFroms.get('307').errors?.invalidDateRange">
                                      date is out of range</small>
                            </div>
                        </div>
                    </div>     
                </td>    
            </tr> 
            <tr> 
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Expiration Date:(mm/dd/yyyy)</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="input-group">
                                <input type="text" placeholder="Alt License Exp  " ngbDatepicker #d308="ngbDatepicker" id="308" class="form-control" formControlName="308" (blur)="clearInputIfInvalid('308')"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d308.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div>
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('308').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY)</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('308').errors?.invalidMonth">
                                  Month is out of range</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('308').errors?.invalidDateyear">
                                    year is out of range(1900-2099)</small>
                                    <small class="text-danger" *ngIf="TannerClinicFroms.get('308').errors?.invalidDateRange">
                                      date is out of range</small>
                            </div>
                        </div>
                    </div>     
                </td>    
            </tr>   
            <tr> 
                <td colspan="12">
                    <div class="row">
                        <label class="formLabel">All other current/past Controlled Licenses:</label> 
                    </div>                    
                </td>     
            </tr>
            <tr> 
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">State:</label> 
                    </div>                    
                </td> 
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <ng-select
                                    [items]="AltCdsStates"
                                    bindLabel="name"
                                    placeholder="Enter Alt CDS State"
                                    bindValue="id"
                                    formControlName="997"
                                    
                                    >
                                </ng-select> 
                        </div>
                    </div>
                </td>    
            </tr>  
            <tr> 
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Number:</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                                <input type="text" placeholder="Alt CDS Number"  id="340" class="form-control" formControlName="340" 
                                >
                        </div>        
                    </div>     
                </td>    
            </tr> 
            <tr> 
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Effective Date:(mm/dd/yyyy)</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="input-group">
                                <input type="text" placeholder="Alt CDS Issue Date " ngbDatepicker #d341  ="ngbDatepicker" id="341" class="form-control" formControlName="341" (blur)="clearInputIfInvalid('341')"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d341.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div>
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('341').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY)</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('341').errors?.invalidMonth">
                                  Month is out of range</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('341').errors?.invalidDateyear">
                                    year is out of range(1900-2099)</small>
                                    <small class="text-danger" *ngIf="TannerClinicFroms.get('341').errors?.invalidDateRange">
                                      date is out of range</small>
                            </div>
                        </div>
                    </div>     
                </td>    
            </tr> 
            <tr> 
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Expiration Date:(mm/dd/yyyy)</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="input-group">
                                <input type="text" placeholder="Alt CDS Exp Date " ngbDatepicker #d342="ngbDatepicker" id="342" class="form-control" formControlName="342" (blur)="clearInputIfInvalid('342')"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d342.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div>
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('342').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY)</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('342').errors?.invalidMonth">
                                  Month is out of range</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('342').errors?.invalidDateyear">
                                    year is out of range(1900-2099)</small>
                                    <small class="text-danger" *ngIf="TannerClinicFroms.get('342').errors?.invalidDateRange">
                                      date is out of range</small>
                            </div>
                        </div>
                    </div>     
                </td>    
            </tr>  
            <tr> 
                <td colspan="12">
                    <div class="row">
                        <label class="formLabel">Board Certification</label> 
                    </div>                    
                </td>  
            </tr>    
            <tr> 
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">*Are you Board Certified:</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <ng-select  [class.is-invalid]="(TannerClinicFroms.controls['1234'].touched && TannerClinicFroms.controls['1234'].invalid) || (TannerClinicFroms.controls['1234'].invalid && isSubmit)"
                                    [items]="boardStatus" bindLabel="name" placeholder="Select Status" bindValue="id" 
                                    formControlName="1234">
                                    </ng-select>
                                    <div class="formLabel col-md-11"
                                    *ngIf="TannerClinicFroms.controls['1234'].touched && TannerClinicFroms.controls['1234'].invalid">
                                    <small class="text-danger" *ngIf="TannerClinicFroms.controls['1234'].errors?.required">
                                        required
                                    </small>
                                </div>   
                        </div>
                    </div>     
                </td>    
            </tr>  
            <tr> 
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Name of Board:</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <ng-select
                                    [items]="boardCertification"
                                    bindLabel="name"
                                    placeholder="Select Board Certification"
                                    bindValue="id"
                                    formControlName="554"
                                    >
                            </ng-select>
                        </div>
                    </div>
                </td>    
            </tr> 
            <tr> 
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Cert #:</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                                <input type="text" placeholder="Primary Board Certification Number"  id="1169" class="form-control" formControlName="1169"
                                >
                        </div>        
                    </div>     
                </td>    
            </tr> 
            <tr> 
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Effective Date:(mm/dd/yyyy)</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="input-group">
                                <input type="text" placeholder="Primary Board Original Issue Date  " ngbDatepicker #d557="ngbDatepicker" id="557" class="form-control" formControlName="557" (blur)="clearInputIfInvalid('557')"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d557.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div>
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('557').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY)</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('557').errors?.invalidMonth">
                                  Month is out of range</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('557').errors?.invalidDateyear">
                                    year is out of range(1900-2099)</small>
                                    <small class="text-danger" *ngIf="TannerClinicFroms.get('557').errors?.invalidDateRange">
                                      date is out of range</small>
                            </div>
                        </div>
                    </div>     
                </td>    
            </tr>  
            <tr> 
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Expiration Date:(mm/dd/yyyy)</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="input-group">
                                <input type="text" placeholder="Primary Board Expiration Date" ngbDatepicker #d560="ngbDatepicker" id="560" class="form-control" formControlName="560" (blur)="clearInputIfInvalid('560')"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d560.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div>
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('560').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY)</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('560').errors?.invalidMonth">
                                  Month is out of range</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('560').errors?.invalidDateyear">
                                    year is out of range(1900-2099)</small>
                                    <small class="text-danger" *ngIf="TannerClinicFroms.get('560').errors?.invalidDateRange">
                                      date is out of range</small>
                            </div>
                        </div>
                    </div>     
                </td>    
            </tr>  
            <tr> 
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Re-certification Date:</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="input-group">
                                <input type="text" placeholder="Primary Board First Recert Date " ngbDatepicker #d558="ngbDatepicker" id="558" class="form-control" formControlName="558" (blur)="clearInputIfInvalid('558')"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d558.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div>
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('558').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY)</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('558').errors?.invalidMonth">
                                  Month is out of range</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('558').errors?.invalidDateyear">
                                    year is out of range(1900-2099)</small>
                                    <small class="text-danger" *ngIf="TannerClinicFroms.get('558').errors?.invalidDateRange">
                                      date is out of range</small>
                            </div>
                        </div>
                    </div>     
                </td>    
            </tr>
            <tr> 
                <td colspan="12">
                    <div class="row">
                        <label class="formLabel">If not certified are you eligible:</label> 
                    </div>                    
                </td>      
            </tr>  
            <tr> 
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Plans for getting certified:</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                                <input type="text" placeholder="Enter Plans Certified"  id="cmd_01794" class="form-control" formControlName="cmd_01794"
                                >
                        </div>        
                    </div>     
                </td>    
            </tr> 
            <tr> 
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Test date:</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="input-group">
                                <input type="text" placeholder="Primary Board Test Date " ngbDatepicker #d1377="ngbDatepicker" id="1377" class="form-control" formControlName="1377" (blur)="clearInputIfInvalid('1377')"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d1377.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div>
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('1377').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY)</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('1377').errors?.invalidMonth">
                                  Month is out of range</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('1377').errors?.invalidDateyear">
                                    year is out of range(1900-2099)</small>
                                    <small class="text-danger" *ngIf="TannerClinicFroms.get('1377').errors?.invalidDateRange">
                                      date is out of range</small>
                            </div>
                        </div>
                    </div>     
                </td>    
            </tr> 
            <tr> 
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Additional Board Certification:</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <ng-select  
                                    [items]="boardStatus" bindLabel="name" placeholder="Select Second Status" bindValue="id" 
                                    formControlName="1235">
                                    </ng-select>
                                    
                        </div>
                    </div>     
                </td>    
            </tr>  
            <tr> 
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Name of Board:</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <ng-select
                                    [items]="boardCertification"
                                    bindLabel="name"
                                    placeholder="Select Second Board Certification"
                                    bindValue="id"
                                    formControlName="561"
                                    >
                            </ng-select>
                        </div>
                    </div>
                </td>    
            </tr> 
            <tr> 
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Cert #:</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                                <input type="text" placeholder="Secondary Board Certification Number"  id="562" class="form-control" formControlName="562"
                                >
                        </div>        
                    </div>     
                </td>    
            </tr> 
            <tr> 
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Effective Date:(mm/dd/yyyy)</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="input-group">
                                <input type="text" placeholder="Secondary Board Original Issue Date  " ngbDatepicker #d564="ngbDatepicker" id="564" class="form-control" formControlName="564" (blur)="clearInputIfInvalid('564')"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d564.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div>
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('564').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY)</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('564').errors?.invalidMonth">
                                  Month is out of range</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('564').errors?.invalidDateyear">
                                    year is out of range(1900-2099)</small>
                                    <small class="text-danger" *ngIf="TannerClinicFroms.get('564').errors?.invalidDateRange">
                                      date is out of range</small>
                            </div>
                        </div>
                    </div>     
                </td>    
            </tr>  
            <tr> 
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Expiration Date:(mm/dd/yyyy)</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="input-group">
                                <input type="text" placeholder="Secondary Board Expiration Date" ngbDatepicker #d567="ngbDatepicker" id="567" class="form-control" formControlName="567" (blur)="clearInputIfInvalid('567')"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d567.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div>
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('567').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY)</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('567').errors?.invalidMonth">
                                  Month is out of range</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('567').errors?.invalidDateyear">
                                    year is out of range(1900-2099)</small>
                                    <small class="text-danger" *ngIf="TannerClinicFroms.get('567').errors?.invalidDateRange">
                                      date is out of range</small>
                            </div>
                        </div>
                    </div>     
                </td>    
            </tr>  
            <tr> 
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Re-certification Date:</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="input-group">
                                <input type="text" placeholder="Secondary Board First Recert Date " ngbDatepicker #d565="ngbDatepicker" id="565" class="form-control" formControlName="565" (blur)="clearInputIfInvalid('565')"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d565.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div>
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('565').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY)</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('565').errors?.invalidMonth">
                                  Month is out of range</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('565').errors?.invalidDateyear">
                                    year is out of range(1900-2099)</small>
                                    <small class="text-danger" *ngIf="TannerClinicFroms.get('565').errors?.invalidDateRange">
                                      date is out of range</small>
                            </div>
                        </div>
                    </div>     
                </td>    
            </tr>
            <tr> 
                <td colspan="12">
                    <div class="row">
                        <label class="formLabel">If not certified are you eligible:</label> 
                    </div>                    
                </td>      
            </tr>  
            <tr> 
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Plans for getting certified:</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                                <input type="text" placeholder="Enter Plans Certified"  id="cmd_01778" class="form-control" formControlName="cmd_01778"
                                >
                        </div>        
                    </div>     
                </td>    
            </tr> 
            <tr> 
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Test date:</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="input-group">
                                <input type="text" placeholder="Secondary Board Test Date " ngbDatepicker #d1378="ngbDatepicker" id="1378" class="form-control" formControlName="1378" (blur)="clearInputIfInvalid('1378')"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d1378.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div>
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('1378').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY)</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('1378').errors?.invalidMonth">
                                  Month is out of range</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('1378').errors?.invalidDateyear">
                                    year is out of range(1900-2099)</small>
                                    <small class="text-danger" *ngIf="TannerClinicFroms.get('1378').errors?.invalidDateRange">
                                      date is out of range</small>
                            </div>
                        </div>
                    </div>     
                </td>    
            </tr>   
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Current Malpractice Insurance:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="345"
                                id="345" name="345" type="text" placeholder="MLP Carrier Name"   data-id="managing_contact_name" >
                        </div> 
                    </div>
                </td>
            </tr> 
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Address:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-6">
                            <input class="form-control onboard"  formControlName="901"
                                id="901" name="901" type="text" placeholder="Enter MLP Add1"   data-id="managing_contact_name" >
                        </div> 
                        <div class="col-md-6">
                            <input class="form-control onboard"  formControlName="902"
                                id="902" name="902" type="text" placeholder="Enter MLP Add2"   data-id="managing_contact_name" >
                        </div> 
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">City, State Zip:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-4">
                            <input class="form-control onboard"  formControlName="903"
                                id="903" name="903" type="text" placeholder="Enter MLP City"   data-id="managing_contact_name" >
                        </div> 
                        <div class="col-md-4">
                            <ng-select
                                    [items]="states"
                                    bindLabel="name"
                                    placeholder="Enter State"
                                    bindValue="id"
                                    formControlName="905"
                                    
                                    >
                                </ng-select>
                        </div>
                        <div class="col-md-4">
                            <input class="form-control onboard"  formControlName="906"
                                id="906" name="906" type="text" placeholder="Enter MLP Zip"   data-id="managing_contact_name" >
                        </div> 
                    </div>
                </td>
            </tr>   
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Phone:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="1266"  (keydown)="handleNumber($event,1266)" maxlength="12"
                                id="1266" name="1266" type="text" placeholder="Enter MLP Phone"   data-id="managing_contact_name" >
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('1266').errors?.pattern">Required pattern xxx-xxx-xxxx</small>
                        </div> 
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Fax number:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="1273"
                                id="1273" name="1273" type="text" placeholder="Enter MLP Fax"   data-id="managing_contact_name" >
                        </div> 
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Policy Number:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="346"
                                id="346" name="346" type="text" placeholder="Enter MLP Policy Number"   data-id="managing_contact_name" >
                        </div> 
                    </div>
                </td>
            </tr>     
            <tr> 
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Effective Date:(mm/dd/yyyy)</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="input-group">
                                <input type="text" placeholder="MLP Issue Date  " ngbDatepicker #d348="ngbDatepicker" id="348" class="form-control" formControlName="348" (blur)="clearInputIfInvalid('348')"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d348.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div>
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('348').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY)</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('348').errors?.invalidMonth">
                                  Month is out of range</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('348').errors?.invalidDateyear">
                                    year is out of range(1900-2099)</small>
                                    <small class="text-danger" *ngIf="TannerClinicFroms.get('348').errors?.invalidDateRange">
                                      date is out of range</small>
                            </div>
                        </div>
                    </div>     
                </td>    
            </tr>  
            <tr> 
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Expiration Date:(mm/dd/yyyy)</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="input-group">
                                <input type="text" placeholder="MLP Expiration Date" ngbDatepicker #d349="ngbDatepicker" id="349" class="form-control" formControlName="349" (blur)="clearInputIfInvalid('349')"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d349.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div>
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('349').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY)</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('349').errors?.invalidMonth">
                                  Month is out of range</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('349').errors?.invalidDateyear">
                                    year is out of range(1900-2099)</small>
                                    <small class="text-danger" *ngIf="TannerClinicFroms.get('349').errors?.invalidDateRange">
                                      date is out of range</small>
                            </div>
                        </div>
                    </div>     
                </td>    
            </tr>  
            <tr> 
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Original Effective Date: </label> 
                    </div>                    
                </td>  
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="input-group">
                                <input type="text" placeholder="MLP Retroactive Date" ngbDatepicker #d945="ngbDatepicker" id="945" class="form-control" formControlName="945" (blur)="clearInputIfInvalid('945')"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d945.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div>
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('945').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY)</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('945').errors?.invalidMonth">
                                  Month is out of range</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('945').errors?.invalidDateyear">
                                    year is out of range(1900-2099)</small>
                                    <small class="text-danger" *ngIf="TannerClinicFroms.get('945').errors?.invalidDateRange">
                                      date is out of range</small>
                            </div>
                        </div>
                    </div>     
                </td>    
            </tr> 
            <tr> 
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Limits per claim:</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-6">
                            <ng-select
                            [items]="preClaim"
                            bindLabel="name"
                            placeholder="Select Per Occurrence"
                            bindValue="id"
                            formControlName="350"
                          >
                          </ng-select>
                        </div>
                        <div class="col-md-6">
                            <ng-select
                                [items]="aggregates"
                                bindLabel="name"
                                placeholder="Select Aggregate"
                                bindValue="id"
                                formControlName="351"
                                >   
                            </ng-select>
                        </div>
                    </div>     
                </td>    
            </tr>     
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Past Malpractice Insurance: (last 10 years)</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="add_1_1242"
                                id="add_1_1242" name="add_1_1242" type="text" placeholder="Previous MLP Carriers"   data-id="managing_contact_name" >
                        </div> 
                    </div>
                </td>
            </tr>  
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Address:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-6">
                            <input class="form-control onboard"  formControlName="add_1_1244"
                                id="add_1_1244" name="add_1_1244" type="text" placeholder="Previous MLP Add1"   data-id="managing_contact_name" >
                        </div> 
                        <div class="col-md-6">
                            <input class="form-control onboard"  formControlName="add_1_1245"
                                id="add_1_1245" name="add_1_1245" type="text" placeholder="Previous MLP Add2"   data-id="managing_contact_name" >
                        </div> 
                    </div>
                </td>
            </tr>  
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">City, State Zip:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-4">
                            <input class="form-control onboard"  formControlName="add_1_1246"
                                id="add_1_1246" name="add_1_1246" type="text" placeholder="Enter Prev MLP City"   data-id="managing_contact_name" >
                        </div> 
                        <div class="col-md-4">
                            <ng-select
                                    [items]="states"
                                    bindLabel="name"
                                    placeholder="Enter State"
                                    bindValue="id"
                                    formControlName="add_1_1248"
                                    
                                    >
                                </ng-select>
                        </div>
                        <div class="col-md-4">
                            <input class="form-control onboard"  formControlName="add_1_1249"
                                id="add_1_1249" name="add_1_1249" type="text" placeholder="Enter Prev MLP Zip"   data-id="managing_contact_name" >
                        </div> 
                    </div>
                </td>
            </tr>   
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Phone:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="add_1_1254" (keydown)="handleNumber($event,'add_1_1254')" maxlength="12"
                                id="add_1_1254" name="add_1_1254" type="text" placeholder="Enter Prev MLP Phone"   data-id="managing_contact_name" />
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('add_1_1254').errors?.pattern">Required pattern xxx-xxx-xxxx</small>
                        </div> 
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Fax number:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="add_1_1255"
                                id="add_1_1255" name="add_1_1255" type="text" placeholder="Enter Prev MLP Fax"   data-id="managing_contact_name" >
                        </div> 
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Policy Number:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="add_1_1256"
                                id="add_1_1256" name="add_1_1256" type="text" placeholder="Enter Prev MLP Policy Number"   data-id="managing_contact_name" >
                        </div> 
                    </div>
                </td>
            </tr>     
            <tr> 
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Effective Date:(mm/dd/yyyy)</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="input-group">
                                <input type="text" placeholder="MLP Prev Issue Date  " ngbDatepicker #d01252="ngbDatepicker" id="add_1_1252" class="form-control" formControlName="add_1_1252" (blur)="clearInputIfInvalid('add_1_1252')"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d01252.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div>
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('add_1_1252').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY)</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('add_1_1252').errors?.invalidMonth">
                                  Month is out of range</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('add_1_1252').errors?.invalidDateyear">
                                    year is out of range(1900-2099)</small>
                                    <small class="text-danger" *ngIf="TannerClinicFroms.get('add_1_1252').errors?.invalidDateRange">
                                      date is out of range</small>
                            </div>
                        </div>
                    </div>     
                </td>    
            </tr>  
            <tr> 
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Expiration Date:(mm/dd/yyyy)</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="input-group">
                                <input type="text" placeholder="MLP Expiration Date" ngbDatepicker #d01253="ngbDatepicker" id="add_1_1253" class="form-control" formControlName="add_1_1253" (blur)="clearInputIfInvalid('add_1_1253')"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d01253.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div>
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('add_1_1253').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY)</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('add_1_1253').errors?.invalidMonth">
                                  Month is out of range</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('add_1_1253').errors?.invalidDateyear">
                                    year is out of range(1900-2099)</small>
                                    <small class="text-danger" *ngIf="TannerClinicFroms.get('add_1_1253').errors?.invalidDateRange">
                                      date is out of range</small>
                            </div>
                        </div>
                    </div>     
                </td>    
            </tr>  
            
            <tr> 
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Limits per claim:</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-6">
                            <ng-select
                            [items]="preClaim"
                            bindLabel="name"
                            placeholder="Select Per Occurrence"
                            bindValue="id"
                            formControlName="add_1_1264"
                          >
                          </ng-select>
                        </div>
                        <div class="col-md-6">
                            <ng-select
                                [items]="aggregates"
                                bindLabel="name"
                                placeholder="Select Aggregate"
                                bindValue="id"
                                formControlName="add_1_1265"
                                >   
                            </ng-select>
                        </div>
                    </div>     
                </td>    
            </tr>     
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Past Malpractice Insurance: (last 10 years)</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="add_2_1242"
                                id="add_2_1242" name="add_2_1242" type="text" placeholder="Previous MLP 2 Carriers"   data-id="managing_contact_name" >
                        </div> 
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Address:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-6">
                            <input class="form-control onboard"  formControlName="add_2_1244"
                                id="add_2_1244" name="add_2_1244" type="text" placeholder="Previous 2 MLP Add1"   data-id="managing_contact_name" >
                        </div> 
                        <div class="col-md-6">
                            <input class="form-control onboard"  formControlName="add_2_1245"
                                id="add_2_1245" name="add_2_1245" type="text" placeholder="Previous 2 MLP Add2"   data-id="managing_contact_name" >
                        </div> 
                    </div>
                </td>
            </tr>  
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">City, State Zip:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-4">
                            <input class="form-control onboard"  formControlName="add_2_1246"
                                id="add_2_1246" name="add_2_1246" type="text" placeholder="Enter 2 Prev MLP City"   data-id="managing_contact_name" >
                        </div> 
                        <div class="col-md-4">
                            <ng-select
                                    [items]="states"
                                    bindLabel="name"
                                    placeholder="Enter State"
                                    bindValue="id"
                                    formControlName="add_2_1248"
                                    
                                    >
                                </ng-select>
                        </div>
                        <div class="col-md-4">
                            <input class="form-control onboard"  formControlName="add_2_1249"
                                id="add_2_1249" name="add_2_1249" type="text" placeholder="Enter Prev 2 MLP Zip"   data-id="managing_contact_name" >
                        </div> 
                    </div>
                </td>
            </tr>   
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Phone:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="add_2_1254" (keydown)="handleNumber($event,'add_2_1254')" maxlength="12"
                                id="add_2_1254" name="add_2_1254" type="text" placeholder="Enter Prev 2 MLP Phone"   data-id="managing_contact_name" >
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('add_2_1254').errors?.pattern">Required pattern xxx-xxx-xxxx</small>
                        </div> 
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Fax number:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="add_2_1255"
                                id="add_2_1255" name="add_2_1255" type="text" placeholder="Enter Prev 2 MLP Fax"   data-id="managing_contact_name" >
                        </div> 
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Policy Number:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="add_2_1256"
                                id="add_2_1256" name="add_2_1256" type="text" placeholder="Enter Prev 2 MLP Policy Number"   data-id="managing_contact_name" >
                        </div> 
                    </div>
                </td>
            </tr>     
            <tr> 
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Effective Date:(mm/dd/yyyy)</label> 
                    </div>                    
                </td>  
                <td colspan="6" >
                    <div class="row">
                        <div class="col-md-8">
                            <div class="input-group">
                                <input type="text" placeholder="Prev MLP 2 Issue Date  " ngbDatepicker #d201252="ngbDatepicker" id="add_2_1252" class="form-control" formControlName="add_2_1252" (blur)="clearInputIfInvalid('add_2_1252')"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d201252.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div>
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('add_2_1252').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY)</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('add_2_1252').errors?.invalidMonth">
                                  Month is out of range</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('add_2_1252').errors?.invalidDateyear">
                                    year is out of range(1900-2099)</small>
                                    <small class="text-danger" *ngIf="TannerClinicFroms.get('add_2_1252').errors?.invalidDateRange">
                                      date is out of range</small>
                            </div>
                        </div>
                    </div>     
                </td>    
            </tr>  
            <tr> 
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Expiration Date:(mm/dd/yyyy)</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="input-group">
                                <input type="text" placeholder="Prev MLP 2 Expiration Date" ngbDatepicker #d201253="ngbDatepicker" id="add_2_1253" class="form-control" formControlName="add_2_1253" (blur)="clearInputIfInvalid('add_2_1253')"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d201253.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div>
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('add_2_1253').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY)</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('add_2_1253').errors?.invalidMonth">
                                  Month is out of range</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('add_2_1253').errors?.invalidDateyear">
                                    year is out of range(1900-2099)</small>
                                    <small class="text-danger" *ngIf="TannerClinicFroms.get('add_2_1253').errors?.invalidDateRange">
                                      date is out of range</small>
                            </div>
                        </div>
                    </div>     
                </td>    
            </tr>  
            
            <tr> 
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Limits per claim:</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-6">
                            <ng-select
                            [items]="preClaim"
                            bindLabel="name"
                            placeholder="Select Per Occurrence"
                            bindValue="id"
                            formControlName="add_2_1264"
                          >
                          </ng-select>
                        </div>
                        <div class="col-md-6">
                            <ng-select
                                [items]="aggregates"
                                bindLabel="name"
                                placeholder="Select Aggregate"
                                bindValue="id"
                                formControlName="add_2_1265"
                                >   
                            </ng-select>
                        </div>
                    </div>     
                </td>    
            </tr>     
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Past Malpractice Insurance: (last 10 years)</label> 
                    </div>                    
                </td> 
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="add_3_1242"
                                id="add_3_1242" name="add_3_1242" type="text" placeholder="Previous MLP 3 Carriers"   data-id="managing_contact_name" >
                        </div> 
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Address:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-6">
                            <input class="form-control onboard"  formControlName="add_3_1244"
                                id="add_3_1244" name="add_3_1244" type="text" placeholder="Previous MLP 3 Add1"   data-id="managing_contact_name" >
                        </div> 
                        <div class="col-md-6">
                            <input class="form-control onboard"  formControlName="add_3_1245"
                                id="add_3_1245" name="add_3_1245" type="text" placeholder="Previous MLP 3 Add2"   data-id="managing_contact_name" >
                        </div> 
                    </div>
                </td>
            </tr>  
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">City, State Zip:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-4">
                            <input class="form-control onboard"  formControlName="add_3_1246"
                                id="add_3_1246" name="add_3_1246" type="text" placeholder="Enter Prev MLP 3 City"   data-id="managing_contact_name" >
                        </div> 
                        <div class="col-md-4">
                            <ng-select
                                    [items]="states"
                                    bindLabel="name"
                                    placeholder="Enter State"
                                    bindValue="id"
                                    formControlName="add_3_1248"
                                    
                                    >
                                </ng-select>
                        </div>
                        <div class="col-md-4">
                            <input class="form-control onboard"  formControlName="add_3_1249"
                                id="add_3_1249" name="add_3_1249" type="text" placeholder="Enter Prev MLP 3 Zip"   data-id="managing_contact_name" >
                        </div> 
                    </div>
                </td>
            </tr>   
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Phone:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="add_3_1254" (keydown)="handleNumber($event,'add_3_1254')" maxlength="12"
                                id="add_3_1254" name="add_3_1254" type="text" placeholder="Enter Prev MLP 3 Phone"   data-id="managing_contact_name" >
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('add_3_1254').errors?.pattern">Required pattern xxx-xxx-xxxx</small>
                        </div> 
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Fax number:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="add_3_1255"
                                id="add_3_1255" name="add_3_1255" type="text" placeholder="Enter Prev MLP 3 Fax"   data-id="managing_contact_name" >
                        </div> 
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Policy Number:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="add_3_1256"
                                id="add_3_1256" name="add_3_1256" type="text" placeholder="Enter Prev MLP 3 Policy Number"   data-id="managing_contact_name" >
                        </div> 
                    </div>
                </td>
            </tr>     
            <tr> 
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Effective Date:(mm/dd/yyyy)</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="input-group">
                                <input type="text" placeholder="Prev MLP 3 Issue Date  " ngbDatepicker #d031252="ngbDatepicker" id="add_3_1252" class="form-control" formControlName="add_3_1252" (blur)="clearInputIfInvalid('add_3_1252')"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d031252.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div>
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('add_3_1252').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY)</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('add_3_1252').errors?.invalidMonth">
                                  Month is out of range</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('add_3_1252').errors?.invalidDateyear">
                                    year is out of range(1900-2099)</small>
                                    <small class="text-danger" *ngIf="TannerClinicFroms.get('add_3_1252').errors?.invalidDateRange">
                                      date is out of range</small>
                            </div>
                        </div>
                    </div>     
                </td>    
            </tr>  
            <tr> 
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Expiration Date:(mm/dd/yyyy)</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="input-group">
                                <input type="text" placeholder="Prev MLP 3 Expiration Date" ngbDatepicker #d031253="ngbDatepicker" id="add_3_1253" class="form-control" formControlName="add_3_1253" (blur)="clearInputIfInvalid('add_3_1253')"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d031253.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div>
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('add_3_1253').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY)</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('add_3_1253').errors?.invalidMonth">
                                  Month is out of range</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('add_3_1253').errors?.invalidDateyear">
                                    year is out of range(1900-2099)</small>
                                    <small class="text-danger" *ngIf="TannerClinicFroms.get('add_3_1253').errors?.invalidDateRange">
                                      date is out of range</small>
                            </div>
                        </div>
                    </div>     
                </td>    
            </tr>  
            
            <tr> 
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Limits per claim:</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-6">
                            <ng-select
                            [items]="preClaim"
                            bindLabel="name"
                            placeholder="Select Per Occurrence"
                            bindValue="id"
                            formControlName="add_3_1264"
                          >
                          </ng-select>
                        </div>
                        <div class="col-md-6">
                            <ng-select
                                [items]="aggregates"
                                bindLabel="name"
                                placeholder="Select Aggregate"
                                bindValue="id"
                                formControlName="add_3_1265"
                                >   
                            </ng-select>
                        </div>
                    </div>     
                </td>    
            </tr>     
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Past Malpractice Insurance: (last 10 years)</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="add_4_1242"
                                id="add_4_1242" name="add_4_1242" type="text" placeholder="Previous MLP 4 Carriers"   data-id="managing_contact_name" >
                        </div> 
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Address:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-6">
                            <input class="form-control onboard"  formControlName="add_4_1244"
                                id="add_4_1244" name="add_4_1244" type="text" placeholder="Previous MLP 4 Add1"   data-id="managing_contact_name" >
                        </div> 
                        <div class="col-md-6">
                            <input class="form-control onboard"  formControlName="add_4_1245"
                                id="add_4_1245" name="add_4_1245" type="text" placeholder="Previous MLP 4 Add2"   data-id="managing_contact_name" >
                        </div> 
                    </div>
                </td>
            </tr>  
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">City, State Zip:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-4">
                            <input class="form-control onboard"  formControlName="add_4_1246"
                                id="add_4_1246" name="add_4_1246" type="text" placeholder="Enter Prev MLP 4 City"   data-id="managing_contact_name" >
                        </div> 
                        <div class="col-md-4">
                            <ng-select
                                    [items]="states"
                                    bindLabel="name"
                                    placeholder="Enter State"
                                    bindValue="id"
                                    formControlName="add_4_1248"
                                    
                                    >
                                </ng-select>
                        </div>
                        <div class="col-md-4">
                            <input class="form-control onboard"  formControlName="add_4_1249"
                                id="add_4_1249" name="add_4_1249" type="text" placeholder="Enter Prev MLP 4 Zip"   data-id="managing_contact_name" >
                        </div> 
                    </div>
                </td>
            </tr>   
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Phone:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="add_4_1254" (keydown)="handleNumber($event,'add_4_1254')" maxlength="12"
                                id="add_4_1254" name="add_4_1254" type="text" placeholder="Enter Prev MLP 4 Phone"   data-id="managing_contact_name" >
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('add_4_1254').errors?.pattern">Required pattern xxx-xxx-xxxx</small>
                        </div> 
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Fax number:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="add_4_1255"
                                id="add_4_1255" name="add_4_1255" type="text" placeholder="Enter Prev MLP 4 Fax"   data-id="managing_contact_name" >
                        </div> 
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Policy Number:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="add_4_1256"
                                id="add_4_1256" name="add_4_1256" type="text" placeholder="Enter Prev MLP 4 Policy Number"   data-id="managing_contact_name" >
                        </div> 
                    </div>
                </td>
            </tr>     
            <tr> 
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Effective Date:(mm/dd/yyyy)</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="input-group">
                                <input type="text" placeholder="Prev MLP 4 Issue Date  " ngbDatepicker #d041252="ngbDatepicker" id="add_4_1252" class="form-control" formControlName="add_4_1252" (blur)="clearInputIfInvalid('add_4_1252')"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d041252.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div>
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('add_4_1252').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY)</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('add_4_1252').errors?.invalidMonth">
                                  Month is out of range</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('add_4_1252').errors?.invalidDateyear">
                                    year is out of range(1900-2099)</small>
                                    <small class="text-danger" *ngIf="TannerClinicFroms.get('add_4_1252').errors?.invalidDateRange">
                                      date is out of range</small>
                            </div>
                        </div>
                    </div>     
                </td>    
            </tr>  
            <tr> 
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Expiration Date:(mm/dd/yyyy)</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="input-group">
                                <input type="text" placeholder="Prev MLP 4 Expiration Date" ngbDatepicker #d041253="ngbDatepicker" id="add_4_1253" class="form-control" formControlName="add_4_1253" (blur)="clearInputIfInvalid('add_4_1253')"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d041253.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div>
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('add_4_1253').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY)</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('add_4_1253').errors?.invalidMonth">
                                  Month is out of range</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('add_4_1253').errors?.invalidDateyear">
                                    year is out of range(1900-2099)</small>
                                    <small class="text-danger" *ngIf="TannerClinicFroms.get('add_4_1253').errors?.invalidDateRange">
                                      date is out of range</small>
                            </div>
                        </div>
                    </div>     
                </td>    
            </tr>  
            
            <tr> 
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Limits per claim:</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-6">
                            <ng-select
                            [items]="preClaim"
                            bindLabel="name"
                            placeholder="Select Per Occurrence"
                            bindValue="id"
                            formControlName="add_4_1264"
                          >
                          </ng-select>
                        </div>
                        <div class="col-md-6">
                            <ng-select
                                [items]="aggregates"
                                bindLabel="name"
                                placeholder="Select Aggregate"
                                bindValue="id"
                                formControlName="add_4_1265"
                                >   
                            </ng-select>
                        </div>
                    </div>     
                </td>    
            </tr> 
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Current Hospital Privileges:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="418"
                                id="418" name="418" type="text" placeholder="Hospital Affiliation 1 Name"   data-id="managing_contact_name" >
                        </div> 
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Address:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-6">
                            <input class="form-control onboard"  formControlName="419"
                                id="419" name="419" type="text" placeholder="Hospital Affiliation 1 Address1"   data-id="managing_contact_name" >
                        </div> 
                        <div class="col-md-6">
                            <input class="form-control onboard"  formControlName="420"
                                id="420" name="420" type="text" placeholder="Hospital Affiliation 1 Address2"   data-id="managing_contact_name" >
                        </div> 
                    </div>
                </td>
            </tr>  
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">City, State Zip:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-4">
                            <input class="form-control onboard"  formControlName="421"
                                id="421" name="421" type="text" placeholder="Hospital Affiliation 1 City"   data-id="managing_contact_name" >
                        </div> 
                        <div class="col-md-4">
                            <ng-select
                                    [items]="states"
                                    bindLabel="name"
                                    placeholder="Enter State"
                                    bindValue="id"
                                    formControlName="423"
                                    
                                    >
                                </ng-select>
                        </div>
                        <div class="col-md-4">
                            <input class="form-control onboard"  formControlName="424"
                                id="424" name="424" type="text" placeholder="Hospital Affiliation 1 Zip"   data-id="managing_contact_name" >
                        </div> 
                    </div>
                </td>
            </tr>   
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Phone:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="1305" (keydown)="handleNumber($event,1305)" maxlength="12"
                                id="1305" name="1305" type="text" placeholder="Hospital Affiliation 1 Phone"   data-id="managing_contact_name" >
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('1305').errors?.pattern">Required pattern xxx-xxx-xxxx</small>
                        </div> 
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Fax number:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="2136"
                                id="2136" name="2136" type="text" placeholder="Hospital Affiliation 1 Fax "   data-id="managing_contact_name" >
                        </div> 
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Effective Date:(mm/dd/yyyy)</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="input-group">
                                <input type="text" placeholder="Hospital Affiliation 1 Initial Appointment " ngbDatepicker #d426="ngbDatepicker" id="426" class="form-control" formControlName="426" (blur)="clearInputIfInvalid('426')"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d426.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div>
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('426').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY)</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('426').errors?.invalidMonth">
                                  Month is out of range</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('426').errors?.invalidDateyear">
                                    year is out of range(1900-2099)</small>
                                    <small class="text-danger" *ngIf="TannerClinicFroms.get('426').errors?.invalidDateRange">
                                      date is out of range</small>
                            </div>
                        </div>
                    </div>     
                </td>    
            </tr>  
            <tr> 
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Expiration Date:(mm/dd/yyyy)</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="input-group">
                                <input type="text" placeholder="Hospital Affiliation 1 Next Appointment Date " ngbDatepicker #d2007="ngbDatepicker" id="2007" class="form-control" formControlName="2007" (blur)="clearInputIfInvalid('2007')"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d2007.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div>
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('2007').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY)</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('2007').errors?.invalidMonth">
                                  Month is out of range</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('2007').errors?.invalidDateyear">
                                    year is out of range(1900-2099)</small>
                                    <small class="text-danger" *ngIf="TannerClinicFroms.get('2007').errors?.invalidDateRange">
                                      date is out of range</small>
                            </div>
                        </div>
                    </div>     
                </td>    
            </tr>  
            
            <tr> 
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Status:</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-6">
                            <ng-select
                            [items]="staffStatus"
                            bindLabel="name"
                            placeholder="Select Staff Status"
                            bindValue="id"
                            formControlName="894"
                          >
                          </ng-select>
                        </div>
                    </div>     
                </td>    
            </tr>   
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Admitting Privileges:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="3183"
                                id="3183" name="3183" type="text" placeholder="Hospital Affiliation 1 Arrangements"   data-id="managing_contact_name" >
                        </div> 
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Current Hospital Privileges:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="427"
                                id="427" name="427" type="text" placeholder="Hospital Affiliation 2 Name"   data-id="managing_contact_name" >
                        </div> 
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Address:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-6">
                            <input class="form-control onboard"  formControlName="428"
                                id="428" name="428" type="text" placeholder="Hospital Affiliation 2 Address1"   data-id="managing_contact_name" >
                        </div> 
                        <div class="col-md-6">
                            <input class="form-control onboard"  formControlName="429"
                                id="429" name="429" type="text" placeholder="Hospital Affiliation 2 Address2"   data-id="managing_contact_name" >
                        </div> 
                    </div>
                </td>
            </tr>  
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">City, State Zip:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-4">
                            <input class="form-control onboard"  formControlName="430"
                                id="430" name="430" type="text" placeholder="Hospital Affiliation 2 City"   data-id="managing_contact_name" >
                        </div> 
                        <div class="col-md-4">
                            <ng-select
                                    [items]="states"
                                    bindLabel="name"
                                    placeholder="Enter State"
                                    bindValue="id"
                                    formControlName="432"
                                    
                                    >
                                </ng-select>
                        </div>
                        <div class="col-md-4">
                            <input class="form-control onboard"  formControlName="433"
                                id="433" name="433" type="text" placeholder="Hospital Affiliation 2 Zip"   data-id="managing_contact_name" >
                        </div> 
                    </div>
                </td>
            </tr>   
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Phone:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="1307" (keydown)="handleNumber($event,1307)" maxlength="12"
                                id="1307" name="1307" type="text" placeholder="Hospital Affiliation 2 Phone"   data-id="managing_contact_name" >
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('1307').errors?.pattern">Required pattern xxx-xxx-xxxx</small>
                        </div> 
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Fax number:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="2137"
                                id="2137" name="2137" type="text" placeholder="Hospital Affiliation 2 Fax "   data-id="managing_contact_name" >
                        </div> 
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Effective Date:(mm/dd/yyyy)</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="input-group">
                                <input type="text" placeholder="Hospital Affiliation 2 Initial Appointment " ngbDatepicker #d435="ngbDatepicker" id="435" class="form-control" formControlName="435" (blur)="clearInputIfInvalid('435')"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d435.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div>
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('435').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY)</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('435').errors?.invalidMonth">
                                  Month is out of range</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('435').errors?.invalidDateyear">
                                    year is out of range(1900-2099)</small>
                                    <small class="text-danger" *ngIf="TannerClinicFroms.get('435').errors?.invalidDateRange">
                                      date is out of range</small>
                            </div>
                        </div>
                    </div>     
                </td>    
            </tr>  
            <tr> 
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Expiration Date:(mm/dd/yyyy)</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="input-group">
                                <input type="text" placeholder="Hospital Affiliation 2 Next Appointment Date " ngbDatepicker #d2008="ngbDatepicker" id="2008" class="form-control" formControlName="2008" (blur)="clearInputIfInvalid('2008')"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d2008.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div>
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('2008').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY)</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('2008').errors?.invalidMonth">
                                  Month is out of range</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('2008').errors?.invalidDateyear">
                                    year is out of range(1900-2099)</small>
                                    <small class="text-danger" *ngIf="TannerClinicFroms.get('2008').errors?.invalidDateRange">
                                      date is out of range</small>
                            </div>
                        </div>
                    </div>     
                </td>    
            </tr>  
            <tr> 
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Status:</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-6">
                            <ng-select
                            [items]="staffStatus"
                            bindLabel="name"
                            placeholder="Select Staff Status"
                            bindValue="id"
                            formControlName="895"
                          >
                          </ng-select>
                        </div>
                    </div>     
                </td>    
            </tr>   
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Admitting Privileges:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="3184"
                                id="3184" name="3184" type="text" placeholder="Hospital Affiliation 2 Arrangements"   data-id="managing_contact_name" >
                        </div> 
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Current Hospital Privileges:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="436"
                                id="436" name="436" type="text" placeholder="Hospital Affiliation 3 Name"   data-id="managing_contact_name" >
                        </div> 
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Address:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-6">
                            <input class="form-control onboard"  formControlName="437"
                                id="437" name="437" type="text" placeholder="Hospital Affiliation 3 Address1"   data-id="managing_contact_name" >
                        </div> 
                        <div class="col-md-6">
                            <input class="form-control onboard"  formControlName="438"
                                id="438" name="438" type="text" placeholder="Hospital Affiliation 3 Address2"   data-id="managing_contact_name" >
                        </div> 
                    </div>
                </td>
            </tr>  
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">City, State Zip:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-4">
                            <input class="form-control onboard"  formControlName="439"
                                id="439" name="439" type="text" placeholder="Hospital Affiliation 3 City"   data-id="managing_contact_name" >
                        </div> 
                        <div class="col-md-4">
                            <ng-select
                                    [items]="states"
                                    bindLabel="name"
                                    placeholder="Enter State"
                                    bindValue="id"
                                    formControlName="441"
                                    
                                    >
                                </ng-select>
                        </div>
                        <div class="col-md-4">
                            <input class="form-control onboard"  formControlName="442"
                                id="442" name="442" type="text" placeholder="Hospital Affiliation 3 Zip"   data-id="managing_contact_name" >
                        </div> 
                    </div>
                </td>
            </tr>   
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Phone:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="1309" (keydown)="handleNumber($event,1309)" maxlength="12"
                                id="1309" name="1309" type="text" placeholder="Hospital Affiliation 3 Phone"   data-id="managing_contact_name" >
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('1309').errors?.pattern">Required pattern xxx-xxx-xxxx</small>
                        </div> 
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Fax number:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">   
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="2138"
                                id="2138" name="2138" type="text" placeholder="Hospital Affiliation 3 Fax "   data-id="managing_contact_name" >
                        </div> 
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Effective Date:(mm/dd/yyyy)</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="input-group">
                                <input type="text" placeholder="Hospital Affiliation 3 Initial Appointment " ngbDatepicker #d444="ngbDatepicker" id="444" class="form-control" formControlName="444" (blur)="clearInputIfInvalid('444')"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d444.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div>
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('444').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY)</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('444').errors?.invalidMonth">
                                  Month is out of range</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('444').errors?.invalidDateyear">
                                    year is out of range(1900-2099)</small>
                                    <small class="text-danger" *ngIf="TannerClinicFroms.get('444').errors?.invalidDateRange">
                                      date is out of range</small>
                            </div>
                        </div>
                    </div>     
                </td>    
            </tr>  
            <tr> 
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Expiration Date:(mm/dd/yyyy)</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="input-group">
                                <input type="text" placeholder="Hospital Affiliation 3 Next Appointment Date " ngbDatepicker #d2011="ngbDatepicker" id="2011" class="form-control" formControlName="2011" (blur)="clearInputIfInvalid('2011')"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d2011.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div>
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('2011').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY)</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('2011').errors?.invalidMonth">
                                  Month is out of range</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('2011').errors?.invalidDateyear">
                                    year is out of range(1900-2099)</small>
                                    <small class="text-danger" *ngIf="TannerClinicFroms.get('2011').errors?.invalidDateRange">
                                      date is out of range</small>
                            </div>
                        </div>
                    </div>     
                </td>    
            </tr>  
            <tr> 
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Status:</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-6">
                            <ng-select
                            [items]="staffStatus"
                            bindLabel="name"
                            placeholder="Select Staff Status"
                            bindValue="id"
                            formControlName="896"
                          >
                          </ng-select>
                        </div>
                    </div>     
                </td>    
            </tr>   
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Admitting Privileges:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="3185"
                                id="3185" name="3185" type="text" placeholder="Hospital Affiliation 3 Arrangements"   data-id="managing_contact_name" >
                        </div> 
                    </div>
                </td>
            </tr>
            
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Current Hospital Privileges:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="add_1_986"
                                id="add_1_986" name="add_1_986" type="text" placeholder="Add1 Hospital Affiliation  Name"   data-id="managing_contact_name" >
                        </div> 
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Address:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-6">
                            <input class="form-control onboard"  formControlName="add_1_987"
                                id="add_1_987" name="add_1_987" type="text" placeholder="Add1 Hospital Affiliation  Address1"   data-id="managing_contact_name" >
                        </div> 
                        <div class="col-md-6">
                            <input class="form-control onboard"  formControlName="add_1_988"
                                id="add_1_988" name="add_1_988" type="text" placeholder="Add1 Hospital Affiliation Address2"   data-id="managing_contact_name" >
                        </div> 
                    </div>
                </td>
            </tr>  
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">City, State Zip:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-4">
                            <input class="form-control onboard"  formControlName="add_1_989"
                                id="add_1_989" name="add_1_989" type="text" placeholder="Add1 Hospital Affiliation  City"   data-id="managing_contact_name" >
                        </div> 
                        <div class="col-md-4">
                            <ng-select
                                    [items]="states"
                                    bindLabel="name"
                                    placeholder="Enter State"
                                    bindValue="id"
                                    formControlName="add_1_991"
                                    
                                    >
                                </ng-select>
                        </div>
                        <div class="col-md-4">
                            <input class="form-control onboard"  formControlName="add_1_992"
                                id="add_1_992" name="add_1_992" type="text" placeholder="Add1 Affiliation  Zip"   data-id="managing_contact_name" >
                        </div> 
                    </div>
                </td>
            </tr>   
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Phone:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="add_1_1311" (keydown)="handleNumber($event,'add_1_1311')" maxlength="12"
                                id="add_1_1311" name="add_1_1311" type="text" placeholder="Add1 Affiliation  Phone"   data-id="managing_contact_name" >
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('add_1_1311').errors?.pattern">Required pattern xxx-xxx-xxxx</small>
                        </div> 
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Fax number:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">   
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="add_1_2139"
                                id="add_1_2139" name="add_1_2139" type="text" placeholder="Add1 Hospital Affiliation  Fax "   data-id="managing_contact_name" >
                        </div> 
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Effective Date:(mm/dd/yyyy)</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="input-group">
                                <input type="text" placeholder="Add1 Affiliation  Initial Appointment " ngbDatepicker #d0994="ngbDatepicker" id="add_1_994" class="form-control" formControlName="add_1_994" (blur)="clearInputIfInvalid('add_1_994')"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d0994.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div>
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('add_1_994').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY)</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('add_1_994').errors?.invalidMonth">
                                  Month is out of range</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('add_1_994').errors?.invalidDateyear">
                                    year is out of range(1900-2099)</small>
                                    <small class="text-danger" *ngIf="TannerClinicFroms.get('add_1_994').errors?.invalidDateRange">
                                      date is out of range</small>
                            </div>
                        </div>
                    </div>     
                </td>    
            </tr>  
            <tr> 
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Expiration Date:(mm/dd/yyyy)</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="input-group">
                                <input type="text" placeholder="Add1 Affiliation Next Appointment Date " ngbDatepicker #d02010="ngbDatepicker" id="add_1_2010" class="form-control" formControlName="add_1_2010"  (blur)="clearInputIfInvalid('add_1_2010')"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d02010.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div>
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('add_1_2010').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY)</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('add_1_2010').errors?.invalidMonth">
                                  Month is out of range</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('add_1_2010').errors?.invalidDateyear">
                                    year is out of range(1900-2099)</small>
                                    <small class="text-danger" *ngIf="TannerClinicFroms.get('add_1_2010').errors?.invalidDateRange">
                                      date is out of range</small>
                            </div>
                        </div>
                    </div>     
                </td>    
            </tr>  
            <tr> 
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Status:</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-6">
                            <ng-select
                            [items]="staffStatus"
                            bindLabel="name"
                            placeholder="Select Staff Status"
                            bindValue="id"
                            formControlName="add_1_995"
                          >
                          </ng-select>
                        </div>
                    </div>     
                </td>    
            </tr>   
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Admitting Privileges:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="add_1_3186"
                                id="add_1_3186" name="add_1_3186" type="text" placeholder="Additional Hospital Affiliation Arrangements "   data-id="managing_contact_name" >
                        </div> 
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Current Hospital Privileges:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="add_2_986"
                                id="add_2_986" name="add_2_986" type="text" placeholder="Add2 Hospital Affiliation  Name"   data-id="managing_contact_name" >
                        </div> 
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Address:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-6">
                            <input class="form-control onboard"  formControlName="add_2_987"
                                id="add_2_987" name="add_2_987" type="text" placeholder="Add2 Hospital Affiliation  Address1"   data-id="managing_contact_name" >
                        </div> 
                        <div class="col-md-6">
                            <input class="form-control onboard"  formControlName="add_2_988"
                                id="add_2_988" name="add_2_988" type="text" placeholder="Add2 Hospital Affiliation Address2"   data-id="managing_contact_name" >
                        </div> 
                    </div>
                </td>
            </tr>  
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">City, State Zip:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-4">
                            <input class="form-control onboard"  formControlName="add_2_989"
                                id="add_2_989" name="add_2_989" type="text" placeholder="Add2 Hospital Affiliation  City"   data-id="managing_contact_name" >
                        </div> 
                        <div class="col-md-4">
                            <ng-select
                                    [items]="states"
                                    bindLabel="name"
                                    placeholder="Enter State"
                                    bindValue="id"
                                    formControlName="add_2_991"
                                    
                                    >
                                </ng-select>
                        </div>
                        <div class="col-md-4">
                            <input class="form-control onboard"  formControlName="add_2_992"
                                id="add_2_992" name="add_2_992" type="text" placeholder="Add2 Affiliation  Zip"   data-id="managing_contact_name" >
                        </div> 
                    </div>
                </td>
            </tr>   
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Phone:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="add_2_1311" (keydown)="handleNumber($event,'add_2_1311')" maxlength="12"
                                id="add_2_1311" name="add_2_1311" type="text" placeholder="Add2 Affiliation  Phone"   data-id="managing_contact_name" >
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('add_2_1311').errors?.pattern">Required pattern xxx-xxx-xxxx</small>
                        </div> 
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Fax number:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">   
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="add_2_2139"
                                id="add_2_2139" name="add_2_2139" type="text" placeholder="Add2 Hospital Affiliation  Fax "   data-id="managing_contact_name" >
                        </div> 
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Effective Date:(mm/dd/yyyy)</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="input-group">
                                <input type="text" placeholder="Add2 Affiliation  Initial Appointment " ngbDatepicker #d1994="ngbDatepicker" id="add_2_994" class="form-control" formControlName="add_2_994" (blur)="clearInputIfInvalid('add_2_994')"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d1994.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div>
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('add_2_994').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY)</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('add_2_994').errors?.invalidMonth">
                                  Month is out of range</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('add_2_994').errors?.invalidDateyear">
                                    year is out of range(1900-2099)</small>
                                    <small class="text-danger" *ngIf="TannerClinicFroms.get('add_2_994').errors?.invalidDateRange">
                                      date is out of range</small>
                            </div>
                        </div>
                    </div>     
                </td>    
            </tr>  
            <tr> 
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Expiration Date:(mm/dd/yyyy)</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="input-group">
                                <input type="text" placeholder="Add2 Affiliation Next Appointment Date " ngbDatepicker #d12010="ngbDatepicker" id="add_2_2010" class="form-control" formControlName="add_2_2010" (blur)="clearInputIfInvalid('add_2_2010')"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d12010.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div>
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('add_2_2010').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY)</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('add_2_2010').errors?.invalidMonth">
                                  Month is out of range</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('add_2_2010').errors?.invalidDateyear">
                                    year is out of range(1900-2099)</small>
                                    <small class="text-danger" *ngIf="TannerClinicFroms.get('add_2_2010').errors?.invalidDateRange">
                                      date is out of range</small>
                            </div>
                        </div>
                    </div>     
                </td>    
            </tr>  
            <tr> 
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Status:</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-6">
                            <ng-select
                            [items]="staffStatus"
                            bindLabel="name"
                            placeholder="Select Staff Status"
                            bindValue="id"
                            formControlName="add_2_995"
                          >
                          </ng-select>
                        </div>
                    </div>     
                </td>    
            </tr>   
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Admitting Privileges:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="add_2_3186"
                                id="add_2_3186" name="add_2_3186" type="text" placeholder="Add2 Hospital Affiliation Arrangements "   data-id="managing_contact_name" >
                        </div> 
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Past Hospital Privileges: (last 10 years)</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="add_1_1183"
                                id="add_1_1183" name="add_1_1183" type="text" placeholder="Previous Hospital Affiliation  Name"   data-id="managing_contact_name" >
                        </div> 
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Address:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-6">
                            <input class="form-control onboard"  formControlName="add_1_1184"
                                id="add_1_1184" name="add_1_1184" type="text" placeholder="Previous Hospital Affiliation  Address1"   data-id="managing_contact_name" >
                        </div> 
                        <div class="col-md-6">
                            <input class="form-control onboard"  formControlName="add_1_1185"
                                id="add_1_1185" name="add_1_1185" type="text" placeholder="Add1 Hospital Affiliation Address2"   data-id="managing_contact_name" >
                        </div> 
                    </div>
                </td>
            </tr>  
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">City, State Zip:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-4">
                            <input class="form-control onboard"  formControlName="add_1_1186"
                                id="add_1_1186" name="add_1_1186" type="text" placeholder="Previous Hospital Affiliation  City"   data-id="managing_contact_name" >
                        </div> 
                        <div class="col-md-4">
                            <ng-select
                                    [items]="states"
                                    bindLabel="name"
                                    placeholder="Enter State"
                                    bindValue="id"
                                    formControlName="add_1_1188"
                                    
                                    >
                                </ng-select>
                        </div>
                        <div class="col-md-4">
                            <input class="form-control onboard"  formControlName="add_1_1189"
                                id="add_1_1189" name="add_1_1189" type="text" placeholder="Previous Affiliation  Zip"   data-id="managing_contact_name" >
                        </div> 
                    </div>
                </td>
            </tr>   
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Phone:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="add_1_1313" (keydown)="handleNumber($event,'add_1_1313')" maxlength="12"
                                id="add_1_1313" name="add_1_1313" type="text" placeholder="Previous Affiliation  Phone"   data-id="managing_contact_name" >
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('add_1_1313').errors?.pattern">Required pattern xxx-xxx-xxxx</small>
                        </div> 
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Fax number:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">   
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="add_1_2140"
                                id="add_1_2140" name="add_1_2140" type="text" placeholder="Previous Hospital Affiliation Fax "   data-id="managing_contact_name" >
                        </div> 
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Effective Date:(mm/dd/yyyy)</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="input-group">
                                <input type="text" placeholder="Previous Hospital Affiliation Initial Appointment " ngbDatepicker #d01191="ngbDatepicker" id="add_1_1191" class="form-control" formControlName="add_1_1191" (blur)="clearInputIfInvalid('add_1_1191')"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d01191.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div>
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('add_1_1191').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY)</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('add_1_1191').errors?.invalidMonth">
                                  Month is out of range</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('add_1_1191').errors?.invalidDateyear">
                                    year is out of range(1900-2099)</small>
                                    <small class="text-danger" *ngIf="TannerClinicFroms.get('add_1_1191').errors?.invalidDateRange">
                                      date is out of range</small>
                            </div>
                        </div>
                    </div>     
                </td>    
            </tr>  
            <tr> 
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Expiration Date:(mm/dd/yyyy)</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="input-group">
                                <input type="text" placeholder="Previous Hospital Affiliation End Date" ngbDatepicker #d02381="ngbDatepicker" id="add_1_2381" class="form-control" formControlName="add_1_2381" (blur)="clearInputIfInvalid('add_1_2381')"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d02381.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div>
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('add_1_2381').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY)</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('add_1_2381').errors?.invalidMonth">
                                  Month is out of range</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('add_1_2381').errors?.invalidDateyear">
                                    year is out of range(1900-2099)</small>
                                    <small class="text-danger" *ngIf="TannerClinicFroms.get('add_1_2381').errors?.invalidDateRange">
                                      date is out of range</small>
                            </div>
                        </div>
                    </div>     
                </td>    
            </tr>  
            <tr> 
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Status:</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-6">
                            <ng-select
                            [items]="staffStatus"
                            bindLabel="name"
                            placeholder="Select Staff Status"
                            bindValue="id"
                            formControlName="add_1_1192"
                          >
                          </ng-select>
                        </div>
                    </div>     
                </td>    
            </tr>   
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Admitting Privileges:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="add_1_2461"
                                id="add_1_2461" name="add_1_2461" type="text" placeholder="Previous Hospital Affiliation Notes"  data-id="managing_contact_name" >
                        </div> 
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Past Hospital Privileges: (last 10 years)</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="add_2_1183"
                                id="add_2_1183" name="add_2_1183" type="text" placeholder="Previous Hospital Affiliation  Name"   data-id="managing_contact_name" >
                        </div> 
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Address:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-6">
                            <input class="form-control onboard"  formControlName="add_2_1184"
                                id="add_2_1184" name="add_2_1184" type="text" placeholder="Previous2 Hospital Affiliation  Address1"   data-id="managing_contact_name" >
                        </div> 
                        <div class="col-md-6">
                            <input class="form-control onboard"  formControlName="add_2_1185"
                                id="add_2_1185" name="add_2_1185" type="text" placeholder="Previous2 Hospital Affiliation Address2"   data-id="managing_contact_name" >
                        </div> 
                    </div>
                </td>
            </tr>  
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">City, State Zip:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-4">
                            <input class="form-control onboard"  formControlName="add_2_1186"
                                id="add_2_1186" name="add_2_1186" type="text" placeholder="Previous 2 Hospital Affiliation  City"   data-id="managing_contact_name" >
                        </div> 
                        <div class="col-md-4">
                            <ng-select
                                    [items]="states"
                                    bindLabel="name"
                                    placeholder="Enter State"
                                    bindValue="id"
                                    formControlName="add_2_1188"
                                    
                                    >
                                </ng-select>
                        </div>
                        <div class="col-md-4">
                            <input class="form-control onboard"  formControlName="add_2_1189"
                                id="add_2_1189" name="add_2_1189" type="text" placeholder="Previous2 Affiliation  Zip"   data-id="managing_contact_name" >
                        </div> 
                    </div>
                </td>
            </tr>   
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Phone:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="add_2_1313" (keydown)="handleNumber($event,'add_2_1313')" maxlength="12"
                                id="add_2_1313" name="add_2_1313" type="text" placeholder="Previous2 Affiliation  Phone"   data-id="managing_contact_name" >
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('add_2_1313').errors?.pattern">Required pattern xxx-xxx-xxxx</small>
                        </div> 
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Fax number:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">   
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="add_2_2140"
                                id="add_2_2140" name="add_2_2140" type="text" placeholder="Previous2 Hospital Affiliation Fax "   data-id="managing_contact_name" >
                        </div> 
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Effective Date:(mm/dd/yyyy)</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="input-group">
                                <input type="text" placeholder="Previous2 Hospital Affiliation Initial Appointment " ngbDatepicker #d21191="ngbDatepicker" id="add_2_1191" class="form-control" formControlName="add_2_1191"  (blur)="clearInputIfInvalid('add_2_1191')"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d21191.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div>
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('add_2_1191').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY)</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('add_2_1191').errors?.invalidMonth">
                                  Month is out of range</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('add_2_1191').errors?.invalidDateyear">
                                    year is out of range(1900-2099)</small>
                                    <small class="text-danger" *ngIf="TannerClinicFroms.get('add_2_1191').errors?.invalidDateRange">
                                      date is out of range</small>
                            </div>
                        </div>
                    </div>     
                </td>    
            </tr>  
            <tr> 
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Expiration Date:(mm/dd/yyyy)</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="input-group">
                                <input type="text" placeholder="Previous Hospital Affiliation End Date" ngbDatepicker #d22381="ngbDatepicker" id="add_2_2381" class="form-control" formControlName="add_2_2381" (blur)="clearInputIfInvalid('add_2_2381')"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d22381.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div>
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('add_2_2381').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY)</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('add_2_2381').errors?.invalidMonth">
                                  Month is out of range</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('add_2_2381').errors?.invalidDateyear">
                                    year is out of range(1900-2099)</small>
                                    <small class="text-danger" *ngIf="TannerClinicFroms.get('add_2_2381').errors?.invalidDateRange">
                                      date is out of range</small>
                            </div>
                        </div>
                    </div>     
                </td>    
            </tr>  
            <tr> 
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Status:</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-6">
                            <ng-select
                            [items]="staffStatus"
                            bindLabel="name"
                            placeholder="Select Staff Status"
                            bindValue="id"
                            formControlName="add_2_1192"
                          >
                          </ng-select>
                        </div>
                    </div>     
                </td>    
            </tr>   
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Admitting Privileges:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="add_2_2461"
                                id="add_2_2461" name="add_2_2461" type="text" placeholder="Previous2 Hospital Affiliation Notes"  data-id="managing_contact_name" >
                        </div> 
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Past Hospital Privileges: (last 10 years)</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="add_3_1183"
                                id="add_3_1183" name="add_3_1183" type="text" placeholder="Previous3 Hospital Affiliation  Name"   data-id="managing_contact_name" >
                        </div> 
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Address:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-6">
                            <input class="form-control onboard"  formControlName="add_3_1184"
                                id="add_3_1184" name="add_3_1184" type="text" placeholder="Previous3 Hospital Affiliation  Address1"   data-id="managing_contact_name" >
                        </div> 
                        <div class="col-md-6">
                            <input class="form-control onboard"  formControlName="add_3_1185"
                                id="add_3_1185" name="add_3_1185" type="text" placeholder="Previous3 Hospital Affiliation Address2"   data-id="managing_contact_name" >
                        </div> 
                    </div>
                </td>
            </tr>  
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">City, State Zip:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-4">
                            <input class="form-control onboard"  formControlName="add_3_1186"
                                id="add_3_1186" name="add_3_1186" type="text" placeholder="Previous3 Hospital Affiliation  City"   data-id="managing_contact_name" >
                        </div> 
                        <div class="col-md-4">
                            <ng-select
                                    [items]="states"
                                    bindLabel="name"
                                    placeholder="Enter State"
                                    bindValue="id"
                                    formControlName="add_3_1188"
                                    
                                    >
                                </ng-select>
                        </div>
                        <div class="col-md-4">
                            <input class="form-control onboard"  formControlName="add_3_1189"
                                id="add_3_1189" name="add_3_1189" type="text" placeholder="Previous3 Affiliation  Zip"   data-id="managing_contact_name" >
                        </div> 
                    </div>
                </td>
            </tr>   
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Phone:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="add_3_1313" (keydown)="handleNumber($event,'add_3_1313')" maxlength="12"
                                id="add_3_1313" name="add_3_1313" type="text" placeholder="Previous3 Affiliation  Phone"   data-id="managing_contact_name" >
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('add_3_1313').errors?.pattern">Required pattern xxx-xxx-xxxx</small>
                        </div> 
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Fax number:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">   
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="add_3_2140"
                                id="add_3_2140" name="add_3_2140" type="text" placeholder="Previous3 Hospital Affiliation Fax "   data-id="managing_contact_name" >
                        </div> 
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Effective Date:(mm/dd/yyyy)</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="input-group">
                                <input type="text" placeholder="Previous3 Hospital Affiliation Initial Appointment " ngbDatepicker #d31191="ngbDatepicker" id="add_3_1191" class="form-control" formControlName="add_3_1191" (blur)="clearInputIfInvalid('add_3_1191')"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d31191.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div>
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('add_3_1191').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY)</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('add_3_1191').errors?.invalidMonth">
                                  Month is out of range</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('add_3_1191').errors?.invalidDateyear">
                                    year is out of range(1900-2099)</small>
                                    <small class="text-danger" *ngIf="TannerClinicFroms.get('add_3_1191').errors?.invalidDateRange">
                                      date is out of range</small>
                            </div>
                        </div>
                    </div>     
                </td>    
            </tr>  
            <tr> 
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Expiration Date:(mm/dd/yyyy)</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="input-group">
                                <input type="text" placeholder="Previous3 Hospital Affiliation End Date" ngbDatepicker #d32381="ngbDatepicker" id="add_3_2381" class="form-control" formControlName="add_3_2381" (blur)="clearInputIfInvalid('add_3_2381')"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d32381.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div>
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('add_3_2381').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY)</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('add_3_2381').errors?.invalidMonth">
                                  Month is out of range</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('add_3_2381').errors?.invalidDateyear">
                                    year is out of range(1900-2099)</small>
                                    <small class="text-danger" *ngIf="TannerClinicFroms.get('add_3_2381').errors?.invalidDateRange">
                                      date is out of range</small>
                            </div>
                        </div>
                    </div>     
                </td>    
            </tr>  
            <tr> 
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Status:</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-6">
                            <ng-select
                            [items]="staffStatus"
                            bindLabel="name"
                            placeholder="Select Staff Status"
                            bindValue="id"
                            formControlName="add_3_1192"
                          >
                          </ng-select>
                        </div>
                    </div>     
                </td>    
            </tr>   
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Admitting Privileges:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="add_3_2461"
                                id="add_3_2461" name="add_3_2461" type="text" placeholder="Previous3 Hospital Affiliation Notes"  data-id="managing_contact_name" >
                        </div> 
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Past Hospital Privileges: (last 10 years)</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="add_4_1183"
                                id="add_4_1183" name="add_4_1183" type="text" placeholder="Previous4 Hospital Affiliation  Name"   data-id="managing_contact_name" >
                        </div> 
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Address:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-6">
                            <input class="form-control onboard"  formControlName="add_4_1184"
                                id="add_4_1184" name="add_4_1184" type="text" placeholder="Previous4 Hospital Affiliation  Address1"   data-id="managing_contact_name" >
                        </div> 
                        <div class="col-md-6">
                            <input class="form-control onboard"  formControlName="add_4_1185"
                                id="add_4_1185" name="add_4_1185" type="text" placeholder="Previous4 Hospital Affiliation Address2"   data-id="managing_contact_name" >
                        </div> 
                    </div>
                </td>
            </tr>  
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">City, State Zip:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-4">
                            <input class="form-control onboard"  formControlName="add_4_1186"
                                id="add_4_1186" name="add_4_1186" type="text" placeholder="Previous4 Hospital Affiliation  City"   data-id="managing_contact_name" >
                        </div> 
                        <div class="col-md-4">
                            <ng-select
                                    [items]="states"
                                    bindLabel="name"
                                    placeholder="Enter State"
                                    bindValue="id"
                                    formControlName="add_4_1188"
                                    
                                    >
                                </ng-select>
                        </div>
                        <div class="col-md-4">
                            <input class="form-control onboard"  formControlName="add_4_1189"
                                id="add_4_1189" name="add_4_1189" type="text" placeholder="Previous4 Affiliation  Zip"   data-id="managing_contact_name" >
                        </div> 
                    </div>
                </td>
            </tr>   
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Phone:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="add_4_1313" (keydown)="handleNumber($event,'add_4_1313')" maxlength="12"
                                id="add_4_1313" name="add_4_1313" type="text" placeholder="Previous4 Affiliation  Phone"   data-id="managing_contact_name" >
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('add_4_1313').errors?.pattern">Required pattern xxx-xxx-xxxx</small>
                        </div> 
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Fax number:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">   
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="add_4_2140"
                                id="add_4_2140" name="add_4_2140" type="text" placeholder="Previous4 Hospital Affiliation Fax "   data-id="managing_contact_name" >
                        </div> 
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Effective Date:(mm/dd/yyyy)</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="input-group">
                                <input type="text" placeholder="Previous 4 Hospital Affiliation Initial Appointment " ngbDatepicker #d41191="ngbDatepicker" id="add_4_1191" class="form-control" formControlName="add_4_1191" (blur)="clearInputIfInvalid('add_4_1191')"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d41191.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div>
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('add_4_1191').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY)</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('add_4_1191').errors?.invalidMonth">
                                  Month is out of range</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('add_4_1191').errors?.invalidDateyear">
                                    year is out of range(1900-2099)</small>
                                    <small class="text-danger" *ngIf="TannerClinicFroms.get('add_4_1191').errors?.invalidDateRange">
                                      date is out of range</small>
                            </div>
                        </div>
                    </div>     
                </td>    
            </tr>  
            <tr> 
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Expiration Date:(mm/dd/yyyy)</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="input-group">
                                <input type="text" placeholder="Previous 4 Hospital  Affiliation End Date" ngbDatepicker #d42381="ngbDatepicker" id="add_4_2381" class="form-control" formControlName="add_4_2381" (blur)="clearInputIfInvalid('add_4_2381')"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d42381.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div>
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('add_4_2381').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY)</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('add_4_2381').errors?.invalidMonth">
                                  Month is out of range</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('add_4_2381').errors?.invalidDateyear">
                                    year is out of range(1900-2099)</small>
                                    <small class="text-danger" *ngIf="TannerClinicFroms.get('add_4_2381').errors?.invalidDateRange">
                                      date is out of range</small>
                            </div>
                        </div>
                    </div>     
                </td>    
            </tr>  
            <tr> 
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Status:</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-6">
                            <ng-select
                            [items]="staffStatus"
                            bindLabel="name"
                            placeholder="Select Staff Status"
                            bindValue="id"
                            formControlName="add_4_1192"
                          >
                          </ng-select>
                        </div>
                    </div>     
                </td>    
            </tr>   
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Admitting Privileges:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="add_4_2461"
                                id="add_4_2461" name="add_4_2461" type="text" placeholder="Previous 4 Hospital Affiliation Notes"  data-id="managing_contact_name" >
                        </div> 
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">ACLS Cert:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <ng-select [items]="mediCertifications" bindLabel="name"  placeholder="Enter Medical Certification " bindValue="id" formControlName="add_1_674"> </ng-select>

                        </div> 
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Cert Number:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="add_1_3270"
                                id="add_1_3270" name="add_1_3270" type="text" placeholder="Medical Certification Number"   data-id="managing_contact_name" >
                        </div> 
                    </div>
                </td>
            </tr>
            <tr> 
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Effective Date:(mm/dd/yyyy)</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="input-group">
                                <input type="text" placeholder="Medical Certification Issue Date" ngbDatepicker #d01677="ngbDatepicker" id="add_1_677" class="form-control" formControlName="add_1_677" (blur)="clearInputIfInvalid('add_1_677')"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d01677.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div>
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('add_1_677').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY)</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('add_1_677').errors?.invalidMonth">
                                  Month is out of range</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('add_1_677').errors?.invalidDateyear">
                                    year is out of range(1900-2099)</small>
                                    <small class="text-danger" *ngIf="TannerClinicFroms.get('add_1_677').errors?.invalidDateRange">
                                      date is out of range</small>
                            </div>
                        </div>
                    </div>     
                </td>    
            </tr>  
            <tr> 
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Expiration Date:(mm/dd/yyyy)</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="input-group">
                                <input type="text" placeholder="Medical Certification Expiration Date" ngbDatepicker #d01678="ngbDatepicker" id="add_1_678" class="form-control" formControlName="add_1_678" (blur)="clearInputIfInvalid('add_1_678')"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d01678.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div>
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('add_1_678').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY)</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('add_1_678').errors?.invalidMonth">
                                  Month is out of range</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('add_1_678').errors?.invalidDateyear">
                                    year is out of range(1900-2099)</small>
                                    <small class="text-danger" *ngIf="TannerClinicFroms.get('add_1_678').errors?.invalidDateRange">
                                      date is out of range</small>
                            </div>
                        </div>
                    </div>     
                </td>    
            </tr>  
            <tr> 
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Original effective Date: (mm/dd/yyyy)</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="input-group">
                                <input type="text" placeholder="mm/dd/yyyy" ngbDatepicker #d01779="ngbDatepicker" id="cmd_01779" class="form-control" formControlName="cmd_01779" (blur)="clearInputIfInvalid('cmd_01779')"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d01779.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div>
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('cmd_01779').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY)</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('cmd_01779').errors?.invalidMonth">
                                  Month is out of range</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('cmd_01779').errors?.invalidDateyear">
                                    year is out of range(1900-2099)</small>
                                    <small class="text-danger" *ngIf="TannerClinicFroms.get('add_4_678').errors?.invalidDateRange">
                                      date is out of range</small>
                            </div>
                        </div>
                    </div>     
                </td>    
            </tr> 
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">BLS Cert:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <ng-select [items]="mediCertifications" bindLabel="name"  placeholder="Enter Medical Certification " bindValue="id" formControlName="add_2_674"> </ng-select>

                        </div> 
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Cert Number:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="add_2_3270"
                                id="add_2_3270" name="add_2_3270" type="text" placeholder="Medical Certification Number"   data-id="managing_contact_name" >
                        </div> 
                    </div>
                </td>
            </tr>
            <tr> 
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Effective Date:(mm/dd/yyyy)</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="input-group">
                                <input type="text" placeholder="Medical Certification Issue Date" ngbDatepicker #d021677="ngbDatepicker" id="add_2_677" class="form-control" formControlName="add_2_677" (blur)="clearInputIfInvalid('add_2_677')"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d021677.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div>
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('add_2_677').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY)</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('add_2_677').errors?.invalidMonth">
                                  Month is out of range</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('add_2_677').errors?.invalidDateyear">
                                    year is out of range(1900-2099)</small>
                                    <small class="text-danger" *ngIf="TannerClinicFroms.get('add_2_677').errors?.invalidDateRange">
                                      date is out of range</small>
                            </div>
                        </div>
                    </div>     
                </td>    
            </tr>  
            <tr> 
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Expiration Date:(mm/dd/yyyy)</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="input-group">
                                <input type="text" placeholder="Medical Certification Expiration Date" ngbDatepicker #d021678="ngbDatepicker" id="add_2_678" class="form-control" formControlName="add_2_678" (blur)="clearInputIfInvalid('add_2_678')"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d021678.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div>
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('add_2_678').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY)</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('add_2_678').errors?.invalidMonth">
                                  Month is out of range</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('add_2_678').errors?.invalidDateyear">
                                    year is out of range(1900-2099)</small>
                                    <small class="text-danger" *ngIf="TannerClinicFroms.get('add_2_678').errors?.invalidDateRange">
                                      date is out of range</small>
                            </div>
                        </div>
                    </div>     
                </td>    
            </tr>  
            <tr> 
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Original effective Date: (mm/dd/yyyy)</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="input-group">
                                <input type="text" placeholder="mm/dd/yyyy" ngbDatepicker #d021779="ngbDatepicker" id="cmd_01790" class="form-control" formControlName="cmd_01790"   (blur)="clearInputIfInvalid('cmd_01790')"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d021779.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div>
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('cmd_01790').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY)</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('cmd_01790').errors?.invalidMonth">
                                  Month is out of range</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('cmd_01790').errors?.invalidDateyear">
                                    year is out of range(1900-2099)</small>
                                    <small class="text-danger" *ngIf="TannerClinicFroms.get('cmd_01790').errors?.invalidDateRange">
                                      date is out of range</small>
                            </div>
                        </div>
                    </div>     
                </td>    
            </tr> 
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">PALS Cert</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <ng-select [items]="mediCertifications" bindLabel="name"  placeholder="Enter Medical Certification " bindValue="id" formControlName="add_3_674"> </ng-select>

                        </div> 
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Cert Number:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="add_3_3270"
                                id="add_3_3270" name="add_3_3270" type="text" placeholder="Medical Certification Number"   data-id="managing_contact_name" >
                        </div> 
                    </div>
                </td>
            </tr>
            <tr> 
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Effective Date:(mm/dd/yyyy)</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="input-group">
                                <input type="text" placeholder="Medical Certification Issue Date" ngbDatepicker #d031677="ngbDatepicker" id="add_3_677" class="form-control" formControlName="add_3_677" (blur)="clearInputIfInvalid('add_3_677')"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d031677.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div>
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('add_3_677').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY)</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('add_3_677').errors?.invalidMonth">
                                  Month is out of range</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('add_3_677').errors?.invalidDateyear">
                                    year is out of range(1900-2099)</small>
                                    <small class="text-danger" *ngIf="TannerClinicFroms.get('add_3_677').errors?.invalidDateRange">
                                      date is out of range</small>
                            </div>
                        </div>
                    </div>     
                </td>    
            </tr>  
            <tr> 
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Expiration Date:(mm/dd/yyyy)</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="input-group">
                                <input type="text" placeholder="Medical Certification Expiration Date" ngbDatepicker #d031678="ngbDatepicker" id="add_3_678" class="form-control" formControlName="add_3_678" (blur)="clearInputIfInvalid('add_3_678')"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d031678.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div>
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('add_3_678').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY)</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('add_3_678').errors?.invalidMonth">
                                  Month is out of range</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('add_3_678').errors?.invalidDateyear">
                                    year is out of range(1900-2099)</small>
                                    <small class="text-danger" *ngIf="TannerClinicFroms.get('add_3_678').errors?.invalidDateRange">
                                      date is out of range</small>
                            </div>
                        </div>
                    </div>     
                </td>    
            </tr>  
            <tr> 
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Original effective Date: (mm/dd/yyyy)</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="input-group">
                                <input type="text" placeholder="mm/dd/yyyy" ngbDatepicker #d031779="ngbDatepicker" id="cmd_01791" class="form-control" formControlName="cmd_01791" (blur)="clearInputIfInvalid('cmd_01791')"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d031779.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div>
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('cmd_01791').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY)</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('cmd_01791').errors?.invalidMonth">
                                  Month is out of range</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('cmd_01791').errors?.invalidDateyear">
                                    year is out of range(1900-2099)</small>
                                    <small class="text-danger" *ngIf="TannerClinicFroms.get('cmd_01791').errors?.invalidDateRange">
                                      date is out of range</small>
                            </div>
                        </div>
                    </div>     
                </td>    
            </tr> 
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">CPR Cert</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <ng-select [items]="mediCertifications" bindLabel="name"  placeholder="Enter Medical Certification " bindValue="id" formControlName="add_4_674"> </ng-select>

                        </div> 
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Cert Number:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="add_4_3270"
                                id="add_4_3270" name="add_4_3270" type="text" placeholder="Medical Certification Number"   data-id="managing_contact_name" >
                        </div> 
                    </div>
                </td>
            </tr>
            <tr> 
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Effective Date:(mm/dd/yyyy)</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="input-group">
                                <input type="text" placeholder="Medical Certification Issue Date" ngbDatepicker #d041677="ngbDatepicker" id="add_4_677" class="form-control" formControlName="add_4_677" (blur)="clearInputIfInvalid('add_4_677')"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d041677.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div>
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('add_4_677').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY)</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('add_4_677').errors?.invalidMonth">
                                  Month is out of range</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('add_4_677').errors?.invalidDateyear">
                                    year is out of range(1900-2099)</small>
                                    <small class="text-danger" *ngIf="TannerClinicFroms.get('add_4_677').errors?.invalidDateRange">
                                      date is out of range</small>
                            </div>
                        </div>
                    </div>     
                </td>    
            </tr>  
            <tr> 
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Expiration Date:(mm/dd/yyyy)</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="input-group">
                                <input type="text" placeholder="Medical Certification Expiration Date" ngbDatepicker #d041678="ngbDatepicker" id="add_4_678" class="form-control" formControlName="add_4_678" (blur)="clearInputIfInvalid('add_4_678')"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d041678.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div>
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('add_4_678').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY)</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('add_4_678').errors?.invalidMonth">
                                  Month is out of range</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('add_4_678').errors?.invalidDateyear">
                                    year is out of range(1900-2099)</small>
                                    <small class="text-danger" *ngIf="TannerClinicFroms.get('add_4_678').errors?.invalidDateRange">
                                      date is out of range</small>
                            </div>
                        </div>
                    </div>     
                </td>    
            </tr>  
            <tr> 
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Original effective Date: (mm/dd/yyyy)</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="input-group">
                                <input type="text" placeholder="mm/dd/yyyy" ngbDatepicker #d041792="ngbDatepicker" id="cmd_01792" class="form-control" formControlName="cmd_01792" (blur)="clearInputIfInvalid('cmd_01792')"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d041792.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div>
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('cmd_01792').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY)</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('cmd_01792').errors?.invalidMonth">
                                  Month is out of range</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('cmd_01792').errors?.invalidDateyear">
                                    year is out of range(1900-2099)</small>
                                    <small class="text-danger" *ngIf="TannerClinicFroms.get('cmd_01792').errors?.invalidDateRange">
                                      date is out of range</small>
                            </div>
                        </div>
                    </div>     
                </td>    
            </tr> 
            <tr>
                <td colspan="12">
                     <b style="font-size:16px;">Education</b>
                </td>
            </tr>
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Undergraduate College or University:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="503"
                                id="503" name="503" type="text" placeholder="UG School"   data-id="managing_contact_name" >
                        </div> 
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Degree: (MD,DO)</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <ng-select 
                            [items]="degree"
                            bindLabel="name"
                            placeholder="Enter Degree"
                            bindValue="id"
                            formControlName="512"
                            >
                            </ng-select>
                        </div> 
                    </div>
                </td>
            </tr>  
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Address:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-6">
                            <input class="form-control onboard"  formControlName="504"
                            id="504" name="504" type="text" placeholder= "UG School Add1"   data-id="managing_contact_name" >
                       
                        </div>
                        <div class="col-md-6">
                            <input class="form-control onboard"  formControlName="505"
                                    id="505" name="505" type="text" placeholder= "UG School Add2"   data-id="managing_contact_name" >
                                
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">City, State, Zip:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-4">  
                            <input class="form-control onboard"  formControlName="506"
                                    id="506" name="506" type="text" placeholder= "UG School City"  data-id="managing_contact_name" >
                                
                        </div>  
                        <div class="col-md-4"> 
                            <ng-select 
                                [items]="states"
                                bindLabel="name"
                                placeholder="Enter State"
                                bindValue="id"
                                formControlName="508"
                                >
                            </ng-select> 
                        </div>  
                        <div class="col-md-4">  
                            <input class="form-control onboard"  formControlName="509"
                                    id="509" name="509" type="text" placeholder= "UG School Zip"   data-id="managing_contact_name" >
                                
                        </div>  
                    </div>
                </td>
            </tr>  
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Phone:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8"> 
                            <input class="form-control onboard"  formControlName="2345" (keydown)="handleNumber($event,2345)" maxlength="12"
                                    id="2345" name="2345" type="text" placeholder= "UG School Phone"   data-id="managing_contact_name" >
                                    <small class="text-danger" *ngIf="TannerClinicFroms.get('2345').errors?.pattern">Required pattern xxx-xxx-xxxx</small>
                                
                        </div>
                    </div>
                </td>
            </tr>  
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Fax:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8"> 
                            <input class="form-control onboard"  formControlName="2346"
                                    id="2346" name="2346" type="text" placeholder= "UG School Fax"   data-id="managing_contact_name" >
                                
                        </div>
                    </div>
                </td>
            </tr>  
            <tr> 
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Start Date:(mm/dd/yyyy)</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="input-group">
                                <input type="text" placeholder="mm/dd/yyyy" ngbDatepicker #d510="ngbDatepicker" id="510" class="form-control" formControlName="510" (blur)="clearInputIfInvalid('510')"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d510.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div>
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('510').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY)</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('510').errors?.invalidMonth">
                                  Month is out of range</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('510').errors?.invalidDateyear">
                                    year is out of range(1900-2099)</small>
                                    <small class="text-danger" *ngIf="TannerClinicFroms.get('510').errors?.invalidDateRange">
                                      date is out of range</small>
                            </div>
                        </div>
                    </div>     
                </td>    
            </tr>     
            <tr> 
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Date Completed:(mm/dd/yyyy)</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="input-group">
                                <input type="text" placeholder="mm/dd/yyyy" ngbDatepicker #d511="ngbDatepicker" id="510" class="form-control" formControlName="511" (blur)="clearInputIfInvalid('511')"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d511.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div>
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('511').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY)</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('511').errors?.invalidMonth">
                                  Month is out of range</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('511').errors?.invalidDateyear">
                                    year is out of range(1900-2099)</small>
                                    <small class="text-danger" *ngIf="TannerClinicFroms.get('511').errors?.invalidDateRange">
                                      date is out of range</small>
                            </div>
                        </div>
                    </div>     
                </td>    
            </tr>  
        
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">*Medical School:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="490" [class.is-invalid]="(TannerClinicFroms.controls['490'].touched && TannerClinicFroms.controls['490'].invalid) || (TannerClinicFroms.controls['490'].invalid && isSubmit)"
                                id="490" name="490" type="text" placeholder="Med School"   data-id="managing_contact_name" >
                                <div class="formLabel col-md-11"
                                *ngIf="TannerClinicFroms.controls['490'].touched && TannerClinicFroms.controls['490'].invalid">
                                <small class="text-danger" *ngIf="TannerClinicFroms.controls['490'].errors?.required">
                                    required
                                </small>
                            </div>
                        </div> 
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Degree: (MD,DO)</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <ng-select 
                            [items]="degree"
                            bindLabel="name"
                            placeholder="Enter Degree"
                            bindValue="id"
                            formControlName="500"
                            >
                            </ng-select>
                        </div> 
                    </div>
                </td>
            </tr>  
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Address:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-6">
                            <input class="form-control onboard"  formControlName="491"
                            id="491" name="491" type="text" placeholder= "Med School Add1"   data-id="managing_contact_name" >
                    
                        </div>
                        <div class="col-md-6">
                            <input class="form-control onboard"  formControlName="492"
                                    id="492" name="492" type="text" placeholder= "Med School Add2"   data-id="managing_contact_name" >
                                
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">City, State, Zip:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-4">  
                            <input class="form-control onboard"  formControlName="493"
                                    id="493" name="493" type="text" placeholder= "Med School City"  data-id="managing_contact_name" >
                                
                        </div>  
                        <div class="col-md-4"> 
                            <ng-select 
                                [items]="states"
                                bindLabel="name"
                                placeholder="Enter State"
                                bindValue="id"
                                formControlName="495"
                                >
                            </ng-select> 
                        </div>  
                        <div class="col-md-4">  
                            <input class="form-control onboard"  formControlName="496"
                                    id="406" name="496" type="text" placeholder= "Med School Zip"   data-id="managing_contact_name" >
                                
                        </div>  
                    </div>
                </td>
            </tr>  
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Phone:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8"> 
                            <input class="form-control onboard"  formControlName="2351" (keydown)="handleNumber($event,2351)" maxlength="12"
                                    id="2351" name="2351" type="text" placeholder= "Med School Phone"   data-id="managing_contact_name" >
                                    <small class="text-danger" *ngIf="TannerClinicFroms.get('2351').errors?.pattern">Required pattern xxx-xxx-xxxx</small>
                                
                        </div>
                    </div>
                </td>
            </tr>  
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Fax:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8"> 
                            <input class="form-control onboard"  formControlName="2352"
                                    id="2352" name="2352" type="text" placeholder= "Med School Fax"   data-id="managing_contact_name" >
                                
                        </div>
                    </div>
                </td>
            </tr>  
            <tr> 
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Start Date:(mm/dd/yyyy)</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="input-group">
                                <input type="text" placeholder="mm/dd/yyyy" ngbDatepicker #d497="ngbDatepicker" id="497" class="form-control" formControlName="497" (blur)="clearInputIfInvalid('497')"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d497.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div>
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('497').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY)</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('497').errors?.invalidMonth">
                                  Month is out of range</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('497').errors?.invalidDateyear">
                                    year is out of range(1900-2099)</small>
                                    <small class="text-danger" *ngIf="TannerClinicFroms.get('497').errors?.invalidDateRange">
                                      date is out of range</small>
                            </div>
                        </div>
                    </div>     
                </td>    
            </tr>     
            <tr> 
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Date Completed:(mm/dd/yyyy)</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="input-group">
                                <input type="text" placeholder="mm/dd/yyyy" ngbDatepicker #d499="ngbDatepicker" id="499" class="form-control" formControlName="499" (blur)="clearInputIfInvalid('499')"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d499.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div>
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('499').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY)</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('499').errors?.invalidMonth">
                                  Month is out of range</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('499').errors?.invalidDateyear">
                                    year is out of range(1900-2099)</small>
                                    <small class="text-danger" *ngIf="TannerClinicFroms.get('499').errors?.invalidDateRange">
                                      date is out of range</small>
                            </div>
                        </div>
                    </div>     
                </td>    
            </tr>   
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">ECFMG:</label> 
                    </div>                    
                </td>   
            </tr>  
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Number:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8"> 
                            <input class="form-control onboard"  formControlName="514"
                                    id="514" name="514" type="text" placeholder= "ECFMG Number"   data-id="managing_contact_name" >
                                
                        </div>
                    </div>
                </td>
            </tr>
            <tr> 
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Issued Date:(mm/dd/yyyy)</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="input-group">
                                <input type="text" placeholder="mm/dd/yyyy" ngbDatepicker #d515="ngbDatepicker" id="515" class="form-control" formControlName="515" (blur)="clearInputIfInvalid('515')"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d515.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div>
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('515').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY)</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('515').errors?.invalidMonth">
                                  Month is out of range</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('515').errors?.invalidDateyear">
                                    year is out of range(1900-2099)</small>
                                    <small class="text-danger" *ngIf="TannerClinicFroms.get('515').errors?.invalidDateRange">
                                      date is out of range</small>
                            </div>
                        </div>
                    </div>     
                </td>    
            </tr>    
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">*Internship:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="517" [class.is-invalid]="(TannerClinicFroms.controls['517'].touched && TannerClinicFroms.controls['517'].invalid) || (TannerClinicFroms.controls['517'].invalid && isSubmit)"
                                id="517" name="517" type="text" placeholder="Intern School"   data-id="managing_contact_name" >
                                <div class="formLabel col-md-11"
                                *ngIf="TannerClinicFroms.controls['517'].touched && TannerClinicFroms.controls['517'].invalid">
                                <small class="text-danger" *ngIf="TannerClinicFroms.controls['517'].errors?.required">
                                    required
                                </small>
                            </div>
                        </div> 
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Address:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-6">
                            <input class="form-control onboard"  formControlName="518"
                            id="518" name="518" type="text" placeholder= "Intern Director Add1"   data-id="managing_contact_name" >
                    
                        </div>
                        <div class="col-md-6">
                            <input class="form-control onboard"  formControlName="519"
                                    id="519" name="519" type="text" placeholder= "Intern Director Add2"   data-id="managing_contact_name" >
                                
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">City, State, Zip:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-4">  
                            <input class="form-control onboard"  formControlName="520"
                                    id="520" name="520" type="text" placeholder= "Intern Director City"  data-id="managing_contact_name" >
                                
                        </div>  
                        <div class="col-md-4"> 
                            <ng-select 
                                [items]="states"
                                bindLabel="name"
                                placeholder="Enter State"
                                bindValue="id"
                                formControlName="522"
                                >
                            </ng-select> 
                        </div>  
                        <div class="col-md-4">  
                            <input class="form-control onboard"  formControlName="523"
                                    id="523" name="523" type="text" placeholder= "Intern Director Zip"   data-id="managing_contact_name" >
                                
                        </div>  
                    </div>
                </td>
            </tr>  
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Phone:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8"> 
                            <input class="form-control onboard"  formControlName="cmd_01798" (keydown)="handleNumber($event,'cmd_01798')" maxlength="12"
                                    id="cmd_01798" name="cmd_01798" type="text" placeholder= "Intern Director Phone"   data-id="managing_contact_name" >
                                    <small class="text-danger" *ngIf="TannerClinicFroms.get('cmd_01798').errors?.pattern">Required pattern xxx-xxx-xxxx</small>
                                
                        </div>
                    </div>
                </td>
            </tr>  
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Fax:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8"> 
                            <input class="form-control onboard"  formControlName="cmd_01796"
                                    id="cmd_01796" name="cmd_01796" type="text" placeholder= "Intern Director Fax"   data-id="managing_contact_name" >
                                
                        </div>
                    </div>
                </td>
            </tr>  
            <tr> 
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Start Date:(mm/dd/yyyy)</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="input-group">
                                <input type="text" placeholder="mm/dd/yyyy" ngbDatepicker #d524="ngbDatepicker" id="524" class="form-control" formControlName="524" (blur)="clearInputIfInvalid('524')"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d524.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div>
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('524').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY)</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('524').errors?.invalidMonth">
                                  Month is out of range</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('524').errors?.invalidDateyear">
                                    year is out of range(1900-2099)</small>
                                    <small class="text-danger" *ngIf="TannerClinicFroms.get('524').errors?.invalidDateRange">
                                      date is out of range</small>
                            </div>
                        </div>
                    </div>     
                </td>    
            </tr>     
            <tr> 
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Date Completed:(mm/dd/yyyy)</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="input-group">
                                <input type="text" placeholder="mm/dd/yyyy" ngbDatepicker #d526="ngbDatepicker" id="526" class="form-control" formControlName="526" (blur)="clearInputIfInvalid('526')"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d526.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div>
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('526').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY)</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('526').errors?.invalidMonth">
                                  Month is out of range</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('526').errors?.invalidDateyear">
                                    year is out of range(1900-2099)</small>
                                    <small class="text-danger" *ngIf="TannerClinicFroms.get('526').errors?.invalidDateRange">
                                      date is out of range</small>
                            </div>
                        </div>
                    </div>     
                </td>    
            </tr>    
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Specialty:</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                        <div class="row">
                            <div class="col-md-8">
                                <ng-select [items]="specialty" bindLabel="name"
                                placeholder="Select Specialities" bindValue="id" formControlName="527">
                            </ng-select>
                            </div>
                        </div>        
                </td>
            </tr> 
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Directors Name:</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                        <div class="row">
                            <div class="col-md-8">
                                <input class="form-control onboard"  formControlName="528"
                                id="528" name="528" type="text" placeholder= "Intern Director"   data-id="managing_contact_name" >
                    
                            </div>
                        </div>
                </td>
            </tr>         
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Directors Phone:</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                        <div class="row">
                            <div class="col-md-8">
                                <input class="form-control onboard"  formControlName="1318" (keydown)="handleNumber($event,1318)" maxlength="12"
                                id="1318" name="1318" type="text" placeholder= "Intern Director Phone"   data-id="managing_contact_name" >
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('1318').errors?.pattern">Required pattern xxx-xxx-xxxx</small>
                    
                            </div>
                        </div>
                </td>
            </tr>  
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Directors Fax:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8"> 
                            <input class="form-control onboard"  formControlName="1319"
                                    id="1319" name="1319" type="text" placeholder= "Intern Director Fax"   data-id="managing_contact_name" >
                                
                        </div>
                    </div>
                </td>
            </tr>  
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Directors e-mail:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8"> 
                            <input class="form-control onboard"  formControlName="4579"
                                    id="4579" name="4579" type="text" placeholder= "Intern Director Email"   data-id="managing_contact_name" >
                                    <small class="text-danger" *ngIf="TannerClinicFroms.controls['4579'].errors?.pattern">
                                        Enter valid Email format</small> 
                                
                        </div>
                    </div>
                </td>
            </tr>   
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">*Residency:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="529" [class.is-invalid]="(TannerClinicFroms.controls['529'].touched && TannerClinicFroms.controls['529'].invalid) || (TannerClinicFroms.controls['529'].invalid && isSubmit)"
                                id="529" name="529" type="text" placeholder="Residency School"   data-id="managing_contact_name" >
                                <div class="formLabel col-md-11"
                                *ngIf="TannerClinicFroms.controls['529'].touched && TannerClinicFroms.controls['529'].invalid">
                                <small class="text-danger" *ngIf="TannerClinicFroms.controls['529'].errors?.required">
                                    required
                                </small>
                            </div>
                        </div> 
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Address:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-6">
                            <input class="form-control onboard"  formControlName="531"
                            id="531" name="531" type="text" placeholder= "Residency Director Add1"   data-id="managing_contact_name" >
                    
                        </div>
                        <div class="col-md-6">
                            <input class="form-control onboard"  formControlName="1498"
                                    id="1498" name="1498" type="text" placeholder= "Residency Director Add2"   data-id="managing_contact_name" >
                                
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">City, State, Zip:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-4">  
                            <input class="form-control onboard"  formControlName="533"
                                    id="533" name="533" type="text" placeholder= "Residency Director City"  data-id="managing_contact_name" >
                                
                        </div>  
                        <div class="col-md-4"> 
                            <ng-select 
                                [items]="states"
                                bindLabel="name"
                                placeholder="Enter State"
                                bindValue="id"
                                formControlName="535"
                                >
                            </ng-select> 
                        </div>  
                        <div class="col-md-4">  
                            <input class="form-control onboard"  formControlName="536"
                                    id="536" name="536" type="text" placeholder= "Residency Director Zip"   data-id="managing_contact_name" >
                                
                        </div>  
                    </div>
                </td>
            </tr>  
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Phone:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8"> 
                            <input class="form-control onboard"  formControlName="cmd_01800" (keydown)="handleNumber($event,'cmd_01800')" maxlength="12"
                                    id="cmd_01800" name="cmd_01800" type="text" placeholder= "Residency Director Phone"   data-id="managing_contact_name" >
                                    <small class="text-danger" *ngIf="TannerClinicFroms.get('cmd_01800').errors?.pattern">Required pattern xxx-xxx-xxxx</small>
                                
                        </div>
                    </div>
                </td>
            </tr>  
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Fax:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8"> 
                            <input class="form-control onboard"  formControlName="cmd_01795"
                                    id="cmd_01795" name="cmd_01795" type="text" placeholder= "Residency Director Fax"   data-id="managing_contact_name" >
                                
                        </div>
                    </div>
                </td>
            </tr>  
            <tr> 
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Start Date:(mm/dd/yyyy)</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="input-group">
                                <input type="text" placeholder="mm/dd/yyyy" ngbDatepicker #d537="ngbDatepicker" id="537" class="form-control" formControlName="537" (blur)="clearInputIfInvalid('537')"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d537.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div>
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('537').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY)</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('537').errors?.invalidMonth">
                                  Month is out of range</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('537').errors?.invalidDateyear">
                                    year is out of range(1900-2099)</small>
                                    <small class="text-danger" *ngIf="TannerClinicFroms.get('537').errors?.invalidDateRange">
                                      date is out of range</small>
                            </div>
                        </div>
                    </div>     
                </td>    
            </tr>     
            <tr> 
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Date Completed:(mm/dd/yyyy)</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="input-group">
                                <input type="text" placeholder="mm/dd/yyyy" ngbDatepicker #d539="ngbDatepicker" id="539" class="form-control" formControlName="539" (blur)="clearInputIfInvalid('539')"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d539.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div>
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('539').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY)</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('539').errors?.invalidMonth">
                                  Month is out of range</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('539').errors?.invalidDateyear">
                                    year is out of range(1900-2099)</small>
                                    <small class="text-danger" *ngIf="TannerClinicFroms.get('539').errors?.invalidDateRange">
                                      date is out of range</small>
                            </div>
                        </div>
                    </div>     
                </td>    
            </tr>    
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Specialty:</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                        <div class="row">
                            <div class="col-md-8">
                                <ng-select [items]="specialty" bindLabel="name"
                                placeholder="Select Specialities" bindValue="id" formControlName="530">
                            </ng-select>
                            </div>
                        </div>        
                </td>
            </tr> 
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Directors Name:</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                        <div class="row">
                            <div class="col-md-8">
                                <input class="form-control onboard"  formControlName="540"
                                id="540" name="540" type="text" placeholder= "Residency Director"   data-id="managing_contact_name" >
                    
                            </div>
                        </div>
                </td>
            </tr>         
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Directors Phone:</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                        <div class="row">
                            <div class="col-md-8">
                                <input class="form-control onboard"  formControlName="1316" (keydown)="handleNumber($event,1316)" maxlength="12"
                                id="1316" name="1316" type="text" placeholder= "Residency Director Phone"   data-id="managing_contact_name" >
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('1316').errors?.pattern">Required pattern xxx-xxx-xxxx</small>
                    
                            </div>
                        </div>
                </td>
            </tr>  
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Directors Fax:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8"> 
                            <input class="form-control onboard"  formControlName="1317"
                                    id="1317" name="1317" type="text" placeholder= "Residency Director Fax"   data-id="managing_contact_name" >
                                
                        </div>
                    </div>
                </td>
            </tr>  
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Directors e-mail:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8"> 
                            <input class="form-control onboard"  formControlName="4581" 
                                    id="4581" name="4581" type="text" placeholder= "Residency Director Email"   data-id="managing_contact_name" >
                                    <small class="text-danger" *ngIf="TannerClinicFroms.controls['4581'].errors?.pattern">
                                        Enter valid Email format</small> 
                                
                        </div>
                    </div>
                </td>
            </tr> 
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Fellowship:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="542" 
                                id="542" name="542" type="text" placeholder="Fellowship School"   data-id="managing_contact_name" >
                        </div> 
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Address:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-6">
                            <input class="form-control onboard"  formControlName="544"
                            id="544" name="544" type="text" placeholder= "Fellowship Director Add1"   data-id="managing_contact_name" >
                    
                        </div>
                        <div class="col-md-6">
                            <input class="form-control onboard"  formControlName="545"
                                    id="545" name="545" type="text" placeholder= "Fellowship Director Add2"   data-id="managing_contact_name" >
                                
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">City, State, Zip:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-4">  
                            <input class="form-control onboard"  formControlName="546"
                                    id="546" name="546" type="text" placeholder= "Fellowship Director City"  data-id="managing_contact_name" >
                                
                        </div>  
                        <div class="col-md-4"> 
                            <ng-select 
                                [items]="states"
                                bindLabel="name"
                                placeholder="Enter State"
                                bindValue="id"
                                formControlName="548"
                                >
                            </ng-select> 
                        </div>  
                        <div class="col-md-4">  
                            <input class="form-control onboard"  formControlName="549"
                                    id="549" name="549" type="text" placeholder= "Fellowship Director Zip"   data-id="managing_contact_name" >
                                
                        </div>  
                    </div>
                </td>
            </tr>  
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Phone:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8"> 
                            <input class="form-control onboard"  formControlName="cmd_01801" (keydown)="handleNumber($event,'cmd_01801')" maxlength="12"
                                    id="cmd_01801" name="cmd_01801" type="text" placeholder= "Fellowship Director Phone"   data-id="managing_contact_name" >
                                    <small class="text-danger" *ngIf="TannerClinicFroms.get('cmd_01801').errors?.pattern">Required pattern xxx-xxx-xxxx</small>
                                
                        </div>
                    </div>
                </td>
            </tr>  
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Fax:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8"> 
                            <input class="form-control onboard"  formControlName="cmd_1797"
                                    id="cmd_1797" name="cmd_1797" type="text" placeholder= "Fellowship Director Fax"   data-id="managing_contact_name" >
                                
                        </div>
                    </div>
                </td>
            </tr>  
            <tr> 
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Start Date:(mm/dd/yyyy)</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="input-group">
                                <input type="text" placeholder="mm/dd/yyyy" ngbDatepicker #d550="ngbDatepicker" id="550" class="form-control" formControlName="550" (blur)="clearInputIfInvalid('550')"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d550.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div>
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('550').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY)</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('550').errors?.invalidMonth">
                                  Month is out of range</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('550').errors?.invalidDateyear">
                                    year is out of range(1900-2099)</small>
                                    <small class="text-danger" *ngIf="TannerClinicFroms.get('550').errors?.invalidDateRange">
                                      date is out of range</small>
                            </div>
                        </div>
                    </div>     
                </td>    
            </tr>     
            <tr> 
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Date Completed:(mm/dd/yyyy)</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="input-group">
                                <input type="text" placeholder="mm/dd/yyyy" ngbDatepicker #d552="ngbDatepicker" id="552" class="form-control" formControlName="552" (blur)="clearInputIfInvalid('552')"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d552.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div>
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('552').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY)</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('552').errors?.invalidMonth">
                                  Month is out of range</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('552').errors?.invalidDateyear">
                                    year is out of range(1900-2099)</small>
                                    <small class="text-danger" *ngIf="TannerClinicFroms.get('552').errors?.invalidDateRange">
                                      date is out of range</small>
                            </div>
                        </div>
                    </div>     
                </td>    
            </tr>    
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Specialty:</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                        <div class="row">
                            <div class="col-md-8">
                                <ng-select [items]="specialty" bindLabel="name"
                                placeholder="Select Specialities" bindValue="id" formControlName="543">
                            </ng-select>
                            </div>
                        </div>        
                </td>
            </tr> 
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Directors Name:</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                        <div class="row">
                            <div class="col-md-8">
                                <input class="form-control onboard"  formControlName="553"
                                id="553" name="553" type="text" placeholder= "Fellowship Director"   data-id="managing_contact_name" >
                    
                            </div>
                        </div>
                </td>
            </tr>         
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Directors Phone:</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                        <div class="row">
                            <div class="col-md-8">
                                <input class="form-control onboard"  formControlName="1322" (keydown)="handleNumber($event,1322)" maxlength="12"
                                id="1322" name="1322" type="text" placeholder= "Fellowship Director Phone"   data-id="managing_contact_name" >
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('1322').errors?.pattern">Required pattern xxx-xxx-xxxx</small>
                            </div>
                        </div>
                </td>
            </tr>  
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Directors Fax:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8"> 
                            <input class="form-control onboard"  formControlName="1323"
                                    id="1323" name="1323" type="text" placeholder= "Fellowship Director Fax"   data-id="managing_contact_name" >
                                
                        </div>
                    </div>
                </td>
            </tr>  
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Directors e-mail:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8"> 
                            <input class="form-control onboard"  formControlName="4583"
                                    id="4583" name="4583" type="text" placeholder= "Fellowship Director Email"   data-id="managing_contact_name" >
                                    <small class="text-danger" *ngIf="TannerClinicFroms.controls['4583'].errors?.pattern">
                                        Enter valid Email format</small> 
                                
                        </div>
                    </div>
                </td>
            </tr> 
            <tr>
                <td colspan="12">
                    <b style="font-size:16px;">Military History </b>
                </td>
            </tr>    
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">*Currently on active or reserve Military Duty? </label> 
                    </div> 
                </td>
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-12">
                            <label class="checkbox-inline">
                                <input type="radio" id="cmd_01780" name="cmd_01780" [class.is-invalid]="(TannerClinicFroms.controls['cmd_01780'].touched && TannerClinicFroms.controls['cmd_01780'].invalid) || (TannerClinicFroms.controls['cmd_01780'].invalid && isSubmit)" formControlName="cmd_01780" value="1">Yes</label>
                                <label class="checkbox-inline">
                                <input type="radio" id="cmd_01780" name="cmd_01780" [class.is-invalid]="(TannerClinicFroms.controls['cmd_01780'].touched && TannerClinicFroms.controls['cmd_01780'].invalid) || (TannerClinicFroms.controls['cmd_01780'].invalid && isSubmit)" formControlName="cmd_01780" value="0">No  </label>
                                <div class="formLabel col-md-11"
                                  *ngIf="TannerClinicFroms.controls['cmd_01780'].touched && TannerClinicFroms.controls['cmd_01780'].invalid">
                                  <small class="text-danger" *ngIf="TannerClinicFroms.controls['cmd_01780'].errors?.required">
                                      required
                                  </small>
                                </div>
                                <span *ngIf="(TannerClinicFroms.controls['cmd_01780'].touched && TannerClinicFroms.controls['cmd_01780'].errors) || (TannerClinicFroms.controls['cmd_01780'].errors && isSubmit)">
                                  <i class="fas fa-times ml-2 text-danger"></i>
                                </span>
                        </div>
                    </div>
                </td>
            </tr>  
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Branch of Service:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8"> 
                            <input class="form-control onboard"  formControlName="1141"
                                    id="1141" name="1141" type="text" placeholder= "Branch"   data-id="managing_contact_name" >
                                
                        </div>
                    </div>
                </td>
            </tr> 
            <tr> 
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Dates of Service:(mm/dd/yyyy - mm/dd/yyyy)</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="input-group">
                                <input type="text" placeholder="Active Duty Effective Date" ngbDatepicker #d1144="ngbDatepicker" id="1144" class="form-control" formControlName="1144"  (blur)="clearInputIfInvalid('1144')"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d1144.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div>
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('1144').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY)</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('1144').errors?.invalidMonth">
                                  Month is out of range</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('1144').errors?.invalidDateyear">
                                    year is out of range(1900-2099)</small>
                                    <small class="text-danger" *ngIf="TannerClinicFroms.get('1144').errors?.invalidDateRange">
                                      date is out of range</small>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="input-group">
                                <input type="text" placeholder="Active Duty Discharge Date" ngbDatepicker #d1145="ngbDatepicker" id="1145" class="form-control" formControlName="1145" (blur)="clearInputIfInvalid('1145')"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d1145.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div>
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('1145').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY)</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('1145').errors?.invalidMonth">
                                  Month is out of range</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('1145').errors?.invalidDateyear">
                                    year is out of range(1900-2099)</small>
                                    <small class="text-danger" *ngIf="TannerClinicFroms.get('1145').errors?.invalidDateRange">
                                      date is out of range</small>
                            </div>
                        </div>
                    </div>     
                </td>    
            </tr>   
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Last Location Street:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-6">
                            <input class="form-control onboard"  formControlName="2112"
                            id="2112" name="2112" type="text" placeholder= "Location Add1"   data-id="managing_contact_name" >
                    
                        </div>
                        <div class="col-md-6">
                            <input class="form-control onboard"  formControlName="2113"
                                    id="2113" name="2113" type="text" placeholder= "Location Add2"   data-id="managing_contact_name" >
                                
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">City, State, Zip:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-4">  
                            <input class="form-control onboard"  formControlName="2114"
                                    id="2114" name="2114" type="text" placeholder= "Location City"  data-id="managing_contact_name" >
                                
                        </div>  
                        <div class="col-md-4"> 
                            <ng-select 
                                [items]="states"
                                bindLabel="name"
                                placeholder="Enter State"
                                bindValue="id"
                                formControlName="2116"
                                >
                            </ng-select> 
                        </div>  
                        <div class="col-md-4">  
                            <input class="form-control onboard"  formControlName="2117"
                                    id="2117" name="2117" type="text" placeholder= "Location Zip"   data-id="managing_contact_name" >
                                
                        </div>  
                    </div>
                </td>
            </tr> 
            <tr>
                <td colspan="12">
                    <b style="font-size:16px;">Work History </b>
                </td>
            </tr> 
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Current Company:</label> 
                    </div>   
                </td>
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="445"
                            id="445" name="445" type="text" placeholder= "Work History  1 Location"   data-id="managing_contact_name" >
                    
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Address:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-6">
                            <input class="form-control onboard"  formControlName="446"
                            id="446" name="446" type="text" placeholder= "Work History 1 Add1"   data-id="managing_contact_name" >
                    
                        </div>
                        <div class="col-md-6">
                            <input class="form-control onboard"  formControlName="447"
                                    id="447" name="447" type="text" placeholder= "Work History 1 Add2"   data-id="managing_contact_name" >
                                
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">City, State, Zip:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-4">  
                            <input class="form-control onboard"  formControlName="448"
                                    id="448" name="448" type="text" placeholder= "Work History 1 City"  data-id="managing_contact_name" >
                                
                        </div>  
                        <div class="col-md-4"> 
                            <ng-select 
                                [items]="states"
                                bindLabel="name"
                                placeholder="Enter State"
                                bindValue="id"
                                formControlName="450"
                                >
                            </ng-select> 
                        </div>  
                        <div class="col-md-4">  
                            <input class="form-control onboard"  formControlName="451"
                                    id="451" name="451" type="text" placeholder= "Work History 1 Zip"   data-id="managing_contact_name" >
                                
                        </div>  
                    </div>
                </td>
            </tr>   
            <tr> 
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Start Date:(mm/dd/yyyy)</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="input-group">
                                <input type="text" placeholder="Work History  1 Start Date" ngbDatepicker #d454="ngbDatepicker" id="454" class="form-control" formControlName="454"   (blur)="clearInputIfInvalid('454')"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d454.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div>
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('454').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY)</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('454').errors?.invalidMonth">
                                  Month is out of range</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('454').errors?.invalidDateyear">
                                    year is out of range(1900-2099)</small>
                                    <small class="text-danger" *ngIf="TannerClinicFroms.get('454').errors?.invalidDateRange">
                                      date is out of range</small>
                            </div>
                        </div>
                    </div>     
                </td>    
            </tr>     
            <tr> 
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Completed Date:(mm/dd/yyyy)</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="input-group">
                                <input type="text" placeholder="Work History  1 End Date" ngbDatepicker #d455="ngbDatepicker" id="455" class="form-control" formControlName="455"  (blur)="clearInputIfInvalid('455')"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d455.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div>
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('455').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY)</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('455').errors?.invalidMonth">
                                  Month is out of range</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('455').errors?.invalidDateyear">
                                    year is out of range(1900-2099)</small>
                                    <small class="text-danger" *ngIf="TannerClinicFroms.get('455').errors?.invalidDateRange">
                                      date is out of range</small>
                            </div>
                        </div>
                    </div>     
                </td>    
            </tr>
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Phone:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="453" (keydown)="handleNumber($event,453)" maxlength="12"
                                id="453" name="453" type="text" placeholder="Work History 1 Phone"   data-id="managing_contact_name" >
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('453').errors?.pattern">Required pattern xxx-xxx-xxxx</small>
                                
                        </div> 
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Fax:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="888"
                                id="888" name="888" type="text" placeholder="Work History 1 Fax"   data-id="managing_contact_name" >
                        </div> 
                    </div>
                </td>
            </tr>     
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Past Company:</label> 
                    </div>   
                </td>
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="456"
                            id="456" name="456" type="text" placeholder= "Work History 2 Location"   data-id="managing_contact_name" >
                    
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Address:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-6">
                            <input class="form-control onboard"  formControlName="457"
                            id="457" name="457" type="text" placeholder= "Work History 2 Add1"   data-id="managing_contact_name" >
                    
                        </div>
                        <div class="col-md-6">
                            <input class="form-control onboard"  formControlName="458"
                                    id="458" name="458" type="text" placeholder= "Work History 2 Add2"   data-id="managing_contact_name" >
                                
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">City, State, Zip:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-4">  
                            <input class="form-control onboard"  formControlName="459"
                                    id="459" name="459" type="text" placeholder= "Work History 2 City"  data-id="managing_contact_name" >
                                
                        </div>  
                        <div class="col-md-4"> 
                            <ng-select 
                                [items]="states"
                                bindLabel="name"
                                placeholder="Enter State"
                                bindValue="id"
                                formControlName="461"
                                >
                            </ng-select> 
                        </div>  
                        <div class="col-md-4">  
                            <input class="form-control onboard"  formControlName="462"
                                    id="462" name="462" type="text" placeholder= "Work History 2 Zip"   data-id="managing_contact_name" >
                                
                        </div>  
                    </div>
                </td>
            </tr>   
            <tr> 
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Start Date:(mm/dd/yyyy)</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="input-group">
                                <input type="text" placeholder="Work History  2 Start Date" ngbDatepicker #d465="ngbDatepicker" id="465" class="form-control" formControlName="465" (blur)="clearInputIfInvalid('465')"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d465.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div>
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('465').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY)</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('465').errors?.invalidMonth">
                                  Month is out of range</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('465').errors?.invalidDateyear">
                                    year is out of range(1900-2099)</small>
                                    <small class="text-danger" *ngIf="TannerClinicFroms.get('465').errors?.invalidDateRange">
                                      date is out of range</small>
                            </div>
                        </div>
                    </div>     
                </td>    
            </tr>     
            <tr> 
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Completed Date:(mm/dd/yyyy)</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="input-group">
                                <input type="text" placeholder="Work History  2 End Date" ngbDatepicker #d466="ngbDatepicker" id="466" class="form-control" formControlName="466" (blur)="clearInputIfInvalid('466')"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d466.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div>
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('466').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY)</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('466').errors?.invalidMonth">
                                  Month is out of range</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('466').errors?.invalidDateyear">
                                    year is out of range(1900-2099)</small>
                                    <small class="text-danger" *ngIf="TannerClinicFroms.get('466').errors?.invalidDateRange">
                                      date is out of range</small>
                            </div>
                        </div>
                    </div>     
                </td>    
            </tr>
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Phone:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="464" (keydown)="handleNumber($event,464)" maxlength="12"
                                id="464" name="464" type="text" placeholder="Work History 2 Phone"   data-id="managing_contact_name" >
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('464').errors?.pattern">Required pattern xxx-xxx-xxxx</small>
                        </div> 
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Fax:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="890"
                                id="890" name="890" type="text" placeholder="Work History 2 Fax"   data-id="managing_contact_name" >
                        </div> 
                    </div>
                </td>
            </tr>  
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Past Company:</label> 
                    </div>   
                </td>
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="467"
                            id="467" name="467" type="text" placeholder= "Work History  3 Location"   data-id="managing_contact_name" >
                    
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Address:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-6">
                            <input class="form-control onboard"  formControlName="468"
                            id="468" name="468" type="text" placeholder= "Work History 3 Add1"   data-id="managing_contact_name" >
                    
                        </div>
                        <div class="col-md-6">
                            <input class="form-control onboard"  formControlName="469"
                                    id="469" name="469" type="text" placeholder= "Work History 3 Add2"   data-id="managing_contact_name" >
                                
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">City, State, Zip:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-4">  
                            <input class="form-control onboard"  formControlName="470"
                                    id="470" name="470" type="text" placeholder= "Work History 3 City"  data-id="managing_contact_name" >
                                
                        </div>  
                        <div class="col-md-4"> 
                            <ng-select 
                                [items]="states"
                                bindLabel="name"
                                placeholder="Enter State"
                                bindValue="id"
                                formControlName="472"
                                >
                            </ng-select> 
                        </div>  
                        <div class="col-md-4">  
                            <input class="form-control onboard"  formControlName="473"
                                    id="473" name="473" type="text" placeholder= "Work History 3 Zip"   data-id="managing_contact_name" >
                                
                        </div>  
                    </div>
                </td>
            </tr>   
            <tr> 
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Start Date:(mm/dd/yyyy)</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="input-group">
                                <input type="text" placeholder="Work History 3 Start Date" ngbDatepicker #d476="ngbDatepicker" id="476" class="form-control" formControlName="476" (blur)="clearInputIfInvalid('476')"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d476.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div>
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('476').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY)</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('476').errors?.invalidMonth">
                                  Month is out of range</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('476').errors?.invalidDateyear">
                                    year is out of range(1900-2099)</small>
                                    <small class="text-danger" *ngIf="TannerClinicFroms.get('476').errors?.invalidDateRange">
                                      date is out of range</small>
                            </div>
                        </div>
                    </div>     
                </td>    
            </tr>     
            <tr> 
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Completed Date:(mm/dd/yyyy)</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="input-group">
                                <input type="text" placeholder="Work History 3 End Date" ngbDatepicker #d477="ngbDatepicker" id="477" class="form-control" formControlName="477"  (blur)="clearInputIfInvalid('477')"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d477.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div>
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('477').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY)</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('477').errors?.invalidMonth">
                                  Month is out of range</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('477').errors?.invalidDateyear">
                                    year is out of range(1900-2099)</small>
                                    <small class="text-danger" *ngIf="TannerClinicFroms.get('477').errors?.invalidDateRange">
                                      date is out of range</small>
                            </div>
                        </div>
                    </div>     
                </td>    
            </tr>
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Phone:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="475" (keydown)="handleNumber($event,475)" maxlength="12"
                                id="475" name="475" type="text" placeholder="Work History 3 Phone"   data-id="managing_contact_name" >
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('475').errors?.pattern">Required pattern xxx-xxx-xxxx</small>
                        </div> 
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Fax:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="892"
                                id="892" name="892" type="text" placeholder="Work History 3 Fax"   data-id="managing_contact_name" >
                        </div> 
                    </div>
                </td>
            </tr>  
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Past Company:</label> 
                    </div>   
                </td>
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="1935"
                            id="1935" name="1935" type="text" placeholder= "Work History  4 Location"   data-id="managing_contact_name" >
                    
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Address:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-6">
                            <input class="form-control onboard"  formControlName="1936"
                            id="1936" name="1936" type="text" placeholder= "Work History 4 Add1"   data-id="managing_contact_name" >
                    
                        </div>
                        <div class="col-md-6">
                            <input class="form-control onboard"  formControlName="1937"
                                    id="1937" name="1937" type="text" placeholder= "Work History 4 Add2"   data-id="managing_contact_name" >
                                
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">City, State, Zip:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-4">  
                            <input class="form-control onboard"  formControlName="1938"
                                    id="1938" name="1938" type="text" placeholder= "Work History 4 City"  data-id="managing_contact_name" >
                                
                        </div>  
                        <div class="col-md-4"> 
                            <ng-select 
                                [items]="states"
                                bindLabel="name"
                                placeholder="Enter State"
                                bindValue="id"
                                formControlName="1940"
                                >
                            </ng-select> 
                        </div>  
                        <div class="col-md-4">  
                            <input class="form-control onboard"  formControlName="1941"
                                    id="1941" name="1941" type="text" placeholder= "Work History 4 Zip"   data-id="managing_contact_name" >
                                
                        </div>  
                    </div>
                </td>
            </tr>   
            <tr> 
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Start Date:(mm/dd/yyyy)</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="input-group">
                                <input type="text" placeholder="Work History 4 Start Date" ngbDatepicker #d1947="ngbDatepicker" id="1947" class="form-control" formControlName="1947"  (blur)="clearInputIfInvalid('1947')"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d1947.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div>
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('1947').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY)</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('1947').errors?.invalidMonth">
                                  Month is out of range</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('1947').errors?.invalidDateyear">
                                    year is out of range(1900-2099)</small>
                                    <small class="text-danger" *ngIf="TannerClinicFroms.get('1947').errors?.invalidDateRange">
                                      date is out of range</small>
                            </div>
                        </div>
                    </div>     
                </td>    
            </tr>     
            <tr> 
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Completed Date:(mm/dd/yyyy)</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="input-group">
                                <input type="text" placeholder="Work History 4 End Date" ngbDatepicker #d1948="ngbDatepicker" id="1948" class="form-control" formControlName="1948" (blur)="clearInputIfInvalid('1948')"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d1948.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div>
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('1948').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY)</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('1948').errors?.invalidMonth">
                                  Month is out of range</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('1948').errors?.invalidDateyear">
                                    year is out of range(1900-2099)</small>
                                    <small class="text-danger" *ngIf="TannerClinicFroms.get('1948').errors?.invalidDateRange">
                                      date is out of range</small>
                            </div>
                        </div>
                    </div>     
                </td>    
            </tr>
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Phone:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="1944" (keydown)="handleNumber($event,1944)" maxlength="12"
                                id="1944" name="1944" type="text" placeholder="Work History 4 Phone"   data-id="managing_contact_name" >
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('1944').errors?.pattern">Required pattern xxx-xxx-xxxx</small>
                        </div> 
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Fax:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="1945"
                                id="1945" name="1945" type="text" placeholder="Work History 4 Fax"   data-id="managing_contact_name" >
                        </div> 
                    </div>
                </td>
            </tr>  
             
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Past Company:</label> 
                    </div>   
                </td>
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="add_1_1954"
                            id="add_1_1954" name="add_1_1954" type="text" placeholder= "Add1 Work History Location"   data-id="managing_contact_name" >
                    
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Address:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-6">
                            <input class="form-control onboard"  formControlName="add_1_1955"
                            id="add_1_1955" name="add_1_1955" type="text" placeholder= "Add1 Work History Add1"   data-id="managing_contact_name" >
                    
                        </div>
                        <div class="col-md-6">
                            <input class="form-control onboard"  formControlName="add_1_1956"
                                    id="add_1_1956" name="add_1_1956" type="text" placeholder= "Add1 Work History Add2"   data-id="managing_contact_name" >
                                
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">City, State, Zip:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-4">  
                            <input class="form-control onboard"  formControlName="add_1_1957"
                                    id="add_1_1957" name="add_1_1957" type="text" placeholder= "Add1 Work History City"  data-id="managing_contact_name" >
                                
                        </div>  
                        <div class="col-md-4"> 
                            <ng-select 
                                [items]="states"
                                bindLabel="name"
                                placeholder="Enter State"
                                bindValue="id"
                                formControlName="add_1_1959"
                                >
                            </ng-select> 
                        </div>  
                        <div class="col-md-4">  
                            <input class="form-control onboard"  formControlName="add_1_1960"
                                    id="add_1_1960" name="add_1_1960" type="text" placeholder= "Add1 Work History Zip"   data-id="managing_contact_name" >
                                
                        </div>  
                    </div>
                </td>
            </tr>   
            <tr> 
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Start Date:(mm/dd/yyyy)</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="input-group">
                                <input type="text" placeholder="Add1 Work History Start Date" ngbDatepicker #d01966="ngbDatepicker" id="add_1_1966" class="form-control" formControlName="add_1_1966"  (blur)="clearInputIfInvalid('add_1_1966')"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d01966.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div>
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('add_1_1966').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY)</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('add_1_1966').errors?.invalidMonth">
                                  Month is out of range</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('add_1_1966').errors?.invalidDateyear">
                                    year is out of range(1900-2099)</small>
                                    <small class="text-danger" *ngIf="TannerClinicFroms.get('add_1_1966').errors?.invalidDateRange">
                                      date is out of range</small>
                            </div>
                        </div>
                    </div>     
                </td>    
            </tr>     
            <tr> 
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Completed Date:(mm/dd/yyyy)</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="input-group">
                                <input type="text" placeholder="Add1 Work History End Date" ngbDatepicker #d01967="ngbDatepicker" id="add_1_1967" class="form-control" formControlName="add_1_1967" (blur)="clearInputIfInvalid('add_1_1967')"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d01967.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div>
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('add_1_1967').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY)</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('add_1_1967').errors?.invalidMonth">
                                  Month is out of range</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('add_1_1967').errors?.invalidDateyear">
                                    year is out of range(1900-2099)</small>
                                    <small class="text-danger" *ngIf="TannerClinicFroms.get('add_1_1967').errors?.invalidDateRange">
                                      date is out of range</small>
                            </div>
                        </div>
                    </div>     
                </td>    
            </tr>
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Phone:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="add_1_1963" (keydown)="handleNumber($event,'add_1_1963')" maxlength="12"
                                id="add_1_1963" name="add_1_1963" type="text" placeholder="Add1 Work History Phone"   data-id="managing_contact_name" >
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('add_1_1963').errors?.pattern">Required pattern xxx-xxx-xxxx</small>
                        </div> 
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Fax:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="add_1_1964"
                                id="add_1_1964" name="add_1_1964" type="text" placeholder="Add1 Work History Fax"   data-id="managing_contact_name" >
                        </div> 
                    </div>
                </td>
            </tr>    
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Past Company:</label> 
                    </div>   
                </td>
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="add_2_1954"
                            id="add_2_1954" name="add_2_1954" type="text" placeholder= "Add2 Work History Location"   data-id="managing_contact_name" >
                    
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Address:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-6">
                            <input class="form-control onboard"  formControlName="add_2_1955"
                            id="add_1_1955" name="add_2_1955" type="text" placeholder= "Add2 Work History Add1"   data-id="managing_contact_name" >
                    
                        </div>
                        <div class="col-md-6">
                            <input class="form-control onboard"  formControlName="add_2_1956"
                                    id="add_2_1956" name="add_2_1956" type="text" placeholder= "Add2 Work History Add2"   data-id="managing_contact_name" >
                                
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">City, State, Zip:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-4">  
                            <input class="form-control onboard"  formControlName="add_2_1957"
                                    id="add_2_1957" name="add_2_1957" type="text" placeholder= "Add2 Work History City"  data-id="managing_contact_name" >
                                
                        </div>  
                        <div class="col-md-4"> 
                            <ng-select 
                                [items]="states"
                                bindLabel="name"
                                placeholder="Enter State"
                                bindValue="id"
                                formControlName="add_2_1959"
                                >
                            </ng-select> 
                        </div>  
                        <div class="col-md-4">  
                            <input class="form-control onboard"  formControlName="add_2_1960"
                                    id="add_2_1960" name="add_2_1960" type="text" placeholder= "Add2 Work History Zip"   data-id="managing_contact_name" >
                                
                        </div>  
                    </div>
                </td>
            </tr>   
            <tr> 
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Start Date:(mm/dd/yyyy)</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="input-group">
                                <input type="text" placeholder="Add2 Work History Start Date" ngbDatepicker #d011966="ngbDatepicker" id="add_2_1966" class="form-control" formControlName="add_2_1966" (blur)="clearInputIfInvalid('add_2_1966')"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d011966.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div>
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('add_2_1966').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY)</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('add_2_1966').errors?.invalidMonth">
                                  Month is out of range</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('add_2_1966').errors?.invalidDateyear">
                                    year is out of range(1900-2099)</small>
                                    <small class="text-danger" *ngIf="TannerClinicFroms.get('add_2_1966').errors?.invalidDateRange">
                                      date is out of range</small>
                            </div>
                        </div>
                    </div>     
                </td>    
            </tr>     
            <tr> 
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Completed Date:(mm/dd/yyyy)</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="input-group">
                                <input type="text" placeholder="Add2 Work History End Date" ngbDatepicker #d011967="ngbDatepicker" id="add_2_1967" class="form-control" formControlName="add_2_1967" (blur)="clearInputIfInvalid('add_2_1967')"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d011967.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div>
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('add_2_1967').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY)</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('add_2_1967').errors?.invalidMonth">
                                  Month is out of range</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('add_2_1967').errors?.invalidDateyear">
                                    year is out of range(1900-2099)</small>
                                    <small class="text-danger" *ngIf="TannerClinicFroms.get('add_2_1967').errors?.invalidDateRange">
                                      date is out of range</small>
                            </div>
                        </div>
                    </div>     
                </td>    
            </tr>
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Phone:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="add_2_1963"  (keydown)="handleNumber($event,'add_2_1963')" maxlength="12"
                                id="add_2_1963" name="add_2_1963" type="text" placeholder="Add2 Work History Phone"   data-id="managing_contact_name" >
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('add_2_1963').errors?.pattern">Required pattern xxx-xxx-xxxx</small>
                        </div> 
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Fax:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="add_2_1964"
                                id="add_2_1964" name="add_2_1964" type="text" placeholder="Add2 Work History Fax"   data-id="managing_contact_name" >
                        </div> 
                    </div>
                </td>
            </tr>  
            <tr>
                <td colspan="12">
                    <div class="row">
                        <label class="formLabel">Gap(s) In History :</label> 
                    </div>   
                </td>
            </tr>  
            <tr>
                <td colspan="12">
                    <div class="row">
                        <label class="formLabel">Please explain any gap(s) in history since starting medical school that lasted longer than 30 days
                            (beside summer breaks):</label> 
                    </div>   
                </td>
            </tr>
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Reason:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="1138"
                                id="1138" name="1138" type="text" placeholder="Explanation for Gap in Work History  1"   data-id="managing_contact_name" >
                        </div> 
                    </div>
                </td>
            </tr>  
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Start date:(mm/dd/yyyy):</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="input-group">
                                <input type="text" placeholder="Gap Start Date 1" ngbDatepicker #d3395="ngbDatepicker" id="3395" class="form-control" formControlName="3395" (blur)="clearInputIfInvalid('3395')"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d3395.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div>
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('3395').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY)</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('3395').errors?.invalidMonth">
                                  Month is out of range</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('3395').errors?.invalidDateyear">
                                    year is out of range(1900-2099)</small>
                                    <small class="text-danger" *ngIf="TannerClinicFroms.get('3395').errors?.invalidDateRange">
                                      date is out of range</small>
                            </div>
                        </div>
                    </div>     
                </td>    
            </tr>   
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">End date:(mm/dd/yyyy)</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="input-group">
                                <input type="text" placeholder="Gap End Date 1" ngbDatepicker #d3396="ngbDatepicker" id="3396" class="form-control" formControlName="3396" (blur)="clearInputIfInvalid('3396')"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d3396.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div>
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('3396').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY)</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('3396').errors?.invalidMonth">
                                  Month is out of range</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('3396').errors?.invalidDateyear">
                                    year is out of range(1900-2099)</small>
                                    <small class="text-danger" *ngIf="TannerClinicFroms.get('3396').errors?.invalidDateRange">
                                      date is out of range</small>
                            </div>
                        </div>
                    </div>     
                </td>    
            </tr>  
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Reason:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="1831"
                                id="1831" name="1831" type="text" placeholder="Explanation for Gap in Work History  2"   data-id="managing_contact_name" >
                        </div> 
                    </div>
                </td>
            </tr>  
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Start date:(mm/dd/yyyy):</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="input-group">
                                <input type="text" placeholder="Gap Start Date 2" ngbDatepicker #d3397="ngbDatepicker" id="3397" class="form-control" formControlName="3397" (blur)="clearInputIfInvalid('3397')"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d3397.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div>
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('3397').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY)</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('3397').errors?.invalidMonth">
                                  Month is out of range</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('3397').errors?.invalidDateyear">
                                    year is out of range(1900-2099)</small>
                                    <small class="text-danger" *ngIf="TannerClinicFroms.get('3397').errors?.invalidDateRange">
                                      date is out of range</small>
                            </div>
                        </div>
                    </div>     
                </td>    
            </tr>   
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">End date:(mm/dd/yyyy)</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="input-group">
                                <input type="text" placeholder="Gap End Date 2" ngbDatepicker #d3398="ngbDatepicker" id="3398" class="form-control" formControlName="3398" (blur)="clearInputIfInvalid('3398')"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d3398.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div>
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('3398').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY)</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('3398').errors?.invalidMonth">
                                  Month is out of range</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('3398').errors?.invalidDateyear">
                                    year is out of range(1900-2099)</small>
                                    <small class="text-danger" *ngIf="TannerClinicFroms.get('3398').errors?.invalidDateRange">
                                      date is out of range</small>
                            </div>
                        </div>
                    </div>     
                </td>    
            </tr> 
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Reason:</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="1832"
                                id="1832" name="1832" type="text" placeholder="Explanation for Gap in Work History  3"   data-id="managing_contact_name" >
                        </div> 
                    </div>
                </td>
            </tr>  
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Start date:(mm/dd/yyyy):</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="input-group">
                                <input type="text" placeholder="Gap Start Date 3" ngbDatepicker #d3399="ngbDatepicker" id="3399" class="form-control" formControlName="3399" (blur)="clearInputIfInvalid('3399')"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d3399.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div>
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('3399').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY)</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('3399').errors?.invalidMonth">
                                  Month is out of range</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('3399').errors?.invalidDateyear">
                                    year is out of range(1900-2099)</small>
                                    <small class="text-danger" *ngIf="TannerClinicFroms.get('3399').errors?.invalidDateRange">
                                      date is out of range</small>
                            </div>
                        </div>
                    </div>     
                </td>    
            </tr>   
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">End date:(mm/dd/yyyy)</label> 
                    </div>                    
                </td>   
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="input-group">
                                <input type="text" placeholder="Gap End Date 3" ngbDatepicker #d3400="ngbDatepicker" id="3400" class="form-control" formControlName="3400" (blur)="clearInputIfInvalid('3400')"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d3400.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div>
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('3400').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY)</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('3400').errors?.invalidMonth">
                                  Month is out of range</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('3400').errors?.invalidDateyear">
                                    year is out of range(1900-2099)</small>
                                    <small class="text-danger" *ngIf="TannerClinicFroms.get('3400').errors?.invalidDateRange">
                                      date is out of range</small>
                            </div>
                        </div>
                    </div>     
                </td>    
            </tr>   
            <tr>
                <td colspan="12">
                    <div class="row">
                        <label class="formLabel">References:</label> 
                    </div>   
                </td>
            </tr>   
            <tr>
                <td colspan="12">
                    <div class="row">
                        <label class="formLabel">References - List four professional references who have direct knowledge (by way of observation of
                            your work or working with you) of your clinical abilities.</label> 
                    </div>   
                </td>
            </tr> 
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">*Name:</label> 
                    </div>   
                </td>
                <td colspan="6">
                    <div class="row">
                       <div class="col-md-8">
                        <input class="form-control onboard"  formControlName="359"
                                    id="359" name="359" type="text" placeholder= "Reference 1 Name" [class.is-invalid]="(TannerClinicFroms.controls['359'].touched && TannerClinicFroms.controls['359'].invalid) || (TannerClinicFroms.controls['359'].invalid && isSubmit)" data-id="managing_contact_name" >
                                <div class="formLabel col-md-11"
                                    *ngIf="TannerClinicFroms.controls['359'].touched && TannerClinicFroms.controls['359'].invalid">
                                    <small class="text-danger" *ngIf="TannerClinicFroms.controls['359'].errors?.required">
                                        required
                                    </small>
                                </div>
                       </div>
                    </div>   
                </td>
            </tr>   
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">*Title:MD, DO, NP, PA, etc:</label> 
                    </div>   
                </td>
                <td colspan="6">
                    <div class="row">
                       <div class="col-md-8">
                        <input class="form-control onboard"  formControlName="2722"
                                    id="2722" name="2722" type="text" placeholder= "Reference 1 Title" [class.is-invalid]="(TannerClinicFroms.controls['2722'].touched && TannerClinicFroms.controls['2722'].invalid) || (TannerClinicFroms.controls['2722'].invalid && isSubmit)" data-id="managing_contact_name" >
                                <div class="formLabel col-md-11"
                                    *ngIf="TannerClinicFroms.controls['2722'].touched && TannerClinicFroms.controls['2722'].invalid">
                                    <small class="text-danger" *ngIf="TannerClinicFroms.controls['2722'].errors?.required">
                                        required
                                    </small>
                                </div>
                       </div>
                    </div>   
                </td>
            </tr>                     
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">*Specialty:</label> 
                    </div>   
                </td>
                <td colspan="6">
                    <div class="row">
                       <div class="col-md-8">
                            <ng-select
                            [items]="specialty"
                                bindLabel="name"
                                placeholder="Enter Specialty"
                                bindValue="id"
                                formControlName="368"
                                [class.is-invalid]="(TannerClinicFroms.controls['368'].touched && TannerClinicFroms.controls['368'].invalid) || (TannerClinicFroms.controls['368'].invalid && isSubmit)"
                                >
                            </ng-select> 
                            <div class="formLabel col-md-11"
                                *ngIf="TannerClinicFroms.controls['368'].touched && TannerClinicFroms.controls['368'].invalid">
                                <small class="text-danger" *ngIf="TannerClinicFroms.controls['368'].errors?.required">
                                    required
                                </small>
                            </div>
                       </div>
                    </div>   
                </td>
            </tr>         
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">*Address:</label> 
                    </div>   
                </td>
                <td colspan="6">
                    <div class="row">
                       <div class="col-md-6">
                            <input class="form-control onboard"  formControlName="360"
                            id="360" name="360" type="text" placeholder= "Reference 1 Add1" [class.is-invalid]="(TannerClinicFroms.controls['360'].touched && TannerClinicFroms.controls['360'].invalid) || (TannerClinicFroms.controls['360'].invalid && isSubmit)" data-id="managing_contact_name" >
                            <div class="formLabel col-md-11"
                                *ngIf="TannerClinicFroms.controls['360'].touched && TannerClinicFroms.controls['360'].invalid">
                                <small class="text-danger" *ngIf="TannerClinicFroms.controls['360'].errors?.required">
                                    required
                                </small>
                            </div> 
                       </div>
                       <div class="col-md-6">
                                <input class="form-control onboard"  formControlName="361"
                                id="361" name="361" type="text" placeholder= "Reference 1 Add2"  data-id="managing_contact_name" >
                            
                           
                        </div>
                    </div>   
                </td>
            </tr>         
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">*City, State, Zip:</label> 
                    </div>   
                </td>
                <td colspan="6">
                    <div class="row">
                       <div class="col-md-4">
                                <input class="form-control onboard"  formControlName="362"
                                id="362" name="362" type="text" placeholder= "Reference 1 City" [class.is-invalid]="(TannerClinicFroms.controls['362'].touched && TannerClinicFroms.controls['362'].invalid) || (TannerClinicFroms.controls['362'].invalid && isSubmit)" data-id="managing_contact_name" >
                            <div class="formLabel col-md-11"
                                *ngIf="TannerClinicFroms.controls['362'].touched && TannerClinicFroms.controls['362'].invalid">
                                <small class="text-danger" *ngIf="TannerClinicFroms.controls['362'].errors?.required">
                                    required
                                </small>
                            </div>
                       </div>
                       <div class="col-md-4">
                                <ng-select
                                [items]="states"
                                bindLabel="name"
                                placeholder="Enter State"
                                bindValue="id"
                                formControlName="364"
                                [class.is-invalid]="(TannerClinicFroms.controls['364'].touched && TannerClinicFroms.controls['364'].invalid) || (TannerClinicFroms.controls['364'].invalid && isSubmit)"
                                >
                            </ng-select> 
                            <div class="formLabel col-md-11"
                                *ngIf="TannerClinicFroms.controls['364'].touched && TannerClinicFroms.controls['364'].invalid">
                                <small class="text-danger" *ngIf="TannerClinicFroms.controls['364'].errors?.required">
                                    required
                                </small>
                            </div>
                           
                        </div>
                        <div class="col-md-4">
                            <input class="form-control onboard"  formControlName="365"
                                    id="365" name="365" type="text" placeholder= "Reference 1 Zip" [class.is-invalid]="(TannerClinicFroms.controls['365'].touched && TannerClinicFroms.controls['365'].invalid) || (TannerClinicFroms.controls['365'].invalid && isSubmit)" data-id="managing_contact_name" >
                                <div class="formLabel col-md-11"
                                    *ngIf="TannerClinicFroms.controls['365'].touched && TannerClinicFroms.controls['365'].invalid">
                                    <small class="text-danger" *ngIf="TannerClinicFroms.controls['365'].errors?.required">
                                        required
                                    </small>
                                </div>
                        </div>
                    </div>   
                </td>
            </tr>  
            <tr>    
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">*Phone:</label> 
                    </div>  
                </td> 
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="369" (keydown)="handleNumber($event,369)" maxlength="12"
                                    id="369" name="369" type="text" placeholder= "Reference 1 Phone " [class.is-invalid]="(TannerClinicFroms.controls['369'].touched && TannerClinicFroms.controls['369'].invalid) || (TannerClinicFroms.controls['369'].invalid && isSubmit)"  data-id="managing_contact_name" >
                                    <div class="mandatory" *ngIf="
                                    TannerClinicFroms.controls['369'].touched &&
                                    TannerClinicFroms.controls['369'].invalid ">
                                      <small class="text-danger" *ngIf="TannerClinicFroms.controls['369'].errors?.required">
                                         Required 
                                       </small>
                                       <small class="text-danger" *ngIf="TannerClinicFroms.controls['369'].errors?.pattern">
                                        Required pattern xxx-xxx-xxxx
                                       </small>
                                   </div>
                        </div>
                    </div>
                </td>         
            </tr>     
            <tr>    
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Fax:</label> 
                    </div>  
                </td> 
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="370"
                                    id="370" name="370" type="text" placeholder= "Reference 1 Fax "   data-id="managing_contact_name" >
                                
                        </div>
                    </div>
                </td>         
            </tr>         
            <tr>    
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">*e-mail address:</label> 
                    </div>  
                </td> 
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="371"
                                    id="371" name="371" type="text" placeholder= "Reference 1 Email " [class.is-invalid]="(TannerClinicFroms.controls['371'].touched && TannerClinicFroms.controls['371'].invalid) || (TannerClinicFroms.controls['371'].invalid && isSubmit)"  data-id="managing_contact_name" >
                                <div class="formLabel col-md-11"
                                    *ngIf="TannerClinicFroms.controls['371'].touched && TannerClinicFroms.controls['371'].invalid">
                                    <small class="text-danger" *ngIf="TannerClinicFroms.controls['371'].errors?.required">
                                        required
                                    </small>
                                </div>    
                        </div>
                    </div>
                </td>         
            </tr> 
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">*Name:</label> 
                    </div>   
                </td>
                <td colspan="6">
                    <div class="row">
                       <div class="col-md-8">
                                <input class="form-control onboard"  formControlName="372"
                                id="372" name="372" type="text" placeholder= "Reference 2 Name" [class.is-invalid]="(TannerClinicFroms.controls['372'].touched && TannerClinicFroms.controls['372'].invalid) || (TannerClinicFroms.controls['372'].invalid && isSubmit)" data-id="managing_contact_name" >
                            <div class="formLabel col-md-11"
                                *ngIf="TannerClinicFroms.controls['372'].touched && TannerClinicFroms.controls['372'].invalid">
                                <small class="text-danger" *ngIf="TannerClinicFroms.controls['372'].errors?.required">
                                    required
                                </small>
                            </div>
                       </div>
                    </div>   
                </td>
            </tr>   
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">*Title:MD, DO, NP, PA, etc:</label> 
                    </div>   
                </td>
                <td colspan="6">
                    <div class="row">
                       <div class="col-md-8">
                        <input class="form-control onboard"  formControlName="2723"
                                    id="2723" name="2723" type="text" placeholder= "Reference 2 Title" [class.is-invalid]="(TannerClinicFroms.controls['2723'].touched && TannerClinicFroms.controls['2723'].invalid) || (TannerClinicFroms.controls['2723'].invalid && isSubmit)" data-id="managing_contact_name" >
                                <div class="formLabel col-md-11"
                                    *ngIf="TannerClinicFroms.controls['2723'].touched && TannerClinicFroms.controls['2723'].invalid">
                                    <small class="text-danger" *ngIf="TannerClinicFroms.controls['2723'].errors?.required">
                                        required
                                    </small>
                                </div>
                       </div>
                    </div>   
                </td>
            </tr>                     
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">*Specialty:</label> 
                    </div>   
                </td>
                <td colspan="6">
                    <div class="row">
                       <div class="col-md-8">
                            <ng-select
                            [items]="specialty"
                                bindLabel="name"
                                placeholder="Enter Specialty"
                                bindValue="id"
                                formControlName="380"
                                [class.is-invalid]="(TannerClinicFroms.controls['380'].touched && TannerClinicFroms.controls['380'].invalid) || (TannerClinicFroms.controls['380'].invalid && isSubmit)"
                                >
                            </ng-select> 
                            <div class="formLabel col-md-11"
                                *ngIf="TannerClinicFroms.controls['380'].touched && TannerClinicFroms.controls['380'].invalid">
                                <small class="text-danger" *ngIf="TannerClinicFroms.controls['380'].errors?.required">
                                    required
                                </small>
                            </div>
                       </div>
                    </div>   
                </td>
            </tr>         
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">*Address:</label> 
                    </div>   
                </td>
                <td colspan="6">
                    <div class="row">
                       <div class="col-md-6">
                                <input class="form-control onboard"  formControlName="373"
                                id="373" name="373" type="text" placeholder= "Reference 2 Add1" [class.is-invalid]="(TannerClinicFroms.controls['373'].touched && TannerClinicFroms.controls['373'].invalid) || (TannerClinicFroms.controls['373'].invalid && isSubmit)" data-id="managing_contact_name" >
                            <div class="formLabel col-md-11"
                                *ngIf="TannerClinicFroms.controls['373'].touched && TannerClinicFroms.controls['373'].invalid">
                                <small class="text-danger" *ngIf="TannerClinicFroms.controls['373'].errors?.required">
                                    required
                                </small>
                            </div>
                       </div>
                       <div class="col-md-6">
                                <input class="form-control onboard"  formControlName="374"
                                id="374" name="374" type="text" placeholder= "Reference 2 Add2"  data-id="managing_contact_name" >
                            
                                
                        </div>
                    </div>   
                </td>
            </tr>         
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">*City, State, Zip:</label> 
                    </div>   
                </td>
                <td colspan="6">
                    <div class="row">
                       <div class="col-md-4">
                                <input class="form-control onboard"  formControlName="375"
                                id="375" name="375" type="text" placeholder= "Reference 2 City" [class.is-invalid]="(TannerClinicFroms.controls['375'].touched && TannerClinicFroms.controls['375'].invalid) || (TannerClinicFroms.controls['375'].invalid && isSubmit)" data-id="managing_contact_name" >
                            <div class="formLabel col-md-11"
                                *ngIf="TannerClinicFroms.controls['375'].touched && TannerClinicFroms.controls['375'].invalid">
                                <small class="text-danger" *ngIf="TannerClinicFroms.controls['375'].errors?.required">
                                    required
                                </small>
                            </div>
                       </div>
                       <div class="col-md-4">
                                <ng-select
                                [items]="states"
                                bindLabel="name"
                                placeholder="Enter State"
                                bindValue="id"
                                formControlName="377"
                                [class.is-invalid]="(TannerClinicFroms.controls['377'].touched && TannerClinicFroms.controls['377'].invalid) || (TannerClinicFroms.controls['377'].invalid && isSubmit)"
                                >
                            </ng-select> 
                            <div class="formLabel col-md-11"
                                *ngIf="TannerClinicFroms.controls['377'].touched && TannerClinicFroms.controls['377'].invalid">
                                <small class="text-danger" *ngIf="TannerClinicFroms.controls['377'].errors?.required">
                                    required
                                </small>
                            </div>
                           
                        </div>
                        <div class="col-md-4">
                                <input class="form-control onboard"  formControlName="378"
                                id="378" name="378" type="text" placeholder= "Reference 2 Zip" [class.is-invalid]="(TannerClinicFroms.controls['378'].touched && TannerClinicFroms.controls['378'].invalid) || (TannerClinicFroms.controls['378'].invalid && isSubmit)" data-id="managing_contact_name" >
                            <div class="formLabel col-md-11"
                                *ngIf="TannerClinicFroms.controls['378'].touched && TannerClinicFroms.controls['378'].invalid">
                                <small class="text-danger" *ngIf="TannerClinicFroms.controls['378'].errors?.required">
                                    required
                                </small>
                            </div>
                        </div>
                    </div>   
                </td>
            </tr>  
            <tr>    
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">*Phone:</label> 
                    </div>  
                </td> 
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="381" (keydown)="handleNumber($event,381)" maxlength="12"
                                    id="381" name="381" type="text" placeholder= "Reference 2 Phone " [class.is-invalid]="(TannerClinicFroms.controls['381'].touched && TannerClinicFroms.controls['381'].invalid) || (TannerClinicFroms.controls['381'].invalid && isSubmit)"  data-id="managing_contact_name" >
                                    <div class="mandatory" *ngIf="
                                    TannerClinicFroms.controls['381'].touched &&
                                    TannerClinicFroms.controls['381'].invalid ">
                                      <small class="text-danger" *ngIf="TannerClinicFroms.controls['381'].errors?.required">
                                         Required 
                                       </small>
                                       <small class="text-danger" *ngIf="TannerClinicFroms.controls['381'].errors?.pattern">
                                        Required pattern xxx-xxx-xxxx
                                       </small>
                                   </div>
                        </div>
                    </div>
                </td>         
            </tr>     
            <tr>    
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Fax:</label> 
                    </div>  
                </td> 
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="382"
                                    id="382" name="382" type="text" placeholder= "Reference 2 Fax "   data-id="managing_contact_name" >
                                
                        </div>
                    </div>
                </td>         
            </tr>         
            <tr>    
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">*e-mail address:</label> 
                    </div>  
                </td> 
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="383"
                                    id="383" name="383" type="text" placeholder= "Reference 2 Email " [class.is-invalid]="(TannerClinicFroms.controls['383'].touched && TannerClinicFroms.controls['383'].invalid) || (TannerClinicFroms.controls['383'].invalid && isSubmit)"  data-id="managing_contact_name" >
                                <div class="formLabel col-md-11"
                                    *ngIf="TannerClinicFroms.controls['383'].touched && TannerClinicFroms.controls['383'].invalid">
                                    <small class="text-danger" *ngIf="TannerClinicFroms.controls['383'].errors?.required">
                                        required
                                    </small>
                                </div>
                        </div>
                    </div>
                </td>         
            </tr>                                                                                                    
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">*Name:</label> 
                    </div>   
                </td>
                <td colspan="6">
                    <div class="row">
                       <div class="col-md-8">
                                <input class="form-control onboard"  formControlName="384"
                                id="384" name="384" type="text" placeholder= "Reference 3 Name" [class.is-invalid]="(TannerClinicFroms.controls['384'].touched && TannerClinicFroms.controls['384'].invalid) || (TannerClinicFroms.controls['384'].invalid && isSubmit)" data-id="managing_contact_name" >
                            <div class="formLabel col-md-11"
                                *ngIf="TannerClinicFroms.controls['384'].touched && TannerClinicFroms.controls['384'].invalid">
                                <small class="text-danger" *ngIf="TannerClinicFroms.controls['384'].errors?.required">
                                    required
                                </small>
                            </div>
                       </div>
                    </div>   
                </td>
            </tr>   
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">*Title:MD, DO, NP, PA, etc:</label> 
                    </div>   
                </td>
                <td colspan="6">
                    <div class="row">
                       <div class="col-md-8">
                        <input class="form-control onboard"  formControlName="2725"
                                    id="2725" name="2725" type="text" placeholder= "Reference 3 Title" [class.is-invalid]="(TannerClinicFroms.controls['2725'].touched && TannerClinicFroms.controls['2725'].invalid) || (TannerClinicFroms.controls['2725'].invalid && isSubmit)" data-id="managing_contact_name" >
                                <div class="formLabel col-md-11"
                                    *ngIf="TannerClinicFroms.controls['2725'].touched && TannerClinicFroms.controls['2725'].invalid">
                                    <small class="text-danger" *ngIf="TannerClinicFroms.controls['2725'].errors?.required">
                                        required
                                    </small>
                                </div>
                       </div>
                    </div>   
                </td>
            </tr>                     
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">*Specialty:</label> 
                    </div>   
                </td>
                <td colspan="6">
                    <div class="row">
                       <div class="col-md-8">
                            <ng-select
                            [items]="specialty"
                                bindLabel="name"
                                placeholder="Enter Specialty"
                                bindValue="id"
                                formControlName="393"
                                [class.is-invalid]="(TannerClinicFroms.controls['393'].touched && TannerClinicFroms.controls['393'].invalid) || (TannerClinicFroms.controls['393'].invalid && isSubmit)"
                                >
                            </ng-select> 
                            <div class="formLabel col-md-11"
                                *ngIf="TannerClinicFroms.controls['393'].touched && TannerClinicFroms.controls['393'].invalid">
                                <small class="text-danger" *ngIf="TannerClinicFroms.controls['393'].errors?.required">
                                    required
                                </small>
                            </div>
                       </div>
                    </div>   
                </td>
            </tr>         
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">*Address:</label> 
                    </div>   
                </td>
                <td colspan="6">
                    <div class="row">
                       <div class="col-md-6">
                                <input class="form-control onboard"  formControlName="385"
                                id="385" name="385" type="text" placeholder= "Reference 3 Add1" [class.is-invalid]="(TannerClinicFroms.controls['385'].touched && TannerClinicFroms.controls['385'].invalid) || (TannerClinicFroms.controls['385'].invalid && isSubmit)" data-id="managing_contact_name" >
                            <div class="formLabel col-md-11"
                                *ngIf="TannerClinicFroms.controls['385'].touched && TannerClinicFroms.controls['385'].invalid">
                                <small class="text-danger" *ngIf="TannerClinicFroms.controls['385'].errors?.required">
                                    required
                                </small>
                            </div>
                       </div>
                       <div class="col-md-6">
                                <input class="form-control onboard"  formControlName="386"
                                id="386" name="386" type="text" placeholder= "Reference 3 Add2"  data-id="managing_contact_name" >
                            
                                
                        </div>
                    </div>   
                </td>
            </tr>         
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">*City, State, Zip:</label> 
                    </div>   
                </td>
                <td colspan="6">
                    <div class="row">
                       <div class="col-md-4">
                                    <input class="form-control onboard"  formControlName="387"
                                    id="387" name="387" type="text" placeholder= "Reference 3 City" [class.is-invalid]="(TannerClinicFroms.controls['387'].touched && TannerClinicFroms.controls['387'].invalid) || (TannerClinicFroms.controls['387'].invalid && isSubmit)" data-id="managing_contact_name" >
                                <div class="formLabel col-md-11"
                                    *ngIf="TannerClinicFroms.controls['387'].touched && TannerClinicFroms.controls['387'].invalid">
                                    <small class="text-danger" *ngIf="TannerClinicFroms.controls['387'].errors?.required">
                                        required
                                    </small>
                                </div>
                       </div>
                       <div class="col-md-4">
                                <ng-select
                                [items]="states"
                                bindLabel="name"
                                placeholder="Enter State"
                                bindValue="id"
                                formControlName="389"
                                [class.is-invalid]="(TannerClinicFroms.controls['389'].touched && TannerClinicFroms.controls['389'].invalid) || (TannerClinicFroms.controls['389'].invalid && isSubmit)"
                                >
                            </ng-select> 
                            <div class="formLabel col-md-11"
                                *ngIf="TannerClinicFroms.controls['389'].touched && TannerClinicFroms.controls['389'].invalid">
                                <small class="text-danger" *ngIf="TannerClinicFroms.controls['389'].errors?.required">
                                    required
                                </small>
                            </div>
                           
                        </div>
                        <div class="col-md-4">
                                <input class="form-control onboard"  formControlName="390"
                                id="390" name="390" type="text" placeholder= "Reference 3 Zip" [class.is-invalid]="(TannerClinicFroms.controls['390'].touched && TannerClinicFroms.controls['390'].invalid) || (TannerClinicFroms.controls['390'].invalid && isSubmit)" data-id="managing_contact_name" >
                            <div class="formLabel col-md-11"
                                *ngIf="TannerClinicFroms.controls['390'].touched && TannerClinicFroms.controls['390'].invalid">
                                <small class="text-danger" *ngIf="TannerClinicFroms.controls['390'].errors?.required">
                                    required
                                </small>
                            </div>
                        </div>
                    </div>   
                </td>
            </tr>  
            <tr>    
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">*Phone:</label> 
                    </div>  
                </td> 
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="394" (keydown)="handleNumber($event,394)" maxlength="12"
                                    id="394" name="394" type="text" placeholder= "Reference 3 Phone " [class.is-invalid]="(TannerClinicFroms.controls['394'].touched && TannerClinicFroms.controls['394'].invalid) || (TannerClinicFroms.controls['394'].invalid && isSubmit)"  data-id="managing_contact_name" >
                                    <div class="mandatory" *ngIf="
                                    TannerClinicFroms.controls['394'].touched &&
                                    TannerClinicFroms.controls['394'].invalid ">
                                      <small class="text-danger" *ngIf="TannerClinicFroms.controls['394'].errors?.required">
                                         Required 
                                       </small>
                                       <small class="text-danger" *ngIf="TannerClinicFroms.controls['394'].errors?.pattern">
                                        Required pattern xxx-xxx-xxxx
                                       </small>
                                   </div>
                        </div>
                    </div>
                </td>         
            </tr>     
            <tr>    
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Fax:</label> 
                    </div>  
                </td> 
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="395"
                                    id="395" name="395" type="text" placeholder= "Reference 3 Fax "   data-id="managing_contact_name" >
                                
                        </div>
                    </div>
                </td>         
            </tr>         
            <tr>    
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">*e-mail address:</label> 
                    </div>  
                </td> 
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="396"
                                    id="396" name="396" type="text" placeholder= "Reference 3 Email " [class.is-invalid]="(TannerClinicFroms.controls['396'].touched && TannerClinicFroms.controls['396'].invalid) || (TannerClinicFroms.controls['396'].invalid && isSubmit)"  data-id="managing_contact_name" >
                                <div class="formLabel col-md-11"
                                    *ngIf="TannerClinicFroms.controls['396'].touched && TannerClinicFroms.controls['396'].invalid">
                                    <small class="text-danger" *ngIf="TannerClinicFroms.controls['396'].errors?.required">
                                        required
                                    </small>
                                </div>
                        </div>
                    </div>
                </td>         
            </tr> 
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">*Name:</label> 
                    </div>   
                </td>
                <td colspan="6">
                    <div class="row">
                       <div class="col-md-8">
                                <input class="form-control onboard"  formControlName="1681"
                                id="1681" name="1681" type="text" placeholder= "Reference 4 Name" [class.is-invalid]="(TannerClinicFroms.controls['1681'].touched && TannerClinicFroms.controls['1681'].invalid) || (TannerClinicFroms.controls['1681'].invalid && isSubmit)" data-id="managing_contact_name" >
                            <div class="formLabel col-md-11"
                                *ngIf="TannerClinicFroms.controls['1681'].touched && TannerClinicFroms.controls['1681'].invalid">
                                <small class="text-danger" *ngIf="TannerClinicFroms.controls['681'].errors?.required">
                                    required
                                </small>
                            </div>
                       </div>
                    </div>   
                </td>
            </tr>   
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">*Title:MD, DO, NP, PA, etc:</label> 
                    </div>   
                </td>
                <td colspan="6">
                    <div class="row">
                       <div class="col-md-8">
                        <input class="form-control onboard"  formControlName="2727"
                                    id="2727" name="2727" type="text" placeholder= "Reference 4 Title" [class.is-invalid]="(TannerClinicFroms.controls['2727'].touched && TannerClinicFroms.controls['2727'].invalid) || (TannerClinicFroms.controls['2727'].invalid && isSubmit)" data-id="managing_contact_name" >
                                <div class="formLabel col-md-11"
                                    *ngIf="TannerClinicFroms.controls['2727'].touched && TannerClinicFroms.controls['2727'].invalid">
                                    <small class="text-danger" *ngIf="TannerClinicFroms.controls['2727'].errors?.required">
                                        required
                                    </small>
                                </div>
                       </div>
                    </div>   
                </td>
            </tr>                     
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">*Specialty:</label> 
                    </div>   
                </td>
                <td colspan="6">
                    <div class="row">
                       <div class="col-md-8">
                            <ng-select
                            [items]="specialty"
                                bindLabel="name"
                                placeholder="Enter Specialty"
                                bindValue="id"
                                formControlName="1691"
                                [class.is-invalid]="(TannerClinicFroms.controls['1691'].touched && TannerClinicFroms.controls['1691'].invalid) || (TannerClinicFroms.controls['1691'].invalid && isSubmit)"
                                >
                            </ng-select> 
                            <div class="formLabel col-md-11"
                                *ngIf="TannerClinicFroms.controls['1691'].touched && TannerClinicFroms.controls['1691'].invalid">
                                <small class="text-danger" *ngIf="TannerClinicFroms.controls['1691'].errors?.required">
                                    required
                                </small>
                            </div>
                        </div>    
                    </div>   
                </td>
            </tr>         
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">*Address:</label> 
                    </div>   
                </td>
                <td colspan="6">
                    <div class="row">
                       <div class="col-md-6">
                                <input class="form-control onboard"  formControlName="1682"
                                id="1682" name="1682" type="text" placeholder= "Reference 4 Add1" [class.is-invalid]="(TannerClinicFroms.controls['1682'].touched && TannerClinicFroms.controls['1682'].invalid) || (TannerClinicFroms.controls['1682'].invalid && isSubmit)" data-id="managing_contact_name" >
                            <div class="formLabel col-md-11"
                                *ngIf="TannerClinicFroms.controls['1682'].touched && TannerClinicFroms.controls['1682'].invalid">
                                <small class="text-danger" *ngIf="TannerClinicFroms.controls['1682'].errors?.required">
                                    required
                                </small>
                            </div>
                       </div>
                       <div class="col-md-6">
                                <input class="form-control onboard"  formControlName="1683"
                                id="1683" name="1683" type="text" placeholder= "Reference 4 Add2"  data-id="managing_contact_name" >
                            
                                
                        </div>
                    </div>   
                </td>
            </tr>         
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">*City, State, Zip:</label> 
                    </div>   
                </td>
                <td colspan="6">
                    <div class="row">
                       <div class="col-md-4">
                                <input class="form-control onboard"  formControlName="1684"
                                id="1684" name="1684" type="text" placeholder= "Reference 4 City" [class.is-invalid]="(TannerClinicFroms.controls['1684'].touched && TannerClinicFroms.controls['1684'].invalid) || (TannerClinicFroms.controls['1684'].invalid && isSubmit)" data-id="managing_contact_name" >
                            <div class="formLabel col-md-11"
                                *ngIf="TannerClinicFroms.controls['1684'].touched && TannerClinicFroms.controls['1684'].invalid">
                                <small class="text-danger" *ngIf="TannerClinicFroms.controls['1684'].errors?.required">
                                    required
                                </small>
                            </div>
                       </div>
                       <div class="col-md-4">
                                <ng-select
                                [items]="states"
                                bindLabel="name"
                                placeholder="Enter State"
                                bindValue="id"
                                formControlName="1686"
                                [class.is-invalid]="(TannerClinicFroms.controls['1686'].touched && TannerClinicFroms.controls['1686'].invalid) || (TannerClinicFroms.controls['1686'].invalid && isSubmit)"
                                >
                            </ng-select> 
                            <div class="formLabel col-md-11"
                                *ngIf="TannerClinicFroms.controls['1686'].touched && TannerClinicFroms.controls['1686'].invalid">
                                <small class="text-danger" *ngIf="TannerClinicFroms.controls['1686'].errors?.required">
                                    required
                                </small>
                            </div>
                           
                        </div>
                        <div class="col-md-4">
                                <input class="form-control onboard"  formControlName="1687"
                                id="1687" name="1687" type="text" placeholder= "Reference 4 Zip" [class.is-invalid]="(TannerClinicFroms.controls['1687'].touched && TannerClinicFroms.controls['1687'].invalid) || (TannerClinicFroms.controls['1687'].invalid && isSubmit)" data-id="managing_contact_name" >
                            <div class="formLabel col-md-11"
                                *ngIf="TannerClinicFroms.controls['1687'].touched && TannerClinicFroms.controls['1687'].invalid">
                                <small class="text-danger" *ngIf="TannerClinicFroms.controls['1687'].errors?.required">
                                    required
                                </small>
                            </div>
                        </div>
                    </div>   
                </td>
            </tr>  
            <tr>    
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">*Phone:</label> 
                    </div>  
                </td> 
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="1692" (keydown)="handleNumber($event,1692)" maxlength="12"
                            id="1692" name="1692" type="text" placeholder= "Reference 4 Phone " [class.is-invalid]="(TannerClinicFroms.controls['1692'].touched && TannerClinicFroms.controls['1692'].invalid) || (TannerClinicFroms.controls['1692'].invalid && isSubmit)"  data-id="managing_contact_name" >
                            <div class="mandatory" *ngIf="
                                    TannerClinicFroms.controls['1692'].touched &&
                                    TannerClinicFroms.controls['1692'].invalid ">
                                      <small class="text-danger" *ngIf="TannerClinicFroms.controls['1692'].errors?.required">
                                         Required 
                                       </small>
                                       <small class="text-danger" *ngIf="TannerClinicFroms.controls['1692'].errors?.pattern">
                                        Required pattern xxx-xxx-xxxx
                                       </small>
                                   </div>
                        </div>
                    </div>
                </td>         
            </tr>     
            <tr>    
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Fax:</label> 
                    </div>  
                </td> 
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="1693"
                                    id="1693" name="1693" type="text" placeholder= "Reference 4 Fax "   data-id="managing_contact_name" >
                                
                        </div>
                    </div>
                </td>         
            </tr>         
            <tr>    
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">*e-mail address:</label> 
                    </div>  
                </td> 
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="1694"
                                    id="1694" name="1694" type="text" placeholder= "Reference 4 Email " [class.is-invalid]="(TannerClinicFroms.controls['1694'].touched && TannerClinicFroms.controls['1694'].invalid) || (TannerClinicFroms.controls['1694'].invalid && isSubmit)"  data-id="managing_contact_name" >
                                <div class="formLabel col-md-11"
                                    *ngIf="TannerClinicFroms.controls['1694'].touched && TannerClinicFroms.controls['1694'].invalid">
                                    <small class="text-danger" *ngIf="TannerClinicFroms.controls['1694'].errors?.required">
                                        required
                                    </small>
                                </div>
                        </div>
                    </div>
                </td>         
            </tr> 
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Name:</label> 
                    </div>   
                </td>
                <td colspan="6">
                    <div class="row">
                       <div class="col-md-8">
                                <input class="form-control onboard"  formControlName="2792"
                                id="2792" name="2792" type="text" placeholder= "Reference 5 Name" [class.is-invalid]="(TannerClinicFroms.controls['2792'].touched && TannerClinicFroms.controls['2792'].invalid) || (TannerClinicFroms.controls['2792'].invalid && isSubmit)"  data-id="managing_contact_name" >
                                <div class="formLabel col-md-11"
                                    *ngIf="TannerClinicFroms.controls['2792'].touched && TannerClinicFroms.controls['2792'].invalid">
                                    <small class="text-danger" *ngIf="TannerClinicFroms.controls['2792'].errors?.required">
                                        required
                                    </small>
                                </div>

                        </div>
                    </div>   
                </td>
            </tr>   
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">*Title:MD, DO, NP, PA, etc:</label> 
                    </div>   
                </td>
                <td colspan="6">
                    <div class="row">
                       <div class="col-md-8">
                        <input class="form-control onboard"  formControlName="2793"
                                    id="2793" name="2793" type="text" placeholder= "Reference 5 Title" [class.is-invalid]="(TannerClinicFroms.controls['2793'].touched && TannerClinicFroms.controls['2793'].invalid) || (TannerClinicFroms.controls['2793'].invalid && isSubmit)" data-id="managing_contact_name" >
                                <div class="formLabel col-md-11"
                                    *ngIf="TannerClinicFroms.controls['2793'].touched && TannerClinicFroms.controls['2793'].invalid">
                                    <small class="text-danger" *ngIf="TannerClinicFroms.controls['2793'].errors?.required">
                                        required
                                    </small>
                                </div>
                       </div>
                    </div>   
                </td>
            </tr>                     
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">*Specialty:</label> 
                    </div>   
                </td>
                <td colspan="6">
                    <div class="row">
                       <div class="col-md-8">
                            <ng-select
                            [items]="specialty"
                                bindLabel="name"
                                placeholder="Enter Specialty"
                                bindValue="id"
                                formControlName="2809"
                                [class.is-invalid]="(TannerClinicFroms.controls['2809'].touched && TannerClinicFroms.controls['2809'].invalid) || (TannerClinicFroms.controls['2809'].invalid && isSubmit)"
                                >
                            </ng-select> 
                            <div class="formLabel col-md-11"
                                *ngIf="TannerClinicFroms.controls['2809'].touched && TannerClinicFroms.controls['2809'].invalid">
                                <small class="text-danger" *ngIf="TannerClinicFroms.controls['2809'].errors?.required">
                                    required
                                </small>
                            </div>
                        </div>    
                    </div>   
                </td>
            </tr>         
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">*Address:</label> 
                    </div>   
                </td>
                <td colspan="6">
                    <div class="row">
                       <div class="col-md-6">
                                <input class="form-control onboard"  formControlName="2794"
                                id="2794" name="2794" type="text" placeholder= "Reference 5 Add1" [class.is-invalid]="(TannerClinicFroms.controls['2794'].touched && TannerClinicFroms.controls['2794'].invalid) || (TannerClinicFroms.controls['2794'].invalid && isSubmit)" data-id="managing_contact_name" >
                            <div class="formLabel col-md-11"
                                *ngIf="TannerClinicFroms.controls['2794'].touched && TannerClinicFroms.controls['2794'].invalid">
                                <small class="text-danger" *ngIf="TannerClinicFroms.controls['2794'].errors?.required">
                                    required
                                </small>
                            </div>
                       </div>
                       <div class="col-md-6">
                                <input class="form-control onboard"  formControlName="2795"
                                id="2795" name="2795" type="text" placeholder= "Reference 5 Add2"  data-id="managing_contact_name" >
                           
                                
                        </div>
                    </div>   
                </td>
            </tr>         
            <tr>
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">*City, State, Zip:</label> 
                    </div>   
                </td>
                <td colspan="6">
                    <div class="row">
                       <div class="col-md-4">
                                <input class="form-control onboard"  formControlName="2796"
                                id="2796" name="2796" type="text" placeholder= "Reference 5 City" [class.is-invalid]="(TannerClinicFroms.controls['2796'].touched && TannerClinicFroms.controls['2796'].invalid) || (TannerClinicFroms.controls['2796'].invalid && isSubmit)" data-id="managing_contact_name" >
                            <div class="formLabel col-md-11"
                                *ngIf="TannerClinicFroms.controls['2796'].touched && TannerClinicFroms.controls['2796'].invalid">
                                <small class="text-danger" *ngIf="TannerClinicFroms.controls['2796'].errors?.required">
                                    required
                                </small>
                            </div>
                       </div>
                       <div class="col-md-4">
                                <ng-select
                                [items]="states"
                                bindLabel="name"
                                placeholder="Enter State"
                                bindValue="id"
                                formControlName="2798"
                                [class.is-invalid]="(TannerClinicFroms.controls['2798'].touched && TannerClinicFroms.controls['2798'].invalid) || (TannerClinicFroms.controls['2798'].invalid && isSubmit)"
                                >
                            </ng-select> 
                            <div class="formLabel col-md-11"
                                *ngIf="TannerClinicFroms.controls['2798'].touched && TannerClinicFroms.controls['2798'].invalid">
                                <small class="text-danger" *ngIf="TannerClinicFroms.controls['2798'].errors?.required">
                                    required
                                </small>
                            </div>
                           
                        </div>
                        <div class="col-md-4">
                                <input class="form-control onboard"  formControlName="2799"
                                id="2799" name="2799" type="text" placeholder= "Reference 5 Zip" [class.is-invalid]="(TannerClinicFroms.controls['2799'].touched && TannerClinicFroms.controls['2799'].invalid) || (TannerClinicFroms.controls['2799'].invalid && isSubmit)" data-id="managing_contact_name" >
                            <div class="formLabel col-md-11"
                                *ngIf="TannerClinicFroms.controls['2799'].touched && TannerClinicFroms.controls['2799'].invalid">
                                <small class="text-danger" *ngIf="TannerClinicFroms.controls['2799'].errors?.required">
                                    required
                                </small>
                            </div>
                        </div>
                    </div>   
                </td>
            </tr>  
            <tr>    
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Phone:</label> 
                    </div>  
                </td> 
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="2800" (keydown)="handleNumber($event,2800)" maxlength="12" [class.is-invalid]="(TannerClinicFroms.controls['2800'].touched && TannerClinicFroms.controls['2800'].invalid) || (TannerClinicFroms.controls['2800'].invalid && isSubmit)"
                            id="2800" name="2800" type="text" placeholder= "Reference 5 Phone "   data-id="managing_contact_name" >
                            <div class="mandatory" *ngIf="
                                    TannerClinicFroms.controls['2800'].touched &&
                                    TannerClinicFroms.controls['2800'].invalid ">
                                      <small class="text-danger" *ngIf="TannerClinicFroms.controls['2800'].errors?.required">
                                         Required 
                                       </small>
                                       <small class="text-danger" *ngIf="TannerClinicFroms.controls['2800'].errors?.pattern">
                                        Required pattern xxx-xxx-xxxx
                                       </small>
                                   </div>
                        </div>
                    </div>
                </td>         
            </tr>     
            <tr>    
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Fax:</label> 
                    </div>  
                </td> 
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="2811"
                                    id="2811" name="2811" type="text" placeholder= "Reference 5 Fax "   data-id="managing_contact_name" >
                                
                        </div>
                    </div>
                </td>         
            </tr>         
            <tr>    
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">e-mail address:</label> 
                    </div>  
                </td> 
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="2812" [class.is-invalid]="(TannerClinicFroms.controls['2812'].touched && TannerClinicFroms.controls['2812'].invalid) || (TannerClinicFroms.controls['2812'].invalid && isSubmit)"
                                    id="2812" name="2812" type="text" placeholder= "Reference 5 Email "   data-id="managing_contact_name" >
                             
                                <div class="formLabel col-md-11"
                                    *ngIf="TannerClinicFroms.controls['2812'].touched && TannerClinicFroms.controls['2812'].invalid">
                                    <small class="text-danger" *ngIf="TannerClinicFroms.controls['2812'].errors?.required">
                                        required
                                    </small>
                                </div>    
                        </div>
                    </div>
                </td>         
            </tr> 
            <tr>    
                <td colspan="12">
                    <div class="row">
                        <label class="formLabel">Professional Affiliations:</label> 
                    </div>  
                </td> 
                   
            </tr> 
            <tr>    
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Name:</label> 
                    </div>  
                </td> 
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="cmd_01781"
                                    id="cmd_01781" name="cmd_01781" type="text" placeholder= "Enter Name"   data-id="managing_contact_name" >
                                
                        </div>
                    </div>
                </td>         
            </tr> 
            <tr>    
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Number:</label> 
                    </div>  
                </td> 
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="cmd_01782"
                                    id="cmd_01782" name="cmd_01782" type="text" placeholder= "Enter Number"   data-id="managing_contact_name" >
                                    <small class="text-danger" *ngIf="TannerClinicFroms.controls['cmd_01782'].errors?.pattern">
                                        Allow only numbers</small>   
                        </div>
                    </div>
                </td>         
            </tr> 
            <tr> 
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Effective Date:</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="input-group">
                                <input type="text" placeholder="Effective Date" ngbDatepicker #d01783="ngbDatepicker" id="cmd_01783" class="form-control" formControlName="cmd_01783" (blur)="clearInputIfInvalid('cmd_01783')"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d01783.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div>
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('cmd_01783').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY)</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('cmd_01783').errors?.invalidMonth">
                                  Month is out of range</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('cmd_01783').errors?.invalidDateyear">
                                    year is out of range(1900-2099)</small>
                                    <small class="text-danger" *ngIf="TannerClinicFroms.get('cmd_01783').errors?.invalidDateRange">
                                      date is out of range</small>
                            </div>
                        </div>
                    </div>     
                </td>    
            </tr>  
            <tr> 
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Exp Date:</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="input-group">
                                <input type="text" placeholder="Exp Date" ngbDatepicker #d01784="ngbDatepicker" id="cmd_01784" class="form-control" formControlName="cmd_01784" (blur)="clearInputIfInvalid('cmd_01784')"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d01784.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div>
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('cmd_01784').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY)</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('cmd_01784').errors?.invalidMonth">
                                  Month is out of range</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('cmd_01784').errors?.invalidDateyear">
                                    year is out of range(1900-2099)</small>
                                    <small class="text-danger" *ngIf="TannerClinicFroms.get('cmd_01784').errors?.invalidDateRange">
                                      date is out of range</small>
                            </div>
                        </div>
                    </div>     
                </td>    
            </tr>  
            <tr>    
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Name:</label> 
                    </div>  
                </td> 
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="cmd_01785"
                                    id="cmd_01785" name="cmd_01785" type="text" placeholder= "Enter Name"   data-id="managing_contact_name" >
                                
                        </div>
                    </div>
                </td>         
            </tr> 
            <tr>    
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Number:</label> 
                    </div>  
                </td> 
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <input class="form-control onboard"  formControlName="cmd_01786"
                                    id="cmd_01786" name="cmd_01786" type="text" placeholder= "Enter Number"   data-id="managing_contact_name" >
                                    <small class="text-danger" *ngIf="TannerClinicFroms.controls['cmd_01786'].errors?.pattern">
                                        Allow only numbers</small>   
                        </div>
                    </div>
                </td>         
            </tr> 
            <tr> 
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Effective Date:</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="input-group">
                                <input type="text" placeholder="Effective Date" ngbDatepicker #d01787="ngbDatepicker" id="cmd_01787" class="form-control" formControlName="cmd_01787" (blur)="clearInputIfInvalid('cmd_01787')"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d01787.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div>
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('cmd_01787').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY)</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('cmd_01787').errors?.invalidMonth">
                                  Month is out of range</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('cmd_01787').errors?.invalidDateyear">
                                    year is out of range(1900-2099)</small>
                                    <small class="text-danger" *ngIf="TannerClinicFroms.get('cmd_01787').errors?.invalidDateRange">
                                      date is out of range</small>
                            </div>
                        </div>
                    </div>     
                </td>    
            </tr>  
            <tr> 
                <td colspan="6">
                    <div class="row">
                        <label class="formLabel">Exp Date:</label> 
                    </div>                    
                </td>  
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="input-group">
                                <input type="text" placeholder="Exp Date" ngbDatepicker #d01788="ngbDatepicker" id="cmd_01788" class="form-control" formControlName="cmd_01788" (blur)="clearInputIfInvalid('cmd_01788')"
                                >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d01788.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>
                            <div>
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('cmd_01788').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY)</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('cmd_01788').errors?.invalidMonth">
                                  Month is out of range</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('cmd_01788').errors?.invalidDateyear">
                                    year is out of range(1900-2099)</small>
                                    <small class="text-danger" *ngIf="TannerClinicFroms.get('cmd_01788').errors?.invalidDateRange">
                                      date is out of range</small>
                            </div>
                        </div>
                    </div>     
                </td>    
            </tr> 
            <tr>
                <td colspan="12">
                    <b style="font-size:16px;">Disclorsure Questions</b>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <table style="width:100%;"  border="1">
                        <tr>
                            <td colspan="7">
                                <b style="font-size:14px;">Question</b>
                            </td>
                            <td colspan="5">
                                <b style="font-size:14px;">Answer</b>
                            </td>
                        </tr>
                        <tr>
                            
                            <td colspan="7" width="75%">
                            <div class="form-group form-inline no-margin sideLabelAlignment">
                                <label class="formLabel"
                                >1. Has your license to practice, in your profession, ever been
                                denied, suspended, revoked, restricted,voluntarily surrendered
                                while under investigation, or have you ever been subject to a
                                consent order,probation or any conditions or limitations by
                                any state licensing board?
                                </label>
                            </div>
                            </td>
                            <td colspan="5" width="20%">
                                <div class="form-group form-inline no-margin sideLabelAlignment">
                                <label class="radio-inline radioStyle">
                                <input
                                    type="radio"
                                    id="cmd_0232"
                                    [class.is-invalid]="
                                    (TannerClinicFroms.controls['cmd_0232'].touched &&
                                        TannerClinicFroms.controls['cmd_0232'].errors) ||
                                    (TannerClinicFroms.controls['cmd_0232'].errors && isSubmit)
                                    "
                                    name="cmd_0232"
                                    formControlName="cmd_0232"
                                    value="1"
                                    (change)="validationsDataAdd($event)"
                                />Yes</label
                                >
                                <label class="radio-inline radioStyle">
                                <input
                                    type="radio"
                                    id="cmd_0232"
                                    [class.is-invalid]="
                                    (TannerClinicFroms.controls['cmd_0232'].touched &&
                                        TannerClinicFroms.controls['cmd_0232'].errors) ||
                                    (TannerClinicFroms.controls['cmd_0232'].errors && isSubmit)
                                    "
                                    name="cmd_0232"
                                    formControlName="cmd_0232"
                                    value="0"
                                    (change)="validationsDataAdd($event)"
                                />No</label
                                >
                                <div
                                class="formLabel col-md-2"
                                    *ngIf="
                                        TannerClinicFroms.controls['cmd_0232'].touched &&
                                        TannerClinicFroms.controls['cmd_0232'].errors
                                    "
                                    >
                                    <small
                                        class="text-danger"
                                        *ngIf="TannerClinicFroms.controls['cmd_0232'].errors?.required"
                                    >
                                        required
                                    </small>
                                </div>
                                    <span
                                    *ngIf="
                                        (TannerClinicFroms.controls['cmd_0232'].touched &&
                                        TannerClinicFroms.controls['cmd_0232'].errors) ||
                                        (TannerClinicFroms.controls['cmd_0232'].errors && isSubmit)
                                    "
                                    >
                                    <i class="fas fa-times ml-2 text-danger"></i>
                                    </span>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            
                            <td colspan="7" width="75%">
                            <div class="form-group form-inline no-margin sideLabelAlignment">
                                <label class="formLabel"
                                >2.Have you ever received a reprimand or been fined by any
                                state licensing board?
                                </label>
                            </div>
                            </td>
                            <td colspan="5" width="20%">
                                <div class="form-group form-inline no-margin sideLabelAlignment">
                                <label class="radio-inline radioStyle">
                                <input
                                    type="radio"
                                    id="cmd_0233"
                                    [class.is-invalid]="
                                    (TannerClinicFroms.controls['cmd_0233'].touched &&
                                        TannerClinicFroms.controls['cmd_0233'].errors) ||
                                    (TannerClinicFroms.controls['cmd_0233'].errors && isSubmit)
                                    "
                                    name="cmd_0233"
                                    formControlName="cmd_0233"
                                    value="1"
                                    (change)="validationsDataAdd($event)"
                                />Yes</label
                                >
                                <label class="radio-inline radioStyle">
                                <input
                                    type="radio"
                                    id="cmd_0233"
                                    [class.is-invalid]="
                                    (TannerClinicFroms.controls['cmd_0233'].touched &&
                                        TannerClinicFroms.controls['cmd_0233'].errors) ||
                                    (TannerClinicFroms.controls['cmd_0233'].errors && isSubmit)
                                    "
                                    name="cmd_0233"
                                    formControlName="cmd_0233"
                                    value="0"
                                    (change)="validationsDataAdd($event)"
                                />No</label
                                >
                                <div
                                class="formLabel col-md-2"
                                    *ngIf="
                                        TannerClinicFroms.controls['cmd_0233'].touched &&
                                        TannerClinicFroms.controls['cmd_0233'].errors
                                    "
                                    >
                                    <small
                                        class="text-danger"
                                        *ngIf="TannerClinicFroms.controls['cmd_0233'].errors?.required"
                                    >
                                        required
                                    </small>
                                </div>
                                    <span
                                    *ngIf="
                                        (TannerClinicFroms.controls['cmd_0233'].touched &&
                                        TannerClinicFroms.controls['cmd_0233'].errors) ||
                                        (TannerClinicFroms.controls['cmd_0233'].errors && isSubmit)
                                    "
                                    >
                                    <i class="fas fa-times ml-2 text-danger"></i>
                                    </span>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            
                            <td colspan="7" width="75%">
                            <div class="form-group form-inline no-margin sideLabelAlignment">
                                <label class="formLabel"
                                >3. Have your clinical privileges or Medical Staff membership at
                                any hospital or healthcare institution ever been denied,
                                suspended, revoked, restricted, denied renewal or subject to
                                probationary or to other disciplinary conditions (for reasons
                                other than non-completion of medical records when quality of
                                care was not adversely affected) or have proceedings toward
                                any of those ends been instituted or recommended by any
                                hospital or healthcare institution, medical staff or
                                committee, or governing board?
                                </label>
                            </div>
                            </td>
                            <td colspan="5" width="20%">
                            <div class="form-group form-inline no-margin sideLabelAlignment">
                                <label class="radio-inline radioStyle">
                                <input
                                    type="radio"
                                    id="cmd_0234"
                                    [class.is-invalid]="
                                    (TannerClinicFroms.controls['cmd_0234'].touched &&
                                        TannerClinicFroms.controls['cmd_0234'].errors) ||
                                    (TannerClinicFroms.controls['cmd_0234'].errors && isSubmit)
                                    "
                                    name="cmd_0234"
                                    formControlName="cmd_0234"
                                    value="1"
                                    (change)="validationsDataAdd($event)"
                                />Yes</label
                                >
                                <label class="radio-inline radioStyle">
                                <input
                                    type="radio"
                                    id="cmd_0234"
                                    [class.is-invalid]="
                                    (TannerClinicFroms.controls['cmd_0234'].touched &&
                                        TannerClinicFroms.controls['cmd_0234'].errors) ||
                                    (TannerClinicFroms.controls['cmd_0234'].errors && isSubmit)
                                    "
                                    name="cmd_0234"
                                    formControlName="cmd_0234"
                                    value="0"
                                    (change)="validationsDataAdd($event)"
                                />No</label
                                >
                                <div
                                class="formLabel col-md-2"
                                *ngIf="
                                    TannerClinicFroms.controls['cmd_0234'].touched &&
                                    TannerClinicFroms.controls['cmd_0234'].errors
                                "
                                >
                                <small
                                    class="text-danger"
                                    *ngIf="TannerClinicFroms.controls['cmd_0234'].errors?.required"
                                >
                                    required
                                </small>
                                </div>
                                <span
                                *ngIf="
                                    (TannerClinicFroms.controls['cmd_0234'].touched &&
                                    TannerClinicFroms.controls['cmd_0234'].errors) ||
                                    (TannerClinicFroms.controls['cmd_0234'].errors && isSubmit)
                                "
                                >
                                <i class="fas fa-times ml-2 text-danger"></i>
                                </span>
                            </div>
                            </td>
                        </tr>
                        <tr>
                           
                            <td colspan="7" width="75%">
                              <div class="form-group form-inline no-margin sideLabelAlignment">
                                <label class="formLabel"
                                  >4. Have you voluntarily surrendered, limited your privileges or
                                  not reapplied for privileges while under investigation?</label
                                >
                              </div>
                            </td>
                            <td colspan="5" width="20%">
                              <div class="form-group form-inline no-margin sideLabelAlignment">
                                <label class="radio-inline radioStyle">
                                  <input
                                    type="radio"
                                    id="cmd_0235"
                                    [class.is-invalid]="
                                      (TannerClinicFroms.controls['cmd_0235'].touched &&
                                        TannerClinicFroms.controls['cmd_0235'].errors) ||
                                      (TannerClinicFroms.controls['cmd_0235'].errors && isSubmit)
                                    "
                                    name="cmd_0235"
                                    formControlName="cmd_0235"
                                    value="1"
                                    (change)="validationsDataAdd($event)"
                                  />Yes</label
                                >
                                <label class="radio-inline radioStyle">
                                  <input
                                    type="radio"
                                    id="cmd_0235"
                                    [class.is-invalid]="
                                      (TannerClinicFroms.controls['cmd_0235'].touched &&
                                        TannerClinicFroms.controls['cmd_0235'].errors) ||
                                      (TannerClinicFroms.controls['cmd_0235'].errors && isSubmit)
                                    "
                                    name="cmd_0235"
                                    formControlName="cmd_0235"
                                    value="0"
                                    (change)="validationsDataAdd($event)"
                                  />No</label
                                >
                                <div
                                  class="formLabel col-md-2"
                                  *ngIf="
                                    TannerClinicFroms.controls['cmd_0235'].touched &&
                                    TannerClinicFroms.controls['cmd_0235'].errors
                                  "
                                >
                                  <small
                                    class="text-danger"
                                    *ngIf="TannerClinicFroms.controls['cmd_0235'].errors?.required"
                                  >
                                    required
                                  </small>
                                </div>
                                <span
                                  *ngIf="
                                    (TannerClinicFroms.controls['cmd_0235'].touched &&
                                      TannerClinicFroms.controls['cmd_0235'].errors) ||
                                    (TannerClinicFroms.controls['cmd_0235'].errors && isSubmit)
                                  "
                                >
                                  <i class="fas fa-times ml-2 text-danger"></i>
                                </span>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            
                        
                            <td colspan="7" width="75%">
                              <div class="form-group form-inline no-margin sideLabelAlignment">
                                <label class="formLabel"
                                  >5. Have you ever been terminated for cause or not renewed for
                                  cause from participation, or been subject to any disciplinary
                                  action, by any managed care organizations (including HMOs,
                                  PPOs, or provider organizations such as IPAs, PHOs)?
                                </label>
                              </div>
                            </td>
                            <td colspan="5" width="20%">
                              <div class="form-group form-inline no-margin sideLabelAlignment">
                                <label class="radio-inline radioStyle">
                                  <input
                                    type="radio"
                                    id="cmd_0236"
                                    [class.is-invalid]="
                                      (TannerClinicFroms.controls['cmd_0236'].touched &&
                                        TannerClinicFroms.controls['cmd_0236'].errors) ||
                                      (TannerClinicFroms.controls['cmd_0236'].errors && isSubmit)
                                    "
                                    name="cmd_0236"
                                    formControlName="cmd_0236"
                                    value="1"
                                    (change)="validationsDataAdd($event)"
                                  />Yes</label
                                >
                                <label class="radio-inline radioStyle">
                                  <input
                                    type="radio"
                                    id="cmd_0236"
                                    [class.is-invalid]="
                                      (TannerClinicFroms.controls['cmd_0236'].touched &&
                                        TannerClinicFroms.controls['cmd_0236'].errors) ||
                                      (TannerClinicFroms.controls['cmd_0236'].errors && isSubmit)
                                    "
                                    name="cmd_0236"
                                    formControlName="cmd_0236"
                                    value="0"
                                    (change)="validationsDataAdd($event)"
                                  />No</label
                                >
                                <div
                                  class="formLabel col-md-2"
                                  *ngIf="
                                    TannerClinicFroms.controls['cmd_0236'].touched &&
                                    TannerClinicFroms.controls['cmd_0236'].errors
                                  "
                                >
                                  <small
                                    class="text-danger"
                                    *ngIf="TannerClinicFroms.controls['cmd_0236'].errors?.required"
                                  >
                                    required
                                  </small>
                                </div>
                                <span
                                  *ngIf="
                                    (TannerClinicFroms.controls['cmd_0236'].touched &&
                                      TannerClinicFroms.controls['cmd_0236'].errors) ||
                                    (TannerClinicFroms.controls['cmd_0236'].errors && isSubmit)
                                  "
                                >
                                  <i class="fas fa-times ml-2 text-danger"></i>
                                </span>
                              </div>
                            </td>
                          </tr>
                         
                          <tr>
                           
                            <td colspan="7" width="75%">
                              <div class="form-group form-inline no-margin sideLabelAlignment">
                                <label class="formLabel"
                                  >6. Were you ever placed on probation, disciplined, formally
                                  reprimanded, suspended or asked to resign during an
                                  internship, residency, fellowship, preceptorship or other
                                  clinical education program? If you are currently in a
                                  training program, have you been placed on probation,
                                  discipline d, formally reprimanded, suspended or asked to
                                  resign?
                                </label>
                              </div>
                            </td>
                            <td colspan="5" width="20%">
                              <div class="form-group form-inline no-margin sideLabelAlignment">
                                <label class="radio-inline radioStyle">
                                  <input
                                    type="radio"
                                    id="cmd_0237"
                                    [class.is-invalid]="
                                      (TannerClinicFroms.controls['cmd_0237'].touched &&
                                        TannerClinicFroms.controls['cmd_0237'].errors) ||
                                      (TannerClinicFroms.controls['cmd_0237'].errors && isSubmit)
                                    "
                                    name="cmd_0237"
                                    formControlName="cmd_0237"
                                    value="1"
                                  />Yes</label
                                >
                                <label class="radio-inline radioStyle">
                                  <input
                                    type="radio"
                                    id="cmd_0237"
                                    [class.is-invalid]="
                                      (TannerClinicFroms.controls['cmd_0237'].touched &&
                                        TannerClinicFroms.controls['cmd_0237'].errors) ||
                                      (TannerClinicFroms.controls['cmd_0237'].errors && isSubmit)
                                    "
                                    name="cmd_0237"
                                    formControlName="cmd_0237"
                                    value="0"
                                    
                                  />No</label
                                >
                                <div
                                  class="formLabel col-md-2"
                                  *ngIf="
                                    TannerClinicFroms.controls['cmd_0237'].touched &&
                                    TannerClinicFroms.controls['cmd_0237'].errors
                                  "
                                >
                                  <small
                                    class="text-danger"
                                    *ngIf="TannerClinicFroms.controls['cmd_0237'].errors?.required"
                                  >
                                    required
                                  </small>
                                </div>
                                <span
                                  *ngIf="
                                    (TannerClinicFroms.controls['cmd_0237'].touched &&
                                      TannerClinicFroms.controls['cmd_0237'].errors) ||
                                    (TannerClinicFroms.controls['cmd_0237'].errors && isSubmit)
                                  "
                                >
                                  <i class="fas fa-times ml-2 text-danger"></i>
                                </span>
                              </div>
                            </td>
                          </tr>
                          <tr>
                        
                            <td colspan="7" width="75%">
                              <div class="form-group form-inline no-margin sideLabelAlignment">
                                <label class="formLabel">
                                  7. Have you ever, while under investigation, voluntarily
                                  withdrawn or prematurely terminated your status as a student
                                  or employee in any internship, residency, fellowship,
                                  preceptorship, or other clinical education program?
                                </label>
                              </div>
                            </td>
                            <td colspan="5" width="20%">
                              <div class="form-group form-inline no-margin sideLabelAlignment">
                                <label class="radio-inline radioStyle">
                                  <input
                                    type="radio"
                                    id="cmd_0238"
                                    [class.is-invalid]="
                                      (TannerClinicFroms.controls['cmd_0238'].touched &&
                                        TannerClinicFroms.controls['cmd_0238'].errors) ||
                                      (TannerClinicFroms.controls['cmd_0238'].errors && isSubmit)
                                    "
                                    name="cmd_0238"
                                    formControlName="cmd_0238"
                                    value="1"
                                    (change)="validationsDataAdd($event)"
                                  />Yes</label
                                >
                                <label class="radio-inline radioStyle">
                                  <input
                                    type="radio"
                                    id="cmd_0238"
                                    [class.is-invalid]="
                                      (TannerClinicFroms.controls['cmd_0238'].touched &&
                                        TannerClinicFroms.controls['cmd_0238'].errors) ||
                                      (TannerClinicFroms.controls['cmd_0238'].errors && isSubmit)
                                    "
                                    name="cmd_0238"
                                    formControlName="cmd_0238"
                                    value="0"
                                    (change)="validationsDataAdd($event)"
                                  />No</label
                                >
                                <div
                                  class="formLabel col-md-2"
                                  *ngIf="
                                    TannerClinicFroms.controls['cmd_0238'].touched &&
                                    TannerClinicFroms.controls['cmd_0238'].errors
                                  "
                                >
                                  <small
                                    class="text-danger"
                                    *ngIf="TannerClinicFroms.controls['cmd_0238'].errors?.required"
                                  >
                                    required
                                  </small>
                                </div>
                                <span
                                  *ngIf="
                                    (TannerClinicFroms.controls['cmd_0238'].touched &&
                                      TannerClinicFroms.controls['cmd_0238'].errors) ||
                                    (TannerClinicFroms.controls['cmd_0238'].errors && isSubmit)
                                  "
                                >
                                  <i class="fas fa-times ml-2 text-danger"></i>
                                </span>
                              </div>
                            </td>
                          </tr>
                          <tr>
                          
                            <td colspan="7" width="75%">
                              <div class="form-group form-inline no-margin sideLabelAlignment">
                                <label class="formLabel"
                                  >8. Have any of your board certifications or eligibility ever
                                  been revoked?
                                </label>
                              </div>
                            </td>
                            <td colspan="5" width="20%">
                              <div class="form-group form-inline no-margin sideLabelAlignment">
                                <label class="radio-inline radioStyle">
                                  <input
                                    type="radio"
                                    id="cmd_0239"
                                    [class.is-invalid]="
                                      (TannerClinicFroms.controls['cmd_0239'].touched &&
                                        TannerClinicFroms.controls['cmd_0239'].errors) ||
                                      (TannerClinicFroms.controls['cmd_0239'].errors && isSubmit)
                                    "
                                    name="cmd_0239"
                                    formControlName="cmd_0239"
                                    value="1"
                                    (change)="validationsDataAdd($event)"
                                  />Yes</label
                                >
                                <label class="radio-inline radioStyle">
                                  <input
                                    type="radio"
                                    id="cmd_0239"
                                    [class.is-invalid]="
                                      (TannerClinicFroms.controls['cmd_0239'].touched &&
                                        TannerClinicFroms.controls['cmd_0239'].errors) ||
                                      (TannerClinicFroms.controls['cmd_0239'].errors && isSubmit)
                                    "
                                    name="cmd_0239"
                                    formControlName="cmd_0239"
                                    value="0"
                                    (change)="validationsDataAdd($event)"
                                  />No</label
                                >
                                <div
                                  class="formLabel col-md-2"
                                  *ngIf="
                                    TannerClinicFroms.controls['cmd_0239'].touched &&
                                    TannerClinicFroms.controls['cmd_0239'].errors
                                  "
                                >
                                  <small
                                    class="text-danger"
                                    *ngIf="TannerClinicFroms.controls['cmd_0239'].errors?.required"
                                  >
                                    required
                                  </small>
                                </div>
                                <span
                                  *ngIf="
                                    (TannerClinicFroms.controls['cmd_0239'].touched &&
                                      TannerClinicFroms.controls['cmd_0239'].errors) ||
                                    (TannerClinicFroms.controls['cmd_0239'].errors && isSubmit)
                                  "
                                >
                                  <i class="fas fa-times ml-2 text-danger"></i>
                                </span>
                              </div>
                            </td>
                          </tr>
                          <tr>
                          
                            <td colspan="7" width="75%">
                              <div class="form-group form-inline no-margin sideLabelAlignment">
                                <label class="formLabel"
                                  >9. Have you ever chosen not to re-certify or voluntarily
                                  surrendered your board certification(s) while under
                                  investigation?
                                </label>
                              </div>
                            </td>
                            <td colspan="5" width="20%">
                              <div class="form-group form-inline no-margin sideLabelAlignment">
                                <label class="radio-inline radioStyle">
                                  <input
                                    type="radio"
                                    id="cmd_0240"
                                    [class.is-invalid]="
                                      (TannerClinicFroms.controls['cmd_0240'].touched &&
                                        TannerClinicFroms.controls['cmd_0240'].errors) ||
                                      (TannerClinicFroms.controls['cmd_0240'].errors && isSubmit)
                                    "
                                    name="cmd_0240"
                                    formControlName="cmd_0240"
                                    value="1"
                                    (change)="validationsDataAdd($event)"
                                  />Yes</label
                                >
                                <label class="radio-inline radioStyle">
                                  <input
                                    type="radio"
                                    id="cmd_0240"
                                    [class.is-invalid]="
                                      (TannerClinicFroms.controls['cmd_0240'].touched &&
                                        TannerClinicFroms.controls['cmd_0240'].errors) ||
                                      (TannerClinicFroms.controls['cmd_0240'].errors && isSubmit)
                                    "
                                    name="cmd_0240"
                                    formControlName="cmd_0240"
                                    value="0"
                                    (change)="validationsDataAdd($event)"
                                  />No</label
                                >
                                <div
                                  class="formLabel col-md-2"
                                  *ngIf="
                                    TannerClinicFroms.controls['cmd_0240'].touched &&
                                    TannerClinicFroms.controls['cmd_0240'].errors
                                  "
                                >
                                  <small
                                    class="text-danger"
                                    *ngIf="TannerClinicFroms.controls['cmd_0240'].errors?.required"
                                  >
                                    required
                                  </small>
                                </div>
                                <span
                                  *ngIf="
                                    (TannerClinicFroms.controls['cmd_0240'].touched &&
                                      TannerClinicFroms.controls['cmd_0240'].errors) ||
                                    (TannerClinicFroms.controls['cmd_0240'].errors && isSubmit)
                                  "
                                >
                                  <i class="fas fa-times ml-2 text-danger"></i>
                                </span>
                              </div>
                            </td>
                          </tr>
                        
                          <tr>
                         
                            <td colspan="7" width="75%">
                              <div class="form-group form-inline no-margin sideLabelAlignment">
                                <label class="formLabel"
                                  >10. Have your Federal DEA and/or Controlled Substances
                                  Certificate(s) or authorization(s) ever been denied,
                                  suspended, revoked, restricted, denied renewal, or voluntarily
                                  relinquished?</label
                                >
                              </div>
                            </td>
                            <td colspan="5" width="20%">
                              <div class="form-group form-inline no-margin sideLabelAlignment">
                                <label class="radio-inline radioStyle">
                                  <input
                                    type="radio"
                                    id="cmd_0241"
                                    [class.is-invalid]="
                                      (TannerClinicFroms.controls['cmd_0241'].touched &&
                                        TannerClinicFroms.controls['cmd_0241'].errors) ||
                                      (TannerClinicFroms.controls['cmd_0241'].errors && isSubmit)
                                    "
                                    name="cmd_0241"
                                    formControlName="cmd_0241"
                                    value="1"
                                    (change)="validationsDataAdd($event)"
                                  />Yes</label
                                >
                                <label class="radio-inline radioStyle">
                                  <input
                                    type="radio"
                                    id="cmd_0241"
                                    [class.is-invalid]="
                                      (TannerClinicFroms.controls['cmd_0241'].touched &&
                                        TannerClinicFroms.controls['cmd_0241'].errors) ||
                                      (TannerClinicFroms.controls['cmd_0241'].errors && isSubmit)
                                    "
                                    name="cmd_0241"
                                    formControlName="cmd_0241"
                                    value="0"
                                    (change)="validationsDataAdd($event)"
                                  />No</label
                                >
                                <div
                                  class="formLabel col-md-2"
                                  *ngIf="
                                    TannerClinicFroms.controls['cmd_0241'].touched &&
                                    TannerClinicFroms.controls['cmd_0241'].errors
                                  "
                                >
                                  <small
                                    class="text-danger"
                                    *ngIf="TannerClinicFroms.controls['cmd_0241'].errors?.required"
                                  >
                                    required
                                  </small>
                                </div>
                                <span
                                  *ngIf="
                                    (TannerClinicFroms.controls['cmd_0241'].touched &&
                                      TannerClinicFroms.controls['cmd_0241'].errors) ||
                                    (TannerClinicFroms.controls['cmd_0241'].errors && isSubmit)
                                  "
                                >
                                  <i class="fas fa-times ml-2 text-danger"></i>
                                </span>
                              </div>
                            </td>
                          </tr>
                          
                          <tr>
                          
                            <td colspan="7" width="75%">
                              <div class="form-group form-inline no-margin sideLabelAlignment">
                                <label class="formLabel"
                                  >11. Have you ever been disciplined, excluded from, debarred,
                                  suspended, reprimanded, sanctioned, censured, disqualified or
                                  otherwise restricted in regard to participation in the
                                  Medicare or Medicaid program, or in regard to other federal or
                                  state governmental health care plans or programs?
                                </label>
                              </div>
                            </td>
                            <td colspan="5" width="20%">
                              <div class="form-group form-inline no-margin sideLabelAlignment">
                                <label class="radio-inline radioStyle">
                                  <input
                                    type="radio"
                                    id="cmd_0242"
                                    [class.is-invalid]="
                                      (TannerClinicFroms.controls['cmd_0242'].touched &&
                                        TannerClinicFroms.controls['cmd_0242'].errors) ||
                                      (TannerClinicFroms.controls['cmd_0242'].errors && isSubmit)
                                    "
                                    name="cmd_0242"
                                    formControlName="cmd_0242"
                                    value="1"
                                    (change)="validationsDataAdd($event)"
                                  />Yes</label
                                >
                                <label class="radio-inline radioStyle">
                                  <input
                                    type="radio"
                                    id="cmd_0242"
                                    [class.is-invalid]="
                                      (TannerClinicFroms.controls['cmd_0242'].touched &&
                                        TannerClinicFroms.controls['cmd_0242'].errors) ||
                                      (TannerClinicFroms.controls['cmd_0242'].errors && isSubmit)
                                    "
                                    name="cmd_0242"
                                    formControlName="cmd_0242"
                                    value="0"
                                    (change)="validationsDataAdd($event)"
                                  />No</label
                                >
                                <div
                                  class="formLabel col-md-2"
                                  *ngIf="
                                    TannerClinicFroms.controls['cmd_0242'].touched &&
                                    TannerClinicFroms.controls['cmd_0242'].errors
                                  "
                                >
                                  <small
                                    class="text-danger"
                                    *ngIf="TannerClinicFroms.controls['cmd_0242'].errors?.required"
                                  >
                                    required
                                  </small>
                                </div>
                                <span
                                  *ngIf="
                                    (TannerClinicFroms.controls['cmd_0242'].touched &&
                                      TannerClinicFroms.controls['cmd_0242'].errors) ||
                                    (TannerClinicFroms.controls['cmd_0242'].errors && isSubmit)
                                  "
                                >
                                  <i class="fas fa-times ml-2 text-danger"></i>
                                </span>
                              </div>
                            </td>
                          </tr>
                         
                          <tr>
                           
                            <td colspan="7" width="75%">
                              <div class="form-group form-inline no-margin sideLabelAlignment">
                                <label class="formLabel"
                                  >12. Are you currently or have you ever been the subject of an
                                  investigation by any hospital, licensing authority, DEA or CDS
                                  authorizing entities, education or training program, Medicare
                                  or Medicaid program, or any other private, federal or state
                                  health program?
                                </label>
                              </div>
                            </td>
                            <td colspan="5" width="20%">
                              <div class="form-group form-inline no-margin sideLabelAlignment">
                                <label class="radio-inline radioStyle">
                                  <input
                                    type="radio"
                                    id="cmd_0243"
                                    [class.is-invalid]="
                                      (TannerClinicFroms.controls['cmd_0243'].touched &&
                                        TannerClinicFroms.controls['cmd_0243'].errors) ||
                                      (TannerClinicFroms.controls['cmd_0243'].errors && isSubmit)
                                    "
                                    name="cmd_0243"
                                    formControlName="cmd_0243"
                                    value="1"
                                    (change)="validationsDataAdd($event)"
                                  />Yes</label
                                >
                                <label class="radio-inline radioStyle">
                                  <input
                                    type="radio"
                                    id="cmd_0243"
                                    [class.is-invalid]="
                                      (TannerClinicFroms.controls['cmd_0243'].touched &&
                                        TannerClinicFroms.controls['cmd_0243'].errors) ||
                                      (TannerClinicFroms.controls['cmd_0243'].errors && isSubmit)
                                    "
                                    name="cmd_0243"
                                    formControlName="cmd_0243"
                                    value="0"
                                    (change)="validationsDataAdd($event)"
                                  />No</label
                                >
                                <div
                                  class="formLabel col-md-2"
                                  *ngIf="
                                    TannerClinicFroms.controls['cmd_0243'].touched &&
                                    TannerClinicFroms.controls['cmd_0243'].errors
                                  "
                                >
                                  <small
                                    class="text-danger"
                                    *ngIf="TannerClinicFroms.controls['cmd_0243'].errors?.required"
                                  >
                                    required
                                  </small>
                                </div>
                                <span
                                  *ngIf="
                                    (TannerClinicFroms.controls['cmd_0243'].touched &&
                                      TannerClinicFroms.controls['cmd_0243'].errors) ||
                                    (TannerClinicFroms.controls['cmd_0243'].errors && isSubmit)
                                  "
                                >
                                  <i class="fas fa-times ml-2 text-danger"></i>
                                </span>
                              </div>
                            </td>
                          </tr>
                          
                          <tr>
                           
                          
                            <td colspan="7" width="75%">
                              <div class="form-group form-inline no-margin sideLabelAlignment">
                                <label class="formLabel"
                                  >13. To your knowledge, has information pertaining to you ever
                                  been reported to the National Practitioner Data Bank or
                                  Healthcare Integrity and Protection Data Bank?
                                </label>
                              </div>
                            </td>
                            <td colspan="5" width="20%">
                              <div class="form-group form-inline no-margin sideLabelAlignment">
                                <label class="radio-inline radioStyle">
                                  <input
                                    type="radio"
                                    id="cmd_0244"
                                    [class.is-invalid]="
                                      (TannerClinicFroms.controls['cmd_0244'].touched &&
                                        TannerClinicFroms.controls['cmd_0244'].errors) ||
                                      (TannerClinicFroms.controls['cmd_0244'].errors && isSubmit)
                                    "
                                    name="cmd_0244"
                                    formControlName="cmd_0244"
                                    value="1"
                                    (change)="validationsDataAdd($event)"
                                  />Yes</label
                                >
                                <label class="radio-inline radioStyle">
                                  <input
                                    type="radio"
                                    id="cmd_0244"
                                    [class.is-invalid]="
                                      (TannerClinicFroms.controls['cmd_0244'].touched &&
                                        TannerClinicFroms.controls['cmd_0244'].errors) ||
                                      (TannerClinicFroms.controls['cmd_0244'].errors && isSubmit)
                                    "
                                    name="cmd_0244"
                                    formControlName="cmd_0244"
                                    value="0"
                                    (change)="validationsDataAdd($event)"
                                  />No</label
                                >
                                <div
                                  class="formLabel col-md-2"
                                  *ngIf="
                                    TannerClinicFroms.controls['cmd_0244'].touched &&
                                    TannerClinicFroms.controls['cmd_0244'].errors
                                  "
                                >
                                  <small
                                    class="text-danger"
                                    *ngIf="TannerClinicFroms.controls['cmd_0244'].errors?.required"
                                  >
                                    required
                                  </small>
                                </div>
                                <span
                                  *ngIf="
                                    (TannerClinicFroms.controls['cmd_0244'].touched &&
                                      TannerClinicFroms.controls['cmd_0244'].errors) ||
                                    (TannerClinicFroms.controls['cmd_0244'].errors && isSubmit)
                                  "
                                >
                                  <i class="fas fa-times ml-2 text-danger"></i>
                                </span>
                              </div>
                            </td>
                          </tr>
                          <tr>
                           
                            <td colspan="7" width="75%">
                              <div class="form-group form-inline no-margin sideLabelAlignment">
                                <label class="formLabel"
                                  >14. Have you ever received sanctions from or been the subject of
                                  investigation by any regulatory agencies (e.g., Clia, OSHA,
                                  etc.)?
                                </label>
                              </div>
                            </td>
                            <td colspan="5" width="20%">
                              <div class="form-group form-inline no-margin sideLabelAlignment">
                                <label class="radio-inline radioStyle">
                                  <input
                                    type="radio"
                                    id="cmd_0245"
                                    [class.is-invalid]="
                                      (TannerClinicFroms.controls['cmd_0245'].touched &&
                                        TannerClinicFroms.controls['cmd_0245'].errors) ||
                                      (TannerClinicFroms.controls['cmd_0245'].errors && isSubmit)
                                    "
                                    name="cmd_0245"
                                    formControlName="cmd_0245"
                                    value="1"
                                    (change)="validationsDataAdd($event)"
                                  />Yes</label
                                >
                                <label class="radio-inline radioStyle">
                                  <input
                                    type="radio"
                                    id="cmd_0245"
                                    [class.is-invalid]="
                                      (TannerClinicFroms.controls['cmd_0245'].touched &&
                                        TannerClinicFroms.controls['cmd_0245'].errors) ||
                                      (TannerClinicFroms.controls['cmd_0245'].errors && isSubmit)
                                    "
                                    name="cmd_0245"
                                    formControlName="cmd_0245"
                                    value="0"
                                    (change)="validationsDataAdd($event)"
                                  />No</label
                                >
                                <div
                                  class="formLabel col-md-2"
                                  *ngIf="
                                    TannerClinicFroms.controls['cmd_0245'].touched &&
                                    TannerClinicFroms.controls['cmd_0245'].errors
                                  "
                                >
                                  <small
                                    class="text-danger"
                                    *ngIf="TannerClinicFroms.controls['cmd_0245'].errors?.required"
                                  >
                                    required
                                  </small>
                                </div>
                                <span
                                  *ngIf="
                                    (TannerClinicFroms.controls['cmd_0245'].touched &&
                                      TannerClinicFroms.controls['cmd_0245'].errors) ||
                                    (TannerClinicFroms.controls['cmd_0245'].errors && isSubmit)
                                  "
                                >
                                  <i class="fas fa-times ml-2 text-danger"></i>
                                </span>
                              </div>
                            </td>
                          </tr>
                          <tr>
                           
                            <td colspan="7" width="75%">
                              <div class="form-group form-inline no-margin sideLabelAlignment">
                                <label class="formLabel"
                                  >15. Have you ever been investigated, sanctioned, reprimanded or
                                  cautioned by a military hospital,facility, or agency, or
                                  voluntarily terminated or resigned while under investigation
                                  by a hospital or healthcare facility of any military agency?
                                </label>
                              </div>
                            </td>
                            <td colspan="5" width="20%">
                              <div class="form-group form-inline no-margin sideLabelAlignment">
                                <label class="radio-inline radioStyle">
                                  <input
                                    type="radio"
                                    id="cmd_0246"
                                    [class.is-invalid]="
                                      (TannerClinicFroms.controls['cmd_0246'].touched &&
                                        TannerClinicFroms.controls['cmd_0246'].errors) ||
                                      (TannerClinicFroms.controls['cmd_0246'].errors && isSubmit)
                                    "
                                    name="cmd_0246"
                                    formControlName="cmd_0246"
                                    value="1"
                                    (change)="validationsDataAdd($event)"
                                  />Yes</label
                                >
                                <label class="radio-inline radioStyle">
                                  <input
                                    type="radio"
                                    id="cmd_0246"
                                    [class.is-invalid]="
                                      (TannerClinicFroms.controls['cmd_0246'].touched &&
                                        TannerClinicFroms.controls['cmd_0246'].errors) ||
                                      (TannerClinicFroms.controls['cmd_0246'].errors && isSubmit)
                                    "
                                    name="cmd_0246"
                                    formControlName="cmd_0246"
                                    value="0"
                                    (change)="validationsDataAdd($event)"
                                  />No</label
                                >
                                <div
                                  class="formLabel col-md-2"
                                  *ngIf="
                                    TannerClinicFroms.controls['cmd_0246'].touched &&
                                    TannerClinicFroms.controls['cmd_0246'].errors
                                  "
                                >
                                  <small
                                    class="text-danger"
                                    *ngIf="TannerClinicFroms.controls['cmd_0246'].errors?.required"
                                  >
                                    required
                                  </small>
                                </div>
                                <span
                                  *ngIf="
                                    (TannerClinicFroms.controls['cmd_0246'].touched &&
                                      TannerClinicFroms.controls['cmd_0246'].errors) ||
                                    (TannerClinicFroms.controls['cmd_0246'].errors && isSubmit)
                                  "
                                >
                                  <i class="fas fa-times ml-2 text-danger"></i>
                                </span>
                              </div>
                            </td>
                          </tr>
                        
                          <tr>
                           
                            <td colspan="7" width="75%">
                              <div class="form-group form-inline no-margin sideLabelAlignment">
                                <label class="formLabel"
                                  >16. Have you had any malpractice actions within the past 5 years
                                  (pending, settled, arbitrated , mediated or litigated?
                                </label>
                              </div>
                            </td>
                            <td colspan="5" width="20%">
                              <div class="form-group form-inline no-margin sideLabelAlignment">
                                <label class="radio-inline radioStyle">
                                  <input
                                    type="radio"
                                    id="cmd_0247"
                                    [class.is-invalid]="
                                      (TannerClinicFroms.controls['cmd_0247'].touched &&
                                        TannerClinicFroms.controls['cmd_0247'].errors) ||
                                      (TannerClinicFroms.controls['cmd_0247'].errors && isSubmit)
                                    "
                                    name="cmd_0247"
                                    formControlName="cmd_0247"
                                    value="1"
                                    (change)="validationsDataAdd($event)"
                                  />Yes</label
                                >
                                <label class="radio-inline radioStyle">
                                  <input
                                    type="radio"
                                    id="cmd_0247"
                                    [class.is-invalid]="
                                      (TannerClinicFroms.controls['cmd_0247'].touched &&
                                        TannerClinicFroms.controls['cmd_0247'].errors) ||
                                      (TannerClinicFroms.controls['cmd_0247'].errors && isSubmit)
                                    "
                                    name="cmd_0247"
                                    formControlName="cmd_0247"
                                    value="0"
                                    (change)="validationsDataAdd($event)"
                                  />No</label
                                >
                                <div
                                  class="formLabel col-md-2"
                                  *ngIf="
                                    TannerClinicFroms.controls['cmd_0247'].touched &&
                                    TannerClinicFroms.controls['cmd_0247'].errors
                                  "
                                >
                                  <small
                                    class="text-danger"
                                    *ngIf="TannerClinicFroms.controls['cmd_0247'].errors?.required"
                                  >
                                    required
                                  </small>
                                </div>
                                <span
                                  *ngIf="
                                    (TannerClinicFroms.controls['cmd_0247'].touched &&
                                      TannerClinicFroms.controls['cmd_0247'].errors) ||
                                    (TannerClinicFroms.controls['cmd_0247'].errors && isSubmit)
                                  "
                                >
                                  <i class="fas fa-times ml-2 text-danger"></i>
                                </span>
                              </div>
                            </td>
                          </tr>
                          
                          <tr>
                           
                            <td colspan="7" width="75%">
                              <div class="form-group form-inline no-margin sideLabelAlignment">
                                <label class="formLabel"
                                  >17. Have you ever been convicted of, pled guilty to, or pled nolo
                                  contendere to any felony that is reasonably related to your
                                  qualifications, competence, functions, or duties as a medical
                                  professional?
                                </label>
                              </div>
                            </td>
                            <td colspan="5" width="20%">
                              <div class="form-group form-inline no-margin sideLabelAlignment">
                                <label class="radio-inline radioStyle">
                                  <input
                                    type="radio"
                                    id="cmd_0248"
                                    [class.is-invalid]="
                                      (TannerClinicFroms.controls['cmd_0248'].touched &&
                                        TannerClinicFroms.controls['cmd_0248'].invalid) ||
                                      (TannerClinicFroms.controls['cmd_0248'].invalid && isSubmit)
                                    "
                                    name="cmd_0248"
                                    formControlName="cmd_0248"
                                    value="1"
                                    (change)="validationsDataAdd($event)"
                                  />Yes</label
                                >
                                <label class="radio-inline radioStyle">
                                  <input
                                    type="radio"
                                    id="cmd_0248"
                                    [class.is-invalid]="
                                      (TannerClinicFroms.controls['cmd_0248'].touched &&
                                        TannerClinicFroms.controls['cmd_0248'].invalid) ||
                                      (TannerClinicFroms.controls['cmd_0248'].invalid && isSubmit)
                                    "
                                    name="cmd_0248"
                                    formControlName="cmd_0248"
                                    value="0"
                                    (change)="validationsDataAdd($event)"
                                  />No</label
                                >
                                <div
                                  class="formLabel col-md-2"
                                  *ngIf="
                                    TannerClinicFroms.controls['cmd_0248'].touched &&
                                    TannerClinicFroms.controls['cmd_0248'].invalid
                                  "
                                >
                                  <small
                                    class="text-danger"
                                    *ngIf="TannerClinicFroms.controls['cmd_0248'].errors?.required"
                                  >
                                    required
                                  </small>
                                </div>
                                <span
                                  *ngIf="
                                    (TannerClinicFroms.controls['cmd_0248'].touched &&
                                      TannerClinicFroms.controls['cmd_0248'].invalid) ||
                                    (TannerClinicFroms.controls['cmd_0248'].invalid && isSubmit)
                                  "
                                >
                                  <i class="fas fa-times ml-2 text-danger"></i>
                                </span>
                              </div>
                            </td>
                          </tr>
                          <tr>
                         
                            <td colspan="7" width="75%">
                              <div class="form-group form-inline no-margin sideLabelAlignment">
                                <label class="formLabel"
                                  >18. Have you ever been convicted of, pled guilty to, or pled nolo
                                  contendere to any felony including an act of violence, child
                                  abuse or a sexual offense?
                                </label>
                              </div>
                            </td>
                            <td colspan="5" width="20%">
                              <div class="form-group form-inline no-margin sideLabelAlignment">
                                <label class="radio-inline radioStyle">
                                  <input
                                    type="radio"
                                    id="cmd_0249"
                                    [class.is-invalid]="
                                      (TannerClinicFroms.controls['cmd_0249'].touched &&
                                        TannerClinicFroms.controls['cmd_0249'].invalid) ||
                                      (TannerClinicFroms.controls['cmd_0249'].invalid && isSubmit)
                                    "
                                    name="cmd_0249"
                                    formControlName="cmd_0249"
                                    value="1"
                                    (change)="validationsDataAdd($event)"
                                  />Yes</label
                                >
                                <label class="radio-inline radioStyle">
                                  <input
                                    type="radio"
                                    id="cmd_0249"
                                    [class.is-invalid]="
                                      (TannerClinicFroms.controls['cmd_0249'].touched &&
                                        TannerClinicFroms.controls['cmd_0249'].invalid) ||
                                      (TannerClinicFroms.controls['cmd_0249'].invalid && isSubmit)
                                    "
                                    name="cmd_0249"
                                    formControlName="cmd_0249"
                                    value="0"
                                    (change)="validationsDataAdd($event)"
                                  />No</label
                                >
                                <div
                                  class="formLabel col-md-2"
                                  *ngIf="
                                    TannerClinicFroms.controls['cmd_0249'].touched &&
                                    TannerClinicFroms.controls['cmd_0249'].invalid
                                  "
                                >
                                  <small
                                    class="text-danger"
                                    *ngIf="TannerClinicFroms.controls['cmd_0249'].errors?.required"
                                  >
                                    required
                                  </small>
                                </div>
                                <span
                                  *ngIf="
                                    (TannerClinicFroms.controls['cmd_0249'].touched &&
                                      TannerClinicFroms.controls['cmd_0249'].invalid) ||
                                    (TannerClinicFroms.controls['cmd_0249'].invalid && isSubmit)
                                  "
                                >
                                  <i class="fas fa-times ml-2 text-danger"></i>
                                </span>
                              </div>
                            </td>
                          </tr>
                          <tr>
                           
                            <td colspan="7" width="75%">
                              <div class="form-group form-inline no-margin sideLabelAlignment">
                                <label class="formLabel"
                                  >19. Have you been court martialed for actions related to your
                                  duties as a medical professional?
                                </label>
                              </div>
                            </td>
                            <td colspan="5" width="20%">
                              <div class="form-group form-inline no-margin sideLabelAlignment">
                                <label class="radio-inline radioStyle">
                                  <input
                                    type="radio"
                                    id="cmd_0250"
                                    [class.is-invalid]="
                                      (TannerClinicFroms.controls['cmd_0250'].touched &&
                                        TannerClinicFroms.controls['cmd_0250'].invalid) ||
                                      (TannerClinicFroms.controls['cmd_0250'].invalid && isSubmit)
                                    "
                                    name="cmd_0250"
                                    formControlName="cmd_0250"
                                    value="1"
                                    (change)="validationsDataAdd($event)"
                                  />Yes</label
                                >
                  
                                <label class="radio-inline radioStyle">
                                  <input
                                    type="radio"
                                    id="cmd_0250"
                                    [class.is-invalid]="
                                      (TannerClinicFroms.controls['cmd_0250'].touched &&
                                        TannerClinicFroms.controls['cmd_0250'].invalid) ||
                                      (TannerClinicFroms.controls['cmd_0250'].invalid && isSubmit)
                                    "
                                    name="cmd_0250"
                                    formControlName="cmd_0250"
                                    value="0"
                                    (change)="validationsDataAdd($event)"
                                  />No</label
                                >
                                <div
                                  class="formLabel col-md-2"
                                  *ngIf="
                                    TannerClinicFroms.controls['cmd_0250'].touched &&
                                    TannerClinicFroms.controls['cmd_0250'].invalid
                                  "
                                >
                                  <small
                                    class="text-danger"
                                    *ngIf="TannerClinicFroms.controls['cmd_0250'].errors?.required"
                                  >
                                    required
                                  </small>
                                </div>
                                <span
                                  *ngIf="
                                    (TannerClinicFroms.controls['cmd_0250'].touched &&
                                      TannerClinicFroms.controls['cmd_0250'].invalid) ||
                                    (TannerClinicFroms.controls['cmd_0250'].invalid && isSubmit)
                                  "
                                >
                                  <i class="fas fa-times ml-2 text-danger"></i>
                                </span>
                              </div>
                            </td>
                          </tr>
                          
                          <tr>
                            
                            <td colspan="7" width="75%">
                              <div class="form-group form-inline no-margin sideLabelAlignment">
                                <label class="formLabel"
                                  >20.  Are you currently engaged in the illegal use of drugs?
                                </label>
                              </div>
                            </td>
                            <td colspan="5" width="20%">
                              <div class="form-group form-inline no-margin sideLabelAlignment">
                                <label class="radio-inline radioStyle">
                                  <input
                                    type="radio"
                                    id="cmd_0251"
                                    [class.is-invalid]="
                                      (TannerClinicFroms.controls['cmd_0251'].touched &&
                                        TannerClinicFroms.controls['cmd_0251'].invalid) ||
                                      (TannerClinicFroms.controls['cmd_0251'].invalid && isSubmit)
                                    "
                                    name="cmd_0251"
                                    formControlName="cmd_0251"
                                    value="1"
                                    (change)="validationsDataAdd($event)"
                                  />Yes</label
                                >
                                <label class="radio-inline radioStyle">
                                  <input
                                    type="radio"
                                    id="cmd_0251"
                                    [class.is-invalid]="
                                      (TannerClinicFroms.controls['cmd_0251'].touched &&
                                        TannerClinicFroms.controls['cmd_0251'].invalid) ||
                                      (TannerClinicFroms.controls['cmd_0251'].invalid && isSubmit)
                                    "
                                    name="cmd_0251"
                                    formControlName="cmd_0251"
                                    value="0"
                                    (change)="validationsDataAdd($event)"
                                  />No</label
                                >
                                <div
                                  class="formLabel col-md-2"
                                  *ngIf="
                                    TannerClinicFroms.controls['cmd_0251'].touched &&
                                    TannerClinicFroms.controls['cmd_0251'].invalid
                                  "
                                >
                                  <small
                                    class="text-danger"
                                    *ngIf="TannerClinicFroms.controls['cmd_0251'].errors?.required"
                                  >
                                    required
                                  </small>
                                </div>
                                <span
                                  *ngIf="
                                    (TannerClinicFroms.controls['cmd_0251'].touched &&
                                      TannerClinicFroms.controls['cmd_0251'].invalid) ||
                                    (TannerClinicFroms.controls['cmd_0251'].invalid && isSubmit)
                                  "
                                >
                                  <i class="fas fa-times ml-2 text-danger"></i>
                                </span>
                              </div>
                            </td>
                          </tr>
                          <tr>
                          
                            <td colspan="7" width="75%">
                              <div class="form-group form-inline no-margin sideLabelAlignment">
                                <label class="formLabel"
                                  >21. Do you use any chemical substances that would in any way
                                  impair or limit your ability to practice medicine and perform
                                  the functions of your job with reasonable skill and safety?
                                </label>
                              </div>
                            </td>
                            <td colspan="5" width="20%">
                              <div class="form-group form-inline no-margin sideLabelAlignment">
                                <label class="radio-inline radioStyle">
                                  <input
                                    type="radio"
                                    id="cmd_0252"
                                    [class.is-invalid]="
                                      (TannerClinicFroms.controls['cmd_0252'].touched &&
                                        TannerClinicFroms.controls['cmd_0252'].invalid) ||
                                      (TannerClinicFroms.controls['cmd_0252'].invalid && isSubmit)
                                    "
                                    name="cmd_0252"
                                    formControlName="cmd_0252"
                                    value="1"
                                    (change)="validationsDataAdd($event)"
                                  />Yes</label
                                >
                                <label class="radio-inline radioStyle">
                                  <input
                                    type="radio"
                                    id="cmd_0252"
                                    [class.is-invalid]="
                                      (TannerClinicFroms.controls['cmd_0252'].touched &&
                                        TannerClinicFroms.controls['cmd_0252'].invalid) ||
                                      (TannerClinicFroms.controls['cmd_0252'].invalid && isSubmit)
                                    "
                                    name="cmd_0252"
                                    formControlName="cmd_0252"
                                    value="0"
                                    (change)="validationsDataAdd($event)"
                                  />No</label
                                >
                                <div
                                  class="formLabel col-md-2"
                                  *ngIf="
                                    TannerClinicFroms.controls['cmd_0252'].touched &&
                                    TannerClinicFroms.controls['cmd_0252'].invalid
                                  "
                                >
                                  <small
                                    class="text-danger"
                                    *ngIf="TannerClinicFroms.controls['cmd_0252'].errors?.required"
                                  >
                                    required
                                  </small>
                                </div>
                                <span
                                  *ngIf="
                                    (TannerClinicFroms.controls['cmd_0252'].touched &&
                                      TannerClinicFroms.controls['cmd_0252'].invalid) ||
                                    (TannerClinicFroms.controls['cmd_0252'].invalid && isSubmit)
                                  "
                                >
                                  <i class="fas fa-times ml-2 text-danger"></i>
                                </span>
                              </div>
                            </td>
                          </tr>
                      
                          <tr>
                            
                            <td colspan="7" width="75%">
                              <div class="form-group form-inline no-margin sideLabelAlignment">
                                <label class="formLabel"
                                  >22. Do you have any reason to believe that you would pose a risk
                                  to the safety or well-being of your patients?</label
                                >
                              </div>
                            </td>
                            <td colspan="5" width="20%">
                              <div class="form-group form-inline no-margin sideLabelAlignment">
                                <label class="radio-inline radioStyle">
                                  <input
                                    type="radio"
                                    id="cmd_0253"
                                    [class.is-invalid]="
                                      (TannerClinicFroms.controls['cmd_0253'].touched &&
                                        TannerClinicFroms.controls['cmd_0253'].invalid) ||
                                      (TannerClinicFroms.controls['cmd_0253'].invalid && isSubmit)
                                    "
                                    name="cmd_0253"
                                    formControlName="cmd_0253"
                                    value="1"
                                    (change)="validationsDataAdd($event)"
                                  />Yes</label
                                >
                                <label class="radio-inline radioStyle">
                                  <input
                                    type="radio"
                                    id="cmd_0253"
                                    [class.is-invalid]="
                                      (TannerClinicFroms.controls['cmd_0253'].touched &&
                                        TannerClinicFroms.controls['cmd_0253'].invalid) ||
                                      (TannerClinicFroms.controls['cmd_0253'].invalid && isSubmit)
                                    "
                                    name="cmd_0253"
                                    formControlName="cmd_0253"
                                    value="0"
                                    (change)="validationsDataAdd($event)"
                                  />No</label
                                >
                                <div
                                  class="formLabel col-md-2"
                                  *ngIf="
                                    TannerClinicFroms.controls['cmd_0253'].touched &&
                                    TannerClinicFroms.controls['cmd_0253'].invalid
                                  "
                                >
                                  <small
                                    class="text-danger"
                                    *ngIf="TannerClinicFroms.controls['cmd_0253'].errors?.required"
                                  >
                                    required
                                  </small>
                                </div>
                                <span
                                  *ngIf="
                                    (TannerClinicFroms.controls['cmd_0253'].touched &&
                                      TannerClinicFroms.controls['cmd_0253'].invalid) ||
                                    (TannerClinicFroms.controls['cmd_0253'].invalid && isSubmit)
                                  "
                                >
                                  <i class="fas fa-times ml-2 text-danger"></i>
                                </span>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            
                              
                            <td colspan="7" width="75%">
                              <div class="form-group form-inline no-margin sideLabelAlignment">
                                <label class="formLabel"
                                  >23. Are you unable to perform the essential functions of a
                                  practitioner in your area of practice, with or without
                                  reasonable accommodation?
                                </label>
                              </div>
                            </td>
                            <td colspan="5" width="20%">
                              <div class="form-group form-inline no-margin sideLabelAlignment">
                                <label class="radio-inline radioStyle">
                                  <input
                                    type="radio"
                                    id="cmd_0254"
                                    [class.is-invalid]="
                                      (TannerClinicFroms.controls['cmd_0254'].touched &&
                                        TannerClinicFroms.controls['cmd_0254'].invalid) ||
                                      (TannerClinicFroms.controls['cmd_0254'].invalid && isSubmit)
                                    "
                                    name="cmd_0254"
                                    formControlName="cmd_0254"
                                    value="1"
                                    (change)="validationsDataAdd($event)"
                                  />Yes</label
                                >
                                <label class="radio-inline radioStyle">
                                  <input
                                    type="radio"
                                    id="cmd_0254"
                                    [class.is-invalid]="
                                      (TannerClinicFroms.controls['cmd_0254'].touched &&
                                        TannerClinicFroms.controls['cmd_0254'].invalid) ||
                                      (TannerClinicFroms.controls['cmd_0254'].invalid && isSubmit)
                                    "
                                    name="cmd_0254"
                                    formControlName="cmd_0254"
                                    value="0"
                                    (change)="validationsDataAdd($event)"
                                  />No</label
                                >
                                <div
                                  class="formLabel col-md-2"
                                  *ngIf="
                                    TannerClinicFroms.controls['cmd_0254'].touched &&
                                    TannerClinicFroms.controls['cmd_0254'].invalid
                                  "
                                >
                                  <small
                                    class="text-danger"
                                    *ngIf="TannerClinicFroms.controls['cmd_0254'].errors?.required"
                                  >
                                    required
                                  </small>
                                </div>
                                <span
                                  *ngIf="
                                    (TannerClinicFroms.controls['cmd_0254'].touched &&
                                      TannerClinicFroms.controls['cmd_0254'].invalid) ||
                                    (TannerClinicFroms.controls['cmd_0254'].invalid && isSubmit)
                                  "
                                >
                                  <i class="fas fa-times ml-2 text-danger"></i>
                                </span>
                              </div>
                            </td>
                          </tr>
                    </table>  
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row">
                        <label class="formLabel">Please list explanations for any questions marked yes below:</label> 
                    </div>                    
                </td>   
            </tr>    
            <tr>
                <td colspan="12">
                    <table style="width:100%;"  border="1">
                        <tr>
                            <td colspan="6" style="width:25px">
                                <div class="row">
                                    <label class="formLabel">Question Number</label> 
                                </div>                    
                            </td>   
                            <td colspan="6">
                                <div class="row">
                                    <label class="formLabel">Explanation</label> 
                                </div>                    
                            </td>   
                        </tr>   
                        <tr>
                            <td colspan="6">
                            <div class="form-group form-inline no-margin sideLabelAlignment">
                                <textarea
                                style="width: 70%"
                                [class.is-invalid]="
                                    questionCounter > 0 && TannerClinicFroms.controls['cmd_0255'].invalid"
                                class="form-control"
                                id="cmd_0255"
                                formControlName="cmd_0255"
                                name="cmd_0255"
                                ></textarea>
                                <div class="formLabel col-sm-2">
                                <small
                                    class="text-danger"
                                    *ngIf="
                                    questionCounter > 0 && TannerClinicFroms.controls['cmd_0255'].invalid"
                                >
                                    required
                                </small>
                                </div>
                            </div>
                            </td>
                            <td colspan="6">
                            <div class="form-inline no-margin sideLabelAlignment">
                                <textarea
                                style="width: 70%"
                                class="form-control"
                                [class.is-invalid]="
                                    questionCounters > 0 && TannerClinicFroms.controls['cmd_0256'].invalid"
                                
                                id="cmd_0256"
                                formControlName="cmd_0256"
                                name="cmd_0256"
                                data-id="Text5.2.0.0"
                                ></textarea>
                                <div class="formLabel col-md-2">
                                    <small
                                        class="text-danger"
                                        *ngIf="
                                        questionCounters > 0 && TannerClinicFroms.controls['cmd_0256'].invalid
                                        "
                                    >
                                        required
                                    </small>
                                    </div>
                            </div>
                            </td>
                        </tr> 
                        <tr>
                            <td colspan="6">
                            <div class="form-group form-inline no-margin sideLabelAlignment">
                                <textarea
                                style="width: 70%"
                                [class.is-invalid]="
                                    questionCounter > 1 && TannerClinicFroms.controls['cmd_0257'].invalid
                                "
                                class="form-control"
                                id="cmd_0257"
                                formControlName="cmd_0257"
                                name="cmd_0257"
                                data-id="Text5.2.1.1"
                                ></textarea>
                                <div class="formLabel col-md-2">
                                <small
                                    class="text-danger"
                                    *ngIf="
                                    questionCounter > 1 && TannerClinicFroms.controls['cmd_0257'].invalid
                                    "
                                >
                                    required
                                </small>
                                </div>
                            </div>
                            </td>
                            <td colspan="6">
                            <div class="form-group form-inline no-margin sideLabelAlignment">
                                <textarea
                                style="width: 70%"
                                [class.is-invalid]="
                                    questionCounters > 1 && TannerClinicFroms.controls['cmd_0258'].invalid
                                "
                                class="form-control"
                                id="cmd_0258"
                                formControlName="cmd_0258"
                                name="cmd_0258"
                                data-id="Text5.2.0.1"
                                ></textarea>
                                <div class="formLabel col-md-2">
                                    <small
                                        class="text-danger"
                                        *ngIf="
                                        questionCounters > 1 && TannerClinicFroms.controls['cmd_0258'].invalid
                                        "
                                    >
                                        required
                                    </small>
                                </div>
                               
                            </div>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="6">
                            <div class="form-group form-inline no-margin sideLabelAlignment">
                                <textarea
                                style="width: 70%"
                                [class.is-invalid]="
                                    questionCounter > 2 && TannerClinicFroms.controls['cmd_0259'].invalid
                                "
                                class="form-control"
                                id="cmd_0259"
                                formControlName="cmd_0259"
                                name="cmd_0259"
                                data-id="Text5.2.1.2"
                                ></textarea>
                                <div class="formLabel col-md-2">
                                <small
                                    class="text-danger"
                                    *ngIf="
                                    questionCounter > 2 && TannerClinicFroms.controls['cmd_0259'].invalid
                                    "
                                >
                                    required
                                </small>
                                </div>
                            </div>
                            </td>
                            <td colspan="6">
                            <div class="form-inline no-margin sideLabelAlignment">
                                <textarea
                                style="width: 70%"
                                class="form-control"
                                id="cmd_0260"
                                [class.is-invalid]="
                                    questionCounters > 2 && TannerClinicFroms.controls['cmd_0260'].invalid
                                "
                                formControlName="cmd_0260"
                                name="cmd_0260"
                                data-id="Text5.2.0.2"
                                ></textarea>
                                <div class="formLabel col-md-2">
                                    <small
                                        class="text-danger"
                                        *ngIf="
                                        questionCounters > 2 && TannerClinicFroms.controls['cmd_0260'].invalid
                                        "
                                    >
                                        required
                                    </small>
                                    </div>
                            </div>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="6">
                            <div class="form-group form-inline no-margin sideLabelAlignment">
                                <textarea
                                style="width: 70%"
                                [class.is-invalid]="
                                    questionCounter > 3 && TannerClinicFroms.controls['cmd_0261'].invalid
                                "
                                class="form-control"
                                id="cmd_0261"
                                formControlName="cmd_0261"
                                name="cmd_0261"
                                data-id="Text5.2.1.3"
                                ></textarea>
                                <div class="formLabel col-md-2">
                                <small
                                    class="text-danger"
                                    *ngIf="
                                    questionCounter > 3 && TannerClinicFroms.controls['cmd_0261'].invalid
                                    "
                                >
                                    required
                                </small>
                                </div>
                            </div>
                            </td>
                            <td colspan="6">
                            <div class="form-inline no-margin sideLabelAlignment">
                                <textarea
                                style="width: 70%"
                                class="form-control"
                                id="cmd_0262"
                                [class.is-invalid]="
                                    questionCounters > 3 && TannerClinicFroms.controls['cmd_0262'].invalid
                                "
                                formControlName="cmd_0262"
                                name="cmd_0262"
                                data-id="Text5.2.0.3"
                                ></textarea>
                                <div class="formLabel col-md-2">
                                    <small
                                        class="text-danger"
                                        *ngIf="
                                        questionCounters> 3 && TannerClinicFroms.controls['cmd_0262'].invalid
                                        "
                                    >
                                        required
                                    </small>
                                </div>
                            </div>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="6">
                            <div class="form-group form-inline no-margin sideLabelAlignment">
                                <textarea
                                style="width: 70%"
                                [class.is-invalid]="
                                    questionCounter > 4 && TannerClinicFroms.controls['cmd_0263'].invalid
                                "
                                class="form-control"
                                id="cmd_0263"
                                formControlName="cmd_0263"
                                name="cmd_0263"
                                data-id="Text5.2.1.4"
                                ></textarea>
                                <div class="formLabel col-md-2">
                                <small
                                    class="text-danger"
                                    *ngIf="
                                    questionCounter > 4 && TannerClinicFroms.controls['cmd_0263'].invalid
                                    "
                                >
                                    required
                                </small>
                                </div>
                            </div>
                            </td>
                            <td colspan="6">
                            <div class="form-inline no-margin sideLabelAlignment">
                                <textarea
                                style="width: 70%"
                                class="form-control"
                                id="cmd_0264"
                                [class.is-invalid]="
                                    questionCounters > 4 && TannerClinicFroms.controls['cmd_0264'].invalid
                                "
                                formControlName="cmd_0264"
                                name="cmd_0264"
                                data-id="Text5.2.0.4"
                                ></textarea>
                                <div class="formLabel col-md-2">
                                    <small
                                        class="text-danger"
                                        *ngIf="
                                        questionCounters > 4 && TannerClinicFroms.controls['cmd_0264'].invalid
                                        "
                                    >
                                        required
                                    </small>
                                    </div>
                            </div>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="6">
                            <div class="form-group form-inline no-margin sideLabelAlignment">
                                <textarea
                                style="width: 70%"
                                [class.is-invalid]="
                                    questionCounter > 5 && TannerClinicFroms.controls['cmd_0265'].invalid
                                "
                                class="form-control"
                                id="cmd_0265"
                                formControlName="cmd_0265"
                                name="cmd_0265"
                                data-id="Text5.2.1.5"
                                ></textarea>
                                <div class="formLabel col-md-2">
                                <small
                                    class="text-danger"
                                    *ngIf="
                                    questionCounter > 5 && TannerClinicFroms.controls['cmd_0265'].invalid
                                    "
                                >
                                    required
                                </small>
                                </div>
                            </div>
                            </td>
                            <td colspan="6">
                            <div class="form-inline no-margin sideLabelAlignment">
                                <textarea
                                style="width: 70%"
                                class="form-control"
                                [class.is-invalid]="
                                questionCounters > 5 && TannerClinicFroms.controls['cmd_0266'].invalid
                                "
                                id="cmd_0266"
                                formControlName="cmd_0266"
                                name="cmd_0266"
                                data-id="Text5.2.0.5"
                                ></textarea>
                                <div class="formLabel col-md-2">
                                    <small
                                        class="text-danger"
                                        *ngIf="
                                        questionCounters > 5 && TannerClinicFroms.controls['cmd_0266'].invalid
                                        "
                                    >
                                        required
                                    </small>
                                    </div>
                            </div>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="6">
                            <div class="form-group form-inline no-margin sideLabelAlignment">
                                <textarea
                                style="width: 70%"
                                [class.is-invalid]="
                                    questionCounter > 6 && TannerClinicFroms.controls['cmd_0267'].invalid
                                "
                                class="form-control"
                                id="cmd_0267"
                                formControlName="cmd_0267"
                                name="cmd_0267"
                                data-id="Text5.2.1.6"
                                ></textarea>
                                <div class="formLabel col-md-2">
                                <small
                                    class="text-danger"
                                    *ngIf="
                                    questionCounter > 6 && TannerClinicFroms.controls['cmd_0267'].invalid
                                    "
                                >
                                    required
                                </small>
                                </div>
                            </div>
                            </td>
                            <td colspan="6">
                            <div class="form-inline no-margin sideLabelAlignment">
                                <textarea
                                style="width: 70%"
                                class="form-control"
                                id="cmd_0268"
                                [class.is-invalid]="
                                    questionCounters > 6 && TannerClinicFroms.controls['cmd_0268'].invalid
                                "
                                formControlName="cmd_0268"
                                name="cmd_0268"
                                data-id="Text5.2.0.6"
                                ></textarea>
                                <div class="formLabel col-md-2">
                                    <small
                                        class="text-danger"
                                        *ngIf="
                                        questionCounters > 6 && TannerClinicFroms.controls['cmd_0268'].invalid
                                        "
                                    >
                                        required
                                    </small>
                                    </div>
                            </div>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="6">
                            <div class="form-group form-inline no-margin sideLabelAlignment">
                                <textarea
                                style="width: 70%"
                                [class.is-invalid]="
                                    questionCounter > 7 && TannerClinicFroms.controls['cmd_0269'].invalid
                                "
                                class="form-control"
                                id="cmd_0269"
                                formControlName="cmd_0269"
                                name="cmd_0269"
                                data-id="Text5.2.1.7"
                                ></textarea>
                                <div class="formLabel col-md-2">
                                <small
                                    class="text-danger"
                                    *ngIf="
                                    questionCounter > 7 && TannerClinicFroms.controls['cmd_0269'].invalid
                                    "
                                >
                                    required
                                </small>
                                </div>
                            </div>
                            </td>
                            <td colspan="6">
                            <div class="form-inline no-margin sideLabelAlignment">
                                <textarea
                                style="width: 70%"
                                class="form-control"
                                id="cmd_0270"
                                [class.is-invalid]="
                                    questionCounters > 7 && TannerClinicFroms.controls['cmd_0270'].invalid
                                "
                                formControlName="cmd_0270"
                                name="cmd_0270"
                                data-id="Text5.2.0.7"
                                ></textarea>
                                <div class="formLabel col-md-2">
                                    <small
                                        class="text-danger"
                                        *ngIf="
                                        questionCounters > 7 && TannerClinicFroms.controls['cmd_0270'].invalid
                                        "
                                    >
                                        required
                                    </small>
                                    </div>
                            </div>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="6">
                            <div class="form-group form-inline no-margin sideLabelAlignment">
                                <textarea
                                style="width: 70%"
                                [class.is-invalid]="
                                    questionCounter > 8 && TannerClinicFroms.controls['cmd_0271'].invalid
                                "
                                class="form-control"
                                id="cmd_0271"
                                formControlName="cmd_0271"
                                name="cmd_0271"
                                data-id="Text5.2.1.8.0"
                                ></textarea>
                                <div class="formLabel col-md-2">
                                <small
                                    class="text-danger"
                                    *ngIf="
                                    questionCounter > 8 && TannerClinicFroms.controls['cmd_0271'].invalid
                                    "
                                >
                                    required
                                </small>
                                </div>
                            </div>
                            </td>
                            <td colspan="6">
                            <div class="form-inline no-margin sideLabelAlignment">
                                <textarea
                                style="width: 70%"
                                class="form-control"
                                id="cmd_0272"
                                formControlName="cmd_0272"
                                [class.is-invalid]="
                                    questionCounters > 8 && TannerClinicFroms.controls['cmd_0272'].invalid
                                "
                                name="cmd_0272"
                                data-id="Text5.2.0.8.0"
                                ></textarea>
                                <div class="formLabel col-md-2">
                                    <small
                                        class="text-danger"
                                        *ngIf="
                                        questionCounters > 8 && TannerClinicFroms.controls['cmd_0272'].invalid
                                        "
                                    >
                                        required
                                    </small>
                                </div>
                            </div>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="6">
                            <div class="form-group form-inline no-margin sideLabelAlignment">
                                <textarea
                                style="width: 70%"
                                [class.is-invalid]="
                                    questionCounter > 9 && TannerClinicFroms.controls['cmd_0273'].invalid
                                "
                                class="form-control"
                                id="cmd_0273"
                                formControlName="cmd_0273"
                                name="cmd_0273"
                                data-id="Text5.2.1.9.0"
                                ></textarea>
                                <div class="formLabel col-md-2">
                                <small
                                    class="text-danger"
                                    *ngIf="
                                    questionCounter > 9 && TannerClinicFroms.controls['cmd_0273'].invalid
                                    "
                                >
                                    required
                                </small>
                                </div>
                            </div>
                            </td>
                            <td colspan="6">
                            <div class="form-inline no-margin sideLabelAlignment">
                                <textarea
                                style="width: 70%"
                                class="form-control"
                                id="cmd_0274"
                                [class.is-invalid]="
                                    questionCounters > 9 && TannerClinicFroms.controls['cmd_0274'].invalid
                                "
                                formControlName="cmd_0274"
                                name="cmd_0274"
                                data-id="Text5.2.0.9.0"
                                ></textarea>
                                <div class="formLabel col-md-2">
                                    <small
                                        class="text-danger"
                                        *ngIf="
                                        questionCounters > 9 && TannerClinicFroms.controls['cmd_0274'].invalid
                                        "
                                    >
                                        required
                                    </small>
                                    </div>
                            </div>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="6">
                            <div class="form-group form-inline no-margin sideLabelAlignment">
                                <textarea
                                style="width: 70%"
                                [class.is-invalid]="
                                    questionCounter > 10 && TannerClinicFroms.controls['cmd_0275'].invalid
                                "
                                class="form-control"
                                id="cmd_0275"
                                formControlName="cmd_0275"
                                name="cmd_0275"
                                data-id="Text5.2.1.8.1"
                                ></textarea>
                                <div class="formLabel col-md-2">
                                <small
                                    class="text-danger"
                                    *ngIf="
                                    questionCounter > 10 &&
                                    TannerClinicFroms.controls['cmd_0275'].invalid
                                    "
                                >
                                    required
                                </small>
                                </div>
                            </div>
                            </td>
                            <td colspan="6">
                            <div class="form-inline no-margin sideLabelAlignment">
                                <textarea
                                style="width: 70%"
                                class="form-control"
                                id="cmd_0276"
                                [class.is-invalid]="
                                    questionCounters > 10 && TannerClinicFroms.controls['cmd_0276'].invalid
                                "
                                formControlName="cmd_0276"
                                name="cmd_0276"
                                data-id="Text5.2.0.8.1"
                                ></textarea>
                                <div class="formLabel col-md-2">
                                    <small
                                        class="text-danger"
                                        *ngIf="
                                        questionCounters > 10 &&
                                        TannerClinicFroms.controls['cmd_0276'].invalid
                                        "
                                    >
                                        required
                                    </small>
                                    </div>
                            </div>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="6">
                            <div class="form-group form-inline no-margin sideLabelAlignment">
                                <textarea
                                style="width: 70%"
                                [class.is-invalid]="
                                    questionCounter > 11 && TannerClinicFroms.controls['cmd_0277'].invalid
                                "
                                class="form-control"
                                id="cmd_0277"
                                formControlName="cmd_0277"
                                name="cmd_0277"
                                data-id="Text5.2.1.9.1"
                                ></textarea>
                                <div class="formLabel col-md-2">
                                <small
                                    class="text-danger"
                                    *ngIf="
                                    questionCounter > 11 &&
                                    TannerClinicFroms.controls['cmd_0277'].invalid
                                    "
                                >
                                    required
                                </small>
                                </div>
                            </div>
                            </td>
                            <td colspan="6">
                            <div class="form-inline no-margin sideLabelAlignment">
                                <textarea
                                style="width: 70%"
                                class="form-control"
                                id="cmd_0278"
                                [class.is-invalid]="
                                    questionCounters > 11 && TannerClinicFroms.controls['cmd_0278'].invalid
                                "
                                formControlName="cmd_0278"
                                name="cmd_0278"
                                data-id="Text5.2.0.9.1"
                                ></textarea>
                                <div class="formLabel col-md-2">
                                    <small
                                        class="text-danger"
                                        *ngIf="
                                        questionCounters > 11 &&
                                        TannerClinicFroms.controls['cmd_0278'].invalid
                                        "
                                    >
                                        required
                                    </small>
                                    </div>
                            </div>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="6">
                            <div class="form-group form-inline no-margin sideLabelAlignment">
                                <textarea
                                style="width: 70%"
                                [class.is-invalid]="
                                    questionCounter > 12 && TannerClinicFroms.controls['cmd_0279'].invalid
                                "
                                class="form-control"
                                id="cmd_0279"
                                formControlName="cmd_0279"
                                name="cmd_0279"
                                data-id="Text5.2.1.8.2"
                                ></textarea>
                                <div class="formLabel col-md-2">
                                <small
                                    class="text-danger"
                                    *ngIf="
                                    questionCounter > 12 &&
                                    TannerClinicFroms.controls['cmd_0279'].invalid
                                    "
                                >
                                    required
                                </small>
                                </div>
                            </div>
                            </td>
                            <td colspan="6">
                            <div class="form-inline no-margin sideLabelAlignment">
                                <textarea
                                style="width: 70%"
                                class="form-control"
                                id="cmd_0280"
                                [class.is-invalid]="
                                    questionCounters > 12 && TannerClinicFroms.controls['cmd_0280'].invalid
                                "
                                formControlName="cmd_0280"
                                name="cmd_0280"
                                data-id="Text5.2.0.8.2"
                                ></textarea>
                                <div class="formLabel col-md-2">
                                    <small
                                        class="text-danger"
                                        *ngIf="
                                        questionCounters > 12 &&
                                        TannerClinicFroms.controls['cmd_0280'].invalid
                                        "
                                    >
                                        required
                                    </small>
                                    </div>
                            </div>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="6">
                            <div class="form-group form-inline no-margin sideLabelAlignment">
                                <textarea
                                style="width: 70%"
                                [class.is-invalid]="
                                    questionCounter > 13 && TannerClinicFroms.controls['cmd_0281'].invalid
                                "
                                class="form-control"
                                id="cmd_0281"
                                formControlName="cmd_0281"
                                name="cmd_0281"
                                data-id="Text5.2.1.9.2"
                                ></textarea>
                                <div class="formLabel col-md-2">
                                <small
                                    class="text-danger"
                                    *ngIf="
                                    questionCounter > 13 &&
                                    TannerClinicFroms.controls['cmd_0281'].invalid
                                    "
                                >
                                    required
                                </small>
                                </div>
                            </div>
                            </td>
                            <td colspan="6">
                            <div class="form-inline no-margin sideLabelAlignment">
                                <textarea
                                style="width: 70%"
                                class="form-control"
                                id="cmd_0282"
                                formControlName="cmd_0282"
                                [class.is-invalid]="
                                    questionCounters > 13 && TannerClinicFroms.controls['cmd_0282'].invalid
                                "
                                name="cmd_0282"
                                data-id="Text5.2.0.9.2"
                                ></textarea>
                                <div class="formLabel col-md-2">
                                    <small
                                        class="text-danger"
                                        *ngIf="
                                        questionCounters > 13 &&
                                        TannerClinicFroms.controls['cmd_0282'].invalid
                                        "
                                    >
                                        required
                                    </small>
                                </div>
                            </div>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="6">
                            <div class="form-group form-inline no-margin sideLabelAlignment">
                                <textarea
                                style="width: 70%"
                                [class.is-invalid]="
                                    questionCounter > 14 && TannerClinicFroms.controls['cmd_0283'].invalid
                                "
                                class="form-control"
                                id="cmd_0283"
                                formControlName="cmd_0283"
                                name="cmd_0283"
                                data-id="Text5.2.1.8.3"
                                ></textarea>
                                <div class="formLabel col-md-2">
                                <small
                                    class="text-danger"
                                    *ngIf="
                                    questionCounter > 14 &&
                                    TannerClinicFroms.controls['cmd_0283'].invalid
                                    "
                                >
                                    required
                                </small>
                                </div>
                            </div>
                            </td>
                            <td colspan="6">
                            <div class="form-inline no-margin sideLabelAlignment">
                                <textarea
                                style="width: 70%"
                                class="form-control"
                                id="cmd_0284"
                                [class.is-invalid]="
                                    questionCounters > 14 && TannerClinicFroms.controls['cmd_0284'].invalid
                                "
                                formControlName="cmd_0284"
                                name="cmd_0284"
                                data-id="Text5.2.0.8.3"
                                ></textarea>
                                <div class="formLabel col-md-2">
                                    <small
                                        class="text-danger"
                                        *ngIf="
                                        questionCounters > 14 &&
                                        TannerClinicFroms.controls['cmd_0284'].invalid
                                        "
                                    >
                                        required
                                    </small>
                                    </div>
                            </div>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="6">
                            <div class="form-group form-inline no-margin sideLabelAlignment">
                                <textarea
                                style="width: 70%"
                                [class.is-invalid]="
                                    questionCounter > 15 && TannerClinicFroms.controls['cmd_0285'].invalid
                                "
                                class="form-control"
                                id="cmd_0285"
                                formControlName="cmd_0285"
                                name="cmd_0285"
                                data-id="Explain 10"
                                ></textarea>
                                <div
                                class="formLabel col-md-2">
                                
                                <small
                                    class="text-danger"
                                    *ngIf="
                                    questionCounter > 15 &&
                                    TannerClinicFroms.controls['cmd_0285'].invalid
                                    "
                                >
                                    required
                                </small>
                                </div>
                            </div>
                            </td>
                            <td colspan="6">
                            <div class="form-inline no-margin sideLabelAlignment">
                                <textarea
                                style="width: 70%"
                                class="form-control"
                                [class.is-invalid]="
                                    questionCounters > 15 && TannerClinicFroms.controls['cmd_0286'].invalid
                                "
                                id="cmd_0286"
                                formControlName="cmd_0286"
                                name="cmd_0286"
                                data-id="Page 10"
                                ></textarea>
                                <div
                                class="formLabel col-md-2">
                                <small
                                    class="text-danger"
                                    *ngIf="
                                    questionCounters > 15 &&
                                    TannerClinicFroms.controls['cmd_0286'].invalid
                                    "
                                >
                                    required
                                </small>
                                </div>
                            </div>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="6">
                            <div class="form-group form-inline no-margin sideLabelAlignment">
                                <textarea
                                style="width: 70%"
                                [class.is-invalid]="
                                    questionCounter > 16 && TannerClinicFroms.controls['cmd_0287'].invalid
                                "
                                class="form-control"
                                id="cmd_0287"
                                formControlName="cmd_0287"
                                name="cmd_0287"
                                data-id="Text5.2.1.9.3"
                                ></textarea>
                                <div class="formLabel col-md-2">
                                <small
                                    class="text-danger"
                                    *ngIf="
                                    questionCounter > 16 &&
                                    TannerClinicFroms.controls['cmd_0287'].invalid
                                    "
                                >
                                    required
                                </small>
                                </div>
                            </div>
                            </td>
                            <td colspan="6">
                            <div class="form-inline no-margin sideLabelAlignment">
                                <textarea
                                style="width: 70%"
                                class="form-control"
                                id="cmd_0288"
                                [class.is-invalid]="
                                    questionCounters > 16 && TannerClinicFroms.controls['cmd_0288'].invalid
                                "
                                formControlName="cmd_0288"
                                name="cmd_0288"
                                data-id="Text5.2.0.9.3"
                                ></textarea>
                                <div class="formLabel col-md-2">
                                    <small
                                        class="text-danger"
                                        *ngIf="
                                        questionCounters > 16 &&
                                        TannerClinicFroms.controls['cmd_0288'].invalid
                                        "
                                    >
                                        required
                                    </small>
                                </div>
                            </div>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="6">
                            <div class="form-group form-inline no-margin sideLabelAlignment">
                                <textarea
                                style="width: 70%"
                                [class.is-invalid]="
                                    questionCounter > 17 && TannerClinicFroms.controls['cmd_0289'].invalid
                                "
                                class="form-control"
                                id="cmd_0289"
                                formControlName="cmd_0289"
                                name="cmd_0289"
                                data-id="Text5.2.1.8.4"
                                ></textarea>
                                <div class="formLabel col-md-2">
                                <small
                                    class="text-danger"
                                    *ngIf="
                                    questionCounter > 17 &&
                                    TannerClinicFroms.controls['cmd_0289'].invalid
                                    "
                                >
                                    required
                                </small>
                                </div>
                            </div>
                            </td>
                            <td colspan="6">
                            <div class="form-inline no-margin sideLabelAlignment">
                                <textarea
                                style="width: 70%"
                                class="form-control"
                                id="cmd_0290"
                                [class.is-invalid]="
                                    questionCounters > 17 && TannerClinicFroms.controls['cmd_0290'].invalid
                                "
                                formControlName="cmd_0290"
                                name="cmd_0290"
                                data-id="Text5.2.0.8.4"
                                ></textarea>
                                <div class="formLabel col-md-2">
                                    <small
                                        class="text-danger"
                                        *ngIf="
                                        questionCounters > 17 &&
                                        TannerClinicFroms.controls['cmd_0290'].invalid
                                        "
                                    >
                                        required
                                    </small>
                                </div>
                            </div>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="6">
                            <div class="form-group form-inline no-margin sideLabelAlignment">
                                <textarea
                                style="width: 70%"
                                [class.is-invalid]="
                                    questionCounter > 18 && TannerClinicFroms.controls['cmd_0291'].invalid
                                "
                                class="form-control"
                                id="cmd_0291"
                                formControlName="cmd_0291"
                                name="cmd_0291"
                                data-id="Text5.2.1.9.4"
                                ></textarea>
                                <div class="formLabel col-md-2">
                                <small
                                    class="text-danger"
                                    *ngIf="
                                    questionCounter > 18 &&
                                    TannerClinicFroms.controls['cmd_0291'].invalid
                                    "
                                >
                                    required
                                </small>
                                </div>
                            </div>
                            </td>
                            <td colspan="6">
                            <div class="form-inline no-margin sideLabelAlignment">
                                <textarea
                                style="width: 70%"
                                class="form-control"
                                id="cmd_0292"
                                [class.is-invalid]="
                                    questionCounters > 18 && TannerClinicFroms.controls['cmd_0292'].invalid
                                "
                                formControlName="cmd_0292"
                                name="cmd_0292"
                                data-id="Text5.2.0.9.4"
                                ></textarea>
                                <div class="formLabel col-md-2">
                                    <small
                                        class="text-danger"
                                        *ngIf="
                                        questionCounters > 18 &&
                                        TannerClinicFroms.controls['cmd_0292'].invalid
                                        "
                                    >
                                        required
                                    </small>
                                    </div>
                            </div>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="6">
                            <div class="form-group form-inline no-margin sideLabelAlignment">
                                <textarea
                                style="width: 70%"
                                [class.is-invalid]="
                                    questionCounter > 19 && TannerClinicFroms.controls['cmd_0293'].invalid
                                "
                                class="form-control"
                                id="cmd_0293"
                                formControlName="cmd_0293"
                                name="cmd_0293"
                                data-id="Text5.2.0.9.4"
                                ></textarea>
                                <div class="formLabel col-md-2">
                                <small
                                    class="text-danger"
                                    *ngIf="
                                    questionCounter > 19 &&
                                    TannerClinicFroms.controls['cmd_0293'].invalid
                                    "
                                >
                                    required
                                </small>
                                </div>
                            </div>
                            </td>
                            <td colspan="6">
                            <div class="form-inline no-margin sideLabelAlignment">
                                <textarea
                                style="width: 70%"
                                class="form-control"
                                id="cmd_0294"
                                formControlName="cmd_0294"
                                [class.is-invalid]="
                                    questionCounters > 19 && TannerClinicFroms.controls['cmd_0294'].invalid
                                "
                                name="cmd_0294"
                                data-id="Page 10"
                                ></textarea>
                                <div class="formLabel col-md-2">
                                    <small
                                        class="text-danger"
                                        *ngIf="
                                        questionCounters > 19 &&
                                        TannerClinicFroms.controls['cmd_0294'].invalid
                                        "
                                    >
                                        required
                                    </small>
                                    </div>
                            </div>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="6">
                            <div class="form-group form-inline no-margin sideLabelAlignment">
                                <textarea
                                style="width: 70%"
                                [class.is-invalid]="
                                    questionCounter > 20 && TannerClinicFroms.controls['cmd_0295'].invalid
                                "
                                class="form-control"
                                id="cmd_0295"
                                formControlName="cmd_0295"
                                name="cmd_0295"
                                data-id="Explain 10"
                                ></textarea>
                                <div class="formLabel col-md-2">
                                <small
                                    class="text-danger"
                                    *ngIf="
                                    questionCounter > 20 &&
                                    TannerClinicFroms.controls['cmd_0295'].invalid
                                    "
                                >
                                    required
                                </small>
                                </div>
                            </div>
                            </td>
                            <td colspan="6">
                            <div class="form-inline no-margin sideLabelAlignment">
                                <textarea
                                style="width: 70%"
                                class="form-control"
                                id="cmd_0296"
                                formControlName="cmd_0296"
                                [class.is-invalid]="
                                    questionCounters > 20 && TannerClinicFroms.controls['cmd_0296'].invalid
                                "
                                name="cmd_0296"
                                data-id="Page 10"
                                ></textarea>
                                <div class="formLabel col-md-2">
                                    <small
                                        class="text-danger"
                                        *ngIf="
                                        questionCounters > 20 &&
                                        TannerClinicFroms.controls['cmd_0296'].invalid
                                        "
                                    >
                                        required
                                    </small>
                                    </div>
                            </div>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="6">
                            <div class="form-group form-inline no-margin sideLabelAlignment">
                                <textarea
                                style="width: 70%"
                                [class.is-invalid]="
                                    questionCounter > 21 && TannerClinicFroms.controls['cmd_0297'].invalid
                                "
                                class="form-control"
                                id="cmd_0297"
                                formControlName="cmd_0297"
                                name="cmd_0297"
                                data-id="Explain 10"
                                ></textarea>
                                <div class="formLabel col-md-2">
                                <small
                                    class="text-danger"
                                    *ngIf="
                                    questionCounter > 21 &&
                                    TannerClinicFroms.controls['cmd_0297'].invalid
                                    "
                                >
                                    required
                                </small>
                                </div>
                            </div>
                            </td>
                            <td colspan="6">
                            <div class="form-inline no-margin sideLabelAlignment">
                                <textarea
                                style="width: 70%"
                                class="form-control"
                                id="cmd_0298"
                                [class.is-invalid]="
                                questionCounters > 21 && TannerClinicFroms.controls['cmd_0298'].invalid"
                                formControlName="cmd_0298"
                                name="cmd_0298"
                                data-id="Page 10"
                                ></textarea>
                                <div class="formLabel col-md-2">
                                    <small
                                        class="text-danger"
                                        *ngIf="
                                        questionCounters > 21 &&
                                        TannerClinicFroms.controls['cmd_0298'].invalid
                                        "
                                    >
                                        required
                                    </small>
                                    </div>
                            </div>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="6">
                            <div class="form-group form-inline no-margin sideLabelAlignment">
                                <textarea
                                style="width: 70%"
                                [class.is-invalid]="
                                    questionCounter > 22 && TannerClinicFroms.controls['cmd_0299'].invalid
                                "
                                class="form-control"
                                id="cmd_0299"
                                formControlName="cmd_0299"
                                name="cmd_0299"
                                data-id="Explain 10"
                                ></textarea>
                                <div class="formLabel col-md-2">
                                <small
                                    class="text-danger"
                                    *ngIf="
                                    questionCounter > 22 &&
                                    TannerClinicFroms.controls['cmd_0299'].invalid
                                    "
                                >
                                    required
                                </small>
                                </div>
                            </div>
                            </td>
                            <td colspan="6">
                            <div class="form-inline no-margin sideLabelAlignment">
                                <textarea
                                style="width: 70%"
                                class="form-control"
                                id="cmd_0300"
                                [class.is-invalid]="
                                    questionCounters > 22 && TannerClinicFroms.controls['cmd_0300'].invalid
                                "
                                formControlName="cmd_0300"
                                name="cmd_0300"
                                data-id="Page 10"
                                ></textarea>
                                <div class="formLabel col-md-2">
                                    <small
                                        class="text-danger"
                                        *ngIf="
                                        questionCounters > 22 &&
                                        TannerClinicFroms.controls['cmd_0300'].invalid
                                        "
                                    >
                                        required
                                    </small>
                                    </div>
                            </div>
                            </td>
                        </tr>
                        <!-- <tr>
                            <td colspan="6">
                            <div class="form-inline no-margin sideLabelAlignment">
                                <textarea
                                style="width: 70%"
                                class="form-control"
                                id="cmd_0301"
                                formControlName="cmd_0301"
                                name="cmd_0301"
                                data-id="Explain 10"
                                ></textarea>
                            </div>
                            </td>
                            <td colspan="6">
                            <div class="form-inline no-margin sideLabelAlignment">
                                <textarea
                                style="width: 70%"
                                class="form-control"
                                id="cmd_0302"
                                formControlName="cmd_0302"
                                name="cmd_0302"
                                data-id="Page 10"
                                ></textarea>
                            </div>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="6">
                            <div class="form-inline no-margin sideLabelAlignment">
                                <textarea
                                style="width: 70%"
                                class="form-control"
                                id="cmd_0303"
                                formControlName="cmd_0303"
                                name="cmd_0303"
                                data-id="Explain 10"
                                ></textarea>
                            </div>
                            </td>
                            <td colspan="6">
                            <div class="form-inline no-margin sideLabelAlignment">
                                <textarea
                                style="width: 70%"
                                class="form-control"
                                id="cmd_0304"
                                formControlName="cmd_0304"
                                name="cmd_0304"
                                data-id="Page 10"
                                ></textarea>
                            </div>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="6">
                            <div class="form-inline no-margin sideLabelAlignment">
                                <textarea
                                style="width: 70%"
                                class="form-control"
                                id="cmd_0305"
                                formControlName="cmd_0305"
                                name="cmd_0305"
                                data-id="Explain 10"
                                ></textarea>
                            </div>
                            </td>
                            <td colspan="6">
                            <div class="form-inline no-margin sideLabelAlignment">
                                <textarea
                                style="width: 70%"
                                class="form-control"
                                id="cmd_0306"
                                formControlName="cmd_0306"
                                name="cmd_0306"
                                data-id="Page 10"
                                ></textarea>
                            </div>
                            </td>
                        </tr>  -->
                        <!-- <tr>
                            <td colspan="7" width="75%">
                                <div class="row">
                                    <div class="col-md-2" >
                                        <label (click)="esignOpenOne()">Signature:</label>
                                    </div>  
                                    <div class="col-md-4">
                                            <img *ngIf="!showOne" class="img" src="./assets/images/e_sign.png" alt="Credential my Doc"
                                        (click)="esignOpenOne()" />
                                            <img *ngIf="showOne" class="img eSignImageShow" [src]="esignOne" alt="Credential my Doc"
                                        (click)="esignOpenOne()" />
                                            <input type="hidden" name="cmd_0019" id="cmd_0019" [class.is-invalid]="(TannerClinicFroms.controls['cmd_0019'].touched && TannerClinicFroms.controls['cmd_0019'].invalid) || (TannerClinicFroms.controls['cmd_0019'].invalid && isSubmit)" formControlName="cmd_0019" value="{{ finalImgPath }}" />
                                            <div class="invalid-feedback"
                                                *ngIf="(TannerClinicFroms.controls['cmd_0019'].touched && TannerClinicFroms.controls['cmd_0019'].invalid) || (TannerClinicFroms.controls['cmd_0019'].invalid && isSubmit)">
                                                    Signature is required and cannot be empty
                                            </div>
                                    </div>        
                                </div>                            
                            </td>
                            <td colspan="5" width="25%">
                                <div class="row">
                                    <div class="col-md-1">
                                        <label style="font-weight: normal;font-size: 14px">Date:</label>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="input-group">
                                                <input type="text" placeholder="Enter Date" id="cmd_0020" class="form-control" formControlName="cmd_0020"
                                                [class.is-invalid]="(TannerClinicFroms.controls['cmd_0020'].touched && TannerClinicFroms.controls['cmd_0020'].invalid ) || (TannerClinicFroms.controls['cmd_0020'].invalid && isSubmit)" ngbDatepicker #d0020="ngbDatepicker">
                                                <div class="input-group-append">
                                                    <button class="btn btn-outline-secondary calendar" (click)="d0020.toggle()" type="button">
                                                        <i class="fas fa-calendar-alt"></i>
                                                    </button>
                                                </div>
                                        </div>    
                                    
                                        <div class="invalid-feedback"
                                            *ngIf="(TannerClinicFroms.controls['cmd_0020'].touched && TannerClinicFroms.controls['cmd_0020'].invalid) || (TannerClinicFroms.controls['cmd_0020'].invalid && isSubmit)">
                                            Date is required and cannot be empty
                                        </div>
                                    </div>
                                </div>    
                            </td>
                        </tr> -->
                    </table>
                </td>
            </tr> 
            <tr>
                <td colspan="6" style="width:40%;">
                    <div class="row">
                        <div class="col-md-2" >
                            <label (click)="esignOpenOne()">Signature:</label>
                        </div>  
                        <div class="col-md-4">
                                <img *ngIf="!showOne" class="img" src="./assets/images/e_sign.png" alt="Credential my Doc"
                            (click)="esignOpenOne()" />
                                <img *ngIf="showOne" class="img eSignImageShow" [src]="esignOne" alt="Credential my Doc"
                            (click)="esignOpenOne()" />
                                <input type="hidden" name="cmd_0019" id="cmd_0019" [class.is-invalid]="(TannerClinicFroms.controls['cmd_0019'].touched && TannerClinicFroms.controls['cmd_0019'].invalid) || (TannerClinicFroms.controls['cmd_0019'].invalid && isSubmit)" formControlName="cmd_0019" value="{{ finalImgPath }}" />
                                <div class="invalid-feedback"
                                    *ngIf="(TannerClinicFroms.controls['cmd_0019'].touched && TannerClinicFroms.controls['cmd_0019'].invalid) || (TannerClinicFroms.controls['cmd_0019'].invalid && isSubmit)">
                                        Signature is required 
                                </div>
                        </div>        
                    </div>                            
                </td>
                <td colspan="6" style="width:60%;">
                    <div class="row">
                        <div class="col-md-1">
                            <label style="font-weight: normal;font-size: 14px">Date:</label>
                        </div>
                        <div class="col-md-4">
                            <div class="input-group">
                                    <input type="text" placeholder="Enter Date" id="cmd_0020" class="form-control" formControlName="cmd_0020" (blur)="clearInputIfInvalidMandi('cmd_0020')"
                                    [class.is-invalid]="(TannerClinicFroms.controls['cmd_0020'].touched && TannerClinicFroms.controls['cmd_0020'].invalid ) || (TannerClinicFroms.controls['cmd_0020'].invalid && isSubmit)" ngbDatepicker #d0020="ngbDatepicker">
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d0020.toggle()" type="button">
                                            <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                            </div>  
                            <div>
                                <small class="text-danger" *ngIf="TannerClinicFroms.get('cmd_0020').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY)</small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('cmd_0020').errors?.invalidMonth">
                                  Month is out of range </small>
                                  <small class="text-danger" *ngIf="TannerClinicFroms.get('cmd_0020').errors?.invalidDateyear">
                                    year is out of range(1900-2099)</small>
                                    <small class="text-danger" *ngIf="TannerClinicFroms.get('cmd_0020').errors?.invalidDateRange">
                                      date is out of range</small>
                            </div> 
                        
                            <div class="invalid-feedback"
                                *ngIf="(TannerClinicFroms.controls['cmd_0020'].touched && TannerClinicFroms.controls['cmd_0020'].invalid) || (TannerClinicFroms.controls['cmd_0020'].invalid && isSubmit)">
                                Date is required and cannot be empty
                            </div>
                        </div>
                    </div>    
                </td>
            </tr>           
            

               
        
            
            
        </table>    
    </form>
    <div class="modal-footer mt-4">
        <button *ngIf="formData.receivedForm" type="button" class="btn customOrange float-right"
        (click)="saveForm('approve')">Approve</button>
        <button *ngIf="formData.submitForm" type="submit" class="btn btn customOrange float-right"
        (click)="saveForm('submit')">Submit</button>
        <button *ngIf="formData.submitForm" type="button" class="btn btn customOrange float-right"
        (click)="saveForm('save')">Save</button>
        <button *ngIf="formData.previewForm" type="button" class="btn customOrange float-right" (click)="close()">Close</button>
    </div>
</div>        