<div id="newManMed">
        <div class="cotainer-fluid mx-1">
            <div class="header">
                <div class="">
                    <div class="text-center">
                      <h2>APPLICATION FOR APPOINTMENT TO THE MEDICAL STAFF</h2>
                      <h4>(Use additional sheets where necessary for additional space)</h4>
                    </div>
                  </div>
            </div>
            <div class = "section">
                <form [formGroup]="newMed">
                    <div>
                        <label>
                            Date :
                        </label>
                        <div class="input-group" style="width:30%">
                            <input
                                class="form-control"
                                placeholder="MM/DD/YYYY"
                                ngbDatepicker
                                #dcmd_0090="ngbDatepicker"
                                id="cmd_0090"
                                name="cmd_0090"   
                                (blur)="clearInputIfInvalid('cmd_0090')"
                                formControlName="cmd_0090"
                                type="text"
                                data-id="date1"
                            />
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary calendar" (click)="dcmd_0090.toggle()" type="button">
                                    <i class="fas fa-calendar-alt"></i>
                                </button>
                            </div>                
                        </div>
                        <div  *ngIf="newMed.get('cmd_0090').invalid ">
                            <small class="text-danger" *ngIf="newMed.get('cmd_0090').errors?.invalidDateFormat">
                              Only Date Format is allowed(MM/DD/YYYY)
                            </small>
                            <small class="text-danger" *ngIf="newMed.get('cmd_0090').errors?.invalidMonth">
                            Month is out of range
                            </small>
                            <small class="text-danger" *ngIf="newMed.get('cmd_0090').errors?.invalidDateyear">
                              year is out of range(1900-2099)                      </small>
                              <small class="text-danger" *ngIf="newMed.get('cmd_0090').errors?.invalidDateRange">
                                date is out of range
                                </small>
                            </div>
                    </div>
                    <div style="margin-top:1px">
                        <table border="1" width=100%>
                                <tr>
                                    <td style="width: 10%;vertical-align: top;">
                                        <b><label style="font-size:15px">IDENTIFYING INFORMATION</label></b>
                                    </td>
                                    <td>
                                        <table  width =100% >
                                            <tr>
                                                <td>
                                                    <label class="formLabel">LAST NAME:</label>
                                                    <br>
                                                    <input  class="form-control"
                                                        id="9" name="9"
                                                        formControlName="9" type="text"
                                                        [class.is-invalid]="(newMed.controls['9'].touched 
                                                            && newMed.controls['9'].invalid) || 
                                                            (newMed.controls['9'].invalid && isSubmit)"
                                                        data-id="lname" placeholder="Last Name"/>
                                                        <div class="formLabel"
                                                            *ngIf="newMed.controls['9'].touched && newMed.controls['9'].invalid">
                                                            <small class="text-danger" *ngIf="newMed.controls['9'].errors?.required">
                                                                required
                                                            </small>
                                                        </div>
                                                </td>
                                                <td>    
                                                    <label class="formLabel">FIRST NAME:</label>
                                                    <br>
                                                    <input  class="form-control"
                                                        id="8" name="8"
                                                        formControlName="8" type="text"
                                                        [class.is-invalid]="(newMed.controls['8'].touched 
                                                            && newMed.controls['8'].invalid) || 
                                                            (newMed.controls['8'].invalid && isSubmit)"
                                                        data-id="fname" placeholder="First Name"/>
                                                        <div class="formLabel"
                                                            *ngIf="newMed.controls['8'].touched && newMed.controls['8'].invalid">
                                                            <small class="text-danger" *ngIf="newMed.controls['8'].errors?.required">
                                                                required
                                                            </small>
                                                        </div>
                                                </td>
                                                <td>
                                                    <label class="formLabel">INITIAL:</label>
                                                    <br>
                                                    <input  class="form-control"
                                                        id="809" name="809"
                                                        formControlName="809" type="text"
                                                        data-id="mname" placeholder="Middle Name"/>
                                                </td>
                                                <td colspan="2">
                                                    <div class="row" style="width:90%;margin-left:15px">
                                                            <label class="formLabel">DATE OF BIRTH:</label> 
                                                            <br>
                                                            <div class="input-group">
                                                                <input
                                                                    class="form-control"
                                                                    placeholder="DOB(MM/DD/YYYY)"
                                                                    ngbDatepicker
                                                                    #d31="ngbDatepicker"
                                                                    id="31"
                                                                    name="31"   
                                                                    formControlName="31"
                                                                    type="text"
                                                                    (blur)="clearInputIfInvalid('31')"
                                                                    data-id="dob_date"
                                                                />
                                                                <div class="input-group-append">
                                                                    <button class="btn btn-outline-secondary calendar" (click)="d31.toggle()" type="button">
                                                                        <i class="fas fa-calendar-alt"></i>
                                                                    </button>
                                                                </div>                
                                                            </div>
                                                            <div  *ngIf="newMed.get('31').invalid ">
                                                                <small class="text-danger" *ngIf="newMed.get('31').errors?.invalidDateFormat">
                                                                  Only Date Format is allowed(MM/DD/YYYY)
                                                                </small>
                                                                <small class="text-danger" *ngIf="newMed.get('31').errors?.invalidMonth">
                                                                Month is out of range
                                                                </small>
                                                                <small class="text-danger" *ngIf="newMed.get('31').errors?.invalidDateyear">
                                                                  year is out of range(1900-2099)                      </small>
                                                                  <small class="text-danger" *ngIf="newMed.get('31').errors?.invalidDateRange">
                                                                    date is out of range
                                                                    </small>
                                                                </div>
                                                            
                
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="5">
                                                    <label class="formLabel">BIRTH PLACE</label>
                                                    <br>
                                                    <input  class="form-control"
                                                        id="810" name="810"
                                                        formControlName="810" type="text"
                                                        data-id="cbirth" placeholder="City of Birth"/>
                                                    <input  class="form-control"
                                                        id="291" name="291"
                                                        formControlName="291" type="text"
                                                        data-id="cobirth" placeholder="Country of Birth"/>
                                                    <div class="form-group col-md-12 dropDrownSet">
                                                        <ng-select
                                                            [items]="states"
                                                            bindLabel="name"
                                                            placeholder="Select States"
                                                            bindValue="id"
                                                            formControlName="289"
                                                        >
                                                        </ng-select>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <label class="formLabel">OFFICE ADDRESS</label>
                                                    <br>
                                                    <input  class="form-control"
                                                        id="cmd_0001" name="cmd_0001"
                                                        formControlName="cmd_0001" type="text"
                                                        data-id="new_test1" placeholder="Address"/>
                                                </td>
                                                <td>
                                                    <label class="formLabel">CITY</label>
                                                    <br>
                                                    <input  class="form-control"
                                                        id="cmd_0002" name="cmd_0002"
                                                        formControlName="cmd_0002" type="text"
                                                        data-id="new_test2" placeholder="city"/>
                                                    </td>
                                                <td>
                                                    <label class="formLabel">STATE</label>
                                                    <br>
                                                    <input  class="form-control"
                                                        id="cmd_0003" name="cmd_0003"
                                                        formControlName="cmd_0003" type="text"
                                                        data-id="new_test3" placeholder="State"/>
                                                </td>
                                                <td>
                                                    <label class="formLabel">ZIP CODE</label>
                                                    <br>
                                                    <input  class="form-control"
                                                        id="cmd_0004" name="cmd_0004"
                                                        formControlName="cmd_0004" type="text"
                                                        data-id="new_test3" placeholder="Zip Code"/>
                                                </td>
                                                <td>
                                                    <label class="formLabel">TELEPHONE</label>
                                                    <br>
                                                    <input  class="form-control"
                                                        id="3734" name="3734"
                                                        formControlName="3734" type="text"
                                                        [class.is-invalid]="(newMed.controls['3734'].touched 
                                                            && newMed.controls['3734'].invalid) || 
                                                            (newMed.controls['3734'].invalid && isSubmit)"
                                                        data-id="wphone" placeholder="Work Phone"/>
                                                        <div class="formLabel"
                                                        *ngIf="newMed.controls['3734'].touched && newMed.controls['3734'].invalid">
                                                            <small class="text-danger" *ngIf="newMed.controls['3734'].errors?.pattern">
                                                                Only Numbers are allowed
                                                            </small>
                                                        </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <label class="formLabel">HOME ADDRESS</label>
                                                    <br>
                                                    <input  class="form-control"
                                                        id="16" name="16"
                                                        formControlName="16" type="text"
                                                        data-id="hadd1" placeholder="Home Address1"/>
                                                    <br>
                                                    <input  class="form-control"
                                                        id="17" name="17"
                                                        formControlName="17" type="text"
                                                        data-id="hadd2" placeholder="Home Address2"/>
                                                </td>
                                                <td>
                                                    <label class="formLabel">CITY</label>
                                                    <br>
                                                    <input  class="form-control"
                                                        id="18" name="18"
                                                        formControlName="18" type="text"
                                                        data-id="hcity" placeholder="City"/>
                                                </td>
                                                <td>
                                                    <label class="formLabel">STATE</label>
                                                    <div class="form-group col-md-12 dropDrownSet">
                                                        <ng-select
                                                            [items]="states"
                                                            bindLabel="name"
                                                            placeholder="Select States"
                                                            bindValue="id"
                                                            formControlName="20"
                                                        >
                                                        </ng-select>
                                                        <div
                                                            class="formLabel col-md-2"
                                                            *ngIf="
                                                            newMed.controls['20'].touched &&
                                                            newMed.controls['20'].invalid">
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <label class="formLabel">ZIP CODE</label>
                                                    <br>
                                                    <input  class="form-control"
                                                        id="21" name="21"
                                                        formControlName="21" type="text"
                                                        data-id="hzip" placeholder="Zip Code"/>
                                                </td>
                                                <td>
                                                    <label class="formLabel">TELEPHONE</label>
                                                    <br>
                                                    <input  class="form-control"
                                                        id="708" name="708"
                                                        formControlName="708" type="text"
                                                        [class.is-invalid]="(newMed.controls['708'].touched 
                                                            && newMed.controls['708'].invalid) || 
                                                            (newMed.controls['708'].invalid && isSubmit)"
                                                        data-id="hphone" placeholder="Home Phone"/>
                                                        <div class="formLabel"
                                                            *ngIf="newMed.controls['708'].touched && newMed.controls['708'].invalid">
                                                            <small class="text-danger" *ngIf="newMed.controls['708'].errors?.pattern">
                                                                Only Numbers are allowed
                                                            </small>
                                                        </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td  colspan="3" width=70%>
                                                    <label class="formLabel">E-MAIL ADDRESS</label>
                                                    <br>
                                                    <input  style="width:65%" class="form-control"
                                                        id="759" name="759"
                                                        formControlName="759" type="text"
                                                        [class.is-invalid]="(newMed.controls['759'].touched 
                                                            && newMed.controls['759'].invalid) || 
                                                            (newMed.controls['759'].invalid && isSubmit)"
                                                        data-id="email" placeholder="Email"/>
                                                    <div class="formLabel"
                                                        *ngIf="newMed.controls['759'].touched && newMed.controls['759'].invalid">
                                                        <small class="text-danger" *ngIf="newMed.controls['759'].errors?.pattern">
                                                            Enter Valid Email
                                                        </small>
                                                    </div>
                                                </td>
                                                <td colspan="2">
                                                    <label class="formLabel">OFFICE FAX NUMBER</label>
                                                    <br>
                                                    <input  class="form-control" style="width:75%"
                                                        id="cmd_0005" name="cmd_0005"
                                                        formControlName="cmd_0005" type="text"
                                                        data-id="new_test5" placeholder="Office fax"/>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <label class="formLabel">CITIZENSHIP</label>
                                                    <br>
                                                    <input  class="form-control"
                                                        id="292" name="292"
                                                        formControlName="292" type="text"
                                                        data-id="czip" placeholder="Citzenship"/>
                                                </td>
                                                <td colspan="2">
                                                    <label class="formLabel">SOCIAL SECURITY NO</label>
                                                    <br>
                                                    <input  class="form-control"
                                                        id="32" name="32"
                                                        formControlName="32" type="text"
                                                        [class.is-invalid]="(newMed.controls['32'].touched 
                                                            && newMed.controls['32'].invalid) || 
                                                            (newMed.controls['32'].invalid && isSubmit)"
                                                        data-id="ssn" placeholder="SSN"/>
                                                    <div class="formLabel"
                                                        *ngIf="newMed.controls['32'].touched && newMed.controls['32'].invalid">
                                                        <small class="text-danger" *ngIf="newMed.controls['32'].errors?.required">
                                                            required
                                                        </small>
                                                    </div>
                                                </td>
                                                <td>
                                                    <label class="formLabel">MARITAL STATUS</label>
                                                    <br>
                                                    <input  class="form-control"
                                                    id="1680" name="1680"
                                                    formControlName="1680" type="text"
                                                    data-id="mstatus" placeholder="Marital Status"/>
                                                </td>
                                                <td>
                                                    <label class="formLabel">NAME OF SPOUSE</label>
                                                    <br>
                                                    <input  class="form-control"
                                                        id="33" name="33"
                                                        formControlName="33" type="text"
                                                        data-id="spouse" placeholder="Spouse"/>
                                                </td>
                                            </tr>
                                        </table>
                                        <br>
                                        <div>
                                            <label class="formLabel">PRACTICE LIMITED TO</label>
                                            <input  class="form-control"
                                                    id="4041" name="4041"
                                                    formControlName="4041" type="text"
                                                    data-id="proserv" style="width:50%"/>
                                                    <br><br>
                                            <label class="formLabel">PRACTICING WITH WHOM AND NATURE OF AFFILIATION </label>
                                            <br><br>
                                            <input  class="form-control"
                                                    id="cmd_0006" name="cmd_0006"
                                                    formControlName="cmd_0006" type="text"
                                                    data-id="poaffi" style="width:50%"/>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="vertical-align: top;">
                                        <b><label style="font-size:14px">PREMEDICAL EDUCATION</label></b>
                                    </td>
                                    <td>
                                        <table width=100%>
                                            <tr>
                                                <td>
                                                    <label class="formLabel">COLLEGE OR UNIVERSITY</label>
                                                    <br>
                                                    <input  class="form-control"
                                                        id="503" name="503"
                                                        formControlName="503" type="text"
                                                        data-id="ugschool" placeholder="UG School"/>
                                                </td>
                                                <td>
                                                    <label class="formLabel">DEGREE</label>
                                                    <br>
                                                    <div class="form-group col-md-12 dropDrownSet">
                                                        <ng-select
                                                            [items]="degree"
                                                             bindLabel="name"
                                                            placeholder="Select Degree"
                                                            bindValue="id"
                                                            formControlName="512">
                                                        </ng-select>
                                                        <div
                                                            class="formLabel col-md-2"
                                                            *ngIf="
                                                            newMed.controls['512'].touched &&
                                                            newMed.controls['512'].invalid">
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <label class="formLabel">HONORS</label>
                                                    <br>
                                                    <input  class="form-control"
                                                        id="513" name="513"
                                                        formControlName="513" type="text"
                                                        data-id="ugprogram" placeholder="UG School Program"/>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="2">
                                                    <label class="formLabel">ADDRESS</label>
                                                    <br>
                                                    <input  class="form-control"
                                                        id="504" name="504"
                                                        formControlName="504" type="text"
                                                        data-id="ugadd1" placeholder="UG School Address1"/>
                                                    <input  class="form-control"
                                                        id="505" name="505"
                                                        formControlName="505" type="text"
                                                        data-id="ugadd2" placeholder="UG School Address2"/>
                                                    <input  class="form-control"
                                                        id="506" name="506"
                                                        formControlName="506" type="text"
                                                        data-id="ugcity" placeholder="UG School City"/>
                                                    <br>
                                                    <div class="form-group col-md-12 dropDrownSet">
                                                        <ng-select
                                                            [items]="states"
                                                            bindLabel="name"
                                                            placeholder="Select States"
                                                            bindValue="id"
                                                            formControlName="508">
                                                        </ng-select>
                                                        <div   class="formLabel col-md-2"
                                                            *ngIf="newMed.controls['508'].touched &&
                                                            newMed.controls['508'].invalid">
                                                        </div>
                                                    </div>
                                                    <input  class="form-control"
                                                        id="509" name="509"
                                                        formControlName="509" type="text"
                                                        data-id="ugzip" placeholder="UG School Zip"/>
                                                </td>
                                                <td>
                                                    <label class="formLabel">DATE OF GRADUATION</label>
                                                    <br>
                                                    <div class="input-group">
                                                        <input
                                                            class="form-control"
                                                            placeholder="End Date(MM/YYYY)"
                                                            ngbDatepicker
                                                            #d1238="ngbDatepicker"
                                                            id="1238"
                                                            name="1238"   
                                                            formControlName="1238"
                                                            (blur)="clearInputIfInvalid('1238')"
                                                            type="text"
                                                            data-id="ug_end_date"/>
                                                            <div class="input-group-append">
                                                                <button class="btn btn-outline-secondary calendar" (click)="d1238.toggle()" type="button">
                                                                    <i class="fas fa-calendar-alt"></i>
                                                                </button>
                                                            </div>   
                                                    </div>
                                                    <div  *ngIf="newMed.get('1238').invalid ">
                                                        <small class="text-danger" *ngIf="newMed.get('1238').errors?.invalidDateFormat">
                                                          Only Date Format is allowed(MM/DD/YYYY)
                                                        </small>
                                                        <small class="text-danger" *ngIf="newMed.get('1238').errors?.invalidMonth">
                                                        Month is out of range
                                                        </small>
                                                        <small class="text-danger" *ngIf="newMed.get('1238').errors?.invalidDateyear">
                                                          year is out of range(1900-2099)                      </small>
                                                          <small class="text-danger" *ngIf="newMed.get('1238').errors?.invalidDateRange">
                                                            date is out of range
                                                            </small>
                                                        </div>
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="vertical-align: top;">
                                    <b><label style="font-size:14px">MEDICAL EDUCATION</label></b>
                                </td>
                                <td>
                                    <table width=100%>
                                        <tr>
                                            <td>
                                                <label class="formLabel">MEDICAL SCHOOL</label>
                                                <br>
                                                <input  class="form-control"
                                                    id="490" name="490"
                                                    formControlName="490" type="text"
                                                    data-id="medschool" placeholder="Med School"/>
                                            </td>
                                            <td>
                                                <label class="formLabel">DEGREE</label>
                                                <br>
                                                <div class="form-group col-md-12 dropDrownSet">
                                                    <ng-select
                                                        [items]="degree"
                                                        bindLabel="name"
                                                        placeholder="Select Degree"
                                                        bindValue="id"
                                                        formControlName="500">
                                                    </ng-select>
                                                    <div
                                                        class="formLabel col-md-2"
                                                        *ngIf="
                                                        newMed.controls['500'].touched &&
                                                        newMed.controls['500'].invalid">
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <label class="formLabel">HONORS</label>
                                                <br>
                                                <input  class="form-control"
                                                    id="1376" name="1376"
                                                    formControlName="1376" type="text"
                                                    data-id="medprogram" placeholder="Med School Program"/>
                                            </td>   
                                        </tr>
                                        <tr>
                                            <td colspan="2">
                                                <label class="formLabel">ADDRESS</label>
                                                <br>
                                                <input  class="form-control"
                                                    id="491" name="491"
                                                    formControlName="491" type="text"
                                                    data-id="medadd1" placeholder="Med School Address1"/>
                                                <input  class="form-control"
                                                    id="492" name="492"
                                                    formControlName="492" type="text"
                                                    data-id="medadd2" placeholder="Med School Address2"/>
                                                <input  class="form-control"
                                                    id="493" name="493"
                                                    formControlName="493" type="text"
                                                    data-id="medcity" placeholder="Med School City"/>
                                                <br>
                                                <div class="form-group col-md-12 dropDrownSet">
                                                    <ng-select
                                                        [items]="states"
                                                        bindLabel="name"
                                                        placeholder="Select States"
                                                        bindValue="id"
                                                        formControlName="495">
                                                    </ng-select>
                                                    <div   class="formLabel col-md-2"
                                                        *ngIf="newMed.controls['495'].touched &&
                                                        newMed.controls['495'].invalid">
                                                    </div>
                                                </div>
                                                <input  class="form-control"
                                                        id="496" name="496"
                                                        formControlName="496" type="text"
                                                        data-id="medzip" placeholder="Med School Zip"/>
                                            </td>
                                            <td>
                                                <label class="formLabel">DATE OF GRADUATION(MO/YR)</label>
                                                <br>
                                                <div class="input-group">
                                                    <input
                                                        class="form-control"
                                                        placeholder="End Date(MM/YYYY)"
                                                        ngbDatepicker
                                                        #d498="ngbDatepicker"
                                                        id="498"
                                                        name="498"   
                                                        formControlName="498"
                                                        (blur)="clearInputIfInvalid('498')"
                                                        type="text"
                                                        data-id="med_end_date"/>
                                                    <div class="input-group-append">
                                                        <button class="btn btn-outline-secondary calendar" (click)="d498.toggle()" type="button">
                                                            <i class="fas fa-calendar-alt"></i>
                                                        </button>
                                                    </div>   
                                                </div>
                                                <div  *ngIf="newMed.get('498').invalid ">
                                                    <small class="text-danger" *ngIf="newMed.get('498').errors?.invalidDateFormat">
                                                      Only Date Format is allowed(MM/DD/YYYY)
                                                    </small>
                                                    <small class="text-danger" *ngIf="newMed.get('498').errors?.invalidMonth">
                                                    Month is out of range
                                                    </small>
                                                    <small class="text-danger" *ngIf="newMed.get('498').errors?.invalidDateyear">
                                                      year is out of range(1900-2099)                      </small>
                                                      <small class="text-danger" *ngIf="newMed.get('498').errors?.invalidDateRange">
                                                        date is out of range
                                                        </small>
                                                    </div>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                                </tr>
                            <tr>
                                <td style="vertical-align: top;">
                                    <b><label style="font-size:14px">INTERNSHIP</label></b>
                                </td>
                                <td>
                                    <table width=100%>
                                        <tr>
                                            <td>    
                                                <label class="formLabel">HOSPITAL/ADDRESS</label>
                                                <br>
                                                <input  class="form-control"
                                                        id="517" name="517"
                                                        formControlName="517" type="text"
                                                        data-id="ischool" placeholder="Intern School"/>
                                                <input  class="form-control"
                                                        id="518" name="518"
                                                        formControlName="518" type="text"
                                                        data-id="iadd1" placeholder="Intern Address1"/>
                                                <input  class="form-control"
                                                        id="519" name="519"
                                                        formControlName="519" type="text"
                                                        data-id="iadd2" placeholder="Intern Address2"/>
                                                <br>
                                                <input  class="form-control"
                                                        id="520" name="520"
                                                        formControlName="520" type="text"
                                                        data-id="icity" placeholder="Intern City"/>
                                                    <!--#TEXT Box 
                                                           [class.is-invalid]="(newMed.controls['520'].touched 
                                                        && newMed.controls['520'].invalid) || 
                                                        (newMed.controls['520'].invalid && isSubmit)"
                                                    
                                                        <div class="formLabel"
                                                    *ngIf="newMed.controls['520'].touched && newMed.controls['520'].invalid">
                                                    <small class="text-danger" *ngIf="newMed.controls['520'].errors?.required">
                                                        required
                                                    </small>
                                                </div>
                                            #dropdown
                                            [class.is-invalid]="
                                                            (newMed.controls['522'].touched &&
                                                            newMed.controls['522'].invalid) ||
                                                            (newMed.controls['522'].invalid && isSubmit)
                                                            " 
                                            #dates
                                            [class.is-invalid]="
                                                        (newMed.controls['524'].touched &&
                                                        newMed.controls['524'].invalid) ||
                                                        (newMed.controls['524'].invalid && isSubmit)"
                                            #radio buttons
                                            [class.is-invalid]="(newMed.controls['cmd_0064'].touched 
                                                        && newMed.controls['cmd_0064'].invalid) || 
                                                        (newMed.controls['cmd_0064'].invalid && isSubmit)"
                                                    
                                            <div class="formLabel"
                                                    *ngIf="newMed.controls['cmd_0064'].touched && newMed.controls['cmd_0064'].invalid">
                                                    <small class="text-danger" *ngIf="newMed.controls['cmd_0064'].errors?.required">
                                                        required
                                                    </small>
                                                </div>      
                                                
                                                <span *ngIf="(newMed.controls['cmd_0064'].touched && newMed.controls['cmd_0064'].errors)
                                                 || (newMed.controls['cmd_0064'].errors && isSubmit)">
                                                    <i class="fas fa-times ml-2 text-danger"></i>
                                                </span>                                                  
                                            -->
                                                <div class="form-group col-md-12 dropDrownSet">
                                                    <ng-select
                                                            [items]="states"
                                                            bindLabel="name"
                                                            placeholder="Select States"
                                                            bindValue="id"
                                                            formControlName="522">
                                                    </ng-select>
                                                    <div   class="formLabel col-md-2"
                                                        *ngIf="newMed.controls['522'].touched &&
                                                            newMed.controls['522'].invalid">
                                                    </div>
                                                </div>
                                                <input  class="form-control"
                                                        id="523" name="523"
                                                        formControlName="523" type="text"
                                                        data-id="izip" placeholder="Intern Zip"/>
                                            </td>
                                            <td>
                                                <label class="formLabel">DATES(MO/YR)</label>
                                                <br>
                                                <div class="input-group">
                                                    <input
                                                        class="form-control"
                                                        placeholder="Start Date(MM/YYYY)"
                                                        ngbDatepicker
                                                        #d524="ngbDatepicker"
                                                        id="524"
                                                        name="524"   
                                                        (blur)="clearInputIfInvalid('524')"
                                                        formControlName="524"
                                                        type="text"
                                                        data-id="in_start_date"/>
                                                    <div class="input-group-append">
                                                        <button class="btn btn-outline-secondary calendar" (click)="d524.toggle()" type="button">
                                                            <i class="fas fa-calendar-alt"></i>
                                                        </button>
                                                    </div>   
                                                </div>
                                                <div  *ngIf="newMed.get('524').invalid ">
                                                    <small class="text-danger" *ngIf="newMed.get('524').errors?.invalidDateFormat">
                                                      Only Date Format is allowed(MM/DD/YYYY)
                                                    </small>
                                                    <small class="text-danger" *ngIf="newMed.get('524').errors?.invalidMonth">
                                                    Month is out of range
                                                    </small>
                                                    <small class="text-danger" *ngIf="newMed.get('524').errors?.invalidDateyear">
                                                      year is out of range(1900-2099)                      </small>
                                                      <small class="text-danger" *ngIf="newMed.get('524').errors?.invalidDateRange">
                                                        date is out of range
                                                        </small>
                                                    </div>

                                                <br>
                                                <div class="input-group">
                                                    <input
                                                        class="form-control"
                                                        placeholder=" End date (MM/YYYY)"
                                                        ngbDatepicker
                                                        #d525="ngbDatepicker"
                                                        id="525"
                                                        name="525"   
                                                        formControlName="525"
                                                        (blur)="clearInputIfInvalid('525')"
                                                        type="text"
                                                        data-id="in_end_date"/>
                                                    <div class="input-group-append">
                                                        <button class="btn btn-outline-secondary calendar" (click)="d525.toggle()" type="button">
                                                            <i class="fas fa-calendar-alt"></i>
                                                        </button>
                                                    </div>   
                                                </div>
                                                <div  *ngIf="newMed.get('525').invalid ">
                                                    <small class="text-danger" *ngIf="newMed.get('525').errors?.invalidDateFormat">
                                                      Only Date Format is allowed(MM/DD/YYYY)
                                                    </small>
                                                    <small class="text-danger" *ngIf="newMed.get('525').errors?.invalidMonth">
                                                    Month is out of range
                                                    </small>
                                                    <small class="text-danger" *ngIf="newMed.get('525').errors?.invalidDateyear">
                                                      year is out of range(1900-2099)                      </small>
                                                      <small class="text-danger" *ngIf="newMed.get('525').errors?.invalidDateRange">
                                                        date is out of range
                                                        </small>
                                                    </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="2">
                                                <label class="formLabel">TYPE OF INTERNSHIP</label>
                                                <br>
                                                <div class="form-group col-md-12 dropDrownSet">
                                                    <ng-select
                                                        [items]="specialty"
                                                        bindLabel="name"
                                                        placeholder="Select Specialty"
                                                        bindValue="id"
                                                        formControlName="527"
                                                    >
                                                    </ng-select>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <label class="formLabel">PRACTITIONERS RESPONSIBLE FOR PERFORMANCE (CHIEF OF STAFF, CHAIRMEN OF DEPARTMENTS, OTHERS)</label>
                                                <br>
                                                <input  class="form-control"
                                                    id="528" name="528"
                                                    formControlName="528" type="text"
                                                    data-id="idirector" placeholder="Intern Director"/>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td style="vertical-align: top;">
                                    <b><label style="font-size:14px">RESIDENCIES</label></b>
                                </td>
                                <td>
                                    <table width=100%>
                                        <tr>
                                            <td>
                                                <label class="formLabel">LOCATION/ADDRESS</label>
                                                <br>
                                                <input  class="form-control"
                                                        id="529" name="529"
                                                        formControlName="529" type="text"
                                                        data-id="rschool" placeholder="Residency School"/>
                                                <input  class="form-control"
                                                        id="531" name="531"
                                                        formControlName="531" type="text"
                                                        data-id="radd1" placeholder="Residency Addrress1"/>
                                                <input  class="form-control"
                                                        id="1498" name="1498"
                                                        formControlName="1498" type="text"
                                                        data-id="radd2" placeholder="Residency Address2"/>
                                                <input  class="form-control"
                                                        id="533" name="533"
                                                        formControlName="533" type="text"
                                                        data-id="rcity" placeholder="Residency City"/>
                                                <br><br>
                                                <div class="form-group col-md-12 dropDrownSet">
                                                    <ng-select
                                                        [items]="states"
                                                        bindLabel="name"
                                                        placeholder="Select States"
                                                        bindValue="id"
                                                        formControlName="535">
                                                    </ng-select>
                                                    <div   class="formLabel col-md-2"
                                                        *ngIf="newMed.controls['535'].touched &&
                                                            newMed.controls['535'].invalid">
                                                    </div>
                                                </div>
                                                <input  class="form-control"
                                                        id="536" name="536"
                                                        formControlName="536" type="text"
                                                        data-id="rzip" placeholder="Residency Zip"/>
                                            </td>
                                            <td>
                                                <label class="formLabel"> DATES(MO/YR)</label>
                                                <div class="input-group">
                                                    <input
                                                        class="form-control"
                                                        placeholder="Start Date(MM/YYYY)"
                                                        ngbDatepicker
                                                        #d537="ngbDatepicker"
                                                        id="537"
                                                        name="537"   
                                                        formControlName="537"
                                                        (blur)="clearInputIfInvalid('537')"
                                                        type="text"
                                                        data-id="re_start_date"/>
                                                    <div class="input-group-append">
                                                        <button class="btn btn-outline-secondary calendar" (click)="d537.toggle()" type="button">
                                                            <i class="fas fa-calendar-alt"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div  *ngIf="newMed.get('537').invalid ">
                                                    <small class="text-danger" *ngIf="newMed.get('537').errors?.invalidDateFormat">
                                                      Only Date Format is allowed(MM/DD/YYYY)
                                                    </small>
                                                    <small class="text-danger" *ngIf="newMed.get('537').errors?.invalidMonth">
                                                    Month is out of range
                                                    </small>
                                                    <small class="text-danger" *ngIf="newMed.get('537').errors?.invalidDateyear">
                                                      year is out of range(1900-2099)                      </small>
                                                      <small class="text-danger" *ngIf="newMed.get('537').errors?.invalidDateRange">
                                                        date is out of range
                                                        </small>
                                                    </div>
                                                <br>
                                                <div class="input-group">
                                                    <input
                                                        class="form-control"
                                                        placeholder=" End date (MM/YYYY)"
                                                        ngbDatepicker
                                                        #d538="ngbDatepicker"
                                                        id="538"
                                                        name="538"   
                                                        formControlName="538"
                                                        (blur)="clearInputIfInvalid('538')"
                                                        type="text"
                                                        data-id="re_end_date"/>
                                                    <div class="input-group-append">
                                                        <button class="btn btn-outline-secondary calendar" (click)="d538.toggle()" type="button">
                                                            <i class="fas fa-calendar-alt"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div  *ngIf="newMed.get('538').invalid ">
                                                    <small class="text-danger" *ngIf="newMed.get('538').errors?.invalidDateFormat">
                                                      Only Date Format is allowed(MM/DD/YYYY)
                                                    </small>
                                                    <small class="text-danger" *ngIf="newMed.get('538').errors?.invalidMonth">
                                                    Month is out of range
                                                    </small>
                                                    <small class="text-danger" *ngIf="newMed.get('538').errors?.invalidDateyear">
                                                      year is out of range(1900-2099)                      </small>
                                                      <small class="text-danger" *ngIf="newMed.get('538').errors?.invalidDateRange">
                                                        date is out of range
                                                        </small>
                                                    </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <label class="formLabel">LOCATION/ADDRESS</label>
                                                <br>
                                                <input  class="form-control"
                                                    id="1170" name="1170"
                                                    formControlName="1170" type="text"
                                                    data-id="arschool" placeholder="Additional Residency School"/>
                                                <input  class="form-control"
                                                    id="1172" name="1172"
                                                    formControlName="1172" type="text"
                                                    data-id="aradd1" placeholder="Additional Address1"/>
                                                <input  class="form-control"
                                                    id="1173" name="1173"
                                                    formControlName="1173" type="text"
                                                    data-id="aradd2" placeholder="Additional Address2"/>
                                                <input  class="form-control"
                                                    id="1174" name="1174"
                                                    formControlName="1174" type="text"
                                                    data-id="racity" placeholder="Additional Residency City"/>
                                                <br><br>
                                                <div class="form-group col-md-12 dropDrownSet">
                                                    <ng-select
                                                        [items]="states"
                                                        bindLabel="name"
                                                        placeholder="Select States"
                                                        bindValue="id"
                                                        formControlName="1176">
                                                    </ng-select>
                                                    <div   class="formLabel col-md-2"
                                                        *ngIf="newMed.controls['1176'].touched &&
                                                            newMed.controls['1176'].invalid">
                                                    </div>
                                                </div>
                                                <input  class="form-control"
                                                    id="1177" name="1177"
                                                    formControlName="1177" type="text"
                                                    data-id="arzip" placeholder="Additional Residency Zip"/>
                                            </td>
                                            <td>
                                                <label class="formLabel">DATES(MO/YR)</label>
                                                <br>
                                                <div class="input-group">
                                                    <input
                                                        class="form-control"
                                                        placeholder="Start Date(MM/YYYY)"
                                                        ngbDatepicker
                                                        #d1178="ngbDatepicker"
                                                        id="1178"
                                                        name="1178"   
                                                        formControlName="1178"
                                                        (blur)="clearInputIfInvalid('1178')"
                                                        type="text"
                                                        data-id="are_start_date"/>
                                                    <div class="input-group-append">
                                                        <button class="btn btn-outline-secondary calendar" (click)="d1178.toggle()" type="button">
                                                            <i class="fas fa-calendar-alt"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div  *ngIf="newMed.get('1178').invalid ">
                                                    <small class="text-danger" *ngIf="newMed.get('1178').errors?.invalidDateFormat">
                                                      Only Date Format is allowed(MM/DD/YYYY)
                                                    </small>
                                                    <small class="text-danger" *ngIf="newMed.get('1178').errors?.invalidMonth">
                                                    Month is out of range
                                                    </small>
                                                    <small class="text-danger" *ngIf="newMed.get('1178').errors?.invalidDateyear">
                                                      year is out of range(1900-2099)                      </small>
                                                      <small class="text-danger" *ngIf="newMed.get('1178').errors?.invalidDateRange">
                                                        date is out of range
                                                        </small>
                                                    </div>
                                                <br>
                                                <div class="input-group">
                                                    <input
                                                        class="form-control"
                                                        placeholder=" End date (MM/YYYY)"
                                                        ngbDatepicker
                                                        #d1179="ngbDatepicker"
                                                        id="1179"
                                                        name="1179"   
                                                        formControlName="1179"
                                                        (blur)="clearInputIfInvalid('1179')"
                                                        type="text"
                                                        data-id="are_end_date"/>
                                                    <div class="input-group-append">
                                                        <button class="btn btn-outline-secondary calendar" (click)="d1179.toggle()" type="button">
                                                            <i class="fas fa-calendar-alt"></i>
                                                        </button>
                                                    </div>   
                                                </div>
                                                <div  *ngIf="newMed.get('1179').invalid ">
                                                    <small class="text-danger" *ngIf="newMed.get('1179').errors?.invalidDateFormat">
                                                      Only Date Format is allowed(MM/DD/YYYY)
                                                    </small>
                                                    <small class="text-danger" *ngIf="newMed.get('1179').errors?.invalidMonth">
                                                    Month is out of range
                                                    </small>
                                                    <small class="text-danger" *ngIf="newMed.get('1179').errors?.invalidDateyear">
                                                      year is out of range(1900-2099)                      </small>
                                                      <small class="text-danger" *ngIf="newMed.get('1179').errors?.invalidDateRange">
                                                        date is out of range
                                                        </small>
                                                    </div>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td style="vertical-align: top;">
                                    <b><label style="font-size:14px"> FELLOWSHIPS</label></b>
                                </td>
                                <td>
                                    <table width=100%>
                                        <tr>
                                            <td>
                                                <label class="formLabel">LOCATION/ADDRESS</label>
                                                <br>
                                                <input  class="form-control"
                                                    id="542" name="542"
                                                    formControlName="542" type="text"
                                                    data-id="fschool" placeholder="Fellowship School"/>
                                                <input  class="form-control"
                                                    id="544" name="544"
                                                    formControlName="544" type="text"
                                                    data-id="fadd1" placeholder="Fellowship Address1"/>
                                                <input  class="form-control"
                                                    id="545" name="545"
                                                    formControlName="545" type="text"
                                                    data-id="fadd2" placeholder="Fellowship Address2"/>
                                                <input  class="form-control"
                                                    id="546" name="546"
                                                    formControlName="546" type="text"
                                                    data-id="fcity" placeholder="Fellowship City"/>
                                                <br><br>
                                                <div class="form-group col-md-12 dropDrownSet">
                                                    <ng-select
                                                        [items]="states"
                                                        bindLabel="name"
                                                        placeholder="Select States"
                                                        bindValue="id"
                                                        formControlName="548">
                                                    </ng-select>
                                                    <div   class="formLabel col-md-2"
                                                        *ngIf="newMed.controls['548'].touched &&
                                                            newMed.controls['548'].invalid">
                                                    </div>
                                                </div>
                                                <input  class="form-control"
                                                    id="549" name="549"
                                                    formControlName="549" type="text"
                                                    data-id="fzip" placeholder="Fellowship Zip"/>
                                            </td>
                                            <td>
                                                <label class="formLabel">DATES(MO/YR)</label>
                                                <div class="input-group">
                                                    <input
                                                        class="form-control"
                                                        placeholder="Start Date(MM/YYYY)"
                                                        ngbDatepicker
                                                        #d550="ngbDatepicker"
                                                        id="550"
                                                        name="550"   
                                                        formControlName="550"
                                                        (blur)="clearInputIfInvalid('550')"
                                                        type="text"
                                                        data-id="fe_start_date"/>
                                                    <div class="input-group-append">
                                                        <button class="btn btn-outline-secondary calendar" (click)="d550.toggle()" type="button">
                                                            <i class="fas fa-calendar-alt"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div  *ngIf="newMed.get('550').invalid ">
                                                    <small class="text-danger" *ngIf="newMed.get('550').errors?.invalidDateFormat">
                                                      Only Date Format is allowed(MM/DD/YYYY)
                                                    </small>
                                                    <small class="text-danger" *ngIf="newMed.get('550').errors?.invalidMonth">
                                                    Month is out of range
                                                    </small>
                                                    <small class="text-danger" *ngIf="newMed.get('550').errors?.invalidDateyear">
                                                      year is out of range(1900-2099)                      </small>
                                                      <small class="text-danger" *ngIf="newMed.get('550').errors?.invalidDateRange">
                                                        date is out of range
                                                        </small>
                                                    </div>
                                                <br>
                                                <div class="input-group">
                                                    <input
                                                        class="form-control"
                                                        placeholder=" End date (MM/YYYY)"
                                                        ngbDatepicker
                                                        #d551="ngbDatepicker"
                                                        id="551"
                                                        name="551"   
                                                        formControlName="551"
                                                        (blur)="clearInputIfInvalid('551')"
                                                        type="text"
                                                        data-id="fe_end_date"/>
                                                    <div class="input-group-append">
                                                        <button class="btn btn-outline-secondary calendar" (click)="d551.toggle()" type="button">
                                                            <i class="fas fa-calendar-alt"></i>
                                                        </button>
                                                    </div>   
                                                </div>
                                                <div  *ngIf="newMed.get('551').invalid ">
                                                    <small class="text-danger" *ngIf="newMed.get('551').errors?.invalidDateFormat">
                                                      Only Date Format is allowed(MM/DD/YYYY)
                                                    </small>
                                                    <small class="text-danger" *ngIf="newMed.get('551').errors?.invalidMonth">
                                                    Month is out of range
                                                    </small>
                                                    <small class="text-danger" *ngIf="newMed.get('551').errors?.invalidDateyear">
                                                      year is out of range(1900-2099)                      </small>
                                                      <small class="text-danger" *ngIf="newMed.get('551').errors?.invalidDateRange">
                                                        date is out of range
                                                        </small>
                                                    </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <label class="formLabel">LOCATION/ADDRESS</label>
                                                <br>
                                                <input  class="form-control"
                                                    id="966" name="966"
                                                    formControlName="966" type="text"
                                                    data-id="afschool" placeholder="Additional Fellowship School"/>
                                                <input  class="form-control"
                                                    id="968" name="968"
                                                    formControlName="968" type="text"
                                                    data-id="afadd1" placeholder="Additional Fellowship Address1"/>
                                                <input  class="form-control"
                                                    id="969" name="969"
                                                    formControlName="969" type="text"
                                                    data-id="afadd2" placeholder="Additional Fellowship Address2"/>
                                                <input  class="form-control"
                                                    id="970" name="970"
                                                    formControlName="970" type="text"
                                                    data-id="afcity" placeholder="Additional Fellowship City"/>
                                                <br>
                                                <br>
                                                <div class="form-group col-md-12 dropDrownSet">
                                                    <ng-select
                                                        [items]="states"
                                                        bindLabel="name"
                                                        placeholder="Select States"
                                                        bindValue="id"
                                                        formControlName="972">
                                                    </ng-select>
                                                    <div   class="formLabel col-md-2"
                                                        *ngIf="newMed.controls['972'].touched &&
                                                        newMed.controls['972'].invalid">
                                                    </div>
                                                </div>
                                                <input  class="form-control"
                                                    id="1301" name="1301"
                                                    formControlName="1301" type ="text"
                                                    data-id="afzip" placeholder="Additional Fellowship Zip"/>
                                            </td>
                                            <td>
                                                <label class="formLabel">DATES(MO/YR)</label>
                                                <div class="input-group">
                                                    <input
                                                        class="form-control"
                                                        placeholder="Start Date(MM/YYYY)"
                                                        ngbDatepicker
                                                        #d974="ngbDatepicker"
                                                        id="974"
                                                        name="974"   
                                                        formControlName="974"
                                                        (blur)="clearInputIfInvalid('974')"
                                                        type="text"
                                                        data-id="afe_start_date"/>
                                                    <div class="input-group-append">
                                                        <button class="btn btn-outline-secondary calendar" (click)="d974.toggle()" type="button">
                                                            <i class="fas fa-calendar-alt"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div  *ngIf="newMed.get('974').invalid ">
                                                    <small class="text-danger" *ngIf="newMed.get('974').errors?.invalidDateFormat">
                                                      Only Date Format is allowed(MM/DD/YYYY)
                                                    </small>
                                                    <small class="text-danger" *ngIf="newMed.get('974').errors?.invalidMonth">
                                                    Month is out of range
                                                    </small>
                                                    <small class="text-danger" *ngIf="newMed.get('974').errors?.invalidDateyear">
                                                      year is out of range(1900-2099)                      </small>
                                                      <small class="text-danger" *ngIf="newMed.get('974').errors?.invalidDateRange">
                                                        date is out of range
                                                        </small>
                                                    </div>
                                                <br>
                                                <div class="input-group">
                                                    <input
                                                        class="form-control"
                                                        placeholder=" End date (MM/YYYY)"
                                                        ngbDatepicker
                                                        #d975="ngbDatepicker"
                                                        id="975"
                                                        name="975"
                                                        formControlName="975"
                                                        (blur)="clearInputIfInvalid('975')"
                                                        type="text"
                                                        data-id="afe_end_date"/>
                                                    <div class="input-group-append">
                                                        <button class="btn btn-outline-secondary calendar" (click)="d975.toggle()" type="button">
                                                            <i class="fas fa-calendar-alt"></i>
                                                        </button>
                                                    </div>   
                                                </div>
                                                <div  *ngIf="newMed.get('975').invalid ">
                                                    <small class="text-danger" *ngIf="newMed.get('975').errors?.invalidDateFormat">
                                                      Only Date Format is allowed(MM/DD/YYYY)
                                                    </small>
                                                    <small class="text-danger" *ngIf="newMed.get('975').errors?.invalidMonth">
                                                    Month is out of range
                                                    </small>
                                                    <small class="text-danger" *ngIf="newMed.get('975').errors?.invalidDateyear">
                                                      year is out of range(1900-2099)                      </small>
                                                      <small class="text-danger" *ngIf="newMed.get('975').errors?.invalidDateRange">
                                                        date is out of range
                                                        </small>
                                                    </div>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td style="vertical-align: top;">
                                    <b><label style="font-size:14px;">LEGAL COMPLIANCE</label></b>
                                </td>
                                <td>
                                    <div class="row" style="margin-left:10px">
                                       <p style="font-size:14px">ARE YOU DIRECTLY OR INDIRECTLY, A PARTY TO ANY EMPLOYMENT OR COMPENSATION WITH RELATIONSHIP OR
                                        INVESTMENT INTEREST IN ANY HOSPITAL, HEALTH SYSTEM OR OTHER HEALTH CARE PROVIDER? </p>
                                        <input
                                            type="radio"
                                            id="cmd_0073"
                                            name="cmd_0073"
                                            (ngModelChange)="handleYesNoChanges('cmd_0073')"
                                            formControlName="cmd_0073"
                                            value="1" />Yes
                                        <input
                                            type="radio"
                                            id="cmd_0073"
                                            name="cmd_0073"
                                            (ngModelChange)="handleYesNoChanges('cmd_0073')"
                                            formControlName="cmd_0073"
                                            value="0" />No
                                        <!--<span *ngIf="(newMed.controls['cmd_0073'].touched && newMed.controls['cmd_0073'].errors)
                                            || (newMed.controls['cmd_0073'].errors && isSubmit)">
                                           <i class="fas fa-times ml-2 text-danger"></i>
                                        </span>-->
                                        <br>
                                    </div>
                                    
                                    <div style="margin-left:10px">
                                        <label>IF YES, WITH WHOM, PLEASE EXPLAIN</label>
                                        <br>
                                        <!-- <div>
                                            <textarea (blur)="handleYesNoChanges('cmd_0073')"  id="cmd_0007" 
                                            class="form-control" name="cmd_0007" id="cmd_0007" formControlName="cmd_0007" cols="30"
                                            rows="10" style="height:90px"></textarea>
                                            
                                        </div> -->
                                        <div>
                                            <textarea   id="cmd_0007" 
                                            class="form-control" name="cmd_0007" id="cmd_0007" formControlName="cmd_0007" cols="30"
                                            rows="10" style="height:90px"></textarea>
                                            
                                        </div>
                                   </div>
                                </td>
                            </tr>
                            <tr>
                                <td style="border-right:hidden">
                                    <b><label style="font-size:14px">I HEREBY APPLY TO THE HOSPITAL FOR APPOINTMENT</label></b>
                                </td>
                                <td>
                                    <input type="checkbox" value="ActiveStaff" formControlName="cmd_0080" id="cmd_0080" (ngModelChange)="handleCheckboxChanges('cmd_0080')" style="margin-left:20%" [class.is-invalid]="newMed.controls['cmd_0080'].invalid">
                                    <label for="">TO THE ACTIVE STAFF</label>
                                    
                                    <br>
                                    <input type="checkbox" value="NonResident" formControlName="cmd_0081" id="cmd_0081" (ngModelChange)="handleCheckboxChanges('cmd_0081')" style="margin-left:20%" [class.is-invalid]="newMed.controls['cmd_0081'].invalid">
                                    <label for="">TO THE ACTIVE NON RESIDENT STAFF</label>
                                    <br>
                                    <input type="checkbox" value="AffiliateStaff" formControlName="cmd_0082" id="cmd_0082" (ngModelChange)="handleCheckboxChanges('cmd_0082')" style="margin-left:20%" [class.is-invalid]="newMed.controls['cmd_0082'].invalid">
                                     <label for="">TO THE AFFILIATE STAFF</label>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2" style="border-top:hidden">
                                    <b><p style="font-size:14px;margin-top:25px">Note: A $150.00 processing fee is payable to Newman Regional Health at the time the application is submitted. Please provide a summary report of your previous 2 years clinical activity including approximate number, type and location of patients, procedures performed; diagnoses treated, consultations rendered.</p></b>
                                </td>
                            </tr>
                            <tr>
                                <td style="vertical-align: top;">
                                    <b><label style="font-size:14px">AFFILIATIONS</label></b>
                                </td>
                                <td>
                                    <div class="row" style="margin-left:10px;width:90%">
                                        <p style="font-size:14px">LIST ALL PRESENT AND PREVIOUS HOSPITAL AND/OR MEDICAL RELATED FACILITY AFFILIATIONS AND MEDICAL STAFF MEMBERSHIPS, IN CHRONOLOGICAL ORDER (INCLUDE ASSISTANTSHIPS AND APPOINTMENTS)
                                        </p>
                                        <p style="font-size:12px">Please use additional sheet of paper if necessary
                                        </p>
                                    </div>
                                    <table width=100%>
                                        <tr>
                                            <td>
                                                <label>NAME AND LOCATION OF HOSPITAL</label>
                                                <br>
                                                <input  class="form-control"
                                                    id="418" name="418"
                                                    formControlName="418" type="text"
                                                    data-id="af1_name" placeholder="Hospital Affiliation 1 Name"/>
                                                <input  class="form-control"
                                                    id="419" name="419"
                                                    formControlName="419" type="text"
                                                    data-id="af1_add1" placeholder="Hospital Affiliation 1 Address1"/>
                                                <input  class="form-control"
                                                    id="420" name="420"
                                                    formControlName="420" type="text"
                                                    data-id="af1_add2" placeholder="Hospital Affiliation 1 Address2"/>
                                                <input  class="form-control"
                                                    id="421" name="421"
                                                    formControlName="421" type="text"
                                                    data-id="af1_city" placeholder="Hospital Affiliation 1 City"/>
                                                    <br><br>
                                                <div class="form-group col-md-12 dropDrownSet">
                                                    <ng-select
                                                        [items]="states"
                                                        bindLabel="name"
                                                        placeholder="Select States"
                                                        bindValue="id"
                                                        formControlName="423">
                                                    </ng-select>
                                                    <div
                                                        class="formLabel col-md-2"
                                                        *ngIf="newMed.controls['423'].touched &&
                                                            newMed.controls['423'].invalid">
                                                    </div>
                                                </div>
                                                <input  class="form-control"
                                                    id="424" name="424"
                                                    formControlName="424" type="text"
                                                    data-id="af1_zip" placeholder="Hospital Affiliation 1 Zip"/> 
                                            </td>
                                            <td>
                                                <label>DATES(MO/YR)</label>
                                                <br>
                                                <div class="input-group">
                                                    <input
                                                        class="form-control"
                                                        placeholder="Start Date(MM/YYYY)"
                                                        ngbDatepicker
                                                        #d426="ngbDatepicker"
                                                        id="426"
                                                        name="426"   
                                                        formControlName="426"
                                                        (blur)="clearInputIfInvalid('426')"
                                                        type="text"
                                                        data-id="hos_aff1_app_date"/>
                                                    <div class="input-group-append">
                                                        <button class="btn btn-outline-secondary calendar" (click)="d426.toggle()" type="button">
                                                            <i class="fas fa-calendar-alt"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div  *ngIf="newMed.get('426').invalid ">
                                                    <small class="text-danger" *ngIf="newMed.get('426').errors?.invalidDateFormat">
                                                      Only Date Format is allowed(MM/DD/YYYY)
                                                    </small>
                                                    <small class="text-danger" *ngIf="newMed.get('426').errors?.invalidMonth">
                                                    Month is out of range
                                                    </small>
                                                    <small class="text-danger" *ngIf="newMed.get('426').errors?.invalidDateyear">
                                                      year is out of range(1900-2099)                      </small>
                                                      <small class="text-danger" *ngIf="newMed.get('426').errors?.invalidDateRange">
                                                        date is out of range
                                                        </small>
                                                    </div>
                                                    <br><br>
                                                <div class="input-group">
                                                    <input
                                                        class="form-control "
                                                        placeholder="End Date(MM/YYYY)"
                                                        ngbDatepicker
                                                        #d1370="ngbDatepicker"
                                                        id="1370"
                                                        name="1370"   
                                                        formControlName="1370"
                                                        (blur)="clearInputIfInvalid('1370')"
                                                        type="text"
                                                        data-id="hos_aff1_end_date"/>
                                                    <div class="input-group-append">
                                                        <button class="btn btn-outline-secondary calendar" (click)="d1370.toggle()" type="button">
                                                            <i class="fas fa-calendar-alt"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div  *ngIf="newMed.get('1370').invalid ">
                                                    <small class="text-danger" *ngIf="newMed.get('1370').errors?.invalidDateFormat">
                                                      Only Date Format is allowed(MM/DD/YYYY)
                                                    </small>
                                                    <small class="text-danger" *ngIf="newMed.get('1370').errors?.invalidMonth">
                                                    Month is out of range
                                                    </small>
                                                    <small class="text-danger" *ngIf="newMed.get('1370').errors?.invalidDateyear">
                                                      year is out of range(1900-2099)                      </small>
                                                      <small class="text-danger" *ngIf="newMed.get('1370').errors?.invalidDateRange">
                                                        date is out of range
                                                        </small>
                                                    </div>
                                            </td>
                                            <td>
                                                <label>CAPACITY</label>
                                                <br>
                                                <input  class="form-control"
                                                    id="cmd_050" name="cmd_050"
                                                    formControlName="cmd_050" type="text"
                                                    data-id="capacity1" placeholder="capacity"/>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <label>NAME AND LOCATION OF HOSPITAL</label>
                                                <br>
                                                <input  class="form-control"
                                                        id="427" name="427"
                                                        formControlName="427" type="text"
                                                        data-id="af2_name" placeholder="Affiliation 2 name"/>
                                                    <input  class="form-control"
                                                        id="428" name="428"
                                                        formControlName="428" type="text"
                                                        data-id="af2_add1" placeholder="Affiliation 2 Address1"/>
                                                    <input  class="form-control"
                                                        id="429" name="429"
                                                        formControlName="429" type="text"
                                                        data-id="af2_add2" placeholder="Affiliation 2 Address2"/>
                                                    <input  class="form-control"
                                                        id="430" name="430"
                                                        formControlName="430" type="text"
                                                        data-id="af2_city" placeholder="Affiliation 2 City"/>
                                                    <br><br>
                                                    <div class="form-group col-md-12 dropDrownSet">
                                                        <ng-select
                                                            [items]="states"
                                                            bindLabel="name"
                                                            placeholder="Select States"
                                                            bindValue="id"
                                                            formControlName="432">
                                                        </ng-select>
                                                    </div>
                                                    <input  class="form-control"
                                                        id="433" name="433"
                                                        formControlName="433" type="text"
                                                        data-id="af2_zip" placeholder="Affilaition 2 Zip"/> 
                                            </td>
                                            <td>
                                                <label>DATES(MO/YR)</label>
                                                <div class="input-group">
                                                    <input
                                                        class="form-control"
                                                        placeholder="Start Date(MM/YYYY)"
                                                        ngbDatepicker
                                                        #d435="ngbDatepicker"
                                                        id="435"
                                                        name="435"   
                                                        formControlName="435"
                                                        (blur)="clearInputIfInvalid('435')"
                                                        type="text"
                                                        data-id="hos_aff2_app_date"/>
                                                    <div class="input-group-append">
                                                        <button class="btn btn-outline-secondary calendar" (click)="d435.toggle()" type="button">
                                                            <i class="fas fa-calendar-alt"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div  *ngIf="newMed.get('435').invalid ">
                                                    <small class="text-danger" *ngIf="newMed.get('435').errors?.invalidDateFormat">
                                                      Only Date Format is allowed(MM/DD/YYYY)
                                                    </small>
                                                    <small class="text-danger" *ngIf="newMed.get('435').errors?.invalidMonth">
                                                    Month is out of range
                                                    </small>
                                                    <small class="text-danger" *ngIf="newMed.get('435').errors?.invalidDateyear">
                                                      year is out of range(1900-2099)                      </small>
                                                      <small class="text-danger" *ngIf="newMed.get('435').errors?.invalidDateRange">
                                                        date is out of range
                                                        </small>
                                                    </div>
                                                    <br><br>
                                                <div class="input-group">
                                                    <input
                                                        class="form-control"
                                                        placeholder="End Date(MM/YYYY)"
                                                        ngbDatepicker
                                                        #d1371="ngbDatepicker"
                                                        id="1371"
                                                        name="1371"   
                                                        formControlName="1371"
                                                        (blur)="clearInputIfInvalid('1371')"
                                                        type="text"
                                                        data-id="hos_aff2_end_date"/>
                                                    <div class="input-group-append">
                                                        <button class="btn btn-outline-secondary calendar" (click)="d1371.toggle()" type="button">
                                                            <i class="fas fa-calendar-alt"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div  *ngIf="newMed.get('1371').invalid ">
                                                    <small class="text-danger" *ngIf="newMed.get('1371').errors?.invalidDateFormat">
                                                      Only Date Format is allowed(MM/DD/YYYY)
                                                    </small>
                                                    <small class="text-danger" *ngIf="newMed.get('1371').errors?.invalidMonth">
                                                    Month is out of range
                                                    </small>
                                                    <small class="text-danger" *ngIf="newMed.get('1371').errors?.invalidDateyear">
                                                      year is out of range(1900-2099)                      </small>
                                                      <small class="text-danger" *ngIf="newMed.get('1371').errors?.invalidDateRange">
                                                        date is out of range
                                                        </small>
                                                    </div>
                                            </td>
                                            <td>
                                                <label>CAPACITY</label>
                                                <br>
                                                <input  class="form-control"
                                                    id="cmd_051" name="cmd_051"
                                                    formControlName="cmd_051" type="text"
                                                    data-id="capacity2"/>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <label>NAME AND LOCATION OF HOSPITAL</label>
                                                <br>
                                                <input  class="form-control"
                                                    id="436" name="436"
                                                    formControlName="436" type="text"
                                                    data-id="af3_name" placeholder="Affiliation 3 Name"/>
                                                <input  class="form-control"
                                                    id="437" name="437"
                                                    formControlName="437" type="text"
                                                    data-id="af3_add1" placeholder="Affiliation 3 Address1"/>
                                                <input  class="form-control"
                                                    id="438" name="438"
                                                    formControlName="438" type="text"
                                                    data-id="af3_add2" placeholder="Affiliation 3 Address2"/>
                                                <input  class="form-control"
                                                    id="439" name="439"
                                                    formControlName="439" type="text"
                                                    data-id="af3_city" placeholder="Affiliation 3 City"/>
                                                <br><br>
                                                <div class="form-group col-md-12 dropDrownSet">
                                                    <ng-select
                                                        [items]="states"
                                                        bindLabel="name"
                                                        placeholder="Select States"
                                                        bindValue="id"
                                                        formControlName="441">
                                                    </ng-select>
                                                </div>
                                                <input  class="form-control"
                                                    id="442" name="442"
                                                    formControlName="442" type="text"
                                                    data-id="af3_zip" placeholder=" Affiliation 3 Zip"/> 
                                            </td>
                                            <td>
                                                <label>DATES(MO/YR)</label>
                                                <div class="input-group">
                                                    <input
                                                        class="form-control"
                                                        placeholder="Start Date(MM/YYYY)"
                                                        ngbDatepicker
                                                        #d444="ngbDatepicker"
                                                        id="444"
                                                        name="444"   
                                                        formControlName="444"
                                                        (blur)="clearInputIfInvalid('444')"
                                                        type="text"
                                                        data-id="hos_aff3_app_date"/>
                                                    <div class="input-group-append">
                                                        <button class="btn btn-outline-secondary calendar" (click)="d444.toggle()" type="button">
                                                            <i class="fas fa-calendar-alt"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div  *ngIf="newMed.get('444').invalid ">
                                                    <small class="text-danger" *ngIf="newMed.get('444').errors?.invalidDateFormat">
                                                      Only Date Format is allowed(MM/DD/YYYY)
                                                    </small>
                                                    <small class="text-danger" *ngIf="newMed.get('444').errors?.invalidMonth">
                                                    Month is out of range
                                                    </small>
                                                    <small class="text-danger" *ngIf="newMed.get('444').errors?.invalidDateyear">
                                                      year is out of range(1900-2099)                      </small>
                                                      <small class="text-danger" *ngIf="newMed.get('444').errors?.invalidDateRange">
                                                        date is out of range
                                                        </small>
                                                    </div>
                                                <br><br>
                                                <div class="input-group">
                                                    <input
                                                        class="form-control"
                                                        placeholder="End Date(MM/YYYY)"
                                                        ngbDatepicker
                                                        #d1372="ngbDatepicker"
                                                        id="1372"
                                                        name="1372"   
                                                        formControlName="1372"
                                                        (blur)="clearInputIfInvalid('1372')"
                                                        type="text"
                                                        data-id="hos_aff3_end_date"/>
                                                    <div class="input-group-append">
                                                        <button class="btn btn-outline-secondary calendar" (click)="d1372.toggle()" type="button">
                                                            <i class="fas fa-calendar-alt"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div  *ngIf="newMed.get('1372').invalid ">
                                                    <small class="text-danger" *ngIf="newMed.get('1372').errors?.invalidDateFormat">
                                                      Only Date Format is allowed(MM/DD/YYYY)
                                                    </small>
                                                    <small class="text-danger" *ngIf="newMed.get('1372').errors?.invalidMonth">
                                                    Month is out of range
                                                    </small>
                                                    <small class="text-danger" *ngIf="newMed.get('1372').errors?.invalidDateyear">
                                                      year is out of range(1900-2099)                      </small>
                                                      <small class="text-danger" *ngIf="newMed.get('1372').errors?.invalidDateRange">
                                                        date is out of range
                                                        </small>
                                                    </div>
                                            </td>
                                            <td>
                                                <label>CAPACITY</label><br>
                                                <input  class="form-control"
                                                    id="cmd_052" name="cmd_052"
                                                    formControlName="cmd_052" type="text"
                                                    data-id="capacity3"/>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <label>NAME AND LOCATION OF HOSPITAL</label>
                                                <br><br>
                                                <input  class="form-control"
                                                    id="986" name="986"
                                                    formControlName="986" type="text"
                                                    data-id="af_ad_name" placeholder="Additional Affiliation Name"/>
                                                <input  class="form-control"
                                                    id="987" name="987" 
                                                    formControlName="987" type="text"
                                                    data-id="af_ad_add1" placeholder="Additional Affiliation Address1"/>
                                                <input  class="form-control"
                                                    id="988" name="988"
                                                    formControlName="988" type="text"
                                                    data-id="af_ad_add2" placeholder="Additional Affiliation Address2"/>
                                                <input  class="form-control"
                                                    id="989" name="989"
                                                    formControlName="989" type="text"
                                                    data-id="af_ad_city" placeholder="Additional Affiliaton City"/>
                                                <br><br>
                                                <div class="form-group col-md-12 dropDrownSet">
                                                    <ng-select
                                                        [items]="states"
                                                        bindLabel="name"
                                                        placeholder="Select States"
                                                            bindValue="id"
                                                            formControlName="991">
                                                        </ng-select>
                                                    </div>
                                                    <input  class="form-control"
                                                        id="992" name="992"
                                                        formControlName="992" type="text"
                                                        data-id="af_ad_zip" placeholder="Additional Affiliation Zip"/> 
                                            </td>
                                            <td>
                                                <label>DATES(MO/YR)</label>
                                                <br>
                                                <div class="input-group">
                                                    <input
                                                        class="form-control"
                                                        placeholder="Start Date(MM/YYYY)"
                                                        ngbDatepicker
                                                        #d994="ngbDatepicker"
                                                        id="994"
                                                        name="994"   
                                                        (blur)="clearInputIfInvalid('994')"
                                                        formControlName="994"
                                                        type="text"
                                                        data-id="hos_aff_add_app_date"/>
                                                    <div class="input-group-append">
                                                        <button class="btn btn-outline-secondary calendar" (click)="d994.toggle()" type="button">
                                                            <i class="fas fa-calendar-alt"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div  *ngIf="newMed.get('994').invalid ">
                                                    <small class="text-danger" *ngIf="newMed.get('994').errors?.invalidDateFormat">
                                                      Only Date Format is allowed(MM/DD/YYYY)
                                                    </small>
                                                    <small class="text-danger" *ngIf="newMed.get('994').errors?.invalidMonth">
                                                    Month is out of range
                                                    </small>
                                                    <small class="text-danger" *ngIf="newMed.get('994').errors?.invalidDateyear">
                                                      year is out of range(1900-2099)                      </small>
                                                      <small class="text-danger" *ngIf="newMed.get('994').errors?.invalidDateRange">
                                                        date is out of range
                                                        </small>
                                                    </div>
                                                <br><br>
                                                <div class="input-group">
                                                    <input
                                                        class="form-control"
                                                        placeholder="End Date(MM/YYYY)"
                                                        ngbDatepicker
                                                        #d1373="ngbDatepicker"
                                                        id="1373"
                                                        name="1373"
                                                        formControlName="1373"
                                                        (blur)="clearInputIfInvalid('1373')"
                                                        type="text"
                                                        data-id="hos_aff_add_end_date"/>
                                                    <div class="input-group-append">
                                                        <button class="btn btn-outline-secondary calendar" (click)="d1373.toggle()" type="button">
                                                            <i class="fas fa-calendar-alt"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div  *ngIf="newMed.get('1373').invalid ">
                                                    <small class="text-danger" *ngIf="newMed.get('1373').errors?.invalidDateFormat">
                                                      Only Date Format is allowed(MM/DD/YYYY)
                                                    </small>
                                                    <small class="text-danger" *ngIf="newMed.get('1373').errors?.invalidMonth">
                                                    Month is out of range
                                                    </small>
                                                    <small class="text-danger" *ngIf="newMed.get('1373').errors?.invalidDateyear">
                                                      year is out of range(1900-2099)                      </small>
                                                      <small class="text-danger" *ngIf="newMed.get('1373').errors?.invalidDateRange">
                                                        date is out of range
                                                        </small>
                                                    </div>
                                            </td>
                                            <td>
                                                <label>CAPACITY</label>
                                                <br>
                                                <input  class="form-control"
                                                    id="cmd_053" name="cmd_053"
                                                    formControlName="cmd_053" type="text"
                                                    data-id="capacity4"/>
                                            </td>
                                        </tr>
                                        </table>
                                </td>
                            </tr>
                            <tr>
                                <td style="vertical-align: top;">
                                   <b><label style="font-size:14px">CERTIFICATION</label></b> 
                                </td>
                                <td>    
                                    <table>
                                        <tr>
                                            <td>
                                                <label>CERTIFIED BY AMERICAN BOARD OF(NAME OF BOARD)</label>
                                                <br>
                                                <div class="form-group col-md-12 dropDrownSet">
                                                    <ng-select
                                                        [items]="boardCertification"
                                                        bindLabel="name"
                                                        placeholder="Select Board"
                                                        bindValue="id"
                                                        formControlName="554">
                                                    </ng-select>
                                                    <div
                                                        class="formLabel col-md-2"
                                                        *ngIf="
                                                        newMed.controls['554'].touched &&
                                                        newMed.controls['554'].invalid">
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <label>DATE</label>
                                                <br>
                                                <div class="input-group">
                                                    <input
                                                        class="form-control"
                                                        placeholder="Start Date(MM/DD/YYYY)"
                                                        ngbDatepicker
                                                        #d557="ngbDatepicker"
                                                        id="557"
                                                        name="557"   
                                                        formControlName="557"
                                                        (blur)="clearInputIfInvalid('557')"
                                                        type="text"
                                                        data-id="br_issue_date"/>
                                                    <div class="input-group-append">
                                                        <button class="btn btn-outline-secondary calendar" (click)="d557.toggle()" type="button">
                                                            <i class="fas fa-calendar-alt"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div  *ngIf="newMed.get('557').invalid ">
                                                    <small class="text-danger" *ngIf="newMed.get('557').errors?.invalidDateFormat">
                                                      Only Date Format is allowed(MM/DD/YYYY)
                                                    </small>
                                                    <small class="text-danger" *ngIf="newMed.get('557').errors?.invalidMonth">
                                                    Month is out of range
                                                    </small>
                                                    <small class="text-danger" *ngIf="newMed.get('557').errors?.invalidDateyear">
                                                      year is out of range(1900-2099)                      </small>
                                                      <small class="text-danger" *ngIf="newMed.get('557').errors?.invalidDateRange">
                                                        date is out of range
                                                        </small>
                                                    </div>
                                            </td>
                                            <td>
                                                <label>RECERTIFICATION DATE</label>
                                                <br>
                                                <div class="input-group">
                                                    <input
                                                        class="form-control"
                                                        placeholder="Exp Date(MM/DD/YYYY)"
                                                        ngbDatepicker
                                                        #d560="ngbDatepicker"
                                                        id="560"
                                                        name="560"   
                                                        formControlName="560"
                                                        (blur)="clearInputIfInvalid('560')"
                                                        type="text"
                                                        data-id="br_exp_date"/>
                                                    <div class="input-group-append">
                                                        <button class="btn btn-outline-secondary calendar" (click)="d560.toggle()" type="button">
                                                            <i class="fas fa-calendar-alt"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div  *ngIf="newMed.get('560').invalid ">
                                                    <small class="text-danger" *ngIf="newMed.get('560').errors?.invalidDateFormat">
                                                      Only Date Format is allowed(MM/DD/YYYY)
                                                    </small>
                                                    <small class="text-danger" *ngIf="newMed.get('560').errors?.invalidMonth">
                                                    Month is out of range
                                                    </small>
                                                    <small class="text-danger" *ngIf="newMed.get('560').errors?.invalidDateyear">
                                                      year is out of range(1900-2099)                      </small>
                                                      <small class="text-danger" *ngIf="newMed.get('560').errors?.invalidDateRange">
                                                        date is out of range
                                                        </small>
                                                    </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="2">
                                                <label>BOARD QUALIFIED(NAME OF BOARD)</label>
                                                <br>
                                                <input  class="form-control"
                                                        id="cmd_0061" name="cmd_0061"
                                                        formControlName="cmd_0061" type="text"
                                                        data-id="br_quali" style="width:100%"/> 
                                            </td>
                                            <td>
                                                <label>DATE</label>
                                                <br>
                                                <div class="input-group">
                                                    <input
                                                        class="form-control"
                                                        placeholder="Date(MM/DD/YYYY)"
                                                        ngbDatepicker
                                                        #dcmd_0062="ngbDatepicker"
                                                        id="cmd_0062"
                                                        name="cmd_0062"   
                                                        formControlName="cmd_0062"
                                                        (blur)="clearInputIfInvalid('cmd_0062')"
                                                        type="text"
                                                        data-id="bqua_date"/>
                                                    <div class="input-group-append">
                                                        <button class="btn btn-outline-secondary calendar" (click)="dcmd_0062.toggle()" type="button">
                                                            <i class="fas fa-calendar-alt"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div  *ngIf="newMed.get('cmd_0062').invalid ">
                                                    <small class="text-danger" *ngIf="newMed.get('cmd_0062').errors?.invalidDateFormat">
                                                      Only Date Format is allowed(MM/DD/YYYY)
                                                    </small>
                                                    <small class="text-danger" *ngIf="newMed.get('cmd_0062').errors?.invalidMonth">
                                                    Month is out of range
                                                    </small>
                                                    <small class="text-danger" *ngIf="newMed.get('cmd_0062').errors?.invalidDateyear">
                                                      year is out of range(1900-2099)                      </small>
                                                      <small class="text-danger" *ngIf="newMed.get('cmd_0062').errors?.invalidDateRange">
                                                        date is out of range
                                                        </small>
                                                    </div>
                                            </td>                                                
                                        </tr>
                                        <tr>
                                            <td>
                                                <label>SPECIALTY BOARD STATUS(NAME OF BOARD)</label>
                                                <br>
                                                <input  class="form-control"
                                                        id="cmd_0068" name="cmd_0068"
                                                        formControlName="cmd_0068" type="text"
                                                        data-id="br_spec" style="width:100%"/> 
                                            </td>
                                            <td>
                                                <label>ARE YOU CERTIFIED?</label>
                                                <br>
                                                <input
                                                    type="radio"
                                                    id="cmd_0064"
                                                    name="cmd_0064"
                                                    formControlName="cmd_0064"
                                                    value="1" />Yes
                                                <input
                                                    type="radio"
                                                    id="cmd_0064"
                                                    name="cmd_0064"
                                                    formControlName="cmd_0064"
                                                    value="0" />No
                                            </td> 
                                            <td>
                                                <label>DATE</label>
                                                <br>
                                                <div class="input-group">
                                                    <input
                                                        class="form-control"
                                                        placeholder="Date(MM/DD/YYYY)"
                                                        ngbDatepicker
                                                        #dcmd_0067="ngbDatepicker"
                                                        id="cmd_0067"
                                                        name="cmd_0067"   
                                                        formControlName="cmd_0067"
                                                        (blur)="clearInputIfInvalid('cmd_0067')"
                                                        type="text"
                                                        data-id="bspec_date"/>
                                                    <div class="input-group-append">
                                                        <button class="btn btn-outline-secondary calendar" (click)="dcmd_0067.toggle()" type="button">
                                                            <i class="fas fa-calendar-alt"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div  *ngIf="newMed.get('cmd_0067').invalid ">
                                                    <small class="text-danger" *ngIf="newMed.get('cmd_0067').errors?.invalidDateFormat">
                                                      Only Date Format is allowed(MM/DD/YYYY)
                                                    </small>
                                                    <small class="text-danger" *ngIf="newMed.get('cmd_0067').errors?.invalidMonth">
                                                    Month is out of range
                                                    </small>
                                                    <small class="text-danger" *ngIf="newMed.get('cmd_0067').errors?.invalidDateyear">
                                                      year is out of range(1900-2099)                      </small>
                                                      <small class="text-danger" *ngIf="newMed.get('cmd_0067').errors?.invalidDateRange">
                                                        date is out of range
                                                        </small>
                                                    </div>
                                            </td>                                                
                                        </tr>
                                        <tr>
                                            <td >
                                                    <p>IF NOT CERTIFIED, GIVE PRESENT STATUS</p>
                                                    <p>( including no status, admissible to take the exam, taken part I and/or part II, passed or failed, number of times )</p>
                                                    
                                                    <input  class="form-control"
                                                        id="cmd_0063" name="cmd_0063"
                                                        formControlName="cmd_0063" type="text"
                                                        data-id="br_cert" style="width:100%"/> 
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td style="vertical-align: top;">
                                    <b><label style="font-size:14px;">LICENSING</label></b>
                                </td>
                                <td>
                                    <table width=100%   >
                                        <tr>
                                            <td colspan="2">
                                                <label>MEDICAL LICENSCE (S) (NAME OF STATE)</label>
                                                <p>(List all past & current)</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <input  class="form-control"
                                                    id="293" name="293"
                                                    formControlName="293" type="text"
                                                    data-id="lic_num" placeholder="License Number"/>
                                                <br>
                                                <input  class="form-control"
                                                    id="300" name="300"
                                                    formControlName="300" type="text"
                                                    data-id="alt_lic_num" placeholder="License 2 Number"/>
                                            </td>
                                            <td>
                                                <div class="form-group col-md-12 dropDrownSet">
                                                    <ng-select
                                                        [items]="states"
                                                        bindLabel="name"
                                                        placeholder="Select States"
                                                        bindValue="id"
                                                        formControlName="299">
                                                    </ng-select>
                                                    <div
                                                        class="formLabel col-md-2">
                                                    </div>
                                                </div>
                                                <br>
                                                <div class="form-group col-md-12 dropDrownSet">
                                                    <ng-select
                                                        [items]="altLicenseState"
                                                        bindLabel="name"
                                                        placeholder="Select States"
                                                        bindValue="id"
                                                        formControlName="907">
                                                    </ng-select>
                                                    <div
                                                        class="formLabel col-md-2">
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <input  class="form-control"
                                                    id="add_1_999" name="add_1_999"
                                                    formControlName="add_1_999" type="text"
                                                    data-id="add_lic_1" placeholder="Additional License Number"/> 
                                                <br>
                                                <input  class="form-control"
                                                    id="add_3_999" name="add_3_999"
                                                    formControlName="add_3_999" type="text"
                                                    data-id="add_lic_3" placeholder="Additional Licensce Number 3"/>
                                            </td>
                                            <td>
                                                <div class="form-group col-md-12 dropDrownSet">
                                                    <ng-select
                                                        [items]="additionalLicenseState"
                                                        bindLabel="name"
                                                        placeholder="Select States"
                                                        bindValue="id"
                                                        formControlName="add_1_1010">
                                                    </ng-select>
                                                    <div
                                                        class="formLabel col-md-2">
                                                    </div>
                                                </div>
                                                <br>
                                                <div class="form-group col-md-12 dropDrownSet">
                                                    <ng-select
                                                        [items]="additionalLicenseState"
                                                        bindLabel="name"
                                                        placeholder="Select States"
                                                        bindValue="id"
                                                        formControlName="add_3_1010">
                                                    </ng-select>
                                                    <div
                                                        class="formLabel col-md-2">
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <input  class="form-control"
                                                    id="add_2_999" name="add_2_999"
                                                    formControlName="add_2_999" type="text"
                                                    data-id="add_lic_2" placeholder="Additional Licensce Number 2"/> 
                                                <br>
                                                <input  class="form-control"
                                                    id="add_4_999" name="add_4_999"
                                                    formControlName="add_4_999" type="text"
                                                    data-id="add_lic_4" placeholder="Additional Licensce Number 4"/> 
                                            </td>
                                            <td>
                                                <div class="form-group col-md-12 dropDrownSet">
                                                    <ng-select
                                                        [items]="additionalLicenseState"
                                                        bindLabel="name"
                                                        placeholder="Select States"
                                                        bindValue="id"
                                                        formControlName="add_2_1010">
                                                    </ng-select>
                                                    <div
                                                        class="formLabel col-md-2">
                                                    </div>
                                                </div>
                                                <br>
                                                <div class="form-group col-md-12 dropDrownSet">
                                                    <ng-select
                                                        [items]="additionalLicenseState"
                                                        bindLabel="name"
                                                        placeholder="Select States"
                                                        bindValue="id"
                                                        formControlName="add_4_1010">
                                                    </ng-select>
                                                    <div
                                                        class="formLabel col-md-2">
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <label>DEA REGISTRATION NUMBER</label>
                                                <br>
                                                <input  class="form-control"
                                                    id="309" name="309"
                                                    formControlName="309" type="text"
                                                    [class.is-invalid]="(newMed.controls['309'].touched 
                                                        && newMed.controls['309'].invalid) || 
                                                        (newMed.controls['309'].invalid && isSubmit)"
                                                    data-id="deanum" placeholder="DEA Number"/>
                                                    <div class="formLabel"
                                                        *ngIf="newMed.controls['309'].touched && newMed.controls['309'].invalid">
                                                        <small class="text-danger" *ngIf="newMed.controls['309'].errors?.required">
                                                            required
                                                        </small>
                                                    </div>
                                            </td>
                                            <td>
                                                <label>EXP.DATE</label>
                                                <br>
                                                <div class="input-group">
                                                    <input
                                                        class="form-control"
                                                        placeholder="MM/DD/YYYY"
                                                        ngbDatepicker
                                                        #d311="ngbDatepicker"
                                                        id="311"
                                                        name="311"   
                                                        formControlName="311"
                                                        (blur)="clearInputIfInvalid('311')"
                                                        type="text"
                                                        data-id="dea_exp"/>
                                                    <div class="input-group-append">
                                                        <button class="btn btn-outline-secondary calendar" (click)="d311.toggle()" type="button">
                                                            <i class="fas fa-calendar-alt"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div  *ngIf="newMed.get('311').invalid ">
                                                    <small class="text-danger" *ngIf="newMed.get('311').errors?.invalidDateFormat">
                                                      Only Date Format is allowed(MM/DD/YYYY)
                                                    </small>
                                                    <small class="text-danger" *ngIf="newMed.get('311').errors?.invalidMonth">
                                                    Month is out of range
                                                    </small>
                                                    <small class="text-danger" *ngIf="newMed.get('311').errors?.invalidDateyear">
                                                      year is out of range(1900-2099)                      </small>
                                                      <small class="text-danger" *ngIf="newMed.get('311').errors?.invalidDateRange">
                                                        date is out of range
                                                        </small>
                                                    </div>
                                            </td>
                                            <td colspan="2">
                                                <label>NPI#</label>
                                                <br>
                                                <input  class="form-control"
                                                    id="1634" name="1634"
                                                    formControlName="1634" type="text"
                                                    data-id="npi" placeholder="NPI"/> 
                                            </td>
                                            <td colspan="2">
                                                <label>ECFMG#</label>
                                                <br>
                                                <input  class="form-control"
                                                    id="514" name="514"
                                                    formControlName="514" type="text"
                                                    data-id="ecfmg" placeholder="ECFMG"/> 
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="6">
                                                <label>eHEALTH EXCHANGE DIRECT ADDRESS</label>
                                                <br>
                                                <input  class="form-control"
                                                id="cmd_0065" name="cmd_0065"
                                                formControlName="cmd_0065" type="text"
                                                data-id="ehed" style="width:80%"/>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td style="vertical-align: top;">
                                    <b><label style="font-size:14px;">MEDICAL REFERENCES</label></b>
                                </td>
                                <td>
                                    <table>
                                        <tr>
                                            <td colspan="3">
                                                <p style="font-size:14px;">SUPPLY THE NAMES OF AT LEAST THREE (3) PROFESSIONAL REFERENCES, OTHER THAN PROGRAM DIRECTORS REFERRED TO UNDER TRAINING/EDUCATION OR INDIVIDUALS WITH WHOM YOU ARE PRESENTLY OR PROFESSIONALLY ASSOCIATED. THE NAMED INDIVIDUALS MUST HAVE PERSONAL KNOWLEDGE, GAINED THROUGH CLINICAL INTERACTION, OF YOUR PROFESSIONAL PRACTICE OVER A REASONABLE PERIOD OF TIME. AT LEAST TWO OF THE REFERENCES MUST BE IN THE SAME SPECIALTY AND ONE MUST HAVE HAD ORGANIZATIONAL RESPONSIBILITY FOR YOUR PERFORMANCE. NONE OF THE INDIVIDUALS SHOULD BE RELATED TO YOU BY FAMILY.</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <label>DOCTOR</label>
                                                <br>
                                                <input  class="form-control"
                                                    id="359" name="359"
                                                    formControlName="359" type="text"
                                                    data-id="ref_1_name" placeholder="Reference 1 Name"/> 
                                            </td>
                                            <td colspan="2">
                                                <label>ADDRESS</label>
                                                <input  class="form-control"
                                                    id="360" name="360"
                                                    formControlName="360" type="text"
                                                    data-id="ref_1_add1" placeholder="Reference 1 Address1"/>
                                                <input  class="form-control"
                                                    id="361" name="361"
                                                    formControlName="361" type="text"
                                                    data-id="ref_1_add2" placeholder="Reference 1 Address2"/> 
                                                <input  class="form-control"
                                                    id="362" name="362"
                                                    formControlName="362" type="text"
                                                    data-id="ref_1_city" placeholder="Reference 1 City"/>
                                                <br><br>
                                                <div class="form-group col-md-12 dropDrownSet">
                                                    <ng-select
                                                        [items]="states"
                                                        bindLabel="name"
                                                        placeholder="Select States"
                                                        bindValue="id"
                                                        formControlName="364">
                                                    </ng-select>
                                                    <div
                                                        class="formLabel col-md-2"
                                                        *ngIf="
                                                        newMed.controls['364'].touched &&
                                                        newMed.controls['364'].invalid">
                                                    </div>
                                                </div>
                                                <input  class="form-control"
                                                    id="365" name="365"
                                                    formControlName="365" type="text"
                                                    data-id="ref_1_zip" placeholder="Reference 1 Zip"/>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <label>SPECIALTY</label>
                                                <br>
                                                <div class="form-group col-md-12 dropDrownSet">
                                                    <ng-select
                                                        [items]="specialty"
                                                        bindLabel="name"
                                                        placeholder="Select Specialty"
                                                        bindValue="id"
                                                        formControlName="368">
                                                    </ng-select>
                                                    <div
                                                        class="formLabel col-md-2"
                                                        *ngIf="
                                                        newMed.controls['368'].touched &&
                                                        newMed.controls['368'].invalid">
                                                    </div>
                                                </div>
                                            </td>
                                            <td colspan="2">
                                                <label>E-MAIL</label>
                                                <br>
                                                <input  class="form-control"
                                                    id="371" name="371"
                                                    formControlName="371" type="text"
                                                    [class.is-invalid]="(newMed.controls['371'].touched 
                                                        && newMed.controls['371'].invalid) || 
                                                        (newMed.controls['371'].invalid && isSubmit)"
                                                    data-id="ref_1_email" placeholder="Reference 1 Email"/> 
                                                    <div class="formLabel"
                                                        *ngIf="newMed.controls['371'].touched && newMed.controls['371'].invalid">
                                                        <small class="text-danger" *ngIf="newMed.controls['371'].errors?.pattern">
                                                            Enter Valid Email
                                                        </small>
                                                    </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <label>NUMBER YEARS KNOWN</label>
                                                <br>
                                                <input  class="form-control"
                                                    id="4242" name="4242"
                                                    formControlName="4242" type="text"
                                                    data-id="ref_1_acqu" placeholder="Reference 1 Acquaintance"/> 
                                            </td>
                                            <td>
                                                <label>PHONE NUMBER</label>
                                                <br>
                                                <input  class="form-control"
                                                    id="369" name="369"
                                                    formControlName="369" type="text"
                                                    data-id="ref_1_phone" placeholder="Reference 1 Phone"/>
                                                    <div class="formLabel"
                                                        *ngIf="newMed.controls['369'].touched && newMed.controls['369'].invalid">
                                                        <small class="text-danger" *ngIf="newMed.controls['369'].errors?.pattern">
                                                            Only Numbers are allowed
                                                        </small>
                                                    </div>
                                            </td>
                                            <td>
                                                <label>FAX#</label>
                                                <br>
                                                <input  class="form-control"
                                                    id="370" name="370"
                                                    formControlName="370" type="text"
                                                    data-id="ref_1_fax" placeholder="Reference 1 Fax"/>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <label>DOCTOR</label>
                                                <br>
                                                <input  class="form-control"
                                                    id="372" name="372"
                                                    formControlName="372" type="text"
                                                    data-id="ref_2_name" placeholder="Reference 2 Name"/> 
                                            </td>
                                            <td colspan="2">
                                                <label>ADDRESS</label>
                                                <br>
                                                <input  class="form-control"
                                                    id="373" name="373"
                                                    formControlName="373" type="text"
                                                    data-id="ref_2_add1" placeholder="Reference 2 Address1"/>
                                                <input  class="form-control"
                                                    id="374" name="374"
                                                    formControlName="374" type="text"
                                                    data-id="ref_2_add2" placeholder="Reference 2 Address2"/> 
                                                <input  class="form-control"
                                                    id="375" name="375"
                                                    formControlName="375" type="text"
                                                    data-id="ref_2_city" placeholder="Reference 2 City"/> 
                                                <br><br>
                                                <div class="form-group col-md-12 dropDrownSet">
                                                    <ng-select
                                                        [items]="states"
                                                        bindLabel="name"
                                                        placeholder="Select States"
                                                        bindValue="id"
                                                        formControlName="377">
                                                    </ng-select>
                                                    <div
                                                        class="formLabel col-md-2"
                                                        *ngIf="
                                                        newMed.controls['377'].touched &&
                                                        newMed.controls['377'].invalid">
                                                    </div>
                                                </div>
                                                <input  class="form-control"
                                                    id="378" name="378"
                                                    formControlName="378" type="text"
                                                    data-id="ref_2_zip" placeholder="Reference 2 Zip"/>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <label>SPECIALTY</label>
                                                <br>
                                                <div class="form-group col-md-12 dropDrownSet">
                                                    <ng-select
                                                        [items]="specialty"
                                                        bindLabel="name"
                                                        placeholder="Select Specialty"
                                                        bindValue="id"
                                                        formControlName="380">
                                                    </ng-select>
                                                    <div
                                                        class="formLabel col-md-2"
                                                            *ngIf="
                                                            newMed.controls['380'].touched &&
                                                            newMed.controls['380'].invalid">
                                                    </div>
                                                </div>
                                            </td>
                                            <td colspan="2">
                                                <label>E-MAIL</label>
                                                <br>
                                                <input  class="form-control"
                                                    id="383" name="383"
                                                    formControlName="383" type="text"
                                                    [class.is-invalid]="(newMed.controls['383'].touched 
                                                        && newMed.controls['383'].invalid) || 
                                                        (newMed.controls['383'].invalid && isSubmit)"
                                                    data-id="ref_2_email" placeholder="Reference 2 Email"/> 
                                                    <div class="formLabel"
                                                        *ngIf="newMed.controls['383'].touched && newMed.controls['383'].invalid">
                                                        <small class="text-danger" *ngIf="newMed.controls['383'].errors?.pattern">
                                                            Enter Valid Email
                                                        </small>
                                                    </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <label>NUMBER YEARS KNOWN</label>
                                                <br>
                                                <input  class="form-control"
                                                    id="4243" name="4243"
                                                    formControlName="4243" type="text"
                                                    data-id="ref_2_acqu" placeholder="Reference 2 Acquaintance"/> 
                                            </td>
                                            <td>
                                                <label>PHONE NUMBER</label>
                                                <br>
                                                <input  class="form-control"
                                                    id="381" name="381"
                                                    formControlName="381" type="text"
                                                    [class.is-invalid]="(newMed.controls['381'].touched 
                                                        && newMed.controls['381'].invalid) || 
                                                        (newMed.controls['381'].invalid && isSubmit)"
                                                    data-id="ref_2_phone" placeholder="Reference 2 Phone"/> 
                                                    <div class="formLabel"
                                                        *ngIf="newMed.controls['381'].touched && newMed.controls['381'].invalid">
                                                        <small class="text-danger" *ngIf="newMed.controls['381'].errors?.pattern">
                                                            Only Numbers are allowed
                                                        </small>
                                                    </div>
                                            </td>
                                            <td>
                                                <label>FAX#</label>
                                                <br>
                                                <input  class="form-control"
                                                    id="382" name="382"
                                                    formControlName="382" type="text"
                                                    data-id="ref_2_fax" placeholder="Reference 2 Fax"/> 
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <label>DOCTOR</label>
                                                <br>
                                                <input  class="form-control"
                                                    id="384" name="384"
                                                    formControlName="384" type="text"
                                                    data-id="ref_3_name" placeholder="Reference 3 Name"/>
                                            </td>
                                            <td colspan="2">
                                                <label>ADDRESS</label>
                                                <br>
                                                <input  class="form-control"
                                                    id="385" name="385"
                                                    formControlName="385" type="text"
                                                    data-id="ref_3_add1" placeholder="Reference 3 Address1"/>
                                                <input  class="form-control"
                                                    id="386" name="386"
                                                    formControlName="386" type="text"
                                                    data-id="ref_3_add2" placeholder="Reference 3 Address2"/>
                                                <input  class="form-control"
                                                    id="387" name="387"
                                                    formControlName="387" type="text"
                                                    data-id="ref_3_city" placeholder="Reference 3 City"/>
                                                <br><br>
                                                <div class="form-group col-md-12 dropDrownSet">
                                                    <ng-select
                                                        [items]="states"
                                                        bindLabel="name"
                                                        placeholder="Select States"
                                                        bindValue="id"
                                                        formControlName="389">
                                                    </ng-select>
                                                    <div
                                                        class="formLabel col-md-2"
                                                        *ngIf="
                                                            newMed.controls['389'].touched &&
                                                            newMed.controls['389'].invalid">
                                                    </div>
                                                </div>
                                                <input  class="form-control"
                                                    id="390" name="390"
                                                    formControlName="390" type="text"
                                                    data-id="ref_3_zip" placeholder="Refernce 3 Zip"/>   
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <label>SPECIALTY</label>
                                                <br>
                                                <div class="form-group col-md-12 dropDrownSet">
                                                    <ng-select
                                                        [items]="specialty"
                                                        bindLabel="name"
                                                        placeholder="Select Specialty"
                                                        bindValue="id"
                                                        formControlName="393">
                                                    </ng-select>
                                                    <div
                                                        class="formLabel col-md-2"
                                                        *ngIf="
                                                            newMed.controls['393'].touched &&
                                                            newMed.controls['393'].invalid">
                                               </div>
                                            </div>
                                            </td>
                                            <td colspan="2">
                                                <label>E-MAIL</label>
                                                <br>
                                                <input  class="form-control"
                                                    id="396" name="396"
                                                    formControlName="396" type="text"
                                                    [class.is-invalid]="(newMed.controls['396'].touched 
                                                        && newMed.controls['396'].invalid) || 
                                                        (newMed.controls['396'].invalid && isSubmit)"
                                                    data-id="ref_3_email" placeholder="Reference 3 Email"/> 
                                                    <div class="formLabel"
                                                        *ngIf="newMed.controls['396'].touched && newMed.controls['396'].invalid">
                                                        <small class="text-danger" *ngIf="newMed.controls['396'].errors?.pattern">
                                                            Enter Valid Email
                                                        </small>
                                                    </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <label>NUMBER YEARS KNOWN</label>
                                                <br>
                                                <input  class="form-control"
                                                    id="4244" name="4244"
                                                    formControlName="4244" type="text"
                                                    data-id="ref_3_acqu" placeholder="Reference 3 Acquaintance"/>
                                            </td>
                                            <td>
                                                <label>PHONE NUMBER</label>
                                                <br>
                                                <input  class="form-control"
                                                    id="394" name="394"
                                                    formControlName="394" type="text"
                                                    [class.is-invalid]="(newMed.controls['394'].touched 
                                                        && newMed.controls['394'].invalid) || 
                                                        (newMed.controls['394'].invalid && isSubmit)"
                                                    data-id="ref_3_phone" placeholder="Reference 3 Phone"/> 
                                                    <div class="formLabel"
                                                        *ngIf="newMed.controls['394'].touched && newMed.controls['394'].invalid">
                                                        <small class="text-danger" *ngIf="newMed.controls['394'].errors?.pattern">
                                                            Only Numbers are allowed
                                                        </small>
                                                    </div>
                                            </td>
                                            <td>
                                                <label>FAX#</label>
                                                <br>
                                                <input  class="form-control"
                                                    id="395" name="395"
                                                    formControlName="395" type="text"
                                                    data-id="ref_3_fax" placeholder="Reference 3 Fax"/> 
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <label>DOCTOR</label>
                                                <br>
                                                <input  class="form-control"
                                                    id="1681" name="1681"
                                                    formControlName="1681" type="text"
                                                    data-id="ref_4_name" placeholder="Referencce 4 Name"/>
                                            </td>
                                            <td colspan="2">
                                                <label>ADDRESS</label>
                                                <br>
                                                <input  class="form-control"
                                                    id="1682" name="1682"
                                                    formControlName="1682" type="text"
                                                    data-id="ref_4_add1" placeholder="Reference 4 Address1"/>
                                                <input  class="form-control"
                                                    id="1683" name="1683"
                                                    formControlName="1683" type="text"
                                                    data-id="ref_4_add2" placeholder="Reference 4 Address2"/> 
                                                <input  class="form-control"
                                                    id="1684" name="1684"
                                                    formControlName="1684" type="text"
                                                    data-id="ref_4_city" placeholder="Reference 4 City"/> 
                                                <div class="form-group col-md-12 dropDrownSet">
                                                    <ng-select
                                                        [items]="states"
                                                        bindLabel="name"
                                                        placeholder="Select States"
                                                        bindValue="id"
                                                        formControlName="1686">
                                                    </ng-select>
                                                    <div
                                                        class="formLabel col-md-2"
                                                        *ngIf="
                                                            newMed.controls['1686'].touched &&
                                                            newMed.controls['1686'].invalid">
                                                    </div>
                                                </div>
                                                <input  class="form-control"
                                                    id="1687" name="1687"
                                                    formControlName="1687" type="text"
                                                    data-id="ref_4_zip" placeholder="Reference 4 Zip"/>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <label>SPECIALTY</label>
                                                <br>
                                                <div class="form-group col-md-12 dropDrownSet">
                                                    <ng-select
                                                        [items]="specialty"
                                                        bindLabel="name"
                                                        placeholder="Select Specialty"
                                                        bindValue="id"
                                                        formControlName="1691">
                                                    </ng-select>
                                                    <div
                                                        class="formLabel col-md-2"
                                                        *ngIf="
                                                            newMed.controls['1691'].touched &&
                                                            newMed.controls['1691'].invalid">
                                                    </div>
                                                </div>
                                            </td>
                                            <td colspan="2">
                                                <label>E-MAIL</label>
                                                <br>
                                                <input  class="form-control"
                                                    id="1694" name="1694"
                                                    formControlName="1694" type="text"
                                                    [class.is-invalid]="(newMed.controls['1694'].touched 
                                                        && newMed.controls['1694'].invalid) || 
                                                        (newMed.controls['1694'].invalid && isSubmit)"
                                                    data-id="ref_4_email" placeholder="Reference 4 Email"/>
                                                    <div class="formLabel"
                                                        *ngIf="newMed.controls['1694'].touched && newMed.controls['1694'].invalid">
                                                        <small class="text-danger" *ngIf="newMed.controls['1694'].errors?.pattern">
                                                            Enter Valid Email
                                                        </small>
                                                    </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <label>NUMBER YEARS KNOWN</label>
                                                <br>
                                                <input  class="form-control"
                                                    id="4245" name="4245"
                                                    formControlName="4245" type="text"
                                                    data-id="ref_4_acqu" placeholder="Reference 4 Acauqaintance"/>
                                            </td>
                                            <td>
                                                <label>PHONE NUMBER</label>
                                                <br>
                                                <input  class="form-control"
                                                    id="1692" name="1692"
                                                    formControlName="1692" type="text"
                                                    data-id="ref_4_phone" placeholder="Reference 4 Phone"/>
                                                    <div class="formLabel"
                                                        *ngIf="newMed.controls['1692'].touched && newMed.controls['1692'].invalid">
                                                        <small class="text-danger" *ngIf="newMed.controls['1692'].errors?.pattern">
                                                            Only Numbers are allowed
                                                        </small>
                                                    </div>
                                            </td>
                                            <td>
                                                <label>FAX#</label>
                                                <br>
                                                <input  class="form-control"
                                                    id="1693" name="1693"
                                                    formControlName="1693" type="text"
                                                    data-id="ref_4_fax1" placeholder="Reference 4 Fax"/> 
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td style="vertical-align: top;">
                                    <b><label style="font-size:14px">HEALTH STATUS</label></b>
                                </td>
                                <td>
                                    <div class="row" style="margin-left:10px;width:99%">
                                        <p style="font-size:14px">ARE YOU ABLE BOTH MENTALLY AND PHYSICALLY TO PERFORM ALL PROCEDURES FOR WHICH YOU HAVE REQUESTED
                                        PRIVILEGES, WITH OR WITHOUT REASONABLE ACCOMMODATION, ACCORDING TO ACCEPTED STANDARDS OF
                                        PROFESSIONAL PERFORMANCE WITHOUT POSING A THREAT TO PATIENTS? (IF YOU WILL REQUIRE REASONABLE
                                        ACCOMMODATION, PLEASE USE A SEPARATE SHEET TO DESCRIBE THE ACCOMMODATION(S) WHICH WILL ENABLE YOU TO
                                        PERFORM THE PRIVILEGES YOU HAVE REQUESTED)</p>
                                        <input
                                            type="radio"
                                            id="cmd_0070"
                                            name="cmd_0070"
                                            formControlName="cmd_0070"
                                            value="1" />Yes
                                        <input
                                            type="radio"
                                            id="cmd_0070"
                                            name="cmd_0070"
                                            formControlName="cmd_0070"
                                            value="0" />No
                                    </div>
                                    <div class="row" style="margin-left:10px">
                                        <p style="font-size:14px">ARE YOU CURRENTLY INVOLVED IN THE USE OF ILLEGAL SUBSTANCES, OR THE ILLEGAL USE OF PRESCRIPTIVE
                                                SUBSTANCES?</p>
                                        <input
                                            type="radio"
                                            id="cmd_0071"
                                            name="cmd_0071"
                                            formControlName="cmd_0071"
                                            value="1" />Yes
                                        <input
                                            type="radio"
                                            id="cmd_0071"
                                            name="cmd_0071"
                                            formControlName="cmd_0071"
                                            value="0" />No
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td style="border-bottom:hidden" colspan="2">
                                        HAVE ANY OF THE FOLLOWING, EITHER VOLUNTARILY OR INVOLUNTARILY, BEEN DENIED, REVOKED, SUSPENDED, REDUCED, LIMITED, ALTERED, SURRENDERED, PLACED ON PROBATION, NOT RENEWED, OR ARE CURRENTLY PENDING OR UNDER INVESTIGATION.
                                </td>
                            </tr>
                            <tr>
                                <td style="border-right:hidden;vertical-align: top;">
                                    <b><label style="font-size:14px">DISCIPLINARY ACTIONS</label></b>
                                </td>
                                <td>
                                    <input
                                        type="radio"
                                        id="cmd_010"
                                        name="cmd_010"
                                        formControlName="cmd_010"
                                        value="1" />Yes
                                    <input
                                        type="radio"
                                        id="cmd_010"
                                        name="cmd_010"
                                        formControlName="cmd_010"
                                        value="0" />No
                                    <label>MEDICAL LICENSE IN ANY STATE</label>
                                    <br>
                                    <input
                                        type="radio"
                                        id="cmd_011"
                                        name="cmd_011"
                                        formControlName="cmd_011"
                                        value="1" />Yes
                                    <input
                                        type="radio"
                                        id="cmd_011"
                                        name="cmd_011"
                                        formControlName="cmd_011"
                                        value="0" />No
                                    <label>OTHER PROFESSIONAL REGISTRATION/LICENSE</label>
                                    <br>
                                    <input
                                        type="radio"
                                        id="cmd_012"
                                        name="cmd_012"
                                        formControlName="cmd_012"
                                        value="1" />Yes
                                    <input
                                        type="radio"
                                        id="cmd_012"
                                        name="cmd_012"
                                        formControlName="cmd_012"
                                        value="0" />No
                                    <label>DEA REGISTRATION</label>
                                    <br>
                                    <input
                                        type="radio"
                                        id="cmd_013"
                                        name="cmd_013"
                                        formControlName="cmd_013"
                                        value="1" />Yes
                                    <input
                                        type="radio"
                                        id="cmd_013"
                                        name="cmd_013"
                                        formControlName="cmd_013"
                                        value="0" />No
                                    <label>ACADEMIC APPOINTMENT</label>
                                    <br>
                                    <input
                                        type="radio"
                                        id="cmd_014"
                                        name="cmd_014"
                                        formControlName="cmd_014"
                                        value="1" />Yes
                                    <input
                                        type="radio"
                                        id="cmd_014"
                                        name="cmd_014"
                                        formControlName="cmd_014"
                                        value="0" />No
                                    <label>MEMBERSHIP ON ANY HOSPITAL MEDICAL STAFF</label>
                                    <br>
                                    <input
                                        type="radio"
                                        id="cmd_015"
                                        name="cmd_015"
                                        formControlName="cmd_015"
                                        value="1" />Yes
                                    <input
                                        type="radio"
                                        id="cmd_015"
                                        name="cmd_015"
                                        formControlName="cmd_015"
                                        value="0" />No
                                    <label>CLINICAL PRIVILEGES</label>
                                    <br>
                                    <input
                                        type="radio"
                                        id="cmd_016"
                                        name="cmd_016"
                                        formControlName="cmd_016"
                                        value="1" />Yes
                                    <input
                                        type="radio"
                                        id="cmd_016"
                                        name="cmd_016"
                                        formControlName="cmd_016"
                                        value="0" />No
                                    <label>PREROGATIVES/RIGHTS ON ANY MEDICAL STAFF</label>
                                    <br>
                                    <input
                                        type="radio"
                                        id="cmd_017"
                                        name="cmd_017"
                                        formControlName="cmd_017"
                                        value="1" />Yes
                                    <input
                                        type="radio"
                                        id="cmd_017"
                                        name="cmd_017"
                                        formControlName="cmd_017"
                                        value="0" />No
                                    <label>OTHER INSTITUTIONAL AFFILIATION OR STATUS</label>
                                    <br>
                                    <input
                                        type="radio"
                                        id="cmd_018"
                                        name="cmd_018"
                                        formControlName="cmd_018"
                                        value="1" />Yes
                                    <input
                                        type="radio"
                                        id="cmd_018"
                                        name="cmd_018"
                                        formControlName="cmd_018"
                                        value="0" />No
                                    <label>PROFESSIONAL SOCIETY MEMBERSHIP/FELLOWSHIP/BOARD CERTIFICATION</label>
                                    <br>
                                    <input
                                        type="radio"
                                        id="cmd_019"
                                        name="cmd_019"
                                        formControlName="cmd_019"
                                        value="1" />Yes
                                    <input
                                        type="radio"
                                        id="cmd_019"
                                        name="cmd_019"
                                        formControlName="cmd_019"
                                        value="0" />No
                                    <label>SANCTION OR EXCLUSION FROM PARTICIPATION IN A FEDERALLY FUNDED INSURANCE PROGRAM (E.G. MEDICARE, MEDICAID, TRICARE)</label>
                                    <br>
                                    <input
                                        type="radio"
                                        id="cmd_020"
                                        name="cmd_020"
                                        formControlName="cmd_020"
                                        value="1" />Yes
                                    <input
                                        type="radio"
                                        id="cmd_020"
                                        name="cmd_020"
                                        formControlName="cmd_020"
                                        value="0" />No
                                    <label>PROFESSIONAL LIABILITY INSURANCE</label>
                                    <br>
                                </td>
                            </tr>
                            <tr style="border-top:hidden">
                                <td style="border-right:hidden;vertical-align: top;">
                                    <b><label style="font-size:14px">LEGAL ACTIONS</label></b>
                                </td>
                                <td>
                                    <input
                                        type="radio"
                                        id="cmd_021"
                                        name="cmd_021"
                                        formControlName="cmd_021"
                                        value="1" />Yes
                                    <input
                                        type="radio"
                                        id="cmd_021"
                                        name="cmd_021"
                                        formControlName="cmd_021"
                                        value="0" />No
                                    <label>HAVE YOU EVER BEEN CONVICTED OF A CRIME OTHER THAN A MINOR TRAFFIC VIOLATION?</label>
                                    <br>
                                    <input
                                        type="radio"
                                        id="cmd_022"
                                        name="cmd_022"
                                        formControlName="cmd_022"
                                        value="1" />Yes
                                    <input
                                        type="radio"
                                        id="cmd_022"
                                        name="cmd_022"
                                        formControlName="cmd_022"
                                        value="0" />No
                                    <label>ARE THERE ANY CHARGES PENDING AGAINST YOU OTHER THAN A MINOR TRAFFIC VIOLATION?</label>
                                    <br>
                                    <input
                                        type="radio"
                                        id="cmd_023"
                                        name="cmd_023"
                                        formControlName="cmd_023"
                                        value="1" />Yes
                                    <input
                                        type="radio"
                                        id="cmd_023"
                                        name="cmd_023"
                                        formControlName="cmd_023"
                                        value="0" />No
                                    <label>HAVE YOU EVER RECEIVED PROBATION WITHOUT A VERDICT OR DISPOSITION OF AN OFFENSE IN LIEU OF TRIAL?</label>
                                    <br>
                                    <input
                                        type="radio"
                                        id="cmd_024"
                                        name="cmd_024"
                                        formControlName="cmd_024"
                                        value="1" />Yes
                                    <input
                                        type="radio"
                                        id="cmd_024"
                                        name="cmd_024"
                                        formControlName="cmd_024"
                                        value="0" />No
                                    <label>HAVE YOU EVER HAD ACCELERATED REHABILITATION IN THE DISPOSITION OF FELONY CHARGES IN ANY STATE, TERRITORY OR COUNTRY?</label>
                                </td>
                            </tr>
                            <tr style="border-top:hidden">
                            <td colspan="2">
                                <label>THE FOLLOWING APPLY TO ANY PROFESSIONAL ACTION TAKEN BY A TRAINING PROGRAM, EMPLOYER, HOSPITAL, MANAGED CARE ORGANIZATION OR OTHER HEALTH CARE FACILITY ( IF YES, PLEASE PROVIDE DETAILED EXPLANATION ON ATTACHED SHEET)</label>
                                <br><br><br>
                                <label>HAVE YOU EVER WITHDRAWN YOUR APPLICATION BEFORE A FINAL DECISION WAS MADE BY THE GOVERNING BOARD?</label>
                                <br>
                                <label><input
                                        type="radio"
                                        id="cmd_025"
                                        name="cmd_025"
                                        formControlName="cmd_025"
                                        value="1" />Yes
                                    <input
                                        type="radio"
                                        id="cmd_025"
                                        name="cmd_025"
                                        formControlName="cmd_025"
                                        value="0" />No
                                </label>
                                <br>
                                <br>
                                <label>HAVE YOU EVER EXPERIENCED A NON-RENEWAL OF EMPLOYMENT, APPOINTMENT, CLINICAL PRIVILEGES OR PARTICIPATING PROVIDER STATUS? </label>
                                <br>
                                <label><input
                                        type="radio"
                                        id="cmd_026"
                                        name="cmd_026"
                                        formControlName="cmd_026"
                                        value="1" />Yes
                                    <input
                                        type="radio"
                                        id="cmd_026"
                                        name="cmd_026"
                                        formControlName="cmd_026"
                                        value="0" />No
                                </label>
                                <br>
                                <br>
                                <label>HAVE YOU EVER EXPERIENCED ANY INVESTIGATIONS OR FOCUSED REVIEWS PENDING OR RESOLVED RELATING TO CLINICAL COMPETENCE OR PROFESSIONAL CONDUCT?</label>
                                <br>
                                <label><input
                                        type="radio"
                                        id="cmd_027"
                                        name="cmd_027"
                                        formControlName="cmd_027"
                                        value="1" />Yes
                                    <input
                                        type="radio"
                                        id="cmd_027"
                                        name="cmd_027"
                                        formControlName="cmd_027"
                                        value="0" />No
                                </label>
                                <br>
                                <br>
                            </td>
                            </tr>
                            <tr style="border-top:hidden">
                                <td colspan="2">
                                    <h4 style="text-align:left;font-size: 20px;">LIABILITY INSURANCE</h4><br>
                                    <label>A CERTIFICATE OF INSURANCE FROM YOUR PROFESSIONAL LIABILITY INSURANCE CARRIER MUST BE ATTACHED. THIS CERTIFICATE MUST INCLUDE: ADDRESS OF COMPANY AND DATES OF COVERAGE.</label>
                                    <br>
                                    <br>
                                    <br>
                                    <label>IF YOU HAVE HAD ANY OTHER PROFESSIONAL LIABILITY INSURANCE COVERAGE DURING THE PAST TEN YEARS, PLEASE LIST COMPANIES AND ADDRESSES BELOW. </label>
                                    <br>
                                    <label><input
                                            type="radio"
                                            id="cmd_028"
                                            name="cmd_028"
                                            (ngModelChange)="handleYesNoChanges('cmd_028')"
                                            formControlName="cmd_028"
                                            value="1" />Yes
                                        <input
                                            type="radio"
                                            id="cmd_028"
                                            name="cmd_028"
                                            (ngModelChange)="handleYesNoChanges('cmd_028')"
                                            formControlName="cmd_028"
                                            value="0" />No
                                    </label><br>
                                    <!--<div class="form-check" style="float:right;margin-top:-30px">
                                        <input formControlName="cmd_NA_02" (ngModelChange)="handleNAchange('cmd_NA_02','cmd_028')" class="form-check-input" type="checkbox" value="1" id="cmd_NA_02">
                                           <label class="form-check-label" for="cmd_NA_02">
                                                    <b>Does Not Apply</b>
                                           </label>
                                               
                                    </div>                                   
                                    <span *ngIf="(newMed.controls['cmd_028'].touched && newMed.controls['cmd_028'].errors)
                                         || (newMed.controls['cmd_028'].errors && isSubmit)">
                                        <i class="fas fa-times ml-2 text-danger"></i>
                                    </span> 
                                    <div class="formLabel"
                                        *ngIf="newMed.controls['cmd_028'].touched && newMed.controls['cmd_028'].invalid">
                                        <small class="text-danger" *ngIf="newMed.controls['cmd_028'].errors?.required">
                                            required
                                        </small>
                                    </div>-->
                                    <!-- <div>
                                        <textarea (blur)="handleYesNoChanges('cmd_028')"  (ngModelChange)="handleTextBox('cmd_0008','cmd_028')" id="cmd_0008" 
                                        class="form-control" name="cmd_0008" id="cmd_0008" formControlName="cmd_0008" cols="30" style="height:90px"
                                        rows="10"></textarea>
                                        <div class="mandatory" *ngIf="newMed.controls['cmd_0008'].touched && newMed.controls['cmd_0008'].invalid">
                                            <small >
                                            required
                                            </small>
                                        </div>
                                    </div> -->
                                    <div>
                                        <textarea  id="cmd_0008" 
                                        class="form-control" name="cmd_0008" id="cmd_0008" formControlName="cmd_0008" cols="30" style="height:90px"
                                        rows="10"></textarea>
                                       
                                    </div>
                                    <br>
                                    <br>
                                    <label>HAVE THERE BEEN, OR ARE THERE CURRENTLY PENDING, ANY MALPRACTICE CLAIMS, SUITS, SETTLEMENTS OR ARBITRATION PROCEEDINGS INVOLVING YOUR PROFESSIONAL PRACTICE?</label>
                                    <br>
                                    <label><input
                                            type="radio"
                                            id="cmd_029"
                                            name="cmd_029"
                                            formControlName="cmd_029"
                                            value="1" />Yes
                                        <input
                                            type="radio"
                                            id="cmd_029"
                                            name="cmd_029"
                                            formControlName="cmd_029"
                                            value="0" />No
                                    </label>
                                    <br>
                                    <br>
                                    <label>HAVE THERE BEEN ANY ADVERSE MALPRACTICE JUDGMENTS OR CLAIM SETTLEMENTS IN THE LAST TEN YEARS THAT WERE PAID BY AN EMPLOYER, INSURANCE CARRIER OR OTHER ENTITY?</label>
                                    <br>
                                    <label><input
                                            type="radio"
                                            id="cmd_030"
                                            name="cmd_030"
                                            formControlName="cmd_030"
                                            value="1" />Yes
                                        <input
                                            type="radio"
                                            id="cmd_030"
                                            name="cmd_030"
                                            formControlName="cmd_030"
                                            value="0" />No
                                    </label>
                                    <br>
                                    <br>
                                    <label>HAVE YOU EXPERIENCED THE CANCELLATION OR DENIAL OF PROFESSIONAL LIABILITY INSURANCE IN THE LAST TEN YEARS?</label>
                                    <br>
                                    <label><input
                                            type="radio"
                                            id="cmd_031"
                                            name="cmd_031"
                                            formControlName="cmd_031"
                                            value="1" />Yes
                                        <input
                                            type="radio"
                                            id="cmd_031"
                                            name="cmd_031"
                                            formControlName="cmd_031"
                                            value="0" />No
                                    </label>
                                    <br>
                                    <br>
                                    <label>HAS THERE BEEN ANY PAYMENT OF PERSONAL FUNDS TO SETTLE ANY ACTUAL OR POTENTIAL MALPRACTICE CLAIMS IN THE LAST TEN YEARS THAT DID NOT INVOLVE A PAYMENT BY AN INSURANCE CARRIER OR OTHER ENTITY?</label>
                                    <br>
                                    <label><input
                                            type="radio"
                                            id="cmd_032"
                                            name="cmd_032"
                                            formControlName="cmd_032"
                                            value="1" />Yes
                                        <input
                                            type="radio"
                                            id="cmd_032"
                                            name="cmd_032"
                                            formControlName="cmd_032"
                                            value="0" />No
                                    </label>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <br>
                        <div style="width:50%;margin-left:30%">
                            <div style="float:left;width:50%">
                                <label (click)="esignOpenOne()">Signature:</label>
                                <img *ngIf="!showOne" class="img" src="./assets/images/e_sign.png" alt="Credential my Doc"
                                (click)="esignOpenOne()" />
                                    <img *ngIf="showOne" class="img eSignImageShow" [src]="esignOne" alt="Credential my Doc"
                                (click)="esignOpenOne()" />
                                <input type="hidden" name="cmd_0091" id="cmd_0091" [class.is-invalid]="(newMed.controls['cmd_0091'].touched && newMed.controls['cmd_0091'].invalid) || (newMed.controls['cmd_0091'].invalid && isSubmit)" formControlName="cmd_0091" value="{{ finalImgPath }}" />
                                <div class="invalid-feedback"
                                    *ngIf="(newMed.controls['cmd_0091'].touched && newMed.controls['cmd_0091'].invalid) || (newMed.controls['cmd_0091'].invalid && isSubmit)">
                                    Signature is required and cannot be empty
                                </div> 
                            </div>
                            <div style="float:right;width:50%">
                            <label>
                                Date :
                            </label>
                                <div class="input-group">
                                    <input
                                        class="form-control"
                                        [class.is-invalid]="
                                        (newMed.controls['cmd_0092'].touched &&
                                        newMed.controls['cmd_0092'].invalid) ||
                                        (newMed.controls['cmd_0092'].invalid && isSubmit)
                                        "
                                        placeholder="MM/DD/YYYY"
                                        ngbDatepicker
                                        #dcmd_0092="ngbDatepicker"
                                        id="cmd_0092"
                                        name="cmd_0092"   
                                        formControlName="cmd_0092"
                                        (blur)="clearInputIfInvalidMandi('cmd_0092')"
                                        type="text"
                                        data-id="sig_date"
                                    />
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="dcmd_0092.toggle()" type="button">
                                            <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>                
                                </div>
                                <div class="text-danger" *ngIf="(newMed.controls['cmd_0092'].invalid&&newMed.controls['cmd_0092'].touched) && (newMed.controls['cmd_0092'].value==''|| newMed.controls['cmd_0092'].value==null) " >
                                    <small >
                                        Date is required and cannot be empty
                                    </small>
                  
                                </div>
                                  <small class="text-danger" *ngIf="newMed.get('cmd_0092').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY)                </small>
                                  <small class="text-danger" *ngIf="newMed.get('cmd_0092').errors?.invalidMonth">
                                  Month is out of range
                                  </small>
                                  <small class="text-danger" *ngIf="newMed.get('cmd_0092').errors?.invalidDateyear">
                                    year is out of range(1900-2099)                  </small>
                                    <small class="text-danger" *ngIf="newMed.get('cmd_0092').errors?.invalidDateRange">
                                      date is out of range
                                      </small>
                            </div>
                        </div>
                </form>
                <div class="modal-footer mt-4">
                        <button
                          *ngIf="formData.receivedForm"
                          type="button"
                          class="btn customOrange float-right"
                          (click)="saveForm('approve')"
                        >
                          Approve
                        </button>
                        <button
                          *ngIf="formData.submitForm"
                          type="button"
                          class="btn btn customOrange float-right"
                          (click)="saveForm('submit')"
                        >
                          Submit
                        </button>
                        <button
                          *ngIf="formData.submitForm"
                          type="button"
                          class="btn btn customOrange float-right"
                          (click)="saveForm('save')"
                        >
                          Save
                        </button>
                        <button
                          *ngIf="formData.previewForm"
                          type="button"
                          class="btn customOrange float-right"
                          (click)="close()"
                        >
                          Close
                        </button>
                    </div>
            </div>
        </div>
        
</div>
    