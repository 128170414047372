import { Injectable } from '@angular/core';
import { ApiServiceService } from '../../../../shared/services/cmd-api-service/api-service.service';

@Injectable({
  providedIn: 'root'
})
export class GeneratedFormService {

    constructor(private apiService: ApiServiceService) { }
  
    public getFormList(postParams) {
      return this.apiService.post('forms/formslist', postParams);
    }

    public getDropDownValuesByType(postParams) {
      return this.apiService.post('forms/dropdownvalues', postParams);
    }
  
    public getCatageory() {
      return this.apiService.post('forms/category', '');
    }
  
    public excelDownload(postParams) {
      return this.apiService.post('forms/formsheet', postParams);
    }

    public downloadPdfFile(postParams) {
      return this.apiService.post('forms/downloadfile', postParams);
    }

    public getMailNotificationDetails(postParams) {
      return this.apiService.post('forms/sendformdetails', postParams);
    }

    public sendMail(postParams) {
      return this.apiService.post('forms/sendmail', postParams);
    }

    public uploadDocument(postParams) {
      return this.apiService.postFile('forms/uploaddocuments', postParams);
    }

    public downloadEsignFile(postParams){
      return this.apiService.post('forms/downloadesign', postParams);
     
    }

}
