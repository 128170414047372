import { Injectable } from '@angular/core';
import { Spinner } from 'ngx-spinner/lib/ngx-spinner.enum';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {

  constructor(
    private spinner: NgxSpinnerService,

  ) { }

  public show(name?: string, spinner?: Spinner) {
    return this.spinner.show(name, spinner)
  }

  public showTemp(time?: number, name?: string, spinner?: Spinner) {
    this.spinner.show(name, spinner)
    if (time) {
      setTimeout(() => {
        this.hide()
      }, time);
    }
  }

  public hide(name?: string, debounce?: number) {
    return this.spinner.hide(name, debounce)
  }
}
