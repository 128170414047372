<div class="container-fluid" id="cherokee-form">
  <form [formGroup]="cherokeeHealthForm">



    <div class="row pl-2 pb-2">

      <div class="col-md-12">
        <h3><u>CREDENTIALING PROCESS </u></h3>
      </div>
      <div class="col-md-12">
        <label>
          Cherokee Health Systems has an internal credentialing process. Staff files are reviewed by the Credentialing
          Review Committee upon employment, and recredentialing occurs every two years. <br>To properly facilitate the
          credentialing process in a timely manner,
          <u><b>please complete the attached application and other forms. attach a copy of the following documents (If
              applicable to you),</b></u> and return them, along with this completed application, to the Credentialing
          Department by:
        </label>
      </div>
    </div>

    <div class="row pl-3">
      <h2><u>ASAP-Urgent.</u></h2>
    </div>
    <div class="row p-3">
      <table class="databox">
        <tbody>
          <tr>
            <th>
              <span>
                <u>WE NEED A COPY OF THE FOLLOWING DOCUMENTS,IF APPLICABLE TO YOU:
                </u>
              </span>
            </th>
          </tr>
          <tr>
            <td>
              <ol>
                <li>Tennessee professional license(s) which shows expiration date
                </li>
                <li>Professional license(s) from any other states or countries
                </li>
                <li>Current Curriculum Vita/Resume-<u>please use mm/yy or mm/dd/yy
                    format</u>
                </li>
                <li>Copies of <u>ALL DIPLOMAS and/or DEGREES</u>
                </li>
                <li>Copies of all internship, residency, fellowship certificates
                </li>
                <li>Current official transcript <u>(if currently a student working
                    towards a higher degree)</u></li>
                <li>Copies of current professional malpractice liability insurance
                  certificates <u>(we also need these for the past five years, if applicable).</u>
                </li>
                <li>Continuing Education for the past 2 years <u>(please include
                    copies of certificates:</u>if you don't have certificates, please list your cont. education on the
                  attached Education/Training Log)
                </li>
                <li>Any Board Certification certificates
                </li>
                <li>Any other certifications you have
                </li>
                <li>Certificate of Fitness
                </li>
                <li>DEA certificate(s)
                </li>
                <li>ECFMG certificate
                </li>
                <li>If you have an NPI number (National Provider Identifier), we
                  need a copy of your NPI letter that shows your NPI number and Taxonomy code.
                </li>
                <li>Copy of current cards re: CPR, CPR/AED, ACLS, PALS, Heartsaver,
                  First Aid, etc.
                </li>
                <li>3 professional references (peer or higher)- Please include
                  name, title, address, city, state, zip code,and phone number for each reference <b>(please do not list
                    relatives) (you may list these on the attached "Professional References" page)</b>
                </li>
              </ol>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="p-3">
        <div class="row pt-6">
          <div class="col-md-12 text-center">
            <h2>Cherokee Health Systems</h2>
          </div>
          <div class="col-md-12 text-center">
            <h2>Credentialing/Recredentialing Application</h2>
          </div>
        </div>


        <div>
          <label class="pl-3" style="padding-left:30px;font-style:italic"><b>1. General Information</b></label>
        </div>
        <div>
          <label class="pl-3" style="padding-left:30px;font-style:italic">A. Provider Name</label>
        </div>
        <div class="row">
          <div class="col-md-2 form-group">
            <label>Dr./Mr./Mrs./Ms.</label>
          </div>
          <div class="col-md-2 form-group">
            <input class="form-control" id="8" type="text" placeholder="First Name" formControlName="8" [class.is-invalid]="(cherokeeHealthForm.controls['8'].touched && cherokeeHealthForm.controls['8'].invalid) || (cherokeeHealthForm.controls['8'].invalid && isSubmit)" (change)="onChangeFirstName($event)" >
            <div class="formLabel col-md-11"
                *ngIf="cherokeeHealthForm.controls['8'].touched && cherokeeHealthForm.controls['8'].invalid">
                <small class="text-danger" *ngIf="cherokeeHealthForm.controls['8'].errors?.required">
                    required
                </small>
            </div>
          </div>
          <div class="col-md-2 form-group">
            <input class="form-control" id="809" type="text" placeholder="Middle Name" formControlName="809" [class.is-invalid]="(cherokeeHealthForm.controls['809'].touched && cherokeeHealthForm.controls['809'].invalid) || (cherokeeHealthForm.controls['809'].invalid && isSubmit)" (change)="onChangeMiddleName($event)">
            <div class="formLabel col-md-11"
                *ngIf="cherokeeHealthForm.controls['809'].touched && cherokeeHealthForm.controls['809'].invalid">
                <small class="text-danger" *ngIf="cherokeeHealthForm.controls['809'].errors?.required">
                    required
                </small>
            </div>
          </div>
          <div class="col-md-2 form-group">
            <input class="form-control" id="9" type="text" placeholder="Last Name" formControlName="9" [class.is-invalid]="(cherokeeHealthForm.controls['9'].touched && cherokeeHealthForm.controls['9'].invalid) || (cherokeeHealthForm.controls['9'].invalid && isSubmit)" (change)="onChangeLastName($event)">
            <div class="formLabel col-md-11"
                *ngIf="cherokeeHealthForm.controls['9'].touched && cherokeeHealthForm.controls['9'].invalid">
                <small class="text-danger" *ngIf="cherokeeHealthForm.controls['9'].errors?.required">
                    required
                </small>
            </div>
          </div>
          <div class="col-md-2 form-group">
            <label>Highest Degree:</label>
          </div>
          <div class="form-group no-margin col-md-2 sideLabelAlignment">
            <div class="form-group selectDrop dropDrownSet">
                <ng-select [virtualScroll]="true" [items]="credentials" [class.is-invalid]="(cherokeeHealthForm.controls['822'].touched && cherokeeHealthForm.controls['822'].invalid) || (cherokeeHealthForm.controls['822'].invalid && isSubmit)" bindLabel="name" placeholder="Select Credential" bindValue="id" formControlName="822">
                </ng-select>
                <div class="formLabel col-md-2"
                    *ngIf="cherokeeHealthForm.controls['822'].touched && cherokeeHealthForm.controls['822'].invalid">
                    <small class="text-danger" *ngIf="cherokeeHealthForm.controls['822'].errors?.required">
                        required
                    </small>
                </div>
            </div>
        </div>
        </div>
        <br>
        <div class="row">
          <div class="col-md-4 form-group">
            <label>Past and/or current professional name(s) used.</label>
          </div>
          <div class="col-md-6 form-group">
            <input class="form-control " id="278" type="text" placeholder="Prior Name" formControlName="278" [class.is-invalid]="(cherokeeHealthForm.controls['278'].touched && cherokeeHealthForm.controls['278'].invalid) || (cherokeeHealthForm.controls['278'].invalid && isSubmit)">
            <div class="formLabel col-md-11"
                *ngIf="cherokeeHealthForm.controls['278'].touched && cherokeeHealthForm.controls['278'].invalid">
                <small class="text-danger" *ngIf="cherokeeHealthForm.controls['278'].errors?.required">
                    required
                </small>
            </div>
          </div>
        </div>

        <br>
        <div class="row">
          <div class="col-md-2 form-group">
            <label>Social Security No:</label>
          </div>
          <div class="col-md-4 form-group">
            <input class="form-control" id="32" type="text" placeholder="SSN" formControlName="32" [class.is-invalid]="(cherokeeHealthForm.controls['32'].touched && cherokeeHealthForm.controls['32'].invalid) || (cherokeeHealthForm.controls['32'].invalid && isSubmit)">
            <div class="formLabel col-md-11"
                *ngIf="cherokeeHealthForm.controls['32'].touched && cherokeeHealthForm.controls['32'].invalid">
                <small class="text-danger" *ngIf="cherokeeHealthForm.controls['32'].errors?.required">
                    required
                </small>
            </div>
          </div>
          <div class="col-md-2 form-group">
            <label>Date of Birth:</label>
          </div>
          <div class="col-md-4 form-group">
            <div class="input-group">
              <input class="form-control" formControlName="31"  (blur)="clearInputIfInvalidMandi('31')" [class.is-invalid]="(cherokeeHealthForm.controls['31'].touched && cherokeeHealthForm.controls['31'].invalid) || (cherokeeHealthForm.controls['31'].invalid && isSubmit)" placeholder="DOB" ngbDatepicker #d1="ngbDatepicker" container="body">
              <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d1.toggle()" type="button"><i
                    class="fas fa-calendar-alt"></i>
                </button>
              </div>
            </div>
            <div class="text-danger" *ngIf="(cherokeeHealthForm.controls['31'].invalid&&cherokeeHealthForm.controls['31'].touched) && (cherokeeHealthForm.controls['31'].value==''|| cherokeeHealthForm.controls['31'].value==null) " >
              <small >
              required
              </small>

          </div>
            <small class="text-danger" *ngIf="cherokeeHealthForm.get('31').errors?.invalidDateFormat">
              Only Date Format is allowed(MM/DD/YYYY)                </small>
            <small class="text-danger" *ngIf="cherokeeHealthForm.get('31').errors?.invalidMonth">
            Month is out of range
            </small>
            <small class="text-danger" *ngIf="cherokeeHealthForm.get('31').errors?.invalidDateyear">
              year is out of range(1900-2099)                  </small>
              <small class="text-danger" *ngIf="cherokeeHealthForm.get('31').errors?.invalidDateRange">
                date is out of range
                </small>
          </div>

        </div>

        <br>

        <div class="row">
          <div class="col-md-2  form-group">
            <label>Place of Birth:</label>
          </div>
          <div class="col-md-3  form-group">
            <input class="form-control" id="810" type="text" placeholder="Birth City" formControlName="810" [class.is-invalid]="(cherokeeHealthForm.controls['810'].touched && cherokeeHealthForm.controls['810'].invalid) || (cherokeeHealthForm.controls['810'].invalid && isSubmit)">
            <div class="formLabel col-md-11"
                *ngIf="cherokeeHealthForm.controls['810'].touched && cherokeeHealthForm.controls['810'].invalid">
                <small class="text-danger" *ngIf="cherokeeHealthForm.controls['810'].errors?.required">
                    required
                </small>
            </div>
          </div>
          <div class="col-md-3 form-group">
            <ng-select [virtualScroll]="true" [items]="stateDropDownDate" bindLabel="name" placeholder="Select Birth State" bindValue="id" formControlName="289" [class.is-invalid]="(cherokeeHealthForm.controls['289'].touched && cherokeeHealthForm.controls['289'].invalid) || (cherokeeHealthForm.controls['289'].invalid && isSubmit)">
            </ng-select>
            <div class="formLabel col-md-11"
                *ngIf="cherokeeHealthForm.controls['289'].touched && cherokeeHealthForm.controls['289'].invalid">
                <small class="text-danger" *ngIf="cherokeeHealthForm.controls['289'].errors?.required">
                    required
                </small>
            </div>
          </div>
          <div class="col-md-3 form-group">
            <input class="form-control " id="291" type="text" placeholder="Birth Country" formControlName="291" [class.is-invalid]="(cherokeeHealthForm.controls['291'].touched && cherokeeHealthForm.controls['291'].invalid) || (cherokeeHealthForm.controls['291'].invalid && isSubmit)">
            <div class="formLabel col-md-11"
                *ngIf="cherokeeHealthForm.controls['291'].touched && cherokeeHealthForm.controls['291'].invalid">
                <small class="text-danger" *ngIf="cherokeeHealthForm.controls['291'].errors?.required">
                    required
                </small>
            </div>
          </div>
        </div>
        <hr
          style="border: 17px solid #818181; margin-left: -87px;margin-right: -87px; margin-top: 50px; margin-bottom: 50px;">


        <div class="row pt-6">
          <div class="col-md-12 text-center">
            <h2>Cherokee Health Systems</h2>
          </div>
          <div class="col-md-12 text-center">
            <h2>Credentialing/Recredentialing Application</h2>
          </div>
          <div class="col-md-12">
            <label style="padding-left:30px;font-style:italic"><b>2. Languages</b></label>
          </div>
        </div>


        <div class="row pt-2">
          <div class="form-group col-md-4">
            <label>What is your native language?
            </label>
          </div>
          <div class=" form-group col-md-4">
            <ng-select [virtualScroll]="true" [items]="languages" bindLabel="name" placeholder="Langauge 1" bindValue="id" formControlName="277" [class.is-invalid]="(cherokeeHealthForm.controls['277'].touched && cherokeeHealthForm.controls['277'].invalid) || (cherokeeHealthForm.controls['277'].invalid && isSubmit)">
            </ng-select>
            <div class="formLabel col-md-11"
                *ngIf="cherokeeHealthForm.controls['277'].touched && cherokeeHealthForm.controls['277'].invalid">
                <small class="text-danger" *ngIf="cherokeeHealthForm.controls['277'].errors?.required">
                    required
                </small>
            </div>
          </div>
        </div>

        <div>

          <table class="dataTable" border="1">
            <tbody>
              <tr>
                <th rowspan="2">Language </th>
                <th colspan="2">Writing</th>
                <th colspan="2">Conversation</th>
                <th colspan="2">Treatment</th>

              </tr>
              <tr>
                <td>Yes</td>
                <td>No</td>
                <td>Yes</td>
                <td>No</td>
                <td>Yes</td>
                <td>No</td>
              </tr>
              <tr>
                <td>English</td>
                <td>
                  <div>
                    <label class="checkbox-inline">
                      <input type="radio" id="cmd_1521" name="cmd_1521" formControlName="cmd_1521" value="1"></label>
                  </div>
                </td>
                <td>
                  <div>
                    <label class="checkbox-inline">
                      <input type="radio" id="cmd_1521_1" name="cmd_1521" formControlName="cmd_1521" value="0"></label>
                  </div>
                </td>
                <td>
                  <div>
                    <label class="checkbox-inline">
                      <input type="radio" id="cmd_1522" name="cmd_1522" formControlName="cmd_1522" value="1"></label>
                  </div>

                </td>
                <td>
                  <div>
                    <label class="checkbox-inline">
                      <input type="radio" id="cmd_1522_1" name="cmd_1522" formControlName="cmd_1522" value="0"></label>
                  </div>
                </td>
                <td>
                  <div>
                    <label class="checkbox-inline">
                      <input type="radio" id="cmd_1523" name="cmd_1523" formControlName="cmd_1523" value="1"></label>
                  </div>

                </td>
                <td>
                  <div>
                    <label class="checkbox-inline">
                      <input type="radio" id="cmd_1523_1" name="cmd_1523" formControlName="cmd_1523" value="0"></label>
                  </div>
                </td>

              </tr>
              <tr>
                <td>American Sign Language </td>
                <td>
                  <div>
                    <label class="checkbox-inline">
                      <input type="radio" id="cmd_1524" name="cmd_1524" formControlName="cmd_1524" value="1"></label>
                  </div>

                </td>
                <td>
                  <div>
                    <label class="checkbox-inline">
                      <input type="radio" id="cmd_1524_1" name="cmd_1524" formControlName="cmd_1524" value="0"></label>
                  </div>
                </td>
                <td>
                  <div>
                    <label class="checkbox-inline">
                      <input type="radio" id="cmd_1525" name="cmd_1525" formControlName="cmd_1525" value="1"></label>
                  </div>

                </td>
                <td>
                  <div>
                    <label class="checkbox-inline">
                      <input type="radio" id="cmd_1525_1" name="cmd_1525" formControlName="cmd_1525" value="0"></label>
                  </div>
                </td>
                <td>
                  <div>
                    <label class="checkbox-inline">
                      <input type="radio" id="cmd_1526" name="cmd_1526" formControlName="cmd_1526" value="1"></label>
                  </div>

                </td>
                <td>
                  <div>
                    <label class="checkbox-inline">
                      <input type="radio" id="cmd_1526_1" name="cmd_1526" formControlName="cmd_1526" value="0"></label>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                    <ng-select [virtualScroll]="true" [items]="secondaryLanguages" bindLabel="name" placeholder="Langauge 2" bindValue="id"
                      formControlName="1535">
                    </ng-select>
                  </div>
                </td>
                <td>
                  <div>
                    <label class="checkbox-inline">
                      <input type="radio" id="cmd_1527" name="cmd_1527" formControlName="cmd_1527" value="1"></label>
                  </div>

                </td>
                <td>
                  <div>
                    <label class="checkbox-inline">
                      <input type="radio" id="cmd_1527_1" name="cmd_1527" formControlName="cmd_1527" value="0"></label>
                  </div>
                </td>
                <td>
                  <div>
                    <label class="checkbox-inline">
                      <input type="radio" id="cmd_1528" name="cmd_1528" formControlName="cmd_1528" value="1"></label>
                  </div>

                </td>
                <td>
                  <div>
                    <label class="checkbox-inline">
                      <input type="radio" id="cmd_1528_1" name="cmd_1528" formControlName="cmd_1528" value="0"></label>
                  </div>
                </td>
                <td>
                  <div>
                    <label class="checkbox-inline">
                      <input type="radio" id="cmd_1529" name="cmd_1529" formControlName="cmd_1529" value="1"></label>
                  </div>

                </td>
                <td>
                  <div>
                    <label class="checkbox-inline">
                      <input type="radio" id="cmd_1529_1" name="cmd_1529" formControlName="cmd_1529" value="0"></label>
                  </div>
                </td>

              </tr>
              <tr>
                <td>
                  <div>
                    <ng-select [virtualScroll]="true" [items]="tertiaryLanguages" formControlName="1536" bindLabel="name" placeholder="Langauge 3" bindValue="id">
                    </ng-select>
                  </div>
                </td>
                <td>
                  <div>
                    <label class="checkbox-inline">
                      <input type="radio" id="cmd_1530" name="cmd_1530" formControlName="cmd_1530" value="1"></label>
                  </div>

                </td>
                <td>
                  <div>
                    <label class="checkbox-inline">
                      <input type="radio" id="cmd_1530_1" name="cmd_1530" formControlName="cmd_1530" value="0"></label>
                  </div>
                </td>
                <td>
                  <div>
                    <label class="checkbox-inline">
                      <input type="radio" id="cmd_1531" name="cmd_1531" formControlName="cmd_1531" value="1"></label>
                  </div>

                </td>
                <td>
                  <div>
                    <label class="checkbox-inline">
                      <input type="radio" id="cmd_1531_1" name="cmd_1531" formControlName="cmd_1531" value="0"></label>
                  </div>
                </td>
                <td>
                  <div>
                    <label class="checkbox-inline">
                      <input type="radio" id="cmd_1532" name="cmd_1532" formControlName="cmd_1532" value="1"></label>
                  </div>

                </td>
                <td>
                  <div>
                    <label class="checkbox-inline">
                      <input type="radio" id="cmd_1532_1" name="cmd_1532" formControlName="cmd_1532" value="0"></label>
                  </div>
                </td>

              </tr>
            </tbody>
          </table>

        </div>



        <div class="row pt-2">
          <label><b>3. Licensure/Education/Certification Information</b></label>

        </div>

        <div class="row">
          <div class="form-group col-md-4">
            <label>Are you CEAP Certified?
            </label>
          </div>
          <div class="col-md-3">
            <label class="checkbox-inline pr-2">
              <input type="radio" id="cmd_1360" name="cmd_1360" formControlName="cmd_1360" value="1"> Yes</label>
            <label class="checkbox-inline">
              <input type="radio" id="cmd_1360_1" name="cmd_1360" formControlName="cmd_1360" value="0"> No</label>
          </div>
        </div>


        <div class="row">
          <div class="col-md-6">
            <label>Substance Abuse/Addiction Certification (state or national):</label>
          </div>
          <div class="col-md-4">
            <input class="form-control  " id="cmd_1533" name="cmd_1533" formControlName="cmd_1533" type="text">
          </div>
        </div>


        <div class="row">
          <label class="pl-2">Active State Licensure </label>
        </div>
        <div class="row">
          <div class="col-md-2  form-group inline-form">
            <label>State:</label>
            <ng-select [virtualScroll]="true" [items]="stateDropDownDate" bindLabel="name" placeholder="Medical lic State" bindValue="id"
              formControlName="299">
            </ng-select>
          </div>
          <div class="col-md-2  form-group inline-form">
            <label>License#:</label>
            <input class="form-control" id="293" formControlName="293" name="293" type="text"
              placeholder="Medical lic number" data-id="prior_name">
          </div>
          <div class="col-md-2 form-group inline-form">
            <label>Type:</label>
            <input class="form-control" id="296" formControlName="296" name="296" type="text"
              placeholder="Medical lic Type">
          </div>
          <div class="col-md-3 form-group inline-form">
            <label>Issue Date:</label>
            <div class="input-group">
              <input class="form-control" formControlName="294" (blur)="clearInputIfInvalid('294')" name="dp" placeholder="Medical lic IssueDate"
                ngbDatepicker #d2="ngbDatepicker" placement="bottom">
              <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d2.toggle()" type="button"><i
                    class="fas fa-calendar-alt"></i></button>
              </div>
              <div  *ngIf="cherokeeHealthForm.get('294').invalid ">
                <small class="text-danger" *ngIf="cherokeeHealthForm.get('294').errors?.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY)
                </small>
                <small class="text-danger" *ngIf="cherokeeHealthForm.get('294').errors?.invalidMonth">
                Month is out of range
                </small>
                <small class="text-danger" *ngIf="cherokeeHealthForm.get('294').errors?.invalidDateyear">
                  year is out of range(1900-2099)                      </small>
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('294').errors?.invalidDateRange">
                    date is out of range
                    </small>
                </div>
            </div>
           
          </div>
          <div class="col-md-3 form-group inline-form">
            <label>Exp. Date:</label>
            <div class="input-group">
              <input class="form-control" formControlName="295" (blur)="clearInputIfInvalid('295')" name="dp" placeholder="Medical lic ExpDate"
                ngbDatepicker #d3="ngbDatepicker" placement="bottom">
              <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d3.toggle()" type="button"><i
                    class="fas fa-calendar-alt"></i></button>
              </div>
              <div  *ngIf="cherokeeHealthForm.get('295').invalid ">
                <small class="text-danger" *ngIf="cherokeeHealthForm.get('295').errors?.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY)
                </small>
                <small class="text-danger" *ngIf="cherokeeHealthForm.get('295').errors?.invalidMonth">
                Month is out of range
                </small>
                <small class="text-danger" *ngIf="cherokeeHealthForm.get('295').errors?.invalidDateyear">
                  year is out of range(1900-2099)                      </small>
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('295').errors?.invalidDateRange">
                    date is out of range
                    </small>
                </div>
            </div>
          </div>

        </div>


        <div class="row">
          <div class="col-md-2 inline-form form-group ">
            <label>State:</label>
            <ng-select [virtualScroll]="true" [items]="alternateLicenseStateData" bindLabel="name" placeholder="Select ALT State" bindValue="id"
              formControlName="907">
            </ng-select>
          </div>
          <div class="col-md-2 inline-form form-group">
            <label>License#:</label>
            <input class="form-control  " id="300" formControlName="300" name="300" type="text"
              placeholder="alt medical lic number" data-id="prior_name">
          </div>
          <div class="col-md-2 inline-form form-group">
            <label>Type:</label>
            <input class="form-control  " id="1274" formControlName="1274" name="1274" type="text"
              placeholder="alt medical lic Type">
          </div>
          <div class="col-md-3 inline-form form-group">
            <label>Issue Date:</label>
            <div class="input-group">
              <input class="form-control" formControlName="307" (blur)="clearInputIfInvalid('307')" name="dp" placeholder="alt medical lic IssueDate"
                ngbDatepicker #d4="ngbDatepicker" placement="bottom">
              <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d4.toggle()" type="button"><i
                    class="fas fa-calendar-alt"></i></button>
              </div>
              <div  *ngIf="cherokeeHealthForm.get('307').invalid ">
                <small class="text-danger" *ngIf="cherokeeHealthForm.get('307').errors?.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY)
                </small>
                <small class="text-danger" *ngIf="cherokeeHealthForm.get('307').errors?.invalidMonth">
                Month is out of range
                </small>
                <small class="text-danger" *ngIf="cherokeeHealthForm.get('307').errors?.invalidDateyear">
                  year is out of range(1900-2099)                      </small>
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('307').errors?.invalidDateRange">
                    date is out of range
                    </small>
                </div>
              
            </div>
          </div>
          <div class="col-md-3 inline-form form-group">
            <label>Exp. Date:</label>
            <div class="input-group">
              <input class="form-control" formControlName="308" (blur)="clearInputIfInvalid('308')" name="dp" placeholder="alt medical lic ExpDate"
                ngbDatepicker #d5="ngbDatepicker" placement="bottom">
              <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d5.toggle()" type="button"><i
                    class="fas fa-calendar-alt"></i></button>
              </div>
              <div  *ngIf="cherokeeHealthForm.get('308').invalid ">
                <small class="text-danger" *ngIf="cherokeeHealthForm.get('308').errors?.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY)
                </small>
                <small class="text-danger" *ngIf="cherokeeHealthForm.get('308').errors?.invalidMonth">
                Month is out of range
                </small>
                <small class="text-danger" *ngIf="cherokeeHealthForm.get('308').errors?.invalidDateyear">
                  year is out of range(1900-2099)                      </small>
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('308').errors?.invalidDateRange">
                    date is out of range
                    </small>
                </div>
            </div>
          </div>

        </div>


        <div class="row">
          <div class="col-md-2 inline-form form-group">
            <label>State:</label>
            <ng-select [virtualScroll]="true" [items]="additionalLicenseStateData" bindLabel="name" formControlName="1010"
              placeholder="Select Add State" bindValue="id">
            </ng-select>
          </div>
          <div class="col-md-2 inline-form form-group">
            <label>License#:</label>
            <input class="form-control" formControlName="999" id="999" name="999" type="text"
              placeholder="add medical lic number">
          </div>
          <div class="col-md-2 inline-form form-group">
            <label>Type:</label>
            <input class="form-control" formControlName="1432" id="1432" name="1432" type="text"
              placeholder="add medical lic Type">
          </div>
          <div class="col-md-3 inline-form form-group">
            <label>Issue Date:</label>
            <div class="input-group">
              <input class="form-control" formControlName="1003"  (blur)="clearInputIfInvalid('1003')" name="dp" placeholder="add medical lic Issue"
                ngbDatepicker #d6="ngbDatepicker" placement="bottom">
              <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d6.toggle()" type="button"><i
                    class="fas fa-calendar-alt"></i></button>
                    
              </div>
              <div  *ngIf="cherokeeHealthForm.get('1003').invalid ">
                <small class="text-danger" *ngIf="cherokeeHealthForm.get('1003').errors?.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY)
                </small>
                <small class="text-danger" *ngIf="cherokeeHealthForm.get('1003').errors?.invalidMonth">
                Month is out of range
                </small>
                <small class="text-danger" *ngIf="cherokeeHealthForm.get('1003').errors?.invalidDateyear">
                  year is out of range(1900-2099)                      </small>
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('1003').errors?.invalidDateRange">
                    date is out of range
                    </small>
                </div>
            </div>
          </div>
          <div class="col-md-3 inline-form form-group">
            <label>Exp. Date:</label>
            <div class="input-group">
              <input class="form-control" formControlName="1004" (blur)="clearInputIfInvalid('1004')" name="dp" placeholder="add Medical lic Exp"
                ngbDatepicker #d7="ngbDatepicker" placement="bottom">
              <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d7.toggle()" type="button"><i
                    class="fas fa-calendar-alt"></i></button>
                  
              </div>
              <div  *ngIf="cherokeeHealthForm.get('1004').invalid ">
                <small class="text-danger" *ngIf="cherokeeHealthForm.get('1004').errors?.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY)
                </small>
                <small class="text-danger" *ngIf="cherokeeHealthForm.get('1004').errors?.invalidMonth">
                Month is out of range
                </small>
                <small class="text-danger" *ngIf="cherokeeHealthForm.get('1004').errors?.invalidDateyear">
                  year is out of range(1900-2099)                      </small>
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('1004').errors?.invalidDateRange">
                    date is out of range
                    </small>
                </div>
            </div>
          </div>

        </div>


        <div class="row">
          <label style="padding-left:30px;font-style:italic"><b>Education/Training</b></label>
        </div>


        <div class="row">
          <label style="padding-left:25px;"><b>Undergraduate</b></label>
        </div>


        <div class="row">
          <div class="col-md-2">
            <label>Institution:</label>
          </div>
          <div class="col-md-3 form-group">
            <input class="form-control" id="503" formControlName="503" name="503" type="text"
              placeholder="Undergrad sch name">
          </div>
          <div class="col-md-3">
            <label>Degree Awarded:</label>
          </div>
          <div class="col-md-4">
            <ng-select [virtualScroll]="true" [items]="degree" bindLabel="name" placeholder="Undergrad sch degree" bindValue="id"
              formControlName="512">
            </ng-select>
          </div>
        </div>


        <div class="row">
          <div class="col-md-5 inline-form">
            <label>City/State:</label>
            <input class="form-control" id="506" formControlName="506" name="506" type="text"
              placeholder="Undergrad sch city">
            <ng-select [virtualScroll]="true" [items]="stateDropDownDate" bindLabel="name" placeholder="Undergrad sch State" bindValue="id"
              formControlName="508">
            </ng-select>
          </div>
          <div class="col-md-1">

            <label>Start Date:</label>
          </div>
          <div class="col-md-2">
            <div class="input-group">
              <input class="form-control" formControlName="510" (blur)="clearInputIfInvalid('510')" name="dp" placeholder="Undergrad sch Start"
                ngbDatepicker #d8="ngbDatepicker" placement="bottom">
              <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d8.toggle()" type="button"><i
                    class="fas fa-calendar-alt"></i></button>
              </div>
              <div  *ngIf="cherokeeHealthForm.get('510').invalid ">
                <small class="text-danger" *ngIf="cherokeeHealthForm.get('510').errors?.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY)
                </small>
                <small class="text-danger" *ngIf="cherokeeHealthForm.get('510').errors?.invalidMonth">
                Month is out of range
                </small>
                <small class="text-danger" *ngIf="cherokeeHealthForm.get('510').errors?.invalidDateyear">
                  year is out of range(1900-2099)                      </small>
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('510').errors?.invalidDateRange">
                    date is out of range
                    </small>
                </div>
            </div>
          </div>
          <div class="col-md-1">
            <label>End Date:</label>
          </div>
          <div class="col-md-2">
            <div class="input-group">
              <input class="form-control" formControlName="1238" (blur)="clearInputIfInvalid('1238')" name="dp" placeholder="Undergrad sch End" ngbDatepicker
                #d9="ngbDatepicker" placement="bottom">
              <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d9.toggle()" type="button"><i
                    class="fas fa-calendar-alt"></i></button>
              </div>
              <div  *ngIf="cherokeeHealthForm.get('1238').invalid ">
                <small class="text-danger" *ngIf="cherokeeHealthForm.get('1238').errors?.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY)
                </small>
                <small class="text-danger" *ngIf="cherokeeHealthForm.get('1238').errors?.invalidMonth">
                Month is out of range
                </small>
                <small class="text-danger" *ngIf="cherokeeHealthForm.get('1238').errors?.invalidDateyear">
                  year is out of range(1900-2099)                      </small>
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('1238').errors?.invalidDateRange">
                    date is out of range
                    </small>
                </div>
            </div>
          </div>
        </div>


        <div class="row">
          <div class="col-md-4">
            <label>Provider name when degree was awarded:</label>
          </div>
          <div class="col-md-3">
            <input class="form-control  " id="cmd_1514" formControlName="cmd_1514" name="cmd_1514" type="text"
              placeholder="">
          </div>
          <div class="col-md-2">
            <label>Field of Study</label>
          </div>
          <div class="col-md-3">
            <input class="form-control" formControlName="513" id="513" name="513" type="text"
              placeholder="Undergrad sch program">
          </div>
        </div>


        <div class="row">
          <label style="padding-left:25px;"><b>Graduate</b></label>
        </div>


        <div class="row">
          <div class="col-md-1">
            <label>Institution:</label>
          </div>
          <div class="col-md-3">
            <input class="form-control" formControlName="479" id="479" name="479" type="text"
              placeholder="Graduate sch name">
          </div>
          <div class="form-group col-md-5">
            <label>APA-approved, regionally accredited graduate prog:
            </label>
          </div>
          <div class="col-md-3">
            <label class="checkbox-inline">
              <input type="radio" id="cmd_1361" formControlName="cmd_1361" name="cmd_1361" value="1">Yes</label>
            <label class="checkbox-inline">
              <input type="radio" id="cmd_1361_1" formControlName="cmd_1361" name="cmd_1361" value="0">No</label>
          </div>
        </div>


        <div class="row">
          <div class="col-md-5 inline-form">
            <label>City/State:</label>
            <input class="form-control" id="485" formControlName="485" name="485" type="text"
              placeholder="Graduate sch city">
            <ng-select [virtualScroll]="true" [items]="stateDropDownDate" bindLabel="name" placeholder="Graduate sch State" bindValue="id"
              formControlName="487">
            </ng-select>
          </div>
          <div class="col-md-1">
            <label>Start Date:</label>
          </div>
          <div class="col-md-2">
            <div class="input-group">
              <input class="form-control" formControlName="481" (blur)="clearInputIfInvalid('481')"  name="dp" placeholder="Graduate sch Start" ngbDatepicker
                #d10="ngbDatepicker" placement="bottom">
              <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d10.toggle()" type="button"><i
                    class="fas fa-calendar-alt"></i></button>
              </div>
              <div  *ngIf="cherokeeHealthForm.get('481').invalid ">
                <small class="text-danger" *ngIf="cherokeeHealthForm.get('481').errors?.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY)
                </small>
                <small class="text-danger" *ngIf="cherokeeHealthForm.get('481').errors?.invalidMonth">
                Month is out of range
                </small>
                <small class="text-danger" *ngIf="cherokeeHealthForm.get('481').errors?.invalidDateyear">
                  year is out of range(1900-2099)                      </small>
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('481').errors?.invalidDateRange">
                    date is out of range
                    </small>
                </div>
            </div>
          </div>
          <div class="col-md-1">
            <label>End Date:</label>
          </div>
          <div class="col-md-2">
            <div class="input-group">
              <input class="form-control" formControlName="1300" (blur)="clearInputIfInvalid('1300')"  name="dp" placeholder="Graduate sch End" ngbDatepicker
                #d11="ngbDatepicker" placement="bottom">
              <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d11.toggle()" type="button"><i
                    class="fas fa-calendar-alt"></i></button>
              </div>
              <div  *ngIf="cherokeeHealthForm.get('1300').invalid ">
                <small class="text-danger" *ngIf="cherokeeHealthForm.get('1300').errors?.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY)
                </small>
                <small class="text-danger" *ngIf="cherokeeHealthForm.get('1300').errors?.invalidMonth">
                Month is out of range
                </small>
                <small class="text-danger" *ngIf="cherokeeHealthForm.get('1300').errors?.invalidDateyear">
                  year is out of range(1900-2099)                      </small>
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('1300').errors?.invalidDateRange">
                    date is out of range
                    </small>
                </div>
            </div>
          </div>
          <div class="col-md-1">
            <label>Degree Awarded:</label>
          </div>
          <div class="col-md-2">
            <ng-select [virtualScroll]="true" [items]="degree" bindLabel="name" placeholder="Graduate School credentials" bindValue="id"
              formControlName="480">
            </ng-select>
          </div>

          <div class="row">
            <div class="col-md-4">
              <label>Provider name when degree was awarded:</label>
            </div>
            <div class="col-md-3">
              <input class="form-control" id="cmd_1513" formControlName="cmd_1513" name="cmd_1513" type="text">
            </div>
            <div class="col-md-2">
              <label>Field of Study</label>
            </div>
            <div class="col-md-3">
              <input class="form-control" formControlName="489" id="489" name="489" type="text"
                placeholder="Graduate sch program">
            </div>
          </div>


        </div>


        <div class="row">
          <label style="padding-left:25px;"><b>Medical School</b></label>
        </div>


        <div class="row">
          <div class="col-md-1">
            <label>Institution:</label>
          </div>
          <div class="col-md-3">
            <input class="form-control" formControlName="490" id="490" name="490" type="text"
              placeholder="Medical sch name">
          </div>
          <div class="form-group col-md-5">
            <label>APA-approved, regionally accredited graduate prog:
            </label>
          </div>
          <div class="col-md-3">
            <label class="checkbox-inline">
              <input type="radio" id="cmd_1511" formControlName="cmd_1511" name="cmd_1511" value="1">Yes</label>
            <label class="checkbox-inline">
              <input type="radio" id="cmd_1511_1" formControlName="cmd_1511" name="cmd_1511" value="0">No</label>
          </div>
        </div>


        <div class="row">
          <div class="col-md-5 inline-form">
            <label>City/State:</label>
            <input class="form-control" id="493" formControlName="493" name="493" type="text"
              placeholder="Medical sch city">
            <ng-select [virtualScroll]="true" [items]="stateDropDownDate" bindLabel="name" placeholder="Medical sch State" bindValue="id"
              formControlName="495">
            </ng-select>
          </div>
          <div class="col-md-1">
            <label>Start Date:</label>
          </div>
          <div class="col-md-2">
            <div class="input-group">
              <input class="form-control" formControlName="497" (blur)="clearInputIfInvalid('497')" name="dp" placeholder="Medical sch Start" ngbDatepicker
                #d12="ngbDatepicker" placement="bottom">
              <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d12.toggle()" type="button"><i
                    class="fas fa-calendar-alt"></i></button>
              </div>
              <div  *ngIf="cherokeeHealthForm.get('497').invalid ">
                <small class="text-danger" *ngIf="cherokeeHealthForm.get('497').errors?.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY)
                </small>
                <small class="text-danger" *ngIf="cherokeeHealthForm.get('497').errors?.invalidMonth">
                Month is out of range
                </small>
                <small class="text-danger" *ngIf="cherokeeHealthForm.get('497').errors?.invalidDateyear">
                  year is out of range(1900-2099)                      </small>
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('497').errors?.invalidDateRange">
                    date is out of range
                    </small>
                </div>
            </div>
          </div>
          <div class="col-md-1">
            <label>End Date:</label>
          </div>
          <div class="col-md-2">
            <div class="input-group">
              <input class="form-control" formControlName="498" (blur)="clearInputIfInvalid('498')" name="dp" placeholder="Medical sch End" ngbDatepicker
                #d13="ngbDatepicker" placement="bottom">
              <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d13.toggle()" type="button"><i
                    class="fas fa-calendar-alt"></i></button>
              </div>
              <div  *ngIf="cherokeeHealthForm.get('498').invalid ">
                <small class="text-danger" *ngIf="cherokeeHealthForm.get('498').errors?.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY)
                </small>
                <small class="text-danger" *ngIf="cherokeeHealthForm.get('498').errors?.invalidMonth">
                Month is out of range
                </small>
                <small class="text-danger" *ngIf="cherokeeHealthForm.get('498').errors?.invalidDateyear">
                  year is out of range(1900-2099)                      </small>
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('498').errors?.invalidDateRange">
                    date is out of range
                    </small>
                </div>
            </div>
          </div>
          <div class="col-md-1">
            <label>Degree Awarded:</label>
          </div>
          <div class="col-md-2">
            <ng-select [virtualScroll]="true" [items]="degree" bindLabel="name" placeholder="Medical School credentials" bindValue="id"
              formControlName="500">
            </ng-select>
          </div>

        </div>


        <div class="row">
          <div class="col-md-4">
            <label>Provider name when degree was awarded:</label>
          </div>
          <div class="col-md-3">
            <input class="form-control" formControlName="cmd_1512" id="cmd_1512" name="cmd_1512" type="text">
          </div>
          <div class="col-md-2">
            <label>Field of Study</label>
          </div>
          <div class="col-md-3">
            <input class="form-control" formControlName="1376" id="1376" name="1376" type="text"
              placeholder="Medical sch program">
          </div>
        </div>


        <div class="row">
          <label style="padding-left:25px;"><b>Internship</b></label>
        </div>


        <div class="row">
          <div class="col-md-1">
            <label>Institution:</label>
          </div>
          <div class="col-md-3">
            <input class="form-control" formControlName="517" id="517" name="517" type="text"
              placeholder="Internship School Name">
          </div>
          <div class="form-group col-md-5">
            <label>APA-approved, regionally accredited graduate prog:
            </label>
          </div>
          <div class="col-md-3">
            <label class="checkbox-inline">
              <input type="radio" id="cmd_1362" formControlName="cmd_1362" name="cmd_1362" value="1">Yes</label>
            <label class="checkbox-inline">
              <input type="radio" id="cmd_1362_1" formControlName="cmd_1362" name="cmd_1362" value="0">No</label>
          </div>
        </div>


        <div class="row">
          <div class="col-md-5 inline-form">
            <label>City/State:</label>
            <input class="form-control" formControlName="520" id="520" name="520" type="text"
              placeholder="Internship School city">
            <ng-select [virtualScroll]="true" [items]="stateDropDownDate" bindLabel="name" placeholder="Internship School State" bindValue="id"
              formControlName="522">
            </ng-select>
          </div>
          <div class="col-md-1">
            <label>Start Date:</label>
          </div>
          <div class="col-md-2">
            <div class="input-group">
              <input class="form-control" formControlName="524" (blur)="clearInputIfInvalid('524')" name="dp" placeholder="Internship School start"
                ngbDatepicker #d14="ngbDatepicker" placement="bottom">
              <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d14.toggle()" type="button"><i
                    class="fas fa-calendar-alt"></i></button>
              </div>
              <div  *ngIf="cherokeeHealthForm.get('524').invalid ">
                <small class="text-danger" *ngIf="cherokeeHealthForm.get('524').errors?.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY)
                </small>
                <small class="text-danger" *ngIf="cherokeeHealthForm.get('524').errors?.invalidMonth">
                Month is out of range
                </small>
                <small class="text-danger" *ngIf="cherokeeHealthForm.get('524').errors?.invalidDateyear">
                  year is out of range(1900-2099)                      </small>
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('524').errors?.invalidDateRange">
                    date is out of range
                    </small>
                </div>
            </div>
          </div>
          <div class="col-md-1">
            <label>End Date:</label>
          </div>
          <div class="col-md-2">
            <div class="input-group">
              <input class="form-control" formControlName="525" (blur)="clearInputIfInvalid('525')" name="dp" placeholder="Internship School end"
                ngbDatepicker #d15="ngbDatepicker" placement="bottom">
              <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d15.toggle()" type="button"><i
                    class="fas fa-calendar-alt"></i></button>
              </div>
              <div  *ngIf="cherokeeHealthForm.get('525').invalid ">
                <small class="text-danger" *ngIf="cherokeeHealthForm.get('525').errors?.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY)
                </small>
                <small class="text-danger" *ngIf="cherokeeHealthForm.get('525').errors?.invalidMonth">
                Month is out of range
                </small>
                <small class="text-danger" *ngIf="cherokeeHealthForm.get('525').errors?.invalidDateyear">
                  year is out of range(1900-2099)                      </small>
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('525').errors?.invalidDateRange">
                    date is out of range
                    </small>
                </div>
            </div>
          </div>
          <div class="col-md-1">
            <label>Degree Awarded:</label>
          </div>
          <div class="col-md-2">
            <ng-select [virtualScroll]="true" [items]="specialty" bindLabel="name" placeholder="intern specialty" bindValue="id"
              formControlName="527">
            </ng-select>
          </div>

        </div>


        <div class="row">
          <div class="col-md-3">
            <label>Provider name when degree was awarded:</label>
          </div>
          <div class="col-md-3">
            <input class="form-control  " id="cmd_1515" formControlName="cmd_1515" name="cmd_1515" type="text">
          </div>
          <div class="form-group col-md-3">
            <label>APA-approved internship
            </label>
          </div>
          <div class="col-md-3">
            <label class="checkbox-inline">
              <input type="radio" id="cmd_1363" name="cmd_1363" formControlName="cmd_1363" value="1">Yes</label>
            <label class="checkbox-inline">
              <input type="radio" id="cmd_1363_1" name="cmd_1363" formControlName="cmd_1363" value="0">No</label>
          </div>
        </div>




        <div class="row">
          <label style="padding-left:25px;"><b>Residency</b></label>
        </div>


        <div class="row">
          <div class="col-md-1">
            <label>Institution:</label>
          </div>
          <div class="col-md-3">
            <input class="form-control  " id="529" name="529" formControlName="529" type="text" placeholder="Residency sch name">
          </div>
          <div class="form-group col-md-5">
            <label>APA-approved, regionally accredited graduate prog:
            </label>
          </div>
          <div class="col-md-3">
            <label class="checkbox-inline">
              <input type="radio" id="cmd_1364" name="cmd_1364" formControlName="cmd_1364" value="1">Yes</label>
            <label class="checkbox-inline">
              <input type="radio" id="cmd_1364_1" name="cmd_1364" formControlName="cmd_1364" value="0">No</label>
          </div>
        </div>


        <div class="row">
          <div class="col-md-5 inline-form">
            <label>City/State:</label>
            <input class="form-control  " id="533" formControlName="533" name="533" type="text"
              placeholder="Residency School city">
            <ng-select [virtualScroll]="true" [items]="stateDropDownDate" bindLabel="name" placeholder="Residency sch State" bindValue="id"
              formControlName="535">
            </ng-select>
          </div>
          <div class="col-md-1">
            <label>Start Date:</label>
          </div>
          <div class="col-md-2">
            <div class="input-group">
              <input class="form-control" formControlName="537" (blur)="clearInputIfInvalid('537')" name="dp" placeholder="Residency School start"
                ngbDatepicker #d16="ngbDatepicker" placement="bottom">
              <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d16.toggle()" type="button"><i
                    class="fas fa-calendar-alt"></i></button>
              </div>
              <div  *ngIf="cherokeeHealthForm.get('537').invalid ">
                <small class="text-danger" *ngIf="cherokeeHealthForm.get('537').errors?.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY)
                </small>
                <small class="text-danger" *ngIf="cherokeeHealthForm.get('537').errors?.invalidMonth">
                Month is out of range
                </small>
                <small class="text-danger" *ngIf="cherokeeHealthForm.get('537').errors?.invalidDateyear">
                  year is out of range(1900-2099)                      </small>
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('537').errors?.invalidDateRange">
                    date is out of range
                    </small>
                </div>
            </div>
          </div>
          <div class="col-md-1">
            <label>End Date:</label>
          </div>
          <div class="col-md-2">
            <div class="input-group">
              <input class="form-control" formControlName="538" (blur)="clearInputIfInvalid('538')" name="dp" placeholder="Residency School end"
                ngbDatepicker #d17="ngbDatepicker" placement="bottom">
              <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d17.toggle()" type="button"><i
                    class="fas fa-calendar-alt"></i></button>
              </div>
              <div  *ngIf="cherokeeHealthForm.get('538').invalid ">
                <small class="text-danger" *ngIf="cherokeeHealthForm.get('538').errors?.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY)
                </small>
                <small class="text-danger" *ngIf="cherokeeHealthForm.get('538').errors?.invalidMonth">
                Month is out of range
                </small>
                <small class="text-danger" *ngIf="cherokeeHealthForm.get('538').errors?.invalidDateyear">
                  year is out of range(1900-2099)                      </small>
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('538').errors?.invalidDateRange">
                    date is out of range
                    </small>
                </div>
            </div>
          </div>
          <div class="col-md-1">
            <label>Degree Awarded:</label>
          </div>
          <div class="col-md-2">
            <input class="form-control  " id="cmd_1519" formControlName="cmd_1519" name="cmd_1519" type="text"
              placeholder="">
          </div>

        </div>


        <div class="row">
          <div class="col-md-4">
            <label>Provider name when degree was awarded:</label>
          </div>
          <div class="col-md-3">
            <input class="form-control  " id="cmd_1664" formControlName="cmd_1664" name="cmd_1664" type="text">
          </div>
          <div class="col-md-2">
            <label>Field of Study</label>
          </div>
          <div class="col-md-3">
            <ng-select [virtualScroll]="true" [items]="specialty" bindLabel="name" placeholder="Residency School specialty" bindValue="id"
              formControlName="530">
            </ng-select>
          </div>
        </div>




        <div class="row">
          <label style="padding-left:25px;"><b>Fellowship</b></label>
        </div>


        <div class="row">
          <div class="col-md-1">
            <label>Institution:</label>
          </div>
          <div class="col-md-3">
            <input class="form-control  " id="542" name="542" formControlName="542" type="text"
              placeholder="fellowship School name">
          </div>
          <div class="form-group col-md-5">
            <label>APA-approved, regionally accredited graduate prog:
            </label>
          </div>
          <div class="col-md-3">
            <label class="checkbox-inline">
              <input type="radio" id="cmd_1365" name="cmd_1365" formControlName="cmd_1365" value="1">Yes</label>
            <label class="checkbox-inline">
              <input type="radio" id="cmd_1365_1" name="cmd_1365" formControlName="cmd_1365" value="0">No</label>
          </div>
        </div>


        <div class="row">
          <div class="col-md-5  inline-form">
            <label>City/State:</label>
            <input class="form-control  " id="546" name="546" formControlName="546" type="text"
              placeholder="Fellowship School city">
            <ng-select [virtualScroll]="true" [items]="stateDropDownDate" bindLabel="name" placeholder="Fellowship school State" bindValue="id"
              formControlName="548">
            </ng-select>
          </div>
          <div class="col-md-1">
            <label>Start Date:</label>
          </div>
          <div class="col-md-2">
            <div class="input-group">
              <input class="form-control" formControlName="550" (blur)="clearInputIfInvalid('550')" name="dp" placeholder="Fellowship school Start"
                ngbDatepicker #d20="ngbDatepicker" placement="bottom">
              <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d20.toggle()" type="button"><i
                    class="fas fa-calendar-alt"></i></button>
              </div>
              <div  *ngIf="cherokeeHealthForm.get('550').invalid ">
                <small class="text-danger" *ngIf="cherokeeHealthForm.get('550').errors?.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY)
                </small>
                <small class="text-danger" *ngIf="cherokeeHealthForm.get('550').errors?.invalidMonth">
                Month is out of range
                </small>
                <small class="text-danger" *ngIf="cherokeeHealthForm.get('550').errors?.invalidDateyear">
                  year is out of range(1900-2099)                      </small>
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('550').errors?.invalidDateRange">
                    date is out of range
                    </small>
                </div>
            </div>
          </div>
          <div class="col-md-1">
            <label>End Date:</label>
          </div>
          <div class="col-md-2">
            <div class="input-group">
              <input class="form-control" formControlName="551" (blur)="clearInputIfInvalid('551')" name="dp" placeholder="Fellowship school End"
                ngbDatepicker #d21="ngbDatepicker" placement="bottom">
              <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d21.toggle()" type="button"><i
                    class="fas fa-calendar-alt"></i></button>
              </div>
              <div  *ngIf="cherokeeHealthForm.get('551').invalid ">
                <small class="text-danger" *ngIf="cherokeeHealthForm.get('551').errors?.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY)
                </small>
                <small class="text-danger" *ngIf="cherokeeHealthForm.get('551').errors?.invalidMonth">
                Month is out of range
                </small>
                <small class="text-danger" *ngIf="cherokeeHealthForm.get('551').errors?.invalidDateyear">
                  year is out of range(1900-2099)                      </small>
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('551').errors?.invalidDateRange">
                    date is out of range
                    </small>
                </div>
            </div>
          </div>
          <div class="col-md-1">
            <label>Degree Awarded:</label>
          </div>
          <div class="col-md-2">
            <input class="form-control " id="cmd_1518" formControlName="cmd_1518" name="cmd_1518" type="text"
              placeholder="">
          </div>

        </div>


        <div class="row">
          <div class="col-md-4">
            <label>Provider name when degree was awarded:</label>
          </div>
          <div class="col-md-3">
            <input class="form-control" id="cmd_1665" formControlName="cmd_1665" name="cmd_1665" type="text">
          </div>
          <div class="col-md-2">
            <label>Field of Study</label>
          </div>
          <div class="col-md-3">
            <ng-select [virtualScroll]="true" [items]="specialty" bindLabel="name" placeholder="fellowship school specialty" bindValue="id"
              formControlName="543">
            </ng-select>
          </div>
        </div>
        <div class="row">
          <label style="padding-left:25px;"><b>other</b></label>
        </div>


        <div class="row">
          <div class="col-md-1">
            <label>Institution:</label>
          </div>
          <div class="col-md-3">
            <input class="form-control" id="cmd_1516" formControlName="cmd_1516" name="cmd_1516" type="text"
              placeholder="">
          </div>
          <div class="form-group col-md-5">
            <label>APA-approved, regionally accredited graduate prog:
            </label>
          </div>
          <div class="col-md-3">
            <label class="checkbox-inline">
              <input type="radio" id="cmd_1366" name="cmd_1366" formControlName="cmd_1366" value="1">Yes</label>
            <label class="checkbox-inline">
              <input type="radio" id="cmd_1366_1" name="cmd_1366" formControlName="cmd_1366" value="0">No</label>
          </div>
        </div>
        <div class="row">
          <div class="col-md-5 inline-form">
            <label>City/State:</label>
            <input class="form-control  " id="cmd_1517" name="cmd_1517" formControlName="cmd_1517" type="text"
              placeholder="">
            <input class="form-control  " id="cmd_1520" name="cmd_1520" formControlName="cmd_1520" type="text"
              placeholder="">
          </div>
          <div class="col-md-1">
            <label>Start Date:</label>
          </div>
          <div class="col-md-2">
            <div class="input-group">
              <input class="form-control" formControlName="cmd_1534" (blur)="clearInputIfInvalid('cmd_1534')" name="dp" placeholder="MM/DD/YYYY" ngbDatepicker
                #d18="ngbDatepicker" placement="bottom">
              <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d18.toggle()" type="button"><i
                    class="fas fa-calendar-alt"></i></button>
              </div>
              <div  *ngIf="cherokeeHealthForm.get('cmd_1534').invalid ">
                <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1534').errors?.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY)
                </small>
                <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1534').errors?.invalidMonth">
                Month is out of range
                </small>
                <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1534').errors?.invalidDateyear">
                  year is out of range(1900-2099)                      </small>
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1534').errors?.invalidDateRange">
                    date is out of range
                    </small>
                </div>
            </div>
          </div>
          <div class="col-md-1">
            <label>End Date:</label>
          </div>
          <div class="col-md-2">
            <div class="input-group">
              <input class="form-control" formControlName="cmd_1535" (blur)="clearInputIfInvalid('cmd_1535')" name="dp" placeholder="MM/DD/YYYY" ngbDatepicker
                #d19="ngbDatepicker" placement="bottom">
              <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d19.toggle()" type="button"><i
                    class="fas fa-calendar-alt"></i></button>
              </div>
              <div  *ngIf="cherokeeHealthForm.get('cmd_1535').invalid ">
                <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1535').errors?.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY)
                </small>
                <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1535').errors?.invalidMonth">
                Month is out of range
                </small>
                <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1535').errors?.invalidDateyear">
                  year is out of range(1900-2099)                      </small>
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1535').errors?.invalidDateRange">
                    date is out of range
                    </small>
                </div>
            </div>
          </div>
          <div class="col-md-1">
            <label>Degree Awarded:</label>
          </div>
          <div class="col-md-2">
            <input class="form-control " id="cmd_1536" name="cmd_1536" formControlName="cmd_1536" type="text"
              placeholder="">
          </div>

        </div>


        <div class="row">
          <div class="col-md-4">
            <label>Provider name when degree was awarded:</label>
          </div>
          <div class="col-md-3">
            <input class="form-control  " id="cmd_1537" name="cmd_1537" formControlName="cmd_1537" type="text"
              placeholder="">
          </div>
          <div class="col-md-2">
            <label>Field of Study</label>
          </div>
          <div class="col-md-3">
            <input class="form-control  " id="cmd_1538" name="cmd_1538" formControlName="cmd_1538" type="text"
              placeholder="">
          </div>
        </div>


        <hr
          style="border: 17px solid #818181; margin-left: -87px;margin-right: -87px; margin-top: 50px; margin-bottom: 50px;">

        <div class="centered" style="text-align:center;">
          <h2>Cherokee Health Systems</h2>

        </div>



        <div class="centered" style="text-align:center;">
          <h2>Credentialing/Recredentialing Application</h2>
        </div>

        <br>

        <div class="row" style="padding-left: 20px;">
          <div class="form-group col-md-4">
            <label style="font-weight: normal;font-size: 14px">NHR/Diplomate Status
            </label>
          </div>
        </div>


        <div class="row">
          <div class="form-group col-md-8">
            <label style="font-weight: normal;font-size: 14px">Member National Register of Health Service Providers
              (NHR)
            </label>
          </div>
          <div class="col-md-3">
            <label class="checkbox-inline">
              <input type="radio" id="cmd_1367" name="cmd_1367" formControlName="cmd_1367" value="1">Yes</label>
            <label class="checkbox-inline">
              <input type="radio" id="cmd_1367_1" name="cmd_1367" formControlName="cmd_1367" value="0">No</label>
          </div>
        </div>


        <div class="row">
          <div class="form-group col-md-8">
            <label style="font-weight: normal;font-size: 14px">Diplomate American Board of Professional Neuropsychology
              (ABPN
            </label>
          </div>
          <div class="col-md-3">
            <label class="checkbox-inline">
              <input type="radio" id="cmd_1368" name="cmd_1368" formControlName="cmd_1368" value="1">Yes</label>
            <label class="checkbox-inline">
              <input type="radio" id="cmd_1368_1" name="cmd_1368" formControlName="cmd_1368" value="0">No</label>
          </div>
        </div>



        <div class="row">
          <div class="form-group col-md-8">
            <label style="font-weight: normal;font-size: 14px">Diplomate American Board of Professional Psychology
              (ABPP)
            </label>
          </div>
          <div class="col-md-3">
            <label class="checkbox-inline">
              <input type="radio" id="cmd_1369" name="cmd_1369" formControlName="cmd_1369" value="1">Yes</label>
            <label class="checkbox-inline">
              <input type="radio" id="cmd_1369_1" name="cmd_1369" formControlName="cmd_1369" value="0">No</label>
          </div>
        </div>

        <br>

        <div class="row" style="padding-left: 20px;">
          <div class="form-group col-md-4">
            <label style="font-weight: normal;font-size: 14px">Certification
            </label>
          </div>
        </div>


        <div class="row" style="padding-left: 20px;">
          <div class="form-group col-md-3">
            <label style="font-weight: normal;font-size: 14px">Certification Type:
            </label>
          </div>
          <div class="col-md-3">
            <input class="form-control " id="cmd_1539" formControlName="cmd_1539" name="cmd_1539" type="text"
              placeholder=" ">
          </div>
          <div class="form-group col-md-3">
            <label style="font-weight: normal;font-size: 14px"> Date of first certification:
            </label>
          </div>
          <div class="col-md-3">
            <div class="input-group">
              <input class="form-control" formControlName="cmd_1540" (blur)="clearInputIfInvalid('cmd_1540')" name="dp" placeholder="MM/DD/YYYY" ngbDatepicker
                #d22="ngbDatepicker" placement="bottom">
              <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d22.toggle()" type="button"><i
                    class="fas fa-calendar-alt"></i></button>
                  
              </div>
              
             
            </div>
          </div>

        </div>
        <div class="row">
          <div class="col-md-3"></div>
          <div class="col-md-3"></div>
          <div class="col-md-3"></div>
          <div class="col-md-3">
            <div  *ngIf="cherokeeHealthForm.get('cmd_1540').invalid ">
              <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1540').errors?.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY)
              </small>
              <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1540').errors?.invalidMonth">
              Month is out of range
              </small>
              <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1540').errors?.invalidDateyear">
                year is out of range(1900-2099)                      </small>
                <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1540').errors?.invalidDateRange">
                  date is out of range
                  </small>
              </div>
          </div>
        </div>


        <div class="row" style="padding-left: 20px;">
          <div class="form-group col-md-3">
            <label style="font-weight: normal;font-size: 14px">Issued by:
            </label>
          </div>
          <div class="col-md-3">
            <input class="form-control " id="cmd_1541" name="cmd_1541" formControlName="cmd_1541" type="text"
              placeholder=" ">
          </div>
          <div class="form-group col-md-3">
            <label style="font-weight: normal;font-size: 14px">Date of last certification:
            </label>
          </div>
          <div class="col-md-3">
            <div class="input-group">
              <input class="form-control" formControlName="cmd_1542" (blur)="clearInputIfInvalid('cmd_1542')" name="dp" placeholder="MM/DD/YYYY" ngbDatepicker
                #d23="ngbDatepicker" placement="bottom">
              <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d23.toggle()" type="button"><i
                    class="fas fa-calendar-alt"></i></button>
              </div>
              
            </div>
          </div>

        </div>
        <div class="row">
          <div class="col-md-3"></div>
          <div class="col-md-3"></div>
          <div class="col-md-3"></div>
          <div class="col-md-3">
            <div  *ngIf="cherokeeHealthForm.get('cmd_1542').invalid ">
              <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1542').errors?.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY)
              </small>
              <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1542').errors?.invalidMonth">
              Month is out of range
              </small>
              <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1542').errors?.invalidDateyear">
                year is out of range(1900-2099)                      </small>
                <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1542').errors?.invalidDateRange">
                  date is out of range
                  </small>
              </div>
          </div>
        </div>


        <div class="row" style="padding-left:20px">
          <div class="form-group col-md-7">
            <label style="font-weight: normal;font-size: 14px">Nurses: CNS Certified?
            </label>
          </div>
          <div class="col-md-3">
            <label class="checkbox-inline">
              <input type="radio" id="cmd_1370" name="cmd_1370" formControlName="cmd_1370" value="1">Yes</label>
            <label class="checkbox-inline">
              <input type="radio" id="cmd_1370_1" name="cmd_1370" formControlName="cmd_1370" value="0">No</label>
          </div>
        </div>


        <div class="row" style="padding-left: 20px;">
          <div class="form-group col-md-2">
            <label style="font-weight: normal;font-size: 14px">No. of years:
            </label>
          </div>
          <div class="col-md-2">
            <input class="form-control " id="cmd_1543" formControlName="cmd_1543" name="cmd_1543" type="text"
              placeholder=" ">
          </div>
          <div class="form-group col-md-2">
            <label style="font-weight: normal;font-size: 14px">Exp. Date:
            </label>
          </div>
          <div class="col-md-2">
            <div class="input-group">
              <input class="form-control" formControlName="cmd_1544" (blur)="clearInputIfInvalid('cmd_1544')" name="dp" placeholder="MM/DD/YYYY" ngbDatepicker
                #d24="ngbDatepicker" placement="bottom">
              <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d24.toggle()" type="button"><i
                    class="fas fa-calendar-alt"></i></button>
              </div>
              <div  *ngIf="cherokeeHealthForm.get('cmd_1544').invalid ">
                <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1544').errors?.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY)
                </small>
                <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1544').errors?.invalidMonth">
                Month is out of range
                </small>
                <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1544').errors?.invalidDateyear">
                  year is out of range(1900-2099)                      </small>
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1544').errors?.invalidDateRange">
                    date is out of range
                    </small>
                </div>
            </div>
          </div>
          <div class="form-group col-md-2">
            <label style="font-weight: normal;font-size: 14px">Specialty:
            </label>
          </div>
          <div class="col-md-2">
            <input class="form-control " id="cmd_1545" formControlName="cmd_1545" name="cmd_1545" type="text"
              placeholder=" ">
          </div>

        </div>


        <div class="row" style="padding-left:20px">
          <div class="form-group col-md-7">
            <label style="font-weight: normal;font-size: 14px">Board Certified
            </label>
          </div>
          <div class="col-md-3">
            <label class="checkbox-inline">
              <input type="radio" id="cmd_1371" name="cmd_1371" formControlName="cmd_1371" value="1">Yes</label>
            <label class="checkbox-inline">
              <input type="radio" id="cmd_1371_1" name="cmd_1371" formControlName="cmd_1371" value="0">No</label>
          </div>
        </div>


        <div class="row" style="padding-left:20px">
          <div class="form-group col-md-1">
            <label style="font-weight: normal;font-size: 14px">Specialty:
            </label>
          </div>
          <div class="col-md-3">
            <ng-select [virtualScroll]="true" [items]="specialty" bindLabel="name" placeholder="Primary Board Specialty" bindValue="id"
              formControlName="556">
            </ng-select>
          </div>
          <div class="form-group col-md-1">
            <label style="font-weight: normal;font-size: 14px">Issue Date:
            </label>
          </div>
          <div class="col-md-3">
            <div class="input-group">
              <input class="form-control" formControlName="557" (blur)="clearInputIfInvalid('557')" name="dp" placeholder="Primary Board issue"
                ngbDatepicker #d25="ngbDatepicker" placement="bottom">
              <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d25.toggle()" type="button"><i
                    class="fas fa-calendar-alt"></i></button>
              </div>
         
            </div>
          </div>
          <div class="form-group col-md-1">
            <label style="font-weight: normal;font-size: 14px">Exp. Date
            </label>
          </div>
          <div class="col-md-3">
            <div class="input-group">
              <input class="form-control" formControlName="560" (blur)="clearInputIfInvalid('560')" name="dp" placeholder="Primary Board exp" ngbDatepicker
                #d26="ngbDatepicker" placement="bottom">
              <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d26.toggle()" type="button"><i
                    class="fas fa-calendar-alt"></i></button>
              </div>
             
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3"></div>
          <div class="col-md-2"></div>
          <div class="col-md-4">
            <div  *ngIf="cherokeeHealthForm.get('557').invalid ">
              <small class="text-danger" *ngIf="cherokeeHealthForm.get('557').errors?.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY)
              </small>
              <small class="text-danger" *ngIf="cherokeeHealthForm.get('557').errors?.invalidMonth">
              Month is out of range
              </small>
              <small class="text-danger" *ngIf="cherokeeHealthForm.get('557').errors?.invalidDateyear">
                year is out of range(1900-2099)                      </small>
                <small class="text-danger" *ngIf="cherokeeHealthForm.get('557').errors?.invalidDateRange">
                  date is out of range
                  </small>
              </div>
          </div>
          <div class="col-md-3">
            <div  *ngIf="cherokeeHealthForm.get('560').invalid ">
              <small class="text-danger" *ngIf="cherokeeHealthForm.get('560').errors?.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY)
              </small>
              <small class="text-danger" *ngIf="cherokeeHealthForm.get('560').errors?.invalidMonth">
              Month is out of range
              </small>
              <small class="text-danger" *ngIf="cherokeeHealthForm.get('560').errors?.invalidDateyear">
                year is out of range(1900-2099)                      </small>
                <small class="text-danger" *ngIf="cherokeeHealthForm.get('560').errors?.invalidDateRange">
                  date is out of range
                  </small>
              </div>
          </div>
        </div>


        <div class="row" style="padding-left:20px">
          <div class="form-group col-md-1">
            <label style="font-weight: normal;font-size: 14px">Specialty:
            </label>
          </div>
          <div class="col-md-3">

            <ng-select [virtualScroll]="true" [items]="specialtySecondary" bindLabel="name" placeholder="Secondary Specialty" bindValue="id"
              formControlName="563">
            </ng-select>
          </div>
          <div class="form-group col-md-1">
            <label style="font-weight: normal;font-size: 14px">Issue Date:
            </label>
          </div>
          <div class="col-md-3">
            <div class="input-group">
              <input class="form-control" formControlName="564" (blur)="clearInputIfInvalid('564')" name="dp" placeholder="secondary Board issue"
                ngbDatepicker #d27="ngbDatepicker" placement="bottom">
              <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d27.toggle()" type="button"><i
                    class="fas fa-calendar-alt"></i></button>
              </div>
          
            </div>
          </div>
          <div class="form-group col-md-1">
            <label style="font-weight: normal;font-size: 14px">Exp. Date
            </label>
          </div>
          <div class="col-md-3">
            <div class="input-group">
              <input class="form-control" formControlName="567" (blur)="clearInputIfInvalid('567')" name="dp" placeholder="secondary Board exp"
                ngbDatepicker #d28="ngbDatepicker" placement="bottom">
              <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d28.toggle()" type="button"><i
                    class="fas fa-calendar-alt"></i></button>
              </div>
              
            </div>
          </div>

        </div>
        <div class="row">
          <div class="col-md-3"></div>
          <div class="col-md-2"></div>
          <div class="col-md-4">
            <div  *ngIf="cherokeeHealthForm.get('564').invalid ">
              <small class="text-danger" *ngIf="cherokeeHealthForm.get('564').errors?.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY)
              </small>
              <small class="text-danger" *ngIf="cherokeeHealthForm.get('564').errors?.invalidMonth">
              Month is out of range
              </small>
              <small class="text-danger" *ngIf="cherokeeHealthForm.get('564').errors?.invalidDateyear">
                year is out of range(1900-2099)                      </small>
                <small class="text-danger" *ngIf="cherokeeHealthForm.get('564').errors?.invalidDateRange">
                  date is out of range
                  </small>
              </div>
          </div>
          <div class="col-md-3">
            <div  *ngIf="cherokeeHealthForm.get('567').invalid ">
              <small class="text-danger" *ngIf="cherokeeHealthForm.get('567').errors?.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY)
              </small>
              <small class="text-danger" *ngIf="cherokeeHealthForm.get('567').errors?.invalidMonth">
              Month is out of range
              </small>
              <small class="text-danger" *ngIf="cherokeeHealthForm.get('567').errors?.invalidDateyear">
                year is out of range(1900-2099)                      </small>
                <small class="text-danger" *ngIf="cherokeeHealthForm.get('567').errors?.invalidDateRange">
                  date is out of range
                  </small>
              </div>
          </div>
        </div>


        <div class="row" style="padding-left:20px">
          <div class="form-group col-md-7">
            <label style="font-weight: normal;font-size: 14px">Board Eligible
            </label>
          </div>
          <div class="col-md-3">
            <label class="checkbox-inline">
              <input type="radio" id="cmd_1372" name="cmd_1372" formControlName="cmd_1372" value="1">Yes</label>
            <label class="checkbox-inline">
              <input type="radio" id="cmd_1372_1" name="cmd_1372" formControlName="cmd_1372" value="0">No</label>
          </div>
        </div>


        <div class="row" style="padding-left:20px">
          <div class="form-group col-md-2">
            <label style="font-weight: normal;font-size: 14px">Specialty:
            </label>
          </div>
          <div class="col-md-3">
            <input class="form-control " id="cmd_1546" name="cmd_1546" formControlName="cmd_1546" type="text"
              placeholder="">
          </div>
          <div class="form-group col-md-2">
            <label style="font-weight: normal;font-size: 14px">Date:
            </label>
          </div>
          <div class="col-md-3">
            <div class="input-group">
              <input class="form-control" formControlName="cmd_1547" (blur)="clearInputIfInvalid('cmd_1547')" name="dp" placeholder="MM/DD/YYYY" ngbDatepicker
                #d29="ngbDatepicker" placement="bottom">
              <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d29.toggle()" type="button"><i
                    class="fas fa-calendar-alt"></i></button>
              </div>
              
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3"></div>
          <div class="col-md-4"></div>
         
          <div class="col-md-3">
            <div  *ngIf="cherokeeHealthForm.get('cmd_1547').invalid ">
              <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1547').errors?.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY)
              </small>
              <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1547').errors?.invalidMonth">
              Month is out of range
              </small>
              <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1547').errors?.invalidDateyear">
                year is out of range(1900-2099)                      </small>
                <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1547').errors?.invalidDateRange">
                  date is out of range
                  </small>
              </div>
          </div>
        </div>

        <div class="row">
          <label style="padding-left:20px;font-style: italic;"><b>4. Current Primary Clinical Affiliations (Please list any
            additional facilities on a separate sheet of paper)</b></label>

        </div>


        <div class="row" style="padding-left:20px">
          <div class="form-group col-md-1">
            <label style="font-weight: normal;font-size: 14px">Facility:
            </label>
          </div>
          <div class="col-md-4">
            <input class="form-control " id="418" name="418" formControlName="418" type="text"
              placeholder="Hosp1 Affiliation name">
          </div>
          <div class="col-md-3">
            <input class="form-control" id="419" name="419" formControlName="419" type="text"
              placeholder="Hosp1 Address 1 ">
          </div>
          <div class="col-md-3">
            <input class="form-control" id="420" name="420" formControlName="420" type="text"
              placeholder="Hosp1 Address 2">
          </div>
        </div>


        <div class="row" style="padding-left:20px">
          <div class="form-group col-md-1">
            <label style="font-weight: normal;font-size: 14px">City:
            </label>
          </div>
          <div class="col-md-2">
            <input class="form-control " id="421" name="421" formControlName="421" type="text"
              placeholder="Hosp1 Affiliation city">
          </div>
          <div class="form-group col-md-1">
            <label style="font-weight: normal;font-size: 14px">State:
            </label>
          </div>
          <div class="form-group form-inline no-margin col-md-2" style="padding: 2px">
            <ng-select [virtualScroll]="true" [items]="stateDropDownDate" bindLabel="name" placeholder="Hosp 1 Affiliations State"
              bindValue="id" formControlName="423">
            </ng-select>
          </div>
          <div class="form-group col-md-1">
            <label style="font-weight: normal;font-size: 14px">Zip:
            </label>
          </div>
          <div class="col-md-2">
            <input class="form-control " id="424" name="424" formControlName="424" type="text"
              placeholder="Hosp1 Affiliation Zip">
          </div>
          <div class="form-group col-md-1">
            <label style="font-weight: normal;font-size: 14px">County:
            </label>
          </div>
          <div class="col-md-2" style="padding: 2px">
            <ng-select [virtualScroll]="true" [items]="country" bindLabel="name" placeholder="Hosp 1 Affiliations Country" bindValue="id"
              formControlName="422">
            </ng-select>

          </div>
        </div>


        <div class="row" style="padding-left:20px">
          <div class="form-group col-md-1">
            <label style="font-weight: normal;font-size: 14px">From:
            </label>
          </div>
          <div class="col-md-3">
            <div class="input-group">
              <input class="form-control" formControlName="426" (blur)="clearInputIfInvalid('426')" name="dp" placeholder="Hosp1 Affiliation start date"
                ngbDatepicker #d30="ngbDatepicker" placement="bottom">
              <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d30.toggle()" type="button"><i
                    class="fas fa-calendar-alt"></i></button>
              </div>
         
            </div>
          </div>
          <div class="form-group col-md-2">
            <label style="font-weight: normal;font-size: 14px">Credentialed Services:
            </label>
          </div>
          <div class="col-md-2">
            <ng-select [virtualScroll]="true" [items]="specialty" bindLabel="name" placeholder="Hosp1 Affiliation specialty" bindValue="id"
              formControlName="3083">
            </ng-select>
          </div>
          <div class="col-md-2">
            <ng-select [virtualScroll]="true" [items]="staffStatus" bindLabel="name" placeholder="Hosp1 HoStaff status" bindValue="id"
              formControlName="894">
            </ng-select>
          </div>

        </div>
        <div class="row">
          <div class="col-md-1"></div>
         
         
          <div class="col-md-3">
            <div  *ngIf="cherokeeHealthForm.get('426').invalid ">
              <small class="text-danger" *ngIf="cherokeeHealthForm.get('426').errors?.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY)
              </small>
              <small class="text-danger" *ngIf="cherokeeHealthForm.get('426').errors?.invalidMonth">
              Month is out of range
              </small>
              <small class="text-danger" *ngIf="cherokeeHealthForm.get('426').errors?.invalidDateyear">
                year is out of range(1900-2099)                      </small>
                <small class="text-danger" *ngIf="cherokeeHealthForm.get('426').errors?.invalidDateRange">
                  date is out of range
                  </small>
              </div>
          </div>
        </div>
        <div class="row" style="padding-left:20px">
          <div class="col-md-12 form-check">
            <label class="checkbox-inline" style="padding-top:0px">
              <input type="checkbox" id="cmd_1373" name="cmd_1373" value="1" formControlName="cmd_1373"> Admitting
            </label>
            <label class="checkbox-inline">
              <input type="checkbox" id="cmd_1374" name="cmd_1374" value="1" formControlName="cmd_1374"> Attending
            </label>
            <label class="checkbox-inline">
              <input type="checkbox" id="cmd_1375" name="cmd_1375" value="1" formControlName="cmd_1375"> Consulting
            </label>
            <label class="checkbox-inline">
              <input type="checkbox" id="cmd_1376" name="cmd_1376" value="1" formControlName="cmd_1376"> Courtesy
            </label>
            <label class="checkbox-inline">
              <input type="checkbox" id="cmd_1377" name="cmd_1377" value="1" formControlName="cmd_1377"> Emergency
            </label>
            <label class="checkbox-inline">
              <input type="checkbox" id="cmd_1378" name="cmd_1378" value="1" formControlName="cmd_1378"> Primary
            </label>
            <label class="checkbox-inline">
              <input type="checkbox" id="cmd_1379" name="cmd_1379" value="1" formControlName="cmd_1379"> Referral
            </label>
            <label class="checkbox-inline">
              <input type="checkbox" id="cmd_1380" name="cmd_1380" value="1" formControlName="cmd_1380"> Staff </label>

          </div>
        </div>


        <div class="row" style="padding-left:20px">
          <div class="form-group col-md-1">
            <label style="font-weight: normal;font-size: 14px">Facility:
            </label>
          </div>
          <div class="col-md-4">
            <input class="form-control " id="427" name="427" formControlName="427" type="text"
              placeholder="Hosp2 Affiliation name">
          </div>
          <div class="col-md-3">
            <input class="form-control" id="428" name="428" formControlName="428" type="text"
              placeholder="Hosp2 Address 1 ">
          </div>
          <div class="col-md-3">
            <input class="form-control" id="429" name="429" formControlName="429" type="text"
              placeholder="Hosp2 Address 2">
          </div>
        </div>


        <div class="row" style="padding-left:20px">
          <div class="form-group col-md-1">
            <label style="font-weight: normal;font-size: 14px">City:
            </label>
          </div>
          <div class="col-md-2">
            <input class="form-control " id="430" formControlName="430" name="430" type="text"
              placeholder="Hosp2 Affiliation city">
          </div>
          <div class="form-group col-md-1">
            <label style="font-weight: normal;font-size: 14px">State:
            </label>
          </div>
          <div class="form-group form-inline no-margin col-md-2" style="padding: 2px">
            <ng-select [virtualScroll]="true" [items]="stateDropDownDate" bindLabel="name" placeholder="Hosp2 Affiliation state" bindValue="id"
              formControlName="432">
            </ng-select>
          </div>
          <div class="form-group col-md-1">
            <label style="font-weight: normal;font-size: 14px">Zip:
            </label>
          </div>
          <div class="col-md-2">
            <input class="form-control " id="433" formControlName="433" name="433" type="text"
              placeholder="Hosp1 Affiliation Zip">
          </div>
          <div class="form-group col-md-1">
            <label style="font-weight: normal;font-size: 14px">County:
            </label>
          </div>
          <div class="col-md-2" style="padding: 2px">
            <ng-select [virtualScroll]="true" [items]="country" bindLabel="name" placeholder="Hosp2 Affiliation County" bindValue="id"
              formControlName="431">
            </ng-select>
          </div>
        </div>

        <div class="row" style="padding-left:20px">
          <div class="form-group col-md-1">
            <label style="font-weight: normal;font-size: 14px">From:
            </label>
          </div>
          <div class="col-md-3">
            <div class="input-group">
              <input class="form-control" formControlName="435" (blur)="clearInputIfInvalid('435')" name="dp" placeholder="Hosp2 Affiliation start date"
                ngbDatepicker #d31="ngbDatepicker" placement="bottom">
              <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d31.toggle()" type="button"><i
                    class="fas fa-calendar-alt"></i></button>
              </div>
             
            </div>
          </div>
          <div class="form-group col-md-2">
            <label style="font-weight: normal;font-size: 14px">Credentialed Services:
            </label>
          </div>
          <div class="col-md-2">
            <ng-select [virtualScroll]="true" [items]="specialty" bindLabel="name" placeholder="Hosp2 Affiliation specialty" bindValue="id"
              formControlName="3085">
            </ng-select>
          </div>
          <div class="col-md-2">
            <ng-select [virtualScroll]="true" [items]="staffStatus" bindLabel="name" placeholder="Hosp2 Staff status" bindValue="id"
              formControlName="895">
            </ng-select>
          </div>


        </div>
        <div class="row">
          <div class="col-md-1"></div>
         
         
          <div class="col-md-3">
            <div  *ngIf="cherokeeHealthForm.get('435').invalid ">
              <small class="text-danger" *ngIf="cherokeeHealthForm.get('435').errors?.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY)
              </small>
              <small class="text-danger" *ngIf="cherokeeHealthForm.get('435').errors?.invalidMonth">
              Month is out of range
              </small>
              <small class="text-danger" *ngIf="cherokeeHealthForm.get('435').errors?.invalidDateyear">
                year is out of range(1900-2099)                      </small>
                <small class="text-danger" *ngIf="cherokeeHealthForm.get('435').errors?.invalidDateRange">
                  date is out of range
                  </small>
              </div>
          </div>
        </div>

        <div class="row" style="padding-left:20px">
          <div class="col-md-12 form-check">
            <label class="checkbox-inline" style="padding-top:0px">
              <input type="checkbox" id="cmd_1381" name="cmd_1381" value="1" formControlName="cmd_1381"> Admitting
            </label>
            <label class="checkbox-inline">
              <input type="checkbox" id="cmd_1382" name="cmd_1382" value="1" formControlName="cmd_1382"> Attending
            </label>
            <label class="checkbox-inline">
              <input type="checkbox" id="cmd_1383" name="cmd_1383" value="1" formControlName="cmd_1383"> Consulting
            </label>
            <label class="checkbox-inline">
              <input type="checkbox" id="cmd_1384" name="cmd_1384" value="1" formControlName="cmd_1384"> Courtesy
            </label>
            <label class="checkbox-inline">
              <input type="checkbox" id="cmd_1385" name="cmd_1385" value="1" formControlName="cmd_1385"> Emergency
            </label>
            <label class="checkbox-inline">
              <input type="checkbox" id="cmd_1386" name="cmd_1386" value="1" formControlName="cmd_1386"> Primary
            </label>
            <label class="checkbox-inline">
              <input type="checkbox" id="cmd_1387" name="cmd_1387" value="1" formControlName="cmd_1387"> Referral
            </label>
            <label class="checkbox-inline">
              <input type="checkbox" id="cmd_1388" name="cmd_1388" value="1" formControlName="cmd_1388"> Staff </label>

          </div>
        </div>


        <div class="row">
          <label style="padding-left:20px;font-style: italic;"><b>5. Clinical Services</b></label>

        </div>


        <div class="row" style="padding-left:20px">
          <div class="col-md-6">
            <label style="font-weight: normal;font-size: 14px"><b>Evaluations and Referral Services</b>
            </label>
          </div>
          <div class="col-md-6">
            <label style="font-weight: normal;font-size: 14px"><b>Specialized &amp; Traditional Practice Services</b>
            </label>
          </div>

        </div>


        <div class="row" style="padding-left:30px">
          <div class="col-md-6">
            <label class="checkbox-inline" style="padding-top:0px">
              <input type="checkbox" id="cmd_1389" name="cmd_1389" value="1" formControlName="cmd_1389"> Mental Health
            </label>
          </div>
          <div class="col-md-6">
            <label class="checkbox-inline" style="padding-top:0px">
              <input type="checkbox" id="cmd_1390" name="cmd_1390" value="1" formControlName="cmd_1390"> Mental Health
            </label>
          </div>
        </div>


        <div class="row" style="padding-left:30px">
          <div class="col-md-6">
            <label class="checkbox-inline" style="padding-top:0px">
              <input type="checkbox" id="cmd_1391" name="cmd_1391" value="1" formControlName="cmd_1391"> Substance Abuse
            </label>
          </div>
          <div class="col-md-6">
            <label class="checkbox-inline" style="padding-top:0px">
              <input type="checkbox" id="cmd_1392" name="cmd_1392" value="1" formControlName="cmd_1392"> Outpatient
              Traditional Practice </label>
          </div>
        </div>


        <div class="row" style="padding-left:488px">
          <div class="col-md-7">
            <label class="checkbox-inline" style="padding-top:0px">
              <input type="checkbox" id="cmd_1393" name="cmd_1393" value="1" formControlName="cmd_1393"> Outpatient:
              Specialized Practice </label>
          </div>

        </div>


        <div class="row" style="padding-left:488px">
          <div class="col-md-6">
            <label class="checkbox-inline" style="padding-top:0px">
              <input type="checkbox" id="cmd_1394" name="cmd_1394" value="1" formControlName="cmd_1394"> Substance Abuse
            </label>
          </div>

        </div>


        <div class="row" style="padding-left:20px">
          <div class="col-md-6">
            <label style="font-weight: normal;font-size: 14px"><b>Brief Treatment Services </b>
            </label>
          </div>
        </div>


        <div class="row" style="padding-left:30px">
          <div class="col-md-6">
            <label class="checkbox-inline" style="padding-top:0px">
              <input type="checkbox" id="cmd_1395" name="cmd_1395" value="1" formControlName="cmd_1395"> Mental Health
            </label>
          </div>

        </div>


        <div class="row" style="padding-left:30px">
          <div class="col-md-6">
            <label class="checkbox-inline" style="padding-top:0px">
              <input type="checkbox" id="cmd_1396" name="cmd_1396" value="1" formControlName="cmd_1396"> Substance Abuse
            </label>
          </div>

        </div>


        <div class="row" style="padding-left:20px">
          <div class="col-md-6">
            <label style="font-weight: normal;font-size: 14px"><b>Crisis Evaluation/lntervention Services </b>
            </label>
          </div>
          <div class="col-md-6">
            <label style="font-weight: normal;font-size: 14px"><b>Employee Assistance Service</b>
            </label>
          </div>

        </div>


        <div class="row" style="padding-left:30px">
          <div class="col-md-6">
            <label class="checkbox-inline" style="padding-top:0px">
              <input type="checkbox" id="cmd_1397" name="cmd_1397" value="1" formControlName="cmd_1397"> Mental Health
            </label>
          </div>
          <div class="col-md-6">
            <label class="checkbox-inline" style="padding-top:0px">
              <input type="checkbox" id="cmd_1398" name="cmd_1398" value="1" formControlName="cmd_1398"> Mental Health
            </label>
          </div>
        </div>


        <div class="row" style="padding-left:30px">
          <div class="col-md-6">
            <label class="checkbox-inline" style="padding-top:0px">
              <input type="checkbox" id="cmd_1399" name="cmd_1399" value="1" formControlName="cmd_1399"> Substance Abuse
            </label>
          </div>
          <div class="col-md-6">
            <label class="checkbox-inline" style="padding-top:0px">
              <input type="checkbox" id="cmd_1400" name="cmd_1400" value="1" formControlName="cmd_1400"> Substance Abuse
            </label>
          </div>
        </div>


        <div class="row" style="padding-left:30px">
          <div class="form-group col-md-5">
            <label style="font-weight: normal;font-size: 14px">Do you provide critical incident/debriefing services?
            </label>
          </div>
          <div class="col-md-3">
            <label class="checkbox-inline">
              <input type="radio" id="cmd_1401" name="cmd_1401" value="1" formControlName="cmd_1401">Yes</label>
            <label class="checkbox-inline">
              <input type="radio" id="cmd_1401_1" name="cmd_1401" value="0" formControlName="cmd_1401">No</label>
          </div>
        </div>

        <hr
          style="border: 17px solid #818181; margin-left: -87px;margin-right: -87px; margin-top: 50px; margin-bottom: 50px;">

        <div class="centered" style="text-align:center;">
          <h2>Cherokee Health Systems</h2>
        </div>



        <div class="centered" style="text-align:center;">
          <h2>Credentialing/Recredentialing Application</h2>
        </div>

        <br><br>
        <div class="row">
          <label style="padding-left:20px;font-style:italic"><b>6. Clinical Services for Specific Areas of Specialty (Under
            Service check the disorders you currently treat. Under Training check those disorders for which you have had
            formal training and experience.)</b>
          </label>

        </div>


        <div class="row" style="padding-left:20px">
          <div class="col-md-1">
            <label style="font-weight: normal;font-size: 14px"><b>Service </b>
            </label>
          </div>
          <div class="col-md-5">
            <label style="font-weight: normal;font-size: 14px"><b>Training</b>
            </label>
          </div>
          <div class="col-md-1">
            <label style="font-weight: normal;font-size: 14px"><b>Service </b>
            </label>
          </div>
          <div class="col-md-5">
            <label style="font-weight: normal;font-size: 14px"><b>Training</b>
            </label>
          </div>

        </div>


        <div class="row" style="padding-left:30px">
          <div class="col-md-1">
            <label class="checkbox-inline" style="padding-top:0px">
              <input type="checkbox" id="cmd_1403" name="cmd_1403" value="1" formControlName="cmd_1403"></label>
          </div>
          <div class="col-md-1">
            <label class="checkbox-inline" style="padding-top:0px">
              <input type="checkbox" id="cmd_1404" name="cmd_1404" value="1" formControlName="cmd_1404"></label>
          </div>
          <div class="col-md-4">
            <label style="font-weight: normal;font-size: 14px">Anxiety Disorder
            </label>
          </div>
          <div class="col-md-1">
            <label class="checkbox-inline" style="padding-top:0px">
              <input type="checkbox" id="cmd_1405" name="cmd_1405" value="1" formControlName="cmd_1405"></label>
          </div>
          <div class="col-md-1">
            <label class="checkbox-inline">
              <input type="checkbox" id="cmd_1406" name="cmd_1406" value="1" formControlName="cmd_1406"></label>
          </div>
          <div class="col-md-3" style="padding-left:50px">
            <label style="font-weight: normal;font-size: 14px">Organic Brain Syndrome
            </label>
          </div>
        </div>


        <div class="row" style="padding-left:30px">
          <div class="col-md-1">
            <label class="checkbox-inline" style="padding-top:0px">
              <input type="checkbox" id="cmd_1407" name="cmd_1407" value="1" formControlName="cmd_1407"></label>
          </div>
          <div class="col-md-1">
            <label class="checkbox-inline" style="padding-top:0px">
              <input type="checkbox" id="cmd_1408" name="cmd_1408" value="1" formControlName="cmd_1408"></label>
          </div>
          <div class="col-md-4" >
            <label style="font-weight: normal;font-size: 14px">Mood Disorders
            </label>
          </div>
          <div class="col-md-1" >
            <label class="checkbox-inline" style="padding-top:0px">
              <input type="checkbox" id="cmd_1409" name="cmd_1409" value="1" formControlName="cmd_1409"></label>
          </div>
          <div class="col-md-1">
            <label class="checkbox-inline">
              <input type="checkbox" id="cmd_1410" name="cmd_1410" value="1" formControlName="cmd_1410"> </label>
          </div>
          <div class="col-md-3" style="padding-left:50px">
            <label style="font-weight: normal;font-size: 14px">Chronic Pain
            </label>
          </div>
        </div>


        <div class="row" style="padding-left:30px">
          <div class="col-md-1">
            <label class="checkbox-inline" style="padding-top:0px">
              <input type="checkbox" id="cmd_1411" name="cmd_1411" value="1" formControlName="cmd_1411"></label>
          </div>
          <div class="col-md-1">
            <label class="checkbox-inline" style="padding-top:0px">
              <input type="checkbox" id="cmd_1412" name="cmd_1412" value="1" formControlName="cmd_1412"> </label>
          </div>
          <div class="col-md-4">
            <label style="font-weight: normal;font-size: 14px">Psychotic Disorders
            </label>
          </div>
          <div class="col-md-1" >
            <label class="checkbox-inline" style="padding-top:0px">
              <input type="checkbox" id="cmd_1413" name="cmd_1413" value="1" formControlName="cmd_1413"></label>
          </div>
          <div class="col-md-1">
            <label class="checkbox-inline" >
              <input type="checkbox" id="cmd_1414" name="cmd_1414" value="1" formControlName="cmd_1414"> </label>
          </div>
          <div class="col-md-4" style="padding-left:50px">
            <label style="font-weight: normal;font-size: 14px">HIV Positive/AIDS patients
            </label>
          </div>
        </div>


        <div class="row" style="padding-left:30px">
          <div class="col-md-1">
            <label class="checkbox-inline" style="padding-top:0px">
              <input type="checkbox" id="cmd_1415" name="cmd_1415" value="1" formControlName="cmd_1415"></label>
          </div>
          <div class="col-md-1">
            <label class="checkbox-inline" style="padding-top:0px">
              <input type="checkbox" id="cmd_1416" name="cmd_1416" value="1" formControlName="cmd_1416"> </label>
          </div>
          <div class="col-md-4" >
            <label style="font-weight: normal;font-size: 14px">Personality Disorder
            </label>
          </div>
          <div class="col-md-1">
            <label class="checkbox-inline" style="padding-top:0px">
              <input type="checkbox" id="cmd_1417" name="cmd_1417" value="1" formControlName="cmd_1417"></label>
          </div>
          <div class="col-md-1">
            <label class="checkbox-inline" >
              <input type="checkbox" id="cmd_1418" name="cmd_1418" value="1" formControlName="cmd_1418"> </label>
          </div>
          <div class="col-md-4" style="padding-left:50px">
            <label style="font-weight: normal;font-size: 14px">Sexual Disorders
            </label>
          </div>

        </div>


        <div class="row" style="padding-left:30px">
          <div class="col-md-1">
            <label class="checkbox-inline" style="padding-top:0px">
              <input type="checkbox" id="cmd_1419" name="cmd_1419" value="1" formControlName="cmd_1419"></label>
          </div>
          <div class="col-md-1">
            <label class="checkbox-inline" style="padding-top:0px">
              <input type="checkbox" id="cmd_1420" name="cmd_1420" value="1" formControlName="cmd_1420"> </label>
          </div>
          <div class="col-md-4" >
            <label style="font-weight: normal;font-size: 14px">Psychosomatic/Somatoform
            </label>
          </div>
          <div class="col-md-1" >
            <label class="checkbox-inline" style="padding-top:0px">
              <input type="checkbox" id="cmd_1421" name="cmd_1421" value="1" formControlName="cmd_1421"></label>
          </div>
          <div class="col-md-1">
            <label class="checkbox-inline" >
              <input type="checkbox" id="cmd_1422" name="cmd_1422" value="1" formControlName="cmd_1422"> </label>
          </div>
          <div class="col-md-4" style="padding-left:50px">
            <label style="font-weight: normal;font-size: 14px">Women's Issues
            </label>
          </div>
        </div>


        <div class="row" style="padding-left:30px">
          <div class="col-md-1">
            <label class="checkbox-inline" style="padding-top:0px">
              <input type="checkbox" id="cmd_1423" name="cmd_1423" value="1" formControlName="cmd_1423"></label>
          </div>
          <div class="col-md-1">
            <label class="checkbox-inline" style="padding-top:0px">
              <input type="checkbox" id="cmd_1424" name="cmd_1424" value="1" formControlName="cmd_1424"> </label>
          </div>
          <div class="col-md-4" >
            <label style="font-weight: normal;font-size: 14px">Addictions
            </label>
          </div>
          <div class="col-md-1" >
            <label class="checkbox-inline" style="padding-top:0px">
              <input type="checkbox" id="cmd_1425" name="cmd_1425" value="1" formControlName="cmd_1425"></label>
          </div>
          <div class="col-md-1">
            <label class="checkbox-inline" >
              <input type="checkbox" id="cmd_1426" name="cmd_1426" value="1" formControlName="cmd_1426"></label>
          </div>
          <div class="col-md-4" style="padding-left:50px">
            <label style="font-weight: normal;font-size: 14px">Sexual Harassment
            </label>
          </div>
        </div>


        <div class="row" style="padding-left:30px">
          <div class="col-md-1">
            <label class="checkbox-inline" style="padding-top:0px">
              <input type="checkbox" id="cmd_1427" name="cmd_1427" value="1" formControlName="cmd_1427"></label>
          </div>
          <div class="col-md-1">
            <label class="checkbox-inline" style="padding-top:0px">
              <input type="checkbox" id="cmd_1428" name="cmd_1428" value="1" formControlName="cmd_1428"></label>
          </div>
          <div class="col-md-4" >
            <label style="font-weight: normal;font-size: 14px">Eating Disorders
            </label>
          </div>
          <div class="col-md-1" >
            <label class="checkbox-inline" style="padding-top:0px">
              <input type="checkbox" id="cmd_1429" name="cmd_1429" value="1" formControlName="cmd_1429"></label>
          </div>
          <div class="col-md-1">
            <label class="checkbox-inline" >
              <input type="checkbox" id="cmd_1430" name="cmd_1430" value="1" formControlName="cmd_1430"> </label>
          </div>
          <div class="col-md-4" style="padding-left:50px">
            <label style="font-weight: normal;font-size: 14px">Divorce
            </label>
          </div>
        </div>


        <div class="row" style="padding-left:30px">
          <div class="col-md-1">
            <label class="checkbox-inline" style="padding-top:0px">
              <input type="checkbox" id="cmd_1431" name="cmd_1431" value="1" formControlName="cmd_1431"></label>
          </div>
          <div class="col-md-1">
            <label class="checkbox-inline" style="padding-top:0px">
              <input type="checkbox" id="cmd_1432" name="cmd_1432" value="1" formControlName="cmd_1432"> </label>
          </div>
          <div class="col-md-4" >
            <label style="font-weight: normal;font-size: 14px">Terminally Ill Patients
            </label>
          </div>
          <div class="col-md-1" >
            <label class="checkbox-inline" style="padding-top:0px">
              <input type="checkbox" id="cmd_1433" name="cmd_1433" value="1" formControlName="cmd_1433"></label>
          </div>
          <div class="col-md-1">
            <label class="checkbox-inline">
              <input type="checkbox" id="cmd_1434" name="cmd_1434" value="1" formControlName="cmd_1434"> </label>
          </div>
          <div class="col-md-4" style="padding-left:50px">
            <label style="font-weight: normal;font-size: 14px">Post Traumatic Stress Disorder
            </label>
          </div>
        </div>


        <div class="row" style="padding-left:30px">
          <div class="col-md-1">
            <label class="checkbox-inline" style="padding-top:0px">
              <input type="checkbox" id="cmd_1435" name="cmd_1435" value="1" formControlName="cmd_1435"></label>
          </div>
          <div class="col-md-1">
            <label class="checkbox-inline" style="padding-top:0px">
              <input type="checkbox" id="cmd_1436" name="cmd_1436" value="1" formControlName="cmd_1436"> </label>
          </div>
          <div class="col-md-4" >
            <label style="font-weight: normal;font-size: 14px">Head Injury Patients
            </label>
          </div>
          <div class="col-md-1" >
            <label class="checkbox-inline" style="padding-top:0px">
              <input type="checkbox" id="cmd_1437" name="cmd_1437" value="1" formControlName="cmd_1437"></label>
          </div>
          <div class="col-md-1">
            <label class="checkbox-inline" >
              <input type="checkbox" id="cmd_1438" name="cmd_1438" value="1" formControlName="cmd_1438"> </label>
          </div>
          <div class="col-md-4" style="padding-left:50px">
            <label style="font-weight: normal;font-size: 14px">Neurological Assessment
            </label>
          </div>
        </div>


        <div class="row" style="padding-left:30px">
          <div class="col-md-1">
            <label class="checkbox-inline" style="padding-top:0px">
              <input type="checkbox" id="cmd_1439" name="cmd_1439" value="1" formControlName="cmd_1439"></label>
          </div>
          <div class="col-md-1">
            <label class="checkbox-inline" style="padding-top:0px">
              <input type="checkbox" id="cmd_1440" name="cmd_1440" value="1" formControlName="cmd_1440"> </label>
          </div>
          <div class="col-md-4" >
            <label style="font-weight: normal;font-size: 14px">Domestic Violence
            </label>
          </div>
          <div class="col-md-1" >
            <label class="checkbox-inline" style="padding-top:0px">
              <input type="checkbox" id="cmd_1441" name="cmd_1441" value="1" formControlName="cmd_1441"></label>
          </div>
          <div class="col-md-1">
            <label class="checkbox-inline" >
              <input type="checkbox" id="cmd_1442" name="cmd_1442" value="1" formControlName="cmd_1442"> </label>
          </div>
          <div class="col-md-4" style="padding-left:50px">
            <label style="font-weight: normal;font-size: 14px">Marriage &amp; Family
            </label>
          </div>

        </div>


        <div class="row" style="padding-left:30px">
          <div class="col-md-1">
            <label class="checkbox-inline" style="padding-top:0px">
              <input type="checkbox" id="cmd_1443" name="cmd_1443" value="1" formControlName="cmd_1443"></label>
          </div>
          <div class="col-md-1">
            <label class="checkbox-inline" style="padding-top:0px">
              <input type="checkbox" id="cmd_1444" name="cmd_1444" value="1" formControlName="cmd_1444"> </label>
          </div>
          <div class="col-md-3" style="padding-top: 10px">
            <label style="font-weight: normal;font-size: 14px">other
            </label>
          </div>
        </div>

        <br>
        <div class="row">
         <label style="padding-left:20px;font-style:italic"><b>7. Practice Information - Client population you serve (Check the age ranges for which you <u>offer</u> services and provide the percentage of your current practice represented by each.)<b>Total percentage must equal 100%.</b>
          </b></label>
        </div>

        <div class="row" style="padding-left:30px">
          <div class="col-md-1">
            <label style="font-weight: normal;font-size: 14px"><b>Treat</b>
            </label>
          </div>
          <div class="col-md-5">
            <label style="font-weight: normal;font-size: 14px"><b>Percentage</b>
            </label>
          </div>
          <div class="col-md-1">
            <label style="font-weight: normal;font-size: 14px"><b>Treat </b>
            </label>
          </div>
          <div class="col-md-4" style="padding-left:35px">
            <label style="font-weight: normal;font-size: 14px"><b>Percentage</b>
            </label>
          </div>

        </div>


        <div class="row">
          <div class="col-md-1">
            <label class="checkbox-inline" style="padding-top:0px">
              <input type="checkbox" id="cmd_1445" name="cmd_1445" formControlName="cmd_1445" [class.is-invalid]="(cherokeeHealthForm.controls['cmd_1445'].touched && cherokeeHealthForm.controls['cmd_1445'].errors) || (cherokeeHealthForm.controls['cmd_1445'].errors && isSubmit)" value="1"></label>
              <div class="formLabel col-md-11"
                *ngIf="cherokeeHealthForm.controls['cmd_1445'].touched && cherokeeHealthForm.controls['cmd_1445'].errors">
                <small class="text-danger" *ngIf="cherokeeHealthForm.controls['cmd_1445'].errors?.required">
                    required
                </small>
              </div>
              <span *ngIf="(cherokeeHealthForm.controls['cmd_1445'].touched && cherokeeHealthForm.controls['cmd_1445'].errors) || (cherokeeHealthForm.controls['cmd_1445'].errors && isSubmit)">
                <i class="fas fa-times ml-2 text-danger"></i>
              </span>
          </div>
          <div class="col-md-1" style="width:10%;padding-left:10px;padding-top:8px">
            <input class="form-control  " id="cmd_1446" formControlName="cmd_1446" [class.is-invalid]="(cherokeeHealthForm.controls['cmd_1446'].touched && cherokeeHealthForm.controls['cmd_1446'].errors) || (cherokeeHealthForm.controls['cmd_1446'].errors && isSubmit)" name="cmd_1446" type="text">
            <div class="formLabel col-md-11"
                *ngIf="cherokeeHealthForm.controls['cmd_1446'].touched && cherokeeHealthForm.controls['cmd_1446'].errors">
                <small class="text-danger" *ngIf="cherokeeHealthForm.controls['cmd_1446'].errors?.required">
                    required
                </small>
            </div>
          </div>
          <div class="col-md-3" style="padding-top: 8px">
            <label style="font-weight: normal;font-size: 14px">Older Adult (65 &amp; over)
            </label>
          </div>
          <div class="col-md-2" style="padding-left:80px">
            <label class="checkbox-inline" style="padding-top:0px">
              <input type="checkbox" id="cmd_1447" name="cmd_1447" formControlName="cmd_1447" [class.is-invalid]="(cherokeeHealthForm.controls['cmd_1447'].touched && cherokeeHealthForm.controls['cmd_1447'].errors) || (cherokeeHealthForm.controls['cmd_1447'].errors && isSubmit)" value="1"></label>
              <div class="formLabel col-md-11"
                *ngIf="cherokeeHealthForm.controls['cmd_1447'].touched && cherokeeHealthForm.controls['cmd_1447'].errors">
                <small class="text-danger" *ngIf="cherokeeHealthForm.controls['cmd_1447'].errors?.required">
                    required
                </small>
              </div>
              <span *ngIf="(cherokeeHealthForm.controls['cmd_1447'].touched && cherokeeHealthForm.controls['cmd_1447'].errors) || (cherokeeHealthForm.controls['cmd_1447'].errors && isSubmit)">
                <i class="fas fa-times ml-2 text-danger"></i>
            </span>
          </div>
          <div class="col-md-1" style="width:10%;padding-left:20px;padding-top:8px">
            <input class="form-control  " id="cmd_1448" formControlName="cmd_1448" [class.is-invalid]="(cherokeeHealthForm.controls['cmd_1448'].touched && cherokeeHealthForm.controls['cmd_1448'].invalid) || (cherokeeHealthForm.controls['cmd_1448'].invalid && isSubmit)" name="cmd_1448" type="text">
            <div class="formLabel col-md-11"
                *ngIf="cherokeeHealthForm.controls['cmd_1448'].touched && cherokeeHealthForm.controls['cmd_1448'].invalid">
                <small class="text-danger" *ngIf="cherokeeHealthForm.controls['cmd_1448'].errors?.required">
                    required
                </small>
            </div>
          </div>
          <div class="col-md-3" style="padding-top:8px;padding-left:20px">
            <label style="font-weight: normal;font-size: 14px">Older Child (6 to 12)
            </label>
          </div>

        </div>


        <div class="row">
          <div class="col-md-1">
            <label class="checkbox-inline" style="padding-top:0px">
              <input type="checkbox" id="cmd_1449" name="cmd_1449" [class.is-invalid]="(cherokeeHealthForm.controls['cmd_1449'].touched && cherokeeHealthForm.controls['cmd_1449'].invalid) || (cherokeeHealthForm.controls['cmd_1449'].invalid && isSubmit)" formControlName="cmd_1449" value="1"></label>
              <div class="formLabel col-md-11"
                *ngIf="cherokeeHealthForm.controls['cmd_1449'].touched && cherokeeHealthForm.controls['cmd_1449'].invalid">
                <small class="text-danger" *ngIf="cherokeeHealthForm.controls['cmd_1449'].errors?.required">
                    required
                </small>
            </div>
            <span *ngIf="(cherokeeHealthForm.controls['cmd_1449'].touched && cherokeeHealthForm.controls['cmd_1449'].errors) || (cherokeeHealthForm.controls['cmd_1449'].errors && isSubmit)">
              <i class="fas fa-times ml-2 text-danger"></i>
            </span>
          </div>
          <div class="col-md-1" style="width:10%;padding-left:10px;padding-top:8px">
            <input class="form-control  " id="cmd_1450" name="cmd_1450" [class.is-invalid]="(cherokeeHealthForm.controls['cmd_1450'].touched && cherokeeHealthForm.controls['cmd_1450'].invalid) || (cherokeeHealthForm.controls['cmd_1450'].invalid && isSubmit)" formControlName="cmd_1450" type="text">
            <div class="formLabel col-md-11"
                *ngIf="cherokeeHealthForm.controls['cmd_1450'].touched && cherokeeHealthForm.controls['cmd_1450'].invalid">
                <small class="text-danger" *ngIf="cherokeeHealthForm.controls['cmd_1450'].errors?.required">
                    required
                </small>
            </div>
          </div>
          <div class="col-md-3" style="padding-top: 8px">
            <label style="font-weight: normal;font-size: 14px">Adult (18 to 65)
            </label>
          </div>
          <div class="col-md-2" style="padding-left:80px">
            <label class="checkbox-inline" style="padding-top:0px">
              <input type="checkbox" id="cmd_1451" name="cmd_1451" [class.is-invalid]="(cherokeeHealthForm.controls['cmd_1451'].touched && cherokeeHealthForm.controls['cmd_1451'].errors) || (cherokeeHealthForm.controls['cmd_1451'].errors && isSubmit)" formControlName="cmd_1451" value="1"></label>
              <div class="formLabel col-md-11"
                *ngIf="cherokeeHealthForm.controls['cmd_1451'].touched && cherokeeHealthForm.controls['cmd_1451'].errors">
                <small class="text-danger" *ngIf="cherokeeHealthForm.controls['cmd_1451'].errors?.required">
                    required
                </small>
              </div>
              <span *ngIf="(cherokeeHealthForm.controls['cmd_1451'].touched && cherokeeHealthForm.controls['cmd_1451'].errors) || (cherokeeHealthForm.controls['cmd_1451'].errors && isSubmit)">
                <i class="fas fa-times ml-2 text-danger"></i>
            </span>
          </div>
          <div class="col-md-1" style="width:10%;padding-left:20px;padding-top:8px">
            <input class="form-control  " id="cmd_1452" name="cmd_1452" [class.is-invalid]="(cherokeeHealthForm.controls['cmd_1452'].touched && cherokeeHealthForm.controls['cmd_1452'].invalid) || (cherokeeHealthForm.controls['cmd_1452'].invalid && isSubmit)" formControlName="cmd_1452" type="text">
            <div class="formLabel col-md-11"
                *ngIf="cherokeeHealthForm.controls['cmd_1452'].touched && cherokeeHealthForm.controls['cmd_1452'].invalid">
                <small class="text-danger" *ngIf="cherokeeHealthForm.controls['cmd_1452'].errors?.required">
                    required
                </small>
            </div>
          </div>
          <div class="col-md-3" style="padding-top:8px;padding-left:20px">
            <label style="font-weight: normal;font-size: 14px">Younger Child (0 to 5)
            </label>
          </div>

        </div>


        <div class="row">
          <div class="col-md-1">
            <label class="checkbox-inline" style="padding-top:0px">
              <input type="checkbox" id="cmd_1453" name="cmd_1453" [class.is-invalid]="(cherokeeHealthForm.controls['cmd_1453'].touched && cherokeeHealthForm.controls['cmd_1453'].errors) || (cherokeeHealthForm.controls['cmd_1453'].errors && isSubmit)" formControlName="cmd_1453" value="1"></label>
              <div class="formLabel col-md-11"
                *ngIf="cherokeeHealthForm.controls['cmd_1453'].touched && cherokeeHealthForm.controls['cmd_1453'].errors">
                <small class="text-danger" *ngIf="cherokeeHealthForm.controls['cmd_1453'].errors?.required">
                    required
                </small>
              </div>
              <span *ngIf="(cherokeeHealthForm.controls['cmd_1453'].touched && cherokeeHealthForm.controls['cmd_1453'].errors) || (cherokeeHealthForm.controls['cmd_1453'].errors && isSubmit)">
                <i class="fas fa-times ml-2 text-danger"></i>
              </span>
          </div>
          <div class="col-md-1" style="width:10%;padding-left:10px;padding-top:8px">
            <input class="form-control  " id="cmd_1454" name="cmd_1454" [class.is-invalid]="(cherokeeHealthForm.controls['cmd_1454'].touched && cherokeeHealthForm.controls['cmd_1454'].invalid) || (cherokeeHealthForm.controls['cmd_1454'].invalid && isSubmit)" formControlName="cmd_1454" type="text">
            <div class="formLabel col-md-11"
                *ngIf="cherokeeHealthForm.controls['cmd_1454'].touched && cherokeeHealthForm.controls['cmd_1454'].invalid">
                <small class="text-danger" *ngIf="cherokeeHealthForm.controls['cmd_1454'].errors?.required">
                    required
                </small>
            </div>
          </div>
          <div class="col-md-3" style="padding-top: 8px">
            <label style="font-weight: normal;font-size: 14px">Adolescent (13 to 17)
            </label>
          </div>
          <div class="col-md-1" style="width:10%;padding-left:200px;padding-top:8px">
            <label style="font-weight: normal;font-size: 14px"><b>100% </b>
            </label>
          </div>
          <div class="col-md-3" style="padding-top:8px;padding-left:110px">
            <label style="font-weight: normal;font-size: 14px"><b>Total</b>
            </label>
          </div>

        </div>

        <br>
        <div class="row">
          <label style="padding-left:20px;font-style:italic"><b>8. Other Specialized Services you wish to provide
          </b></label>
        </div>


        <div class="row" style="padding-left:70px">
          <div class="col-md-5">
            <label class="checkbox-inline" style="padding-top:0px">
              <input type="checkbox" id="cmd_1455" name="cmd_1455" formControlName="cmd_1455" value="1">Services to the
              chronically mentally ill </label>
          </div>
          <div class="col-md-4">
            <label class="checkbox-inline" style="padding-top:0px">
              <input type="checkbox" id="cmd_1456" name="cmd_1456" formControlName="cmd_1456" value="1">Psychological
              Testing </label>
          </div>

        </div>

        <br>
        <div class="row">
          <label style="padding-left:20px;"><b>9. Treatment Modality (Indicate modalities you currently employ in your
            practice.)Total percentages must equal 100%.</b>
          </label>
        </div>


        <div class="row" style="padding-left:30px">
          <div class="col-md-1">
            <label style="font-weight: normal;font-size: 14px"><b>Treat</b>
            </label>
          </div>
          <div class="col-md-5">
            <label style="font-weight: normal;font-size: 14px"><b>Percentage</b>
            </label>
          </div>
          <div class="col-md-1">
            <label style="font-weight: normal;font-size: 14px"><b>Treat </b>
            </label>
          </div>
          <div class="col-md-4" style="padding-left:35px">
            <label style="font-weight: normal;font-size: 14px"><b>Percentage</b>
            </label>
          </div>

        </div>


        <div class="row">
          <div class="col-md-1">
            <label class="checkbox-inline" style="padding-top:0px">
              <input type="checkbox" id="cmd_1457" name="cmd_1457" formControlName="cmd_1457" value="1"></label>
          </div>
          <div class="col-md-1" style="width:10%;padding-left:10px;padding-top:8px">
            <input class="form-control  " id="cmd_1458" name="cmd_1458" formControlName="cmd_1458" type="text">
          </div>
          <div class="col-md-3" style="padding-top: 8px">
            <label style="font-weight: normal;font-size: 14px">Individual Psychotherapy
            </label>
          </div>
          <div class="col-md-2" style="padding-left:80px">
            <label class="checkbox-inline" style="padding-top:0px">
              <input type="checkbox" id="cmd_1459" name="cmd_1459" value="1" formControlName="cmd_1459"></label>
          </div>
          <div class="col-md-1" style="width:10%;padding-left:20px;padding-top:8px">
            <input class="form-control  " id="cmd_1460" name="cmd_1460" formControlName="cmd_1460" type="text">
          </div>
          <div class="col-md-3" style="padding-top:8px;padding-left:20px">
            <label style="font-weight: normal;font-size: 14px">Behavior Modification
            </label>
          </div>

        </div>


        <div class="row">
          <div class="col-md-1">
            <label class="checkbox-inline" style="padding-top:0px">
              <input type="checkbox" id="cmd_1461" name="cmd_1461" formControlName="cmd_1461" value="1"></label>
          </div>
          <div class="col-md-1" style="width:10%;padding-left:10px;padding-top:8px">
            <input class="form-control  " id="cmd_1462" formControlName="cmd_1462" name="cmd_1462" type="text">
          </div>
          <div class="col-md-3" style="padding-top: 8px">
            <label style="font-weight: normal;font-size: 14px">Conjoint/Couple Psychotherapy
            </label>
          </div>
          <div class="col-md-2" style="padding-left:80px">
            <label class="checkbox-inline" style="padding-top:0px">
              <input type="checkbox" id="cmd_1463" formControlName="cmd_1463" name="cmd_1463" value="1"></label>
          </div>
          <div class="col-md-1" style="width:10%;padding-left:20px;padding-top:8px">
            <input class="form-control  " id="cmd_1464" formControlName="cmd_1464" name="cmd_1464" type="text">
          </div>
          <div class="col-md-3" style="padding-top:8px;padding-left:20px">
            <label style="font-weight: normal;font-size: 14px">Pharmacotherapy
            </label>
          </div>

        </div>


        <div class="row">
          <div class="col-md-1">
            <label class="checkbox-inline" style="padding-top:0px">
              <input type="checkbox" id="cmd_1465" formControlName="cmd_1465" name="cmd_1465" value="1"></label>
          </div>
          <div class="col-md-1" style="width:10%;padding-left:10px;padding-top:8px">
            <input class="form-control  " id="cmd_1466" formControlName="cmd_1466" name="cmd_1466" type="text">
          </div>
          <div class="col-md-3" style="padding-top: 8px">
            <label style="font-weight: normal;font-size: 14px">Family Psychotherapy
            </label>
          </div>
          <div class="col-md-2" style="padding-left:80px">
            <label class="checkbox-inline" style="padding-top:0px">
              <input type="checkbox" id="cmd_1467" formControlName="cmd_1467" name="cmd_1467" value="1"></label>
          </div>
          <div class="col-md-1" style="width:10%;padding-left:20px;padding-top:8px">
            <input class="form-control  " id="cmd_1468" formControlName="cmd_1468" name="cmd_1468" type="text">
          </div>
          <div class="col-md-3" style="padding-top:8px;padding-left:20px">
            <label style="font-weight: normal;font-size: 14px">Hypnosis
            </label>
          </div>

        </div>


        <div class="row">
          <div class="col-md-1">
            <label class="checkbox-inline" style="padding-top:0px">
              <input type="checkbox" id="cmd_1469" formControlName="cmd_1469" name="cmd_1469" value="1"></label>
          </div>
          <div class="col-md-1" style="width:10%;padding-left:10px;padding-top:8px">
            <input class="form-control  " id="cmd_1470" formControlName="cmd_1470" name="cmd_1470" type="text">
          </div>
          <div class="col-md-3" style="padding-top: 8px">
            <label style="font-weight: normal;font-size: 14px">Group Psychotherapy - MH
            </label>
          </div>
          <div class="col-md-2" style="padding-left:80px">
            <label class="checkbox-inline" style="padding-top:0px">
              <input type="checkbox" id="cmd_1471" name="cmd_1471" formControlName="cmd_1471" value="1"></label>
          </div>
          <div class="col-md-1" style="width:10%;padding-left:20px;padding-top:8px;">
            <input class="form-control  " id="cmd_1472" formControlName="cmd_1472" name="cmd_1472" type="text">
          </div>
          <div class="col-md-3" style="padding-top:8px;padding-left:20px">
            <label style="font-weight: normal;font-size: 14px">Play Therapy
            </label>
          </div>

        </div>


        <div class="row">
          <div class="col-md-1">
            <label class="checkbox-inline" style="padding-top:0px">
              <input type="checkbox" id="cmd_1473" formControlName="cmd_1473" name="cmd_1473" value="1"></label>
          </div>
          <div class="col-md-1" style="width:10%;padding-left:10px;padding-top:8px">
            <input class="form-control  " id="cmd_1474" formControlName="cmd_1474" name="cmd_1474" type="text">
          </div>
          <div class="col-md-3" style="padding-top: 8px">
            <label style="font-weight: normal;font-size: 14px">Group Psychotherapy - SA
            </label>
          </div>
          <div class="col-md-1" style="width:10%;padding-left:200px;padding-top:8px">
            <label style="font-weight: normal;font-size: 14px"><b>100% </b>
            </label>
          </div>
          <div class="col-md-3" style="padding-top:8px;padding-left:110px">
            <label style="font-weight: normal;font-size: 14px"><b>Total</b>
            </label>
          </div>

        </div>


        <div class="row">
          <div class="col-md-1">
            <label class="checkbox-inline" style="padding-top:0px">
              <input type="checkbox" id="cmd_1475" formControlName="cmd_1475" name="cmd_1475" value="1"></label>
          </div>
          <div class="col-md-1" style="width:10%;padding-left:10px;padding-top:8px">
            <input class="form-control  " id="cmd_1476" formControlName="cmd_1476" name="cmd_1476" type="text">
          </div>
          <div class="col-md-3" style="padding-top: 8px">
            <label style="font-weight: normal;font-size: 14px">Biofeedback
            </label>
          </div>
        </div>


        <div class="row">
          <div class="col-md-1">
            <label class="checkbox-inline" style="padding-top:0px">
              <input type="checkbox" id="cmd_1477" formControlName="cmd_1477" name="cmd_1477" value="1"></label>
          </div>
          <div class="col-md-1" style="width:10%;padding-left:10px;padding-top:8px">
            <input class="form-control  " id="cmd_1478" formControlName="cmd_1478" name="cmd_1478" type="text">
          </div>
          <div class="col-md-3" style="padding-top: 8px">
            <label style="font-weight: normal;font-size: 14px">Other (please specify below)
            </label>
          </div>
        </div>


        <div class="row">
          <div class="col-md-12">
            <input class="form-control  " id="cmd_1479" formControlName="cmd_1479" name="cmd_1479"
              placeholder="If other is chosen, make this text area required. " type="text">
          </div>
        </div>


        <div class="row">
          <div class="col-md-12">
            <input class="form-control " id="cmd_01480" formControlName="cmd_01480" name="cmd_01480" type="text">
          </div>
        </div>


        <hr
          style="border: 17px solid #818181; margin-left: -87px;margin-right: -87px; margin-top: 50px; margin-bottom: 50px;">

        <div class="centered" style="text-align:center;">
          <h2>Cherokee Health Systems</h2>
        </div>



        <div class="centered" style="text-align:center;">
          <h2>Credentialing/Recredentialing Application</h2>
        </div>


        <div class="row">
          <label style="padding-left:20px;font-style: italic;"><b>10. Professional Liability Information </b></label>

        </div>


        <div class="row" style="padding-left: 30px;">
          <div class="col-md-2">
            <label style="font-weight: normal;font-size: 14px">Current Carrier:</label>
          </div>
          <div class="col-md-9">
            <input class="form-control  " id="345" formControlName="345" name="345" type="text"
              placeholder="Malpractice Insurance Name">
          </div>
        </div>


        <div class="row" style="padding-left:30px">
          <div class="form-group col-md-2">
            <label style="font-weight: normal;font-size: 14px">Address:
            </label>
          </div>
          <div class="col-md-4">
            <input class="form-control" id="901" name="901" formControlName="901" type="text"
              placeholder="Malpractice Insurance add1">
          </div>
          <div class="col-md-4">
            <input class="form-control" id="902" name="902" formControlName="902" type="text"
              placeholder="Malpractice Insurance add2">
          </div>
        </div>


        <div class="row" style="padding-left:30px">
          <div class="col-md-1">
            <label style="font-weight: normal;font-size: 14px">City:
            </label>
          </div>
          <div class="col-md-1">
            <input class="form-control" id="903" name="903" formControlName="903" type="text"
              placeholder="Malpractice Insurance city">
          </div>
          <div class="col-md-1">
            <label style="font-weight: normal;font-size: 14px">State:
            </label>
          </div>
          <div class="col-md-2">
            <ng-select [virtualScroll]="true" [items]="stateDropDownDate" bindLabel="name" formControlName="905"
              placeholder="Malpractice Insurance State" bindValue="id">
            </ng-select>
          </div>

          <div class="col-md-1">
            <label style="font-weight: normal;font-size: 14px;">Zip:
            </label>
          </div>
          <div class="col-md-1">
            <input class="form-control " id="906" name="906" formControlName="906" type="text"
              placeholder="Malpractice Insurance Zip">
          </div>
          <div class="col-md-1">
            <label style="font-weight: normal;font-size: 14px">Coverage Limits:$
            </label>
          </div>
          <div class="col-md-2">
            <ng-select [virtualScroll]="true" [items]="preClaim" bindLabel="name" placeholder="Malpractice Insurance Per claim" bindValue="id"
              formControlName="350">
            </ng-select>
          </div>

          <div class="col-md-2">
            <ng-select [virtualScroll]="true" [items]="aggregates" bindLabel="name" formControlName="351"
              placeholder="Malpractice Insurance Aggregates" bindValue="id" formControlName="351">
            </ng-select>
          </div>
        </div>


        <div class="row" style="padding-left:30px">
          <div class="col-md-2">
            <label style="font-weight: normal;font-size: 14px">Date coverage first began:
            </label>
          </div>
          <div class="col-md-2">
            <div class="input-group">
              <input class="form-control" formControlName="348" (blur)="clearInputIfInvalid('348')" name="dp" placeholder="Malpractice Insurance start date"
                ngbDatepicker #d32="ngbDatepicker" placement="bottom">
              <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d32.toggle()" type="button"><i
                    class="fas fa-calendar-alt"></i></button>
              </div>
              <div  *ngIf="cherokeeHealthForm.get('348').invalid ">
                <small class="text-danger" *ngIf="cherokeeHealthForm.get('348').errors?.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY)
                </small>
                <small class="text-danger" *ngIf="cherokeeHealthForm.get('348').errors?.invalidMonth">
                Month is out of range
                </small>
                <small class="text-danger" *ngIf="cherokeeHealthForm.get('348').errors?.invalidDateyear">
                  year is out of range(1900-2099)                      </small>
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('348').errors?.invalidDateRange">
                    date is out of range
                    </small>
                </div>
            </div>
          </div>
          <div class="col-md-2">
            <label style="font-weight: normal;font-size: 14px">Expiration Date:
            </label>
          </div>
          <div class="col-md-2">
            <div class="input-group">
              <input class="form-control" formControlName="349" (blur)="clearInputIfInvalid('349')" name="dp" placeholder="Malpractice Insurance exp"
                ngbDatepicker #d33="ngbDatepicker" placement="bottom">
              <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d33.toggle()" type="button"><i
                    class="fas fa-calendar-alt"></i></button>
              </div>
              <div  *ngIf="cherokeeHealthForm.get('349').invalid ">
                <small class="text-danger" *ngIf="cherokeeHealthForm.get('349').errors?.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY)
                </small>
                <small class="text-danger" *ngIf="cherokeeHealthForm.get('349').errors?.invalidMonth">
                Month is out of range
                </small>
                <small class="text-danger" *ngIf="cherokeeHealthForm.get('349').errors?.invalidDateyear">
                  year is out of range(1900-2099)                      </small>
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('349').errors?.invalidDateRange">
                    date is out of range
                    </small>
                </div>
            </div>
          </div>
          <div class="col-md-1">
            <label style="font-weight: normal;font-size: 14px">Policy #:
            </label>
          </div>
          <div class="col-md-3">
            <input class="form-control  " id="346" name="346" formControlName="346" type="text"
              placeholder="Malpractice Insurance policy">
          </div>
        </div>


        <div class="row" style="padding-left:30px">
          <div class="col-md-6">
            <label style="font-weight: normal;font-size: 14px">Is this an individual or group policy?
            </label>
          </div>
          <div class="col-md-3">
            <label class="checkbox-inline" style="padding-top:0px">
              <input type="radio" id="cmd_01481" name="cmd_01481" formControlName="cmd_01481"
                value="1">Individual</label>
          </div>
          <div class="col-md-3">
            <label class="checkbox-inline" style="padding-top:0px">
              <input type="radio" id="cmd_01481_1" name="cmd_01481" formControlName="cmd_01481"
                value="0">Group/Organization</label>
          </div>
        </div>


        <div class="row" style="padding-left: 30px;">
          <div class="col-md-2">
            <label style="font-weight: normal;font-size: 14px">Previous Carrier</label>
          </div>
          <div class="form-group form-inline no-margin col-md-9" style="padding: 2px">
            <input class="form-control  " id="1242" name="1242" formControlName="1242" type="text"
              data-id="MLP Carrier 2" placeholder="Previous Malpractice Insurance Name">
          </div>
        </div>


        <div class="row" style="padding-left:30px">
          <div class="form-group col-md-2">
            <label style="font-weight: normal;font-size: 14px">Address:
            </label>
          </div>
          <div class="col-md-4">
            <input class="form-control" id="1244" name="1244" formControlName="1244" type="text"
              placeholder="Previous Malpractice Insurance add1">
          </div>
          <div class="col-md-4">
            <input class="form-control" id="1245" name="1245" formControlName="1245" type="text"
              placeholder="Previous Malpractice Insurance add2">
          </div>
        </div>


        <div class="row" style="padding-left:30px">
          <div class="col-md-1">
            <label style="font-weight: normal;font-size: 14px">City:
            </label>
          </div>
          <div class="form-group form-inline col-md-1" style="padding: 2px">
            <input style="width: 100%" class="form-control" formControlName="1246" id="1246" name="1246" type="text"
              data-id="mlp 2 city" placeholder="Previous Malpractice Insurance city">
          </div>
          <div class="col-md-1">
            <label style="font-weight: normal;font-size: 14px">State:
            </label>
          </div>
          <div class="form-group form-inline col-md-2" style="padding: 2px">
            <ng-select [virtualScroll]="true" [items]="stateDropDownDate" bindLabel="name" placeholder="Select States" bindValue="id"
              formControlName="1248">
            </ng-select>
          </div>

          <div class="col-md-1">
            <label style="font-weight: normal;font-size: 14px;">Zip:
            </label>
          </div>
          <div class="form-group form-inline col-md-1" style="padding: 2px">
            <input style="width: 100%" class="form-control" formControlName="1249" id="1249" name="1249" type="text"
              data-id="MLP 2 zip" placeholder="Previous Malpractice Insurance Zip">
          </div>
          <div class="col-md-1">
            <label style="font-weight: normal;font-size: 14px">Coverage Limits:$
            </label>
          </div>
          <div class="col-md-2">
            <ng-select [virtualScroll]="true" [items]="preClaim" bindLabel="name" placeholder="Select Pre claim" bindValue="id"
              formControlName="1264">
            </ng-select>
          </div>
          <div class="col-md-2">
            <ng-select [virtualScroll]="true" [items]="aggregates" bindLabel="name" placeholder="Select Agregate" bindValue="id"
              formControlName="1265">
            </ng-select>

          </div>
        </div>


        <div class="row" style="padding-left:30px">
          <div class="col-md-2">
            <label style="font-weight: normal;font-size: 14px">Date coverage first began:
            </label>
          </div>
          <div class="col-md-2">
            <div class="input-group">
              <input class="form-control" formControlName="1252" (blur)="clearInputIfInvalid('1252')" name="dp"
                placeholder="Previous Malpractice Insurance start date" ngbDatepicker #d34="ngbDatepicker"
                placement="bottom">
              <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d34.toggle()" type="button"><i
                    class="fas fa-calendar-alt"></i></button>
              </div>
              <div  *ngIf="cherokeeHealthForm.get('1252').invalid ">
                <small class="text-danger" *ngIf="cherokeeHealthForm.get('1252').errors?.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY)
                </small>
                <small class="text-danger" *ngIf="cherokeeHealthForm.get('1252').errors?.invalidMonth">
                Month is out of range
                </small>
                <small class="text-danger" *ngIf="cherokeeHealthForm.get('1252').errors?.invalidDateyear">
                  year is out of range(1900-2099)                      </small>
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('1252').errors?.invalidDateRange">
                    date is out of range
                    </small>
                </div>
            </div>
          </div>
          <div class="col-md-2">
            <label style="font-weight: normal;font-size: 14px">Expiration Date:
            </label>
          </div>
          <div class="col-md-2">
            <div class="input-group">
              <input class="form-control" formControlName="1253" (blur)="clearInputIfInvalid('1253')" name="dp"
                placeholder="Previous Malpractice Insurance exp" ngbDatepicker #d35="ngbDatepicker" placement="bottom">
              <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d35.toggle()" type="button"><i
                    class="fas fa-calendar-alt"></i></button>
              </div>
              <div  *ngIf="cherokeeHealthForm.get('1253').invalid ">
                <small class="text-danger" *ngIf="cherokeeHealthForm.get('1253').errors?.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY)
                </small>
                <small class="text-danger" *ngIf="cherokeeHealthForm.get('1253').errors?.invalidMonth">
                Month is out of range
                </small>
                <small class="text-danger" *ngIf="cherokeeHealthForm.get('1253').errors?.invalidDateyear">
                  year is out of range(1900-2099)                      </small>
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('1253').errors?.invalidDateRange">
                    date is out of range
                    </small>
                </div>
            </div>
          </div>
          <div class="col-md-1">
            <label style="font-weight: normal;font-size: 14px">Policy #:
            </label>
          </div>
          <div class="form-group form-inline no-margin" style="padding: 2px">
            <input class="form-control  " id="1256" name="1256" type="text" formControlName="1256" data-id="MLP 2 pol"
              placeholder="Previous Malpractice Insurance policy">
          </div>
        </div>


        <div class="row" style="padding-left:30px">
          <div class="col-md-6">
            <label style="font-weight: normal;font-size: 14px">Is this an individual or group policy?
            </label>
          </div>
          <div class="col-md-3">
            <label class="checkbox-inline" style="padding-top:0px">
              <input type="radio" id="cmd_01483" formControlName="cmd_01483" name="cmd_01483"
                value="1">Individual</label>
          </div>
          <div class="col-md-3">
            <label class="checkbox-inline" style="padding-top:0px">
              <input type="radio" id="cmd_01483_1" name="cmd_01483" formControlName="cmd_01483"
                value="0">Group/Organization</label>
          </div>
        </div>


        <div class="row">
          <label style="padding-left:10px;font-style:italic;"><b>11. Malpractice History</b></label>

        </div>


        <div class="row" style="padding-left:30px">
          <div class="form-group col-md-9">
            <label style="font-weight: normal;font-size: 14px">I affirm that I have maintained malpractice insurance
              consecutively for the past five years.<label
                style="font-weight: normal;font-style:italic;font-size: 12px">(If no, please explain in an attachment.)
              </label></label>
          </div>
          <div class="col-md-3">
            <label class="checkbox-inline">
              <input type="radio" id="cmd_01485" name="cmd_01485" [class.is-invalid]="(cherokeeHealthForm.controls['cmd_01485'].touched && cherokeeHealthForm.controls['cmd_01485'].invalid) || (cherokeeHealthForm.controls['cmd_01485'].invalid && isSubmit)" formControlName="cmd_01485" value="1">Yes</label>
              <label class="checkbox-inline">
                <input type="radio" id="cmd_01485_1" name="cmd_01485" [class.is-invalid]="(cherokeeHealthForm.controls['cmd_01485'].touched && cherokeeHealthForm.controls['cmd_01485'].invalid) || (cherokeeHealthForm.controls['cmd_01485'].invalid && isSubmit)" formControlName="cmd_01485" value="0">No</label>
              <div class="formLabel col-md-11"
                *ngIf="cherokeeHealthForm.controls['cmd_01485'].touched && cherokeeHealthForm.controls['cmd_01485'].invalid">
                <small class="text-danger" *ngIf="cherokeeHealthForm.controls['cmd_01485'].errors?.required">
                    required
                </small>
              </div>
              <span *ngIf="(cherokeeHealthForm.controls['cmd_01485'].touched && cherokeeHealthForm.controls['cmd_01485'].errors) || (cherokeeHealthForm.controls['cmd_01485'].errors && isSubmit)">
                <i class="fas fa-times ml-2 text-danger"></i>
              </span>
          </div>
      </div>
        <div class="row" style="padding-left:20px">
          <div class="form-group col-md-9">
            <label style="font-weight: normal;font-size: 14px">A. Have you ever been named in any malpractice action?
            </label>
          </div>
          <div class="col-md-3">
            <label class="checkbox-inline">
              <input type="radio" id="cmd_01486" name="cmd_01486" [class.is-invalid]="(cherokeeHealthForm.controls['cmd_01486'].touched && cherokeeHealthForm.controls['cmd_01486'].invalid) || (cherokeeHealthForm.controls['cmd_01486'].invalid && isSubmit)" formControlName="cmd_01486" value="1">Yes</label>
              <label class="checkbox-inline">
              <input type="radio" id="cmd_01486_1" name="cmd_01486" [class.is-invalid]="(cherokeeHealthForm.controls['cmd_01486'].touched && cherokeeHealthForm.controls['cmd_01486'].invalid) || (cherokeeHealthForm.controls['cmd_01486'].invalid && isSubmit)" formControlName="cmd_01486" value="0">No</label>
              <div class="formLabel col-md-11"
                *ngIf="cherokeeHealthForm.controls['cmd_01486'].touched && cherokeeHealthForm.controls['cmd_01486'].invalid">
                <small class="text-danger" *ngIf="cherokeeHealthForm.controls['cmd_01486'].errors?.required">
                    required
                </small>
              </div>
              <span *ngIf="(cherokeeHealthForm.controls['cmd_01486'].touched && cherokeeHealthForm.controls['cmd_01486'].errors) || (cherokeeHealthForm.controls['cmd_01486'].errors && isSubmit)">
                <i class="fas fa-times ml-2 text-danger"></i>
              </span>
          </div>
        </div>


        <div class="row" style="padding-left:30px">
          <div class="form-group col-md-9">
            <label style="font-weight: normal;font-style:italic;font-size: 14px">if the answer to the above question Is
              yes, please attach the following Information for each suit or setilement whether open or closed, and
              regardless of whether or not payment was made:
            </label>
          </div>
        </div>


        <div class="row">
          <div class="form-group col-md-9">
            <label style="font-weight: normal;font-style:italic;font-size: 14px">1. A complete copy of the complaint
              filed stating the allegations and the current status
            </label>
          </div>
        </div>


        <div class="row">
          <div class="form-group col-md-9">
            <label style="font-weight: normal;font-style:italic;font-size: 14px">2. If the actions have been settled or
              dismissed.Cherokee Health Systems will need a copy of the settlement or dismissal. If actions were
              settled.Cherokee Health Systems will need lo know which parly accepted liability for the actions.
            </label>
          </div>
        </div>


        <div class="row">
          <div class="form-group col-md-9">
            <label style="font-weight: normal;font-style:italic;font-size: 14px">3. A claims history report from the
              insurer covering the claim.
            </label>
          </div>
        </div>


        <div class="row" style="padding-left:30px">
          <div class="form-group col-md-12">
            <label style="font-weight: normal;font-size: 14px"><b>For Questions B-G: If the response Is yes to any of
                these questions, please explain in an attachment.</b>
            </label>
          </div>
        </div>


        <div class="row" style="padding-left:20px">
          <div class="form-group col-md-9">
            <label style="font-weight: normal;font-size: 14px">B.Have you ever been denied malpractice insurance or has
              your insurance ever been canceled or renewal refused?
            </label>
          </div>
          <div class="col-md-3">
            <label class="checkbox-inline">
              <input type="radio" id="cmd_01488" name="cmd_01488" [class.is-invalid]="(cherokeeHealthForm.controls['cmd_01488'].touched && cherokeeHealthForm.controls['cmd_01488'].invalid) || (cherokeeHealthForm.controls['cmd_01488'].invalid && isSubmit)" formControlName="cmd_01488" value="1">Yes</label>
              <label class="checkbox-inline">
              <input type="radio" id="cmd_01488_1" name="cmd_01488" [class.is-invalid]="(cherokeeHealthForm.controls['cmd_01488'].touched && cherokeeHealthForm.controls['cmd_01488'].invalid) || (cherokeeHealthForm.controls['cmd_01488'].invalid && isSubmit)" formControlName="cmd_01488" value="0">No</label>
              <div class="formLabel col-md-11"
                *ngIf="cherokeeHealthForm.controls['cmd_01488'].touched && cherokeeHealthForm.controls['cmd_01488'].invalid">
                <small class="text-danger" *ngIf="cherokeeHealthForm.controls['cmd_01488'].errors?.required">
                    required
                </small>
              </div>
                <span *ngIf="(cherokeeHealthForm.controls['cmd_01488'].touched && cherokeeHealthForm.controls['cmd_01488'].errors) || (cherokeeHealthForm.controls['cmd_01488'].errors && isSubmit)">
                  <i class="fas fa-times ml-2 text-danger"></i>
                </span>
          </div>
        </div>



        <div class="row" style="padding-left:20px">
          <div class="form-group col-md-9">
            <label style="font-weight: normal;font-size: 14px">C. Has there ever been any action against or
              investigation relating to your:
            </label>
          </div>
        </div>


        <div class="row">
          <div class="form-group col-md-9">
            <label style="font-weight: normal;font-size: 14px">(a) license
            </label>
          </div>
          <div class="col-md-3">
            <label class="checkbox-inline">
              <input type="radio" id="cmd_01489" name="cmd_01489" [class.is-invalid]="(cherokeeHealthForm.controls['cmd_01489'].touched && cherokeeHealthForm.controls['cmd_01489'].invalid) || (cherokeeHealthForm.controls['cmd_01489'].invalid && isSubmit)" formControlName="cmd_01489" value="1">Yes</label>
              <label class="checkbox-inline">
              <input type="radio" id="cmd_01489_1" name="cmd_01489" [class.is-invalid]="(cherokeeHealthForm.controls['cmd_01489'].touched && cherokeeHealthForm.controls['cmd_01489'].invalid) || (cherokeeHealthForm.controls['cmd_01489'].invalid && isSubmit)" formControlName="cmd_01489" value="0">No</label>
              <div class="formLabel col-md-11"
                *ngIf="cherokeeHealthForm.controls['cmd_01489'].touched && cherokeeHealthForm.controls['cmd_01489'].invalid">
                <small class="text-danger" *ngIf="cherokeeHealthForm.controls['cmd_01489'].errors?.required">
                    required
                </small>
              </div>
              <span *ngIf="(cherokeeHealthForm.controls['cmd_01489'].touched && cherokeeHealthForm.controls['cmd_01489'].errors) || (cherokeeHealthForm.controls['cmd_01489'].errors && isSubmit)">
                <i class="fas fa-times ml-2 text-danger"></i>
              </span>
          </div>
        </div>


        <div class="row">
          <div class="form-group col-md-9">
            <label style="font-weight: normal;font-size: 14px">(b) certification
            </label>
          </div>
          <div class="col-md-3">
            <label class="checkbox-inline">
              <input type="radio" id="cmd_1490" name="cmd_1490" [class.is-invalid]="(cherokeeHealthForm.controls['cmd_1490'].touched && cherokeeHealthForm.controls['cmd_1490'].invalid) || (cherokeeHealthForm.controls['cmd_1490'].invalid && isSubmit)" formControlName="cmd_1490" value="1">Yes</label>
              <label class="checkbox-inline">
              <input type="radio" id="cmd_1490_1" name="cmd_1490" [class.is-invalid]="(cherokeeHealthForm.controls['cmd_1490'].touched && cherokeeHealthForm.controls['cmd_1490'].invalid) || (cherokeeHealthForm.controls['cmd_1490'].invalid && isSubmit)" formControlName="cmd_1490" value="0">No</label>
              <div class="formLabel col-md-11"
                *ngIf="cherokeeHealthForm.controls['cmd_1490'].touched && cherokeeHealthForm.controls['cmd_1490'].invalid">
                <small class="text-danger" *ngIf="cherokeeHealthForm.controls['cmd_1490'].errors?.required">
                    required
                </small>
              </div>
              <span *ngIf="(cherokeeHealthForm.controls['cmd_1490'].touched && cherokeeHealthForm.controls['cmd_1490'].errors) || (cherokeeHealthForm.controls['cmd_1490'].errors && isSubmit)">
                <i class="fas fa-times ml-2 text-danger"></i>
              </span>
          </div>
        </div>


        <div class="row">
          <div class="form-group col-md-9">
            <label style="font-weight: normal;font-size: 14px">(c)registration
            </label>
          </div>
          <div class="col-md-3">
            <label class="checkbox-inline">
              <input type="radio" id="cmd_1491" name="cmd_1491" [class.is-invalid]="(cherokeeHealthForm.controls['cmd_1491'].touched && cherokeeHealthForm.controls['cmd_1491'].invalid) || (cherokeeHealthForm.controls['cmd_1491'].invalid && isSubmit)" formControlName="cmd_1491" value="1">Yes</label>
              <label class="checkbox-inline">
              <input type="radio" id="cmd_1491_1" name="cmd_1491" [class.is-invalid]="(cherokeeHealthForm.controls['cmd_1491'].touched && cherokeeHealthForm.controls['cmd_1491'].invalid) || (cherokeeHealthForm.controls['cmd_1491'].invalid && isSubmit)" formControlName="cmd_1491" value="0">No</label>
              <div class="formLabel col-md-11"
                *ngIf="cherokeeHealthForm.controls['cmd_1491'].touched && cherokeeHealthForm.controls['cmd_1491'].invalid">
                <small class="text-danger" *ngIf="cherokeeHealthForm.controls['cmd_1491'].errors?.required">
                    required
                </small>
              </div>
              <span *ngIf="(cherokeeHealthForm.controls['cmd_1491'].touched && cherokeeHealthForm.controls['cmd_1491'].errors) || (cherokeeHealthForm.controls['cmd_1491'].errors && isSubmit)">
                <i class="fas fa-times ml-2 text-danger"></i>
              </span>
          </div>
        </div>


        <div class="row">
          <div class="form-group col-md-9">
            <label style="font-weight: normal;font-size: 14px">(d) privileges
            </label>
          </div>
          <div class="col-md-3">
            <label class="checkbox-inline">
              <input type="radio" id="cmd_1492" name="cmd_1492" [class.is-invalid]="(cherokeeHealthForm.controls['cmd_1492'].touched && cherokeeHealthForm.controls['cmd_1492'].invalid) || (cherokeeHealthForm.controls['cmd_1492'].invalid && isSubmit)" formControlName="cmd_1492" value="1">Yes</label>
              <label class="checkbox-inline">
              <input type="radio" id="cmd_1492_1" name="cmd_1492" [class.is-invalid]="(cherokeeHealthForm.controls['cmd_1492'].touched && cherokeeHealthForm.controls['cmd_1492'].invalid) || (cherokeeHealthForm.controls['cmd_1492'].invalid && isSubmit)" formControlName="cmd_1492" value="0">No</label>
              <div class="formLabel col-md-11"
                *ngIf="cherokeeHealthForm.controls['cmd_1492'].touched && cherokeeHealthForm.controls['cmd_1492'].invalid">
                <small class="text-danger" *ngIf="cherokeeHealthForm.controls['cmd_1492'].errors?.required">
                    required
                </small>
              </div>
              <span *ngIf="(cherokeeHealthForm.controls['cmd_1492'].touched && cherokeeHealthForm.controls['cmd_1492'].errors) || (cherokeeHealthForm.controls['cmd_1492'].errors && isSubmit)">
                <i class="fas fa-times ml-2 text-danger"></i>
              </span>
          </div>
        </div>


        <div class="row" style="padding-left:20px">
          <div class="form-group col-md-9">
            <label style="font-weight: normal;font-size: 14px">D. Have you ever been convicted of a crime, including,
              but not limited to, crimes involving children, fraud, or narcotics? (Misdemeanors do not need to be
              reported.)
            </label>
          </div>
          <div class="col-md-3">
            <label class="checkbox-inline">
              <input type="radio" id="cmd_1493" name="cmd_1493" [class.is-invalid]="(cherokeeHealthForm.controls['cmd_1493'].touched && cherokeeHealthForm.controls['cmd_1493'].invalid) || (cherokeeHealthForm.controls['cmd_1493'].invalid && isSubmit)" formControlName="cmd_1493" value="1">Yes</label>
              <label class="checkbox-inline">
              <input type="radio" id="cmd_1493_1" name="cmd_1493" [class.is-invalid]="(cherokeeHealthForm.controls['cmd_1493'].touched && cherokeeHealthForm.controls['cmd_1493'].invalid) || (cherokeeHealthForm.controls['cmd_1493'].invalid && isSubmit)" formControlName="cmd_1493" value="0">No</label>
              <div class="formLabel col-md-11"
                *ngIf="cherokeeHealthForm.controls['cmd_1493'].touched && cherokeeHealthForm.controls['cmd_1493'].invalid">
                <small class="text-danger" *ngIf="cherokeeHealthForm.controls['cmd_1493'].errors?.required">
                    required
                </small>
              </div>
              <span *ngIf="(cherokeeHealthForm.controls['cmd_1493'].touched && cherokeeHealthForm.controls['cmd_1493'].errors) || (cherokeeHealthForm.controls['cmd_1493'].errors && isSubmit)">
                <i class="fas fa-times ml-2 text-danger"></i>
              </span>
          </div>
        </div>


        <div class="row" style="padding-left:20px">
          <div class="form-group col-md-9">
            <label style="font-weight: normal;font-size: 14px">E. Have any adverse actions been filed against you by
              Medicare or Medicaid?
            </label>
          </div>
          <div class="col-md-3">
            <label class="checkbox-inline">
              <input type="radio" id="cmd_1494" name="cmd_1494" [class.is-invalid]="(cherokeeHealthForm.controls['cmd_1494'].touched && cherokeeHealthForm.controls['cmd_1494'].invalid) || (cherokeeHealthForm.controls['cmd_1494'].invalid && isSubmit)" formControlName="cmd_1494" value="1">Yes</label>
              <label class="checkbox-inline">
              <input type="radio" id="cmd_1494_1" name="cmd_1494" [class.is-invalid]="(cherokeeHealthForm.controls['cmd_1494'].touched && cherokeeHealthForm.controls['cmd_1494'].invalid) || (cherokeeHealthForm.controls['cmd_1494'].invalid && isSubmit)" formControlName="cmd_1494" value="0">No</label>
              <div class="formLabel col-md-11"
                *ngIf="cherokeeHealthForm.controls['cmd_1494'].touched && cherokeeHealthForm.controls['cmd_1494'].invalid">
                <small class="text-danger" *ngIf="cherokeeHealthForm.controls['cmd_1494'].errors?.required">
                    required
                </small>
              </div>
              <span *ngIf="(cherokeeHealthForm.controls['cmd_1494'].touched && cherokeeHealthForm.controls['cmd_1494'].errors) || (cherokeeHealthForm.controls['cmd_1494'].errors && isSubmit)">
                <i class="fas fa-times ml-2 text-danger"></i>
              </span>
          </div>
        </div>


        <div class="row" style="padding-left:20px">
          <div class="form-group col-md-9">
            <label style="font-weight: normal;font-size: 14px">F. Have you ever been sanctioned by a professional
              association for ethical violations?
            </label>
          </div>
          <div class="col-md-3">
            <label class="checkbox-inline">
              <input type="radio" id="cmd_1495" name="cmd_1495" [class.is-invalid]="(cherokeeHealthForm.controls['cmd_1495'].touched && cherokeeHealthForm.controls['cmd_1495'].invalid) || (cherokeeHealthForm.controls['cmd_1495'].invalid && isSubmit)" formControlName="cmd_1495" value="1">Yes</label>
              <label class="checkbox-inline">
              <input type="radio" id="cmd_1495_1" name="cmd_1495" [class.is-invalid]="(cherokeeHealthForm.controls['cmd_1495'].touched && cherokeeHealthForm.controls['cmd_1495'].invalid) || (cherokeeHealthForm.controls['cmd_1495'].invalid && isSubmit)" formControlName="cmd_1495" value="0">No</label>
              <div class="formLabel col-md-11"
                *ngIf="cherokeeHealthForm.controls['cmd_1495'].touched && cherokeeHealthForm.controls['cmd_1495'].invalid">
                <small class="text-danger" *ngIf="cherokeeHealthForm.controls['cmd_1495'].errors?.required">
                    required
                </small>
              </div>
              <span *ngIf="(cherokeeHealthForm.controls['cmd_1495'].touched && cherokeeHealthForm.controls['cmd_1495'].errors) || (cherokeeHealthForm.controls['cmd_1495'].errors && isSubmit)">
                <i class="fas fa-times ml-2 text-danger"></i>
              </span>
          </div>
        </div>


        <div class="row" style="padding-left:20px">
          <div class="form-group col-md-9">
            <label style="font-weight: normal;font-size: 14px">G. Are you presently using illegal drugs?
            </label>
          </div>
          <div class="col-md-3">
            <label class="checkbox-inline">
              <input type="radio" id="cmd_1496" name="cmd_1496" [class.is-invalid]="(cherokeeHealthForm.controls['cmd_1496'].touched && cherokeeHealthForm.controls['cmd_1496'].invalid) || (cherokeeHealthForm.controls['cmd_1496'].invalid && isSubmit)" formControlName="cmd_1496" value="1">Yes</label>
              <label class="checkbox-inline">
              <input type="radio" id="cmd_1496_1" name="cmd_1496" [class.is-invalid]="(cherokeeHealthForm.controls['cmd_1496'].touched && cherokeeHealthForm.controls['cmd_1496'].invalid) || (cherokeeHealthForm.controls['cmd_1496'].invalid && isSubmit)" formControlName="cmd_1496" value="0">No</label>
              <div class="formLabel col-md-11"
                *ngIf="cherokeeHealthForm.controls['cmd_1496'].touched && cherokeeHealthForm.controls['cmd_1496'].invalid">
                <small class="text-danger" *ngIf="cherokeeHealthForm.controls['cmd_1496'].errors?.required">
                    required
                </small>
              </div>
              <span *ngIf="(cherokeeHealthForm.controls['cmd_1496'].touched && cherokeeHealthForm.controls['cmd_1496'].errors) || (cherokeeHealthForm.controls['cmd_1496'].errors && isSubmit)">
                <i class="fas fa-times ml-2 text-danger"></i>
              </span>
          </div>
        </div>


        <div class="row" style="padding-left:20px">
          <div class="form-group col-md-9">
            <label style="font-weight: normal;font-size: 14px">H. <b>PLEASE READ CAREFULLY:</b> Are you able to perform
              the essential functions of a Cherokee Health Systems provider such as seeing patients in a timely manner,
              complying with Cherokee Health Systems policies and procedures, rendering competent quality care to
              patients, with or without accommodations? If no, please explain in an attachment.
            </label>
          </div>
          <div class="col-md-3">
            <label class="checkbox-inline">
              <input type="radio" id="cmd_1497" name="cmd_1497" [class.is-invalid]="(cherokeeHealthForm.controls['cmd_1497'].touched && cherokeeHealthForm.controls['cmd_1497'].invalid) || (cherokeeHealthForm.controls['cmd_1497'].invalid && isSubmit)" formControlName="cmd_1497" value="1">Yes</label>
              <label class="checkbox-inline">
              <input type="radio" id="cmd_1497_1" name="cmd_1497" [class.is-invalid]="(cherokeeHealthForm.controls['cmd_1497'].touched && cherokeeHealthForm.controls['cmd_1497'].invalid) || (cherokeeHealthForm.controls['cmd_1497'].invalid && isSubmit)" formControlName="cmd_1497" value="0">No</label>
              <div class="formLabel col-md-11"
                *ngIf="cherokeeHealthForm.controls['cmd_1497'].touched && cherokeeHealthForm.controls['cmd_1497'].invalid">
                <small class="text-danger" *ngIf="cherokeeHealthForm.controls['cmd_1497'].errors?.required">
                    required
                </small>
              </div>
              <span *ngIf="(cherokeeHealthForm.controls['cmd_1497'].touched && cherokeeHealthForm.controls['cmd_1497'].errors) || (cherokeeHealthForm.controls['cmd_1497'].errors && isSubmit)">
                <i class="fas fa-times ml-2 text-danger"></i>
              </span>
          </div>
        </div>


        <div class="row" style="padding-left:10px">
          <label style="font-weight: normal;font-style:italic;font-size: 14px">12. Voluntary Information - Patients
            often express preferences for therapists of a particular ethnic background or gender.Therefore, your
            completion <br>of the information below will allow us to more readily meet these patient's needs when a
            referral is requested. If you volunteer ta provide the following information, it will be used only when a
            patient Indicates such information is important in selecting a provider and it will be held in strictest
            confidence. The infonnation wi/1 not be released to any other party, except in aggregate form.
          </label>

        </div>


        <div class="row">
          <div class="form-group col-md-3">
            <label style="font-weight: normal;font-size: 14px">Gender:
            </label>
          </div>
          <div class="col-md-6">
            <label class="checkbox-inline">
              <input type="radio" id="cmd_1498" name="cmd_1498" formControlName="cmd_1498" value="1">Male</label>
            <label class="checkbox-inline">
              <input type="radio" id="cmd_1498_1" name="cmd_1498" formControlName="cmd_1498" value="0">Female</label>
          </div>
        </div>


        <div class="row" style="padding-left:20px">
          <div class="form-group col-md-1">
            <label style="font-weight: normal;font-size: 14px">Ethnic Background:
            </label>
          </div>
          <div class="col-md-1" style="padding-left: 30px">
            <label class="checkbox-inline" style="padding-top:0px">
              <input type="checkbox" id="cmd_1499" name="cmd_1499" formControlName="cmd_1499" value="1">Black/African
              American</label>
          </div>

          <div class="col-md-2" style="padding-left: 80px">
            <label class="checkbox-inline" style="padding-top:0px">
              <input type="checkbox" id="cmd_1500" name="cmd_1500" formControlName="cmd_1500"
                value="1">Hispanic/Latino</label>
          </div>
          <div class="col-md-2" style="padding-left:80px">
            <label class="checkbox-inline" style="padding-top:0px">
              <input type="checkbox" id="cmd_1501" name="cmd_1501" formControlName="cmd_1501" value="1">Asian/Pacific
              Islander</label>
          </div>
          <div class="col-md-2" style="padding-left:80px">
            <label class="checkbox-inline" style="padding-top:0px">
              <input type="checkbox" id="cmd_1502" name="cmd_1502" formControlName="cmd_1502" value="1">American
              Indian/Alaska Native American</label>
          </div>
          <div class="col-md-2" style="padding-left:60px">
            <label class="checkbox-inline" style="padding-top:0px">
              <input type="checkbox" id="cmd_1503" name="cmd_1503" formControlName="cmd_1503"
                value="1">Caucasian</label>
          </div>
          <div class="col-md-1">
            <label class="checkbox-inline" style="padding-top:0px">
              <input type="checkbox" id="cmd_1504" name="cmd_1504" formControlName="cmd_1504" value="1">Other</label>
          </div>
        </div>
        <hr
          style="border: 17px solid #818181; margin-left: -87px;margin-right: -87px; margin-top: 50px; margin-bottom: 50px;">

        <div class="centered" style="text-align:center;">
          <h2>Cherokee Health Systems</h2>
        </div>
        <div class="centered" style="text-align:center;">
          <h2>Credentialing/Recredentialing Application</h2>
        </div>

        <br>

        <div class="row" style="padding-left:20px">
          <label style="font-style:italic;font-size: 14px"><b>13. Work History - Chronologically list all previous and
            present work history (beginning with the most recent) related to clinical practice within the <br>past five
            years. Please do not refer to your curriculum vitae or resume; the information requested must be
            submitted on this form. Please include month and year.</b> </label>

        </div>

        <br>
        <table  class="dataTable" style="width:100%;padding-left:120px;">
          <tbody>
            <tr>
              <th>Facility/Practice Name </th>
              <th>Title/Position </th>
              <th>Address </th>
              <th>From(mo/yr)</th>
              <th>To(mo/yr)</th>
            </tr>
            <tr>
              <td style=" border: 1px solid black;text-align: center;padding-top:50px">

                <input class="form-control  " id="445" name="445" formControlName="445" type="text"
                  placeholder="Work Hist 1 name">


              </td>
              <td style=" border: 1px solid black;padding-top: 50px">

                <input class="form-control  " id="1326" name="1326" formControlName="1326" type="text"
                  placeholder="Work Hist 1 title">


              </td>
              <td style=" border: 1px solid black;padding-top:20px">
                <div style="padding-top: 5px">
                  <input class="form-control  " id="446" name="446" formControlName="446" type="text"
                    placeholder="Work Hist 1 add1">
                </div>
                <div style="padding-top: 5px">
                  <input class="form-control  " id="447" name="447" formControlName="447" type="text"
                    placeholder="Work Hist 1 add2">
                </div>
                <div style="padding-top: 5px">
                  <input class="form-control  " id="448" name="448" formControlName="448" type="text"
                    placeholder="Work Hist 1 city">
                </div>
                <div style="padding-left:16px;padding-top:10px">
                  <ng-select [virtualScroll]="true" [items]="stateDropDownDate" bindLabel="name" placeholder="Work Hist 1 State" bindValue="id"
                    formControlName="450">
                  </ng-select>
                </div>
                <div style="padding-top: 5px">
                  <input class="form-control  " id="451" name="451" formControlName="451" type="text"
                    placeholder="Work Hist 1 Zip">

                </div>

              </td>
              <td style=" border: 1px solid black;padding-top: 50px">
                <div class="input-group">
                  <input class="form-control" formControlName="454" (blur)="clearInputIfInvalid('454')" name="dp" placeholder="Work Hist 1 Start"
                    ngbDatepicker #d36="ngbDatepicker" placement="bottom">
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d36.toggle()" type="button"><i
                        class="fas fa-calendar-alt"></i></button>
                  </div>
                 
                </div>
                <div  *ngIf="cherokeeHealthForm.get('454').invalid ">
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('454').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('454').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('454').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="cherokeeHealthForm.get('454').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </td>
              <td style=" border: 1px solid black;padding-top: 50px">
                <div class="input-group">
                  <input class="form-control" formControlName="455" (blur)="clearInputIfInvalid('455')" name="dp" placeholder="Work Hist 1 End"
                    ngbDatepicker #d37="ngbDatepicker" placement="bottom">
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d37.toggle()" type="button"><i
                        class="fas fa-calendar-alt"></i></button>
                  </div>
                </div>
                <div  *ngIf="cherokeeHealthForm.get('455').invalid ">
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('455').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('455').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('455').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="cherokeeHealthForm.get('455').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </td>
            </tr>
            <tr>
              <td style=" border: 1px solid black;text-align: center;padding-top:50px">

                <input class="form-control" id="456" name="456" formControlName="456" type="text"
                  placeholder="Work Hist 2 name">


              </td>
              <td style=" border: 1px solid black;padding-top: 50px">

                <input class="form-control" id="1343" name="1343" formControlName="1343" type="text"
                  placeholder="Work Hist 2 title">
              </td>

              <td style=" border: 1px solid black;padding-top:20px">
                <div style="padding-top: 5px">
                  <input class="form-control  " id="457" name="457" formControlName="457" type="text"
                    placeholder="Work Hist 2 add1">
                </div>
                <div style="padding-top: 5px">
                  <input class="form-control  " id="458" name="458" formControlName="458" type="text"
                    placeholder="Work Hist 2 add2">
                </div>
                <div style="padding-top: 5px">
                  <input class="form-control  " id="459" name="459" formControlName="459" type="text"
                    placeholder="Work Hist 2 city">
                </div>
                <div style="padding-left:16px;padding-top:10px">
                  <ng-select [virtualScroll]="true" [items]="stateDropDownDate" bindLabel="name" placeholder="Work Hist 2 State" bindValue="id"
                    formControlName="461">
                  </ng-select>
                </div>
                <div style="padding-top: 5px">
                  <input class="form-control  " id="462" name="462" formControlName="462" type="text"
                    placeholder="Work Hist 2 Zip">

                </div>

              </td>
              <td style=" border: 1px solid black;padding-top: 50px">
                <div class="input-group">
                  <input class="form-control" formControlName="465" (blur)="clearInputIfInvalid('465')" name="dp" placeholder="Work Hist 2 Start"
                    ngbDatepicker #d38="ngbDatepicker" placement="bottom">
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d38.toggle()" type="button"><i
                        class="fas fa-calendar-alt"></i></button>
                  </div>
                  
                </div>
                <div  *ngIf="cherokeeHealthForm.get('465').invalid ">
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('465').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('465').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('465').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="cherokeeHealthForm.get('465').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </td>
              <td style=" border: 1px solid black;padding-top: 50px">
                <div class="input-group">
                  <input class="form-control" formControlName="466" (blur)="clearInputIfInvalid('466')" name="dp" placeholder="Work Hist 2 End" ngbDatepicker
                    #d39="ngbDatepicker" placement="bottom">
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d39.toggle()" type="button"><i
                        class="fas fa-calendar-alt"></i></button>
                  </div>
                </div>
                <div  *ngIf="cherokeeHealthForm.get('466').invalid ">
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('466').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('466').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('466').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="cherokeeHealthForm.get('466').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </td>
            </tr>
            <tr>
              <td style=" border: 1px solid black;text-align: center;padding-top:50px">

                <input class="form-control" id="469" name="469" type="text" formControlName="469"
                  placeholder="Work Hist 3 name">
              </td>
              <td style=" border: 1px solid black;padding-top: 50px">

                <input class="form-control" id="1339" name="1339" formControlName="1339" type="text"
                  placeholder="Work Hist 3 title">


              </td>
              <td style=" border: 1px solid black;padding-top:20px">
                <div style="padding-top: 5px">
                  <input class="form-control  " id="468" name="468" formControlName="468" type="text"
                    placeholder="Work Hist 3 Add1">
                </div>
                <div style="padding-top: 5px">
                  <input class="form-control  " id="469_1" name="469" formControlName="469" type="text"
                    placeholder="Work Hist 3 Add2">
                </div>
                <div style="padding-top: 5px">
                  <input class="form-control  " id="470" name="470" formControlName="470" type="text"
                    placeholder="Work Hist 3 City">
                </div>
                <div style="padding-left:16px;padding-top:5px">
                  <ng-select [virtualScroll]="true" [items]="stateDropDownDate" bindLabel="name" placeholder="Work Hist 3 State" bindValue="id"
                    formControlName="472">
                  </ng-select>
                </div>
                <div style="padding-top: 5px">
                  <input class="form-control  " id="473" name="473" formControlName="473" type="text"
                    placeholder="Work Hist 3 Zip">

                </div>

              </td>
              <td style=" border: 1px solid black;padding-top: 50px">
                <div class="input-group">
                  <input class="form-control" formControlName="476" (blur)="clearInputIfInvalid('476')" name="dp" placeholder="Work Hist 3 Start"
                    ngbDatepicker #d40="ngbDatepicker" placement="bottom">
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d40.toggle()" type="button"><i
                        class="fas fa-calendar-alt"></i></button>
                  </div>
                </div>
                <div  *ngIf="cherokeeHealthForm.get('476').invalid ">
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('476').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('476').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('476').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="cherokeeHealthForm.get('476').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </td>
              <td style=" border: 1px solid black;padding-top: 50px">
                <div class="input-group">
                  <input class="form-control" formControlName="477" (blur)="clearInputIfInvalid('477')" name="dp" placeholder="Work Hist 3 End"
                    ngbDatepicker #d41="ngbDatepicker" placement="bottom">
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d41.toggle()" type="button"><i
                        class="fas fa-calendar-alt"></i></button>
                  </div>
                </div>
                <div  *ngIf="cherokeeHealthForm.get('477').invalid ">
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('477').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('477').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('477').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="cherokeeHealthForm.get('477').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </td>
            </tr>
            <tr>
              <td style=" border: 1px solid black;text-align: center;padding-top:50px">

                <input class="form-control  " id="1935" name="1935" formControlName="1935" type="text"
                  placeholder="Work Hist 4 name">


              </td>
              <td style=" border: 1px solid black;padding-top: 50px">

                <input class="form-control  " id="1949" name="1949" formControlName="1949" type="text"
                  placeholder="Work Hist 4 title">


              </td>
              <td >
                <div style="padding-top: 5px">
                  <input class="form-control  " id="1936" name="1936" formControlName="1936" type="text"
                    placeholder="Work Hist 4 Add1">
                </div>
                <div style="padding-top: 5px">
                  <input class="form-control  " id="1937" name="1937" formControlName="1937" type="text"
                    placeholder="Work Hist 4 Add2">
                </div>
                <div style="padding-top: 5px">
                  <input class="form-control  " id="1938" name="1938" formControlName="1938" type="text"
                    placeholder="Work Hist 4 City">
                </div>
                <div style="padding-left:16px;padding-top:5px">
                  <ng-select [virtualScroll]="true" [items]="stateDropDownDate" bindLabel="name" placeholder="Work Hist 4 State" bindValue="id"
                    formControlName="1940">
                  </ng-select>
                </div>
                <div style="padding-top: 5px">
                  <input class="form-control  " id="1941" name="1941" formControlName="1941" type="text"
                    placeholder="Work Hist 4 Zip">

                </div>

              </td>
              <td style=" border: 1px solid black;padding-top: 50px">
                <div class="input-group">
                  <input class="form-control" formControlName="1947" (blur)="clearInputIfInvalid('1947')" name="dp" placeholder="Work Hist 4 Start"
                    ngbDatepicker #d42="ngbDatepicker" placement="bottom">
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d42.toggle()" type="button"><i
                        class="fas fa-calendar-alt"></i></button>
                  </div>
                </div>
                <div  *ngIf="cherokeeHealthForm.get('1947').invalid ">
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('1947').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('1947').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('1947').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="cherokeeHealthForm.get('1947').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </td>
              <td style=" border: 1px solid black;padding-top: 50px">
                <div class="input-group">
                  <input class="form-control" formControlName="1948" (blur)="clearInputIfInvalid('1948')" name="dp" placeholder="Work Hist 4 End"
                    ngbDatepicker #d43="ngbDatepicker" placement="bottom">
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d43.toggle()" type="button"><i
                        class="fas fa-calendar-alt"></i></button>
                  </div>
                </div>
                <div  *ngIf="cherokeeHealthForm.get('1948').invalid ">
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('1948').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('1948').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('1948').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="cherokeeHealthForm.get('1948').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </td>
            </tr>
            <tr>
              <td style=" border: 1px solid black;text-align: center;padding-top:50px">

                <input class="form-control  " id="cmd_1622" name="cmd_1622" formControlName="cmd_1622" type="text"
                  placeholder="Additional Work Hist  name">


              </td>
              <td style=" border: 1px solid black;padding-top: 50px">

                <input class="form-control  " id="cmd_1623" name="cmd_1623" formControlName="cmd_1623" type="text"
                  placeholder="Additional Work Hist  title">


              </td>
              <td  style=" border: 1px solid black;text-align: center;padding-top:50px">
                <div style="padding-top: 5px">
                  <input class="form-control  " id="cmd_1624" name="cmd_1624" formControlName="cmd_1624" type="text"
                    placeholder="Additional Work Hist Add1">
                </div>
                <div style="padding-top: 5px">
                  <input class="form-control  " id="cmd_1625" name="cmd_1625" formControlName="cmd_1625" type="text"
                    placeholder="Additional Work Hist Add2">
                </div>
                <div style="padding-top: 5px">
                  <input class="form-control" id="cmd_1626" name="cmd_1626" formControlName="cmd_1626" type="text"
                    placeholder="Additional Work Hist City">
                </div>
                <div style="padding-left:16px;padding-top:5px">
                  <ng-select [virtualScroll]="true" [items]="stateDropDownDate" bindLabel="name" placeholder="Additional Work Hist State"
                    bindValue="id" formControlName="cmd_1627">
                  </ng-select>
                </div>
                <div style="padding-top: 5px">
                  <input class="form-control  " id="cmd_1628" name="cmd_1628" formControlName="cmd_1628" type="text"
                    placeholder="Additional Work Hist  Zip">

                </div>

              </td>
              <td style=" border: 1px solid black;padding-top: 50px">
                <div class="input-group">
                  <input class="form-control" formControlName="cmd_1629" (blur)="clearInputIfInvalid('cmd_1629')" name="dp"
                    placeholder="Additional Work Hist Start" ngbDatepicker #d44="ngbDatepicker" placement="bottom">
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d44.toggle()" type="button"><i
                        class="fas fa-calendar-alt"></i></button>
                  </div>
                </div>
                <div  *ngIf="cherokeeHealthForm.get('cmd_1629').invalid ">
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1629').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1629').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1629').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1629').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </td>
              <td style=" border: 1px solid black;padding-top: 50px">
                <div class="input-group">
                  <input class="form-control" formControlName="cmd_1630" (blur)="clearInputIfInvalid('cmd_1630')" name="dp"
                    placeholder="Additional Work Hist End" ngbDatepicker #d45="ngbDatepicker" placement="bottom">
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d45.toggle()" type="button"><i
                        class="fas fa-calendar-alt"></i></button>
                  </div>
                </div>
                <div  *ngIf="cherokeeHealthForm.get('cmd_1630').invalid ">
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1630').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1630').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1630').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1630').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </td>
            </tr>

            <tr>
              <td style=" border: 1px solid black;text-align: center;padding-top:50px">

                <input class="form-control  " id="cmd_1631" name="cmd_1631" formControlName="cmd_1631" type="text"
                  placeholder="Additional Work Hist  name">


              </td>
              <td style=" border: 1px solid black;padding-top: 50px">

                <input class="form-control  " id="cmd_1632" name="cmd_1632" formControlName="cmd_1632" type="text"
                  placeholder="Additional Work Hist  title">


              </td>
              <td  style=" border: 1px solid black;text-align: center;padding-top:50px">
                <div style="padding-top: 5px">
                  <input class="form-control  " id="cmd_1633" name="cmd_1633" formControlName="cmd_1633" type="text"
                    placeholder="Additional Work Hist Add1">
                </div>
                <div style="padding-top: 5px">
                  <input class="form-control  " id="cmd_1634" name="cmd_1634" formControlName="cmd_1634" type="text"
                    placeholder="Additional Work Hist Add2">
                </div>
                <div style="padding-top: 5px">
                  <input class="form-control  " id="cmd_1635" name="cmd_1635" formControlName="cmd_1635" type="text"
                    placeholder="Additional Work Hist City">
                </div>
                <div style="padding-left:16px;padding-top:5px">
                  <ng-select [virtualScroll]="true" [items]="stateDropDownDate" bindLabel="name" placeholder="Additional Work Hist State"
                    bindValue="id" formControlName="cmd_1636">
                  </ng-select>
                </div>
                <div style="padding-top: 5px">
                  <input class="form-control  " id="cmd_1637" name="cmd_1637" type="text" formControlName="cmd_1637"
                    placeholder="Additional Work Hist  Zip">

                </div>

              </td>
              <td style=" border: 1px solid black;padding-top: 50px">
                <div class="input-group">
                  <input class="form-control" formControlName="cmd_1638" (blur)="clearInputIfInvalid('cmd_1638')"  name="dp"
                    placeholder="Additional Work Hist Start" ngbDatepicker #d46="ngbDatepicker" placement="bottom">
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d46.toggle()" type="button"><i
                        class="fas fa-calendar-alt"></i></button>
                  </div>
                </div>
                <div  *ngIf="cherokeeHealthForm.get('cmd_1638').invalid ">
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1638').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1638').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1638').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1638').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>

              </td>
              <td style=" border: 1px solid black;padding-top: 50px">
                <div class="input-group">
                  <input class="form-control" formControlName="cmd_1639" (blur)="clearInputIfInvalid('cmd_1639')" name="dp"
                    placeholder="Additional Work Hist End" ngbDatepicker #d47="ngbDatepicker" placement="bottom">
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d47.toggle()" type="button"><i
                        class="fas fa-calendar-alt"></i></button>
                  </div>
                </div>
                <div  *ngIf="cherokeeHealthForm.get('cmd_1639').invalid ">
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1639').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1639').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1639').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1639').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </td>
            </tr>
            <tr>
              <td style=" border: 1px solid black;text-align: center;padding-top:50px">

                <input class="form-control  " id="cmd_1640" name="cmd_1640" formControlName="cmd_1640" type="text"
                  placeholder="Additional Work Hist  name">


              </td>
              <td style=" border: 1px solid black;padding-top: 50px">

                <input class="form-control  " id="cmd_1641" name="cmd_1641" formControlName="cmd_1641" type="text"
                  placeholder="Additional Work Hist  title">


              </td>
              <td  style=" border: 1px solid black;text-align: center;padding-top:50px">
                <div style="padding-top: 5px">
                  <input class="form-control  " id="cmd_1642" name="cmd_1642" type="text" formControlName="cmd_1642"
                    placeholder="Additional Work Hist Add1">
                </div>
                <div style="padding-top: 5px">
                  <input class="form-control  " id="cmd_1643" name="cmd_1643" type="text" formControlName="cmd_1643"
                    placeholder="Additional Work Hist Add2">
                </div>
                <div style="padding-top: 5px">
                  <input class="form-control  " id="cmd_1644" name="cmd_1644" type="text" formControlName="cmd_1644"
                    placeholder="Additional Work Hist City">
                </div>
                <div style="padding-left:16px;padding-top:5px">
                  <ng-select [virtualScroll]="true" [items]="stateDropDownDate" bindLabel="name" placeholder="Additional Work Hist State"
                    bindValue="id" formControlName="cmd_1645">
                  </ng-select>
                </div>
                <div style="padding-top: 5px">
                  <input class="form-control  " id="cmd_1646" name="cmd_1646" type="text" formControlName="cmd_1646"
                    placeholder="Additional Work Hist  Zip">

                </div>

              </td>
              <td style=" border: 1px solid black;padding-top: 50px">
                <div class="input-group">
                  <input class="form-control" formControlName="cmd_1647" (blur)="clearInputIfInvalid('cmd_1647')" name="dp"
                    placeholder="Additional Work Hist  Start" ngbDatepicker #d48="ngbDatepicker" placement="bottom">
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d48.toggle()" type="button"><i
                        class="fas fa-calendar-alt"></i></button>
                  </div>
                </div>
                <div  *ngIf="cherokeeHealthForm.get('cmd_1647').invalid ">
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1647').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1647').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1647').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1647').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </td>
              <td style=" border: 1px solid black;padding-top: 50px">
                <div class="input-group">
                  <input class="form-control" formControlName="cmd_1648" (blur)="clearInputIfInvalid('cmd_1648')" name="dp"
                    placeholder="Additional Work Hist  End" ngbDatepicker #d49="ngbDatepicker" placement="bottom">
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d49.toggle()" type="button"><i
                        class="fas fa-calendar-alt"></i></button>
                  </div>
                </div>
                <div  *ngIf="cherokeeHealthForm.get('cmd_1648').invalid ">
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1648').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1648').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1648').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1648').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </td>
            </tr>
            <tr>
              <td style=" border: 1px solid black;text-align: center;padding-top:50px">
                <input class="form-control  " id="cmd_1649" name="cmd_1649" formControlName="cmd_1649" type="text"
                  placeholder="Additional Work Hist  name">
              </td>
              <td style=" border: 1px solid black;padding-top: 50px">
                <input class="form-control  " id="cmd_1650" name="cmd_1650" formControlName="cmd_1650" type="text"
                  placeholder="Additional Work Hist  title">
              </td>
              <td  style=" border: 1px solid black;text-align: center;padding-top:50px">
                <div style="padding-top: 5px">
                  <input class="form-control  " id="cmd_1651" name="cmd_1651" formControlName="cmd_1651" type="text"
                    placeholder="Additional Work Hist Add1">
                </div>
                <div style="padding-top: 5px">
                  <input class="form-control  " id="cmd_1652" name="cmd_1652" formControlName="cmd_1652" type="text"
                    placeholder="Additional Work Hist Add2">
                </div>
                <div style="padding-top: 5px">
                  <input class="form-control  " id="cmd_1653" name="cmd_1653" formControlName="cmd_1653" type="text"
                    placeholder="Additional Work Hist City">
                </div>
                <div style="padding-left:16px;padding-top:5px">
                  <ng-select [virtualScroll]="true" [items]="stateDropDownDate" bindLabel="name" placeholder="Additional Work Hist State"
                    bindValue="id" formControlName="cmd_1654">
                  </ng-select>
                </div>
                <div style="padding-top: 5px">
                  <input class="form-control  " id="cmd_1655" name="cmd_1655" formControlName="cmd_1655" type="text"
                    placeholder="Additional Work Hist  Zip">

                </div>

              </td>
              <td style=" border: 1px solid black;padding-top: 50px">
                <div class="input-group">
                  <input class="form-control" formControlName="cmd_1656" (blur)="clearInputIfInvalid('cmd_1656')" name="dp"
                    placeholder="Additional Work Hist  Start" ngbDatepicker #d50="ngbDatepicker" placement="bottom">
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d50.toggle()" type="button"><i
                        class="fas fa-calendar-alt"></i></button>
                  </div>
                </div>
                <div  *ngIf="cherokeeHealthForm.get('cmd_1656').invalid ">
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1656').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1656').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1656').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1656').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </td>
              <td style=" border: 1px solid black;padding-top: 50px">
                <div class="input-group">
                  <input class="form-control" formControlName="cmd_1657" (blur)="clearInputIfInvalid('cmd_1657')" name="dp"
                    placeholder="Additional Work Hist End" ngbDatepicker #d51="ngbDatepicker" placement="bottom">
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d51.toggle()" type="button"><i
                        class="fas fa-calendar-alt"></i></button>
                  </div>
                </div>
                <div  *ngIf="cherokeeHealthForm.get('cmd_1657').invalid ">
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1657').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1657').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1657').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1657').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </td>
            </tr>
          </tbody>
        </table>
        <br>

        <div class="row" style="padding-left:20px">
          <label style="font-size: 14px">I hereby certify that all information in this application and the copies of my
            state license(s), certificate(s) of insurance,and professional certificate(s) are correct and complete.
          </label>

        </div>

        <br>

        <div class="row" style="margin-left: 2rem">

          <div class="col-md-2" style="text-align: center;">
            <label style="font-weight: normal;font-size: 14px;text-align: center;">⁭ Signature</label>
          </div>

          <div class="col-md-4" style="text-align: left;">
            <!-- <img *ngIf="!showOne" class="img" src="./assets/images/e_sign.png" alt="Credential my Doc"
              (click)="esignOpen(1)">
            <img *ngIf="showOne" class="img eSignImageShow" [src]="esignOne" alt="Credential my Doc"
              (click)="esignOpen(1)">
            <input type="hidden" name="cmd_1662" id="cmd_1662" formControlName="cmd_1662" value={{finalImgPathOne}}> -->
            <img *ngIf="!showOne" class="img" src="./assets/images/e_sign.png" alt="Credential my Doc" (click)="esignOpenOne()">
            <img  *ngIf="showOne" class="img eSignImageShow" [src]="esignOne"  alt="Credential my Doc" (click)="esignOpenOne()">
            <input type="hidden" name="cmd_1662" id="cmd_1662" formControlName="cmd_1662">
          </div>
          <div class="col-md-1" style="padding-left:20px">
            <label style="font-weight: normal;font-size: 14px;">Date</label>
          </div>
          <div class="col-md-4">
            <div class="input-group">
              <input class="form-control" formControlName="cmd_1663" (blur)="clearInputIfInvalid('cmd_1663')" name="dp" placeholder="Date" ngbDatepicker
                #d99="ngbDatepicker" placement="bottom">
              <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d99.toggle()" type="button"><i
                    class="fas fa-calendar-alt"></i></button>
              </div>
            </div>
            <div  *ngIf="cherokeeHealthForm.get('cmd_1663').invalid ">
              <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1663').errors?.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY)
              </small>
              <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1663').errors?.invalidMonth">
              Month is out of range
              </small>
              <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1663').errors?.invalidDateyear">
                year is out of range(1900-2099)                      </small>
                <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1663').errors?.invalidDateRange">
                  date is out of range
                  </small>
              </div>
          </div>
        </div>
        <br>
        <div class="row" style="padding-left: 20px;">
          <div class="col-md-2">
            <label style="font-weight: normal;font-size: 14px">NPI #:</label>
          </div>
          <div class="col-md-4">
            <input class="form-control " id="1634" name="1634" [class.is-invalid]="(cherokeeHealthForm.controls['1634'].touched && cherokeeHealthForm.controls['1634'].invalid) || (cherokeeHealthForm.controls['1634'].invalid && isSubmit)" formControlName="1634" type="text" placeholder="Provider NPI">
            <div class="formLabel col-md-11"
              *ngIf="cherokeeHealthForm.controls['1634'].touched && cherokeeHealthForm.controls['1634'].invalid">
              <small class="text-danger" *ngIf="cherokeeHealthForm.controls['1634'].errors?.required">
                  required
              </small>
            </div>
          </div>
          <div class="col-md-2">
            <label style="font-weight: normal;font-size: 14px">CAQH #: </label>
          </div>
          <div class="col-md-4">
            <input class="form-control " id="333" name="333" [class.is-invalid]="(cherokeeHealthForm.controls['333'].touched && cherokeeHealthForm.controls['333'].invalid) || (cherokeeHealthForm.controls['333'].invalid && isSubmit)" formControlName="333" type="text" placeholder="CAQH Number">
            <div class="formLabel col-md-11"
                *ngIf="cherokeeHealthForm.controls['333'].touched && cherokeeHealthForm.controls['333'].invalid">
                <small class="text-danger" *ngIf="cherokeeHealthForm.controls['333'].errors?.required">
                    required
                </small>
              </div>
          </div>
        </div>


        <div class="row" style="padding-left: 20px;">
          <div class="col-md-2">
            <label style="font-weight: normal;font-size: 14px">NPI User ID:</label>
          </div>
          <div class="col-md-4">
            <input class="form-control " id="1635" name="1635" type="text" formControlName="1635"
              placeholder="Provider NPI Username">
          </div>
          <div class="col-md-2">
            <label style="font-weight: normal;font-size: 14px">CAQH User ID:</label>
          </div>
          <div class="col-md-4">
            <input class="form-control " id="334" name="334" [class.is-invalid]="(cherokeeHealthForm.controls['334'].touched && cherokeeHealthForm.controls['334'].invalid) || (cherokeeHealthForm.controls['334'].invalid && isSubmit)" formControlName="334" type="text" placeholder="CAQH User name">
            <div class="formLabel col-md-11"
                *ngIf="cherokeeHealthForm.controls['334'].touched && cherokeeHealthForm.controls['334'].invalid">
                <small class="text-danger" *ngIf="cherokeeHealthForm.controls['334'].errors?.required">
                    required
                </small>
              </div>
          </div>
        </div>


        <div class="row" style="padding-left: 20px;">
          <div class="col-md-2">
            <label style="font-weight: normal;font-size: 14px">NPI Password: </label>
          </div>
          <div class="col-md-4">
            <input class="form-control " id="1636" name="1636" type="text" formControlName="1636"
              placeholder="Provider NPI Password">
          </div>
          <div class="col-md-2">
            <label style="font-weight: normal;font-size: 14px">CAQH Password:</label>
          </div>
          <div class="col-md-4">
            <input class="form-control " id="335" name="335" [class.is-invalid]="(cherokeeHealthForm.controls['335'].touched && cherokeeHealthForm.controls['335'].invalid) || (cherokeeHealthForm.controls['335'].invalid && isSubmit)" type="text" formControlName="335"
              placeholder="CAQH Password">
            <div class="formLabel col-md-11"
              *ngIf="cherokeeHealthForm.controls['335'].touched && cherokeeHealthForm.controls['335'].invalid">
              <small class="text-danger" *ngIf="cherokeeHealthForm.controls['335'].errors?.required">
                  required
              </small>
            </div>
          </div>
        </div>
        <div class="row" style="padding-left: 20px;">
          <div class="col-md-3">
            <label style="font-weight: normal;font-size: 14px">Security Question #1: </label>
          </div>
          <div class="col-md-3">
            <input class="form-control " id="cmd_1505" name="cmd_1505" [class.is-invalid]="(cherokeeHealthForm.controls['cmd_1505'].touched && cherokeeHealthForm.controls['cmd_1505'].invalid) || (cherokeeHealthForm.controls['cmd_1505'].invalid && isSubmit)" formControlName="cmd_1505" type="text" placeholder="CAQH security 1 question">
            <div class="formLabel col-md-11"
              *ngIf="cherokeeHealthForm.controls['cmd_1505'].touched && cherokeeHealthForm.controls['cmd_1505'].invalid">
              <small class="text-danger" *ngIf="cherokeeHealthForm.controls['cmd_1505'].errors?.required">
                  required
              </small>
            </div>
          </div>
          <div class="col-md-3">
            <label style="font-weight: normal;font-size: 14px">Security Question Answer#1:</label>
          </div>
          <div class="col-md-3">
            <input class="form-control " id="cmd_1506" name="cmd_1506" type="text" [class.is-invalid]="(cherokeeHealthForm.controls['cmd_1506'].touched && cherokeeHealthForm.controls['cmd_1506'].invalid) || (cherokeeHealthForm.controls['cmd_1506'].invalid && isSubmit)" formControlName="cmd_1506" placeholder="CAQH security 1 answer">
            <div class="formLabel col-md-11"
              *ngIf="cherokeeHealthForm.controls['cmd_1506'].touched && cherokeeHealthForm.controls['cmd_1506'].invalid">
              <small class="text-danger" *ngIf="cherokeeHealthForm.controls['cmd_1506'].errors?.required">
                  required
              </small>
            </div>
          </div>
        </div>


        <div class="row" style="padding-left: 20px;">
          <div class="col-md-3">
            <label style="font-weight: normal;font-size: 14px">Security Question #2: </label>
          </div>
          <div class="col-md-3">
            <input class="form-control " id="cmd_1507" name="cmd_1507" type="text" [class.is-invalid]="(cherokeeHealthForm.controls['cmd_1507'].touched && cherokeeHealthForm.controls['cmd_1507'].invalid) || (cherokeeHealthForm.controls['cmd_1507'].invalid && isSubmit)" formControlName="cmd_1507" placeholder="CAQH security 2 question">
            <div class="formLabel col-md-11"
              *ngIf="cherokeeHealthForm.controls['cmd_1507'].touched && cherokeeHealthForm.controls['cmd_1507'].invalid">
              <small class="text-danger" *ngIf="cherokeeHealthForm.controls['cmd_1507'].errors?.required">
                  required
              </small>
            </div>
          </div>
          <div class="col-md-3">
            <label style="font-weight: normal;font-size: 14px">Security Question Answer #2:</label>
          </div>
          <div class="col-md-3">
            <input class="form-control " id="cmd_1508" name="cmd_1508" type="text" [class.is-invalid]="(cherokeeHealthForm.controls['cmd_1508'].touched && cherokeeHealthForm.controls['cmd_1508'].invalid) || (cherokeeHealthForm.controls['cmd_1508'].invalid && isSubmit)" formControlName="cmd_1508" placeholder="CAQH security 2 answer">
            <div class="formLabel col-md-11"
              *ngIf="cherokeeHealthForm.controls['cmd_1508'].touched && cherokeeHealthForm.controls['cmd_1508'].invalid">
              <small class="text-danger" *ngIf="cherokeeHealthForm.controls['cmd_1508'].errors?.required">
                  required
              </small>
            </div>
          </div>
        </div>
        <div class="row" style="padding-left: 20px;">
          <div class="col-md-3">
            <label style="font-weight: normal;font-size: 14px">Security Question #3: </label>
          </div>
          <div class="col-md-3">
            <input class="form-control " id="cmd_1509" name="cmd_1509" type="text" [class.is-invalid]="(cherokeeHealthForm.controls['cmd_1509'].touched && cherokeeHealthForm.controls['cmd_1509'].invalid) || (cherokeeHealthForm.controls['cmd_1509'].invalid && isSubmit)" formControlName="cmd_1509" placeholder="CAQH security 3 question">
            <div class="formLabel col-md-11"
                *ngIf="cherokeeHealthForm.controls['cmd_1509'].touched && cherokeeHealthForm.controls['cmd_1509'].invalid">
                <small class="text-danger" *ngIf="cherokeeHealthForm.controls['cmd_1509'].errors?.required">
                    required
                </small>
              </div>
          </div>
          <div class="col-md-3">
            <label style="font-weight: normal;font-size: 14px">Security Question Answer #3:</label>
          </div>
          <div class="col-md-3">
            <input class="form-control " id="cmd_1510" name="cmd_1510" type="text" [class.is-invalid]="(cherokeeHealthForm.controls['cmd_1510'].touched && cherokeeHealthForm.controls['cmd_1510'].invalid) || (cherokeeHealthForm.controls['cmd_1510'].invalid && isSubmit)" formControlName="cmd_1510" placeholder="CAQH security 3 answer">
            <div class="formLabel col-md-11"
                *ngIf="cherokeeHealthForm.controls['cmd_1510'].touched && cherokeeHealthForm.controls['cmd_1510'].invalid">
                <small class="text-danger" *ngIf="cherokeeHealthForm.controls['cmd_1510'].errors?.required">
                    required
                </small>
              </div>
          </div>
        </div>
        <hr
          style="border: 17px solid #818181; margin-left: -87px;margin-right: -87px; margin-top: 50px; margin-bottom: 50px;">
        <br><br>

        <div class="centered" style="text-align:center;padding-top:20px">
          <h3><b>Professional References (peer references or higher)</b> </h3>
        </div>




        <div class="centered" style="text-align:center;">
          <h3><b><u>(PLEASE PRINT</u> and please do <u>not</u> list relatives) </b></h3>
        </div>

        <br>

        <div class="centered" style="text-align:center;">

          <span style="background-color: #dddddd;"><b> PLEASE LIST ALL INFORMATION REQUESTED BELOW FOR EACH REFERENCE
            </b></span>

        </div>


        <table class="dataTable" border="1"
          style="margin-left:auto;margin-right:auto;border: 1px solid black; border-collapse: collapse;" width="80%">
          <tbody>
            <tr>
              <td >
                <div class="row">
                <div class="col-md-2">
                  <label style="font-weight: normal;font-size: 20px;"><b>Name:</b></label>
                </div>
                <div class="col-md-10" >
                  <input class="form-control" id="359" name="359" type="text" [class.is-invalid]="(cherokeeHealthForm.controls['359'].touched && cherokeeHealthForm.controls['359'].invalid) || (cherokeeHealthForm.controls['359'].invalid && isSubmit)" formControlName="359" placeholder="ref 1 name" data-id="name">
                  <div class="formLabel col-md-11"
                    *ngIf="cherokeeHealthForm.controls['359'].touched && cherokeeHealthForm.controls['359'].invalid">
                    <small class="text-danger" *ngIf="cherokeeHealthForm.controls['359'].errors?.required">
                        required
                    </small>
                  </div>
                </div>
                </div>
              </td>
            </tr>
            <tr>
              <td >
                <div class="row">
                <div class="col-md-2">
                  <label style="font-weight: normal;font-size: 20px"><b>Title:</b></label>
                </div>
                <div class="col-md-10" >
                  <input class="form-control" id="2722" name="2722" type="text" [class.is-invalid]="(cherokeeHealthForm.controls['2722'].touched && cherokeeHealthForm.controls['2722'].invalid) || (cherokeeHealthForm.controls['2722'].invalid && isSubmit)" formControlName="2722" placeholder="ref 1 Title" data-id="name">
                  <div class="formLabel col-md-11"
                    *ngIf="cherokeeHealthForm.controls['2722'].touched && cherokeeHealthForm.controls['2722'].invalid">
                    <small class="text-danger" *ngIf="cherokeeHealthForm.controls['2722'].errors?.required">
                        required
                    </small>
                  </div>
                </div>
                </div>
              </td>
            </tr>
            <tr>
              <td >
                <div class="row">
                <div class="col-md-2">
                  <label style="font-weight: normal;font-size: 20px"><b>Address:</b></label>
                </div>
                <div class="col-md-10" >
                  <input class="form-control  " id="360" name="360" [class.is-invalid]="(cherokeeHealthForm.controls['360'].touched && cherokeeHealthForm.controls['360'].invalid) || (cherokeeHealthForm.controls['360'].invalid && isSubmit)" formControlName="360" placeholder="ref 1 address 1" type="text">
                  <div class="formLabel col-md-11"
                    *ngIf="cherokeeHealthForm.controls['360'].touched && cherokeeHealthForm.controls['360'].invalid">
                    <small class="text-danger" *ngIf="cherokeeHealthForm.controls['360'].errors?.required">
                        required
                    </small>
                  </div>
                </div>
                </div>
              </td>
            </tr>
            <tr>
              <td >
                <div class="row">
                <div class="col-md-12" >
                  <input class="form-control  " id="361" name="361" [class.is-invalid]="(cherokeeHealthForm.controls['361'].touched && cherokeeHealthForm.controls['361'].invalid) || (cherokeeHealthForm.controls['361'].invalid && isSubmit)" formControlName="361" placeholder="ref 1 address 2" type="text">
                  <div class="formLabel col-md-11"
                    *ngIf="cherokeeHealthForm.controls['361'].touched && cherokeeHealthForm.controls['361'].invalid">
                    <small class="text-danger" *ngIf="cherokeeHealthForm.controls['361'].errors?.required">
                        required
                    </small>
                  </div>
                </div>
                </div>
              </td>
            </tr>
            <tr>
              <td >
                <div class="row">
                <div class="col-md-4">
                  <label style="font-weight: normal;font-size: 20px"><b>City /State/Zip:</b></label>
                </div>
                <div class="col-md-3" >
                  <input class="form-control  " id="362" name="362" [class.is-invalid]="(cherokeeHealthForm.controls['362'].touched && cherokeeHealthForm.controls['362'].invalid) || (cherokeeHealthForm.controls['362'].invalid && isSubmit)" formControlName="362" placeholder="ref 1 city" type="text">
                  <div class="formLabel col-md-11"
                    *ngIf="cherokeeHealthForm.controls['362'].touched && cherokeeHealthForm.controls['362'].invalid">
                    <small class="text-danger" *ngIf="cherokeeHealthForm.controls['362'].errors?.required">
                        required
                    </small>
                  </div>
                </div>

                <div class="col-md-2">
                  <ng-select [virtualScroll]="true" [items]="stateDropDownDate" bindLabel="name" placeholder="ref 1 state" bindValue="id" formControlName="364" [class.is-invalid]="(cherokeeHealthForm.controls['364'].touched && cherokeeHealthForm.controls['364'].invalid) || (cherokeeHealthForm.controls['364'].invalid && isSubmit)">
                  </ng-select>
                  <div class="formLabel col-md-11"
                    *ngIf="cherokeeHealthForm.controls['364'].touched && cherokeeHealthForm.controls['364'].invalid">
                    <small class="text-danger" *ngIf="cherokeeHealthForm.controls['364'].errors?.required">
                        required
                    </small>
                  </div>
                </div>
                <div class="col-md-3" >
                  <input class="form-control  " id="365" name="365" [class.is-invalid]="(cherokeeHealthForm.controls['365'].touched && cherokeeHealthForm.controls['365'].invalid) || (cherokeeHealthForm.controls['365'].invalid && isSubmit)" formControlName="365" placeholder="ref 1 zip" type="text">
                  <div class="formLabel col-md-11"
                    *ngIf="cherokeeHealthForm.controls['365'].touched && cherokeeHealthForm.controls['365'].invalid">
                    <small class="text-danger" *ngIf="cherokeeHealthForm.controls['365'].errors?.required">
                        required
                    </small>
                  </div>
                </div>
                </div>
              </td>
            </tr>
            <tr>
              <td >
                <div class="row">
                <div class="col-md-4">
                  <label style="font-weight: normal;font-size: 20px"><b>Phone Number:</b></label>
                  <label style="font-weight: normal;font-size:17px">(format: xxx-xxx-xxxx)</label>
                </div>
                <div class="col-md-8" >
                  <input class="form-control " id="369" name="369" [class.is-invalid]="(cherokeeHealthForm.controls['369'].touched && cherokeeHealthForm.controls['369'].invalid) || (cherokeeHealthForm.controls['369'].invalid && isSubmit)" formControlName="369" placeholder="ref 1 phone" type="text" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" maxlength="15">
                  <div class="formLabel col-md-11"
                    *ngIf="cherokeeHealthForm.controls['369'].touched && cherokeeHealthForm.controls['369'].invalid">
                    <small class="text-danger" *ngIf="cherokeeHealthForm.controls['369'].errors?.required">
                        required
                    </small>
                  </div>
                </div>
                </div>
              </td>
            </tr>
            <tr>
              <td style="border: 1px solid black;background-color: #dddddd;padding:10px">
              </td>
            </tr>
            <tr>
              <td >
                <div class="row">
                <div class="col-md-2">
                  <label style="font-weight: normal;font-size: 20px;"><b>Name:</b></label>
                </div>
                <div class="col-md-10" >
                  <input class="form-control  " id="372" name="372" type="text" [class.is-invalid]="(cherokeeHealthForm.controls['372'].touched && cherokeeHealthForm.controls['372'].invalid) || (cherokeeHealthForm.controls['372'].invalid && isSubmit)" formControlName="372" placeholder="ref 2 name" data-id="name">
                  <div class="formLabel col-md-11"
                    *ngIf="cherokeeHealthForm.controls['372'].touched && cherokeeHealthForm.controls['372'].invalid">
                    <small class="text-danger" *ngIf="cherokeeHealthForm.controls['372'].errors?.required">
                        required
                    </small>
                  </div>
                </div>
                </div>
              </td>
            </tr>
            <tr>
              <td >
                <div class="row">
                <div class="col-md-2">
                  <label style="font-weight: normal;font-size: 20px"><b>Title:</b></label>
                </div>
                <div class="col-md-10" >
                  <input class="form-control  " id="2723" name="2723" [class.is-invalid]="(cherokeeHealthForm.controls['2723'].touched && cherokeeHealthForm.controls['2723'].invalid) || (cherokeeHealthForm.controls['2723'].invalid && isSubmit)" formControlName="2723" placeholder="ref 2 title" type="text">
                  <div class="formLabel col-md-11"
                    *ngIf="cherokeeHealthForm.controls['2723'].touched && cherokeeHealthForm.controls['2723'].invalid">
                    <small class="text-danger" *ngIf="cherokeeHealthForm.controls['2723'].errors?.required">
                        required
                    </small>
                  </div>
                </div>
                </div>
              </td>
            </tr>
            <tr>
              <td >
                <div class="row">
                <div class="col-md-2">
                  <label style="font-weight: normal;font-size: 20px"><b>Address:</b></label>
                </div>
                <div class="col-md-10" >
                  <input class="form-control  " id="373" name="373" [class.is-invalid]="(cherokeeHealthForm.controls['373'].touched && cherokeeHealthForm.controls['373'].invalid) || (cherokeeHealthForm.controls['373'].invalid && isSubmit)" formControlName="373" placeholder="ref 2 address 1" type="text">
                  <div class="formLabel col-md-11"
                    *ngIf="cherokeeHealthForm.controls['373'].touched && cherokeeHealthForm.controls['373'].invalid">
                    <small class="text-danger" *ngIf="cherokeeHealthForm.controls['373'].errors?.required">
                        required
                    </small>
                  </div>
                </div>
                </div>
              </td>
            </tr>
            <tr>
              <td > <div class="row">
                <div class="col-md-12" >
                  <input class="form-control  " id="374" name="374" [class.is-invalid]="(cherokeeHealthForm.controls['374'].touched && cherokeeHealthForm.controls['374'].invalid) || (cherokeeHealthForm.controls['374'].invalid && isSubmit)" formControlName="374" placeholder="ref 2 address 2" type="text">
                  <div class="formLabel col-md-11"
                    *ngIf="cherokeeHealthForm.controls['374'].touched && cherokeeHealthForm.controls['374'].invalid">
                    <small class="text-danger" *ngIf="cherokeeHealthForm.controls['374'].errors?.required">
                        required
                    </small>
                  </div>
                </div>
                </div>
              </td>
            </tr>
            <tr>
              <td >
                <div class="row">
                <div class="col-md-4">
                  <label style="font-weight: normal;font-size: 20px"><b>City /State/Zip:</b></label>
                </div>
                <div class="col-md-3" >
                  <input class="form-control  " id="375" name="375" [class.is-invalid]="(cherokeeHealthForm.controls['375'].touched && cherokeeHealthForm.controls['375'].invalid) || (cherokeeHealthForm.controls['375'].invalid && isSubmit)" formControlName="375" placeholder="ref 2 city" type="text">
                  <div class="formLabel col-md-11"
                    *ngIf="cherokeeHealthForm.controls['375'].touched && cherokeeHealthForm.controls['375'].invalid">
                    <small class="text-danger" *ngIf="cherokeeHealthForm.controls['375'].errors?.required">
                        required
                    </small>
                  </div>
                </div>
                <div class="col-md-2">
                  <ng-select [virtualScroll]="true" [items]="stateDropDownDate" bindLabel="name" placeholder="ref 2 state" bindValue="id" formControlName="377" [class.is-invalid]="(cherokeeHealthForm.controls['377'].touched && cherokeeHealthForm.controls['377'].invalid) || (cherokeeHealthForm.controls['377'].invalid && isSubmit)">
                  </ng-select>
                  <div class="formLabel col-md-11"
                    *ngIf="cherokeeHealthForm.controls['377'].touched && cherokeeHealthForm.controls['377'].invalid">
                    <small class="text-danger" *ngIf="cherokeeHealthForm.controls['377'].errors?.required">
                        required
                    </small>
                  </div>
                </div>
                <div class="col-md-3" >
                  <input class="form-control  " id="378" name="378" [class.is-invalid]="(cherokeeHealthForm.controls['378'].touched && cherokeeHealthForm.controls['378'].invalid) || (cherokeeHealthForm.controls['378'].invalid && isSubmit)" formControlName="378" placeholder="ref 2 zip" type="text">
                  <div class="formLabel col-md-11"
                    *ngIf="cherokeeHealthForm.controls['378'].touched && cherokeeHealthForm.controls['378'].invalid">
                    <small class="text-danger" *ngIf="cherokeeHealthForm.controls['378'].errors?.required">
                        required
                    </small>
                  </div>
                </div>
                </div>
              </td>
            </tr>
            <tr>
              <td >
                <div class="row">
                <div class="col-md-4">
                  <label style="font-weight: normal;font-size: 20px"><b>Phone Number:</b></label>
                  <label style="font-weight: normal;font-size:17px">(format: xxx-xxx-xxxx)</label>
                </div>
                <div class="col-md-8" >
                  <input class="form-control  " id="381" name="381" [class.is-invalid]="(cherokeeHealthForm.controls['381'].touched && cherokeeHealthForm.controls['381'].invalid) || (cherokeeHealthForm.controls['381'].invalid && isSubmit)" formControlName="381" placeholder="ref 2 phone" type="text" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" maxlength="15">
                  <div class="formLabel col-md-11"
                    *ngIf="cherokeeHealthForm.controls['381'].touched && cherokeeHealthForm.controls['381'].invalid">
                    <small class="text-danger" *ngIf="cherokeeHealthForm.controls['381'].errors?.required">
                        required
                    </small>
                  </div>
                </div>
                </div>
              </td>
            </tr>
            <tr>
              <td style="border: 1px solid black;background-color: #dddddd;padding:10px">
              </td>
            </tr>
            <tr>
              <td >
                <div class="row">
                <div class="col-md-2">
                  <label style="font-weight: normal;font-size: 20px;"><b>Name:</b></label>
                </div>
                <div class="col-md-10" >
                  <input class="form-control  " id="384" name="384" [class.is-invalid]="(cherokeeHealthForm.controls['384'].touched && cherokeeHealthForm.controls['384'].invalid) || (cherokeeHealthForm.controls['384'].invalid && isSubmit)" formControlName="384" type="text" placeholder="ref 3 name" data-id="name">
                  <div class="formLabel col-md-11"
                    *ngIf="cherokeeHealthForm.controls['384'].touched && cherokeeHealthForm.controls['384'].invalid">
                    <small class="text-danger" *ngIf="cherokeeHealthForm.controls['384'].errors?.required">
                        required
                    </small>
                  </div>
                </div>
                </div>
              </td>
            </tr>
            <tr>
              <td >
                <div class="row">
                <div class="col-md-2">
                  <label style="font-weight: normal;font-size: 20px"><b>Title:</b></label>
                </div>
                <div class="col-md-10" >
                  <input class="form-control" id="2725" [class.is-invalid]="(cherokeeHealthForm.controls['2725'].touched && cherokeeHealthForm.controls['2725'].invalid) || (cherokeeHealthForm.controls['2725'].invalid && isSubmit)" formControlName="2725" name="2725" placeholder="ref 3 title" type="text">
                  <div class="formLabel col-md-11"
                    *ngIf="cherokeeHealthForm.controls['2725'].touched && cherokeeHealthForm.controls['2725'].invalid">
                    <small class="text-danger" *ngIf="cherokeeHealthForm.controls['2725'].errors?.required">
                        required
                    </small>
                  </div>
                </div>
                </div>
              </td>
            </tr>
            <tr>
              <td >
                <div class="row">
                <div class="col-md-2">
                  <label style="font-weight: normal;font-size: 20px"><b>Address:</b></label>
                </div>
                <div class="col-md-10" >
                  <input class="form-control" id="385" name="385" [class.is-invalid]="(cherokeeHealthForm.controls['385'].touched && cherokeeHealthForm.controls['385'].invalid) || (cherokeeHealthForm.controls['385'].invalid && isSubmit)" formControlName="385" placeholder="ref 3 address 1" type="text">
                  <div class="formLabel col-md-11"
                    *ngIf="cherokeeHealthForm.controls['385'].touched && cherokeeHealthForm.controls['385'].invalid">
                    <small class="text-danger" *ngIf="cherokeeHealthForm.controls['385'].errors?.required">
                        required
                    </small>
                  </div>
                </div>
                </div>
              </td>
            </tr>
            <tr>
              <td >
                <div class="col-md-12" >
                  <input class="form-control" id="386" name="386" [class.is-invalid]="(cherokeeHealthForm.controls['386'].touched && cherokeeHealthForm.controls['386'].invalid) || (cherokeeHealthForm.controls['386'].invalid && isSubmit)" formControlName="386" placeholder="ref 3 address 2" type="text">
                  <div class="formLabel col-md-11"
                    *ngIf="cherokeeHealthForm.controls['386'].touched && cherokeeHealthForm.controls['386'].invalid">
                    <small class="text-danger" *ngIf="cherokeeHealthForm.controls['386'].errors?.required">
                        required
                    </small>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td >
                <div class="row">
                <div class="col-md-4">
                  <label style="font-weight: normal;font-size: 20px"><b>City /State/Zip:</b></label>
                </div>
                <div class="col-md-3" >
                  <input class="form-control" id="387" name="387" [class.is-invalid]="(cherokeeHealthForm.controls['387'].touched && cherokeeHealthForm.controls['387'].invalid) || (cherokeeHealthForm.controls['387'].invalid && isSubmit)" formControlName="387" placeholder="ref 3 city" type="text">
                  <div class="formLabel col-md-11"
                    *ngIf="cherokeeHealthForm.controls['387'].touched && cherokeeHealthForm.controls['387'].invalid">
                    <small class="text-danger" *ngIf="cherokeeHealthForm.controls['387'].errors?.required">
                        required
                    </small>
                  </div>
                </div>


                <div class="col-md-2">
                  <ng-select [virtualScroll]="true" [items]="stateDropDownDate" bindLabel="name" placeholder="ref 3 state" bindValue="id" formControlName="389" [class.is-invalid]="(cherokeeHealthForm.controls['389'].touched && cherokeeHealthForm.controls['389'].invalid) || (cherokeeHealthForm.controls['389'].invalid && isSubmit)">
                  </ng-select>
                  <div class="formLabel col-md-11"
                    *ngIf="cherokeeHealthForm.controls['389'].touched && cherokeeHealthForm.controls['389'].invalid">
                    <small class="text-danger" *ngIf="cherokeeHealthForm.controls['389'].errors?.required">
                        required
                    </small>
                  </div>
                </div>
                <div class="col-md-3" >
                  <input class="form-control" id="390" name="390" [class.is-invalid]="(cherokeeHealthForm.controls['390'].touched && cherokeeHealthForm.controls['390'].invalid) || (cherokeeHealthForm.controls['390'].invalid && isSubmit)" formControlName="390" placeholder="ref 3 zip" type="text">
                  <div class="formLabel col-md-11"
                    *ngIf="cherokeeHealthForm.controls['390'].touched && cherokeeHealthForm.controls['390'].invalid">
                    <small class="text-danger" *ngIf="cherokeeHealthForm.controls['390'].errors?.required">
                        required
                    </small>
                  </div>
                </div>
                </div>
              </td>
            </tr>
            <tr>
              <td >
                <div class="row">
                <div class="col-md-4">
                  <label style="font-weight: normal;font-size: 20px"><b>Phone Number:</b></label>
                  <label style="font-weight: normal;font-size:17px">(format: xxx-xxx-xxxx)</label>
                </div>
                <div class="col-md-8" >
                  <input class="form-control" id="394" name="394" [class.is-invalid]="(cherokeeHealthForm.controls['394'].touched && cherokeeHealthForm.controls['394'].invalid) || (cherokeeHealthForm.controls['394'].invalid && isSubmit)" formControlName="394" placeholder="ref 3 phone" type="text" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" maxlength="15">
                  <div class="formLabel col-md-11"
                    *ngIf="cherokeeHealthForm.controls['394'].touched && cherokeeHealthForm.controls['394'].invalid">
                    <small class="text-danger" *ngIf="cherokeeHealthForm.controls['394'].errors?.required">
                        required
                    </small>
                  </div>
                </div>
                </div>
              </td>
            </tr>

          </tbody>
        </table>
        <br><br>
        <hr
          style="border: 17px solid #818181; margin-left: -87px;margin-right: -87px; margin-top: 50px; margin-bottom: 50px;">
        <br><br>

        <div class="centered" style="text-align:center;">
          <h4><b><u>CONTINUING EDUCATION / TRAINING LOG</u> </b></h4>
        </div>


        <div class="row">
          <div class="col-md-1">
            <label style="font-weight: normal;font-size: 14px"><b>Name:</b></label>
          </div>
          <div class="col-md-5">
            <input class="form-control" id="cmd_1613" name="cmd_1613" formControlName="cmd_1613"
              placeholder="Printed name (as entered previously)" type="text">
          </div>
        </div>


        <br>

        <div class="row">
          <div class="col-md-1">
            <label style="font-weight: normal;font-size: 14px"><b>Employee Title:</b></label>
          </div>
          <div class="col-md-5">
            <input class="form-control" id="1451" name="1451" formControlName="1451"
              placeholder="Employee Title" type="text">
          </div>
        </div>


        <br>

        <div class="row">
          <div class="col-md-2">
            <label style="font-weight: normal;font-size: 14px"><b>Hire Date:</b></label>
          </div>
          <div class="col-md-5">
            <div class="input-group">
              <input class="form-control" formControlName="283" (blur)="clearInputIfInvalid('283')" name="dp" placeholder="Provider Start Date"
                ngbDatepicker #d52="ngbDatepicker" placement="bottom">
              <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d52.toggle()" type="button"><i
                    class="fas fa-calendar-alt"></i></button>
              </div>
              
            </div>
            <div  *ngIf="cherokeeHealthForm.get('283').invalid ">
              <small class="text-danger" *ngIf="cherokeeHealthForm.get('283').errors?.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY)
              </small>
              <small class="text-danger" *ngIf="cherokeeHealthForm.get('283').errors?.invalidMonth">
              Month is out of range
              </small>
              <small class="text-danger" *ngIf="cherokeeHealthForm.get('283').errors?.invalidDateyear">
                year is out of range(1900-2099)                      </small>
                <small class="text-danger" *ngIf="cherokeeHealthForm.get('283').errors?.invalidDateRange">
                  date is out of range
                  </small>
              </div>
          </div>
        </div>


        <br>
        <table class="dataTable"
          style="margin-left:auto;margin-right:auto;border: 1px solid black; border-collapse: collapse;" width="85%">
          <tbody>
            <tr>
              <th>DATE </th>
              <th>TITLE/CONTENT OF PROGRAM/TRAINING </th>
              <th>NO.OF HOURS/CEU'S</th>

            </tr>
            <tr>
              <td>
                <div class="input-group">
                  <input class="form-control" formControlName="cmd_1548" (blur)="clearInputIfInvalid('cmd_1548')" name="dp" ngbDatepicker #d53="ngbDatepicker"
                    placement="bottom">
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d53.toggle()" type="button"><i
                        class="fas fa-calendar-alt"></i></button>
                  </div>
                </div>
                <div  *ngIf="cherokeeHealthForm.get('cmd_1548').invalid ">
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1548').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1548').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1548').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1548').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </td>
              <td>

                <input class="form-control" id="cmd_1549" name="cmd_1549" type="text" formControlName="cmd_1549">
              </td>
              <td>

                <input class="form-control" id="cmd_1550" name="cmd_1550" type="text" formControlName="cmd_1550">
              </td>
            </tr>
            <tr>
              <td>
                <div class="input-group">
                  <input class="form-control" formControlName="cmd_1551" (blur)="clearInputIfInvalid('cmd_1551')" name="dp" ngbDatepicker #d54="ngbDatepicker"
                    placement="bottom">
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d54.toggle()" type="button"><i
                        class="fas fa-calendar-alt"></i></button>
                  </div>
                </div>
                <div  *ngIf="cherokeeHealthForm.get('cmd_1551').invalid ">
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1551').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1551').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1551').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1551').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </td>
              <td>

                <input class="form-control  " id="cmd_1552" name="cmd_1552" type="text" formControlName="cmd_1552">
              </td>
              <td>

                <input class="form-control  " id="cmd_1553" name="cmd_1553" type="text" formControlName="cmd_1553">
              </td>
            </tr>
            <tr>
              <td>
                <div class="input-group">
                  <input class="form-control" formControlName="cmd_1554" (blur)="clearInputIfInvalid('cmd_1554')" name="dp" ngbDatepicker #d55="ngbDatepicker"
                    placement="bottom">
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d55.toggle()" type="button"><i
                        class="fas fa-calendar-alt"></i></button>
                  </div>
                </div>
                <div  *ngIf="cherokeeHealthForm.get('cmd_1554').invalid ">
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1554').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1554').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1554').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1554').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </td>
              <td>

                <input class="form-control  " id="cmd_1555" name="cmd_1555" type="text" formControlName="cmd_1555">
              </td>
              <td>

                <input class="form-control  " id="cmd_1556" name="cmd_1556" type="text" formControlName="cmd_1556">
              </td>
            </tr>
            <tr>
              <td>
                <div class="input-group">
                  <input class="form-control" formControlName="cmd_1557" (blur)="clearInputIfInvalid('cmd_1557')" name="dp" ngbDatepicker #d64="ngbDatepicker"
                    placement="bottom">
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d64.toggle()" type="button"><i
                        class="fas fa-calendar-alt"></i></button>
                  </div>
                </div>
                <div  *ngIf="cherokeeHealthForm.get('cmd_1557').invalid ">
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1557').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1557').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1557').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1557').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </td>
              <td>

                <input class="form-control  " id="cmd_1558" name="cmd_1558" type="text" formControlName="cmd_1558">
              </td>
              <td>

                <input class="form-control  " id="cmd_1559" name="cmd_1559" type="text" formControlName="cmd_1559">
              </td>
            </tr>
            <tr>
              <td>
                <div class="input-group">
                  <input class="form-control" formControlName="cmd_1560" (blur)="clearInputIfInvalid('cmd_1560')" name="dp" ngbDatepicker #d56="ngbDatepicker"
                    placement="bottom">
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d56.toggle()" type="button"><i
                        class="fas fa-calendar-alt"></i></button>
                  </div>
                </div>
                <div  *ngIf="cherokeeHealthForm.get('cmd_1560').invalid ">
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1560').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1560').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1560').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1560').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </td>
              <td>

                <input class="form-control  " id="cmd_1561" name="cmd_1561" type="text" formControlName="cmd_1561">
              </td>
              <td>

                <input class="form-control  " id="cmd_1562" name="cmd_1562" type="text" formControlName="cmd_1562">
              </td>
            </tr>
            <tr>
              <td>
                <div class="input-group">
                  <input class="form-control" formControlName="cmd_1563" (blur)="clearInputIfInvalid('cmd_1563')" name="dp" ngbDatepicker #d57="ngbDatepicker"
                    placement="bottom">
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d57.toggle()" type="button"><i
                        class="fas fa-calendar-alt"></i></button>
                  </div>
                </div>
                <div  *ngIf="cherokeeHealthForm.get('cmd_1563').invalid ">
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1563').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1563').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1563').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1563').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </td>
              <td>

                <input class="form-control  " id="cmd_1564" name="cmd_1564" type="text" formControlName="cmd_1564">
              </td>
              <td>

                <input class="form-control  " id="cmd_1565" name="cmd_1565" type="text" formControlName="cmd_1565">
              </td>
            </tr>
            <tr>
              <td>
                <div class="input-group">
                  <input class="form-control" formControlName="cmd_1566" (blur)="clearInputIfInvalid('cmd_1566')" name="dp" ngbDatepicker #d58="ngbDatepicker"
                    placement="bottom">
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d58.toggle()" type="button"><i
                        class="fas fa-calendar-alt"></i></button>
                  </div>
                </div>
                <div  *ngIf="cherokeeHealthForm.get('cmd_1566').invalid ">
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1566').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1566').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1566').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1566').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </td>
              <td>

                <input class="form-control  " id="cmd_1567" name="cmd_1567" type="text" formControlName="cmd_1567">
              </td>
              <td>

                <input class="form-control  " id="cmd_1568" name="cmd_1568" type="text" formControlName="cmd_1568">
              </td>
            </tr>
            <tr>
              <td>
                <div class="input-group">
                  <input class="form-control" formControlName="cmd_1569" (blur)="clearInputIfInvalid('cmd_1569')" name="dp" ngbDatepicker #d59="ngbDatepicker"
                    placement="bottom">
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d59.toggle()" type="button"><i
                        class="fas fa-calendar-alt"></i></button>
                  </div>
                </div>
                <div  *ngIf="cherokeeHealthForm.get('cmd_1569').invalid ">
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1569').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1569').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1569').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1569').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </td>
              <td>

                <input class="form-control  " id="cmd_1570" name="cmd_1570" type="text" formControlName="cmd_1570">
              </td>
              <td>

                <input class="form-control  " id="cmd_1571" name="cmd_1571" type="text" formControlName="cmd_1571">
              </td>
            </tr>
            <tr>
              <td>
                <div class="input-group">
                  <input class="form-control" formControlName="cmd_1572" (blur)="clearInputIfInvalid('cmd_1572')" name="dp" ngbDatepicker #d60="ngbDatepicker"
                    placement="bottom">
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d60.toggle()" type="button"><i
                        class="fas fa-calendar-alt"></i></button>
                  </div>
                </div>
                <div  *ngIf="cherokeeHealthForm.get('cmd_1572').invalid ">
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1572').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1572').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1572').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1572').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </td>
              <td>

                <input class="form-control  " id="cmd_1573" name="cmd_1573" type="text" formControlName="cmd_1573">
              </td>
              <td>

                <input class="form-control  " id="cmd_1574" name="cmd_1574" type="text" formControlName="cmd_1574">
              </td>
            </tr>
            <tr>
              <td>
                <div class="input-group">
                  <input class="form-control" formControlName="cmd_1575" (blur)="clearInputIfInvalid('cmd_1575')" name="dp" ngbDatepicker #d61="ngbDatepicker"
                    placement="bottom">
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d61.toggle()" type="button"><i
                        class="fas fa-calendar-alt"></i></button>
                  </div>
                </div>
                <div  *ngIf="cherokeeHealthForm.get('cmd_1575').invalid ">
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1575').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1575').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1575').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1575').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </td>
              <td>

                <input class="form-control  " id="cmd_1576" name="cmd_1576" type="text" formControlName="cmd_1576">
              </td>
              <td>

                <input class="form-control  " id="cmd_1577" name="cmd_1577" type="text" formControlName="cmd_1577">
              </td>
            </tr>
            <tr>
              <td>
                <div class="input-group">
                  <input class="form-control" formControlName="cmd_1578" (blur)="clearInputIfInvalid('cmd_1578')" name="dp"
                    ngbDatepicker #d62="ngbDatepicker" placement="bottom">
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d62.toggle()" type="button"><i
                        class="fas fa-calendar-alt"></i></button>
                  </div>
                </div>
                <div  *ngIf="cherokeeHealthForm.get('cmd_1578').invalid ">
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1578').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1578').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1578').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1578').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </td>
              <td>

                <input class="form-control  " id="cmd_1579" name="cmd_1579" type="text" formControlName="cmd_1579">
              </td>
              <td>

                <input class="form-control  " id="cmd_1580" name="cmd_1580" type="text" formControlName="cmd_1580">
              </td>
            </tr>
            <tr>
              <td>
                <div class="input-group">
                  <input class="form-control" formControlName="cmd_1581" (blur)="clearInputIfInvalid('cmd_1581')" name="dp"
                    ngbDatepicker #d63="ngbDatepicker" placement="bottom">
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d63.toggle()" type="button"><i
                        class="fas fa-calendar-alt"></i></button>
                  </div>
                </div>
                <div  *ngIf="cherokeeHealthForm.get('cmd_1581').invalid ">
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1581').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1581').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1581').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1581').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </td>
              <td>
                <input class="form-control  " id="cmd_1582" name="cmd_1582" type="text" formControlName="cmd_1582">
              </td>
              <td>

                <input class="form-control  " id="cmd_1583" name="cmd_1583" type="text" formControlName="cmd_1583">
              </td>
            </tr>
            <tr>
              <td>
                <div class="input-group">
                  <input class="form-control" formControlName="cmd_1584" (blur)="clearInputIfInvalid('cmd_1584')" name="dp"
                    ngbDatepicker #d65="ngbDatepicker" placement="bottom">
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d65.toggle()" type="button"><i
                        class="fas fa-calendar-alt"></i></button>
                  </div>
                </div>
                <div  *ngIf="cherokeeHealthForm.get('cmd_1584').invalid ">
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1584').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1584').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1584').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1584').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </td>
              <td>

                <input class="form-control  " id="cmd_1585" name="cmd_1585" type="text" formControlName="cmd_1585">
              </td>
              <td>

                <input class="form-control  " id="cmd_1586" name="cmd_1586" type="text" formControlName="cmd_1586">
              </td>
            </tr>
            <tr>
              <td>
                <div class="input-group">
                  <input class="form-control" formControlName="cmd_1587" (blur)="clearInputIfInvalid('cmd_1587')" name="dp" ngbDatepicker #d66="ngbDatepicker"
                    placement="bottom">
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d66.toggle()" type="button"><i
                        class="fas fa-calendar-alt"></i></button>
                  </div>
                </div>
                <div  *ngIf="cherokeeHealthForm.get('cmd_1587').invalid ">
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1587').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1587').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1587').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1587').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </td>
              <td>

                <input class="form-control  " id="cmd_1588" name="cmd_1588" type="text" formControlName="cmd_1588">
              </td>
              <td>

                <input class="form-control  " id="cmd_1589" name="cmd_1589" type="text" formControlName="cmd_1589">
              </td>
            </tr>
            <tr>
              <td>
                <div class="input-group">
                  <input class="form-control" formControlName="cmd_1590" (blur)="clearInputIfInvalid('cmd_1590')" name="dp" ngbDatepicker #d67="ngbDatepicker"
                    placement="bottom">
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d67.toggle()" type="button"><i
                        class="fas fa-calendar-alt"></i></button>
                  </div>
                </div>
                <div  *ngIf="cherokeeHealthForm.get('cmd_1590').invalid ">
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1590').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1590').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1590').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1590').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </td>
              <td>

                <input class="form-control  " id="cmd_1591" name="cmd_1591" type="text" formControlName="cmd_1591">
              </td>
              <td>

                <input class="form-control  " id="cmd_1592" name="cmd_1592" type="text" formControlName="cmd_1592">
              </td>
            </tr>
            <tr>
              <td>
                <div class="input-group">
                  <input class="form-control" formControlName="cmd_1593" (blur)="clearInputIfInvalid('cmd_1593')" name="dp"
                    ngbDatepicker #d68="ngbDatepicker" placement="bottom">
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d68.toggle()" type="button"><i
                        class="fas fa-calendar-alt"></i></button>
                  </div>
                </div>
                <div  *ngIf="cherokeeHealthForm.get('cmd_1593').invalid ">
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1593').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1593').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1593').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1593').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </td>
              <td>

                <input class="form-control  " id="cmd_1594" name="cmd_1594" type="text" formControlName="cmd_1594">
              </td>
              <td>

                <input class="form-control  " id="cmd_1595" name="cmd_1595" type="text" formControlName="cmd_1595">
              </td>
            </tr>
            <tr>
              <td>
                <div class="input-group">
                  <input class="form-control" formControlName="cmd_1596" (blur)="clearInputIfInvalid('cmd_1596')" name="dp"
                    ngbDatepicker #d69="ngbDatepicker" placement="bottom">
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d69.toggle()" type="button"><i
                        class="fas fa-calendar-alt"></i></button>
                  </div>
                </div>
                <div  *ngIf="cherokeeHealthForm.get('cmd_1596').invalid ">
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1596').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1596').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1596').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1596').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </td>
              <td>

                <input class="form-control  " id="cmd_1597" name="cmd_1597" type="text" formControlName="cmd_1597">
              </td>
              <td>

                <input class="form-control  " id="cmd_1598" name="cmd_1598" type="text" formControlName="cmd_1598">
              </td>
            </tr>
            <tr>
              <td>
                <div class="input-group">
                  <input class="form-control" formControlName="cmd_1599" (blur)="clearInputIfInvalid('cmd_1599')" name="dp" ngbDatepicker #d70="ngbDatepicker"
                    placement="bottom">
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d70.toggle()" type="button"><i
                        class="fas fa-calendar-alt"></i></button>
                  </div>
                </div>
                <div  *ngIf="cherokeeHealthForm.get('cmd_1599').invalid ">
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1599').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1599').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1599').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1599').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </td>
              <td>

                <input class="form-control  " id="cmd_1600" name="cmd_1600" type="text" formControlName="cmd_1600">
              </td>
              <td>

                <input class="form-control  " id="cmd_1601" name="cmd_1601" type="text" formControlName="cmd_1601">
              </td>
            </tr>
          </tbody>
        </table>
        <br><br>
        <hr
          style="border: 17px solid #818181; margin-left: -87px;margin-right: -87px; margin-top: 50px; margin-bottom: 50px;">
        <br><br><br><br>

        <div class="centered" style="text-align:center;">
          <h2><b>Cherokee Health Systems</b></h2>
        </div>



        <div class="centered" style="text-align:center;">
          <h2><b>Provider Consent Form</b> </h2>
        </div>

        <div class="inner" style="width: 85%;margin: auto;margin-top: 5%;">

          <label style="font-weight: normal;font-size: 16px">I,

            <input style="width:220px" id="cmd_1602" name="cmd_1602" type="text" formControlName="cmd_1602"
              placeholder="Printed Name">

            ,grant permission and consent for Cherokee Health Systems to obtain and verify information contained on my
            applications for employment and/or credentialing, in order to evaluate my professional competence,ability to
            render clinical services, character, and ethical qualifications. </label>

        </div>
        <div class="inner" style="width: 85%;margin: auto;margin-top: 3%;">
          <label style="font-weight: normal;font-size:16px">I consent to the release by any person, organization, or
            other entity to Cherokee Health Systems any information deemed necessary to evaluate same and agree to hold
            harmless any such person or organization from any cause of action based on the release of such information
            to Cherokee Health Systems. </label>
        </div>
        <div class="inner" style="width: 85%;margin: auto;margin-top: 3%;">
          <label style="font-weight: normal;font-size:16px">I further agree that photocopies of this document will be as
            binding as the original. </label>
        </div>
        <table class="dataTable" style="margin-left:auto;margin-right:auto;border-collapse: collapse;" width="80%">
          <tbody>
            <tr>

            </tr>

            <tr>


            </tr>
            <tr>


            </tr>

          </tbody>
        </table>
        <br><br>

        <div class="row" style="margin-left: 2rem">

          <div class="col-md-3" style="text-align: center;">
            <label style="font-weight: normal;font-size: 14px;text-align: center;">⁭Signature of Applicant</label>
          </div>

          <div class="col-md-8" style="text-align: left;">
            <!-- <img data-toggle="modal" data-source="cmd_1660" class="esign-img" id="cmd_1660s" name="cmd_1660s" data-target="#myModal" alt="Click here to Esign" src="https://stage.credentialmydoc.com/Login/images/e_sign.png">
                    <input type="hidden" name="cmd_1660" id="cmd_1660"> -->

            <!-- <img *ngIf="!showTwo" class="img" src="./assets/images/e_sign.png" alt="Credential my Doc"
              (click)="esignOpen(2)">
            <img *ngIf="showTwo" class="img eSignImageShow" [src]="esignTwo" alt="Credential my Doc"
              (click)="esignOpen(2)">
            <input type="hidden" name="cmd_1660" id="cmd_1660" formControlName="cmd_1660" value={{finalImgPathTwo}}> -->
            <img *ngIf="!showTwo" class="img" src="./assets/images/e_sign.png" alt="Credential my Doc" (click)="esignOpenTwo()">
            <img  *ngIf="showTwo" class="img eSignImageShow" [src]="esignTwo"  alt="Credential my Doc" (click)="esignOpenTwo()">
            <input type="hidden" name="cmd_1660" id="cmd_1660" formControlName="cmd_1660">
          </div>
        </div>
        <br>

        <div class="row">

          <div class="col-md-3" style="text-align: center;">
            <label style="font-weight: normal;font-size: 14px;text-align: center;">Date</label>
          </div>
          <div class="col-md-3">
            <div class="input-group">
              <input class="form-control" formControlName="cmd_1661" (blur)="clearInputIfInvalid('cmd_1661')" placeholder="Date" ngbDatepicker
                #d74="ngbDatepicker" placement="bottom">
              <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d74.toggle()" type="button"><i
                    class="fas fa-calendar-alt"></i></button>
              </div>
            </div>
            <div  *ngIf="cherokeeHealthForm.get('cmd_1661').invalid ">
              <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1661').errors?.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY)
              </small>
              <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1661').errors?.invalidMonth">
              Month is out of range
              </small>
              <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1661').errors?.invalidDateyear">
                year is out of range(1900-2099)                      </small>
                <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1661').errors?.invalidDateRange">
                  date is out of range
                  </small>
              </div>
          </div>
        </div>
        <br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br>
        <hr
          style="border: 17px solid #818181; margin-left: -87px;margin-right: -87px; margin-top: 50px; margin-bottom: 50px;">
        <br><br><br>
        <div style="padding-left:30px ">

          <img style="height: 140px; margin-bottom: 10px;width:330px" src="../../../../assets/images/cherokee.png"
            alt="Credential my Doc">

        </div>

        <div class="centered" style="text-align:center;">
          <h4><b style="font-size:20px">Statement of Health Fitness</b></h4>
        </div>



        <div class="inner" style="width: 85%;margin: auto;margin-top: 5%;">
          <label style="font-weight: normal;font-size:16px"><b>You MUST provide a detailed explanation below for any
              question to which you respond "YES". </b></label>
        </div>


        <br><br>


        <div class="row">
          <div class="form-group col-md-12">
            <label><b>1.</b> Do you currently have a chemical dependency/substance abuse problem,treated or untreated,
              which in any<br> way impairs your ability to practice to the fullest extent of your licensure and
              qualifications or in any way poses <br>a risk of harm to your patients?
            </label>
          </div><br>
          <div class="col-md-5">
            <label class="checkbox-inline">
              <input type="radio" id="cmd_1603" name="cmd_1603" value="1" [class.is-invalid]="(cherokeeHealthForm.controls['cmd_1603'].touched && cherokeeHealthForm.controls['cmd_1603'].errors) || (cherokeeHealthForm.controls['cmd_1603'].errors && isSubmit)" (change)="onChangeCmdFive($event)" formControlName="cmd_1603"><b>Yes</b></label>
            <label class="checkbox-inline">
              <input type="radio" id="cmd_1603_1" name="cmd_1603" value="0" [class.is-invalid]="(cherokeeHealthForm.controls['cmd_1603'].touched && cherokeeHealthForm.controls['cmd_1603'].errors) || (cherokeeHealthForm.controls['cmd_1603'].errors && isSubmit)" (change)="onChangeCmdFive($event)" formControlName="cmd_1603"><b>No</b></label>
              <div class="formLabel col-md-11"
                  *ngIf="cherokeeHealthForm.controls['cmd_1603'].touched && cherokeeHealthForm.controls['cmd_1603'].errors">
                  <small class="text-danger" *ngIf="cherokeeHealthForm.controls['cmd_1603'].errors?.required">
                      required
                  </small>
              </div>
              <span *ngIf="(cherokeeHealthForm.controls['cmd_1603'].touched && cherokeeHealthForm.controls['cmd_1603'].errors) || (cherokeeHealthForm.controls['cmd_1603'].errors && isSubmit)">
                <i class="fas fa-times ml-2 text-danger"></i>
              </span>
          </div>
        </div>
        <br>
        <div class="row">
          <div class="form-group col-md-12">
            <label><b>2.</b> Are you currently taking any medications that may affect your clinical judgment or motor
              skills which in any <br>way impairs your ability to practice to the fullest extent of your licensure and
              qualifications or in any way poses<br> a risk of harm to your patients?
            </label>
          </div><br>
          <div class="col-md-5">
            <label class="checkbox-inline">
              <input type="radio" id="cmd_1604" name="cmd_1604" value="1" [class.is-invalid]="(cherokeeHealthForm.controls['cmd_1604'].touched && cherokeeHealthForm.controls['cmd_1604'].invalid) || (cherokeeHealthForm.controls['cmd_1604'].invalid && isSubmit)" (change)="onChangeCmdFour($event)" formControlName="cmd_1604"><b>Yes</b></label>
             <label class="checkbox-inline">
              <input type="radio" id="cmd_1604_1" name="cmd_1604" value="0" [class.is-invalid]="(cherokeeHealthForm.controls['cmd_1604'].touched && cherokeeHealthForm.controls['cmd_1604'].invalid) || (cherokeeHealthForm.controls['cmd_1604'].invalid && isSubmit)" (change)="onChangeCmdFour($event)" formControlName="cmd_1604"><b>No</b></label>
              <div class="formLabel col-md-11"
                  *ngIf="cherokeeHealthForm.controls['cmd_1604'].touched && cherokeeHealthForm.controls['cmd_1604'].invalid">
                  <small class="text-danger" *ngIf="cherokeeHealthForm.controls['cmd_1604'].errors?.required">
                      required
                  </small>
              </div>
                <span *ngIf="(cherokeeHealthForm.controls['cmd_1604'].touched && cherokeeHealthForm.controls['cmd_1604'].errors) || (cherokeeHealthForm.controls['cmd_1604'].errors && isSubmit)">
                  <i class="fas fa-times ml-2 text-danger"></i>
                </span>
          </div>
        </div>


        <br>


        <div class="row">
          <div class="form-group col-md-12">
            <label><b>3.</b> Do you currently have any physical or mental health condition, treated or untreated which
              in any way impairs<br> your ability to practice to the fullest extent of your licensure and qualifications
              or in any way, poses a risk of harm<br> to your patients?
            </label>
          </div><br>
          <div class="col-md-5">
            <label class="checkbox-inline">
              <input type="radio" id="cmd_1605" name="cmd_1605" value="1" [class.is-invalid]="(cherokeeHealthForm.controls['cmd_1605'].touched && cherokeeHealthForm.controls['cmd_1605'].invalid) || (cherokeeHealthForm.controls['cmd_1605'].invalid && isSubmit)" (change)="onChangeCmdThree($event)" formControlName="cmd_1605"><b>Yes</b></label>
              <label class="checkbox-inline">
              <input type="radio" id="cmd_1605_1" name="cmd_1605" value="0" [class.is-invalid]="(cherokeeHealthForm.controls['cmd_1605'].touched && cherokeeHealthForm.controls['cmd_1605'].invalid) || (cherokeeHealthForm.controls['cmd_1605'].invalid && isSubmit)" (change)="onChangeCmdThree($event)" formControlName="cmd_1605"><b>No</b></label>
              <div class="formLabel col-md-11"
                *ngIf="cherokeeHealthForm.controls['cmd_1605'].touched && cherokeeHealthForm.controls['cmd_1605'].invalid">
                <small class="text-danger" *ngIf="cherokeeHealthForm.controls['cmd_1605'].errors?.required">
                    required
                </small>
              </div>
              <span *ngIf="(cherokeeHealthForm.controls['cmd_1605'].touched && cherokeeHealthForm.controls['cmd_1605'].errors) || (cherokeeHealthForm.controls['cmd_1605'].errors && isSubmit)">
                <i class="fas fa-times ml-2 text-danger"></i>
              </span>
          </div>
        </div>


        <br>
        <div class="inner" style="width: 85%;margin: auto;margin-top: 2%;">
          <label style="font-weight: normal;font-size:16px"><u><b>Provide detailed explanation for any item(s) marked
                YES:</b></u></label>
        </div>

        <div class="row">
          <div class="col-md-12 form-group">
            <div *ngIf="changeCmdFive">
              <input class="form-control  " id="cmd_1606" name="cmd_1606" [class.is-invalid]="cherokeeHealthForm.controls['cmd_1606'].value==''" type="text" formControlName="cmd_1606">
              <div class="formLabel col-md-11"
                *ngIf="cherokeeHealthForm.controls['cmd_1606'].touched && cherokeeHealthForm.controls['cmd_1606'].errors">
                <small class="text-danger" *ngIf="cherokeeHealthForm.controls['cmd_1606'].errors?.required">
                    required
                </small>
              </div>
            </div>
          </div>
          <div class="col-md-12 form-group">
            <div *ngIf="changeCmdFour">
              <input class="form-control  " id="cmd_1607" name="cmd_1607" [class.is-invalid]="cherokeeHealthForm.controls['cmd_1607'].value==''" type="text" formControlName="cmd_1607">
              <div class="formLabel col-md-11"
                *ngIf="cherokeeHealthForm.controls['cmd_1607'].touched && cherokeeHealthForm.controls['cmd_1607'].errors">
                <small class="text-danger" *ngIf="cherokeeHealthForm.controls['cmd_1607'].errors?.required">
                    required
                </small>
              </div>
            </div>
          </div>
          <div class="col-md-12 form-group">
            <div *ngIf="changeCmdThree">
              <input class="form-control  " id="cmd_1608" name="cmd_1608" [class.is-invalid]="cherokeeHealthForm.controls['cmd_1608'].value==''" type="text" formControlName="cmd_1608">
              <div class="formLabel col-md-11"
                *ngIf="cherokeeHealthForm.controls['cmd_1608'].touched && cherokeeHealthForm.controls['cmd_1608'].errors">
                <small class="text-danger" *ngIf="cherokeeHealthForm.controls['cmd_1608'].errors?.required">
                    required
                </small>
              </div>
            </div>
          </div>
          <div class="col-md-12 form-group">
            <input class="form-control  " id="cmd_1609" name="cmd_1609" type="text" formControlName="cmd_1609">
          </div>
          <div class="col-md-12 form-group">
            <input class="form-control  " id="cmd_1610" name="cmd_1610" type="text" formControlName="cmd_1610">
          </div>
          <div class="col-md-12 form-group">
            <input class="form-control  " id="cmd_1611" name="cmd_1611" type="text" formControlName="cmd_1611">
          </div>
        </div>
        <br><br>
        <div class="row" style="margin-left: 2rem">

          <div class="col-md-2 form-group" style="text-align: center;">
            <label style="font-weight: normal;font-size: 14px;text-align: center;">⁭ Signature</label>
          </div>

          <div class="col-md-4 form-group" style="text-align: left;">
            <!-- <img *ngIf="!showThree" class="img" src="./assets/images/e_sign.png" alt="Credential my Doc"
              (click)="esignOpen(3)">
            <img *ngIf="showThree" class="img eSignImageShow" [src]="esignThree" alt="Credential my Doc"
              (click)="esignOpen(3)">
            <input type="hidden" name="cmd_1658" id="cmd_1658" formControlName="cmd_1658" value={{finalImgPathThree}}> -->
            <img *ngIf="!showThree" class="img" src="./assets/images/e_sign.png" alt="Credential my Doc" (click)="esignOpenThree()">
            <img  *ngIf="showThree" class="img eSignImageShow" [src]="esignThree"  alt="Credential my Doc" (click)="esignOpenThree()">
            <input type="hidden" name="cmd_1658" id="cmd_1658" formControlName="cmd_1658">
          </div>
          <div class="col-md-1" style="padding-left:20px">
            <label>Date</label>
          </div>
          <div class="col-md-4">
            <div class="input-group">
              <input class="form-control" formControlName="cmd_1659" (blur)="clearInputIfInvalid('cmd_1659')" name="d1" placeholder="Date" ngbDatepicker
                #d71="ngbDatepicker" placement="bottom">
              <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d71.toggle()" type="button"><i
                    class="fas fa-calendar-alt"></i></button>
              </div>
            </div>
            <div  *ngIf="cherokeeHealthForm.get('cmd_1659').invalid ">
              <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1659').errors?.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY)
              </small>
              <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1659').errors?.invalidMonth">
              Month is out of range
              </small>
              <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1659').errors?.invalidDateyear">
                year is out of range(1900-2099)                      </small>
                <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1659').errors?.invalidDateRange">
                  date is out of range
                  </small>
              </div>
          </div>
        </div>
        <hr
          style="border: 17px solid #818181; margin-left: -87px;margin-right: -87px; margin-top: 50px; margin-bottom: 50px;">
        <br><br>
        <div class="d-flex justify-content-center">
          <h2><b>Cherokee Health Systems</b></h2>
        </div>
        <br>
        <table class="dataTable">
          <tbody>
            <tr>
              <td>
                <div class="row pt-2 pl-2">
                  <div class="col-md-2">
                    <h6><b>Name:</b></h6>
                  </div>
                  <div class="col-md-10 form-group">
                    <input class="form-control" id="cmd_1615" name="cmd_1615" type="text" formControlName="cmd_1615"
                      placeholder="printed name (should populate based on data entered previously">
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <br>
        <table class="dataTable">
          <tbody>
            <tr>
              <td>
                <div class="col-md-12 text-left">
                  <h6><u><b>Professional licensure in other states and/or countries: </b></u></h6>
                </div>
                <br>
                <div class="row">
                  <div class="form-group col-md-9">
                    <label>Are you presently, or have you been in the past, professionally licensed in another state or
                      country?
                    </label>
                  </div>
                  <div class="col-md-3 form-group">
                    <label class="checkbox-inline">
                      <input type="radio" id="cmd_1616" name="cmd_1616" value="1" [class.is-invalid]="(cherokeeHealthForm.controls['cmd_1616'].touched && cherokeeHealthForm.controls['cmd_1616'].errors) || (cherokeeHealthForm.controls['cmd_1616'].errors && isSubmit)" (change)="onChangeCmdTwo($event)" formControlName="cmd_1616">Yes</label>
                    <label class="checkbox-inline">
                      <input type="radio" id="cmd_1616_1" name="cmd_1616" value="0" [class.is-invalid]="(cherokeeHealthForm.controls['cmd_1616'].touched && cherokeeHealthForm.controls['cmd_1616'].errors) || (cherokeeHealthForm.controls['cmd_1616'].errors && isSubmit)" (change)="onChangeCmdTwo($event)" formControlName="cmd_1616">No</label>
                      <div class="formLabel col-md-11"
                        *ngIf="cherokeeHealthForm.controls['cmd_1616'].touched && cherokeeHealthForm.controls['cmd_1616'].errors">
                        <small class="text-danger" *ngIf="cherokeeHealthForm.controls['cmd_1616'].errors?.required">
                            required
                        </small>
                      </div>
                      <span *ngIf="(cherokeeHealthForm.controls['cmd_1616'].touched && cherokeeHealthForm.controls['cmd_1616'].errors) || (cherokeeHealthForm.controls['cmd_1616'].errors && isSubmit)">
                        <i class="fas fa-times ml-2 text-danger"></i>
                    </span>
                  </div>

                </div>
                <div class="row">
                  <div class="col-md-12 form-group">
                    <label><u><b>If yes,</b></u> please state where (including license number(s) if known): </label>
                  </div>

                  <div class="col-md-12  form-group">
                    <div *ngIf="changeCmdTwo">
                      <input class="form-control  " id="cmd_1617" name="cmd_1617" type="text" [class.is-invalid]="cherokeeHealthForm.controls['cmd_1617'].value==''" formControlName="cmd_1617">
                      <div class="formLabel col-md-11"
                          *ngIf="cherokeeHealthForm.controls['cmd_1617'].touched && cherokeeHealthForm.controls['cmd_1617'].errors">
                          <small class="text-danger" *ngIf="cherokeeHealthForm.controls['cmd_1617'].errors?.required">
                              required
                          </small>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 form-group">
                    <input class="form-control" id="cmd_1670" name="cmd_1670" type="text" formControlName="cmd_1670">
                  </div>
                  <div class="col-md-12 form-group">
                    <input class="form-control" id="cmd_1671" name="cmd_1671" type="text" formControlName="cmd_1671">
                  </div>
                  <div class="col-md-12 form-group">
                    <input class="form-control" id="cmd_1672" name="cmd_1672" type="text" formControlName="cmd_1672">
                  </div>
                  <div class="col-md-12 form-group">
                    <input class="form-control" id="cmd_1674" name="cmd_1674" type="text" formControlName="cmd_1674">
                  </div>
                  <div class="col-md-12 form-group">
                    <input class="form-control" id="cmd_1675" name="cmd_1675" type="text" formControlName="cmd_1675">
                  </div>
                </div>

              </td>
            </tr>
          </tbody>
        </table>
        <br>
        <table class="dataTable">
          <tbody>
            <tr>
              <td>
                <div class="row">
                  <div class="col-md-4">
                    <label><u><b>UPIN # (if applicable):</b></u></label>
                  </div>
                  <div class="col-md-8">
                    <input class="form-control" id="819" name="819" type="text" placeholder="Upin number"
                      formControlName="819">
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <br>
        <table class="dataTable">
          <tbody>
            <tr>
              <td>
                <div class="row">
                  <div class="form-group col-md-9">
                    <label><u><b>Moonlighting:</b></u>Are you (or will you be) moonlighting anywhere while working for
                      Cherokee Health Systems?
                    </label>
                  </div>
                  <div class="col-md-3">
                    <label class="checkbox-inline">
                      <input type="radio" id="cmd_1618" name="cmd_1618" value="1" [class.is-invalid]="(cherokeeHealthForm.controls['cmd_1618'].touched && cherokeeHealthForm.controls['cmd_1618'].errors) || (cherokeeHealthForm.controls['cmd_1618'].errors && isSubmit)" (change)="onChangeCmdOne($event)" formControlName="cmd_1618">Yes</label>
                      <label class="checkbox-inline">
                      <input type="radio" id="cmd_1618_1" name="cmd_1618" value="0" [class.is-invalid]="(cherokeeHealthForm.controls['cmd_1618'].touched && cherokeeHealthForm.controls['cmd_1618'].errors) || (cherokeeHealthForm.controls['cmd_1618'].errors && isSubmit)" (change)="onChangeCmdOne($event)" formControlName="cmd_1618">No</label>
                      <div class="formLabel col-md-11"
                        *ngIf="cherokeeHealthForm.controls['cmd_1618'].touched && cherokeeHealthForm.controls['cmd_1618'].errors">
                        <small class="text-danger" *ngIf="cherokeeHealthForm.controls['cmd_1618'].errors?.required">
                            required
                        </small>
                      </div>
                      <span *ngIf="(cherokeeHealthForm.controls['cmd_1618'].touched && cherokeeHealthForm.controls['cmd_1618'].errors) || (cherokeeHealthForm.controls['cmd_1618'].errors && isSubmit)">
                        <i class="fas fa-times ml-2 text-danger"></i>
                    </span>
                  </div>
                </div>
                <div class="row pt-2">
                  <div class="col-md-8 form-group">
                    <label><u><b>If yes,</b></u>we need a letter explaining the moonlighting arrangements to add to your
                      credentialing file<b> (or you may explain in the space below).</b> </label>
                  </div>
                  <div class="col-md-12  form-group">
                    <div *ngIf="changeCmdOne">
                      <input class="form-control  " id="cmd_1676" name="cmd_1676" type="text" [class.is-invalid]="cherokeeHealthForm.controls['cmd_1676'].value==''" formControlName="cmd_1676">
                      <div class="formLabel col-md-11"
                          *ngIf="cherokeeHealthForm.controls['cmd_1676'].touched && cherokeeHealthForm.controls['cmd_1676'].errors">
                          <small class="text-danger" *ngIf="cherokeeHealthForm.controls['cmd_1676'].errors?.required">
                              required
                          </small>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12  form-group">
                    <input class="form-control  " id="cmd_1677" name="cmd_1677" type="text" formControlName="cmd_1677">
                  </div>
                  <div class="col-md-12  form-group">
                    <input class="form-control  " id="cmd_1678" name="cmd_1678" type="text" formControlName="cmd_1678">
                  </div>
                  <div class="col-md-12  form-group">
                    <input class="form-control  " id="cmd_1679" name="cmd_1679" type="text" formControlName="cmd_1679">
                  </div>
                  <div class="col-md-12 form-group">
                    <input class="form-control  " id="cmd_1680" name="cmd_1680" type="text" formControlName="cmd_1680">
                  </div>
                  <div class="col-md-12  form-group">
                    <input class="form-control" id="cmd_1681" name="cmd_1681" type="text" formControlName="cmd_1681">
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <br><br>
        <div class="row ml-2">
          <div class="col-md-2">
            <label>⁭ Signature</label>
          </div>
          <div class="col-md-4">
            <!-- <img *ngIf="!showFour" class="img" src="./assets/images/e_sign.png" alt="Credential my Doc"
              (click)="esignOpen(4)">
            <img *ngIf="showFour" class="img eSignImageShow" [src]="esignFour" alt="Credential my Doc"
              (click)="esignOpen(4)">
            <input type="hidden" name="cmd_1621" id="cmd_1621" formControlName="cmd_1621" value={{finalImgPathFour}}> -->
            <img *ngIf="!showFour" class="img" src="./assets/images/e_sign.png" alt="Credential my Doc" (click)="esignOpenFour()">
            <img  *ngIf="showFour" class="img eSignImageShow" [src]="esignFour"  alt="Credential my Doc" (click)="esignOpenFour()">
            <input type="hidden" name="cmd_1621" id="cmd_1621" formControlName="cmd_1621">
          </div>
          <div class="col-md-1">
            <label>Date</label>
          </div>
          <div class="col-md-4">
            <div class="input-group">
              <input class="form-control" formControlName="cmd_1620" (blur)="clearInputIfInvalid('cmd_1620')" placeholder="Date" ngbDatepicker
                #d73="ngbDatepicker" placement="bottom">
              <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d73.toggle()" type="button"><i
                    class="fas fa-calendar-alt"></i></button>
              </div>
            </div>
            <div  *ngIf="cherokeeHealthForm.get('cmd_1620').invalid ">
              <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1620').errors?.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY)
              </small>
              <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1620').errors?.invalidMonth">
              Month is out of range
              </small>
              <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1620').errors?.invalidDateyear">
                year is out of range(1900-2099)                      </small>
                <small class="text-danger" *ngIf="cherokeeHealthForm.get('cmd_1620').errors?.invalidDateRange">
                  date is out of range
                  </small>
              </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

<div class="modal-footer mt-4">
  <button *ngIf="formData.receivedForm" type="button" class="btn customOrange float-right"
  (click)="saveForm('approve')">Approve</button>
  <button *ngIf="formData.submitForm" type="submit" class="btn btn customOrange float-right"
  (click)="saveForm('submit')">Submit</button>
  <button *ngIf="formData.submitForm" type="button" class="btn btn customOrange float-right"
  (click)="saveForm('save')">Save</button>
  <button *ngIf="formData.previewForm" type="button" class="btn customOrange float-right" (click)="close()">Close</button>
</div>
