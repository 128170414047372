import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators , FormControl, ValidationErrors } from '@angular/forms';
import { OnboardingformsServiceService } from '../../services/onboardingforms-service/onboardingforms-service.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToasterNotiService } from '../../services/notifications/toaster-noti.service';
import {
  NgbModal,
  NgbModalOptions,
  NgbDateAdapter,
  NgbDateParserFormatter
} from '@ng-bootstrap/ng-bootstrap';
import { EsignComponent } from '../esign/esign.component';
import { DomSanitizer } from '@angular/platform-browser';
import { StorageService } from '../../services/storage/storage.service';
import {
  CustomAdapter,
  CustomDateParserFormatter
} from '../../DateFormatter/customDate';
import { DpDatePickerModule } from 'ng2-date-picker';
import { Error_Message } from '../../services/common/common.service';

@Component({
  selector: 'ctg-sph-chatham',
  templateUrl: './sph-chatham.component.html',
  styleUrls: ['./sph-chatham.component.scss'],
  providers: [
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }
  ]
})
export class SphChathamComponent implements OnInit {
  constructor(
    private fb: UntypedFormBuilder,
    private onboardService: OnboardingformsServiceService,
    private spinner: NgxSpinnerService,
    private notiService: ToasterNotiService,
    private modalService: NgbModal,
    private _sanitizer: DomSanitizer,
    private storageService: StorageService
  ) {}

  @Input() formData;
  @Input() fieldsData;
  @Input() emailCustId;
  @Input() formId;
  @Output() onSubmit = new EventEmitter();
  @Output() onClose = new EventEmitter();
  public southGeorgiaFormOne: UntypedFormGroup;
  modalOption: NgbModalOptions = {
    size: 'md',
    keyboard: false,
    backdrop: 'static'
  };
  greetings: Array<any> = [];
  specialty: Array<any> = [];
  specialtySecondary: Array<any> = [];
  degree: Array<any> = [];
  locDropdown: Array<any> = [];
  languages: Array<any> = [];
  secondaryLanguages: Array<any> = [];
  tertiaryLanguages: Array<any> = [];
  quaternaryLanguages: Array<any> = [];
  boardCertification: Array<any> = [];
  states: Array<any> = [];
  id: '';
  finalImgPath: any = [];
  finalImg: any;
  finalImgPathTwo: any = [];
  finalImgTwo: any = '';
  esignOne: any;
  esignTwo: any;
  showOne: any = false;
  private emailPattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  showTwo: any = false;
  public userId: any;
  public customerId: any;
  isSubmit: boolean = false;
  firstName: any;
  lastName: any;
  middleName: any;
  greetingDropDown: any;
  specialtyDropDown: any;
  degreeDropDown: any;
  finalName: any;
  datepicker: any;
  ImgOne: any;
  imgOneSet: any;
  imgTwoSet: any;
  imgOneSetting: any;

  ngOnInit(): void {
    this.customerId = Number(this.storageService.getItem('customerId'));
    this.buildForm();
    this.getGreetingsData();
    this.getSpecialtyData();
    this.getDegreeData();
    this.getLocationData();
    this.getLanguagesData();
    this.getsecondaryLanguagesData();
    this.gettertiaryLanguagesData();
    this.getquaternaryLanguagesData();
    this.getSpecialtySecondaryData();
    this.getBoardCertificationData();
    this.getStatesData();
    this.fieldsData && this.bindFormValue(this.fieldsData);
    this.datepicker = Date.now();
    this.afterSaveDataDisplay();
    
  }

  public bindFormValue(array) {
    let fieldsCtrls = {};
    for (let i = 0; i < array.length; i++) {
      fieldsCtrls[array[i].field_name] = array[i].field_value;
      if (array[i].field_name == 'cmd_100009') {
        if (array[i].field_value != '') {
          this.previewEsign(array[i].field_value, array[i].field_name);
        }
      }
      if (array[i].field_name == 'cmd_100096') {
        if (array[i].field_value != '') {
          this.previewEsign(array[i].field_value, array[i].field_name);
        }
      }
    }
    this.southGeorgiaFormOne.patchValue(fieldsCtrls);
  }

  public previewEsign(filePath, fieldId) {
    //this.spinner.show();
    let params = {
      filePath: filePath
    };
    this.onboardService.getPreviewEsign(params).subscribe(
      (data: any) => {
        if (data['success']) {
          this.ImgOne = data.base64;
          this.setEsign(this.ImgOne, fieldId);
        } else {
          //this.notiService.showError(data.error, '', 3000)
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public setEsign(filePath, fieldId) {
    this.imgOneSetting = 1;
    // console.log(filePath);
    if (fieldId == 'cmd_100009') {
      this.esignOne = this._sanitizer.bypassSecurityTrustResourceUrl(
        'data:image/jpg;base64,' + filePath
      );
      this.showOne = true;
    }
    if (fieldId == 'cmd_100096') {
      this.esignTwo = this._sanitizer.bypassSecurityTrustResourceUrl(
        'data:image/jpg;base64,' + filePath
      );
      this.showTwo = true;
    }
  }

  public getGreetingsData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getGreetingsData(params).subscribe(
      data => {
        if (data['success']) {
          this.greetings = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getStatesData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getStatesData(params).subscribe(
      data => {
        if (data['success']) {
          this.states = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getSpecialtyData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getSpecialtyData(params).subscribe(
      data => {
        if (data['success']) {
          this.specialty = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  // public returnLabel(data) {
  //   let str = '';
  //   if (data.practice_name != null) {
  //     str = str + data.practice_name + ' ';
  //   }
  //   if (data.practice__add1 != null) {
  //     str = str + data.practice__add1 + ' ';
  //   }
  //   if (data.practice__add2 != null) {
  //     str = str + data.practice__add2 + ' ';
  //   }
  //   if (data.tin != null) {
  //     str = str + data.tin + ' ';
  //   }
  //   return str;

  // }

  public toGetLocationDetailsOne(event) {
    let params = {
      locationId: event
    };
    this.onboardService.toGetLocationDetails(params).subscribe(
      data => {
        if (data['success']) {
          // console.log(data.data['0'].pay_to__state);
          let temp = {
            cmd_100045: data.data['0'].practice__add1,
            cmd_100047: data.data['0'].practice__add2,
            cmd_100049: data.data['0'].practice__city,
            cmd_100093: data.data['0'].practice__state.toString(),
            cmd_100052: data.data['0'].practice__zip,
            cmd_100053: data.data['0'].phone,
            cmd_100056: data.data['0'].fax,
            cmd_110009: data.data['0'].practice_mailing_address_add1,
            cmd_110010: data.data['0'].practice_mailing_address_add2,
            cmd_110011: data.data['0'].practice_mailing_address_city,
            cmd_110013: data.data['0'].practice_mailing_address_zip,
            cmd_110012: data.data[
              '0'
            ].practice_mailing_address_state.toString(),
            cmd_110006: data.data['0'].pay_to__add1,
            cmd_100054: data.data['0'].pay_to__city,
            cmd_100055: data.data['0'].pay_to__state.toString(),
            cmd_100057: data.data['0'].pay_to__zip,
            cmd_100058: data.data['0'].pay_to_telephone,
            cmd_100059: data.data['0'].pay_to_fax,
            cmd_110015: data.data['0'].office_manage_email,
            cmd_100044: data.data['0'].tin_id,
            1634: data.data['0'].location_npi,
            cmd_110016: data.data['0'].office_manager_first_name,
            cmd_110017: data.data['0'].office_manager_last_name,
            cmd_100097: event,
            cmd_100011: data.data['0'].tin_id
          };
          // this.locationSet(event);
          this.southGeorgiaFormOne.patchValue(temp);
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        this.notiService.showError(Error_Message, '', 3000);
      }
    );
  }

  public locationSet(event) {
    let temp = {
      cmd_100097: event.id
    };
    this.southGeorgiaFormOne.patchValue(temp);
  }

  public toGetLocationDetailsTwo(event) {
    let params = {
      locationId: event
    };
    this.onboardService.toGetLocationDetails(params).subscribe(
      data => {
        if (data['success']) {
          // console.log(data);
          let temp = {
            cmd_100061: data.data['0'].practice__add1,
            cmd_100063: data.data['0'].practice__add2,
            cmd_100065: data.data['0'].practice__city,
            cmd_100094: data.data['0'].practice__state.toString(),
            cmd_1000681: data.data['0'].practice__zip,
            cmd_100069: data.data['0'].phone,
            cmd_1000721: data.data['0'].fax,
            cmd_110007: data.data['0'].pay_to__add1,
            cmd_1000701: data.data['0'].pay_to__city,
            cmd_100071: data.data['0'].pay_to__state.toString(),
            cmd_100073: data.data['0'].pay_to__zip,
            cmd_100074: data.data['0'].pay_to_telephone,
            cmd_100075: data.data['0'].pay_to_fax,
            cmd_100060: data.data['0'].tin_id,
            cmd_100062: data.data['0'].location_npi,
            cmd_100066: data.data['0'].office_manager_first_name,
            cmd_100067: data.data['0'].office_manager_last_name
          };
          this.southGeorgiaFormOne.patchValue(temp);
          //cmd_100061  ------------  practice__add1
          //cmd_100063  ------------ practice__add2
          //cmd_100065  ----------- practice__city
          //cmd_100094 -------------- practice__state
          //cmd_1000681 ----------- practice__zip
          //cmd_100069 ---------- phone
          //cmd_1000721 ---------fax
          //cmd_110007  -------------  pay_to__add1
          //cmd_1000701   ---------  pay_to__city
          //cmd_100071   ---------pay_to__state
          //cmd_100073         -----------------   pay_to__zip
          //cmd_100074   -----------  pay_to_telephone
          //cmd_100075    ----------   pay_to_fax
          //cmd_100060  -------------  tin_id
          //cmd_100062   --------  location_npi
          //cmd_100066     -----------  office_manager_first_name
          //cmd_100067      -------------   office_manager_last_name
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        this.notiService.showError(Error_Message, '', 3000);
      }
    );
  }

  public toGetLocationDetailsThree(event) {
    let params = {
      locationId: event
    };
    this.onboardService.toGetLocationDetails(params).subscribe(
      data => {
        if (data['success']) {
          // console.log(data);
          let temp = {
            cmd_100077: data.data['0'].practice__add1,
            cmd_100078: data.data['0'].practice__add2,
            cmd_100080: data.data['0'].practice__city,
            cmd_100095: data.data['0'].practice__state.toString(),
            cmd_100083: data.data['0'].practice__zip,
            cmd_100084: data.data['0'].phone,
            cmd_100087: data.data['0'].fax,
            cmd_110008: data.data['0'].pay_to__add1,
            cmd_100085: data.data['0'].pay_to__city,
            cmd_100086: data.data['0'].pay_to__state.toString(),
            cmd_100088: data.data['0'].pay_to__zip,
            cmd_100089: data.data['0'].pay_to_telephone,
            cmd_100090: data.data['0'].pay_to_fax,
            cmd_100076: data.data['0'].tin_id,
            cmd_100092: data.data['0'].location_npi,
            cmd_100081: data.data['0'].office_manager_first_name,
            cmd_100082: data.data['0'].office_manager_last_name
          };
          this.southGeorgiaFormOne.patchValue(temp);
          //cmd_100077  ------------  practice__add1
          //cmd_100078  ------------ practice__add2
          //cmd_100080  ----------- practice__city
          //cmd_100095 -------------- practice__state
          //cmd_100083 ----------- practice__zip
          //cmd_100084 ---------- phone
          //cmd_100087 ---------fax
          //cmd_110008  -------------  pay_to__add1
          //cmd_100085   ---------  pay_to__city
          //cmd_100086   ---------pay_to__state
          //cmd_100088         -----------------   pay_to__zip
          //cmd_100089   -----------  pay_to_telephone
          //cmd_100090    ----------   pay_to_fax
          //cmd_100076  -------------  tin_id
          //cmd_100092   --------  location_npi
          //cmd_100081     -----------  office_manager_first_name
          //cmd_100082      -------------   office_manager_last_name
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        this.notiService.showError(Error_Message, '', 3000);
      }
    );
  }

  public getSpecialtySecondaryData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getSpecialtySecondaryData(params).subscribe(
      data => {
        if (data['success']) {
          this.specialtySecondary = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getDegreeData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getDegreeData(params).subscribe(
      data => {
        if (data['success']) {
          this.degree = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getBoardCertificationData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getBoardCertificationData(params).subscribe(
      data => {
        if (data['success']) {
          this.boardCertification = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public afterSaveDataDisplay() {
    let params = {
      type: 19,
      formId: this.formId
    };
    //this.spinner.show();
    this.onboardService.toGetSaveData(params).subscribe(
      data => {
        if (data['success']) {
          this.bindFormValue(data.data);
        } else {
          //this.notiService.showError(data.error, '', 3000)
        }
        //this.spinner.hide();
      },
      error => {
        //this.notiService.showError(Error_Message, '', 3000)
      }
    );
  }

  public getLocationData() {
    let params: {};
    if (this.emailCustId) {
      params = {
        customerId: this.emailCustId
      };
    } else {
      params = {
        customerId: this.customerId
      };
    }
    //this.spinner.show();
    this.onboardService.getLocationData(params).subscribe(
      data => {
        if (data['success']) {
          this.locDropdown = data.data.map(i => ({
            ...i,
            id: i.location_id.toString(),
            label:
              (i.practice_name ? i.practice_name : '') +
              ' ' +
              (i.practice__add1 ? i.practice__add1 : '') +
              ' ' +
              (i.practice__add2 ? i.practice__add2 : '') +
              ' ' +
              (i.tin ? i.tin : '')
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getLanguagesData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getLanguagesData(params).subscribe(
      data => {
        if (data['success']) {
          this.languages = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getsecondaryLanguagesData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getsecondaryLanguagesData(params).subscribe(
      data => {
        if (data['success']) {
          this.secondaryLanguages = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public gettertiaryLanguagesData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.gettertiaryLanguagesData(params).subscribe(
      data => {
        if (data['success']) {
          this.tertiaryLanguages = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getquaternaryLanguagesData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getquaternaryLanguagesData(params).subscribe(
      data => {
        if (data['success']) {
          this.quaternaryLanguages = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  buildForm() {
    this.southGeorgiaFormOne = this.fb.group({
      cmd_110016: [''],
      cmd_110017: [''],
      822: [''],
      8: [null, [Validators.required]],
      9: ['', [Validators.required]],
      1994: [null, [Validators.required,this.dateFormatValidator]],
      cmd_110014: [''],
      cmd_110114: [''],
      811: [null, [Validators.required]],
      284: [null],
      cmd_110214: [null],
      1449: [null, [Validators.required,this.dateFormatValidator]],
      cmd_110001: [''],
      cmd_110002: [''],
      500: [null, [Validators.required]],
      1218: ['', [Validators.required]],
      1634: [''],
      325: ['', [Validators.required]],
      277: [null, [Validators.required]],
      1535: [null],
      1536: [null],
      2207: [null],
      cmd_110215: [null],
      962: [null, [Validators.required]],
      554: [null, [Validators.required]],
      1169: ['', [Validators.required]],
      557: [null, [Validators.required,this.dateFormatValidator]],
      560: [null, [Validators.required,this.dateFormatValidator]],
      561: [null],
      562: [''],
      564: [null ,[this.dateFormatValidator]],
      567: [null,[this.dateFormatValidator]],
      946: [null],
      951: [''],
      953: [null,[this.dateFormatValidator]],
      954: [null,[this.dateFormatValidator]],
      cmd_100009: [''],
      955: [null],
      956: [''],
      957: [null,[this.dateFormatValidator]],
      961: [null,[this.dateFormatValidator]],
      759: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
      cmd_110015: [''],
      cmd_110009: [''],
      cmd_100013: [''],
      cmd_110010: [''],
      cmd_110011: [''],
      cmd_110012: [null],
      cmd_110013: [''],
      cmd_100040: [null, [Validators.required]],
      cmd_100044: [''],
      cmd_100046: [''],
      cmd_100048: [''],
      cmd_100050: [''],
      cmd_100051: [''],
      cmd_100053: [''],
      cmd_100056: [''],
      cmd_110006: [''],
      cmd_100093: [null],
      cmd_100054: [''],
      cmd_100057: [''],
      cmd_100058: [''],
      cmd_100059: [''],
      cmd_100045: [''],
      cmd_100047: [''],
      cmd_100049: [''],
      cmd_100055: [null],
      cmd_100052: [''],
      cmd_100041: [null, [Validators.required]],
      cmd_100060: [''],
      cmd_100062: [''],
      cmd_100064: [''],
      cmd_100066: [''],
      cmd_100067: [''],
      cmd_100069: [''],
      cmd_1000721: [''],
      cmd_110007: [''],
      cmd_100071: [null],
      cmd_1000701: [''],
      cmd_100073: [''],
      cmd_100074: [''],
      cmd_100075: [''],
      cmd_100061: [''],
      cmd_100063: [''],
      cmd_100065: [''],
      cmd_1000681: [''],
      cmd_100094: [null],
      cmd_100042: [null, [Validators.required]],
      cmd_100076: [''],
      cmd_100092: [''],
      cmd_100081: [''],
      cmd_100079: [''],
      cmd_100082: [''],
      cmd_100084: [''],
      cmd_100087: [''],
      cmd_110008: [''],
      cmd_100086: [null],
      cmd_100085: [''],
      cmd_100088: [''],
      cmd_100089: [''],
      cmd_100090: [''],
      cmd_100077: [''],
      cmd_100078: [''],
      cmd_100080: [''],
      cmd_100083: [''],
      cmd_100095: [null],
      cmd_100014: ['', [Validators.required]],
      cmd_100015: ['', [Validators.required]],
      cmd_100016: ['', [Validators.required]],
      cmd_100017: ['', [Validators.required]],
      cmd_100018: ['', [Validators.required]],
      cmd_100019: ['', [Validators.required]],
      cmd_100020: ['', [Validators.required]],
      cmd_100021: ['', [Validators.required]],
      cmd_100022: ['', [Validators.required]],
      cmd_100023: ['', [Validators.required]],
      cmd_100024: ['', [Validators.required]],
      cmd_100025: ['', [Validators.required]],
      cmd_100026: ['', [Validators.required]],
      cmd_100027: ['', [Validators.required]],
      cmd_100028: ['', [Validators.required]],
      cmd_100029: ['', [Validators.required]],
      cmd_100030: ['', [Validators.required]],
      cmd_100031: ['', [Validators.required]],
      cmd_100032: ['', [Validators.required]],
      cmd_100033: ['', [Validators.required]],
      cmd_100034: ['', [Validators.required]],
      cmd_100035: ['', [Validators.required]],
      cmd_100036: ['', [Validators.required]],
      cmd_100037: ['', [Validators.required]],
      cmd_100038: ['', [Validators.required]],
      cmd_100008: [null, [Validators.required,this.dateFormatValidator]],
      cmd_110003: [''],
      cmd_110004: [''],
      cmd_110216: [null],
      cmd_100011: [''],
      cmd_100097: [null],
      cmd_100099: [null],
      cmd_100001: [''],
      cmd_100002: [''],
      cmd_100003: [''],
      cmd_100004: [null,[this.dateFormatValidator]],
      cmd_110005: [''],
      cmd_100096: [''],
      cmd_100007: [null, [Validators.required,this.dateFormatValidator]]
    });
    // console.log(this.southGeorgiaFormOne);
  }

  onChangeFirstName(event) {
    //cmd_110001
    //cmd_100001
    //cmd_110003
    //cmd_110005 first + last
    this.firstName = event.target.value;

    this.finalName = this.firstName + ' ' + this.lastName;
    let temp = {
      cmd_110001: event.target.value,
      cmd_100001: event.target.value,
      cmd_110003: event.target.value,
      cmd_110005: this.finalName
    };
    this.southGeorgiaFormOne.patchValue(temp);
  }

  onChangeLastName(event) {
    //cmd_110014
    //cmd_110114
    //cmd_110002
    //cmd_110004
    //cmd_100002
    //cmd_110005  first + last
    this.lastName = event.target.value;
    this.finalName = this.firstName + ' ' + this.lastName;
    let temp = {
      cmd_110014: event.target.value,
      cmd_110114: event.target.value,
      cmd_110002: event.target.value,
      cmd_110004: event.target.value,
      cmd_100002: event.target.value,
      cmd_110005: this.finalName
    };
    this.southGeorgiaFormOne.patchValue(temp);
  }

  onChangeGreetings(event) {
    //cmd_110214
    //this.southGeorgiaFormOne.controls['cmd_110214'].setValue(event)
    //this.greetingDropDown = event.id
    let temp = {
      cmd_110214: event.id
    };
    this.southGeorgiaFormOne.patchValue(temp);
  }

  onChangeSpecialty(event) {
    //cmd_110215
    //cmd_100099
    let temp = {
      cmd_110215: event.id,
      cmd_100099: event.id
    };
    this.southGeorgiaFormOne.patchValue(temp);
    //this.specialtyDropDown = event.id
  }

  onChangedegree(event) {
    let temp = {
      cmd_110216: event.id
    };
    this.southGeorgiaFormOne.patchValue(temp);
    //cmd_110216
    //this.degreeDropDown = event.id
  }
  dateFormatValidator(control: FormControl): ValidationErrors | null {
    const dateString = control.value;
    const dateFormat = /^\d{2}\/\d{2}\/\d{4}$/;
  
    if (dateString && !dateString.match(dateFormat)) {
      return { 'invalidDateFormat': true };
    }
  


    if (dateString) {
      const dateParts = dateString.split('/');
      const month = parseInt(dateParts[0]);
      const day = parseInt(dateParts[1]);
      const year = parseInt(dateParts[2]);
  
      // Check if any of the date components are zero or month is not between 1 and 12
      
  
      const ListofDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
      
      
      if (month < 1 || month > 12 || day === 0 || year === 0) {
        return { 'invalidMonth': true };
      }
      if (month === 1 || month > 2) {
        if (day > ListofDays[month - 1]) {
          return { 'invalidDateRange': true };
        }
      } else if (month === 2) {
        const leapYear = (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
        if (!leapYear && day >= 29) {
          return { 'invalidDateRange': true };
        }
         else if (leapYear && day > 29) {
          return { 'invalidDateRange': true };
        }
      }
      if (year < 1900 || year > 2099) {
        return { 'invalidDateyear': true };
      }
      
     
    }
  
    return null;
  }
  clearInputIfInvalid(controlName: string,handleYesNoValue?:string) {
    const control = this.southGeorgiaFormOne.get(controlName);
    const dateString = control.value;
  
    // Use the dateFormatValidator to check if the date is valid
    const validationErrors = this.dateFormatValidator(new FormControl(dateString));
  
    if (validationErrors) {
      // If any of the validation conditions are met, clear the input field and set errors to null
      control.setValue('');
      control.setErrors(null);
    }
    
    
  }
  clearInputIfInvalidMandi(controlName: string) {
    const control = this.southGeorgiaFormOne.get(controlName);
    const dateString = control.value;
  
    // Use the dateFormatValidator to check if the date is valid
    const validationErrors = this.dateFormatValidator(new FormControl(dateString));
  
    if (validationErrors) {
      // If any of the validation conditions are met, clear the input field and set errors to null
      control.setValue('');

    }}
  saveForm(type) {
    this.isSubmit = true;
    if (type == 'submit') {
      if (this.southGeorgiaFormOne.invalid) {
        this.notiService.showError(
          'Please Fill the all mandatory fields to continue',
          '',
          3000
        );
      } else {
        let data = {
          formType: type,
          formValues: this.southGeorgiaFormOne.value
        };
        this.onSubmit.emit(data);
      }
    } else {
      let data = {
        formType: type,
        formValues: this.southGeorgiaFormOne.value,
        formId: 19
      };
      this.onSubmit.emit(data);
      //this.onClose.emit('close modal');
    }
  }

  close() {
    this.onClose.emit('close modal');
  }

  public esignOpenOne() {
    const modalRef = this.modalService.open(EsignComponent, this.modalOption);
    modalRef.result.then(result => {});
    modalRef.componentInstance.base64Img.subscribe(data => {
      modalRef.close();
      this.finalImgPath = data.finalFilePath;
      let temp = {
        cmd_100009: this.finalImgPath
      };
      this.southGeorgiaFormOne.patchValue(temp);
      this.finalImg = data.base64Img;
      this.esignOne = this._sanitizer.bypassSecurityTrustResourceUrl(
        'data:image/jpg;base64,' + this.finalImg
      );
      this.showOne = true;
    });
  }

  public esignOpenTwo() {
    const modalRef = this.modalService.open(EsignComponent, this.modalOption);
    modalRef.result.then(result => {});
    modalRef.componentInstance.base64Img.subscribe(data => {
      modalRef.close();
      this.finalImgPathTwo = data.finalFilePath;
      let temp = {
        cmd_100096: this.finalImgPathTwo
      };
      this.southGeorgiaFormOne.patchValue(temp);
      this.finalImgTwo = data.base64Img;
      this.esignTwo = this._sanitizer.bypassSecurityTrustResourceUrl(
        'data:image/jpg;base64,' + this.finalImgTwo
      );
      this.showTwo = true;
    });
  }
}
