import { Injectable } from '@angular/core';
import { ApiServiceService } from '../cmd-api-service/api-service.service';

@Injectable({
  providedIn: 'root'
})
export class MoveModalService {

  constructor(private apiService:ApiServiceService) { }
  //Provider
  public movedata(postParams){
    return this.apiService.post('providerpassport/movedata', postParams);
  }
}
