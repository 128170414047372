import { Injectable } from '@angular/core';
import { ApiServiceService } from '../../../shared/services/cmd-api-service/api-service.service';

@Injectable({
  providedIn: 'root'
})
export class SigninService {

  constructor(private apiService:ApiServiceService) { }
  
  public login(postParams){
      return this.apiService.post('verifylogin',postParams);
  } 

  public otpValidation(postParams){
    return this.apiService.post('verifyotp',postParams)
  }

  public resendOtp(postParams){
    return this.apiService.post('resendotp',postParams)
  }

  public forgetPassword(postParams){
    return this.apiService.post('forgotpassword',postParams)
  }

  public resetpassword(postParams){
    return this.apiService.post('resetpassword',postParams)
  }

  public updateEmail(postParams) {
    return this.apiService.post('fmaemailupdate', postParams)
  }

}
