import { Component, OnInit, Input,Output,EventEmitter } from '@angular/core';
import { ToasterNotiService } from 'apps/cmd-frontend/src/app/shared/services/notifications/toaster-noti.service';
import { OnboardingformsServiceService } from '../../../../../../services/onboardingforms-service/onboardingforms-service.service'
import { FormControl, ValidationErrors } from '@angular/forms';


@Component({
  selector: 'ctg-texas-section-one',
  templateUrl: './texas-section-one.component.html',
  styleUrls: ['./texas-section-one.component.scss']
})
export class TexasSectionOneComponent implements OnInit {
  questionCounter = 0;
  indexKey = 0;
  yesNoQuestionIds: any = [];
  yesNoQuestionExplanation: any = [];
  constructor(
    private onboardService:OnboardingformsServiceService,
    private notiService: ToasterNotiService) { }
  @Input() texas;
  @Input() isSubmit;
  @Input() usmilchange;
  @Input() ecfmgchange;
  @Input() certifiedchange;
  @Output() educationapp = new EventEmitter();
  @Output() profspecialtyapp = new EventEmitter();
  @Output() workhistoryapp = new EventEmitter();
  @Output() hospitalaffapp = new EventEmitter();
  @Output() referapp = new EventEmitter();
  @Output() professionalapp = new EventEmitter();
  @Output() usmilitary = new EventEmitter();
  @Output() ecfmgnumber = new EventEmitter();
  @Output() certified = new EventEmitter();
  suffix: Array<object> = [];
  greetings: Array<object> = [];
  specialty: Array<object> = [];
  specialtySecondary: Array<object> = [];
  degree: Array<object> = [];
  location: Array<object> = [];
  languages: Array<object> = [];
  credentials: Array<object> = [];  
  secondaryLanguages: Array<object> = [];
  tertiaryLanguages: Array<object> = [];
  quaternaryLanguages: Array<object> = [];
  boardCertification: Array<object> = [];
  imgOneSetting: any;
  AltCdsStates: Array<object> = [];
  altLicenseState: Array<object> = [];
  specialtyTer: Array<object> = [];
  additionalLicenseState: Array<object> = [];
  states : Array<object> = [];
  preClaim: Array<object> = [];
  aggregates: Array<object> = [];
  id : '';
  finalImgPath:any=[];
  finalImg:any;
  public country: any = [];
  esignOne:any;
  showOne:any = false;
  public eduapp : boolean =false;
  public workapp : boolean =false;
  public hospapp : boolean =false; 
  public refapp : boolean =false;
  public proapp : boolean =false; 
  public premlpapp : boolean =false;
  public prospeapp : boolean =false;

  
  ngOnInit(): void {
    // // console.log(this.states)
    this.getCredentialsData();
    this.getStatesData();
    this.getDegreeData();
    this.getSuffixData();
    this.getSpecialtyData();
    this.getBoardCertificationData();
    this.getAltCdsStatesData();
    this.getAlternateLicenseStateData();
    this.getAdditionalLicenseStateData();
    this.getTerSpecialtyData();
    this.getCountyData();
    this.getPreClaimData();
    this.getAggregatesData();
    // // console.log(this.credentials)
    // // console.log(this.degree)
    // // console.log(this.suffix)
  }

  public getCredentialsData(){
    let params : {}
    ////this.spinner.show();
    this.onboardService.getCredentialsData(params).subscribe(
      data => {
        if (data['success']) {
          this.credentials = data.data.map(i=>({
            ...i,id:i.id.toString()
          }))
        }
        else {
          this.notiService.showError(data.error, '', 3000)
        }
        ////this.spinner.hide();
      },
      error => {
        ////this.spinner.hide();
      } 
    ) 
  }

  public getCountyData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getCountyData(params).subscribe(
      data => {
        if (data['success']) {
          this.country = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }
  public getAggregatesData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getAggregatesData(params).subscribe(
      data => {
        if (data['success']) {
          this.aggregates = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getPreClaimData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getPreClaimData(params).subscribe(
      data => {
        if (data['success']) {
          this.preClaim = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getTerSpecialtyData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getTerSpecialtyData(params).subscribe(
      data => {
        if (data['success']) {
          this.specialtyTer = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getAlternateLicenseStateData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getAlternateLicenseStateData(params).subscribe(
      data => {
        if (data['success']) {
          this.altLicenseState = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getAltCdsStatesData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getAltCdsStatesData(params).subscribe(
      data => {
        if (data['success']) {
          this.AltCdsStates = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getAdditionalLicenseStateData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getAdditionalLicenseStateData(params).subscribe(
      data => {
        if (data['success']) {
          this.additionalLicenseState = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }


  public getStatesData(){
    let params : {}
    ////this.spinner.show();
    this.onboardService.getStatesData(params).subscribe(
      data => {
        if (data['success']) {
          this.states = data.data.map(i=>({
            ...i,id:i.id.toString()
          }))
        }
        else {
          this.notiService.showError(data.error, '', 3000)
        }
        ////this.spinner.hide();
      },
      error => {
        ////this.spinner.hide();
      } 
    )
  }

  public getDegreeData(){
    let params : {}
    //this.spinner.show();
    this.onboardService.getDegreeData(params).subscribe(
      data => {
        if (data['success']) {
          this.degree = data.data.map(i=>({
            ...i,id:i.id.toString()
          }))
        }
        else {
          this.notiService.showError(data.error, '', 3000)
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      } 
    ) 
  }

  public getSpecialtyData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getSpecialtyData(params).subscribe(
      data => {
        if (data['success']) {
          this.specialty = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getBoardCertificationData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getBoardCertificationData(params).subscribe(
      data => {
        if (data['success']) {
          this.boardCertification = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public education(event){
    // console.log(event.target.value,event.target.checked)
      this.educationapp.emit(event)
  }
  
  public proSpecApp(event){
    // console.log(event.target.value,event.target.checked)
      this.profspecialtyapp.emit(event)
  }

  public workHistApp(event){
    // console.log(event.target.value,event.target.checked)
      this.workhistoryapp.emit(event)
  }

  public hospAffApp(event){
    // console.log(event.target.value,event.target.checked)
      this.hospitalaffapp.emit(event)
  }

  public referencesApp(event){
    // console.log(event.target.value,event.target.checked)
      this.referapp.emit(event)
  }

  public proInsApp(event){
    // console.log(event.target.value,event.target.checked)
      this.professionalapp.emit(event)
  }

  public onChangeCmdOne(event){
    // console.log(event.target.value,event.target.checked)
      this.ecfmgnumber.emit(event)
  }

  public onChangeCmdTwo(event){
    // console.log(event.target.value,event.target.checked)
      this.usmilitary.emit(event)
  }

  public onChangeCmdThree(event){
    // console.log(event.target.value,event.target.checked)
      this.certified.emit(event)
  }


  public getSuffixData(){
    let params : {}
    ////this.spinner.show();
    this.onboardService.getSuffixData(params).subscribe(
      data => {
        if (data['success']) {
          this.suffix = data.data.map(i=>({
            ...i,id:i.id.toString()
          }))
        }
        else {
          this.notiService.showError(data.error, '', 3000)
        }
        ////this.spinner.hide();
      },
      error => {
        ////this.spinner.hide();
      } 
    )
  }
  dateFormatValidator(control: FormControl): ValidationErrors | null {
    const dateString = control.value;
    const dateFormat = /^\d{2}\/\d{2}\/\d{4}$/;
  
    if (dateString && !dateString.match(dateFormat)) {
      return { 'invalidDateFormat': true };
    }
  


    if (dateString) {
      const dateParts = dateString.split('/');
      const month = parseInt(dateParts[0]);
      const day = parseInt(dateParts[1]);
      const year = parseInt(dateParts[2]);
  
      // Check if any of the date components are zero or month is not between 1 and 12
      
  
      const ListofDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
      
      
      if (month < 1 || month > 12 || day === 0 || year === 0) {
        return { 'invalidMonth': true };
      }
      if (month === 1 || month > 2) {
        if (day > ListofDays[month - 1]) {
          return { 'invalidDateRange': true };
        }
      } else if (month === 2) {
        const leapYear = (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
        if (!leapYear && day >= 29) {
          return { 'invalidDateRange': true };
        }
         else if (leapYear && day > 29) {
          return { 'invalidDateRange': true };
        }
      }
      if (year < 1900 || year > 2099) {
        return { 'invalidDateyear': true };
      }
      
     
    }
  
    return null;
  }
  clearInputIfInvalidMandi(controlName: string) {
    const control = this.texas.get(controlName);
    const dateString = control.value;
  
    // Use the dateFormatValidator to check if the date is valid
    const validationErrors = this.dateFormatValidator(new FormControl(dateString));
  
    if (validationErrors) {
      // If any of the validation conditions are met, clear the input field and set errors to null
      control.setValue('');

    }}
    clearInputIfInvalid(controlName: string,handleYesNoValue?:string) {
      const control = this.texas.get(controlName);
      const dateString = control.value;
    
      // Use the dateFormatValidator to check if the date is valid
      const validationErrors = this.dateFormatValidator(new FormControl(dateString));
    
      if (validationErrors) {
        // If any of the validation conditions are met, clear the input field and set errors to null
        control.setValue('');
        control.setErrors(null);
      }
     
      
    }
    public validationsDataAdd(event?) {
      let yesNoQuestionIds = {
        cmd_0232: 'cmd_0255',
        cmd_0233: 'cmd_0257',
        cmd_0234: 'cmd_0259',
        cmd_0235: 'cmd_0261',
        cmd_0236: 'cmd_0263',
        cmd_0237: 'cmd_0265',
        cmd_0238: 'cmd_0267',
        cmd_0239: 'cmd_0269',
        cmd_0240: 'cmd_0271',
        cmd_0241: 'cmd_0273',
        cmd_0242: 'cmd_0275',
        cmd_0243: 'cmd_0277',
        cmd_0244: 'cmd_0279',
        cmd_0245: 'cmd_0281',
        cmd_0246: 'cmd_0283',
        cmd_0247: 'cmd_0285',
        cmd_0248: 'cmd_0287',
        cmd_0249: 'cmd_0289',
        cmd_0250: 'cmd_0291',
        cmd_0251: 'cmd_0293',
        cmd_0252: 'cmd_0295',
        cmd_0253: 'cmd_0297',
        cmd_0254: 'cmd_0299'
      };
      // ID of respective explanation section
      let yesNoQuestionExplanation = [
        'cmd_0255',
        'cmd_0257',
        'cmd_0259',
        'cmd_0261',
        'cmd_0263',
        'cmd_0265',
        'cmd_0267',
        'cmd_0269',
        'cmd_0271',
        'cmd_0273',
        'cmd_0275',
        'cmd_0277',
        'cmd_0279',
        'cmd_0281',
        'cmd_0283',
        'cmd_0285',
        'cmd_0287',
        'cmd_0289',
        'cmd_0291',
        'cmd_0293',
        'cmd_0295',
        'cmd_0297',
        'cmd_0299'
      ];
      this.questionCounter = 0;
      for (const key in yesNoQuestionIds) {
        if (yesNoQuestionIds.hasOwnProperty(key)) {
          // console.log(yesNoQuestionIds[key]);
          const element = this.texas.controls[key].value;
          // console.log(element);
          if (element == 1) {
            this.questionCounter++;
          }
        }
      }
      // console.log(this.questionCounter);
      for (this.indexKey = 0; this.indexKey < 23; this.indexKey++) {
        // console.log(yesNoQuestionExplanation[0]);
        if (this.indexKey < this.questionCounter) {
          // console.log(this.texas.controls[yesNoQuestionExplanation[this.indexKey]])
          if (this.texas.controls[yesNoQuestionExplanation[this.indexKey]].value == '' || this.texas.controls[yesNoQuestionExplanation[this.indexKey]].value == null) {
            // console.log('setting error')
          this.texas.controls[yesNoQuestionExplanation[this.indexKey]].setErrors(
            {
              errors: true
            }
          );
          }
        } else {
          // console.log('removing error');
          // console.log('else error');
          this.texas.controls[yesNoQuestionExplanation[this.indexKey]].setErrors(
            null
          );
        }
      }
      // for (const key in this.texas.controls) {
      //   if (this.texas.controls.hasOwnProperty(key)) {
      //     const element = this.texas.controls[key];
      //     if(element.status=="INVALID"){
      //     console.log(key + " " + element.status)
      //     }
      //   }
      // }
    }

}
