<div id="hap">
    
  <div class="header">
    <div class="row">
      <div class="col-md-2">
        <img class="logoImg" src="../../../../assets/images/HAP.png" 
        alt="Credential my Doc" />
      </div>
      <div class="col-md-10">
        <div  class="alginLeft">
          <h2>CredentialMyDoc Credentialing Application</h2>
        </div>
      </div>
    </div>

    
  </div>

    
  <div class="section">
      <form [formGroup]="hap">
          <div class="centered texasFirstTable">
            <h3 class="hThreeStyle">Section I-Individual Information</h3>
          </div>
          <table border="1" width="100%">
              <tr>
                  <td colspan="18">
                      <div class="row">
                        <div class="col-md-4">
                          TYPE OF PROFESSIONAL<span
                          class="mandatory">*</span>
                          <div class="form-group selectDrop dropDrownSet">
                            <ng-select
                              [items]="credentials"
                              [class.is-invalid]="
                                (hap.controls['822'].touched &&
                                hap.controls['822'].invalid) ||
                                (hap.controls['822'].invalid && isSubmit)
                              "
                              bindLabel="name"
                              placeholder="Select Degree"
                              bindValue="id"
                              formControlName="822"
                            >
                            </ng-select>
                            <div
                              class="formLabel col-md-2"
                              *ngIf="
                              hap.controls['822'].touched &&
                              hap.controls['822'].invalid
                              "
                            >
                              <small
                                class="text-danger"
                                *ngIf="hap.controls['822'].errors?.required"
                              >
                                required
                              </small>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>   
              </tr>
              <tr>
                  <td colspan="18">
                    <div class="row">
                      <div class="col-md-3">
                        <label class="formLabel">LAST NAME<span
                          class="mandatory">*</span></label>
                        <input
                          class="form-control"
                          id="9"
                          [class.is-invalid]="
                            (hap.controls['9'].touched &&
                            hap.controls['9'].invalid) ||
                            (hap.controls['9'].invalid && isSubmit)
                          "
                          name="9"
                          formControlName="9"
                          (change)="onChangeLastName($event)"
                          type="text"
                          data-id="lname"
                          placeholder="Last name "
                        />
                        <div
                          class="formLabel col-md-2"
                          *ngIf="
                          hap.controls['9'].touched &&
                          hap.controls['9'].invalid
                          "
                        >
                          <small
                            class="text-danger"
                            *ngIf="hap.controls['9'].errors?.required"
                          >
                            required
                          </small>
                        </div>
                      </div>
                      <div class="col-md-3 ">
                        <label class="formLabel">FIRST NAME<span
                          class="mandatory">*</span></label>
                        <input
                          class="form-control"
                          id="8"
                          [class.is-invalid]="
                            (hap.controls['8'].touched &&
                              hap.controls['8'].invalid) ||
                            (hap.controls['8'].invalid && isSubmit)
                          "
                          name="8"
                          (change)="onChangeFirstName($event)"
                          formControlName="8"
                          type="text"
                          data-id="fname"
                          placeholder="First Name"
                        />
                        <div
                          class="formLabel col-md-2"
                          *ngIf="
                            hap.controls['8'].touched &&
                            hap.controls['8'].invalid
                          "
                        >
                          <small
                            class="text-danger"
                            *ngIf="hap.controls['8'].errors?.required"
                          >
                            required
                          </small>
                        </div>
                      </div>
                      <div class="col-md-3 ">
                        <label class="formLabel">MIDDLE NAME</label>
                        <input
                          class="form-control"
                          id="809"
                          name="809"
                          formControlName="809"
                          type="text"
                          data-id="mname"
                          placeholder="Middle Name"
                        />
                      </div>
                      <div class="col-md-3 form-group">
                        <label class="formLabel"> (JR., SR., ETC.)Suffix</label>
                        <div class="form-group "> 
                          <ng-select
                            [items]="suffix"
                            bindLabel="name"
                            placeholder="Suffix"
                            bindValue="id"
                            formControlName="29"
                          >
                          </ng-select>
                        </div>
                      </div>
                    </div>
                  </td>
              </tr>
              <tr>
                  <td colspan="9">
                    <div class="row">
                      <div class="no-margin col-md-4">
                        <label class="formLabel">MAIDEN NAME </label>
                        <input
                          class="form-control"
                          id="278"
                          name="278"
                          formControlName="278"
                          type="text"
                          data-id="priorname"
                          placeholder="Prior Name"
                        />
                      </div>
                      <div class="col-md-6">
                        <label class="formLabel">YEARS ASSOCIATED (YYYY-YYYY) </label>
                        <div class="input-group">
                          <input
                            class="form-control "

                            ngbDatepicker
                            #d1="ngbDatepicker"
                            id="280"
                            name="280"
                            formControlName="280"
                            type="text"
                            data-id="priornameeffdate_date"
                            placeholder="Prior Name To Date"
                          />
                          <div class="input-group-append">
                            <button
                              class="btn btn-outline-secondary calendar"
                              (click)="d1.toggle()"
                              type="button"
                            >
                              <i class="fas fa-calendar-alt"></i>
                            </button>
                          </div>
                        </div>
                        <div  *ngIf="hap.get('280').invalid && hap.get('280').touched">
                          <small class="text-danger" *ngIf="hap.get('280').errors.invalidDateFormat">
                            Only Date Format is allowed
                          </small>
                        </div>
                        <div class="input-group">
                          <input
                            class="form-control onboard datepicker"
                            placeholder="MM/DD/YYYY"
                            ngbDatepicker
                            #d2="ngbDatepicker"
                            id="281"
                            name="281"
                            formControlName="281"
                            type="text"
                            data-id="priornametermdate_date"
                            placeholder="Prior Name From Date"
                          />
                          <div class="input-group-append">
                            <button
                              class="btn btn-outline-secondary calendar"
                              (click)="d2.toggle()"
                              type="button"
                            >
                              <i class="fas fa-calendar-alt"></i>
                            </button>
                          </div>
                        </div>
                        <div  *ngIf="hap.get('281').invalid && hap.get('281').touched">
                          <small class="text-danger" *ngIf="hap.get('281').errors.invalidDateFormat">
                            Only Date Format is allowed
                          </small>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td colspan="9">
                    <div class="row">
                      <div class="col-md-6">
                        <label class="formLabel">OTHER NAME </label>
                        <input
                          class="form-control"
                          id="2337"
                          name="2337"
                          formControlName="2337"
                          type="text"
                          data-id="othername"
                        />
                      </div>
                      <div class="form-group col-md-6">
                        <label class="formLabel">YEARS ASSOCIATED(YYYY-YYYY)</label>
                        <div class="input-group">
                          <input
                            class="form-control onboard datepicker"
                            placeholder="MM/DD/YYYY"
                            ngbDatepicker
                            #d3="ngbDatepicker"
                            id="cmd_01"
                            name="cmd_01"
                            formControlName="cmd_01"
                            type="text"
                            data-id="othernameeffdate_date"
                          />
                          <div class="input-group-append">
                            <button
                              class="btn btn-outline-secondary calendar"
                              (click)="d3.toggle()"
                              type="button"
                            >
                              <i class="fas fa-calendar-alt"></i>
                            </button>
                          </div>
                        </div>
                        <div  *ngIf="hap.get('cmd_01').invalid && hap.get('cmd_01').touched">
                          <small class="text-danger" *ngIf="hap.get('cmd_01').errors.invalidDateFormat">
                            Only Date Format is allowed
                          </small>
                        </div>
                        <div class="input-group">
                          <input
                            class="form-control onboard datepicker"
                            placeholder="MM/DD/YYYY"
                            ngbDatepicker
                            #d4="ngbDatepicker"
                            id="cmd_02"
                            name="cmd_02"
                            formControlName="cmd_02"
                            type="text"
                            data-id="othernametermdate_date"
                          />
                          <div class="input-group-append">
                            <button
                              class="btn btn-outline-secondary calendar"
                              (click)="d4.toggle()"
                              type="button"
                            >
                              <i class="fas fa-calendar-alt"></i>
                            </button>
                          </div>
                        </div>
                        <div  *ngIf="hap.get('cmd_02').invalid && hap.get('cmd_02').touched">
                          <small class="text-danger" *ngIf="hap.get('cmd_02').errors.invalidDateFormat">
                            Only Date Format is allowed
                          </small>
                        </div>
                      </div>
                    </div>
                  </td>
              </tr>
              <tr>
                  <td colspan="18">
                    <div class="form-group form-inline no-margin sideLabelAlignment">
                      <label class="formLabel">HOME MAILING ADDRESS<span
                        class="mandatory">*</span></label>
                    <div class="col-md-3"><input
                      class="form-control"
                      [class.is-invalid]="
                        (hap.controls['16'].touched &&
                          hap.controls['16'].errors) ||
                        (hap.controls['16'].errors && isSubmit)
                      "
                      id="16"
                      name="16"
                      formControlName="16"
                      type="text"
                      data-id="homeaddress1"
                      placeholder="Home Address 1  "
                    />
                    <div
                      class="formLabel col-md-2"
                      *ngIf="
                        hap.controls['16'].touched &&
                        hap.controls['16'].errors
                      "
                    >
                      <small
                        class="text-danger"
                        *ngIf="hap.controls['16'].errors?.required"
                      >
                        required
                      </small>
                    </div></div>
                      <div class=col-md-3>
                        <input
                        class="form-control"
                        [class.is-invalid]="
                          (hap.controls['17'].touched &&
                            hap.controls['17'].errors) ||
                          (hap.controls['17'].errors && isSubmit)
                        "
                        id="17"
                        name="17"
                        formControlName="17"
                        type="text"
                        data-id="homeaddress2"
                        placeholder="Home Address 2  "
                      />
                      <div
                        class="formLabel col-md-2"
                        *ngIf="
                          hap.controls['17'].touched &&
                          hap.controls['17'].errors
                        "
                      >
                        <small
                          class="text-danger"
                          *ngIf="hap.controls['17'].errors?.required"
                        >
                          required
                        </small>
                      </div>
                      </div>
                      
                    </div>
                  </td>
              </tr>
              <tr>
                  <td colspan="18">
                    <div class="row">
                      <div class="col-md-4">
                        <label class="formLabel">CITY<span
                          class="mandatory">*</span></label>
                        <input
                          class="form-control"
                          [class.is-invalid]="
                            (hap.controls['18'].touched &&
                              hap.controls['18'].invalid) ||
                            (hap.controls['18'].invalid && isSubmit)
                          "
                          id="18"
                          name="18"
                          formControlName="18"
                          type="text"
                          data-id="homecity"
                          placeholder="Home City "
                        />
                        <div
                          class="formLabel col-md-2"
                          *ngIf="
                            hap.controls['18'].touched &&
                            hap.controls['18'].invalid
                          "
                        >
                          <small
                            class="text-danger"
                            *ngIf="hap.controls['18'].errors?.required"
                          >
                            required
                          </small>
                        </div>
                      </div>
                      <div class="form-group col-md-4">
                        <label class="formLabel ">STATE/COUNTRY<span
                          class="mandatory">*</span></label>
                        <div class="form-group ">
                          <ng-select
                            [items]="states"
                            [class.is-invalid]="
                              (hap.controls['20'].touched &&
                                hap.controls['20'].invalid) ||
                              (hap.controls['20'].invalid && isSubmit)
                            "
                            bindLabel="name"
                            placeholder="Select States"
                            bindValue="id"
                            formControlName="20"
                            placeholder="State or Country"
                          >
                          </ng-select>
                          <div
                            class="formLabel col-md-2"
                            *ngIf="
                              hap.controls['20'].touched &&
                              hap.controls['20'].invalid
                            "
                          >
                            <small
                              class="text-danger"
                              *ngIf="hap.controls['20'].errors?.required"
                            >
                              required
                            </small>
                          </div>
                        </div>
                      </div>
                      <div class="form-group col-md-4">
                        <label class="formLabel">POSTAL CODE<span
                          class="mandatory">*</span></label>
                        <input
                          class="form-control "
                          
                          [class.is-invalid]="
                            (hap.controls['21'].touched &&
                              hap.controls['21'].invalid) ||
                            (hap.controls['21'].invalid && isSubmit)
                          "
                          id="21"
                          name="21"
                          (keypress)="numberOnly($event)"
                          formControlName="21"
                          type="text"
                          data-id="homezip"
                          placeholder="Home Zip "
                        />
                        <!-- <div

                          class="formLabel col-md-2"
                          *ngIf="
                            hap.controls['21'].touched &&
                            hap.controls['21'].invalid
                          "
                        ><small
                        class="text-danger"
                        *ngIf="
                          hap.controls['21'].errors?.minlength ||
                          hap.controls['21'].errors?.maxlength
                        "
                      ><div> 
                        Zip code must be atleast 5 digits and less than 11 digits</div>
                      </small>
                          <small
                            class="text-danger"
                            *ngIf="hap.controls['21'].errors?.required"
                          >
                            required
                          </small>
                          
                        
                        </div> -->
                     
                       
                     <div

                          class="formLabel col-md-2"
                          *ngIf="
                            hap.controls['21'].touched &&
                            hap.controls['21'].invalid
                          "
                        > <small
                        class="text-danger"
                        *ngIf="hap.controls['21'].errors?.required"
                      >
                        required
                      </small>
                      </div>
                        
                        <!-- <div class="formLabel col-md-2" *ngIf="hap.controls['21'].touched && hap.controls['21'].invalid">
                          <small class="text-danger">
                            <ng-container *ngIf="hap.controls['21'].errors?.required">Required</ng-container>
                            <ng-container *ngIf="hap.controls['21'].errors?.minlength || hap.controls['21'].errors?.maxlength">
                              Zip code must be between 5 and 10 characters and can contain both letters and numbers.
                            </ng-container>
                          </small>
                        </div> -->
                      </div>
                    </div>
                  </td>
              </tr>
              <tr>
                  <td colspan="6">
                    <div class="form-inline no-margin col-md-12 sideLabelAlignment">
                      <label class="formLabel">HOME PHONE NUMBER<span
                        class="mandatory">*</span> </label>
                      <input
                        class="form-control"
                        (keypress)="numberOnly($event)"
                        [class.is-invalid]="
                          (hap.controls['708'].touched &&
                            hap.controls['708'].invalid) ||
                          (hap.controls['708'].invalid && isSubmit)
                        "
                        id="708"
                        name="708"
                        formControlName="708"
                        type="text"
                        data-id="homephone"
                        placeholder="Home Phone "
                      />
                      <div
                        class="formLabel col-md-2"
                        *ngIf="
                          hap.controls['708'].touched &&
                          hap.controls['708'].invalid
                        "
                      >
                        <small
                          class="text-danger"
                          *ngIf="hap.controls['708'].errors?.required"
                        >
                          required
                        </small>
                      </div>
                    </div>
                  </td>
                  <td colspan="6">
                    <div
                      class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
                    >
                      <label class="formLabel">SOCIAL SECURITY NUMBER <span
                        class="mandatory">*</span></label>
                      <input
                        class="form-control"
                        [class.is-invalid]="
                          (hap.controls['32'].touched &&
                            hap.controls['32'].invalid) ||
                          (hap.controls['32'].invalid && isSubmit)
                        "
                        id="32"
                        name="32"
                        formControlName="32"
                        type="text"
                        data-id="ssn"
                        placeholder="SSN"
                        (ngModelChange)="getSsn($event)"
                      />
                      <div
                        class="formLabel col-md-2"
                        *ngIf="
                          hap.controls['32'].touched &&
                          hap.controls['32'].invalid
                        "
                      >
                        <small
                          class="text-danger"
                          *ngIf="hap.controls['32'].errors?.required"
                        >
                          required
                        </small>
                      </div>
                    </div>
                  </td>
                  <td colspan="6">
                    <div class="form-group no-margin col-md-12 sideLabelAlignment">
                      <label class="formLabel">GENDER<span
                        class="mandatory">*</span></label>
                      <div class="form-group selectDrop dropDrownSet">
                        <ng-select
                          [items]="gender"
                          bindLabel="name"
                          [class.is-invalid]="
                          (hap.controls['30'].touched &&
                            hap.controls['30'].invalid) ||
                          (hap.controls['30'].invalid && isSubmit)
                        "
                          placeholder="Select Gender"
                          bindValue="id"
                          formControlName="30"
                          placeholder="Gender"
                        >
                        </ng-select>
                        <div
                        class="formLabel col-md-2"
                        *ngIf="
                          hap.controls['30'].touched &&
                          hap.controls['30'].invalid
                        "
                      >
                        <small
                          class="text-danger"
                          *ngIf="hap.controls['30'].errors?.required"
                        >
                          required
                        </small>
                      </div>
                      </div>
                    </div>
                  </td>
              </tr>
              <tr>
                <td colspan="18">
                  <div class="form-group form-inline no-margin sideLabelAlignment">
                    <label class="formLabel">CORRESPONDENCE ADDRESS </label>
                    <div class="col-md-3">
                      <input
                        class="form-control"
                        id="cmd_837"
                        name="cmd_837"
                        formControlName="cmd_837"
                        type="text"
                        data-id="prac1_MailingAddress1"
                      />
                    </div>
                    <div class="col-md-3">
                      <input
                        class="form-control"
                        id="cmd_838"
                        name="cmd_838"
                        formControlName="cmd_838"
                        type="text"
                        data-id="prac1_MailingAddress2"
                      />
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                  <td colspan="18">
                    <div class="row">
                      <div class="col-md-4">
                        <label class="formLabel">CITY</label>
                        <input
                          class="form-control"
                          id="cmd_839"
                          name="cmd_839"
                          formControlName="cmd_839"
                          type="text"
                          data-id="prac1_MailingCity"
                        />
                      </div>
                      <div class="col-md-4">
                        <label class="formLabel ">STATE/COUNTRY</label>
                        <div class="form-group dropDrownSet">
                          <div class="">
                            <ng-select
                              [items]="states"
                              bindLabel="name"
                              placeholder="State or Country"
                              bindValue="id"
                              formControlName="cmd_841"
                            >
                            </ng-select>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <label class="formLabel">POSTAL CODE</label>
                        <input
                          class="form-control "
                          id="cmd_842"
                          (keypress)="numberOnly($event)"
                          name="cmd_842"
                          formControlName="cmd_842"
                          type="text"
                          data-id="prac1_MailingZIP"
                        />
                        <div *ngIf="hap.get('cmd_842').invalid && hap.get('cmd_842').touched">
                          <small class="text-danger" *ngIf="hap.get('cmd_842').errors.invalidZipCode">
                            Zip code must be atleast 5 digits and less than 11 digits.
                          </small>
                        </div>
                      </div>

                    </div>
                  </td>
              </tr>
              <tr>
                  <td colspan="6">
                    <div class="form-inline no-margin col-md-12 sideLabelAlignment">
                      <label class="formLabel">PHONE NUMBER</label>
                      <input
                        class="form-control"
                        id="cmd_870"
                        name="cmd_870"
                        (keypress)="numberOnly($event)"
                        formControlName="cmd_870"
                        type="text"
                        data-id="prac1_mailingphone"
                        
                      />
                      <small class="text-danger" *ngIf="hap.controls['cmd_870'].errors?.pattern">
                        Only Phone number Format is allowed</small>
                    </div>
                  </td>
                  <td colspan="6">
                    <div
                      class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
                    >
                      <label class="formLabel">FAX NUMBER </label>
                      <input
                        class="form-control"
                        id="cmd_871"
                        name="cmd_871"
                        (keypress)="numberOnly($event)"
                        formControlName="cmd_871"
                        type="text"
                        data-id="prac1_mailingfax"
                      />
                    </div>
                  </td>
                  <td colspan="6">
                    <div
                      class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
                    >
                      <label class="formLabel">E-MAIL </label>
                      <input
                        class="form-control"
                        
                        id="759"
                        name="759"
                        formControlName="759"
                        type="text"l
                        data-id="hemail"
                        placeholder="Email "
                        style="width:300px"
                      />
                      <small class="text-danger" *ngIf="hap.controls['759'].errors?.pattern">
                        Only E-Mail Format is allowed</small>
                    </div>
                  </td>
              </tr>
              <tr>
                <td colspan="7">
                  <div
                    class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
                  >
                    <label class="formLabel">DATE OF BIRTH (MM/DD/YYYY) <span
                      class="mandatory">*</span></label>
                    <div class="input-group">
                      <input
                        class="form-control onboard"
                        [class.is-invalid]="
                          (hap.controls['31'].touched &&
                            hap.controls['31'].invalid) ||
                          (hap.controls['31'].invalid && isSubmit)
                        "
                        placeholder="DOB"
                        ngbDatepicker
                        #d5="ngbDatepicker"
                        id="31"
                        name="31"
                        formControlName="31"
                        type="text"
                        data-id="dob_date"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-outline-secondary calendar"
                          (click)="d5.toggle()"
                          type="button"
                        >
                          <i class="fas fa-calendar-alt"></i>
                        </button>
                      </div>
                    </div>
                    <div
                      class="formLabel col-md-2"
                      *ngIf="
                        hap.controls['31'].touched &&
                        hap.controls['31'].invalid
                      "
                    >
                      <small
                        class="text-danger"
                        *ngIf="hap.controls['31'].errors?.required"
                      >
                        required
                      </small>
                    </div>
                  </div>
                </td>
                
                <td colspan="6">
                  <div class="form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="formLabel">PLACE OF BIRTH <span
                      class="mandatory">*</span></label>
                  </div>
                  <div class="col-md-7">
                    <div class="form-group  dropDrownSet">
                      <ng-select
                        [items]="states"
                        [class.is-invalid]="
                          (hap.controls['289'].touched &&
                            hap.controls['289'].invalid) ||
                          (hap.controls['289'].invalid && isSubmit)
                        "
                        bindLabel="name"

                        bindValue="id"
                        formControlName="289"
                        placeholder="City of Birth, Birth State or Country"
                      >
                      </ng-select>
                      <div
                        class="formLabel col-md-2"
                        *ngIf="
                          hap.controls['289'].touched &&
                          hap.controls['289'].invalid
                        "
                      >
                        <small
                          class="text-danger"
                          *ngIf="hap.controls['289'].errors?.required"
                        >
                          required
                        </small>
                      </div>
                    </div>
                  </div>
                  <div class="form-group no-margin col-md-7 ">
                    <input
                      class="form-control"
                      id="810"
                      [class.is-invalid]="
                        (hap.controls['810'].touched &&
                          hap.controls['810'].invalid) ||
                        (hap.controls['810'].invalid && isSubmit)
                      "
                      name="810"
                      formControlName="810"
                      type="text"
                      data-id="bcity"
                      placeholder="Country of Birth  "
                    />
                    <div
                      class="formLabel col-md-2"
                      *ngIf="
                        hap.controls['810'].touched &&
                        hap.controls['810'].invalid
                      "
                    >
                      <small
                        class="text-danger"
                        *ngIf="hap.controls['810'].errors?.required"
                      >
                        required
                      </small>
                    </div>
                  </div>
                  
                </td>
                <td colspan="6">
                  <div class="form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="formLabel">CITIZENSHIP </label>
                    <input
                      class="form-control"
                      id="292"
                      name="292"
                      formControlName="292"
                      type="text"
                      data-id="citizenship"

                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td colspan="6">
                  <div class="form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="formLabel"
                      >IF NOT AMERICAN CITIZEN, VISA NUMBER & STATUS
                    </label>
                    <input
                      class="form-control"
                      id="1584"
                      name="1584"
                      formControlName="1584"
                      type="text"
                      data-id="Visa #"
                      placeholder="VISA Number"
                    />
                    <input
                      class="form-control"
                      id="1282"
                      name="1282"
                      formControlName="1282"
                      type="text"
                      data-id="Visa Status"
                      placeholder="VISA status"
                    />
                  </div>
                </td>
                <td colspan="6">
                  <div class="form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="formLabel"
                      >ARE YOU ELIGIBLE TO WORK IN THE UNITED STATES?
                    </label>
                    <label class="checkbox-inline radioStyle">
                      <input
                        type="radio"
                        id="cmd_04"
                        name="cmd_04"
                        formControlName="cmd_04"
                        value="1"
                      />Yes</label
                    >
                    <label class="checkbox-inline radioStyle">
                      <input
                        type="radio"
                        id="cmd_04"
                        name="cmd_04"
                        formControlName="cmd_04"
                        value="0"
                      />No</label
                    >
                  </div>
                </td>
                <td colspan="6">
                  <div class="form-group no-margin col-md-6 sideLabelAlignment">
                    <label class="formLabel">CAQH ID<span
                      class="mandatory">*</span></label>
                    <input
                      class="form-control"
                      [class.is-invalid]="
                          (hap.controls['333'].touched &&
                            hap.controls['333'].invalid) ||
                          (hap.controls['333'].invalid && isSubmit)
                        "
                      id="333"
                      name="333"
                      formControlName="333"
                      type="text"
                      data-id="caqh"
                      placeholder="CAQH ID"
                    />
                    <div
                      class="formLabel col-md-2"
                      *ngIf="
                        hap.controls['333'].touched &&
                        hap.controls['333'].invalid
                      "
                    >
                      <small
                        class="text-danger"
                        *ngIf="hap.controls['333'].errors?.required"
                      >
                        required
                      </small>
                    </div>
                  </div>
                  <div class="form-group no-margin col-md-6 sideLabelAlignment">
                    <label class="formLabel">USERNAME<span
                      class="mandatory">*</span></label>
                    <input
                      class="form-control"
                      [class.is-invalid]="
                      (hap.controls['334'].touched &&
                        hap.controls['334'].invalid) ||
                      (hap.controls['334'].invalid && isSubmit)
                    "
                      id="334"
                      name="334"
                      formControlName="334"
                      type="text"
                      data-id="caqh_username"
                      placeholder="CAQH username"
                    />
                    <div
                      class="formLabel col-md-2"
                      *ngIf="
                        hap.controls['334'].touched &&
                        hap.controls['334'].invalid
                      "
                    >
                      <small
                        class="text-danger"
                        *ngIf="hap.controls['334'].errors?.required"
                      >
                        required
                      </small>
                    </div>
                  </div>
                  <div class="form-group no-margin col-md-6 sideLabelAlignment">
                    <label class="formLabel">PASSWORD<span
                      class="mandatory">*</span></label>
                    <input
                      class="form-control"
                      [class.is-invalid]="
                      (hap.controls['335'].touched &&
                        hap.controls['335'].invalid) ||
                      (hap.controls['335'].invalid && isSubmit)
                    "
                      id="335"
                      name="335"
                      formControlName="335"
                      type="text"
                      data-id="caqh_pswd"
                      placeholder="CAQH Password"
                    />
                  </div>
                  <div
                      class="formLabel col-md-2"
                      *ngIf="
                        hap.controls['335'].touched &&
                        hap.controls['335'].invalid
                      "
                    >
                      <small
                        class="text-danger"
                        *ngIf="hap.controls['335'].errors?.required"
                      >
                        required
                      </small>
                    </div>
                </td>
              </tr>
              <tr>
                <td colspan="6">
                  <div class="form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="formLabel"
                      >U.S.MILITARY SERVICE/PUBLIC HEALTH
                    </label>
                    <label class="checkbox-inline radioStyle">
                      <input
                        type="radio"
                        id="cmd_05"
                        name="cmd_05"
                        formControlName="cmd_05"
                        
                        value="1"
                      />Yes</label
                    >
                    <label class="checkbox-inline radioStyle">
                      <input
                        type="radio"
                        id="cmd_05"
                        name="cmd_05"
                        formControlName="cmd_05"
                        
                        value="0"
                      />No</label
                    >
                  </div>
                </td>
                <td colspan="6">
                  <div
                    class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
                  >
                    <label class="formLabel"
                      >DATES OF SERVICE (MM/DD/YYYY) TO (MM/DD/YYYY)
                    </label>
                  </div>
                  <div class="form-group no-margin form-inline col-md-6 sideLabelAlignment">
                    <div class="input-group">
                      <input
                        class="form-control onboard datepicker"
                        
                        placeholder="MIL start"
                        ngbDatepicker
                        #d6="ngbDatepicker"
                        id="1144"
                        name="1144"
                        formControlName="1144"
                        type="text"
                        data-id="Mil Active from"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-outline-secondary calendar"
                          (click)="d6.toggle()"
                          type="button"
                        >
                          <i class="fas fa-calendar-alt"></i>
                        </button>
                      </div>
                    </div>
                    <div  *ngIf="hap.get('1144').invalid && hap.get('1144').touched">
                      <small class="text-danger" *ngIf="hap.get('1144').errors.invalidDateFormat">
                        Only Date Format is allowed
                      </small>
                    </div>
                    
                  </div>
                  <div class="form-group no-margin form-inline col-md-6 sideLabelAlignment">
                    <div class="input-group">
                      <input
                        class="form-control onboard datepicker"
                        
                        placeholder="MIL end"
                        ngbDatepicker
                        #d7="ngbDatepicker"
                        id="1145"
                        name="1145"
                        formControlName="1145"
                        type="text"
                        data-id="Mil Active to"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-outline-secondary calendar"
                          (click)="d7.toggle()"
                          type="button"
                        >
                          <i class="fas fa-calendar-alt"></i>
                        </button>
                      </div>
                    </div>
                    <div  *ngIf="hap.get('1145').invalid && hap.get('1145').touched">
                      <small class="text-danger" *ngIf="hap.get('1145').errors.invalidDateFormat">
                        Only Date Format is allowed
                      </small>
                    </div>
                    
                  </div>
                </td>
                <td colspan="6">
                  <div class="form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="formLabel">LAST LOCATION </label>
                    <input
                      class="form-control"
                      id="1139"
                      
                      name="1139"
                      formControlName="1139"
                      type="text"
                      data-id="Military Locaiton"
                      placeholder="MIL location"
                    />
                    
                  </div>
                </td>
              </tr>
              <tr>
                <td colspan="9">
                  <div class="form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="formLabel">BRANCH OF SERVICE </label>
                    <input
                      class="form-control"

                      id="1141"
                      name="1141"
                      formControlName="1141"
                      type="text"
                      data-id="Mil branch"
                      placeholder="MIL Branch"
                    />
                    
                  </div>
                </td>
                <td colspan="9">
                  <div class="form-inline no-margin col-md-12 sideLabelAlignment">
                    <label class="formLabel"
                      >ARE YOU CURRENTLY ON ACTIVE OR RESERVE MILITARY DUTY?
                    </label>
                    <label class="checkbox-inline radioStyle">
                      <input
                        type="radio"
                        id="cmd_06"
                        name="cmd_06"
                        formControlName="cmd_06"
                        value="1"
                      />Yes</label
                    >
                    <label class="checkbox-inline radioStyle">
                      <input
                        type="radio"
                        id="cmd_06"
                        name="cmd_06"
                        formControlName="cmd_06"
                        value="0"
                      />No</label
                    >
                  </div>
                </td>
              </tr>
          </table>
          <table border="1" width="100%">
            <tr>
              <td colspan="14">
                <div class="centered texasFirstTable">
                  <h3 class="texasHThree">Education</h3>
                </div>
              </td>
              <td colspan="4">
                <label class="texasFloat">Does Not Apply</label>
                <input
                  class="texasFloat"
                  type="checkbox"
                  name="cmd_0722"
                  id="cmd_0722"
                  formControlName="cmd_0722"
                  (ngModelChange)="handleNAchange('cmd_0722')"
                  value="1"
                />

              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div
                  class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel"
                    >PROFESSIONAL DEGREE (MEDICAL, DENTAL, CHIROPRACTIC, ETC.)
                  </label>
                </div>
                <div
                  class="form-group form-inline no-margin col-md-6 sideLabelAlignment"
                >
                  <label class="formLabel">Issuing Institution:<span
                    class="mandatory">*</span> </label>
                </div>
                <div class="form-group no-margin col-md-6 sideLabelAlignment">
                  <input
                    class="form-control"
                    [class.is-invalid]="hap.controls['490'].invalid"  
                    id="490"
                    name="490"
                    formControlName="490"
                    type="text"
                    data-id="meds"
                    placeholder="Med School"
                    (blur)="handleNAchange('cmd_0722', 490)"
                  />
                </div>
                <div class="mandatory"
                *ngIf="hap.controls['490'].invalid">
          <small >
              required
          </small>
                   </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="formLabel">ADDRESS </label>
                  <div class="col-md-3">
                    <input
                    class="form-control"
                    
                    id="491"
                    name="491"
                    formControlName="491"
                    type="text"
                    data-id="medsadd1"
                    placeholder="Med School Address1    "
                  />
                  </div>
                  <div class="col-md-3">
                    <input
                    class="form-control"
                    
                    id="492"
                    name="492"
                    formControlName="492"
                    type="text"
                    data-id="medsadd2"
                    placeholder="Med School Address 2 "
                  />
                  </div>

                  

                  
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div class="row">
                  <div class="col-md-4">
                    <label class="formLabel">CITY<span
                      class="mandatory">*</span></label>
                  
                    <input
                      class="form-control"
                      [class.is-invalid]="hap.controls['493'].invalid"  
                      id="493"
                      name="493"
                      formControlName="493"
                      type="text"
                      data-id="medsci"
                      placeholder="Med School City  "
                      (blur)="handleNAchange('cmd_0722', 493)"
                      
                    />
                    <div class="mandatory"
                *ngIf="hap.controls['493'].invalid">
          <small >
              required
          </small>
                   </div>
                  </div>
                  <div class="form-group col-md-4">
                    <label class="formLabel ">STATE/COUNTRY<span
                      class="mandatory">*</span></label>
                    <div class="form-group dropDrownSet">
                      <ng-select
                        [items]="states"
                        [class.is-invalid]="hap.controls['495'].invalid"  
                        bindLabel="name"
                        placeholder="Select States"
                        bindValue="id"
                        formControlName="495"
                        placeholder="State or Country"
                        (blur)="handleNAchange('cmd_0722', 495)"
                      >
                      </ng-select>
                      <div class="mandatory"
                *ngIf="hap.controls['495'].invalid">
          <small >
              required
          </small>
                   </div>
                    </div>
                  </div>
                  <div class="form-group col-md-4 ">
                    <label class="formLabel">POSTAL CODE</label>
                    <input
                      class="form-control "

                      (keypress)="numberOnly($event)"
                      id="496"
                      name="496"
                      formControlName="496"
                      type="text"
                      data-id="medsz"
                      placeholder="Med School Zip  "
                    />
                    <div *ngIf="hap.get('496').invalid && hap.get('496').touched">
                      <small class="text-danger" *ngIf="hap.get('496').errors.invalidZipCode">
                        Zip code must be atleast 5 digits and less than 11 digits.
                      </small>
                    </div>
                    
                  </div>
                </div>
              </td>
          </tr>
          <tr>
            <td colspan="9">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="formLabel">DEGREE <span
                  class="mandatory">*</span></label>
              </div>
                <div class="form-group col-md-6 dropDrownSet">
                  <ng-select
                    [items]="degree"
                    [class.is-invalid]="hap.controls['500'].invalid"  
                    bindLabel="name"
                    placeholder=" Select Degree"
                    bindValue="id"
                    formControlName="500"
                    (blur)="handleNAchange('cmd_0722', 500)"
                  >
                  </ng-select>
                  <div class="mandatory"
                *ngIf="hap.controls['500'].invalid">
          <small >
              required
          </small>
                   </div>
                </div>
              
            </td>
            <td colspan="9">

                <div
                  class="form-group form-inline no-margin col-md-9 sideLabelAlignment"
                >
                  <label class="formLabel"
                    >ATTENDANCE DATES(MM/DD/YYYY TO MM/DD/YYYY)<span
                    class="mandatory">*</span></label
                  >
                  <br />
                </div>
              <div class="row">
                <div class="col-md-4">
                  <div class="input-group">
                    <input
                      class="form-control onboard datepicker"
                      [class.is-invalid]="hap.controls['497'].invalid"  
                      placeholder="MM/DD/YYYY"
                      ngbDatepicker
                      #d8="ngbDatepicker"
                      id="497"
                      name="497"
                      formControlName="497"
                      type="text"
                      data-id="medsstart_date"
                      placeholder="Med School Start"
                      (blur)="handleNAchange('cmd_0722', 497)"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="d8.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div class="mandatory"
                *ngIf="hap.controls['497'].invalid">
          <small >
              required
          </small>
                   </div>
                </div>
                <div class="col-md-4">
                  <div class="input-group">
                    <input
                      class="form-control onboard datepicker"
                      [class.is-invalid]="hap.controls['498'].invalid"  

                      ngbDatepicker
                      #d9="ngbDatepicker"
                      id="498"
                      name="498"
                      formControlName="498"
                      type="text"
                      data-id="medsend_date"
                      placeholder="Med School End"
                      (blur)="handleNAchange('cmd_0722', 498)"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="d9.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div class="mandatory"
                *ngIf="hap.controls['498'].invalid">
          <small >
              required
          </small>
                   </div>
                </div>
              </div>
              
              
            </td>
          </tr>
          <tr>
            <td colspan="8">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="checkbox-inline">POST-GRADUATE EDUCATION<span
                  class="mandatory">*</span> </label>
              </div>

              <label  class="checkbox-inline ">
                <input
                  type="radio"
                  id="cmd_08"
                  name="cmd_08"
                  [class.is-invalid]="
                    (hap.controls['cmd_08'].touched &&
                      hap.controls['cmd_08'].invalid) ||
                    (hap.controls['cmd_08'].invalid && isSubmit)
                  "
                  formControlName="cmd_08"
                  value="0"
                  (blur)="handleNAchange('cmd_0722', 'cmd_08')"
                />Internship</label
              >
              <label  class="checkbox-inline ">
                <input
                  type="radio"
                  id="cmd_08"
                  name="cmd_08"
                  [class.is-invalid]="
                    (hap.controls['cmd_08'].touched &&
                      hap.controls['cmd_08'].invalid) ||
                    (hap.controls['cmd_08'].invalid && isSubmit)
                  "
                  formControlName="cmd_08"
                  value="1"
                  (blur)="handleNAchange('cmd_0722', 'cmd_08')"
                />Residency</label
              >
              <label  class="checkbox-inline ">
                <input
                  type="radio"
                  id="cmd_08"
                  name="cmd_08"
                  [class.is-invalid]="
                    (hap.controls['cmd_08'].touched &&
                      hap.controls['cmd_08'].invalid) ||
                    (hap.controls['cmd_08'].invalid && isSubmit)
                  "
                  formControlName="cmd_08"
                  value="2"
                  (blur)="handleNAchange('cmd_0722', 'cmd_08')"
                />Fellowship</label
              >
              <label  class="checkbox-inline ">
                <input
                  type="radio"
                  id="cmd_08"
                  name="cmd_08"
                  [class.is-invalid]="
                    (hap.controls['cmd_08'].touched &&
                      hap.controls['cmd_08'].invalid) ||
                    (hap.controls['cmd_08'].invalid && isSubmit)
                  "
                  formControlName="cmd_08"
                  value="3"
                  (blur)="handleNAchange('cmd_0722', 'cmd_08')"
                />Teaching Appointment</label
              >
              <div
              *ngIf="
                hap.controls['cmd_08'].invalid && isSubmit
              "
            >
              <small
                class="text-danger"
                *ngIf="hap.controls['cmd_08'].errors"
              >
                Required
              </small>
            </div>
            </td>
            <td colspan="10">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="checkbox-inline radioStyle">SPECIALTY</label></div>
              
              <div class="form-group col-md-6 dropDrownSet">
                <ng-select
                  [items]="specialty"
                  bindLabel="name"
                  placeholder="Select Specialty"
                  bindValue="id"
                  formControlName="527"
                  placeholder="Primary Specialty"
                >
                </ng-select>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="18">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="formLabel">INSTITUTION<span
                  class="mandatory">*</span></label>
                <input
                  class="form-control"
                  [class.is-invalid]="hap.controls['517'].invalid"  
                  id="517"
                  name="517"
                  formControlName="517"
                  type="text"
                  data-id="interns"
                  placeholder="Intern School"
                  (blur)="handleNAchange('cmd_0722', 517)"
                />
                <div class="mandatory"
                *ngIf="hap.controls['517'].invalid">
          <small >
              required
          </small>
                   </div>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="18">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="formLabel">ADDRESS</label>
                <div class="col-md-3">
                  <input
                  class="form-control"
                  id="518"
                  name="518"
                  placeholder="Address"
                  formControlName="518"
                  type="text"
                  data-id="internadd1"
                  placeholder="Intern Address1"
                />
                </div>
                <div class="col-md-3">
                  <input
                  class="form-control"
                  id="519"
                  name="519"
                  placeholder="Address"
                  formControlName="519"
                  type="text"
                  data-id="internadd2"
                  placeholder="Intern Address2"
                />
                </div>


              </div>
            </td>
            <tr>
              <td colspan="18">
                <div class="row">
                  <div class="col-md-4">
                    <label class="formLabel">CITY<span
                      class="mandatory">*</span></label>
                    <input
                      class="form-control"
                      [class.is-invalid]="hap.controls['520'].invalid"  
                      id="520"
                      name="520"
                      formControlName="520"
                      type="text"
                      data-id="internci"
                      placeholder="Intern City  "
                      (blur)="handleNAchange('cmd_0722', 520)"
                    />
                    <div class="mandatory"
                *ngIf="hap.controls['520'].invalid">
          <small >
              required
          </small>
                   </div>
                  </div>
                  <div class="form-group col-md-4 ">
                    <label class="formLabel ">STATE/COUNTRY<span
                      class="mandatory">*</span></label>
                    <div class="form-group  dropDrownSet">
                      <ng-select
                        [items]="states"
                        [class.is-invalid]="hap.controls['522'].invalid"  
                        bindLabel="name"
                        placeholder="Select States"
                        bindValue="id"
                        formControlName="522"
                        placeholder="State or Country"
                        (blur)="handleNAchange('cmd_0722', 522)"
                      >
                      </ng-select>
                      <div class="mandatory"
                *ngIf="hap.controls['522'].invalid">
          <small >
              required
          </small>
                   </div>
                    </div>
                  </div>
                  <div class="form-group col-md-4 ">
                    <label class="formLabel">POSTAL CODE</label>
                    <input
                      class="form-control "

                      (keypress)="numberOnly($event)"
                      id="523"
                      name="523"
                      formControlName="523"
                      type="text"
                      data-id="internz"
                      placeholder="Intern Zip  "
                    />
                    <div *ngIf="hap.get('523').invalid && hap.get('523').touched">
                      <small class="text-danger" *ngIf="hap.get('523').errors.invalidZipCode">
                        Zip code must be atleast 5 digits and less than 11 digits.
                      </small>
                    </div>
                    
                  </div>
                </div>
              </td>
          </tr>
          <tr>
            <td colspan="6">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              ></div>
              <input
                type="checkbox"
                id="cmd_09"
                name="cmd_09"
                formControlName="cmd_09"
                value="1"
              />Program successfully completed
            </td>
            <td colspan="12">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="formLabel"
                  >ATTENDANCE DATES (MM/DD/YYYY TO MM/DD/YYYY)<span
                  class="mandatory">*</span>
                </label></div>
                <div class="row">
                  <div class="col-md-4">
                    <div class="input-group">
                      <input
                        class="form-control onboard datepicker"
                        [class.is-invalid]="hap.controls['524'].invalid"  
                        placeholder="Intern Start Date"
                        ngbDatepicker
                        #d10="ngbDatepicker"
                        id="524"
                        name="524"
                        formControlName="524"
                        type="text"
                        data-id="internstart_date"
                        (blur)="handleNAchange('cmd_0722', 524)"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-outline-secondary calendar"
                          (click)="d10.toggle()"
                          type="button"
                        >
                          <i class="fas fa-calendar-alt"></i>
                        </button>
                      </div>
                      <div class="mandatory"
                      *ngIf="hap.controls['524'].invalid">
                <small >
                    required
                </small>
                         </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="input-group">
                      <input
                        class="form-control onboard datepicker"
                        [class.is-invalid]="hap.controls['525'].invalid"  
                        placeholder="Intern End Date "
                        ngbDatepicker
                        #d11="ngbDatepicker"
                        id="525"
                        name="525"
                        formControlName="525"
                        type="text"
                        data-id="internend_date"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-outline-secondary calendar"
                          (click)="d11.toggle()"
                          type="button"
                        >
                          <i class="fas fa-calendar-alt"></i>
                        </button>
                      </div>
                      <div class="mandatory"
                      *ngIf="hap.controls['525'].invalid">
                <small >
                    required
                </small>
                         </div>
                    </div>
                  </div>
                </div>
                
                
              
            </td>
          </tr>
          <tr>
            <td colspan="9">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="formLabel">PROGRAM DIRECTOR </label>
                <input
                  style="width:40%;"
                  class="form-control"
                  id="528"
                  name="528"
                  formControlName="528"
                  type="text"
                  data-id="interndir"
                  placeholder="Internship Director"
                />
              </div>
            </td>
            <td colspan="9">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="formLabel"
                  >CURRENT PROGRAM DIRECTOR (IF KNOWN)
                </label>
                <input
                  style="width:40%;"
                  class="form-control"
                  id="818"
                  name="818"
                  formControlName="818"
                  type="text"
                  data-id="interndir2"
                  placeholder="Internship Attention"
                />
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="8">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="checkbox-inline">POST-GRADUATE EDUCATION <span
                  class="mandatory">*</span></label>
              </div>

              <label  class="checkbox-inline ">
                <input
                  type="radio"
                  id="cmd_010"
                  name="cmd_010"
                  [class.is-invalid]="hap.controls['cmd_010'].invalid"  
                  formControlName="cmd_010"
                  value="0"
                  (blur)="handleNAchange('cmd_0722', 'cmd_010')"
                />Internship</label
              >
              <label  class="checkbox-inline ">
                <input
                  type="radio"
                  id="cmd_010"
                  name="cmd_010"
                  [class.is-invalid]="hap.controls['cmd_010'].invalid"
                  formControlName="cmd_010"
                  value="1"
                  (blur)="handleNAchange('cmd_0722', 'cmd_010')"
                />Residency</label
              >
              <label  class="checkbox-inline ">
                <input
                  type="radio"
                  id="cmd_010"
                  name="cmd_010"
                  [class.is-invalid]="hap.controls['cmd_010'].invalid"
                  formControlName="cmd_010"
                  value="2"
                  (blur)="handleNAchange('cmd_0722', 'cmd_010')"
                />Fellowship</label
              >
              <label  class="checkbox-inline ">
                <input
                  type="radio"
                  id="cmd_010"
                  name="cmd_010"
                  [class.is-invalid]="hap.controls['cmd_010'].invalid"
                  formControlName="cmd_010"
                  value="3"
                  (blur)="handleNAchange('cmd_0722', 'cmd_010')"
                />Teaching Appointment</label
              >
              <div class="mandatory"
              *ngIf="hap.controls['cmd_010'].invalid">
        <small >
            required
        </small>
                 </div>
            </td>
            <td colspan="10">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="checkbox-inline radioStyle">SPECIALTY</label></div>
              
              <div class="form-group col-md-8 dropDrownSet">
                <ng-select
                  [items]="specialty"
                  bindLabel="name"
                  placeholder="Select Specialty"
                  bindValue="id"
                  formControlName="530"
                  placeholder="Primary Specialty"
                >
                </ng-select>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="18">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="checkbox-inline radioStyle">INSTITUTION<span
                  class="mandatory">*</span></label>
                <input
                  class="form-control"
                
                  [class.is-invalid]="hap.controls['529'].invalid"  
                  id="529"
                  name="529"
                  formControlName="529"
                  type="text"
                  data-id="res"
                  placeholder="Residency School "
                  (blur)="handleNAchange('cmd_0722', 529)"
                />
                <div class="mandatory"
                *ngIf="hap.controls['529'].invalid">
          <small >
              required
          </small>
                   </div>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="18">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="checkbox-inline radioStyle">ADDRESS</label>
                <div class="col-md-3">
                  <input
                  class="form-control"
                  id="531"
                  name="531"
                  formControlName="531"
                  type="text"
                  data-id="resadd1"
                  placeholder="Residency Address1  "
                />
                </div>
                <div class="col-md-3">
                  <input
                  class="form-control"
                  id="1498"
                  name="1498"
                  formControlName="1498"
                  type="text"
                  data-id="resadd2"
                  placeholder="Residency Address 2"
                />
                </div>


              </div>
            </td>
          </tr>
          <tr>
            <td colspan="18">
              <div class="row">
                <div class="col-md-4">
                  <label class="formLabel">CITY <span class="mandatory">*</span> </label>
                  <input
                    class="form-control"
                    [class.is-invalid]="hap.controls['533'].invalid"  
                    id="533"
                    name="533"
                    formControlName="533"
                    type="text"
                    data-id="resci"
                    placeholder="Residency City"
                    (blur)="handleNAchange('cmd_0722', 533)"
                  />
                  <div class="mandatory"
                  *ngIf="hap.controls['533'].invalid">
            <small >
                required
            </small>
                     </div>
                </div>
                <div class="form-group col-md-4 ">
                  <label class="formLabel ">STATE/COUNTRY<span class="mandatory">*</span></label>
                  <div class="form-group  dropDrownSet">
                    <ng-select
                      [items]="states"
                      [class.is-invalid]="hap.controls['535'].invalid"  
                      bindLabel="name"
                      placeholder="Select States"
                      bindValue="id"
                      formControlName="535"
                      placeholder="State or Country"
                      (blur)="handleNAchange('cmd_0722', 535)"
                    >
                    </ng-select>
                    <div class="mandatory"
                  *ngIf="hap.controls['535'].invalid">
            <small >
                required
            </small>
                     </div>
                  </div>
                </div>
                <div class="form-group col-md-4 ">
                  <label class="formLabel">POSTAL CODE</label>
                  <input
                    class="form-control "
                    (keypress)="numberOnly($event)"
                    
                    id="536"
                    name="536"
                    formControlName="536"
                    type="text"
                    data-id="resz"
                    placeholder="Residency Zip   "
                  />
                  <div *ngIf="hap.get('536').invalid && hap.get('536').touched">
                    <small class="text-danger" *ngIf="hap.get('536').errors.invalidZipCode">
                      Zip code must be atleast 5 digits and less than 11 digits.
                    </small>
                  </div>
                  
                </div>
              </div>
            </td>
        </tr>
          </table>
          <hr class="tablePersonal" />
          <table border="1" width="100%">
            <td colspan="14">
              <div class="centered texasFirstTable">
                <h3 class="texasHThree">Education -continued</h3>
              </div>
            </td>
            <tr>
              <td colspan="6">
                <div
                  class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="checkbox-inline radioStyle"
                    >POST-GRADUATE EDUCATION<span class="mandatory">*</span>
                  </label>
                </div>
                <input
                  type="checkbox"
                  id="cmd_011"
                  name="cmd_011"
                  formControlName="cmd_011"
                  value="1"
                  [class.is-invalid]="hap.controls['cmd_011'].invalid"  
                  (blur)="handleNAchange('cmd_0722','cmd_011')"
                />Program successfully completed
                <div class="mandatory"
                *ngIf="hap.controls['cmd_011'].invalid">
          <small >
              required
          </small>
                   </div>
              </td>
              <td colspan="12">
                <div
                  class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="checkbox-inline radioStyle"
                    >ATTENDANCE DATES (MM/DD/YYYY TO MM/DD/YYYY)<span class="mandatory">*</span>
                  </label>
                </div>
                <div class="row">
                  <div class="col-md-4">
                    <div class="input-group">
                      <input
                    style="width:25%;"
                    class="form-control onboard datepicker"
                    [class.is-invalid]="hap.controls['537'].invalid" 
                    placeholder="Residency Start "
                    ngbDatepicker
                    #d12="ngbDatepicker"
                    id="537"
                    name="537"
                    formControlName="537"
                    type="text"
                    data-id="resstart_date"
                    (blur)="handleNAchange('cmd_0722', 537)"
                  /> 
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="d12.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                  <div class="mandatory"
                  *ngIf="hap.controls['537'].invalid">
            <small >
                required
            </small>
                     </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="input-group">
                     <input
                    style="width:25%;"
                    class="form-control onboard datepicker"
                    [class.is-invalid]="hap.controls['538'].invalid" 
                    placeholder="Residency End "
                    ngbDatepicker
                    #d13="ngbDatepicker"
                    id="538"
                    name="538"
                    formControlName="538"
                    type="text"
                    data-id="resend_date"
                    (blur)="handleNAchange('cmd_0722', 538)"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="d13.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                  <div class="mandatory"
                  *ngIf="hap.controls['538'].invalid">
            <small >
                required
            </small>
                     </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="9">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="formLabel">PROGRAM DIRECTOR </label>
                  <input
                    style="width:40%;"
                    class="form-control"
                    id="540"
                    name="540"
                    formControlName="540"
                    type="text"
                    data-id="resdir1"
                    placeholder="Residency Director "
                  />
                </div>
              </td>
              <td colspan="9">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="formLabel"
                    >CURRENT PROGRAM DIRECTOR (IF KNOWN)</label
                  >
                  <input
                    style="width:40%;"
                    class="form-control"
                    id="541"
                    name="541"
                    formControlName="541"
                    type="text"
                    data-id="resdir2"
                    placeholder="Residency Attention"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div
                  class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel">OTHER GRADUATE-LEVEL EDUCATION</label>
                </div>
                <div class="form-group form-inline col-md-6 no-margin sideLabelAlignment">
                  <label class="formLabel">Issuing Institution:</label>
                  <div class="col-md-6">
                    <input
                   class="form-control"
                   id="479"
                   name="479"
                   formControlName="479"
                   type="text"
                   data-id="grad_school1"
                   placeholder="Grad School"
          />
                  </div>
                  
                </div>
                
              </td>
            </tr>
            
            <tr>
              <td colspan="18">
                <div
                  class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="checkbox-inline radioStyle">ADDRESS</label>
                  <div class="col-md-3">
                    <input
                    class="form-control"
                    id="483"
                    name="483"
                    formControlName="483"
                    type="text"
                    data-id="Training Pro 1 address1"
                    placeholder="Grad School Address1"
                  />
                  </div>
                  <div class="col-md-3">
                    <input
                    class="form-control"
                    id="484"
                    name="484"
                    formControlName="484"
                    type="text"
                    data-id="Training Pro 1 address2"
                    placeholder="Grad School Address2"
                  />
                  </div>


                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div class="row">
                  <div class="col-md-4">
                    <label class="formLabel">CITY</label>
                    <input
                      class="form-control"
                      id="485"
                      name="485"
                      formControlName="485"
                      type="text"
                      data-id="prac1_MailingCity"
                      placeholder="Grad School City "
                    />
                  </div>
                  <div class="col-md-4 ">
                    <label class="formLabel ">STATE/COUNTRY</label>
                    <div class="form-group dropDrownSet">
                      <div class="">
                        <ng-select
                          [items]="states"
                          bindLabel="name"
                          placeholder="States or Country"
                          bindValue="id"
                          formControlName="487"
                        >
                        </ng-select>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <label class="formLabel">POSTAL CODE</label>
                    <input
                      class="form-control "
                      id="488"
                      name="488"
                      formControlName="488"
                      type="text"
                      data-id="prac1_MailingZIP"
                      placeholder="Grad School Zip "
                    />
                    <div *ngIf="hap.get('488').invalid && hap.get('488').touched">
                      <small class="text-danger" *ngIf="hap.get('488').errors.invalidZipCode">
                        Zip code must be atleast 5 digits and less than 11 digits.
                      </small>
                    </div>
                  </div>
                </div>
              </td>
          </tr>
          <tr>
      <td colspan="6">
        <div class="form-group form-inline no-margin sideLabelAlignment">
          <label class="formLabel">DEGREE</label></div>
          <div class="form-group col-md-8 dropDrownSet">
            <ng-select
              [items]="degree"
              bindLabel="name"
              placeholder="Select Degree"
              bindValue="id"
              formControlName="480"
            >
            </ng-select>
          </div>
        
      </td>
      <td colspan="12">
        <div
          class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
        >
          <label class="checkbox-inline radioStyle"
            >ATTENDANCE DATES (MM/DD/YYYY TO MM/DD/YYYY)
          </label>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="input-group">
              <input
              style="width:25%;"
              class="form-control onboard datepicker"
              
              placeholder="Grad School Start Date"
              ngbDatepicker
              #d14="ngbDatepicker"
              id="481"
              name="481"
              formControlName="481"
              type="text"
              data-id="Training Pro 1 from"
            />
            <div class="input-group-append">
              <button
                class="btn btn-outline-secondary calendar"
                (click)="d14.toggle()"
                type="button"
              >
                <i class="fas fa-calendar-alt"></i>
              </button>
            </div>
            </div>
            <div  *ngIf="hap.get('481').invalid && hap.get('481').touched">
              <small class="text-danger" *ngIf="hap.get('481').errors.invalidDateFormat">
                Only Date Format is allowed
              </small>
            </div>
          </div>
          <div class="col-md-4">
            <div class="input-group">
              <input
              style="width:25%;"
              class="form-control onboard datepicker"

              placeholder="Grad School End Date"
              ngbDatepicker
              #d15="ngbDatepicker"
              id="1300"
              name="1300"
              formControlName="1300"
              type="text"
              data-id="Training Pro 1 to"
            />
            <div class="input-group-append">
              <button
                class="btn btn-outline-secondary calendar"
                (click)="d15.toggle()"
                type="button"
              >
                <i class="fas fa-calendar-alt"></i>
              </button>
            </div>
            </div>
            <div  *ngIf="hap.get('1300').invalid && hap.get('1300').touched">
              <small class="text-danger" *ngIf="hap.get('1300').errors.invalidDateFormat">
                Only Date Format is allowed
              </small>
            </div>
          </div>
        </div>
      

          
        
        
      </td>
          </tr>
          <tr>
            <td colspan="18">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="checkbox-inline radioStyle"
                  >Licenses and Certificates</label
                >
                - Please include all license(s) and certifications in all States
                where you are currently or have previously been licensed.
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="6">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="formLabel">LICENSE TYPE <span class="mandatory">*</span></label></div>
                <input
                  style="width:70%;"
                  class="form-control"
                  
                  [class.is-invalid]="hap.controls['296'].invalid" 
                  id="296"
                  name="296"
                  formControlName="296"
                  type="text"
                  data-id="licensetype"
                  placeholder="License Type "
                  (blur)="handleNAchange('cmd_07259', 296)"
                  
                />
                <div
                  class="formLabel col-md-2"
                  *ngIf="
                    hap.controls['296'].touched &&
                    hap.controls['296'].errors
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="hap.controls['296'].errors"
                  >
                    required
                  </small>
                </div>
              
            </td>
            <td colspan="6">
              <div
                class="form-group  no-margin col-md-12 sideLabelAlignment"
              >
                <label class="formLabel">LICENSE NUMBER<span class="mandatory">*</span> </label>
                <input
                  style="width:70%;"
                  [class.is-invalid]="hap.controls['293'].invalid"
                  class="form-control"
                  id="293"
                  name="293"
                  formControlName="293"
                  type="text"
                  data-id="license"
                  placeholder="License Number "
                  (blur)="handleNAchange('cmd_07259', 293)"
                />
                <div
                  class="formLabel col-md-2"
                  *ngIf="
                    hap.controls['293'].touched &&
                    hap.controls['293'].errors
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="hap.controls['293'].errors"
                  >
                    required
                  </small>
                </div>
              </div>
            </td>
            <td colspan="6">
              <div
                class="form-group form-inline no-margin col-md-12"
                style="padding: 2px;"
              >
                <label class="formLabel">STATE OF REGISTRATION<span class="mandatory">*</span> </label>
              </div>
                <div class="form-group col-md-8 dropDrownSet">
                  
                    <ng-select
                      [items]="states"
                      [class.is-invalid]="hap.controls['299'].invalid"
                      bindLabel="name"
                      placeholder="Select States"
                      bindValue="id"
                      formControlName="299"
                      placeholder="License 1 State"
                      (blur)="handleNAchange('cmd_07259', 299)"
                    >
                    </ng-select>
                  
                  <div
                    class="formLabel col-md-2"
                    *ngIf="
                      hap.controls['299'].touched &&
                      hap.controls['299'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="hap.controls['299'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
              
            </td>
          </tr>
          <tr>
            <td colspan="6">
              <div class="form-group form-inline no-margin sideLabelAlignment">
                <label class="formLabel"
                  >ORIGINAL DATE OF ISSUE (MM/DD/YYYY)<span class="mandatory">*</span>
                </label>
                <div class="input-group">
                  <input
                    style="width:50%;"
                    [class.is-invalid]="hap.controls['294'].invalid"
                    class="form-control onboard datepicker"
                    placeholder="License Issue Date "
                    ngbDatepicker
                    #d16="ngbDatepicker"
                    id="294"
                    name="294"
                    formControlName="294"
                    type="text"
                    data-id="licenseissuedate"
                    (blur)="handleNAchange('cmd_07259', 294)"
                    
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="d16.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
                <div
                  class="formLabel col-md-2"
                  *ngIf="
                    hap.controls['294'].touched &&
                    hap.controls['294'].errors
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="hap.controls['294'].errors"
                  >
                    required
                  </small>
                </div>
              </div>
            </td>
            <td colspan="6">
              <div class="form-group form-inline no-margin sideLabelAlignment">
                <label class="formLabel">EXPIRATION DATE (MM/DD/YYYY) <span class="mandatory">*</span></label>
                <div class="input-group">
                  <input
                    style="width:50%;"
                    [class.is-invalid]="hap.controls['295'].invalid"
                    class="form-control onboard datepicker"
                    placeholder="License Expiration"
                    ngbDatepicker
                    #d17="ngbDatepicker"
                    id="295"
                    name="295"
                    formControlName="295"
                    type="text"
                    data-id="licenseexp_date"
                    (blur)="handleNAchange('cmd_07259', 295)"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="d17.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
                <div
                  class="formLabel col-md-2"
                  *ngIf="
                    hap.controls['295'].touched &&
                    hap.controls['295'].errors
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="hap.controls['295'].errors"
                  >
                    required
                  </small>
                </div>
              </div>
            </td>
            <td colspan="6">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                DO YOU CURRENTLY PRACTICE IN THIS STATE?<span class="mandatory">*</span>
              </div>
              <div class="form-group no-margin col-md-10 sideLabelAlignment">
                <label class="radio-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_013"
                    name="cmd_013"
                    [class.is-invalid]="
                    (hap.controls['cmd_013'].touched &&
                      hap.controls['cmd_013'].invalid) ||
                    (hap.controls['cmd_013'].invalid && isSubmit)
                  "
                    formControlName="cmd_013"
                    value="1"
                    data-id="yes or no"
                  />Yes</label
                >
                <label class="radio-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_013"
                    name="cmd_013"
                    data-id="yes or no"
                    [class.is-invalid]="
                    (hap.controls['cmd_013'].touched &&
                      hap.controls['cmd_013'].invalid) ||
                    (hap.controls['cmd_013'].invalid && isSubmit)
                  "
                    formControlName="cmd_013"
                    value="0"
                  />No</label
                >
                <div
              *ngIf="
                hap.controls['cmd_013'].invalid && isSubmit
              "
            >
              <small
                class="text-danger"
                *ngIf="hap.controls['cmd_013'].errors"
              >
                Please select YES or NO
              </small>
            </div>
              </div>
              <div class="form-group no-margin col-md-8 sideLabelAlignment">
                <input
                  type="checkbox"
                  name="cmd_07259"
                  id="cmd_07259"
                  formControlName="cmd_07259"
                  (ngModelChange)="handleNAchange('cmd_07259')"
                  value="1"
                  class="texasFloat"
                />
                I have not obtained Licensure yet
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="6">
              <div
                class="form-group  no-margin col-md-12 sideLabelAlignment"
              >
                <label class="formLabel">LICENSE TYPE </label></div>
                <input
                  style="width: 40%"
                  class="form-control"
                  id="1274"
                  name="1274"
                  formControlName="1274"
                  type="text"
                  data-id="Alt License type"
                  placeholder="License 2 Type "
                />
              
            </td>
            <td colspan="6">
              <div
                class="form-group  no-margin col-md-12 sideLabelAlignment"
              >
                <label class="formLabel">LICENSE NUMBER </label></div>
                <input
                  style="width: 60%"
                  class="form-control"
                  id="300"
                  name="300"
                  formControlName="300"
                  type="text"
                  data-id="alt license"
                  placeholder="License 2 Number "
                />
              
            </td>
            <td colspan="6">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="formLabel">STATE OF REGISTRATION </label></div>
                <div class="form-group col-md-6 dropDrownSet">
                  
                    <ng-select
                      [items]="altLicenseState"
                      bindLabel="name"
                      placeholder="License 2 State"
                      bindValue="id"
                      formControlName="907"
                    
                    >
                    </ng-select>
                  
                </div>
              
            </td>
          </tr>
          <tr>
            <td colspan="6">
              <div class="form-group form-inline no-margin sideLabelAlignment">
                <label class="formLabel"
                  >ORIGINAL DATE OF ISSUE (MM/DD/YYYY)
                </label>
                <div class="input-group">
                  <input
                    class="form-control onboard datepicker"
                    placeholder="License 2 Issue Date"
                    ngbDatepicker
                    #d18="ngbDatepicker"
                    id="307"
                    name="307"
                    formControlName="307"
                    type="text"
                    data-id="alt licesne issue"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="d18.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
                
              </div>
              <div  *ngIf="hap.get('307').invalid && hap.get('307').touched">
                <small class="text-danger" *ngIf="hap.get('307').errors.invalidDateFormat">
                  Only Date Format is allowed
                </small>
              </div>
            </td>
            <td colspan="6">
              <div class="form-group form-inline no-margin sideLabelAlignment">
                <label class="formLabel">EXPIRATION DATE (MM/DD/YYYY) </label>
                <div class="input-group">
                  <input
                    style="width: 48%"
                    class="form-control onboard datepicker"
                    placeholder="Alt License Exp "
                    ngbDatepicker
                    #d19="ngbDatepicker"
                    id="308"
                    name="308"
                    formControlName="308"
                    type="text"
                    data-id="alt licesne exp"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="d19.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
                
              </div>
              <div  *ngIf="hap.get('308').invalid && hap.get('308').touched">
                <small class="text-danger" *ngIf="hap.get('308').errors.invalidDateFormat">
                  Only Date Format is allowed
                </small>
              </div>
            </td>
            <td colspan="6">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                DO YOU CURRENTLY PRACTICE IN THIS STATE?
              </div>
              <div class="form-group no-margin col-md-12 sideLabelAlignment">
                <label class="radio-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_014"
                    name="cmd_014"
                    formControlName="cmd_014"
                    value="1"
                  />Yes</label
                >
                <label class="radio-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_014"
                    name="cmd_014"
                    formControlName="cmd_014"
                    value="0"
                  />No</label
                >
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="6">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="formLabel">LICENSE TYPE </label>
                <input
                  style="width: 40%"
                  class="form-control"
                  id="1432"
                  name="1432"
                  formControlName="1432"
                  type="text"
                  data-id="add_license_type"

                  placeholder="Additional Lic Type"
                />
              </div>
            </td>
            <td colspan="6">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="formLabel">LICENSE NUMBER </label>
                <input
                  style="width: 60%"
                  class="form-control"
                  id="999"
                  name="999"
                  formControlName="999"
                  type="text"
                  data-id="add_license"
                  placeholder="Additional License Number"
                />
              </div>
            </td>
            <td colspan="6">
              <div
                class="form-group form-inline no-margin col-md-12 marginStyle"
              >
                <label class="formLabel">STATE OF REGISTRATION </label>
                <div class="form-group selectDrop dropDrownSet">
                  <div class="col-md-12">
                    <ng-select
                      [items]="additionalLicenseState"
                      bindLabel="name"
                      placeholder="License 3 State"
                      bindValue="id"
                      formControlName="1010"
                    >
                    </ng-select>
                  </div>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="6">
              <div class="form-group form-inline no-margin sideLabelAlignment">
                <label class="formLabel"
                  >ORIGINAL DATE OF ISSUE (MM/DD/YYYY)
                </label>
                <div class="input-group">
                  <input
                    class="form-control onboard datepicker"
                    placeholder="Add Lic Issue"
                    ngbDatepicker
                    #d20="ngbDatepicker"
                    id="1003"
                    name="1003"
                    formControlName="1003"
                    type="text"
                    data-id="add license issue"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="d20.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
                
              </div>
              <div  *ngIf="hap.get('1003').invalid && hap.get('1003').touched">
                <small class="text-danger" *ngIf="hap.get('1003').errors.invalidDateFormat">
                  Only Date Format is allowed
                </small>
              </div>
            </td>
            <td colspan="6">
              <div class="form-group form-inline no-margin sideLabelAlignment">
                <label class="formLabel">EXPIRATION DATE (MM/DD/YYYY) </label>
                <div class="input-group">
                  <input
                    style="width: 48%"
                    class="form-control onboard datepicker"
                    placeholder="Add Lic Exp"
                    ngbDatepicker
                    #d21="ngbDatepicker"
                    id="1004"
                    name="1004"
                    formControlName="1004"
                    type="text"
                    data-id="add license exp"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="d21.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
                
              </div>
              <div  *ngIf="hap.get('1004').invalid && hap.get('1004').touched">
                <small class="text-danger" *ngIf="hap.get('1004').errors.invalidDateFormat">
                  Only Date Format is allowed
                </small>
              </div>
            </td>
            <td colspan="6">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                DO YOU CURRENTLY PRACTICE IN THIS STATE?
              </div>
              <div class="form-group no-margin col-md-12 sideLabelAlignment">
                <label class="radio-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_015"
                    name="cmd_015"
                    formControlName="cmd_015"
                    value="1"
                  />Yes</label
                >
                <label class="radio-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_015"
                    name="cmd_015"
                    formControlName="cmd_015"
                    value="0"
                  />No</label
                >
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="6">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              ></div>
              <div class="form-group no-margin col-md-12 sideLabelAlignment">
                <label class="radio-inline radioStyle"></label>
                <input
                  type="radio"
                  id="cmd_016"
                  name="cmd_016"
                  formControlName="cmd_016"
                  value="1"
                />DEA Number:
                <input
                  style="width:70%;"
                  class="form-control"
                  id="309"
                  name="309"
                  formControlName="309"
                  type="text"
                  data-id="dea"
                  placeholder="DEA Number "
                />
              </div>
              
            </td>
            <td colspan="6">
              <div class="form-group form-inline no-margin sideLabelAlignment">
                <label class="formLabel"
                  >ORIGINAL DATE OF ISSUE (MM/DD/YYYY)
                </label>
                <div class="input-group">
                  <input
                    class="form-control onboard datepicker"
                    placeholder="DEA Issue Date "
                    ngbDatepicker
                    #d22="ngbDatepicker"
                    id="310"
                    name="310"
                    formControlName="310"
                    type="text"
                    data-id="deaissue"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="d22.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div  *ngIf="hap.get('310').invalid && hap.get('310').touched">
                <small class="text-danger" *ngIf="hap.get('310').errors.invalidDateFormat">
                  Only Date Format is allowed
                </small>
              </div>
            </td>
            <td colspan="6">
              <div class="form-group form-inline no-margin sideLabelAlignment">
                <label class="formLabel">EXPIRATION DATE (MM/DD/YYYY) </label>
                <div class="input-group">
                  <input
                    style="width:52%;"
                    class="form-control onboard datepicker"
                    ngbDatepicker
                    #d23="ngbDatepicker"
                    placeholder="DEA Exp Date "
                    id="311"
                    name="311"
                    formControlName="311"
                    type="text"
                    data-id="dea_exp_date"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="d23.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>

              </div>
              <div  *ngIf="hap.get('311').invalid && hap.get('311').touched">
                <small class="text-danger" *ngIf="hap.get('311').errors.invalidDateFormat">
                  Only Date Format is allowed
                </small>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="6">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              ></div>
              <div class="form-group no-margin col-md-12 sideLabelAlignment">
                <label class="radio-inline radioStyle"></label>
                <input
                  type="radio"
                  id="cmd_017"
                  name="cmd_017"
                  formControlName="cmd_017"
                  value="1"
                />
                CDS Number:
                <input
                  style="width:70%;"
                  class="form-control"
                  id="337"
                  name="337"
                  formControlName="337"
                  type="text"
                  data-id="driverslic"
                  placeholder="State Controlled Substance Number (CDS) "
                />
              </div>
            </td>
            <td colspan="6">
              <div class="form-group form-inline no-margin sideLabelAlignment">
                <label class="formLabel"
                  >ORIGINAL DATE OF ISSUE (MM/DD/YYYY)
                </label>
                <div class="input-group">
                  <input
                    style="width:48%;"
                    class="form-control onboard datepicker"
                    placeholder="CDS Issue Date "
                    ngbDatepicker
                    #d24="ngbDatepicker"
                    id="338"
                    name="338"
                    formControlName="338"
                    type="text"
                    data-id="drivers issue"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="d24.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div  *ngIf="hap.get('338').invalid && hap.get('338').touched">
                <small class="text-danger" *ngIf="hap.get('338').errors.invalidDateFormat">
                  Only Date Format is allowed
                </small>
              </div>
            </td>
            <td colspan="6">
              <div class="form-group form-inline no-margin sideLabelAlignment">
                <label class="formLabel">EXPIRATION DATE (MM/DD/YYYY) </label>
                <div class="input-group">
                  <input
                    style="width:52%;"
                    class="form-control onboard datepicker"
                    placeholder="CDS Exp Date "
                    ngbDatepicker
                    #d25="ngbDatepicker"
                    id="339"
                    name="339"
                    formControlName="339"
                    type="text"
                    data-id="drivers exp"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="d25.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div  *ngIf="hap.get('339').invalid && hap.get('339').touched">
                <small class="text-danger" *ngIf="hap.get('339').errors.invalidDateFormat">
                  Only Date Format is allowed
                </small>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="6">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="formLabel">OTHER CDS (PLEASE SPECIFY) </label>
                <input
                  style="width:70%;"
                  class="form-control"
                  id="cmd_019"
                  name="cmd_019"
                  formControlName="cmd_019"
                  type="text"
                  data-id="CDs other"
                />
              </div>
            </td>
            <td colspan="6">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="formLabel">NUMBER</label>
                <input
                  style="width:70%;"
                  class="form-control"
                  id="340"
                  name="340"
                  formControlName="340"
                  type="text"
                  data-id="CDs number"
                  placeholder="Alt CDS Number "
                />
              </div>
            </td>
            <td colspan="6">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="formLabel">STATE OF REGISTRATION </label>
                <div class="form-group selectDrop dropDrownSet">
                  <div class="col-md-12">
                    <ng-select
                      [items]="AltCdsStates"
                      bindLabel="name"
                      placeholder="ALT CDS STATE"
                      bindValue="id"
                      formControlName="997"
                    >
                    </ng-select>
                  </div>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="6">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="formLabel"
                  >ORIGINAL DATE OF ISSUE (MM/DD/YYYY)
                </label>
                <div class="input-group">
                  <input
                    style="width:50%;"
                    class="form-control onboard datepicker"
                    placeholder="ALT CDS ISSUE"
                    ngbDatepicker
                    #d26="ngbDatepicker"
                    id="341"
                    name="341"
                    formControlName="341"
                    type="text"
                    data-id="alt CDs issue date"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="d26.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div  *ngIf="hap.get('341').invalid && hap.get('341').touched">
                <small class="text-danger" *ngIf="hap.get('341').errors.invalidDateFormat">
                  Only Date Format is allowed
                </small>
              </div>
            </td>
            <td colspan="6">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="formLabel">EXPIRATION DATE (MM/DD/YYYY) </label>
                <div class="input-group">
                  <input
                    style="width:48%;"
                    class="form-control onboard datepicker"
                    placeholder="ALT CDS EXP"
                    ngbDatepicker
                    #d27="ngbDatepicker"
                    id="342"
                    name="342"
                    formControlName="342"
                    type="text"
                    data-id="alt CDs Exp date"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="d27.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div  *ngIf="hap.get('342').invalid && hap.get('342').touched">
                <small class="text-danger" *ngIf="hap.get('342').errors.invalidDateFormat">
                  Only Date Format is allowed
                </small>
              </div>
            </td>
            <td colspan="6">
              <div
                class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
              >
                <label class="formLabel"
                  >DO YOU CURRENTLY PRACTICE IN THIS STATE?
                  <input
                    type="radio"
                    id="cmd_020"
                    name="cmd_020"
                    formControlName="cmd_020"
                    value="1"
                  />Yes</label
                >
                <label class="radio-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_020"
                    name="cmd_020"
                    formControlName="cmd_020"
                    value="0"
                  />No</label
                >
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="8">
              <div class="form-inline no-margin col-md-12 sideLabelAlignment">
                <label class="formLabel">UPIN</label>
                <input
                  class="form-control"
                  id="819"
                  name="819"
                  formControlName="819"
                  type="text"
                  data-id="upin"
                  placeholder="UPIN"
                />
              </div>
            </td>
            <td colspan="10">
              <div class="form-inline no-margin col-md-12 sideLabelAlignment">
                <label class="formLabel"
                  >NATIONAL PROVIDER IDENTIFIER (WHEN AVAILABLE)<span class="mandatory">*</span>
                </label>
                <input
                  class="form-control"
                  id="1634"
                  name="1634"
                  [class.is-invalid]="
                  (hap.controls['1634'].touched &&
                    hap.controls['1634'].invalid) ||
                  (hap.controls['1634'].invalid && isSubmit)
                "
                  formControlName="1634"
                  type="text"
                  data-id="npi"
                  Placeholder="NPI"
                />
                <div
                  class="formLabel col-md-2"
                  *ngIf="
                    hap.controls['1634'].touched &&
                    hap.controls['1634'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="hap.controls['1634'].errors?.required"
                  >
                    required
                  </small>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="9">
              <div class="form-group form-inline no-margin sideLabelAlignment">
                <label class="formLabel"
                  >ARE YOU A PARTICIPATING MEDICARE PROVIDER?
                  <input
                    type="radio"
                    id="cmd_021"
                    name="cmd_021"
                    formControlName="cmd_021"
                    value="1"
                  />Yes</label
                >
                <label class="radio-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_021"
                    name="cmd_021"
                    formControlName="cmd_021"
                    value="0"
                  />No</label
                >
                <label class="formLabel">Medicare Provider Number:</label>
                <input
                  class="form-control"
                  id="325"
                  name="325"
                  formControlName="325"
                  type="text"
                  data-id="medicare"
                  placeholder="MEDICARE NUMBER"
                />
              </div>
            </td>
            <td colspan="9">
              <div class="form-group form-inline no-margin sideLabelAlignment">
                <label class="formLabel"
                  >ARE YOU A PARTICIPATING MEDICAID PROVIDER?
                  <input
                    type="radio"
                    id="cmd_022"
                    name="cmd_022"
                    formControlName="cmd_022"
                    value="1"
                  />Yes</label
                >
                <label class="radio-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_022"
                    name="cmd_022"
                    formControlName="cmd_022"
                    value="0"
                  />No</label
                >
                <label class="formLabel">Medicaid Provider Number:</label>
                <input
                  class="form-control"
                  id="812"
                  name="812"
                  formControlName="812"
                  type="text"
                  data-id="medicaid"
                  placeholder="MEDICAID NUMBER"
                />
              </div>
            </td>
          </tr>
          <tr>
      <td colspan="9">
        <div
          class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
        >
          <label class="boldParagraph"
            >EDUCATIONAL COUNCIL FOR FOREIGN MEDICAL GRADUATES (ECFMG)<span class="mandatory">*</span>
            <input
              type="radio"
              id="cmd_023"
              name="cmd_023"
              (ngModelChange)="handleYesNoChanges('cmd_023')" [class.is-invalid]="(hap.controls['cmd_023'].touched && hap.controls['cmd_023'].invalid) || (hap.controls['cmd_023'].invalid && isSubmit)"
              formControlName="cmd_023"
              value="0"
            />N/A</label
          >
          <label class="radio-inline radioStyle">
            <input
              type="radio"
              id="cmd_023"
              name="cmd_023"
              (ngModelChange)="handleYesNoChanges('cmd_023')" [class.is-invalid]="(hap.controls['cmd_023'].touched && hap.controls['cmd_023'].invalid) || (hap.controls['cmd_023'].invalid && isSubmit)"
              formControlName="cmd_023"
              value="1"
            />Yes</label
          >
          <label class="radio-inline radioStyle">
            <input
              type="radio"
              id="cmd_023"
              name="cmd_023"
              (ngModelChange)="handleYesNoChanges('cmd_023')" [class.is-invalid]="(hap.controls['cmd_023'].touched && hap.controls['cmd_023'].invalid) || (hap.controls['cmd_023'].invalid && isSubmit)"
              formControlName="cmd_023"
              value="2"
            />No</label
          >
          <div class="formLabel col-md-11"
          *ngIf="hap.controls['cmd_023'].touched && hap.controls['cmd_023'].invalid">
          <small class="text-danger" *ngIf="hap.controls['cmd_023'].errors?.required">
              required
          </small>
        </div>
        <span *ngIf="(hap.controls['cmd_023'].touched && hap.controls['cmd_023'].errors) || (hap.controls['cmd_023'].errors && isSubmit)">
          <i class="fas fa-times ml-2 text-danger"></i>
        </span>
          <div class="formLabel col-md-11"></div>
          <label class="formLabel"> ECFMG Number:</label>
          <input
          (blur)="handleNAchange('cmd_023')"
          [class.is-invalid]="(hap.controls['514'].invalid) || (hap.controls['cmd_023'].value ==1 && hap.controls['514'].value=='') "   
            class="text form-control col-md-3"
            id="514"
            name="514"
            formControlName="514"
            type="text"
            data-id="ecfmg"
            placeholder="ECFMG Number "
            
          />
          <div class="mandatory" *ngIf="(hap.controls['514'].invalid) || (hap.controls['cmd_023'].value ==1 && hap.controls['514'].value=='')">
            <small >
            required
            </small>
          </div>
        </div>
      </td>
      <td colspan="9">
        <div
          class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
        >
          <label class="formLabel">ECFMG ISSUE DATE (MM/DD/YYYY) </label>
        </div>
        
        <div class="input-group col-md-5">
          <input
            style="width:38%;"
            class="form-control onboard datepicker"
            placeholder="ECFMG Issue Date "
            ngbDatepicker
            #d28="ngbDatepicker"
            id="515"
            name="515"
            formControlName="515"
            type="text"
            data-id="ecfmgissue_date"
          />
          <div class="input-group-append">
            <button
              class="btn btn-outline-secondary calendar"
              (click)="d28.toggle()"
              type="button"
            >
              <i class="fas fa-calendar-alt"></i>
            </button>
          </div>
        </div>
        <div  *ngIf="hap.get('515').invalid && hap.get('515').touched">
          <small class="text-danger" *ngIf="hap.get('515').errors.invalidDateFormat">
            Only Date Format is allowed
          </small>
        </div>
      </td>
    </tr>
           </table>
           <table border="1" width="100%">
            <tr>
              <td colspan="14">
                <div class="centered texasFirstTable">
                  <h3 class="texasHThree">Professional/Specialty Information</h3>
                </div>
              </td>
              <td colspan="4">
                <label class="texasFloat">Does Not Apply</label>
                <input
                  class="texasFloat"
                  type="checkbox"
                  name="cmd_0726"
                  id="cmd_0726"
                  (ngModelChange)="handleNAchange('cmd_0726')"
                  formControlName="cmd_0726"
                  value="1"
                />
              </td>
             </tr>
             <tr>
              <td colspan="6">
                
                  <label class="boldParagraph">PRIMARY SPECIALTY <span class="mandatory">*</span></label> 
                  
                    <ng-select 
                      [items]="specialty"
                      [class.is-invalid]="hap.controls['284'].invalid"
                      bindLabel="name"
                      placeholder="Primary Board specialty"
                      bindValue="id"
                      formControlName="284"
                      (blur)="handleNAchange('cmd_0726', 284)"
                    >
                    </ng-select>
                    <div class="mandatory"
                         *ngIf="hap.controls['284'].invalid">
                   <small >
                       required
                   </small>
                   </div>
                  
                
              </td>
              <td colspan="12">
                
                  <label class="boldParagraph">BOARD CERTIFIED?<span class="mandatory">*</span></label>
                  <label class="radio-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_024"
                      name="cmd_024"
                      formControlName="cmd_024"
                      (blur)="handleNAchange('cmd_0726')"
                      (ngModelChange)="handleYesNoChanges('cmd_024')"
                      [class.is-invalid]="(hap.controls['cmd_024'].touched &&
                      hap.controls['cmd_024'].invalid) ||
                  (hap.controls['cmd_024'].invalid && isSubmit)"
                      
                      value="1"
                      
                    />Yes</label
                  >
                  <label class="radio-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_024"
                      name="cmd_024"
                      formControlName="cmd_024"
                      (blur)="handleNAchange('cmd_0726')"
                      (ngModelChange)="handleYesNoChanges('cmd_024')"
                      [class.is-invalid]="(hap.controls['cmd_024'].touched &&
                      hap.controls['cmd_024'].invalid) ||
                  (hap.controls['cmd_024'].invalid && isSubmit)"
                      
                      value="0"
                      
                    />No</label
                  >
                  <div class="mandatory"
                  *ngIf="hap.controls['cmd_024'].invalid">
            <small >
                required
            </small>
            </div>
                  <span *ngIf="(hap.controls['cmd_024'].touched && hap.controls['cmd_024'].errors) || (hap.controls['cmd_024'].errors && isSubmit)">
                    <i class="fas fa-times ml-2 text-danger"></i>
                  </span>
                  <br />
              
                  <div >
                    
                    
                      <label class="formLabel">Name of Certifying Board:</label>
                      
                        
                          <ng-select class="col-md-6"
                          [items]="boardCertification"
                          (blur)="handleYesNoChanges('cmd_024')" 
                          [class.is-invalid]="hap.controls['554'].invalid"                              
                          placeholder="Primary Board Certification "
                          bindValue="id"
                          bindLabel="name"
                          formControlName="554"
                          
                          >
                          </ng-select>
                          <div class="mandatory"
                          *ngIf="hap.controls['554'].invalid">
                    <small >
                        required
                    </small>
                             </div>
                        
                      

                  </div>
                
                
              </td>
            <!-- <td colspan="12">
                      
                        <label class="boldParagraph">BOARD CERTIFIED?</label>
                        <label class="radio-inline radioStyle">
                          <input
                            type="radio"
                            id="cmd_024"
                            name="cmd_024"
                            formControlName="cmd_024"
                            [class.is-invalid]="hap.controls['cmd_024'].invalid"  
                            (change)="onChangeCmdThree($event)"
                            value="1"
                            (blur)="handleNAchange('cmd_0726', 'cmd_024')"
                          />Yes</label
                        >
                        <label class="radio-inline radioStyle">
                          <input
                            type="radio"
                            id="cmd_024"
                            name="cmd_024"
                            formControlName="cmd_024"
                            [class.is-invalid]="hap.controls['cmd_024'].invalid"  
                            (change)="onChangeCmdThree($event)"
                            value="0"
                            (blur)="handleNAchange('cmd_0726', 'cmd_024')"
                          />No</label
                        >
                        <div class="mandatory"
                        *ngIf="hap.controls['cmd_024'].invalid">
                  <small >
                      required
                  </small>
                    </div>
                        <span
                          *ngIf="
                            (hap.controls['cmd_024'].touched &&
                              hap.controls['cmd_024'].errors) ||
                            (hap.controls['cmd_024'].errors && isSubmit)
                          "
                        >
                          <i class="fas fa-times ml-2 text-danger"></i>
                        </span>
                        <br />
                    
                        <div *ngIf="changeCmdThree">
                          
                          
                            <label class="formLabel">Name of Certifying Board:</label>
                            
                              
                                <ng-select class="col-md-6"
                                [items]="boardCertification"
                                (blur)="handleYesNoChanges('cmd_024')" 
                                [class.is-invalid]="hap.controls['554'].invalid"                              
                                placeholder="Primary Board Certification "
                                bindValue="id"
                                bindLabel="name"
                                formControlName="554"
                                
                                >
                                </ng-select>
                                <div class="mandatory"
                                *ngIf="hap.controls['554'].invalid">
                          <small >
                              required
                          </small>
                                   </div>
                              
                            

                        </div>
                      
                      
                    </td> -->
            </tr>
            <tr>
              <td colspan="4">
                <div
                  class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel"
                    >INITIAL CERTIFICATION DATE (MM/DD/YYYY)<span class="mandatory">*</span>
                  </label>
                  <div class="input-group">
                    <input
                      style="width:80%;"
                      [class.is-invalid]="hap.controls['557'].invalid"
                      class="form-control onboard datepicker"
                      placeholder="Pri Board Issue"
                      ngbDatepicker
                      #d29="ngbDatepicker"
                      id="557"
                      name="557"
                      formControlName="557"
                      (blur)="handleNAchange('cmd_0726', 557)"
                      type="text"
                      data-id="primarybcert"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="d29.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div class="mandatory"
                         *ngIf="hap.controls['557'].invalid">
                   <small >
                       required
                   </small>
                  </div>
                </div>
              </td>
              <td colspan="8">
                <div
                  class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel"
                    >RECERTIFICATION DATE(S), IF APPLICABLE (MM/DD/YYYY)
                  </label>
                  <div class="input-group">
                    <input
                      style="width:48%;"
                      class="form-control onboard datepicker"
                      placeholder="Pri Board First Recert Date"
                      ngbDatepicker
                      #d30="ngbDatepicker"
                      id="559"
                      name="559"
                      formControlName="559"
                      type="text"
                      data-id="primarybrcert2"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="d30.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                </div>
                <div  *ngIf="hap.get('559').invalid && hap.get('559').touched">
                  <small class="text-danger" *ngIf="hap.get('559').errors.invalidDateFormat">
                    Only Date Format is allowed
                  </small>
                </div>
              </td>
              <td colspan="6">
                <div class="form-group form-inline no-margin sideLabelAlignment">
                  <label class="formLabel"
                    >EXPIRATION DATE, IF APPLICABLE (MM/DD/YYYY)
                  </label>
                  <div class="input-group">
                    <input
                      style="width:51%;"
                      class="form-control onboard datepicker"
                      placeholder="Pri Board Exp"
                      ngbDatepicker
                      #d31="ngbDatepicker"
                      id="560"
                      name="560"
                      formControlName="560"
                      type="text"
                      data-id="primarybexp_date"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="d31.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                </div>
                <div  *ngIf="hap.get('560').invalid && hap.get('560').touched">
                  <small class="text-danger" *ngIf="hap.get('560').errors.invalidDateFormat">
                    Only Date Format is allowed
                  </small>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                
                  <div class="col-md-8">
                    <label class="formLabel"
                      >IF NOT BOARD CERTIFIED, INDICATE ANY OF THE FOLLOWING THAT
                      APPLY.</label
                    >
                  </div>
                  <div class="row">
                    <div class="col-md-4">
                      <label>
                    
                      <input
                        type="checkbox"
                        id="cmd_025"
                        name="cmd_025"
                        formControlName="cmd_025"
                        value="1"/>
                      I have taken exam, results pending for Board</label>
                    </div>
                    
              
                      <div class="col-md-6">
                        <input type="text" style="width:50%"  id="cmd_029" class="form-control" formControlName="cmd_029" >
                        
                      
                    </div>
                  </div>  
                  <div class="row">
                    <div class="col-md-4">
                      <label>
                        <input
                        type="checkbox"
                        id="cmd_026"
                        name="cmd_026"
                        formControlName="cmd_026"
                        value="1"/>
                      I have taken Part I and am eligible for Part II of the Exam.</label>
                    </div>
                    <div class="col-md-6">   
                      <input
                      style="width: 50%"
                      class="form-control"
                      id="cmd_030"
                      name="cmd_030"
                      formControlName="cmd_030"
                      type="text"
                      data-id="test2"
                  />
                    </div>
                  </div>  
                  <div class="row">             
                    <div class="col-md-4">
                      <label>
                    
                        <input
                        type="checkbox"
                        id="cmd_027"
                        name="cmd_027"
                        formControlName="cmd_027"
                        value="1"/>I am intending to sit for the Boards on (date)</label>
                    </div>
                    <div class="col-md-6">   
                      <input
                      style="width: 50%"
                      class="form-control"
                      id="cmd_031"
                      name="cmd_031"
                      formControlName="cmd_031"
                      type="text"
                      data-id="test3"
                  />
                    </div>
                  </div>  
                  <div class="row">
            
                    <div class="col-md-12">
                      <label>
                        <input
                          type="checkbox"
                          id="cmd_028"
                          name="cmd_028"
                          formControlName="cmd_028"
                          value="1"
                        />
                      I am not planning to take Boards</label>
                   
                    </div>
                  </div>  
                
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div class="form-group">
                  <div class="row extraBoxPaddingLeft">
                    <div class="col-md-12">
                      <label class="formLabel"
                        >DO YOU WISH TO BE LISTED IN THE DIRECTORY UNDER THIS
                        SPECIALTY?
                      </label>
                    </div>
                  </div>
                  <br />
                  <label class="formLabel"><b>HMO:</b></label>
                  <label class="boldParagraph" class="checkbox-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_032"
                      name="cmd_032"
                      formControlName="cmd_032"
                      value="1"
                    />
                    Yes</label
                  >
                
                  <label class="boldParagraph" class="checkbox-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_032"
                      name="cmd_032"
                      formControlName="cmd_032"
                      value="0"
                    />
                    No
                  </label>
                  <br>
                  <label class="formLabel"><b>PPO:</b></label>
                  <label class="boldParagraph" class="checkbox-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_033"
                      name="cmd_033"
                      formControlName="cmd_033"
                      value="1"
                    />
                    Yes</label
                  >
                  <label class="boldParagraph" class="checkbox-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_033"
                      name="cmd_033"
                      formControlName="cmd_033"
                      value="0"
                    />
                    No
                  </label>
                  <br />
                  <label class="formLabel"><b>POS:</b></label>
                  <label class="boldParagraph" class="checkbox-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_034"
                      name="cmd_034"
                      formControlName="cmd_034"
                      value="1"
                    />Yes</label
                  >
                  <label class="boldParagraph" class="checkbox-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_034"
                      name="cmd_034"
                      formControlName="cmd_034"
                      value="0"
                    />
                    No
                  </label>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6">
                
                  
                    <label class="formLabel">SECONDARY SPECIALTY </label>
                  
                    <ng-select 
                      [items]="specialtySecondary"
                      bindLabel="name"
                      placeholder="Secondary Board specialty"
                      bindValue="id"
                      formControlName="962"
                      
                    >
                    </ng-select>

                
                  
                  
                
              </td>
              <td colspan="12">
                
                  <label class="boldParagraph">BOARD CERTIFIED?</label>
                  <label class="radio-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_035"
                      name="cmd_035"
                      formControlName="cmd_035"
                      (change)="onChangeCmdThree1($event)"
                      value="1"
                    />Yes</label
                  >
                  <label class="radio-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_035"
                      name="cmd_035"
                      formControlName="cmd_035"
                      (change)="onChangeCmdThree1($event)"
                      value="0"
                    />No</label
                  >
                  <br />
                  <div *ngIf="changeCmdThree1"> 
                  
                    <label class="formLabel">Name of Certifying Board:</label>
                    
                      
                        <ng-select class="col-md-6"
                          [items]="boardCertification"
                          bindLabel="name"
                          placeholder="Second Board Certification"
                          bindValue="id"
                          formControlName="561"
                        >
                        </ng-select>
                      
                    
                  
                </div>
                
              </td>
            </tr>
            <tr>
              <td colspan="6">
                <div
                  class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel"
                    >INITIAL CERTIFICATION DATE (MM/DD/YYYY)
                  </label>
                  <div class="input-group">
                    <input
                      style="width:53%;"
                      class="form-control onboard datepicker"
                      placeholder="Sec Board Issue"
                      ngbDatepicker
                      #d32="ngbDatepicker"
                      id="564"
                      name="564"
                      formControlName="564"
                      type="text"
                      data-id="secbcert"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="d32.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                </div>
                <div  *ngIf="hap.get('564').invalid && hap.get('564').touched">
                  <small class="text-danger" *ngIf="hap.get('564').errors.invalidDateFormat">
                    Only Date Format is allowed
                  </small>
                </div>
              </td>
              <td colspan="6">
                <div
                  class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel"
                    >RECERTIFICATION DATE(S), IF APPLICABLE (MM/DD/YYYY)
                  </label>
                  <div class="input-group">
                    <input
                      style="width:47%;"
                      class="form-control onboard datepicker"
                      placeholder="Sec Board first recert date"
                      ngbDatepicker
                      #d33="ngbDatepicker"
                      id="565"
                      name="565"
                      formControlName="565"
                      type="text"
                      data-id="secbrcert2"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="d33.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                </div>
                <div  *ngIf="hap.get('565').invalid && hap.get('565').touched">
                  <small class="text-danger" *ngIf="hap.get('565').errors.invalidDateFormat">
                    Only Date Format is allowed
                  </small>
                </div>
              </td>
              <td colspan="6">
                <div
                  class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel"
                    >EXPIRATION DATE, IF APPLICABLE (MM/DD/YYYY)
                  </label>
                  <div class="input-group">
                    <input
                      style="width:50%;"
                      class="form-control onboard datepicker"
                      placeholder="Sec Board Exp"
                      ngbDatepicker
                      #d34="ngbDatepicker"
                      id="567"
                      name="567"
                      formControlName="567"
                      type="text"
                      data-id="secbexp_date"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="d34.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                </div>
                <div  *ngIf="hap.get('567').invalid && hap.get('567').touched">
                  <small class="text-danger" *ngIf="hap.get('567').errors.invalidDateFormat">
                    Only Date Format is allowed
                  </small>
                </div>
              </td>
            </tr>
             </table> 
             <hr class="tablePersonal" />
  <table border="1" width="100%">
    <td colspan="18">
      <div class="centered texasFirstTable">
        <h3 class="texasHThree">
          <b>Professional/Specialty Information</b> -continued
        </h3>
      </div>
    </td>
    <tr>
      <td colspan="18">
        
          <div class="col-md-8">
            <label class="formLabel"
              >IF NOT BOARD CERTIFIED, INDICATE ANY OF THE FOLLOWING THAT
              APPLY.</label
            >
          </div>
          <div class="row">
            <div class="col-md-4">
              <label>
            
       <input 
                type="checkbox"
                id="cmd_036"
                name="cmd_036"
                formControlName="cmd_036"
                value="1"/>
              I have taken exam, results pending for Board</label>
            </div>
               
      
              <div class="col-md-6
              ">
                <input type="text" style="width: 50%"   id="cmd_040" class="form-control" formControlName="cmd_040" >
                
              
            </div>
          </div>  
          <div class="row">
            <div class="col-md-4">
              <label>
                <input
                type="checkbox"
                id="cmd_037"
                name="cmd_037"
                formControlName="cmd_037"
                value="1"/>
              I have taken Part I and am eligible for Part II of the Exam.</label>
            </div>
            <div class="col-md-6">   
              <input
              style="width: 50%"
              class="form-control"
              id="cmd_041"
              name="cmd_041"
              formControlName="cmd_041"
              type="text"
              data-id="test5"
          />
            </div>
          </div>  
          <div class="row">             
            <div class="col-md-4">
              <label>
            
                <input
                type="checkbox"
                id="cmd_038"
                name="cmd_038"
                formControlName="cmd_038"
                value="1"/>I am intending to sit for the Boards on (date)</label>
            </div>
            <div class="col-md-6">   
              <input
              style="width: 50%"
              class="form-control"
              id="cmd_042"
              name="cmd_042"
              formControlName="cmd_042"
              type="text"
              data-id="test6"
          />
            </div>
          </div>  
          <div class="row">
    
            <div class="col-md-12">
              <label>
                <input
                type="checkbox"
                id="cmd_039"
                name="cmd_039"
                formControlName="cmd_039"
                value="1"
              />
              I am not planning to take Boards</label>
           
            </div>
          </div>  
        
      </td>
    </tr>
<tr>
      <td colspan="18">
        <div class="form-group ">
          <label class="formLabel"
            >DO YOU WISH TO BE LISTED IN THE DIRECTORY UNDER THIS
            SPECIALTY?
          </label>
          <br />
          <label class="formLabel"><b>HMO:</b></label>
          <label class="boldParagraph" class="checkbox-inline radioStyle">
            <input
              type="radio"
              id="cmd_043"
              name="cmd_043"
              formControlName="cmd_043"
              value="1"
            />
            Yes</label
          >
          <label class="boldParagraph" class="checkbox-inline radioStyle">
            <input
              type="radio"
              id="cmd_043"
              name="cmd_043"
              formControlName="cmd_043"
              value="0"
            />
            No
          </label>
          <br />
          <label class="formLabel"><b>PPO:</b></label>
          <label class="boldParagraph" class="checkbox-inline radioStyle">
            <input
              type="radio"
              id="cmd_044"
              name="cmd_044"
              formControlName="cmd_044"
              value="1"
            />
            Yes</label
          >
          <label class="boldParagraph" class="checkbox-inline radioStyle">
            <input
              type="radio"
              id="cmd_044"
              name="cmd_044"
              formControlName="cmd_044"
              value="0"
            />
            No
          </label>
          <br />
          <label class="formLabel"><b>POS:</b></label>
          <label class="boldParagraph" class="checkbox-inline radioStyle">
            <input
              type="radio"
              id="cmd_045"
              name="cmd_045"
              formControlName="cmd_045"
              value="1"
            />Yes</label
          >
          <label class="boldParagraph" class="checkbox-inline radioStyle">
            <input
              type="radio"
              id="cmd_045"
              name="cmd_045"
              formControlName="cmd_045"
              value="0"
            />
            No
          </label>
        </div>
      </td>
</tr>
    <tr>
      <td colspan="9">
        <div class="row">
          <div class="col-md-12">
            <label class="formLabel">ADDITIONAL SPECIALTY </label>
          
            <!-- <ng-select
              [items]="specialtyTer"
              bindLabel="name"
              placeholder="Third Board specialty"
              bindValue="id"
              formControlName="965"
            >
            </ng-select> -->
            <ng-select
              [items]="specialtyTer"
              bindLabel="name"
              placeholder="Third Board specialty"
              bindValue="id"
              formControlName="965"
            >
            </ng-select>
          </div>
        </div>
          
          
        
      </td>
      <td colspan="9">
        
          <label class="boldParagraph">BOARD CERTIFIED?</label>
          <label class="radio-inline radioStyle">
            <input
              type="radio"
              id="cmd_046"
              name="cmd_046"
              formControlName="cmd_046"
              (change)="onChangeCmdThree2($event)"
              value="1"
            />Yes</label
          >
          <label class="radio-inline radioStyle">
            <input
              type="radio"
              id="cmd_046"
              name="cmd_046"
              formControlName="cmd_046"
              (change)="onChangeCmdThree2($event)"
              
              value="0"
            />No</label
          >
          <br />
          <div *ngIf="changeCmdThree2"> 
          
            <label class="formLabel">Name of Certifying Board:</label>
            
              
                <ng-select class="col-md-6"
                  [items]="boardCertification"
                  bindLabel="name"
                  placeholder="Third Board Certification "
                  bindValue="id"
                  formControlName="946"
                >
                </ng-select>
              
            
          
        </div>
        
      </td>
    </tr>
    <tr>
      <td colspan="6">
        <div
          class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
        >
          <label class="formLabel"
            >INITIAL CERTIFICATION DATE (MM/DD/YYYY)
          </label>
          <div class="input-group">
            <input
              style="width:69%;"
              class="form-control onboard datepicker"
              placeholder="Third Board Issue"
              ngbDatepicker
              #d35="ngbDatepicker"
              id="953"
              name="953"
              formControlName="953"
              type="text"
              data-id="thrid board original issue date"
            />
            <div class="input-group-append">
              <button
                class="btn btn-outline-secondary calendar"
                (click)="d35.toggle()"
                type="button"
              >
                <i class="fas fa-calendar-alt"></i>
              </button>
            </div>
          </div>
        </div>
        <div  *ngIf="hap.get('953').invalid && hap.get('953').touched">
          <small class="text-danger" *ngIf="hap.get('953').errors.invalidDateFormat">
            Only Date Format is allowed
          </small>
        </div>
      </td>
      <td colspan="6">
        <div
          class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
        >
          <label class="formLabel"
            >RECERTIFICATION DATE(S), IF APPLICABLE (MM/DD/YYYY)
          </label>
          <div class="input-group">
            <input
              style="width:210px"
              class="form-control onboard  datepicker"
              placeholder="Third Board First Recert Date"
              ngbDatepicker
              #d36="ngbDatepicker"
              id="950"
              name="950"
              formControlName="950"
              type="text"
              data-id="Tertiary Board recert 2"
            />
            <div class="input-group-append">
              <button
                class="btn btn-outline-secondary calendar"
                (click)="d36.toggle()"
                type="button"
              >
                <i class="fas fa-calendar-alt"></i>
              </button>
            </div>
          </div>
        </div>
        <div  *ngIf="hap.get('950').invalid && hap.get('950').touched">
          <small class="text-danger" *ngIf="hap.get('950').errors.invalidDateFormat">
            Only Date Format is allowed
          </small>
        </div>
      </td>
      <td colspan="6">
        <div
          class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
        >
          <label class="formLabel"
            >EXPIRATION DATE, IF APPLICABLE (MM/DD/YYYY)
          </label>
          <div class="input-group">
            <input
              style="width:45%;"
              class="form-control onboard datepicker"
              placeholder="Third Board Exp"
              ngbDatepicker
              #d37="ngbDatepicker"
              id="954"
              name="954"
              formControlName="954"
              type="text"
              data-id="Tertiary Board exp"
            />
            <div class="input-group-append">
              <button
                class="btn btn-outline-secondary calendar"
                (click)="d37.toggle()"
                type="button"
              >
                <i class="fas fa-calendar-alt"></i>
              </button>
            </div>
          </div>
        </div>
        <div  *ngIf="hap.get('954').invalid && hap.get('954').touched">
          <small class="text-danger" *ngIf="hap.get('954').errors.invalidDateFormat">
            Only Date Format is allowed
          </small>
        </div>
      </td>
    </tr>
    <tr>
      <td colspan="18">
        
          <div class="col-md-8">
            <label class="formLabel"
              >IF NOT BOARD CERTIFIED, INDICATE ANY OF THE FOLLOWING THAT
              APPLY.</label
            >
          </div>
          <div class="row">
            <div class="col-md-4">
              <label>
                <input
                type="checkbox"
                id="cmd_047"
                name="cmd_047"
                formControlName="cmd_047"
                value="1"/>
              I have taken exam, results pending for Board</label>
            </div>
             
      
              <div class="col-md-6">
                <input
                style="width: 50%"
                class="form-control"
                id="cmd_051"
                name="cmd_051"
                formControlName="cmd_051"
                type="text"
                data-id="test7"
            />
                
              
            </div>
          </div>  
          <div class="row">
            <div class="col-md-4">
              <label>
                <input
                type="checkbox"
                id="cmd_048"
                name="cmd_048"
                formControlName="cmd_048"
                value="1"/>
              I have taken Part I and am eligible for Part II of the Exam.</label>
            </div>
            <div class="col-md-6">   
              <input
              style="width: 50%"
              class="form-control"
              id="cmd_052"
              name="cmd_052"
              formControlName="cmd_052"
              type="text"
              data-id="test8"
          />
            </div>
          </div>  
          <div class="row">             
            <div class="col-md-4">
              <label>
            
                <input
                type="checkbox"
                id="cmd_049"
                name="cmd_049"
                formControlName="cmd_049"
                value="1"/>I am intending to sit for the Boards on (date)</label>
            </div>
            <div class="col-md-6">   
              <input
              style="width: 50%"
              class="form-control"
              id="cmd_053"
              name="cmd_053"
              formControlName="cmd_053"
              type="text"
              data-id="test9"
          />
            </div>
          </div>  
          <div class="row">
    
            <div class="col-md-12">
              <label>
                <input
                type="checkbox"
                id="cmd_050"
                name="cmd_050"
                formControlName="cmd_050"
                value="1"
              />
              I am not planning to take Boards</label>
           
            </div>
          </div>  
        
      </td>
    </tr>
    <!-- <tr>
      <td colspan="18">
        <div class="form-group form-inline no-margin sideLabelAlignment">
          <div class="col-md-8">
            <label class="formLabel"
              >IF NOT BOARD CERTIFIED, INDICATE ANY OF THE FOLLOWING THAT
              APPLY.</label
            >
            <br />
          </div>
          <div class="col-md-12">
            <label
              class="boldParagraph"
              class="checkbox-inline radioStyle"
            >
              <input
                type="checkbox"
                id="cmd_047"
                name="cmd_047"
                formControlName="cmd_047"
                value="1"/>
              I have taken exam, results pending for Board
              <input
                style="width: 50%"
                class="form-control"
                id="cmd_051"
                name="cmd_051"
                formControlName="cmd_051"
                type="text"
                data-id="test7"
            /></label>
          </div>
          <br />
          <div class="col-md-12">
            <label
              class="boldParagraph"
              class="checkbox-inline radioStyle"
            >
              <input
                type="checkbox"
                id="cmd_048"
                name="cmd_048"
                formControlName="cmd_048"
                value="1"/>
              I have taken Part I and am eligible for Part II of the Exam.
              <input
                style="width: 50%"
                class="form-control"
                id="cmd_052"
                name="cmd_052"
                formControlName="cmd_052"
                type="text"
                data-id="test8"
            /></label>
          </div>
          <br />
          <div class="col-md-12">
            <label
              class="boldParagraph"
              class="checkbox-inline radioStyle"
            >
              <input
                type="checkbox"
                id="cmd_049"
                name="cmd_049"
                formControlName="cmd_049"
                value="1"/>I am intending to sit for the Boards on (date)
              <input
                style="width: 50%"
                class="form-control"
                id="cmd_053"
                name="cmd_053"
                formControlName="cmd_053"
                type="text"
                data-id="test9"
            /></label>
          </div>
          <br />
          <div class="col-md-12">
            <label
              class="boldParagraph"
              class="checkbox-inline radioStyle"
            >
              <input
                type="checkbox"
                id="cmd_050"
                name="cmd_050"
                formControlName="cmd_050"
                value="1"
              />
              I am not planning to take Boards</label
            >
          </div>
        </div>
      </td>
    </tr> -->
    <tr>
      <td colspan="18">
        <div class="form-group ">
          <label class="formLabel"
            >DO YOU WISH TO BE LISTED IN THE DIRECTORY UNDER THIS
            SPECIALTY?
          </label>
          <br />
          <label class="formLabel"><b>HMO:</b></label>
          <label class="boldParagraph" class="checkbox-inline radioStyle">
            <input
              type="radio"
              id="cmd_054"
              name="cmd_054"
              formControlName="cmd_054"
              value="1"
            />
            Yes</label
          >
          <label class="boldParagraph" class="checkbox-inline radioStyle">
            <input
              type="radio"
              id="cmd_054"
              name="cmd_054"
              formControlName="cmd_054"
              value="0"
            />
            No
          </label>
          <br />
          <label class="formLabel"><b>PPO:</b></label>
          <label class="boldParagraph" class="checkbox-inline radioStyle">
            <input
              type="radio"
              id="cmd_055"
              name="cmd_055"
              formControlName="cmd_055"
              value="1"
            />
            Yes</label
          >
          <label class="boldParagraph" class="checkbox-inline radioStyle">
            <input
              type="radio"
              id="cmd_055"
              name="cmd_055"
              formControlName="cmd_055"
              value="0"
            />
            No
          </label>
          <br />
          <label class="formLabel"><b>POS:</b></label>
          <label class="boldParagraph" class="checkbox-inline radioStyle">
            <input
              type="radio"
              id="cmd_056"
              name="cmd_056"
              formControlName="cmd_056"
              value="1"
            />Yes</label
          >
          <label class="boldParagraph" class="checkbox-inline radioStyle">
            <input
              type="radio"
              id="cmd_056"
              name="cmd_056"
              formControlName="cmd_056"
              value="0"
            />
            No
          </label>
        </div>
      </td>
    </tr>
    <tr>
      <td colspan="18">
        <div
          class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
        >
          <label class="checkbox-inline radioStyle"
            >PLEASE LIST OTHER AREAS OF PROFESSIONAL PRACTICE INTEREST OR
            FOCUS (HIV/AIDS, ETC.)
          </label>
          <input
            class="form-control"
            id="cmd_057"
            name="cmd_057"
            formControlName="cmd_057"
            type="text"
            data-id="other interest"
          />
        </div>
      </td>
    </tr>
    <tr>
      <td colspan="14">
        <div
          class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
        >
          <label class="formLabel"><b>Work History.</b></label
          >- Please provide a chronological work history. You may submit a
          Curriculum Vitae as a supplement. Please explain all gaps in
          employment that lasted more than six months
        </div>
      </td>
      <td colspan="4">
        <label class="texasFloat">Does Not Apply</label>
        <input
          type="checkbox"
          name="cmd_0727"
          id="cmd_0727"
          formControlName="cmd_0727"
          value="1"
          (ngModelChange)="handleNAchange('cmd_0727')"
          class="texasFloat"
        />
      </td>
    </tr>
    <tr>
      <td colspan="8">
        <div
          class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
        >
          <label class="formLabel"
            >CURRENT PRACTICE/EMPLOYER NAME<span class="mandatory">*</span>
            <input
              class="form-control"
              id="445"
              name="445"
              formControlName="445"
              placeholder="Work History 1 Location "
              [class.is-invalid]="hap.controls['445'].invalid"
              type="text"
              data-id="wkhist1"
              (blur)="handleNAchange('cmd_0727', 445)"
            />
            <div class="mandatory"
                         *ngIf="hap.controls['445'].invalid">
                   <small >
                       required
                   </small>
                  </div>
          </label>
        </div>
      </td>
      <td colspan="10">
        <div
          class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
        >
          <label class="formLabel"
            >START DATE/END DATE (MM/DD/YYYY TO MM/DD/YYYY)<span class="mandatory">*</span>
          </label>
          <br>
          <div class="input-group">
            <input
              class="form-control onboard datepicker"
              [class.is-invalid]="hap.controls['454'].invalid"
              placeholder="Work History 1 Start Date"
              ngbDatepicker
              #d38="ngbDatepicker"
              id="454"
              name="454"
              formControlName="454"
              type="text"
              data-id="wk1histstartdt"
              (blur)="handleNAchange('cmd_0727', 454)"
            />
            <div class="input-group-append">
              <button
                class="btn btn-outline-secondary calendar"
                (click)="d38.toggle()"
                type="button"
              >
                <i class="fas fa-calendar-alt"></i>
              </button>
            </div>
          </div>
          <div class="mandatory"
                         *ngIf="hap.controls['454'].invalid">
                   <small >
                       required
                   </small>
                  </div>
          <div class="input-group">
            <input
              class="form-control onboard datepicker"
              [class.is-invalid]="hap.controls['455'].invalid"
              placeholder="Work History 1 End Date "
              ngbDatepicker
              #d39="ngbDatepicker"
              id="455"
              name="455"
              formControlName="455"
              type="text"
              data-id="wk1histenddt"
              (blur)="handleNAchange('cmd_0727', 455)"
            />
            <div class="input-group-append">
              <button
                class="btn btn-outline-secondary calendar"
                (click)="d39.toggle()"
                type="button"
              >
                <i class="fas fa-calendar-alt"></i>
              </button>
            </div>
          </div>
          <div class="mandatory"
                         *ngIf="hap.controls['455'].invalid">
                   <small >
                       required
                   </small>
                  </div>
        </div>
      </td>
    </tr>
    <tr>
      <td colspan="18">
        <div
          class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
        >
          <label class="formLabel">ADDRESS<span class="mandatory">*</span></label>
        
            <input
            style="width: 20%;margin-bottom: 2px;"
            [class.is-invalid]="hap.controls['446'].invalid"
            class="form-control"
            placeholder="Work History 1 Address1"
            id="446"
            name="446"
            formControlName="446"
            type="text"
            data-id="wkhist1add"
            (blur)="handleNAchange('cmd_0727', 446)"
          />
          <div class="mandatory"
                         *ngIf="hap.controls['446'].invalid">
                   <small >
                       required
                   </small>
                  </div>
                
          
          <input
            style="width: 20%;margin-bottom: 2px;margin-left:140px"
            [class.is-invalid]="hap.controls['447'].invalid"
            class="form-control"
            placeholder="Work History 1 Address2 "
            id="447"
            name="447"
            formControlName="447"
            type="text"
            data-id="wkhist1add2"
            (blur)="handleNAchange('cmd_0727', 447)"
          />
          <div class="mandatory"
                         *ngIf="hap.controls['447'].invalid">
                   <small >
                       required
                   </small>
                  </div>
        </div>
      </td>
    </tr>
    <tr>
      <td colspan="18">
        <div class="row">
          <div class="col-md-4">
            <label class="formLabel">CITY<span class="mandatory">*</span></label>
          <input
            style="width: 100%"
            placeholder="Work History 1 City"
            [class.is-invalid]="hap.controls['448'].invalid"
            class="form-control"
            id="448"
            name="448"
            formControlName="448"
            type="text"
            data-id="Work hist 1 city"
            (blur)="handleNAchange('cmd_0727', 448)"
          />
          <div class="mandatory"
                         *ngIf="hap.controls['448'].invalid">
                   <small >
                       required
                   </small>
                  </div>
          </div>
          <div class="form-group col-md-4 ">
            <label class="formLabel">STATE/COUNTRY<span class="mandatory">*</span></label>
        
            
              <ng-select
                [items]="states"
                [class.is-invalid]="hap.controls['450'].invalid"
                bindLabel="name"
                placeholder="State or Country"
                bindValue="id"
                formControlName="450"
                (blur)="handleNAchange('cmd_0727', 450)"
              >
              </ng-select>
            
              <div class="mandatory"
              *ngIf="hap.controls['450'].invalid">
        <small >
            required
        </small>
       </div>
          
          </div>
          <div class="form-group col-md-4">
            <label class="formLabel">POSTAL CODE<span class="mandatory">*</span></label>
            <input
              style="width: 100%"

              [class.is-invalid]="hap.controls['451'].invalid"
              class="form-control"
              (keypress)="numberOnly($event)"
              placeholder="Zip"
              id="451"
              name="451"
              formControlName="451"
              type="text"
              data-id="Work hist 1 zip"
              placeholder="Work History 1 Zip "
              (blur)="handleNAchange('cmd_0727', 451)"
            />
            <div class="mandatory"
            *ngIf="hap.controls['451'].invalid">
      <small >
          required
      </small>
     </div>
           
         <div

              class="formLabel col-md-2"
              *ngIf="
                hap.controls['451'].touched &&
                hap.controls['451'].invalid
              "
            > <small
            class="text-danger"
            *ngIf="hap.controls['451'].errors?.required"
          >
            required
          </small>
          </div>
        </div>
        </div>
      </td>

  </tr>
  <tr>
    <td colspan="8">
      <div
        class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
      >
        <label class="formLabel"
          ><b>PREVIOUS PRACTICE/EMPLOYER NAME</b>
          <input
            class="form-control"
            id="456"
            name="456"
            formControlName="456"
            type="text"
            data-id="wkhist2"
            placeholder="Work History 2 Location "
          />
        </label>
      </div>
    </td>
    <td colspan="10">
      <div
        class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
      >
        <label class="formLabel"
          >START DATE/END DATE (MM/DD/YYYY TO MM/DD/YYYY)
        </label>
        <div class="input-group">
          <input
            style="width:25%;"
            class="form-control onboard datepicker"
            placeholder="Work History 2 Start Date"
            ngbDatepicker
            #d40="ngbDatepicker"
            id="465"
            name="465"
            formControlName="465"
            type="text"
            data-id="wkhist2startdt"
          />
          <div class="input-group-append">
            <button
              class="btn btn-outline-secondary calendar"
              (click)="d40.toggle()"
              type="button"
            >
              <i class="fas fa-calendar-alt"></i>
            </button>
          </div>
        </div>
        <div  *ngIf="hap.get('465').invalid && hap.get('465').touched">
          <small class="text-danger" *ngIf="hap.get('465').errors.invalidDateFormat">
            Only Date Format is allowed
          </small>
        </div>
        <div class="input-group">
          <input
            class="form-control onboard datepicker"
            placeholder="Work History 2 End Date "
            ngbDatepicker
            #d41="ngbDatepicker"
            id="466"
            name="466"
            formControlName="466"
            type="text"
            data-id="wkhist2enddt"
          />
          <div class="input-group-append">
            <button
              class="btn btn-outline-secondary calendar"
              (click)="d41.toggle()"
              type="button"
            >
              <i class="fas fa-calendar-alt"></i>
            </button>
          </div>
        </div>
        <div  *ngIf="hap.get('466').invalid && hap.get('466').touched">
          <small class="text-danger" *ngIf="hap.get('466').errors.invalidDateFormat">
            Only Date Format is allowed
          </small>
        </div>
      </div>
      
    </td>
  </tr>
  <tr>
    <td colspan="18">
      <div
        class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
      >
        <label class="formLabel">ADDRESS</label>
        <input
          style="width: 20%;margin-bottom: 2px;"
          class="form-control"
          placeholder="Work History 2 Address1"
          id="457"
          name="457"
          formControlName="457"
          type="text"
          data-id="wkhist2add"
        />
        <input
        style="width: 20%;margin-bottom: 2px;margin-left:140px"
          class="form-control"
          placeholder="Work History 2 Address2"
          id="458"
          name="458"
          formControlName="458"
          type="text"
          data-id="wkhist2address2"
        />
      </div>
    </td>
  </tr>
  <tr>
    <td colspan="18">
      <div class="row">
        <div class="col-md-4">
          <label class="formLabel">CITY</label>
          <input
            style="width: 100%"

            class="form-control"
            id="459"
            name="459"
            formControlName="459"
            type="text"
            data-id="Work hist 2 city"
            placeholder="Work History 2  City "
          />
        </div>
        <div class="form-group col-md-4 ">
          <label class="formLabel">STATE/COUNTRY</label>
      
          
          <ng-select
          [items]="states"

          bindLabel="name"

          bindValue="id"
          formControlName="461"
          placeholder="State or Country"
        >
        </ng-select>
        </div>
        <div class="form-group col-md-4">
          <label class="formLabel">POSTAL CODE</label>
          <input
          style="width: 100%"
          class="form-control"
          (keypress)="numberOnly($event)"
          placeholder="Work History 2  Zip "
          id="462"
          
          name="462"
          formControlName="462"
          type="text"
          data-id="Work hist 2 zip"

        />
        <div *ngIf="hap.get('462').invalid && hap.get('462').touched">
          <small class="text-danger" *ngIf="hap.get('462').errors.invalidZipCode">
            Zip code must be atleast 5 digits and less than 11 digits.
          </small>
        </div>
          
      </div>
      </div>
    </td>
    
</tr>
<tr>
  <td colspan="18">
    <div
      class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
    >
      <label class="formLabel">REASON FOR DISCONTINUANCE </label>
      <input
        class="form-control wa"
        id="1338"
        name="1338"
        formControlName="1338"
        type="text"
        data-id="wkhist2reasonforleaving"
        placeholder="Work History 2 Reason for Leaving"
      />
    </div>
  </td>
</tr>
<tr>
  <td colspan="8">
    <div
      class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
    >
      <label class="formLabel"
        ><b>PREVIOUS PRACTICE/EMPLOYER NAME</b>
        <input
          class="form-control"
          id="467"
          name="467"
          formControlName="467"
          type="text"
          data-id="wkhist3"
          placeholder="Work History 3 Location "
          
        />
      </label>
    </div>
  </td>
  <td colspan="10">
    <div
      class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
    >
      <label class="formLabel"
        >START DATE/END DATE (MM/DD/YYYY TO MM/DD/YYYY)
      </label>
      <div class="input-group">
        <input
          style="width:25%;"
          class="form-control onboard datepicker"
          placeholder="Work History 3 Start Date"
          ngbDatepicker
          #d42="ngbDatepicker"
          id="476"
          name="476"
          formControlName="476"
          type="text"
          data-id="wkhist3startdt"
        />
        
        <div class="input-group-append">
          <button
            class="btn btn-outline-secondary calendar"
            (click)="d42.toggle()"
            type="button"
          >
            <i class="fas fa-calendar-alt"></i>
          </button>
        </div>
      </div>
      <div  *ngIf="hap.get('476').invalid && hap.get('476').touched">
        <small class="text-danger" *ngIf="hap.get('476').errors.invalidDateFormat">
          Only Date Format is allowed
        </small>
      </div>
      <div class="input-group">
        <input
          class="form-control onboard datepicker"
          placeholder="Work History 3 End Date "
          ngbDatepicker
          #d43="ngbDatepicker"
          id="477"
          name="477"
          formControlName="477"
          type="text"
          data-id="wkhist3enddt"
        />
        <div class="input-group-append">
          <button
            class="btn btn-outline-secondary calendar"
            (click)="d43.toggle()"
            type="button"
          >
            <i class="fas fa-calendar-alt"></i>
          </button>
        </div>
      </div>
      <div  *ngIf="hap.get('477').invalid && hap.get('477').touched">
        <small class="text-danger" *ngIf="hap.get('477').errors.invalidDateFormat">
          Only Date Format is allowed
        </small>
      </div>
      
    </div>
    

  </td>
</tr>
<tr>
  <td colspan="18">
    <div
      class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
    >
      <label class="formLabel">ADDRESS</label>
      <input
        style="width: 20%;margin-bottom: 2px;"
        class="form-control"
        placeholder="Work History 3 Address1"
        id="468"
        name="468"
        formControlName="468"
        type="text"
        data-id="wkhist3add"
      />
      <input
        style="width: 20%;margin-bottom: 2px;margin-left:140px"
        class="form-control"
        placeholder="Work History 3 Address2"
        id="469"
        name="469"
        formControlName="469"
        type="text"
        data-id="wkhist3address2"
      />
    </div>
  </td>
</tr>
<tr>
  <td colspan="18">
    <div class="row">
      <div class="col-md-4">
        <label class="formLabel">CITY</label>
        <input
        style="width: 100%"
        placeholder="Work History 3 City"
        class="form-control"
        id="470"
        name="470"
        formControlName="470"
        type="text"
        data-id="Work hist 3 city"
      />
      </div>
      <div class="form-group col-md-4 ">
        <label class="formLabel">STATE/COUNTRY</label>
    
        
        <ng-select
        [items]="states"

        bindLabel="name"
        placeholder="State or Country "
        bindValue="id"
        formControlName="472"
      >
      </ng-select>
      </div>
      <div class="form-group col-md-4">
        <label class="formLabel">POSTAL CODE</label>
        <input
        style="width: 100%"
        class="form-control"
        (keypress)="numberOnly($event)"
        id="473"
        name="473"
        formControlName="473"
        type="text"
        data-id="Work hist 3 zip"
        placeholder="Work History 3 Zip "
      />
      <div *ngIf="hap.get('473').invalid && hap.get('473').touched">
        <small class="text-danger" *ngIf="hap.get('473').errors.invalidZipCode">
          Zip code must be atleast 5 digits and less than 11 digits.
        </small>
      </div>
        
    </div>
    </div>
  </td>
  
</tr>
<tr>
<td colspan="18">
  <div
    class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
  >
    <label class="formLabel">REASON FOR DISCONTINUANCE </label>
    <input
      class="form-control wa"
      id="1342"
      name="1342"
      formControlName="1342"
      type="text"
      data-id="wkhist3reasonforleaving"
      placeholder="Work History 3  Reason for Leaving"
    />
  </div>
</td>
</tr>
<tr>
<td colspan="8">
  <div
    class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
  >
    <label class="formLabel"
      ><b>PREVIOUS PRACTICE/EMPLOYER NAME</b>
      <input
        class="form-control"
        id="1935"
        name="1935"
        formControlName="1935"
        type="text"
        data-id="wkhist4"
        placeholder="Work History 4 Location "
      />
    </label>
  </div>
</td>
<td colspan="10">
  <div
    class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
  >
    <label class="formLabel"
      >START DATE/END DATE (MM/DD/YYYY TO MM/DD/YYYY)
    </label>
    <div class="input-group">
      <input
        style="width:25%;"
        class="form-control onboard datepicker"
        placeholder="Work History 4 Start Date"
        ngbDatepicker
        #d44="ngbDatepicker"
        id="1947"
        name="1947"
        formControlName="1947"
        type="text"
        data-id="wkhist4startdt"
      />
      <div class="input-group-append">
        <button
          class="btn btn-outline-secondary calendar"
          (click)="d44.toggle()"
          type="button"
        >
          <i class="fas fa-calendar-alt"></i>
        </button>
      </div>
    </div>
    <div  *ngIf="hap.get('1947').invalid && hap.get('1947').touched">
      <small class="text-danger" *ngIf="hap.get('1947').errors.invalidDateFormat">
        Only Date Format is allowed
      </small>
    </div>
    <div class="input-group">
      <input
        class="form-control onboard datepicker"
        placeholder="Work History 4 End Date "
        ngbDatepicker
        #d45="ngbDatepicker"
        id="1948"
        name="1948"
        formControlName="1948"
        type="text"
        data-id="wkhist4enddt"
      />
      <div class="input-group-append">
        <button
          class="btn btn-outline-secondary calendar"
          (click)="d45.toggle()"
          type="button"
        >
          <i class="fas fa-calendar-alt"></i>
        </button>
      </div>
    </div>
    <div  *ngIf="hap.get('1948').invalid && hap.get('1948').touched">
      <small class="text-danger" *ngIf="hap.get('1948').errors.invalidDateFormat">
        Only Date Format is allowed
      </small>
    </div>
  </div>
  
</td>
</tr>
<tr>
<td colspan="18">
  <div
    class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
  >
    <label class="formLabel">ADDRESS</label>
    <input
      style="width: 20%;margin-bottom: 2px;"
      class="form-control"
      placeholder="Work History 4 Address1 "
      id="1936"
      name="1936"
      formControlName="1936"
      type="text"
      data-id="wkhist4add"
    />
    <input
      style="width: 20%;margin-bottom: 2px;margin-left:140px"
      class="form-control"
      placeholder="Work History 4 Address 2 "
      id="1937"
      name="1937"
      formControlName="1937"
      type="text"
      data-id="wkhist4address2"
    />
  </div>
</td>
</tr>
<tr>
<td colspan="18">
  <div class="row">
    <div class="col-md-4">
      <label class="formLabel">CITY</label>
      <input
      style="width: 100%"
      placeholder="Work History 4 City"
      class="form-control"
      id="1938"
      name="1938"
      formControlName="1938"
      type="text"
      data-id="Work hist 4 city"
    />
    </div>
    <div class="form-group col-md-4 ">
      <label class="formLabel">STATE/COUNTRY</label>
  
      
      <ng-select
      [items]="states"
      bindLabel="name"
      placeholder="State or Country"
      bindValue="id"
      formControlName="1940"
    >
    </ng-select>
    </div>
    <div class="form-group col-md-4">
      <label class="formLabel">POSTAL CODE</label>
      <input
      style="width: 100%"
      class="form-control"
      placeholder="Work History 4 Zip "
      id="1941"
      name="1941"
      formControlName="1941"
      type="text"
      data-id="Work hist 4 zip"
      (keypress)="numberOnly($event)"
    />
    <div *ngIf="hap.get('1941').invalid && hap.get('1941').touched">
      <small class="text-danger" *ngIf="hap.get('1941').errors.invalidZipCode">
        Zip code must be atleast 5 digits and less than 11 digits.
      </small>
    </div>
      
  </div>
  </div>
</td>

</tr>
<tr>
<td colspan="18">
<div
  class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
>
  <label class="formLabel">REASON FOR DISCONTINUANCE </label>
  <input
    class="form-control wa"
    id="1952"
    name="1952"
    formControlName="1952"
    type="text"
    data-id="wkhist4reasonforleaving"
    placeholder="Work History 4 Reason for Leaving"
  />
</div>
</td>
</tr>
<tr>
<td colspan="18" width="60%">
<div
  class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
>
  <label class="formLabel">
    PLEASE PROVIDE AN EXPLANATION FOR ANY GAPS GREATER THAN SIX
    MONTHS (MM/DD/YYYY TO MM/DD/YYYY) IN WORK HISTORY.
  </label>
</div>
<div
  class="form-group "
>
<div class="row">
  <div class="col-md-4 form-group form-inline no-margin ">
    <label class="formLabel">Gap Dates: </label>
    <div class="input-group">
      <input
        style="width: 40%"
        class="form-control onboard datepicker"
        placeholder="Gap 1 Start"
        ngbDatepicker
        #d46="ngbDatepicker"
        id="3395"
        name="3395"
        formControlName="3395"
        type="text"
        data-id="Gap from 1"
      />
      <div class="input-group-append">
        <button
          class="btn btn-outline-secondary calendar"
          (click)="d46.toggle()"
          type="button"
        >
          <i class="fas fa-calendar-alt"></i>
        </button>
      </div>
    </div>
    <div  *ngIf="hap.get('3395').invalid && hap.get('3395').touched">
      <small class="text-danger" *ngIf="hap.get('3395').errors.invalidDateFormat">
        Only Date Format is allowed
      </small>
    </div>
  </div>
  
  <div class="col-md-4 form-group form-inline no-margin ">
    <div class="input-group">
    <input
      style="width: 53%"
      class="form-control onboard datepicker"
      placeholder="Gap 1 End"
      ngbDatepicker
      #d47="ngbDatepicker"
      id="3396"
      name="3396"
      formControlName="3396"
      type="text"
      data-id="Gap to 1"
    />
    <div class="input-group-append">
      <button
        class="btn btn-outline-secondary calendar"
        (click)="d47.toggle()"
        type="button"
      >
        <i class="fas fa-calendar-alt"></i>
      </button>
    </div>
    </div>
    <div  *ngIf="hap.get('3396').invalid && hap.get('3396').touched">
      <small class="text-danger" *ngIf="hap.get('3396').errors.invalidDateFormat">
        Only Date Format is allowed
      </small>
    </div>
    
  </div>  
  
<div class="col-md-4 form-group form-inline no-margin">  
  <label class="formLabel">Explanation: </label>
  <input class="input-group"
    style="width: 50%"
    class="form-control"
    id="1138"
    name="1138"
    formControlName="1138"
    type="text"
    data-id="Gap 1"
    placeholder="Gap 1 Reason"
  />
</div>
</div>
</div>
<div
class="form-group "
>
<div class="row">
<div class="col-md-4 form-group form-inline no-margin ">
<label class="formLabel">Gap Dates: </label>
<div class="input-group">
  <input
  style="width: 40%"
  class="form-control onboard datepicker"
  placeholder="Gap 2 Start"
  ngbDatepicker
  #d48="ngbDatepicker"
  id="3397"
  name="3397"
  formControlName="3397"
  type="text"
  data-id="Gap from 2"
  />
  <div class="input-group-append">
    <button
      class="btn btn-outline-secondary calendar"
      (click)="d48.toggle()"
      type="button"
    >
      <i class="fas fa-calendar-alt"></i>
    </button>
  </div>
</div>
<div  *ngIf="hap.get('3397').invalid && hap.get('3397').touched">
  <small class="text-danger" *ngIf="hap.get('3397').errors.invalidDateFormat">
    Only Date Format is allowed
  </small>
</div>
</div>
<div class="col-md-4 form-group form-inline no-margin ">
<div class="input-group">
<input
style="width: 53%"
class="form-control onboard datepicker"
placeholder="Gap 2 End"
ngbDatepicker
#d49="ngbDatepicker"
id="3398"
name="3398"
formControlName="3398"
type="text"
data-id="Gap to 2"
/>
<div class="input-group-append">
  <button
    class="btn btn-outline-secondary calendar"
    (click)="d49.toggle()"
    type="button"
  >
    <i class="fas fa-calendar-alt"></i>
  </button>
</div>
</div>
<div  *ngIf="hap.get('3398').invalid && hap.get('3398').touched">
  <small class="text-danger" *ngIf="hap.get('3398').errors.invalidDateFormat">
    Only Date Format is allowed
  </small>
</div>
</div>  
<div class="col-md-4 form-group form-inline no-margin">  
<label class="formLabel">Explanation: </label>
<input class="input-group"
style="width: 50%"
class="form-control"
id="1831"
name="1831"
formControlName="1831"
type="text"
data-id="Gap 2"
placeholder="Gap 2 Reason"
/>
</div>
</div>
</div>
<div
class="form-group "
>
<div class="row">
<div class="col-md-4 form-group form-inline no-margin ">
<label class="formLabel">Gap Dates: </label>
<div class="input-group">
<input
style="width: 40%"
class="form-control onboard datepicker"
placeholder="Gap 3 Start"
ngbDatepicker
#d50="ngbDatepicker"
id="3399"
name="3399"
formControlName="3399"
type="text"
data-id="Gap from 3.0"
/>
<div class="input-group-append">
<button
  class="btn btn-outline-secondary calendar"
  (click)="d50.toggle()"
  type="button"
>
  <i class="fas fa-calendar-alt"></i>
</button>
</div>
</div>
<div  *ngIf="hap.get('3399').invalid && hap.get('3399').touched">
<small class="text-danger" *ngIf="hap.get('3399').errors.invalidDateFormat">
Only Date Format is allowed
</small>
</div>
</div>
<div class="col-md-4 form-group form-inline no-margin ">
<div class="input-group">
<input
style="width: 53%"
class="form-control onboard datepicker"
placeholder="Gap 3 End"
ngbDatepicker
#d51="ngbDatepicker"
id="3400"
name="3400"
formControlName="3400"
type="text"
data-id="Gap to 3.0"
/>
<div class="input-group-append">
<button
class="btn btn-outline-secondary calendar"
(click)="d51.toggle()"
type="button"
>
<i class="fas fa-calendar-alt"></i>
</button>
</div>
</div>
<div  *ngIf="hap.get('3400').invalid && hap.get('3400').touched">
<small class="text-danger" *ngIf="hap.get('3400').errors.invalidDateFormat">
Only Date Format is allowed
</small>
</div>
</div>  
<div class="col-md-4 form-group form-inline no-margin">  
<label class="formLabel">Explanation: </label>
<input class="input-group"
style="width: 50%"
class="form-control"
id="1832"
name="1832"
formControlName="1832"
type="text"
data-id="Gap 3.0"
placeholder="Gap 3 Reason"
/>
</div>
</div>
</div>
<div
class="form-group "
>
<div class="row">
<div class="col-md-4 form-group form-inline no-margin ">
<label class="formLabel">Gap Dates: </label>
<div class="input-group">
<input
style="width: 40%"
class="form-control onboard datepicker"
placeholder="Gap 4 Start"
ngbDatepicker
#d52="ngbDatepicker"
id="3401"
name="3401"
formControlName="3401"
type="text"
data-id="Gap from 3.1"
/>
<div class="input-group-append">
<button
  class="btn btn-outline-secondary calendar"
  (click)="d52.toggle()"
  type="button"
>
  <i class="fas fa-calendar-alt"></i>
</button>
</div>
</div>
<div  *ngIf="hap.get('3401').invalid && hap.get('3401').touched">
<small class="text-danger" *ngIf="hap.get('3401').errors.invalidDateFormat">
Only Date Format is allowed
</small>
</div>
</div>
<div class="col-md-4 form-group form-inline no-margin ">
<div class="input-group">
<input
style="width: 53%"
class="form-control onboard datepicker"
placeholder="Gap 4 End"
ngbDatepicker
#d53="ngbDatepicker"
id="3402"
name="3402"
formControlName="3402"
type="text"
data-id="Gap to 3.1"
/>
<div class="input-group-append">
<button
class="btn btn-outline-secondary calendar"
(click)="d53.toggle()"
type="button"
>
<i class="fas fa-calendar-alt"></i>
</button>
</div>
</div>
<div  *ngIf="hap.get('3402').invalid && hap.get('3402').touched">
<small class="text-danger" *ngIf="hap.get('3402').errors.invalidDateFormat">
Only Date Format is allowed
</small>
</div>
</div>  
<div class="col-md-4 form-group form-inline no-margin">  
<label class="formLabel">Explanation: </label>
<input class="input-group"
style="width: 50%"
class="form-control"
id="1953"
name="1953"
formControlName="1953"
type="text"
data-id="Gap 3.1"
placeholder="Gap 4 Reason"
/>
</div>
</div>
</div>

</td>
</tr>  
</table>
<hr class="tablePersonal" />
<table border="1" width="100%">
<tr>
<td colspan="18">
  <div
    class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
  >
    <label class="formLabel"><b>Hospital Affiliations</b></label
    >-Please include all hospitals where you currently have or have
    previously had privileges.
  </div>
</td>

</tr>   
<tr>
<td colspan="9">
  <div class="form-inline no-margin col-md-12 sideLabelAlignment">
    <label class="formLabel"
      >DO YOU HAVE HOSPITAL PRIVILEGES?
    </label>
    <label class="checkbox-inline radioStyle">
      <input
        type="radio"
        id="cmd_059"
        name="cmd_059"
        formControlName="cmd_059"
        value="1"
      />Yes</label
    >
    <label class="checkbox-inline radioStyle">
      <input
        type="radio"
        id="cmd_059"
        name="cmd_059"
        formControlName="cmd_059"
        value="0"
      />No</label
    >
  </div>
</td>
<td colspan="9">
  <div class="">
    <label class="formLabel"
      >IF YOU DO NOT HAVE ADMITTING PRIVILEGES, WHAT ADMITTING
      ARRANGEMENTS DO YOU HAVE?
    </label>
    <input
      class="form-control"
      id="cmd_060"
      name="cmd_060"
      formControlName="cmd_060"
      type="text"
      data-id="hospaff1arrangements"
      placeholder="Hospital Affiliation 1 admitting arrangments"
    />
  </div>
</td>
</tr>  
<tr>
<td colspan="9">
  <div class="form-inline" >
    <label class="formLabel"
      >PRIMARY HOSPITAL WHERE YOU HAVE ADMITTING PRIVILEGES</label
    >
    <input
      class="form-control"
      
      id="418"
      name="418"
      formControlName="418"
      type="text"
      data-id="hospaff1nm"
      placeholder="Hospital Affiliation 1 Name "
    />
    
  </div>
</td>
<td colspan="9">
  <div class="form-inline">
    <label class="formLabel">START DATE (MM/DD/YYYY) </label>
    <div class="input-group">
      <input
        class="form-control onboard datepicker"

        placeholder="Hospital Affiliation 1 Initial Appointment "
        class="wa"
        ngbDatepicker
        #d54="ngbDatepicker"
        id="426"
        name="426"
        formControlName="426"
        type="text"
        data-id="hospaff1app"
        style="margin-left:4px"
      />
      <div class="input-group-append">
        <button
          class="btn btn-outline-secondary calendar"
          (click)="d54.toggle()"
          type="button"
        >
          <i class="fas fa-calendar-alt"></i>
        </button>
      </div>
    </div>
    <div  *ngIf="hap.get('426').invalid && hap.get('426').touched">
      <small class="text-danger" *ngIf="hap.get('426').errors.invalidDateFormat">
        Only Date Format is allowed
      </small>
    </div>
    
  </div>
  <br>
  <div class="form-inline">
    <label class="formLabel">END DATE (MM/DD/YYYY) </label>
    <div class="input-group">
      <input
        class="form-control onboard datepicker"

        placeholder="Hospital Affiliation 1 Next Appointment Date"
        class="wa"
        ngbDatepicker
        #d5412="ngbDatepicker"
        id="2007"
        name="2007"
        formControlName="2007"
        type="text"
        data-id="hospaff1appNext"
        style="margin-left:15px"
      />
      <div class="input-group-append">
        <button
          class="btn btn-outline-secondary calendar"
          (click)="d5412.toggle()"
          type="button"
        >
          <i class="fas fa-calendar-alt"></i>
        </button>
      </div>
    </div>
    <div  *ngIf="hap.get('2007').invalid && hap.get('2007').touched">
      <small class="text-danger" *ngIf="hap.get('2007').errors.invalidDateFormat">
        Only Date Format is allowed
      </small>
    </div>
    
  </div>
</td>
</tr>  







<tr>
  <td colspan="18">
    <div
      class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
    >
      <label class="checkbox-inline radioStyle">ADDRESS</label>
      <input
        class="form-control"

        id="419"
        name="419"
        formControlName="419"
        type="text"
        data-id="hospaff1add1"
        placeholder="Hospital Affiliation 1 address 1"
        style="width: 20%;margin-bottom: 2px;"
      />
      
      <input
        class="form-control"
        
        id="420"
        name="420"
        formControlName="420"
        type="text"
        data-id="hospaff1add2"
        placeholder="Hospital Affiliation 1 address 2"
        style="width: 20%;margin-bottom: 2px;margin-left:140px"
      />
      
    </div>
  </td>
</tr>
<tr>
  <td colspan="18">
  <div class="row">
      <div class="col-md-4">
        <label class="formLabel">CITY</label>
        <input
        style="width: 100%"

        class="form-control"
        placeholder="City"
        id="421"
        name="421"
        formControlName="421"
        type="text"
        data-id="hospaff1city"
        placeholder="Hospital Affiliation 1 City"
      />
      
      </div>
      <div class="form-group col-md-4 ">
        <label class="formLabel">STATE/COUNTRY</label>
    
        <ng-select
                [items]="states"

                bindLabel="name"
                placeholder="Hospital Affiliation 1 state"
                bindValue="id"
                formControlName="423"
              >
              </ng-select>
            
       </div>
            
      
      <div class="form-group col-md-4">
        <label class="formLabel">POSTAL CODE</label>
        <input
        style="width: 100%"
        minlength="5"
        maxlength="10"
        
        class="form-control"
        placeholder="Hospital Affiliation 1 zip"
        id="424"
        (keypress)="numberOnly($event)"
        name="424"
        formControlName="424"
        type="text"
        data-id="hospaff1zip"
      />
      <div *ngIf="hap.get('424').invalid && hap.get('424').touched">
        <small class="text-danger" *ngIf="hap.get('424').errors.invalidZipCode">
          Zip code must be atleast 5 digits and less than 11 digits.
        </small>
      </div>
      
        
    </div>
  </div>
  </td>
  
</tr>
<tr>
  <td colspan="6">

      <label class="formLabel">PHONE NUMBER </label>
      <input
        class="form-control wa"
        (keypress)="numberOnly($event)"

        id="1305"
        name="1305"
        formControlName="1305"
        type="text"
        data-id="hospaff1phone"
        placeholder="Hospital Affiliation 1 phone"
      />
      
    
  </td>
  <td colspan="6">
    <div
      class="form-group form-inline no-margin col-md-3 sideLabelAlignment"
    >
      <label class="formLabel">FAX</label>
      <input
        class="form-control"
        id="2136"
        name="2136"
        (keypress)="numberOnly($event)"
        formControlName="2136"
        type="text"
        data-id="hospaff1fax"
        placeholder="Hospital Affiliation 1 fax"
      />
    </div>
  </td>
  <td colspan="6">
    <div
      class="form-group  sideLabelAlignment"
    >
      <label class="formLabel">E-MAIL </label>
      <input
        class="form-control col-md-7"
        id="2142"
        name="2142"
        formControlName="2142"
        type="text"
        data-id="hospaff1email"
        placeholder="Hospital Affiliation 1 email"
      />
      
    </div>
    <small class="text-danger" *ngIf="hap.controls['2142'].errors?.pattern">
      Only E-Mail Format is allowed</small>
  </td>
</tr>
<tr>
  <td colspan="6">
    <div class="form-inline no-margin col-md-12 sideLabelAlignment">
      <label class="formLabel">FULL UNRESTRICTED PRIVILEGES? </label>
      <label class="checkbox-inline radioStyle">
        <input
          type="radio"
          id="cmd_061"
          name="cmd_061"
          formControlName="cmd_061"
          value="1"
        />Yes</label
      >
      <label class="checkbox-inline radioStyle">
        <input
          type="radio"
          id="cmd_061"
          name="cmd_061"
          formControlName="cmd_061"
          value="0"
        />No</label
      >
    </div>
  </td>
  <td colspan="6">
    
      <label class="formLabel"
        >TYPES OF PRIVILEGES (PROVISIONAL, LIMITED, CONDITIONAL, ETC.)
      </label>
      
      
          <ng-select
            [items]="staffStatus"
            bindLabel="name"
            placeholder="Hospital Affiliation 1 Status "
            bindValue="id"
            formControlName="894"
          >
          </ng-select>
      
      
  
  </td>
  <td colspan="6">
    <div class="form-inline no-margin col-md-12 sideLabelAlignment">
      <label class="formLabel">ARE PRIVILEGES TEMPORARY? </label>
      <label class="checkbox-inline radioStyle">
        <input
          type="radio"
          id="cmd_062"
          name="cmd_062"
          formControlName="cmd_062"
          value="1"
        />Yes</label
      >
      <label class="checkbox-inline radioStyle">
        <input
          type="radio"
          id="cmd_062"
          name="cmd_062"
          formControlName="cmd_062"
          value="0"
        />No</label
      >
    </div>
  </td>
</tr>
<tr>
  <td colspan="18">
    <div
      class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
    >
      <label class="checkbox-inline radioStyle"
        >OF THE TOTAL NUMBER OF ADMISSIONS TO ALL HOSPITALS IN THE
        PAST YEAR, WHAT PERCENTAGE IS TO PRIMARY HOSPITAL?
      </label>
      <input
        class="form-control"
        id="cmd_063"
        name="cmd_063"
        formControlName="cmd_063"
        type="text"
        data-id="hospaff1adimissons"
      />
    </div>
  </td>
</tr>
<tr>
  <td colspan="9">
    <div
      class="form-group form-inline no-margin col-md-6 sideLabelAlignment"
    >
      <label class="formLabel"
        >OTHER HOSPITAL WHERE YOU HAVE PRIVILEGES
      </label>
      <input
        class="form-control"
        id="427"
        name="427"
        formControlName="427"
        type="text"
        data-id="hospaff2nm"
        placeholder="Hospital Affiliation 2 Name "
      />
    </div>
  </td>
  <td colspan="9">
    <!-- <div
      class="form-group form-inline no-margin col-md-3 sideLabelAlignment"
    >
      <label class="formLabel">START DATE (MM/DD/YYYY) </label>
      <div class="input-group">
        <input
          class="form-control onboard datepicker"

          ngbDatepicker
          #d55="ngbDatepicker"
          id="435"
          name="435"
          formControlName="435"
          type="text"
          data-id="hospaff2app"
          placeholder="Hospital Affiliation 2 Initial Appointment "
        />
        <div class="input-group-append">
          <button
            class="btn btn-outline-secondary calendar"
            (click)="d55.toggle()"
            type="button"
          >
            <i class="fas fa-calendar-alt"></i>
          </button>
        </div>
      </div>
    </div> -->
    <div class="form-inline">
      <label class="formLabel">START DATE (MM/DD/YYYY) </label>
      <div class="input-group">
        <input
          class="form-control onboard datepicker"

          placeholder="Hospital Affiliation 2 Initial Appointment "
          class="wa"
          ngbDatepicker
          #d55="ngbDatepicker"
          id="435"
          name="435"
          formControlName="435"
          type="text"
          data-id="hospaff2app"
          style="margin-left:4px"
        />
        <div class="input-group-append">
          <button
            class="btn btn-outline-secondary calendar"
            (click)="d55.toggle()"
            type="button"
          >
            <i class="fas fa-calendar-alt"></i>
          </button>
        </div>
      </div>
      <div  *ngIf="hap.get('435').invalid && hap.get('435').touched">
        <small class="text-danger" *ngIf="hap.get('435').errors.invalidDateFormat">
          Only Date Format is allowed
        </small>
      </div>
      
    </div>
    <br>
    <div class="form-inline">
      <label class="formLabel">END DATE (MM/DD/YYYY) </label>
      <div class="input-group">
        <input
          class="form-control onboard datepicker"

          placeholder="Hospital Affiliation 2 Next Appointment Date"
          class="wa"
          ngbDatepicker
          #d542008="ngbDatepicker"
          id="2008"
          name="2008"
          formControlName="2008"
          type="text"
          data-id="hospaff2appNext"
          style="margin-left:15px"
        />
        <div class="input-group-append">
          <button
            class="btn btn-outline-secondary calendar"
            (click)="d542008.toggle()"
            type="button"
          >
            <i class="fas fa-calendar-alt"></i>
          </button>
        </div>
      </div>
      <div  *ngIf="hap.get('2008').invalid && hap.get('2008').touched">
        <small class="text-danger" *ngIf="hap.get('2008').errors.invalidDateFormat">
          Only Date Format is allowed
        </small>
      </div>
      
    </div>
  </td>
</tr>
<tr>
  <td colspan="18">
    <div
      class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
    >
      <label class="checkbox-inline radioStyle">ADDRESS</label>
      <input
        class="form-control"
        id="428"
        name="428"
        formControlName="428"
        type="text"
        data-id="hospaff2add.0"
        placeholder="Hospital Affiliation 2 address 1"
        style="width: 20%;margin-bottom: 2px;"
      />
      <input
        class="form-control"
        id="429"
        name="429"
        formControlName="429"
        type="text"
        data-id="hospaff2add.1"
        placeholder="Hospital Affiliation 2 address 2"
        style="width: 20%;margin-bottom: 2px;margin-left:140px"
      />
    </div>
  </td>
</tr>
<tr>
  <td colspan="18">
    <div class="row">
      <div class="col-md-4">
        <label class="formLabel">CITY</label>
        <input
        style="width: 100%"
        class="form-control"
        id="430"
        name="430"
        formControlName="430"
        type="text"
        placeholder="Hospital Affiliation 2 City"
        data-id="hospaff2city"
      />
      </div>
      <div class="col-md-4 ">
        <label class="formLabel">STATE/COUNTRY</label>
    
        
          <ng-select
                [items]="states"

                bindLabel="name"
                placeholder="Hospital Affiliation 2"
                bindValue="id"
                formControlName="432"
              >
              </ng-select>
            </div>
            
      
      <div class="col-md-4">
        <label class="formLabel">POSTAL CODE</label>
        <input
        style="width: 100%"
        class="form-control"
        id="433"
        (keypress)="numberOnly($event)"
        name="433"
        formControlName="433"
        type="text"
        placeholder="Hospital Affiliation 2 zip"
        data-id="hospaff2zip"
      />
      <div *ngIf="hap.get('433').invalid && hap.get('433').touched">
        <small class="text-danger" *ngIf="hap.get('433').errors.invalidZipCode">
          Zip code must be atleast 5 digits and less than 11 digits.
        </small>
      </div>
        
    </div>
  </div>

  </td>
  
</tr>
<tr>
  <td colspan="6">
    
      <label class="formLabel">PHONE NUMBER </label>
      <input
        class="form-control col-md-7"
        (keypress)="numberOnly($event)"
        id="1307"
        name="1307"
        formControlName="1307"
        type="text"
        data-id="hospaff2phone"
        placeholder="Hospital Affiliation 2 phone"
      />
    
  </td>
  <td colspan="6">
    <div
      class="form-group form-inline no-margin col-md-3 sideLabelAlignment"
    >
      <label class="formLabel">FAX</label>
      <input
        class="form-control"
        id="2137"
        name="2137"
        formControlName="2137"
        (keypress)="numberOnly($event)"
        type="text"
        data-id="hospaff2fax"
        placeholder="Hospital Affiliation 2 fax"
      />
    </div>
  </td>
  <td colspan="6">
    <div
      class="form-group  no-margin sideLabelAlignment"
    >
      <label class="formLabel">E-MAIL</label>
      <input
        class="form-control col-md-7"
        id="2143"
        name="2143"
        formControlName="2143"
        type="text"
        data-id="hospaff2email"
        placeholder="Hospital Affiliation 2 email"
      />
    </div>
    <small class="text-danger" *ngIf="hap.controls['2143'].errors?.pattern">
      Only E-Mail Format is allowed</small>
  </td>
</tr>
<tr>
  <td colspan="6">
    <div class="form-inline no-margin col-md-12 sideLabelAlignment">
      <label class="formLabel">FULL UNRESTRICTED PRIVILEGES? </label>
      <label class="checkbox-inline radioStyle">
        <input
          type="radio"
          id="cmd_064"
          name="cmd_064"
          formControlName="cmd_064"
          value="1"
        />Yes</label
      >
      <label class="checkbox-inline radioStyle">
        <input
          type="radio"
          id="cmd_064"
          name="cmd_064"
          formControlName="cmd_064"
          value="0"
        />No</label
      >
    </div>
  </td>
  <td colspan="6">
    
      <label class="formLabel"
        >TYPES OF PRIVILEGES (PROVISIONAL, LIMITED, CONDITIONAL, ETC.)
      </label>
        <ng-select
          [items]="staffStatus"
          bindLabel="name"
          placeholder="Hospital Affiliation 2 Status "
          bindValue="id"
          formControlName="895"
        >
        </ng-select>
    
    
  </td>
  <td colspan="6">
    <div class="form-inline no-margin col-md-12 sideLabelAlignment">
      <label class="formLabel">ARE PRIVILEGES TEMPORARY? </label>
      <label class="checkbox-inline radioStyle">
        <input
          type="radio"
          id="cmd_065"
          name="cmd_065"
          formControlName="cmd_065"
          value="1"
        />Yes</label
      >
      <label class="checkbox-inline radioStyle">
        <input
          type="radio"
          id="cmd_065"
          name="cmd_065"
          formControlName="cmd_065"
          value="0"
        />No</label
      >
    </div>
  </td>
</tr>
<tr>
  <td colspan="18">
    <div
      class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
    >
      <label class="checkbox-inline radioStyle"
        >OF THE TOTAL NUMBER OF ADMISSIONS TO ALL HOSPITALS IN THE
        PAST YEAR, WHAT PERCENTAGE IS TO PRIMARY HOSPITAL?
      </label>
      <input
        class="form-control"
        id="cmd_066"
        name="cmd_066"
        formControlName="cmd_066"
        type="text"
        data-id="hospaff2admissons"
      />
    </div>
  </td>
</tr>
<tr>
  <td colspan="10">
    <div
      class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
    >
      <label class="formLabel"
        >PREVIOUS HOSPITAL WHERE YOU HAVE HAD PRIVILEGES</label
      >
      <input
        class="form-control wa"
        id="1183"
        name="1183"
        formControlName="1183"
        type="text"
        data-id="Pre aff 1"
        placeholder="Previous Hospital Affiliation 1 Name "
        
      />
    </div>
  </td>
  <td colspan="8">
    <div
      class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
    >
      <label class="formLabel"
        >AFFILIATION DATES (MM/DD/YYYY TO MM/DD/YYYY)
      </label>
      <div class="input-group">
        <input

          class="form-control  onboard datepicker"
          placeholder="Previous Hospital Affiliation 1 inital Appointment "
          ngbDatepicker
          #d56="ngbDatepicker"
          id="1191"
          name="1191"
          formControlName="1191"
          type="text"
          data-id="Pre aff 1 from"
          style="width:310px"
        />
        <div class="input-group-append">
          <button
            class="btn btn-outline-secondary calendar"
            (click)="d56.toggle()"
            type="button"
          >
            <i class="fas fa-calendar-alt"></i>
          </button>
        </div>
      </div>
      <div  *ngIf="hap.get('1191').invalid && hap.get('1191').touched">
        <small class="text-danger" *ngIf="hap.get('1191').errors.invalidDateFormat">
          Only Date Format is allowed
        </small>
      </div>
      <br>
      <br>
      <br>
      <br>
      <div class="input-group">
        <input
          class="form-control onboard datepicker"
          placeholder="Previous Hospital  Affiliation 1 End Date "
          ngbDatepicker
          #d57="ngbDatepicker"
          id="2381"
          name="2381"
          formControlName="2381"
          type="text"
          data-id="Pre aff 1 to"
          style="width:310px"
        />
        <div class="input-group-append">
          <button
            class="btn btn-outline-secondary calendar"
            (click)="d57.toggle()"
            type="button"
          >
            <i class="fas fa-calendar-alt"></i>
          </button>
        </div>
      </div>
      <div  *ngIf="hap.get('2381').invalid && hap.get('2381').touched">
        <small class="text-danger" *ngIf="hap.get('2381').errors.invalidDateFormat">
          Only Date Format is allowed
        </small>
      </div>
    </div>
  </td>
</tr>
<tr>
  <td colspan="18">
    <div
      class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
    >
      <label class="checkbox-inline radioStyle">ADDRESS</label>
      <input
        class="form-control wa"
        id="1184"
        name="1184"
        formControlName="1184"
        type="text"
        data-id="Pre aff 1 add1"
        placeholder="Previous Hospital Affiliation 1 Address 1"
      />
      <input
        class="form-control wa"
        id="1185"
        name="1185"
        formControlName="1185"
        type="text"
        data-id="Pre aff 1 add2"
        placeholder="Previous Hospital Affiliation 1 address 2"
        style="margin-left:140px"
      />
    </div>
  </td>
</tr>
<tr>
  <td colspan="18">
    <div class="row">
      <div class="col-md-4">
        <label class="formLabel">CITY</label>
        <input
        style="width: 100%"
        class="form-control"
        id="1186"
        name="1186"
        formControlName="1186"
        type="text"
        placeholder="Previous Hospital Affiliation 1 City"
        data-id="Pre aff 1 city"
      />
      </div>
      <div class="form-group col-md-4 ">
        <label class="formLabel">STATE/COUNTRY</label>
        <ng-select
        [items]="states"

        bindLabel="name"
        placeholder="Previous Hospital Affiliation 1 state "
        bindValue="id"
        formControlName="1188"
      >
      </ng-select>
      
      </div>
      <div class="form-group col-md-4">
        <label class="formLabel">POSTAL CODE</label>
        <input
        style="width: 100%"
        class="form-control"
        id="1189"
        (keypress)="numberOnly($event)"
        name="1189"
        formControlName="1189"
        type="text"
        placeholder="Previous Hospital Affiliation 1 zip"
        data-id="Pre aff 1 zip"
      />
      <div *ngIf="hap.get('1189').invalid && hap.get('1189').touched">
        <small class="text-danger" *ngIf="hap.get('1189').errors.invalidZipCode">
          Zip code must be atleast 5 digits and less than 11 digits.
        </small>
      </div>
        
    </div>
    </div>
  </td>
  
</tr>

<tr>
  <td colspan="6">
    <div class="form-inline no-margin col-md-12 sideLabelAlignment">
      <label class="formLabel">FULL UNRESTRICTED PRIVILEGES? </label>
      <label class="checkbox-inline radioStyle">
        <input
          type="radio"
          id="cmd_068"
          name="cmd_068"
          formControlName="cmd_068"
          value="1"
        />Yes</label
      >
      <label class="checkbox-inline radioStyle">
        <input
          type="radio"
          id="cmd_068"
          name="cmd_068"
          formControlName="cmd_068"
          value="0"
        />No</label
      >
    </div>
  </td>
  <td colspan="6">
    
      <label class="formLabel"
        >TYPES OF PRIVILEGES (PROVISIONAL, LIMITED, CONDITIONAL, ETC.)
      </label>
      
        <ng-select
          [items]="staffStatus"
          bindLabel="name"
          placeholder="Previous Hospital Affiliation 1  status "
          bindValue="id"
          formControlName="1192"
        >
        </ng-select>
      
    
  </td>
  <td colspan="6">
    <div class="form-inline no-margin col-md-12 sideLabelAlignment">
      <label class="formLabel">WERE PRIVILEGES TEMPORARY? </label>
      <label class="checkbox-inline radioStyle">
        <input
          type="radio"
          id="cmd_069"
          name="cmd_069"
          formControlName="cmd_069"
          value="1"
        />Yes</label
      >
      <label class="checkbox-inline radioStyle">
        <input
          type="radio"
          id="cmd_069"
          name="cmd_069"
          formControlName="cmd_069"
          value="0"
        />No</label
      >
    </div>
  </td>
</tr>
<tr>
  <td colspan="18">
    <div
      class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
    >
      <label class="formLabel">REASON FOR DISCONTINUANCE </label>
      <input
        class="form-control"
        id="1303"
        name="1303"
        formControlName="1303"
        type="text"
        data-id="Pre aff 1 leaving"
        style="width:350px"
        placeholder="Previous Hospital Affiliation 1 reason for leaving"
      />
    </div>
  </td>
</tr>



<tr>
  <td colspan="18">
    <div
      class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
    >
      <label class="formLabel"><b>References</b></label
      >-Please provide three peer references from the same field
      and/or specialty who are not partners in your own group practice
      and are not relatives. All peer references should have firsthand
      knowledge of your abilities
    </div>
  </td>
  
</tr>
<tr>
  <td colspan="6">

      <label class="formLabel">1. NAME/TITLE</label>
      <input
        class="form-control"
        
        id="359"
        name="359"
        formControlName="359"
        type="text"
        data-id="reference1name"
        placeholder="Reference 1 Name "
      />
      
      <label class="formLabel">E-MAIL</label>
      <input
      
        
        class="form-control"
        id="371"
        name="371"
        formControlName="371"
        type="text"
        data-id="reference1email"
        placeholder="Reference 1 Email "
      />


      
  
    <small class="text-danger" *ngIf="hap.controls['371'].errors?.pattern">
      Only E-Mail Format is allowed</small>
  </td>
  <td colspan="6">
    <div class="form-group form-inline no-margin col-md-12 textInPro">
      <label class="formLabel"
        >PHONE NUMBER
        <input
          class="form-control"
          (keypress)="numberOnly($event)"

          id="369"
          name="369"
          formControlName="369"
          type="text"
          data-id="reference1telephone"
          placeholder="Reference 1 Phone "
        />
        
      </label>
    </div>
  </td>
  <td colspan="6">
    <div class="form-group form-inline no-margin col-md-12 textInPro">
      <label class="formLabel"
        >FAX NUMBER
        <input
          class="form-control"
          id="370"
          name="370"
          formControlName="370"
          (keypress)="numberOnly($event)"
          type="text"
          data-id="reference1fax"
          placeholder="Reference 1 fax"
        />
      </label>
    </div>
  </td>
</tr>
<tr>
  <td colspan="18">
    <div
      class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
    >
      <label class="checkbox-inline radioStyle">ADDRESS</label>
      <div class="col-md-3">
        <input
        
        class="form-control"
        id="360"
        name="360"
        formControlName="360"
        type="text"
        data-id="reference1address"
        placeholder="Reference 1 add1"
      />
      </div>
<div class="col-md-3">
<input


class="form-control"
id="361"
name="361"
formControlName="361"
type="text"
data-id="reference1address2"
placeholder="Reference 1 add 2"
/>
</div>
      

      
    </div>
  </td>
</tr>
<tr>
  <td colspan="18">
    <div class="row">
      <div class="col-md-4">
        <label class="formLabel">CITY</label>
        <input
        style="width: 100%"

        class="form-control"
        id="362"
        name="362"
        formControlName="362"
        type="text"
        data-id="reference1city"
        placeholder="Reference 1 city"
      />
      
      </div>
      <div class="form-group col-md-4 ">
        <label class="formLabel">STATE/COUNTRY</label>
        <ng-select
        [items]="states"

        bindLabel="name"
        placeholder="Reference 1 state "
        bindValue="id"
        formControlName="364"
      >
      </ng-select>
      
      </div>
      <div class="form-group col-md-4">
        <label class="formLabel">POSTAL CODE</label>
        <input
        style="width: 100%"
        (keypress)="numberOnly($event)"

        class="form-control"
        id="365"
        name="365"
        formControlName="365"
        type="text"
        data-id="reference1zip"
        placeholder="Reference 1 zip"
      />
      <div *ngIf="hap.get('365').invalid && hap.get('365').touched">
        <small class="text-danger" *ngIf="hap.get('365').errors.invalidZipCode">
          Zip code must be atleast 5 digits and less than 11 digits.
        </small>
      </div>

    </div>
    </div>
  </td>
  
</tr>
<tr>
  <td colspan="6">
    
      <label class="formLabel">2. NAME/TITLE</label>
      <input
        class="form-control"

        id="372"
        name="372"
        formControlName="372"
        type="text"
        data-id="reference2name"
        placeholder="Reference 2 Name "
      />
      
      <label class="formLabel"
        >E-MAIL</label>
        <input
          class="form-control"

          id="383"
          name="383"
          formControlName="383"
          type="text"
          data-id="Text8.1.0"
          placeholder="Reference 2 Email "
        />
        
      
    
    <small class="text-danger" *ngIf="hap.controls['383'].errors?.pattern">
      Only E-Mail Format is allowed</small>
  </td>
  <td colspan="6">
    <div class="form-group form-inline no-margin col-md-12 textInPro">
      <label class="formLabel"
        >PHONE NUMBER
        <input
          class="form-control"
          (keypress)="numberOnly($event)"
          
          id="381"
          name="381"
          formControlName="381"
          type="text"
          data-id="reference2telephone"
          placeholder="Reference 2 Phone "
        />
        
      </label>
    </div>
  </td>
  <td colspan="6">
    <div class="form-group form-inline no-margin col-md-12 textInPro">
      <label class="formLabel"
        >FAX NUMBER
        <input
          style="margin-left: 65px"
          class="form-control"
          id="382"
          name="382"
          formControlName="382"
          (keypress)="numberOnly($event)"
          type="text"
          data-id="Text8.1.1"
          placeholder="Reference 2 fax"
        />
      </label>
    </div>
  </td>
</tr>
<tr>
  <td colspan="18">
    <div
      class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
    >
      <label class="checkbox-inline radioStyle">ADDRESS</label>
      <div class="col-md-3">
        <input
        class="form-control"
        
        id="373"
        name="373"
        formControlName="373"
        type="text"
        data-id="reference2address"
        placeholder="Reference 2 add1"
      /> </div>
      <div class="col-md-3"> 
        <input
        class="form-control"
        id="374"
        name="374"
        formControlName="374"
        type="text"
        data-id="reference2address2"
        placeholder="Reference 2 add2"

      />
      </div>
      

    </div>
  </td>
</tr>
<tr>
  <td colspan="18">
    <div class="row">
      <div class="col-md-4">
        <label class="formLabel">CITY</label>
        <input
        style="width: 100%"
        class="form-control"
        id="375"
        name="375"
        formControlName="375"
        type="text"
        data-id="reference2city"
        placeholder="Reference 2 city"
      />
      </div>
      <div class="form-group col-md-4 ">
        <label class="formLabel">STATE/COUNTRY</label>
        <ng-select
        [items]="states"
        bindLabel="name"
        placeholder="Reference 2 state "
        bindValue="id"
        formControlName="377"
      >
      </ng-select>
      </div>
      <div class="form-group col-md-4">
        <label class="formLabel">POSTAL CODE</label>
        <input
        style="width: 100%"
        class="form-control"
        id="378"
        (keypress)="numberOnly($event)"
        name="378"
        formControlName="378"
        type="text"
        data-id="reference2zip"
        placeholder="Reference 2 zip"
      />
      <div *ngIf="hap.get('378').invalid && hap.get('378').touched">
        <small class="text-danger" *ngIf="hap.get('378').errors.invalidZipCode">
          Zip code must be atleast 5 digits and less than 11 digits.
        </small>
      </div>
        
    </div>
    </div>
  </td>
  
</tr>
<tr>
  <td colspan="6">

      <label class="formLabel">3. NAME/TITLE</label>
      <input
        class="form-control"
        
        id="384"
        name="384"
        formControlName="384"
        type="text"
        data-id="reference3name"
        placeholder="Reference 3 Name "
      />
      
      <label class="formLabel"
        >E-MAIL</label>
        <input
          class="form-control"

          id="396"
          name="396"
          formControlName="396"
          type="text"
          data-id="Text8.2.0"
          placeholder="Reference 3 Email "
        />
        
      
    
    <small class="text-danger" *ngIf="hap.controls['396'].errors?.pattern">
      Only E-Mail Format is allowed</small>
  </td>
  <td colspan="6">
    <div class="form-group form-inline no-margin col-md-12 textInPro">
      <label class="formLabel"
        >PHONE NUMBER
        <input
          class="form-control"
          (keypress)="numberOnly($event)"

          id="394"
          name="394"
          formControlName="394"
          type="text"
          data-id="reference3telephone"
          placeholder="Reference 3 Phone "
        />
        
      </label>
    </div>
  </td>
  <td colspan="6">
    <div class="form-group form-inline no-margin col-md-12 textInPro">
      <label class="formLabel"
        >FAX NUMBER
        <input
          style="margin-left : 65px"
          class="form-control"
          id="395"
          name="395"
          formControlName="395"
          (keypress)="numberOnly($event)"
          type="text"
          data-id="Text8.2.1"
          placeholder="Reference 3 fax "
        />
      </label>
    </div>
  </td>
</tr>
<tr>
  <td colspan="18">
    <div
      class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
    >
      <label class="checkbox-inline radioStyle">ADDRESS</label>
      <div class="col-md-3">
        <input
        class="form-control"
        id="385"
        name="385"
        formControlName="385"
        type="text"
        data-id="reference3add"
        placeholder="Reference 3 add1"
      />
      </div>
      <div class="col-md-3">
        <input
        class="form-control"
        id="386"
        name="386"
        formControlName="386"
        type="text"
        data-id="reference3spec"
        placeholder="Reference 3 add2"
      />
      </div>

      
    </div>
  </td>
</tr>
<tr>
  <td colspan="18">
    <div class="row">
      <div class="col-md-4">
        <label class="formLabel">CITY</label>
        <input
        style="width: 100%"
        class="form-control"
        id="387"
        name="387"
        formControlName="387"
        type="text"
        data-id="reference3city"
        placeholder="Reference 3 city"
      />
      </div>
      <div class="form-group col-md-4 ">
        <label class="formLabel">STATE/COUNTRY</label>
        <ng-select
        [items]="states"
        bindLabel="name"
        placeholder="Reference 3 state"
        bindValue="id"
        formControlName="389"
      >
      </ng-select>
      </div>
      <div class="form-group col-md-4">
        <label class="formLabel">POSTAL CODE</label>
        <input
        style="width: 100%"
        class="form-control"
        id="390"
        (keypress)="numberOnly($event)"
        name="390"
        formControlName="390"
        type="text"
        data-id="reference3zip"
        placeholder="Reference 3 zip"
      />
      <div *ngIf="hap.get('390').invalid && hap.get('390').touched">
        <small class="text-danger" *ngIf="hap.get('390').errors.invalidZipCode">
          Zip code must be atleast 5 digits and less than 11 digits.
        </small>
      </div>
        
    </div>
    </div>
  </td>
  
</tr>


<tr>
<td colspan="18">
  <div
    class="form-group form-inline no-margin col-md-3 sideLabelAlignment"
  >
    <label class="formLabel"
      ><b>Professional Liability Insurance Coverage</b></label
    >
  </div>
</td>

</tr>
<tr>
<td colspan="6" width="21%">
  <div class="form-group form-inline no-margin sideLabelAlignment">
    SELF-INSURED?<label class="checkbox-inline radioStyle">
      <input
        type="radio"
        id="cmd_071"
        name="cmd_071"
        formControlName="cmd_071"
        value="1"
      />Yes</label
    >
    <label class="checkbox-inline radioStyle">
      <input
        type="radio"
        id="cmd_071"
        name="cmd_071"
        formControlName="cmd_071"
        value="0"
      />No</label
    >
  </div>
</td>
<td colspan="12" width="79%">
  <div class="form-group form-inline no-margin sideLabelAlignment">
    <label class="formLabel"
      >NAME OF CURRENT MALPRACTICE INSURANCE CARRIER OR SELF-INSURED
      ENTITY
    </label>
    <input
      class="form-control"

      id="345"
      name="345"
      formControlName="345"
      type="text"
      data-id="mlp1carrier"
      placeholder="MLP Carrier Name "
    />
    
  </div>
</td>
</tr>
<tr>
<td colspan="18" width="79%">
  <div class="form-group form-inline no-margin sideLabelAlignment">
    <label class="formLabel">ADDRESS </label>
    <div class="col-md-3">
      <input
      class="form-control"

      id="901"
      name="901"
      formControlName="901"
      type="text"
      data-id="MLP Mailing Add1"
      placeholder="MLP Address 1"
    />
    </div>
    <div class="col-md-3">
      <input
      class="form-control"

      id="902"
      name="902"
      formControlName="902"
      type="text"
      data-id="MLP Mailing Add2"
      placeholder="MLP Address 2"
    />
    </div>

    

    
  </div>
</td>
</tr>
<tr>
<td colspan="18">
  <div class="row">
    <div class="col-md-4">
      <label class="formLabel">CITY</label>
      <input
      style="width: 100%"

      class="form-control"
      id="903"
      name="903"
      formControlName="903"
      type="text"
      data-id="MLP City"
      placeholder="MLP City "
    />
    
    </div>
    <div class="form-group col-md-4 ">
      <label class="formLabel">STATE/COUNTRY</label>
      <ng-select
      [items]="states"

      bindLabel="name"
      placeholder="State or Country "
      bindValue="id"
      formControlName="905"
    >
    </ng-select>
    
    </div>
    <div class="form-group col-md-4">
      <label class="formLabel">POSTAL CODE</label>
      <input
      style="width: 100%"

      (keypress)="numberOnly($event)"
      class="form-control"
      id="906"
      name="906"
      formControlName="906"
      type="text"
      data-id="MLP Zip"
      placeholder="MLP Zip "
    />
    <div *ngIf="hap.get('906').invalid && hap.get('906').touched">
      <small class="text-danger" *ngIf="hap.get('906').errors.invalidZipCode">
        Zip code must be atleast 5 digits and less than 11 digits.
      </small>
    </div>
    
      
  </div>
  </div>
</td>

</tr>
<tr>
<td colspan="4">
  <div
    class="form-group form-inline no-margin col-md-3 sideLabelAlignment"
  >
    <label class="formLabel">PHONE NUMBER </label>
    <input
      class="form-control"
      (keypress)="numberOnly($event)"

      id="1266"
      name="1266"
      formControlName="1266"
      type="text"
      data-id="MLP Phone"
      placeholder="MLP Phone "
    />
    
  </div>
</td>
<td colspan="4">
  <div
    class="form-group form-inline no-margin col-md-3 sideLabelAlignment"
  >
    <label class="formLabel">POLICY NUMBER</label>
    <input
      class="form-control"

      id="346"
      name="346"
      formControlName="346"
      type="text"
      data-id="mlp1pol"
      placeholder="MLP Policy Number "
    />
    
  </div>
</td>
<td colspan="10">
  <div class="row">
    <div class="col-md-6">
      <label class="formLabel">EFFECTIVE DATE (MM/DD/YYYY) </label>
    <div class="input-group">
      <input
        class="form-control onboard datepicker"
        placeholder="MLP Issue Date "
        ngbDatepicker
        #d58="ngbDatepicker"
        id="348"
        name="348"
        formControlName="348"
        type="text"
        data-id="mlp1dateeff_date"

      />
      <div class="input-group-append">
        <button
          class="btn btn-outline-secondary calendar "
          (click)="d58.toggle()"
          type="button"
        >
          <i class="fas fa-calendar-alt"></i>
        </button>
      </div>
    </div>
    <div  *ngIf="hap.get('348').invalid && hap.get('348').touched">
      <small class="text-danger" *ngIf="hap.get('348').errors.invalidDateFormat">
        Only Date Format is allowed
      </small>
    </div>
    </div>
    <div class="col-md-6">  <label class="formLabel">EXPIRATION DATE (MM/DD/YYYY) </label>
      <div class="input-group">
        <input
          class="form-control onboard datepicker"

          placeholder="MLP Expiration "
          ngbDatepicker
          #d59="ngbDatepicker"
          id="349"
          name="349"
          formControlName="349"
          type="text"
          data-id="mlp1dateexp_date"
        />
        <div class="input-group-append">
          <button
            class="btn btn-outline-secondary calendar"
            (click)="d59.toggle()"
            type="button"
          >
            <i class="fas fa-calendar-alt"></i>
          </button>
        </div>
      
      
    </div>
    <div  *ngIf="hap.get('349').invalid && hap.get('349').touched">
      <small class="text-danger" *ngIf="hap.get('349').errors.invalidDateFormat">
        Only Date Format is allowed
      </small>
    </div>
  </div>
  </div>

    
    
  

  
</td>
</tr>
<tr>
<td colspan="4">

    <label class="formLabel"
      >AMOUNT OF COVERAGE PER OCCURRENCE
    </label>
  
      <ng-select
        [items]="preClaim"

        bindLabel="name"
        placeholder="Per Claim "
        bindValue="id"
        formControlName="350"
      >
      </ng-select>
      
    
  
</td>
<td colspan="4">
  
    <label class="formLabel">AMOUNT OF COVERAGE AGGREGATE</label>
    
      <ng-select
        [items]="aggregates"

        bindLabel="name"
        placeholder="Aggregate"
        bindValue="id"
        formControlName="351"
      >
      </ng-select>
      
  
</td>
<td colspan="6">
  <div
    class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
  >
    <label class="checkbox-inline radioStyle">
      TYPE OF COVERAGE
      <br />
      <input
        type="radio"
        id="cmd_072"
        name="cmd_072"
        formControlName="cmd_072"
        value="1"
      />Individual</label
    >
    <label class="checkbox-inline radioStyle">
      <input
        type="radio"
        id="cmd_072"
        name="cmd_072"
        formControlName="cmd_072"
        value="0"
      />Shared</label
    >
  </div>
</td>
<td colspan="4">
  <div
    class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
  >
    <label class="formLabel">LENGTH OF TIME WITH CARRIER</label>
    <input
      class="form-control"
      id="cmd_073"
      name="cmd_073"
      formControlName="cmd_073"
      type="text"
      data-id="MLP length of time"
    />
  </div>
</td>
</tr>
<tr>
<td colspan="18">
  <div
    class="form-group form-inline no-margin col-md-3 sideLabelAlignment"
  >
    <label class="formLabel"
      ><b>PREVIOUS MALPRACTICE INSURANCE </b></label
    >
  </div>
</td>

</tr>
<tr>
<td colspan="18" width="79%">
  <div class="form-group form-inline no-margin sideLabelAlignment">
    <label class="formLabel"
      >NAME OF PREVIOUS MALPRACTICE INSURANCE CARRIER IF WITH
      CURRENT CARRIER LESS THAN 5 YEARS
    </label>
    <input
      class="form-control wa"
      id="1242"
      name="1242"
      formControlName="1242"
      type="text"
      data-id="prevmlp1carrier"
      placeholder="Previous MLP Carrier Name "
    />
    
  </div>
</td>
</tr>
<tr>
<td colspan="18" width="79%">
  <div class="form-group form-inline no-margin sideLabelAlignment">
    <label class="formLabel">ADDRESS </label>
    <div class="col-md-3">
      <input
      class="form-control"
      id="1244"
      name="1244"
      formControlName="1244"
      type="text"
      data-id="prevMLP Mailing Add1"
      placeholder="Previous MLP Address 1"
    />
    </div>
    <div class="col-md-3">
      <input
      class="form-control"
      id="1245"
      name="1245"
      formControlName="1245"
      type="text"
      data-id="prevMLP Mailing Add2"
      placeholder="Previous MLP Address 2  "
    />
    </div>

    
    
    
  </div>
</td>
</tr>
<tr>
<td colspan="18">
  <div class="row">
    <div class="col-md-4">
      <label class="formLabel">CITY</label>
      <input
      style="width: 100%"
      class="form-control"
      id="1246"
      name="1246"
      formControlName="1246"
      type="text"
      data-id="prevMLP City"
      placeholder="Previous MLP City "
    />
    </div>
    <div class="form-group col-md-4 ">
      <label class="formLabel">STATE/COUNTRY</label>
      <ng-select
      [items]="states"
      bindLabel="name"
      placeholder="State or Country "
      bindValue="id"
      formControlName="1248"
    >
    </ng-select>
    </div>
    <div class="form-group col-md-4">
      <label class="formLabel">POSTAL CODE</label>
      <input
      style="width: 100%"
      (keypress)="numberOnly($event)"
      class="form-control"
      id="1249"
      name="1249"
      formControlName="1249"
      type="text"
      data-id="prevMLP Zip"
      placeholder="Previous MLP Zip "
    />
    <div *ngIf="hap.get('1249').invalid && hap.get('1249').touched">
      <small class="text-danger" *ngIf="hap.get('1249').errors.invalidZipCode">
        Zip code must be atleast 5 digits and less than 11 digits.
      </small>
    </div>
      
  </div>
  </div>
</td>

</tr>
<tr>
<td colspan="4">
<div
  class="form-group form-inline no-margin col-md-3 sideLabelAlignment"
>
  <label class="formLabel">PHONE NUMBER </label>
  <input
    class="form-control"
    (keypress)="numberOnly($event)"
    id="1254"
    name="1254"
    formControlName="1254"
    type="text"
    data-id="prevMLP Phone"
    placeholder="Previous MLP Phone "
  />
</div>
</td>
<td colspan="4">

  <label class="formLabel">POLICY NUMBER</label>
  <input
    class="form-control"
    id="1256"
    name="1256"
    formControlName="1256"
    type="text"
    data-id="prevmlp1pol"
    placeholder="Previous MLP Policy Number "
  />
  

</td>
<td colspan="10">
<div class="row">
  <div class="col-md-6"><label class="formLabel">EFFECTIVE DATE (MM/DD/YYYY) </label>
    <div class="input-group">
      <input
        class="form-control onboard datepicker"
        placeholder="Previous MLP Issue"
        ngbDatepicker
        #d60="ngbDatepicker"
        id="1252"
        name="1252"
        formControlName="1252"
        type="text"
        data-id="prevmlp1dateeff_date"
      />
      <div class="input-group-append">
        <button
          class="btn btn-outline-secondary calendar"
          (click)="d60.toggle()"
          type="button"
        >
          <i class="fas fa-calendar-alt"></i>
        </button>
      </div>
    </div>
    <div  *ngIf="hap.get('1252').invalid && hap.get('1252').touched">
      <small class="text-danger" *ngIf="hap.get('1252').errors.invalidDateFormat">
        Only Date Format is allowed
      </small>
    </div>
  </div>
  <div class="col-md-6">
    <label class="formLabel">EXPIRATION DATE (MM/DD/YYYY) </label>
  <div class="input-group">
    <input
      class="form-control onboard datepicker"
      placeholder="Previous MLP Exp"
      ngbDatepicker
      #d61="ngbDatepicker"
      id="1253"
      name="1253"
      formControlName="1253"
      type="text"
      data-id="prevmlp1dateexp_date"
    />
    <div class="input-group-append">
      <button
        class="btn btn-outline-secondary calendar"
        (click)="d61.toggle()"
        type="button"
      >
        <i class="fas fa-calendar-alt"></i>
      </button>
    </div>
  </div>
  <div  *ngIf="hap.get('1253').invalid && hap.get('1253').touched">
    <small class="text-danger" *ngIf="hap.get('1253').errors.invalidDateFormat">
      Only Date Format is allowed
    </small>
  </div>
  </div>
</div>
  


  
  

</td>
</tr>
<tr>
<td colspan="4">

  <label class="formLabel"
    >AMOUNT OF COVERAGE PER OCCURRENCE
  </label>
  
    <ng-select
      [items]="preClaim"
      
      bindLabel="name"
      placeholder="Per Claim"
      bindValue="id"
      formControlName="1264"
    >
    </ng-select>
    

</td>
<td colspan="4">

  <label class="formLabel">AMOUNT OF COVERAGE AGGREGATE</label>
  <div class="form-group selectDrop dropDrownSet">
    <ng-select
      [items]="aggregates"

      bindLabel="name"
      placeholder="Aggregate "
      bindValue="id"
      formControlName="1265"
    >
    </ng-select>
    
  </div>

</td>
<td colspan="6">
<div
  class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
>
  <label class="checkbox-inline radioStyle">
    TYPE OF COVERAGE
    <br />
    <input
      type="radio"
      id="cmd_074"
      name="cmd_074"
      formControlName="cmd_074"
      value="1"
    />Individual</label
  >
  <label class="checkbox-inline radioStyle">
    <input
      type="radio"
      id="cmd_074"
      name="cmd_074"
      formControlName="cmd_074"
      value="0"
    />Shared</label
  >
</div>
</td>
<td colspan="4">
<div
  class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
>
  <label class="formLabel">LENGTH OF TIME WITH CARRIER</label>
  <input
    class="form-control"
    id="cmd_075"
    name="cmd_075"
    formControlName="cmd_075"
    type="text"
    data-id="prevMLP length of time"
  />
</div>
</td>
</tr>


  
</table>
<br>
<br>
<table border="1" width="100%">
  <tr>
    <td colspan="14">
      <div
        class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
      >
        <label class="formLabel"
          ><b>Practice Location Information </b></label
        >
        - Please answer the following questions for each practice
        location. Use Attachment F or make copies of pages 6-7 as
        necessary.
      </div>
    </td>
    <td colspan="4">
      <div
        class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
      >
        <label class="checkbox-inline radioStyle"
          >PRACTICE LOCATION
        </label>
        <input
          class="form-control"
          id="cmd_088"
          name="cmd_088"
          formControlName="cmd_088"
          type="text"
          data-id="Text3.0"
        />of
        <input
          class="form-control"
          id="cmd_089"
          name="cmd_089"
          formControlName="cmd_089"
          type="text"
          data-id="Text3.1"
        />
      </div>
    </td>
  </tr>
  <tr>
    <td colspan="18">
      <div class="form-group form-inline no-margin sideLabelAlignment">
        TYPE OF SERVICE PROVIDED
        <label class="checkbox-inline radioStyle">
          <input
            type="checkbox"
            id="cmd_090"
            name="cmd_090"
            formControlName="cmd_090"
            value="1"
          />Solo Primary Care
        </label>
        <label class="checkbox-inline radioStyle">
          <input
            type="checkbox"
            id="cmd_091"
            name="cmd_091"
            formControlName="cmd_091"
            value="1"
          />Solo Specialty Care
        </label>
        <label class="checkbox-inline radioStyle">
          <input
            type="checkbox"
            id="cmd_092"
            name="cmd_092"
            formControlName="cmd_092"
            value="1"
          />Group Primary Care
        </label>
        <label class="checkbox-inline radioStyle">
          <input
            type="checkbox"
            id="cmd_093"
            name="cmd_093"
            formControlName="cmd_093"
            value="1"
          />Group Single Specialty
        </label>
        <label class="checkbox-inline radioStyle">
          <input
            type="checkbox"
            id="cmd_094"
            name="cmd_094"
            formControlName="cmd_094"
            value="1"
          />Group Multi-Specialty
        </label>
      </div>
    </td>
  </tr>
  <tr>
    <td colspan="9">
      <div class="form-group form-inline no-margin sideLabelAlignment">
        <label class="formLabel"
          >GROUP NAME/PRACTICE NAME TO APPEAR IN THE DIRECTORY
        </label>
        <input
          class="form-control"
          id="cmd_0728"
          name="cmd_0728"
          formControlName="cmd_0728"
          type="text"
          data-id="prac1_PracticeName"
        />
      </div>
    </td>
    <td colspan="9">
      <div class="form-group form-inline no-margin sideLabelAlignment">
        <label class="formLabel"
          >GROUP/CORPORATE NAME AS IT APPEARS ON IRS W-9
        </label>
        <input
          style="width:70%;"
          class="form-control"
          id="cmd_0729"
          name="cmd_0729"
          formControlName="cmd_0729"
          type="text"
          data-id="prac1_TINName"
        />
      </div>
    </td>
  </tr>
  <tr>
    <td colspan="18">
      <div
        class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
      >
        <label class="formLabel"
          >PRACTICE LOCATION ADDRESS:<input
            type="checkbox"
            id="cmd_095"
            name="cmd_095"
            formControlName="cmd_095"
            value="1"
          />Primary
        </label>
        <div class="col-md-3">
          <input
          class="form-control"
          id="cmd_0730"
          name="cmd_0730"
          formControlName="cmd_0730"
          type="text"
          data-id="prac1_Address1"
        />
        </div>
        <div class="col-md-3">
          <input
          class="form-control"
          id="cmd_0731"
          name="cmd_0731"
          formControlName="cmd_0731"
          type="text"
          data-id="prac1_Address2"
        />
        </div>

        
      </div>
    </td>
  </tr>
  <tr>
    <td colspan="18">
      <div class="row">
        <div class="col-md-4">
          <label class="formLabel">CITY</label>
          <input
            style="width:70%;"
            class="form-control"
            id="cmd_0732"
            name="cmd_0732"
            formControlName="cmd_0732"
            type="text"
            data-id="prac1_City"
          />
          
        </div>
        <div class="form-group col-md-4">
          <label class="formLabel ">STATE/COUNTRY</label>
          <div class="form-group ">
            <ng-select
              [items]="states"
              bindLabel="name"
              placeholder="Select country"
              bindValue="id"
              formControlName="cmd_0733"
            >
            </ng-select>
            
          </div>
        </div>
        <div class="form-group col-md-4">
          <label class="formLabel">POSTAL CODE</label>
          <input
            style="width:70%;"
            class="form-control"
            id="cmd_0734"
            name="cmd_0734"
            (keypress)="numberOnly($event)"
            formControlName="cmd_0734"
            type="text"
            data-id="prac1_ZIP"
          />
          <div *ngIf="hap.get('cmd_0734').invalid && hap.get('cmd_0734').touched">
            <small class="text-danger" *ngIf="hap.get('cmd_0734').errors.invalidZipCode">
              Zip code must be atleast 5 digits and less than 11 digits.
            </small>
          </div>
          
        </div>
      </div>
    </td>
</tr>
<tr>
  <td colspan="6">
    <div class="form-inline no-margin col-md-12 sideLabelAlignment">
      <label class="formLabel">PHONE NUMBER</label>
      <input
        class="form-control"
        (keypress)="numberOnly($event)"
        id="cmd_0735"
        name="cmd_0735"
        formControlName="cmd_0735"
        type="text"
        data-id="prac1_Phone"
      />
    </div>
  </td>
  <td colspan="6">
    <div
      class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
    >
      <label class="formLabel">FAX NUMBER </label>
      <input
        class="form-control"
        id="cmd_0736"
        name="cmd_0736"
        formControlName="cmd_0736"
        (keypress)="numberOnly($event)"
        type="text"
        data-id="prac1_Fax"
      />
    </div>
  </td>
  <td colspan="6">
    <div
      class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
    >
      <label class="formLabel">E-MAIL </label>
      <input
        class="form-control"
        style="width:60%;"
        id="cmd_0737"
        name="cmd_0737"
        formControlName="cmd_0737"
        type="text"
        data-id="Office email"
      />
    </div>
    <small class="text-danger" *ngIf="hap.controls['cmd_0737'].errors?.pattern">
      Only E-Mail Format is allowed</small>
  </td>
</tr>
<tr>
  <td colspan="6">
    <div class="form-inline no-margin col-md-12 sideLabelAlignment">
      <label class="formLabel">BACK OFFICE PHONE NUMBER </label>
      <input
        class="form-control"
        (keypress)="numberOnly($event)"
        id="cmd_0738"
        name="cmd_0738"
        formControlName="cmd_0738"
        type="text"
        data-id="Answering Service"
      />
    </div>
  </td>
  <td colspan="6">
    <div
      class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
    >
      <label class="formLabel">SITE-SPECIFIC MEDICAID NUMBER</label>
      <input
        class="form-control"
        id="cmd_0739"
        name="cmd_0739"
        formControlName="cmd_0739"
        type="text"
        data-id="Pager"
      />
    </div>
  </td>
  <td colspan="6">
    <div
      class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
    >
      <label class="formLabel">TAX ID NUMBER</label>
      <input
        style="width:70%;"
        class="form-control"
        id="cmd_0740"
        name="cmd_0740"
        formControlName="cmd_0740"
        type="text"
        data-id="prac1_TIN"
      />
    </div>
  </td>
</tr>
<tr>
  <td colspan="9">
    <div class="form-inline no-margin col-md-12 sideLabelAlignment">
      <label class="formLabel"
        >GROUP NUMBER CORRESPONDING TO TAX ID NUMBER</label
      >
      <input
        class="form-control"
        id="cmd_0741"
        name="cmd_0741"
        formControlName="cmd_0741"
        type="text"
        data-id="prac1_NPI"
      />
    </div>
  </td>
  <td colspan="9">
    <div
      class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
    >
      <label class="formLabel"
        >GROUP NAME CORRESPONDING TO TAX ID NUMBER
      </label>
      <input
        style="width:70%;"
        class="form-control"
        id="cmd_0742"
        name="cmd_0742"
        formControlName="cmd_0742"
        type="text"
        data-id="prac1_TINName"
      />
    </div>
  </td>
</tr>
<tr>
  <td colspan="6">
    <div class="form-group form-inline no-margin sideLabelAlignment">
      ARE YOU CURRENTLY PRACTICING AT THIS LOCATION?
      <label class="checkbox-inline radioStyle">
        <input
          type="radio"
          id="cmd_09512"
          name="cmd_09512"
          formControlName="cmd_09512"
          value="1"
        />Yes
      </label>
      <label class="checkbox-inline radioStyle">
        <input
          type="radio"
          id="cmd_09512"
          name="cmd_09512"
          formControlName="cmd_09512"
          value="0"
        />No
      </label>
    </div>
  </td>
  <td colspan="6">
    <label class="formLabel">
      IF NO, EXPECTED START DATE? (MM/DD/YYYY)
    </label>
    <div class="input-group">
      <input
        style="width: 62%"
        class="form-control onboard datepicker"
        ngbDatepicker
        #d62="ngbDatepicker"
        id="cmd_0743"
        name="cmd_0743"
        placeholder="MM/DD/YYYY"
        formControlName="cmd_0743"
        type="text"
        data-id="phystartdate"
      />
      <div class="input-group-append">
        <button
          class="btn btn-outline-secondary calendar"
          (click)="d62.toggle()"
          type="button"
        >
          <i class="fas fa-calendar-alt"></i>
        </button>
      </div>
    </div>
    <div  *ngIf="hap.get('cmd_0743').invalid && hap.get('cmd_0743').touched">
      <small class="text-danger" *ngIf="hap.get('cmd_0743').errors.invalidDateFormat">
        Only Date Format is allowed
      </small>
    </div>
  </td>
  <td colspan="6">
    <div class="form-group form-inline no-margin sideLabelAlignment">
      DO YOU WANT THIS LOCATION LISTED IN THE DIRECTORY?
      <label class="checkbox-inline radioStyle">
        <input
          type="radio"
          id="cmd_096"
          name="cmd_096"
          formControlName="cmd_096"
          value="1"
        />Yes
      </label>
      <label class="checkbox-inline radioStyle">
        <input
          type="radio"
          id="cmd_096"
          name="cmd_096"
          formControlName="cmd_096"
          value="0"
        />No
      </label>
    </div>
  </td>
</tr>
<tr>
  <td colspan="6">
    <div class="form-inline no-margin col-md-12 sideLabelAlignment">
      <label class="formLabel"
        >OFFICE MANAGER OR STAFF CONTACT
      </label>
      <input
        style="width:40%;"
        class="form-control"
        id="cmd_0744"
        name="cmd_0744"
        formControlName="cmd_0744"
        type="text"
        data-id="prac1_OfficeMgrFname"
      />
      <input
        style="width:40%;"
        class="form-control"
        id="cmd_0745"
        name="cmd_0745"
        formControlName="cmd_0745"
        type="text"
        data-id="prac1_OfficeMgrLname"
      />
    </div>
  </td>
  <td colspan="6">
    <div
      class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
    >
      <label class="formLabel">PHONE NUMBER </label>
      <input
        style="width:70%;"
        (keypress)="numberOnly($event)"
        class="form-control"
        id="cmd_0746"
        name="cmd_0746"
        formControlName="cmd_0746"
        type="text"
        data-id="prac1_OMPhone"
      />
    </div>
  </td>
  <td colspan="6">
    <div
      class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
    >
      <label class="formLabel">FAX NUMBER </label>
      <input
        style="width:30%;"
        class="form-control"
        id="cmd_0747"
        name="cmd_0747"
        formControlName="cmd_0747"
        (keypress)="numberOnly($event)"
        type="text"
        data-id="prac1_OMFax"
      />
    </div>
  </td>
</tr>
<tr>
  <td colspan="18">
    <div
      class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
    >
      <label class="formLabel">CREDENTIALING CONTACT</label>
      <input
        style="width: 20%"
        class="form-control"
        placeholder="lastname"
        id="cmd_0748"
        name="cmd_0748"
        formControlName="cmd_0748"
        type="text"
        data-id="cplname"
      />
      <input
        style="width: 20%"
        class="form-control"
        placeholder="firstname"
        id="cmd_0749"
        name="cmd_0749"
        formControlName="cmd_0749"
        type="text"
        data-id="cpfname"
      />
      <div class="form-group selectDrop dropDrownSet">
        <div class="col-md-12">
          <ng-select
            [items]="suffix"
            bindLabel="name"
            placeholder="Suffix"
            bindValue="id"
            formControlName="cmd_0750"
          >
          </ng-select>
        </div>
      </div>
    </div>
  </td>
</tr>
<tr>
  <td colspan="18">
    <div
      class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
    >
      <label class="formLabel">ADDRESS</label>
      <div  class="col-md-3">
        <input
        class="form-control"
        id="cmd_0751"
        name="cmd_0751"
        placeholder="Address"
        formControlName="cmd_0751"
        type="text"
        data-id="cpadd1"
      />
      </div>
      <div  class="col-md-3">
        <input
        class="form-control"
        id="cmd_0752"
        name="cmd_0752"
        placeholder="Address"
        formControlName="cmd_0752"
        type="text"
        data-id="cpadd2"
      />
      </div>


    </div>
  </td>
</tr>
<tr>
  <td colspan="18">
    <div class="row">
      <div class="col-md-4">
        <label class="formLabel">CITY</label>
        <input
        class="form-control"
        placeholder="City"
        id="cmd_0753"
        name="cmd_0753"
        formControlName="cmd_0753"
        type="text"
        data-id="cpcity"
      />
      </div>
      <div class="form-group col-md-4 ">
        <label class="formLabel">STATE/COUNTRY</label>
        <ng-select
        [items]="states"
        bindLabel="name"
        placeholder="Select States"
        bindValue="id"
        formControlName="cmd_0754"
      >
      </ng-select>
      </div>
      <div class="form-group col-md-4">
        <label class="formLabel">POSTAL CODE</label>
        <input
        class="form-control"
        id="cmd_0755"
        name="cmd_0755"
        placeholder="Zip"
        formControlName="cmd_0755"
        (keypress)="numberOnly($event)"
        type="text"
        data-id="cpzip"
      />
      <div *ngIf="hap.get('cmd_0755').invalid && hap.get('cmd_0755').touched">
        <small class="text-danger" *ngIf="hap.get('cmd_0755').errors.invalidZipCode">
          Zip code must be atleast 5 digits and less than 11 digits.
        </small>
      </div>
        
    </div>
    </div>
  </td>
  
</tr>
<tr>
<td colspan="6">
  <div
    class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
  >
    <label class="formLabel">PHONE NUMBER </label>
    <input
      style="width:70%;"
      (keypress)="numberOnly($event)"
      class="form-control"
      id="cmd_0756"
      name="cmd_0756"
      formControlName="cmd_0756"
      type="text"
      data-id="cptele"
    />
  </div>
</td>
<td colspan="6">
  <div
    class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
  >
    <label class="formLabel">FAX NUMBER </label>
    <input
      style="width:70%;"
      class="form-control"
      id="cmd_0757"
      name="cmd_0757"
      formControlName="cmd_0757"
      (keypress)="numberOnly($event)"
      type="text"
      data-id="cpfax"
    />
  </div>
</td>
<td colspan="6">
  <div class="form-inline no-margin col-md-12 sideLabelAlignment">
    <label class="formLabel">E-MAIL </label>
    <input
      style="width:60%;"
      class="form-control"
      id="cmd_0758"
      name="cmd_0758"
      formControlName="cmd_0758"
      type="text"
      data-id="cpemail"
    />
  </div>
  <small class="text-danger" *ngIf="hap.controls['cmd_0758'].errors?.pattern">
    Only E-Mail Format is allowed</small>
</td>
</tr>
<tr>
<td colspan="9">
  <div
    class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
  >
    <label class="formLabel"
      >BILLING COMPANY'S NAME (IF APPLICABLE)</label
    >
    <input
      style="width:70%;"
      class="form-control"
      id="cmd_0759"
      name="cmd_0759"
      formControlName="cmd_0759"
      type="text"
      data-id="prac1_paytoloc"
    />
  </div>
</td>
<td colspan="9">
  <div
    class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
  >
    <label class="formLabel">BILLING REPRESENTATIVE</label>
    <input
      style="width:70%;"
      class="form-control"
      id="cmd_0760"
      name="cmd_0760"
      formControlName="cmd_0760"
      type="text"
      data-id="prac1_paytocontract"
    />
  </div>
</td>
</tr>
<tr>
<td colspan="18">
  <div
    class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
  >
    <label class="formLabel">ADDRESS </label>
    <div class="col-md-3">
      <input
      class="form-control"
      id="cmd_0761"
      name="cmd_0761"
      formControlName="cmd_0761"
      type="text"
      data-id="prac1_PaytoAddress1"
    />
    </div>
    <div class="col-md-3">
      <input
      class="form-control"
      id="cmd_0762"
      name="cmd_0762"
      formControlName="cmd_0762"
      type="text"
      data-id="prac1_PaytoAddress2"
    />
    </div>
    

  </div>
</td>
</tr>
<tr>
<td colspan="18">
  <div class="row">
    <div class="col-md-4">
      <label class="formLabel">CITY</label>
      <input
      class="form-control"
      id="cmd_0763"
      name="cmd_0763"
      formControlName="cmd_0763"
      type="text"
      data-id="prac1_PaytoCity"
    />
    </div>
    <div class="form-group col-md-4 ">
      <label class="formLabel">STATE/COUNTRY</label>
      <ng-select
      [items]="states"
      bindLabel="name"
      placeholder="Select States"
      bindValue="id"
      formControlName="cmd_0764"
    >
    </ng-select>
    </div>
    <div class="form-group col-md-4">
      <label class="formLabel">POSTAL CODE</label>
      <input
      class="form-control"
      id="cmd_0765"
      (keypress)="numberOnly($event)"
      name="cmd_0765"
      formControlName="cmd_0765"
      type="text"
      data-id="prac1_PaytoZIP"
    />
    <div *ngIf="hap.get('cmd_0765').invalid && hap.get('cmd_0765').touched">
      <small class="text-danger" *ngIf="hap.get('cmd_0765').errors.invalidZipCode">
        Zip code must be atleast 5 digits and less than 11 digits.
      </small>
    </div>
      
  </div>
  </div>
</td>

</tr>
<tr>
<td colspan="6">
<div
  class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
>
  <label class="formLabel">PHONE NUMBER </label>
  <input
    style="width:70%;"
    (keypress)="numberOnly($event)"
    class="form-control"
    id="cmd_0766"
    name="cmd_0766"
    formControlName="cmd_0766"
    type="text"
    data-id="prac1_paytophone"
  />
</div>
</td>
<td colspan="6">
<div
  class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
>
  <label class="formLabel">FAX NUMBER </label>
  <input
    style="width:70%;"
    class="form-control"
    id="cmd_0767"
    name="cmd_0767"
    formControlName="cmd_0767"
    (keypress)="numberOnly($event)"
    type="text"
    data-id="prac1_paytofax"
  />
</div>
</td>
<td colspan="6">
<div class="form-inline no-margin col-md-12 sideLabelAlignment">
  <label class="formLabel">E-MAIL </label>
  <input
    style="width:60%;"
    class="form-control"
    id="cmd_0768"
    name="cmd_0768"
    formControlName="cmd_0768"
    type="text"
    data-id="prac1_paytoemail"
  />
</div>
<small class="text-danger" *ngIf="hap.controls['cmd_0768'].errors?.pattern">
  Only E-Mail Format is allowed</small>
</td>
</tr>
<tr>
<td colspan="6">
<div
  class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
>
  <label class="formLabel"
    >DEPARTMENT NAME IF HOSPITAL-BASED
  </label>
  <input
    style="width:70%;"
    class="form-control"
    id="cmd_097"
    name="cmd_097"
    formControlName="cmd_097"
    type="text"
    data-id="prac1_paytodepart"
  />
</div>
</td>
<td colspan="6">
<div
  class="form-group form-inline no-margin col-md-12 sideLabelAlignment"
>
  <label class="formLabel">CHECK PAYABLE TO </label>
  <input
    style="width:70%;"
    class="form-control"
    id="cmd_0769"
    name="cmd_0769"
    formControlName="cmd_0769"
    type="text"
    data-id="prac1_paytotinname"
  />
</div>
</td>
<td colspan="6">
<div class="form-inline no-margin col-md-12 sideLabelAlignment">
  <label class="formLabel">CAN YOU BILL ELECTRONICALLY? </label>
  <label class="radio-inline radioStyle">
    <input
      type="radio"
      id="cmd_098"
      name="cmd_098"
      formControlName="cmd_098"
      value="1"
    />Yes</label
  >
  <label class="radio-inline radioStyle">
    <input
      type="radio"
      id="cmd_098"
      name="cmd_098"
      formControlName="cmd_098"
      value="0"
    />No</label
  >
</div>
</td>
</tr>



</table>
<br>
<br>
<table border="1" width="100%">

  <tr>
    <td colspan="18">
      <div class="form-group form-inline no-margin col-md-12">
        <label class="formLabel">HOURS PATIENTS ARE SEEN </label>
      </div>
      <br />
      <div class="row">
        <div class="col-md-2"> 
          Monday
        </div>
        <div class="col-md-2">
          <input
          type="checkbox"
          id="cmd_099"
          name="cmd_099"
          formControlName="cmd_099"
          value="1"
          />No Office Hours
       </div>
        <div class="col-md-2">
          Morning
          <input
          style="width:70%;"
          class="form-control"
          id="cmd_0770"
          name="cmd_0770"
          formControlName="cmd_0770"
          type="text"
          data-id="Monday"
        />
        </div>
        <div class="col-md-2 ">
          Afternoon<input
          style="width:70%;"
          class="form-control"
          id="cmd_0106"
          name="cmd_0106"
          formControlName="cmd_0106"
          type="text"
          data-id="Text4.2.3.0"
        />
        </div>
        <div class="col-md-2">
          Evening<input
          style="width:70%;"
          class="form-control"
          id="cmd_0114"
          name="cmd_0114"
          formControlName="cmd_0114"
          type="text"
          data-id="Text4.2.4.0"
        />
        </div>
        
      </div>
      <div class="row">
        <div class="col-md-2">Tuesday</div>
        <div class="col-md-2">
          <input
          type="checkbox"
          id="cmd_0100"
          name="cmd_0100"
          formControlName="cmd_0100"
          value="1"
        />No Office Hours
        </div>
        <div class="col-md-2">
          Morning<input
          style="width:70%;"
          class="form-control"
          id="cmd_0771"
          name="cmd_0771"
          formControlName="cmd_0771"
          type="text"
          data-id="Tuesday"
        />
        </div>
        <div class="col-md-2">
          Afternoon<input
          style="width:70%;"
          class="form-control"
          id="cmd_0107"
          name="cmd_0107"
          formControlName="cmd_0107"
          type="text"
          data-id="Text4.2.3.1"
        />
        </div>
        <div class="col-md-2">
          Evening<input
          style="width:70%;"
          class="form-control"
          id="cmd_0115"
          name="cmd_0115"
          formControlName="cmd_0115"
          type="text"
          data-id="Text4.2.4.1"
        />
        </div>
      </div>
      <div class="row">
        <div class="col-md-2">Wednesday
        </div>  
        <div class="col-md-2">
          <input
          type="checkbox"
          id="cmd_0101"
          name="cmd_0101"
          formControlName="cmd_0101"
          value="1"
        />No Office Hours
        </div>  
        <div class="col-md-2">
          Morning<input
          style="width:70%;"
          class="form-control"
          id="cmd_0772"
          name="cmd_0772"
          formControlName="cmd_0772"
          type="text"
          data-id="Wednesday"
        />
        </div>  
        <div class="col-md-2">
          Afternoon<input
          style="width:70%;"
          class="form-control"
          id="cmd_0109"
          name="cmd_0109"
          formControlName="cmd_0109"
          type="text"
          data-id="Text4.2.3.2"
        />
        </div>  
        <div class="col-md-2">
          Evening<input
          style="width:70%;"
          class="form-control"
          id="cmd_0116"
          name="cmd_0116"
          formControlName="cmd_0116"
          type="text"
          data-id="Text4.2.4.2"
        />
        </div>  
        

      </div>
      <div class="row">
        <div class="col-md-2">Thursday</div>
        <div class="col-md-2">
          <input
          type="checkbox"
          id="cmd_0102"
          name="cmd_0102"
          formControlName="cmd_0102"
          value="1"
        />No Office Hours
        </div>
        <div class="col-md-2">
          Morning<input
          style="width:70%;"
          class="form-control"
          id="cmd_0773"
          name="cmd_0773"
          formControlName="cmd_0773"
          type="text"
          data-id="Thrusday"
        />
        </div>
        <div class="col-md-2">
          Afternoon<input
          style="width:70%;"
          class="form-control"
          id="cmd_0110"
          name="cmd_0110"
          formControlName="cmd_0110"
          type="text"
          data-id="Text4.2.3.3"
        />
        </div>
        <div class="col-md-2">
          Evening<input
          style="width:70%;"
          class="form-control"
          id="cmd_0117"
          name="cmd_0117"
          formControlName="cmd_0117"
          type="text"
          data-id="Text4.2.4.3"
        />
        </div>
      </div>
      <div class="row">
        <div class="col-md-2">Friday</div>
        <div class="col-md-2">
          <input
          type="checkbox"
          id="cmd_0103"
          name="cmd_0103"
          formControlName="cmd_0103"
          value="1"
        />No Office Hours
        </div>
        <div class="col-md-2">
          Morning<input
          style="width:70%;"
          class="form-control"
          id="cmd_0774"
          name="cmd_0774"
          formControlName="cmd_0774"
          type="text"
          data-id="Friday"
        />
        </div>
        <div class="col-md-2">
          Afternoon<input
          style="width:70%;"
          class="form-control"
          id="cmd_0111"
          name="cmd_0111"
          formControlName="cmd_0111"
          type="text"
          data-id="Text4.2.3.4"
        />
        </div>
        <div class="col-md-2">
          Evening<input
          style="width:70%;"
          class="form-control"
          id="cmd_0118"
          name="cmd_0118"
          formControlName="cmd_0118"
          type="text"
          data-id="Text4.2.4.4"
        />
        </div>
      </div>
      <div class="row">
        <div class="col-md-2">Saturday</div>
        <div class="col-md-2">
          <input
          type="checkbox"
          id="cmd_0104"
          name="cmd_0104"
          formControlName="cmd_0104"
          value="1"
        />No Office Hours
        </div>
        <div class="col-md-2">
          Morning<input
          style="width:70%;"
          class="form-control"
          id="cmd_0775"
          name="cmd_0775"
          formControlName="cmd_0775"
          type="text"
          data-id="Saturday"
        />
        </div>
        <div class="col-md-2">
          Afternoon<input
          style="width:70%;"
          class="form-control"
          id="cmd_0112"
          name="cmd_0112"
          formControlName="cmd_0112"
          type="text"
          data-id="Text4.2.3.5"
        />
        </div>
        <div class="col-md-2">
          Evening<input
          style="width:70%;"
          class="form-control"
          id="cmd_0119"
          name="cmd_0119"
          formControlName="cmd_0119"
          type="text"
          data-id="Text4.2.4.5"
        />
        </div>
      </div>
      <div class="row">
        <div class="col-md-2">Sunday</div>
        <div class="col-md-2">
          <input
          type="checkbox"
          id="cmd_0105"
          name="cmd_0105"
          formControlName="cmd_0105"
          value="1"
        />No Office Hours
        </div>
        <div class="col-md-2">
          Morning<input
          style="width:70%;"
          class="form-control"
          id="cmd_0776"
          name="cmd_0776"
          formControlName="cmd_0776"
          type="text"
          data-id="Sunday"
        />
        </div>
        <div class="col-md-2">
          Afternoon<input
          style="width:70%;"
          class="form-control"
          id="cmd_0113"
          name="cmd_0113"
          formControlName="cmd_0113"
          type="text"
          data-id="Text4.2.3.6"
        />
        </div>
        <div class="col-md-2">
          Evening<input
          style="width:70%;"
          class="form-control"
          id="cmd_0120"
          name="cmd_0120"
          formControlName="cmd_0120"
          type="text"
          data-id="Text4.2.4.6"
        />
        </div>
      </div>

    </td>
  </tr>
  <tr>
    <td colspan="18">

      <div class="form-group form-inline no-margin col-md-12">
        <label class="formLabel"
          >DOES THIS LOCATION PROVIDE 24 HOUR/7 DAY A WEEK PHONE
          COVERAGE?
        </label>
      </div>
      <br />
      <div class="row">
        <div class="col-md-3">
          <input
          type="checkbox"
          id="cmd_0121"
          name="cmd_0121"
          formControlName="cmd_0121"
          value="1"
        />Answering Service
        </div>
        <div class="col-md-4">
          <input
          type="checkbox"
          id="cmd_0122"
          name="cmd_0122"
          formControlName="cmd_0122"
          value="1"
        />Voice mail with instructions to call answering service
        </div>
        <div class="col-md-3">
          <input
          type="checkbox"
          id="cmd_0123"
          name="cmd_0123"
          formControlName="cmd_0123"
          value="1"
        />Voice mail with other instructions
        </div>
        <div class="col-md-2">
          
        <input
        type="checkbox"
        id="cmd_0124"
        name="cmd_0124"
        formControlName="cmd_0124"
        value="1"
      />None
        </div>
      </div>

    </td>
  </tr>
  <tr>
    <td colspan="18">
      <div class="form-group form-inline no-margin col-md-12">
        <label class="formLabel">THIS PRACTICE LOCATION ACCEPTS</label>
      </div>
      <br />
      <div class="row">
          <div class="col-md-3">
            <input
          type="checkbox"
          id="cmd_0125"
          name="cmd_0125"
          formControlName="cmd_0125"
          value="1"
        />all new patients
          </div>
          <div class="col-md-3"><input
            type="checkbox"
            id="cmd_0126"
            name="cmd_0126"
            formControlName="cmd_0126"
            value="1"
          />existing patients with change of payor</div>
          <div class="col-md-3">
            <input
          type="checkbox"
          id="cmd_0127"
          name="cmd_0127"
          formControlName="cmd_0127"
          value="1"
        />new patients with referral
          </div>
          <div class="col-md-3">
            <input
          type="checkbox"
          id="cmd_0128"
          name="cmd_0128"
          formControlName="cmd_0128"
          value="1"
        />
        new Medicare patients
          </div>
      </div>
      <br>
      <div class="row">
        <div class=col-md-3>
          <input
          type="checkbox"
          id="cmd_0129"
          name="cmd_0129"
          formControlName="cmd_0129"
          value="1"
        />
        new Medicaid patients
        </div>
      </div>
    
    </td>
  </tr>
  <tr>
    <td colspan="18">
      <div class="form-group form-inline no-margin sideLabelAlignment">
        <label class="formLabel"
          >IF NEW PATIENT ACCEPTANCE VARIES BY HEALTH PLAN, PLEASE
          PROVIDE EXPLANATION.
        </label>
        <input
          class="form-control"
          id="cmd_0130"
          name="cmd_0130"
          formControlName="cmd_0130"
          type="text"
          data-id="Text4.4.1"
        />
      </div>
    </td>
  </tr>
  <tr>
    <td colspan="18">
      <div class="form-group form-inline no-margin col-md-12">
        <label class="formLabel">PRACTICE LIMITATIONS </label>
      </div>
      <br />
      <div class="row">
        <div class="col-md-4 form-group form-inline no-margin">
          <input
          type="radio"
          id="cmd_0131"
          name="cmd_0131"
          formControlName="cmd_0131"
          value="0"
        />Male only
        </div>
        <div class="col-md-4">
          <input
          type="radio"
          id="cmd_0131"
          name="cmd_0131"
          formControlName="cmd_0131"
          value="1"
        />Female only
        </div>
        <div class="col-md-4">  

          Age <input
            style="width:40%;"
            class="form-control"
            id="cmd_0777"
            name="cmd_0777"
            formControlName="cmd_0777"
            type="text"
            data-id="prac1_agespatients"
          />
        </div>
      </div>
       <div class="row">
        <div class="col-md-4 form-inline">
          <input
          type="radio"
          id="cmd_0131"
          name="cmd_0131"
          formControlName="cmd_0131"
          value="2"
        />Other
        <input
          style="width:70%;"
          class="form-control"
          id="cmd_0132"
          name="cmd_0132"
          formControlName="cmd_0132"
          type="text"
          data-id="Text4.4.3"
        />
        </div>
       </div> 
      
      
    </td>
  </tr>
  <tr>
    <td colspan="18">
      <div class="form-group form-inline no-margin col-md-12">
        <label class="formLabel"
          >DO NURSE PRACTITIONERS, PHYSICIAN ASSISTANTS, MIDWIVES,
          SOCIAL WORKERS OR OTHER NON-PHYSICIAN PROVIDERS CARE FOR
          PATIENTS AT THIS PRACTICE LOCATION?
        </label>
      </div>
      <br />
      <div class="row">
        <div class="col-md-3">
          <input
          type="radio"
          id="cmd_0133"
          name="cmd_0133"
          formControlName="cmd_0133"
          value="1"
        />Yes
        </div>
        <div class="col-md-3">
          <input
          type="radio"
          id="cmd_0133"
          name="cmd_0133"
          formControlName="cmd_0133"
          value="0"
        />No
        </div>
        <div class="col-md-6">
          If yes, provide the following information for each staff member:
          <input
            style="width:70%;"
            class="form-control"
            id="cmd_0134"
            name="cmd_0134"
            formControlName="cmd_0134"
            type="text"
            data-id="Text4.4.3"
          />
        </div>
      </div>
      

    </td>
  </tr>
  <tr>
    <td colspan="18">
      <div class="row">
        <div class="col-md-3">
          <label class="formLabel">NAME</label>
        <input
          class="form-control"
          id="cmd_0135"
          name="cmd_0135"
          formControlName="cmd_0135"
          type="text"
          data-id="Text4.3.3.0"
        />
        </div>
        <div class="col-md-6">
          <label class="formLabel form-inline">PROFESSIONAL DESIGNATION </label>
         <div class=row>
          <div class="col-md-6" >
            <input
            class="form-control"
            id="cmd_0136"
            name="cmd_0136"
            formControlName="cmd_0136"
            type="text"
            data-id="Text4.3.4.0"
          />
          </div>
          <div class="col-md-6" >
            <input
            class="form-control"
            id="cmd_0137"
            name="cmd_0137"
            formControlName="cmd_0137"
            type="text"
            data-id="Text4.4.4.0.0"
          />
          </div>
         </div>


        </div>
        <div class="col-md-3">
          <label class="formLabel">STATE & LICENSE NO.</label>
        <input
          class="form-control"
          id="cmd_0138"
          name="cmd_0138"
          formControlName="cmd_0138"
          type="text"
          data-id="Text4.4.4.1.0"
        />
        </div>
      </div>
      
      
    </td>
  </tr>
  <tr>
    <td colspan="18">
      <div class="row">
        <div class="col-md-3">
          <label class="formLabel">NAME</label>
          <input
            class="form-control"
            id="cmd_0139"
            name="cmd_0139"
            formControlName="cmd_0139"
            type="text"
            data-id="Text4.3.3.1.0"
          />
        </div>
        <div class="col-md-6">
          <label class="formLabel form-inline">PROFESSIONAL DESIGNATION </label>
         <div class=row>
          <div class="col-md-6" >
            <input
            class="form-control"
            id="cmd_0140"
            name="cmd_0140"
            formControlName="cmd_0140"
            type="text"
            data-id="Text4.3.4.1.0"
          />
          </div>
          <div class="col-md-6" >
            <input
            class="form-control"
            id="cmd_0141"
            name="cmd_0141"
            formControlName="cmd_0141"
            type="text"
            data-id="Text4.4.4.0.1.0"
          />
          </div>
         </div>


        </div>
        <div class="col-md-3">
          <label class="formLabel">STATE & LICENSE NO.</label>
          <input
          class="form-control"
          id="cmd_0142"
          name="cmd_0142"
          formControlName="cmd_0142"
          type="text"
          data-id="Text4.4.4.1.1.0"
        />
        </div>
      </div>
      
      
    </td>
  </tr>
  

</table>
<br>
<br>
<table border="1" width="100%">
  <tr>
    <td colspan="18">
      <div class="form-group form-inline no-margin sideLabelAlignment">
        <b>Section II-Disclosure Questions</b>
        <label class="boldParagraph"
          >- Please provide an explanation for any question answered
          yes-except 16-on page 10. Licensure
        </label>
        <br />
      </div>
    </td>
  </tr>
  <tr>
    <td colspan="2" width="5%">
      <div class="form-group form-inline no-margin sideLabelAlignment">
        <label class="formLabel">1</label>
      </div>
    </td>
    <td colspan="12" width="75%">
      <div class="form-group form-inline no-margin sideLabelAlignment">
        <label class="formLabel"
          >Has your license to practice, in your profession, ever been
          denied, suspended, revoked, restricted,voluntarily surrendered
          while under investigation, or have you ever been subject to a
          consent order,probation or any conditions or limitations by
          any state licensing board?<span
          class="mandatory">*</span></label
        >
      </div>
    </td>
    <td colspan="4" width="20%">
      <div class="form-group form-inline no-margin sideLabelAlignment">
        <label class="radio-inline radioStyle">
          <input
            type="radio"
            [class.is-invalid]="
              (hap.controls['cmd_0232'].touched &&
                hap.controls['cmd_0232'].errors) ||
              (hap.controls['cmd_0232'].invalid && isSubmit)
            "
            id="cmd_0232"
            name="cmd_0232"
            formControlName="cmd_0232"
            value="1"
            
          />Yes</label
        >
        <label class="radio-inline radioStyle">
          <input
            type="radio"
            [class.is-invalid]="
              (hap.controls['cmd_0232'].touched &&
                hap.controls['cmd_0232'].errors) ||
              (hap.controls['cmd_0232'].invalid && isSubmit)
            "
            id="cmd_0232"
            name="cmd_0232"
            formControlName="cmd_0232"
            value="0"
            
          />No</label
        >
        <div
          class="formLabel col-md-2"
          *ngIf="
            hap.controls['cmd_0232'].touched &&
            hap.controls['cmd_0232'].errors
          "
        >
          <small
            class="text-danger"
            *ngIf="hap.controls['cmd_0232'].errors?.required"
          >
            required
          </small>
        </div>
        <span
          *ngIf="
            (hap.controls['cmd_0232'].touched &&
              hap.controls['cmd_0232'].errors) ||
            (hap.controls['cmd_0232'].errors && isSubmit)
          "
        >
          <i class="fas fa-times ml-2 text-danger"></i>
        </span>
      </div>
    </td>
  </tr>
  <tr>
    <td colspan="2" width="5%">
      <div class="form-group form-inline no-margin sideLabelAlignment">
        <label class="formLabel">2</label>
      </div>
    </td>
    <td colspan="12" width="75%">
      <div class="form-group form-inline no-margin sideLabelAlignment">
        <label class="formLabel"
          >Have you ever received a reprimand or been fined by any state
          licensing board?<span
          class="mandatory">*</span>
        </label>
      </div>
    </td>
    <td colspan="4" width="20%">
      <div class="form-group form-inline no-margin sideLabelAlignment">
        <label class="radio-inline radioStyle">
          <input
            type="radio"
            id="cmd_0233"
            [class.is-invalid]="
              (hap.controls['cmd_0233'].touched &&
                hap.controls['cmd_0233'].errors) ||
              (hap.controls['cmd_0233'].errors && isSubmit)
            "
            name="cmd_0233"
            formControlName="cmd_0233"
            value="1"
            
          />Yes</label
        >
        <label class="radio-inline radioStyle">
          <input
            type="radio"
            id="cmd_0233"
            [class.is-invalid]="
              (hap.controls['cmd_0233'].touched &&
                hap.controls['cmd_0233'].errors) ||
              (hap.controls['cmd_0233'].errors && isSubmit)
            "
            name="cmd_0233"
            formControlName="cmd_0233"
            value="0"
            
          />No</label
        >
        <div
          class="formLabel col-md-2"
          *ngIf="
            hap.controls['cmd_0233'].touched &&
            hap.controls['cmd_0233'].errors
          "
        >
          <small
            class="text-danger"
            *ngIf="hap.controls['cmd_0233'].errors?.required"
          >
            required
          </small>
        </div>
        <span
          *ngIf="
            (hap.controls['cmd_0233'].touched &&
              hap.controls['cmd_0233'].errors) ||
            (hap.controls['cmd_0233'].errors && isSubmit)
          "
        >
          <i class="fas fa-times ml-2 text-danger"></i>
        </span>
      </div>
    </td>
  </tr>
  <tr>
    <td colspan="18" width="5%">
      <div class="form-group form-inline no-margin sideLabelAlignment">
        <label class="formLabel"
          >Hospital Privileges and Other Affiliations
        </label>
      </div>
    </td>
  </tr>
  <tr>
    <td colspan="2" width="5%">
      <div class="form-group form-inline no-margin sideLabelAlignment">
        <label class="formLabel">3</label>
      </div>
    </td>
    <td colspan="12" width="75%">
      <div class="form-group form-inline no-margin sideLabelAlignment">
        <label class="formLabel"
          >Have your clinical privileges or Medical Staff membership at
          any hospital or healthcare institution ever been denied,
          suspended, revoked, restricted, denied renewal or subject to
          probationary or to other disciplinary conditions (for reasons
          other than non-completion of medical records when quality of
          care was not adversely affected) or have proceedings toward
          any of those ends been instituted or recommended by any
          hospital or healthcare institution, medical staff or
          committee, or governing board?<span
          class="mandatory">*</span>
        </label>
      </div>
    </td>
    <td colspan="4" width="20%">
      <div class="form-group form-inline no-margin sideLabelAlignment">
        <label class="radio-inline radioStyle">
          <input
            type="radio"
            id="cmd_0234"
            [class.is-invalid]="
              (hap.controls['cmd_0234'].touched &&
                hap.controls['cmd_0234'].errors) ||
              (hap.controls['cmd_0234'].errors && isSubmit)
            "
            name="cmd_0234"
            formControlName="cmd_0234"
            value="1"
            
          />Yes</label
        >
        <label class="radio-inline radioStyle">
          <input
            type="radio"
            id="cmd_0234"
            [class.is-invalid]="
              (hap.controls['cmd_0234'].touched &&
                hap.controls['cmd_0234'].errors) ||
              (hap.controls['cmd_0234'].errors && isSubmit)
            "
            name="cmd_0234"
            formControlName="cmd_0234"
            value="0"
            
          />No</label
        >
        <div
          class="formLabel col-md-2"
          *ngIf="
            hap.controls['cmd_0234'].touched &&
            hap.controls['cmd_0234'].errors
          "
        >
          <small
            class="text-danger"
            *ngIf="hap.controls['cmd_0234'].errors?.required"
          >
            required
          </small>
        </div>
        <span
          *ngIf="
            (hap.controls['cmd_0234'].touched &&
              hap.controls['cmd_0234'].errors) ||
            (hap.controls['cmd_0234'].errors && isSubmit)
          "
        >
          <i class="fas fa-times ml-2 text-danger"></i>
        </span>
      </div>
    </td>
  </tr>
  <tr>
    <td colspan="2" width="5%">
      <div class="form-group form-inline no-margin sideLabelAlignment">
        <label class="formLabel">4</label>
      </div>
    </td>
    <td colspan="12" width="75%">
      <div class="form-group form-inline no-margin sideLabelAlignment">
        <label class="formLabel"
          >Have you voluntarily surrendered, limited your privileges or
          not reapplied for privileges while under investigation?<span
          class="mandatory">*</span></label
        >
      </div>
    </td>
    <td colspan="4" width="20%">
      <div class="form-group form-inline no-margin sideLabelAlignment">
        <label class="radio-inline radioStyle">
          <input
            type="radio"
            id="cmd_0235"
            [class.is-invalid]="
              (hap.controls['cmd_0235'].touched &&
                hap.controls['cmd_0235'].errors) ||
              (hap.controls['cmd_0235'].errors && isSubmit)
            "
            name="cmd_0235"
            formControlName="cmd_0235"
            value="1"
            
          />Yes</label
        >
        <label class="radio-inline radioStyle">
          <input
            type="radio"
            id="cmd_0235"
            [class.is-invalid]="
              (hap.controls['cmd_0235'].touched &&
                hap.controls['cmd_0235'].errors) ||
              (hap.controls['cmd_0235'].errors && isSubmit)
            "
            name="cmd_0235"
            formControlName="cmd_0235"
            value="0"
          />No</label
        >
        <div
          class="formLabel col-md-2"
          *ngIf="
            hap.controls['cmd_0235'].touched &&
            hap.controls['cmd_0235'].errors
          "
        >
          <small
            class="text-danger"
            *ngIf="hap.controls['cmd_0235'].errors?.required"
          >
            required
          </small>
        </div>
        <span
          *ngIf="
            (hap.controls['cmd_0235'].touched &&
              hap.controls['cmd_0235'].errors) ||
            (hap.controls['cmd_0235'].errors && isSubmit)
          "
        >
          <i class="fas fa-times ml-2 text-danger"></i>
        </span>
      </div>
    </td>
  </tr>
  <tr>
    <td colspan="2" width="5%">
      <div class="form-group form-inline no-margin sideLabelAlignment">
        <label class="formLabel">5</label>
      </div>
    </td>
    <td colspan="12" width="75%">
      <div class="form-group form-inline no-margin sideLabelAlignment">
        <label class="formLabel"
          >Have you ever been terminated for cause or not renewed for
          cause from participation, or been subject to any disciplinary
          action, by any managed care organizations (including HMOs,
          PPOs, or provider organizations such as IPAs, PHOs)?<span
          class="mandatory">*</span>
        </label>
      </div>
    </td>
    <td colspan="4" width="20%">
      <div class="form-group form-inline no-margin sideLabelAlignment">
        <label class="radio-inline radioStyle">
          <input
            type="radio"
            id="cmd_0236"
            [class.is-invalid]="
              (hap.controls['cmd_0236'].touched &&
                hap.controls['cmd_0236'].errors) ||
              (hap.controls['cmd_0236'].errors && isSubmit)
            "
            name="cmd_0236"
            formControlName="cmd_0236"
            value="1"

          />Yes</label
        >
        <label class="radio-inline radioStyle">
          <input
            type="radio"
            id="cmd_0236"
            [class.is-invalid]="
              (hap.controls['cmd_0236'].touched &&
                hap.controls['cmd_0236'].errors) ||
              (hap.controls['cmd_0236'].errors && isSubmit)
            "
            name="cmd_0236"
            formControlName="cmd_0236"
            value="0"
            
          />No</label
        >
        <div
          class="formLabel col-md-2"
          *ngIf="
            hap.controls['cmd_0236'].touched &&
            hap.controls['cmd_0236'].errors
          "
        >
          <small
            class="text-danger"
            *ngIf="hap.controls['cmd_0236'].errors?.required"
          >
            required
          </small>
        </div>
        <span
          *ngIf="
            (hap.controls['cmd_0236'].touched &&
              hap.controls['cmd_0236'].errors) ||
            (hap.controls['cmd_0236'].errors && isSubmit)
          "
        >
          <i class="fas fa-times ml-2 text-danger"></i>
        </span>
      </div>
    </td>
  </tr>
  <tr>
    <td colspan="18" width="5%">
      <div class="form-group form-inline no-margin sideLabelAlignment">
        <label class="formLabel"
          >Education, Training and Board Certification
        </label>
      </div>
    </td>
  </tr>
  <tr>
    <td colspan="2" width="5%">
      <div class="form-group form-inline no-margin sideLabelAlignment">
        <label class="formLabel">6</label>
      </div>
    </td>
    <td colspan="12" width="75%">
      <div class="form-group form-inline no-margin sideLabelAlignment">
        <label class="formLabel"
          >Were you ever placed on probation, disciplined, formally
          reprimanded, suspended or asked to resign during an
          internship, residency, fellowship, preceptorship or other
          clinical education program? If you are currently in a
          training program, have you been placed on probation,
          disciplined, formally reprimanded, suspended or asked to
          resign?<span
          class="mandatory">*</span>
        </label>
      </div>
    </td>
    <td colspan="4" width="20%">
      <div class="form-group form-inline no-margin sideLabelAlignment">
        <label class="radio-inline radioStyle">
          <input
            type="radio"
            id="cmd_0237"
            [class.is-invalid]="
              (hap.controls['cmd_0237'].touched &&
                hap.controls['cmd_0237'].errors) ||
              (hap.controls['cmd_0237'].errors && isSubmit)
            "
            name="cmd_0237"
            formControlName="cmd_0237"
            value="1"
          />Yes</label
        >
        <label class="radio-inline radioStyle">
          <input
            type="radio"
            id="cmd_0237"
            [class.is-invalid]="
              (hap.controls['cmd_0237'].touched &&
                hap.controls['cmd_0237'].errors) ||
              (hap.controls['cmd_0237'].errors && isSubmit)
            "
            name="cmd_0237"
            formControlName="cmd_0237"
            value="0"
            
          />No</label
        >
        <div
          class="formLabel col-md-2"
          *ngIf="
            hap.controls['cmd_0237'].touched &&
            hap.controls['cmd_0237'].errors
          "
        >
          <small
            class="text-danger"
            *ngIf="hap.controls['cmd_0237'].errors?.required"
          >
            required
          </small>
        </div>
        <span
          *ngIf="
            (hap.controls['cmd_0237'].touched &&
              hap.controls['cmd_0237'].errors) ||
            (hap.controls['cmd_0237'].errors && isSubmit)
          "
        >
          <i class="fas fa-times ml-2 text-danger"></i>
        </span>
      </div>
    </td>
  </tr>
  <tr>
    <td colspan="2" width="5%">
      <div class="form-group form-inline no-margin sideLabelAlignment">
        <label class="formLabel">7</label>
      </div>
    </td>
    <td colspan="12" width="75%">
      <div class="form-group form-inline no-margin sideLabelAlignment">
        <label class="formLabel">
          Have you ever, while under investigation, voluntarily
          withdrawn or prematurely terminated your status as a student
          or employee in any internship, residency, fellowship,
          preceptorship, or other clinical education program?<span
          class="mandatory">*</span>
        </label>
      </div>
    </td>
    <td colspan="4" width="20%">
      <div class="form-group form-inline no-margin sideLabelAlignment">
        <label class="radio-inline radioStyle">
          <input
            type="radio"
            id="cmd_0238"
            [class.is-invalid]="
              (hap.controls['cmd_0238'].touched &&
                hap.controls['cmd_0238'].errors) ||
              (hap.controls['cmd_0238'].errors && isSubmit)
            "
            name="cmd_0238"
            formControlName="cmd_0238"
            value="1"
            
          />Yes</label
        >
        <label class="radio-inline radioStyle">
          <input
            type="radio"
            id="cmd_0238"
            [class.is-invalid]="
              (hap.controls['cmd_0238'].touched &&
                hap.controls['cmd_0238'].errors) ||
              (hap.controls['cmd_0238'].errors && isSubmit)
            "
            name="cmd_0238"
            formControlName="cmd_0238"
            value="0"
            
          />No</label
        >
        <div
          class="formLabel col-md-2"
          *ngIf="
            hap.controls['cmd_0238'].touched &&
            hap.controls['cmd_0238'].errors
          "
        >
          <small
            class="text-danger"
            *ngIf="hap.controls['cmd_0238'].errors?.required"
          >
            required
          </small>
        </div>
        <span
          *ngIf="
            (hap.controls['cmd_0238'].touched &&
              hap.controls['cmd_0238'].errors) ||
            (hap.controls['cmd_0238'].errors && isSubmit)
          "
        >
          <i class="fas fa-times ml-2 text-danger"></i>
        </span>
      </div>
    </td>
  </tr>
  <tr>
    <td colspan="2" width="5%">
      <div class="form-group form-inline no-margin sideLabelAlignment">
        <label class="formLabel">8</label>
      </div>
    </td>
    <td colspan="12" width="75%">
      <div class="form-group form-inline no-margin sideLabelAlignment">
        <label class="formLabel"
          >Have any of your board certifications or eligibility ever
          been revoked?<span
          class="mandatory">*</span>
        </label>
      </div>
    </td>
    <td colspan="4" width="20%">
      <div class="form-group form-inline no-margin sideLabelAlignment">
        <label class="radio-inline radioStyle">
          <input
            type="radio"
            id="cmd_0239"
            [class.is-invalid]="
              (hap.controls['cmd_0239'].touched &&
                hap.controls['cmd_0239'].errors) ||
              (hap.controls['cmd_0239'].errors && isSubmit)
            "
            name="cmd_0239"
            formControlName="cmd_0239"
            value="1"
            
          />Yes</label
        >
        <label class="radio-inline radioStyle">
          <input
            type="radio"
            id="cmd_0239"
            [class.is-invalid]="
              (hap.controls['cmd_0239'].touched &&
                hap.controls['cmd_0239'].errors) ||
              (hap.controls['cmd_0239'].errors && isSubmit)
            "
            name="cmd_0239"
            formControlName="cmd_0239"
            value="0"

          />No</label
        >
        <div
          class="formLabel col-md-2"
          *ngIf="
            hap.controls['cmd_0239'].touched &&
            hap.controls['cmd_0239'].errors
          "
        >
          <small
            class="text-danger"
            *ngIf="hap.controls['cmd_0239'].errors?.required"
          >
            required
          </small>
        </div>
        <span
          *ngIf="
            (hap.controls['cmd_0239'].touched &&
              hap.controls['cmd_0239'].errors) ||
            (hap.controls['cmd_0239'].errors && isSubmit)
          "
        >
          <i class="fas fa-times ml-2 text-danger"></i>
        </span>
      </div>
    </td>
  </tr>
  <tr>
    <td colspan="2" width="5%">
      <div    class="form-group form-inline no-margin sideLabelAlignment">
        <label class="formLabel">9</label>
      </div>
    </td>
    <td colspan="12" width="75%">
      <div class="form-group form-inline no-margin sideLabelAlignment">
        <label class="formLabel"
          >Have you ever chosen not to re-certify or voluntarily
          surrendered your board certification(s) while under
          investigation?<span
          class="mandatory">*</span>
        </label>
      </div>
    </td>
    <td colspan="4" width="20%">
      <div class="form-group form-inline no-margin sideLabelAlignment">
        <label class="radio-inline radioStyle">
          <input
            type="radio"
            id="cmd_0240"
            [class.is-invalid]="
              (hap.controls['cmd_0240'].touched &&
                hap.controls['cmd_0240'].errors) ||
              (hap.controls['cmd_0240'].errors && isSubmit)
            "
            name="cmd_0240"
            formControlName="cmd_0240"
            value="1"
            
          />Yes</label
        >
        <label class="radio-inline radioStyle">
          <input
            type="radio"
            id="cmd_0240"
            [class.is-invalid]="
              (hap.controls['cmd_0240'].touched &&
                hap.controls['cmd_0240'].errors) ||
              (hap.controls['cmd_0240'].errors && isSubmit)
            "
            name="cmd_0240"
            formControlName="cmd_0240"
            value="0"
            
          />No</label
        >
        <div
          class="formLabel col-md-2"
          *ngIf="
            hap.controls['cmd_0240'].touched &&
            hap.controls['cmd_0240'].errors
          "
        >
          <small
            class="text-danger"
            *ngIf="hap.controls['cmd_0240'].errors?.required"
          >
            required
          </small>
        </div>
        <span
          *ngIf="
            (hap.controls['cmd_0240'].touched &&
              hap.controls['cmd_0240'].errors) ||
            (hap.controls['cmd_0240'].errors && isSubmit)
          "
        >
          <i class="fas fa-times ml-2 text-danger"></i>
        </span>
      </div>
    </td>
  </tr>
  <tr>
    <td colspan="18" width="5%">
      <div class="form-group form-inline no-margin sideLabelAlignment">
        <label class="formLabel">DEA or CDS </label>
      </div>
    </td>
  </tr>
  <tr>
    <td colspan="2" width="5%">
      <div class="form-group form-inline no-margin sideLabelAlignment">
        <label class="formLabel">10</label>
      </div>
    </td>
    <td colspan="12" width="75%">
      <div class="form-group form-inline no-margin sideLabelAlignment">
        <label class="formLabel"
          >Have your Federal DEA and/or Controlled Substances
          Certificate(s) or authorization(s) ever been denied,
          suspended, revoked, restricted, denied renewal, or voluntarily
          relinquished?<span
          class="mandatory">*</span></label
        >
      </div>
    </td>
    <td colspan="4" width="20%">
      <div class="form-group form-inline no-margin sideLabelAlignment">
        <label class="radio-inline radioStyle">
          <input
            type="radio"
            id="cmd_0241"
            [class.is-invalid]="
              (hap.controls['cmd_0241'].touched &&
                hap.controls['cmd_0241'].errors) ||
              (hap.controls['cmd_0241'].errors && isSubmit)
            "
            name="cmd_0241"
            formControlName="cmd_0241"
            value="1"
            
          />Yes</label
        >
        <label class="radio-inline radioStyle">
          <input
            type="radio"
            id="cmd_0241"
            [class.is-invalid]="
              (hap.controls['cmd_0241'].touched &&
                hap.controls['cmd_0241'].errors) ||
              (hap.controls['cmd_0241'].errors && isSubmit)
            "
            name="cmd_0241"
            formControlName="cmd_0241"
            value="0"
            
          />No</label
        >
        <div
          class="formLabel col-md-2"
          *ngIf="
            hap.controls['cmd_0241'].touched &&
            hap.controls['cmd_0241'].errors
          "
        >
          <small
            class="text-danger"
            *ngIf="hap.controls['cmd_0241'].errors?.required"
          >
            required
          </small>
        </div>
        <span
          *ngIf="
            (hap.controls['cmd_0241'].touched &&
              hap.controls['cmd_0241'].errors) ||
            (hap.controls['cmd_0241'].errors && isSubmit)
          "
        >
          <i class="fas fa-times ml-2 text-danger"></i>
        </span>
      </div>
    </td>
  </tr>
  <tr>
    <td colspan="18" width="5%">
      <div class="form-group form-inline no-margin sideLabelAlignment">
        <label class="formLabel"
          >Medicare, Medicaid or other Governmental Program
          Participation
        </label>
      </div>
    </td>
  </tr>
  <tr>
    <td colspan="2" width="5%">
      <div class="form-group form-inline no-margin sideLabelAlignment">
        <label class="formLabel">11</label>
      </div>
    </td>
    <td colspan="12" width="75%">
      <div class="form-group form-inline no-margin sideLabelAlignment">
        <label class="formLabel"
          >Have you ever been disciplined, excluded from, debarred,
          suspended, reprimanded, sanctioned, censured, disqualified or
          otherwise restricted in regard to participation in the
          Medicare or Medicaid program, or in regard to other federal or
          state governmental health care plans or programs?<span
          class="mandatory">*</span>
        </label>
      </div>
    </td>
    <td colspan="4" width="20%">
      <div class="form-group form-inline no-margin sideLabelAlignment">
        <label class="radio-inline radioStyle">
          <input
            type="radio"
            id="cmd_0242"
            [class.is-invalid]="
              (hap.controls['cmd_0242'].touched &&
                hap.controls['cmd_0242'].errors) ||
              (hap.controls['cmd_0242'].errors && isSubmit)
            "
            name="cmd_0242"
            formControlName="cmd_0242"
            value="1"
            
          />Yes</label
        >
        <label class="radio-inline radioStyle">
          <input
            type="radio"
            id="cmd_0242"
            [class.is-invalid]="
              (hap.controls['cmd_0242'].touched &&
                hap.controls['cmd_0242'].errors) ||
              (hap.controls['cmd_0242'].errors && isSubmit)
            "
            name="cmd_0242"
            formControlName="cmd_0242"
            value="0"
            
          />No</label
        >
        <div
          class="formLabel col-md-2"
          *ngIf="
            hap.controls['cmd_0242'].touched &&
            hap.controls['cmd_0242'].errors
          "
        >
          <small
            class="text-danger"
            *ngIf="hap.controls['cmd_0242'].errors?.required"
          >
            required
          </small>
        </div>
        <span
          *ngIf="
            (hap.controls['cmd_0242'].touched &&
              hap.controls['cmd_0242'].errors) ||
            (hap.controls['cmd_0242'].errors && isSubmit)
          "
        >
          <i class="fas fa-times ml-2 text-danger"></i>
        </span>
      </div>
    </td>
  </tr>
  <tr>
    <td colspan="18" width="5%">
      <div class="form-group form-inline no-margin sideLabelAlignment">
        <label class="formLabel"
          >Other Sanctions or Investigations
        </label>
      </div>
    </td>
  </tr>
<tr>
  <td colspan="2" width="5%">
    <div class="form-group form-inline no-margin sideLabelAlignment">
      <label class="formLabel">12</label>
    </div>
  </td>
  <td colspan="12" width="75%">
    <div class="form-group form-inline no-margin sideLabelAlignment">
      <label class="formLabel"
        >Are you currently or have you ever been the subject of an
        investigation by any hospital, licensing authority, DEA or CDS
        authorizing entities, education or training program, Medicare
        or Medicaid program, or any other private, federal or state
        health program?<span
        class="mandatory">*</span>
      </label>
    </div>
  </td>
  <td colspan="4" width="20%">
    <div class="form-group form-inline no-margin sideLabelAlignment">
      <label class="radio-inline radioStyle">
        <input
          type="radio"
          id="cmd_0243"
          [class.is-invalid]="
            (hap.controls['cmd_0243'].touched &&
              hap.controls['cmd_0243'].errors) ||
            (hap.controls['cmd_0243'].errors && isSubmit)
          "
          name="cmd_0243"
          formControlName="cmd_0243"
          value="1"
          
        />Yes</label
      >
      <label class="radio-inline radioStyle">
        <input
          type="radio"
          id="cmd_0243"
          [class.is-invalid]="
            (hap.controls['cmd_0243'].touched &&
              hap.controls['cmd_0243'].errors) ||
            (hap.controls['cmd_0243'].errors && isSubmit)
          "
          name="cmd_0243"
          formControlName="cmd_0243"
          value="0"
          
        />No</label
      >
      <div
        class="formLabel col-md-2"
        *ngIf="
          hap.controls['cmd_0243'].touched &&
          hap.controls['cmd_0243'].errors
        "
      >
        <small
          class="text-danger"
          *ngIf="hap.controls['cmd_0243'].errors?.required"
        >
          required
        </small>
      </div>
      <span
        *ngIf="
          (hap.controls['cmd_0243'].touched &&
            hap.controls['cmd_0243'].errors) ||
          (hap.controls['cmd_0243'].errors && isSubmit)
        "
      >
        <i class="fas fa-times ml-2 text-danger"></i>
      </span>
    </div>
  </td>
</tr>
<tr>
  <td colspan="18" width="5%">
    <div class="form-group form-inline no-margin sideLabelAlignment">
      <label class="formLabel"
        >Other Sanctions or Investigations
      </label>
    </div>
  </td>
</tr>
<tr>
  <td colspan="2" width="5%">
    <div class="form-group form-inline no-margin sideLabelAlignment">
      <label class="formLabel">13</label>
    </div>
  </td>
  <td colspan="12" width="75%">
    <div class="form-group form-inline no-margin sideLabelAlignment">
      <label class="formLabel"
        >To your knowledge, has information pertaining to you ever
        been reported to the National Practitioner Data Bank or
        Healthcare Integrity and Protection Data Bank?<span
        class="mandatory">*</span>
      </label>
    </div>
  </td>
  <td colspan="4" width="20%">
    <div class="form-group form-inline no-margin sideLabelAlignment">
      <label class="radio-inline radioStyle">
        <input
          type="radio"
          id="cmd_0244"
          [class.is-invalid]="
            (hap.controls['cmd_0244'].touched &&
              hap.controls['cmd_0244'].errors) ||
            (hap.controls['cmd_0244'].errors && isSubmit)
          "
          name="cmd_0244"
          formControlName="cmd_0244"
          value="1"
          
        />Yes</label
      >
      <label class="radio-inline radioStyle">
        <input
          type="radio"
          id="cmd_0244"
          [class.is-invalid]="
            (hap.controls['cmd_0244'].touched &&
              hap.controls['cmd_0244'].errors) ||
            (hap.controls['cmd_0244'].errors && isSubmit)
          "
          name="cmd_0244"
          formControlName="cmd_0244"
          value="0"
          
        />No</label
      >
      <div
        class="formLabel col-md-2"
        *ngIf="
          hap.controls['cmd_0244'].touched &&
          hap.controls['cmd_0244'].errors
        "
      >
        <small
          class="text-danger"
          *ngIf="hap.controls['cmd_0244'].errors?.required"
        >
          required
        </small>
      </div>
      <span
        *ngIf="
          (hap.controls['cmd_0244'].touched &&
            hap.controls['cmd_0244'].errors) ||
          (hap.controls['cmd_0244'].errors && isSubmit)
        "
      >
        <i class="fas fa-times ml-2 text-danger"></i>
      </span>
    </div>
  </td>
</tr>
<tr>
  <td colspan="2" width="5%">
    <div class="form-group form-inline no-margin sideLabelAlignment">
      <label class="formLabel">14</label>
    </div>
  </td>
  <td colspan="12" width="75%">
    <div class="form-group form-inline no-margin sideLabelAlignment">
      <label class="formLabel"
        >Have you ever received sanctions from or been the subject of
        investigation by any regulatory agencies (e.g., CLIA, OSHA,
        etc.)?<span
        class="mandatory">*</span>
      </label>
    </div>
  </td>
  <td colspan="4" width="20%">
    <div class="form-group form-inline no-margin sideLabelAlignment">
      <label class="radio-inline radioStyle">
        <input
          type="radio"
          id="cmd_0245"
          [class.is-invalid]="
            (hap.controls['cmd_0245'].touched &&
              hap.controls['cmd_0245'].errors) ||
            (hap.controls['cmd_0245'].errors && isSubmit)
          "
          name="cmd_0245"
          formControlName="cmd_0245"
          value="1"
          
        />Yes</label
      >
      <label class="radio-inline radioStyle">
        <input
          type="radio"
          id="cmd_0245"
          [class.is-invalid]="
            (hap.controls['cmd_0245'].touched &&
              hap.controls['cmd_0245'].errors) ||
            (hap.controls['cmd_0245'].errors && isSubmit)
          "
          name="cmd_0245"
          formControlName="cmd_0245"
          value="0"
          
        />No</label
      >
      <div
        class="formLabel col-md-2"
        *ngIf="
          hap.controls['cmd_0245'].touched &&
          hap.controls['cmd_0245'].errors
        "
      >
        <small
          class="text-danger"
          *ngIf="hap.controls['cmd_0245'].errors?.required"
        >
          required
        </small>
      </div>
      <span
        *ngIf="
          (hap.controls['cmd_0245'].touched &&
            hap.controls['cmd_0245'].errors) ||
          (hap.controls['cmd_0245'].errors && isSubmit)
        "
      >
        <i class="fas fa-times ml-2 text-danger"></i>
      </span>
    </div>
  </td>
</tr>
<tr>
  <td colspan="2" width="5%">
    <div class="form-group form-inline no-margin sideLabelAlignment">
      <label class="formLabel">15</label>
    </div>
  </td>
  <td colspan="12" width="75%">
    <div class="form-group form-inline no-margin sideLabelAlignment">
      <label class="formLabel"
        >Have you ever been investigated, sanctioned, reprimanded or
        cautioned by a military hospital,facility, or agency, or
        voluntarily terminated or resigned while under investigation
        by a hospital or healthcare facility of any military agency?<span
        class="mandatory">*</span>
      </label>
    </div>
  </td>
  <td colspan="4" width="20%">
    <div class="form-group form-inline no-margin sideLabelAlignment">
      <label class="radio-inline radioStyle">
        <input
          type="radio"
          id="cmd_0246"
          [class.is-invalid]="
            (hap.controls['cmd_0246'].touched &&
              hap.controls['cmd_0246'].errors) ||
            (hap.controls['cmd_0246'].errors && isSubmit)
          "
          name="cmd_0246"
          formControlName="cmd_0246"
          value="1"
          
        />Yes</label
      >
      <label class="radio-inline radioStyle">
        <input
          type="radio"
          id="cmd_0246"
          [class.is-invalid]="
            (hap.controls['cmd_0246'].touched &&
              hap.controls['cmd_0246'].errors) ||
            (hap.controls['cmd_0246'].errors && isSubmit)
          "
          name="cmd_0246"
          formControlName="cmd_0246"
          value="0"
          
        />No</label
      >
      <div
        class="formLabel col-md-2"
        *ngIf="
          hap.controls['cmd_0246'].touched &&
          hap.controls['cmd_0246'].errors
        "
      >
        <small
          class="text-danger"
          *ngIf="hap.controls['cmd_0246'].errors?.required"
        >
          required
        </small>
      </div>
      <span
        *ngIf="
          (hap.controls['cmd_0246'].touched &&
            hap.controls['cmd_0246'].errors) ||
          (hap.controls['cmd_0246'].errors && isSubmit)
        "
      >
        <i class="fas fa-times ml-2 text-danger"></i>
      </span>
    </div>
  </td>
</tr>
<tr>
  <td colspan="18" width="5%">
    <div class="form-group form-inline no-margin sideLabelAlignment">
      <label class="formLabel">Malpractice Claims History </label>
    </div>
  </td>
</tr>
<tr>
  <td colspan="2" width="5%">
    <div class="form-group form-inline no-margin sideLabelAlignment">
      <label class="formLabel">16</label>
    </div>
  </td>
  <td colspan="12" width="75%">
    <div class="form-group form-inline no-margin sideLabelAlignment">
      <label class="formLabel"
        >Have you had any malpractice actions within the past 5 years
        (pending, settled, arbitrated , mediated or litigated?<span
        class="mandatory">*</span>
      </label>
    </div>
  </td>
  <td colspan="4" width="20%">
    <div class="form-group form-inline no-margin sideLabelAlignment">
      <label class="radio-inline radioStyle">
        <input
          type="radio"
          id="cmd_0247"
          [class.is-invalid]="
            (hap.controls['cmd_0247'].touched &&
              hap.controls['cmd_0247'].errors) ||
            (hap.controls['cmd_0247'].errors && isSubmit)
          "
          name="cmd_0247"
          formControlName="cmd_0247"
          value="1"
          
        />Yes</label
      >
      <label class="radio-inline radioStyle">
        <input
          type="radio"
          id="cmd_0247"
          [class.is-invalid]="
            (hap.controls['cmd_0247'].touched &&
              hap.controls['cmd_0247'].errors) ||
            (hap.controls['cmd_0247'].errors && isSubmit)
          "
          name="cmd_0247"
          formControlName="cmd_0247"
          value="0"
          
        />No</label
      >
      <div
        class="formLabel col-md-2"
        *ngIf="
          hap.controls['cmd_0247'].touched &&
          hap.controls['cmd_0247'].errors
        "
      >
        <small
          class="text-danger"
          *ngIf="hap.controls['cmd_0247'].errors?.required"
        >
          required
        </small>
      </div>
      <span
        *ngIf="
          (hap.controls['cmd_0247'].touched &&
            hap.controls['cmd_0247'].errors) ||
          (hap.controls['cmd_0247'].errors && isSubmit)
        "
      >
        <i class="fas fa-times ml-2 text-danger"></i>
      </span>
    </div>
  </td>
</tr>
<tr>
  <td colspan="18" width="5%">
    <div class="form-group form-inline no-margin sideLabelAlignment">
      <label class="formLabel">Criminal </label>
    </div>
  </td>
</tr>
<tr>
  <td colspan="2" width="5%">
    <div class="form-group form-inline no-margin sideLabelAlignment">
      <label class="formLabel">17</label>
    </div>
  </td>
  <td colspan="12" width="75%">
    <div class="form-group form-inline no-margin sideLabelAlignment">
      <label class="formLabel"
        >Have you ever been convicted of, pled guilty to, or pled nolo
        contendere to any felony that is reasonably related to your
        qualifications, competence, functions, or duties as a medical
        professional<span
        class="mandatory">*</span>
      </label>
    </div>
  </td>
  <td colspan="4" width="20%">
    <div class="form-group form-inline no-margin sideLabelAlignment">
      <label class="radio-inline radioStyle">
        <input
          type="radio"
          id="cmd_0248"
          [class.is-invalid]="
            (hap.controls['cmd_0248'].touched &&
              hap.controls['cmd_0248'].invalid) ||
            (hap.controls['cmd_0248'].invalid && isSubmit)
          "
          name="cmd_0248"
          formControlName="cmd_0248"
          value="1"
          
        />Yes</label
      >
      <label class="radio-inline radioStyle">
        <input
          type="radio"
          id="cmd_0248"
          [class.is-invalid]="
            (hap.controls['cmd_0248'].touched &&
              hap.controls['cmd_0248'].invalid) ||
            (hap.controls['cmd_0248'].invalid && isSubmit)
          "
          name="cmd_0248"
          formControlName="cmd_0248"
          value="0"
          
        />No</label
      >
      <div
        class="formLabel col-md-2"
        *ngIf="
          hap.controls['cmd_0248'].touched &&
          hap.controls['cmd_0248'].invalid
        "
      >
        <small
          class="text-danger"
          *ngIf="hap.controls['cmd_0248'].errors?.required"
        >
          required
        </small>
      </div>
      <span
        *ngIf="
          (hap.controls['cmd_0248'].touched &&
            hap.controls['cmd_0248'].invalid) ||
          (hap.controls['cmd_0248'].invalid && isSubmit)
        "
      >
        <i class="fas fa-times ml-2 text-danger"></i>
      </span>
    </div>
  </td>
</tr>
<tr>
  <td colspan="2" width="5%">
    <div class="form-group form-inline no-margin sideLabelAlignment">
      <label class="formLabel">18</label>
    </div>
  </td>
  <td colspan="12" width="75%">
    <div class="form-group form-inline no-margin sideLabelAlignment">
      <label class="formLabel"
        >Have you ever been convicted of, pled guilty to, or pled nolo
        contendere to any felony including an act of violence, child
        abuse or a sexual offense?<span
        class="mandatory">*</span>
      </label>
    </div>
  </td>
  <td colspan="4" width="20%">
    <div class="form-group form-inline no-margin sideLabelAlignment">
      <label class="radio-inline radioStyle">
        <input
          type="radio"
          id="cmd_0249"
          [class.is-invalid]="
            (hap.controls['cmd_0249'].touched &&
              hap.controls['cmd_0249'].invalid) ||
            (hap.controls['cmd_0249'].invalid && isSubmit)
          "
          name="cmd_0249"
          formControlName="cmd_0249"
          value="1"
          
        />Yes</label
      >
      <label class="radio-inline radioStyle">
        <input
          type="radio"
          id="cmd_0249"
          [class.is-invalid]="
            (hap.controls['cmd_0249'].touched &&
              hap.controls['cmd_0249'].invalid) ||
            (hap.controls['cmd_0249'].invalid && isSubmit)
          "
          name="cmd_0249"
          formControlName="cmd_0249"
          value="0"

        />No</label
      >
      <div
        class="formLabel col-md-2"
        *ngIf="
          hap.controls['cmd_0249'].touched &&
          hap.controls['cmd_0249'].invalid
        "
      >
        <small
          class="text-danger"
          *ngIf="hap.controls['cmd_0249'].errors?.required"
        >
          required
        </small>
      </div>
      <span
        *ngIf="
          (hap.controls['cmd_0249'].touched &&
            hap.controls['cmd_0249'].invalid) ||
          (hap.controls['cmd_0249'].invalid && isSubmit)
        "
      >
        <i class="fas fa-times ml-2 text-danger"></i>
      </span>
    </div>
  </td>
</tr>
<tr>
  <td colspan="2" width="5%">
    <div class="form-group form-inline no-margin sideLabelAlignment">
      <label class="formLabel">19</label>
    </div>
  </td>
  <td colspan="12" width="75%">
    <div class="form-group form-inline no-margin sideLabelAlignment">
      <label class="formLabel"
        >Have you been court-martialed for actions related to your
        duties as a medical professional?<span
        class="mandatory">*</span>
      </label>
    </div>
  </td>
  <td colspan="4" width="20%">
    <div class="form-group form-inline no-margin sideLabelAlignment">
      <label class="radio-inline radioStyle">
        <input
          type="radio"
          id="cmd_0250"
          [class.is-invalid]="
            (hap.controls['cmd_0250'].touched &&
              hap.controls['cmd_0250'].invalid) ||
            (hap.controls['cmd_0250'].invalid && isSubmit)
          "
          name="cmd_0250"
          formControlName="cmd_0250"
          value="1"
          
        />Yes</label
      >

      <label class="radio-inline radioStyle">
        <input
          type="radio"
          id="cmd_0250"
          [class.is-invalid]="
            (hap.controls['cmd_0250'].touched &&
              hap.controls['cmd_0250'].invalid) ||
            (hap.controls['cmd_0250'].invalid && isSubmit)
          "
          name="cmd_0250"
          formControlName="cmd_0250"
          value="0"
          
        />No</label
      >
      <div
        class="formLabel col-md-2"
        *ngIf="
          hap.controls['cmd_0250'].touched &&
          hap.controls['cmd_0250'].invalid
        "
      >
        <small
          class="text-danger"
          *ngIf="hap.controls['cmd_0250'].errors?.required"
        >
          required
        </small>
      </div>
      <span
        *ngIf="
          (hap.controls['cmd_0250'].touched &&
            hap.controls['cmd_0250'].invalid) ||
          (hap.controls['cmd_0250'].invalid && isSubmit)
        "
      >
        <i class="fas fa-times ml-2 text-danger"></i>
      </span>
    </div>
  </td>
</tr>
<tr>
  <td colspan="18" width="5%">
    <div class="form-group form-inline no-margin sideLabelAlignment">
      <label class="formLabel">Ability to Perform Job </label>
    </div>
  </td>
</tr>
<tr>
  <td colspan="2" width="5%">
    <div class="form-group form-inline no-margin sideLabelAlignment">
      <label class="formLabel">20</label>
    </div>
  </td>
  <td colspan="12" width="75%">
    <div class="form-group form-inline no-margin sideLabelAlignment">
      <label class="formLabel"
        >Are you currently engaged in the illegal use of drugs?
        ("Currently" means sufficiently recent to justify a reasonable
        belief that the use of drug may have an ongoing impact on
        one's ability to pr actice medicine. It is not limited to the
        day of, or within a matter of days or weeks before the d ate
        of application, rather that it has occurred recently enough to
        indicate the individual is actively engaged in such conduct.
        "Illegal use of drugs" refers to drugs whose possession or
        distribution is unlawful under the Controlled Substances Act,
        21 U.S.C. § 812.22. It "does not include the use of a drug
        taken under supervision by a licensed health care
        professional, or other uses authorized by the Con trolled
        Substances Act or other provision of Federal law." The term
        does include, however, the unlawful use of prescription
        controlled substances.)<span
        class="mandatory">*</span>
      </label>
    </div>
  </td>
  <td colspan="4" width="20%">
    <div class="form-group form-inline no-margin sideLabelAlignment">
      <label class="radio-inline radioStyle">
        <input
          type="radio"
          id="cmd_0251"
          [class.is-invalid]="
            (hap.controls['cmd_0251'].touched &&
              hap.controls['cmd_0251'].invalid) ||
            (hap.controls['cmd_0251'].invalid && isSubmit)
          "
          name="cmd_0251"
          formControlName="cmd_0251"
          value="1"
          
        />Yes</label
      >
      <label class="radio-inline radioStyle">
        <input
          type="radio"
          id="cmd_0251"
          [class.is-invalid]="
            (hap.controls['cmd_0251'].touched &&
              hap.controls['cmd_0251'].invalid) ||
            (hap.controls['cmd_0251'].invalid && isSubmit)
          "
          name="cmd_0251"
          formControlName="cmd_0251"
          value="0"
          
        />No</label
      >
      <div
        class="formLabel col-md-2"
        *ngIf="
          hap.controls['cmd_0251'].touched &&
          hap.controls['cmd_0251'].invalid
        "
      >
        <small
          class="text-danger"
          *ngIf="hap.controls['cmd_0251'].errors?.required"
        >
          required
        </small>
      </div>
      <span
        *ngIf="
          (hap.controls['cmd_0251'].touched &&
            hap.controls['cmd_0251'].invalid) ||
          (hap.controls['cmd_0251'].invalid && isSubmit)
        "
      >
        <i class="fas fa-times ml-2 text-danger"></i>
      </span>
    </div>
  </td>
</tr>
<tr>
  <td colspan="2" width="5%">
    <div class="form-group form-inline no-margin sideLabelAlignment">
      <label class="formLabel">21</label>
    </div>
  </td>
  <td colspan="12" width="75%">
    <div class="form-group form-inline no-margin sideLabelAlignment">
      <label class="formLabel"
        >Do you use any chemical substances that would in any way
        impair or limit your ability to practice medicine and perform
        the functions of your job with reasonable skill and safety?<span
        class="mandatory">*</span>
      </label>
    </div>
  </td>
  <td colspan="4" width="20%">
    <div class="form-group form-inline no-margin sideLabelAlignment">
      <label class="radio-inline radioStyle">
        <input
          type="radio"
          id="cmd_0252"
          [class.is-invalid]="
            (hap.controls['cmd_0252'].touched &&
              hap.controls['cmd_0252'].invalid) ||
            (hap.controls['cmd_0252'].invalid && isSubmit)
          "
          name="cmd_0252"
          formControlName="cmd_0252"
          value="1"
          
        />Yes</label
      >
      <label class="radio-inline radioStyle">
        <input
          type="radio"
          id="cmd_0252"
          [class.is-invalid]="
            (hap.controls['cmd_0252'].touched &&
              hap.controls['cmd_0252'].invalid) ||
            (hap.controls['cmd_0252'].invalid && isSubmit)
          "
          name="cmd_0252"
          formControlName="cmd_0252"
          value="0"
          
        />No</label
      >
      <div
        class="formLabel col-md-2"
        *ngIf="
          hap.controls['cmd_0252'].touched &&
          hap.controls['cmd_0252'].invalid
        "
      >
        <small
          class="text-danger"
          *ngIf="hap.controls['cmd_0252'].errors?.required"
        >
          required
        </small>
      </div>
      <span
        *ngIf="
          (hap.controls['cmd_0252'].touched &&
            hap.controls['cmd_0252'].invalid) ||
          (hap.controls['cmd_0252'].invalid && isSubmit)
        "
      >
        <i class="fas fa-times ml-2 text-danger"></i>
      </span>
    </div>
  </td>
</tr>
<tr>
  <td colspan="18" width="5%">
    <div class="form-group form-inline no-margin sideLabelAlignment">
      <label class="formLabel">Ability to Perform Job </label>
    </div>
  </td>
</tr>
<tr>
  <td colspan="2" width="5%">
    <div class="form-group form-inline no-margin sideLabelAlignment">
      <label class="formLabel">22</label>
    </div>
  </td>
  <td colspan="12" width="75%">
    <div class="form-group form-inline no-margin sideLabelAlignment">
      <label class="formLabel"
        >Do you have any reason to believe that you would pose a risk
        to the safety or well-being of your patients?<span
        class="mandatory">*</span></label
      >
    </div>
  </td>
  <td colspan="4" width="20%">
    <div class="form-group form-inline no-margin sideLabelAlignment">
      <label class="radio-inline radioStyle">
        <input
          type="radio"
          id="cmd_0253"
          [class.is-invalid]="
            (hap.controls['cmd_0253'].touched &&
              hap.controls['cmd_0253'].invalid) ||
            (hap.controls['cmd_0253'].invalid && isSubmit)
          "
          name="cmd_0253"
          formControlName="cmd_0253"
          value="1"
          
        />Yes</label
      >
      <label class="radio-inline radioStyle">
        <input
          type="radio"
          id="cmd_0253"
          [class.is-invalid]="
            (hap.controls['cmd_0253'].touched &&
              hap.controls['cmd_0253'].invalid) ||
            (hap.controls['cmd_0253'].invalid && isSubmit)
          "
          name="cmd_0253"
          formControlName="cmd_0253"
          value="0"
          
        />No</label
      >
      <div
        class="formLabel col-md-2"
        *ngIf="
          hap.controls['cmd_0253'].touched &&
          hap.controls['cmd_0253'].invalid
        "
      >
        <small
          class="text-danger"
          *ngIf="hap.controls['cmd_0253'].errors?.required"
        >
          required
        </small>
      </div>
      <span
        *ngIf="
          (hap.controls['cmd_0253'].touched &&
            hap.controls['cmd_0253'].invalid) ||
          (hap.controls['cmd_0253'].invalid && isSubmit)
        "
      >
        <i class="fas fa-times ml-2 text-danger"></i>
      </span>
    </div>
  </td>
</tr>
<tr>
  <td colspan="2" width="5%">
    <div class="form-group form-inline no-margin sideLabelAlignment">
      <label class="formLabel">23</label>
    </div>
  </td>
  <td colspan="12" width="75%">
    <div class="form-group form-inline no-margin sideLabelAlignment">
      <label class="formLabel"
        >Are you unable to perform the essential functions of a
        practitioner in your area of practice, with or without
        reasonable accommodation?<span
        class="mandatory">*</span>
      </label>
    </div>
  </td>
  <td colspan="4" width="20%">
    <div class="form-group form-inline no-margin sideLabelAlignment">
      <label class="radio-inline radioStyle">
        <input
          type="radio"
          id="cmd_0254"
          [class.is-invalid]="
            (hap.controls['cmd_0254'].touched &&
              hap.controls['cmd_0254'].invalid) ||
            (hap.controls['cmd_0254'].invalid && isSubmit)
          "
          name="cmd_0254"
          formControlName="cmd_0254"
          value="1"
          
        />Yes</label
      >
      <label class="radio-inline radioStyle">
        <input
          type="radio"
          id="cmd_0254"
          [class.is-invalid]="
            (hap.controls['cmd_0254'].touched &&
              hap.controls['cmd_0254'].invalid) ||
            (hap.controls['cmd_0254'].invalid && isSubmit)
          "
          name="cmd_0254"
          formControlName="cmd_0254"
          value="0"
          
        />No</label
      >
      <div
        class="formLabel col-md-2"
        *ngIf="
          hap.controls['cmd_0254'].touched &&
          hap.controls['cmd_0254'].invalid
        "
      >
        <small
          class="text-danger"
          *ngIf="hap.controls['cmd_0254'].errors?.required"
        >
          required
        </small>
      </div>
      <span
        *ngIf="
          (hap.controls['cmd_0254'].touched &&
            hap.controls['cmd_0254'].invalid) ||
          (hap.controls['cmd_0254'].invalid && isSubmit)
        "
      >
        <i class="fas fa-times ml-2 text-danger"></i>
      </span>
    </div>
  </td>
</tr>



</table>
<label class="boldParagraph"
    >Please use the space below to explain yes answers to any question
    except #16.
  </label>
<hr class="tablePersonal" />
<h3 class="hThreeTableStyle">
  <b>Section III – Standard Authorization, Attestation and Release </b>
</h3>
(Not for Use for Employment Purposes) I understand and agree that, as
part of the credentialing application process for participation and⁄or
clinical privileges (hereinafter, referred to as “Participation”) at or
with
<table border="1" width="100%"></table>
<tr>
  <td colspan="18">
    <div class="form-group form-inline no-margin sideLabelAlignment">
      <label class="formLabel">
        (PLEASE INDICATE MANAGED CARE COMPANY(S) OR HOSPITAL(S) TO
        WHICH YOU ARE APPLYING) (HEREINAFTER, INDIVIDUALLY REFERRED TO
        AS THE “ENTITY”)
      </label>
      <input
        style="width: 70%"
        class="form-control"
        id="cmd_0307"
        name="cmd_0307"
        formControlName="cmd_0307"
        type="text"
        data-id="Text5.1.4"
      />
    </div>
  </td>
</tr>

<div >
<label
          >and any of the Entity’s affiliated entities, I am required to
          provide sufficient and accurate information for a proper
          evaluation of my current licensure, relevant training and⁄or
          experience, clinical competence, health status, character,
          ethics, and any other criteria used by the Entity for
          determining initial and ongoing eligibility for Participation.
          Each Entity and its representatives, employees, and agent(s)
          acknowledge that the information obtained relating to the
          application process will be held confidential to the extent
          permitted by law.
        </label>

</div>
<br>
<div>
  <label
  >I acknowledge that each Entity has its own criteria for
  acceptance, and I may be accepted or rejected by each
  independently. I further acknowledge and understand that my
  cooperation in obtaining information and my consent to the
  release of information do not guarantee that any Entity will
  grant me clinical privileges or contract with me as a provider
  of services. I understand that my application for Participation
  with the Entity is not an application for employment with the
  Entity and that acceptance of my application by the Entity will
  not result in my employment by the Entity.
</label>
</div>
<br>
<div>
  <label
          ><b> For Hospital Credentialing</b>. I consent to appear for an
          interview with the credentials committee, medical staff
          executive committee, or other representatives of the medical
          staff, hospital administration or the governing board, if
          required or requested. As a medical staff member, I pledge to
          provide continuous care for my patients. I have been informed of
          existing hospital by laws, rules and regulation,, and policies
          regarding the application process,and I agree that as a medical
          staff member, I will be bound by them.
        </label>
</div>
<br>
<div>
  <label
          >
          <b> Authorization of Investigation Concerning Application for
          Participation</b>.I authorize the following individuals including,
          without limitation, the Entity, its representatives, employees,
          and/or designated agent(s); the Entity’s affiliated entities and
          their representatives, employees, and/or designated agents; and
          the Entity’s designated professional credentials verification
          organization (collectively referred to as “Agents”), to
          investigate information, which includes both oral and written
          statements, records, and documents, concerning my application
          for Participation. I agree to allow the Entity and/or its
          Agent(s) to inspect all records and documents relating to such
          an investigation.
        </label>
</div>
<br>
<div>
  
  <label
  >
  <b> Authorization of Third-Party Sources to Release Information
  Concerning Application for Participation</b>. I authorize any third
  party, including, but not limited to, individuals, agencies,
  medical groups responsible for credentials verification,
  corporations, companies, employers, former employers, hospitals,
  health plans, health maintenance organizations, managed care
  organizations, law enforcement or licensing agencies, insurance
  companies, educational and other institutions, military
  services, medical credentialing and accreditation agencies,
  professional medical societies, the Federation of State Medical
  Boards, the National Practitioner Data Bank, and the Health Care
  Integrity and Protection Data Bank, to release to the Entity
  and/or its Agent(s), information, including otherwise privileged
  or confidential information, concerning my professional
  qualifications, credentials, clinical competence, quality
  assurance and utilization data, character, mental condition,
  physical condition, alcohol or chemical dependency diagnosis and
  treatment, ethics, behavior, or any other matter reasonably
  having a bearing on my qualifications for Participation in, or
  with, the Entity. I authorize my current and past professional
  liability carrier(s) to release my history of claims that have
  been made and/or are currently pending against me. I
  specifically waive written notice from any entities and
  individuals who provide information based upon this
  Authorization, Attestation and Release.</label>
</div>
<br>
<div>
  <label >
    <b> Authorization of Release and Exchange of Disciplinary
    Information</b>.I hereby further authorize any third party at which
    I currently have Participation or had Participation and/or each
    third party’s agents to release “Disciplinary Information,” as
    defined below, to the Entity and/or its Agent(s). I hereby
    further authorize the Agent(s) to release Disciplinary
    Information about any disciplinary action taken against me to
    its participating Entities at which I have Participation, and as
    may be otherwise required by law. As used herein, “Disciplinary
    Information” means information concerning: (I) any action taken
    by such health care organizations, their administrators, or
    their medical or other committees to revoke, deny, suspend,
    restrict, or condition my Participation or impose a corrective
    action plan; (ii) any other disciplinary action involving me,
    including, but not limited to, discipline in the employment
    context; or (iii) my resignation prior to the conclusion of any
    disciplinary proceedings or prior to the commencement of formal
    charges, but after I have knowledge that such formal charges
    were being (or are being) contemplated and/or were (or are) in
    preparation.
  </label>
</div>
<br>
<div>
  <label >
   <b>  Release from Liability</b>.I release from all liability and hold
    harmless any Entity, its Agent(s), and any other third party for
    their acts performed in good faith and without malice unless
    such acts are due to the gross negligence or willful misconduct
    of the Entity, its Agent(s), or other third party in connection
    with the gathering, release and exchange of, and reliance upon,
    information used in accordance with this Authorization,
    Attestation and Release. I further agree not to sue any Entity,
    any Agent(s), or any other third party for their acts,
    defamation or any other claims based on statements made in good
    faith and without malice or misconduct of such Entity, Agent(s)
    or third party in connection with the credentialing process.
    This release shall be in addition to, and in no way shall limit,
    any other applicable immunities provided by law for peer review
    and credentialing activities.
  </label>
</div>
<hr class="tablePersonal" />
<h3 class="texasHThree">
  <b>Section III – Standard Authorization, Attestation and Release</b> –
  continued
</h3>
<div>
  <label
  >In this Authorization, Attestation and Release, all references
  to the Entity, its Agent(s), and⁄or other third party include
  their respective employees, directors, officers, advisors,
  counsel, and agents. The Entity or any of its affiliates or
  agents retains the right to allow access to the application
  information for purposes of a credentialing audit to customers
  and⁄or their auditors to the extent required in connection with
  an audit of the credentialing processes and provided that the
  customer and⁄or their auditor executes an appropriate
  confidentiality agreement. I understand and agree that this
  Authorization, Attestation and Release is irrevocable for any
  period during which I am an applicant for Participation at an
  Entity, a member of an Entity’s medical or health care staff, or
  a participating provider of an Entity. I agree to execute
  another form of consent if law or regulation limits the
  application of this irrevocable authorization. I understand that
  my failure to promptly provide another consent may be grounds
  for termination or discipline by the Entity in accordance with
  the applicable bylaws, rules, and regulations, and requirements
  of the Entity, or grounds for my termination of Participation at
  or with the Entity. I agree that information obtained in
  accordance with the provisions of this Authorization,
  Attestation and Release is not and will not be a violation of my
  privacy.
</label>
</div>
<br>
<div>
  <label 
  >I certify that all information provided by me in my application
  is true, correct, and complete to the best of my knowledge and
  belief, and that I will notify the Entity and⁄or its Agent(s)
  within 10 days of any material changes to the information I have
  provided in my application or authorized to be released pursuant
  to the credentialing process. I understand that corrections to
  the application are permitted at any time prior to a
  determination of Participation by the Entity, and must be
  submitted on-line or in writing, and must be dated and signed by
  me (may be a written or an electronic signature). I understand
  and agree that any material misstatement or omission in the
  application may constitute grounds for withdrawal of the
  application from consideration; denial or revocation of
  Participation; and⁄or immediate suspension or termination of
  Participation. This action may be disclosed to the Entity and⁄or
  its Agent(s).
</label>
</div><br>
<div>
  <label
          >I further acknowledge that I have read and understand the
          foregoing Authorization, Attestation and Release. I understand
          and agree that a facsimile or photocopy of this Authorization,
          Attestation and Release shall be as effective as the original.
        </label>
</div><br>
<label class="boldForImage">SIGNATURE<span
  class="mandatory">*</span></label>
        <div class="col-md-3 signaturePadding">
          <img
            *ngIf="!showOne"
            class="img"
            src="./assets/images/e_sign.png"
            alt="Credential my Doc"
            (click)="esignOpenOne()"
          />
          <img
            *ngIf="showOne"
            class="img eSignImageShow"
            [src]="esignOne"
            alt="Credential my Doc"
            (click)="esignOpenOne()"
          />
          <input
            type="hidden"
            name="cmd_11122"
            id="cmd_11122"
            formControlName="cmd_11122"
            [class.is-invalid]="(hap.controls['cmd_11122'].touched && hap.controls['cmd_11122'].invalid) || (hap.controls['cmd_11122'].invalid && isSubmit)"
            value="{{ finalImgPath }}"
          />
          <div class="invalid-feedback"
              *ngIf="(hap.controls['cmd_11122'].touched && hap.controls['cmd_11122'].invalid) || (hap.controls['cmd_11122'].invalid && isSubmit)">
                  Signature is required and cannot be empty
          </div>    
        </div>
        <div class="form-group form-inline no-margin sideLabelAlignment">
          <label class="formLabel"
            >NAME<span
            class="mandatory">*</span>
          </label>
          <input
          style="width: 300px"
          class="form-control"
          [class.is-invalid]="
          (hap.controls['cmd_0897'].touched &&
            hap.controls['cmd_0897'].invalid) ||
          (hap.controls['cmd_0897'].invalid && isSubmit)
        "
          id="cmd_0897"
          name="cmd_0897"
          formControlName="cmd_0897"
          type="text"
          data-id="full_name"
          placeholder="Printed name"
        />
        <div
                  class="formLabel col-md-2"
                  *ngIf="
                    hap.controls['cmd_0897'].touched &&
                    hap.controls['cmd_0897'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="hap.controls['cmd_0897'].errors?.required"
                  >
                    required
                  </small>
                </div>
        </div>  
        <div class="form-group form-inline no-margin sideLabelAlignment">
          <label class="formLabel"
            >Last 4 digits of SSN or NPI (PLEASE PRINT OR TYPE)<span
            class="mandatory">*</span>
          </label>
          <input
          style="width: 300px"
          class="form-control"
          [class.is-invalid]="
          (hap.controls['cmd_0779'].touched &&
            hap.controls['cmd_0779'].invalid) ||
          (hap.controls['cmd_0779'].invalid && isSubmit)
        "
          id="cmd_0779"
          name="cmd_0779"
          formControlName="cmd_0779"
          placeholder="last 4 of ssn"
          type="text"
          data-id="NPI"
        />
        
        <div
            class="formLabel col-md-2"
                  *ngIf="
                    hap.controls['cmd_0779'].touched &&
                    hap.controls['cmd_0779'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="hap.controls['cmd_0779'].errors?.required"
                  >
                    required
                  </small>
                </div>
        </div>
        <div class="form-inline ">
          <label class="formLabel"
            >DATE (MM⁄DD⁄ YYYY)<span
            class="mandatory">*</span>
          </label>
          <div class="input-group"> 
          <input
          style="width: 300px"
          class="form-control onboard datepicker"
          [class.is-invalid]="
          (hap.controls['cmd_0310'].touched &&
            hap.controls['cmd_0310'].invalid) ||
          (hap.controls['cmd_0310'].invalid && isSubmit)
        "
          ngbDatepicker
          #d63="ngbDatepicker"
          id="cmd_0310"
          name="cmd_0310"
          formControlName="cmd_0310"
          type="text"
          data-id="Text5.1.2"
        />
        <div class="input-group-append">
          <button
            class="btn btn-outline-secondary calendar"
            (click)="d63.toggle()"
            type="button"
          >
            <i class="fas fa-calendar-alt"></i>
          </button>
        </div>
      </div>
        <div
        class="formLabel col-md-2"
              *ngIf="
                hap.controls['cmd_0310'].touched &&
                hap.controls['cmd_0310'].invalid
              "
            >
              <small
                class="text-danger"
                *ngIf="hap.controls['cmd_0310'].errors?.required"
              >
                required
              </small>
            </div>
        </div>  

        





</form>    
   
  </div>   


<div class="modal-footer mt-4">
<button
*ngIf="formData.receivedForm"
type="button"
class="btn customOrange float-right"
(click)="saveForm('approve')"
>
Approve
</button>
<button
*ngIf="formData.submitForm"
type="button"
class="btn btn customOrange float-right"
(click)="saveForm('submit')"
>
Submit
</button>
<button
*ngIf="formData.submitForm"
type="button"
class="btn btn customOrange float-right"
(click)="saveForm('save')"
>
Save
</button>
<button
*ngIf="formData.previewForm"
type="button"
class="btn customOrange float-right"
(click)="close()"
>
Close
</button>
</div>

</div>
