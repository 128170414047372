import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, FormControl, ValidationErrors } from '@angular/forms';
import { OnboardingformsServiceService } from '../../services/onboardingforms-service/onboardingforms-service.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToasterNotiService } from '../../services/notifications/toaster-noti.service';
import {
  NgbModal,
  NgbModalOptions,
  NgbDateAdapter,
  NgbDateParserFormatter
} from '@ng-bootstrap/ng-bootstrap';
import { EsignComponent } from '../esign/esign.component';
import { DomSanitizer } from '@angular/platform-browser';
import {
  CustomAdapter,
  CustomDateParserFormatter
} from '../../DateFormatter/customDate';

@Component({
  selector: 'ctg-ga-form',
  templateUrl: './ga-form.component.html',
  styleUrls: ['./ga-form.component.scss'],
  providers: [
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }
  ]
})
export class GaFormComponent implements OnInit {
  @Input() formData;
  @Input() fieldsData;
  @Input() formId;
  @Output() onSubmit = new EventEmitter();
  @Output() onClose = new EventEmitter();
  public gaForm: UntypedFormGroup;
  modalOption: NgbModalOptions = {
    size: 'md',
    keyboard: false,
    backdrop: 'static'
  };
  suffix: Array<object> = [];
  degree: Array<object> = [];
  states: Array<object> = [];
  specialty: Array<object> = [];
  specialtySecondary: Array<object> = [];
  languages: Array<object> = [];
  secondaryLanguages: Array<object> = [];
  tertiaryLanguages: Array<object> = [];
  altLicenseState: Array<object> = [];
  additionalLicenseState: Array<object> = [];
  quaternaryLanguages: Array<object> = [];
  boardCertification: Array<object> = [];
  preClaim: Array<object> = [];
  aggregates: Array<object> = [];
  id: '';
  finalImgPath: any = [];
  finalImg: any;
  finalImgPathTwo: any = [];
  finalImgTwo: any = '';
  finalImgPathThree: any = [];
  finalImgThree: any = '';
  finalImgPathFour: any = [];
  finalImgFour: any = '';
  esignOne: any;
  esignTwo: any;
  esignThree: any;
  esignFour: any;
  showOne: any = false;
  showTwo: any = false;
  showThree: any = false;
  showFour: any = false;
  isSubmit: boolean = false;
  public mlpDoesNotApply: boolean = false;
  public pliDoesnot: boolean = false;
  public peerDoesnot: boolean = false;
  public otherFacilityDoesnot: boolean = false;
  public preHospDoesnot: boolean = false;
  public hospInprocessDoesnot: boolean = false;
  public otherStateCareDoesnot: boolean = false;
  public militaryHealthDoesnot: boolean = false;
  public facultyPositionsDoesnot: boolean = false;
  public otherClinicalDoesnot: boolean = false;
  public fellowshipDoesnot: boolean = false;
  public residencyDoesnot: boolean = false;
  public intersnipDoesnot: boolean = false;
  public ForeignMedicalDoesnot: boolean = false;
  public MedicalProfessionalDoesnot: boolean = false;
  public graduateDegreeDoesnot: boolean = false;
  public ExplanInterruptionDoesnot: boolean = false;
  public boardCertiDoesnot: boolean = false;
  public drugenforcementDoesnot: boolean = false;
  public proworkhistDoesnot: boolean = false;
  ImgOne: any;
  imgOneSet: any;
  imgTwoSet: any;
  imgOneSetting: any;
  private emailPattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  constructor(
    private fb: UntypedFormBuilder,
    private onboardService: OnboardingformsServiceService,
    private spinner: NgxSpinnerService,
    private notiService: ToasterNotiService,
    private modalService: NgbModal,
    private _sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.buildForm();
    this.getSuffixData();
    this.getDegreeData();
    this.getStatesData();
    this.getAlternateLicenseStateData();
    this.getSpecialtyData();
    this.getAdditionalLicenseStateData();
    this.getSpecialtySecondaryData();
    this.getLanguagesData();
    this.getsecondaryLanguagesData();
    this.gettertiaryLanguagesData();
    this.getquaternaryLanguagesData();
    this.getBoardCertificationData();
    this.getPreClaimData();
    this.getAggregatesData();
    this.fieldsData && this.bindFormValue(this.fieldsData);
    this.afterSaveDataDisplay();
    
  }

  public bindFormValue(array) {
    let fieldsCtrls = {};
    for (let i = 0; i < array.length; i++) {
      fieldsCtrls[array[i].field_name] = array[i].field_value;
      if (array[i].field_name == 'cmd_0991') {
        if (array[i].field_value != '') {
          this.previewEsign(array[i].field_value, array[i].field_name);
        }
      }
      if (array[i].field_name == 'cmd_0992') {
        if (array[i].field_value != '') {
          this.previewEsign(array[i].field_value, array[i].field_name);
        }
      }
      if (array[i].field_name == 'cmd_0993') {
        if (array[i].field_value != '') {
          this.previewEsign(array[i].field_value, array[i].field_name);
        }
      }
      if (array[i].field_name == 'cmd_100072') {
        if (array[i].field_value != '') {
          this.previewEsign(array[i].field_value, array[i].field_name);
        }
      }
    }
    this.gaForm.patchValue(fieldsCtrls);
  }

  public previewEsign(filePath, fieldId) {
    //this.spinner.show();
    let params = {
      filePath: filePath
    };
    this.onboardService.getPreviewEsign(params).subscribe(
      (data: any) => {
        if (data['success']) {
          this.ImgOne = data.base64;
          this.setEsign(this.ImgOne, fieldId);
        } else {
          //this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public setEsign(filePath, fieldId) {
    this.imgOneSetting = 1;
    // console.log(filePath);
    if (fieldId == 'cmd_0991') {
      this.esignOne = this._sanitizer.bypassSecurityTrustResourceUrl(
        'data:image/jpg;base64,' + filePath
      );
      this.showOne = true;
    }
    if (fieldId == 'cmd_0992') {
      this.esignTwo = this._sanitizer.bypassSecurityTrustResourceUrl(
        'data:image/jpg;base64,' + filePath
      );
      this.showTwo = true;
    }
    if (fieldId == 'cmd_0993') {
      this.esignThree = this._sanitizer.bypassSecurityTrustResourceUrl(
        'data:image/jpg;base64,' + filePath
      );
      this.showThree = true;
    }
    if (fieldId == 'cmd_100072') {
      this.esignFour = this._sanitizer.bypassSecurityTrustResourceUrl(
        'data:image/jpg;base64,' + filePath
      );
      this.showFour = true;
    }
  }

  public afterSaveDataDisplay() {
    let params = {
      type: 12,
      formId: this.formId
    };
    //this.spinner.show();
    this.onboardService.toGetSaveData(params).subscribe(
      data => {
        if (data['success']) {
          this.bindFormValue(data.data);
        } else {
          //this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.notiService.showError(Error_Message, '', 3000);
      }
    );
  }

  buildForm() {
    this.gaForm = this.fb.group({
      cmd_0171: [''],
      cmd_172: [''],
      cmd_100072: [''],
      8: ['', [Validators.required]],
      9: ['', [Validators.required]],
      29: [null],
      809: [''],
      822: [null, [Validators.required]],
      cmd_180: [''],
      278: [''],
      280: [null,[this.dateFormatValidator]],
      281: [null,[this.dateFormatValidator]],
      16: ['', [Validators.required]],
      18: ['', [Validators.required]],
      20: [null, [Validators.required]],
      21: [
        '',
        [Validators.required, Validators.minLength(5), Validators.maxLength(10)]
      ],
      708: [''],
      759: ['', [Validators.required]],
      292: [''],
      31: [null, [Validators.required,this.dateFormatValidator]],
      810: ['', [Validators.required]],
      289: [null, [Validators.required]],
      cmd_01: [''],
      32: ['', [Validators.required]],
      819: [''],
      1634: [''],
      325: [''],
      812: [''],
      814: [''],
      293: ['', [Validators.required]],
      295: [null, [Validators.required,this.dateFormatValidator]],
      309: [''],
      311: [null, [Validators.required,this.dateFormatValidator]],
      cmd_02: [''],
      cmd_03:  [null,[this.dateFormatValidator] ],
      cmd_04: [''],
      33: [''],
      284: [null],
      962: [null],
      277: [null],
      1535: [null],
      1536: [null],
      cmd_0193: [''],
      cmd_0190: [''],
      554: [null],
      556: [null],
      557: [null, [this.dateFormatValidator]],
      cmd_012: ['', [Validators.required]],
      cmd_013: ['', [Validators.required]],
      cmd_014: [null,[this.dateFormatValidator] ],
      cmd_015: ['', [Validators.required]],
      cmd_016: ['', [Validators.required]],
      1377: [null,[this.dateFormatValidator] ],
      cmd_017: [null,[this.dateFormatValidator] ],
      cmd_018: ['', [Validators.required]],
      cmd_019: ['', [Validators.required]],
      503: ['', [Validators.required]],
      558: [null,[this.dateFormatValidator] ],
      559:  [null,[this.dateFormatValidator] ],
      560:[null, [this.dateFormatValidator]],
      561: [null],
      563: [null],
      564:[null,[this.dateFormatValidator] ],
      565:[null,[this.dateFormatValidator] ],
      566:[null,[this.dateFormatValidator] ],
      567:[null,[this.dateFormatValidator] ],
      946: [null],
      948: [null],
      953: [null,[this.dateFormatValidator] ],
      950: [null,[this.dateFormatValidator] ],
      952:[null,[this.dateFormatValidator] ],
      954:[null,[this.dateFormatValidator] ],
      512: [null, [Validators.required]],
      511: [null, [Validators.required,this.dateFormatValidator]],
      506: ['', [Validators.required]],
      513: ['', [Validators.required]],
      508: [null, [Validators.required]],
      cmd_020: [''],
      479: [''],
      480: [null],
      485: [''],
      482: [null,[this.dateFormatValidator] ],
      487: [null],
      489: [''],
      cmd_0191: [''],
      490: [''],
      491: [''],
      493: [''],
      495: [null],
      496: ['', [Validators.minLength(5), Validators.maxLength(10)]],
      497: [null,[this.dateFormatValidator]],
      498: [null,[this.dateFormatValidator]],
      499: [null,[this.dateFormatValidator]],
      500: [null],
      cmd_021: [''],
      cmd_022: [''],
      514: [''],
      515: [null,[this.dateFormatValidator]],
      cmd_023: [''],
      cmd_024:[null,[this.dateFormatValidator]],
      cmd_026: [''],
      517: [''],
      518: [''],
      520: [''],
      522: [null],
      523: [''],
      524:[null,[this.dateFormatValidator]],
      525:[null,[this.dateFormatValidator]],
      526:[null,[this.dateFormatValidator]],
      527: [null],
      528: [''],
      cmd_027: [''],
      cmd_025: [''],
      529: [''],
      530: [null],
      531: [''],
      533: [''],
      535: [null],
      536: [''],
      537: [null,[this.dateFormatValidator]],
      538: [null,[this.dateFormatValidator]],
      539:  [null,[this.dateFormatValidator]],
      540: [''],
      cmd_028: [''],
      cmd_029: [''],
      542: [''],
      543: [null],
      544: [''],
      546: [''],
      548: [null],
      549: [''],
      550: [null,[this.dateFormatValidator]],
      551:[null,[this.dateFormatValidator]],
      552:[null,[this.dateFormatValidator]],
      553: [''],
      cmd_030: [''],
      cmd_0192: [''],
      1288: [''],
      1628: [null],
      1291: [''],
      1293: [null],
      1294: [''],
      1295: [null,[this.dateFormatValidator]],
      1296:[null,[this.dateFormatValidator]],
      1297:[null,[this.dateFormatValidator]],
      1298: [null],
      1299: [null],
      cmd_031: [''],
      cmd_032: [''],
      cmd_033: [''],
      cmd_034: [''],
      cmd_035: [''],
      cmd_036: [''],
      cmd_037: [null,[this.dateFormatValidator]],
      cmd_038:  [null,[this.dateFormatValidator]],
      cmd_039:  [null,[this.dateFormatValidator]],
      cmd_040: [''],
      cmd_041: [''],
      cmd_042: [''],
      cmd_043: [''],
      1147: [null],
      1154: [''],
      1146: [''],
      1150: [''],
      1152: [null],
      1153: [''],
      1155: [null,[this.dateFormatValidator]],
      1156:  [null,[this.dateFormatValidator]],
      1158: [null],
      1165: [''],
      1157: [''],
      1161: [''],
      1163: [null],
      1164: [''],
      1166:  [null,[this.dateFormatValidator]],
      1167: [null,[this.dateFormatValidator]],
      cmd_044: [''],
      1139: [''],
      1140: [''],
      1141: [''],
      1144:  [null,[this.dateFormatValidator]],
      1145: [null,[this.dateFormatValidator]],
      cmd_045: [''],
      cmd_046: [''],
      cmd_047: [''],
      cmd_048: [''],
      1274: [''],
      300: [''],
      907: [null],
      308:[null,[this.dateFormatValidator]],
      307: [null,[this.dateFormatValidator]],
      3690: [null,[this.dateFormatValidator]],
      cmd_049: [''],
      cmd_06: [''],
      1432: [''],
      999: [''],
      1010: [null],
      1004:[null,[this.dateFormatValidator]],
      1003: [null,[this.dateFormatValidator]],
      cmd_051: [''],
      418: ['', [Validators.required]],
      425: ['', [Validators.required]],
      426: [null,[Validators.required,this.dateFormatValidator]],
      419: ['', [Validators.required]],
      421: ['', [Validators.required]],
      423: [null, [Validators.required]],
      424: ['', [Validators.required]],
      427: [''],
      434: [''],
      435:  [null,[this.dateFormatValidator]],
      428: [''],
      432: [null],
      433: [''],
      436: [''],
      443: [''],
      444:  [null,[this.dateFormatValidator]],
      437: [''],
      439: [''],
      441: [null],
      442: [''],
      986: [''],
      993: [''],
      994: [null,[this.dateFormatValidator]],
      987: [''],
      989: [''],
      991: [null],
      992: [''],
      cmd_053: [''],
      cmd_054: [''],
      cmd_055: [''],
      cmd_056: [null,[this.dateFormatValidator]],
      cmd_057: [''],
      cmd_058: [''],
      cmd_059: [null],
      cmd_060: [''],
      cmd_061: [''],
      cmd_062: [''],
      cmd_063:[null,[this.dateFormatValidator]],
      cmd_064: [''],
      cmd_065: [''],
      cmd_066: [null],
      cmd_067: [''],
      cmd_068: [''],
      cmd_069: [''],
      cmd_070:[null,[this.dateFormatValidator]],
      cmd_071: [''],
      cmd_072: [''],
      cmd_073: [null],
      cmd_074: [''],
      cmd_075: [''],
      2025: [''],
      2042: [null,[this.dateFormatValidator]],
      2044:[null,[this.dateFormatValidator]],
      1303: [''],
      1184: [''],
      1186: [''],
      1188: [null],
      1189: [''],
      2046: [''],
      2057:[null,[this.dateFormatValidator]],
      2059:[null,[this.dateFormatValidator]],
      cmd_076: [''],
      2047: [''],
      2049: [''],
      2051: [null],
      2052: [''],
      cmd_077: [''],
      2061: [''],
      2072:[null,[this.dateFormatValidator]],
      2074:[null,[this.dateFormatValidator]],
      cmd_078: [''],
      2062: [''],
      2064: [''],
      2066: [null],
      2081: [null],
      2067: [''],
      2076: [''],
      2087: [null,[this.dateFormatValidator]],
      2089: [null,[this.dateFormatValidator]],
      cmd_079: [''],
      2077: [''],
      2079: [''],
      2082: [''],
      445: ['', [Validators.required]],
      452: ['', [Validators.required]],
      454:[null,[Validators.required,this.dateFormatValidator]],
      455:[null,[Validators.required,this.dateFormatValidator]],
      cmd_0206: [''],
      447: ['', [Validators.required]],
      446: ['', [Validators.required]],
      448: ['', [Validators.required]],
      450: [null, [Validators.required]],
      451: ['', [Validators.required]],
      456: [''],
      463: [''],
      464: [''],
      465: [null,[this.dateFormatValidator]],
      466: [null,[this.dateFormatValidator]],
      457: [''],
      458: [''],
      459: [''],
      461: [null],
      462: [''],
      467: [''],
      474: [''],
      475: [''],
      476: [null,[this.dateFormatValidator]],
      477: [null,[this.dateFormatValidator]],
      468: [''],
      469: [''],
      470: [''],
      472: [null],
      473: [''],
      1138: [''],
      cmd_081: [''],
      cmd_082: [null,[this.dateFormatValidator]],
      cmd_083: [null,[this.dateFormatValidator]],
      1831: [''],
      1832: [''],
      cmd_084:[null,[this.dateFormatValidator]],
      cmd_085:[null,[this.dateFormatValidator]],
      cmd_086: [null,[this.dateFormatValidator]],
      cmd_087:[null,[this.dateFormatValidator]],
      359: ['', [Validators.required]],
      368: [null, [Validators.required]],
      366:[null,[Validators.required,this.dateFormatValidator]],
      367: [null,[Validators.required,this.dateFormatValidator]],
      369: ['', [Validators.required]],
      370: [''],
      371: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
      360: ['', [Validators.required]],
      362: ['', [Validators.required]],
      365: ['', [Validators.required]],
      364: [null, [Validators.required]],
      380: [null],
      372: [''],
      817: [null,[this.dateFormatValidator]],
      379: [null,[this.dateFormatValidator]],
      381: [''],
      382: [''],
      383: [''],
      373: [''],
      375: [''],
      377: [null],
      378: [''],
      384: [''],
      393: [null],
      391:[null,[this.dateFormatValidator]],
      392:[null,[this.dateFormatValidator]],
      394: [''],
      395: [''],
      389: [null],
      396: [''],
      385: [''],
      387: [''],
      390: [''],
      453: ['', [Validators.required]],
      430: [''],
      cmd_0201: [''],
      345: [''],
      346: [''],
      cmd_088: [''],
      1240: [''],
      901: [''],
      903: [''],
      906: [''],
      905: [null],
      348:[null,[this.dateFormatValidator]],
      349:[null,[this.dateFormatValidator]],
      cmd_089: [''],
      945: [null,[this.dateFormatValidator]],
      cmd_090: [''],
      1242: [''],
      1256: [''],
      cmd_092: [''],
      1243: [''],
      1254: [''],
      1246: [''],
      1249: [''],
      1252:[null,[this.dateFormatValidator]],
      1251: [null,[this.dateFormatValidator]],
      1253: [null,[this.dateFormatValidator]],
      cmd_093: [''],
      cmd_094: [''],
      cmd_095: [''],
      cmd_096: [''],
      cmd_097: [''],
      cmd_098: [null],
      cmd_099: [null],
      cmd_0100: [''],
      cmd_0101: [''],
      cmd_0102: [null],
      cmd_0103: [''],
      cmd_0104: [null,[this.dateFormatValidator]],
      cmd_0105:[null,[this.dateFormatValidator]],
      cmd_0106: [null,[this.dateFormatValidator]],
      cmd_0107: [''],
      cmd_0108: [''],
      cmd_0109: [''],
      cmd_0110: [''],
      cmd_0111: [''],
      cmd_0112: [''],
      350: [null],
      351: [null],
      1265: [null],
      1264: [null],
      1245: [''],
      1248: [null],
      1266: [''],
      cmd_0113: ['', [Validators.required]],
      cmd_0114: ['', [Validators.required]],
      cmd_0115: ['', [Validators.required]],
      cmd_0116: ['', [Validators.required]],
      cmd_0117: ['', [Validators.required]],
      cmd_0118: ['', [Validators.required]],
      cmd_0119: ['', [Validators.required]],
      cmd_0120: ['', [Validators.required]],
      cmd_0121: ['', [Validators.required]],
      cmd_0122: ['', [Validators.required]],
      cmd_0123: ['', [Validators.required]],
      cmd_0124: ['', [Validators.required]],
      cmd_0125: ['', [Validators.required]],
      cmd_0126: ['', [Validators.required]],
      cmd_0127: ['', [Validators.required]],
      cmd_0128: ['', [Validators.required]],
      cmd_0129: ['', [Validators.required]],
      cmd_0130: ['', [Validators.required]],
      cmd_0131: ['', [Validators.required]],
      cmd_0991: [''],
      cmd_0132: [''],
      cmd_0200:[null,[this.dateFormatValidator]],
      cmd_0133: [''],
      cmd_0198: [''],
      cmd_0134: [''],
      cmd_0135: [''],
      cmd_0136: [''],
      cmd_0137: [''],
      cmd_0138: [''],
      cmd_0139: [''],
      cmd_0140: [''],
      cmd_0141: [''],
      cmd_0142: [''],
      cmd_0143: [''],
      cmd_0144: [''],
      cmd_0145: [''],
      cmd_0146: [''],
      cmd_0147: [''],
      cmd_0148: [''],
      cmd_0149: [''],
      cmd_0150: [''],
      cmd_0151: [''],
      cmd_0152: [''],
      cmd_0153: [''],
      cmd_0154: [''],
      cmd_0155: [''],
      cmd_0156: [''],
      cmd_0195: [null,[this.dateFormatValidator]],
      cmd_0157: [''],
      cmd_0158: [''],
      cmd_0159: [''],
      cmd_160: [''],
      cmd_0161: [''],
      1205: [''],
      1206: [''],
      1207: [''],
      1208: [''],
      1209: [''],
      1210: [''],
      cmd_0162: [''],
      cmd_0163: [''],
      cmd_0164: [''],
      1213: [''],
      1214: [''],
      1216: [''],
      1217: [''],
      1218: [''],
      cmd_0165: [''],
      1219: [null,[this.dateFormatValidator]],
      1275: [''],
      1220: [null,[this.dateFormatValidator]],
      cmd_0166: [''],
      cmd_0167: [''],
      1222: [null,[this.dateFormatValidator]],
      cmd_0168: [''],
      1223:[null,[this.dateFormatValidator]],
      1226: [null,[this.dateFormatValidator]],
      1228: [''],
      1229: [''],
      cmd_0169: [''],
      1227:  [null,[this.dateFormatValidator]],
      1231: [''],
      1232: [''],
      cmd_0196:[null,[this.dateFormatValidator]],
      cmd_0993: [''],
      cmd_0994: [''],
      cmd_0170: [''],
      cmd_0199:[null,[this.dateFormatValidator]],
      cmd_0992: [''],
      397: [''],
      399: [''],
      398: [''],
      408: [''],
      409: [''],
      414: [''],
      415: ['']
    });
  }

  public getSuffixData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getSuffixData(params).subscribe(
      data => {
        if (data['success']) {
          this.suffix = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }
  setErrorControl(name){
    this.gaForm.controls[name].setErrors({ errors: true });
  }
  removeErrorControl(name){
    this.gaForm.controls[name].setErrors(null)
  }
  handleNAchange(cName,eName?){
    let result:boolean=false;
    if(cName=='cmd_090'){
      let x=[1242,1256,1243,1254,1264,1265,1245,1246,1248,1249,1252,1251,1253]
      x.forEach(element => {
        const ctrl = this.gaForm.controls[element];
          if ((ctrl.value === '' || ctrl.value === null) && !this.gaForm.controls[cName].value) {
            this.setErrorControl(element);
            result = true;
          } else {
            this.removeErrorControl(element);
            ctrl.setErrors(null);
          }
      });
    } 
    if(cName=='cmd_0193'){
      let x=[557,560,554,556]
      x.forEach(element => {
        const ctrl = this.gaForm.controls[element];
          if ((ctrl.value === '' || ctrl.value === null) && !this.gaForm.controls[cName].value) {
            this.setErrorControl(element);
            result = true;
          }  else {
            this.removeErrorControl(element);
            ctrl.setErrors(null);
          }
      });
    } 
    if(cName=='cmd_0191'){
      let x=[497,498,499,490,493,495,496,500]
      x.forEach(element => {
        const ctrl = this.gaForm.controls[element];
          if ((ctrl.value === '' || ctrl.value === null) && !this.gaForm.controls[cName].value) {
            this.setErrorControl(element);
            result = true;
          }  else {
            this.removeErrorControl(element);
            ctrl.setErrors(null);
          }
      });
    }
    if(cName=='cmd_022'){
      let x=['cmd_024',515,514]
      x.forEach(element => {
        const ctrl = this.gaForm.controls[element];
          if ((ctrl.value === '' || ctrl.value === null) && !this.gaForm.controls[cName].value) {
            this.setErrorControl(element);
            result = true;
          }  else {
            this.removeErrorControl(element);
            ctrl.setErrors(null);
          }
      });
    }
    if(cName=='cmd_026'){
      let x=[517,518,520,522,523,524,525,526,527,528]
      x.forEach(element => {
        const ctrl = this.gaForm.controls[element];
          if ((ctrl.value === '' || ctrl.value === null) && !this.gaForm.controls[cName].value) {
            this.setErrorControl(element);
            result = true;
          }  else {
            this.removeErrorControl(element);
            ctrl.setErrors(null);
          }
      });
    }
    if(cName=='cmd_025'){
      let x=[529,531,530,533,535,536,537,538,539,540]
      x.forEach(element => {
        const ctrl = this.gaForm.controls[element];
          if ((ctrl.value === '' || ctrl.value === null) && !this.gaForm.controls[cName].value) {
            this.setErrorControl(element);
            result = true;
          }  else {
            this.removeErrorControl(element);
            ctrl.setErrors(null);
          }
      });
    }
    if(cName=='cmd_029'){
      let x=[542,544,543,546,548,549,550,551,552,553]
      x.forEach(element => {
        const ctrl = this.gaForm.controls[element];
          if ((ctrl.value === '' || ctrl.value === null) && !this.gaForm.controls[cName].value) {
            this.setErrorControl(element);
            result = true;
          }  else {
            this.removeErrorControl(element);
            ctrl.setErrors(null);
          }
      });
    }
    if(cName=='cmd_0192'){
      let x=[1288,1628,1291,1293,1294,1295,1296,1297,1299,1298]
      x.forEach(element => {
        const ctrl = this.gaForm.controls[element];
          if ((ctrl.value === '' || ctrl.value === null) && !this.gaForm.controls[cName].value) {
            this.setErrorControl(element);
            result = true;
          }  else {
            this.removeErrorControl(element);
            ctrl.setErrors(null);
          }
      });
    }
    if(cName=='cmd_043'){
      let x=[1147,1154,1146,1150,1152,1153,1155,1156,1158,1165,1157,1161,1163,1164,1166,1167]
      x.forEach(element => {
        const ctrl = this.gaForm.controls[element];
          if ((ctrl.value === '' || ctrl.value === null) && !this.gaForm.controls[cName].value) {
            this.setErrorControl(element);
            result = true;
          }  else {
            this.removeErrorControl(element);
            ctrl.setErrors(null);
          }
      });
    }
    if(cName=='cmd_044'){
      let x=[1139,1140,1141,1144,1145]
      x.forEach(element => {
        const ctrl = this.gaForm.controls[element];
          if ((ctrl.value === '' || ctrl.value === null) && !this.gaForm.controls[cName].value) {
            this.setErrorControl(element);
            result = true;
          }  else {
            this.removeErrorControl(element);
            ctrl.setErrors(null);
          }
      });
    }
    if(cName=='cmd_048'){
      let x=[1274,300,907,308,307,308,3690,1432,999,1010,1004,1003,1004]
      x.forEach(element => {
        const ctrl = this.gaForm.controls[element];
          if ((ctrl.value === '' || ctrl.value === null) && !this.gaForm.controls[cName].value) {
            this.setErrorControl(element);
            result = true;
          }  else {
            this.removeErrorControl(element);
            ctrl.setErrors(null);
          }
      });
    }
    if(cName=='cmd_075'){
      let x=[2025,2042,2044,1303,1184,1186,1189,2052,1010,1004,1003,1004]
      x.forEach(element => {
        const ctrl = this.gaForm.controls[element];
          if ((ctrl.value === '' || ctrl.value === null) && !this.gaForm.controls[cName].value) {
            this.setErrorControl(element);
            result = true;
          }  else {
            this.removeErrorControl(element);
            ctrl.setErrors(null);
          }
      });
    }
    if(cName=='cmd_077'){
      let x=[2061,2072,2074,2062,2064,2066,2067,2052,1010,1004,1003,1004]
      x.forEach(element => {
        const ctrl = this.gaForm.controls[element];
          if ((ctrl.value === '' || ctrl.value === null) && !this.gaForm.controls[cName].value) {
            this.setErrorControl(element);
            result = true;
          }  else {
            this.removeErrorControl(element);
            ctrl.setErrors(null);
          }
      });
    }
    if(cName=='cmd_0201'){
      let x=[345,346,1240,1266,350,351,901,903,905,906,348,349,945]
      x.forEach(element => {
        const ctrl = this.gaForm.controls[element];
          if ((ctrl.value === '' || ctrl.value === null) && !this.gaForm.controls[cName].value) {
            this.setErrorControl(element);
            result = true;
          }  else {
            this.removeErrorControl(element);
            ctrl.setErrors(null);
          }
      });
    }
    if(cName=='cmd_020'){
      let x=[479,480,482,485,487,489]
      x.forEach(element => {
        const ctrl = this.gaForm.controls[element];
          if ((ctrl.value === '' || ctrl.value === null) && !this.gaForm.controls[cName].value) {
            this.setErrorControl(element);
            result = true;
          }  else {
            this.removeErrorControl(element);
            ctrl.setErrors(null);
          }
      });
    }
    if(cName=='cmd_081'){
      let x=[1138,1831,1832]
      x.forEach(element => {
        const ctrl = this.gaForm.controls[element];
          if ((ctrl.value === '' || ctrl.value === null) && !this.gaForm.controls[cName].value) {
            this.setErrorControl(element);
            result = true;
          }  else {
            this.removeErrorControl(element);
            ctrl.setErrors(null);
          }
      });
    }
  }
  public getAggregatesData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getAggregatesData(params).subscribe(
      data => {
        if (data['success']) {
          this.aggregates = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getDegreeData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getDegreeData(params).subscribe(
      data => {
        if (data['success']) {
          this.degree = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getPreClaimData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getPreClaimData(params).subscribe(
      data => {
        if (data['success']) {
          this.preClaim = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getAlternateLicenseStateData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getAlternateLicenseStateData(params).subscribe(
      data => {
        if (data['success']) {
          this.altLicenseState = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getAdditionalLicenseStateData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getAdditionalLicenseStateData(params).subscribe(
      data => {
        if (data['success']) {
          this.additionalLicenseState = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getStatesData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getStatesData(params).subscribe(
      data => {
        if (data['success']) {
          this.states = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getSpecialtyData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getSpecialtyData(params).subscribe(
      data => {
        if (data['success']) {
          this.specialty = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getSpecialtySecondaryData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getSpecialtySecondaryData(params).subscribe(
      data => {
        if (data['success']) {
          this.specialtySecondary = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getLanguagesData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getLanguagesData(params).subscribe(
      data => {
        if (data['success']) {
          this.languages = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getsecondaryLanguagesData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getsecondaryLanguagesData(params).subscribe(
      data => {
        if (data['success']) {
          this.secondaryLanguages = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public gettertiaryLanguagesData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.gettertiaryLanguagesData(params).subscribe(
      data => {
        if (data['success']) {
          this.tertiaryLanguages = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getquaternaryLanguagesData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getquaternaryLanguagesData(params).subscribe(
      data => {
        if (data['success']) {
          this.quaternaryLanguages = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getBoardCertificationData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getBoardCertificationData(params).subscribe(
      data => {
        if (data['success']) {
          this.boardCertification = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  saveForm(type) {
    this.isSubmit = true;
    console.log(this.gaForm.controls);
    this.handleNAchange('cmd_0201');
    this.handleNAchange('cmd_090');
    this.handleNAchange('cmd_0193');
    this.handleNAchange('cmd_0191');
    this.handleNAchange('cmd_022');
    this.handleNAchange('cmd_026');
    this.handleNAchange('cmd_025');
    this.handleNAchange('cmd_029');
    this.handleNAchange('cmd_0192');
    this.handleNAchange('cmd_043');
    this.handleNAchange('cmd_044');
    this.handleNAchange('cmd_048');
    this.handleNAchange('cmd_075');
    this.handleNAchange('cmd_077');
    this.handleNAchange('cmd_020');
    this.handleNAchange('cmd_081');
    // console.log(this.gaForm);
    // if (type == 'submit') {
    //   if (
    //     this.gaForm.invalid ||
    //     (!this.mlpDoesNotApply &&
    //       (!this.gaForm.controls['1242'].value ||
    //         !this.gaForm.controls['1256'].value ||
    //         !this.gaForm.controls['1243'].value ||
    //         !this.gaForm.controls['1246'].value ||
    //         !this.gaForm.controls['1249'].value ||
    //         !this.gaForm.controls['1252'].value ||
    //         !this.gaForm.controls['1251'].value ||
    //         !this.gaForm.controls['1253'].value ||
    //         !this.gaForm.controls['1254'].value ||
    //         !this.gaForm.controls['1264'].value ||
    //         !this.gaForm.controls['1265'].value ||
    //         !this.gaForm.controls['1248'].value ||
    //         !this.gaForm.controls['1245'].value ||
    //         !this.gaForm.controls['cmd_0107'].value ||
    //         !this.gaForm.controls['cmd_0108'].value ||
    //         !this.gaForm.controls['cmd_0109'].value ||
    //         !this.gaForm.controls['cmd_0110'].value ||
    //         !this.gaForm.controls['cmd_0111'].value ||
    //         !this.gaForm.controls['cmd_0112'].value)) ||
    //     (!this.pliDoesnot &&
    //       (!this.gaForm.controls['345'].value ||
    //         !this.gaForm.controls['346'].value ||
    //         !this.gaForm.controls['1240'].value ||
    //         !this.gaForm.controls['1266'].value ||
    //         !this.gaForm.controls['901'].value ||
    //         !this.gaForm.controls['903'].value ||
    //         !this.gaForm.controls['348'].value ||
    //         !this.gaForm.controls['349'].value ||
    //         !this.gaForm.controls['945'].value ||
    //         !this.gaForm.controls['906'].value ||
    //         !this.gaForm.controls['350'].value ||
    //         !this.gaForm.controls['351'].value ||
    //         !this.gaForm.controls['905'].value ||
    //         !this.gaForm.controls['cmd_089'].value)) ||
    //     (!this.ExplanInterruptionDoesnot &&
    //       (!this.gaForm.controls['1138'].value ||
    //         !this.gaForm.controls['1832'].value ||
    //         !this.gaForm.controls['1831'].value)) ||
    //     (!this.otherFacilityDoesnot &&
    //       (!this.gaForm.controls['2061'].value ||
    //         !this.gaForm.controls['2062'].value ||
    //         !this.gaForm.controls['2066'].value ||
    //         !this.gaForm.controls['2067'].value ||
    //         !this.gaForm.controls['2064'].value ||
    //         !this.gaForm.controls['2072'].value ||
    //         !this.gaForm.controls['2074'].value)) ||
    //     (!this.MedicalProfessionalDoesnot &&
    //       (!this.gaForm.controls['490'].value ||
    //         !this.gaForm.controls['491'].value ||
    //         !this.gaForm.controls['493'].value ||
    //         !this.gaForm.controls['495'].value ||
    //         !this.gaForm.controls['496'].value ||
    //         !this.gaForm.controls['497'].value ||
    //         !this.gaForm.controls['498'].value ||
    //         !this.gaForm.controls['499'].value ||
    //         !this.gaForm.controls['cmd_021'].value ||
    //         !this.gaForm.controls['500'].value)) ||
    //     (!this.otherStateCareDoesnot &&
    //       (!this.gaForm.controls['1274'].value ||
    //         !this.gaForm.controls['300'].value ||
    //         !this.gaForm.controls['907'].value ||
    //         !this.gaForm.controls['308'].value ||
    //         !this.gaForm.controls['307'].value ||
    //         !this.gaForm.controls['1432'].value ||
    //         !this.gaForm.controls['999'].value ||
    //         !this.gaForm.controls['1010'].value ||
    //         !this.gaForm.controls['1003'].value ||
    //         !this.gaForm.controls['1004'].value)) ||
    //     (!this.militaryHealthDoesnot &&
    //       (!this.gaForm.controls['1139'].value ||
    //         !this.gaForm.controls['1140'].value ||
    //         !this.gaForm.controls['1144'].value ||
    //         !this.gaForm.controls['1141'].value ||
    //         !this.gaForm.controls['1145'].value ||
    //         !this.gaForm.controls['cmd_045'].value ||
    //         !this.gaForm.controls['cmd_046'].value ||
    //         !this.gaForm.controls['cmd_047'].value)) ||
    //     (!this.graduateDegreeDoesnot &&
    //       (!this.gaForm.controls['479'].value ||
    //         !this.gaForm.controls['480'].value ||
    //         !this.gaForm.controls['482'].value ||
    //         !this.gaForm.controls['485'].value ||
    //         !this.gaForm.controls['487'].value ||
    //         !this.gaForm.controls['489'].value)) ||
    //     (!this.intersnipDoesnot &&
    //       (!this.gaForm.controls['517'].value ||
    //         !this.gaForm.controls['518'].value ||
    //         !this.gaForm.controls['520'].value ||
    //         !this.gaForm.controls['522'].value ||
    //         !this.gaForm.controls['523'].value ||
    //         !this.gaForm.controls['524'].value ||
    //         !this.gaForm.controls['525'].value ||
    //         !this.gaForm.controls['526'].value ||
    //         !this.gaForm.controls['527'].value ||
    //         !this.gaForm.controls['528'].value)) ||
    //     (!this.residencyDoesnot &&
    //       (!this.gaForm.controls['529'].value ||
    //         !this.gaForm.controls['531'].value ||
    //         !this.gaForm.controls['530'].value ||
    //         !this.gaForm.controls['533'].value ||
    //         !this.gaForm.controls['535'].value ||
    //         !this.gaForm.controls['536'].value ||
    //         !this.gaForm.controls['537'].value ||
    //         !this.gaForm.controls['538'].value ||
    //         !this.gaForm.controls['539'].value ||
    //         !this.gaForm.controls['540'].value)) ||
    //     (!this.fellowshipDoesnot &&
    //       (!this.gaForm.controls['542'].value ||
    //         !this.gaForm.controls['544'].value ||
    //         !this.gaForm.controls['543'].value ||
    //         !this.gaForm.controls['546'].value ||
    //         !this.gaForm.controls['548'].value ||
    //         !this.gaForm.controls['549'].value ||
    //         !this.gaForm.controls['550'].value ||
    //         !this.gaForm.controls['551'].value ||
    //         !this.gaForm.controls['552'].value ||
    //         !this.gaForm.controls['553'].value)) ||
    //     (!this.facultyPositionsDoesnot &&
    //       (!this.gaForm.controls['1147'].value ||
    //         !this.gaForm.controls['1154'].value ||
    //         !this.gaForm.controls['1146'].value ||
    //         !this.gaForm.controls['1150'].value ||
    //         !this.gaForm.controls['1152'].value ||
    //         !this.gaForm.controls['1153'].value ||
    //         !this.gaForm.controls['1155'].value ||
    //         !this.gaForm.controls['1156'].value ||
    //         !this.gaForm.controls['1158'].value ||
    //         !this.gaForm.controls['1165'].value ||
    //         !this.gaForm.controls['1157'].value ||
    //         !this.gaForm.controls['1164'].value ||
    //         !this.gaForm.controls['1166'].value ||
    //         !this.gaForm.controls['1167'].value ||
    //         !this.gaForm.controls['1163'].value ||
    //         !this.gaForm.controls['1161'].value)) ||
    //     (!this.ForeignMedicalDoesnot &&
    //       (!this.gaForm.controls['514'].value ||
    //         !this.gaForm.controls['515'].value ||
    //         !this.gaForm.controls['cmd_023'].value ||
    //         !this.gaForm.controls['cmd_024'].value)) ||
    //     (!this.boardCertiDoesnot &&
    //       (!this.gaForm.controls['554'].value ||
    //         !this.gaForm.controls['556'].value ||
    //         !this.gaForm.controls['557'].value ||
    //         !this.gaForm.controls['560'].value)) ||
    //     (!this.otherClinicalDoesnot &&
    //       (!this.gaForm.controls['1288'].value ||
    //         !this.gaForm.controls['1628'].value ||
    //         !this.gaForm.controls['1291'].value ||
    //         !this.gaForm.controls['1294'].value ||
    //         !this.gaForm.controls['1293'].value ||
    //         !this.gaForm.controls['1295'].value ||
    //         !this.gaForm.controls['1296'].value ||
    //         !this.gaForm.controls['1297'].value ||
    //         !this.gaForm.controls['1298'].value ||
    //         !this.gaForm.controls['1299'].value)) ||
    //     (!this.drugenforcementDoesnot &&
    //       (!this.gaForm.controls['309'].value ||
    //         !this.gaForm.controls['311'].value)) ||
    //     (!this.proworkhistDoesnot && !this.gaForm.controls['455'].value)
    //   ) {
    //     if (!this.mlpDoesNotApply && !this.gaForm.controls['1242'].value) {
    //       this.gaForm.controls['1242'].setErrors({ errors: true });
    //     }
    //     if (!this.mlpDoesNotApply && !this.gaForm.controls['1256'].value) {
    //       this.gaForm.controls['1256'].setErrors({ errors: true });
    //     }
    //     if (!this.mlpDoesNotApply && !this.gaForm.controls['1243'].value) {
    //       this.gaForm.controls['1243'].setErrors({ errors: true });
    //     }
    //     if (!this.mlpDoesNotApply && !this.gaForm.controls['1246'].value) {
    //       this.gaForm.controls['1246'].setErrors({ errors: true });
    //     }
    //     if (!this.mlpDoesNotApply && !this.gaForm.controls['1249'].value) {
    //       this.gaForm.controls['1249'].setErrors({ errors: true });
    //     }
    //     if (!this.mlpDoesNotApply && !this.gaForm.controls['1252'].value) {
    //       this.gaForm.controls['1252'].setErrors({ errors: true });
    //     }
    //     if (!this.mlpDoesNotApply && !this.gaForm.controls['1251'].value) {
    //       this.gaForm.controls['1251'].setErrors({ errors: true });
    //     }
    //     if (!this.mlpDoesNotApply && !this.gaForm.controls['1253'].value) {
    //       this.gaForm.controls['1253'].setErrors({ errors: true });
    //     }
    //     if (!this.mlpDoesNotApply && !this.gaForm.controls['1254'].value) {
    //       this.gaForm.controls['1254'].setErrors({ errors: true });
    //     }
    //     if (!this.mlpDoesNotApply && !this.gaForm.controls['1264'].value) {
    //       this.gaForm.controls['1264'].setErrors({ errors: true });
    //     }
    //     if (!this.mlpDoesNotApply && !this.gaForm.controls['1265'].value) {
    //       this.gaForm.controls['1265'].setErrors({ errors: true });
    //     }
    //     if (!this.mlpDoesNotApply && !this.gaForm.controls['1248'].value) {
    //       this.gaForm.controls['1248'].setErrors({ errors: true });
    //     }
    //     if (!this.mlpDoesNotApply && !this.gaForm.controls['1245'].value) {
    //       this.gaForm.controls['1245'].setErrors({ errors: true });
    //     }
    //     if (!this.mlpDoesNotApply && !this.gaForm.controls['cmd_0107'].value) {
    //       this.gaForm.controls['cmd_0107'].setErrors({ errors: true });
    //     }
    //     if (!this.mlpDoesNotApply && !this.gaForm.controls['cmd_0108'].value) {
    //       this.gaForm.controls['cmd_0108'].setErrors({ errors: true });
    //     }
    //     if (!this.mlpDoesNotApply && !this.gaForm.controls['cmd_0109'].value) {
    //       this.gaForm.controls['cmd_0109'].setErrors({ errors: true });
    //     }
    //     if (!this.mlpDoesNotApply && !this.gaForm.controls['cmd_0110'].value) {
    //       this.gaForm.controls['cmd_0110'].setErrors({ errors: true });
    //     }
    //     if (!this.mlpDoesNotApply && !this.gaForm.controls['cmd_0111'].value) {
    //       this.gaForm.controls['cmd_0111'].setErrors({ errors: true });
    //     }
    //     if (!this.mlpDoesNotApply && !this.gaForm.controls['cmd_0112'].value) {
    //       this.gaForm.controls['cmd_0112'].setErrors({ errors: true });
    //     }

    //     ///part 2
    //     // 345,346,1240,1266,901,903,348,349,945,906,350,351,905

    //     if (!this.pliDoesnot && !this.gaForm.controls['345'].value) {
    //       this.gaForm.controls['345'].setErrors({ errors: true });
    //     }
    //     if (!this.pliDoesnot && !this.gaForm.controls['346'].value) {
    //       this.gaForm.controls['346'].setErrors({ errors: true });
    //     }
    //     if (!this.pliDoesnot && !this.gaForm.controls['1240'].value) {
    //       this.gaForm.controls['1240'].setErrors({ errors: true });
    //     }
    //     if (!this.pliDoesnot && !this.gaForm.controls['1266'].value) {
    //       this.gaForm.controls['1266'].setErrors({ errors: true });
    //     }
    //     if (!this.pliDoesnot && !this.gaForm.controls['901'].value) {
    //       this.gaForm.controls['901'].setErrors({ errors: true });
    //     }
    //     if (!this.pliDoesnot && !this.gaForm.controls['903'].value) {
    //       this.gaForm.controls['903'].setErrors({ errors: true });
    //     }
    //     if (!this.pliDoesnot && !this.gaForm.controls['348'].value) {
    //       this.gaForm.controls['348'].setErrors({ errors: true });
    //     }
    //     if (!this.pliDoesnot && !this.gaForm.controls['349'].value) {
    //       this.gaForm.controls['349'].setErrors({ errors: true });
    //     }
    //     if (!this.pliDoesnot && !this.gaForm.controls['945'].value) {
    //       this.gaForm.controls['945'].setErrors({ errors: true });
    //     }
    //     if (!this.pliDoesnot && !this.gaForm.controls['906'].value) {
    //       this.gaForm.controls['906'].setErrors({ errors: true });
    //     }
    //     if (!this.pliDoesnot && !this.gaForm.controls['350'].value) {
    //       this.gaForm.controls['350'].setErrors({ errors: true });
    //     }
    //     if (!this.pliDoesnot && !this.gaForm.controls['351'].value) {
    //       this.gaForm.controls['351'].setErrors({ errors: true });
    //     }
    //     if (!this.pliDoesnot && !this.gaForm.controls['905'].value) {
    //       this.gaForm.controls['905'].setErrors({ errors: true });
    //     }
    //     if (!this.pliDoesnot && !this.gaForm.controls['cmd_089'].value) {
    //       this.gaForm.controls['cmd_089'].setErrors({ errors: true });
    //     }

    //     //explanation
    //     if (
    //       !this.ExplanInterruptionDoesnot &&
    //       !this.gaForm.controls['1832'].value
    //     ) {
    //       this.gaForm.controls['1832'].setErrors({ errors: true });
    //     }
    //     if (
    //       !this.ExplanInterruptionDoesnot &&
    //       !this.gaForm.controls['1831'].value
    //     ) {
    //       this.gaForm.controls['1831'].setErrors({ errors: true });
    //     }
    //     if (
    //       !this.ExplanInterruptionDoesnot &&
    //       !this.gaForm.controls['1138'].value
    //     ) {
    //       this.gaForm.controls['1138'].setErrors({ errors: true });
    //     }

    //     if (!this.otherFacilityDoesnot && !this.gaForm.controls['2062'].value) {
    //       this.gaForm.controls['2062'].setErrors({ errors: true });
    //     }
    //     if (!this.otherFacilityDoesnot && !this.gaForm.controls['2061'].value) {
    //       this.gaForm.controls['2061'].setErrors({ errors: true });
    //     }
    //     if (!this.otherFacilityDoesnot && !this.gaForm.controls['2064'].value) {
    //       this.gaForm.controls['2064'].setErrors({ errors: true });
    //     }
    //     if (!this.otherFacilityDoesnot && !this.gaForm.controls['2066'].value) {
    //       this.gaForm.controls['2066'].setErrors({ errors: true });
    //     }
    //     if (!this.otherFacilityDoesnot && !this.gaForm.controls['2067'].value) {
    //       this.gaForm.controls['2067'].setErrors({ errors: true });
    //     }
    //     if (!this.otherFacilityDoesnot && !this.gaForm.controls['2072'].value) {
    //       this.gaForm.controls['2072'].setErrors({ errors: true });
    //     }
    //     if (!this.otherFacilityDoesnot && !this.gaForm.controls['2074'].value) {
    //       this.gaForm.controls['2074'].setErrors({ errors: true });
    //     }

    //     if (
    //       !this.otherStateCareDoesnot &&
    //       !this.gaForm.controls['1274'].value
    //     ) {
    //       this.gaForm.controls['1274'].setErrors({ errors: true });
    //     }
    //     if (!this.otherStateCareDoesnot && !this.gaForm.controls['300'].value) {
    //       this.gaForm.controls['300'].setErrors({ errors: true });
    //     }
    //     if (!this.otherStateCareDoesnot && !this.gaForm.controls['907'].value) {
    //       this.gaForm.controls['907'].setErrors({ errors: true });
    //     }
    //     if (!this.otherStateCareDoesnot && !this.gaForm.controls['308'].value) {
    //       this.gaForm.controls['308'].setErrors({ errors: true });
    //     }
    //     if (!this.otherStateCareDoesnot && !this.gaForm.controls['307'].value) {
    //       this.gaForm.controls['307'].setErrors({ errors: true });
    //     }
    //     if (!this.otherStateCareDoesnot && !this.gaForm.controls['999'].value) {
    //       this.gaForm.controls['999'].setErrors({ errors: true });
    //     }
    //     if (
    //       !this.otherStateCareDoesnot &&
    //       !this.gaForm.controls['1432'].value
    //     ) {
    //       this.gaForm.controls['1432'].setErrors({ errors: true });
    //     }
    //     if (
    //       !this.otherStateCareDoesnot &&
    //       !this.gaForm.controls['1010'].value
    //     ) {
    //       this.gaForm.controls['1010'].setErrors({ errors: true });
    //     }
    //     if (
    //       !this.otherStateCareDoesnot &&
    //       !this.gaForm.controls['1003'].value
    //     ) {
    //       this.gaForm.controls['1003'].setErrors({ errors: true });
    //     }
    //     if (
    //       !this.otherStateCareDoesnot &&
    //       !this.gaForm.controls['1004'].value
    //     ) {
    //       this.gaForm.controls['1004'].setErrors({ errors: true });
    //     }

    //     if (!this.boardCertiDoesnot && !this.gaForm.controls['554'].value) {
    //       this.gaForm.controls['554'].setErrors({ errors: true });
    //     }
    //     if (!this.boardCertiDoesnot && !this.gaForm.controls['556'].value) {
    //       this.gaForm.controls['556'].setErrors({ errors: true });
    //     }
    //     if (!this.boardCertiDoesnot && !this.gaForm.controls['557'].value) {
    //       this.gaForm.controls['557'].setErrors({ errors: true });
    //     }
    //     if (!this.boardCertiDoesnot && !this.gaForm.controls['560'].value) {
    //       this.gaForm.controls['560'].setErrors({ errors: true });
    //     }

    //     if (
    //       !this.militaryHealthDoesnot &&
    //       !this.gaForm.controls['1139'].value
    //     ) {
    //       this.gaForm.controls['1139'].setErrors({ errors: true });
    //     }
    //     if (
    //       !this.militaryHealthDoesnot &&
    //       !this.gaForm.controls['1140'].value
    //     ) {
    //       this.gaForm.controls['1140'].setErrors({ errors: true });
    //     }
    //     if (
    //       !this.militaryHealthDoesnot &&
    //       !this.gaForm.controls['1141'].value
    //     ) {
    //       this.gaForm.controls['1141'].setErrors({ errors: true });
    //     }
    //     if (
    //       !this.militaryHealthDoesnot &&
    //       !this.gaForm.controls['1144'].value
    //     ) {
    //       this.gaForm.controls['1144'].setErrors({ errors: true });
    //     }
    //     if (
    //       !this.militaryHealthDoesnot &&
    //       !this.gaForm.controls['1145'].value
    //     ) {
    //       this.gaForm.controls['1145'].setErrors({ errors: true });
    //     }
    //     if (
    //       !this.militaryHealthDoesnot &&
    //       !this.gaForm.controls['cmd_045'].value
    //     ) {
    //       this.gaForm.controls['cmd_045'].setErrors({ errors: true });
    //     }
    //     if (
    //       !this.militaryHealthDoesnot &&
    //       !this.gaForm.controls['cmd_046'].value
    //     ) {
    //       this.gaForm.controls['cmd_046'].setErrors({ errors: true });
    //     }
    //     if (
    //       !this.militaryHealthDoesnot &&
    //       !this.gaForm.controls['cmd_047'].value
    //     ) {
    //       this.gaForm.controls['cmd_047'].setErrors({ errors: true });
    //     }

    //     /// 479,480,482,485,487,489
    //     if (!this.graduateDegreeDoesnot && !this.gaForm.controls['479'].value) {
    //       this.gaForm.controls['479'].setErrors({ errors: true });
    //     }
    //     if (!this.graduateDegreeDoesnot && !this.gaForm.controls['480'].value) {
    //       this.gaForm.controls['480'].setErrors({ errors: true });
    //     }
    //     if (!this.graduateDegreeDoesnot && !this.gaForm.controls['482'].value) {
    //       this.gaForm.controls['482'].setErrors({ errors: true });
    //     }
    //     if (!this.graduateDegreeDoesnot && !this.gaForm.controls['485'].value) {
    //       this.gaForm.controls['485'].setErrors({ errors: true });
    //     }
    //     if (!this.graduateDegreeDoesnot && !this.gaForm.controls['487'].value) {
    //       this.gaForm.controls['487'].setErrors({ errors: true });
    //     }
    //     if (!this.graduateDegreeDoesnot && !this.gaForm.controls['489'].value) {
    //       this.gaForm.controls['489'].setErrors({ errors: true });
    //     }

    //     if (
    //       !this.MedicalProfessionalDoesnot &&
    //       !this.gaForm.controls['490'].value
    //     ) {
    //       this.gaForm.controls['490'].setErrors({ errors: true });
    //     }
    //     if (
    //       !this.MedicalProfessionalDoesnot &&
    //       !this.gaForm.controls['491'].value
    //     ) {
    //       this.gaForm.controls['491'].setErrors({ errors: true });
    //     }
    //     if (
    //       !this.MedicalProfessionalDoesnot &&
    //       !this.gaForm.controls['493'].value
    //     ) {
    //       this.gaForm.controls['493'].setErrors({ errors: true });
    //     }

    //     if (
    //       !this.MedicalProfessionalDoesnot &&
    //       !this.gaForm.controls['495'].value
    //     ) {
    //       this.gaForm.controls['495'].setErrors({ errors: true });
    //     }
    //     if (
    //       !this.MedicalProfessionalDoesnot &&
    //       !this.gaForm.controls['496'].value
    //     ) {
    //       this.gaForm.controls['496'].setErrors({ errors: true });
    //     }
    //     if (
    //       !this.MedicalProfessionalDoesnot &&
    //       !this.gaForm.controls['497'].value
    //     ) {
    //       this.gaForm.controls['497'].setErrors({ errors: true });
    //     }
    //     if (
    //       !this.MedicalProfessionalDoesnot &&
    //       !this.gaForm.controls['498'].value
    //     ) {
    //       this.gaForm.controls['498'].setErrors({ errors: true });
    //     }
    //     if (
    //       !this.MedicalProfessionalDoesnot &&
    //       !this.gaForm.controls['499'].value
    //     ) {
    //       this.gaForm.controls['499'].setErrors({ errors: true });
    //     }
    //     if (
    //       !this.MedicalProfessionalDoesnot &&
    //       !this.gaForm.controls['500'].value
    //     ) {
    //       this.gaForm.controls['500'].setErrors({ errors: true });
    //     }
    //     if (
    //       !this.MedicalProfessionalDoesnot &&
    //       !this.gaForm.controls['cmd_021'].value
    //     ) {
    //       this.gaForm.controls['cmd_021'].setErrors({ errors: true });
    //     }

    //     if (!this.intersnipDoesnot && !this.gaForm.controls['517'].value) {
    //       this.gaForm.controls['517'].setErrors({ errors: true });
    //     }
    //     if (!this.intersnipDoesnot && !this.gaForm.controls['518'].value) {
    //       this.gaForm.controls['518'].setErrors({ errors: true });
    //     }
    //     if (!this.intersnipDoesnot && !this.gaForm.controls['520'].value) {
    //       this.gaForm.controls['520'].setErrors({ errors: true });
    //     }

    //     if (!this.intersnipDoesnot && !this.gaForm.controls['522'].value) {
    //       this.gaForm.controls['522'].setErrors({ errors: true });
    //     }
    //     if (!this.intersnipDoesnot && !this.gaForm.controls['523'].value) {
    //       this.gaForm.controls['523'].setErrors({ errors: true });
    //     }
    //     if (!this.intersnipDoesnot && !this.gaForm.controls['524'].value) {
    //       this.gaForm.controls['524'].setErrors({ errors: true });
    //     }
    //     if (!this.intersnipDoesnot && !this.gaForm.controls['525'].value) {
    //       this.gaForm.controls['525'].setErrors({ errors: true });
    //     }
    //     if (!this.intersnipDoesnot && !this.gaForm.controls['526'].value) {
    //       this.gaForm.controls['526'].setErrors({ errors: true });
    //     }
    //     if (!this.intersnipDoesnot && !this.gaForm.controls['527'].value) {
    //       this.gaForm.controls['527'].setErrors({ errors: true });
    //     }
    //     if (!this.intersnipDoesnot && !this.gaForm.controls['528'].value) {
    //       this.gaForm.controls['528'].setErrors({ errors: true });
    //     }

    //     if (!this.residencyDoesnot && !this.gaForm.controls['529'].value) {
    //       this.gaForm.controls['529'].setErrors({ errors: true });
    //     }
    //     if (!this.residencyDoesnot && !this.gaForm.controls['531'].value) {
    //       this.gaForm.controls['531'].setErrors({ errors: true });
    //     }

    //     if (!this.residencyDoesnot && !this.gaForm.controls['530'].value) {
    //       this.gaForm.controls['530'].setErrors({ errors: true });
    //     }
    //     if (!this.residencyDoesnot && !this.gaForm.controls['533'].value) {
    //       this.gaForm.controls['533'].setErrors({ errors: true });
    //     }
    //     if (!this.residencyDoesnot && !this.gaForm.controls['535'].value) {
    //       this.gaForm.controls['535'].setErrors({ errors: true });
    //     }
    //     if (!this.residencyDoesnot && !this.gaForm.controls['536'].value) {
    //       this.gaForm.controls['536'].setErrors({ errors: true });
    //     }
    //     if (!this.residencyDoesnot && !this.gaForm.controls['537'].value) {
    //       this.gaForm.controls['537'].setErrors({ errors: true });
    //     }
    //     if (!this.residencyDoesnot && !this.gaForm.controls['538'].value) {
    //       this.gaForm.controls['538'].setErrors({ errors: true });
    //     }
    //     if (!this.residencyDoesnot && !this.gaForm.controls['539'].value) {
    //       this.gaForm.controls['539'].setErrors({ errors: true });
    //     }

    //     if (!this.residencyDoesnot && !this.gaForm.controls['540'].value) {
    //       this.gaForm.controls['540'].setErrors({ errors: true });
    //     }

    //     if (!this.fellowshipDoesnot && !this.gaForm.controls['542'].value) {
    //       this.gaForm.controls['542'].setErrors({ errors: true });
    //     }

    //     if (!this.fellowshipDoesnot && !this.gaForm.controls['544'].value) {
    //       this.gaForm.controls['544'].setErrors({ errors: true });
    //     }
    //     if (!this.fellowshipDoesnot && !this.gaForm.controls['543'].value) {
    //       this.gaForm.controls['543'].setErrors({ errors: true });
    //     }

    //     if (!this.fellowshipDoesnot && !this.gaForm.controls['546'].value) {
    //       this.gaForm.controls['546'].setErrors({ errors: true });
    //     }
    //     if (!this.fellowshipDoesnot && !this.gaForm.controls['548'].value) {
    //       this.gaForm.controls['548'].setErrors({ errors: true });
    //     }

    //     if (!this.fellowshipDoesnot && !this.gaForm.controls['549'].value) {
    //       this.gaForm.controls['549'].setErrors({ errors: true });
    //     }
    //     if (!this.fellowshipDoesnot && !this.gaForm.controls['550'].value) {
    //       this.gaForm.controls['550'].setErrors({ errors: true });
    //     }

    //     if (!this.fellowshipDoesnot && !this.gaForm.controls['551'].value) {
    //       this.gaForm.controls['551'].setErrors({ errors: true });
    //     }
    //     if (!this.fellowshipDoesnot && !this.gaForm.controls['552'].value) {
    //       this.gaForm.controls['552'].setErrors({ errors: true });
    //     }

    //     if (!this.fellowshipDoesnot && !this.gaForm.controls['553'].value) {
    //       this.gaForm.controls['553'].setErrors({ errors: true });
    //     }

    //     if (!this.ForeignMedicalDoesnot && !this.gaForm.controls['514'].value) {
    //       this.gaForm.controls['514'].setErrors({ errors: true });
    //     }

    //     if (!this.ForeignMedicalDoesnot && !this.gaForm.controls['515'].value) {
    //       this.gaForm.controls['515'].setErrors({ errors: true });
    //     }
    //     if (
    //       !this.ForeignMedicalDoesnot &&
    //       !this.gaForm.controls['cmd_023'].value
    //     ) {
    //       this.gaForm.controls['cmd_023'].setErrors({ errors: true });
    //     }

    //     if (
    //       !this.ForeignMedicalDoesnot &&
    //       !this.gaForm.controls['cmd_024'].value
    //     ) {
    //       this.gaForm.controls['cmd_024'].setErrors({ errors: true });
    //     }

    //     if (
    //       !this.facultyPositionsDoesnot &&
    //       !this.gaForm.controls['1147'].value
    //     ) {
    //       this.gaForm.controls['1147'].setErrors({ errors: true });
    //     }
    //     if (
    //       !this.facultyPositionsDoesnot &&
    //       !this.gaForm.controls['1154'].value
    //     ) {
    //       this.gaForm.controls['1154'].setErrors({ errors: true });
    //     }
    //     if (
    //       !this.facultyPositionsDoesnot &&
    //       !this.gaForm.controls['1146'].value
    //     ) {
    //       this.gaForm.controls['1146'].setErrors({ errors: true });
    //     }
    //     if (
    //       !this.facultyPositionsDoesnot &&
    //       !this.gaForm.controls['1150'].value
    //     ) {
    //       this.gaForm.controls['1150'].setErrors({ errors: true });
    //     }
    //     if (
    //       !this.facultyPositionsDoesnot &&
    //       !this.gaForm.controls['1152'].value
    //     ) {
    //       this.gaForm.controls['1152'].setErrors({ errors: true });
    //     }
    //     if (
    //       !this.facultyPositionsDoesnot &&
    //       !this.gaForm.controls['1153'].value
    //     ) {
    //       this.gaForm.controls['1153'].setErrors({ errors: true });
    //     }
    //     if (
    //       !this.facultyPositionsDoesnot &&
    //       !this.gaForm.controls['1155'].value
    //     ) {
    //       this.gaForm.controls['1155'].setErrors({ errors: true });
    //     }
    //     if (
    //       !this.facultyPositionsDoesnot &&
    //       !this.gaForm.controls['1156'].value
    //     ) {
    //       this.gaForm.controls['1156'].setErrors({ errors: true });
    //     }
    //     if (
    //       !this.facultyPositionsDoesnot &&
    //       !this.gaForm.controls['1157'].value
    //     ) {
    //       this.gaForm.controls['1157'].setErrors({ errors: true });
    //     }
    //     if (
    //       !this.facultyPositionsDoesnot &&
    //       !this.gaForm.controls['1158'].value
    //     ) {
    //       this.gaForm.controls['1158'].setErrors({ errors: true });
    //     }
    //     if (
    //       !this.facultyPositionsDoesnot &&
    //       !this.gaForm.controls['1164'].value
    //     ) {
    //       this.gaForm.controls['1164'].setErrors({ errors: true });
    //     }
    //     if (
    //       !this.facultyPositionsDoesnot &&
    //       !this.gaForm.controls['1165'].value
    //     ) {
    //       this.gaForm.controls['1165'].setErrors({ errors: true });
    //     }
    //     if (
    //       !this.facultyPositionsDoesnot &&
    //       !this.gaForm.controls['1166'].value
    //     ) {
    //       this.gaForm.controls['1166'].setErrors({ errors: true });
    //     }
    //     if (
    //       !this.facultyPositionsDoesnot &&
    //       !this.gaForm.controls['1167'].value
    //     ) {
    //       this.gaForm.controls['1167'].setErrors({ errors: true });
    //     }
    //     if (
    //       !this.facultyPositionsDoesnot &&
    //       !this.gaForm.controls['1161'].value
    //     ) {
    //       this.gaForm.controls['1161'].setErrors({ errors: true });
    //     }
    //     if (
    //       !this.facultyPositionsDoesnot &&
    //       !this.gaForm.controls['1163'].value
    //     ) {
    //       this.gaForm.controls['1163'].setErrors({ errors: true });
    //     }

    //     if (!this.otherClinicalDoesnot && !this.gaForm.controls['1288'].value) {
    //       this.gaForm.controls['1288'].setErrors({ errors: true });
    //     }
    //     if (!this.otherClinicalDoesnot && !this.gaForm.controls['1291'].value) {
    //       this.gaForm.controls['1291'].setErrors({ errors: true });
    //     }
    //     if (!this.otherClinicalDoesnot && !this.gaForm.controls['1294'].value) {
    //       this.gaForm.controls['1294'].setErrors({ errors: true });
    //     }
    //     if (!this.otherClinicalDoesnot && !this.gaForm.controls['1293'].value) {
    //       this.gaForm.controls['1293'].setErrors({ errors: true });
    //     }
    //     if (!this.otherClinicalDoesnot && !this.gaForm.controls['1295'].value) {
    //       this.gaForm.controls['1295'].setErrors({ errors: true });
    //     }
    //     if (!this.otherClinicalDoesnot && !this.gaForm.controls['1296'].value) {
    //       this.gaForm.controls['1296'].setErrors({ errors: true });
    //     }
    //     if (!this.otherClinicalDoesnot && !this.gaForm.controls['1297'].value) {
    //       this.gaForm.controls['1297'].setErrors({ errors: true });
    //     }
    //     if (!this.otherClinicalDoesnot && !this.gaForm.controls['1298'].value) {
    //       this.gaForm.controls['1298'].setErrors({ errors: true });
    //     }
    //     if (!this.otherClinicalDoesnot && !this.gaForm.controls['1299'].value) {
    //       this.gaForm.controls['1299'].setErrors({ errors: true });
    //     }
    //     if (!this.otherClinicalDoesnot && !this.gaForm.controls['1628'].value) {
    //       this.gaForm.controls['1628'].setErrors({ errors: true });
    //     }
    //     if (
    //       !this.drugenforcementDoesnot &&
    //       !this.gaForm.controls['309'].value
    //     ) {
    //       this.gaForm.controls['309'].setErrors({ errors: true });
    //     }
    //     if (
    //       !this.drugenforcementDoesnot &&
    //       !this.gaForm.controls['311'].value
    //     ) {
    //       this.gaForm.controls['311'].setErrors({ errors: true });
    //     }
    //     if (!this.proWorkHist && !this.gaForm.controls['455'].value) {
    //       this.gaForm.controls['455'].setErrors({ errors: true });
    //     }

    //     this.notiService.showError(
    //       'Please Fill the all mandatory fields to continue',
    //       '',
    //       3000
    //     );
    //   } else {
    //     let data = {
    //       formType: type,
    //       formValues: this.gaForm.value
    //     };
    //     this.onSubmit.emit(data);
    //   }

    // } 
    if (type == 'submit') {

      if (this.gaForm.invalid) {
        this.notiService.showError(
          'Please Fill the all mandatory fields to continue',
          '',
          3000
        );
        for (const key in this.gaForm.controls) {
          if (this.gaForm.controls.hasOwnProperty(key)) {
            const element = this.gaForm.controls[key];
            if(element.status=="INVALID"){
            console.log(key + " " + element.status)
            }
          }
        }
      } else {
        // if (this.gaForm.controls['cmd_11122'].value == null || this.gaForm.controls['cmd_11122'].value == '') {
        //   this.notiService.showError('Please add your Signature')
        //   return
        // }
        let data = {
          formType: type,
          formValues: this.gaForm.value
        };
        console.log("Form has been submitted successfully");
        this.onSubmit.emit(data);
        console.log(this.gaForm);

      }
    } else {
      let data = {
        formType: type,
        formValues: this.gaForm.value,
        formId: 23
      };
      this.onSubmit.emit(data);
      this.onClose.emit('close modal');
    }
  }

  close() {
    this.onClose.emit('close modal');
  }

  public esignOpenOne() {
    const modalRef = this.modalService.open(EsignComponent, this.modalOption);
    modalRef.result.then(result => {});
    modalRef.componentInstance.base64Img.subscribe(data => {
      this.finalImgPath = data.finalFilePath;
      modalRef.close();
      let temp = {
        cmd_0991: this.finalImgPath
      };
      this.gaForm.patchValue(temp);
      this.finalImgPath = data.finalFilePath;
      this.finalImg = data.base64Img;
      this.esignOne = this._sanitizer.bypassSecurityTrustResourceUrl(
        'data:image/jpg;base64,' + this.finalImg
      );
      this.showOne = true;
    });
  }

  public esignOpenTwo() {
    const modalRef = this.modalService.open(EsignComponent, this.modalOption);
    modalRef.result.then(result => {});
    modalRef.componentInstance.base64Img.subscribe(data => {
      modalRef.close();
      this.finalImgPathTwo = data.finalFilePath;
      let temp = {
        cmd_0992: this.finalImgPathTwo
      };
      this.gaForm.patchValue(temp);
      this.finalImgPathTwo = data.finalFilePath;
      this.finalImgTwo = data.base64Img;
      this.esignTwo = this._sanitizer.bypassSecurityTrustResourceUrl(
        'data:image/jpg;base64,' + this.finalImgTwo
      );
      this.showTwo = true;
    });
  }

  public esignOpenThree() {
    const modalRef = this.modalService.open(EsignComponent, this.modalOption);
    modalRef.result.then(result => {});
    modalRef.componentInstance.base64Img.subscribe(data => {
      modalRef.close();
      this.finalImgPathThree = data.finalFilePath;
      let temp = {
        cmd_0993: this.finalImgPathThree
      };
      this.gaForm.patchValue(temp);
      this.finalImgPathThree = data.finalFilePath;
      this.finalImgThree = data.base64Img;
      this.esignThree = this._sanitizer.bypassSecurityTrustResourceUrl(
        'data:image/jpg;base64,' + this.finalImgThree
      );
      this.showThree = true;
    });
  }

  public esignOpenFour() {
    const modalRef = this.modalService.open(EsignComponent, this.modalOption);
    modalRef.result.then(result => {});
    modalRef.componentInstance.base64Img.subscribe(data => {
      modalRef.close();
      this.finalImgPathFour = data.finalFilePath;
      let temp = {
        cmd_100072: this.finalImgPathFour
      };
      this.gaForm.patchValue(temp);
      this.finalImgPathFour = data.finalFilePath;
      this.finalImgFour = data.base64Img;
      this.esignFour = this._sanitizer.bypassSecurityTrustResourceUrl(
        'data:image/jpg;base64,' + this.finalImgFour
      );
      this.showFour = true;
    });
  }

  // public mplDoesNotApply(event) {
  //   // console.log(event.target.value, event.target.checked);
  //   this.mlpDoesNotApply = event.target.checked;
  //   if (this.mlpDoesNotApply) {
  //     this.gaForm.controls[1242].setErrors(null);
  //     this.gaForm.controls[1256].setErrors(null);
  //     this.gaForm.controls[1243].setErrors(null);
  //     this.gaForm.controls[1245].setErrors(null);
  //     this.gaForm.controls[1246].setErrors(null);
  //     this.gaForm.controls[1248].setErrors(null);
  //     this.gaForm.controls[1249].setErrors(null);
  //     this.gaForm.controls[1254].setErrors(null);
  //     this.gaForm.controls[1264].setErrors(null);
  //     this.gaForm.controls[1265].setErrors(null);
  //     this.gaForm.controls[1252].setErrors(null);
  //     this.gaForm.controls[1251].setErrors(null);
  //     this.gaForm.controls[1253].setErrors(null);
  //     this.gaForm.controls['cmd_0107'].setErrors(null);
  //     this.gaForm.controls['cmd_0108'].setErrors(null);
  //     this.gaForm.controls['cmd_0109'].setErrors(null);
  //     this.gaForm.controls['cmd_0110'].setErrors(null);
  //     this.gaForm.controls['cmd_0111'].setErrors(null);
  //     this.gaForm.controls['cmd_0112'].setErrors(null);
  //   }
  // }

  // public boardCerti(event) {
  //   // console.log(event.target.value, event.target.checked);
  //   this.boardCertiDoesnot = event.target.checked;
  //   if (this.boardCertiDoesnot) {
  //     this.gaForm.controls[560].setErrors(null);
  //     this.gaForm.controls[557].setErrors(null);
  //     this.gaForm.controls[554].setErrors(null);
  //     this.gaForm.controls[556].setErrors(null);
  //   }
  // }
  // public graduateProfessional(event) {
  //   // console.log(event.target.value, event.target.checked);
  //   this.graduateDegreeDoesnot = event.target.checked;
  //   if (this.graduateDegreeDoesnot) {
  //     this.gaForm.controls[479].setErrors(null);
  //     this.gaForm.controls[480].setErrors(null);
  //     this.gaForm.controls[482].setErrors(null);
  //     this.gaForm.controls[485].setErrors(null);
  //     this.gaForm.controls[487].setErrors(null);
  //     this.gaForm.controls[489].setErrors(null);
  //   }
  // }

  // public expalanationRequired(event) {
  //   // console.log(event.target.value, event.target.checked);
  //   this.ExplanInterruptionDoesnot = event.target.checked;
  //   if (this.ExplanInterruptionDoesnot) {
  //     this.gaForm.controls[1138].setErrors(null);
  //     this.gaForm.controls[1831].setErrors(null);
  //     this.gaForm.controls[1832].setErrors(null);
  //   }
  // }

  // public otherFacilityAff(event) {
  //   // console.log(event.target.value, event.target.checked);
  //   this.otherFacilityDoesnot = event.target.checked;
  //   if (this.otherFacilityDoesnot) {
  //     this.gaForm.controls[2061].setErrors(null);
  //     this.gaForm.controls[2067].setErrors(null);
  //     this.gaForm.controls[2062].setErrors(null);
  //     this.gaForm.controls[2064].setErrors(null);
  //     this.gaForm.controls[2066].setErrors(null);
  //     this.gaForm.controls[2072].setErrors(null);
  //     this.gaForm.controls[2074].setErrors(null);
  //   }
  // }

  // public preHosAff(event) {
  //   // console.log(event.target.value, event.target.checked);
  //   this.preHospDoesnot = event.target.checked;
  //   if (this.preHospDoesnot) {
  //     this.gaForm.controls[2025].setErrors(null);
  //     this.gaForm.controls[2042].setErrors(null);
  //     this.gaForm.controls[2044].setErrors(null);
  //     this.gaForm.controls[1303].setErrors(null);
  //     this.gaForm.controls[1189].setErrors(null);
  //     this.gaForm.controls[1186].setErrors(null);
  //     this.gaForm.controls[1184].setErrors(null);
  //   }
  // }

  // public mplDoesNotApplyFirst(event) {
  //   // console.log(event.target.value, event.target.checked);
  //   this.pliDoesnot = event.target.checked;
  //   if (this.pliDoesnot) {
  //     this.gaForm.controls[345].setErrors(null);
  //     this.gaForm.controls[346].setErrors(null);
  //     this.gaForm.controls[1240].setErrors(null);
  //     this.gaForm.controls[1266].setErrors(null);
  //     this.gaForm.controls[901].setErrors(null);
  //     this.gaForm.controls[903].setErrors(null);
  //     this.gaForm.controls[348].setErrors(null);
  //     this.gaForm.controls[349].setErrors(null);
  //     this.gaForm.controls[945].setErrors(null);
  //     this.gaForm.controls[906].setErrors(null);
  //     this.gaForm.controls[350].setErrors(null);
  //     this.gaForm.controls[351].setErrors(null);
  //     this.gaForm.controls[905].setErrors(null);
  //     this.gaForm.controls['cmd_089'].setErrors(null);
  //   }
  // }

  // public otherStateHealth(event) {
  //   // console.log(event.target.value, event.target.checked);
  //   this.otherStateCareDoesnot = event.target.checked;
  //   if (this.otherStateCareDoesnot) {
  //     this.gaForm.controls[1274].setErrors(null);
  //     this.gaForm.controls[300].setErrors(null);
  //     this.gaForm.controls[907].setErrors(null);
  //     this.gaForm.controls[308].setErrors(null);
  //     this.gaForm.controls[307].setErrors(null);
  //     this.gaForm.controls[1432].setErrors(null);
  //     this.gaForm.controls[999].setErrors(null);
  //     this.gaForm.controls[1010].setErrors(null);
  //     this.gaForm.controls[1004].setErrors(null);
  //     this.gaForm.controls[1003].setErrors(null);
  //   }
  // }

  // public milpublicHealth(event) {
  //   // console.log(event.target.value, event.target.checked);
  //   this.militaryHealthDoesnot = event.target.checked;
  //   if (this.militaryHealthDoesnot) {
  //     this.gaForm.controls[1139].setErrors(null);
  //     this.gaForm.controls[1140].setErrors(null);
  //     this.gaForm.controls[1141].setErrors(null);
  //     this.gaForm.controls[1144].setErrors(null);
  //     this.gaForm.controls[1145].setErrors(null);
  //     this.gaForm.controls['cmd_045'].setErrors(null);
  //     this.gaForm.controls['cmd_046'].setErrors(null);
  //     this.gaForm.controls['cmd_047'].setErrors(null);
  //   }
  // }

  // public medicalprofession(event) {
  //   // console.log(event.target.value, event.target.checked);
  //   this.MedicalProfessionalDoesnot = event.target.checked;
  //   if (this.MedicalProfessionalDoesnot) {
  //     this.gaForm.controls[490].setErrors(null);
  //     this.gaForm.controls[491].setErrors(null);
  //     this.gaForm.controls[493].setErrors(null);
  //     this.gaForm.controls[495].setErrors(null);
  //     this.gaForm.controls[496].setErrors(null);
  //     this.gaForm.controls[497].setErrors(null);
  //     this.gaForm.controls[498].setErrors(null);
  //     this.gaForm.controls[499].setErrors(null);
  //     this.gaForm.controls[500].setErrors(null);
  //     this.gaForm.controls['cmd_021'].setErrors(null);
  //   }
  // }

  // public foreignMedical(event) {
  //   // console.log(event.target.value, event.target.checked);
  //   this.ForeignMedicalDoesnot = event.target.checked;
  //   if (this.ForeignMedicalDoesnot) {
  //     this.gaForm.controls[514].setErrors(null);
  //     this.gaForm.controls[515].setErrors(null);
  //     this.gaForm.controls['cmd_023'].setErrors(null);
  //     this.gaForm.controls['cmd_024'].setErrors(null);
  //   }
  // }

  // public internship(event) {
  //   // console.log(event.target.value, event.target.checked);
  //   this.intersnipDoesnot = event.target.checked;
  //   if (this.intersnipDoesnot) {
  //     this.gaForm.controls[517].setErrors(null);
  //     this.gaForm.controls[518].setErrors(null);
  //     this.gaForm.controls[520].setErrors(null);
  //     this.gaForm.controls[522].setErrors(null);
  //     this.gaForm.controls[523].setErrors(null);
  //     this.gaForm.controls[524].setErrors(null);
  //     this.gaForm.controls[525].setErrors(null);
  //     this.gaForm.controls[526].setErrors(null);
  //     this.gaForm.controls[527].setErrors(null);
  //     this.gaForm.controls[528].setErrors(null);
  //   }
  // }

  // public residency(event) {
  //   // console.log(event.target.value, event.target.checked);
  //   this.residencyDoesnot = event.target.checked;
  //   if (this.residencyDoesnot) {
  //     this.gaForm.controls[529].setErrors(null);
  //     this.gaForm.controls[531].setErrors(null);
  //     this.gaForm.controls[530].setErrors(null);
  //     this.gaForm.controls[533].setErrors(null);
  //     this.gaForm.controls[535].setErrors(null);
  //     this.gaForm.controls[536].setErrors(null);
  //     this.gaForm.controls[537].setErrors(null);
  //     this.gaForm.controls[538].setErrors(null);
  //     this.gaForm.controls[539].setErrors(null);
  //     this.gaForm.controls[540].setErrors(null);
  //   }
  // }

  // public fellowship(event) {
  //   // console.log(event.target.value, event.target.checked);
  //   this.fellowshipDoesnot = event.target.checked;
  //   if (this.fellowshipDoesnot) {
  //     this.gaForm.controls[542].setErrors(null);
  //     this.gaForm.controls[544].setErrors(null);
  //     this.gaForm.controls[543].setErrors(null);
  //     this.gaForm.controls[546].setErrors(null);
  //     this.gaForm.controls[548].setErrors(null);
  //     this.gaForm.controls[549].setErrors(null);
  //     this.gaForm.controls[550].setErrors(null);
  //     this.gaForm.controls[551].setErrors(null);
  //     this.gaForm.controls[552].setErrors(null);
  //     this.gaForm.controls[553].setErrors(null);
  //   }
  // }

  // public otherClinical(event) {
  //   // console.log(event.target.value, event.target.checked);
  //   this.otherClinicalDoesnot = event.target.checked;
  //   if (this.otherClinicalDoesnot) {
  //     this.gaForm.controls[1288].setErrors(null);
  //     this.gaForm.controls[1291].setErrors(null);
  //     this.gaForm.controls[1294].setErrors(null);
  //     this.gaForm.controls[1295].setErrors(null);
  //     this.gaForm.controls[1297].setErrors(null);
  //     this.gaForm.controls[1298].setErrors(null);
  //     this.gaForm.controls[1299].setErrors(null);
  //     this.gaForm.controls[1628].setErrors(null);
  //     this.gaForm.controls[1296].setErrors(null);
  //     this.gaForm.controls[1293].setErrors(null);
  //   }
  // }

  //1147,1154,1146,1150,1152,1153,1155,1156,1158,1165,1157,1164,1166,1167
  // public facultyPosition(event) {
  //   // console.log(event.target.value, event.target.checked);
  //   this.facultyPositionsDoesnot = event.target.checked;
  //   if (this.facultyPositionsDoesnot) {
  //     this.gaForm.controls[1147].setErrors(null);
  //     this.gaForm.controls[1154].setErrors(null);
  //     this.gaForm.controls[1146].setErrors(null);
  //     this.gaForm.controls[1150].setErrors(null);
  //     this.gaForm.controls[1152].setErrors(null);
  //     this.gaForm.controls[1153].setErrors(null);
  //     this.gaForm.controls[1155].setErrors(null);
  //     this.gaForm.controls[1156].setErrors(null);
  //     this.gaForm.controls[1158].setErrors(null);
  //     this.gaForm.controls[1165].setErrors(null);
  //     this.gaForm.controls[1157].setErrors(null);
  //     this.gaForm.controls[1166].setErrors(null);
  //     this.gaForm.controls[1167].setErrors(null);
  //     this.gaForm.controls[1164].setErrors(null);
  //     this.gaForm.controls[1161].setErrors(null);
  //     this.gaForm.controls[1163].setErrors(null);
  //   }
  // }

  public drugEnforcement(event) {
    // console.log(event.target.value, event.target.checked);
    this.drugenforcementDoesnot = event.target.checked;
    if (this.drugenforcementDoesnot) {
      this.gaForm.controls[309].setErrors(null);
      this.gaForm.controls[311].setErrors(null);
    }
  }

  public proWorkHist(event) {
    // console.log(event.target.value, event.target.checked);
    this.proworkhistDoesnot = event.target.checked;
    if (this.proworkhistDoesnot) {
      this.gaForm.controls[455].setErrors(null);
    }
  }
  dateFormatValidator(control: FormControl): ValidationErrors | null {
     const dateString = control.value;
     const dateFormat = /^\d{2}\/\d{2}\/\d{4}$/;
     
     if (dateString && !dateString.match(dateFormat)) {
     return { 'invalidDateFormat': true };
     }
    
    
    
     if (dateString) {
       const dateParts = dateString.split('/');
       const month = parseInt(dateParts[0]);
       const day = parseInt(dateParts[1]);
      const year = parseInt(dateParts[2]);
    
     // Check if any of the date components are zero or month is not between 1 and 12
   
     const ListofDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
   
    
     if (month < 1 || month > 12 || day === 0 || year === 0) {
     return { 'invalidMonth': true };
   }
    if (month === 1 || month > 2) {
     if (day > ListofDays[month - 1]) {
    return { 'invalidDateRange': true };
     }
     } else if (month === 2) {
     const leapYear = (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
     if (!leapYear && day >= 29) {
    return { 'invalidDateRange': true };
     }
    else if (leapYear && day > 29) {
     return { 'invalidDateRange': true };
    }
    }
    if (year < 1900 || year > 2099) {
    return { 'invalidDateyear': true };
   }
    
  
    }
    
     return null;
    }
    clearInputIfInvalidMandi(controlName: string) {
     const control = this.gaForm.get(controlName);
     const dateString = control.value;
      
       // Use the dateFormatValidator to check if the date is valid
       const validationErrors = this.dateFormatValidator(new FormControl(dateString));
      
      if (validationErrors) {
      // If any of the validation conditions are met, clear the input field and set errors to null
      control.setValue('');
      
    }}
      clearInputIfInvalid(controlName: string,handleYesNoValue?:string) {
        const control = this.gaForm.get(controlName);
        const dateString = control.value;
       
        // Use the dateFormatValidator to check if the date is valid
       const validationErrors = this.dateFormatValidator(new FormControl(dateString));
        if (validationErrors) {
        // If any of the validation conditions are met, clear the input field and set errors to null
       control.setValue('');
         control.setErrors(null);
         }
        //     if(handleYesNoValue ){
        //     
        //    
        //           this.handleNAchange(handleYesNoValue);
        //     
        //       
        //       
        //     }
         
         }
         
}
