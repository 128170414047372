import { Injectable } from '@angular/core';
import { ApiServiceService } from '../../../../shared/services/cmd-api-service/api-service.service';

@Injectable({
  providedIn: 'root'
})
export class CannedReportService {

  constructor(private apiService: ApiServiceService) { }

  public getCannedReportType(postParams) {
    return this.apiService.post('reportcanned/getcannedreportstype', postParams);
  }
  public getReportsList(postParams) {
    return this.apiService.post('reportcanned/getcannedreportslist', postParams);
  }

  public getProviderList(postParams) {
    return this.apiService.post('cannedreport/providerData', postParams);
  }

  public getTinList(postParams) {
    return this.apiService.post('reportcanned/gettinslisting', postParams);
  }

  public getLocationdetailsfromtins(postParams) {
    return this.apiService.post('reportcanned/locationdetailsfromtins', postParams);
  }

  public getProviderdetailsfromlocation(postParams) {
    return this.apiService.post('reportcanned/providerdetailsfromlocation', postParams);
  }

  public getModalityCmeDetails(postParams) {
    return this.apiService.post('reportcanned/getModalityCmeDetails', postParams);
  }

  public getModalityCmeWorksheet(postParams) {
    return this.apiService.post('reportcanned/getModalityCmeWorksheet', postParams);
  }

  public getStatusList(postParams) {
    return this.apiService.post('reportcanned/getstatuslisting', postParams);
  }

  public getStateList(postParams) {
    return this.apiService.post('reportcanned/getstatelisting', postParams);
  }

  public getPayerList(postParams) {
    return this.apiService.post('reportcanned/getpayerslisting', postParams);
  }

  public getPayersTypeList(postParams) {
    return this.apiService.post('reportcanned/getpayerstypelisting', postParams);
  }

  public getLocationList(postParams) {
    return this.apiService.post('reportcanned/getlocationlisting', postParams);
  }
  public getLocationNPIList(postParams) {
    return this.apiService.post('queuedcannedreport/queuedcannedreportnpidetails', postParams);
  }

  public getMoctrackerCredentialList(postParams) {
    return this.apiService.post('reportcanned/getmoctrackercredentiallisting', postParams);
  }

  public getProviderCredentialList(postParams) {
    return this.apiService.post('reportcanned/getprovidercredentiallisting', postParams);
  }

  public getUmbrellalist(postParams) {
    return this.apiService.post('reportcanned/getumbrellalisting', postParams);
  }
  public getWorkflowUsersList(postParams) {
    return this.apiService.post('reportcanned/getworkflowuserslisting', postParams);
  }

  public getPHOPayerList(postParams) {
    return this.apiService.post('reportcanned/getphopayerlisting', postParams);
  }
  public getPhoPayerTypeList(postParams) {
    return this.apiService.post('reportcanned/getphopayertypelisting', postParams);
  }

  public getWorkFlowStatus(postParams) {
    return this.apiService.post('reportcanned/workflowstatusselection', postParams);
  }

  public getPrimaryLocations(postParams) {
    return this.apiService.post('reportcanned/providerprimarylocation', postParams);
  }

  /*CME Reports*/
  public generateAnesthesiacmecrnareports(postParams) {
    return this.apiService.post('cmecannedreport/anesthesiacmecrnareports', postParams);
  }

  public generateAnesthesiacmemdreports(postParams) {
    return this.apiService.post('cmecannedreport/anesthesiacmemdreports', postParams);
  }


  public generatePhysicianradiologycmeworksheet(postParams) {
    return this.apiService.post('cmecannedreport/physicianradiologycmeworksheet', postParams);
  }

  public generateAnesthesiacmeauditreports(postParams) {
    return this.apiService.post('cmecannedreport/anesthesiacmeauditrepports', postParams);
  }

  public generateRadiologycmetrackingreports(postParams) {
    return this.apiService.post('cmecannedreport/radiologycmetrackingreports', postParams);
  }

  public generateRadiologycmereportsummaryreport(postParams) {
    return this.apiService.post('cmecannedreport/radiologycmereportsummaryreport', postParams);
  }

  public generateAnesthesiacmeorganizationalreports(postParams) {
    return this.apiService.post('cmecannedreport/anesthesiacmeorganizationalreports', postParams);
  }

  public generateRadiologycmereportdetailreport(postParams) {
    return this.apiService.post('cmecannedreport/radiologycmereportdetailreport', postParams);
  }

  public generatecmecannedreport(postParams) {
    return this.apiService.post('cmecannedreport/cmecannedreport', postParams);
  }

  public generateMOCTrackerReport(postParams) {
    return this.apiService.post('cmecannedreport/moctrackerreport', postParams);
  }

  /* Credentialing */

  public generateAmerigroupCVOCredentialingUpdateRecordReport(postParams) {
    return this.apiService.post('cannedreport/amerigroupcvocredentialingupdaterecordreport', postParams);
  }

  public generateAmerigroupProviderUpdateRoster(postParams) {
    return this.apiService.post('cannedreport/amerigroupproviderupdateroster', postParams);
  }

  public generateBcbsgaUpdateReport(postParams) {
    return this.apiService.post('cannedreport/bcbsgaupdatereport', postParams);
  }

  public generateCaqhCredentialing(postParams) {
    return this.apiService.post('cannedreport/caqhcredentialing', postParams);
  }

  public generateCaqhCredentialingSpread(postParams) {
    return this.apiService.post('cannedreport/caqhcredentialingspread', postParams);
  }

  public generateCAQHTextFile(postParams) {
    return this.apiService.post('cannedreport/caqhtextfilereport', postParams); //need api Integartion
  }

  public generateCareImprovmentPlus(postParams) {
    return this.apiService.post('cannedreport/careimprovmentplus', postParams);
  }

  public generateCredDashboardReport(postParams) {
    return this.apiService.post('cannedreport/creddashboardreport', postParams);
  }

  public generatecredManagementDashboard(postParams) {
    return this.apiService.post('cannedreport/credmanagementdashboard', postParams);
  }

  public generateCredentialingOutlookReport(postParams) {
    return this.apiService.post('cannedreport/credentialingoutlookreport', postParams);
  }

  public generateCriticalitemsenrollmentspread(postParams) {
    return this.apiService.post('cannedreport/criticalitemsenrospread', postParams);
  }

  public generateDocumentationReport(postParams) {
    return this.apiService.post('cannedreport/documentationreport', postParams);
  }

  public generateEmpireBcbsNonCredentialProvider(postParams) {
    return this.apiService.post('cannedreport/empirebcbsnoncredentialprovider', postParams);
  }

  public generateEmpireBcbsProfessionalProviderReports(postParams) {
    return this.apiService.post('cannedreport/empirebcbsprofessionalproreports', postParams);
  }

  public generateHospitalAffstateStatusReport(postParams) {
    return this.apiService.post('cannedreport/hospitalaffstatestatusreport', postParams);
  }

  public generateHospitalAffiReports(postParams) {
    return this.apiService.post('cannedreport/hospitalaffireports', postParams);
  }

  public generateHumanaChoicecareCAQHMarketRosterReport(postParams) {
    return this.apiService.post('cannedreport/humanachoicecarecaqhmarketroster', postParams);
  }

  public generateHumanaCredentialingRosterReport(postParams) {
    return this.apiService.post('cannedreport/humanacredentialingrosterreports', postParams);
  }

  public generateHumanaUpdateReport(postParams) {
    return this.apiService.post('cannedreport/humanaupdatereport', postParams);
  }

  public generateLocationDemographicValuationReport(postParams) {
    return this.apiService.post('cannedreport/locationdemographicvaluationreports', postParams);
  }

  public generateMailMergeSpreadsheet(postParams) {
    return this.apiService.post('cannedreport/mailmerspreadsheet', postParams);
  }

  public generateMegallanRosterReport(postParams) {
    return this.apiService.post('cannedreport/megallanRosterreport', postParams);
  }

  public generateMembershipRosterReport(postParams) {
    return this.apiService.post('cannedreport/membershipRosterreport', postParams);
  }

  public generateNetworkParticipationRosterAetnaReport(postParams) {
    return this.apiService.post('cannedreport/networkparticipationrosteraetna', postParams);
  }

  public generatePeachStateHealthPlanRHCReport(postParams) {
    return this.apiService.post('cannedreport/peachstatehealthplanrhcreports', postParams);
  }

  public generateProviderNetworkParticipation(postParams) {
    return this.apiService.post('cannedreport/providernetworkparticipation', postParams);
  }

  public generateProviderRecredentialingReport(postParams) {
    return this.apiService.post('cannedreport/providerrecredentialingreports', postParams);
  }

  public josephhealthpartnersreport(postParams) {
    return this.apiService.post('pcannedreport/josephhealthpartnersreport', postParams);
  }

  public generateProviderExpenseReport(postParams) {
    return this.apiService.post('cannedreport/providerexpensereports', postParams);
  }

  public generateSemiAnnualCredentialingReport(postParams) {
    return this.apiService.post('cannedreport/semiannualcredentialingreports', postParams);
  }

  public generateSRSPrescriberUpdateRequestReport(postParams) {
    return this.apiService.post('cannedreport/srsprescriberUpdatereports', postParams);
  }

  public generateSunRxPanelUpdateReport(postParams) {
    return this.apiService.post('cannedreport/sunrxpanelupdatereports', postParams);
  }

  public generateUnitedHealthcareReport(postParams) {
    return this.apiService.post('cannedreport/unitedhealthcarereports', postParams);
  }

  /*CVO Reports*/
  public generateCVOMonitoringReport(postParams) {
    return this.apiService.post('cvocannedreport/cvomonitoringreports', postParams);
  }

  public generateSanctionBasedReport(postParams) {
    return this.apiService.post('cvocannedreport/sanctionbasedreports', postParams);
  }

  /*Delegated Credentialing*/


  public generateAetnaDelegatedCredentialingReport(postParams) {
    return this.apiService.post('dccannedreport/aetnadelegatedcredentialingreports', postParams);
  }


  public generateAlliantDelegatedCredentialingReport(postParams) {
    return this.apiService.post('dccannedreport/alliantdeligatedCredentreports', postParams);
  }


  public generateAmbetterDelegatedCredentialingForm(postParams) {
    return this.apiService.post('dccannedreport/ambetterdelogatedcredform', postParams);
  }


  public generateAmerigroupDelegatedCredentialingReport(postParams) {
    return this.apiService.post('dccannedreport/amerigroupdelegatedcredreports', postParams);
  }


  public generateAmerigroupGANewProviderTemplate(postParams) {
    return this.apiService.post('dccannedreport/amerigroupganewproviderytemplate', postParams);
  }


  public generateAmerigroupGATerminationProviderTemplate(postParams) {
    return this.apiService.post('dccannedreport/amerigroupgaterminationprovidertemp', postParams);
  }


  public generateAmerigroupGAUpdateProviderTemplate(postParams) {
    return this.apiService.post('dccannedreport/amerigroupgaupdateprovidertemp', postParams);
  }


  public generateAmerigroupTexasDelegatedRoster_providerReport(postParams) {
    return this.apiService.post('dccannedreport/amerigrouptexasdelegatedrostereportprovider', postParams);
  }


  public generateAmerigroupTexasDelegatedRoster_tinReport(postParams) {
    return this.apiService.post('dccannedreport/amerigrouptexasdelegatedrostereporttins', postParams);
  }


  public generateBCBSDelegatedCredentialingRoster(postParams) {
    return this.apiService.post('dccannedreport/bcbsdelegatedcredentialingroster', postParams);
  }

  public generateCareSourceDelegatedCredentialingReport(postParams) {
    return this.apiService.post('dccannedreport/caresourcedelegatedCredentialreport', postParams);
  }


  public generateCignaHealthspringDelegatedCredentialingReport(postParams) {
    return this.apiService.post('dccannedreport/cignahealthspringdelegatedcrentialreport', postParams);
  }


  public generateCoventryFirstHealthDelegatedCredentialingReport(postParams) {
    return this.apiService.post('dccannedreport/coventryfirshealthdelegatedcredentialingreport', postParams);
  }


  public generateHumanaDelegatedCredentialingReports(postParams) {
    return this.apiService.post('dccannedreport/humanadelegatedcredentialingreport', postParams);
  }


  public generatePeachstateandAmbetterDelegatedReports(postParams) {
    return this.apiService.post('dccannedreport/peachstateambetterdelegatedreports', postParams);
  }


  public generatePeachStateDelegatedCredentialing(postParams) {
    return this.apiService.post('dccannedreport/peachstatedelegatedcredentialing', postParams);
  }


  public generateSuperiorHealthPlanDelegatedCredentialingUpdateReport(postParams) {
    return this.apiService.post('dccannedreport/superiorhealthplandelegatedcredentialingupdatereport', postParams);
  }


  public generateSupermedDelegatedCredentialing(postParams) {
    return this.apiService.post('dccannedreport/supermeddelegatedcredentialreport', postParams);
  }


  public generateTodaysOptionDelegatedCredentialingReport(postParams) {
    return this.apiService.post('dccannedreport/todaysoptiondelegatedcredentialingreport', postParams);
  }


  public generateUHCDelegatedCredentialing(postParams) {
    return this.apiService.post('dccannedreport/uhcdelegatedcredentialingreport', postParams);
  }

  public generateWellcareDelegatedCredentialing(postParams) {
    return this.apiService.post('dccannedreport/wellcaredelegatedcredentialingreport', postParams);
  }

  public generateTricareDelegatedProviderRoster(postParams) {
    return this.apiService.post('dccannedreport/tricaredelegatedproviderroster', postParams);
  }

  /* Expirations*/

  public generateCertificationReport(postParams) {
    return this.apiService.post('/expcannedreport/certificationreports', postParams);
  }

  public generateExpirationStatusReport(postParams) {
    return this.apiService.post('expcannedreport/expirationstatusreports', postParams);
  }

  public generateHealthStatusExpirationReport(postParams) {
    return this.apiService.post('expcannedreport/healthstatusexpirationreports', postParams);
  }

  public generateLicenseExpirationReport(postParams) {
    return this.apiService.post('expcannedreport/licenseexpirationreports', postParams);
  }

  public generateLicenseMonitoringReport(postParams) {
    return this.apiService.post('expcannedreport/licensemonitoringreports', postParams);
  }

  public generateMedicalCertificationsReport(postParams) {
    return this.apiService.post('expcannedreport/medicalcertificationsreports', postParams);
  }

  /* Location Based*/

  public generateLocationBasedCredentialingStatusReport(postParams) {
    return this.apiService.post('lbcannedreport/locationbasedcredstatusrep', postParams);
  }

  public generateLocationWorkingReport(postParams) {
    return this.apiService.post('lbcannedreport/locationworkingrep', postParams);
  }

  public generateProviderLocationChanges(postParams) {
    return this.apiService.post('lbcannedreport/providerlocationchanges', postParams);
  }

  /*Payer*/

  public generateAetnaTXDelegatedRosterTemplate(postParams) {
    return this.apiService.post('pcannedreport/aetnatxdelegatedrostertemp', postParams);
  }

  public generateBCBSCredentialingStatusReport(postParams) {
    return this.apiService.post('pcannedreport/bcbscredentialingStatusrep', postParams);
  }
  public generateBCBSTXRosterTemplate(postParams) {
    return this.apiService.post('pcannedreport/bcbstxrostertemplaterep', postParams);
  }
  public generateCarrierParticipationRoster(postParams) {
    return this.apiService.post('pcannedreport/carrierparticipationroster', postParams);
  }
  public generateContractStatusSpreadsheet(postParams) {
    return this.apiService.post('pcannedreport/contractstatusspreadsheetrep', postParams);
  }
  public generateContractWorkingReport(postParams) {
    return this.apiService.post('pcannedreport/contractworkingreportrep', postParams);
  }
  public generateCredentialingStatusExpandedReport(postParams) {
    return this.apiService.post('pcannedreport/credentialingstatusexpandedrep', postParams);
  }
  public generateCredentialingStatusReport(postParams) {
    return this.apiService.post('pcannedreport/credentialingstatusrep', postParams);
  }
  public generateFacilityRosterandPayerDetailsReport(postParams) {
    return this.apiService.post('pcannedreport/facilityrosterpayerdetailsrep', postParams);
  }
  public generateNewProviderPayerandAffilationMatrix(postParams) {
    return this.apiService.post('pcannedreport/newproviderpayeraffilationmatrix', postParams);
  }
  public generateOrganizationCredentialingandPrivilegingStatusReport(postParams) {
    return this.apiService.post('pcannedreport/organizationcredentialingprivilegingstatusrep', postParams);
  }
  public generatePayerIncentiveReport(postParams) {
    return this.apiService.post('pcannedreport/payerincentiverep', postParams);
  }
  public generatePayerScorecardReport(postParams) {
    return this.apiService.post('pcannedreport/payerScorecardrep', postParams);
  }
  public generatePHOPayerRosterReport(postParams) {
    return this.apiService.post('pcannedreport/phopayerrosterrep', postParams);
  }

  public generatePhysicianContractsReport(postParams) {
    return this.apiService.post('pcannedreport/physiciancontractsrep', postParams);
  }

  public generateProviderPayerChanges(postParams) {
    return this.apiService.post('pcannedreport/providerpayerchangesrep', postParams);
  }

  public generateValanceContractStatusReport(postParams) {
    return this.apiService.post('pcannedreport/valancecontractstatusrep', postParams);
  }

  public generateWeeklyCredentialingUpdateReport(postParams) {
    return this.apiService.post('pcannedreport/weeklycredentialingupdaterep', postParams);
  }

  public generateWeeklyPhysicianContractsReport(postParams) {
    return this.apiService.post('pcannedreport/weeklyphysiciancontractsrep', postParams);
  }

  public generateWeeklyTerminationContractReport(postParams) {
    return this.apiService.post('pcannedreport/weeklyterminationcontractreport', postParams);
  }


  /*Roaster*/

  public generateIllinoisAssociationMedicaidHealthPlansMCOCarrierRoster(postParams) {
    return this.apiService.post('rcannedreport/illinoisAsscarrierrosterrep', postParams);
  }

  public generateBCBSIPAStandardFormatRoster(postParams) {
    return this.apiService.post('rcannedreport/bcbsipastandardformatroster', postParams);
  }

  public generateLocationRosterReport(postParams) {
    return this.apiService.post('rcannedreport/providertinlocationrosterrep', postParams);
  }

  public generatePhysicianLocationReport(postParams) {
    return this.apiService.post('rcannedreport/physicianlocationRep', postParams);
  }

  public generatePhysicianSpreadsheet(postParams) {
    return this.apiService.post('rcannedreport/physicianspreadsheetrep', postParams);
  }

  public generatePracticeManagersRoster(postParams) {
    return this.apiService.post('rcannedreport/practiceManagersRosterRep', postParams);
  }

  public generateProviderAdditionalInformationRosterReport(postParams) {
    return this.apiService.post('rcannedreport/provideradditionalinformationrosterrep', postParams);
  }

  public generateProviderNPIRosterReport(postParams) {
    return this.apiService.post('rcannedreport/providernpirosterrep', postParams);
  }

  public generateProviderOfficeManagerRoster(postParams) {
    return this.apiService.post('rcannedreport/provideofficemanagerrosterrep', postParams);
  }


  public generateProviderRosterSortableReport(postParams) {
    return this.apiService.post('rcannedreport/providerrostersortablereport', postParams);
  }

  public generateTINContractMatrix(postParams) {
    return this.apiService.post('rcannedreport/tincontractmatrixreport', postParams);
  }

  /*WorkFlow*/

  public generateManagementCareTrackingReport(postParams) {
    return this.apiService.post('wcannedreport/managementcaretrackingreport', postParams);
  }

  public generatePayerWorkflowProgressReport(postParams) {
    return this.apiService.post('wcannedreport/payerworkflowprogressreport', postParams);
  }

  public generateSynergyPayerWorkflowProgressReport(postParams) {
    return this.apiService.post('wcannedreport/synergypayerworkflowprogressRep', postParams);
  }

  public generateTINBillingReport(postParams) {
    return this.apiService.post('wcannedreport/tinbillingreportdownload', postParams);
  }

  public generateWorkflowActivityReport(postParams) {
    return this.apiService.post('wcannedreport/workflowactivityreport', postParams);
  }

  public generateWorkflowSummaryReport(postParams) {
    return this.apiService.post('wcannedreport/workflowSummaryRep', postParams);
  }

  /*Provider Tin Nad Location  Report api */
  public generateProviderTinLocationrosterReport(postParams) {
    return this.apiService.post('rcannedreport/providertinlocationrosterrep', postParams);
  }

  public generateTinrosterReport(postParams) {
    return this.apiService.post('rcannedreport/tinrosterrep', postParams);
  }

  public generateLocationrosterReport(postParams) {
    return this.apiService.post('rcannedreport/locationrosterrep', postParams);
  }

  public generateTinWorkflowstatusReport(postParams) {
    return this.apiService.post('wcannedreport/tinworkflowstatusreport', postParams);
  }

  public generateWorkflowPayerDashboardReport(postParams) {
    return this.apiService.post('wcannedreport/workflowpayerdashboardreport', postParams);
  }

  public generateMedicalAssociatesHealthPlanRoster(postParams) {
    return this.apiService.post('rcannedreport/medicalassociateshealthplanroster', postParams);
  }
  /*Queue report*/

  public generateQueuedReport(postParams) {
    return this.apiService.post('queuedcannedreport/queuedcannedreportdownload', postParams);
  }

  public getproviderlistBystatus(postParams) {
    return this.apiService.post('cannedreport/providerlistrostersortable', postParams);
  }

}


