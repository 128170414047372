<div  id="csc_care">
  <div class="header">
          <div class="col-md-2">
            <img src="./assets/images/Eye_helth_care.jpg"  alt="Credential my Doc" class="imStyle" style="height: 100px; width: 291px; margin-bottom: 6px;" >
            
         </div> 
    </div>
    <form [formGroup]="eyeForm">
        <div class="text-center">
            <h2>CREDENTIALING APPLICATION</h2> 
        </div>
        <table class="table tabwidth">
            <tr>
                <td colspan="12">
                    <div class="centered" class="identifyBox">
                        <h3 class="hThreeAlign">
                            II. Practitioner Information 
                          <span class="normalParagraphStyle">
                            <i>Please provide the practitioner’s full legal name.</i>
                          </span>
                        </h3>
                      </div>
                </td>
            </tr>   
                <tr>
                    <td colspan="12">
                        <div class="row">
                            <div class="col-md-3"> 
                                <label class="form-label" style="font-size: 14px;">Last Name(include suffix;Jr., Sr., III):</label>
                                <input class="form-control onboard"  formControlName="9"
                                    id="9" name="9" type="text" (change)="onChangeLastName($event)" placeholder= "Last name" data-id="managing_contact_name" 
                                    [class.is-invalid]="(eyeForm.controls['9'].touched && eyeForm.controls['9'].invalid) || (eyeForm.controls['9'].invalid && isSubmit)"  >
                                <div class="formLabel col-md-11"
                                    *ngIf="eyeForm.controls['9'].touched && eyeForm.controls['9'].invalid">
                                    <small class="text-danger" *ngIf="eyeForm.controls['9'].errors?.required">
                                        required
                                    </small>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <label class="form-label" style="font-size: 14px;">First:</label>  
                                <input class="form-control onboard" (change)="onChangeFirstName($event)"   formControlName="8"
                                    id="8" name="8" type="text" placeholder= "First Name" data-id="managing_contact_name"
                                    [class.is-invalid]="(eyeForm.controls['8'].touched && eyeForm.controls['8'].invalid) || (eyeForm.controls['8'].invalid && isSubmit)"  >
                                <div class="formLabel col-md-11"
                                    *ngIf="eyeForm.controls['8'].touched && eyeForm.controls['8'].invalid">
                                    <small class="text-danger" *ngIf="eyeForm.controls['8'].errors?.required">
                                        required
                                    </small>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <label class="form-label" style="font-size: 14px;">Middle:</label>
                                <input class="form-control onboard" (change)="onChangeMiddleName($event)" formControlName="809"
                                    id="809" name="809" type="text" placeholder= "Middle Name" data-id="managing_contact_name" 
                                    [class.is-invalid]="(eyeForm.controls['809'].touched && eyeForm.controls['809'].invalid) || (eyeForm.controls['809'].invalid && isSubmit)" >
                                <div class="formLabel col-md-11"
                                    *ngIf="eyeForm.controls['809'].touched && eyeForm.controls['809'].invalid">
                                    <small class="text-danger" *ngIf="eyeForm.controls['809'].errors?.required">
                                        required
                                    </small>
                                </div> 
                            </div>
                            <div class="col-md-3"> 
                                <div
                                class=" form-group selectDropTwo degree inputPadding"
                              >
                              <label class="formlabel" style="font-size: 14px;">Degree(s):</label>
                                <ng-select
                                  [items]="crdential"
                                  bindLabel="name"
                                  placeholder=" Credentials"
                                  bindValue="id"
                                  formControlName="822"
                                  [class.is-invalid]="(eyeForm.controls['822'].touched && eyeForm.controls['822'].invalid) || (eyeForm.controls['822'].invalid && isSubmit)" >
                                  
                                >
                                </ng-select>
                                <div
                                  class="formLabel col-md-11"
                                  *ngIf="
                                  eyeForm.controls['822'].touched &&
                                  eyeForm.controls['822'].invalid
                                  "
                                >
                                  <small
                                    class="text-danger"
                                    *ngIf="eyeForm.controls['822'].errors"
                                  >
                                    required
                                  </small>
                                </div>
                              </div>
                            </div>
                        </div>
                    </td>
                </tr> 
                <tr>
                    <td colspan="12">
                        <div class="row">
                        <div class="col-md-8">
                            <label class="formLabel" style="font-size: 16px;">Is there any other name under which you have been known or have used since starting professional training?</label>
                        </div>
                      
                        <div class="col-md-4">
                            <label class="radio-inline radioStyle">
                                <input
                                    type="radio"
                                    id="cmd_001"
                                    name="cmd_001"
                                    [class.is-invalid]="(eyeForm.controls['cmd_001'].touched 
                                    && eyeForm.controls['cmd_001'].invalid) || 
                                    (eyeForm.controls['cmd_001'].invalid && isSubmit)"
                                    formControlName="cmd_001"
                                    value="1"
                                />Yes</label>
                                <label class="radio-inline radioStyle">
                                <input
                                     type="radio"
                                     id="cmd_001"
                                    name="cmd_001"
                                    [class.is-invalid]="(eyeForm.controls['cmd_001'].touched 
                                    && eyeForm.controls['cmd_001'].invalid) || 
                                    (eyeForm.controls['cmd_001'].invalid && isSubmit)"
                                    formControlName="cmd_001"
                                    value="0"
                                />No</label>
                                <div class="formLabel"
                                *ngIf="eyeForm.controls['cmd_001'].touched && eyeForm.controls['cmd_001'].invalid">
                                <small class="text-danger" *ngIf="eyeForm.controls['cmd_001'].errors?.required">
                                    required
                                </small>
                               </div>
                               <span *ngIf="(eyeForm.controls['cmd_001'].touched && eyeForm.controls['cmd_001'].errors)
                               || (eyeForm.controls['cmd_001'].errors && isSubmit)">
                             <i class="fas fa-times ml-2 text-danger"></i>
                                </span>
                        </div>
                        <div class="row">
                            <label style="margin-left: 28px; font-size: 14px;" class="formLabel">Name(s) and Year(s) Used: Prior Name </label>
                        </div>
                            <div class="col-md-4">
                                <input class="form-control" id="278" name="278"  placeholder="Prior Name"  formControlName="278"/>
                                
                        </div>
                    </div>
                    </td>
                </tr> 
                <tr>
                    <td colspan="12">
                    <label class="form-label" style="font-size: 14px;">Home street address:</label>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="row">
                        <div class="col-md-6"> 
                            <input class="form-control onboard"  formControlName="16"
                                id="16" name="16" type="text" placeholder= "Home Address 1" data-id="managing_contact_name" [class.is-invalid]="(eyeForm.controls['16'].touched && eyeForm.controls['16'].invalid) || (eyeForm.controls['16'].invalid && isSubmit)" >
                            <div class="formLabel col-md-11"
                                *ngIf="eyeForm.controls['16'].touched && eyeForm.controls['16'].invalid">
                                <small class="text-danger" *ngIf="eyeForm.controls['16'].errors?.required">
                                    required
                                </small>
                            </div>
                        </div>
                        <div class="col-md-6"> 
                            <input class="form-control onboard"  formControlName="17"
                                id="17" name="17" type="text" placeholder= "Home Address 2" data-id="managing_contact_name"  >
                            <!-- <div class="formLabel col-md-11"
                                *ngIf="eyeForm.controls['17'].touched && eyeForm.controls['17'].invalid">
                                <small class="text-danger" *ngIf="eyeForm.controls['17'].errors?.required">
                                    required
                                </small>
                            </div> -->
                        </div>
                    </div>
                    </div>
                    <div class="col-md-6">
                        <div class="row">
                        <div class="col-md-6"> 
                            <label class="form-label" style="font-size: 14px;"> Home telephone number:</label>
                            <input class="form-control onboard"  formControlName="708" (keydown)="handleNumber($event,708)" maxlength="12"
                                id="708" name="708" type="text" placeholder= "Home Phone" data-id="managing_contact_name" [class.is-invalid]="(eyeForm.controls['708'].touched && eyeForm.controls['708'].invalid) || (eyeForm.controls['708'].invalid && isSubmit)" >
                            <div class="formLabel col-md-11"
                                *ngIf="eyeForm.controls['708'].touched && eyeForm.controls['708'].invalid">
                                <small class="text-danger" *ngIf="eyeForm.controls['708'].errors?.required">
                                    required
                                </small>
                                <small class="text-danger" *ngIf="eyeForm.controls['708'].errors?.pattern">
                                  Required pattern xxx-xxx-xxxx
                                 </small>
                            </div>
                        </div>
                        <div class="col-md-6"> 
                            <label class="form-label" style="font-size: 14px;">Mobile/alternate number:</label>
                            <input class="form-control onboard"  formControlName="709" (keydown)="handleNumber($event,709)" maxlength="12"
                                id="709" name="709" type="text" placeholder= "Mobile Phone
                                " data-id="managing_contact_name" [class.is-invalid]="(eyeForm.controls['709'].touched && eyeForm.controls['709'].invalid) || (eyeForm.controls['709'].invalid && isSubmit)" >
                            <div class="formLabel col-md-11"
                                *ngIf="eyeForm.controls['709'].touched && eyeForm.controls['709'].invalid">
                                <small class="text-danger" *ngIf="eyeForm.controls['709'].errors?.required">
                                    required
                                </small>
                                <small class="text-danger" *ngIf="eyeForm.controls['709'].errors?.pattern">
                                  Required pattern xxx-xxx-xxxx
                                 </small>
                            </div>
                      </div>  
                    </div> 
                    <div class="row">
                        <div class="col-md-12"> 
                            <label class="form-label" style="font-size: 14px;">Email address:</label>
                            <input class="form-control onboard"  formControlName="1664"
                                id="1664" name="1664" type="text" placeholder= "Email" data-id="managing_contact_name" [class.is-invalid]="(eyeForm.controls['1664'].touched && eyeForm.controls['1664'].invalid) || (eyeForm.controls['1664'].invalid && isSubmit)" >
                            <div class="formLabel col-md-11"
                                *ngIf="eyeForm.controls['1664'].touched && eyeForm.controls['1664'].invalid">
                                <small class="text-danger" *ngIf="eyeForm.controls['1664'].errors?.required">
                                    required
                                </small>
                                <small
                                class="text-danger"
                                *ngIf="eyeForm.controls['1664'].errors.pattern"
                                >Please enter a valid email.</small>
                            </div>
                        </div>
                </div>  
                    </div>  
                    </div>
                </td>  
                </tr>  
                <tr>
                    <td colspan="12">
                        <div class="row">
                            <div class="col-md-4"> 
                                <label class="form-label" style="font-size: 14px;">City:</label>
                                <input class="form-control onboard"  formControlName="18"
                                    id="18" name="18" type="text" placeholder= "Home City
                                    " data-id="managing_contact_name" [class.is-invalid]="(eyeForm.controls['18'].touched && eyeForm.controls['18'].invalid) || (eyeForm.controls['18'].invalid && isSubmit)" >
                                <div class="formLabel col-md-11"
                                    *ngIf="eyeForm.controls['18'].touched && eyeForm.controls['18'].invalid">
                                    <small class="text-danger" *ngIf="eyeForm.controls['18'].errors?.required">
                                        required
                                    </small>
                                </div>
                          </div>   
                          <div class="form-group col-md-4 sideLabelAlignment">
                            <label class="formLabel " style="font-size: 14px;">State:</label>
                            <div class="form-group col-md-12 dropDrownSet">
                              <ng-select
                                [items]="states"
                                [class.is-invalid]="
                                  (eyeForm.controls['20'].touched &&
                                  eyeForm.controls['20'].invalid) ||
                                  (eyeForm.controls['20'].invalid && isSubmit)
                                "
                                bindLabel="name"
                                placeholder="Home State"
                                bindValue="id"
                                formControlName="20"
                              >
                              </ng-select>
                              <div
                                class="formLabel col-md-2"
                                *ngIf="
                                eyeForm.controls['20'].touched &&
                                eyeForm.controls['20'].invalid
                                "
                              >
                                <small
                                  class="text-danger"
                                  *ngIf="eyeForm.controls['20'].errors?.required"
                                >
                                  required
                                </small>
                              </div>
                            </div>
                          </div>   
                      <div class="col-md-4"> 
                        <label class="form-label" style="font-size: 14px;">ZIP:</label>
                        <input class="form-control onboard"  formControlName="21"
                            id="21" name="21" type="text" placeholder= "Home Zip
                            " data-id="managing_contact_name" [class.is-invalid]="(eyeForm.controls['21'].touched && eyeForm.controls['21'].invalid) || (eyeForm.controls['21'].invalid && isSubmit)" >
                        <!-- <div class="formLabel col-md-11"
                            *ngIf="eyeForm.controls['21'].touched && eyeForm.controls['21'].invalid">
                            <small class="text-danger" *ngIf="eyeForm.controls['21'].errors?.required">
                                required
                            </small>
                        </div> -->
                        <div
                    class="formLabel col-md-2"
                    *ngIf="
                      
                      eyeForm.controls['21'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="eyeForm.controls['21'].errors?.required"
                    >
                      required
                    </small>
                    <small
                      class="text-danger"
                      *ngIf="
                        eyeForm.controls['21'].errors?.invalidZipCode
                      "
                    >
                      Zip code must be atleast 5 digits and less than 10 digits
                    </small>
                  </div>
                  </div>   
                        </div>
                    </td>
                </tr>  
                <tr>
                    <td colspan="12">
                        <div class="row">
                            <div class="col-md-3">
                                <label class="formLabel " style="font-size: 14px;">Country:</label>
                            <div class="form-group col-md-12 dropDrownSet">
                                <ng-select
                                  [items]="country"
                                  [class.is-invalid]="
                                    (eyeForm.controls['19'].touched &&
                                    eyeForm.controls['19'].invalid) ||
                                    (eyeForm.controls['19'].invalid && isSubmit)
                                  "
                                  bindLabel="name"
                                  placeholder="Home Country"
                                  bindValue="id"
                                  formControlName="19"
                                >
                                </ng-select>
                                <div
                                  class="formLabel col-md-2"
                                  *ngIf="
                                  eyeForm.controls['19'].touched &&
                                  eyeForm.controls['19'].invalid
                                  "
                                >
                                  <small
                                    class="text-danger"
                                    *ngIf="eyeForm.controls['19'].errors?.required"
                                  >
                                    required
                                  </small>
                                </div>
                              </div>  
                            </div>  
                          <div class="col-md-3">    
                            <label class="form-label" style="font-size: 14px;"> Birth Date:  Month/Day/Year</label>
                            <div class="input-group">
                               <input
                                 class="form-control onboard"
                                 [class.is-invalid]="
                                (eyeForm.controls['31'].touched &&
                                eyeForm.controls['31'].invalid) ||
                                (eyeForm.controls['31'].invalid && isSubmit)
                               "
                                placeholder="DOB"
                                ngbDatepicker
                                #d01="ngbDatepicker"
                                 id="31"
                                  name="31"
                                 formControlName="31"
                                 (blur)="clearInputIfInvalidMandi('31')"
                                  type="text"
                                  data-id="dob_date"/>
                                  <div class="input-group-append">
                                <button
                                class="btn btn-outline-secondary calendar"
                                (click)="d01.toggle()"
                                type="button">
                                <i class="fas fa-calendar-alt"></i>
                              </button>
                            </div>
                            <small class="text-danger" *ngIf="eyeForm.get('31').errors?.invalidDateFormat">
                              Only Date Format is allowed(MM/DD/YYYY)                </small>
                            <small class="text-danger" *ngIf="eyeForm.get('31').errors?.invalidMonth">
                            Month is out of range
                            </small>
                            <small class="text-danger" *ngIf="eyeForm.get('31').errors?.invalidDateyear">
                              year is out of range(1900-2099)                  </small>
                              <small class="text-danger" *ngIf="eyeForm.get('31').errors?.invalidDateRange">
                                date is out of range
                                </small>
                          </div>
                          <div
                            class="formLabel col-md-2"
                            *ngIf="
                            eyeForm.controls['31'].touched &&
                            eyeForm.controls['31'].invalid
                            ">
                            <small class="text-danger"*ngIf="eyeForm.controls['31'].errors?.required">
                              required
                            </small>
                          </div>
                        </div>
                          <div class="col-md-2"> 
                            <label class="formLabel">PLACE OF BIRTH:</label>
                            <input class="form-control onboard"  formControlName="810"
                                id="810" name="810" type="text" placeholder= "City of Birth
                                " data-id="managing_contact_name" [class.is-invalid]="(eyeForm.controls['810'].touched && eyeForm.controls['810'].invalid) || (eyeForm.controls['810'].invalid && isSubmit)" >
                            <div class="formLabel col-md-11"
                                *ngIf="eyeForm.controls['810'].touched && eyeForm.controls['810'].invalid">
                                <small class="text-danger" *ngIf="eyeForm.controls['810'].errors?.required">
                                    required
                                </small>
                            </div>
                      </div>  
                            <div class="col-md-2 mt-4">
                              <div class="form-group  dropDrownSet">
                                <ng-select
                                  [items]="states"
                                  [class.is-invalid]="
                                    (eyeForm.controls['289'].touched &&
                                      eyeForm.controls['289'].invalid) ||
                                    (eyeForm.controls['289'].invalid && isSubmit)
                                  "
                                  bindLabel="name"
          
                                  bindValue="id"
                                  formControlName="289"
                                  placeholder="State of Birth"
                                >
                                </ng-select>
                                <div
                                  class="formLabel col-md-2"
                                  *ngIf="
                                    eyeForm.controls['289'].touched &&
                                    eyeForm.controls['810'].invalid
                                  "
                                >
                                  <small
                                    class="text-danger"
                                    *ngIf="eyeForm.controls['289'].errors?.required"
                                  >
                                    required
                                  </small>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-2 mt-4"> 
                                <input class="form-control onboard"  formControlName="291"
                                    id="291" name="291" type="text" placeholder= " Country of Birth
                                    " data-id="managing_contact_name" [class.is-invalid]="(eyeForm.controls['291'].touched && eyeForm.controls['291'].invalid) || (eyeForm.controls['291'].invalid && isSubmit)" >
                                <div class="formLabel col-md-11"
                                    *ngIf="eyeForm.controls['291'].touched && eyeForm.controls['291'].invalid">
                                    <small class="text-danger" *ngIf="eyeForm.controls['291'].errors?.required">
                                        required
                                    </small>
                                </div>
                          </div>  
                        </div>
                    </td>
                </tr> 
                <tr>
                    <td colspan="12">
                        <div class="row">
                            <div class="col-md-4">
                                <label class="formLabel">Citizenship:</label> 
                                <input class="form-control onboard"  formControlName="292"
                                    id="292" name="292" type="text" placeholder= " Citizenship
                                    " data-id="managing_contact_name" [class.is-invalid]="(eyeForm.controls['292'].touched && eyeForm.controls['292'].invalid) || (eyeForm.controls['292'].invalid && isSubmit)" >
                                <div class="formLabel col-md-11"
                                    *ngIf="eyeForm.controls['292'].touched && eyeForm.controls['292'].invalid">
                                    <small class="text-danger" *ngIf="eyeForm.controls['292'].errors?.required">
                                        required
                                    </small>
                                </div>
                          </div> 
                          <div class="col-md-4"> 
                            <label class="formLabel">Social Security number:</label>
                            <input class="form-control onboard"  formControlName="32"
                                id="32" name="32" type="text" placeholder= " SSN
                                " data-id="managing_contact_name" [class.is-invalid]="(eyeForm.controls['32'].touched && eyeForm.controls['32'].invalid) || (eyeForm.controls['32'].invalid && isSubmit)" >
                            <div class="formLabel col-md-11"
                                *ngIf="eyeForm.controls['32'].touched && eyeForm.controls['32'].invalid">
                                <small class="text-danger" *ngIf="eyeForm.controls['32'].errors?.required">
                                    required
                                </small>
                            </div>
                      </div>
                      <div class="col-md-4">
                        <label class="formLabel">Gender:</label>
                        <div class="form-group selectDrop dropDrownSet">
                                <ng-select [items]="gender" bindLabel="name" placeholder=" Gender" bindValue="id"
                                    [class.is-invalid]="(eyeForm.controls['30'].touched && eyeForm.controls['30'].invalid) || (eyeForm.controls['30'].invalid && isSubmit)"
                                    formControlName="30">
                                </ng-select>
                                <div class="formLabel col-md-11"
                                    *ngIf="eyeForm.controls['30'].touched && eyeForm.controls['30'].invalid">
                                    <small class="text-danger" *ngIf="eyeForm.controls['30'].errors?.required">
                                        required
                                    </small>
                                </div>
                            </div>
                        <!-- </div> -->
                    </div>
                        </div>
                    </td>
                </tr>   
                <tr>
                    <td colspan="12">
                        <div class="row">
                            <div class="col-md-3">
                                <label class="formLabel">Immigrant Visa number (if applicable):</label> 
                                <input class="form-control onboard"  formControlName="1584"
                                    id="1584" name="1584" type="text" placeholder= " Visa Number
                                    " data-id="managing_contact_name">
                               
                          </div> 
                          <div class="col-md-3">    
                            <label class="form-label" style="font-size: 14px;"> Visa expiration date:</label>
                            <div class="input-group">
                               <input
                                 class="form-control onboard"
                                placeholder="Visa Expiration date"
                                ngbDatepicker
                                #d1585="ngbDatepicker"
                                 id="1585"
                                  name="1585"
                                 formControlName="1585"
                                  type="text"
                                  data-id="dob_date"
                                  (blur)="clearInputIfInvalid('1585')"
                                  />
                                  <div class="input-group-append">
                                <button
                                class="btn btn-outline-secondary calendar"
                                (click)="d1585.toggle()"
                                type="button">
                                <i class="fas fa-calendar-alt"></i>
                              </button>
                            </div>
                            <div  *ngIf="eyeForm.get('1585').invalid ">
                              <small class="text-danger" *ngIf="eyeForm.get('1585').errors.invalidDateFormat">
                                Only Date Format is allowed(MM/DD/YYYY)                </small>
                              <small class="text-danger" *ngIf="eyeForm.get('1585').errors.invalidMonth">
                              Month is out of range
                              </small>
                              <small class="text-danger" *ngIf="eyeForm.get('1585').errors.invalidDateyear">
                                year is out of range(1900-2099)                  </small>
                                <small class="text-danger" *ngIf="eyeForm.get('1585').errors.invalidDateRange">
                                  date is out of range
                                  </small>
                                </div>
                          </div>
                        </div>
                        <div class="col-md-3">
                            <label class="formLabel">Status:</label> 
                            <input class="form-control onboard"  formControlName="1282"
                                id="1282" name="1282" type="text" placeholder= " Visa Status
                                " data-id="managing_contact_name">
                           
                      </div> 
                      <div class="col-md-3">
                        <label class="formLabel">Type:</label> 
                        <input class="form-control onboard"  formControlName="1281"
                            id="1281" name="1281" type="text" placeholder= " Visa Type
                            " data-id="managing_contact_name">
                       
                  </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="12">
                        <div class="row">
                            <div class="col-md-6">
                                <label class="formLabel">Educational Commission for Foreign Medical Graduates (ECFMG) number (if applicable):</label> 
                                <input class="form-control onboard"  formControlName="514"
                                    id="514" name="514" type="text" placeholder= " ECFMG Number
                                    " data-id="managing_contact_name">
                               
                          </div>
                          <div class="col-md-3">    
                            <label class="form-label" style="font-size: 14px;">Month/Year Issued:</label>
                            <div class="input-group">
                               <input
                                 class="form-control onboard"
                                placeholder="ECFMG Issue"
                                ngbDatepicker
                                #d0145="ngbDatepicker"
                                 id="515"
                                  name="515"
                                 formControlName="515"
                                 (blur)="clearInputIfInvalid('515')"
                                  type="text"
                                  data-id="dob_date"/>
                                  <div class="input-group-append">
                                <button
                                class="btn btn-outline-secondary calendar"
                                (click)="d0145.toggle()"
                                type="button">
                                <i class="fas fa-calendar-alt"></i>
                              </button>
                            </div>
                            <div  *ngIf="eyeForm.get('515').invalid ">
                              <small class="text-danger" *ngIf="eyeForm.get('515').errors.invalidDateFormat">
                                Only Date Format is allowed(MM/DD/YYYY)                </small>
                              <small class="text-danger" *ngIf="eyeForm.get('515').errors.invalidMonth">
                              Month is out of range
                              </small>
                              <small class="text-danger" *ngIf="eyeForm.get('515').errors.invalidDateyear">
                                year is out of range(1900-2099)                  </small>
                                <small class="text-danger" *ngIf="eyeForm.get('515').errors.invalidDateRange">
                                  date is out of range
                                  </small>
                                </div>
                          </div>
                        </div>
                        </div>
                    </td>
                </tr>
                <tr>
                  <td colspan="12">
                      <div class="centered" class="identifyBox">
                          <h3 class="hThreeAlign">
                            III. Specialty Information 
                            <span class="normalParagraphStyle">
                              <i>This information may be included in directory listings.</i>
                            </span>
                          </h3>
                        </div>
                  </td>
              </tr>
              <tr class="border">
                <td colspan="4">
                  Principal clinical specialty
                  <ng-select
                  [items]="specialty"
                  [class.is-invalid]="eyeForm.controls['284'].invalid" 
                  bindLabel="name"
                  placeholder="Primary Specialty"
                  bindValue="id"
                  formControlName="284"
                  style="width:400px"
                >
                </ng-select>
                <div
                  class="formLabel col-md-2"
                  *ngIf="
                    eyeForm.controls['284'].touched &&
                    eyeForm.controls['284'].errors
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="eyeForm.controls['284'].errors"
                  >
                    required
                  </small>
                </div>
                </td>
                <td colspan="8">
                  Do you want to be designated as a primary care practitioner (PCP)?
                    <label class="radio-inline radioStyle">
                        <input
                            type="radio"
                            id="cmd_00132"
                            name="cmd_00132"
                            [class.is-invalid]="(eyeForm.controls['cmd_00132'].touched 
                            && eyeForm.controls['cmd_00132'].invalid) || 
                            (eyeForm.controls['cmd_00132'].invalid && isSubmit)"
                            formControlName="cmd_00132"
                            value="1"
                        />Yes</label>
                        <label class="radio-inline radioStyle">
                        <input
                             type="radio"
                             id="cmd_00132"
                            name="cmd_00132"
                            [class.is-invalid]="(eyeForm.controls['cmd_00132'].touched 
                            && eyeForm.controls['cmd_00132'].invalid) || 
                            (eyeForm.controls['cmd_00132'].invalid && isSubmit)"
                            formControlName="cmd_00132"
                            value="0"
                        />No</label>
                        <div class="formLabel"
                        *ngIf="eyeForm.controls['cmd_00132'].touched && eyeForm.controls['cmd_00132'].invalid">
                        <small class="text-danger" *ngIf="eyeForm.controls['cmd_00132'].errors?.required">
                            required
                        </small>
                       </div>
                       <span *ngIf="(eyeForm.controls['cmd_00132'].touched && eyeForm.controls['cmd_00132'].errors)
                       || (eyeForm.controls['cmd_00132'].errors && isSubmit)">
                     <i class="fas fa-times ml-2 text-danger"></i>
                        </span>
                </td>
              </tr>
                <tr class="border">
                <td colspan="12">
                  <label class="form-label" style="font-size: 16px;">Additional clinical practice specialties:</label>
                  <div class="row">
                    <div class="col-md-4">
                          <ng-select
                            [items]="specialtySecondary"
                            bindLabel="name"
                            placeholder="Secondary Specialty"
                            bindValue="id"
                            formControlName="962"
                          >
                          </ng-select>
                        </div>
                        <div class="col-md-4">
                          <ng-select
                            [items]="specialtyTer"
                            bindLabel="name"
                            placeholder="Tertiary Specialty"
                            bindValue="id"
                            formControlName="965"
                          >
                          </ng-select>
                        </div>
                        <div class="col-md-4">
                          <ng-select
                            [items]="specialtyquart"
                            bindLabel="name"
                            placeholder="Quaternary Specialty"
                            bindValue="id"
                            formControlName="1934"
                          >
                          </ng-select>
                        </div>

                  </div>
                </td>
              </tr>
              <tr>
    <td colspan="12">
    <div class="row">
      <label class="form-lable" style="font-size: 25px; margin-left: 20px;">Category of professional activity, check all boxes that apply:</label>
      <div class="col-md-6">
        <label class="form-label" style="font-size: 22px;">Clinical practice:</label>
        <div class="row">
          <div class="col-md-6"> 
            <div class="col-md-6">
              <label class="checkbox-inline radioStyle">
                <input
                    type="radio"
                    id="cmd_002"
                    name="cmd_002"
                    [class.is-invalid]="(eyeForm.controls['cmd_002'].touched 
                                && eyeForm.controls['cmd_002'].invalid) || 
                                (eyeForm.controls['cmd_002'].invalid && isSubmit)"
                    formControlName="cmd_002"
                    value="1"
                />Full Time
                </label>
                <div class="formLabel"
                *ngIf="eyeForm.controls['cmd_002'].touched && eyeForm.controls['cmd_002'].invalid">
                <small class="text-danger" *ngIf="eyeForm.controls['cmd_002'].errors?.required">
                    required
                </small>
               </div>
               <span *ngIf="(eyeForm.controls['cmd_002'].touched && eyeForm.controls['cmd_002'].errors)
               || (eyeForm.controls['cmd_002'].errors && isSubmit)">
             <i class="fas fa-times ml-2 text-danger"></i>
                </span>

          </div>  
          <div class="col-md-6">
            <label class="checkbox-inline radioStyle">
              <input
                  type="radio"
                  id="cmd_003"
                  name="cmd_003"
                  [class.is-invalid]="(eyeForm.controls['cmd_003'].touched 
                              && eyeForm.controls['cmd_003'].invalid) || 
                              (eyeForm.controls['cmd_003'].invalid && isSubmit)"
                  formControlName="cmd_003"
                  value="1"
              />Part Time
              </label>
              <div class="formLabel"
              *ngIf="eyeForm.controls['cmd_003'].touched && eyeForm.controls['cmd_003'].invalid">
              <small class="text-danger" *ngIf="eyeForm.controls['cmd_003'].errors?.required">
                  required
              </small>
             </div>
             <span *ngIf="(eyeForm.controls['cmd_003'].touched && eyeForm.controls['cmd_003'].errors)
             || (eyeForm.controls['cmd_003'].errors && isSubmit)">
           <i class="fas fa-times ml-2 text-danger"></i>
              </span>

        </div> 
        <div class="col-md-6">
          <label class="checkbox-inline radioStyle">
            <input
                type="radio"
                id="cmd_004"
                name="cmd_004"
                [class.is-invalid]="(eyeForm.controls['cmd_004'].touched 
                            && eyeForm.controls['cmd_004'].invalid) || 
                            (eyeForm.controls['cmd_004'].invalid && isSubmit)"
                formControlName="cmd_004"
                value="1"
            />Locum /Temporary
            </label>
            <div class="formLabel"
            *ngIf="eyeForm.controls['cmd_004'].touched && eyeForm.controls['cmd_004'].invalid">
            <small class="text-danger" *ngIf="eyeForm.controls['cmd_004'].errors?.required">
                required
            </small>
           </div>
           <span *ngIf="(eyeForm.controls['cmd_004'].touched && eyeForm.controls['cmd_004'].errors)
           || (eyeForm.controls['cmd_004'].errors && isSubmit)">
         <i class="fas fa-times ml-2 text-danger"></i>
            </span>

      </div> 
      <div class="col-md-6">
        <label class="checkbox-inline radioStyle">
          <input
              type="radio"
              id="cmd_005"
              name="cmd_005"
              [class.is-invalid]="(eyeForm.controls['cmd_005'].touched 
                          && eyeForm.controls['cmd_005'].invalid) || 
                          (eyeForm.controls['cmd_005'].invalid && isSubmit)"
              formControlName="cmd_005"
              value="1"
          />Telemedicine
          </label>
          <div class="formLabel"
          *ngIf="eyeForm.controls['cmd_005'].touched && eyeForm.controls['cmd_005'].invalid">
          <small class="text-danger" *ngIf="eyeForm.controls['cmd_005'].errors?.required">
              required
          </small>
         </div>
         <span *ngIf="(eyeForm.controls['cmd_005'].touched && eyeForm.controls['cmd_005'].errors)
         || (eyeForm.controls['cmd_005'].errors && isSubmit)">
       <i class="fas fa-times ml-2 text-danger"></i>
          </span>

    </div> 
    <div class="col-md-6">
      <label class="checkbox-inline radioStyle">
        <input
            type="radio"
            id="cmd_006"
            name="cmd_006"
            [class.is-invalid]="(eyeForm.controls['cmd_006'].touched 
                        && eyeForm.controls['cmd_006'].invalid) || 
                        (eyeForm.controls['cmd_006'].invalid && isSubmit)"
            formControlName="cmd_006"
            value="1"
        />Other (explain)
        </label>
        <div class="formLabel"
        *ngIf="eyeForm.controls['cmd_006'].touched && eyeForm.controls['cmd_006'].invalid">
        <small class="text-danger" *ngIf="eyeForm.controls['cmd_006'].errors?.required">
            required
        </small>
       </div>
       <span *ngIf="(eyeForm.controls['cmd_006'].touched && eyeForm.controls['cmd_006'].errors)
       || (eyeForm.controls['cmd_006'].errors && isSubmit)">
     <i class="fas fa-times ml-2 text-danger"></i>
        </span>

  </div>  
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <label class="form-label" style="font-size: 22px;">Other professional activities:</label>
        <div class="col-md-6">
          <label class="radio-inline radioStyle">
              <input
                  type="radio"
                  id="cmd_007"
                  name="cmd_007"
                  formControlName="cmd_007"
                  value="1"
              />Administration</label>
      </div>
      <div class="col-md-6">
        <label class="radio-inline radioStyle">
            <input
                type="radio"
                id="cmd_008"
                name="cmd_008"
                formControlName="cmd_008"
                value="1"
            />Teaching</label>
    </div>
    <div class="col-md-6">
      <label class="radio-inline radioStyle">
          <input
              type="radio"
              id="cmd_009"
              name="cmd_009"
              formControlName="cmd_009"
              value="1"
          />Research</label>
  </div>
  <div class="col-md-6">
    <label class="radio-inline radioStyle">
        <input
            type="radio"
            id="cmd_0010"
            name="cmd_0010"
            formControlName="cmd_0010"
            value="1"
        />Retired</label>
</div>
<div class="col-md-6">
  <label class="radio-inline radioStyle">
      <input
          type="radio"
          id="cmd_011"
          name="cmd_011"
          formControlName="cmd_011"
          value="1"
      />Other (explain)</label>
</div>
      </div>
    </div>
                </td>
              </tr>
              <tr>
                <td colspan="12">
                  <div class="centered texasFirstTable">
                    <div class="row">
                    <div class="col-md-8">
                        <h2  style="margin-left: 15px;  font-size: 16px;"><b>IV. Board Certification/Recertification</b>  <i style="margin-left: 20px;"> This section does not apply to licensure</i></h2>
                         
                    </div>
                    <div class="col-md-4">
                        <label for="cmd_0013"><b>Does Not Apply:</b></label>&nbsp;&nbsp;
                        <input type="checkbox" formControlName="cmd_0013"
                            id="cmd_0013" value="1" (ngModelChange)="handleNAchange('cmd_0013')"
                          >
                </div>
                  </div>
                </div>
                </td>
            </tr>
            <tr>
              <td colspan="12">
              
                  <label class="normalParagraphStyle" style="font-size: 22px;"
                    >List all current and past certifications. Please attach additional sheets, if necessary</label>             
              </td>
            </tr>
             <tr class="border">
              <td colspan="1">
                  <label 
                    >Name of issuing board
                  </label>
              </td>
              <td colspan="2" >
                  <label >Board
                    Certification
                    Number
                    (as applicable) </label>
              </td>
              <td colspan="3">
                  <label 
                    >Specialty
                  </label>
              </td>
              <td colspan="3">
                  <label 
                    >Date
                    certified/
                    recertified
                    month/year
                  </label>
              </td>
              <td colspan="3">
                  <label 
                    >Expiration
                    date (if any)
                    month/year
                  </label>
              </td>
            </tr> 
             <tr class="border">
              <td colspan="1">
                <ng-select
                   [items]="boardCertification"
                   [class.is-invalid]="
                   (eyeForm.controls['554'].invalid)
                  " bindLabel="name"
                  placeholder="Primary Board Certification"
                  bindValue="id"
                 formControlName="554"
                 (blur)="handleNAchange('cmd_0013',554)"
                 style="width:300px"
                 >
                </ng-select>
                 <div
                 class="formLabel col-md-2"
                   *ngIf="
                  eyeForm.controls['554'].touched &&
                   eyeForm.controls['554'].invalid">
              </div>
              </td>
              <td colspan="2" >
                <input  formControlName="1169" class="form-control  " id="1169" name="1169" type="text" placeholder="Primary Board Certification Number">
              </td>
              <td colspan="3">
                <ng-select 
                [items]="specialty"
                [class.is-invalid]="
                (eyeForm.controls['556'].invalid)
               "
                bindLabel="name"
                placeholder="Prim Specialities "
                bindValue="id"
                formControlName="556"
                (blur)="handleNAchange('cmd_0013',556)"
                style="width: 200px;"
               
              >
              </ng-select>
              <div class="mandatory"
              *ngIf="eyeForm.controls['556'].invalid">
        <small >
            required
        </small>
        </div>
              </td>
              <td colspan="3">
                <div class="input-group-append">
                <input class="form-control onboard datepicker" formControlName="557" (blur)="handleNAchange('cmd_0013',557)" (blur)="clearInputIfInvalid('557','cmd_0013')"
                                [class.is-invalid]="(eyeForm.controls['557'].invalid)" 
                                ngbDatepicker #d14="ngbDatepicker" id="557" name="557" type="text" placeholder= "Primary Board Original Issue Date" data-id="557">
                  <button class="btn btn-outline-secondary calendar" (click)="d14.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
              </div>
                <div class="formLabel col-md-11"
                *ngIf="eyeForm.controls['557'].touched && eyeForm.controls['557'].invalid">
                <small class="text-danger" *ngIf="eyeForm.controls['557'].errors?.required">
                    required
                </small>
            </div>
            <div  *ngIf="eyeForm.get('557').invalid ">
              <small class="text-danger" *ngIf="eyeForm.get('557').errors?.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY)                  </small>
              <small class="text-danger" *ngIf="eyeForm.get('557').errors?.invalidMonth">
              Month is out of range
              </small>
              <small class="text-danger" *ngIf="eyeForm.get('557').errors?.invalidDateyear">
                year is out of range(1900-2099)                    </small>
                <small class="text-danger" *ngIf="eyeForm.get('557').errors?.invalidDateRange">
                  date is out of range
                  </small>
              </div>
              </td>
              <td colspan="3">
                <div class="input-group-append">
                <input class="form-control onboard datepicker" formControlName="560" (blur)="handleNAchange('cmd_0013',560)" (blur)="clearInputIfInvalid('560','cmd_0013')"
                [class.is-invalid]="(eyeForm.controls['560'].invalid)" 
                ngbDatepicker #d15="ngbDatepicker" id="560" name="560" type="text" placeholder= "Primary Board Expiration Date" data-id="560">
               <button class="btn btn-outline-secondary calendar" (click)="d15.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                </div>
<div class="formLabel col-md-11"
*ngIf="eyeForm.controls['560'].touched && eyeForm.controls['560'].invalid">
<small class="text-danger" *ngIf="eyeForm.controls['560'].errors?.required">
    required
</small>
</div>
<div  *ngIf="eyeForm.get('560').invalid ">
  <small class="text-danger" *ngIf="eyeForm.get('560').errors?.invalidDateFormat">
    Only Date Format is allowed(MM/DD/YYYY)                  </small>
  <small class="text-danger" *ngIf="eyeForm.get('560').errors?.invalidMonth">
  Month is out of range
  </small>
  <small class="text-danger" *ngIf="eyeForm.get('560').errors?.invalidDateyear">
    year is out of range(1900-2099)                    </small>
    <small class="text-danger" *ngIf="eyeForm.get('560').errors?.invalidDateRange">
      date is out of range
      </small>
  </div>
 </td>
 </tr>  
            <tr class="border">
              <td colspan="1">
                <ng-select
                   [items]="boardCertification"
                   [class.is-invalid]="
                   (eyeForm.controls['561'].invalid)
                  " bindLabel="name"
                  placeholder="Secondary Board Certifications"
                  bindValue="id"
                 formControlName="561"
                 (blur)="handleNAchange('cmd_0013',561)"
                 style="width:300px"
                 >
                </ng-select>
                <div
                class="formLabel col-md-2"
                  *ngIf="
                 eyeForm.controls['561'].touched &&
                  eyeForm.controls['561'].invalid">
             </div>
              </td>
              <td colspan="2">
                <input class="form-control onboard"  formControlName="562"
                id="562" name="562" type="text" placeholder= "Secondary Board Certificate Number" data-id="managing_contact_name" >
              </td>
              <td colspan="3">
                <ng-select [items]="specialtySecondary" bindLabel="name" placeholder=" Second Specialities" bindValue="id"
                        formControlName="563"  (blur)="handleNAchange('cmd_0013',563)"
                        [class.is-invalid]="(eyeForm.controls['563'].invalid)
                        || (eyeForm.controls['563'].errors && isSubmit )"
                        style="width:200px"
                        >
                        
                    </ng-select>
                    <div class="formLabel "
               *ngIf="(eyeForm.controls['563'].touched && eyeForm.controls['563'].invalid)
               || (eyeForm.controls['563'].errors && isSubmit )">
               <small class="text-danger" *ngIf="eyeForm.controls['563'].errors">
                   required
               </small>
           </div>
              </td>
              <td colspan="3">
                <div class="input-group-append">
                <input class="form-control onboard datepicker" formControlName="564"  (blur)="handleNAchange('cmd_0013',564)" (blur)="clearInputIfInvalid('564','cmd_0013')"
                                [class.is-invalid]="(eyeForm.controls['564'].invalid)"
                                ngbDatepicker #d16="ngbDatepicker" id="564" name="564" type="text" placeholder= "Secondary Board Original Issue Date" data-id="564">
                  <button class="btn btn-outline-secondary calendar" (click)="d16.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
              </div>
              <div class="formLabel col-md-11"
              *ngIf="eyeForm.controls['564'].touched && eyeForm.controls['564'].invalid">
              <small class="text-danger" *ngIf="eyeForm.controls['564'].errors?.required">
                  required
              </small>
          </div>
          <div  *ngIf="eyeForm.get('564').invalid ">
  <small class="text-danger" *ngIf="eyeForm.get('564').errors?.invalidDateFormat">
    Only Date Format is allowed(MM/DD/YYYY)                  </small>
  <small class="text-danger" *ngIf="eyeForm.get('564').errors?.invalidMonth">
  Month is out of range
  </small>
  <small class="text-danger" *ngIf="eyeForm.get('564').errors?.invalidDateyear">
    year is out of range(1900-2099)                    </small>
    <small class="text-danger" *ngIf="eyeForm.get('564').errors?.invalidDateRange">
      date is out of range
      </small>
  </div>
              </td>
              <td colspan="3">
                <div class="input-group-append">
                  <input class="form-control onboard datepicker" formControlName="567" (blur)="handleNAchange('cmd_0013',567)" (blur)="clearInputIfInvalid('567','cmd_0013')"
                                [class.is-invalid]="(eyeForm.controls['567'].invalid)"
                                ngbDatepicker #d17="ngbDatepicker" id="567" name="567" type="text" placeholder= "Secondary Board Expiration Date" data-id="567">
                  <button class="btn btn-outline-secondary calendar" (click)="d17.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                   </div>
                   <div class="formLabel col-md-11"
                   *ngIf="eyeForm.controls['567'].touched && eyeForm.controls['567'].invalid">
                   <small class="text-danger" *ngIf="eyeForm.controls['567'].errors?.required">
                       required
                   </small>
               </div>
               <div  *ngIf="eyeForm.get('567').invalid ">
                <small class="text-danger" *ngIf="eyeForm.get('567').errors?.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY)                  </small>
                <small class="text-danger" *ngIf="eyeForm.get('567').errors?.invalidMonth">
                Month is out of range
                </small>
                <small class="text-danger" *ngIf="eyeForm.get('567').errors?.invalidDateyear">
                  year is out of range(1900-2099)                    </small>
                  <small class="text-danger" *ngIf="eyeForm.get('567').errors?.invalidDateRange">
                    date is out of range
                    </small>
                </div>
              </td>
            </tr>
            <tr class="border">
              <td colspan="1">
                <ng-select
                [items]="boardCertification"
                [class.is-invalid]="
                (eyeForm.controls['946'].invalid)
               " bindLabel="name"
               placeholder="Third Board Certifications"
               bindValue="id"
              formControlName="946"
              (blur)="handleNAchange('cmd_0013',946)"
              style="width:300px"
              >
             </ng-select>
             <div
             class="formLabel col-md-2"
               *ngIf="
              eyeForm.controls['946'].touched &&
               eyeForm.controls['946'].invalid">
          </div>
              </td>
              <td colspan="2">
                <input class="form-control onboard " id="951" name="951"  type="text" formControlName="951" data-id="951" placeholder="Third Board Certificate Number ">
              </td>
              <td colspan="3">
                <ng-select [items]="specialtyTer" bindLabel="name" placeholder=" Third Specialities" bindValue="id"
                formControlName="948" (blur)="handleNAchange('cmd_0013','948')"
                [class.is-invalid]="(eyeForm.controls['948'].invalid)
                || (eyeForm.controls['948'].errors && isSubmit )"
                style="width:200px">
            </ng-select>
            <div class="formLabel "
       *ngIf="(eyeForm.controls['948'].touched && eyeForm.controls['948'].invalid)
       || (eyeForm.controls['948'].errors && isSubmit )">
       <small class="text-danger" *ngIf="eyeForm.controls['948'].errors">
           required
       </small>
   </div>
              </td>
              <td colspan="3">
                <div class="input-group-append">
                  <input class="form-control onboard datepicker" formControlName="953" (blur)="handleNAchange('cmd_0013',953)" (blur)="clearInputIfInvalid('953','cmd_0013')" 
                                [class.is-invalid]="(eyeForm.controls['953'].invalid)"
                                ngbDatepicker #d18="ngbDatepicker" id="953" name="953" type="text" placeholder= "Third Board Original Issue Date" data-id="953">
                  <button class="btn btn-outline-secondary calendar" (click)="d18.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                   </div>
                   <div class="formLabel col-md-11"
                   *ngIf="eyeForm.controls['953'].touched && eyeForm.controls['953'].invalid">
                   <small class="text-danger" *ngIf="eyeForm.controls['953'].errors?.required">
                       required
                   </small>
               </div>
               <div  *ngIf="eyeForm.get('953').invalid ">
                <small class="text-danger" *ngIf="eyeForm.get('953').errors?.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY)                  </small>
                <small class="text-danger" *ngIf="eyeForm.get('953').errors?.invalidMonth">
                Month is out of range
                </small>
                <small class="text-danger" *ngIf="eyeForm.get('953').errors?.invalidDateyear">
                  year is out of range(1900-2099)                    </small>
                  <small class="text-danger" *ngIf="eyeForm.get('953').errors?.invalidDateRange">
                    date is out of range
                    </small>
                </div>
              </td>
              <td colspan="3">
                <div class="input-group-append">
                  <input class="form-control onboard datepicker" formControlName="954" (blur)="handleNAchange('cmd_0013',954)" (blur)="clearInputIfInvalid('954','cmd_0013')"
                                [class.is-invalid]="(eyeForm.controls['954'].invalid)"
                                ngbDatepicker #d19="ngbDatepicker" id="954" name="954" type="text" placeholder= "Third Board Expiration Date" data-id="954">
                  <button class="btn btn-outline-secondary calendar" (click)="d19.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                   </div>
                   <div class="formLabel col-md-11"
                   *ngIf="eyeForm.controls['954'].touched && eyeForm.controls['954'].invalid">
                   <small class="text-danger" *ngIf="eyeForm.controls['954'].errors?.required">
                       required
                   </small>
               </div>
               <div  *ngIf="eyeForm.get('954').invalid ">
                <small class="text-danger" *ngIf="eyeForm.get('954').errors?.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY)                  </small>
                <small class="text-danger" *ngIf="eyeForm.get('954').errors?.invalidMonth">
                Month is out of range
                </small>
                <small class="text-danger" *ngIf="eyeForm.get('954').errors?.invalidDateyear">
                  year is out of range(1900-2099)                    </small>
                  <small class="text-danger" *ngIf="eyeForm.get('954').errors?.invalidDateRange">
                    date is out of range
                    </small>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="12">
                <label class="form-label" style="font-size: 18px;">If not currently board certified, describe your intent for certification, if any, and dates of previous testing and or intended future
                  testing for certification below. Please attach additional sheets, if necessary. </label>
              </td>
            </tr>
            <tr>
              <td colspan="12">
                <div class="centered" class="identifyBox">
                  <h3 class="hThreeAlign">
                    V. Other Certifications 
                    <span class="normalParagraphStyle">
                      <i>Please attach copy of certificate(s), if applicable</i>
                    </span>
                  </h3>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="12">
                <label class="form-label" style="font-size: 18px;">Examples include: ACLS, BLS, ATLS, PALS, NRP, AANA, Fluoroscopy, Radiography, etc. </label>
              </td>
            </tr>
            <tr class="border">
              <td colspan="3">
                <label 
                  >Type
                </label>
                <ng-select [items]="mediCertifications" bindLabel="name"  placeholder="Medical Certification" bindValue="id" formControlName="add_1_674" style="width:300px"> </ng-select>
            </td>
            <td colspan="3" >
                <label >Number: </label>
                <input class="form-control onboard"  formControlName="add_1_3270"
                id="add_1_3270" name="add_1_3270" type="text" placeholder="Medical Certification Number"   data-id="managing_contact_name" >
            </td>
            <td colspan="3" >
              <label >Month/Year of certification: </label>
              <div class="input-group-append">
                <input type="text" placeholder="Medical Certification Issue Date" ngbDatepicker #d20="ngbDatepicker" id="add_1_677"(blur)="clearInputIfInvalid('add_1_677')" class="form-control" formControlName="add_1_677" 
                >
                        <button class="btn btn-outline-secondary calendar" (click)="d20.toggle()" type="button">
                        <i class="fas fa-calendar-alt"></i>
                        </button>
                    </div>
                    <div  *ngIf="eyeForm.get('add_1_677').invalid ">
                      <small class="text-danger" *ngIf="eyeForm.get('add_1_677').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)                  </small>
                      <small class="text-danger" *ngIf="eyeForm.get('add_1_677').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="eyeForm.get('add_1_677').errors?.invalidDateyear">
                        year is out of range(1900-2099)                    </small>
                        <small class="text-danger" *ngIf="eyeForm.get('add_1_677').errors?.invalidDateRange">
                          date is out of range
                          </small>
                      </div>
          </td>
          <td colspan="3" >
            <label >Month/Year of expiration: </label>
            <div class="input-group-append">
              <input type="text" placeholder="Medical Certification Expiration Date" ngbDatepicker #d22="ngbDatepicker" id="add_1_678" class="form-control" formControlName="add_1_678" (blur)="clearInputIfInvalid('add_1_678')"
              >
                      <button class="btn btn-outline-secondary calendar" (click)="d22.toggle()" type="button">
                      <i class="fas fa-calendar-alt"></i>
                      </button>
                  </div>
                  <div  *ngIf="eyeForm.get('add_1_678').invalid ">
                    <small class="text-danger" *ngIf="eyeForm.get('add_1_678').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)                  </small>
                    <small class="text-danger" *ngIf="eyeForm.get('add_1_678').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="eyeForm.get('add_1_678').errors?.invalidDateyear">
                      year is out of range(1900-2099)                    </small>
                      <small class="text-danger" *ngIf="eyeForm.get('add_1_678').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
        </td>
            </tr>
            <tr class="border">
              <td colspan="3">
                <label 
                  >Type
                </label>
                <ng-select [items]="mediCertifications" bindLabel="name"  placeholder="Medical Certification" bindValue="id" formControlName="add_2_674" style="width:300px"> </ng-select>
            </td>
            <td colspan="3" >
                <label >Number: </label>
                <input class="form-control onboard"  formControlName="add_2_3270"
                id="add_2_3270" name="add_2_3270" type="text" placeholder="Medical Certification Number"   data-id="managing_contact_name" >
            </td>
            <td colspan="3" >
              <label >Month/Year of certification: </label>
              <div class="input-group-append">
                <input type="text" placeholder="Medical Certification Issue Date" (blur)="clearInputIfInvalid('add_2_677')" ngbDatepicker #d23="ngbDatepicker" id="add_2_677" class="form-control" formControlName="add_2_677" 
                >
                        <button class="btn btn-outline-secondary calendar" (click)="d23.toggle()" type="button">
                        <i class="fas fa-calendar-alt"></i>
                        </button>
                    </div>
                    <div  *ngIf="eyeForm.get('add_2_677').invalid ">
                      <small class="text-danger" *ngIf="eyeForm.get('add_2_677').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)                  </small>
                      <small class="text-danger" *ngIf="eyeForm.get('add_2_677').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="eyeForm.get('add_2_677').errors?.invalidDateyear">
                        year is out of range(1900-2099)                    </small>
                        <small class="text-danger" *ngIf="eyeForm.get('add_2_677').errors?.invalidDateRange">
                          date is out of range
                          </small>
                      </div>
          </td>
          <td colspan="3" >
            <label >Month/Year of expiration: </label>
            <div class="input-group-append">
              <input type="text" placeholder="Medical Certification Expiration Date" (blur)="clearInputIfInvalid('add_2_678')" ngbDatepicker #d24="ngbDatepicker" id="add_2_678" class="form-control" formControlName="add_2_678"
              >
                      <button class="btn btn-outline-secondary calendar" (click)="d24.toggle()" type="button">
                      <i class="fas fa-calendar-alt"></i>
                      </button>
                  </div>
                  <div  *ngIf="eyeForm.get('add_2_678').invalid ">
                    <small class="text-danger" *ngIf="eyeForm.get('add_2_678').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)                  </small>
                    <small class="text-danger" *ngIf="eyeForm.get('add_2_678').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="eyeForm.get('add_2_678').errors?.invalidDateyear">
                      year is out of range(1900-2099)                    </small>
                      <small class="text-danger" *ngIf="eyeForm.get('add_2_678').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>

        </td>
            </tr>
            <tr class="border">
              <td colspan="3">
                <label 
                  >Type
                </label>
                <ng-select [items]="mediCertifications" bindLabel="name"  placeholder="Medical Certification" bindValue="id" formControlName="add_3_674" style="width:300px"> </ng-select>
            </td>
            <td colspan="3" >
                <label >Number: </label>
                <input class="form-control onboard"  formControlName="add_3_3270"
                id="add_3_3270" name="add_3_3270" type="text" placeholder="Medical Certification Number"   data-id="managing_contact_name" >
            </td>
            <td colspan="3" >
              <label >Month/Year of certification: </label>
              <div class="input-group-append">
                <input type="text" placeholder="Medical Certification Issue Date" (blur)="clearInputIfInvalid('add_3_677')" ngbDatepicker #d25="ngbDatepicker" id="add_3_677" class="form-control" formControlName="add_3_677" 
                >
                        <button class="btn btn-outline-secondary calendar" (click)="d25.toggle()" type="button">
                        <i class="fas fa-calendar-alt"></i>
                        </button>
                    </div>
                    <div  *ngIf="eyeForm.get('add_3_677').invalid ">
                      <small class="text-danger" *ngIf="eyeForm.get('add_3_677').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)                  </small>
                      <small class="text-danger" *ngIf="eyeForm.get('add_3_677').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="eyeForm.get('add_3_677').errors?.invalidDateyear">
                        year is out of range(1900-2099)                    </small>
                        <small class="text-danger" *ngIf="eyeForm.get('add_3_677').errors?.invalidDateRange">
                          date is out of range
                          </small>
                      </div>
          </td>
          <td colspan="3" >
            <label >Month/Year of expiration: </label>
            <div class="input-group-append">
              <input type="text" placeholder="Medical Certification Expiration Date" (blur)="clearInputIfInvalid('add_3_678')" ngbDatepicker #d26="ngbDatepicker" id="add_3_678" class="form-control" formControlName="add_3_678"
              >
                      <button class="btn btn-outline-secondary calendar" (click)="d26.toggle()" type="button">
                      <i class="fas fa-calendar-alt"></i>
                      </button>
                  </div>
                  <div  *ngIf="eyeForm.get('add_3_678').invalid ">
                    <small class="text-danger" *ngIf="eyeForm.get('add_3_678').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)                  </small>
                    <small class="text-danger" *ngIf="eyeForm.get('add_3_678').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="eyeForm.get('add_3_678').errors?.invalidDateyear">
                      year is out of range(1900-2099)                    </small>
                      <small class="text-danger" *ngIf="eyeForm.get('add_3_678').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
        </td>
            </tr>
            <tr class="border">
              <td colspan="3">
                <label 
                  >Type
                </label>
                <ng-select [items]="mediCertifications" bindLabel="name"  placeholder="Medical Certification" bindValue="id" formControlName="add_4_674" style="width:300px"> </ng-select>
            </td>
            <td colspan="3" >
                <label >Number: </label>
                <input class="form-control onboard"  formControlName="add_4_3270"
                id="add_4_3270" name="add_4_3270" type="text" placeholder="Medical Certification Number"   data-id="managing_contact_name"  >
            </td>
            <td colspan="3" >
              <label >Month/Year of certification: </label>
              <div class="input-group-append">
                <input type="text" placeholder="Medical Certification Issue Date" (blur)="clearInputIfInvalid('add_4_677')" ngbDatepicker #d27="ngbDatepicker" id="add_4_677" class="form-control" formControlName="add_4_677" 
                >
                        <button class="btn btn-outline-secondary calendar" (click)="d27.toggle()" type="button">
                        <i class="fas fa-calendar-alt"></i>
                        </button>
                    </div>
                    <div  *ngIf="eyeForm.get('add_4_677').invalid ">
                      <small class="text-danger" *ngIf="eyeForm.get('add_4_677').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)                  </small>
                      <small class="text-danger" *ngIf="eyeForm.get('add_4_677').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="eyeForm.get('add_4_677').errors?.invalidDateyear">
                        year is out of range(1900-2099)                    </small>
                        <small class="text-danger" *ngIf="eyeForm.get('add_4_677').errors?.invalidDateRange">
                          date is out of range
                          </small>
                      </div>
          </td>
          <td colspan="3" >
            <label >Month/Year of expiration: </label>
            <div class="input-group-append">
              <input type="text" placeholder="Medical Certification Expiration Date" (blur)="clearInputIfInvalid('add_4_678')" ngbDatepicker #d28="ngbDatepicker" id="add_4_678" class="form-control" formControlName="add_4_678"
              >
                      <button class="btn btn-outline-secondary calendar" (click)="d28.toggle()" type="button">
                      <i class="fas fa-calendar-alt"></i>
                      </button>
                  </div>
                  <div  *ngIf="eyeForm.get('add_4_678').invalid ">
                    <small class="text-danger" *ngIf="eyeForm.get('add_4_678').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)                  </small>
                    <small class="text-danger" *ngIf="eyeForm.get('add_4_678').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="eyeForm.get('add_4_678').errors?.invalidDateyear">
                      year is out of range(1900-2099)                    </small>
                      <small class="text-danger" *ngIf="eyeForm.get('add_4_678').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
        </td>
            </tr>
            <tr>
              <td colspan="12">
                <label class="form-label" style="font-size: 18px;">For additional certifications, please attach a separate sheet.</label>
              </td>
            </tr>
            <tr>
              <td colspan="12">
                <div class="centered" class="identifyBox">
                  <h3 class="hThreeAlign">
                    VI. Practice and Employment Information
                    <!-- <span class="normalParagraphStyle">
                      <i>Please attach copy of certificate(s), if applicable</i>
                    </span> -->
                  </h3>
                </div>
              </td>
            </tr>
            <tr class="border">
              <td colspan="6">
                <h6 class="form-label">Name of primary practice/affiliation or clinic: 
                  
                </h6>
                <input type="text" class="form-control" formControlName="cmd_014">

              </td>
              <td colspan="6">
                <label class="form-label">
                  Department name (if hospital based):</label>
                  <input type="text" class="form-control" formControlName="cmd_015">

              </td>
            </tr>
            <tr class="border">
              <td colspan="8">
                <label class="form-label">Primary Clinical Practice street address:
                  
                </label>
                <input type="text" class="form-control" formControlName="cmd_016">

              </td>
              <td colspan="4">
                <label class="form-label">
                  Entity type 2 (group) NPI number:</label>
                  <input type="text" class="form-control" formControlName="cmd_017">

              </td>
            </tr>
            <tr class="border">
              <td colspan="3">
                <label class="form-label">City: 
                  
                </label>
                <input type="text" class="form-control" formControlName="cmd_018">

              </td>
              <td colspan="3">
                <label class="form-label">
                  County:</label>
                  <input type="text" class="form-control" formControlName="cmd_019">

              </td>
              <td colspan="3">
                <label class="form-label">
                  State:</label>
                  <input type="text" class="form-control" formControlName="cmd_020">

              </td>
              <td colspan="3">
                <label class="form-label">
                  ZIP:</label>
                  <input type="text" class="form-control" formControlName="cmd_021">
                  <small
                  class="text-danger"
                  *ngIf="eyeForm.controls['cmd_021'].errors?.pattern"
                  > Zip code must be atleast 5 digits and less than 10 digits.</small
                >

              </td>
              
            </tr>
            <tr class="border">
              <td colspan="4">
                <label class="form-label">Primary office telephone number:
                  
                </label>
                <input type="text" class="form-control" formControlName="cmd_022" (keydown)="handleNumber($event,'cmd_022')" maxlength="12">
                <small class="text-danger" *ngIf="eyeForm.controls['cmd_022'].errors?.pattern">
                  Required pattern xxx-xxx-xxxx
                 </small>
              </td>
              <td colspan="3">
                <label class="form-label">
                  Primary office fax number:</label>
                  <input type="text" class="form-control" formControlName="cmd_023" (keydown)="handleNumber($event,'cmd_023')" maxlength="12">
                  <small class="text-danger" *ngIf="eyeForm.controls['cmd_023'].errors?.pattern">
                    Required pattern xxx-xxx-xxxx
                   </small>
              </td>
              <td colspan="4">
                <label class="form-label">
                  Patient appointment telephone number:</label>
                  <input type="text" class="form-control" formControlName="cmd_024" (keydown)="handleNumber($event,'cmd_024')" maxlength="12">
                  <small class="text-danger" *ngIf="eyeForm.controls['cmd_024'].errors?.pattern">
                    Required pattern xxx-xxx-xxxx
                   </small>
              </td>
              
            </tr>
            <tr class="border">
              <td colspan="8">
                <label class="form-label">Mailing/Billing Address (if different from above):
                  
                </label>
                <input type="text" class="form-control" formControlName="cmd_025">

              </td>
              <td colspan="4">
                <label class="form-label">
                  Attn:</label>
                  <input type="text" class="form-control" formControlName="cmd_026">

              </td>
              
            </tr>
            <tr class="border">
              <td colspan="4">
                <label class="form-label">Office manager:
                  
                </label>
                <input type="text" class="form-control" formControlName="cmd_027">

              </td>
              <td colspan="4">
                <label class="form-label">
                  Office manager’s telephone number:</label>
                  <input type="text" class="form-control" formControlName="cmd_028" (keydown)="handleNumber($event,'cmd_028')" maxlength="12">
                  <small class="text-danger" *ngIf="eyeForm.controls['cmd_028'].errors?.pattern">
                    Required pattern xxx-xxx-xxxx
                   </small>
              </td>
              <td colspan="4">
                <label class="form-label">
                  Office manager’s fax number:</label>
                  <input type="text" class="form-control" formControlName="cmd_029" (keydown)="handleNumber($event,'cmd_029')" maxlength="12">
                  <small class="text-danger" *ngIf="eyeForm.controls['cmd_029'].errors?.pattern">
                    Required pattern xxx-xxx-xxxx
                   </small>
              </td>
              
            </tr>
            <tr class="border">
              <td colspan="4">
                <label class="form-label">Exchange/answering service number:
                  
                </label>
                <input type="text" class="form-control" formControlName="cmd_030">

              </td>
              <td colspan="4">
                <label class="form-label">
                  Pager number:</label>
                  <input type="text" class="form-control" formControlName="cmd_031">

              </td>
              <td colspan="4">
                <label class="form-label">
                  Office email address:</label>
                  <input type="text" class="form-control" formControlName="cmd_032">
                  <small
                      class="text-danger"
                      *ngIf="eyeForm.controls['cmd_032'].errors?.pattern"
                      >Please enter a valid email.</small
                    >

              </td>
              
            </tr>
            <tr class="border">
              <td colspan="12">
                <label class="form-label">
                  Credentialing Contact and Address:</label>
                  <input type="text" class="form-control" formControlName="cmd_033">

              </td>
            </tr>
            <tr class="border">
              <td colspan="4">
                <label class="form-label">Credentialing contact’s telephone number:
                  
                </label>
                <input type="text" class="form-control" formControlName="cmd_034" (keydown)="handleNumber($event,'cmd_034')" maxlength="12">
                <small class="text-danger" *ngIf="eyeForm.controls['cmd_034'].errors?.pattern">
                  Required pattern xxx-xxx-xxxx
                 </small>
              </td>
              <td colspan="3">
                <label class="form-label">
                  Credentialing contact’s fax number:</label>
                  <input type="text" class="form-control" formControlName="cmd_035" (keydown)="handleNumber($event,'cmd_035')" maxlength="12">
                  <small class="text-danger" *ngIf="eyeForm.controls['cmd_035'].errors?.pattern">
                    Required pattern xxx-xxx-xxxx
                   </small>
              </td>
              <td colspan="4">
                <label class="form-label">
                  Credentialing contact’s email address:
                </label>
                  <input type="text" class="form-control" formControlName="cmd_036">
                  <small
                      class="text-danger"
                      *ngIf="eyeForm.controls['cmd_036'].errors?.pattern"
                      >Please enter a valid email.</small
                    >

              </td>
              
            </tr>
            <tr class="border">
              <td colspan="12">
                <label class="form-label">
                  Federal tax ID number or social security number, if used for business purposes:</label>
                  <input type="text" class="form-control" formControlName="cmd_037">

              </td>
            </tr>
            <tr class="border">
              <td colspan="12">
                <label class="form-label">
                  Name affiliated with tax ID number:</label>
                  <input type="text" class="form-control" formControlName="cmd_038">

              </td>
            </tr>
            <tr class="border">
              <td colspan="6">
                <h6 class="form-label">
                  Name of secondary practice/affiliation or clinic:</h6>
                  <input type="text" class="form-control" formControlName="cmd_039">

              </td>
              <td colspan="6">
                <label class="form-label">
                  Department name (if hospital based):</label>
                  <input type="text" class="form-control" formControlName="cmd_040">

              </td>
            </tr>
            <tr class="border">
              <td colspan="8">
                <label class="form-label">
                  Secondary Clinical Practice street address:</label>
                  <input type="text" class="form-control" formControlName="cmd_041">

              </td>
              <td colspan="4">
                <label class="form-label">
                  Entity type 2 (group) NPI number:</label>
                  <input type="text" class="form-control" formControlName="cmd_042">

              </td>
            </tr>
            <tr class="border">
              <td colspan="3">
                <label class="form-label">City: 
                  
                </label>
                <input type="text" class="form-control" formControlName="cmd_043">

              </td>
              <td colspan="3">
                <label class="form-label">
                  County:</label>
                  <input type="text" class="form-control" formControlName="cmd_044">

              </td>
              <td colspan="3">
                <label class="form-label">
                  State:</label>
                  <input type="text" class="form-control" formControlName="cmd_045">

              </td>
              <td colspan="3">
                <label class="form-label">
                  ZIP:</label>
                  <input type="text" class="form-control" formControlName="cmd_046">
                  <small
                  class="text-danger"
                  *ngIf="eyeForm.controls['cmd_046'].errors?.pattern"
                  > Zip code must be atleast 5 digits and less than 10 digits.</small
                >
              </td>
              
            </tr>
            <tr class="border">
              <td colspan="4">
                <label class="form-label">Primary office telephone number:
                  
                </label>
                <input type="text" class="form-control" formControlName="cmd_047" (keydown)="handleNumber($event,'cmd_047')" maxlength="12">
                <small class="text-danger" *ngIf="eyeForm.controls['cmd_047'].errors?.pattern">
                  Required pattern xxx-xxx-xxxx
                 </small>
              </td>
              <td colspan="3">
                <label class="form-label">
                  Primary office fax number:</label>
                  <input type="text" class="form-control" formControlName="cmd_048" (keydown)="handleNumber($event,'cmd_048')" maxlength="12">
                  <small class="text-danger" *ngIf="eyeForm.controls['cmd_048'].errors?.pattern">
                    Required pattern xxx-xxx-xxxx
                   </small>
              </td>
              <td colspan="4">
                <label class="form-label">
                  Patient appointment telephone number:</label>
                  <input type="text" class="form-control" formControlName="cmd_049" (keydown)="handleNumber($event,'cmd_049')" maxlength="12">
                  <small class="text-danger" *ngIf="eyeForm.controls['cmd_049'].errors?.pattern">
                    Required pattern xxx-xxx-xxxx
                   </small>
              </td>
              
            </tr>
            <tr class="border">
              <td colspan="8">
                <label class="form-label">Mailing/Billing Address (if different from above):
                  
                </label>
                <input type="text" class="form-control" formControlName="cmd_050">

              </td>
              <td colspan="4">
                <label class="form-label">
                  Attn:</label>
                  <input type="text" class="form-control" formControlName="cmd_051">

              </td>
              
            </tr>
            <tr class="border">
              <td colspan="4">
                <label class="form-label">Office manager:
                  
                </label>
                <input type="text" class="form-control" formControlName="cmd_052">

              </td>
              <td colspan="4">
                <label class="form-label">
                  Office manager’s telephone number:</label>
                  <input type="text" class="form-control" formControlName="cmd_053" (keydown)="handleNumber($event,'cmd_053')" maxlength="12">
                  <small class="text-danger" *ngIf="eyeForm.controls['cmd_053'].errors?.pattern">
                    Required pattern xxx-xxx-xxxx
                   </small>
              </td>
              <td colspan="4">
                <label class="form-label">
                  Office manager’s fax number:</label>
                  <input type="text" class="form-control" formControlName="cmd_054" (keydown)="handleNumber($event,'cmd_054')" maxlength="12">
                  <small class="text-danger" *ngIf="eyeForm.controls['cmd_054'].errors?.pattern">
                    Required pattern xxx-xxx-xxxx
                   </small>
              </td>
              
            </tr>
            <tr class="border">
              <td colspan="4">
                <label class="form-label">Exchange/answering service number:
                  
                </label>
                <input type="text" class="form-control" formControlName="cmd_055">

              </td>
              <td colspan="4">
                <label class="form-label">
                  Pager number:</label>
                  <input type="text" class="form-control" formControlName="cmd_056">

              </td>
              <td colspan="4">
                <label class="form-label">
                  Office email address:</label>
                  <input type="text" class="form-control" formControlName="cmd_057">
                  <small
                      class="text-danger"
                      *ngIf="eyeForm.controls['cmd_057'].errors?.pattern"
                      >Please enter a valid email.</small
                    >

              </td>
              
            </tr>
            <tr class="border">
              <td colspan="12">
                <label class="form-label">
                  Credentialing Contact and Address:</label>
                  <input type="text" class="form-control" formControlName="cmd_058">

              </td>
            </tr>
            <tr class="border">
              <td colspan="4">
                <label class="form-label">Credentialing contact’s telephone number:
                  
                </label>
                <input type="text" class="form-control" formControlName="cmd_059" (keydown)="handleNumber($event,'cmd_059')" maxlength="12">
                <small class="text-danger" *ngIf="eyeForm.controls['cmd_059'].errors?.pattern">
                  Required pattern xxx-xxx-xxxx
                 </small>
              </td>
              <td colspan="3">
                <label class="form-label">
                  Credentialing contact’s fax number:</label>
                  <input type="text" class="form-control" formControlName="cmd_060" (keydown)="handleNumber($event,'cmd_060')" maxlength="12">
                  <small class="text-danger" *ngIf="eyeForm.controls['cmd_060'].errors?.pattern">
                    Required pattern xxx-xxx-xxxx
                   </small>
              </td>
              <td colspan="4">
                <label class="form-label">
                  Credentialing contact’s email address:
                </label>
                  <input type="text" class="form-control" formControlName="cmd_061">
                  <small
                  class="text-danger"
                  *ngIf="eyeForm.controls['cmd_061'].errors?.pattern"
                  >Please enter a valid email.</small
                >

              </td>
              
            </tr>
            <tr class="border">
              <td colspan="12">
                <label class="form-label">
                  Federal tax ID number or social security number, if used for business purposes:</label>
                  <input type="text" class="form-control" formControlName="cmd_062">

              </td>
            </tr>
            <tr class="border">
              <td colspan="12">
                <label class="form-label">
                  Name affiliated with tax ID number:</label>
                  <input type="text" class="form-control" formControlName="cmd_063">

              </td>
            </tr>
            <tr>
              <td colspan="12">
                <h6 class="form-label" >Please list other office locations with above information on a separate sheet.</h6>
              </td>
            </tr>
            <tr>
              <td colspan="12">
                <div class="centered" class="identifyBox">
                  <h3 class="hThreeAlign">
                    VII. Practice Call Coverage
                    <!-- <span class="normalParagraphStyle">
                      <i>Please attach copy of certificate(s), if applicable</i>
                    </span> -->
                  </h3>
                  <h6>Please provide the name and specialty of those practitioners who provide care for your patients when you are unavailable.
                  </h6>
                </div>
              </td>
            </tr>
            <tr class="border">
              <td colspan="6">
                  <h6
                    >Name:
              </h6>
              </td>
              <td colspan="6" >
                  <h6 >Specialty:</h6>
              </td>   
            </tr> 
            <tr class="border">
              <td colspan="6">
                <input class="form-control" id="2953" name="2953"  formControlName="2953" placeholder="Covering Physician Name 1"/>
              </td>
              <td colspan="6">
                  <ng-select
                  [items]="specialty"
                  bindLabel="name"
                  placeholder="Covering Physician Primary Specialty 1"
                  bindValue="id"
                  formControlName="3403"
                  style="width:400px"
                >
                </ng-select>
                 
              </td>
            </tr>
            <tr class="border">
              <td colspan="6">
                <input class="form-control" id="2963" name="2963"  formControlName="2963" placeholder="Covering Physician Name 2"/>
              </td>
              <td colspan="6">
                  <ng-select
                    [items]="specialty"
                    bindLabel="name"
                    placeholder="Covering Physician Primary Specialty 2"
                    bindValue="id"
                    formControlName="3404"
                    style="width:400px"
                  >
                  </ng-select>
              </td>
            </tr>
            <tr class="border">
              <td colspan="6">
                <input class="form-control" id="2979" name="2979"  formControlName="2979" placeholder="Covering Physician Name 3"/>
              </td>
              <td colspan="6">
                  <ng-select
                    [items]="specialty"
                    bindLabel="name"
                    placeholder="Covering Physician Primary Specialty 3"
                    bindValue="id"
                    formControlName="3405"
                    style="width:400px"
                  >
                  </ng-select>
              </td>
            </tr>
            <tr class="border">
              <td colspan="6">
                <input class="form-control" id="add_1_3469" name="add_1_3469"  formControlName="add_1_3469" placeholder="Covering Physician Name 4"/>
              </td>
              <td colspan="6">
                  <ng-select
                    [items]="specialty"
                    bindLabel="name"
                    placeholder="Covering Physician Primary Specialty 4"
                    bindValue="id"
                    formControlName="add_1_3491"
                    style="width:400px"
                  >
                  </ng-select>
              </td>
            </tr>
            <tr class="border">
              <td colspan="6">
                <input class="form-control" id="add_2_3469" name="add_2_3469"  formControlName="add_2_3469" placeholder="Covering Physician Name 5"/>
              </td>
              <td colspan="6">
                  <ng-select
                    [items]="specialty"
                    bindLabel="name"
                    placeholder="Covering Physician Primary Specialty 5"
                    bindValue="id"
                    formControlName="add_2_3491"
                    style="width:400px"
                  >
                  </ng-select>
              </td>
            </tr>
            <tr>
              <td colspan="12">
                <div class="centered texasFirstTable">
                  <div class="row">
                  <div class="col-md-8">
                      <h2  style="margin-left: 31px;  font-size: 18px;"><b>VIII. Undergraduate Education</b>
                        <span class="normalParagraphStyle">
                          <i>(Please attach additional sheets, if necessary.)</i>
                        </span>
                      </h2>
                  </div>
                  <div class="col-md-4">
                      <label for="cmd_0064"><b>Does Not Apply:</b></label>&nbsp;&nbsp;
                      <input type="checkbox" formControlName="cmd_0064"
                          id="cmd_0064" value="1"  (ngModelChange)="handleNAchange('cmd_0064')"
                          >
              </div>
                </div>
              </div>
              </td>
          </tr>
          <tr class="border">
            <td colspan="4">
         Complete school name and street address:
         <input class="form-control onboard" formControlName="503" (blur)="handleNAchange('cmd_0064',503)"
         [class.is-invalid]="(eyeForm.controls['503'].invalid)"
         id="503" name="503" type="text" placeholder= "UG School" data-id="managing_contact_name">         
         <div class="formLabel col-md-11"
         *ngIf="eyeForm.controls['503'].touched && eyeForm.controls['503'].invalid">
         <small class="text-danger" *ngIf="eyeForm.controls['503'].errors?.required">
             required
         </small>
     </div>
         <div class="row">
          <div class="col-md-6 mt-2">
            <input class="form-control onboard" formControlName="504" (blur)="handleNAchange('cmd_0064',504)"
            [class.is-invalid]="(eyeForm.controls['504'].invalid)"
            id="504" name="504" type="text" placeholder= "UG School Address1" data-id="managing_contact_name">       
          <div class="formLabel col-md-11"
         *ngIf="eyeForm.controls['504'].touched && eyeForm.controls['504'].invalid">
         <small class="text-danger" *ngIf="eyeForm.controls['504'].errors?.required">
             required
         </small>
     </div>
        </div>
        <div class="col-md-6 mt-2">
          <input class="form-control onboard" formControlName="505"
          id="505" name="505" type="text" placeholder= "UG School Address2" data-id="managing_contact_name">       
      
        </div>
        </div>
            </td>
            <td colspan="4">
              Degree received:
              <ng-select [items]="degree" bindLabel="name" placeholder="Select Degree" bindValue="id" (blur)="handleNAchange('cmd_0064',512)"
              [class.is-invalid]="(eyeForm.controls['512'].invalid)"
               formControlName="512"
               style="width:200px">
              </ng-select>
              <div class="formLabel col-md-11"
                  *ngIf="eyeForm.controls['512'].touched && eyeForm.controls['512'].invalid">
                  <small class="text-danger" *ngIf="eyeForm.controls['512'].errors?.required">
                      required
                  </small>
              </div>
                </td>
                <td colspan="4">
                  <div class="responsive-container">
                      <div>Month/year of start:</div>
                      <div >
                        <div class="input-group-append">
                          <input class="form-control onboard datepicker" formControlName="510"  (blur)="handleNAchange('cmd_0064',510)" (blur)="clearInputIfInvalid('510', 'cmd_0064')"
                          [class.is-invalid]="(eyeForm.controls['510'].invalid)"
                          ngbDatepicker #d560="ngbDatepicker" id="510" name="510" type="text" placeholder= "UG School Start Date MM/YYYY" data-id="510">
                              <button class="btn btn-outline-secondary calendar" (click)="d560.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                          </div>
                          <div class="formLabel col-md-11"
                              *ngIf="eyeForm.controls['510'].touched && eyeForm.controls['510'].invalid">
                              <small class="text-danger" *ngIf="eyeForm.controls['510'].errors?.required">
                                  required
                              </small>
                          </div>
                          <div  *ngIf="eyeForm.get('510').invalid ">
                            <small class="text-danger" *ngIf="eyeForm.get('510').errors?.invalidDateFormat">
                              Only Date Format is allowed(MM/DD/YYYY)                  </small>
                            <small class="text-danger" *ngIf="eyeForm.get('510').errors?.invalidMonth">
                            Month is out of range
                            </small>
                            <small class="text-danger" *ngIf="eyeForm.get('510').errors?.invalidDateyear">
                              year is out of range(1900-2099)                    </small>
                              <small class="text-danger" *ngIf="eyeForm.get('510').errors?.invalidDateRange">
                                date is out of range
                                </small>
                            </div>
                      </div>
                      <div class="mt-2">Month/year of graduation:</div>
                      <div >
                        <div class="input-group-append">
                          <input class="form-control onboard datepicker" formControlName="1238"  (blur)="handleNAchange('cmd_0064',1238)" (blur)="clearInputIfInvalid('1238', 'cmd_0064')"
                          [class.is-invalid]="(eyeForm.controls['1238'].invalid)"
                          ngbDatepicker #d561="ngbDatepicker" id="1238" name="1238" type="text" placeholder= "UG School End Date MM/YYYY" data-id="1238">
                              <button class="btn btn-outline-secondary calendar" (click)="d561.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                          </div>
                          <div class="formLabel col-md-11"
                              *ngIf="eyeForm.controls['1238'].touched && eyeForm.controls['1238'].invalid">
                              <small class="text-danger" *ngIf="eyeForm.controls['1238'].errors?.required">
                                  required
                              </small>
                          </div>
                          <div  *ngIf="eyeForm.get('1238').invalid ">
                            <small class="text-danger" *ngIf="eyeForm.get('1238').errors?.invalidDateFormat">
                              Only Date Format is allowed(MM/DD/YYYY)                  </small>
                            <small class="text-danger" *ngIf="eyeForm.get('1238').errors?.invalidMonth">
                            Month is out of range
                            </small>
                            <small class="text-danger" *ngIf="eyeForm.get('1238').errors?.invalidDateyear">
                              year is out of range(1900-2099)                    </small>
                              <small class="text-danger" *ngIf="eyeForm.get('1238').errors?.invalidDateRange">
                                date is out of range
                                </small>
                            </div>
                      </div>
                  </div>
              </td>
              
          </tr>
          <tr>
            <td colspan="4">
              City:
              <input class="form-control onboard" formControlName="506" (blur)="handleNAchange('cmd_0064',506)"
              [class.is-invalid]="(eyeForm.controls['506'].invalid)"
              id="506" name="506" type="text" placeholder= "UG School City" data-id="managing_contact_name">       
            <div class="formLabel col-md-11"
           *ngIf="eyeForm.controls['506'].touched && eyeForm.controls['506'].invalid">
           <small class="text-danger" *ngIf="eyeForm.controls['506'].errors?.required">
               required
           </small>
       </div>
            </td>
            <td colspan="4">
              State:
              <ng-select
              [items]="states"
              [class.is-invalid]="
                (eyeForm.controls['508'].invalid)
              "
              bindLabel="name"
              placeholder="State or Country"
              bindValue="id"
              formControlName="508"
              style="width:250px"
            >
            </ng-select>       
              <div
              class="formLabel col-md-11"
              *ngIf="
                eyeForm.controls['508'].touched &&
                eyeForm.controls['508'].invalid
              "
            >
              <small
                class="text-danger"
                *ngIf="eyeForm.controls['508'].errors"
              >
                required
              </small>
            </div>
            </td>
            <td colspan="4">
              Course of study or major:
              <input class="form-control onboard" formControlName="513" (blur)="handleNAchange('cmd_0064',513)"
              [class.is-invalid]="(eyeForm.controls['513'].invalid)"
              id="513" name="513" type="text" placeholder= "UG School Program" data-id="managing_contact_name">       
            <div class="formLabel col-md-11"
           *ngIf="eyeForm.controls['513'].touched && eyeForm.controls['513'].invalid">
           <small class="text-danger" *ngIf="eyeForm.controls['513'].errors?.required">
               required
           </small>
       </div>
            </td>
          </tr>
          <tr>
            <td colspan="12">
              <div class="centered texasFirstTable">
                <div class="row">
                <div class="col-md-8">
                    <h2  style="margin-left: 31px;  font-size: 18px;"><b>IX. Graduate Education </b>
                      <span class="normalParagraphStyle">
                        <i> (Please attach additional sheets, if necessary.)</i>
                      </span>
                    </h2>
                </div>
                <div class="col-md-4">
                    <label for="cmd_0065"><b>Does Not Apply:</b></label>&nbsp;&nbsp;
                    <input type="checkbox" formControlName="cmd_0065"
                        id="cmd_0065" value="1"  
                        >
            </div>
              </div>
            </div>
            </td>
        </tr>
        <tr class="border">
          <td colspan="4">
       Complete school name and street address:
       <input class="form-control onboard" formControlName="479" 
       id="479" name="479" type="text" placeholder= "Grad School" data-id="managing_contact_name">         
      
       <div class="row">
        <div class="col-md-6 mt-2">
          <input class="form-control onboard" formControlName="483" 
          id="483" name="483" type="text" placeholder= "Grad School Address1" data-id="managing_contact_name">       
       
      </div>
      <div class="col-md-6 mt-2">
        <input class="form-control onboard" formControlName="484"
        id="484" name="484" type="text" placeholder= "Grad School Address 2" data-id="managing_contact_name">       
    
      </div>
      </div>
          </td>
          <td colspan="4">
            Degree received:
            <ng-select [items]="degree" bindLabel="name" placeholder="Grad Degree" bindValue="id" 
             formControlName="480"
             style="width:250px">
            </ng-select>
            
              </td>
              <td colspan="4">
                <div class="responsive-container">
                    <div>Month/year of start:</div>
                    <div >
                      <div class="input-group-append">
                        <input class="form-control onboard datepicker" formControlName="481" (blur)="clearInputIfInvalid('481')" 
                        ngbDatepicker #d31="ngbDatepicker" id="481" name="481" type="text" placeholder= "Grad School Start Date MM/YYYY" data-id="481">
                            <button class="btn btn-outline-secondary calendar" (click)="d31.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                        </div>
                        <div  *ngIf="eyeForm.get('481').invalid ">
                          <small class="text-danger" *ngIf="eyeForm.get('481').errors?.invalidDateFormat">
                            Only Date Format is allowed(MM/DD/YYYY)                  </small>
                          <small class="text-danger" *ngIf="eyeForm.get('481').errors?.invalidMonth">
                          Month is out of range
                          </small>
                          <small class="text-danger" *ngIf="eyeForm.get('481').errors?.invalidDateyear">
                            year is out of range(1900-2099)                    </small>
                            <small class="text-danger" *ngIf="eyeForm.get('481').errors?.invalidDateRange">
                              date is out of range
                              </small>
                          </div>
                        
                    </div>
                    <div class="mt-2">Month/year of graduation:</div>
                    <div >
                      <div class="input-group-append">
                        <input class="form-control onboard datepicker" formControlName="1300"  (blur)="clearInputIfInvalid('1300')"
                        ngbDatepicker #d30="ngbDatepicker" id="1300" name="1300" type="text" placeholder= "Grad School End Date MM/YYYY" data-id="1300">
                            <button class="btn btn-outline-secondary calendar" (click)="d30.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                        </div>
                        <div  *ngIf="eyeForm.get('1300').invalid ">
                          <small class="text-danger" *ngIf="eyeForm.get('1300').errors?.invalidDateFormat">
                            Only Date Format is allowed(MM/DD/YYYY)                  </small>
                          <small class="text-danger" *ngIf="eyeForm.get('1300').errors?.invalidMonth">
                          Month is out of range
                          </small>
                          <small class="text-danger" *ngIf="eyeForm.get('1300').errors?.invalidDateyear">
                            year is out of range(1900-2099)                    </small>
                            <small class="text-danger" *ngIf="eyeForm.get('1300').errors?.invalidDateRange">
                              date is out of range
                              </small>
                          </div>
                        
                    </div>
                </div>
            </td>
            
        </tr>
        <tr class="border">
          <td colspan="4">
            City:
            <input class="form-control onboard" formControlName="485" 
            id="485" name="485" type="text" placeholder= "Grad School City" data-id="managing_contact_name">       
        
          </td>
          <td colspan="4">
            State:
            <ng-select
            [items]="states"
            bindLabel="name"
            placeholder="State or Country"
            bindValue="id"
            formControlName="487"
            style="width:250px"
          >
          </ng-select>       
           
          </td>
          <td colspan="4">
            Course of study or major:
            <input class="form-control onboard" formControlName="489"
            id="489" name="489" type="text" placeholder= "Grad School Program" data-id="managing_contact_name">       
        
          </td>
        </tr>
        <tr>
          <td colspan="12">
            <div class="centered texasFirstTable">
              <div class="row">
              <div class="col-md-8">
                  <h2  style="margin-left: 31px;  font-size: 18px;"><b>X. Medical / Professional Education </b>
                    <span class="normalParagraphStyle">
                      <i> (Please attach additional sheets, if necessary.)</i>
                    </span>
                  </h2>
              </div>
              <div class="col-md-4">
                  <label for="cmd_0066"><b>Does Not Apply:</b></label>&nbsp;&nbsp;
                  <input type="checkbox" formControlName="cmd_0066"
                      id="cmd_0066" value="1" (ngModelChange)="handleNAchange('cmd_0066')"  
                      >
          </div>
            </div>
          </div>
          </td>
      </tr>
      <tr>
        <td colspan="12">
          <h6>Complete medical/professional school name and street address:</h6>
          <div class="row">
            <div class="col-md-4">
              <input class="form-control onboard" formControlName="490" (blur)="handleNAchange('cmd_0066',490)"
              [class.is-invalid]="(eyeForm.controls['490'].invalid)"
              id="490" name="490" type="text" placeholder= "Med School" data-id="managing_contact_name">         
              <div class="formLabel col-md-11"
              *ngIf="eyeForm.controls['490'].touched && eyeForm.controls['490'].invalid">
              <small class="text-danger" *ngIf="eyeForm.controls['490'].errors?.required">
                  required
              </small>
          </div>
            </div>
            <div class="col-md-4">
              <input class="form-control onboard" formControlName="491" (blur)="handleNAchange('cmd_0066',491)"
              [class.is-invalid]="(eyeForm.controls['491'].invalid)"
              id="491" name="491" type="text" placeholder= "Med School Address1" data-id="managing_contact_name">         
              <div class="formLabel col-md-11"
              *ngIf="eyeForm.controls['491'].touched && eyeForm.controls['491'].invalid">
              <small class="text-danger" *ngIf="eyeForm.controls['491'].errors?.required">
                  required
              </small>
          </div>
            </div>
            <div class="col-md-4">
              <input class="form-control onboard" formControlName="492"  (blur)="handleNAchange('cmd_0066',492)"
              [class.is-invalid]="(eyeForm.controls['492'].invalid)"
              id="492" name="492" type="text" placeholder= "Med School Address2" data-id="managing_contact_name">         
              <div class="formLabel col-md-11"
              *ngIf="eyeForm.controls['492'].touched && eyeForm.controls['492'].invalid">
              <small class="text-danger" *ngIf="eyeForm.controls['492'].errors?.required">
                  required
              </small>
          </div>
            </div>
          </div>
        </td>
      </tr>
      <tr class="border">
        <td colspan="2">
          City:
          <input class="form-control onboard" formControlName="493" (blur)="handleNAchange('cmd_0066',493)"
              [class.is-invalid]="(eyeForm.controls['493'].invalid)"
              id="493" name="493" type="text" placeholder= "Med School City" data-id="managing_contact_name">         
              <div class="formLabel col-md-11"
              *ngIf="eyeForm.controls['493'].touched && eyeForm.controls['493'].invalid">
              <small class="text-danger" *ngIf="eyeForm.controls['493'].errors?.required">
                  required
              </small>
          </div>
        </td>
        <td colspan="3">
          State:
          <ng-select
          [items]="states"
          [class.is-invalid]="
            (eyeForm.controls['495'].invalid)
          "
          bindLabel="name"
          placeholder="State or Country"
          bindValue="id"
          formControlName="495"
          (blur)="handleNAchange('cmd_0066',495)"
          style="width:180px"
        >
        </ng-select>       
          <div
          class="formLabel col-md-11"
          *ngIf="
            eyeForm.controls['495'].touched &&
            eyeForm.controls['495'].invalid
          "
        >
          <small
            class="text-danger"
            *ngIf="eyeForm.controls['495'].errors"
          >
            required
          </small>
        </div>     
         
        </td>
        <td colspan="3">
          ZIP:         
          <input class="form-control onboard" formControlName="496" (blur)="handleNAchange('cmd_0066',496)"    
          [class.is-invalid]="(eyeForm.controls['496'].invalid)"
          id="496" name="496" type="text" placeholder= "Med School Zip" data-id="managing_contact_name">         
          <div
                  class="formLabel col-md-2"
                  *ngIf="
                    eyeForm.controls['496'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="eyeForm.controls['496'].errors"
                  >
                    required
                  </small>
                  <small
                    class="text-danger"
                    *ngIf="
                      eyeForm.controls['496'].errors?.invalidZipCode
                    "
                  >
                    Zip code must be atleast 5 digits and less than 10 digits
                  </small>
                </div>
        </td>
        <td colspan="4">
          Contact email:
          <input class="form-control onboard" formControlName="2353" (blur)="handleNAchange('cmd_0066',2353)"
          [class.is-invalid]="(eyeForm.controls['2353'].invalid)"
          id="2353" name="2353" type="text" placeholder= "Med School Email" data-id="managing_contact_name">         
          <div class="formLabel col-md-11"
          *ngIf="eyeForm.controls['2353'].touched && eyeForm.controls['2353'].errors">
          <small class="text-danger" *ngIf="eyeForm.controls['2353'].errors?.required">
              required
          </small>
          <small
          class="text-danger"
          *ngIf="eyeForm.controls['2353'].invalid"
          >Please enter a valid email.</small
        >
      </div>
        </td>
      </tr>
      <tr class="border">
        <td colspan="4">
          Degree received:
          <ng-select [items]="degree" bindLabel="name" placeholder="Med Degree" bindValue="id" (blur)="handleNAchange('cmd_0066',500)"
          [class.is-invalid]="(eyeForm.controls['500'].invalid)"
           formControlName="500">
          </ng-select>
          <div class="formLabel col-md-11"
              *ngIf="eyeForm.controls['500'].touched && eyeForm.controls['500'].invalid">
              <small class="text-danger" *ngIf="eyeForm.controls['500'].errors?.required">
                  required
              </small>
          </div>
        </td>
        <td colspan="4">
          Phone number:
          <input class="form-control onboard" formControlName="2351" (keydown)="handleNumber($event,2351)" maxlength="12" (blur)="handleNAchange('cmd_0066',2351)"
          [class.is-invalid]="(eyeForm.controls['2351'].invalid)"
          id="2351" name="2351" type="text" placeholder= "Med School Phone" data-id="managing_contact_name">         
          <div class="formLabel col-md-11"
          *ngIf="eyeForm.controls['2351'].touched && eyeForm.controls['2351'].invalid">
          <small class="text-danger" *ngIf="eyeForm.controls['2351'].errors?.required">
              required
          </small>
          <small class="text-danger" *ngIf="eyeForm.controls['2351'].errors?.incorrectPhoneFormat">
            Required pattern xxx-xxx-xxxx
           </small>
          </div>
        </td>
        <td colspan="4">
          Fax number, if available
          <input class="form-control onboard" formControlName="2352" (keydown)="handleNumber($event,2352)" maxlength="12"
          id="2352" name="2352" type="text" placeholder= "Med School Fax" data-id="managing_contact_name">         
          <small class="text-danger" *ngIf="eyeForm.controls['2352'].errors?.pattern">
            Required pattern xxx-xxx-xxxx
           </small>
        </td>
      </tr>
      <tr class="border">
        <td colspan="4">
          From month/year:
          <ctg-month-mode 
          [initialVal]="eyeForm.controls[497]&&eyeForm.controls[497].value" (blur)="handleNAchange('cmd_0066',497)"  (blur)="clearInputIfInvalidMonth('497', 'cmd_0066')"
          (returnValue)="eyeForm.controls[497].setValue($event)"></ctg-month-mode>
               <div class="mandatory"
                     *ngIf="eyeForm.controls['497'].errors">
                     <small >
                        required
                     </small>
               </div>
            <div class="formLabel col-md-11"
                *ngIf="eyeForm.controls['497'].touched && eyeForm.controls['497'].invalid">
                <small class="text-danger" *ngIf="eyeForm.controls['497'].errors?.required">
                    required
                </small>
            </div>
            <div  *ngIf="eyeForm.get('497').invalid ">
              <small class="text-danger" *ngIf="eyeForm.get('497').errors?.invalidDateFormat">
                Only Date Format is allowed(MM/YYYY)                  </small>
              <small class="text-danger" *ngIf="eyeForm.get('497').errors?.invalidMonth">
              Month is out of range
              </small>
              <small class="text-danger" *ngIf="eyeForm.get('497').errors?.invalidDateyear">
                year is out of range(1900-2099)                    </small>
               
              </div>
        </td>
        <td colspan="4">
          To month/year:
          <ctg-month-mode 
          [initialVal]="eyeForm.controls[498]&&eyeForm.controls[498].value" (blur)="handleNAchange('cmd_0066',498)"  (blur)="clearInputIfInvalidMonth('498', 'cmd_0066')"
          (returnValue)="eyeForm.controls[498].setValue($event)"></ctg-month-mode>
          <div class="mandatory"
          *ngIf="eyeForm.controls['498'].errors">
          <small >
             required
          </small>
    </div>
            <div  *ngIf="eyeForm.get('498').invalid ">
              <small class="text-danger" *ngIf="eyeForm.get('498').errors?.invalidDateFormat">
                Only Date Format is allowed(MM/YYYY)                  </small>
              <small class="text-danger" *ngIf="eyeForm.get('498').errors?.invalidMonth">
              Month is out of range
              </small>
              <small class="text-danger" *ngIf="eyeForm.get('498').errors?.invalidDateyear">
                year is out of range(1900-2099)  </small>
              </div>
        </td>
        <td colspan="4">
          Month/year of completion:
          <div class="input-group-append">
            <input class="form-control onboard datepicker" formControlName="499" (blur)="handleNAchange('cmd_0066',499)"  (blur)="clearInputIfInvalid('499', 'cmd_0066')"
            [class.is-invalid]="(eyeForm.controls['499'].invalid)"
            ngbDatepicker #d36="ngbDatepicker" id="499" name="499" type="text" placeholder= "Med School Completed MM/DD/YYYY" data-id="499">
                <button class="btn btn-outline-secondary calendar" (click)="d36.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
            </div>
            <div class="formLabel col-md-11"
                *ngIf="eyeForm.controls['499'].touched && eyeForm.controls['499'].invalid">
                <small class="text-danger" *ngIf="eyeForm.controls['499'].errors?.required">
                    required
                </small>
            </div>
            <div  *ngIf="eyeForm.get('499').invalid ">
              <small class="text-danger" *ngIf="eyeForm.get('499').errors?.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY)                  </small>
              <small class="text-danger" *ngIf="eyeForm.get('499').errors?.invalidMonth">
              Month is out of range
              </small>
              <small class="text-danger" *ngIf="eyeForm.get('499').errors?.invalidDateyear">
                year is out of range(1900-2099)                    </small>
                <small class="text-danger" *ngIf="eyeForm.get('499').errors?.invalidDateRange">
                  date is out of range
                  </small>
              </div>
        </td>
      </tr>
      <tr>
        <td colspan="12">
            <div class="row">
                    <div class="col-md-6">
                   <label class="formLabel" style="font-size: 18px;">Did you complete the program?
                   </label>
                 </div>
          
            <div class="col-md-4">
                <label class="radio-inline radioStyle">
                    <input
                        type="radio"
                        id="cmd_0067"
                        name="cmd_0067"
                    formControlName="cmd_0067"
                    value="1"
                    />Yes</label>
                    <label class="radio-inline radioStyle">
                    <input
                         type="radio"
                         id="cmd_0067"
                        name="cmd_0067"
                    formControlName="cmd_0067"
                    value="0"
                    />No <i> (if you did not complete the program, please explain on a separate sheet.)</i></label> 
                  
            </div>
           </div>
        </td>
    </tr>
    <tr>
      <td colspan="12">
        <h6>Complete medical/professional school name and street address:</h6>
        <div class="row">
          <div class="col-md-4">
            <input class="form-control onboard" formControlName="1288"
            id="1288" name="1288" type="text" placeholder= "Additional School Name" data-id="managing_contact_name">         
          </div>
          <div class="col-md-4">
            <input class="form-control onboard" formControlName="1289"
            id="1289" name="1289" type="text" placeholder= "Additional School Address 1" data-id="managing_contact_name">         
          
          </div>
          <div class="col-md-4">
            <input class="form-control onboard" formControlName="1290"
            id="1290" name="1290" type="text" placeholder= " Additional School Address 2" data-id="managing_contact_name">         
          
          </div>
        </div>
      </td>
    </tr>
    <tr class="border">
      <td colspan="2">
        City:
        <input class="form-control onboard" formControlName="1291"
            id="1291" name="1291" type="text" placeholder= "Additional School City" data-id="managing_contact_name">         
      </td>
      <td colspan="3">
        State:
        <ng-select
        [items]="states"
        
        bindLabel="name"
        placeholder="State or Country"
        bindValue="id"
        formControlName="1293"
        style="width:180px"
      >
      </ng-select>       
           
       
      </td>
      <td colspan="3">
        ZIP:         
        <input class="form-control onboard" formControlName="1294"
        id="1294" name="1294" type="text" placeholder= "Additional School Zip" data-id="managing_contact_name">         
        <small
        class="text-danger"
        *ngIf="eyeForm.controls['1294'].errors?.pattern"
        > Zip code must be atleast 5 digits and less than 10 digits.</small
      >

      </td>
      <td colspan="4">
        Contact email:
        <input class="form-control onboard" formControlName="2356"
        id="2356" name="2356" type="text" placeholder= "Additional School Email" data-id="managing_contact_name">         
        <small
        class="text-danger"
        *ngIf="eyeForm.controls['2356'].errors?.pattern"
        >Please enter a valid email.</small
      >
      </td>
    </tr>
    <tr class="border">
      <td colspan="4">
        Degree received:
        <ng-select [items]="degree" bindLabel="name" placeholder="Primary Specialty" bindValue="id" 
         formControlName="1298">
        </ng-select>
      
      </td>
      <td colspan="4">
        Phone number:
        <input class="form-control onboard" formControlName="2354" (keydown)="handleNumber($event,2354)" maxlength="12"
        id="2354" name="2354" type="text" placeholder= "Additional School Phone" data-id="managing_contact_name">         
        <small class="text-danger" *ngIf="eyeForm.controls['2354'].errors?.pattern">
          Required pattern xxx-xxx-xxxx
         </small>
      </td>
      <td colspan="4">
        Fax number, if available
        <input class="form-control onboard" formControlName="2355" (keydown)="handleNumber($event,2355)" maxlength="12"
        id="2355" name="2355" type="text" placeholder= "Additional School Fax" data-id="managing_contact_name">         
        <small class="text-danger" *ngIf="eyeForm.controls['2355'].errors?.pattern">
          Required pattern xxx-xxx-xxxx
         </small>
      </td>
    </tr>
    <tr class="border">
      <td colspan="4">
        From month/year:
        <ctg-month-mode 
        [initialVal]="eyeForm.controls[1295]&&eyeForm.controls[1295].value"   (blur)="clearInputIfInvalidMonth('1295')"
        (returnValue)="eyeForm.controls[1295].setValue($event)"></ctg-month-mode>
          <div  *ngIf="eyeForm.get('1295').invalid ">
            <small class="text-danger" *ngIf="eyeForm.get('1295').errors?.invalidDateFormat">
              Only Date Format is allowed(MM/YYYY)                  </small>
            <small class="text-danger" *ngIf="eyeForm.get('1295').errors?.invalidMonth">
            Month is out of range
            </small>
            <small class="text-danger" *ngIf="eyeForm.get('1295').errors?.invalidDateyear">
              year is out of range(1900-2099)                    </small>
            </div>
      </td>
      <td colspan="4">
        To month/year:
        <ctg-month-mode 
        [initialVal]="eyeForm.controls[1296]&&eyeForm.controls[1296].value"   (blur)="clearInputIfInvalidMonth('1296')"
        (returnValue)="eyeForm.controls[1296].setValue($event)"></ctg-month-mode>
         <div  *ngIf="eyeForm.get('1296').invalid ">
          <small class="text-danger" *ngIf="eyeForm.get('1296').errors?.invalidDateFormat">
            Only Date Format is allowed(MM/YYYY)                  </small>
          <small class="text-danger" *ngIf="eyeForm.get('1296').errors?.invalidMonth">
          Month is out of range
          </small>
          <small class="text-danger" *ngIf="eyeForm.get('1296').errors?.invalidDateyear">
            year is out of range(1900-2099)                    </small>
          </div>
         
      </td>
      <td colspan="4">
        Month/year of completion:
        <div class="input-group-append">
          <input class="form-control onboard datepicker" formControlName="1297" (blur)="clearInputIfInvalid('1297')"
          ngbDatepicker #d41="ngbDatepicker" id="1297" name="1297" type="text" placeholder= "Additional School Complete Date MM/DD/YYYY" data-id="1297">
              <button class="btn btn-outline-secondary calendar" (click)="d41.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
          </div>  
          <div  *ngIf="eyeForm.get('1297').invalid ">
            <small class="text-danger" *ngIf="eyeForm.get('1297').errors?.invalidDateFormat">
              Only Date Format is allowed(MM/DD/YYYY)                  </small>
            <small class="text-danger" *ngIf="eyeForm.get('1297').errors?.invalidMonth">
            Month is out of range
            </small>
            <small class="text-danger" *ngIf="eyeForm.get('1297').errors?.invalidDateyear">
              year is out of range(1900-2099)                    </small>
              <small class="text-danger" *ngIf="eyeForm.get('1297').errors?.invalidDateRange">
                date is out of range
                </small>
            </div> 
      </td>
    </tr>
    <tr>
      <td colspan="12">
          <div class="row">
                  <div class="col-md-4">
                 <label class="formLabel" style="font-size: 18px;">Did you complete the program?
                 </label>
               </div>
        
          <div class="col-md-4">
              <label class="radio-inline radioStyle">
                  <input
                      type="radio"
                      id="cmd_0068"
                      name="cmd_0068"
                  formControlName="cmd_0068"
                  value="1"
                  />Yes</label>
                  <label class="radio-inline radioStyle">
                  <input
                       type="radio"
                       id="cmd_0068"
                      name="cmd_0068"
                  formControlName="cmd_0068"
                  value="0"
                  />No</label>
                  <span class="normalParagraphStyle">
                    <i> (if you did not complete the program, please explain on a separate sheet.)</i>
                  </span>
        
          </div>
         </div>
      </td>
  </tr>
  <tr>
    <td colspan="12">
      <div class="centered texasFirstTable">
        <div class="row">
        <div class="col-md-8">
            <h2  style="margin-left: 31px;  font-size: 18px;"><b>XI. Post-Graduate Year 1 / Internship</b>
              <span class="normalParagraphStyle">
                <i>  (Please attach additional sheets, if necessary.)</i>
              </span>
            </h2>
        </div>
        <div class="col-md-4">
            <label for="cmd_0069"><b>Does Not Apply:</b></label>&nbsp;&nbsp;
            <input type="checkbox" formControlName="cmd_0069"
                id="cmd_0069" value="1" (ngModelChange)="handleNAchange('cmd_0069')"   
                >
    </div>
      </div>
    </div>
    </td>
</tr>
<tr>
  <td colspan="12">
    <h6>Complete medical/professional school name and street address:</h6>
    <div class="row">
      <div class="col-md-4">
        <input class="form-control onboard" formControlName="517" (blur)="handleNAchange('cmd_0069',517)" 
        [class.is-invalid]="(eyeForm.controls['517'].invalid)"
        id="517" name="517" type="text" placeholder= "Intern School Name" data-id="managing_contact_name">         
        <div class="formLabel col-md-11"
        *ngIf="eyeForm.controls['517'].touched && eyeForm.controls['517'].invalid">
        <small class="text-danger" *ngIf="eyeForm.controls['517'].errors?.required">
            required
        </small>
    </div>
      </div>
      <div class="col-md-4">
        <input class="form-control onboard" formControlName="518" (blur)="handleNAchange('cmd_0069',518)"  
        [class.is-invalid]="(eyeForm.controls['518'].invalid)"
        id="518" name="518" type="text" placeholder= "Intern School Address1" data-id="managing_contact_name">         
        <div class="formLabel col-md-11"
        *ngIf="eyeForm.controls['518'].touched && eyeForm.controls['518'].invalid">
        <small class="text-danger" *ngIf="eyeForm.controls['518'].errors?.required">
            required
        </small>
    </div>
      </div>
      <div class="col-md-4">
        <input class="form-control onboard" formControlName="519"  
        id="519" name="519" type="text" placeholder= " Intern School Address2" data-id="managing_contact_name">         
      </div>
    </div>
  </td>
</tr>
<tr class="border">
  <td colspan="2">
    City:
    <input class="form-control onboard" formControlName="520" (blur)="handleNAchange('cmd_0069',520)" 
        [class.is-invalid]="(eyeForm.controls['520'].invalid)"
        id="520" name="520" type="text" placeholder= "Intern School City" data-id="managing_contact_name">         
        <div class="formLabel col-md-11"
        *ngIf="eyeForm.controls['520'].touched && eyeForm.controls['520'].invalid">
        <small class="text-danger" *ngIf="eyeForm.controls['520'].errors?.required">
            required
        </small>
    </div>
  </td>
  <td colspan="3">
    State:
    <ng-select
    [items]="states"
    [class.is-invalid]="
      (eyeForm.controls['522'].invalid)
    "
    bindLabel="name"
    placeholder="State or Country"
    bindValue="id"
    formControlName="522"
    (blur)="handleNAchange('cmd_0069',522)"
    style="width:180px"
  >
  </ng-select>       
    <div
    class="formLabel col-md-11"
    *ngIf="
      eyeForm.controls['522'].touched &&
      eyeForm.controls['522'].invalid
    "
  >
    <small
      class="text-danger"
      *ngIf="eyeForm.controls['522'].errors"
    >
      required
    </small>
  </div>     
   
  </td>
  <td colspan="3">
    ZIP:         
    <input class="form-control onboard" formControlName="523"  (blur)="handleNAchange('cmd_0069',523)"  
    [class.is-invalid]="(eyeForm.controls['523'].invalid)"
    id="523" name="523" type="text" placeholder= "Intern School Zip" data-id="managing_contact_name">         
    <div
    class="formLabel col-md-2"
    *ngIf="
      eyeForm.controls['523'].invalid
    "
  >
    <small
      class="text-danger"
      *ngIf="eyeForm.controls['523'].errors"
    >
      required
    </small>
    <small
      class="text-danger"
      *ngIf="
        eyeForm.controls['523'].errors?.invalidZipCode
      "
    >
      Zip code must be atleast 5 digits and less than 10 digits
    </small>
  </div>
  </td>
  <td colspan="4">
    Contact email:
    <input class="form-control onboard" formControlName="4579" (blur)="handleNAchange('cmd_0069',4579)"
    [class.is-invalid]="(eyeForm.controls['4579'].invalid)"
    id="4579" name="4579" type="text" placeholder= "Intern School Email" data-id="managing_contact_name">         
    <div class="formLabel col-md-11"
    *ngIf="eyeForm.controls['4579'].touched && eyeForm.controls['4579'].invalid">
    <small class="text-danger" *ngIf="eyeForm.controls['4579'].errors?.required">
        required
    </small>
</div>
<small
class="text-danger"
*ngIf="eyeForm.controls['4579'].invalid"
>Please enter a valid email.</small
>
  </td>
</tr>
<tr class="border">
  <td colspan="4">
    Type of internship/specialty:
    <ng-select [items]="specialty" bindLabel="name" placeholder="Intern School Specialty " bindValue="id"  (blur)="handleNAchange('cmd_0069',527)"
    [class.is-invalid]="(eyeForm.controls['527'].invalid)"
     formControlName="527"
     style="width:400px">
    </ng-select>
    <div class="formLabel col-md-11"
        *ngIf="eyeForm.controls['527'].touched && eyeForm.controls['527'].invalid">
        <small class="text-danger" *ngIf="eyeForm.controls['527'].errors?.required">
            required
        </small>
    </div>
  </td>
  <td colspan="4">
    Phone number:
    <input class="form-control onboard" formControlName="1318" (keydown)="handleNumber($event,1318)" maxlength="12" (blur)="handleNAchange('cmd_0069',1318)"
    [class.is-invalid]="(eyeForm.controls['1318'].invalid)"
    id="1318" name="1318" type="text" placeholder= "Intern Director Phone" data-id="managing_contact_name">         
    <div class="formLabel col-md-11"
    *ngIf="eyeForm.controls['1318'].touched && eyeForm.controls['1318'].invalid">
    <small class="text-danger" *ngIf="eyeForm.controls['1318'].errors?.required">
        required
    </small>
    <small class="text-danger" *ngIf="eyeForm.controls['1318'].errors?.incorrectPhoneFormat">
      Required pattern xxx-xxx-xxxx
     </small>
</div>
  </td>
  <td colspan="4">
    Fax number, if available
    <input class="form-control onboard" formControlName="1319" (keydown)="handleNumber($event,1319)" maxlength="12" (blur)="handleNAchange('cmd_0069',1319)"
    [class.is-invalid]="(eyeForm.controls['1319'].invalid)"
    id="1319" name="1319" type="text" placeholder= "Intern Director Fax " data-id="managing_contact_name">         
    <div class="formLabel col-md-11"
    *ngIf="eyeForm.controls['1319'].touched && eyeForm.controls['1319'].invalid">
    <small class="text-danger" *ngIf="eyeForm.controls['1319'].errors?.required">
        required
    </small>
    <small class="text-danger" *ngIf="eyeForm.controls['1319'].errors?.incorrectPhoneFormat">
      Required pattern xxx-xxx-xxxx
     </small>
</div>
  </td>
</tr>
<tr class="border">
  <td colspan="4">
    From month/year:
    <div class="input-group-append">
      <input class="form-control onboard datepicker" formControlName="524" (blur)="handleNAchange('cmd_0069',524)"  (blur)="clearInputIfInvalid('524', 'cmd_0069')"
      [class.is-invalid]="(eyeForm.controls['524'].invalid)"
      ngbDatepicker #d42="ngbDatepicker" id="524" name="524" type="text" placeholder= "Intern Start Date" data-id="524">
          <button class="btn btn-outline-secondary calendar" (click)="d42.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
      </div>
      <div class="formLabel col-md-11"
          *ngIf="eyeForm.controls['524'].touched && eyeForm.controls['524'].invalid">
          <small class="text-danger" *ngIf="eyeForm.controls['524'].errors?.required">
              required
          </small>
      </div>
      <div  *ngIf="eyeForm.get('524').invalid ">
        <small class="text-danger" *ngIf="eyeForm.get('524').errors?.invalidDateFormat">
          Only Date Format is allowed(MM/DD/YYYY)                  </small>
        <small class="text-danger" *ngIf="eyeForm.get('524').errors?.invalidMonth">
        Month is out of range
        </small>
        <small class="text-danger" *ngIf="eyeForm.get('524').errors?.invalidDateyear">
          year is out of range(1900-2099)                    </small>
          <small class="text-danger" *ngIf="eyeForm.get('524').errors?.invalidDateRange">
            date is out of range
            </small>
        </div>
  </td>
  <td colspan="4">
    To month/year:
    <div class="input-group-append">
      <input class="form-control onboard datepicker" formControlName="525" (blur)="handleNAchange('cmd_0069',525)" (blur)="clearInputIfInvalid('525', 'cmd_0069')"
      [class.is-invalid]="(eyeForm.controls['525'].invalid)"
      ngbDatepicker #d43="ngbDatepicker" id="525" name="525" type="text" placeholder= "Intern End Date" data-id="525">
          <button class="btn btn-outline-secondary calendar" (click)="d43.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
      </div>
      <div class="formLabel col-md-11"
          *ngIf="eyeForm.controls['525'].touched && eyeForm.controls['525'].invalid">
          <small class="text-danger" *ngIf="eyeForm.controls['525'].errors?.required">
              required
          </small>
      </div>
      <div  *ngIf="eyeForm.get('525').invalid ">
        <small class="text-danger" *ngIf="eyeForm.get('525').errors?.invalidDateFormat">
          Only Date Format is allowed(MM/DD/YYYY)                  </small>
        <small class="text-danger" *ngIf="eyeForm.get('525').errors?.invalidMonth">
        Month is out of range
        </small>
        <small class="text-danger" *ngIf="eyeForm.get('525').errors?.invalidDateyear">
          year is out of range(1900-2099)                    </small>
          <small class="text-danger" *ngIf="eyeForm.get('525').errors?.invalidDateRange">
            date is out of range
            </small>
        </div>
  </td>
  <td colspan="4">
    Month/year of completion:
    <div class="input-group-append">
      <input class="form-control onboard datepicker" formControlName="526" (blur)="handleNAchange('cmd_0069',526)" (blur)="clearInputIfInvalid('526', 'cmd_0069')"
      [class.is-invalid]="(eyeForm.controls['526'].invalid)"
      ngbDatepicker #d380="ngbDatepicker" id="526" name="526" type="text" placeholder= "Intern Complete Date " data-id="526">
          <button class="btn btn-outline-secondary calendar" (click)="d380.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
      </div>
      <div class="formLabel col-md-11"
          *ngIf="eyeForm.controls['526'].touched && eyeForm.controls['526'].invalid">
          <small class="text-danger" *ngIf="eyeForm.controls['526'].errors?.required">
              required
          </small>
      </div>
      <div  *ngIf="eyeForm.get('526').invalid ">
        <small class="text-danger" *ngIf="eyeForm.get('526').errors?.invalidDateFormat">
          Only Date Format is allowed(MM/DD/YYYY)                  </small>
        <small class="text-danger" *ngIf="eyeForm.get('526').errors?.invalidMonth">
        Month is out of range
        </small>
        <small class="text-danger" *ngIf="eyeForm.get('526').errors?.invalidDateyear">
          year is out of range(1900-2099)                    </small>
          <small class="text-danger" *ngIf="eyeForm.get('526').errors?.invalidDateRange">
            date is out of range
            </small>
        </div>
  </td>
</tr>
<tr>
  <td colspan="12">
      <div class="row">
      <div class="col-md-4">
          <label class="formLabel" style="font-size: 16px;">Did you complete the program?</label>
      </div>
    
      <div class="col-md-4">
          <label class="radio-inline radioStyle">
              <input
                  type="radio"
                  id="cmd_0070"
                  name="cmd_0070"
                  [class.is-invalid]="(eyeForm.controls['cmd_0070'].touched 
                  && eyeForm.controls['cmd_0070'].invalid) || 
                  (eyeForm.controls['cmd_0070'].invalid && isSubmit)"
                  formControlName="cmd_0070"
                  value="1"
              />Yes</label>
              <label class="radio-inline radioStyle">
              <input
                   type="radio"
                   id="cmd_0070"
                  name="cmd_0070"
                  [class.is-invalid]="(eyeForm.controls['cmd_0070'].touched 
                  && eyeForm.controls['cmd_0070'].invalid) || 
                  (eyeForm.controls['cmd_0070'].invalid && isSubmit)"
                  formControlName="cmd_0070"
                  value="0"
              />No</label>
              <div class="formLabel"
              *ngIf="eyeForm.controls['cmd_0070'].touched && eyeForm.controls['cmd_0070'].invalid">
              <small class="text-danger" *ngIf="eyeForm.controls['cmd_0070'].errors?.required">
                  required
              </small>
             </div>
             <span *ngIf="(eyeForm.controls['cmd_0070'].touched && eyeForm.controls['cmd_0070'].errors)
             || (eyeForm.controls['cmd_0070'].errors && isSubmit)">
           <i class="fas fa-times ml-2 text-danger"></i>
              </span>
              <span class="normalParagraphStyle">
                <i> (if you did not complete the program, please explain on a separate sheet.)</i>
              </span>
      </div>     
  </div>
  </td>
</tr>
<tr>
  <td colspan="12">
    <div class="centered texasFirstTable">
      <div class="row">
      <div class="col-md-8">
          <h2  style="margin-left: 31px;  font-size: 18px;"><b>XII. Residencies</b>
            <span class="normalParagraphStyle">
              <i> (Please attach additional sheets, if necessary.)</i>
            </span>
          </h2>
      </div>
      <div class="col-md-4">
          <label for="cmd_0071"><b>Does Not Apply:</b></label>&nbsp;&nbsp;
          <input type="checkbox" formControlName="cmd_0071"
              id="cmd_0071" value="1"  (ngModelChange)="handleNAchange('cmd_0071')" 
              >
  </div>
    </div>
  </div>
  </td>
</tr> 
<tr>
  <td colspan="12">
    <h6>Complete institution name and street address:</h6>
    <div class="row">
      <div class="col-md-4">
        <input class="form-control onboard" formControlName="529" (blur)="handleNAchange('cmd_0071',529)" 
        [class.is-invalid]="(eyeForm.controls['529'].invalid)"
        id="529" name="529" type="text" placeholder= "Residency School" data-id="managing_contact_name">         
        <div class="formLabel col-md-11"
        *ngIf="eyeForm.controls['529'].touched && eyeForm.controls['529'].invalid">
        <small class="text-danger" *ngIf="eyeForm.controls['529'].errors?.required">
            required
        </small>
    </div>
      </div>
      <div class="col-md-4">
        <input class="form-control onboard" formControlName="531" (blur)="handleNAchange('cmd_0071',531)"
        [class.is-invalid]="(eyeForm.controls['531'].invalid)"
        id="531" name="531" type="text" placeholder= "Residency Address1" data-id="managing_contact_name">         
        <div class="formLabel col-md-11"
        *ngIf="eyeForm.controls['531'].touched && eyeForm.controls['531'].invalid">
        <small class="text-danger" *ngIf="eyeForm.controls['531'].errors?.required">
            required
        </small>
    </div>
      </div>
      <div class="col-md-4">
        <input class="form-control onboard" formControlName="1498"  
        id="1498" name="1498" type="text" placeholder= " Residency Address 2" data-id="managing_contact_name">         
      </div>
    </div>
  </td>
</tr>
<tr class="border">
  <td colspan="2">
    City:
    <input class="form-control onboard" formControlName="533" (blur)="handleNAchange('cmd_0071',533)" 
        [class.is-invalid]="(eyeForm.controls['533'].invalid)"
        id="533" name="533" type="text" placeholder= "Residency City" data-id="managing_contact_name">         
        <div class="formLabel col-md-11"
        *ngIf="eyeForm.controls['533'].touched && eyeForm.controls['533'].invalid">
        <small class="text-danger" *ngIf="eyeForm.controls['533'].errors?.required">
            required
        </small>
    </div>
  </td>
  <td colspan="3">
    State:
    <ng-select
    [items]="states"
    [class.is-invalid]="
      (eyeForm.controls['535'].invalid)
    "
    bindLabel="name"
    placeholder="Residency State"
    bindValue="id"
    formControlName="535"
    (blur)="handleNAchange('cmd_0071',535)"
    style="width:180px"
  >
  </ng-select>       
    <div
    class="formLabel col-md-11"
    *ngIf="
      eyeForm.controls['535'].touched &&
      eyeForm.controls['535'].invalid
    "
  >
    <small
      class="text-danger"
      *ngIf="eyeForm.controls['535'].errors"
    >
      required
    </small>
  </div>     
   
  </td>
  <td colspan="3">
    ZIP:         
    <input class="form-control onboard" formControlName="536" (blur)="handleNAchange('cmd_0071',536)"  
    [class.is-invalid]="(eyeForm.controls['536'].invalid)"
    id="536" name="536" type="text" placeholder= "Residency Zip" data-id="managing_contact_name">         
    <div
    class="formLabel col-md-2"
    *ngIf="
      eyeForm.controls['536'].invalid
    "
  >
    <small
      class="text-danger"
      *ngIf="eyeForm.controls['536'].errors"
    >
      required
    </small>
    <small
      class="text-danger"
      *ngIf="
        eyeForm.controls['536'].errors?.invalidZipCode
      "
    >
      Zip code must be atleast 5 digits and less than 10 digits
    </small>
  </div>
  </td>
  <td colspan="4">
    Contact email:
    <input class="form-control onboard" formControlName="4581" (blur)="handleNAchange('cmd_0071',4581)"
    [class.is-invalid]="(eyeForm.controls['4581'].invalid)"
    id="4581" name="4581" type="text" placeholder= "Residency Director Email" data-id="managing_contact_name">         
    <div class="formLabel col-md-11"
    *ngIf="eyeForm.controls['4581'].touched && eyeForm.controls['4581'].invalid">
    <small class="text-danger" *ngIf="eyeForm.controls['4581'].errors?.required">
        required
    </small>
</div>
<small
class="text-danger"
*ngIf="eyeForm.controls['4581'].invalid"
>Please enter a valid email.</small
>
  </td>
</tr>
<tr class="border">
  <td colspan="4">
    Specialty:
    <ng-select [items]="specialty" bindLabel="name" placeholder="Residency Specialty" bindValue="id" (blur)="handleNAchange('cmd_0071',530)"
    [class.is-invalid]="(eyeForm.controls['530'].invalid)"
     formControlName="530"
     style="width:400px">
    </ng-select>
    <div class="formLabel col-md-11"
        *ngIf="eyeForm.controls['530'].touched && eyeForm.controls['530'].invalid">
        <small class="text-danger" *ngIf="eyeForm.controls['530'].errors?.required">
            required
        </small>
    </div>
  </td>
  <td colspan="4">
    Phone number:
    <input class="form-control onboard" formControlName="1316" (keydown)="handleNumber($event,1316)" maxlength="12" (blur)="handleNAchange('cmd_0071',1316)" 
    [class.is-invalid]="(eyeForm.controls['1316'].invalid)"
    id="1316" name="1316" type="text" placeholder= "Residency Director Phone" data-id="managing_contact_name">         
    <div class="formLabel col-md-11"
    *ngIf="eyeForm.controls['1316'].touched && eyeForm.controls['1316'].invalid">
    <small class="text-danger" *ngIf="eyeForm.controls['1316'].errors?.required">
        required
    </small>
    <small class="text-danger" *ngIf="eyeForm.controls['1316'].errors?.incorrectPhoneFormat">
      Required pattern xxx-xxx-xxxx
     </small>
</div>
  </td>
  <td colspan="4">
    Fax number, if available
    <input class="form-control onboard" formControlName="1317" (keydown)="handleNumber($event,1317)" maxlength="12" (blur)="handleNAchange('cmd_0071',1317)"
    [class.is-invalid]="(eyeForm.controls['1317'].invalid)"
    id="1317" name="1317" type="text" placeholder= "Residency Director Fax" data-id="managing_contact_name">         
    <div class="formLabel col-md-11"
    *ngIf="eyeForm.controls['1317'].touched && eyeForm.controls['1317'].invalid">
    <small class="text-danger" *ngIf="eyeForm.controls['1317'].errors?.required">
        required
    </small>
    <small class="text-danger" *ngIf="eyeForm.controls['1317'].errors?.incorrectPhoneFormat">
      Required pattern xxx-xxx-xxxx
     </small>
</div>
  </td>
</tr>
<tr class="border">
  <td colspan="4">
    From month/year:
    <div class="input-group-append">
      <input class="form-control onboard datepicker" formControlName="537" (blur)="handleNAchange('cmd_0071',537)" (blur)="clearInputIfInvalid('537', 'cmd_0071')"
      [class.is-invalid]="(eyeForm.controls['537'].invalid)"
      ngbDatepicker #d45="ngbDatepicker" id="537" name="537" type="text" placeholder= "Residency Start" data-id="537">
          <button class="btn btn-outline-secondary calendar" (click)="d45.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
      </div>
      <div class="formLabel col-md-11"
          *ngIf="eyeForm.controls['537'].touched && eyeForm.controls['537'].invalid">
          <small class="text-danger" *ngIf="eyeForm.controls['537'].errors?.required">
              required
          </small>
      </div>
      <div  *ngIf="eyeForm.get('537').invalid ">
        <small class="text-danger" *ngIf="eyeForm.get('537').errors?.invalidDateFormat">
          Only Date Format is allowed(MM/DD/YYYY)                  </small>
        <small class="text-danger" *ngIf="eyeForm.get('537').errors?.invalidMonth">
        Month is out of range
        </small>
        <small class="text-danger" *ngIf="eyeForm.get('537').errors?.invalidDateyear">
          year is out of range(1900-2099)                    </small>
          <small class="text-danger" *ngIf="eyeForm.get('537').errors?.invalidDateRange">
            date is out of range
            </small>
        </div>
  </td>
  <td colspan="4">
    To month/year:
    <div class="input-group-append">
      <input class="form-control onboard datepicker" formControlName="538" (blur)="handleNAchange('cmd_0071',538)" (blur)="clearInputIfInvalid('538', 'cmd_0071')"
      [class.is-invalid]="(eyeForm.controls['538'].invalid)"
      ngbDatepicker #d46="ngbDatepicker" id="538" name="538" type="text" placeholder= "Residency End" data-id="538">
          <button class="btn btn-outline-secondary calendar" (click)="d46.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
      </div>
      <div class="formLabel col-md-11"
          *ngIf="eyeForm.controls['538'].touched && eyeForm.controls['538'].invalid">
          <small class="text-danger" *ngIf="eyeForm.controls['538'].errors?.required">
              required
          </small>
      </div>
      <div  *ngIf="eyeForm.get('538').invalid ">
        <small class="text-danger" *ngIf="eyeForm.get('538').errors?.invalidDateFormat">
          Only Date Format is allowed(MM/DD/YYYY)                  </small>
        <small class="text-danger" *ngIf="eyeForm.get('538').errors?.invalidMonth">
        Month is out of range
        </small>
        <small class="text-danger" *ngIf="eyeForm.get('538').errors?.invalidDateyear">
          year is out of range(1900-2099)                    </small>
          <small class="text-danger" *ngIf="eyeForm.get('538').errors?.invalidDateRange">
            date is out of range
            </small>
        </div>
  </td>
  <td colspan="4">
    Month/year of completion:
    <div class="input-group-append">
      <input class="form-control onboard datepicker" formControlName="539" (blur)="handleNAchange('cmd_0071',539)" (blur)="clearInputIfInvalid('539', 'cmd_0071')"
      [class.is-invalid]="(eyeForm.controls['539'].invalid)"
      ngbDatepicker #d47="ngbDatepicker" id="539" name="539" type="text" placeholder= "Residency Completed" data-id="539">
          <button class="btn btn-outline-secondary calendar" (click)="d47.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
      </div>
      <div class="formLabel col-md-11"
          *ngIf="eyeForm.controls['539'].touched && eyeForm.controls['539'].invalid">
          <small class="text-danger" *ngIf="eyeForm.controls['539'].errors?.required">
              required
          </small>
      </div>
      <div  *ngIf="eyeForm.get('539').invalid ">
        <small class="text-danger" *ngIf="eyeForm.get('539').errors?.invalidDateFormat">
          Only Date Format is allowed(MM/DD/YYYY)                  </small>
        <small class="text-danger" *ngIf="eyeForm.get('539').errors?.invalidMonth">
        Month is out of range
        </small>
        <small class="text-danger" *ngIf="eyeForm.get('539').errors?.invalidDateyear">
          year is out of range(1900-2099)                    </small>
          <small class="text-danger" *ngIf="eyeForm.get('539').errors?.invalidDateRange">
            date is out of range
            </small>
        </div>
  </td>
</tr>
<tr>
  <td colspan="12">
      <div class="row">
      <div class="col-md-4">
          <label class="formLabel" style="font-size: 16px;">Did you complete the program?</label>
      </div>
    
      <div class="col-md-4">
          <label class="radio-inline radioStyle">
              <input
                  type="radio"
                  id="cmd_0072"
                  name="cmd_0072"
                  [class.is-invalid]="(eyeForm.controls['cmd_0072'].touched 
                  && eyeForm.controls['cmd_0072'].invalid) || 
                  (eyeForm.controls['cmd_0072'].invalid && isSubmit)"
                  formControlName="cmd_0072"
                  value="1"
              />Yes</label>
              <label class="radio-inline radioStyle">
              <input
                   type="radio"
                   id="cmd_0072"
                  name="cmd_0072"
                  [class.is-invalid]="(eyeForm.controls['cmd_0072'].touched 
                  && eyeForm.controls['cmd_0072'].invalid) || 
                  (eyeForm.controls['cmd_0072'].invalid && isSubmit)"
                  formControlName="cmd_0072"
                  value="0"
              />No</label>
              <div class="formLabel"
              *ngIf="eyeForm.controls['cmd_0072'].touched && eyeForm.controls['cmd_0072'].invalid">
              <small class="text-danger" *ngIf="eyeForm.controls['cmd_0072'].errors?.required">
                  required
              </small>
             </div>
             <span *ngIf="(eyeForm.controls['cmd_0072'].touched && eyeForm.controls['cmd_0072'].errors)
             || (eyeForm.controls['cmd_0072'].errors && isSubmit)">
           <i class="fas fa-times ml-2 text-danger"></i>
              </span>
              <span class="normalParagraphStyle">
                <i> (if you did not complete the program, please explain on a separate sheet.)</i>
              </span>
      </div>     
  </div>
  </td>
</tr>
<tr>
  <td colspan="12">
    <h6>Complete institution name and street address:</h6>
    <div class="row">
      <div class="col-md-4">
        <input class="form-control onboard" formControlName="1170" 
        id="1170" name="1170" type="text" placeholder= "Add Residency School" data-id="managing_contact_name">             
      </div>
      <div class="col-md-4">
        <input class="form-control onboard" formControlName="1172" 
        id="1172" name="1172" type="text" placeholder= " Add Residency Address1" data-id="managing_contact_name">              
      </div>
      <div class="col-md-4">
        <input class="form-control onboard" formControlName="1173"  
        id="1173" name="1173" type="text" placeholder= " Add Residency Address 2" data-id="managing_contact_name">         
      </div>
    </div>
  </td>
</tr>
<tr class="border">
  <td colspan="2">
    City:
    <input class="form-control onboard" formControlName="1174" 
        id="1174" name="1174" type="text" placeholder= "Add Residency City" data-id="managing_contact_name">         
  </td>
  <td colspan="3">
    State:
    <ng-select
    [items]="states"
    bindLabel="name"
    placeholder="Add Residency State"
    bindValue="id"
    formControlName="1176"
    style="width:180px"
  >
  </ng-select>           
   
  </td>
  <td colspan="3">
    ZIP:         
    <input class="form-control onboard" formControlName="1177" 
    id="1177" name="1177" type="text" placeholder= "Add Residency Zip" data-id="managing_contact_name">         
    <small
    class="text-danger"
    *ngIf="eyeForm.controls['1177'].errors?.pattern"
    > Zip code must be atleast 5 digits and less than 10 digits.</small
  >
  </td>
  <td colspan="4">
    Contact email:
    <input class="form-control onboard" formControlName="4582" 
    id="4582" name="4582" type="text" placeholder= "Add Residency Director Email" data-id="managing_contact_name">  
    <small
    class="text-danger"
    *ngIf="eyeForm.controls['4582'].errors?.pattern"
    >Please enter a valid email.</small
    >      
  </td>
</tr>
<tr class="border">
  <td colspan="4">
    Specialty:
    <ng-select [items]="specialty" bindLabel="name" placeholder="Add Residency Specialty" bindValue="id" 
     formControlName="1171"
     style="width:400px">
    </ng-select>
  </td>
  <td colspan="4">
    Phone number:
    <input class="form-control onboard" formControlName="1320" (keydown)="handleNumber($event,1320)" maxlength="12"
    id="1320" name="1320" type="text" placeholder= "Add Residency Director Phone" data-id="managing_contact_name">
    <small class="text-danger" *ngIf="eyeForm.get('1320').errors?.pattern">Required pattern xxx-xxx-xxxx</small>
         
  </td>
  <td colspan="4">
    Fax number, if available
    <input class="form-control onboard" formControlName="1321" (keydown)="handleNumber($event,1321)" maxlength="12"
    id="1321" name="1321" type="text" placeholder= "Add Residency Director Fax" data-id="managing_contact_name">   
    <small class="text-danger" *ngIf="eyeForm.get('1321').errors?.pattern">Required pattern xxx-xxx-xxxx</small>
 
  </td>
</tr>
<tr class="border">
  <td colspan="4">
    From month/year:
    <div class="input-group-append">
      <input class="form-control onboard datepicker" formControlName="1178"  (blur)="clearInputIfInvalid('1178')"
      ngbDatepicker #d48="ngbDatepicker" id="1178" name="1178" type="text" placeholder= "Add Residency Start" data-id="1178">
          <button class="btn btn-outline-secondary calendar" (click)="d48.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
      </div>
      <div  *ngIf="eyeForm.get('1178').invalid ">
        <small class="text-danger" *ngIf="eyeForm.get('1178').errors?.invalidDateFormat">
          Only Date Format is allowed(MM/DD/YYYY)                  </small>
        <small class="text-danger" *ngIf="eyeForm.get('1178').errors?.invalidMonth">
        Month is out of range
        </small>
        <small class="text-danger" *ngIf="eyeForm.get('1178').errors?.invalidDateyear">
          year is out of range(1900-2099)                    </small>
          <small class="text-danger" *ngIf="eyeForm.get('1178').errors?.invalidDateRange">
            date is out of range
            </small>
        </div>
  </td>
  <td colspan="4">
    To month/year:
    <div class="input-group-append">
      <input class="form-control onboard datepicker" formControlName="1179" (blur)="clearInputIfInvalid('1179')"
      ngbDatepicker #d49="ngbDatepicker" id="1179" name="1179" type="text" placeholder= "Add Residency End" data-id="1179">
          <button class="btn btn-outline-secondary calendar" (click)="d49.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
      </div>
      <div  *ngIf="eyeForm.get('1179').invalid ">
        <small class="text-danger" *ngIf="eyeForm.get('1179').errors?.invalidDateFormat">
          Only Date Format is allowed(MM/DD/YYYY)                  </small>
        <small class="text-danger" *ngIf="eyeForm.get('1179').errors?.invalidMonth">
        Month is out of range
        </small>
        <small class="text-danger" *ngIf="eyeForm.get('1179').errors?.invalidDateyear">
          year is out of range(1900-2099)                    </small>
          <small class="text-danger" *ngIf="eyeForm.get('1179').errors?.invalidDateRange">
            date is out of range
            </small>
        </div>
     
  </td>
  <td colspan="4">
    Month/year of completion:
    <div class="input-group-append">
      <input class="form-control onboard datepicker" formControlName="1180" (blur)="clearInputIfInvalid('1180')"
      ngbDatepicker #d50="ngbDatepicker" id="1180" name="1180" type="text" placeholder= "Add Residency Completed" data-id="1180">
          <button class="btn btn-outline-secondary calendar" (click)="d50.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
      </div>
      <div  *ngIf="eyeForm.get('1180').invalid ">
        <small class="text-danger" *ngIf="eyeForm.get('1180').errors?.invalidDateFormat">
          Only Date Format is allowed(MM/DD/YYYY)                  </small>
        <small class="text-danger" *ngIf="eyeForm.get('1180').errors?.invalidMonth">
        Month is out of range
        </small>
        <small class="text-danger" *ngIf="eyeForm.get('1180').errors?.invalidDateyear">
          year is out of range(1900-2099)                    </small>
          <small class="text-danger" *ngIf="eyeForm.get('1180').errors?.invalidDateRange">
            date is out of range
            </small>
        </div>
  </td>
</tr>
<tr>
  <td colspan="12">
      <div class="row">
              <div class="col-md-4">
             <label class="formLabel" style="font-size: 18px;">Did you complete the program?
             </label>
           </div>
    
      <div class="col-md-4">
          <label class="radio-inline radioStyle">
              <input
                  type="radio"
                  id="cmd_0073"
                  name="cmd_0073"
              formControlName="cmd_0073"
              value="1"
              />Yes</label>
              <label class="radio-inline radioStyle">
              <input
                   type="radio"
                   id="cmd_0073"
                  name="cmd_0073"
              formControlName="cmd_0073"
              value="0"
              />No</label>
              <span class="normalParagraphStyle">
                <i> (if you did not complete the program, please explain on a separate sheet.)</i>
              </span>
    
      </div>
     </div>
  </td>
</tr>
<tr>
  <td colspan="12">
    <div class="centered texasFirstTable">
      <div class="row">
      <div class="col-md-8">
          <h2  style="margin-left: 31px;  font-size: 18px;"><b>XIII. Fellowships, Preceptorships, or Other Clinical Training Programs</b>
            <span class="normalParagraphStyle">
              <i> (Please attach additional sheets, if necessary.)</i>
            </span>
          </h2>
      </div>
      <div class="col-md-4">
          <label for="cmd_0074"><b>Does Not Apply:</b></label>&nbsp;&nbsp;
          <input type="checkbox" formControlName="cmd_0074"
              id="cmd_0074" value="1"  (ngModelChange)="handleNAchange('cmd_0074')"  
              >
  </div>
    </div>
  </div>
  </td>
</tr> 
<tr>
  <td colspan="12">
    <h6>Complete institution name and street address:</h6>
    <div class="row">
      <div class="col-md-4">
        <input class="form-control onboard" formControlName="542" (blur)="handleNAchange('cmd_0074',542)"
        [class.is-invalid]="(eyeForm.controls['542'].invalid)"
        id="542" name="542" type="text" placeholder= "Fellowship School" data-id="managing_contact_name">         
        <div class="formLabel col-md-11"
        *ngIf="eyeForm.controls['542'].touched && eyeForm.controls['542'].invalid">
        <small class="text-danger" *ngIf="eyeForm.controls['542'].errors?.required">
            required
        </small>
    </div>
      </div>
      <div class="col-md-4">
        <input class="form-control onboard" formControlName="544" (blur)="handleNAchange('cmd_0074',544)"
        [class.is-invalid]="(eyeForm.controls['544'].invalid)"
        id="544" name="544" type="text" placeholder= " Fellowship Address1" data-id="managing_contact_name">         
        <div class="formLabel col-md-11"
        *ngIf="eyeForm.controls['544'].touched && eyeForm.controls['544'].invalid">
        <small class="text-danger" *ngIf="eyeForm.controls['544'].errors?.required">
            required
        </small>
    </div>
      </div>
      <div class="col-md-4">
        <input class="form-control onboard" formControlName="545"  
        id="545" name="545" type="text" placeholder= " Fellowship Address 2" data-id="managing_contact_name">         
      </div>
    </div>
  </td>
</tr>
<tr class="border">
  <td colspan="2">
    City:
    <input class="form-control onboard" formControlName="546" (blur)="handleNAchange('cmd_0074',546)" 
        [class.is-invalid]="(eyeForm.controls['546'].invalid)"
        id="546" name="546" type="text" placeholder= "Fellowship City" data-id="managing_contact_name">         
        <div class="formLabel col-md-11"
        *ngIf="eyeForm.controls['546'].touched && eyeForm.controls['546'].invalid">
        <small class="text-danger" *ngIf="eyeForm.controls['546'].errors?.required">
            required
        </small>
    </div>
  </td>
  <td colspan="3">
    State:
    <ng-select
    [items]="states"
    [class.is-invalid]="
      (eyeForm.controls['548'].invalid)
    "
    bindLabel="name"
    placeholder="Fellowship State"
    bindValue="id"
    formControlName="548"
    (blur)="handleNAchange('cmd_0074',548)"
    style="width:180px"
  >
  </ng-select>       
    <div
    class="formLabel col-md-11"
    *ngIf="
      eyeForm.controls['548'].touched &&
      eyeForm.controls['548'].invalid
    "
  >
    <small
      class="text-danger"
      *ngIf="eyeForm.controls['548'].errors"
    >
      required
    </small>
  </div>     
   
  </td>
  <td colspan="3">
    ZIP:         
    <input class="form-control onboard" formControlName="549"  (blur)="handleNAchange('cmd_0074',549)" 
    [class.is-invalid]="(eyeForm.controls['549'].invalid)"
    id="549" name="549" type="text" placeholder= "Fellowship Zip" data-id="managing_contact_name">         
    <div
    class="formLabel col-md-2"
    *ngIf="
      eyeForm.controls['549'].invalid
    "
  >
    <small
      class="text-danger"
      *ngIf="eyeForm.controls['549'].errors"
    >
      required
    </small>
    <small
      class="text-danger"
      *ngIf="
        eyeForm.controls['549'].errors?.invalidZipCode
      "
    >
      Zip code must be atleast 5 digits and less than 10 digits
    </small>
  </div>
  </td>
  <td colspan="4">
    Contact email:
    <input class="form-control onboard" formControlName="4583"  (blur)="handleNAchange('cmd_0074',4583)"
    [class.is-invalid]="(eyeForm.controls['4583'].invalid)"
    id="4583" name="4583" type="text" placeholder= "Fellowship Director Email" data-id="managing_contact_name">         
    <div class="formLabel col-md-11"
    *ngIf="eyeForm.controls['4583'].touched && eyeForm.controls['4583'].invalid">
    <small class="text-danger" *ngIf="eyeForm.controls['4583'].errors?.required">
        required
    </small>
</div>
<small
class="text-danger"
*ngIf="eyeForm.controls['4583'].invalid"
>Please enter a valid email.</small
>
  </td>
</tr>
<tr class="border">
  <td colspan="4">
    Specialty:
    <ng-select [items]="specialty" bindLabel="name" placeholder="Fellowship Specialty" bindValue="id" (blur)="handleNAchange('cmd_0074',543)" 
    [class.is-invalid]="(eyeForm.controls['543'].invalid)"
     formControlName="543"
     style="width:400px">
    </ng-select>
    <div class="formLabel col-md-11"
        *ngIf="eyeForm.controls['543'].touched && eyeForm.controls['543'].invalid">
        <small class="text-danger" *ngIf="eyeForm.controls['543'].errors?.required">
            required
        </small>
    </div>
  </td>
  <td colspan="4">
    Phone number:
    <input class="form-control onboard" formControlName="1322" (keydown)="handleNumber($event,1322)" maxlength="12"  (blur)="handleNAchange('cmd_0074',1322)"
    [class.is-invalid]="(eyeForm.controls['1322'].invalid)"
    id="1322" name="1322" type="text" placeholder= "Fellowship Director Phone" data-id="managing_contact_name">         
    <div class="formLabel col-md-11"
    *ngIf="eyeForm.controls['1322'].touched && eyeForm.controls['1322'].invalid">
    <small class="text-danger" *ngIf="eyeForm.controls['1322'].errors?.required">
        required
    </small>
    <small class="text-danger" *ngIf="eyeForm.controls['1322'].errors?.incorrectPhoneFormat">
      Required pattern xxx-xxx-xxxx
     </small>
</div>
  </td>
  <td colspan="4">
    Fax number, if available
    <input class="form-control onboard" formControlName="1323" (keydown)="handleNumber($event,1323)"  maxlength="12" (blur)="handleNAchange('cmd_0074',1323)"
    [class.is-invalid]="(eyeForm.controls['1323'].invalid)"
    id="1323" name="1323" type="text" placeholder= "Fellowship Director Fax" data-id="managing_contact_name">         
    <div class="formLabel col-md-11"
    *ngIf="eyeForm.controls['1323'].touched && eyeForm.controls['1323'].invalid">
    <small class="text-danger" *ngIf="eyeForm.controls['1323'].errors?.required">
        required
    </small>
    <small class="text-danger" *ngIf="eyeForm.controls['1323'].errors?.incorrectPhoneFormat">
      Required pattern xxx-xxx-xxxx
     </small>
</div>
  </td>
</tr>
<tr class="border">
  <td colspan="4">
    From month/year:
    <div class="input-group-append">
      <input class="form-control onboard datepicker" formControlName="550" (blur)="clearInputIfInvalid('550', 'cmd_0074')"  (blur)="handleNAchange('cmd_0074',550)"
      [class.is-invalid]="(eyeForm.controls['550'].invalid)"
      ngbDatepicker #d53="ngbDatepicker" id="550" name="550" type="text" placeholder= "Fellowship Start Date " data-id="550">
          <button class="btn btn-outline-secondary calendar" (click)="d53.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
      </div>
      <div class="formLabel col-md-11"
          *ngIf="eyeForm.controls['550'].touched && eyeForm.controls['550'].invalid">
          <small class="text-danger" *ngIf="eyeForm.controls['550'].errors?.required">
              required
          </small>
      </div>
      <div  *ngIf="eyeForm.get('550').invalid ">
        <small class="text-danger" *ngIf="eyeForm.get('550').errors?.invalidDateFormat">
          Only Date Format is allowed(MM/DD/YYYY)                  </small>
        <small class="text-danger" *ngIf="eyeForm.get('550').errors?.invalidMonth">
        Month is out of range
        </small>
        <small class="text-danger" *ngIf="eyeForm.get('550').errors?.invalidDateyear">
          year is out of range(1900-2099)                    </small>
          <small class="text-danger" *ngIf="eyeForm.get('550').errors?.invalidDateRange">
            date is out of range
            </small>
        </div>
  </td>
  <td colspan="4">
    To month/year:
    <div class="input-group-append">
      <input class="form-control onboard datepicker" formControlName="551"  (blur)="handleNAchange('cmd_0074',551)" (blur)="clearInputIfInvalid('551', 'cmd_0074')"
      [class.is-invalid]="(eyeForm.controls['551'].invalid)"
      ngbDatepicker #d52="ngbDatepicker" id="551" name="551" type="text" placeholder= "Fellowship End Date" data-id="551">
          <button class="btn btn-outline-secondary calendar" (click)="d52.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
      </div>
      <div class="formLabel col-md-11"
          *ngIf="eyeForm.controls['551'].touched && eyeForm.controls['551'].invalid">
          <small class="text-danger" *ngIf="eyeForm.controls['551'].errors?.required">
              required
          </small>
      </div>
      <div  *ngIf="eyeForm.get('551').invalid ">
        <small class="text-danger" *ngIf="eyeForm.get('551').errors?.invalidDateFormat">
          Only Date Format is allowed(MM/DD/YYYY)                  </small>
        <small class="text-danger" *ngIf="eyeForm.get('551').errors?.invalidMonth">
        Month is out of range
        </small>
        <small class="text-danger" *ngIf="eyeForm.get('551').errors?.invalidDateyear">
          year is out of range(1900-2099)                    </small>
          <small class="text-danger" *ngIf="eyeForm.get('551').errors?.invalidDateRange">
            date is out of range
            </small>
        </div>
  </td>
  <td colspan="4">
    Month/year of completion:
    <div class="input-group-append">
      <input class="form-control onboard datepicker" formControlName="552"  (blur)="handleNAchange('cmd_0074',552)" (blur)="clearInputIfInvalid('552', 'cmd_0074')"
      [class.is-invalid]="(eyeForm.controls['552'].invalid)"
      ngbDatepicker #d51="ngbDatepicker" id="552" name="552" type="text" placeholder= "Fellowship Complete Date" data-id="552">
          <button class="btn btn-outline-secondary calendar" (click)="d51.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
      </div>
      <div class="formLabel col-md-11"
          *ngIf="eyeForm.controls['552'].touched && eyeForm.controls['552'].invalid">
          <small class="text-danger" *ngIf="eyeForm.controls['552'].errors?.required">
              required
          </small>
      </div>
      <div  *ngIf="eyeForm.get('552').invalid ">
        <small class="text-danger" *ngIf="eyeForm.get('552').errors?.invalidDateFormat">
          Only Date Format is allowed(MM/DD/YYYY)                  </small>
        <small class="text-danger" *ngIf="eyeForm.get('552').errors?.invalidMonth">
        Month is out of range
        </small>
        <small class="text-danger" *ngIf="eyeForm.get('552').errors?.invalidDateyear">
          year is out of range(1900-2099)                    </small>
          <small class="text-danger" *ngIf="eyeForm.get('552').errors?.invalidDateRange">
            date is out of range
            </small>
        </div>
  </td>
</tr>
<tr>
  <td colspan="12">
      <div class="row">
      <div class="col-md-4">
          <label class="formLabel" style="font-size: 16px;">Did you complete the program?</label>
      </div>
    
      <div class="col-md-4">
          <label class="radio-inline radioStyle">
              <input
                  type="radio"
                  id="cmd_0075"
                  name="cmd_0075"
                  [class.is-invalid]="(eyeForm.controls['cmd_0075'].touched 
                  && eyeForm.controls['cmd_0075'].invalid) || 
                  (eyeForm.controls['cmd_0075'].invalid && isSubmit)"
                  formControlName="cmd_0075"
                  value="1"
              />Yes</label>
              <label class="radio-inline radioStyle">
              <input
                   type="radio"
                   id="cmd_0075"
                  name="cmd_0075"
                  [class.is-invalid]="(eyeForm.controls['cmd_0075'].touched 
                  && eyeForm.controls['cmd_0075'].invalid) || 
                  (eyeForm.controls['cmd_0075'].invalid && isSubmit)"
                  formControlName="cmd_0075"
                  value="0"
              />No</label>
              <div class="formLabel"
              *ngIf="eyeForm.controls['cmd_0075'].touched && eyeForm.controls['cmd_0075'].invalid">
              <small class="text-danger" *ngIf="eyeForm.controls['cmd_0075'].errors?.required">
                  required
              </small>
             </div>
             <span *ngIf="(eyeForm.controls['cmd_0075'].touched && eyeForm.controls['cmd_0075'].errors)
             || (eyeForm.controls['cmd_0075'].errors && isSubmit)">
           <i class="fas fa-times ml-2 text-danger"></i>
              </span>
              <span class="normalParagraphStyle">
                <i> (if you did not complete the program, please explain on a separate sheet.)</i>
              </span>
      </div>     
  </div>
  </td>
</tr>
<tr>
  <td colspan="12">
    <h6>Complete institution name and street address:</h6>
    <div class="row">
      <div class="col-md-4">
        <input class="form-control onboard" formControlName="966" 
        id="966" name="966" type="text" placeholder= "Add Fellowship School" data-id="managing_contact_name">             
      </div>
      <div class="col-md-4">
        <input class="form-control onboard" formControlName="968" 
        id="968" name="968" type="text" placeholder= "Add Fellowship Address1" data-id="managing_contact_name">              
      </div>
      <div class="col-md-4">
        <input class="form-control onboard" formControlName="969"  
        id="969" name="969" type="text" placeholder= "Add Fellowship Address 2" data-id="managing_contact_name">         
      </div>
    </div>
  </td>
</tr>
<tr class="border">
  <td colspan="2">
    City:
    <input class="form-control onboard" formControlName="970" 
        id="970" name="970" type="text" placeholder= "Add Fellowship City" data-id="managing_contact_name">         
  </td>
  <td colspan="3">
    State:
    <ng-select
    [items]="states"
    bindLabel="name"
    placeholder="Add Fellowship State"
    bindValue="id"
    formControlName="972"
    style="width:180px"
  >
  </ng-select>           
   
  </td>
  <td colspan="3">
    ZIP:         
    <input class="form-control onboard" formControlName="1301" 
    id="1301" name="1301" type="text" placeholder= "Add Fellowship Zip" data-id="managing_contact_name">         
    <small
    class="text-danger"
    *ngIf="eyeForm.controls['1301'].errors?.pattern"
    > Zip code must be atleast 5 digits and less than 10 digits.</small
  >
  </td>
  <td colspan="4">
    Contact email:
    <input class="form-control onboard" formControlName="4584" 
    id="4584" name="4584" type="text" placeholder= "Add Fellowship Director Email" data-id="managing_contact_name"> 
    <small
    class="text-danger"
    *ngIf="eyeForm.controls['4584'].errors?.pattern"
    >Please enter a valid email.</small
    >         
  </td>
</tr>
<tr class="border">
  <td colspan="4">
    Specialty:
    <ng-select [items]="specialty" bindLabel="name" placeholder="Add Fellowship Specialty" bindValue="id" 
     formControlName="967"
     style="width:400px">
    </ng-select>
  </td>
  <td colspan="4">
    Phone number:
    <input class="form-control onboard" formControlName="1324" (keydown)="handleNumber($event,1324)" maxlength="12" maxlength="12"
    id="1324" name="1324" type="text" placeholder= "Add Fellowship Phone" data-id="managing_contact_name"> 
    <small class="text-danger" *ngIf="eyeForm.get('1324').errors?.pattern">Required pattern xxx-xxx-xxxx</small>
        
  </td>
  <td colspan="4">
    Fax number, if available
    <input class="form-control onboard" formControlName="1325" (keydown)="handleNumber($event,1325)" maxlength="12"
    id="1325" name="1325" type="text" placeholder= "Add Fellowship Fax" data-id="managing_contact_name">
    <small class="text-danger" *ngIf="eyeForm.get('1325').errors?.pattern">Required pattern xxx-xxx-xxxx</small>
         
  </td>
</tr>
<tr class="border">
  <td colspan="4">
    From month/year:
    <div class="input-group-append">
      <input class="form-control onboard datepicker" formControlName="974" (blur)="clearInputIfInvalid('974')" 
      ngbDatepicker #d55="ngbDatepicker" id="974" name="974" type="text" placeholder= "Add Fellowship Start Date" data-id="974">
          <button class="btn btn-outline-secondary calendar" (click)="d55.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
      </div>
      <div  *ngIf="eyeForm.get('974').invalid ">
        <small class="text-danger" *ngIf="eyeForm.get('974').errors?.invalidDateFormat">
          Only Date Format is allowed(MM/DD/YYYY)                  </small>
        <small class="text-danger" *ngIf="eyeForm.get('974').errors?.invalidMonth">
        Month is out of range
        </small>
        <small class="text-danger" *ngIf="eyeForm.get('974').errors?.invalidDateyear">
          year is out of range(1900-2099)                    </small>
          <small class="text-danger" *ngIf="eyeForm.get('974').errors?.invalidDateRange">
            date is out of range
            </small>
        </div>
  </td>
  <td colspan="4">
    To month/year:
    <div class="input-group-append">
      <input class="form-control onboard datepicker" formControlName="975" (blur)="clearInputIfInvalid('975')" 
      ngbDatepicker #d56="ngbDatepicker" id="975" name="975" type="text" placeholder= "Add Fellowship End Date" data-id="975">
          <button class="btn btn-outline-secondary calendar" (click)="d56.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
      </div>
      <div  *ngIf="eyeForm.get('975').invalid ">
        <small class="text-danger" *ngIf="eyeForm.get('975').errors?.invalidDateFormat">
          Only Date Format is allowed(MM/DD/YYYY)                  </small>
        <small class="text-danger" *ngIf="eyeForm.get('975').errors?.invalidMonth">
        Month is out of range
        </small>
        <small class="text-danger" *ngIf="eyeForm.get('975').errors?.invalidDateyear">
          year is out of range(1900-2099)                    </small>
          <small class="text-danger" *ngIf="eyeForm.get('975').errors?.invalidDateRange">
            date is out of range
            </small>
        </div>
     
  </td>
  <td colspan="4">
    Month/year of completion:
    <div class="input-group-append">
      <input class="form-control onboard datepicker" formControlName="982" (blur)="clearInputIfInvalid('982')"
      ngbDatepicker #d57="ngbDatepicker" id="982" name="982" type="text" placeholder= "Add Fellowship Complete Date" data-id="982">
          <button class="btn btn-outline-secondary calendar" (click)="d57.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
      </div>
      <div  *ngIf="eyeForm.get('982').invalid ">
        <small class="text-danger" *ngIf="eyeForm.get('982').errors?.invalidDateFormat">
          Only Date Format is allowed(MM/DD/YYYY)                  </small>
        <small class="text-danger" *ngIf="eyeForm.get('982').errors?.invalidMonth">
        Month is out of range
        </small>
        <small class="text-danger" *ngIf="eyeForm.get('982').errors?.invalidDateyear">
          year is out of range(1900-2099)                    </small>
          <small class="text-danger" *ngIf="eyeForm.get('982').errors?.invalidDateRange">
            date is out of range
            </small>
        </div>
  </td>
</tr>
<tr>
  <td colspan="12">
      <div class="row">
              <div class="col-md-4">
             <label class="formLabel" style="font-size: 18px;">Did you complete the program?
             </label>
           </div>
    
      <div class="col-md-4">
          <label class="radio-inline radioStyle">
              <input
                  type="radio"
                  id="cmd_0076"
                  name="cmd_0076"
              formControlName="cmd_0076"
              value="1"
              />Yes</label>
              <label class="radio-inline radioStyle">
              <input
                   type="radio"
                   id="cmd_0076"
                  name="cmd_0076"
              formControlName="cmd_0076"
              value="0"
              />No</label>
              <span class="normalParagraphStyle">
                <i> (if you did not complete the program, please explain on a separate sheet.)</i>
              </span>
    
      </div>
     </div>
  </td>
</tr>
<tr>
  <td colspan="12">
    <div class="centered texasFirstTable">
      <div class="row">
      <div class="col-md-8">
          <h2  style="margin-left: 31px;  font-size: 18px;"><b>XIV. Health Care Licensure, Registrations, Certificates & ID Numbers</b>
            
          </h2>
          <span class="normalParagraphStyle">
            <i> (Please attach additional sheets, if necessary.)</i>
          </span>
      </div>
      <div class="col-md-4">
          <label for="cmd_0077"><b>Does Not Apply:</b></label>&nbsp;&nbsp;
          <input type="checkbox" formControlName="cmd_0077"
              id="cmd_0077" value="1"  (ngModelChange)="handleNAchange('cmd_0077')"  
              >
  </div>
    </div>
  </div>
  </td>
</tr>
<tr class="border">
  <td colspan="4">
    Oregon license or registration number:
    <input class="form-control onboard" formControlName="293"  (blur)="handleNAchange('cmd_0077',293)"
    [class.is-invalid]="(eyeForm.controls['293'].invalid)"
    id="293" name="293" type="text" placeholder= "License Number" data-id="managing_contact_name">         
    <div class="formLabel col-md-11"ll
    *ngIf="eyeForm.controls['293'].touched && eyeForm.controls['293'].invalid">
    <small class="text-danger" *ngIf="eyeForm.controls['293'].errors?.required">
        required
    </small>
</div>
  </td>
  <td colspan="4">
    Type:
    <input class="form-control onboard" formControlName="296" (blur)="handleNAchange('cmd_0077',296)"
    [class.is-invalid]="(eyeForm.controls['296'].invalid)"
    id="296" name="296" type="text" placeholder= "License Type" data-id="managing_contact_name">         
    <div class="formLabel col-md-11"ll
    *ngIf="eyeForm.controls['296'].touched && eyeForm.controls['296'].invalid">
    <small class="text-danger" *ngIf="eyeForm.controls['296'].errors?.required">
        required
    </small>
</div>
  </td>
  <td colspan="4">
    Month/Day/Year of Expiration:
    <div class="input-group-append">
      <input class="form-control onboard datepicker" formControlName="295" (blur)="handleNAchange('cmd_0077',295)" (blur)="clearInputIfInvalid('295', 'cmd_0077')" 
      [class.is-invalid]="(eyeForm.controls['295'].invalid)"
      ngbDatepicker #d550="ngbDatepicker" id="295" name="295" type="text" placeholder= "License Expiration Date" data-id="295">
          <button class="btn btn-outline-secondary calendar" (click)="d550.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
      </div>
      <div class="formLabel col-md-11"
          *ngIf="eyeForm.controls['295'].touched && eyeForm.controls['295'].invalid">
          <small class="text-danger" *ngIf="eyeForm.controls['295'].errors?.required">
              required
          </small>
      </div>
      <div  *ngIf="eyeForm.get('295').invalid ">
        <small class="text-danger" *ngIf="eyeForm.get('295').errors?.invalidDateFormat">
          Only Date Format is allowed(MM/DD/YYYY)                  </small>
        <small class="text-danger" *ngIf="eyeForm.get('295').errors?.invalidMonth">
        Month is out of range
        </small>
        <small class="text-danger" *ngIf="eyeForm.get('295').errors?.invalidDateyear">
          year is out of range(1900-2099)                    </small>
          <small class="text-danger" *ngIf="eyeForm.get('295').errors?.invalidDateRange">
            date is out of range
            </small>
        </div>
  </td>
</tr>
<tr class="border">
  <td colspan="8">
    Drug Enforcement Administration (DEA) registration number (if applicable):
    <input class="form-control onboard" formControlName="309" (blur)="handleNAchange('cmd_0077',309)"
    [class.is-invalid]="(eyeForm.controls['309'].invalid)"
    id="309" name="309" type="text" placeholder= "DEA Number" data-id="managing_contact_name">         
    <div class="formLabel col-md-11"ll
    *ngIf="eyeForm.controls['309'].touched && eyeForm.controls['309'].invalid">
    <small class="text-danger" *ngIf="eyeForm.controls['309'].errors?.required">
        required
    </small>
</div>
  </td>
 
  <td colspan="4">
    Month/Day/Year of Expiration:
    <div class="input-group-append">
      <input class="form-control onboard datepicker" formControlName="311"  (blur)="handleNAchange('cmd_0077',311)" (blur)="clearInputIfInvalid('311', 'cmd_0077')"
      [class.is-invalid]="(eyeForm.controls['311'].invalid)"
      ngbDatepicker #d3400="ngbDatepicker" id="311" name="311" type="text" placeholder= "DEA Exp Date " data-id="311">
          <button class="btn btn-outline-secondary calendar" (click)="d3400.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
      </div>
      <div class="formLabel col-md-11"
          *ngIf="eyeForm.controls['311'].touched && eyeForm.controls['311'].invalid">
          <small class="text-danger" *ngIf="eyeForm.controls['311'].errors?.required">
              required
          </small>
      </div>
      <div  *ngIf="eyeForm.get('311').invalid ">
        <small class="text-danger" *ngIf="eyeForm.get('311').errors?.invalidDateFormat">
          Only Date Format is allowed(MM/DD/YYYY)                  </small>
        <small class="text-danger" *ngIf="eyeForm.get('311').errors?.invalidMonth">
        Month is out of range
        </small>
        <small class="text-danger" *ngIf="eyeForm.get('311').errors?.invalidDateyear">
          year is out of range(1900-2099)                    </small>
          <small class="text-danger" *ngIf="eyeForm.get('311').errors?.invalidDateRange">
            date is out of range
            </small>
        </div>
  </td>
</tr>
<tr class="border">
  <td colspan="8">
    Controlled substance registration (CSR) number (if applicable):
    <input class="form-control onboard" formControlName="337" 
    id="337" name="337" type="text" placeholder= "State Controlled Substance Number (CDS)" data-id="managing_contact_name">         
    
  </td>
 
  <td colspan="4">
    Month/Day/Year of Expiration:
    <div class="input-group-append">
      <input class="form-control onboard datepicker" formControlName="339"  (blur)="clearInputIfInvalid('339')"
      ngbDatepicker #d3500="ngbDatepicker" id="339" name="339" type="text" placeholder= "CDS Exp Date " data-id="339">
          <button class="btn btn-outline-secondary calendar" (click)="d3500.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
      </div>
      <div  *ngIf="eyeForm.get('339').invalid ">
        <small class="text-danger" *ngIf="eyeForm.get('339').errors?.invalidDateFormat">
          Only Date Format is allowed(MM/DD/YYYY)                  </small>
        <small class="text-danger" *ngIf="eyeForm.get('339').errors?.invalidMonth">
        Month is out of range
        </small>
        <small class="text-danger" *ngIf="eyeForm.get('339').errors?.invalidDateyear">
          year is out of range(1900-2099)                    </small>
          <small class="text-danger" *ngIf="eyeForm.get('339').errors?.invalidDateRange">
            date is out of range
            </small>
        </div>
  </td>
</tr>
<tr class="border">
  <td colspan="4">
    Entity type 1 (individual) NPI number:
    <input class="form-control onboard" formControlName="1634" (blur)="handleNAchange('cmd_0077',1634)"
    [class.is-invalid]="(eyeForm.controls['1634'].invalid)"
    id="1634" name="1634" type="text" placeholder= "NPI" data-id="managing_contact_name">         
    <div class="formLabel col-md-11"ll
    *ngIf="eyeForm.controls['1634'].touched && eyeForm.controls['1634'].invalid">
    <small class="text-danger" *ngIf="eyeForm.controls['1634'].errors?.required">
        required
    </small>
</div>
  </td>
  <td colspan="4">
    Medicare number:
    <input class="form-control onboard" formControlName="325" 
    id="325" name="325" type="text" placeholder= "Medicare Number (PTAN)" data-id="managing_contact_name">         
  </td>
  <td colspan="4">
    Oregon Medicaid provider number:
      <input class="form-control onboard datepicker" formControlName="812"  
      id="812" name="812" type="text" placeholder= "Medicaid Number" data-id="812">
  </td>
</tr>
<tr>
  <td  colspan="12">
    <label style="font-size: 18px;" class="form-control">
      Physician Assistant Supervising Physician Full Name and Oregon License Number: 
    </label>
  </td>
</tr>
<tr>
  <td colspan="12">
    <div class="centered texasFirstTable">
      <div class="row">
      <div class="col-md-8">
          <h2  style="margin-left: 31px;  font-size: 18px;"><b>XV. Other State Health Care Licenses, Registrations & Certificates</b>
            
          </h2>
          <span class="normalParagraphStyle">
            <i> (Please attach additional sheets, if necessary.)</i>
          </span>
      </div>
      <div class="col-md-4">
          <label for="cmd_0078"><b>Does Not Apply:</b></label>&nbsp;&nbsp;
          <input type="checkbox" formControlName="cmd_0078"
              id="cmd_0078" value="1"  (ngModelChange)="handleNAchange('cmd_0078')"  
              >
  </div>
    </div>
  </div>
  </td>
</tr>
<tr class="border">
  <td colspan="4">
    State/Country:
    <ng-select
    [items]="additionalLicensesstates"
    [class.is-invalid]="
      (eyeForm.controls['add_1_1010'].invalid)
    "
    bindLabel="name"
    placeholder="Additional License State or Country"
    bindValue="id"
    formControlName="add_1_1010"
    (blur)="handleNAchange('cmd_0078','add_1_1010')"
    
  >
  </ng-select>       
    <div
    class="formLabel col-md-11"
    *ngIf="
      eyeForm.controls['add_1_1010'].touched &&
      eyeForm.controls['add_1_1010'].invalid
    "
  >
    <small
      class="text-danger"
      *ngIf="eyeForm.controls['add_1_1010'].errors"
    >
      required
    </small>
  </div>     
   
  </td>
  <td colspan="4">
    Number:         
    <input class="form-control onboard" formControlName="add_1_999"  (blur)="handleNAchange('cmd_0078','add_1_999')"
    [class.is-invalid]="(eyeForm.controls['add_1_999'].invalid)"
    id="add_1_999" name="add_1_999" type="text" placeholder= "Additional License Number" data-id="managing_contact_name">         
    <div class="formLabel col-md-11"
    *ngIf="eyeForm.controls['add_1_999'].touched && eyeForm.controls['add_1_999'].invalid">
    <small class="text-danger" *ngIf="eyeForm.controls['add_1_999'].errors?.required">
        required
    </small>
</div>
  </td>
  <td colspan="4">
    Type:
    <input class="form-control onboard" formControlName="add_1_1432" (blur)="handleNAchange('cmd_0078','add_1_1432')"
    [class.is-invalid]="(eyeForm.controls['add_1_1432'].invalid)"
    id="add_1_1432" name="add_1_1432" type="text" placeholder= "Additional License Type" data-id="managing_contact_name">         
    <div class="formLabel col-md-11"
    *ngIf="eyeForm.controls['add_1_1432'].touched && eyeForm.controls['add_1_1432'].invalid">
    <small class="text-danger" *ngIf="eyeForm.controls['add_1_1432'].errors?.required">
        required
    </small>
</div>
  </td>
</tr>
<tr class="border">
  <td colspan="4">
    Year obtained:
    <div class="input-group-append">
      <input class="form-control onboard datepicker" formControlName="add_1_1003" (blur)="clearInputIfInvalid('add_1_1003' ,'cmd_0078')"  (blur)="handleNAchange('cmd_0078','add_1_1003')" 
      [class.is-invalid]="(eyeForm.controls['add_1_1003'].invalid)"
      ngbDatepicker #d59="ngbDatepicker" id="add_1_1003" name="add_1_1003" type="text" placeholder= "Additional License Issue Date " data-id="add_1_1003">
          <button class="btn btn-outline-secondary calendar" (click)="d59.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
      </div>
      <div class="formLabel col-md-11"
          *ngIf="eyeForm.controls['add_1_1003'].touched && eyeForm.controls['add_1_1003'].invalid">
          <small class="text-danger" *ngIf="eyeForm.controls['add_1_1003'].errors?.required">
              required
          </small>
      </div>
      <div  *ngIf="eyeForm.get('add_1_1003').invalid ">
        <small class="text-danger" *ngIf="eyeForm.get('add_1_1003').errors?.invalidDateFormat">
          Only Date Format is allowed(MM/DD/YYYY)                  </small>
        <small class="text-danger" *ngIf="eyeForm.get('add_1_1003').errors?.invalidMonth">
        Month is out of range
        </small>
        <small class="text-danger" *ngIf="eyeForm.get('add_1_1003').errors?.invalidDateyear">
          year is out of range(1900-2099)                    </small>
          <small class="text-danger" *ngIf="eyeForm.get('add_1_1003').errors?.invalidDateRange">
            date is out of range
            </small>
        </div>
  </td>
  <td colspan="4">
    Month/Day/Year of expiration:
    <div class="input-group-append">
      <input class="form-control onboard datepicker" formControlName="add_1_1004" (blur)="handleNAchange('cmd_0078','add_1_1004')" (blur)="clearInputIfInvalid('add_1_1004' ,'cmd_0078')"
      [class.is-invalid]="(eyeForm.controls['add_1_1004'].invalid)"
      ngbDatepicker #d60="ngbDatepicker" id="add_1_1004" name="add_1_1004" type="text" placeholder= "Additional License Expiration Date" data-id="add_1_1004">
          <button class="btn btn-outline-secondary calendar" (click)="d60.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
      </div>
      <div class="formLabel col-md-11"
          *ngIf="eyeForm.controls['add_1_1004'].touched && eyeForm.controls['add_1_1004'].invalid">
          <small class="text-danger" *ngIf="eyeForm.controls['add_1_1004'].errors?.required">
              required
          </small>
      </div>
      <div  *ngIf="eyeForm.get('add_1_1004').invalid ">
        <small class="text-danger" *ngIf="eyeForm.get('add_1_1004').errors?.invalidDateFormat">
          Only Date Format is allowed(MM/DD/YYYY)                  </small>
        <small class="text-danger" *ngIf="eyeForm.get('add_1_1004').errors?.invalidMonth">
        Month is out of ranglle
        </small>
        <small class="text-danger" *ngIf="eyeForm.get('add_1_1004').errors?.invalidDateyear">
          year is out of range(1900-2099)                    </small>
          <small class="text-danger" *ngIf="eyeForm.get('add_1_1004').errors?.invalidDateRange">
            date is out of range
            </small>
        </div>
  </td>
  <td colspan="4">
    Year relinquished:
    <div class="input-group-append">
      <input class="form-control onboard datepicker" formControlName="cmd_0079" 
       id="cmd_0079" name="cmd_0079" type="text" data-id="cmd_0079">
      </div>
      
  </td>
</tr>
<tr>
  <td colspan="12">
    Reason:
    <div class="input-group-append">
      <input class="form-control onboard datepicker" formControlName="cmd_0080" 
       id="cmd_0080" name="cmd_0080" type="text" data-id="cmd_0080">
      </div>
      
  </td>
</tr>
<tr class="border">
  <td colspan="4">
    State/Country:
    <ng-select
    [items]="additionalLicensesstates"
    bindLabel="name"
    placeholder="Additional License State or Country"
    bindValue="id"
    formControlName="add_2_1010"
    
  >
  </ng-select>       
  
  </td>
  <td colspan="4">
    Number:         
    <input class="form-control onboard" formControlName="add_2_999" 
    id="add_2_999" name="add_2_999" type="text" placeholder= "Additional License Number" data-id="managing_contact_name">         
   
  </td>
  <td colspan="4">
    Type:
    <input class="form-control onboard" formControlName="add_2_1432" 
    id="add_2_1432" name="add_2_1432" type="text" placeholder= "Additional License Type" data-id="managing_contact_name">         
  
  </td>
</tr>
<tr class="border">
  <td colspan="4">
    Year obtained:
    <div class="input-group-append">
      <input class="form-control onboard datepicker" formControlName="add_2_1003" (blur)="clearInputIfInvalid('add_2_1003')"
      ngbDatepicker #d62="ngbDatepicker" id="add_2_1003" name="add_2_1003" type="text" placeholder= "Additional License Issue Date " data-id="add_2_1003">
          <button class="btn btn-outline-secondary calendar" (click)="d62.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
      </div>
      <div  *ngIf="eyeForm.get('add_2_1003').invalid ">
        <small class="text-danger" *ngIf="eyeForm.get('add_2_1003').errors?.invalidDateFormat">
          Only Date Format is allowed(MM/DD/YYYY)                  </small>
        <small class="text-danger" *ngIf="eyeForm.get('add_2_1003').errors?.invalidMonth">
        Month is out of range
        </small>
        <small class="text-danger" *ngIf="eyeForm.get('add_2_1003').errors?.invalidDateyear">
          year is out of range(1900-2099)                    </small>
          <small class="text-danger" *ngIf="eyeForm.get('add_2_1003').errors?.invalidDateRange">
            date is out of range
            </small>
        </div>
  </td>
  <td colspan="4">
    Month/Day/Year of expiration:
    <div class="input-group-append">
      <input class="form-control onboard datepicker" formControlName="add_2_1004" (blur)="clearInputIfInvalid('add_2_1004')"
      ngbDatepicker #d63="ngbDatepicker" id="add_2_1004" name="add_2_1004" type="text" placeholder= "Additional License Expiration Date" data-id="add_2_1004">
          <button class="btn btn-outline-secondary calendar" (click)="d63.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
      </div>
      <div  *ngIf="eyeForm.get('add_2_1004').invalid ">
        <small class="text-danger" *ngIf="eyeForm.get('add_2_1004').errors?.invalidDateFormat">
          Only Date Format is allowed(MM/DD/YYYY)                  </small>
        <small class="text-danger" *ngIf="eyeForm.get('add_2_1004').errors?.invalidMonth">
        Month is out of range
        </small>
        <small class="text-danger" *ngIf="eyeForm.get('add_2_1004').errors?.invalidDateyear">
          year is out of range(1900-2099)                    </small>
          <small class="text-danger" *ngIf="eyeForm.get('add_2_1004').errors?.invalidDateRange">
            date is out of range
            </small>
        </div>

  </td>
  <td colspan="4">
    Year relinquished:
    <div class="input-group-append">
      <input class="form-control onboard datepicker" formControlName="cmd_0081" 
       id="cmd_0081" name="cmd_0081" type="text" data-id="cmd_0081">
      </div>
      
  </td>
</tr>
<tr>
  <td colspan="12">
    Reason:
    <div class="input-group-append">
      <input class="form-control onboard datepicker" formControlName="cmd_0082" 
       id="cmd_0082" name="cmd_0082" type="text" data-id="cmd_0082">
      </div>
      
  </td>
</tr>
<tr class="border">
  <td colspan="4">
    State/Country:
    <ng-select
    [items]="additionalLicensesstates"
    bindLabel="name"
    placeholder="Additional License State or Country"
    bindValue="id"
    formControlName="add_3_1010"
    
  >
  </ng-select>       
  
  </td>
  <td colspan="4">
    Number:         
    <input class="form-control onboard" formControlName="add_3_999" 
    id="add_3_999" name="add_3_999" type="text" placeholder= "Additional License Number" data-id="managing_contact_name">         
   
  </td>
  <td colspan="4">
    Type:
    <input class="form-control onboard" formControlName="add_3_1432" 
    id="add_3_1432" name="add_3_1432" type="text" placeholder= "Additional License Type" data-id="managing_contact_name">         
  
  </td>
</tr>
<tr class="border">
  <td colspan="4">
    Year obtained:
    <div class="input-group-append">
      <input class="form-control onboard datepicker" formControlName="add_3_1003" (blur)="clearInputIfInvalid('add_3_1003')"
      ngbDatepicker #d66="ngbDatepicker" id="add_3_1003" name="add_3_1003" type="text" placeholder= "Additional License Issue Date " data-id="add_3_1003">
          <button class="btn btn-outline-secondary calendar" (click)="d66.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
      </div>
      <div  *ngIf="eyeForm.get('add_3_1003').invalid ">
        <small class="text-danger" *ngIf="eyeForm.get('add_3_1003').errors?.invalidDateFormat">
          Only Date Format is allowed(MM/DD/YYYY)                  </small>
        <small class="text-danger" *ngIf="eyeForm.get('add_3_1003').errors?.invalidMonth">
        Month is out of range
        </small>
        <small class="text-danger" *ngIf="eyeForm.get('add_3_1003').errors?.invalidDateyear">
          year is out of range(1900-2099)                    </small>
          <small class="text-danger" *ngIf="eyeForm.get('add_3_1003').errors?.invalidDateRange">
            date is out of range
            </small>
        </div>
  </td>
  <td colspan="4">
    Month/Day/Year of expiration:
    <div class="input-group-append">
      <input class="form-control onboard datepicker" formControlName="add_3_1004" (blur)="clearInputIfInvalid('add_3_1004')"
      ngbDatepicker #d65="ngbDatepicker" id="add_3_1004" name="add_3_1004" type="text" placeholder= "Additional License Expiration Date" data-id="add_3_1004">
          <button class="btn btn-outline-secondary calendar" (click)="d65.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
      </div>
      <div  *ngIf="eyeForm.get('add_3_1004').invalid ">
        <small class="text-danger" *ngIf="eyeForm.get('add_3_1004').errors?.invalidDateFormat">
          Only Date Format is allowed(MM/DD/YYYY)                  </small>
        <small class="text-danger" *ngIf="eyeForm.get('add_3_1004').errors?.invalidMonth">
        Month is out of range
        </small>
        <small class="text-danger" *ngIf="eyeForm.get('add_3_1004').errors?.invalidDateyear">
          year is out of range(1900-2099)                    </small>
          <small class="text-danger" *ngIf="eyeForm.get('add_3_1004').errors?.invalidDateRange">
            date is out of range
            </small>
        </div>

  </td>
  <td colspan="4">
    Year relinquished:
    <div class="input-group-append">
      <input class="form-control onboard datepicker" formControlName="cmd_0084" 
       id="cmd_0084" name="cmd_0084" type="text" data-id="cmd_0084">
      </div>
      
  </td>
</tr>
<tr>
  <td colspan="12">
    Reason:
    <div class="input-group-append">
      <input class="form-control onboard datepicker" formControlName="cmd_0083" 
       id="cmd_0083" name="cmd_0083" type="text" data-id="cmd_0083">
      </div>
      
  </td>
</tr>
<tr>
  <td colspan="12">
    <div class="centered texasFirstTable">
      <div class="row">
      <div class="col-md-8">
          <h2  style="margin-left: 31px;  font-size: 18px;"><b>XVI. Hospital and Other Health Care Facility Affiliations</b>
            
          </h2>
      </div>
    </div>
  </div>
  </td>
</tr>
<tr class="border">
  <td colspan="12">
  <div class="col-12">
      Please list in reverse chronological order, with the current affiliation(s) first, all health care institutions where you have and/or have had clinical
privileges and/or staff membership. Include (A) current affiliations, (B) applications in process, and (C) previous hospitals, and other facility affiliations
(e.g., hospitals, surgery centers or any other health care related facility). If more space is needed, please attach additional sheets. Do not list
residencies, internships or fellowships. Please list employment in Section XVII, Professional Practice/Work History.</div>
  </td>
</tr>
<tr>
  <td colspan="12">
    <div class="centered texasFirstTable">
      <div class="row">
      <div class="col-md-8">
          <h2  style="margin-left: 31px;  font-size: 18px;"><b>A. Current Affiliations</b>
            
          </h2>
      </div>
      <div class="col-md-4">
          <label for="cmd_0085"><b>Does Not Apply:</b></label>&nbsp;&nbsp;
          <input type="checkbox" formControlName="cmd_0085"
              id="cmd_0085" value="1"   (ngModelChange)="handleNAchange('cmd_0085')"
              >
  </div>
    </div>
  </div>
  </td>
</tr>
<tr class="border">
  <td colspan="3">
    Facility name:
    <input class="form-control onboard" formControlName="418" (blur)="handleNAchange('cmd_0085',418)"
    [class.is-invalid]="(eyeForm.controls['418'].invalid)"
    id="418" name="418" type="text" placeholder= "Hospital Affiliation 1 Name" data-id="managing_contact_name">         
    <div class="formLabel col-md-11"ll
    *ngIf="eyeForm.controls['418'].touched && eyeForm.controls['418'].invalid">
    <small class="text-danger" *ngIf="eyeForm.controls['418'].errors?.required">
        required
    </small>
</div>
  </td>
  <td colspan="3">
    Phone number:
    <input class="form-control onboard" formControlName="1305" (keydown)="handleNumber($event,1305)" maxlength="12" (blur)="handleNAchange('cmd_0085',1305)"
    [class.is-invalid]="(eyeForm.controls['1305'].invalid)"
    id="1305" name="1305" type="text" placeholder= "Hospital Affiliation 1 Phone" data-id="managing_contact_name">         
    <div class="formLabel col-md-11"ll
    *ngIf="eyeForm.controls['1305'].touched && eyeForm.controls['1305'].invalid">
    <small class="text-danger" *ngIf="eyeForm.controls['1305'].errors?.required">
        required
    </small>
    <small class="text-danger" *ngIf="eyeForm.controls['1305'].errors?.incorrectPhoneFormat">
      Required pattern xxx-xxx-xxxx
     </small>
</div>
  </td>
  <td colspan="3">
    Fax number, if available
    <input class="form-control onboard" formControlName="2136" (keydown)="handleNumber($event,2136)" maxlength="12" (blur)="handleNAchange('cmd_0085',2136)"
    [class.is-invalid]="(eyeForm.controls['2136'].invalid)"
    id="2136" name="2136" type="text" placeholder= "Hospital Affiliation 1 Fax" data-id="managing_contact_name">         
    <div class="formLabel col-md-11"ll
    *ngIf="eyeForm.controls['2136'].touched && eyeForm.controls['2136'].invalid">
    <small class="text-danger" *ngIf="eyeForm.controls['2136'].errors?.required">
        required
    </small>
    <small class="text-danger" *ngIf="eyeForm.controls['2136'].errors?.incorrectPhoneFormat">
      Required pattern xxx-xxx-xxxx
     </small>
</div>
  </td>
  <td colspan="3" rowspan="3">
    Complete address:
    <input class="form-control onboard" formControlName="419" (blur)="handleNAchange('cmd_0085',419)"
    [class.is-invalid]="(eyeForm.controls['419'].invalid)"
    id="419" name="419" type="text" placeholder= "Hospital Affiliation 1 Address1" data-id="managing_contact_name">         
    <div class="formLabel col-md-11"ll
    *ngIf="eyeForm.controls['419'].touched && eyeForm.controls['419'].invalid">
    <small class="text-danger" *ngIf="eyeForm.controls['419'].errors?.required">
        required
    </small>
</div>
<input class="form-control onboard" formControlName="420" (blur)="handleNAchange('cmd_0085',420)"
[class.is-invalid]="(eyeForm.controls['420'].invalid)"
id="420" name="420" type="text" placeholder= "Hospital Affiliation 1 Address 2" data-id="managing_contact_name">         
<div class="formLabel col-md-11"ll
*ngIf="eyeForm.controls['420'].touched && eyeForm.controls['420'].invalid">
<small class="text-danger" *ngIf="eyeForm.controls['420'].errors?.required">
    required
</small>
</div>
<input class="form-control onboard" formControlName="421" (blur)="handleNAchange('cmd_0085',421)"
[class.is-invalid]="(eyeForm.controls['421'].invalid)"
id="421" name="421" type="text" placeholder= "Hospital Affiliation 1 City" data-id="managing_contact_name">         
<div class="formLabel col-md-11"ll
*ngIf="eyeForm.controls['421'].touched && eyeForm.controls['421'].invalid">
<small class="text-danger" *ngIf="eyeForm.controls['421'].errors?.required">
    required
</small>
</div>
<ng-select
    [items]="states"
    [class.is-invalid]="
      (eyeForm.controls['423'].invalid)
    "
    bindLabel="name"
    placeholder="States"
    bindValue="id"
    formControlName="423"
    (blur)="handleNAchange('cmd_0085',423)"
    style="width:225px"
  >
  </ng-select>       
    <div
    class="formLabel col-md-11"
    *ngIf="
      eyeForm.controls['423'].touched &&
      eyeForm.controls['423'].invalid
    "
  >
    <small
      class="text-danger"
      *ngIf="eyeForm.controls['423'].errors"
    >
      required
    </small>
  </div> 
  <input class="form-control onboard" formControlName="424" (blur)="handleNAchange('cmd_0085',424)"
[class.is-invalid]="(eyeForm.controls['424'].invalid)"
id="424" name="424" type="text" placeholder= "Hospital Affiliation 1 Zip" data-id="managing_contact_name">         
<div
class="formLabel col-md-2"
*ngIf="
  eyeForm.controls['424'].invalid
"
>
<small
  class="text-danger"
  *ngIf="eyeForm.controls['424'].errors"
>
  required
</small>
<small
  class="text-danger"
  *ngIf="
    eyeForm.controls['424'].errors?.invalidZipCode
  "
>
  Zip code must be atleast 5 digits and less than 10 digits
</small>
</div>
  </td>
</tr>
<tr class="border">
  <td colspan="3">
    Status (e.g. active, courtesy, provisional, allied health, etc.):
    <ng-select [items]="staffStatus" bindLabel="name" placeholder="Hospital Affiliation 1 Staff Status" bindValue="id"
    [class.is-invalid]="(eyeForm.controls['894'].invalid)"
    formControlName="894" (blur)="handleNAchange('cmd_0085',894)"
    style="width:400px">
</ng-select>
<div class="formLabel col-md-11"
    *ngIf="eyeForm.controls['894'].touched && eyeForm.controls['894'].invalid">
    <small class="text-danger" *ngIf="eyeForm.controls['894'].errors?.required">
        required
    </small>
</div>
  </td>
  <td colspan="6">
    Month/day/year of appointment
    <div class="input-group-append">
      <input class="form-control onboard datepicker" formControlName="426" (blur)="handleNAchange('cmd_0085',426)" (blur)="clearInputIfInvalid('426', 'cmd_0085')"
      [class.is-invalid]="(eyeForm.controls['426'].invalid)"
      ngbDatepicker #d69="ngbDatepicker" id="426" name="426" type="text" placeholder= "Hospital Affiliation 1 Initial Appointment" data-id="426">
          <button class="btn btn-outline-secondary calendar" (click)="d69.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
      </div>
      <div class="formLabel col-md-11"
          *ngIf="eyeForm.controls['426'].touched && eyeForm.controls['426'].invalid">
          <small class="text-danger" *ngIf="eyeForm.controls['426'].errors?.required">
              required
          </small>
      </div>
      <div  *ngIf="eyeForm.get('426').invalid ">
        <small class="text-danger" *ngIf="eyeForm.get('426').errors?.invalidDateFormat">
          Only Date Format is allowed(MM/DD/YYYY)                  </small>
        <small class="text-danger" *ngIf="eyeForm.get('426').errors?.invalidMonth">
        Month is out of range
        </small>
        <small class="text-danger" *ngIf="eyeForm.get('426').errors?.invalidDateyear">
          year is out of range(1900-2099)                    </small>
          <small class="text-danger" *ngIf="eyeForm.get('426').errors?.invalidDateRange">
            date is out of range
            </small>
        </div>
  </td> 
</tr>
<tr>
  <td colspan="3">
    Contact email
    <input class="form-control onboard" formControlName="2142" (blur)="handleNAchange('cmd_0085',2142)"
    [class.is-invalid]="(eyeForm.controls['2142'].invalid)"
    id="2142" name="2142" type="text" placeholder= "Hospital Affiliation 1 Contact Email " data-id="managing_contact_name">         
    <div class="formLabel col-md-11"ll
    *ngIf="eyeForm.controls['2142'].touched && eyeForm.controls['2142'].invalid">
    <small class="text-danger" *ngIf="eyeForm.controls['2142'].errors?.required">
        required
    </small>
    </div>
    <small
class="text-danger"
*ngIf="eyeForm.controls['2142'].invalid"
>Please enter a valid email.</small
>
  </td>
</tr>
<tr class="border">
  <td colspan="6">
          <label class="formLabel" style="font-size: 16px;">Do you have admitting privileges at this facility?</label>
              <label class="radio-inline radioStyle">
              <input
                  type="radio"
                  id="cmd_0086"
                  name="cmd_0086"
                  [class.is-invalid]="(eyeForm.controls['cmd_0086'].touched 
                  && eyeForm.controls['cmd_0086'].invalid) || 
                  (eyeForm.controls['cmd_0086'].invalid && isSubmit)"
                  formControlName="cmd_0086"
                  value="1"
              />Yes</label>
              <label class="radio-inline radioStyle">
              <input
                   type="radio"
                   id="cmd_0086"
                  name="cmd_0086"
                  [class.is-invalid]="(eyeForm.controls['cmd_0086'].touched 
                  && eyeForm.controls['cmd_0086'].invalid) || 
                  (eyeForm.controls['cmd_0086'].invalid && isSubmit)"
                  formControlName="cmd_0086"
                  value="0"
              />No</label>
              <div class="formLabel"
              *ngIf="eyeForm.controls['cmd_0086'].touched && eyeForm.controls['cmd_0086'].invalid">
              <small class="text-danger" *ngIf="eyeForm.controls['cmd_0086'].errors?.required">
                  required
              </small>
             </div>
             <span *ngIf="(eyeForm.controls['cmd_0086'].touched && eyeForm.controls['cmd_0086'].errors)
             || (eyeForm.controls['cmd_0086'].errors && isSubmit)">
           <i class="fas fa-times ml-2 text-danger"></i>
              </span>
  </td>
 <td colspan="6">
  Professional liability carrier:
  <input class="form-control onboard" formControlName="cmd_0087" 
  id="cmd_0087" name="cmd_0087" type="text" placeholder= "" data-id="managing_contact_name"> 
 </td>
</tr> 
<tr class="border">
  <td colspan="3">
    Facility name:
    <input class="form-control onboard" formControlName="427" 
    id="427" name="427" type="text" placeholder= "Hospital Affiliation 2 Name" data-id="managing_contact_name">         
  </td>
  <td colspan="3">
    Phone number:
    <input class="form-control onboard" formControlName="1307" (keydown)="handleNumber($event,1307)" maxlength="12"
    id="1307" name="1307" type="text" placeholder= "Hospital Affiliation 2 Phone" data-id="managing_contact_name">    
    <small class="text-danger" *ngIf="eyeForm.get('1307').errors?.pattern">Required pattern xxx-xxx-xxxx</small>
     
  </td>
  <td colspan="3">
    Fax number, if available
    <input class="form-control onboard" formControlName="2137" (keydown)="handleNumber($event,2137)" maxlength="12"
    id="2137" name="2137" type="text" placeholder= "Hospital Affiliation 2 Fax" data-id="managing_contact_name">    
    <small class="text-danger" *ngIf="eyeForm.get('2137').errors?.pattern">Required pattern xxx-xxx-xxxx</small>
     
  </td>
  <td colspan="3" rowspan="3">
    Complete address:
    <input class="form-control onboard" formControlName="428" 
    id="428" name="428" type="text" placeholder= "Hospital Affiliation 2 Address1" data-id="managing_contact_name">         
   
   <input class="form-control onboard" formControlName="429" 
    id="429" name="429" type="text" placeholder= "Hospital Affiliation 2 Address 2" data-id="managing_contact_name">        

   <input class="form-control onboard" formControlName="430" 
   id="430" name="430" type="text" placeholder= "Hospital Affiliation 2 City" data-id="managing_contact_name">         

<ng-select
    [items]="states"
   
    bindLabel="name"
    placeholder="States"
    bindValue="id"
    formControlName="432"
    style="width:225px"
    
  >
  </ng-select>       
   
   <input class="form-control onboard" formControlName="433" 
  id="433" name="433" type="text" placeholder= "Hospital Affiliation 2 Zip" data-id="managing_contact_name">         
  <small
  class="text-danger"
  *ngIf="eyeForm.controls['433'].errors?.pattern"
  > Zip code must be atleast 5 digits and less than 10 digits.</small
>
  </td>
</tr>
<tr class="border">
  <td colspan="3">
    Status (e.g. active, courtesy, provisional, allied health, etc.):
    <ng-select [items]="staffStatus" bindLabel="name" placeholder="Hospital Affiliation 2 Staff Status" bindValue="id"
    formControlName="895" style="width:400px">
</ng-select>

  </td>
  <td colspan="6">
    Month/day/year of appointment
    <div class="input-group-append">
      <input class="form-control onboard datepicker" formControlName="435" 
      ngbDatepicker #d71="ngbDatepicker" id="435" name="435" (blur)="clearInputIfInvalid('435')" type="text" placeholder= "Hospital Affiliation 2 Initial Appointment" data-id="435">
          <button class="btn btn-outline-secondary calendar" (click)="d71.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
      </div>
      <div  *ngIf="eyeForm.get('435').invalid ">
        <small class="text-danger" *ngIf="eyeForm.get('435').errors?.invalidDateFormat">
          Only Date Format is allowed(MM/DD/YYYY)                  </small>
        <small class="text-danger" *ngIf="eyeForm.get('435').errors?.invalidMonth">
        Month is out of range
        </small>
        <small class="text-danger" *ngIf="eyeForm.get('435').errors?.invalidDateyear">
          year is out of range(1900-2099)                    </small>
          <small class="text-danger" *ngIf="eyeForm.get('435').errors?.invalidDateRange">
            date is out of range
            </small>
        </div>
      
  </td> 
</tr>
<tr>
  <td colspan="3">
    Contact email
    <input class="form-control onboard" formControlName="2143" 
    id="2143" name="2143" type="text" placeholder= "Hospital Affiliation 2 Contact Email " data-id="managing_contact_name">         
    <small
    class="text-danger"
    *ngIf="eyeForm.controls['2143'].errors?.pattern"
    >Please enter a valid email.</small
    >
  </td>
</tr>
<tr class="border">
  <td colspan="6">
          <label class="formLabel" style="font-size: 16px;">Do you have admitting privileges at this facility?</label>
              <label class="radio-inline radioStyle">
              <input
                  type="radio"
                  id="cmd_0088"
                  name="cmd_0088"
                  formControlName="cmd_0088"
                  value="1"
              />Yes</label>
              <label class="radio-inline radioStyle">
              <input
                   type="radio"
                   id="cmd_0088"
                  name="cmd_0088"
                  formControlName="cmd_0088"
                  value="0"
              />No</label>
            
            
  </td>
 <td colspan="6">
  Professional liability carrier:
  <input class="form-control onboard" formControlName="cmd_0089" 
  id="cmd_0089" name="cmd_0089" type="text" placeholder= "" data-id="managing_contact_name"> 
 </td>
</tr>
<tr class="border">
  <td colspan="3">
    Facility name:
    <input class="form-control onboard" formControlName="436" 
    id="436" name="436" type="text" placeholder= "Hospital Affiliation 3 Name" data-id="managing_contact_name">         
  </td>
  <td colspan="3">
    Phone number:
    <input class="form-control onboard" formControlName="1309" (keydown)="handleNumber($event,1309)" maxlength="12"
    id="1309" name="1309" type="text" placeholder= "Hospital Affiliation 3 Phone" data-id="managing_contact_name">  
    <small class="text-danger" *ngIf="eyeForm.get('1309').errors?.pattern">Required pattern xxx-xxx-xxxx</small>
       
  </td>
  <td colspan="3">
    Fax number, if available
    <input class="form-control onboard" formControlName="2138" (keydown)="handleNumber($event,2138)" maxlength="12"
    id="2138" name="2138" type="text" placeholder= "Hospital Affiliation 3 Fax" data-id="managing_contact_name"> 
    <small class="text-danger" *ngIf="eyeForm.get('2138').errors?.pattern">Required pattern xxx-xxx-xxxx</small>
        
  </td>
  <td colspan="3" rowspan="3">
    Complete address:
    <input class="form-control onboard" formControlName="437" 
    id="437" name="437" type="text" placeholder= "Hospital Affiliation 3 Address1" data-id="managing_contact_name">         
   
   <input class="form-control onboard" formControlName="438" 
    id="438" name="438" type="text" placeholder= "Hospital Affiliation 3 Address 2" data-id="managing_contact_name">        

   <input class="form-control onboard" formControlName="439" 
   id="439" name="439" type="text" placeholder= "Hospital Affiliation 3 City" data-id="managing_contact_name">         

<ng-select
    [items]="states"
   
    bindLabel="name"
    placeholder="States"
    bindValue="id"
    formControlName="441"
    style="width:225px"
    
  >
  </ng-select>       
   
   <input class="form-control onboard" formControlName="442" 
  id="442" name="442" type="text" placeholder= "Hospital Affiliation 3 Zip" data-id="managing_contact_name">         
  <small
  class="text-danger"
  *ngIf="eyeForm.controls['442'].errors?.pattern"
  > Zip code must be atleast 5 digits and less than 10 digits.</small
>
  </td>
</tr>
<tr class="border">
  <td colspan="3">
    Status (e.g. active, courtesy, provisional, allied health, etc.):
    <ng-select [items]="staffStatus" bindLabel="name" placeholder="Hospital Affiliation 3 Staff Status" bindValue="id"
    formControlName="896"
    style="width:400px">
</ng-select>

  </td>
  <td colspan="6">
    Month/day/year of appointment
    <div class="input-group-append">
      <input class="form-control onboard datepicker" formControlName="444" (blur)="clearInputIfInvalid('444')"
      ngbDatepicker #d72="ngbDatepicker" id="444" name="444" type="text" placeholder= "Hospital Affiliation 3 Initial Appointment" data-id="444">
          <button class="btn btn-outline-secondary calendar" (click)="d72.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
      </div>
      <div  *ngIf="eyeForm.get('444').invalid ">
        <small class="text-danger" *ngIf="eyeForm.get('444').errors?.invalidDateFormat">
          Only Date Format is allowed(MM/DD/YYYY)                  </small>
        <small class="text-danger" *ngIf="eyeForm.get('444').errors?.invalidMonth">
        Month is out of range
        </small>
        <small class="text-danger" *ngIf="eyeForm.get('444').errors?.invalidDateyear">
          year is out of range(1900-2099)                    </small>
          <small class="text-danger" *ngIf="eyeForm.get('444').errors?.invalidDateRange">
            date is out of range
            </small>
        </div>
      
  </td> 
</tr>
<tr>
  <td colspan="3">
    Contact email
    <input class="form-control onboard" formControlName="2144" 
    id="2144" name="2144" type="text" placeholder= "Hospital Affiliation 3 Contact Email " data-id="managing_contact_name">         
    <small
    class="text-danger"
    *ngIf="eyeForm.controls['2144'].errors?.pattern"
    >Please enter a valid email.</small
    >
  </td>
</tr>
<tr class="border">
  <td colspan="6">
          <label class="formLabel" style="font-size: 16px;">Do you have admitting privileges at this facility?</label>
              <label class="radio-inline radioStyle">
              <input
                  type="radio"
                  id="cmd_0090"
                  name="cmd_0090"
                  formControlName="cmd_0090"
                  value="1"
              />Yes</label>
              <label class="radio-inline radioStyle">
              <input
                   type="radio"
                   id="cmd_0090"
                  name="cmd_0090"
                  formControlName="cmd_0090"
                  value="0"
              />No</label>
            
            
  </td>
 <td colspan="6">
  Professional liability carrier:
  <input class="form-control onboard" formControlName="cmd_0091" 
  id="cmd_0091" name="cmd_0091" type="text" placeholder= "" data-id="managing_contact_name"> 
 </td>
</tr>
<tr class="border">
  <td colspan="3">
    Facility name:
    <input class="form-control onboard" formControlName="add_1_986" 
    id="add_1_986" name="add_1_986" type="text" placeholder= "Hospital Affiliation 4 Name" data-id="managing_contact_name">         
  </td>
  <td colspan="3">
    Phone number:
    <input class="form-control onboard" formControlName="add_1_1311"  (keydown)="handleNumber($event,'add_1_1311')" maxlength="12"
    id="add_1_1311" name="add_1_1311" type="text" placeholder= "Hospital Affiliation 4 Phone" data-id="managing_contact_name">  
    <small class="text-danger" *ngIf="eyeForm.get('add_1_1311').errors?.pattern">Required pattern xxx-xxx-xxxx</small>
       
  </td>
  <td colspan="3">
    Fax number, if available
    <input class="form-control onboard" formControlName="add_1_2139" (keydown)="handleNumber($event,'add_1_2139')" maxlength="12"
    id="add_1_2139" name="add_1_2139" type="text" placeholder= "Hospital Affiliation 4 Fax" data-id="managing_contact_name">  
    <small class="text-danger" *ngIf="eyeForm.get('add_1_2139').errors?.pattern">Required pattern xxx-xxx-xxxx</small>
       
  </td>
  <td colspan="3" rowspan="3">
    Complete address:
    <input class="form-control onboard" formControlName="add_1_987" 
    id="add_1_987" name="add_1_987" type="text" placeholder= "Hospital Affiliation 4 Address1" data-id="managing_contact_name">         
   
   <input class="form-control onboard" formControlName="add_1_988" 
    id="add_1_988" name="add_1_988" type="text" placeholder= "Hospital Affiliation 4 Address 2" data-id="managing_contact_name">        

   <input class="form-control onboard" formControlName="add_1_989" 
   id="add_1_989" name="add_1_989" type="text" placeholder= "Hospital Affiliation 4 City" data-id="managing_contact_name">         

<ng-select
    [items]="states"
   
    bindLabel="name"
    placeholder="States"
    bindValue="id"
    formControlName="add_1_991"
    style="width:225px"
    
  >
  </ng-select>       
   
   <input class="form-control onboard" formControlName="add_1_992" 
  id="add_1_992" name="add_1_992" type="text" placeholder= "Hospital Affiliation 4 Zip" data-id="managing_contact_name">         
  <small
  class="text-danger"
  *ngIf="eyeForm.controls['add_1_992'].errors?.pattern"
  > Zip code must be atleast 5 digits and less than 10 digits.</small
>
  </td>
</tr>
<tr class="border">
  <td colspan="3">
    Status (e.g. active, courtesy, provisional, allied health, etc.):
    <ng-select [items]="staffStatus" bindLabel="name" placeholder="Hospital Affiliation 4 Staff Status" bindValue="id"
    formControlName="add_1_995" style="width:400px">
</ng-select>

  </td>
  <td colspan="6">
    Month/day/year of appointment
    <div class="input-group-append">
      <input class="form-control onboard datepicker" formControlName="add_1_994" (blur)="clearInputIfInvalid('add_1_994')"
      ngbDatepicker #d73="ngbDatepicker" id="add_1_994" name="add_1_994" type="text" placeholder= "Hospital Affiliation 4 Initial Appointment" data-id="add_1_994">
          <button class="btn btn-outline-secondary calendar" (click)="d73.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
      </div>
      <div  *ngIf="eyeForm.get('add_1_994').invalid ">
        <small class="text-danger" *ngIf="eyeForm.get('add_1_994').errors?.invalidDateFormat">
          Only Date Format is allowed(MM/DD/YYYY)                  </small>
        <small class="text-danger" *ngIf="eyeForm.get('add_1_994').errors?.invalidMonth">
        Month is out of range
        </small>
        <small class="text-danger" *ngIf="eyeForm.get('add_1_994').errors?.invalidDateyear">
          year is out of range(1900-2099)                    </small>
          <small class="text-danger" *ngIf="eyeForm.get('add_1_994').errors?.invalidDateRange">
            date is out of range
            </small>
        </div>
      
  </td> 
</tr>
<tr>
  <td colspan="3">
    Contact email
    <input class="form-control onboard" formControlName="add_1_2145" 
    id="add_1_2145" name="add_1_2145" type="text" placeholder= "Hospital Affiliation 4 Contact Email " data-id="managing_contact_name">         
    <small
    class="text-danger"
    *ngIf="eyeForm.controls['add_1_2145'].errors?.pattern"
    >Please enter a valid email.</small
    >
  </td>
</tr>
<tr class="border">
  <td colspan="6">
          <label class="formLabel" style="font-size: 16px;">Do you have admitting privileges at this facility?</label>
              <label class="radio-inline radioStyle">
              <input
                  type="radio"
                  id="cmd_0092"
                  name="cmd_0092"
                  formControlName="cmd_0092"
                  value="1"
              />Yes</label>
              <label class="radio-inline radioStyle">
              <input
                   type="radio"
                   id="cmd_0092"
                  name="cmd_0092"
                  formControlName="cmd_0092"
                  value="0"
              />No</label>
            
            
  </td>
 <td colspan="6">
  Professional liability carrier:
  <input class="form-control onboard" formControlName="cmd_0093" 
  id="cmd_0093" name="cmd_0093" type="text" placeholder= "" data-id="managing_contact_name"> 
 </td>
</tr>
<tr>
  <td colspan="12"> 
  <label>If you do not have hospital admitting privileges at any of the affiliations listed in this section, please explain on a separate sheet your plan for
    continuity of care for patients who require admitting</label>
  </td>
</tr>
<tr>
  <td colspan="12">
    <div class="centered texasFirstTable">
      <div class="row">
      <div class="col-md-8">
          <h2  style="margin-left: 31px;  font-size: 18px;"><b>B. Applications in Process</b>
            
          </h2>
      </div>
      <div class="col-md-4">
          <label for="cmd_0094"><b>Does Not Apply:</b></label>&nbsp;&nbsp;
          <input type="checkbox" formControlName="cmd_0094"
              id="cmd_0094" value="1"  (ngModelChange)="handleNAchange('cmd_0094')"  
              >
  </div>
    </div>
  </div>
  </td>
</tr>
<tr class="border">
  <td colspan="3">
    Facility name:
    <input class="form-control onboard" formControlName="add_2_986" (blur)="handleNAchange('cmd_0094', 'add_2_986')" 
    [class.is-invalid]="(eyeForm.controls['add_2_986'].invalid)"
    id="add_2_986" name="add_2_986" type="text" placeholder= "Additional Hospital Affiliation 1  Name" data-id="managing_contact_name">         
    <div class="formLabel col-md-11"ll
    *ngIf="eyeForm.controls['add_2_986'].touched && eyeForm.controls['add_2_986'].invalid">
    <small class="text-danger" *ngIf="eyeForm.controls['add_2_986'].errors?.required">
        required
    </small>
</div>
  </td>
  <td colspan="3">
    Phone number:
    <input class="form-control onboard" formControlName="add_2_1311" (keydown)="handleNumber($event,'add_2_1311')" maxlength="12" (blur)="handleNAchange('cmd_0094', 'add_2_1311')"
    [class.is-invalid]="(eyeForm.controls['add_2_1311'].invalid)"
    id="add_2_1311" name="add_2_1311" type="text" placeholder= " Additional Hospital Affiliation 1 Phone" data-id="managing_contact_name">         
    <div class="formLabel col-md-11"ll
    *ngIf="eyeForm.controls['add_2_1311'].touched && eyeForm.controls['add_2_1311'].invalid">
    <small class="text-danger" *ngIf="eyeForm.controls['add_2_1311'].errors?.required">
        required
    </small>
    <small class="text-danger" *ngIf="eyeForm.controls['add_2_1311'].errors?.incorrectPhoneFormat">
      Required pattern xxx-xxx-xxxx
     </small>
</div>
  </td>
  <td colspan="3">
    Fax number, if available
    <input class="form-control onboard" formControlName="add_2_2139" (keydown)="handleNumber($event,'add_2_2139')" maxlength="12" (blur)="handleNAchange('cmd_0094', 'add_2_2139')"
    [class.is-invalid]="(eyeForm.controls['add_2_2139'].invalid)"
    id="add_2_2139" name="add_2_2139" type="text" placeholder= " Additional Hospital Affiliation 1 Fax" data-id="managing_contact_name">         
    <div class="formLabel col-md-11"ll
    *ngIf="eyeForm.controls['add_2_2139'].touched && eyeForm.controls['add_2_2139'].invalid">
    <small class="text-danger" *ngIf="eyeForm.controls['add_2_2139'].errors?.required">
        required
    </small>
    <small class="text-danger" *ngIf="eyeForm.controls['add_2_2139'].errors?.incorrectPhoneFormat">
      Required pattern xxx-xxx-xxxx
     </small>
</div>
  </td>
  <td colspan="3" rowspan="3">
    Complete address:
    <input class="form-control onboard" formControlName="add_2_987" (blur)="handleNAchange('cmd_0094', 'add_2_987')"
    [class.is-invalid]="(eyeForm.controls['add_2_987'].invalid)"
    id="add_2_987" name="add_2_987" type="text" placeholder= " Additional Hospital Affiliation 1 Address1" data-id="managing_contact_name">         
    <div class="formLabel col-md-11"ll
    *ngIf="eyeForm.controls['add_2_987'].touched && eyeForm.controls['add_2_987'].invalid">
    <small class="text-danger" *ngIf="eyeForm.controls['add_2_987'].errors?.required">
        required
    </small>
</div>
<input class="form-control onboard" formControlName="add_2_988" (blur)="handleNAchange('cmd_0094', 'add_2_988')"
[class.is-invalid]="(eyeForm.controls['add_2_988'].invalid)"
id="add_2_988" name="add_2_988" type="text" placeholder= " Additional Hospital Affiliation 1 Address 2" data-id="managing_contact_name">         
<div class="formLabel col-md-11"ll
*ngIf="eyeForm.controls['add_2_988'].touched && eyeForm.controls['add_2_988'].invalid">
<small class="text-danger" *ngIf="eyeForm.controls['add_2_988'].errors?.required">
    required
</small>
</div>
<input class="form-control onboard" formControlName="add_2_989" (blur)="handleNAchange('cmd_0094', 'add_2_989')"
[class.is-invalid]="(eyeForm.controls['add_2_989'].invalid)"
id="add_2_989" name="add_2_989" type="text" placeholder= " Additional Hospital Affiliation 1 City" data-id="managing_contact_name">         
<div class="formLabel col-md-11"ll
*ngIf="eyeForm.controls['add_2_989'].touched && eyeForm.controls['add_2_989'].invalid">
<small class="text-danger" *ngIf="eyeForm.controls['add_2_989'].errors?.required">
    required
</small>
</div>
<ng-select
    [items]="states"
    [class.is-invalid]="
      (eyeForm.controls['add_2_991'].invalid)
    "
    bindLabel="name"
    placeholder="States"
    bindValue="id"
    formControlName="add_2_991"
    (blur)="handleNAchange('cmd_0094', 'add_2_991')"
    style="width:225px"
  >
  </ng-select>       
    <div
    class="formLabel col-md-11"
    *ngIf="
      eyeForm.controls['add_2_991'].touched &&
      eyeForm.controls['add_2_991'].invalid
    "
  >
    <small
      class="text-danger"
      *ngIf="eyeForm.controls['add_2_991'].errors"
    >
      required
    </small>
  </div> 
  <input class="form-control onboard" formControlName="add_2_992"  (blur)="handleNAchange('cmd_0094', 'add_2_992')"
[class.is-invalid]="(eyeForm.controls['add_2_992'].invalid)"
id="add_2_992" name="add_2_992" type="text" placeholder= " Additional Hospital Affiliation 1 Zip" data-id="managing_contact_name">         
<div
class="formLabel col-md-2"
*ngIf="
  eyeForm.controls['add_2_992'].invalid
"
>
<small
  class="text-danger"
  *ngIf="eyeForm.controls['add_2_992'].errors"
>
  required
</small>
<small
  class="text-danger"
  *ngIf="
    eyeForm.controls['add_2_992'].errors?.invalidZipCode
  "
>
  Zip code must be atleast 5 digits and less than 10 digits
</small>
</div>
  </td>
</tr>
<tr class="border">
  <td colspan="3">
    Status (e.g. active, courtesy, provisional, allied health, etc.):
    <ng-select [items]="staffStatus" bindLabel="name" placeholder=" Additional Hospital Affiliation 1 Staff Status" bindValue="id"
    [class.is-invalid]="(eyeForm.controls['add_2_995'].invalid)"
    formControlName="add_2_995" (blur)="handleNAchange('cmd_0094', 'add_2_995')" style="width:400px">
</ng-select>
<div class="formLabel col-md-11"
    *ngIf="eyeForm.controls['add_2_995'].touched && eyeForm.controls['add_2_995'].invalid">
    <small class="text-danger" *ngIf="eyeForm.controls['add_2_995'].errors?.required">
        required
    </small>
</div>
  </td>
  <td colspan="6">
    Month/day/year of submission
    <div class="input-group-append">
      <input class="form-control onboard datepicker" formControlName="add_2_994" (blur)="handleNAchange('cmd_0094', 'add_2_994')" (blur)="clearInputIfInvalid('add_2_994','cmd_0094')"
      [class.is-invalid]="(eyeForm.controls['add_2_994'].invalid)"
      ngbDatepicker #d78="ngbDatepicker" id="add_2_994" name="add_2_994" type="text" placeholder= " Additional Hospital Affiliation 1 Initial Appointment" data-id="add_2_994">
          <button class="btn btn-outline-secondary calendar" (click)="d78.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
      </div>
      <div class="formLabel col-md-11"
          *ngIf="eyeForm.controls['add_2_994'].touched && eyeForm.controls['add_2_994'].invalid">
          <small class="text-danger" *ngIf="eyeForm.controls['add_2_994'].errors?.required">
              required
          </small>
      </div>
      <div  *ngIf="eyeForm.get('add_2_994').invalid ">
        <small class="text-danger" *ngIf="eyeForm.get('add_2_994').errors?.invalidDateFormat">
          Only Date Format is allowed(MM/DD/YYYY)                  </small>
        <small class="text-danger" *ngIf="eyeForm.get('add_2_994').errors?.invalidMonth">
        Month is out of range
        </small>
        <small class="text-danger" *ngIf="eyeForm.get('add_2_994').errors?.invalidDateyear">
          year is out of range(1900-2099)                    </small>
          <small class="text-danger" *ngIf="eyeForm.get('add_2_994').errors?.invalidDateRange">
            date is out of range
            </small>
        </div>
  </td> 
</tr>
<tr>
  <td colspan="3">
    
  </td>
</tr>
<tr class="border">
  <td colspan="3">
    Facility name:
    <input class="form-control onboard" formControlName="add_3_986" 
    id="add_3_986" name="add_3_986" type="text" placeholder= "Additional Hospital Affiliation 1 Name" data-id="managing_contact_name">         
  </td>
  <td colspan="3">
    Phone number:
    <input class="form-control onboard" formControlName="add_3_1311" (keydown)="handleNumber($event,'add_3_1311')" maxlength="12"
    id="add_3_1311" name="add_3_1311" type="text" placeholder= " Additional Hospital Affiliation 1 Phone" data-id="managing_contact_name">
    <small class="text-danger" *ngIf="eyeForm.get('add_3_1311').errors?.pattern">Required pattern xxx-xxx-xxxx</small>
         
  </td>
  <td colspan="3">
    Fax number, if available
    <input class="form-control onboard" formControlName="add_3_2139" (keydown)="handleNumber($event,'add_3_2139')" maxlength="12"
    id="add_3_2139" name="add_3_2139" type="text" placeholder= " Additional Hospital Affiliation 1 Fax" data-id="managing_contact_name">  
    <small class="text-danger" *ngIf="eyeForm.get('add_3_2139').errors?.pattern">Required pattern xxx-xxx-xxxx</small>
       
  </td>
  <td colspan="3" rowspan="3">
    Complete address:
    <input class="form-control onboard" formControlName="add_3_987" 
    id="add_3_987" name="add_3_987" type="text" placeholder= " Additional Hospital Affiliation 1 Address1" data-id="managing_contact_name">         
   
   <input class="form-control onboard" formControlName="add_3_988" 
    id="add_3_988" name="add_3_988" type="text" placeholder= " Additional Hospital Affiliation 2 Address 2" data-id="managing_contact_name">        

   <input class="form-control onboard" formControlName="add_3_989" 
   id="add_3_989" name="add_3_989" type="text" placeholder= " Additional Hospital Affiliation 1 City" data-id="managing_contact_name">         

<ng-select
    [items]="states"
   
    bindLabel="name"
    placeholder="States"
    bindValue="id"
    formControlName="add_3_991"
    style="width:225px"
    
  >
  </ng-select>       
   
   <input class="form-control onboard" formControlName="add_3_992" 
  id="add_3_992" name="add_3_992" type="text" placeholder= " Additional Hospital Affiliation 1 Zip" data-id="managing_contact_name">         
  <small
  class="text-danger"
  *ngIf="eyeForm.controls['add_3_992'].errors?.pattern"
  > Zip code must be atleast 5 digits and less than 10 digits.</small
>
  </td>
</tr>
<br>
<tr class="border">
  <td colspan="3">
    Status (e.g. active, courtesy, provisional, allied health, etc.):
    <ng-select [items]="staffStatus" bindLabel="name" placeholder=" Additional Hospital Affiliation 1 Staff Status" bindValue="id"
    formControlName="add_3_995" style="width:400px">
</ng-select>

  </td>
  <td colspan="6">
    Month/day/year of submission
    <div class="input-group-append">
      <input class="form-control onboard datepicker" formControlName="add_3_994" (blur)="clearInputIfInvalid('add_3_994')"
      ngbDatepicker #d80="ngbDatepicker" id="add_3_994" name="add_3_994" type="text" placeholder= " Additional Hospital Affiliation 1 Initial Appointment Date" data-id="add_3_994">
          <button class="btn btn-outline-secondary calendar" (click)="d80.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
      </div>
      <div  *ngIf="eyeForm.get('add_3_994').invalid ">
        <small class="text-danger" *ngIf="eyeForm.get('add_3_994').errors?.invalidDateFormat">
          Only Date Format is allowed(MM/DD/YYYY)                  </small>
        <small class="text-danger" *ngIf="eyeForm.get('add_3_994').errors?.invalidMonth">
        Month is out of range
        </small>
        <small class="text-danger" *ngIf="eyeForm.get('add_3_994').errors?.invalidDateyear">
          year is out of range(1900-2099)                    </small>
          <small class="text-danger" *ngIf="eyeForm.get('add_3_994').errors?.invalidDateRange">
            date is out of range
            </small>
        </div>
      
  </td> 
</tr>
<tr class="border">
  <td colspan="12">
          <h2  style="margin-left: 31px;  font-size: 18px;"><b>Continued - XVI. Hospital and Other Health Care Facility Affiliations</b>    
          </h2>
  </td>
</tr>
<tr class="border">
  <td colspan="12">
  <div class="col-12">
      Please list in reverse chronological order, with the current affiliation(s) first, all health care institutions where you have and/or have had clinical
privileges and/or staff membership. Include (A) current affiliations, (B) applications in process, and (C) previous hospitals, and other facility affiliations
(e.g., hospitals, surgery centers or any other health care related facility). If more space is needed, please attach additional sheets. Do not list
residencies, internships or fellowships. Please list employment in Section XVII, Professional Practice/Work History.</div>
  </td>
</tr>
<tr>
  <td colspan="12">
    <div class="centered texasFirstTable">
      <div class="row">
      <div class="col-md-8">
          <h2  style="margin-left: 31px;  font-size: 18px;"><b>C. Previous Affiliations</b>
            
          </h2>
      </div>
      <div class="col-md-4">
          <label for="cmd_0095"><b>Does Not Apply:</b></label>&nbsp;&nbsp;
          <input type="checkbox" formControlName="cmd_0095"
              id="cmd_0095" value="1"  (ngModelChange)="handleNAchange( 'cmd_0095')"
              >
  </div>
    </div>
  </div>
  </td>
</tr>
<tr class="border">
  <td colspan="3">
    Facility name:
    <input class="form-control onboard" formControlName="add_1_1183" (blur)="handleNAchange('cmd_0095', 'add_1_1183')" 
    [class.is-invalid]="(eyeForm.controls['add_1_1183'].invalid)"
    id="add_1_1183" name="add_1_1183" type="text" placeholder= "Previous Hospital Affiliation Name" data-id="managing_contact_name">         
    <div class="formLabel col-md-11"ll
    *ngIf="eyeForm.controls['add_1_1183'].touched && eyeForm.controls['add_1_1183'].invalid">
    <small class="text-danger" *ngIf="eyeForm.controls['add_1_1183'].errors?.required">
        required
    </small>
</div>
  </td>
  <td colspan="3">
    Phone number:
    <input class="form-control onboard" formControlName="add_1_1313" (keydown)="handleNumber($event,'add_1_1313')" maxlength="12" (blur)="handleNAchange('cmd_0095', 'add_1_1313')"
    [class.is-invalid]="(eyeForm.controls['add_1_1313'].invalid)"
    id="add_1_1313" name="add_1_1313" type="text" placeholder= "Previous Hospital Affiliation Phone" data-id="managing_contact_name">         
    <div class="formLabel col-md-11"ll
    *ngIf="eyeForm.controls['add_1_1313'].touched && eyeForm.controls['add_1_1313'].invalid">
    <small class="text-danger" *ngIf="eyeForm.controls['add_1_1313'].errors?.required">
        required
    </small>
    <small class="text-danger" *ngIf="eyeForm.controls['add_1_1313'].errors?.incorrectPhoneFormat">
      Required pattern xxx-xxx-xxxx
     </small>
</div>
  </td>
  <td colspan="3">
    Fax number, if available
    <input class="form-control onboard" formControlName="add_1_2140" (keydown)="handleNumber($event,'add_1_2140')" maxlength="12" (blur)="handleNAchange('cmd_0095', 'add_1_2140')"
    [class.is-invalid]="(eyeForm.controls['add_1_2140'].invalid)"
    id="add_1_2140" name="add_1_2140" type="text" placeholder= " Previous Hospital Affiliation Fax" data-id="managing_contact_name">         
    <div class="formLabel col-md-11"ll
    *ngIf="eyeForm.controls['add_1_2140'].touched && eyeForm.controls['add_1_2140'].invalid">
    <small class="text-danger" *ngIf="eyeForm.controls['add_1_2140'].errors?.required">
        required
    </small>
    <small class="text-danger" *ngIf="eyeForm.controls['add_1_2140'].errors?.incorrectPhoneFormat">
      Required pattern xxx-xxx-xxxx
     </small>
</div>
  </td>
  <td colspan="3" rowspan="3">
    Complete address:
    <input class="form-control onboard" formControlName="add_1_1184" (blur)="handleNAchange('cmd_0095', 'add_1_1184')"
    [class.is-invalid]="(eyeForm.controls['add_1_1184'].invalid)"
    id="add_1_1184" name="add_1_1184" type="text" placeholder= " Previous Hospital Affiliation Address" data-id="managing_contact_name">         
    <div class="formLabel col-md-11"ll
    *ngIf="eyeForm.controls['add_1_1184'].touched && eyeForm.controls['add_1_1184'].invalid">
    <small class="text-danger" *ngIf="eyeForm.controls['add_1_1184'].errors?.required">
        required
    </small>
</div>
<input class="form-control onboard" formControlName="add_1_1185" (blur)="handleNAchange('cmd_0095', 'add_1_1185')"
[class.is-invalid]="(eyeForm.controls['add_1_1185'].invalid)"
id="add_1_1185" name="add_1_1185" type="text" placeholder= "Previous Hospital Affiliation Address2" data-id="managing_contact_name">         
<div class="formLabel col-md-11"ll
*ngIf="eyeForm.controls['add_1_1185'].touched && eyeForm.controls['add_1_1185'].invalid">
<small class="text-danger" *ngIf="eyeForm.controls['add_1_1185'].errors?.required">
    required
</small>
</div>
<input class="form-control onboard" formControlName="add_1_1186" (blur)="handleNAchange('cmd_0095', 'add_1_1186')"
[class.is-invalid]="(eyeForm.controls['add_1_1186'].invalid)"
id="add_1_1186" name="add_1_1186" type="text" placeholder= "Previous Hospital Affiliation City" data-id="managing_contact_name">         
<div class="formLabel col-md-11"ll
*ngIf="eyeForm.controls['add_1_1186'].touched && eyeForm.controls['add_1_1186'].invalid">
<small class="text-danger" *ngIf="eyeForm.controls['add_1_1186'].errors?.required">
    required
</small>
</div>
<ng-select
    [items]="states"
    [class.is-invalid]="
      (eyeForm.controls['add_1_1188'].invalid)
    "
    bindLabel="name"
    placeholder="States"
    bindValue="id"
    formControlName="add_1_1188"
    (blur)="handleNAchange('cmd_0095', 'add_1_1188')"
    style="width:225px"
  >
  </ng-select>       
    <div
    class="formLabel col-md-11"
    *ngIf="
      eyeForm.controls['add_1_1188'].touched &&
      eyeForm.controls['add_1_1188'].invalid
    "
  >
    <small
      class="text-danger"
      *ngIf="eyeForm.controls['add_1_1188'].errors"
    >
      required
    </small>
  </div> 
  <input class="form-control onboard" formControlName="add_1_1189"  (blur)="handleNAchange('cmd_0095', 'add_1_1189')"
[class.is-invalid]="(eyeForm.controls['add_1_1189'].invalid)"
id="add_1_1189" name="add_1_1189" type="text" placeholder= " Previous Hospital Affiliation Zip" data-id="managing_contact_name">         
<div
  class="formLabel col-md-2"
  *ngIf="
    eyeForm.controls['add_1_1189'].invalid
  "
  >
  <small
    class="text-danger"
    *ngIf="eyeForm.controls['add_1_1189'].errors"
  >
    required
  </small>
  <small
    class="text-danger"
    *ngIf="
      eyeForm.controls['add_1_1189'].errors?.invalidZipCode
    "
  >
    Zip code must be atleast 5 digits and less than 10 digits
  </small>
  </div>
  </td>
</tr>
<tr class="border">
  <td colspan="3">
    From month / day / year:
    <div class="input-group-append">
      <input class="form-control onboard datepicker" formControlName="add_1_1191" (blur)="handleNAchange('cmd_0095', 'add_1_1191')" (blur)="clearInputIfInvalid('add_1_1191','cmd_0095')"
      [class.is-invalid]="(eyeForm.controls['add_1_1191'].invalid)"
      ngbDatepicker #d98="ngbDatepicker" id="add_1_1191" name="add_1_1191" type="text" placeholder= " Previous Hospital Affiliation Initial Appointment" data-id="add_1_1191">
          <button class="btn btn-outline-secondary calendar" (click)="d98.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
      </div>
      <div class="formLabel col-md-11"
          *ngIf="eyeForm.controls['add_1_1191'].touched && eyeForm.controls['add_1_1191'].invalid">
          <small class="text-danger" *ngIf="eyeForm.controls['add_1_1191'].errors?.required">
              required
          </small>
      </div>
      <div  *ngIf="eyeForm.get('add_1_1191').invalid ">
        <small class="text-danger" *ngIf="eyeForm.get('add_1_1191').errors?.invalidDateFormat">
          Only Date Format is allowed(MM/DD/YYYY)                  </small>
        <small class="text-danger" *ngIf="eyeForm.get('add_1_1191').errors?.invalidMonth">
        Month is out of range
        </small>
        <small class="text-danger" *ngIf="eyeForm.get('add_1_1191').errors?.invalidDateyear">
          year is out of range(1900-2099)                    </small>
          <small class="text-danger" *ngIf="eyeForm.get('add_1_1191').errors?.invalidDateRange">
            date is out of range
            </small>
        </div>
  </td> 
  <td colspan="6">
    To month / day / year:
    <div class="input-group-append">
      <input class="form-control onboard datepicker" formControlName="add_1_2381" (blur)="handleNAchange('cmd_0095', 'add_1_2381')" (blur)="clearInputIfInvalid('add_1_2381','cmd_0095')"
      [class.is-invalid]="(eyeForm.controls['add_1_2381'].invalid)"
      ngbDatepicker #d99="ngbDatepicker" id="add_1_2381" name="add_1_2381" type="text" placeholder= "Previous Hospital Affiliation End Date" data-id="add_1_2381">
          <button class="btn btn-outline-secondary calendar" (click)="d99.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
      </div>
      <div class="formLabel col-md-11"
          *ngIf="eyeForm.controls['add_1_2381'].touched && eyeForm.controls['add_1_2381'].invalid">
          <small class="text-danger" *ngIf="eyeForm.controls['add_1_2381'].errors?.required">
              required
          </small>
      </div>
      <div  *ngIf="eyeForm.get('add_1_2381').invalid ">
        <small class="text-danger" *ngIf="eyeForm.get('add_1_2381').errors?.invalidDateFormat">
          Only Date Format is allowed(MM/DD/YYYY)                  </small>
        <small class="text-danger" *ngIf="eyeForm.get('add_1_2381').errors?.invalidMonth">
        Month is out of range
        </small>
        <small class="text-danger" *ngIf="eyeForm.get('add_1_2381').errors?.invalidDateyear">
          year is out of range(1900-2099)                    </small>
          <small class="text-danger" *ngIf="eyeForm.get('add_1_2381').errors?.invalidDateRange">
            date is out of range
            </small>
        </div>
  </td> 
</tr>
<tr class="border">
  <td colspan="3">
    Professional liability carrier: 
    <input class="form-control onboard" formControlName="cmd_0096" 
    id="cmd_0096" name="cmd_0096" type="text" placeholder= "" data-id="managing_contact_name">         
  </td>
  <td colspan="6">
    Reason for leaving:
    <input class="form-control onboard" formControlName="add_1_1303" (blur)="handleNAchange('cmd_0095', 'add_1_1303')"
    [class.is-invalid]="(eyeForm.controls['add_1_1303'].touched && eyeForm.controls['add_1_1303'].invalid) || (eyeForm.controls['add_1_1303'].invalid && isSubmit)"
    id="add_1_1303" name="add_1_1303" type="text" placeholder= "Previous Hospital Affiliation Reason for Leaving" data-id="managing_contact_name">         
    <div class="formLabel col-md-11"ll
    *ngIf="eyeForm.controls['add_1_1303'].touched && eyeForm.controls['add_1_1303'].invalid">
    <small class="text-danger" *ngIf="eyeForm.controls['add_1_1303'].errors?.required">
        required
    </small>
    </div>
  </td>
</tr>
<tr class="border">
  <td colspan="3">
    Facility name:
    <input class="form-control onboard" formControlName="add_2_1183" 
    id="add_2_1183" name="add_2_1183" type="text" placeholder= "Previous Hospital Affiliation Name" data-id="managing_contact_name">         
    
  </td>
  <td colspan="3">
    Phone number:
    <input class="form-control onboard" formControlName="add_2_1313" (keydown)="handleNumber($event,'add_2_1313')" maxlength="12"
    id="add_2_1313" name="add_2_1313" type="text" placeholder= "Previous Hospital Affiliation Phone" data-id="managing_contact_name">         
    <small class="text-danger" *ngIf="eyeForm.get('add_2_1313').errors?.pattern">Required pattern xxx-xxx-xxxx</small>

  </td>
  <td colspan="3">
    Fax number, if available
    <input class="form-control onboard" formControlName="add_2_2140" (keydown)="handleNumber($event,'add_2_2140')" maxlength="12"
    id="add_2_2140" name="add_2_2140" type="text" placeholder= " Previous Hospital Affiliation Fax" data-id="managing_contact_name">         
    <small class="text-danger" *ngIf="eyeForm.get('add_2_2140').errors?.pattern">Required pattern xxx-xxx-xxxx</small>

  </td>
  <td colspan="3" rowspan="3">
    Complete address:
    <input class="form-control onboard" formControlName="add_2_1184"
    id="add_2_1184" name="add_2_1184" type="text" placeholder= " Previous Hospital Affiliation Address" data-id="managing_contact_name">         
   
<input class="form-control onboard" formControlName="add_2_1185"
id="add_2_1185" name="add_2_1185" type="text" placeholder= "Previous Hospital Affiliation Address2" data-id="managing_contact_name">         

<input class="form-control onboard" formControlName="add_2_1186" 
id="add_2_1186" name="add_2_1186" type="text" placeholder= "Previous Hospital Affiliation City" data-id="managing_contact_name">         

<ng-select
    [items]="states"
    
    bindLabel="name"
    placeholder="States"
    bindValue="id"
    formControlName="add_2_1188"
    style="width:225px"
  >
  </ng-select>       
  <input class="form-control onboard" formControlName="add_2_1189"  
id="add_2_1189" name="add_2_1189" type="text" placeholder= " Previous Hospital Affiliation Zip" data-id="managing_contact_name">         
<small
        class="text-danger"
        *ngIf="eyeForm.controls['add_2_1189'].errors?.pattern"
        > Zip code must be atleast 5 digits and less than 10 digits.</small
      >
  </td>
</tr>
<tr class="border">
  <td colspan="3">
    From month / day / year:
    <div class="input-group-append">
      <input class="form-control onboard datepicker" formControlName="add_2_1191" (blur)="clearInputIfInvalid('add_2_1191')"
      ngbDatepicker #d102="ngbDatepicker" id="add_2_1191" name="add_2_1191" type="text" placeholder= " Previous Hospital Affiliation Initial Appointment" data-id="add_2_1191">
          <button class="btn btn-outline-secondary calendar" (click)="d102.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
      </div>
      <div  *ngIf="eyeForm.get('add_2_1191').invalid ">
        <small class="text-danger" *ngIf="eyeForm.get('add_2_1191').errors?.invalidDateFormat">
          Only Date Format is allowed(MM/DD/YYYY)                  </small>
        <small class="text-danger" *ngIf="eyeForm.get('add_2_1191').errors?.invalidMonth">
        Month is out of range
        </small>
        <small class="text-danger" *ngIf="eyeForm.get('add_2_1191').errors?.invalidDateyear">
          year is out of range(1900-2099)                    </small>
          <small class="text-danger" *ngIf="eyeForm.get('add_2_1191').errors?.invalidDateRange">
            date is out of range
            </small>
        </div>
     
  </td> 
  <td colspan="6">
    To month / day / year:
    <div class="input-group-append">
      <input class="form-control onboard datepicker" formControlName="add_2_2381" (blur)="clearInputIfInvalid('add_2_2381')"
      ngbDatepicker #d101="ngbDatepicker" id="add_2_2381" name="add_2_2381" type="text" placeholder= "Previous Hospital Affiliation End Date" data-id="add_2_2381">
          <button class="btn btn-outline-secondary calendar" (click)="d101.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
      </div>
      <div  *ngIf="eyeForm.get('add_2_2381').invalid ">
        <small class="text-danger" *ngIf="eyeForm.get('add_2_2381').errors?.invalidDateFormat">
          Only Date Format is allowed(MM/DD/YYYY)                  </small>
        <small class="text-danger" *ngIf="eyeForm.get('add_2_2381').errors?.invalidMonth">
        Month is out of range
        </small>
        <small class="text-danger" *ngIf="eyeForm.get('add_2_2381').errors?.invalidDateyear">
          year is out of range(1900-2099)                    </small>
          <small class="text-danger" *ngIf="eyeForm.get('add_2_2381').errors?.invalidDateRange">
            date is out of range
            </small>
        </div>
  </td> 
</tr>
<tr class="border">
  <td colspan="3">
    Professional liability carrier: 
    <input class="form-control onboard" formControlName="cmd_0097" 
    id="cmd_0097" name="cmd_0097" type="text" placeholder= "" data-id="managing_contact_name">         
  </td>
  <td colspan="6">
    Reason for leaving:
    <input class="form-control onboard" formControlName="add_2_1303" 
    id="add_2_1303" name="add_2_1303" type="text" placeholder= "Previous Hospital Affiliation Reason for Leaving" data-id="managing_contact_name">         
    
  </td>
</tr>
<tr class="border">
  <td colspan="3">
    Facility name:
    <input class="form-control onboard" formControlName="add_3_1183" 
    id="add_3_1183" name="add_3_1183" type="text" placeholder= "Previous Hospital Affiliation Name" data-id="managing_contact_name">         
    
  </td>
  <td colspan="3">
    Phone number:
    <input class="form-control onboard" formControlName="add_3_1313" (keydown)="handleNumber($event,'add_3_1313')" maxlength="12"
    id="add_3_1313" name="add_3_1313" type="text" placeholder= "Previous Hospital Affiliation Phone" data-id="managing_contact_name">         
    <small class="text-danger" *ngIf="eyeForm.get('add_3_1313').errors?.pattern">Required pattern xxx-xxx-xxxx</small>

  </td>
  <td colspan="3">
    Fax number, if available
    <input class="form-control onboard" formControlName="add_3_2140" (keydown)="handleNumber($event,'add_3_2140')" maxlength="12"
    id="add_3_2140" name="add_3_2140" type="text" placeholder= " Previous Hospital Affiliation Fax" data-id="managing_contact_name">         
    <small class="text-danger" *ngIf="eyeForm.get('add_3_2140').errors?.pattern">Required pattern xxx-xxx-xxxx</small>

  </td>
  <td colspan="3" rowspan="3">
    Complete address:
    <input class="form-control onboard" formControlName="add_3_1184"
    id="add_3_1184" name="add_3_1184" type="text" placeholder= " Previous Hospital Affiliation Address" data-id="managing_contact_name">         
   
<input class="form-control onboard" formControlName="add_3_1185"
id="add_3_1185" name="add_3_1185" type="text" placeholder= "Previous Hospital Affiliation Address2" data-id="managing_contact_name">         

<input class="form-control onboard" formControlName="add_3_1186" 
id="add_3_1186" name="add_3_1186" type="text" placeholder= "Previous Hospital Affiliation City" data-id="managing_contact_name">         

<ng-select
    [items]="states"
    
    bindLabel="name"
    placeholder="States"
    bindValue="id"
    formControlName="add_3_1188"
    style="width:225px"
  >
  </ng-select>       
  <input class="form-control onboard" formControlName="add_3_1189"  
id="add_3_1189" name="add_3_1189" type="text" placeholder= " Previous Hospital Affiliation Zip" data-id="managing_contact_name">         
<small
        class="text-danger"
        *ngIf="eyeForm.controls['add_3_1189'].errors?.pattern"
        > Zip code must be atleast 5 digits and less than 10 digits.</small
      >
  </td>
</tr>
<tr class="border">
  <td colspan="3">
    From month / day / year:
    <div class="input-group-append">
      <input class="form-control onboard datepicker" formControlName="add_3_1191" (blur)="clearInputIfInvalid('add_3_1191')"
      ngbDatepicker #d104="ngbDatepicker" id="add_3_1191" name="add_3_1191" type="text" placeholder= " Previous Hospital Affiliation Initial Appointment" data-id="add_3_1191">
          <button class="btn btn-outline-secondary calendar" (click)="d104.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
      </div>
      <div  *ngIf="eyeForm.get('add_3_1191').invalid ">
        <small class="text-danger" *ngIf="eyeForm.get('add_3_1191').errors?.invalidDateFormat">
          Only Date Format is allowed(MM/DD/YYYY)                  </small>
        <small class="text-danger" *ngIf="eyeForm.get('add_3_1191').errors?.invalidMonth">
        Month is out of range
        </small>
        <small class="text-danger" *ngIf="eyeForm.get('add_3_1191').errors?.invalidDateyear">
          year is out of range(1900-2099)                    </small>
          <small class="text-danger" *ngIf="eyeForm.get('add_3_1191').errors?.invalidDateRange">
            date is out of range
            </small>
        </div>
  </td> 
  <td colspan="6">
    To month / day / year:
    <div class="input-group-append">
      <input class="form-control onboard datepicker" formControlName="add_3_2381" (blur)="clearInputIfInvalid('add_3_2381')"
      ngbDatepicker #d103="ngbDatepicker" id="add_3_2381" name="add_3_2381" type="text" placeholder= "Previous Hospital Affiliation End Date" data-id="add_3_2381">
          <button class="btn btn-outline-secondary calendar" (click)="d103.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
      </div>
      <div  *ngIf="eyeForm.get('add_3_2381').invalid ">
        <small class="text-danger" *ngIf="eyeForm.get('add_3_2381').errors?.invalidDateFormat">
          Only Date Format is allowed(MM/DD/YYYY)                  </small>
        <small class="text-danger" *ngIf="eyeForm.get('add_3_2381').errors?.invalidMonth">
        Month is out of range
        </small>
        <small class="text-danger" *ngIf="eyeForm.get('add_3_2381').errors?.invalidDateyear">
          year is out of range(1900-2099)                    </small>
          <small class="text-danger" *ngIf="eyeForm.get('add_3_2381').errors?.invalidDateRange">
            date is out of range
            </small>
        </div>
  </td> 
</tr>
<tr class="border">
  <td colspan="3">
    Professional liability carrier: 
    <input class="form-control onboard" formControlName="cmd_0098" 
    id="cmd_0098" name="cmd_0098" type="text" placeholder= "" data-id="managing_contact_name">         
  </td>
  <td colspan="6">
    Reason for leaving:
    <input class="form-control onboard" formControlName="add_3_1303" 
    id="add_3_1303" name="add_3_1303" type="text" placeholder= "Previous Hospital Affiliation Reason for Leaving" data-id="managing_contact_name">         
    
  </td>
</tr>
<tr>
  <td colspan="12">
    <div class="centered texasFirstTable">
      <div class="row">
      <div class="col-md-8">
          <h2  style="margin-left: 31px;  font-size: 18px;"><b>XVII. Professional Practice / Work History</b>
            
          </h2>
          <h3 style="margin-left: 31px;  font-size: 15px; "> Curriculum vitae is not sufficient </h3>
      </div>
      <div class="col-md-4">
          <label for="cmd_0101"><b>Does Not Apply:</b></label>&nbsp;&nbsp;
          <input type="checkbox" formControlName="cmd_0101"
              id="cmd_0101" value="1"  (ngModelChange)="handleNAchange( 'cmd_0101')"
              >
  </div>
    </div>
  </div>
  </td>
</tr>
<tr class="border">
  <td colspan="12">
  <div class="col-12">
    <h6>A. Please account for all periods of time from the date of entry into medical/professional school to present. Chronologically
    list all work, professional and practice history activities since completion of postgraduate training, including military
    service. Please explain in section B any gaps greater than two (2) months. (Please attach additional sheets, if necessary.)</h6></div>
  </td>
</tr>
<tr class="border">
  <td colspan="2">
    Name of practice / employer:
    <input class="form-control onboard" formControlName="445" (blur)="handleNAchange('cmd_0101', 445)" 
    [class.is-invalid]="(eyeForm.controls['445'].invalid)"
    id="445" name="445" type="text" placeholder= "Work History 1 Location " data-id="managing_contact_name">         
    <div class="formLabel col-md-11"ll
    *ngIf="eyeForm.controls['445'].touched && eyeForm.controls['445'].invalid">
    <small class="text-danger" *ngIf="eyeForm.controls['445'].errors?.required">
        required
    </small>
</div>
  </td>
  <td colspan="1">
    Contact’s name:
    <input class="form-control onboard" formControlName="452" (blur)="handleNAchange('cmd_0101', 452)"
    [class.is-invalid]="(eyeForm.controls['452'].invalid)"
    id="452" name="452" type="text" placeholder= "Work History 1 Contact" data-id="managing_contact_name">         
    <div class="formLabel col-md-11"ll
    *ngIf="eyeForm.controls['452'].touched && eyeForm.controls['452'].invalid">
    <small class="text-danger" *ngIf="eyeForm.controls['452'].errors?.required">
        required
    </small>
</div>
  </td>
  <td colspan="2">
    Telephone number:
    <input class="form-control onboard" formControlName="453" (keydown)="handleNumber($event, 453 )" maxlength="12" (blur)="handleNAchange('cmd_0101', 453)"
    [class.is-invalid]="(eyeForm.controls['453'].invalid)"
    id="453" name="453" type="text" placeholder= "Work History 1 Telephone" data-id="managing_contact_name">         
    <div class="formLabel col-md-11"ll
    *ngIf="eyeForm.controls['453'].touched && eyeForm.controls['453'].invalid">
    <small class="text-danger" *ngIf="eyeForm.controls['453'].errors?.required">
        required
    </small>
    <!-- <small class="text-danger" *ngIf="eyeForm.controls['453'].errors?.incorrectPhoneFormat">
      Required pattern xxx-xxx-xxxx
     </small> -->
     <small class="text-danger" *ngIf="eyeForm.get('453').errors?.incorrectPhoneFormat">Required pattern xxx-xxx-xxxx</small>

</div>
  </td>
  <td colspan="2">
    Fax number:
    <input class="form-control onboard" formControlName="888" (keydown)="handleNumber($event, 888 )" maxlength="12" (blur)="handleNAchange('cmd_0101', 888)"
    [class.is-invalid]="(eyeForm.controls['888'].invalid)"
    id="888" name="888" type="text" placeholder= " Work History 1 Fax" data-id="managing_contact_name">         
    <div class="formLabel col-md-11"ll
    *ngIf="eyeForm.controls['888'].touched && eyeForm.controls['888'].invalid">
    <small class="text-danger" *ngIf="eyeForm.controls['888'].errors?.required">
        required
    </small>
    <small class="text-danger" *ngIf="eyeForm.get('888').errors?.incorrectPhoneFormat">Required pattern xxx-xxx-xxxx</small>

</div>
  </td>
  <td colspan="6" rowspan="3">
    Complete address:
    <input class="form-control onboard" formControlName="446"  (blur)="handleNAchange('cmd_0101', 446)"
    [class.is-invalid]="(eyeForm.controls['446'].invalid)"
    id="446" name="446" type="text" placeholder= " Work History 1 Address1" data-id="managing_contact_name">         
    <div class="formLabel col-md-11"ll
    *ngIf="eyeForm.controls['446'].touched && eyeForm.controls['446'].invalid">
    <small class="text-danger" *ngIf="eyeForm.controls['446'].errors?.required">
        required
    </small>
</div>
<input class="form-control onboard" formControlName="447"  (blur)="handleNAchange('cmd_0101', 447)"
[class.is-invalid]="(eyeForm.controls['447'].invalid)"
id="447" name="447" type="text" placeholder= " Work History 1 Address2" data-id="managing_contact_name">         
<div class="formLabel col-md-11"ll
*ngIf="eyeForm.controls['447'].touched && eyeForm.controls['447'].invalid">
<small class="text-danger" *ngIf="eyeForm.controls['447'].errors?.required">
    required
</small>
</div>
<input class="form-control onboard" formControlName="448" (blur)="handleNAchange('cmd_0101', 448)"
[class.is-invalid]="(eyeForm.controls['448'].invalid)"
id="448" name="448" type="text" placeholder= "Work History 1 City" data-id="managing_contact_name">         
<div class="formLabel col-md-11"ll
*ngIf="eyeForm.controls['448'].touched && eyeForm.controls['448'].invalid">
<small class="text-danger" *ngIf="eyeForm.controls['448'].errors?.required">
    required
</small>
</div>
<ng-select
    [items]="states"
    [class.is-invalid]="
      (eyeForm.controls['450'].invalid)
    "
    bindLabel="name"
    placeholder="States"
    bindValue="id"
    formControlName="450"
    (blur)="handleNAchange('cmd_0101', 450)"
    style="width:280px"
  >
  </ng-select>       
    <div
    class="formLabel col-md-11"
    *ngIf="
      eyeForm.controls['450'].touched &&
      eyeForm.controls['450'].invalid
    "
  >
    <small
      class="text-danger"
      *ngIf="eyeForm.controls['450'].errors"
    >
      required
    </small>
  </div> 
  <input class="form-control onboard" formControlName="451" (blur)="handleNAchange('cmd_0101', 451)"
[class.is-invalid]="(eyeForm.controls['451'].invalid)"
id="451" name="451" type="text" placeholder= " Work History 1 Zip" data-id="managing_contact_name">         
<div
class="formLabel col-md-2"
*ngIf="
  eyeForm.controls['451'].invalid
"
>
<small
  class="text-danger"
  *ngIf="eyeForm.controls['451'].errors"
>
  required
</small>
<small
  class="text-danger"
  *ngIf="
    eyeForm.controls['451'].errors?.invalidZipCode
  "
>
  Zip code must be atleast 5 digits and less than 10 digits
</small>
</div>
  </td>
</tr>
<tr class="border">
  <td colspan="3">
    From month / day / year:
    <div class="input-group-append">
      <input class="form-control onboard datepicker" formControlName="454" (blur)="handleNAchange('cmd_0101', 454)" (blur)="clearInputIfInvalid('454','cmd_0101')"
      [class.is-invalid]="(eyeForm.controls['454'].invalid)"
      ngbDatepicker #d105="ngbDatepicker" id="454" name="454" type="text" placeholder= "Work History 1 Start Date" data-id="454">
          <button class="btn btn-outline-secondary calendar" (click)="d105.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
      </div>
      <div class="formLabel col-md-11"
          *ngIf="eyeForm.controls['454'].touched && eyeForm.controls['454'].invalid">
          <small class="text-danger" *ngIf="eyeForm.controls['454'].errors?.required">
              required
          </small>
      </div>
      <div  *ngIf="eyeForm.get('454').invalid ">
        <small class="text-danger" *ngIf="eyeForm.get('454').errors?.invalidDateFormat">
          Only Date Format is allowed(MM/DD/YYYY)                  </small>
        <small class="text-danger" *ngIf="eyeForm.get('454').errors?.invalidMonth">
        Month is out of range
        </small>
        <small class="text-danger" *ngIf="eyeForm.get('454').errors?.invalidDateyear">
          year is out of range(1900-2099)                    </small>
          <small class="text-danger" *ngIf="eyeForm.get('454').errors?.invalidDateRange">
            date is out of range
            </small>
        </div>
  </td> 
  <td colspan="4">
    To month / day / year:
    <div class="input-group-append">
      <input class="form-control onboard datepicker" formControlName="455" (blur)="handleNAchange('cmd_0101', 455)" (blur)="clearInputIfInvalid('455','cmd_0101')"
      [class.is-invalid]="(eyeForm.controls['455'].invalid)"
      ngbDatepicker #d108="ngbDatepicker" id="455" name="455" type="text" placeholder= "Work History 1 End Date" data-id="455">
          <button class="btn btn-outline-secondary calendar" (click)="d108.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
      </div>
      <div class="formLabel col-md-11"
          *ngIf="eyeForm.controls['455'].touched && eyeForm.controls['455'].invalid">
          <small class="text-danger" *ngIf="eyeForm.controls['455'].errors?.required">
              required
          </small>
      </div>
      <div  *ngIf="eyeForm.get('455').invalid ">
        <small class="text-danger" *ngIf="eyeForm.get('455').errors?.invalidDateFormat">
          Only Date Format is allowed(MM/DD/YYYY)                  </small>
        <small class="text-danger" *ngIf="eyeForm.get('455').errors?.invalidMonth">
        Month is out of range
        </small>
        <small class="text-danger" *ngIf="eyeForm.get('455').errors?.invalidDateyear">
          year is out of range(1900-2099)                    </small>
          <small class="text-danger" *ngIf="eyeForm.get('455').errors?.invalidDateRange">
            date is out of range
            </small>
        </div>
  </td> 
</tr>
<tr class="border">
  <td colspan="4">
    Contact’s email address, if available:
    <input class="form-control onboard" formControlName="889" (blur)="handleNAchange('cmd_0101', 889)"
    [class.is-invalid]="(eyeForm.controls['889'].invalid)"
    id="889" name="889" type="text" placeholder= "Work History 1 Email" data-id="managing_contact_name">         
    <div class="formLabel col-md-11"ll
    *ngIf="eyeForm.controls['889'].touched && eyeForm.controls['889'].invalid">
    <small class="text-danger" *ngIf="eyeForm.controls['889'].errors?.required">
        required
    </small>
    </div>
    <small
    class="text-danger"
    *ngIf="eyeForm.controls['889'].invalid"
    >Please enter a valid email.</small
    >
  </td>
  <td colspan="3">
    Professional liability carrier: 
    <input class="form-control onboard" formControlName="cmd_0099" 
    id="cmd_0099" name="cmd_0099" type="text" placeholder= "" data-id="managing_contact_name">         
  </td>
</tr>
<tr class="border">
  <td colspan="2">
    Name of practice / employer:
    <input class="form-control onboard" formControlName="456" 
    id="456" name="456" type="text" placeholder= "Work History 2 Location " data-id="managing_contact_name">         
  
  </td>
  <td colspan="1">
    Contact’s name:
    <input class="form-control onboard" formControlName="463" 
    id="463" name="463" type="text" placeholder= "Work History 2 Contact" data-id="managing_contact_name">         
  
  </td>
  <td colspan="2">
    Telephone number:
    <input class="form-control onboard" formControlName="464" (keydown)="handleNumber($event, 464 )" maxlength="12"
    id="464" name="464" type="text" placeholder= "Work History 2 Telephone" data-id="managing_contact_name"> 
    <small class="text-danger" *ngIf="eyeForm.get('464').errors?.pattern">Required pattern xxx-xxx-xxxx</small>
        
  </td>
  <td colspan="2">
    Fax number:
    <input class="form-control onboard" formControlName="890" (keydown)="handleNumber($event, 890 )" maxlength="12"
    id="890" name="890" type="text" placeholder= " Work History 2 Fax" data-id="managing_contact_name">   
    <small class="text-danger" *ngIf="eyeForm.get('890').errors?.pattern">Required pattern xxx-xxx-xxxx</small>
      
  </td>
  <td colspan="6" rowspan="3">
    Complete address:
    <input class="form-control onboard" formControlName="457"  
    id="457" name="457" type="text" placeholder= " Work History 2 Address1" data-id="managing_contact_name">         
<input class="form-control onboard" formControlName="458"  
id="458" name="458" type="text" placeholder= " Work History 2 Address2" data-id="managing_contact_name">         
<input class="form-control onboard" formControlName="459" 
id="459" name="459" type="text" placeholder= "Work History 2 City" data-id="managing_contact_name">         

<ng-select
    [items]="states"
    bindLabel="name"
    placeholder="States"
    bindValue="id"
    formControlName="461"
    style="width:280px"
   
  >
  </ng-select>       
   
  <input class="form-control onboard" formControlName="462" 
id="462" name="462" type="text" placeholder= " Work History 2 Zip" data-id="managing_contact_name">         
<small
class="text-danger"
*ngIf="eyeForm.controls['462'].errors?.pattern"
> Zip code must be atleast 5 digits and less than 10 digits.</small
>  
</td>
</tr>
<tr class="border">
  <td colspan="3">
    From month / day / year:
    <div class="input-group-append">
      <input class="form-control onboard datepicker" formControlName="465" (blur)="clearInputIfInvalid('465')"
      ngbDatepicker #d111="ngbDatepicker" id="465" name="465" type="text" placeholder= "Work History 2 Start Date" data-id="465">
          <button class="btn btn-outline-secondary calendar" (click)="d111.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
      </div>
      <div  *ngIf="eyeForm.get('465').invalid ">
        <small class="text-danger" *ngIf="eyeForm.get('465').errors?.invalidDateFormat">
          Only Date Format is allowed(MM/DD/YYYY)                  </small>
        <small class="text-danger" *ngIf="eyeForm.get('465').errors?.invalidMonth">
        Month is out of range
        </small>
        <small class="text-danger" *ngIf="eyeForm.get('465').errors?.invalidDateyear">
          year is out of range(1900-2099)                    </small>
          <small class="text-danger" *ngIf="eyeForm.get('465').errors?.invalidDateRange">
            date is out of range
            </small>
        </div>
  </td> 
  <td colspan="4">
    To month / day / year:
    <div class="input-group-append">
      <input class="form-control onboard datepicker" formControlName="466" (blur)="clearInputIfInvalid('466')"
      ngbDatepicker #d110="ngbDatepicker" id="466" name="466" type="text" placeholder= "Work History 2 End Date" data-id="466">
          <button class="btn btn-outline-secondary calendar" (click)="d110.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
      </div>
      <div  *ngIf="eyeForm.get('466').invalid ">
        <small class="text-danger" *ngIf="eyeForm.get('466').errors?.invalidDateFormat">
          Only Date Format is allowed(MM/DD/YYYY)                  </small>
        <small class="text-danger" *ngIf="eyeForm.get('466').errors?.invalidMonth">
        Month is out of range
        </small>
        <small class="text-danger" *ngIf="eyeForm.get('466').errors?.invalidDateyear">
          year is out of range(1900-2099)                    </small>
          <small class="text-danger" *ngIf="eyeForm.get('466').errors?.invalidDateRange">
            date is out of range
            </small>
        </div>
  </td> 
</tr>
<tr class="border">
  <td colspan="4">
    Contact’s email address, if available:
    <input class="form-control onboard" formControlName="891" 
    id="891" name="891" type="text" placeholder= "Work History 2 Email" data-id="managing_contact_name"> 
    <small
    class="text-danger"
    *ngIf="eyeForm.controls['891'].errors?.pattern"
    >Please enter a valid email.</small
    >        
  </td>
  <td colspan="3">
    Professional liability carrier: 
    <input class="form-control onboard" formControlName="cmd_00102" 
    id="cmd_00102" name="cmd_00102" type="text" placeholder= "" data-id="managing_contact_name">         
  </td>
</tr>
<tr class="border">
  <td colspan="2">
    Name of practice / employer:
    <input class="form-control onboard" formControlName="467" 
    id="467" name="467" type="text" placeholder= "Work History 3 Location " data-id="managing_contact_name">         
  
  </td>
  <td colspan="1">
    Contact’s name:
    <input class="form-control onboard" formControlName="474" 
    id="474" name="474" type="text" placeholder= "Work History 3 Contact" data-id="managing_contact_name">         
  
  </td>
  <td colspan="2">
    Telephone number:
    <input class="form-control onboard" formControlName="475" (keydown)="handleNumber($event, 475 )" maxlength="12"
    id="475" name="475" type="text" placeholder= "Work History 3 Telephone" data-id="managing_contact_name"> 
    <small class="text-danger" *ngIf="eyeForm.get('475').errors?.pattern">Required pattern xxx-xxx-xxxx</small>
        
  </td>
  <td colspan="2">
    Fax number:
    <input class="form-control onboard" formControlName="892" (keydown)="handleNumber($event, 892 )" maxlength="12"
    id="892" name="892" type="text" placeholder= " Work History 3 Fax" data-id="managing_contact_name">
    <small class="text-danger" *ngIf="eyeForm.get('892').errors?.pattern">Required pattern xxx-xxx-xxxx</small>
         
  </td>
  <td colspan="6" rowspan="3">
    Complete address:
    <input class="form-control onboard" formControlName="468"  
    id="468" name="468" type="text" placeholder= " Work History 3 Address1" data-id="managing_contact_name">         
<input class="form-control onboard" formControlName="469"  
id="469" name="469" type="text" placeholder= " Work History 3 Address2" data-id="managing_contact_name">         
<input class="form-control onboard" formControlName="470" 
id="470" name="470" type="text" placeholder= "Work History 3 City" data-id="managing_contact_name">         

<ng-select
    [items]="states"
    bindLabel="name"
    placeholder="States"
    bindValue="id"
    formControlName="472"
    style="width:280px"
   
  >
  </ng-select>       
   
  <input class="form-control onboard" formControlName="473" 
id="473" name="473" type="text" placeholder= " Work History 3 Zip" data-id="managing_contact_name">         
<small
class="text-danger"
*ngIf="eyeForm.controls['473'].errors?.pattern"
> Zip code must be atleast 5 digits and less than 10 digits.</small
> 
</td>
</tr>
<tr class="border">
  <td colspan="3">
    From month / day / year:
    <div class="input-group-append">
      <input class="form-control onboard datepicker" formControlName="476" (blur)="clearInputIfInvalid('476')"
      ngbDatepicker #d115="ngbDatepicker" id="476" name="476" type="text" placeholder= "Work History 3 Start Date" data-id="476">
          <button class="btn btn-outline-secondary calendar" (click)="d115.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
      </div>
      <div  *ngIf="eyeForm.get('476').invalid ">
        <small class="text-danger" *ngIf="eyeForm.get('476').errors?.invalidDateFormat">
          Only Date Format is allowed(MM/DD/YYYY)                  </small>
        <small class="text-danger" *ngIf="eyeForm.get('476').errors?.invalidMonth">
        Month is out of range
        </small>
        <small class="text-danger" *ngIf="eyeForm.get('476').errors?.invalidDateyear">
          year is out of range(1900-2099)                    </small>
          <small class="text-danger" *ngIf="eyeForm.get('476').errors?.invalidDateRange">
            date is out of range
            </small>
        </div>
  </td> 
  <td colspan="4">
    To month / day / year:
    <div class="input-group-append">
      <input class="form-control onboard datepicker" formControlName="477" (blur)="clearInputIfInvalid('477')"
      ngbDatepicker #d116="ngbDatepicker" id="477" name="477" type="text" placeholder= "Work History 3 End Date" data-id="477">
          <button class="btn btn-outline-secondary calendar" (click)="d116.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
      </div>
      <div  *ngIf="eyeForm.get('477').invalid ">
        <small class="text-danger" *ngIf="eyeForm.get('477').errors?.invalidDateFormat">
          Only Date Format is allowed(MM/DD/YYYY)                  </small>
        <small class="text-danger" *ngIf="eyeForm.get('477').errors?.invalidMonth">
        Month is out of range
        </small>
        <small class="text-danger" *ngIf="eyeForm.get('477').errors?.invalidDateyear">
          year is out of range(1900-2099)                    </small>
          <small class="text-danger" *ngIf="eyeForm.get('477').errors?.invalidDateRange">
            date is out of range
            </small>
        </div>
  </td> 
</tr>
<tr class="border">
  <td colspan="4">
    Contact’s email address, if available:
    <input class="form-control onboard" formControlName="893" 
    id="893" name="893" type="text" placeholder= "Work History 3 Email" data-id="managing_contact_name">  
    <small
    class="text-danger"
    *ngIf="eyeForm.controls['893'].errors?.pattern"
    >Please enter a valid email.</small
    >       
  </td>
  <td colspan="3">
    Professional liability carrier: 
    <input class="form-control onboard" formControlName="cmd_00103" 
    id="cmd_00103" name="cmd_00103" type="text" placeholder= "" data-id="managing_contact_name">         
  </td>
</tr>
<tr class="border">
  <td colspan="2">
    Name of practice / employer:
    <input class="form-control onboard" formControlName="1935" 
    id="1935" name="1935" type="text" placeholder= "Work History 4 Location " data-id="managing_contact_name">         
  
  </td>
  <td colspan="1">
    Contact’s name:
    <input class="form-control onboard" formControlName="1943" 
    id="1943" name="1943" type="text" placeholder= "Work History 4 Contact" data-id="managing_contact_name">         
  
  </td>
  <td colspan="2">
    Telephone number:
    <input class="form-control onboard" formControlName="1944" (keydown)="handleNumber($event, 1944 )" maxlength="12"
    id="1944" name="1944" type="text" placeholder= "Work History 4 Telephone" data-id="managing_contact_name"> 
    <small class="text-danger" *ngIf="eyeForm.get('1944').errors?.pattern">Required pattern xxx-xxx-xxxx</small>
        
  </td>
  <td colspan="2">
    Fax number:
    <input class="form-control onboard" formControlName="1945" (keydown)="handleNumber($event, 1945 )" maxlength="12"
    id="1945" name="1945" type="text" placeholder= " Work History 4 Fax" data-id="managing_contact_name"> 
    <small class="text-danger" *ngIf="eyeForm.get('1945').errors?.pattern">Required pattern xxx-xxx-xxxx</small>        
  </td>
  <td colspan="6" rowspan="3">
    Complete address:
    <input class="form-control onboard" formControlName="1936"  
    id="1936" name="1936" type="text" placeholder= " Work History 4 Address1" data-id="managing_contact_name">         
<input class="form-control onboard" formControlName="1937"  
id="1937" name="1937" type="text" placeholder= " Work History 4 Address2" data-id="managing_contact_name">         
<input class="form-control onboard" formControlName="1938" 
id="1938" name="1938" type="text" placeholder= "Work History 4 City" data-id="managing_contact_name">         

<ng-select
    [items]="states"
    bindLabel="name"
    placeholder="States"
    bindValue="id"
    formControlName="1940"
   
  >
  </ng-select>       
   
  <input class="form-control onboard" formControlName="1941" 
id="1941" name="1941" type="text" placeholder= " Work History 4 Zip" data-id="managing_contact_name">         
<small
class="text-danger"
*ngIf="eyeForm.controls['1941'].errors?.pattern"
> Zip code must be atleast 5 digits and less than 10 digits.</small
>
</td>
</tr>
<tr class="border">
  <td colspan="3">
    From month / day / year:
    <div class="input-group-append">
      <input class="form-control onboard datepicker" formControlName="1947" (blur)="clearInputIfInvalid('1947')"
      ngbDatepicker #d120="ngbDatepicker" id="1947" name="1947" type="text" placeholder= "Work History 4 Start Date" data-id="1947">
          <button class="btn btn-outline-secondary calendar" (click)="d120.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
      </div>
      <div  *ngIf="eyeForm.get('1947').invalid ">
        <small class="text-danger" *ngIf="eyeForm.get('1947').errors?.invalidDateFormat">
          Only Date Format is allowed(MM/DD/YYYY)                  </small>
        <small class="text-danger" *ngIf="eyeForm.get('1947').errors?.invalidMonth">
        Month is out of range
        </small>
        <small class="text-danger" *ngIf="eyeForm.get('1947').errors?.invalidDateyear">
          year is out of range(1900-2099)                    </small>
          <small class="text-danger" *ngIf="eyeForm.get('1947').errors?.invalidDateRange">
            date is out of range
            </small>
        </div>
  </td> 
  <td colspan="4">
    To month / day / year:
    <div class="input-group-append">
      <input class="form-control onboard datepicker" formControlName="1948" (blur)="clearInputIfInvalid('1948')"
      ngbDatepicker #d119="ngbDatepicker" id="1948" name="1948" type="text" placeholder= "Work History 4 End Date" data-id="1948">
          <button class="btn btn-outline-secondary calendar" (click)="d119.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
      </div>
      <div  *ngIf="eyeForm.get('1948').invalid ">
        <small class="text-danger" *ngIf="eyeForm.get('1948').errors?.invalidDateFormat">
          Only Date Format is allowed(MM/DD/YYYY)                  </small>
        <small class="text-danger" *ngIf="eyeForm.get('1948').errors?.invalidMonth">
        Month is out of range
        </small>
        <small class="text-danger" *ngIf="eyeForm.get('1948').errors?.invalidDateyear">
          year is out of range(1900-2099)                    </small>
          <small class="text-danger" *ngIf="eyeForm.get('1948').errors?.invalidDateRange">
            date is out of range
            </small>
        </div>
  </td> 
</tr>
<tr class="border">
  <td colspan="4">
    Contact’s email address, if available:
    <input class="form-control onboard" formControlName="1946" 
    id="1946" name="1946" type="text" placeholder= "Work History 4 Email" data-id="managing_contact_name"> 
    <small
    class="text-danger"
    *ngIf="eyeForm.controls['1946'].errors?.pattern"
    >Please enter a valid email.</small
    >        
  </td>
  <td colspan="3">
    Professional liability carrier: 
    <input class="form-control onboard" formControlName="cmd_00104" 
    id="cmd_00104" name="cmd_00104" type="text" placeholder= "" data-id="managing_contact_name">         
  </td>
</tr>
<tr>
  <td colspan="12">
    <div class="centered texasFirstTable">
      <div class="row">
      <div class="col-md-8">
          <h2  style="margin-left: 31px;  font-size: 18px;"><b>B.Please explain any gaps greater than two (2) months. Include activities and/or names and dates</b>
            
          </h2>
          <span>
            <h6>where applicable. (Please attach additional sheets, if necessary.)</h6>
          </span>
      </div>
      <div class="col-md-4">
          <label for="cmd_0105"><b>Does Not Apply:</b></label>&nbsp;&nbsp;
          <input type="checkbox" formControlName="cmd_0105"
              id="cmd_0105" value="1" (ngModelChange)="handleNAchange( 'cmd_0105')"
              >
  </div>
    </div>
  </div>
  </td>
</tr>
<tr class="border">
  <td colspan="4">
      <label 
        >Activities and/or names:
      </label>
  </td>
  <td colspan="4" >
      <label >From month / year:
         </label>
  </td>
  <td colspan="4">
      <label 
        >To month / year:
      </label>
  </td>
</tr> 
 <tr class="border">
  <td colspan="4" >
    <input  formControlName="1138" class="form-control  " (blur)="handleNAchange('cmd_0105', 1138)" id="1138" name="1138" type="text" placeholder="Explanation for Gap in Work History 1"
    [class.is-invalid]="(eyeForm.controls['1138'].invalid)">
    <div class="formLabel col-md-11"ll
    *ngIf="eyeForm.controls['1138'].touched && eyeForm.controls['1138'].invalid">
    <small class="text-danger" *ngIf="eyeForm.controls['1138'].errors?.required">
        required
    </small>
    </div>
  </td>
  <td colspan="4">
    <div class="input-group-append">
    <input class="form-control onboard datepicker" formControlName="3395" (blur)="clearInputIfInvalid('3395','cmd_0105')"  (blur)="handleNAchange('cmd_0105', 3395)"
                    [class.is-invalid]="(eyeForm.controls['3395'].invalid)"
                    ngbDatepicker #d132="ngbDatepicker" id="3395" name="3395" type="text" placeholder= "Gap Start Date 1" data-id="3395">
      <button class="btn btn-outline-secondary calendar" (click)="d132.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
  </div>
    <div class="formLabel col-md-11"
    *ngIf="eyeForm.controls['3395'].touched && eyeForm.controls['3395'].invalid">
    <small class="text-danger" *ngIf="eyeForm.controls['3395'].errors?.required">
        required
    </small>
</div>
<div  *ngIf="eyeForm.get('3395').invalid ">
  <small class="text-danger" *ngIf="eyeForm.get('3395').errors?.invalidDateFormat">
    Only Date Format is allowed(MM/DD/YYYY)                  </small>
  <small class="text-danger" *ngIf="eyeForm.get('3395').errors?.invalidMonth">
  Month is out of range
  </small>
  <small class="text-danger" *ngIf="eyeForm.get('3395').errors?.invalidDateyear">
    year is out of range(1900-2099)                    </small>
    <small class="text-danger" *ngIf="eyeForm.get('3395').errors?.invalidDateRange">
      date is out of range
      </small>
  </div>
  </td>
  <td colspan="4">
    <div class="input-group-append">
    <input class="form-control onboard datepicker" formControlName="3396"  (blur)="clearInputIfInvalid('3396','cmd_0105')" (blur)="handleNAchange('cmd_0105', 3396)"
    [class.is-invalid]="(eyeForm.controls['3396'].invalid)"
    ngbDatepicker #d131="ngbDatepicker" id="3396" name="3396" type="text" placeholder= "Gap End Date 1" data-id="3396">
   <button class="btn btn-outline-secondary calendar" (click)="d131.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
    </div>
<div class="formLabel col-md-11"
*ngIf="eyeForm.controls['3396'].touched && eyeForm.controls['3396'].invalid">
<small class="text-danger" *ngIf="eyeForm.controls['3396'].errors?.required">
required
</small>
</div>
<div  *ngIf="eyeForm.get('3396').invalid ">
  <small class="text-danger" *ngIf="eyeForm.get('3396').errors?.invalidDateFormat">
    Only Date Format is allowed(MM/DD/YYYY)                  </small>
  <small class="text-danger" *ngIf="eyeForm.get('3396').errors?.invalidMonth">
  Month is out of range
  </small>
  <small class="text-danger" *ngIf="eyeForm.get('3396').errors?.invalidDateyear">
    year is out of range(1900-2099)                    </small>
    <small class="text-danger" *ngIf="eyeForm.get('3396').errors?.invalidDateRange">
      date is out of range
      </small>
  </div>
</td>
</tr>  
<tr class="border">
  <td colspan="4" >
    <input  formControlName="1831" class="form-control  " id="1831" name="1831" (blur)="handleNAchange('cmd_0105', 1831)" type="text" placeholder="Explanation for Gap in Work History 2"
    [class.is-invalid]="(eyeForm.controls['1831'].invalid)">
    <div class="formLabel col-md-11"ll
    *ngIf="eyeForm.controls['1831'].touched && eyeForm.controls['1831'].invalid">
    <small class="text-danger" *ngIf="eyeForm.controls['1831'].errors?.required">
        required
    </small>
    </div>
  </td>
  <td colspan="4">
    <div class="input-group-append">
    <input class="form-control onboard datepicker" formControlName="3397" (blur)="clearInputIfInvalid('3397','cmd_0105')" (blur)="handleNAchange('cmd_0105', 3397)"
                    [class.is-invalid]="(eyeForm.controls['3397'].invalid)"
                    ngbDatepicker #d135="ngbDatepicker" id="3397" name="3397" type="text" placeholder= "Gap Start Date 2" data-id="3397">
      <button class="btn btn-outline-secondary calendar" (click)="d135.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
  </div>
    <div class="formLabel col-md-11"
    *ngIf="eyeForm.controls['3397'].touched && eyeForm.controls['3397'].invalid">
    <small class="text-danger" *ngIf="eyeForm.controls['3397'].errors?.required">
        required
    </small>
</div>
<div  *ngIf="eyeForm.get('3397').invalid ">
  <small class="text-danger" *ngIf="eyeForm.get('3397').errors?.invalidDateFormat">
    Only Date Format is allowed(MM/DD/YYYY)                  </small>
  <small class="text-danger" *ngIf="eyeForm.get('3397').errors?.invalidMonth">
  Month is out of range
  </small>
  <small class="text-danger" *ngIf="eyeForm.get('3397').errors?.invalidDateyear">
    year is out of range(1900-2099)                    </small>
    <small class="text-danger" *ngIf="eyeForm.get('3397').errors?.invalidDateRange">
      date is out of range
      </small>
  </div>
  </td>
  <td colspan="4">
    <div class="input-group-append">
    <input class="form-control onboard datepicker" formControlName="3398" (blur)="handleNAchange('cmd_0105', 3398)" (blur)="clearInputIfInvalid('3398','cmd_0105')"
    [class.is-invalid]="(eyeForm.controls['3398'].invalid)"
    ngbDatepicker #d134="ngbDatepicker" id="3398" name="3398" type="text" placeholder= "Gap End Date 2" data-id="3398">
   <button class="btn btn-outline-secondary calendar" (click)="d134.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
    </div>
<div class="formLabel col-md-11"
*ngIf="eyeForm.controls['3398'].touched && eyeForm.controls['3398'].invalid">
<small class="text-danger" *ngIf="eyeForm.controls['3398'].errors?.required">
required
</small>
</div>
<div  *ngIf="eyeForm.get('3398').invalid ">
  <small class="text-danger" *ngIf="eyeForm.get('3398').errors?.invalidDateFormat">
    Only Date Format is allowed(MM/DD/YYYY)                  </small>
  <small class="text-danger" *ngIf="eyeForm.get('3398').errors?.invalidMonth">
  Month is out of range
  </small>
  <small class="text-danger" *ngIf="eyeForm.get('3398').errors?.invalidDateyear">
    year is out of range(1900-2099)                    </small>
    <small class="text-danger" *ngIf="eyeForm.get('3398').errors?.invalidDateRange">
      date is out of range
      </small>
  </div>
</td>
</tr> 
<tr class="border">
  <td colspan="4" >
    <input  formControlName="1832" class="form-control  " id="1832" name="1832" (blur)="handleNAchange('cmd_0105', 1832)" type="text" placeholder="Explanation for Gap in Work History 3"
    [class.is-invalid]="(eyeForm.controls['1832'].invalid)">
    <div class="formLabel col-md-11"ll
    *ngIf="eyeForm.controls['1832'].touched && eyeForm.controls['1832'].invalid">
    <small class="text-danger" *ngIf="eyeForm.controls['1832'].errors?.required">
        required
    </small>
    </div>
  </td>
  <td colspan="4">
    <div class="input-group-append">
    <input class="form-control onboard datepicker" formControlName="3399" (blur)="handleNAchange('cmd_0105', 3399)" (blur)="clearInputIfInvalid('3399','cmd_0105')"
                    [class.is-invalid]="(eyeForm.controls['3399'].invalid)"
                    ngbDatepicker #d139="ngbDatepicker" id="3399" name="3399" type="text" placeholder= "Gap Start Date 3" data-id="3399">
      <button class="btn btn-outline-secondary calendar" (click)="d139.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
  </div>
    <div class="formLabel col-md-11"
    *ngIf="eyeForm.controls['3399'].touched && eyeForm.controls['3399'].invalid">
    <small class="text-danger" *ngIf="eyeForm.controls['3399'].errors?.required">
        required
    </small>
</div>
<div  *ngIf="eyeForm.get('3399').invalid ">
  <small class="text-danger" *ngIf="eyeForm.get('3399').errors?.invalidDateFormat">
    Only Date Format is allowed(MM/DD/YYYY)                  </small>
  <small class="text-danger" *ngIf="eyeForm.get('3399').errors?.invalidMonth">
  Month is out of range
  </small>
  <small class="text-danger" *ngIf="eyeForm.get('3399').errors?.invalidDateyear">
    year is out of range(1900-2099)                    </small>
    <small class="text-danger" *ngIf="eyeForm.get('3399').errors?.invalidDateRange">
      date is out of range
      </small>
  </div>
  </td>
  <td colspan="4">
    <div class="input-group-append">
    <input class="form-control onboard datepicker" formControlName="3400" (blur)="handleNAchange('cmd_0105', 3400)" (blur)="clearInputIfInvalid('3400','cmd_0105')"
    [class.is-invalid]="(eyeForm.controls['3400'].invalid)"
    ngbDatepicker #d140="ngbDatepicker" id="3400" name="3400" type="text" placeholder= "Gap End Date 3" data-id="3400">
   <button class="btn btn-outline-secondary calendar" (click)="d140.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
    </div>
<div class="formLabel col-md-11"
*ngIf="eyeForm.controls['3400'].touched && eyeForm.controls['3400'].invalid">
<small class="text-danger" *ngIf="eyeForm.controls['3400'].errors?.required">
required
</small>
</div>
<div  *ngIf="eyeForm.get('3400').invalid ">
  <small class="text-danger" *ngIf="eyeForm.get('3400').errors?.invalidDateFormat">
    Only Date Format is allowed(MM/DD/YYYY)                  </small>
  <small class="text-danger" *ngIf="eyeForm.get('3400').errors?.invalidMonth">
  Month is out of range
  </small>
  <small class="text-danger" *ngIf="eyeForm.get('3400').errors?.invalidDateyear">
    year is out of range(1900-2099)                    </small>
    <small class="text-danger" *ngIf="eyeForm.get('3400').errors?.invalidDateRange">
      date is out of range
      </small>
  </div>
</td>
</tr> 
<tr class="border">
  <td colspan="4" >
    <input  formControlName="1953" class="form-control  " id="1953" name="1953" (blur)="handleNAchange('cmd_0105', 1953)" type="text" placeholder="Explanation for Gap in Work History 4"
    [class.is-invalid]="(eyeForm.controls['1953'].invalid)">
    <div class="formLabel col-md-11"ll
    *ngIf="eyeForm.controls['1953'].touched && eyeForm.controls['1953'].invalid">
    <small class="text-danger" *ngIf="eyeForm.controls['1953'].errors?.required">
        required
    </small>
    </div>
  </td>
  <td colspan="4">
    <div class="input-group-append">
    <input class="form-control onboard datepicker" formControlName="3401" (blur)="handleNAchange('cmd_0105', 3401)"  (blur)="clearInputIfInvalid('3401','cmd_0105')"
                    [class.is-invalid]="(eyeForm.controls['3401'].invalid)"
                    ngbDatepicker #d145="ngbDatepicker" id="3401" name="3401" type="text" placeholder= "Gap Start Date 4" data-id="3401">
      <button class="btn btn-outline-secondary calendar" (click)="d145.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
  </div>
    <div class="formLabel col-md-11"
    *ngIf="eyeForm.controls['3401'].touched && eyeForm.controls['3401'].invalid">
    <small class="text-danger" *ngIf="eyeForm.controls['3401'].errors?.required">
        required
    </small>
</div>
<div  *ngIf="eyeForm.get('3401').invalid ">
  <small class="text-danger" *ngIf="eyeForm.get('3401').errors?.invalidDateFormat">
    Only Date Format is allowed(MM/DD/YYYY)                  </small>
  <small class="text-danger" *ngIf="eyeForm.get('3401').errors?.invalidMonth">
  Month is out of range
  </small>
  <small class="text-danger" *ngIf="eyeForm.get('3401').errors?.invalidDateyear">
    year is out of range(1900-2099)                    </small>
    <small class="text-danger" *ngIf="eyeForm.get('3401').errors?.invalidDateRange">
      date is out of range
      </small>
  </div>
  </td>
  <td colspan="4">
    <div class="input-group-append">
    <input class="form-control onboard datepicker" formControlName="3402" (blur)="clearInputIfInvalid('3402','cmd_0105')" (blur)="handleNAchange('cmd_0105', 3402)"
    [class.is-invalid]="(eyeForm.controls['3402'].invalid)"
    ngbDatepicker #d144="ngbDatepicker" id="3402" name="3402" type="text" placeholder= "Gap End Date 4" data-id="3402">
   <button class="btn btn-outline-secondary calendar" (click)="d144.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
    </div>
<div class="formLabel col-md-11"
*ngIf="eyeForm.controls['3402'].touched && eyeForm.controls['3402'].invalid">
<small class="text-danger" *ngIf="eyeForm.controls['3402'].errors?.required">
required
</small>
</div>
<div  *ngIf="eyeForm.get('3402').invalid ">
  <small class="text-danger" *ngIf="eyeForm.get('3402').errors?.invalidDateFormat">
    Only Date Format is allowed(MM/DD/YYYY)                  </small>
  <small class="text-danger" *ngIf="eyeForm.get('3402').errors?.invalidMonth">
  Month is out of range
  </small>
  <small class="text-danger" *ngIf="eyeForm.get('3402').errors?.invalidDateyear">
    year is out of range(1900-2099)                    </small>
    <small class="text-danger" *ngIf="eyeForm.get('3402').errors?.invalidDateRange">
      date is out of range
      </small>
  </div>
</td>
</tr> 
<tr class="border">
  <td colspan="4" >
    <input  formControlName="add_1_4119" class="form-control  "   (blur)="handleNAchange('cmd_0105', 'add_1_4119')" id="add_1_4119" name="add_1_4119" type="text" placeholder="Additional Explanation for Gap in Work History"
    [class.is-invalid]="(eyeForm.controls['add_1_4119'].invalid)">
    <div class="formLabel col-md-11"ll
    *ngIf="eyeForm.controls['add_1_4119'].touched && eyeForm.controls['add_1_4119'].invalid">
    <small class="text-danger" *ngIf="eyeForm.controls['add_1_4119'].errors?.required">
        required
    </small>
    </div>
  </td>
  <td colspan="4">
    <div class="input-group-append">
    <input class="form-control onboard datepicker" formControlName="add_1_4117" (blur)="clearInputIfInvalid('add_1_4117','cmd_0105')" (blur)="handleNAchange('cmd_0105', 'add_1_4117')"
                    [class.is-invalid]="(eyeForm.controls['add_1_4117'].invalid)"
                    ngbDatepicker #d151="ngbDatepicker" id="add_1_4117" name="add_1_4117" type="text" placeholder= "Additional Gap Start Date" data-id="add_1_4117">
      <button class="btn btn-outline-secondary calendar" (click)="d151.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
  </div>
    <div class="formLabel col-md-11"
    *ngIf="eyeForm.controls['add_1_4117'].touched && eyeForm.controls['add_1_4117'].invalid">
    <small class="text-danger" *ngIf="eyeForm.controls['add_1_4117'].errors?.required">
        required
    </small>
</div>
<div  *ngIf="eyeForm.get('add_1_4117').invalid ">
  <small class="text-danger" *ngIf="eyeForm.get('add_1_4117').errors?.invalidDateFormat">
    Only Date Format is allowed(MM/DD/YYYY)                  </small>
  <small class="text-danger" *ngIf="eyeForm.get('add_1_4117').errors?.invalidMonth">
  Month is out of range
  </small>
  <small class="text-danger" *ngIf="eyeForm.get('add_1_4117').errors?.invalidDateyear">
    year is out of range(1900-2099)                    </small>
    <small class="text-danger" *ngIf="eyeForm.get('add_1_4117').errors?.invalidDateRange">
      date is out of range
      </small>
  </div>
  </td>
  <td colspan="4">
    <div class="input-group-append">
    <input class="form-control onboard datepicker" formControlName="add_1_4118" (blur)="clearInputIfInvalid('add_1_4118','cmd_0105')" (blur)="clearInputIfInvalid('add_1_4118','cmd_0105')" (blur)="handleNAchange('cmd_0105', 'add_1_4118')"
    [class.is-invalid]="(eyeForm.controls['add_1_4118'].invalid)"
    ngbDatepicker #d152="ngbDatepicker" id="add_1_4118" name="add_1_4118" type="text" placeholder= "Additional Gap End Date" data-id="add_1_4118">
   <button class="btn btn-outline-secondary calendar" (click)="d152.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
    </div>
<div class="formLabel col-md-11"
*ngIf="eyeForm.controls['add_1_4118'].touched && eyeForm.controls['add_1_4118'].invalid">
<small class="text-danger" *ngIf="eyeForm.controls['add_1_4118'].errors?.required">
required
</small>
</div>
<div  *ngIf="eyeForm.get('add_1_4118').invalid ">
  <small class="text-danger" *ngIf="eyeForm.get('add_1_4118').errors?.invalidDateFormat">
    Only Date Format is allowed(MM/DD/YYYY)                  </small>
  <small class="text-danger" *ngIf="eyeForm.get('add_1_4118').errors?.invalidMonth">
  Month is out of range
  </small>
  <small class="text-danger" *ngIf="eyeForm.get('add_1_4118').errors?.invalidDateyear">
    year is out of range(1900-2099)                    </small>
    <small class="text-danger" *ngIf="eyeForm.get('add_1_4118').errors?.invalidDateRange">
      date is out of range
      </small>
  </div>
</td>
</tr> 
<tr class="border">
  <td colspan="4" >
    <input  formControlName="add_2_4119" class="form-control  " id="add_2_4119" name="add_2_4119" type="text" placeholder="Additional Explanation for Gap in Work History"
  >
  </td>
  <td colspan="4">
    <div class="input-group-append">
    <input class="form-control onboard datepicker" formControlName="add_2_4117" 
                    ngbDatepicker #d153="ngbDatepicker" id="add_2_4117" name="add_2_4117" (blur)="clearInputIfInvalid('add_2_4117')" type="text" placeholder= "Additional Gap Start Date" data-id="add_2_4117">
      <button class="btn btn-outline-secondary calendar" (click)="d153.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
  </div>
  <div  *ngIf="eyeForm.get('add_2_4117').invalid ">
    <small class="text-danger" *ngIf="eyeForm.get('add_2_4117').errors?.invalidDateFormat">
      Only Date Format is allowed(MM/DD/YYYY)                  </small>
    <small class="text-danger" *ngIf="eyeForm.get('add_2_4117').errors?.invalidMonth">
    Month is out of range
    </small>
    <small class="text-danger" *ngIf="eyeForm.get('add_2_4117').errors?.invalidDateyear">
      year is out of range(1900-2099)                    </small>
      <small class="text-danger" *ngIf="eyeForm.get('add_2_4117').errors?.invalidDateRange">
        date is out of range
        </small>
    </div>
  </td>
  <td colspan="4">
    <div class="input-group-append">
    <input class="form-control onboard datepicker" formControlName="add_2_4118"  (blur)="clearInputIfInvalid('add_2_4118')"
    ngbDatepicker #d154="ngbDatepicker" id="add_2_4118" name="add_2_4118" type="text" placeholder= "Additional Gap End Date" data-id="add_2_4118">
   <button class="btn btn-outline-secondary calendar" (click)="d154.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
    </div>
    <div  *ngIf="eyeForm.get('add_2_4118').invalid ">
      <small class="text-danger" *ngIf="eyeForm.get('add_2_4118').errors?.invalidDateFormat">
        Only Date Format is allowed(MM/DD/YYYY)                  </small>
      <small class="text-danger" *ngIf="eyeForm.get('add_2_4118').errors?.invalidMonth">
      Month is out of range
      </small>
      <small class="text-danger" *ngIf="eyeForm.get('add_2_4118').errors?.invalidDateyear">
        year is out of range(1900-2099)                    </small>
        <small class="text-danger" *ngIf="eyeForm.get('add_2_4118').errors?.invalidDateRange">
          date is out of range
          </small>
      </div>
</td>
</tr> 
<tr class="border">
  <td colspan="4" >
    <input  formControlName="add_3_4119" class="form-control  " id="add_3_4119" name="add_3_4119" type="text" placeholder="Additional Explanation for Gap in Work History"
    >
 
  </td>
  <td colspan="4">
    <div class="input-group-append">
    <input class="form-control onboard datepicker" formControlName="add_3_4117"  (blur)="clearInputIfInvalid('add_3_4117')"
                    ngbDatepicker #d155="ngbDatepicker" id="add_3_4117" name="add_3_4117" type="text" placeholder= "Additional Gap Start Date" data-id="add_3_4117">
      <button class="btn btn-outline-secondary calendar" (click)="d155.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
  </div>
  <div  *ngIf="eyeForm.get('add_3_4117').invalid ">
    <small class="text-danger" *ngIf="eyeForm.get('add_3_4117').errors?.invalidDateFormat">
      Only Date Format is allowed(MM/DD/YYYY)                  </small>
    <small class="text-danger" *ngIf="eyeForm.get('add_3_4117').errors?.invalidMonth">
    Month is out of range
    </small>
    <small class="text-danger" *ngIf="eyeForm.get('add_3_4117').errors?.invalidDateyear">
      year is out of range(1900-2099)                    </small>
      <small class="text-danger" *ngIf="eyeForm.get('add_3_4117').errors?.invalidDateRange">
        date is out of range
        </small>
    </div>
  </td>
  <td colspan="4">
    <div class="input-group-append">
    <input class="form-control onboard datepicker" formControlName="add_3_4118" (blur)="clearInputIfInvalid('add_3_4118')"
    ngbDatepicker #d156="ngbDatepicker" id="add_3_4118" name="add_3_4118" type="text" placeholder= "Additional Gap End Date" data-id="add_3_4118">
   <button class="btn btn-outline-secondary calendar" (click)="d156.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
    </div>
    <div  *ngIf="eyeForm.get('add_3_4118').invalid ">
      <small class="text-danger" *ngIf="eyeForm.get('add_3_4118').errors?.invalidDateFormat">
        Only Date Format is allowed(MM/DD/YYYY)                  </small>
      <small class="text-danger" *ngIf="eyeForm.get('add_3_4118').errors?.invalidMonth">
      Month is out of range
      </small>
      <small class="text-danger" *ngIf="eyeForm.get('add_3_4118').errors?.invalidDateyear">
        year is out of range(1900-2099)                    </small>
        <small class="text-danger" *ngIf="eyeForm.get('add_3_4118').errors?.invalidDateRange">
          date is out of range
          </small>
      </div>
</td>
</tr> 
<tr class="border">
  <td colspan="4" >
    <input  formControlName="add_4_4119" class="form-control  " id="add_4_4119" name="add_4_4119" type="text" placeholder="Additional Explanation for Gap in Work History"
    >
    
  </td>
  <td colspan="4">
    <div class="input-group-append">
    <input class="form-control onboard datepicker" formControlName="add_4_4117" (blur)="clearInputIfInvalid('add_4_4117')"
                    ngbDatepicker #d157="ngbDatepicker" id="add_4_4117" name="add_4_4117" type="text" placeholder= "Additional Gap Start Date" data-id="add_4_4117">
      <button class="btn btn-outline-secondary calendar" (click)="d157.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
  </div>
  <div  *ngIf="eyeForm.get('add_4_4117').invalid ">
    <small class="text-danger" *ngIf="eyeForm.get('add_4_4117').errors?.invalidDateFormat">
      Only Date Format is allowed(MM/DD/YYYY)                  </small>
    <small class="text-danger" *ngIf="eyeForm.get('add_4_4117').errors?.invalidMonth">
    Month is out of range
    </small>
    <small class="text-danger" *ngIf="eyeForm.get('add_4_4117').errors?.invalidDateyear">
      year is out of range(1900-2099)                    </small>
      <small class="text-danger" *ngIf="eyeForm.get('add_4_4117').errors?.invalidDateRange">
        date is out of range
        </small>
    </div>
  </td>
  <td colspan="4">
    <div class="input-group-append">
    <input class="form-control onboard datepicker" formControlName="add_4_4118" (blur)="clearInputIfInvalid('add_4_4118')"
    ngbDatepicker #d158="ngbDatepicker" id="add_4_4118" name="add_4_4118" type="text" placeholder= "Additional Gap End Date" data-id="add_4_4118">
   <button class="btn btn-outline-secondary calendar" (click)="d158.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
    </div>
    <div  *ngIf="eyeForm.get('add_4_4118').invalid ">
      <small class="text-danger" *ngIf="eyeForm.get('add_4_4118').errors?.invalidDateFormat">
        Only Date Format is allowed(MM/DD/YYYY)                  </small>
      <small class="text-danger" *ngIf="eyeForm.get('add_4_4118').errors?.invalidMonth">
      Month is out of range
      </small>
      <small class="text-danger" *ngIf="eyeForm.get('add_4_4118').errors?.invalidDateyear">
        year is out of range(1900-2099)                    </small>
        <small class="text-danger" *ngIf="eyeForm.get('add_4_4118').errors?.invalidDateRange">
          date is out of range
          </small>
      </div>
</td>
</tr> 
<tr class="border">
  <td colspan="4" >
    <input  formControlName="add_5_4119" class="form-control  " id="add_5_4119" name="add_5_4119" type="text" placeholder="Additional Explanation for Gap in Work History"
   >
  </td>
  <td colspan="4">
    <div class="input-group-append">
    <input class="form-control onboard datepicker" formControlName="add_5_4117"  (blur)="clearInputIfInvalid('add_5_4117')"
                    ngbDatepicker #d1500="ngbDatepicker" id="add_5_4117" name="add_5_4117" type="text" placeholder= "Additional Gap Start Date" data-id="add_5_4117">
      <button class="btn btn-outline-secondary calendar" (click)="d1500.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
  </div>
  <div  *ngIf="eyeForm.get('add_5_4117').invalid ">
    <small class="text-danger" *ngIf="eyeForm.get('add_5_4117').errors?.invalidDateFormat">
      Only Date Format is allowed(MM/DD/YYYY)                  </small>
    <small class="text-danger" *ngIf="eyeForm.get('add_5_4117').errors?.invalidMonth">
    Month is out of range
    </small>
    <small class="text-danger" *ngIf="eyeForm.get('add_5_4117').errors?.invalidDateyear">
      year is out of range(1900-2099)                    </small>
      <small class="text-danger" *ngIf="eyeForm.get('add_5_4117').errors?.invalidDateRange">
        date is out of range
        </small>
    </div>
  </td>
  <td colspan="4">
    <div class="input-group-append">
    <input class="form-control onboard datepicker" formControlName="add_5_4118" (blur)="clearInputIfInvalid('add_5_4118')"
    ngbDatepicker #dadd_5_4118="ngbDatepicker" id="add_5_4118" name="add_5_4118" type="text" placeholder= "Additional Gap End Date" data-id="add_5_4118">
   <button class="btn btn-outline-secondary calendar" (click)="dadd_5_4118.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
    </div>
    <div  *ngIf="eyeForm.get('add_5_4118').invalid ">
      <small class="text-danger" *ngIf="eyeForm.get('add_5_4118').errors?.invalidDateFormat">
        Only Date Format is allowed(MM/DD/YYYY)                  </small>
      <small class="text-danger" *ngIf="eyeForm.get('add_5_4118').errors?.invalidMonth">
      Month is out of range
      </small>
      <small class="text-danger" *ngIf="eyeForm.get('add_5_4118').errors?.invalidDateyear">
        year is out of range(1900-2099)                    </small>
        <small class="text-danger" *ngIf="eyeForm.get('add_5_4118').errors?.invalidDateRange">
          date is out of range
          </small>
      </div>
</td>
</tr>
<tr class="border">
  <td colspan="4" >
    <input  formControlName="add_6_4119" class="form-control  " id="add_6_4119" name="add_6_4119" type="text" placeholder="Additional Explanation for Gap in Work History"
   >
    
  </td>
  <td colspan="4">
    <div class="input-group-append">
    <input class="form-control onboard datepicker" formControlName="add_6_4117" (blur)="clearInputIfInvalid('add_6_4117')"
                    ngbDatepicker #d159="ngbDatepicker" id="add_6_4117" name="add_6_4117" type="text" placeholder= "Additional Gap Start Date" data-id="add_6_4117">
      <button class="btn btn-outline-secondary calendar" (click)="d159.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
  </div>
  <div  *ngIf="eyeForm.get('add_6_4117').invalid ">
    <small class="text-danger" *ngIf="eyeForm.get('add_6_4117').errors?.invalidDateFormat">
      Only Date Format is allowed(MM/DD/YYYY)                  </small>
    <small class="text-danger" *ngIf="eyeForm.get('add_6_4117').errors?.invalidMonth">
    Month is out of range
    </small>
    <small class="text-danger" *ngIf="eyeForm.get('add_6_4117').errors?.invalidDateyear">
      year is out of range(1900-2099)                    </small>
      <small class="text-danger" *ngIf="eyeForm.get('add_6_4117').errors?.invalidDateRange">
        date is out of range
        </small>
    </div>
  </td>
  <td colspan="4">
    <div class="input-group-append">
    <input class="form-control onboard datepicker" formControlName="add_6_4118" (blur)="clearInputIfInvalid('add_6_4118')"
    ngbDatepicker #d160="ngbDatepicker" id="add_6_4118" name="add_5_4118" type="text" placeholder= "Additional Gap End Date" data-id="add_6_4118">
   <button class="btn btn-outline-secondary calendar" (click)="d160.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
    </div>
    <div  *ngIf="eyeForm.get('add_6_4118').invalid ">
      <small class="text-danger" *ngIf="eyeForm.get('add_6_4118').errors?.invalidDateFormat">
        Only Date Format is allowed(MM/DD/YYYY)                  </small>
      <small class="text-danger" *ngIf="eyeForm.get('add_6_4118').errors?.invalidMonth">
      Month is out of range
      </small>
      <small class="text-danger" *ngIf="eyeForm.get('add_6_4118').errors?.invalidDateyear">
        year is out of range(1900-2099)                    </small>
        <small class="text-danger" *ngIf="eyeForm.get('add_6_4118').errors?.invalidDateRange">
          date is out of range
          </small>
      </div>
</td>
</tr>
<tr class="border">
  <td colspan="4" >
    <input  formControlName="add_7_4119" class="form-control  " id="add_7_4119" name="add_7_4119" type="text" placeholder="Additional Explanation for Gap in Work History"
    >
   
  </td>
  <td colspan="4">
    <div class="input-group-append">
    <input class="form-control onboard datepicker" formControlName="add_7_4117" (blur)="clearInputIfInvalid('add_7_4117')"
                    ngbDatepicker #d167="ngbDatepicker" id="add_7_4117" name="add_7_4117" type="text" placeholder= "Additional Gap Start Date" data-id="add_7_4117">
      <button class="btn btn-outline-secondary calendar" (click)="d167.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
  </div>
  <div  *ngIf="eyeForm.get('add_7_4117').invalid ">
    <small class="text-danger" *ngIf="eyeForm.get('add_7_4117').errors?.invalidDateFormat">
      Only Date Format is allowed(MM/DD/YYYY)                  </small>
    <small class="text-danger" *ngIf="eyeForm.get('add_7_4117').errors?.invalidMonth">
    Month is out of range
    </small>
    <small class="text-danger" *ngIf="eyeForm.get('add_7_4117').errors?.invalidDateyear">
      year is out of range(1900-2099)                    </small>
      <small class="text-danger" *ngIf="eyeForm.get('add_7_4117').errors?.invalidDateRange">
        date is out of range
        </small>
    </div>
  </td>
  <td colspan="4">
    <div class="input-group-append">
    <input class="form-control onboard datepicker" formControlName="add_7_4118"   (blur)="clearInputIfInvalid('add_7_4118')"
    ngbDatepicker #d165="ngbDatepicker" id="add_7_4118" name="add_7_4118" type="text" placeholder= "Additional Gap End Date" data-id="add_7_4118">
   <button class="btn btn-outline-secondary calendar" (click)="d165.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
    </div>
    <div  *ngIf="eyeForm.get('add_7_4118').invalid ">
      <small class="text-danger" *ngIf="eyeForm.get('add_7_4118').errors?.invalidDateFormat">
        Only Date Format is allowed(MM/DD/YYYY)                  </small>
      <small class="text-danger" *ngIf="eyeForm.get('add_7_4118').errors?.invalidMonth">
      Month is out of range
      </small>
      <small class="text-danger" *ngIf="eyeForm.get('add_7_4118').errors?.invalidDateyear">
        year is out of range(1900-2099)                    </small>
        <small class="text-danger" *ngIf="eyeForm.get('add_7_4118').errors?.invalidDateRange">
          date is out of range
          </small>
      </div>
</td>
</tr>
<tr>
  <td colspan="12">
    <div class="centered texasFirstTable">
      <div class="row">
      <div class="col-md-8">
          <h2  style="margin-left: 31px;  font-size: 18px;"><b>XVIII. Peer References</b>
            
          </h2>
      </div>
    </div>
  </div>
  </td>
</tr>
<tr class="border">
  <td colspan="12">
  <div class="col-12">
    <h6>Please list three (3) references, from peers who through recent observations are directly familiar with your clinical skills
      and current competence. Do not include relatives. If possible, include at least one member from the Medical Staff of each facility
      at which you have privileges.</h6></div>
  </td>
</tr>
<tr class="border">
  <td colspan="6">
 <div class="row border">
  <div class="col-md-12">
    Name of reference:
    <input  formControlName="359" class="form-control" id="359" name="359" type="text" placeholder="Reference 1 Name"
    [class.is-invalid]="(eyeForm.controls['359'].touched && eyeForm.controls['359'].invalid) || (eyeForm.controls['359'].invalid && isSubmit)">
    <div class="formLabel col-md-11"
    *ngIf="eyeForm.controls['359'].touched && eyeForm.controls['359'].invalid">
    <small class="text-danger" *ngIf="eyeForm.controls['359'].errors?.required">
        required
    </small>
    </div>
  </div>
 </div> 
 <div class="row border">
  <div class="col-md-12">
    Specialty:
    <ng-select
    [items]="specialty"
    [class.is-invalid]="
      (eyeForm.controls['368'].touched &&
        eyeForm.controls['368'].invalid) ||
      (eyeForm.controls['368'].invalid && isSubmit)
    "
    bindLabel="name"
    placeholder="Reference 1 Specialities"
    bindValue="id"
    formControlName="368"
  >
  </ng-select>
  <div
    class="formLabel col-md-11"
    *ngIf="
      eyeForm.controls['368'].touched &&
      eyeForm.controls['368'].invalid
    "
  >
    <small
      class="text-danger"
      *ngIf="eyeForm.controls['368'].errors"
    >
      required
    </small>
  </div> 
  </div>
 </div> 
 <div class="row border">
  <div class="col-md-12">
    Credentials:
    <input  formControlName="2722" class="form-control  " id="2722" name="2722" type="text" placeholder="Reference 1 Title"
    [class.is-invalid]="(eyeForm.controls['2722'].touched && eyeForm.controls['2722'].invalid) || (eyeForm.controls['2722'].invalid && isSubmit)" >
    <div class="formLabel col-md-11"
    *ngIf="eyeForm.controls['2722'].touched && eyeForm.controls['2722'].invalid">
    <small class="text-danger" *ngIf="eyeForm.controls['2722'].errors?.required">
        required
    </small>
    </div>
  </div>
 </div> 
 <div class="row border">
  <div class="col-md-12">
    Professional relationship:
    <input  formControlName="2721" class="form-control  " id="2721" name="2721" type="text" placeholder="Reference 1 Relation"
    [class.is-invalid]="(eyeForm.controls['2721'].touched && eyeForm.controls['2721'].invalid) || (eyeForm.controls['2721'].invalid && isSubmit)" >
    <div class="formLabel col-md-11"
    *ngIf="eyeForm.controls['2721'].touched && eyeForm.controls['2721'].invalid">
    <small class="text-danger" *ngIf="eyeForm.controls['2721'].errors?.required">
        required
    </small>
    </div>
  </div>
 </div> 
 
  
</td>
<td colspan="6">
  <label>Complete address, include department if applicable:</label>
  <input  formControlName="360" class="form-control  " id="360" name="360" type="text" placeholder="Reference 1 Address1"
  [class.is-invalid]="(eyeForm.controls['360'].touched && eyeForm.controls['360'].invalid) || (eyeForm.controls['360'].invalid && isSubmit)" >
  <div class="formLabel col-md-11"
    *ngIf="eyeForm.controls['360'].touched && eyeForm.controls['360'].invalid">
    <small class="text-danger" *ngIf="eyeForm.controls['360'].errors?.required">
        required
    </small>
    </div>
  <input  formControlName="361" class="form-control  " id="361" name="361" type="text" placeholder="Reference 1 Address2"
  >
  <input  formControlName="362" class="form-control  " id="362" name="362" type="text" placeholder="Reference 1 City"
  [class.is-invalid]="(eyeForm.controls['362'].touched && eyeForm.controls['362'].invalid) || (eyeForm.controls['362'].invalid && isSubmit)" >
  <div class="formLabel col-md-11"
    *ngIf="eyeForm.controls['362'].touched && eyeForm.controls['362'].invalid">
    <small class="text-danger" *ngIf="eyeForm.controls['362'].errors?.required">
        required
    </small>
    </div>
  <ng-select
    [items]="states"
    [class.is-invalid]="
      (eyeForm.controls['364'].touched &&
        eyeForm.controls['364'].invalid) ||
      (eyeForm.controls['364'].invalid && isSubmit)
    "
    bindLabel="name"
    placeholder="States"
    bindValue="id"
    formControlName="364"
   
  >
  </ng-select>       
    <div
    class="formLabel col-md-11"
    *ngIf="
      eyeForm.controls['364'].touched &&
      eyeForm.controls['364'].invalid
    "
  >
    <small
      class="text-danger"
      *ngIf="eyeForm.controls['364'].errors"
    >
      required
    </small>
  </div>
  <input  formControlName="365" class="form-control  " id="365" name="365" type="text" placeholder="Reference 1 Zip"
  [class.is-invalid]="(eyeForm.controls['365'].touched && eyeForm.controls['365'].invalid) || (eyeForm.controls['365'].invalid && isSubmit)" >
  <div
  class="formLabel col-md-2"
  *ngIf="
    eyeForm.controls['365'].invalid
  "
  >
  <small
    class="text-danger"
    *ngIf="eyeForm.controls['365'].errors"
  >
    required
  </small>
  <small
    class="text-danger"
    *ngIf="
      eyeForm.controls['365'].errors?.invalidZipCode
    "
  >
    Zip code must be atleast 5 digits and less than 10 digits
  </small>
  </div>
</td>
</tr>
<tr class="border">
  <td colspan="4">
    Telephone number:
    <input  formControlName="369" class="form-control  " (keydown)="handleNumber($event, 369 )" id="369" name="369" type="text" placeholder="Reference 1 Phone" maxlength="12"
    [class.is-invalid]="(eyeForm.controls['369'].touched && eyeForm.controls['369'].invalid) || (eyeForm.controls['369'].invalid && isSubmit)"  >
    <div class="formLabel col-md-11"
  *ngIf="eyeForm.controls['369'].touched && eyeForm.controls['369'].invalid">
  <small class="text-danger" *ngIf="eyeForm.controls['369'].errors?.required">
      required
  </small>
  <small class="text-danger" *ngIf="eyeForm.get('369').errors?.pattern">Required pattern xxx-xxx-xxxx</small>

  </div>
  </td>
  <td colspan="4">
    Fax number:
    <input  formControlName="370" class="form-control  " (keydown)="handleNumber($event, 370 )" id="370" name="370" type="text" placeholder="Reference 1 Fax" maxlength="12"
    >
    <small class="text-danger" *ngIf="eyeForm.get('370').errors?.pattern">Required pattern xxx-xxx-xxxx</small>

  </td>
  <td colspan="4">
    Email address, if available:
    <input  formControlName="371" class="form-control  " id="371" name="371" type="text" placeholder="Reference 1 Email"
    [class.is-invalid]="(eyeForm.controls['371'].touched && eyeForm.controls['371'].invalid) || (eyeForm.controls['371'].invalid && isSubmit)"  >
    <div class="formLabel col-md-11"
  *ngIf="eyeForm.controls['371'].touched && eyeForm.controls['371'].invalid">
  <small class="text-danger" *ngIf="eyeForm.controls['371'].errors?.required">
      required
  </small>
    <small
    class="text-danger"
    *ngIf="eyeForm.controls['371'].errors?.pattern"
    >Please enter a valid email.</small
    >        
  </div>
  </td>
</tr>
<tr class="border">
  <td colspan="6">
 <div class="row border">
  <div class="col-md-12">
    Name of reference:
    <input  formControlName="372" class="form-control" id="372" name="372" type="text" placeholder="Reference 2 Name"
    [class.is-invalid]="(eyeForm.controls['372'].touched && eyeForm.controls['372'].invalid) || (eyeForm.controls['372'].invalid && isSubmit)">
    <div class="formLabel col-md-11"
    *ngIf="eyeForm.controls['372'].touched && eyeForm.controls['372'].invalid">
    <small class="text-danger" *ngIf="eyeForm.controls['372'].errors?.required">
        required
    </small>
    </div>
  </div>
 </div> 
 <div class="row border">
  <div class="col-md-12">
    Specialty:
    <ng-select
    [items]="specialty"
    [class.is-invalid]="
      (eyeForm.controls['380'].touched &&
        eyeForm.controls['380'].invalid) ||
      (eyeForm.controls['380'].invalid && isSubmit)
    "
    bindLabel="name"
    placeholder="Reference 2 Specialities"
    bindValue="id"
    formControlName="380"
  >
  </ng-select>
  <div
    class="formLabel col-md-11"
    *ngIf="
      eyeForm.controls['380'].touched &&
      eyeForm.controls['380'].invalid
    "
  >
    <small
      class="text-danger"
      *ngIf="eyeForm.controls['380'].errors"
    >
      required
    </small>
  </div> 
  </div>
 </div> 
 <div class="row border">
  <div class="col-md-12">
    Credentials:
    <input  formControlName="2723" class="form-control  " id="2723" name="2723" type="text" placeholder="Reference 2 Title"
    [class.is-invalid]="(eyeForm.controls['2723'].touched && eyeForm.controls['2723'].invalid) || (eyeForm.controls['2723'].invalid && isSubmit)" >
    <div class="formLabel col-md-11"
    *ngIf="eyeForm.controls['2723'].touched && eyeForm.controls['2723'].invalid">
    <small class="text-danger" *ngIf="eyeForm.controls['2723'].errors?.required">
        required
    </small>
    </div>
  </div>
 </div> 
 <div class="row border">
  <div class="col-md-12">
    Professional relationship:
    <input  formControlName="2724" class="form-control  " id="2724" name="2724" type="text" placeholder="Reference 2 Relation"
    [class.is-invalid]="(eyeForm.controls['2724'].touched && eyeForm.controls['2724'].invalid) || (eyeForm.controls['2724'].invalid && isSubmit)" >
    <div class="formLabel col-md-11"
    *ngIf="eyeForm.controls['2724'].touched && eyeForm.controls['2724'].invalid">
    <small class="text-danger" *ngIf="eyeForm.controls['2724'].errors?.required">
        required
    </small>
    </div>
  </div>
 </div> 
 
  
</td>
<td colspan="6">
  <label>Complete address, include department if applicable:</label>
  <input  formControlName="373" class="form-control  " id="373" name="373" type="text" placeholder="Reference 2 Address1"
  [class.is-invalid]="(eyeForm.controls['373'].touched && eyeForm.controls['373'].invalid) || (eyeForm.controls['373'].invalid && isSubmit)" >
  <div class="formLabel col-md-11"
    *ngIf="eyeForm.controls['373'].touched && eyeForm.controls['373'].invalid">
    <small class="text-danger" *ngIf="eyeForm.controls['373'].errors?.required">
        required
    </small>
    </div>
  <input  formControlName="374" class="form-control  " id="374" name="374" type="text" placeholder="Reference 2 Address2"
  >
  <input  formControlName="375" class="form-control  " id="375" name="375" type="text" placeholder="Reference 2 City"
  [class.is-invalid]="(eyeForm.controls['375'].touched && eyeForm.controls['375'].invalid) || (eyeForm.controls['375'].invalid && isSubmit)" >
  <div class="formLabel col-md-11"
    *ngIf="eyeForm.controls['375'].touched && eyeForm.controls['375'].invalid">
    <small class="text-danger" *ngIf="eyeForm.controls['375'].errors?.required">
        required
    </small>
    </div>
  <ng-select
    [items]="states"
    [class.is-invalid]="
      (eyeForm.controls['377'].touched &&
        eyeForm.controls['377'].invalid) ||
      (eyeForm.controls['377'].invalid && isSubmit)
    "
    bindLabel="name"
    placeholder="States"
    bindValue="id"
    formControlName="377"
   
  >
  </ng-select>       
    <div
    class="formLabel col-md-11"
    *ngIf="
      eyeForm.controls['377'].touched &&
      eyeForm.controls['377'].invalid
    "
  >
    <small
      class="text-danger"
      *ngIf="eyeForm.controls['377'].errors"
    >
      required
    </small>
  </div>
  <input  formControlName="378" class="form-control  " id="378" name="378" type="text" placeholder="Reference 2 Zip"
  [class.is-invalid]="(eyeForm.controls['378'].touched && eyeForm.controls['378'].invalid) || (eyeForm.controls['378'].invalid && isSubmit)" >
  <div
  class="formLabel col-md-2"
  *ngIf="
    eyeForm.controls['378'].invalid
  "
  >
  <small
    class="text-danger"
    *ngIf="eyeForm.controls['378'].errors"
  >
    required
  </small>
  <small
    class="text-danger"
    *ngIf="
      eyeForm.controls['378'].errors?.invalidZipCode
    "
  >
    Zip code must be atleast 5 digits and less than 10 digits
  </small>
  </div>
</td>
</tr>
<tr class="border">
  <td colspan="4">
    Telephone number:
    <input  formControlName="381" class="form-control  " (keydown)="handleNumber($event, 381 )" id="381" name="381" type="text" placeholder="Reference 2 Phone" maxlength="12"
    [class.is-invalid]="(eyeForm.controls['381'].touched && eyeForm.controls['381'].invalid) || (eyeForm.controls['381'].invalid && isSubmit)"  >
    <div class="formLabel col-md-11"
  *ngIf="eyeForm.controls['381'].touched && eyeForm.controls['381'].invalid">
  <small class="text-danger" *ngIf="eyeForm.controls['381'].errors?.required">
      required
  </small>
  <small class="text-danger" *ngIf="eyeForm.get('381').errors?.pattern">Required pattern xxx-xxx-xxxx</small>

  </div>
  </td>
  <td colspan="4">
    Fax number:
    <input  formControlName="382" class="form-control  " (keydown)="handleNumber($event, 382 )" id="382" name="382" type="text" placeholder="Reference 2 Fax" maxlength="12"
  >
  <small class="text-danger" *ngIf="eyeForm.get('382').errors?.pattern">Required pattern xxx-xxx-xxxx</small>

  </td>
  <td colspan="4">
    Email address, if available:
    <input  formControlName="383" class="form-control  " id="383" name="383" type="text" placeholder="Reference 2 Email"
    [class.is-invalid]="(eyeForm.controls['383'].touched && eyeForm.controls['383'].invalid) || (eyeForm.controls['383'].invalid && isSubmit)"  >
    <div class="formLabel col-md-11"
  *ngIf="eyeForm.controls['383'].touched && eyeForm.controls['383'].invalid">
  <small class="text-danger" *ngIf="eyeForm.controls['383'].errors?.required">
      required
  </small>
  <small
  class="text-danger"
  *ngIf="eyeForm.controls['383'].errors?.pattern"
  >Please enter a valid email.</small
  >
  </div>
  </td>
</tr>
<tr class="border">
  <td colspan="6">
 <div class="row border">
  <div class="col-md-12">
    Name of reference:
    <input  formControlName="384" class="form-control" id="384" name="384" type="text" placeholder="Reference 3 Name"
    [class.is-invalid]="(eyeForm.controls['384'].touched && eyeForm.controls['384'].invalid) || (eyeForm.controls['384'].invalid && isSubmit)">
    <div class="formLabel col-md-11"
    *ngIf="eyeForm.controls['384'].touched && eyeForm.controls['384'].invalid">
    <small class="text-danger" *ngIf="eyeForm.controls['384'].errors?.required">
        required
    </small>
    </div>
  </div>
 </div> 
 <div class="row border">
  <div class="col-md-12">
    Specialty:
    <ng-select
    [items]="specialty"
    [class.is-invalid]="
      (eyeForm.controls['393'].touched &&
        eyeForm.controls['393'].invalid) ||
      (eyeForm.controls['393'].invalid && isSubmit)
    "
    bindLabel="name"
    placeholder="Reference 3 Specialities"
    bindValue="id"
    formControlName="393"
  >
  </ng-select>
  <div
    class="formLabel col-md-11"
    *ngIf="
      eyeForm.controls['393'].touched &&
      eyeForm.controls['393'].invalid
    "
  >
    <small
      class="text-danger"
      *ngIf="eyeForm.controls['393'].errors"
    >
      required
    </small>
  </div> 
  </div>
 </div> 
 <div class="row border">
  <div class="col-md-12">
    Credentials:
    <input  formControlName="2725" class="form-control  " id="2725" name="2725" type="text" placeholder="Reference 3 Title"
    [class.is-invalid]="(eyeForm.controls['2725'].touched && eyeForm.controls['2725'].invalid) || (eyeForm.controls['2725'].invalid && isSubmit)" >
    <div class="formLabel col-md-11"
    *ngIf="eyeForm.controls['2725'].touched && eyeForm.controls['2725'].invalid">
    <small class="text-danger" *ngIf="eyeForm.controls['2725'].errors?.required">
        required
    </small>
    </div>
  </div>
 </div> 
 <div class="row border">
  <div class="col-md-12">
    Professional relationship:
    <input  formControlName="2726" class="form-control  " id="2726" name="2726" type="text" placeholder="Reference 3 Relation"
    [class.is-invalid]="(eyeForm.controls['2726'].touched && eyeForm.controls['2726'].invalid) || (eyeForm.controls['2726'].invalid && isSubmit)" >
    <div class="formLabel col-md-11"
    *ngIf="eyeForm.controls['2726'].touched && eyeForm.controls['2726'].invalid">
    <small class="text-danger" *ngIf="eyeForm.controls['2726'].errors?.required">
        required
    </small>
    </div>
  </div>
 </div> 
 
  
</td>
<td colspan="6">
  <label>Complete address, include department if applicable:</label>
  <input  formControlName="385" class="form-control  " id="385" name="385" type="text" placeholder="Reference 3 Address1"
  [class.is-invalid]="(eyeForm.controls['385'].touched && eyeForm.controls['385'].invalid) || (eyeForm.controls['385'].invalid && isSubmit)" >
  <div class="formLabel col-md-11"
    *ngIf="eyeForm.controls['385'].touched && eyeForm.controls['385'].invalid">
    <small class="text-danger" *ngIf="eyeForm.controls['385'].errors?.required">
        required
    </small>
    </div>
  <input  formControlName="386" class="form-control  " id="386" name="386" type="text" placeholder="Reference 3 Address2"
  >
  <input  formControlName="387" class="form-control  " id="387" name="387" type="text" placeholder="Reference 3 City"
  [class.is-invalid]="(eyeForm.controls['387'].touched && eyeForm.controls['387'].invalid) || (eyeForm.controls['387'].invalid && isSubmit)" >
  <div class="formLabel col-md-11"
    *ngIf="eyeForm.controls['387'].touched && eyeForm.controls['387'].invalid">
    <small class="text-danger" *ngIf="eyeForm.controls['387'].errors?.required">
        required
    </small>
    </div>
  <ng-select
    [items]="states"
    [class.is-invalid]="
      (eyeForm.controls['389'].touched &&
        eyeForm.controls['389'].invalid) ||
      (eyeForm.controls['389'].invalid && isSubmit)
    "
    bindLabel="name"
    placeholder="States"
    bindValue="id"
    formControlName="389"
   
  >
  </ng-select>       
    <div
    class="formLabel col-md-11"
    *ngIf="
      eyeForm.controls['389'].touched &&
      eyeForm.controls['389'].invalid
    "
  >
    <small
      class="text-danger"
      *ngIf="eyeForm.controls['389'].errors"
    >
      required
    </small>
  </div>
  <input  formControlName="390" class="form-control  " id="390" name="390" type="text" placeholder="Reference 3 Zip"
  [class.is-invalid]="(eyeForm.controls['390'].touched && eyeForm.controls['390'].invalid) || (eyeForm.controls['390'].invalid && isSubmit)" >
  <div
  class="formLabel col-md-2"
  *ngIf="
    eyeForm.controls['390'].invalid
  "
  >
  <small
    class="text-danger"
    *ngIf="eyeForm.controls['390'].errors"
  >
    required
  </small>
  <small
    class="text-danger"
    *ngIf="
      eyeForm.controls['390'].errors?.invalidZipCode
    "
  >
    Zip code must be atleast 5 digits and less than 10 digits
  </small>
  </div>
</td>
</tr>
<tr class="border">
  <td colspan="4">
    Telephone number:
    <input  formControlName="394" class="form-control  " id="394" (keydown)="handleNumber($event, 394 )" name="394" type="text" placeholder="Reference 3 Phone" maxlength="12"
    [class.is-invalid]="(eyeForm.controls['394'].touched && eyeForm.controls['394'].invalid) || (eyeForm.controls['394'].invalid && isSubmit)"  >
    <div class="formLabel col-md-11"
  *ngIf="eyeForm.controls['394'].touched && eyeForm.controls['394'].invalid">
  <small class="text-danger" *ngIf="eyeForm.controls['394'].errors?.required">
      required
  </small>
  <small class="text-danger" *ngIf="eyeForm.get('394').errors?.incorrectPhoneFormat">Required pattern xxx-xxx-xxxx</small>

  </div>
  </td>
  <td colspan="4">
    Fax number:
    <input  formControlName="395" class="form-control  " (keydown)="handleNumber($event, 395 )" id="395" name="395" type="text" placeholder="Reference 3 Fax" maxlength="12"
    >
    <small class="text-danger" *ngIf="eyeForm.get('395').errors?.pattern">Required pattern xxx-xxx-xxxx</small>

  </td>
  <td colspan="4">
    Email address, if available:
    <input  formControlName="396" class="form-control  " id="396" name="396" type="text" placeholder="Reference 3 Email"
    [class.is-invalid]="(eyeForm.controls['396'].touched && eyeForm.controls['396'].invalid) || (eyeForm.controls['396'].invalid && isSubmit)"  >
    <div class="formLabel col-md-11"
  *ngIf="eyeForm.controls['396'].touched && eyeForm.controls['396'].invalid">
  <small class="text-danger" *ngIf="eyeForm.controls['396'].errors?.required">
      required
  </small>
  <small
  class="text-danger"
  *ngIf="eyeForm.controls['396'].errors?.pattern"
  >Please enter a valid email.</small
  >
  </div>
  </td>
</tr>
<tr>
  <td colspan="12">
    <div class="centered texasFirstTable">
      <div class="row">
      <div class="col-md-8">
          <h2  style="margin-left: 31px;  font-size: 18px;"><b>XIX. Continuing Medical Education</b>
            
          </h2>
          <h6>Please list activities for which you have received CME credit(s) during the past two (2) years.</h6>
          <span class="normalParagraphStyle">
            <i> (Please attach additional sheets, if necessary.)</i>
          </span>
      </div>
      <div class="col-md-4">
          <label for="cmd_00106"><b>Does Not Apply:</b></label>&nbsp;&nbsp;
          <input type="checkbox" formControlName="cmd_00106"
              id="cmd_00106" value="1"   (ngModelChange)="handleNAchange( 'cmd_00106')" 
              >
  </div>
    </div>
  </div>
  </td>
</tr>
<tr class="border">
  <td colspan="4">
    Name:
    <ng-select [items]="CMEDropdown"
    [class.is-invalid]="
    (eyeForm.controls['add_1_2538'].invalid)
  "
     bindLabel="label"
      placeholder="CME Category"
     bindValue="id"
    formControlName="add_1_2538"
    (blur)="handleNAchange('cmd_00106', 'add_1_2538')"
    >
  </ng-select>       
    <div
    class="formLabel col-md-11"
    *ngIf="
      eyeForm.controls['add_1_2538'].touched &&
      eyeForm.controls['add_1_2538'].invalid
    "
  >
    <small
      class="text-danger"
      *ngIf="eyeForm.controls['add_1_2538'].errors"
    >
      required
    </small>
  </div>     
   
  </td>
  <td colspan="4">
    Month / year attended:    
    <div class="input-group-append">
      <input class="form-control onboard datepicker" formControlName="add_1_1374" (blur)="clearInputIfInvalid('add_1_1374','cmd_00106')"  (blur)="handleNAchange('cmd_00106', 'add_1_1374')"
      [class.is-invalid]="(eyeForm.controls['add_1_1374'].invalid)"
      ngbDatepicker #d176="ngbDatepicker" id="add_1_1374" name="add_1_1374" type="text" placeholder= "CME Start Date " data-id="add_1_1374">
     <button class="btn btn-outline-secondary calendar" (click)="d176.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
      </div>
  <div class="formLabel col-md-11"
  *ngIf="eyeForm.controls['add_1_1374'].touched && eyeForm.controls['add_1_1374'].invalid">
  <small class="text-danger" *ngIf="eyeForm.controls['add_1_1374'].errors?.required">
  required
  </small>
  </div>
  <div  *ngIf="eyeForm.get('add_1_1374').invalid ">
    <small class="text-danger" *ngIf="eyeForm.get('add_1_1374').errors?.invalidDateFormat">
      Only Date Format is allowed(MM/DD/YYYY)                  </small>
    <small class="text-danger" *ngIf="eyeForm.get('add_1_1374').errors?.invalidMonth">
    Month is out of range
    </small>
    <small class="text-danger" *ngIf="eyeForm.get('add_1_1374').errors?.invalidDateyear">
      year is out of range(1900-2099)                    </small>
      <small class="text-danger" *ngIf="eyeForm.get('add_1_1374').errors?.invalidDateRange">
        date is out of range
        </small>
    </div>
  </td>
  <td colspan="4">
    Hours:
    <input class="form-control onboard" formControlName="add_1_1016" (blur)="handleNAchange('cmd_00106', 'add_1_1016')"
    [class.is-invalid]="(eyeForm.controls['add_1_1016'].invalid)"
    id="add_1_1016" name="add_1_1016" type="text" placeholder= "CME Hours" data-id="managing_contact_name">         
    <div class="formLabel col-md-11"
    *ngIf="eyeForm.controls['add_1_1016'].touched && eyeForm.controls['add_1_1016'].invalid">
    <small class="text-danger" *ngIf="eyeForm.controls['add_1_1016'].errors?.required">
        required
    </small>
</div>
  </td>
</tr>
<tr class="border">
  <td colspan="4">
    Name:
    <ng-select [items]="CMEDropdown"
     bindLabel="label"
      placeholder="CME Category"
     bindValue="id"
    formControlName="add_2_2538"
    >
  </ng-select>         
   
  </td>
  <td colspan="4">
    Month / year attended:    
    <div class="input-group-append">
      <input class="form-control onboard datepicker" formControlName="add_2_1374" (blur)="clearInputIfInvalid('add_2_1374')"
      ngbDatepicker #d177="ngbDatepicker" id="add_2_1374" name="add_2_1374" type="text" placeholder= "CME Start Date " data-id="add_2_1374">
     <button class="btn btn-outline-secondary calendar" (click)="d177.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
      </div>
      <div  *ngIf="eyeForm.get('add_2_1374').invalid ">
        <small class="text-danger" *ngIf="eyeForm.get('add_2_1374').errors?.invalidDateFormat">
          Only Date Format is allowed(MM/DD/YYYY)                  </small>
        <small class="text-danger" *ngIf="eyeForm.get('add_2_1374').errors?.invalidMonth">
        Month is out of range
        </small>
        <small class="text-danger" *ngIf="eyeForm.get('add_2_1374').errors?.invalidDateyear">
          year is out of range(1900-2099)                    </small>
          <small class="text-danger" *ngIf="eyeForm.get('add_2_1374').errors?.invalidDateRange">
            date is out of range
            </small>
          </div>
  </td>
  <td colspan="4">
    Hours:
    <input class="form-control onboard" formControlName="add_2_1016" 
    id="add_2_1016" name="add_2_1016" type="text" placeholder= "CME Hours" data-id="managing_contact_name">         
  </td>
</tr>
<tr class="border">
  <td colspan="4">
    Name:
    <ng-select [items]="CMEDropdown"
     bindLabel="label"
      placeholder="CME Category"
     bindValue="id"
    formControlName="add_3_2538"
    >
  </ng-select>         
   
  </td>
  <td colspan="4">
    Month / year attended:    
    <div class="input-group-append">
      <input class="form-control onboard datepicker" formControlName="add_3_1374" (blur)="clearInputIfInvalid('add_3_1374')"
      ngbDatepicker #d178="ngbDatepicker" id="add_3_1374" name="add_3_1374" type="text" placeholder= "CME Start Date " data-id="add_3_1374">
     <button class="btn btn-outline-secondary calendar" (click)="d178.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
      </div>
      <div  *ngIf="eyeForm.get('add_3_1374').invalid ">
        <small class="text-danger" *ngIf="eyeForm.get('add_3_1374').errors?.invalidDateFormat">
          Only Date Format is allowed(MM/DD/YYYY)                  </small>
        <small class="text-danger" *ngIf="eyeForm.get('add_3_1374').errors?.invalidMonth">
        Month is out of range
        </small>
        <small class="text-danger" *ngIf="eyeForm.get('add_3_1374').errors?.invalidDateyear">
          year is out of range(1900-2099)                    </small>
          <small class="text-danger" *ngIf="eyeForm.get('add_3_1374').errors?.invalidDateRange">
            date is out of range
            </small>
          </div>
  </td>
  <td colspan="4">
    Hours:
    <input class="form-control onboard" formControlName="add_3_1016" 
    id="add_3_1016" name="add_3_1016" type="text" placeholder= "CME Hours" data-id="managing_contact_name">         
  </td>
</tr>
<tr class="border">
  <td colspan="4">
    Name:
    <ng-select [items]="CMEDropdown"
     bindLabel="label"
      placeholder="CME Category"
     bindValue="id"
    formControlName="add_4_2538"
    >
  </ng-select>         
   
  </td>
  <td colspan="4">
    Month / year attended:    
    <div class="input-group-append">
      <input class="form-control onboard datepicker" formControlName="add_4_1374" (blur)="clearInputIfInvalid('add_4_1374')"
      ngbDatepicker #d179="ngbDatepicker" id="add_4_1374" name="add_4_1374" type="text" placeholder= "CME Start Date " data-id="add_4_1374">
     <button class="btn btn-outline-secondary calendar" (click)="d179.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
      </div>
      <div  *ngIf="eyeForm.get('add_4_1374').invalid ">
        <small class="text-danger" *ngIf="eyeForm.get('add_4_1374').errors?.invalidDateFormat">
          Only Date Format is allowed(MM/DD/YYYY)                  </small>
        <small class="text-danger" *ngIf="eyeForm.get('add_4_1374').errors?.invalidMonth">
        Month is out of range
        </small>
        <small class="text-danger" *ngIf="eyeForm.get('add_4_1374').errors?.invalidDateyear">
          year is out of range(1900-2099)                    </small>
          <small class="text-danger" *ngIf="eyeForm.get('add_4_1374').errors?.invalidDateRange">
            date is out of range
            </small>
          </div>
  </td>
  <td colspan="4">
    Hours:
    <input class="form-control onboard" formControlName="add_4_1016" 
    id="add_4_1016" name="add_4_1016" type="text" placeholder= "CME Hours" data-id="managing_contact_name">         
  </td>
</tr>
<tr class="border">
  <td colspan="4">
    Name:
    <ng-select [items]="CMEDropdown"
     bindLabel="label"
      placeholder="CME Category"
     bindValue="id"
    formControlName="add_5_2538"
    >
  </ng-select>         
   
  </td>
  <td colspan="4">
    Month / year attended:    
    <div class="input-group-append">
      <input class="form-control onboard datepicker" formControlName="add_5_1374" (blur)="clearInputIfInvalid('add_5_1374')"
      ngbDatepicker #d180="ngbDatepicker" id="add_5_1374" name="add_5_1374" type="text" placeholder= "CME Start Date " data-id="add_5_1374">
     <button class="btn btn-outline-secondary calendar" (click)="d180.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
      </div>
      <div  *ngIf="eyeForm.get('add_5_1374').invalid ">
        <small class="text-danger" *ngIf="eyeForm.get('add_5_1374').errors?.invalidDateFormat">
          Only Date Format is allowed(MM/DD/YYYY)                  </small>
        <small class="text-danger" *ngIf="eyeForm.get('add_5_1374').errors?.invalidMonth">
        Month is out of range
        </small>
        <small class="text-danger" *ngIf="eyeForm.get('add_5_1374').errors?.invalidDateyear">
          year is out of range(1900-2099)                    </small>
          <small class="text-danger" *ngIf="eyeForm.get('add_5_1374').errors?.invalidDateRange">
            date is out of range
            </small>
          </div>
  </td>
  <td colspan="4">
    Hours:
    <input class="form-control onboard" formControlName="add_5_1016" 
    id="add_5_1016" name="add_5_1016" type="text" placeholder= "CME Hours" data-id="managing_contact_name">         
  </td>
</tr>
<tr class="border">
  <td colspan="4">
    Name:
    <ng-select [items]="CMEDropdown"
     bindLabel="label"
      placeholder="CME Category"
     bindValue="id"
    formControlName="add_6_2538"
    >
  </ng-select>         
   
  </td>
  <td colspan="4">
    Month / year attended:    
    <div class="input-group-append">
      <input class="form-control onboard datepicker" formControlName="add_6_1374" (blur)="clearInputIfInvalid('add_6_1374')"
      ngbDatepicker #d181="ngbDatepicker" id="add_6_1374" name="add_6_1374" type="text" placeholder= "CME Start Date " data-id="add_6_1374">
     <button class="btn btn-outline-secondary calendar" (click)="d181.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
      </div>
      <div  *ngIf="eyeForm.get('add_6_1374').invalid ">
        <small class="text-danger" *ngIf="eyeForm.get('add_6_1374').errors?.invalidDateFormat">
          Only Date Format is allowed(MM/DD/YYYY)                  </small>
        <small class="text-danger" *ngIf="eyeForm.get('add_6_1374').errors?.invalidMonth">
        Month is out of range
        </small>
        <small class="text-danger" *ngIf="eyeForm.get('add_6_1374').errors?.invalidDateyear">
          year is out of range(1900-2099)                    </small>
          <small class="text-danger" *ngIf="eyeForm.get('add_6_1374').errors?.invalidDateRange">
            date is out of range
            </small>
          </div>
  </td>
  <td colspan="4">
    Hours:
    <input class="form-control onboard" formControlName="add_6_1016" 
    id="add_6_1016" name="add_6_1016" type="text" placeholder= "CME Hours" data-id="managing_contact_name">         
  </td>
</tr>
<tr>
  <td colspan="12">
    <div class="centered texasFirstTable">
      <div class="row">
      <div class="col-md-8">
          <h2  style="margin-left: 31px;  font-size: 18px;"><b>XX. Professional Liability Insurance</b>
            
          </h2>
      </div>
      <div class="col-md-4">
          <label for="cmd_00107"><b>Does Not Apply:</b></label>&nbsp;&nbsp;
          <input type="checkbox" formControlName="cmd_00107"
              id="cmd_00107" value="1" (ngModelChange)="handleNAchange( 'cmd_00107')" 
              >
  </div>
    </div>
  </div>
  </td>
</tr>
<tr class="border">
  <td colspan="4">
    Current insurance carrier / provider of professional liability coverage:
    <input  formControlName="345" class="form-control  " id="345" name="345"  (blur)="handleNAchange('cmd_00107', 345)" type="text" placeholder="MLP Carrier Name"
    [class.is-invalid]="(eyeForm.controls['345'].invalid)"  >
    <div class="formLabel col-md-11"
  *ngIf="eyeForm.controls['345'].touched && eyeForm.controls['345'].invalid">
  <small class="text-danger" *ngIf="eyeForm.controls['345'].errors?.required">
      required
  </small>
  </div>
  </td>
  <td colspan="4">
    Policy number:
    <input  formControlName="346" class="form-control  "   (blur)="handleNAchange('cmd_00107', 346)" id="346" name="346" type="text" placeholder="MLP Policy Number"
    [class.is-invalid]="(eyeForm.controls['346'].invalid)"  >
    <div class="formLabel col-md-11"
  *ngIf="eyeForm.controls['346'].touched && eyeForm.controls['346'].invalid">
  <small class="text-danger" *ngIf="eyeForm.controls['346'].errors?.required">
      required
  </small>
  </div>
  </td>
  <td colspan="4">
    Type of coverage:
    <input  formControlName="347" class="form-control  " id="347" name="347"  (blur)="handleNAchange('cmd_00107', 347)" type="text" placeholder="MLP Type of Coverage"
    [class.is-invalid]="(eyeForm.controls['347'].invalid)"  >
    <div class="formLabel col-md-11"
  *ngIf="eyeForm.controls['347'].touched && eyeForm.controls['347'].invalid">
  <small class="text-danger" *ngIf="eyeForm.controls['347'].errors?.required">
      required
  </small>
  </div>
  </td>
</tr>
<tr class="border">
  <td colspan="3">
    Name of local contact:
    <input  formControlName="1240" class="form-control  " id="1240" name="1240" (blur)="handleNAchange('cmd_00107', 1240)" type="text" placeholder="MLP Agents Name"
    [class.is-invalid]="(eyeForm.controls['1240'].invalid)"  >
    <div class="formLabel col-md-11"
  *ngIf="eyeForm.controls['1240'].touched && eyeForm.controls['1240'].invalid">
  <small class="text-danger" *ngIf="eyeForm.controls['1240'].errors?.required">
      required
  </small>
  </div>
  </td>
  <td colspan="3">
    Mailing address 1:
    <input  formControlName="901" class="form-control  " id="901" name="901" (blur)="handleNAchange('cmd_00107', 901)" type="text" placeholder=" MLP Address 1"
    [class.is-invalid]="(eyeForm.controls['901'].invalid)"  >
    <div class="formLabel col-md-11"
  *ngIf="eyeForm.controls['901'].touched && eyeForm.controls['901'].invalid">
  <small class="text-danger" *ngIf="eyeForm.controls['901'].errors?.required">
      required
  </small>
  </div>
  </td>
  <td colspan="3">
    Mailing address2:
    <input  formControlName="902" class="form-control  " id="902" name="902" (blur)="handleNAchange('cmd_00107', 902)"  type="text" placeholder="MLP Address 2"
    [class.is-invalid]="(eyeForm.controls['902'].invalid)"  >
    <div class="formLabel col-md-11"
  *ngIf="eyeForm.controls['902'].touched && eyeForm.controls['902'].invalid">
  <small class="text-danger" *ngIf="eyeForm.controls['902'].errors?.required">
      required
  </small>
  </div>
  </td>
  <td colspan="3">
    MLP City
    <input  formControlName="903" class="form-control  " id="903" name="903" type="text" placeholder="MLP City" (blur)="handleNAchange('cmd_00107', 903)"
    [class.is-invalid]="(eyeForm.controls['903'].invalid)"  >
    <div class="formLabel col-md-11"
  *ngIf="eyeForm.controls['903'].touched && eyeForm.controls['903'].invalid">
  <small class="text-danger" *ngIf="eyeForm.controls['903'].errors?.required">
      required
  </small>
  </div>
  </td>
</tr>
<tr class="border">
  <td colspan="3">
    Contact’s telephone number:
    <input  formControlName="1266" class="form-control  " (keydown)="handleNumber($event, 1266 )" maxlength="12"  (blur)="handleNAchange('cmd_00107', 1266)" id="1266" name="1266" type="text" placeholder="MLP Phone" 
    [class.is-invalid]="(eyeForm.controls['1266'].invalid)"  >

    <div class="formLabel col-md-11"
  *ngIf=" eyeForm.controls['1266'].invalid">
  <small class="text-danger" *ngIf="eyeForm.controls['1266'].errors?.required &&eyeForm.controls['1266'].touched">
      required
  </small>
  <small class="text-danger" *ngIf="eyeForm.get('1266').errors ?.incorrectPhoneFormat">Required pattern xxx-xxx-xxxx</small>
  </div>
  </td>
  <td colspan="3">
    Fax number, if available:
    <input  formControlName="1273" class="form-control  " (keydown)="handleNumber($event, 1273 )" id="1273" name="1273" type="text" placeholder=" MLP Fax" maxlength="12"
>
<small class="text-danger" *ngIf="eyeForm.get('1273').errors?.pattern">Required pattern xxx-xxx-xxxx</small>

  </td>
  <td colspan="3">
    State:
    <ng-select
    [items]="states"
    [class.is-invalid]="
      (eyeForm.controls['905'].invalid)
    "
    bindLabel="name"
    placeholder="States"
    bindValue="id"
    formControlName="905"
    (blur)="handleNAchange('cmd_00107', 905)"
    style="width:170px"
  >
  </ng-select>       
    <div
    class="formLabel col-md-11"
    *ngIf="
      eyeForm.controls['905'].touched &&
      eyeForm.controls['905'].invalid
    "
  >
    <small
      class="text-danger"
      *ngIf="eyeForm.controls['905'].errors"
    >
      required
    </small>
  </div>
  </td>
  <td colspan="3">
    MLP Zip:
    <input  formControlName="906" class="form-control  "     (blur)="handleNAchange('cmd_00107', 906)"    id="906" name="906" type="text" placeholder=" MLP Zip"
    [class.is-invalid]="(eyeForm.controls['906'].invalid)"  >
    <div
    class="formLabel col-md-2"
    *ngIf="
      eyeForm.controls['906'].invalid
    "
  >
    <small
      class="text-danger"
      *ngIf="eyeForm.controls['906'].errors"
    >
      required
    </small>
    <small
      class="text-danger"
      *ngIf="
        eyeForm.controls['906'].errors?.invalidZipCode
      "
    >
      Zip code must be atleast 5 digits and less than 10 digits
    </small>
  </div>
  </td>
</tr>
<tr class="border">
  <td colspan="4">
    Per claim limit of liability:
    <ng-select [items]="preClaim" bindLabel="name" placeholder="Select per claim" bindValue="id"
    [class.is-invalid]="(eyeForm.controls['350'].invalid)"
    formControlName="350"(blur)="handleNAchange('cmd_00107', '350')" >
    
</ng-select>
    <div class="formLabel col-md-11"
  *ngIf="eyeForm.controls['350'].touched && eyeForm.controls['350'].invalid">
  <small class="text-danger" *ngIf="eyeForm.controls['350'].errors?.required">
      required
  </small>
  </div>
  </td>
  <td colspan="4">
    Aggregate amount:
    <ng-select [items]="aggregates" bindLabel="name" placeholder="Select Aggregate"  bindValue="id"
    [class.is-invalid]="(eyeForm.controls['351'].invalid)"
    formControlName="351"(blur)="handleNAchange('cmd_00107', 351)">
    </ng-select>
    <div class="formLabel col-md-11"
        *ngIf="eyeForm.controls['351'].touched && eyeForm.controls['351'].invalid">
        <small class="text-danger" *ngIf="eyeForm.controls['351'].errors?.required">
            required
        </small>
    </div>
  </td>
  <td colspan="4">
    Contact’s email address, if available:
    <input  formControlName="1639" class="form-control  "  (blur)="handleNAchange('cmd_00107', 1639)" id="1639" name="1639" type="text" placeholder=" MLP Email"
    [class.is-invalid]="(eyeForm.controls['1639'].invalid)"  >
    <div class="formLabel col-md-11"
  *ngIf="eyeForm.controls['1639'].touched && eyeForm.controls['1639'].invalid">
  <small class="text-danger" *ngIf="eyeForm.controls['1639'].errors?.required">
      required
  </small>
  </div>
  <small
  class="text-danger"
  *ngIf="eyeForm.controls['1639'].invalid"
  >Please enter a valid email.</small
  >
  </td>
</tr>
<tr class="border">
  <td colspan="4">
    Month / day / year effective:    
    <div class="input-group-append">
      <input class="form-control onboard datepicker" formControlName="348" (blur)="clearInputIfInvalid('348','cmd_00107')"  (blur)="handleNAchange('cmd_00107', 348)"
      [class.is-invalid]="(eyeForm.controls['348'].invalid)"
      ngbDatepicker #d192="ngbDatepicker" id="348" name="348" type="text" placeholder= "MLP Issue Date" data-id="348">
     <button class="btn btn-outline-secondary calendar" (click)="d192.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
      </div>
  <div class="formLabel col-md-11"
  *ngIf="eyeForm.controls['348'].touched && eyeForm.controls['348'].invalid">
  <small class="text-danger" *ngIf="eyeForm.controls['348'].errors?.required">
  required
  </small>
  </div>
  <div  *ngIf="eyeForm.get('348').invalid ">
    <small class="text-danger" *ngIf="eyeForm.get('348').errors?.invalidDateFormat">
      Only Date Format is allowed(MM/DD/YYYY)                  </small>
    <small class="text-danger" *ngIf="eyeForm.get('348').errors?.invalidMonth">
    Month is out of range
    </small>
    <small class="text-danger" *ngIf="eyeForm.get('348').errors?.invalidDateyear">
      year is out of range(1900-2099)                    </small>
      <small class="text-danger" *ngIf="eyeForm.get('348').errors?.invalidDateRange">
        date is out of range
        </small>
      </div>
  </td>
  <td colspan="4">
    Month / day / year retroactive date, if applicable:
    <div class="input-group-append">
      <input class="form-control onboard datepicker" formControlName="945"  (blur)="handleNAchange('cmd_00107', 945)" (blur)="clearInputIfInvalid('945','cmd_00107')"
      [class.is-invalid]="(eyeForm.controls['945'].invalid)"
      ngbDatepicker #d193="ngbDatepicker" id="945" name="945" type="text" placeholder= "MLP Retroactive Date" data-id="945">
     <button class="btn btn-outline-secondary calendar" (click)="d193.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
      </div>
  <div class="formLabel col-md-11"
  *ngIf="eyeForm.controls['945'].touched && eyeForm.controls['945'].invalid">
  <small class="text-danger" *ngIf="eyeForm.controls['945'].errors?.required">
  required
  </small>
  </div>
  <div  *ngIf="eyeForm.get('945').invalid ">
    <small class="text-danger" *ngIf="eyeForm.get('945').errors?.invalidDateFormat">
      Only Date Format is allowed(MM/DD/YYYY)                  </small>
    <small class="text-danger" *ngIf="eyeForm.get('945').errors?.invalidMonth">
    Month is out of range
    </small>
    <small class="text-danger" *ngIf="eyeForm.get('945').errors?.invalidDateyear">
      year is out of range(1900-2099)                    </small>
      <small class="text-danger" *ngIf="eyeForm.get('945').errors?.invalidDateRange">
        date is out of range
        </small>
      </div>
  </td>
  <td colspan="4">
    Month / day / year of expiration:
    <div class="input-group-append">
      <input class="form-control onboard datepicker" formControlName="349"  (blur)="handleNAchange('cmd_00107', 349)" (blur)="clearInputIfInvalid('349','cmd_00107')"
      [class.is-invalid]="(eyeForm.controls['349'].invalid)"
      ngbDatepicker #d185="ngbDatepicker" id="349" name="349" type="text" placeholder= "MLP Expiration Date" data-id="349">
     <button class="btn btn-outline-secondary calendar" (click)="d185.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
      </div>
  <div class="formLabel col-md-11"
  *ngIf="eyeForm.controls['349'].touched && eyeForm.controls['349'].invalid">
  <small class="text-danger" *ngIf="eyeForm.controls['349'].errors?.required">
  required
  </small>
  </div>
  <div  *ngIf="eyeForm.get('349').invalid ">
    <small class="text-danger" *ngIf="eyeForm.get('349').errors?.invalidDateFormat">
      Only Date Format is allowed(MM/DD/YYYY)                  </small>
    <small class="text-danger" *ngIf="eyeForm.get('349').errors?.invalidMonth">
    Month is out of range
    </small>
    <small class="text-danger" *ngIf="eyeForm.get('349').errors?.invalidDateyear">
      year is out of range(1900-2099)                    </small>
      <small class="text-danger" *ngIf="eyeForm.get('349').errors?.invalidDateRange">
        date is out of range
        </small>
      </div>
  </td>
</tr>
<tr>
  <td colspan="12">
    <div class="centered texasFirstTable">
      <div class="row">
      <div class="col-md-8">
          <h2  style="margin-left: 31px;  font-size: 18px;"><b>Please list all previous professional liability carriers within the past five (5) years</b>
          </h2>
          <span>
            <i>(Please attach additional sheets, if necessary.)</i>
          </span>
      </div>
      <div class="col-md-4">
          <label for="cmd_00108"><b>Does Not Apply:</b></label>&nbsp;&nbsp;
          <input type="checkbox" formControlName="cmd_00108"
              id="cmd_00108" value="1" (ngModelChange)="handleNAchange( 'cmd_00108')" 
              >
  </div>
    </div>
  </div>
  </td>
</tr>
<tr class="border">
  <td colspan="4">
    Insurance carrier / provider of professional liability coverage:
    <input  formControlName="add_1_1242" class="form-control" id="add_1_1242" name="add_1_1242"  (blur)="handleNAchange('cmd_00108', 'add_1_1242')" type="text" placeholder="Previous MLP Carriers"
    [class.is-invalid]="(eyeForm.controls['add_1_1242'].invalid)"  >
    <div class="formLabel col-md-11"
  *ngIf="eyeForm.controls['add_1_1242'].touched && eyeForm.controls['add_1_1242'].invalid">
  <small class="text-danger" *ngIf="eyeForm.controls['add_1_1242'].errors?.required">
      required
  </small>
  </div>
  </td>
  <td colspan="4">
    Policy number:
    <input  formControlName="add_1_1256" class="form-control"   (blur)="handleNAchange('cmd_00108', 'add_1_1256')" id="add_1_1256" name="add_1_1256" type="text" placeholder="Previous MLP Policy Number"
    [class.is-invalid]="(eyeForm.controls['add_1_1256'].invalid)"  >
    <div class="formLabel col-md-11"
  *ngIf="eyeForm.controls['add_1_1256'].touched && eyeForm.controls['add_1_1256'].invalid">
  <small class="text-danger" *ngIf="eyeForm.controls['add_1_1256'].errors?.required">
      required
  </small>
  </div>
  </td>
  <td colspan="4">
    Type of coverage:
    <input  formControlName="add_1_1257" class="form-control  " id="add_1_1257" name="add_1_1257"  (blur)="handleNAchange('cmd_00108', 'add_1_1257')" type="text" placeholder="Previous MLP Type of Coverage"
    [class.is-invalid]="(eyeForm.controls['add_1_1257'].invalid)"  >
    <div class="formLabel col-md-11"
  *ngIf="eyeForm.controls['add_1_1257'].touched && eyeForm.controls['add_1_1257'].invalid">
  <small class="text-danger" *ngIf="eyeForm.controls['add_1_1257'].errors?.required">
      required
  </small>
  </div>
  </td>
</tr>
<tr class="border">
  <td colspan="3">
    Name of local contact:
    <input  formControlName="add_1_1243" class="form-control  " id="add_1_1243" name="add_1_1243" (blur)="handleNAchange('cmd_00108', 'add_1_1243')" type="text" placeholder="Previous MLP Agent"
    [class.is-invalid]="(eyeForm.controls['add_1_1243'].invalid)"  >
    <div class="formLabel col-md-11"
  *ngIf="eyeForm.controls['add_1_1243'].touched && eyeForm.controls['add_1_1243'].invalid">
  <small class="text-danger" *ngIf="eyeForm.controls['add_1_1243'].errors?.required">
      required
  </small>
  </div>
  </td>
  <td colspan="3">
    Mailing address 1:
    <input  formControlName="add_1_1244" class="form-control  " id="add_1_1244" name="add_1_1244" (blur)="handleNAchange('cmd_00108', 'add_1_1244')" type="text" placeholder="Previous MLP Address 1"
    [class.is-invalid]="(eyeForm.controls['add_1_1244'].invalid)"  >
    <div class="formLabel col-md-11"
  *ngIf="eyeForm.controls['add_1_1244'].touched && eyeForm.controls['add_1_1244'].invalid">
  <small class="text-danger" *ngIf="eyeForm.controls['add_1_1244'].errors?.required">
      required
  </small>
  </div>
  </td>
  <td colspan="3">
    Mailing address2:
    <input  formControlName="add_1_1245" class="form-control  " id="add_1_1245" name="add_1_1245" (blur)="handleNAchange('cmd_00108', 'add_1_1245')"  type="text" placeholder=" Previous MLP Address 2"
    [class.is-invalid]="(eyeForm.controls['add_1_1245'].invalid)"  >
    <div class="formLabel col-md-11"
  *ngIf="eyeForm.controls['add_1_1245'].touched && eyeForm.controls['add_1_1245'].invalid">
  <small class="text-danger" *ngIf="eyeForm.controls['add_1_1245'].errors?.required">
      required
  </small>
  </div>
  </td>
  <td colspan="3">
    MLP City
    <input  formControlName="add_1_1246" class="form-control  " id="add_1_1246" name="add_1_1246" type="text" placeholder=" Previous MLP City" (blur)="handleNAchange('cmd_00108', 'add_1_1246')"
    [class.is-invalid]="(eyeForm.controls['add_1_1246'].invalid)"  >
    <div class="formLabel col-md-11"
  *ngIf="eyeForm.controls['add_1_1246'].touched && eyeForm.controls['add_1_1246'].invalid">
  <small class="text-danger" *ngIf="eyeForm.controls['add_1_1246'].errors?.required">
      required
  </small>
  </div>
  </td>
</tr>
<tr class="border">
  <td colspan="3">
    Contact’s telephone number:
    <input  formControlName="add_1_1254" class="form-control  " (keydown)="handleNumber($event, 'add_1_1254' )" (blur)="handleNAchange('cmd_00108', 'add_1_1254')" id="add_1_1254" name="add_1_1254" type="text" placeholder="Previous MLP Phone" maxlength="12"
    [class.is-invalid]="(eyeForm.controls['add_1_1254'].invalid)"  >
    <div class="formLabel col-md-11"
  *ngIf="eyeForm.controls['add_1_1254'].touched && eyeForm.controls['add_1_1254'].invalid">
  <small class="text-danger" *ngIf="eyeForm.controls['add_1_1254'].errors?.required">
      required
  </small>
  <small class="text-danger" *ngIf="eyeForm.get('add_1_1254').errors ?.incorrectPhoneFormat">Required pattern xxx-xxx-xxxx</small>

  </div>
  </td>
  <td colspan="3">
    Fax number, if available:
    <input  formControlName="add_1_1255" class="form-control  " (keydown)="handleNumber($event, 'add_1_1255' )" id="add_1_1255" name="add_1_1255" type="text" placeholder="Previous MLP Fax" maxlength="12"
>
<small class="text-danger" *ngIf="eyeForm.get('add_1_1255').errors?.pattern">Required pattern xxx-xxx-xxxx</small>

  </td>
  <td colspan="3">
    State:
    <ng-select
    [items]="states"
    [class.is-invalid]="
      (eyeForm.controls['add_1_1248'].invalid)
    "
    bindLabel="name"
    placeholder="States"
    bindValue="id"
    formControlName="add_1_1248"
    (blur)="handleNAchange('cmd_00108', 'add_1_1248')"
    style="width:170px"
  >
  </ng-select>       
    <div
    class="formLabel col-md-11"
    *ngIf="
      eyeForm.controls['add_1_1248'].touched &&
      eyeForm.controls['add_1_1248'].invalid
    "
  >
    <small
      class="text-danger"
      *ngIf="eyeForm.controls['add_1_1248'].errors"
    >
      required
    </small>
  </div>
  </td>
  <td colspan="3">
    MLP Zip:
    <input  formControlName="add_1_1249" class="form-control  "     (blur)="handleNAchange('cmd_00108', 'add_1_1249')"    id="add_1_1249" name="add_1_1249" type="text" placeholder="Previous MLP Zip"
    [class.is-invalid]="(eyeForm.controls['add_1_1249'].invalid)"  >
    <div
    class="formLabel col-md-2"
    *ngIf="
      eyeForm.controls['add_1_1249'].invalid
    "
  >
    <small
      class="text-danger"
      *ngIf="eyeForm.controls['add_1_1249'].errors"
    >
      required
    </small>
    <small
      class="text-danger"
      *ngIf="
        eyeForm.controls['add_1_1249'].errors?.invalidZipCode
      "
    >
      Zip code must be atleast 5 digits and less than 10 digits
    </small>
  </div>

  </td>
</tr>
<tr class="border">
  <td colspan="4">
    Per claim limit of liability:
    <ng-select [items]="preClaim" bindLabel="name" placeholder="Select per claim" bindValue="id"
    [class.is-invalid]="(eyeForm.controls['add_1_1264'].invalid)"
    formControlName="add_1_1264"(blur)="handleNAchange('cmd_00108', 'add_1_1264')" >
    
</ng-select>
    <div class="formLabel col-md-11"
  *ngIf="eyeForm.controls['add_1_1264'].touched && eyeForm.controls['add_1_1264'].invalid">
  <small class="text-danger" *ngIf="eyeForm.controls['add_1_1264'].errors?.required">
      required
  </small>
  </div>
  </td>
  <td colspan="4">
    Aggregate amount:
    <ng-select [items]="aggregates" bindLabel="name" placeholder="Aggregate"  bindValue="id"
    [class.is-invalid]="(eyeForm.controls['add_1_1265'].invalid)"
    formControlName="add_1_1265"(blur)="handleNAchange('cmd_00108', 'add_1_1265')">
    </ng-select>
    <div class="formLabel col-md-11"
        *ngIf="eyeForm.controls['add_1_1265'].touched && eyeForm.controls['add_1_1265'].invalid">
        <small class="text-danger" *ngIf="eyeForm.controls['add_1_1265'].errors?.required">
            required
        </small>
    </div>
  </td>
  <td colspan="4">
    Contact’s email address, if available:
    <input  formControlName="add_1_1857" class="form-control  "  (blur)="handleNAchange('cmd_00108', 'add_1_1857')" id="add_1_1857" name="add_1_1857" type="text" placeholder="Previous MLP Email"
    [class.is-invalid]="(eyeForm.controls['add_1_1857'].invalid)"  >
    <div class="formLabel col-md-11"
  *ngIf="eyeForm.controls['add_1_1857'].touched && eyeForm.controls['add_1_1857'].invalid">
  <small class="text-danger" *ngIf="eyeForm.controls['add_1_1857'].errors?.required">
      required
  </small>
  </div>
  <small
  class="text-danger"
  *ngIf="eyeForm.controls['add_1_1857'].invalid"
  >Please enter a valid email.</small
  >
  </td>
</tr>
<tr class="border">
  <td colspan="4">
    Month / day / year effective:    
    <div class="input-group-append">
      <input class="form-control onboard datepicker" formControlName="add_1_4637" (blur)="clearInputIfInvalid('add_1_4637','cmd_00108')"  (blur)="handleNAchange('cmd_00108', 'add_1_4637')"
      [class.is-invalid]="(eyeForm.controls['add_1_4637'].invalid)"
      ngbDatepicker #d202="ngbDatepicker" id="add_1_4637" name="add_1_4637" type="text" placeholder= " Previous MLP Issue Date" data-id="add_1_4637">
     <button class="btn btn-outline-secondary calendar" (click)="d202.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
      </div>
  <div class="formLabel col-md-11"
  *ngIf="eyeForm.controls['add_1_4637'].touched && eyeForm.controls['add_1_4637'].invalid">
  <small class="text-danger" *ngIf="eyeForm.controls['add_1_4637'].errors?.required">
  required
  </small>
  </div>
  <div  *ngIf="eyeForm.get('add_1_4637').invalid ">
    <small class="text-danger" *ngIf="eyeForm.get('add_1_4637').errors?.invalidDateFormat">
      Only Date Format is allowed(MM/DD/YYYY)                  </small>
    <small class="text-danger" *ngIf="eyeForm.get('add_1_4637').errors?.invalidMonth">
    Month is out of range
    </small>
    <small class="text-danger" *ngIf="eyeForm.get('add_1_4637').errors?.invalidDateyear">
      year is out of range(1900-2099)                    </small>
      <small class="text-danger" *ngIf="eyeForm.get('add_1_4637').errors?.invalidDateRange">
        date is out of range
        </small>
      </div>
  </td>
  <td colspan="4">
    Month / day / year retroactive date, if applicable:
    <div class="input-group-append">
      <input class="form-control onboard datepicker" formControlName="add_1_1251"  (blur)="handleNAchange('cmd_00108', 'add_1_1251')" (blur)="clearInputIfInvalid('add_1_1251','cmd_00108')"  
      [class.is-invalid]="(eyeForm.controls['add_1_1251'].invalid)"
      ngbDatepicker #d199="ngbDatepicker" id="add_1_1251" name="add_1_1251" type="text" placeholder= "Previous MLP Retroactive Date" data-id="add_1_1251">
     <button class="btn btn-outline-secondary calendar" (click)="d199.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
      </div>
  <div class="formLabel col-md-11"
  *ngIf="eyeForm.controls['add_1_1251'].touched && eyeForm.controls['add_1_1251'].invalid">
  <small class="text-danger" *ngIf="eyeForm.controls['add_1_1251'].errors?.required">
  required
  </small>
  </div>
  <div  *ngIf="eyeForm.get('add_1_1251').invalid ">
    <small class="text-danger" *ngIf="eyeForm.get('add_1_1251').errors?.invalidDateFormat">
      Only Date Format is allowed(MM/DD/YYYY)                  </small>
    <small class="text-danger" *ngIf="eyeForm.get('add_1_1251').errors?.invalidMonth">
    Month is out of range
    </small>
    <small class="text-danger" *ngIf="eyeForm.get('add_1_1251').errors?.invalidDateyear">
      year is out of range(1900-2099)                    </small>
      <small class="text-danger" *ngIf="eyeForm.get('add_1_1251').errors?.invalidDateRange">
        date is out of range
        </small>
      </div>
  </td>
  <td colspan="4">
    Month / day / year of expiration:
    <div class="input-group-append">
      <input class="form-control onboard datepicker" formControlName="add_1_1253"  (blur)="handleNAchange('cmd_00108', 'add_1_1253')"  (blur)="clearInputIfInvalid('add_1_1253','cmd_00108')"
      [class.is-invalid]="(eyeForm.controls['add_1_1253'].invalid)"
      ngbDatepicker #d189="ngbDatepicker" id="add_1_1253" name="add_1_1253" type="text" placeholder= "Previous Expiration Date" data-id="add_1_1253">
     <button class="btn btn-outline-secondary calendar" (click)="d189.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
      </div>
  <div class="formLabel col-md-11"
  *ngIf="eyeForm.controls['add_1_1253'].touched && eyeForm.controls['add_1_1253'].invalid">
  <small class="text-danger" *ngIf="eyeForm.controls['add_1_1253'].errors?.required">
  required
  </small>
  </div>
  <div  *ngIf="eyeForm.get('add_1_1253').invalid ">
    <small class="text-danger" *ngIf="eyeForm.get('add_1_1253').errors?.invalidDateFormat">
      Only Date Format is allowed(MM/DD/YYYY)                  </small>
    <small class="text-danger" *ngIf="eyeForm.get('add_1_1253').errors?.invalidMonth">
    Month is out of range
    </small>
    <small class="text-danger" *ngIf="eyeForm.get('add_1_1253').errors?.invalidDateyear">
      year is out of range(1900-2099)                    </small>
      <small class="text-danger" *ngIf="eyeForm.get('add_1_1253').errors?.invalidDateRange">
        date is out of range
        </small>
      </div>
  </td>
</tr>
<tr class="border">
  <td colspan="4">
    Insurance carrier / provider of professional liability coverage:
    <input  formControlName="add_2_1242" class="form-control" id="add_2_1242" name="add_2_1242"   type="text" placeholder="Previous MLP Carriers"
    >
  
  </td>
  <td colspan="4">
    Policy number:
    <input  formControlName="add_2_1256" class="form-control"    id="add_2_1256" name="add_2_1256" type="text" placeholder="Previous MLP Policy Number"
   >
 
  <td colspan="4">
    Type of coverage:
    <input  formControlName="add_2_1257" class="form-control  " id="add_2_1257" name="add_2_1257"   type="text" placeholder="Previous MLP Type of Coverage"
     >
  
  </td>
</tr>
<tr class="border">
  <td colspan="3">
    Name of local contact:
    <input  formControlName="add_2_1243" class="form-control  " id="add_2_1243" name="add_2_1243"  type="text" placeholder="Previous MLP Agent"
     >
  
  </td>
  <td colspan="3">
    Mailing address 1:
    <input  formControlName="add_2_1244" class="form-control  " id="add_2_1244" name="add_2_1244"  type="text" placeholder="Previous MLP Address 1"
     >

  </td>
  <td colspan="3">
    Mailing address2:
    <input  formControlName="add_2_1245" class="form-control  " id="add_2_1245" name="add_2_1245"  type="text" placeholder=" Previous MLP Address 2"
    >
   
  </td>
  <td colspan="3">
    MLP City
    <input  formControlName="add_2_1246" class="form-control  " id="add_2_1246" name="add_2_1246" type="text" placeholder=" Previous MLP City" 
    >
  
  </td>
</tr>
<tr class="border">
  <td colspan="3">
    Contact’s telephone number:
    <input  formControlName="add_2_1254" class="form-control  " (keydown)="handleNumber($event, 'add_2_1254' )"  id="add_2_1254" name="add_2_1254" type="text" placeholder="Previous MLP Phone" maxlength="12"
    >
    <small class="text-danger" *ngIf="eyeForm.get('add_2_1254').errors?.pattern">Required pattern xxx-xxx-xxxx</small>

  </td>
  <td colspan="3">
    Fax number, if available:
    <input  formControlName="add_2_1255" class="form-control  " (keydown)="handleNumber($event, 'add_2_1255' )" id="add_2_1255" name="add_2_1255" type="text" placeholder="Previous MLP Fax" maxlength="12"
>
<small class="text-danger" *ngIf="eyeForm.get('add_2_1255').errors?.pattern">Required pattern xxx-xxx-xxxx</small>

  </td>
  <td colspan="3">
    State:
    <ng-select
    [items]="states"
    bindLabel="name"
    placeholder="States"
    bindValue="id"
    formControlName="add_2_1248"
    style="width:170px"
    
  >
  </ng-select>       
  </td>
  <td colspan="3">
    MLP Zip:
    <input  formControlName="add_2_1249" class="form-control  "        id="add_2_1249" name="add_2_1249" type="text" placeholder="Previous MLP Zip"
    >
    <small
        class="text-danger"
        *ngIf="eyeForm.controls['add_2_1249'].errors?.pattern"
        > Zip code must be atleast 5 digits and less than 10 digits.</small
      >
  </td>
</tr>
<tr class="border">
  <td colspan="4">
    Per claim limit of liability:
    <ng-select [items]="preClaim" bindLabel="name" placeholder=" per claim" bindValue="id"
    formControlName="add_2_1264" >   
</ng-select> 
  </td>
  <td colspan="4">
    Aggregate amount:
    <ng-select [items]="aggregates" bindLabel="name" placeholder=" Aggregate"  bindValue="id"
    formControlName="add_2_1265">
    </ng-select> 
  </td>
  <td colspan="4">
    Contact’s email address, if available:
    <input  formControlName="add_2_1857" class="form-control  "   id="add_2_1857" name="add_2_1857" type="text" placeholder="Previous MLP Email"
     >
     <small
  class="text-danger"
  *ngIf="eyeForm.controls['add_2_1857'].errors?.pattern"
  >Please enter a valid email.</small
  >
  </td>
</tr>
<tr class="border">
  <td colspan="4">
    Month / day / year effective:    
    <div class="input-group-append">
      <input class="form-control onboard datepicker" formControlName="add_2_4637"   (blur)="clearInputIfInvalid('add_2_4637')" 
      ngbDatepicker #d206="ngbDatepicker" id="add_2_4637" name="add_2_4637" type="text" placeholder= " Previous MLP Issue Date" data-id="add_2_4637">
     <button class="btn btn-outline-secondary calendar" (click)="d206.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
      </div>
      <div  *ngIf="eyeForm.get('add_2_4637').invalid ">
        <small class="text-danger" *ngIf="eyeForm.get('add_2_4637').errors?.invalidDateFormat">
          Only Date Format is allowed(MM/DD/YYYY)                  </small>
        <small class="text-danger" *ngIf="eyeForm.get('add_2_4637').errors?.invalidMonth">
        Month is out of range
        </small>
        <small class="text-danger" *ngIf="eyeForm.get('add_2_4637').errors?.invalidDateyear">
          year is out of range(1900-2099)                    </small>
          <small class="text-danger" *ngIf="eyeForm.get('add_2_4637').errors?.invalidDateRange">
            date is out of range
            </small>
          </div>
  </td>
  <td colspan="4">
    Month / day / year retroactive date, if applicable:
    <div class="input-group-append">
      <input class="form-control onboard datepicker" formControlName="add_2_1251"     (blur)="clearInputIfInvalid('add_2_1251')" 
      ngbDatepicker #d400="ngbDatepicker" id="add_2_1251" name="add_2_1251" type="text" placeholder= "Previous MLP Retroactive Date" data-id="add_2_1251">
     <button class="btn btn-outline-secondary calendar" (click)="d400.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
      </div>
      <div  *ngIf="eyeForm.get('add_2_1251').invalid ">
        <small class="text-danger" *ngIf="eyeForm.get('add_2_1251').errors?.invalidDateFormat">
          Only Date Format is allowed(MM/DD/YYYY)                  </small>
        <small class="text-danger" *ngIf="eyeForm.get('add_2_1251').errors?.invalidMonth">
        Month is out of range
        </small>
        <small class="text-danger" *ngIf="eyeForm.get('add_2_1251').errors?.invalidDateyear">
          year is out of range(1900-2099)                    </small>
          <small class="text-danger" *ngIf="eyeForm.get('add_2_1251').errors?.invalidDateRange">
            date is out of range
            </small>
          </div>
  </td>
  <td colspan="4">
    Month / day / year of expiration:
    <div class="input-group-append">
      <input class="form-control onboard datepicker" formControlName="add_2_1253"   (blur)="clearInputIfInvalid('add_2_1253')"
      ngbDatepicker #d205="ngbDatepicker" id="add_2_1253" name="add_2_1253" type="text" placeholder= "Previous Expiration Date" data-id="add_2_1253">
     <button class="btn btn-outline-secondary calendar" (click)="d205.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
      </div>
      <div  *ngIf="eyeForm.get('add_2_1253').invalid ">
        <small class="text-danger" *ngIf="eyeForm.get('add_2_1253').errors?.invalidDateFormat">
          Only Date Format is allowed(MM/DD/YYYY)                  </small>
        <small class="text-danger" *ngIf="eyeForm.get('add_2_1253').errors?.invalidMonth">
        Month is out of range
        </small>
        <small class="text-danger" *ngIf="eyeForm.get('add_2_1253').errors?.invalidDateyear">
          year is out of range(1900-2099)                    </small>
          <small class="text-danger" *ngIf="eyeForm.get('add_2_1253').errors?.invalidDateRange">
            date is out of range
            </small>
          </div>
  </td>
</tr>
<tr class="border">
  <td colspan="4">
    Insurance carrier / provider of professional liability coverage:
    <input  formControlName="add_3_1242" class="form-control" id="add_3_1242" name="add_3_1242"   type="text" placeholder="Previous MLP Carriers"
    >
  
  </td>
  <td colspan="4">
    Policy number:
    <input  formControlName="add_3_1256" class="form-control"    id="add_3_1256" name="add_3_1256" type="text" placeholder="Previous MLP Policy Number"
   >
 
  <td colspan="4">
    Type of coverage:
    <input  formControlName="add_3_1257" class="form-control  " id="add_3_1257" name="add_3_1257"   type="text" placeholder="Previous MLP Type of Coverage"
     >
  
  </td>
</tr>
<tr class="border">
  <td colspan="3">
    Name of local contact:
    <input  formControlName="add_3_1243" class="form-control  " id="add_3_1243" name="add_3_1243"  type="text" placeholder="Previous MLP Agent"
     >
  
  </td>
  <td colspan="3">
    Mailing address 1:
    <input  formControlName="add_3_1244" class="form-control  " id="add_3_1244" name="add_3_1244"  type="text" placeholder="Previous MLP Address 1"
     >

  </td>
  <td colspan="3">
    Mailing address2:
    <input  formControlName="add_3_1245" class="form-control  " id="add_3_1245" name="add_3_1245"  type="text" placeholder=" Previous MLP Address 2"
    >
   
  </td>
  <td colspan="3">
    MLP City
    <input  formControlName="add_3_1246" class="form-control  " id="add_3_1246" name="add_3_1246" type="text" placeholder=" Previous MLP City" 
    >
  
  </td>
</tr>
<tr class="border">
  <td colspan="3">
    Contact’s telephone number:
    <input  formControlName="add_3_1254" class="form-control  " (keydown)="handleNumber($event, 'add_3_1254' )"  id="add_3_1254" name="add_3_1254" type="text" placeholder="Previous MLP Phone" maxlength="12"
    >
    <small class="text-danger" *ngIf="eyeForm.get('add_3_1254').errors?.pattern">Required pattern xxx-xxx-xxxx</small>

  </td>
  <td colspan="3">
    Fax number, if available:
    <input  formControlName="add_3_1255" class="form-control  " (keydown)="handleNumber($event, 'add_3_1255' )" id="add_3_1255" name="add_3_1255" type="text" placeholder="Previous MLP Fax" maxlength="12"
>
<small class="text-danger" *ngIf="eyeForm.get('add_3_1255').errors?.pattern">Required pattern xxx-xxx-xxxx</small>

  </td>
  <td colspan="3">
    State:
    <ng-select
    [items]="states"
    bindLabel="name"
    placeholder="States"
    bindValue="id"
    formControlName="add_3_1248"
    style="width:170px"
    
  >
  </ng-select>       
  </td>
  <td colspan="3">
    MLP Zip:
    <input  formControlName="add_3_1249" class="form-control  "        id="add_3_1249" name="add_3_1249" type="text" placeholder="Previous MLP Zip"
    >
    <small
        class="text-danger"
        *ngIf="eyeForm.controls['add_3_1249'].errors?.pattern"
        > Zip code must be atleast 5 digits and less than 10 digits.</small
      >
  </td>
</tr>
<tr class="border">
  <td colspan="4">
    Per claim limit of liability:
    <ng-select [items]="preClaim" bindLabel="name" placeholder=" per claim" bindValue="id"
    formControlName="add_3_1264" >   
</ng-select> 
  </td>
  <td colspan="4">
    Aggregate amount:
    <ng-select [items]="aggregates" bindLabel="name" placeholder=" Aggregate"  bindValue="id"
    formControlName="add_3_1265">
    </ng-select> 
  </td>
  <td colspan="4">
    Contact’s email address, if available:
    <input  formControlName="add_3_1857" class="form-control  "   id="add_3_1857" name="add_3_1857" type="text" placeholder="Previous MLP Email"
     >
     <small
     class="text-danger"
     *ngIf="eyeForm.controls['add_3_1857'].errors?.pattern"
     >Please enter a valid email.</small
     >
  </td>
</tr>
<tr class="border">
  <td colspan="4">
    Month / day / year effective:    
    <div class="input-group-append">
      <input class="form-control onboard datepicker" formControlName="add_3_4637"   (blur)="clearInputIfInvalid('add_3_4637')"
      ngbDatepicker #d2010="ngbDatepicker" id="add_3_4637" name="add_3_4637" type="text" placeholder= " Previous MLP Issue Date" data-id="add_3_4637">
     <button class="btn btn-outline-secondary calendar" (click)="d2010.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
      </div>
      <div  *ngIf="eyeForm.get('add_3_4637').invalid ">
        <small class="text-danger" *ngIf="eyeForm.get('add_3_4637').errors?.invalidDateFormat">
          Only Date Format is allowed(MM/DD/YYYY)                  </small>
        <small class="text-danger" *ngIf="eyeForm.get('add_3_4637').errors?.invalidMonth">
        Month is out of range
        </small>
        <small class="text-danger" *ngIf="eyeForm.get('add_3_4637').errors?.invalidDateyear">
          year is out of range(1900-2099)                    </small>
          <small class="text-danger" *ngIf="eyeForm.get('add_3_4637').errors?.invalidDateRange">
            date is out of range
            </small>
          </div>
  </td>
  <td colspan="4">
    Month / day / year retroactive date, if applicable:
    <div class="input-group-append">
      <input class="form-control onboard datepicker" formControlName="add_3_1251"     (blur)="clearInputIfInvalid('add_3_1251')"
      ngbDatepicker #d2011="ngbDatepicker" id="add_3_1251" name="add_3_1251" type="text" placeholder= "Previous MLP Retroactive Date" data-id="add_3_1251">
     <button class="btn btn-outline-secondary calendar" (click)="d2011.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
      </div>
      <div  *ngIf="eyeForm.get('add_3_1251').invalid ">
        <small class="text-danger" *ngIf="eyeForm.get('add_3_1251').errors?.invalidDateFormat">
          Only Date Format is allowed(MM/DD/YYYY)                  </small>
        <small class="text-danger" *ngIf="eyeForm.get('add_3_1251').errors?.invalidMonth">
        Month is out of range
        </small>
        <small class="text-danger" *ngIf="eyeForm.get('add_3_1251').errors?.invalidDateyear">
          year is out of range(1900-2099)                    </small>
          <small class="text-danger" *ngIf="eyeForm.get('add_3_1251').errors?.invalidDateRange">
            date is out of range
            </small>
          </div>
  </td>
  <td colspan="4">
    Month / day / year of expiration:
    <div class="input-group-append">
      <input class="form-control onboard datepicker" formControlName="add_3_1253"  (blur)="clearInputIfInvalid('add_3_1253')"
      ngbDatepicker #d2012="ngbDatepicker" id="add_3_1253" name="add_3_1253" type="text" placeholder= "Previous Expiration Date" data-id="add_3_1253">
     <button class="btn btn-outline-secondary calendar" (click)="d2012.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
      </div>
      <div  *ngIf="eyeForm.get('add_3_1253').invalid ">
        <small class="text-danger" *ngIf="eyeForm.get('add_3_1253').errors?.invalidDateFormat">
          Only Date Format is allowed(MM/DD/YYYY)                  </small>
        <small class="text-danger" *ngIf="eyeForm.get('add_3_1253').errors?.invalidMonth">
        Month is out of range
        </small>
        <small class="text-danger" *ngIf="eyeForm.get('add_3_1253').errors?.invalidDateyear">
          year is out of range(1900-2099)                    </small>
          <small class="text-danger" *ngIf="eyeForm.get('add_3_1253').errors?.invalidDateRange">
            date is out of range
            </small>
          </div>
  </td>
</tr>
<tr class="border">
  <td colspan="4">
    Insurance carrier / provider of professional liability coverage:
    <input  formControlName="add_4_1242" class="form-control" id="add_4_1242" name="add_4_1242"   type="text" placeholder="Previous MLP Carriers"
    >
  
  </td>
  <td colspan="4">
    Policy number:
    <input  formControlName="add_4_1256" class="form-control"    id="add_4_1256" name="add_4_1256" type="text" placeholder="Previous MLP Policy Number"
   >
 
  <td colspan="4">
    Type of coverage:
    <input  formControlName="add_4_1257" class="form-control  " id="add_4_1257" name="add_4_1257"   type="text" placeholder="Previous MLP Type of Coverage"
     >
  
  </td>
</tr>
<tr class="border">
  <td colspan="3">
    Name of local contact:
    <input  formControlName="add_4_1243" class="form-control  " id="add_4_1243" name="add_4_1243"  type="text" placeholder="Previous MLP Agent"
     >
  
  </td>
  <td colspan="3">
    Mailing address 1:
    <input  formControlName="add_4_1244" class="form-control  " id="add_4_1244" name="add_4_1244"  type="text" placeholder="Previous MLP Address 1"
     >

  </td>
  <td colspan="3">
    Mailing address2:
    <input  formControlName="add_4_1245" class="form-control  " id="add_4_1245" name="add_4_1245"  type="text" placeholder=" Previous MLP Address 2"
    >
   
  </td>
  <td colspan="3">
    MLP City
    <input  formControlName="add_4_1246" class="form-control  " id="add_4_1246" name="add_4_1246" type="text" placeholder=" Previous MLP City" 
    >
  
  </td>
</tr>
<tr class="border">
  <td colspan="3">
    Contact’s telephone number:
    <input  formControlName="add_4_1254" class="form-control  " (keydown)="handleNumber($event, 'add_4_1254' )" id="add_4_1254" name="add_4_1254" type="text" placeholder="Previous MLP Phone" maxlength="12"
    >
    <small class="text-danger" *ngIf="eyeForm.get('add_4_1254').errors?.pattern">Required pattern xxx-xxx-xxxx</small>

  </td>
  <td colspan="3">
    Fax number, if available:
    <input  formControlName="add_4_1255" class="form-control  " (keydown)="handleNumber($event, 'add_4_1255' )" id="add_4_1255" name="add_4_1255" type="text" placeholder="Previous MLP Fax" maxlength="12"
>
<small class="text-danger" *ngIf="eyeForm.get('add_4_1255').errors?.pattern">Required pattern xxx-xxx-xxxx</small>

  </td>
  <td colspan="3">
    State:
    <ng-select
    [items]="states"
    bindLabel="name"
    placeholder="States"
    bindValue="id"
    formControlName="add_4_1248"
    style="width:170px"
    
  >
  </ng-select>       
  </td>
  <td colspan="3">
    MLP Zip:
    <input  formControlName="add_4_1249" class="form-control  "        id="add_4_1249" name="add_4_1249" type="text" placeholder="Previous MLP Zip"
    >
    <small
        class="text-danger"
        *ngIf="eyeForm.controls['add_4_1249'].errors?.pattern"
        > Zip code must be atleast 5 digits and less than 10 digits.</small
      >
  </td>
</tr>
<tr class="border">
  <td colspan="4">
    Per claim limit of liability:
    <ng-select [items]="preClaim" bindLabel="name" placeholder=" per claim" bindValue="id"
    formControlName="add_4_1264" >   
</ng-select> 
  </td>
  <td colspan="4">
    Aggregate amount:
    <ng-select [items]="aggregates" bindLabel="name" placeholder=" Aggregate"  bindValue="id"
    formControlName="add_4_1265">
    </ng-select> 
  </td>
  <td colspan="4">
    Contact’s email address, if available:
    <input  formControlName="add_4_1857" class="form-control  "   id="add_4_1857" name="add_4_1857" type="text" placeholder="Previous MLP Email"
     >
     <small
     class="text-danger"
     *ngIf="eyeForm.controls['add_4_1857'].errors?.pattern"
     >Please enter a valid email.</small
     >
  </td>
</tr>
<tr class="border">
  <td colspan="4">
    Month / day / year effective:    
    <div class="input-group-append">
      <input class="form-control onboard datepicker" formControlName="add_4_4637"  (blur)="clearInputIfInvalid('add_4_4637')"
      ngbDatepicker #d2014="ngbDatepicker" id="add_4_4637" name="add_4_4637" type="text" placeholder= " Previous MLP Issue Date" data-id="add_4_4637">
     <button class="btn btn-outline-secondary calendar" (click)="d2014.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
      </div>
      <div  *ngIf="eyeForm.get('add_4_4637').invalid ">
        <small class="text-danger" *ngIf="eyeForm.get('add_4_4637').errors?.invalidDateFormat">
          Only Date Format is allowed(MM/DD/YYYY)                  </small>
        <small class="text-danger" *ngIf="eyeForm.get('add_4_4637').errors?.invalidMonth">
        Month is out of range
        </small>
        <small class="text-danger" *ngIf="eyeForm.get('add_4_4637').errors?.invalidDateyear">
          year is out of range(1900-2099)                    </small>
          <small class="text-danger" *ngIf="eyeForm.get('add_4_4637').errors?.invalidDateRange">
            date is out of range
            </small>
          </div>
  </td>
  <td colspan="4">
    Month / day / year retroactive date, if applicable:
    <div class="input-group-append">
      <input class="form-control onboard datepicker" formControlName="add_4_1251"  (blur)="clearInputIfInvalid('add_4_1251')"
      ngbDatepicker #d2019="ngbDatepicker" id="add_4_1251" name="add_4_1251" type="text" placeholder= "Previous MLP Retroactive Date" data-id="add_4_1251">
     <button class="btn btn-outline-secondary calendar" (click)="d2019.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
      </div>
      <div  *ngIf="eyeForm.get('add_4_1251').invalid ">
        <small class="text-danger" *ngIf="eyeForm.get('add_4_1251').errors?.invalidDateFormat">
          Only Date Format is allowed(MM/DD/YYYY)                  </small>
        <small class="text-danger" *ngIf="eyeForm.get('add_4_1251').errors?.invalidMonth">
        Month is out of range
        </small>
        <small class="text-danger" *ngIf="eyeForm.get('add_4_1251').errors?.invalidDateyear">
          year is out of range(1900-2099)                    </small>
          <small class="text-danger" *ngIf="eyeForm.get('add_4_1251').errors?.invalidDateRange">
            date is out of range
            </small>
          </div>
  </td>
  <td colspan="4">
    Month / day / year of expiration:
    <div class="input-group-append">
      <input class="form-control onboard datepicker" formControlName="add_4_1253"  (blur)="clearInputIfInvalid('add_4_1253')"
      ngbDatepicker #d2001="ngbDatepicker" id="add_4_1253" name="add_4_1253" type="text" placeholder= "Previous Expiration Date" data-id="add_4_1253">
     <button class="btn btn-outline-secondary calendar" (click)="d2001.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
      </div>
      <div  *ngIf="eyeForm.get('add_4_1253').invalid ">
        <small class="text-danger" *ngIf="eyeForm.get('add_4_1253').errors?.invalidDateFormat">
          Only Date Format is allowed(MM/DD/YYYY)                  </small>
        <small class="text-danger" *ngIf="eyeForm.get('add_4_1253').errors?.invalidMonth">
        Month is out of range
        </small>
        <small class="text-danger" *ngIf="eyeForm.get('add_4_1253').errors?.invalidDateyear">
          year is out of range(1900-2099)                    </small>
          <small class="text-danger" *ngIf="eyeForm.get('add_4_1253').errors?.invalidDateRange">
            date is out of range
            </small>
          </div>
  </td>
</tr>
<tr class="border">
  <td colspan="11">
      <div class="col-md-12">
        <h2  style="margin-left: 31px;  font-size: 18px;"><b>XXI. Attestation Questions – This section to be completed by the Practitioner.
            Modification to the wording or format of these Attestation Questions will invalidate the application.</b></h2></div>        
  </td>
</tr>
<tr>
  <td colspan="11">
      <div class="col-md-12">
          <label class="formLabel">Please answer the following questions “yes” or “no”. If your answer to any of the following questions is “yes”, please provide details and reasons, as
            specified in each question, on a separate sheet. Please sign and date each additional sheet.
          </label>
      </div>        

  </td>
</tr>
<tr class="border">
  <td colspan="1" style="width:1px;">
      <div class="col-md-12">
          <label class="formLabel">A.</label>
      </div>        

  </td>
  <td colspan="7">
      <div class="col-md-12">
          <label class="formLabel">Has your license, certification, or registration to practice your profession, Drug Enforcement Administration (DEA)
            registration, or narcotic registration/certificate in any jurisdiction ever been denied, limited, suspended, revoked, not
            renewed, voluntarily or involuntarily relinquished, or subject to stipulated or probationary conditions, had a corrective
            action, or have you ever been fined or received a letter of reprimand or is any such action pending or under review?</label>
      </div>        

  </td>
  <td colspan="2" >
      <div class="col-md-12">
          <input type="radio" formControlName="cmd_00109"
                  [class.is-invalid]="(eyeForm.controls['cmd_00109'].touched && eyeForm.controls['cmd_00109'].invalid) || (eyeForm.controls['cmd_00109'].invalid && isSubmit)"
                  id="cmd_00109" name="cmd_00109" value="1">
  
              <label>Yes</label>
  
              <div class="formLabel col-md-11"
                  *ngIf="eyeForm.controls['cmd_00109'].touched && eyeForm.controls['cmd_00109'].invalid">
  
                  <small class="text-danger" *ngIf="eyeForm.controls['cmd_00109'].errors?.required">
  
                      required
  
                  </small>
  
              </div>
  
              
      </div>
  </td>
  <td colspan="2" >
      <div class="col-md-12">
          <input type="radio" formControlName="cmd_00109"
                  [class.is-invalid]="(eyeForm.controls['cmd_00109'].touched && eyeForm.controls['cmd_00109'].invalid) || (eyeForm.controls['cmd_00109'].invalid && isSubmit)"
                  id="cmd_00109" name="cmd_00109" value="0">
  
              <label>No</label>
  
              <div class="formLabel col-md-11"
                  *ngIf="eyeForm.controls['cmd_00109'].touched && eyeForm.controls['cmd_00109'].invalid">
  
                  <small class="text-danger" *ngIf="eyeForm.controls['cmd_00109'].errors?.required">
  
                      required
  
                  </small>
  
              </div>
  
              <span
                  *ngIf="(eyeForm.controls['cmd_00109'].touched && eyeForm.controls['cmd_00109'].invalid) || (eyeForm.controls['cmd_00109'].invalid && isSubmit)">
  
                  <i class="fas fa-times ml-2 text-danger"></i>
  
              </span>
      </div>
  </td>
</tr>
<tr class="border">
  <td colspan="1" style="width:1px;">
      <div class="col-md-12">
          <label class="formLabel">B.</label>
      </div>        

  </td>
  <td colspan="7">
      <div class="col-md-12">
          <label class="formLabel">Have you ever been suspended, fined, disciplined, or otherwise sanctioned, restricted or excluded for any reasons, by
            Medicare, Medicaid, or any public program or is any such action pending or under review?</label>
      </div>        

  </td>
  <td colspan="2" >
      <div class="col-md-12">
          <input type="radio" formControlName="cmd_00110"
                  [class.is-invalid]="(eyeForm.controls['cmd_00110'].touched && eyeForm.controls['cmd_00110'].invalid) || (eyeForm.controls['cmd_00110'].invalid && isSubmit)"
                  id="cmd_00110" name="cmd_00110" value="1">
  
              <label>Yes</label>
  
              <div class="formLabel col-md-11"
                  *ngIf="eyeForm.controls['cmd_00110'].touched && eyeForm.controls['cmd_00110'].invalid">
  
                  <small class="text-danger" *ngIf="eyeForm.controls['cmd_00110'].errors?.required">
  
                      required
  
                  </small>
  
              </div>
  
              
      </div>
  </td>
  <td colspan="2" >
      <div class="col-md-12">
          <input type="radio" formControlName="cmd_00110"
                  [class.is-invalid]="(eyeForm.controls['cmd_00110'].touched && eyeForm.controls['cmd_00110'].invalid) || (eyeForm.controls['cmd_00110'].invalid && isSubmit)"
                  id="cmd_00110" name="cmd_00110" value="0">
  
              <label>No</label>
  
              <div class="formLabel col-md-11"
                  *ngIf="eyeForm.controls['cmd_00110'].touched && eyeForm.controls['cmd_00110'].invalid">
  
                  <small class="text-danger" *ngIf="eyeForm.controls['cmd_00110'].errors?.required">
  
                      required
  
                  </small>
  
              </div>
  
              <span
                  *ngIf="(eyeForm.controls['cmd_00110'].touched && eyeForm.controls['cmd_00110'].invalid) || (eyeForm.controls['cmd_00110'].invalid && isSubmit)">
  
                  <i class="fas fa-times ml-2 text-danger"></i>
  
              </span>
      </div>
  </td>
</tr>
<tr class="border">
  <td colspan="1" style="width:1px;">
      <div class="col-md-12">
          <label class="formLabel">C.</label>
      </div>        

  </td>
  <td colspan="7">
      <div class="col-md-12">
          <label class="formLabel">Have you ever been denied clinical privileges, membership, or contractual participation by any health care related
            organization*, or have clinical privileges, membership, participation or employment at any such organization ever
            been placed on probation, suspended, restricted, revoked, voluntarily relinquished while under investigation, not
            renewed while under investigation, involuntarily relinquished, or is any such action pending or under review?</label>
      </div>        

  </td>
  <td colspan="2" >
      <div class="col-md-12">
          <input type="radio" formControlName="cmd_00111"
                  [class.is-invalid]="(eyeForm.controls['cmd_00111'].touched && eyeForm.controls['cmd_00111'].invalid) || (eyeForm.controls['cmd_00111'].invalid && isSubmit)"
                  id="cmd_00111" name="cmd_00111" value="1">
  
              <label>Yes</label>
  
              <div class="formLabel col-md-11"
                  *ngIf="eyeForm.controls['cmd_00111'].touched && eyeForm.controls['cmd_00111'].invalid">
  
                  <small class="text-danger" *ngIf="eyeForm.controls['cmd_00111'].errors?.required">
  
                      required
  
                  </small>
  
              </div>
  
              
      </div>
  </td>
  <td colspan="2" >
      <div class="col-md-12">
          <input type="radio" formControlName="cmd_00111"
                  [class.is-invalid]="(eyeForm.controls['cmd_00111'].touched && eyeForm.controls['cmd_00111'].invalid) || (eyeForm.controls['cmd_00111'].invalid && isSubmit)"
                  id="cmd_00111" name="cmd_00111" value="0">
  
              <label>No</label>
  
              <div class="formLabel col-md-11"
                  *ngIf="eyeForm.controls['cmd_00111'].touched && eyeForm.controls['cmd_00111'].invalid">
  
                  <small class="text-danger" *ngIf="eyeForm.controls['cmd_00111'].errors?.required">
  
                      required
  
                  </small>
  
              </div>
  
              <span
                  *ngIf="(eyeForm.controls['cmd_00111'].touched && eyeForm.controls['cmd_00111'].invalid) || (eyeForm.controls['cmd_00111'].invalid && isSubmit)">
  
                  <i class="fas fa-times ml-2 text-danger"></i>
  
              </span>
      </div>
  </td>
</tr>
<tr class="border">
  <td colspan="1" style="width:1px;">
      <div class="col-md-12">
          <label class="formLabel">D.</label>
      </div>        

  </td>
  <td colspan="7">
      <div class="col-md-12">
          <label class="formLabel">Have you ever surrendered clinical privileges, accepted restrictions on privileges, terminated contractual participation
            or employment, taken a leave of absence, committed to retraining, or resigned from any health care related
            organization* while under investigation or potential review?</label>
      </div>        

  </td>
  <td colspan="2" >
      <div class="col-md-12">
          <input type="radio" formControlName="cmd_00112"
                  [class.is-invalid]="(eyeForm.controls['cmd_00112'].touched && eyeForm.controls['cmd_00112'].invalid) || (eyeForm.controls['cmd_00112'].invalid && isSubmit)"
                  id="cmd_00112" name="cmd_00112" value="1">
  
              <label>Yes</label>
  
              <div class="formLabel col-md-11"
                  *ngIf="eyeForm.controls['cmd_00112'].touched && eyeForm.controls['cmd_00112'].invalid">
  
                  <small class="text-danger" *ngIf="eyeForm.controls['cmd_00112'].errors?.required">
  
                      required
  
                  </small>
  
              </div>
  
              
      </div>
  </td>
  <td colspan="2" >
      <div class="col-md-12">
          <input type="radio" formControlName="cmd_00112"
                  [class.is-invalid]="(eyeForm.controls['cmd_00112'].touched && eyeForm.controls['cmd_00112'].invalid) || (eyeForm.controls['cmd_00112'].invalid && isSubmit)"
                  id="cmd_00112" name="cmd_00112" value="0">
  
              <label>No</label>
  
              <div class="formLabel col-md-11"
                  *ngIf="eyeForm.controls['cmd_00112'].touched && eyeForm.controls['cmd_00112'].invalid">
  
                  <small class="text-danger" *ngIf="eyeForm.controls['cmd_00112'].errors?.required">
  
                      required
  
                  </small>
  
              </div>
  
              <span
                  *ngIf="(eyeForm.controls['cmd_00112'].touched && eyeForm.controls['cmd_00112'].invalid) || (eyeForm.controls['cmd_00112'].invalid && isSubmit)">
  
                  <i class="fas fa-times ml-2 text-danger"></i>
  
              </span>
      </div>
  </td>
</tr>
<tr class="border">
  <td colspan="1" style="width:1px;">
      <div class="col-md-12">
          <label class="formLabel">E.</label>
      </div>        

  </td>
  <td colspan="7">
      <div class="col-md-12">
          <label class="formLabel">Has an application for clinical privileges, appointment, membership, employment or participation in any health care
            related organization* ever been withdrawn on your request prior to the organization’s final action?</label>
      </div>        

  </td>
  <td colspan="2" >
      <div class="col-md-12">
          <input type="radio" formControlName="cmd_00113"
                  [class.is-invalid]="(eyeForm.controls['cmd_00113'].touched && eyeForm.controls['cmd_00113'].invalid) || (eyeForm.controls['cmd_00113'].invalid && isSubmit)"
                  id="cmd_00113" name="cmd_00113" value="1">
  
              <label>Yes</label>
  
              <div class="formLabel col-md-11"
                  *ngIf="eyeForm.controls['cmd_00113'].touched && eyeForm.controls['cmd_00113'].invalid">
  
                  <small class="text-danger" *ngIf="eyeForm.controls['cmd_00113'].errors?.required">
  
                      required
  
                  </small>
  
              </div>
  
              
      </div>
  </td>
  <td colspan="2" >
      <div class="col-md-12">
          <input type="radio" formControlName="cmd_00113"
                  [class.is-invalid]="(eyeForm.controls['cmd_00113'].touched && eyeForm.controls['cmd_00113'].invalid) || (eyeForm.controls['cmd_00113'].invalid && isSubmit)"
                  id="cmd_00113" name="cmd_00113" value="0">
  
              <label>No</label>
  
              <div class="formLabel col-md-11"
                  *ngIf="eyeForm.controls['cmd_00113'].touched && eyeForm.controls['cmd_00113'].invalid">
  
                  <small class="text-danger" *ngIf="eyeForm.controls['cmd_00113'].errors?.required">
  
                      required
  
                  </small>
  
              </div>
  
              <span
                  *ngIf="(eyeForm.controls['cmd_00113'].touched && eyeForm.controls['cmd_00113'].invalid) || (eyeForm.controls['cmd_00113'].invalid && isSubmit)">
  
                  <i class="fas fa-times ml-2 text-danger"></i>
  
              </span>
      </div>
  </td>
</tr>
<tr class="border">
  <td colspan="1" style="width:1px;">
      <div class="col-md-12">
          <label class="formLabel">F.</label>
      </div>        

  </td>
  <td colspan="7">
      <div class="col-md-12">
          <label class="formLabel">Has your membership or fellowship in any local, county, state, regional, national, or international professional
            organization ever been revoked, denied, limited, voluntarily relinquished while under investigation, not renewed
            while under investigation, involuntarily relinquished, or is any such action pending or under review?</label>
      </div>        

  </td>
  <td colspan="2" >
      <div class="col-md-12">
          <input type="radio" formControlName="cmd_00114"
                  [class.is-invalid]="(eyeForm.controls['cmd_00114'].touched && eyeForm.controls['cmd_00114'].invalid) || (eyeForm.controls['cmd_00114'].invalid && isSubmit)"
                  id="cmd_00114" name="cmd_00114" value="1">
  
              <label>Yes</label>
  
              <div class="formLabel col-md-11"
                  *ngIf="eyeForm.controls['cmd_00114'].touched && eyeForm.controls['cmd_00114'].invalid">
  
                  <small class="text-danger" *ngIf="eyeForm.controls['cmd_00114'].errors?.required">
  
                      required
  
                  </small>
  
              </div>
  
              
      </div>
  </td>
  <td colspan="2" >
      <div class="col-md-12">
          <input type="radio" formControlName="cmd_00114"
                  [class.is-invalid]="(eyeForm.controls['cmd_00114'].touched && eyeForm.controls['cmd_00114'].invalid) || (eyeForm.controls['cmd_00114'].invalid && isSubmit)"
                  id="cmd_00114" name="cmd_00114" value="0">
  
              <label>No</label>
  
              <div class="formLabel col-md-11"
                  *ngIf="eyeForm.controls['cmd_00114'].touched && eyeForm.controls['cmd_00114'].invalid">
  
                  <small class="text-danger" *ngIf="eyeForm.controls['cmd_00114'].errors?.required">
  
                      required
  
                  </small>
  
              </div>
  
              <span
                  *ngIf="(eyeForm.controls['cmd_00114'].touched && eyeForm.controls['cmd_00114'].invalid) || (eyeForm.controls['cmd_00114'].invalid && isSubmit)">
  
                  <i class="fas fa-times ml-2 text-danger"></i>
  
              </span>
      </div>
  </td>
</tr>
<tr class="border">
  <td colspan="1" style="width:1px;">
      <div class="col-md-12">
          <label class="formLabel">G.</label>
      </div>        

  </td>
  <td colspan="7">
      <div class="col-md-12">
          <label class="formLabel">Have you ever voluntarily or involuntarily left or been discharged from the education program leading to your current
            licensure or any subsequent training programs?</label>
      </div>        

  </td>
  <td colspan="2" >
      <div class="col-md-12">
          <input type="radio" formControlName="cmd_00115"
                  [class.is-invalid]="(eyeForm.controls['cmd_00115'].touched && eyeForm.controls['cmd_00115'].invalid) || (eyeForm.controls['cmd_00115'].invalid && isSubmit)"
                  id="cmd_00115" name="cmd_00115" value="1">
  
              <label>Yes</label>
  
              <div class="formLabel col-md-11"
                  *ngIf="eyeForm.controls['cmd_00115'].touched && eyeForm.controls['cmd_00115'].invalid">
  
                  <small class="text-danger" *ngIf="eyeForm.controls['cmd_00115'].errors?.required">
  
                      required
  
                  </small>
  
              </div>
  
              
      </div>
  </td>
  <td colspan="2" >
      <div class="col-md-12">
          <input type="radio" formControlName="cmd_00115"
                  [class.is-invalid]="(eyeForm.controls['cmd_00115'].touched && eyeForm.controls['cmd_00115'].invalid) || (eyeForm.controls['cmd_00115'].invalid && isSubmit)"
                  id="cmd_00115" name="cmd_00115" value="0">
  
              <label>No</label>
  
              <div class="formLabel col-md-11"
                  *ngIf="eyeForm.controls['cmd_00115'].touched && eyeForm.controls['cmd_00115'].invalid">
  
                  <small class="text-danger" *ngIf="eyeForm.controls['cmd_00115'].errors?.required">
  
                      required
  
                  </small>
  
              </div>
  
              <span
                  *ngIf="(eyeForm.controls['cmd_00115'].touched && eyeForm.controls['cmd_00115'].invalid) || (eyeForm.controls['cmd_00115'].invalid && isSubmit)">
  
                  <i class="fas fa-times ml-2 text-danger"></i>
  
              </span>
      </div>
  </td>
</tr>
<tr class="border">
  <td colspan="1" style="width:1px;">
      <div class="col-md-12">
          <label class="formLabel">H.</label>
      </div>        

  </td>
  <td colspan="7">
      <div class="col-md-12">
          <label class="formLabel">Have you ever had board certification revoked?</label>
      </div>        

  </td>
  <td colspan="2" >
      <div class="col-md-12">
          <input type="radio" formControlName="cmd_00116"
                  [class.is-invalid]="(eyeForm.controls['cmd_00116'].touched && eyeForm.controls['cmd_00116'].invalid) || (eyeForm.controls['cmd_00116'].invalid && isSubmit)"
                  id="cmd_00116" name="cmd_00116" value="1">
  
              <label>Yes</label>
  
              <div class="formLabel col-md-11"
                  *ngIf="eyeForm.controls['cmd_00116'].touched && eyeForm.controls['cmd_00116'].invalid">
  
                  <small class="text-danger" *ngIf="eyeForm.controls['cmd_00116'].errors?.required">
  
                      required
  
                  </small>
  
              </div>
  
              
      </div>
  </td>
  <td colspan="2" >
      <div class="col-md-12">
          <input type="radio" formControlName="cmd_00116"
                  [class.is-invalid]="(eyeForm.controls['cmd_00116'].touched && eyeForm.controls['cmd_00116'].invalid) || (eyeForm.controls['cmd_00116'].invalid && isSubmit)"
                  id="cmd_00116" name="cmd_00116" value="0">
  
              <label>No</label>
  
              <div class="formLabel col-md-11"
                  *ngIf="eyeForm.controls['cmd_00116'].touched && eyeForm.controls['cmd_00116'].invalid">
  
                  <small class="text-danger" *ngIf="eyeForm.controls['cmd_00116'].errors?.required">
  
                      required
  
                  </small>
  
              </div>
  
              <span
                  *ngIf="(eyeForm.controls['cmd_00116'].touched && eyeForm.controls['cmd_00116'].invalid) || (eyeForm.controls['cmd_00116'].invalid && isSubmit)">
  
                  <i class="fas fa-times ml-2 text-danger"></i>
  
              </span>
      </div>
  </td>
</tr>
<tr class="border">
  <td colspan="1" style="width:1px;">
      <div class="col-md-12">
          <label class="formLabel">I.</label>
      </div>        

  </td>
  <td colspan="7">
      <div class="col-md-12">
          <label class="formLabel">Have you ever been the subject of any reports to a state or federal data bank or state licensing or disciplinary entity?</label>
      </div>        

  </td>
  <td colspan="2" >
      <div class="col-md-12">
          <input type="radio" formControlName="cmd_00117"
                  [class.is-invalid]="(eyeForm.controls['cmd_00117'].touched && eyeForm.controls['cmd_00117'].invalid) || (eyeForm.controls['cmd_00117'].invalid && isSubmit)"
                  id="cmd_00117" name="cmd_00117" value="1">
  
              <label>Yes</label>
  
              <div class="formLabel col-md-11"
                  *ngIf="eyeForm.controls['cmd_00117'].touched && eyeForm.controls['cmd_00117'].invalid">
  
                  <small class="text-danger" *ngIf="eyeForm.controls['cmd_00117'].errors?.required">
  
                      required
  
                  </small>
  
              </div>
  
              
      </div>
  </td>
  <td colspan="2" >
      <div class="col-md-12">
          <input type="radio" formControlName="cmd_00117"
                  [class.is-invalid]="(eyeForm.controls['cmd_00117'].touched && eyeForm.controls['cmd_00117'].invalid) || (eyeForm.controls['cmd_00117'].invalid && isSubmit)"
                  id="cmd_00117" name="cmd_00117" value="0">
  
              <label>No</label>
  
              <div class="formLabel col-md-11"
                  *ngIf="eyeForm.controls['cmd_00117'].touched && eyeForm.controls['cmd_00117'].invalid">
  
                  <small class="text-danger" *ngIf="eyeForm.controls['cmd_00117'].errors?.required">
  
                      required
  
                  </small>
  
              </div>
  
              <span
                  *ngIf="(eyeForm.controls['cmd_00117'].touched && eyeForm.controls['cmd_00117'].invalid) || (eyeForm.controls['cmd_00117'].invalid && isSubmit)">
  
                  <i class="fas fa-times ml-2 text-danger"></i>
  
              </span>
      </div>
  </td>
</tr>
<tr class="border">
  <td colspan="1" style="width:1px;">
      <div class="col-md-12">
          <label class="formLabel">J.</label>
      </div>        

  </td>
  <td colspan="7">
      <div class="col-md-12">
          <label class="formLabel">Have you ever been charged with a criminal violation (felony or misdemeanor)?</label>
      </div>        

  </td>
  <td colspan="2" >
      <div class="col-md-12">
          <input type="radio" formControlName="cmd_00118"
                  [class.is-invalid]="(eyeForm.controls['cmd_00118'].touched && eyeForm.controls['cmd_00118'].invalid) || (eyeForm.controls['cmd_00118'].invalid && isSubmit)"
                  id="cmd_00118" name="cmd_00118" value="1">
  
              <label>Yes</label>
  
              <div class="formLabel col-md-11"
                  *ngIf="eyeForm.controls['cmd_00118'].touched && eyeForm.controls['cmd_00118'].invalid">
  
                  <small class="text-danger" *ngIf="eyeForm.controls['cmd_00118'].errors?.required">
  
                      required
  
                  </small>
  
              </div>
  
              
      </div>
  </td>
  <td colspan="2" >
      <div class="col-md-12">
          <input type="radio" formControlName="cmd_00118"
                  [class.is-invalid]="(eyeForm.controls['cmd_00118'].touched && eyeForm.controls['cmd_00118'].invalid) || (eyeForm.controls['cmd_00118'].invalid && isSubmit)"
                  id="cmd_00118" name="cmd_00118" value="0">
  
              <label>No</label>
  
              <div class="formLabel col-md-11"
                  *ngIf="eyeForm.controls['cmd_00118'].touched && eyeForm.controls['cmd_00118'].invalid">
  
                  <small class="text-danger" *ngIf="eyeForm.controls['cmd_00118'].errors?.required">
  
                      required
  
                  </small>
  
              </div>
  
              <span
                  *ngIf="(eyeForm.controls['cmd_00118'].touched && eyeForm.controls['cmd_00118'].invalid) || (eyeForm.controls['cmd_00118'].invalid && isSubmit)">
  
                  <i class="fas fa-times ml-2 text-danger"></i>
  
              </span>
      </div>
  </td>
</tr>
<tr class="border">
  <td colspan="1" style="width:1px;">
      <div class="col-md-12">
          <label class="formLabel">K.</label>
      </div>        

  </td>
  <td colspan="7">
      <div class="col-md-12">
          <label class="formLabel">Do you presently use any illegal drugs?</label>
      </div>        

  </td>
  <td colspan="2" >
      <div class="col-md-12">
          <input type="radio" formControlName="cmd_00119"
                  [class.is-invalid]="(eyeForm.controls['cmd_00119'].touched && eyeForm.controls['cmd_00119'].invalid) || (eyeForm.controls['cmd_00119'].invalid && isSubmit)"
                  id="cmd_00119" name="cmd_00119" value="1">
  
              <label>Yes</label>
  
              <div class="formLabel col-md-11"
                  *ngIf="eyeForm.controls['cmd_00119'].touched && eyeForm.controls['cmd_00119'].invalid">
  
                  <small class="text-danger" *ngIf="eyeForm.controls['cmd_00119'].errors?.required">
  
                      required
  
                  </small>
  
              </div>
  
              
      </div>
  </td>
  <td colspan="2" >
      <div class="col-md-12">
          <input type="radio" formControlName="cmd_00119"
                  [class.is-invalid]="(eyeForm.controls['cmd_00119'].touched && eyeForm.controls['cmd_00119'].invalid) || (eyeForm.controls['cmd_00119'].invalid && isSubmit)"
                  id="cmd_00119" name="cmd_00119" value="0">
  
              <label>No</label>
  
              <div class="formLabel col-md-11"
                  *ngIf="eyeForm.controls['cmd_00119'].touched && eyeForm.controls['cmd_00119'].invalid">
  
                  <small class="text-danger" *ngIf="eyeForm.controls['cmd_00119'].errors?.required">
  
                      required
  
                  </small>
  
              </div>
  
              <span
                  *ngIf="(eyeForm.controls['cmd_00119'].touched && eyeForm.controls['cmd_00119'].invalid) || (eyeForm.controls['cmd_00119'].invalid && isSubmit)">
  
                  <i class="fas fa-times ml-2 text-danger"></i>
  
              </span>
      </div>
  </td>
</tr>
<tr class="border">
  <td colspan="1" style="width:1px;">
      <div class="col-md-12">
          <label class="formLabel">L.</label>
      </div>        

  </td>
  <td colspan="7">
      <div class="col-md-12">
          <label class="formLabel">Do you currently have any physical condition, mental health condition, or chemical dependency condition (alcohol or
            other substance) that currently affects your ability to practice, with or without reasonable accommodation, the
            privileges requested?</label>
      </div>        

  </td>
  <td colspan="2" >
      <div class="col-md-12">
          <input type="radio" formControlName="cmd_00120"
                  [class.is-invalid]="(eyeForm.controls['cmd_00120'].touched && eyeForm.controls['cmd_00120'].invalid) || (eyeForm.controls['cmd_00120'].invalid && isSubmit)"
                  id="cmd_00120" name="cmd_00120" value="1">
  
              <label>Yes</label>
  
              <div class="formLabel col-md-11"
                  *ngIf="eyeForm.controls['cmd_00120'].touched && eyeForm.controls['cmd_00120'].invalid">
  
                  <small class="text-danger" *ngIf="eyeForm.controls['cmd_00120'].errors?.required">
  
                      required
  
                  </small>
  
              </div>
  
              
      </div>
  </td>
  <td colspan="2" >
      <div class="col-md-12">
          <input type="radio" formControlName="cmd_00120"
                  [class.is-invalid]="(eyeForm.controls['cmd_00120'].touched && eyeForm.controls['cmd_00120'].invalid) || (eyeForm.controls['cmd_00120'].invalid && isSubmit)"
                  id="cmd_00120" name="cmd_00120" value="0">
  
              <label>No</label>
  
              <div class="formLabel col-md-11"
                  *ngIf="eyeForm.controls['cmd_00120'].touched && eyeForm.controls['cmd_00120'].invalid">
  
                  <small class="text-danger" *ngIf="eyeForm.controls['cmd_00120'].errors?.required">
  
                      required
  
                  </small>
  
              </div>
  
              <span
                  *ngIf="(eyeForm.controls['cmd_00120'].touched && eyeForm.controls['cmd_00120'].invalid) || (eyeForm.controls['cmd_00120'].invalid && isSubmit)">
  
                  <i class="fas fa-times ml-2 text-danger"></i>
  
              </span>
      </div>
  </td>
</tr>
<tr class="border">
  <td colspan="1" style="width:1px;">
      <div class="col-md-12">
          <label class="formLabel">M.</label>
      </div>        

  </td>
  <td colspan="7">
      <div class="col-md-12">
          <label class="formLabel">Are you unable to perform any of the services/clinical privileges required by the applicable participating practitioner
            agreement/hospital appointment, with or without reasonable accommodation, according to accepted standards of
            professional performance?</label>
      </div>        

  </td>
  <td colspan="2" >
      <div class="col-md-12">
          <input type="radio" formControlName="cmd_00121"
                  [class.is-invalid]="(eyeForm.controls['cmd_00121'].touched && eyeForm.controls['cmd_00121'].invalid) || (eyeForm.controls['cmd_00121'].invalid && isSubmit)"
                  id="cmd_00121" name="cmd_00121" value="1">
  
              <label>Yes</label>
  
              <div class="formLabel col-md-11"
                  *ngIf="eyeForm.controls['cmd_00121'].touched && eyeForm.controls['cmd_00121'].invalid">
  
                  <small class="text-danger" *ngIf="eyeForm.controls['cmd_00121'].errors?.required">
  
                      required
  
                  </small>
  
              </div>
  
              
      </div>
  </td>
  <td colspan="2" >
      <div class="col-md-12">
          <input type="radio" formControlName="cmd_00121"
                  [class.is-invalid]="(eyeForm.controls['cmd_00121'].touched && eyeForm.controls['cmd_00121'].invalid) || (eyeForm.controls['cmd_00121'].invalid && isSubmit)"
                  id="cmd_00121" name="cmd_00121" value="0">
  
              <label>No</label>
  
              <div class="formLabel col-md-11"
                  *ngIf="eyeForm.controls['cmd_00121'].touched && eyeForm.controls['cmd_00121'].invalid">
  
                  <small class="text-danger" *ngIf="eyeForm.controls['cmd_00121'].errors?.required">
  
                      required
  
                  </small>
  
              </div>
  
              <span
                  *ngIf="(eyeForm.controls['cmd_00121'].touched && eyeForm.controls['cmd_00121'].invalid) || (eyeForm.controls['cmd_00121'].invalid && isSubmit)">
  
                  <i class="fas fa-times ml-2 text-danger"></i>
  
              </span>
      </div>
  </td>
</tr>
<tr class="border">
  <td colspan="1" style="width:1px;">
      <div class="col-md-12">
          <label class="formLabel">N.</label>
      </div>        

  </td>
  <td colspan="7">
      <div class="col-md-12">
          <label class="formLabel">Have any professional liability claims or lawsuits ever been closed and/or filed against you? If yes, please complete Attachment A, Professional Liability Action Detail, for each past or current claim and/or
            lawsuit</label>
      </div>        

  </td>
  <td colspan="2" >
      <div class="col-md-12">
          <input type="radio" formControlName="cmd_00122"
                  [class.is-invalid]="(eyeForm.controls['cmd_00122'].touched && eyeForm.controls['cmd_00122'].invalid) || (eyeForm.controls['cmd_00122'].invalid && isSubmit)"
                  id="cmd_00122" name="cmd_00122" value="1">
  
              <label>Yes</label>
  
              <div class="formLabel col-md-11"
                  *ngIf="eyeForm.controls['cmd_00122'].touched && eyeForm.controls['cmd_00122'].invalid">
  
                  <small class="text-danger" *ngIf="eyeForm.controls['cmd_00122'].errors?.required">
  
                      required
  
                  </small>
  
              </div>
  
              
      </div>
  </td>
  <td colspan="2" >
      <div class="col-md-12">
          <input type="radio" formControlName="cmd_00122"
                  [class.is-invalid]="(eyeForm.controls['cmd_00122'].touched && eyeForm.controls['cmd_00122'].invalid) || (eyeForm.controls['cmd_00122'].invalid && isSubmit)"
                  id="cmd_00122" name="cmd_00122" value="0">
  
              <label>No</label>
  
              <div class="formLabel col-md-11"
                  *ngIf="eyeForm.controls['cmd_00122'].touched && eyeForm.controls['cmd_00122'].invalid">
  
                  <small class="text-danger" *ngIf="eyeForm.controls['cmd_00122'].errors?.required">
  
                      required
  
                  </small>
  
              </div>
  
              <span
                  *ngIf="(eyeForm.controls['cmd_00122'].touched && eyeForm.controls['cmd_00122'].invalid) || (eyeForm.controls['cmd_00122'].invalid && isSubmit)">
  
                  <i class="fas fa-times ml-2 text-danger"></i>
  
              </span>
      </div>
  </td>
</tr>
<tr class="border">
  <td colspan="1" style="width:1px;">
      <div class="col-md-12">
          <label class="formLabel">O.</label>
      </div>        

  </td>
  <td colspan="7">
      <div class="col-md-12">
          <label class="formLabel"> Has your professional liability insurance ever been terminated, not renewed, restricted, or modified (e.g. reduced
            limits, restricted coverage, surcharged), or have you ever been denied professional liability insurance?</label>
      </div>       

  </td>
  <td colspan="2" >
      <div class="col-md-12">
          <input type="radio" formControlName="cmd_00123"
                  [class.is-invalid]="(eyeForm.controls['cmd_00123'].touched && eyeForm.controls['cmd_00123'].invalid) || (eyeForm.controls['cmd_00123'].invalid && isSubmit)"
                  id="cmd_00123" name="cmd_00123" value="1">
  
              <label>Yes</label>
  
              <div class="formLabel col-md-11"
                  *ngIf="eyeForm.controls['cmd_00123'].touched && eyeForm.controls['cmd_00123'].invalid">
  
                  <small class="text-danger" *ngIf="eyeForm.controls['cmd_00123'].errors?.required">
  
                      required
  
                  </small>
  
              </div>
  
              
      </div>
  </td>
  <td colspan="2" >
      <div class="col-md-12">
          <input type="radio" formControlName="cmd_00123"
                  [class.is-invalid]="(eyeForm.controls['cmd_00123'].touched && eyeForm.controls['cmd_00123'].invalid) || (eyeForm.controls['cmd_00123'].invalid && isSubmit)"
                  id="cmd_00123" name="cmd_00123" value="0">
  
              <label>No</label>
  
              <div class="formLabel col-md-11"
                  *ngIf="eyeForm.controls['cmd_00123'].touched && eyeForm.controls['cmd_00123'].invalid">
  
                  <small class="text-danger" *ngIf="eyeForm.controls['cmd_00123'].errors?.required">
  
                      required
  
                  </small>
  
              </div>
  
              <span
                  *ngIf="(eyeForm.controls['cmd_00123'].touched && eyeForm.controls['cmd_00123'].invalid) || (eyeForm.controls['cmd_00123'].invalid && isSubmit)">
  
                  <i class="fas fa-times ml-2 text-danger"></i>
  
              </span>
      </div>
  </td>
</tr>
<tr class="border">
  <td colspan="12">
      <div class="col-md-12">
          <label class="formLabel">*e.g. hospital, medical staff, medical group, independent practice association (IPA), health plan, health maintenance organization (HMO),
            preferred provider organization (PPO), physician hospital organization (PHO), medical society, professional association, health care faculty
            position or other health delivery entity or system</label>
      </div>       

  </td>
</tr>
<tr class="border">
  <td colspan="12">
      <div class="col-md-12">
          <label class="formLabel">I certify the information in this entire application is complete, current, correct, and not misleading. I understand and acknowledge that any
            misstatements in, or omissions from this application will constitute cause for denial of my application or summary dismissal or termination of my
            clinical privileges, membership or practitioner participation agreement. A photocopy of this application, including this attestation, the authorization
            and release and any or all attachments has the same force and effect as the original. I have reviewed this information on the most recent date indicated
            below and it continues to be true and complete. While this application is being processed, I agree to update the information originally provided in this
            application should there be any change in the information.</label><br>
            <label class="formLabel">
              I agree to provide continuous care for my patients, until the practitioner/patient relationship has been properly terminated by either party, or in
accordance with contract provisions.
            </label>
      </div>       

  </td>
</tr>
<tr class="border">
  <td colspan="6">
          <div class="col-md-4">
                  <img *ngIf="!showOne" class="img" src="./assets/images/e_sign.png" alt="Credential my Doc"
              (click)="esignOpenOne()" />
                  <img *ngIf="showOne" class="img eSignImageShow" [src]="esignOne" alt="Credential my Doc"
              (click)="esignOpenOne()" />
                  <input type="hidden" name="cmd_00124" id="cmd_00124" [class.is-invalid]="(eyeForm.controls['cmd_00124'].touched && eyeForm.controls['cmd_00124'].invalid) || (eyeForm.controls['cmd_00124'].invalid && isSubmit)" formControlName="cmd_00124" value="{{ finalImgPath }}" />
                  <div class="invalid-feedback"
                      *ngIf="(eyeForm.controls['cmd_00124'].touched && eyeForm.controls['cmd_00124'].invalid) || (eyeForm.controls['cmd_00124'].invalid && isSubmit)">
                           Signature is required and cannot be empty
                  </div>
                  <div class="col-md-2" style="padding-left:60px">
                      <label (click)="esignOpenOne()" style="margin-left: -53px;">Signature:</label>
                  </div>  
          </div>
  </td>
  <td colspan="6">
   Date:
    <div class="input-group-append">
      <input class="form-control onboard datepicker" formControlName="cmd_00125" (blur)="clearInputIfInvalidMandi('cmd_00125')"
      [class.is-invalid]="(eyeForm.controls['cmd_00125'].touched && eyeForm.controls['cmd_00125'].invalid) || (eyeForm.controls['cmd_00125'].invalid && isSubmit)"
      ngbDatepicker #d2003="ngbDatepicker" id="cmd_00125" name="cmd_00125" type="text"  data-id="cmd_00125">
     <button class="btn btn-outline-secondary calendar" (click)="d2003.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
      </div>
  <div class="formLabel col-md-11"
  *ngIf="eyeForm.controls['cmd_00125'].touched && eyeForm.controls['cmd_00125'].invalid">
  <small class="text-danger" *ngIf="eyeForm.controls['cmd_00125'].errors?.required">
  required
  </small>
  </div>
  <div  *ngIf="eyeForm.get('cmd_00125').invalid ">
    <small class="text-danger" *ngIf="eyeForm.get('cmd_00125').errors?.invalidDateFormat">
      Only Date Format is allowed(MM/DD/YYYY)                  </small>
    <small class="text-danger" *ngIf="eyeForm.get('cmd_00125').errors?.invalidMonth">
    Month is out of range
    </small>
    <small class="text-danger" *ngIf="eyeForm.get('cmd_00125').errors?.invalidDateyear">
      year is out of range(1900-2099)                    </small>
      <small class="text-danger" *ngIf="eyeForm.get('cmd_00125').errors?.invalidDateRange">
        date is out of range
        </small>
      </div>
  </td>
</tr>
<tr>
  <td colspan="12">
      <div class="text-center">
          <h6>OREGON PRACTITIONER CREDENTIALING APPLICATION</h6>
          <h6>AUTHORIZATION AND RELEASE OF INFORMATION FORM</h6><br>
          <h6><i>Modified Releases Will Not Be Accepted</i></h6>
      </div> 
  </td>
</tr>
<tr>
  <td colspan="12">
          <label class="formLabel">By submitting this application, I understand and agree to the following:</label>
  </td>
</tr>
<tr>
  <td colspan="12">
    <ol>
      <li>
        I understand and acknowledge that, as an applicant for medical staff membership at the designated hospital(s) and/or participation status with
the health care related organization(s) [e.g. hospital, medical staff, medical group, independent practice association (IPA), health plan, health
maintenance organization (HMO), preferred provider organization (PPO), physician hospital organization (PHO), medical society,
professional association, medical school faculty position or other health delivery entity or system] indicated on this application , I have the
burden of producing adequate information for proper evaluation of my competence, character, ethics, mental and physical health status, and
other qualifications. In this application, I have provided information on my qualifications, professional training and experience, prior and
current licensure, Drug Enforcement Agency registration and history, and applicable certifications. I have provided peer references familiar
with my professional competence and ethical character, if requested. I have disclosed and explained any past or pending professional
corrective action, licensure limitations or related matter, if any. I have reported my malpractice claims history, if any, and have attached or
will provide a copy of a current certificate of professional liability coverage.
      </li>
      <li>
        I further understand and acknowledge that the health care related organization(s) or designated agent would investigate the information in this
        application. By submitting this application, I agree to such investigation and to the disciplinary reporting and information exchange activities
        of the health care related organization(s) as a part of the verification and Credentialing process.
        
      </li>
      <li>
        I authorize all individuals, institutions, entities of other hospitals or institutions with which I have been associated and all professional liability
        insurers with which I have had or currently have professional liability insurance, who may have information bearing on my professional
        qualifications, ethical standing, competence, and mental and physical health status, to consult with the designated health care related
        organization(s), their staffs and agents.
        
      </li>
      <li>
        I consent to the inspection of records and documents that may be material to an evaluation of qualifications and my ability to carry out the
        clinical privileges/services I request. I authorize each and every individual and organization in custody of such records and documents to
        permit such inspection and copying. I am willing to make myself available for interviews, if required or requested.
        
      </li>
      <li>
        I release from any liability, to the fullest extent permitted by law, all persons for their acts performed in a reasonable manner in conjunction
        with investigating and evaluating my application and qualifications, and I waive all legal claims against any representative of the health care
        related organization(s) or their respective agent(s) who acts in good faith and without malice in connection with the investigation of this
        application.
        
      </li>
      <li>
        I understand and agree that the authorizations and releases given by me herein shall be valid so long as I am an applicant for or have medical
        staff membership and/or clinical privileges/participation status at the health care related organization(s) designated herein, unless revoked by
        me in writing.
        
      </li>
      <li>
        For hospital or medical staff membership/clinical privileges, I acknowledge that I have been informed of, and hereby agree to abide by, the
        medical staff bylaws, rules, regulations and policies.
        
      </li>
      <li>
        I agree to exhaust all available procedures and remedies as outlined in the bylaws, rules, regulations, and policies, and/or contractual
        agreements of the health care related organization(s) where I have membership and/or clinical privileges/participation status before initiating
        judicial action.
        
      </li>
      <li>
        I further acknowledge that I have read and understand the foregoing Authorization and Release. A photocopy of this Authorization and
        Release shall be as effective as the original and authorization constitutes my written authorization and request to communicate any relevant
        information and to release any and all supportive documentation regarding this application.
        
      </li>
    </ol>
  </td>
</tr>
<tr class="border">
  <td colspan="12">
              <input class="form-control onboard"  formControlName="cmd_00126" 
                  id="cmd_00126" name="cmd_00126" type="text" placeholder= "Printed Name" [class.is-invalid]="(eyeForm.controls['cmd_00126'].touched && eyeForm.controls['cmd_00126'].invalid) || (eyeForm.controls['cmd_00126'].invalid && isSubmit)"  data-id="managing_contact_name" >
              <div class="formLabel col-md-11"
                  *ngIf="eyeForm.controls['cmd_00126'].touched && eyeForm.controls['cmd_00126'].invalid">
                  <small class="text-danger" *ngIf="eyeForm.controls['cmd_00126'].errors?.required">
                      required
                  </small>
              </div>
              <label class="formlabel" style="font-size: 12px;">Printed name:</label>
  </td>
</tr>
<tr class="border">
  <td colspan="6">
          <div class="col-md-4">
                  <img *ngIf="!showTwo" class="img" src="./assets/images/e_sign.png" alt="Credential my Doc"
              (click)="esignOpenTwo()" />
                  <img *ngIf="showTwo" class="img eSignImageShow" [src]="esignTwo" alt="Credential my Doc"
              (click)="esignOpenTwo()" />
                  <input type="hidden" name="cmd_00127" id="cmd_00127" [class.is-invalid]="(eyeForm.controls['cmd_00127'].touched && eyeForm.controls['cmd_00127'].invalid) || (eyeForm.controls['cmd_00127'].invalid && isSubmit)" formControlName="cmd_00127" value="{{ finalImgPath }}" />
                  <div class="invalid-feedback"
                      *ngIf="(eyeForm.controls['cmd_00127'].touched && eyeForm.controls['cmd_00127'].invalid) || (eyeForm.controls['cmd_00127'].invalid && isSubmit)">
                           Signature is required and cannot be empty
                  </div>
                  <div class="col-md-2" style="padding-left:60px">
                      <label (click)="esignOpenTwo()" style="margin-left: -53px;">Signature:</label>
                  </div>  
          </div>
  </td>
  <td colspan="6">
   Date:
    <div class="input-group-append">
      <input class="form-control onboard datepicker" formControlName="cmd_00128" (blur)="clearInputIfInvalidMandi('cmd_00128')"
      [class.is-invalid]="(eyeForm.controls['cmd_00128'].touched && eyeForm.controls['cmd_00128'].invalid) || (eyeForm.controls['cmd_00128'].invalid && isSubmit)"
      ngbDatepicker #d2004="ngbDatepicker" id="cmd_00128" name="cmd_00128" type="text"  data-id="cmd_00128">
     <button class="btn btn-outline-secondary calendar" (click)="d2004.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
      </div>
  <div class="formLabel col-md-11"
  *ngIf="eyeForm.controls['cmd_00128'].touched && eyeForm.controls['cmd_00128'].invalid">
  <small class="text-danger" *ngIf="eyeForm.controls['cmd_00128'].errors?.required">
  required
  </small>
  </div>
  <div  *ngIf="eyeForm.get('cmd_00128').invalid ">
    <small class="text-danger" *ngIf="eyeForm.get('cmd_00128').errors?.invalidDateFormat">
      Only Date Format is allowed(MM/DD/YYYY)                  </small>
    <small class="text-danger" *ngIf="eyeForm.get('cmd_00128').errors?.invalidMonth">
    Month is out of range
    </small>
    <small class="text-danger" *ngIf="eyeForm.get('cmd_00128').errors?.invalidDateyear">
      year is out of range(1900-2099)                    </small>
      <small class="text-danger" *ngIf="eyeForm.get('cmd_00128').errors?.invalidDateRange">
        date is out of range
        </small>
      </div>
  </td>
</tr>
<tr>
  <td colspan="12">
      <div class="text-center">
          <h6>Attachment A</h6>
      </div> 
  </td>
</tr>
  <tr class="border">
  <td colspan="12">
  <div class="centered texasFirstTable">
  <div class="row">
  <div class="col-md-8">
  <h2  style="margin-left: 15px;  font-size: 16px;"><b>Professional Liability Action Detail — Confidential</b></h2>
                         
</div>
<div class="col-md-4">
<label for="cmd_00131"><b>Does Not Apply:</b></label>&nbsp;&nbsp;
<input type="checkbox" formControlName="cmd_00131"
id="cmd_00131" value="1">
</div>
</div>
</div>
</td>
</tr>
<tr class="border">
  <td colspan="12">
      <div class="col-md-12">
          <label class="formLabel" style="font-size: 18px;">Please list any past or current professional liability claim or lawsuit, which has been filed against you. Photocopy
            this page as needed and submit a separate page for EACH professional liability claim/lawsuit. It is not
            acceptable to simply submit court documents in lieu of completing this document. Please complete each field.
            Please attach additional sheet(s), if necessary.</label>
      </div>       

  </td>
</tr>
<tr class="border">
  <td colspan="12">
    <label class="formlabel" style="font-size: 12px;">Practitioner’s name (print or type):</label>
              <input class="form-control onboard"  formControlName="cmd_00126" 
                  id="cmd_00126" name="cmd_00126" type="text" placeholder= "Providers full name"   data-id="managing_contact_name" >
              
  </td>
</tr>
<tr class="border">
  <td colspan="12">
    <label for="incident-date">Month/day/year of the incident:</label>
    <div class="input-group-append">
      <input class="form-control onboard datepicker" formControlName="1208" (blur)="clearInputIfInvalid('1208')"
      ngbDatepicker #d2204="ngbDatepicker" id="1208" name="1208" type="text" data-id="1208">
     <button class="btn btn-outline-secondary calendar" (click)="d2204.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
      </div>
      <div  *ngIf="eyeForm.get('1208').invalid ">
        <small class="text-danger" *ngIf="eyeForm.get('1208').errors?.invalidDateFormat">
          Only Date Format is allowed(MM/DD/YYYY)                  </small>
        <small class="text-danger" *ngIf="eyeForm.get('1208').errors?.invalidMonth">
        Month is out of range
        </small>
        <small class="text-danger" *ngIf="eyeForm.get('1208').errors?.invalidDateyear">
          year is out of range(1900-2099)                    </small>
          <small class="text-danger" *ngIf="eyeForm.get('1208').errors?.invalidDateRange">
            date is out of range
            </small>
          </div>
  </td>
</tr>
<tr class="border">
  <td colspan="12">

    <div class="input-group-append">
      <input class="form-control onboard" placeholder="How was provider alleged to have caused the injury"  formControlName="1216" 
                  id="1216" name="1216" type="text"    data-id="managing_contact_name" >
      </div>
  </td>
</tr>
<tr class="border">
  <td colspan="12">
    Your role and specific responsibilities in the incident:<br> 
    
    <div class="input-group-append">
      <input class="form-control onboard"  placeholder="What was the providers role in the event" formControlName="1218" 
                  id="1218" name="1218" type="text"    data-id="managing_contact_name" >
      </div>
  </td>
</tr>
<tr class="border">
  <td colspan="12">
    Subsequent events, including patient’s clinical outcome: <br> 
    
    <div class="input-group-append">
      <input class="form-control onboard" placeholder="Specifics of the event"  formControlName="1217" 
                  id="1217" name="1217" type="text"    data-id="managing_contact_name" >
      </div>
  </td>
</tr>
<tr class="border">
  <td colspan="12">
    <label for="incident-date">Month/day/year the suit or claim was filed:</label>
    <div class="input-group-append">
      <input class="form-control onboard datepicker" (blur)="clearInputIfInvalid('1209')" formControlName="1209" placeholder="Date of Lawsuit" 
      ngbDatepicker #d2205="ngbDatepicker" id="1209" name="1209" type="text" data-id="1209">
     <button class="btn btn-outline-secondary calendar" (click)="d2205.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
      </div>
      <div  *ngIf="eyeForm.get('1209').invalid ">
        <small class="text-danger" *ngIf="eyeForm.get('1209').errors?.invalidDateFormat">
          Only Date Format is allowed(MM/DD/YYYY)                  </small>
        <small class="text-danger" *ngIf="eyeForm.get('1209').errors?.invalidMonth">
        Month is out of range
        </small>
        <small class="text-danger" *ngIf="eyeForm.get('1209').errors?.invalidDateyear">
          year is out of range(1900-2099)                    </small>
          <small class="text-danger" *ngIf="eyeForm.get('1209').errors?.invalidDateRange">
            date is out of range
            </small>
          </div>
  </td>
</tr>
<tr class="border">
  <td colspan="12">
    Name and address of insurance carrier/professional liability provider that handled the claim:
    <div class="row">
      <div class="col-md-3">
     
        <input class="form-control" id="1210" name="1210"  placeholder="Insurance Carrier at Time of Occurrence" formControlName="1210"/>
      </div>
      <div class="col-md-3">
        
        <input class="form-control" id="2012" name="2012"  placeholder="Insurance Carrier Address 1" formControlName="2012"/>
      </div>
      <div class="col-md-3">
        
        <input class="form-control" id="1844" placeholder="Insurance Carrier Address2" name="1844"  formControlName="1844"/>
      </div>
      <div class="col-md-3">
        
        <input class="form-control" id="1845" name="1845"  placeholder="Insurance Carrier City"  formControlName="1845"/>
      </div>
      <div class="col-md-3">
        
        <ng-select
        [items]="states"
        bindLabel="name"
        bindValue="id"
        formControlName="1847"
        placeholder="State"
      >
      </ng-select>
      </div>
      <div class="col-md-3">
        
        <input class="form-control" placeholder="Insurance Carrier Zip" id="1848" name="1848"  formControlName="1848"/>
        <small
        class="text-danger"
        *ngIf="eyeForm.controls['1848'].errors?.pattern"
        > Zip code must be atleast 5 digits and less than 10 digits.</small
      >
      </div>
    </div>
  </td>
</tr>
<tr class="border">
  <td colspan="12">
    Your status in the legal action (primary defendant, co-defendant, other):
    <input class="form-control" id="1213" name="1213" placeholder="Other Defendants Involved"  formControlName="1213"/>
  </td>
</tr>
<tr class="border">
  <td colspan="12">
    Current status of suit or other action:
    <input class="form-control" id="1858" name="1858" placeholder="Claim Status"  formControlName="1858"/>
  </td>
</tr>
<tr class="border">
  <td colspan="12">
    <label for="incident-date">Month/day /year of settlement, judgment, or dismissal:</label>
    <div class="input-group-append">
      <input class="form-control onboard datepicker" formControlName="1226" (blur)="clearInputIfInvalid('1226')" placeholder="Settlement Date" 
      ngbDatepicker #d2206="ngbDatepicker" id="1226" name="1226" type="text" data-id="1226">
     <button class="btn btn-outline-secondary calendar" (click)="d2206.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
      </div>
      <div  *ngIf="eyeForm.get('1226').invalid ">
        <small class="text-danger" *ngIf="eyeForm.get('1226').errors?.invalidDateFormat">
          Only Date Format is allowed(MM/DD/YYYY)                  </small>
        <small class="text-danger" *ngIf="eyeForm.get('1226').errors?.invalidMonth">
        Month is out of range
        </small>
        <small class="text-danger" *ngIf="eyeForm.get('1226').errors?.invalidDateyear">
          year is out of range(1900-2099)                    </small>
          <small class="text-danger" *ngIf="eyeForm.get('1226').errors?.invalidDateRange">
            date is out of range
            </small>
          </div>
  </td>
</tr>
<tr class="border">
  <td colspan="12">
    If case was settled out-of-court, or with a judgment, settlement amount attributed to you:
    <input class="form-control" id="1231" name="1231" placeholder="Total Amount of Settlement"  formControlName="1231"/>
  </td>
</tr>
<tr class="border">
  <td colspan="12">
    
    <b>I verify the information contained in this form is correct and complete to the best of my knowledge.</b>
  </td>
</tr>
<tr class="border">
  <td colspan="6">
          <div class="col-md-4 signaturePadding">
                  <img *ngIf="!showThree" class="img" src="./assets/images/e_sign.png" alt="Credential my Doc"
              (click)="esignOpenThree()" />
                  <img *ngIf="showThree" class="img eSignImageShow" [src]="esignThree" alt="Credential my Doc"
              (click)="esignOpenThree()" />
                  <input type="hidden" name="cmd_00130" id="cmd_00130" [class.is-invalid]="(eyeForm.controls['cmd_00130'].touched && eyeForm.controls['cmd_00130'].invalid) || (eyeForm.controls['cmd_00130'].invalid && isSubmit)" formControlName="cmd_00130" value="{{ finalImgPath }}" />
                  <div class="invalid-feedback"
                      *ngIf="(eyeForm.controls['cmd_00130'].touched && eyeForm.controls['cmd_00130'].invalid) || (eyeForm.controls['cmd_00130'].invalid && isSubmit)">
                           Signature is required and cannot be empty
                  </div>
                  <div class="col-md-2" style="padding-left:60px">
                      <label (click)="esignOpenThree()" style="margin-left: -53px;">Signature:</label>
                  </div>  
          </div>
  </td>
  <td colspan="6">
   Date:
    <div class="input-group-append">
      <input class="form-control onboard datepicker" formControlName="cmd_00129" (blur)="clearInputIfInvalidMandi('cmd_00129')"
      [class.is-invalid]="(eyeForm.controls['cmd_00129'].touched && eyeForm.controls['cmd_00129'].invalid) || (eyeForm.controls['cmd_00129'].invalid && isSubmit)"
      ngbDatepicker #d2009="ngbDatepicker" id="cmd_00129" name="cmd_00129" type="text"  data-id="cmd_00129">
     <button class="btn btn-outline-secondary calendar" (click)="d2009.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
      </div>
  <div class="formLabel col-md-11"
  *ngIf="eyeForm.controls['cmd_00129'].touched && eyeForm.controls['cmd_00129'].invalid">
  <small class="text-danger" *ngIf="eyeForm.controls['cmd_00129'].errors?.required">
  required
  </small>
  </div>
  <div  *ngIf="eyeForm.get('cmd_00129').invalid ">
    <small class="text-danger" *ngIf="eyeForm.get('cmd_00129').errors?.invalidDateFormat">
      Only Date Format is allowed(MM/DD/YYYY)                  </small>
    <small class="text-danger" *ngIf="eyeForm.get('cmd_00129').errors?.invalidMonth">
    Month is out of range
    </small>
    <small class="text-danger" *ngIf="eyeForm.get('cmd_00129').errors?.invalidDateyear">
      year is out of range(1900-2099)                    </small>
      <small class="text-danger" *ngIf="eyeForm.get('cmd_00129').errors?.invalidDateRange">
        date is out of range
        </small>
      </div>
  </td>
</tr>
  </table> 
  </form>
  <div class="modal-footer mt-4">

    <button *ngIf="formData.receivedForm" type="button" class="btn customOrange float-right"
    (click)="saveForm('approve')">Approve</button>
    <button *ngIf="formData.submitForm" type="submit" class="btn btn customOrange float-right"
    (click)="saveForm('submit')">Submit</button>
    <button *ngIf="formData.submitForm" type="button" class="btn btn customOrange float-right"
    (click)="saveForm('save')">Save</button>
    <button *ngIf="formData.previewForm" type="button" class="btn customOrange float-right" (click)="close()">Close</button>

</div>
</div>