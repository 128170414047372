import { Component,OnInit, Input, EventEmitter, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { OnboardingformsServiceService } from '../../../services/onboardingforms-service/onboardingforms-service.service';
import { ToasterNotiService } from '../../../services/notifications/toaster-noti.service';
// import { CommonService } from '../../../shared/services/common/common.service'
import { EsignComponent } from '../../esign/esign.component';
import { CustomAdapter, CustomDateParserFormatter } from '../../../DateFormatter/customDate';
import { DomSanitizer } from '@angular/platform-browser';

import {
  NgbModal,
  NgbModalOptions,
  NgbDateAdapter,
  NgbDateParserFormatter
} from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'dicksonmd',
  templateUrl: './dickson-medical-associates-md1.component.html',
  styleUrls: ['./dickson-medical-associates-md1.component.scss'],
  providers: [
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }
  ]
})
export class DicksonMedicalAssociatesMD1Component implements OnInit {
  
  @Input() DicksonMDFroms1 : UntypedFormGroup
  @Input() isSubmit;
  @Output() isHandleNAChanges = new EventEmitter();
  @Output() isclearInputIfInvalid = new EventEmitter();
  @Output() isclearInputIfInvalidMandi = new EventEmitter();
  @Output() isnumberOnly = new EventEmitter();
  @Input() showTwo: any = false;

  @Input() showThree: any = false;
  // @Input() showFour: any = false;
  @Input() showOne: any = false;


  @Input() esignOne: any;
  @Input() esignTwo: any;
  @Input() esignThree: any;
  @Input() esignFour: any;

  @Input() finalImgPath: any = '';
  @Input() finalImg: any;

  @Input() finalImgPathTwo: any = [];
  @Input() finalImgTwo: any = '';


  @Input() finalImgPathThree: any = [];
  @Input() finalImgThree: any = '';

  // @Input() finalImgPathFour: any = [];
  // @Input() finalImgFour: any = '';






  constructor(
    private fb: UntypedFormBuilder,
    private modalService: NgbModal,
    private _sanitizer: DomSanitizer,
    private onboardService: OnboardingformsServiceService, private notiService: ToasterNotiService,

  ) { }

  @Input() formData;
  @Input() fieldsData;
  @Input() formId;

  modalOption: NgbModalOptions = {
    size: 'md',
    keyboard: false,
    backdrop: 'static'
  };


  ngOnInit(): void {
    this.getStatesData();
    this.getStaffStatusData();
    this.getPreClaimData();
    this.getAggregatesData();



  }

  
  
  clearInputIfInvalid(event) {
    this.isclearInputIfInvalid.emit(event)

  }
  clearInputIfInvalidMandi(event) {
    this.isclearInputIfInvalidMandi.emit(event)

  }
  
  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  handleNAchange(event,ename?) {
    this.isHandleNAChanges.emit(event)

  }
  

  states: Array<object> = [];
  specialty: Array<object> = [];
  public preClaim: any = [];
  public aggregates: any = [];
  questionCounter = 0;
  staffStatus: Array<object> = [];
  indexKey = 0;

  public esignOpenOne() {
    const modalRef = this.modalService.open(EsignComponent, this.modalOption);
    modalRef.result.then(result => { });
    modalRef.componentInstance.base64Img.subscribe(data => {
      modalRef.close();
      this.finalImgPath = data.finalFilePath;
      let temp = {
        cmd_11122: this.finalImgPath
      };
      this.DicksonMDFroms1.patchValue(temp);
      this.finalImg = data.base64Img;
      this.esignOne = this._sanitizer.bypassSecurityTrustResourceUrl(
        'data:image/jpg;base64,' + this.finalImg
      );
      this.showOne = true;
    });
  }

  public validationsDataAdd(event?) {
    let yesNoQuestionIds = {
      cmd_0232: 'cmd_0255',
      cmd_0233: 'cmd_0257',
      cmd_0234: 'cmd_0259',
      cmd_0235: 'cmd_0261',
      cmd_0236: 'cmd_0263',
      cmd_0237: 'cmd_0265',
      cmd_0238: 'cmd_0267',
      cmd_0239: 'cmd_0269',
      cmd_0240: 'cmd_0271',
      cmd_0241: 'cmd_0273',
      cmd_0242: 'cmd_0275',
      cmd_0243: 'cmd_0277',
      cmd_0244: 'cmd_0279',
      cmd_0245: 'cmd_0281',
      cmd_0246: 'cmd_0283',
      cmd_0247: 'cmd_0285',
      cmd_0248: 'cmd_0287',
      cmd_0249: 'cmd_0289',
      cmd_0250: 'cmd_0291',
      cmd_0251: 'cmd_0293',
      cmd_0252: 'cmd_0295',
      cmd_0253: 'cmd_0297',
      cmd_0254: 'cmd_0299'
    };
    // ID of respective explanation section
    let yesNoQuestionExplanation = [
      'cmd_0255',
      'cmd_0257',
      'cmd_0259',
      'cmd_0261',
      'cmd_0263',
      'cmd_0265',
      'cmd_0267',
      'cmd_0269',
      'cmd_0271',
      'cmd_0273',
      'cmd_0275',
      'cmd_0277',
      'cmd_0279',
      'cmd_0281',
      'cmd_0283',
      'cmd_0285',
      'cmd_0287',
      'cmd_0289',
      'cmd_0291',
      'cmd_0293',
      'cmd_0295',
      'cmd_0297',
      'cmd_0299'
    ];
    this.questionCounter = 0;
    for (const key in yesNoQuestionIds) {
      if (yesNoQuestionIds.hasOwnProperty(key)) {
        // console.log(yesNoQuestionIds[key]);
        const element = this.DicksonMDFroms1.controls[key].value;
        // console.log(element);
        if (element == 1) {
          this.questionCounter++;
        }
      }
    }
    // console.log(this.questionCounter);
    for (this.indexKey = 0; this.indexKey < 23; this.indexKey++) {
      // console.log(yesNoQuestionExplanation[0]);
      if (this.indexKey < this.questionCounter) {
        // console.log(this.DicksonMDFroms1.controls[yesNoQuestionExplanation[this.indexKey]])
        if (this.DicksonMDFroms1.controls[yesNoQuestionExplanation[this.indexKey]].value == '' || this.DicksonMDFroms1.controls[yesNoQuestionExplanation[this.indexKey]].value == null) {
          // console.log('setting error')
        this.DicksonMDFroms1.controls[yesNoQuestionExplanation[this.indexKey]].setErrors(
          {
            errors: true
          }
        );
        }
      } else {
        // console.log('removing error');
        // console.log('else error');
        this.DicksonMDFroms1.controls[yesNoQuestionExplanation[this.indexKey]].setErrors(
          null
        );
      }
    }
    // for (const key in this.DicksonMDFroms1.controls) {
    //   if (this.DicksonMDFroms1.controls.hasOwnProperty(key)) {
    //     const element = this.DicksonMDFroms1.controls[key];
    //     if(element.status=="INVALID"){
    //     console.log(key + " " + element.status)
    //     }
    //   }
    // }
  }

  public getStaffStatusData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getStaffStatusData(params).subscribe(
      data => {
        if (data['success']) {
          this.staffStatus = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }
 
  public getAggregatesData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getAggregatesData(params).subscribe(
      data => {
        if (data['success']) {
          this.aggregates = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }
  public getPreClaimData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getPreClaimData(params).subscribe(
      data => {
        if (data['success']) {
          this.preClaim = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }
  public getSpecialtyData() 
    {
      let params: {};
      //this.spinner.show();
      this.onboardService.getSpecialtyData(params).subscribe(
        data => {
          if (data['success']) {
            this.specialty = data.data.map(i => ({
              ...i,
              id: i.id.toString()
            }));
          } else {
            this.notiService.showError(data.error, '', 3000);
          }
          //this.spinner.hide();
        },
        error => {
          //this.spinner.hide();
        }
      );
    }
  public getStatesData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getStatesData(params).subscribe(
      data => {
        if (data['success']) {
          this.states = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
      },
      error => {
      }
    );
  }

  

  

  








}
