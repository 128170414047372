<div class="modal-header">
    <h4 class="modal-title">{{isSend? 'Send Mail Notification':'ReSend Mail Notification'}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="row p-2"> 

      <div class="col-4"> Provider Name</div> 
      <div class="col-8"> : {{mailDetails &&mailDetails.pro_name}}</div> 
    </div>
    <div class="row p-2"> 

        <div class="col-4">Form Name</div> 
        <div class="col-8">: {{mailDetails &&mailDetails.form_name}}</div> 
      </div>

      <div class="row p-2"> 

        <div class="col-4"> Pending Since</div> 
        <div class="col-8">: {{mailDetails &&mailDetails.created_on}}</div> 
      </div>
      <div class="row p-2"> 
        <div class="col-4"> Provider Email:</div> 
        <div class="col-8">: {{mailDetails &&mailDetails.email}}</div> 
      </div>

      <div class="d-flex flex-row-reverse bd-highlight p-2"> 
    <button type="button" class="btn customOrange" (click)="onsendEmail()">{{isSend?'Send':'Resend'}} </button>
      </div>

    </div>
    <div class="modal-footer">

    </div>