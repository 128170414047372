<div id="monthMode">
  <div class="input-group">

    <input name="date" type="text" class="form-control month-input" [(ngModel)]="selectedDate"
      [ngbPopover]="popContent" [popoverTitle]="popTitle" placement="bottom" container="body" [autoClose]="'outside'"
      triggers="manual" #pop="ngbPopover"  (keyup)="handleDateChange($event)"
      placeholder="MM/DD/YYYY" maxlength="10" (blur)="onBlur()" />
      <div class="input-group-prepend" (click)="handlePopover(pop)">
        <div class="input-group-text"><i class="fas fa-calendar-alt"></i></div>
      </div>
  </div>
    <!-- <button class="btn"><i class="fas fa-calendar-alt"></i></button> -->
  <ng-template #popTitle let-language="language">
    <div class="date-header">
    <i class="fas fa-chevron-left float-left pr-2" (click)="handleMonthSelect('back')"></i>
    <ng-select dropdownPosition='bottom' [virtualScroll]="true" [items]="years" bindLabel="name" placeholder="year"
      bindValue="id" [clearable]="false" [(ngModel)]="selectedYear" (change)="handleYear($event)"></ng-select>
      <ng-select dropdownPosition='bottom' [virtualScroll]="true" [items]="months" bindLabel="name" placeholder="month"
      bindValue="value" [clearable]="false" [(ngModel)]="selectedMonth" (change)="handleMonthSelect('change')"></ng-select>
      <i class="fas fa-chevron-right float-right pl-2" (click)="handleMonthSelect('move')"></i>
    </div>
    </ng-template>
  <ng-template #popContent let-greeting="greeting" >
    <div class="container">
      <div class="item monthLabel p-0">Mo</div>
      <div class="item monthLabel p-0">Tu</div>
      <div class="item monthLabel p-0">We</div>
      <div class="item monthLabel p-0">Th</div>
      <div class="item monthLabel p-0">Fr</div>
      <div class="item monthLabel p-0">Sa</div>
      <div class="item monthLabel p-0">Su</div>
      <div class="item" *ngFor="let item of offset"></div>
      <div class="item" [ngStyle]="{'border':item.value==today?'1px solid black':'none'}" [ngClass]="item.value==selectedDate?'active':'inActive'" *ngFor="let item of days" (click)="handleDaysSelection(item)">{{item.name}}</div>
    </div>
  </ng-template>
</div>
