 <div id="intermedimages">
    <div>
        <h2 style="text-align:center">Idaho Practitioner Credentials Verification Application</h2>
    </div>
    <form [formGroup]="intermedimage">
        <table width="100%">
            <tbody>
                <div class="main">
                    <tr>
                        <td>
                            <div>
                                <h5><b>To use the Idaho Practitioner Application (IPA), follow these instructions:</b>
                                </h5>
                            </div>
                        </td>
                    </tr>
                    <tr class="col-12">
                        <td>
                            <div>
                                <ol>
                                    <li>
                                        Complete the application in its entirety using black or blue ink. <b>Keep an
                                            <u>unsigned</u>
                                            and <u>undated</u> copy of the
                                            application on file for future requests</b>. When a request is received,
                                        send a
                                        copy
                                        of
                                        the completed application, making
                                        sure that all information is complete, current and accurate. Please sign and
                                        date
                                        pages
                                        8
                                        and 10. Please document any YES
                                        responses on the Attestation Question page.
                                    </li>
                                    <li>
                                        <b>Prior to submitting this application to any health care related organization,
                                            inquire
                                            with the organization, as you may need
                                            authorization (through a pre-application process) before the application is
                                            accepted
                                        </b>.
                                        Identify the health care related
                                        organization(s) to which this application is being submitted in the space
                                        provided
                                        below.
                                    </li>
                                    <li>
                                        Attach copies of requested documents each time the application is submitted.
                                    </li>
                                    <li>
                                        If changes must be made to the completed application, strike out the information
                                        and
                                        write
                                        in the modification, initial and
                                        date.
                                    </li>
                                    <li>
                                        If a section does not apply to you, please check the provided box at the top of
                                        the
                                        section.
                                    </li>
                                    <li>
                                        Expect addendums from the requesting organizations for information not included
                                        on
                                        the
                                        IPA.
                                    </li>
                                </ol>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div>
                                <label class="table table-bordered" style="border: 2px solid black;">
                                    <b>This application is submitted to:</b>
                                    <span>
                                        <input type="text" id="cmd_373" class="form-control" formControlName="cmd_373"
                                            class="form-control">
                                    </span>
                                </label>
                            </div>
                        </td>
                    </tr>
                    <tr class="col-12">
                        <td colspan="12">
                            <div class="table table-bordered" style="table-layout: fixed;">
                                <td colspan="12">
                                    <label>
                                        <h6>I. INSTRUCTIONS</h6>
                                    </label>
                                </td>
                                <tr class="col-12">
                                    <td colspan="4">
                                        <div class="row">
                                            <div class="col-12">
                                                <label>This form should be&nbsp;<b>typed or legibly printed in black or blue
                                                        ink.&nbsp;</b>If
                                                    more
                                                    space is needed than provided, attach additional sheets and
                                                    reference the question being answered. <i><u>Please do not use
                                                            abbreviations.</u></i><b>&nbsp;Current copies of the following documents
                                                        must be
                                                        submitted with this
                                                        application:&nbsp;</b>(all are required for MDs, DOs; as applicable for other
                                                    health
                                                    practitioners).If not available, indicate why.
                                                </label>
                                            </div>
                                            <div>
                                                <ul>
                                                    <li>State Professional License(s).</li>
                                                </ul>
                                                <ul>
                                                    <li>DEA Certificate w/ Idaho address.</li>
                                                </ul>
                                                <ul>
                                                    <li>ECFMG (if applicable).</li>
                                                </ul>
                                                <ul>
                                                    <li>ISBP Certificate.</li>
                                                </ul>
                                                <ul>
                                                    <li>Passport photo (for hospitals only).</li>
                                                </ul>
                                                <ul>
                                                    <li>Face Sheet of Professional Liability Policy or Certificate.</li>
                                                </ul>
                                                <ul>
                                                    <li>Curriculum Vitae (Not an acceptable substitute for completing
                                                        the application.). </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <div>
                                    <h6 style="text-align:center"><b>** All sections must be completed in their
                                            entirety.
                                            **</b>
                                    </h6>
                                </div>
                          </div>
                      </td>
                   </tr>
                     <tr class="col-12"> 
                        <td colspan="12">
                            <div class="table table-bordered" style="table-layout: fixed;">
                                <td colspan="12">
                                    <label>
                                        <h6>II. PRACTITIONER INFORMATION</h6>
                                    </label>
                                </td>
                                <tr class="col-12">
                                    <td colspan="4">
                                        <div class="row">
                                            <div class="col-12">
                                                <label for="9">Last Name:(include suffix; Jr., Sr., III)<span
                                                        class="mandatory">*</span>:</label>
                                            </div>
                                            <div class="col-6">
                                                <input type="text" placeholder=" Last Name" id="9"  (change)="onChangeLastName($event)"formControlName="9"
                                                    class="form-control"
                                                    [class.is-invalid]="(intermedimage.controls['9'].touched && intermedimage.controls['9'].invalid ) || (intermedimage.controls['9'].invalid && isSubmit)">
                                                <div class="invalid-feedback"
                                                    *ngIf="(intermedimage.controls['9'].touched && intermedimage.controls['9'].invalid) || (intermedimage.controls['9'].invalid && isSubmit)">
                                                    The Last name is required and cannot be empty </div>
                                            </div>
                                            <div class="col-6">
                                                <ng-select [items]="suffix"
                                                    [class.is-invalid]="(intermedimage.controls['29'].touched && intermedimage.controls['29'].invalid ) || (intermedimage.controls['29'].invalid && isSubmit)"
                                                    bindLabel="name" placeholder="Suffix" bindValue="id" formControlName="29">
                                                </ng-select>
                                                <div class="invalid-feedback"
                                                    *ngIf="(intermedimage.controls['29'].touched && intermedimage.controls['29'].invalid) || (intermedimage.controls['29'].invalid && isSubmit)">
                                                    Suffix is required and cannot be empty </div>

                                            </div>
                                        </div>
                                    </td>
                                    <td colspan="4">
                                        <div class="row">
                                            <div class="col-12">
                                                <label for="8">First (do not abbreviate) <span class="mandatory">*</span>:</label>
                                            </div>
                                            <div class="col-10">
                                                <input type="text" placeholder=" First Name" id="8" (change)="onChangeFirstName($event)" formControlName="8"
                                                    class="form-control"
                                                    [class.is-invalid]="(intermedimage.controls['8'].touched && intermedimage.controls['8'].invalid ) || (intermedimage.controls['8'].invalid && isSubmit)">
                                                <div class="invalid-feedback"
                                                    *ngIf="(intermedimage.controls['8'].touched && intermedimage.controls['8'].invalid) || (intermedimage.controls['8'].invalid && isSubmit)">
                                                    The First name is required and cannot be empty </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td colspan="4">
                                        <div class="row">
                                            <div class="col-12">
                                                <label for="809">Middle (do not abbreviate):<span class="mandatory">*</span>:</label>
                                            </div>
                                            <div class="col-10">
                                                <input type="text" placeholder="Middle Name" id="809" formControlName="809"
                                                    class="form-control"
                                                    [class.is-invalid]="(intermedimage.controls['809'].touched && intermedimage.controls['809'].invalid ) || (intermedimage.controls['809'].invalid && isSubmit)">
                                                <div class="invalid-feedback"
                                                    *ngIf="(intermedimage.controls['809'].touched && intermedimage.controls['809'].invalid) || (intermedimage.controls['809'].invalid && isSubmit)">
                                                    The Middle name is required and cannot be empty </div>
                                            </div>
                                        </div>
                                    </td>

                                </tr>
                                <tr class="col-12">
                                    <td colspan="7">
                                        <div class="row">
                                            <div class="col-6">
                                                <label for="278">Other name(s) under which you have been known by reference,
                                                    licensing
                                                    and
                                                    or
                                                    educational institutions?</label>
                                            </div>
                                            <div class="col-6">
                                                <input type="text" class="form-control" formControlName="278" id="278"  placeholder="Prior Name"
                                                    class="form-control">
                                            </div>
                                        </div>
                                    </td>
                                    <td colspan="5">
                                        <div class="row">
                                            <div class="col-12">
                                                <label for="822">Degree(s):</label>
                                            </div>
                                            <div class="col-12">
                                                <ng-select [labelForId]="'822'" [items]="credentials" [class.is-invalid]="
                                                    (intermedimage.controls['822'].touched &&
                                                    intermedimage.controls['822'].invalid) ||
                                                    (intermedimage.controls['822'].invalid && isSubmit)" bindLabel="name"
                                                    placeholder="Select Degree" bindValue="id" formControlName="822"> </ng-select>
                                                <div class="invalid-feedback"
                                                    *ngIf="(intermedimage.controls['822'].touched && intermedimage.controls['822'].invalid) || (intermedimage.controls['822'].invalid && isSubmit)">
                                                    Degree is required and cannot be empty </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr class="col-12">
                                    <td colspan="3">
                                        <div class="row">
                                            <div class="col-12 ">
                                                <label for="708">Home Telephone Number:<span class="mandatory">*</span></label>
                                            </div>

                                            <div class="col-12  ">
                                                <input type="text" placeholder="Home Phone " id="708" formControlName="708"
                                                    class="form-control"
                                                    [class.is-invalid]="(intermedimage.controls['708'].touched && intermedimage.controls['708'].invalid ) || (intermedimage.controls['708'].invalid && isSubmit)">
                                                <div class="invalid-feedback"
                                                    *ngIf="(intermedimage.controls['708'].touched && intermedimage.controls['708'].invalid) || (intermedimage.controls['708'].invalid && isSubmit)">
                                                    Cell Phone is required and cannot be empty </div>
                                            </div>

                                        </div>
                                    </td>
                                    <td colspan="2">
                                        <div class="row">
                                            <div class="col-12 ">
                                                <label for="1017">Pager Number:</label>
                                            </div>
                                            <div class="col-12  ">
                                                <input type="text" placeholder="Pager" id="1017" formControlName="1017"
                                                    class="form-control">
                                            </div>
                                        </div>
                                    </td>
                                    <td colspan="3">
                                        <div class="row">
                                            <div class="col-12 ">
                                                <label for="709">Cell Number:<span class="mandatory">*</span></label>
                                            </div>
                                            <div class="col-12  ">
                                                <input type="text" placeholder="Mobile Phone " id="709"
                                                    formControlName="709" class="form-control"
                                                    [class.is-invalid]="(intermedimage.controls['709'].touched && intermedimage.controls['709'].invalid ) || (intermedimage.controls['709'].invalid && isSubmit)">
                                                <div class="invalid-feedback"
                                                    *ngIf="(intermedimage.controls['709'].touched && intermedimage.controls['709'].invalid) || (intermedimage.controls['709'].invalid && isSubmit)">
                                                    Cell Phone is required and cannot be empty </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td colspan="5">
                                        <div class="row">
                                            <div class="col-4 ">
                                                <label for="759">E-Mail Address:<span class="mandatory">*</span></label>
                                            </div>
                                            <div class="col-12  ">
                                                <input type="text" placeholder="E-Mail " id="759" formControlName="759"
                                                    class="form-control"
                                                    [class.is-invalid]="(intermedimage.controls['759'].touched && intermedimage.controls['759'].invalid ) || (intermedimage.controls['759'].invalid && isSubmit)">
                                                <div class="invalid-feedback"
                                                    *ngIf="(intermedimage.controls['759'].touched && intermedimage.controls['759'].invalid) || (intermedimage.controls['759'].invalid && isSubmit)">
                                                    E-Mail Address is required and cannot be empty </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr class="col-12">
                                    <td colspan="3">
                                        <div class="row">
                                            <div class="col-12">
                                                <label for="16">Home Malling Address:<span class="mandatory">*</span></label>
                                            </div>
                                            <div class="col-12">
                                                <input type="text" placeholder="Home Address1  " formControlName="16"
                                                    id="16" class="form-control" class="form-control"
                                                    [class.is-invalid]="(intermedimage.controls['16'].touched && intermedimage.controls['16'].invalid ) || (intermedimage.controls['16'].invalid && isSubmit)">
                                                <div class="invalid-feedback"
                                                    *ngIf="(intermedimage.controls['16'].touched && intermedimage.controls['16'].invalid) || (intermedimage.controls['16'].invalid && isSubmit)">
                                                    Home Mailing Address 1 is required and cannot be empty </div>
                                            </div>
                                            <div class="col-12">
                                                <input type="text" placeholder="Home Address2" formControlName="17"
                                                    id="17" class="form-control" class="form-control"
                                                    [class.is-invalid]="(intermedimage.controls['17'].touched && intermedimage.controls['17'].invalid ) || (intermedimage.controls['17'].invalid && isSubmit)">
                                                <div class="invalid-feedback"
                                                    *ngIf="(intermedimage.controls['17'].touched && intermedimage.controls['17'].invalid) || (intermedimage.controls['17'].invalid && isSubmit)">
                                                    Home Malling Address 2 is required and cannot be empty </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td colspan="2">
                                        <div class="row">
                                            <div class="col-12">
                                                <label for="18">City<span class="mandatory">*</span></label>
                                            </div>
                                            <div class="col-10">
                                                <input type="text" placeholder="Home City " id="18" formControlName="18"
                                                    class="form-control"
                                                    [class.is-invalid]="(intermedimage.controls['18'].touched && intermedimage.controls['18'].invalid ) || (intermedimage.controls['18'].invalid && isSubmit)">
                                                <div class="invalid-feedback"
                                                    *ngIf="(intermedimage.controls['18'].touched && intermedimage.controls['18'].invalid) || (intermedimage.controls['18'].invalid && isSubmit)">
                                                    City is required and cannot be empty </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td colspan="4">
                                        <div class="row">
                                            <div class="col-12 ">
                                                <label for="20">State:<span class="mandatory">*</span></label>
                                            </div>
                                            <div class="col-12 ">
                                                <ng-select [items]="states" [class.is-invalid]="(intermedimage.controls['20'].touched &&
                                                    intermedimage.controls['20'].invalid) ||
                                                    (intermedimage.controls['20'].invalid && isSubmit)" bindLabel="name"
                                                    placeholder="Home State" bindValue="id" formControlName="20"> </ng-select>
                                                <div class="invalid-feedback"
                                                    *ngIf="(intermedimage.controls['20'].touched && intermedimage.controls['20'].invalid) || (intermedimage.controls['20'].invalid && isSubmit)">
                                                    State is required and cannot be empty </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td colspan="3">
                                        <div class="row">
                                            <div class="col-12 ">
                                                <label for="21">Zip Code:<span class="mandatory">*</span></label>
                                            </div>
                                            <div class="col-12 ">
                                                <input type="text" placeholder="Home Zip" id="21" formControlName="21"
                                                    class="form-control"
                                                    [class.is-invalid]="(intermedimage.controls['21'].touched && intermedimage.controls['21'].invalid ) || (intermedimage.controls['21'].invalid && isSubmit)">
                                                <div class="invalid-feedback"
                                                    *ngIf="(intermedimage.controls['21'].touched && intermedimage.controls['21'].invalid) || (intermedimage.controls['21'].invalid && isSubmit)">
                                                    Zip is required and cannot be empty </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr class="col-12">
                                    <td colspan="4">
                                        <div class="row">
                                            <div class="col-12 ">
                                                <label for="31">Birth Date(mm/dd/yy):<span class="mandatory">*</span></label>
                                            </div>
                                            <div class="col-8 ">
                                                <div class="input-group">
                                                <input type="text" placeholder="MM/DD/YYYY" id="31" formControlName="31" (blur)="clearInputIfInvalidMandi('31')" 
                                                    class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker
                                                    #idh01="ngbDatepicker"
                                                    [class.is-invalid]="(intermedimage.controls['31'].touched && intermedimage.controls['31'].invalid ) || (intermedimage.controls['31'].invalid && isSubmit)">
                                                <div class="input-group-append">
                                                    <button class="btn btn-outline-secondary calendar" (click)="idh01.toggle()"
                                                        type="button">
                                                        <i class="fas fa-calendar-alt"></i> </button>
                                                </div>
                                                </div>
                                                <div class="text-danger"
                                                    *ngIf="(intermedimage.controls['31'].invalid&&intermedimage.controls['31'].touched) && (intermedimage.controls['31'].value==''|| intermedimage.controls['31'].value==null) ">
                                                    <small>
                                                        Birth Date is required and cannot be empty
                                                    </small>
                                                
                                                </div>
                                                <small class="text-danger" *ngIf="intermedimage.get('31').errors?.invalidDateFormat">
                                                    Only Date Format is allowed(MM/DD/YYYY) </small>
                                                <small class="text-danger" *ngIf="intermedimage.get('31').errors?.invalidMonth">
                                                    Month is out of range
                                                </small>
                                                <small class="text-danger" *ngIf="intermedimage.get('31').errors?.invalidDateyear">
                                                    year is out of range(1900-2099) </small>
                                                <small class="text-danger" *ngIf="intermedimage.get('31').errors?.invalidDateRange">
                                                    date is out of range
                                                </small>
                                            </div>
                                        </div>
                                    </td>
                                    <td colspan="4">
                                        <div class="row">
                                            <div class="col-12 ">
                                                <label for="810">Birth Place:  </label>
                                            </div>
                                            <div class="col-3 ">
                                                <label for="810">City:<span
                                                        class="mandatory">*</span></label>
                                            </div>
                                            <div class="col-8 ">
                                                <input type="text" placeholder="City of Birth " id="810" formControlName="810"
                                                    class="form-control"
                                                    [class.is-invalid]="(intermedimage.controls['810'].touched && intermedimage.controls['810'].invalid ) || (intermedimage.controls['810'].invalid && isSubmit)">
                                                <div class="invalid-feedback"
                                                    *ngIf="(intermedimage.controls['810'].touched && intermedimage.controls['810'].invalid) || (intermedimage.controls['810'].invalid && isSubmit)">
                                                    Birth Place is required and cannot be empty </div>
                                            </div>
                                            <div class="col-3 ">
                                                <label for="289">State:<span
                                                        class="mandatory">*</span></label>
                                            </div>
                                            <div class="col-8 ">
                                                <ng-select [items]="states" [class.is-invalid]="(intermedimage.controls['289'].touched &&
                                                    intermedimage.controls['289'].invalid) ||
                                                    (intermedimage.controls['289'].invalid && isSubmit)" bindLabel="name"
                                                    placeholder="Birth State" bindValue="id" formControlName="289"> </ng-select>
                                                <div class="invalid-feedback"
                                                    *ngIf="(intermedimage.controls['289'].touched && intermedimage.controls['289'].invalid) || (intermedimage.controls['289'].invalid && isSubmit)">
                                                    State is required and cannot be empty </div>
                                            </div>
                                            <div class="col-3 ">
                                                <label for="291">Country:<span
                                                        class="mandatory">*</span></label>
                                            </div>
                                            <div class="col-8 ">
                                                <input type="text" placeholder="Country of Birth" id="291" formControlName="291"
                                                    class="form-control"
                                                    [class.is-invalid]="(intermedimage.controls['291'].touched && intermedimage.controls['291'].invalid ) || (intermedimage.controls['291'].invalid && isSubmit)">
                                                <div class="invalid-feedback"
                                                    *ngIf="(intermedimage.controls['291'].touched && intermedimage.controls['291'].invalid) || (intermedimage.controls['291'].invalid && isSubmit)">
                                                    Birth Place is required and cannot be empty </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td colspan="4">
                                        <div class="row">
                                            <div class="col-12 ">
                                                <label for="32">Social Security Number:<span class="mandatory">*</span></label>
                                            </div>
                                            <div class="col-12 ">
                                                <input type="Text" placeholder="SSN" id="32"
                                                    formControlName="32" class="form-control"
                                                    [class.is-invalid]="(intermedimage.controls['32'].touched && intermedimage.controls['32'].invalid ) || (intermedimage.controls['32'].invalid && isSubmit)">
                                                <div class="invalid-feedback"
                                                    *ngIf="(intermedimage.controls['32'].touched && intermedimage.controls['32'].invalid) || (intermedimage.controls['32'].invalid && isSubmit)">
                                                    SSN Number is required and cannot be empty </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr class="col-12">
                                    <td colspan="4">
                                        <div class="row">
                                            <div class="col-4 pr-0">
                                                <label for="292">CitizenShip:<span class="mandatory">*</span></label>
                                            </div>
                                            <div class="col-8 pl-0">
                                                <input type="text" placeholder="CitizenShip" id="292" formControlName="292"
                                                    class="form-control"
                                                    [class.is-invalid]="(intermedimage.controls['292'].touched && intermedimage.controls['292'].invalid ) || (intermedimage.controls['292'].invalid && isSubmit)">
                                                <div class="invalid-feedback"
                                                    *ngIf="(intermedimage.controls['292'].touched && intermedimage.controls['292'].invalid) || (intermedimage.controls['292'].invalid && isSubmit)">
                                                    CitizenShip is required and cannot be empty </div>
                                            </div>
                                        </div>
                                    </td>

                                    <td colspan="8">
                                        <div class="row">
                                            <div class="col-4 pr-0">
                                                <label for="9">Languages Fluently Spoken by Practitioner:</label>
                                            </div>
                                            <div class="col-4 pl-0">
                                                <ng-select [items]="languages" [class.is-invalid]="(intermedimage.controls['277'].touched &&
                                                    intermedimage.controls['277'].invalid) ||
                                                    (intermedimage.controls['277'].invalid && isSubmit)" bindLabel="name"
                                                    placeholder="Primary Language" bindValue="id" formControlName="277">
                                                </ng-select>
                                            </div>
                                            <div class="col-4 pl-0">
                                                <ng-select [items]="secondaryLanguages" [class.is-invalid]="(intermedimage.controls['1535'].touched &&
                                                    intermedimage.controls['1535'].invalid) ||
                                                    (intermedimage.controls['1535'].invalid && isSubmit)" bindLabel="name"
                                                    placeholder=" Secondary Language" bindValue="id" formControlName="1535">
                                                </ng-select>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr class="col-12">
                                    
                                    <td colspan="5">
                                        <div class="row">
                                            <div class="form-inline mb-1 pl-0">
                                                <label for="278">Type of Provider:&nbsp;&nbsp;&nbsp;&nbsp;</label>

                                                <input type="checkbox" value="pcp" formControlName="cmd_01" id="cmd_01">&nbsp;&nbsp;
                                                <label for="pcp">PCP</label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                <input type="checkbox" value="urgentcare" formControlName="cmd_02"
                                                    id="cmd_02">&nbsp;&nbsp;
                                                <label for="urgentcare">Urgent Care</label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                <input type="checkbox" value="specialist" formControlName="cmd_03"
                                                    id="cmd_03">&nbsp;&nbsp;
                                                <label for="specialist">Specialist</label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            </div>
                                        </div>
                                    </td>
                                    <td colspan="4">
                                        <div class="row">
                                            <div class="col-4 ml-0">
                                                <label for="30">Gender:<span class="mandatory">*</span></label>
                                            </div>
                                            <div class="col-8 pl-0">
                                                <ng-select [items]="gender" [class.is-invalid]=" (intermedimage.controls['30'].touched &&
                                                intermedimage.controls['30'].invalid) ||
                                                (intermedimage.controls['30'].invalid && isSubmit)" bindLabel="name"
                                                    placeholder="Select Gender" bindValue="id" formControlName="30"> </ng-select>
                                                <div class="invalid-feedback"
                                                    *ngIf="(intermedimage.controls['30'].touched && intermedimage.controls['30'].invalid) || (intermedimage.controls['30'].invalid && isSubmit)">
                                                    Gender is required and cannot be empty </div>

                                            </div>
                                        </div>
                                    </td>
                                    <td colspan="3">
                                        <div class="row">
                                            <div class="col-4 pr-0">
                                                <label for="1634">NPI: <span class="mandatory">*</span></label>
                                            </div>
                                            <div class="col-8 pl-0">
                                                <input type="text" placeholder="NPI " id="1634" formControlName="1634"
                                                    class="form-control"
                                                    [class.is-invalid]="(intermedimage.controls['1634'].touched && intermedimage.controls['1634'].invalid ) || (intermedimage.controls['1634'].invalid && isSubmit)">
                                                <div class="invalid-feedback"
                                                    *ngIf="(intermedimage.controls['1634'].touched && intermedimage.controls['1634'].invalid) || (intermedimage.controls['1634'].invalid && isSubmit)">
                                                    NPI Number is required and cannot be empty </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr class="Col-12">
                                    <td colspan="4">
                                        <div class="row">
                                            <div class="col-4 pr-0">
                                                <label for="819">Medicare UPIN:</label>
                                            </div>
                                            <div class="col-8 pl-0">
                                                <input type="text" placeholder="Medicare Number" id="819"
                                                    formControlName="819" class="form-control">
                                            </div>
                                        </div>
                                    </td>
                                    <td colspan="3">
                                        <div class="row">
                                            <div class="col-4 pr-0">
                                                <label for="325">Medicare Number(ID):</label>
                                            </div>
                                            <div class="col-8 pl-0">
                                                <input type="text" placeholder="Medicare Number (PTAN) " id="325"
                                                    formControlName="325" class="form-control">
                                            </div>
                                        </div>
                                    </td>
                                    <td colspan="5">
                                        <div class="row">
                                            <div class="col-4 pr-0">
                                                <label for="812">Medicaid Number(s):</label>
                                            </div>
                                            <div class="col-8 pl-0">
                                                <input type="text" placeholder=" Medicaid  Number " id="812"
                                                    formControlName="812" class="form-control">
                                            </div>
                                        </div>
                                    </td>

                                </tr>
                                <tr class="col-12">
                                    <td colspan="5">
                                        <div class="row">
                                            <div class="col-12">
                                                <label for="284">Other professional interests in practice, research, etc.:</label>
                                            </div>
                                            <div class="col-8">
                                                <ng-select [items]="specialty" [class.is-invalid]="(intermedimage.controls['284'].touched &&
                                                    intermedimage.controls['284'].invalid) ||
                                                    (intermedimage.controls['284'].invalid && isSubmit)" bindLabel="name"
                                                    placeholder="Primary Specialty" bindValue="id" formControlName="284">
                                                </ng-select>

                                            </div>
                                        </div>
                                    </td>
                                    <td colspan="7">
                                        <div class="row">
                                            <div class="col-12">
                                                <label for="3826">Taxonomy (10-digit code identifying specialty or subspecialty):
                                                </label>
                                            </div>
                                            <div class="col-8 ">
                                                <input type="text" placeholder="Taxonomy" id="3826" maxlength="10"
                                                    formControlName="3826" class="form-control">
                                            </div>
                                        </div>
                                    </td>
                                    </tr>
                                    <tr class ="col-12">
                                    <td colspan="12">
                                        <div class="row">
                                            <div class="col-2">
                                                <label for="962">Subspecialties:</label>
                                            </div>
                                            <div class="col-5">
                                                <ng-select [items]="specialtySecondary" [class.is-invalid]="(intermedimage.controls['962'].touched &&
                                                                    intermedimage.controls['962'].invalid) ||
                                                                    (intermedimage.controls['962'].invalid && isSubmit)"
                                                    bindLabel="name" placeholder="Secondary Specialty" bindValue="id"
                                                    formControlName="962">
                                                </ng-select>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                         </div>
                      </td>
                    </tr>
                    <tr class="col-12">
                    <td colspan="12">
                        <div class="table table-bordered" style="table-layout: fixed;">
                            <td colspan="12">
                                <label>
                                    <h6>III. PRACTICE INFORMATION </h6>
                                </label>
                            </td>
                                <tr>
                                    <td colspan="12">
                                        <div class="row">
                                            <div class="col-4 pr-0">
                                                <label><b>Effective Date at Primary Practice location:</b></label>
                                            </div>
                                            <div class="col-4 pl-0 ">
                                                <div class="input-group">
                                                <input type="text" placeholder=" Effective Date" id="283" formControlName="283" (blur)="clearInputIfInvalid('283')" 
                                                    class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker
                                                    #idh02="ngbDatepicker"
                                                    [class.is-invalid]="(intermedimage.controls['283'].touched && intermedimage.controls['283'].invalid ) || (intermedimage.controls['283'].invalid && isSubmit)">
                                                <div class="input-group-append">
                                                    <button class="btn btn-outline-secondary calendar" (click)="idh02.toggle()"
                                                        type="button">
                                                        <i class="fas fa-calendar-alt"></i> </button>
                                                </div>
                                                </div>
                                                <div *ngIf="intermedimage.get('283').invalid ">
                                                    <small class="text-danger" *ngIf="intermedimage.get('283').errors?.invalidDateFormat">
                                                        Only Date Format is allowed(MM/DD/YYYY)
                                                    </small>
                                                    <small class="text-danger" *ngIf="intermedimage.get('283').errors?.invalidMonth">
                                                        Month is out of range
                                                    </small>
                                                    <small class="text-danger" *ngIf="intermedimage.get('283').errors?.invalidDateyear">
                                                        year is out of range(1900-2099) </small>
                                                    <small class="text-danger" *ngIf="intermedimage.get('283').errors?.invalidDateRange">
                                                        date is out of range
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr class="col-12">
                                    <td colspan="8">
                                        <div class="row">
                                            <div class="col-6 pr-0">
                                                <label for="1634">Name of practice, affiliation or clinic name: </label>
                                            </div>
                                            <div class="col-6 pl-0">
                                                <input type="text" placeholder="Name" id="cmd_15" formControlName="cmd_15"
                                                    class="form-control"
                                                    [class.is-invalid]="(intermedimage.controls['cmd_15'].touched && intermedimage.controls['cmd_15'].invalid ) || (intermedimage.controls['cmd_15'].invalid && isSubmit)">
                                                <div class="invalid-feedback"
                                                    *ngIf="(intermedimage.controls['cmd_15'].touched && intermedimage.controls['cmd_15'].invalid) || (intermedimage.controls['cmd_15'].invalid && isSubmit)">
                                                    NPI Number is required and cannot be empty
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td colspan="8">
                                        <div class="row">
                                            <div class="col-6 pr-0">
                                                <label for="1634">Department name (if hospital based) </label>
                                            </div>
                                            <div class="col-6 pl-0">
                                                <input type="text" placeholder=" Department Name" id="cmd_16" formControlName="cmd_16"
                                                    class="form-control"
                                                    [class.is-invalid]="(intermedimage.controls['cmd_16'].touched && intermedimage.controls['cmd_16'].invalid ) || (intermedimage.controls['cmd_16'].invalid && isSubmit)">
                                                <div class="invalid-feedback"
                                                    *ngIf="(intermedimage.controls['cmd_16'].touched && intermedimage.controls['cmd_16'].invalid) || (intermedimage.controls['cmd_16'].invalid && isSubmit)">
                                                    NPI Number is required and cannot be empty
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr class="col-12">
                                    <td colspan="6">
                                        <div class="row">
                                            <div class="col-4 pr-0">
                                                <label for="cmd_19">Primary Office Street Address:</label>
                                            </div>
                                            <div class="col-8 pl-0 d-flex">
                                                <input type="text" placeholder=" Primary Office Street Address1" id="cmd_19"
                                                    formControlName="cmd_19" class="form-control">
                                            </div>
                                            <div class="col-4 pl-0 d-flex"></div>
                                            <div class="col-8 pl-0">
                                                <input type="text" placeholder=" Primary Office Street Address2" id="cmd_019"
                                                    formControlName="cmd_019" class="form-control">
                                            </div>
                                        </div>
                                    </td>

                                    <td colspan="6">
                                        <div class="row">
                                            <div class="col-6 pr-0">
                                                <label for="cmd_20">City:</label>
                                            </div>
                                            <div class="col-6 pl-0">
                                                <input type="text" placeholder=" City " id="cmd_20" formControlName="cmd_20"
                                                    class="form-control">
                                            </div>
                                            <div class="col-6 pr-0">
                                                <label for="cmd_21">State:</label>
                                            </div>
                                            <div class="col-6 pl-0">
                                                <ng-select [items]="states" [class.is-invalid]="(intermedimage.controls['cmd_21'].touched &&
                                                                        intermedimage.controls['cmd_21'].invalid) ||
                                                                        (intermedimage.controls['cmd_21'].invalid && isSubmit)"
                                                    bindLabel="name" placeholder=" State" bindValue="id" formControlName="cmd_21">
                                                </ng-select>
                                            </div>
                                            <div class="col-6 pr-0">
                                                <label for="cmd_22">Zip Code:</label>
                                            </div>
                                            <div class="col-6 pl-0">
                                                <input type="text" placeholder=" Zip Code" id="cmd_22" formControlName="cmd_22"
                                                    class="form-control">
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="6">
                                        <div class="row">
                                            <div class="col-6 pr-0">
                                                <label for="cmd_17">Patient Appointment Telephone Number:</label>
                                            </div>
                                            <div class="col-6 pl-0">
                                                <input type="text" placeholder=" Patient Appointment Telephone Number " id="cmd_17"
                                                    formControlName="cmd_17" class="form-control"> <small class="text-danger"
                                                    *ngIf="intermedimage.controls['cmd_17'].errors?.pattern">
                                                    Only number are allowed</small>
                                            </div>
                                        </div>
                                    </td>
                                    <td colspan="6">
                                        <div class="row">
                                            <div class="col-6 pr-0">
                                                <label for="cmd_18">Fax Number:</label>
                                            </div>
                                            <div class="col-6 pl-0">
                                                <input type="text" placeholder="Fax Number" id="cmd_18" formControlName="cmd_18"
                                                    class="form-control">
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="6">
                                        <div class="row">
                                            <div class="col-6 pr-0">
                                                <label for="cmd_35">Name Affiliated with Tax ID Number:</label>
                                            </div>
                                            <div class="col-6 pl-0">
                                                <input type="text" placeholder="Affiliated with Tax ID Number" id="cmd_35"
                                                    formControlName="cmd_35" class="form-control">
                                            </div>
                                        </div>
                                    </td>
                                    <td colspan="6">
                                        <div class="row">
                                            <div class="col-6 pr-0">
                                                <label for="cmd_36">Federal Tax ID Number:</label>
                                            </div>
                                            <div class="col-6 pl-0">
                                                <input type="text" placeholder=" Federal Tax ID Number" id="cmd_36"
                                                    formControlName="cmd_36" class="form-control">
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr class="col-12">
                                    <td colspan="6">
                                        <div class="row">
                                            <div class="col-4 pr-0">
                                                <label for="cmd_24">Mailing Address: (if different from above)</label>
                                            </div>
                                            <div class="col-8 pl-0 d-flex">
                                                <input type="text" placeholder="Mailing Address 1" id="cmd_24"
                                                    formControlName="cmd_24" class="form-control">
                                            </div>
                                            <div class="col-4 pl-0 d-flex"></div>
                                            <div class="col-8 pl-0">
                                                <input type="text" placeholder=" Mailing Address 2" id="cmd_374"
                                                    formControlName="cmd_374" class="form-control">
                                            </div>
                                        </div>
                                    </td>

                                    <td colspan="6">
                                        <div class="row">
                                            <div class="col-6 pr-0">
                                                <label for="cmd_375">City:</label>
                                            </div>
                                            <div class="col-6 pl-0">
                                                <input type="text" placeholder=" City " id="cmd_375" formControlName="cmd_375"
                                                    class="form-control">
                                            </div>
                                            <div class="col-6 pr-0">
                                                <label for="cmd_376">State:</label>
                                            </div>
                                            <div class="col-6 pl-0">
                                                <ng-select [items]="states" 
                                                    bindLabel="name" placeholder=" State" bindValue="id" formControlName="cmd_376">
                                                </ng-select>
                                            </div>
                                            <div class="col-6 pr-0">
                                                <label for="cmd_377">Zip Code:</label>
                                            </div>
                                            <div class="col-6 pl-0">
                                                <input type="text" placeholder=" Zip Code" id="cmd_377" formControlName="cmd_377"
                                                    class="form-control">
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr class="col-12">
                                    <td colspan="6">
                                        <div class="row">
                                            <div class="col-4 pr-0">
                                                <label for="cmd_25">Billing Address:(if different from above)</label>
                                            </div>
                                            <div class="col-8 pl-0 d-flex">
                                                <input type="text" placeholder="Billing Address 1" id="cmd_25"
                                                    formControlName="cmd_25" class="form-control">
                                            </div>
                                            <div class="col-4 pl-0 d-flex"></div>

                                            <div class="col-8 pl-0">
                                                <input type="text" placeholder=" Billing Address 2" id="cmd_378"
                                                    formControlName="cmd_378" class="form-control">
                                            </div>
                                        </div>
                                    </td>

                                    <td colspan="6">
                                        <div class="row">
                                            <div class="col-6 pr-0">
                                                <label for="cmd_379">City:</label>
                                            </div>
                                            <div class="col-6 pl-0">
                                                <input type="text" placeholder=" City " id="cmd_379" formControlName="cmd_379"
                                                    class="form-control">
                                            </div>
                                            <div class="col-6 pr-0">
                                                <label for="cmd_380">State:</label>
                                            </div>
                                            <div class="col-6 pl-0">
                                                <ng-select [items]="states" 
                                                    bindLabel="name" placeholder=" State" bindValue="id" formControlName="cmd_380">
                                                </ng-select>
                                            </div>
                                            <div class="col-6 pr-0">
                                                <label for="cmd_381">Zip Code:</label>
                                            </div>
                                            <div class="col-6 pl-0">
                                                <input type="text" placeholder=" Zip Code" id="cmd_381" formControlName="cmd_381"
                                                    class="form-control">
                                            </div>
                                        </div>
                                    </td>
                                </tr>

                                <tr>
                                    <td colspan="10">
                                        <div class="row">
                                            <div class="col-3 pr-0">
                                                <label for="cmd_27">Office Manager / Administrator Name:</label>
                                            </div>
                                            <div class="col-3 pl-0">
                                                <input type="text" placeholder=" Office Manager" id="cmd_27"
                                                    formControlName="cmd_27" class="form-control">
                                            </div>
                                            <div class="col-3 pl-0">
                                                <input type="text" placeholder=" Office Manager" id="cmd_382"
                                                    formControlName="cmd_382" class="form-control">
                                            </div>
                                            <div class="col-3 pl-0">
                                                <input type="text" placeholder=" Office Manager" id="cmd_383"
                                                    formControlName="cmd_383" class="form-control">
                                            </div>
                                        </div>
                                    </td>
                                    <td colspan="2">
                                        <div class="row">
                                            <div class="col-6 pr-0">
                                                <label for="cmd_28">Administration Telephone Number:</label>
                                            </div>
                                            <div class="col-6 pl-0">
                                                <input type="text" placeholder=" Administration Telephone Number" id="cmd_28"
                                                    formControlName="cmd_28" class="form-control"> <small class="text-danger"
                                                    *ngIf="intermedimage.controls['cmd_28'].errors?.pattern">
                                                    Only number are allowed</small>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="6">
                                        <div class="row">
                                            <div class="col-6 pr-0">
                                                <label for="cmd_30">Fax Number:</label>
                                            </div>
                                            <div class="col-6 pl-0">
                                                <input type="text" placeholder="Fax Number" id="cmd_30" formControlName="cmd_30"
                                                    class="form-control">
                                            </div>
                                        </div>
                                    </td>
                                    <td colspan="6">
                                        <div class="row">
                                            <div class="col-6 pr-0">
                                                <label for="cmd_29">E-mail Address:</label>
                                            </div>
                                            <div class="col-6 pl-0">
                                                <input type="text" placeholder=" E-mail Address" id="cmd_29"
                                                    formControlName="cmd_29" class="form-control"> <small class="text-danger"
                                                    *ngIf="intermedimage.controls['cmd_29'].errors?.pattern">
                                                    Only E-mail format is allowed</small>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="10">
                                        <div class="row">
                                            <div class="col-3 pr-0">
                                                <label for="cmd_31">Credentialing Contact (if different from above):</label>
                                            </div>
                                            <div class="col-3 pl-0">
                                                <input type="text" placeholder=" Credentialing Contact1" id="cmd_31"
                                                    formControlName="cmd_31" class="form-control">
                                            </div>
                                            <div class="col-3 pl-0">
                                                <input type="text" placeholder=" Credentialing Contact2" id="cmd_384"
                                                    formControlName="cmd_384" class="form-control">
                                            </div>
                                            <div class="col-3 pl-0">
                                                <input type="text" placeholder=" Credentialing Contact3" id="cmd_385"
                                                    formControlName="cmd_385" class="form-control">
                                            </div>
                                        </div>
                                    </td>
                                    <td colspan="2">
                                        <div class="row">
                                            <div class="col-6 pr-0">
                                                <label for="cmd_32">Credentialing Telephone Number:</label>
                                            </div>
                                            <div class="col-6 pl-0">
                                                <input type="text" placeholder=" Telephone Number" id="cmd_32"
                                                    formControlName="cmd_32" class="form-control"> <small class="text-danger"
                                                    *ngIf="intermedimage.controls['cmd_32'].errors?.pattern">
                                                    Only number are allowed</small>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="6">
                                        <div class="row">
                                            <div class="col-6 pr-0">
                                                <label for="cmd_34">Fax Number:</label>
                                            </div>
                                            <div class="col-6 pl-0">
                                                <input type="text" placeholder=" Fax Number" id="cmd_34" formControlName="cmd_34"
                                                    class="form-control">
                                            </div>
                                        </div>
                                    </td>
                                    <td colspan="6">
                                        <div class="row">
                                            <div class="col-6 pr-0">
                                                <label for="cmd_33">E-mail Address:</label>
                                            </div>
                                            <div class="col-6 pl-0">
                                                <input type="text" placeholder=" E-mail Address" id="cmd_33"
                                                    formControlName="cmd_33" class="form-control"> <small class="text-danger"
                                                    *ngIf="intermedimage.controls['cmd_33'].errors?.pattern">
                                                    Only E-mail is allowed</small>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="12">
                                        <div class="row">
                                            <div class="col-4">
                                                <label for="cmd_77"><b>Effective Date at SECONDARY Practice location:
                                                        </b></label>
                                            </div>
                                            <div class="col-4 ">
                                                <div class="input-group">
                                                <input type="text" placeholder=" Effective Date" id="cmd_77" (blur)="clearInputIfInvalid('cmd_77')" 
                                                    formControlName="cmd_77" class="form-control datepicker"
                                                    data-id="priornameeffdate_date" ngbDatepicker #idh03="ngbDatepicker">
                                                <div class="input-group-append">
                                                    <button class="btn btn-outline-secondary calendar" (click)="idh03.toggle()"
                                                        type="button">
                                                        <i class="fas fa-calendar-alt"></i> </button>
                                                </div>
                                                </div>
                                                <div *ngIf="intermedimage.get('cmd_77').invalid ">
                                                    <small class="text-danger" *ngIf="intermedimage.get('cmd_77').errors?.invalidDateFormat">
                                                        Only Date Format is allowed(MM/DD/YYYY)
                                                    </small>
                                                    <small class="text-danger" *ngIf="intermedimage.get('cmd_77').errors?.invalidMonth">
                                                        Month is out of range
                                                    </small>
                                                    <small class="text-danger" *ngIf="intermedimage.get('cmd_77').errors?.invalidDateyear">
                                                        year is out of range(1900-2099) </small>
                                                    <small class="text-danger" *ngIf="intermedimage.get('cmd_77').errors?.invalidDateRange">
                                                        date is out of range
                                                    </small>
                                                  </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr class="col-12">
                                    <td colspan="8">
                                        <div class="row">
                                            <div class="col-6 pr-0">
                                                <label for="cmd_92">Name of secondary practice, affiliation or clinic name: </label>
                                            </div>
                                            <div class="col-6 pl-0">
                                                <input type="text" placeholder="Name" id="cmd_92" formControlName="cmd_92"
                                                    class="form-control"
                                                    [class.is-invalid]="(intermedimage.controls['cmd_92'].touched && intermedimage.controls['cmd_92'].invalid ) || (intermedimage.controls['cmd_92'].invalid && isSubmit)">
                                                <div class="invalid-feedback"
                                                    *ngIf="(intermedimage.controls['cmd_92'].touched && intermedimage.controls['cmd_92'].invalid) || (intermedimage.controls['cmd_92'].invalid && isSubmit)">
                                                    NPI Number is required and cannot be empty
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td colspan="8">
                                        <div class="row">
                                            <div class="col-6 pr-0">
                                                <label for="cmd_93">Department name (if hospital based)</label>
                                            </div>
                                            <div class="col-6 pl-0">
                                                <input type="text" placeholder="Department Name" id="cmd_93" formControlName="cmd_93"
                                                    class="form-control"
                                                    [class.is-invalid]="(intermedimage.controls['cmd_93'].touched && intermedimage.controls['cmd_93'].invalid ) || (intermedimage.controls['cmd_93'].invalid && isSubmit)">
                                                <div class="invalid-feedback"
                                                    *ngIf="(intermedimage.controls['cmd_93'].touched && intermedimage.controls['cmd_93'].invalid) || (intermedimage.controls['cmd_93'].invalid && isSubmit)">
                                                    NPI Number is required and cannot be empty
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr class="col-12">
                                    <td colspan="6">
                                        <div class="row">
                                            <div class="col-4 pr-0">
                                                <label for="cmd_96">Secondary Office Street Address:</label>
                                            </div>
                                            <div class="col-8 pl-0 d-flex">
                                                <input type="text" placeholder="Secondary Office Street Address1   " id="cmd_96"
                                                    formControlName="cmd_96" class="form-control">
                                            </div>
                                            <div class="col-4 pl-0 d-flex"></div>
                                            <div class="col-8 pl-0">
                                                <input type="text" placeholder="Secondary Office Street Address2" id="cmd_096"
                                                    formControlName="cmd_096" class="form-control">
                                            </div>
                                        </div>
                                    </td>
                                    <td colspan="6">
                                        <div class="row">
                                            <div class="col-6 pr-0">
                                                <label for="cmd_97">City:</label>
                                            </div>
                                            <div class="col-6 pl-0">
                                                <input type="text" placeholder=" City " id="cmd_97" formControlName="cmd_97"
                                                    class="form-control">
                                            </div>
                                            <div class="col-6 pr-0">
                                                <label for="cmd_98">State:</label>
                                            </div>
                                            <div class="col-6 pl-0">
                                                <ng-select [items]="states" [class.is-invalid]="(intermedimage.controls['cmd_98'].touched &&
                                                                intermedimage.controls['cmd_98'].invalid) ||
                                                                (intermedimage.controls['cmd_98'].invalid && isSubmit)"
                                                    bindLabel="name" placeholder=" State" bindValue="id" formControlName="cmd_98">
                                                </ng-select>
                                            </div>
                                            <div class="col-6 pr-0">
                                                <label for="cmd_99">Zip Code:</label>
                                            </div>
                                            <div class="col-6 pl-0">
                                                <input type="text" placeholder=" Zip Code" id="cmd_99" formControlName="cmd_99"
                                                    class="form-control">
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="6">
                                        <div class="row">
                                            <div class="col-6 pr-0">
                                                <label for="cmd_94">Patient Appointment Telephone Number:</label>
                                            </div>
                                            <div class="col-6 pl-0">
                                                <input type="text" placeholder=" Patient Appointment Telephone Number " id="cmd_94"
                                                    formControlName="cmd_94" class="form-control"> <small class="text-danger"
                                                    *ngIf="intermedimage.controls['cmd_94'].errors?.pattern">
                                                    Only number are allowed</small>
                                            </div>
                                        </div>
                                    </td>
                                    <td colspan="6">
                                        <div class="row">
                                            <div class="col-6 pr-0">
                                                <label for="cmd_95">Fax Number:</label>
                                            </div>
                                            <div class="col-6 pl-0">
                                                <input type="text" placeholder=" Fax Number" id="cmd_95" formControlName="cmd_95"
                                                    class="form-control">
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="6">
                                        <div class="row">
                                            <div class="col-6 pr-0">
                                                <label for="cmd_112">Name Affiliated with Tax ID Number:</label>
                                            </div>
                                            <div class="col-6 pl-0">
                                                <input type="text" placeholder=" Affiliated with Tax ID Number" id="cmd_112"
                                                    formControlName="cmd_112" class="form-control">
                                            </div>
                                        </div>
                                    </td>
                                    <td colspan="6">
                                        <div class="row">
                                            <div class="col-6 pr-0">
                                                <label for="cmd_113">Federal Tax ID Number:</label>
                                            </div>
                                            <div class="col-6 pl-0">
                                                <input type="text" placeholder=" Federal Tax ID Number" id="cmd_113"
                                                    formControlName="cmd_113" class="form-control">
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr class="col-12">
                                    <td colspan="6">
                                        <div class="row">
                                            <div class="col-4 pr-0">
                                                <label for="cmd_101">Mailing Address: (if different from above)</label>
                                            </div>
                                            <div class="col-8 pl-0 d-flex">
                                                <input type="text" placeholder="Mailing Address 1" id="cmd_101"
                                                    formControlName="cmd_101" class="form-control">
                                            </div>
                                            <div class="col-4 pl-0 d-flex"></div>
                                            <div class="col-8 pl-0">
                                                <input type="text" placeholder=" Mailing Address 2" id="cmd_0101"
                                                    formControlName="cmd_0101" class="form-control">
                                            </div>
                                        </div>
                                    </td>

                                    <td colspan="6">
                                        <div class="row">
                                            <div class="col-6 pr-0">
                                                <label for="cmd_00101">City:</label>
                                            </div>
                                            <div class="col-6 pl-0">
                                                <input type="text" placeholder=" City " id="cmd_00101" formControlName="cmd_00101"
                                                    class="form-control">
                                            </div>
                                            <div class="col-6 pr-0">
                                                <label for="cmd_000101">State:</label>
                                            </div>
                                            <div class="col-6 pl-0">
                                                <ng-select [items]="states" 
                                                    bindLabel="name" placeholder=" State" bindValue="id" formControlName="cmd_000101">
                                                </ng-select>
                                            </div>
                                            <div class="col-6 pr-0">
                                                <label for="cmd_0000101">Zip Code:</label>
                                            </div>
                                            <div class="col-6 pl-0">
                                                <input type="text" placeholder=" Zip Code" id="cmd_0000101" formControlName="cmd_0000101"
                                                    class="form-control">
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr class="col-12">
                                    <td colspan="6">
                                        <div class="row">
                                            <div class="col-4 pr-0">
                                                <label for="cmd_102">Billing Address:(if different from above)</label>
                                            </div>
                                            <div class="col-8 pl-0 d-flex">
                                                <input type="text" placeholder=" Billing Address 1" id="cmd_102"
                                                    formControlName="cmd_102" class="form-control">
                                            </div>
                                            <div class="col-4 pl-0 d-flex"></div>

                                            <div class="col-8 pl-0">
                                                <input type="text" placeholder=" Billing Address 2" id="cmd_0102"
                                                    formControlName="cmd_0102" class="form-control">
                                            </div>
                                        </div>
                                    </td>

                                    <td colspan="6">
                                        <div class="row">
                                            <div class="col-6 pr-0">
                                                <label for="cmd_00102">City:</label>
                                            </div>
                                            <div class="col-6 pl-0">
                                                <input type="text" placeholder=" City " id="cmd_00102" formControlName="cmd_00102"
                                                    class="form-control">
                                            </div>
                                            <div class="col-6 pr-0">
                                                <label for="cmd_000102">State:</label>
                                            </div>
                                            <div class="col-6 pl-0">
                                                <ng-select [items]="states" 
                                                    bindLabel="name" placeholder=" State" bindValue="id" formControlName="cmd_000102">
                                                </ng-select>
                                            </div>
                                            <div class="col-6 pr-0">
                                                <label for="cmd_0000102">Zip Code:</label>
                                            </div>
                                            <div class="col-6 pl-0">
                                                <input type="text" placeholder=" Zip Code" id="cmd_0000102" formControlName="cmd_0000102"
                                                    class="form-control">
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                
                                <tr>
                                    <td colspan="10">
                                        <div class="row">
                                            <div class="col-3 pr-0">
                                                <label for="cmd_104">Office Manager / Administrator Name:</label>
                                            </div>
                                            <div class="col-4 pl-0">
                                                <input type="text" placeholder=" Office Manager" id="cmd_104"
                                                    formControlName="cmd_104" class="form-control">
                                            </div>
                                            <div class="col-4 pl-0">
                                                <input type="text" placeholder=" Office Manager" id="cmd_0104"
                                                    formControlName="cmd_0104" class="form-control">
                                            </div>
                                            <!-- <div class="col-3 pl-0">
                                                <input type="text" placeholder=" Office Manager" id="cmd_00104"
                                                    formControlName="cmd_00104" class="form-control">
                                            </div> -->
                                        </div>
                                    </td>
                                    <td colspan="2">
                                        <div class="row">
                                            <div class="col-6 pr-0">
                                                <label for="cmd_105">Administration Telephone Number:</label>
                                            </div>
                                            <div class="col-6 pl-0">
                                                <input type="text" placeholder=" Administration Telephone Number" id="cmd_105"
                                                    formControlName="cmd_105" class="form-control"> <small class="text-danger"
                                                    *ngIf="intermedimage.controls['cmd_105'].errors?.pattern">
                                                    Only number are allowed</small>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="6">
                                        <div class="row">
                                            <div class="col-6 pr-0">
                                                <label for="cmd_107">Fax Number:</label>
                                            </div>
                                            <div class="col-6 pl-0">
                                                <input type="text" placeholder=" Fax Number" id="cmd_107" formControlName="cmd_107"
                                                    class="form-control">
                                            </div>
                                        </div>
                                    </td>
                                    <td colspan="6">
                                        <div class="row">
                                            <div class="col-6 pr-0">
                                                <label for="cmd_106">E-mail Address:</label>
                                            </div>
                                            <div class="col-6 pl-0">
                                                <input type="text" placeholder=" E-mail Address" id="cmd_106"
                                                    formControlName="cmd_106" class="form-control"> <small class="text-danger"
                                                    *ngIf="intermedimage.controls['cmd_106'].errors?.pattern">
                                                    Only E-mail format is allowed</small>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="10">
                                        <div class="row">
                                            <div class="col-3 pr-0">
                                                <label for="cmd_31">Credentialing Contact (if different from above):</label>
                                            </div>
                                            <div class="col-4 pl-0">
                                                <input type="text" placeholder=" Credentialing Contact1" id="cmd_108"
                                                    formControlName="cmd_108" class="form-control">
                                            </div>
                                            <div class="col-4 pl-0">
                                                <input type="text" placeholder=" Credentialing Contact2" id="cmd_0108"
                                                    formControlName="cmd_0108" class="form-control">
                                            </div>
                                            <!-- <div class="col-3 pl-0">
                                                <input type="text" placeholder=" Credentialing Contact3" id="cmd_00108"
                                                    formControlName="cmd_00108" class="form-control">
                                            </div> -->
                                        </div>
                                    </td>
                                    <td colspan="2">
                                        <div class="row">
                                            <div class="col-6 pr-0">
                                                <label for="cmd_32">Credentialing Telephone Number:</label>
                                            </div>
                                            <div class="col-6 pl-0">
                                                <input type="text" placeholder=" Telephone Number" id="cmd_109"
                                                    formControlName="cmd_109" class="form-control"> <small class="text-danger"
                                                    *ngIf="intermedimage.controls['cmd_109'].errors?.pattern">
                                                    Only number are allowed</small>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="6">
                                        <div class="row">
                                            <div class="col-6 pr-0">
                                                <label for="cmd_111">Fax Number:</label>
                                            </div>
                                            <div class="col-6 pl-0">
                                                <input type="text" placeholder=" Fax Number" id="cmd_111" formControlName="cmd_111"
                                                    class="form-control">
                                            </div>
                                        </div>
                                    </td>
                                    <td colspan="6">
                                        <div class="row">
                                            <div class="col-6 pr-0">
                                                <label for="cmd_110">E-mail Address:</label>
                                            </div>
                                            <div class="col-6 pl-0">
                                                <input type="text" placeholder=" E-mail Address" id="cmd_110"
                                                    formControlName="cmd_110" class="form-control"> <small class="text-danger"
                                                    *ngIf="intermedimage.controls['cmd_110'].errors?.pattern">
                                                    Only E-mail is allowed</small>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr class="col-12">
                                    <td colspan="12">
                                        <div class="col-12 pr-0 ">
                                            <h5 style="text-align:center">List other office locations with above information on a
                                                separate
                                                sheet.
                                            </h5>
                                        </div>
                                    </td>
                                </tr>
                           </div>
                         </td>
                   </tr>
                    <tr class="col-12">
                        <td colspan="12">
                            <div class="table table-bordered" style="table-layout: fixed;">
                        <td colspan="12">
                            <label>
                                <h6>IV. PROFESSIONAL LICENSURE </h6>
                            </label>
                        </td>
                    <tr class="col-12">
                        <td class="col-12">
                            <div class="row">
                                <div class="col-5">
                                    <label for="293">Idaho State professional license/registration/certificate number:<span
                                            class="mandatory">*</span></label>
                                </div>
                                <div class="col-3 d-flex">
                                    <input type="text" placeholder=" License Number" id="293" formControlName="293"
                                        class="form-control"
                                        [class.is-invalid]="(intermedimage.controls['293'].touched && intermedimage.controls['293'].invalid ) || (intermedimage.controls['293'].invalid && isSubmit)">
                                    <div class="invalid-feedback"
                                        *ngIf="(intermedimage.controls['293'].touched && intermedimage.controls['293'].invalid) || (intermedimage.controls['293'].invalid && isSubmit)">
                                        Required
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="form-inline mb-1">
                                        <label for="cmd_01" class="ml-3"><b>Status:</b></label>&nbsp;&nbsp;
                                        <input type="Checkbox" value="Active " formControlName="cmd_003" id="cmd_003">&nbsp;&nbsp;
                                        <label for="cmd_02">Active</label>&nbsp;&nbsp;&nbsp;&nbsp;
                                        <input type="Checkbox" value="Inactive " formControlName="cmd_04" id="cmd_04">&nbsp;&nbsp;
                                        <label for="cmd_02">Inactive</label>&nbsp;&nbsp;&nbsp;&nbsp;
                                        <input type="Checkbox" value="Temporary" formControlName="cmd_05" id="cmd_05">&nbsp;&nbsp;
                                        <label for="cmd_02">Temporary</label>&nbsp;&nbsp;&nbsp;&nbsp;
                                    </div>
                                </div>
                                <div class="col-5">
                                    <label for="294">Issue Date:<span class="mandatory">*</span></label>
                                </div>
                                <div class="col-4 ">
                                    <div class="input-group">
                                    <input type="text" placeholder="License Issue Date " id="294" formControlName="294" (blur)="clearInputIfInvalidMandi('294')" 
                                        class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker #idh04="ngbDatepicker"
                                        [class.is-invalid]="(intermedimage.controls['294'].touched && intermedimage.controls['294'].invalid ) || (intermedimage.controls['294'].invalid && isSubmit)">
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="idh04.toggle()" type="button">
                                            <i class="fas fa-calendar-alt"></i> </button>
                                    </div>
                                    </div>
                                    <div class="text-danger"
                                        *ngIf="(intermedimage.controls['294'].invalid&&intermedimage.controls['294'].touched) && (intermedimage.controls['294'].value==''|| intermedimage.controls['294'].value==null) ">
                                        <small>
                                        Required
                                        </small>
                                    
                                    </div>
                                    <small class="text-danger" *ngIf="intermedimage.get('294').errors?.invalidDateFormat">
                                        Only Date Format is allowed(MM/DD/YYYY) </small>
                                    <small class="text-danger" *ngIf="intermedimage.get('294').errors?.invalidMonth">
                                        Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="intermedimage.get('294').errors?.invalidDateyear">
                                        year is out of range(1900-2099) </small>
                                    <small class="text-danger" *ngIf="intermedimage.get('294').errors?.invalidDateRange">
                                        date is out of range
                                    </small>
                                </div>
                                
                                <div class="col-5">
                                    <label for="295">Expiration Date:<span class="mandatory">*</span></label>
                                </div>
                                <div class="col-4  ">
                                    <div class="input-group">
                                    <input type="text" placeholder="License Expiration Date" id="295" formControlName="295" (blur)="clearInputIfInvalidMandi('295')" 
                                        class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker #idh05="ngbDatepicker"
                                        [class.is-invalid]="(intermedimage.controls['295'].touched && intermedimage.controls['295'].invalid ) || (intermedimage.controls['295'].invalid && isSubmit)">
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="idh05.toggle()" type="button">
                                            <i class="fas fa-calendar-alt"></i> </button>
                                    </div>
                                    </div>
                                    <div class="text-danger"
                                        *ngIf="(intermedimage.controls['295'].invalid&&intermedimage.controls['295'].touched) && (intermedimage.controls['295'].value==''|| intermedimage.controls['295'].value==null) ">
                                        <small>
                                        Required
                                        </small>
                                    
                                    </div>
                                    <small class="text-danger" *ngIf="intermedimage.get('295').errors?.invalidDateFormat">
                                        Only Date Format is allowed(MM/DD/YYYY) </small>
                                    <small class="text-danger" *ngIf="intermedimage.get('295').errors?.invalidMonth">
                                        Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="intermedimage.get('295').errors?.invalidDateyear">
                                        year is out of range(1900-2099) </small>
                                    <small class="text-danger" *ngIf="intermedimage.get('295').errors?.invalidDateRange">
                                        date is out of range
                                    </small>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <!-- <tr class="col-12">
                        <td colspan="10">
                            <div class="row">
                                <div class="form-inline mb-1">
                                    <label for="cmd_01" class="ml-3"><b>Status:</b></label>&nbsp;&nbsp;
                                    <input type="Checkbox" value="Active " formControlName="cmd_003" id="cmd_003">&nbsp;&nbsp;
                                    <label for="cmd_02">Active</label>&nbsp;&nbsp;&nbsp;&nbsp;
                                    <input type="Checkbox" value="Inactive " formControlName="cmd_04" id="cmd_04">&nbsp;&nbsp;
                                    <label for="cmd_02">Inactive</label>&nbsp;&nbsp;&nbsp;&nbsp;
                                    <input type="Checkbox" value="Temporary" formControlName="cmd_05" id="cmd_05">&nbsp;&nbsp;
                                    <label for="cmd_02">Temporary</label>&nbsp;&nbsp;&nbsp;&nbsp;
                                </div>
                            </div>
                        </td>
                    </tr> -->
                    <tr class="col-12">
                        <td>
                            <div class="row">
                                <div class="col-4">
                                    <label for="1745"><b>Name of Sponsor if required by licensure, (i.e. Physician’s
                                            Assistant):</b></label>
                                </div>
                                <div class="col-2">
                                    <input type="Text" placeholder="  Physician First Name " id="1745" formControlName="1745"
                                        class="form-control">
                                </div>
                                <div class="col-2">
                                    <input type="Text" placeholder=" Physician Last Name " id="1746" formControlName="1746"
                                        class="form-control">
                                </div>
                                <div class="col-2">
                                    <ng-select [items]="suffix" bindLabel="name" placeholder="Suffix" bindValue="id" formControlName="1748">
                                    </ng-select>
                                </div>
                                <div class="col-2">
                                    <ng-select [labelForId]="'1749'" [items]="credentials" bindLabel="name" placeholder="Select Degree"
                                        bindValue="id" formControlName="1749">
                                    </ng-select>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr class="col-12">
                        <td class="col-12">
                            <div class="row">
                                <div class="col-5">
                                    <label for="309">Drug Enforcement Administration (DEA) Registration Number:<span *ngIf="intermedimage.controls['309'].invalid ||intermedimage.controls['cmd_1600'].value==null||intermedimage.controls['cmd_1600'].value=='' " class="mandatory">*</span></label>
                                </div>
                                <div class="col-4">
                                    <input type="text" (blur)="handleNAchange('cmd_1600',309)" placeholder=" DEA Number" id="309" formControlName="309" class="form-control"
                                        [class.is-invalid]="intermedimage.controls['309'].invalid ">                      
                                     <div class="invalid-feedback"
                                         *ngIf="(intermedimage.controls['309'].touched && intermedimage.controls['309'].invalid) || (intermedimage.controls['309'].invalid && isSubmit)">
                                          Required
                                     </div>
                                </div>
                                <div class="col-3">
                                    <label class="ml-4" for="cmd_1600"><b>Does Not Apply:</b></label>&nbsp;&nbsp;
                                    <input type="checkbox" value="Yes" formControlName="cmd_1600"
                                        (ngModelChange)="handleNAchange('cmd_1600')" id="cmd_1600">
                                </div>
                                <div class="col-5">
                                    <label for="311">Issue Date:<span *ngIf="intermedimage.controls['310'].invalid ||intermedimage.controls['cmd_1600'].value==null||intermedimage.controls['cmd_1600'].value=='' " class="mandatory">*</span></label>
                                </div>
                                <div class="col-4 ">
                                    <div class="input-group">
                                    <input type="text" (blur)="handleNAchange('cmd_1600',310)" (blur)="clearInputIfInvalid('310','cmd_1600')" placeholder="DEA Issue Date " id="310" formControlName="310" (blur)="clearInputIfInvalid('310','cmd_1600')"
                                        class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker #idh06="ngbDatepicker"
                                        [class.is-invalid]="intermedimage.controls['310'].invalid">
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="idh06.toggle()" type="button">
                                            <i class="fas fa-calendar-alt"></i> </button>
                                    </div>
                                    </div>
                                    <div class="text-danger"
                                        *ngIf="intermedimage.controls['310'].invalid && (intermedimage.controls['310'].value==''|| intermedimage.controls['310'].value==null) ">
                                        <small>
                                            Required
                                        </small>
                                    
                                    </div>
                                    
                                    <div *ngIf="intermedimage.get('310').invalid">
                                        <small class="text-danger" *ngIf="intermedimage.get('310').errors.invalidDateFormat">
                                            Only Date Format is allowed(MM/DD/YYYY) </small>
                                        <small class="text-danger" *ngIf="intermedimage.get('310').errors.invalidMonth">
                                            Month is out of range
                                        </small>
                                        <small class="text-danger" *ngIf="intermedimage.get('310').errors.invalidDateyear">
                                            year is out of range(1900-2099) </small>
                                        <small class="text-danger" *ngIf="intermedimage.get('310').errors.invalidDateRange">
                                            date is out of range
                                        </small>
                                    </div>
                                </div>
                                <div class="col-5">
                                    <label for="311">Expiration Date:<span *ngIf="intermedimage.controls['311'].invalid ||intermedimage.controls['cmd_1600'].value==null||intermedimage.controls['cmd_1600'].value=='' " class="mandatory">*</span></label>
                                </div>
                                <div class="col-4 ">
                                    <div class="input-group">
                                    <input type="text"  (blur)="handleNAchange('cmd_1600',311)" (blur)="clearInputIfInvalid('311','cmd_1600')" placeholder="DEA Expiration Date " id="311" formControlName="311"
                                        class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker #idh07="ngbDatepicker"
                                        [class.is-invalid]="(intermedimage.controls['311'].invalid )">
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="idh07.toggle()" type="button">
                                            <i class="fas fa-calendar-alt"></i> </button>
                                    </div>
                                    </div>
                                    <div class="text-danger"
                                    *ngIf="intermedimage.controls['311'].invalid && (intermedimage.controls['311'].value==''|| intermedimage.controls['311'].value==null) ">
                                    <small>
                                        Required
                                    </small>
                                
                                </div>
                                
                                <div *ngIf="intermedimage.get('311').invalid">
                                    <small class="text-danger" *ngIf="intermedimage.get('311').errors.invalidDateFormat">
                                        Only Date Format is allowed(MM/DD/YYYY) </small>
                                    <small class="text-danger" *ngIf="intermedimage.get('311').errors.invalidMonth">
                                        Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="intermedimage.get('311').errors.invalidDateyear">
                                        year is out of range(1900-2099) </small>
                                    <small class="text-danger" *ngIf="intermedimage.get('311').errors.invalidDateRange">
                                        date is out of range
                                    </small>
                                </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr class="col-12">
                        <td class="col-12">
                            <div class="row">
                                <div class="col-5">
                                    <label for="337">State controlled substance certificate number:</label>
                                </div>
                                <div class="col-4">
                                    <input type="text" placeholder="CDS Number" id="337" formControlName="337" class="form-control"
                                        [class.is-invalid]="(intermedimage.controls['337'].touched && intermedimage.controls['337'].invalid ) || (intermedimage.controls['337'].invalid && isSubmit)">
                                </div>
                                <div class="col-5">
                                    <label for="338">Issue Date:</label>
                                </div>
                                <div class="col-4 d-flex">
                                    <input type="text" placeholder="CDS Issue Date" id="338" formControlName="338" (blur)="clearInputIfInvalid('338')" 
                                        class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker #idh08="ngbDatepicker"
                                        [class.is-invalid]="(intermedimage.controls['338'].touched && intermedimage.controls['338'].invalid ) || (intermedimage.controls['338'].invalid && isSubmit)">
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="idh08.toggle()" type="button">
                                            <i class="fas fa-calendar-alt"></i> </button>
                                    </div>
                                </div>
                                <div class="col-5"></div>
                                <div class="col-4">
                                    <div *ngIf="intermedimage.get('338').invalid ">
                                        <small class="text-danger" *ngIf="intermedimage.get('338').errors?.invalidDateFormat">
                                            Only Date Format is allowed(MM/DD/YYYY)
                                        </small>
                                        <small class="text-danger" *ngIf="intermedimage.get('338').errors?.invalidMonth">
                                            Month is out of range
                                        </small>
                                        <small class="text-danger" *ngIf="intermedimage.get('338').errors?.invalidDateyear">
                                            year is out of range(1900-2099) </small>
                                        <small class="text-danger" *ngIf="intermedimage.get('338').errors?.invalidDateRange">
                                            date is out of range
                                        </small>
                                      </div>
                                </div>
                                <div class="col-5">
                                    <label for="339">Expiration Date:</label>
                                </div>
                                <div class="col-4 ">
                                    <div class="input-group">
                                    <input type="text" placeholder="CDS Expiration Date " id="339"  (blur)="clearInputIfInvalid('339')"  formControlName="339" class="form-control datepicker"
                                        data-id="priornameeffdate_date" ngbDatepicker #idh09="ngbDatepicker"
                                        [class.is-invalid]="(intermedimage.controls['339'].touched && intermedimage.controls['339'].invalid ) || (intermedimage.controls['339'].invalid && isSubmit)">
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="idh09.toggle()" type="button">
                                            <i class="fas fa-calendar-alt"></i> </button>
                                    </div>
                                    </div>
                                </div>
                                <div class="col-5"></div>
                                <div class="col-4">
                                    <div *ngIf="intermedimage.get('339').invalid ">
                                        <small class="text-danger" *ngIf="intermedimage.get('339').errors?.invalidDateFormat">
                                            Only Date Format is allowed(MM/DD/YYYY)
                                        </small>
                                        <small class="text-danger" *ngIf="intermedimage.get('339').errors?.invalidMonth">
                                            Month is out of range
                                        </small>
                                        <small class="text-danger" *ngIf="intermedimage.get('339').errors?.invalidDateyear">
                                            year is out of range(1900-2099) </small>
                                        <small class="text-danger" *ngIf="intermedimage.get('339').errors?.invalidDateRange">
                                            date is out of range
                                        </small>
                                      </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr class="col-12">
                        <td class="col-6">
                            <div class="row">
                                <div class="col-5">
                                    <label for="514">ECFMG Number (applicable to foreign medical graduates):</label>
                                </div>
                                <div class="col-4">
                                    <input type="Text" placeholder=" ECFMG Number" id="514" formControlName="514" class="form-control">
                                </div>
                                <div class="col-5">
                                    <label for="515">Date Issued:</label>
                                </div>
                                <div class="col-4 ">
                                    <div class="input-group">
                                    <input type="text" placeholder="ECFMG Issue Date " id="515" formControlName="515" (blur)="clearInputIfInvalid('515')" 
                                        class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker
                                        #idh10="ngbDatepicker">
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="idh10.toggle()" type="button">
                                            <i class="fas fa-calendar-alt"></i> </button>
                                    </div>
                                    </div>
                                </div>
                                <div class="col-5"></div>
                                <div class="col-4">
                                    <div *ngIf="intermedimage.get('515').invalid ">
                                        <small class="text-danger" *ngIf="intermedimage.get('515').errors?.invalidDateFormat">
                                            Only Date Format is allowed(MM/DD/YYYY)
                                        </small>
                                        <small class="text-danger" *ngIf="intermedimage.get('515').errors?.invalidMonth">
                                            Month is out of range
                                        </small>
                                        <small class="text-danger" *ngIf="intermedimage.get('515').errors?.invalidDateyear">
                                            year is out of range(1900-2099) </small>
                                        <small class="text-danger" *ngIf="intermedimage.get('515').errors?.invalidDateRange">
                                            date is out of range
                                        </small>
                                      </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                    </div>
                    </td>
                    </tr>
                    <tr class="col-12">
                        <td colspan="12">
                            <div class="table table-bordered" style="table-layout: fixed;">
                        <td colspan="12">
                            <label>
                                <h6>V. ALL OTHER PROFESSIONAL LICENSES </h6>
                            </label>
                        </td>
                    <tr>
                        <td colspan="4">
                            <div class="row">
                                <div class="col-6 pr-0">
                                    <label for="907">State:</label>
                                </div>
                                <div class="col-6 pl-0">
                                    <ng-select [items]="altLicenseState" bindLabel="name" placeholder="Certification State" bindValue="id"
                                        formControlName="907"> </ng-select>
                                </div>
                                <div class="col-6 pr-0">
                                    <label for="300">License/registration/certificate number:</label>
                                </div>
                                <div class="col-6 pl-0">
                                    <input type="text" placeholder="License Number  " id="300" formControlName="300"
                                        class="form-control">
                                </div>
                            </div>
                        </td>
                        <td colspan="4">
                            <div class="row">
                                <div class="col-4 ">
                                    <label for="307">Date Issued:</label>
                                </div>
                                <div class="col-8" style="display: inline-block;">
                                    <div class="input-group">
                                    <input type="text" placeholder="License  Issue Date  " id="307" formControlName="307" (blur)="clearInputIfInvalid('307')" 
                                        class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker
                                        #idh11="ngbDatepicker">
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="idh11.toggle()" type="button">
                                            <i class="fas fa-calendar-alt"></i> </button>
                                    </div>
                                    </div>
                                </div>
                                <div class="col-4"></div>
                                <div class="col-8">
                                    <div *ngIf="intermedimage.get('307').invalid ">
                                        <small class="text-danger" *ngIf="intermedimage.get('307').errors?.invalidDateFormat">
                                            Only Date Format is allowed(MM/DD/YYYY)
                                        </small>
                                        <small class="text-danger" *ngIf="intermedimage.get('307').errors?.invalidMonth">
                                            Month is out of range
                                        </small>
                                        <small class="text-danger" *ngIf="intermedimage.get('307').errors?.invalidDateyear">
                                            year is out of range(1900-2099) </small>
                                        <small class="text-danger" *ngIf="intermedimage.get('307').errors?.invalidDateRange">
                                            date is out of range
                                        </small>
                                      </div>
                                </div>
                                <div class="col-4 pr-0">
                                    <label for="308">Expiration Date:</label>
                                </div>
                                <div class="col-8">
                                    <div class="input-group">
                                    <input type="text" placeholder="Alt License Expiration Date  " id="308" formControlName="308" (blur)="clearInputIfInvalid('308')" 
                                        class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker
                                        #idh12="ngbDatepicker">
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="idh12.toggle()" type="button">
                                            <i class="fas fa-calendar-alt"></i> </button>
                                    </div>
                                    </div>
                                </div>
                                <div class="col-4"></div>
                                <div class="col-8">
                                    <div *ngIf="intermedimage.get('308').invalid ">
                                        <small class="text-danger" *ngIf="intermedimage.get('308').errors?.invalidDateFormat">
                                            Only Date Format is allowed(MM/DD/YYYY)
                                        </small>
                                        <small class="text-danger" *ngIf="intermedimage.get('308').errors?.invalidMonth">
                                            Month is out of range
                                        </small>
                                        <small class="text-danger" *ngIf="intermedimage.get('308').errors?.invalidDateyear">
                                            year is out of range(1900-2099) </small>
                                        <small class="text-danger" *ngIf="intermedimage.get('308').errors?.invalidDateRange">
                                            date is out of range
                                        </small>
                                      </div>
                                </div>
                            </div>
                        </td>
                        <td colspan="4">
                            <div class="row">
                                <div class="col-4 pr-0">
                                    <label for="cmd_154">Year.Relinquish:</label>
                                </div>
                                <div class="col-8 pl-0">
                                    <input type="text" placeholder="Year Relinquish" id="cmd_154" formControlName="cmd_154"
                                        class="form-control">
                                </div>
                                <div class="col-4 pr-0">
                                    <label for="cmd_155">Reason:</label>
                                </div>
                                <div class="col-8 pl-0">
                                    <input type="text" placeholder="Reason" id="cmd_155" formControlName="cmd_155"
                                        class="form-control">
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="4">
                            <div class="row">
                                <div class="col-6 pr-0">
                                    <label for="1010">State:</label>
                                </div>
                                <div class="col-6 pl-0">
                                    <ng-select [items]="additionalLicenseState" bindLabel="name" placeholder="Additional License State" bindValue="id"
                                        formControlName="1010"> </ng-select>
                                </div>
                                <div class="col-6 pr-0">
                                    <label for="999">License/registration/certificate number:</label>
                                </div>
                                <div class="col-6 pl-0">
                                    <input type="text" placeholder="Additional License Number " id="999" formControlName="999"
                                        class="form-control">
                                </div>
                            </div>
                        </td>
                        <td colspan="4">
                            <div class="row">
                                <div class="col-4 pr-0">
                                    <label for="1003">Date Issued:</label>
                                </div>
                                <div class="col-8 pl-0 ">
                                    <div class="input-group">
                                    <input type="text" placeholder="Additional License Issue Date " id="1003" formControlName="1003" (blur)="clearInputIfInvalid('1003')" 
                                        class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker
                                        #idh13="ngbDatepicker">
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="idh13.toggle()" type="button">
                                            <i class="fas fa-calendar-alt"></i> </button>
                                    </div>
                                    </div>
                                </div>
                                <div class="col-4"></div>
                                <div class="col-8">
                                    <div *ngIf="intermedimage.get('1003').invalid ">
                                        <small class="text-danger" *ngIf="intermedimage.get('1003').errors?.invalidDateFormat">
                                            Only Date Format is allowed(MM/DD/YYYY)
                                        </small>
                                        <small class="text-danger" *ngIf="intermedimage.get('1003').errors?.invalidMonth">
                                            Month is out of range
                                        </small>
                                        <small class="text-danger" *ngIf="intermedimage.get('1003').errors?.invalidDateyear">
                                            year is out of range(1900-2099) </small>
                                        <small class="text-danger" *ngIf="intermedimage.get('1003').errors?.invalidDateRange">
                                            date is out of range
                                        </small>
                                      </div>
                                </div>
                                <div class="col-4 pr-0">
                                    <label for="1004">Expiration Date:</label>
                                </div>
                                <div class="col-8 pl-0 ">
                                    <div class="input-group">
                                    <input type="text" placeholder="Additional License Expiration Date " id="1004" formControlName="1004"  (blur)="clearInputIfInvalid('1004')" 
                                        class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker
                                        #idh14="ngbDatepicker">
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="idh14.toggle()" type="button">
                                            <i class="fas fa-calendar-alt"></i> </button>
                                    </div>
                                    </div>
                                </div>
                                <div class="col-4"></div>
                                <div class="col-8">
                                    <div *ngIf="intermedimage.get('1004').invalid ">
                                        <small class="text-danger" *ngIf="intermedimage.get('1004').errors?.invalidDateFormat">
                                            Only Date Format is allowed(MM/DD/YYYY)
                                        </small>
                                        <small class="text-danger" *ngIf="intermedimage.get('1004').errors?.invalidMonth">
                                            Month is out of range
                                        </small>
                                        <small class="text-danger" *ngIf="intermedimage.get('1004').errors?.invalidDateyear">
                                            year is out of range(1900-2099) </small>
                                        <small class="text-danger" *ngIf="intermedimage.get('1004').errors?.invalidDateRange">
                                            date is out of range
                                        </small>
                                      </div>
                                </div>
                            </div>
                        </td>
                        <td colspan="4">
                            <div class="row">
                                <div class="col-4 pr-0">
                                    <label for="cmd_156">Year.Relinquish:</label>
                                </div>
                                <div class="col-8 pl-0">
                                    <input type="text" placeholder="Year Relinquish" id="cmd_156" formControlName="cmd_156"
                                        class="form-control">
                                </div>
                                <div class="col-4 pr-0">
                                    <label for="cmd_157">Reason:</label>
                                </div>
                                <div class="col-8 pl-0">
                                    <input type="text" placeholder="Reason" id="cmd_157" formControlName="cmd_157"
                                        class="form-control">
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="4">
                            <div class="row">
                                <div class="col-6 pr-0">
                                    <label for="add_1_1010">State:</label>
                                </div>
                                <div class="col-6 pl-0">
                                    <ng-select [items]="additionalLicenseState" bindLabel="name" placeholder="Additional License State" bindValue="id"
                                        formControlName="add_1_1010"> </ng-select>
                                </div>
                                <div class="col-6 pr-0">
                                    <label for="add_1_999">License/registration/certificate number:</label>
                                </div>
                                <div class="col-6 pl-0">
                                    <input type="text" placeholder="Additional License Number " id="add_1_999" formControlName="add_1_999"
                                        class="form-control">
                                </div>
                            </div>
                        </td>
                        <td colspan="4">
                            <div class="row">
                                <div class="col-4 pr-0">
                                    <label for="add_1_1003">Date Issued:</label>
                                </div>
                                <div class="col-8 pl-0 ">
                                    <div class="input-group">
                                    <input type="text" placeholder="Additional License Issue Date " id="add_1_1003" formControlName="add_1_1003" (blur)="clearInputIfInvalid('add_1_1003')" 
                                        class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker
                                        #idh15="ngbDatepicker">
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="idh15.toggle()" type="button">
                                            <i class="fas fa-calendar-alt"></i> </button>
                                    </div>
                                    </div>
                                </div>
                                <div class="col-4"></div>
                                <div class="col-8">
                                    <div *ngIf="intermedimage.get('add_1_1003').invalid ">
                                        <small class="text-danger" *ngIf="intermedimage.get('add_1_1003').errors?.invalidDateFormat">
                                            Only Date Format is allowed(MM/DD/YYYY)
                                        </small>
                                        <small class="text-danger" *ngIf="intermedimage.get('add_1_1003').errors?.invalidMonth">
                                            Month is out of range
                                        </small>
                                        <small class="text-danger" *ngIf="intermedimage.get('add_1_1003').errors?.invalidDateyear">
                                            year is out of range(1900-2099) </small>
                                        <small class="text-danger" *ngIf="intermedimage.get('add_1_1003').errors?.invalidDateRange">
                                            date is out of range
                                        </small>
                                      </div>
                                </div>
                                <div class="col-4 pr-0">
                                    <label for="add_1_1004">Expiration Date:</label>
                                </div>
                                <div class="col-8 pl-0 ">
                                    <div class="input-group">
                                    <input type="text" placeholder="Additional License Expiration Date " id="add_1_1004" formControlName="add_1_1004" (blur)="clearInputIfInvalid('add_1_1004')" 
                                        class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker
                                        #idh16="ngbDatepicker">
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="idh16.toggle()" type="button">
                                            <i class="fas fa-calendar-alt"></i> </button>
                                    </div>
                                    </div>
                                </div>
                                <div class="col-4"></div>
                                <div class="col-8">
                                    <div *ngIf="intermedimage.get('add_1_1004').invalid ">
                                        <small class="text-danger" *ngIf="intermedimage.get('add_1_1004').errors?.invalidDateFormat">
                                            Only Date Format is allowed(MM/DD/YYYY)
                                        </small>
                                        <small class="text-danger" *ngIf="intermedimage.get('add_1_1004').errors?.invalidMonth">
                                            Month is out of range
                                        </small>
                                        <small class="text-danger" *ngIf="intermedimage.get('add_1_1004').errors?.invalidDateyear">
                                            year is out of range(1900-2099) </small>
                                        <small class="text-danger" *ngIf="intermedimage.get('add_1_1004').errors?.invalidDateRange">
                                            date is out of range
                                        </small>
                                      </div>
                                </div>
                            </div>
                        </td>
                        <td colspan="4">
                            <div class="row">
                                <div class="col-4 pr-0">
                                    <label for="cmd_0155">Year.Relinquish:</label>
                                </div>
                                <div class="col-8 pl-0">
                                    <input type="text" placeholder="Year Relinquish" id="cmd_0155" formControlName="cmd_0155"
                                        class="form-control">
                                </div>
                                <div class="col-4 pr-0">
                                    <label for="cmd_0156">Reason:</label>
                                </div>
                                <div class="col-8 pl-0">
                                    <input type="text" placeholder=" Reason" id="cmd_0156" formControlName="cmd_0156"
                                        class="form-control">
                                </div>
                            </div>
                        </td>
                    </tr>
                    </div>
                    </td>
                    </tr>
                    <tr class="col-12">
                        <td colspan="12">
                            <div class="table table-bordered" style="table-layout: fixed;">
                        <td colspan="12">
                            <label>
                                <h6>VI. UNDER-GRADUATE EDUCATION </h6>
                            </label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="doesnotapply">
                                <label for="cmd_160"><b>Does Not Apply:</b></label>&nbsp;&nbsp;
                                <input type="checkbox" value="Yes" (ngModelChange)="handleNAchange('cmd_160')" formControlName="cmd_160"
                                    id="cmd_160">
                            </span>
                        </td>
                    <tr class="col-12">
                        <td colspan="12">
                            <div class="row">
                                <div class="col-3">
                                    <label for="503">Name of college or university:<span *ngIf="intermedimage.controls['503'].invalid ||intermedimage.controls['cmd_160'].value==null||intermedimage.controls['cmd_160'].value=='' " class="mandatory">*</span></label>
                                </div>
                                <div class="col-7">
                                    <input type="text" (blur)="handleNAchange('cmd_160')" placeholder=" UG  School" id="503" formControlName="503" class="form-control"
                                        [class.is-invalid]="(intermedimage.controls['503'].invalid)">
                                    <div class="invalid-feedback"
                                        *ngIf="(intermedimage.controls['503'].touched && intermedimage.controls['503'].invalid) || (intermedimage.controls['503'].invalid && isSubmit)">
                                        Required </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr class="col-12">
                        <td colspan="6">
                            <div class="row">
                                <div class="col-4">
                                    <label for="512">Degree Received:<span *ngIf="intermedimage.controls['512'].invalid ||intermedimage.controls['cmd_160'].value==null||intermedimage.controls['cmd_160'].value=='' " class="mandatory">*</span></label>
                                </div>
                                <div class="col-7">
                                    <ng-select [labelForId]='512' [items]="degree"  (blur)="handleNAchange('cmd_160')" [class.is-invalid]="
                                                                                (intermedimage.controls['512'].invalid)"
                                        bindLabel="name" placeholder="Select Degree" bindValue="id" formControlName="512">
                                    </ng-select>
                                    <div class="invalid-feedback"
                                        *ngIf="(intermedimage.controls['512'].touched && intermedimage.controls['512'].invalid) || (intermedimage.controls['512'].invalid && isSubmit)">
                                        Degree is required and cannot be empty
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td colspan="6">
                            <div class="row">
                                <div class="col-5">
                                    <label for="511">Graduation Date (mm/dd/yy):<span *ngIf="intermedimage.controls['511'].invalid ||intermedimage.controls['cmd_160'].value==null||intermedimage.controls['cmd_160'].value=='' " class="mandatory">*</span></label>
                                </div>
                                <div class="col-7 " style="display: inline-block;">
                                    <div class="input-group">
                                    <input type="text" placeholder="UG School complete Date  "  (blur)="handleNAchange('cmd_160')" (blur)="clearInputIfInvalid('511','cmd_160')"  id="511" formControlName="511"
                                        class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker #idh17="ngbDatepicker"
                                        [class.is-invalid]="(intermedimage.controls['511'].invalid )">
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="idh17.toggle()" type="button">
                                            <i class="fas fa-calendar-alt"></i> </button>
                                    </div>
                                    </div>
                                    <div class="text-danger"
                                    *ngIf="intermedimage.controls['511'].invalid && (intermedimage.controls['511'].value==''|| intermedimage.controls['511'].value==null) ">
                                    <small>
                                        Required
                                    </small>
                                
                                </div>
                                
                                <div *ngIf="intermedimage.get('511').invalid">
                                    <small class="text-danger" *ngIf="intermedimage.get('511').errors.invalidDateFormat">
                                        Only Date Format is allowed(MM/DD/YYYY) </small>
                                    <small class="text-danger" *ngIf="intermedimage.get('511').errors.invalidMonth">
                                        Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="intermedimage.get('511').errors.invalidDateyear">
                                        year is out of range(1900-2099) </small>
                                    <small class="text-danger" *ngIf="intermedimage.get('511').errors.invalidDateRange">
                                        date is out of range
                                    </small>
                                </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr class="col-12">
                        <td colspan="6">
                            <div class="row">
                                <div class="col-4 ">
                                    <label for="504">Mailing Address:<span *ngIf="intermedimage.controls['504'].invalid ||intermedimage.controls['cmd_160'].value==null||intermedimage.controls['cmd_160'].value=='' " class="mandatory">*</span></label>
                                </div>
                                <div class="col-8 ">
                                    <input type="text"  (blur)="handleNAchange('cmd_160')" placeholder="UG School Address1 " id="504" formControlName="504"
                                        class="form-control"
                                        [class.is-invalid]="( intermedimage.controls['504'].invalid )">
                                    <div class="invalid-feedback"
                                        *ngIf="(intermedimage.controls['504'].touched && intermedimage.controls['504'].invalid) || (intermedimage.controls['504'].invalid && isSubmit)">
                                        Required
                                    </div>
                                </div>
                                <div class="col-4 pr-0">
                                </div>
                                <div class="col-8 ">
                                    <input type="text"  (blur)="handleNAchange('cmd_160')" placeholder="UG School Address2" id="505" formControlName="505"
                                        class="form-control"
                                        [class.is-invalid]="(intermedimage.controls['505'].touched && intermedimage.controls['505'].invalid ) || (intermedimage.controls['505'].invalid && isSubmit)">
                                    <div class="invalid-feedback"
                                        *ngIf="(intermedimage.controls['505'].invalid) ">
                                        Required
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td colspan="6">
                            <div class="row">
                                <div class="col-3 pr-0">
                                    <label for="506">City:<span *ngIf="intermedimage.controls['506'].invalid ||intermedimage.controls['cmd_160'].value==null||intermedimage.controls['cmd_160'].value=='' " class="mandatory">*</span></label>
                                </div>
                                <div class="col-8 pl-0">
                                    <input type="text" (blur)="handleNAchange('cmd_160')" placeholder="UG School City" id="506" formControlName="506" class="form-control"
                                        [class.is-invalid]="( intermedimage.controls['506'].invalid )">
                                    <div class="invalid-feedback"
                                        *ngIf="(intermedimage.controls['506'].touched && intermedimage.controls['506'].invalid) || (intermedimage.controls['506'].invalid && isSubmit)">
                                        Required
                                    </div>
                                </div>
                                <div class="col-3 pr-0">
                                    <label for="508">State:<span *ngIf="intermedimage.controls['508'].invalid ||intermedimage.controls['cmd_160'].value==null||intermedimage.controls['cmd_160'].value=='' " class="mandatory">*</span></label>
                                </div>
                                <div class="col-8 pl-0">
                                    <ng-select [items]="states" (blur)="handleNAchange('cmd_160')" [class.is-invalid]="(
                                                                                    intermedimage.controls['508'].invalid)"
                                        bindLabel="name" placeholder="UG School State" bindValue="id" formControlName="508">
                                    </ng-select>
                                    <div class="invalid-feedback"
                                        *ngIf="(intermedimage.controls['508'].touched && intermedimage.controls['508'].invalid) || (intermedimage.controls['508'].invalid && isSubmit)">
                                        State is required and cannot be empty
                                    </div>
                                </div>
                                <div class="col-3 pr-0">
                                    <label for="509">Zip Code:<span *ngIf="intermedimage.controls['509'].invalid ||intermedimage.controls['cmd_160'].value==null||intermedimage.controls['cmd_160'].value=='' " class="mandatory">*</span></label>
                                </div>
                                <div class="col-8 pl-0">
                                    <input type="text" (blur)="handleNAchange('cmd_160')" placeholder="UG School Zip" id="509" formControlName="509" class="form-control"
                                        [class.is-invalid]="( intermedimage.controls['509'].invalid )">
                                    <div class="invalid-feedback"
                                        *ngIf="(intermedimage.controls['509'].touched && intermedimage.controls['509'].invalid) || (intermedimage.controls['509'].invalid && isSubmit)">
                                        Required
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr class="col-12">
                        <td colspan="12">
                            <div class="row">
                                <div class="col-3">
                                    <label for="1288">Name of college or university:</label>
                                </div>
                                <div class="col-7">
                                    <input type="text" placeholder="Additional School Name " id="1288" formControlName="1288" class="form-control">
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr class="col-12">
                        <td colspan="6">
                            <div class="row">
                                <div class="col-4">
                                    <label for="1298">Degree Received:</label>
                                </div>
                                <div class="col-7">
                                    <ng-select [items]="degree"
                                        [class.is-invalid]="(intermedimage.controls['1298'].touched &&
                                                                                                            intermedimage.controls['1298'].invalid) ||
                                                                                                            (intermedimage.controls['1298'].invalid && isSubmit)"
                                        bindLabel="name" placeholder="Additional school  Degree" bindValue="id" formControlName="1298">
                                    </ng-select>
                                    <div class="invalid-feedback"
                                        *ngIf="(intermedimage.controls['1298'].touched && intermedimage.controls['1298'].invalid) || (intermedimage.controls['1298'].invalid && isSubmit)">
                                        Degree is required and cannot be empty </div>
                                </div>
                            </div>
                        </td>
                        <td colspan="6">
                            <div class="row">
                                <div class="col-5 pr-0">
                                    <label for="1297">Graduation Date(mm/dd/yy):</label>
                                </div>
                                <div class="col-7 ">
                                    <div class="input-group">
                                    <input type="text" placeholder="Additional School Complete Date " id="1297" formControlName="1297" (blur)="clearInputIfInvalid('1297')" 
                                        class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker
                                        #idh18="ngbDatepicker">
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="idh18.toggle()" type="button">
                                            <i class="fas fa-calendar-alt"></i> </button>
                                    </div>
                                    </div>
                                    <div *ngIf="intermedimage.get('1297').invalid ">
                                        <small class="text-danger" *ngIf="intermedimage.get('1297').errors?.invalidDateFormat">
                                            Only Date Format is allowed(MM/DD/YYYY)
                                        </small>
                                        <small class="text-danger" *ngIf="intermedimage.get('1297').errors?.invalidMonth">
                                            Month is out of range
                                        </small>
                                        <small class="text-danger" *ngIf="intermedimage.get('1297').errors?.invalidDateyear">
                                            year is out of range(1900-2099) </small>
                                        <small class="text-danger" *ngIf="intermedimage.get('1297').errors?.invalidDateRange">
                                            date is out of range
                                        </small>
                                      </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr class="col-12">
                        <td colspan="6">
                            <div class="row">
                                <div class="col-4 ">
                                    <label for="1289">Mailing Address:</label>
                                </div>
                                <div class="col-8 ">
                                    <input type="text" placeholder="Additional School Address1 " id="1289" formControlName="1289"
                                        class="form-control">
                                </div>
                                <div class="col-4 "></div>

                                <div class="col-8 ">
                                    <input type="text" placeholder="Additional School Address2" id="1290" formControlName="1290"
                                        class="form-control">
                                </div>
                            </div>
                        </td>
                        <td colspan="6">
                            <div class="row">
                                <div class="col-3 pr-0">
                                    <label for="1291">City:</label>
                                </div>
                                <div class="col-8 pl-0">
                                    <input type="text" placeholder="Additional School City " id="1291" formControlName="1291" class="form-control">
                                </div>
                                <div class="col-3 pr-0">
                                    <label for="1293">State:</label>
                                </div>
                                <div class="col-8 pl-0">
                                    <ng-select [items]="states"
                                        [class.is-invalid]="(intermedimage.controls['1293'].touched &&
                                                                                                        intermedimage.controls['1293'].invalid) ||
                                                                                                        (intermedimage.controls['1293'].invalid && isSubmit)"
                                        bindLabel="name" placeholder="Additional School State " bindValue="id" formControlName="1293">
                                    </ng-select>
                                    <div class="invalid-feedback"
                                        *ngIf="(intermedimage.controls['1293'].touched && intermedimage.controls['1293'].invalid) || (intermedimage.controls['1293'].invalid && isSubmit)">
                                        State is required and cannot be empty </div>
                                </div>
                                <div class="col-3 pr-0">
                                    <label for="1294">Zip Code:</label>
                                </div>
                                <div class="col-8 pl-0">
                                    <input type="text" placeholder="Additional School Zip" id="1294" formControlName="1294" class="form-control">
                                </div>
                            </div>
                        </td>
                    </tr>
                    </div>
                    </td>
                    </tr>
                    <tr class="col-12">
                        <td colspan="12">
                            <div class="table table-bordered" style="table-layout: fixed;">
                                <td colspan="12">
                                    <label>
                                        <h6>VII.MEDICAL/PROFESSIONAL EDUCATION ( Do not abbreviate ) (Attach additional sheet if necessary) </h6>
                                    </label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="doesnotapply">
                                        <label for="cmd_162"><b>Does Not Apply:</b></label>&nbsp;&nbsp;
                                        <input type="checkbox" value="Yes" (ngModelChange)="handleNAchange('cmd_162')" formControlName="cmd_162"
                                            id="cmd_162">
                                    </span>
                                </td>
                                <tr class="col-12">
                                    <td colspan="4">
                                        <div class="row">
                                            <div class="col-12">
                                                <label for="490">Medical/Professional School:<span *ngIf="intermedimage.controls['490'].invalid ||intermedimage.controls['cmd_162'].value==null||intermedimage.controls['cmd_162'].value=='' " class="mandatory">*</span></label>
                                            </div>
                                            <div class="col-12">
                                                <input type="text" (blur)="handleNAchange('cmd_162')" placeholder="Med School" id="490" formControlName="490"
                                                    class="form-control"
                                                    [class.is-invalid]="(intermedimage.controls['490'].invalid)">
                                                <div class="invalid-feedback"
                                                    *ngIf="(intermedimage.controls['490'].touched && intermedimage.controls['490'].invalid) || (intermedimage.controls['490'].invalid && isSubmit)">
                                                    Required
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td colspan="3">
                                        <div class="row">
                                            <div class="col-4">
                                                <label for="497">Start Date(mm/dd/yy):<span *ngIf="intermedimage.controls['497'].invalid ||intermedimage.controls['cmd_162'].value==null||intermedimage.controls['cmd_162'].value=='' " class="mandatory">*</span></label>
                                            </div>
                                            <div class="col-8 ">
                                                <div class="input-group">
                                                <input type="text" (blur)="handleNAchange('cmd_162')" (blur)="clearInputIfInvalid('497','cmd_162')"  placeholder="Med School Start " id="497" formControlName="497"
                                                    class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker #idh19="ngbDatepicker"
                                                    [class.is-invalid]="(intermedimage.controls['497'].invalid )">
                                                <div class="input-group-append">
                                                    <button class="btn btn-outline-secondary calendar" (click)="idh19.toggle()" type="button"> <i
                                                            class="fas fa-calendar-alt"></i> </button>
                                                </div>
                                                </div>
                                                <div class="text-danger"
                                                    *ngIf="intermedimage.controls['497'].invalid && (intermedimage.controls['497'].value==''|| intermedimage.controls['497'].value==null) ">
                                                    <small>
                                                        Required
                                                    </small>
                                                
                                                </div>
                                                
                                                <div *ngIf="intermedimage.get('497').invalid">
                                                    <small class="text-danger" *ngIf="intermedimage.get('497').errors.invalidDateFormat">
                                                        Only Date Format is allowed(MM/DD/YYYY) </small>
                                                    <small class="text-danger" *ngIf="intermedimage.get('497').errors.invalidMonth">
                                                        Month is out of range
                                                    </small>
                                                    <small class="text-danger" *ngIf="intermedimage.get('497').errors.invalidDateyear">
                                                        year is out of range(1900-2099) </small>
                                                    <small class="text-danger" *ngIf="intermedimage.get('497').errors.invalidDateRange">
                                                        date is out of range
                                                    </small>
                                                </div>
                                            </div>
                                            <div class="col-4">
                                                <label for="499">Graduation Date(mm/dd/yy):<span *ngIf="intermedimage.controls['499'].invalid ||intermedimage.controls['cmd_162'].value==null||intermedimage.controls['cmd_162'].value=='' " class="mandatory">*</span></label>
                                            </div>
                                            <div class="col-8 ">
                                                <div class="input-group">
                                                <input type="text"  (blur)="handleNAchange('cmd_162')" (blur)="clearInputIfInvalid('499','cmd_162')"  placeholder="Med School Completed " id="499" formControlName="499"
                                                    class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker #idh20="ngbDatepicker"
                                                    [class.is-invalid]="( intermedimage.controls['499'].invalid )">
                                                <div class="input-group-append">
                                                    <button class="btn btn-outline-secondary calendar" (click)="idh20.toggle()" type="button"> <i
                                                            class="fas fa-calendar-alt"></i> </button>
                                                </div>
                                                </div>
                                                <div class="text-danger"
                                                *ngIf="intermedimage.controls['499'].invalid && (intermedimage.controls['499'].value==''|| intermedimage.controls['499'].value==null) ">
                                                <small>
                                                    Required
                                                </small>
                                            
                                            </div>
                                            
                                            <div *ngIf="intermedimage.get('499').invalid">
                                                <small class="text-danger" *ngIf="intermedimage.get('499').errors.invalidDateFormat">
                                                    Only Date Format is allowed(MM/DD/YYYY) </small>
                                                <small class="text-danger" *ngIf="intermedimage.get('499').errors.invalidMonth">
                                                    Month is out of range
                                                </small>
                                                <small class="text-danger" *ngIf="intermedimage.get('499').errors.invalidDateyear">
                                                    year is out of range(1900-2099) </small>
                                                <small class="text-danger" *ngIf="intermedimage.get('499').errors.invalidDateRange">
                                                    date is out of range
                                                </small>
                                            </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td colspan="5">
                                        <div class="row">
                                            <div class="col-12 pr-0">
                                                <label for="500">Degree Received:<span *ngIf="intermedimage.controls['500'].invalid ||intermedimage.controls['cmd_162'].value==null||intermedimage.controls['cmd_162'].value=='' " class="mandatory">*</span></label>
                                            </div>
                                            <div class="col-10">
                                                <ng-select [items]="degree"  (blur)="handleNAchange('cmd_162')" [class.is-invalid]="(
                                                                     intermedimage.controls['500'].invalid) " bindLabel="name"
                                                    placeholder="Med School Degree " bindValue="id" formControlName="500"> </ng-select>
                                                <div class="invalid-feedback"
                                                    *ngIf="(intermedimage.controls['500'].touched && intermedimage.controls['500'].invalid) || (intermedimage.controls['500'].invalid && isSubmit)">
                                                    Degree is required and cannot be empty 
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr class="col-12">
                                    <td colspan="5">
                                        <div class="row">
                                            <div class="col-3 pr-0 ">
                                                <label for="491">Mailing Address:<span *ngIf="intermedimage.controls['491'].invalid ||intermedimage.controls['cmd_162'].value==null||intermedimage.controls['cmd_162'].value=='' " class="mandatory">*</span></label>
                                            </div>
                                            <div class="col-7 pl-0">
                                                <input type="text"  (blur)="handleNAchange('cmd_162')" placeholder="Med School Address1" id="491" formControlName="491"
                                                    class="form-control"
                                                    [class.is-invalid]="( intermedimage.controls['491'].invalid )">
                                                <div class="invalid-feedback"
                                                    *ngIf="(intermedimage.controls['491'].touched && intermedimage.controls['491'].invalid) || (intermedimage.controls['491'].invalid && isSubmit)">
                                                    Required </div>
                                            </div>
                                            <div class="col-3 pr-0">                                                
                                            </div>                              
                                            <div class="col-7 pl-0">
                                                <input type="text" (blur)="handleNAchange('cmd_162')" placeholder="Med School Address2" id="492" formControlName="492"
                                                    class="form-control"
                                                    [class.is-invalid]="( intermedimage.controls['492'].invalid )">
                                                <div class="invalid-feedback"
                                                    *ngIf="(intermedimage.controls['492'].touched && intermedimage.controls['492'].invalid) || (intermedimage.controls['492'].invalid && isSubmit)">
                                                    Required </div>
                                            </div>
                                        </div>
                                
                                    </td>
                                    <td colspan="7">
                                        <div class="row">
                                            <div class="col-3 pr-0">
                                                <label for="493">City:<span *ngIf="intermedimage.controls['493'].invalid ||intermedimage.controls['cmd_162'].value==null||intermedimage.controls['cmd_162'].value=='' " class="mandatory">*</span></label>
                                            </div>
                                            <div class="col-8 pl-0">
                                                <input type="text"  (blur)="handleNAchange('cmd_162')" placeholder="Med School City" id="493" formControlName="493" class="form-control"
                                                    [class.is-invalid]="(intermedimage.controls['493'].invalid )">
                                                <div class="invalid-feedback"
                                                    *ngIf="(intermedimage.controls['493'].touched && intermedimage.controls['493'].invalid) || (intermedimage.controls['493'].invalid && isSubmit)">
                                                    Required 
                                                </div>
                                            </div>
                                            <div class="col-3 pr-0">
                                                <label for="495">State:<span *ngIf="intermedimage.controls['495'].invalid ||intermedimage.controls['cmd_162'].value==null||intermedimage.controls['cmd_162'].value=='' " class="mandatory">*</span></label>
                                            </div>
                                            <div class="col-8 pl-0">
                                                <ng-select [items]="states" (blur)="handleNAchange('cmd_162')"  [class.is-invalid]="(
                                                                     intermedimage.controls['495'].invalid) " bindLabel="name"
                                                    placeholder="Med School State" bindValue="id" formControlName="495"> </ng-select>
                                                <div class="invalid-feedback"
                                                    *ngIf="(intermedimage.controls['495'].touched && intermedimage.controls['495'].invalid) || (intermedimage.controls['495'].invalid && isSubmit)">
                                                    State is required and cannot be empty 
                                                </div>
                                            </div>
                                            <div class="col-3 pr-0">
                                                <label for="496">Zip Code:<span *ngIf="intermedimage.controls['496'].invalid ||intermedimage.controls['cmd_162'].value==null||intermedimage.controls['cmd_162'].value=='' " class="mandatory">*</span></label>
                                            </div>
                                            <div class="col-8 pl-0">
                                                <input type="text"  (blur)="handleNAchange('cmd_162')" placeholder="Med School Zip" id="496" formControlName="496" class="form-control"
                                                    [class.is-invalid]="(intermedimage.controls['496'].invalid ) ">
                                                <div class="invalid-feedback"
                                                    *ngIf="(intermedimage.controls['496'].touched && intermedimage.controls['496'].invalid) || (intermedimage.controls['496'].invalid && isSubmit)">
                                                    Required 
                                                </div>
                                            </div>
                                        </div>                                
                                    </td>
                                </tr>
                                <tr class="col-12">
                                    <td colspan="5">
                                        <div class="row">
                                            <div class="col-3 pr-0">
                                                <label for="2351"> Phone:<span *ngIf="intermedimage.controls['2351'].invalid ||intermedimage.controls['cmd_162'].value==null||intermedimage.controls['cmd_162'].value=='' " class="mandatory">*</span></label>
                                            </div>
                                            <div class="col-7 pl-0">
                                                <input type="text" (blur)="handleNAchange('cmd_162')" placeholder="Med School Phone" id="2351" formControlName="2351"
                                                    class="form-control"
                                                    [class.is-invalid]="(intermedimage.controls['2351'].invalid )">
                                                <div class="invalid-feedback"
                                                    *ngIf="(intermedimage.controls['2351'].touched && intermedimage.controls['2351'].invalid) || (intermedimage.controls['2351'].invalid && isSubmit)">
                                                    Phone is required and cannot be empty </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td colspan="7">
                                        <div class="row">
                                            <div class="col-3 pr-0">
                                                <label for="2352">Fax:<span *ngIf="intermedimage.controls['2352'].invalid ||intermedimage.controls['cmd_162'].value==null||intermedimage.controls['cmd_162'].value=='' " class="mandatory">*</span></label>
                                            </div>
                                            <div class="col-8 pl-0">
                                                <input type="text" (blur)="handleNAchange('cmd_162')"  placeholder="Med School Fax" id="2352" formControlName="2352" class="form-control"
                                                    [class.is-invalid]="(intermedimage.controls['2352'].invalid )">
                                                <div class="invalid-feedback"
                                                    *ngIf="(intermedimage.controls['2352'].touched && intermedimage.controls['2352'].invalid) || (intermedimage.controls['2352'].invalid && isSubmit)">
                                                    Required 
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="4">
                                        <div class="row">
                                            <div class="col-8 pr-0">
                                                <label for="add_1_1288">Medical/Professional School:</label>
                                            </div>
                                            <div class="col-12">
                                                <input type="text" placeholder="Additional School Name " id="add_1_1288"
                                                    formControlName="add_1_1288" class="form-control">
                                            </div>
                                        </div>
                                    </td>
                                    <td colspan="4">
                                        <div class="row">
                                            <div class="col-4">
                                                <label for="add_1_1295">Start Date(mm/dd/yy):</label>
                                            </div>
                                            <div class="col-8  ">
                                                <div class="input-group">
                                                <input type="text" placeholder="Additional SchoolStart Date" id="add_1_1295" formControlName="add_1_1295" (blur)="clearInputIfInvalid('add_1_1295')" 
                                                    class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker
                                                    #idh21="ngbDatepicker">
                                                <div class="input-group-append">
                                                    <button class="btn btn-outline-secondary calendar" (click)="idh21.toggle()" type="button"> <i
                                                            class="fas fa-calendar-alt"></i> </button>
                                                </div>
                                                </div>
                                                <div *ngIf="intermedimage.get('add_1_1295').invalid ">
                                                    <small class="text-danger" *ngIf="intermedimage.get('add_1_1295').errors?.invalidDateFormat">
                                                        Only Date Format is allowed(MM/DD/YYYY)
                                                    </small>
                                                    <small class="text-danger" *ngIf="intermedimage.get('add_1_1295').errors?.invalidMonth">
                                                        Month is out of range
                                                    </small>
                                                    <small class="text-danger" *ngIf="intermedimage.get('add_1_1295').errors?.invalidDateyear">
                                                        year is out of range(1900-2099) </small>
                                                    <small class="text-danger" *ngIf="intermedimage.get('add_1_1295').errors?.invalidDateRange">
                                                        date is out of range
                                                    </small>
                                                  </div>
                                            </div>
                                            <div class="col-4">
                                                <label for="add_1_1297">Graduation Date(mm/dd/yy):</label>
                                            </div>
                                            <div class="col-8  ">
                                                <div class="input-group">
                                                <input type="text" placeholder="Additional School Complete Date " id="add_1_1297" formControlName="add_1_1297" (blur)="clearInputIfInvalid('add_1_1297')" 
                                                    class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker
                                                    #idh22="ngbDatepicker">
                                                <div class="input-group-append">
                                                    <button class="btn btn-outline-secondary calendar" (click)="idh22.toggle()" type="button"> <i
                                                            class="fas fa-calendar-alt"></i> </button>
                                                </div>
                                                </div>
                                                <div *ngIf="intermedimage.get('add_1_1297').invalid ">
                                                    <small class="text-danger" *ngIf="intermedimage.get('add_1_1297').errors?.invalidDateFormat">
                                                        Only Date Format is allowed(MM/DD/YYYY)
                                                    </small>
                                                    <small class="text-danger" *ngIf="intermedimage.get('add_1_1297').errors?.invalidMonth">
                                                        Month is out of range
                                                    </small>
                                                    <small class="text-danger" *ngIf="intermedimage.get('add_1_1297').errors?.invalidDateyear">
                                                        year is out of range(1900-2099) </small>
                                                    <small class="text-danger" *ngIf="intermedimage.get('add_1_1297').errors?.invalidDateRange">
                                                        date is out of range
                                                    </small>
                                                  </div>
                                            </div>
                                        </div>
                                    </td>
                                
                                    <td colspan="4">
                                        <div class="row">
                                            <div class="col-12 pr-0">
                                                <label for="add_1_1298">Degree Received:</label>
                                            </div>
                                            <div class="col-10">
                                                <ng-select [items]="degree" [class.is-invalid]="(intermedimage.controls['add_1_1298'].touched &&
                                                                     intermedimage.controls['add_1_1298'].invalid) ||
                                                                     (intermedimage.controls['add_1_1298'].invalid && isSubmit)" bindLabel="name"
                                                    placeholder=" Additional school Degree" bindValue="id" formControlName="add_1_1298"> </ng-select>
                                                <div class="invalid-feedback"
                                                    *ngIf="(intermedimage.controls['add_1_1298'].touched && intermedimage.controls['add_1_1298'].invalid) || (intermedimage.controls['add_1_1298'].invalid && isSubmit)">
                                                    Degree is required and cannot be empty 
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr class="col-12">
                                    <td colspan="5">
                                        <div class="row">
                                            <div class="col-3 pr-0">
                                                <label for="add_1_1289">Mailing Address:</label>
                                            </div>
                                            <div class="col-7 pl-0">
                                                <input type="text" placeholder=" Additional school Address1" id="add_1_1289" formControlName="add_1_1289"
                                                    class="form-control">
                                            </div>
                                            <div class="col-3 pr-0">                                          
                                            </div>
                                            <div class="col-7 pl-0">
                                                <input type="text" placeholder="Additional School Address2 " id="add_1_1290" formControlName="add_1_1290"
                                                    class="form-control">
                                            </div>
                                        </div>
                                
                                    </td>
                                    <td colspan="7">
                                        <div class="row">
                                            <div class="col-3 pr-0">
                                                <label for="add_1_1291">City:</label>
                                            </div>
                                            <div class="col-8 pl-0">
                                                <input type="text" placeholder="Additional School City" id="add_1_1291" formControlName="add_1_1291"
                                                    class="form-control">
                                            </div>
                                            <div class="col-3 pr-0">
                                                <label for="add_1_1291">State:</label>
                                            </div>
                                            <div class="col-8 pl-0">
                                                <ng-select [items]="states" [class.is-invalid]="(intermedimage.controls['add_1_1293'].touched &&
                                                                     intermedimage.controls['add_1_1293'].invalid) ||
                                                                     (intermedimage.controls['add_1_1293'].invalid && isSubmit)" bindLabel="name"
                                                    placeholder=" State" bindValue="id" formControlName="add_1_1293"> </ng-select>
                                            </div>
                                            <div class="col-3 pr-0">
                                                <label for="add_1_1291">Zip Code:</label>
                                            </div>
                                            <div class="col-8 pl-0">
                                                <input type="text" placeholder="Additional School Zip" id="add_1_1294" formControlName="add_1_1294"
                                                    class="form-control">
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr class="12">
                                    <td colspan="6">
                                        <div class="row">
                                            <div class="col-3 pr-0">
                                                <label for="2354"> Phone:</label>
                                            </div>
                                            <div class="col-7 pl-0">
                                                <input type="text" placeholder="Additional School Phone " id="2354" formControlName="2354"
                                                    class="form-control">
                                            </div>
                                        </div>
                                    </td>
                                    <td colspan="6">
                                        <div class="row">
                                            <div class="col-3 pr-0">
                                                <label for="2355">Fax :</label>
                                            </div>
                                            <div class="col-8 pl-0">
                                                <input type="text" placeholder="Additional School Fax" id="2355" formControlName="2355" class="form-control">
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                          </div>
                       </td>
                   </tr>
                   <tr class="col-12">
                        <td colspan="12">
                            <div class="table table-bordered" style="table-layout: fixed;">
                                <td colspan="12">
                                    <label>
                                        <h6>VIII. GRADUATE EDUCATION  ( Do not abbreviate ) (Attach additional sheet if necessary)</h6>
                                    </label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="doesnotapply">
                                        <label for="cmd_0160"><b>Does Not Apply:</b></label>&nbsp;&nbsp;
                                        <input type="checkbox" value="Yes" (ngModelChange)="handleNAchange('cmd_0160')" formControlName="cmd_0160"
                                            id="cmd_0160">
                                    </span>
                                </td>
                                <tr class="col-12">
                                    <td colspan="6">
                                        <div class="row">
                                            <div class="col-4">
                                                <label for="479">Institution:<span *ngIf="intermedimage.controls['479'].invalid ||intermedimage.controls['cmd_0160'].value==null||intermedimage.controls['cmd_0160'].value=='' " class="mandatory">*</span></label>
                                            </div>
                                            <div class="col-8">
                                                <input type="text"  (blur)="handleNAchange('cmd_0160')" placeholder="Grad School" id="479" formControlName="479" class="form-control"
                                                    [class.is-invalid]="(intermedimage.controls['479'].invalid )">
                                
                                                <div class="invalid-feedback"
                                                    *ngIf="(intermedimage.controls['479'].touched && intermedimage.controls['479'].invalid) || (intermedimage.controls['479'].invalid && isSubmit)">
                                                    Required </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td colspan="6">
                                        <div class="row">
                                            <div class="col-4">
                                                <label for="489">Program or course of study:<span *ngIf="intermedimage.controls['489'].invalid ||intermedimage.controls['cmd_0160'].value==null||intermedimage.controls['cmd_0160'].value=='' " class="mandatory">*</span></label>
                                            </div>
                                            <div class="col-8">
                                                <input type="text" (blur)="handleNAchange('cmd_0160')" placeholder="Grad School Program " [class.is-invalid]="(
                                                                                intermedimage.controls['489'].invalid)" id="489"
                                                    formControlName="489" class="form-control">
                                                <div class="invalid-feedback"
                                                    *ngIf="(intermedimage.controls['489'].touched && intermedimage.controls['489'].invalid) || (intermedimage.controls['489'].invalid && isSubmit)">
                                                    Required </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr class="col-12">
                                    <td colspan="5">
                                        <div class="row">
                                            <div class="col-4">
                                                <label for="480">Degree Received:<span *ngIf="intermedimage.controls['480'].invalid ||intermedimage.controls['cmd_0160'].value==null||intermedimage.controls['cmd_0160'].value=='' " class="mandatory">*</span></label>
                                            </div>
                                            <div class="col-6">
                                                <ng-select [labelForId]="'480'" [items]="degree"  (blur)="handleNAchange('cmd_0160')" [class.is-invalid]="
                                                                     
                                                                     intermedimage.controls['480'].invalid" bindLabel="name"
                                                    placeholder="Grad School Degree" bindValue="id" formControlName="480"> </ng-select>
                                
                                                <div class="invalid-feedback"
                                                    *ngIf="(intermedimage.controls['480'].touched && intermedimage.controls['480'].invalid) || (intermedimage.controls['480'].invalid && isSubmit)">
                                                    Required </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td colspan="7">
                                        <div class="row">
                                            <div class="col-3 pr-0">
                                                <label for="4074">Faculty Director:<span *ngIf="intermedimage.controls['4074'].invalid ||intermedimage.controls['cmd_0160'].value==null||intermedimage.controls['cmd_0160'].value=='' " class="mandatory">*</span></label>
                                            </div>
                                            <div class="col-8 pl-0">
                                                <input type="text" (blur)="handleNAchange('cmd_0160')" placeholder="Grad School Attention  " id="4074" formControlName="4074"
                                                    class="form-control"
                                                    [class.is-invalid]="( intermedimage.controls['4074'].invalid )">
                                
                                                <div class="invalid-feedback"
                                                    *ngIf="(intermedimage.controls['4074'].touched && intermedimage.controls['4074'].invalid) || (intermedimage.controls['4074'].invalid && isSubmit)">
                                                    Required </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>                              
                                <tr class="col-12">
                                    <td colspan="5">
                                        <div class="row">
                                            <div class="col-4 ">
                                                <label for="483">Mailing Address:<span *ngIf="intermedimage.controls['483'].invalid ||intermedimage.controls['cmd_0160'].value==null||intermedimage.controls['cmd_0160'].value=='' " class="mandatory">*</span></label>
                                            </div>
                                            <div class="col-8  ">
                                                <input type="text"  (blur)="handleNAchange('cmd_0160')" placeholder="Grad School Address1" id="483" formControlName="483"
                                                    class="form-control"
                                                    [class.is-invalid]="(intermedimage.controls['483'].invalid )">
                                
                                                <div class="invalid-feedback"
                                                    *ngIf="(intermedimage.controls['483'].touched && intermedimage.controls['483'].invalid) || (intermedimage.controls['483'].invalid && isSubmit)">
                                                    Required </div>
                                            </div>
                                            <div class="col-4"></div>
                                            <div class="col-8 ">
                                                <input type="text" (blur)="handleNAchange('cmd_0160')" placeholder="Grad School Address2" id="484" formControlName="484"
                                                    class="form-control"
                                                    [class.is-invalid]="( intermedimage.controls['484'].invalid )">
                                
                                                <div class="invalid-feedback"
                                                    *ngIf="(intermedimage.controls['484'].touched && intermedimage.controls['484'].invalid) || (intermedimage.controls['484'].invalid && isSubmit)">
                                                    Required </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td colspan="7">
                                        <div class="row">
                                            <div class="col-3 pr-0">
                                                <label for="485">City:<span *ngIf="intermedimage.controls['485'].invalid ||intermedimage.controls['cmd_0160'].value==null||intermedimage.controls['cmd_0160'].value=='' " class="mandatory">*</span></label>
                                            </div>
                                
                                            <div class="col-8 pl-0">
                                                <input type="text" (blur)="handleNAchange('cmd_0160')" placeholder="Grad School City" id="485" formControlName="485" class="form-control"
                                                    [class.is-invalid]="( intermedimage.controls['485'].invalid )">
                                
                                                <div class="invalid-feedback"
                                                    *ngIf="(intermedimage.controls['293'].touched && intermedimage.controls['293'].invalid) || (intermedimage.controls['293'].invalid && isSubmit)">
                                                    Required </div>
                                            </div>
                                            <div class="col-3 pr-0">
                                                <label for="487">State:<span *ngIf="intermedimage.controls['487'].invalid ||intermedimage.controls['cmd_0160'].value==null||intermedimage.controls['cmd_0160'].value=='' " class="mandatory">*</span></label>
                                            </div>
                                            <div class="col-8 pl-0">
                                                <ng-select [items]="states" (blur)="handleNAchange('cmd_0160')" [class.is-invalid]="(
                                                                     intermedimage.controls['487'].invalid)" bindLabel="name"
                                                    placeholder="Grad School State" bindValue="id" formControlName="487"> </ng-select>
                                
                                                <div class="invalid-feedback"
                                                    *ngIf="(intermedimage.controls['487'].touched && intermedimage.controls['487'].invalid) || (intermedimage.controls['487'].invalid && isSubmit)">
                                                    Required </div>
                                            </div>
                                            <div class="col-3 pr-0">
                                                <label for="488">Zip Code:<span *ngIf="intermedimage.controls['488'].invalid ||intermedimage.controls['cmd_0160'].value==null||intermedimage.controls['cmd_0160'].value=='' " class="mandatory">*</span></label>
                                            </div>
                                            <div class="col-8 pl-0">
                                                <input type="text" (blur)="handleNAchange('cmd_0160')" placeholder="Grad School Zip" id="488" formControlName="488" class="form-control"
                                                    [class.is-invalid]="( intermedimage.controls['488'].invalid )">
                                
                                                <div class="invalid-feedback"
                                                    *ngIf="(intermedimage.controls['488'].touched && intermedimage.controls['488'].invalid) || (intermedimage.controls['488'].invalid && isSubmit)">
                                                    Required </div>
                                            </div>
                                
                                        </div>
                                    </td>
                                </tr>
                                <tr class="col-12">
                                    <td colspan="3">
                                        <div class="row">
                                            <div class="col-8">
                                                <label for="481">Start Date (mm/dd/yy):<span *ngIf="intermedimage.controls['481'].invalid ||intermedimage.controls['cmd_0160'].value==null||intermedimage.controls['cmd_0160'].value=='' " class="mandatory">*</span></label>
                                            </div>
                                            <div class="col-12 " style="display: inline-block;">
                                                <div class="input-group">
                                                <input type="text" (blur)="handleNAchange('cmd_0160')" (blur)="clearInputIfInvalid('481','cmd_0160')"   placeholder="Grad School Start Date  " id="481" formControlName="481"
                                                    class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker
                                                    #idh200="ngbDatepicker"
                                                    [class.is-invalid]="(intermedimage.controls['481'].invalid )">
                                                <div class="input-group-append">
                                                    <button class="btn btn-outline-secondary calendar" (click)="idh200.toggle()" type="button"> <i
                                                            class="fas fa-calendar-alt"></i> </button>
                                                </div>
                                                </div>
                                                <div class="text-danger"
                                                    *ngIf="intermedimage.controls['481'].invalid && (intermedimage.controls['481'].value==''|| intermedimage.controls['481'].value==null) ">
                                                    <small>
                                                        Required
                                                    </small>
                                                
                                                </div>
                                                <div *ngIf="intermedimage.get('481').invalid">
                                                    <small class="text-danger" *ngIf="intermedimage.get('481').errors.invalidDateFormat">
                                                        Only Date Format is allowed(MM/DD/YYYY) </small>
                                                    <small class="text-danger" *ngIf="intermedimage.get('481').errors.invalidMonth">
                                                        Month is out of range
                                                    </small>
                                                    <small class="text-danger" *ngIf="intermedimage.get('481').errors.invalidDateyear">
                                                        year is out of range(1900-2099) </small>
                                                    <small class="text-danger" *ngIf="intermedimage.get('481').errors.invalidDateRange">
                                                        date is out of range
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td colspan="3">
                                        <div class="row">
                                            <div class="col-8">
                                                <label for="482">Completion Date (mm/dd/yy):<span *ngIf="intermedimage.controls['482'].invalid ||intermedimage.controls['cmd_0160'].value==null||intermedimage.controls['cmd_0160'].value=='' " class="mandatory">*</span></label>
                                            </div>
                                            <div class="col-12 " style="display: inline-block;">
                                                <div class="input-group">
                                                <input type="text"  (blur)="handleNAchange('cmd_0160')" (blur)="clearInputIfInvalid('482','cmd_0160')"  placeholder="Grad School Completed Date  " id="482" formControlName="482"
                                                    class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker #idh23="ngbDatepicker"
                                                    [class.is-invalid]="(intermedimage.controls['482'].invalid )">
                                                <div class="input-group-append">
                                                    <button class="btn btn-outline-secondary calendar" (click)="idh23.toggle()" type="button"> <i
                                                            class="fas fa-calendar-alt"></i> </button>
                                                </div>
                                                </div>
                                                <div class="text-danger"
                                                    *ngIf="intermedimage.controls['482'].invalid && (intermedimage.controls['482'].value==''|| intermedimage.controls['482'].value==null) ">
                                                    <small>
                                                        Required
                                                    </small>
                                                
                                                </div>
                                                <div *ngIf="intermedimage.get('482').invalid">
                                                    <small class="text-danger" *ngIf="intermedimage.get('482').errors.invalidDateFormat">
                                                        Only Date Format is allowed(MM/DD/YYYY) </small>
                                                    <small class="text-danger" *ngIf="intermedimage.get('482').errors.invalidMonth">
                                                        Month is out of range
                                                    </small>
                                                    <small class="text-danger" *ngIf="intermedimage.get('482').errors.invalidDateyear">
                                                        year is out of range(1900-2099) </small>
                                                    <small class="text-danger" *ngIf="intermedimage.get('482').errors.invalidDateRange">
                                                        date is out of range
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td colspan="3">
                                        <div class="row">
                                            <div class="col-3 pr-0">
                                                <label for="2348">Phone:<span *ngIf="intermedimage.controls['2348'].invalid ||intermedimage.controls['cmd_0160'].value==null||intermedimage.controls['cmd_0160'].value=='' " class="mandatory">*</span></label>
                                            </div>
                                            <div class="col-8 pl-0">
                                                <input type="text"  (blur)="handleNAchange('cmd_0160')" placeholder="grad school Phone" id="2348" formControlName="2348"
                                                    class="form-control"
                                                    [class.is-invalid]="( intermedimage.controls['2348'].invalid )">
                                
                                                <div class="invalid-feedback"
                                                    *ngIf="(intermedimage.controls['2348'].touched && intermedimage.controls['2348'].invalid) || (intermedimage.controls['2348'].invalid && isSubmit)">
                                                    Required </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td colspan="3">
                                        <div class="row">
                                            <div class="col-3 pr-0">
                                                <label for="2349">Fax:<span *ngIf="intermedimage.controls['2349'].invalid ||intermedimage.controls['cmd_0160'].value==null||intermedimage.controls['cmd_0160'].value=='' " class="mandatory">*</span></label>
                                            </div>
                                            <div class="col-8">
                                                <input type="text" (blur)="handleNAchange('cmd_0160')"  placeholder="grad school fax" id="2349" formControlName="2349" class="form-control"
                                                    [class.is-invalid]="( intermedimage.controls['2349'].invalid )">
                                                <div class="invalid-feedback"
                                                    *ngIf="(intermedimage.controls['2349'].touched && intermedimage.controls['2349'].invalid) || (intermedimage.controls['2349'].invalid && isSubmit)">
                                                    Required </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                         </div>
                      </td>
                   </tr>
                   
                   <tr class="col-12">
                    <td colspan="12">
                        <div class="table table-bordered" style="table-layout: fixed;">
                            <tr >
                              <td colspan="12">
                                <label>
                                    <h6>IX. INTERNSHIP/PGYI   ( Do not abbreviate )    (Attach additional sheet if necessary)</h6>
                                </label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            
                                <span class="doesnotapply">
                                    <label for="cmd_0162"><b>Does Not Apply:</b></label>&nbsp;&nbsp;
                                    <input type="checkbox" value="Yes" (ngModelChange)="handleNAchange('cmd_0162')" formControlName="cmd_0162"
                                        id="cmd_0162">
                                </span>
                               </td>
                        </tr>       
                <tr >
                    <td colspan="6">
                        <div class="row">
                            <div class="col-3">
                                <label for="517">Institution:<span
                                        *ngIf="intermedimage.controls['517'].invalid ||intermedimage.controls['cmd_0162'].value==null||intermedimage.controls['cmd_0162'].value=='' "
                                        class="mandatory">*</span></label>
                            </div>
                            <div class="col-9">
                                <input type="text" (blur)="handleNAchange('cmd_0162')"
                                    [class.is-invalid]="
                                                                                            intermedimage.controls['517'].invalid" placeholder="Intern School " id="517"
                                    formControlName="517" class="form-control">
                                <div class="invalid-feedback"
                                    *ngIf="(intermedimage.controls['517'].touched && intermedimage.controls['517'].invalid) || (intermedimage.controls['517'].invalid && isSubmit)">
                                    Required </div>
                            </div>
                        </div>
                    </td>
                
                    <td colspan="6">
                        <div class="row">
                            <div class="col-3">
                                <label for="528">Program Director:<span
                                        *ngIf="intermedimage.controls['528'].invalid ||intermedimage.controls['cmd_0162'].value==null||intermedimage.controls['cmd_0162'].value=='' "
                                        class="mandatory">*</span></label>
                            </div>
                            <div class="col-9">
                                <input type="text" (blur)="handleNAchange('cmd_0162')"
                                    [class.is-invalid]="
                                                                                            intermedimage.controls['528'].invalid" placeholder="Intern Director "
                                    id="528" formControlName="528" class="form-control">
                                <div class="invalid-feedback"
                                    *ngIf="(intermedimage.controls['528'].touched && intermedimage.controls['528'].invalid) || (intermedimage.controls['528'].invalid && isSubmit)">
                                    Required </div>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr >
                    <td colspan="7">
                        <div class="row">
                            <div class="col-3 pr-0">
                                <label for="518">Mailing Address:<span
                                        *ngIf="intermedimage.controls['518'].invalid ||intermedimage.controls['cmd_0162'].value==null||intermedimage.controls['cmd_0162'].value=='' "
                                        class="mandatory">*</span></label>
                            </div>
                            <div class="col-9 pl-0">
                                <input type="text" (blur)="handleNAchange('cmd_0162')"
                                    [class.is-invalid]="
                                                                                            intermedimage.controls['518'].invalid" placeholder="Intern Address1"
                                    id="518" formControlName="518" class="form-control">
                                <div class="invalid-feedback"
                                    *ngIf="(intermedimage.controls['518'].touched && intermedimage.controls['518'].invalid) || (intermedimage.controls['518'].invalid && isSubmit)">
                                    Required </div>
                            </div>
                            <div class="col-3 pr-0"></div>
                            <div class="col-9 pl-0">
                                <input type="text" (blur)="handleNAchange('cmd_0162')"
                                    [class.is-invalid]="
                                                                                            intermedimage.controls['519'].invalid" placeholder="Intern Address2"
                                    id="519" formControlName="519" class="form-control">
                                <div class="invalid-feedback"
                                    *ngIf="(intermedimage.controls['519'].touched && intermedimage.controls['519'].invalid) || (intermedimage.controls['519'].invalid && isSubmit)">
                                    Required </div>
                            </div>
                        </div>
                    </td>
                    
                    <td colspan="5">
                        <div class="row">
                            <div class="col-3 pr-0">
                                <label for="520">City:<span
                                        *ngIf="intermedimage.controls['520'].invalid ||intermedimage.controls['cmd_0162'].value==null||intermedimage.controls['cmd_0162'].value=='' "
                                        class="mandatory">*</span></label>
                            </div>
                            <div class="col-8 pl-0 ">
                                <input type="text" (blur)="handleNAchange('cmd_0162')"
                                    [class.is-invalid]="
                                                                                            intermedimage.controls['520'].invalid" placeholder="Intern City " id="520"
                                    formControlName="520" class="form-control">
                                <div class="invalid-feedback"
                                    *ngIf="(intermedimage.controls['520'].touched && intermedimage.controls['520'].invalid) || (intermedimage.controls['520'].invalid && isSubmit)">
                                    Required </div>
                            </div>
                            <div class="col-3 pr-0">
                                <label for="522">State:<span
                                        *ngIf="intermedimage.controls['522'].invalid ||intermedimage.controls['cmd_0162'].value==null||intermedimage.controls['cmd_0162'].value=='' "
                                        class="mandatory">*</span></label>
                            </div>
                            <div class="col-8 pl-0">
                                <ng-select [items]="states" (blur)="handleNAchange('cmd_0162')" [class.is-invalid]="
                                                                                 intermedimage.controls['522'].invalid"
                                    bindLabel="name" placeholder="Intern State" bindValue="id" formControlName="522"> </ng-select>
                
                                <div class="invalid-feedback"
                                    *ngIf="(intermedimage.controls['522'].touched && intermedimage.controls['522'].invalid) || (intermedimage.controls['522'].invalid && isSubmit)">
                                    Required </div>
                            </div>
                            <div class="col-3 pr-0 ">
                                <label for="523">Zip Code:<span
                                        *ngIf="intermedimage.controls['523'].invalid ||intermedimage.controls['cmd_0162'].value==null||intermedimage.controls['cmd_0162'].value=='' "
                                        class="mandatory">*</span></label>
                            </div>
                            <div class="col-8 pl-0">
                                <input type="text" (blur)="handleNAchange('cmd_0162')"
                                    [class.is-invalid]="
                                                                                            intermedimage.controls['523'].invalid" placeholder="Intern Zip" id="523"
                                    formControlName="523" class="form-control">
                                <div class="invalid-feedback"
                                    *ngIf="(intermedimage.controls['523'].touched && intermedimage.controls['523'].invalid) || (intermedimage.controls['523'].invalid && isSubmit)">
                                    Required </div>
                            </div>
                        </div>
                    </td>
                
                </tr>
                <tr >
                    <td colspan="6">
                        <div class="row">
                            <div class="col-3">
                                <label for="524">Start date(mm/dd/yy):<span
                                        *ngIf="intermedimage.controls['524'].invalid ||intermedimage.controls['cmd_0162'].value==null||intermedimage.controls['cmd_0162'].value=='' "
                                        class="mandatory">*</span></label>
                            </div>
                            <div class="col-8 ">
                                <div class="input-group">
                                <input type="text" (blur)="handleNAchange('cmd_0162')" (blur)="clearInputIfInvalid('524','cmd_0162')" 
                                    [class.is-invalid]="
                                                                                            intermedimage.controls['524'].invalid" placeholder="Intern Start Date "
                                    class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker #idh24="ngbDatepicker"
                                    id="524" formControlName="524">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="idh24.toggle()" type="button"> <i
                                            class="fas fa-calendar-alt"></i> </button>
                                </div>
                                </div>
                                <div class="text-danger"
                                    *ngIf="intermedimage.controls['524'].invalid && (intermedimage.controls['524'].value==''|| intermedimage.controls['524'].value==null) ">
                                    <small>
                                        Required
                                    </small>
                                
                                </div>
                                <div *ngIf="intermedimage.get('524').invalid">
                                    <small class="text-danger" *ngIf="intermedimage.get('524').errors.invalidDateFormat">
                                        Only Date Format is allowed(MM/DD/YYYY) </small>
                                    <small class="text-danger" *ngIf="intermedimage.get('524').errors.invalidMonth">
                                        Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="intermedimage.get('524').errors.invalidDateyear">
                                        year is out of range(1900-2099) </small>
                                    <small class="text-danger" *ngIf="intermedimage.get('524').errors.invalidDateRange">
                                        date is out of range
                                    </small>
                                </div>
                            </div>
                        </div>
                    </td>
               
                    <td colspan="6">
                        <div class="row">
                            <div class="col-3">
                                <label for="526">Completion date(mm/dd/yy):<span
                                        *ngIf="intermedimage.controls['526'].invalid ||intermedimage.controls['cmd_0162'].value==null||intermedimage.controls['cmd_0162'].value=='' "
                                        class="mandatory">*</span></label>
                            </div>
                            <div class="col-8  ">
                                <div class="input-group">
                                <input type="text" (blur)="handleNAchange('cmd_0162')" (blur)="clearInputIfInvalid('526','cmd_0162')"
                                    [class.is-invalid]="
                                                                                            intermedimage.controls['526'].invalid" placeholder="Intern Complete Date  "
                                    id="526" formControlName="526" class="form-control datepicker" data-id="priornameeffdate_date"
                                    ngbDatepicker #idh25="ngbDatepicker">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="idh25.toggle()" type="button"> <i
                                            class="fas fa-calendar-alt"></i> </button>
                                </div>
                                </div>
                                <div class="text-danger"
                                *ngIf="intermedimage.controls['526'].invalid && (intermedimage.controls['526'].value==''|| intermedimage.controls['526'].value==null) ">
                                <small>
                                    Required
                                </small>
                            
                            </div>
                            <div *ngIf="intermedimage.get('526').invalid">
                                <small class="text-danger" *ngIf="intermedimage.get('526').errors.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY) </small>
                                <small class="text-danger" *ngIf="intermedimage.get('526').errors.invalidMonth">
                                    Month is out of range
                                </small>
                                <small class="text-danger" *ngIf="intermedimage.get('526').errors.invalidDateyear">
                                    year is out of range(1900-2099) </small>
                                <small class="text-danger" *ngIf="intermedimage.get('526').errors.invalidDateRange">
                                    date is out of range
                                </small>
                            </div> <div class="invalid-feedback"
                                    *ngIf="(intermedimage.controls['526'].touched && intermedimage.controls['526'].invalid) || (intermedimage.controls['526'].invalid && isSubmit)">
                                    Required </div>
                            </div>
                        </div>
                
                    </td>
                </tr>
                <tr >
                    <td colspan="6">
                        <div class="row">
                            <div class="col-3">
                                <label for="1318">Phone Number:<span
                                        *ngIf="intermedimage.controls['1318'].invalid ||intermedimage.controls['cmd_0162'].value==null||intermedimage.controls['cmd_0162'].value=='' "
                                        class="mandatory">*</span></label>
                            </div>
                            <div class="col-8">
                                <input type="text" (blur)="handleNAchange('cmd_0162')"
                                    [class.is-invalid]="
                                                                                            intermedimage.controls['1318'].invalid" placeholder="Intern Director Phone "
                                    id="1318" formControlName="1318" class="form-control"> <small class="text-danger"
                                    *ngIf="intermedimage.controls['1318'].errors?.pattern">
                                    Only number are allowed</small>
                                <div class="invalid-feedback"
                                    *ngIf="(intermedimage.controls['1318'].touched && intermedimage.controls['1318'].invalid) || (intermedimage.controls['1318'].invalid && isSubmit)">
                                    Required </div>
                            </div>
                        </div>
                    </td>
                   
                    <td colspan="6">
                        <div class="row">
                            <div class="col-3">
                                <label for="1319">Fax Number:<span
                                        *ngIf="intermedimage.controls['1319'].invalid ||intermedimage.controls['cmd_0162'].value==null||intermedimage.controls['cmd_0162'].value=='' "
                                        class="mandatory">*</span></label>
                            </div>
                            <div class="col-8">
                                <input type="text" (blur)="handleNAchange('cmd_0162')"
                                    [class.is-invalid]="
                                                                                            intermedimage.controls['1319'].invalid" placeholder="Intern Director Fax " id="1319"
                                    formControlName="1319" class="form-control">
                                <div class="invalid-feedback"
                                    *ngIf="(intermedimage.controls['1319'].touched && intermedimage.controls['1319'].invalid) || (intermedimage.controls['1319'].invalid && isSubmit)">
                                    Required </div>
                            </div>
                        </div>
                    </td>
                
                </tr>
                <tr >
                    <td colspan="6">
                        <div class="row">
                            <div class="col-3">
                                <label for="2539">Type of Internship:<span
                                        *ngIf="intermedimage.controls['2539'].invalid ||intermedimage.controls['cmd_0162'].value==null||intermedimage.controls['cmd_0162'].value=='' "
                                        class="mandatory">*</span></label>
                            </div>
                            <div class="col-8">
                                <input type="text" (blur)="handleNAchange('cmd_0162')"
                                    [class.is-invalid]="
                                                                                            intermedimage.controls['2539'].invalid" placeholder="Internship Department "
                                    id="2539" formControlName="2539" class="form-control">
                                <div class="invalid-feedback"
                                    *ngIf="(intermedimage.controls['2539'].touched && intermedimage.controls['2539'].invalid) || (intermedimage.controls['2539'].invalid && isSubmit)">
                                    Required </div>
                            </div>
                
                        </div>
                    </td>
               
                    <td colspan="6">
                        <div class="row">
                            <div class="col-3">
                                <label for="527">Specialty:<span
                                        *ngIf="intermedimage.controls['527'].invalid ||intermedimage.controls['cmd_0162'].value==null||intermedimage.controls['cmd_0162'].value=='' "
                                        class="mandatory">*</span></label>
                            </div>
                            <div class="col-8">
                                <ng-select [items]="specialty" (blur)="handleNAchange('cmd_0162')"  [class.is-invalid]="
                                                                         intermedimage.controls['527'].invalid"
                                    bindLabel="name" placeholder=" Intern Primary Specialty" bindValue="id" formControlName="527"> </ng-select>
                                <div class="invalid-feedback"
                                    *ngIf="(intermedimage.controls['527'].touched && intermedimage.controls['527'].invalid) || (intermedimage.controls['527'].invalid && isSubmit)">
                                    primary Speciality is required and cannot be empty </div>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr >
                    <td colspan="12">
                        <div class="row">
                            <label class="ml-3">Did you successfully complete the program?<span
                                    *ngIf="intermedimage.controls['cmd_0165'].invalid ||intermedimage.controls['cmd_0162'].value==null||intermedimage.controls['cmd_0162'].value=='' "
                                    class="mandatory">*</span>
                            </label>&nbsp;&nbsp;&nbsp;
                            <div class="col-md-2">
                                <input type="radio" (blur)="handleNAchange('cmd_0162')" formControlName="cmd_0165"
                                    [class.is-invalid]="(intermedimage.controls['cmd_0165'].touched && intermedimage.controls['cmd_0165'].invalid) || (intermedimage.controls['cmd_0165'].invalid && isSubmit)"
                                    id="cmd_0165" name="cmd_0165" value="1">
                                <label>Yes&nbsp;&nbsp;</label>
                                <div class="formLabel col-md-11"
                                    *ngIf="intermedimage.controls['cmd_0165'].touched && intermedimage.controls['cmd_0165'].invalid">
                                    <small class="text-danger" *ngIf="intermedimage.controls['cmd_0165'].errors?.required">
                                        required
                                    </small>
                                </div>
                                <input type="radio" (blur)="handleNAchange('cmd_0162')" formControlName="cmd_0165"
                                    [class.is-invalid]="(intermedimage.controls['cmd_0165'].touched && intermedimage.controls['cmd_0165'].invalid) || (intermedimage.controls['cmd_0165'].invalid && isSubmit)"
                                    id="cmd_0165" name="cmd_0165" value="0">
                                <label>No</label>
                                <div class="formLabel col-md-11"
                                    *ngIf="intermedimage.controls['cmd_0165'].touched && intermedimage.controls['cmd_0165'].invalid">
                                    <small class="text-danger" *ngIf="intermedimage.controls['cmd_0165'].errors?.required">
                                        required
                                    </small>
                                </div>
                                <span
                                    *ngIf="(intermedimage.controls['cmd_0165'].touched && intermedimage.controls['cmd_0165'].invalid) || (intermedimage.controls['cmd_0165'].invalid && isSubmit)">
                                    <i class="fas fa-times ml-2 text-danger"></i>
                                </span>
                            </div>
                        </div>
                    </td>
                </tr>
                </div>
                </td> 
                   </tr>  
                
                  
                <tr class="col-12">
                    <td colspan="12">
                        <div class="table table-bordered" style="table-layout: fixed;">
                            <td colspan="12">
                                <label>
                                    <h6>X. RESIDENCIES ( Do not abbreviate ) (Attach additional sheet if necessary)</h6>
                                </label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="doesnotapply">
                                    <label for="cmd_163"><b>Does Not Apply:</b></label>&nbsp;&nbsp;
                                    <input type="checkbox" value="Yes" (ngModelChange)="handleNAchange('cmd_163')" formControlName="cmd_163"
                                        id="cmd_163">
                                </span>
                            </td>
                            <tr class="col-12">
                                <td colspan="6">
                                    <div class="row">
                                        <div class="col-3">
                                            <label for="529">Institution:<span *ngIf="intermedimage.controls['529'].invalid ||intermedimage.controls['cmd_163'].value==null||intermedimage.controls['cmd_163'].value=='' " class="mandatory">*</span></label>
                                        </div>
                                        <div class="col-8">
                                            <input type="text" (blur)="handleNAchange('cmd_163')"
                                                [class.is-invalid]="(
                                                  intermedimage.controls['529'].invalid)"
                                                placeholder="Residency School " id="529" formControlName="529" class="form-control">
                                                <div class="mandatory" *ngIf="intermedimage.controls['529'].invalid">
                                                    <small>
                                                      required
                                                    </small>
                                                  </div>
                                        </div>
                                    </div>
                                </td>
                            <!-- </tr>
                            <tr class="col-12"> -->
                            
                                <td colspan="6">
                                    <div class="row">
                                        <div class="col-3">
                                            <label for="540">Program Director:<span *ngIf="intermedimage.controls['540'].invalid ||intermedimage.controls['cmd_163'].value==null||intermedimage.controls['cmd_163'].value=='' " class="mandatory">*</span></label>
                                        </div>
                                        <div class="col-8">
                                            <input type="text" (blur)="handleNAchange('cmd_163')"
                                                [class.is-invalid]="
                                                 intermedimage.controls['540'].invalid"
                                                placeholder="Residency Director " id="540" formControlName="540" class="form-control">
                                            <div class="invalid-feedback"
                                                *ngIf="(intermedimage.controls['540'].touched && intermedimage.controls['540'].invalid) || (intermedimage.controls['540'].invalid && isSubmit)">
                                                Required </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr class="col-12">
                                <td colspan="5">
                                    <div class="row">
                                        <div class="col-3 pr-0">
                                            <label for="531">Mailing Address:<span *ngIf="intermedimage.controls['531'].invalid ||intermedimage.controls['cmd_163'].value==null||intermedimage.controls['cmd_163'].value=='' " class="mandatory">*</span></label>
                                        </div>
                                        <div class="col-8 pl-0">
                                            <input type="text" (blur)="handleNAchange('cmd_163')"
                                                [class.is-invalid]="intermedimage.controls['531'].invalid"
                                                placeholder="Residency Address1 " id="531" formControlName="531" class="form-control">
                                            <div class="invalid-feedback"
                                                *ngIf="(intermedimage.controls['531'].touched && intermedimage.controls['531'].invalid) || (intermedimage.controls['531'].invalid && isSubmit)">
                                                Required </div>
                                        </div>
                                        <div class="col-3 pr-0"></div>
                                        <div class="col-8 pl-0">
                                            <input type="text" (blur)="handleNAchange('cmd_163')"
                                                [class.is-invalid]=" intermedimage.controls['1498'].invalid"
                                                placeholder="Residency Address2" id="1498" formControlName="1498" class="form-control">
                                            <div class="invalid-feedback"
                                                *ngIf="(intermedimage.controls['1498'].touched && intermedimage.controls['1498'].invalid) || (intermedimage.controls['1498'].invalid && isSubmit)">
                                                Required </div>
                                        </div>
                                    </div>
                                </td>
                            <!-- </tr>
                            <tr class="col-12"> -->
                            
                                <td colspan="7">
                                    <div class="row">
                                        <div class="col-3 pr-0">
                                            <label for="533">City:<span *ngIf="intermedimage.controls['533'].invalid ||intermedimage.controls['cmd_163'].value==null||intermedimage.controls['cmd_163'].value=='' " class="mandatory">*</span></label>
                                        </div>
                                        <div class="col-8 pl-0">
                                            <input type="text" (blur)="handleNAchange('cmd_163')"
                                                [class.is-invalid]=" intermedimage.controls['533'].invalid"
                                                placeholder="Residency  city" id="533" formControlName="533" class="form-control">
                                            <div class="invalid-feedback"
                                                *ngIf="(intermedimage.controls['533'].touched && intermedimage.controls['533'].invalid) || (intermedimage.controls['533'].invalid && isSubmit)">
                                                Required </div>
                                        </div>
                                        <div class="col-3 pr-0">
                                            <label for="535">State:<span *ngIf="intermedimage.controls['535'].invalid ||intermedimage.controls['cmd_163'].value==null||intermedimage.controls['cmd_163'].value=='' " class="mandatory">*</span></label>
                                        </div>
                                        <div class="col-8 pl-0">
                                            <ng-select [items]="states" (blur)="handleNAchange('cmd_163')"
                                                [class.is-invalid]="
                                               intermedimage.controls['535'].invalid"
                                                bindLabel="name" placeholder="Residency State" bindValue="id" formControlName="535"> </ng-select>
                                            <div class="invalid-feedback"
                                                *ngIf="(intermedimage.controls['535'].touched && intermedimage.controls['535'].invalid) || (intermedimage.controls['535'].invalid && isSubmit)">
                                                Required </div>
                            
                                        </div>
                                        <div class="col-3 pr-0">
                                            <label for="536">Zip Code:<span *ngIf="intermedimage.controls['536'].invalid ||intermedimage.controls['cmd_163'].value==null||intermedimage.controls['cmd_163'].value=='' " class="mandatory">*</span></label>
                                        </div>
                                        <div class="col-8 pl-0">
                                            <input type="text" (blur)="handleNAchange('cmd_163')"
                                                [class.is-invalid]="
                                                                                                                            intermedimage.controls['536'].invalid"
                                                placeholder="Residency Zip" id="536" formControlName="536" class="form-control">
                                            <div class="invalid-feedback"
                                                *ngIf="(intermedimage.controls['536'].touched && intermedimage.controls['536'].invalid) || (intermedimage.controls['536'].invalid && isSubmit)">
                                                Required </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr class="col-12">
                                <td class="col-6">
                                    <div class="row">
                                        <div class="col-3">
                                            <label for="537">Start date(mm/dd/yy):<span *ngIf="intermedimage.controls['537'].invalid ||intermedimage.controls['cmd_163'].value==null||intermedimage.controls['cmd_163'].value=='' " class="mandatory">*</span></label>
                                        </div>
                                        <div class="col-6 ">
                                            <div class="input-group">
                                            <input type="text" (blur)="handleNAchange('cmd_163')" (blur)="clearInputIfInvalid('537','cmd_163')" 
                                                [class.is-invalid]="
                                                  intermedimage.controls['537'].invalid"
                                                placeholder="Residency Start  " id="537" formControlName="537" class="form-control datepicker"
                                                data-id="priornameeffdate_date" ngbDatepicker #idh26="ngbDatepicker">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary calendar" (click)="idh26.toggle()" type="button"> <i
                                                        class="fas fa-calendar-alt"></i> </button>
                                            </div>
                                            </div>
                                            <div class="text-danger"
                                            *ngIf="intermedimage.controls['537'].invalid && (intermedimage.controls['537'].value==''|| intermedimage.controls['537'].value==null) ">
                                            <small>
                                                Required
                                            </small>
                                        
                                        </div>
                                        <div *ngIf="intermedimage.get('537').invalid">
                                            <small class="text-danger" *ngIf="intermedimage.get('537').errors.invalidDateFormat">
                                                Only Date Format is allowed(MM/DD/YYYY) </small>
                                            <small class="text-danger" *ngIf="intermedimage.get('537').errors.invalidMonth">
                                                Month is out of range
                                            </small>
                                            <small class="text-danger" *ngIf="intermedimage.get('537').errors.invalidDateyear">
                                                year is out of range(1900-2099) </small>
                                            <small class="text-danger" *ngIf="intermedimage.get('537').errors.invalidDateRange">
                                                date is out of range
                                            </small>
                                        </div>
                            
                                        </div>
                                  </div>
                             </td>
                             <td colspan="6">
                                <div class="row">
                                     <div class="col-3">
                                        <label for="538">Completion date (mm/dd/yy):<span *ngIf="intermedimage.controls['538'].invalid ||intermedimage.controls['cmd_163'].value==null||intermedimage.controls['cmd_163'].value=='' " class="mandatory">*</span></label>
                                     </div>
                                     <div class="col-6 ">
                                        <div class="input-group">
                                            <input type="text" (blur)="handleNAchange('cmd_163')"  (blur)="clearInputIfInvalid('538','cmd_163')" 
                                                [class.is-invalid]="
                                                intermedimage.controls['538'].invalid"
                                                placeholder="Residency End" id="538" formControlName="538" class="form-control datepicker"
                                                data-id="priornameeffdate_date" ngbDatepicker #idh27="ngbDatepicker">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary calendar" (click)="idh27.toggle()" type="button"> <i
                                                        class="fas fa-calendar-alt"></i> </button>
                                            </div>
                                            </div>
                                            <div class="text-danger"
                                            *ngIf="intermedimage.controls['538'].invalid && (intermedimage.controls['538'].value==''|| intermedimage.controls['538'].value==null) ">
                                            <small>
                                                Required
                                            </small>
                                        
                                        </div>
                                        <div *ngIf="intermedimage.get('538').invalid">
                                            <small class="text-danger" *ngIf="intermedimage.get('538').errors.invalidDateFormat">
                                                Only Date Format is allowed(MM/DD/YYYY) </small>
                                            <small class="text-danger" *ngIf="intermedimage.get('538').errors.invalidMonth">
                                                Month is out of range
                                            </small>
                                            <small class="text-danger" *ngIf="intermedimage.get('538').errors.invalidDateyear">
                                                year is out of range(1900-2099) </small>
                                            <small class="text-danger" *ngIf="intermedimage.get('538').errors.invalidDateRange">
                                                date is out of range
                                            </small>
                                        </div>
                            
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr class="col-12">
                                <td colspan="6">
                                    <div class="row">
                                        <div class="col-3">
                                            <label for="1316">Phone:<span *ngIf="intermedimage.controls['1316'].invalid ||intermedimage.controls['cmd_163'].value==null||intermedimage.controls['cmd_163'].value=='' " class="mandatory">*</span></label>
                                        </div>
                                        <div class="col-8">
                                            <input type="text" (blur)="handleNAchange('cmd_163')"
                                                [class.is-invalid]="
                                                 intermedimage.controls['1316'].invalid"
                                                placeholder="Residency Phone" id="1316" formControlName="1316" class="form-control"> <small
                                                class="text-danger" *ngIf="intermedimage.controls['1316'].errors?.pattern">
                                                Only number are allowed</small>
                                            <div class="invalid-feedback"
                                                *ngIf="(intermedimage.controls['1316'].touched && intermedimage.controls['1316'].invalid) || (intermedimage.controls['1316'].invalid && isSubmit)">
                                                Required </div>
                                        </div>
                                    </div>
                                </td>
                            <!-- </tr>
                            <tr class="col-12"> -->
                                <td colspan="6">
                                    <div class="row">
                                        <div class="col-3">
                                            <label for="1317">Fax:<span *ngIf="intermedimage.controls['1317'].invalid ||intermedimage.controls['cmd_163'].value==null||intermedimage.controls['cmd_163'].value=='' " class="mandatory">*</span></label>
                                        </div>
                                        <div class="col-8">
                                            <input type="text" (blur)="handleNAchange('cmd_163')"
                                                [class.is-invalid]="
                                                                                                                            intermedimage.controls['1317'].invalid"
                                                placeholder="Residency Fax" id="1317" formControlName="1317" class="form-control">
                                            <div class="invalid-feedback"
                                                *ngIf="(intermedimage.controls['1317'].touched && intermedimage.controls['1317'].invalid) || (intermedimage.controls['1317'].invalid && isSubmit)">
                                                Required </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr class="col-12">
                                <td class="col-6">
                                    <div class="row">
                                        <div class="col-3">
                                            <label for="2540">Type of Residency:<span *ngIf="intermedimage.controls['2540'].invalid ||intermedimage.controls['cmd_163'].value==null||intermedimage.controls['cmd_163'].value=='' " class="mandatory">*</span></label>
                                        </div>
                                        <div class="col-6">
                                            <input type="text" (blur)="handleNAchange('cmd_163')"
                                                [class.is-invalid]="
                                                   intermedimage.controls['2540'].invalid"
                                                placeholder="Residency Department " id="2540" formControlName="2540" class="form-control">
                                            <div class="invalid-feedback"
                                                *ngIf="(intermedimage.controls['2540'].touched && intermedimage.controls['2540'].invalid) || (intermedimage.controls['2540'].invalid && isSubmit)">
                                                Required </div>
                                        </div>
                                   </div>
                                </td>
                                <td colspan="6">
                                   <div class="row">
                                        <div class="col-3">
                                            <label for="530">Specialty:<span *ngIf="intermedimage.controls['530'].invalid ||intermedimage.controls['cmd_163'].value==null||intermedimage.controls['cmd_163'].value=='' " class="mandatory">*</span></label>
                                        </div>
                                        <div class="col-8"> 
                                            <ng-select [items]="specialty" (blur)="handleNAchange('cmd_163')"
                                                [class.is-invalid]="(
                                               intermedimage.controls['530'].invalid)"
                                                bindLabel="name" placeholder=" Residency Primary Specialty" bindValue="id" formControlName="530"> </ng-select>
                                            <div class="invalid-feedback"
                                                *ngIf="(intermedimage.controls['530'].touched && intermedimage.controls['530'].invalid) || (intermedimage.controls['530'].invalid && isSubmit)">
                                                primary Speciality is required and cannot be empty </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr class="col-12">
                                <td colspan="12">
                                    <div class="row">
                                        <label class="ml-3">Did you successfully complete the program?<span *ngIf="intermedimage.controls['cmd_164'].invalid ||intermedimage.controls['cmd_163'].value==null||intermedimage.controls['cmd_163'].value=='' " class="mandatory">*</span>
                                        </label>&nbsp;&nbsp;&nbsp;
                                        <div class="col-md-2">
                                            <input type="radio" formControlName="cmd_164" (blur)="handleNAchange('cmd_163')"
                                                [class.is-invalid]="(intermedimage.controls['cmd_164'].touched && intermedimage.controls['cmd_164'].invalid) || (intermedimage.controls['cmd_164'].invalid && isSubmit)"
                                                id="cmd_164" name="cmd_164" value="1">
                                            <label>Yes&nbsp;&nbsp;</label>
                                            <div class="formLabel col-md-11"
                                                *ngIf="intermedimage.controls['cmd_164'].touched && intermedimage.controls['cmd_164'].invalid">
                                                <small class="text-danger" *ngIf="intermedimage.controls['cmd_164'].errors?.required">
                                                    required
                                                </small>
                                            </div>
                                            <input type="radio" formControlName="cmd_164" (blur)="handleNAchange('cmd_163')"
                                                [class.is-invalid]="(intermedimage.controls['cmd_164'].touched && intermedimage.controls['cmd_164'].invalid) || (intermedimage.controls['cmd_164'].invalid && isSubmit)"
                                                id="cmd_164" name="cmd_164" value="0">
                                            <label>No</label>
                                            <div class="formLabel col-md-11"
                                                *ngIf="intermedimage.controls['cmd_164'].touched && intermedimage.controls['cmd_164'].invalid">
                                                <small class="text-danger" *ngIf="intermedimage.controls['cmd_164'].errors?.required">
                                                    required
                                                </small>
                                            </div>
                                            <span
                                                *ngIf="(intermedimage.controls['cmd_164'].touched && intermedimage.controls['cmd_164'].invalid) || (intermedimage.controls['cmd_164'].invalid && isSubmit)">
                                                <i class="fas fa-times ml-2 text-danger"></i>
                                            </span>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr class="col-12">
                                <td colspan="6">
                                    <div class="row">
                                        <div class="col-3">
                                            <label for="1170">Institution:<span *ngIf="intermedimage.controls['1170'].invalid ||intermedimage.controls['cmd_0163'].value==null||intermedimage.controls['cmd_0163'].value=='' " class="mandatory">*</span></label>
                                        </div>
                                        <div class="col-6">
                                            <input type="text" (blur)="handleNAchange('cmd_0163')"
                                                [class.is-invalid]="
                                               intermedimage.controls['1170'].invalid"
                                                placeholder="Additional Residency Schools " id="1170" formControlName="1170" class="form-control">
                                            <div class="invalid-feedback"
                                                *ngIf="(intermedimage.controls['1170'].touched && intermedimage.controls['1170'].invalid) || (intermedimage.controls['1170'].invalid && isSubmit)">
                                                Required </div>
                                        </div>
                                        
                                    </div>
                                </td>
                                <td colspan="6">
                                    <div class="row">
                                        <div class="col-3">
                                            <label for="1181">Program Director:<span *ngIf="intermedimage.controls['1181'].invalid ||intermedimage.controls['cmd_0163'].value==null||intermedimage.controls['cmd_0163'].value=='' " class="mandatory">*</span></label>
                                        </div>
                                        <div class="col-5">
                                            <input type="text" (blur)="handleNAchange('cmd_0163')"
                                                [class.is-invalid]="
                                                  intermedimage.controls['1181'].invalid"
                                                placeholder="Additional Residency Director " id="1181" formControlName="1181" class="form-control">
                                            <div class="invalid-feedback"
                                                *ngIf="(intermedimage.controls['1181'].touched && intermedimage.controls['1181'].invalid) || (intermedimage.controls['1181'].invalid && isSubmit)">
                                                Required </div>
                                        </div>
                                        <div class="col-4">
                                            <label class="ml-4" for="cmd_0163"><b>Does Not Apply:</b></label>&nbsp;&nbsp;
                                            <input type="checkbox" value="Yes" formControlName="cmd_0163"
                                                (ngModelChange)="handleNAchange('cmd_0163')" id="cmd_0163">
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr class="col-12">
                                <td colspan="6">
                                    <div class="row">
                                        <div class="col-3 pr-0">
                                            <label for="1172">Mailing Address:<span *ngIf="intermedimage.controls['1172'].invalid ||intermedimage.controls['cmd_0163'].value==null||intermedimage.controls['cmd_0163'].value=='' " class="mandatory">*</span></label>
                                        </div>
                                        <div class="col-8 pl-0">
                                            <input type="text" (blur)="handleNAchange('cmd_0163')"
                                                [class.is-invalid]="
                                                   intermedimage.controls['1172'].invalid"
                                                placeholder="Additional Residency Address1 " id="1172" formControlName="1172" class="form-control">
                                            <div class="invalid-feedback"
                                                *ngIf="(intermedimage.controls['1172'].touched && intermedimage.controls['1172'].invalid) || (intermedimage.controls['1172'].invalid && isSubmit)">
                                                Required </div>
                                        </div>
                                        <div class="col-3 pr-0"></div>
                                        <div class="col-8 pl-0">
                                            <input type="text" (blur)="handleNAchange('cmd_0163')"
                                                [class.is-invalid]="
                                                                                                                                intermedimage.controls['1173'].invalid"
                                                placeholder="Additional Residency Address2" id="1173" formControlName="1173" class="form-control">
                                            <div class="invalid-feedback"
                                                *ngIf="(intermedimage.controls['1173'].touched && intermedimage.controls['1173'].invalid) || (intermedimage.controls['1173'].invalid && isSubmit)">
                                                Required </div>
                                        </div>
                                    </div>
                                </td>
                                <td colspan="6">
                                    <div class="row">
                                        <div class="col-3">
                                            <label for="1174">City:<span *ngIf="intermedimage.controls['1174'].invalid ||intermedimage.controls['cmd_0163'].value==null||intermedimage.controls['cmd_0163'].value=='' " class="mandatory">*</span></label>
                                        </div>
                                        <div class="col-8">
                                            <input type="text" (blur)="handleNAchange('cmd_0163')"
                                                [class.is-invalid]="
                                                   intermedimage.controls['1174'].invalid"
                                                placeholder="Additional Residency City " id="1174" formControlName="1174" class="form-control">
                                            <div class="invalid-feedback"
                                                *ngIf="(intermedimage.controls['1174'].touched && intermedimage.controls['1174'].invalid) || (intermedimage.controls['1174'].invalid && isSubmit)">
                                                Required </div>
                                        </div>
                                        <div class="col-3">
                                            <label for="1176">State:<span *ngIf="intermedimage.controls['1176'].invalid ||intermedimage.controls['cmd_0163'].value==null||intermedimage.controls['cmd_0163'].value=='' " class="mandatory">*</span></label>
                                        </div>
                                        <div class="col-8">
                                            <ng-select [items]="states" (blur)="handleNAchange('cmd_0163')"
                                                [class.is-invalid]="
                                                  intermedimage.controls['1176'].invalid"
                                                bindLabel="name" placeholder="Additional Residency State" bindValue="id" formControlName="1176"> </ng-select>
                                            <div class="invalid-feedback"
                                                *ngIf="(intermedimage.controls['1176'].touched && intermedimage.controls['1176'].invalid) || (intermedimage.controls['1176'].invalid && isSubmit)">
                                                State is required and cannot be empty </div>
                                        </div>
                                        <div class="col-3">
                                            <label for="1177">Zip Code:<span *ngIf="intermedimage.controls['1177'].invalid ||intermedimage.controls['cmd_0163'].value==null||intermedimage.controls['cmd_0163'].value=='' " class="mandatory">*</span></label>
                                        </div>
                                        <div class="col-8">
                                            <input type="text" (blur)="handleNAchange('cmd_0163')"
                                                [class.is-invalid]="
                                               intermedimage.controls['1177'].invalid"
                                                placeholder="Additional Residency Zip" id="1177" formControlName="1177" class="form-control">
                                            <div class="invalid-feedback"
                                                *ngIf="(intermedimage.controls['1177'].touched && intermedimage.controls['1177'].invalid) || (intermedimage.controls['1177'].invalid && isSubmit)">
                                                Required </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                           <tr class="col-12"> 
                                <td colspan="6">
                                    <div class="row">
                            
                                        <div class="col-3">
                                            <label for="1178">Start date(mm/dd/yy):<span *ngIf="intermedimage.controls['1178'].invalid ||intermedimage.controls['cmd_0163'].value==null||intermedimage.controls['cmd_0163'].value=='' " class="mandatory">*</span></label>
                                        </div>
                                        <div class="col-6 ">
                                            <div class="input-group">
                                            <input type="text" (blur)="handleNAchange('cmd_0163')" (blur)="clearInputIfInvalid('1178','cmd_0163')"
                                                [class.is-invalid]="
                                                                                                                                intermedimage.controls['1178'].invalid"
                                                placeholder="Additional Residency Start Date  " id="1178" formControlName="1178" class="form-control datepicker"
                                                data-id="priornameeffdate_date" ngbDatepicker #idh28="ngbDatepicker">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary calendar" (click)="idh28.toggle()" type="button"> <i
                                                        class="fas fa-calendar-alt"></i> </button>
                                            </div>
                                            </div>
                                            <div class="text-danger"
                                                *ngIf="intermedimage.controls['1178'].invalid && (intermedimage.controls['1178'].value==''|| intermedimage.controls['1178'].value==null) ">
                                                <small>
                                                    Required
                                                </small>
                                            
                                            </div>
                                            <div *ngIf="intermedimage.get('1178').invalid">
                                                <small class="text-danger" *ngIf="intermedimage.get('1178').errors.invalidDateFormat">
                                                    Only Date Format is allowed(MM/DD/YYYY) </small>
                                                <small class="text-danger" *ngIf="intermedimage.get('1178').errors.invalidMonth">
                                                    Month is out of range
                                                </small>
                                                <small class="text-danger" *ngIf="intermedimage.get('1178').errors.invalidDateyear">
                                                    year is out of range(1900-2099) </small>
                                                <small class="text-danger" *ngIf="intermedimage.get('1178').errors.invalidDateRange">
                                                    date is out of range
                                                </small>
                                            </div>
                            
                                        </div>
                                  </div>
                             </td>
                               <td colspan="6">
                                <div class="row">
                                        <div class="col-3">
                                            <label for="1180">Completion date(mm/dd/yy):<span *ngIf="intermedimage.controls['1180'].invalid ||intermedimage.controls['cmd_0163'].value==null||intermedimage.controls['cmd_0163'].value=='' " class="mandatory">*</span></label>
                                        </div>
                                        <div class="col-6 ">
                                            <div class="input-group">
                                            <input type="text" (blur)="handleNAchange('cmd_0163')" (blur)="clearInputIfInvalid('1180','cmd_0163')"
                                                [class.is-invalid]="
                                               intermedimage.controls['1180'].invalid"
                                                placeholder="Additional Residency End Date " id="1180" formControlName="1180" class="form-control datepicker"
                                                data-id="priornameeffdate_date" ngbDatepicker #idh29="ngbDatepicker">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary calendar" (click)="idh29.toggle()" type="button"> <i
                                                        class="fas fa-calendar-alt"></i> </button>
                                            </div>
                                            </div>
                                            <div class="text-danger"
                                            *ngIf="intermedimage.controls['1180'].invalid && (intermedimage.controls['1180'].value==''|| intermedimage.controls['1180'].value==null) ">
                                            <small>
                                                Required
                                            </small>
                                        
                                        </div>
                                        <div *ngIf="intermedimage.get('1180').invalid">
                                            <small class="text-danger" *ngIf="intermedimage.get('1180').errors.invalidDateFormat">
                                                Only Date Format is allowed(MM/DD/YYYY) </small>
                                            <small class="text-danger" *ngIf="intermedimage.get('1180').errors.invalidMonth">
                                                Month is out of range
                                            </small>
                                            <small class="text-danger" *ngIf="intermedimage.get('1180').errors.invalidDateyear">
                                                year is out of range(1900-2099) </small>
                                            <small class="text-danger" *ngIf="intermedimage.get('1180').errors.invalidDateRange">
                                                date is out of range
                                            </small>
                                        </div>
                            
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr class="col-12">
                                <td colspan="6">
                                    <div class="row">
                                        <div class="col-3">
                                            <label for="1320">Phone Number:<span *ngIf="intermedimage.controls['1320'].invalid ||intermedimage.controls['cmd_0163'].value==null||intermedimage.controls['cmd_0163'].value=='' " class="mandatory">*</span></label>
                                        </div>
                                        <div class="col-8">
                                            <input type="text" (blur)="handleNAchange('cmd_0163')"
                                                [class.is-invalid]="
                                                   intermedimage.controls['1320'].invalid"
                                                placeholder="Additional Residency Director Phone" id="1320" formControlName="1320" class="form-control"> <small
                                                class="text-danger" *ngIf="intermedimage.controls['1320'].errors?.pattern">
                                                Only number are allowed</small>
                                            <div class="invalid-feedback"
                                                *ngIf="(intermedimage.controls['1320'].touched && intermedimage.controls['1320'].invalid) || (intermedimage.controls['1320'].invalid && isSubmit)">
                                                Required </div>
                                        </div>
                                    </div>
                                </td>
                                <td colspan="6">
                                    <div class="row">
                                        <div class="col-3">
                                            <label for="1321">Fax Number:<span *ngIf="intermedimage.controls['1321'].invalid ||intermedimage.controls['cmd_0163'].value==null||intermedimage.controls['cmd_0163'].value=='' " class="mandatory">*</span></label>
                                        </div>
                                        <div class="col-8">
                                            <input type="text" (blur)="handleNAchange('cmd_0163')"
                                                [class.is-invalid]="
                                               intermedimage.controls['1321'].invalid"
                                                placeholder="Additional Residency Director Fax" id="1321" formControlName="1321" class="form-control">
                                            <div class="invalid-feedback"
                                                *ngIf="(intermedimage.controls['1321'].touched && intermedimage.controls['1321'].invalid) || (intermedimage.controls['1321'].invalid && isSubmit)">
                                                Required </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr class="col-12">
                                <td colspan="6">
                                    <div class="row">
                                        <div class="col-3">
                                            <label for="2541">Type of Residency:<span *ngIf="intermedimage.controls['2541'].invalid ||intermedimage.controls['cmd_0163'].value==null||intermedimage.controls['cmd_0163'].value=='' " class="mandatory">*</span></label>
                                        </div>
                                        <div class="col-8">
                                            <input type="text" (blur)="handleNAchange('cmd_0163')"
                                                [class.is-invalid]="
                                               intermedimage.controls['2541'].invalid"
                                                placeholder="Additional Residency Department " id="2541" formControlName="2541" class="form-control">
                                            <div class="invalid-feedback"
                                                *ngIf="(intermedimage.controls['2541'].touched && intermedimage.controls['2541'].invalid) || (intermedimage.controls['2541'].invalid && isSubmit)">
                                                Required </div>
                                        </div>
                                  </div>
                                </td>
                                 <td colspan="6">
                                   <div class="row">
                                        <div class="col-3">
                                            <label for="1171">Specialty:<span *ngIf="intermedimage.controls['1171'].invalid ||intermedimage.controls['cmd_0163'].value==null||intermedimage.controls['cmd_0163'].value=='' " class="mandatory">*</span></label>
                                        </div>
                                        <div class="col-8">
                                            <ng-select [items]="specialty" (blur)="handleNAchange('cmd_0163')"
                                                [class.is-invalid]="
                                                 intermedimage.controls['1171'].invalid"
                                                bindLabel="name" placeholder="Additional Residency  Primary Specialty  " bindValue="id" formControlName="1171"> </ng-select>
                                            <div class="invalid-feedback"
                                                *ngIf="(intermedimage.controls['1171'].touched && intermedimage.controls['1171'].invalid) || (intermedimage.controls['1171'].invalid && isSubmit)">
                                                primary Speciality is required and cannot be empty </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr class="col-12">
                                <td colspan="12">
                                    <div class="row">
                                        <label class="ml-3">Did you successfully complete the program?<span *ngIf="intermedimage.controls['cmd_165'].invalid ||intermedimage.controls['cmd_0163'].value==null||intermedimage.controls['cmd_0163'].value=='' " class="mandatory">*</span>
                                        </label>&nbsp;&nbsp;&nbsp;
                                        <div class="col-md-2">
                                            <input type="radio" formControlName="cmd_165" (blur)="handleNAchange('cmd_0163')"
                                                [class.is-invalid]="(intermedimage.controls['cmd_165'].touched && intermedimage.controls['cmd_165'].invalid) || (intermedimage.controls['cmd_165'].invalid && isSubmit)"
                                                id="cmd_165" name="cmd_165" value="1">
                                            <label>Yes&nbsp;&nbsp;</label>
                                            <div class="formLabel col-md-11"
                                                *ngIf="intermedimage.controls['cmd_165'].touched && intermedimage.controls['cmd_165'].invalid">
                                                <small class="text-danger" *ngIf="intermedimage.controls['cmd_165'].errors?.required">
                                                    required
                                                </small>
                                            </div>
                                            <input type="radio" formControlName="cmd_165" (blur)="handleNAchange('cmd_0163')"
                                                [class.is-invalid]="(intermedimage.controls['cmd_165'].touched && intermedimage.controls['cmd_165'].invalid) || (intermedimage.controls['cmd_165'].invalid && isSubmit)"
                                                id="cmd_165" name="cmd_165" value="0">
                                            <label>No</label>
                                            <div class="formLabel col-md-11"
                                                *ngIf="intermedimage.controls['cmd_165'].touched && intermedimage.controls['cmd_165'].invalid">
                                                <small class="text-danger" *ngIf="intermedimage.controls['cmd_165'].errors?.required">
                                                    required
                                                </small>
                                            </div>
                                            <span
                                                *ngIf="(intermedimage.controls['cmd_165'].touched && intermedimage.controls['cmd_165'].invalid) || (intermedimage.controls['cmd_165'].invalid && isSubmit)">
                                                <i class="fas fa-times ml-2 text-danger"></i>
                                            </span>
                                        </div>
                                    </div>
                                </td>
                            </tr> 
                      </div>
                   </td>
                </tr>
                   <tr class="col-12">
                    <td colspan="12">
                        <div class="table table-bordered" style="table-layout: fixed;">
                    <td colspan="12">
                        <label>
                            <h6>XI. FELLOWSHIPS ( Do not abbreviate ) (Attach additional sheet if necessary) </h6>
                        </label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="doesnotapply">
                            <label for="cmd_166"><b>Does Not Apply:</b></label>&nbsp;&nbsp;
                            <input type="checkbox" value="Yes" (ngModelChange)="handleNAchange('cmd_166')" formControlName="cmd_166"
                                id="cmd_166">
                        </span>
                    </td>
                <tr class="col-12">
                    <td colspan="6">
                        <div class="row">
                            <div class="col-3">
                                <label for="542">Institution:<span *ngIf="intermedimage.controls['542'].invalid ||intermedimage.controls['cmd_166'].value==null||intermedimage.controls['cmd_166'].value=='' " class="mandatory">*</span></label>
                            </div>
                            <div class="col-8">
                                <input type="text" (blur)="handleNAchange('cmd_166')"
                                    [class.is-invalid]="
                                                                                            intermedimage.controls['542'].invalid"
                                    placeholder="Fellowship School " id="542" formControlName="542" class="form-control">
                                <div class="invalid-feedback"
                                    *ngIf="(intermedimage.controls['542'].touched && intermedimage.controls['542'].invalid) || (intermedimage.controls['542'].invalid && isSubmit)">
                                    Required </div>
                            </div>
                        </div>
                    </td>
                    <td colspan="6">
                        <div class="row">
                            <div class="col-3">
                                <label for="553">Program Director:<span *ngIf="intermedimage.controls['553'].invalid ||intermedimage.controls['cmd_166'].value==null||intermedimage.controls['cmd_166'].value=='' " class="mandatory">*</span></label>
                            </div>
                            <div class="col-8">
                                <input type="text" placeholder="Fellowship Director" (blur)="handleNAchange('cmd_166')"
                                    [class.is-invalid]="
                                                                                            intermedimage.controls['553'].invalid" id="553" formControlName="553"
                                    class="form-control">
                                <div class="invalid-feedback"
                                    *ngIf="(intermedimage.controls['553'].touched && intermedimage.controls['553'].invalid) || (intermedimage.controls['553'].invalid && isSubmit)">
                                    Required </div>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr class="col-12">
                    <td colspan="5">
                        <div class="row">
                            <div class="col-3 pr-0">
                                <label for="544">Mailing Address:<span *ngIf="intermedimage.controls['544'].invalid ||intermedimage.controls['cmd_166'].value==null||intermedimage.controls['cmd_166'].value=='' " class="mandatory">*</span></label>
                            </div>
                            <div class="col-8 pl-0">
                                <input type="text" (blur)="handleNAchange('cmd_166')" placeholder="Fellowship Address 1"
                                    [class.is-invalid]="
                                                                                            intermedimage.controls['544'].invalid" id="544" formControlName="544"
                                    class="form-control">
                                <div class="invalid-feedback"
                                    *ngIf="(intermedimage.controls['544'].touched && intermedimage.controls['544'].invalid) || (intermedimage.controls['544'].invalid && isSubmit)">
                                    Required </div>
                            </div>
                            <div class="col-3 pr-0"></div>
                            <div class="col-8 pl-0">
                                <input type="text" placeholder="Fellowship Address 2" (blur)="handleNAchange('cmd_166')"
                                    [class.is-invalid]="
                                                                                            intermedimage.controls['545'].invalid" id="545" formControlName="545"
                                    class="form-control">
                                <div class="invalid-feedback"
                                    *ngIf="(intermedimage.controls['545'].touched && intermedimage.controls['545'].invalid) || (intermedimage.controls['545'].invalid && isSubmit)">
                                    Required </div>
                            </div>
                        </div>
                
                    </td>
                    <td colspan="7">
                        <div class="row">
                            <div class="col-3 pr-0">
                                <label for="546">City:<span *ngIf="intermedimage.controls['546'].invalid ||intermedimage.controls['cmd_166'].value==null||intermedimage.controls['cmd_166'].value=='' " class="mandatory">*</span></label>
                            </div>
                            <div class="col-8 pl-0">
                                <input type="text" placeholder="Fellowship City" (blur)="handleNAchange('cmd_166')"
                                    [class.is-invalid]="
                                                                                            intermedimage.controls['546'].invalid" id="546"
                                    formControlName="546" class="form-control">
                                <div class="invalid-feedback"
                                    *ngIf="(intermedimage.controls['546'].touched && intermedimage.controls['546'].invalid) || (intermedimage.controls['546'].invalid && isSubmit)">
                                    Required </div>
                            </div>
                            <div class="col-3 pr-0">
                                <label for="548">State:<span *ngIf="intermedimage.controls['548'].invalid ||intermedimage.controls['cmd_166'].value==null||intermedimage.controls['cmd_166'].value=='' " class="mandatory">*</span></label>
                            </div>
                            <div class="col-8 pl-0">
                                <ng-select [items]="states" (blur)="handleNAchange('cmd_166')" [class.is-invalid]="
                                                                                 intermedimage.controls['548'].invalid"
                                    bindLabel="name" placeholder="Fellowship  State" bindValue="id" formControlName="548"> </ng-select>
                                <div class="invalid-feedback"
                                    *ngIf="(intermedimage.controls['548'].touched && intermedimage.controls['548'].invalid) || (intermedimage.controls['548'].invalid && isSubmit)">
                                    Required </div>
                
                            </div>
                            <div class="col-3 pr-0">
                                <label for="549">Zip Code :<span *ngIf="intermedimage.controls['549'].invalid ||intermedimage.controls['cmd_166'].value==null||intermedimage.controls['cmd_166'].value=='' " class="mandatory">*</span></label>
                            </div>
                            <div class="col-8 pl-0">
                                <input type="text" placeholder="Fellowship  Zip" (blur)="handleNAchange('cmd_166')"
                                    [class.is-invalid]="
                                                                                            intermedimage.controls['549'].invalid" id="549" formControlName="549"
                                    class="form-control">
                                <div class="invalid-feedback"
                                    *ngIf="(intermedimage.controls['549'].touched && intermedimage.controls['549'].invalid) || (intermedimage.controls['549'].invalid && isSubmit)">
                                    Required </div>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr class="col-12">
                    <td colspan="6">
                        <div class="row">
                            <div class="col-3">
                                <label for="550">Start Date (mm/dd/yy):<span *ngIf="intermedimage.controls['550'].invalid ||intermedimage.controls['cmd_166'].value==null||intermedimage.controls['cmd_166'].value=='' " class="mandatory">*</span></label>
                            </div>
                            <div class="col-8 ">
                                <div class="input-group">
                                <input type="text" placeholder="Fellowship  Start Date" id="550" (blur)="handleNAchange('cmd_166')" (blur)="clearInputIfInvalid('550','cmd_166')" 
                                    [class.is-invalid]="
                                                                                            intermedimage.controls['550'].invalid" formControlName="550"
                                    class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker
                                    #idh30="ngbDatepicker">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="idh30.toggle()" type="button"> <i
                                            class="fas fa-calendar-alt"></i> </button>
                                </div>
                                </div>
                                <div class="text-danger"
                                    *ngIf="intermedimage.controls['550'].invalid && (intermedimage.controls['550'].value==''|| intermedimage.controls['550'].value==null) ">
                                    <small>
                                        Required
                                    </small>
                                
                                </div>
                                <div *ngIf="intermedimage.get('550').invalid">
                                    <small class="text-danger" *ngIf="intermedimage.get('550').errors.invalidDateFormat">
                                        Only Date Format is allowed(MM/DD/YYYY) </small>
                                    <small class="text-danger" *ngIf="intermedimage.get('550').errors.invalidMonth">
                                        Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="intermedimage.get('550').errors.invalidDateyear">
                                        year is out of range(1900-2099) </small>
                                    <small class="text-danger" *ngIf="intermedimage.get('550').errors.invalidDateRange">
                                        date is out of range
                                    </small>
                                </div>
                
                            </div>
                        </div>
                    </td>
                    <td colspan="6">
                        <div class="row">
                            <div class="col-3">
                                <label for="552">Completion Date (mm/dd/yy):<span *ngIf="intermedimage.controls['552'].invalid ||intermedimage.controls['cmd_166'].value==null||intermedimage.controls['cmd_166'].value=='' " class="mandatory">*</span></label>
                            </div>
                            <div class="col-6  ">
                                <div class="input-group">
                                <input type="text" placeholder="Fellowship Complete Date " (blur)="handleNAchange('cmd_166')" (blur)="clearInputIfInvalid('552','cmd_166')" 
                                    [class.is-invalid]="
                                                                                                    intermedimage.controls['552'].invalid" id="552" formControlName="552"
                                    class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker
                                    #idh31="ngbDatepicker">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="idh31.toggle()" type="button"> <i
                                            class="fas fa-calendar-alt"></i> </button>
                                </div>
                                </div>
                                <div class="text-danger"
                                    *ngIf="intermedimage.controls['552'].invalid && (intermedimage.controls['552'].value==''|| intermedimage.controls['552'].value==null) ">
                                    <small>
                                        Required
                                    </small>
                                
                                </div>
                                <div *ngIf="intermedimage.get('552').invalid">
                                    <small class="text-danger" *ngIf="intermedimage.get('552').errors.invalidDateFormat">
                                        Only Date Format is allowed(MM/DD/YYYY) </small>
                                    <small class="text-danger" *ngIf="intermedimage.get('552').errors.invalidMonth">
                                        Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="intermedimage.get('552').errors.invalidDateyear">
                                        year is out of range(1900-2099) </small>
                                    <small class="text-danger" *ngIf="intermedimage.get('552').errors.invalidDateRange">
                                        date is out of range
                                        </small>
</div>
                
                            </div>
                        </div>
                    </td>
                    </tr>
                    <tr class="12">
                
                
                    <td colspan="6">
                        <div class="row">
                            <div class="col-3">
                                <label for="1322">Phone:<span *ngIf="intermedimage.controls['1322'].invalid ||intermedimage.controls['cmd_166'].value==null||intermedimage.controls['cmd_166'].value=='' " class="mandatory">*</span></label>
                            </div>
                            <div class="col-8">
                                <input type="text" placeholder="Fellowship Phone" (blur)="handleNAchange('cmd_166')"
                                    [class.is-invalid]="
                                                                                            intermedimage.controls['1322'].invalid" id="1322" formControlName="1322"
                                    class="form-control"> <small class="text-danger"
                                    *ngIf="intermedimage.controls['1322'].errors?.pattern">
                                    Only number are allowed</small>
                                <div class="invalid-feedback"
                                    *ngIf="(intermedimage.controls['1322'].touched && intermedimage.controls['1322'].invalid) || (intermedimage.controls['1322'].invalid && isSubmit)">
                                    Required </div>
                            </div>
                        </div>
                    </td>
                
                    <td colspan="6">
                        <div class="row">
                            <div class="col-3">
                                <label for="1323">Fax:<span *ngIf="intermedimage.controls['1323'].invalid ||intermedimage.controls['cmd_166'].value==null||intermedimage.controls['cmd_166'].value=='' " class="mandatory">*</span></label>
                            </div>
                            <div class="col-8">
                                <input type="text" placeholder="Fellowship Fax" (blur)="handleNAchange('cmd_166')"
                                    [class.is-invalid]="
                                                                                            intermedimage.controls['1323'].invalid" id="1323" formControlName="1323"
                                    class="form-control">
                                <div class="invalid-feedback"
                                    *ngIf="(intermedimage.controls['1323'].touched && intermedimage.controls['1323'].invalid) || (intermedimage.controls['1323'].invalid && isSubmit)">
                                    Required </div>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr class="col-12">
                    <td colspan="12">
                        <div class="row">
                            <div class="col-2">
                                <label for="2542">Course of Study:<span *ngIf="intermedimage.controls['2542'].invalid ||intermedimage.controls['cmd_166'].value==null||intermedimage.controls['cmd_166'].value=='' " class="mandatory">*</span></label>
                            </div>
                            <div class="col-6">
                                <input type="text" placeholder="Fellowship Department " (blur)="handleNAchange('cmd_166')"
                                    [class.is-invalid]="
                                                                                            intermedimage.controls['2542'].invalid" id="2542" formControlName="2542"
                                    class="form-control">
                                <div class="invalid-feedback"
                                    *ngIf="(intermedimage.controls['2542'].touched && intermedimage.controls['2542'].invalid) || (intermedimage.controls['2542'].invalid && isSubmit)">
                                    Required </div>
                            </div>
                        </div>
                    </td>
                
                </tr>
                <tr class="col-12">
                    <td colspan="12">
                        <div class="row">
                            <label class="ml-3">Did you successfully complete the program?<span *ngIf="intermedimage.controls['cmd_167'].invalid ||intermedimage.controls['cmd_166'].value==null||intermedimage.controls['cmd_166'].value=='' " class="mandatory">*</span>
                            </label>&nbsp;&nbsp;&nbsp;
                            <div class="col-md-2">
                                <input type="radio" formControlName="cmd_167" (blur)="handleNAchange('cmd_166')"
                                    [class.is-invalid]="(intermedimage.controls['cmd_167'].touched && intermedimage.controls['cmd_167'].invalid) || (intermedimage.controls['cmd_167'].invalid && isSubmit)"
                                    id="cmd_167" name="cmd_167" value="1">
                                <label>Yes&nbsp;&nbsp;</label>
                                <div class="formLabel col-md-11"
                                    *ngIf="intermedimage.controls['cmd_167'].touched && intermedimage.controls['cmd_167'].invalid">
                                    <small class="text-danger" *ngIf="intermedimage.controls['cmd_167'].errors?.required">
                                        required
                                    </small>
                                </div>
                                <input type="radio" formControlName="cmd_167" (blur)="handleNAchange('cmd_166')"
                                    [class.is-invalid]="(intermedimage.controls['cmd_167'].touched && intermedimage.controls['cmd_167'].invalid) || (intermedimage.controls['cmd_167'].invalid && isSubmit)"
                                    id="cmd_167" name="cmd_167" value="0">
                                <label>No</label>
                                <div class="formLabel col-md-11"
                                    *ngIf="intermedimage.controls['cmd_167'].touched && intermedimage.controls['cmd_167'].invalid">
                                    <small class="text-danger" *ngIf="intermedimage.controls['cmd_167'].errors?.required">
                                        required
                                    </small>
                                </div>
                                <span
                                    *ngIf="(intermedimage.controls['cmd_167'].touched && intermedimage.controls['cmd_167'].invalid) || (intermedimage.controls['cmd_167'].invalid && isSubmit)">
                                    <i class="fas fa-times ml-2 text-danger"></i>
                                </span>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr class="col-12">
                    <td colspan="5">
                        <div class="row">
                            <div class="col-3">
                                <label for="966">Institution:<span *ngIf="intermedimage.controls['966'].invalid ||intermedimage.controls['cmd_00163'].value==null||intermedimage.controls['cmd_00163'].value=='' " class="mandatory">*</span></label>
                            </div>
                            <div class="col-8">
                                <input type="text" (blur)="handleNAchange('cmd_00163')"
                                    [class.is-invalid]="
                                                                                            intermedimage.controls['966'].invalid"
                                    placeholder="Additional Fellowship School " id="966" formControlName="966" class="form-control">
                                <div class="invalid-feedback"
                                    *ngIf="(intermedimage.controls['966'].touched && intermedimage.controls['966'].invalid) || (intermedimage.controls['966'].invalid && isSubmit)">
                                    Required
                                </div>
                            </div>
                        </div>
                    </td>
                    <td colspan="7">
                        <div class="row">
                            <div class="col-3">
                                <label for="983">Program Director:<span *ngIf="intermedimage.controls['983'].invalid ||intermedimage.controls['cmd_00163'].value==null||intermedimage.controls['cmd_00163'].value=='' " class="mandatory">*</span></label>
                            </div>
                            <div class="col-5">
                                <input type="text" placeholder="Additional Fellowship Director" (blur)="handleNAchange('cmd_00163')"
                                    [class.is-invalid]="
                                                                                            intermedimage.controls['983'].invalid" id="983" formControlName="983"
                                    class="form-control">
                                <div class="invalid-feedback"
                                    *ngIf="(intermedimage.controls['983'].touched && intermedimage.controls['983'].invalid) || (intermedimage.controls['983'].invalid && isSubmit)">
                                    Required
                                </div>
                            </div>
                            <div class="col-4">
                                <label class="ml-4" for="cmd_00163"><b>Does Not Apply:</b></label>&nbsp;&nbsp;
                                <input type="checkbox" value="Yes" formControlName="cmd_00163"
                                    (ngModelChange)="handleNAchange('cmd_00163')" id="cmd_00163">
                            </div>
                        </div>
                    </td>
                </tr>
                <tr class="col-12">
                    <td colspan="6">
                        <div class="row">
                            <div class="col-3 pr-0">
                                <label for="968">Mailing Address:<span *ngIf="intermedimage.controls['968'].invalid ||intermedimage.controls['cmd_00163'].value==null||intermedimage.controls['cmd_00163'].value=='' " class="mandatory">*</span></label>
                            </div>
                            <div class="col-8 pl-0">
                                <input type="text" placeholder="Additional Fellowship Address 1" (blur)="handleNAchange('cmd_00163')"
                                    [class.is-invalid]="
                                                                                            intermedimage.controls['968'].invalid" id="968" formControlName="968"
                                    class="form-control">
                                <div class="invalid-feedback"
                                    *ngIf="(intermedimage.controls['968'].touched && intermedimage.controls['968'].invalid) || (intermedimage.controls['968'].invalid && isSubmit)">
                                    Required </div>
                            </div>
                            <div class="col-3 pr-0"></div>
                            <div class="col-8 pl-0">
                                <input type="text" placeholder="Additional Fellowship Address 2" (blur)="handleNAchange('cmd_00163')"
                                    [class.is-invalid]="
                                                                                            intermedimage.controls['969'].invalid" id="969" formControlName="969"
                                    class="form-control">
                                <div class="invalid-feedback"
                                    *ngIf="(intermedimage.controls['969'].touched && intermedimage.controls['969'].invalid) || (intermedimage.controls['969'].invalid && isSubmit)">
                                    Required </div>
                            </div>
                        </div>
                    </td>
                    <td colspan="6">
                        <div class="row">
                            <div class="col-3 pr-0">
                                <label for="970">City:<span *ngIf="intermedimage.controls['970'].invalid ||intermedimage.controls['cmd_00163'].value==null||intermedimage.controls['cmd_00163'].value=='' " class="mandatory">*</span></label>
                            </div>
                            <div class="col-8 pl-0">
                                <input type="text" placeholder="Additional Fellowship city" id="970" (blur)="handleNAchange('cmd_00163')"
                                    [class.is-invalid]="
                                                                                            intermedimage.controls['970'].invalid" formControlName="970"
                                    class="form-control">
                                <div class="invalid-feedback"
                                    *ngIf="(intermedimage.controls['970'].touched && intermedimage.controls['970'].invalid) || (intermedimage.controls['970'].invalid && isSubmit)">
                                    Required </div>
                            </div>
                            <div class="col-3 pr-0">
                                <label for="972">State:<span *ngIf="intermedimage.controls['972'].invalid ||intermedimage.controls['cmd_00163'].value==null||intermedimage.controls['cmd_00163'].value=='' " class="mandatory">*</span></label>
                            </div>
                            <div class="col-8 pl-0">
                                <ng-select [items]="states" (blur)="handleNAchange('cmd_00163')" [class.is-invalid]="
                                                                                 intermedimage.controls['972'].invalid"
                                    bindLabel="name" placeholder="Additional Fellowship State" bindValue="id" formControlName="972"> </ng-select>
                                <div class="invalid-feedback"
                                    *ngIf="(intermedimage.controls['972'].touched && intermedimage.controls['972'].invalid) || (intermedimage.controls['972'].invalid && isSubmit)">
                                    Required </div>
                
                            </div>
                            <div class="col-3 pr-0">
                                <label for="1301">Zip Code:<span *ngIf="intermedimage.controls['1301'].invalid ||intermedimage.controls['cmd_00163'].value==null||intermedimage.controls['cmd_00163'].value=='' " class="mandatory">*</span></label>
                            </div>
                            <div class="col-8 pl-0">
                                <input type="text" placeholder="Additional Fellowship Zip" id="1301" (blur)="handleNAchange('cmd_00163')"
                                    [class.is-invalid]="
                                                                                            intermedimage.controls['1301'].invalid" formControlName="1301"
                                    class="form-control">
                                <div class="invalid-feedback"
                                    *ngIf="(intermedimage.controls['1301'].touched && intermedimage.controls['1301'].invalid) || (intermedimage.controls['1301'].invalid && isSubmit)">
                                    Required </div>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr class="col-12">
                    <td colspan="6">
                        <div class="row">
                            <div class="col-3">
                                <label for="974">Start Date (mm/dd/yy):<span *ngIf="intermedimage.controls['974'].invalid ||intermedimage.controls['cmd_00163'].value==null||intermedimage.controls['cmd_00163'].value=='' " class="mandatory">*</span></label>
                            </div>
                            <div class="col-8  ">
                                <div class="input-group">
                                <input type="text" placeholder="Additional Fellowship Start Date" id="974" (blur)="handleNAchange('cmd_00163')" (blur)="clearInputIfInvalid('974','cmd_00163')" 
                                    [class.is-invalid]="
                                                                                            intermedimage.controls['974'].invalid" formControlName="974"
                                    class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker
                                    #idh32="ngbDatepicker">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="idh32.toggle()" type="button"> <i
                                            class="fas fa-calendar-alt"></i> </button>
                                </div>
                                </div>
                                <div class="text-danger"
                                *ngIf="intermedimage.controls['974'].invalid && (intermedimage.controls['974'].value==''|| intermedimage.controls['974'].value==null) ">
                                <small>
                                    Required
                                </small>
                            
                            </div>
                            <div *ngIf="intermedimage.get('974').invalid">
                                <small class="text-danger" *ngIf="intermedimage.get('974').errors.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY) </small>
                                <small class="text-danger" *ngIf="intermedimage.get('974').errors.invalidMonth">
                                    Month is out of range
                                </small>
                                <small class="text-danger" *ngIf="intermedimage.get('974').errors.invalidDateyear">
                                    year is out of range(1900-2099) </small>
                                <small class="text-danger" *ngIf="intermedimage.get('974').errors.invalidDateRange">
                                    date is out of range
                                </small>
                            </div>
                
                            </div>
                        </div>
                    </td>
                    <td colspan="6">
                        <div class="row">
                            <div class="col-3">
                                <label for="982">Completion Date (mm/dd/yy):<span *ngIf="intermedimage.controls['982'].invalid ||intermedimage.controls['cmd_00163'].value==null||intermedimage.controls['cmd_00163'].value=='' " class="mandatory">*</span></label>
                            </div>
                            <div class="col-6  ">
                                <div class="input-group">
                                <input type="text" placeholder="Additional Fellowship Complete Date" (blur)="handleNAchange('cmd_00163')" (blur)="clearInputIfInvalid('982','cmd_00163')"
                                    [class.is-invalid]="
                                                                                            intermedimage.controls['982'].invalid" id="982" formControlName="982"
                                    class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker
                                    #idh33="ngbDatepicker">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="idh33.toggle()" type="button"> <i
                                            class="fas fa-calendar-alt"></i> </button>
                                </div>
                                </div>
                                <div class="text-danger"
                                *ngIf="intermedimage.controls['982'].invalid && (intermedimage.controls['982'].value==''|| intermedimage.controls['982'].value==null) ">
                                <small>
                                    Required
                                </small>
                            
                            </div>
                            <div *ngIf="intermedimage.get('982').invalid">
                                <small class="text-danger" *ngIf="intermedimage.get('982').errors.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY) </small>
                                <small class="text-danger" *ngIf="intermedimage.get('982').errors.invalidMonth">
                                    Month is out of range
                                </small>
                                <small class="text-danger" *ngIf="intermedimage.get('982').errors.invalidDateyear">
                                    year is out of range(1900-2099) </small>
                                <small class="text-danger" *ngIf="intermedimage.get('982').errors.invalidDateRange">
                                    date is out of range
                                </small>
                            </div>
                
                            </div>
                        </div>
                    </td>
                    </tr>
                    <tr class="12">
                    <td colspan="6">
                        <div class="row">
                            <div class="col-3">
                                <label for="1324">Phone :<span *ngIf="intermedimage.controls['1324'].invalid ||intermedimage.controls['cmd_00163'].value==null||intermedimage.controls['cmd_00163'].value=='' " class="mandatory">*</span></label>
                            </div>
                            <div class="col-8">
                                <input type="text" placeholder="Additional Fellowship Phone" (blur)="handleNAchange('cmd_00163')"
                                    [class.is-invalid]="
                                                                                            intermedimage.controls['1324'].invalid" id="1324" formControlName="1324"
                                    class="form-control"> <small class="text-danger"
                                    *ngIf="intermedimage.controls['1324'].errors?.pattern">
                                    Only number are allowed</small>
                                <div class="invalid-feedback"
                                    *ngIf="(intermedimage.controls['1324'].touched && intermedimage.controls['1324'].invalid) || (intermedimage.controls['1324'].invalid && isSubmit)">
                                    Required </div>
                            </div>
                        </div>
                    </td>
                
                    <td colspan="6">
                        <div class="row">
                            <div class="col-3">
                                <label for="1325">Fax:<span *ngIf="intermedimage.controls['1325'].invalid ||intermedimage.controls['cmd_00163'].value==null||intermedimage.controls['cmd_00163'].value=='' " class="mandatory">*</span></label>
                            </div>
                            <div class="col-8">
                                <input type="text" (blur)="handleNAchange('cmd_00163')"
                                    [class.is-invalid]="
                                                                                            intermedimage.controls['1325'].invalid"
                                    placeholder="Additional Fellowship Fax" id="1325" formControlName="1325" class="form-control">
                                <div class="invalid-feedback"
                                    *ngIf="(intermedimage.controls['1325'].touched && intermedimage.controls['1325'].invalid) || (intermedimage.controls['1325'].invalid && isSubmit)">
                                    Required </div>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr class="col-12">
                    <td colspan="12">
                        <div class="row">
                            <div class="col-2">
                                <label for="2543">Course of Study:<span *ngIf="intermedimage.controls['2543'].invalid ||intermedimage.controls['cmd_00163'].value==null||intermedimage.controls['cmd_00163'].value=='' " class="mandatory">*</span></label>
                            </div>
                            <div class="col-6">
                                <input type="text" placeholder="Additional Fellowship Department" (blur)="handleNAchange('cmd_00163')"
                                    [class.is-invalid]="
                                                                                            intermedimage.controls['2543'].invalid" id="2543" formControlName="2543"
                                    class="form-control">
                                <div class="invalid-feedback"
                                    *ngIf="(intermedimage.controls['2543'].touched && intermedimage.controls['2543'].invalid) || (intermedimage.controls['2543'].invalid && isSubmit)">
                                    Required </div>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr class="col-12">
                    <td colspan="12">
                        <div class="row">
                            <label class="ml-3">Did you successfully complete the program?<span *ngIf="intermedimage.controls['cmd_168'].invalid ||intermedimage.controls['cmd_00163'].value==null||intermedimage.controls['cmd_00163'].value=='' " class="mandatory">*</span>
                            </label>&nbsp;&nbsp;&nbsp;
                            <div class="col-md-2">
                                <input type="radio" formControlName="cmd_168" (blur)="handleNAchange('cmd_00163')"
                                    [class.is-invalid]="(intermedimage.controls['cmd_168'].touched && intermedimage.controls['cmd_168'].invalid) || (intermedimage.controls['cmd_168'].invalid && isSubmit)"
                                    id="cmd_168" name="cmd_168" value="1">
                                <label>Yes&nbsp;&nbsp;</label>
                                <div class="formLabel col-md-11"
                                    *ngIf="intermedimage.controls['cmd_168'].touched && intermedimage.controls['cmd_168'].invalid">
                                    <small class="text-danger" *ngIf="intermedimage.controls['cmd_168'].errors?.required">
                                        required
                                    </small>
                                </div>
                                <input type="radio" formControlName="cmd_168" (blur)="handleNAchange('cmd_00163')"
                                    [class.is-invalid]="(intermedimage.controls['cmd_168'].touched && intermedimage.controls['cmd_168'].invalid) || (intermedimage.controls['cmd_168'].invalid && isSubmit)"
                                    id="cmd_168" name="cmd_168" value="0">
                                <label>No</label>
                                <div class="formLabel col-md-11"
                                    *ngIf="intermedimage.controls['cmd_168'].touched && intermedimage.controls['cmd_168'].invalid">
                                    <small class="text-danger" *ngIf="intermedimage.controls['cmd_168'].errors?.required">
                                        required
                                    </small>
                                </div>
                                <span
                                    *ngIf="(intermedimage.controls['cmd_168'].touched && intermedimage.controls['cmd_168'].invalid) || (intermedimage.controls['cmd_168'].invalid && isSubmit)">
                                    <i class="fas fa-times ml-2 text-danger"></i>
                                </span>
                            </div>
                        </div>
                    </td>
                </tr>
                </div>
                </td>
                   </tr>
                   <tr class="col-12">
                    <td colspan="12">
                        <div class="table table-bordered" style="table-layout: fixed;">
                    <td colspan="12">
                        <label>
                            <h6>XII. PRECEPTORSHIP ( Do not abbreviate ) (Attach additional sheet if necessary) </h6>
                        </label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="doesnotapply">
                            <label for="cmd_169"><b>Does Not Apply:</b></label>&nbsp;&nbsp;
                            <input type="checkbox" value="Yes" (ngModelChange)="handleNAchange('cmd_169')" formControlName="cmd_169"
                                id="cmd_169">
                        </span>
                    </td>
                
                <tr class="col-12">
                    <td colspan="8">
                        <div class="row">
                            <div class="col-4">
                                <label for="2857">Institution:</label>
                            </div>
                            <div class="col-8">
                                <input type="text" placeholder="Additional Intern School " id="2857" formControlName="2857"
                                    class="form-control">
                            </div>
                        </div>
                    </td>
                    <td colspan="3">
                        <div class="row">
                            <div class="col-3 pr-0">
                                <label for="2869">Department Chairman:</label>
                            </div>
                            <div class="col-8 pl-0">
                                <input type="text" placeholder="Additional Intern Director " id="2869" formControlName="2869"
                                    class="form-control">
                            </div>
                        </div>
                    </td>
                </tr>
                <tr class="col-12">
                    <td colspan="5">
                        <div class="row">
                            <div class="col-4">
                                <label for="2859">Mailing address:</label>
                            </div>
                            <div class="col-8">
                                <input type="text" placeholder="Additional Intern Address 1 " id="2859" formControlName="2859" class="form-control">
                            </div>
                            <div class="col-4"></div>
                
                            <div class="col-8">
                                <input type="text" placeholder="Additional Intern Address 2" id="2860" formControlName="2860" class="form-control">
                            </div>
                
                        </div>
                    </td>
                
                
                    <td colspan="7">
                        <div class="row">
                            <div class="col-3 pr-0">
                                <label for="2861">City:</label>
                            </div>
                            <div class="col-8 ">
                                <input type="text" placeholder="Additional Intern City " id="2861" formControlName="2861" class="form-control">
                            </div>
                            <div class="col-3 pr-0">
                                <label for="2863">State:</label>
                            </div>
                            <div class="col-8 ">
                                <ng-select [items]="states" [class.is-invalid]="(intermedimage.controls['2863'].touched &&
                                                                            intermedimage.controls['2863'].invalid) ||
                                                                            (intermedimage.controls['2863'].invalid && isSubmit)"
                                    bindLabel="name" placeholder="Additional Intern State" bindValue="id" formControlName="2863"> </ng-select>
                            </div>
                            <div class="col-3 pr-0">
                                <label for="2864">Zip Code:</label>
                            </div>
                            <div class="col-8 ">
                                <input type="text" placeholder="Additional Intern Zip" id="2864" formControlName="2864" class="form-control">
                            </div>
                        </div>
                    </td>
                
                </tr>
                <tr class="col-12">
                    <td colspan="6">
                        <div class="row">
                            <div class="col-4">
                                <label for="2866">Start Date(mm/dd/yy):</label>
                            </div>
                            <div class="col-8 ">
                                <div class="input-group">
                                <input type="text" placeholder="Additional Intern Start " id="2866" formControlName="2866" (blur)="clearInputIfInvalid('2866')" 
                                    class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker
                                    #idh34="ngbDatepicker">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="idh34.toggle()" type="button"> <i
                                            class="fas fa-calendar-alt"></i> </button>
                                </div>
                                </div>
                                <div *ngIf="intermedimage.get('2866').invalid ">
                                    <small class="text-danger" *ngIf="intermedimage.get('2866').errors?.invalidDateFormat">
                                        Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="intermedimage.get('2866').errors?.invalidMonth">
                                        Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="intermedimage.get('2866').errors?.invalidDateyear">
                                        year is out of range(1900-2099) </small>
                                    <small class="text-danger" *ngIf="intermedimage.get('2866').errors?.invalidDateRange">
                                        date is out of range
                                    </small>
                                  </div>
                            </div>
                
                        </div>
                    </td>
                    <td colspan="6">
                        <div class="row">
                            <div class="col-3">
                                <label for="2866">Completion Date (mm/dd/yy):</label>
                            </div>
                            <div class="col-6 ">
                                <div class="input-group">
                                <input type="text" placeholder="Additional Intern Completion " id="2868" formControlName="2868"  (blur)="clearInputIfInvalid('2868')" 
                                    class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker
                                    #idh35="ngbDatepicker">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="idh35.toggle()" type="button"> <i
                                            class="fas fa-calendar-alt"></i> </button>
                                </div>
                                </div>
                                <div *ngIf="intermedimage.get('2868').invalid ">
                                    <small class="text-danger" *ngIf="intermedimage.get('2868').errors?.invalidDateFormat">
                                        Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="intermedimage.get('2868').errors?.invalidMonth">
                                        Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="intermedimage.get('2868').errors?.invalidDateyear">
                                        year is out of range(1900-2099) </small>
                                    <small class="text-danger" *ngIf="intermedimage.get('2868').errors?.invalidDateRange">
                                        date is out of range
                                    </small>
                                  </div>
                            </div>
                        </div>
                    </td>
                    </tr>
                    <tr class="12">
                    <td colspan="6">
                        <div class="row">
                            <div class="col-4 pr-0">
                                <label for="2871">Phone:</label>
                            </div>
                            <div class="col-8 pl-0">
                                <input type="text" placeholder="Additional Intern Director Phone " id="2871" formControlName="2871"
                                    class="form-control">
                                <small class="text-danger" *ngIf="intermedimage.controls['2871'].errors?.pattern">
                                    Only number are allowed</small>
                            </div>
                        </div>
                    </td>
                    <td colspan="6">
                        <div class="row">
                            <div class="col-3">
                                <label for="2872">Fax Number:</label>
                            </div>
                            <div class="col-8 d-flex">
                                <input type="text" placeholder="Additional Intern Director Fax " id="2872" formControlName="2872" class="form-control">
                            </div>
                        </div>
                    </td>
                </tr>
                <tr class="col-12">
                
                    <td colspan="12">
                        <div class="row">
                            <div class="col-2 pr-0">
                                <label for="2873">Training:</label>
                            </div>
                            <div class="col-6 pl-0">
                                <input type="text" placeholder="Additional Intern Department " id="2873" formControlName="2873" class="form-control">
                            </div>
                        </div>
                    </td>
                
                </tr>
                </div>
                </td>
                   </tr>
                   <tr class="col-12">
                    <td colspan="12">
                        <div class="table table-bordered" style="table-layout: fixed;">
                    <td colspan="12">
                        <label>
                            <h6>XIII. FACULTY APPOINTMENT ( Do not abbreviate ) (Attach additional sheet if necessary) </h6>
                        </label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="doesnotapply">
                            <label for="cmd_170"><b>Does Not Apply:</b></label>&nbsp;&nbsp;
                            <input type="checkbox" value="Yes" (ngModelChange)="handleNAchange('cmd_170')" formControlName="cmd_170"
                                id="cmd_170">
                        </span>
                    </td>
                <tr class="col-12">
                    <td colspan="8">
                        <div class="row">
                            <div class="col-4 ">
                                <label for="1146">Institution:</label>
                            </div>
                            <div class="col-8 ">
                                <input type="text" placeholder="Primary Institution Name " id="1146" formControlName="1146"
                                    class="form-control">
                            </div>
                        </div>
                    </td>
                    <td colspan="4">
                        <div class="row">
                            <div class="col-3">
                                <label for="1382">Faculty Director:</label>
                            </div>
                            <div class="col-8">
                                <input type="text" placeholder="Primary Institution Chairman " id="1382" formControlName="1382"
                                    class="form-control">
                            </div>
                        </div>
                    </td>
                </tr>
                <tr class="col-12">
                    <td colspan="5">
                        <div class="row">
                            <div class="col-4">
                                <label for="1148">Mailing address:</label>
                            </div>
                            <div class="col-8">
                                <input type="text" placeholder="Primary Institution Address1 " id="1148" formControlName="1148" class="form-control">
                            </div>
                            <div class="col-4"></div>
                
                            <div class="col-8">
                                <input type="text" placeholder="Primary Institution Address2" id="1149" formControlName="1149" class="form-control">
                            </div>
                        </div>
                    </td>
                    <td colspan="7">
                        <div class="row">
                            <div class="col-3 pr-0">
                                <label for="1150">City:</label>
                            </div>
                            <div class="col-8 ">
                                <input type="text" placeholder="Primary Institution City " id="1150" formControlName="1150" class="form-control">
                            </div>
                            <div class="col-3 pr-0">
                                <label for="1152">State:</label>
                            </div>
                            <div class="col-8 ">
                                <ng-select [items]="states" [class.is-invalid]="(intermedimage.controls['1152'].touched &&
                                                                                 intermedimage.controls['1152'].invalid) ||
                                                                                 (intermedimage.controls['1152'].invalid && isSubmit)"
                                    bindLabel="name" placeholder="Primary Institution State" bindValue="id" formControlName="1152"> </ng-select>
                            </div>
                            <div class="col-3 pr-0">
                                <label for="1153">Zip Code:</label>
                            </div>
                            <div class="col-8 ">
                                <input type="text" placeholder="Primary Institution Zip" id="1153" formControlName="1153" class="form-control">
                            </div>
                        </div>
                    </td>
                
                
                </tr>
                <tr class="col-12">
                    <td colspan="6">
                        <div class="row">
                            <div class="col-4 pr-0">
                                <label for="1155">Start Date(mm/dd/yy):</label>
                            </div>
                            <div class="col-8   ">
                                <div class="input-group">
                                <input type="text" placeholder="Primary Position Start Date " id="1155" formControlName="1155" (blur)="clearInputIfInvalid('1155')" 
                                    class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker
                                    #idh36="ngbDatepicker">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="idh36.toggle()" type="button"> <i
                                            class="fas fa-calendar-alt"></i> </button>
                                </div>
                                </div>
                                <div *ngIf="intermedimage.get('1155').invalid ">
                                    <small class="text-danger" *ngIf="intermedimage.get('1155').errors?.invalidDateFormat">
                                        Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="intermedimage.get('1155').errors?.invalidMonth">
                                        Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="intermedimage.get('1155').errors?.invalidDateyear">
                                        year is out of range(1900-2099) </small>
                                    <small class="text-danger" *ngIf="intermedimage.get('1155').errors?.invalidDateRange">
                                        date is out of range
                                    </small>
                                  </div>
                            </div>
                        </div>
                    </td>
                    <td colspan="6">
                        <div class="row">
                            <div class="col-3 d-flex ">
                                <label for="1155">Completion Date (mm/dd/yy):</label>
                            </div>
                            <div class="col-6  ">
                                <div class="input-group">
                                <input type="text" placeholder="Primary Position Term Date " id="1156" formControlName="1156" (blur)="clearInputIfInvalid('1156')" 
                                    class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker
                                    #idh37="ngbDatepicker">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="idh37.toggle()" type="button"> <i
                                            class="fas fa-calendar-alt"></i> </button>
                                </div>
                                </div>
                                <div *ngIf="intermedimage.get('1156').invalid ">
                                    <small class="text-danger" *ngIf="intermedimage.get('1156').errors?.invalidDateFormat">
                                        Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="intermedimage.get('1156').errors?.invalidMonth">
                                        Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="intermedimage.get('1156').errors?.invalidDateyear">
                                        year is out of range(1900-2099) </small>
                                    <small class="text-danger" *ngIf="intermedimage.get('1156').errors?.invalidDateRange">
                                        date is out of range
                                    </small>
                                  </div>
                            </div>
                        </div>
                    </td>
                    </tr>
                    <tr class="12">
                    <td colspan="6">
                        <div class="row">
                            <div class="col-4 pr-0">
                                <label for="4142">Phone:</label>
                            </div>
                            <div class="col-8 pl-0">
                                <input type="text" placeholder="Primary Institution Phone" id="4142" formControlName="4142"
                                    class="form-control"> <small class="text-danger"
                                    *ngIf="intermedimage.controls['4142'].errors?.pattern">
                                    Only number are allowed</small>
                            </div>
                        </div>
                    </td>
                    <td colspan="6">
                        <div class="row">
                            <div class="col-3 d-flex">
                                <label for="cmd_171">Fax Number:</label>
                            </div>
                            <div class="col-8 pl-0">
                                <input type="text" placeholder="Primary Institution Fax" id="cmd_171" formControlName="cmd_171"
                                    class="form-control">
                            </div>
                        </div>
                    </td>
                </tr>
                <tr class="col-12">
                    <td colspan="12">
                        <div class="row">
                            <div class="col-2 pr-0">
                                <label for="1154">Position:</label>
                            </div>
                            <div class="col-6 pl-0">
                                <input type="text" placeholder="Primary Position Rank or Title " id="1154" formControlName="1154" class="form-control">
                            </div>
                        </div>
                    </td>
                
                </tr>
                </div>
                </td>
                   </tr>
                   <tr class="col-12">
                    <td colspan="12">
                        <div class="table table-bordered" style="table-layout: fixed;">
                    <td colspan="12">
                        <label>
                            <h6>XIV. BOARD CERTIFICATION ( Do not abbreviate ) (Attach additional sheet if necessary) </h6>
                        </label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="doesnotapply">
                            <label for="cmd_173"><b>Does Not Apply:</b></label>&nbsp;&nbsp;
                            <input type="checkbox" value="Yes" (change)="handleNAchange('cmd_173')" formControlName="cmd_173"
                                id="cmd_173">
                        </span>
                    </td>
                <tr class="col-12">
                
                    <td colspan="12">
                        <label for="cmd_1007"><b>Are you board or otherwise professionally certified?</b></label>
                    </td>
                </tr>
                <tr class="col-12">
                
                    <td colspan="12">
                        <div class=" row">
                    <td class="col-4">
                        <input type="Radio" class="ml-4" value="1" name="cmd_174" formControlName="cmd_174" id="cmd_174"
                            (change)="BCRadioButtonYes($event)">&nbsp;&nbsp;&nbsp;
                        <label for="Yes"><b>Yes</b>&nbsp;If "Yes", please complete below</label>
                    </td>
                    <td class="col-8">
                        <input type="Radio" value="0" formControlName="cmd_174" name="cmd_174" id="cmd_174"
                            (change)="BCRadioButtonNo($event)">&nbsp;&nbsp;&nbsp;
                        <label for="No"><b>No</b>&nbsp;If "No", describe your intent for certification, if any, and dates of testing for
                            Certification on separate sheet.</label>
                        <!-- <span>
                
                            <input class="form-control  " id="cmd_0174" name="cmd_0174" type="text" [class.is-invalid]="(intermedimage.controls['cmd_0174'].touched &&
                                                               intermedimage.controls['cmd_0174'].invalid) ||
                                                               (intermedimage.controls['cmd_0174'].invalid && isSubmit)"
                                formControlName="cmd_0174">
                            <div class="formLabel col-md-11"
                                *ngIf="intermedimage.controls['cmd_0174'].touched && intermedimage.controls['cmd_0174'].errors">
                                <small class="text-danger" *ngIf="intermedimage.controls['cmd_0174'].errors?.required">
                                    required
                                </small>
                            </div>
                        </span> -->
                        <div *ngIf="bcRadioButtonNo">
                            <input class="form-control  " id="cmd_0174" name="cmd_0174" type="text" [class.is-invalid]="(intermedimage.controls['cmd_0174'].invalid)"  (blur)="handleNAchange('cmd_173')" formControlName="cmd_0174">
                            <div class="formLabel col-md-11"
                                *ngIf="intermedimage.controls['cmd_0174'].touched && intermedimage.controls['cmd_0174'].errors">
                                <small class="text-danger" *ngIf="intermedimage.controls['cmd_0174'].errors?.required">
                                  required
                              </small>
                            </div>
                        
                          </div>
                    </td>
                    </div>
                    </td>
                </tr>
                <tr class="col-12">
                    <td class="col-12">
                        <table style="width:100%" style="table-layout: auto;">
                            <tr class="col-12" style="table-layout: fixed;">
                                <th class="col-4">Primary Board Certification</th>
                                <th class="col-4">Secondary Board Certification</th>
                                <th class="col-4">Third Board Certification</th>
                            </tr>
                            <tr class="col-4" stye="width:100%" style="table-layout: fixed;">
                                <td class="col-4">
                                    <div class="row">
                                        <div class="col-3">
                                            <label for="554">Issuing Board/Entity:<span *ngIf="intermedimage.controls['554'].invalid ||intermedimage.controls['cmd_173'].value==null||intermedimage.controls['cmd_173'].value=='' " class="mandatory">*</span></label>
                                        </div>
                                        <div class="col-9">
                                            <ng-select [items]="boardCertification" 
                                            [class.is-invalid]="(intermedimage.controls['554'].invalid)"  	(blur)="handleNAchange('cmd_173')"  bindLabel="name"
                                                placeholder=" BoardCertification" bindValue="id" formControlName="554">
                                            </ng-select>
                
                                            <div class="invalid-feedback"
                                                *ngIf="(intermedimage.controls['554'].touched && intermedimage.controls['554'].invalid) || (intermedimage.controls['554'].invalid && isSubmit)">
                                                boardCertification is required and cannot be empty </div>
                                        </div>
                
                                    </div>
                                    <div class="row">
                                        <div class="col-3">
                                            <label for="cmd_175">State Issued:<span *ngIf="intermedimage.controls['cmd_175'].invalid ||intermedimage.controls['cmd_173'].value==null||intermedimage.controls['cmd_173'].value=='' " class="mandatory">*</span></label>
                                        </div>
                                        <div class="col-9">
                                            <ng-select [items]="states"   [class.is-invalid]="(intermedimage.controls['cmd_175'].invalid)" (blur)="handleNAchange('cmd_173')"
                                                bindLabel="name" placeholder=" State" bindValue="id" formControlName="cmd_175">
                                            </ng-select>
                                            <div class="invalid-feedback"
                                                *ngIf="(intermedimage.controls['cmd_175'].touched && intermedimage.controls['cmd_175'].invalid) || (intermedimage.controls['cmd_175'].invalid && isSubmit)">
                                                State is required and cannot be empty </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-3">
                                            <label for="556">Specialty:<span *ngIf="intermedimage.controls['556'].invalid ||intermedimage.controls['cmd_173'].value==null||intermedimage.controls['cmd_173'].value=='' " class="mandatory">*</span></label>
                                        </div>
                                        <div class="col-9">
                                            <ng-select [items]="specialty"  [class.is-invalid]="(intermedimage.controls['556'].invalid)" (blur)="handleNAchange('cmd_173')" 
                                                bindLabel="name" placeholder="Primary Specialty" bindValue="id" formControlName="556">
                                            </ng-select>
                                            <div class="invalid-feedback"
                                                *ngIf="(intermedimage.controls['556'].touched && intermedimage.controls['556'].invalid) || (intermedimage.controls['556'].invalid && isSubmit)">
                                                primary Speciality is required and cannot be empty </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-6">
                                            <label for="557">Date Certified:<span *ngIf="intermedimage.controls['557'].invalid ||intermedimage.controls['cmd_173'].value==null||intermedimage.controls['cmd_173'].value=='' " class="mandatory">*</span></label>
                                        </div>
                                        <div class="col-6 ">
                                            <div class="input-group">
                                            <input type="text" id="557"  formControlName="557" class="form-control datepicker"
                                                data-id="priornameeffdate_date" ngbDatepicker #idh38="ngbDatepicker"
                                                (blur)="handleNAchange('cmd_173')" (blur)="clearInputIfInvalid('557','cmd_173')"  [class.is-invalid]="(intermedimage.controls['557'].invalid ) ">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary calendar" (click)="idh38.toggle()"
                                                    type="button"> <i class="fas fa-calendar-alt"></i> </button>
                                            </div>
                                            </div>
                                            <div class="text-danger"
                                                *ngIf="intermedimage.controls['557'].invalid && (intermedimage.controls['557'].value==''|| intermedimage.controls['557'].value==null) ">
                                                <small>
                                                    Required
                                                </small>
                                            
                                            </div>
                                            <div *ngIf="intermedimage.get('557').invalid">
                                                <small class="text-danger" *ngIf="intermedimage.get('557').errors.invalidDateFormat">
                                                    Only Date Format is allowed(MM/DD/YYYY) </small>
                                                <small class="text-danger" *ngIf="intermedimage.get('557').errors.invalidMonth">
                                                    Month is out of range
                                                </small>
                                                <small class="text-danger" *ngIf="intermedimage.get('557').errors.invalidDateyear">
                                                    year is out of range(1900-2099) </small>
                                                <small class="text-danger" *ngIf="intermedimage.get('557').errors.invalidDateRange">
                                                    date is out of range
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-6">
                                            <label for="558">Date Recertified:<span *ngIf="intermedimage.controls['558'].invalid ||intermedimage.controls['cmd_173'].value==null||intermedimage.controls['cmd_173'].value=='' " class="mandatory">*</span></label>
                                        </div>
                                        <div class="col-6">
                                            <div class="input-group">
                                            <input type="text" id="558"  formControlName="558" class="form-control datepicker"
                                                data-id="priornameeffdate_date" ngbDatepicker #idh39="ngbDatepicker"
                                                (blur)="handleNAchange('cmd_173')"  (blur)="clearInputIfInvalid('558','cmd_173')"  [class.is-invalid]="( intermedimage.controls['558'].invalid )">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary calendar" (click)="idh39.toggle()"
                                                    type="button"> <i class="fas fa-calendar-alt"></i> </button>
                                            </div>
                                            </div>
                                            <div class="text-danger"
                                            *ngIf="intermedimage.controls['558'].invalid && (intermedimage.controls['558'].value==''|| intermedimage.controls['558'].value==null) ">
                                            <small>
                                                Required
                                            </small>
                                        
                                        </div>
                                        <div *ngIf="intermedimage.get('558').invalid">
                                            <small class="text-danger" *ngIf="intermedimage.get('558').errors.invalidDateFormat">
                                                Only Date Format is allowed(MM/DD/YYYY) </small>
                                            <small class="text-danger" *ngIf="intermedimage.get('558').errors.invalidMonth">
                                                Month is out of range
                                            </small>
                                            <small class="text-danger" *ngIf="intermedimage.get('558').errors.invalidDateyear">
                                                year is out of range(1900-2099) </small>
                                            <small class="text-danger" *ngIf="intermedimage.get('558').errors.invalidDateRange">
                                                date is out of range
                                            </small>
                                        </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-6 ">
                                            <label for="560">Expiration Date (if any):<span *ngIf="intermedimage.controls['560'].invalid ||intermedimage.controls['cmd_173'].value==null||intermedimage.controls['cmd_173'].value=='' " class="mandatory">*</span></label>
                                        </div>
                                        <div class="col-6 d-flex ">
                                            <!-- <div class="input-group"> -->
                                            <input type="text" id="560"  formControlName="560" class="form-control datepicker"
                                                data-id="priornameeffdate_date" ngbDatepicker #idh40="ngbDatepicker" style="width:100%"
                                                (blur)="handleNAchange('cmd_173')"   (blur)="clearInputIfInvalid('560','cmd_173')"  [class.is-invalid]="(intermedimage.controls['560'].invalid ) ">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary calendar" (click)="idh40.toggle()"
                                                    type="button"> <i class="fas fa-calendar-alt"></i> </button>
                                            <!-- </div> -->
                                            <!-- </div> -->
                                            
                                            </div>
                                                
                                        </div>
                                        <div class="col-6"></div>
                                        <div class="col-6">
                                            <div class="text-danger"
                                                *ngIf="intermedimage.controls['560'].invalid && (intermedimage.controls['560'].value==''|| intermedimage.controls['560'].value==null) ">
                                                <small>
                                                    Required
                                                </small>
                                            
                                            </div>
                                            <div *ngIf="intermedimage.get('560').invalid">
                                                <small class="text-danger" *ngIf="intermedimage.get('560').errors.invalidDateFormat">
                                                    Only Date Format is allowed(MM/DD/YYYY) </small>
                                                <small class="text-danger" *ngIf="intermedimage.get('560').errors.invalidMonth">
                                                    Month is out of range
                                                </small>
                                                <small class="text-danger" *ngIf="intermedimage.get('560').errors.invalidDateyear">
                                                    year is out of range(1900-2099) </small>
                                                <small class="text-danger" *ngIf="intermedimage.get('560').errors.invalidDateRange">
                                                    date is out of range
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td class="col-4">
                                    <div class="row">
                                        <div class="col-3">
                                            <label for="561">Issuing Board/Entity:</label>
                                        </div>
                                        <div class="col-9">
                                            <ng-select [items]="boardCertification" bindLabel="name"
                                                placeholder=" BoardCertification" bindValue="id" formControlName="561">
                                            </ng-select>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-3">
                                            <label for="cmd_176">State Issued:</label>
                                        </div>
                                        <div class="col-9">
                                            <ng-select [items]="states" bindLabel="name" placeholder=" State" bindValue="id"
                                                formControlName="cmd_176"> </ng-select>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-3">
                                            <label for="563">Specialty:</label>
                                        </div>
                                        <div class="col-9">
                                            <ng-select [items]="specialtySecondary" bindLabel="name" placeholder="Secondary Specialty"
                                                bindValue="id" formControlName="563"> </ng-select>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-5">
                                            <label for="564">Date Certified:</label>
                                        </div>
                                        <div class="col-7 ">
                                            <div class="input-group">
                                            <input type="text" id="564" formControlName="564" class="form-control datepicker" (blur)="clearInputIfInvalid('564')" 
                                                data-id="priornameeffdate_date" ngbDatepicker #idh41="ngbDatepicker">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary calendar" (click)="idh41.toggle()"
                                                    type="button"> <i class="fas fa-calendar-alt"></i> </button>
                                            </div>
                                            </div>
                                            <div *ngIf="intermedimage.get('564').invalid ">
                                                <small class="text-danger" *ngIf="intermedimage.get('564').errors?.invalidDateFormat">
                                                    Only Date Format is allowed(MM/DD/YYYY)
                                                </small>
                                                <small class="text-danger" *ngIf="intermedimage.get('564').errors?.invalidMonth">
                                                    Month is out of range
                                                </small>
                                                <small class="text-danger" *ngIf="intermedimage.get('564').errors?.invalidDateyear">
                                                    year is out of range(1900-2099) </small>
                                                <small class="text-danger" *ngIf="intermedimage.get('564').errors?.invalidDateRange">
                                                    date is out of range
                                                </small>
                                              </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-5">
                                            <label for="565">Date Recertified:</label>
                                        </div>
                                        <div class="col-7 ">
                                            <div class="input-group">
                                            <input type="text" id="565" formControlName="565" class="form-control datepicker" (blur)="clearInputIfInvalid('565')" 
                                                data-id="priornameeffdate_date" ngbDatepicker #idh42="ngbDatepicker">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary calendar" (click)="idh42.toggle()"
                                                    type="button"> <i class="fas fa-calendar-alt"></i> </button>
                                            </div>
                                            </div>
                                            <div *ngIf="intermedimage.get('565').invalid ">
                                                <small class="text-danger" *ngIf="intermedimage.get('565').errors?.invalidDateFormat">
                                                    Only Date Format is allowed(MM/DD/YYYY)
                                                </small>
                                                <small class="text-danger" *ngIf="intermedimage.get('565').errors?.invalidMonth">
                                                    Month is out of range
                                                </small>
                                                <small class="text-danger" *ngIf="intermedimage.get('565').errors?.invalidDateyear">
                                                    year is out of range(1900-2099) </small>
                                                <small class="text-danger" *ngIf="intermedimage.get('565').errors?.invalidDateRange">
                                                    date is out of range
                                                </small>
                                              </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-5">
                                            <label for="567">Expiration Date (if any):</label>
                                        </div>
                                        <div class="col-7 ">
                                            <div class="input-group">
                                            <input type="text" id="567" formControlName="567" class="form-control datepicker" (blur)="clearInputIfInvalid('567')" 
                                                data-id="priornameeffdate_date" ngbDatepicker #idh43="ngbDatepicker">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary calendar" (click)="idh43.toggle()"
                                                    type="button"> <i class="fas fa-calendar-alt"></i> </button>
                                            </div>
                                            </div>
                                            <div *ngIf="intermedimage.get('567').invalid ">
                                                <small class="text-danger" *ngIf="intermedimage.get('567').errors?.invalidDateFormat">
                                                    Only Date Format is allowed(MM/DD/YYYY)
                                                </small>
                                                <small class="text-danger" *ngIf="intermedimage.get('567').errors?.invalidMonth">
                                                    Month is out of range
                                                </small>
                                                <small class="text-danger" *ngIf="intermedimage.get('567').errors?.invalidDateyear">
                                                    year is out of range(1900-2099) </small>
                                                <small class="text-danger" *ngIf="intermedimage.get('567').errors?.invalidDateRange">
                                                    date is out of range
                                                </small>
                                              </div>
                                        </div>
                                    </div>
                                </td>
                                <td class="col-4">
                                    <div class="row">
                                        <div class="col-3">
                                            <label for="946">Issuing Board/Entity:</label>
                                        </div>
                                        <div class="col-9">
                                            <ng-select [items]="boardCertification" bindLabel="name"
                                                placeholder=" BoardCertification" bindValue="id" formControlName="946">
                                            </ng-select>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-3">
                                            <label for="cmd_177">State Issued:</label>
                                        </div>
                                        <div class="col-9">
                                            <ng-select [items]="states" bindLabel="name" placeholder=" State" bindValue="id"
                                                formControlName="cmd_177"> </ng-select>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-3">
                                            <label for="948">Specialty:</label>
                                        </div>
                                        <div class="col-9">
                                            <ng-select [items]="specialtyTer" bindLabel="name" placeholder="Third Specialty"
                                                bindValue="id" formControlName="948"> </ng-select>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-5">
                                            <label for="953">Date Certified:</label>
                                        </div>
                                        <div class="col-7 ">
                                            <div class="input-group">
                                            <input type="text" id="953" formControlName="953" class="form-control datepicker" (blur)="clearInputIfInvalid('953')" 
                                                data-id="priornameeffdate_date" ngbDatepicker #idh44="ngbDatepicker">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary calendar" (click)="idh44.toggle()"
                                                    type="button"> <i class="fas fa-calendar-alt"></i> </button>
                                            </div>
                                            </div>
                                            <div *ngIf="intermedimage.get('953').invalid ">
                                                <small class="text-danger" *ngIf="intermedimage.get('953').errors?.invalidDateFormat">
                                                    Only Date Format is allowed(MM/DD/YYYY)
                                                </small>
                                                <small class="text-danger" *ngIf="intermedimage.get('953').errors?.invalidMonth">
                                                    Month is out of range
                                                </small>
                                                <small class="text-danger" *ngIf="intermedimage.get('953').errors?.invalidDateyear">
                                                    year is out of range(1900-2099) </small>
                                                <small class="text-danger" *ngIf="intermedimage.get('953').errors?.invalidDateRange">
                                                    date is out of range
                                                </small>
                                              </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-5">
                                            <label for="950">Date Recertified:</label>
                                        </div>
                                        <div class="col-7 ">
                                            <div class="input-group">
                                            <input type="text" id="950" formControlName="950" class="form-control datepicker" (blur)="clearInputIfInvalid('950')" 
                                                data-id="priornameeffdate_date" ngbDatepicker #idh45="ngbDatepicker">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary calendar" (click)="idh45.toggle()"
                                                    type="button"> <i class="fas fa-calendar-alt"></i> </button>
                                            </div>
                                            </div>
                                            <div *ngIf="intermedimage.get('950').invalid ">
                                                <small class="text-danger" *ngIf="intermedimage.get('950').errors?.invalidDateFormat">
                                                    Only Date Format is allowed(MM/DD/YYYY)
                                                </small>
                                                <small class="text-danger" *ngIf="intermedimage.get('950').errors?.invalidMonth">
                                                    Month is out of range
                                                </small>
                                                <small class="text-danger" *ngIf="intermedimage.get('950').errors?.invalidDateyear">
                                                    year is out of range(1900-2099) </small>
                                                <small class="text-danger" *ngIf="intermedimage.get('950').errors?.invalidDateRange">
                                                    date is out of range
                                                </small>
                                              </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-5">
                                            <label for="954">Expiration Date (if any):</label>
                                        </div>
                                        <div class="col-7 ">
                                            <div class="input-group">
                                            <input type="text" id="954" formControlName="954" class="form-control datepicker" (blur)="clearInputIfInvalid('954')" 
                                                data-id="priornameeffdate_date" ngbDatepicker #idh46="ngbDatepicker">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary calendar" (click)="idh46.toggle()"
                                                    type="button"> <i class="fas fa-calendar-alt"></i> </button>
                                            </div>
                                            </div>
                                            <div *ngIf="intermedimage.get('954').invalid ">
                                                <small class="text-danger" *ngIf="intermedimage.get('954').errors?.invalidDateFormat">
                                                    Only Date Format is allowed(MM/DD/YYYY)
                                                </small>
                                                <small class="text-danger" *ngIf="intermedimage.get('954').errors?.invalidMonth">
                                                    Month is out of range
                                                </small>
                                                <small class="text-danger" *ngIf="intermedimage.get('954').errors?.invalidDateyear">
                                                    year is out of range(1900-2099) </small>
                                                <small class="text-danger" *ngIf="intermedimage.get('954').errors?.invalidDateRange">
                                                    date is out of range
                                                </small>
                                              </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
                <tr class="col-12">
                    <td colspan="12">
                        <div class="row">
                            <label class="ml-3">Have you applied for certification other than those indicated
                                above?</label>&nbsp;&nbsp;&nbsp;
                            <input type="radio" id="cmd_179" name="cmd_179" formControlName="cmd_179" value="1">
                            <label>&nbsp;&nbsp;Yes</label>&nbsp;&nbsp;&nbsp;
                            <input type="radio" id="cmd_179" name="cmd_179" formControlName="cmd_179" value="0">
                            <label>&nbsp;&nbsp;No&nbsp;&nbsp;&nbsp;</label>
                        </div>
                    </td>
                </tr>
                <tr class="col-12">
                    <td colspan="12">
                        <div class="row">
                            <div class="col-4 pr-0">
                                <label for="cmd_180">If so, list certification and date:</label>
                            </div>
                            <div class="col-4 pl-0">
                                <input type="text" placeholder=" list certification " id="cmd_000355" formControlName="cmd_000355"
                                    class="form-control">
                            </div>
                            <div class="col-4 pl-0 ">
                                <div class="input-group">
                                <input type="text" placeholder=" Date" id="cmd_180" formControlName="cmd_180" (blur)="clearInputIfInvalid('cmd_180')" 
                                    class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker
                                    #idh47="ngbDatepicker">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="idh47.toggle()" type="button"> <i
                                            class="fas fa-calendar-alt"></i> </button>
                                </div>
                                </div>
                                <div *ngIf="intermedimage.get('cmd_180').invalid ">
                                    <small class="text-danger" *ngIf="intermedimage.get('cmd_180').errors?.invalidDateFormat">
                                        Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="intermedimage.get('cmd_180').errors?.invalidMonth">
                                        Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="intermedimage.get('cmd_180').errors?.invalidDateyear">
                                        year is out of range(1900-2099) </small>
                                    <small class="text-danger" *ngIf="intermedimage.get('cmd_180').errors?.invalidDateRange">
                                        date is out of range
                                    </small>
                                  </div>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr class="col-12">
                    <td colspan="12">
                        <div class="row">
                            <div class="col-8 pr-0">
                                <label for="cmd_182">If you participate in a specialty which does not have board certification, please
                                    indicate specialty:</label>
                            </div>
                            <div class="col-4 pl-0">
                                <input type="text" placeholder=" participate in a specialty" id="cmd_182" formControlName="cmd_182"
                                    class="form-control">
                            </div>
                        </div>
                    </td>
                </tr>
                </div>
                </td>
                   </tr>
                   <tr class="col-12">
                    <td colspan="12">
                        <div class="table table-bordered" style="table-layout: fixed;">
                    <td colspan="12">
                        <label>
                            <h6>XV. OTHER CERTIFICATIONS (Do not abbreviate ) (Attach additional sheet if necessary) ACLS, BLS, ATLS, PALS, NRP, NALS
                                (i.e., Fluoroscopy, Radiography, etc. – Attach certificate if applicable) </h6>
                        </label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="doesnotapply">
                            <label for="cmd_0173"><b>Does Not Apply:</b></label>&nbsp;&nbsp;
                            <input type="checkbox" value="Yes" (ngModelChange)="handleNAchange('cmd_0173')" formControlName="cmd_0173"
                                id="cmd_0173">
                        </span>
                    </td>
                <tr class="col-12">
                    <td colspan="4">
                        <div class="row">
                            <div class="col-4 pr-0 ">
                                <label for="674">Type:<span *ngIf="intermedimage.controls['674'].invalid ||intermedimage.controls['cmd_0173'].value==null||intermedimage.controls['cmd_0173'].value=='' " class="mandatory">*</span></label>
                            </div>
                            <div class="col-8 pl-0 ">
                                <ng-select [items]="mediCertifications"  (blur)="handleNAchange('cmd_0173')"bindLabel="name"
                                    [class.is-invalid]="
                                                                                            intermedimage.controls['674'].invalid" placeholder="Medical Certification " bindValue="id"
                                    formControlName="674"> </ng-select>
                            </div>
                            <div class="invalid-feedback"
                                *ngIf="(intermedimage.controls['674'].touched && intermedimage.controls['674'].invalid) || (intermedimage.controls['674'].invalid && isSubmit)">
                                Required
                            </div>
                
                        </div>
                    </td>
                    <td colspan="4">
                        <div class="row">
                            <div class="col-4 pr-0">
                                <label for="3270">Number:<span *ngIf="intermedimage.controls['3270'].invalid ||intermedimage.controls['cmd_0173'].value==null||intermedimage.controls['cmd_0173'].value=='' " class="mandatory">*</span></label>
                            </div>
                            <div class="col-8 pl-0 d-flex">
                                <input type="text" placeholder="Medical Certification Number " (blur)="handleNAchange('cmd_0173')"
                                    [class.is-invalid]="
                                                                                            intermedimage.controls['3270'].invalid" id="3270"
                                    formControlName="3270" class="form-control">
                            </div>
                            <div class="invalid-feedback"
                                *ngIf="(intermedimage.controls['3270'].touched && intermedimage.controls['3270'].invalid) || (intermedimage.controls['3270'].invalid && isSubmit)">
                                Required </div>
                
                        </div>
                    </td>
                    <td colspan="4">
                        <div class="row">
                            <div class="col-4 pr-0">
                                <label for="678">Expiration Date:<span *ngIf="intermedimage.controls['678'].invalid ||intermedimage.controls['cmd_0173'].value==null||intermedimage.controls['cmd_0173'].value=='' " class="mandatory">*</span></label>
                            </div>
                            <div class="col-8 pl-0 ">
                                <div class="input-group">
                                <input type="text" placeholder="Medical Certification Expiration Date " (blur)="handleNAchange('cmd_0173')" (blur)="clearInputIfInvalid('678','cmd_0173')" 
                                    [class.is-invalid]="
                                                                                            intermedimage.controls['678'].invalid" id="678" formControlName="678"
                                    class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker
                                    #idh48="ngbDatepicker">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="idh48.toggle()" type="button"> <i
                                            class="fas fa-calendar-alt"></i> </button>
                                </div>
                                </div>
                                <div class="text-danger"
                                *ngIf="intermedimage.controls['678'].invalid && (intermedimage.controls['678'].value==''|| intermedimage.controls['678'].value==null) ">
                                <small>
                                    Required
                                </small>
                            
                            </div>
                            <div *ngIf="intermedimage.get('678').invalid">
                                <small class="text-danger" *ngIf="intermedimage.get('678').errors.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY) </small>
                                <small class="text-danger" *ngIf="intermedimage.get('678').errors.invalidMonth">
                                    Month is out of range
                                </small>
                                <small class="text-danger" *ngIf="intermedimage.get('678').errors.invalidDateyear">
                                    year is out of range(1900-2099) </small>
                                <small class="text-danger" *ngIf="intermedimage.get('678').errors.invalidDateRange">
                                    date is out of range
                                </small>
                            </div>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr class="col-12">
                    <td colspan="4">
                        <div class="row">
                            <div class="col-4 pr-0">
                                <label for="add_1_674">Type:<span *ngIf="intermedimage.controls['add_1_674'].invalid ||intermedimage.controls['cmd_0173'].value==null||intermedimage.controls['cmd_0173'].value=='' " class="mandatory">*</span></label>
                            </div>
                            <div class="col-8 pl-0 ">
                                <ng-select [items]="mediCertifications" bindLabel="name" (blur)="handleNAchange('cmd_0173')"
                                    [class.is-invalid]="
                                                                                            intermedimage.controls['add_1_674'].invalid" placeholder="Medical Certification "
                                    bindValue="id" formControlName="add_1_674"> </ng-select>
                                    </div>
                                <div class="invalid-feedback"
                                    *ngIf="(intermedimage.controls['add_1_674'].touched && intermedimage.controls['add_1_674'].invalid) || (intermedimage.controls['add_1_674'].invalid && isSubmit)">
                                    Required </div>
                            </div>
                        <!-- </div> -->
                    </td>
                    <td colspan="4">
                        <div class="row">
                            <div class="col-4 pr-0">
                                <label for="add_1_3270">Number:<span *ngIf="intermedimage.controls['add_1_3270'].invalid ||intermedimage.controls['cmd_0173'].value==null||intermedimage.controls['cmd_0173'].value=='' " class="mandatory">*</span></label>
                            </div>
                            <div class="col-8 pl-0 d-flex">
                                <input type="text" placeholder="Medical Certification Number " (blur)="handleNAchange('cmd_0173')"
                                    [class.is-invalid]="
                                                                                            intermedimage.controls['add_1_3270'].invalid" id="add_1_3270"
                                    formControlName="add_1_3270" class="form-control">
                            </div>
                            <div class="invalid-feedback"
                                *ngIf="(intermedimage.controls['add_1_3270'].touched && intermedimage.controls['add_1_3270'].invalid) || (intermedimage.controls['add_1_3270'].invalid && isSubmit)">
                                Required </div>
                
                        </div>
                    </td>
                    <td colspan="4">
                        <div class="row">
                            <div class="col-4 pr-0">
                                <label for="add_1_678">Expiration Date:<span *ngIf="intermedimage.controls['add_1_678'].invalid ||intermedimage.controls['cmd_0173'].value==null||intermedimage.controls['cmd_0173'].value=='' " class="mandatory">*</span></label>
                            </div>
                            <div class="col-8 pl-0  ">
                                <div class="input-group">
                                <input type="text" placeholder="Medical Certification Expiration Date " (blur)="handleNAchange('cmd_0173')" (blur)="clearInputIfInvalid('add_1_678','cmd_0173')" 
                                    [class.is-invalid]="
                                                                                            intermedimage.controls['add_1_678'].invalid" id="add_1_678"
                                    formControlName="add_1_678" class="form-control datepicker" data-id="priornameeffdate_date"
                                    ngbDatepicker #idh49="ngbDatepicker">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="idh49.toggle()" type="button"> <i
                                            class="fas fa-calendar-alt"></i> </button>
                                </div>
                                </div>
                                <div class="text-danger"
                                *ngIf="intermedimage.controls['add_1_678'].invalid && (intermedimage.controls['add_1_678'].value==''|| intermedimage.controls['add_1_678'].value==null) ">
                                <small>
                                    Required
                                </small>
                            
                            </div>
                            <div *ngIf="intermedimage.get('add_1_678').invalid">
                                <small class="text-danger" *ngIf="intermedimage.get('add_1_678').errors.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY) </small>
                                <small class="text-danger" *ngIf="intermedimage.get('add_1_678').errors.invalidMonth">
                                    Month is out of range
                                </small>
                                <small class="text-danger" *ngIf="intermedimage.get('add_1_678').errors.invalidDateyear">
                                    year is out of range(1900-2099) </small>
                                <small class="text-danger" *ngIf="intermedimage.get('add_1_678').errors.invalidDateRange">
                                    date is out of range
                                </small>
                            </div>
                
                            </div>
                        </div>
                    </td>
                </tr>
                <tr class="col-12">
                    <td colspan="4">
                        <div class="row">
                            <div class="col-4 pr-0">
                                <label for="add_2_674">Type:<span *ngIf="intermedimage.controls['add_2_674'].invalid ||intermedimage.controls['cmd_0173'].value==null||intermedimage.controls['cmd_0173'].value=='' " class="mandatory">*</span></label>
                            </div>
                            <div class="col-8 pl-0">
                                <ng-select [items]="mediCertifications" (blur)="handleNAchange('cmd_0173')"
                                    [class.is-invalid]="
                                                                                            intermedimage.controls['add_2_674'].invalid" bindLabel="name"
                                    placeholder="Medical Certification " bindValue="id" formControlName="add_2_674"> </ng-select>
                            </div>
                            <div class="invalid-feedback"
                                *ngIf="(intermedimage.controls['add_2_674'].touched && intermedimage.controls['add_2_674'].invalid) || (intermedimage.controls['add_2_674'].invalid && isSubmit)">
                                Required </div>
                
                        </div>
                    </td>
                    <td colspan="4">
                        <div class="row">
                            <div class="col-4 pr-0">
                                <label for="add_2_3270">Number:<span *ngIf="intermedimage.controls['add_2_3270'].invalid ||intermedimage.controls['cmd_0173'].value==null||intermedimage.controls['cmd_0173'].value=='' " class="mandatory">*</span></label>
                            </div>
                            <div class="col-8 pl-0 d-flex">
                                <input type="text" placeholder="Medical Certification Number " (blur)="handleNAchange('cmd_0173')"
                                    [class.is-invalid]="
                                                                                            intermedimage.controls['add_2_3270'].invalid" id="add_2_3270"
                                    formControlName="add_2_3270" class="form-control">
                            </div>
                            <div class="invalid-feedback"
                                *ngIf="(intermedimage.controls['add_2_3270'].touched && intermedimage.controls['add_2_3270'].invalid) || (intermedimage.controls['add_2_3270'].invalid && isSubmit)">
                                Required </div>
                
                        </div>
                    </td>
                    <td colspan="4">
                        <div class="row">
                            <div class="col-4 pr-0">
                                <label for="add_2_678">Expiration Date:<span *ngIf="intermedimage.controls['add_2_678'].invalid ||intermedimage.controls['cmd_0173'].value==null||intermedimage.controls['cmd_0173'].value=='' " class="mandatory">*</span></label>
                            </div>
                            <div class="col-8 pl-0 ">
                                <div class="input-group">
                                <input type="text" placeholder="Medical Certification Expiration Date " (blur)="handleNAchange('cmd_0173')" (blur)="clearInputIfInvalid('add_2_678','cmd_0173')"
                                    [class.is-invalid]="
                                                                                            intermedimage.controls['add_2_678'].invalid" id="add_2_678"
                                    formControlName="add_2_678" class="form-control datepicker" data-id="priornameeffdate_date"
                                    ngbDatepicker #idh50="ngbDatepicker">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="idh50.toggle()" type="button"> <i
                                            class="fas fa-calendar-alt"></i> </button>
                                </div>
                                </div>
                                <div class="text-danger"
                                *ngIf="intermedimage.controls['add_2_678'].invalid && (intermedimage.controls['add_2_678'].value==''|| intermedimage.controls['add_2_678'].value==null) ">
                                <small>
                                    Required
                                </small>
                            
                            </div>
                            <div *ngIf="intermedimage.get('add_2_678').invalid">
                                <small class="text-danger" *ngIf="intermedimage.get('add_2_678').errors.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY) </small>
                                <small class="text-danger" *ngIf="intermedimage.get('add_2_678').errors.invalidMonth">
                                    Month is out of range
                                </small>
                                <small class="text-danger" *ngIf="intermedimage.get('add_2_678').errors.invalidDateyear">
                                    year is out of range(1900-2099) </small>
                                <small class="text-danger" *ngIf="intermedimage.get('add_2_678').errors.invalidDateRange">
                                    date is out of range
                                </small>
                            </div>
                
                            </div>
                        </div>
                    </td>
                </tr>
                <tr class="col-12">
                    <td colspan="4">
                        <div class="row">
                            <div class="col-4 pr-0">
                                <label for="add_3_674">Type:<span *ngIf="intermedimage.controls['add_3_674'].invalid ||intermedimage.controls['cmd_0173'].value==null||intermedimage.controls['cmd_0173'].value=='' " class="mandatory">*</span></label>
                            </div>
                            <div class="col-8 pl-0">
                                <ng-select [items]="mediCertifications" bindLabel="name" (blur)="handleNAchange('cmd_0173')"
                                    [class.is-invalid]="
                                                                                            intermedimage.controls['add_3_674'].invalid" placeholder="Medical Certification"
                                    bindValue="id" formControlName="add_3_674"> </ng-select>
                            </div>
                            <div class="invalid-feedback"
                                *ngIf="(intermedimage.controls['add_3_674'].touched && intermedimage.controls['add_3_674'].invalid) || (intermedimage.controls['add_3_674'].invalid && isSubmit)">
                                Required </div>
                
                        </div>
                    </td>
                    <td colspan="4">
                        <div class="row">
                            <div class="col-4 pr-0">
                                <label for="add_3_3270">Number:<span *ngIf="intermedimage.controls['add_3_3270'].invalid ||intermedimage.controls['cmd_0173'].value==null||intermedimage.controls['cmd_0173'].value=='' " class="mandatory">*</span></label>
                            </div>
                            <div class="col-8 pl-0">
                                <input type="text" placeholder="Medical Certification Number" (blur)="handleNAchange('cmd_0173')"
                                    [class.is-invalid]="
                                                                                            intermedimage.controls['add_3_3270'].invalid" id="add_3_3270"
                                    formControlName="add_3_3270" class="form-control">
                            </div>
                            <div class="invalid-feedback"
                                *ngIf="(intermedimage.controls['add_3_3270'].touched && intermedimage.controls['add_3_3270'].invalid) || (intermedimage.controls['add_3_3270'].invalid && isSubmit)">
                                Required </div>
                
                        </div>
                    </td>
                    <td colspan="4">
                        <div class="row">
                            <div class="col-4 pr-0">
                                <label for="add_3_678">Expiration Date:<span *ngIf="intermedimage.controls['add_3_678'].invalid ||intermedimage.controls['cmd_0173'].value==null||intermedimage.controls['cmd_0173'].value=='' " class="mandatory">*</span></label>
                            </div>
                            <div class="col-8 pl-0 ">
                                <div class="input-group">
                                <input type="text" placeholder="Medical Certification Expiration Date" (blur)="handleNAchange('cmd_0173')" (blur)="clearInputIfInvalid('add_3_678','cmd_0173')"
                                    [class.is-invalid]="
                                                                                            intermedimage.controls['add_3_678'].invalid" id="add_3_678"
                                    formControlName="add_3_678" class="form-control datepicker" data-id="priornameeffdate_date"
                                    ngbDatepicker #idh51="ngbDatepicker">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="idh51.toggle()" type="button"> <i
                                            class="fas fa-calendar-alt"></i> </button>
                                </div>
                                </div>
                                <div class="text-danger"
                                *ngIf="intermedimage.controls['add_3_678'].invalid && (intermedimage.controls['add_3_678'].value==''|| intermedimage.controls['add_3_678'].value==null) ">
                                <small>
                                    Required
                                </small>
                            
                            </div>
                            <div *ngIf="intermedimage.get('add_3_678').invalid">
                                <small class="text-danger" *ngIf="intermedimage.get('add_3_678').errors.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY) </small>
                                <small class="text-danger" *ngIf="intermedimage.get('add_3_678').errors.invalidMonth">
                                    Month is out of range
                                </small>
                                <small class="text-danger" *ngIf="intermedimage.get('add_3_678').errors.invalidDateyear">
                                    year is out of range(1900-2099) </small>
                                <small class="text-danger" *ngIf="intermedimage.get('add_3_678').errors.invalidDateRange">
                                    date is out of range
                                </small>
                            </div>
                
                
                            </div>
                        </div>
                    </td>
                </tr>
                </div>
                </td>
                   </tr>
                   <tr class="col-12">   
                    <td colspan="12">
                        <div class="table table-bordered" style="table-layout: fixed;">
                        <td colspan="12">
                            <label>
                                <h6>XVI. HOSPITAL AND OTHER INSTITUTIONAL AFFILIATIONS </h6>
                            </label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="doesnotapply">
                                <label for="cmd_187"><b>Does Not Apply:</b></label>&nbsp;&nbsp;
                                <input type="checkbox" value="Yes" (ngModelChange)="handleNAchange('cmd_187')" formControlName="cmd_187"
                                    id="cmd_187">
                            </span>
                        </td>
                <tr class="col-12">
                
                    <td colspan="12">
                        <label>Please list in <b>reverse chronological order (with the current affiliation(s)
                                first)</b>&nbsp;&nbsp;all institutions where you (A) have current affiliations, (B) applications in
                            process, (C) have had previous affiliations or, if no current affiliation, (D) have a current
                            coverage plan. This includes hospitals, surgery centers, institutions, corporations, military assignments,
                            or government
                            agencies. If more space is needed, attach additional sheet(s). List only affiliations here, list employment
                            in section XVII, Work History.</label>
                
                
                
                    </td>
                </tr>
                <tr class="col-12">
                    <td colspan="12">
                        <div class="row">
                            <div class="col-12 ">
                
                                <label><b>A. CURRENT  AFFILIATIONS ( Do not abbreviate ) (Attach additional sheet if
                                        necessary)</b></label>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr class="col-12">
                    <td colspan="12">
                        <div class="row">
                            <div class="col-6 pr-0">
                                <label for="418">Name of primary facility (Do you have admitting privileges)?<span *ngIf="intermedimage.controls['cmd_000339'].invalid ||intermedimage.controls['cmd_187'].value==null||intermedimage.controls['cmd_187'].value=='' " class="mandatory">*</span></label>
                
                                <input type="radio" formControlName="cmd_000339" (blur)="handleNAchange('cmd_187')"
                                    [class.is-invalid]="(intermedimage.controls['cmd_000339'].touched && intermedimage.controls['cmd_000339'].invalid) || (intermedimage.controls['cmd_000339'].invalid && isSubmit)"
                                    id="cmd_000339" name="cmd_000339" value="1">
                                <label>Yes&nbsp;&nbsp;</label>
                                <div class="formLabel col-md-11"
                                    *ngIf="intermedimage.controls['cmd_000339'].touched && intermedimage.controls['cmd_000339'].invalid">
                                    <small class="text-danger" *ngIf="intermedimage.controls['cmd_000339'].errors?.required">
                                        required
                                    </small>
                                </div>
                                <input type="radio" formControlName="cmd_000339" (blur)="handleNAchange('cmd_187')"
                                    [class.is-invalid]="(intermedimage.controls['cmd_000339'].touched && intermedimage.controls['cmd_000339'].invalid) || (intermedimage.controls['cmd_000339'].invalid && isSubmit)"
                                    id="cmd_000339" name="cmd_000339" value="0">
                                <label>No</label>
                                <div class="formLabel col-md-11"
                                    *ngIf="intermedimage.controls['cmd_000339'].touched && intermedimage.controls['cmd_000339'].invalid">
                                    <small class="text-danger" *ngIf="intermedimage.controls['cmd_000339'].errors?.required">
                                        required
                                    </small>
                                </div>
                                <span
                                    *ngIf="(intermedimage.controls['cmd_000339'].touched && intermedimage.controls['cmd_000339'].invalid) || (intermedimage.controls['cmd_000339'].invalid && isSubmit)">
                                    <i class="fas fa-times ml-2 text-danger"></i>
                                </span>
                
                
                            </div>
                
                
                            <div class="col-6 pl-0">
                                <input type="text" placeholder="Hospital Affiliation 1 Name " id="418" formControlName="418"
                                    class="form-control" (blur)="handleNAchange('cmd_187')"
                                    [class.is-invalid]="intermedimage.controls['418'].invalid ">
                                <div class="invalid-feedback"
                                    *ngIf="(intermedimage.controls['418'].touched && intermedimage.controls['418'].invalid) || (intermedimage.controls['418'].invalid && isSubmit)">
                                    Required </div>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr class="col-12">
                    <td colspan="3">
                        <div class="row">
                            <div class="col-3 ">
                                <label for="425">Department:<span *ngIf="intermedimage.controls['425'].invalid ||intermedimage.controls['cmd_187'].value==null||intermedimage.controls['cmd_187'].value=='' " class="mandatory">*</span></label>
                            </div>
                            <div class="col-12 ">
                                <input type="text"  placeholder="Hospital Affiliation 1 Department" id="425" formControlName="425" class="form-control"
                                    [class.is-invalid]="intermedimage.controls['425'].invalid ">
                                <div class="invalid-feedback"
                                    *ngIf="(intermedimage.controls['425'].touched && intermedimage.controls['425'].invalid) || (intermedimage.controls['425'].invalid && isSubmit)">
                                    Required </div>
                            </div>
                        </div>
                    </td>
                    <td colspan="5">
                        <div class="row">
                            <div class="col-7 ">
                                <label for="1304">Department/Clinical Chair:<span *ngIf="intermedimage.controls['1304'].invalid ||intermedimage.controls['cmd_187'].value==null||intermedimage.controls['cmd_187'].value=='' " class="mandatory">*</span></label>
                            </div>
                            <div class="col-12 ">
                                <input type="text" (blur)="handleNAchange('cmd_187')" placeholder="Hospital Affiliation 1 Department/Clinical Chair" id="1304" formControlName="1304" class="form-control"
                                    [class.is-invalid]=" intermedimage.controls['1304'].invalid ">
                                <div class="invalid-feedback"
                                    *ngIf="(intermedimage.controls['1304'].touched && intermedimage.controls['1304'].invalid) || (intermedimage.controls['1304'].invalid && isSubmit)">
                                    Required </div>
                            </div>
                        </div>
                    </td>
                    <td colspan="5">
                        <div class="row">
                            <div class="col-6 pr-0">
                                <label for="894">Status (active, provisional, courtesy, temporary, etc.):<span *ngIf="intermedimage.controls['894'].invalid ||intermedimage.controls['cmd_187'].value==null||intermedimage.controls['cmd_187'].value=='' " class="mandatory">*</span></label>
                            </div>
                            <div class="col-6 pl-0">
                                <ng-select [items]="staffStatus"  (blur)="handleNAchange('cmd_187')" [class.is-invalid]="
                                                                    intermedimage.controls['894'].invalid"
                                    bindLabel="name" formControlName="894" placeholder="Hospital Affiliation 1 Status" bindValue="id"> </ng-select>
                                <div class="invalid-feedback"
                                    *ngIf="(intermedimage.controls['894'].touched && intermedimage.controls['894'].invalid) || (intermedimage.controls['894'].invalid && isSubmit)">
                                    Status is required and cannot be empty </div>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr class="col-12">
                    <td colspan="5">
                        <div class="row">
                            <div class="col-4 pr-0">
                                <label for="419">Mailing Address:<span *ngIf="intermedimage.controls['419'].invalid ||intermedimage.controls['cmd_187'].value==null||intermedimage.controls['cmd_187'].value=='' " class="mandatory">*</span></label>
                            </div>
                            <div class="col-8 ">
                                <input type="text" (blur)="handleNAchange('cmd_187')" placeholder="Hospital Affiliation 1 Address 1" id="419" formControlName="419"
                                    class="form-control"
                                    [class.is-invalid]=" intermedimage.controls['419'].invalid ">
                                <div class="invalid-feedback"
                                    *ngIf="(intermedimage.controls['419'].touched && intermedimage.controls['419'].invalid) || (intermedimage.controls['419'].invalid && isSubmit)">
                                    Required </div>
                            </div>
                            <div class="col-4"></div>
                
                            <div class="col-8">
                                <input type="text"  (blur)="handleNAchange('cmd_187')" placeholder="Hospital Affiliation 1 Address 2" id="420" formControlName="420"
                                    class="form-control"
                                    [class.is-invalid]=" intermedimage.controls['420'].invalid ">
                                <div class="invalid-feedback"
                                    *ngIf="(intermedimage.controls['420'].touched && intermedimage.controls['420'].invalid) || (intermedimage.controls['420'].invalid && isSubmit)">
                                    Required </div>
                            </div>
                        </div>
                    </td>
                    <td colspan="7">
                        <div class="row">
                            <div class="col-3 pr-0">
                                <label for="421">City:<span *ngIf="intermedimage.controls['421'].invalid ||intermedimage.controls['cmd_187'].value==null||intermedimage.controls['cmd_187'].value=='' " class="mandatory">*</span></label>
                            </div>
                            <div class="col-8 pl-0">
                                <input type="text" (blur)="handleNAchange('cmd_187')"  placeholder="Hospital Affiliation 1 City" id="421" formControlName="421" class="form-control"
                                    [class.is-invalid]=" intermedimage.controls['421'].invalid ">
                                <div class="invalid-feedback"
                                    *ngIf="(intermedimage.controls['421'].touched && intermedimage.controls['421'].invalid) || (intermedimage.controls['421'].invalid && isSubmit)">
                                    Required </div>
                            </div>
                            <div class="col-3 pr-0">
                                <label for="423">State:<span *ngIf="intermedimage.controls['423'].invalid ||intermedimage.controls['cmd_187'].value==null||intermedimage.controls['cmd_187'].value=='' " class="mandatory">*</span></label>
                            </div>
                            <div class="col-8 pl-0">
                                <ng-select [items]="states"  (blur)="handleNAchange('cmd_187')" [class.is-invalid]="
                                                                    intermedimage.controls['423'].invalid"
                                    bindLabel="name" placeholder="Hospital Affiliation 1state" bindValue="id" formControlName="423"> </ng-select>
                                <div class="invalid-feedback"
                                    *ngIf="(intermedimage.controls['423'].touched && intermedimage.controls['423'].invalid) || (intermedimage.controls['423'].invalid && isSubmit)">
                                    State is required and cannot be empty </div>
                            </div>
                            <div class="col-3 pr-0">
                                <label for="424">Zip Code:<span *ngIf="intermedimage.controls['424'].invalid ||intermedimage.controls['cmd_187'].value==null||intermedimage.controls['cmd_187'].value=='' " class="mandatory">*</span></label>
                            </div>
                            <div class="col-8 pl-0">
                                <input type="text" (blur)="handleNAchange('cmd_187')" placeholder="Hospital Affiliation 1 Zip" id="424" formControlName="424" class="form-control"
                                    [class.is-invalid]=" intermedimage.controls['424'].invalid ">
                                <div class="invalid-feedback"
                                    *ngIf="(intermedimage.controls['424'].touched && intermedimage.controls['424'].invalid) || (intermedimage.controls['424'].invalid && isSubmit)">
                                    Required </div>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr class="col-12">
                    <td colspan="4">
                        <div class="row">
                            <div class="col-12 ">
                                <label for="1305">Phone Number:<span *ngIf="intermedimage.controls['1305'].invalid ||intermedimage.controls['cmd_187'].value==null||intermedimage.controls['cmd_187'].value=='' " class="mandatory">*</span></label>
                            </div>
                            <div class="col-12 ">
                                <input type="text"  (blur)="handleNAchange('cmd_187')" placeholder="Hospital Affiliation 1 Phone" id="1305" formControlName="1305"
                                    class="form-control"
                                    [class.is-invalid]=" intermedimage.controls['1305'].invalid ">
                                <div class="invalid-feedback"
                                    *ngIf="(intermedimage.controls['1305'].touched && intermedimage.controls['1305'].invalid) || (intermedimage.controls['1305'].invalid && isSubmit)">
                                    Required </div>
                            </div>
                        </div>
                    </td>
                    <td colspan="4">
                        <div class="row">
                            <div class="col-12 ">
                                <label for="2136">Fax Number:<span *ngIf="intermedimage.controls['2136'].invalid ||intermedimage.controls['cmd_187'].value==null||intermedimage.controls['cmd_187'].value=='' " class="mandatory">*</span></label>
                            </div>
                            <div class="col-12 ">
                                <input type="text" (blur)="handleNAchange('cmd_187')"  placeholder="Hospital Affiliation 1 Fax" id="2136" formControlName="2136" class="form-control"
                                    [class.is-invalid]="intermedimage.controls['2136'].invalid ">
                                <div class="invalid-feedback"
                                    *ngIf="(intermedimage.controls['2136'].touched && intermedimage.controls['2136'].invalid) || (intermedimage.controls['2136'].invalid && isSubmit)">
                                    Required </div>
                            </div>
                        </div>
                    </td>
                    <td colspan="5">
                        <div class="row">
                            <div class="col-4 pr-0">
                                <label for="426">Appointment Date (mm/dd/yy):<span *ngIf="intermedimage.controls['426'].invalid ||intermedimage.controls['cmd_187'].value==null||intermedimage.controls['cmd_187'].value=='' " class="mandatory">*</span></label>
                            </div>
                            <div class="col-8 pl-0  ">
                                <div class="input-group">
                                <input type="text" (blur)="handleNAchange('cmd_187')"  (blur)="clearInputIfInvalid('426','cmd_187')"   placeholder="Hospital Affiliation 1 Appointment Date" id="426" formControlName="426"
                                    class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker #idh52="ngbDatepicker"
                                    [class.is-invalid]="intermedimage.controls['426'].invalid ">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="idh52.toggle()" type="button"> <i
                                            class="fas fa-calendar-alt"></i> </button>
                                </div>
                                </div>
                                <div class="text-danger"
                                *ngIf="intermedimage.controls['426'].invalid && (intermedimage.controls['426'].value==''|| intermedimage.controls['426'].value==null) ">
                                <small>
                                    Required
                                </small>
                            
                            </div>
                            <div *ngIf="intermedimage.get('426').invalid">
                                <small class="text-danger" *ngIf="intermedimage.get('426').errors.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY) </small>
                                <small class="text-danger" *ngIf="intermedimage.get('426').errors.invalidMonth">
                                    Month is out of range
                                </small>
                                <small class="text-danger" *ngIf="intermedimage.get('426').errors.invalidDateyear">
                                    year is out of range(1900-2099) </small>
                                <small class="text-danger" *ngIf="intermedimage.get('426').errors.invalidDateRange">
                                    date is out of range
                                </small>
                            </div> <div class="invalid-feedback"
                                    *ngIf="(intermedimage.controls['426'].touched && intermedimage.controls['426'].invalid) || (intermedimage.controls['426'].invalid && isSubmit)">
                                    Required </div>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr class="col-12">
                    <td colspan="12">
                        <div class="row">
                            <div class="col-6 ">
                                <label for="427">Name of secondary facility (Do you have admitting privileges)?</label>
                
                                <input type="radio" formControlName="cmd_0339"
                                    [class.is-invalid]="(intermedimage.controls['cmd_0339'].touched && intermedimage.controls['cmd_0339'].invalid) || (intermedimage.controls['cmd_0339'].invalid && isSubmit)"
                                    id="cmd_0339" name="cmd_0339" value="1">
                                <label>Yes&nbsp;&nbsp;</label>
                                <div class="formLabel col-md-11"
                                    *ngIf="intermedimage.controls['cmd_0339'].touched && intermedimage.controls['cmd_0339'].invalid">
                                    <small class="text-danger" *ngIf="intermedimage.controls['cmd_0339'].errors?.required">
                                        required
                                    </small>
                                </div>
                                <input type="radio" formControlName="cmd_0339"
                                    [class.is-invalid]="(intermedimage.controls['cmd_0339'].touched && intermedimage.controls['cmd_0339'].invalid) || (intermedimage.controls['cmd_0339'].invalid && isSubmit)"
                                    id="cmd_0339" name="cmd_0339" value="0">
                                <label>No</label>
                                <div class="formLabel col-md-11"
                                    *ngIf="intermedimage.controls['cmd_0339'].touched && intermedimage.controls['cmd_0339'].invalid">
                                    <small class="text-danger" *ngIf="intermedimage.controls['cmd_0339'].errors?.required">
                                        required
                                    </small>
                                </div>
                                <span
                                    *ngIf="(intermedimage.controls['cmd_0339'].touched && intermedimage.controls['cmd_0339'].invalid) || (intermedimage.controls['cmd_0339'].invalid && isSubmit)">
                                    <i class="fas fa-times ml-2 text-danger"></i>
                                </span>
                
                
                            </div>
                            <div class="col-6 ">
                                <input type="text" placeholder="Hospital Affiliation 2 Name" id="427"
                                    formControlName="427" class="form-control">
                            </div>
                        </div>
                    </td>
                </tr>
                <tr class="col-12">
                    <td colspan="3">
                        <div class="row">
                            <div class="col-7 ">
                                <label for="434">Department:</label>
                            </div>
                            <div class="col-12 ">
                                <input type="text" placeholder="Hospital Affiliation 2 Department" id="434" formControlName="434" class="form-control">
                            </div>
                        </div>
                    </td>
                    <td colspan="5">
                        <div class="row">
                            <div class="col-7 ">
                                <label for="1306">Department/Clinical Chair:</label>
                            </div>
                            <div class="col-12 ">
                                <input type="text" placeholder="Hospital Affiliation 2 Department/Clinical Chair" id="1306" formControlName="1306" class="form-control"
                                    [class.is-invalid]="(intermedimage.controls['1306'].touched && intermedimage.controls['1306'].invalid ) || (intermedimage.controls['1306'].invalid && isSubmit)">
                                <div class="invalid-feedback"
                                    *ngIf="(intermedimage.controls['1306'].touched && intermedimage.controls['1306'].invalid) || (intermedimage.controls['1306'].invalid && isSubmit)">
                                    Required </div>
                            </div>
                        </div>
                    </td>
                    <td colspan="5">
                        <div class="row">
                            <div class="col-6 pr-0">
                                <label for="895">Status (active, provisional, courtesy, temporary, etc.)::</label>
                            </div>
                            <div class="col-6 pl-0">
                                <ng-select [items]="staffStatus" [class.is-invalid]="(intermedimage.controls['895'].touched &&
                                                                    intermedimage.controls['895'].invalid) ||
                                                                    (intermedimage.controls['895'].invalid && isSubmit)"
                                    bindLabel="name" formControlName="895" placeholder="Hospital Affiliation 2 status" bindValue="id"> </ng-select>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr class="col-12">
                    <td colspan="5">
                        <div class="row">
                            <div class="col-4 pr-0">
                                <label for="428">Mailing Address:</label>
                            </div>
                            <div class="col-8 ">
                                <input type="text" placeholder="Hospital Affiliation 2 Address 1" id="428" formControlName="428"
                                    class="form-control">
                            </div>
                            <div class="col-4 "></div>
                
                            <div class="col-8 ">
                                <input type="text" placeholder="Hospital Affiliation 2 Address 2" id="429" formControlName="429"
                                    class="form-control">
                            </div>
                        </div>
                    </td>
                    <td colspan="7">
                        <div class="row">
                            <div class="col-3 pr-0">
                                <label for="430">City:</label>
                            </div>
                            <div class="col-8 pl-0">
                                <input type="text" placeholder="Hospital Affiliation 2 City" id="430" formControlName="430" class="form-control">
                            </div>
                            <div class="col-3 pr-0">
                                <label for="432">State:</label>
                            </div>
                            <div class="col-8 pl-0">
                                <ng-select [items]="states" [class.is-invalid]="(intermedimage.controls['432'].touched &&
                                                                    intermedimage.controls['432'].invalid) ||
                                                                    (intermedimage.controls['432'].invalid && isSubmit)"
                                    bindLabel="name" placeholder="Hospital Affiliation 2 State" bindValue="id" formControlName="432"> </ng-select>
                            </div>
                            <div class="col-3 pr-0">
                                <label for="433">Zip Code:</label>
                            </div>
                            <div class="col-8 pl-0">
                                <input type="text" placeholder="Hospital Affiliation 2 Zip" id="433" formControlName="433" class="form-control">
                            </div>
                        </div>
                    </td>
                </tr>
                <tr class="col-12">
                    <td colspan="4">
                        <div class="row">
                            <div class="col-12 ">
                                <label for="1307">Phone Number:</label>
                            </div>
                            <div class="col-12 ">
                                <input type="text" placeholder="Hospital Affiliation 2 Phone" id="1307" formControlName="1307"
                                    class="form-control"> <small class="text-danger"
                                    *ngIf="intermedimage.controls['1307'].errors?.pattern">
                                    Only number are allowed</small>
                            </div>
                        </div>
                    </td>
                    <td colspan="3">
                        <div class="row">
                            <div class="col-12 ">
                                <label for="2137">Fax Number:</label>
                            </div>
                            <div class="col-12 ">
                                <input type="text" placeholder="Hospital Affiliation 2 Fax " id="2137" formControlName="2137"
                                    class="form-control">
                            </div>
                        </div>
                    </td>
                    <td colspan="5">
                        <div class="row">
                            <div class="col-4 pr-0">
                                <label for="435">Appointment Date (mm/dd/yy):</label>
                            </div>
                            <div class="col-8 ">
                                <div class="input-group">
                                <input type="text" placeholder="Hospital Affiliation 2 Appointment Date" id="435" formControlName="435" (blur)="clearInputIfInvalid('435')" 
                                    class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker
                                    #idh53="ngbDatepicker">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="idh53.toggle()" type="button"> <i
                                            class="fas fa-calendar-alt"></i> </button>
                                </div>
                                </div>
                                <div *ngIf="intermedimage.get('435').invalid ">
                                    <small class="text-danger" *ngIf="intermedimage.get('435').errors?.invalidDateFormat">
                                        Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="intermedimage.get('435').errors?.invalidMonth">
                                        Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="intermedimage.get('435').errors?.invalidDateyear">
                                        year is out of range(1900-2099) </small>
                                    <small class="text-danger" *ngIf="intermedimage.get('435').errors?.invalidDateRange">
                                        date is out of range
                                    </small>
                                  </div>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr class="col-12">
                    <td colspan="12">
                        <div class="row">
                            <div class="col-6 pr-0">
                                <label for="436">Name of other facility (Do you have admitting privileges)?</label>
                
                                <input type="radio" formControlName="cmd_00339"
                                    [class.is-invalid]="(intermedimage.controls['cmd_00339'].touched && intermedimage.controls['cmd_00339'].invalid) || (intermedimage.controls['cmd_00339'].invalid && isSubmit)"
                                    id="cmd_00339" name="cmd_00339" value="1">
                                <label>Yes&nbsp;&nbsp;</label>
                                <div class="formLabel col-md-11"
                                    *ngIf="intermedimage.controls['cmd_00339'].touched && intermedimage.controls['cmd_00339'].invalid">
                                    <small class="text-danger" *ngIf="intermedimage.controls['cmd_00339'].errors?.required">
                                        required
                                    </small>
                                </div>
                                <input type="radio" formControlName="cmd_00339"
                                    [class.is-invalid]="(intermedimage.controls['cmd_00339'].touched && intermedimage.controls['cmd_00339'].invalid) || (intermedimage.controls['cmd_00339'].invalid && isSubmit)"
                                    id="cmd_00339" name="cmd_00339" value="0">
                                <label>No</label>
                                <div class="formLabel col-md-11"
                                    *ngIf="intermedimage.controls['cmd_00339'].touched && intermedimage.controls['cmd_00339'].invalid">
                                    <small class="text-danger" *ngIf="intermedimage.controls['cmd_00339'].errors?.required">
                                        required
                                    </small>
                                </div>
                                <span
                                    *ngIf="(intermedimage.controls['cmd_00339'].touched && intermedimage.controls['cmd_00339'].invalid) || (intermedimage.controls['cmd_00339'].invalid && isSubmit)">
                                    <i class="fas fa-times ml-2 text-danger"></i>
                                </span>
                
                
                            </div>
                            <div class="col-6 pl-0">
                                <input type="text" placeholder="Hospital Affiliation 3 Name" id="436" formControlName="436"
                                    class="form-control">
                            </div>
                        </div>
                
                
                    </td>
                </tr>
                <tr class="col-12">
                    <td colspan="3">
                        <div class="row">
                            <div class="col-3 ">
                                <label for="443">Department:</label>
                            </div>
                            <div class="col-12 ">
                                <input type="text" placeholder="Hospital Affiliation 3 Department" id="443" formControlName="443" class="form-control">
                            </div>
                        </div>
                    </td>
                    <td colspan="5">
                        <div class="row">
                            <div class="col-7 ">
                                <label for="1308">Department/Clinical Chair:</label>
                            </div>
                            <div class="col-12 ">
                                <input type="text" placeholder="Hospital Affiliation 3 Department/Clinical Chair" id="1308" formControlName="1308" class="form-control"
                                    [class.is-invalid]="(intermedimage.controls['1308'].touched && intermedimage.controls['1308'].invalid ) || (intermedimage.controls['1308'].invalid && isSubmit)">
                                <div class="invalid-feedback"
                                    *ngIf="(intermedimage.controls['1308'].touched && intermedimage.controls['1308'].invalid) || (intermedimage.controls['1308'].invalid && isSubmit)">
                                    Required </div>
                            </div>
                        </div>
                    </td>
                    <td colspan="5">
                        <div class="row">
                            <div class="col-6 pr-0">
                                <label for="896">Status (active, provisional, courtesy, temporary, etc.):</label>
                            </div>
                            <div class="col-6 pl-0">
                                <ng-select [items]="staffStatus" [class.is-invalid]="(intermedimage.controls['896'].touched &&
                                                                    intermedimage.controls['896'].invalid) ||
                                                                    (intermedimage.controls['896'].invalid && isSubmit)"
                                    bindLabel="name" formControlName="896" placeholder=" status" bindValue="id"> </ng-select>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr class="col-12">
                    <td colspan="5">
                        <div class="row">
                            <div class="col-4 pr-0">
                                <label for="437">Mailing Address:</label>
                            </div>
                            <div class="col-8 ">
                                <input type="text" placeholder="Hospital Affiliation 3 Address 1" id="437" formControlName="437"
                                    class="form-control">
                            </div>
                            <div class="col-4 "></div>
                
                            <div class="col-8">
                                <input type="text" placeholder="Hospital Affiliation 3 Address 2" id="438" formControlName="438"
                                    class="form-control">
                            </div>
                        </div>
                    </td>
                    <td colspan="7">
                        <div class="row">
                            <div class="col-3">
                                <label for="439">City:</label>
                            </div>
                            <div class="col-8 pl-0">
                                <input type="text" placeholder="Hospital Affiliation 3 City" id="439" formControlName="439" class="form-control">
                            </div>
                            <div class="col-3">
                                <label for="441">State:</label>
                            </div>
                            <div class="col-8 pl-0">
                                <ng-select [items]="states" [class.is-invalid]="(intermedimage.controls['441'].touched &&
                                                                    intermedimage.controls['441'].invalid) ||
                                                                    (intermedimage.controls['441'].invalid && isSubmit)"
                                    bindLabel="name" placeholder="Hospital Affiliation 3 State" bindValue="id" formControlName="441"> </ng-select>
                            </div>
                            <div class="col-3">
                                <label for="442">Zip Code:</label>
                            </div>
                            <div class="col-8 pl-0">
                                <input type="text" placeholder="Hospital Affiliation 3 Zip" id="442" formControlName="442" class="form-control">
                            </div>
                        </div>
                    </td>
                </tr>
                <tr class="col-12">
                    <td colspan="4">
                        <div class="row">
                            <div class="col-12">
                                <label for="1309">Phone Number:</label>
                            </div>
                            <div class="col-12">
                                <input type="text" placeholder="Hospital Affiliation 3 Phone" id="1309" formControlName="1309"
                                    class="form-control"> <small class="text-danger"
                                    *ngIf="intermedimage.controls['1309'].errors?.pattern">
                                    Only number are allowed</small>
                            </div>
                        </div>
                    </td>
                    <td colspan="3">
                        <div class="row">
                            <div class="col-12">
                                <label for="2138">Fax Number:</label>
                            </div>
                            <div class="col-12">
                                <input type="text" placeholder="Hospital Affiliation 3 Fax  " id="2138" formControlName="2138"
                                    class="form-control">
                            </div>
                        </div>
                    </td>
                    <td colspan="5">
                        <div class="row">
                            <div class="col-4 pr-0">
                                <label for="444">Appointment Date (mm/dd/yy):</label>
                            </div>
                            <div class="col-8 pl-0 ">
                                <div class="input-group">
                                <input type="text" placeholder="Hospital Affiliation 3 Appointment Date" id="444" formControlName="444" (blur)="clearInputIfInvalid('444')" 
                                    class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker
                                    #idh54="ngbDatepicker">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="idh54.toggle()" type="button"> <i
                                            class="fas fa-calendar-alt"></i> </button>
                                </div>
                                </div>
                                <div *ngIf="intermedimage.get('444').invalid ">
                                    <small class="text-danger" *ngIf="intermedimage.get('444').errors?.invalidDateFormat">
                                        Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="intermedimage.get('444').errors?.invalidMonth">
                                        Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="intermedimage.get('444').errors?.invalidDateyear">
                                        year is out of range(1900-2099) </small>
                                    <small class="text-danger" *ngIf="intermedimage.get('444').errors?.invalidDateRange">
                                        date is out of range
                                    </small>
                                  </div>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr class="col-12">
                    <td colspan="12">
                        <label><b>B. APPLICATIONS IN PROCESS (Do not abbreviate)(Attach additional sheet if necessary)</b></label>
                    </td>
                </tr>
                <tr class="col-12">
                    <td colspan="12">
                        <div class="row">
                            <div class="col-2 pr-0">
                                <label for="cmd_234">Hospital/Institution:</label>
                            </div>
                            <div class="col-8 pl-0">
                                <input type="text" placeholder="Hospital/Institution" id="cmd_234" formControlName="cmd_234"
                                    class="form-control">
                            </div>
                        </div>
                    </td>
                </tr>
                <tr class="col-12">
                    <td colspan="5">
                        <div class="row">
                            <div class="col-4 pr-0">
                                <label for="cmd_237">Mailing Address:</label>
                            </div>
                            <div class="col-8 pl-0">
                                <input type="text" placeholder="  Mailing Address 1" id="cmd_237" formControlName="cmd_237"
                                    class="form-control">
                            </div>
                            <div class="col-4 pr-0"></div>
                            <div class="col-8 pl-0">
                                <input type="text" placeholder=" Mailing Address 2" id="cmd_238" formControlName="cmd_238"
                                    class="form-control">
                            </div>
                        </div>
                    </td>
                    <td colspan="7">
                        <div class="row">
                            <div class="col-3 pr-0">
                                <label for="cmd_239">City:</label>
                            </div>
                            <div class="col-8 pl-0">
                                <input type="text" placeholder="  City" id="cmd_239" formControlName="cmd_239" class="form-control">
                            </div>
                            <div class="col-3 pr-0">
                                <label for="cmd_240">State:</label>
                            </div>
                            <div class="col-8 pl-0">
                                <ng-select [items]="states" [class.is-invalid]="(intermedimage.controls['cmd_240'].touched &&
                                                 intermedimage.controls['cmd_240'].invalid) ||
                                                 (intermedimage.controls['cmd_240'].invalid && isSubmit)" bindLabel="name"
                                    placeholder="  State" bindValue="id" formControlName="cmd_240"> </ng-select>
                            </div>
                            <div class="col-3 pr-0">
                                <label for="cmd_241">Zip Code:</label>
                            </div>
                            <div class="col-8 pl-0">
                                <input type="text" placeholder="  Zip" id="cmd_241" formControlName="cmd_241" class="form-control">
                            </div>
                        </div>
                    </td>
                </tr>
                <tr class="col-12">
                    <td colspan="4">
                        <div class="row">
                            <div class="col-4 pr-0">
                                <label for="cmd_235">Phone Number:</label>
                            </div>
                            <div class="col-8 pl-0">
                                <input type="text" placeholder="  Phone Number" id="cmd_235" formControlName="cmd_235"
                                    class="form-control"> <small class="text-danger"
                                    *ngIf="intermedimage.controls['cmd_235'].errors?.pattern">
                                    Only number are allowed</small>
                            </div>
                        </div>
                    </td>
                    <td colspan="4">
                        <div class="row">
                            <div class="col-6 pr-0">
                                <label for="cmd_0235">Fax Number:</label>
                            </div>
                            <div class="col-6 pl-0">
                                <input type="text" placeholder="  Fax Number" id="cmd_0235" formControlName="cmd_0235"
                                    class="form-control">
                            </div>
                        </div>
                    </td>
                    <td colspan="4">
                        <div class="row">
                            <div class="col-6 pr-0">
                                <label for="cmd_236">Date Application Submitted(mm/dd/yy):</label>
                            </div>
                            <div class="col-6 pl-0">
                                <div class="input-group">
                                <input type="text" placeholder="  Date Application Submitted" id="cmd_236" formControlName="cmd_236" (blur)="clearInputIfInvalid('cmd_236')" 
                                    class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker
                                    #idh55="ngbDatepicker">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="idh55.toggle()" type="button"> <i
                                            class="fas fa-calendar-alt"></i> </button>
                                </div>
                                </div>
                                <div *ngIf="intermedimage.get('cmd_236').invalid ">
                                    <small class="text-danger" *ngIf="intermedimage.get('cmd_236').errors?.invalidDateFormat">
                                        Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="intermedimage.get('cmd_236').errors?.invalidMonth">
                                        Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="intermedimage.get('cmd_236').errors?.invalidDateyear">
                                        year is out of range(1900-2099) </small>
                                    <small class="text-danger" *ngIf="intermedimage.get('cmd_236').errors?.invalidDateRange">
                                        date is out of range
                                    </small>
                                  </div>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr class="col-12">
                    <td colspan="12">
                        <div class="row">
                            <div class="col-2 pr-0">
                                <label for="cmd_242">Hospital/Institution:</label>
                            </div>
                            <div class="col-8 pl-0">
                                <input type="text" placeholder="  Hospital/Institution" id="    cmd_242" formControlName="cmd_242"
                                    class="form-control">
                            </div>
                        </div>
                    </td>
                
                </tr>
                <tr class="col-12">
                    <td colspan="5">
                        <div class="row">
                            <div class="col-4 pr-0">
                                <label for="cmd_245">Mailing Address:</label>
                            </div>
                            <div class="col-8 pl-0">
                                <input type="text" placeholder="  Mailing Address 1" id="cmd_245" formControlName="cmd_245"
                                    class="form-control">
                            </div>
                            <div class="col-4 pr-0"></div>
                            <div class="col-8 pl-0">
                                <input type="text" placeholder="  Mailing Address 2" id="cmd_246" formControlName="cmd_246"
                                    class="form-control">
                            </div>
                        </div>
                    </td>
                    <td colspan="7">
                        <div class="row">
                            <div class="col-3 pr-0">
                                <label for="cmd_247">City:</label>
                            </div>
                            <div class="col-8 pl-0">
                                <input type="text" placeholder="  City" id="cmd_247" formControlName="cmd_247" class="form-control">
                            </div>
                            <div class="col-3 pr-0">
                                <label for="cmd_248">State:</label>
                            </div>
                            <div class="col-8 pl-0">
                                <ng-select [items]="states" [class.is-invalid]="(intermedimage.controls['cmd_248'].touched &&
                                                 intermedimage.controls['cmd_248'].invalid) ||
                                                 (intermedimage.controls['cmd_248'].invalid && isSubmit)" bindLabel="name"
                                    placeholder="  State" bindValue="id" formControlName="cmd_248"> </ng-select>
                            </div>
                            <div class="col-3 pr-0">
                                <label for="cmd_249">Zip Code:</label>
                            </div>
                            <div class="col-8 pl-0">
                                <input type="text" placeholder="  Zip" id="cmd_249" formControlName="cmd_249" class="form-control">
                            </div>
                        </div>
                    </td>
                </tr>
                <tr class="col-12">
                    <td colspan="4">
                        <div class="row">
                            <div class="col-4 pr-0">
                                <label for="cmd_243">Phone Number:</label>
                            </div>
                            <div class="col-8 pl-0">
                                <input type="text" placeholder="  Phone Number" id="cmd_243" formControlName="cmd_243"
                                    class="form-control"> <small class="text-danger"
                                    *ngIf="intermedimage.controls['cmd_243'].errors?.pattern">
                                    Only number are allowed</small>
                            </div>
                        </div>
                    </td>
                    <td colspan="4">
                        <div class="row">
                            <div class="col-6 pr-0">
                                <label for="cmd_0243">Fax Number:</label>
                            </div>
                            <div class="col-6 pl-0">
                                <input type="text" placeholder="  Fax Number" id="cmd_0243" formControlName="cmd_0243"
                                    class="form-control">
                            </div>
                        </div>
                    </td>
                    <td colspan="4">
                        <div class="row">
                            <div class="col-6 pr-0">
                                <label for="cmd_244">Date Application Submitted(mm/dd/yy):</label>
                            </div>
                            <div class="col-6 pl-0 ">
                                <div class="input-group">
                                <input type="text" placeholder="  Date Application Submitted" id="cmd_244" formControlName="cmd_244" (blur)="clearInputIfInvalid('cmd_244')" 
                                    class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker 
                                    #idh56="ngbDatepicker">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="idh56.toggle()" type="button"> <i
                                            class="fas fa-calendar-alt"></i> </button>
                                </div>
                                </div>
                                <div *ngIf="intermedimage.get('cmd_244').invalid ">
                                    <small class="text-danger" *ngIf="intermedimage.get('cmd_244').errors?.invalidDateFormat">
                                        Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="intermedimage.get('cmd_244').errors?.invalidMonth">
                                        Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="intermedimage.get('cmd_244').errors?.invalidDateyear">
                                        year is out of range(1900-2099) </small>
                                    <small class="text-danger" *ngIf="intermedimage.get('cmd_244').errors?.invalidDateRange">
                                        date is out of range
                                    </small>
                                  </div>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr class="col-12">
                    <td colspan="12">
                        <label for="9"><b>C. PREVIOUS AFFILIATIONS&nbsp;&nbsp;(Do not abbreviate) (Attach additional sheet if
                                necessary)</b></label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="doesnotapply">
                            <label for="cmd_059"><b>Does Not Apply:</b></label>&nbsp;&nbsp;
                            <input type="checkbox" (ngModelChange)="handleNAchange('cmd_059')" value="1" formControlName="cmd_059" id="cmd_059">
                        </span>
                    </td>
                </tr>
                <tr class="col-12">
                    <td colspan="12">
                        <div class="row">
                            <div class="col-2 pr-0">
                                <label for="1183">Name of facility:</label>
                            </div>
                            <div class="col-6 pl-0">
                                <input type="text" placeholder="  Previous Hospital Affiliation Name " formControlName="1183"
                                    id="1183>                                                                                                                                                                                                                                                                 "
                                    class="form-control">
                            </div>
                        </div>
                    </td>
                </tr>
                <tr class="col-12">
                    <td colspan="6">
                        <div class="row">
                            <div class="col-4 pr-0">
                                <label for="1190">Department:</label>
                            </div>
                            <div class="col-6 pl-0">
                                <input type="text" placeholder="  Department" id="1190" formControlName="1190" class="form-control">
                            </div>
                        </div>
                    </td>
                    <td colspan="6">
                        <div class="row">
                            <div class="col-4 pr-0">
                                <label for="1312">Department/Clinical Chair:</label>
                            </div>
                            <div class="col-7 pl-0">
                                <input type="text" placeholder="  Department/Clinical Chair" id="1312" formControlName="1312" class="form-control">
                            </div>
                        </div>
                    </td>
                </tr>
                <tr class="col-12">
                    <td colspan="5">
                        <div class="row">
                            <div class="col-4 pr-0">
                                <label for="1184">Mailing Address:</label>
                            </div>
                            <div class="col-8 pl-0">
                                <input type="text" placeholder="  Mailing Address 1" id="1184" formControlName="1184"
                                    class="form-control">
                            </div>
                            <div class="col-4 pr-0"></div>
                            <div class="col-8 pl-0">
                                <input type="text" placeholder="  Mailing Address 2" id="1185" formControlName="1185"
                                    class="form-control">
                            </div>
                        </div>
                    </td>
                    <td colspan="7">
                        <div class="row">
                            <div class="col-3 pr-0">
                                <label for="1186">City:</label>
                            </div>
                            <div class="col-8 pl-0">
                                <input type="text" placeholder="  City" id="1186" formControlName="1186" class="form-control">
                            </div>
                            <div class="col-3 pr-0">
                                <label for="1188">State:</label>
                            </div>
                            <div class="col-8 pl-0">
                                <ng-select [items]="states" [class.is-invalid]="(intermedimage.controls['1188'].touched &&
                                                                             intermedimage.controls['1188'].invalid) ||
                                                                             (intermedimage.controls['1188'].invalid && isSubmit)"
                                    bindLabel="name" placeholder="  State" bindValue="id" formControlName="1188"> </ng-select>
                            </div>
                            <div class="col-3 pr-0">
                                <label for="1189">Zip Code:</label>
                            </div>
                            <div class="col-8 pl-0">
                                <input type="text" placeholder="  Zip" id="1189" formControlName="1189" class="form-control">
                            </div>
                        </div>
                    </td>
                </tr>
                <tr class="col-12">
                    <td colspan="4">
                        <div class="row">
                            <div class="col-4 pr-0">
                                <label for="1313">Phone Number:</label>
                            </div>
                            <div class="col-8 pl-0">
                                <input type="text" placeholder="  Phone Number" id="1313" formControlName="1313"
                                    class="form-control"> <small class="text-danger"
                                    *ngIf="intermedimage.controls['1313'].errors?.pattern">
                                    Only number are allowed</small>
                            </div>
                        </div>
                    </td>
                    <td colspan="4">
                        <div class="row">
                            <div class="col-4 pr-0">
                                <label for="2140">Fax Number:</label>
                            </div>
                            <div class="col-8 pl-0">
                                <input type="text" placeholder="  Fax Number" id="2140" formControlName="2140" class="form-control">
                            </div>
                        </div>
                    </td>
                    <td colspan="6">
                        <div class="row">
                            <div class="col-6 pr-0">
                                <label for="1192">Previous Status (active, provisional, courtesy, temporary, etc.):</label>
                            </div>
                            <div class="col-6 pl-0">
                                <ng-select [items]="staffStatus" [class.is-invalid]="(intermedimage.controls['1192'].touched &&
                                                                             intermedimage.controls['1192'].invalid) ||
                                                                             (intermedimage.controls['1192'].invalid && isSubmit)"
                                    bindLabel="name" formControlName="1192" placeholder="  status" bindValue="id"> </ng-select>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr class="col-12">
                    <td colspan="6">
                        <div class="row">
                            <div class="col-3 pr-0">
                                <label for="1303">Reason for Leaving:</label>
                            </div>
                            <div class="col-8 pl-0">
                                <input type="text" placeholder="  Reason for Leaving" id="1303" formControlName="1303"
                                    class="form-control">
                            </div>
                        </div>
                    </td>
                    <td colspan="3">
                        <div class="row">
                            <div class="col-12 ">
                                <label for="1191">Appointment Date From (mm/dd/yy):</label>
                            </div>
                            <div class="col-10 ">
                                <div class="input-group">
                                <input type="text" placeholder="  From Date" id="1191" formControlName="1191" (blur)="clearInputIfInvalid('1191')" 
                                    class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker
                                    #idh57="ngbDatepicker">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="idh57.toggle()" type="button"> <i
                                            class="fas fa-calendar-alt"></i> </button>
                                </div>
                                </div>
                                <div *ngIf="intermedimage.get('1191').invalid ">
                                    <small class="text-danger" *ngIf="intermedimage.get('1191').errors?.invalidDateFormat">
                                        Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="intermedimage.get('1191').errors?.invalidMonth">
                                        Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="intermedimage.get('1191').errors?.invalidDateyear">
                                        year is out of range(1900-2099) </small>
                                    <small class="text-danger" *ngIf="intermedimage.get('1191').errors?.invalidDateRange">
                                        date is out of range
                                    </small>
                                  </div>
                            </div>
                        </div>
                    </td>
                    <td colspan="3">
                        <div class="row">
                            <div class="col-12">
                                <label for="2381"> To (mm/dd/yy):</label>
                            </div>
                            <div class="col-10 ">
                                <div class="input-group">
                                <input type="text" placeholder="  To Date" id="2381" formControlName="2381" (blur)="clearInputIfInvalid('2381')" 
                                    class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker 
                                    #idh58="ngbDatepicker">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="idh58.toggle()" type="button"> <i
                                            class="fas fa-calendar-alt"></i> </button>
                                </div>
                                </div>
                                <div *ngIf="intermedimage.get('2381').invalid ">
                                    <small class="text-danger" *ngIf="intermedimage.get('2381').errors?.invalidDateFormat">
                                        Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="intermedimage.get('2381').errors?.invalidMonth">
                                        Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="intermedimage.get('2381').errors?.invalidDateyear">
                                        year is out of range(1900-2099) </small>
                                    <small class="text-danger" *ngIf="intermedimage.get('2381').errors?.invalidDateRange">
                                        date is out of range
                                    </small>
                                  </div>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr class="col-12">
                    <td colspan="12">
                        <div class="row">
                            <div class="col-2 pr-0">
                                <label for="add_1_1183">Name of facility:</label>
                            </div>
                            <div class="col-6 pl-0">
                                <input type="text" placeholder="  Previous Hospital Affiliation Name " id="add_1_1183"
                                    formControlName="add_1_1183" class="form-control">
                            </div>
                        </div>
                    </td>
                </tr>
                <tr class="col-12">
                    <td colspan="6">
                        <div class="row">
                            <div class="col-4 pr-0">
                                <label for="add_1_1190">Department:</label>
                            </div>
                            <div class="col-6 pl-0">
                                <input type="text" placeholder="  Department" id="add_1_1190" formControlName="add_1_1190"
                                    class="form-control">
                            </div>
                        </div>
                    </td>
                    <td colspan="6">
                        <div class="row">
                            <div class="col-4 pr-0">
                                <label for="add_1_1312">Department/Clinical Chair:</label>
                            </div>
                            <div class="col-7 pl-0">
                                <input type="text" placeholder="  Department/Clinical Chair" id="add_1_1312" formControlName="add_1_1312"
                                    class="form-control">
                            </div>
                        </div>
                    </td>
                </tr>
                <tr class="col-12">
                    <td colspan="5">
                        <div class="row">
                            <div class="col-4 pr-0">
                                <label for="add_1_1184">Mailing Address:</label>
                            </div>
                            <div class="col-8 pl-0">
                                <input type="text" placeholder="  Mailing Address 1" id="add_1_1184" formControlName="add_1_1184"
                                    class="form-control">
                            </div>
                            <div class="col-4 pr-0"></div>
                            <div class="col-8 pl-0">
                                <input type="text" placeholder="  Mailing Address 2" id="add_1_1185" formControlName="add_1_1185"
                                    class="form-control">
                            </div>
                        </div>
                    </td>
                    <td colspan="7">
                        <div class="row">
                            <div class="col-3 pr-0">
                                <label for="add_1_1186">City:</label>
                            </div>
                            <div class="col-8 pl-0">
                                <input type="text" placeholder="  City" id="add_1_1186" formControlName="add_1_1186"
                                    class="form-control">
                            </div>
                            <div class="col-3 pr-0">
                                <label for="add_1_1188">State:</label>
                            </div>
                            <div class="col-8 pl-0">
                                <ng-select [items]="states"
                                    [class.is-invalid]="(intermedimage.controls['add_1_1188'].touched &&
                                                                             intermedimage.controls['add_1_1188'].invalid) ||
                                                                             (intermedimage.controls['add_1_1188'].invalid && isSubmit)" bindLabel="name"
                                    placeholder="  State" bindValue="id" formControlName="add_1_1188"> </ng-select>
                            </div>
                            <div class="col-3 pr-0">
                                <label for="add_1_1189">Zip Code:</label>
                            </div>
                            <div class="col-8 pl-0">
                                <input type="text" placeholder="  Zip" id="add_1_1189" formControlName="add_1_1189"
                                    class="form-control">
                            </div>
                        </div>
                    </td>
                </tr>
                <tr class="col-12">
                    <td colspan="4">
                        <div class="row">
                            <div class="col-4 pr-0">
                                <label for="add_1_1313">Phone Number:</label>
                            </div>
                            <div class="col-8 pl-0">
                                <input type="text" placeholder="  Phone Number" id="add_1_1313" formControlName="add_1_1313"
                                    class="form-control"> <small class="text-danger"
                                    *ngIf="intermedimage.controls['add_1_1313'].errors?.pattern">
                                    Only number are allowed</small>
                            </div>
                        </div>
                    </td>
                    <td colspan="4">
                        <div class="row">
                            <div class="col-4 pr-0">
                                <label for="add_1_2140">Fax Number:</label>
                            </div>
                            <div class="col-8 pl-0">
                                <input type="text" placeholder="  Fax Number" id="add_1_2140" formControlName="add_1_2140"
                                    class="form-control">
                            </div>
                        </div>
                    </td>
                    <td colspan="4">
                        <div class="row">
                            <div class="col-6 pr-0">
                                <label for="add_1_1192">Previous Status (active, provisional, courtesy, temporary, etc.):</label>
                            </div>
                            <div class="col-6 pl-0">
                                <ng-select [items]="staffStatus" bindLabel="name" formControlName="add_1_1192"
                                    placeholder="  status" bindValue="id"> </ng-select>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr class="col-12">
                    <td colspan="6">
                        <div class="row">
                            <div class="col-3 pr-0">
                                <label for="add_1_1303">Reason for Leaving:</label>
                            </div>
                            <div class="col-6 pl-0">
                                <input type="text" placeholder="  Reason for Leaving" id="add_1_1303" formControlName="add_1_1303"
                                    class="form-control">
                            </div>
                        </div>
                    </td>
                    <td colspan="3">
                        <div class="row">
                            <div class="col-12">
                                <label for="add_1_1191">Appointment Date From (mm/dd/yy):</label>
                            </div>
                            <div class="col-10 ">
                                <div class="input-group">
                                <input type="text" placeholder="  From Date" id="add_1_1191" formControlName="add_1_1191" (blur)="clearInputIfInvalid('add_1_1191')" 
                                    class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker
                                    #idh59="ngbDatepicker">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="idh59.toggle()" type="button"> <i
                                            class="fas fa-calendar-alt"></i> </button>
                                </div>
                                </div>
                                <div *ngIf="intermedimage.get('add_1_1191').invalid ">
                                    <small class="text-danger" *ngIf="intermedimage.get('add_1_1191').errors?.invalidDateFormat">
                                        Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="intermedimage.get('add_1_1191').errors?.invalidMonth">
                                        Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="intermedimage.get('add_1_1191').errors?.invalidDateyear">
                                        year is out of range(1900-2099) </small>
                                    <small class="text-danger" *ngIf="intermedimage.get('add_1_1191').errors?.invalidDateRange">
                                        date is out of range
                                    </small>
                                  </div>
                            </div>
                        </div>
                    </td>
                    <td colspan="3">
                        <div class="row">
                            <div class="col-12 ">
                                <label for="add_1_2381">To (mm/dd/yy):</label>
                            </div>
                            <div class="col-10 ">
                                <div class="input-group">
                                <input type="text" placeholder="  To Date" id="add_1_2381" formControlName="add_1_2381" (blur)="clearInputIfInvalid('add_1_2381')" 
                                    class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker
                                    #idh60="ngbDatepicker">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="idh60.toggle()" type="button"> <i
                                            class="fas fa-calendar-alt"></i> </button>
                                </div>
                                </div>
                                <div *ngIf="intermedimage.get('add_1_2381').invalid ">
                                    <small class="text-danger" *ngIf="intermedimage.get('add_1_2381').errors?.invalidDateFormat">
                                        Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="intermedimage.get('add_1_2381').errors?.invalidMonth">
                                        Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="intermedimage.get('add_1_2381').errors?.invalidDateyear">
                                        year is out of range(1900-2099) </small>
                                    <small class="text-danger" *ngIf="intermedimage.get('add_1_2381').errors?.invalidDateRange">
                                        date is out of range
                                    </small>
                                  </div>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr class="col-12">
                    <td colspan="12">
                        <div class="row">
                            <div class="col-2 pr-0">
                                <label for="add_2_1183">Name of other facility:</label>
                            </div>
                            <div class="col-6 pl-0">
                                <input type="text" placeholder=" Previous Hospital Affiliation Name " id="add_2_1183"
                                    formControlName="add_2_1183" class="form-control">
                            </div>
                        </div>
                    </td>
                </tr>
                <tr class="col-12">
                    <td colspan="6">
                        <div class="row">
                            <div class="col-4 pr-0">
                                <label for="add_2_1190">Department:</label>
                            </div>
                            <div class="col-6 pl-0">
                                <input type="text" placeholder="  Department" id="add_2_1190" formControlName="add_2_1190"
                                    class="form-control">
                            </div>
                        </div>
                    </td>
                    <td colspan="6">
                        <div class="row">
                            <div class="col-4 pr-0">
                                <label for="add_2_1312">Department/Clinical Chair:</label>
                            </div>
                            <div class="col-7 pl-0">
                                <input type="text" placeholder="  Department/Clinical Chair" id="add_2_1312" formControlName="add_2_1312"
                                    class="form-control">
                            </div>
                        </div>
                    </td>
                </tr>
                <tr class="col-12">
                    <td colspan="5">
                        <div class="row">
                            <div class="col-4 pr-0">
                                <label for="add_2_1184">Mailing Address:</label>
                            </div>
                            <div class="col-8 pl-0">
                                <input type="text" placeholder="  Mailing Address 1" id="add_2_1184" formControlName="add_2_1184"
                                    class="form-control">
                            </div>
                            <div class="col-4 pr-0"></div>
                            <div class="col-8 pl-0">
                                <input type="text" placeholder="  Mailing Address 2" id="add_2_1185" formControlName="add_2_1185"
                                    class="form-control">
                            </div>
                        </div>
                    </td>
                    <td colspan="7">
                        <div class="row">
                            <div class="col-3 pr-0">
                                <label for="add_2_1186">City:</label>
                            </div>
                            <div class="col-8 pl-0">
                                <input type="text" placeholder="  City" id="add_2_1186" formControlName="add_2_1186"
                                    class="form-control">
                            </div>
                            <div class="col-3 pr-0">
                                <label for="add_2_1188">State:</label>
                            </div>
                            <div class="col-8 pl-0">
                                <ng-select [items]="states"
                                    [class.is-invalid]="(intermedimage.controls['add_2_1188'].touched &&
                                                                             intermedimage.controls['add_2_1188'].invalid) ||
                                                                             (intermedimage.controls['add_2_1188'].invalid && isSubmit)" bindLabel="name"
                                    placeholder="  State" bindValue="id" formControlName="add_2_1188"> </ng-select>
                            </div>
                            <div class="col-3 pr-0">
                                <label for="add_2_1189">Zip Code:</label>
                            </div>
                            <div class="col-8 pl-0">
                                <input type="text" placeholder="  Zip" id="add_2_1189" formControlName="add_2_1189"
                                    class="form-control">
                            </div>
                        </div>
                    </td>
                </tr>
                <tr class="col-12">
                    <td colspan="4">
                        <div class="row">
                            <div class="col-4 pr-0">
                                <label for="add_2_1313">Phone Number:</label>
                            </div>
                            <div class="col-8 pl-0">
                                <input type="text" placeholder="  Phone Number" id="add_2_1313" formControlName="add_2_1313"
                                    class="form-control"> <small class="text-danger"
                                    *ngIf="intermedimage.controls['add_2_1313'].errors?.pattern">
                                    Only number are allowed</small>
                            </div>
                        </div>
                    </td>
                    <td colspan="4">
                        <div class="row">
                            <div class="col-4 pr-0">
                                <label for="add_2_2140">Fax Number:</label>
                            </div>
                            <div class="col-8 pl-0">
                                <input type="text" placeholder="  Fax Number" id="add_2_2140" formControlName="add_2_2140"
                                    class="form-control">
                            </div>
                        </div>
                    </td>
                    <td colspan="4">
                        <div class="row">
                            <div class="col-6 pr-0">
                                <label for="add_2_1192">Previous Status (active, provisional, courtesy, temporary, etc.):</label>
                            </div>
                            <div class="col-6 pl-0">
                                <ng-select [items]="staffStatus" bindLabel="name" formControlName="add_2_1192"
                                    placeholder="  status" bindValue="id"> </ng-select>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr class="col-12">
                    <td colspan="6">
                        <div class="row">
                            <div class="col-3 pr-0">
                                <label for="add_2_1303">Reason for Leaving:</label>
                            </div>
                            <div class="col-6 pl-0">
                                <input type="text" placeholder="  Reason for Leaving" id="add_2_1303" formControlName="add_2_1303"
                                    class="form-control">
                            </div>
                        </div>
                    </td>
                
                    <td colspan="3">
                
                        <div class="row">
                            <div class="col-12">
                                <label for="add_2_1191">Appointment Date From (mm/dd/yy):</label>
                            </div>
                            <div class="col-10 ">
                                <div class="input-group">
                                <input type="text" placeholder="  From Date" id="add_2_1191" formControlName="add_2_1191"  (blur)="clearInputIfInvalid('add_2_1191')" 
                                    class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker
                                    #idh61="ngbDatepicker">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="idh61.toggle()" type="button"> <i
                                            class="fas fa-calendar-alt"></i> </button>
                                </div>
                                </div>
                                <div *ngIf="intermedimage.get('add_2_1191').invalid ">
                                    <small class="text-danger" *ngIf="intermedimage.get('add_2_1191').errors?.invalidDateFormat">
                                        Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="intermedimage.get('add_2_1191').errors?.invalidMonth">
                                        Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="intermedimage.get('add_2_1191').errors?.invalidDateyear">
                                        year is out of range(1900-2099) </small>
                                    <small class="text-danger" *ngIf="intermedimage.get('add_2_1191').errors?.invalidDateRange">
                                        date is out of range
                                    </small>
                                  </div>
                            </div>
                        </div>
                    </td>
                    <td colspan="3">
                        <div class="row">
                            <div class="col-12 ">
                                <label for="add_2_2381">To (mm/dd/yy):</label>
                            </div>
                            <div class="col-10 ">
                                <div class="input-group">
                                <input type="text" placeholder="  To Date" id="add_2_2381" formControlName="add_2_2381" (blur)="clearInputIfInvalid('add_2_2381')" 
                                    class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker
                                    #idh62="ngbDatepicker">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="idh62.toggle()" type="button"> <i
                                            class="fas fa-calendar-alt"></i> </button>
                                </div>
                                </div>
                                <div *ngIf="intermedimage.get('add_2_2381').invalid ">
                                    <small class="text-danger" *ngIf="intermedimage.get('add_2_2381').errors?.invalidDateFormat">
                                        Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="intermedimage.get('add_2_2381').errors?.invalidMonth">
                                        Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="intermedimage.get('add_2_2381').errors?.invalidDateyear">
                                        year is out of range(1900-2099) </small>
                                    <small class="text-danger" *ngIf="intermedimage.get('add_2_2381').errors?.invalidDateRange">
                                        date is out of range
                                    </small>
                                  </div>
                            </div>
                        </div>
                    </td>
                </tr>  
                <tr class="col-12">
                    <td colspan="12">
                        <label for="9"><b>D. INPATIENT COVERAGE PLAN&nbsp;&nbsp;(for those without admitting
                                privileges)</b></label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="doesnotapply">
                            <label for="cmd_1007"><b>Does Not Apply:</b></label>&nbsp;&nbsp;
                            <input type="checkbox" value="1" formControlName="cmd_59" (ngModelChange)="handleNAchange('cmd_59')"
                                id="cmd_59">
                        </span>
                    </td>
                </tr>
                <tr>
                    <td colspan="12">
                        <label for="9"><b>Please attach signed letter of agreement from the physician or group representative that
                                admits
                                and manages the inpatient care for your patients.</b></label>
                    </td>
                </tr>
                <tr>
                    <td colspan="12">
                        <table style="width:100%">
                            <tr>
                                <th>
                                    <h6><small>Name of Admitting Physician/Practice/Clinic/Group:</small></h6>
                                </th>
                                <th>
                                    <h6><small>Hospital Where privileged:</small></h6>
                                </th>
                            </tr>
                            <tr>
                                <td>
                                    <input  type="text" id="cmd_60" formControlName="cmd_60" style="width:100%">
                                </td>
                                <td>
                                    <input  type="text" id="cmd_61" formControlName="cmd_61" style="width:100%">
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <input  type="text" id="cmd_62" formControlName="cmd_62" style="width:100%">
                                </td>
                                <td>
                                    <input  type="text" id="cmd_63" formControlName="cmd_63" style="width:100%">
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <input type="text" id="cmd_061" formControlName="cmd_061" style="width:100%">
                                </td>
                                <td>
                                    <input  type="text" id="cmd_063" formControlName="cmd_063" style="width:100%">
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
                </div>
                </td>
                   </tr>     
                   <tr class="col-12">
                    <td colspan="12">
                        <div class="table table-bordered" style="table-layout: fixed;">
                            <td colspan="12">
                                <label>
                                    <h6>XVII. WORK HISTORY ( Do not abbreviate ) (Attach additional sheet if necessary) </h6>
                                </label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="doesnotapply">
                                    <label for="cmd_00187"><b>Does Not Apply:</b></label>&nbsp;&nbsp;
                                    <input type="checkbox" value="Yes" (ngModelChange)="handleNAchange('cmd_00187')" formControlName="cmd_00187"
                                        id="cmd_00187">
                                </span>
                            </td>
                            <tr class="col-12">
                            
                                <td colspan="12">
                            
                                    <label>Chronologically list all work history activities since completion of professional training (use extra
                                        sheets if necessary).This information must be complete. Curriculum vitae is <u>not</u> sufficient.</label>
                            
                                </td>
                            
                            </tr>
                            <tr class="col-12">
                            
                                <td colspan="4">
                            
                                    <div class="row">
                            
                                        <div class="col-12">
                            
                                            <label for="445">Name of current practice/employer:<span *ngIf="intermedimage.controls['445'].invalid ||intermedimage.controls['cmd_00187'].value==null||intermedimage.controls['cmd_00187'].value=='' " class="mandatory">*</span></label>
                            
                                        </div>
                            
                                        <div class="col-12">
                            
                                            <input type="text"  (blur)="handleNAchange('cmd_00187')" placeholder="Work History 1 Location " id="445" formControlName="445"
                                                class="form-control"
                                                [class.is-invalid]="intermedimage.controls['445'].invalid ">
                            
                                            <div class="invalid-feedback"
                                                *ngIf="(intermedimage.controls['445'].touched && intermedimage.controls['445'].invalid) || (intermedimage.controls['445'].invalid && isSubmit)">
                                                Required </div>
                            
                                        </div>
                            
                                    </div>
                            
                                </td>
                            
                                <td colspan="4">
                            
                                    <div class="row">
                            
                                        <div class="col-4">
                            
                                            <label for="452">Contact Name:<span *ngIf="intermedimage.controls['452'].invalid ||intermedimage.controls['cmd_00187'].value==null||intermedimage.controls['cmd_00187'].value=='' " class="mandatory">*</span></label>
                            
                                        </div>
                            
                                        <div class="col-8">
                            
                                            <input type="text" (blur)="handleNAchange('cmd_00187')" placeholder="Work History 1 Contact" id="452" formControlName="452" class="form-control"
                                                [class.is-invalid]=" intermedimage.controls['452'].invalid ">
                            
                                            <div class="invalid-feedback"
                                                *ngIf="(intermedimage.controls['452'].touched && intermedimage.controls['452'].invalid) || (intermedimage.controls['452'].invalid && isSubmit)">
                                                Required </div>
                            
                                        </div>
                            
                                    </div>
                            
                                </td>
                            
                                <td colspan="4">
                            
                                    <div class="row">
                            
                                        <div class="col-5">
                            
                                            <label for="453">Telephone Number:<span *ngIf="intermedimage.controls['453'].invalid ||intermedimage.controls['cmd_00187'].value==null||intermedimage.controls['cmd_00187'].value=='' " class="mandatory">*</span></label>
                            
                                        </div>
                            
                                        <div class="col-7">
                            
                                            <input type="text" (blur)="handleNAchange('cmd_00187')" placeholder="Work History 1 Telephone  " id="453" formControlName="453"
                                                class="form-control"
                                                [class.is-invalid]=" intermedimage.controls['453'].invalid ">
                            
                                            <div class="invalid-feedback"
                                                *ngIf="(intermedimage.controls['453'].touched && intermedimage.controls['453'].invalid) || (intermedimage.controls['453'].invalid && isSubmit)">
                                                Required </div>
                            
                                        </div>
                            
                                    </div>
                            
                                </td>
                            
                            </tr>
                            <tr class="col-12">

                                <td colspan="4">
                            
                                    <div class="row">
                            
                                        <div class="col-4 pr-0">
                            
                                            <label for="888">Fax Number:<span *ngIf="intermedimage.controls['888'].invalid ||intermedimage.controls['cmd_00187'].value==null||intermedimage.controls['cmd_00187'].value=='' " class="mandatory">*</span></label>
                            
                                        </div>
                            
                                        <div class="col-8 pl-0">
                            
                                            <input type="text" (blur)="handleNAchange('cmd_00187')" placeholder="Work History 1 Fax " id="888" formControlName="888" class="form-control"
                                                [class.is-invalid]="intermedimage.controls['888'].invalid ">
                            
                                            <div class="invalid-feedback"
                                                *ngIf="(intermedimage.controls['888'].touched && intermedimage.controls['888'].invalid) || (intermedimage.controls['888'].invalid && isSubmit)">
                                                Required </div>
                            
                                        </div>
                            
                                        <div class="col-6 pr-0">
                            
                                            <label for="454">From (mm/dd/yy):<span *ngIf="intermedimage.controls['454'].invalid ||intermedimage.controls['cmd_00187'].value==null||intermedimage.controls['cmd_00187'].value=='' " class="mandatory">*</span></label>
                            
                                        </div>
                            
                                        <div class="col-6 pl-0 ">
                                          <div class="input-group">
                                            <input type="text" (blur)="handleNAchange('cmd_00187')" (blur)="clearInputIfInvalid('454','cmd_00187')"  placeholder="Work History 1 Start Date" id="454" formControlName="454"
                                                class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker #idh63="ngbDatepicker"
                                                [class.is-invalid]=" intermedimage.controls['454'].invalid ">
                            
                                            <div class="input-group-append">
                            
                                                <button class="btn btn-outline-secondary calendar" (click)="idh63.toggle()" type="button"> <i
                                                        class="fas fa-calendar-alt"></i> </button>
                            
                                            </div>
                                            </div>
                                            <div class="text-danger"
                                                *ngIf="intermedimage.controls['454'].invalid && (intermedimage.controls['454'].value==''|| intermedimage.controls['454'].value==null) ">
                                                <small>
                                                    Required
                                                </small>
                                            
                                            </div>
                                            <div *ngIf="intermedimage.get('454').invalid">
                                                <small class="text-danger" *ngIf="intermedimage.get('454').errors.invalidDateFormat">
                                                    Only Date Format is allowed(MM/DD/YYYY) </small>
                                                <small class="text-danger" *ngIf="intermedimage.get('454').errors.invalidMonth">
                                                    Month is out of range
                                                </small>
                                                <small class="text-danger" *ngIf="intermedimage.get('454').errors.invalidDateyear">
                                                    year is out of range(1900-2099) </small>
                                                <small class="text-danger" *ngIf="intermedimage.get('454').errors.invalidDateRange">
                                                    date is out of range
                                                </small>
</div>
                            
                                        </div>
                            
                                        <div class="col-6 pr-0">
                            
                                            <label for="9">To (mm/dd/yy):<span *ngIf="intermedimage.controls['455'].invalid ||intermedimage.controls['cmd_00187'].value==null||intermedimage.controls['cmd_00187'].value=='' " class="mandatory">*</span></label>
                            
                                        </div>
                            
                                        <div class="col-6 pl-0 ">
                                          <div class="input-group">
                                            <input type="text" (blur)="handleNAchange('cmd_00187')" (blur)="clearInputIfInvalid('455','cmd_00187')" placeholder="Work History 1 End Date" id="455" formControlName="455"
                                                class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker #idh64="ngbDatepicker"
                                                [class.is-invalid]=" intermedimage.controls['455'].invalid ">
                            
                                            <div class="input-group-append">
                            
                                                <button class="btn btn-outline-secondary calendar" (click)="idh64.toggle()" type="button"> <i
                                                        class="fas fa-calendar-alt"></i> </button>
                            
                                            </div>
                                            </div>
                                            <div class="text-danger"
                                                *ngIf="intermedimage.controls['455'].invalid && (intermedimage.controls['455'].value==''|| intermedimage.controls['455'].value==null) ">
                                                <small>
                                                    Required
                                                </small>
                                            
                                            </div>
                                            <div *ngIf="intermedimage.get('455').invalid">
                                                <small class="text-danger" *ngIf="intermedimage.get('455').errors.invalidDateFormat">
                                                    Only Date Format is allowed(MM/DD/YYYY) </small>
                                                <small class="text-danger" *ngIf="intermedimage.get('455').errors.invalidMonth">
                                                    Month is out of range
                                                </small>
                                                <small class="text-danger" *ngIf="intermedimage.get('455').errors.invalidDateyear">
                                                    year is out of range(1900-2099) </small>
                                                <small class="text-danger" *ngIf="intermedimage.get('455').errors.invalidDateRange">
                                                    date is out of range
                                                </small>
                                            </div>
                            
                                        </div>
                            
                                    </div>
                            
                                </td>
                            
                                <td colspan="4">
                            
                                    <div class="row">
                            
                                        <div class="col-12">
                            
                                            <label for="446">Mailing Address:<span *ngIf="intermedimage.controls['446'].invalid ||intermedimage.controls['cmd_00187'].value==null||intermedimage.controls['cmd_00187'].value=='' " class="mandatory">*</span></label>
                            
                                        </div>
                            
                                        <div class="col-12">
                            
                                            <input type="text" (blur)="handleNAchange('cmd_00187')" placeholder="Work History 1 Address 1" id="446" formControlName="446" class="form-control"
                                                [class.is-invalid]="intermedimage.controls['446'].invalid ">
                            
                                            <div class="invalid-feedback"
                                                *ngIf="(intermedimage.controls['446'].touched && intermedimage.controls['446'].invalid) || (intermedimage.controls['446'].invalid && isSubmit)">
                                                Required </div>
                            
                                        </div>
                            
                                        <div class="col-12">
                            
                                            <input type="text" (blur)="handleNAchange('cmd_00187')" placeholder="Work History 1 Address 2" id="447" formControlName="447" class="form-control"
                                                [class.is-invalid]="intermedimage.controls['447'].invalid">
                            
                                            <div class="invalid-feedback"
                                                *ngIf="(intermedimage.controls['447'].touched && intermedimage.controls['447'].invalid) || (intermedimage.controls['447'].invalid && isSubmit)">
                                                Required </div>
                            
                                        </div>
                            
                                    </div>
                            
                                </td>
                            
                                <td colspan="4">
                            
                                    <div class="row">
                            
                                        <div class="col-3 pr-0">
                            
                                            <label for="448">City:<span *ngIf="intermedimage.controls['448'].invalid ||intermedimage.controls['cmd_00187'].value==null||intermedimage.controls['cmd_00187'].value=='' " class="mandatory">*</span></label>
                            
                                        </div>
                            
                                        <div class="col-8 pl-0">
                            
                                            <input type="text" (blur)="handleNAchange('cmd_00187')" placeholder="Work History 1 City" id="448" formControlName="448" class="form-control"
                                                [class.is-invalid]=" intermedimage.controls['448'].invalid">
                            
                                        </div>
                            
                                        <div class="invalid-feedback"
                                            *ngIf="(intermedimage.controls['448'].touched && intermedimage.controls['448'].invalid) || (intermedimage.controls['448'].invalid && isSubmit)">
                                            Required </div>
                            
                                        <div class="col-3 pr-0">
                            
                                            <label for="450">State:<span *ngIf="intermedimage.controls['450'].invalid ||intermedimage.controls['cmd_00187'].value==null||intermedimage.controls['cmd_00187'].value=='' " class="mandatory">*</span></label>
                            
                                        </div>
                            
                                        <div class="col-8 pl-0">
                            
                                            <ng-select [items]="states" (blur)="handleNAchange('cmd_00187')" [class.is-invalid]="
                                                            
                                                            intermedimage.controls['450'].invalid" bindLabel="name"
                                                placeholder="Work History 1 State" bindValue="id" formControlName="450"> </ng-select>
                            
                                            <div class="invalid-feedback"
                                                *ngIf="(intermedimage.controls['450'].touched && intermedimage.controls['450'].invalid) || (intermedimage.controls['450'].invalid && isSubmit)">
                                                State is required and cannot be empty </div>
                            
                                        </div>
                            
                                        <div class="col-3 pr-0">
                            
                                            <label for="451">Zip Code:<span *ngIf="intermedimage.controls['451'].invalid ||intermedimage.controls['cmd_00187'].value==null||intermedimage.controls['cmd_00187'].value=='' " class="mandatory">*</span></label>
                            
                                        </div>
                            
                                        <div class="col-8 pl-0">
                            
                                            <input type="text" (blur)="handleNAchange('cmd_00187')" placeholder="Work History 1 Zip" id="451" formControlName="451" class="form-control"
                                                [class.is-invalid]="intermedimage.controls['451'].invalid ">
                            
                                            <div class="invalid-feedback"
                                                *ngIf="(intermedimage.controls['451'].touched && intermedimage.controls['451'].invalid) || (intermedimage.controls['451'].invalid && isSubmit)">
                                                Required </div>
                            
                                        </div>
                            
                                    </div>
                            
                                </td>
                            
                            
                            </tr>
                            <tr class="col-12">
                            
                                <td colspan="4">
                            
                                    <div class="row">
                            
                                        <div class="col-12">
                            
                                            <label for="456">Name of Practice / Employer:</label>
                            
                                        </div>
                            
                                        <div class="col-12">
                            
                                            <input type="text" placeholder="Work History 2 Location " id="456" formControlName="456"
                                                class="form-control"
                                                [class.is-invalid]="(intermedimage.controls['456'].touched && intermedimage.controls['456'].invalid ) || (intermedimage.controls['456'].invalid && isSubmit)">
                                        </div>
                            
                                    </div>
                            
                                </td>
                            
                                <td colspan="4">
                            
                                    <div class="row">
                            
                                        <div class="col-4">
                            
                                            <label for="463">Contact Name:</label>
                            
                                        </div>
                            
                                        <div class="col-8">
                            
                                            <input type="text" placeholder="Work History 2 Contact " id="463" formControlName="463" class="form-control"
                                                [class.is-invalid]="(intermedimage.controls['463'].touched && intermedimage.controls['463'].invalid ) || (intermedimage.controls['463'].invalid && isSubmit)">
                            
                                            <div class="invalid-feedback"
                                                *ngIf="(intermedimage.controls['463'].touched && intermedimage.controls['463'].invalid) || (intermedimage.controls['463'].invalid && isSubmit)">
                                                Required </div>
                            
                                        </div>
                            
                                    </div>
                            
                                </td>
                            
                                <td colspan="4">
                            
                                    <div class="row">
                            
                                        <div class="col-5">
                            
                                            <label for="464">Telephone Number:</label>
                            
                                        </div>
                            
                                        <div class="col-7">
                            
                                            <input type="text" placeholder="Work History 2 Telephone" id="464" formControlName="464"
                                                class="form-control"
                                                [class.is-invalid]="(intermedimage.controls['464'].touched && intermedimage.controls['464'].invalid ) || (intermedimage.controls['464'].invalid && isSubmit)">
                            
                                            <div class="invalid-feedback"
                                                *ngIf="(intermedimage.controls['464'].touched && intermedimage.controls['464'].invalid) || (intermedimage.controls['464'].invalid && isSubmit)">
                                                Required </div>
                            
                                        </div>
                            
                                    </div>
                            
                                </td>
                            
                            </tr>
                            <tr class="col-12">
                                
                                <td colspan="4">
                            
                                    <div class="row">
                            
                                        <div class="col-6 pr-0">
                            
                                            <label for="890">Fax Number:</label>
                            
                                        </div>
                            
                                        <div class="col-6 pl-0 d-flex">
                            
                                            <input type="text" placeholder="Work History 2 Fax Number" id="890" formControlName="890" class="form-control"
                                                [class.is-invalid]="(intermedimage.controls['890'].touched && intermedimage.controls['890'].invalid ) || (intermedimage.controls['890'].invalid && isSubmit)">
                            
                                            <div class="invalid-feedback"
                                                *ngIf="(intermedimage.controls['890'].touched && intermedimage.controls['890'].invalid) || (intermedimage.controls['890'].invalid && isSubmit)">
                                                Required </div>
                            
                                        </div>
                            
                                        <div class="col-6 pr-0">
                            
                                            <label for="465">From (mm/dd/yy):</label>
                            
                                        </div>
                            
                                        <div class="col-6 pl-0 ">
                                          <div class="input-group">
                                            <input type="text" placeholder="Work History 2 Start Date" id="465" formControlName="465" (blur)="clearInputIfInvalid('465')" 
                                                class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker #idh65="ngbDatepicker"
                                                [class.is-invalid]="(intermedimage.controls['465'].touched && intermedimage.controls['465'].invalid ) || (intermedimage.controls['465'].invalid && isSubmit)">
                            
                                            <div class="input-group-append">
                            
                                                <button class="btn btn-outline-secondary calendar" (click)="idh65.toggle()" type="button"> <i
                                                        class="fas fa-calendar-alt"></i> </button>
                            
                                            </div>
                                            </div>
                                            <div *ngIf="intermedimage.get('465').invalid ">
                                                <small class="text-danger" *ngIf="intermedimage.get('465').errors?.invalidDateFormat">
                                                    Only Date Format is allowed(MM/DD/YYYY)
                                                </small>
                                                <small class="text-danger" *ngIf="intermedimage.get('465').errors?.invalidMonth">
                                                    Month is out of range
                                                </small>
                                                <small class="text-danger" *ngIf="intermedimage.get('465').errors?.invalidDateyear">
                                                    year is out of range(1900-2099) </small>
                                                <small class="text-danger" *ngIf="intermedimage.get('465').errors?.invalidDateRange">
                                                    date is out of range
                                                </small>
                                              </div>
                            
                                        </div>
                            
                                        <div class="col-6 pr-0">
                            
                                            <label for="466">To (mm/dd/yy):</label>
                            
                                        </div>
                            
                                        <div class="col-6 pl-0 ">
                                          <div class="input-group">
                                            <input type="text" placeholder="Work History 2 End Date" id="466" formControlName="466" (blur)="clearInputIfInvalid('466')" 
                                                class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker #idh66="ngbDatepicker"
                                                [class.is-invalid]="(intermedimage.controls['466'].touched && intermedimage.controls['466'].invalid ) || (intermedimage.controls['466'].invalid && isSubmit)">
                            
                                            <div class="input-group-append">
                            
                                                <button class="btn btn-outline-secondary calendar" (click)="idh66.toggle()" type="button"> <i
                                                        class="fas fa-calendar-alt"></i> </button>
                            
                                            </div>
                                            </div>
                                            <div *ngIf="intermedimage.get('466').invalid ">
                                                <small class="text-danger" *ngIf="intermedimage.get('466').errors?.invalidDateFormat">
                                                    Only Date Format is allowed(MM/DD/YYYY)
                                                </small>
                                                <small class="text-danger" *ngIf="intermedimage.get('466').errors?.invalidMonth">
                                                    Month is out of range
                                                </small>
                                                <small class="text-danger" *ngIf="intermedimage.get('466').errors?.invalidDateyear">
                                                    year is out of range(1900-2099) </small>
                                                <small class="text-danger" *ngIf="intermedimage.get('466').errors?.invalidDateRange">
                                                    date is out of range
                                                </small>
                                              </div>
                            
                                        </div>
                            
                                    </div>
                            
                                </td>
                                <td colspan="4">
                            
                                    <div class="row">
                            
                                        <div class="col-12">
                            
                                            <label for="457">Mailing Address:</label>
                            
                                        </div>
                            
                                        <div class="col-12">
                            
                                            <input type="text" placeholder="Work History 2 Address 1" id="457" formControlName="457" class="form-control"
                                                [class.is-invalid]="(intermedimage.controls['457'].touched && intermedimage.controls['457'].invalid ) || (intermedimage.controls['457'].invalid && isSubmit)">
                            
                                            <div class="invalid-feedback"
                                                *ngIf="(intermedimage.controls['457'].touched && intermedimage.controls['457'].invalid) || (intermedimage.controls['457'].invalid && isSubmit)">
                                                Required </div>
                            
                                        </div>
                            
                                        <div class="col-12">
                            
                                            <input type="text" placeholder="Work History 2 Address 2" id="458" formControlName="458" class="form-control"
                                                [class.is-invalid]="(intermedimage.controls['458'].touched && intermedimage.controls['458'].invalid ) || (intermedimage.controls['458'].invalid && isSubmit)">
                            
                                            <div class="invalid-feedback"
                                                *ngIf="(intermedimage.controls['458'].touched && intermedimage.controls['458'].invalid) || (intermedimage.controls['458'].invalid && isSubmit)">
                                                Required </div>
                            
                                        </div>
                            
                                    </div>
                            
                                </td>
                            
                                <td colspan="4">
                            
                                    <div class="row">
                            
                                        <div class="col-3 pr-0">
                            
                                            <label for="459">City:</label>
                            
                                        </div>
                            
                                        <div class="col-8 pl-0">
                            
                                            <input type="text" placeholder="Work History 2 City" id="459" formControlName="459" class="form-control"
                                                [class.is-invalid]="(intermedimage.controls['459'].touched && intermedimage.controls['459'].invalid ) || (intermedimage.controls['459'].invalid && isSubmit)">
                            
                                            <div class="invalid-feedback"
                                                *ngIf="(intermedimage.controls['459'].touched && intermedimage.controls['459'].invalid) || (intermedimage.controls['459'].invalid && isSubmit)">
                                                Required </div>
                            
                                        </div>
                            
                                        <div class="col-3 pr-0">
                            
                                            <label for="461">State:</label>
                            
                                        </div>
                            
                                        <div class="col-8 pl-0">
                            
                                            <ng-select [items]="states" [class.is-invalid]="(intermedimage.controls['461'].touched &&
                                                            
                                                            intermedimage.controls['461'].invalid) ||
                                                            
                                                            (intermedimage.controls['461'].invalid && isSubmit)" bindLabel="name"
                                                placeholder="Work History 2 State" bindValue="id" formControlName="461"> </ng-select>
                            
                                            <div class="invalid-feedback"
                                                *ngIf="(intermedimage.controls['461'].touched && intermedimage.controls['461'].invalid) || (intermedimage.controls['461'].invalid && isSubmit)">
                                                State is required and cannot be empty </div>
                            
                                        </div>
                            
                                        <div class="col-3 pr-0">
                            
                                            <label for="462">Zip Code:</label>
                            
                                        </div>
                            
                                        <div class="col-8 pl-0">
                            
                                            <input type="text" placeholder="Work History 2 Zip" id="462" formControlName="462" class="form-control"
                                                [class.is-invalid]="(intermedimage.controls['462'].touched && intermedimage.controls['462'].invalid ) || (intermedimage.controls['462'].invalid && isSubmit)">
                            
                                            <div class="invalid-feedback"
                                                *ngIf="(intermedimage.controls['462'].touched && intermedimage.controls['462'].invalid) || (intermedimage.controls['462'].invalid && isSubmit)">
                                                Required </div>
                            
                                        </div>
                            
                                    </div>
                            
                                </td>
                            
                            
                            </tr>
                            <tr class="col-12">
                            
                                <td colspan="12">
                            
                                    <div class="row">
                            
                                        <div class="col-2">
                            
                                            <label for="1338">Reason for Leaving:</label>
                            
                                        </div>
                            
                                        <div class="col-7">
                            
                                            <input type="text" placeholder="Work History 2 Reason for Leaving" id="1338" formControlName="1338"
                                                class="form-control"
                                                [class.is-invalid]="(intermedimage.controls['1338'].touched && intermedimage.controls['1338'].invalid ) || (intermedimage.controls['1338'].invalid && isSubmit)">
                            
                                            <div class="invalid-feedback"
                                                *ngIf="(intermedimage.controls['1338'].touched && intermedimage.controls['1338'].invalid) || (intermedimage.controls['1338'].invalid && isSubmit)">
                                                Required </div>
                            
                                        </div>
                            
                                    </div>
                            
                                </td>
                            
                            </tr>
                            <tr class="col-12">
                            
                                <td colspan="4">
                            
                                    <div class="row">
                            
                                        <div class="col-12">
                            
                                            <label for="467">Name of Practice / Employer:</label>
                            
                                        </div>
                            
                                        <div class="col-12">
                            
                                            <input type="text" placeholder="Work History 3 Location" id="467" formControlName="467"
                                                class="form-control">
                                        </div>
                            
                                    </div>
                            
                                </td>
                            
                                <td colspan="4">
                            
                                    <div class="row">
                            
                                        <div class="col-4">
                            
                                            <label for="474">Contact Name:</label>
                            
                                        </div>
                            
                                        <div class="col-8">
                            
                                            <input type="text" placeholder="Work History 3 Contact" id="474" formControlName="474" class="form-control">
                                        </div>
                            
                                    </div>
                            
                                </td>
                            
                                <td colspan="4">
                            
                                    <div class="row">
                            
                                        <div class="col-5">
                            
                                            <label for="475">Telephone Number:</label>
                            
                                        </div>
                            
                                        <div class="col-7">
                            
                                            <input type="text" placeholder="Work History 3 Telephone " id="475" formControlName="475"
                                                class="form-control"> <small class="text-danger"
                                                *ngIf="intermedimage.controls['475'].errors?.pattern">
                            
                                                Only number are allowed</small>
                                        </div>
                            
                                    </div>
                            
                                </td>
                            
                            </tr>
                            <tr class="col-12">
                                <td colspan="4">
                            
                                    <div class="row">
                            
                                        <div class="col-6 pr-0">
                            
                                            <label for="892">Fax Number:</label>
                            
                                        </div>
                            
                                        <div class="col-6 pl-0 d-flex">
                            
                                            <input type="text" placeholder="Work History 3 Fax " id="892" formControlName="892" class="form-control">
                            
                            
                                        </div>
                            
                                        <div class="col-6 pr-0">
                            
                                            <label for="476">From (mm/dd/yy):</label>
                            
                                        </div>
                            
                                        <div class="col-6 pl-0 ">
                                          <div class="input-group">
                            
                                            <input type="text" placeholder="Work History 3 Start Date" id="476" formControlName="476" (blur)="clearInputIfInvalid('476')" 
                                                class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker
                                                #idh67="ngbDatepicker">
                            
                                            <div class="input-group-append">
                            
                                                <button class="btn btn-outline-secondary calendar" (click)="idh67.toggle()" type="button"> <i
                                                        class="fas fa-calendar-alt"></i> </button>
                            
                                            </div>
                                            </div>
                                            <div *ngIf="intermedimage.get('476').invalid ">
                                                <small class="text-danger" *ngIf="intermedimage.get('476').errors?.invalidDateFormat">
                                                    Only Date Format is allowed(MM/DD/YYYY)
                                                </small>
                                                <small class="text-danger" *ngIf="intermedimage.get('476').errors?.invalidMonth">
                                                    Month is out of range
                                                </small>
                                                <small class="text-danger" *ngIf="intermedimage.get('476').errors?.invalidDateyear">
                                                    year is out of range(1900-2099) </small>
                                                <small class="text-danger" *ngIf="intermedimage.get('476').errors?.invalidDateRange">
                                                    date is out of range
                                                </small>
                                              </div>
                                        </div>
                            
                                        <div class="col-6 pr-0">
                            
                                            <label for="477">To (mm/dd/yy):</label>
                            
                                        </div>
                            
                                        <div class="col-6 pl-0 ">
                                          <div class="input-group">
                            
                                            <input type="text" placeholder="Work History 3 End Date" id="477" formControlName="477" (blur)="clearInputIfInvalid('477')" 
                                                class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker
                                                #idh68="ngbDatepicker">
                            
                                            <div class="input-group-append">
                            
                                                <button class="btn btn-outline-secondary calendar" (click)="idh68.toggle()" type="button"> <i
                                                        class="fas fa-calendar-alt"></i> </button>
                            
                                            </div>
                                            </div>
                                            <div *ngIf="intermedimage.get('477').invalid ">
                                                <small class="text-danger" *ngIf="intermedimage.get('477').errors?.invalidDateFormat">
                                                    Only Date Format is allowed(MM/DD/YYYY)
                                                </small>
                                                <small class="text-danger" *ngIf="intermedimage.get('477').errors?.invalidMonth">
                                                    Month is out of range
                                                </small>
                                                <small class="text-danger" *ngIf="intermedimage.get('477').errors?.invalidDateyear">
                                                    year is out of range(1900-2099) </small>
                                                <small class="text-danger" *ngIf="intermedimage.get('477').errors?.invalidDateRange">
                                                    date is out of range
                                                </small>
                                              </div>
                                        </div>
                            
                                    </div>
                            
                                </td>
                            
                                <td colspan="4">
                            
                                    <div class="row">
                            
                                        <div class="col-12">
                            
                                            <label for="468">Mailing Address:</label>
                            
                                        </div>
                            
                                        <div class="col-12">
                            
                                            <input type="text" placeholder="Work History 3 Address 1" id="468" formControlName="468" class="form-control">
                                        </div>
                            
                                        <div class="col-12">
                            
                                            <input type="text" placeholder="Work History 3 Address 2" id="469" formControlName="469" class="form-control">
                                        </div>
                            
                                    </div>
                            
                                </td>
                            
                                <td colspan="4">
                            
                                    <div class="row">
                            
                                        <div class="col-3 pr-0">
                            
                                            <label for="470">City:</label>
                            
                                        </div>
                            
                                        <div class="col-8 pl-0">
                            
                                            <input type="text" placeholder="Work History 3 City" id="470" formControlName="470" class="form-control">
                                        </div>
                            
                                        <div class="col-3 pr-0">
                            
                                            <label for="472">State:</label>
                            
                                        </div>
                            
                                        <div class="col-8 pl-0">
                            
                                            <ng-select [items]="states" [class.is-invalid]="(intermedimage.controls['472'].touched &&
                                                            
                                                            intermedimage.controls['472'].invalid) ||
                                                            
                                                            (intermedimage.controls['472'].invalid && isSubmit)" bindLabel="name"
                                                placeholder="Work History 3 State" bindValue="id" formControlName="472"> </ng-select>
                            
                                        </div>
                            
                                        <div class="col-3 pr-0">
                            
                                            <label for="473">Zip Code:</label>
                            
                                        </div>
                            
                                        <div class="col-8 pl-0">
                            
                                            <input type="text" placeholder="Work History 3 Zip" id="473" formControlName="473" class="form-control">
                                        </div>
                            
                                    </div>
                            
                                </td>
                            
                              
                            
                            </tr>
                            <tr class="col-12">
                            
                                <td colspan="12">
                            
                                    <div class="row">
                            
                                        <div class="col-2">
                            
                                            <label for="1342">Reason for Leaving:</label>
                            
                                        </div>
                            
                                        <div class="col-7">
                            
                                            <input type="text" placeholder="Work History 3 Reason for Leaving" id="1342" formControlName="1342"
                                                class="form-control">
                                        </div>
                            
                                    </div>
                            
                                </td>
                            
                            </tr>
                            <tr class="col-12">
                            
                                <td colspan="12">
                            
                                    <label><b> Please account for all gaps between dates of medical/professional school
                            
                                            graduation to
                            
                                            present not covered elsewhere within this application. Include dates, activity and names where
                            
                                            applicable:</b></label>&nbsp;<span class="doesnotapply">
                            
                                        <label for="cmd_0059"><b>Does Not Apply:</b></label>&nbsp;&nbsp;
                            
                                        <input type="checkbox" value="1" formControlName="cmd_0059" (ngModelChange)="handleNAchange('cmd_0059')"
                                            id="cmd_0059">
                            
                                    </span>
                            
                                </td>
                            
                            </tr>
                            <tr class="col-12">
                                <td colspan="4">
                                    <h6 style="text-align:center"><small>Activity/ Name</small></h6>
                                </td>
                                <td colspan="4">
                                    <h6 style="text-align:center"><small>From</small></h6>
                                </td>
                                <td colspan="4">
                                    <h6 style="text-align:center"><small>To</small></h6>
                                </td>
                            </tr>
                            <tr class="col-12">
                            
                                <td colspan="3">
                            
                                    <div class="row">
                            
                                        <div class="col-12">
                            
                                            <input type="text"  (blur)="handleNAchange('cmd_0059')" id="1138" [class.is-invalid]="
                                                            
                                                            intermedimage.controls['1138'].invalid"
                                                placeholder="Explanation for Gap in Work History 1" formControlName="1138" class="form-control"
                                                style="width:100%">
                            
                                            <div class="invalid-feedback"
                                                *ngIf="(intermedimage.controls['1138'].touched && intermedimage.controls['1138'].invalid) || (intermedimage.controls['1138'].invalid && isSubmit)">
                                                Required </div>
                            
                                        </div>
                            
                                    </div>
                            
                                </td>
                            
                                <td colspan="4">
                            
                                    <div class="row">
                            
                                        <!-- <div class="col-4">
                            
                                            <label for="346">From(mm/dd/yy):</label>
                            
                                        </div> -->
                            
                                        <div class="col-10 d-flex">
                                          <!-- <div class="input-group"> -->
                            
                                            <input type="text" (blur)="handleNAchange('cmd_0059')"  (blur)="clearInputIfInvalid('3395','cmd_0059')"  id="3395" placeholder="Gap 1 Start Date" [class.is-invalid]="
                                                            
                                                            intermedimage.controls['3395'].invalid" formControlName="3395"
                                                style="width:100%" class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker
                                                #idh69="ngbDatepicker">
                            
                                            <div class="input-group-append">
                            
                                                <button class="btn btn-outline-secondary calendar" (click)="idh69.toggle()" type="button"> <i
                                                        class="fas fa-calendar-alt"></i> </button>
                            
                                            </div>
                                        </div>
                                            <!-- </div> -->
                                            <div class="text-danger"
                                                *ngIf="intermedimage.controls['3395'].invalid && (intermedimage.controls['3395'].value==''|| intermedimage.controls['3395'].value==null) ">
                                                <small>
                                                    Required
                                                </small>
                                            
                                            </div>
                                            <div *ngIf="intermedimage.get('3395').invalid">
                                                <small class="text-danger" *ngIf="intermedimage.get('3395').errors.invalidDateFormat">
                                                    Only Date Format is allowed(MM/DD/YYYY) </small>
                                                <small class="text-danger" *ngIf="intermedimage.get('3395').errors.invalidMonth">
                                                    Month is out of range
                                                </small>
                                                <small class="text-danger" *ngIf="intermedimage.get('3395').errors.invalidDateyear">
                                                    year is out of range(1900-2099) </small>
                                                <small class="text-danger" *ngIf="intermedimage.get('3395').errors.invalidDateRange">
                                                    date is out of range
                                                </small>
                                            </div>
                            
                            
                                        </div>
                            
                                    <!-- </div> -->
                            
                                </td>
                            
                                <td colspan="4">
                            
                                    <div class="row">
                            
                                        <!-- <div class="col-4">
                            
                                            <label for="346">To (mm/dd/yy):</label>
                            
                                        </div> -->
                            
                                        <div class="col-8 d-flex">
                                          <!-- <div class="input-group"> -->
                            
                                            <input type="text" (blur)="handleNAchange('cmd_0059')" (blur)="clearInputIfInvalid('3396','cmd_0059')"  id="3396" placeholder="Gap 1 End Date" [class.is-invalid]="
                                                            
                                                            intermedimage.controls['3396'].invalid" formControlName="3396"
                                                style="width:100%" class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker
                                                #idh70="ngbDatepicker">
                            
                                            <div class="input-group-append">
                            
                                                <button class="btn btn-outline-secondary calendar" (click)="idh70.toggle()" type="button"> <i
                                                        class="fas fa-calendar-alt"></i> </button>
                            
                                            </div>
                                            </div>
                                            <!-- </div> -->
                                            <div class="text-danger"
                                                *ngIf="intermedimage.controls['3396'].invalid && (intermedimage.controls['3396'].value==''|| intermedimage.controls['3396'].value==null) ">
                                                <small>
                                                    Required
                                                </small>
                                            
                                            </div>
                                            <div *ngIf="intermedimage.get('3396').invalid">
                                                <small class="text-danger" *ngIf="intermedimage.get('3396').errors.invalidDateFormat">
                                                    Only Date Format is allowed(MM/DD/YYYY) </small>
                                                <small class="text-danger" *ngIf="intermedimage.get('3396').errors.invalidMonth">
                                                    Month is out of range
                                                </small>
                                                <small class="text-danger" *ngIf="intermedimage.get('3396').errors.invalidDateyear">
                                                    year is out of range(1900-2099) </small>
                                                <small class="text-danger" *ngIf="intermedimage.get('3396').errors.invalidDateRange">
                                                    date is out of range
                                                </small>
                                            </div>
                            
                            
                                        <!-- </div> -->
                            
                                    </div>
                            
                                </td>
                            
                            </tr>
                            <tr class="col-12">
                            
                                <td colspan="4">
                            
                                    <div class="row">
                            
                                        <div class="col-12">
                            
                                            <input type="text" (blur)="handleNAchange('cmd_0059')" id="1831" [class.is-invalid]="(
                                                            
                                                            intermedimage.controls['1831'].invalid) "
                                                placeholder="Explanation for Gap in Work History 2" formControlName="1831" class="form-control">
                            
                                            <div class="invalid-feedback"
                                                *ngIf="(intermedimage.controls['1831'].touched && intermedimage.controls['1831'].invalid) || (intermedimage.controls['1831'].invalid && isSubmit)">
                                                Required </div>
                            
                                        </div>
                            
                                    </div>
                            
                                </td>
                            
                                <td colspan="4">
                            
                                    <div class="row">
                            
                                        <!-- <div class="col-4">
                            
                                            <label for="346">From(mm/dd/yy):</label>
                            
                                        </div> -->
                            
                                        <div class="col-10 ">
                                          <div class="input-group">
                            
                                            <input type="text" (blur)="handleNAchange('cmd_0059')" (blur)="clearInputIfInvalid('3397','cmd_0059')"  id="3397" placeholder="Gap 2 Start Date" [class.is-invalid]="
                                                            
                                                            intermedimage.controls['3397'].invalid" formControlName="3397"
                                                class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker
                                                #idh71="ngbDatepicker">
                            
                                            <div class="input-group-append">
                            
                                                <button class="btn btn-outline-secondary calendar" (click)="idh71.toggle()" type="button"> <i
                                                        class="fas fa-calendar-alt"></i> </button>
                            
                                            </div>
                                            </div>
                                            <div class="text-danger"
                                            *ngIf="intermedimage.controls['3397'].invalid && (intermedimage.controls['3397'].value==''|| intermedimage.controls['3397'].value==null) ">
                                            <small>
                                                Required
                                            </small>
                                        
                                        </div>
                                        <div *ngIf="intermedimage.get('3397').invalid">
                                            <small class="text-danger" *ngIf="intermedimage.get('3397').errors.invalidDateFormat">
                                                Only Date Format is allowed(MM/DD/YYYY) </small>
                                            <small class="text-danger" *ngIf="intermedimage.get('3397').errors.invalidMonth">
                                                Month is out of range
                                            </small>
                                            <small class="text-danger" *ngIf="intermedimage.get('3397').errors.invalidDateyear">
                                                year is out of range(1900-2099) </small>
                                            <small class="text-danger" *ngIf="intermedimage.get('3397').errors.invalidDateRange">
                                                date is out of range
                                            </small>
                                        </div>
                            
                            
                                        </div>
                            
                                    </div>
                            
                                </td>
                            
                                <td colspan="4">
                            
                                    <div class="row">
                            
                                        <!-- <div class="col-4">
                            
                                            <label for="346">To (mm/dd/yy):</label>
                            
                                        </div> -->
                            
                                        <div class="col-8 ">
                                          <div class="input-group">
                            
                                            <input type="text" (blur)="handleNAchange('cmd_0059')" (blur)="clearInputIfInvalid('3398','cmd_0059')"  id="3398" placeholder="Gap 2 End Date" [class.is-invalid]="
                                                            
                                                            intermedimage.controls['3398'].invalid" formControlName="3398"
                                                class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker
                                                #idh72="ngbDatepicker">
                            
                                            <div class="input-group-append">
                            
                                                <button class="btn btn-outline-secondary calendar" (click)="idh72.toggle()" type="button"> <i
                                                        class="fas fa-calendar-alt"></i> </button>
                            
                                            </div>
                                            </div>
                                            <div class="text-danger"
                                            *ngIf="intermedimage.controls['3398'].invalid && (intermedimage.controls['3398'].value==''|| intermedimage.controls['3398'].value==null) ">
                                            <small>
                                                Required
                                            </small>
                                        
                                        </div>
                                        <div *ngIf="intermedimage.get('3398').invalid">
                                            <small class="text-danger" *ngIf="intermedimage.get('3398').errors.invalidDateFormat">
                                                Only Date Format is allowed(MM/DD/YYYY) </small>
                                            <small class="text-danger" *ngIf="intermedimage.get('3398').errors.invalidMonth">
                                                Month is out of range
                                            </small>
                                            <small class="text-danger" *ngIf="intermedimage.get('3398').errors.invalidDateyear">
                                                year is out of range(1900-2099) </small>
                                            <small class="text-danger" *ngIf="intermedimage.get('3398').errors.invalidDateRange">
                                                date is out of range
                                            </small>
                                        </div>
                            
                                        </div>
                            
                                    </div>
                            
                                </td>
                            
                            </tr>
                            <tr class="col-12">
                            
                                <td colspan="4">
                            
                                    <div class="row">
                            
                                        <div class="col-12">
                            
                                            <input type="text" (blur)="handleNAchange('cmd_0059')" id="1832" [class.is-invalid]="
                                                            
                                                            intermedimage.controls['1832'].invalid"
                                                placeholder="Explanation for Gap in Work History 3" formControlName="1832" class="form-control">
                            
                                            <div class="invalid-feedback"
                                                *ngIf="(intermedimage.controls['1832'].touched && intermedimage.controls['1832'].invalid) || (intermedimage.controls['1832'].invalid && isSubmit)">
                                                Required </div>
                            
                                        </div>
                            
                                    </div>
                            
                                </td>
                            
                                <td colspan="4">
                            
                                    <div class="row">
                            
                                        <!-- <div class="col-4">
                            
                                            <label for="3399">From(mm/dd/yy):</label>
                            
                                        </div> -->
                            
                                        <div class="col-10 ">
                                          <div class="input-group">
                            
                                            <input type="text" (blur)="handleNAchange('cmd_0059')" (blur)="clearInputIfInvalid('3399','cmd_0059')"  id="3399" placeholder="Gap 3 Start Date" [class.is-invalid]="
                                                            
                                                            intermedimage.controls['3399'].invalid" formControlName="3399"
                                                class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker
                                                #idh73="ngbDatepicker">
                            
                                            <div class="input-group-append">
                            
                                                <button class="btn btn-outline-secondary calendar" (click)="idh73.toggle()" type="button"> <i
                                                        class="fas fa-calendar-alt"></i> </button>
                            
                                            </div>
                                            </div>
                                            <div class="text-danger"
                                            *ngIf="intermedimage.controls['3399'].invalid && (intermedimage.controls['3399'].value==''|| intermedimage.controls['3399'].value==null) ">
                                            <small>
                                                Required
                                            </small>
                                        
                                        </div>
                                        <div *ngIf="intermedimage.get('3399').invalid">
                                            <small class="text-danger" *ngIf="intermedimage.get('3399').errors.invalidDateFormat">
                                                Only Date Format is allowed(MM/DD/YYYY) </small>
                                            <small class="text-danger" *ngIf="intermedimage.get('3399').errors.invalidMonth">
                                                Month is out of range
                                            </small>
                                            <small class="text-danger" *ngIf="intermedimage.get('3399').errors.invalidDateyear">
                                                year is out of range(1900-2099) </small>
                                            <small class="text-danger" *ngIf="intermedimage.get('3399').errors.invalidDateRange">
                                                date is out of range
                                            </small>
                                        </div>
                            
                            
                                        </div>
                            
                                    </div>
                            
                                </td>
                            
                                <td colspan="4">
                            
                                    <div class="row">
                            
                                        <!-- <div class="col-4">
                            
                                            <label for="3400">To (mm/dd/yy):</label>
                            
                                        </div> -->
                            
                                        <div class="col-8 ">
                                          <div class="input-group">
                            
                                            <input type="text" (blur)="handleNAchange('cmd_0059')" (blur)="clearInputIfInvalid('3400','cmd_0059')"  id="3400" placeholder="Gap 3 End Date" [class.is-invalid]="
                                                            
                                                            intermedimage.controls['3400'].invalid" formControlName="3400"
                                                class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker
                                                #idh74="ngbDatepicker">
                            
                                            <div class="input-group-append">
                            
                                                <button class="btn btn-outline-secondary calendar" (click)="idh74.toggle()" type="button"> <i
                                                        class="fas fa-calendar-alt"></i> </button>
                            
                                            </div>
                                            </div>
                                            <div class="text-danger"
                                                *ngIf="intermedimage.controls['3400'].invalid && (intermedimage.controls['3400'].value==''|| intermedimage.controls['3400'].value==null) ">
                                                <small>
                                                    Required
                                                </small>
                                             
                                            </div>
                                            <div *ngIf="intermedimage.get('3400').invalid">
                                                <small class="text-danger" *ngIf="intermedimage.get('3400').errors.invalidDateFormat">
                                                    Only Date Format is allowed(MM/DD/YYYY) </small>
                                                <small class="text-danger" *ngIf="intermedimage.get('3400').errors.invalidMonth">
                                                    Month is out of range
                                                </small>
                                                <small class="text-danger" *ngIf="intermedimage.get('3400').errors.invalidDateyear">
                                                    year is out of range(1900-2099) </small>
                                                <small class="text-danger" *ngIf="intermedimage.get('3400').errors.invalidDateRange">
                                                    date is out of range
                                                </small>
                                            </div>
                            
                            
                                        </div>
                            
                                    </div>
                            
                                </td>
                            
                            </tr>
                                
                         </div>
                  </td>
                   </tr>  
                    <ctg-Intermed-llc-Section1 [intermedimage1]="intermedimage"  
                         [showOne]="showOne" [finalImgPath]="finalImgPath"
                          [esignOne]="esignOne" [showTwo]="showTwo" [esignTwo]="esignTwo" 
                           [finalImgPathTwo]="finalImgPathTwo"
                           (isHandleNAChanges)="handleNAchange($event)"
                           (isclearInputIfInvalid)="clearInputIfInvalid($event)"
                           (isclearInputIfInvalidMandi)="clearInputIfInvalidMandi($event)"
                           [finalImgTwo]="finalImgTwo"   [isSubmit]="isSubmit">
                    </ctg-Intermed-llc-Section1>                                                            
              </div>
          </tbody>
        </table>
    </form>
    <div class="modal-footer mt-4">
        <button *ngIf="formData.receivedForm" type="button" class="btn customOrange float-right"
            (click)="saveForm('approve')"> Approve </button>
        <button *ngIf="formData.submitForm" type="button" class="btn btn customOrange float-right"
            (click)="saveForm('submit')"> Submit </button>
        <button *ngIf="formData.submitForm" type="button" class="btn btn customOrange float-right"
            (click)="saveForm('save')"> Save </button>
        <button *ngIf="formData.previewForm" type="button" class="btn customOrange float-right" (click)="close()"> Close
        </button>
    </div>

</div> 