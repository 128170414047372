export class UserFieldConstant{
    public static cmd:any="cmd";
    public static workflowMod:any="mwrkf";
    public static workflow:any= "wrkf";
    public static createTask:any= "task";
    public static createNote:any="nte";
    public static addComLog:any= "c_log";
    public static providerPassport:any= "pro";

    public static showAllProvider:any= "all_pro";
    public static providerPortalList:any= "port_list";
    public static portalNotActivted:any= "pro_nact";

    public static manageTInLocation:any= "tloc";
    public static expiration:any= "exp";

    public static reportsMod:any="repo";
    public static report:any= "rep";
    public static ftcaReport:any="ftca";
    public static portalReports:any="port_rep";
    public static Report2:any= "report";
    public static tinReports:any= "tin_rep";
    public static providerRoasterReport:any="pro_rep";

    public static settingMod:any="settings";
    public static history:any="hist";
    public static userManagement:any= "uman";
    public static addUser:any= "auser";
    public static providerPortalUser:any="quser";
    public static uniformFields:any="cre_co";

    public static support:any="sup";

    /*Custom values*/
    public static dopAccess:any="dop";
    public static caqhsyncAccess:any="caqhsync";
    public static cannedReportScheduleAccess:any="cannedReportScheduleaccess";
    public static onBoardingFormAccess:any="onboardingformAccess";
    public static docsignAccess:any="docsignAccess";
    public static apiAccess:any="apiAccess";
    public static pendingForm:any='pendingForms';
    public static providerlogin: any = 'providerlogin';

}