import { Injectable } from '@angular/core';
import { ApiServiceService } from 'apps/cmd-frontend/src/app/shared/services/cmd-api-service/api-service.service';

@Injectable({
  providedIn: 'root'
})
export class MedicalStaffService {

  constructor(
    private apiService:ApiServiceService
  ) { }

  public getFacilityDropdown1(postParams){
    return this.apiService.post('medicalstaffverification/facilitydropdown', postParams);
  }

  public searchProvider(postParams){
    return this.apiService.post('medicalstaffverification/searchProvider', postParams);
  }

  public DownloadData(postParams){
    return this.apiService.post('medicalstaffverification/pdfgenrate', postParams);
  }



}
