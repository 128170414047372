import { Injectable } from '@angular/core';
import { ApiServiceService } from '../cmd-api-service/api-service.service';

@Injectable({
  providedIn: 'root'
})
export class CreateTaskService {

  constructor(private apiService:ApiServiceService) { }

  public tinMappedRecords(postParams){
    return this.apiService.post('workflow/tinmappedrecords', postParams);
  }
  public saveTask(postParams){
    return this.apiService.post('workflow/savetask', postParams);
  }
  public saveTaskFile(postParams){
    return this.apiService.postFile('workflow/savetask', postParams);
  }
  public Affliation(postParams){
    return this.apiService.post('providerpassport/affiliation', postParams);
  }
}
