<div id="medSpring">
    <div class="cotainer-fluid mx-1">
        <div class="header">
            <h2 class="blueColour"><b>MedSpring Choice One Urgent Care</b></h2>
        </div>
        <hr>
        <div class="section">
            <form [formGroup]="medSpring">
                <div class="title">
                    <h4>
                        PERSONAL INFORMATION:
                    </h4>
                </div>
                <div class="dataBox">
                    <div class="row ">
                        <div class="col-md-4">
                            <div class="row">
                                <label for="fName" class="col-sm-4 pt-2 formLabel">First Name<span
                                        class="mandatory">*</span></label>
                                <input  type="text" placeholder="First Name" id="fName" class="form-control col-md-8" formControlName="8" [class.is-invalid]="(medSpring.controls['8'].touched && medSpring.controls['8'].invalid ) || (medSpring.controls['8'].invalid && isSubmit)">
                                <div class="formLabel col-md-11"                                                                     
                                    *ngIf="medSpring.controls['8'].touched && medSpring.controls['8'].invalid">
                                    <small class="text-danger" *ngIf="medSpring.controls['8'].errors?.required">The
                                        First name is required and cannot be empty</small>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="row">
                                <label for="lName" class="col-sm-4 pt-2 formLabel">Last Name<span
                                        class="mandatory">*</span></label>
                                <input type="text" placeholder="Last Name" id="lName" class="form-control col-md-8" formControlName="9" [class.is-invalid]="(medSpring.controls['9'].touched && medSpring.controls['9'].invalid ) || (medSpring.controls['9'].invalid && isSubmit)">
                                <div class="formLabel col-md-11"
                                    *ngIf="medSpring.controls['9'].touched && medSpring.controls['9'].invalid">
                                    <small class="text-danger" *ngIf="medSpring.controls['9'].errors?.required">The Last
                                        name is required and cannot be empty</small>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="row">
                                <label for="822" class="col-sm-4 pt-2 formLabel">Credential<span class="mandatory">*</span></label>
                                <div class="form-group selectDrop col-md-8">
                                    <ng-select [items]="credentials" bindLabel="name" placeholder="Select Credential" [class.is-invalid]="(medSpring.controls['822'].touched && medSpring.controls['822'].invalid ) || (medSpring.controls['822'].invalid && isSubmit)" bindValue="id" formControlName="822">
                                    </ng-select>
                                    <div *ngIf="medSpring.controls['822'].touched && medSpring.controls['822'].invalid">
                                        <small class="text-danger"
                                            *ngIf="medSpring.controls['822'].errors?.required">The Credential is
                                            required and cannot be empty</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="form-group col-md-12 ml-4">
                            <div class="form-check form-check-inline ml-4">
                                <label class="form-check-label" for="inlineRadio1Male">Male</label>
                                <input formControlName='30' class="form-check-input m-1 mt-1" type="radio"
                                    value="1" id="inlineRadio1Male">
                            </div>
                            <div class="form-check form-check-inline ml-4">
                                <label class="form-check-label" for="inlineRadio2Female">Female</label>
                                <input formControlName='30' class="form-check-input ml-1 mt-1" type="radio"
                                    value="0" id="inlineRadio2Female">
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-md-3">
                            <div class="row">
                                <label for="31" class="col-sm-4 pt-2 formLabel">DOB<span
                                        class="mandatory">*</span></label>
                                <div class="form-group col-md-8 ">
                                    <div class="input-group">
                                        <input class="form-control" formControlName="31" (blur)="clearInputIfInvalidMandi('31')"  [class.is-invalid]="(medSpring.controls['31'].touched && medSpring.controls['31'].invalid ) || (medSpring.controls['31'].invalid && isSubmit)" name="dp" placeholder="From Date" ngbDatepicker #d17="ngbDatepicker" placement="bottom-left">
                                        <div class="input-group-append">
                                            <button class="btn btn-outline-secondary calendar" (click)="d17.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                        </div>
                                    </div>
                                    <div *ngIf="medSpring.controls['31'].touched && medSpring.controls['31'].invalid">
                                        <small class="text-danger" *ngIf="medSpring.controls['31'].errors?.required">The
                                            DOB is required and cannot be empty</small>
                                    </div>
                                    <div>
                                        <small class="text-danger" *ngIf="medSpring.get('31').errors?.invalidDateFormat">
                                          Only Date Format is allowed(MM/DD/YYYY)</small>
                                        <small class="text-danger" *ngIf="medSpring.get('31').errors?.invalidMonth">
                                         Month is out of range
                                        </small>
                                        <small class="text-danger" *ngIf="medSpring.get('31').errors?.invalidDateyear">
                                          year is out of range(1900-2099)</small>
                                         <small class="text-danger" *ngIf="medSpring.get('31').errors?.invalidDateRange">
                                         date is out of range
                                          </small>
                                        </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-9">
                            <div class="row">
                                <label for="810" class="col-sm-3 pt-2 formLabel">Place of Birth<span
                                        class="mandatory">*</span></label>
                                <input type="text" placeholder="City" id="810" class="form-control col-md-3" formControlName="810" [class.is-invalid]="(medSpring.controls['810'].touched && medSpring.controls['810'].invalid ) || (medSpring.controls['810'].invalid && isSubmit)">
                                <div class="form-group selectDrop col-md-3">                                    
                                    <ng-select [items]="states" bindLabel="name" placeholder="Select States" bindValue="id" formControlName="289">
                                    </ng-select>
                                </div>
                                <input type="text" placeholder="Country of Birth" id="Birth" class="form-control col-md-3" formControlName="291" [class.is-invalid]="(medSpring.controls['291'].touched && medSpring.controls['291'].invalid ) || (medSpring.controls['291'].invalid && isSubmit)">
                                <div class="formLabel col-md-6"
                                    *ngIf="medSpring.controls['810'].touched && medSpring.controls['810'].invalid">
                                    <small class="text-danger" *ngIf="medSpring.controls['810'].errors?.required">The
                                        Place of Birth and Country is required and cannot be empty</small>
                                </div>
                                <div class="formLabel col-md-6"
                                    *ngIf="medSpring.controls['291'].touched && medSpring.controls['291'].invalid">
                                    <small class="text-danger" *ngIf="medSpring.controls['291'].errors?.required">The
                                        Country of Birth is required and cannot be empty</small>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-md-4">
                            <div class="row">
                                <label for="32" class="col-sm-4 pt-2 formLabel">SSN<span
                                        class="mandatory">*</span></label>
                                <input type="text" placeholder="Enter SSN" id="32" class="form-control col-md-8" formControlName="32" [class.is-invalid]="(medSpring.controls['32'].touched && medSpring.controls['32'].invalid ) || (medSpring.controls['32'].invalid && isSubmit)">
                                <div class="formLabel col-md-11"
                                    *ngIf="medSpring.controls['32'].touched && medSpring.controls['32'].invalid">
                                    <small class="text-danger" *ngIf="medSpring.controls['32'].errors?.required">The SSN
                                        is required and cannot be empty</small>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="row">
                                <label for="709" class="col-sm-4 pt-2 formLabel">Phone<span
                                        class="mandatory">*</span></label>
                                <input type="text" placeholder="Enter Phone" id="709" class="form-control col-md-8" formControlName="709" [class.is-invalid]="(medSpring.controls['709'].touched && medSpring.controls['709'].invalid ) || (medSpring.controls['709'].invalid && isSubmit)">
                                <div class="formLabel col-md-11"
                                    *ngIf="medSpring.controls['709'].touched && medSpring.controls['709'].invalid">
                                    <small class="text-danger" *ngIf="medSpring.controls['709'].errors?.required">The
                                        Phone is required and cannot be empty</small>
                                    <small class="text-danger" *ngIf="medSpring.controls['709'].errors?.pattern">
                                        Only number are allowed</small>    
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="row">
                                <label for="759" class="col-sm-4 pt-2 formLabel">Email<span
                                        class="mandatory">*</span></label>
                                <input type="text" placeholder="Enter Email" id="759" class="form-control col-md-8" formControlName="759" [class.is-invalid]="(medSpring.controls['759'].touched && medSpring.controls['759'].invalid ) || (medSpring.controls['759'].invalid && isSubmit)">
                                <div class="formLabel col-md-11"
                                    *ngIf="medSpring.controls['759'].touched && medSpring.controls['759'].invalid">
                                    <small class="text-danger" *ngIf="medSpring.controls['759'].errors?.required">The
                                        Email is required and cannot be empty</small>
                                    <small class="text-danger" *ngIf="medSpring.controls['759'].errors?.pattern">The
                                        Email must be a valid email address</small>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-md-4">
                            <div class="row">
                                <label for="16" class="col-sm-4 pt-2 formLabel">Home Address</label>
                                <input type="text" placeholder="Home Address 1" id="16" class="form-control col-md-8"
                                    formControlName="16">
                            </div>
                        </div>
                        <input type="text" placeholder="Home Address 2" id="17" class="form-control col-md-3"
                            formControlName="17">
                        <input type="text" placeholder="City" id="18" class="form-control col-md-3"
                            formControlName="18">
                        <div class="form-group selectDrop col-md-2">
                            <ng-select [items]="states" bindLabel="name" placeholder="Select State"
                                bindValue="id" formControlName="20">
                            </ng-select>
                        </div>
                        <div class="col-md-4">
                            <div class="row">
                                <label for="21" class="col-sm-4 pt-2 formLabel"><span></span></label>
                                <input type="text" placeholder="Zip" id="21" 
                                [class.is-invalid]="(medSpring.controls['21'].invalid && isSubmit)"
                                class="form-control col-md-8"
                                formControlName="21">
                                <div class="formLabel col-md-11"
                                    *ngIf="medSpring.controls['21'].invalid">
                                    <small class="text-danger" *ngIf="medSpring.controls['21'].errors?.minLength">
                                        Should not be less then 5 digits</small>
                                    <small class="text-danger" *ngIf="medSpring.controls['21'].errors?.maxLength">
                                        Should not be more then 10 digits</small> 
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr>
                </div>
                <div class="title">
                    <h4>
                        LICENSING INFORMATION:
                    </h4>
                </div>
                <div class="dataBox">
                    <div class="row ">
                        <div class="col-md-4">
                            <div class="row">
                                <label for="1634" class="col-sm-4 pt-2 formLabel">NPI#<span
                                        class="mandatory">*</span></label>
                                <input type="text" placeholder="NPI" id="1634" class="form-control col-md-8" formControlName="1634" [class.is-invalid]="(medSpring.controls['1634'].touched && medSpring.controls['1634'].invalid ) || (medSpring.controls['1634'].invalid && isSubmit)">
                                <div class="formLabel col-md-10"
                                    *ngIf="medSpring.controls['1634'].touched && medSpring.controls['1634'].invalid">
                                    <small class="text-danger" *ngIf="medSpring.controls['1634'].errors?.required">The
                                        NPI is required and cannot be empty</small>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="row">
                                <label for="293" class="col-sm-4 pt-2 formLabel">License<span
                                        class="mandatory">*</span></label>
                                <input type="text" placeholder="License" id="293" class="form-control col-md-8" formControlName="293" [class.is-invalid]="(medSpring.controls['293'].touched && medSpring.controls['293'].invalid ) || (medSpring.controls['293'].invalid && isSubmit)">
                                <div class="formLabel col-md-11"
                                    *ngIf="medSpring.controls['293'].touched && medSpring.controls['293'].invalid">
                                    <small class="text-danger" *ngIf="medSpring.controls['293'].errors?.required">The
                                        License is required and cannot be empty</small>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="row">
                                <label for="cmd_0400" class="col-sm-4 pt-2 formLabel">DOT cert</label>
                                <input type="text" placeholder="DOT cert" id="cmd_0400" class="form-control col-md-8"
                                    formControlName="cmd_0400">
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="row ">
                        <!-- <div class="col-md-4">
                            <div class="row">
                                <label for="311" class="col-sm-4 pt-2 formLabel">DEA</label>
                                <input type="text" placeholder="DEA" id="311" class="form-control col-md-8"
                                    formControlName="311">
                                <div class="input-group">
                                    <input class="form-control" formControlName="311" name="dp"
                                        placeholder="Select Date" ngbDatepicker #d40="ngbDatepicker"
                                        placement="bottom-left">
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d40.toggle()"
                                            type="button"><i class="fas fa-calendar-alt"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>     -->
                        <div class="col-md-4">
                            <div class="row">
                                <label for="309" class="col-sm-4 pt-2 formLabel">DEA</label>
                                <input type="text" placeholder="DEA" id="309" class="form-control col-md-8"
                                formControlName="309">
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="row">
                                <label for="337" class="col-sm-4 pt-2 formLabel">CDS</label>
                                <input type="text" placeholder="CDS" id="337" class="form-control col-md-8"
                                    formControlName="337">
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="row">
                                <label for="cmd_0401" class="col-sm-4 pt-2 formLabel">ACLS/BLS</label>
                                <input type="text" placeholder="ACLS/BLS" id="cmd_0401" class="form-control col-md-8"
                                    formControlName="cmd_0401">
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-md-4">
                            <div class="row">
                                <label for="554" class="col-sm-4 pt-2 formLabel">Certifying Board</label>
                                <div class="form-group selectDrop col-md-8">
                                    <ng-select [items]="boardCertification" bindLabel="name" placeholder="Select"
                                        bindValue="id" formControlName="554">
                                    </ng-select>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="row">
                                <label for="1169" class="col-sm-4 pt-2 formLabel">Certification Number</label>
                                <input type="text" placeholder="Certification Number" id="1169"
                                    class="form-control col-md-8" formControlName="1169">
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-md-4">
                            <div class="row">
                                <label for="560" class="col-sm-4 pt-2 formLabel">Expiration Date</label>
                                <div class="form-group col-md-8 ">
                                    <div class="input-group">
                                        <input class="form-control" formControlName="560" name="dp" (blur)="clearInputIfInvalid('560')"
                                            placeholder="Select Date" ngbDatepicker #d18="ngbDatepicker"
                                            placement="bottom-left">
                                        <div class="input-group-append">
                                            <button class="btn btn-outline-secondary calendar" (click)="d18.toggle()"
                                                type="button"><i class="fas fa-calendar-alt"></i></button>
                                        </div>
                                        <div *ngIf="medSpring.get('560').invalid ">
                                            <small class="text-danger" *ngIf="medSpring.get('560').errors.invalidDateFormat">
                                             Only Date Format is allowed(MM/DD/YYYY)</small>
                                            <small class="text-danger" *ngIf="medSpring.get('560').errors.invalidMonth">
                                            Month is out of range
                                            </small>
                                           <small class="text-danger" *ngIf="medSpring.get('560').errors.invalidDateyear">
                                             year is out of range(1900-2099) </small>
                                           <small class="text-danger" *ngIf="medSpring.get('560').errors.invalidDateRange">
                                           date is out of range
                                           </small>
                                           </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="title">
                    <h4>
                        LOGIN INFORMATION:
                    </h4>
                </div>
                <div class="dataBox">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="row">
                                <label for="2108" class="col-sm-4 pt-2 formLabel">PECOS Username</label>
                                <input type="text" placeholder="PECOS Username" id="2108" class="form-control col-md-8"
                                    formControlName="2108">
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="row">
                                <label for="2109" class="col-sm-4 pt-2 formLabel">PECOS Password</label>
                                <input type="text" placeholder="PECOS Password" id="2109" class="form-control col-md-8"
                                    formControlName="2109">
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-md-4">
                            <div class="row">
                                <label for="333" class="col-sm-4 pt-2 formLabel">CAQH ID<span
                                        class="mandatory">*</span></label>
                                <input type="text" placeholder="CAQH ID" id="333" class="form-control col-md-8" formControlName="333" [class.is-invalid]="(medSpring.controls['333'].touched && medSpring.controls['333'].invalid ) || (medSpring.controls['333'].invalid && isSubmit)">
                                <div class="formLabel col-md-11"
                                    *ngIf="medSpring.controls['333'].touched && medSpring.controls['333'].invalid">
                                    <small class="text-danger" *ngIf="medSpring.controls['333'].errors?.required">The
                                        CAQH ID is required and cannot be empty</small>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="row">
                                <label for="334" class="col-sm-4 pt-2 formLabel">CAQH Username<span
                                        class="mandatory">*</span></label>
                                <input type="text" placeholder="CAQH Username" id="334" class="form-control col-md-8" formControlName="334" [class.is-invalid]="(medSpring.controls['334'].touched && medSpring.controls['334'].invalid ) || (medSpring.controls['334'].invalid && isSubmit)">
                                <div class="formLabel col-md-12"
                                    *ngIf="medSpring.controls['334'].touched && medSpring.controls['334'].invalid">
                                    <small class="text-danger" *ngIf="medSpring.controls['334'].errors?.required">The
                                        CAQH Username is required and cannot be empty</small>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-md-4">
                            <div class="row">
                                <label for="335" class="col-sm-4 pt-2 formLabel">CAQH Password<span
                                        class="mandatory">*</span></label>
                                <input type="text" placeholder="CAQH Password" id="335" class="form-control col-md-8" formControlName="335" [class.is-invalid]="(medSpring.controls['335'].touched && medSpring.controls['335'].invalid ) || (medSpring.controls['335'].invalid && isSubmit)">
                                <div class="formLabel col-md-12"
                                    *ngIf="medSpring.controls['335'].touched && medSpring.controls['335'].invalid">
                                    <small class="text-danger" *ngIf="medSpring.controls['335'].errors?.required">The
                                        CAQH Password is required and cannot be empty</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="title">
                    <h4>
                        EDUCATION:
                    </h4>
                </div>
                <div class="dataBox">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="row">
                                <label for="503" class="col-sm-4 pt-2 formLabel">UnderGrad School</label>
                                <input type="text" placeholder="UnderGrad School" id="503" class="form-control col-md-8"
                                    formControlName="503">
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="row">
                                <label for="511" class="col-sm-4 pt-2 formLabel">Date CoMLPeted</label>
                                <div class="form-group col-md-8 ">
                                    <div class="input-group">
                                        <input class="form-control" formControlName="511" name="dp" (blur)="clearInputIfInvalid('511')"
                                            placeholder="Date CoMLPeted" ngbDatepicker #d19="ngbDatepicker"
                                            placement="bottom-left">
                                        <div class="input-group-append">
                                            <button class="btn btn-outline-secondary calendar" (click)="d19.toggle()"
                                                type="button"><i class="fas fa-calendar-alt"></i></button>
                                        </div>
                                        <div *ngIf="medSpring.get('511').invalid ">
                                            <small class="text-danger" *ngIf="medSpring.get('511').errors.invalidDateFormat">
                                             Only Date Format is allowed(MM/DD/YYYY)</small>
                                            <small class="text-danger" *ngIf="medSpring.get('511').errors.invalidMonth">
                                            Month is out of range
                                            </small>
                                           <small class="text-danger" *ngIf="medSpring.get('511').errors.invalidDateyear">
                                             year is out of range(1900-2099) </small>
                                           <small class="text-danger" *ngIf="medSpring.get('511').errors.invalidDateRange">
                                           date is out of range
                                           </small>
                                           </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-md-4">
                            <div class="row">
                                <label for="479" class="col-sm-4 pt-2 formLabel">Grad School</label>
                                <input type="text" placeholder="Grad School" id="479" class="form-control col-md-8"
                                    formControlName="479">
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="row">
                                <label for="482" class="col-sm-4 pt-2 formLabel">Date CoMLPeted</label>
                                <div class="form-group col-md-8 ">
                                    <div class="input-group">
                                        <input class="form-control" formControlName="482" name="dp" (blur)="clearInputIfInvalid('482')"
                                            placeholder="Date CoMLPeted" ngbDatepicker #d20="ngbDatepicker"
                                            placement="bottom-left">
                                        <div class="input-group-append">
                                            <button class="btn btn-outline-secondary calendar" (click)="d20.toggle()"
                                                type="button"><i class="fas fa-calendar-alt"></i></button>
                                        </div>
                                        <div *ngIf="medSpring.get('482').invalid ">
                                            <small class="text-danger" *ngIf="medSpring.get('482').errors.invalidDateFormat">
                                             Only Date Format is allowed(MM/DD/YYYY)</small>
                                            <small class="text-danger" *ngIf="medSpring.get('482').errors.invalidMonth">
                                            Month is out of range
                                            </small>
                                           <small class="text-danger" *ngIf="medSpring.get('482').errors.invalidDateyear">
                                             year is out of range(1900-2099) </small>
                                           <small class="text-danger" *ngIf="medSpring.get('482').errors.invalidDateRange">
                                           date is out of range
                                           </small>
                                           </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <div class="row">
                                <label for="490" class="col-sm-4 pt-2 formLabel">Medical School</label>
                                <input type="text" placeholder="Medical School" id="490" class="form-control col-md-8"
                                    formControlName="490">
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="row">
                                <label for="499" class="col-sm-4 pt-2 formLabel">Date CoMLPeted</label>
                                <div class="form-group col-md-8 ">
                                    <div class="input-group">
                                        <input class="form-control" formControlName="499" name="dp" (blur)="clearInputIfInvalid('499')"
                                            placeholder="Date CoMLPeted" ngbDatepicker #d21="ngbDatepicker"
                                            placement="bottom-left">
                                        <div class="input-group-append">
                                            <button class="btn btn-outline-secondary calendar" (click)="d21.toggle()"
                                                type="button"><i class="fas fa-calendar-alt"></i></button>
                                        </div>
                                        <div *ngIf="medSpring.get('499').invalid ">
                                            <small class="text-danger" *ngIf="medSpring.get('499').errors.invalidDateFormat">
                                             Only Date Format is allowed(MM/DD/YYYY)</small>
                                            <small class="text-danger" *ngIf="medSpring.get('499').errors.invalidMonth">
                                            Month is out of range
                                            </small>
                                           <small class="text-danger" *ngIf="medSpring.get('499').errors.invalidDateyear">
                                             year is out of range(1900-2099) </small>
                                           <small class="text-danger" *ngIf="medSpring.get('499').errors.invalidDateRange">
                                           date is out of range
                                           </small>
                                           </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="title">
                    <h4>
                        RESIDENCY:
                    </h4>
                </div>
                <div class="dataBox">
                    <div class="row">
                        <div class="form-group col-md-12 ml-4">
                            <div class="form-check form-check-inline ml-4">
                                <input formControlName="cmd_0424" class="form-check-input m-1 mt-1" type="radio"
                                    value="1" id="inlineRadio1Yes">
                                <label class="form-check-label" for="inlineRadio1Yes">Yes</label>
                            </div>
                            <div class="form-check form-check-inline ml-4">
                                <input formControlName="cmd_0424" class="form-check-input ml-1 mt-1" type="radio"
                                    value="0" id="inlineRadio2No">
                                <label class="form-check-label" for="inlineRadio2No">No (if no, skip this
                                    section)</label>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-md-4">
                            <div class="row">
                                <label for="529" class="col-sm-4 pt-2 formLabel">Institution</label>
                                <input type="text" placeholder="Institution" id="529" class="form-control col-md-8"
                                    formControlName="529">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row">
                                <label for="537" class="col-sm-3 pt-2 formLabel">Attendance Dates</label>
                                <div class="form-group col-md-4 ">
                                    <div class="input-group">
                                        <input class="form-control" formControlName="537" name="dp" placeholder="Date" (blur)="clearInputIfInvalid('537')"
                                            ngbDatepicker #d22="ngbDatepicker" placement="bottom-left">
                                        <div class="input-group-append">
                                            <button class="btn btn-outline-secondary calendar" (click)="d22.toggle()"
                                                type="button"><i class="fas fa-calendar-alt"></i></button>
                                        </div>
                                        <div *ngIf="medSpring.get('537').invalid ">
                                            <small class="text-danger" *ngIf="medSpring.get('537').errors.invalidDateFormat">
                                             Only Date Format is allowed(MM/DD/YYYY)</small>
                                            <small class="text-danger" *ngIf="medSpring.get('537').errors.invalidMonth">
                                            Month is out of range
                                            </small>
                                           <small class="text-danger" *ngIf="medSpring.get('537').errors.invalidDateyear">
                                             year is out of range(1900-2099) </small>
                                           <small class="text-danger" *ngIf="medSpring.get('537').errors.invalidDateRange">
                                           date is out of range
                                           </small>
                                           </div>
                                    </div>
                                </div>
                                <div class="form-group col-md-4 ">
                                    <div class="input-group">
                                        <input class="form-control" formControlName="538" name="dp" placeholder="Date" (blur)="clearInputIfInvalid('538')"
                                            ngbDatepicker #d23="ngbDatepicker" placement="bottom-left">
                                        <div class="input-group-append">
                                            <button class="btn btn-outline-secondary calendar" (click)="d23.toggle()"
                                                type="button"><i class="fas fa-calendar-alt"></i></button>
                                        </div>
                                        <div *ngIf="medSpring.get('538').invalid ">
                                            <small class="text-danger" *ngIf="medSpring.get('538').errors.invalidDateFormat">
                                             Only Date Format is allowed(MM/DD/YYYY)</small>
                                            <small class="text-danger" *ngIf="medSpring.get('538').errors.invalidMonth">
                                            Month is out of range
                                            </small>
                                           <small class="text-danger" *ngIf="medSpring.get('538').errors.invalidDateyear">
                                             year is out of range(1900-2099) </small>
                                           <small class="text-danger" *ngIf="medSpring.get('538').errors.invalidDateRange">
                                           date is out of range
                                           </small>
                                           </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="title">
                    <h4>
                        EMLPOYMENT HISTORY:
                    </h4>
                </div>
                <div class="dataBox">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="row">
                                <label for="445" class="col-sm-4 pt-2 formLabel">Current EMLPoyer Name</label>
                                <input type="text" placeholder="Current EMLPoyer Name" id="445"
                                    class="form-control col-md-8" formControlName="445">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row">
                                <label for="454" class="col-sm-3 pt-2 formLabel">Start Date/End Date</label>
                                <div class="form-group col-md-4 ">
                                    <div class="input-group">
                                        <input class="form-control" formControlName="454" name="dp" placeholder="Date" (blur)="clearInputIfInvalid('454')"
                                            ngbDatepicker #d24="ngbDatepicker" placement="bottom-left">
                                        <div class="input-group-append">
                                            <button class="btn btn-outline-secondary calendar" (click)="d24.toggle()"
                                                type="button"><i class="fas fa-calendar-alt"></i></button>
                                        </div>
                                        <div *ngIf="medSpring.get('454').invalid ">
                                            <small class="text-danger" *ngIf="medSpring.get('454').errors.invalidDateFormat">
                                             Only Date Format is allowed(MM/DD/YYYY)</small>
                                            <small class="text-danger" *ngIf="medSpring.get('454').errors.invalidMonth">
                                            Month is out of range
                                            </small>
                                           <small class="text-danger" *ngIf="medSpring.get('454').errors.invalidDateyear">
                                             year is out of range(1900-2099) </small>
                                           <small class="text-danger" *ngIf="medSpring.get('454').errors.invalidDateRange">
                                           date is out of range
                                           </small>
                                           </div>
                                    </div>
                                </div>
                                <div class="form-group col-md-4 ">
                                    <div class="input-group">
                                        <input class="form-control" formControlName="455" name="dp" placeholder="Date" (blur)="clearInputIfInvalid('455')"
                                            ngbDatepicker #d25="ngbDatepicker" placement="bottom-left">
                                        <div class="input-group-append">
                                            <button class="btn btn-outline-secondary calendar" (click)="d25.toggle()"
                                                type="button"><i class="fas fa-calendar-alt"></i></button>
                                        </div>
                                        <div *ngIf="medSpring.get('455').invalid ">
                                            <small class="text-danger" *ngIf="medSpring.get('455').errors.invalidDateFormat">
                                             Only Date Format is allowed(MM/DD/YYYY)</small>
                                            <small class="text-danger" *ngIf="medSpring.get('455').errors.invalidMonth">
                                            Month is out of range
                                            </small>
                                           <small class="text-danger" *ngIf="medSpring.get('455').errors.invalidDateyear">
                                             year is out of range(1900-2099) </small>
                                           <small class="text-danger" *ngIf="medSpring.get('455').errors.invalidDateRange">
                                           date is out of range
                                           </small>
                                           </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-md-4">
                            <div class="row">
                                <label for="456" class="col-sm-4 pt-2 formLabel">Previous EMLPoyer Name</label>
                                <input type="text" placeholder="Previous EMLPoyer Name" id="456"
                                    class="form-control col-md-8" formControlName="456">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row">
                                <label for="465" class="col-sm-3 pt-2 formLabel">Start Date/End Date</label>
                                <div class="form-group col-md-4 ">
                                    <div class="input-group">
                                        <input class="form-control" formControlName="465" name="dp" placeholder="Date" (blur)="clearInputIfInvalid('465')"
                                            ngbDatepicker #d26="ngbDatepicker" placement="bottom-left">
                                        <div class="input-group-append">
                                            <button class="btn btn-outline-secondary calendar" (click)="d26.toggle()"
                                                type="button"><i class="fas fa-calendar-alt"></i></button>
                                        </div>
                                        <div *ngIf="medSpring.get('465').invalid ">
                                            <small class="text-danger" *ngIf="medSpring.get('465').errors.invalidDateFormat">
                                             Only Date Format is allowed(MM/DD/YYYY)</small>
                                            <small class="text-danger" *ngIf="medSpring.get('465').errors.invalidMonth">
                                            Month is out of range
                                            </small>
                                           <small class="text-danger" *ngIf="medSpring.get('465').errors.invalidDateyear">
                                             year is out of range(1900-2099) </small>
                                           <small class="text-danger" *ngIf="medSpring.get('465').errors.invalidDateRange">
                                           date is out of range
                                           </small>
                                           </div>
                                    </div>
                                </div>
                                <div class="form-group col-md-4 ">
                                    <div class="input-group">
                                        <input class="form-control" formControlName="466" name="dp" placeholder="Date" (blur)="clearInputIfInvalid('466')"
                                            ngbDatepicker #d27="ngbDatepicker" placement="bottom-left">
                                        <div class="input-group-append">
                                            <button class="btn btn-outline-secondary calendar" (click)="d27.toggle()"
                                                type="button"><i class="fas fa-calendar-alt"></i></button>
                                        </div>
                                        <div *ngIf="medSpring.get('466').invalid ">
                                            <small class="text-danger" *ngIf="medSpring.get('466').errors.invalidDateFormat">
                                             Only Date Format is allowed(MM/DD/YYYY)</small>
                                            <small class="text-danger" *ngIf="medSpring.get('466').errors.invalidMonth">
                                            Month is out of range
                                            </small>
                                           <small class="text-danger" *ngIf="medSpring.get('466').errors.invalidDateyear">
                                             year is out of range(1900-2099) </small>
                                           <small class="text-danger" *ngIf="medSpring.get('466').errors.invalidDateRange">
                                           date is out of range
                                           </small>
                                           </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-md-4">
                            <div class="row">
                                <label for="467" class="col-sm-4 pt-2 formLabel">Previous EMLPoyer Name</label>
                                <input type="text" placeholder="Previous EMLPoyer Name" id="467"
                                    class="form-control col-md-8" formControlName="467">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row">
                                <label for="476" class="col-sm-3 pt-2 formLabel">Start Date/End Date</label>
                                <div class="form-group col-md-4 ">
                                    <div class="input-group">
                                        <input class="form-control" formControlName="476" name="dp" placeholder="Date" (blur)="clearInputIfInvalid('476')"
                                            ngbDatepicker #d28="ngbDatepicker" placement="bottom-left">
                                        <div class="input-group-append">
                                            <button class="btn btn-outline-secondary calendar" (click)="d28.toggle()"
                                                type="button"><i class="fas fa-calendar-alt"></i></button>
                                        </div>
                                        <div *ngIf="medSpring.get('476').invalid ">
                                            <small class="text-danger" *ngIf="medSpring.get('476').errors.invalidDateFormat">
                                             Only Date Format is allowed(MM/DD/YYYY)</small>
                                            <small class="text-danger" *ngIf="medSpring.get('476').errors.invalidMonth">
                                            Month is out of range
                                            </small>
                                           <small class="text-danger" *ngIf="medSpring.get('476').errors.invalidDateyear">
                                             year is out of range(1900-2099) </small>
                                           <small class="text-danger" *ngIf="medSpring.get('476').errors.invalidDateRange">
                                           date is out of range
                                           </small>
                                           </div>
                                    </div>
                                </div>
                                <div class="form-group col-md-4 ">
                                    <div class="input-group">
                                        <input class="form-control" formControlName="477" name="dp" (blur)="clearInputIfInvalid('477')"
                                            placeholder="From Date" ngbDatepicker #d29="ngbDatepicker"
                                            placement="bottom-left">
                                        <div class="input-group-append">
                                            <button class="btn btn-outline-secondary calendar" (click)="d29.toggle()"
                                                type="button"><i class="fas fa-calendar-alt"></i></button>
                                        </div>
                                        <div *ngIf="medSpring.get('477').invalid ">
                                            <small class="text-danger" *ngIf="medSpring.get('477').errors.invalidDateFormat">
                                             Only Date Format is allowed(MM/DD/YYYY)</small>
                                            <small class="text-danger" *ngIf="medSpring.get('477').errors.invalidMonth">
                                            Month is out of range
                                            </small>
                                           <small class="text-danger" *ngIf="medSpring.get('477').errors.invalidDateyear">
                                             year is out of range(1900-2099) </small>
                                           <small class="text-danger" *ngIf="medSpring.get('477').errors.invalidDateRange">
                                           date is out of range
                                           </small>
                                           </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="row p-2">
                        <label for="1138" class="col-sm-2 pt-2 formLabel">Work History Gap Explanation</label>
                    </div>
                    <div class="row pl-4">
                        <input type="text" placeholder="Explanation for Gap in Work History 1" id="1138"
                            class="form-control col-md-10" formControlName="1138">
                    </div>
                    <hr>
                    <div class="row pl-4">
                        <input type="text" placeholder="Explanation for Gap in Work History 2" id="1831"
                            class="form-control col-md-10" formControlName="1831">
                    </div>
                    <hr>
                    <div class="row pl-4">
                        <input type="text" placeholder="Explanation for Gap in Work History 3" id="1832"
                            class="form-control col-md-10" formControlName="1832">
                    </div>
                    <hr>
                    <div class="row pl-4">
                        <input type="text" placeholder="Explanation for Gap in Work History 4" id="1953"
                            class="form-control col-md-10" formControlName="1953">
                    </div>

                </div>
                <div class="title">
                    <h4>
                        MALPRACTICE HISTORY:
                    </h4>
                </div>
                <div class="dataBox">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="row">
                                <label for="345" class="col-sm-4 pt-2 formLabel">Previous Carrier Name</label>
                                <input type="text" placeholder="MLP Carrier Name" id="345" class="form-control col-md-8"
                                    formControlName="345">
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="row">
                                <label for="346" class="col-sm-4 pt-2 formLabel">Policy Number</label>
                                <input type="text" placeholder="MLP Policy Number" id="346"
                                    class="form-control col-md-8" formControlName="346">
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="row">
                                <label for="901" class="col-sm-1 pt-2 formLabel">Address</label>
                                <input type="text" placeholder="MLP Address 1" id="901" class="form-control col-md-3"
                                    formControlName="901">
                                <input type="text" placeholder="MLP Address 2" id="902" class="form-control col-md-3"
                                    formControlName="902">
                                <input type="text" placeholder="MLP City" id="903" class="form-control col-md-3"
                                    formControlName="903">
                                <div class="form-group selectDrop col-md-2">
                                    <ng-select [items]="states" bindLabel="name" placeholder="Select State"
                                        bindValue="id" formControlName="905">
                                    </ng-select>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="row">
                                        <label for="906" class="col-sm-4 pt-2 formLabel"><span></span></label>
                                        <input type="text" placeholder="Zip" id="906" class="form-control col-md-8"
                                            formControlName="906">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-md-4">
                            <div class="row">
                                <label for="1266" class="col-sm-4 pt-2 formLabel">Phone</label>
                                <input type="text" placeholder="MLP Phone" 
                                [class.is-invalid]="(medSpring.controls['1266'].invalid && isSubmit)"
                                id="1266" class="form-control col-md-8"
                                    formControlName="1266">
                                <div class="formLabel col-md-11"                                                                     
                                    *ngIf="medSpring.controls['1266'].invalid">
                                    <small class="text-danger" *ngIf="medSpring.controls['1266'].invalid && medSpring.controls['1266'].errors?.pattern">
                                        Only number are allowed
                                    </small>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="row">
                                <label for="1273" class="col-sm-4 pt-2 formLabel">Fax</label>
                                <input type="text" placeholder="MLP Fax" id="1273" 
                                [class.is-invalid]="(medSpring.controls['1273'].invalid && isSubmit)"
                                class="form-control col-md-8"
                                    formControlName="1273">
                                <div class="formLabel col-md-11"                                                                     
                                    *ngIf="medSpring.controls['1273'].invalid">
                                    <small class="text-danger" *ngIf="medSpring.controls['1273'].invalid && medSpring.controls['1273'].errors?.pattern">
                                        Only number are allowed
                                    </small>
                                </div>        
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-md-4">
                            <div class="row">
                                <label for="348" class="col-sm-4 pt-2 formLabel">Issue date</label>
                                <div class="form-group col-md-8 ">
                                    <div class="input-group">
                                        <input class="form-control" formControlName="348" name="dp" placeholder="Date" (blur)="clearInputIfInvalid('348')"
                                            ngbDatepicker #d30="ngbDatepicker" placement="bottom-left">
                                        <div class="input-group-append">
                                            <button class="btn btn-outline-secondary calendar" (click)="d30.toggle()"
                                                type="button"><i class="fas fa-calendar-alt"></i></button>
                                        </div>
                                        <div *ngIf="medSpring.get('348').invalid ">
                                            <small class="text-danger" *ngIf="medSpring.get('348').errors.invalidDateFormat">
                                             Only Date Format is allowed(MM/DD/YYYY)</small>
                                            <small class="text-danger" *ngIf="medSpring.get('348').errors.invalidMonth">
                                            Month is out of range
                                            </small>
                                           <small class="text-danger" *ngIf="medSpring.get('348').errors.invalidDateyear">
                                             year is out of range(1900-2099) </small>
                                           <small class="text-danger" *ngIf="medSpring.get('348').errors.invalidDateRange">
                                           date is out of range
                                           </small>
                                           </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="row">
                                <label for="349" class="col-sm-4 pt-2 formLabel">Expiration Date</label>
                                <div class="form-group col-md-8 ">
                                    <div class="input-group">
                                        <input class="form-control" formControlName="349" name="dp" placeholder="Date" (blur)="clearInputIfInvalid('349')"
                                            ngbDatepicker #d31="ngbDatepicker" placement="bottom-left">
                                        <div class="input-group-append">
                                            <button class="btn btn-outline-secondary calendar" (click)="d31.toggle()"
                                                type="button"><i class="fas fa-calendar-alt"></i></button>
                                        </div>
                                        <div *ngIf="medSpring.get('349').invalid ">
                                            <small class="text-danger" *ngIf="medSpring.get('349').errors.invalidDateFormat">
                                             Only Date Format is allowed(MM/DD/YYYY)</small>
                                            <small class="text-danger" *ngIf="medSpring.get('349').errors.invalidMonth">
                                            Month is out of range
                                            </small>
                                           <small class="text-danger" *ngIf="medSpring.get('349').errors.invalidDateyear">
                                             year is out of range(1900-2099) </small>
                                           <small class="text-danger" *ngIf="medSpring.get('349').errors.invalidDateRange">
                                           date is out of range
                                           </small>
                                           </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="form-group col-md-12 ml-4">
                            <div class="form-check form-check-inline ml-4">
                                <label class="form-check-label" for="inlineRadio1Aggregate">Aggregate</label>
                                <input formControlName="cmd_0426" class="form-check-input m-1 mt-1" type="radio"
                                    value="1" id="inlineRadio1Aggregate">
                            </div>
                            <div class="form-check form-check-inline ml-4">
                                <label class="form-check-label" for="inlineRadio2Occurence">Occurence</label>
                                <input formControlName="cmd_0426" class="form-check-input ml-1 mt-1" type="radio"
                                    value="0" id="inlineRadio2Occurence">
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-md-4">
                            <div class="row">
                                <label for="350" class="col-sm-4 pt-2 formLabel">PerClaim Amount</label>
                                <div class="form-group selectDrop col-md-8">
                                    <ng-select [items]="preClaim" bindLabel="name" placeholder="PerClaim Amount"
                                        bindValue="id" formControlName="350">
                                    </ng-select>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="row">
                                <label for="351" class="col-sm-4 pt-2 formLabel">PerAggregate Amount</label>
                                <div class="form-group selectDrop col-md-8">
                                    <ng-select [items]="aggregates" bindLabel="name"
                                        placeholder="PerAggregate Amount" bindValue="id" formControlName="351">
                                    </ng-select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-md-4">
                            <div class="row">
                                <label for="1242" class="col-sm-4 pt-2 formLabel">Previous Carrier Name</label>
                                <input type="text" placeholder="Previous MLP Carrier Name" id="1242"
                                    class="form-control col-md-8" formControlName="1242">
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="row">
                                <label for="1256" class="col-sm-4 pt-2 formLabel">Policy Number</label>
                                <input type="text" placeholder="Previous MLP Policy Number" id="1256"
                                    class="form-control col-md-8" formControlName="1256">
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="row">
                                <label for="1244" class="col-sm-1 pt-2 formLabel">Address</label>
                                <input type="text" placeholder="Previous MLP Address 1" id="1244" class="form-control col-md-3"
                                    formControlName="1244">
                                <input type="text" placeholder="Previous MLP Address 2" id="1245" class="form-control col-md-3"
                                    formControlName="1245">
                                <input type="text" placeholder="Previous MLP City" id="1246" class="form-control col-md-3"
                                    formControlName="1246">
                                <div class="form-group selectDrop col-md-2">
                                    <ng-select [items]="states" bindLabel="name" placeholder="Select State"
                                        bindValue="id" formControlName="1248">
                                    </ng-select>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="row">
                                        <label for="1249" class="col-sm-4 pt-2 formLabel"><span></span></label>
                                        <input type="text" placeholder="Previous Zip" id="1249" class="form-control col-md-8"
                                            formControlName="1249">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-md-4">
                            <div class="row">
                                <label for="1254" class="col-sm-4 pt-2 formLabel">Phone</label>
                                <input type="text" placeholder="Previous MLP Phone" 
                                [class.is-invalid]="(medSpring.controls['1254'].invalid && isSubmit)"
                                id="1254" class="form-control col-md-8"
                                    formControlName="1254">
                                <div class="formLabel col-md-11"                                                                     
                                    *ngIf="medSpring.controls['1254'].invalid">
                                    <small class="text-danger" *ngIf="medSpring.controls['1254'].invalid && medSpring.controls['1254'].errors?.pattern">
                                        Only number are allowed
                                    </small>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="row">
                                <label for="1255" class="col-sm-4 pt-2 formLabel">Fax</label>
                                <input type="text" placeholder="Previous MLP Fax" 
                                [class.is-invalid]="(medSpring.controls['1255'].invalid && isSubmit)"
                                id="1255" class="form-control col-md-8"
                                formControlName="1255">
                                <div class="formLabel col-md-11"                                                                     
                                    *ngIf="medSpring.controls['1255'].invalid">
                                    <small class="text-danger" *ngIf="medSpring.controls['1255'].invalid && medSpring.controls['1255'].errors?.pattern">
                                        Only number are allowed
                                    </small>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-md-4">
                            <div class="row">
                                <label for="1252" class="col-sm-4 pt-2 formLabel">Issue date</label>
                                <div class="form-group col-md-8 ">
                                    <div class="input-group">
                                        <input class="form-control" formControlName="1252" name="dp" placeholder="Date" (blur)="clearInputIfInvalid('1252')"
                                            ngbDatepicker #d32="ngbDatepicker" placement="bottom-left">
                                        <div class="input-group-append">
                                            <button class="btn btn-outline-secondary calendar" (click)="d32.toggle()"
                                                type="button"><i class="fas fa-calendar-alt"></i></button>
                                        </div>
                                        <div *ngIf="medSpring.get('1252').invalid ">
                                            <small class="text-danger" *ngIf="medSpring.get('1252').errors.invalidDateFormat">
                                             Only Date Format is allowed(MM/DD/YYYY)</small>
                                            <small class="text-danger" *ngIf="medSpring.get('1252').errors.invalidMonth">
                                            Month is out of range
                                            </small>
                                           <small class="text-danger" *ngIf="medSpring.get('1252').errors.invalidDateyear">
                                             year is out of range(1900-2099) </small>
                                           <small class="text-danger" *ngIf="medSpring.get('1252').errors.invalidDateRange">
                                           date is out of range
                                           </small>
                                           </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="row">
                                <label for="1253" class="col-sm-4 pt-2 formLabel">Expiration Date</label>
                                <div class="form-group col-md-8">
                                    <div class="input-group">
                                        <input class="form-control" formControlName="1253" name="dp" placeholder="Date" (blur)="clearInputIfInvalid('1253')"
                                            ngbDatepicker #d33="ngbDatepicker" placement="bottom-left">
                                        <div class="input-group-append">
                                            <button class="btn btn-outline-secondary calendar" (click)="d33.toggle()"
                                                type="button"><i class="fas fa-calendar-alt"></i></button>
                                        </div>
                                        <div *ngIf="medSpring.get('1253').invalid ">
                                            <small class="text-danger" *ngIf="medSpring.get('1253').errors.invalidDateFormat">
                                             Only Date Format is allowed(MM/DD/YYYY)</small>
                                            <small class="text-danger" *ngIf="medSpring.get('1253').errors.invalidMonth">
                                            Month is out of range
                                            </small>
                                           <small class="text-danger" *ngIf="medSpring.get('1253').errors.invalidDateyear">
                                             year is out of range(1900-2099) </small>
                                           <small class="text-danger" *ngIf="medSpring.get('1253').errors.invalidDateRange">
                                           date is out of range
                                           </small>
                                           </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="form-group col-md-12 ml-4">
                            <div class="form-check form-check-inline ml-4">
                                <label class="form-check-label" for="inlineRadioAggregate">Aggregate</label>
                                <input formControlName="cmd_0425" class="form-check-input m-1 mt-1" type="radio"
                                    value="1" id="inlineRadioAggregate">
                            </div>
                            <div class="form-check form-check-inline ml-4">
                                <label class="form-check-label" for="inlineRadioOccurence">Occurence</label>
                                <input formControlName="cmd_0425" class="form-check-input ml-1 mt-1" type="radio"
                                    value="0" id="inlineRadioOccurence">
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-md-4">
                            <div class="row">
                                <label for="1264" class="col-sm-4 pt-2 formLabel">PerClaim Amount</label>
                                <div class="form-group selectDrop col-md-8">
                                    <ng-select [items]="preClaim" bindLabel="name" placeholder="PerClaim Amount"
                                        bindValue="id" formControlName="1264">
                                    </ng-select>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="row">
                                <label for="1265" class="col-sm-4 pt-2 formLabel">PerAggregate Amount</label>
                                <div class="form-group selectDrop col-md-8">
                                    <ng-select [items]="aggregates" bindLabel="name"
                                        placeholder="PerAggregate Amount" bindValue="id" formControlName="1265">
                                    </ng-select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr>
                </div>
            </form>
        </div>
    </div>
    <div class="modal-footer mt-4 ">
        <button *ngIf="formData.receivedForm" type="button" class="btn customOrange float-right"
        (click)="saveForm('approve')">Approve</button>
        <button *ngIf="formData.submitForm" type="button" class="btn customOrange float-right"
        (click)="saveForm('submit')">Submit</button>
        <button *ngIf="formData.submitForm" type="button" class="btn customOrange float-right"
        (click)="saveForm('save')">Save</button>
        <button *ngIf="formData.previewForm" type="button" class="btn customOrange float-right" (click)="close()">Close</button>
    </div>
</div>