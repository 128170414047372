<ngx-spinner bdColor = "rgba(51, 51, 51, 0.8)" size = "large" color = "#fff" [fullScreen] = "true"
type = "ball-clip-rotate-pulse"></ngx-spinner>
<div class="modal-header">
  <h4 class="modal-title">Change Password</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
    <form [formGroup]="updtpaswd">

            <div class="form-group">
              <!-- <label for="password"> Old Password
                <span class="mandatory">*</span>
              </label> -->
              <input type="password" class="form-control" formControlName="old_password" id="password" placeholder="Old Password">
            </div>

            <div class="form-group">
              <!-- <label for="password"> New Password
                <span class="mandatory">*</span>
              </label> -->
              <input type="password" class="form-control" formControlName="new_password" id="password" placeholder="New Password">
              <small class="text-danger" *ngIf="updtpaswd.controls['new_password'].hasError('required') && updtpaswd.controls['new_password'].touched">
                  Password is mandatory. </small>
                  <small class="text-danger" *ngIf="updtpaswd.controls['new_password'].hasError('minlength') && updtpaswd.controls['new_password'].touched">
                    Password should be atleast 10 characters. </small>
                  <small class="text-danger" *ngIf="updtpaswd.controls['new_password'].hasError('pattern') && !updtpaswd.controls['new_password'].hasError('minlength')">
                    Password must contain a least one symbol,upper and lower case letters,
                    and numeric value. </small> 
            </div>

            <div class="form-group">
                <!-- <label for="password">Confirm Password
                  <span class="mandatory">*</span>
                </label> -->
                <input type="password" class="form-control" formControlName="confirmedPassword" id="confirmedPassword" placeholder="Confirm Password" >
                <small class="text-danger" *ngIf="updtpaswd.controls['confirmedPassword'].hasError('required') && updtpaswd.controls['confirmedPassword'].touched">Confirm Password is mandatory</small>
                <small class="text-danger" *ngIf="updtpaswd.controls['confirmedPassword'].hasError('matchPassword') && updtpaswd.controls['confirmedPassword'].touched">Passwords doesn't match</small>
          </div>
    </form>
  
</div>
<div class="modal-footer">
  <button type="button" class="btn customOrange btn-sm mx-2" click-stop-propagation [disabled]="updtpaswd.invalid" (click)="addsaveDetails()">Change Password</button>
  <!-- <button type="button" class="btn btn-customer btn-sm mx-2" (click)="clearuser()">Clear</button> -->
  <button type="button" class="btn customOrange btn-sm mx-2" (click)="activeModal.close('Close click')">Cancel</button>

</div>

