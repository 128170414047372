import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { StorageService } from 'apps/cmd-frontend/src/app/shared/services/storage/storage.service';
import { AsyncSubject, Observable } from 'rxjs';
import { ToasterNotiService } from 'apps/cmd-frontend/src/app/shared/services/notifications/toaster-noti.service';

export interface SelectedFiles {
  fileName: string;
  fileSize : Number;
  base64?: string;
}

@Component({
  selector: 'ctg-esign-upload',
  templateUrl: './esign-upload.component.html',
  styleUrls: ['./esign-upload.component.scss']
})
export class EsignUploadComponent implements OnInit {

  @Input() selectedForm:any;
  @ViewChild('uploadfile') Uploadfile:ElementRef;
  public fileList:any;
  @Output() uploadFormEmit:EventEmitter<any> = new EventEmitter();
  public userId: any;
  public customerId:any;
  public selectedFiles : SelectedFiles[] = [];
 
  public esignuploadForm:UntypedFormGroup;
  public submitted:boolean;


  constructor(public activeModal: NgbActiveModal,
    private storageService:StorageService,
    private fb:UntypedFormBuilder,
    private notiService: ToasterNotiService ) { }

  ngOnInit(): void {
    this.userId= Number(this.storageService.getItem('userId'))
    this.customerId=Number(this.storageService.getItem('customerId'))
    this.buildSearchEmailForm();
  }

  buildSearchEmailForm(){
    this.esignuploadForm = this.fb.group({
     uploadfile:[null,Validators.required]
    })
  }
  
  get f() { return this.esignuploadForm.controls; }

  fileChange(event){
    this.fileList = event.target.files[0];
    this.Uploadfile.nativeElement.nextSibling.innerHTML = this.fileList.name;
  }

  public toFilesBase64(files: File[], selectedFiles: SelectedFiles[]): Observable<SelectedFiles[]> {
    const result = new AsyncSubject<SelectedFiles[]>();
    if (files?.length) {
      Object.keys(files)?.forEach(async (file, i) => {
        const reader = new FileReader();
        reader.readAsDataURL(files[i]);
        reader.onload = (e) => {
          selectedFiles = selectedFiles?.filter(f => f?.fileName != files[i]?.name)
          selectedFiles.push({ fileName: files[i]?.name, fileSize : files[i]?.size, base64:reader?.result as string })
          result.next(selectedFiles);
         //const base64Img = (fileReader.result as string).substring((fileReader.result as string).indexOf( ',' ) + 1 );
          this.selectedFiles = selectedFiles;
          if (files?.length === (i + 1)) {
            result.complete();
          }
        };
      });
      return result;
    } else {
      result.next([]);
      result.complete();
      return result;
    }
  }

  public onFileSelected(event) {
    this.selectedFiles = [];
    const file = event.target.files;
    this.Uploadfile.nativeElement.nextSibling.innerHTML = file[0].name;
    // this.selectedFiles = []; // clear
    console.log(file)
    this.toFilesBase64(file, this.selectedFiles).subscribe((res: SelectedFiles[]) => {
      this.selectedFiles = res;
    });
  }
  

  onUploadClick(){
      this.submitted=true
   
        if(this.esignuploadForm.invalid)
        return

        if(this.selectedFiles.length != 0){
          for (let x in this.selectedFiles) {
          let size =  this.selectedFiles[x].fileSize.toString();
          let uploadFileSizeLimit = 60;
            console.log("File Size in MB : ",((+size/1024)/1000));
              if(((+size/1024)/1000) >= uploadFileSizeLimit){
                this.notiService.showError("File size can not be greater than 60 MB.","",3000);
                this.selectedFiles = [];
                return
              }
          }
        }

        let postParams = new FormData();

        postParams.append('customerId', this.customerId);
        postParams.append('userId', this.userId);
        postParams.append('generate_form_id', this.selectedForm.generated_form_id);
      // postParams.append('esign', this.fileList, this.fileList.name);
         for (var i = 0; i < this.selectedFiles.length; i++) {
              postParams.append('esignfile', JSON.stringify(this.selectedFiles[i]));
          }
        
        this.uploadFormEmit.emit(postParams)

    }

}
